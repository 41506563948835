import * as types from "../Constants";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, userData: action.payload };
    case types.LOGIN_FAILURE:
      return { ...state, loginError: action.payload };

    case types.LOGOUT_SUCCESS:
      return { ...state, logoutSuccess: action.payload };
    case types.LOGOUT_FAILURE:
      return { ...state, logoutError: action.payload };

    case types.TOKEN_REFRESH_SUCCESS:
      return { ...state, refreshTokenRes: action.payload };
    case types.TOKEN_REFRESH_FAILURE:
      return { ...state, logoutError: action.payload };

    case types.ADD_NOTIFICATION_TOKEN_SUCCESS:
      return { ...state, tokenRes: action.payload };
    case types.ADD_NOTIFICATION_TOKEN_FAILURE:
      return { ...state, tokenError: action.payload };

    case types.REMOVE_NOTIFICATION_TOKEN_SUCCESS:
      return { ...state, tokenRemoveRes: action.payload };
    case types.REMOVE_NOTIFICATION_TOKEN_FAILURE:
      return { ...state, tokenRemoveError: action.payload };

    case types.GET_USER_ACTIVITY_SUCCESS:
      return { ...state, userActivityRes: action.payload };
    case types.GET_USER_ACTIVITY_FAILURE:
      return { ...state, userActivityError: action.payload };

    default:
      return state;
  }
};
