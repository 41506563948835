import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientSummary = (data) => {
  const options = {
    url: `v1/dashboard/Client`,
  };
  options.types = [
    types.GET_CLIENT_SUMMARY_SUCCESS,
    types.GET_CLIENT_SUMMARY_FAILURE,
  ];

  return api.post(options, data);
};

export const getClientSummarySettings = (data) => {
  const options = {
    url: `v1/dashboard/GetUserDashboardSettings`,
  };
  options.types = [
    types.GET_CLIENT_SUMMARY_SETTINGS_SUCCESS,
    types.GET_CLIENT_SUMMARY_SETTINGS_FAILURE,
  ];

  return api.get(options);
};

export const updateClientSummarySettings = (data) => {
  const options = {
    url: `v1/dashboard/UpdateUserDashboardSetting`,
  };
  options.types = [
    types.UPDATE_CLIENT_SUMMARY_SETTINGS_SUCCESS,
    types.UPDATE_CLIENT_SUMMARY_SETTINGS_FAILURE,
  ];

  return api.put(options, data);
};
export const getProcessingPersons = () => {
  const options = {
    url: `v1/users/ddl/All`,
  };
  options.types = [
    types.GET_PROCESSING_PERSONS_SUCCESS,
    types.GET_PROCESSING_PERSONS_FAILURE,
  ];

  return api.get(options);
};
export const getPermissonUser = (data) => {
  let bol = data || false;
  const options = {
    url: `v1/users/ddl/PermisionUser/${bol}`,
  };
  options.types = [
    types.GET_PERMISSION_USER_SUCCESS,
    types.GET_PERMISSION_USER_FAILURE,
  ];

  return api.get(options);
};

export const getAllCountries = () => {
  const options = {
    url: `v1/config/GetAllCountries`,
  };
  options.types = [
    types.GET_COUNTRY_LIST_SUCCESS,
    types.GET_COUNTRY_LIST_FAILURE,
  ];

  return api.get(options);
};

export const updateClientPriority = (data) => {
  const options = {
    url: `v1/subject/type/Priority`,
  };
  options.types = [
    types.UPDATE_CLIENT_PRIORITY_SUCCESS,
    types.UPDATE_CLIENT_PRIORITY_FAILURE,
  ];

  return api.post(options, data);
};

export const updateClientCasePriority = (data) => {
  const options = {
    url: `v1/subject/case/UpdateFromDashboard`,
  };
  options.types = [
    types.UPDATE_CLIENT_CASE_PRIORITY_SUCCESS,
    types.UPDATE_CLIENT_CASE_PRIORITY_FAILURE,
  ];

  return api.put(options, data);
};

export const getClientMails = ({
  id,
  branchId,
  familyId,
  emailType,
  pageSize,
  pageNumber,
  visaId,
}) => {
  const options = {
    url: `v1/imap/ClientEmailHistory/${id}/${familyId}/${emailType}/${pageSize}/${pageNumber}/${visaId}`,
  };
  options.types = [
    types.GET_CLIENT_MAILS_SUCCESS,
    types.GET_CLIENT_MAILS_FAILURE,
  ];

  return api.get(options);
};

export const addCaseTask = (data) => {
  const options = {
    url: `v1/task/TaskWithUsers`,
  };
  options.types = [types.ADD_CASE_TASKS_SUCCESS, types.ADD_CASE_TASKS_FAILURE];

  return api.post(options, data);
};

export const getCaseTask = (subjectId, familyId) => {
  const options = {
    url: `v1/task/AllBySubjectId/${subjectId}/${familyId}`,
  };
  options.types = [types.GET_CASE_TASKS_SUCCESS, types.GET_CASE_TASKS_FAILURE];

  return api.get(options);
};

export const getReminders = (id) => {
  const options = {
    url: `v1/reminder/All/SubjectId/${id}`,
  };
  options.types = [
    types.GET_CLIENT_REMINDERS_SUCCESS,
    types.GET_CLIENT_REMINDERS_FAILURE,
  ];

  return api.get(options);
};

export const addReminder = (data) => {
  const options = {
    url: `v1/reminder`,
  };
  options.types = [types.ADD_REMINDER_SUCCESS, types.ADD_REMINDER_FAILURE];

  return api.post(options, data);
};

export const updateReminderForClient = (data) => {
  const options = {
    url: `v1/reminder/reminderStatus`,
  };
  options.types = [
    types.UPDATE_REMINDER_SUCCESS,
    types.UPDATE_REMINDER_FAILURE,
  ];

  return api.put(options, data);
};

export const getClientEmailAndPhone = (id) => {
  const options = {
    url: `v1/client/CLientDetailInfo/${id}`,
  };
  options.types = [
    types.GET_CLIENT_EMAIL_AND_PHONE_SUCCESS,
    types.GET_CLIENT_EMAIL_AND_PHONE_FAILURE,
  ];

  return api.get(options);
};
