import { api } from "../../services/api";
import * as types from "../Constants";

export const getLetterTemplates = () => {
  const options = {
    url: `v1/template/All`,
  };
  options.types = [
    types.GET_LETTER_TEMPLATE_SUCCESS,
    types.GET_LETTER_TEMPLATE_FAILURE,
  ];

  return api.get(options);
};

export const addLetterTemplates = (data) => {
  const options = {
    url: "v1/template",
  };
  options.types = [
    types.ADD_LETTER_TEMPLATE_SUCCESS,
    types.ADD_LETTER_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const removeLetterTemplates = (data) => {
  const options = {
    url: "v1/template",
  };
  options.types = [
    types.DELETE_LETTER_TEMPLATE_SUCCESS,
    types.DELETE_LETTER_TEMPLATE_FAILURE,
  ];

  return api.delete(options, data);
};

export const updetaLetterTemplates = (data) => {
  const options = {
    url: "v1/template",
  };
  options.types = [
    types.PUT_LETTER_TEMPLATE_SUCCESS,
    types.PUT_LETTER_TEMPLATE_FAILURE,
  ];

  return api.put(options, data);
};

export const sortLetterTemplates = (data) => {
  const options = {
    url: "v1/template/TemplateReOrder",
  };
  options.types = [
    types.SORT_LETTER_TEMPLATE_SUCCESS,
    types.SORT_LETTER_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const addContractEmail = (data) => {
  const options = {
    url: "v1/client/contract/ContractEmail",
  };
  options.types = [
    types.ADD_CONTRACT_EMAIL_SUCCESS,
    types.ADD_CONTRACT_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const getContractEmail = () => {
  const options = {
    url: `v1/client/contract/ContractEmail`,
  };
  options.types = [
    types.GET_CONTRACT_EMAIL_SUCCESS,
    types.GET_CONTRACT_EMAIL_FAILURE,
  ];

  return api.get(options);
};
