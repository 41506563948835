import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Table,
  Spin,
  Modal,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getAllInvoiceTypes,
  getAllBanksListing,
  getAllCurrencies,
  addNewInvoice,
  getLastInvoiceNumber,
  addInvoicePayment,
  getInvoicePayments,
  deleteInvoicePayment,
  editInvoice,
  setEmailTemplate,
  getBranch,
  getInvoiceStatuses,
  getCompany,
  checkDuplicateClientInvoice,
  postInvoiceToXero,
  updateInvoiceToXero,
  getProfileClientTag,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import FroalaEditor from "react-froala-wysiwyg";
import activityData from "../Components/ClientActivity/ActivityData";
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class AddInvoiceToXero extends React.Component {
  formRef = React.createRef();
  formRefTemp = React.createRef();
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.state = {
      dateString: "",
      currencyName: "",
      loading: false,
      isTaxFieldVisible: true,
      clientProfileId: clientprofileid,
      branchId: selectedBranchId,
      dateStr: "",
      dueDate: "",
      accountCode: "",
      invoiceNo: Math.random()
        .toString(36)
        .substr(2, 5),
      invoiceTypeId: 1,
      taxInclusive: false,
      notes: "",
      lastInvoiceNumber: "",
      clientTagId: 0,
      _amount: 100,
      qTotal: 0,
      total: 0,
      subTotal: 0,
      discountAmount: 0,
      discount: 0,
      addedAmount: 0,
      gst: 0,
      dueAmount: 0,
      invoiceItems: [],
      invoiceItemsDefined: [],
      invoiceId: 0,
      invoicePayments: [],
      visibleDeleteModal: false,
      paymentDescription: "",
      visibleSendingReceiptModal: false,
      sendingReceiptEmail: "",
      subjectTypeId: 2,
      invoicePaymentsAmountTotal: 0,
      branchName: "",
      setEmailData: "",
      invoiceTypesListing: [],
      invoiceStatuses: [],
      statusId: 0,
      userEmail: userEmail,
      deletedPayment: 0,
      isDisabled: false,
      isDuplicateInvoiceExists: false,
    };
  }

  componentDidMount() {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.getCompany();
    this.props.getClientTag(
      this.props && this.props.invoiceInfo && this.props.invoiceInfo.subjectId
    );
    this.props.getAllInvoiceTypes();
    this.props.getAllCurrencies();
    this.props.getBranch();
    this.props.getInvoiceStatuses(this.state.invoiceTypeId);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let _invoiceInfo = nextProps.invoiceInfo;
    let _invoiceStatuses =
      nextProps && nextProps.invoiceStatuses && nextProps.invoiceStatuses;
    let _invoiceTypesListing =
      nextProps.invoiceTypesListing &&
      nextProps.invoiceTypesListing.items &&
      nextProps.invoiceTypesListing.items;
    let _branchData = nextProps.branchData;
    let invoiceTypesListingForChild = [];
    this.setState({ invoiceStatuses: _invoiceStatuses });
    this.setState({ invoiceTypesListing: _invoiceTypesListing });

    let _invoicePaymentsAmountTotal = 0;
    let _lastInvoiceNumber = nextProps && nextProps.lastInvoiceNumber;
    this.setState({ lastInvoiceNumber: _lastInvoiceNumber });

    if (_invoiceInfo && _invoiceInfo.id) {
      if (_invoiceInfo.id !== this.state.invoiceId) {
        // if(_invoiceInfo.invoiceTypeId === 3)
        // {
        //   this.setState({gst : 0 })
        // }
        let _dateStr = moment(_invoiceInfo.dateStr).format("DD/MM/YYYY");
        let _dueDate = moment(_invoiceInfo.dueDate).format("DD/MM/YYYY");
        this.props.getInvoiceStatuses(_invoiceInfo.invoiceTypeId);
        let _branchName;
        if (_branchData && _branchData.length > 0) {
          for (
            let branchIndex = 0;
            branchIndex < _branchData.length;
            branchIndex++
          ) {
            if (_branchData[branchIndex].id === this.state.branchId) {
              _branchName = _branchData[branchIndex].name;
            }
            this.setState({ branchName: _branchName });
          }
        }
        this.setState(
          {
            dateStr: _dateStr,
            dueDate: _dueDate,
            invoiceId: _invoiceInfo.id,
            invoiceNo: _invoiceInfo.invoiceNo,
            invoiceTypeId: _invoiceInfo.invoiceTypeId,
            clientTagId: _invoiceInfo.clientTagId,
            invoiceItemsDefined: _invoiceInfo.invoiceItems,
            taxInclusive: _invoiceInfo.taxInclusive,
            notes: _invoiceInfo.notes,
            isTaxFieldVisible: _invoiceInfo.invoiceTypeId !== 3,
          },
          () => {
            let _subTotal = _invoiceInfo.subTotal;
            let _currencyId;
            let _gst = _invoiceInfo.gst;
            let _discount = _invoiceInfo.discount;
            let _total = _invoiceInfo.total;
            let _dueAmount = _invoiceInfo.dueAmount;

            if (_invoiceInfo.currencyId === 0) {
              _currencyId = undefined;
            } else {
              _currencyId = _invoiceInfo.currencyId;
            }
            this.setState(
              {
                subTotal: _subTotal,
                currencyId: _currencyId,
                gst: _gst,
                discount: _discount,
                total: _total,
                dueAmount: _dueAmount,
              },
              () => {
                let _currencyList =
                  this.props &&
                  this.props.currencyListing &&
                  this.props.currencyListing;
                if (_currencyList && _currencyList.length > 0) {
                  for (
                    let pointer = 0;
                    pointer < _currencyList.length;
                    pointer++
                  ) {
                    if (_currencyList[pointer].id === this.state.currencyId) {
                      this.setState({
                        currencyName: _currencyList[pointer].currencyName,
                      });
                    }
                  }
                }
              }
            );
            this.formRef.current.setFieldsValue({
              subTotal: _subTotal,
              currencyId: _currencyId,
              gst: _gst,
              discount: _discount,
              total: _total,
              dueAmount: _dueAmount,
            });
          }
        );
      }
    }
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {});
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  handleDateChange = (date, dateString) => {
    this.setState({ dateStr: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  handleDueDateChange = (date, dateString) => {
    this.setState({ dueDate: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  getDatePickers = () => {
    return (
      <Row>
        <Col xs={11} offset={1}>
          <p style={{ fontSize: "11px" }}>{"Date"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dateStr === "" || this.state.dateStr === "Invalid date"
                ? undefined
                : moment(this.state.dateStr, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDateChange}
          />
        </Col>
        <Col xs={11} style={{ marginLeft: "8px" }}>
          <p style={{ fontSize: "11px" }}>{"Due Date"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dueDate === "" || this.state.dueDate === "Invalid date"
                ? undefined
                : moment(this.state.dueDate, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDueDateChange}
          />
        </Col>
      </Row>
    );
  };

  handleChangeInvoiceNo = (e) => {
    if (e.target.value.length < 6) {
      this.setState({ invoiceNo: e.target.value });
    } else {
      message.error("Max character limit for invoice no reached!");
    }
  };

  handleChangeInvoiceType = (value) => {
    this.setState({ invoiceTypeId: value }, () => {
      this.props.getInvoiceStatuses(this.state.invoiceTypeId);
    });
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  onBlur = () => {
    let _data = {
      invoiceNo: this.state.invoiceNo,
    };

    if (_data.invoiceNo !== "") {
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      this.props.checkDuplicateClientInvoice(_data).then(() => {
        let _duplicate = this.props && this.props.duplicate;
        if (_duplicate === true) {
          if (_invoiceInfo && _invoiceInfo.id) {
            if (this.state.invoiceNo !== _invoiceInfo.invoiceNo) {
              this.setState({ isDuplicateInvoiceExists: true }, () => {
                message.error("Invoice no must be unique!");
              });
            }
          } else {
            this.setState({ isDuplicateInvoiceExists: true }, () => {
              message.error("Invoice no must be unique!");
            });
          }
        } else {
          this.setState({ isDuplicateInvoiceExists: false }, () => {});
        }
      });
    }
  };

  getFilters = () => {
    return (
      <Row>
        <Col xs={6}></Col>
        <Col xs={6}>
          <p style={{ fontSize: "11px", marginLeft: "8px" }}>{"Invoice #"}</p>
          <Input
            disabled={true}
            value={this.state.invoiceNo}
            onChange={this.handleChangeInvoiceNo}
            onBlur={this.onBlur}
            style={{ marginLeft: "8px" }}
          />
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "11px", marginLeft: "16px" }}>{"Type"}</p>
          <Select
            disabled={true}
            style={{ marginLeft: "16px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeInvoiceType}
            defaultValue={
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing[0] &&
              this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypesListing[0].id
                : undefined
            }
            value={
              this.state.invoiceTypeId === 0
                ? undefined
                : this.state.invoiceTypesListing &&
                  this.state.invoiceTypesListing[0] &&
                  this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypeId
                : undefined
            }
          >
            {this.state &&
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.invoiceTypeName}</Option>;
              })}
          </Select>
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "10px", marginLeft: "8px" }}>{"Client Tags"}</p>
          <Select
            disabled={true}
            style={{ marginLeft: "8px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeClientTags}
            value={
              this.state.clientTagId === 0 ? undefined : this.state.clientTagId
            }
          >
            <Option value={0}>{"Select"}</Option>
            {this.props &&
              this.props.clientTagRes &&
              this.props.clientTagRes.items &&
              this.props.clientTagRes.items.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.tag}</Option>;
              })}
          </Select>
        </Col>
      </Row>
    );
  };

  handleChangeAmount = (value) => {};

  onFinishAddNewLines = (values) => {
    let _invoiceInfoId =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo.id;

    let invoiceItemsTemp = [];
    let _invoiceItems =
      values && values.invoiceItems === undefined ? [] : values.invoiceItems;
    if (_invoiceItems && _invoiceItems.length > 0) {
      for (
        let invoiceItemIndex = 0;
        invoiceItemIndex < _invoiceItems.length;
        invoiceItemIndex++
      ) {
        invoiceItemsTemp.push({
          id: 0,
          invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
          description:
            _invoiceItems[invoiceItemIndex].description === undefined
              ? ""
              : _invoiceItems[invoiceItemIndex].description,
          amount:
            _invoiceItems[invoiceItemIndex].amount === undefined
              ? 0
              : _invoiceItems[invoiceItemIndex].amount,
          xeroLineItemID: "00000000-0000-0000-0000-000000000000",
          taxInclusive: true,
        });
      }
    }
    if (this.state && this.state.invoiceItemsDefined.length > 0) {
      for (
        let invoiceItemDefinedIndex = 0;
        invoiceItemDefinedIndex < this.state.invoiceItemsDefined.length;
        invoiceItemDefinedIndex++
      ) {
        invoiceItemsTemp.push({
          id: _invoiceInfoId
            ? this.state.invoiceItemsDefined[invoiceItemDefinedIndex].id
            : 0,
          invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
          description: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
            .description,
          amount: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
            .amount,
          xeroLineItemID: "00000000-0000-0000-0000-000000000000",
          taxInclusive: true,
        });
      }
    }

    this.setState({ invoiceItems: invoiceItemsTemp });
    let amountTotal = 0;
    let amountTotalDefinedInvoiceItems = 0;
    if (values && values.invoiceItems && values.invoiceItems.length > 0) {
      for (let index = 0; index < values.invoiceItems.length; index++) {
        if (values.invoiceItems[index].amount !== undefined) {
          amountTotal = amountTotal + values.invoiceItems[index].amount;
        }
      }
    }

    let g = this.state.invoiceItemsDefined;

    if (
      this.state.invoiceItemsDefined &&
      this.state.invoiceItemsDefined.length > 0
    ) {
      for (
        let indexDefinedItems = 0;
        indexDefinedItems < this.state.invoiceItemsDefined.length;
        indexDefinedItems++
      ) {
        amountTotalDefinedInvoiceItems =
          amountTotalDefinedInvoiceItems +
          this.state.invoiceItemsDefined[indexDefinedItems].amount;
      }
    }

    amountTotal = amountTotal + amountTotalDefinedInvoiceItems;

    this.setState(
      {
        subTotal: amountTotal,
      },
      () => {
        let _discountedAmountDiscountPercentage;
        let _discountedAmountDiscountFinal;
        let _discountedAmountTaxPercentage;
        let _tempTotalAmount;
        if (this.state.discount !== 0) {
          _discountedAmountDiscountPercentage =
            (this.state.discount * this.state.subTotal) / 100;
          _discountedAmountDiscountFinal =
            this.state.subTotal - _discountedAmountDiscountPercentage;
        } else {
          _discountedAmountDiscountFinal =
            this.state.subTotal - this.state.discountAmount;
        }
        _discountedAmountTaxPercentage =
          (_discountedAmountDiscountFinal * this.state.gst) / 100;

        _tempTotalAmount =
          _discountedAmountTaxPercentage + _discountedAmountDiscountFinal;

        this.setState(
          {
            total: Math.round((_tempTotalAmount + Number.EPSILON) * 100) / 100,
            dueAmount: _tempTotalAmount - this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              subTotal: amountTotal,
              total: this.state.total,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    );
  };

  handleChangeInvoiceItemsDefinedDescription = (e, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].description = e.target.value;
        }
      }
    }
    this.setState({ invoiceItemsDefined: __invoiceItemsDefined }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeInvoiceItemsDefinedAmount = (value, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].amount = value;
        }
      }
    }
    this.setState({ invoiceItemsDefined: __invoiceItemsDefined }, () => {
      console.log("state", this.state);
    });
  };

  getAddNewLine = () => {
    return (
      <div>
        <Form onFinish={this.onFinishAddNewLines}>
          <Form.List name="invoiceItems">
            {(fields, { add, remove }) => (
              <>
                <Row>
                  <Col xs={23} className="add-new-line-container">
                    <Row style={{ padding: "4px" }}>
                      <Col xs={24} className="add-new-line-header">
                        <Row>
                          <Col xs={2}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"SR#"}
                            </h5>
                          </Col>
                          <Col xs={16}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"DESCRIPTION"}
                            </h5>
                          </Col>
                          <Col xs={3}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"AMOUNT"}
                            </h5>
                          </Col>
                          <Col xs={3}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"ACTION"}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {this.state &&
                      this.state.invoiceItemsDefined &&
                      this.state.invoiceItemsDefined.map((data, index) => {
                        return (
                          <Row>
                            <Col xs={2}>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {index + 1}
                              </p>
                            </Col>
                            <Col xs={16}>
                              <div className="add-new-line-inputs">
                                <Input
                                  disabled={true}
                                  defaultValue={data.description}
                                  onChange={(e) =>
                                    this.handleChangeInvoiceItemsDefinedDescription(
                                      e,
                                      data
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col xs={3} className="add-new-line-inputs">
                              <InputNumber
                                disabled={true}
                                defaultValue={data.amount}
                                onChange={(value) =>
                                  this.handleChangeInvoiceItemsDefinedAmount(
                                    value,
                                    data
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col xs={2}>
                          <p style={{ textAlign: "center", fontSize: "16px" }}>
                            {this.state &&
                              this.state.invoiceItemsDefined &&
                              this.state.invoiceItemsDefined.length + 1 + index}
                          </p>
                        </Col>
                        <Col xs={16}>
                          <div className="add-new-line-inputs">
                            <Form.Item
                              {...field}
                              name={[field.name, "description"]}
                              fieldKey={[field.fieldKey, "description"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter description!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div
                            className="add-new-line-inputs"
                            onClick={() => {
                              this.setState({ fieldKey: field }, () => {
                                console.log("field key", this.state.fieldKey);
                              });
                            }}
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "amount"]}
                              fieldKey={[field.fieldKey, "amount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter amount!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (value < 0) {
                                      return Promise.reject(
                                        "Type only positive numbers"
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={3}>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            <DeleteOutlined />
                          </div>
                        </Col>
                      </Row>
                    ))}
                    {/*<Row>*/}
                    {/*    <Col*/}
                    {/*        xs={6}*/}
                    {/*        offset={18}*/}
                    {/*        style={{ paddingLeft: "10px", marginTop: "6px" }}*/}
                    {/*    >*/}
                    {/*        <Button*/}
                    {/*            size="small"*/}
                    {/*            type="primary"*/}
                    {/*            htmlType="submit"*/}
                    {/*            // style={{*/}
                    {/*            //   display: fields.length > 0 ? "flex" : "none"*/}
                    {/*            // }}*/}
                    {/*        >*/}
                    {/*            Calculate Sub Total*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col xs={24}>*/}
                {/*        <Form.Item>*/}
                {/*            <div*/}
                {/*                className="add-new-line-button"*/}
                {/*                onClick={() => add()}*/}
                {/*            >*/}
                {/*                <p>{"ADD NEW LINE"}</p>*/}
                {/*            </div>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    );
  };

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState({ taxInclusive: e.target.checked }, () => {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
      this.setState({ gst: 0 });
      if (this.state.discount === 0) {
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - this.state.discountAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
      if (this.state.discountAmount === 0) {
        let _discountedAmount =
          (this.state.discount * this.state.subTotal) / 100;
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - _discountedAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    });
  };

  handleChangeDiscountPercentage = (value) => {
    let _discountedAmount;
    let __discountedAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discount: 0,
      });
      let _tempAmount;
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
        },
        () => {
          let b = _tempAmount - this.state.invoicePaymentsAmountTotal;

          this.setState({
            dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
          });
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    } else {
      let _tempAmount;
      _discountedAmount = (value * this.state.subTotal) / 100;
      __discountedAmount = this.state.subTotal - _discountedAmount;
      let discountedAmountPercentage =
        (__discountedAmount * this.state.gst) / 100;
      _tempAmount = __discountedAmount + discountedAmountPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
          dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discountAmount: 0,
    });
    this.setState({ discountAmount: 0, qTotal: 0 });
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
    }
    let addedAmount;

    this.setState({ gst: value }, () => {});
    let totalAmount;
    if (this.state.discount === 0 && this.state.discountAmount === 0) {
      addedAmount = (value * this.state.subTotal) / 100;
      totalAmount = addedAmount + this.state.subTotal;
    } else {
      let cal;
      if (this.state.discount === 0) {
        cal = this.state.subTotal - this.state.discountAmount;
      } else {
        cal =
          this.state.subTotal -
          (this.state.discount * this.state.subTotal) / 100;
      }
      addedAmount = (value * cal) / 100;
      totalAmount = addedAmount + cal;
    }

    this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
      this.setState(
        {
          dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          console.log(
            "state",
            this.state._amount - this.state.invoicePaymentsAmountTotal
          );
        }
      );
      // this.setState({ total: this.state._amount})
      this.formRef.current.setFieldsValue({
        total: Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
        dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
      });
    });
  };

  getForm = () => {
    return (
      <div>
        <Row style={{ marginTop: "17px", marginLeft: "17px" }}>
          <Col xs={7}>
            <TextArea
              disabled={true}
              value={this.state.notes}
              rows={14}
              onChange={this.handleChangeNotes}
            />
          </Col>
          <Col xs={8} offset={1}>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="subTotal"
                  colon={false}
                  labelAlign="left"
                  label="Sub Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  {...this.layout}
                  name="currencyId"
                  colon={false}
                  labelAlign="left"
                  label="Currency"
                >
                  <Select
                    disabled={true}
                    placeholder="Please Select Currency!"
                    size="small"
                  >
                    {this.props &&
                      this.props.currencyListing &&
                      this.props.currencyListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={data.id}>{data.currencyName}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.isTaxFieldVisible ? (
              <div>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Checkbox
                      disabled={true}
                      onChange={this.handleChangeTaxInclusiveCheck}
                      checked={this.state.taxInclusive}
                    >
                      {<span className={"filter-text"}>Tax inclusive</span>}
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item
                      name="gst"
                      colon={false}
                      labelAlign="left"
                      label="Tax %"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        disabled={true}
                        size="small"
                        // disabled={!!this.state.taxInclusive}
                        onChange={this.handleChangeTaxPercentage}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discount"
                  colon={false}
                  labelAlign="left"
                  label="Discount %"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    disabled={true}
                    size="small"
                    onChange={this.handleChangeDiscountPercentage}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="total"
                  colon={false}
                  labelAlign="left"
                  label="Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="dueAmount"
                  colon={false}
                  labelAlign="left"
                  label="Due"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="accountCode"
                  colon={false}
                  labelAlign="left"
                  label="Account Code"
                >
                  <Input
                    type="number"
                    size="medium"
                    onChange={this.onChangeAccountCode}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  onChangeAccountCode = (e) => {
    this.setState({
      accountCode: e.target.value === null ? "" : e.target.value,
    });
  };

  onClickPostToXero = (invoiceInformation) => {
    if (this.state.accountCode === "") {
      message.error("Please enter account code!");
    } else {
      let _dateStr;
      if (this.state.dateStr === "") {
        _dateStr = "1900-01-01T00:00:00+00:00";
      } else {
        _dateStr = moment(this.state.dateStr, "DD/MM/YYYY").format();
      }
      this.setState({ dateString: _dateStr }, () => {
        this.setState({ loading: true });
        if (
          invoiceInformation &&
          invoiceInformation.xeroInvoiceId !==
            "00000000-0000-0000-0000-000000000000"
        ) {
          this.props
            .updateInvoiceToXero(this.state)
            .then(() => {
              this.setState({ loading: false });
              message.success("Invoice is posted to Xero successfully!");
              this.props.history.push("/xero-sync-invoices");
            })
            .catch((err) => {
              this.setState({ loading: false });
              message.error("An error occurred! Please try again!");
            });
        } else {
          this.props
            .postInvoiceToXero(this.state)
            .then(() => {
              this.setState({ loading: false });
              message.success("Invoice is posted to Xero successfully!");
              this.props.history.push("/xero-sync-invoices");
            })
            .catch((err) => {
              this.setState({ loading: false });
              if (err.payload && err.payload.response.status == 400)
                message.error(err.payload && err.payload.response.data);
              else message.error("An error occurred! Please try again!");
            });
        }
      });
    }
  };

  getButtons = (invoiceInformation) => {
    return (
      <div>
        <Row style={{ marginLeft: "17px", marginTop: "44px" }}>
          <Col xs={24} style={{ display: "flex" }}>
            <Button
              className="button-blue"
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={() => this.onClickPostToXero(invoiceInformation)}
              disabled={this.state.isDisabled}
            >
              {"Post to Xero"}
            </Button>
            <Button
              className="button-blue"
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={this.backToInvoices}
            >
              Back to Invoices
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  backToInvoices = () => {
    this.props.history.push("/xero-sync-invoices");
  };

  render() {
    let _invoiceInformation =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo;
    return (
      <Fragment>
        <div className="reminder-tabs-header employer-Header">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Xero Invoices" key="1"></TabPane>
          </Tabs>
        </div>
        <Spin size="large" spinning={this.state.loading}>
          <div
            className="ts-container-add-client"
            style={{ marginTop: "53px" }}
          >
            <Row>
              <Col xs={23} offset={1}>
                <h4 style={{ marginTop: "6px" }}>{"Update Invoice"}</h4>
              </Col>
            </Row>
            <Form
              onFinish={this.onFinish}
              ref={this.formRef}
              initialValues={{
                discount: this.state.discount,
                subTotal: this.state.subTotal,
                gst: this.state.gst,
                total: this.state.subTotal,
                dueAmount: this.state.dueAmount,
              }}
            >
              <div className="ca-gray-cont">
                <Row style={{ marginTop: "-15px", marginRight: "-21px" }}>
                  <Col xs={11}>{this.getDatePickers()}</Col>
                  <Col xs={13}>{this.getFilters()}</Col>
                </Row>
                {this.getAddNewLine()}
                {this.getForm()}
                {this.getButtons(_invoiceInformation)}
              </div>
            </Form>
          </div>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let b =
    state &&
    state.invoicesReducer &&
    state.invoicesReducer.duplicate &&
    state.invoicesReducer.duplicate.duplicate;

  return {
    clientTagRes:
      state &&
      state.allClientReducer &&
      state.allClientReducer.getClientRes &&
      state.allClientReducer.getClientRes,
    invoiceTypesListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceTypesListing &&
      state.invoicesReducer.invoiceTypesListing,
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items &&
      state.invoicesReducer.currencyListing.items,
    lastInvoiceNumber:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.lastInvoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    invoiceInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceInfo &&
      state.invoicesReducer.invoiceInfo,
    duplicate:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.duplicate &&
      state.invoicesReducer.duplicate.duplicate &&
      state.invoicesReducer.duplicate.duplicate,
    _invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments,
    invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments.items &&
      state.invoicesReducer.invoicePayments.items,
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    companyData:
      state &&
      state.companyReducer &&
      state.companyReducer.companyData &&
      state.companyReducer.companyData,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getProfileClientTag, dispatch),
  getAllInvoiceTypes: bindActionCreators(getAllInvoiceTypes, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  addNewInvoice: bindActionCreators(addNewInvoice, dispatch),
  getLastInvoiceNumber: bindActionCreators(getLastInvoiceNumber, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  getInvoicePayments: bindActionCreators(getInvoicePayments, dispatch),
  deleteInvoicePayment: bindActionCreators(deleteInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  getBranch: bindActionCreators(getBranch, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  checkDuplicateClientInvoice: bindActionCreators(
    checkDuplicateClientInvoice,
    dispatch
  ),
  postInvoiceToXero: bindActionCreators(postInvoiceToXero, dispatch),
  updateInvoiceToXero: bindActionCreators(updateInvoiceToXero, dispatch),
});
AddInvoiceToXero = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInvoiceToXero);

export default withRouter(AddInvoiceToXero);
