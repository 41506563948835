import { api } from "../../services/api";
import * as types from "../Constants";
export const updateClientProfilesState = (payload) => {
  return {
    type: types.GET_CLIENT_PROFILES_LISTING_SUCCESS,
    payload,
  };
};
export const getClientProfilesWithProgress = (data) => {
  const options = {
    url: `v1/client/SearchClientCMS`,
  };

  options.types = [
    types.GET_CLIENT_PROFILES_LISTING_SUCCESS,
    types.GET_CLIENT_PROFILES_LISTING_FAILURE,
  ];

  return api.post(options, data);
};
export const getClientProfiles = (data) => {
  const options = {
    url: `v1/client/SearchClientCMSNew`,
  };

  options.types = [
    types.GET_CLIENT_PROFILES_LISTING_SUCCESS,
    types.GET_CLIENT_PROFILES_LISTING_FAILURE,
  ];

  return api.post(options, data);
};

export const searchClientProfiles = (data) => {
  const options = {
    url: `v1/client/SearchClientCMSNew`,
  };

  options.types = [
    types.SEARCH_CLIENT_PROFILES_LISTING_SUCCESS,
    types.SEARCH_CLIENT_PROFILES_LISTING_FAILURE,
  ];

  return api.post(options, data);
};

// export const advanceSearchClientProfiles = (data) => {
//   const options = {
//     url: `v1/client/SearchClientCMSAdvance`,
//   };

//   options.types = [
//     types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS,
//     types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_FAILURE,
//   ];

//   return api.post(options, data);
// };

export const advanceSearchClientProfiles = (data) => {
  const options = {
    url: `v1/client/SearchClientCMSAdvanceNew`,
  };

  options.types = [
    types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS,
    types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_FAILURE,
  ];

  return api.post(options, data);
};

export const getCaseStatuses = () => {
  const options = {
    url: `v1/company/visastatus/All`,
  };

  options.types = [
    types.GET_VISA_STATUS_LISTING_SUCCESS,
    types.GET_VISA_STATUS_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const updateVisaCaseStatus = (data) => {
  const options = {
    url: "v1/subject/case/UpdateSubjectCaseStatus",
  };
  options.types = [
    types.UPDATE_VISA_CASE_STATUS_SUCCESS,
    types.UPDATE_VISA_CASE_STATUS_FAILURE,
  ];

  return api.put(options, data);
};

export const getAdmissionCaseStatuses = () => {
  const options = {
    url: `v1/client/programdetail/Status`,
  };

  options.types = [
    types.GET_ADMISSION_CASE_STATUS_LISTING_SUCCESS,
    types.GET_ADMISSION_CASE_STATUS_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const updateAdmissionCaseStatus = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };

  options.types = [
    types.UPDATE_ADMISSION_CASE_STATUS_SUCCESS,
    types.UPDATE_ADMISSION_CASE_STATUS_FAILURE,
  ];

  return api.put(options, data);
};

export const getCheckList = () => {
  const options = {
    url: `v1/case/CheckList`,
  };

  options.types = [
    types.GET_CHECKLIST_LISTING_SUCCESS,
    types.GET_CHECKLIST_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getCheckListTasks = (checklistId) => {
  const options = {
    url: `v1/task/CheckList/${checklistId}`,
  };

  options.types = [
    types.GET_CHECKLIST_TASKS_SUCCESS,
    types.GET_CHECKLIST_TASKS_FAILURE,
  ];

  return api.get(options);
};

export const getVisaCaseCheckList = (caseId, caseType) => {
  const options = {
    url: `v1/case/CaseCheckList/${caseId}/${caseType}`,
  };

  options.types = [
    types.GET_VISA_CASE_CHECKLIST_LISTING_SUCCESS,
    types.GET_VISA_CASE_CHECKLIST_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const attachCaseCheckListWithCase = (data) => {
  const options = {
    url: "v1/case/CaseCheckList",
  };
  options.types = [
    types.ADD_CHECKLIST_WITH_CASE_SUCCESS,
    types.ADD_CHECKLIST_WITH_CASE_FAILURE,
  ];

  return api.post(options, data);
};

export const addCaseCheckListTask = (data) => {
  const options = {
    url: "v1/task",
  };
  options.types = [
    types.ADD_CHECKLIST_TASK_SUCCESS,
    types.ADD_CHECKLIST_TASK_FAILURE,
  ];

  return api.post(options, data);
};

export const completeChecklistTask = (data) => {
  const options = {
    url: "v1/task/CompleteTask",
  };
  options.types = [
    types.COMPLETE_CHECKLIST_TASK_SUCCESS,
    types.COMPLETE_CHECKLIST_TASK_FAILURE,
  ];

  return api.put(options, data);
};

export const updateCaseCheckListTask = (data) => {
  const options = {
    url: "v1/task",
  };
  options.types = [
    types.UPDATE_CHECKLIST_TASK_SUCCESS,
    types.UPDATE_CHECKLIST_TASK_FAILURE,
  ];

  return api.put(options, data);
};

// export const removeCmsClient = data => {
//   const options = {
//     url: "v1/client"
//   };
//   options.types = [
//     types.DELETE_CMS_CLIENT_SUCCESS,
//     types.DELETE_CMS_CLIENT_FAILURE
//   ];

//   return api.delete(options, data);
// };
export const removeCmsClientOrAdd = (data) => {
  const options = {
    url: "v1/client/ToggleCMS",
  };
  options.types = [
    types.DELETE_CMS_CLIENT_SUCCESS,
    types.DELETE_CMS_CLIENT_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeCmsCase = (data) => {
  const options = {
    url: "v1/subject/case",
  };
  options.types = [
    types.DELETE_CMS_CASE_SUCCESS,
    types.DELETE_CMS_CASE_FAILURE,
  ];

  return api.delete(options, data);
};
export const removeCmsAdmissionCase = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };
  options.types = [
    types.DELETE_CMS_ADMISSION_CASE_SUCCESS,
    types.DELETE_CMS_ADMISSION_CASE_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeCmsChecklist = (data) => {
  const options = {
    url: "v1/case/CaseCheckList",
  };
  options.types = [
    types.DELETE_CMS_CASE_CHECKLIST_SUCCESS,
    types.DELETE_CMS_CASE_CHECKLIST_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeCmsTasks = (data) => {
  const options = {
    url: "v1/task/Bulk",
  };
  options.types = [
    types.DELETE_CMS_CHECKLIST_TASK_SUCCESS,
    types.DELETE_CMS_CHECKLIST_TASK_FAILURE,
  ];

  return api.delete(options, data);
};

export const addTaskComment = (data) => {
  const options = {
    url: "v1/task/comment",
  };
  options.types = [
    types.ADD_TASK_COMMENT_SUCCESS,
    types.ADD_TASK_COMMENT_FAILURE,
  ];

  return api.post(options, data);
};

export const getTaskComment = (taskId) => {
  const options = {
    url: `v1/task/comment/All/${taskId}`,
  };
  options.types = [
    types.GET_TASK_COMMENT_SUCCESS,
    types.GET_TASK_COMMENT_FAILURE,
  ];

  return api.get(options);
};

export const getClientAllVisaCases = (clientId) => {
  const options = {
    url: `v1/subject/case/CMS/${clientId}`,
  };
  options.types = [types.GET_VISA_CASES_SUCCESS, types.GET_VISA_CASES_FAILURE];

  return api.get(options);
};

export const getClientAdmissionProgram = (clientId) => {
  const options = {
    url: `v1/client/programdetail/All/${clientId}`,
  };

  options.types = [
    types.GET_CLIENT_ADMISSION_PROGRAM_SUCCESS,
    types.GET_CLIENT_ADMISSION_PROGRAM_FAILURE,
  ];

  return api.get(options);
};

export const updateClientActiveStatus = (data) => {
  const options = {
    url: "v1/client/UpdateClientStatus",
  };
  options.types = [
    types.UPDATE_CLIENT_STATUS_SUCCESS,
    types.UPDATE_CLIENT_STATUS_FAILURE,
  ];

  return api.post(options, data);
};
