import * as types from "../Constants";

export const partnerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_PARTNER_PROFILE_SUCCESS:
      if (action.payload) {
        localStorage.setItem(
          "clientprofileid",
          JSON.stringify(action.payload.id)
        );
        sessionStorage.setItem("clientprofileid", action.payload.id);
        sessionStorage.setItem("memberType", "spouse");
        if (action.payload.id !== action.payload.clientId) {
          localStorage.setItem("clientProfileIdMain", action.payload.clientId);
          sessionStorage.setItem(
            "clientProfileIdMain",
            action.payload.clientId
          );
        }
        localStorage.setItem("familyId", action.payload.familyId);
        sessionStorage.setItem("familyId", action.payload.familyId);
      }
      return { ...state, partnerProfileData: action.payload };
    case types.GET_PARTNER_PROFILE_FAILURE:
      return {
        ...state,
        partnerProfileErr: action.payload,
        partnerProfileData: null,
      };

    case types.UPD_PARTNER_PROFILE_SUCCESS:
      return { ...state, updPartnerDataSuccess: action.payload };
    case types.UPD_PARTNER_PROFILE_FAILURE:
      return { ...state, updPartnerErr: action.payload };

    case types.ADD_PARTNER_PROFILE_SUCCESS:
      return { ...state, addPartnerDataSuccess: action.payload };
    case types.ADD_PARTNER_PROFILE_FAILURE:
      return { ...state, addPartnerErr: action.payload };

    default:
      return state;
  }
};
