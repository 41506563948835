import React, { Fragment, useEffect, useState } from "react";
import { Images } from "../../Themes";
import { Button, Collapse, Checkbox, message, Tooltip } from "antd";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import {
  FileAddOutlined,
  BellOutlined,
  DownCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Panel } = Collapse;

const SideArea = ({
  showModal,
  reminderTaskRes,
  onGetReminderTasks,
  onGetAllClients,
  allClientData,

  onRemoveReminderTask,
  getAllUsers,
}) => {
  // const [arrow, setArrow] = useState(false);

  console.log(
    "reminderTaskResreminderTaskResreminderTaskRes2323",
    reminderTaskRes
  );
  useEffect(() => {
    onGetReminderTasks();
  }, [onGetReminderTasks]);

  function onRcheckChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const removeTag = (data) => {
    // setLoading(true);

    const remove = {
      id: data.id,
      delete: true,
    };

    onRemoveReminderTask(remove).then(() => {
      onGetReminderTasks().then(() => {
        // setLoading(false);
        message.success("Successfully Deleted!");
      });
    });
  };

  return (
    <Fragment>
      <div style={{ marginTop: "38px" }}>
        <div className="reminder-btn-main">
          <div className="add-task-btn">
            <Button
              onClick={() => showModal("add-task")}
              icon={<FileAddOutlined />}
            >
              Add Tasks
            </Button>
          </div>
          <div className="reminder-btn">
            <Button
              onClick={() => showModal("add-reminder")}
              icon={<BellOutlined />}
            >
              Add Reminders
            </Button>
          </div>
        </div>

        <div className="sidebar-reminder">
          <Collapse className="reminder-dropdown" defaultActiveKey={["1"]}>
            <Panel
              // extraField={}
              showArrow={false}
              header={
                <div className="d-flex space-between">
                  <div className="d-flex">
                    <span>
                      <img
                        src={Images.bellRed}
                        style={{ width: 15, height: 15 }}
                      />
                    </span>
                    <h4
                      className="reminder-head"
                      style={{ marginLeft: 10, color: "" }}
                    >
                      REMINDERS
                    </h4>
                  </div>
                  <div>
                    {/* <Dropdown extraField={extra} trigger={["click"]}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        Click me <DownCircleOutlined />
                      </a>
                    </Dropdown> */}
                  </div>
                  <div className="d-flex">
                    <span className="">
                      <DownCircleOutlined />
                      {}
                    </span>
                  </div>
                </div>
              }
              key="1"
            >
              <div className="mdtr-rem-row-cont completed-box-lenght">
                <div style={{ display: "flex" }}>
                  <div className="mdtr-org-sm-box" />
                  <div style={{ marginLeft: 10, width: "100%" }}>
                    {reminderTaskRes &&
                      reminderTaskRes.items &&
                      reminderTaskRes.items.length > 0 &&
                      reminderTaskRes.items.map((data, index) => {
                        return (
                          <div
                            className="reminder-border "
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex ">
                              <span className="color-check">
                                <Checkbox onChange={onRcheckChange} />
                              </span>

                              <div>
                                <div>
                                  <span
                                    className="cv-bold-text"
                                    style={{
                                      color: "#1281BC",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.reminderDetail}
                                  </span>
                                </div>

                                <div>
                                  <div style={{ fontFamily: "Poppins" }}>
                                    <span>{data.reminderDescription}</span>
                                  </div>{" "}
                                  <div>
                                    <span
                                      className="cv-bold-text"
                                      style={{
                                        // color: "#1281BC",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.subjectName
                                        ? "For " + data.subjectName + " "
                                        : ""}
                                      Expires On{" "}
                                      {moment(data.reminderDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      className="cv-normal-text"
                                      style={{
                                        fontSize: 10,
                                        fontWeight: "600",
                                        marginLeft: 0,
                                      }}
                                    >
                                      {yyyymmddFormate(data.reminderDate)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                justifyContent: "flex-end",
                              }}
                              className="table-action"
                              onClick={() => showModal("update-reminder", data)}
                            >
                              <Tooltip placement="topLeft" title={`Update`}>
                                <EditOutlined
                                // onClick={() => showModal(record)}
                                />
                              </Tooltip>
                              <Tooltip placement="topLeft" title={`Delete`}>
                                <DeleteOutlined
                                  onClick={() => removeTag(data)}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};

export default SideArea;
