import React, { Fragment, useEffect, useState } from "react";
import { Radio, Collapse } from "antd";

const EmailPopupcontent = ({
  email,
  index,
  onChangeMailOptions,
  nullRadioState,
  radioValueChange,
}) => {
  const [radioValue, setRadioValue] = useState("");

  useEffect(() => {
    // setModelContent(data.content);

    if (nullRadioState) {
      setRadioValue("");
      radioValueChange();
    }
  }, [nullRadioState]);

  return (
    <Fragment>
      {email.address && (
        <div className="imp-emails-row">
          {/* <Checkbox value={email.address} /> */}
          <div style={{ width: 200 }}>
            <span>{email.name}</span>
          </div>
          <div style={{ width: 250 }}>
            <span>{email.address}</span>
          </div>
          <Radio.Group
            onChange={(e) => {
              setRadioValue(e.target.value);

              onChangeMailOptions(e, email, index);
            }}
            // defaultValue="to"
            value={radioValue}
          >
            <Radio value="to">To</Radio>
            <Radio value="cc">CC</Radio>
            <Radio value="bcc">BCC</Radio>
          </Radio.Group>
        </div>
      )}
    </Fragment>
  );
};
export default EmailPopupcontent;
