import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: ["token"]
});

export const AppAccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  authToken: null
});

/* ------------- Reducers ------------- */

// setting state to login
export const dologin = (state, { token }) => state.merge({ authToken: token });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN]: dologin
});

/* ------------- Selectors ------------- */

// Is the current user logged in?
// export const isLoggedIn = (accountState) => accountState.login_status !== null
// export const getToken = (accountState) => accountState.token !== null
