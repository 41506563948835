import React from "react";
import FroalaEditorCom from "../../../../../Containers/FroalaEditorCom";

const CreateEmailEditors = ({ data, handleEditNote }) => {
  return (
    <FroalaEditorCom
      model={data.content}
      onModelChange={(value) => handleEditNote(value, data.id)}
    />
  );
};
export default CreateEmailEditors;
