import React, { Fragment, useState } from "react";
import { Row, message, Col, Form, Button, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { yyyymmddFormate } from "../../../../Common/reUseFunction";
import moment from "moment";
import renderHTML from "react-render-html";
import FroalaEditorCom from "../../../../Containers/FroalaEditorCom";

const regex = /(<([^>]+)>)/gi;
let re = /&nbsp;/g;

let userName = localStorage.getItem("userName");

const FileNotesList = ({
  onGetEmployerFile,
  employerFileRes,
  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  userDataEmp,

  onRemoveEmployerFile,

  item,
  index,

  onUpdateEmployerFile,

  setLoading,
  loading,
  updateFileNoteState,
}) => {
  const [editFileNote, setEditFileNote] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [fileNoteData, setFileNoteData] = useState();

  const removeFileNotes = () => {
    setLoading(true);
    const remove = {
      id: item && item.id,
      delete: true,
    };
    onRemoveEmployerFile(remove)
      .then(() => {
        updateFileNoteState(true, index);
        // onGetEmployerFile(userDataEmp && userDataEmp.id);
        setLoading(false);
        message.success("Successfully Deleted!");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editNotes = (value) => {
    console.log("Thisisdemodata---==========", item);
    setEditFileNote(!editFileNote);
    setEditMode(item.note);
  };

  const handleEditNote = (value) => {
    setFileNoteData(value);
    setEditMode(value);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form:", values);

    setLoading(true);
    const update = {
      id: item && item.id,
      note: fileNoteData && fileNoteData,
      employerId: userDataEmp && userDataEmp.id,
    };

    onUpdateEmployerFile(update)
      .then(() => {
        updateFileNoteState(false, index, fileNoteData);
        // onGetEmployerFile(userDataEmp && userDataEmp.id);
        setLoading(false);
        message.success("Successfully Updated!");
        setEditFileNote(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let html = item.note;
  let regex = /\S+[a-z0-9]@[a-z0-9\.]+/gim;
  let resultArr = html.match(regex);
  if (resultArr && resultArr.length > 0) {
    for (let ind = 0; ind < resultArr.length; ind++) {
      if (resultArr[ind][0] === "<") {
        let filteredValue = resultArr[ind].replace(/</g, "< ");
        html = html.replace(resultArr[ind], filteredValue);
      } else if (resultArr[ind][0] === "<" && resultArr[ind][1] === "/") {
        let filteredValue = resultArr[ind].replace("/", " ");
        html = html.replace(resultArr[ind], filteredValue);
      } else {
        let filteredValue = resultArr[ind].replace(/</g, "< ");
        html = html.replace(resultArr[ind], filteredValue);
      }
    }
  }
  let gap = html;

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <div
            className={
              index % 2 === 0
                ? "file-note-dark margin-top space-between d-flex"
                : "file-note-light margin-top space-between d-flex"
            }
            key={item.id}
          >
            <div style={{ width: "92%" }}>
              <div className="float-left d-flex width-100">
                <p style={{ fontSize: 12, marginRight: "10px" }}>
                  {moment(item.createdDate).format("DD MMM YYYY hh:mm A") + " "}
                  {/* {yyyymmddFormate(item.createdDate)} */}
                </p>
                <p style={{ fontSize: 12, color: "#36b1e4" }}>
                  {item.fullName}
                </p>
              </div>
              <div>{renderHTML(gap)}</div>
            </div>

            <div>
              <div className="table-action">
                <EditOutlined onClick={() => editNotes()} />

                <DeleteOutlined onClick={() => removeFileNotes()} />
              </div>
            </div>
          </div>
          {editFileNote === true && (
            <div>
              <Row>
                <Col span={24}>
                  <div className=" margin-top  letter-froala">
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={editMode && editMode}
                      onModelChange={(value) => handleEditNote(value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ justifyContent: "flex-end", marginTop: "10px" }}>
                <Form.Item className="d-flex">
                  <Button
                    className="button-blue"
                    type="primary"
                    htmlType="submit"
                    disabled={fileNoteData === ""}
                  >
                    UPDATE
                  </Button>
                </Form.Item>
              </Row>
            </div>
          )}
        </Form>
      </Spin>
    </Fragment>
  );
};

export default FileNotesList;
