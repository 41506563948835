import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Table,
  Spin,
  message,
  Button,
  Upload,
  Form,
  Avatar,
  Tooltip,
} from "antd";
import moment from "moment";
import { saveAs } from "file-saver";
import { apiRefresh } from "../../services/api";

function getBase64(img, callback) {
  const reader = new FileReader();

  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//
//   return isJpgOrPng && isLt2M;
// }

const EzmCommonDocs = ({
  onGetDocuments,
  documentRes,

  onAddDocument,

  onRemoveDocument,
  onSetActiveInnerTab,
  onGetDocumentDownload,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadDoc, setUploadDoc] = useState(false);
  const [addData, setAddData] = useState([]);
  const [imageUrl, setImageUrl] = useState({});
  const [imageValues, setImageValues] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetDocuments()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetDocuments]);

  if (documentRes && documentRes.items) {
    var findDocument =
      documentRes &&
      documentRes.items.filter((obj) => obj.companyDocumentTypeId === 1);
  }
  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveDocument(remove).then(() => {
      message.success("Successfully Deleted!");
      onGetDocuments()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      setImageValues(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl) => setLoading(false));
    } else if (info.file.status === "error") {
      message.error("Failed!");
      setLoading(false);
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onHandleChange = (info) => {
    // if (
    //   fileList &&
    //   fileList[fileList.length - 1] &&
    //   fileList[fileList.length - 1].size > 50 * 1024 * 1024
    // ) {
    //   message.warning("File size should not be greater than 50MB");
    // } else {

    if (info.fileList.length > 0) {
      setIsImage(true);
      setImageValues(info.file.originFileObj || info.file);
      setFileList(info.fileList);
      // getBase64(info.file.originFileObj, (imageUrl) => {
      //
      //   setImageUrl(imageUrl);
      //   setLoading(false);
      // });
    } else {
      setIsImage(false);
    }

    // }
  };

  const onFinish = async (values) => {
    var count = 0;
    if (fileList.length > 10) {
      message.error("10 files at a time can be uploaded.");
    } else if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile = fileList[i].originFileObj;
        let extensionArr = selectedFile.name.split(".");
        let fileExt = extensionArr[extensionArr.length - 1];
        if (
          fileExt == "mp3" ||
          fileExt == "mp4" ||
          fileExt == "avi" ||
          fileExt == "mov" ||
          fileExt == "wmv"
        ) {
          message.error(fileExt + " type is not allowed");
        } else {
          setLoading(true);
          let formData = new FormData();
          formData.append("file", selectedFile);

          const JsonData = {
            CompanyDocumentTypeId: 1,
            Name: selectedFile && selectedFile.name,
            SizeInKB: selectedFile && selectedFile.size,
            Extension: selectedFile && selectedFile.type,
            BlobUrl: "",
            BlobFileName: "",
          };
          formData.append("Data", JSON.stringify(JsonData));
          await onAddDocument(formData)
            .then(() => {
              count++;
              if (count == fileList.length) {
                setLoading(false);
                message.success("Uploaded Successfully!");
                setImageValues({});
                onGetDocuments();
                setIsImage(false);
                setFileList([]);
              }
            })
            .catch(() => {
              message.error("Failed!");
              setLoading(false);
            });
        }
      }
    }
  };

  const [form] = Form.useForm();
  // const onFinish = (values) => {
  //   console.log("valuesvaluesvalues", values);
  //   console.log("image value", imageValues);
  //   if (imageValues) {
  //     setLoading(true);
  //     const JsonData = {
  //       CompanyDocumentTypeId: 1,
  //       SizeInKB: imageValues && imageValues.size,
  //       Name: imageValues && imageValues.name,
  //       BlobUrl: "",
  //       BlobFileName: "",
  //       Extension: imageValues && imageValues.type,
  //     };

  //     var formdata = new FormData();
  //     formdata.append("file", imageValues && imageValues);
  //     formdata.append("Data", JSON.stringify(JsonData));

  //     onAddDocument(formdata)
  //       .then(() => {
  //         setLoading(false);
  //         message.success("Uploaded Successfully!");
  //         setImageValues({});
  //         onGetDocuments();
  //         setIsImage(false);
  //       })
  //       .catch((err) => {
  //         message.error("Failed!");
  //         setLoading(false);
  //       });
  //   } else {
  //     message.warning("Upload file first!");
  //   }
  // };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    // },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return <span>{moment(text).format("DD/MM/YYYY hh:mm")}</span>;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <DownloadOutlined
              onClick={() => {
                onDownloadDoc(record);
              }}
            />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onDownloadDoc = (value) => {
    const docTypeOpt = {
      url: `v1/company/document/GetDocumentBytes/${value.id}/${value.blobFileName}`,
    };

    docTypeOpt.types = [
      "DOCUMENT_DOWNLOAD_SUCCESS",
      "DOCUMENT_DOWNLOAD_FAILURE",
    ];

    setLoading(true);
    apiRefresh
      .getFile(docTypeOpt)
      .then((res) => {
        downlodDocument(res, value);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.name}`);
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.creativeCommons}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">EZM Common Docs</span>
                <Tooltip
                  placement="topLeft"
                  title={`Here you can add some common files and attachments so you can easily access them through Ezymigrate.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="client-section">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="d-flex space-between">
                    <div>
                      {!uploadDoc && uploadDoc === false ? (
                        <Button
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                          className="button-blue"
                        >
                          ADD NEW DOCUMENT
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                          className="button-blue"
                        >
                          HIDE
                        </Button>
                      )}
                    </div>
                  </div>

                  {uploadDoc && uploadDoc === true && (
                    <div className="file-uploader">
                      <Form onFinish={onFinish} form={form}>
                        <Form.Item name="username">
                          <Upload
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={onHandleChange}
                            multiple
                          >
                            {fileList && fileList.length < 10 ? (
                              <div className="import-file-button-sec">
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                              </div>
                            ) : null}
                            {/* {uploadButton} */}
                          </Upload>
                          <Button
                            htmlType="submit"
                            type="primary"
                            className={imageValues && "button-blue"}
                            disabled={!isImage}
                            style={{ marginTop: 20 }}
                          >
                            Upload
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={findDocument && findDocument}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EzmCommonDocs;
