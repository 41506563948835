import React from "react";
import "./ReportsStyles.css";
import { Select, DatePicker, Table, Tag, Space, Spin, Checkbox } from "antd";

import { CSVLink } from "react-csv";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";
import VisaReportChild from "../Components/Reports/VisaReport";
import { apiRefresh } from "../services/api";
import { saveAs } from "file-saver";
import { JobSectors } from "../Components/JobSectors";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const headOption = [
  { tabName: "Reports", linkName: "/reports" },
  { tabName: "Visa Reports", linkName: "/visa-report" },
  // // { tabName: "Case Management", linkName: "/" },
  // { tabName: "Potential Clients", linkName: "/potential-clients-report" },
  { tabName: "Time Tracking", linkName: "/time-tracking-report" },
  // { tabName: "Employer Report", linkName: "/employer-reports" },
];
const dateFormat = "DD/MM/YYYY";

const headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Age", key: "age" },
];

const data = [
  {
    firstName: "Warren",
    lastName: "Morrow",
    email: "sokyt@mailinator.com",
    age: "36",
  },
  {
    firstName: "Gwendolyn",
    lastName: "Galloway",
    email: "weciz@mailinator.com",
    age: "76",
  },
  {
    firstName: "Astra",
    lastName: "Wyatt",
    email: "quvyn@mailinator.com",
    age: "57",
  },
  {
    firstName: "Jasmine",
    lastName: "Wong",
    email: "toxazoc@mailinator.com",
    age: "42",
  },
  {
    firstName: "Brooke",
    lastName: "Mcconnell",
    email: "vyry@mailinator.com",
    age: "56",
  },
  {
    firstName: "Christen",
    lastName: "Haney",
    email: "pagevolal@mailinator.com",
    age: "23",
  },
  {
    firstName: "Tate",
    lastName: "Vega",
    email: "dycubo@mailinator.com",
    age: "87",
  },
  {
    firstName: "Amber",
    lastName: "Brady",
    email: "vyconixy@mailinator.com",
    age: "78",
  },
  {
    firstName: "Philip",
    lastName: "Whitfield",
    email: "velyfi@mailinator.com",
    age: "22",
  },
  {
    firstName: "Kitra",
    lastName: "Hammond",
    email: "fiwiloqu@mailinator.com",
    age: "35",
  },
  {
    firstName: "Charity",
    lastName: "Mathews",
    email: "fubigonero@mailinator.com",
    age: "63",
  },
];

const csvReport = {
  data: data,
  headers: headers,
  filename: "Clue_Mediator_Report.csv",
};

class VisaReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMainTab: "",
      activeTab: "",
      extend: false,
      dateFrom: "",
      dateTo: "",
      processingPerson: [],
      loadReports: false,
      pageNumber: 1,
      pageSize: 20,
      processingIds: "",
      visaTypeId: null,
      caseStatusId: [],
      ausVisaTypeId: null,
      ausSubVisaId: null,
      destination: null,
      liaId: null,
      allClients: null,
      statusesArray: [],
      jobSectorsArray: [],
      selectedJobSector: [],
      activeInactive: "All",
      checkedInclude: false,
      selectedCountry: null,
      loadCountries: true,
    };
    this.props.onGetTeamMember();
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    this.props.onGetVisaStatus();
    // this.props.onGetVisaTypeByCountry("28");
    this.props.onGetDestination();
    this.props.onGetVisaStatusWithHide();
    this.props
      .onGetBVisaCountries()
      .then(() => {
        this.setState({ loadCountries: false });
      })
      .catch((err) => {
        this.setState({ loadCountries: false });
      });
  }

  componentDidMount() {
    if (JobSectors) {
      let jobSectorOpt = [];
      for (var i = 0; i < JobSectors.length; i++) {
        jobSectorOpt.push(
          <Option value={JobSectors[i].id}>{JobSectors[i].label}</Option>
        );
      }
      this.setState({ jobSectorsArray: jobSectorOpt });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.brnchVisaCountriesData != this.props.brnchVisaCountriesData) {
      var findCountry = this.props.brnchVisaCountriesData.find(
        (obj) => obj.countryId == localStorage.getItem("defaultCountryId")
      );

      if (findCountry) {
        this.setState({ selectedCountry: findCountry.countryId }, () => {
          this.props.onGetVisaTypeByCountry(findCountry.countryId);
        });
      } else {
        this.setState(
          {
            selectedCountry:
              this.props.brnchVisaCountriesData &&
              this.props.brnchVisaCountriesData.length > 0 &&
              this.props.brnchVisaCountriesData[0].countryId,
          },
          () => {
            this.props.onGetVisaTypeByCountry(this.state.selectedCountry);
          }
        );
      }
    }
  }

  onChangeDateFrom = (date, dateString) => {
    this.setState({ dateFrom: date, pageNumber: 1 });
  };

  onChangeDateTo = (date, dateString) => {
    this.setState({ dateTo: date, pageNumber: 1 });
  };

  changePageSize = (value) => {
    this.setState({ pageSize: value });
  };

  onVisaReport = (paginationData) => {
    var processingIds = "";
    var statuses = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    // let findJobSector = null;
    // if (this.state.selectedJobSector) {
    //   findJobSector = JobSectors.find(
    //     (obj) => obj.id == this.state.selectedJobSector
    //   );
    // }
    if (this.state.caseStatusId.length > 0) {
      for (var i = 0; i < this.state.caseStatusId.length; i++) {
        if (i === 0) {
          statuses = this.state.caseStatusId[i];
        } else {
          statuses += "," + this.state.caseStatusId[i];
        }
      }
    }
    this.setState({
      processingIds: processingIds,
      pageNumber:
        (paginationData && paginationData.pageNumber) || this.state.pageNumber,
      pageSize:
        (paginationData && paginationData.pageSize) || this.state.pageSize,
    });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize:
        (paginationData && paginationData.pageSize) || this.state.pageSize,
      pageNumber:
        (paginationData && paginationData.pageNumber) || this.state.pageNumber,
      visaTypeId: this.state.visaTypeId || 0,
      caseStatusId: statuses.toString(),
      subId: this.ausSubVisaId || 0,
      destination: this.state.destination || "",
      liaId: this.state.liaId || "00000000-0000-0000-0000-000000000000",
      countryId: this.state.selectedCountry
        ? parseInt(this.state.selectedCountry)
        : 0,
      statusList: this.state.statusesArray || [],
      isFamily: this.state.checkedInclude,
      jobSector: this.state.selectedJobSector
        ? this.state.selectedJobSector.toString()
        : "",
      active: this.state.activeInactive || "",
    };
    this.props
      .onVisaReport(data)
      .then(() => {
        this.setState({ loadReports: false });
      })
      .catch(() => this.setState({ loadReports: false }));
  };

  onExport = () => {
    var processingIds = "";
    var statuses = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    // let jobSector = "";
    // if (
    //   this.state.selectedJobSector &&
    //   this.state.selectedJobSector.length > 0
    // ) {
    //   for (var i = 0; i < this.state.selectedJobSector.length; i++) {
    //     if (i == 0) {
    //       jobSector += this.state.selectedJobSector[i];
    //     } else {
    //       jobSector += "," + this.state.selectedJobSector[i];
    //     }
    //   }
    // }
    if (this.state.caseStatusId.length > 0) {
      for (var i = 0; i < this.state.caseStatusId.length; i++) {
        if (i === 0) {
          statuses = this.state.caseStatusId[i].toString();
        } else {
          statuses += "," + this.state.caseStatusId[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
      visaTypeId: this.state.visaTypeId || 0,
      caseStatusId: statuses,
      subId: this.ausSubVisaId || 0,
      destination: this.state.destination || "",
      liaId: this.state.liaId || "00000000-0000-0000-0000-000000000000",
      countryId: this.state.selectedCountry
        ? parseInt(this.state.selectedCountry)
        : 0,
      statusList: this.state.statusesArray || [],
      isFamily: this.state.checkedInclude,
      jobSector: this.state.selectedJobSector
        ? this.state.selectedJobSector.toString()
        : "",
      active: this.state.activeInactive || "",
    };
    const visaReportOpt = {
      url: `v1/report/VisaExport`,
    };

    visaReportOpt.types = [
      "EXPORT_VISA_REPORT_SUCCESS",
      "EXPORT_VISA_REPORT_FAILURE",
    ];
    apiRefresh
      .postWithFileRes(visaReportOpt, data)
      .then((res) => {
        this.setState({ loadReports: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "VisaReport.xlsx");
      })
      .catch(() => this.setState({ loadReports: false }));
  };

  setDefault = () => {
    this.setState(
      {
        dateFrom: "",
        dateTo: "",
        visaTypeId: "",
        caseStatusId: [],
        activeInactive: "All",
        liaId: null,
        selectedJobSector: "",
        processingPerson: [],
        checkedInclude: false,
      },
      () => {
        this.onVisaReport();
      }
    );
  };

  onChangeStatus = (value) => {
    this.setState({ statusesArray: value, pageNumber: 1 });
  };

  render() {
    const {
      selectedOption,
      dateFrom,
      dateTo,
      visaTypeId,
      caseStatusId,
      ausVisaTypeId,
      ausSubVisaId,
      destination,
      liaId,
      processingPerson,
      allClients,
      pageSize,
      loadReports,
      selectedJobSector,
      activeInactive,
      checkedInclude,
      selectedCountry,
      loadCountries,
    } = this.state;
    const {
      teamMembers,
      visaTypeData,
      visaStatusData,
      countryVisaTypeData,
      visaCategoryData,
      destinationRes,
      allClientData,
      visaReportRes,
      visaStatusHideData,
      brnchVisaCountriesData,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadReports || loadCountries}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs data={headOption} activeTab="Visa Reports" />

              <div className="report-container">
                <div>
                  <div>
                    <div className="pciq-top-div">
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D" }}
                      >
                        VISA REPORT FILTERS
                      </span>
                    </div>

                    <div className="ca-gray-cont" style={{ border: 0 }}>
                      <div style={{ display: "flex", marginLeft: 20 }}>
                        <div style={{ display: "flex", width: "47%" }}>
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateFrom}
                              value={dateFrom}
                              format={dateFormat}
                              placeholder="From Date"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            width: "47%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateTo}
                              value={dateTo}
                              format={dateFormat}
                              placeholder="To Date"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginLeft: 20,
                          marginTop: 10,
                        }}
                      >
                        <div
                          className="pc-select-width"
                          style={{ marginLeft: 0, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Select Visa Type"
                              value={selectedCountry}
                              onChange={(value) => {
                                this.setState({
                                  selectedCountry: value,
                                  pageNumber: 1,
                                });
                                this.props.onGetVisaTypeByCountry(value);
                              }}
                            >
                              <Option value={0}>All</Option>
                              {brnchVisaCountriesData &&
                                brnchVisaCountriesData.map((country, index) => (
                                  <Option value={country.countryId}>
                                    {country.countryName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div
                          className="pc-select-width"
                          style={{ marginLeft: 20, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              placeholder="Select Visa Type"
                              value={visaTypeId}
                              onChange={(value) => {
                                this.setState({
                                  visaTypeId: value,
                                  pageNumber: 1,
                                });
                              }}
                            >
                              {countryVisaTypeData &&
                                countryVisaTypeData.items.map((visa, index) => (
                                  <Option value={visa.id}>
                                    {visa.visaTypeName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>

                        <div
                          className="pc-select-width multi-select-option"
                          style={{ marginLeft: 15, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Select Visa Status"
                              mode="multiple"
                              value={caseStatusId}
                              onChange={(value) =>
                                this.setState({
                                  caseStatusId: value,
                                  pageNumber: 1,
                                })
                              }
                            >
                              {visaStatusData &&
                                visaStatusData.items.map(
                                  (visaStatus, index) => (
                                    <Option value={visaStatus.id}>
                                      {visaStatus.name}
                                    </Option>
                                  )
                                )}
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginLeft: 20,
                          marginTop: 10,
                        }}
                      >
                        <div
                          className="pc-select-width"
                          style={{ marginLeft: 0, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              placeholder="All Clients"
                              value={activeInactive}
                              onChange={(value) =>
                                this.setState({
                                  activeInactive: value,
                                  pageNumber: 1,
                                })
                              }
                            >
                              <Option value="All">All Clients</Option>
                              <Option value="true">Active</Option>
                              <Option value="false">Inactive</Option>
                            </Select>
                          </div>
                        </div>

                        <div
                          className="pc-select-width"
                          style={{ marginLeft: 15, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              placeholder="LIA"
                              value={liaId}
                              onChange={(value) =>
                                this.setState({ liaId: value, pageNumber: 1 })
                              }
                            >
                              {teamMembers &&
                                teamMembers.users.map((user, userInd) => (
                                  <Option value={user.id}>
                                    {user.fullName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                        <div
                          className="pc-select-width multi-select-option"
                          style={{ marginLeft: 15, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              placeholder="Job Sector"
                              // mode="multiple"
                              value={selectedJobSector}
                              onChange={(value) =>
                                this.setState({
                                  selectedJobSector: value,
                                  pageNumber: 1,
                                })
                              }
                            >
                              <Option value="">All</Option>
                              {this.state.jobSectorsArray}
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginLeft: 20,
                          marginTop: 10,
                        }}
                      >
                        <div
                          className="pc-select-width multi-select-option"
                          style={{ marginLeft: 0, width: "31.65%" }}
                        >
                          <div>
                            <Select
                              placeholder="Processing Person"
                              mode="multiple"
                              value={processingPerson}
                              onChange={(value) =>
                                this.setState({
                                  processingPerson: value,
                                  pageNumber: 1,
                                })
                              }
                            >
                              {teamMembers &&
                                teamMembers.users.map((user, userInd) => (
                                  <Option value={user.id}>
                                    {user.fullName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>

                        <div
                          className="pc-select-width multi-select-option"
                          style={{ marginLeft: 25, width: "31.65%" }}
                        >
                          <div>
                            <Checkbox
                              checked={checkedInclude}
                              style={{ color: "#55626c" }}
                              onChange={(e) => {
                                this.setState({
                                  checkedInclude: e.target.checked,
                                  pageNumber: 1,
                                });
                              }}
                            >
                              Include All Family
                            </Checkbox>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginRight: 15,
                            // width: "31.65%",
                          }}
                        >
                          {visaReportRes && visaReportRes.list.length > 0 && (
                            <div
                              className="pc-btn-cont"
                              style={{
                                paddingTop: 5,
                                paddingBottom: 5,
                                borderRadius: 3,
                                cursor: "pointer",
                                marginRight: 15,
                              }}
                              onClick={this.onExport}
                            >
                              <span className="pc-btn-text">EXPORT</span>
                            </div>
                          )}
                          <div
                            className="pc-btn-cont"
                            style={{
                              paddingTop: 5,
                              paddingBottom: 5,
                              borderRadius: 3,
                              cursor: "pointer",
                              marginRight: 15,
                            }}
                            onClick={this.onVisaReport}
                          >
                            <span className="pc-btn-text">SHOW</span>
                          </div>
                          <div
                            className="pc-btn-cont"
                            style={{
                              paddingTop: 5,
                              paddingBottom: 5,
                              borderRadius: 3,
                              cursor: "pointer",
                            }}
                            onClick={this.setDefault}
                          >
                            <span className="pc-btn-text">CLEAR</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: 20,
                            marginTop: 10,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ===== Design from potential client's client source report Screen ===== */}
              {visaReportRes && visaReportRes.list.length > 0 && (
                <div
                  className="report-container"
                  style={{ paddingTop: 30, paddingBottom: 30 }}
                >
                  {/* <div className="pciq-top-div">
                <span className="pc-top-div-text" style={{ color: "#0A3C5D" }}>
                  VISA DETAIL
                </span>
              </div> */}

                  {/* <div className="pc-text-field-row" style={{ marginBottom: 20 }}>
                <div>
                  <span className="cv-normal-text">Show</span>
                </div>
                <div style={{ width: 100, marginLeft: 15 }}>
                  <Select value={pageSize} onChange={this.changePageSize}>
                    <Option value="10">10</Option>
                    <Option value="25">25</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                    <Option value="500">500</Option>
                  </Select>
                </div>

                <div>
                  <span className="cv-normal-text">entries</span>
                </div>
              </div> */}

                  <VisaReportChild
                    fileNotesReportRes={visaReportRes && visaReportRes.list}
                    displayText={"Visa Report"}
                    reportsCount={visaReportRes && visaReportRes.count}
                    onFileNotesReport={this.onVisaReport}
                    requestData={this.state}
                    visaStatusHideData={visaStatusHideData}
                    onChangeStatus={this.onChangeStatus}
                  />
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default VisaReport;
