import React, { useEffect, Fragment, useState } from "react";
import {
  PlusCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Images } from "./../../../Themes";
import { apiRefresh } from "../../../services/api";
import * as types from "../../../store/Constants";
import {
  Table,
  Spin,
  Button,
  Menu,
  Dropdown,
  Input,
  Form,
  Modal,
  message,
  Select,
  Tooltip,
  Row,
  InputNumber,
  Space,
} from "antd";
import { yyyymmddFormate } from "./../../../Common/reUseFunction";
import { saveAs } from "file-saver";
import "../folder.css";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import activityData from "../../../Components/ClientActivity/ActivityData";
import moment from "moment";

const { Option } = Select;
let fields = [];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

var userId = localStorage.getItem("userId");

let clientprofileid = sessionStorage.getItem("clientprofileid");

const EmployerDocuments = ({
  onGetEmployerDocument,
  employerDocumentRes,

  onRemoveEmployerDocument,

  showModal,

  clientProfileData,

  onGetClientProfile,

  onGetDocumentDownload,
  downloadDocRes,

  onGetDocumentPdf,
  documentPdfRes,

  onUpdateEmployerDocument,

  onFilterData,
  documentFilterRes,
  onAddFilteredType,
  UpdateInChild,
  ShowButtons,
  onCaseData,
  folderDocType,
  profileData,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
  const [selectedRowKeys3, setSelectedRowKeys3] = useState([]);

  const [docArray, setDocArray] = useState([]);
  const [mergeArray, setMergeArray] = useState([]);
  const [docArrayForFilter, setdocArrayForFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [updateId, setUpdateId] = useState(0);
  const [Isfiltered, setIsfiltered] = useState(false);
  const [valueId, setValueId] = useState("");
  const [bulkTypeId, setBulkTypeId] = useState(-1);

  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal3Visible, setModal3Visible] = useState(false);
  const [modal4Visible, setModal4Visible] = useState(false);
  const [modal5Visible, setModal5Visible] = useState(false);
  const [modal6Visible, setModal6Visible] = useState(false);
  const [modal7Visible, setModal7Visible] = useState(false);
  const [modal8Visible, setModal8Visible] = useState(false);

  const [onDelete, setOnDelete] = useState("");
  const [onVisaLink, setOnVisaLink] = useState("");
  const [onSplitPDF, setOnSplitPDF] = useState("");

  const [onSelectType, setOnSelectType] = useState("");
  const [docType, setDoctype] = useState(0);
  const [CaseId, setCaseId] = useState(0);
  const [docTypeId, setdocTypeId] = useState(folderDocType);
  const [ppf, setPpf] = useState("");
  const [form] = Form.useForm();
  const BackToDocClick = () => {
    UpdateInChild();
  };
  const MoveDocuments = () => {
    if (selectedRowKeys1.length == 0) {
      message.error("No Record Selected!");
      return;
    }
    setModal6Visible(true);
  };

  useEffect(() => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    setLoading(true);

    var data = new Object();
    data.id = clientprofileid;
    data.docType = docTypeId;
    data.CaseId = CaseId;
    data.IsVisa = false;
    if (CaseId != 0) {
      data.IsVisa = true;
    }

    onGetEmployerDocument(data)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetEmployerDocument, docTypeId, CaseId]);

  useEffect(() => {
    if (
      employerDocumentRes &&
      employerDocumentRes != docArray &&
      !Isfiltered &&
      employerDocumentRes.items
    ) {
      employerDocumentRes.items.map((data, index) => {
        if (employerDocumentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });

      setDocArray(employerDocumentRes.items);
    } else if (employerDocumentRes && employerDocumentRes.items) {
      employerDocumentRes.items.map((data, index) => {
        if (employerDocumentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });

      setDocArray(employerDocumentRes.items);
    } else {
      setDocArray([]);
    }
  }, [employerDocumentRes]);

  function handleMenuClick(e) {
    console.log("click", e.key);
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    if (
      selectedRowKeys1.length == 0 &&
      e.key != 8 &&
      e.key != 9 &&
      e.key != 10
    ) {
      message.error("No Record Selected!");
      return;
    }

    if (e.key == 3) {
      if (selectedRowKeys1.length <= 1) {
        message.error("Please select at least 2 records");
        return;
      }
      let ids = [];
      var haltAPI = false;
      selectedRowKeys1.map((data, index) => {
        if (data.docuementExtension != "pdf") {
          message.error("Only PDF can be merged");
          haltAPI = true;
          return;
        }
        data.chosen = true;
        ids.push(data.id);
      });
      if (!haltAPI) {
        setMergeArray(selectedRowKeys1);
        setSelectedRowKeys3(selectedRowKeys1);
        setModal8Visible(true);
      }
    }
    if (e.key == 1) {
      setLoading(true);
      selectedRowKeys1.map((data, index) => {
        const remove = {
          id: data && data.id,
          delete: true,
        };
        onRemoveEmployerDocument(remove);
      });
      setTimeout(() => {
        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });

        setLoading(false);
        message.success("Successfully Deleted!");
      }, 2000);
    }
    if (e.key == 2) {
      selectedRowKeys1.map((data, index) => {
        onGetDocumentDownload(data)
          .then((res) => {
            downlodDocument(res.payload, data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      });
    }
    if (e.key == 4 || e.key == 5 || e.key == 6 || e.key == 7) {
      var dataArray = [];
      selectedRowKeys1.map((data, index) => {
        var item = new Object();
        item.id = data.id;
        item.isAgent = data.isAgent;
        item.isClient = data.isClient;
        if (e.key == 4) {
          item.isAgent = true;
        }
        if (e.key == 5) {
          item.isAgent = false;
        }
        if (e.key == 6) {
          item.isClient = true;
        }
        if (e.key == 7) {
          item.isClient = false;
        }

        dataArray.push(item);
      });
      const docTypeOpt = {
        url: `v1/document/UpdateDocumentPermission`,
      };

      docTypeOpt.types = [
        types.GET_DOCUMENT_TYPE_SUCCESS,
        types.GET_DOCUMENT_TYPE_FAILURE,
      ];

      setLoading(true);
      apiRefresh.post(docTypeOpt, dataArray);
      setTimeout(() => {
        let clientprofileid = sessionStorage.getItem("clientprofileid");

        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });
        setLoading(false);
        message.success("Successfully Updated!");
      }, 2000);
    }
    if (e.key == 8) {
      if (docArrayForFilter.length == 0) {
        setdocArrayForFilter(docArray);
        var filteredList = docArray.filter(
          (x) => x.docuementExtension == "pdf"
        );
        setDocArray(filteredList);
        setIsfiltered(true);
      } else {
        var filteredList = docArrayForFilter.filter(
          (x) => x.docuementExtension == "pdf"
        );
        setDocArray(filteredList);
        setIsfiltered(true);
      }
    }
    if (e.key == 9) {
      if (docArrayForFilter.length == 0) {
        setdocArrayForFilter(docArray);
        var filteredList = docArray.filter(
          (value) =>
            value.docuementExtension == "png" ||
            value.docuementExtension == "jpg" ||
            value.docuementExtension == "jpeg" ||
            value.docuementExtension == "gif" ||
            value.docuementExtension == "svg"
        );
        setDocArray(filteredList);
        setIsfiltered(true);
      } else {
        var filteredList = docArrayForFilter.filter(
          (value) =>
            value.docuementExtension == "png" ||
            value.docuementExtension == "jpg" ||
            value.docuementExtension == "jpeg" ||
            value.docuementExtension == "gif" ||
            value.docuementExtension == "svg"
        );
        setDocArray(filteredList);
        setIsfiltered(true);
      }
    }
    if (e.key == 10) {
      setdocArrayForFilter([]);
      if (employerDocumentRes && employerDocumentRes.items) {
        employerDocumentRes.items.map((data, index) => {
          if (employerDocumentRes) {
            data.index = index;
            data.key = `${index + 1}`;
            data.chosen = false;
          }
        });

        setDocArray(employerDocumentRes.items);
      } else {
        setDocArray([]);
      }
      setIsfiltered(false);
    }
    if (e.key == 11) {
      setModal6Visible(true);
    }
    if (e.key == 14) {
      setModal7Visible(true);
    }
    if (e.key == 12) {
      selectedRowKeys1.map((value, index) => {
        if (
          value.docuementExtension == "jpg" ||
          value.docuementExtension == "png" ||
          value.docuementExtension == "jpeg" ||
          value.docuementExtension == "gif" ||
          value.docuementExtension == "svg"
        ) {
          setLoading(true);
          let selectedBranchId = localStorage.getItem("selectedBranchId");
          const docTypeOpt = {
            url: `v1/document/ConvertToPDF/${value.id}`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];

          apiRefresh
            .get(docTypeOpt)
            .then((res) => {
              let clientprofileid = sessionStorage.getItem("clientprofileid");
              var data = new Object();
              data.id = clientprofileid;
              data.docType = docTypeId;
              data.CaseId = CaseId;
              data.IsVisa = false;
              if (CaseId != 0) {
                data.IsVisa = true;
              }
              onGetEmployerDocument(data)
                .then((res) => {
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            })
            .catch((err) => {
              setLoading(false);
            });
        } else {
          message.error("Invalid file extension");
        }
      });
    }
  }

  const confirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value);
  };

  const ConvertToPDF = (value) => {
    setLoading(true);

    let selectedBranchId = localStorage.getItem("selectedBranchId");
    const docTypeOpt = {
      url: `v1/document/ConvertToPDF/${value.id}`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh
      .get(docTypeOpt)
      .then((res) => {
        setLoading(false);
        let clientprofileid = sessionStorage.getItem("clientprofileid");

        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const SendSplit = (values) => {
    if (values.ppf == null && values.sights == null) {
      message.error("Please set parameters to split");
      return false;
    }
    var data = new Object();
    data.id = onSplitPDF.id;
    data.branchId = localStorage.getItem("selectedBranchId");
    if (values.ppf) {
      data.pagePerFile = parseInt(values.ppf);
    } else {
      data.pagePerFile = parseInt(0);
    }
    data.range = values.sights;
    if (data.range == null) {
      data.range = [];
    }

    const docTypeOpt = {
      url: `v1/document/SplitDoc`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    setLoading(true);
    apiRefresh
      .post(docTypeOpt, data)
      .then((res) => {
        setLoading(false);
        message.success("Success");
        let clientprofileid = sessionStorage.getItem("clientprofileid");

        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });
        setModal5Visible(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data);
      });
  };
  const SplitPDF = (value) => {
    setModal5Visible(!modal5Visible);
    setOnSplitPDF(value);
  };

  const linkVisa = (value) => {
    setModal4Visible(!modal4Visible);
    setOnVisaLink(value);
  };
  const removeTag = () => {
    let userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    setLoading(true);
    const remove = {
      id: onDelete && onDelete.id,
      delete: true,
    };
    onRemoveEmployerDocument(remove).then(() => {
      setTimeout(() => {
        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });

        let userName = localStorage.getItem("userName");
        // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              " Document title " +
              onDelete.title +
              " deleted for client by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Documents",
            invoiceId: "0",
          };
          activityData(myData);
        }

        setLoading(false);
        setModal1Visible(false);
        message.success("Successfully Deleted!");
      }, 3000);
    });
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="2">Download Selected</Menu.Item>
      <Menu.Item key="3">Merge Selected</Menu.Item>
      {/*<Menu.Item key="4">Show Selected To Agents</Menu.Item>*/}
      {/*<Menu.Item key="5">Hide Selected From Agents</Menu.Item>*/}
      <Menu.Item key="6">Show Selected To Client</Menu.Item>
      <Menu.Item key="7">Hide Selected From Client</Menu.Item>
      <Menu.Item key="11">Change Document(s) Type</Menu.Item>
      <Menu.Item key="12">Convert to PDF</Menu.Item>
      <Menu.Item key="8">Show PDF</Menu.Item>
      <Menu.Item key="9">Show Images</Menu.Item>
      <Menu.Item key="10">Show All</Menu.Item>
      <Menu.Item key="14">Link Visa</Menu.Item>
      <Menu.Item key="1">Delete Selected File</Menu.Item>
    </Menu>
  );
  const onDownloadDoc = (value) => {
    setLoading(true);

    onGetDocumentDownload(value)
      .then((res) => {
        console.log("document res", res.payload);
        downlodDocument(res.payload, value);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.title}.${value.docuementExtension}`);
  };

  const onDocumentPdf = (value) => {
    setLoading(true);
    onGetDocumentDownload(value)
      .then((res) => {
        setLoading(false);

        if (
          value.docuementExtension == "pdf" ||
          value.docuementExtension == "png" ||
          value.docuementExtension == "jpg" ||
          value.docuementExtension == "jpeg" ||
          value.docuementExtension == "gif" ||
          value.docuementExtension == "svg" ||
          value.docuementExtension == ".pdf" ||
          value.docuementExtension == ".png" ||
          value.docuementExtension == ".jpg" ||
          value.docuementExtension == ".jpeg" ||
          value.docuementExtension == ".gif" ||
          value.docuementExtension == ".svg"
        ) {
          if (
            value.docuementExtension == "pdf" ||
            value.docuementExtension == ".pdf"
          ) {
            const file = new Blob([res.payload], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          } else {
            const blob = new Blob([res.payload], { type: "image/png" });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
          }
        } else {
          let blob = new Blob([res.payload], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${value.title}.${value.docuementExtension}`);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onUpdateTittle = (value, id) => {
    setValue(value);
    setUpdateId(id);
  };

  const updateConfirmation = (record) => {
    setModal2Visible(!modal2Visible);
    setValueId(record);
  };

  const updateOkConfirmation = (value) => {
    setLoading(true);

    if (value == "") {
      value = valueId.title;
    }
    if (updateId != valueId.id) {
      value = valueId.title;
    }
    const data = {
      documentTitle: value && value,
      id: valueId && valueId.id,
    };

    onUpdateEmployerDocument(data)
      .then(() => {
        let clientprofileid = sessionStorage.getItem("clientprofileid");

        var data = new Object();
        data.id = clientprofileid;
        data.docType = docTypeId;
        data.CaseId = CaseId;
        data.IsVisa = false;
        if (CaseId != 0) {
          data.IsVisa = true;
        }
        let userName = localStorage.getItem("userName");
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              " Document title updated to " + value + " by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Documents",
            invoiceId: "0",
          };
          activityData(myData);
        }
        onGetEmployerDocument(data).catch((err) => {
          setLoading(false);
        });
        setLoading(false);
        message.success("Successfully Updated!");
        setModal2Visible(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const LinkVisa = (value) => {
    let a = onVisaLink;
    setLoading(true);
    setModal4Visible(false);

    const docTypeOpt = {
      url: `v1/visa/document`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    var data = new Object();
    var visaDocument = [];
    selectedRowKeys.map((item, index) => {
      var document = new Object();
      document.documentId = onVisaLink.id;
      document.subjectCaseId = item.id;
      visaDocument.push(document);
    });

    data.visaDoucments = visaDocument;
    apiRefresh.post(docTypeOpt, data).then((res) => {});
    setLoading(false);
    setSelectedRowKeys((previousState) => {
      let oldArr = [...previousState];
      oldArr = [];
      return oldArr;
    });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const typeConfirmation = (value, record) => {
    setModal3Visible(!modal3Visible);

    setDoctype(parseInt(value));
    setOnSelectType(record);
  };
  const ChangeDocsType = () => {
    if (bulkTypeId != null) {
      var request = new Object();
      var id = [];
      selectedRowKeys1.map((data, index) => {
        id.push(data.id);
      });
      request.id = id;
      request.documentTypeId = bulkTypeId;
      const docTypeOpt = {
        url: `v1/document/UpdateDocumentTypeBulk`,
      };

      docTypeOpt.types = [
        types.GET_DOCUMENT_TYPE_SUCCESS,
        types.GET_DOCUMENT_TYPE_FAILURE,
      ];
      setLoading(true);
      apiRefresh
        .post(docTypeOpt, request)
        .then((res) => {
          setLoading(false);
          message.success("Document Type Changed");
          setModal6Visible(false);
          let clientprofileid = sessionStorage.getItem("clientprofileid");

          var data = new Object();
          data.id = clientprofileid;
          data.docType = docTypeId;
          data.CaseId = CaseId;
          data.IsVisa = false;
          if (CaseId != 0) {
            data.IsVisa = true;
          }
          onGetEmployerDocument(data).catch((err) => {
            setLoading(false);
          });
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed changing type");
          setModal6Visible(false);
        });
    }
  };
  const LinkBulkVisa = () => {
    setLoading(true);
    setModal7Visible(false);

    var userId = localStorage.getItem("userId");
    var data = new Object();
    var visaDocument = [];
    selectedRowKeys1.map((doc, index) => {
      selectedRowKeys2.map((item, index) => {
        var document = new Object();
        document.documentId = doc.id;
        document.subjectCaseId = item.id;
        visaDocument.push(document);
      });
    });
    data.visaDoucments = visaDocument;
    const docTypeOpt = {
      url: `v1/visa/document`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.post(docTypeOpt, data).then((res) => {
      message.success("Document(s) linked with visa(s)");
    });
    setLoading(false);
  };
  const selectNewType = () => {
    setLoading(true);
    const data = {
      documentTypeId: docType,
      id: onSelectType && onSelectType.id,
    };

    onAddFilteredType(data).then(() => {
      var data = new Object();
      let clientprofileid = sessionStorage.getItem("clientprofileid");

      data.id = clientprofileid;
      data.docType = docTypeId;
      data.CaseId = CaseId;
      data.IsVisa = false;
      if (CaseId != 0) {
        data.IsVisa = true;
      }
      onGetEmployerDocument(data).catch((err) => {
        setLoading(false);
      });
      setModal3Visible(false);
      setLoading(false);
      message.success("Successfully Added!");
    });
  };
  const UpIndex = (record) => {
    var arr = mergeArray;
    var index = arr.findIndex((x) => x.id == record.id);
    if (index > 0) {
      setMergeArray((previousState) => {
        let newArr = [...previousState];
        var selectedArr = selectedRowKeys3;
        var obj = selectedArr.find((x) => x.id == record.id);
        if (obj == null) {
          newArr[index].chosen = false;
        }
        arraymove(newArr, index, index - 1);
        // setSelectedRowKeys3(previousState =>{
        //   return newArr;
        // });
        return newArr;
      });
    }
  };
  const DownIndex = (record) => {
    var arr = mergeArray;
    var index = arr.findIndex((x) => x.id == record.id);
    if (arr.length - 1 > index) {
      setMergeArray((previousState) => {
        let newArr = [...previousState];
        var selectedArr = selectedRowKeys3;
        var obj = selectedArr.find((x) => x.id == record.id);
        if (obj == null) {
          newArr[index].chosen = false;
        }
        arraymove(newArr, index, index + 1);
        // setSelectedRowKeys3(previousState =>{
        //   return newArr;
        // });
        return newArr;
      });
    }
  };
  function arraymove(arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  }

  const MergeBulk = () => {
    setLoading(true);
    if (selectedRowKeys3.length <= 1) {
      message.error("Select at least two documents");
    } else {
      let ids = [];
      mergeArray.map((data, index) => {
        if (selectedRowKeys3.find((x) => x.id == data.id)) {
          ids.push(data.id);
        }
      });
      const docTypeOpt = {
        url: `v1/document/MergeDoc`,
      };

      docTypeOpt.types = [
        types.GET_DOCUMENT_TYPE_SUCCESS,
        types.GET_DOCUMENT_TYPE_FAILURE,
      ];
      var data = new Object();
      data.ids = ids;
      data.branchId = localStorage.getItem("selectedBranchId");
      setLoading(true);
      apiRefresh
        .post(docTypeOpt, data)
        .then((res) => {
          setLoading(false);
          message.success("Documents merged");
          setModal8Visible(false);
          let clientprofileid = sessionStorage.getItem("clientprofileid");
          var data = new Object();
          data.id = clientprofileid;
          data.docType = docTypeId;
          data.CaseId = CaseId;
          data.IsVisa = false;
          if (CaseId != 0) {
            data.IsVisa = true;
          }
          onGetEmployerDocument(data).catch((err) => {
            setLoading(false);
          });
        })
        .catch((err) => {
          setLoading(false);
          setModal8Visible(false);
          message.error("Documents merging failed");
        });
    }
  };
  const visaColumns = [
    {
      title: "Visa",
      dataIndex: "branchVisaTypeName",
      key: "branchVisaTypeName",
    },
  ];

  const mergeColumns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "",
      className: "docNameAction",
      render: (text, record) => {
        return (
          <div style={{ display: "block" }}>
            <div style={{ display: "inline-block" }}>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Download`}>
                  <UpOutlined
                    onClick={() => UpIndex(record)}
                    className="cv-action-icon"
                  />
                </Tooltip>
              </div>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Download`}>
                  <DownOutlined
                    onClick={() => DownIndex(record)}
                    className="cv-action-icon"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: "#",
      key: "index",
      className: "docId",
      render: (text, record, index) => {
        return (
          <div>
            {record.isClient == true && record.isAgent == false ? (
              <div className="yellow_span">{index + 1}</div>
            ) : record.isClient == false && record.isAgent == true ? (
              <div className="green_span">{index + 1}</div>
            ) : record.isClient == true && record.isAgent == true ? (
              <div className="blue_span">{index + 1}</div>
            ) : (
              <div className="white_span">{index + 1}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Document",
      dataIndex: "title",
      className: "docNameTitle",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <p style={{ color: "#1890ff", fontSize: "11px" }}>
              {`${record && record.title}.${record &&
                record.docuementExtension} `}
            </p>
            <p style={{ color: "#1890ff", fontSize: "10px" }}>
              {`${yyyymmddFormate(record && record.createdDate)} | ${record &&
                record.sizeInKB / 1000} KB `}
            </p>
          </div>
        );
      },
    },

    {
      title: "Title",
      dataIndex: "title",
      //width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <Input
            className="docInput"
            placeholder={record && record.title}
            onChange={(e) => onUpdateTittle(e.target.value, record.id)}
          />
        );
      },
    },
    {
      title: "Type",
      dataIndex: "documentTypeId",
      className: "docNameType",
      // sortDirections: ["ascend", "descend"],
      // sorter: (a, b) => a.documentTypeId.localeCompare(b.documentTypeId),
      render: (text, record) => {
        return (
          <div className="selectType">
            <Select
              placeholder="Select"
              onChange={(value) => typeConfirmation(value, record)}
              defaultValue={
                record.documentTypeId == 0 ? -1 : record.documentTypeId
              }
              value={record.documentTypeId}
              dropdownClassName="docOption-width"
            >
              <Option key={0} value={0}>
                Unparented
              </Option>
              {onFilterData &&
                onFilterData.items &&
                onFilterData.items.map((item, index) => (
                  <Option key={item.id} value={item.id}>
                    {" "}
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: "Action",
      className: "docNameAction",
      render: (text, record) => {
        return (
          <div style={{ display: "block" }}>
            <div style={{ display: "inline-block" }}>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Download`}>
                  <img
                    onClick={() => onDownloadDoc(record)}
                    src={Images.download}
                    className="cv-action-icon"
                  />
                </Tooltip>
              </div>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Preview`}>
                  <img
                    onClick={() => onDocumentPdf(record)}
                    src={Images.eyepreview}
                    className="cv-action-icon"
                    style={{ width: 17, height: 15 }}
                  />
                </Tooltip>
              </div>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Update`}>
                  <img
                    onClick={() => updateConfirmation(record)}
                    src={Images.edit}
                    className="cv-action-icon"
                    style={{ height: 15 }}
                  />
                </Tooltip>
              </div>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Delete`}>
                  <img
                    onClick={() => confirmation(record)}
                    src={Images.deletedoc}
                    className="cv-action-icon"
                  />
                </Tooltip>
              </div>
              <div className="cv-action-icons-borderDocType">
                <Tooltip placement="topLeft" title={`Link Visa`}>
                  <img
                    onClick={() => linkVisa(record)}
                    src={Images.linkvisa}
                    className="cv-action-icon"
                  />
                </Tooltip>
              </div>
              {record.docuementExtension == "jpg" ||
              record.docuementExtension == "png" ||
              record.docuementExtension == "jpeg" ||
              record.docuementExtension == "gif" ||
              record.docuementExtension == "svg" ? (
                <div
                  className="cv-action-icons-borderDocType"
                  style={{ marginLeft: 5 }}
                >
                  <Tooltip placement="topLeft" title={`Convert to PDF`}>
                    <img
                      onClick={() => ConvertToPDF(record)}
                      src={Images.ctopdf}
                      className="cv-action-icon"
                    />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
              {record.docuementExtension == "pdf" ? (
                <div
                  className="cv-action-icons-borderDocType"
                  style={{ marginLeft: 5 }}
                >
                  <Tooltip placement="topLeft" title={`Split PDF`}>
                    <img
                      onClick={() => SplitPDF(record)}
                      src={Images.filemerge}
                      className="cv-action-icon"
                    />
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };
  const rowSelection2 = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys2(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection3 = {
    fixed: "right",
    selectedRowKeys: selectedRowKeys3
      .filter((item) => item.chosen)
      .map((item) => item.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys3((previousState) => {
        let oldArr = [...previousState];
        oldArr = selectedRows;
        return oldArr;
      });
    },
  };

  const rowSelection1 = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys1(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const onFinishD = (values) => {
    console.log("Received values of form:", values);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="employer-table border-color-blue">
          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "7px", marginBottom: "26px" }}>
                <Button
                  style={{
                    backgroundColor: "#ff6318",
                    border: 0,
                    zIndex: "2",
                    borderRadius: "5px",
                  }}
                  onClick={() => showModal("add-documents")}
                  icon={<PlusCircleOutlined />}
                  type="primary"
                >
                  Add Document
                </Button>
              </div>
              {ShowButtons ? (
                <div
                  style={{
                    marginRight: "7px",
                    marginBottom: "26px",
                    borderRadius: "5px",
                  }}
                >
                  <Button
                    style={{ border: 0, zIndex: "2" }}
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    onClick={MoveDocuments}
                    className="button-blue"
                  >
                    Move Documents
                  </Button>
                </div>
              ) : (
                <div></div>
              )}

              {ShowButtons ? (
                <div
                  style={{
                    marginRight: "7px",
                    marginBottom: "26px",
                    borderRadius: "5px",
                  }}
                >
                  <Button
                    style={{ border: 0, zIndex: "2" }}
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    onClick={BackToDocClick}
                    className="button-blue"
                  >
                    Back To Documents
                  </Button>
                </div>
              ) : (
                <div></div>
              )}
              <div className="action-text-cont">
                <Dropdown.Button overlay={menu}>ACTIONS</Dropdown.Button>
              </div>
            </div>
          </Row>
          <Row>
            <div
              style={{
                width: "100%",
                marginBottom: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <h5 style={{ display: "flex", alignItems: "center" }}>
                Visible To <div className="yellow_box"></div>Client{" "}
                {/*<div className="green_box"></div> Agent{" "}*/}
                {/*<div className="blue_box"></div> Both*/}
              </h5>
            </div>
          </Row>
          <Row>
            <div
              style={{
                width: "100%",
                marginBottom: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Select
                style={{ width: "150px", marginRight: 10 }}
                placeholder="Select"
                defaultValue="Visa"
                onChange={(value) => {
                  if (value == null) {
                    value = "0";
                  }
                  setCaseId(parseInt(value));
                }}
                allowClear
              >
                <Option Key="0">Visa</Option>
                {onCaseData &&
                  onCaseData.map((item, index) => (
                    <Option key={item.id}> {item.branchVisaTypeName}</Option>
                  ))}
              </Select>
              {!ShowButtons ? (
                <Select
                  style={{ width: "150px" }}
                  placeholder="Select"
                  defaultValue="Document Type"
                  onChange={(value) => {
                    if (value == null) {
                      value = "-1";
                    }
                    setdocTypeId(parseInt(value));
                  }}
                  allowClear
                >
                  <Option Key="-1">Document Type</Option>
                  {onFilterData &&
                    onFilterData.items &&
                    onFilterData.items.map((item, index) => (
                      <Option key={item.id}> {item.name}</Option>
                    ))}
                </Select>
              ) : (
                <div></div>
              )}
            </div>
          </Row>
          <Table
            className="border-3 "
            rowSelection={rowSelection1}
            showCount={true}
            columns={columns}
            dataSource={docArray}
            pagination={false}
          />
        </div>
        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal1Visible}
          onOk={() => removeTag(value)}
          onCancel={() => setModal1Visible(false)}
          Header={false}
        >
          <h4>Are you sure you want to delete this ?</h4>
        </Modal>
        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal2Visible}
          onOk={() => updateOkConfirmation(value)}
          onCancel={() => setModal2Visible(false)}
          Header={false}
        >
          <h4> Are you sure you want to update the title of this document?</h4>
        </Modal>
        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal3Visible}
          onOk={() => selectNewType()}
          onCancel={() => setModal3Visible(false)}
          Header={false}
        >
          <h4 style={{ marginTop: 30 }}>
            Are you sure you want to change document type ?
          </h4>
        </Modal>
        {modal4Visible ? (
          <Modal
            title={false}
            style={{ top: 20 }}
            visible={modal4Visible}
            onOk={() => LinkVisa()}
            onCancel={() => setModal4Visible(false)}
            Header={false}
          >
            <Table
              className="border-3 "
              rowSelection={rowSelection}
              showCount={true}
              columns={visaColumns}
              dataSource={onCaseData}
              pagination={false}
            />
          </Modal>
        ) : null}

        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal5Visible}
          // onOk={() => SendSplit()}
          onCancel={() => setModal5Visible(false)}
          Header={false}
          footer={false}
        >
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={SendSplit}
            autoComplete="off"
          >
            <Form.Item name="ppf" label="Pages per file">
              <InputNumber />
            </Form.Item>
            <Row>Or</Row>
            <Form.List name="sights">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area ||
                          prevValues.sights !== curValues.sights
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="From"
                            name={[field.name, "from"]}
                            fieldKey={[field.fieldKey, "from"]}
                          >
                            <InputNumber />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="To"
                        name={[field.name, "to"]}
                        fieldKey={[field.fieldKey, "to"]}
                      >
                        <InputNumber />
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Row
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal6Visible}
          onOk={() => ChangeDocsType()}
          onCancel={() => setModal6Visible(false)}
          Header={false}
        >
          <Select
            style={{ width: "150px" }}
            placeholder="Select"
            onChange={(value) => setBulkTypeId(value)}
          >
            <Option key={0} value={0}>
              {" "}
              Unparented
            </Option>
            {onFilterData &&
              onFilterData.items &&
              onFilterData.items.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {" "}
                  {item.name}
                </Option>
              ))}
          </Select>
        </Modal>

        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal7Visible}
          onOk={() => LinkBulkVisa()}
          onCancel={() => setModal7Visible(false)}
          Header={false}
        >
          <Table
            className="border-3 "
            rowSelection={rowSelection2}
            showCount={true}
            columns={visaColumns}
            dataSource={onCaseData}
            pagination={false}
          />
        </Modal>

        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal8Visible}
          onOk={() => MergeBulk()}
          onCancel={() => setModal8Visible(false)}
          Header={false}
        >
          <Table
            className="border-3 "
            rowSelection={rowSelection3}
            showCount={true}
            columns={mergeColumns}
            dataSource={mergeArray}
            pagination={false}
          />
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default EmployerDocuments;
