import React, { Component } from "react";
import PropTypes from "prop-types";
import settings from "../../svgs/settings.svg";
import exportImg from "../../svgs/export.svg";
import DashboardClientTopBars from "../../Components/Shared/DashboardClientTopBar";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "./PotentialClientStyle.css";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Select,
  Table,
  Spin,
  Modal,
  DatePicker,
  Popconfirm,
  Input,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  DownOutlined,
  MessageTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
  FileTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
import renderHTML from "react-render-html";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Images } from "../../Themes";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import DashboardPotentialReport from "../../Reports/PotentialClientDashboardReport/PotentialClientDashboardReport";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

let config = {
  key: "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
  // height: "auto",
  // toolbarSticky: false,
  charCounterCount: false,
  // placeholderText: "Edit Your Content Here!",
  // imageUpload: true,
  // imageDefaultAlign: "left",
  // imageDefaultDisplay: "inline-block",
  // Set max image size to 5MB.
  // imageMaxSize: 5  1024  1024,
  // Allow to upload PNG and JPG.
  imageAllowedTypes: ["jpeg", "jpg", "png"],
  events: {
    "charCounter.update": () => {
      // Do something here.
      // this is the editor instance.
      console.log("char");
    },
    "image.uploaded": function(response) {
      // Do something here.
      // this is the editor instance.
      console.log(this);
    },
    "image.beforeUpload": function(files) {
      let editor = config.editor;
      if (files.length) {
        // Create a File Reader.
        let reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = (e) => {
          let result = e.target.result;
          console.log("result", result);

          editor.image.insert(result, null, null, editor.image.get());
        };
        // Read image as base64.
        let pic = reader.readAsDataURL(files[0]);
        console.log("pic", pic);
      }
      editor.popups.hideAll();
      // Stop default upload chain.
      return false;
    },
  },
};

const topBar = [
  { tabName: "Client Summary", linkName: "/dashboard" },
  { tabName: "Potential Clients", linkName: "/dashboard/potentialclient" },
  { tabName: "Students", linkName: "/dashboard/student" },
  { tabName: "Employers", linkName: "/dashboard/employers" },
  { tabName: "Reminders", linkName: "/dashboard/reminders" },
];
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class PotentialClients extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      PotentialClients: [],
      ProcessingPersons: [],
      selectedBranch: null,
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userManager") === "true"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userId"),
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      isTaskModalOpen: false,
      pageLoading: false,
      newTaskObj: {},
      fileNoteObj: {
        fileNote: "",
      },
      selectedTag: "",
      selectedStatus: "",
    };
  }
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 100,
      align: "center",
      render: (data, record) => ({
        children: data,
        props: {
          className: "addCursor",
          onClick: () => this.openClientFile(record),
        },
      }),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 100,
      align: "center",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Source",
      dataIndex: "source",
      width: 100,
      align: "center",
      sorter: (a, b) => {
        if (!a.source && !b.source) {
          a.source = "";
          b.source = "";
        }
        return a.source.length - b.source.length;
      },
    },
    {
      title: "Intrested Visa",
      dataIndex: "intrestedVisa",
      width: 100,
      align: "center",
      sorter: (a, b) => {
        if (!a.intrestedVisa && !b.intrestedVisa) {
          a.intrestedVisa = "";
          b.intrestedVisa = "";
        }
        return a.intrestedVisa.length - b.intrestedVisa.length;
      },
    },
    {
      title: "Task/Fnotes",
      dataIndex: "totalTask",
      render: (data, record) => (
        <>
          <span>{data}</span>{" "}
          <span>
            <FileTwoTone onClick={() => this.viewTasks(record)} />
          </span>
        </>
      ),
      sorter: (a, b) => a.totalTask - b.totalTask,
      width: 120,
      align: "center",
    },
    {
      title: "Worth",
      dataIndex: "worth",
      width: 100,
      align: "center",
      sorter: (a, b) => a.worth.length - b.worth.length,
    },
    {
      title: "Client Status",
      dataIndex: "clientStatus",
      width: 100,
      align: "center",
      sorter: (a, b) => a.clientStatus.length - b.clientStatus.length,
    },
    {
      title: "Client Tag",
      dataIndex: "clientTag",
      width: 100,
      align: "center",
      sorter: (a, b) => a.clientTag.length - b.clientTag.length,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
      width: 100,
      align: "center",
      sorter: (a, b) =>
        moment(a.createdDate).unix() - moment(b.createdDate).unix(),
    },
    {
      title: "Last Activity",
      dataIndex: "modifiedDate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
      width: 100,
      align: "center",
      sorter: (a, b) =>
        moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix(),
    },
  ];
  openClientFile = ({ id }) => {
    this.setState({ pageLoading: true });
    let { onGetPotentialClient, onGetUpdateBol, history } = this.props;
    onGetPotentialClient(id)
      .then((res) => {
        this.setState({ pageLoading: false });
        onGetUpdateBol(false);
        history.push("/update-potential-client");
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };

  componentWillMount = () => {
    console.log("ClientSummary will mount");
  };

  componentDidMount = () => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.setState({ pageLoading: true, selectedBranch: selectedBranchId });
    let { currentPage, pageSize } = this.state;
    const {
      onGetDashboardPotentialClients,
      onGetProcessingPersons,
      getPotentialClientStatus,
    } = this.props;
    onGetProcessingPersons();
    getPotentialClientStatus();
    onGetDashboardPotentialClients({
      pageSize: pageSize,
      pageNumber: currentPage,
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userManager") === "true"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userId"),
      clientStatus: "",
      clientTagId: "",
    });
    this.props.getClientTag();
    this.props.getPotentialClientStatus();
  };

  componentWillReceiveProps = (nextProps) => {
    let processingPersons =
      nextProps &&
      nextProps.processingPersons &&
      nextProps.processingPersons.users;
    let potentialClientList = nextProps && nextProps.potentialClientList;
    let potentialClientError = nextProps && nextProps.potentialClientError;

    if (processingPersons)
      this.setState({ ProcessingPersons: processingPersons });
    if (potentialClientList)
      this.setState({
        PotentialClients: potentialClientList.pClients,
        pageLoading: false,
        totalPages: potentialClientList.count,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
      });
    if (potentialClientError) this.setState({ pageLoading: false });
  };

  paginate = (value) => {
    let { current, pageSize } = value;
    let { processingPerson, selectedStatus, selectedTag } = this.state;
    let { onGetDashboardPotentialClients } = this.props;
    this.setState({ pageLoading: true });
    this.setState(
      { pageSize: value.pageSize, currentPage: value.current },
      () => {
        onGetDashboardPotentialClients({
          pageSize: pageSize,
          pageNumber: current,
          processingPerson: processingPerson,
          clientStatus: selectedStatus,
          clientTagId: selectedTag.toString(),
        })
          .then(() => this.setState({ pageLoading: false }))
          .catch(() => this.setState({ pageLoading: false }));
      }
    );
  };
  onChangeProcessingPerson = (value) => {
    this.setState({ processingPerson: value }, () =>
      this.paginate({ pageSize: 20, current: 1 })
    );
  };

  getTaskAndNoteModal = ({
    isTaskModalOpen,
    pageLoading,
    ProcessingPersons,
    newTaskObj,
    fileNoteObj,
    fileNotes,
  }) => {
    return (
      <Modal
        title={"Details"}
        visible={isTaskModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 450, overflowY: "scroll" }}
        width={992}
        height={1000}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => this.addNewTask()}
              >
                <Form.Item
                  name="date"
                  labelAlign="left"
                  label="Due Date"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <DatePicker
                    calendarIcon={null}
                    placeholder="Select Date"
                    clearIcon={null}
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskDate", e.toJSON())
                    }
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Task Title :"
                  name="Title"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskTitle", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Task Description :"
                  name="Description"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      this.onChangeTaskHandler(
                        "taskDescription",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Add Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Required!",
                  //   },
                  // ]}
                >
                  <Select
                    showSearch
                    style={{ borderRadius: 0 }}
                    placeholder="Processing Persons"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue="a"
                    onSelect={this.onChangeFollower.bind(this)}
                    // value={processingPerson ? processingPerson : null}
                  >
                    <Option value={"a"}>Please Select</Option>
                    {ProcessingPersons.map((person) => (
                      <Option value={person.id}>{person.fullName}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                >
                  {newTaskObj && newTaskObj.taskUsers
                    ? newTaskObj.taskUsers.map((el, ind) => (
                        <>
                          <Tag color="#2db7f5">
                            <span
                              onClick={() => {
                                newTaskObj.taskUsers.splice(ind, 1);
                                this.setState({ newTaskObj: newTaskObj });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <CloseOutlined />{" "}
                            </span>{" "}
                            {el.name}
                          </Tag>
                        </>
                      ))
                    : null}
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="button-blue"
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div>
            <span style={{ fontSize: 14, fontWeight: "500", color: "#266090" }}>
              TASK
            </span>
          </div>
          <div className="modalTable">
            {newTaskObj.currentTasks
              ? newTaskObj.currentTasks.map((task) => (
                  <div
                    className="cm-table-content-row"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="content-index-40">
                      <div
                        class="cm-greenbox-cont"
                        style={{ display: "-webkit-inline-box" }}
                      >
                        <div
                          class="cm-green-box"
                          style={{
                            backgroundColor: "orange",
                            marginRight: 10,
                          }}
                        ></div>
                        <span class="cm-task-text">{task.taskTitle}</span>
                      </div>
                    </div>

                    <div className="content-index-5"></div>
                  </div>
                ))
              : null}
          </div>
          <div style={{ width: "100%" }}>
            <div className="file-note-modal">
              <Form
                ref={this.formRef2}
                {...layout}
                name="basic"
                onFinish={() => this.onAddClientFile()}
              >
                <Form.Item name="fileNote" style={{ width: "100%" }}>
                  <div className="letter-froala">
                    <FroalaEditor
                      ref={(el) => {
                        config = el;
                      }}
                      config={config}
                      model={fileNoteObj.fileNote}
                      onModelChange={this.handleNoteModelChange}
                    />
                  </div>
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="button-blue"
                    style={{ float: "right" }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="file-note">
              {fileNotes &&
                fileNotes.map((el) => (
                  <>
                    <div className="file-note-list">
                      <div className="d-flex">
                        <p style={{ marginRight: 10 }}>
                          {moment(el.createdDate).format("DD/MM/YYYY")}
                        </p>
                        <p>{moment(el.createdDate).format("HH:MM A")}</p>
                      </div>
                      <div>{renderHTML(el.fileNote)}</div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  };
  addNewTask = () => {
    let userId = localStorage.getItem("userId");

    this.setState({ pageLoading: true });
    let {
      subjectId,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers,
    } = this.state.newTaskObj;
    let users = taskUsers.map((el) => ({ userId: el.userId }));
    let taskObj = {
      subjectId,
      isPontential: false,
      isCompleted: false,
      caseCheckListId: 0,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers: users.length
        ? [...users, { userId: userId }]
        : { userId: userId },
    };
    this.props
      .onAddTask(taskObj)
      .then((res) => {
        this.getTasks({ id: subjectId });
      })
      .catch((err) => {
        this.setState({ pageLoading: false });
      });
  };
  viewTasks = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: data,
          },
          pageLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: [],
          },
          pageLoading: false,
        })
      );
    this.props
      .onGetClientFile(record.id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };

  onChangeFollower = (val, val2) => {
    let { newTaskObj } = this.state;
    this.setState(
      {
        newTaskObj: {
          ...newTaskObj,
          taskUsers: [
            ...newTaskObj.taskUsers,
            { userId: val, name: val2.children },
          ],
        },
      },
      () => this.formRef.current.setFieldsValue({ followers: "a" })
    );
  };
  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };
  getTasks = (record) => {
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        openNotificationWithIcon("success", "Task Added");
        this.setState(
          {
            newTaskObj: {
              ...this.state.newTaskObj,
              currentTasks: data,
              taskUsers: [],
            },
            pageLoading: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch((er) => {
        this.setState({
          pageLoading: false,
        });
      });
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };
  onAddClientFile = () => {
    this.setState({ pageLoading: true });
    let { fileNoteObj } = this.state;
    let { onAddClientFile } = this.props;
    onAddClientFile(fileNoteObj)
      .then((res) => {
        this.getAllFileNotes(fileNoteObj.clientId);
        openNotificationWithIcon("success", "File Note Added!");
        this.setState(
          {
            pageLoading: false,
            fileNoteObj: { ...fileNoteObj, fileNote: "" },
          },
          () => config.editor.html.set("")
        );
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  getAllFileNotes = (id) => {
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({ fileNotes: notes, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  handleNoteModelChange = (val) => {
    let { fileNoteObj } = this.state;
    this.setState({ fileNoteObj: { ...fileNoteObj, fileNote: val } });
  };

  onExport = () => {
    let { processingPerson, selectedBranch } = this.state;

    this.setState({ pageLoading: true });
    const clientExportOpt = {
      url: `v1/dashboard/PotentialClientExport`,
    };
    clientExportOpt.types = ["CLIENT_EXPORT_SUCCESS", "CLIENT_EXPORT_FAILURE"];

    let data = {
      pageSize: 0,
      pageNumber: 0,
      processingPerson: processingPerson,
      clientStatus: this.state.selectedStatus.toString(),
      clientTagId: this.state.selectedTag.toString(),
    };

    apiRefresh
      .postWithFileRes(clientExportOpt, data)
      .then((res) => {
        this.setState({ pageLoading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Potential_Client_Summary.xlsx");
      })
      .catch((err) => this.setState({ pageLoading: false }));
  };

  render() {
    const {
      processingPerson,
      ProcessingPersons,
      pageLoading,
      PotentialClients,
      pageSize,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <Spin size="large" spinning={pageLoading}>
        <div>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <Row>
                <Col xs={24}>
                  <DashboardClientTopBars
                    data={topBar}
                    activeTab="Potential Clients"
                  />
                </Col>
              </Row>
              {/* <div style={{height: "100vh"}}>
                <h3 style={{display: "flex", justifyContent: "center"}}>
                  Coming Soon!
                </h3>
              </div> */}

              <div class="container-fluid">
                <div
                  class="top-row"
                  style={{ paddingRight: 0, marginBottom: 0 }}
                >
                  <div
                    class="top-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div class="select-optionss" style={{ paddingRight: 1 }}>
                        <Select
                          showSearch
                          style={{ width: 173, borderRadius: 0 }}
                          placeholder="PROCESSING PERSON"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeProcessingPerson.bind(this)}
                          // value={processingPerson ? processingPerson : null}
                          className="potential-client-select"
                        >
                          <Option
                            value={"00000000-0000-0000-0000-000000000000"}
                          >
                            All
                          </Option>
                          {ProcessingPersons.map((person) => (
                            <Option value={person.id}>{person.fullName}</Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Client Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(e) => {
                            this.setState({
                              selectedStatus: e,
                              pageLoading: true,
                            });
                            this.props
                              .onGetDashboardPotentialClients({
                                pageSize: this.state.pageSize,
                                pageNumber: 1,
                                processingPerson: processingPerson,
                                clientStatus: e.toString(),
                                clientTagId: this.state.selectedTag.toString(),
                              })
                              .then(() => this.setState({ pageLoading: false }))
                              .catch(() =>
                                this.setState({ pageLoading: false })
                              );
                          }}
                          // defaultValue={""}
                        >
                          <Option value={""}>All</Option>
                          {this.props &&
                            this.props.potentialClientData &&
                            this.props.potentialClientData.map((data) => {
                              // eslint-disable-next-line react/jsx-no-undef
                              return (
                                <Option value={data.name}>{data.name}</Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Client Tag"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(e) => {
                            this.setState({
                              selectedTag: e,
                              pageLoading: true,
                            });
                            this.props
                              .onGetDashboardPotentialClients({
                                pageSize: this.state.pageSize,
                                pageNumber: 1,
                                processingPerson: processingPerson,
                                clientStatus: this.state.selectedStatus.toString(),
                                clientTagId: e.toString(),
                              })
                              .then(() => this.setState({ pageLoading: false }))
                              .catch(() =>
                                this.setState({ pageLoading: false })
                              );
                          }}
                        >
                          <Option value={""}>All</Option>
                          {this.props &&
                            this.props.clientTagRes &&
                            this.props.clientTagRes.items.map((data) => {
                              return (
                                <Option value={data.id}>{data.name}</Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div class="setting-export-cont" style={{ height: 24 }}>
                        <Tooltip placement="topLeft" title={`Potential Report`}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={exportImg}
                            class="setting-export-img"
                            onClick={this.onExport}
                          />
                        </Tooltip>
                        {/* <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <img
                                style={{ cursor: "pointer" }}
                                src={exportImg}
                                class="setting-export-img"
                              />
                            );
                          }}
                          content={() => this.potentialClientRef}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-card">
                <div className="container-fluid">
                  <DashboardPotentialReport
                    ref={(el) => (this.potentialClientRef = el)}
                    PotentialClients={PotentialClients}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    columns={this.columns}
                    paginate={this.paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.getTaskAndNoteModal(this.state)}
      </Spin>
    );
  }
}

PotentialClients.propTypes = {
  // bla: PropTypes.string,
};

PotentialClients.defaultProps = {
  // bla: 'test',
};

export default PotentialClients;
