import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const docViewReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_VIEW_SUCCESS:
      return { ...state, docViewRes: action.payload };
    case types.GET_DOCUMENT_VIEW_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_VIEW_SUCCESS:
      return { ...state, addDocViewRes: action.payload };
    case types.ADD_DOCUMENT_VIEW_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
