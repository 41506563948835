import * as types from "../Constants";

export const dealsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_BRANCH_PIPELINE_SUCCESS:
      return { ...state, brnchPipelineData: action.payload };
    case types.GET_BRANCH_PIPELINE_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_SALES_PIPELINE_SUCCESS:
      return { ...state, addSalePipelineRes: action.payload };
    case types.ADD_SALES_PIPELINE_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_SALES_PIPELINE_SUCCESS:
      return { ...state, getSalePipelineRes: action.payload };
    case types.GET_SALES_PIPELINE_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
