import * as types from "../Constants";
import { api } from "../../services/api";

export const getPotentialClientListing = (data) => {
  let _data = {
    fromDate: data.fromDate,
    toDate: data.toDate,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    address: data.address,
    occupation: data.occupation,
    clientStatus: data.clientStatus,
    interestedVisa: data.interestedVisa,
    clientTag: data.clientTag,
    priority: data.priority,
    salePerson: data.salePerson,
    exclude: data.exclude,
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    processingPerson: data.processingPerson,
  };
  const options = {
    url: "v1/potentialclient/All",
  };
  options.types = [
    types.GET_POTENTIAL_CLIENT_LISTING_SUCCESS,
    types.GET_POTENTIAL_CLIENT_LISTING_FAILURE,
  ];
  return api.post(options, _data);
};

export const addPotentialClient = (data) => {
  let _data = {
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    dob: data.dob || "1900-01-01T00:00:00.000Z",
    email: data.email || "",
    mobile: data.mobile || "",
    address: data.address || "",
    notes: data.notes || "",
    worth: data.worth || "",
    visaTypeId: data.visaTypeId || 0,
    visaTypeName: data.visaTypeName || "",
    phone: data.phone || "",
    salePerson: data.salePerson || "",
    intrestedVisa: data.intrestedVisa || "",
    sourceId: data.sourceId || 0,
    sourceDescription: data.sourceDescription || "",
    clientStatus: data.clientStatus || "",
    occupation: data.occupation || "",
    bulkSubscribe: true,
    webLink: true,
    city: "",
    deletedDate: null,
    contactDate: data.contactDate || "1900-01-01T00:00:00.000Z",
    title: "",
    middleName: "",
    countryCode: data.countryCode || 0,
    clientTags: data.clientTags || [],
    processingPersons: data.processingPersons || [],
  };

  const options = {
    url: "v1/potentialclient",
  };

  options.types = [
    types.ADD_POTENTIAL_CLIENT_SUCCESS,
    types.ADD_POTENTIAL_CLIENT_FAILURE,
  ];
  return api.post(options, _data);
};

export const editPotentialClient = (data) => {
  let _data = {
    id: data.potentialClientId,
    firstName: data.firstName,
    lastName: data.lastName,
    dob: data.dob,
    email: data.email,
    mobile: data.mobile,
    address: data.address,
    notes: data.notes,
    worth: data.worth,
    visaTypeId: data.visaTypeId,
    visaTypeName: data.visaTypeName,
    phone: data.phone,
    salePerson: data.salePerson,
    intrestedVisa: data.intrestedVisa,
    sourceId: data.sourceId,
    sourceDescription: data.sourceDescription,
    clientStatus: data.clientStatus,
    occupation: data.occupation,
    bulkSubscribe: true,
    webLink: true,
    city: "",
    deletedDate: null,
    contactDate: data.contactDate,
    title: "",
    middleName: "",
    countryCode: data.countryCode,
    clientTags: data.clientTags,
    // processingPersons: data.processingPersons,
  };
  const options = {
    url: "v1/potentialclient",
  };

  options.types = [
    types.EDIT_POTENTIAL_CLIENT_SUCCESS,
    types.EDIT_POTENTIAL_CLIENT_FAILURE,
  ];
  return api.put(options, _data);
};

export const deletePotentialClient = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/potentialclient",
  };

  options.types = [
    types.DELETE_POTENTIAL_CLIENT_SUCCESS,
    types.DELETE_POTENTIAL_CLIENT_FAILURE,
  ];

  return api.delete(options, deleteData);
};

export const getPotentialClientInfo = (id) => {
  const options = {
    url: `v1/potentialclient/${id}`,
  };

  options.types = [
    types.GET_POTENTIAL_CLIENT_INFO_SUCCESS,
    types.GET_POTENTIAL_CLIENT_INFO_FAILURE,
  ];

  return api.get(options);
};

export const getPotentialClientUpdateBol = (payload) => {
  return {
    type: types.GET_POTENTIAL_CLIENT_UPDATE_BOL,
    payload,
  };
};

export const importPotentialClient = (data) => {
  const options = {
    url: "v1/potentialclient/ImportPotentialClientCreation",
  };
  options.types = [
    types.CREATE_IMPORT_POTENTIAL_CLIENT_SUCCESS,
    types.CREATE_IMPORT_POTENTIAL_CLIENT_FAILURE,
  ];
  return api.post(options, data);
};

export const checkDuplicatePotentialClient = (data) => {
  let _data = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
  };

  const options = {
    url: "v1/potentialclient/CheckDuplicatePotentialClient",
  };
  options.types = [
    types.DUPLICATE_CHECK_POTENTIAL_CLIENT_SUCCESS,
    types.DUPLICATE_CHECK_POTENTIAL_CLIENT_FAILURE,
  ];
  return api.post(options, _data);
};

export const movePClientToClient = (id) => {
  const options = {
    url: `v1/potentialclient/MovePotentialClientToClientAuto/${id}`,
  };
  options.types = [types.MOVE_TO_CLIENT_SUCCESS, types.MOVE_TO_CLIENT_FAILURE];
  return api.get(options);
};

export const getPClientFormatFile = () => {
  const options = {
    url:
      "v1/document/GetDocumentBytes/5f4486b3-bd3b-4023-bab3-adbef494b2acImportPClientFormat.xlsx",
  };

  options.types = [
    types.POTENTIAL_CLIENT_FORMAT_FILE_SUCCESS,
    types.POTENTIAL_CLIENT_FORMAT_FILE_FAILURE,
  ];

  return api.getFile(options);
};

export const addNewClientNull = (payload) => {
  return {
    type: types.ADD_NEW_CLIENT_NULL,
    payload,
  };
};

export const getPClientEmailImport = (clientId) => {
  const options = {
    url: `v1/emailimport/PotentialClientImportSettings/${clientId}`,
  };

  options.types = [
    types.POTENTIAL_EMAIL_IMPORT_SUCCESS,
    types.POTENTIAL_EMAIL_IMPORT_FAILURE,
  ];

  return api.get(options);
};

export const getPClientEmailHistory = (
  id,
  familyid,
  EmailType,
  PageSize,
  PageNumber,
  VisaId
) => {
  const options = {
    url: `v1/imap/PotentialClientEmailHistory/${id}/${familyid}/${EmailType}/${PageSize}/${PageNumber}/${VisaId}`,
  };

  options.types = [
    types.GET_POTENTIAL_EMAIL_HISTORY_SUCCESS,
    types.GET_POTENTIAL_EMAIL_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const mergeFromPotentialClient = (data) => {
  const options = {
    url: `v1/questionnaire/QuestionnaireToPClient`,
  };

  options.types = [
    types.MERGE_FROM_POTENTIAL_CLIENT_SUCCESS,
    types.MERGE_FROM_POTENTIAL_CLIENT_FAILURE,
  ];

  return api.post(options, data);
};

export const updMergeFromPotentialClient = (data) => {
  const options = {
    url: `v1/questionnaire/QuestionnaireToPClient`,
  };

  options.types = [
    types.UPD_MERGE_FROM_POTENTIAL_CLIENT_SUCCESS,
    types.UPD_MERGE_FROM_POTENTIAL_CLIENT_FAILURE,
  ];

  return api.put(options, data);
};

export const updMergeToPotentialClient = (data) => {
  const options = {
    url: `v1/questionnaire/PQuestionnaireMapping`,
  };

  options.types = [
    types.UPD_MERGE_TO_POTENTIAL_CLIENT_SUCCESS,
    types.UPD_MERGE_TO_POTENTIAL_CLIENT_FAILURE,
  ];

  return api.put(options, data);
};
