import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultFontSettings from "../AccountSetting/UserLevelSettings/DefaultFontSettings/DefaultFontSettings";

import {
  getDefaultFont,
  addDefaultFont,
  updImapForAll,
  getImapForAll,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    defaultFontRes: state.documentTypeReducer.defaultFontRes,
    addDefaultFontRes: state.documentTypeReducer.addDefaultFontRes,
    imapForAllRes: state.imapSettingReducer.imapForAllRes,
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDefaultFont: bindActionCreators(getDefaultFont, dispatch),
    onAddDefaultFont: bindActionCreators(addDefaultFont, dispatch),
    onUpdImapForAll: bindActionCreators(updImapForAll, dispatch),
    onGetImapForAll: bindActionCreators(getImapForAll, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultFontSettings);
