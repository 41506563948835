import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubmitQuestionnaire from "../CustomQuestionnaire/SubmitQuestionnaire";

import {
  getQuestionnaire,
  submitQuestionnaire,
  getCountries,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    questionnaireData: state.questionnaireReducer.questionnaireData,
    countriesData: state.visaTypeReducer.countriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitQuestionnaire);
