import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
//import { Test } from './PotentialClientDashboardReport.styles';

class PotentialClientDashboardReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentWillMount = () => {
    console.log("PotentialClientDashboardReport will mount");
  };

  componentDidMount = () => {
    console.log("PotentialClientDashboardReport mounted");
  };

  componentWillReceiveProps = (nextProps) => {
    console.log("PotentialClientDashboardReport will receive props", nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    console.log(
      "PotentialClientDashboardReport will update",
      nextProps,
      nextState
    );
  };

  componentDidUpdate = () => {
    console.log("PotentialClientDashboardReport did update");
  };

  componentWillUnmount = () => {
    console.log("PotentialClientDashboardReport will unmount");
  };

  render() {
    const {
      PotentialClients,
      currentPage,
      totalPages,
      pageSize,
      columns,
      paginate,
    } = this.props;

    return (
      <Table
        ref={(el) => (this.potentialClientRef = el)}
        // rowClassName={(record, index) =>
        //   index % 2 === 0 ? "table-row-light" : "table-row-dark"
        // }
        columns={columns}
        dataSource={PotentialClients}
        onChange={paginate}
        showSorterTooltip={{ title: "" }}
        pagination={{
          defaultCurrent: currentPage,
          total: totalPages,
          showSizeChanger: true,
          defaultPageSize: pageSize,
          pageSizeOptions: ["20", "50", "100", "150", "200"],
        }}
        // scroll={{ x: "auto" }}
      />
    );
  }
}

PotentialClientDashboardReport.propTypes = {
  // bla: PropTypes.string,
};

PotentialClientDashboardReport.defaultProps = {
  // bla: 'test',
};

export default PotentialClientDashboardReport;
