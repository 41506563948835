import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Form,
  Table,
  Modal,
  Spin,
  Tabs,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getDueOverDueInvoices,
  getInvoiceInfo,
  getParentInvoiceListing,
  getProfileClientTag,
  getSchoolInvoiceInfo,
  getWorkCompletedInvoices,
  getWorkInProgressInvoices,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Listing from "./Templates/Listing";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;
class Reports extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      branchId: selectedBranchId,
      subjectTypeId: 0,
      pageNumber: 1,
      pageSize: 5,
      dateFrom: "1900-01-01T00:00:00+00:00",
      dateTo: "1900-01-01T00:00:00+00:00",
      loading: false,
      workInProgressInvoices: [],
      workCompletedInvoices: [],
      totalPages: 0,
      defaultCurrent: 1,
      key: "1",
      name: "",
      email: "",
      invoiceNo: "",
      clientTagId: 0,
      loadingTable: false,
      totalAmount: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getWorkInProgressInvoices(this.state)
      .then(() => {
        this.props.getClientTag();
        this.setState({ loading: false });
      })
      .catch(() => {
        this.props.getClientTag();
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getClientTag().then(() => {});
      });
    }
    let _workInProgressInvoices;
    let _workCompletedInvoices;
    let _workInProgressInvoicesCount;
    let _workCompletedInvoicesCount;

    _workInProgressInvoices =
      nextProps &&
      nextProps.workInProgressInvoices &&
      nextProps.workInProgressInvoices;
    _workCompletedInvoices =
      nextProps &&
      nextProps.workCompletedInvoices &&
      nextProps.workCompletedInvoices;
    _workInProgressInvoicesCount =
      nextProps &&
      nextProps.workInProgressInvoicesCount &&
      nextProps.workInProgressInvoicesCount;
    _workCompletedInvoicesCount =
      nextProps &&
      nextProps.workCompletedInvoicesCount &&
      nextProps.workCompletedInvoicesCount;
    if (_workInProgressInvoices && _workInProgressInvoices.length > 0) {
      let _amount = 0;
      for (let _index = 0; _index < _workInProgressInvoices.length; _index++) {
        // let _items =
        //   _workInProgressInvoices[_index].payments && _workInProgressInvoices[_index].payments.items && _workInProgressInvoices[_index].payments.items;
        // if (_items && _items.length > 0) {
        //   for (let index = 0; index < _items.length; index++) {
        //     _amount = _amount + _items[index].amount;
        //   }
        //
        // }
        _amount =
          _amount +
          (_workInProgressInvoices &&
            _workInProgressInvoices[_index].paidAmount);
      }
      this.setState({ totalAmount: _amount });
    }
    if (_workCompletedInvoices && _workCompletedInvoices.length > 0) {
      let _amount = 0;
      for (let _index = 0; _index < _workCompletedInvoices.length; _index++) {
        // let _items =
        //   _workCompletedInvoices[_index].payments && _workCompletedInvoices[_index].payments.items && _workCompletedInvoices[_index].payments.items;
        // if (_items && _items.length > 0) {
        //   for (let index = 0; index < _items.length; index++) {
        //     _amount = _amount + _items[index].amount;
        //   }
        // }
        _amount =
          _amount +
          (_workCompletedInvoices && _workCompletedInvoices[_index].paidAmount);
      }
      this.setState({ totalAmount: _amount });
    }
    this.setState({
      workInProgressInvoices: _workInProgressInvoices,
      workCompletedInvoices: _workCompletedInvoices,
      totalPages:
        this.state.key === "1"
          ? _workInProgressInvoicesCount
          : _workCompletedInvoicesCount,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00:00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00:00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        loadingTable: true,
      },
      () => {
        if (this.state.key === "1") {
          this.props
            .getWorkInProgressInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.workInProgressInvoicesCount &&
                this.props.workInProgressInvoicesCount;
              this.setState({
                loadingTable: false,
                defaultCurrent: value.current,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
        } else {
          this.props
            .getWorkCompletedInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.workCompletedInvoicesCount &&
                this.props.workCompletedInvoicesCount;
              this.setState({
                loadingTable: false,
                defaultCurrent: value.current,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
        }
      }
    );
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeInvoiceNumber = (e) => {
    this.setState({ invoiceNo: e.target.value });
  };

  getFiltersSection = () => {
    return (
      <div>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Name</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input size="medium" onChange={this.handleChangeName} />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Email</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input size="medium" onChange={this.handleChangeEmail} />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Date From</p>
              </div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  placeholder="Date From"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateFrom}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Date To</p>
              </div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  placeholder="Date To"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateTo}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Invoice #</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input
                  size="medium"
                  onChange={this.handleChangeInvoiceNumber}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>
                  Client Tags
                </p>
              </div>
              <div style={{ width: "80%" }}>
                <Select
                  className={"width-web-kit"}
                  placeholder={"Select"}
                  onChange={this.handleChangeClientTags}
                  value={
                    this.state.clientTagId === 0
                      ? undefined
                      : this.state.clientTagId
                  }
                >
                  <Option value={0}>{"Select"}</Option>
                  {this.props &&
                    this.props.clientTagRes &&
                    this.props.clientTagRes.items &&
                    this.props.clientTagRes.items.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  columns = [
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Client ID",
      dataIndex: "ezmId",
      key: "ezmId",
    },
    {
      title: "Client Name",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },

    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        // let _items =
        //   record.payments && record.payments.items && record.payments.items;
        // let _amount = 0;
        // if (_items && _items.length > 0) {
        //   for (let index = 0; index < _items.length; index++) {
        //     _amount = _amount + _items[index].amount;
        //   }
        // }
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.paidAmount}
          </p>
        );
      },
    },
  ];

  onChange = (value) => {
    this.setState({ subjectTypeId: value }, () => {
      if (this.state.key === "1") {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkInProgressInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      } else {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkCompletedInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      }
    });
  };

  onChangeTab = (key) => {
    this.setState({ key: key }, () => {
      if (this.state.key === "1") {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkInProgressInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      } else {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkCompletedInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      }
    });
  };

  getTable = () => {
    return (
      <div>
        {this.state.key === "1" ? (
          <Row>
            <Col xs={24} style={{ display: "flex" }}>
              <p>Work In Progress Period:&nbsp;&nbsp;&nbsp;</p>
              <p style={{ fontWeight: "bold" }}>
                {` From:  ${
                  this.state.dateFrom === "1900-01-01T00:00:00+00:00"
                    ? " Start"
                    : moment(this.state.dateFrom).format("DD/MM/YYYY")
                } 
                To: ${
                  this.state.dateTo === "1900-01-01T00:00:00+00:00"
                    ? " End"
                    : moment(this.state.dateTo).format("DD/MM/YYYY")
                }`}
              </p>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={24} style={{ display: "flex" }}>
              <p>Work Completed Period:&nbsp;&nbsp;&nbsp;</p>
              <p style={{ fontWeight: "bold" }}>
                {` From:  ${
                  this.state.dateFrom === "1900-01-01T00:00:00+00:00"
                    ? " Start"
                    : moment(this.state.dateFrom).format("DD/MM/YYYY")
                } 
                 To: ${
                   this.state.dateTo === "1900-01-01T00:00:00+00:00"
                     ? " End"
                     : moment(this.state.dateTo).format("DD/MM/YYYY")
                 }`}
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            {this.state.loadingTable ? (
              <div className={"spinner-xero"}>
                <Spin size="large" />
              </div>
            ) : (
              <Row>
                <Col xs={24} className={"school-table overflow"}>
                  <Table
                    dataSource={
                      this.state.key === "1"
                        ? this.state.workInProgressInvoices
                        : this.state.workCompletedInvoices
                    }
                    columns={this.columns}
                    onChange={this.paginate}
                    pagination={{
                      defaultCurrent: this.state.defaultCurrent,
                      total: this.state.totalPages,
                      defaultPageSize: 20,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3} offset={21}>
            <p style={{ fontWeight: "bold" }}>
              {`Total  ${this.state.totalAmount}`}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <Fragment style={{ height: "100vh" }}>
            <div
              className="ts-container-import-clients"
              style={{ marginTop: "22px" }}
            >
              {this.getFiltersSection()}
            </div>
            <div className="ts-container-import-clients">
              <Row>
                <Col xs={6} style={{ marginBottom: "37px" }}>
                  <Select
                    placeholder="Select Invoice Department"
                    value={this.state.subjectTypeId}
                    onChange={this.onChange}
                  >
                    <Option value={0}>All Invoices</Option>
                    <Option value={8}>Template Invoices</Option>
                    <Option value={2}>Client Invoices</Option>
                    <Option value={3}>Potential Client Invoices</Option>
                    <Option value={4}>Employer Invoices</Option>
                    <Option value={5}>School Invoices</Option>
                  </Select>
                </Col>
              </Row>
              <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane tab="Work In Progress" key="1">
                  {this.getTable()}
                </TabPane>
                <TabPane tab="Work Completed" key="2">
                  {this.getTable()}
                </TabPane>
              </Tabs>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    workInProgressInvoices:
      state.accountReducer &&
      state.accountReducer.workInProgressInvoices &&
      state.accountReducer.workInProgressInvoices.items &&
      state.accountReducer.workInProgressInvoices.items,
    workInProgressInvoicesCount:
      state.accountReducer &&
      state.accountReducer.workInProgressInvoicesCount &&
      state.accountReducer.workInProgressInvoicesCount,
    workCompletedInvoices:
      state.accountReducer &&
      state.accountReducer.workCompletedInvoices &&
      state.accountReducer.workCompletedInvoices.items &&
      state.accountReducer.workCompletedInvoices.items,
    workCompletedInvoicesCount:
      state.accountReducer &&
      state.accountReducer.workCompletedInvoicesCount &&
      state.accountReducer.workCompletedInvoicesCount,
    clientTagRes:
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getWorkCompletedInvoices: bindActionCreators(
    getWorkCompletedInvoices,
    dispatch
  ),
  getWorkInProgressInvoices: bindActionCreators(
    getWorkInProgressInvoices,
    dispatch
  ),
});
Reports = connect(mapStateToProps, mapDispatchToProps)(Reports);

export default withRouter(Reports);
