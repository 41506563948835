import { api } from "../../services/api";
import * as types from "../Constants";

export const getAllChats = () => {
  const options = {
    url: "v1/chat/All",
  };

  options.types = [types.CHATS_DISPLAY_SUCCESS, types.CHATS_DISPLAY_FAILURE];

  return api.get(options);
};

export const getClientsData = () => {
  const options = {
    url: "v1/client/GetAllClientsByBranchId",
  };

  options.types = [types.GET_CLIENTS_SUCCESS, types.GET_CLIENTS_FAILURE];

  return api.get(options);
};

export const addChatThread = (data) => {
  const options = {
    url: "v1/chat/Chat",
  };

  options.types = [
    types.ADD_CHAT_THREAD_SUCCESS,
    types.ADD_CHAT_THREAD_FAILURE,
  ];

  return api.post(options, data);
};

export const getChatMessages = (id) => {
  const options = {
    url: "v1/chat/Thread/" + id,
  };

  options.types = [
    types.GET_CHAT_MESSAGES_SUCCESS,
    types.GET_CHAT_MESSAGES_FAILURE,
  ];

  return api.get(options);
};

export const addThreadMessage = (data) => {
  const options = {
    url: "v1/chat/Message",
  };

  options.types = [
    types.ADD_THREAD_MESSAGE_SUCCESS,
    types.ADD_THREAD_MESSAGE_FAILURE,
  ];

  return api.post(options, data);
};

export const addChatMember = (data) => {
  const options = {
    url: "v1/chat/AddMember",
  };

  options.types = [
    types.ADD_CHAT_MEMBER_SUCCESS,
    types.ADD_CHAT_MEMBER_FAILURE,
  ];

  return api.post(options, data);
};

export const getUsers = () => {
  let selectedBranchId = localStorage.getItem("selectedBranchId");
  let processingPerson =
    localStorage.getItem("userOwner") === "True"
      ? "00000000-0000-0000-0000-000000000000"
      : localStorage.getItem("userManager") === "true"
      ? "00000000-0000-0000-0000-000000000000"
      : localStorage.getItem("userOwner") !== "True" &&
        localStorage.getItem("userManager") !== "true" &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() === "client database").status === 1
      ? "00000000-0000-0000-0000-000000000000"
      : localStorage.getItem("userId");
  const options = {
    url: `v1/users/ddl/All`,
  };

  options.types = [types.GET_USERS_SUCCESS, types.GET_USERS_FAILURE];

  return api.get(options);
};

export const addChatFileNotes = (data) => {
  const options = {
    url: "v1/client/filenote/MoveChatToFileNote",
  };

  options.types = [
    types.ADD_CHAT_FILENOTES_SUCCESS,
    types.ADD_CHAT_FILENOTES_FAILURE,
  ];

  return api.post(options, data);
};
