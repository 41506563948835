import React, { Fragment, useState, useEffect } from "react";
import { Checkbox, Spin } from "antd";
import moment from "moment";

import Reminders from "./Reminders";

const RemindersTab = ({
  remindersRes,
  onUpdateReminder,
  onGetReminder,
  activeKey,
  clientId,
}) => {
  const [showFields, setShowField] = useState("0");
  const [reminderData, setReminderData] = useState([]);
  const [loadReminder, setLoadReminder] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    let clientprofileid = clientId;
    // if (activeKey === "8") {
    onGetReminder(clientprofileid)
      .then((res) => {
        if (res.payload && res.payload.items.length > 0) {
          setReminderData(res.payload.items);
        }
        setLoadReminder(false);
      })
      .catch((err) => {
        setLoadReminder(false);
      });
    // }
  }, []);

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (remindersRes) {
      var findCompReminder = remindersRes.items.filter(
        (obj) => obj.isCompleted === true
      );
      if (findCompReminder && e.target.checked) {
        setIsFiltered(true);
        setReminderData(findCompReminder);
      } else {
        setIsFiltered(false);
        setReminderData(remindersRes.items);
      }
    }
  };

  return (
    <Fragment>
      <Spin spinning={loadReminder}>
        <div style={{ marginLeft: 0, marginTop: 20 }}>
          <div>
            <Checkbox onChange={onChange}>Show Completed</Checkbox>
          </div>
          {reminderData && reminderData.length > 0 && (
            <div className="reminder-tabs-cont">
              {reminderData.map((reminder, index) => (
                <Reminders
                  reminder={reminder}
                  index={index}
                  onUpdateReminder={onUpdateReminder}
                  isFiltered={isFiltered}
                />
              ))}
            </div>
          )}
        </div>
      </Spin>
    </Fragment>
  );
};

export default RemindersTab;
