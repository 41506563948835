import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Images } from "../../Themes";
import FileNotesTable from "./FileNotesTable";

// const SaleHistoryReport = ({ saleHistoryRes, displayText }) => {
export class FileNotesReport extends React.PureComponent {
  render() {
    const {
      fileNotesReportRes,
      displayText,
      reportsCount,
      onFileNotesReport,
      requestData,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <FileNotesTable
            fileNotesReportRes={fileNotesReportRes}
            ref={(el) => (this.fileNotetRef = el)}
            displayText={displayText}
            reportsCount={reportsCount}
            onFileNotesReport={onFileNotesReport}
            requestData={requestData}
          />
        </div>
      </Fragment>
    );
  }
}
export default FileNotesReport;
