import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Mail from "../Mail/ImapEmail";

import {
  getMailBox,
  searchImapEmail,
  manualImportEmail,
  searchMainClient,
  searchPotentialClient,
  searchEmployer,
  manualImportPotentialEmail,
  manualImportEmployerEmail,
  autoImportEmailClient,
  getAutoEmailClientImport,
  getLetterTemplates,
  addDraftClient,
  addEmailDocument,
  getSignature,
  getPdf,
  getDocuments,
  getCountries,
  getVisaType,
  getVisaStatus,
  getClientTag,
  getUsers,
  getPermissonUser,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    mailBoxData: state.mailReducer.mailBoxData,
    searchMailData: state.mailReducer.searchMailData,
    mainClientsData: state.clientProfileReducer.clientsData,
    potentialClientsData: state.clientProfileReducer.potentialClientsData,
    searchEmployerRes: state.employerManagReducer.searchEmployerRes,
    searchEmployerData: state.mailReducer.searchEmployerData,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    emailDocumentRes: state.clientProfileReducer.emailDocumentRes,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    documentRes: state.accountDocumentReducer.documentRes,
    activeKey: state.employerManagReducer.activeKey,
    countriesData: state.visaTypeReducer.countriesData,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    clientTagRes: state.accountSetReducer.clientTagRes,
    userData: state.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMailBox: bindActionCreators(getMailBox, dispatch),
    onSearchImapEmail: bindActionCreators(searchImapEmail, dispatch),
    onImportManualEmail: bindActionCreators(manualImportEmail, dispatch),
    onImportManualPotentialEmail: bindActionCreators(
      manualImportPotentialEmail,
      dispatch
    ),
    onImportManualEmployerEmail: bindActionCreators(
      manualImportEmployerEmail,
      dispatch
    ),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onSearchPotentialClient: bindActionCreators(
      searchPotentialClient,
      dispatch
    ),
    onSearchEmployer: bindActionCreators(searchEmployer, dispatch),
    onAutoImportEmailClient: bindActionCreators(
      autoImportEmailClient,
      dispatch
    ),
    onGetAutoImportEmailClient: bindActionCreators(
      getAutoEmailClientImport,
      dispatch
    ),
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onAddDraftClient: bindActionCreators(addDraftClient, dispatch),
    onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
    onGetSignature: bindActionCreators(getSignature, dispatch),
    onGetPdf: bindActionCreators(getPdf, dispatch),
    onGetDocuments: bindActionCreators(getDocuments, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mail);
