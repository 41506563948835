import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const xeroReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_XERO_TOKEN_SUCCESS:
      return { ...state, isExists: action.payload };
    case types.GET_XERO_TOKEN_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      console.log("on error console", action.payload);
    // case types.DELETE_SCHOOL_SUCCESS:
    //
    //   console.log("on error console", action.payload);
    //   return { ...state, bol: action.payload.response };
    default:
      return state;
  }
};
