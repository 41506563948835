import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Table,
  Spin,
  Modal,
  Checkbox,
  Select,
  message,
  Dropdown,
  Menu,
  DatePicker,
  Input,
  Popconfirm,
  Tag,
  Tooltip,
  Avatar,
  Image,
} from "antd";
import moment from "moment";
import {
  DownOutlined,
  MessageTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
  FileDoneOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  UserOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  FileTwoTone,
} from "@ant-design/icons";
import renderHTML from "react-render-html";
import "./DashboardStyles.css";
import settings from "../svgs/settings.svg";
import exportImg from "../svgs/export.svg";
import DashboardClientTopBars from "../Components/Shared/DashboardClientTopBar";
import { openNotificationWithIcon } from "../Common/reUseFunction";
import { Images } from "../Themes";
import mehronCross from "../svgs/mehron-cross.svg";
import ClientSummaryReport from "../Reports/ClientSummaryReport/ClientSummaryReport";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import personFill from "../svgs/person-fill.svg";
import grayCross from "../svgs/gray-cross.svg";
import rightArrow from "../svgs/right-arrow.svg";
import { saveAs } from "file-saver";
import AddFollowerModal from "../MyDailyTasksReminders/SideArea/AddFollowerModal";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import { apiRefresh } from "../services/api";

const { Option } = Select;
const { TextArea } = Input;

const topBar = [
  { tabName: "Client Summary", linkName: "/dashboard" },
  { tabName: "Potential Clients", linkName: "/dashboard/potentialclient" },
  { tabName: "Students", linkName: "/dashboard/student" },
  { tabName: "Employers", linkName: "/dashboard/employers" },
  { tabName: "Reminders", linkName: "/dashboard/reminders" },
];

let config = {
  key: "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
  // height: "auto",
  // toolbarSticky: false,
  charCounterCount: false,
  // placeholderText: "Edit Your Content Here!",
  // imageUpload: true,
  // imageDefaultAlign: "left",
  // imageDefaultDisplay: "inline-block",
  // Set max image size to 5MB.
  // imageMaxSize: 5  1024  1024,
  // Allow to upload PNG and JPG.
  imageAllowedTypes: ["jpeg", "jpg", "png"],
  events: {
    "charCounter.update": () => {
      // Do something here.
      // this is the editor instance.
      console.log("char");
    },
    "image.uploaded": function(response) {
      // Do something here.
      // this is the editor instance.
      console.log(this);
    },
    "image.beforeUpload": function(files) {
      let editor = config.editor;
      if (files.length) {
        // Create a File Reader.
        let reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = (e) => {
          let result = e.target.result;
          console.log("result", result);

          editor.image.insert(result, null, null, editor.image.get());
        };
        // Read image as base64.
        let pic = reader.readAsDataURL(files[0]);
        console.log("pic", pic);
      }
      editor.popups.hideAll();
      // Stop default upload chain.
      return false;
    },
  },
};

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const columnObject = {
  priority: "Priority",
  clientNumber: "Inz Id",
  name: "Name",
  dateOfBirth: "DOB",
  visaTypeName: "Visa Name",
  visaStatus: "Visa Status",
  startDate: "Visa Date",
  totalTask: "Total Task",
  totalReminder: "Total Reminder",
  contractSigned: "Contract Signed",
  followUpDate: "Follow Up Date",
  modifiedDate: "Modified Date",
  processingPerson: "Processing Person",
  clienttags: "Client Tags",
  casePriority: "Case Priority",
  isPaid: "Paid",
  currentNewZealandVisaExpiry: "Current Visa Expiry",
  medicalIssueDate: "Medical Issue Date",
  xrayIssueDate: "Xray Issue Date",
  policeCertificate: "Police Certificate Date",
  fileNote: "File Note",
  email: "Email",
  clientSerial: "Internal Id",
};
const colWidth = "";
const emailButtons = {
  inboximport: (
    <Tag style={{ backgroundColor: "#f0ad4e", color: "#fff" }}>
      INBOX IMPORTED
    </Tag>
  ),
  sentimport: (
    <Tag style={{ backgroundColor: "#f0ad4e", color: "#fff" }}>
      SENT IMPORTED
    </Tag>
  ),
  draft: <Tag style={{ backgroundColor: "#108ee9", color: "#fff" }}>DRAFT</Tag>,
  isSent: (
    <Tag style={{ backgroundColor: "#f0ad4e", color: "#fff" }}>IMPORTED</Tag>
  ),
};

var priority = "";
var casePriority = "";
class Dashboard extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      Clients: [],
      DashbordSettings: [],
      ProcessingPersons: [],
      Countries: [],
      visaStatus: [],
      selectedBranch: null,
      selectedCountry: 0,
      username: "",
      selectedOption: null,
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userManager") === "true"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userId"),
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      newTaskObj: {},
      newReminderObj: {},
      updateTask: {},
      pageLoading: false,
      isSettingsModalOpen: false,
      isStatusDateModalOpen: false,
      isTaskModalOpen: false,
      isReminderModalOpen: false,
      isUpdateTask: false,
      isFileNoteModalOpen: false,
      isFollowerModalOpen: false,
      visaTypes: [],
      visaStatuses: [],
      selectedVisaType: "",
      selectedVisaStatus: "",
      onDefault: true,
      fileNoteObj: {
        fileNote: "",
      },
      Counts: {
        count: 0,
        high: 0,
        low: 0,
        medium: 0,
        onShore: 0,
        visaCount: 0,
      },
    };
  }

  columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
      filters: [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Low",
          value: "Low",
        },
      ],
      onFilter: (value, record) => {
        priority = value;
        return record.priority.indexOf(value) === 0;
      },
      render: (text, record) => {
        return (
          <div
            className="priority-buttons"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span
              class="priority-button"
              style={{
                borderColor: "#d9534f",
                backgroundColor: text === "High" ? "#d9534f" : "#0000",
              }}
              onClick={() => {
                this.updateClientPriority(record, "High");
              }}
            ></span>
            <span
              class="priority-button"
              onClick={() => {
                this.updateClientPriority(record, "Medium");
              }}
              style={{
                borderColor: "#f0ad4e",
                backgroundColor: text === "Medium" ? "#f0ad4e" : "#0000",
              }}
            ></span>
            <span
              class="priority-button High-priority"
              onClick={() => {
                this.updateClientPriority(record, "Low");
              }}
              style={{
                borderColor: "#5cb85c",
                backgroundColor: text === "Low" ? "#5cb85c" : "#0000",
              }}
            ></span>
          </div>
        );
      },
    },
    {
      title: "Internal Id",
      dataIndex: "clientSerial",
      align: "center",
      sorter: (a, b) => a.clientSerial.length - b.clientSerial.length,
    },
    {
      title: "Id",
      dataIndex: "clientNumber",
      align: "center",
      sorter: (a, b) => a.clientNumber.length - b.clientNumber.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      align: "center",
      render: (text, record) => {
        return !JSON.parse(record.onShore)
          ? {
              children: text,
              props: {
                className: "offshore",
                onClick: () => this.openClientFile(record),
              },
            }
          : {
              children: text,
              props: {
                className: "addPointer",
                onClick: () => this.openClientFile(record),
              },
            };
      },
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      align: "center",
      width: "100px",
      sorter: (a, b) =>
        moment(a.dateOfBirth).unix() - moment(b.dateOfBirth).unix(),
      render: (date) => {
        return date === "01/01/0001 00:00:00 +00:00" ||
          !date.length ||
          date === "01/01/1900 00:00:00 +00:00" ||
          date === "01/01/1900 00:00:00"
          ? null
          : moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Visa Type",
      dataIndex: "visaTypeName",
      align: "center",
      width: "200px",
      sorter: (a, b) => {
        if (!a.visaTypeName) a.visaTypeName = "";
        if (!b.visaTypeName) b.visaTypeName = "";
        return a.visaTypeName.length - b.visaTypeName.length;
      },
      render: (text, record) => {
        return (
          <>
            {text}
            {/* <Dropdown overlay={this.menu(record)}>
              <Button
                style={{
                  backgroundColor: record.visaStatusColor,
                  fontSize: 12,
                  display: "block",
                  margin: "auto",
                }}
              >
                {record.visaStatus} <DownOutlined />
              </Button>
            </Dropdown> */}
          </>
        );
      },
    },
    {
      title: "Visa Status",
      dataIndex: "visaStatus",
      align: "center",
      width: "200px",
      // sorter: (a, b) => {
      //   if (!a.visaStatus) a.visaStatus = "";
      //   if (!b.visaStatus) b.visaStatus = "";
      //   return a.visaStatus.length - b.visaStatus.length;
      // },
      sorter: (a, b) => a.visaStatus.length - b.visaStatus.length,
      render: (text, record) => {
        return (
          <>
            {text.length > 0 ? (
              <Dropdown overlay={this.menu(record)}>
                <Button
                  style={{
                    backgroundColor: record.visaStatusColor,
                    fontSize: 12,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  {text} <DownOutlined />
                </Button>
              </Dropdown>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Visa Date",
      dataIndex: "startDate",
      align: "center",
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
      sortDirections: ["descend", "ascend", "ascend"],
      render: (date) => {
        return date === "01/01/0001 00:00:00 +00:00" ||
          !date.length ||
          date === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Total Task",
      dataIndex: "totalTask",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.totalTask - b.totalTask,
      render: (text, record) => {
        return JSON.parse(record.todayTask)
          ? {
              children: text,
              props: {
                className: "today-task-reminders",
                onClick: () => this.viewTasks(record),
              },
            }
          : JSON.parse(record.dueTask)
          ? {
              children: text,
              props: {
                className: "dueTask",
                onClick: () => this.viewTasks(record),
              },
            }
          : {
              children: text,
              props: {
                className: "cursorPointer",
                onClick: () => this.viewTasks(record),
              },
            };
      },
    },
    {
      title: "Total Reminder",
      dataIndex: "totalReminder",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.totalReminder - b.totalReminder,
      render: (text, record) => {
        return JSON.parse(record.todayReminder)
          ? {
              children: text,
              props: {
                className: "today-task-reminders",
                onClick: () => this.viewReminders(record),
              },
            }
          : JSON.parse(record.dueReminder)
          ? {
              children: text,
              props: {
                className: "dueTask",
                onClick: () => this.viewReminders(record),
              },
            }
          : {
              children: text,
              props: {
                className: "cursorPointer",
                onClick: () => this.viewReminders(record),
              },
            };
      },
    },
    {
      title: "Contract Signed",
      dataIndex: "contractSigned",
      align: "center",
      sorter: (a, b) =>
        moment(a.contractSignedDate).unix() -
        moment(b.contractSignedDate).unix(),
      render: (data, record) =>
        record.contractSignedDate === "01/01/0001 00:00:00 +00:00" ||
        !record.contractSignedDate.length ||
        record.contractSignedDate === "01/01/1900 00:00:00 +00:00"
          ? null
          : `${data ? "Yes" : "No"}, ${moment(record.contractSignedDate).format(
              "DD/MM/YYYY"
            )}`,
    },
    {
      title: "Follow Up Date",
      dataIndex: "followUpDate",
      align: "center",
      sorter: (a, b) =>
        moment(a.followUpDate).unix() - moment(b.followUpDate).unix(),
      render: (date, record) => {
        return {
          children: (
            <DatePicker
              calendarIcon={null}
              clearIcon={null}
              onChange={(e) => this.onUpdateFollowUpDate(record, e.toJSON())}
              value={
                date === "01/01/0001 00:00:00 +00:00" ||
                !date.length ||
                date === "01/01/1900 00:00:00 +00:00"
                  ? null
                  : moment(date)
              }
              popupStyle={{ width: "210px !important" }}
              format="DD/MM/YYYY"
            />
          ),
          props: {
            className: "followDate",
          },
        };
      },
    },
    {
      title: "Modified Date",
      dataIndex: "modifiedDate",
      align: "center",
      sorter: (a, b) =>
        moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix(),
      render: (date) =>
        date === "01/01/0001 00:00:00 +00:00" ||
        !date.length ||
        date === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Processing Person",
      dataIndex: "processingPerson",
      align: "center",
      sorter: (a, b) => a.processingPerson.length - b.processingPerson.length,
    },
    {
      title: "Client Tags",
      width: "200px",
      dataIndex: "clienttags",
      align: "center",
      sorter: (a, b) => a.clienttags.length - b.clienttags.length,
    },
    {
      title: "Case Priority",
      dataIndex: "casePriority",
      align: "center",
      filters: [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Low",
          value: "Low",
        },
      ],
      onFilter: (value, record, c) => {
        casePriority = value;
        return record.casePriority.indexOf(value) === 0;
      },
      render: (text, record) => {
        return (
          <div
            className="priority-buttons"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span
              class="priority-button"
              style={{
                borderColor: "#d9534f",
                backgroundColor: text === "High" ? "#d9534f" : "#0000",
              }}
              onClick={() => {
                this.updateClientCasePriority(record, "High");
              }}
            ></span>
            <span
              class="priority-button"
              onClick={() => {
                this.updateClientCasePriority(record, "Medium");
              }}
              style={{
                borderColor: "#f0ad4e",
                backgroundColor: text === "Medium" ? "#f0ad4e" : "#0000",
              }}
            ></span>
            <span
              class="priority-button High-priority"
              onClick={() => {
                this.updateClientCasePriority(record, "Low");
              }}
              style={{
                borderColor: "#5cb85c",
                backgroundColor: text === "Low" ? "#5cb85c" : "#0000",
              }}
            ></span>
          </div>
        );
      },
    },
    {
      title: "Paid",
      dataIndex: "isPaid",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.isPaid - b.isPaid,
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: "Current Visa Expiry",
      dataIndex: "currentNewZealandVisaExpiry",
      align: "center",
      width: "200px",
      sorter: (a, b) =>
        moment(a.currentNewZealandVisaExpiry).unix() -
        moment(b.currentNewZealandVisaExpiry).unix(),
      render: (date) =>
        date === "01/01/0001 00:00:00 +00:00" ||
        !date.length ||
        date === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Medical Issue Date",
      dataIndex: "medicalIssueDate",
      align: "center",
      width: "200px",
      sorter: (a, b) =>
        moment(a.medicalIssueDate).unix() - moment(b.medicalIssueDate).unix(),
      render: (date) => {
        return date === "01/01/0001 00:00:00 +00:00" ||
          !date.length ||
          date === "01/01/1900 00:00:00 +00:00" ||
          date === "01/01/1900 00:00:00"
          ? null
          : moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Xray Issue Date",
      dataIndex: "xrayIssueDate",
      width: "200px",
      align: "center",
      sorter: (a, b) =>
        moment(a.xrayIssueDate).unix() - moment(b.xrayIssueDate).unix(),
      render: (date) => {
        return date === "01/01/0001 00:00:00 +00:00" ||
          !date.length ||
          date === "01/01/1900 00:00:00 +00:00" ||
          date === "01/01/1900 00:00:00"
          ? null
          : moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Police Certificate Date",
      dataIndex: "policeCertificate",
      width: "200px",
      align: "center",
      sorter: (a, b) =>
        moment(a.policeCertificate).unix() - moment(b.policeCertificate).unix(),
      render: (date) => {
        return date === "01/01/0001 00:00:00 +00:00" ||
          !date.length ||
          date === "01/01/1900 00:00:00 +00:00" ||
          date === "01/01/1900 00:00:00"
          ? null
          : moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Emails",
      dataIndex: "email",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.email - b.email,
      render: (text, record) => (
        <MessageTwoTone
          onClick={() => this.viewClientMails(record.id, record.familyId)}
        />
      ),
    },
    {
      title: "File Note",
      dataIndex: "fileNote",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.fileNote - b.fileNote,
      render: (text, record) => (
        <>
          <InfoCircleTwoTone onClick={() => this.viewEmailAndPhone(record)} />{" "}
          <FileTwoTone onClick={() => this.viewClientFileNote(record)} />
        </>
      ),
    },
  ];
  mainColumns = this.columns;
  emailColumns = [
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return (
          <>
            {this.getEmailButton(record)}
            {moment(text).format("DD/MM/YYYY")}
          </>
        );
      },
      width: 300,
    },
    { title: "From", dataIndex: "from" },
    { title: "To", dataIndex: "to" },
    { title: "Subject", dataIndex: "subject" },
  ];

  getEmailButton = (record) => {
    return record.importText.toLowerCase() == "inbox" ? (
      <Tag style={{ backgroundColor: "#f0ad4e", color: "#fff" }}>
        Inbox Imported
      </Tag>
    ) : record.autoImport === true ? (
      <Tag style={{ backgroundColor: "#f0ad4e", color: "#fff" }}>
        Sent Imported
      </Tag>
    ) : record.isSent === true ? (
      <Tag style={{ backgroundColor: "#5cb85c", color: "#fff" }}>
        Sent Email
      </Tag>
    ) : record.draft === true ? (
      <Tag style={{ backgroundColor: "#108ee9", color: "#fff" }}>Draft</Tag>
    ) : (
      record.import && (
        <Tag style={{ backgroundColor: "#FCBB3A", color: "#fff" }}>
          Imported
        </Tag>
      )
    );
  };

  viewClientMails = (id, familyId) => {
    this.setState({ pageLoading: true });
    let branchId = localStorage.getItem("selectedBranchId");
    this.props
      .onGetClientMails({
        id: id,
        familyId: familyId,
        emailType: "0",
        pageSize: "1000",
        pageNumber: "1",
        visaId: 0,
      })
      .then((res) => {
        this.setState({
          isEmailsModalOpen: true,
          emailData: res.payload.emailHistories,
          tempEmailData: res.payload.emailHistories,
          pageLoading: false,
        });
      })
      .catch((er) => {
        this.setState({
          isEmailsModalOpen: true,
          emailData: [],
          tempEmailData: [],
          pageLoading: false,
        });
      });
  };

  disabledDate(current) {
    return moment().add(-1, "days") >= current;
  }
  onUpdateFollowUpDate = (data, date) => {
    if (!data.subjectCaseId) return message.info("No visa case found!");
    let allow = window.confirm("Are you sure to update follow date?");
    if (!allow) return;
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientCasePriority({
        id: data.subjectCaseId,
        followUpDate: date,
        visaPriority: data.casePriority,
      })
      .then((res) => {
        this.getClientData();
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  menu = (record) => (
    <Menu style={{ overflow: "auto", height: 300 }}>
      {this.state.visaStatus.map((visa) => (
        <Menu.Item onClick={() => this.onChangeVisaCaseStatus(record, visa)}>
          {visa.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  onChangeVisaCaseStatus = (record, visa) => {
    this.setState({
      statusData: {
        id: record.subjectCaseId,
        branchVisaTypeId: record.branchVisaTypeId,
        caseStatusId: visa.id,
        statusName: visa.name,
      },
      isStatusDateModalOpen: true,
    });
  };
  updateVisaStatus = () => {
    this.setState({ pageLoading: true });
    let { statusData } = this.state;
    this.props
      .onUpdateVisaCaseStatus(statusData)
      .then((res) => {
        this.getClientData();
        this.setState({ isStatusDateModalOpen: false }, () =>
          this.resetTaskForm()
        );
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  getStatusDateModal = ({ isStatusDateModalOpen, pageLoading, statusData }) => {
    return (
      <Modal
        title={"Update Status"}
        visible={isStatusDateModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isStatusDateModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isStatusDateModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 250, overflow: "auto" }}
        width={600}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.updateVisaStatus()}
            >
              <Form.Item
                labelAlign="left"
                label="Visa Date"
                name="date"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Required!" }]}
              >
                <DatePicker
                  calendarIcon={null}
                  clearIcon={null}
                  onChange={(e) =>
                    this.setState({
                      statusData: {
                        ...this.state.statusData,
                        date: e.toJSON(),
                      },
                    })
                  }
                  format="DD/MM/YYYY"
                />
              </Form.Item>
              {statusData &&
                statusData.statusName === "Approved" &&
                this.getApprovedFields()}
              {statusData &&
                statusData.statusName === "Assessment Completed" &&
                this.getAccessmentFields()}
              {statusData &&
                statusData.statusName === "Contract Signed" &&
                this.getContractSignedFields()}
              {statusData &&
                statusData.statusName === "Declined" &&
                this.getDeclineFields()}
              {statusData &&
                statusData.statusName === "Refused" &&
                this.getRefusedFields()}
              {statusData &&
                statusData.statusName === "Selected" &&
                this.getSelectedFields()}
              {statusData &&
                statusData.statusName === "Immigration Matters (PPI)" &&
                this.getPPiFields()}
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button disabled={pageLoading} type="primary" htmlType="submit">
                  {pageLoading && <Spin size="small" />}
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };
  getApprovedFields = () => (
    <>
      <Form.Item
        name="approveDate"
        labelAlign="left"
        label="Approve Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Approve Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("approveDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="expiryDate"
        labelAlign="left"
        label="Expiry Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Expiry Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("expiryDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );
  getAccessmentFields = () => (
    <Form.Item
      name="assessmentComplete"
      labelAlign="left"
      label="Assesment Completed Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Assessment Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeDate("assessmentCompletedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getContractSignedFields = () => (
    <Form.Item
      name="contractSignedDate"
      labelAlign="left"
      label="Contract Signed Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Contract Signed Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeDate("contractSignedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );

  getDeclineFields = () => (
    <>
      <Form.Item
        name="declineDate"
        labelAlign="left"
        label="Decline Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Decline Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("declineDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="declinedescription"
        labelAlign="left"
        label="Decline Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                declineDescription: e.target.value,
              },
            })
          }
        />
      </Form.Item>
    </>
  );
  getRefusedFields = () => (
    <>
      <Form.Item
        name="refusedDate"
        labelAlign="left"
        label="Refused Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select Refused Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("refusedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="refusedDescription"
        labelAlign="left"
        label="Refused Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                refusedDescription: e.target.value,
              },
            })
          }
        />
      </Form.Item>
    </>
  );
  getSelectedFields = () => (
    <>
      <Form.Item
        name="eoiSelectDate"
        labelAlign="left"
        label="EOI Selected Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select EOI Selected Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("eoiSelectedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="eoiitaDate"
        labelAlign="left"
        label="EOI Ita Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select EOI Ita Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("eoiitaDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );

  getPPiFields = () => (
    <>
      <Form.Item
        name="description"
        labelAlign="left"
        label="PPI Description"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Input
          placeholder="Select PPI Description"
          onChange={(e) =>
            this.setState({
              caseStatusData: {
                ...this.state.caseStatusData,
                description: e.target.value,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item
        name="recieveDate"
        labelAlign="left"
        label="PPI Recieved Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Recieved Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("receivedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="ppiduedate"
        labelAlign="left"
        label="PPI Due Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Due Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("dueDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="extendedDate"
        labelAlign="left"
        label="PPI Extended Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Extended Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("extendDueDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
      <Form.Item
        name="submitteddate"
        labelAlign="left"
        label="PPI Submitted Date"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Required!" }]}
      >
        <DatePicker
          placeholder="Select PPI Submitted Date"
          calendarIcon={null}
          clearIcon={null}
          onChange={(e) => this.onChangeDate("submittedDate", e)}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </>
  );
  getStartDateFields = () => (
    <Form.Item
      name="startdate"
      labelAlign="left"
      label="Start Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Start Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("startDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getAppliedDateFields = () => (
    <Form.Item
      name="applieddate"
      labelAlign="left"
      label="Applied Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Applied Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("appliedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getConditionalDateFields = () => (
    <Form.Item
      name="conditionaldate"
      labelAlign="left"
      label="Conditional Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Conditional Offer Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("conditionalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getFormalDateFields = () => (
    <Form.Item
      name="formaldate"
      labelAlign="left"
      label="Formal Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Formal Offer Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("formalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getPaidDateFields = () => (
    <Form.Item
      name="paiddate"
      labelAlign="left"
      label="Paid Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Paid Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("paidDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getDeclineDateFields = () => (
    <Form.Item
      name="declinedate"
      labelAlign="left"
      label="Decline Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Decline Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("declineDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  onChangeDate = (type, val) => {
    let selectedDate = val.toJSON();
    this.setState({
      statusData: {
        ...this.state.statusData,
        [type]: selectedDate,
      },
    });
  };
  getEmailsModal = ({ isEmailsModalOpen, tempEmailData }) => {
    return (
      <Modal
        title={"Emails"}
        visible={isEmailsModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isEmailsModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isEmailsModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 550, overflowY: "scroll" }}
        width={1192}
      >
        <div style={{ width: "100%" }}>
          <div>
            <div class="select-optionss" style={{ paddingRight: 1 }}>
              <Select
                style={{ width: 120, borderRadius: 0 }}
                placeholder="Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={this.onChangeEmailStatus.bind(this)}
              >
                <Option value={"all"}>All</Option>
                <Option value={"isSent"}>Sent</Option>
                <Option value={"draft"}>Draft</Option>
                <Option value={"inboximport"}>Inbox Imported</Option>
                <Option value={"sentimport"}>Sent Imported</Option>
                <Option value={"import"}>Imported</Option>
              </Select>
            </div>
            {this.getEmailsTable(tempEmailData)}
          </div>
        </div>
      </Modal>
    );
  };
  onChangeEmailStatus = (val) => {
    let { emailData } = this.state;
    if (val === "all") return this.setState({ tempEmailData: emailData });
    if (val === "isSent")
      return this.setState({
        tempEmailData: emailData.filter(
          (el) =>
            el.isSent &&
            el.importText.toLowerCase() !== "inbox" &&
            !el.autoImport
        ),
      });
    if (val === "import")
      return this.setState({
        tempEmailData: emailData.filter((el) => el.import),
      });
    if (val === "draft")
      return this.setState({
        tempEmailData: emailData.filter((el) => el.draft),
      });
    if (val === "inboximport")
      return this.setState({
        tempEmailData: emailData.filter(
          (el) => el.importText.toLowerCase() === "inbox"
        ),
      });
    if (val === "sentimport")
      return this.setState({
        tempEmailData: emailData.filter(
          (el) => el.autoImport && el.importText.toLowerCase() !== "inbox"
        ),
      });
  };

  getEmailsTable = (emailData) => (
    <div className="email-table">
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        columns={this.emailColumns}
        dataSource={emailData}
        scroll={{ x: "auto" }}
      />
    </div>
  );

  updateClientPriority = (data, priority) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientPriority({
        subjectId: data.id,
        priority: priority,
      })
      .then(() => this.getClientData())
      .catch((er) => this.setState({ pageLoading: false }));
  };
  updateClientCasePriority = (data, priority) => {
    if (!data.subjectCaseId) return message.info("No visa case found");
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientCasePriority({
        id: data.subjectCaseId,
        visaPriority: priority,
        followUpDate: moment(data.followUpDate),
      })
      .then((res) => {
        this.getClientData();
      })
      .catch((er) => this.setState({ pageLoading: false }));
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChangeProcessingPerson = (value) => {
    this.setState({ processingPerson: value }, () =>
      this.paginate({ pageSize: this.state.pageSize, current: 1 })
    );
  };
  onChangeCountry = (value) => {
    this.setState(
      {
        selectedCountry: value === "0" ? 0 : value,
        onDefault: false,
      },
      () => this.paginate({ pageSize: this.state.pageSize, current: 1 })
    );
    this.props.onGetVisaTypeByCountry(value);
  };

  onChangeVisaType = (value) => {
    this.setState(
      {
        selectedVisaType: value,
      },
      () => this.paginate({ pageSize: this.state.pageSize, current: 1 })
    );
  };

  onChangeVisaStatus = (value) => {
    this.setState(
      {
        selectedVisaStatus: value,
      },
      () => this.paginate({ pageSize: this.state.pageSize, current: 1 })
    );
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  componentDidMount = () => {
    let { currentPage, pageSize } = this.state;
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.setState({ pageLoading: true, selectedBranch: selectedBranchId });
    let userId = localStorage.getItem("userId");
    this.props.onGetClientSummarySettings();
    this.props.onGetBVisaCountries().then(() => {
      let findCountry =
        this.props.brnchVisaCountriesData &&
        this.props.brnchVisaCountriesData.find(
          (obj) => obj.countryId == localStorage.getItem("defaultCountryId")
        );

      this.props.onGetClientSummary({
        countryName: findCountry ? findCountry.countryName : "",
        pageSize: pageSize,
        pageNumber: currentPage,
        processingPerson:
          // localStorage.getItem("userOwner") === "True"
          //   ? "00000000-0000-0000-0000-000000000000"
          //   : localStorage.getItem("userManager") === "true"
          //   ? "00000000-0000-0000-0000-000000000000"
          //   : localStorage.getItem("userOwner") !== "True" &&
          //     localStorage.getItem("userManager") !== "true" &&
          //     JSON.parse(
          //       decodeURIComponent(
          //         escape(
          //           window.atob(localStorage.getItem("userSystemPermissions"))
          //         )
          //       )
          //     ).find((x) => x.role.toLowerCase() === "client database").status ===
          //       1
          //   ? "00000000-0000-0000-0000-000000000000"
          localStorage.getItem("userId"),
        priority: "",
        casePriority: "",
      });
    });

    this.props.onGetPermProcessingPerson();
    this.props.onGetAllCountries();
    this.props.onGetCaseStatuses();
    let countryIdDef = localStorage.getItem("defaultCountryId") || 0;
    this.props.onGetVisaTypeByCountry(countryIdDef);
    this.props.onGetVisaStatus();
  };
  getClientData = () => {
    let {
      currentPage,
      pageSize,
      selectedBranch,
      selectedCountry,
      processingPerson,
    } = this.state;
    this.setState({ pageLoading: true });

    let userId = localStorage.getItem("userId");
    var findCountry = this.state.Countries.find(
      (obj) => obj.countryId == selectedCountry
    );
    this.props.onGetClientSummary({
      countryName: findCountry ? findCountry.countryName : "",
      pageSize: pageSize,
      pageNumber: currentPage,
      processingPerson: processingPerson,
      priority: priority,
      casePriority: casePriority,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.permProcessingPerson !== this.props.permProcessingPerson) {
      var findPerson =
        this.props.permProcessingPerson &&
        this.props.permProcessingPerson.users &&
        this.props.permProcessingPerson.users.find(
          (obj) => obj.id === localStorage.getItem("userId")
        );
      if (findPerson) {
        this.setState({ processingPerson: findPerson.id });
      }
      this.setState({
        ProcessingPersons: this.props.permProcessingPerson.users,
      });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let clientSummaries =
      nextProps &&
      nextProps.clientSummaryList &&
      nextProps.clientSummaryList.clients;
    let clientCounts =
      nextProps &&
      nextProps.clientSummaryList &&
      nextProps.clientSummaryList.visaCount;
    let processingPersons =
      nextProps &&
      nextProps.permProcessingPerson &&
      nextProps.permProcessingPerson.users;
    let clientSummaryError = nextProps && nextProps.clientSummaryError;

    let clientSummariesSettings =
      nextProps &&
      nextProps.clientSummarySettings &&
      nextProps.clientSummarySettings.clientDashboard;
    let countryList =
      nextProps &&
      nextProps.brnchVisaCountriesData &&
      nextProps.brnchVisaCountriesData;
    let counts = nextProps && nextProps.clientSummaryList;
    let visaStatus =
      nextProps && nextProps.visaStatus && nextProps.visaStatus.items;
    if (clientSummaries) {
      let { count, high, low, medium, onShore, visaCount } = counts;
      this.setState({
        Clients: clientSummaries,
        pageLoading: false,
        totalPages: clientCounts,
        Counts: { count, high, low, medium, onShore, visaCount },
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
      });
    }
    if (nextProps.visaTypeData && nextProps.visaTypeData.items) {
      let visas = [];
      if (nextProps.visaTypeData.items.length > 0)
        visas.push(<Option key="">All</Option>);
      for (var i = 0; i < nextProps.visaTypeData.items.length; i++) {
        visas.push(
          <Option key={nextProps.visaTypeData.items[i].id}>
            {nextProps.visaTypeData.items[i].visaTypeName}
          </Option>
        );
      }
      this.setState({ visaTypes: visas });
    }
    if (nextProps.visaStatusData && nextProps.visaStatusData.items) {
      let statuses = [];
      if (nextProps.visaStatusData.items.length > 0)
        statuses.push(<Option key="">All</Option>);
      for (var i = 0; i < nextProps.visaStatusData.items.length; i++) {
        statuses.push(
          <Option key={nextProps.visaStatusData.items[i].id}>
            {nextProps.visaStatusData.items[i].name}
          </Option>
        );
      }
      this.setState({ visaStatuses: statuses });
    }
    if (clientSummaryError) this.setState({ pageLoading: false });
    if (clientSummariesSettings) {
      this.setState({ DashbordSettings: clientSummariesSettings }, () =>
        this.filterColumns(clientSummariesSettings)
      );
    }
    // if (processingPersons) {
    //   var findPerson = processingPersons.find(
    //     (obj) => obj.id === localStorage.getItem("userId")
    //   );
    //
    //   if (findPerson) {
    //     this.setState({ processingPerson: findPerson.id });
    //   }
    //   this.setState({ ProcessingPersons: processingPersons });
    // }
    if (nextProps.selectedBranch)
      if (nextProps.selectedBranch !== this.state.selectedBranch) {
        this.setState(
          { selectedBranch: nextProps.selectedBranch },
          () => {}
          // this.getClientData()
        );
      }
    let _countryList = [];
    // if (countryList && countryList.length > 0) {
    //   for (let index = 0; index < countryList.length; index++) {
    //     if (
    //       countryList[index].name === "AUSTRALIA" ||
    //       countryList[index].name === "CANADA" ||
    //       countryList[index].name === "NEW ZEALAND"
    //     ) {
    //       _countryList.push(countryList[index]);
    //     } else {
    //     }
    //   }
    // }

    let countryId =
      countryList &&
      countryList.find(
        (obj) => obj.countryId == localStorage.getItem("defaultCountryId")
      );
    if (
      this.state.onDefault &&
      localStorage.getItem("defaultCountryId") &&
      localStorage.getItem("defaultCountryId") != 0 &&
      countryId
    ) {
      this.setState({
        selectedCountry: localStorage.getItem("defaultCountryId"),
      });
    }
    this.setState({ Countries: countryList });
    if (visaStatus)
      this.setState({
        visaStatus: visaStatus.sort((a, b) => a.name.localeCompare(b.name)),
      });
  }
  filterColumns = (settings) => {
    this.mainColumns = this.columns.filter((col) => settings[col.dataIndex]);
  };

  paginate = (value) => {
    let { current, pageSize } = value;
    let {
      processingPerson,
      selectedBranch,
      selectedCountry,
      selectedVisaType,
      selectedVisaStatus,
    } = this.state;
    console.log(value);

    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");
    this.setState(
      { pageSize: value.pageSize, currentPage: value.current },
      () => {
        var findCountry = this.state.Countries.find(
          (obj) => obj.countryId == selectedCountry
        );
        this.props
          .onGetClientSummary({
            countryName: findCountry ? findCountry.countryName : "",
            pageSize: pageSize,
            pageNumber: current,
            processingPerson: processingPerson,
            visaTypeId: selectedVisaType ? parseInt(selectedVisaType) : 0,
            visaStatusId: selectedVisaStatus ? parseInt(selectedVisaStatus) : 0,
            priority: priority,
            casePriority: casePriority,
          })
          .then(() => this.setState({ pageLoading: false }))
          .catch(() => this.setState({ pageLoading: false }));
      }
    );
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };

  getSettingsModal = ({
    isSettingsModalOpen,
    pageLoading,
    DashbordSettings,
  }) => (
    <Modal
      title={"Dashboard Settings"}
      visible={isSettingsModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isSettingsModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isSettingsModalOpen: false });
      }}
      header={true}
      footer={false}
      bodyStyle={{ maxHeight: 450, overflow: "auto" }}
      width={992}
    >
      <div style={{ width: "100%" }}>
        <div>
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            className="grid-view"
            onFinish={() => {
              this.updateDashboardSettings();
            }}
          >
            {Object.keys(DashbordSettings).map((key) => (
              <Form.Item name={key}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Checkbox
                  checked={DashbordSettings[key]}
                  onChange={(e) => this.onChangeSetting(key, e.target.checked)}
                >
                  {columnObject[key]}
                  {/* {key} */}
                </Checkbox>
              </Form.Item>
            ))}
            <Form.Item {...tailLayout} className="c-footer-button">
              <Button
                disabled={pageLoading}
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#1179af",
                  color: "#fff",
                  height: 32,
                  borderRadius: 5,
                  border: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                {pageLoading && <Spin size="small" />}
                Save
              </Button>
              <Button
                style={{
                  margin: "0 5px",
                  backgroundColor: "#1179af",
                  color: "#fff",
                  height: 32,
                  borderRadius: 5,
                  border: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
                onClick={() => {
                  this.resetTaskForm();
                  this.setState({ isSettingsModalOpen: false });
                }}
                type="primary"
              >
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
  onChangeSetting = (key, val) => {
    this.setState({
      DashbordSettings: { ...this.state.DashbordSettings, [key]: val },
    });
  };

  updateDashboardSettings = () => {
    let { DashbordSettings } = this.state;
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientSummarySettings(DashbordSettings)
      .then(() => {
        this.filterColumns(DashbordSettings);
        this.setState(
          { pageLoading: false, isSettingsModalOpen: false },
          () => {
            openNotificationWithIcon(
              "success",
              "Client Summary settings updated"
            );
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            pageLoading: false,
          },
          () =>
            openNotificationWithIcon(
              "error",
              "Error:",
              "Could't update settings."
            )
        );
      });
  };
  sortDailyTaskAsc = (tasks) => {
    let { newTaskObj } = this.state;
    let data = tasks.sort((a, b) => {
      if (moment(a.taskDate) > moment(b.taskDate)) return 1;
      if (moment(a.taskDate) < moment(b.taskDate)) return -1;
      else return 0;
    });
    this.setState({
      newTaskObj: { ...newTaskObj, currentTasks: data, pageLoading: false },
    });
  };
  sortDailyTaskDesc = (tasks) => {
    let { newTaskObj } = this.state;
    let data = tasks.sort((a, b) => {
      if (moment(a.taskDate) > moment(b.taskDate)) return -1;
      if (moment(a.taskDate) < moment(b.taskDate)) return 1;
      else return 0;
    });
    this.setState({
      newTaskObj: { ...newTaskObj, currentTasks: data, pageLoading: false },
    });
  };
  getAddTaskModal = ({
    isTaskModalOpen,
    pageLoading,
    ProcessingPersons,
    newTaskObj,
  }) => {
    return (
      <Modal
        title={"Details"}
        visible={isTaskModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 450, overflowY: "scroll" }}
        width={992}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => this.addNewTask()}
              >
                <Form.Item
                  name="date"
                  labelAlign="left"
                  label="Due Date"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <DatePicker
                    calendarIcon={null}
                    placeholder="Select Date"
                    clearIcon={null}
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskDate", e.toJSON())
                    }
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Task Title :"
                  name="Title"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskTitle", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Task Description :"
                  name="Description"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      this.onChangeTaskHandler(
                        "taskDescription",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Add Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                >
                  <Select
                    showSearch
                    style={{ borderRadius: 0 }}
                    placeholder="Processing Persons"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue="a"
                    onSelect={this.onChangeFollower.bind(this)}
                    // value={processingPerson ? processingPerson : null}
                  >
                    <Option value={"a"}>Please Select</Option>
                    {ProcessingPersons.map((person) => (
                      <Option value={person.id}>{person.fullName}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                >
                  {newTaskObj && newTaskObj.taskUsers
                    ? newTaskObj.taskUsers.map((el, ind) => (
                        <>
                          <Tag color="#2db7f5">
                            <span
                              onClick={() => {
                                newTaskObj.taskUsers.splice(ind, 1);
                                this.setState({ newTaskObj: newTaskObj });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <CloseOutlined />
                            </span>
                            {el.name}
                          </Tag>
                        </>
                      ))
                    : null}
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div>
            <span style={{ fontSize: 14, fontWeight: "500", color: "#266090" }}>
              TASKS
            </span>
            <span>
              <div style={{ marginLeft: "auto", float: "right" }}>
                <UpSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => this.sortDailyTaskAsc(newTaskObj.currentTasks)}
                />{" "}
                <DownSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.sortDailyTaskDesc(newTaskObj.currentTasks)
                  }
                />
              </div>
            </span>
          </div>

          <div className="modalTable">
            {newTaskObj.currentTasks
              ? newTaskObj.currentTasks.map((task) => (
                  <>
                    <div
                      className="cm-table-content-row"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="content-index-50">
                        <div
                          class="cm-greenbox-cont"
                          style={{ display: "-webkit-inline-box" }}
                        >
                          <div
                            onClick={() => this.onCompleteTask(task)}
                            onMouseOver={(e) => this.onHover(e, task)}
                            onMouseOut={(e) => this.onMouseOuts(e, task)}
                            class="cm-green-box"
                            style={{
                              backgroundColor: task.isCompleted
                                ? "#3ab449"
                                : "orange",
                              marginRight: 10,
                            }}
                          ></div>
                          <span
                            onClick={() => {
                              task.isTaskCommentOpen = !task.isTaskCommentOpen;
                              if (!task.isTaskCommentOpen)
                                return this.setState({
                                  newTaskObj: this.state.newTaskObj,
                                });
                              this.setState({
                                pageLoading: true,
                              });
                              this.getTaskComments(task);
                              this.getTaskFollowers(task);
                            }}
                            class="cm-task-text"
                            style={{ marginLeft: 0 }}
                          >
                            {task.taskTitle}
                          </span>
                          <span>
                            ({moment(task.taskDate).format("DD/MM/YYYY")})
                          </span>
                        </div>
                      </div>

                      <div style={{ display: "flex" }} class="content-index-10">
                        <div
                          onClick={() =>
                            this.setState({
                              isFollowerModalOpen: true,
                              updateTask: task,
                            })
                          }
                          className="client-user-cont"
                        >
                          <img
                            src={Images.userCircleGray}
                            style={{ width: 15, height: 15 }}
                          />
                          <img
                            src={Images.nextGray}
                            style={{
                              marginLeft: 5,
                              transform: `rotate(90deg)`,
                              width: 12,
                              height: 12,
                            }}
                          />
                        </div>
                        <img
                          src={Images.brownCalendar}
                          class="cm-icon-size"
                          style={{ marginRight: 5, cursor: "pointer" }}
                          onClick={() =>
                            this.setState({
                              isRescheduleTaskModalOpen: true,
                              updateTask: task,
                            })
                          }
                        />
                        {task.isCompleted ? (
                          <Popconfirm
                            title="Are you sure to reset this task?"
                            onConfirm={() => {
                              task.isCompleted = false;
                              this.onUpdateTask(task);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <a href="#">
                              <img
                                src={Images.refreshArrow}
                                class="cm-icon-size"
                                style={{ marginLeft: 5 }}
                              />
                            </a>
                          </Popconfirm>
                        ) : (
                          <EditTwoTone
                            onClick={() =>
                              this.setState(
                                {
                                  updateTask: task,
                                  isUpdateTask: true,
                                },
                                () =>
                                  this.formRef.current.setFieldsValue({
                                    Title: task.taskTitle,
                                  })
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                    {task.isTaskCommentOpen && (
                      <>
                        <div
                          className="cm-table-content-row"
                          style={{
                            marginTop: 0,
                            alignItems: "flex-start",
                            border: 0,
                          }}
                        >
                          <div>
                            <div
                              className="content-index-5"
                              style={{ marginTop: 25, marginLeft: 10 }}
                            >
                              <div style={{ display: "flex" }}>
                                <span class="cm-followers-text">Followers</span>
                                {task.users
                                  ? task.users.map((item) => (
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {item.imgURL &&
                                        item.imgURL != null &&
                                        item.imgURL != "" ? (
                                          <Tooltip
                                            title={item.fullName}
                                            color="#eae9d4"
                                            overlayInnerStyle={{
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <CloseCircleOutlined
                                                style={{
                                                  position: "absolute",
                                                  right: 0,
                                                  top: -8,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.removeFollower(
                                                    item.id,
                                                    task
                                                  )
                                                }
                                              />
                                              <Avatar
                                                src={
                                                  <Image
                                                    src={item.imgURL}
                                                    style={{
                                                      height: 32,
                                                      width: 32,
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title={item.fullName}
                                            color="#eae9d4"
                                            overlayInnerStyle={{
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <CloseCircleOutlined
                                                style={{
                                                  position: "absolute",
                                                  right: 0,
                                                  top: -8,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.removeFollower(
                                                    item.id,
                                                    task
                                                  )
                                                }
                                              />
                                              <Avatar
                                                className="mar-right"
                                                size="small"
                                                style={{
                                                  height: 32,
                                                  width: 32,
                                                }}
                                                icon={
                                                  <UserOutlined
                                                    style={{
                                                      height: 32,
                                                      width: 32,
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        )}
                                      </span>
                                    ))
                                  : null}
                              </div>
                            </div>
                          </div>

                          <div
                            className="content-index-5"
                            style={{ marginTop: 3 }}
                            onClick={() => {
                              task.isTaskCommentOpen = !task.isTaskCommentOpen;
                              this.setState({
                                cliens: this.state.clients,
                              });
                            }}
                          >
                            <img
                              src={grayCross}
                              class="cm-icon-size"
                              style={{ marginLeft: 5 }}
                            />
                          </div>
                        </div>
                        <textarea
                          value={task.comment ? task.comment : ""}
                          onChange={(e) => {
                            task.comment = e.target.value;
                            this.setState({
                              clients: this.state.clients,
                            });
                          }}
                          rows={5}
                          class="cm-textarea"
                        />

                        <div class="cm-gray-btn-cont">
                          <div></div>
                          <Button
                            disabled={
                              task.comment
                                ? task.comment.length
                                  ? false
                                  : true
                                : true
                            }
                            style={{
                              backgroundColor: task.comment
                                ? task.comment.length
                                  ? "blue"
                                  : "grey"
                                : "grey",
                            }}
                            onClick={() => this.addTaskComment(task)}
                            // class="cm-gray-btn"
                          >
                            <span class="cm-gray-btn-text">Comment</span>
                            <img
                              src={rightArrow}
                              class="cm-icon-size"
                              style={{ marginLeft: 10 }}
                            />
                          </Button>
                        </div>
                        {task.comments ? (
                          <div
                            className="cm-table-content-rows"
                            style={{ marginBottom: 0 }}
                          >
                            {task.comments.map((el) => (
                              <>
                                <div class="cm-profile-boxes">
                                  <div class="cm-profile-fill-conts">
                                    {el.imgURL ? (
                                      <img
                                        src={el.imgURL}
                                        class="cm-profile-fills"
                                      />
                                    ) : null}
                                  </div>
                                  <div style={{ alignSelf: "center" }}>
                                    <h4>{el.userName}</h4>
                                  </div>
                                </div>
                                <div className="content-index-100">
                                  <div>
                                    <span class="cm-task-texts">
                                      {el.description}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ))
              : null}
          </div>
        </Spin>
      </Modal>
    );
  };
  getTaskComments = (task) => {
    this.props
      .onGetTaskComment(task.id)
      .then((res) => {
        let comm = res.payload && res.payload.items;
        if (comm) {
          task.comments = comm;
          task.comment = "";
          this.setState({
            newTaskObj: this.state.newTaskObj,
            pageLoading: false,
          });
        }
        this.setState({ pageLoading: false });
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  getTaskFollowers = (task) => {
    this.props.onGetTaskFollowers(task.id).then((res) => {
      let users = res.payload && res.payload.items;
      if (users) {
        task.users = users;
        this.setState({
          newTaskObj: this.state.newTaskObj,
          pageLoading: false,
        });
      }
      this.setState({ pageLoading: false });
    });
  };
  removeFollower = (id, task) => {
    let userId = localStorage.getItem("userId");
    let payload = {
      id: id,
      delete: true,
    };
    this.setState({ pageLoading: true });
    this.props.onRemoveFollower(payload).then((res) => {
      message.success("Follower Removed!");
      task.users = task.users.filter((el) => el.id !== id);
      this.setState({ newTaskObj: this.state.newTaskObj, pageLoading: false });
    });
  };
  addTaskComment = (task) => {
    this.setState({ pageLoading: true });
    let { id, comment } = task;
    let userId = localStorage.getItem("userId");
    this.props
      .onAddTaskComment({
        taskId: id,
        description: comment,
      })
      .then(() => {
        openNotificationWithIcon("success", "Comment added!");
        this.getTaskComments(task);
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  getUpdateTaskModal = ({ isUpdateTask, pageLoading, updateTask }) => {
    return (
      <Modal
        title={"Update Task"}
        visible={isUpdateTask}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isUpdateTask: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isUpdateTask: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 250, overflow: "auto" }}
        width={492}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.onUpdateTask(updateTask)}
            >
              <Form.Item
                // className={"add-client-form-inputs"}
                colon={false}
                labelAlign="left"
                label="Task Title"
                name="Title"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Input
                  onChange={(e) => {
                    updateTask.taskTitle = e.target.value;
                  }}
                  value={updateTask.taskTitle ? updateTask.taskTitle : ""}
                />
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button disabled={pageLoading} type="primary" htmlType="submit">
                  {pageLoading && <Spin size="small" />}
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };
  onUpdateTask = (task) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientTask(task)
      .then(() => {
        this.setState(
          {
            Clients: this.state.Clients,
            pageLoading: false,
            isUpdateTask: false,
            isRescheduleTaskModalOpen: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  onCompleteTask = (task) => {
    if (task.isCompleted) return;
    let userId = localStorage.getItem("userId");
    this.setState({ pageLoading: true });
    this.props
      .onCompleteClientTask({ id: task.id })
      .then(() => {
        task.isCompleted = true;
        this.setState({ Clients: this.state.Clients, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  onChangeFollower = (val, val2) => {
    let { newTaskObj } = this.state;
    this.setState(
      {
        newTaskObj: {
          ...newTaskObj,
          taskUsers: [
            ...newTaskObj.taskUsers,
            { userId: val, name: val2.children },
          ],
        },
      },
      () => this.formRef.current.setFieldsValue({ followers: "a" })
    );
  };
  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };
  addNewTask = () => {
    let branchId = localStorage.getItem("selectedBranchId");
    let userId = localStorage.getItem("userId");

    this.setState({ pageLoading: true });
    let {
      subjectId,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers,
    } = this.state.newTaskObj;
    let users = taskUsers.map((el) => ({ userId: el.userId }));
    let taskObj = {
      subjectId,
      isPontential: false,
      isCompleted: false,
      caseCheckListId: 0,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers: users.length
        ? [...users, { userId: userId }]
        : [{ userId: userId }],
    };
    this.props
      .onAddTask(taskObj)
      .then((res) => {
        this.getTasks({ id: subjectId });
      })
      .catch((err) => {
        this.setState({ pageLoading: false });
      });
  };

  viewTasks = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: data,
          },
          pageLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: [],
          },
          pageLoading: false,
        })
      );
  };
  getTasks = (record) => {
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        openNotificationWithIcon("success", "Task Added");
        this.setState(
          {
            newTaskObj: {
              ...this.state.newTaskObj,
              currentTasks: data,
              taskUsers: [],
            },
            pageLoading: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch(() =>
        this.setState(
          {
            pageLoading: false,
          },
          () => openNotificationWithIcon("error", "Error:", "could't add task")
        )
      );
  };
  getReminderModal = ({ isReminderModalOpen, pageLoading, newReminderObj }) => {
    return (
      <Modal
        title={"Details"}
        visible={isReminderModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isReminderModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isReminderModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 450, overflowY: "scroll" }}
        width={992}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => this.addNewReminder()}
              >
                <Form.Item
                  name="date"
                  labelAlign="left"
                  label="Date"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <DatePicker
                    calendarIcon={null}
                    placeholder="Select Date"
                    clearIcon={null}
                    onChange={(e) =>
                      this.onChangeReminderHandler("reminderDate", e.toJSON())
                    }
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Reminder Title :"
                  name="Title"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeReminderHandler(
                        "reminderDetail",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Reminder Description :"
                  name="Description"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      this.onChangeReminderHandler(
                        "reminderDescription",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Add Reminder
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div>
            <span style={{ fontSize: 14, fontWeight: "500", color: "#266090" }}>
              REMINDER
            </span>
          </div>
          <div className="modalTable">
            {newReminderObj.reminders && newReminderObj.reminders.length
              ? newReminderObj.reminders.map((el) => (
                  <div
                    className="cm-table-content-row"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="content-index-40">
                      <div
                        class="cm-greenbox-cont"
                        style={{
                          marginLeft: 0,
                          marginBottom: 5,
                          display: "-webkit-inline-box",
                        }}
                      >
                        <div
                          onClick={() =>
                            this.onCompleteReminder(el, newReminderObj)
                          }
                          onMouseOver={(e) => this.onHover(e, el)}
                          onMouseOut={(e) => this.onMouseOuts(e, el)}
                          class="cm-green-box"
                          style={{
                            backgroundColor: el.isCompleted
                              ? "#3ab449"
                              : "orange",
                            marginRight: 10,
                          }}
                        ></div>
                        <span class="cm-task-text" style={{ marginLeft: 0 }}>
                          {el.reminderDetail}
                        </span>
                      </div>
                      <div className="d-flex">
                        <div style={{ marginRight: 5 }}>
                          {el.reminderDescription}
                        </div>
                        <div>
                          {moment(el.reminderDate).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="content-index-5"></div>
                  </div>
                ))
              : null}
          </div>
        </Spin>
      </Modal>
    );
  };
  onHover = (e, reminder) => {
    if (!reminder.isCompleted) e.target.style["background-color"] = "#3ab449";
  };

  onMouseOuts = (e, reminder) => {
    if (!reminder.isCompleted) e.target.style["background-color"] = "orange";
  };

  viewReminders = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetReminders(record.id)
      .then((res) => {
        let data = res.payload && res.payload.items;
        if (data) {
          this.setState({
            isReminderModalOpen: true,
            newReminderObj: {
              subjectId: record.id,
              subjectName: record.name,
              reminderTypeId: 1,
              isCompleted: false,
              reminders: data,
            },
            pageLoading: false,
          });
        }
      })
      .catch((er) => {
        this.setState({
          isReminderModalOpen: true,
          newReminderObj: {
            subjectId: record.id,
            subjectName: record.name,
            reminderTypeId: 1,
            isCompleted: false,
            reminders: [],
          },
          pageLoading: false,
        });
      });
  };
  onChangeReminderHandler = (type, val) => {
    this.setState({
      newReminderObj: { ...this.state.newReminderObj, [type]: val },
    });
  };
  addNewReminder = () => {
    this.setState({ pageLoading: true });
    let {
      isCompleted,
      reminderDate,
      reminderDescription,
      reminderDetail,
      reminderTypeId,
      subjectId,
      subjectName,
    } = this.state.newReminderObj;
    this.props
      .onAddReminder({
        isCompleted,
        reminderDate,
        reminderDescription,
        reminderDetail,
        reminderTypeId,
        subjectId,
        subjectName,
        valueA: "",
        expiryDate: reminderDate,
      })
      .then((res) => {
        this.getReminders(subjectId);
        openNotificationWithIcon("success", "Reminder Added");
      })
      .catch((er) => {
        this.setState({ pageLoading: false });

        openNotificationWithIcon("error", "Error", "Could't add reminder");
      });
  };
  getReminders = (id) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetReminders(id)
      .then((res) => {
        let data = res.payload && res.payload.items;
        if (data) {
          let { newReminderObj } = this.state;
          this.setState({
            newReminderObj: {
              ...newReminderObj,
              reminders: data,
            },
            pageLoading: false,
          });
        }
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  onCompleteReminder = (reminder, { subjectId }) => {
    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");
    this.props
      .onUpdateReminder({
        id: reminder.id,
        isCompleted: true,
      })
      .then((res) => {
        this.getReminders(subjectId);
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };

  getRescheduleTaskModal = ({
    isRescheduleTaskModalOpen,
    updateTask,
    pageLoading,
  }) => (
    <Modal
      title={"Reschedule Task"}
      visible={isRescheduleTaskModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isRescheduleTaskModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isRescheduleTaskModalOpen: false });
      }}
      header={true}
      footer={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            overflow: "inherit",
            paddingBottom: 6,
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 5,
          }}
        >
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            onFinish={() => this.onUpdateTask(updateTask)}
          >
            <Form.Item
              name="date"
              labelAlign="left"
              label="Due Date"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Required!" }]}
            >
              <DatePicker
                calendarIcon={null}
                clearIcon={null}
                onChange={(e) => {
                  updateTask.taskDate = e.toJSON();
                }}
                placeholder="Select Date"
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
              <Button disabled={pageLoading} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );

  getFileNoteModal = ({
    isFileNoteModalOpen,
    fileNoteObj,
    pageLoading,
    fileNotes,
  }) => (
    <Modal
      title={"File Notes"}
      visible={isFileNoteModalOpen}
      onOk={() => {
        this.setState({ isFileNoteModalOpen: false });
      }}
      onCancel={() => {
        this.setState({ isFileNoteModalOpen: false });
      }}
      header={true}
      footer={false}
      width={1000}
      bodyStyle={{ maxHeight: 550, overflow: "scroll" }}
    >
      <Spin spinning={pageLoading} size="large">
        <div style={{ width: "100%" }}>
          <div className="file-note-modal">
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.onAddClientFile()}
            >
              <Form.Item name="fileNote" style={{ width: "100%" }}>
                <div className="letter-froala">
                  <FroalaEditor
                    ref={(el) => {
                      config = el;
                    }}
                    config={config}
                    name="model_data"
                    model={fileNoteObj.fileNote}
                    onModelChange={this.handleNoteModelChange}
                  />
                </div>
              </Form.Item>
              <Form.Item style={{ marginLeft: 0 }}>
                <Button
                  disabled={pageLoading}
                  className="button-blue"
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 0 }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="file-note">
            {fileNotes &&
              fileNotes.map((el) => (
                <>
                  <div className="file-note-list">
                    <div className="d-flex">
                      <p style={{ marginRight: 10 }}>
                        {moment(el.createdDate).format("DD/MM/YYYY")}
                      </p>
                      <p>{moment(el.createdDate).format("HH:MM A")}</p>
                    </div>
                    <div>{renderHTML(el.fileNote)}</div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </Spin>
    </Modal>
  );
  handleNoteModelChange = (val) => {
    let { fileNoteObj } = this.state;
    this.setState({ fileNoteObj: { ...fileNoteObj, fileNote: val } });
  };

  onAddClientFile = () => {
    this.setState({ pageLoading: true });
    let { fileNoteObj } = this.state;
    let { onAddClientFile } = this.props;
    onAddClientFile(fileNoteObj)
      .then((res) => {
        this.getAllFileNotes(fileNoteObj.clientId);
        openNotificationWithIcon("success", "File Note Added!");
        this.setState(
          {
            pageLoading: false,
            fileNoteObj: { ...fileNoteObj, fileNote: "" },
          },
          () => config.editor.html.set("")
        );
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  viewClientFileNote = ({ id }) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isFileNoteModalOpen: true,
          fileNoteObj: { clientId: id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isFileNoteModalOpen: true,
          fileNoteObj: { clientId: id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };
  viewEmailAndPhone = ({ id }) => {
    let { onGetClientEmailAndPhone } = this.props;
    this.setState({ pageLoading: true });
    onGetClientEmailAndPhone(id)
      .then((res) => {
        this.setState({ pageLoading: false, isEmailPhoneModal: true }, () => {
          this.formRef.current.setFieldsValue({
            Email: res.payload.email,
            Phone: res.payload.mobile,
          });
        });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  getAllFileNotes = (id) => {
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({ fileNotes: notes, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };

  getEmailAndPhoneModal = ({ isEmailPhoneModal, pageLoading, clientInfo }) => (
    <Modal
      title={"Email And Phone"}
      visible={isEmailPhoneModal}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isEmailPhoneModal: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isEmailPhoneModal: false });
      }}
      header={true}
      footer={false}
    >
      <div style={{ width: "100%" }}>
        <Form ref={this.formRef} {...layout} name="basic">
          <Form.Item
            colon={false}
            name="Email"
            labelAlign="left"
            label="Email"
            style={{ width: "100%" }}
          >
            {/* eslint-disable-next-line react/jsx-no-undef */}

            <Input readOnly />
          </Form.Item>
          <Form.Item
            colon={false}
            name="Phone"
            labelAlign="left"
            label="Phone"
            style={{ width: "100%" }}
          >
            {/* eslint-disable-next-line react/jsx-no-undef */}

            <Input readOnly />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );

  openClientFile = ({ id, clientId, memberType, familyId }) => {
    localStorage.setItem("clientprofileid", JSON.stringify(id));
    localStorage.setItem("familyId", familyId);
    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      this.props.history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      this.props.history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else {
      this.props.history.push({
        pathname: "/client-family-member",
        state: {
          id: clientId,
          familyId: familyId,
          clickBy: "clientArea",
          memberId: id,
        },
      });
    }
  };
  addFollowerModal = ({
    isFollowerModalOpen,
    newTaskObj,
    updateTask,
    ProcessingPersons,
  }) => (
    <Modal
      className="reminder-model-main"
      title={"ADD FOLLOWER"}
      visible={isFollowerModalOpen}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isFollowerModalOpen: false });
      }}
      Header={false}
      footer={false}
    >
      <AddFollowerModal
        updateTitle={updateTask.id}
        addTaskFollower={this.props.onAddTaskFollower}
        users={ProcessingPersons.map((el) => ({
          label: el.fullName,
          value: el.id,
        }))}
        handleCancel={() => {
          this.resetTaskForm();
          this.setState({ isFollowerModalOpen: false });
        }}
      />
    </Modal>
  );

  onExportClient = () => {
    let {
      selectedBranch,
      selectedCountry,
      processingPerson,
      selectedVisaType,
      selectedVisaStatus,
    } = this.state;

    this.setState({ pageLoading: true });
    const clientExportOpt = {
      url: `v1/dashboard/ClientExport`,
    };
    clientExportOpt.types = ["CLIENT_EXPORT_SUCCESS", "CLIENT_EXPORT_FAILURE"];
    var findCountry = null;
    if (selectedCountry && selectedCountry != 0) {
      findCountry = this.state.Countries.find(
        (obj) => obj.countryId == selectedCountry
      );
    }

    let data = {
      countryName: findCountry ? findCountry.countryName : "",
      pageSize: 0,
      pageNumber: 0,
      processingPerson: processingPerson,
      visaTypeId: selectedVisaType ? parseInt(selectedVisaType) : 0,
      visaStatusId: selectedVisaStatus ? parseInt(selectedVisaStatus) : 0,
      priority: priority,
      casePriority: casePriority,
    };

    apiRefresh
      .postWithFileRes(clientExportOpt, data)
      .then((res) => {
        this.setState({ pageLoading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Client_Summary.xlsx");
      })
      .catch((err) => this.setState({ pageLoading: false }));
  };
  render() {
    const {
      pageLoading,
      totalPages,
      ProcessingPersons,
      processingPerson,
      Countries,
      Counts,
      Clients,
      pageSize,
      currentPage,
    } = this.state;
    let { count, high, low, medium, onShore, visaCount } = Counts;
    return (
      <Spin spinning={pageLoading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <Row>
                <Col xs={24}>
                  <DashboardClientTopBars
                    data={topBar}
                    activeTab="Client Summary"
                  />
                </Col>
              </Row>
              <div class="container-fluid">
                <div
                  class="top-row"
                  style={{ paddingRight: 0, marginBottom: 0 }}
                >
                  <div
                    class="top-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div class="select-optionss" style={{ paddingRight: 1 }}>
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Processing Persons"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeProcessingPerson.bind(this)}
                          value={processingPerson ? processingPerson : null}
                        >
                          {localStorage.getItem("userOwner") == "True" && (
                            <Option
                              value={"00000000-0000-0000-0000-000000000000"}
                            >
                              All
                            </Option>
                          )}
                          {ProcessingPersons.map((person) => (
                            <Option value={person.id}>{person.fullName}</Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Select Country"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeCountry.bind(this)}
                          defaultValue={0}
                          value={parseInt(this.state.selectedCountry)}
                        >
                          <Option value={0}>All</Option>
                          {Countries &&
                            Countries.map((cont) => (
                              <Option value={cont.countryId}>
                                {" "}
                                {cont.countryName}
                              </Option>
                            ))}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Visa Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeVisaType.bind(this)}
                        >
                          {this.state.visaTypes}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Visa Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeVisaStatus.bind(this)}
                        >
                          {this.state.visaStatuses}
                        </Select>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-high-filled"></div>
                          <span class="priority-text">High</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({high})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-medium-filled"></div>
                          <span class="priority-text">Medium</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({medium})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-low-filled"></div>
                          <span class="priority-text">Low</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({low})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="todays-tasks-filled"></div>
                          <span class="priority-text">
                            Today's Task/Reminders
                          </span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="pending-tasks-filled"></div>
                          <span class="priority-text">
                            Pending Task/Reminders
                          </span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="offshore-clients-filled"></div>
                          <span class="priority-text">Offshore Clients</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        class="clients-count-cont"
                        style={{ width: 78, height: 24 }}
                      >
                        <span
                          style={{
                            fontSize: 11,
                            color: "#687278",
                            fontFamily: "Poppins",
                          }}
                        >
                          Clients:{" "}
                          <span
                            style={{ fontWeight: "700", fontFamily: "Poppins" }}
                          >
                            {totalPages}
                          </span>
                        </span>
                      </div>
                      <div
                        class="clients-count-cont"
                        style={{ marginLeft: 5, width: 78, height: 24 }}
                      >
                        <span
                          style={{
                            fontSize: 11,
                            color: "#687278",
                            fontFamily: "Poppins",
                          }}
                        >
                          Cases:{" "}
                          <span
                            style={{ fontWeight: "700", fontFamily: "Poppins" }}
                          >
                            {visaCount}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{ height: 24 }}
                        onClick={() =>
                          this.setState({ isSettingsModalOpen: true })
                        }
                        class="setting-export-cont"
                      >
                        <img src={settings} class="setting-export-img" />
                      </div>
                      {/* <div class="setting-export-cont" style={{ height: 24 }}>
                        <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <img
                                style={{ cursor: "pointer" }}
                                src={exportImg}
                                class="setting-export-img"
                              />
                            );
                          }}
                          content={() => this.ClientRef}
                        />
                      </div> */}
                      <div class="setting-export-cont" style={{ height: 24 }}>
                        <Tooltip placement="topLeft" title={`Client Export`}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={exportImg}
                            class="setting-export-img"
                            onClick={this.onExportClient}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rem-table-margin">
                <div class="table-card table-filter">
                  <div className="container-fluid">
                    {/* {this.getTable(this.state)} */}
                    <ClientSummaryReport
                      Clients={Clients}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      columns={this.mainColumns}
                      paginate={this.paginate}
                      ref={(el) => (this.ClientRef = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.getSettingsModal(this.state)}
        {this.getStatusDateModal(this.state)}
        {this.getEmailsModal(this.state)}
        {this.getAddTaskModal(this.state)}
        {this.getReminderModal(this.state)}
        {this.getUpdateTaskModal(this.state)}
        {this.getRescheduleTaskModal(this.state)}
        {this.getFileNoteModal(this.state)}
        {this.getEmailAndPhoneModal(this.state)}
        {this.addFollowerModal(this.state)}
      </Spin>
    );
  }
}

export default Dashboard;
