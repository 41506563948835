import React, { Fragment, useState, useEffect } from "react";
import {
  UserOutlined,
  LinkOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
// import { yyyymmddFormate } from "./../../../../../Common/reUseFunction";
import {
  Table,
  message,
  Modal,
  Tooltip,
  Form,
  Row,
  Col,
  Input,
  AutoComplete,
  Spin,
} from "antd";
import { Images } from "../Themes";
import moment from "moment";
import { apiRefresh } from "../services/api";

let userId = localStorage.getItem("userId");

let clientprofileid = sessionStorage.getItem("clientprofileid");

const SalesPipelineTable = ({
  getSalePipelineRes,
  showModal,
  isModalVisible,
  onGetSalePipeline,
  setLoading,
}) => {
  const [modal1Visible, setModal1Visible] = useState(false);
  const [recordData, setRecord] = useState(null);

  useEffect(() => {
    // onGetSalePipeline();
  }, []);
  useEffect(() => {
    letterTempaltesFilter(getSalePipelineRes);
  }, [getSalePipelineRes]);

  const letterTempaltesFilter = (getSalePipelineRes) => {
    let filterList = [];
    if (
      getSalePipelineRes &&
      getSalePipelineRes.pipeline &&
      getSalePipelineRes.pipeline.length > 0
    ) {
      getSalePipelineRes.pipeline.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "100px",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.name}
        </p>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "100px",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.description}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div className="table-action" style={{ alignItems: "center" }}>
              {record.branchId !== "00000000-0000-0000-0000-000000000000" && (
                <img
                  src={Images.editBorderBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => showModal(record, "update-pipeline")}
                />
              )}
              <div style={{ cursor: "pointer" }}>
                <img
                  src={Images.deleteBlue}
                  style={{ width: 15, marginRight: 10, cursor: "pointer" }}
                  onClick={() => openDelModal(record)}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const openDelModal = (data) => {
    setModal1Visible(true);
    setRecord(data);
  };

  const removePipeline = () => {
    let data = {
      id: recordData.id,
    };
    const remPipelineOpt = {
      url: `v1/deal/pipeline`,
    };
    remPipelineOpt.types = [
      "REMOVE_PIPELINE_SUCCESS",
      "REMOVE_PIPELINE_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(remPipelineOpt, data)
      .then((res) => {
        message.success("Deleted successfuly!");
        setLoading(false);
        onGetSalePipeline();
        setModal1Visible(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div>
        <Table
          // expandedRowKeys={expandedRowsEntriesKeys}
          pagination={false}
          className="job-table table-head employer-tables border-3 school-table
          "
          dataSource={getSalePipelineRes && getSalePipelineRes.pipeline}
          columns={columns}
        />
      </div>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={() => removePipeline()}
        onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
    </Fragment>
  );
};

export default SalesPipelineTable;
