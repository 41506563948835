import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import { message, Spin, Form, Select, Button } from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

const TimeZoneSettings = ({
  onGetTimeZone,
  timeZoneRes,
  onUpdateTimeZone,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [TimeChange, setTimeChange] = useState({});
  const state = useLocation().state;

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);

    onGetTimeZone()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetTimeZone]);

  const onTimeZoneChange = (value) => {
    setTimeChange(value);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const data = {
      timeZone: TimeChange,
    };

    onUpdateTimeZone(data)
      .then(() => {
        onGetTimeZone()
          .then(() => {
            setLoading(false);
            message.success("Successfully Deleted!");
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.timeZone} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Time Zone Settings</span>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    onFinish={onFinish}
                    form={form}
                    className="d-flex space-between"
                  >
                    <div className="reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Select
                          onChange={onTimeZoneChange}
                          style={{ width: "340px" }}
                        >
                          <Option value="company email">Company Email</Option>
                          <Option value="2">Reminder Email</Option>
                          <Option value="3">processing Person</Option>
                          <Option value="1">Company Email</Option>
                          <Option value="2">Reminder Email</Option>
                          <Option value="3">processing Person</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="mail-chimp-btn reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Button htmlType="submit" type="primary">
                          UPDATE
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default TimeZoneSettings;
