import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const employerManagReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_EMPLOYER_MANAG_SUCCESS:
      return { ...state, employerManagRes: action.payload };
    case types.GET_EMPLOYER_MANAG_FAILURE:
      return { ...state, addfileNotesError: action.payload };

    case types.ADD_EMPLOYER_MANAG_SUCCESS:
      return { ...state, addEmployerManagRes: action.payload };
    case types.ADD_EMPLOYER_MANAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_EMPLOYER_MANAG_SUCCESS:
      return { ...state, deleteEmployerManagRes: action.payload };
    case types.DELETE_EMPLOYER_MANAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_CONTACTS_SUCCESS:
      return { ...state, EmployerContactRes: action.payload };
    case types.GET_EMPLOYER_CONTACTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_CONTACTS_SUCCESS:
      return { ...state, addEmployerContactRes: action.payload };
    case types.ADD_EMPLOYER_CONTACTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_JOB_SUCCESS:
      return { ...state, employerJobRes: action.payload };
    case types.GET_EMPLOYER_JOB_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_JOB_SUCCESS:
      return { ...state, addEmployerJobRes: action.payload };
    case types.ADD_EMPLOYER_JOB_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_FILE_SUCCESS:
      return { ...state, employerFileRes: action.payload };
    case types.GET_EMPLOYER_FILE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_FILE_SUCCESS:
      return { ...state, addEmployerFileRes: action.payload };
    case types.ADD_EMPLOYER_FILE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_SEARCH_SUCCESS:
      return { ...state, searchEmployerRes: action.payload };
    case types.ADD_EMPLOYER_SEARCH_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_SINGLE_EMPLOYER_SUCCESS:
      return { ...state, singleEmployerRes: action.payload };
    case types.GET_SINGLE_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_EMPLOYER_CONTACT_SUCCESS:
      return { ...state, delEmployerConRes: action.payload };
    case types.DELETE_EMPLOYER_CONTACT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_EMPLOYER_FILE_SUCCESS:
      return { ...state, delEmployerFilaRes: action.payload };
    case types.DELETE_EMPLOYER_FILE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_EMPLOYER_MANAG_SUCCESS:
      return { ...state, updateEmployerRes: action.payload };
    case types.UPDATE_EMPLOYER_MANAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_EMPLOYER_FILE_SUCCESS:
      return { ...state, updateEmployerfileRes: action.payload };
    case types.UPDATE_EMPLOYER_FILE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_EMPLOYER_CONTACTS_SUCCESS:
      return { ...state, updateEmployerContactRes: action.payload };
    case types.UPDATE_EMPLOYER_CONTACTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_EMPLOYER_SUCCESS:
      return { ...state, employerDocumentRes: action.payload };
    case types.GET_DOCUMENT_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_EMPLOYER_SUCCESS:
      return { ...state, addemployerDocumentRes: action.payload };
    case types.ADD_DOCUMENT_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_DOCUMENT_EMPLOYER_SUCCESS:
      return { ...state, remEmployerDocumentRes: action.payload };
    case types.DELETE_DOCUMENT_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_DOCUMENT_EMPLOYER_SUCCESS:
      return { ...state, updateEmployerDocumentRes: action.payload };
    case types.UPDATE_DOCUMENT_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_CHECKLIST_SUCCESS:
      let j = action;

      return { ...state, employerCheckListRes: action.payload };
    case types.GET_EMPLOYER_CHECKLIST_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_CHECKLIST_SUCCESS:
      return { ...state, addEmployerCheckListRes: action.payload };
    case types.ADD_EMPLOYER_CHECKLIST_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_LIST_SUCCESS:
      return { ...state, employerListRes: action.payload };
    case types.GET_EMPLOYER_LIST_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMPLOYER_EMAIL_SUCCESS:
      return { ...state, addEmployerEmailRes: action.payload };
    case types.ADD_EMPLOYER_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_HISTORY_SUCCESS:
      return { ...state, employerHistoryRes: action.payload };
    case types.GET_EMPLOYER_HISTORY_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMAIL_IMPORT_SUCCESS:
      return { ...state, emailImportRes: action.payload };
    case types.GET_EMAIL_IMPORT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMAIL_IMPORT_SUCCESS:
      return { ...state, addEmailImportRes: action.payload };
    case types.ADD_EMAIL_IMPORT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_TITTLE_SUCCESS:
      return { ...state, addDoumentTittleRes: action.payload };
    case types.ADD_DOCUMENT_TITTLE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CFILE_NOTES_SUCCESS:
      return { ...state, clientFileRes: action.payload };
    case types.GET_CFILE_NOTES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_CFILE_NOTES_SUCCESS:
      return { ...state, addClientFileRes: action.payload };
    case types.ADD_CFILE_NOTES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_CFILE_NOTES_SUCCESS:
      return { ...state, addClientFileRes: action.payload };
    case types.UPDATE_CFILE_NOTES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_CFILE_NOTES_SUCCESS:
      return { ...state, removeClientFileRes: action.payload };
    case types.DELETE_CFILE_NOTES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.SIDEBAR_ACTION_OPEN_AND_CLOSE:
      return { ...state, sideBarRes: action.payload };

    case types.ADD_ANY_TEMPLATE_SUCCESS:
      return { ...state, addAnyTemRes: action.payload };
    case types.ADD_ANY_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_UPLOAD_FILE_SUCCESS:
      return { ...state, addUploadFileRes: action.payload };
    case types.ADD_UPLOAD_FILE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.SET_ACTIVE_KEY_SUCCESS:
      return { ...state, activeKey: action.payload };

    case types.SET_CLIENT_TAB_SUCCESS:
      return { ...state, clientTab: action.payload };

    case types.DELETE_CREATE_EMAIL_SUCCESS:
      return { ...state, removeCreateERes: action.payload };
    case types.DELETE_CREATE_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_EMPLOYER_JOB_SUCCESS:
      return { ...state, updateJobRes: action.payload };
    case types.UPDATE_EMPLOYER_JOB_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_EMPLOYER_JOB_SUCCESS:
      return { ...state, removeJobRes: action.payload };
    case types.DELETE_EMPLOYER_JOB_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CLIENT_SEARCH_SUCCESS:
      return { ...state, clientSearchRes: action.payload };
    case types.GET_CLIENT_SEARCH_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CLIENT_EMP_SUCCESS:
      return { ...state, clientEmpRes: action.payload };
    case types.GET_CLIENT_EMP_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        // openNotificationWithIcon("success", "list is empty!");
        return { ...state, clientEmpRes: [] };
      } else {
      }
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_CLIENT_EMP_SUCCESS:
      return { ...state, empClientEmpRes: action.payload };
    case types.ADD_CLIENT_EMP_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        // openNotificationWithIcon("success", "list is empty!");
        return { ...state, empClientEmpRes: [] };
      } else {
      }
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.LINK_EMP_CLIENT_SUCCESS:
      return { ...state, linkEmpClientRes: action.payload };
    case types.LINK_EMP_CLIENT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_WITHNAME_SUCCESS:
      return { ...state, docWithNameRes: action.payload };
    case types.ADD_DOCUMENT_WITHNAME_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CLIENT_JOB_HISTORY_SUCCESS:
      return { ...state, clientJobHisRes: action.payload };
    case types.GET_CLIENT_JOB_HISTORY_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_SINGLE_JOBHIS_SUCCESS:
      return { ...state, singleJobHisRes: action.payload };
    case types.ADD_SINGLE_JOBHIS_FAILURE:
      // if (
      //   action.payload &&
      //   action.payload.response &&
      //   action.payload.response.status &&
      //   action.payload.response.status === 404
      // ) {
      return { ...state, singleJobHisRes: [] };
      // } else {
      //
      // }
      console.log("on error console", action.payload);
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_POTENTIAL_EMPLOYER_SUCCESS:
      return { ...state, movePotentialEmpRes: action.payload };
    case types.UPDATE_POTENTIAL_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_CASE_SUCCESS:
      return { ...state, employerCaseRes: action.payload };
    case types.GET_EMPLOYER_CASE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
