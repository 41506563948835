import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Table,
  Spin,
  Modal,
  Checkbox,
  Select,
  message,
  Dropdown,
  Menu,
  DatePicker,
  Input,
  Popconfirm,
  Tag,
  Tooltip,
  Avatar,
  Image,
} from "antd";
import moment from "moment";
import {
  DownOutlined,
  MessageTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
  FileDoneOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  UserOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  FileTwoTone,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import grayCross from "../../svgs/gray-cross.svg";
import rightArrow from "../../svgs/right-arrow.svg";
import renderHTML from "react-render-html";
import "../DashboardStyles.css";
import settings from "../../svgs/settings.svg";
import exportImg from "../../svgs/export.svg";
import DashboardClientTopBars from "../../Components/Shared/DashboardClientTopBar";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";
// import ClientSummaryReport from "../Reports/ClientSummaryReport/ClientSummaryReport";
// import AddFollowerModal from "../MyDailyTasksReminders/SideArea/AddFollowerModal";

const { Option } = Select;
const { TextArea } = Input;

let config = {
  key: "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
  // height: "auto",
  // toolbarSticky: false,
  charCounterCount: false,
  // placeholderText: "Edit Your Content Here!",
  // imageUpload: true,
  // imageDefaultAlign: "left",
  // imageDefaultDisplay: "inline-block",
  // Set max image size to 5MB.
  // imageMaxSize: 5  1024  1024,
  // Allow to upload PNG and JPG.
  imageAllowedTypes: ["jpeg", "jpg", "png"],
  events: {
    "charCounter.update": () => {
      // Do something here.
      // this is the editor instance.
      console.log("char");
    },
    "image.uploaded": function(response) {
      // Do something here.
      // this is the editor instance.
      console.log(this);
    },
    "image.beforeUpload": function(files) {
      let editor = config.editor;
      if (files.length) {
        // Create a File Reader.
        let reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = (e) => {
          let result = e.target.result;
          console.log("result", result);

          editor.image.insert(result, null, null, editor.image.get());
        };
        // Read image as base64.
        let pic = reader.readAsDataURL(files[0]);
        console.log("pic", pic);
      }
      editor.popups.hideAll();
      // Stop default upload chain.
      return false;
    },
  },
};

const topBar = [
  { tabName: "Client Summary", linkName: "/dashboard" },
  { tabName: "Potential Clients", linkName: "/dashboard/potentialclient" },
  { tabName: "Students", linkName: "/dashboard/student" },
  { tabName: "Employers", linkName: "/dashboard/employers" },
  { tabName: "Reminders", linkName: "/dashboard/reminders" },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const columnObject = {
  priority: "Priority",
  clientNumber: "Inz Id",
  name: "Name",
  dateOfBirth: "DOB",
  visaTypeName: "Visa Name",
  startDate: "Visa Date",
  totalTask: "Total Task",
  totalReminder: "Total Reminder",
  contractSigned: "Contract Signed",
  followUpDate: "Follow Up Date",
  modifiedDate: "Modified Date",
  ProcessingPersons: "Processing Person",
  clienttags: "Client Tags",
  casePriority: "Case Priority",
  isPaid: "Paid",
  currentNewZealandVisaExpiry: "Current Visa Expiry",
  medicalIssueDate: "Medical Issue Date",
  xrayIssueDate: "Xray Issue Date",
  policeCertificate: "Police Certificate Date",
  fileNotes: "File Note",
  newTaskObj: {},
  fileNoteObj: {},
  isTaskModalOpen: false,
  pageLoading: false,
  email: "Email",
  clientSerial: "Internal Id",
  school: "School", // using
  program: "Program", // using
  agentName: "Agent", // using
  status: "Status", // using
  statusDate: "Status Date", // using
  appliedDate: "Applied Date", // using
  courseEffectiveDate: "Course Effective Date", // using
  studentNo: "Student#", // using
  ftsDate: "FTS Date",
  aipDate: "AIP Date",
  aipSubmitted: "AIP Submitted",
  visaApprovedDate: "Visa Approved Date",
  initialDeposit: "Initial Deposit",
};
const colWidth = 180;

class Student extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      DashbordSettings: [],
      ProcessingPersons: [],
      Countries: [],
      admissionStatus: [],
      selectedBranch: null,
      selectedCountry: 0,
      username: "",
      selectedOption: null,
      processingPerson: "00000000-0000-0000-0000-000000000000",
      pageNumber: 1,
      pageSize: 20,
      totalPages: 0,
      newTaskObj: {},
      newReminderObj: {},
      updateTask: {},
      pageLoading: false,
      modalLoading: false,
      isSettingsModalOpen: false,
      isStatusDateModalOpen: false,
      isTaskModalOpen: false,
      isReminderModalOpen: false,
      isUpdateTask: false,
      isFileNoteModalOpen: false,
      isFollowerModalOpen: false,
      status: "",
      agentId: "00000000-0000-0000-0000-000000000000",
      studentId: "",
      prevPageSize: 20,
      columnState: "",
      priorityState: "",
      sorterArray: [],
      studentsDashboardSet: [],
      fileNoteObj: {
        fileNote: "",
      },
      Counts: {
        count: 0,
        high: 0,
        low: 0,
        medium: 0,
        onShore: 0,
        visaCount: 0,
      },
      isAdmissionCaseModalVisible: false,
      admissionStatusData: {},
      selectedItem: null,
    };
  }
  columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      width: colWidth,
      filters: [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Low",
          value: "Low",
        },
      ],
      onFilter: (value, record) => true,
      render: (text, record) => {
        return (
          <div className="priority-buttons">
            <span
              class="priority-button"
              style={{
                borderColor: "#d9534f",
                backgroundColor: text === "High" ? "#d9534f" : "#0000",
              }}
              onClick={() => {
                this.updateClientPriority(record, "High");
              }}
            ></span>
            <span
              class="priority-button"
              onClick={() => {
                this.updateClientPriority(record, "Medium");
              }}
              style={{
                borderColor: "#f0ad4e",
                backgroundColor: text === "Medium" ? "#f0ad4e" : "#0000",
              }}
            ></span>
            <span
              class="priority-button High-priority"
              onClick={() => {
                this.updateClientPriority(record, "Low");
              }}
              style={{
                borderColor: "#5cb85c",
                backgroundColor: text === "Low" ? "#5cb85c" : "#0000",
              }}
            ></span>
          </div>
        );
      },
    },
    {
      title: "EZM ID",
      dataIndex: "clientNumber",
      align: "center",
      width: colWidth,
      sorter: (a, b) => a.clientNumber.length - b.clientNumber.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: colWidth,
      sorter: true,
      align: "center",
      render: (text, record) => {
        return {
          children: text,
          props: {
            className: "addPointer",
            onClick: () => this.openClientFile(record),
          },
        };
      },
    },
    {
      title: "Task/Fnotes",
      dataIndex: "totalTask",
      render: (data, record) => (
        <>
          <span>{data}</span>{" "}
          <span>
            <FileTwoTone onClick={() => this.viewTasks(record)} />
          </span>
        </>
      ),
      sorter: true,
      width: colWidth,
      align: "center",
    },
    {
      title: "School",
      dataIndex: "school",
      align: "center",
      width: colWidth,
      sorter: true,
    },
    {
      title: "Program",
      dataIndex: "program",
      align: "center",
      width: colWidth,
      sorter: true,
    },
    {
      title: "Agent",
      dataIndex: "agentName",
      align: "center",
      width: colWidth,
      // sorter: (a, b) => {
      //   if (a.program && b.program) return a.program.length - b.program.length;
      // },
    },
    {
      title: "Initial Deposit",
      dataIndex: "initialDeposit",
      align: "center",
      width: colWidth,
      // sorter: (a, b) => {
      //   if (a.program && b.program) return a.program.length - b.program.length;
      // },
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: colWidth,
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <Dropdown overlay={this.menu(record)}>
              <Button
                style={{
                  // backgroundColor: record.visaStatusColor,
                  fontSize: 12,
                  display: "block",
                  margin: "auto",
                }}
                onClick={() => {
                  console.log("======record========", record);

                  this.setState({ studentId: record.programId });
                }}
              >
                {record.status} <DownOutlined />
              </Button>
            </Dropdown>
          </>
        );
      },
    },
    {
      title: "Status Date",
      dataIndex: "statusDate",
      align: "center",
      width: colWidth,

      sorter: true,
      sortDirections: ["descend", "ascend", "ascend"],
      render: (statusDate) => {
        return statusDate === "01/01/0001 00:00:00 +00:00" ||
          !statusDate.length ||
          statusDate === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(statusDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Applied Date",
      dataIndex: "appliedDate",
      align: "center",
      width: colWidth,
      sorter: true,
      render: (appliedDate, record) => {
        return {
          children: (
            <DatePicker
              calendarIcon={null}
              clearIcon={null}
              //   onChange={(e) => this.onUpdateFollowUpDate(record, e.toJSON())}
              value={
                appliedDate === "01/01/0001 00:00:00 +00:00" ||
                !appliedDate.length ||
                appliedDate === "01/01/1900 00:00:00 +00:00"
                  ? null
                  : moment(appliedDate)
              }
              popupStyle={{ width: "210px !important" }}
              format="DD/MM/YYYY"
              onChange={(date) => {
                var findStatusId = this.state.admissionStatus.find(
                  (obj) => obj.name == record.status
                );
                this.setState(
                  {
                    studentId: record.programId,
                    selectedItem: record,
                    admissionStatusData: {
                      programStatus: findStatusId ? findStatusId.id : 0,
                      conditionalOfferDate: moment(record.courseEffectiveDate),
                      appliedDate: date,
                    },
                  },
                  () => {
                    this.updateAdmissionCaseStatus();
                  }
                );
              }}
            />
          ),
          props: {
            className: "appliedDate",
          },
        };
      },
    },
    {
      title: "Student#",
      dataIndex: "studentNo",
      align: "center",
      width: colWidth,
      sorter: true,
    },
    {
      title: "Course Effective Date",
      dataIndex: "courseEffectiveDate",
      align: "center",
      width: colWidth,
      sorter: true,
      render: (courseEffectiveDate, record) => {
        return {
          children: (
            <DatePicker
              calendarIcon={null}
              clearIcon={null}
              //   onChange={(e) => this.onUpdateFollowUpDate(record, e.toJSON())}
              value={
                !courseEffectiveDate ||
                courseEffectiveDate === "01/01/0001 00:00:00 +00:00" ||
                !courseEffectiveDate.length ||
                courseEffectiveDate === "01/01/1900 00:00:00 +00:00"
                  ? null
                  : moment(courseEffectiveDate)
              }
              popupStyle={{ width: "210px !important" }}
              format="DD/MM/YYYY"
              onChange={(date) => {
                var findStatusId = this.state.admissionStatus.find(
                  (obj) => obj.name == record.status
                );
                this.setState(
                  {
                    studentId: record.programId,
                    selectedItem: record,
                    admissionStatusData: {
                      programStatus: findStatusId ? findStatusId.id : 0,
                      courseEffectiveDate: date,
                      date: moment(record.appliedDate),
                    },
                  },
                  () => {
                    this.updateAdmissionCaseStatus();
                  }
                );
              }}
            />
          ),
          props: {
            className: "courseEffectiveDate",
          },
        };
      },
    },
    {
      title: "FTS Date",
      dataIndex: "ftsDate",
      align: "center",
      width: colWidth,

      sorter: true,
      sortDirections: ["descend", "ascend", "ascend"],
      render: (ftsDate) => {
        return ftsDate === "01/01/0001 00:00:00 +00:00" ||
          !ftsDate.length ||
          ftsDate === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(ftsDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "AIP Date",
      dataIndex: "aipDate",
      align: "center",
      width: colWidth,

      sorter: true,
      sortDirections: ["descend", "ascend", "ascend"],
      render: (aipDate) => {
        return aipDate === "01/01/0001 00:00:00 +00:00" ||
          !aipDate.length ||
          aipDate === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(aipDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "AIP Submitted",
      dataIndex: "aipSubmitted",
      align: "center",
      width: colWidth,
      render: (record) => {
        return <span>{record ? "Yes" : "No"}</span>;
      },
      // sorter: (a, b) => {
      //   if (a.aipSubmitted && b.aipSubmitted)
      //     return a.aipSubmitted.length - b.aipSubmitted.length;
      // },
    },
    {
      title: "Visa Approved Date",
      dataIndex: "visaApprovedDate",
      align: "center",
      width: colWidth,

      sorter: true,
      sortDirections: ["descend", "ascend", "ascend"],
      render: (visaApprovedDate) => {
        return visaApprovedDate === "01/01/0001 00:00:00 +00:00" ||
          !visaApprovedDate.length ||
          visaApprovedDate === "01/01/1900 00:00:00 +00:00"
          ? null
          : moment(visaApprovedDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Agent",
      dataIndex: "agent",
      align: "center",
      width: colWidth,
      sorter: true,
    },
  ];
  mainColumns = this.columns;

  menu = (record) => (
    <Menu style={{ overflow: "auto", height: 300 }}>
      {this.state.admissionStatus.map((item) => {
        return (
          <Menu.Item
            onClick={() => {
              this.setState({
                selectedItem: record,
                studentId: record.programId,
                isAdmissionCaseModalVisible: true,
                admissionStatusData: {
                  programStatus: item.id,
                  programStatusName: item.name.trim(),
                  color: item.color,
                },
              });
            }}
          >
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  updateClientPriority = (data, priority) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientPriority({
        subjectId: data.id,
        priority: priority,
      })
      .then(() => this.getDashboardStudents())
      .catch((er) => this.setState({ pageLoading: false }));
  };

  getDashboardStudents = (pp, ss) => {
    let findStatus = this.state.admissionStatus.find((obj) => obj.id == ss);
    this.setState({ pageLoading: true });
    if (this.state.prevPageSize != this.state.pageSize) {
      this.setState({ pageNumber: 1 });
    }
    this.props
      .onGetDashboardStudents({
        pageNumber:
          this.state.prevPageSize != this.state.pageSize
            ? 1
            : this.state.pageNumber,
        pageSize: this.state.pageSize,
        processingPerson:
          pp && pp !== "00000000-0000-0000-0000-000000000000"
            ? pp
            : this.state.processingPerson,
        agentId: "00000000-0000-0000-0000-000000000000",
        status: findStatus ? findStatus.name : "",
        columns: this.state.columnState || "",
        priority: this.state.priorityState || "",
      })
      .then((res) => {
        this.setState({ prevPageSize: this.state.pageSize });
      });
  };

  componentDidMount = () => {
    this.setState({ pageLoading: true });
    this.props.onGetDashboardStudents({
      pageNumber: 1,
      pageSize: 20,
      processingPerson: this.state.processingPerson,
      agentId: "00000000-0000-0000-0000-000000000000",
      status: "",
      columns: "",
      priority: "",
    });
    this.props.onGetClientSummarySettings().then((res) => {
      this.setState({
        studentsDashboardSet: res.payload && res.payload.studentDashboard,
      });
      this.filterColumns(res.payload && res.payload.studentDashboard);
    });
    this.props.onGetAdmissionCaseStatus();
    this.props.onGetTeamMember();
  };

  filterColumns = (settings) => {
    this.mainColumns = this.columns.filter((col) => settings[col.dataIndex]);
  };

  componentWillReceiveProps = (nextProps) => {
    let studentsList = nextProps && nextProps.studentsList;
    let studentsListError = nextProps && nextProps.studentsListError;
    let admissionCaseStatus = nextProps && nextProps.admissionCaseStatus;
    let processingPersons =
      nextProps &&
      nextProps.processingPersons &&
      nextProps.processingPersons.users;
    if (studentsList) {
      this.setState({
        students: studentsList.students,
        totalPages: studentsList.count,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
        pageLoading: false,
      });
    }
    if (admissionCaseStatus) {
      this.setState({ admissionStatus: admissionCaseStatus.items });
    }
    if (processingPersons)
      this.setState({ ProcessingPersons: processingPersons });
    if (studentsListError) this.setState({ pageLoading: false });
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.studentsList !== this.props.studentsList) {
  //     this.setState({ students: this.props.studentsList });
  //   }
  // }

  updateAdmissionCaseStatus = () => {
    this.setState({ modalLoading: true });
    const { admissionStatusData } = this.state;

    let data = {
      id: this.state.studentId,
      programStatus: admissionStatusData.programStatus || 0,
      appliedDate:
        admissionStatusData.appliedDate ||
        (this.state.selectedItem &&
          moment(this.state.selectedItem.appliedDate)),
      courseEffectiveDate:
        admissionStatusData.courseEffectiveDate ||
        (this.state.selectedItem &&
          moment(this.state.selectedItem.courseEffectiveDate)),

      modifiedDate: admissionStatusData.date || new Date(),
      startDate: admissionStatusData.startDate || "1900-01-01T00:00:00Z",
      declineDate: admissionStatusData.declineDate || "1900-01-01T00:00:00Z",
      paidDate: admissionStatusData.paidDate || "1900-01-01T00:00:00Z",
      formalOfferDate:
        admissionStatusData.formalOfferDate || "1900-01-01T00:00:00Z",
      conditionalOfferDate:
        admissionStatusData.conditionalOfferDate || "1900-01-01T00:00:00Z",
    };

    const clientExportOpt = {
      url: `v1/client/programdetail/Short`,
    };
    clientExportOpt.types = ["CLIENT_EXPORT_SUCCESS", "CLIENT_EXPORT_FAILURE"];

    // this.props
    //   .onUpdateAdmissionCaseStatus(admissionStatusData)
    apiRefresh
      .put(clientExportOpt, data)
      .then((res) => {
        this.setState(
          {
            // clients: this.state.clients,
            modalLoading: false,
            admissionStatusData: {},
            isAdmissionCaseModalVisible: false,
          },
          () => {
            this.resetTaskForm();
            openNotificationWithIcon(
              "success",
              "Admission Case status updated successfully!"
            );
          }
        );
        this.getDashboardStudents();
      })
      .catch((er) => {
        this.setState({ modalLoading: false });
      });
  };

  onChangeAdmissionDate = (type, val) => {
    let selectedDate = val.toJSON();
    this.setState({
      admissionStatusData: {
        ...this.state.admissionStatusData,
        [type]: selectedDate,
      },
    });
  };

  getStartDateFields = () => (
    <Form.Item
      name="startdate"
      labelAlign="left"
      label="Start Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Start Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("startDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );

  getAppliedDateFields = () => (
    <Form.Item
      name="applieddate"
      labelAlign="left"
      label="Applied Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Applied Date"
        calendarIcon={null}
        clearIcon={null}
        value={this.state.appliedDate}
        onChange={(e) => this.onChangeAdmissionDate("appliedDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getConditionalDateFields = () => (
    <Form.Item
      name="conditionaldate"
      labelAlign="left"
      label="Conditional Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Conditional Offer Date"
        calendarIcon={null}
        clearIcon={null}
        value={this.state.conditionalOfferDate}
        onChange={(e) => this.onChangeAdmissionDate("conditionalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getFormalDateFields = () => (
    <Form.Item
      name="formaldate"
      labelAlign="left"
      label="Formal Offer Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Formal Offer Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("formalOfferDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getPaidDateFields = () => (
    <Form.Item
      name="paiddate"
      labelAlign="left"
      label="Paid Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Paid Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("paidDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );
  getDeclineDateFields = () => (
    <Form.Item
      name="declinedate"
      labelAlign="left"
      label="Decline Date"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Required!" }]}
    >
      <DatePicker
        placeholder="Select Decline Date"
        calendarIcon={null}
        clearIcon={null}
        onChange={(e) => this.onChangeAdmissionDate("declineDate", e)}
        format="DD/MM/YYYY"
      />
    </Form.Item>
  );

  updateAdmissionStatusModal = () => {
    let {
      isAdmissionCaseModalVisible,
      admissionStatusData,
      modalLoading,
    } = this.state;
    return (
      <Modal
        title={"Update Admission Case Status"}
        visible={isAdmissionCaseModalVisible}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isAdmissionCaseModalVisible: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isAdmissionCaseModalVisible: false });
        }}
        header={true}
        footer={false}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              overflow: "inherit",
              paddingBottom: 6,
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 5,
            }}
          >
            <Form
              ref={this.formRef}
              {...layout}
              name="basic"
              onFinish={() => this.updateAdmissionCaseStatus()}
            >
              <Form.Item
                name="date"
                labelAlign="left"
                label="Modified Date"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Required!" }]}
              >
                <DatePicker
                  placeholder="Select Date"
                  calendarIcon={null}
                  clearIcon={null}
                  onChange={(e) => this.onChangeAdmissionDate("date", e)}
                  value={admissionStatusData ? admissionStatusData.date : ""}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Start" &&
                this.getStartDateFields()}
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Applied" &&
                this.getAppliedDateFields()}
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Conditional Offer" &&
                this.getConditionalDateFields()}
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Formal Offer" &&
                this.getFormalDateFields()}
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Paid" &&
                this.getPaidDateFields()}
              {admissionStatusData &&
                admissionStatusData.programStatusName === "Decline" &&
                this.getDeclineDateFields()}
              <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                <Button
                  disabled={modalLoading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                >
                  {modalLoading && <Spin size="small" />}
                  Save
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    this.resetTaskForm();
                    this.setState({ isAdmissionCaseModalVisible: false });
                  }}
                >
                  {modalLoading && <Spin size="small" />}
                  Close
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  onChangeSetting = (key, val) => {
    this.setState({
      studentsDashboardSet: { ...this.state.studentsDashboardSet, [key]: val },
    });
  };

  getSettingsModal = ({
    isSettingsModalOpen,
    pageLoading,
    studentsDashboardSet,
  }) => (
    <Modal
      title={"Dashboard Settings"}
      visible={isSettingsModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isSettingsModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isSettingsModalOpen: false });
      }}
      header={true}
      footer={false}
      bodyStyle={{ maxHeight: 450, overflow: "auto" }}
      width={992}
    >
      <div style={{ width: "100%" }}>
        <div>
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            className="grid-view"
            onFinish={() => {
              this.updateDashboardSettings();
            }}
          >
            {Object.keys(studentsDashboardSet).map((key) => {
              return (
                <Form.Item name={key}>
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Checkbox
                    checked={studentsDashboardSet[key]}
                    onChange={(e) =>
                      this.onChangeSetting(key, e.target.checked)
                    }
                  >
                    {columnObject[key]}
                    {/* {key} */}
                  </Checkbox>
                </Form.Item>
              );
            })}
            <Form.Item {...tailLayout} className="c-footer-button">
              <Button
                disabled={pageLoading}
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#1179af",
                  color: "#fff",
                  height: 32,
                  borderRadius: 5,
                  border: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                {pageLoading && <Spin size="small" />}
                Save
              </Button>
              <Button
                style={{
                  margin: "0 5px",
                  backgroundColor: "#1179af",
                  color: "#fff",
                  height: 32,
                  borderRadius: 5,
                  border: "none",
                  fontWeight: 500,
                  fontSize: 14,
                }}
                onClick={() => {
                  this.resetTaskForm();
                  this.setState({ isSettingsModalOpen: false });
                }}
                type="primary"
              >
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );

  getTaskAndNoteModal = ({
    isTaskModalOpen,
    pageLoading,
    ProcessingPersons,
    newTaskObj,
    fileNoteObj,
    fileNotes,
  }) => {
    return (
      <Modal
        title={"Details"}
        visible={isTaskModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isTaskModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 450, overflowY: "scroll" }}
        width={992}
        height={1000}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => this.addNewTask()}
              >
                <Form.Item
                  name="date"
                  labelAlign="left"
                  label="Due Date"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <DatePicker
                    calendarIcon={null}
                    placeholder="Select Date"
                    clearIcon={null}
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskDate", e.toJSON())
                    }
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Task Title :"
                  name="Title"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeTaskHandler("taskTitle", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Task Description :"
                  name="Description"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      this.onChangeTaskHandler(
                        "taskDescription",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Add Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ borderRadius: 0 }}
                    placeholder="Processing Persons"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue="a"
                    onSelect={this.onChangeFollower.bind(this)}
                    // value={processingPerson ? processingPerson : null}
                  >
                    <Option value={"a"}>Please Select</Option>
                    {ProcessingPersons.map((person) => (
                      <Option value={person.id}>{person.fullName}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  // className={"add-client-form-inputs"}
                  colon={false}
                  labelAlign="left"
                  label="Followers :"
                  name="followers"
                  style={{ width: "100%" }}
                >
                  {newTaskObj && newTaskObj.taskUsers
                    ? newTaskObj.taskUsers.map((el, ind) => (
                        <>
                          <Tag color="#2db7f5">
                            <span
                              onClick={() => {
                                newTaskObj.taskUsers.splice(ind, 1);
                                this.setState({ newTaskObj: newTaskObj });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <CloseOutlined />{" "}
                            </span>{" "}
                            {el.name}
                          </Tag>
                        </>
                      ))
                    : null}
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="button-blue"
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div>
            <span style={{ fontSize: 14, fontWeight: "500", color: "#266090" }}>
              TASK
            </span>
            <span>
              <div style={{ marginLeft: "auto", float: "right" }}>
                <UpSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => this.sortDailyTaskAsc(newTaskObj.currentTasks)}
                />{" "}
                <DownSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.sortDailyTaskDesc(newTaskObj.currentTasks)
                  }
                />
              </div>
            </span>
          </div>
          <div className="modalTable">
            {newTaskObj.currentTasks
              ? newTaskObj.currentTasks.map((task) => (
                  <>
                    <div
                      className="cm-table-content-row"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="content-index-50">
                        <div
                          class="cm-greenbox-cont"
                          style={{ display: "-webkit-inline-box" }}
                        >
                          <div
                            class="cm-green-box"
                            style={{
                              backgroundColor: task.isCompleted
                                ? "#3ab449"
                                : "orange",
                              marginRight: 10,
                            }}
                          ></div>
                          <span
                            onClick={() => {
                              task.isTaskCommentOpen = !task.isTaskCommentOpen;
                              if (!task.isTaskCommentOpen)
                                return this.setState({
                                  newTaskObj: this.state.newTaskObj,
                                });
                              this.setState({
                                pageLoading: true,
                              });
                              this.getTaskComments(task);
                              this.getTaskFollowers(task);
                            }}
                            class="cm-task-text"
                            style={{ marginLeft: 0 }}
                          >
                            {task.taskTitle}
                          </span>
                          <span>
                            ({moment(task.taskDate).format("DD/MM/YYYY")})
                          </span>
                        </div>
                      </div>
                    </div>
                    {task.isTaskCommentOpen && (
                      <>
                        <div
                          className="cm-table-content-row"
                          style={{
                            marginTop: 0,
                            alignItems: "flex-start",
                            border: 0,
                          }}
                        >
                          <div>
                            <div
                              className="content-index-5"
                              style={{ marginTop: 25, marginLeft: 10 }}
                            >
                              <div style={{ display: "flex" }}>
                                <span class="cm-followers-text">Followers</span>
                                {task.users
                                  ? task.users.map((item) => (
                                      <span
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {item.imgURL &&
                                        item.imgURL != null &&
                                        item.imgURL != "" ? (
                                          <Tooltip
                                            title={item.fullName}
                                            color="#eae9d4"
                                            overlayInnerStyle={{
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <CloseCircleOutlined
                                                style={{
                                                  position: "absolute",
                                                  right: 0,
                                                  top: -8,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.removeFollower(
                                                    item.id,
                                                    task
                                                  )
                                                }
                                              />
                                              <Avatar
                                                src={
                                                  <Image
                                                    src={item.imgURL}
                                                    style={{
                                                      height: 32,
                                                      width: 32,
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title={item.fullName}
                                            color="#eae9d4"
                                            overlayInnerStyle={{
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{ position: "relative" }}
                                            >
                                              <CloseCircleOutlined
                                                style={{
                                                  position: "absolute",
                                                  right: 0,
                                                  top: -8,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.removeFollower(
                                                    item.id,
                                                    task
                                                  )
                                                }
                                              />
                                              <Avatar
                                                className="mar-right"
                                                size="small"
                                                style={{
                                                  height: 32,
                                                  width: 32,
                                                }}
                                                icon={
                                                  <UserOutlined
                                                    style={{
                                                      height: 32,
                                                      width: 32,
                                                    }}
                                                  />
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        )}
                                      </span>
                                    ))
                                  : null}
                                {/* <div
                                  class="cm-profile-fill-cont"
                                  style={{ marginLeft: 20 }}
                                >
                                  <img
                                    src={personFill}
                                    class="cm-profile-fill"
                                  />
                                </div>
                                <div class="cm-profile-fill-cont">
                                  <img
                                    src={personFill}
                                    class="cm-profile-fill"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>

                          <div
                            className="content-index-5"
                            style={{ marginTop: 3 }}
                            onClick={() => {
                              task.isTaskCommentOpen = !task.isTaskCommentOpen;
                              this.setState({
                                cliens: this.state.clients,
                              });
                            }}
                          >
                            <img
                              src={grayCross}
                              class="cm-icon-size"
                              style={{ marginLeft: 5 }}
                            />
                          </div>
                        </div>
                        <textarea
                          value={task.comment ? task.comment : ""}
                          onChange={(e) => {
                            task.comment = e.target.value;
                            this.setState({
                              clients: this.state.clients,
                            });
                          }}
                          rows={5}
                          class="cm-textarea"
                        />

                        <div class="cm-gray-btn-cont">
                          <div></div>
                          <Button
                            disabled={
                              task.comment
                                ? task.comment.length
                                  ? false
                                  : true
                                : true
                            }
                            style={{
                              backgroundColor: task.comment
                                ? task.comment.length
                                  ? "blue"
                                  : "grey"
                                : "grey",
                            }}
                            onClick={() => this.addTaskComment(task)}
                            // class="cm-gray-btn"
                          >
                            <span class="cm-gray-btn-text">Comment</span>
                            <img
                              src={rightArrow}
                              class="cm-icon-size"
                              style={{ marginLeft: 10 }}
                            />
                          </Button>
                        </div>
                        {task.comments ? (
                          <div
                            className="cm-table-content-rows"
                            style={{ marginBottom: 0 }}
                          >
                            {task.comments.map((el) => (
                              <>
                                <div class="cm-profile-boxes">
                                  <div class="cm-profile-fill-conts">
                                    {el.imgURL ? (
                                      <img
                                        src={el.imgURL}
                                        class="cm-profile-fills"
                                      />
                                    ) : null}
                                  </div>
                                  <div style={{ alignSelf: "center" }}>
                                    <h4>{el.userName}</h4>
                                  </div>
                                </div>
                                <div className="content-index-100">
                                  <div>
                                    <span class="cm-task-texts">
                                      {el.description}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                ))
              : null}
          </div>
          <div style={{ width: "100%" }}>
            <div className="file-note-modal">
              <Form
                ref={this.formRef2}
                {...layout}
                name="basic"
                onFinish={() => this.onAddClientFile()}
              >
                <Form.Item name="fileNote" style={{ width: "100%" }}>
                  <div className="letter-froala">
                    <FroalaEditor
                      ref={(el) => {
                        config = el;
                      }}
                      config={config}
                      name="model_data"
                      model={fileNoteObj.fileNote}
                      onModelChange={this.handleNoteModelChange}
                    />
                  </div>
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="button-blue"
                    style={{ float: "right" }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="file-note">
              {fileNotes &&
                fileNotes.map((el) => (
                  <>
                    <div className="file-note-list">
                      <div className="d-flex">
                        <p style={{ marginRight: 10 }}>
                          {moment(el.createdDate).format("DD/MM/YYYY")}
                        </p>
                        <p>{moment(el.createdDate).format("HH:MM A")}</p>
                      </div>
                      <div>{renderHTML(el.fileNote)}</div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  };

  addTaskComment = (task) => {
    this.setState({ pageLoading: true });
    let { id, comment } = task;
    let userId = localStorage.getItem("userId");
    this.props
      .onAddTaskComment({
        taskId: id,
        description: comment,
      })
      .then(() => {
        openNotificationWithIcon("success", "Comment added!");
        this.getTaskComments(task);
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  getTaskFollowers = (task) => {
    this.props.onGetTaskFollowers(task.id).then((res) => {
      let users = res.payload && res.payload.items;
      if (users) {
        task.users = users;
        this.setState({
          newTaskObj: this.state.newTaskObj,
          pageLoading: false,
        });
      }
      this.setState({ pageLoading: false });
    });
  };

  getTaskComments = (task) => {
    this.props
      .onGetTaskComment(task.id)
      .then((res) => {
        let comm = res.payload && res.payload.items;
        if (comm) {
          task.comments = comm;
          task.comment = "";
          this.setState({
            newTaskObj: this.state.newTaskObj,
            pageLoading: false,
          });
        }
        this.setState({ pageLoading: false });
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  sortDailyTaskDesc = (tasks) => {
    let { newTaskObj } = this.state;
    let data = tasks.sort((a, b) => {
      if (moment(a.taskDate) > moment(b.taskDate)) return -1;
      if (moment(a.taskDate) < moment(b.taskDate)) return 1;
      else return 0;
    });
    this.setState({
      newTaskObj: { ...newTaskObj, currentTasks: data, pageLoading: false },
    });
  };

  sortDailyTaskAsc = (tasks) => {
    let { newTaskObj } = this.state;
    let data = tasks.sort((a, b) => {
      if (moment(a.taskDate) > moment(b.taskDate)) return 1;
      if (moment(a.taskDate) < moment(b.taskDate)) return -1;
      else return 0;
    });
    this.setState({
      newTaskObj: { ...newTaskObj, currentTasks: data, pageLoading: false },
    });
  };

  viewTasks = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: data,
          },
          pageLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: [],
          },
          pageLoading: false,
        })
      );
    this.props
      .onGetClientFile(record.id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };

  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };

  onChangeFollower = (val, val2) => {
    let { newTaskObj } = this.state;
    this.setState(
      {
        newTaskObj: {
          ...newTaskObj,
          taskUsers: [
            ...newTaskObj.taskUsers,
            { userId: val, name: val2.children },
          ],
        },
      },
      () => this.formRef.current.setFieldsValue({ followers: "a" })
    );
  };

  onAddClientFile = () => {
    this.setState({ pageLoading: true });
    let { fileNoteObj } = this.state;
    let { onAddClientFile } = this.props;
    let userId = localStorage.getItem("userId");
    onAddClientFile(fileNoteObj)
      .then((res) => {
        this.getAllFileNotes(fileNoteObj.clientId);
        openNotificationWithIcon("success", "File Note Added!");
        this.setState(
          {
            pageLoading: false,
            fileNoteObj: { ...fileNoteObj, fileNote: "" },
          },
          () => config.editor.html.set("")
        );
      })
      .catch(() => this.setState({ pageLoading: false }));
  };

  openClientFile = ({ id, clientId, memberType, familyId }) => {
    localStorage.setItem("clientprofileid", JSON.stringify(id));
    localStorage.setItem("familyId", familyId);

    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      this.props.history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      this.props.history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      this.props.history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };

  removeFollower = (id, task) => {
    let userId = localStorage.getItem("userId");
    let payload = {
      id: id,
      delete: true,
    };
    this.setState({ pageLoading: true });
    this.props.onRemoveFollower(payload).then((res) => {
      message.success("Follower Removed!");
      task.users = task.users.filter((el) => el.id !== id);
      this.setState({ newTaskObj: this.state.newTaskObj, pageLoading: false });
    });
  };

  getAllFileNotes = (id) => {
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({ fileNotes: notes, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };

  addNewTask = () => {
    let userId = localStorage.getItem("userId");

    this.setState({ pageLoading: true });
    let {
      subjectId,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers,
    } = this.state.newTaskObj;
    let taskObj = {
      subjectId,
      isPontential: false,
      isCompleted: false,
      caseCheckListId: 0,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers: taskUsers.map((el) => ({ userId: el.userId })),
    };
    this.props
      .onAddTask(taskObj)
      .then((res) => {
        this.getTasks({ id: subjectId });
      })
      .catch((err) => {
        this.setState({ pageLoading: false });
      });
  };

  getTasks = (record) => {
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let data = res.payload.items.sort((a, b) => {
          if (moment(a.taskDate) > moment(b.taskDate)) return -1;
          if (moment(a.taskDate) < moment(b.taskDate)) return 1;
          else return 0;
        });
        openNotificationWithIcon("success", "Task Added");
        this.setState(
          {
            newTaskObj: {
              ...this.state.newTaskObj,
              currentTasks: data,
              taskUsers: [],
            },
            pageLoading: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch((er) => {
        this.setState({
          pageLoading: false,
        });
      });
  };

  handleNoteModelChange = (val) => {
    let { fileNoteObj } = this.state;
    this.setState({ fileNoteObj: { ...fileNoteObj, fileNote: val } });
  };

  updateDashboardSettings = () => {
    let { studentsDashboardSet } = this.state;
    this.setState({ pageLoading: true });
    const stuDashOpt = {
      url: `v1/dashboard/UpdateStudentDashboardSetting`,
    };
    stuDashOpt.types = [
      "STUDENT_DASHBOARD_SETTINGS_SUCCESS",
      "STUDENT_DASHBOARD_SETTINGS_FAILURE",
    ];

    apiRefresh
      .put(stuDashOpt, studentsDashboardSet)
      .then(() => {
        this.filterColumns(studentsDashboardSet);
        this.setState(
          { pageLoading: false, isSettingsModalOpen: false },
          () => {
            openNotificationWithIcon(
              "success",
              "Student Dashboard settings updated"
            );
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            pageLoading: false,
          },
          () =>
            openNotificationWithIcon(
              "error",
              "Error:",
              "Could't update settings."
            )
        );
      });
  };

  paginate = (value, filters, sorter) => {
    let filt = "";
    let sorterString = "";
    let sortArr = [...this.state.sorterArray];
    if (filters && filters.priority && filters.priority.length > 0) {
      for (var i = 0; i < filters.priority.length; i++) {
        if (i == 0) filt = filters.priority[i];
        else filt += "," + filters.priority[i];
      }
    }
    let order = sorter && sorter.order == "ascend" ? "asc" : "desc";
    let sortCol = sorter && sorter.field ? true : false;
    if (sorter && sorter.field) {
      let findInd = sortArr.findIndex((obj) => obj.field === sorter.field);
      if (findInd > -1) {
        sortArr.splice(findInd, 1);
      }
      sortArr.push(sorter);
      for (var i = 0; i < sortArr.length; i++) {
        let order = sortArr[i].order == "ascend" ? "asc" : "desc";
        if (i === 0) {
          sorterString = sortArr[i].field + " " + order;
        } else {
          sorterString = sorterString + "," + sortArr[i].field + " " + order;
        }
      }
    }

    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        columnState: sorterString,
        priorityState: filt,
        sorterArray: sortArr,
      },
      () => {
        this.getDashboardStudents(
          this.state.processingPerson,
          this.state.status
        );
      }
    );
  };

  onExport = () => {
    let { processingPerson, selectedBranch } = this.state;

    let findStatus = this.state.admissionStatus.find(
      (obj) => obj.id == this.state.status
    );

    this.setState({ pageLoading: true });
    const clientExportOpt = {
      url: `v1/dashboard/StudentExport`,
    };
    clientExportOpt.types = ["CLIENT_EXPORT_SUCCESS", "CLIENT_EXPORT_FAILURE"];

    let data = {
      pageSize: 0,
      pageNumber: 0,
      processingPerson: processingPerson,
      agentId: this.state.agentId,
      status: findStatus ? findStatus.name : "",
      column: this.state.columnState,
      priority: this.state.priorityState,
    };

    apiRefresh
      .postWithFileRes(clientExportOpt, data)
      .then((res) => {
        this.setState({ pageLoading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Student_Summary.xlsx");
      })
      .catch((err) => this.setState({ pageLoading: false }));
  };

  render() {
    const {
      pageLoading,
      totalPages,
      ProcessingPersons,
      processingPerson,
      Countries,
      Counts,
      students,
      pageSize,
      currentPage,
    } = this.state;
    let { count, high, low, medium, onShore, visaCount } = Counts;
    return (
      <Spin spinning={pageLoading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <Row>
                <Col xs={24}>
                  <DashboardClientTopBars data={topBar} activeTab="Students" />
                </Col>
              </Row>
              <div class="container-fluid">
                <div
                  class="top-row"
                  style={{ paddingRight: 0, marginBottom: 0 }}
                >
                  <div
                    class="top-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div class="select-optionss" style={{ paddingRight: 1 }}>
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Processing Person"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(e) => {
                            this.setState(
                              { processingPerson: e, pageNumber: 1 },
                              () => {
                                this.getDashboardStudents(e, this.state.status);
                              }
                            );
                          }}
                          value={this.state.processingPerson}
                        >
                          <Option
                            value={"00000000-0000-0000-0000-000000000000"}
                          >
                            All
                          </Option>
                          {this.props.teamMembers &&
                            this.props.teamMembers.users.map((person) => (
                              <Option key={person.id}>{person.fullName}</Option>
                            ))}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Select Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(e) => {
                            this.setState(
                              { status: e.toString(), pageNumber: 1 },
                              () => {
                                this.getDashboardStudents(
                                  this.state.processingPerson,
                                  e.toString()
                                );
                              }
                            );
                          }}
                          // defaultValue={"$001"}
                        >
                          <Option value={""}>All</Option>
                          {this.state.admissionStatus.map((cont) => (
                            <Option value={cont.id}>{cont.name}</Option>
                          ))}
                        </Select>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-high-filled"></div>
                          <span class="priority-text">High</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({high})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-medium-filled"></div>
                          <span class="priority-text">Medium</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({medium})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-low-filled"></div>
                          <span class="priority-text">Low</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">({low})</span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="todays-tasks-filled"></div>
                          <span class="priority-text">
                            Today's Task/Reminders
                          </span>
                        </div>
                      </div>

                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="offshore-clients-filled"></div>
                          <span class="priority-text">Offshore Clients</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        class="clients-count-cont"
                        style={{ width: 78, height: 24 }}
                      >
                        <span
                          style={{
                            fontSize: 11,
                            color: "#687278",
                            fontFamily: "Poppins",
                          }}
                        >
                          Clients:{" "}
                          <span
                            style={{ fontWeight: "700", fontFamily: "Poppins" }}
                          >
                            {totalPages}
                          </span>
                        </span>
                      </div>
                      <div
                        class="clients-count-cont"
                        style={{ marginLeft: 5, width: 78, height: 24 }}
                      >
                        <span
                          style={{
                            fontSize: 11,
                            color: "#687278",
                            fontFamily: "Poppins",
                          }}
                        >
                          Cases:{" "}
                          <span
                            style={{ fontWeight: "700", fontFamily: "Poppins" }}
                          >
                            {visaCount}
                          </span>
                        </span>
                      </div>
                      <div
                        style={{ height: 24 }}
                        onClick={() =>
                          this.setState({ isSettingsModalOpen: true })
                        }
                        class="setting-export-cont"
                      >
                        <img src={settings} class="setting-export-img" />
                      </div>
                      {/* <div class="setting-export-cont" style={{ height: 24 }}>
                        <img
                          style={{ cursor: "pointer" }}
                          src={exportImg}
                          class="setting-export-img"
                        />
                      </div> */}
                      <div class="setting-export-cont" style={{ height: 24 }}>
                        <Tooltip placement="topLeft" title={`Student Export`}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={exportImg}
                            class="setting-export-img"
                            onClick={this.onExport}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-card">
                <Table
                  ref={(el) => (this.potentialClientRef = el)}
                  // rowClassName={(record, index) =>
                  //   index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  // }
                  className="dashboard-student-summary"
                  columns={this.mainColumns}
                  dataSource={students}
                  showSorterTooltip={{ title: "" }}
                  onChange={this.paginate}
                  pagination={{
                    defaultCurrent: currentPage,
                    current: this.state.pageNumber,
                    total: totalPages,
                    showSizeChanger: true,
                    defaultPageSize: pageSize,
                    pageSizeOptions: ["20", "50", "100", "150", "200"],
                  }}
                  scroll={{ x: "auto", y: 540 }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.getTaskAndNoteModal(this.state)}
        {this.updateAdmissionStatusModal()}
        {this.getSettingsModal(this.state)}
      </Spin>
    );
  }
}

export default Student;
