import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MyDailyTasksReminder from "./../MyDailyTasksReminders";

import {
  getReminderTasks,
  addReminderTask,
  getDailyTasks,
  addDailyTasks,
  removeTasks,
  updetaTasks,
  updateCompletedTask,
  getAllClients,
  removeReminderTask,
  getTasksComments,
  addTaskComments,
  getUsers,
  getTasksFollowers,
  addTaskFollower,
  searchMainClient,
  searchPotentialClient,
  updateReminderDetail,
  getPotentialClientInfo,
  getClientProfile,
  getPotentialClientUpdateBol,
  removeFollower,
  removeTaskClient,
  addTaskFileNote,
  getCompletedTasks,
  getEmployerData,
  getTimeTrackingPopup,
  getVisaWorkType,
  getVisaTypePrice,
  getServiceType,
  getVisaType,
  getPermissonUser,
  getVisaApplication,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    reminderTaskRes: state.reminderReducer.reminderTaskRes,
    // addReminderTaskRes: state.reminderTaskRes.addReminderTaskRes,
    dailyTaskRes: state.reminderReducer.dailyTaskRes,
    adddailyTaskRes: state.reminderReducer.adddailyTaskRes,
    removeTaskRes: state.reminderReducer.removeTaskRes,
    updateTaskRes: state.reminderReducer.updateTaskRes,
    updateComletedTaskRes: state.reminderReducer.updateComletedTaskRes,
    allClientData: state.allClientReducer.allClientData,
    removeReminderRes: state.allClientReducer.removeReminderRes,
    searchClientData: state.clientProfileReducer.searchConnectionData,
    potentialClientsData: state.clientProfileReducer.potentialClientsData,
    timeTrackingPopupRes: state.timeTrackingReducer.timeTrackingPopupRes,
    visaWorkTypeRes: state.timeTrackingReducer.visaWorkTypeRes,
    visaTypePriceRes: state.timeTrackingReducer.visaTypePriceRes,
    serviceTypeRes: state.timeTrackingReducer.serviceTypeRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaAppData: state.clientVisaReducer.visaAppData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetReminderTasks: bindActionCreators(getReminderTasks, dispatch),
    onAddReminderTask: bindActionCreators(addReminderTask, dispatch),
    onGetDailyTasks: bindActionCreators(getDailyTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onUpdetaTasks: bindActionCreators(updetaTasks, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onGetAllClients: bindActionCreators(getAllClients, dispatch),
    onRemoveReminderTask: bindActionCreators(removeReminderTask, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onAddTaskComments: bindActionCreators(addTaskComments, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onSearchPotentialClient: bindActionCreators(
      searchPotentialClient,
      dispatch
    ),
    onUpdateReminderDetail: bindActionCreators(updateReminderDetail, dispatch),
    onGetPotentialClient: bindActionCreators(getPotentialClientInfo, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetUpdateBol: bindActionCreators(getPotentialClientUpdateBol, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onRemoveTaskClient: bindActionCreators(removeTaskClient, dispatch),
    onAddFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetCompletedTasks: bindActionCreators(getCompletedTasks, dispatch),
    onGetEmployerData: bindActionCreators(getEmployerData, dispatch),
    onGetTimeTrackingPopup: bindActionCreators(getTimeTrackingPopup, dispatch),
    onGetVisaWorkType: bindActionCreators(getVisaWorkType, dispatch),
    onGetVisaTypePrice: bindActionCreators(getVisaTypePrice, dispatch),
    onGetServiceType: bindActionCreators(getServiceType, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDailyTasksReminder);
