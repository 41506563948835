import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";

const ClientTags = ({
  onAddClientTag,
  addClientTagRes,

  handleCancel,

  onGetClientTag,
  clientTagRes,

  setLoading,

  onUpdetaClientTag,
  updateClientTagRes,

  createMode,

  updatedata,
}) => {
  const [tagName, setTagName] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: updatedata.name,
    });
  }, [updatedata]);
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    let selectedBranchId = localStorage.getItem("selectedBranchId");

    if (createMode === "add-tag") {
      const data = {
        subjectId: selectedBranchId,
        name: values.name,
      };
      onAddClientTag(data)
        .then(() => handleCancel())
        .then(() => onGetClientTag())
        .then(() => {
          setLoading(false);
          message.success("Successfully Added!");
        });
    } else {
      const updata = {
        id: updatedata.id,
        subjectId: updatedata && updatedata.subjectId,
        name: values.name,
      };

      onUpdetaClientTag(updata)
        .then(() => handleCancel())
        .then(() => onGetClientTag())
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        });
    }
  };

  return (
    <Fragment>
      <Form name="main" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input placeholder="Add New" />
        </Form.Item>
        <Form.Item>
          <Button
            className="form-btn button-blue"
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
export default ClientTags;
