import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Spin,
  Row,
  Col,
  Select,
  Checkbox,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import activityData from "../../Components/ClientActivity/ActivityData";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { getFilterListForModulesOtherThanInvoices } from "../../Common/getEmailContentList";
import { getEmailContent } from "../../store/Actions/index";
import { getCompany } from "../../store/Actions";
import * as types from "../../store/Constants";
const { Option } = Select;

const QuestionnaireEmail = ({
  handleCancel,
  visible,
  emailContent,
  onSendEmail,
  dynamicLinkState,
  questionnaireName,
  clientEmail,
  onAddDraftClient,
  clientprofileid,
  isPotentialClient,
  isEmployer,
  clientName,
  signatureText,
}) => {
  const [letterString, setLetterString] = useState("");
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState("");
  const [hostName, setHostName] = useState("");
  const [resultList, setResultList] = useState([]);
  const [_emailContent, setEmailContent] = useState();
  const [isSendCopy, setIsSendCopy] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setEmailContent(
      `Dear ${clientName} <br/> ${emailContent} <br/> ${signatureText}`
    );
  }, [emailContent]);

  useEffect(() => {
    const Opt = {
      url: `v1/emailtemplate`,
    };
    Opt.types = [
      types.GET_EMAIL_CONTENT_SUCCESS,
      types.GET_EMAIL_CONTENT_FAILURE,
    ];

    apiRefresh.get(Opt).then((res) => {
      let resultList = getFilterListForModulesOtherThanInvoices(res);
      setResultList(resultList);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: "",
      to: clientEmail || "",
      subject: questionnaireName || "",
    });
    if (clientEmail) {
      setToSend(clientEmail);
    } else {
      setToSend("");
    }
  }, [clientEmail, questionnaireName]);
  const onFinish = (values) => {
    if (_emailContent !== "" && _emailContent !== undefined) {
      console.log("Received values of form:", values);
      let selectedBranchName = localStorage.getItem("selectedBranchName");
      // const fromEmail = getSpecificUserEmail();
      console.log("Success:", values);
      const formData = new FormData();
      if (isSendCopy === true) {
        formData.append(
          `Recipients[1].name`,
          localStorage.getItem("userEmail")
        );
        formData.append(`Recipients[1].type`, "CC");
      }
      formData.append("Message", _emailContent);
      formData.append("Subject", values.subject);
      formData.append("From", selectedBranchName);
      formData.append("Priority", "1");
      formData.append("FrequencyCode", "EZM");
      formData.append("Status", "Queued");
      formData.append("ModuleId", "2");
      formData.append("isSent", true);
      if (isPotentialClient) {
        formData.append("IsPotential", true);
      }
      // selectedUser && selectedUser.map((data, index) => (
      //   formData.append(`Recipients[${index}].name`, JSON.stringify(data))
      // ))
      formData.append(`Recipients[0].name`, toSend);
      // selectedUser && selectedUser.map((data, index) => (
      //   formData.append(`Recipients[${index}].type`, "To")
      // ))
      formData.append(`Recipients[0].type`, "To");
      // formData.append("Attachments", []);
      formData.append(`Attachments[0].name`, "");
      formData.append(`Attachments[0].type`, "");
      formData.append(`Attachments[0].blobURL`, "");

      let userId = localStorage.getItem("userId");

      const draftData = {
        id: 0,
        emailMessage: _emailContent || "",
        isRead: true,
        attachmentName: "",
        attachmentUrl: "",
        sizeInKB: 0,
        subject: values && values ? values.subject : "",
        from: selectedBranchName,

        import: false,
        to: toSend,
        cc: "",
        bcc: "",
        notClients: false,
        clientReply: false,
        clientReplyMail: 0,
        importMessageId: "",
        draft: false,
        emailType: 0,
        importText: "",
        // clientId: clientprofileid,
        importedDate: new Date(),
        autoImport: false,
        isSent: true,
        clientEmailType: 0,
        timeStamp: new Date(),
      };

      if (isPotentialClient) {
        draftData.potentialClientId = clientprofileid;
      } else if (isEmployer) {
        draftData.employerId = clientprofileid;
      } else {
        draftData.clientId = clientprofileid;
      }

      let url = "";
      if (isPotentialClient) {
        url = `v1/imap/PotentialClientEmail`;
      } else if (isEmployer) {
        url = `v1/imap/EmployerEmail`;
      } else {
        url = `v1/imap/ClientEmail`;
      }

      const histOpt = {
        url: url,
      };
      histOpt.types = [
        "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
        "EMAIL_QUEUE_WITH_BLOB_FAILURE",
      ];

      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(sessionStorage.getItem("profileData"));
      let myData = {
        clientName: clientName,
        logMessage:
          " Email with subject : " +
          values.subject +
          "\n" +
          '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
          '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
          '<div style="display: none">' +
          _emailContent +
          "</div>" +
          "<br />" +
          " created by " +
          userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Questionnaire",
        invoiceId: "0",
      };
      apiRefresh.post(histOpt, draftData).then((resp) => {
        formData.append("emailId", resp);
        const VisaOpt = {
          url: `v1/emailqueue/EmailQueueWithBlobUrl`,
        };
        VisaOpt.types = [
          "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
          "EMAIL_QUEUE_WITH_BLOB_FAILURE",
        ];

        activityData(myData);
        apiRefresh.post(VisaOpt, formData).then(() => {
          message.success(`Successfully Email Send`);
          form.setFieldsValue({
            contentType: undefined,
            content: emailContent,
          });
          setEmailContent(emailContent);
          handleCancel();
        });
      });

      var name =
        window.location.host == "localhost:3000" ? "http://" : "https://";
      name += window.location.host;

      setHostName(name);

      // let clientprofileid = sessionStorage.getItem("clientprofileid");
      let familyId = sessionStorage.getItem("familyId");

      if (questionnaireName) {
        let linkData = {
          clientId: clientprofileid || "00000000-0000-0000-0000-000000000000",
          familyId: familyId,
          linkType: 1,
          link: name + "/c_q?para=" + dynamicLinkState,
          name: questionnaireName,
        };

        const VisaOpt = {
          url: `v1/client/Link`,
        };
        VisaOpt.types = ["SAVE_LINK_SUCCES", "SAVE_LINK_FAILURE"];

        apiRefresh
          .post(VisaOpt, linkData)
          .then((res) => {})
          .catch((err) => {});
      }
    } else {
      message.error("Content is empty!");
    }
  };

  const onChange = (value) => {
    setEmailContent(value);
  };

  const onhandlingCancel = () => {
    handleCancel();
    form.setFieldsValue({
      contentType: undefined,
      content: emailContent,
    });
    setEmailContent(emailContent);
  };

  const onRequiredChange = (e) => {
    setIsSendCopy(e.target.checked);
  };

  return (
    <Fragment>
      <Modal
        title="Title"
        visible={visible}
        footer={false}
        onCancel={onhandlingCancel}
        width="600px"
      >
        <div className="border-box">
          <Form
            onFinish={onFinish}
            form={form}
            className="width-100"
            name="main"
          >
            <Form.Item name="to">
              <div className="width-100 d-flex align-center letter-font">
                <div className="w-91">
                  <Input
                    placeholder="To"
                    value={toSend}
                    onChange={(e) => setToSend(e.target.value)}
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item required={false}>
              <Checkbox onChange={onRequiredChange} checked={isSendCopy}>
                Send me a copy
              </Checkbox>
            </Form.Item>

            <div className="width-100 d-flex align-center letter-font">
              <div className="w-91" style={{ marginTop: 10 }}>
                <Form.Item
                  name="subject"
                  rules={[
                    { required: true, message: "Required!" },
                    {
                      max: 280,
                      message: "Subject should be less than 280 character",
                    }
                  ]}
                >
                  <Input placeholder="Subject"  maxLength={280} />
                </Form.Item>
              </div>
            </div>
            <Row>
              <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Form.Item name="contentType">
                  <Select
                    placeholder={"Select content type"}
                    virtual={false}
                    onChange={(value) => onChange(value)}
                  >
                    <Option value={""}>{"Select"}</Option>
                    {resultList.map((item, index) => (
                      <Option value={item.emailContent}>
                        {item.emailType}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="" name="content">
              <div className="width-100 d-flex align-center letter-font letter-spacing">
                <div className="w-91 letter-froala" style={{ marginTop: 10 }}>
                  <Spin size={"large"} spinning={loading}>
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={_emailContent}
                      onModelChange={(value) => setEmailContent(value)}
                    />
                  </Spin>
                </div>
              </div>
            </Form.Item>
            <Form.Item className="d-flex" style={{ marginTop: 20 }}>
              <Button
                className="form-btn button-blue"
                type="primary"
                htmlType="submit"
              >
                SEND
              </Button>
            </Form.Item>

            {false && (
              <Form.Item className="d-flex">
                <Button
                  className="form-btn button-blue"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
                <div className="margin-btns">
                  <Button className="form-btn button-blue" type="primary">
                    PREVIEW
                  </Button>
                </div>
                <div className="margin-btns">
                  <Button
                    onClick={() => onhandlingCancel()}
                    className="form-btn button-blue"
                    type="primary"
                  >
                    CANCEL
                  </Button>
                </div>
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};
export default QuestionnaireEmail;
