import React, { useState } from "react";
import { Upload, Button, message, Row, Col, Modal, Spin, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import activityData from "../../../Components/ClientActivity/ActivityData";
import moment from "moment";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const { Option } = Select;
const AddEmployerDocuments = ({
  onAddEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  clientProfileData,

  onGetEmployerDocument,
  employerDocumentRes,

  onFilterData,

  handleCancel,
  folderDocType,
}) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, SetPreviewVisible] = useState(false);
  const [previewImage, SetPreviewImage] = useState("");
  const [previewTitle, SetPreviewTitle] = useState("");
  const [docTypeValue, SetDocTypeValue] = useState("0");
  const [docTypeId, setdocTypeId] = useState(folderDocType);
  const [loading, setLoading] = useState(false);

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    } else setFileList(fileList);
  };

  const handleSubmit = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    if (fileList.length > 10) {
      message.error("10 files at a time can be uploaded.");
    } else if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile = fileList[i].originFileObj;
        let extensionArr = selectedFile.name.split(".");
        let fileExt = extensionArr[extensionArr.length - 1];
        if (
          fileExt == "mp3" ||
          fileExt == "mp4" ||
          fileExt == "avi" ||
          fileExt == "mov" ||
          fileExt == "wmv"
        ) {
          message.error(fileExt + " type is not allowed");
        } else {
          setLoading(true);
          let formData = new FormData();
          formData.append("file", selectedFile);

          const JsonData = {
            SubjectId: clientprofileid,
            FamilyId: "00000000-0000-0000-0000-000000000000",
            DocumentTypeId: parseInt(docTypeValue ?? "0"),
            Title: selectedFile && selectedFile.name,
            SizeInKB: selectedFile && selectedFile.size,
            DocuementExtension: selectedFile && selectedFile.type,
            IsAgent: false,
            IsClient: false,
            ShowAgent: false,
            ShowClient: false,
            QuestionnaireId: 0,
            BranchId: localStorage.getItem("selectedBranchId"),
          };
          formData.append("Data", JSON.stringify(JsonData));
          onAddEmployerDocument(formData)
            .then(() => {
              var data = new Object();
              data.id = clientprofileid;
              data.docType = folderDocType;
              data.CaseId = 0;
              data.IsVisa = false;
              onGetEmployerDocument(data);
              setLoading(false);
              handleCancel();
              let userName = localStorage.getItem("userName");
              var profileData = JSON.parse(
                sessionStorage.getItem("profileData")
              );
              let myData = {
                clientName: profileData.fullName,
                logMessage:
                  "\n Document title " +
                  JsonData.Title +
                  " uploaded " +
                  " by " +
                  userName,
                date: moment(new Date()).format("DD/MM/YYYY"),
                logType: "Client Documents",
                invoiceId: "0",
              };
              activityData(myData);
              message.success("Successfully Added!");
            })
            .catch(() => setLoading(false));
        }
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    SetPreviewVisible(true);
    SetPreviewImage(file.url || file.preview);

    SetPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <Spin size="large" spinning={loading}>
      <Row>
        <div>
          <Select
            style={{ width: 150, marginBottom: 10 }}
            placeholder="Select"
            allowClear
            defaultValue="Select"
            onChange={(value) => SetDocTypeValue(value)}
          >
            <Option Key="0">Select</Option>
            {onFilterData &&
              onFilterData.items &&
              onFilterData.items.map((item, index) => (
                <Option key={item.id}> {item.name}</Option>
              ))}
          </Select>
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <div className="client-doc-main">
            <Upload
              listType="picture-card"
              fileList={fileList}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={onHandleChange}
              multiple
            >
              {fileList && fileList.length < 10 ? (
                <div className="import-file-button-sec">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </div>
              ) : null}
            </Upload>
          </div>
          <Button
            style={{ marginTop: "10px", float: "right" }}
            onClick={handleSubmit}
            className={fileList && fileList[0] && "button-blue"}
            type="primary"
            disabled={!(fileList && fileList[0])}
          >
            Upload
          </Button>

          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Col>
      </Row>
    </Spin>
  );
};

export default AddEmployerDocuments;
