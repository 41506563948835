import React, { Fragment, useEffect } from "react";
import { Table, Spin } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import { Link } from "react-router-dom";

const EmployerClient = ({
  onGetClientByEmpId,
  empClientEmpRes,
  userDataEmp,
}) => {
  useEffect(() => {
    const clientData = {
      employerId: userDataEmp && userDataEmp.id,
      searchParam: "",
    };

    onGetClientByEmpId(clientData).then(() => {
      // setLoading(false);
    });
  }, [onGetClientByEmpId, userDataEmp]);

  const history = useHistory();

  // useEffect(() => {
  //   setLoading(true);
  //
  //   onGetClientJobHis(isClientTagId && isClientTagId)
  //     .then(() => {
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // }, [isClientTagId, onGetClientJobHis, setLoading]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      width: "250px",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => {
        return (
          <div
            className="table-action"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: "/profile",
                state: { id: record.clientId },
              });
            }}
          >
            <span>{record.firstName}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "300px",
    },
    {
      title: "DOB",
      key: "DOB",
      render: (text, record) => {
        return (
          <div className="table-action">
            <span>
              {record && record.dateOfBirth === "1900-01-01T00:00:00+00:00"
                ? ""
                : record && yyyymmddFormate(record.dateOfBirth)}
            </span>
          </div>
        );
      },
    },

    {
      title: "Country",
      dataIndex: "visaCountryName",
      key: "visaCountryName",
      render: (text, record) => {
        const arr = record.visaCountryName.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const strVisaCountryName = arr.join(" ");
        return <Link className={"view-country"}>{strVisaCountryName}</Link>;
      },
    },
  ];

  return (
    <Fragment>
      {/* <Spin size="large" spinning={loading}> */}

      <div className="single-job">
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          style={{ marginTop: "10px" }}
          className="job-table table-head employer-tables border-3"
          dataSource={empClientEmpRes && empClientEmpRes.items}
          columns={columns}
          pagination={false}
        />
      </div>
      {/* </Spin> */}
    </Fragment>
  );
};
export default EmployerClient;
