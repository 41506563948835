import React, { Fragment, useState, useEffect } from "react";
import { yyyymmddFormate } from "./../../../../../Common/reUseFunction";
import { Table } from "antd";
import { apiRefresh } from "../../../../../services/api";
import moment from "moment";

const JobCheckReport = ({ userDataEmp, jobUpdate, tabKey }) => {
  const [jobCheckList, setJobCheckList] = useState([]);
  useEffect(() => {
    if (userDataEmp) {
      let dataFilter = {
        subjectId: userDataEmp && userDataEmp.id,
        caseStatusId: 0,
        visaTypeId: 10100,
        jobCheckId: 0,
      };
      const getEmpCaseOpt = {
        url: `v1/employerCase/GetReport`,
      };

      getEmpCaseOpt.types = [
        "GET_EMPLOYER_CASE_REPORT_SUCCESS",
        "GET_EMPLOYER_CASE_REPORT_FAILURE",
      ];

      apiRefresh.post(getEmpCaseOpt, dataFilter).then((res) => {
        setJobCheckList(res);
      });
    }
  }, [userDataEmp, jobUpdate]);

  useEffect(() => {
    let dataFilter = {
      subjectId: userDataEmp && userDataEmp.id,
      caseStatusId: 0,
      visaTypeId: 10100,
      jobCheckId: 0,
    };
    if (tabKey == "1") {
      const getEmpCaseOpt = {
        url: `v1/employerCase/GetReport`,
      };

      getEmpCaseOpt.types = [
        "GET_EMPLOYER_CASE_REPORT_SUCCESS",
        "GET_EMPLOYER_CASE_REPORT_FAILURE",
      ];

      apiRefresh.post(getEmpCaseOpt, dataFilter).then((res) => {
        setJobCheckList(res);
      });
    }
  }, [tabKey]);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "9.0909%",
    },
    {
      title: "Job check Case name",
      dataIndex: "jobTitle",
      key: "jobTitle",
      width: "9.0909%",
    },
    {
      title: "Expiry",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: "9.0909%",
      render: (text, record) => (
        <span>
          {moment(text).format("DD/MM/YYYY") != "01/01/1900"
            ? moment(text).format("DD/MM/YYYY")
            : ""}
        </span>
      ),
    },
    {
      title: "Experience Required",
      dataIndex: "experinceRequired",
      key: "experinceRequired",
      width: "9.0909%",
    },
    {
      title: "Advertising Expiry",
      dataIndex: "advertisingExpiry",
      key: "advertisingExpiry",
      width: "9.0909%",
      render: (text, record) => (
        <span>
          {moment(text).format("DD/MM/YYYY") != "01/01/1900"
            ? moment(text).format("DD/MM/YYYY")
            : ""}
        </span>
      ),
    },
    {
      title: "LIA",
      dataIndex: "liaName",
      key: "liaName",
      width: "9.0909%",
    },
  ];

  return (
    <Fragment>
      <div className="d-end space-between" style={{ marginTop: 25 }}>
        <div style={{ marginBottom: "7px" }} className="contact-Head">
          <div className="d-flex align-item ">
            <h4
              // style={{ color: "#5b6875", marginTop: "10px" }}
              className="top-text heading-forms"
              style={{
                marginLeft: "1px",
                fontWeight: 600,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              JobCheck Report
            </h4>
          </div>
        </div>
      </div>
      <div>
        <Table
          pagination={false}
          className="job-table table-head employer-tables border-3 school-table"
          dataSource={jobCheckList}
          columns={columns}
          scroll={{ x: "auto" }}
        />
      </div>
    </Fragment>
  );
};

export default JobCheckReport;
