import React from "react";
// import "./ClientsStyles.css";

import { Link } from "react-router-dom";
import { Images } from "../../../../Themes";
import HeaderBarTabs from "../../../../Components/Header/HeaderTabs";

import headOption from "../../../../Components/Header/HeaderTabOptions";

import { DownOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";

import {
  Select,
  Checkbox,
  Form,
  Input,
  Button,
  DatePicker,
  message,
  Spin,
  Modal,
} from "antd";

import moment from "moment";
import StartEmployerVisa from "./StartEmployerVisa";
import UpdateCaseStatus from "./UpdateCaseStatus";
// import { setActiveKey } from "../store/Actions";

const { TextArea } = Input;

const { Option } = Select;

var users = [];

const dateFormat = "DD/MM/YYYY";

const typeArray = [
  { id: 1, name: "Standard" },
  { id: 2, name: "High-Volume" },
  { id: 3, name: "Franchise" },
  { id: 4, name: "Third-Party" },
];

class EmployerCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "document",
      extend: false,
      activeTabNewVisa: false,
      showAppDates: false,
      linkFamily: false,
      checked: false,
      showDocuments: false,
      showEmail: false,
      showVisaInfo: false,
      visaInfoData: null,
      loadVisaInfo: false,
      expendedView: true,
      newApplicationWith: null,
      checkIndex: null,
      headerOptions: [],

      eoiitaDate: "",
      eoiSelectedDate: "",

      profileData: null,

      visaDelModal: false,
      caseHistoryDelModal: false,
      visaObj: null,
      visaStatusId: "",
      showPPIDetail: false,
      caseInd: null,
      showFileNotes: false,
      startEmployerVisaModal: false,
      updateEmployerVisaModal: false,
      loadCase: true,
      accType: null,
    };

    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let data = {
      clientId: clientProfileIdMain,
      familyId: familyId,
    };

    this.props
      .onGetEmployerCase(props.userDataEmp && props.userDataEmp.id)
      .then((res) => {
        this.setState({ loadCase: false });
      })
      .catch((err) => {
        this.setState({ loadCase: false });
      });
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    this.props.onGetVisaStatus();
    // this.props.onGetClientDocument();
    // this.props.onGetPartner();
  }

  formRef = React.createRef();

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    var data = JSON.parse(sessionStorage.getItem("profileData"));
    this.setState({ profileData: data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabKey != this.props.tabKey && this.props.tabKey == "5") {
      let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
      let familyId = sessionStorage.getItem("familyId");
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };

      this.props
        .onGetEmployerCase(this.props.userDataEmp && this.props.userDataEmp.id)
        .then((res) => {
          this.setState({ loadCase: false });
        })
        .catch((err) => {
          this.setState({ loadCase: false });
        });
      this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
      this.props.onGetVisaStatus();
    }

    if (prevProps.employerCaseRes !== this.props.employerCaseRes) {
    }
    if (this.props.teamMembers && this.props.teamMembers.users) {
      users = [];
      for (var i = 0; i < this.props.teamMembers.users.length; i++) {
        users.push(
          <Option key={this.props.teamMembers.users[i].id}>
            {this.props.teamMembers.users[i].fullName}
          </Option>
        );
      }
    }
    if (this.state.initializeValues) {
      this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        studentVisa: this.state.visaInfoData.branchVisaTypeName,
        visaDescription: this.state.visaInfoData.visaDescription,
        submissionType: this.state.visaInfoData.submissionType,
        liaName: this.state.visaInfoData.liaName,
        lia: this.state.visaInfoData.liaName,
        visaAppNumber: this.state.visaInfoData.visaApplicationNumber,
        caseOfficerEmail: this.state.visaInfoData.caseOfficerName,
        branch: this.state.visaInfoData.branch,
        courierName: this.state.visaInfoData.courierName,
        trackingNumber: this.state.visaInfoData.trackingNumber,
        eoiNumber: this.state.visaInfoData.eioNumber,
        returnedDate: moment(this.state.visaInfoData.documentReturnedDate),
        documentDescription: this.state.visaInfoData.documentDescription,
        worth: this.state.visaInfoData.worth,
        saleDate: moment(this.state.visaInfoData.saleDate),
        salesPerson:
          this.state.visaInfoData.salePersonId ===
          "00000000-0000-0000-0000-000000000000"
            ? ""
            : this.state.visaInfoData.salePersonId,
        followupDate: moment(this.state.visaInfoData.followUpDate),
      });
      this.setState({ initializeValues: false });
    }
  }

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeInfoTab: value });
  };

  onChangeTopTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onClickStatus = (visaApp) => {
    this.setState({
      showVisaInfo: true,
      expendedView: false,
      visaInfoData: visaApp,
      initializeValues: true,
    });
    if (visaApp) {
      // this.formRef.current.setFieldsValue({
      //   studentVisa: visaApp.branchVisaTypeName,
      //   visaDescription: visaApp.visaDescription,
      //   submissionType: visaApp.submissionType,
      //   liaName: visaApp.liaName,
      //   lia: visaApp.liaName,
      //   visaAppNumber: visaApp.visaApplicationNumber,
      //   caseOfficerEmail: visaApp.caseOfficerName,
      //   branch: visaApp.branch,
      //   courierName: visaApp.courierName,
      //   trackingNumber: visaApp.trackingNumber,
      //   eoiNumber: visaApp.eioNumber,
      //   returnedDate: moment(visaApp.documentReturnedDate),
      //   documentDescription: visaApp.documentDescription,
      //   worth: visaApp.worth,
      //   saleDate: moment(visaApp.saleDate),
      //   salesPerson:
      //     visaApp.salePersonId === "00000000-0000-0000-0000-000000000000"
      //       ? ""
      //       : visaApp.salePersonId,
      //   followupDate: moment(visaApp.followUpDate),
      // });
    }
  };

  removeStatus = (caseHistoryId) => {
    var userId = localStorage.getItem("userId");
    let data = {
      id: caseHistoryId,
      delete: true,
    };
    this.setState({ loadVisaInfo: true });
    this.props
      .onRemoveCaseStatus(data)
      .then((res) => {
        message.success("Case status remove successfully");

        this.props
          .onGetEmployerCase(this.props.userDataEmp.id)
          .then((resp) => {
            this.setState({ loadVisaInfo: false, caseHistoryDelModal: false });
          })
          .catch((error) => {
            this.setState({ loadVisaInfo: false });
          });
        this.setState({ caseHistoryDelModal: false });
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  removeSubject = (subjectCase) => {
    var userId = localStorage.getItem("userId");
    let data = {
      id: subjectCase.id,
      delete: true,
    };
    this.setState({ loadVisaInfo: true });
    var visaStatus =
      this.props.visaStatusData &&
      this.props.visaStatusData.items.find(
        (obj) => obj.id == subjectCase.caseStatusId
      );
    var statusName = "";
    if (visaStatus) {
      statusName = visaStatus.name;
    }
    this.props
      .onRemoveSubjectCase(data)
      .then((res) => {
        message.success("Visa application remove successfully");
        this.setState({ visaDelModal: false });
        this.props
          .onGetEmployerCase(this.props.userDataEmp.id)
          .then((resp) => {
            this.setState({ loadVisaInfo: false });
          })
          .catch((error) => {
            this.setState({ loadVisaInfo: false });
          });
        this.setState({ visaDelModal: false });
      })
      .catch((err) => {
        this.setState({ loadVisaInfo: false });
      });
  };

  showRightMenu = () => {
    this.setState({ expendedView: false });
  };

  getCaseDocuments = (caseId) => {
    this.setState({
      linkFamily: false,
      showDocuments: true,
      showEmail: false,
      showAppDates: false,
      expendedView: true,
    });
    this.props.onGetCaseDocument(caseId);
  };

  startNewApp = (country) => {
    this.setState({
      activeTabNewVisa: true,
      newApplicationWith: country,
      startEmployerVisaModal: true,
    });
    // if (country === "nz") {
    //   this.props.onGetVisaTypeByCountry("168");
    // }
    // else if (country === "au") {
    //   this.props.onGetVisaTypeByCountry("28");
    // } else {
    //   this.props.onGetVisaTypeByCountry("53");
    // }
  };

  onChangeEoiItaDate = (value) => {
    this.setState({ eoiItaDate: value });
  };

  deleteFromObject = (keyPart, obj) => {
    for (var k in obj) {
      // Loop through the object
      if (~k.indexOf(keyPart)) {
        // If the current key contains the string we're looking for
        delete obj[k]; // Delete obj[key];
      }
    }
    return obj;
  };

  isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      var result = {};
      var keys = Object.keys(bProps);

      for (var key in aProps) {
        if (!keys.includes(key)) {
          result[key] = aProps[key];
        }
      }
      var keyNames = Object.keys(result);
      // for (var i = 0; i < keyNames.length; i++) {
      //   var name = keyNames[i];
      //   delete aProps.keyNames[i];
      // }

      var myObject = [];

      for (var i = 0; i < keyNames.length; i++) {
        myObject = this.deleteFromObject(keyNames[i], aProps);
      }

      console.log(aProps, myObject);
      // return false;
    }
    var propNames = "";

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (
        propName !== "createdDate" &&
        propName !== "modifiedDate" &&
        propName !== "createdBy" &&
        propName !== "modifiedBy" &&
        propName !== "caseHistory" &&
        propName !== "caseLink"
      ) {
        if (a[propName] !== b[propName]) {
          if (propNames === "") {
            propNames += propName;
          } else {
            propNames += ", " + propName;
          }
        }
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return propNames;
  };

  onDownloadDoc = (value) => {
    // setLoading(true);
    this.props
      .onGetDocumentDownload(value)
      .then((res) => {
        console.log("document res", res.payload);
        this.downlodDocument(res.payload, value);
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.title}.${value.docuementExtension}`);
  };

  onDocumentPdf = (value) => {
    // setLoading(true);
    this.props
      .onGetDocumentDownload(value)
      .then((res) => {
        // setLoading(false);

        if (
          value.docuementExtension == "pdf" ||
          value.docuementExtension == "png" ||
          value.docuementExtension == "jpg" ||
          value.docuementExtension == "jpeg" ||
          value.docuementExtension == "gif" ||
          value.docuementExtension == "svg"
        ) {
          if (value.docuementExtension == "pdf") {
            const file = new Blob([res.payload], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          } else {
            const blob = new Blob([res.payload], { type: "image/png" });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");
          }
        } else {
          let blob = new Blob([res.payload], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${value.title}.${value.docuementExtension}`);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  onClose = () => {
    this.setState({ startEmployerVisaModal: false });
  };

  onCloseUpdateModal = () => {
    this.setState({ updateEmployerVisaModal: false });
  };

  render() {
    const {
      selectedOption,
      activeTabNewVisa,
      showAppDates,
      linkFamily,
      showDocuments,
      showVisaInfo,
      expendedView,
      newApplicationWith,
      checkIndex,
      headerOptions,
      eoiItaDate,
      eoiSelectedDate,
      profileData,
      visaDelModal,
      caseHistoryDelModal,
      loadVisaInfo,
      visaObj,
      visaStatusId,
      showPPIDetail,
      caseInd,
      showFileNotes,
      startEmployerVisaModal,
      updateEmployerVisaModal,
      loadCase,
    } = this.state;
    const {
      employerCaseRes,
      caseDocumentData,
      onUpdateCaseHistory,
      visaFileNotesRes,
      onStartNewApplication,
      visaTypeData,
      onGetEmployerCase,
      userDataEmp,
      visaStatusData,
      onUpdateCaseStatus,
      onGetEmployerJob,
      employerJobRes,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadCase}>
          <div style={{ display: "block" }}>
            <h4
              className="top-text heading-forms"
              style={{
                marginLeft: "40px !important",
                fontWeight: 600,
                fontSize: "14px",
                marginTop: "19px",
                fontFamily: "Poppins",
              }}
            >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
            <div className="page-container">
              {/* {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="Visas" />
              )} */}

              <div
                className="cv-main-container"
                style={{ display: "flex", margin: 10, padding: 0 }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="cv-left-container"
                    style={{ width: "100%", marginTop: 1 }}
                  >
                    <div className="cv-top-btn-row">
                      <div
                        className="cv-btn-blue ec-new-case-btn"
                        onClick={() => {
                          this.startNewApp("nz");
                        }}
                      >
                        <div>
                          <span className="cv-top-lbtn-text">
                            Start New Application
                          </span>
                        </div>
                        <div>
                          <span className="cv-top-rbtn-text">Preparing</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="cv-top-btn-row">
                      <div
                        className="cv-btn-blue ec-new-case-btn"
                        style={{ padding: 21 }}
                        onClick={() => {
                          this.setState({ updateEmployerVisaModal: true });
                        }}
                      >
                        <div>
                          <span className="cv-top-lbtn-text">Update</span>
                        </div>
                      </div>
                    </div> */}

                    <Modal
                      title="Start New Visa"
                      style={{ top: 20 }}
                      visible={startEmployerVisaModal}
                      onCancel={() =>
                        this.setState({ startEmployerVisaModal: false })
                      }
                      Header={false}
                      footer={false}
                    >
                      <StartEmployerVisa
                        onStartNewApplication={onStartNewApplication}
                        onGetEmployerCase={onGetEmployerCase}
                        visaTypeData={visaTypeData}
                        onCloseModal={this.onClose}
                        userDataEmp={userDataEmp}
                        onGetEmployerJob={onGetEmployerJob}
                        employerJobRes={employerJobRes}
                      />
                    </Modal>

                    <Modal
                      title="Update Visa"
                      style={{ top: 20 }}
                      visible={updateEmployerVisaModal}
                      onCancel={() =>
                        this.setState({ updateEmployerVisaModal: false })
                      }
                      Header={false}
                      footer={false}
                    >
                      <>
                        {employerCaseRes &&
                          employerCaseRes.items.map((visa, index) => {
                            return (
                              <UpdateCaseStatus
                                onUpdateCaseStatus={onUpdateCaseStatus}
                                onGetEmployerCase={onGetEmployerCase}
                                visaStatusData={visaStatusData}
                                onCloseModal={this.onCloseUpdateModal}
                                userDataEmp={userDataEmp}
                                visa={visa}
                                index={index}
                              />
                            );
                          })}
                      </>
                    </Modal>

                    {/* ===== Design from Visa Flow Screen ===== */}

                    {true && (
                      <div>
                        <Modal
                          title={false}
                          style={{ top: 20 }}
                          visible={visaDelModal}
                          onOk={() => this.removeSubject(visaObj)}
                          onCancel={() =>
                            this.setState({ visaDelModal: false })
                          }
                          Header={false}
                        >
                          <h4>Are you sure you want to delete this ?</h4>
                        </Modal>

                        <Modal
                          title={false}
                          style={{ top: 20 }}
                          visible={caseHistoryDelModal}
                          onOk={() => this.removeStatus(visaStatusId)}
                          onCancel={() =>
                            this.setState({ caseHistoryDelModal: false })
                          }
                          Header={false}
                        >
                          <h4>Are you sure you want to delete this ?</h4>
                        </Modal>
                        {employerCaseRes &&
                          employerCaseRes.items.map((visaApp, index) => {
                            if (!this.state.eoiitaDate) {
                              // this.setState({
                              //   eoiItaDate: moment(visaApp.eoiitaDate),
                              //   eoiSelectedDate: moment(visaApp.eoiSelectedDate),
                              // });
                            }

                            if (visaApp.typeId && visaApp.typeId !== 0) {
                              var type = typeArray.find(
                                (obj) => obj.id == visaApp.typeId
                              );
                              // if (type) {
                              //   this.setState({ accType: type });
                              // }
                            }

                            return (
                              <div key={index}>
                                {/* <VisaCard
                              visaApp={visaApp}
                              onRemoveSubjectCase={onRemoveSubjectCase}
                              onGetEmployerCase={onGetEmployerCase}
                            /> */}
                                <div
                                  className="cv-gray-cont"
                                  style={{
                                    paddingBottom:
                                      showAppDates ||
                                      showDocuments ||
                                      linkFamily
                                        ? 0
                                        : 20,
                                  }}
                                >
                                  <div className="cv-row">
                                    <div style={{ paddingRight: 10 }}>
                                      <div style={{ display: "flex" }}>
                                        {visaApp.branchVisaTypeName && (
                                          <span className="cv-bold-text">
                                            {visaApp.branchVisaTypeName.toUpperCase()}
                                          </span>
                                        )}
                                        {type && (
                                          <div className="cv-green-box">
                                            <span className="cv-green-text">
                                              {type.name}
                                            </span>
                                          </div>
                                        )}
                                        {visaApp.caseTitle !== "" && (
                                          <div className="cv-green-box">
                                            <span className="cv-green-text">
                                              {visaApp.caseTitle.toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                        {visaApp.country !== "" && (
                                          <div className="cv-green-box">
                                            <span className="cv-green-text">
                                              {visaApp.country.toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        {visaApp.caseLinks &&
                                          visaApp.caseLinks.items &&
                                          visaApp.caseLinks.items.length > 0 &&
                                          visaApp.caseLinks.items.map(
                                            (caseLink, linkIndex) => {
                                              return (
                                                <div
                                                  key={linkIndex}
                                                  className="linked-member-cont"
                                                  style={{
                                                    marginLeft:
                                                      linkIndex > 0 ? 5 : 0,
                                                  }}
                                                >
                                                  <div className="button-first-second-row">
                                                    <span
                                                      className="linked-member-text"
                                                      style={{
                                                        color: "#555555",
                                                      }}
                                                    >
                                                      {caseLink.firstName}
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                      {visaApp.visaStatusName ===
                                        "Approved" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                Expiry Date :{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              format={dateFormat}
                                              value={
                                                visaApp.expiryDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(visaApp.expiryDate)
                                                  : ""
                                              }
                                            />
                                          </div>
                                          <div
                                            className="cv-date-row"
                                            style={{ marginTop: 5 }}
                                          >
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                Approved Date :{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              format={dateFormat}
                                              value={
                                                visaApp.approveDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(visaApp.approveDate)
                                                  : ""
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                      {visaApp.visaStatusName === "Refused" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                Refused Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              format={dateFormat}
                                              value={
                                                visaApp.refusedDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(visaApp.refusedDate)
                                                  : ""
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                      {visaApp.visaStatusName ===
                                        "Assessment Completed" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                Assessment Completed Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              format={dateFormat}
                                              value={
                                                visaApp.assessmentCompletedDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(
                                                      visaApp.assessmentCompletedDate
                                                    )
                                                  : ""
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                      {visaApp.visaStatusName ===
                                        "Selected" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                EOI ITA Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              value={
                                                visaApp.eoiitaDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(visaApp.eoiitaDate)
                                                  : ""
                                              }
                                              onChange={this.onChangeEoiItaDate}
                                              format={dateFormat}
                                            />
                                          </div>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                EOI Selected Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              value={
                                                visaApp.eoiSelectedDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(
                                                      visaApp.eoiSelectedDate
                                                    )
                                                  : ""
                                              }
                                              onChange={
                                                this.onChangeEoiSelectedDate
                                              }
                                              format={dateFormat}
                                            />
                                          </div>
                                        </>
                                      )}
                                      {visaApp.visaStatusName ===
                                        "Contract Signed Date" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                Contract Signed Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              value={
                                                visaApp.contractSignedDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(
                                                      visaApp.contractSignedDate
                                                    )
                                                  : ""
                                              }
                                              format={dateFormat}
                                            />
                                          </div>
                                        </>
                                      )}
                                      {visaApp.visaStatusName ===
                                        "Approve in principle" && (
                                        <>
                                          <div className="cv-date-row">
                                            <div style={{ width: 175 }}>
                                              <span className="cv-date-label">
                                                AIP Date:{" "}
                                              </span>
                                            </div>
                                            <DatePicker
                                              value={
                                                visaApp.aipDate !==
                                                "1900-01-01T00:00:00+00:00"
                                                  ? moment(visaApp.aipDate)
                                                  : ""
                                              }
                                              format={dateFormat}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="cv-preparing-box"
                                          style={{
                                            backgroundColor:
                                              visaApp.visaStatusColor,
                                          }}
                                        >
                                          <div
                                            className="cv-imm-cont"
                                            // style={{ cursor: "pointer" }}
                                            // onClick={() =>
                                            //   this.onClickStatus(visaApp)
                                            // }
                                          >
                                            {visaApp.visaStatusName && (
                                              <span className="cv-imm-text">
                                                {visaApp.visaStatusName.toUpperCase()}
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            className="cv-icons-row"
                                            style={{
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.setState({
                                                  showAppDates: true,
                                                  linkFamily: false,
                                                  showDocuments: false,
                                                  showEmail: false,
                                                  expendedView: true,
                                                  checkIndex: index,
                                                  showFileNotes: false,
                                                });
                                              }}
                                            >
                                              <DownOutlined
                                                style={{ color: "#FFFFFF" }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="cv-delete-cont"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            this.setState({
                                              visaDelModal: true,
                                              visaObj: visaApp,
                                            });
                                          }}
                                        >
                                          <img
                                            src={Images.deleteGray}
                                            className="cv-dlt-icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {(showAppDates ||
                                    linkFamily ||
                                    showDocuments ||
                                    showFileNotes) &&
                                    checkIndex === index && (
                                      <div className="cv-show-hide-cont">
                                        <div></div>
                                        <div
                                          className="sus-show-btn"
                                          style={{ marginRight: 30 }}
                                          onClick={() =>
                                            this.setState({
                                              showAppDates: false,
                                              linkFamily: false,
                                              showDocuments: false,
                                              showFileNotes: false,
                                            })
                                          }
                                        >
                                          <span className="sus-save-btn-text">
                                            HIDE
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  {showDocuments && checkIndex === index && (
                                    <div className="cv-gray-cont">
                                      <div className="sus-tab-container">
                                        <div
                                          onClick={() =>
                                            this.onChangeTopTab("document")
                                          }
                                          className={
                                            this.state.activeTab == "document"
                                              ? "cv-active-tab"
                                              : "cv-inactive-tab"
                                          }
                                        >
                                          <span
                                            className={
                                              this.state.activeTab == "document"
                                                ? "cv-active-tab-text"
                                                : "cv-inactive-tab-text"
                                            }
                                          >
                                            DOCUMENT
                                          </span>
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.onChangeTopTab(
                                              "documentChecklist"
                                            )
                                          }
                                          className={
                                            this.state.activeTab ==
                                            "documentChecklist"
                                              ? "cv-active-tab"
                                              : "cv-inactive-tab"
                                          }
                                        >
                                          <span
                                            className={
                                              this.state.activeTab ==
                                              "documentChecklist"
                                                ? "cv-active-tab-text"
                                                : "cv-inactive-tab-text"
                                            }
                                          >
                                            SEND DOCUMENT CHECKLIST
                                          </span>
                                        </div>
                                      </div>

                                      <div className="cv-white-cont">
                                        <div
                                          className="cv-row"
                                          style={{ marginBottom: 10 }}
                                        >
                                          <div />
                                          <div style={{ display: "flex" }}>
                                            <div className="cv-print-icon-cont">
                                              <img
                                                src={Images.printBlue}
                                                className="profile-print-icon"
                                              />
                                            </div>
                                            <div className="cv-extend-icon-cont">
                                              <img
                                                src={Images.extendIcon}
                                                className="cv-extend-icon"
                                                style={{
                                                  transform: `rotate(270deg)`,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div />
                                          <div
                                            style={{
                                              display: "flex",
                                              marginBottom: 10,
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <span
                                                className="cv-bold-text"
                                                style={{ color: "#75868F" }}
                                              >
                                                Visible To:
                                              </span>
                                            </div>
                                            {/*<div className="cv-circle-lable-cont">*/}
                                            {/*  <div className="cv-client-circle"></div>*/}
                                            {/*  <span*/}
                                            {/*    className="cv-normal-text"*/}
                                            {/*    style={{ marginLeft: 5 }}*/}
                                            {/*  >*/}
                                            {/*    Client*/}
                                            {/*  </span>*/}
                                            {/*</div>*/}
                                            {/*<div className="cv-circle-lable-cont">*/}
                                            {/*  <div className="cv-agent-circle"></div>*/}
                                            {/*  <span*/}
                                            {/*    className="cv-normal-text"*/}
                                            {/*    style={{ marginLeft: 5 }}*/}
                                            {/*  >*/}
                                            {/*    Agent*/}
                                            {/*  </span>*/}
                                            {/*</div>*/}
                                            <div className="cv-circle-lable-cont">
                                              <div className="cv-both-circle"></div>
                                              <span
                                                className="cv-normal-text"
                                                style={{ marginLeft: 5 }}
                                              >
                                                Both
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="cv-doc-head-row">
                                          <div
                                            className="cv-width-55"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div>
                                              <input
                                                type="checkbox"
                                                className="sus-checkbox"
                                                onChange={this.handleCheck}
                                                defaultChecked={
                                                  this.state.checked
                                                }
                                              />
                                            </div>
                                            <div className="cv-doc-inner-index">
                                              <span className="cv-doc-head-text">
                                                #
                                              </span>
                                            </div>
                                            <div className="cv-doc-width">
                                              <span className="cv-doc-head-text">
                                                Document
                                              </span>
                                            </div>
                                          </div>
                                          <div className="cv-width-17">
                                            <span className="cv-doc-head-text">
                                              Title
                                            </span>
                                          </div>
                                          <div className="cv-width-13">
                                            <span className="cv-doc-head-text">
                                              Type
                                            </span>
                                          </div>
                                          <div className="cv-width-15">
                                            <span className="cv-doc-head-text">
                                              Action
                                            </span>
                                          </div>
                                        </div>

                                        {caseDocumentData &&
                                          caseDocumentData.items.map(
                                            (document, index) => {
                                              return (
                                                <div className="cv-doc-row">
                                                  <div
                                                    className="cv-width-52"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        className="sus-checkbox"
                                                        onChange={
                                                          this.handleCheck
                                                        }
                                                        defaultChecked={
                                                          this.state.checked
                                                        }
                                                      />
                                                    </div>
                                                    <div className="cv-doc-inner-index">
                                                      <span
                                                        className="cv-doc-text"
                                                        style={{
                                                          color: "#5B5B5B",
                                                        }}
                                                      >
                                                        {document.id}
                                                      </span>
                                                    </div>
                                                    <div className="cv-doc-width">
                                                      <div>
                                                        <span
                                                          className="cv-doc-text"
                                                          style={{
                                                            color: "#1081B8",
                                                          }}
                                                        >
                                                          {document.title}
                                                        </span>
                                                      </div>
                                                      <div className="cv-doc-date-text-cont">
                                                        <span className="cv-doc-date-text"></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="cv-width-20"
                                                    style={{ padding: 1 }}
                                                  >
                                                    <div className="cv-title-box">
                                                      <span
                                                        className="cv-normal-text"
                                                        style={{
                                                          fontSize: 9,
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        {document.blobFileName}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="cv-width-13"
                                                    style={{ padding: 1 }}
                                                  >
                                                    <div
                                                      className="cv-title-box"
                                                      style={{ width: "80%" }}
                                                    >
                                                      <span
                                                        className="cv-normal-text"
                                                        style={{
                                                          fontSize: 9,
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        show data
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="cv-width-15"
                                                    style={{ display: "block" }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          this.onDownloadDoc(
                                                            document
                                                          )
                                                        }
                                                        className="cv-action-icons-border"
                                                      >
                                                        <img
                                                          src={Images.download}
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                      <div
                                                        className="cv-action-icons-border"
                                                        onClick={() =>
                                                          this.onDocumentPdf(
                                                            document
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: 5,
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            Images.visibility
                                                          }
                                                          className="cv-action-icon"
                                                        />
                                                      </div>
                                                      {false && (
                                                        <div
                                                          className="cv-action-icons-border"
                                                          style={{
                                                            marginLeft: 5,
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              Images.download
                                                            }
                                                            className="cv-action-icon"
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                    {false && (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          marginTop: 3,
                                                        }}
                                                      >
                                                        <div className="cv-action-icons-border">
                                                          <img
                                                            src={
                                                              Images.fileNotes
                                                            }
                                                            className="cv-action-icon"
                                                          />
                                                        </div>
                                                        <div
                                                          className="cv-action-icons-border"
                                                          style={{
                                                            marginLeft: 5,
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              Images.multimediaBlue
                                                            }
                                                            className="cv-action-icon"
                                                          />
                                                        </div>
                                                        <div
                                                          className="cv-action-icons-border"
                                                          style={{
                                                            marginLeft: 5,
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              Images.deleteIcon
                                                            }
                                                            className="cv-action-icon"
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  )}
                                  {showFileNotes && checkIndex === index && (
                                    <div
                                      className="file-notes-content"
                                      style={{ marginTop: 20 }}
                                    >
                                      {visaFileNotesRes &&
                                        visaFileNotesRes.items.map(
                                          (fileNote, index) => {
                                            var loginUserName = localStorage.getItem(
                                              "userName"
                                            );
                                            return (
                                              <div>
                                                <div
                                                  key={index}
                                                  className="file-content-index-cont"
                                                  style={{
                                                    paddingTop: 8,
                                                    paddingBottom: 8,
                                                    marginBottom: 20,
                                                  }}
                                                >
                                                  <div className="file-content-index-row">
                                                    <div>
                                                      <div>
                                                        <span className="date-time">
                                                          {moment(
                                                            fileNote.modifiedDate
                                                          ).format(
                                                            "DD MMM YYYY hh:mm A"
                                                          ) + " "}
                                                        </span>
                                                        <span className="title">
                                                          {loginUserName}
                                                        </span>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <p className="file-content">
                                                          For VisaCitizenship
                                                          :&nbsp;
                                                        </p>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              fileNote.fileNote,
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="delete-cont">
                                                      {/* <div
                                                      className="delete-icon"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.setState({
                                                          showOnEdit: true,
                                                          showIndex: index,
                                                          editFileNotesString:
                                                            fileNote.fileNote,
                                                        });
                                                      }}
                                                    >
                                                      <img
                                                        src={Images.notesBlue}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                      {/* <div className="delete-icon">
                                                      <img
                                                        src={Images.blueAdd}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                      {/* <div
                                                      className="delete-icon"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.onRemoveFileNote(
                                                          fileNote.id
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={Images.deleteIcon}
                                                        style={{
                                                          width: 15,
                                                          height: 15,
                                                        }}
                                                      />
                                                    </div> */}
                                                    </div>
                                                  </div>
                                                  {false && (
                                                    <div>
                                                      <div
                                                        style={{
                                                          marginTop: 20,
                                                          marginBottom: 20,
                                                        }}
                                                      >
                                                        {/* <FroalaEditor
                                                          ref={(el) => {
                                                            config = el;
                                                          }}
                                                          config={config}
                                                          tag="textarea"
                                                          model={
                                                            editFileNotesString
                                                          }
                                                          onModelChange={(e) =>
                                                            this.setState({
                                                              editFileNotesString: e,
                                                            })
                                                          }
                                                        /> */}
                                                      </div>
                                                      <div
                                                        className="button-container-cont"
                                                        style={{
                                                          paddingLeft: 0,
                                                          paddingTop: 0,
                                                        }}
                                                      >
                                                        <div
                                                          onClick={() =>
                                                            this.updFileNote(
                                                              fileNote.id
                                                            )
                                                          }
                                                          className="add-file-note-btn margin-top-12"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color: "#FFFFFF",
                                                              fontSize: 10,
                                                            }}
                                                          >
                                                            SAVE
                                                          </span>
                                                        </div>
                                                        <div
                                                          onClick={
                                                            this.handleCancel
                                                          }
                                                          className="add-file-note-btn margin-top-12"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginLeft: 20,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color: "#FFFFFF",
                                                              fontSize: 10,
                                                            }}
                                                          >
                                                            CANCEL
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>

                                <div className="cv-ass-rows-cont">
                                  {showAppDates &&
                                    checkIndex === index &&
                                    visaApp.caseHistory.map(
                                      (caseHistory, caseIndex) => {
                                        return (
                                          <div key={caseIndex}>
                                            <div
                                              className="cv-row"
                                              style={{
                                                marginTop: 10,
                                                alignItems: "center",
                                              }}
                                            >
                                              <div className="cv-ass-row">
                                                <div
                                                  className="cv-blue-box"
                                                  style={{
                                                    backgroundColor:
                                                      caseHistory.color,
                                                  }}
                                                ></div>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <span className="cv-normal-text">
                                                    {caseHistory.caseStatusName}
                                                  </span>
                                                </div>
                                                {/* disable detail button */}
                                                {caseHistory.caseStatusId ===
                                                  32 && (
                                                  <div
                                                    className="cv-org-cont"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.setState({
                                                        showPPIDetail: true,
                                                        caseInd: caseIndex,
                                                      })
                                                    }
                                                  >
                                                    <span className="cv-org-text">
                                                      DETAILS
                                                    </span>
                                                  </div>
                                                )}
                                              </div>

                                              <div className="cv-row">
                                                <DatePicker
                                                  value={moment(
                                                    caseHistory.startDate
                                                  )}
                                                  format={dateFormat}
                                                  onChange={(
                                                    date,
                                                    dateString
                                                  ) => {
                                                    let data = {
                                                      id: caseHistory.id,
                                                      startDate: date,
                                                    };
                                                    this.setState({
                                                      loadVisaInfo: true,
                                                    });
                                                    onUpdateCaseHistory(data)
                                                      .then((res) => {
                                                        this.props
                                                          .onGetEmployerCase(
                                                            this.props
                                                              .userDataEmp.id
                                                          )
                                                          .then((resp) => {
                                                            this.setState({
                                                              loadVisaInfo: false,
                                                            });
                                                          })
                                                          .catch((err) => {
                                                            this.setState({
                                                              loadVisaInfo: false,
                                                            });
                                                          });
                                                      })
                                                      .catch((err) => {
                                                        this.setState({
                                                          loadVisaInfo: false,
                                                        });
                                                      });
                                                  }}
                                                />
                                                <span className="cv-normal-text"></span>
                                                <div
                                                  className="cv-cross-cont"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    this.setState({
                                                      caseHistoryDelModal: true,
                                                      visaStatusId:
                                                        caseHistory.id,
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    src={Images.crossWhite}
                                                    className="cv-svg-8"
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            {showPPIDetail &&
                                              caseIndex === caseInd && (
                                                <div
                                                  className="cv-show-hide-cont"
                                                  style={{ border: 0 }}
                                                >
                                                  <div></div>
                                                  <div
                                                    className="sus-show-btn"
                                                    style={{
                                                      marginRight: 110,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.setState({
                                                        showPPIDetail: false,
                                                        caseInd: null,
                                                      })
                                                    }
                                                  >
                                                    <span
                                                      className="sus-save-btn-text"
                                                      style={{
                                                        backgroundColor:
                                                          "#f5f5f5",
                                                      }}
                                                    >
                                                      HIDE
                                                    </span>
                                                  </div>
                                                </div>
                                              )}

                                            {showPPIDetail &&
                                              caseIndex === caseInd && (
                                                <div className="cv-detail-ppi-date">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p className="medical-label">
                                                      Description
                                                    </p>
                                                    <div
                                                      style={{
                                                        width: "48%",
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      <div>
                                                        <Form.Item>
                                                          <Input
                                                            value={
                                                              caseHistory.description
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p className="medical-label">
                                                      Received Date
                                                    </p>
                                                    <div
                                                      style={{
                                                        width: "48%",
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      <div>
                                                        <Form.Item>
                                                          <DatePicker
                                                            format={dateFormat}
                                                            value={
                                                              caseHistory.receivedDate ===
                                                              "1900-01-01T00:00:00+00:00"
                                                                ? ""
                                                                : caseHistory.receivedDate
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p className="medical-label">
                                                      Due Date
                                                    </p>
                                                    <div
                                                      style={{
                                                        width: "48%",
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      <div>
                                                        <Form.Item>
                                                          <DatePicker
                                                            format={dateFormat}
                                                            value={
                                                              caseHistory.dueDate ===
                                                              "1900-01-01T00:00:00+00:00"
                                                                ? ""
                                                                : caseHistory.dueDate
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p className="medical-label">
                                                      Extend Due Date
                                                    </p>
                                                    <div
                                                      style={{
                                                        width: "48%",
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      <div>
                                                        <Form.Item>
                                                          <DatePicker
                                                            format={dateFormat}
                                                            value={
                                                              caseHistory.extendDueDate ===
                                                              "1900-01-01T00:00:00+00:00"
                                                                ? ""
                                                                : caseHistory.extendDueDate
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      marginTop: 10,
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <p className="medical-label">
                                                      Submitted Date
                                                    </p>
                                                    <div
                                                      style={{
                                                        width: "48%",
                                                        paddingLeft: 1,
                                                      }}
                                                    >
                                                      <div>
                                                        <Form.Item>
                                                          <DatePicker
                                                            format={dateFormat}
                                                            value={
                                                              caseHistory.submittedDate ===
                                                              "1900-01-01T00:00:00+00:00"
                                                                ? ""
                                                                : caseHistory.submittedDate
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {/* ===== Design from Visa Document Screen ===== */}

                    {false && (
                      <div>
                        {false && (
                          <div className="cv-row">
                            <div style={{ margin: 10 }}>
                              <div className="cv-assist-cont">
                                <span className="cv-bold-text">
                                  Investor Plus (Investor 1 Category)
                                </span>
                                <div className="cv-green-box">
                                  <span className="cv-green-text">
                                    NEW ZELAND
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="cv-gray-cont">
                          {false && (
                            <div className="cv-row">
                              <div style={{ width: "50%" }}>
                                <div>
                                  <p style={{ marginTop: 0, marginLeft: 5 }}>
                                    Expiry Date
                                  </p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={this.onChangeDate}
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p style={{ marginLeft: 5 }}>Approved Date</p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={this.onChangeDate}
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", marginTop: 20 }}>
                                <div
                                  className="cv-purpel-box"
                                  style={{
                                    height: 50,
                                    backgroundColor: "#45BE57",
                                  }}
                                >
                                  <div className="cv-imm-cont">
                                    <span className="cv-imm-text">
                                      APPROVED
                                    </span>
                                  </div>
                                  <div className="cv-icons-row">
                                    <div>
                                      <img
                                        src={Images.multimedia}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.emailWhite}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteGray}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="sus-tab-container">
                            <div
                              onClick={() => this.onChangeTopTab("document")}
                              className={
                                this.state.activeTab == "document"
                                  ? "cv-active-tab"
                                  : "cv-inactive-tab"
                              }
                            >
                              <span
                                className={
                                  this.state.activeTab == "document"
                                    ? "cv-active-tab-text"
                                    : "cv-inactive-tab-text"
                                }
                              >
                                DOCUMENT
                              </span>
                            </div>
                            <div
                              onClick={() =>
                                this.onChangeTopTab("documentChecklist")
                              }
                              className={
                                this.state.activeTab == "documentChecklist"
                                  ? "cv-active-tab"
                                  : "cv-inactive-tab"
                              }
                            >
                              <span
                                className={
                                  this.state.activeTab == "documentChecklist"
                                    ? "cv-active-tab-text"
                                    : "cv-inactive-tab-text"
                                }
                              >
                                SEND DOCUMENT CHECKLIST
                              </span>
                            </div>
                          </div>

                          <div className="cv-white-cont">
                            <div
                              className="cv-row"
                              style={{ marginBottom: 10 }}
                            >
                              <div />
                              <div style={{ display: "flex" }}>
                                <div className="cv-print-icon-cont">
                                  <img
                                    src={Images.printBlue}
                                    className="profile-print-icon"
                                  />
                                </div>
                                <div className="cv-extend-icon-cont">
                                  <img
                                    src={Images.extendIcon}
                                    className="cv-extend-icon"
                                    style={{ transform: `rotate(270deg)` }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="cv-doc-head-row">
                              <div
                                className="cv-width-55"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    className="sus-checkbox"
                                    onChange={this.handleCheck}
                                    defaultChecked={this.state.checked}
                                  />
                                </div>
                                <div className="cv-doc-inner-index">
                                  <span className="cv-doc-head-text">#</span>
                                </div>
                                <div className="cv-doc-width">
                                  <span className="cv-doc-head-text">
                                    Document
                                  </span>
                                </div>
                              </div>
                              <div className="cv-width-17">
                                <span className="cv-doc-head-text">Title</span>
                              </div>
                              <div className="cv-width-13">
                                <span className="cv-doc-head-text">Type</span>
                              </div>
                              <div className="cv-width-15">
                                <span className="cv-doc-head-text">Action</span>
                              </div>
                            </div>

                            {false && (
                              <div className="cv-doc-row">
                                <div
                                  className="cv-width-52"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sus-checkbox"
                                      onChange={this.handleCheck}
                                      defaultChecked={this.state.checked}
                                    />
                                  </div>
                                  <div className="cv-doc-inner-index">
                                    <span
                                      className="cv-doc-text"
                                      style={{ color: "#5B5B5B" }}
                                    >
                                      1
                                    </span>
                                  </div>
                                  <div className="cv-doc-width">
                                    <div>
                                      <span
                                        className="cv-doc-text"
                                        style={{ color: "#1081B8" }}
                                      >
                                        Merge-66598939340573894-PDF.pdf
                                      </span>
                                    </div>
                                    <div className="cv-doc-date-text-cont">
                                      <span className="cv-doc-date-text">
                                        12/02/2019 | 20 KB
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-20"
                                  style={{ padding: 1 }}
                                >
                                  <div className="cv-title-box">
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontSize: 7, marginLeft: 5 }}
                                    >
                                      show data
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-13"
                                  style={{ padding: 1 }}
                                >
                                  <div
                                    className="cv-title-box"
                                    style={{ width: "80%" }}
                                  >
                                    <span
                                      className="cv-normal-text"
                                      style={{ fontSize: 7, marginLeft: 5 }}
                                    >
                                      show data
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="cv-width-15"
                                  style={{ display: "block" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div className="cv-action-icons-border">
                                      <img
                                        src={Images.download}
                                        className="cv-action-icon"
                                      />
                                    </div>
                                    <div
                                      className="cv-action-icons-border"
                                      style={{ marginLeft: 5 }}
                                    >
                                      <img
                                        src={Images.visibility}
                                        className="cv-action-icon"
                                      />
                                    </div>
                                    {false && (
                                      <div
                                        className="cv-action-icons-border"
                                        style={{ marginLeft: 5 }}
                                      >
                                        <img
                                          src={Images.download}
                                          className="cv-action-icon"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {false && (
                                    <div
                                      style={{ display: "flex", marginTop: 3 }}
                                    >
                                      <div className="cv-action-icons-border">
                                        <img
                                          src={Images.fileNotes}
                                          className="cv-action-icon"
                                        />
                                      </div>
                                      <div
                                        className="cv-action-icons-border"
                                        style={{ marginLeft: 5 }}
                                      >
                                        <img
                                          src={Images.multimediaBlue}
                                          className="cv-action-icon"
                                        />
                                      </div>
                                      <div
                                        className="cv-action-icons-border"
                                        style={{ marginLeft: 5 }}
                                      >
                                        <img
                                          src={Images.deleteIcon}
                                          className="cv-action-icon"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="cv-show-hide-cont">
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>

                        <div className="cv-row">
                          <div style={{ margin: 10 }}>
                            <div className="cv-assist-cont">
                              <span className="cv-bold-text">
                                Family and Spousal Visas
                              </span>
                              <div className="cv-green-box">
                                <span className="cv-green-text">AUSTRALIA</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cv-gray-cont">
                          <div className="cv-row">
                            <div>
                              <div>
                                <span className="cv-normal-text">
                                  Child visa (subclass 02)
                                </span>
                              </div>
                              <div className="cv-assist-cont">
                                <span className="cv-normal-text">
                                  Queensland
                                </span>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="cv-purpel-box"
                                style={{
                                  backgroundColor: "#F8940A",
                                  marginTop: 10,
                                }}
                              >
                                <div className="cv-imm-cont">
                                  <span className="cv-imm-text">
                                    IMMIGRATION MATTERS
                                  </span>
                                </div>
                                <div className="cv-icons-row">
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.emailWhite}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="cv-delete-cont">
                                <img
                                  src={Images.deleteGray}
                                  className="cv-dlt-icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="cv-show-hide-cont"
                            style={{ marginTop: 10 }}
                          >
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ===== Design from Visa file notes Screen ===== */}

                    {false && (
                      <div>
                        <div className="cv-row">
                          <div style={{ margin: 10 }}>
                            <div className="cv-assist-cont">
                              <span className="cv-bold-text">
                                Investor Plus (Investor 1 Category)
                              </span>
                              <div className="cv-green-box">
                                <span className="cv-green-text">
                                  NEW ZELAND
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cv-gray-cont">
                          <div className="cv-row">
                            <div style={{ width: "50%" }}>
                              <div>
                                <p style={{ marginTop: 0, marginLeft: 5 }}>
                                  Expiry Date
                                </p>
                                <div
                                  class="profile-input-border"
                                  style={{
                                    display: "flex",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    overflow: "inherit",
                                  }}
                                >
                                  <div
                                    class="profile-input-with-icon"
                                    style={{
                                      overflow: "inherit",
                                      display: "flex",
                                      height: 30,
                                      paddingRight: 3,
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      paddingRight: 5,
                                      width: "100%",
                                    }}
                                  >
                                    <DatePicker
                                      format={dateFormat}
                                      calendarIcon={null}
                                      clearIcon={null}
                                      onChange={this.onChangeDate}
                                      value={this.state.date}
                                    />
                                    <img
                                      src={Images.calendar}
                                      className="profile-calendar-icon"
                                    />
                                  </div>
                                  <div class="profile-refresh-cont">
                                    <img
                                      src={Images.refreshArrow}
                                      style={{ width: 18, height: 18 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p style={{ marginLeft: 5 }}>Approved Date</p>
                                <div
                                  class="profile-input-border"
                                  style={{
                                    display: "flex",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    overflow: "inherit",
                                  }}
                                >
                                  <div
                                    class="profile-input-with-icon"
                                    style={{
                                      overflow: "inherit",
                                      display: "flex",
                                      height: 30,
                                      paddingRight: 3,
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      paddingRight: 5,
                                      width: "100%",
                                    }}
                                  >
                                    <DatePicker
                                      format={dateFormat}
                                      calendarIcon={null}
                                      clearIcon={null}
                                      onChange={this.onChangeDate}
                                      value={this.state.date}
                                    />
                                    <img
                                      src={Images.calendar}
                                      className="profile-calendar-icon"
                                    />
                                  </div>
                                  <div class="profile-refresh-cont">
                                    <img
                                      src={Images.refreshArrow}
                                      style={{ width: 18, height: 18 }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 20 }}>
                              <div
                                className="cv-purpel-box"
                                style={{
                                  height: 50,
                                  backgroundColor: "#45BE57",
                                }}
                              >
                                <div className="cv-imm-cont">
                                  <span className="cv-imm-text">APPROVED</span>
                                </div>
                                <div className="cv-icons-row">
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.interfaceIcon}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.emailWhite}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.interfaceIcon}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="cv-delete-cont"
                                style={{ marginTop: 0 }}
                              >
                                <img
                                  src={Images.deleteGray}
                                  className="cv-dlt-icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="cv-time-spent-row">
                            <div>
                              <span className="cv-bold-text">
                                File Notes
                                <span className="cv-normal-text">
                                  (Activity log)
                                </span>
                              </span>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div>
                                <p>Time Spent in Min(s)</p>
                                <div class="input-border">
                                  <input
                                    style={{ width: 150 }}
                                    className="input"
                                    placeholder="Search"
                                    type="text"
                                    onChange={this.myChangeHaAcndler}
                                  />
                                </div>
                              </div>
                              <div
                                className="cv-print-icon-cont"
                                style={{ marginTop: 32, marginLeft: 5 }}
                              >
                                <img
                                  src={Images.printBlue}
                                  className="profile-print-icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <textarea
                              value={this.state.value}
                              onChange={this.handleChange}
                              rows={5}
                              class="cv-textarea"
                            />
                          </div>

                          <div style={{ display: "flex" }}>
                            <div className="cv-redish-btn">
                              <span className="cv-redish-btn-text">Save</span>
                            </div>
                          </div>

                          <div className="cv-filenotes-cont">
                            <div className="cv-filenotes-row">
                              <div>
                                <div>
                                  <span className="cv-file-notes-gray-text">
                                    14 Feb 2019 08-40 PM{" "}
                                  </span>
                                  <span
                                    className="file-content"
                                    style={{ fontSize: 10 }}
                                  >
                                    Benedict Thomas
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      color: "#1081B8",
                                      fontSize: 11,
                                      fontWeight: "500",
                                    }}
                                  >
                                    For VisaCitizenship : Test
                                  </span>
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.fileNotes}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteIcon}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteIcon}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="cv-filenotes-cont">
                            <div
                              className="cv-filenotes-row"
                              style={{ backgroundColor: "#FFFFFF" }}
                            >
                              <div>
                                <div>
                                  <span className="cv-file-notes-gray-text">
                                    14 Feb 2019 08-40 PM{" "}
                                  </span>
                                  <span
                                    className="file-content"
                                    style={{ fontSize: 10 }}
                                  >
                                    Benedict Thomas
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      color: "#1081B8",
                                      fontSize: 11,
                                      fontWeight: "500",
                                    }}
                                  >
                                    For VisaCitizenship : Test
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteIcon}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="cv-show-hide-cont">
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>

                        <div className="cv-row">
                          <div style={{ margin: 10 }}>
                            <div className="cv-assist-cont">
                              <span className="cv-bold-text">
                                Family and Spousal Visas
                              </span>
                              <div className="cv-green-box">
                                <span className="cv-green-text">AUSTRALIA</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cv-gray-cont">
                          <div className="cv-row">
                            <div>
                              <div>
                                <span className="cv-normal-text">
                                  Child visa (subclass 02)
                                </span>
                              </div>
                              <div className="cv-assist-cont">
                                <span className="cv-normal-text">
                                  Queensland
                                </span>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="cv-purpel-box"
                                style={{
                                  backgroundColor: "#F8940A",
                                  marginTop: 10,
                                }}
                              >
                                <div className="cv-imm-cont">
                                  <span className="cv-imm-text">
                                    IMMIGRATION MATTERS
                                  </span>
                                </div>
                                <div className="cv-icons-row">
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.emailWhite}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={Images.multimedia}
                                      style={{ width: 15, height: 15 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="cv-delete-cont">
                                <img
                                  src={Images.deleteGray}
                                  className="cv-dlt-icon"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="cv-show-hide-cont"
                            style={{ marginTop: 10 }}
                          >
                            <div></div>
                            <div
                              className="sus-show-btn"
                              style={{ marginRight: 30 }}
                            >
                              <span className="sus-save-btn-text">HIDE</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ===== Design from Visa link family Screen ===== */}

                    {false && (
                      <div>
                        <div className="cv-row">
                          {/* Expiry date false in family link */}
                          {false && (
                            <div style={{ margin: 10 }}>
                              <div className="cv-assist-cont">
                                <span className="cv-bold-text">
                                  Investor Plus (Investor 1 Category)
                                </span>
                                <div className="cv-green-box">
                                  <span className="cv-green-text">
                                    NEW ZELAND
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="cv-gray-cont"
                          style={{ paddingBottom: 20 }}
                        >
                          {false && (
                            <div className="cv-row">
                              <div style={{ width: "50%" }}>
                                <div>
                                  <p style={{ marginTop: 0, marginLeft: 5 }}>
                                    Expiry Date
                                  </p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={this.onChangeDate}
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p style={{ marginLeft: 5 }}>Approved Date</p>
                                  <div
                                    class="profile-input-border"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "transparent",
                                      border: "none",
                                      overflow: "inherit",
                                    }}
                                  >
                                    <div
                                      class="profile-input-with-icon"
                                      style={{
                                        overflow: "inherit",
                                        display: "flex",
                                        height: 30,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                        width: "100%",
                                      }}
                                    >
                                      <DatePicker
                                        format={dateFormat}
                                        calendarIcon={null}
                                        clearIcon={null}
                                        onChange={this.onChangeDate}
                                        value={this.state.date}
                                      />
                                      <img
                                        src={Images.calendar}
                                        className="profile-calendar-icon"
                                      />
                                    </div>
                                    <div class="profile-refresh-cont">
                                      <img
                                        src={Images.refreshArrow}
                                        style={{ width: 18, height: 18 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", marginTop: 20 }}>
                                <div
                                  className="cv-purpel-box"
                                  style={{
                                    height: 50,
                                    backgroundColor: "#45BE57",
                                  }}
                                >
                                  <div className="cv-imm-cont">
                                    <span className="cv-imm-text">
                                      APPROVED
                                    </span>
                                  </div>
                                  <div className="cv-icons-row">
                                    <div>
                                      <img
                                        src={Images.multimedia}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.emailWhite}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={Images.interfaceIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="cv-delete-cont"
                                  style={{ marginTop: 0 }}
                                >
                                  <img
                                    src={Images.deleteGray}
                                    className="cv-dlt-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {/* <div className="cv-horizontal-line"></div> */}
                          <div className="cv-lf-white-cont">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 20,
                                marginLeft: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 15,
                                }}
                              >
                                <div
                                  class="cm-profile-fill-cont"
                                  style={{ marginLeft: 20 }}
                                >
                                  <img
                                    src={Images.personFill}
                                    class="cm-profile-fill"
                                  />
                                </div>
                                <div className="cv-lf-checkbox-label-cont">
                                  <span className="sus-checkbox-label">
                                    Test partner
                                  </span>
                                </div>
                                <div>
                                  <Checkbox
                                    checked={this.state.checked}
                                    onChange={this.onChangeFamilyCheck}
                                  ></Checkbox>
                                </div>
                              </div>
                              {/* disable static users */}
                              {false && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 15,
                                  }}
                                >
                                  <div
                                    class="cm-profile-fill-cont"
                                    style={{ marginLeft: 20 }}
                                  >
                                    <img
                                      src={Images.personFill}
                                      class="cm-profile-fill"
                                    />
                                  </div>
                                  <div className="cv-lf-checkbox-label-cont">
                                    <span className="sus-checkbox-label">
                                      Sam Ban
                                    </span>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sus-checkbox"
                                      onChange={this.handleCheck}
                                      defaultChecked={this.state.checked}
                                    />
                                  </div>
                                </div>
                              )}
                              {false && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 15,
                                  }}
                                >
                                  <div
                                    class="cm-profile-fill-cont"
                                    style={{ marginLeft: 20 }}
                                  >
                                    <img
                                      src={Images.personFill}
                                      class="cm-profile-fill"
                                    />
                                  </div>
                                  <div className="cv-lf-checkbox-label-cont">
                                    <span className="sus-checkbox-label">
                                      Lewis Jams
                                    </span>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      className="sus-checkbox"
                                      onChange={this.handleCheck}
                                      defaultChecked={this.state.checked}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {false && (
                          <div className="cv-row">
                            <div style={{ margin: 10 }}>
                              <div className="cv-assist-cont">
                                <span className="cv-bold-text">
                                  Family and Spousal Visas
                                </span>
                                <div className="cv-green-box">
                                  <span className="cv-green-text">
                                    AUSTRALIA
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default EmployerCase;
