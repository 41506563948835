import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardBi from "./../DashboardBi";

import {
  getClientAnalytics,
  getProcessingPersons,
  getTaskAnalytics,
  getAccountsAnalytics,
  getVisaAnalytics,
  getIdleSinceAnalytics,
  getActiveSinceAnalytics,
  getVisaStatusAnalytics,
  getCaseStatuses,
  getActiveBalanceAnalytics,
  updateCompletedTask,
  getTasksComments,
  addTaskComment,
  getTasksFollowers,
  getClientProfile,
  getPotentialClientInfo,
  getPotentialClientUpdateBol,
  getDailyTasks,
  updetaTasks,
  addTaskFollower,
  removeFollower,
  removeTasks,
  getClientsByClientDate,
  getVisaClientsByVisaTypeId,
  getReminderTasks,
  removeTaskClient,
  searchMainClient,
  removeReminderTask,
  addReminderTask,
  searchPotentialClient,
  updateReminderDetail,
  updateDashboardReminderStatus,
  getIncomingAccountsClientInfo,
  getOutgoingAccountsClientInfo,
  getPermissonUser,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    clientAnalytics: state.dashboardBiReducer.clientAnalytics,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    clientAnalyticsError: state.dashboardBiReducer.clientAnalyticsError,
    processingPersons: state.dashboardPotentialClientReducer.processingPersons,
    taskAnalytics: state.dashboardBiReducer.taskAnalytics,
    accountAnalytics: state.dashboardBiReducer.accountAnalytics,
    accountAnalyticsError: state.dashboardBiReducer.accountAnalyticsError,
    visaAnalytics: state.dashboardBiReducer.visaAnalytics,
    visaAnalyticsError: state.dashboardBiReducer.visaAnalyticsError,
    idleSinceAnalytics: state.dashboardBiReducer.idleSinceAnalytics,
    idleSinceAnalyticsError: state.dashboardBiReducer.idleSinceAnalyticsError,
    activeSinceAnalytics: state.dashboardBiReducer.activeSinceAnalytics,
    activeSinceAnalyticsError:
      state.dashboardBiReducer.activeSinceAnalyticsError,
    visaStatusAnalytics: state.dashboardBiReducer.visaStatusAnalytics,
    visaStatus: state.caseManagementReducer.visaStatus,
    activeBalanceAnalytics: state.dashboardBiReducer.activeBalanceAnalytics,
    dailyTaskRes: state.reminderReducer.dailyTaskRes,
    clientDetails: state.dashboardBiReducer.clientDetails,
    clientDetailsError: state.dashboardBiReducer.clientDetailsError,
    visaClientDetails: state.dashboardBiReducer.visaClientDetails,
    visaClientDetailsError: state.dashboardBiReducer.visaClientDetailsError,
    reminderTaskRes: state.reminderReducer.reminderTaskRes,
    searchClientData: state.clientProfileReducer.searchConnectionData,
    potentialClientsData: state.clientProfileReducer.potentialClientsData,
    incomingClientDetails: state.dashboardBiReducer.incomingClientDetails,
    outgoingClientDetails: state.dashboardBiReducer.outgoingClientDetails,
    permProcessingPerson: state.clientSummaryReducer.permProcessingPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientAnalytics: bindActionCreators(getClientAnalytics, dispatch),
    onGetProcessingPersons: bindActionCreators(getProcessingPersons, dispatch),
    onGetTaskAnalytics: bindActionCreators(getTaskAnalytics, dispatch),
    onGetAccountsAnalytics: bindActionCreators(getAccountsAnalytics, dispatch),
    onGetVisaAnalytics: bindActionCreators(getVisaAnalytics, dispatch),
    onGetIdleSinceAnalytics: bindActionCreators(
      getIdleSinceAnalytics,
      dispatch
    ),
    onGetActiveSinceAnalytics: bindActionCreators(
      getActiveSinceAnalytics,
      dispatch
    ),
    onGetVisaStatusAnalytics: bindActionCreators(
      getVisaStatusAnalytics,
      dispatch
    ),
    onGetVisaStatuses: bindActionCreators(getCaseStatuses, dispatch),
    onGetActiveBalanceAnalytics: bindActionCreators(
      getActiveBalanceAnalytics,
      dispatch
    ),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetPotentialClient: bindActionCreators(getPotentialClientInfo, dispatch),
    onGetUpdateBol: bindActionCreators(getPotentialClientUpdateBol, dispatch),
    onGetDailyTasks: bindActionCreators(getDailyTasks, dispatch),
    onUpdetaTasks: bindActionCreators(updetaTasks, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onGetClientsByClientDate: bindActionCreators(
      getClientsByClientDate,
      dispatch
    ),
    onGetVisaClients: bindActionCreators(getVisaClientsByVisaTypeId, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onRemoveTaskClient: bindActionCreators(removeTaskClient, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onRemoveReminderTask: bindActionCreators(removeReminderTask, dispatch),
    onAddReminderTask: bindActionCreators(addReminderTask, dispatch),
    onSearchPotentialClient: bindActionCreators(
      searchPotentialClient,
      dispatch
    ),
    onUpdateReminderDetail: bindActionCreators(updateReminderDetail, dispatch),
    onUpdateDashboardReminderStatus: bindActionCreators(
      updateDashboardReminderStatus,
      dispatch
    ),
    onGetIncomingClientDetail: bindActionCreators(
      getIncomingAccountsClientInfo,
      dispatch
    ),
    onGetOutgoingClientDetail: bindActionCreators(
      getOutgoingAccountsClientInfo,
      dispatch
    ),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBi);
