import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginImage from "../Admin/LoginImage";

import { uploadAvatar } from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginImage);
