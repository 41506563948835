import React from "react";
import Select from "react-select";
import { message, Spin, Button, Upload, Input, Form } from "antd";
import FileDownload from "js-file-download";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";

import TeamMemberCard from "../Components/SuperUserSetting/TeamMemberCard";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const headOption = [
  {
    tabName: "Organization Settings",
    linkName: "/super-user-setting?activeTab=company-information",
  },
  // { tabName: "ADD TEAM MEMBER", linkName: "/team-member" },
  { tabName: "Branch Settings", linkName: "/branch-settings" },
];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

var stopLoader = false;

class AddTeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      myEmail: "",
      myPassword: "",
    };

    // this.props;
    // .onGetTeamMember()
    // .then((res) => {
    //
    // })
    // .catch((err) => {
    //   // console.log(this.props.teamMemberError);
    // });
    // this.props.onGetBranch();
  }

  componentDidUpdate(prevProps) {
    console.log("show add success value", this.props.addTeamMemberSuccess);
    if (
      prevProps.addTeamMemberSuccess !== this.props.addTeamMemberSuccess &&
      this.props.addTeamMemberSuccess.id !== "Email exists"
    ) {
      // this.props.onGetTeamMember();
    }
    if (prevProps.updManagerSuccess !== this.props.updManagerSuccess) {
      //
      // stopLoader = true;
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onSubmit = () => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.myEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!strongRegex.test(this.state.myPassword)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (!this.state.fullName) {
      message.warning("User name must be required!");
    } else {
      this.setState({ loading: true });
      let selectedBranchId = localStorage.getItem("selectedBranchId");

      let data = {
        roleId: 0,
        title: "",
        image: this.state.addUserImageUrl,
        forgotPasswordBit: false,
        lastSignedIn: new Date(),
        signature: "",
        signatureImage: "",
        timeZone: "",
        comission: 0,
        agreementUrl: "",
        description: "",
        fullName: this.state.fullName,
        alternateEmail: this.state.myEmail,
        status: 0,
        isManager: false,
        isOwner: false,
        permissions: [
          {
            role: "Client Database",
            status: 0,
          },
          {
            role: "Client Database (Update)",
            status: 1,
          },
          {
            role: "Add A Client",
            status: 0,
          },
          {
            role: "Potential Clients",
            status: 0,
          },
          {
            role: "Potential Clients (Update)",
            status: 1,
          },
          {
            role: "Accounts",
            status: 0,
          },
          {
            role: "Visa Forms",
            status: 0,
          },
          {
            role: "Reports",
            status: 0,
          },
          {
            role: "Mail",
            status: 0,
          },
          {
            role: "Case Management",
            status: 0,
          },
          {
            role: "Agent Portal",
            status: 0,
          },
          {
            role: "Agent Portal Accounts",
            status: 0,
          },
          {
            role: "School Management",
            status: 0,
          },
          {
            role: "School Management Account",
            status: 0,
          },
          {
            role: "Employer Management",
            status: 0,
          },
          {
            role: "Employer Management Account",
            status: 0,
          },
          {
            role: "Referral Management",
            status: 0,
          },
          {
            role: "Custom Questionnaire",
            status: 0,
          },
          {
            role: "Deals",
            status: 0,
          },
          {
            role: "Meetings",
            status: 0,
          },
          {
            role: "Time Tracking",
            status: 0,
          },
          {
            role: "Company Level Settings",
            status: 0,
          },
          // {
          //   role:"Potential Client Account",
          //   status: 0
          // }
        ],
        loginPermission: {
          timeStart: "9:00 AM",
          timeEnd: "5:00 PM",
          timeZone: "(UTC+12:00)",
          days: [
            {
              day: "Monday",
              status: 0,
            },
            {
              day: "Tuesday",
              status: 0,
            },
            {
              day: "Wednesday",
              status: 0,
            },
            {
              day: "Thursday",
              status: 0,
            },
            {
              day: "Friday",
              status: 0,
            },
            {
              day: "Saturday",
              status: 0,
            },
            {
              day: "Sunday",
              status: 0,
            },
          ],
        },
      };

      console.log(data);

      this.props
        .onAddTeamMember(data)
        .then(() => {
          this.setState({ loading: false });

          if (this.props.addTeamMemberSuccess.id === "Email exists") {
            message.error("User email already exist!");
          } else {
            // this.props.onGetTeamMember();
            let createPassword = {
              userId: this.props.addTeamMemberSuccess.id,
              email: this.state.myEmail,
              password: this.state.myPassword,
            };

            this.props.onAddPassword(createPassword);
            let data = {
              branchId: selectedBranchId,
              status: 1,
              userId: this.props.addTeamMemberSuccess.id,
              branchDesignation: "",
            };
            this.props.onAssignBranch(data);
            // this.props.onAddUserPermission(permissionData);
            message.success("User added successfully");
            this.setState({
              fullName: "",
              myEmail: "",
              myPassword: "",
              addUserImageUrl: "",
            });
          }
        })
        .catch((err) => {
          message.error(
            err.payload && err.payload.response && err.payload.response.data,
            3
          );
          this.setState({ loading: false });
        });
    }
  };

  updateUsersPassword = (passwordData) => {
    console.log("show password data ====== ", passwordData);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!strongRegex.test(passwordData.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (passwordData.password !== passwordData.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      this.setState({ passwordLoading: true });
      let data = {
        userId: passwordData.userId,
        email: passwordData.email,
        password: passwordData.password,
      };
      this.props.onChangeUsersPassword(data).then(() => {
        message.success("User's password updated successfully");
      });
    }
  };

  disableTeamMember = (disableUserData) => {
    this.props.onDisableUser(disableUserData).then(() => {
      if (disableUserData.isLock) {
        message.success("User disabled successfully!");
      } else {
        message.success("User enabled successfully!");
      }
      this.props.onGetTeamMember();
    });
  };

  uploadImage = (info, id) => {
    this.setState({ loadUploadImage: true });
    if (id) {
      this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        if (id) {
          this.setState({
            imageUrl: this.props.imageUploadSuccess,
            loadUploadImage: false,
          });
        } else {
          this.setState({
            addUserImageUrl: this.props.imageUploadSuccess,
            loadUploadImage: false,
          });
        }
      }
    });
  };

  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  assignBranch = (data) => {
    this.props.onAssignBranch(data);
  };

  unassignBranch = (data) => {
    this.props.onUnassignBranch(data);
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    this.props
      .onExportData()
      .then(function(response) {
        this.setState({ exportLoading: false });
        var blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Ezymigrate.zip");
      })
      .catch((e) => message.error("Unable to export data, please try again!"));
  };

  render() {
    const {
      selectedOption,
      fullName,
      myEmail,
      myPassword,
      imageUrl,
      addUserImageUrl,
      exportLoading,
    } = this.state;
    const {
      teamMembers,
      imageUploadSuccess,
      branchData,
      storageData,
    } = this.props;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div
            className="sus-content-card-container"
            style={{ paddingRight: 0, width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <div
                class="sus-form-container"
                style={{
                  width: "100%",
                  marginLeft: 0,
                  marginTop: 67,
                  height: 215,
                  paddingLeft: 55,
                  paddingRight: 55,
                  border: 1,
                  borderStyle: "solid",
                  borderColor: "#D9D9D9",
                }}
              >
                <div
                  class="form-cont"
                  style={{
                    justifyContent: "space-between",
                    paddingTop: 20,
                    paddingBottom: 0,
                    display: "block",
                  }}
                >
                  <Form onFinish={this.onSubmit}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 15,
                      }}
                    >
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={this.uploadImage}
                        onChange={this.handleChangeImage}
                      >
                        <Spin spinning={this.state.loadUploadImage}>
                          {addUserImageUrl ? (
                            <img
                              src={addUserImageUrl}
                              alt="avatar"
                              style={{ width: 105, height: 105 }}
                            />
                          ) : (
                            <img
                              src={Images.dummyUserImage}
                              className="sus-profile-img"
                            />
                          )}
                        </Spin>
                      </Upload>
                      <div style={{ width: "100%" }}>
                        <div class="profile-input-border">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Full Name*"
                              type="text"
                              value={fullName}
                              onChange={(e) =>
                                this.myChangeHandler("fullName", e)
                              }
                            />
                          </Form.Item>
                        </div>
                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Email*"
                              type="text"
                              value={myEmail}
                              autoComplete="off"
                              onChange={(e) =>
                                this.myChangeHandler("myEmail", e)
                              }
                            />
                          </Form.Item>
                        </div>
                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <Input
                            className="profile-input"
                            placeholder="Password"
                            type="password"
                            value={myPassword}
                            autoComplete="off"
                            onChange={(e) =>
                              this.myChangeHandler("myPassword", e)
                            }
                          />
                        </div>
                        <Button
                          // onClick={this.onSubmit}
                          loading={this.state.loading}
                          type="primary"
                          className="sus-add-btn"
                          style={{ marginTop: 20 }}
                          htmlType="submit"
                        >
                          ADD
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddTeamMember;
