import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import { Table, Spin, message, Button, Upload, Form, Avatar } from "antd";
import yyyymmddFormate from "./../../Common/reUseFunction";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//
//   return isJpgOrPng && isLt2M;
// }

const { Dragger } = Upload;
const AgentPortalDocuments = ({
  onGetDocuments,
  documentRes,

  onAddDocument,

  onRemoveDocument,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadDoc, setUploadDoc] = useState(false);
  const [addData, setAddData] = useState([]);
  const [imageUrl, setImageUrl] = useState({});
  const [imageValues, setImageValues] = useState({});
  const state = useLocation().state;

  useEffect(() => {
    setLoading(true);
    onGetDocuments()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetDocuments]);

  if (documentRes && documentRes.items) {
    var findDocument =
      documentRes &&
      documentRes.items.filter((obj) => obj.companyDocumentTypeId === 2);
  }

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveDocument(remove)
      .then(() => {
        onGetDocuments().then(() => {
          setLoading(false);
          message.success("Successfully Deleted!");
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      setImageValues(info.file.originFileObj || info.file);
      setLoading(false);
      // getBase64(
      //   info.file.originFileObj,
      //   (imageUrl) => setImageUrl(imageUrl),
      //   setLoading(false)
      // );
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);

    const JsonData = {
      CompanyDocumentTypeId: 2,
      SizeInKB: imageValues && imageValues.size,
      Name: imageValues && imageValues.name,
      BlobUrl: "",
      BlobFileName: "",
      Extension: imageValues && imageValues.type,
    };

    var formdata = new FormData();
    formdata.append("file", imageValues && imageValues);
    formdata.append("Data", JSON.stringify(JsonData));

    onAddDocument(formdata).then(() => onGetDocuments());
    setLoading(false);
    message.success("Uploaded Successfully!");
  };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    // },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "createdDate",
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <DownloadOutlined onClick={() => onDownloadDoc(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onDownloadDoc = (value) => {
    const docTypeOpt = {
      url: `v1/company/document/GetDocumentBytes/${value.id}/${value.blobFileName}`,
    };

    docTypeOpt.types = [
      "DOCUMENT_DOWNLOAD_SUCCESS",
      "DOCUMENT_DOWNLOAD_FAILURE",
    ];

    setLoading(true);
    apiRefresh
      .getFile(docTypeOpt)
      .then((res) => {
        downlodDocument(res, value);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.name}`);
  };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.agentDoc} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  Agent Portal Document
                </span>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="client-section">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="d-flex space-between">
                    <div>
                      {!uploadDoc && uploadDoc === false ? (
                        <Button
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                          className="button-blue"
                        >
                          ADD NEW DOCUMENT
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                          className="button-blue"
                        >
                          HIDE
                        </Button>
                      )}
                    </div>
                  </div>
                  {uploadDoc && uploadDoc === true && (
                    <div className="file-uploader">
                      <Form onFinish={onFinish} form={form}>
                        <Form.Item name="username">
                          <Dragger
                            name="avatar"
                            listType="card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            // beforeUpload={beforeUpload}
                            onChange={handleChange}
                          >
                            {uploadButton}
                          </Dragger>
                          <Button
                            htmlType="submit"
                            type="primary"
                            className="button-blue"
                          >
                            Upload
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={findDocument && findDocument}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AgentPortalDocuments;
