import * as types from "../Constants";

export const visaStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case types.VISA_STATUS_SUCCESS:
      return { ...state, visaStatusData: action.payload };
    case types.VISA_STATUS_FAILURE:
      console.log("on error console", action.payload);
    case types.VISA_STATUS_HIDE_SUCCESS:
      return { ...state, visaStatusWithHideData: action.payload };
    case types.VISA_STATUS_HIDE_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_VISA_STATUS_SUCCESS:
      return { ...state, addVisaStatusSuccess: action.payload };
    case types.ADD_VISA_STATUS_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_VISA_STATUS_SUCCESS:
      return { ...state, updVisaStatusSuccess: action.payload };
    case types.UPDATE_VISA_STATUS_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
