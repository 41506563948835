import { api } from "../../services/api";
import * as types from "../Constants";

export const getQuestionnaireSettings = () => {
  const options = {
    url: `v1/branch/QuestionnaireSetting`,
  };
  options.types = [
    types.GET_QUESTIONNAIRE_SETTING_SUCCESS,
    types.GET_QUESTIONNAIRE_SETTING_FAILURE,
  ];

  return api.get(options);
};

export const updateQuestionnaireSet = (data) => {
  const options = {
    url: "v1/branch/QuestionnaireSetting",
  };
  options.types = [
    types.PUT_QUESTIONNAIRE_SETTING_SUCCESS,
    types.PUT_QUESTIONNAIRE_SETTING_FAILURE,
  ];

  return api.put(options, data);
};
