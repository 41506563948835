import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, message, Radio } from "antd";
import FroalaEditor from "react-froala-wysiwyg";
import * as types from "../../store/Constants";
import { apiRefresh } from "../../services/api";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const LetterTemplatesForm = ({
  handleCancel,
  setLoading,

  createMode,

  updatedata,

  onGetLetterTemplates,

  onAddLetterTemplates,

  onUpdetaLetterTemplates,
  onGetDocumentDownload,
}) => {
  const [letterString, setLetterString] = useState("");

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    var header = false;
    if (values.header == 1) {
      header = true;
    }
    if (createMode === "add-contracts") {
      const data = {
        id: 0,
        type: "CONTRACT",
        name: values.name,
        content: letterString,
        header: header,
        defaultContract: true,
      };

      onAddLetterTemplates(data)
        .then(() => handleCancel())
        .then(() => onGetLetterTemplates())
        .then(() => {
          setLoading(false);
          message.success("Successfully Added!");
        });
    } else {
      const update = {
        id: updatedata.id,
        type: "CONTRACT",
        name: values.name,
        content: letterString,
        header: header,
        defaultContract: true,
      };

      onUpdetaLetterTemplates(update)
        .then(() => handleCancel())
        .then(() => onGetLetterTemplates())
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        });
    }
  };
  useEffect(() => {
    if (updatedata) {
      var Header = 2;
      if (updatedata.header == true) {
        Header = 1;
      }

      form.setFieldsValue({
        name: updatedata.name,
        header: Header,
      });
      setLetterString(updatedata.content);
    }
  }, [updatedata]);
  const openPDF = () => {
    var formData = new FormData();
    formData.append(`Html`, letterString);
    formData.append(`BranchId`, localStorage.getItem("selectedBranchId"));
    formData.append(`FileTitle`, "Letter");
    const docTypeOpt = {
      url: `v1/config/GetPdf`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.post(docTypeOpt, formData).then((res) => {
      let selectedBranchId = localStorage.getItem("selectedBranchId");
      const docViewOpt = {
        url: `v1/document/GetDocumentBytesforAttachment/${res.uri}/${selectedBranchId}`,
      };

      docViewOpt.types = [
        types.GET_DOCUMENT_PREVIEW_SUCCESS,
        types.GET_DOCUMENT_PREVIEW_FAILURE,
      ];
      apiRefresh.getFile(docViewOpt).then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
    });
  };

  return (
    <Fragment>
      <Form
        className="width-100 border-box padding-20"
        name="main"
        onFinish={onFinish}
        form={form}
      >
        <div className="width-100 d-flex align-center letter-font">
          <div className="w-20 ">
            <p>Name</p>
          </div>
          <div className="w-80">
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Required!" },
                {
                  max: 100,
                  message: "Name should be less than 100 character",
                },
              ]}
            >
              <Input placeholder="Add New" />
            </Form.Item>
          </div>
        </div>
        <div className="width-100 d-flex align-center letter-font letter-spacing">
          <div className="w-20 ">
            <p>Show Header</p>
          </div>
          <div className="w-80">
            <Form.Item name="header">
              <Radio.Group defaultValue={2}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <Form.Item className="" name="content">
          <div className="width-100 d-flex align-center letter-font letter-spacing">
            <div className="w-20">
              <p>Contents</p>
            </div>
            <div className="w-80 letter-froala">
              <FroalaEditorCom
                model={letterString}
                onModelChange={(value) => setLetterString(value)}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item className="d-flex-contract">
          <Button
            onClick={() => handleCancel()}
            className="form-btn button-blue"
            type="primary"
          >
            Cancel
          </Button>

          <div className="margin-btns">
            <Button
              className="form-btn button-blue"
              onClick={() => openPDF()}
              type="primary"
            >
              Preview
            </Button>
          </div>
          <div className="margin-btns">
            <Button
              className="form-btn button-blue"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form.Item>
        <Form.Item></Form.Item>
      </Form>
    </Fragment>
  );
};
export default LetterTemplatesForm;
