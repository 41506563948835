import React, { useState } from "react";
import FileNotes from "./FileNotes";
import { Spin } from "antd";

const FileNotesMain = ({
  onGetEmployerFile,
  employerFileRes,
  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  potentialClientInfo,

  onRemoveEmployerFile,

  handleCancel,

  onUpdateEmployerFile,

  onGetClientFile,
  clientFileRes,
  employerDocumentRes,

  onAddClientFile,

  onUpdateClientFile,

  onDeleteClientFile,
  onGetAllUsers,
  onAddDailyTasks,
  users,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="border-box emp-profile-box">
      <p style={{ marginBottom: "8px", marginLeft: "13px" }}>
        {potentialClientInfo && potentialClientInfo.firstName
          ? `Name: ${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`
          : `Add a New Potential Client`}
      </p>
      <FileNotes
        onGetEmployerFile={onGetEmployerFile}
        employerFileRes={employerFileRes}
        onAddEmployerFile={onAddEmployerFile}
        onGetLetterTemplates={onGetLetterTemplates}
        LetterTemplatesRes={LetterTemplatesRes}
        potentialClientInfo={potentialClientInfo}
        onRemoveEmployerFile={onRemoveEmployerFile}
        handleCancel={handleCancel}
        onUpdateEmployerFile={onUpdateEmployerFile}
        onGetClientFile={onGetClientFile}
        clientFileRes={clientFileRes}
        employerDocumentRes={employerDocumentRes}
        onAddClientFile={onAddClientFile}
        onUpdateClientFile={onUpdateClientFile}
        onDeleteClientFile={onDeleteClientFile}
        setLoading={setLoading}
        loading={loading}
        onGetAllUsers={onGetAllUsers}
        onAddDailyTasks={onAddDailyTasks}
        users={users}
      />
    </div>
  );
};

export default FileNotesMain;
