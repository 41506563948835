import clientProfile from "../svgs/upper-menu-icons/client-profile.svg";
import admission from "../svgs/upper-menu-icons/admission.svg";
import documents from "../svgs/upper-menu-icons/documents.png";
import email from "../svgs/upper-menu-icons/email.svg";
import activities from "../svgs/upper-menu-icons/activities.svg";
import caseManagement from "../svgs/upper-menu-icons/case-management.png";
import accounts from "../svgs/upper-menu-icons/accounts.png";
import questionnare from "../svgs/upper-menu-icons/questionnare.svg";
import supplier from "../svgs/upper-menu-icons/supplier.svg";
import print from "../svgs/upper-menu-icons/print.svg";
import visas from "../svgs/upper-menu-icons/visas.png";
import printWhite from "../svgs/print.svg";
import releasePlanImage from "../images/release-plan.png";
import downArrWhite from "../svgs/down-arrow-white.svg";
import btnImg from "../svgs/btn-img.svg";
import tickWhite from "../svgs/tick-white.svg";
import calender from "../svgs/calender.svg";
import rightArrow from "../svgs/right-arrow.svg";
import emailWhite from "../svgs/email-white.svg";
import updateWhite from "../svgs/update-button.svg";
import calendar from "../svgs/calender.svg";
import brownCalendar from "../svgs/brown-calendar.svg";
import cgreenCross from "../svgs/cgreen-cross.svg";
import blueAdd from "../svgs/blue-add.svg";
import refreshArrow from "../svgs/arrows.svg";
import logoWithIcon from "../images/logo-with-icon.png";
import visaType from "../svgs/visa-type.svg";
import visaStatus from "../svgs/visa-status.svg";
import potentialClients from "../svgs/potential-clients.svg";
import plusIcon from "../svgs/plus-icon.svg";
import crossRed from "../svgs/cross-red.svg";
import fonts from "../svgs/fonts.svg";
import signature from "../svgs/signature.svg";
import imap from "../svgs/imap.svg";
import timeZone from "../svgs/time-zone.svg";
import documentView from "../svgs/documents.svg";
import calendarBlue from "../svgs/calendar-blue.svg";
import dailyMailSetting from "../svgs/daily-mail-settings.svg";
import documentSync from "../svgs/document-sync.svg";
import emailContent from "../svgs/email-content.svg";
import letterTemplate from "../svgs/letter-template.svg";
import contract from "../svgs/contract.svg";
import fileNotes from "../svgs/file-notes.svg";
import docChecklist from "../svgs/doc-checklist.svg";
import visaUpdateNotification from "../svgs/visa-update-notification.svg";
import reminder from "../svgs/reminder.svg";
import mailChimp from "../svgs/mail-chimp-icon.svg";
import docType from "../svgs/doc-type.svg";
import clientTags from "../svgs/client-tags.svg";
import myVisaDoc from "../svgs/my-visa-doc.svg";
import agentDoc from "../svgs/agent-document.svg";
import questionnaireIcon from "../svgs/questionnaire.svg";
import timeTracking from "../svgs/time-tracking.svg";
import disable from "../svgs/disable.svg";
import deleteIcon from "../svgs/del-blue.svg";
import multimedia from "../svgs/multimedia.svg";
import crossWhite from "../svgs/cross-white.svg";
import deleteGray from "../svgs/delete-gray.svg";
import interfaceIcon from "../svgs/interface.svg";
import siginingDocs from "../svgs/sigining-doc.svg";
import download from "../svgs/download.svg";
import printBlue from "../svgs/print-blue.svg";
import extendIcon from "../svgs/extend.svg";
import multimediaBlue from "../svgs/multimedia-blue.svg";
import visibility from "../svgs/visibility.svg";
import personFill from "../svgs/person-fill.svg";
import officeMatereial from "../svgs/office-material.svg";
import backToDoc from "../svgs/back-to-doc.svg";
import move from "../svgs/move.svg";
import profile from "../images/profile-pic.png";
import cross from "../svgs/cross.svg";
import multimediaOption from "../svgs/multimedia-option.svg";
import down from "../svgs/down.svg";
import editBorderIcon from "../svgs/edit-border.svg";
import grayCross from "../svgs/gray-cross.svg";
import printNBlue from "../svgs/print-nblue.svg";
import userCircle from "../svgs/user-circle.svg";
import docEmp from "../svgs/doc-emp.svg";
import userEmp from "../svgs/user-employer.svg";
import copy from "../svgs/copy.svg";
import paper from "../svgs/paper.svg";
import userCircleGray from "../svgs/user-circle-gray.svg";
import nextGray from "../svgs/next-gray.svg";
import bellIcon from "../svgs/bell-icon.svg";
import bellRed from "../svgs/bell-red.svg";
import arrowCurveBlue from "../svgs/arrow-curve-blue.svg";
import editBorderBlue from "../svgs/edit-border-blue.svg";
import deleteBlue from "../svgs/delete-blue.svg";
import pointer from "../svgs/pointer.svg";
import arrowDarkBlue from "../svgs/arrow-dark-blue.svg";
import clockBlue from "../svgs/clock-blue.svg";
import notesWhite from "../svgs/notes-white.svg";
import notesBlue from "../svgs/file-notes-blue.svg";
import logo from "../images/logo.png";
import loginImage from "../images/login-banner.PNG";
import dummyUserImage from "../images/dummy-person-image.jpg";
import organizationSettings from "../svgs/organization-settings.svg";
import addIcon from "./../images/add-icon.png";
import AddTask from "./../images/AddTask.png";
import duplicate from "./../images/duplicate.png";
import deals from "./../images/deals.png";
import admissionTab from "./../images/admission_tab.png";
import newApplicaton from "./../images/new_application.png";
import jobs from "./../images/jobs.png";
import IconImg from "./../images/IconImg.png";
import Featuresimg from "./../images/Features-img.png";
import filemerge from "./../images/file-merge.png";
import linkvisa from "./../svgs/link-visa.svg";
import ctopdf from "./../svgs/ctopdf.svg";
import deletedoc from "./../svgs/del-blue.svg";
import eyepreview from "./../svgs/Preview.svg";
import folder from "./../images/folder.png";
import yellowfolder from "./../images/yellowfolder.png";
import releasePlanImageSecond from "./../images/releasePlanBanner.png";
import detailEmail from "./../images/detail-email.jpg";
import forward from "./../images/forward-arrow-icon.png";
import crossGrayFilled from "../images/cross-gray-filled.png";
import reminders from "../images/reminders.png";
import creativeCommons from "../images/creative-commons.png";
import important from "../images/important.png";
import edit from "../svgs/edit.svg";
import info from "../svgs/info.svg";
import organizationSetting from "../images/organization-setting.png";
import outlookInt from "../images/creative-commons.png";
import SendGrid from "../images/SendGrid.png";
import apiKeyIcon from "../images/api-key-icon.png";
import visaCountry from "../images/visaCountry.png";
import jsonImg from "../images/json.png";
import SupervisorLogin from "../images/SupervisorLogin.jpg";
import crossIcon from "../svgs/cross-icon.png";
import activeGreen from "../svgs/active-green.png";
import grayDollar from "../svgs/grayDollar.png";
import coverLetterView from "../images/cover-letter-view.png";
import personIcon from "../svgs/person.svg";
import sendBlue from "../images/Send_blue.png";
import oopsIcon from "../images/oops.jpg";
const images = {
  logo: logo,
  logoWithIcon: logoWithIcon,
  clientProfile: clientProfile,
  admission: admission,
  documents: documents,
  email: email,
  activities: activities,
  accounts: accounts,
  caseManagement: caseManagement,
  questionnare: questionnare,
  supplier: supplier,
  print: print,
  visas: visas,
  printWhite: printWhite,
  whiteArrow: downArrWhite,
  btnImage: btnImg,
  calender: calender,
  rightArrow: rightArrow,
  tickWhite: tickWhite,
  emailWhite: emailWhite,
  updateWhite: updateWhite,
  calendar: calendar,
  cgreenCross: cgreenCross,
  brownCalendar: brownCalendar,
  blueAdd: blueAdd,
  refreshArrow: refreshArrow,
  visaType: visaType,
  visaStatus: visaStatus,
  potentialClients: potentialClients,
  plusIcon: plusIcon,
  crossRed: crossRed,
  fonts: fonts,
  signature: signature,
  imap: imap,
  timeZone: timeZone,
  documentView: documentView,
  calendarBlue: calendarBlue,
  dailyMailSetting: dailyMailSetting,
  documentSync: documentSync,
  emailContent: emailContent,
  letterTemplate: letterTemplate,
  contract: contract,
  fileNotes: fileNotes,
  docChecklist: docChecklist,
  visaUpdateNotification: visaUpdateNotification,
  reminder: reminder,
  mailChimp: mailChimp,
  docType: docType,
  clientTags: clientTags,
  myVisaDoc: myVisaDoc,
  agentDoc: agentDoc,
  questionnaireIcon: questionnaireIcon,
  timeTracking: timeTracking,
  disable: disable,
  deleteIcon: deleteIcon,
  multimedia: multimedia,
  crossWhite: crossWhite,
  deleteGray: deleteGray,
  interfaceIcon: interfaceIcon,
  signingDocs: siginingDocs,
  download: download,
  printBlue: printBlue,
  extendIcon: extendIcon,
  multimediaBlue: multimediaBlue,
  visibility: visibility,
  personFill: personFill,
  officeMatereial: officeMatereial,
  backToDoc: backToDoc,
  move: move,
  profile: profile,
  cross: cross,
  multimediaOption: multimediaOption,
  down: down,
  editBorderIcon: editBorderIcon,
  grayCross: grayCross,
  printNBlue: printNBlue,
  userCircle: userCircle,
  docEmp: docEmp,
  userEmp: userEmp,
  copy: copy,
  paper: paper,
  userCircleGray: userCircleGray,
  nextGray: nextGray,
  bellIcon: bellIcon,
  bellRed: bellRed,
  arrowCurveBlue: arrowCurveBlue,
  editBorderBlue: editBorderBlue,
  deleteBlue: deleteBlue,
  pointer: pointer,
  arrowDarkBlue: arrowDarkBlue,
  clockBlue: clockBlue,
  notesWhite: notesWhite,
  notesBlue: notesBlue,
  loginImage: loginImage,
  releasePlanImage: releasePlanImage,
  releasePlanImageSecond: releasePlanImageSecond,
  organizationSettings: organizationSettings,
  dummyUserImage: dummyUserImage,
  addIcon: addIcon,
  AddTask: AddTask,
  duplicate: duplicate,
  deals: deals,
  admissionTab: admissionTab,
  newApplicaton: newApplicaton,
  jobs: jobs,
  IconImg: IconImg,
  Featuresimg: Featuresimg,
  filemerge: filemerge,
  linkvisa: linkvisa,
  ctopdf: ctopdf,
  deletedoc: deletedoc,
  eyepreview: eyepreview,
  folder: folder,
  yellowfolder: yellowfolder,
  detailEmail: detailEmail,
  forward: forward,
  crossGrayFilled: crossGrayFilled,
  reminders: reminders,
  creativeCommons: creativeCommons,
  important: important,
  edit: edit,
  info: info,
  organizationSetting: organizationSetting,
  outlookInt: outlookInt,
  SupervisorLogin: SupervisorLogin,
  crossIcon: crossIcon,
  activeGreen: activeGreen,
  grayDollar: grayDollar,
  personIcon: personIcon,
  coverLetterView: coverLetterView,
  jsonImg: jsonImg,
  SendGrid: SendGrid,
  apiKeyIcon: apiKeyIcon,
  visaCountry: visaCountry,
  sendBlue: sendBlue,
  oopsIcon,
};

export default images;
