import * as types from "../Constants";

export const reportReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SALE_HISTORY_REPORT_SUCCESS:
      return { ...state, saleHistoryRes: action.payload };
    case types.SALE_HISTORY_REPORT_FAILURE:
      return { ...state, saleHistoryError: action.payload };

    case types.PROCESSING_PERSON_REPORT_SUCCESS:
      return { ...state, processingPersonReportRes: action.payload };
    case types.PROCESSING_PERSON_REPORT_FAILURE:
      return { ...state, processingPersonReportError: action.payload };

    case types.CLIENT_SOURCE_REPORT_SUCCESS:
      return { ...state, clientSourceReportRes: action.payload };
    case types.CLIENT_SOURCE_REPORT_FAILURE:
      return { ...state, clientSourceReportError: action.payload };

    case types.TASK_REPORT_SUCCESS:
      return { ...state, taskReportRes: action.payload };
    case types.TASK_REPORT_FAILURE:
      return { ...state, taskReportError: action.payload };

    case types.BIRTHDAY_REPORT_SUCCESS:
      return { ...state, birthdayReportRes: action.payload };
    case types.BIRTHDAY_REPORT_FAILURE:
      return { ...state, birthdayReportError: action.payload };

    case types.FILE_NOTES_REPORT_SUCCESS:
      return { ...state, fileNotesReportRes: action.payload };
    case types.FILE_NOTES_REPORT_FAILURE:
      return { ...state, fileNotesReportError: action.payload };

    case types.ACTIVE_CASES_REPORT_SUCCESS:
      return { ...state, activeCasesReportRes: action.payload };
    case types.ACTIVE_CASES_REPORT_FAILURE:
      return { ...state, activeCasesReportError: action.payload };

    case types.VISA_EXPIRY_REPORT_SUCCESS:
      return { ...state, visaExpiryReportRes: action.payload };
    case types.VISA_EXPIRY_REPORT_FAILURE:
      return { ...state, visaExpiryReportError: action.payload };

    case types.CLIENT_EMPLOYER_REPORT_SUCCESS:
      return { ...state, clientEmployerReportRes: action.payload };
    case types.CLIENT_EMPLOYER_REPORT_FAILURE:
      return { ...state, clientEmployerReportError: action.payload };

    case types.DOC_CHECKLIST_REPORT_SUCCESS:
      return { ...state, docChecklistReportRes: action.payload };
    case types.DOC_CHECKLIST_REPORT_FAILURE:
      return { ...state, docChecklistReportError: action.payload };

    case types.SERVICE_MNGMNT_REPORT_SUCCESS:
      return { ...state, servMngmntReportRes: action.payload };
    case types.SERVICE_MNGMNT_REPORT_FAILURE:
      return { ...state, servMngmntReportError: action.payload };

    case types.EMPLOYER_MEDICAL_EXPIRY_SUCCESS:
      return { ...state, employerMedicalExpRes: action.payload };
    case types.EMPLOYER_MEDICAL_EXPIRY_FAILURE:
      return { ...state, employerMedicalExpError: action.payload };

    case types.POLICE_CERTIFICATE_EXPIRY_SUCCESS:
      return { ...state, policeCertificateExpRes: action.payload };
    case types.POLICE_CERTIFICATE_EXPIRY_FAILURE:
      return { ...state, policeCertificateExpError: action.payload };

    case types.EMPLOYER_PASSPORT_EXPIRY_SUCCESS:
      return { ...state, employerPassportExpRes: action.payload };
    case types.EMPLOYER_PASSPORT_EXPIRY_FAILURE:
      return { ...state, employerPassportExpError: action.payload };

    case types.TRAVEL_CONDITION_EXPIRY_SUCCESS:
      return { ...state, travelConditionExpRes: action.payload };
    case types.TRAVEL_CONDITION_EXPIRY_FAILURE:
      return { ...state, travelConditionExpError: action.payload };

    case types.P_SALE_HISTORY_REPORT_SUCCESS:
      return { ...state, pSaleHistoryRes: action.payload };
    case types.P_SALE_HISTORY_REPORT_FAILURE:
      return { ...state, pSaleHistoryError: action.payload };

    case types.P_CLIENT_SOURCE_REPORT_SUCCESS:
      return { ...state, pClientSourceReportRes: action.payload };
    case types.P_CLIENT_SOURCE_REPORT_FAILURE:
      return { ...state, pClientSourceReportError: action.payload };

    case types.P_PROCESSING_PERSON_REPORT_SUCCESS:
      return { ...state, pcProcessingPersonReportRes: action.payload };
    case types.P_PROCESSING_PERSON_REPORT_FAILURE:
      return { ...state, pcProcessingPersonReportError: action.payload };

    case types.P_FILE_NOTES_REPORT_SUCCESS:
      return { ...state, pcFileNotesReportRes: action.payload };
    case types.P_FILE_NOTES_REPORT_FAILURE:
      return { ...state, pcFileNotesReportError: action.payload };

    case types.POTENTIAL_CLIENT_STATUS_REPORT_SUCCESS:
      return { ...state, pcStatusReportRes: action.payload };
    case types.POTENTIAL_CLIENT_STATUS_REPORT_FAILURE:
      return { ...state, pcStatusReportError: action.payload };

    case types.POTENTIAL_CLIENT_FILE_NOTES_SUCCESS:
      return { ...state, pcFileNotesRes: action.payload };
    case types.POTENTIAL_CLIENT_FILE_NOTES_FAILURE:
      return { ...state, pcFileNotesError: action.payload };

    case types.DESTINATION_SUCCESS:
      return { ...state, destinationRes: action.payload };
    case types.DESTINATION_FAILURE:
      return { ...state, destinationError: action.payload };

    case types.VISA_REPORT_SUCCESS:
      return { ...state, visaReportRes: action.payload };
    case types.VISA_REPORT_FAILURE:
      return { ...state, visaReportError: action.payload };

    case types.TIME_TRACKING_REPORT_SUCCESS:
      return { ...state, timeTrackingReportRes: action.payload };
    case types.TIME_TRACKING_REPORT_FAILURE:
      return { ...state, timeTrackingReportError: action.payload };

    case types.GET_FILE_NOTES_REPORT_SUCCESS:
      return { ...state, getFileNotesReportRes: action.payload };
    case types.GET_FILE_NOTES_REPORT_FAILURE:
      return { ...state, getFileNotesReportError: action.payload };

    default:
      return state;
  }
};
