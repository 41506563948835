import jwt_decode from "jwt-decode";
export const getBranchId = () => {
  let branchId = localStorage.getItem("selectedBranchId");
  let token = localStorage.getItem("token");
  if (branchId) {
    return branchId;
  } else if (token) {
    var jwtDecoded = jwt_decode(token);
    localStorage.setItem("selectedBranchId", jwtDecoded.BranchId);
    return jwtDecoded.BranchId;
  } else {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
};
