import React, { Fragment, useState, useEffect } from "react";

// import { yyyymmddFormate } from "./../../../../../Common/reUseFunction";
import { Table, message, Modal } from "antd";
import { Images } from "../../Themes";
import { apiRefresh } from "../../services/api";
import LinkKeyStage from "./LinkKeyStage";

const StageTable = ({
  getSalePipelineRes,
  showModal,
  isModalVisible,
  onGetSalePipeline,
  setLoading,
  getPipeline,
  gridList,
  onGetMailChimp,
  mailChimpList,
}) => {
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [mailChimpIntegrated, setMailChimpIntegrated] = useState(false);
  const [updateRes, setUpdateRes] = useState(false);
  const [recordData, setRecord] = useState(null);

  useEffect(() => {
    // onGetSalePipeline();
    const checkMailchimpOpt = {
      url: `v1/mailchimp/GetIsMailChimpOrSendGrid`,
    };
    checkMailchimpOpt.types = [
      "CHECK_MAILCHIMP_SUCCESS",
      "CHECK_MAILCHIMP_FAILURE",
    ];
    setLoading(true);
    apiRefresh.get(checkMailchimpOpt).then((res) => {
      if (res == "MailChimp") {
        setMailChimpIntegrated(true);
      } else {
        setMailChimpIntegrated(false);
      }
      setLoading(false);
    });
    onGetMailChimp()
      .then((res) => {
        setUpdateRes(res && res.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    salesPipelineFilter(getSalePipelineRes);
  }, [getSalePipelineRes]);

  const salesPipelineFilter = (getSalePipelineRes) => {
    let filterList = [];
    if (
      getSalePipelineRes &&
      getSalePipelineRes.pipeline &&
      getSalePipelineRes.pipeline.length > 0
    ) {
      getSalePipelineRes.pipeline.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "100px",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.name}
        </p>
      ),
    },
    {
      title: "Win Probability",
      dataIndex: "winProbability",
      key: "winProbability",
      width: "100px",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.winProbability}
        </p>
      ),
    },
    {
      title: "SendGrid",
      dataIndex: "listid",
      key: "listid",
      width: "100px",
      render: (text, record) => {
        var findObj = gridList && gridList.find((obj) => obj.id === text);
        return (
          <p
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {findObj && findObj.name}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div className="table-action" style={{ alignItems: "center" }}>
              {record.branchId !== "00000000-0000-0000-0000-000000000000" && (
                <img
                  src={Images.editBorderBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => showModal(record, "update-pipeline")}
                />
              )}
              <div style={{ cursor: "pointer" }}>
                <img
                  src={Images.linkvisa}
                  style={{ width: 15, marginRight: 10, cursor: "pointer" }}
                  onClick={() => openlinkModal(record)}
                />
              </div>
              <div style={{ cursor: "pointer" }}>
                <img
                  src={Images.deleteBlue}
                  style={{ width: 15, marginRight: 10, cursor: "pointer" }}
                  onClick={() => openDelModal(record)}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const openDelModal = (data) => {
    setModal1Visible(true);
    setRecord(data);
  };

  const openlinkModal = (data) => {
    setModal2Visible(true);
    setRecord(data);
  };

  const removePipeline = () => {
    let data = {
      id: recordData.id,
    };
    const remPipelineOpt = {
      url: `v1/deal/stage`,
    };
    remPipelineOpt.types = [
      "REMOVE_PIPELINE_SUCCESS",
      "REMOVE_PIPELINE_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(remPipelineOpt, data)
      .then((res) => {
        message.success("Deleted successfuly!");
        setLoading(false);
        setModal1Visible(false);
        onGetSalePipeline();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  const handleCancel = () => {
    setModal2Visible(false);
    setRecord(null);
  };

  return (
    <Fragment>
      <div>
        <Table
          // expandedRowKeys={expandedRowsEntriesKeys}
          pagination={false}
          className="job-table table-head employer-tables border-3 school-table"
          dataSource={getSalePipelineRes}
          columns={columns}
        />
      </div>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={() => removePipeline()}
        onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal2Visible}
        // onOk={() => removePipeline()}
        onCancel={() => setModal2Visible(false)}
        footer={false}
        Header={false}
      >
        <LinkKeyStage
          // onAddSalePipeline={onAddSalePipeline}
          handleCancel={handleCancel}
          setLoading={setLoading}
          // loading={loading}
          onGetSalePipeline={getPipeline}
          updatedata={recordData}
          gridList={gridList}
          mailChimpIntegrated={mailChimpIntegrated}
          mailChimpList={mailChimpList}
          // pipelineObj={pipelineObj}
        />
      </Modal>
    </Fragment>
  );
};

export default StageTable;
