import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const EmployerJobHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_EMPLOYER_JOB_HISTORY_SUCCESS:
      return { ...state, employerJobHistoryRes: action.payload };
    case types.GET_EMPLOYER_JOB_HISTORY_FAILURE:
      if (action.payload.response && action.payload.response.status === 404) {
         
        return { ...state, employerJobHistoryRes: null };
      } else {
         
        if (action.payload.response === "undefined") {
          window.location.assign("/login");
        }
      }

    case types.ADD_EMPLOYER_JOB_HISTORY_SUCCESS:
      return { ...state, addEmployerJobHistoryRes: action.payload };
    case types.ADD_EMPLOYER_JOB_HISTORY_FAILURE:
      if (action.payload.response && action.payload.response.status === 400) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Request not completed due to missing data!"
        );
      } else {
         
      }

      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_EMPLOYER_JOB_HISTORY_SUCCESS:
      return { ...state, updEmployerJobHistoryRes: action.payload };
    case types.UPDATE_EMPLOYER_JOB_HISTORY_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_EMPLOYER_JOB_HISTORY_SUCCESS:
      return { ...state, removeEmployerJobHistoryRes: action.payload };
    case types.DELETE_EMPLOYER_JOB_HISTORY_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_JOB_STATUS_SUCCESS:
      return { ...state, employerJobStatusRes: action.payload };
    case types.GET_EMPLOYER_JOB_STATUS_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMPLOYER_JOB_HISTORY_CURRENT_SUCCESS:
      return { ...state, employerJobHistoryCurrentRes: action.payload };
    case types.GET_EMPLOYER_JOB_HISTORY_CURRENT_FAILURE:
      if (action.payload.response && action.payload.response.status !== 404) {
         
      }
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
