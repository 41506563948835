import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

const DocumentView = ({
  onGetDocumentView,
  docViewRes,
  onAddDocumentView,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [folderCheck, setFolderCheck] = useState(false);
  const state = useLocation().state;

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    onGetDocumentView()
      .then((res) => {
        setLoading(false);
        setFolderCheck(res.payload.isDocumentView);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetDocumentView]);

  const onTargetChecked = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setFolderCheck(e.target.checked);
    const data = {
      isDocumentView: e.target.checked,
    };
    onAddDocumentView(data).then(() => {
      message.success("Successfully Updated!");
    });
  };

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.documentView} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Document View</span>
                <Tooltip
                  placement="topLeft"
                  title={`When enabling folder view, will give you the ability to have specific folders with more of a classic folder view.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>

              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    onChange={onDocumentShow}
                    form={form}
                    className="d-flex space-between"
                  >
                    <div className="reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Checkbox
                          checked={folderCheck}
                          onChange={onTargetChecked}
                        >
                          Folder View
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DocumentView;
