// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
  Space,
} from "antd";
import moment from "moment";
import OptFillQuestion from "./OptFillQuestion";

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function AnswerField(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [value, setValue] = useState("");
  const [optionName, setOptionName] = useState("");
  const [question, setQuestion] = useState(Props.question);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [imageValues, setImageValues] = useState(null);
  const [imageUrl, setImageUrl] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateValue1, setDateValue1] = useState(null);
  const [fileList, setFileList] = useState(null);

  const { Option } = Select;

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const selectDropdown = (value) => {
    var selectedValue =
      Props.questionData.questionOptions.length > 0 &&
      Props.questionData.questionOptions[0].option === "Country"
        ? Props.questionData.questionOptions.length > 0 &&
          Props.questionData.questionOptions[0].option
        : value;
    Props.selectedOption(selectedValue);
  };

  const onChangeCheckbox = (value) => {
    setIsCheckbox(true);
    setCheckboxOptions(value);

    // setCheckboxOptions(prevState => [...prevState, { optionName: option.option}])
    Props.selectedOption(value, true);
  };

  const onHandleChange = (info) => {
    // if (
    //   fileList &&
    //   fileList[fileList.length - 1] &&
    //   fileList[fileList.length - 1].size > 50 * 1024 * 1024
    // ) {
    //   message.warning("File size should not be greater than 50MB");
    // } else {

    // setIsImage(true);
    setFileList(info.fileList);
    // setImageValues(info.file.originFileObj);
    // getBase64(
    //   info.file.originFileObj,
    //   (imageUrl) => setImageUrl(imageUrl),
    //   setLoading(false)
    // );
    // }
  };

  return (
    <div>
      <div
        style={{ width: "100%", marginTop: Props.answerIndex === 0 ? 0 : 15 }}
      >
        <div>
          {Props.questionData.answerType == 1 ? (
            <div
              className="profile-input-border"
              style={{
                border: 0,
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <Input className="profile-input" />
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 2 ? (
            <div
              style={{
                border: 0,
                marginTop: 10,
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
                // initialValue={moment("2007-11-01")}
              >
                <DatePicker
                  value={dateValue1}
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 3 ? (
            <div>
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <Select
                  style={{
                    marginTop: 10,
                    width: "100%",
                  }}
                  onChange={selectDropdown}
                >
                  {Props.questionData.questionOptions.length > 0 &&
                  Props.questionData.questionOptions[0].option.trim() ===
                    "Country"
                    ? Props.countriesData &&
                      Props.countriesData.length > 0 &&
                      Props.countriesData.map((country) => {
                        return (
                          <Option value={country.name}>{country.name}</Option>
                        );
                      })
                    : Props.questionData.questionOptions.length > 0 &&
                      Props.questionData.questionOptions[0].option.trim() ===
                        "VisaType"
                    ? Props.visaTypeData &&
                      Props.visaTypeData.length > 0 &&
                      Props.visaTypeData.map((visa) => {
                        return (
                          <Option value={visa.id}>{visa.visaTypeName}</Option>
                        );
                      })
                    : Props.questionData.questionOptions.map(
                        (option, index) => {
                          //

                          return (
                            <Option value={option.option}>
                              {option.option}
                            </Option>
                          );
                        }
                      )}
                </Select>
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 4 ? (
            <div
              className="options-cont"
              style={{
                display: "flex",
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <Radio.Group>
                  {Props.questionData.questionOptions.length > 0 &&
                    Props.questionData.questionOptions.map((option, index) => {
                      return (
                        <Radio
                          onChange={() => Props.selectedOption(option.option)}
                          value={option.option}
                          defaultChecked="none"
                        >
                          {option.option}
                        </Radio>
                      );
                    })}
                </Radio.Group>
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 5 ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <Checkbox.Group onChange={onChangeCheckbox}>
                  {Props.questionData.questionOptions.length > 0 &&
                    Props.questionData.questionOptions.map((option, index) => {
                      return (
                        <Checkbox value={option.option}>
                          {option.option}
                        </Checkbox>
                      );
                    })}
                </Checkbox.Group>
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 6 ? (
            <div
              className="profile-input-border"
              style={{
                border: 0,
                marginTop: 10,
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="MM/YYYY" />
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 7 ? (
            <div
              style={{
                border: 0,
                marginTop: 10,
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                <textarea rows={5} className="cv-textarea" />
              </Form.Item>
            </div>
          ) : Props.questionData.answerType === 8 ? (
            <div
              // className="profile-input-border"
              style={{
                border: 0,
                marginTop: 10,
              }}
            >
              <Form.Item
                {...Props.answer}
                name={[Props.answer.name, "answer"]}
                key={[Props.answer.fieldKey, "answer"]}
                rules={[
                  {
                    required: Props.questionData.isRequired,
                    message: "Required!",
                  },
                ]}
              >
                {Props.questionData.answers.length > 0 ? (
                  <div className="profile-input-border">
                    <Input disabled={true} className="profile-input" />
                  </div>
                ) : (
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={onHandleChange}
                    multiple
                  >
                    {uploadButton}
                  </Upload>
                )}
                {/* <Input
                  value="Please check attachment above"
                  disabled={true}
                  className="profile-input"
                /> */}
                {/* <Upload
                  // listType="picture-card"
                  // fileList={fileList}
                  // beforeUpload={() => false}
                  onChange={(e) => {
                     
                    console.log(e);
                  }}
                  // multiple
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload> */}
                {/* <Upload>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload> */}
              </Form.Item>
            </div>
          ) : null}
          {false &&
            Props.questionData.isMultiple &&
            Props.answerIndex !== 0 && (
              <MinusCircleOutlined
                onClick={() => Props.remove(Props.answer.name)}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default AnswerField;
