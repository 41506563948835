import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Spin,
  Select,
  Table,
  DatePicker,
  Modal,
  message,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Fragment } from "react";
import { Images } from "./../../Themes";

const dataSource = [
  {
    key: "1",
    name: "Mike",
    age: 32,
    address: "10 Downing Street",
  },
  {
    key: "2",
    name: "John",
    age: 42,
    address: "10 Downing Street",
  },
];

const PotentialEmployer = ({
  onGetEmployerManag,
  employerManagRes,
  onAddEmployerManag,
  addEmployerManagRes,

  onAddDocWithName,
  docWithNameRes,

  onDeleteEmployer,

  singleEmployeFuc,

  showModal,

  setLoading,
  loading,

  onMovePotentialEmployer,
  onGetPClientEmailHistory,
  potentialClientInfo,
}) => {
  // const [loading, setLoading] = useState(false);

  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    letterTempaltesFilter(employerManagRes);
  }, [employerManagRes]);

  const letterTempaltesFilter = (employerManagRes) => {
    let filterList = [];
    if (
      employerManagRes &&
      employerManagRes.items &&
      employerManagRes.items.length > 0
    ) {
      employerManagRes.items.map((data, index) => {
        if (data.isPotential === true) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });

      setDataSource(filterList);
    }
  };

  const removeEmploye = () => {
    setLoading(true);
    const remove = {
      id: onDelete,
      delete: true,
    };

    onDeleteEmployer(remove).then(() => {
      onGetEmployerManag();
      setLoading(false);
      setModal1Visible(false);

      message.success("Successfully Deleted!");
    });
  };

  const moveEmployer = () => {
    setLoading(true);
    const remove = {
      id: onDelete,
      isPotential: false,
    };

    onMovePotentialEmployer(remove).then(() => {
      onGetEmployerManag();
      setLoading(false);
      setModal2Visible(false);

      message.success("Successfully moved!");
    });
  };

  const confirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value);
  };

  const moveConfirmation = (value) => {
    setModal2Visible(!modal2Visible);
    setOnDelete(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      render: (text, record) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => singleEmployeFuc(record)}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Business/Company Name",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
    },
    {
      title: "Contact No",
      dataIndex: "contactNo",
      key: "contactNo",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },

    {
      title: "Action",
      dataIndex: "id",
      ellipsis: true,
      // width: "100px",
      render: (text, record) => (
        <span>
          <Tooltip title="Edit">
            <a href="javascript:" style={{ marginRight: "10px" }}>
              <EditOutlined onClick={() => singleEmployeFuc(record)} />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a href="javascript:" style={{ marginRight: "10px" }}>
              <DeleteOutlined
                onClick={() => confirmation(record && record.id)}
              />
            </a>
          </Tooltip>
          <Tooltip title="Move To Employer">
            <a href="javascript:">
              <PlusCircleOutlined
                onClick={() => moveConfirmation(record && record.id)}
              />
            </a>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="p-emp-container" style={{ marginTop: "20px" }}>
        <div className="d-end space-between">
          <div style={{ marginBottom: "7px" }} className="contact-Head">
            {/*<div className="d-flex align-item ">*/}
            {/*</div>*/}
            <>
              <img
                style={{ width: "29px", height: "29px" }}
                src={Images.addIcon}
                className="icons-client"
                type="primary"
                onClick={() => showModal("add-potential")}
              />
            </>
          </div>
        </div>
        <Row style={{ marginTop: "20px" }} className="p-emp-table">
          <Col className={"school-table"}>
            <Spin size="large" spinning={loading}>
              <Table dataSource={dataSource && dataSource} columns={columns} />
            </Spin>
          </Col>
        </Row>
      </div>

      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={() => removeEmploye()}
        onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal2Visible}
        onOk={() => moveEmployer()}
        onCancel={() => setModal2Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to move this ?</h4>
      </Modal>
    </Fragment>
  );
};

export default PotentialEmployer;
