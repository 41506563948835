import * as types from "../Constants";

export const clientVisaReducer = (state = {}, action) => {
  switch (action.type) {
    case types.START_NEW_APPLICATION_SUCCESS:
      return { ...state, newAppSuccess: action.payload };
    case types.START_NEW_APPLICATION_FAILURE:
      return { ...state, newAppFailure: action.payload };

    case types.GET_VISA_APPLICATION_SUCCESS:
      return { ...state, visaAppData: action.payload };
    case types.GET_VISA_APPLICATION_FAILURE:
      return { ...state, visaAppError: action.payload };

    case types.GET_CLIENT_DOCUMENTS_SUCCESS:
      return { ...state, clientDocumentData: action.payload };
    case types.GET_CLIENT_DOCUMENTS_FAILURE:
      return { ...state, clientDocumentError: action.payload };

    case types.GET_CLIENT_EMAIL_SUCCESS:
      return { ...state, clientEmailData: action.payload };
    case types.GET_CLIENT_EMAIL_FAILURE:
      return { ...state, clientEmailError: action.payload };

    case types.GET_CLIENT_FILE_NOTES_SUCCESS:
      return { ...state, clientFileNotesData: action.payload };
    case types.GET_CLIENT_FILE_NOTES_FAILURE:
      return { ...state, clientFileNotesError: action.payload };

    case types.ADD_FAMILY_LINK_SUCCESS:
      return { ...state, familyLinkSuccess: action.payload };
    case types.ADD_FAMILY_LINK_FAILURE:
      return { ...state, familyLinkError: action.payload };

    case types.REMOVE_FAMILY_LINK_SUCCESS:
      return { ...state, removeFamilyLinkSuccess: action.payload };
    case types.REMOVE_FAMILY_LINK_FAILURE:
      return { ...state, removeFamilyLinkError: action.payload };

    case types.UPDATE_SUBJECT_CASE_SUCCESS:
      return { ...state, updSubjectCaseSuccess: action.payload };
    case types.UPDATE_SUBJECT_CASE_FAILURE:
      return { ...state, updSubjectCaseError: action.payload };

    case types.UPDATE_CASE_STATUS_SUCCESS:
      return { ...state, updCaseStatusSuccess: action.payload };
    case types.UPDATE_CASE_STATUS_FAILURE:
      return { ...state, updCaseStatusError: action.payload };

    case types.DELETE_CASE_STATUS_SUCCESS:
      return { ...state, dltCaseStatusSuccess: action.payload };
    case types.DELETE_CASE_STATUS_FAILURE:
      return { ...state, dltCaseStatusError: action.payload };

    case types.DELETE_SUBJECT_CASE_SUCCESS:
      return { ...state, dltSubjectCaseSuccess: action.payload };
    case types.DELETE_SUBJECT_CASE_FAILURE:
      return { ...state, dltSubjectCaseError: action.payload };

    case types.GET_CASE_DOCUMENTS_SUCCESS:
      return { ...state, caseDocumentData: action.payload };
    case types.GET_CASE_DOCUMENTS_FAILURE:
      return { ...state, caseDocumentError: action.payload };

    case types.GET_VISA_TYPE_BY_COUNTRY_SUCCESS:
      return { ...state, countryVisaTypeData: action.payload };
    case types.GET_VISA_TYPE_BY_COUNTRY_FAILURE:
      return { ...state, countryVisaTypeError: action.payload };

    case types.GET_VISA_CATEGORY_SUCCESS:
      return { ...state, visaCategoryData: action.payload };
    case types.GET_VISA_CATEGORY_FAILURE:
      return { ...state, visaCategoryError: action.payload };

    case types.UPDATE_CASE_PRIORITY_SUCCESS:
      return { ...state, visaPriorityRes: action.payload };
    case types.UPDATE_CASE_PRIORITY_FAILURE:
      return { ...state, visaPriorityError: action.payload };

    case types.UPDATE_SIGNED_VISA_SUCCESS:
      return { ...state, visaSignedRes: action.payload };
    case types.UPDATE_SIGNED_VISA_FAILURE:
      return { ...state, visaSignedError: action.payload };

    case types.UPDATE_PAID_VISA_SUCCESS:
      return { ...state, visaPaidRes: action.payload };
    case types.UPDATE_PAID_VISA_FAILURE:
      return { ...state, visaPaidError: action.payload };

    case types.UPDATE_CASE_HISTORY_SUCCESS:
      return { ...state, updCaseHistorySuccess: action.payload };
    case types.UPDATE_CASE_HISTORY_FAILURE:
      return { ...state, updCaseHistoryError: action.payload };

    case types.GET_VISA_EMAIL_SUCCESS:
      return { ...state, visaEmailRes: action.payload };
    case types.GET_VISA_EMAIL_FAILURE:
      return { ...state, visaEmailErr: action.payload };

    default:
      return state;
  }
};
