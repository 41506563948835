import React, { Fragment, useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
const dateFormat = "DD/MM/YYYY";
function onChange(date, dateString) {
  console.log(date, dateString);
}

const { Option } = Select;

const LinkKeyStage = ({
  onAddSalePipeline,
  handleCancel,

  createMode,

  //   setLoading,
  //   loading,
  onGetSalePipeline,
  updatedata,
  pipelineObj,
  gridList,
  mailChimpIntegrated,
  mailChimpList,
}) => {
  const [openDateChange, setOpenDateChange] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tagList, setTagList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {}, []);
  useEffect(() => {
    // setLoading(true);

    if (updatedata && updatedata.id) {
      if (
        mailChimpIntegrated &&
        updatedata.listid &&
        updatedata.listid != "00000000-0000-0000-0000-000000000000"
      ) {
        onChangeMailChimpList(updatedata.listid);
      }

      form.setFieldsValue({
        name: updatedata.name,
        listId:
          updatedata.listid &&
          updatedata.listid != "00000000-0000-0000-0000-000000000000"
            ? updatedata.listid
            : "",
        tagId:
          updatedata.tagId &&
          updatedata.tagId != "00000000-0000-0000-0000-000000000000"
            ? updatedata.tagId
            : "",
      });
    }
    // setLoading(false);
  }, [updatedata, form, setLoading]);

  const onFinish = (values) => {
    setButtonLoading(true);
    let userId = localStorage.getItem("userId");
    if (updatedata && updatedata.mappingId == 0) {
      let data = {
        listId: values.listId,
        stageId: (updatedata && updatedata.id) || "",
        isActive: true,
        tagId: values.tagId || "",
      };
      const addPipelineStageOpt = {
        url: `v1/deal/sendgridmap`,
      };
      addPipelineStageOpt.types = [
        "REMOVE_PIPELINE_SUCCESS",
        "REMOVE_PIPELINE_FAILURE",
      ];

      apiRefresh
        .post(addPipelineStageOpt, data)
        .then(() => {
          setButtonLoading(false);
          onGetSalePipeline();
          handleCancel();
          message.success("Linked Successfully!");
        })
        .catch(() => {
          setLoading(false);
          message.error("Failed!");
        });
    } else {
      setButtonLoading(true);
      let data = {
        id: updatedata.mappingId,
        listId: values.listId,
        stageId: (updatedata && updatedata.id) || "",
        isActive: true,
        tagId: values.tagId || "",
      };
      const addPipelineStageOpt = {
        url: `v1/deal/sendgridmap`,
      };
      addPipelineStageOpt.types = [
        "REMOVE_PIPELINE_SUCCESS",
        "REMOVE_PIPELINE_FAILURE",
      ];

      apiRefresh
        .put(addPipelineStageOpt, data)
        .then(() => {
          setButtonLoading(false);
          onGetSalePipeline();
          handleCancel();
          message.success("Updated Successfully!");
        })
        .catch(() => {
          setButtonLoading(false);
          message.error("Failed!");
        });
    }
  };

  const onChangeMailChimpList = (value) => {
    const getTagListOpt = {
      url: `v1/mailchimp/GetAlltags/${value}`,
    };
    getTagListOpt.types = ["GET_TAG_LIST_SUCCESS", "GET_TAG_LIST_FAILURE"];

    apiRefresh.get(getTagListOpt).then((res) => {
      setTagList(res.lists);
    });
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <Form
          onFinish={onFinish}
          form={form}
          className="emp-content-box p-box add-job-form width-100"
          name="main"
          style={{ marginTop: 25 }}
        >
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Name</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="Name" disabled />
              </Form.Item>
            </Col>
          </Row>
          {mailChimpIntegrated ? (
            <>
              <Row gutter={8} className="margin-top">
                <Col span={8}>
                  <p style={{ fontSize: 13 }}>MailChimp List</p>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="listId"
                    // rules={[{ required: true, message: "Required!" }]}
                  >
                    <Select placeholder="" onChange={onChangeMailChimpList}>
                      {mailChimpList &&
                        mailChimpList.map((list, ind) => (
                          <Option value={list.listId}>{list.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="margin-top">
                <Col span={8}>
                  <p style={{ fontSize: 13 }}>Client Tag List</p>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="tagId"
                    // rules={[{ required: true, message: "Required!" }]}
                  >
                    <Select placeholder="">
                      {tagList &&
                        tagList.map((list, ind) => (
                          <Option value={list.id}>{list.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <Row gutter={8} className="margin-top">
              <Col span={8}>
                <p style={{ fontSize: 13 }}>Sendgrid Contact List</p>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="listId"
                  // rules={[{ required: true, message: "Required!" }]}
                >
                  <Select placeholder="">
                    {gridList &&
                      gridList.map((list, ind) => (
                        <Option value={list.id}>{list.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item className="margin-top-20">
            <div className="letter-froala emp-froala">
              <div className="document-checklist--btn d-flex">
                <Button
                  style={{ marginRight: "10px" }}
                  // onClick={() => emailTypeData(data.emailType)}
                  htmlType="submit"
                  type="primary"
                  className="button-blue"
                  loading={loading}
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="button-blue"
                >
                  Close
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Fragment>
  );
};
export default LinkKeyStage;
