// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
} from "antd";

import OptionalQuestion from "./OptionalQuestion";
import UpdateSectionCard from "./UpdateSectionCard";
import UpdateCreatedQuestionCard from "./UpdateCreatedQuestionCard";

import {
  MinusCircleOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { apiRefresh } from "../../services/api";

var mappParentData = [];
var mappChildData = [];

function UpdateQuestionCard(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);
  const [fieldName, setFieldName] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [answerType, setAnswerType] = useState(
    Props.questionData ? Props.questionData.answerType.toString() : "1"
  );
  const [optionText, setOptionText] = useState("");
  const [options, setOptions] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [multiCheckboxOption, setMultiCheckbox] = useState("");
  const [radioOption, setRadioOption] = useState("");
  const [isCascading, setCascading] = useState(
    Props.questionData && Props.questionData.isCascading
  );
  const [addQuestion, setAddQuestion] = useState(false);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [dataByUpdate, setDataByUpdate] = useState(true);
  const [isOptionSectionAdd, setIsOptionSectionAdd] = useState(false);
  const [addOptionalSection, setAddOptionalSection] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [isMultiple, SetMultiple] = useState(
    Props.questionData && Props.questionData.isMultiple
  );
  const [questionDataState, setQuestionData] = useState(Props.questionData);
  const [mappedChild, setMappedChild] = useState([]);
  const [propertyDisable, setPropertyDisable] = useState(false);
  const [answerTypeOptions, setAnswerTypeOptions] = useState([]);
  const [typesArray, setTypesArray] = useState([
    { value: "1", label: "Textbox" },
    { value: "2", label: "Date" },
    { value: "3", label: "Drop down list" },
    { value: "4", label: "Radio button" },
    { value: "5", label: "Checkbox" },
    { value: "6", label: "Month" },
    { value: "7", label: "Textarea" },
    { value: "8", label: "File" },
  ]);
  const [propertyType, setPropertytype] = useState([]);
  const [parentId, setParentId] = useState("");
  const [questionString, setQuestionString] = useState(
    Props.questionData ? Props.questionData.question : ""
  );
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [mappChildDataOpt, setMappChildDataOpt] = useState([]);

  useEffect(() => {
    setFieldName(Props.field.name);
    setFieldKey(Props.field.fieldKey);
  }, [Props]);

  useEffect(() => {
    if (Props.mappParentData && Props.questionData) {
      var findParent = Props.mappParentData.find(
        (obj) => obj.name == Props.questionData.mapped
      );
      if (findParent) {
        setParentId(findParent.id);
        if (
          findParent.id == "37" ||
          findParent.id == "38" ||
          findParent.id == "39"
        ) {
          onGetBranchOtherInfo(findParent.id);
        } else {
          Props.getMappChild(findParent.id).then((res) => {
            makePropertiesOpt(res.payload);
            setMappedChild(res.payload);
            if (
              res &&
              res.payload &&
              res.payload.length > 0 &&
              Props.questionData.mappedProperty
            ) {
              var findChild = res.payload.find(
                (obj) => obj.name == Props.questionData.mappedProperty
              );

              if (
                Props.questionData.mapped != "37" &&
                Props.questionData.mapped != "38" &&
                Props.questionData.mapped != "39"
              ) {
                if (findChild) {
                  //  setChildType(findChild.type);
                  var types = [];
                  for (var i = 0; i < typesArray.length; i++) {
                    if (findChild.type.toLowerCase() == "text") {
                      if (
                        typesArray[i].value == "1" ||
                        typesArray[i].value == "3" ||
                        typesArray[i].value == "7"
                      ) {
                        types.push(
                          <Option value={typesArray[i].value}>
                            {typesArray[i].label}
                          </Option>
                        );
                      }
                    } else if (findChild.type.toLowerCase() == "radio") {
                      if (typesArray[i].value == "4") {
                        types.push(
                          <Option value={typesArray[i].value}>
                            {typesArray[i].label}
                          </Option>
                        );
                      }
                      setAnswerType("4");
                    } else if (findChild.type.toLowerCase() == "date") {
                      if (
                        typesArray[i].value == "2" ||
                        typesArray[i].value == "6"
                      ) {
                        types.push(
                          <Option value={typesArray[i].value}>
                            {typesArray[i].label}
                          </Option>
                        );
                      }
                      setAnswerType("2");
                    }
                  }
                  setAnswerTypeOptions(types);
                }
              }
            }
          });
        }
      }
    }
  }, [Props.mappParentData]);

  useEffect(() => {
    var types = [];
    for (var i = 0; i < typesArray.length; i++) {
      types.push(
        <Option value={typesArray[i].value}>{typesArray[i].label}</Option>
      );
    }
    // setTypesArray(answerTypes);
    setAnswerTypeOptions(types);
  }, []);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const sectionOptionsArray = [];
  // if(radioOption === "") {
  //
  // const sectionOptions = [];
  // }
  //

  if (
    !Props.isNewQuestion &&
    options.length === 0 &&
    questionDataState &&
    questionDataState.questionOptions.length > 0
  ) {
    var getOptions = [];
    for (var i = 0; i < questionDataState.questionOptions.length; i++) {
      console.log(questionDataState.questionOptions[i].option);
      getOptions.push(questionDataState.questionOptions[i].option);

      Props.addOptionValues({
        optionName: questionDataState.questionOptions[i].option,
        mainSection: Props.mainSectionIndex,
        mainQuestion: Props.mainQuestionIndex,
        questionIndex: Props.optQuestionIndex,
        sectionIndex: Props.optSectionIndex,
      });
    }
    setOptions(getOptions);
  }
  if (questionDataState) {
    questionDataState.answerType = questionDataState.answerType.toString();
  }

  const onChangeAnswerType = (value) => {
    setAnswerType(value);
  };

  const onChangeRadio = (e) => {
    console.log("radio1 checked", e.target.value);
    setRadioOption(e.target.value);
    setAddOptionalSection(false);
  };

  const onChangeOptionCheckbox = (value) => {
    setMultiCheckbox(value);
  };

  const onChangeIsRequired = (value) => {
    console.log("checkbox checked", value);
    // setCheckboxOptions(value);
    setAddOptionalSection(false);
    // var checkCascading = value.find((obj) => obj === "cascading");
    // if (checkCascading) {
    //   setCascading(true);
    // } else {
    //   setCascading(false);
    // }
    // var checkMultiple = value.find((obj) => obj === "multiAnswers");
    // if (checkMultiple) {
    //   SetMultiple(true);
    // } else {
    //   SetMultiple(false);
    // }
  };

  const onChangeIsCascading = (value) => {
    console.log("checkbox checked", value);
    // setCheckboxOptions(value);
    setAddOptionalSection(false);
    // var checkCascading = value.find((obj) => obj === "cascading");
    // if (checkCascading) {
    setCascading(value.target.checked);
    // } else {
    // setCascading(false);
    // }
    // var checkMultiple = value.find((obj) => obj === "multiAnswers");
    // if (checkMultiple) {
    //   SetMultiple(true);
    // } else {
    //   SetMultiple(false);
    // }
  };

  const onChangeIsMultiple = (value) => {
    console.log("checkbox checked", value);
    // setCheckboxOptions(value);
    setAddOptionalSection(false);
    // var checkCascading = value.find((obj) => obj === "cascading");
    // if (checkCascading) {
    // setCascading(true);
    // } else {
    // setCascading(false);
    // }
    // var checkMultiple = value.find((obj) => obj === "multiAnswers");
    // if (checkMultiple) {
    SetMultiple(value.target.checked);
    // } else {
    //   SetMultiple(false);
    // }
  };

  if (options.length <= 0) {
    var optionsArray = [];
  }

  const addDropdownOptions = () => {
    if (optionText == "") {
      return false;
    }
    setOptions(options.concat(optionText));
    Props.addDropdownValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex,
      isSubOptional: Props.subOptionalSection,
      subOptSectionIndex: Props.subOptSectionIndex,
      subOptQuestionIndex: Props.subOptQuestionIndex,
    });
    setOptionText("");
  };
  const addRadioOptions = () => {
    if (optionText == "") {
      return false;
    }
    console.log(Props.field, Props.section);
    setOptions(options.concat(optionText));
    Props.addOptionValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex,
      isSubOptional: Props.subOptionalSection,
      subOptSectionIndex: Props.subOptSectionIndex,
      subOptQuestionIndex: Props.subOptQuestionIndex,
    });
    setOptionText("");
  };

  const addCheckboxOptions = () => {
    if (optionText == "") {
      return false;
    }
    setOptions(options.concat(optionText));
    Props.addCheckboxValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex,
      isSubOptional: Props.subOptionalSection,
      subOptSectionIndex: Props.subOptSectionIndex,
      subOptQuestionIndex: Props.subOptQuestionIndex,
    });
    setOptionText("");
  };

  const dropdownOptions = [];

  for (var i = 0; i < options.length; i++) {
    dropdownOptions.push(<Option key={options[i]}>{options[i]}</Option>);
  }

  if (Props.mappParentData && Props.mappParentData.length > 0) {
    for (var i = 0; i < Props.mappParentData.length; i++) {
      mappParentData.push(
        <Option key={JSON.stringify(Props.mappParentData[i])}>
          {Props.mappParentData[i].name}
        </Option>
      );
    }
  }

  // if (Props.mappChildData && Props.mappChildData.length > 0) {
  //   for (var i = 0; i < Props.mappChildData.length; i++) {
  //     mappChildData.push(
  //       <Option key={Props.mappChildData[i].name}>
  //         {Props.mappChildData[i].name}
  //       </Option>
  //     );
  //   }
  // }

  // if (mappedChild.length > 0) {
  //   for (var i = 0; i < mappedChild.length; i++) {
  //     if (parentId == "37" || parentId == "38" || parentId == "39") {
  //       mappChildData.push(
  //         <Option key={mappedChild[i].friendlyName}>
  //           {mappedChild[i].friendlyName}
  //         </Option>
  //       );
  //     } else {
  //       mappChildData.push(
  //         <Option key={mappedChild[i].name}>{mappedChild[i].name}</Option>
  //       );
  //     }
  //   }
  // }

  const onChangeMappingParent = (value) => {
    setPropertyDisable(true);
    setMappedChild([]);
    console.log("on mapping parent", value);

    var parentObj = JSON.parse(value);
    var parent = Props.mappParentData.find(
      (obj) => obj.name === parentObj.name
    );
    setParentId(parent.id);
    if (parent.id == "37" || parent.id == "38" || parent.id == "39") {
      onGetBranchOtherInfo(parent.id);
    } else {
      Props.getMappChild(parent.id).then((res) => {
        console.log(res);
        makePropertiesOpt(res.payload);
        setMappedChild(res.payload);
        setPropertyDisable(false);
      });
    }
  };

  const onGetBranchOtherInfo = (parentid) => {
    const branchOtherInfoOpt = {
      url: `v1/branch/BranchOtherInfo`,
    };
    branchOtherInfoOpt.types = [
      "GET_BRANCH_OTHER_INFO_SUCCESS",
      "GET_BRANCH_OTHER_INFO_FAILURE",
    ];

    apiRefresh
      .get(branchOtherInfoOpt)
      .then((res) => {
        makePropertiesOtherInfoOpt(res.items);
        var findFamily = res.items.filter((obj) => obj.family == true);

        var findPartner = res.items.filter((obj) => obj.partner == true);
        var findClient = res.items.filter(
          (obj) => obj.partner == false && obj.family == false
        );

        if (parentid == "38" && findFamily && findFamily.length > 0) {
          // setFamilyChild(findFamily)

          setMappedChild(findFamily);
          setPropertyDisable(false);
        } else if (parentid == "39" && findPartner && findPartner.length > 0) {
          // setClientPartnerChild(findPartner);

          setMappedChild(findPartner);
          setPropertyDisable(false);
        } else if (parentid == "37" && findClient && findClient.length > 0) {
          // setClientChild(findClient)

          setMappedChild(findClient);
          setPropertyDisable(false);
        } else {
          setPropertyDisable(false);
        }
      })
      .catch((err) => {});
  };

  const makePropertiesOtherInfoOpt = (data) => {
    mappChildData = [];
    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        mappChildData.push(
          <Option value={JSON.stringify(data[i])}>
            {data[i].friendlyName}
          </Option>
        );
      }
    }
    setMappChildDataOpt(mappChildData);
  };

  const makePropertiesOpt = (data) => {
    mappChildData = [];
    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        mappChildData.push(
          <Option value={JSON.stringify(data[i])}>{data[i].name}</Option>
        );
      }
    }
    setMappChildDataOpt(mappChildData);
  };

  const onChangeCheckboxOption = (value) => {
    setCheckboxValue(value);
    setAddOptionalSection(false);
  };

  const selectDropdown = (value) => {
    setOptionSelected(value);
    setAddOptionalSection(false);
  };

  const onAddBranchOtherInfo = () => {
    let data = {
      type: answerType,
      child: false,
      columnName: questionString,
      family: parentId == "38" ? true : false,
      friendlyName: searchString,
      partner: parentId == "39" ? true : false,
    };

    if (
      (questionString != "" || questionString) &&
      (searchString || searchString != "")
    ) {
      setLoading(true);
      const branchOtherInfoOpt = {
        url: `v1/branch/BranchOtherInfo`,
      };
      branchOtherInfoOpt.types = [
        "ADD_BRANCH_OTHER_INFO_SUCCESS",
        "ADD_BRANCH_OTHER_INFO_FAILURE",
      ];

      apiRefresh
        .post(branchOtherInfoOpt, data)
        .then((res) => {
          message.success("Added!");
          mappParentData = [];
          setPropertyDisable(true);
          onGetBranchOtherInfo(parentId);
          setLoading(false);
          setSearchString("");
        })
        .catch((err) => {
          console.log("error=========", err);

          message.error("Failed!");
          setLoading(false);
        });
    } else {
      message.warning("Please enter question name and other info option!");
    }
  };

  const onChangeMappedChildValue = (val) => {
    // setMappChildValue(val);
    var findChild = mappedChild.find((obj) => obj.name == val);

    if (parentId != "37" && parentId != "38" && parentId != "39") {
      if (findChild) {
        //  setChildType(findChild.type);
        var types = [];
        for (var i = 0; i < typesArray.length; i++) {
          if (findChild.type.toLowerCase() == "text") {
            if (
              typesArray[i].value == "1" ||
              typesArray[i].value == "3" ||
              typesArray[i].value == "7"
            ) {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
          } else if (findChild.type.toLowerCase() == "radio") {
            if (typesArray[i].value == "4") {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
            setAnswerType("4");
          } else if (findChild.type.toLowerCase() == "date") {
            if (typesArray[i].value == "2" || typesArray[i].value == "6") {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
            setAnswerType("2");
          }
        }
        setAnswerTypeOptions(types);
      }
    }
  };

  return (
    <div>
      <div
        className="cq-form-cont"
        style={{
          width: "100%",
          marginLeft: 0,
          marginTop: 20,
          padding: 30,
          backgroundColor: "#EEF9FF",
        }}
      >
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <div
            style={{
              cursor: "pointer",
              justifyContent: "flex-end",
              display: "flex",
            }}
            onClick={() => {
              // if (!isOptionSectionAdd) {
              // var findQuestionOptionsIndex = Props.optionsArray.findIndex(
              //   obj =>
              //     obj.mainSection === Props.mainSectionIndex &&
              //     obj.mainQuestion === Props.mainQuestionIndex &&
              //     obj.sectionIndex === Props.optSectionIndex &&
              //     obj.questionIndex === Props.optQuestionIndex
              // );
              // var findQuestionOptions = Props.optionsArray.filter(
              //   obj =>
              //     obj.mainSection === Props.mainSectionIndex &&
              //     obj.mainQuestion === Props.mainQuestionIndex &&
              //     obj.sectionIndex === Props.optSectionIndex &&
              //     obj.questionIndex === Props.optQuestionIndex
              // );
              // if (findQuestionOptionsIndex > -1) {
              //   // Delete options selected questions from main option's array
              //   Props.optionsArray.splice(
              //     findQuestionOptionsIndex,
              //     findQuestionOptions.length
              //   );
              // }
              // if (Props.questionsDataArray.length > 0) {
              //   Props.questionsDataArray.splice(Props.optQuestionIndex, 1);
              // }

              // Remove index from questions array
              Props.questionRemove(Props.optQuestionIndex);
              // console.log(findQuestionOptions);

              // Delete index from array with check question is text or not

              Props.questionsArray.splice(Props.optQuestionIndex, 1);
              // } else {
              //   message.warning(
              //     "Please delete dependent sections and questions first!"
              //   );
              // }
            }}
          >
            <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
          </div>
        </div>
        <div className="question-field-row">
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                Props.questionArrayMove(
                  Props.questionsArray,
                  Props.index,
                  Props.index - 1
                );

                Props.move(Props.index, Props.index - 1);
              }}
              className="section-arrow-icons"
            >
              <UpOutlined />
            </div>

            <div
              onClick={() => {
                Props.questionArrayMove(
                  Props.questionsArray,
                  Props.index,
                  Props.index + 1
                );

                Props.move(Props.index, Props.index + 1);
              }}
              className="section-arrow-icons"
            >
              <DownOutlined />
            </div>
            <p className="cq-label-text">Question</p>
          </div>
          <div style={{ width: "60%" }}>
            <Form.Item
              {...Props.field}
              name={[fieldName, "question"]}
              fieldKey={[fieldKey, "question"]}
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input
                onChange={(e) => setQuestionString(e.target.value)}
                className="profile-input profile-input-border"
              />
            </Form.Item>
          </div>
        </div>
        <div>
          {/* <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChangeCheckbox}
            value={Props.field.checkedOptions}
          > */}
          <div
            style={{
              display: "flex",
              marginTop: 20,
              width: "68%",
            }}
          >
            <div className="sus-checkbox-cont">
              <Form.Item
                {...Props.field}
                name={[fieldName, "isRequired"]}
                fieldKey={[fieldKey, "isRequired"]}
                valuePropName="checked"
              >
                <Checkbox
                  className="sus-checkbox-label"
                  onChange={onChangeIsRequired}
                >
                  Required
                </Checkbox>
              </Form.Item>
            </div>
            {isMultiple ? (
              <div className="sus-checkbox-cont">
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "isCascading"]}
                  fieldKey={[fieldKey, "isCascading"]}
                  valuePropName="checked"
                >
                  <Checkbox disabled className="sus-checkbox-label">
                    cascading
                  </Checkbox>
                </Form.Item>
              </div>
            ) : (
              <div className="sus-checkbox-cont">
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "isCascading"]}
                  fieldKey={[fieldKey, "isCascading"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    className="sus-checkbox-label"
                    onChange={onChangeIsCascading}
                  >
                    cascading
                  </Checkbox>
                </Form.Item>
              </div>
            )}
            {isCascading ? (
              <div className="sus-checkbox-cont">
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "isMultiple"]}
                  fieldKey={[fieldKey, "isMultiple"]}
                  valuePropName="checked"
                >
                  <Checkbox disabled className="sus-checkbox-label">
                    Multiple Answers
                  </Checkbox>
                </Form.Item>
              </div>
            ) : (
              <div className="sus-checkbox-cont">
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "isMultiple"]}
                  fieldKey={[fieldKey, "isMultiple"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    className="sus-checkbox-label"
                    onChange={onChangeIsMultiple}
                  >
                    Multiple Answers
                  </Checkbox>
                </Form.Item>
              </div>
            )}
          </div>
          {/* </Checkbox.Group> */}
        </div>

        {answerType != 8 && (
          <div className="question-field-row" style={{ marginTop: 30 }}>
            <p className="cq-label-text">Mapping Parent</p>
            <div style={{ width: "60%", paddingLeft: 1 }}>
              <div>
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "mapped"]}
                  fieldKey={[fieldKey, "mapped"]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeMappingParent}
                  >
                    {/* <Option value="">Select Mapping Parent</Option> */}
                    {mappParentData}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
        )}
        {(Props.questionData &&
          Props.questionData.mappedProperty &&
          Props.mappChildData) ||
        (Props.questionData && answerType != "8") ? (
          <div className="question-field-row" style={{ marginTop: 30 }}>
            <p className="cq-label-text">Mapping Property</p>
            <div style={{ width: "60%", paddingLeft: 1, display: "flex" }}>
              <div
                style={{
                  width:
                    parentId == "37" || parentId == "38" || parentId == "39"
                      ? `calc(100% - 134px)`
                      : "100%",
                }}
              >
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "mappedProperty"]}
                  fieldKey={[fieldKey, "mappedProperty"]}
                  rules={[
                    { required: parentId ? true : false, message: "Required!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onchange={onChangeMappedChildValue}
                    onSearch={(e) => {
                      if (e != "") setSearchString(e);
                    }}
                    autoClearSearchValue={false}
                  >
                    {/* <Option value="">Select Mapping Child</Option> */}
                    {mappChildDataOpt}
                  </Select>
                </Form.Item>
              </div>
              {(parentId == "37" || parentId == "38" || parentId == "39") && (
                <Button onClick={() => onAddBranchOtherInfo()}>
                  <span className="cq-add-btn">Add Other Info</span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="question-field-row" style={{ marginTop: 30 }}>
            <p className="cq-label-text">Mapping Property</p>
            <div style={{ width: "60%", paddingLeft: 1 }}>
              <div>
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "mappedProperty"]}
                  fieldKey={[fieldKey, "mappedProperty"]}
                  rules={[
                    { required: parentId ? true : false, message: "Required!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeMappedChildValue}
                  >
                    {/* <Option value="">Select Mapping Child</Option> */}
                    {mappChildDataOpt}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
        )}
        <div className="question-field-row" style={{ marginTop: 30 }}>
          <p className="cq-label-text">Answer Type</p>
          <div style={{ width: "60%", paddingLeft: 1 }}>
            <div style={{ paddingLeft: 7 }}>
              <Form.Item
                {...Props.field}
                name={[fieldName, "answerType"]}
                fieldKey={[fieldKey, "answerType"]}
              >
                <Select onChange={onChangeAnswerType} defaultValue="1">
                  <Option value={1}>Textbox</Option>
                  <Option value={2}>Date</Option>
                  <Option value={3}>Drop down list</Option>
                  <Option value={4}>Radio button</Option>
                  <Option value={5}>Checkbox</Option>
                  <Option value={6}>Month</Option>
                  <Option value={7}>Textarea</Option>
                  <Option value={8}>File</Option>
                </Select>
              </Form.Item>
            </div>

            {answerType == 1 && (
              <div
                className="profile-input-border"
                style={{
                  width: "98%",
                  marginTop: 10,
                  marginLeft: 7,
                  paddingLeft: 7,
                }}
              >
                <Form.Item>
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            )}
            {answerType == 2 && (
              <div
                className="profile-input-border"
                style={{
                  border: 0,
                  width: "98%",
                  marginTop: 10,
                  marginLeft: 7,
                }}
              >
                <Form.Item>
                  <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                </Form.Item>
              </div>
            )}
            {false && answerType == 3 && (
              <div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div
                    className="profile-input-border"
                    style={{
                      width: "98%",
                      marginLeft: 7,
                    }}
                  >
                    <div>
                      <Input
                        value={optionText}
                        onChange={(e) => setOptionText(e.target.value)}
                        className="profile-input"
                      />
                    </div>
                  </div>
                  <div>
                    <Button onClick={addDropdownOptions}>
                      <span className="cq-add-btn">ADD</span>
                    </Button>
                  </div>
                </div>
                <Select selectDropdown style={{ marginTop: 10 }}>
                  {dropdownOptions}
                </Select>
              </div>
            )}
            {answerType == 6 && (
              <div
                className="profile-input-border"
                style={{
                  border: 0,
                  width: "98%",
                  marginTop: 10,
                  marginLeft: 7,
                }}
              >
                <Form.Item>
                  <DatePicker style={{ width: "100%" }} format="MM-YYYY" />
                </Form.Item>
              </div>
            )}
            {answerType == 7 && (
              <div
                style={{
                  border: 0,
                  width: "98%",
                  marginTop: 10,
                  marginLeft: 7,
                }}
              >
                <Form.Item>
                  <textarea rows={5} className="cv-textarea" />
                </Form.Item>
              </div>
            )}
            {answerType == 8 && (
              <div
                className="profile-input-border"
                style={{
                  width: "98%",
                  marginTop: 10,
                  marginLeft: 7,
                }}
              >
                <Form.Item>
                  <Input type="file" className="profile-input" />
                </Form.Item>
              </div>
            )}
            {false && answerType == 4 && (
              <div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div
                    className="profile-input-border"
                    style={{
                      width: "98%",
                      marginLeft: 7,
                      paddingLeft: 7,
                    }}
                  >
                    <div>
                      <Input
                        value={optionText}
                        onChange={(e) => setOptionText(e.target.value)}
                        className="profile-input"
                      />
                    </div>
                  </div>
                  <div>
                    <Button onClick={addRadioOptions}>
                      <span className="cq-add-btn">ADD</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {false && answerType == 5 && (
              <div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div
                    className="profile-input-border"
                    style={{
                      width: "98%",
                      marginLeft: 7,
                    }}
                  >
                    <div>
                      <Input
                        value={optionText}
                        onChange={(e) => setOptionText(e.target.value)}
                        className="profile-input"
                      />
                    </div>
                  </div>
                  <div>
                    <Button onClick={addCheckboxOptions}>
                      <span className="cq-add-btn">ADD</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <>
          {/* {answerType === "4" && (
            <div style={{ display: "flex", marginTop: 20 }}>
              <Radio.Group onChange={onChangeRadio} value={radioOption}>
                {options.length > 0 &&
                  options.map((option, index) => {
                    return <Radio value={option}>{option}</Radio>;
                  })}
              </Radio.Group>
            </div>
          )} */}
          {/* {answerType === "5" && (
            <div style={{ display: "flex" }}>
              <Checkbox.Group onChange={onChangeCheckboxOption}>
                {options.length > 0 &&
                  options.map((option, index) => {
                    return <Checkbox value={option}>{option}</Checkbox>;
                  })}
              </Checkbox.Group>
            </div>
          )} */}
          {/* {false && options.length > optionValues.length ? add() : null} */}
        </>

        <>
          <Form.List name={[Props.field.name, "questionOptions"]}>
            {(questionOptions, { add, remove }, { errors }) => {
              return (
                <>
                  {answerType == "3" && (
                    <div>
                      <div
                        className="question-field-row"
                        style={{ justifyContent: "flex-end", marginTop: 10 }}
                      >
                        <div
                          className="profile-input-border"
                          style={{
                            width: "49%",
                            marginRight: 5,
                          }}
                        >
                          <div>
                            <Input
                              value={optionText}
                              onChange={(e) => setOptionText(e.target.value)}
                              className="profile-input"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              addDropdownOptions();
                              if (optionText != "") {
                                add({ option: optionText });
                              }
                              setOptionText("");
                            }}
                          >
                            <span className="cq-add-btn">ADD</span>
                          </Button>
                        </div>
                      </div>
                      <div
                        className="question-field-row"
                        style={{ justifyContent: "flex-end", marginTop: 10 }}
                      >
                        <Select
                          onChange={selectDropdown}
                          style={{ marginTop: 10, width: "59%" }}
                        >
                          {dropdownOptions}
                        </Select>
                      </div>
                    </div>
                  )}
                  {answerType == "4" && (
                    <>
                      <div>
                        <div
                          className="question-field-row"
                          style={{ justifyContent: "flex-end", marginTop: 10 }}
                        >
                          <div
                            className="profile-input-border"
                            style={{
                              width: "49%",
                              marginLeft: 7,
                              marginRight: 5,
                            }}
                          >
                            <div>
                              <Input
                                value={optionText}
                                onChange={(e) => setOptionText(e.target.value)}
                                className="profile-input"
                              />
                            </div>
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                addRadioOptions();
                                if (optionText != "") {
                                  add({ option: optionText });
                                }
                                setOptionText("");
                              }}
                            >
                              <span className="cq-add-btn">ADD</span>
                            </Button>
                          </div>
                        </div>
                        <div
                          className="options-cont"
                          style={{ display: "flex", marginTop: 20 }}
                        >
                          <Radio.Group
                            onChange={onChangeRadio}
                            value={radioOption}
                          >
                            {options.length > 0 &&
                              options.map((option, index) => {
                                return <Radio value={option}>{option}</Radio>;
                              })}
                          </Radio.Group>
                        </div>
                      </div>
                    </>
                  )}
                  {answerType == "5" && (
                    <div>
                      <div
                        className="question-field-row"
                        style={{ justifyContent: "flex-end", marginTop: 10 }}
                      >
                        <div
                          className="profile-input-border"
                          style={{
                            width: "49%",
                            marginLeft: 7,
                            marginRight: 5,
                          }}
                        >
                          <div>
                            <Input
                              value={optionText}
                              onChange={(e) => setOptionText(e.target.value)}
                              className="profile-input"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              addCheckboxOptions();
                              if (optionText != "") {
                                add({ option: optionText });
                              }
                              setOptionText("");
                            }}
                          >
                            <span className="cq-add-btn">ADD</span>
                          </Button>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: 20 }}>
                        <Checkbox.Group onChange={onChangeCheckboxOption}>
                          {options.length > 0 &&
                            options.map((option, index) => {
                              return (
                                <Checkbox value={option}>{option}</Checkbox>
                              );
                            })}
                        </Checkbox.Group>
                      </div>
                    </div>
                  )}
                  {questionOptions.map((option, optIndex) => {
                    return (
                      <>
                        {false && (
                          <Form.Item required={false} key={option.key}>
                            <Form.Item
                              {...option}
                              name={[option.name, "option"]}
                              fieldKey={[option.key, "option"]}
                              // value={options[0]}
                            >
                              {/* <Radio /> */}
                              {/* <Input /> */}
                            </Form.Item>
                          </Form.Item>
                        )}
                        <Form.List name={[option.name, "optionalQuestions"]}>
                          {(
                            optionalQuestions,
                            { add, remove, move },
                            { errors }
                          ) => {
                            if (
                              (((questionDataState &&
                                questionDataState.isCascading) ||
                                isCascading) &&
                                radioOption === options[optIndex]) ||
                              (checkboxValue.length > 0 &&
                                checkboxValue[checkboxValue.length - 1] ===
                                  options[optIndex]) ||
                              optionSelected === options[optIndex]
                            ) {
                              if (
                                optionalQuestions.length === 0 &&
                                isOptionSectionAdd &&
                                optionalQuestions.length < 1
                              ) {
                                setIsOptionSectionAdd(false);
                              }
                              if (!addOptionalSection) {
                                //
                                add();
                                setAddOptionalSection(true);
                              }
                            }
                            // else if (
                            //   ((questionDataState &&
                            //     questionDataState.isCascading) ||
                            //     isCascading) &&
                            //   isOptionSectionAdd &&
                            //   optionalQuestions.length < 1 &&
                            //     questionDataState &&
                            //       questionDataState.questionOptions[optIndex]
                            //         .optionalQuestions.length < 1
                            // ) {
                            //
                            //   setIsOptionSectionAdd(false);
                            // }

                            return (
                              <>
                                {optionalQuestions.map((section, index) => {
                                  console.log(
                                    optionalQuestions.length,
                                    sectionOptions
                                  );
                                  // if (!isOptionSectionAdd) {
                                  //   setIsOptionSectionAdd(true);
                                  // }

                                  if (!questionDataState) {
                                    var optionName = options[
                                      optIndex
                                    ].toUpperCase();
                                  } else if (
                                    options.length >
                                    questionDataState.questionOptions.length
                                  ) {
                                    var optionName = options[
                                      optIndex
                                    ].toUpperCase();
                                  } else if (questionDataState) {
                                    var optionName = questionDataState.questionOptions[
                                      optIndex
                                    ].option.toUpperCase();
                                  }

                                  // if (
                                  //   sectionOptions.length <
                                  //   optionalQuestions.length
                                  // ) {
                                  //   if (
                                  //     index ===
                                  //     optionalQuestions.length - 1
                                  //   ) {
                                  //     // setSectionOptions((prevState) => [
                                  //     //   ...prevState,
                                  //     //   {
                                  //     //     optionName:
                                  //     //       questionDataState.questionOptions[
                                  //     //         optIndex
                                  //     //       ].option,
                                  //     //     sectionInd: optIndex,
                                  //     //     mainQuestionIndex:
                                  //     //       Props.mainQuestionIndex,
                                  //     //     mainSectionIndex:
                                  //     //       Props.mainSectionIndex,
                                  //     //   },
                                  //     // ]);
                                  //   }
                                  // }
                                  return (
                                    <Form.Item
                                      required={false}
                                      key={section.key}
                                    >
                                      <UpdateSectionCard
                                        section={section}
                                        optSectionIndex={index}
                                        sectionIndex={index}
                                        sectionMove={move}
                                        sectionData={
                                          questionDataState &&
                                          questionDataState.isCascading &&
                                          optIndex <
                                            questionDataState.questionOptions
                                              .length
                                            ? questionDataState.questionOptions[
                                                optIndex
                                              ].optionalQuestions[index]
                                            : null
                                        }
                                        addDropdownValues={
                                          Props.addDropdownValues
                                        }
                                        sectionOptions={optionName}
                                        options={options}
                                        addQuestion={addQuestion}
                                        addSectionOptions={
                                          Props.addSectionOptions
                                        }
                                        dataByUpdate={dataByUpdate}
                                        optionIndex={optIndex}
                                        mainSectionIndex={
                                          Props.mainSectionIndex
                                        }
                                        mainQuestionIndex={
                                          Props.mainQuestionIndex
                                        }
                                        addOptionValues={Props.addOptionValues}
                                        optionalSection={true}
                                        addCheckboxValues={
                                          Props.addCheckboxValues
                                        }
                                        sectionRemove={remove}
                                        mappParentData={Props.mappParentData}
                                        getMappChild={Props.getMappChild}
                                      />
                                    </Form.Item>
                                  );
                                })}
                                {addQuestion && setAddQuestion(false)}
                                {false && isCascading && radioOption && (
                                  <Form.Item>
                                    <div style={{ display: "flex" }}>
                                      {false && (
                                        <Button
                                          type="dashed"
                                          onClick={() => {
                                            setDataByUpdate(false);
                                            add();
                                          }}
                                          className="cq-orng-btn"
                                          style={{
                                            marginTop: 20,
                                            marginRight: 10,
                                          }}
                                        >
                                          <span className="cq-btn-text">
                                            Add Section
                                          </span>
                                        </Button>
                                      )}

                                      {/*======= Need to finalize======*/}

                                      {/* <Button
                                        type="dashed"
                                        className="cq-orng-btn"
                                        style={{
                                          marginTop: 20,
                                          marginRight: 10
                                        }}
                                      >
                                        <span className="cq-btn-text">
                                          Add Text
                                        </span>
                                      </Button>
                                      <Button
                                        type="dashed"
                                        className="cq-orng-btn"
                                        onClick={() => {
                                          setAddQuestion(true);
                                          setDataByUpdate(false);
                                          add();
                                        }}
                                        style={{ marginTop: 20 }}
                                      >
                                        <span className="cq-btn-text">
                                          Add Quesstion(s)
                                        </span>
                                      </Button> */}
                                      <Form.ErrorList errors={errors} />
                                    </div>
                                  </Form.Item>
                                )}
                              </>
                            );
                          }}
                        </Form.List>
                      </>
                    );
                  })}
                </>
              );
            }}
          </Form.List>
        </>
        {/*<Form.List name={[Props.field.name, "optionalQuestions"]}>
            {(optionalQuestions, { add, remove }, { errors }) => {
              if (isOptionSectionAdd && optionalQuestions.length < 1) {
                setIsOptionSectionAdd(false);
              }
              if (isCascading) {
                if (
                  optionalQuestions.length === 0 &&
                  !addOptionalSection &&
                  (radioOption || checkboxValue.length === 1 || optionSelected)
                ) {
                  add();
                  setAddOptionalSection(true);
                } else if (
                  optionalQuestions.length > 0 &&
                  !addOptionalSection
                ) {
                  var findOption = sectionOptions.find(
                    (obj) => obj.optionName === radioOption.toUpperCase()
                  );
                  if (!findOption) {
                    setAddOptionalSection(true);
                    add();
                  }
                }
              }
              return (
                <>
                  {optionalQuestions.map((section, index) => {
                    console.log(optionalQuestions.length, sectionOptions);
                    if (sectionOptions.length < optionalQuestions.length) {
                      if (index === optionalQuestions.length - 1) {
                        setSectionOptions(
                          sectionOptions.concat({
                            optionName:
                              checkboxValue.length > 0
                                ? checkboxValue[
                                    checkboxValue.length - 1
                                  ].toUpperCase()
                                : optionSelected
                                ? optionSelected.toUpperCase()
                                : radioOption.toUpperCase(),
                            sectionInd: index,
                            mainQuestionIndex: Props.mainQuestionIndex,
                            mainSectionIndex: Props.mainSectionIndex,
                            optSectionIndex: Props.optSectionIndex,
                            optQuestionIndex: Props.optQuestionIndex,
                          })
                        );
                      }
                    }

                    return (
                      <Form.Item required={false} key={section.key}>
                        <UpdateSectionCard
                          section={section}
                          optSectionIndex={Props.optSectionIndex || index}
                          subOptsectionIndex={
                            Props.optionalSection ? index : null
                          }
                          optQuestionIndex={
                            Props.optionalSection
                              ? Props.optQuestionIndex
                              : null
                          }
                          subOptionalSection={
                            Props.optionalSection ? true : false
                          }
                          subOptSectionIndex={
                            Props.optionalSection ? index : null
                          }
                          sectionIndex={index}
                          sectionOptions={sectionOptions}
                          options={options}
                          addQuestion={addQuestion}
                          addSectionOptions={Props.addSectionOptions}
                          dataByUpdate={dataByUpdate}
                          mainSectionIndex={Props.mainSectionIndex}
                          mainQuestionIndex={Props.mainQuestionIndex}
                          addOptionValues={Props.addOptionValues}
                          optionalSection={true}
                          isNewQuestion={Props.isNewQuestion}
                          optionsArray={Props.optionsArray}
                          addCheckboxValues={Props.addCheckboxValues}
                        />
                      </Form.Item>
                    );
                  })}
                  {addQuestion && setAddQuestion(false)}
                  {false && isCascading && radioOption && (
                    <Form.Item>
                      <div style={{ display: "flex" }}>
                        <Button
                          type="dashed"
                          onClick={() => {
                            setDataByUpdate(false);
                            add();
                          }}
                          className="cq-orng-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Section</span>
                        </Button>

                        {/*======= Need to finalize======

                        <Button
                          type="dashed"
                          className="cq-orng-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Text</span>
                        </Button>
                        <Button
                          type="dashed"
                          className="cq-orng-btn"
                          onClick={() => {
                            setAddQuestion(true);
                            setDataByUpdate(false);
                            add();
                          }}
                          style={{ marginTop: 20 }}
                        >
                          <span className="cq-btn-text">Add Quesstion(s)</span>
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.List>*/}
      </div>
    </div>
  );
}

export default UpdateQuestionCard;
