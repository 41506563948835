import React from "react";
import { connect } from "react-redux";
import { createCode, createToken, getXeroToken } from "../store/Actions";
import { Button, Spin, message, Row, Col } from "antd";
import { bindActionCreators } from "redux";
import { Redirect, Switch, withRouter } from "react-router-dom";
import { apiRefresh } from "../services/api";

class Xero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.props
      .getXeroToken()
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        if (err.payload && err.payload.response.status === 400) {
          message.error(err.payload && err.payload.response.data);
        }
        this.setState({ loading: false });
      });
  }

  getComponents = () => {
    if (this.props.isExists && !this.state.loading) {
      if (
        window.location.pathname === "/xero-sync-add-account" &&
        this.props.isExists.isExists === true
      ) {
        this.props.history.push("/xero-sync-invoices");
      } else if (
        window.location.pathname === "/xero-sync" &&
        this.props.isExists.isExists === true
      ) {
        this.props.history.push("/xero-sync-invoices");
      } else if (
        window.location.pathname === "/xero-sync-invoices" &&
        this.props.isExists.isExists === true
      ) {
        this.props.history.push("/xero-sync-invoices");
      } else if (
        window.location.pathname === "/xero-sync-add-account" &&
        this.props.isExists.isExists === false
      ) {
        this.props.history.push("/xero-sync-add-account");
      } else if (
        window.location.pathname === "/xero-sync" &&
        this.props.isExists.isExists === false
      ) {
        this.props.history.push("/xero-sync-add-account");
      } else if (
        window.location.pathname === "/xero-sync-invoices" &&
        this.props.isExists.isExists === false
      ) {
        this.props.history.push("/xero-sync-add-account");
      } else {
      }
    }
  };

  render() {
    return (
      <div>
        <Spin size="large" spinning={this.state.loading}>
          <Row>
            <Col style={{ padding: 30 }}>
              <Button
                className="remove-xero-account"
                onClick={() => {
                  const remXeroAcc = {
                    url: `v1/xero/RemoveXeroAccount`,
                  };
                  remXeroAcc.types = [
                    "REMOVE_XERO_ACCOUNT_SUCCESS",
                    "REMOVE_XERO_ACCOUNT_FAILURE",
                  ];
                  this.setState({ loading: true });
                  apiRefresh
                    .delete(remXeroAcc)
                    .then((res) => {
                      this.setState({ loading: false });
                      getXeroToken();
                      this.props.history.push("/xero-sync-add-account");
                    })
                    .catch((err) => {
                      this.setState({ loading: false });
                    });
                }}
              >
                Remove Account
              </Button>
            </Col>
          </Row>
          {this.getComponents()}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isExists: state && state.xeroReducer && state.xeroReducer.isExists,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getXeroToken: bindActionCreators(getXeroToken, dispatch),
});

Xero = connect(mapStateToProps, mapDispatchToProps)(Xero);

export default withRouter(Xero);
