import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderBarTop from "../Components/Theme";

import { closeAndOpenSidebar } from "../store/Actions";

const mapStateToProps = state => {
  return {
    sideBarRes: state.employerManagReducer.sideBarRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseAndOpenSidebar: bindActionCreators(closeAndOpenSidebar, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBarTop);
