import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  message,
  Col,
  Form,
  Button,
  Spin,
  Select,
  DatePicker,
  Input,
  Modal,
  Tag,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { yyyymmddFormate } from "../../Common/reUseFunction";
import moment from "moment";
import renderHTML from "react-render-html";
import "../Task/Task.css";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const regex = /(<([^>]+)>)/gi;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
let re = /&nbsp;/g;
let userName = localStorage.getItem("userName");

const FileNotesList = ({
  onGetLetterTemplates,
  LetterTemplatesRes,

  potentialClientInfo,

  onDeleteClientFile,

  item,
  index,

  onUpdateClientFile,

  setLoading,
  loading,

  onGetClientFile,
  employerDocumentRes,
  users,
  onAddDailyTasks,
  updateFileNoteState,
}) => {
  const [fileNoteData, setFileNoteData] = useState(
    false
    // employerDocumentRes && employerDocumentRes.fileNote
  );
  const [editFileNote, setEditFileNote] = useState();
  const [noteFileData, setNoteFileData] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [addTaskLoader, setAddTaskLoader] = useState(false);
  const [fileNoteValue, setFileNoteValue] = useState(null);

  useEffect(() => {
    let html = item.FileNote;
    let div = document.createElement("div");
    div.innerHTML = html;
    let text = div.textContent || div.innerText || "";
    setFileNoteValue(text);
  });

  const formRef = useRef();

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const handleEditNote = (model) => {
    setEditFileNote(model);
    setFileNoteData(model);
  };

  const handleFileEditData = (value) => {
    console.log("Thisisdemodata---==========", item);
    setNoteFileData(!noteFileData);
    setFileNoteData(item.FileNote);
  };

  const removeFileNotes = () => {
    setLoading(true);

    const remove = {
      id: item && item.Id,
      delete: true,
    };

    onDeleteClientFile(remove).then(() => {
      updateFileNoteState(true, index);
      setLoading(false);
    });

    message.success("Successfully Deleted!");
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form:", values);

    setLoading(true);
    const update = {
      id: item && item.Id,
      clientId: potentialClientInfo && potentialClientInfo.id,
      fileNote: editFileNote && editFileNote,
    };
    onUpdateClientFile(update).then(() => {
      setNoteFileData(!noteFileData);
      setFileNoteData("");
      updateFileNoteState(false, index, editFileNote);
    });
    setEditFileNote(false);
    message.success("Successfully Updated!");
    setLoading(false);
  };

  const onSubmit = (values) => {
    setAddTaskLoader(true);
    let followers = [];
    const userId = localStorage.getItem("userId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: potentialClientInfo
        ? potentialClientInfo.id
        : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: false,
      isCompleted: false,
    };
    onAddDailyTasks(data)
      .then((res) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        setTaskModal(false);
      })
      .catch((error) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Unable to Add Task!");
      });
  };

  const renderAddTaskModal = () => (
    <Modal
      title="Add Task"
      visible={taskModal}
      onCancel={() => setTaskModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={addTaskLoader}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          ref={formRef}
          {...layout}
        >
          <div className="add-tasks">
            <div>
              <div className="mdtr-modal-gray-cont">
                <div className="modal-parts">
                  <Form.Item
                    className="form-parts"
                    label="Task Title"
                    name="task_title"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="form-parts"
                    label="Task Description"
                    name="task_description"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    initialValue={fileNoteValue}
                  >
                    <Input.TextArea rows={6} showCount maxLength={250} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts"
                    label="Select Date"
                    name="select_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts multi-select-option"
                    label="Add Followers"
                    name="add_followers"
                    // rules={[{ message: "Please Add Followers!" }]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      // options={users}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >{users}</Select>
                  </Form.Item>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      className="task-blue"
                      htmlType="submit"
                    >
                      ADD TASK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
  let html = item.FileNote;
  let regex = /\S+[a-z0-9]@[a-z0-9\.]+/gim;
  let resultArr = html && html.match(regex);
  if (resultArr && resultArr.length > 0) {
    for (let ind = 0; ind < resultArr.length; ind++) {
      if (resultArr[ind][0] === "<") {
        let filteredValue = resultArr[ind].replace(/</g, "< ");
        html = html.replace(resultArr[ind], filteredValue);
      } else if (resultArr[ind][0] === "<" && resultArr[ind][1] === "/") {
        let filteredValue = resultArr[ind].replace("/", " ");
        html = html.replace(resultArr[ind], filteredValue);
      } else {
        let filteredValue = resultArr[ind].replace(/</g, "< ");
        html = html.replace(resultArr[ind], filteredValue);
      }
    }
  }
  let gap = html;

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <Row
            className={
              index % 2 === 0
                ? "file-note-dark margin-top space-between d-flex"
                : "file-note-light margin-top space-between d-flex"
            }
            key={item.Id}
          >
            <div style={{ width: "92%" }}>
              <div className="float-left d-flex width-100">
                <p style={{ fontSize: 12, marginRight: "10px" }}>
                  {moment(item.CreatedDate).format("DD MMM YYYY hh:mm A") + " "}
                  {/* {yyyymmddFormate(item.createdDate)} */}
                </p>
                <p style={{ fontSize: 12, color: "#36b1e4" }}>
                  {item.FullName}
                </p>
              </div>
              <div>{renderHTML(gap)}</div>
              {/*<div*/}
              {/*    className="float-left width-100"*/}
              {/*    style={{ textAlign: "left" }}*/}
              {/*>*/}
              {/*  <p style={{ color: "#1081b9", fontSize: 12 }}>*/}
              {/*    {item.fileNote.replace(regex, "").replace(re, "")}*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*<p style={{ fontSize: 12, color: "#36b1e4" }}>{userName}</p>*/}
              {/*<p style={{ color: "#1081b9", fontSize: 12 }}>*/}
              {/* {item.fileNote.replace(regex, "")}*/}
              {/*</p>*/}
            </div>
            <div>
              <div className="table-action">
                <EditOutlined onClick={() => handleFileEditData()} />
                <PlusOutlined
                  style={{ marginLeft: 3, marginRight: 3 }}
                  onClick={() => {
                    setTaskModal(true);
                  }}
                />
                <DeleteOutlined onClick={() => removeFileNotes()} />
              </div>
            </div>
          </Row>
          {noteFileData === true && (
            <div>
              <Row>
                <Col span={24}>
                  <div className=" margin-top  letter-froala">
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={fileNoteData && fileNoteData}
                      onModelChange={(value) => handleEditNote(value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ justifyContent: "flex-end" }}>
                <Form.Item className="d-flex ">
                  <Button
                    className="employer-btn"
                    style={{ marginTop: "12px" }}
                    type="primary"
                    htmlType="submit"
                    disabled={editFileNote === ""}
                  >
                    UPDATE
                  </Button>
                </Form.Item>
              </Row>
            </div>
          )}
        </Form>
      </Spin>
      {renderAddTaskModal()}
    </Fragment>
  );
};

export default FileNotesList;
