import React, { Fragment } from "react";
import { Table, Button, Spin } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";
var http = window.location.host == "localhost:3000" ? "http://" : "https://";
var URL = http + window.location.host + "/CheckList/index.htm?param=";
const columns = [
  {
    title: "DOCUMENT CHECKLIST",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "EZM ID",
    dataIndex: "ezmId",
    key: "ezmId",
    // render: (text, row, index) => {
    //   //   if (index == 0) {
    //   return <a>{text}</a>;
    //   //   }
    //   //   return {
    //   //     children: <a>{text}</a>,
    //   //     props: {
    //   //       colSpan: 6,
    //   //     },
    //   //   };
    // },
  },
  {
    title: "CLIENT NAME",
    dataIndex: "fullName",
    key: "fullName",
  },

  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
  },
  // {
  //   title: "EMAIL",
  //   dataIndex: "email",
  //   key: "email",
  // },
  {
    title: "STATUS",
    dataIndex: "isSubmitted",
    key: "isSubmitted",
    render: (text, row, index) => {
      return <span>{text ? "Submitted" : "Pending"}</span>;
    },
  },
  {
    title: "",
    dataIndex: "link",
    key: "link",
    render: (text, row, index) => {
      return (
        <a href={URL + text} target="_blank">
          LINK
        </a>
      );
    },
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class DocChecklistTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 20,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
      loading: false,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson:
            this.props.requestData.processingIds ||
            "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
          subjectId:
            this.props.requestData.selectedClient ||
            "00000000-0000-0000-0000-000000000000",
          clientStatus: this.props.requestData.clientStatus || "All",
        };
        this.setState({ loading: true });
        this.props
          .onDocChecklistReport(data)
          .then(() => {
            this.setState(
              {
                defaultCurrent: 1,
                loading: false,
                //   branchId: this.state.branchId,
              },
              () => {
                console.log("state", this.state);
              }
            );
          })
          .catch((er) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  clientEmployerExport = () => {
    let data = {
      processingPerson:
        this.props.processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: 0,
      pageNumber: 0,
    };
    this.setState({ loading: true });
    const visaExpiryOpt = {
      url: `v1/report/ClientEmployerExport`,
    };
    visaExpiryOpt.types = [
      "VISA_EXPIRY_REPORT_SUCCESS",
      "VISA_EXPIRY_REPORT_FAILURE",
    ];

    apiRefresh
      .postWithFileRes(visaExpiryOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Client_Employer_Report.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { clientEmployerReportRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          {/* <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
            <Button
              className="button-blue"
              style={{ marginTop: 10 }}
              onClick={this.clientEmployerExport}
              loading={this.state.loading}
            >
              Export
            </Button>
          </div> */}
          <Spin spinning={this.state.loading}>
            <Table
              columns={columns}
              dataSource={clientEmployerReportRes && clientEmployerReportRes}
              pagination={false}
              className="border-3 table-head"
              bordered
              onChange={this.paginate}
              pagination={{
                defaultCurrent: this.state.defaultCurrent,
                total: this.props.reportsCount,
                defaultPageSize: 20,
              }}
              scroll={{ x: "auto" }}
            />
          </Spin>
        </div>
      </Fragment>
    );
  }
}
export default DocChecklistTable;
