import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const outgoingPaymentsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_OUTGOING_PAYMENTS_LISTING_SUCCESS:
      return { ...state, outgoingPaymentsListing: action.payload };
    case types.GET_OUTGOING_PAYMENTS_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Payment list is empty!");
        return { ...state, outgoingPaymentsListing: [] };
      } else {
         
      }
      console.log("on error console", action.payload);
    case types.GET_OUTGOING_PAYMENT_INFO_SUCCESS:
      return { ...state, outgoingPaymentInfo: action.payload };
    case types.GET_OUTGOING_PAYMENT_INFO_FAILURE:
       
      console.log("on error console", action.payload);
    case types.GET_PAYMENT_SOURCES_LISTING_SUCCESS:
      return { ...state, paymentSourcesListing: action.payload };
    case types.GET_PAYMENT_SOURCES_LISTING_FAILURE:
       
      console.log("on error console", action.payload);
    case types.GET_OUTGOING_PAYMENT_SOURCE_INFO_SUCCESS:
      return { ...state, outgoingPaymentSourceInfo: action.payload };
    default:
      return state;
  }
};
