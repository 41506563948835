import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Companies from "../Admin/Companies";

import { onGetCompanies, onGetBranches } from "../store/Actions";
import AdminSidebar from "../Components/AdminSideBar";

const mapStateToProps = state => {
  return {
    companiesRes: state.AdminReducer.companiesRes,
    branchesRes: state.AdminReducer.branchesRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCompanies: bindActionCreators(onGetCompanies, dispatch),
    onGetBranches: bindActionCreators(onGetBranches, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);