import React, { useEffect, useState, Fragment } from "react";
import { Collapse, Avatar, Card, Modal } from "antd";
import moment from "moment";
import ReactPlayer from "react-player";

import { ModuleLinks } from "../Themes";

const { Panel } = Collapse;
const { Meta } = Card;

const Videos = () => {
  const [visible, setVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  useEffect(() => {}, []);

  const onChange = (key) => {
    console.log(key);
  };

  const panelStyle = {
    marginBottom: 15,
    backgroundColor: "#D1EAF2",
    fontSize: 18,
    padding: `12px 16px`,
  };
  return (
    <Fragment>
      <div className="page-container" style={{ padding: 25 }}>
        {ModuleLinks.map((module, ind) => {
          return (
            <Collapse onChange={onChange}>
              <Panel header={module.name} key={ind + 1} style={panelStyle}>
                <div style={{ display: "inline-block" }}>
                  {module.links.map((link, i) => (
                    <div
                      style={{ display: "inline-block", margin: 5 }}
                      // onClick={() => window.open(link.url, "_blank")}
                      onClick={() => {
                        setLinkUrl(link.url);
                        setVisible(true);
                        setIsPlaying(true);
                      }}
                    >
                      <Card
                        style={{
                          width: 290,
                          cursor: "pointer",
                        }}
                        cover={<img alt="" src={link.thumbNail} />}
                        actions={
                          [
                            // <SettingOutlined key="setting" />,
                            // <EditOutlined key="edit" />,
                            // <EllipsisOutlined key="ellipsis" />,
                          ]
                        }
                      >
                        <Meta
                          // avatar={
                          //   <Avatar src="https://joeschmoe.io/api/v1/random" />
                          // }
                          //   title={link.topic}
                          description={link.topic}
                        />
                      </Card>
                      {/* <ReactPlayer url={link.url} /> */}
                    </div>
                  ))}
                </div>
              </Panel>
              {/* <Panel
                showArrow={false}
                header="This is panel header with no arrow icon"
                key="2"
              >
                {/* <p>{text}</p> 
              </Panel> */}
            </Collapse>
          );
        })}
      </div>
      <Modal
        title="Tutorial"
        visible={visible}
        // onOk={handleOk}
        onCancel={() => {
          setIsPlaying(false);
          setLinkUrl("");
          setTimeout(() => {
            setVisible(false);
          }, 500);
        }}
        header={false}
        footer={false}
        width={690}
      >
        <ReactPlayer
          url={linkUrl}
          // controls={isPlaying}
          playing={isPlaying}
          muted={!isPlaying}
        />
      </Modal>
    </Fragment>
  );
};

export default Videos;
