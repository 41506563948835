import React from "react";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
} from "antd";
import { bindActionCreators } from "redux";
import { getIncomingOutgoingPayments, setActiveKey } from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ProfileSideBar from "../Components/ProfileSideBar";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class AccountsFlow extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    super(props);
    this.state = {
      showForm: "listing",
      accountsFlowListing: [],
      dateFrom: "1900-01-01T00:00:00+00",
      dateTo: "1900-01-01T00:00:00+00",
      loading: false,
      clientprofileid: clientprofileid,
      totalIncoming: 0,
      totalOutgoing: 0,
    };
  }

  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.date).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Source/Bank",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
  ];

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getIncomingOutgoingPayments(this.state).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _accountsFlowListing = nextProps && nextProps.accountsFlowListing;
    let _totalIncoming = 0;
    let _totalOutgoing = 0;
    if (_accountsFlowListing && _accountsFlowListing.length > 0) {
      for (let index = 0; index < _accountsFlowListing.length; index++) {
        if (_accountsFlowListing[index].type === "incoming") {
          _totalIncoming = _totalIncoming + _accountsFlowListing[index].amount;
        } else if (_accountsFlowListing[index].type === "outgoing") {
          _totalOutgoing = _totalOutgoing + _accountsFlowListing[index].amount;
        } else {
          //do-nothing
        }
      }
    }

    this.setState({
      accountsFlowListing: _accountsFlowListing,
      totalIncoming: _totalIncoming,
      totalOutgoing: _totalOutgoing,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  show = () => {
    if (
      this.state.dateFrom === "1900-01-01T00:00:00+00" ||
      this.state.dateFrom === "Invalid date"
    ) {
      message.error("Please select date from!");
    } else if (
      this.state.dateTo === "1900-01-01T00:00:00+00" ||
      this.state.dateTo === "Invalid date"
    ) {
      message.error("Please select date to!");
    } else {
      this.props.getIncomingOutgoingPayments(this.state).then(() => {});
    }
  };

  showAll = () => {
    this.setState(
      {
        loading: true,
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
      },
      () => {
        this.props.getIncomingOutgoingPayments(this.state).then(() => {
          this.setState({
            loading: false,
            dateFrom: "1900-01-01T00:00:00+00",
            dateTo: "1900-01-01T00:00:00+00",
          });
        });
      }
    );
  };

  getListingView = () => {
    return (
      <div>
        <div className="ca-gray-cont">
          <Row>
            <Col xs={15}>
              <Row>
                <Col xs={8} offset={1}>
                  <DatePicker
                    format={dateFormat}
                    placeholder={"SELECT DATE FROM"}
                    onChange={this.handleChangeDateFrom}
                  />
                </Col>
                <Col xs={7} offset={3}>
                  <DatePicker
                    placeholder={"SELECT DATE TO"}
                    format={dateFormat}
                    onChange={this.handleChangeDateTo}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={10} offset={11}>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      onClick={this.show}
                      style={{ marginRight: "4px" }}
                    >
                      SEARCH
                    </Button>
                    <Button type="primary" onClick={this.showAll}>
                      SHOW ALL
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="ca-gray-cont"
          style={{ padding: 20, paddingBottom: 20 }}
        >
          <Row>
            <Col xs={24} className={"school-table accounts-flow-table"}>
              <Table
                dataSource={
                  this.state.accountsFlowListing
                    ? this.state.accountsFlowListing
                    : null
                }
                rowClassName={(record, index) =>
                  record.type === "incoming"
                    ? "incoming-payments-table-row"
                    : "outgoing-payments-table-row"
                }
                columns={this.columns}
                pagination={false}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24}>
            <div style={{ display: "block", marginLeft: "25px" }}>
              <p>{`TOTAL OUTGOING : ${this.state.totalOutgoing}`}</p>
              <p>{`TOTAL INCOMING : ${this.state.totalIncoming}`}</p>
              <p>{`DIFFERENCE : ${this.state.totalIncoming -
                this.state.totalOutgoing}`}</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div>
              {this.state.showForm === "listing" ? this.getListingView() : null}
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.accountReducer &&
    state.accountReducer.accountsFlowListing &&
    state.accountReducer.accountsFlowListing;

  return {
    accountsFlowListing:
      state &&
      state.accountReducer &&
      state.accountReducer.accountsFlowListing &&
      state.accountReducer.accountsFlowListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getIncomingOutgoingPayments: bindActionCreators(
    getIncomingOutgoingPayments,
    dispatch
  ),
});
AccountsFlow = connect(mapStateToProps, mapDispatchToProps)(AccountsFlow);

export default withRouter(AccountsFlow);
