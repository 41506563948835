import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import { Images } from "../Themes";
import { bindActionCreators } from "redux";
import {
  getPaymentSourcesList,
  setOutgoingPaymentSourceInfo,
  deleteOutgoingPaymentSource,
} from "../store/Actions";
import { connect } from "react-redux";
import Banks from "./Banks";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddSource from "./AddSource";
const { Option } = Select;

class OutgoingPaymentSources extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      sources: [],
      branchId: selectedBranchId,
      visibleAddSourceModal: false,
      visibleDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getPaymentSourcesList();
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.props.setOutgoingPaymentSourceInfo(record);
              this.setState({ visibleAddSourceModal: true });
            }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  handleCancelAddSourceModal = (e) => {
    this.setState({
      visibleAddSourceModal: false,
    });
  };

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this source?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.props
                    .deleteOutgoingPaymentSource(this.state.index)
                    .then(() => {
                      message.success("Source is deleted successfully!");
                      this.props.getPaymentSourcesList();
                      this.setState({ visibleDeleteModal: false });
                    });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    let _paymentSourcesListing = nextProps && nextProps.paymentSourcesListing;
    this.setState({
      sources: _paymentSourcesListing,
    });
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getPaymentSourcesList().then(() => {});
      });
    }
  }

  getOutgoingPaymentSourcesSection = () => {
    return (
      <Row xs={24}>
        <Col xs={21} offset={1} style={{ marginTop: "13px" }}>
          <Table
            dataSource={this.state.sources ? this.state.sources : null}
            columns={this.columns}
            pagination={{
              defaultPageSize: 20,
            }}
          />
        </Col>
        <Col xs={1} offset={1} style={{ marginTop: "-14px" }}>
          <img
            src={Images.addIcon}
            className="icons-client"
            type="primary"
            onClick={() => {
              this.props.setOutgoingPaymentSourceInfo(null);
              this.setState({ visibleAddSourceModal: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <Row>
          <Col xs={24}>
            <h4 className={"settings-heading"}>{"Outgoing Payment Sources"}</h4>
          </Col>
        </Row>
        <div className="ca-gray-cont">
          {this.getOutgoingPaymentSourcesSection()}
          {this.state.visibleAddSourceModal ? (
            <AddSource
              handleCancelAddSourceModal={this.handleCancelAddSourceModal}
              visibleAddSourceModal={this.state.visibleAddSourceModal}
            />
          ) : null}
          {this.getModalDelete()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentSourcesListing:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.paymentSourcesListing,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPaymentSourcesList: bindActionCreators(getPaymentSourcesList, dispatch),
  setOutgoingPaymentSourceInfo: bindActionCreators(
    setOutgoingPaymentSourceInfo,
    dispatch
  ),
  deleteOutgoingPaymentSource: bindActionCreators(
    deleteOutgoingPaymentSource,
    dispatch
  ),
});

OutgoingPaymentSources = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutgoingPaymentSources);

export default OutgoingPaymentSources;
