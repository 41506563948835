import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CompanyUsersList from "../Admin/CompanyUsersList";
import { onGetCompanies, onGetCompanyUsers } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    companiesRes: state.AdminReducer.companiesRes,
    companyUsersRes: state.AdminReducer.companyUsersRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCompanies: bindActionCreators(onGetCompanies, dispatch),
    onGetCompanyUsers: bindActionCreators(onGetCompanyUsers, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersList);
