import React from "react";
import { Row, Col, Button, Form, Space } from "antd";
import Select from "react-select";
import "./PotentialClientStyles.css";
import { Redirect } from "react-router-dom";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import PotentialEmployerfrom from "./PotentialEmployer";
import PotentialClient from "./PotentialClient";
import WebAssessment from "./WebAssessment";
import CommissionReminder from "../SchoolManagement/CommissionReminder";
import AddNewPotentialClient from "./AddNewPotentialClient";
import PotentialClientsStatus from "./PotentialClientsStatus";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QuestionnaireList from "./Questionnaire/Questionnaire";
import {
  getEmployerManag,
  addEmployerManag,
  addDocWithName,
  deleteEmployerManag,
  getEmployerContact,
  addEmployerContact,
  removeEmployerContact,
  updateEmployerManag,
  updateEmployerContact,
  getEmployerFile,
  addEmployerFile,
  getLetterTemplates,
  removeEmployerFile,
  updateEmployerFile,
  addAnyTamplate,
  getEmployerDocument,
  removeEmployerDocument,
  addDocumentTittle,
  getFilteredType,
  addFilteredType,
  getDocumentChecklist,
  getEmployerCheckList,
  addEmployerDocument,
  updateEmployerDocument,
  addEmployerCheckList,
  getDocumentDownload,
  sendDynamicLinkEmail,
  getEmployerHistory,
  addEmployerEmail,
  getAutoEmailImport,
  addAutoEmailImport,
  deleteCreateEmail,
  movePotentialEmployer,
  getSignature,
  getDocuments,
  getPClientEmailHistory,
  manualImportPotentialEmail,
  getPdf,
  getImapForAll,
  addEmailDocument,
  getAllQuestionnaire,
  createDynamicLink,
  getFilledQuestionnaire,
  addNewClient,
  updMergeToClient,
  addPotentialClient,
  updMergeToPotentialClient,
  getDynamicLink,
  getCountries,
  submitQuestionnaire,
  getQuestionnaire,
  getVisaType,
  getClientTag,
} from "../store/Actions";

const headOption = [
  {
    tabName: "Potential Clients",
    linkName: "/potential-client/potential-clients",
  },
  // {
  //   tabName: "Potential Clients Status",
  //   linkName: "/potential-client/potential-clients-status",
  // },
  {
    tabName: "Potential Employers",
    linkName: "/potential-client/potential-employer",
  },
];
class PCleintCustomQuestionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      onGetAllQuestionnaire,
      questionnaireList,
      onCreateDynamicLink,
      dynamicLinkSuccess,
      onGetFilledQuestionnaire,
      filledQuestionnaireRes,
      addNewClient,
      onUpdMergeToClient,
      onUpdMergeToPClient,
      addPotentialClient,
      onGetDynamicLink,
      onGetCountries,
      onSubmitQuestionnaire,
      onGetQuestionnaire,
      dynamicLinkData,
      countriesData,
      questionnaireData,
      onAddEmployerManag,
      onGetVisaType,
      visaTypeData,
      clientTagRes,
      getClientTag,
    } = this.props;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            {/* <Row>
              <Col xs={24}>
                <PotentialHeaderTabs
                  data={headOption}
                  activePath={window.location.pathname}
                />
              </Col>
            </Row> */}
            <Row>
              <Col xs={24}>
                <QuestionnaireList
                  onGetAllQuestionnaire={onGetAllQuestionnaire}
                  questionnaireList={questionnaireList}
                  onCreateDynamicLink={onCreateDynamicLink}
                  dynamicLinkSuccess={dynamicLinkSuccess}
                  onGetFilledQuestionnaire={onGetFilledQuestionnaire}
                  filledQuestionnaireRes={filledQuestionnaireRes}
                  addNewClient={addNewClient}
                  onUpdMergeToClient={onUpdMergeToClient}
                  addPotentialClient={addPotentialClient}
                  onUpdMergeToPClient={onUpdMergeToPClient}
                  onGetDynamicLink={onGetDynamicLink}
                  onGetCountries={onGetCountries}
                  onSubmitQuestionnaire={onSubmitQuestionnaire}
                  onGetQuestionnaire={onGetQuestionnaire}
                  dynamicLinkData={dynamicLinkData}
                  countriesData={countriesData}
                  questionnaireData={questionnaireData}
                  onAddEmployerManag={onAddEmployerManag}
                  onGetVisaType={onGetVisaType}
                  visaTypeData={visaTypeData}
                  getClientTag={getClientTag}
                  clientTagRes={clientTagRes}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionnaireList: state.questionnaireReducer.questionnaireList,
    dynamicLinkSuccess: state.questionnaireReducer.dynamicLinkSuccess,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    countriesData: state.visaTypeReducer.countriesData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetAllQuestionnaire: bindActionCreators(getAllQuestionnaire, dispatch),
  onCreateDynamicLink: bindActionCreators(createDynamicLink, dispatch),
  onGetFilledQuestionnaire: bindActionCreators(
    getFilledQuestionnaire,
    dispatch
  ),
  addNewClient: bindActionCreators(addNewClient, dispatch),
  onUpdMergeToClient: bindActionCreators(updMergeToClient, dispatch),
  addPotentialClient: bindActionCreators(addPotentialClient, dispatch),
  onUpdMergeToPClient: bindActionCreators(updMergeToPotentialClient, dispatch),
  onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
  onGetCountries: bindActionCreators(getCountries, dispatch),
  onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
  onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
  onAddEmployerManag: bindActionCreators(addEmployerManag, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
  getClientTag: bindActionCreators(getClientTag, dispatch),
});

PCleintCustomQuestionnaire = connect(
  mapStateToProps,
  mapDispatchToProps
)(PCleintCustomQuestionnaire);

export default PCleintCustomQuestionnaire;
