import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal, Spin, Image } from "antd";
import EmployerDocuments from "./EmployerDocuments/EmployerDocuments";
import AddEmployerDocuments from "./EmployerDocuments/AddEmployerDocuments";
import UpdateEmployerDocuments from "./EmployerDocuments/UpdateEmployerDocuments";
import DocumentCheckList from "./DocumentsCheckList/DocumentCheckList";
import HeaderBarTabs from "../../../../Components/Header/HeaderTabs";
import { Images } from "../../../../Themes";
import headOption from "../../../../Components/Header/HeaderTabOptions";
import { apiRefresh } from "../../../../services/api";
import * as types from "../../../../store/Constants";
import "./folder.css";
import { FolderOpenTwoTone } from "@ant-design/icons";

const { TabPane } = Tabs;

const EmployerManagementTabs = ({
  onGetEmployerDocument,
  employerDocumentRes,
  userDataEmp,
  onAddEmployerDocument,
  onRemoveEmployerDocument,
  onUpdateEmployerDocument,
  onUploadAvatar,
  imageUploadSuccess,
  onGetDocumentChecklist,
  docChecklistRes,
  onGetEmployerCheckList,
  employerCheckListRes,
  onAddEmployerCheckList,
  location,
  history,
  onSendEmailLink,
  onGetDocumentDownload,
  downloadDocRes,
  onGetDocumentPdf,
  documentPdfRes,
  onGetFilteredType,
  documentFilterRes,
  onAddFilteredType,
  clientTab,
  onGetClientTag,
  clientTagRes,
  getClientRes,
  onGetProfileClientTag,
  onAddProfileClientTag,
  addProfielTagRes,
  onRemoveProfileClientTag,
  removeProfileTagRes,
  visaStatusData,
  onUpdateCaseStatus,
  visaAppData,
  onGetVisaApplication,
  onGetClientJobHistoryCurrent,
  employerJobHistoryCurrentRes,
  onSetActiveKey,
  activeKey,
  onGetClientTask,
  onUpdateTask,
  onGetAllUsers,
  onAddTaskFollower,
  onRemoveTasks,
  onAddDailyTasks,
  onGetClientFamily,
  onUpdateCompletedTask,
  onAddTaskFileNote,
  onAddTaskComment,
  onGetTaskComments,
  onGetTaskFollowers,
  tabKey,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [onUpdateTitle, setOnUpdateTitle] = useState();
  const [headerOptions, setHeaderOptions] = useState([]);
  const [isFolderView, setisFolderView] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [ShowButtons, setShowButtons] = useState(false);
  const [onFilterData, setOnFilterData] = useState([]);
  const [folderDocType, setfolderDocType] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const folderOpt = {
      url: "v1/users/DocumentView",
    };
    folderOpt.types = [
      types.GET_DOCUMENT_VIEW_SUCCESS,
      types.GET_DOCUMENT_VIEW_FAILURE,
    ];

    apiRefresh.get(folderOpt).then((res) => {
      setisFolderView(res.isDocumentView);
      setShowButtons(res.isDocumentView);
      if (res.isDocumentView) {
        setLoading(true);
        const docTypeOpt = {
          url: `v1/document/type/All`,
        };
        docTypeOpt.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];

        apiRefresh
          .get(docTypeOpt)
          .then((res) => {
            setDocTypes(res.items);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    });
  }, [clientTab, userDataEmp]);
  useEffect(() => {
    onGetFilteredType()
      .then((res) => {
        setOnFilterData(res && res.payload);
      })
      .catch(() => {});
  }, []);
  const showModal = (modalTypeName, value) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
    if (modalType === "update-documents") {
      setOnUpdateTitle(value);
    }
    // setAddReminders(true);
    // setUpdateTitle(id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const folderClick = (id) => {
    setisFolderView(false);
    setfolderDocType(id);
  };
  const UpdateInChild = () => {
    setLoading(true);
    let clientprofileid = userDataEmp.id;
    var data = new Object();
    data.id = clientprofileid;
    data.docType = -1;
    data.CaseId = 0;
    data.IsVisa = false;

    onGetEmployerDocument(data)
      .then(() => {
        setLoading(false);
        setisFolderView(true);
      })
      .catch((err) => {
        setLoading(false);
        setisFolderView(true);
      });
  };

  return (
    <div>
      <Spin spinning={loading}>
        <h4
          className="top-text heading-forms"
          style={{
            marginLeft: "40px",
            fontWeight: 600,
            fontSize: "14px",
            marginTop: "18px",
            fontFamily: "Poppins",
          }}
        >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
        {isFolderView ? (
          <Fragment>
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Documents" />
            )}
            <div
              style={{
                display: "flex",
                whiteSpace: "break-spaces",
                margin: 10,
              }}
            >
              <div
                className="employerin-box employer-manag-tabs"
                style={{ margin: 0 }}
              >
                <div
                  style={{ padding: 30, width: "100%" }}
                  className="bg-white "
                >
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"small"}
                    className="mar-r employer-doc-tab"
                  >
                    <TabPane tab="DOCUMENT" key="1">
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <div style={{ display: "flex", width: "100%" }}>
                          <div className="folderClass">
                            <Image
                              tabName="Unparented"
                              src={Images.yellowfolder}
                              onClick={() => folderClick(0)}
                              preview={false}
                              style={{ width: 50 }}
                            />
                            <div class="doc-name">Unparented</div>
                          </div>
                          {docTypes.map(function(object, i) {
                            var found =
                              employerDocumentRes &&
                              employerDocumentRes.items.find(
                                (x) => x.documentTypeId == object.id
                              );
                            if (found) {
                              return (
                                <>
                                  <div className="folderClass">
                                    <Image
                                      tabName={object.name}
                                      src={Images.folder}
                                      onClick={() => folderClick(object.id)}
                                      preview={false}
                                      style={{ width: 50 }}
                                    />
                                    <div class="doc-name">{object.name}</div>{" "}
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tab="DOCUMENT CHECKLIST" key="2">
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <DocumentCheckList
                          onGetDocumentChecklist={onGetDocumentChecklist}
                          docChecklistRes={docChecklistRes}
                          onGetEmployerCheckList={onGetEmployerCheckList}
                          employerCheckListRes={employerCheckListRes}
                          onAddEmployerCheckList={onAddEmployerCheckList}
                          location={location}
                          history={history}
                          onSendEmailLink={onSendEmailLink}
                          userDataEmp={userDataEmp}
                          tabKey={tabKey}
                        />
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>

            {isModalVisible && (
              <div className="reminder-model">
                <Modal
                  className="reminder-model-main"
                  title={
                    (modalType === "add-documents" && "ADD DOCUMENTS") ||
                    (modalType === "update-documents" && "UPDATE DOCUMENTS")
                  }
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  // Header={true}
                  footer={false}
                >
                  {modalType === "add-documents" && (
                    <AddEmployerDocuments
                      onAddEmployerDocument={onAddEmployerDocument}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      handleCancel={handleCancel}
                      folderDocType={folderDocType}
                      userDataEmp={userDataEmp}
                    />
                  )}
                </Modal>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Documents" />
            )}
            <div style={{ display: "flex", margin: 10 }}>
              <div
                className="employerin-box employer-manag-tabs"
                style={{ margin: 0 }}
              >
                <div style={{ padding: 30 }} className="bg-white ">
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"small"}
                    className="mar-r employer-doc-tab"
                  >
                    <TabPane tab="DOCUMENT" key="1">
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <EmployerDocuments
                          onGetEmployerDocument={onGetEmployerDocument}
                          employerDocumentRes={employerDocumentRes}
                          userDataEmp={userDataEmp}
                          showModal={showModal}
                          onRemoveEmployerDocument={onRemoveEmployerDocument}
                          onGetDocumentDownload={onGetDocumentDownload}
                          downloadDocRes={downloadDocRes}
                          onGetDocumentPdf={onGetDocumentPdf}
                          documentPdfRes={documentPdfRes}
                          onUpdateEmployerDocument={onUpdateEmployerDocument}
                          onFilterData={onFilterData}
                          documentFilterRes={documentFilterRes}
                          onAddFilteredType={onAddFilteredType}
                          UpdateInChild={UpdateInChild}
                          ShowButtons={ShowButtons}
                          folderDocType={folderDocType}
                          tabKey={tabKey}
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="DOCUMENT CHECKLIST" key="2">
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <DocumentCheckList
                          onGetDocumentChecklist={onGetDocumentChecklist}
                          docChecklistRes={docChecklistRes}
                          onGetEmployerCheckList={onGetEmployerCheckList}
                          employerCheckListRes={employerCheckListRes}
                          onAddEmployerCheckList={onAddEmployerCheckList}
                          location={location}
                          history={history}
                          onSendEmailLink={onSendEmailLink}
                          userDataEmp={userDataEmp}
                          tabKey={tabKey}
                        />
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>

            {isModalVisible && (
              <div className="reminder-model">
                <Modal
                  className="reminder-model-main"
                  title={
                    (modalType === "add-documents" && "ADD DOCUMENTS") ||
                    (modalType === "update-documents" && "UPDATE DOCUMENTS")
                  }
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  // Header={true}
                  footer={false}
                >
                  {modalType === "add-documents" && (
                    <AddEmployerDocuments
                      onAddEmployerDocument={onAddEmployerDocument}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      handleCancel={handleCancel}
                      onFilterData={onFilterData}
                      folderDocType={folderDocType}
                      userDataEmp={userDataEmp}
                    />
                  )}
                  {/* 
            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onUpdateEmployerDocument={onUpdateEmployerDocument}
                onUpdateTitle={onUpdateTitle}
              />
            )} */}
                </Modal>
              </div>
            )}
          </Fragment>
        )}
      </Spin>
    </div>
  );
};

export default EmployerManagementTabs;
