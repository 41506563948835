import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Progress,
} from "antd";
import moment from "moment";

import { Images } from "../../Themes";
import Form from "antd/lib/form/Form";
import { PriorityHigh } from "@material-ui/icons";
import { signedVisa } from "../../store/Actions";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const Reminders = ({ reminder, index, onUpdateReminder, isFiltered }) => {
  const [showCompleted, setShowCompleted] = useState(
    reminder && reminder.isCompleted
  );

  const onChange = (e) => {
    let data = {
      id: reminder.id,
      isCompleted: e.target.checked,
    };
    setShowCompleted(e.target.checked);
    onUpdateReminder(data);
  };

  useEffect(() => {
    if (reminder) {
      setShowCompleted(reminder.isCompleted);
    }
  }, [isFiltered]);

  return (
    <Fragment>
      <div style={{ marginLeft: 0, marginTop: index === 0 ? 0 : 15 }}>
        <div>
          <Checkbox checked={showCompleted} onChange={onChange}></Checkbox>
          <span className="reminder-small-text" style={{ marginLeft: 5 }}>
            {reminder.reminderDetail}
          </span>
        </div>
        <div>
          <span className="reminder-bold-text">
            For {reminder.subjectName} expires on{" "}
            {moment(reminder.reminderDate).format("DD/MM/YYYY")}
          </span>
        </div>
        <div>
          <span className="reminder-date-text">
            ({moment(reminder.createdDate).format("DD/MM/YYYY")})
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Reminders;
