import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Table,
  Spin,
  Modal,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getAllInvoiceTypes,
  getAllBanksListing,
  getAllCurrencies,
  addNewInvoice,
  getLastInvoiceNumber,
  addInvoicePayment,
  getInvoicePayments,
  deleteInvoicePayment,
  editSchoolInvoice,
  setEmailTemplate,
  getBranch,
  getInvoiceStatuses,
  getCompany,
  checkDuplicateClientInvoice,
  getSearchStudentListing,
  getAllTaxesListing,
  addNewSchoolInvoice,
  postInvoiceToXero,
  postSchoolInvoiceToXero,
  updateSchoolInvoiceToXero,
} from "../store/Actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
// import nextId from "react-id-generator";

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD/MM/YYYY";
let notesTypes = [
  {
    label: "Student",
    text:
      "Ezymigrate is the best for student management Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    label: "Visit",
    text:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    label: "Great",
    text:
      "Lorem Ipsum，也称乱数假文或者哑元文本， 是印刷及排版领域所常用的虚拟文字。由于曾经一台匿名的打印机刻意打乱了一盒印刷字体从而造出一本字体样品书，Lorem Ipsum从西元15世纪起就被作为此领域的标准文本使用。它不仅延续了五个世纪，还通过了电子排版的挑战，其雏形却依然保存至今。 Anna is great",
  },
  {
    label: "Payment Note",
    text:
      "Please pay upon receipt failing which 2000% interest charged daily!!",
  },
  {
    label: "Marketing Note",
    text: "Thomas and his team works hard. Please like us on Facebook!",
  },
  {
    label: "Notes",
    text: "You have fully paid , there is no need to pay anything extra",
  },
  {
    label: "Test",
    text: "asd",
  },
  {
    label: "Note 123",
    text: "already paid , no action required!",
  },
];

class AddSchoolInvoiceToXero extends React.Component {
  formRef = React.createRef();
  formRefTemp = React.createRef();
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    let _gstPercent =
      props && props.companyData && props.companyData.gstPercent;
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.state = {
      loading: true,
      isTaxFieldVisible: true,
      clientProfileId:
        props && props.selectedSchoolId && props.selectedSchoolId,
      branchId: selectedBranchId,
      dateStr: "",
      dueDate: "",
      invoiceNo: Math.random()
        .toString(36)
        .substr(2, 5),
      invoiceTypeId: 1,
      taxInclusive: true,
      notes: "",
      lastInvoiceNumber: "",
      clientTagId: 0,
      _amount: 100,
      qTotal: 0,
      total: 0,
      subTotal: 0,
      discountAmount: 0,
      discount: 0,
      addedAmount: 0,
      gst: 0,
      dueAmount: 0,
      invoiceItems: [],
      invoiceItemsDefined: [],
      invoiceId: 0,
      invoicePayments: [],
      visibleDeleteModal: false,
      paymentDescription: "",
      visibleSendingReceiptModal: false,
      sendingReceiptEmail: "",
      invoicePaymentsAmountTotal: 0,
      branchName: "",
      setEmailData: "",
      invoiceTypesListing: [],
      invoiceStatuses: [],
      statusId: 0,
      userEmail: userEmail,
      deletedPayment: 0,
      isDisabled: false,
      isDuplicateInvoiceExists: false,
      showModalAddStudent: false,
      selectedSchoolId: 0,
      students: [],
      taxListing: [],
      taxName: "",
      taxValueDropdown: 0,
      subjectTypeId: 5,
      accountCode: "",
      currencyName: "",
      dateString: "",
    };
  }

  paymentColumns = [
    {
      title: "Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text, record) => (
        <p className="invoice-table-body-dates">
          {moment(record.receivedDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            style={{ marginRight: "4px" }}
            onClick={this.showModalSendingReceipt}
          >
            {"SEND RECEIPT"}{" "}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              this.showModalDelete(record.id, record.amount);
            }}
          >
            {"UNDO"}
          </Button>
        </span>
      ),
    },
  ];

  showModalSendingReceipt = () => {
    let _statusId;
    if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
      if (this.state.invoiceStatuses && this.state.invoiceStatuses.length > 0) {
        _statusId = this.state.invoiceStatuses[1].id;
      }
    } else {
      _statusId = this.state.invoiceStatuses[5].id;
    }
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _childrenTotalAmount =
      this.state.childrenTotalAmount + this.state.total;
    this.setState({ statusId: _statusId }, () => {
      if (this.state.invoiceTypeId === 0) {
        message.error("Please select Invoice type Id!");
      } else if (
        this.state.dateStr === "" ||
        this.state.dateStr === "Invalid date"
      ) {
        message.error("Please select date!");
      } else if (
        this.state.dueDate === "" ||
        this.state.dueDate === "Invalid date"
      ) {
        message.error("Please select due date!");
      } else if (this.state.invoiceNo === "") {
        message.error("Please add Invoice no!");
      } else if (this.state.subTotal === 0) {
        message.error("Sub total is 0!");
      } else if (
        this.props.parentInvoiceId !== 0 &&
        _childrenTotalAmount > this.state.parentTotalAmount
      ) {
        message.error(
          "Sum of all child invoices amount can never be greater than parent invoice amount!"
        );
      } else if (this.state.discountAmount > this.state.subTotal) {
        message.error("Discount amount must be less than total amount!");
      } else {
        let _data = {
          invoiceNo: this.state.invoiceNo,
        };
        this.props.checkDuplicateClientInvoice(_data).then(() => {
          let _duplicate = this.props && this.props.duplicate;
          if (
            _duplicate === true &&
            this.state.invoiceNo !== _invoiceInfo.invoiceNo
          ) {
            message.error("Invoice no must be unique!");
          } else {
            this.props.editInvoice(this.state).then(() => {});
          }
        });
      }
    });
    this.setState({ visibleSendingReceiptModal: true });
  };

  componentDidMount() {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.getCompany();
    this.props.getClientTag();
    this.props.getAllInvoiceTypes();
    this.props.getAllBanksListing();
    this.props.getAllCurrencies();
    this.props.getLastInvoiceNumber();
    this.props.getBranch();
    this.props.getInvoiceStatuses(this.state.invoiceTypeId);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      parentInvoiceId: nextProps.parentInvoiceId,
      childrenTotalAmount: nextProps.childrenTotalAmount,
      parentTotalAmount: nextProps.parentTotalAmount,
      selectedSchoolId: nextProps.selectedSchoolId,
    });

    let _taxListing = nextProps && nextProps.taxListing;
    this.setState({
      taxListing: _taxListing,
    });
    let _invoiceInfo = nextProps.invoiceInfo;
    let _invoiceStatuses =
      nextProps && nextProps.invoiceStatuses && nextProps.invoiceStatuses;
    let _invoiceTypesListing =
      nextProps.invoiceTypesListing &&
      nextProps.invoiceTypesListing.items &&
      nextProps.invoiceTypesListing.items;
    let _invoicePayments = nextProps.invoicePayments;
    let _branchData = nextProps.branchData;
    let _setEmailData = nextProps.setEmailData;
    let invoiceTypesListingForChild = [];
    this.setState({ invoiceStatuses: _invoiceStatuses });
    if (this.props.parentInvoiceId !== 0) {
      if (_invoiceTypesListing && _invoiceTypesListing.length > 0) {
        for (let index = 0; index < _invoiceTypesListing.length; index++) {
          if (_invoiceTypesListing[index].id !== 2) {
            invoiceTypesListingForChild.push({
              id: _invoiceTypesListing[index].id,
              invoiceTypeName: _invoiceTypesListing[index].invoiceTypeName,
            });
          }
        }

        this.setState({ invoiceTypesListing: invoiceTypesListingForChild });
      }
    } else {
      this.setState({ invoiceTypesListing: _invoiceTypesListing });
    }

    let _invoicePaymentsAmountTotal = 0;
    let _lastInvoiceNumber = nextProps && nextProps.lastInvoiceNumber;
    this.setState({ lastInvoiceNumber: _lastInvoiceNumber });

    if (_invoiceInfo && _invoiceInfo.id) {
      if (_invoiceInfo.id !== this.state.invoiceId) {
        // if(_invoiceInfo.invoiceTypeId === 3)
        // {
        //   this.setState({gst : 0 })
        // }
        this.setState(
          { invoiceItemsDefined: _invoiceInfo.invoiceItems },
          () => {
            let _invoiceItemsDefined = this.state.invoiceItemsDefined;
            if (_invoiceItemsDefined.length > 0) {
              for (
                let index = 0;
                index < _invoiceItemsDefined.length;
                index++
              ) {
                _invoiceItemsDefined[index].amount = parseFloat(
                  _invoiceItemsDefined[index].commissionAmount
                );
                _invoiceItemsDefined[index].uniqueId = _invoiceItemsDefined[
                  index
                ].id.toString();
                _invoiceItemsDefined[index].studentNo =
                  _invoiceItemsDefined[index].studentNumber;
                _invoiceItemsDefined[index].ID = _invoiceItemsDefined[index].id;

                _invoiceItemsDefined[index].enrolledProgram =
                  _invoiceItemsDefined[index].programmeName;
                _invoiceItemsDefined[index].courseEffectiveDate =
                  _invoiceItemsDefined[index].startDate;
                _invoiceItemsDefined[index].fee =
                  _invoiceItemsDefined[index].programmeFee;

                _invoiceItemsDefined[index].percentage =
                  _invoiceItemsDefined[index].commissionRate;
                _invoiceItemsDefined[index].programId = 0;
                _invoiceItemsDefined[index].createdBy =
                  "3fa85f64-5717-4562-b3fc-2c963f66afa6";
                _invoiceItemsDefined[index].invoiceId = _invoiceInfo.id;
              }
            }
            let a = _invoiceItemsDefined;

            this.setState({ students: _invoiceItemsDefined });
          }
        );

        let _dateStr = moment(_invoiceInfo.dateStr).format("DD/MM/YYYY");
        let _dueDate = moment(_invoiceInfo.dueDate).format("DD/MM/YYYY");
        this.props.getInvoiceStatuses(_invoiceInfo.invoiceTypeId);
        let _branchName;
        if (_branchData && _branchData.length > 0) {
          for (
            let branchIndex = 0;
            branchIndex < _branchData.length;
            branchIndex++
          ) {
            if (_branchData[branchIndex].id === this.state.branchId) {
              _branchName = _branchData[branchIndex].name;
            }
            this.setState({ branchName: _branchName });
          }
        }
        this.setState(
          {
            dateStr: _dateStr,
            dueDate: _dueDate,
            invoiceId: _invoiceInfo.id,
            invoiceNo: _invoiceInfo.invoiceNo,
            invoiceTypeId: _invoiceInfo.invoiceTypeId,
            clientTagId: _invoiceInfo.clientTagId,
            taxInclusive: _invoiceInfo.taxInclusive,
            notes: _invoiceInfo.notes,
            isTaxFieldVisible: _invoiceInfo.invoiceTypeId !== 3,
          },
          () => {
            let _subTotal = _invoiceInfo.subTotal;
            let _currencyId;
            let _gst = _invoiceInfo.gst;
            let _discount = _invoiceInfo.discount;
            let _discountAmount = _invoiceInfo.discountAmount;
            let _total = _invoiceInfo.total;
            let _dueAmount = _invoiceInfo.dueAmount;

            let _bankAccount;
            if (_invoiceInfo.bankAccount === 0) {
              _bankAccount = undefined;
            } else {
              _bankAccount = _invoiceInfo.bankAccount;
            }
            if (_invoiceInfo.currencyId === 0) {
              _currencyId = undefined;
            } else {
              _currencyId = _invoiceInfo.currencyId;
            }
            this.setState(
              {
                subTotal: _subTotal,
                currencyId: _currencyId,
                gst: _gst,
                discount: _discount,
                discountAmount: _discountAmount,
                total: _total,
                dueAmount: _dueAmount,
                bankAccount: _bankAccount,
              },
              () => {
                let _currencyList =
                  this.props &&
                  this.props.currencyListing &&
                  this.props.currencyListing;
                if (_currencyList && _currencyList.length > 0) {
                  for (
                    let pointer = 0;
                    pointer < _currencyList.length;
                    pointer++
                  ) {
                    if (_currencyList[pointer].id === this.state.currencyId) {
                      this.setState({
                        currencyName: _currencyList[pointer].id,
                      });
                    }
                  }
                }
              }
            );
            this.formRef.current.setFieldsValue({
              subTotal: _subTotal,
              currencyId: _currencyId,
              bankAccount: _bankAccount,
              gst: _gst,
              discount: _discount,
              discountAmount: _discountAmount,
              total: _total,
              dueAmount: _dueAmount,
            });
          }
        );
      }
    }
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId });
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  handleCourseEffectiveDateFromChange = (date, dateString) => {
    this.setState(
      {
        cDateFrom:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ aDateFrom: undefined, aDateTo: undefined });
      }
    );
  };

  handleCourseEffectiveDateToChange = (date, dateString) => {
    this.setState(
      {
        cDateTo:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ aDateFrom: undefined, aDateTo: undefined });
      }
    );
  };

  handleAppliedDateFromChange = (date, dateString) => {
    this.setState(
      {
        aDateFrom:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ cDateFrom: undefined, cDateTo: undefined });
      }
    );
  };

  handleAppliedDateToChange = (date, dateString) => {
    this.setState(
      {
        aDateTo:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ cDateFrom: undefined, cDateTo: undefined });
      }
    );
  };

  handleDateChange = (date, dateString) => {
    this.setState({ dateStr: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  handleDueDateChange = (date, dateString) => {
    this.setState({ dueDate: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  getDatePickers = () => {
    return (
      <Row>
        <Col xs={11} offset={1}>
          <p style={{ fontSize: "11px" }}>{"Date"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dateStr === "" || this.state.dateStr === "Invalid date"
                ? undefined
                : moment(this.state.dateStr, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDateChange}
          />
        </Col>
        <Col xs={11} style={{ marginLeft: "8px" }}>
          <p style={{ fontSize: "11px" }}>{"Due Date"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dueDate === "" || this.state.dueDate === "Invalid date"
                ? undefined
                : moment(this.state.dueDate, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDueDateChange}
          />
        </Col>
      </Row>
    );
  };

  handleChangeInvoiceNo = (e) => {
    if (e.target.value.length < 6) {
      this.setState({ invoiceNo: e.target.value });
    } else {
      message.error("Max character limit for invoice no reached!");
    }
  };

  handleChangeInvoiceType = (value) => {
    // let _gstPercent =
    //     this.props && this.props.companyData && this.props.companyData.gstPercent;
    // let cal;
    // if (this.state.discount === 0) {
    //     cal = this.state.subTotal - this.state.discountAmount;
    // } else {
    //     cal =
    //         this.state.subTotal - (this.state.discount * this.state.subTotal) / 100;
    // }
    // let _qTotal;
    // if (this.state.qTotal === 0) {
    //     _qTotal = this.state.total;
    // } else {
    //     _qTotal = this.state.qTotal;
    // }
    // if (value === 3) {
    //     this.setState({ isTaxFieldVisible: false }, () => {
    //         let _taxAmount = (this.state.gst * cal) / 100;
    //         let removedTaxTotalAmount = _qTotal - _taxAmount;
    //         let removedTaxTotalDue = this.state.dueAmount - _taxAmount;
    //
    //         this.setState(
    //             {
    //                 total:
    //                     Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100,
    //                 dueAmount: removedTaxTotalDue,
    //                 qTotal:
    //                     Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100,
    //                 gst: 0
    //             },
    //             () => {
    //                 this.formRef.current.setFieldsValue({
    //                     total: this.state.total,
    //                     dueAmount: this.state.dueAmount,
    //                     gst: 0
    //                 });
    //             }
    //         );
    //     });
    // } else if (this.state.invoiceTypeId === 3 && (value === 1 || value === 2)) {
    //     this.setState(
    //         { isTaxFieldVisible: true, gst: parseInt(_gstPercent) },
    //         () => {
    //             let _taxAmount = (this.state.gst * cal) / 100;
    //             let removedTaxTotalAmount = _qTotal + _taxAmount;
    //             let removedTaxTotalDue = this.state.dueAmount + _taxAmount;
    //
    //             this.setState(
    //                 {
    //                     total:
    //                         Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) /
    //                         100,
    //                     dueAmount: removedTaxTotalDue,
    //                     qTotal:
    //                         Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100
    //                 },
    //                 () => {
    //                     this.formRef.current.setFieldsValue({
    //                         total: this.state.total,
    //                         dueAmount: this.state.dueAmount,
    //                         gst: parseInt(_gstPercent)
    //                     });
    //                 }
    //             );
    //         }
    //     );
    // }

    this.setState({ invoiceTypeId: value }, () => {
      this.props.getInvoiceStatuses(this.state.invoiceTypeId);
    });
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  handleChangeNotes = (e) => {
    this.setState({ notes: e.target.value });
  };

  onBlur = () => {
    let _data = {
      invoiceNo: this.state.invoiceNo,
    };

    if (_data.invoiceNo !== "") {
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      this.props.checkDuplicateClientInvoice(_data).then(() => {
        let _duplicate = this.props && this.props.duplicate;
        if (_duplicate === true) {
          if (_invoiceInfo && _invoiceInfo.id) {
            if (this.state.invoiceNo !== _invoiceInfo.invoiceNo) {
              this.setState({ isDuplicateInvoiceExists: true }, () => {
                message.error("Invoice no must be unique!");
              });
            }
          } else {
            this.setState({ isDuplicateInvoiceExists: true }, () => {
              message.error("Invoice no must be unique!");
            });
          }
        } else {
          this.setState({ isDuplicateInvoiceExists: false }, () => {});
        }
      });
    }
  };

  getFilters = () => {
    return (
      <Row>
        <Col xs={6}></Col>
        <Col xs={6} offset={5}>
          <p style={{ fontSize: "11px", marginLeft: "8px", marginTop: "2px" }}>
            {"Invoice #"}
          </p>
          <Input
            disabled={true}
            value={this.state.invoiceNo}
            onChange={this.handleChangeInvoiceNo}
            onBlur={this.onBlur}
            style={{ marginLeft: "8px" }}
          />
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "11px", marginLeft: "16px" }}>{"Type"}</p>
          <Select
            disabled={true}
            style={{ marginLeft: "16px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeInvoiceType}
            defaultValue={
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing[0] &&
              this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypesListing[0].id
                : undefined
            }
            value={
              this.state.invoiceTypeId === 0
                ? undefined
                : this.state.invoiceTypesListing &&
                  this.state.invoiceTypesListing[0] &&
                  this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypeId
                : undefined
            }
          >
            {this.state &&
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.invoiceTypeName}</Option>;
              })}
          </Select>
        </Col>
        {/*<Col xs={5}>*/}
        {/*    <p style={{ fontSize: "10px", marginLeft: "8px" }}>{"Client Tags"}</p>*/}
        {/*    <Select*/}
        {/*        disabled={true}*/}
        {/*        style={{ marginLeft: "8px" }}*/}
        {/*        className={"width-web-kit"}*/}
        {/*        placeholder={"Select"}*/}
        {/*        onChange={this.handleChangeClientTags}*/}
        {/*        value={*/}
        {/*            this.state.clientTagId === 0 ? undefined : this.state.clientTagId*/}
        {/*        }*/}
        {/*    >*/}
        {/*        <Option value={0}>{"Select"}</Option>*/}
        {/*        {this.props &&*/}
        {/*        this.props.clientTagRes &&*/}
        {/*        this.props.clientTagRes.items &&*/}
        {/*        this.props.clientTagRes.items.map((data) => {*/}
        {/*            // eslint-disable-next-line react/jsx-no-undef*/}
        {/*            return <Option value={data.id}>{data.name}</Option>;*/}
        {/*        })}*/}
        {/*    </Select>*/}
        {/*</Col>*/}
      </Row>
    );
  };

  handleChangeAmount = (value) => {};

  onBlurFeeInput = (e, data) => {
    if (e.target.value <= 0) {
      message.error("Please enter fee greater than 0!");
    } else {
      if (this.state.students.length > 0) {
        for (let index = 0; index < this.state.students.length; index++) {
          if (this.state.students[index].uniqueId === data.uniqueId) {
            let students = [...this.state.students];
            let student = { ...students[index] };

            let _commisionPercentageForFeeChanged = 0;
            _commisionPercentageForFeeChanged =
              (this.state.students[index].amount / parseFloat(e.target.value)) *
              100;

            student.percentage =
              Math.round(
                (_commisionPercentageForFeeChanged + Number.EPSILON) * 100
              ) / 100;
            student.commissionRate =
              Math.round(
                (_commisionPercentageForFeeChanged + Number.EPSILON) * 100
              ) / 100;
            student.fee = parseFloat(e.target.value);
            student.programmeFee = parseFloat(e.target.value);
            students[index] = student;

            this.setState({ students: students });
          }
        }
      }
    }
  };

  onBlurPercentageInput = (e, data) => {
    if (e.target.value < 0) {
      message.error("Please enter fee greater than equal to 0!");
    } else {
      if (this.state.students.length > 0) {
        for (let index = 0; index < this.state.students.length; index++) {
          if (this.state.students[index].uniqueId === data.uniqueId) {
            let students = [...this.state.students];
            let student = { ...students[index] };

            let _commisionAmountForPercentageChanged = 0;
            _commisionAmountForPercentageChanged =
              (this.state.students[index].fee * parseFloat(e.target.value)) /
              100;

            student.amount =
              e.target.value === ""
                ? 0
                : Math.round(
                    (_commisionAmountForPercentageChanged + Number.EPSILON) *
                      100
                  ) / 100;
            student.commissionAmount =
              e.target.value === ""
                ? 0
                : Math.round(
                    (_commisionAmountForPercentageChanged + Number.EPSILON) *
                      100
                  ) / 100;
            student.percentage =
              e.target.value === "" ? 0 : parseFloat(e.target.value);
            student.commissionRate =
              e.target.value === "" ? 0 : parseFloat(e.target.value);
            students[index] = student;
            let _subTotal = 0;

            this.setState({ students: students }, () => {
              if (this.state.students.length > 0) {
                for (
                  let index = 0;
                  index < this.state.students.length;
                  index++
                ) {
                  _subTotal = _subTotal + this.state.students[index].amount;
                }
              }
              this.setState(
                {
                  subTotal:
                    Math.round((_subTotal + Number.EPSILON) * 100) / 100,
                },
                () => {
                  let _discountedAmountDiscountPercentage;
                  let _discountedAmountDiscountFinal;
                  let _discountedAmountTaxPercentage;
                  let _tempTotalAmount;
                  if (this.state.discount !== 0) {
                    _discountedAmountDiscountPercentage =
                      (this.state.discount * this.state.subTotal) / 100;
                    _discountedAmountDiscountFinal =
                      this.state.subTotal - _discountedAmountDiscountPercentage;
                  } else {
                    _discountedAmountDiscountFinal =
                      this.state.subTotal - this.state.discountAmount;
                  }
                  _discountedAmountTaxPercentage =
                    (_discountedAmountDiscountFinal * this.state.gst) / 100;

                  _tempTotalAmount =
                    _discountedAmountTaxPercentage +
                    _discountedAmountDiscountFinal;

                  this.setState(
                    {
                      total:
                        Math.round((_tempTotalAmount + Number.EPSILON) * 100) /
                        100,
                      dueAmount:
                        _tempTotalAmount -
                        this.state.invoicePaymentsAmountTotal,
                    },
                    () => {
                      this.formRef.current.setFieldsValue({
                        subTotal: this.state.subTotal,
                        total: this.state.total,
                        dueAmount: this.state.dueAmount,
                      });
                    }
                  );
                }
              );
            });
          }
        }
      }
    }
  };

  onBlurAmountInput = (e, data) => {
    if (e.target.value < 0) {
      message.error("Please enter fee greater than equal to 0!");
    } else {
      if (this.state.students.length > 0) {
        for (let index = 0; index < this.state.students.length; index++) {
          if (this.state.students[index].uniqueId === data.uniqueId) {
            let students = [...this.state.students];
            let student = { ...students[index] };

            let _commisionPercentageForAmountChanged = 0;
            _commisionPercentageForAmountChanged =
              (parseFloat(e.target.value) / this.state.students[index].fee) *
              100;

            student.percentage =
              e.target.value === ""
                ? 0
                : Math.round(
                    (_commisionPercentageForAmountChanged + Number.EPSILON) *
                      100
                  ) / 100;
            student.commissionRate =
              e.target.value === ""
                ? 0
                : Math.round(
                    (_commisionPercentageForAmountChanged + Number.EPSILON) *
                      100
                  ) / 100;
            student.commissionAmount =
              e.target.value === "" ? 0 : parseFloat(e.target.value);
            student.amount =
              e.target.value === "" ? 0 : parseFloat(e.target.value);
            students[index] = student;

            let _subTotal = 0;

            this.setState({ students: students }, () => {
              if (this.state.students.length > 0) {
                for (
                  let index = 0;
                  index < this.state.students.length;
                  index++
                ) {
                  _subTotal = _subTotal + this.state.students[index].amount;
                }
              }
              this.setState(
                {
                  subTotal:
                    Math.round((_subTotal + Number.EPSILON) * 100) / 100,
                },
                () => {
                  let _discountedAmountDiscountPercentage;
                  let _discountedAmountDiscountFinal;
                  let _discountedAmountTaxPercentage;
                  let _tempTotalAmount;
                  if (this.state.discount !== 0) {
                    _discountedAmountDiscountPercentage =
                      (this.state.discount * this.state.subTotal) / 100;
                    _discountedAmountDiscountFinal =
                      this.state.subTotal - _discountedAmountDiscountPercentage;
                  } else {
                    _discountedAmountDiscountFinal =
                      this.state.subTotal - this.state.discountAmount;
                  }
                  _discountedAmountTaxPercentage =
                    (_discountedAmountDiscountFinal * this.state.gst) / 100;

                  _tempTotalAmount =
                    _discountedAmountTaxPercentage +
                    _discountedAmountDiscountFinal;

                  this.setState(
                    {
                      total:
                        Math.round((_tempTotalAmount + Number.EPSILON) * 100) /
                        100,
                      dueAmount:
                        _tempTotalAmount -
                        this.state.invoicePaymentsAmountTotal,
                    },
                    () => {
                      this.formRef.current.setFieldsValue({
                        subTotal: this.state.subTotal,
                        total: this.state.total,
                        dueAmount: this.state.dueAmount,
                      });
                    }
                  );
                }
              );
            });
          }
        }
      }
    }
  };

  onDeleteStudent = (data) => {
    let _students = [...this.state.students];
    let _filteredStudents = _students.filter(
      (obj) => obj.uniqueId !== data.uniqueId
    );

    let _filteredSubtotal =
      Math.round((this.state.subTotal - data.amount + Number.EPSILON) * 100) /
      100;

    this.setState(
      { students: _filteredStudents, subTotal: _filteredSubtotal },
      () => {
        let _discountedAmountDiscountPercentage;
        let _discountedAmountDiscountFinal;
        let _discountedAmountTaxPercentage;
        let _tempTotalAmount;
        if (this.state.discount !== 0) {
          _discountedAmountDiscountPercentage =
            (this.state.discount * this.state.subTotal) / 100;
          _discountedAmountDiscountFinal =
            this.state.subTotal - _discountedAmountDiscountPercentage;
        } else {
          _discountedAmountDiscountFinal =
            this.state.subTotal - this.state.discountAmount;
        }
        _discountedAmountTaxPercentage =
          (_discountedAmountDiscountFinal * this.state.gst) / 100;

        _tempTotalAmount =
          _discountedAmountTaxPercentage + _discountedAmountDiscountFinal;

        this.setState(
          {
            total: Math.round((_tempTotalAmount + Number.EPSILON) * 100) / 100,
            dueAmount: _tempTotalAmount - this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              subTotal: this.state.subTotal,
              total: this.state.total,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    );
  };

  getAddNewLine = () => {
    return (
      <div>
        <Form onFinish={this.S}>
          <>
            <Row>
              <Col xs={23} className="add-new-line-container">
                <Row style={{ padding: "4px" }}>
                  <Col xs={24} className="add-new-line-header-school-invoices">
                    <Row>
                      <Col xs={1}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"SR#"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Student ID"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Student Name"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Programme Name"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Course Effective Date"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Programme Fee"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Commission Rate %"}
                        </h5>
                      </Col>
                      <Col xs={3}>
                        <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                          {"Commission Amount"}
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/*{this.state &&*/}
                {/*this.state.invoiceItemsDefined &&*/}
                {/*this.state.invoiceItemsDefined.map((data, index) => {*/}
                {/*    return (*/}
                {/*        <Row>*/}
                {/*            <Col xs={1} className="add-new-line-inputs"*/}
                {/*                 style={{marginTop : "-6px"}}>*/}
                {/*                <p style={{*/}
                {/*                    textAlign: "center",*/}
                {/*                    fontSize: "16px"*/}
                {/*                }}>*/}
                {/*                    {index + 1}*/}
                {/*                </p>*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <Input defaultValue={data.studentNumber}/>*/}
                {/*            </Col >*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <Input defaultValue={data.firstName+data.lastName}/>*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <Input defaultValue={data.enrolledProgram}/>*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <Input*/}
                {/*                    defaultValue={moment(data.courseEffectiveDate).format("DD/MM/YYYY")}/>*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <InputNumber defaultValue={data.fee}*/}
                {/*                             onBlur={*/}
                {/*                                 (e) => this.onBlurFeeInput(e,data)}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <InputNumber defaultValue={data.percentage}*/}
                {/*                             value={data.percentage}*/}
                {/*                             onBlur={*/}
                {/*                                 (e) => this.onBlurPercentageInput(e,data)}*/}
                {/*                />*/}
                {/*            </Col>*/}
                {/*            <Col xs={3} className="add-new-line-inputs">*/}
                {/*                <InputNumber*/}
                {/*                    defaultValue={data.amount}*/}
                {/*                    value={data.amount}*/}
                {/*                    onBlur={*/}
                {/*                        (e) => this.onBlurAmountInput(e,data)}/>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*    );*/}
                {/*})}*/}
                {this.state.students.map((data, index) => {
                  return (
                    <Row key={data.uniqueId}>
                      <Col
                        xs={1}
                        className="add-new-line-inputs"
                        style={{ marginTop: "-6px" }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                        >
                          {index + 1}
                        </p>
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <Input disabled={true} defaultValue={data.studentNo} />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <Input
                          disabled={true}
                          defaultValue={
                            data.ID === 0
                              ? data.firstName + data.lastName
                              : data.studentName
                          }
                        />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <Input
                          disabled={true}
                          defaultValue={data.enrolledProgram}
                        />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <Input
                          disabled={true}
                          defaultValue={moment(data.courseEffectiveDate).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <InputNumber
                          disabled={true}
                          defaultValue={data.fee}
                          onBlur={(e) => this.onBlurFeeInput(e, data)}
                        />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <InputNumber
                          disabled={true}
                          defaultValue={data.percentage}
                          value={data.percentage}
                          onBlur={(e) => this.onBlurPercentageInput(e, data)}
                        />
                      </Col>
                      <Col xs={3} className="add-new-line-inputs">
                        <InputNumber
                          disabled={true}
                          defaultValue={data.amount}
                          value={data.amount}
                          onBlur={(e) => this.onBlurAmountInput(e, data)}
                        />
                      </Col>
                      <Col xs={2} className="add-new-line-inputs">
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "5px",
                            cursor: "pointer",
                            display: data.ID === 0 ? "flex" : "none",
                          }}
                        >
                          <DeleteOutlined
                            onClick={() => this.onDeleteStudent(data)}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col xs={24}>*/}
            {/*        <Form.Item>*/}
            {/*            <div*/}
            {/*                className="add-new-line-button"*/}
            {/*                onClick={this.addStudent}*/}
            {/*            >*/}
            {/*                <p>{"ADD STUDENT"}</p>*/}
            {/*            </div>*/}
            {/*        </Form.Item>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
          </>
        </Form>
      </div>
    );
  };

  addStudent = () => {
    this.setState({ showModalAddStudent: true }, () => {});
  };

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState({ taxInclusive: e.target.checked }, () => {
      this.formRef.current.setFieldsValue({
        gst:
          !this.state.taxInclusive && this.state.taxValueDropdown !== 0
            ? this.state.taxValueDropdown
            : 0,
      });
      this.setState(
        {
          gst:
            !this.state.taxInclusive && this.state.taxValueDropdown !== 0
              ? this.state.taxValueDropdown
              : 0,
        },
        () => {
          let totalAmount;
          let addedAmount;
          if (this.state.discount === 0 && this.state.discountAmount === 0) {
            addedAmount = (this.state.gst * this.state.subTotal) / 100;
            totalAmount = addedAmount + this.state.subTotal;
          } else {
            let cal;
            if (this.state.discount === 0) {
              cal = this.state.subTotal - this.state.discountAmount;
            } else {
              cal =
                this.state.subTotal -
                (this.state.discount * this.state.subTotal) / 100;
            }
            addedAmount = (this.state.gst * cal) / 100;
            totalAmount = addedAmount + cal;
          }

          this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
            this.setState(
              {
                dueAmount:
                  this.state._amount - this.state.invoicePaymentsAmountTotal,
              },
              () => {
                console.log(
                  "state",
                  this.state._amount - this.state.invoicePaymentsAmountTotal
                );
              }
            );
            // this.setState({ total: this.state._amount})
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount:
                this.state._amount - this.state.invoicePaymentsAmountTotal,
            });
          });
        }
      );
      if (this.state.discount === 0) {
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - this.state.discountAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
      if (this.state.discountAmount === 0) {
        let _discountedAmount =
          (this.state.discount * this.state.subTotal) / 100;
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - _discountedAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    });
  };

  handleChangeDiscountPercentage = (value) => {
    let _discountedAmount;
    let __discountedAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discount: 0,
      });
      let _tempAmount;
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
        },
        () => {
          let b = _tempAmount - this.state.invoicePaymentsAmountTotal;

          this.setState({
            dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
          });
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    } else {
      let _tempAmount;
      _discountedAmount = (value * this.state.subTotal) / 100;
      __discountedAmount = this.state.subTotal - _discountedAmount;
      let discountedAmountPercentage =
        (__discountedAmount * this.state.gst) / 100;
      _tempAmount = __discountedAmount + discountedAmountPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
          dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discountAmount: 0,
    });
    this.setState({ discountAmount: 0, qTotal: 0 });
  };

  handleChangeDiscountAmount = (value) => {
    let ___discountedAmount;
    let _tempAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discountAmount: 0,
      });
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discountAmount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
          dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    } else {
      ___discountedAmount = this.state.subTotal - value;
      let discountedAmountPercentage =
        (___discountedAmount * this.state.gst) / 100;
      _tempAmount = ___discountedAmount + discountedAmountPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discountAmount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
          dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discount: 0,
    });
    this.setState({ discount: 0, qTotal: 0 });
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
    }
    let addedAmount;

    this.setState({ gst: value }, () => {});
    let totalAmount;
    if (this.state.discount === 0 && this.state.discountAmount === 0) {
      addedAmount = (value * this.state.subTotal) / 100;
      totalAmount = addedAmount + this.state.subTotal;
    } else {
      let cal;
      if (this.state.discount === 0) {
        cal = this.state.subTotal - this.state.discountAmount;
      } else {
        cal =
          this.state.subTotal -
          (this.state.discount * this.state.subTotal) / 100;
      }
      addedAmount = (value * cal) / 100;
      totalAmount = addedAmount + cal;
    }

    this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
      this.setState(
        {
          dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          console.log(
            "state",
            this.state._amount - this.state.invoicePaymentsAmountTotal
          );
        }
      );
      // this.setState({ total: this.state._amount})
      this.formRef.current.setFieldsValue({
        total: Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
        dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
      });
    });
  };

  getForm = () => {
    return (
      <div>
        <Row style={{ marginTop: "17px", marginLeft: "17px" }}>
          <Col xs={7}>
            <TextArea
              disabled={true}
              value={this.state.notes}
              rows={16}
              onChange={this.handleChangeNotes}
            />
          </Col>
          <Col xs={8} offset={1}>
            <Row>
              <Col xs={24}>
                <Select
                  disabled={true}
                  placeholder="Please Select Notes!"
                  size="small"
                  onChange={(value) => {
                    this.setState({ notes: value });
                  }}
                >
                  {notesTypes.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.text}>{data.label}</Option>;
                  })}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="subTotal"
                  colon={false}
                  labelAlign="left"
                  label="Sub Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  {...this.layout}
                  name="currencyId"
                  colon={false}
                  labelAlign="left"
                  label="Currency"
                >
                  <Select
                    disabled={true}
                    placeholder="Please Select Currency!"
                    size="small"
                  >
                    {this.props &&
                      this.props.currencyListing &&
                      this.props.currencyListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={data.id}>{data.currencyName}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.isTaxFieldVisible ? (
              <div>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Checkbox
                      disabled={true}
                      onChange={this.handleChangeTaxInclusiveCheck}
                      checked={this.state.taxInclusive}
                    >
                      {<span className={"filter-text"}>Tax inclusive</span>}
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item name="taxName">
                      <Select
                        disabled={true}
                        virtual={false}
                        placeholder="Please Select Tax!"
                        size="small"
                        onChange={this.onChangeTaxDropdownValue}
                      >
                        <Option value={""}>{"Please select tax"}</Option>
                        {this.state &&
                          this.state.taxListing &&
                          this.state.taxListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option
                                value={JSON.stringify(data)}
                                key={data.id}
                              >
                                {data.name}-{data.percent}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item
                      name="gst"
                      colon={false}
                      labelAlign="left"
                      label="Tax %"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        disabled={true}
                        size="small"
                        // disabled={!!this.state.taxInclusive}
                        onChange={this.handleChangeTaxPercentage}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item name="bankAccount" colon={false} labelAlign="left">
                  <Select
                    disabled={true}
                    placeholder="Please Select Bank Account!"
                    size="small"
                  >
                    {this.props &&
                      this.props.bankListing &&
                      this.props.bankListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discount"
                  colon={false}
                  labelAlign="left"
                  label="Discount %"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    disabled={true}
                    size="small"
                    onChange={this.handleChangeDiscountPercentage}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discountAmount"
                  colon={false}
                  labelAlign="left"
                  label="Discount Amount"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    disabled={true}
                    size="small"
                    onChange={this.handleChangeDiscountAmount}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="total"
                  colon={false}
                  labelAlign="left"
                  label="Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            {this.state.invoiceId !== 0 ? (
              <Row style={{ marginTop: "10px" }}>
                <Col xs={24}>
                  <Form.Item
                    name="dueAmount"
                    colon={false}
                    labelAlign="left"
                    label="Due"
                  >
                    <InputNumber size="small" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="accountCode"
                  colon={false}
                  labelAlign="left"
                  label="Account Code"
                >
                  <InputNumber
                    size="small"
                    onChange={this.onChangeAccountCode}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  onChangeAccountCode = (value) => {
    this.setState({ accountCode: value.toString() });
  };

  getButtons = (invoiceInformation) => {
    return (
      <div>
        <Row style={{ marginLeft: "17px", marginTop: "44px" }}>
          <Col xs={24} style={{ display: "flex" }}>
            <Button
              className="button-blue"
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={() => this.onClickPostToXero(invoiceInformation)}
              disabled={this.state.isDisabled}
            >
              {"Post to Xero"}
            </Button>
            <Button
              className="button-blue"
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={this.backToInvoices}
            >
              Back to Invoices
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  backToInvoices = () => {
    this.props.history.push("/xero-sync-invoices");
  };

  onClickPostToXero = (invoiceInformation) => {
    if (this.state.accountCode === "") {
      message.error("Please enter account code!");
    } else {
      let _dateStr;
      if (this.state.dateStr === "") {
        _dateStr = "1900-01-01T00:00:00+00:00";
      } else {
        _dateStr = moment(this.state.dateStr, "DD/MM/YYYY").format();
      }
      this.setState({ dateString: _dateStr }, () => {
        this.setState({ loading: true });
        if (
          invoiceInformation &&
          invoiceInformation.xeroInvoiceId !==
            "00000000-0000-0000-0000-000000000000"
        ) {
          this.props
            .updateSchoolInvoiceToXero(this.state)
            .then(() => {
              this.setState({ loading: false });
              message.success("Invoice is posted to Xero successfully!");
              this.props.history.push("/xero-sync-invoices");
            })
            .catch((err) => {
              this.setState({ loading: false });
              message.error("An error occurred! Please try again!");
            });
        } else {
          this.props
            .postSchoolInvoiceToXero(this.state)
            .then(() => {
              this.setState({ loading: false });
              message.success("Invoice is posted to Xero successfully!");
              this.props.history.push("/xero-sync-invoices");
            })
            .catch((err) => {
              this.setState({ loading: false });
              message.error("An error occurred! Please try again!");
            });
        }
      });
    }
  };

  onFinish = (values) => {
    let _subTotal =
      values && values.subTotal === undefined ? 0 : values.subTotal;
    let _currencyId =
      values && values.currencyId === undefined ? 0 : values.currencyId;
    let _gst = values && values.gst === undefined ? 0 : values.gst;
    let _bankAccount =
      values && values.bankAccount === undefined ? 0 : values.bankAccount;
    let _discount;
    if (
      (values && values.discount === undefined) ||
      (values && values.discount === null)
    ) {
      _discount = 0;
    } else {
      _discount = values.discount;
    }
    let _discountAmount;
    if (
      (values && values.discountAmount === undefined) ||
      (values && values.discountAmount === null)
    ) {
      _discountAmount = 0;
    } else {
      _discountAmount = values.discountAmount;
    }
    let _total = values && values.total === undefined ? 0 : values.total;
    let _dueAmount =
      values && values.dueAmount === undefined
        ? values.total
        : values.dueAmount;

    let _statusId;
    if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
      if (this.state.invoiceStatuses && this.state.invoiceStatuses.length > 0) {
        _statusId = this.state.invoiceStatuses[0].id;
      }
    } else {
      _statusId = this.state.invoiceStatuses[4].id;
    }
    this.setState(
      {
        subTotal: _subTotal,
        currencyId: _currencyId,
        gst: _gst,
        bankAccount: _bankAccount,
        discount: _discount,
        discountAmount: _discountAmount,
        total: _total,
        dueAmount: _dueAmount,
        statusId: _statusId,
      },
      () => {
        let _childrenTotalAmount =
          this.state.childrenTotalAmount + this.state.total;
        let _invoiceInfo = this.props && this.props.invoiceInfo;
        if (this.state.invoiceId !== 0) {
          if (this.state.invoiceTypeId === 0) {
            message.error("Please select Invoice type Id!");
          } else if (
            this.state.dateStr === "" ||
            this.state.dateStr === "Invalid date"
          ) {
            message.error("Please select date!");
          } else if (
            this.state.dueDate === "" ||
            this.state.dueDate === "Invalid date"
          ) {
            message.error("Please select due date!");
          } else if (this.state.invoiceNo === "") {
            message.error("Please add Invoice no!");
          } else if (this.state.subTotal === 0) {
            message.error("Sub total is 0!");
          } else if (
            this.props.parentInvoiceId !== 0 &&
            _childrenTotalAmount > this.state.parentTotalAmount
          ) {
            message.error(
              "Sum of all child invoices amount can never be greater than parent invoice amount!"
            );
          } else {
            let _data = {
              invoiceNo: this.state.invoiceNo,
            };
            this.props.checkDuplicateClientInvoice(_data).then(() => {
              let _duplicate = this.props && this.props.duplicate;
              if (
                _duplicate === true &&
                this.state.invoiceNo !== _invoiceInfo.invoiceNo
              ) {
                message.error("Invoice no must be unique!");
              } else {
                this.props.editInvoice(this.state).then(() => {
                  message.success("Invoice is updated successfully!");
                });
              }
            });
          }
        } else {
          if (this.state.invoiceTypeId === 0) {
            message.error("Please select Invoice type Id!");
          } else if (
            this.state.dateStr === "" ||
            this.state.dateStr === "Invalid date"
          ) {
            message.error("Please select date!");
          } else if (
            this.state.dueDate === "" ||
            this.state.dueDate === "Invalid date"
          ) {
            message.error("Please select due date!");
          } else if (this.state.invoiceNo === "") {
            message.error("Please add Invoice no!");
          } else if (
            this.state.students.length === 0 ||
            this.state.subTotal === 0
          ) {
            message.error("Please add atleast one student!");
          } else if (
            this.props.parentInvoiceId !== 0 &&
            _childrenTotalAmount > this.state.parentTotalAmount
          ) {
            message.error(
              "Sum of all child invoices amount can never be greater than parent invoice amount!"
            );
          } else {
            this.setState({ isDisabled: true });

            let _data = {
              invoiceNo: this.state.invoiceNo,
            };
            this.props.checkDuplicateClientInvoice(_data).then(() => {
              let _duplicate = this.props && this.props.duplicate;
              if (_duplicate === true) {
                message.error("Invoice no must be unique!");
              } else {
                this.props
                  .addNewInvoice(this.state)
                  .then(() => {
                    message.success("Invoice is added successfully!");
                    this.props.getChildInfo(0, 0, 0, "refresh");
                  })
                  .catch(() => {
                    this.setState({
                      isDisabled: false,
                    });
                  });
              }
            });
          }
        }
      }
    );
  };

  handleChangePaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  handlePaymentDateChange = (date, dateString) => {
    this.setState({ paymentDate: moment(date).format() }, () => {
      console.log("state", this.state);
    });
  };

  handleChangePaymentBank = (value) => {
    this.setState({ paymentBank: value });
  };

  handleChangePaymentDescription = (e) => {
    this.setState({ paymentDescription: e.target.value });
  };

  onChangeTaxDropdownValue = (data) => {
    let _taxDropdownValue = JSON.parse(data);
    this.setState(
      {
        taxName: _taxDropdownValue.name,
        taxValueDropdown: _taxDropdownValue.percent,
        gst: this.state.taxInclusive ? 0 : _taxDropdownValue.percent,
      },
      () => {
        this.formRef.current.setFieldsValue({
          gst: this.state.gst,
        });
        let totalAmount;
        let addedAmount;
        if (this.state.discount === 0 && this.state.discountAmount === 0) {
          addedAmount = (this.state.gst * this.state.subTotal) / 100;
          totalAmount = addedAmount + this.state.subTotal;
        } else {
          let cal;
          if (this.state.discount === 0) {
            cal = this.state.subTotal - this.state.discountAmount;
          } else {
            cal =
              this.state.subTotal -
              (this.state.discount * this.state.subTotal) / 100;
          }
          addedAmount = (this.state.gst * cal) / 100;
          totalAmount = addedAmount + cal;
        }

        this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
          this.setState(
            {
              dueAmount:
                this.state._amount - this.state.invoicePaymentsAmountTotal,
            },
            () => {
              console.log(
                "state",
                this.state._amount - this.state.invoicePaymentsAmountTotal
              );
            }
          );
          // this.setState({ total: this.state._amount})
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount:
              this.state._amount - this.state.invoicePaymentsAmountTotal,
          });
        });
      }
    );
  };

  onFinishAddPaymentForm = (values) => {
    if (this.state.invoiceId !== 0) {
      let _statusId;
      if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
        if (
          this.state.invoiceStatuses &&
          this.state.invoiceStatuses.length > 0
        ) {
          _statusId = this.state.invoiceStatuses[0].id;
        }
      } else {
        _statusId = this.state.invoiceStatuses[4].id;
      }
      let _childrenTotalAmount =
        this.state.childrenTotalAmount + this.state.total;
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      this.setState({ statusId: _statusId }, () => {
        if (this.state.invoiceTypeId === 0) {
          message.error("Please select Invoice type Id!");
        } else if (
          this.state.dateStr === "" ||
          this.state.dateStr === "Invalid date"
        ) {
          message.error("Please select date!");
        } else if (
          this.state.dueDate === "" ||
          this.state.dueDate === "Invalid date"
        ) {
          message.error("Please select due date!");
        } else if (this.state.invoiceNo === "") {
          message.error("Please add Invoice no!");
        } else if (this.state.subTotal === 0) {
          message.error("Sub total is 0!");
        } else if (
          this.props.parentInvoiceId !== 0 &&
          _childrenTotalAmount > this.state.parentTotalAmount
        ) {
          message.error(
            "Sum of all child invoices amount can never be greater than parent invoice amount!"
          );
        } else if (this.state.discountAmount > this.state.subTotal) {
          message.error("Discount amount must be less than total amount!");
        } else {
          let _data = {
            invoiceNo: this.state.invoiceNo,
          };
          this.props.checkDuplicateClientInvoice(_data).then(() => {
            let _duplicate = this.props && this.props.duplicate;
            if (
              _duplicate === true &&
              this.state.invoiceNo !== _invoiceInfo.invoiceNo
            ) {
              message.error("Invoice no must be unique!");
            } else {
              this.props.editInvoice(this.state).then(() => {
                message.success("Invoice is updated successfully!");
              });
            }
          });
        }
      });
    }
  };

  getMakePaymentForm = () => {
    return (
      <Row>
        <Col xs={24}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <Form onFinish={this.onFinishAddPaymentForm} ref={this.formRefTemp}>
              <div className="ca-gray-cont ca-gray-padding">
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Amount"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please enter amount!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={this.handleChangePaymentAmount}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Date"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Please select date!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={this.handlePaymentDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Bank Account"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item name="paymentBank">
                      <Select
                        placeholder="Please Select Bank Account!"
                        onChange={this.handleChangePaymentBank}
                      >
                        {this.props &&
                          this.props.bankListing &&
                          this.props.bankListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return <Option value={data.id}>{data.name}</Option>;
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Description"}</p>
                  </Col>
                  <Col xs={14}>
                    <Input
                      value={this.state.paymentDescription}
                      onChange={this.handleChangePaymentDescription}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div className="payment-buttons-container">
                      <Button
                        style={{ marginRight: "13px" }}
                        type="primary"
                        htmlType="submit"
                      >
                        {"ADD PAYMENT"}
                      </Button>
                      <Button type="primary">
                        {"ADD PAYMENT AND SEND RECEIPT"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    );
  };

  getPaymentHistoryTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="ca-gray-cont ca-gray-padding-payment-history">
              <Table
                dataSource={
                  this.state.invoicePayments
                    ? this.state.invoicePayments.sort((a, b) =>
                        a.receivedDate.localeCompare(b.receivedDate)
                      )
                    : null
                }
                columns={this.paymentColumns}
                pagination={false}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  showModalDelete = (id, amount) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
      deletedPayment: amount,
    });
  };

  handleDeletePayment = (id) => {
    this.props.deleteInvoicePayment(id).then(() => {
      message.success("Payment deleted successfully!");
      this.setState(
        {
          visibleDeleteModal: false,
          dueAmount: this.state.dueAmount + this.state.deletedPayment,
        },
        () => {
          this.formRef.current.setFieldsValue({
            dueAmount: this.state.dueAmount,
          });
          let _statusId;
          let _invoiceInfo = this.props && this.props.invoiceInfo;
          if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
            if (
              this.state.invoiceStatuses &&
              this.state.invoiceStatuses.length > 0
            ) {
              _statusId = this.state.invoiceStatuses[0].id;
            }
          } else {
            _statusId = this.state.invoiceStatuses[4].id;
          }
          let _childrenTotalAmount =
            this.state.childrenTotalAmount + this.state.total;
          this.setState({ statusId: _statusId }, () => {
            if (this.state.invoiceTypeId === 0) {
              message.error("Please select Invoice type Id!");
            } else if (
              this.state.dateStr === "" ||
              this.state.dateStr === "Invalid date"
            ) {
              message.error("Please select date!");
            } else if (
              this.state.dueDate === "" ||
              this.state.dueDate === "Invalid date"
            ) {
              message.error("Please select due date!");
            } else if (this.state.invoiceNo === "") {
              message.error("Please add Invoice no!");
            } else if (this.state.subTotal === 0) {
              message.error("Sub total is 0!");
            } else if (
              this.props.parentInvoiceId !== 0 &&
              _childrenTotalAmount > this.state.parentTotalAmount
            ) {
              message.error(
                "Sum of all child invoices amount can never be greater than parent invoice amount!"
              );
            } else if (this.state.discountAmount > this.state.subTotal) {
              message.error("Discount amount must be less than total amount!");
            } else {
              let _data = {
                invoiceNo: this.state.invoiceNo,
              };
              this.props.checkDuplicateClientInvoice(_data).then(() => {
                let _duplicate = this.props && this.props.duplicate;
                if (
                  _duplicate === true &&
                  this.state.invoiceNo !== _invoiceInfo.invoiceNo
                ) {
                  message.error("Invoice no must be unique!");
                } else {
                  this.props.editInvoice(this.state).then(() => {
                    message.success("Invoice is updated successfully!");
                  });
                }
              });
            }
          });
        }
      );
    });
  };

  handleCancelAddStudentModal = (e) => {
    this.setState({
      showModalAddStudent: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete payment?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.handleDeletePayment(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelSendingReceiptModal = (e) => {
    this.setState({
      visibleSendingReceiptModal: false,
    });
  };

  getSelectedStudents = (students) => {
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _alreadyDefinedStudentsSubTotal = 0;
    let finalListStudents = [...students, ...this.state.students];
    let _subTotal = 0;
    if (students.length > 0) {
      for (let index = 0; index < students.length; index++) {
        let _amount = 0;
        _amount =
          Math.round(
            ((students[index].fee * students[index].percentage) / 100 +
              Number.EPSILON) *
              100
          ) / 100;

        students[index].amount = _amount;
        students[index].uniqueId = Math.random()
          .toString(36)
          .substr(2, 5);
        _subTotal = _subTotal + _amount;
        students[index].studentNumber = students[index].studentNo;
        students[index].studentName =
          students[index].firstName + students[index].lastName;
        students[index].programmeName = students[index].enrolledProgram;
        students[index].startDate = students[index].courseEffectiveDate;
        students[index].programmeFee = students[index].fee;
        students[index].commissionRate = students[index].percentage;
        students[index].commissionAmount = _amount;
        students[index].clientId = students[index].id;
        students[index].programId = 0;
        students[index].createdBy = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
        students[index].id = 0;
        students[index].invoiceId =
          _invoiceInfo && _invoiceInfo.id ? _invoiceInfo.id : 0;
        students[index].ID = 0;
      }
    }
    if (this.state.students.length > 0) {
      for (let index = 0; index < this.state.students.length; index++) {
        _alreadyDefinedStudentsSubTotal =
          _alreadyDefinedStudentsSubTotal + this.state.students[index].amount;
      }
    }
    let finalAmount =
      Math.round(
        (_subTotal + _alreadyDefinedStudentsSubTotal + Number.EPSILON) * 100
      ) / 100;

    this.setState(
      { students: finalListStudents, subTotal: finalAmount },
      () => {
        let _discountedAmountDiscountPercentage;
        let _discountedAmountDiscountFinal;
        let _discountedAmountTaxPercentage;
        let _tempTotalAmount;
        if (this.state.discount !== 0) {
          _discountedAmountDiscountPercentage =
            (this.state.discount * this.state.subTotal) / 100;
          _discountedAmountDiscountFinal =
            this.state.subTotal - _discountedAmountDiscountPercentage;
        } else {
          _discountedAmountDiscountFinal =
            this.state.subTotal - this.state.discountAmount;
        }
        _discountedAmountTaxPercentage =
          (_discountedAmountDiscountFinal * this.state.gst) / 100;

        _tempTotalAmount =
          _discountedAmountTaxPercentage + _discountedAmountDiscountFinal;

        this.setState(
          {
            total: Math.round((_tempTotalAmount + Number.EPSILON) * 100) / 100,
            dueAmount: _tempTotalAmount - this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              subTotal: this.state.subTotal,
              total: this.state.total,
              dueAmount: this.state.dueAmount,
            });
          }
        );
        this.formRef.current.setFieldsValue({
          subTotal: this.state.subTotal,
          total: this.state.total,
        });
        this.setState({ showModalAddStudent: false });
      }
    );
  };

  render() {
    let _gstPercent =
      this.props && this.props.companyData && this.props.companyData.gstPercent;
    let _invoiceInformation =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo;

    return (
      <div>
        <Row>
          <Col xs={23} offset={1}>
            <h4 style={{ marginTop: "6px" }}>
              {_invoiceInformation && _invoiceInformation.id
                ? "Update Invoice"
                : ""}
            </h4>
          </Col>
        </Row>
        <Form
          onFinish={this.onFinish}
          ref={this.formRef}
          initialValues={{
            discount: this.state.discount,
            discountAmount: this.state.discountAmount,
            subTotal: this.state.subTotal,
            gst: 0,
            total: this.state.subTotal,
            dueAmount: 0,
          }}
        >
          <div className="ca-gray-cont">
            <Row style={{ marginTop: "-15px", marginRight: "-21px" }}>
              <Col xs={11}>{this.getDatePickers()}</Col>
              <Col xs={13}>{this.getFilters()}</Col>
            </Row>
            {this.getAddNewLine()}
            {this.getForm()}
            {this.getButtons(_invoiceInformation)}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    invoiceTypesListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceTypesListing &&
      state.invoicesReducer.invoiceTypesListing,
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items &&
      state.invoicesReducer.currencyListing.items,
    lastInvoiceNumber:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.lastInvoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    invoiceInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceSchoolInfo &&
      state.invoicesReducer.invoiceSchoolInfo,
    duplicate:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.duplicate &&
      state.invoicesReducer.duplicate.duplicate &&
      state.invoicesReducer.duplicate.duplicate,
    _invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments,
    invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments.items &&
      state.invoicesReducer.invoicePayments.items,
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    selectedSchoolEmail:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.selectedSchoolEmail,
    companyData:
      state &&
      state.companyReducer &&
      state.companyReducer.companyData &&
      state.companyReducer.companyData,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getAllInvoiceTypes: bindActionCreators(getAllInvoiceTypes, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  addNewInvoice: bindActionCreators(addNewSchoolInvoice, dispatch),
  getLastInvoiceNumber: bindActionCreators(getLastInvoiceNumber, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  getInvoicePayments: bindActionCreators(getInvoicePayments, dispatch),
  deleteInvoicePayment: bindActionCreators(deleteInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editSchoolInvoice, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  getBranch: bindActionCreators(getBranch, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  checkDuplicateClientInvoice: bindActionCreators(
    checkDuplicateClientInvoice,
    dispatch
  ),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  postSchoolInvoiceToXero: bindActionCreators(
    postSchoolInvoiceToXero,
    dispatch
  ),
  updateSchoolInvoiceToXero: bindActionCreators(
    updateSchoolInvoiceToXero,
    dispatch
  ),
});
AddSchoolInvoiceToXero = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSchoolInvoiceToXero);

export default withRouter(AddSchoolInvoiceToXero);
