import React from "react";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
  InputNumber,
} from "antd";
import { bindActionCreators } from "redux";
import {
  addOutgoingPayment,
  getOutgoingPaymentsListing,
  getPaymentSourcesList,
  editOutgoingPayment,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const layoutSendingReceipt = {
  labelCol: { span: 3, offset: 2 },
  wrapperCol: { span: 14, offset: 5 },
};

class AddOutgoingPaymentForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    super(props);
    this.state = {
      clientId: clientprofileid,
      sourceId: 0,
      amount: 0,
      description: "",
      date: "1900-01-01T00:00:00+00",
      agentId: 0,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.props.getPaymentSourcesList().then(() => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props && this.props.outgoingPaymentInfo) {
      let _outgoingPaymentInfo = this.props && this.props.outgoingPaymentInfo;
      if (this.props.paymentSourcesListing) {
        for (
          let index = 0;
          index < this.props.paymentSourcesListing.length;
          index++
        ) {
          if (
            _outgoingPaymentInfo.sourceId ===
            this.props.paymentSourcesListing[index].name
          ) {
            _outgoingPaymentInfo.sourceId = this.props.paymentSourcesListing[
              index
            ].id;
          }
        }
      }
      let _date = moment(_outgoingPaymentInfo.date).format("DD-MM-YYYY");
      let __date = moment(_date, dateFormat);

      this.setState(
        {
          amount: _outgoingPaymentInfo.amount,
          description: _outgoingPaymentInfo.description,
          sourceId: _outgoingPaymentInfo.sourceId,
          date: __date,
          id: _outgoingPaymentInfo.id,
        },
        () => {
          this.formRef.current.setFieldsValue(
            {
              amount: _outgoingPaymentInfo.amount,
              description: _outgoingPaymentInfo.description,
              sourceId:
                _outgoingPaymentInfo.sourceId === 0
                  ? undefined
                  : _outgoingPaymentInfo.sourceId,
              date: __date,
            },
            () => {}
          );
        }
      );
    }
  }

  onFinishAddOutgoingPayments = (values) => {
    let _amount = values && values.amount === undefined ? 0 : values.amount;
    let _description =
      values && values.description === undefined ? "" : values.description;
    let _sourceId =
      values && values.sourceId === undefined ? 0 : values.sourceId;

    this.setState(
      {
        amount: _amount,
        description: _description,
        sourceId: _sourceId,
      },
      () => {
        if (
          this.state.date === "1900-01-01T00:00:00+00" ||
          this.state.date === "Invalid date"
        ) {
          message.error("Please select date!");
        } else {
          let _data = {
            clientprofileid: this.state.clientId,
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo,
          };
          if (this.props && this.props.outgoingPaymentInfo) {
            let _date = this.state.date.toISOString();

            this.setState({ date: _date }, () => {
              this.props.editOutgoingPayment(this.state).then(() => {
                message.success("Payment is updated successfully!");
                this.props.getOutgoingPaymentsListing(_data).then(() => {
                  this.props.handleCancelAddOutgoingPaymentModal();
                });
              });
            });
          } else {
            let _date = this.state.date.toISOString();

            this.setState({ date: _date }, () => {
              this.props.addOutgoingPayment(this.state).then(() => {
                message.success("Payment is added successfully!");
                this.props.getOutgoingPaymentsListing(_data).then(() => {
                  this.props.handleCancelAddOutgoingPaymentModal();
                });
              });
            });
          }
        }
      }
    );
  };

  handleDateChange = (date, dateString) => {
    this.setState({ date: date === null ? "Invalid date" : date }, () => {
      console.log("state", this.state);
    });
  };

  getAddOutgoingPaymentForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Amount"
          name="amount"
          rules={[
            { required: true, message: "Required!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value < 0) {
                  return Promise.reject("Type only positive numbers");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <InputNumber
            size="small"
            style={{ width: "-webkit-fill-available" }}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Date"
          name="date"
          rules={[{ required: true, message: "Required!" }]}
        >
          <DatePicker
            format={dateFormat}
            size="small"
            onChange={this.handleDateChange}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Source"
          name="sourceId"
        >
          <Select placeholder="Please select a source" size="small">
            <Option value={0}>{"Select"}</Option>
            {this.props &&
              this.props.paymentSourcesListing &&
              props.paymentSourcesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Description"
          name="description"
        >
          <Input size="small" />
        </Form.Item>
      </div>
    );
  };

  render() {
    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-outgoing-payments"
          title="OUTGOING PAYMENTS"
          visible={this.props.visibleAddOutgoingPaymentModal}
          onCancel={this.props.handleCancelAddOutgoingPaymentModal}
          footer={null}
          maskClosable={false}
        >
          <Form
            {...layoutSendingReceipt}
            onFinish={this.onFinishAddOutgoingPayments}
            ref={this.formRef}
          >
            {this.getAddOutgoingPaymentForm(this.props)}
            <Row>
              <Col xs={4} offset={18} style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.props.handleCancelAddOutgoingPaymentModal}
                  >
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state &&
    state.outgoingPaymentsReducer &&
    state.outgoingPaymentsReducer.paymentSourcesListing &&
    state.outgoingPaymentsReducer.paymentSourcesListing;

  return {
    paymentSourcesListing:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.paymentSourcesListing &&
      state.outgoingPaymentsReducer.paymentSourcesListing,
    outgoingPaymentInfo:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.outgoingPaymentInfo &&
      state.outgoingPaymentsReducer.outgoingPaymentInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOutgoingPaymentsListing: bindActionCreators(
    getOutgoingPaymentsListing,
    dispatch
  ),
  addOutgoingPayment: bindActionCreators(addOutgoingPayment, dispatch),
  getPaymentSourcesList: bindActionCreators(getPaymentSourcesList, dispatch),
  editOutgoingPayment: bindActionCreators(editOutgoingPayment, dispatch),
});

AddOutgoingPaymentForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOutgoingPaymentForm);

export default withRouter(AddOutgoingPaymentForm);
