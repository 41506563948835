import React from "react";
import HeaderBar from "./../Header/HeaderBar";
import AdminHeaderBar from "./../Header/AdminHeaderBar";
import SupervisorHeader from "./../Header/SupervisorHeader";
import Sidebar from "./../SideBar";
import AdminSidebar from "../../Containers/AdminSidebar";
import Routes from "./../../routes";
import { Layout, Spin } from "antd";
import {
  Router,
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
// var admintoken = null;
const { Header, Sider, Content, Footer } = Layout;
var isSupervisor = localStorage.getItem("isSupervisor") === "True";

class Theme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadClient: false,
      admintoken: localStorage.getItem("admintoken"),
    };
    this.contentRef = React.createRef();
    // this.props.onGetBranch();
    // this.props.onGetUserDetail();
  }
  componentDidMount() {
    // admintoken = localStorage.getItem("admintoken");
    if (this.state.admintoken == null) {
      // this.props.onGetBranch();
      this.props.onGetUserBranch().then((res) => {
        let selectedBranchId = localStorage.getItem("selectedBranchId");
        var findCurrentBranch = res.payload.find(
          (obj) => obj.branchId == selectedBranchId
        );
        if (findCurrentBranch) {
          localStorage.setItem("userManager", findCurrentBranch.isManager);
          localStorage.setItem(
            "selectedBranchName",
            findCurrentBranch.branchName
          );
          localStorage.setItem(
            "defaultCountryId",
            findCurrentBranch.branchDetail.defaultCountry
          );
        }
      });
      this.props.onGetUserDetail();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    // admintoken = localStorage.getItem("admintoken");
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _loader = nextProps.loader;
    if (_loader !== undefined && this.state.loadClient !== nextProps.loader) {
      this.setState({ loadClient: _loader });
    }
  }

  render() {
    const { admintoken } = this.state;
    const {
      onSearchClient,
      onUserLogout,
      userBranchData,
      onSearchPotentialClient,
      onCloseAndOpenSidebar,
      sideBarRes,
      getPotentialClientInfo,
      getPotentialClientUpdateBol,
      onGetClientProfile,
      userDetail,
      onNotificationRemove,
      onGetUserActivity,
      teamMembers,
      userActivityRes,
      onGetTeamMember,
      onGetPermProcessingPerson,
      permProcessingPerson,
      onSearchEmployerMain,
      mainEmpSearchRes,
      branchPermissionRes,
    } = this.props;

    return (
      <div className="tet">
        <Spin spinning={this.state.loadClient} size="large">
          <Layout className="layout-class">
            <Header className="ant-layout-header-padding">
              {admintoken != null ? (
                <AdminHeaderBar
                  onUserLogout={onUserLogout}
                  onCloseAndOpenSidebar={onCloseAndOpenSidebar}
                />
              ) : isSupervisor ? (
                <SupervisorHeader
                  onCloseAndOpenSidebar={onCloseAndOpenSidebar}
                  onUserLogout={onUserLogout}
                />
              ) : (
                <HeaderBar
                  getPotentialClientInfo={getPotentialClientInfo}
                  getPotentialClientUpdateBol={getPotentialClientUpdateBol}
                  onSearchClient={onSearchClient}
                  onUserLogout={onUserLogout}
                  branchData={userBranchData}
                  onNotificationRemove={onNotificationRemove}
                  onSearchPotentialClient={onSearchPotentialClient}
                  onCloseAndOpenSidebar={onCloseAndOpenSidebar}
                  sideBarRes={sideBarRes}
                  history={this.props.history}
                  onGetClientProfile={onGetClientProfile}
                  userDetail={userDetail}
                  reference={this.contentRef}
                  onGetUserActivity={onGetUserActivity}
                  teamMembers={permProcessingPerson}
                  userActivityRes={userActivityRes}
                  onGetTeamMember={onGetTeamMember}
                  onGetPermProcessingPerson={onGetPermProcessingPerson}
                  onSearchEmployerMain={onSearchEmployerMain}
                  mainEmpSearchRes={mainEmpSearchRes}
                  branchPermissionRes={branchPermissionRes}
                />
              )}
            </Header>
            <Layout
              className="site-layout site-layout-background superDashboard"
              // style={{ height: "100vh" }}
            >
              <Sider
                className="main-slider"
                style={{ padding: 0, zIndex: 3 }}
                width={267}
                trigger={null}
                collapsible
                hasSider
                collapsed={sideBarRes}
              >
                {admintoken != null ? (
                  <AdminSidebar activeScreen="Companies" />
                ) : (
                  <Sidebar activeScreen="Dashboard (BI)" />
                )}
              </Sider>
            </Layout>
            <Content
              className={
                (sideBarRes === undefined && "open-sidebar") ||
                (sideBarRes === false && "open-sidebar")
                  ? "open-sidebar"
                  : "close-sidebar"
              }
            >
              <Routes ref={this.contentRef} />
            </Content>
          </Layout>
        </Spin>
      </div>
    );
  }
}

export default Theme;
