import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Input,
  Checkbox,
} from "antd";
// import "./OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const AllMails = ({
  onSetActiveInnerTab,
  //   onGetOutlookMail,
  //   onOutlookLogin,
  //   onAddOutlookAccount,
  //   onRemoveOutlookAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [allMails, setAllMails] = useState(null);
  const [editEnable, setEditEnable] = useState(false);
  const [isMailImport, setIsMailImport] = useState(false);
  const state = useLocation().state;

  const [form] = Form.useForm();

  useEffect(() => {
    getAllMails();
  }, []);

  const getAllMails = () => {
    const getAllMailsOpt = {
      url: `v1/branch/AllMailImportStatus`,
    };
    getAllMailsOpt.types = ["GET_ALL_MAILS_SUCCESS", "GET_ALL_MAILS_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getAllMailsOpt)
      .then((res) => {
        setLoading(false);
        setAllMails(res);
        setIsMailImport(res.isAllMailImport);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onChangeAllMails = (e) => {
    let data = {
      id: allMails.id,
      isAllMailImport: e.target.checked,
    };
    const updAllMailsOpt = {
      url: `v1/branch/UpdateAllMailImportStatus`,
    };
    updAllMailsOpt.types = [
      "UPD_ALL_MAIL_IMPORT_SUCCESS",
      "UPD_ALL_MAIL_IMPORT_FAILURE",
    ];

    setLoading(true);

    apiRefresh
      .put(updAllMailsOpt, data)
      .then((res) => {
        // setAllMails(res);
        getAllMails();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className="mar-top-75">
                <div
                  style={{
                    paddingLeft: allMails && !editEnable ? 20 : 65,
                    paddingRight: allMails && !editEnable ? 20 : 65,
                  }}
                >
                  <div>
                    <Checkbox
                      onChange={onChangeAllMails}
                      checked={isMailImport}
                    >
                      All Mails Import
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default AllMails;
