import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import JobHistory from "../Clients/JobHistory";

import {
  getEmployerManag,
  getClientJobHistory,
  addEmployerJobHistory,
  updEmployerJobHistory,
  removeEmployerJobHistory,
  onGetClientJobStatuses,
  getClientJobHistoryCurrent,
  updateCaseStatus,
  getVisaApplication,
  getClientEmployer,
  addEmployerJob,
  setActiveKey,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  getVisaTypeByCountry,
  getVisaType,
  getAdmissionStatuses,
  getAdmissionProgram,
  getReminderTasks,
  getClientProfile,
  setProfileData,
  getPartnerData,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    employerJobHistoryRes:
      state.EmployerJobHistoryReducer.employerJobHistoryRes,
    employerJobStatusRes: state.EmployerJobHistoryReducer.employerJobStatusRes,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    clientEmployerRes: state.clientEmployerReducer.clientEmployerRes,
    clientTab: state.employerManagReducer.clientTab,
    activeKey: state.employerManagReducer.activeKey,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onGetClientJobHistory: bindActionCreators(getClientJobHistory, dispatch),
    onAddEmployerJobHistory: bindActionCreators(
      addEmployerJobHistory,
      dispatch
    ),
    onUpdEmployerJobHistory: bindActionCreators(
      updEmployerJobHistory,
      dispatch
    ),
    onRemoveEmployerJobHistory: bindActionCreators(
      removeEmployerJobHistory,
      dispatch
    ),
    onGetClientJobStatuses: bindActionCreators(
      onGetClientJobStatuses,
      dispatch
    ),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetClientEmployer: bindActionCreators(getClientEmployer, dispatch),
    onAddEmployerJob: bindActionCreators(addEmployerJob, dispatch),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobHistory);
