import { api } from "../../services/api";
import * as types from "../Constants";

export const addAdminLogin = data => {
  const options = {
    url: "v1/user/identity/AuthenticateAdmin"
  };

  options.types = [
    types.ADD_ADMIN_LOGIN_SUCCESS,
    types.ADD_ADMIN_LOGIN_FAILURE
  ];

  return api.post(options, data);
};
