import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const studentListingReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_STUDENT_LISTING_SUCCESS:
      return { ...state, studentListing: action.payload };
    case types.GET_STUDENT_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Student list is empty!");
        return { ...state, studentListing: [] };
      } else {
         
      }
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
