import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientSummary from "../Dashboard/Dashboard";
import {
  getClientSummary,
  getClientSummarySettings,
  updateClientSummarySettings,
  getProcessingPersons,
  getAllCountries,
  updateClientPriority,
  updateClientCasePriority,
  getCaseStatuses,
  getClientMails,
  updateVisaCaseStatus,
  addCaseTask,
  getCaseTask,
  getReminders,
  addReminder,
  updateReminderForClient,
  completeChecklistTask,
  updateCaseCheckListTask,
  addClientFile,
  getClientFile,
  getClientEmailAndPhone,
  getTaskComment,
  addTaskComment,
  addTaskFollower,
  removeFollower,
  getTasksFollowers,
  getPermissonUser,
  getVisaType,
  getVisaStatus,
  getVisaTypeByCountry,
  getBranchVisaCountries,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    clientSummaryList: state.clientSummaryReducer.clientSummaryList,
    clientSummaryError: state.clientSummaryReducer.clientSummaryError,
    clientSummarySettings: state.clientSummaryReducer.clientSummarySettings,
    processingPersons: state.clientSummaryReducer.processingPersons,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    countryList: state.clientSummaryReducer.countryList,
    visaStatus: state.caseManagementReducer.visaStatus,
    permProcessingPerson: state.clientSummaryReducer.permProcessingPerson,
    visaTypeData: state.clientVisaReducer.countryVisaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientSummary: bindActionCreators(getClientSummary, dispatch),
    onGetClientSummarySettings: bindActionCreators(
      getClientSummarySettings,
      dispatch
    ),
    onUpdateClientSummarySettings: bindActionCreators(
      updateClientSummarySettings,
      dispatch
    ),
    onGetProcessingPersons: bindActionCreators(getProcessingPersons, dispatch),
    onGetAllCountries: bindActionCreators(getAllCountries, dispatch),
    onUpdateClientPriority: bindActionCreators(updateClientPriority, dispatch),
    onUpdateClientCasePriority: bindActionCreators(
      updateClientCasePriority,
      dispatch
    ),
    onGetCaseStatuses: bindActionCreators(getCaseStatuses, dispatch),
    onGetClientMails: bindActionCreators(getClientMails, dispatch),
    onUpdateVisaCaseStatus: bindActionCreators(updateVisaCaseStatus, dispatch),
    onAddTask: bindActionCreators(addCaseTask, dispatch),
    onGetCaseTask: bindActionCreators(getCaseTask, dispatch),
    onGetReminders: bindActionCreators(getReminders, dispatch),
    onAddReminder: bindActionCreators(addReminder, dispatch),
    onUpdateReminder: bindActionCreators(updateReminderForClient, dispatch),
    onCompleteClientTask: bindActionCreators(completeChecklistTask, dispatch),
    onUpdateClientTask: bindActionCreators(updateCaseCheckListTask, dispatch),
    onAddClientFile: bindActionCreators(addClientFile, dispatch),
    onGetClientFile: bindActionCreators(getClientFile, dispatch),
    onGetClientEmailAndPhone: bindActionCreators(
      getClientEmailAndPhone,
      dispatch
    ),
    onGetTaskComment: bindActionCreators(getTaskComment, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSummary);
