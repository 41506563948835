import React from "react";
import Select from "react-select";
import { message, Spin, Button, Upload } from "antd";
import FileDownload from "js-file-download";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";

import TeamMemberCard from "../Components/SuperUserSetting/TeamMemberCard";
import { apiRefresh } from "../services/api";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

var stopLoader = false;

class TeamMemberSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: true,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",
      teamMembersData: [],
    };

    this.props
      .onGetTeamMember()
      .then((res) => {
        let users = res.payload ? res.payload.users : [];
        this.setState({ loading: false, teamMembersData: users });
      })
      .catch((err) => {
        // console.log(this.props.teamMemberError);
        this.setState({ loading: false });
      });
    this.props.onGetBranch();
  }

  componentDidUpdate(prevProps) {
    console.log("show add success value", this.props.addTeamMemberSuccess);
    if (
      prevProps.addTeamMemberSuccess !== this.props.addTeamMemberSuccess &&
      this.props.addTeamMemberSuccess.id !== "Email exists"
    ) {
      this.props
        .onGetTeamMember()
        .then((res) => {
          this.setState({ teamMembersData: res.payload.users });
        })
        .catch((err) => {});
      let createPassword = {
        userId: this.props.addTeamMemberSuccess.id,
        email: this.state.email,
        password: this.state.password,
      };
      let permissionData = {
        userId: this.props.addTeamMemberSuccess.id,
        userSystemPermissions: [
          {
            role: "Client Database",
            status: 1,
          },
        ],
        userloginPermissions: {
          timeStart: "9:00 AM",
          timeEnd: "5:00 PM",
          timeZone: "(UTC+12:00)",
          days: [
            {
              day: "Monday",
              status: 1,
            },
          ],
        },
      };

      this.props.onAddPassword(createPassword);
      // this.props.onAddUserPermission(permissionData);
    }
    if (prevProps.updManagerSuccess !== this.props.updManagerSuccess) {
      //
      // stopLoader = true;
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onSubmit = () => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!strongRegex.test(this.state.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else {
      this.setState({ loading: true });

      let data = {
        roleId: 0,
        title: "",
        image: this.state.addUserImageUrl,
        forgotPasswordBit: false,
        lastSignedIn: new Date(),
        signature: "",
        signatureImage: "",
        timeZone: "",
        comission: 0,
        agreementUrl: "",
        description: "",
        fullName: this.state.fullName,
        alternateEmail: this.state.email,
        status: 0,
        isManager: false,
        isOwner: false,
      };

      console.log(data);

      this.props
        .onAddTeamMember(data)
        .then(() => {
          this.setState({ loading: false });
          if (this.props.addTeamMemberSuccess.id === "Email exists") {
            message.error("User email already exist!");
          } else {
            // this.props.onGetTeamMember();
            let createPassword = {
              userId: this.props.addTeamMemberSuccess.id,
              email: this.state.email,
              password: this.state.password,
            };
            this.props.onAddPassword(createPassword);
            message.success("User added successfully");
            this.setState({
              fullName: "",
              email: "",
              password: "",
              addUserImageUrl: "",
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  updateUserData = (userData, totalBranches) => {
    console.log("show user data from card", userData);
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(userData.alternateEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else {
      const ownerCountOpt = {
        url: `v1/users/OwnerCount`,
      };
      ownerCountOpt.types = [
        "GET_OWNER_COUNT_SUCCES",
        "GET_OWNER_COUNT_FAILURE",
      ];

      apiRefresh.get(ownerCountOpt).then((resp) => {
        this.props
          .onGetTeamMember()
          .then((res) => {
            let users = res.payload ? res.payload.users : [];
            if (users && users.length > 0) {
              for (let ind = 0; ind < users.length; ind++) {
                if (users[ind].id === userData.Id) {
                  if (
                    resp === 1 &&
                    users[ind].isOwner === true &&
                    userData.isOwner === false
                  ) {
                    message.error("Atleast one owner is mandatory!");
                  } else if (resp === 0 || resp > 0 || userData.isOwner) {
                    this.setState({ loading: false }, () => {
                      this.props.onUpdateManager(userData).then((res) => {
                        if (
                          res &&
                          res.payload === "This email is already taken"
                        ) {
                          //donothing
                        } else {
                          message.success("Updated!");
                        }
                      });
                    });
                  } else {
                  }
                }
              }
            }
          })
          .catch((err) => {
            // console.log(this.props.teamMemberError);
            this.setState({ loading: false });
          });
      });
    }
  };

  updateUsersPassword = (passwordData) => {
    console.log("show password data ====== ", passwordData);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!strongRegex.test(passwordData.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (passwordData.password !== passwordData.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      this.setState({ passwordLoading: true });
      let data = {
        userId: passwordData.userId,
        email: passwordData.email,
        password: passwordData.password,
      };
      this.props.onChangeUsersPassword(data).then(() => {
        message.success("User's password updated successfully");
      });
    }
  };

  disableTeamMember = (disableUserData) => {
    this.props.onDisableUser(disableUserData).then(() => {
      if (disableUserData.isLock) {
        message.success("User disabled successfully!");
      } else {
        message.success("User enabled successfully!");
      }
      this.props.onGetTeamMember();
    });
  };

  uploadImage = (info, id) => {
    this.setState({ loadUploadImage: true });
    if (id) {
      this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        if (id) {
          this.setState({
            imageUrl: this.props.imageUploadSuccess,
            loadUploadImage: false,
          });
        } else {
          this.setState({
            addUserImageUrl: this.props.imageUploadSuccess,
            loadUploadImage: false,
          });
        }
      }
    });
  };

  setParentOwnerMethod = (
    ownerBit,
    userDataId,
    totalBranches,
    selectedBranches,
    userData
  ) => {
    const ownerCountOpt = {
      url: `v1/users/OwnerCount`,
    };
    ownerCountOpt.types = ["GET_OWNER_COUNT_SUCCES", "GET_OWNER_COUNT_FAILURE"];
    this.setState({ loading: true }, () => {
      apiRefresh
        .get(ownerCountOpt)
        .then((resp) => {
          this.props
            .onGetTeamMember()
            .then((res) => {
              let users = res.payload ? res.payload.users : [];
              if (users && users.length > 0) {
                for (let ind = 0; ind < users.length; ind++) {
                  if (users[ind].id === userData.Id) {
                    if (
                      resp === 1 &&
                      users[ind].isOwner === true &&
                      userData.isOwner === false
                    ) {
                      message.error("Atleast one owner is mandatory!");
                      this.setState({ loading: true }, () => {
                        this.props
                          .onGetTeamMember()
                          .then((res) => {
                            let users = res.payload ? res.payload.users : [];
                            this.setState({
                              loading: false,
                              teamMembersData: users,
                            });
                          })
                          .catch(() => {
                            this.setState({ loading: false });
                          });
                      });
                    } else {
                      let data = {
                        userId: userDataId,
                        branchIds: totalBranches,
                        userSystemPermissions: [
                          {
                            role: "Client Database",
                            status: 1,
                          },
                          {
                            role: "Client Database (Update)",
                            status: 1,
                          },
                          {
                            role: "Potential Clients",
                            status: 1,
                          },
                          {
                            role: "Add A Client",
                            status: 1,
                          },
                          {
                            role: "Potential Clients (Update)",
                            status: 1,
                          },
                          {
                            role: "Accounts",
                            status: 1,
                          },
                          {
                            role: "Visa Forms",
                            status: 1,
                          },
                          {
                            role: "Reports",
                            status: 1,
                          },
                          {
                            role: "Mail",
                            status: 1,
                          },
                          {
                            role: "Case Management",
                            status: 1,
                          },
                          {
                            role: "Agent Portal",
                            status: 1,
                          },
                          {
                            role: "Agent Portal Accounts",
                            status: 1,
                          },
                          {
                            role: "School Management",
                            status: 1,
                          },
                          {
                            role: "School Management Account",
                            status: 1,
                          },
                          {
                            role: "Employer Management",
                            status: 1,
                          },
                          {
                            role: "Employer Management Account",
                            status: 1,
                          },
                          {
                            role: "Custom Questionnaire",
                            status: 1,
                          },
                          {
                            role: "Deals",
                            status: 1,
                          },
                          {
                            role: "Meetings",
                            status: 1,
                          },
                          {
                            role: "Time Tracking",
                            status: 1,
                          },
                          {
                            role: "Company Level Settings",
                            status: 1,
                          },
                          // {
                          //   role: "Potential Client Account",
                          //   status: 1
                          // }
                        ],
                        userloginPermissions: {
                          timeStart: "",
                          timeEnd: "",
                          timeZone: "",
                          days: [
                            {
                              day: "Monday",
                              status: 1,
                            },
                            {
                              day: "Tuesday",
                              status: 1,
                            },
                            {
                              day: "Wednesday",
                              status: 1,
                            },
                            {
                              day: "Thursday",
                              status: 1,
                            },
                            {
                              day: "Friday",
                              status: 1,
                            },
                            {
                              day: "Saturday",
                              status: 1,
                            },
                            {
                              day: "Sunday",
                              status: 1,
                            },
                          ],
                        },
                        isManager: true,
                      };
                      if (ownerBit) {
                        const opt = {
                          url: "v1/user/permission/InsertOwnerPermission",
                        };
                        opt.types = [
                          "MAKE_OWNER_SUCCESS",
                          "MAKE_OWNER_FAILURE",
                        ];
                        this.setState({ loading: true }, () => {
                          this.props
                            .onUpdateManager(userData)
                            .then((res) => {
                              if (
                                res &&
                                res.payload === "This email is already taken"
                              ) {
                                //donothing
                              } else {
                                apiRefresh
                                  .post(opt, data)
                                  .then((res) => {
                                    this.props &&
                                      this.props
                                        .onGetTeamMember()
                                        .then((res) => {
                                          let users = res.payload
                                            ? res.payload.users
                                            : [];
                                          message.success(
                                            "User is an owner now!"
                                          );
                                          this.setState({
                                            loading: false,
                                            teamMembersData: users,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                  })
                                  .catch((res) => {
                                    this.props &&
                                      this.props
                                        .onGetTeamMember(res)
                                        .then(() => {
                                          let users = res.payload
                                            ? res.payload.users
                                            : [];
                                          this.setState({
                                            loading: false,
                                            teamMembersData: users,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                  });
                              }
                            })
                            .catch(() => {
                              this.setState({ loading: false });
                            });
                        });
                      } else {
                        let _data = {
                          userId: userDataId,
                          branchIds: selectedBranches,
                          userSystemPermissions: [
                            {
                              role: "Client Database",
                              status: 0,
                            },
                            {
                              role: "Client Database (Update)",
                              status: 1,
                            },
                            {
                              role: "Potential Clients (Update)",
                              status: 1,
                            },
                            {
                              role: "Add A Client",
                              status: 0,
                            },
                            {
                              role: "Potential Clients",
                              status: 0,
                            },
                            {
                              role: "Accounts",
                              status: 0,
                            },
                            {
                              role: "Visa Forms",
                              status: 0,
                            },
                            {
                              role: "Reports",
                              status: 0,
                            },
                            {
                              role: "Mail",
                              status: 0,
                            },
                            {
                              role: "Case Management",
                              status: 0,
                            },
                            {
                              role: "Agent Portal",
                              status: 0,
                            },
                            {
                              role: "Agent Portal Accounts",
                              status: 0,
                            },
                            {
                              role: "School Management",
                              status: 0,
                            },
                            {
                              role: "School Management Account",
                              status: 0,
                            },
                            {
                              role: "Employer Management",
                              status: 0,
                            },
                            {
                              role: "Employer Management Account",
                              status: 0,
                            },
                            {
                              role: "Custom Questionnaire",
                              status: 0,
                            },
                            {
                              role: "Deals",
                              status: 0,
                            },
                            {
                              role: "Meetings",
                              status: 0,
                            },
                            {
                              role: "Time Tracking",
                              status: 0,
                            },
                            {
                              role: "Company Level Settings",
                              status: 0,
                            },
                            // {
                            //   role: "Potential Client Account",
                            //   status: 0
                            // }
                          ],
                          userloginPermissions: {
                            timeStart: "",
                            timeEnd: "",
                            timeZone: "",
                            days: [
                              {
                                day: "Monday",
                                status: 0,
                              },
                              {
                                day: "Tuesday",
                                status: 0,
                              },
                              {
                                day: "Wednesday",
                                status: 0,
                              },
                              {
                                day: "Thursday",
                                status: 0,
                              },
                              {
                                day: "Friday",
                                status: 0,
                              },
                              {
                                day: "Saturday",
                                status: 0,
                              },
                              {
                                day: "Sunday",
                                status: 0,
                              },
                            ],
                          },
                          isManager: false,
                        };
                        const opt = {
                          url: "v1/user/permission/InsertOwnerPermission",
                        };
                        opt.types = [
                          "MAKE_OWNER_SUCCESS",
                          "MAKE_OWNER_FAILURE",
                        ];
                        this.setState({ loading: true }, () => {
                          this.props
                            .onUpdateManager(userData)
                            .then((res) => {
                              if (
                                res &&
                                res.payload === "This email is already taken"
                              ) {
                                //donothing
                              } else {
                                apiRefresh
                                  .post(opt, _data)
                                  .then((res) => {
                                    this.props &&
                                      this.props
                                        .onGetTeamMember()
                                        .then((res) => {
                                          let users = res.payload
                                            ? res.payload.users
                                            : [];
                                          message.success(
                                            "User is not an owner now!"
                                          );
                                          this.setState({
                                            loading: false,
                                            teamMembersData: users,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                  })
                                  .catch((res) => {
                                    this.props &&
                                      this.props
                                        .onGetTeamMember()
                                        .then((res) => {
                                          let users = res.payload
                                            ? res.payload.users
                                            : [];
                                          this.setState({
                                            loading: false,
                                            teamMembersData: users,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                  });
                              }
                            })
                            .catch(() => {
                              this.setState({ loading: false });
                            });
                        });
                      }
                    }
                  }
                }
              }
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };
  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  assignBranch = (data) => {
    this.props.onAssignBranch(data);
  };

  unassignBranch = (data) => {
    this.props.onUnassignBranch(data);
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    this.props
      .onExportData()
      .then(function(response) {
        this.setState({ exportLoading: false });
        var blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Ezymigrate.zip");
      })
      .catch((e) => message.error("Unable to export data, please try again!"));
  };

  render() {
    const {
      selectedOption,
      fullName,
      email,
      password,
      imageUrl,
      addUserImageUrl,
      exportLoading,
      loading,
      teamMembersData,
    } = this.state;
    const {
      teamMembers,
      imageUploadSuccess,
      branchData,
      storageData,
      onAssignBranch,
      onUnassignBranch,
      onGetUserBranch,
      onUpdateUserPermission,
    } = this.props;

    return (
      <div>
        {loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <div
              className="sus-content-card-container"
              style={{ paddingRight: 0, width: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    marginTop: 40,
                    marginBottom: 10,
                  }}
                >
                  <span className="sus-cp-text">MEMBERS</span>
                </div>
                {teamMembersData &&
                  teamMembersData.map((item, index) => {
                    let imageUrl = "";
                    if (this.state.imageUpdateId == item.id) {
                      imageUrl = imageUploadSuccess;
                    }

                    return (
                      <TeamMemberCard
                        setParentOwnerMethod={this.setParentOwnerMethod}
                        member={item}
                        parentMethod={this.updateUserData}
                        passwordUpdateMethod={this.updateUsersPassword}
                        onDisable={this.props.onDisableUser}
                        onSuccessUpdation={this.props.updManagerSuccess}
                        uploadImage={this.uploadImage}
                        imageUrl={imageUrl}
                        branchData={branchData}
                        onAssingBranch={onAssignBranch}
                        onUnassignBranch={onUnassignBranch}
                        onGetUserBranch={onGetUserBranch}
                      />
                    );
                  })}
              </div>

              {/* <div className="sus-right-cont" style={{ marginTop: 67 }}>
              <div className="sus-card-cont">
                <div className="sus-card-header">
                  <div>
                    <span className="sus-card-header-text">STORAGE</span>
                  </div>
                  <div>
                    <span className="sus-card-header-small-text">HIDE</span>
                  </div>
                </div>
                <div className="sus-bar-cont">
                  <ProgressBar
                    bgcolor="#05B8E6"
                    completed={storageData ? storageData.used : 0}
                  />
                </div>
                <div className="sus-card-content-cont">
                  <div style={{ width: 100 }}>
                    <span className="sus-card-content-text">Used:</span>
                  </div>
                  <div style={{ width: 100 }}>
                    <span className="sus-card-content-text">
                      {storageData ? storageData.used : 0.0} MB
                    </span>
                  </div>
                </div>
                <div
                  className="sus-card-content-cont"
                  style={{ paddingTop: 0 }}
                >
                  <div style={{ width: 100 }}>
                    <span className="sus-card-content-text">Remaining:</span>
                  </div>
                  <div style={{ width: 100 }}>
                    <span className="sus-card-content-text">
                      {storageData ? storageData.available : 0.0} MB
                    </span>
                  </div>
                </div>
              </div>
              <div className="sus-card-cont" style={{ marginTop: 40 }}>
                <div className="sus-card-header">
                  <div>
                    <span className="sus-card-header-text">DATA EXPORTS</span>
                  </div>
                  <div>
                    <span className="sus-card-header-small-text">HIDE</span>
                  </div>
                </div>
                <div
                  className="sus-card-content-cont"
                  style={{ paddingTop: 25 }}
                >
                  <span
                    className="sus-card-content-text"
                    style={{ color: "#6C6C6C" }}
                  >
                    EXPORT DATA
                  </span>
                </div>
                <div
                  className="sus-card-content-cont"
                  style={{ paddingTop: 0 }}
                >
                  <span
                    className="sus-card-content-text"
                    style={{ color: "#41AAD2" }}
                  >
                    Export All Data to CSV
                  </span>
                </div>

                <div
                  style={{
                    width: "47%",
                    display: "flex",
                    marginLeft: 35,
                    marginTop: 5,
                  }}
                >
                  <Button
                    className="sus-cp-btn"
                    loading={exportLoading}
                    onClick={this.exportData}
                  >
                    <span className="sus-cp-btn-text">EXPORT</span>
                  </Button>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TeamMemberSettings;
