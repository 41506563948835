import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const AdminReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ADMIN_COMPANIES_SUCCESS:
      return { ...state, companiesRes: action.payload };
    case types.GET_ADMIN_COMPANIES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/companies");
      }
    case types.GET_ADMIN_BRANCHES_SUCCESS:
      return { ...state, branchesRes: action.payload };
    case types.GET_ADMIN_BRANCHES_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/companies");
      }

    case types.GET_BRANCH_DETAIL_SUCCESS:
      return { ...state, branchDetailRes: action.payload };
    case types.GET_BRANCH_DETAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/branchDetail");
      }

    case types.UPDATE_BRANCH_DETAIL_SUCCESS:
      return { ...state, updateBranchDetailRes: action.payload };
    case types.UPDATE_BRANCH_DETAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/branchDetail");
      }

    case types.IMPERSONATE_SUCCESS:
      return { ...state, impersonateRes: action.payload };
    case types.IMPERSONATE_SUCCESS:
      if (action.payload.response === "undefined") {
        window.location.assign("/branchDetail");
      }

    case types.GET_COMPANY_USERS_SUCCESS:
      return { ...state, companyUsersRes: action.payload };
    case types.GET_COMPANY_USERS_FAILURE:
      if (action.payload.response === "undefined") {
        // window.location.assign("/branchDetail");
      }

    default:
      return state;
  }
};
