import React, { Fragment, useEffect, useState, useParams } from "react";
import {
  Table,
  Button,
  Space,
  Spin,
  Form,
  Select,
  Modal,
  message,
  Tooltip,
} from "antd";
import DocumentChecklistForm from "../../../Components/DocumentChecklist/DocumentChecklistForm.js";
import EmailDocumentChecklistForm from "../../../Components/DocumentChecklist/EmailDocumentChecklistForm";
import { Link } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

const { Option } = Select;
var URL = "";

const DocumentCheckList = ({
  onGetDocumentChecklist,
  docChecklistRes,
  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,
  potentialClientInfo,
}) => {
  let clientprofileid = potentialClientInfo.id;

  var http = window.location.host == "localhost:3000" ? "http://" : "https://";
  URL = http + window.location.host + "/CheckList/index.htm?param=";
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [selectdata, setSelectdata] = useState([]);
  const [linkdata, setLinkdata] = useState([]);
  const [checklistItem, setChecklistItem] = useState([]);
  const [checklistName, setChecklistName] = useState("");

  // const { id } = useParams();

  // const handleProceed = (e) => {
  //
  //   history.push(`/document-link-client/${id}`);
  // };

  // const [linkId, setLinkId] = useState("");

  useEffect(() => {
    setLoading(true);

    onGetDocumentChecklist().then((res) => {
      setLoading(false);
    });
  }, [onGetDocumentChecklist]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    if (employerCheckListRes)
      setChecklistItem(employerCheckListRes && employerCheckListRes.items);
  }, [employerCheckListRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {}, [docChecklistRes]);

  useEffect(() => {
    setLoading(true);

    onGetEmployerCheckList(clientprofileid)
      .then((res) => {
        setChecklistItem(res.payload.items);
        setLoading(false);
      })
      .catch((err) => {
        setChecklistItem([]);
        setLoading(false);
      });
  }, [onGetEmployerCheckList]);

  // useEffect(() => {
  //   setLoading(true);
  //     setChecklistItem(employerCheckListRes && employerCheckListRes);
  //     setLoading(false);
  // }, [checklistItem]);

  const showModal = (value, data, name) => {
    if (value == "send-email") {
      setLinkdata(data);
    } else {
      setSelectdata(data);
    }
    setChecklistName(name);
    setIsModalVisible(true);
    setCreateMode(value);
  };
  const RenderChecklist = (value) => {
    if (docChecklistRes.items) {
      var item = docChecklistRes.items.find((x) => x.id == value);
      showModal("add-document", item, item.name);
    }
  };

  const onLinkClient = (record) => {
    window.open(URL + record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Link Client",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <div>
          Please{" "}
          <Link
            key={record && record.id}
            className="click-here"
            type="link"
            // to=""
            onClick={() => onLinkClient(record && record.link)}
          >
            Click Here{" "}
          </Link>
          to open
        </div>
      ),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text, record) => (
        <Space size="middle">
          {URL + text}
          {/* <Button
            className="copy-link"
            type="default"
            onClick={() => {
              navigator.clipboard.writeText(URL + text);
              message.info("Copied");
            }}
          >
            Copy Link
          </Button> */}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (text, record) => (
        <Space className="send-btn" size="middle">
          <Tooltip title="Copy Link" style={{ display: "inline-block" }}>
            <CopyOutlined
              style={{
                cursor: "pointer",
                color: "#458ccc",
                fontSize: 16,
                marginTop: 6,
              }}
              onClick={() => {
                navigator.clipboard.writeText(URL + record.link);
                message.info("Copied");
              }}
            />
          </Tooltip>
          <Button
            onClick={() => showModal("send-email", record.link, record.name)}
            type="primary"
          >
            SEND
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <div style={{ width: "25%" }} className="mar-top-1 mb-6rem ">
        <Form>
          <Form.Item name="gender" rules={[{ required: true }]}>
            <Select
              mode="single"
              showArrow
              checked={false}
              placeholder="Select"
              style={{ width: "100%" }}
              onChange={(value) => RenderChecklist(value)}
            >
              {docChecklistRes &&
                docChecklistRes.items &&
                docChecklistRes.items.map((item, index) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="mar-top-1 mb-6rem">
        <Spin size="large" spinning={loading}>
          <div className={"school-table"}>
            <Table
              bordered
              columns={columns}
              dataSource={checklistItem}
              pagination={false}
            />
          </div>
        </Spin>
      </div>
      {isModalVisible && (
        <Modal
          className="reminder-model-main"
          title={"Document Checklist"}
          visible={isModalVisible}
          onCancel={handleCancel}
          header={false}
          footer={false}
          width={800}
        >
          {createMode === "add-document" && (
            <DocumentChecklistForm
              handleCancel={handleCancel}
              createMode={createMode}
              view={"potentialClient"}
              Mode={selectdata}
              setLoading={setLoading}
              loading={loading}
              onAddDocumentChecklist={onAddEmployerCheckList}
              onGetDocumentChecklist={onGetEmployerCheckList}
              showModal={showModal}
              checklistName={checklistName}
              clientprofileid={clientprofileid}
            />
          )}
          {createMode === "send-email" && (
            <EmailDocumentChecklistForm
              handleCancel={handleCancel}
              onSendEmailLink={onSendEmailLink}
              clientprofileid={clientprofileid}
              linkdata={linkdata}
              emailUrl="PotentialClientEmail"
              fromPotentialClient={true}
              checklistName={checklistName}
              clientName={`${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default DocumentCheckList;
