import React, { Fragment, useEffect, useState } from "react";
import { yyyymmddFormate } from "../../Common/reUseFunction";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  DatePicker,
  Form,
  Button,
  Spin,
  Select,
  Card,
  Tabs,
  message,
  Modal,
  Table,
  Tooltip,
} from "antd";
import moment from "moment-timezone";

const Questionnaire = ({
  showModal,
  closeSignaturePanel,
  onGetPotentialClient,
  onGetUpdateBol,
}) => {
  const history = useHistory();
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    const docTypeOpt = {
      url: `v1/dashboardbi/GetQuestionnaire`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh
      .get(docTypeOpt)
      .then((res) => {
        setSignatures(res);
      })
      .catch((err) => {});
  }, []);
  var clientColumns = [
    {
      title: "Questionnaire Name",
      dataIndex: "questionnaireName",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (text, data) => {
        return <a onClick={() => openClientFile(data)}>{text}</a>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
  ];
  const openClientFile = (data) => {
    localStorage.setItem("clientprofileid", JSON.stringify(data.id));
    localStorage.setItem("familyId", data.familyId);
    if (data.memberType.toLowerCase() === "client") {
      localStorage.setItem("clientProfileIdMain", data.clientId);
      localStorage.setItem("familyId", data.familyId);
      history.push({
        pathname: "/profile",
        state: { id: data.clientId },
      });
    } else if (data.memberType.toLowerCase() === "spouse") {
      history.push({
        pathname: "/partner-profile",
        state: { id: data.clientId, familyId: data.familyId },
      });
    } else if (data.memberType.toLowerCase() === "child") {
      history.push({
        pathname: "/client-family-member",
        state: { id: data.clientId, familyId: data.familyId },
      });
    }
  };
  return (
    <Fragment>
      <div className="task-to-do">
        <div class="heading-style" style={{ marginBottom: 15 }}>
          <span style={{ marginLeft: 0 }}>Questionnaires</span>
          <a
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              float: "right",
              marginRight: "-17px",
              marginTop: "-9px",
            }}
          >
            <CloseCircleOutlined
              onClick={() => closeSignaturePanel()}
              style={{ fontSize: "20px" }}
            />
          </a>
        </div>

        <div
          className="reminder-border "
          style={{
            display: "flex",
          }}
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            columns={clientColumns}
            dataSource={signatures}
            pagination={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Questionnaire;
