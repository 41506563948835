import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const schoolReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SEARCH_SCHOOL_SUCCESS:
      return { ...state, searchSchoolListing: action.payload };
    case types.SEARCH_SCHOOL_FAILURE:
         
      console.log("on error console", action.payload);
    case types.GET_STATUSES_SUCCESS:
      return { ...state, statusesListing: action.payload };
    case types.GET_STATUSES_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_TYPE_LISTING_SUCCESS:
      return { ...state, schoolTypeListing: action.payload };
    case types.GET_SCHOOL_TYPE_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "School type list is empty!");
        return { ...state, schoolTypeListing: [] };
      } else {
         
      }
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_LISTING_SUCCESS:
      return { ...state, schoolListing: action.payload };
    case types.GET_SCHOOL_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        // openNotificationWithIcon("success", "School list is empty!");
        return { ...state, schoolListing: [] };
      } else {
         
      }
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_INFO_SUCCESS:
      return { ...state, schoolInfo: action.payload };
    case types.GET_SCHOOL_INFO_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "School list is empty!");
        return { ...state, schoolInfo: [] };
      } else {
         
      }
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_LISTING_FOR_TYPE_SUCCESS:
      return { ...state, schoolListingForType: action.payload };
    case types.GET_SCHOOL_LISTING_FOR_TYPE_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "School list is empty!");
        return { ...state, schoolListingForType: [] };
      } else {
         
      }
      console.log("on error console", action.payload);
    case types.GET_SCHOOL_INVOICES_SUCCESS:
      return { ...state, schoolInvoicesListing: action.payload, invoicesCount: action.payload.totalCount};
    case types.GET_SCHOOL_INVOICES_FAILURE:
      if (
          action.payload &&
          action.payload.response &&
          action.payload.response.status &&
          action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Invoice list is empty!");
        return { ...state, schoolInvoicesListing: [] , invoicesCount: action.payload.totalCount};
      } else {
         
      }
      console.log("on error console", action.payload);
    // case types.DELETE_SCHOOL_SUCCESS:
    //    
    //   console.log("on error console", action.payload);
    //   return { ...state, bol: action.payload.response };
    default:
      return state;
  }
};
