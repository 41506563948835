import { api } from "../../services/api";
import * as types from "../Constants";
import {
  VISA_STATUS_REORDER_FAILURE,
  VISA_STATUS_REORDER_SUCCESS,
} from "../Constants";

export const getVisaStatus = (data) => {
  const options = {
    url: "v1/company/visastatus/All",
  };

  options.types = [types.VISA_STATUS_SUCCESS, types.VISA_STATUS_FAILURE];

  return api.get(options);
};

export const getVisaStatusWithHide = () => {
  const options = {
    url: "v1/company/visastatus/AllWithHide",
  };

  options.types = [
    types.VISA_STATUS_HIDE_SUCCESS,
    types.VISA_STATUS_HIDE_FAILURE,
  ];

  return api.get(options);
};

export const addVisaStatus = (data) => {
  const options = {
    url: "v1/company/visastatus",
  };

  options.types = [
    types.ADD_VISA_STATUS_SUCCESS,
    types.ADD_VISA_STATUS_FAILURE,
  ];

  return api.post(options, data);
};

export const VisaStatusReOrder = (data) => {
  const options = {
    url: "v1/company/visastatus/VisaStatusReOrder",
  };

  options.types = [
    types.VISA_STATUS_REORDER_SUCCESS,
    types.VISA_STATUS_REORDER_FAILURE,
  ];

  return api.post(options, data);
};

export const updVisaStatus = (data) => {
  const options = {
    url: "v1/company/visastatus",
  };

  options.types = [
    types.UPDATE_VISA_STATUS_SUCCESS,
    types.UPDATE_VISA_STATUS_SUCCESS,
  ];

  return api.put(options, data);
};
