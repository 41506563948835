import React, { useState, useEffect, Fragment } from "react";
import { Tabs, Row, Col, Modal, message, Alert } from "antd";
import TastToDo from "./../ReminderTabs/TaskToDo";
import CompletedTasks from "./../ReminderTabs/CompletedTasks";
import CommissionReminders from "./../ReminderTabs/CommissionReminders";
import SideArea from "./SideArea";
import AddReminderModal from "./AddReminderModal";
import AddTasksModal from "./AddTasksModal";
import UpdateModal from "./UpdateModal";
import UpdateDateModal from "./UpdateDateModal";
import ComfirmToDelete from "./ComfirmToDelete";
import AddFollowerModal from "./AddFollowerModal";
import { DataUsageSharp } from "@material-ui/icons";
import UpdateReminderModal from "./UpdateReminderModal";
import activityData from "../../Components/ClientActivity/ActivityData";
import moment from "moment";
import AddTimeTrackingForm from "../../Components/TimeTracking/AddTimeTrackingForm";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const { TabPane } = Tabs;
const InnerHeader = ({
  onGetReminderTasks,

  reminderTaskRes,
  onAddReminderTask,

  onGetDailyTasks,
  dailyTaskRes,

  onAddDailyTasks,

  onRemoveTasks,
  removeTaskRes,

  onUpdetaTasks,

  onUpdateCompletedTask,
  updateComletedTaskRes,

  onGetAllClients,
  allClientData,

  onRemoveReminderTask,
  getTasksComments,
  addTaskComment,
  getAllUsers,
  getTaskFollowers,
  addTaskFollower,
  onSearchClient,
  onSearchPotentialClient,
  searchClientData,
  potentialClientsData,

  onUpdateReminderDetail,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  onRemoveFollower,
  onRemoveTaskClient,
  onAddFileNote,
  onGetCompletedTasks,
  onGetEmployerData,
  onGetTimeTrackingPopup,
  timeTrackingPopupRes,
  onGetVisaWorkType,
  onGetVisaTypePrice,
  onGetServiceType,
  onGetVisaType,
  visaWorkTypeRes,
  visaTypePriceRes,
  serviceTypeRes,
  visaTypeData,
  onGetPermProcessingPerson,
  visaAppData,
  onGetVisaApplication,
}) => {
  const [changeTabs, setChangeTabs] = useState("1");
  const [addReminders, setAddReminders] = useState(false);
  const [addTasks, setAddTasks] = useState(false);
  const [modalType, setModalType] = useState("");
  const [updateTitle, setUpdateTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [reminderData, setReminderData] = useState({});
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [usersPerm, setUsersPerm] = useState([]);
  const [reloadFlag, setReload] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [timeTrackingVisible, setTimeTrackingVisible] = useState(false);
  const [task, setTask] = useState(null);

  useEffect(() => {
    getAllUsers().then((res) => {
      const usersInfo = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      setUsers(usersInfo);

      onGetPermProcessingPerson(true).then((res) => {
        const usersListInfo = res.payload.users.map((item) => {
          return {
            label: item.fullName,
            value: item.id,
          };
        });
        setUsersList(usersListInfo);
      });
    });
    // onGetPermProcessingPerson(true).then((res) => {
    //   const userPermi = res.payload.users.map((item) => {
    //     return {
    //       label: item.fullName,
    //       value: item.id,
    //     };
    //   });
    //   setUsersPerm(userPermi);
    // });
    onGetTimeTrackingPopup();
  }, []);

  useEffect(() => {
    if (timeTrackingPopupRes) {
      setShowPopup(timeTrackingPopupRes.isTimeTrackingPopUp);
    }
  });

  const changeTab = (key) => {
    setChangeTabs(key);
  };

  const showModal = (modalTypeName, id, data) => {
    setModalType(modalTypeName);
    setAddReminders(true);
    setUpdateTitle(id);
    setUpdateData(data);
    setReminderData(id);
  };

  const showAddTask = () => {
    setAddTasks(true);
  };

  const handleCancel = () => {
    setAddReminders(false);
    setAddTasks(false);
    addSuccess();
  };

  const addSuccess = () => {
    setTimeTrackingVisible(false);
  };

  const ComletedTasks = (updateTitle) => {
    console.log("tasskkkk", dailyTaskRes);

    setLoading(true);
    const completed = {
      id: updateTitle.id,
    };
    onUpdateCompletedTask(completed).then(() => {
      setLoading(false);
      setTask([updateTitle]);
      message.success("Successfully Completed!");
      if (
        showPopup &&
        !updateTitle.isPontential &&
        updateTitle.subjectId != "00000000-0000-0000-0000-000000000000"
      ) {
        setTimeTrackingVisible(true);
      }
      setReload(!reloadFlag);
      let userName = localStorage.getItem("userName");
      let myData = {
        clientName: "",
        logMessage:
          "\nTask " + updateTitle.taskTitle + " completed by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Daily Tasks/Reminders",
        invoiceId: "0",
        notClientPart: true,
        linkedClient: updateTitle.subjectId,
      };
      activityData(myData);

      // window.location.reload();
    });
  };

  const removeTask = (updateTitle) => {
    setLoading(true);
    const remove = {
      id: updateTitle,
      delete: true,
    };
    onRemoveTasks(remove).then(() => {
      setLoading(false);
      message.success("Successfully Deleted!");
      alert("Successfully Deleted!");
      handleCancel();
      setReload(!reloadFlag);
      let userName = localStorage.getItem("userName");
      let myData = {
        clientName: "",
        logMessage:
          "\nTask " + updateData.taskTitle + " removed by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Daily Tasks/Reminders",
        invoiceId: "0",
        notClientPart: true,
        linkedClient: updateData.subjectId,
      };
      activityData(myData);
      // window.location.reload();
    });
  };

  return (
    <Fragment>
      <div className="reminder-tabs-header">
        <Tabs
          defaultActiveKey="1"
          onChange={changeTab}
          tabBarStyle={{ fontFamily: "Poppins" }}
        >
          <TabPane tab="Tasks To Do" key="1" />
          <TabPane tab="Completed Tasks" key="2" />
          {/* <TabPane tab="Commission Reminders" key="3" /> */}
        </Tabs>

        <Row className="mar-zero" gutter={16}>
          <Col span={16}>
            {changeTabs === "1" && (
              <span>
                <TastToDo
                  showModal={showModal}
                  onGetReminderTasks={onGetReminderTasks}
                  reminderTaskRes={reminderTaskRes}
                  onGetDailyTasks={onGetDailyTasks}
                  dailyTaskRes={dailyTaskRes}
                  onRemoveTasks={onRemoveTasks}
                  removeTaskRes={removeTaskRes}
                  onGetAllClients={onGetAllClients}
                  onUpdateCompletedTask={onUpdateCompletedTask}
                  updateComletedTaskRes={updateComletedTaskRes}
                  ComletedTasks={ComletedTasks}
                  removeTask={removeTask}
                  getTasksComments={getTasksComments}
                  addTaskComment={addTaskComment}
                  getTaskFollowers={getTaskFollowers}
                  onGetClientProfile={onGetClientProfile}
                  onGetPotentialClient={onGetPotentialClient}
                  onGetUpdateBol={onGetUpdateBol}
                  onRemoveFollower={onRemoveFollower}
                  users={users}
                  onRemoveTaskClient={onRemoveTaskClient}
                  onSearchClient={onSearchClient}
                  onSearchPotentialClient={onSearchPotentialClient}
                  reloadFlag={reloadFlag}
                  setReload={setReload}
                  onAddFileNote={onAddFileNote}
                  onGetEmployerData={onGetEmployerData}
                  onGetPermProcessingPerson={onGetPermProcessingPerson}
                />
              </span>
            )}

            {changeTabs === "2" && (
              <span>
                <CompletedTasks
                  showModal={showModal}
                  onGetDailyTasks={onGetDailyTasks}
                  dailyTaskRes={dailyTaskRes}
                  ComletedTasks={ComletedTasks}
                  onUpdetaTasks={onUpdetaTasks}
                  updateTitle={updateTitle}
                  onRemoveTasks={onRemoveTasks}
                  getTasksComments={getTasksComments}
                  addTaskComment={addTaskComment}
                  getTaskFollowers={getTaskFollowers}
                  onGetClientProfile={onGetClientProfile}
                  onGetPotentialClient={onGetPotentialClient}
                  onGetUpdateBol={onGetUpdateBol}
                  onRemoveFollower={onRemoveFollower}
                  onRemoveTaskClient={onRemoveTaskClient}
                  onSearchClient={onSearchClient}
                  onSearchPotentialClient={onSearchPotentialClient}
                  reloadFlag={reloadFlag}
                  setReload={setReload}
                  onAddFileNote={onAddFileNote}
                  onGetCompletedTasks={onGetCompletedTasks}
                  users={users}
                  onGetEmployerData={onGetEmployerData}
                />
              </span>
            )}
            {changeTabs === "3" && (
              <span>
                <div style={{ height: "100vh" }}>
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    Coming Soon
                  </h3>
                </div>
                {/*<CommissionReminders showModal={showModal} />*/}
              </span>
            )}
          </Col>
          <Col span={8}>
            <SideArea
              showModal={showModal}
              showAddTask={showAddTask}
              onGetReminderTasks={onGetReminderTasks}
              onAddReminderTask={onAddReminderTask}
              reminderTaskRes={reminderTaskRes}
              onGetDailyTasks={onGetDailyTasks}
              onGetAllClients={onGetAllClients}
              allClientData={allClientData}
              onRemoveReminderTask={onRemoveReminderTask}
              getAllUsers={getAllUsers}
            />
          </Col>
        </Row>
      </div>
      <div className="reminder-model">
        <Modal
          className="reminder-model-main"
          title={
            (modalType === "add-reminder" && "ADD REMINDER") ||
            (modalType === "add-task" && "ADD TASKS") ||
            (modalType === "for-update" && "UPDATE") ||
            (modalType === "for-update-date" && "RESCHEDULE TASK") ||
            (modalType === "confirm-delete" && "CONFIRMATION") ||
            (modalType === "add-follower" && "ADD FOLLOWER") ||
            (modalType === "update-reminder" && "UPDATE REMINDER")
          }
          visible={addReminders}
          onCancel={handleCancel}
          Header={false}
          footer={false}
        >
          {modalType === "add-reminder" && (
            <AddReminderModal
              onAddReminderTask={onAddReminderTask}
              onGetReminderTasks={onGetReminderTasks}
              searchClientData={searchClientData}
              potentialClientsData={potentialClientsData}
              onSearchClient={onSearchClient}
              onSearchPotentialClient={onSearchPotentialClient}
              handleCancel={handleCancel}
              reminderData={reminderData}
              onUpdateReminderDetail={onUpdateReminderDetail}
            />
          )}
          {modalType === "update-reminder" && (
            <UpdateReminderModal
              onAddReminderTask={onAddReminderTask}
              onGetReminderTasks={onGetReminderTasks}
              searchClientData={searchClientData}
              potentialClientsData={potentialClientsData}
              onSearchClient={onSearchClient}
              onSearchPotentialClient={onSearchPotentialClient}
              handleCancel={handleCancel}
              reminderData={reminderData}
              onUpdateReminderDetail={onUpdateReminderDetail}
            />
          )}
          {modalType === "add-task" && (
            <AddTasksModal
              onAddDailyTasks={onAddDailyTasks}
              onGetDailyTasks={onGetDailyTasks}
              onGetAllClients={onGetAllClients}
              allClientData={allClientData}
              getAllUsers={getAllUsers}
              onGetPermProcessingPerson={onGetPermProcessingPerson}
              onSearchClient={onSearchClient}
              onSearchPotentialClient={onSearchPotentialClient}
              reloadFlag={reloadFlag}
              setReload={setReload}
              handleCancel={handleCancel}
            />
          )}
          {modalType === "for-update" && (
            <UpdateModal
              onUpdetaTasks={onUpdetaTasks}
              onGetDailyTasks={onGetDailyTasks}
              dailyTaskRes={dailyTaskRes}
              updateTitle={updateTitle}
              updateData={updateData}
              reloadFlag={reloadFlag}
              setReload={setReload}
              handleCancel={handleCancel}
            />
          )}
          {modalType === "for-update-date" && (
            <UpdateDateModal
              onUpdetaTasks={onUpdetaTasks}
              onGetDailyTasks={onGetDailyTasks}
              dailyTaskRes={dailyTaskRes}
              updateTitle={updateTitle}
              updateData={updateData}
              reloadFlag={reloadFlag}
              setReload={setReload}
              handleCancel={handleCancel}
            />
          )}
          {modalType === "add-follower" && (
            <AddFollowerModal
              onUpdetaTasks={onUpdetaTasks}
              onGetDailyTasks={onGetDailyTasks}
              dailyTaskRes={dailyTaskRes}
              updateTitle={updateTitle}
              updateData={updateData}
              getAllUsers={getAllUsers}
              addTaskFollower={addTaskFollower}
              users={usersList}
              handleCancel={handleCancel}
              reloadFlag={reloadFlag}
              setReload={setReload}
              onGetPermProcessingPerson={onGetPermProcessingPerson}
            />
          )}
          {modalType === "confirm-delete" && (
            <ComfirmToDelete
              removeTask={removeTask}
              updateTitle={updateTitle}
              handleCancel={handleCancel}
            />
          )}
        </Modal>
        <Modal
          title="Time Tracking"
          visible={timeTrackingVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          header={false}
          footer={false}
          width={550}
        >
          <AddTimeTrackingForm
            onGetVisaType={onGetVisaType}
            onGetVisaWorkType={onGetVisaWorkType}
            onGetVisaTypePrice={onGetVisaTypePrice}
            onGetServiceType={onGetServiceType}
            visaTypeData={visaTypeData}
            visaWorkTypeRes={visaWorkTypeRes}
            visaTypePriceRes={visaTypePriceRes}
            serviceTypeRes={serviceTypeRes}
            // getTasks={getTasks}
            tasks={task}
            addSuccess={addSuccess}
            handleCancel={handleCancel}
            completed={true}
            clientId={task && task[0].subjectId}
            visaAppData={visaAppData}
            onGetVisaApplication={onGetVisaApplication}
          />
        </Modal>
      </div>
    </Fragment>
  );
};

export default InnerHeader;
