import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientHistory = (
  clientid,
  familyId,
  emailType,
  pageSize,
  pageNumber,
  visaId
) => {
  const options = {
    url: `v1/imap/ClientEmailHistory/${clientid}/${familyId}/${emailType}/${pageSize}/${pageNumber}/${visaId}`,
  };

  options.types = [
    types.GET_EMAIL_HISTORY_SUCCESS,
    types.GET_EMAIL_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const addDraftClient = (data) => {
  const options = {
    url: "v1/imap/ClientEmail",
  };
  options.types = [
    types.ADD_DRAFT_CLIENT_SUCCESS,
    types.ADD_DRAFT_CLIENT_FAILURE,
  ];

  return api.post(options, data);
};

export const deleteClientEmail = (data) => {
  const options = {
    url: "v1/imap/ClientEmail",
  };

  options.types = [
    types.DELETE_CLIENT_EMAIL_SUCCESS,
    types.DELETE_CLIENT_EMAIL_FAILURE,
  ];

  return api.delete(options, data);
};

export const addEmailDocument = (data) => {
  const options = {
    url: "v1/document/MultiUploadWithFileName",
  };
  options.types = [
    types.ADD_EMAIL_DOCUMENT_SUCCESS,
    types.ADD_EMAIL_DOCUMENT_FAILURE,
  ];

  return api.post(options, data);
};

export const getDocumentDownload = (document, documentId) => {
  let docId = documentId || document.id;
  const options = {
    url: `v1/document/GetDocumentBytes/${docId}/${document.blobFileName}`,
  };

  options.types = [
    types.GET_DOCUMENT_DOWNLOAD_SUCCESS,
    types.GET_DOCUMENT_DOWNLOAD_FAILURE,
  ];

  return api.getFile(options);
};

export const getDocumentPdf = (DocumentName) => {
  const options = {
    url: `v1/document/GetDocumentPDF/${DocumentName}`,
  };

  options.types = [
    types.GET_DOCUMENT_PDF_SUCCESS,
    types.GET_DOCUMENT_PDF_FAILURE,
  ];

  return api.get(options);
};

export const getFilteredType = () => {
  const options = {
    url: `v1/document/type/All`,
  };

  options.types = [
    types.GET_DOCUMENT_FILTERTYPE_SUCCESS,
    types.GET_DOCUMENT_FILTERTYPE_FAILURE,
  ];

  return api.get(options);
};

export const addFilteredType = (data) => {
  const options = {
    url: "v1/document/UpdateDocumentType",
  };
  options.types = [
    types.ADD_DOCUMENT_FILTERTYPE_SUCCESS,
    types.ADD_DOCUMENT_FILTERTYPE_FAILURE,
  ];

  return api.post(options, data);
};

export const clientFamilyEmails = (id) => {
  const options = {
    url: `v1/client/email/AllByFamily/${id}`,
  };
  options.types = [
    types.CLIENT_FAMILY_EMAIL_SUCCESS,
    types.CLIENT_FAMILY_EMAIL_FAILURE,
  ];

  return api.get(options);
};

export const getPdf = (data) => {
  const options = {
    url: `v1/config/GetPDF`,
  };
  options.types = [types.GET_PDF_SUCCESS, types.GET_PDF_FAILURE];

  return api.post(options, data);
};

export const linkEmail = (data) => {
  const options = {
    url: `v1/email/visaemail`,
  };
  options.types = [types.LINK_EMAIL_SUCCESS, types.LINK_EMAIL_FAILURE];

  return api.post(options, data);
};
