import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEmailImport,
  setActiveInnerTab,
  updEmailImport,
} from "../store/Actions";
import EmailImportSettings from "../AccountSetting/EmailImportSettings";

const mapStateToProps = (state) => {
  return {
    docViewRes: state.docViewReducer.docViewRes,
    addDocViewRes: state.docViewReducer.addDocViewRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmailImport: bindActionCreators(getEmailImport, dispatch),
    onUpdEmailImport: bindActionCreators(updEmailImport, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailImportSettings);
