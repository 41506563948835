import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Input,
  Table,
} from "antd";
// import "./OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const CoverGeniusToken = ({
  onSetActiveInnerTab,
  //   onGetOutlookMail,
  //   onOutlookLogin,
  //   onAddOutlookAccount,
  //   onRemoveOutlookAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [tokenData, setTokenData] = useState({
    maxToken: 0,
    remaining: 0,
    consumed: 0,
  });
  const state = useLocation().state;

  useEffect(() => {
    getToken();
  }, []);

  const columns = [
    {
      width: "50px",
      title: "Consumed",
      dataIndex: "consumed",
      key: "consumed",
    },
    {
      width: "50px",
      title: "Remaining",
      dataIndex: "remaining",
      key: "remaining",
    },
    {
      width: "50px",
      title: "Total",
      dataIndex: "maxToken",
      key: "maxToken",
    },
  ];

  const getToken = () => {
    const getTokenOpt = {
      url: `v1/openAI/UserMaxToken`,
    };
    getTokenOpt.types = ["GET_TOKEN_SUCCESS", "GET_TOKEN_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getTokenOpt)
      .then((res) => {
        setLoading(false);
        setTokenData(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img
                      src={Images.coverLetterView}
                      className="sus-bottom-icon"
                      style={{ width: 50 }}
                    />
                  </div>
                  <span className="top-text">Cover Genius Token</span>
                  <Tooltip placement="topLeft" title={``}>
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <Table
                  columns={columns}
                  dataSource={[tokenData]}
                  className="border-3 table-head"
                  bordered
                  //   scroll={{ x: "auto" }}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default CoverGeniusToken;
