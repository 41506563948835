import React, { useEffect, useState, Fragment } from "react";
import { Select, Spin, Modal, Form, message } from "antd";
import moment from "moment";

import debounce from "lodash/debounce";
import { Images } from "./../../Themes";
import activityData from "../../Components/ClientActivity/ActivityData";

const { Option } = Select;

const ClientSideBar = ({
  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onAddProfileClientTag,
  addProfielTagRes,

  onRemoveProfileClientTag,
  removeProfileTagRes,
  clientId,
  isEmployer,
}) => {
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [visibleTag, setVisibleTag] = useState("");
  let clientprofileid = clientId;
  const confirmationTag = (id, value) => {
    if (value) {
      setModal1Visible(value);
    }
    if (id) {
      setSelectedTag(id);
    }
  };

  const removeProfileTag = (item) => {
    setLoading(true);
    const removeData = {
      id: item.id,
      delete: true,
    };
    onRemoveProfileClientTag(removeData)
      .then(() => {
        let userName = localStorage.getItem("userName");
        if (isEmployer) {
          var profileData = JSON.parse(sessionStorage.getItem("profileData"));
          let myData = {
            clientName: profileData.fullName,
            logMessage: "Tag " + item.tag + " removed by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tag",
            invoiceId: "0",
          };
          activityData(myData);
        }
        onGetProfileClientTag(clientprofileid)
          .then(() => {
            setLoading(false);
            message.success("Successfully Deleted!");
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    onGetClientTag().then(() => {
      setLoading(false);
    });
  }, [onGetClientTag]);

  useEffect(() => {
    let clientprofileid = clientId;
    onGetProfileClientTag(clientprofileid);
  }, [onGetProfileClientTag]);

  const selectNewTag = () => {
    setLoading(true);
    let clientprofileid = clientId;
    const data = {
      subjectId: clientprofileid,
      tagId: Number(selectedTag),
    };

    var clientTag =
      clientTagRes && clientTagRes.items.find((obj) => obj.id == selectedTag);
    var tagName = "";
    if (clientTag) {
      tagName = clientTag.name;
    }
    onAddProfileClientTag(data).then(() => {
      onGetProfileClientTag(clientprofileid).then(() => {
        setSelectedTag("");
        setModal1Visible(false);
        setLoading(false);
        message.success("Successfully Added!");
        let userName = localStorage.getItem("userName");
        if (!isEmployer) {
          var profileData = JSON.parse(sessionStorage.getItem("profileData"));
          let myData = {
            clientName: profileData.fullName,
            logMessage: "Tag " + tagName + " assigned by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tag",
            invoiceId: "0",
          };
          activityData(myData);
        }
      });
    });
  };

  return (
    <Fragment>
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        size="small"
        spinning={loading}
      >
        <div
          className="form-container"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          ></div>
          <Modal
            title={false}
            style={{ top: 20 }}
            visible={modal1Visible}
            onOk={() => selectNewTag()}
            onCancel={() => setModal1Visible(false)}
            Header={false}
          >
            <h5>Are you sure you want to add client tag?</h5>
          </Modal>
          <div style={{ padding: 10 }}>
            <Form.Item>
              <Select
                mode="single"
                showArrow
                checked={false}
                onChange={(value) => confirmationTag(value, !modal1Visible)}
                placeholder="Select Client Tag"
                style={{ width: "100%" }}
                value={selectedTag}
              >
                {clientTagRes &&
                  clientTagRes.items.map((item, index) => (
                    <Option key={item.id}> {item.name}</Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          {getClientRes &&
            getClientRes.items &&
            getClientRes.items.length > 0 &&
            getClientRes.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    index % 1 === 0 ? "client-tag-btn" : "button-second"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => removeProfileTag(item)}
                >
                  <div className="button-first-second-row">
                    <img src={Images.crossWhite} className="btn-cross-icon" />
                    <span className="btn-fst-scnd-text">{item.tag}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </Spin>
    </Fragment>
  );
};

export default ClientSideBar;
