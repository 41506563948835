import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const allClientReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_PROFILE_CLIENT_TAG_SUCCESS:
      return { ...state, getClientRes: action.payload };
    case types.GET_PROFILE_CLIENT_TAG_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CLIENT_SOURCE_LISTING_SUCCESS:
      return { ...state, clientSourceListing: action.payload };
    case types.GET_CLIENT_SOURCE_LISTING_FAILURE:
       

    case types.ADD_PROFILE_CLIENT_TAG_SUCCESS:
      return { ...state, addProfielTagRes: action.payload };
    case types.ADD_PROFILE_CLIENT_TAG_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_PROFILE_CLIENT_TAG_SUCCESS:
      return { ...state, removeProfileTagRes: action.payload };
    case types.DELETE_PROFILE_CLIENT_TAG_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    //   case types.PUT_CLIENT_TAG_SUCCESS:
    //     return { ...state, updateClientTagRes: action.payload };
    //   case types.PUT_CLIENT_TAG_FAILURE:
    //      
    //     if (action.payload.response === "undefined") {
    //       window.location.assign("/login");
    //     }

    default:
      return state;
  }
};
