import { api } from "../../services/api";
import * as types from "../Constants";

export const getDashboardEmployers = (data) => {
  const options = {
    url: `v1/dashboard/Employer`,
  };
  options.types = [
    types.GET_DASHBOARD_EMPLOYERS_SUCCESS,
    types.GET_DASHBOARD_EMPLOYERS_FAILURE,
  ];

  return api.post(options, data);
};

// export const updateDashboardReminderStatus = (data) => {
//   const options = {
//     url: `v1/reminder/ReminderStatus`,
//   };
//   options.types = [
//     types.UPDATE_DASHBOARD_REMINDER_STATUS_SUCCESS,
//     types.UPDATE_DASHBOARD_REMINDER_STATUS_FAILURE,
//   ];

//   return api.put(options,data);
// };
