import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  FolderAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { Table, message, Modal, Upload, Button, Form, Spin } from "antd";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const LetterTemplateTable = ({
  onGetLetterTemplates,
  LetterTemplatesRes,
  storeData,
  setIsModalVisible,
  setCreateMode,
  setUpdatedata,

  onSortLetterTemplates,

  removeTag,
}) => {
  const [dataSource, setDataSource] = useState(null);
  const [Attachments, setAttachments] = useState([]);
  const [showpopup, setShowpopup] = useState(false);
  const [TemplateId, setTemplateId] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newIndexdata, setNewIndexdata] = useState();
  const [oldIndexdata, setOldIndexdata] = useState();

  useEffect(() => {
    letterTempaltesFilter(LetterTemplatesRes);
  }, [LetterTemplatesRes]);

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "LETTER") {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
      setDataSource(filterList);
    }
  };

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined onClick={() => showModal(record, "edit-value")} />
            <FolderAddOutlined
              onClick={() => ShowAttachments(record && record.id)}
            />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const attachmentsColumns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <DeleteOutlined onClick={() => removeAttach(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      let idArray = [];
      for (var i = 0; i < newData.length; i++) {
        idArray.push({ id: newData[i].id, newOrder: i });
      }
      setDataSource(newData);
      setOldIndexdata(oldIndex);
      setNewIndexdata(newIndex);

      const data = {
        id: dataSource && dataSource.length > 0 && dataSource[oldIndex].id,
        oldOrder: oldIndex,
        newOrder: newIndex,
      };

      onSortLetterTemplates({ items: idArray }).then(() =>
        onGetLetterTemplates()
      );
    }
  };

  const onFinish = () => {
    const data = {
      id: dataSource && dataSource.id,
      oldOrder: oldIndexdata,
      newOrder: newIndexdata,
    };

    onSortLetterTemplates(data).then(() => onGetLetterTemplates());
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      rowSelection
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      dataSource &&
      dataSource.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const showModal = (value, modelType) => {
    const getContractOpt = {
      url: `v1/template/${value.id}`,
    };

    getContractOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.get(getContractOpt).then((res) => {
      setIsModalVisible(res);
      setCreateMode(modelType);
      setUpdatedata(res);
      window.scrollTo({ top: 250, left: 0, behavior: "smooth" });
    });
  };
  const ShowAttachments = (id) => {
    setTemplateId(id);
    setLoading(true);
    const docTypeOpt1 = {
      url: `v1/template/Attachments/All/` + id,
    };
    docTypeOpt1.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh
      .get(docTypeOpt1)
      .then((res) => {
        if (res.items) {
          setAttachments(res.items);
        } else {
          setAttachments([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    setShowpopup(true);
  };
  const handleCancel = () => {
    setShowpopup(false);
  };

  const handleSubmit = () => {
    if (fileList.length > 10) {
      message.error("10 files at a time can be uploaded.");
    } else if (fileList && fileList.length > 0) {
      let fileArray = [];
      let formData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        formData.append("files", fileList[i].originFileObj);
      }

      formData.append("TemplateId", TemplateId);

      const docTypeOpt = {
        url: `v1/template/Attachments`,
      };
      docTypeOpt.types = [
        types.GET_DOCUMENT_TYPE_SUCCESS,
        types.GET_DOCUMENT_TYPE_FAILURE,
      ];
      setLoading(true);
      apiRefresh
        .post(docTypeOpt, formData)
        .then((res) => {
          setLoading(false);
          setFileList([]);
          setShowpopup(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    } else setFileList(fileList);
  };
  const removeAttach = (id) => {
    setLoading(true);
    const docTypeOpt = {
      url: `v1/template/Attachment`,
    };
    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    var data = new Object();
    data.id = id;

    apiRefresh
      .delete(docTypeOpt, data)
      .then((res1) => {
        const docTypeOpt1 = {
          url: `v1/template/Attachments/All/` + TemplateId,
        };
        docTypeOpt1.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];

        apiRefresh
          .get(docTypeOpt1)
          .then((res) => {
            if (res.items) {
              setAttachments(res.items);
            } else {
              setAttachments([]);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Form onFinish={onFinish}>
        <Form.Item name="name">
          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns && columns}
            // onFinish={onTableChange}
            // onChange={}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </Form.Item>
      </Form>
      <Modal
        visible={showpopup}
        title="Attachments"
        footer={null}
        onCancel={handleCancel}
      >
        <Spin size="large" spinning={loading}>
          <div className="client-doc-main">
            <Upload
              listType="picture-card"
              fileList={fileList}
              beforeUpload={() => false}
              onChange={onHandleChange}
              multiple
              width="100"
            >
              {fileList && fileList.length < 10 ? (
                <div className="import-file-button-sec">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </div>
              ) : null}
            </Upload>
            <Button
              style={{ marginTop: "27px", float: "right" }}
              onClick={handleSubmit}
              className={fileList && fileList[0] && "button-blue"}
              type="primary"
              disabled={!(fileList && fileList[0])}
            >
              Upload
            </Button>
          </div>

          <Table
            pagination={false}
            dataSource={Attachments}
            columns={attachmentsColumns}
            width="100"
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
          />
        </Spin>
      </Modal>
    </div>
  );
};
export default LetterTemplateTable;
