import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ["data"],
  loginSuccess: ["success"],
  loginFailure: ["error"],

  nullSuccessRequest: ["onSuccess"]
});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  onSuccess: null,
  fetching: false,
  error: null
});

/* ------------- Reducers ------------- */

// we're attempting to signin
export const request = state => {
  console.log("on redux call");
  return state.merge({ fetching: true });
};

// we've successfully signed in
export const success = (state, { success }) =>
  state.merge({ fetching: false, onSuccess: success });

// we've had a problem signin
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// call after routing form login

export const nullrequest = (state, { nullSuccess }) =>
  state.merge({ onSuccess: nullSuccess });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,

  [Types.NULL_SUCCESS_REQUEST]: nullrequest
});
