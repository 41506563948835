import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

import {
  userLogin,
  userLogout,
  getUserDetail,
  refreshToken,
  addNotificationToken,
  removeNotificationToken,
  userExternalLogin,
  passwordRecovery,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPasswordRecovery: bindActionCreators(passwordRecovery, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
