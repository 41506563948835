import React, { Fragment, useEffect, useState } from "react";
import { Checkbox, message } from "antd";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
import { Link, useHistory } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const Signature = ({ showModal, closeSignaturePanel }) => {
  const history = useHistory();
  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    const docTypeOpt = {
      url: `v1/client/contract/GetSignatures`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh
      .get(docTypeOpt)
      .then((res) => {
        setSignatures(res);
      })
      .catch((err) => {});
  }, []);
  const openClientFile = (data) => {
    localStorage.setItem("clientProfileIdMain", data.clientId);
    localStorage.setItem("familyId", data.familyId);
    if (data.memberType.toLowerCase() === "client") {
      localStorage.setItem("clientProfileIdMain", data.clientMainId);
      localStorage.setItem("familyId", data.familyId);
      history.push({
        pathname: "/profile",
        state: { id: data.clientMainId },
      });
    } else if (data.memberType.toLowerCase() === "spouse") {
      history.push({
        pathname: "/partner-profile",
        state: { id: data.clientMainId, familyId: data.familyId },
      });
    } else if (data.memberType.toLowerCase() === "child") {
      history.push({
        pathname: "/client-family-member",
        state: { id: data.clientMainId, familyId: data.familyId },
      });
    }
  };

  return (
    <Fragment>
      <div className="task-to-do">
        <div class="heading-style" style={{ marginBottom: 15 }}>
          <span style={{ marginLeft: 0 }}>Digital Signature</span>
          <a
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              float: "right",
              marginRight: "-17px",
              marginTop: "-9px",
            }}
          >
            <CloseCircleOutlined
              onClick={() => closeSignaturePanel()}
              style={{ fontSize: "20px" }}
            />
          </a>
        </div>
        {signatures &&
          signatures.length > 0 &&
          signatures.map((data, index) => {
            return (
              <div
                className="reminder-border "
                style={{
                  display: "flex",
                }}
              >
                <div style={{ width: "50%" }} className="d-flex ">
                  <div>
                    <div
                      className="marked-name"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => openClientFile(data)}
                    >
                      <span
                        style={{
                          color: "#1281BC",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#ffffff",
                        }}
                      >
                        {data.clientName}
                      </span>
                    </div>
                    <div>
                      <div>
                        <span
                          className="cv-normal-text"
                          style={{
                            fontSize: 10,
                            fontWeight: "600",
                            marginLeft: 0,
                          }}
                        >
                          {yyyymmddFormate(data.signedDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default Signature;
