import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MailChimp from "../AccountSetting/TimeTracking/SettingTimeTracking";

import {
  getTimeTracking,
  addTimeTracking,
  setActiveInnerTab,
  getTimeTrackingPopup,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    timeTrackingRes: state.timeTrackingReducer.timeTrackingRes,
    timeTrackingPopupRes: state.timeTrackingReducer.timeTrackingPopupRes,
    addTimeTrackingRes: state.timeTrackingReducer.addTimeTrackingRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTimeTracking: bindActionCreators(getTimeTracking, dispatch),
    onGetTimeTrackingPopup: bindActionCreators(getTimeTrackingPopup, dispatch),
    onAddTimeTracking: bindActionCreators(addTimeTracking, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimp);
