import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Batches from "../Supervisor/batches/Batches";

import {
  getAllBatches,
  createBatch,
  updateBatch,
  removeBatch
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    batches: state.supervisorReducer.batches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllBatches: bindActionCreators(getAllBatches, dispatch),
    onCreateBatch: bindActionCreators(createBatch, dispatch),
    onUpdateBatch: bindActionCreators(updateBatch, dispatch),
    onRemoveBatch: bindActionCreators(removeBatch, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Batches);
