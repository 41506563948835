import * as types from "../Constants";
import { api } from "../../services/api";
import {
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE_SUCCESS,
  GET_TEMPLATE_INFO_SUCCESS,
  UPDATE_TEMPLATE_STATUS_SUCCESS,
} from "../Constants";

export const setTemplateStatus = (data) => {
  let _data = {
    id: data.id,
    status: data.status,
  };

  const options = {
    url: "v1/client/balance/UpdateStatus",
  };

  options.types = [
    types.UPDATE_TEMPLATE_STATUS_SUCCESS,
    types.UPDATE_TEMPLATE_STATUS_FAILURE,
  ];
  return api.put(options, _data);
};

export const addNewTemplate = (data) => {
  let _data = {
    name: data.name,
    description: data.description,
    stages: data.stages,
    total: data.total,
  };

  const options = {
    url: "v1/invoice/template",
  };

  options.types = [types.ADD_TEMPLATE_SUCCESS, types.ADD_TEMPLATE_FAILURE];
  return api.post(options, _data);
};

export const editTemplate = (data) => {
  let _data = {
    id: data.id,
    name: data.name,
    description: data.description,
    stages: data.stages,
    total: data.total,
  };

  const options = {
    url: "v1/invoice/template",
  };

  options.types = [types.EDIT_TEMPLATE_SUCCESS, types.EDIT_TEMPLATE_FAILURE];
  return api.put(options, _data);
};

export const getTemplateListing = () => {
  const options = {
    url: `v1/invoice/template/All`,
  };

  options.types = [
    types.GET_TEMPLATE_LISTING_SUCCESS,
    types.GET_TEMPLATE_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getTemplateInfo = (id) => {
  const options = {
    url: `v1/invoice/template/${id}`,
  };

  options.types = [
    types.GET_TEMPLATE_INFO_SUCCESS,
    types.GET_TEMPLATE_INFO_FAILURE,
  ];

  return api.get(options);
};

export const deleteTemplate = (data) => {
  let _data = {
    id: data,
    delete: true,
  };
  const options = {
    url: "v1/invoice/template",
  };

  options.types = [
    types.DELETE_TEMPLATE_SUCCESS,
    types.DELETE_TEMPLATE_FAILURE,
  ];

  return api.delete(options, _data);
};
