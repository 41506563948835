export const SystemGeneratedEmails = [
  {
    emailType: "Invitation to www.checkmyvisa.io",
    emailContent:
      "<p>Your Immigration Adviser / Lawyer has registered your email address with www.checkmyvisa.io please click on the ACTIVATE button below to create your free account.</p><p>With checkmyvisa.io, you can stay in touch with your Immigration Adviser / Lawyer more efficiently.All future e mail communication with your Immigration Adviser / Lawyer will be through www.checkmyvisa.io .</p><p>Whenever your Immigration adviser / lawyer, sends you a new email or update your visa status,you will automatically receive a notification in your email.<br><br>Click on the blow link to Reset your Password<br><a href='@Link' target='_blank' rel='noopener noreferrer'>Click here &nbsp;</a><br>@Link</p>",
  },
  {
    emailType: "Invoice Email",
    emailContent:
      "<p>Here's invoice @InvoiceNumber for @Amount @CurrencyId.</p><p>The amount outstanding of @Amount is due on @DueDate.</p>",
  },
  {
    emailType: "Notifications",
    emailContent:
      "<p>Your Immigration Adviser / Lawyer has left a message for you to review.</p>",
  },
  {
    emailType: "Update Visa Status Notification",
    emailContent:
      "<p>To @ClientName <br />Your Immigration Adviser / Lawyer has updated your Visa Status to @VisaStatus.</p>",
  },
  {
    emailType: "Questionnaire Link",
    emailContent: "<p>Please fill the form on the following link.</p>",
  },
  {
    emailType: "Invoice Follow-up",
    emailContent:
      "<p>This is to remind you that your invoice @InvoiceNo with amount : @Amount @CurrencyId is due on @DueDate</p>",
  },
  {
    emailType: "Client Birthday",
    emailContent:
      "<h2>Happy Birthday @ClientName </h2><p>Wishing you a Happy birthday filled with joy and success. Looking forward to another year of prosperous collaboration.</p><p>Best regards,   <br>@CompanyName   <br>@DigitalSignature</p>",
  },
];
