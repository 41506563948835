import React from "react";
import { Images } from "../Themes";

const PurchaseSubscription = () => {
  return (
    <div style={{ padding: 40, backgroundColor: "#fff" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={Images.oopsIcon} style={{ width: "25%" }} />
      </div>
      <h3>
        Unlock Premium Features! <br />
        Upgrade your subscription to unlock this module and access premium
        content. Enjoy enhanced features and exclusive benefits by subscribing
        now.
      </h3>
    </div>
  );
};

export default PurchaseSubscription;
