import React, { useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import ProfileEmployer from "./ProfileEmployer/index";
import FileNotesMain from "./FileNotes";
import EmployerDocuments from "./EmployerDocuments/index";
import EmployerEmailTabs from "./EmployerEmail";

const { TabPane } = Tabs;

const EmployerManagementTabs = ({
  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  onGetEmployerData,
  singleEmployerRes,
  singleEmployeFuc,

  handleCancel,

  onUploadAvatar,
  imageUploadSuccess,

  onGetTeamMember,
  teamMembers,

  onGetDocumentDownload,
  onGetDocumentPdf,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,

  onGetClientEmp,
  clientEmpRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onGetClientJobHis,
  clientJobHisRes,

  onGetSingleClientHis,
  singleJobHisRes,

  /////////////////////////

  userDataEmp,

  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  onRemoveEmployerContact,

  onUpdateEmployerManag,

  onUpdateEmployerContact,

  onGetEmployerManag,
  employerManagRes,

  onAddDocWithName,
  docWithNameRes,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onRemoveEmployerFile,

  onUpdateEmployerFile,

  onAddAnyTamplate,
  addAnyTemRes,

  onGetEmployerDocument,
  employerDocumentRes,

  onRemoveEmployerDocument,

  onAddDocumentTittle,

  onGetFilteredType,
  documentFilterRes,

  onAddFilteredType,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerDocument,

  onUpdateEmployerDocument,

  onAddEmployerCheckList,

  onSendEmailLink,

  location,
  history,

  onGetEmployerHistory,
  employerHistoryRes,

  onAddEmployerEmail,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onDeleteCreateEmail,
  onGetPClientEmailHistory,
  potentialClientInfo,

  potentialClientHistory,
  onGetPClientHistory,
  onGetSignature,
  onGetDocuments,
  onAddPotentialEmail,
  onGetPdf,
  onGetImapForAll,
  imapForAllRes,
  onAddEmailDocument,
  documentRes,
  onGetEmailContent,
}) => {
  const [loading, setLoading] = useState(false);
  const [addInvoice, setAddInvoice] = useState("");
  const [parentInvoiceId, setParentInvoiceId] = useState(0);
  const [childrenTotalAmount, setChildrenTotalAmount] = useState(0);
  const [parentTotalAmount, setParentTotalAmount] = useState(0);

  // useEffect(() => {
  //   onGetEmployerData(userDataEmp && userDataEmp.id);
  // }, [userDataEmp]);

  console.log(
    "userDataEmpuserDataEmpuserDataEmpuserDataEmp2313123123123123",
    userDataEmp
  );

  return (
    <div style={{ display: "flex" }}>
      <div className="box-emp">
        <div className="employer-manag-tabs">
          <Tabs
            defaultActiveKey="1"
            type="card"
            size={"small"}
            className="mar-r"
            // onChange={onClickInvoicesTab}
          >
            <TabPane tab="PROFILE" key="1">
              <ProfileEmployer
                userDataEmp={userDataEmp}
                ongetEmployerContact={ongetEmployerContact}
                EmployerContactRes={EmployerContactRes}
                onAddEmployerContact={onAddEmployerContact}
                onRemoveEmployerContact={onRemoveEmployerContact}
                onUpdateEmployerManag={onUpdateEmployerManag}
                onUpdateEmployerContact={onUpdateEmployerContact}
                onGetEmployerManag={onGetEmployerManag}
                employerManagRes={employerManagRes}
                docWithNameRes={docWithNameRes}
                onAddDocWithName={onAddDocWithName}
              />
            </TabPane>
            <TabPane tab="FILE NOTES" key="2">
              <FileNotesMain
                onGetEmployerFile={onGetEmployerFile}
                employerFileRes={employerFileRes}
                onAddEmployerFile={onAddEmployerFile}
                onGetLetterTemplates={onGetLetterTemplates}
                LetterTemplatesRes={LetterTemplatesRes}
                userDataEmp={userDataEmp}
                onRemoveEmployerFile={onRemoveEmployerFile}
                onUpdateEmployerFile={onUpdateEmployerFile}
                onAddAnyTamplate={onAddAnyTamplate}
                addAnyTemRes={addAnyTemRes}
              />
            </TabPane>
            <TabPane tab="DOCUMENTS" key="3">
              <EmployerDocuments
                onGetEmployerDocument={onGetEmployerDocument}
                employerDocumentRes={employerDocumentRes}
                userDataEmp={userDataEmp}
                onRemoveEmployerDocument={onRemoveEmployerDocument}
                onAddDocumentTittle={onAddDocumentTittle}
                onGetFilteredType={onGetFilteredType}
                documentFilterRes={documentFilterRes}
                onAddFilteredType={onAddFilteredType}
                onGetDocumentChecklist={onGetDocumentChecklist}
                docChecklistRes={docChecklistRes}
                onGetEmployerCheckList={onGetEmployerCheckList}
                employerCheckListRes={employerCheckListRes}
                onAddEmployerDocument={onAddEmployerDocument}
                onUpdateEmployerDocument={onUpdateEmployerDocument}
                onAddEmployerCheckList={onAddEmployerCheckList}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
                location={location}
                history={history}
                onSendEmailLink={onSendEmailLink}
                onGetDocumentDownload={onGetDocumentDownload}
                onGetDocumentPdf={onGetDocumentPdf}
              />
            </TabPane>
            <TabPane tab="EMAIL" key="4">
              <EmployerEmailTabs
                onGetEmailContent={onGetEmailContent}
                onGetEmployerHistory={onGetEmployerHistory}
                employerHistoryRes={employerHistoryRes}
                onSendEmailLink={onSendEmailLink}
                onGetLetterTemplates={onGetLetterTemplates}
                LetterTemplatesRes={LetterTemplatesRes}
                onAddEmployerEmail={onAddEmployerEmail}
                onGetAutoEmailImport={onGetAutoEmailImport}
                emailImportRes={emailImportRes}
                onAddAutoEmailImport={onAddAutoEmailImport}
                onDeleteCreateEmail={onDeleteCreateEmail}
                onGetEmployerDocument={onGetEmployerDocument}
                employerDocumentRes={employerDocumentRes}
                userDataEmp={userDataEmp}
                onAddEmployerDocument={onAddEmployerDocument}
                onRemoveEmployerDocument={onRemoveEmployerDocument}
                onUpdateEmployerDocument={onUpdateEmployerDocument}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
                onGetDocumentChecklist={onGetDocumentChecklist}
                docChecklistRes={docChecklistRes}
                onGetEmployerCheckList={onGetEmployerCheckList}
                employerCheckListRes={employerCheckListRes}
                onAddEmployerCheckList={onAddEmployerCheckList}
                location={location}
                history={history}
                onAddAnyTamplate={onAddAnyTamplate}
                addAnyTemRes={addAnyTemRes}
                onGetPClientEmailHistory={onGetPClientEmailHistory}
                potentialClientHistory={potentialClientHistory}
                onGetPClientHistory={onGetPClientHistory}
                onGetSignature={onGetSignature}
                onGetDocuments={onGetDocuments}
                onAddPotentialEmail={onAddPotentialEmail}
                potentialClientInfo={employerManagRes}
                onGetPdf={onGetPdf}
                onGetImapForAll={onGetImapForAll}
                imapForAllRes={imapForAllRes}
                onAddEmailDocument={onAddEmailDocument}
                documentRes={documentRes}
                onGetDocumentDownload={onGetDocumentDownload}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default EmployerManagementTabs;
