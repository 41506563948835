import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardReminders from "./../Dashboard/Reminders";

import {
  getDashboardReminders,
  updateDashboardReminderStatus,
  getDashboardRemindersTypes,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dashboardRemindersList:
      state.dashboardRemindersReducer.dashboardRemindersList,
    remindersTypes: state.dashboardRemindersReducer.remindersTypes,
    remindersTypesError:state.dashboardRemindersReducer.remindersTypesError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDashboardReminders: bindActionCreators(
      getDashboardReminders,
      dispatch
    ),
    onUpdateDashboardReminderStatus: bindActionCreators(
      updateDashboardReminderStatus,
      dispatch
    ),
    onGetDashboardRemindersTypes: bindActionCreators(
      getDashboardRemindersTypes,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardReminders);
