import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal, Spin, Image } from "antd";
import EmployerDocuments from "./EmployerDocuments/EmployerDocuments";
import AddEmployerDocuments from "./EmployerDocuments/AddEmployerDocuments";
import UpdateEmployerDocuments from "./EmployerDocuments/UpdateEmployerDocuments";
import DocumentCheckList from "./DocumentsCheckList/DocumentCheckList";
import HeaderBarTabs from "./../../Components/Header/HeaderTabs";
import { Images } from "./../../Themes";
import headOption from "../../Components/Header/HeaderTabOptions";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
import "./folder.css";
import { FolderOpenTwoTone } from "@ant-design/icons";

import ProfileSideBar from "../../Components/ProfileSideBar";

const { TabPane } = Tabs;

const EmployerManagementTabs = ({
  onGetEmployerDocument,
  employerDocumentRes,

  userDataEmp,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onGetClientProfile,
  clientProfileData,

  onGetDocumentDownload,
  downloadDocRes,

  onGetDocumentPdf,
  documentPdfRes,

  onGetFilteredType,
  documentFilterRes,

  onAddFilteredType,

  clientTab,

  onGetClientTag,
  clientTagRes,
  getClientRes,
  onGetProfileClientTag,
  onAddProfileClientTag,
  addProfielTagRes,
  onRemoveProfileClientTag,
  removeProfileTagRes,
  visaStatusData,
  onUpdateCaseStatus,
  visaAppData,
  onGetVisaApplication,
  onGetClientJobHistoryCurrent,
  employerJobHistoryCurrentRes,
  onSetActiveKey,
  activeKey,
  onGetClientTask,
  onUpdateTask,
  onGetAllUsers,
  onAddTaskFollower,
  onRemoveTasks,
  onAddDailyTasks,
  onGetClientFamily,
  onUpdateCompletedTask,
  onAddTaskFileNote,
  onAddTaskComment,
  onGetTaskComments,
  onGetTaskFollowers,
  onGetVisaStatus,

  onGetAdmissionProgram,
  onGetAdmissionStatuses,

  onSetProfileData,
  profileDataRdx,

  onGetPartner,
  partnerProfileData,

  clientFamilyData,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [onUpdateTitle, setOnUpdateTitle] = useState();
  const [headerOptions, setHeaderOptions] = useState([]);
  const [isFolderView, setisFolderView] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [ShowButtons, setShowButtons] = useState(false);
  const [onFilterData, setOnFilterData] = useState([]);
  const [onCaseData, setonCaseData] = useState([]);
  const [folderDocType, setfolderDocType] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    const folderOpt = {
      url: "v1/users/DocumentView",
    };
    folderOpt.types = [
      types.GET_DOCUMENT_VIEW_SUCCESS,
      types.GET_DOCUMENT_VIEW_FAILURE,
    ];

    apiRefresh.get(folderOpt).then((res) => {
      setisFolderView(res.isDocumentView);
      setShowButtons(res.isDocumentView);
      if (res.isDocumentView) {
        setLoading(true);
        const docTypeOpt = {
          url: `v1/document/type/All`,
        };
        docTypeOpt.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];

        apiRefresh
          .get(docTypeOpt)
          .then((res) => {
            setDocTypes(res.items);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }

      const VisaOpt = {
        url: `v1/subject/case/All/dropdown/` + clientprofileid,
      };
      VisaOpt.types = [
        types.GET_DOCUMENT_TYPE_SUCCESS,
        types.GET_DOCUMENT_TYPE_FAILURE,
      ];

      apiRefresh.get(VisaOpt).then((res) => {
        res.items.map((data, index) => {
          if (data) {
            data.index = index;
            data.key = `${index + 1}`;
          }
        });
        setonCaseData(res.items);
      });
    });
    if (clientTab) {
      var options = headOption(clientTab);
      setHeaderOptions(options);
      // this.setState({ headerOptions: options });
    } else {
      setHeaderOptions([
        {
          tabName: "Client Profile",
          imageName: Images.clientProfile,
          linkName: "/profile",
        },
        { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
        {
          tabName: "Admission",
          imageName: Images.admission,
          linkName: "/admission",
        },
        {
          tabName: "Documents",
          imageName: Images.documents,
          linkName: "/documents",
        },
        {
          tabName: "Email",
          imageName: Images.email,
          linkName: "/client-email",
        },
        {
          tabName: "Activities",
          imageName: Images.activities,
          linkName: "/activities",
        },
        {
          tabName: "File Notes",
          imageName: Images.documents,
          linkName: "/file-notes",
        },
        {
          tabName: "Accounts",
          imageName: Images.accounts,
          linkName: "/client-account",
        },
        {
          tabName: "Open Case Management",
          imageName: Images.caseManagement,
          linkName: "/Case-management",
        },
        {
          tabName: "Questionnaire",
          imageName: Images.questionnare,
          linkName: "/client-questionnaire",
        },
        { tabName: "Chat", imageName: Images.supplier, linkName: "" },
        {
          tabName: "Print Case",
          imageName: Images.print,
          linkName: "/print-case",
        },
      ]);
    }
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // setProfileData(data);
    // let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    // onGetClientProfile(clientProfileIdMain);
  }, [clientTab, onGetClientProfile]);
  useEffect(() => {
    onSetActiveKey(null);
    onGetFilteredType()
      .then((res) => {
        setOnFilterData(res && res.payload);
      })
      .catch(() => {});

    let clientId = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      onGetClientProfile(clientId);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      onGetClientFamily(data);
    }
  }, []);

  useEffect(() => {
    if (profileDataRdx) {
      setProfileData(JSON.parse(profileDataRdx));
    }
  }, [profileDataRdx]);

  useEffect(() => {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (clientProfileData && memberType == "client") {
      setProfileDataRdx(clientProfileData);
    } else if (partnerProfileData && memberType == "spouse") {
      setProfileDataRdx(partnerProfileData);
    } else if (clientFamilyData && memberType == "child") {
      let findClient = clientFamilyData.items.find(
        (obj) => obj.id == clientprofileid
      );
      if (findClient) {
        setProfileDataRdx(findClient);
      }
    }
  }, [clientProfileData, partnerProfileData, clientFamilyData]);

  const setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  const showModal = (modalTypeName, value) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
    if (modalType === "update-documents") {
      setOnUpdateTitle(value);
    }
    // setAddReminders(true);
    // setUpdateTitle(id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const folderClick = (id) => {
    setisFolderView(false);
    setfolderDocType(id);
  };
  const UpdateInChild = () => {
    setLoading(true);
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    var data = new Object();
    data.id = clientprofileid;
    data.docType = -1;
    data.CaseId = 0;
    data.IsVisa = false;

    onGetEmployerDocument(data)
      .then(() => {
        setLoading(false);
        setisFolderView(true);
      })
      .catch((err) => {
        setLoading(false);
        setisFolderView(true);
      });
  };

  return (
    <div>
      <Spin spinning={loading}>
        {isFolderView ? (
          <Fragment>
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Documents" />
            )}
            <div
              style={{
                display: "flex",
                whiteSpace: "break-spaces",
                margin: 10,
              }}
            >
              <div
                className={
                  activeKey
                    ? "content-width-open-sidebar"
                    : "content-width-close-sidebar"
                }
              >
                {profileData ? (
                  <div style={{ width: "100%" }}>
                    <p>Client Name: {profileData && profileData.fullName}</p>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                <div
                  className="employerin-box employer-manag-tabs"
                  style={{ margin: 0 }}
                >
                  <div
                    style={{ padding: 30, width: "100%" }}
                    className="bg-white "
                  >
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      size={"small"}
                      className="mar-r employer-doc-tab"
                    >
                      <TabPane tab="DOCUMENT" key="1">
                        <div
                          style={{
                            border: "2px solid #c9c9ca",
                            padding: "20px",
                            backgroundColor: "#f0f2f5b8",
                          }}
                        >
                          <div style={{ display: "flex", width: "100%" }}>
                            <div className="folderClass">
                              <Image
                                tabName="Unparented"
                                src={Images.yellowfolder}
                                onClick={() => folderClick(0)}
                                preview={false}
                                style={{ width: 50 }}
                              />
                              <div class="doc-name">Unparented</div>
                            </div>
                            {docTypes.map(function(object, i) {
                              var found =
                                employerDocumentRes &&
                                employerDocumentRes.items.find(
                                  (x) => x.documentTypeId == object.id
                                );
                              if (found) {
                                return (
                                  <>
                                    <div className="folderClass">
                                      <Image
                                        tabName={object.name}
                                        src={Images.folder}
                                        onClick={() => folderClick(object.id)}
                                        preview={false}
                                        style={{ width: 50 }}
                                      />
                                      <div class="doc-name">{object.name}</div>{" "}
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="DOCUMENT CHECKLIST" key="2">
                        <div
                          style={{
                            border: "2px solid #c9c9ca",
                            padding: "20px",
                            backgroundColor: "#f0f2f5b8",
                          }}
                        >
                          <DocumentCheckList
                            onGetDocumentChecklist={onGetDocumentChecklist}
                            docChecklistRes={docChecklistRes}
                            onGetEmployerCheckList={onGetEmployerCheckList}
                            employerCheckListRes={employerCheckListRes}
                            onAddEmployerCheckList={onAddEmployerCheckList}
                            location={location}
                            history={history}
                            onSendEmailLink={onSendEmailLink}
                          />
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div
                style={{ width: activeKey ? "438px" : "115px", marginLeft: 20 }}
              >
                <ProfileSideBar
                  onGetClientTag={onGetClientTag}
                  clientTagRes={clientTagRes}
                  getClientRes={getClientRes}
                  onGetProfileClientTag={onGetProfileClientTag}
                  onAddProfileClientTag={onAddProfileClientTag}
                  addProfielTagRes={addProfielTagRes}
                  onRemoveProfileClientTag={onRemoveProfileClientTag}
                  removeProfileTagRes={removeProfileTagRes}
                  visaStatusData={visaStatusData}
                  onUpdateCaseStatus={onUpdateCaseStatus}
                  visaAppData={visaAppData}
                  onGetVisaApplication={onGetVisaApplication}
                  onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
                  employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
                  onSetActiveKey={onSetActiveKey}
                  activeKey={activeKey}
                  onGetClientTask={onGetClientTask}
                  onUpdateTask={onUpdateTask}
                  onGetAllUsers={onGetAllUsers}
                  onAddTaskFollower={onAddTaskFollower}
                  onRemoveTasks={onRemoveTasks}
                  onAddDailyTasks={onAddDailyTasks}
                  onGetClientFamily={onGetClientFamily}
                  onUpdateCompletedTask={onUpdateCompletedTask}
                  onAddTaskFileNote={onAddTaskFileNote}
                  onAddTaskComment={onAddTaskComment}
                  onGetTaskComments={onGetTaskComments}
                  onGetTaskFollowers={onGetTaskFollowers}
                  onGetVisaStatus={onGetVisaStatus}
                  onGetAdmissionProgram={onGetAdmissionProgram}
                  onGetAdmissionStatuses={onGetAdmissionStatuses}
                />
              </div>
            </div>

            {isModalVisible && (
              <div className="reminder-model">
                <Modal
                  className="reminder-model-main"
                  title={
                    (modalType === "add-documents" && "ADD DOCUMENTS") ||
                    (modalType === "update-documents" && "UPDATE DOCUMENTS")
                  }
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  // Header={true}
                  footer={false}
                >
                  {modalType === "add-documents" && (
                    <AddEmployerDocuments
                      onAddEmployerDocument={onAddEmployerDocument}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      clientProfileData={clientProfileData}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      onGetClientProfile={onGetClientProfile}
                      handleCancel={handleCancel}
                      folderDocType={folderDocType}
                    />
                  )}
                </Modal>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {headerOptions.length > 0 && (
              <HeaderBarTabs data={headerOptions} activeTab="Documents" />
            )}
            <div style={{ display: "flex", margin: 10 }}>
              <div
                className={
                  activeKey
                    ? "content-width-open-sidebar"
                    : "content-width-close-sidebar"
                }
                style={{ margin: 0 }}
              >
                {profileData ? (
                  <div>
                    <div style={{ width: "100%" }}>
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  </div>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                <div
                  className="employerin-box employer-manag-tabs"
                  style={{ margin: 0 }}
                >
                  <div style={{ padding: 30 }} className="bg-white ">
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      size={"small"}
                      className="mar-r employer-doc-tab"
                    >
                      <TabPane tab="DOCUMENT" key="1">
                        <div
                          style={{
                            border: "2px solid #c9c9ca",
                            padding: "20px",
                            backgroundColor: "#f0f2f5b8",
                          }}
                        >
                          <EmployerDocuments
                            onGetEmployerDocument={onGetEmployerDocument}
                            employerDocumentRes={employerDocumentRes}
                            userDataEmp={userDataEmp}
                            showModal={showModal}
                            onRemoveEmployerDocument={onRemoveEmployerDocument}
                            clientProfileData={clientProfileData}
                            onGetClientProfile={onGetClientProfile}
                            onGetDocumentDownload={onGetDocumentDownload}
                            downloadDocRes={downloadDocRes}
                            onGetDocumentPdf={onGetDocumentPdf}
                            documentPdfRes={documentPdfRes}
                            onUpdateEmployerDocument={onUpdateEmployerDocument}
                            onFilterData={onFilterData}
                            onCaseData={onCaseData}
                            documentFilterRes={documentFilterRes}
                            onAddFilteredType={onAddFilteredType}
                            UpdateInChild={UpdateInChild}
                            ShowButtons={ShowButtons}
                            folderDocType={folderDocType}
                            profileData={profileData}
                          />
                        </div>
                      </TabPane>
                      <TabPane tab="DOCUMENT CHECKLIST" key="2">
                        <div
                          style={{
                            border: "2px solid #c9c9ca",
                            padding: "20px",
                            backgroundColor: "#f0f2f5b8",
                          }}
                        >
                          <DocumentCheckList
                            onGetDocumentChecklist={onGetDocumentChecklist}
                            docChecklistRes={docChecklistRes}
                            onGetEmployerCheckList={onGetEmployerCheckList}
                            employerCheckListRes={employerCheckListRes}
                            onAddEmployerCheckList={onAddEmployerCheckList}
                            location={location}
                            history={history}
                            onSendEmailLink={onSendEmailLink}
                            profileData={profileData}
                          />
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div
                style={{ width: activeKey ? "438px" : "115px", marginLeft: 20 }}
              >
                <ProfileSideBar
                  onGetClientTag={onGetClientTag}
                  clientTagRes={clientTagRes}
                  getClientRes={getClientRes}
                  onGetProfileClientTag={onGetProfileClientTag}
                  onAddProfileClientTag={onAddProfileClientTag}
                  addProfielTagRes={addProfielTagRes}
                  onRemoveProfileClientTag={onRemoveProfileClientTag}
                  removeProfileTagRes={removeProfileTagRes}
                  visaStatusData={visaStatusData}
                  onUpdateCaseStatus={onUpdateCaseStatus}
                  visaAppData={visaAppData}
                  onGetVisaApplication={onGetVisaApplication}
                  onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
                  employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
                  onSetActiveKey={onSetActiveKey}
                  activeKey={activeKey}
                  onGetClientTask={onGetClientTask}
                  onUpdateTask={onUpdateTask}
                  onGetAllUsers={onGetAllUsers}
                  onAddTaskFollower={onAddTaskFollower}
                  onRemoveTasks={onRemoveTasks}
                  onAddDailyTasks={onAddDailyTasks}
                  onGetClientFamily={onGetClientFamily}
                  onUpdateCompletedTask={onUpdateCompletedTask}
                  onAddTaskFileNote={onAddTaskFileNote}
                  onAddTaskComment={onAddTaskComment}
                  onGetTaskComments={onGetTaskComments}
                  onGetTaskFollowers={onGetTaskFollowers}
                  onGetVisaStatus={onGetVisaStatus}
                  onGetAdmissionProgram={onGetAdmissionProgram}
                  onGetAdmissionStatuses={onGetAdmissionStatuses}
                />
              </div>
            </div>

            {isModalVisible && (
              <div className="reminder-model">
                <Modal
                  className="reminder-model-main"
                  title={
                    (modalType === "add-documents" && "ADD DOCUMENTS") ||
                    (modalType === "update-documents" && "UPDATE DOCUMENTS")
                  }
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  // Header={true}
                  footer={false}
                >
                  {modalType === "add-documents" && (
                    <AddEmployerDocuments
                      onAddEmployerDocument={onAddEmployerDocument}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      clientProfileData={clientProfileData}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      onGetClientProfile={onGetClientProfile}
                      handleCancel={handleCancel}
                      onFilterData={onFilterData}
                      folderDocType={folderDocType}
                    />
                  )}
                  {/* 
            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onUpdateEmployerDocument={onUpdateEmployerDocument}
                onUpdateTitle={onUpdateTitle}
              />
            )} */}
                </Modal>
              </div>
            )}
          </Fragment>
        )}
      </Spin>
    </div>
  );
};

export default EmployerManagementTabs;
