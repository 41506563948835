import React from "react";

import { Select, DatePicker, Table, Tag, Space, Spin } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Link } from "react-router-dom";

import "./ReportsStyles.css";

import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ComponentToPrint from "../Components/Reports/ClientSourceReportPrint";
import ProcessingPersonReport from "../Components/Reports/ProcessingPersonReport";
import TaskPerformanceReport from "../Components/Reports/TaskPerformanceReport";
import BirthdayReport from "../Components/Reports/BirthdayReport";
import FileNotesReport from "../Components/Reports/FileNotesReport";
import ActiveCasesReport from "../Components/Reports/ActiveCasesReport";
import VisaExpiryReport from "../Components/Reports/VisaExpiryReport";
import ClientEmployerReport from "../Components/Reports/ClientEmployerReport";
import EMedicalExpiryReport from "../Components/Reports/EMedicalExpiry";
import PoliceCertificateExpiryReport from "../Components/Reports/PoliceCertificateExpiry";
import PassportExpiryReport from "../Components/Reports/PassportExpiry";
import { TravelConditionExpiryReport } from "../Components/Reports/TravelConditionExpiry";

const headOption = [
  { tabName: "Reports", linkName: "/reports" },
  // { tabName: "Visa Reports", linkName: "/visa-report" },
  // // { tabName: "Case Management", linkName: "/" },
  // { tabName: "Potential Clients", linkName: "/potential-clients-report" },
  // { tabName: "Time Tracking", linkName: "/time-tracking" },
  // { tabName: "Employer Report", linkName: "/employer-reports" },
];

const { Option } = Select;

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      if (index == 0) {
        return <a>{text}</a>;
      }
      return {
        children: <a>{text}</a>,
        props: {
          colSpan: 6,
        },
      };
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Invoiced Amount",
    dataIndex: "total",
    key: "invoicedAmount",
  },

  {
    title: "Received Amount",
    dataIndex: "received",
    key: "receivedAmount",
  },
];

var totalInvoiced = 0;
var totalReceived = 0;

const dateFormat = "DD/MM/YYYY";

class EmployerReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMainTab: "",
      activeTab: "",
      extend: false,
      dateFrom: "",
      dateTo: "",
      processingPerson: [],
      loadReports: false,
      pageNumber: 1,
      pageSize: 10,
      employersArray: [],
      clientTagId: "",
    };

    this.props.onGetClientTag().then((res) => {});
    this.props.onGetEmployerManag().then((res) => {});
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.saleHistoryRes !== this.props.saleHistoryRes) {
      totalInvoiced = 0;
      totalReceived = 0;
      if (this.props.saleHistoryRes)
        for (var i = 0; i < this.props.saleHistoryRes.list.length; i++) {
          totalInvoiced += parseInt(this.props.saleHistoryRes.list[i].total);
          totalReceived += parseInt(this.props.saleHistoryRes.list[i].received);
        }
    }

    if (
      PrevProps.processingPersonReportRes !==
      this.props.processingPersonReportRes
    ) {
      totalInvoiced = 0;
      totalReceived = 0;
      if (this.props.processingPersonReportRes)
        for (
          var i = 0;
          i < this.props.processingPersonReportRes.list.length;
          i++
        ) {
          totalInvoiced += parseInt(
            this.props.processingPersonReportRes.list[i].total
          );
          totalReceived += parseInt(
            this.props.processingPersonReportRes.list[i].received
          );
        }
    }
  }

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeMainTab = (value) => {
    this.setState({ activeMainTab: value });
  };

  onChangeTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (value) => {
    this.setState({ employersArray: value });
  };

  handleChangeClientTag = (value) => {
    this.setState({ clientTagId: value });
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onChangeDateFrom = (date, dateString) => {
    console.log(date, dateString);
    this.setState({ dateFrom: date });
  };

  onChangeDateTo = (date, dateString) => {
    console.log(date, dateString);
    this.setState({ dateTo: date });
  };

  onMedicalExpiring = () => {
    var employerIds = "";
    this.setState({ loadReports: true });
    if (this.state.employersArray.length > 0) {
      for (var i = 0; i < this.state.employersArray.length; i++) {
        if (i === 0) {
          employerIds = this.state.employersArray[i];
        } else {
          employerIds += "," + this.state.employersArray[i];
        }
      }
    }
    let data = {
      employerId: employerIds || "00000000-0000-0000-0000-000000000000",
      tagId: this.state.clientTagId.toString(),
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onEmployerMedicalExpiry(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("MEDICAL EXPIRING");
    });
  };

  onPoliceCertificate = () => {
    var employerIds = "";
    this.setState({ loadReports: true });
    if (this.state.employersArray.length > 0) {
      for (var i = 0; i < this.state.employersArray.length; i++) {
        if (i === 0) {
          employerIds = this.state.employersArray[i];
        } else {
          employerIds += "," + this.state.employersArray[i];
        }
      }
    }
    let data = {
      employerId: employerIds || "00000000-0000-0000-0000-000000000000",
      tagId: this.state.clientTagId.toString(),
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onPoliceCertificateExpiry(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("POLICE CERTIFICATE EXPIRY");
    });
  };

  onEmployerPassport = () => {
    var employerIds = "";
    this.setState({ loadReports: true });
    if (this.state.employersArray.length > 0) {
      for (var i = 0; i < this.state.employersArray.length; i++) {
        if (i === 0) {
          employerIds = this.state.employersArray[i];
        } else {
          employerIds += "," + this.state.employersArray[i];
        }
      }
    }
    let data = {
      employerId: employerIds || "00000000-0000-0000-0000-000000000000",
      tagId: this.state.clientTagId.toString(),
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onEmployerPassportExpiry(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("PASSPORT EXPIRY");
    });
  };

  onTravelCondition = () => {
    var employerIds = "";
    this.setState({ loadReports: true });
    if (this.state.employersArray.length > 0) {
      for (var i = 0; i < this.state.employersArray.length; i++) {
        if (i === 0) {
          employerIds = this.state.employersArray[i];
        } else {
          employerIds += "," + this.state.employersArray[i];
        }
      }
    }
    let data = {
      employerId: employerIds || "00000000-0000-0000-0000-000000000000",
      tagId: this.state.clientTagId.toString(),
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onTravelConditionExpiry(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("TRAVEL CONDITIONS EXPIRING");
    });
  };

  onBirthdayReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    let data = {
      processingPerson: processingIds,
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onBirthdayReport(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("BIRTHDAY REPORT");
    });
  };

  print = () => {
    window.print();
  };
  render() {
    const {
      dateFrom,
      dateTo,
      processingPerson,
      activeTab,
      employersArray,
      clientTagId,
    } = this.state;
    const {
      saleHistoryRes,
      processingPersonReportRes,
      taskReportRes,
      onEmployerMedicalExpiry,
      onProcessingPersonReport,
      onTaskReport,
      onBirthdayReport,
      birthdayReportRes,
      onActiveCasesReport,
      activeCasesReportRes,
      onClientEmployerReport,
      clientEmployerReportRes,
      employerManagRes,
      clientTagRes,
      employerMedicalExpRes,
      policeCertificateExpRes,
      onPoliceCertificateExpiry,
      employerPassportExpRes,
      travelConditionExpRes,
      onTravelConditionExpiry,
      onEmployerPassportExpiry,
    } = this.props;
    console.log("sale history", saleHistoryRes);
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <PotentialHeaderTabs
              data={headOption}
              activeTab="Employer Report"
            />

            <div className="report-container">
              <div>
                <div>
                  <div className="pciq-top-div">
                    <span
                      className="pc-top-div-text"
                      style={{ color: "#0A3C5D" }}
                    >
                      Report Filter
                    </span>
                  </div>

                  <div className="ca-gray-cont" style={{ border: 0 }}>
                    <div style={{ display: "flex", marginLeft: 20 }}>
                      <div style={{ display: "flex", width: "47%" }}>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <DatePicker
                            onChange={this.onChangeDateFrom}
                            value={dateFrom}
                            format={dateFormat}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: 20,
                          display: "flex",
                          width: "47%",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <DatePicker
                            onChange={this.onChangeDateTo}
                            value={dateTo}
                            format={dateFormat}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", marginLeft: 20, marginTop: 10 }}
                    >
                      <div style={{ width: "47%" }}>
                        <div>
                          <Select
                            placeholder="Please select"
                            value={clientTagId}
                            onChange={this.handleChangeClientTag}
                            style={{ width: "100%" }}
                          >
                            {clientTagRes &&
                              clientTagRes.items.map((client, userInd) => (
                                <Option value={client.id}>{client.name}</Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div
                        className="multi-select-option"
                        style={{ width: "47%", marginLeft: 20 }}
                      >
                        <div>
                          <Select
                            mode="multiple"
                            placeholder="Please select"
                            value={employersArray}
                            onChange={this.handleChange}
                            style={{ width: "100%" }}
                          >
                            {employerManagRes &&
                              employerManagRes.items.map((employer, empInd) => (
                                <Option value={employer.id}>
                                  {employer.name}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="report-btn-tab-row">
                    <div
                      className={
                        activeTab === "MEDICAL EXPIRING"
                          ? "report-active-btn-tab"
                          : "report-inactive-btn-tab"
                      }
                      style={{ width: "19%" }}
                      onClick={() => {
                        this.onMedicalExpiring();
                      }}
                    >
                      <span className="pc-btn-text">MEDICAL EXPIRING</span>
                    </div>

                    <div
                      className={
                        activeTab === "POLICE CERTIFICATE EXPIRY"
                          ? "report-active-btn-tab"
                          : "report-inactive-btn-tab"
                      }
                      style={{ width: "19%" }}
                      onClick={() => {
                        this.onPoliceCertificate();
                      }}
                    >
                      <span className="pc-btn-text">
                        POLICE CERTIFICATE EXPIRY
                      </span>
                    </div>

                    <div
                      className={
                        activeTab === "PASSPORT EXPIRING"
                          ? "report-active-btn-tab"
                          : "report-inactive-btn-tab"
                      }
                      style={{ width: "19%" }}
                      onClick={() => {
                        this.onEmployerPassport();
                      }}
                    >
                      <span className="pc-btn-text">PASSPORT EXPIRING</span>
                    </div>

                    <div
                      className={
                        activeTab === "TRAVEL CONDITIONS EXPIRING"
                          ? "report-active-btn-tab"
                          : "report-inactive-btn-tab"
                      }
                      style={{ width: "19%" }}
                      onClick={() => {
                        this.onTravelCondition();
                      }}
                    >
                      <span className="pc-btn-text">
                        TRAVEL CONDITIONS EXPIRING
                      </span>
                    </div>
                  </div>

                  {/* ===== Design from Sales history report Screen ===== */}

                  {true && (
                    <div>
                      {activeTab === "MEDICAL EXPIRING" && (
                        <EMedicalExpiryReport
                          employerMedicalExpRes={
                            employerMedicalExpRes && employerMedicalExpRes.list
                          }
                          reportsCount={
                            employerMedicalExpRes && employerMedicalExpRes.count
                          }
                          displayText={"Sale History Report"}
                          onEmployerMedicalExpiry={onEmployerMedicalExpiry}
                          requestData={this.state}
                        />
                      )}

                      {activeTab === "POLICE CERTIFICATE EXPIRY" && (
                        <div>
                          <PoliceCertificateExpiryReport
                            clientSourceReportRes={
                              policeCertificateExpRes &&
                              policeCertificateExpRes.list
                            }
                            displayText={"Client Source Report"}
                            reportsCount={
                              policeCertificateExpRes &&
                              policeCertificateExpRes.count
                            }
                            onClientSourceReport={onPoliceCertificateExpiry}
                            requestData={this.state}
                          />
                        </div>
                      )}

                      {activeTab === "PASSPORT EXPIRING" && (
                        <PassportExpiryReport
                          processingPersonReportRes={
                            employerPassportExpRes &&
                            employerPassportExpRes.list
                          }
                          displayText={"Processing Person Report"}
                          totalInvoiced={totalInvoiced}
                          totalReceived={totalReceived}
                          reportsCount={
                            employerPassportExpRes &&
                            employerPassportExpRes.count
                          }
                          onEmployerPassportExpiry={onEmployerPassportExpiry}
                          requestData={this.state}
                        />
                      )}

                      {activeTab === "BIRTHDAY REPORT" && (
                        <TravelConditionExpiryReport
                          birthdayReportRes={
                            travelConditionExpRes && travelConditionExpRes.list
                          }
                          displayText={"Birthday Report"}
                          totalInvoiced={totalInvoiced}
                          totalReceived={totalReceived}
                          reportsCount={
                            travelConditionExpRes && travelConditionExpRes.count
                          }
                          onTravelConditionExpiry={onTravelConditionExpiry}
                          requestData={this.state}
                        />
                      )}

                      {activeTab === "TASK PERFORMANCE" && (
                        <TaskPerformanceReport
                          taskReportRes={taskReportRes && taskReportRes.list}
                          displayText={"Task Performance"}
                          reportsCount={taskReportRes && taskReportRes.count}
                          onTaskReport={onTaskReport}
                          requestData={this.state}
                        />
                      )}

                      {activeTab === "ACTIVE CASES" && (
                        <ActiveCasesReport
                          fileNotesReportRes={
                            activeCasesReportRes && activeCasesReportRes.list
                          }
                          displayText={"File Notes"}
                          reportsCount={
                            activeCasesReportRes && activeCasesReportRes.count
                          }
                          onFileNotesReport={onActiveCasesReport}
                          requestData={this.state}
                        />
                      )}

                      {activeTab === "CLIENT EMPLOYERS" && (
                        <ClientEmployerReport
                          fileNotesReportRes={
                            clientEmployerReportRes &&
                            clientEmployerReportRes.list
                          }
                          displayText={"File Notes"}
                          reportsCount={
                            clientEmployerReportRes &&
                            clientEmployerReportRes.count
                          }
                          onBirthdayReport={onClientEmployerReport}
                          requestData={this.state}
                        />
                      )}

                      <div className="report-total-main-cont">
                        <div />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployerReports;
