import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Spin } from "antd";
import "./PotentialClientStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { createDynamicLink, getDynamicLink } from "../store/Actions";

const headOption = [
  { tabName: "Web Assessment", linkName: "/web-assessment" },
  { tabName: "Web Inquiry", linkName: "/web-inquiry-detailed" },
  {
    tabName: "Web Inquiry Link (Detailed)",
    linkName: "/web-inquiry-link-detailed",
  },
  { tabName: "Web Inquiry Link (Basic)", linkName: "/web-inquiry-link-basic" },
  { tabName: "Web Assessment Link", linkName: "/web-assessment-link" },
  { tabName: "Basic Assessment Link", linkName: "/basic-assessment-link" },
];

class BasicAssessmentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicLinkState: "",
      loading: true,
    };
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let questionnaireData = {
      clientId: "00000000-0000-0000-0000-000000000000",
      branchId: selectedBranchId,
      questionnaireId: 1838,
      isGrouped: false,
      groupId: 0,
      branchId: localStorage.getItem("selectedBranchId"),
      isPotential: true,
      userId: localStorage.getItem("userId"),
    };
    this.props
      .onCreateDynamicLink(questionnaireData)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ dynamicLinkState: this.props.dynamicLinkSuccess });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dynamicLinkSuccess != this.props.dynamicLinkSuccess) {
      if (this.props.dynamicLinkSuccess) {
        this.setState({ dynamicLinkState: this.props.dynamicLinkSuccess });
      }
    }
  }

  openQuestionnaire = () => {
    window.open(
      window.location.origin + "/c_q?para=" + this.props.dynamicLinkSuccess,
      "_blank"
    );
  };

  render() {
    const { dynamicLinkState, loading } = this.state;
    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs
                data={headOption}
                activeTab="Basic Assessment Link"
              />

              {dynamicLinkState && (
                <div className="pc-wa-web-inquiry-link-detailed-cont">
                  <div className="pc-bottom-org-box-cont">
                    <div className="pciq-org-box" style={{ borderRadius: 5 }}>
                      <span className="pciq-org-box-text">
                        Web Inquiry (EN)
                      </span>
                    </div>
                  </div>

                  <div className="pc-link-main-cont">
                    <div
                      className="pc-link-color-box"
                      onClick={this.openQuestionnaire}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="pc-link-text">
                        {window.location.origin +
                          "/c_q?para=" +
                          dynamicLinkState}
                      </span>
                    </div>
                  </div>

                  {/* <div className="pc-bottom-org-box-cont" style={{ marginTop: 10 }}>
                <div className="pciq-org-box" style={{ borderRadius: 5 }}>
                  <span className="pciq-org-box-text">Web Inquiry (CH)</span>
                </div>
              </div>

              <div className="pc-link-main-cont">
                <div className="pc-link-color-box">
                  <span className="pc-link-text">
                    https://ezymigrate.co.nz/Questionnaire/Migrationquirey?para=
                  </span>
                </div>
              </div> */}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dynamicLinkSuccess: state.questionnaireReducer.dynamicLinkSuccess,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
  onCreateDynamicLink: bindActionCreators(createDynamicLink, dispatch),
});

BasicAssessmentLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicAssessmentLink);

export default BasicAssessmentLink;
