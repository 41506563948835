import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const templatesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TEMPLATE_LISTING_SUCCESS:
      return { ...state, templateListing: action.payload};
    case types.GET_TEMPLATE_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Template list is empty!");
        return { ...state, templateListing: [] };
      } else {

      }
      console.log("on error console", action.payload);
    default:
      return state;
  }
};


