import React from "react";
import { Row, Col } from "antd";

import "./PrivacyPolicy.css";

import { Images } from "../Themes";

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const {} = this.state;
    const {} = this.props;
    return (
      <div className="term-conditions">
        <div
          className="pp-container "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <img src={Images.logo} />
          </div>

          <a className="a" href="https://app.ezymigrate.com/login" target="_blank">
            <button class="sign-in-btn btn-default">
              <i class="fa fa-sign-in" aria-hidden="true"></i> sign in
            </button>
          </a>
        </div>

        <div style={{ padding: 50, marginTop: 60 }}>
          <p className="s1">Ezymigrate terms of service</p>
          <p
            style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}
          >
            <br />
          </p>
          <ol id="l1">
            <li data-list-text={1}>
              <p
                className="s2"
                style={{
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                APPLICATION OF TERMS
              </p>
              <ol id="l2">
                <li data-list-text="1.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Application and agreement:&nbsp;
                    <span className="p">
                      These Terms apply to your use of the Ezymigrate Service
                      (as that term is defined below). By setting up an account
                      with Ezymigrate:-
                    </span>
                  </h1>
                  <ol id="l3">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        You agree to these Terms and;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Where your access and use is on behalf of another person
                        (e.g. a company), you confirm that you are authorised
                        to, and do in fact, agree to these Terms on that
                        person’s behalf and that, by agreeing to these Terms on
                        that person’s behalf, that person is bound by these
                        Terms.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="1.2">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    If you do not agree:&nbsp;
                    <span className="p">
                      If you do not agree to these Terms, you are not authorised
                      to access and use the Services, and you must immediately
                      stop doing so.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={2}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                INTERPRETATION
              </p>
              <ol id="l4">
                <li data-list-text="2.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Definitions:&nbsp;
                    <span className="p">
                      In these Terms, the following terms have the stated
                      meaning:
                    </span>
                  </h1>
                  <p
                    className="p"
                    style={{ textIndent: "0pt", textAlign: "left" }}
                  >
                    <br />
                  </p>
                  <table
                    style={{
                      borderCollapse: "collapse",
                    }}
                    cellSpacing={0}
                  >
                    <tbody>
                      <tr style={{ height: "33pt" }}>
                        <td style={{ width: "101pt" }} bgcolor="#BEBEBE">
                          <p
                            className="s3"
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Term
                          </p>
                        </td>
                        <td style={{ width: "327pt" }} bgcolor="#BEBEBE">
                          <p
                            className="s3"
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "24pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Meaning
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "48pt" }}>
                        <td style={{ width: "101pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Client Portal
                          </p>
                        </td>
                        <td style={{ width: "327pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "24pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Has the meaning given in the definition of
                            Ezymigrate Service
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "101pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Confidential Information
                          </p>
                        </td>
                        <td style={{ width: "327pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "24pt",
                              paddingRight: "8pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            The terms of the Agreement and any information that
                            is not public knowledge and that is obtained from
                            the other party in the course of, or in connection
                            with, the provision and use of the Ezymigrate
                            Service. Our Confidential Information includes
                            Intellectual Property owned by us (or our
                            licensors), including the Ezymigrate Software. Your
                            Confidential Information includes the Data.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "71pt" }}>
                        <td style={{ width: "101pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Data
                          </p>
                        </td>
                        <td style={{ width: "327pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "24pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            All data, content, and information (including
                            Personal Information) owned, held, used or created
                            by you or on your behalf that is stored using, or
                            inputted into, the Ezymigrate Service.
                          </p>
                        </td>
                      </tr>

                      <tr style={{ height: "132pt" }}>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              paddingRight: "1pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Ezymigrate Service
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            The following services:
                          </p>
                          <p
                            className="s6"
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "45pt",
                              paddingRight: "8pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            <span className="s6">▲&nbsp;</span>
                            <span className="s5">
                              The Ezymigrate CRM facility, accessed via the
                              Website, and having the functionality described at
                              ezymigrate.com/how-it-works.html; and
                            </span>
                          </p>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "45pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            <span className="s6">▲ </span>
                            The Ezymigrate client portal, accessed via&nbsp;
                            <span
                              style={{
                                color: "#00F",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "underline",
                                fontSize: "12pt",
                              }}
                            >
                              {" "}
                              <a
                                href="http://www.checkmyvisa.io/"
                                className="s8 a"
                                target="_blank"
                              >
                                www.checkmyvisa.io{" "}
                              </a>
                            </span>
                            (<b>Client Portal</b>).
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "48pt" }}>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Ezymigrate Software
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              paddingRight: "8pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            The software owned by Ezymigrate (and its licensors)
                            that is used to provide the Ezymigrate Service.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Fees
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              paddingRight: "8pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            The applicable fees set out in our pricing page on
                            the Website at
                            <a
                              href="https://ezymigrate.com/faqs/"
                              className="s8 a"
                              target="_blank"
                            >
                              https://www.ezymigrate.com/faqs.html{" "}
                            </a>{" "}
                            or as otherwise agreed in writing between you and
                            us, as may be updated from time to time in
                            accordance with clause&nbsp;7.3.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "132pt" }}>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Force Majeure
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              paddingRight: "8pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            An event that is beyond the reasonable control of a
                            party, excluding:
                          </p>
                          <p
                            className="s9"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "38pt",
                              paddingRight: "37pt",
                              textIndent: "-21pt",
                              lineHeight: "117%",
                              textAlign: "justify",
                            }}
                          >
                            <span className="s6">▲&nbsp;</span>
                            <span style={{ color: "#555" }}>
                              An event to the extent that it could have been
                              avoided by a party taking reasonable steps or
                              reasonable care; or
                            </span>
                          </p>
                          <p
                            className="s9"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "16pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            <span className="s6">▲&nbsp;</span>
                            <span style={{ color: "#555" }}>
                              A lack of funds for any reason.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "80pt" }}>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Good Industry Practice
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              paddingRight: "7pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            In relation to an undertaking, the exercise of that
                            degree of skill and care which would reasonably be
                            expected from an experienced operator engaging in
                            the same or a similar undertaking.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "111pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Intellectual Property Rights
                          </p>
                        </td>
                        <td style={{ width: "317pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "15pt",
                              paddingRight: "8pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            Includes copyright and all rights existing anywhere
                            in the world conferred under statute, common law or
                            equity relating to inventions (including patents),
                            registered and unregistered trade marks and designs,
                            circuit layouts, data and databases, confidential
                            information, know how, and all other rights
                            resulting from intellectual activity.
                            <b>Intellectual Property </b>has a consistent
                            meaning, and includes any enhancement, modification
                            or derivative work of the Intellectual
                          </p>
                          <p
                            className="s5"
                            style={{
                              paddingLeft: "15pt",
                              textIndent: "0pt",
                              lineHeight: "13pt",
                              textAlign: "left",
                            }}
                          >
                            Property.
                          </p>
                        </td>
                      </tr>

                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Initial Term
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            One Year
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "49pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Objectionable
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Includes being objectionable, defamatory, obscene,
                            harassing, threatening, harmful, or unlawful in any
                            way.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Partner
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            The government authorities and/or trusted businesses
                            (including Microsoft Azure for cloud storage and
                            other related services, and Xero for invoicing
                            services) that enable us to provide certain features
                            of the Ezymigrate Service. A full list of our
                            current partners is available on request by email to
                            info@ezymigrate.com
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Payment Terms
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            The payment terms set out in the Key Details (if
                            any)
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "64pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Permitted Users
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              paddingRight: "12pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            Your personnel who are authorised to access and use
                            the Services on your behalf in accordance with
                          </p>
                          <p
                            className="s5"
                            style={{
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            clause&nbsp; 5.3.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "49pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "118%",
                              textAlign: "left",
                            }}
                          >
                            Personal Information
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Has the meaning given in the Privacy Act 1993
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Related Services
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Has the meaning given in clause&nbsp; 3.5a.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Services
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            The Ezymigrate Service and the Support Services.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "49pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Start Date
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              paddingRight: "12pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            The date that you accept these Terms under clause
                            &nbsp; 1.1.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Support Services
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Has the meaning given in clause&nbsp; 4.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "32pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Terms
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            These terms titled Ezymigrate Terms of Service.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Underlying Systems
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              paddingRight: "3pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            The Ezymigrate Software, IT solutions, systems and
                            networks (including other software and hardware)
                            used to provide the Services, including any third
                            party solutions, systems and networks.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "55pt" }}>
                        <td style={{ width: "115pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            User ID
                          </p>
                        </td>
                        <td style={{ width: "314pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "10pt",
                              paddingRight: "12pt",
                              textIndent: "0pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            a unique name and/or password allocated to a
                            Permitted User to allow that Permitted User to
                            access
                          </p>
                          <p
                            className="s5"
                            style={{
                              paddingLeft: "10pt",
                              textIndent: "0pt",
                              lineHeight: "13pt",
                              textAlign: "left",
                            }}
                          >
                            the Ezymigrate Service or certain parts of it.
                          </p>
                        </td>
                      </tr>

                      <tr style={{ height: "48pt" }}>
                        <td style={{ width: "103pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Website
                          </p>
                        </td>
                        <td style={{ width: "326pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "22pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            The internet site at ezymigrate.com, or such other
                            site notified to you by us.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "48pt" }}>
                        <td style={{ width: "103pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            We, us <span className="s5">or </span>our
                          </p>
                        </td>
                        <td style={{ width: "326pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "22pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Smitten Online Limited (trading as Ezymigrate), a
                            New Zealand company, company number&nbsp;
                            <span style={{ color: "#4D4D4D" }}>5955876</span>
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "48pt" }}>
                        <td style={{ width: "103pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Year
                          </p>
                        </td>
                        <td style={{ width: "326pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "22pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            A 12-month period starting on the Start Date or the
                            anniversary of that date.
                          </p>
                        </td>
                      </tr>
                      <tr style={{ height: "81pt" }}>
                        <td style={{ width: "103pt" }}>
                          <p
                            className="s4"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            You <span className="s5">or </span>your
                          </p>
                          <p
                            className="p"
                            style={{ textIndent: "0pt", textAlign: "left" }}
                          >
                            <br />
                          </p>
                          <p
                            className="s4"
                            style={{
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            Ezyforms
                          </p>
                        </td>
                        <td style={{ width: "326pt" }}>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "8pt",
                              paddingLeft: "22pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            You or, if clause&nbsp; 1.1b applies, both you and
                            the other person on whose behalf you are acting.
                          </p>
                          <p
                            className="s5"
                            style={{
                              paddingTop: "6pt",
                              paddingLeft: "22pt",
                              paddingRight: "33pt",
                              textIndent: "3pt",
                              lineHeight: "16pt",
                              textAlign: "left",
                            }}
                          >
                            A chrome extension that works with Ezymigrate to
                            auto-fill forms
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p
                    className="p"
                    style={{ textIndent: "0pt", textAlign: "left" }}
                  >
                    <br />
                  </p>
                </li>
                <li data-list-text="2.2">
                  <h1
                    style={{
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Interpretation: <span className="p">In these Terms:</span>
                  </h1>
                  <ol id="l5">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Clause and other headings are for ease of reference only
                        and do not affect the interpretation of these Terms;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Words in the singular include the plural and vice versa;
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        A reference to:
                      </p>
                      <ol id="l6">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            A <b>party </b>includes that party’s permitted
                            assigns;
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <h1
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Personnel&nbsp;
                            <span className="p">
                              includes officers, employees, contractors and
                              agents, but a reference to your personnel does not
                              include us;
                            </span>
                          </h1>
                        </li>
                        <li data-list-text="iii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            A <b>person </b>includes an individual, a body
                            corporate, an association of persons (whether
                            corporate or not), a trust, a government department,
                            or any other entity;
                          </p>
                        </li>
                        <li data-list-text="iv">
                          <h1
                            style={{
                              paddingTop: "10pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Including&nbsp;
                            <span className="p">
                              and similar words do not imply any limit; and
                            </span>
                          </h1>
                        </li>
                        <li data-list-text="v">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            A statute includes references to regulations, orders
                            or notices made under or in connection with the
                            statute or regulations and all amendments,
                            replacements or other changes to any of them.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={3}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                SERVICES
              </p>
              <ol id="l7">
                <li data-list-text="3.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    General:&nbsp;
                    <span className="p">
                      We will use best efforts to provide the Services:
                    </span>
                  </h1>
                  <ol id="l8">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        In accordance with these Terms and New Zealand law;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Exercising reasonable care, skill and diligence; and
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Using suitably skilled and experienced personnel.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.2">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Non-exclusive:&nbsp;
                    <span className="p">
                      Our provision of the Services to you is non-exclusive.
                      Nothing in the Agreement prevents us from providing the
                      Services to any other person.
                    </span>
                  </h1>
                </li>
                <li data-list-text="3.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Availability:
                  </h1>
                  <ol id="l9">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Subject to clause&nbsp; 3.3b, we will use reasonable
                        efforts to ensure the Ezymigrate Service is available [
                        <i>on a 24/7 basis</i>] in New Zealand. However, it is
                        possible that on occasion the Ezymigrate Service may be
                        unavailable to permit maintenance or other development
                        activity to take place, or in the event of Force
                        Majeure. We will use reasonable efforts to publish on
                        the Website advance details of any unavailability.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Through the use of web services and APIs, the Ezymigrate
                        Service interoperates with a range of third party
                        service features, including those provided by the
                        Partners. We do not make any warranty or representation:
                      </p>
                      <ol id="l10">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "10pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            On the availability of those features. Without
                            limiting the previous sentence, if a third party
                            feature provider ceases to provide that feature or
                            ceases to make that feature available on reasonable
                            terms, we may cease to make available that feature
                            to you. To avoid doubt, if we exercise our right to
                            cease the availability of a third party feature, you
                            are not entitled to any refund, discount or other
                            compensation; or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "10pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            On the performance of those third parties, their
                            services or their systems, and we will have no
                            liability to you for any delay or failure to provide
                            the Services as a result of any delay or failure by
                            any such third party (including a Partner) or its
                            services or systems.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="3.4">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Underlying Systems:&nbsp;
                    <span className="p">
                      We are responsible for procuring all Underlying Systems
                      reasonably required for us to provide the Ezymigrate
                      Service in accordance with these Terms.
                    </span>
                  </h1>
                </li>
                <li data-list-text="3.5">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Related Services:
                  </h1>
                  <ol id="l11">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        We may, from time to time, make available additional
                        services and/or features to supplement the Ezymigrate
                        Service (<b>Related Services</b>
                        ).
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Subject to you paying the applicable Fees, and agreeing
                        to any different or further terms, we may agree to
                        provide to you a Related Service.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={4}>
              <p
                className="s2"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                SUPPORT SERVICES
              </p>
              <ol id="l12">
                <li data-list-text="4.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Support Services:&nbsp; For so long as you continue to
                    comply with these Terms (including paying the Fees) we will
                    provide the following support services in accordance with
                    the further provisions of this clause&nbsp;
                    <span className="p">4 (</span>Support Services
                    <span className="p">):</span>
                  </h1>
                  <ol id="l13">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        telephone and email support in the form of consultation,
                        assistance and advice; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        reasonable efforts to assist in the resolution of issues
                        (taking into account the nature and severity of the
                        issue):
                      </p>
                      <ol id="l14">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            24/7 for priority 1 issues (determined in our
                            reasonable discretion); and/or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            during our business hours (Monday to Friday from
                            8.30am to 5.00pm, excluding public holidays in
                            Auckland) (<b>Business Hours</b>) for non-priority 1
                            issues.
                          </p>
                          <ol id="l15">
                            <li data-list-text={2}>
                              <h1
                                style={{
                                  paddingTop: "10pt",
                                  paddingLeft: "33pt",
                                  textIndent: "-28pt",
                                  textAlign: "left",
                                }}
                              >
                                Conditions:&nbsp;
                                <span className="p">
                                  The provision of support is conditional on you
                                  contacting us:
                                </span>
                              </h1>
                              <ol id="l16">
                                <li data-list-text="a">
                                  <p
                                    style={{
                                      paddingTop: "12pt",
                                      paddingLeft: "61pt",
                                      textIndent: "-28pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    by phone during our Business Hours; or
                                  </p>
                                </li>
                                <li data-list-text="b">
                                  <p
                                    style={{
                                      paddingTop: "12pt",
                                      paddingLeft: "61pt",
                                      textIndent: "-28pt",
                                      textAlign: "left",
                                    }}
                                  >
                                    by email to&nbsp; support@ezymigrate.com
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li data-list-text={3}>
                              <h1
                                style={{
                                  paddingTop: "12pt",
                                  paddingLeft: "33pt",
                                  textIndent: "-28pt",
                                  lineHeight: "117%",
                                  textAlign: "left",
                                }}
                              >
                                Exclusions:&nbsp;
                                <span className="p">
                                  Nothing in these Terms requires us to provide
                                  Support Services where the support is required
                                  as a result of:
                                </span>
                              </h1>
                              <ol id="l17">
                                <li data-list-text="a">
                                  <p
                                    style={{
                                      paddingTop: "9pt",
                                      paddingLeft: "61pt",
                                      textIndent: "-28pt",
                                      lineHeight: "117%",
                                      textAlign: "left",
                                    }}
                                  >
                                    modification or alteration of the Ezymigrate
                                    Service by any person other than us; or
                                  </p>
                                </li>
                                <li data-list-text="b">
                                  <p
                                    style={{
                                      paddingTop: "9pt",
                                      paddingLeft: "61pt",
                                      textIndent: "-28pt",
                                      lineHeight: "115%",
                                      textAlign: "left",
                                    }}
                                  >
                                    a breach of these Terms by you or your
                                    personnel, including use of the Ezymigrate
                                    Service by you or your personnel in a manner
                                    or for a purpose not reasonably contemplated
                                    by these Terms and not authorised in writing
                                    by us.
                                  </p>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={5}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                CLIENT OBLIGATIONS
              </p>
              <ol id="l18">
                <li data-list-text="5.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    General use:&nbsp;
                    <span className="p">You and your personnel must:</span>
                  </h1>
                  <ol id="l19">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Use the services in accordance with these Terms and Good
                        Industry Practice and solely for:
                      </p>
                      <ol id="l20">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Your ordinary business purposes of providing
                            immigration services to your own clients; and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Lawful purposes (including complying with the
                            Unsolicited Electronic Messages Act 2007);
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Comply with all applicable immigration, employment, and
                        privacy, laws and codes of practice;
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Not resell or make available the Services to any third
                        party, or otherwise commercially exploit the Services
                        except as expressly permitted under these Terms; and
                      </p>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        provide true, current and complete information in your
                        dealings with us (including when setting up an account),
                        and must promptly update that information as required so
                        that the information remains true, current and complete.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="5.2">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Access conditions:&nbsp;
                    <span className="p">
                      When accessing the Ezymigrate Service, you and your
                      personnel must:
                    </span>
                  </h1>
                  <ol id="l21">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Not impersonate another person or misrepresent
                        authorisation to act on behalf of others or us;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Correctly identify the sender of all electronic
                        transmissions;
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Not attempt to undermine the security or integrity of
                        the Underlying Systems;
                      </p>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Not use, or misuse, the Ezymigrate Service in any way
                        which may impair the functionality of the Underlying
                        Systems or impair the ability of any other user to use
                        the Ezymigrate Service;
                      </p>
                    </li>
                    <li data-list-text="e">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Not attempt to view, access or copy any material or data
                        other than that to which you are authorised to access;
                      </p>
                    </li>
                    <li data-list-text="f">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Neither use the Ezymigrate Service in a manner, nor
                        transmit, input or store any Data, that breaches any
                        third party right (including Intellectual Property
                        Rights and privacy rights) or is Objectionable,
                        incorrect or misleading; and
                      </p>
                    </li>
                    <li data-list-text="g">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Comply with any terms of use on the Website, as updated
                        from time to time by us<i>.</i>
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="5.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Personnel:
                  </h1>
                  <ol id="l22">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Without limiting clause&nbsp; 5.2, no individual other
                        than a Permitted User may access or use the Ezymigrate
                        Service.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        You may authorise any member of your personnel to be a
                        Permitted User, provided that:
                      </p>
                      <ol id="l23">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            The number of Permitted Users does not exceed any
                            maximum number of users notified to you or selected
                            by you when setting up
                          </p>
                          <p
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "90pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            your account. To avoid doubt, this restriction does
                            not limit the number of end clients you may permit
                            to use the Client Portal; and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            You provide us with the Permitted User’s name and
                            other information that we reasonably require in
                            relation to the Permitted User.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        You and each Permitted User must keep any User ID
                        allocated to you secure, and:
                      </p>
                      <ol id="l24">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            not permit any other person to use that User ID,
                            including not disclosing or providing it to any
                            other person; and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            immediately notify us on becoming aware of any
                            unauthorised use or disclosure of that User ID, by
                            sending an email to&nbsp; support@ezymigrate.com
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        You must:
                      </p>
                      <ol id="l25">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Procure each Permitted User’s compliance with these
                            Terms; and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Require your end clients to agree to any terms of
                            use applicable to the use of the Client Portal.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="e">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        An act or omission by any of the following is deemed to
                        be an act or omission by you:
                      </p>
                      <ol id="l26">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            your personnel (including, to avoid doubt, a
                            Permitted User); and/or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Any person using a User ID allocated to you or a
                            Permitted User.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="5.4">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Authorisations:&nbsp;
                    <span className="p">
                      You are responsible for procuring all licences,
                      authorisations and consents required for you and your
                      personnel to use the Services, including to use, store and
                      input Data into, and process and distribute Data through,
                      the Services.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={6}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                DATA
              </p>
              <ol id="l27">
                <li data-list-text="6.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Ezymigrate access to Data:
                  </h1>
                  <ol id="l28">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        You acknowledge that:
                      </p>
                      <ol id="l29">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            We may require access to the Data to exercise our
                            rights and perform our obligations under these Terms
                            (including to monitor, maintain and/or improve the
                            Services); and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "justify",
                            }}
                          >
                            To the extent that this is necessary but subject to
                            clause&nbsp; 9, we may authorise a member or members
                            of its personnel or our Partners to access the Data
                            for this purpose.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        You must arrange all consents and approvals that are
                        necessary for us, our personnel and Partners to access
                        the Data as described in
                      </p>
                      <p
                        style={{
                          paddingLeft: "61pt",
                          textIndent: "0pt",
                          textAlign: "left",
                        }}
                      >
                        clause&nbsp; 6.1 a.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="6.2">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Agent:
                  </h1>
                  <ol id="l30">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        You acknowledge and agree that to the extent data
                        contains personal information, in collecting, holding
                        and processing that information through the Services, we
                        are acting as your agent for the purposes of the Privacy
                        Act 1993 and any other applicable privacy law.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        You must obtain all necessary consents from the relevant
                        individual to enable us and our personnel to collect,
                        use, hold and process that information in accordance
                        with these Terms.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="6.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Backups of Data:&nbsp;
                    <span className="p">
                      While we will take standard industry measures to back up
                      all data stored using the Ezymigrate Service, you must
                      keep, in accordance with Good Industry Practice, separate
                      back-up copies of all Data uploaded by you onto the
                      Ezymigrate Service.
                    </span>
                  </h1>
                </li>
                <li data-list-text="6.4">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Storage of Data
                    <span className="p">
                      : The Ezymigrate Service does not include a maximum data
                      storage capacity under the Fees; provided that you must
                      request from us additional data storage capacity once your
                      Data storage reaches limit. On receipt of your request, we
                      will provide additional data storage capacity at no extra
                      cost.
                    </span>
                  </h1>
                </li>
                <li data-list-text="6.5">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    International storage of Data:&nbsp;
                    <span className="p">
                      You agree that we may store, and may engage third party
                      service providers to store on our behalf, Data (including
                      any Personal Information) in secure servers in Australia
                      and we, and those third party service providers, may
                      access that Data (including any Personal Information) in
                      Australia and New Zealand from time to time. Ezymigrate
                      currently uses Microsoft Azure for data storage and
                      related services.
                    </span>
                  </h1>
                </li>
                <li data-list-text="6.6">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Indemnities:&nbsp;
                    <span className="p">
                      You indemnify us and our Partners against any liability,
                      claim, proceeding, cost, expense (including the actual
                      legal fees charged by our solicitors) and loss of any kind
                      arising from:
                    </span>
                  </h1>
                  <ol id="l31">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Any actual or alleged claim by a third party that any
                        Data infringes the rights of any third party (including
                        Intellectual Property Rights and privacy rights) or that
                        the Data is Objectionable, incorrect, incomplete,
                        misleading, or has not been provided to any person in a
                        timely manner;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Your use of the Services in any unlawful or negligent
                        manner; or
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        A breach by you of clause&nbsp; 6.3.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={7}>
              <p
                className="s2"
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                FEES
              </p>
              <ol id="l32">
                <li data-list-text="7.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Fees: <span className="p">You must pay us the Fees.</span>
                  </h1>
                </li>
                <li data-list-text="7.2">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Invoicing and payment:
                  </h1>
                  <ol id="l33">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        We will provide you with valid GST tax invoices, monthly
                        in advance for the Fees due in the following month.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        The Fees exclude GST, which you must pay on taxable
                        supplies under these Terms.
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        You must pay the Fees electronically in cleared funds
                        without any set off or deduction:
                      </p>
                      <ol id="l34">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            Using our payment provider, Ezidebit, which you must
                            set up to automatically debit the Fees from your
                            bank account (see&nbsp;
                            <span
                              style={{
                                color: "black",
                                fontFamily: "Arial, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textDecoration: "underline",
                                fontSize: "12pt",
                              }}
                            >
                              www.ezidebit.com
                            </span>
                            &nbsp; for more information); and
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "10pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Prior to the start of the month to which the
                            invoiced Services relate.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="7.3">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Increases:
                  </h1>
                  <ol id="l35">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        By giving at least [<i>2 months’</i>] notice, Ezymigrate
                        may increase the Fees (but not in the [Initial Term).
                        Fees updated under this clause are deemed to be the
                        Fees.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        If you do not wish to pay the increased Fees, you may
                        terminate your right to access and use the Services on
                        no less than [<i>1 month’s</i>] notice, provided the
                        notice is received by us before the effective date of
                        the Fee increase. If you do not terminate in accordance
                        with this clause, you are deemed to have accepted the
                        increased Fees.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="7.4">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Refunds:&nbsp;
                    <span className="p">
                      We will not refund (on termination or otherwise) any Fees
                      paid in advance, unless required to do so by law.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={8}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                INTELLECTUAL PROPERTY
              </p>
              <ol id="l36">
                <li data-list-text="8.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Ownership:
                  </h1>
                  <ol id="l37">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Subject to clause&nbsp; 8.1b, title to, and all
                        Intellectual Property Rights in, the Services, the
                        Website, and all Underlying Systems is and remains the
                        property of us (and our licensors). You must not dispute
                        that ownership.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Title to, and all Intellectual Property Rights in, the
                        Data (as between the parties) remains your property. You
                        grant us and our personnel a worldwide, non-exclusive,
                        fully paid up, transferable, irrevocable licence to use,
                        store, copy, modify, make available and communicate the
                        Data for
                      </p>
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "0pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        any purpose in connection with the exercise of our
                        rights and performance of our obligations in accordance
                        with these Terms.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="8.2">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Know-how:&nbsp;
                    <span className="p">
                      To the extent not owned by us, you grant us a
                      royalty-free, transferable, irrevocable and perpetual
                      licence to use any know-how, techniques, ideas,
                      methodologies, and similar Intellectual Property used by
                      us in the provision of the Services.
                    </span>
                  </h1>
                </li>
                <li data-list-text="8.3">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Feedback:&nbsp;
                    <span className="p">
                      If you provide us with ideas, comments or suggestions
                      relating to the Services or Underlying Systems (together
                      &nbsp;
                    </span>
                    feedback<span className="p">):</span>
                  </h1>
                  <ol id="l38">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        all Intellectual Property Rights in that feedback, and
                        anything created as a result of that feedback (including
                        new material, enhancements, modifications or derivative
                        works), are owned solely by us; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        we may use or disclose the feedback for any purpose.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="8.4">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Third party sites and material:&nbsp;
                    <span className="p">
                      You acknowledge that the Ezymigrate Service may link to
                      third party websites or feeds that are connected or
                      relevant to the Ezymigrate Service. Any link from the
                      Ezymigrate Service does not imply any endorsement,
                      approval or recommendation of, or responsibility for,
                      those websites or feeds or their content or operators by
                      us. To the maximum extent permitted by law, we exclude all
                      responsibility or liability for those websites or feeds.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={9}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                CONFIDENTIALITY
              </p>
              <ol id="l39">
                <li data-list-text="9.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Security:&nbsp;
                    <span className="p">
                      Each party must, unless it has the prior written consent
                      of the other party:
                    </span>
                  </h1>
                  <ol id="l40">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Keep confidential at all times the Confidential
                        Information of the other party;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Take reasonable steps to effect and maintain adequate
                        security measures to safeguard the other party’s
                        Confidential Information from unauthorised access or
                        use; and
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Disclose the other party’s Confidential Information to
                        its personnel or professional advisors on a&nbsp;
                        <i>need to know </i>
                        basis only and, in that case, ensure that any personnel
                        or professional advisor to whom it discloses the other
                        party’s Confidential Information is aware of, and
                        complies with, the provisions of clauses 9.1a and&nbsp;
                        9.1b.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="9.2">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Permitted disclosure:&nbsp; The obligation of
                    confidentiality in clause&nbsp;
                    <span className="p">
                      9.1a does not apply to any disclosure or use of
                      Confidential Information:
                    </span>
                  </h1>
                  <ol id="l41">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        For the purpose of performing a party’s obligations or
                        exercising a party’s rights under these Terms;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        (Subject to clause&nbsp; 9.3) required by law (including
                        under the rules of any stock exchange);
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Which is publicly available through no fault of the
                        recipient of the Confidential Information or its
                        personnel;
                      </p>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Which was rightfully received by a party from a third
                        party without restriction and without breach of any
                        obligation of confidentiality; or
                      </p>
                    </li>
                    <li data-list-text="e">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        By us if required as part of a <i>bona fide </i>
                        sale of our business (assets or shares, whether in whole
                        or in part) to a third party, provided that we enter
                        into a confidentiality agreement with the third party on
                        terms no less restrictive than this clause &nbsp; 9.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="9.3">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Notification:&nbsp;
                    <span className="p">
                      Where a party is required by law to disclose or use any
                      Confidential Information of the other party, it must:
                    </span>
                  </h1>
                  <ol id="l42">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        promptly, and prior to disclosure, give written notice
                        of the requirement to the other party to enable the
                        other party to seek a protective order or other
                        appropriate remedy; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        where the other party’s Confidential Information is
                        disclosed as a result of the requirement, use all
                        reasonable endeavours to obtain, from the recipient,
                        assurances that the Confidential Information will be
                        treated as confidential.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={10}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                WARRANTIES
              </p>
              <ol id="l43">
                <li data-list-text="10.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Mutual warranties:&nbsp;
                    <span className="p">
                      Each party warrants that it has full power and authority
                      to enter into and perform its obligations under these
                      Terms.
                    </span>
                  </h1>
                </li>
                <li data-list-text="10.2">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    No implied warranties:&nbsp;
                    <span className="p">
                      To the maximum extent permitted by law:
                    </span>
                  </h1>
                  <ol id="l44">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Our warranties are limited to those set out in these
                        Terms, and all other conditions, guarantees or
                        warranties whether expressed or implied by statute or
                        otherwise (including any warranty under the Sale of
                        Goods Act 1908) are expressly excluded and, to the
                        extent that they cannot be excluded, our liability for
                        them is limited to $500.00; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        We make no representation concerning the quality of the
                        Services, and without limiting the foregoing, do not
                        promise that the Ezymigrate Service will:
                      </p>
                      <ol id="l45">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "justify",
                            }}
                          >
                            Meet your (or any other person’s) requirements or be
                            suitable for a particular purpose, including that
                            the use of the Ezymigrate Service will:
                          </p>
                          <p
                            className="s13"
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            <span className="s6">▲&nbsp;</span>
                            <span className="s14">
                              Fulfil or meet any statutory role or
                              responsibility you have; or
                            </span>
                          </p>
                          <p
                            className="s13"
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "118pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            <span className="s6">▲&nbsp;</span>
                            <span className="s14">
                              Ensure that any person meets or complies with any
                              deadline, criteria for assessment, or legal
                              requirement; or
                            </span>
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Be secure, free of viruses or other harmful code,
                            uninterrupted or error free.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="10.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Consumer Guarantees Act:&nbsp;
                    <span className="p">
                      You agree and represent that you are acquiring the
                      Services, and accepting these Terms, for the purpose of a
                      business and that the Consumer Guarantees Act 1993 does
                      not apply to the supply of the Services or these Terms.
                    </span>
                  </h1>
                </li>
                <li data-list-text="10.4">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Limitation of remedies:&nbsp;
                    <span className="p">
                      Where legislation or rule of law implies into these Terms
                      a condition or warranty that cannot be excluded or
                      modified by contract, the condition or warranty is deemed
                      to be included in these Terms. However, our liability for
                      any breach of that condition or warranty is limited, at
                      our option, to:
                    </span>
                  </h1>
                  <ol id="l46">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Supplying the Services again; and/or
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Paying the costs of having the Services supplied again.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li data-list-text={11}>
              <p
                className="s2"
                style={{
                  paddingTop: "9pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                LIABILITY
              </p>
              <ol id="l47">
                <li data-list-text="11.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Maximum liability:&nbsp; Our maximum aggregate liability
                    under or in connection with these Terms or relating to the
                    Services, whether in contract, tort (including negligence),
                    breach of statutory duty or otherwise, must not in any Year
                    exceed an amount equal to the Fees paid by you under these
                    Terms in the previous Year (which in the first Year is
                    deemed to be the total Fees paid by you from the Start Date
                    to the date of the first event giving rise to liability).
                    The cap in this clause&nbsp; 11.1 includes the cap set out
                    in clause&nbsp;
                    <span className="p">10.2a.</span>
                  </h1>
                </li>
                <li data-list-text="11.2">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Unrecoverable loss:
                  </h1>
                  <ol id="l48">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Neither party is liable to the other under or in
                        connection with the Agreement or the Services for any:
                      </p>
                      <ol id="l49">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Loss of profit, revenue, savings, business, use,
                            and/or goodwill; or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Consequential, indirect, incidental or special
                            damage or loss of any kind.
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        We will not be liable to you or any other person under
                        or in connection with these Terms or the Services for
                        any loss of data (including Data).
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="11.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Ezymigrate unlimited liability:&nbsp; Clauses&nbsp; 11.1
                    and&nbsp;
                    <span className="p">
                      11.2 do not apply to limit our liability under or in
                      connection with these Terms for:
                    </span>
                  </h1>
                  <ol id="l50">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Personal injury or death;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Fraud or wilful misconduct; or
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        A breach of clause&nbsp; 9.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="11.4">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Client unlimited liability:&nbsp; Clause&nbsp;
                    <span className="p">
                      11.2a does not apply to limit your liability:
                    </span>
                  </h1>
                  <ol id="l51">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        To pay the Fees;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        For breach of clause&nbsp; 5;
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Under the indemnities in clause&nbsp; 6.6; or
                      </p>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Under or in connection with these Terms for those
                        matters stated in clauses&nbsp; 11.3a to&nbsp; 11.3c.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="11.5">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    No liability for other’s failure:&nbsp;
                    <span className="p">
                      Neither party will be responsible, liable, or held to be
                      in breach of these Terms for any failure to perform its
                      obligations under these Terms or otherwise, to the extent
                      that the failure is caused by the other party failing to
                      comply with its obligations under these Terms, or by the
                      negligence or misconduct of the other party or its
                      personnel.
                    </span>
                  </h1>
                </li>
                <li data-list-text="11.6">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Mitigation:&nbsp;
                    <span className="p">
                      Each party must take reasonable steps to mitigate any loss
                      or damage, cost or expense it may suffer or incur arising
                      out of anything done or not done by the other party under
                      or in connection with these Terms.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={12}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                TERM, TERMINATION AND SUSPENSION
              </p>
              <ol id="l52">
                <li data-list-text="12.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Duration:&nbsp; Unless terminated under clause&nbsp; 7.3b or
                    this clause&nbsp;
                    <span className="p">
                      12, your right to access and use the Services:
                    </span>
                  </h1>
                  <ol id="l53">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Starts on the Start Date and continues for the Initial
                        Term; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        On expiry of the Initial Term, continues for further
                        terms of 5 years each unless:
                      </p>
                      <ol id="l54">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            You give 1 month’s notice that your right to access
                            and use the Services will terminate on the expiry of
                            the Initial Term or the notice (as applicable); or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "115%",
                              textAlign: "left",
                            }}
                          >
                            We give 1 months’ notice that your right to access
                            and use the Services will terminate on the expiry of
                            the Initial Term or the notice (as applicable).
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="12.2">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "justify",
                    }}
                  >
                    Termination during Initial Term: &nbsp;
                    <span className="p">
                      You may terminate your right to access and use the
                      Services during the Initial Term by giving us 1 month’s
                      notice, provided that:
                    </span>
                  </h1>
                  <ol id="l55">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        We will not refund or credit any Fees paid by you in
                        advance; and
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        You must pay us the remainder of the Initial Term.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="12.3">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Other termination rights:&nbsp;
                    <span className="p">
                      Either party may, by notice to the other party,
                      immediately terminate your right to access and use the
                      Services if the other party:
                    </span>
                  </h1>
                  <ol id="l56">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Breaches any material provision of these Terms and the
                        breach is not:
                      </p>
                      <ol id="l57">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Remedied within 10 days of the receipt of a notice
                            from the first party requiring it to remedy the
                            breach; or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            Capable of being remedied;
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Becomes insolvent, liquidated or bankrupt, has an
                        administrator, receiver, liquidator, statutory manager,
                        mortgagee’s or charge’s agent appointed, becomes subject
                        to any form of insolvency action or external
                        administration, or ceases to continue business for any
                        reason; or
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        is unable to perform a material obligation under these
                        Terms for 30 days or more due to Force Majeure.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="12.4">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Consequences of termination or expiry:
                  </h1>
                  <ol id="l58">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Termination or expiry of your right to access and use
                        the Services does not affect either party’s rights and
                        obligations that accrued before that termination or
                        expiry.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        On termination under clause&nbsp; 12.2 of your right to
                        access and use the Services, you must pay the amount
                        under clause&nbsp; 12.2b.
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        Except to the extent that a party has ongoing rights to
                        use Confidential Information, at the other party’s
                        request following termination or expiry of your right to
                        access and use the Services, a party must promptly
                        return to the other party or destroy all Confidential
                        Information of the other party that is in the first
                        party’s possession or control.
                      </p>
                    </li>
                    <li data-list-text="d">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        At any time prior to one month after the date of
                        termination or expiry, you may:
                      </p>
                      <ol id="l59">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Download a copy of any readily available Data stored
                            using the Ezymigrate Service. ;
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "justify",
                            }}
                          >
                            Request in writing a copy of any readily available
                            Data stored using the Ezymigrate Service, provided
                            that you pay our reasonable costs of providing that
                            copy; and/or
                          </p>
                        </li>
                        <li data-list-text="iii">
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "justify",
                            }}
                          >
                            Request deletion of the Data stored using the
                            Ezymigrate Service, in which case we must use
                            reasonable efforts to promptly delete that
                          </p>
                          <p
                            style={{
                              paddingTop: "3pt",
                              paddingLeft: "90pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            Data. Deletion of Data within 20 working days is
                            deemed to fulfil our obligation under this
                            subparagraph.
                          </p>
                          <p
                            style={{
                              paddingTop: "9pt",
                              paddingLeft: "61pt",
                              textIndent: "0pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            To avoid doubt, we are not required to comply with
                            clause&nbsp; 12.4di or clause 12.4dii to the extent
                            that you previously requested deletion of the Data
                            or that any request under clause&nbsp; 12.4dii is
                            not in writing.
                          </p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li data-list-text="12.5">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Obligations continuing:&nbsp; Clauses which, by their
                    nature, are intended to survive termination or expiry of the
                    Agreement, including clauses &nbsp; 6.6,&nbsp; 8,&nbsp;
                    9,&nbsp;
                    <span className="p">11,</span>
                  </h1>
                  <p
                    style={{
                      paddingLeft: "33pt",
                      textIndent: "0pt",
                      lineHeight: "14pt",
                      textAlign: "left",
                    }}
                  >
                    12.4,&nbsp; 12.5 and&nbsp; 13, continue in force.
                  </p>
                </li>
                <li data-list-text="12.6">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "justify",
                    }}
                  >
                    Suspending access:&nbsp;
                    <span className="p">
                      Without limiting any other right or remedy available to
                      us, we may restrict or suspend your access to the
                      Ezymigrate Service where you (including any of your
                      personnel):
                    </span>
                  </h1>
                  <ol id="l60">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "10pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Undermine, or attempt to undermine, the security or
                        integrity of the Ezymigrate Service or any Underlying
                        Systems;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Use, or attempt to use, the Ezymigrate Service:
                      </p>
                      <ol id="l61">
                        <li data-list-text="i">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              textAlign: "left",
                            }}
                          >
                            For improper purposes; or
                          </p>
                        </li>
                        <li data-list-text="ii">
                          <p
                            style={{
                              paddingTop: "12pt",
                              paddingLeft: "90pt",
                              textIndent: "-28pt",
                              lineHeight: "117%",
                              textAlign: "left",
                            }}
                          >
                            In a manner, other than for normal operational
                            purposes, that materially reduces the operational
                            performance of the Ezymigrate Service; or
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Have otherwise materially breached these Terms (in our
                        reasonable opinion).
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="12.7">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "justify",
                    }}
                  >
                    Notice:&nbsp; We must notify you where we restrict or
                    suspend your access under clause&nbsp;
                    <span className="p">12.6.</span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={13}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                DISPUTES
              </p>
              <ol id="l62">
                <li data-list-text="13.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Good faith negotiations:&nbsp;
                    <span className="p">
                      Before taking any court action, a party must use best
                      efforts to resolve any dispute under, or in connection
                      with, these Terms or the Services through good faith
                      negotiations.
                    </span>
                  </h1>
                </li>
                <li data-list-text="13.2">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "justify",
                    }}
                  >
                    Obligations continue:&nbsp;
                    <span className="p">
                      Each party must, to the extent possible, continue to
                      perform its obligations under these Terms even if there is
                      a dispute.
                    </span>
                  </h1>
                </li>
                <li data-list-text="13.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Right to seek relief:&nbsp; This clause&nbsp;
                    <span className="p">
                      13 does not affect either party’s right to seek urgent
                      interlocutory and/or injunctive relief.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
            <li data-list-text={14}>
              <p
                className="s2"
                style={{
                  paddingTop: "7pt",
                  paddingLeft: "33pt",
                  textIndent: "-28pt",
                  textAlign: "left",
                }}
              >
                GENERAL
              </p>
              <ol id="l63">
                <li data-list-text="14.1">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Force Majeure:&nbsp;
                    <span className="p">
                      Neither party is liable to the other for any failure to
                      perform its obligations under the Agreement to the extent
                      caused by Force Majeure, provided that the affected party:
                    </span>
                  </h1>
                  <ol id="l64">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "117%",
                          textAlign: "left",
                        }}
                      >
                        Immediately notifies the other party and provides full
                        information about the Force Majeure;
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "9pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Uses best efforts to overcome the Force Majeure; and
                      </p>
                    </li>
                    <li data-list-text="c">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          textAlign: "left",
                        }}
                      >
                        Continues to perform its obligations to the extent
                        practicable.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="14.2">
                  <h1
                    style={{
                      paddingTop: "12pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Rights of third parties:&nbsp;
                    <span className="p">
                      No person other than you and us has any right to a benefit
                      under, or to enforce, these Terms.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.3">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Waiver:&nbsp;
                    <span className="p">
                      To waive a right under these Terms, that waiver must be in
                      writing and signed by the waiving party.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.4">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Independent contractor:&nbsp; Subject to clause&nbsp;
                    <span className="p">
                      6.2a, we are an independent contractor of yours, and no
                      other relationship (e.g. joint venture, agency, trust or
                      partnership) exists under these Terms.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.5">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Notices:&nbsp; If we need to contact you, we may do so by
                    email or by posting a notice on the Website. You agree that
                    this satisfies all legal requirements for written
                    communications. A notice given by you to us under these
                    Terms must be sent by email to&nbsp;
                    <span
                      style={{
                        color: "#00F",
                        fontFamily: "Arial, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "underline",
                        fontSize: "12pt",
                      }}
                    >
                      info@ezymigrate.co
                    </span>
                    <span className="p">
                      m or otherwise notified by us to you for this purpose.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.6">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Severability:&nbsp;
                    <span className="p">
                      Any illegality, unenforceability or invalidity of a
                      provision of these Terms does not affect the legality,
                      enforceability or validity of the remaining provisions.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.7">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "117%",
                      textAlign: "left",
                    }}
                  >
                    Variation:&nbsp;
                    <span className="p">
                      Except as otherwise permitted under these Terms, any
                      variation to these Terms must be in writing and signed by
                      both parties.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.8">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Entire agreement:&nbsp;
                    <span className="p">
                      These Terms set out everything agreed by the parties
                      relating to the Services, and supersede and cancel
                      anything discussed, exchanged or agreed prior to the Start
                      Date. The parties have not relied on any representation,
                      warranty or agreement relating to the subject matter of
                      these Terms that is not expressly set out in these Terms,
                      and no such representation, warranty or agreement has any
                      effect from the Start Date. Without limiting the previous
                      sentence, the parties agree that sections 9, 12A and 13 of
                      the Fair Trading Act 1986 do not apply to these Terms or
                      the supply of the Services.
                    </span>
                  </h1>
                </li>
                <li data-list-text="14.9">
                  <h1
                    style={{
                      paddingTop: "10pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      textAlign: "left",
                    }}
                  >
                    Subcontracting and assignment:
                  </h1>
                  <ol id="l65">
                    <li data-list-text="a">
                      <p
                        style={{
                          paddingTop: "12pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        You may not assign, novate, subcontract or transfer any
                        right or obligation under these Terms without our prior
                        written consent, that consent not to be unreasonably
                        withheld. You remain liable for your obligations under
                        these Terms despite any approved assignment,
                        subcontracting or transfer. Any assignment, novation,
                        subcontracting or transfer must be in writing.
                      </p>
                    </li>
                    <li data-list-text="b">
                      <p
                        style={{
                          paddingTop: "3pt",
                          paddingLeft: "61pt",
                          textIndent: "-28pt",
                          lineHeight: "115%",
                          textAlign: "left",
                        }}
                      >
                        A change of control affecting you is deemed to be an
                        assignment for which our prior written consent is
                        required under clause&nbsp; 14.9a. In this clause{" "}
                        <b>change of control </b>means any transfer of shares or
                        other arrangement affecting you or any member of its
                        group, which results in a change in your effective
                        control.
                      </p>
                    </li>
                  </ol>
                </li>
                <li data-list-text="14.10">
                  <h1
                    style={{
                      paddingTop: "9pt",
                      paddingLeft: "33pt",
                      textIndent: "-28pt",
                      lineHeight: "115%",
                      textAlign: "left",
                    }}
                  >
                    Law:&nbsp;
                    <span className="p">
                      These Terms are governed by, and must be interpreted in
                      accordance with, the laws of New Zealand. Each party
                      submits to the non-exclusive jurisdiction of the courts of
                      New Zealand in relation to any dispute connected with the
                      Agreement.
                    </span>
                  </h1>
                </li>
              </ol>
            </li>
          </ol>
          <p className="p" style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            className="s2"
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Ezyforms Disclaimer
          </p>
          <p
            style={{
              paddingTop: "12pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "115%",
              textAlign: "left",
            }}
          >
            15. You must personally review the auto-filled data to make sure it
            has been uploaded correctly. The user submits the web form to the
            concerned authority with their own responsibility. We cannot take
            any responsibility for incorrect data that might be submitted to any
            authority.
          </p>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;
