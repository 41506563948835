import React, { Fragment, useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
  Spin,
  Checkbox,
  AutoComplete,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../services/api";
const dateFormat = "DD/MM/YYYY";
function onChange(date, dateString) {
  console.log(date, dateString);
}

const { Option } = Select;

var notSearch = true;

const AddDealForm = ({
  onAddSalePipeline,
  handleCancel,

  createMode,

  setLoading,
  loading,
  onGetSalePipeline,
  state,
  onGetTeamMember,
  teamMembers,
  pipelineId,
  getSinglePiplineData,
  onSearchPotentialClient,
  onSearchClient,
  updateData,
  isUpdate,
  setIsUpdate,
  setUpdateData,
  onSearchEmployerMain,
  updateByProfile,
  onGetBranchPipeline,
  brnchPipelineData,
  clientId,
  clientType,
}) => {
  const [openDateChange, setOpenDateChange] = useState("");
  const [isPotential, setIsPotential] = useState(clientType || "client");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(clientId || "");
  const [stateOpt, setStateOpt] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    onGetTeamMember();
    form.setFieldsValue({
      isPotential: isPotential,
    });
    if (updateByProfile) {
      onGetBranchPipeline();
    } else {
      setStateOpt(state);
    }
  }, []);

  useEffect(() => {
    if (updateData && updateData.id) {
      if (updateByProfile) {
        setSelectedClient(sessionStorage.getItem("clientprofileid"));
      }
      if (updateData.pipelineId != "00000000-0000-0000-0000-000000000000")
        changePipeline(updateData.pipelineId);
      form.setFieldsValue({
        name: updateData.name || "",
        description: updateData.description || "",
        stageId: updateByProfile
          ? ""
          : updateData.stageId != "00000000-0000-0000-0000-000000000000"
          ? updateData.stageId
          : "",
        ownerId:
          updateData.ownerId != "00000000-0000-0000-0000-000000000000"
            ? updateData.ownerId
            : "",
        isPotential: updateData.isPotential || false,
        client: updateData.clientName !== "" ? updateData.clientName : "",
        amount: updateData.amount !== 0 ? updateData.amount : "",
        expectedCloseDate:
          updateData.expectedCloseDate &&
          updateData.expectedCloseDate != "1900-01-01T00:00:00+00:00"
            ? updateData.expectedCloseDate
            : "",
        priority:
          updateData.priority != 0 ? updateData.priority.toString() : "",
        pipelineId:
          updateData.pipelineId != "00000000-0000-0000-0000-000000000000"
            ? updateData.pipelineId
            : "",
        stageId:
          updateData.stageId != "00000000-0000-0000-0000-000000000000"
            ? updateData.stageId
            : "",
      });
      setSelectedClient(updateData.subjectId);
      setSelectedClientName(updateData.clientName);
    }
  }, [updateData, form, setLoading]);

  const onFinish = (values) => {
    setLoading(true);

    let data = {
      name: values.name || "",
      description: values.description || "",
      stageId: values.stageId || "00000000-0000-0000-0000-000000000000",
      ownerId: values.ownerId || "00000000-0000-0000-0000-000000000000",
      isPotential: values.isPotential || clientType,
      subjectId: selectedClient || "00000000-0000-0000-0000-000000000000",
      amount: values.amount ? parseInt(values.amount) : 0,
      expectedCloseDate: values.ExpCloseDate || "1900-01-01T00:00:00+00:00",
      priority: values.priority ? parseInt(values.priority) : 0,
      status: 0,
    };
    if (isUpdate && updateData && updateData.id) {
      data.closeDate = values.ExpCloseDate || "1900-01-01T00:00:00+00:00";
      data.id = updateData.id;
      const updDealOpt = {
        url: `v1/deal`,
      };
      updDealOpt.types = ["UPDATE_DEAL_SUCCESS", "UPDATE_DEAL_FAILURE"];

      apiRefresh.put(updDealOpt, data).then((res) => {
        setIsUpdate(false);
        setUpdateData(null);
        setLoading(false);
        form.resetFields();
        getSinglePiplineData(pipelineId);
        handleCancel();
        message.success("Successfully updated!");
      });
    } else {
      const addDealOpt = {
        url: `v1/deal`,
      };
      addDealOpt.types = ["ADD_DEAL_SUCCESS", "ADD_DEAL_FAILURE"];

      apiRefresh
        .post(addDealOpt, data)
        .then(() => {
          setLoading(false);
          getSinglePiplineData(pipelineId);
          handleCancel();
          message.success("Successfully Added!");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const searchResult = (query) =>
    query.map((_, idx) => {
      let category;
      if (_.name) {
        category = _.name;
      } else if (_.label) {
        category = _.label;
      } else {
        category = `${_.firstName} ${_.lastName}`;
      }
      return {
        value: category,
        label: (
          <div
            className="search-client-card-cont"
            key={_.id}
            style={{ display: "block" }}
          >
            <span>{category}</span>
            <div style={{ display: "flex" }}>
              <div>
                <span class="date-text">DOB :</span>
              </div>
              <div>
                <span class="date-text">
                  &nbsp;
                  {_.dateOfBirth
                    ? _.dateOfBirth !== "1900-01-01T00:00:00+00:00"
                      ? moment(_.dateOfBirth).format("DD/MM/YYYY")
                      : ""
                    : _.dob && _.dob !== "1900-01-01T00:00:00+00:00"
                    ? moment(_.dob).format("DD/MM/YYYY")
                    : ""}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <span class="date-text">Email :</span>
              </div>
              <div>
                <span class="date-text">&nbsp;{_.email}</span>
              </div>
            </div>
          </div>
        ),
      };
    });

  const handleSearch = (val) => {
    console.log("search:", val);
    if (val.length > 2 && notSearch) {
      notSearch = false;

      setTimeout(() => {
        setLoading(true);
        setOptions(searchResult([]));
        if (isPotential == "pc") {
          onSearchPotentialClient(val)
            .then((res) => {
              notSearch = true;
              setLoading(false);
              setOptions(
                res && res.payload && res.payload.potentialClients
                  ? searchResult(res.payload.potentialClients)
                  : []
              );
            })
            .catch((err) => {
              notSearch = true;
              setLoading(false);
            });
        } else if (isPotential == "client") {
          onSearchClient(val)
            .then((res) => {
              setLoading(false);
              notSearch = true;
              setOptions(
                res && res.payload && res.payload.clients
                  ? searchResult(res.payload.clients)
                  : []
              );
            })
            .catch((err) => {
              notSearch = true;
              setLoading(false);
            });
        } else {
          onSearchEmployerMain(val).then((res) => {
            setLoading(false);
            notSearch = true;
            setOptions(
              res && res.payload && res.payload.items
                ? searchResult(res.payload.items)
                : []
            );
          });
        }
      }, 1500);
    }
  };

  const onSelect = (value, opt) => {
    setSelectedClientName(value);
    setSelectedClient(opt.label.key);
  };

  const changePipeline = (e) => {
    var findPipeline =
      brnchPipelineData &&
      brnchPipelineData.pipeline.find((obj) => obj.id == e);
    if (findPipeline) {
      setStateOpt(findPipeline.stagesList);
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <Fragment>
        <Form
          onFinish={onFinish}
          form={form}
          className="emp-content-box p-box add-job-form width-100"
          name="main"
        >
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Name</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          {updateByProfile && (
            <Row gutter={8} className="margin-top">
              <Col span={8}>
                <p style={{ fontSize: 13 }}>Sales Pipeline</p>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="pipelineId"
                  // rules={[{ required: true, message: "Required!" }]}
                >
                  <Select
                    style={{ width: 300 }}
                    placeholder=""
                    onChange={changePipeline}
                  >
                    {brnchPipelineData &&
                      brnchPipelineData.pipeline &&
                      brnchPipelineData.pipeline.length > 0 &&
                      brnchPipelineData.pipeline.map((item, ind) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Stage</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="stageId"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select style={{ width: 300 }} placeholder="">
                  {stateOpt &&
                    stateOpt.length > 0 &&
                    stateOpt.map((item, ind) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Owner</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="ownerId"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select style={{ width: 300 }} placeholder="">
                  {teamMembers &&
                    teamMembers.users.map((user, ind) => (
                      <Option value={user.id}>{user.fullName}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {updateByProfile ? null : (
            <Row gutter={8} className="margin-top">
              <Col span={8}>
                <p style={{ fontSize: 13 }}>Type</p>
              </Col>
              <Col span={16}>
                <Form.Item name="isPotential">
                  <Select
                    style={{ width: 300 }}
                    placeholder=""
                    onChange={(e) => {
                      setIsPotential(e);
                      setSelectedClientName("");
                      setSelectedClient("");
                      setOptions([]);
                    }}
                  >
                    <Option value="client">Client</Option>
                    <Option value="pc">Potential Client</Option>
                    <Option value="employer">Employer</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {updateByProfile ? null : (
            <Row gutter={8} className="margin-top">
              <Col span={8}>
                <p style={{ fontSize: 13 }}>Client</p>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="client"
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <AutoComplete
                    // style={{ width: 300 }}
                    options={options}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    notFoundContent="Not Found"
                    placeholder="Search Client"
                    value={selectedClientName}
                    onChange={(val) => setSelectedClientName(val)}
                  ></AutoComplete>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Amount</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="amount"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <InputNumber placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Expected Close Date</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="ExpCloseDate"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Priority</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="priority"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select placeholder="">
                  <Option value="1">Low</Option>
                  <Option value="2">Medium</Option>
                  <Option value="3">High</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="margin-top-20">
            <div className="letter-froala emp-froala">
              <div className="document-checklist--btn d-flex">
                <Button
                  style={{ marginRight: "10px" }}
                  // onClick={() => emailTypeData(data.emailType)}
                  htmlType="submit"
                  type="primary"
                  className="button-blue"
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="button-blue"
                >
                  Close
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    </Spin>
  );
};
export default AddDealForm;
