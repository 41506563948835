import React from "react";
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Spin, Button } from "antd";

import "../Login/LoginStyles.css";
import { Images } from "../Themes";
import { apiRefresh } from "../services/api";

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      loading: false,
      imageUrl: "",
      imageLoading: true,
    };
  }

  componentDidMount() {
    const getImageOpt = {
      url: `v1/admin/EzyMigrateSettings/GetLoginPageImage`,
    };
    getImageOpt.types = ["GET_IMAGE_SUCCESS", "GET_IMAGE_FAILURE"];

    apiRefresh
      .get(getImageOpt)
      .then((res) => {
        this.setState({ imageUrl: (res && res.paramValue) || "" });
        this.setState({ imageLoading: false });
      })
      .catch((err) => {
        this.setState({ imageLoading: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData !== this.props.userData) {
      // localStorage.setItem("token", this.props.userData.accessToken);
      // this.props.history.push("/profile");
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    let data = {
      email: this.state.userName,
      password: this.state.password,
    };
    this.props
      .onAdminLogin(data)
      .then((res) => {
        this.setState({ loading: false }, () => {
          localStorage.removeItem("token");
          localStorage.setItem("admintoken", res.payload.accessToken);
          localStorage.setItem("refreshToken", res.payload.refreshToken);

          let admintoken = localStorage.getItem("admintoken");
          var jwtDecoded = jwt_decode(res.payload.accessToken);
          localStorage.setItem("userId", jwtDecoded.sub);
          localStorage.setItem("userEmail", jwtDecoded.email);
          localStorage.setItem("userName", jwtDecoded.FullName);
          localStorage.setItem("adminPermissions", jwtDecoded.Permissions);
          this.props.getAdminToken(admintoken);
          this.props.history.push("/companies");
        });
      })
      // .then((res) => {
      //   data = {
      //     refreshToken: res.payload.refreshToken,
      //   };
      //
      //   this.props.onRefreshToken(data);
      // })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    let a = this.props;
    const { userName, password, imageLoading } = this.state;
    return (
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: "#FFFFFF" }}
      >
        <Spin spinning={imageLoading} size="large">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // paddingLeft: 30,
              // paddingRight: 30,
            }}
          >
            <div style={{ marginLeft: 30, paddingTop: 80 }}>
              <div className="lg-item-center">
                <img
                  src={Images.logo}
                  className="logo-img"
                  style={{ width: 220, height: "auto" }}
                />
              </div>
              <div>
                <p className="lgin-text">Admin Log In</p>
              </div>
              <div className="lg-item-center"></div>
              <div className="input-cont-width">
                <p>Username</p>
                <div className="profile-input-border" style={{ width: 240 }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={userName}
                    onChange={(e) => this.myChangeHandler("userName", e)}
                  />
                </div>
              </div>
              <div className="input-cont-width">
                <p>Password</p>
                <div className="profile-input-border" style={{ width: 240 }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="password"
                    value={password}
                    onChange={(e) => this.myChangeHandler("password", e)}
                  />
                </div>
              </div>

              <div className="ts-send-btn-cont">
                <Button
                  type="primary"
                  loading={this.state.loading}
                  onClick={this.onSubmit}
                  className="ts-send-btn"
                  style={{ marginLeft: 10, width: 190 }}
                >
                  <span className="sus-modal-button-text">Log In</span>
                </Button>
              </div>

              <div
                className="ca-checkbox-row"
                style={{
                  marginLeft: 0,
                  marginBottom: 0,
                  justifyContent: "center",
                }}
              >
                <input
                  type="checkbox"
                  className="sus-checkbox"
                  onChange={this.handleCheck}
                  defaultChecked={this.state.checked}
                />
                <span className="cv-normal-text" style={{ color: "#363636" }}>
                  Keep me logged in
                </span>
              </div>
              <div
                className="lg-item-center"
                style={{ padding: 15, width: 210 }}
              >
                <span className="lg-copyright-text">
                  @2001-2021 All Rights Reserved. Ezymigrate is a registered
                  trademark of Smitten Online. Cookie Preferences Privacy and
                  Terms.
                </span>
              </div>
            </div>
            <div>
              <img
                src={this.state.imageUrl || Images.loginImage}
                className="img-login-bg"
                style={{ height: "100vh" }}
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranch: state.branchReducer.selectedBranch,
  };
};

export default withRouter(AdminLogin);
