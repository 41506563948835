import React from "react";
import "./ClientsStyles.css";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";
import moment from "moment";

import { Select, Input, Form, Button, message, InputNumber, Spin } from "antd";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";

import UpdateEmployerForm from "../Components/Client/EmployerForm";

import ProfileSideBar from "../Components/ProfileSideBar";

import headOption from "../Components/Header/HeaderTabOptions";
import activityData from "../Components/ClientActivity/ActivityData";

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "QUALIFICATION", linkName: "/qualification" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const userId = localStorage.getItem("userId");

const { Option } = Select;

var jobStatuses = [];

class Employer extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      employersArray: [],
      loading: false,
      showAddForm: false,
      loadEmployer: true,
      headerOptions: [],
      profileData: null,
      selectedEmployer: "",
    };

    this.props.onSetActiveKey(null);
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (clientprofileid) {
      this.props.onGetEmployerManag().then((res) => {
        let array = [];
        for (var i = 0; i < res.payload.items.length; i++) {
          array.push(
            <Option key={res.payload.items[i].id}>
              {res.payload.items[i].name}
            </Option>
          );
        }
        this.setState({ employersArray: array });
      });
      this.props
        .onGetClientEmployer(clientprofileid)
        .then((res) => {
          this.setState({ loadEmployer: false });
        })
        .catch((err) => {
          this.setState({ loadEmployer: false });
        });
      this.props.onGetClientJobStatuses().then((res) => {
        jobStatuses = [];
        if (res.payload.count > 0) {
          for (var i = 0; i < res.payload.statuses.length; i++) {
            jobStatuses.push(
              <Option key={res.payload.statuses[i].id}>
                {res.payload.statuses[i].name}
              </Option>
            );
          }
        }
      });
    } else {
      setTimeout(() => {
        this.setState({ loadEmployer: false });
      }, 1500);
    }
  }

  componentDidMount() {
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(this.props.profileDataRdx);
    // this.setState({ profileData: data });
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientProfileIdMain);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  componentDidUpdate(prevProps) {
    let memberType = sessionStorage.getItem("memberType");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      memberType == "client"
    ) {
      this.setProfileDataRdx(this.props.clientProfileData);
    } else if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      memberType == "spouse"
    ) {
      this.setProfileDataRdx(this.props.partnerProfileData);
    } else if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      memberType == "child"
    ) {
      let findClient =
        this.props.clientFamilyData &&
        this.props.clientFamilyData.items.find(
          (obj) => obj.id == clientprofileid
        );
      if (findClient) {
        this.setProfileDataRdx(findClient);
      }
    }
    if (
      (prevProps.profileDataRdx && !this.state.profileData) ||
      prevProps.profileDataRdx != this.props.profileDataRdx
    ) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
  }

  setProfileDataRdx = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  onChange = (e) => {
    console.log(e);
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    this.setState({ selectedEmployer: e });

    const create = window.confirm(
      "Are you sure you want to add this supplier to client?"
    );
    if (create) {
      var findEmployer = null;
      findEmployer = this.props.employerManagRes.items.find(
        (obj) => obj.id === e
      );
      let data = {
        employerId: findEmployer.id,
        clientId: clientprofileid,
        jobStatus:
          findEmployer.jobStatus === 0 || !findEmployer.jobStatus
            ? 2
            : findEmployer.jobStatus,
      };
      let empExist = null;
      if (
        this.props.clientEmployerRes &&
        this.props.clientEmployerRes.length > 0
      ) {
        empExist = this.props.clientEmployerRes.find((obj) => obj.id === e);
      }
      if (empExist) {
        message.warning("Supplier already exist!");
      } else {
        this.props
          .onLinkEmployer(data)
          .then((res) => {
            this.setState({ loading: false });
            message.success("Employer added successfully!");
            this.props.onGetClientEmployer(clientprofileid);
            let userName = localStorage.getItem("userName");
            var profileData = JSON.parse(this.props.profileDataRdx);
            if (profileData) {
              let myData = {
                clientName: profileData.fullName,
                logMessage:
                  "employer " + findEmployer.name + " assigned by " + userName,
                date: moment(new Date()).format("DD/MM/YYYY"),
                logType: "Client Information",
                invoiceId: "0",
              };
              activityData(myData);
            }
            this.setState({ selectedEmployer: "" });
          })
          .catch((err) => {
            this.setState({ loading: false });
            message.error("Failed to add employer!");
          });
      }
    }
  };

  onFinish = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loading: true });
    let data = {
      clientId: clientprofileid,
      name: values.name || "",
      email: values.email || "",
      contactNo: values.contactNo ? values.contactNo.toString() : "",
      business: values.business || "",
      city: "",
      contactPerson: values.contactPerson || "",
      mobile: values.mobile ? values.mobile.toString() : "",
      website: values.website || "",
      jobSector: values.jobSector || "",
      nzbn: values.nzbn || "",
      occupation: values.occupation || "",
      numberOfEmployees: "",
      yearsOfBusiness: "",
      isPotential: false,
      employerType: "Employers",
      address: values.address || "",
      agreementUrl: "",
      xeroID: "",
      jobStatus: values.jobStatus > 0 ? parseInt(values.jobStatus) : 2,
      accredationStartDate: "1900-01-01T00:00:00+00",
      accredationExpiryDate: "1900-01-01T00:00:00+00",
      isActive: true,
      secondaryEmail: values.secondaryEmail || "",
      irdNumber: values.irdNumber || "",
    };
    this.props
      .onAddClientEmployer(data)
      .then((res) => {
        this.setState({ loading: false });
        message.success("Employer added successfully!");
        this.formRef.current.resetFields();
        this.props.onGetClientEmployer(clientprofileid);
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage: "employer " + values.name + " added by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed to add employer!");
      });
  };

  render() {
    const {
      selectedOption,
      employersArray,
      showAddForm,
      loadEmployer,
      headerOptions,
      profileData,
      selectedEmployer,
    } = this.state;
    const {
      clientEmployerRes,
      onUpdClientEmployer,
      onGetClientEmployer,
      onRemoveClientEmployer,
      onGetEmployerJob,
      clientProfileData,
      onAddEmployerJobHistory,
      employerJobHistoryRes,
      visaStatusData,
      onUpdateCaseStatus,
      visaAppData,
      onGetVisaApplication,
      onGetClientJobHistoryCurrent,
      employerJobHistoryCurrentRes,
      onSetActiveKey,
      activeKey,
      onGetReminder,
      onGetVisaStatus,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaType,
      onGetVisaTypeByCountry,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadEmployer}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs
                  data={headerOptions}
                  activeTab="Client Profile"
                />
              )}
              <ProfileTopBarTabs
                data={topBar}
                activeTab="EMPLOYER INFORMATION"
              />
              <div
                className="emp-container"
                style={{ paddingBottom: 40, margin: 10 }}
              >
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  {profileData ? (
                    <div style={{ width: "100%" }}>
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  <div>
                    <div class="ce-add-button-cont" style={{ marginLeft: 0 }}>
                      <Button
                        className="emp-blue-btn"
                        style={{
                          marginRight: 10,
                          color: "#FFFFFF",
                          width: "100%",
                        }}
                        onClick={() => this.setState({ showAddForm: true })}
                      >
                        ADD
                      </Button>
                    </div>
                    <div class="emp-middle-cont" style={{ marginLeft: 0 }}>
                      <div class="form-cont">
                        <div style={{ width: "100%" }}>
                          <p class="emp-ace-bold-text">ADD CLIENT EMPLOYERS</p>
                        </div>
                      </div>
                      <div className="emp-ace-cont">
                        <div style={{ paddingLeft: 1, width: "55%" }}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            value={selectedEmployer}
                            onChange={this.onChange}
                          >
                            {employersArray}
                          </Select>
                        </div>
                      </div>
                    </div>
                    {clientEmployerRes &&
                      clientEmployerRes.map((employer, index) => {
                        return (
                          <UpdateEmployerForm
                            employer={employer}
                            index={index}
                            onUpdClientEmployer={onUpdClientEmployer}
                            onGetClientEmployer={onGetClientEmployer}
                            onRemoveClientEmployer={onRemoveClientEmployer}
                            onGetEmployerJob={onGetEmployerJob}
                            onAddEmployerJobHistory={onAddEmployerJobHistory}
                            jobStatuses={jobStatuses}
                            allEmployers={clientEmployerRes}
                            employerJobHistoryRes={
                              employerJobHistoryRes &&
                              employerJobHistoryRes.items
                            }
                          />
                        );
                      })}
                    {showAddForm && (
                      <div
                        class="emp-middle-cont"
                        style={{ marginTop: 20, marginLeft: 0 }}
                      >
                        <div class="form-cont">
                          <div style={{ width: "100%" }}>
                            <p class="emp-ace-bold-text">ADD EMPLOYER</p>
                          </div>
                        </div>
                        <div className="emp-ace-cont">
                          <Form onFinish={this.onFinish} ref={this.formRef}>
                            <div
                              class="form-cont"
                              style={{
                                justifyContent: "space-between",
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: "block",
                                width: "100%",
                              }}
                            >
                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Job Status
                                </p>
                                <div style={{ paddingLeft: 1, width: "65%" }}>
                                  <Form.Item name="jobStatus">
                                    <Select>{jobStatuses}</Select>
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Name*
                                </p>
                                <div className="emp-field-div">
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{ width: "100%" }}
                                      className="profile-input emp-input-border"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Legal Company Name
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="business">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Address
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="address">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Contact Person
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="contactPerson">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Email
                                </p>
                                <div className="emp-field-div">
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="email"
                                    rules={[
                                      {
                                        type: "email",
                                        message: "Required!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{ width: "100%" }}
                                      className="profile-input emp-input-border"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Secondary Email
                                </p>
                                <div className="emp-field-div">
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="secondaryEmail"
                                    rules={[
                                      {
                                        type: "email",
                                        message: "Required!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{ width: "100%" }}
                                      className="profile-input emp-input-border"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Mobile
                                </p>
                                <div className="emp-field-div">
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="mobile"
                                  >
                                    <InputNumber
                                      className="profile-input emp-input-border input-number-field"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Phone
                                </p>
                                <div className="emp-field-div">
                                  <Form.Item
                                    style={{ width: "100%" }}
                                    name="contactNo"
                                  >
                                    <InputNumber
                                      className="profile-input emp-input-border input-number-field"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Website
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="website">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Job Sector
                                </p>
                                <div style={{ paddingLeft: 1, width: "65%" }}>
                                  <Form.Item name="jobSector">
                                    <Select>
                                      <Option value="Administrative">
                                        Administrative
                                      </Option>
                                      <Option value="AgricultureFarming">
                                        Agriculture / Farming
                                      </Option>
                                      <Option value="Automotive">
                                        Automotive
                                      </Option>
                                      <Option value="Construction">
                                        Construction
                                      </Option>
                                      <Option value="Electrical">
                                        Electrical
                                      </Option>
                                      <Option value="Engineer">Engineer</Option>
                                      <Option value="Finance">Finance</Option>
                                      <Option value="FMCG">FMCG</Option>
                                      <Option value="Hospitality">
                                        Hospitality
                                      </Option>
                                      <Option value="Human Resources">
                                        Human Resources
                                      </Option>
                                      <Option value="Insurance">
                                        Insurance
                                      </Option>
                                      <Option value="ICT">ICT</Option>
                                      <Option value="Legal">Legal</Option>
                                      <Option value="Marketing">
                                        Marketing
                                      </Option>
                                      <Option value="Medical">Medical</Option>
                                      <Option value="Real estate">
                                        Real estate
                                      </Option>
                                      <Option value="Retail">Retail</Option>
                                      <Option value="Sales">Sales</Option>
                                      <Option value="Supply chain">
                                        Supply chain
                                      </Option>
                                      <Option value="Teachers">Teachers</Option>
                                      <Option value="Trades">Trades</Option>
                                      <Option value="Not Employed">
                                        Not Employed
                                      </Option>
                                      <Option value="Transportation">
                                        Transportation
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  Occupation
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="occupation">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  NZBN
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="nzbn">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="ce-field-row">
                                <p
                                  class="medical-label"
                                  style={{ fontSize: 11 }}
                                >
                                  IRD Number
                                </p>
                                <div class="emp-input-border">
                                  <Form.Item name="irdNumber">
                                    <Input
                                      className="profile-input"
                                      placeholder=""
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div />
                                <div
                                  className="button-blue-cont"
                                  style={{
                                    marginTop: 34,
                                    paddingRight: 0,
                                    width: 70,
                                  }}
                                >
                                  {/* <div className="emp-blue-btn">
                              <span style={{ color: "#FFFFFF", fontSize: 12 }}>
                                ADD
                              </span>
                            </div> */}
                                  <Button
                                    loading={this.state.loading}
                                    htmlType="submit"
                                    name="add"
                                    className="emp-blue-btn"
                                    style={{
                                      marginRight: 10,
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    ADD
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 15,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <ProfileSideBar
                        onGetClientTag={this.props.onGetClientTag}
                        clientTagRes={this.props.clientTagRes}
                        getClientRes={this.props.getClientRes}
                        onGetProfileClientTag={this.props.onGetProfileClientTag}
                        onAddProfileClientTag={this.props.onAddProfileClientTag}
                        addProfielTagRes={this.props.addProfielTagRes}
                        onRemoveProfileClientTag={
                          this.props.onRemoveProfileClientTag
                        }
                        removeProfileTagRes={this.props.removeProfileTagRes}
                        visaStatusData={visaStatusData}
                        onUpdateCaseStatus={onUpdateCaseStatus}
                        visaAppData={visaAppData}
                        onGetVisaApplication={onGetVisaApplication}
                        onGetClientJobHistoryCurrent={
                          onGetClientJobHistoryCurrent
                        }
                        employerJobHistoryCurrentRes={
                          employerJobHistoryCurrentRes
                        }
                        onSetActiveKey={onSetActiveKey}
                        activeKey={activeKey}
                        onGetClientTask={this.props.onGetClientTask}
                        onUpdateTask={this.props.onUpdateTask}
                        onGetAllUsers={this.props.onGetAllUsers}
                        onAddTaskFollower={this.props.onAddTaskFollower}
                        onRemoveTasks={this.props.onRemoveTasks}
                        onAddDailyTasks={this.props.onAddDailyTasks}
                        onGetClientFamily={this.props.onGetClientFamily}
                        onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                        onAddTaskFileNote={this.props.onAddTaskFileNote}
                        onAddTaskComment={this.props.onAddTaskComment}
                        onGetTaskComments={this.props.onGetTaskComments}
                        onGetTaskFollowers={this.props.onGetTaskFollowers}
                        onGetReminder={onGetReminder}
                        onGetVisaStatus={onGetVisaStatus}
                        onGetAdmissionProgram={onGetAdmissionProgram}
                        onGetAdmissionStatuses={onGetAdmissionStatuses}
                        onGetVisaType={onGetVisaType}
                        onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                      />
                    </div>
                  </div>
                </div>
                {false && (
                  <div style={{ paddingRight: 45 }}>
                    <div
                      class="sus-form-container"
                      style={{
                        width: "50%",
                        minHeight: 130,
                        marginLeft: 100,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#D9D9D9",
                      }}
                    >
                      <div
                        className="sus-add-cont-row"
                        style={{ marginTop: 10, marginRight: -40 }}
                      >
                        <div></div>
                        <div onClick={this.openModal}>
                          <img src={Images.plusIcon} style={{ width: 20 }} />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: -40,
                          marginRight: -40,
                        }}
                      >
                        <div className="sus-table"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Employer;
