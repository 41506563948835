import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
} from "antd";
import { bindActionCreators } from "redux";
import {
  getDailyTransactions,
  getIncomingOutgoingPayments,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class DailyTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsDailyTransactions: [],
      dateFrom: "1900-01-01T00:00:00+00",
      dateTo: "1900-01-01T00:00:00+00",
      loading: false,
      totalIncoming: 0,
      totalOutgoing: 0,
    };
  }

  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.date).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Client Number",
      dataIndex: "ezmId",
      key: "ezmId",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
      maxWidth: "200px",
    },
    {
      title: "Source/Bank",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
  ];

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getDailyTransactions(this.state)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _accountsDailyTransactions =
      nextProps && nextProps.accountsDailyTransactions;
    let _totalIncoming = 0;
    let _totalOutgoing = 0;
    if (_accountsDailyTransactions && _accountsDailyTransactions.length > 0) {
      for (let index = 0; index < _accountsDailyTransactions.length; index++) {
        if (_accountsDailyTransactions[index].type === "incoming") {
          _totalIncoming =
            _totalIncoming + _accountsDailyTransactions[index].amount;
        } else if (_accountsDailyTransactions[index].type === "outgoing") {
          _totalOutgoing =
            _totalOutgoing + _accountsDailyTransactions[index].amount;
        } else {
          //do-nothing
        }
      }
    }

    this.setState({
      accountsDailyTransactions: _accountsDailyTransactions,
      totalIncoming: _totalIncoming,
      totalOutgoing: _totalOutgoing,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  show = () => {
    if (
      this.state.dateFrom === "1900-01-01T00:00:00+00" ||
      this.state.dateFrom === "Invalid date"
    ) {
      message.error("Please select date from!");
    } else if (
      this.state.dateTo === "1900-01-01T00:00:00+00" ||
      this.state.dateTo === "Invalid date"
    ) {
      message.error("Please select date to!");
    } else {
      this.props.getDailyTransactions(this.state).then(() => {});
    }
  };

  showAll = () => {
    this.setState(
      {
        loading: true,
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
      },
      () => {
        this.props.getDailyTransactions(this.state).then(() => {
          this.setState({
            loading: false,
            dateFrom: "1900-01-01T00:00:00+00",
            dateTo: "1900-01-01T00:00:00+00",
          });
        });
      }
    );
  };

  getListingView = () => {
    return (
      <div>
        <div className="ca-gray-cont">
          <Row>
            <Col xs={15}>
              <Row>
                <Col xs={8} offset={1}>
                  <DatePicker
                    format={dateFormat}
                    placeholder={"SELECT DATE FROM"}
                    onChange={this.handleChangeDateFrom}
                  />
                </Col>
                <Col xs={7} offset={3}>
                  <DatePicker
                    placeholder={"SELECT DATE TO"}
                    format={dateFormat}
                    onChange={this.handleChangeDateTo}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={11} offset={10}>
                  <div style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      onClick={this.show}
                      style={{ marginRight: "4px" }}
                    >
                      SEARCH
                    </Button>
                    <Button type="primary" onClick={this.showAll}>
                      SHOW ALL
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="ca-gray-cont"
          style={{ padding: 20, paddingBottom: 20 }}
        >
          <Row>
            <Col xs={24} className={"school-table accounts-flow-table"}>
              <Table
                dataSource={
                  this.state.accountsDailyTransactions
                    ? this.state.accountsDailyTransactions
                    : null
                }
                rowClassName={(record, index) =>
                  record.type === "incoming"
                    ? "incoming-payments-table-row"
                    : "outgoing-payments-table-row"
                }
                columns={this.columns}
                pagination={false}
                scroll={{ x: "auto" }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24}>
            <div style={{ display: "block", marginLeft: "25px" }}>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: "bold" }}>
                  TOTAL OUTGOING :&nbsp;&nbsp;&nbsp;
                </p>
                <p>{`${Math.round(
                  (this.state.totalOutgoing + Number.EPSILON) * 100
                ) / 100}`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: "bold" }}>
                  TOTAL INCOMING :&nbsp;&nbsp;&nbsp;
                </p>
                <p>{`${Math.round(
                  (this.state.totalIncoming + Number.EPSILON) * 100
                ) / 100}`}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: "bold" }}>
                  DIFFERENCE :&nbsp;&nbsp;&nbsp;
                </p>
                <p>{`${Math.round(
                  (Math.abs(
                    this.state.totalIncoming - this.state.totalOutgoing
                  ) +
                    Number.EPSILON) *
                    100
                ) / 100}`}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className="page-container" style={{ marginTop: "-30px" }}>
              <div className="ts-container-settings">
                {this.getListingView()}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.accountReducer &&
    state.accountReducer.accountsDailyTransactions &&
    state.accountReducer.accountsDailyTransactions;

  return {
    accountsDailyTransactions:
      state &&
      state.accountReducer &&
      state.accountReducer.accountsDailyTransactions &&
      state.accountReducer.accountsDailyTransactions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDailyTransactions: bindActionCreators(getDailyTransactions, dispatch),
});
DailyTransactions = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyTransactions);

export default withRouter(DailyTransactions);
