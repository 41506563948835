// @flow
import React, { useState, useEffect } from "react";
import { Button, message, Select, Upload } from "antd";
import { Images } from "../../Themes";

function TeamMemberCard(Props) {
  const [show, setShow] = useState(false);
  const [pushValues, setPushValues] = useState(true);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(Props.member.fullName);
  const [email, setEmail] = useState(Props.member.alternateEmail);
  const [isButtonClicked, setClickButton] = useState(false);
  const [companyDesignation, setCompanyDesignation] = useState(
    Props.member.title
  );

  const [imageUrl, setImageUrl] = useState(
    Props.imageUrl ? Props.imageUrl : Props.member.image
  );
  const [selectedOption, setSelectedOption] = useState(
    Props.member.roleId == 3
      ? "User"
      : Props.member.roleId === 2
      ? "Manager"
      : Props.member.roleId === 1
      ? "Super User"
      : null
  );
  const [isManager, setIsManager] = useState(Props.member.isManager);
  const [isOwner, setIsOwner] = useState(Props.member.isOwner);
  const [isLock, setIsLock] = useState(Props.member.isLock);
  // const [ selectedOption, setSelectedOption ] = useState(Props.member.roleId );
  const [totalBranches, setTotalBranches] = useState([]);
  const [selectedBranches, setSelectedBranch] = useState([]);
  const [copySelectedBranches, setCopypSelectedBranch] = useState([]);
  const [deleteBranch, setDeleteBranch] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  var userOwner = localStorage.getItem("userOwner");
  var userId = localStorage.getItem("userId");

  useEffect(() => {
    if (Props.onSuccessUpdation) setLoading(false);
    setName(Props.member.fullName);
    setEmail(Props.member.alternateEmail);
    setCompanyDesignation(Props.member.title);
  }, [
    Props.onSuccessUpdation,
    Props.member.fullName,
    Props.member.alternateEmail,
    Props.member.title,
  ]);

  const { Option } = Select;

  const branch = [];
  const _totalBranches = [];
  if (Props.branchData && Props.branchData.items.length > 0) {
    for (let i = 0; i < Props.branchData.items.length; i++) {
      _totalBranches.push(Props.branchData.items[i].id);
      branch.push(
        <Option key={Props.branchData.items[i].id}>
          {Props.branchData.items[i].name}
        </Option>
      );
    }
  }

  const onClickDisable = () => {
    let d = Props.member;
    let state = isLock;
    let disableData = {
      userId: Props.member.id,
      isLock: !isLock,
    };
    setLoading(true);
    Props.onDisable(disableData)
      .then((res) => {
        setIsLock(disableData.isLock);

        if (disableData.isLock) {
          message.success("User disabled successfully!");
        } else {
          message.success("User enabled successfully!");
        }
        setLoading(false);
      })
      .catch(() => {
        message.error("Failed!");
        setLoading(false);
      });
  };

  const handleChange = (selectedOption) => {
    console.log("show selected user", selectedOption);
    setSelectedOption(parseInt(selectedOption));
  };

  if (
    pushValues &&
    Props.member.userBranches &&
    Props.member.userBranches.length > 0
  ) {
    for (var i = 0; i < Props.member.userBranches.length; i++) {
      if (Props.member.userBranches[i].status == 1)
        selectedBranches.push(Props.member.userBranches[i].branchId);
    }
    setCopypSelectedBranch(selectedBranches);
    setDeleteBranch(selectedBranches.concat(deleteBranch));
    setPushValues(false);
  }

  const handleChangeBranch = (selectedBranches) => {
    setDeleteBranch(copySelectedBranches);

    if (selectedBranches.length < copySelectedBranches.length) {
      if (copySelectedBranches.length === 1) {
        message.warning("Cann't delete all assinged branches!");
        return;
      }
      setSelectedBranch(selectedBranches);
      for (var i = 0; i < selectedBranches.length; i++) {
        for (var j = 0; j < deleteBranch.length; j++) {
          if (selectedBranches[i] === deleteBranch[j]) {
            deleteBranch.splice(j, 1);
            break;
          }
        }
      }
      let data = {
        branchId: deleteBranch[0],
        status: 0,
        userId: Props.member.id,
        branchDesignation: Props.member.title,
      };
      Props.onUnassignBranch(data)
        .then((res) => {
          Props.onGetUserBranch();
        })
        .catch((err) => {});
    } else {
      let data = {
        branchId: selectedBranches[selectedBranches.length - 1],
        status: 1,
        userId: Props.member.id,
        branchDesignation: Props.member.title,
      };
      Props.onAssingBranch(data)
        .then((res) => {
          Props.onGetUserBranch();
        })
        .catch((err) => {});
    }
  };

  let passwordData = {
    userId: Props.member.id,
    email: Props.member.alternateEmail,
    password: newPassword,
    confirmPassword: confirmPassword,
  };

  function updateTeamMember() {
    // setLoading(true);
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name.length < 3) {
      message.warning("Name should be more than three characters!");
      return;
    }
    if (!emailRegex.test(email)) {
      message.warning("Please enter valid email address");
      return;
    }
    let userData = {
      Id: Props.member.id,
      fullName: name,
      alternateEmail: email,
      isManager: isManager,
      isOwner: isOwner,
      RoleId:
        typeof selectedOption === "string" || selectedOption === null
          ? Props.member.roleId
          : selectedOption,
      Title: companyDesignation,
      Image: Props.imageUrl ? Props.imageUrl : imageUrl,
      ForgotPasswordBit: false,
      LastSignedIn: new Date(),
      Signature: "",
      TimeZone: "",
      comission: 0,
      agreementUrl: "",
      description: "",
      SignatureImage: "",
      userPermissionId:
        Props &&
        Props.member &&
        Props.member.userBranches[0] &&
        Props.member.userBranches[0].userPermissions[0] &&
        Props.member.userBranches[0].userPermissions[0].id &&
        Props.member.userBranches[0].userPermissions[0].id,
    };
    console.log("show user data ", userData, imageUrl);

    Props.parentMethod(userData, _totalBranches);
  }

  const makeData = (ownerBit, userDataId, totalBranches, selectedBranches) => {
    let _userData = {
      Id: Props.member.id,
      fullName: name,
      alternateEmail: email,
      isManager: isManager,
      isOwner: ownerBit,
      RoleId:
        typeof selectedOption === "string" || selectedOption === null
          ? Props.member.roleId
          : selectedOption,
      Title: companyDesignation,
      Image: Props.imageUrl ? Props.imageUrl : imageUrl,
      ForgotPasswordBit: false,
      LastSignedIn: new Date(),
      Signature: "",
      TimeZone: "",
      comission: 0,
      agreementUrl: "",
      description: "",
      SignatureImage: "",
      userPermissionId:
        Props &&
        Props.member &&
        Props.member.userBranches[0] &&
        Props.member.userBranches[0].userPermissions[0] &&
        Props.member.userBranches[0].userPermissions[0].id &&
        Props.member.userBranches[0].userPermissions[0].id,
    };
    Props.setParentOwnerMethod(
      ownerBit,
      userDataId,
      totalBranches,
      selectedBranches,
      _userData
    );
  };
  function updateUsersPassword() {
    // setPasswordLoading(true);
    // Props.onUsersPasswordUpdate(userData)
  }

  return (
    <div>
      <div style={{ marginTop: 20, width: "100%" }}>
        {show && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: -75,
              marginLeft: 45,
              marginTop: 20,
            }}
          >
            <div></div>
            <div
              className="sus-show-btn"
              onClick={() => {
                setShow({ show: false });
              }}
            >
              <span className="sus-save-btn-text">HIDE</span>
            </div>
          </div>
        )}
        <div
          className="sus-member-card-cont"
          style={{
            height: "auto",
            marginTop: show ? 0 : 20,
          }}
        >
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 20,
              paddingTop: 5,
              paddingBottom: 0,
              display: "block",
              width: `calc(100% + 30px)`,
              marginLeft: -30,
              marginRight: -30,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div className="sus-profile-cont">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={(info) => Props.uploadImage(info, Props.member.id)}
                    onChange={Props.handleChangeImage}
                  >
                    {Props.imageUrl ? (
                      <img
                        src={Props.imageUrl}
                        alt="avatar"
                        className="sus-profile-img"
                      />
                    ) : imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        className="sus-profile-img"
                      />
                    ) : (
                      <img
                        src={Images.dummyUserImage}
                        className="sus-profile-img"
                      />
                    )}
                  </Upload>
                </div>
              </div>
              <div style={{ width: "56%" }}>
                <div className="profile-input-border">
                  <input
                    className="profile-input"
                    placeholder="Advisor"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="profile-input-border" style={{ marginTop: 10 }}>
                  <input
                    className="profile-input"
                    placeholder="Email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
                <div className="profile-input-border" style={{ marginTop: 10 }}>
                  <input
                    className="profile-input"
                    placeholder="Company Role"
                    type="text"
                    value={companyDesignation}
                    onChange={(e) => setCompanyDesignation(e.target.value)}
                  />
                </div>

                <div
                  className="select-options multi-select-option"
                  style={{ marginTop: 10 }}
                >
                  <Select
                    mode="multiple"
                    bordered={false}
                    placeholder="Assign Branch"
                    defaultValue={selectedBranches}
                    onChange={handleChangeBranch}
                    style={{ width: "100%" }}
                  >
                    {branch}
                  </Select>
                </div>

                {userOwner == "True" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      marginRight: -50,
                    }}
                  >
                    {/* <div className="sus-checkbox-cont">
                      <input
                        type="checkbox"
                        className="sus-checkbox"
                        onChange={() => setIsManager(!isManager)}
                        defaultChecked={isManager}
                      />
                      <span className="sus-disable-text">Branch Manager</span>
                    </div> */}
                    <div className="sus-checkbox-cont">
                      <input
                        type="checkbox"
                        className="sus-checkbox"
                        onChange={() => {
                          setIsOwner(!isOwner);
                          makeData(
                            !isOwner,
                            Props.member.id,
                            _totalBranches,
                            selectedBranches
                          );
                        }}
                        defaultChecked={isOwner}
                      />
                      <span className="sus-disable-text">Owner</span>
                    </div>
                  </div>
                )}

                {Props.member.id != userId && (
                  <div
                    className="sus-disable-cont"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setClickButton(!isButtonClicked);
                      onClickDisable();
                    }}
                  >
                    <span className="sus-disable-text">
                      {isLock ? "Enable Team Member" : "Disable Team Member"}
                    </span>
                    <img src={Images.disable} className="sus-disable-icon" />
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => updateTeamMember()}
                    loading={loading}
                    type="primary"
                    className="sus-add-btn"
                    style={{ marginTop: 20 }}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div />
              <div style={{ width: "55%", paddingBottom: 40 }}>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <span className="sus-cp-text" style={{ fontSize: 12 }}>
                    CHANGE PASSWORD
                  </span>
                </div>
                <div>
                  <div
                    className="profile-input-border"
                    style={{ marginTop: 10 }}
                  >
                    <input
                      className="profile-input"
                      placeholder="Password"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>

                  <div
                    className="profile-input-border"
                    style={{ marginTop: 10 }}
                  >
                    <input
                      className="profile-input"
                      placeholder="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={() => Props.passwordUpdateMethod(passwordData)}
                    type="primary"
                    className="sus-save-btn"
                    style={{ marginTop: 20 }}
                  >
                    CHANGE PASSWORD
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMemberCard;
