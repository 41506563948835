import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BranchSetting from "../SuperUserSetting/BranchSettings";

import {
  getBranch,
  addBranch,
  getManager,
  assignBranch,
  unassignBranch,
  updateManager,
  uploadAvatar,
  getStorage,
  exportStorage,
  updBranch,
  getUserBranch,
  updAssignedBranch,
  disableUser,
  updUserPermission,
  changeUsersPassword,
  getTeamMember,
  getCountries,
  getAllCurrencies,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    branchData: state.branchReducer.branchData,
    branchError: state.branchReducer.branchError,
    updBranchSuccess: state.branchReducer.updBranchSuccess,
    userBranchData: state.branchReducer.userBranchData,
    companyData: state.companyReducer.companyData,
    teamMembers: state.teamMemberReducer.teamMembers,
    assignBranchSuccess: state.teamMemberReducer.assignBranchSuccess,
    unAssignBranchSuccess: state.teamMemberReducer.unAssignBranchSuccess,
    managerData: state.managerReducer.managerData,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    storageData: state.userDetailReducer.storageData,
    exportSuccess: state.userDetailReducer.exportSuccess,
    countriesData: state.visaTypeReducer.countriesData,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBranch: bindActionCreators(getBranch, dispatch),
    onAddBranch: bindActionCreators(addBranch, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetManager: bindActionCreators(getManager, dispatch),
    onAssignBranch: bindActionCreators(assignBranch, dispatch),
    onUpdAssignBranch: bindActionCreators(updAssignedBranch, dispatch),
    onUnassignBranch: bindActionCreators(unassignBranch, dispatch),
    onUpdateManager: bindActionCreators(updateManager, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetStorage: bindActionCreators(getStorage, dispatch),
    onExportData: bindActionCreators(exportStorage, dispatch),
    onUpdBranch: bindActionCreators(updBranch, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    onDisableUser: bindActionCreators(disableUser, dispatch),
    onUpdateUserPermission: bindActionCreators(updUserPermission, dispatch),
    onChangeUsersPassword: bindActionCreators(changeUsersPassword, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchSetting);
