import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  FolderAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { Table, message, Modal, Upload, Button, Row, Spin } from "antd";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const ContractEmailTable = ({
  onGetContractEmail,
  contractEmailRes,
  setCreateMode,
  setUpdatedata,
  updatedata,

  onSortLetterTemplates,

  removeTag,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [Attachments, setAttachments] = useState([]);
  const [showpopup, setShowpopup] = useState(false);
  const [TemplateId, setTemplateId] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    letterTempaltesFilter(contractEmailRes);
  }, [contractEmailRes]);

  const letterTempaltesFilter = (contractEmailRes) => {
    let filterList = [];
    if (contractEmailRes && contractEmailRes.length > 0) {
      contractEmailRes.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        filterList.push(data);
      });
      setDataSource(filterList);
    } else {
      setDataSource([]);
    }
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      width: 30,
      className: "drag-visible",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Email",
      dataIndex: "contractEmail",
      //   render: (text, record) => {
      //     return record.name;
      //   },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            {/* <EditOutlined onClick={() => showModal(record, "edit-value")} />
            <FolderAddOutlined
              onClick={() => ShowAttachments(record && record.id)}
            /> */}
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const attachmentsColumns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <DeleteOutlined onClick={() => removeAttach(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setDataSource(newData);
      const data = {
        id: dataSource && dataSource.length > 0 && dataSource[oldIndex].id,
        oldOrder: oldIndex,
        newOrder: newIndex,
      };

      onSortLetterTemplates(data).then(() => onGetContractEmail());
      message.success("Successfully Updated!");
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      // disableAutoscroll
      rowSelection
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      dataSource &&
      dataSource.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    } else setFileList(fileList);
  };
  const removeAttach = (id) => {
    setLoading(true);
    const docTypeOpt = {
      url: `v1/template/Attachment`,
    };
    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    var data = new Object();
    data.id = id;

    apiRefresh
      .delete(docTypeOpt, data)
      .then((res1) => {
        const docTypeOpt1 = {
          url: `v1/template/Attachments/All/` + TemplateId,
        };
        docTypeOpt1.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];

        apiRefresh
          .get(docTypeOpt1)
          .then((res) => {
            if (res.items) {
              setAttachments(res.items);
            } else {
              setAttachments([]);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns && columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};
export default ContractEmailTable;
