export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILURE = "REFRESH_FAILURE";

//Templates
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE_STATUS_SUCCESS = "UPDATE_TEMPLATE_STATUS_SUCCESS";
export const UPDATE_TEMPLATE_STATUS_FAILURE = "UPDATE_TEMPLATE_STATUS_FAILURE";

export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE";

export const GET_TEMPLATE_LISTING_SUCCESS = "GET_TEMPLATE_LISTING_SUCCESS";
export const GET_TEMPLATE_LISTING_FAILURE = "GET_TEMPLATE_LISTING_FAILURE";

export const GET_TEMPLATE_INFO_SUCCESS = "GET_TEMPLATE_INFO_SUCCESS";
export const GET_TEMPLATE_INFO_FAILURE = "GET_TEMPLATE_INFO_FAILURE";

export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

//Admin
export const GET_ADMIN_COMPANIES_SUCCESS = "GET_ADMIN_COMPANIES_SUCCESS";
export const GET_ADMIN_COMPANIES_FAILURE = "GET_ADMIN_COMPANIES_FAILURE";

export const IMPERSONATE_SUCCESS = "IMPERSONATE_SUCCESS";
export const IMPERSONATE_FAILURE = "IMPERSONATE_FAILURE";

export const GET_COMPANY_USERS_SUCCESS = "GET_COMPANY_USERS_SUCCESS";
export const GET_COMPANY_USERS_FAILURE = "GET_COMPANY_USERS_FAILURE";

export const BRANCH_USER_LOCK_UNLOCK_SUCCESS =
  "BRANCH_USER_LOCK_UNLOCK_SUCCESS";
export const BRANCH_USER_LOCK_UNLOCK_FAILURE =
  "BRANCH_USER_LOCK_UNLOCK_FAILURE";

export const GET_BRANCH_DETAIL_SUCCESS = "GET_BRANCH_DETAIL_SUCCESS";
export const GET_BRANCH_DETAIL_FAILURE = "GET_BRANCH_DETAIL_FAILURE";

export const UPDATE_BRANCH_DETAIL_SUCCESS = "UPDATE_BRANCH_DETAIL_SUCCESS";
export const UPDATE_BRANCH_DETAIL_FAILURE = "UPDATE_BRANCH_DETAIL_FAILURE";

export const GET_ADMIN_BRANCHES_SUCCESS = "GET_ADMIN_BRANCHES_SUCCESS";
export const GET_ADMIN_BRANCHES_FAILURE = "GET_ADMIN_BRANCHES_FAILURE";
//Admin Login

export const ADD_ADMIN_LOGIN_SUCCESS = "ADD_ADMIN_LOGIN_SUCCESS";
export const ADD_ADMIN_LOGIN_FAILURE = "ADD_ADMIN_LOGIN_FAILURE";

// Login

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

//Xero
export const GET_XERO_TOKEN_SUCCESS = "GET_XERO_TOKEN_SUCCESS";
export const GET_XERO_TOKEN_FAILURE = "GET_XERO_TOKEN_FAILURE";

export const CREATE_XERO_CODE_SUCCESS = "CREATE_XERO_CODE_SUCCESS";
export const CREATE_XERO_CODE_FAILURE = "CREATE_XERO_CODE_FAILURE";

export const CREATE_XERO_TOKEN_SUCCESS = "CREATE_XERO_TOKEN_SUCCESS";
export const CREATE_XERO_TOKEN_FAILURE = "CREATE_XERO_TOKEN_FAILURE";

export const POST_INVOICE_TO_XERO_SUCCESS = "POST_INVOICE_TO_XERO_SUCCESS";
export const POST_INVOICE_TO_XERO_FAILURE = "POST_INVOICE_TO_XERO_FAILURE";

export const UPDATE_INVOICE_TO_XERO_SUCCESS = "UPDATE_INVOICE_TO_XERO_SUCCESS";
export const UPDATE_INVOICE_TO_XERO_FAILURE = "UPDATE_INVOICE_TO_XERO_FAILURE";

export const POST_INVOICE_PAYMENT_TO_XERO_SUCCESS =
  "POST_INVOICE_PAYMENT_TO_XERO_SUCCESS";
export const POST_INVOICE_PAYMENT_TO_XERO_FAILURE =
  "POST_INVOICE_PAYMENT_TO_XERO_FAILURE";

export const POST_SCHOOL_INVOICE_TO_XERO_SUCCESS =
  "POST_SCHOOL_INVOICE_TO_XERO_SUCCESS";
export const POST_SCHOOL_INVOICE_TO_XERO_FAILURE =
  "POST_SCHOOL_INVOICE_TO_XERO_FAILURE";

export const UPDATE_SCHOOL_INVOICE_TO_XERO_SUCCESS =
  "UPDATE_SCHOOL_INVOICE_TO_XERO_SUCCESS";
export const UPDATE_SCHOOL_INVOICE_TO_XERO_FAILURE =
  "UPDATE_SCHOOL_INVOICE_TO_XERO_FAILURE";

export const GET_SCHOOL_INVOICE_PDF_SUCCESS = "GET_SCHOOL_INVOICE_PDF_SUCCESS";
export const GET_SCHOOL_INVOICE_PDF_FAILURE = "GET_SCHOOL_INVOICE_PDF_FAILURE";

export const GET_SCHOOL_INVOICE_RECEIPT_PDF_SUCCESS =
  "GET_SCHOOL_INVOICE_RECEIPT_PDF_SUCCESS";
export const GET_SCHOOL_INVOICE_RECEIPT_PDF_FAILURE =
  "GET_SCHOOL_INVOICE_RECEIPT_PDF_FAILURE";

export const GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS =
  "GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS";
export const GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_FAILURE =
  "GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_FAILURE";

// User Detail

export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const ADD_USER_DETAIL_SUCCESS = "ADD_USER_DETAIL_SUCCESS";
export const ADD_USER_DETAIL_FAILURE = "ADD_USER_DETAIL_FAILURE";

export const UPDATE_USER_DETAIL_SUCCESS = "UPDATE_USER_DETAIL_SUCCESS";
export const UPDATE_USER_DETAIL_FAILURE = "UPDATE_USER_DETAIL_FAILURE";

export const ADD_USER_ADDRESS_SUCCESS = "ADD_USER_ADDRESS_SUCCESS";
export const ADD_USER_ADDRESS_FAILURE = "ADD_USER_ADDRESS_FAILURE";

export const UPD_USER_ADDRESS_SUCCESS = "UPD_USER_ADDRESS_SUCCESS";
export const UPD_USER_ADDRESS_FAILURE = "UPD_USER_ADDRESS_FAILURE";

export const ADD_USER_CONTACT_SUCCESS = "ADD_USER_CONTACT_SUCCESS";
export const ADD_USER_CONTACT_FAILURE = "ADD_USER_CONTACT_FAILURE";

export const UPD_USER_CONTACT_SUCCESS = "UPD_USER_CONTACT_SUCCESS";
export const UPD_USER_CONTACT_FAILURE = "UPD_USER_CONTACT_FAILURE";

// Change password

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CHANGE_USERS_PASSWORD_SUCCESS = "CHANGE_USERS_PASSWORD_SUCCESS";
export const CHANGE_USERS_PASSWORD_FAILURE = "CHANGE_USERS_PASSWORD_FAILURE";

// Disable User

export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
export const DISABLE_USER_FAILURE = "DISABLE_USER_FAILURE";

// Upload avatar

export const UPLOAD_USER_AVATAR_SUCCESS = "UPLOAD_USER_AVATAR_SUCCESS";
export const UPLOAD_USER_AVATAR_FAILURE = "UPLOAD_USER_AVATAR_FAILURE";

// Group

export const GROUP_SUCCESS = "GROUP_SUCCESS";
export const GROUP_FAILURE = "GROUP_FAILURE";

export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";

export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";

// Manager

export const MANAGER_SUCCESS = "MANAGER_SUCCESS";
export const MANAGER_FAILURE = "MANAGER_FAILURE";

export const UPDATE_MANAGER_SUCCESS = "UPDATE_MANAGER_SUCCESS";
export const UPDATE_MANAGER_FAILURE = "UPDATE_MANAGER_FAILURE";

// Owner

export const OWNER_SUCCESS = "OWNER_SUCCESS";
export const OWNER_FAILURE = "OWNER_FAILURE";

export const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS";
export const UPDATE_OWNER_FAILURE = "UPDATE_OWNER_FAILURE";

// Team Member

export const TEAM_MEMBER_SUCCESS = "TEAM_MEMBER_SUCCESS";
export const TEAM_MEMBER_FAILURE = "TEAM_MEMBER_FAILURE";

export const TEAM_MEMBER_SUCCESS_OBJ = "TEAM_MEMBER_SUCCESS_OBJ";
export const TEAM_MEMBER_FAILURE_OBJ = "TEAM_MEMBER_FAILURE_OBJ";

export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_FAILURE = "ADD_TEAM_MEMBER_FAILURE";

export const ADD_PASSWORD_SUCCESS = "ADD_PASSWORD_SUCCESS";
export const ADD_PASSWORD_FAILURE = "ADD_PASSWORD_FAILURE";

export const ASSIGN_BRANCH_SUCCESS = "ASSIGN_BRANCH_SUCCESS";
export const ASSIGN_BRANCH_FAILURE = "ASSIGN_BRANCH_FAILURE";

export const UPDATE_ASSIGN_BRANCH_SUCCESS = "UPDATE_ASSIGN_BRANCH_SUCCESS";
export const UPDATE_ASSIGN_BRANCH_FAILURE = "UPDATE_ASSIGN_BRANCH_FAILURE";

export const UNASSIGN_BRANCH_SUCCESS = "UNASSIGN_BRANCH_SUCCESS";
export const UNASSIGN_BRANCH_FAILURE = "UNASSIGN_BRANCH_FAILURE";

export const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS";
export const ADD_AGENT_FAILURE = "ADD_AGENT_FAILURE";

// Branches

export const BRANCH_SUCCESS = "BRANCH_SUCCESS";
export const BRANCH_FAILURE = "BRANCH_FAILURE";

export const USER_BRANCH_SUCCESS = "USER_BRANCH_SUCCESS";
export const USER_BRANCH_FAILURE = "USER_BRANCH_FAILURE";

export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS";
export const ADD_BRANCH_FAILURE = "ADD_BRANCH_FAILURE";

export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "UPDATE_BRANCH_FAILURE";

// Company

export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILURE = "COMPANY_FAILURE";

export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

// Permission

export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
export const PERMISSION_FAILURE = "PERMISSION_FAILURE";

export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";

export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILURE = "UPDATE_PERMISSION_FAILURE";

// Visa Status

export const VISA_STATUS_SUCCESS = "VISA_STATUS_SUCCESS";
export const VISA_STATUS_FAILURE = "VISA_STATUS_FAILURE";

export const VISA_STATUS_HIDE_SUCCESS = "VISA_STATUS_HIDE_SUCCESS";
export const VISA_STATUS_HIDE_FAILURE = "VISA_STATUS_HIDE_FAILURE";

export const ADD_VISA_STATUS_SUCCESS = "ADD_VISA_STATUS_SUCCESS";
export const ADD_VISA_STATUS_FAILURE = "ADD_VISA_STATUS_FAILURE";

export const VISA_STATUS_REORDER_SUCCESS = "VISA_STATUS_REORDER_SUCCESS";
export const VISA_STATUS_REORDER_FAILURE = "VISA_STATUS_REORDER_FAILURE";

export const UPDATE_VISA_STATUS_SUCCESS = "UPDATE_VISA_STATUS_SUCCESS";
export const UPDATE_VISA_STATUS_FAILURE = "UPDATE_VISA_STATUS_FAILURE";

// Visa Types

export const VISA_TYPE_SUCCESS = "VISA_TYPE_SUCCESS";
export const VISA_TYPE_FAILURE = "VISA_TYPE_FAILURE";

export const VISA_TYPE_WITH_HIDDEN_SUCCESS = "VISA_TYPE_WITH_HIDDEN_SUCCESS";
export const VISA_TYPE_WITH_HIDDEN_FAILURE = "VISA_TYPE_WITH_HIDDEN_FAILURE";

export const ADD_VISA_TYPE_SUCCESS = "ADD_VISA_TYPE_SUCCESS";
export const ADD_VISA_TYPE_FAILURE = "ADD_VISA_TYPE_FAILURE";

export const HIDE_UNHIDE_VISA_TYPE_SUCCESS = "HIDE_UNHIDE_VISA_TYPE_SUCCESS";
export const HIDE_UNHIDE_VISA_TYPE_FAILURE = "HIDE_UNHIDE_VISA_TYPE_FAILURE";

export const HIDE_UNHIDE_VISA_STATUS_SUCCESS =
  "HIDE_UNHIDE_VISA_STATUS_SUCCESS";
export const HIDE_UNHIDE_VISA_STATUS_FAILURE =
  "HIDE_UNHIDE_VISA_STATUS_FAILURE";

export const UPDATE_VISA_TYPE_SUCCESS = "UPDATE_VISA_TYPE_SUCCESS";
export const UPDATE_VISA_TYPE_FAILURE = "UPDATE_VISA_TYPE_FAILURE";

// Potential client status

export const POTENTIAL_CLIENT_STATUS_SUCCESS =
  "POTENTIAL_CLIENT_STATUS_SUCCESS";
export const POTENTIAL_CLIENT_STATUS_FAILURE =
  "POTENTIAL_CLIENT_STATUS_FAILURE";

export const ADD_POTENTIAL_CLIENT_STATUS_SUCCESS =
  "ADD_POTENTIAL_CLIENT_STATUS_SUCCESS";
export const ADD_POTENTIAL_CLIENT_STATUS_FAILURE =
  "ADD_POTENTIAL_CLIENT_STATUS_FAILURE";

export const UPDATE_POTENTIAL_CLIENT_STATUS_SUCCESS =
  "UPDATE_POTENTIAL_CLIENT_STATUS_SUCCESS";
export const UPDATE_POTENTIAL_CLIENT_STATUS_FAILURE =
  "UPDATE_POTENTIAL_CLIENT_STATUS_FAILURE";

export const POTENTIAL_CLIENT_FORMAT_FILE_SUCCESS =
  "POTENTIAL_CLIENT_FORMAT_FILE_SUCCESS";
export const POTENTIAL_CLIENT_FORMAT_FILE_FAILURE =
  "POTENTIAL_CLIENT_FORMAT_FILE_FAILURE";

// Get countries

export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

// Get storage on right side

export const GET_STORAGE_SUCCESS = "GET_STORAGE_SUCCESS";
export const GET_STORAGE_FAILURE = "GET_STORAGE_FAILURE";

export const EXPORT_DATA_SUCCESS = "EXPORT_DATA_SUCCESS";
export const EXPORT_DATA_FAILURE = "EXPORT_DATA_FAILURE";

// Client profile

export const SET_CLIENT_PROFILE_LOADER_SUCCESS =
  "SET_CLIENT-PROFILE-LOADER_SUCCESS";

export const GET_CLIENT_TEMPLATE_LISTING_SUCCESS =
  "GET_CLIENT_TEMPLATE_LISTING_SUCCESS";
export const GET_CLIENT_TEMPLATE_LISTING_FAILURE =
  "GET_CLIENT_TEMPLATE_LISTING_FAILURE";

export const GET_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS =
  "GET_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS";
export const GET_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE =
  "GET_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE";

export const ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS =
  "ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS";
export const ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE =
  "ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE";

export const GET_CLIENT_TEMPLATE_INFO_SUCCESS =
  "GET_CLIENT_TEMPLATE_INFO_SUCCESS";
export const GET_CLIENT_TEMPLATE_INFO_FAILURE =
  "GET_CLIENT_TEMPLATE_INFO_FAILURE";

export const EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS =
  "EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS";
export const EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE =
  "EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE";

export const DELETE_TEMPLATE_FOR_CLIENT_SUCCESS =
  "DELETE_TEMPLATE_FOR_CLIENT_SUCCESS";
export const DELETE_TEMPLATE_FOR_CLIENT_FAILURE =
  "DELETE_TEMPLATE_FOR_CLIENT_FAILURE";

export const GET_CLIENT_PROFILE_SUCCESS = "GET_CLIENT_PROFILE_SUCCESS";
export const GET_CLIENT_PROFILE_FAILURE = "GET_CLIENT_PROFILE_FAILURE";

export const UPD_CLIENT_PROFILE_SUCCESS = "UPD_CLIENT_PROFILE_SUCCESS";
export const UPD_CLIENT_PROFILE_FAILURE = "UPD_CLIENT_PROFILE_FAILURE";

export const UPD_CLIENT_ADDRESS_SUCCESS = "UPD_CLIENT_ADDRESS_SUCCESS";
export const UPD_CLIENT_ADDRESS_FAILURE = "UPD_CLIENT_ADDRESS_FAILURE";

export const UPD_CLIENT_EMAIL_SUCCESS = "UPD_CLIENT_EMAIL_SUCCESS";
export const UPD_CLIENT_EMAIL_FAILURE = "UPD_CLIENT_EMAIL_FAILURE";

export const UPD_CLIENT_MEDICAL_SUCCESS = "UPD_CLIENT_MEDICAL_SUCCESS";
export const UPD_CLIENT_MEDICAL_FAILURE = "UPD_CLIENT_MEDICAL_FAILURE";

export const UPD_CLIENT_PHONE_SUCCESS = "UPD_CLIENT_PHONE_SUCCESS";
export const UPD_CLIENT_PHONE_FAILURE = "UPD_CLIENT_PHONE_FAILURE";

export const UPD_CLIENT_PASSPORT_SUCCESS = "UPD_CLIENT_PASSPORT_SUCCESS";
export const UPD_CLIENT_PASSPORT_FAILURE = "UPD_CLIENT_PASSPORT_FAILURE";

export const GET_ALL_CLIENT_SUCCESS = "GET_ALL_CLIENT_SUCCESS";
export const GET_ALL_CLIENT_FAILURE = "GET_ALL_CLIENT_FAILURE";

export const GET_PROCESSING_GROUPS_SUCCESS = "GET_PROCESSING_GROUPS_SUCCESS";
export const GET_PROCESSING_GROUPS_FAILURE = "GET_PROCESSING_GROUPS_FAILURE";

export const GET_GROUP_MEMBER_SUCCESS = "GET_GROUP_MEMBER_SUCCESS";
export const GET_GROUP_MEMBER_FAILURE = "GET_GROUP_MEMBER_FAILURE";

export const ADD_PROCESSING_GROUP_SUCCESS = "ADD_PROCESSING_GROUP_SUCCESS";
export const ADD_PROCESSING_GROUP_FAILURE = "ADD_PROCESSING_GROUP_FAILURE";

export const ADD_PROCESSING_PERSON_SUCCESS = "ADD_PROCESSING_PERSON_SUCCESS";
export const ADD_PROCESSING_PERSON_FAILURE = "ADD_PROCESSING_PERSON_FAILURE";

export const REMOVE_PROCESSING_GROUP_SUCCESS =
  "REMOVE_PROCESSING_GROUP_SUCCESS";
export const REMOVE_PROCESSING_GROUP_FAILURE =
  "REMOVE_PROCESSING_GROUP_FAILURE";

export const REMOVE_PROCESSING_PERSON_SUCCESS =
  "REMOVE_PROCESSING_PERSON_SUCCESS";
export const REMOVE_PROCESSING_PERSON_FAILURE =
  "REMOVE_PROCESSING_PERSON_FAILURE";

export const SEARCH_CONNECTION_SUCCESS = "SEARCH_CONNECTION_SUCCESS";
export const SEARCH_CONNECTION_FAILURE = "SEARCH_CONNECTION_FAILURE";

export const GET_ALL_POLICE_CERTIFICATE_SUCCESS =
  "GET_ALL_POLICE_CERTIFICATE_SUCCESS";
export const GET_ALL_POLICE_CERTIFICATE_FAILURE =
  "GET_ALL_POLICE_CERTIFICATE_FAILURE";

export const ADD_POLICE_CERTIFICATE_SUCCESS = "ADD_POLICE_CERTIFICATE_SUCCESS";
export const ADD_POLICE_CERTIFICATE_FAILURE = "ADD_POLICE_CERTIFICATE_FAILURE";

export const ADD_ACCESSING_AUTHORITIES_SUCCESS =
  "ADD_ACCESSING_AUTHORITIES_SUCCESS";
export const ADD_ACCESSING_AUTHORITIES_FAILURE =
  "ADD_ACCESSING_AUTHORITIES_FAILURE";

export const REMOVE_POLICE_CERTIFICATE_SUCCESS =
  "REMOVE_POLICE_CERTIFICATE_SUCCESS";
export const REMOVE_POLICE_CERTIFICATE_FAILURE =
  "REMOVE_POLICE_CERTIFICATE_FAILURE";

export const REMOVE_ACCESSING_AUTHORITIES_SUCCESS =
  "REMOVE_ACCESSING_AUTHORITIES_SUCCESS";
export const REMOVE_ACCESSING_AUTHORITIES_FAILURE =
  "REMOVE_ACCESSING_AUTHORITIES_FAILURE";

export const ADD_CONNECTION_SUCCESS = "ADD_CONNECTION_SUCCESS";
export const ADD_CONNECTION_FAILURE = "ADD_CONNECTION_FAILURE";

export const REMOVE_CONNECTION_SUCCESS = "REMOVE_CONNECTION_SUCCESS";
export const REMOVE_CONNECTION_FAILURE = "REMOVE_CONNECTION_FAILURE";

export const GET_ACCESSING_AUTH_SUCCESS = "GET_ACCESSING_AUTH_SUCCESS";
export const GET_ACCESSING_AUTH_FAILURE = "GET_ACCESSING_AUTH_FAILURE";

export const GET_CLIENT_FAMILY_SUCCESS = "GET_CLIENT_FAMILY_SUCCESS";
export const GET_CLIENT_FAMILY_FAILURE = "GET_CLIENT_FAMILY_FAILURE";

export const CREATE_CLIENT_LINK_SUCCESS = "CREATE_CLIENT_LINK_SUCCESS";
export const CREATE_CLIENT_LINK_FAILURE = "CREATE_CLIENT_LINK_FAILURE";

export const GET_CLIENT_LINK_SUCCESS = "GET_CLIENT_LINK_SUCCESS";
export const GET_CLIENT_LINK_FAILURE = "GET_CLIENT_LINK_FAILURE";

export const SET_CMV_LINK_TEMPLATE_SUCCESS = "SET_CMV_LINK_TEMPLATE_SUCCESS";
export const SET_CMV_LINK_TEMPLATE_FAILURE = "SET_CMV_LINK_TEMPLATE_FAILURE";

export const GET_MAIN_CLIENT_SUCCESS = "GET_MAIN_CLIENT_SUCCESS";
export const GET_MAIN_CLIENT_FAILURE = "GET_MAIN_CLIENT_FAILURE";

export const UPDATE_SIGNED_DATE_SUCCESS = "UPDATE_SIGNED_DATE_SUCCESS";
export const UPDATE_SIGNED_DATE_FAILURE = "UPDATE_SIGNED_DATE_FAILURE";

export const CHECK_UNIQUE_EMAIL_SUCCESS = "CHECK_UNIQUE_EMAIL_SUCCESS";
export const CHECK_UNIQUE_EMAIL_FAILURE = "CHECK_UNIQUE_EMAIL_FAILURE";

// Get Partner profile

export const GET_PARTNER_PROFILE_SUCCESS = "GET_PARTNER_PROFILE_SUCCESS";
export const GET_PARTNER_PROFILE_FAILURE = "GET_PARTNER_PROFILE_FAILURE";

export const UPD_PARTNER_PROFILE_SUCCESS = "UPD_PARTNER_PROFILE_SUCCESS";
export const UPD_PARTNER_PROFILE_FAILURE = "UPD_PARTNER_PROFILE_FAILURE";

export const ADD_PARTNER_PROFILE_SUCCESS = "ADD_PARTNER_PROFILE_SUCCESS";
export const ADD_PARTNER_PROFILE_FAILURE = "ADD_PARTNER_PROFILE_FAILURE";

// Client Qualification

export const ADD_CLIENT_QUALIFICATION_SUCCESS =
  "ADD_CLIENT_QUALIFICATION_SUCCESS";
export const ADD_CLIENT_QUALIFICATION_FAILURE =
  "ADD_CLIENT_QUALIFICATION_FAILURE";

export const GET_CLIENT_QUALIFICATION_SUCCESS =
  "GET_CLIENT_QUALIFICATION_SUCCESS";
export const GET_CLIENT_QUALIFICATION_FAILURE =
  "GET_CLIENT_QUALIFICATION_FAILURE";

export const UPDATE_CLIENT_QUALIFICATION_SUCCESS =
  "UPDATE_CLIENT_QUALIFICATION_SUCCESS";
export const UPDATE_CLIENT_QUALIFICATION_FAILURE =
  "UPDATE_CLIENT_QUALIFICATION_FAILURE";

// Client File Notes

export const ADD_FILE_NOTES_SUCCESS = "ADD_FILE_NOTES_SUCCESS";
export const ADD_FILE_NOTES_FAILURE = "ADD_FILE_NOTES_FAILURE";

export const GET_FILE_NOTES_SUCCESS = "GET_FILE_NOTES_SUCCESS";
export const GET_FILE_NOTES_FAILURE = "GET_FILE_NOTES_FAILURE";

export const UPDATE_FILE_NOTES_SUCCESS = "UPDATE_FILE_NOTES_SUCCESS";
export const UPDATE_FILE_NOTES_FAILURE = "UPDATE_FILE_NOTES_FAILURE";

export const DELETE_FILE_NOTES_SUCCESS = "DELETE_FILE_NOTES_SUCCESS";
export const DELETE_FILE_NOTES_FAILURE = "DELETE_FILE_NOTES_FAILURE";

export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

// Custom Questionnaire

export const CREATE_QUESTIONNAIRE_SUCCESS = "CREATE_QUESTIONNAIRE_SUCCESS";
export const CREATE_QUESTIONNAIRE_FAILURE = "CREATE_QUESTIONNAIRE_FAILURE";

export const GET_ALL_QUESTIONNAIRE_SUCCESS = "GET_ALL_QUESTIONNAIRE_SUCCESS";
export const GET_ALL_QUESTIONNAIRE_FAILURE = "GET_ALL_QUESTIONNAIRE_FAILURE";

export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_FAILURE = "GET_QUESTIONNAIRE_FAILURE";

export const GET_QUESTIONNAIRE_GROUP_SUCCESS =
  "GET_QUESTIONNAIRE_GROUP_SUCCESS";
export const GET_QUESTIONNAIRE_GROUP_FAILURE =
  "GET_QUESTIONNAIRE_GROUP_FAILURE";

export const GET_GROUPED_QUESTIONNAIRE_SUCCESS =
  "GET_GROUPED_QUESTIONNAIRE_SUCCESS";
export const GET_GROUPED_QUESTIONNAIRE_FAILURE =
  "GET_GROUPED_QUESTIONNAIRE_FAILURE";

export const GET_FILLED_QUESTIONNAIRE_SUCCESS =
  "GET_FILLED_QUESTIONNAIRE_SUCCESS";
export const GET_FILLED_QUESTIONNAIRE_FAILURE =
  "GET_FILLED_QUESTIONNAIRE_FAILURE";

export const GET_ALL_FILLED_QUESTIONNAIRE_SUCCESS =
  "GET_ALL_FILLED_QUESTIONNAIRE_SUCCESS";
export const GET_ALL_FILLED_QUESTIONNAIRE_FAILURE =
  "GET_ALL_FILLED_QUESTIONNAIRE_FAILURE";

export const MERGE_FROM_CLIENT_SUCCESS = "MERGE_FROM_CLIENT_SUCCESS";
export const MERGE_FROM_CLIENT_FAILURE = "MERGE_FROM_CLIENT_FAILURE";

export const UPD_MERGE_FROM_CLIENT_SUCCESS = "UPD_MERGE_FROM_CLIENT_SUCCESS";
export const UPD_MERGE_FROM_CLIENT_FAILURE = "UPD_MERGE_FROM_CLIENT_FAILURE";

export const UPD_MERGE_TO_CLIENT_SUCCESS = "UPD_MERGE_TO_CLIENT_SUCCESS";
export const UPD_MERGE_TO_CLIENT_FAILURE = "UPD_MERGE_TO_CLIENT_FAILURE";

export const MERGE_FROM_POTENTIAL_CLIENT_SUCCESS =
  "MERGE_FROM_POTENTIAL_CLIENT_SUCCESS";
export const MERGE_FROM_POTENTIAL_CLIENT_FAILURE =
  "MERGE_FROM_POTENTIAL_CLIENT_FAILURE";

export const UPD_MERGE_FROM_POTENTIAL_CLIENT_SUCCESS =
  "UPD_MERGE_FROM_POTENTIAL_CLIENT_SUCCESS";
export const UPD_MERGE_FROM_POTENTIAL_CLIENT_FAILURE =
  "UPD_MERGE_FROM_POTENTIAL_CLIENT_FAILURE";

export const UPD_MERGE_TO_POTENTIAL_CLIENT_SUCCESS =
  "UPD_MERGE_TO_POTENTIAL_CLIENT_SUCCESS";
export const UPD_MERGE_TO_POTENTIAL_CLIENT_FAILURE =
  "UPD_MERGE_TO_POTENTIAL_CLIENT_FAILURE";

// GET CLIETN TAG
export const GET_CLIENT_TAG_SUCCESS = "GET_CLIENT_TAG_SUCCESS";
export const GET_CLIENT_TAG_FAILURE = "GET_CLIENT_TAG_FAILURE";

// ADD CLIENT TAG
export const ADD_CLIENT_TAG_SUCCESS = "ADD_CLIENT_TAG_SUCCESS";
export const ADD_CLIENT_TAG_FAILURE = "ADD_CLIENT_TAG_FAILURE";

// DELETE CLIENT TAG
export const DELETE_CLIENT_TAG_SUCCESS = "DELETE_CLIENT_TAG_SUCCESS";
export const DELETE_CLIENT_TAG_FAILURE = "DELETE_CLIENT_TAG_FAILURE";

// PUT CLIENT TAG
export const PUT_CLIENT_TAG_SUCCESS = "PUT_CLIENT_TAG_SUCCESS";
export const PUT_CLIENT_TAG_FAILURE = "PUT_CLIENT_TAG_FAILURE";

export const SUBMIT_QUESTIONNAIRE_SUCCESS = "SUBMIT_QUESTIONNAIRE_SUCCESS";
export const SUBMIT_QUESTIONNAIRE_FAILURE = "SUBMIT_QUESTIONNAIRE_FAILURE";

export const UPDATE_SUBMIT_QUESTIONNAIRE_SUCCESS =
  "UPDATE_SUBMIT_QUESTIONNAIRE_SUCCESS";
export const UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE =
  "UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE";

export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS";
export const UPDATE_QUESTIONNAIRE_FAILURE = "UPDATE_QUESTIONNAIRE_FAILURE";

export const GET_MAPPING_PARENT_SUCCESS = "GET_MAPPING_PARENT_SUCCESS";
export const GET_MAPPING_PARENT_FAILURE = "GET_MAPPING_PARENT_FAILURE";

export const GET_MAPPING_CHILD_SUCCESS = "GET_MAPPING_CHILD_SUCCESS";
export const GET_MAPPING_CHILD_FAILURE = "GET_MAPPING_CHILD_FAILURE";

//PROFILE CLIENT TAG:

// GET
export const GET_PROFILE_CLIENT_TAG_SUCCESS = "GET_PROFILE_CLIENT_TAG_SUCCESS";
export const GET_PROFILE_CLIENT_TAG_FAILURE = "GET_PROFILE_CLIENT_TAG_FAILURE";

// POST
export const ADD_PROFILE_CLIENT_TAG_SUCCESS = "ADD_PROFILE_CLIENT_TAG_SUCCESS";
export const ADD_PROFILE_CLIENT_TAG_FAILURE = "ADD_PROFILE_CLIENT_TAG_FAILURE";

// DELETE
export const DELETE_PROFILE_CLIENT_TAG_SUCCESS =
  "DELETE_PROFILE_CLIENT_TAG_SUCCESS";
export const DELETE_PROFILE_CLIENT_TAG_FAILURE =
  "DELETE_PROFILE_CLIENT_TAG_FAILURE";

//REMINDERS

// GET
export const GET_REMINDER_SUCCESS = "GET_REMINDER_SUCCESS";
export const GET_REMINDER_FAILURE = "GET_REMINDER_FAILURE";

// POST
export const ADD_REMINDER_TASK_SUCCESS = "ADD_REMINDER_TASK_SUCCESS";
export const ADD_REMINDER_TASK_FAILURE = "ADD_REMINDER_TASK_FAILURE";

export const ADD_TASK_FILE_NOTE_SUCCESS = "ADD_TASK_FILE_NOTE_SUCCESS";
export const ADD_TASK_FILE_NOTE_FAILURE = "ADD_TASK_FILE_NOTE_FAILURE";

//DAILY TASKS
//GET
export const GET_DAILY_TASKS_SUCCESS = "GET_DAILY_TASKS_SUCCESS";
export const GET_DAILY_TASKS_FAILURE = "GET_DAILY_TASKS_FAILURE";

export const GET_TASK_FOLLOWERS_SUCCESS = "GET_TASK_FOLLOWERS_SUCCESS";
export const GET_TASK_FOLLOWERS_FAILURE = "GET_TASK_FOLLOWERS_FAILURE";

export const GET_CLIENT_TASK_SUCCESS = "GET_CLIENT_TASK_SUCCESS";
export const GET_CLIENT_TASK_FAILURE = "GET_CLIENT_TASK_FAILURE";

export const GET_POTENTIAL_TASK_SUCCESS = "GET_POTENTIAL_TASK_SUCCESS";
export const GET_POTENTIAL_TASK_FAILURE = "GET_POTENTIAL_TASK_FAILURE";

export const GET_TASK_COMPLETED_SUCCESS = "GET_TASK_COMPLETED_SUCCESS";
export const GET_TASK_COMPLETED_FAILURE = "GET_TASK_COMPLETED_FAILURE";

//POST
export const ADD_DAILY_TASKS_SUCCESS = "ADD_DAILY_TASKS_SUCCESS";
export const ADD_DAILY_TASKS_FAILURE = "ADD_DAILY_TASKS_FAILURE";

export const ADD_TASK_FOLLOWER_SUCCESS = "ADD_TASK_FOLLOWER_SUCCESS";
export const ADD_TASK_FOLLOWER_FAILURE = "ADD_TASK_FOLLOWER_FAILURE";

//DELETE
export const DELETE_TASKS_SUCCESS = "DELETE_TASKS_SUCCESS";
export const DELETE_TASKS_FAILURE = "DELETE_TASKS_FAILURE";

export const DELETE_FOLLOWER_SUCCESS = "DELETE_FOLLOWER_SUCCESS";
export const DELETE_FOLLOWER_FAILURE = "DELETE_FOLLOWER_FAILURE";

export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

//PUT
export const PUT_TASK_SUCCESS = "PUT_TASK_SUCCESS";
export const PUT_TASK_FAILURE = "PUT_TASK_FAILURE";

//PUT
export const PUT_COMPLETED_TASK_SUCCESS = "PUT_COMPLETED_TASK_SUCCESS";
export const PUT_COMPLETED_TASK_FAILURE = "PUT_COMPLETED_TASK_FAILURE";

//TASK COMMENTS
export const GET_TASK_COMMENTS_SUCCESS = "GET_TASK_COMMENTS_SUCCESS";
export const GET_TASK_COMMENTS_FAILURE = "GET_TASK_COMMENTS_FAILURE";

export const ADD_TASK_COMMENTS_SUCCESS = "ADD_TASK_COMMENT_SUCCESS";
export const ADD_TASK_COMMENTS_FAILURE = "ADD_TASK_COMMENT_FAILURE";

// REMINDER SETTINGS //

//POST
export const ADD_REMINDER_SETTINGS_SUCCESS = "ADD_REMINDER_SETTINGS_SUCCESS";
export const ADD_REMINDER_SETTINGS_FAILURE = "ADD_REMINDER_SETTINGS_FAILURE";

//GET
export const GET_REMINDER_SETTINGS_SUCCESS = "GET_REMINDER_SETTINGS_SUCCESS";
export const GET_REMINDER_SETTINGS_FAILURE = "GET_REMINDER_SETTINGS_FAILURE";

// EMAIL CONTENT //

//GET
export const GET_EMAIL_CONTENT_SUCCESS = "GET_EMAIL_CONTENT_SUCCESS";
export const GET_EMAIL_CONTENT_FAILURE = "GET_EMAIL_CONTENT_FAILURE";

export const GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS =
  "GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS";
export const GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE =
  "GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE";

//POST
export const ADD_EMAIL_CONTENT_SUCCESS = "ADD_EMAIL_CONTENT_SUCCESS";
export const ADD_EMAIL_CONTENT_FAILURE = "ADD_EMAIL_CONTENT_FAILURE";

//PUT
export const PUT_EMAIL_CONTENT_SUCCESS = "PUT_EMAIL_CONTENT_SUCCESS";
export const PUT_EMAIL_CONTENT_FAILURE = "PUT_EMAIL_CONTENT_FAILURE";

//DEL
export const DELETE_EMAIL_CONTENT_SUCCESS = "DELETE_EMAIL_CONTENT_SUCCESS";
export const DELETE_EMAIL_CONTENT_FAILURE = "DELETE_EMAIL_CONTENT_FAILURE";

export const UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS =
  "UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS";
export const UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE =
  "UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE";

// DOCUMENT TYPE //

//GET
export const GET_DOCUMENT_TYPE_SUCCESS = "GET_DOCUMENT_TYPE_SUCCESS";
export const GET_DOCUMENT_TYPE_FAILURE = "GET_DOCUMENT_TYPE_FAILURE";

export const GET_DOCUMENT_PREVIEW_SUCCESS = "GET_DOCUMENT_PREVIEW_SUCCESS";
export const GET_DOCUMENT_PREVIEW_FAILURE = "GET_DOCUMENT_PREVIEW_FAILURE";

//POST
export const ADD_DOCUMENT_TYPE_SUCCESS = "ADD_DOCUMENT_TYPE_SUCCESS";
export const ADD_DOCUMENT_TYPE_FAILURE = "ADD_DOCUMENT_TYPE_FAILURE";

//PUT
export const PUT_DOCUMENT_TYPE_SUCCESS = "PUT_DOCUMENT_TYPE_SUCCESS";
export const PUT_DOCUMENT_TYPE_FAILURE = "PUT_DOCUMENT_TYPE_FAILURE";

//DELETE
export const DELETE_DOCUMENT_TYPE_SUCCESS = "DELETE_DOCUMENT_TYPE_SUCCESS";
export const DELETE_DOCUMENT_TYPE_FAILURE = "DELETE_DOCUMENT_TYPE_FAILURE";

// DOCUMENT TYPE //

//GET
export const GET_MAIL_CHIMP_SUCCESS = "GET_MAIL_CHIMP_SUCCESS";
export const GET_MAIL_CHIMP_FAILURE = "GET_MAIL_CHIMP_FAILURE";

//POST
export const ADD_MAIL_CHIMP_SUCCESS = "ADD_MAIL_CHIMP_SUCCESS";
export const ADD_MAIL_CHIMP_FAILURE = "ADD_MAIL_CHIMP_FAILURE";

// DOCUMENT TYPE //

//GET
export const GET_LETTER_TEMPLATE_SUCCESS = "GET_LETTER_TEMPLATE_SUCCESS";
export const GET_LETTER_TEMPLATE_FAILURE = "GET_LETTER_TEMPLATE_FAILURE";

//POST
export const ADD_LETTER_TEMPLATE_SUCCESS = "ADD_LETTER_TEMPLATE_SUCCESS";
export const ADD_LETTER_TEMPLATE_FAILURE = "ADD_LETTER_TEMPLATE_FAILURE";

//DELETE
export const DELETE_LETTER_TEMPLATE_SUCCESS = "DELETE_LETTER_TEMPLATE_SUCCESS";
export const DELETE_LETTER_TEMPLATE_FAILURE = "DELETE_LETTER_TEMPLATE_FAILURE";

//PUT
export const PUT_LETTER_TEMPLATE_SUCCESS = "PUT_LETTER_TEMPLATE_SUCCESS";
export const PUT_LETTER_TEMPLATE_FAILURE = "PUT_LETTER_TEMPLATE_FAILURE";

//POST FOR SORTING
export const SORT_LETTER_TEMPLATE_SUCCESS = "SORT_LETTER_TEMPLATE_SUCCESS";
export const SORT_LETTER_TEMPLATE_FAILURE = "SORT_LETTER_TEMPLATE_FAILURE";

//ADD CONTRACT EMAIL
export const ADD_CONTRACT_EMAIL_SUCCESS = "ADD_CONTRACT_EMAIL_SUCCESS";
export const ADD_CONTRACT_EMAIL_FAILURE = "ADD_CONTRACT_EMAIL_FAILURE";

//GET CONTRACT EMAIL
export const GET_CONTRACT_EMAIL_SUCCESS = "GET_CONTRACT_EMAIL_SUCCESS";
export const GET_CONTRACT_EMAIL_FAILURE = "GET_CONTRACT_EMAIL_FAILURE";

// DOCUMENTS //

//GET
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";

//POST
export const ADD_DOCUMENTS_SUCCESS = "ADD_DOCUMENTS_SUCCESS";
export const ADD_DOCUMENTS_FAILURE = "ADD_DOCUMENTS_FAILURE";

//DELETE
export const DELETE_DOCUMENTS_SUCCESS = "DELETE_DOCUMENTS_SUCCESS";
export const DELETE_DOCUMENTS_FAILURE = "DELETE_DOCUMENTS_FAILURE";

// TIME TRACKING //

//GET
export const GET_TIME_TRACKINGS_SUCCESS = "GET_TIME_TRACKINGS_SUCCESS";
export const GET_TIME_TRACKINGS_FAILURE = "GET_TIME_TRACKINGS_FAILURE";

export const GET_TIME_TRACKINGS_POPUP_SUCCESS =
  "GET_TIME_TRACKINGS_POPUP_SUCCESS";
export const GET_TIME_TRACKINGS_POPUP_FAILURE =
  "GET_TIME_TRACKINGS_POPUP_FAILURE";

//POST
export const ADD_TIME_TRACKINGS_SUCCESS = "ADD_TIME_TRACKINGS_SUCCESS";
export const ADD_TIME_TRACKINGS_FAILURE = "ADD_TIME_TRACKINGS_FAILURE";

// VISA UPATE NOTIFICATIO

// PUT
export const GET_UPDATE_NOTIFICATION_SUCCESS =
  "GET_UPDATE_NOTIFICATION_SUCCESS";
export const GET_UPDATE_NOTIFICATION_FAILURE =
  "GET_UPDATE_NOTIFICATION_FAILURE";
export const PUT_UPDATE_NOTIFICATION_SUCCESS =
  "PUT_UPDATE_NOTIFICATION_SUCCESS";
export const PUT_UPDATE_NOTIFICATION_FAILURE =
  "PUT_UPDATE_NOTIFICATION_FAILURE";

// SIGNATURE

//GET
export const GET_SIGNATURE_SUCCESS = "GET_SIGNATURE_SUCCESS";
export const GET_SIGNATURE_FAILURE = "GET_SIGNATURE_FAILURE";

//PUT
export const PUT_SIGNATURE_SUCCESS = "PUT_SIGNATURE_SUCCESS";
export const PUT_SIGNATURE_FAILURE = "PUT_SIGNATURE_FAILURE";

// SIGNATURE

//GET
export const GET_DETAULT_FONT_SUCCESS = "GET_DETAULT_FONT_SUCCESS";
export const GET_DETAULT_FONT_FAILURE = "GET_DETAULT_FONT_FAILURE";

//PUT
export const PUT_DETAULT_FONT_SUCCESS = "PUT_DETAULT_FONT_SUCCESS";
export const PUT_DETAULT_FONT_FAILURE = "PUT_DETAULT_FONT_FAILURE";

// TIME ZONE

//GET
export const GET_TIME_ZONE_SUCCESS = "GET_TIME_ZONE_SUCCESS";
export const GET_TIME_ZONE_FAILURE = "GET_TIME_ZONE_FAILURE";

//PUT
export const PUT_TIME_ZONE_SUCCESS = "PUT_TIME_ZONE_SUCCESS";
export const PUT_TIME_ZONE_FAILURE = "PUT_TIME_ZONE_FAILURE";

// EMAIL SETTINGS

//GET
export const GET_EMAIL_SETTINGS_SUCCESS = "GET_EMAIL_SETTINGS_SUCCESS";
export const GET_EMAIL_SETTINGS_FAILURE = "GET_EMAIL_SETTINGS_FAILURE";

//PUT
export const PUT_EMAIL_SETTINGS_SUCCESS = "PUT_EMAIL_SETTINGS_SUCCESS";
export const PUT_EMAIL_SETTINGS_FAILURE = "PUT_EMAIL_SETTINGS_FAILURE";

// QUESTIONNAIRE SETTINGS

//GET
export const GET_QUESTIONNAIRE_SETTING_SUCCESS =
  "GET_QUESTIONNAIRE_SETTING_SUCCESS";
export const GET_QUESTIONNAIRE_SETTING_FAILURE =
  "GET_QUESTIONNAIRE_SETTING_FAILURE";

//PUT
export const PUT_QUESTIONNAIRE_SETTING_SUCCESS =
  "PUT_QUESTIONNAIRE_SETTING_SUCCESS";
export const PUT_QUESTIONNAIRE_SETTING_FAILURE =
  "PUT_QUESTIONNAIRE_SETTING_FAILURE";

// DOCUMENT VIEW

//GET
export const GET_DOCUMENT_VIEW_SUCCESS = "GET_DOCUMENT_VIEW_SUCCESS";
export const GET_DOCUMENT_VIEW_FAILURE = "GET_DOCUMENT_VIEW_FAILURE";

//PUT
export const ADD_DOCUMENT_VIEW_SUCCESS = "ADD_DOCUMENT_VIEW_SUCCESS";
export const ADD_DOCUMENT_VIEW_FAILURE = "ADD_DOCUMENT_VIEW_FAILURE";
export const DUPLICATE_QUESTIONNAIRE_SUCCESS =
  "DUPLICATE_QUESTIONNAIRE_SUCCESS";
export const DUPLICATE_QUESTIONNAIRE_FAILURE =
  "DUPLICATE_QUESTIONNAIRE_FAILURE";

export const GET_EMAIL_IMPORT_CC_SUCCESS = "GET_EMAIL_IMPORT_CC_SUCCESS";
export const GET_EMAIL_IMPORT_CC_FAILURE = "GET_EMAIL_IMPORT_CC_FAILURE";

export const ADD_EMAIL_IMPORT_CC_SUCCESS = "ADD_EMAIL_IMPORT_CC_SUCCESS";
export const ADD_EMAIL_IMPORT_CC_FAILURE = "ADD_EMAIL_IMPORT_CC_FAILURE";

export const DYNAMIC_LINK_SUCCESS = "DYNAMIC_LINK_SUCCESS";
export const DYNAMIC_LINK_FAILURE = "DYNAMIC_LINK_FAILURE";

export const GET_DYNAMIC_LINK_SUCCESS = "GET_DYNAMIC_LINK_SUCCESS";
export const GET_DYNAMIC_LINK_FAILURE = "GET_DYNAMIC_LINK_FAILURE";

export const SEND_DYNAMIC_LINK_SUCCESS = "SEND_DYNAMIC_LINK_SUCCESS";
export const SEND_DYNAMIC_LINK_FAILURE = "SEND_DYNAMIC_LINK_FAILURE";

export const SEND_DYNAMIC_LINK_BLOB_SUCCESS = "SEND_DYNAMIC_LINK_BLOB_SUCCESS";
export const SEND_DYNAMIC_LINK_BLOB_FAILURE = "SEND_DYNAMIC_LINK_BLOB_FAILURE";

export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_FAILURE = "GET_EMAIL_TEMPLATE_FAILURE";

export const SET_EMAIL_TEMPLATE_SUCCESS = "SET_EMAIL_TEMPLATE_SUCCESS";
export const SET_EMAIL_TEMPLATE_FAILURE = "SET_EMAIL_TEMPLATE_FAILURE";

// EMPLOYER MANAGEMENT

export const GET_EMPLOYER_MANAG_SUCCESS = "GET_EMPLOYER_MANAG_SUCCESS";
export const GET_EMPLOYER_MANAG_FAILURE = "GET_EMPLOYER_MANAG_FAILURE";

export const ADD_EMPLOYER_MANAG_SUCCESS = "ADD_EMPLOYER_MANAG_SUCCESS";
export const ADD_EMPLOYER_MANAG_FAILURE = "ADD_EMPLOYER_MANAG_FAILURE";

export const DELETE_EMPLOYER_MANAG_SUCCESS = "DELETE_EMPLOYER_MANAG_SUCCESS";
export const DELETE_EMPLOYER_MANAG_FAILURE = "DELETE_EMPLOYER_MANAG_FAILURE";

export const GET_EMPLOYER_CONTACTS_SUCCESS = "GET_EMPLOYER_CONTACTS_SUCCESS";
export const GET_EMPLOYER_CONTACTS_FAILURE = "GET_EMPLOYER_CONTACTS_FAILURE";

export const ADD_EMPLOYER_CONTACTS_SUCCESS = "ADD_EMPLOYER_CONTACTS_SUCCESS";
export const ADD_EMPLOYER_CONTACTS_FAILURE = "ADD_EMPLOYER_CONTACTS_FAILURE";

export const GET_EMPLOYER_JOB_SUCCESS = "GET_EMPLOYER_JOB_SUCCESS";
export const GET_EMPLOYER_JOB_FAILURE = "GET_EMPLOYER_JOB_FAILURE";

export const ADD_EMPLOYER_JOB_SUCCESS = "ADD_EMPLOYER_JOB_SUCCESS";
export const ADD_EMPLOYER_JOB_FAILURE = "ADD_EMPLOYER_JOB_FAILURE";

// FILE NOTES

export const GET_EMPLOYER_FILE_SUCCESS = "GET_EMPLOYER_FILE_SUCCESS";
export const GET_EMPLOYER_FILE_FAILURE = "GET_EMPLOYER_FILE_FAILURE";

export const ADD_EMPLOYER_FILE_SUCCESS = "ADD_EMPLOYER_FILE_SUCCESS";
export const ADD_EMPLOYER_FILE_FAILURE = "ADD_EMPLOYER_FILE_FAILURE";

export const ADD_EMPLOYER_SEARCH_SUCCESS = "ADD_EMPLOYER_SEARCH_SUCCESS";
export const ADD_EMPLOYER_SEARCH_FAILURE = "ADD_EMPLOYER_SEARCH_FAILURE";

export const GET_SINGLE_EMPLOYER_SUCCESS = "GET_SINGLE_EMPLOYER_SUCCESS";
export const GET_SINGLE_EMPLOYER_FAILURE = "GET_SINGLE_EMPLOYER_FAILURE";

export const DELETE_EMPLOYER_CONTACT_SUCCESS =
  "DELETE_EMPLOYER_CONTACT_SUCCESS";
export const DELETE_EMPLOYER_CONTACT_FAILURE =
  "DELETE_EMPLOYER_CONTACT_FAILURE";

export const DELETE_EMPLOYER_FILE_SUCCESS = "DELETE_EMPLOYER_FILE_SUCCESS";
export const DELETE_EMPLOYER_FILE_FAILURE = "DELETE_EMPLOYER_FILE_FAILURE";

export const UPDATE_EMPLOYER_MANAG_SUCCESS = "UPDATE_EMPLOYER_MANAG_SUCCESS";
export const UPDATE_EMPLOYER_MANAG_FAILURE = "UPDATE_EMPLOYER_MANAG_FAILURE";

export const UPDATE_EMPLOYER_FILE_SUCCESS = "UPDATE_EMPLOYER_FILE_SUCCESS";
export const UPDATE_EMPLOYER_FILE_FAILURE = "UPDATE_EMPLOYER_FILE_FAILURE";

export const UPDATE_EMPLOYER_CONTACTS_SUCCESS =
  "UPDATE_EMPLOYER_CONTACTS_SUCCESS";
export const UPDATE_EMPLOYER_CONTACTS_FAILURE =
  "UPDATE_EMPLOYER_CONTACTS_FAILURE";

export const GET_DOCUMENT_EMPLOYER_SUCCESS = "GET_DOCUMENT_EMPLOYER_SUCCESS";
export const GET_DOCUMENT_EMPLOYER_FAILURE = "GET_DOCUMENT_EMPLOYER_FAILURE";

export const ADD_DOCUMENT_EMPLOYER_SUCCESS = "ADD_DOCUMENT_EMPLOYER_SUCCESS";
export const ADD_DOCUMENT_EMPLOYER_FAILURE = "ADD_DOCUMENT_EMPLOYER_FAILURE";

export const DELETE_DOCUMENT_EMPLOYER_SUCCESS =
  "DELETE_DOCUMENT_EMPLOYER_SUCCESS";
export const DELETE_DOCUMENT_EMPLOYER_FAILURE =
  "DELETE_DOCUMENT_EMPLOYER_FAILURE";
// CLIENT VISA

export const START_NEW_APPLICATION_SUCCESS = "START_NEW_APPLICATION_SUCCESS";
export const START_NEW_APPLICATION_FAILURE = "START_NEW_APPLICATION_FAILURE";

export const GET_VISA_APPLICATION_SUCCESS = "GET_VISA_APPLICATION_SUCCESS";
export const GET_VISA_APPLICATION_FAILURE = "GET_VISA_APPLICATION_FAILURE";

export const GET_FAMILY_MEMBERS_SUCCESS = "GET_FAMILY_MEMBERS_SUCCESS";
export const GET_FAMILY_MEMBERS_FAILURE = "GET_FAMILY_MEMBERS_FAILURE";

export const GET_CLIENT_DOCUMENTS_SUCCESS = "GET_CLIENT_DOCUMENTS_SUCCESS";
export const GET_CLIENT_DOCUMENTS_FAILURE = "GET_CLIENT_DOCUMENTS_FAILURE";

export const GET_CLIENT_EMAIL_SUCCESS = "GET_CLIENT_EMAIL_SUCCESS";
export const GET_CLIENT_EMAIL_FAILURE = "GET_CLIENT_EMAIL_FAILURE";

export const GET_CLIENT_FILE_NOTES_SUCCESS = "GET_CLIENT_FILE_NOTES_SUCCESS";
export const GET_CLIENT_FILE_NOTES_FAILURE = "GET_CLIENT_FILE_NOTES_FAILURE";

export const ADD_FAMILY_LINK_SUCCESS = "ADD_FAMILY_LINK_SUCCESS";
export const ADD_FAMILY_LINK_FAILURE = "ADD_FAMILY_LINK_FAILURE";

export const REMOVE_FAMILY_LINK_SUCCESS = "REMOVE_FAMILY_LINK_SUCCESS";
export const REMOVE_FAMILY_LINK_FAILURE = "REMOVE_FAMILY_LINK_FAILURE";

export const UPDATE_SUBJECT_CASE_SUCCESS = "UPDATE_SUBJECT_CASE_SUCCESS";
export const UPDATE_SUBJECT_CASE_FAILURE = "UPDATE_SUBJECT_CASE_FAILURE";

export const UPDATE_CASE_STATUS_SUCCESS = "UPDATE_CASE_STATUS_SUCCESS";
export const UPDATE_CASE_STATUS_FAILURE = "UPDATE_CASE_STATUS_FAILURE";

export const DELETE_CASE_STATUS_SUCCESS = "DELETE_CASE_STATUS_SUCCESS";
export const DELETE_CASE_STATUS_FAILURE = "DELETE_CASE_STATUS_FAILURE";

export const DELETE_SUBJECT_CASE_SUCCESS = "DELETE_SUBJECT_CASE_SUCCESS";
export const DELETE_SUBJECT_CASE_FAILURE = "DELETE_SUBJECT_CASE_FAILURE";

export const GET_CASE_DOCUMENTS_SUCCESS = "GET_CASE_DOCUMENTS_SUCCESS";
export const GET_CASE_DOCUMENTS_FAILURE = "GET_CASE_DOCUMENTS_FAILURE";

export const UPDATE_CASE_HISTORY_SUCCESS = "UPDATE_CASE_HISTORY_SUCCESS";
export const UPDATE_CASE_HISTORY_FAILURE = "UPDATE_CASE_HISTORY_FAILURE";

export const GET_VISA_TYPE_BY_COUNTRY_SUCCESS =
  "GET_VISA_TYPE_BY_COUNTRY_SUCCESS";
export const GET_VISA_TYPE_BY_COUNTRY_FAILURE =
  "GET_VISA_TYPE_BY_COUNTRY_FAILURE";

export const GET_VISA_CATEGORY_SUCCESS = "GET_VISA_CATEGORY_SUCCESS";
export const GET_VISA_CATEGORY_FAILURE = "GET_VISA_CATEGORY_FAILURE";

export const GET_VISA_FILE_NOTES_SUCCESS = "GET_VISA_FILE_NOTES_SUCCESS";
export const GET_VISA_FILE_NOTES_FAILURE = "GET_VISA_FILE_NOTES_FAILURE";

export const GET_FILE_NOTES_PDF_SUCCESS = "GET_FILE_NOTES_PDF_SUCCESS";
export const GET_FILE_NOTES_PDF_FAILURE = "GET_FILE_NOTES_PDF_FAILURE";

// STUDENT LISTING

export const GET_STUDENT_LISTING_SUCCESS = "GET_STUDENT_LISTING_SUCCESS";
export const GET_STUDENT_LISTING_FAILURE = "GET_STUDENT_LISTING_FAILURE";

// SCHOOLS

export const GET_SCHOOL_LISTING_SUCCESS = "GET_SCHOOL_LISTING_SUCCESS";
export const GET_SCHOOL_LISTING_FAILURE = "GET_SCHOOL_LISTING_FAILURE";

export const GET_STATUSES_SUCCESS = "GET_STATUSES_SUCCESS";
export const GET_STATUSES_FAILURE = "GET_STATUSES_FAILURE";

export const SET_SCHOOL_INVOICES_DATA_NULL_SUCCESS =
  "SET_SCHOOL_INVOICES_DATA_NULL_SUCCESS";

export const GET_SCHOOL_LISTING_FOR_TYPE_SUCCESS =
  "GET_SCHOOL_LISTING_FOR_TYPE_SUCCESS";
export const GET_SCHOOL_LISTING_FOR_TYPE_FAILURE =
  "GET_SCHOOL_LISTING_FOR_TYPE_FAILURE";

export const GET_SCHOOL_TYPE_LISTING_SUCCESS =
  "GET_SCHOOL_TYPE_LISTING_SUCCESS";
export const GET_SCHOOL_TYPE_LISTING_FAILURE =
  "GET_SCHOOL_TYPE_LISTING_FAILURE";

export const GET_SCHOOL_INVOICE_PDF_HTML_BLOB_SUCCESS =
  "GET_SCHOOL_INVOICE_PDF_HTML_BLOB_SUCCESS";
export const GET_SCHOOL_INVOICE_PDF_HTML_BLOB_FAILURE =
  "GET_SCHOOL_INVOICE_PDF_HTML_BLOB_FAILURE";

export const DELETE_SCHOOL_SUCCESS = "GET_SCHOOL_LISTING_FOR_TYPE_SUCCESS";
export const DELETE_SCHOOL_FAILURE = "GET_SCHOOL_LISTING_FOR_TYPE_FAILURE";

export const ADD_SCHOOL_SUCCESS = "ADD_SCHOOL_SUCCESS";
export const ADD_SCHOOL_FAILURE = "ADD_SCHOOL_FAILURE";

export const ADD_SCHOOL_INVOICE_SUCCESS = "ADD_SCHOOL_INVOICE_SUCCESS";
export const ADD_SCHOOL_INVOICE_FAILURE = "ADD_SCHOOL_INVOICE_FAILURE";

export const EDIT_SCHOOL_INVOICE_SUCCESS = "EDIT_SCHOOL_INVOICE_SUCCESS";
export const EDIT_SCHOOL_INVOICE_FAILURE = "EDIT_SCHOOL_INVOICE_FAILURE";

export const GET_SCHOOL_INVOICE_INFO_SUCCESS =
  "GET_SCHOOL_INVOICE_INFO_SUCCESS";
export const GET_SCHOOL_INVOICE_INFO_FAILURE =
  "GET_SCHOOL_INVOICE_INFO_FAILURE";

export const GET_SCHOOL_INVOICES_SUCCESS = "GET_SCHOOL_INVOICES_SUCCESS";
export const GET_SCHOOL_INVOICES_FAILURE = "GET_SCHOOL_INVOICES_FAILURE";

export const EDIT_SCHOOL_SUCCESS = "EDIT_SCHOOL_SUCCESS";
export const EDIT_SCHOOL_FAILURE = "EDIT_SCHOOL_FAILURE";

export const GET_SCHOOL_INFO_SUCCESS = "GET_SCHOOL_INFO_SUCCESS";
export const GET_SCHOOL_INFO_FAILURE = "GET_SCHOOL_INFO_FAILURE";

export const SEARCH_SCHOOL_SUCCESS = "SEARCH_SCHOOL_SUCCESS";
export const SEARCH_SCHOOL_FAILURE = "SEARCH_SCHOOL_FAILURE";

export const SEARCH_STUDENT_SUCCESS = "SEARCH_STUDENT_SUCCESS";
export const SEARCH_STUDENT_FAILURE = "SEARCH_STUDENT_FAILURE";

// CONTACT & LEVEL

export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";

export const DELETE_LEVEL_SUCCESS = "DELETE_LEVEL_SUCCESS";
export const DELETE_LEVEL_FAILURE = "DELETE_LEVEL_FAILURE";

// COMMISSION REMINDERS
export const GET_COMMISSION_REMINDER_LISTING_SUCCESS =
  "GET_COMMISSION_REMINDER_LISTING_SUCCESS";
export const GET_COMMISSION_REMINDER_LISTING_FAILURE =
  "GET_COMMISSION_REMINDER_LISTING_FAILURE";

export const EDIT_COMMISSION_REMINDER_SUCCESS =
  "EDIT_COMMISSION_REMINDER_SUCCESS";
export const EDIT_COMMISSION_REMINDER_FAILURE =
  "EDIT_COMMISSION_REMINDER_FAILURE";

export const REMOVE_COMMISSION_REMINDER_SUCCESS =
  "REMOVE_COMMISSION_REMINDER_SUCCESS";
export const REMOVE_COMMISSION_REMINDER_FAILURE =
  "REMOVE_COMMISSION_REMINDER_FAILURE";

// Technical Support
export const CREATE_TECHNICAL_COMPLAINT_SUCCESS =
  "CREATE_TECHNICAL_COMPLAINT_SUCCESS";
export const CREATE_TECHNICAL_COMPLAINTE_FAILURE =
  "CREATE_TECHNICAL_COMPLAINTE_FAILURE";

export const UPLOAD_SUPPORT_FILE_SUCCESS = "UPLOAD_SUPPORT_FILE_SUCCESS";
export const UPLOAD_SUPPORT_FILE_FAILURE = "UPLOAD_SUPPORT_FILE_FAILURE";

export const UPDATE_DOCUMENT_EMPLOYER_SUCCESS =
  "UPDATE_DOCUMENT_EMPLOYER_SUCCESS";
export const UPDATE_DOCUMENT_EMPLOYER_FAILURE =
  "UPDATE_DOCUMENT_EMPLOYER_FAILURE";
// Client Admission

export const CLIENT_ADMISSION_SUCCESS = "CLIENT_ADMISSION_SUCCESS";
export const CLIENT_ADMISSION_FAILURE = "CLIENT_ADMISSION_FAILURE";

export const GET_SCHOOL_TYPES_SUCCESS = "GET_SCHOOL_TYPES_SUCCESS";
export const GET_SCHOOL_TYPES_FAILURE = "GET_SCHOOL_TYPES_FAILURE";

export const GET_ADMISSION_PROGRAM_SUCCESS = "GET_ADMISSION_PROGRAM_SUCCESS";
export const GET_ADMISSION_PROGRAM_FAILURE = "GET_ADMISSION_PROGRAM_FAILURE";

export const DELETE_ADMISSION_PROGRAM_SUCCESS =
  "DELETE_ADMISSION_PROGRAM_SUCCESS";
export const DELETE_ADMISSION_PROGRAM_FAILURE =
  "DELETE_ADMISSION_PROGRAM_FAILURE";

export const GET_CLIENT_ADMISSION_PROGRAM_SUCCESS =
  "GET_CLIENT_ADMISSION_PROGRAM_SUCCESS";
export const GET_CLIENT_ADMISSION_PROGRAM_FAILURE =
  "GET_CLIENT_ADMISSION_PROGRAM_FAILURE";

// Client Employer
export const GET_CLIENT_EMPLOYER_SUCCESS = "GET_CLIENT_EMPLOYER_SUCCESS";
export const GET_CLIENT_EMPLOYER_FAILURE = "GET_CLIENT_EMPLOYER_FAILURE";

export const ADD_CLIENT_EMPLOYER_SUCCESS = "ADD_CLIENT_EMPLOYER_SUCCESS";
export const ADD_CLIENT_EMPLOYER_FAILURE = "ADD_CLIENT_EMPLOYER_FAILURE";

export const UPDATE_CLIENT_EMPLOYER_SUCCESS = "UPDATE_CLIENT_EMPLOYER_SUCCESS";
export const UPDATE_CLIENT_EMPLOYER_FAILURE = "UPDATE_CLIENT_EMPLOYER_FAILURE";

export const DELETE_CLIENT_EMPLOYER_SUCCESS = "DELETE_CLIENT_EMPLOYER_SUCCESS";
export const DELETE_CLIENT_EMPLOYER_FAILURE = "DELETE_CLIENT_EMPLOYER_FAILURE";

export const LINK_EMPLOYER_SUCCESS = "LINK_EMPLOYER_SUCCESS";
export const LINK_EMPLOYER_FAILURE = "LINK_EMPLOYER_FAILURE";

// Client employer job history

export const GET_EMPLOYER_JOB_HISTORY_SUCCESS =
  "GET_EMPLOYER_JOB_HISTORY_SUCCESS";
export const GET_EMPLOYER_JOB_HISTORY_FAILURE =
  "GET_EMPLOYER_JOB_HISTORY_FAILURE";

export const ADD_EMPLOYER_JOB_HISTORY_SUCCESS =
  "ADD_EMPLOYER_JOB_HISTORY_SUCCESS";
export const ADD_EMPLOYER_JOB_HISTORY_FAILURE =
  "ADD_EMPLOYER_JOB_HISTORY_FAILURE";

export const UPDATE_EMPLOYER_JOB_HISTORY_SUCCESS =
  "UPDATE_EMPLOYER_JOB_HISTORY_SUCCESS";
export const UPDATE_EMPLOYER_JOB_HISTORY_FAILURE =
  "UPDATE_EMPLOYER_JOB_HISTORY_FAILURE";

export const DELETE_EMPLOYER_JOB_HISTORY_SUCCESS =
  "DELETE_EMPLOYER_JOB_HISTORY_SUCCESS";
export const DELETE_EMPLOYER_JOB_HISTORY_FAILURE =
  "DELETE_EMPLOYER_JOB_HISTORY_FAILURE";

export const GET_EMPLOYER_JOB_STATUS_SUCCESS =
  "GET_EMPLOYER_JOB_STATUS_SUCCESS";
export const GET_EMPLOYER_JOB_STATUS_FAILURE =
  "GET_EMPLOYER_JOB_STATUS_FAILURE";

export const GET_EMPLOYER_JOB_HISTORY_CURRENT_SUCCESS =
  "GET_EMPLOYER_JOB_HISTORY_CURRENT_SUCCESS";
export const GET_EMPLOYER_JOB_HISTORY_CURRENT_FAILURE =
  "GET_EMPLOYER_JOB_HISTORY_CURRENT_FAILURE";

export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAILURE = "GET_SCHOOLS_FAILURE";

export const GET_SCHOOL_LEVEL_SUCCESS = "GET_SCHOOL_LEVEL_SUCCESS";
export const GET_SCHOOL_LEVEL_FAILURE = "GET_SCHOOL_LEVEL_FAILURE";

export const ADD_COMISSION_FORM_SUCCESS = "ADD_COMISSION_FORM_SUCCESS";
export const ADD_COMISSION_FORM_FAILURE = "ADD_COMISSION_FORM_FAILURE";

export const GET_COMISSION_PAYMENT_SUCCESS = "GET_COMISSION_PAYMENT_SUCCESS";
export const GET_COMISSION_PAYMENT_FAILURE = "GET_COMISSION_PAYMENT_FAILURE";

export const GET_ADMISSION_STATUS_SUCCESS = "GET_ADMISSION_STATUS_SUCCESS";
export const GET_ADMISSION_STATUS_FAILURE = "GET_ADMISSION_STATUS_FAILURE";

export const UPD_ADMISSION_FORM_SUCCESS = "UPD_ADMISSION_FORM_SUCCESS";
export const UPD_ADMISSION_FORM_FAILURE = "UPD_ADMISSION_FORM_FAILURE";

export const UPD_ADMISSION_STATUS_SUCCESS = "UPD_ADMISSION_STATUS_SUCCESS";
export const UPD_ADMISSION_STATUS_FAILURE = "UPD_ADMISSION_STATUS_FAILURE";

export const ADD_ADMISSION_REMINDER_SUCCESS = "ADD_ADMISSION_REMINDER_SUCCESS";
export const ADD_ADMISSION_REMINDER_FAILURE = "ADD_ADMISSION_REMINDER_FAILURE";
// Account
export const ACCOUNT_CREATE_INVOICE_SUCCESS = "ACCOUNT_CREATE_INVOICE_SUCCESS";
export const ACCOUNT_CREATE_INVOICE_FAILURE = "ACCOUNT_CREATE_INVOICE_FAILURE";

export const GET_EMPLOYER_LIST_SUCCESS = "GET_EMPLOYER_LIST_SUCCESS";
export const GET_EMPLOYER_LIST_FAILURE = "GET_EMPLOYER_LIST_FAILURE";

export const ACCOUNT_INCOMING_OUTGOING_PAYMENTS_SUCCESS =
  "ACCOUNT_INCOMING_OUTGOING_PAYMENTS_SUCCESS";
export const ACCOUNT_INCOMING_OUTGOING_PAYMENTS_FAILURE =
  "ACCOUNT_INCOMING_OUTGOING_PAYMENTS_FAILURE";

export const ACCOUNT_DAILY_TRANSACTIONS_SUCCESS =
  "ACCOUNT_DAILY_TRANSACTIONS_SUCCESS";
export const ACCOUNT_DAILY_TRANSACTIONS_FAILURE =
  "ACCOUNT_DAILY_TRANSACTIONS_FAILURE";

export const GET_DUE_OVER_INVOICES_LISTING_SUCCESS =
  "GET_DUE_OVER_INVOICES_LISTING_SUCCESS";
export const GET_DUE_OVER_INVOICES_LISTING_FAILURE =
  "GET_DUE_OVER_INVOICES_LISTING_FAILURE";

export const GET_ALL_TYPES_INVOICES_SUCCESS = "GET_ALL_TYPES_INVOICES_SUCCESS";
export const GET_ALL_TYPES_INVOICES_FAILURE = "GET_ALL_TYPES_INVOICES_FAILURE";

export const GET_WORK_IN_PROGRESS_INVOICES_LISTING_SUCCESS =
  "GET_WORK_IN_PROGRESS_INVOICES_LISTING_SUCCESS";
export const GET_WORK_IN_PROGRESS_INVOICES_LISTING_FAILURE =
  "GET_WORK_IN_PROGRESS_INVOICES_LISTING_FAILURE";

export const GET_WORK_COMPLETED_INVOICES_LISTING_SUCCESS =
  "GET_WORK_COMPLETED_INVOICES_LISTING_SUCCESS";
export const GET_WORK_COMPLETED_INVOICES_LISTING_FAILURE =
  "GET_WORK_COMPLETED_INVOICES_LISTING_FAILURE";

export const GET_INVOICES_GRAPH_LISTING_SUCCESS =
  "GET_INVOICES_GRAPH_LISTING_SUCCESS";
export const GET_INVOICES_GRAPH_LISTING_FAILURE =
  "GET_INVOICES_GRAPH_LISTING_FAILURE";

export const GET_TEMPLATE_ITEM_INFO_SUCCESS = "GET_TEMPLATE_ITEM_INFO_SUCCESS";
export const GET_TEMPLATE_ITEM_INFO_FAILURE = "GET_TEMPLATE_ITEM_INFO_FAILURE";

// DOCUMENT CHECKLIST
export const GET_DOCUMENT_CHECKLIST_SUCCESS = "GET_DOCUMENT_CHECKLIST_SUCCESS";
export const GET_DOCUMENT_CHECKLIST_FAILURE = "GET_DOCUMENT_CHECKLIST_FAILURE";

export const ADD_DOCUMENT_CHECKLIST_SUCCESS = "ADD_DOCUMENT_CHECKLIST_SUCCESS";
export const ADD_DOCUMENT_CHECKLIST_FAILURE = "ADD_DOCUMENT_CHECKLIST_FAILURE";

export const UPDATE_DOCUMENT_CHECKLIST_SUCCESS =
  "UPDATE_DOCUMENT_CHECKLIST_SUCCESS";
export const UPDATE_DOCUMENT_CHECKLIST_FAILURE =
  "UPDATE_DOCUMENT_CHECKLIST_FAILURE";

export const DELETE_DOCUMENT_CHECKLIST_SUCCESS =
  "DELETE_DOCUMENT_CHECKLIST_SUCCESS";
export const DELETE_DOCUMENT_CHECKLIST_FAILURE =
  "DELETE_DOCUMENT_CHECKLIST_FAILURE";

export const GET_CHECKLIST_ITEMS_SUCCESS = "GET_CHECKLIST_ITEMS_SUCCESS";
export const GET_CHECKLIST_ITEMS_FAILURE = "GET_CHECKLIST_ITEMS_FAILURE";

export const GET_EMPLOYER_CHECKLIST_SUCCESS = "GET_EMPLOYER_CHECKLIST_SUCCESS";
export const GET_EMPLOYER_CHECKLIST_FAILURE = "GET_EMPLOYER_CHECKLIST_FAILURE";

export const ADD_EMPLOYER_CHECKLIST_SUCCESS = "ADD_EMPLOYER_CHECKLIST_SUCCESS";
export const ADD_EMPLOYER_CHECKLIST_FAILURE = "ADD_EMPLOYER_CHECKLIST_FAILURE";

export const UPDATE_IMAP_SETTING_SUCCESS = "UPDATE_IMAP_SETTING_SUCCESS";
export const UPDATE_IMAP_SETTING_FAILURE = "UPDATE_IMAP_SETTING_FAILURE";

export const GET_IMAP_SETTING_SUCCESS = "GET_IMAP_SETTING_SUCCESS";
export const GET_IMAP_SETTING_FAILURE = "GET_IMAP_SETTING_FAILURE";
// Client Source
export const GET_CLIENT_SOURCE_LISTING_SUCCESS =
  "GET_CLIENT_SOURCE_LISTING_SUCCESS";
export const GET_CLIENT_SOURCE_LISTING_FAILURE =
  "GET_CLIENT_SOURCE_LISTING_FAILURE";

// Potential Client

export const MOVE_TO_CLIENT_SUCCESS = "MOVE_TO_CLIENT_SUCCESS";
export const MOVE_TO_CLIENT_FAILURE = "MOVE_TO_CLIENT_FAILURE";

export const GET_POTENTIAL_CLIENT_LISTING_SUCCESS =
  "GET_POTENTIAL_CLIENT_LISTING_SUCCESS";
export const GET_POTENTIAL_CLIENT_LISTING_FAILURE =
  "GET_POTENTIAL_CLIENT_LISTING_FAILURE";

export const ADD_POTENTIAL_CLIENT_SUCCESS = "ADD_POTENTIAL_CLIENT_SUCCESS";
export const ADD_POTENTIAL_CLIENT_FAILURE = "ADD_POTENTIAL_CLIENT_FAILURE";

export const DELETE_POTENTIAL_CLIENT_SUCCESS =
  "DELETE_POTENTIAL_CLIENT_SUCCESS";
export const DELETE_POTENTIAL_CLIENT_FAILURE =
  "DELETE_POTENTIAL_CLIENT_FAILURE";

export const GET_POTENTIAL_CLIENT_INFO_SUCCESS =
  "GET_POTENTIAL_CLIENT_INFO_SUCCESS";
export const GET_POTENTIAL_CLIENT_INFO_FAILURE =
  "GET_POTENTIAL_CLIENT_INFO_FAILURE";

export const EDIT_POTENTIAL_CLIENT_SUCCESS = "EDIT_POTENTIAL_CLIENT_SUCCESS";
export const EDIT_POTENTIAL_CLIENT_FAILURE = "EDIT_POTENTIAL_CLIENT_FAILURE";

export const DUPLICATE_CHECK_POTENTIAL_CLIENT_SUCCESS =
  "DUPLICATE_CHECK_POTENTIAL_CLIENT_SUCCESS";
export const DUPLICATE_CHECK_POTENTIAL_CLIENT_FAILURE =
  "DUPLICATE_CHECK_POTENTIAL_CLIENT_FAILURE";

export const GET_POTENTIAL_CLIENT_SUCCESS = "GET_POTENTIAL_CLIENT_SUCCESS";
export const GET_POTENTIAL_CLIENT_FAILURE = "GET_POTENTIAL_CLIENT_FAILURE";

export const GET_EMPLOYER_SEARCH_SUCCESS = "GET_EMPLOYER_SEARCH_SUCCESS";
export const GET_EMPLOYER_SEARCH_FAILURE = "GET_EMPLOYER_SEARCH_FAILURE";

export const GET_POTENTIAL_CLIENT_UPDATE_BOL =
  "GET_POTENTIAL_CLIENT_UPDATE_BOL";

// import potential client
export const CREATE_IMPORT_POTENTIAL_CLIENT_SUCCESS =
  "CREATE_IMPORT_POTENTIAL_CLIENT_SUCCESS";
export const CREATE_IMPORT_POTENTIAL_CLIENT_FAILURE =
  "CREATE_IMPORT_POTENTIAL_CLIENT_FAILURE";

export const POTENTIAL_EMAIL_IMPORT_SUCCESS = "POTENTIAL_EMAIL_IMPORT_SUCCESS";
export const POTENTIAL_EMAIL_IMPORT_FAILURE = "POTENTIAL_EMAIL_IMPORT_FAILURE";

export const ADD_NEW_CLIENT_NULL = "ADD_NEW_CLIENT_NULL";

export const CREATE_NEW_CLIENT_SUCCESS = "CREATE_NEW_CLIENT_SUCCESS";
export const CREATE_NEW_CLIENT_FAILURE = "CREATE_NEW_CLIENT_FAILURE";

export const ADD_EMPLOYER_EMAIL_SUCCESS = "ADD_EMPLOYER_EMAIL_SUCCESS";
export const ADD_EMPLOYER_EMAIL_FAILURE = "ADD_EMPLOYER_EMAIL_FAILURE";

export const GET_EMPLOYER_HISTORY_SUCCESS = "GET_EMPLOYER_HISTORY_SUCCESS";
export const GET_EMPLOYER_HISTORY_FAILURE = "GET_EMPLOYER_HISTORY_FAILURE";

export const GET_EMAIL_IMPORT_SUCCESS = "GET_EMAIL_IMPORT_SUCCESS";
export const GET_EMAIL_IMPORT_FAILURE = "GET_EMAIL_IMPORT_FAILURE";

export const GET_POTENTIAL_EMAIL_HISTORY_SUCCESS =
  "GET_POTENTIAL_EMAIL_HISTORY_SUCCESS";
export const GET_POTENTIAL_EMAIL_HISTORY_FAILURE =
  "GET_POTENTIAL_EMAIL_HISTORY_FAILURE";

export const ADD_EMAIL_IMPORT_SUCCESS = "ADD_EMAIL_IMPORT_SUCCESS";
export const ADD_EMAIL_IMPORT_FAILURE = "ADD_EMAIL_IMPORT_FAILURE";

export const GET_EMAIL_HISTORY_SUCCESS = "GET_EMAIL_HISTORY_SUCCESS";
export const GET_EMAIL_HISTORY_FAILURE = "GET_EMAIL_HISTORY_FAILURE";

export const ADD_DOCUMENT_TITTLE_SUCCESS = "ADD_DOCUMENT_TITTLE_SUCCESS";
export const ADD_DOCUMENT_TITTLE_FAILURE = "ADD_DOCUMENT_TITTLE_FAILURE";

export const GET_CFILE_NOTES_SUCCESS = "GET_CFILE_NOTES_SUCCESS";
export const GET_CFILE_NOTES_FAILURE = "GET_CFILE_NOTES_FAILURE";

export const ADD_CFILE_NOTES_SUCCESS = "ADD_CFILE_NOTES_SUCCESS";
export const ADD_CFILE_NOTES_FAILURE = "ADD_CFILE_NOTES_FAILURE";

export const UPDATE_CFILE_NOTES_SUCCESS = "UPDATE_CFILE_NOTES_SUCCESS";
export const UPDATE_CFILE_NOTES_FAILURE = "UPDATE_CFILE_NOTES_FAILURE";

export const DELETE_CFILE_NOTES_SUCCESS = "DELETE_CFILE_NOTES_SUCCESS";
export const DELETE_CFILE_NOTES_FAILURE = "DELETE_CFILE_NOTES_FAILURE";

export const DELETE_REMINDER_TASK_SUCCESS = "DELETE_REMINDER_TASK_SUCCESS";
export const DELETE_REMINDER_TASK_FAILURE = "DELETE_REMINDER_TASK_FAILURE";
export const GET_BRANCH_SELECTED = "GET_BRANCH_SELECTED";

export const SIDEBAR_ACTION_OPEN_AND_CLOSE = "SIDEBAR_ACTION_OPEN_AND_CLOSE";

export const ADD_ANY_TEMPLATE_SUCCESS = "ADD_ANY_TEMPLATE_SUCCESS";
export const ADD_ANY_TEMPLATE_FAILURE = "ADD_ANY_TEMPLATE_FAILURE";

/**
 * DOCUMENT SYNC
 */

export const ADD_DROPBOX_DOC_SUCCESS = "ADD_DROPBOX_DOC_SUCCESS";
export const ADD_DROPBOX_DOC_FAILURE = "ADD_DROPBOX_DOC_FAILURE";

export const GET_DROPBOX_DOC_SUCCESS = "GET_DROPBOX_DOC_SUCCESS";
export const GET_DROPBOX_DOC_FAILURE = "GET_DROPBOX_DOC_FAILURE";

export const DROPBOX_LOGIN_SUCCESS = "GET_DROPBOX_VIEW_SUCCESS";
export const DROPBOX_LOGIN_FAILURE = "GET_DROPBOX_VIEW_FAILURE";

export const ONEDRIVE_LOGIN_SUCCESS = "GET_ONEDRIVE_VIEW_SUCCESS";
export const ONEDRIVE_LOGIN_FAILURE = "GET_ONEDRIVE_VIEW_FAILURE";

export const ONEDRIVE_TOKEN_SUCCESS = "GET_ONEDRIVE_TOKEN_SUCCESS";
export const ONEDRIVE_TOKEN_FAILURE = "GET_ONEDRIVE_TOKEN_FAILURE";

export const ONEDRIVE_ACCOUNT_SUCCESS = "GET_ONEDRIVE_ACCOUNT_SUCCESS";
export const ONEDRIVE_ACCOUNT_FAILURE = "GET_ONEDRIVE_ACCOUNT_FAILURE";

export const GOOGLEDRIVE_LOGIN_SUCCESS = "GET_GOOGLEDRIVE_VIEW_SUCCESS";
export const GOOGLEDRIVE_LOGIN_FAILURE = "GET_GOOGLEDRIVE_VIEW_FAILURE";

export const GOOGLEDRIVE_TOKEN_SUCCESS = "GET_GOOGLEDRIVE_TOKEN_SUCCESS";
export const GOOGLEDRIVE_TOKEN_FAILURE = "GET_GOOGLEDRIVE_TOKEN_FAILURE";

export const GOOGLEDRIVE_ACCOUNT_SUCCESS = "GET_GOOGLEDRIVE_ACCOUNT_SUCCESS";
export const GOOGLEDRIVE_ACCOUNT_FAILURE = "GET_GOOGLEDRIVE_ACCOUNT_FAILURE";

export const OFFICE365_LOGIN_SUCCESS = "GET_OFFICE365_VIEW_SUCCESS";
export const OFFICE365_LOGIN_FAILURE = "GET_OFFICE365_VIEW_FAILURE";

export const OFFICE365_TOKEN_SUCCESS = "GET_OFFICE365_TOKEN_SUCCESS";
export const OFFICE365_TOKEN_FAILURE = "GET_OFFICE365_TOKEN_FAILURE";

export const OFFICE365_ACCOUNT_SUCCESS = "GET_OFFICE365_ACCOUNT_SUCCESS";
export const OFFICE365_ACCOUNT_FAILURE = "GET_OFFICE365_ACCOUNT_FAILURE";

export const DELETE_DROPBOX_ACCOUNT_SUCCESS = "DELETE_DROPBOX_ACCOUNT_SUCCESS";
export const DELETE_DROPBOX_ACCOUNT_FAILURE = "DELETE_DROPBOX_ACCOUNT_FAILURE";

export const DELETE_ONEDRIVE_ACCOUNT_SUCCESS =
  "DELETE_ONEDRIVE_ACCOUNT_SUCCESS";
export const DELETE_ONEDRIVE_ACCOUNT_FAILURE =
  "DELETE_ONEDRIVE_ACCOUNT_FAILURE";

export const DELETE_GOOGLEDRIVE_ACCOUNT_SUCCESS =
  "DELETE_GOOGLEDRIVE_ACCOUNT_SUCCESS";
export const DELETE_GOOGLEDRIVE_ACCOUNT_FAILURE =
  "DELETE_GOOGLEDRIVE_ACCOUNT_FAILURE";

export const DELETE_OFFICE365_ACCOUNT_SUCCESS =
  "DELETE_OFFICE365_ACCOUNT_SUCCESS";
export const DELETE_OFFICE365_ACCOUNT_FAILURE =
  "DELETE_OFFICE365_ACCOUNT_FAILURE";
/**
 * DOCUMENT SYNC
 */
export const ADD_UPLOAD_FILE_SUCCESS = "ADD_UPLOAD_FILE_SUCCESS";
export const ADD_UPLOAD_FILE_FAILURE = "ADD_UPLOAD_FILE_FAILURE";

export const GET_DYNAMIC_KEYS_SUCCESS = "GET_DYNAMIC_KEYS_SUCCESS";
export const GET_DYNAMIC_KEYS_FAILURE = "GET_DYNAMIC_KEYS_FAILURE";

export const REMOVE_CLIENT_SUCCESS = "REMOVE_CLIENT_SUCCESS";
export const REMOVE_CLIENT_FAILURE = "REMOVE_CLIENT_FAILURE";

export const ADD_SIGN_UP_SUCCESS = "ADD_SIGN_UP_SUCCESS";
export const ADD_SIGN_UP_FAILURE = "ADD_SIGN_UP_FAILURE";

export const SET_ACTIVE_INNER_TAB_SUCCESS = "SET_ACTIVE_INNER_TAB_SUCCESS";

export const SET_PROFILE_DATA_SUCCESS = "SET_PROFILE_DATA_SUCCESS";

export const SET_ACTIVE_KEY_SUCCESS = "SET_ACTIVE_KEY_SUCCESS";
export const SET_CLIENT_TAB_SUCCESS = "SET_CLIENT_TAB_SUCCESS";

export const GET_TEXT_ENCODING_SUCCESS = "GET_TEXT_ENCODING_SUCCESS";
export const GET_TEXT_ENCODING_FAILURE = "GET_TEXT_ENCODING_FAILURE";
// invoices
export const GET_INVOICE_LISTING_SUCCESS = "GET_INVOICE_LISTING_SUCCESS";
export const GET_INVOICE_LISTING_FAILURE = "GET_INVOICE_LISTING_FAILURE";

export const SEND_FOLLOW_UP_SUCCESS = "SEND_FOLLOW_UP_SUCCESS";
export const SEND_FOLLOW_UP_FAILURE = "SEND_FOLLOW_UP_FAILURE";

export const GET_CHILD_INVOICE_LISTING_SUCCESS =
  "GET_CHILD_INVOICE_LISTING_SUCCESS";
export const GET_CHILD_INVOICE_LISTING_FAILURE =
  "GET_CHILD_INVOICE_LISTING_FAILURE";

export const SET_EMAIL_TEMPLATE_PAYMENT_SUCCESS =
  "SET_EMAIL_TEMPLATE_PAYMENT_SUCCESS";
export const SET_EMAIL_TEMPLATE_PAYMENT_FAILURE =
  "SET_EMAIL_TEMPLATE_PAYMENT_FAILURE";

export const GET_INVOICE_LISTING_BY_SUBJECT_ID_SUCCESS =
  "GET_INVOICE_LISTING_BY_SUBJECT_ID_SUCCESS";
export const GET_INVOICE_LISTING_BY_SUBJECT_ID_FAILURE =
  "GET_INVOICE_LISTING_BY_SUBJECT_ID_FAILURE";

export const GET_ALL_INVOICE_TYPES_SUCCESS = "GET_ALL_INVOICE_TYPES_SUCCESS";
export const GET_ALL_INVOICE_TYPES_FAILURE = "GET_ALL_INVOICE_TYPES_FAILURE";

export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_FAILURE = "ADD_INVOICE_FAILURE";

export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILURE = "EDIT_INVOICE_FAILURE";

export const GET_LAST_INVOICE_NO_SUCCESS = "GET_LAST_INVOICE_NO_SUCCESS";
export const GET_LAST_INVOICE_NO_FAILURE = "GET_LAST_INVOICE_NO_FAILURE";

export const GET_INVOICE_INFO_SUCCESS = "GET_INVOICE_INFO_SUCCESS";
export const GET_INVOICE_INFO_FAILURE = "GET_INVOICE_INFO_FAILURE";

export const GET_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS =
  "GET_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS";
export const GET_INVOICE_PDF_WITHOUT_UPDATE_FAILURE =
  "GET_INVOICE_PDF_WITHOUT_UPDATE_FAILURE";

export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";

export const SET_INVOICES_DATA_NULL_SUCCESS = "SET_INVOICES_DATA_NULL_SUCCESS";

export const GET_SCHOOL_EMAIL_SUCCESS = "GET_SCHOOL_EMAIL_SUCCESS";

export const GET_INVOICE_STATUSES_LISTING_SUCCESS =
  "GET_INVOICE_STATUSES_LISTING_SUCCESS";
export const GET_INVOICE_STATUSES_LISTING_FAILURE =
  "GET_INVOICE_STATUSES_LISTING_FAILURE";

export const CHECK_DUPLICATE_INVOICE_SUCCESS =
  "CHECK_DUPLICATE_INVOICE_SUCCESS";
export const CHECK_DUPLICATE_INVOICE_FAILURE =
  "CHECK_DUPLICATE_INVOICE_FAILURE";

//invoicePDFs
export const GET_INVOICE_PDF_SUCCESS = "GET_INVOICE_PDF_SUCCESS";
export const GET_INVOICE_PDF_FAILURE = "GET_INVOICE_PDF_FAILURE";

export const GET_INVOICE_PDF_HTML_BLOB_SUCCESS =
  "GET_INVOICE_PDF_HTML_BLOB_SUCCESS";
export const GET_INVOICE_PDF_HTML_BLOB_FAILURE =
  "GET_INVOICE_PDF_HTML_BLOB_FAILURE";

export const GET_INVOICE_RECEIPT_PDF_HTML_BLOB_SUCCESS =
  "GET_INVOICE_RECEIPT_PDF_HTML_BLOB_SUCCESS";
export const GET_INVOICE_RECEIPT_PDF_HTML_BLOB_FAILURE =
  "GET_INVOICE_RECEIPT_PDF_HTML_BLOB_FAILURE";

export const GET_INVOICE_PAYMENT_PDF_SUCCESS =
  "GET_INVOICE_PAYMENT_PDF_SUCCESS";
export const GET_INVOICE_PAYMENT_PDF_FAILURE =
  "GET_INVOICE_PAYMENT_PDF_FAILURE";

//invoicePayments

export const ADD_INVOICE_PAYMENT_SUCCESS = "ADD_INVOICE_PAYMENT_SUCCESS";
export const ADD_INVOICE_PAYMENT_FAILURE = "ADD_INVOICE_PAYMENT_FAILURE";

export const GET_ALL_INVOICE_PAYMENTS_SUCCESS =
  "GET_ALL_INVOICE_PAYMENTS_SUCCESS";
export const GET_ALL_INVOICE_PAYMENTS_FAILURE =
  "GET_ALL_INVOICE_PAYMENTS_FAILURE";

export const DELETE_INVOICE_PAYMENT_SUCCESS = "DELETE_INVOICE_PAYMENT_SUCCESS";
export const DELETE_INVOICE_PAYMENT_FAILURE = "DELETE_INVOICE_PAYMENT_FAILURE";

export const ADD_INVOICE_TEMPLATE_SUCCESS = "ADD_INVOICE_TEMPLATE_SUCCESS";
export const ADD_INVOICE_TEMPLATE_FAILURE = "ADD_INVOICE_TEMPLATE_FAILURE";

export const GET_INVOICE_TEMPLATE_SUCCESS = "GET_INVOICE_TEMPLATE_SUCCESS";
export const GET_INVOICE_TEMPLATE_FAILURE = "GET_INVOICE_TEMPLATE_FAILURE";

//banks
export const GET_ALL_BANKS_LISTING_SUCCESS = "GET_ALL_BANKS_LISTING_SUCCESS";
export const GET_ALL_BANKS_LISTING_FAILURE = "GET_ALL_BANKS_LISTING_FAILURE";

export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_FAILURE = "ADD_BANK_FAILURE";

export const EDIT_BANK_SUCCESS = "EDIT_BANK_SUCCESS";
export const EDIT_BANK_FAILURE = "EDIT_BANK_FAILURE";

export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAILURE = "DELETE_BANK_FAILURE";

export const GET_BANK_INFO_SUCCESS = "GET_BANK_INFO_SUCCESS";

//taxes
export const GET_ALL_TAXES_LISTING_SUCCESS = "GET_ALL_TAXES_LISTING_SUCCESS";
export const GET_ALL_TAXES_LISTING_FAILURE = "GET_ALL_TAXES_LISTING_FAILURE";

export const ADD_TAX_SUCCESS = "ADD_TAX_SUCCESS";
export const ADD_TAX_FAILURE = "ADD_TAX_FAILURE";

export const EDIT_TAX_SUCCESS = "EDIT_TAX_SUCCESS";
export const EDIT_TAX_FAILURE = "EDIT_TAX_FAILURE";

export const DELETE_TAX_SUCCESS = "DELETE_TAX_SUCCESS";
export const DELETE_TAX_FAILURE = "DELETE_TAX_FAILURE";

export const GET_TAX_INFO_SUCCESS = "GET_TAX_INFO_SUCCESS";

//notes
export const GET_ALL_NOTES_LISTING_SUCCESS = "GET_ALL_NOTES_LISTING_SUCCESS";
export const GET_ALL_NOTES_LISTING_FAILURE = "GET_ALL_NOTES_LISTING_FAILURE";

export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAILURE = "ADD_NOTE_FAILURE";

export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";

export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILURE = "DELETE_NOTE_FAILURE";

export const GET_NOTE_INFO_SUCCESS = "GET_NOTE_INFO_SUCCESS";

//currencies
export const GET_ALL_CURRENCY_LISTING_SUCCESS =
  "GET_ALL_CURRENCY_LISTING_SUCCESS";
export const GET_ALL_CURRENCY_LISTING_FAILURE =
  "GET_ALL_CURRENCY_LISTING_FAILURE";

// REFRESH TOKEN
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAILURE = "TOKEN_REFRESH_FAILURE";

export const ADD_DRAFT_CLIENT_SUCCESS = "ADD_DRAFT_CLIENT_SUCCESS";
export const ADD_DRAFT_CLIENT_FAILURE = "ADD_DRAFT_CLIENT_FAILURE";
// Outgoing Payments
export const GET_OUTGOING_PAYMENTS_LISTING_SUCCESS =
  "GET_OUTGOING_PAYMENTS_LISTING_SUCCESS";
export const GET_OUTGOING_PAYMENTS_LISTING_FAILURE =
  "GET_OUTGOING_PAYMENTS_LISTING_FAILURE";

export const ADD_OUTGOING_PAYMENTS_SUCCESS = "ADD_OUTGOING_PAYMENTS_SUCCESS";
export const ADD_OUTGOING_PAYMENTS_FAILURE = "ADD_OUTGOING_PAYMENTS_FAILURE";

export const DELETE_OUTGOING_PAYMENT_SUCCESS =
  "DELETE_OUTGOING_PAYMENTS_SUCCESS";
export const DELETE_OUTGOING_PAYMENT_FAILURE =
  "DELETE_OUTGOING_PAYMENTS_FAILURE";

export const GET_OUTGOING_PAYMENT_INFO_SUCCESS =
  "GET_OUTGOING_PAYMENT_INFO_SUCCESS";
export const GET_OUTGOING_PAYMENT_INFO_FAILURE =
  "GET_OUTGOING_PAYMENT_INFO_FAILURE";

//outgoing-payment-sources
export const GET_PAYMENT_SOURCES_LISTING_SUCCESS =
  "GET_PAYMENT_SOURCES_LISTING_SUCCESS";
export const GET_PAYMENT_SOURCES_LISTING_FAILURE =
  "GET_PAYMENT_SOURCES_LISTING_FAILURE";

export const DELETE_OUTGOING_PAYMENT_SOURCE_SUCCESS =
  "DELETE_OUTGOING_PAYMENT_SOURCE_SUCCESS";
export const DELETE_OUTGOING_PAYMENT_SOURCE_FAILURE =
  "DELETE_OUTGOING_PAYMENT_SOURCE_FAILURE";
export const GET_OUTGOING_PAYMENT_SOURCE_INFO_SUCCESS =
  "GET_OUTGOING_PAYMENT_SOURCE_INFO_SUCCESS";
export const ADD_OUTGOING_PAYMENTS_SOURCE_SUCCESS =
  "ADD_OUTGOING_PAYMENTS_SOURCE_SUCCESS";
export const ADD_OUTGOING_PAYMENTS_SOURCE_FAILURE =
  "ADD_OUTGOING_PAYMENTS_SOURCE_FAILURE";

export const EDIT_OUTGOING_PAYMENTS_SOURCE_SUCCESS =
  "EDIT_OUTGOING_PAYMENTS_SOURCE_SUCCESS";
export const EDIT_OUTGOING_PAYMENTS_SOURCE_FAILURE =
  "EDIT_OUTGOING_PAYMENTS_SOURCE_FAILURE";

export const EDIT_OUTGOING_PAYMENTS_SUCCESS = "EDIT_OUTGOING_PAYMENTS_SUCCESS";
export const EDIT_OUTGOING_PAYMENTS_FAILURE = "EDIT_OUTGOING_PAYMENTS_FAILURE";

// START: case management system //

export const GET_CLIENT_PROFILES_LISTING_SUCCESS =
  "GET_CLIENT_PROFILES_LISTING_SUCCESS";
export const GET_CLIENT_PROFILES_LISTING_FAILURE =
  "GET_CLIENT_PROFILES_LISTING_FAILURE";

export const GET_VISA_STATUS_LISTING_SUCCESS =
  "GET_VISA_STATUS_LISTING_SUCCESS";
export const GET_VISA_STATUS_LISTING_FAILURE =
  "GET_VISA_STATUS_LISTING_FAILURE";

export const UPDATE_VISA_CASE_STATUS_SUCCESS =
  "UPDATE_VISA_CASE_STATUS_SUCCESS";
export const UPDATE_VISA_CASE_STATUS_FAILURE =
  "UPDATE_VISA_CASE_STATUS_FAILURE";

export const GET_ADMISSION_CASE_STATUS_LISTING_SUCCESS =
  "GET_ADMISSION_CASE_STATUS_LISTING_SUCCESS";
export const GET_ADMISSION_CASE_STATUS_LISTING_FAILURE =
  "GET_ADMISSION_CASE_STATUS_LISTING_FAILURE";

export const UPDATE_ADMISSION_CASE_STATUS_SUCCESS =
  "UPDATE_ADMISSION_CASE_STATUS_SUCCESS";
export const UPDATE_ADMISSION_CASE_STATUS_FAILURE =
  "UPDATE_ADMISSION_CASE_STATUS_FAILURE";

export const GET_CHECKLIST_LISTING_SUCCESS = "GET_CHECKLIST_LISTING_SUCCESS";
export const GET_CHECKLIST_LISTING_FAILURE = "GET_CHECKLIST_LISTING_FAILURE";

export const GET_VISA_CASE_CHECKLIST_LISTING_SUCCESS =
  "GET_VISA_CASE_CHECKLIST_LISTING_SUCCESS";
export const GET_VISA_CASE_CHECKLIST_LISTING_FAILURE =
  "GET_VISA_CASE_CHECKLIST_LISTING_FAILURE";

export const ADD_CHECKLIST_WITH_CASE_SUCCESS =
  "ADD_CHECKLIST_WITH_CASE_SUCCESS";
export const ADD_CHECKLIST_WITH_CASE_FAILURE =
  "ADD_CHECKLIST_WITH_CASE_FAILURE";

export const ADD_CHECKLIST_TASK_SUCCESS = "ADD_CHECKLIST_TASK_SUCCESS";
export const ADD_CHECKLIST_TASK_FAILURE = "ADD_CHECKLIST_TASK_FAILURE";

export const COMPLETE_CHECKLIST_TASK_SUCCESS =
  "COMPLETE_CHECKLIST_TASK_SUCCESS";
export const COMPLETE_CHECKLIST_TASK_FAILURE =
  "COMPLETE_CHECKLIST_TASK_FAILURE";

export const UPDATE_CHECKLIST_TASK_SUCCESS = "UPDATE_CHECKLIST_TASK_SUCCESS";
export const UPDATE_CHECKLIST_TASK_FAILURE = "UPDATE_CHECKLIST_TASK_FAILURE";

export const DELETE_CMS_CLIENT_SUCCESS = "DELETE_CMS_CLIENT_SUCCESS";
export const DELETE_CMS_CLIENT_FAILURE = "DELETE_CMS_CLIENT_FAILURE";

export const DELETE_CMS_CASE_SUCCESS = "DELETE_CMS_CASE_SUCCESS";
export const DELETE_CMS_CASE_FAILURE = "DELETE_CMS_CASE_FAILURE";

export const DELETE_CMS_ADMISSION_CASE_SUCCESS =
  "DELETE_CMS_ADMISSION_CASE_SUCCESS";
export const DELETE_CMS_ADMISSION_CASE_FAILURE =
  "DELETE_CMS_ADMISSION_CASE_FAILURE";

export const DELETE_CMS_CASE_CHECKLIST_SUCCESS =
  "DELETE_CMS_CASE_CHECKLIST_SUCCESS";
export const DELETE_CMS_CASE_CHECKLIST_FAILURE =
  "DELETE_CMS_CASE_CHECKLIST_FAILURE";

export const DELETE_CMS_CHECKLIST_TASK_SUCCESS =
  "DELETE_CMS_CHECKLIST_TASK_SUCCESS";
export const DELETE_CMS_CHECKLIST_TASK_FAILURE =
  "DELETE_CMS_CHECKLIST_TASK_FAILURE";

export const GET_CHECKLIST_TASKS_SUCCESS = "GET_CHECKLIST_TASKS_SUCCESS";
export const GET_CHECKLIST_TASKS_FAILURE = "GET_CHECKLIST_TASKS_FAILURE";

export const ADD_TASK_COMMENT_SUCCESS = "ADD_TASK_COMMENT_SUCCESS";
export const ADD_TASK_COMMENT_FAILURE = "ADD_TASK_COMMENT_FAILURE";

export const GET_TASK_COMMENT_SUCCESS = "GET_TASK_COMMENT_SUCCESS";
export const GET_TASK_COMMENT_FAILURE = "GET_TASK_COMMENT_FAILURE";

export const ADD_VISA_CATEGORY_TYPE_SUCCESS = "ADD_VISA_CATEGORY_TYPE_SUCCESS";
export const ADD_VISA_CATEGORY_TYPE_FAILURE = "ADD_VISA_CATEGORY_TYPE_FAILURE";

export const ADD_VISA_CHECKLIST_SUCCESS = "ADD_VISA_CHECKLIST_SUCCESS";
export const ADD_VISA_CHECKLIST_FAILURE = "ADD_VISA_CHECKLIST_FAILURE";

export const GET_VISA_CATEGORY_TYPE_SUCCESS = "GET_VISA_CATEGORY_TYPE_SUCCESS";
export const GET_VISA_CATEGORY_TYPE_FAILURE = "GET_VISA_CATEGORY_TYPE_FAILURE";

export const GET_VISA_CATEGORY_CHECKLIST_SUCCESS =
  "GET_VISA_CATEGORY_CHECKLIST_SUCCESS";
export const GET_VISA_CATEGORY_CHECKLIST_FAILURE =
  "GET_VISA_CATEGORY_CHECKLIST_FAILURE";

export const DELETE_CHECKLIST_CATEGORY_SUCCESS =
  "DELETE_CHECKLIST_CATEGORY_SUCCESS";
export const DELETE_CHECKLIST_CATEGORY_FAILURE =
  "DELETE_CHECKLIST_CATEGORY_FAILURE";

export const UPDATE_CHECKLIST_CATEGORY_SUCCESS =
  "UPDATE_CHECKLIST_CATEGORY_SUCCESS";
export const UPDATE_CHECKLIST_CATEGORY_FAILURE =
  "UPDATE_CHECKLIST_CATEGORY_FAILURE";

export const UPDATE_CASE_PRIORITY_SUCCESS = "UPDATE_CASE_PRIORITY_SUCCESS";
export const UPDATE_CASE_PRIORITY_FAILURE = "UPDATE_CASE_PRIORITY_FAILURE";

export const UPDATE_SIGNED_VISA_SUCCESS = "UPDATE_SIGNED_VISA_SUCCESS";
export const UPDATE_SIGNED_VISA_FAILURE = "UPDATE_SIGNED_VISA_FAILURE";

export const UPDATE_PAID_VISA_SUCCESS = "UPDATE_PAID_VISA_SUCCESS";
export const UPDATE_PAID_VISA_FAILURE = "UPDATE_PAID_VISA_FAILURE";

export const UPDATE_VISA_CHECKLIST_SUCCESS = "UPDATE_VISA_CHECKLIST_SUCCESS";
export const UPDATE_VISA_CHECKLIST_FAILURE = "UPDATE_VISA_CHECKLIST_FAILURE";

export const DELETE_CHECKLIST_SUCCESS = "DELETE_CHECKLIST_SUCCESS";
export const DELETE_CHECKLIST_FAILURE = "DELETE_CHECKLIST_FAILURE";

export const GET_CHECKLIST_TASK_SUCCESS = "GET_CHECKLIST_TASK_SUCCESS";
export const GET_CHECKLIST_TASK_FAILURE = "GET_CHECKLIST_TASK_FAILURE";

export const DELETE_CHECKLIST_TASK_SUCCESS = "DELETE_CHECKLIST_TASK_SUCCESS";
export const DELETE_CHECKLIST_TASK_FAILURE = "DELETE_CHECKLIST_TASK_FAILURE";

export const SEARCH_CLIENT_PROFILES_LISTING_SUCCESS =
  "SEARCH_CLIENT_PROFILES_LISTING_SUCCESS";
export const SEARCH_CLIENT_PROFILES_LISTING_FAILURE =
  "SEARCH_CLIENT_PROFILES_LISTING_FAILURE";

export const GET_VISA_CASES_SUCCESS = "GET_VISA_CASES_SUCCESS";
export const GET_VISA_CASES_FAILURE = "GET_VISA_CASES_FAILURE";

export const UPDATE_CLIENT_STATUS_SUCCESS = "UPDATE_CLIENT_STATUS_SUCCESS";
export const UPDATE_CLIENT_STATUS_FAILURE = "UPDATE_CLIENT_STATUS_FAILURE";

export const ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS =
  "ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS";
export const ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_FAILURE =
  "ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_FAILURE";
//END:  case management system //

export const ADD_SIGN_FORM_SUCCESS = "ADD_SIGN_FORM_SUCCESS";
export const ADD_SIGN_FORM_FAILURE = "ADD_SIGN_FORM_FAILURE";

export const DELETE_CREATE_EMAIL_SUCCESS = "DELETE_CREATE_EMAIL_SUCCESS";
export const DELETE_CREATE_EMAIL_FAILURE = "DELETE_CREATE_EMAIL_FAILURE";

export const DELETE_CLIENT_EMAIL_SUCCESS = "DELETE_CLIENT_EMAIL_SUCCESS";
export const DELETE_CLIENT_EMAIL_FAILURE = "DELETE_CLIENT_EMAIL_FAILURE";

export const ADD_EMAIL_DOCUMENT_SUCCESS = "ADD_EMAIL_DOCUMENT_SUCCESS";
export const ADD_EMAIL_DOCUMENT_FAILURE = "ADD_EMAIL_DOCUMENT_FAILURE";

export const GET_DOCUMENT_DOWNLOAD_SUCCESS = "GET_DOCUMENT_DOWNLOAD_SUCCESS";
export const GET_DOCUMENT_DOWNLOAD_FAILURE = "GET_DOCUMENT_DOWNLOAD_FAILURE";

export const GET_DOCUMENT_PDF_SUCCESS = "GET_DOCUMENT_PDF_SUCCESS";
export const GET_DOCUMENT_PDF_FAILURE = "GET_DOCUMENT_PDF_FAILURE";

export const GET_DOCUMENT_FILTERTYPE_SUCCESS =
  "GET_DOCUMENT_FILTERTYPE_SUCCESS";
export const GET_DOCUMENT_FILTERTYPE_FAILURE =
  "GET_DOCUMENT_FILTERTYPE_FAILURE";

export const ADD_DOCUMENT_FILTERTYPE_SUCCESS =
  "ADD_DOCUMENT_FILTERTYPE_SUCCESS";
export const ADD_DOCUMENT_FILTERTYPE_FAILURE =
  "ADD_DOCUMENT_FILTERTYPE_FAILURE";

export const UPDATE_EMPLOYER_JOB_SUCCESS = "UPDATE_EMPLOYER_JOB_SUCCESS";
export const UPDATE_EMPLOYER_JOB_FAILURE = "UPDATE_EMPLOYER_JOB_FAILURE";

export const DELETE_EMPLOYER_JOB_SUCCESS = "DELETE_EMPLOYER_JOB_SUCCESS";
export const DELETE_EMPLOYER_JOB_FAILURE = "DELETE_EMPLOYER_JOB_FAILURE";

export const GET_CLIENT_SEARCH_SUCCESS = "GET_CLIENT_SEARCH_SUCCESS";
export const GET_CLIENT_SEARCH_FAILURE = "GET_CLIENT_SEARCH_FAILURE";

export const GET_CLIENT_EMP_SUCCESS = "GET_CLIENT_EMP_SUCCESS";
export const GET_CLIENT_EMP_FAILURE = "GET_CLIENT_EMP_FAILURE";

export const ADD_CLIENT_EMP_SUCCESS = "ADD_CLIENT_EMP_SUCCESS";
export const ADD_CLIENT_EMP_FAILURE = "ADD_CLIENT_EMP_FAILURE";

export const LINK_EMP_CLIENT_SUCCESS = "LINK_EMP_CLIENT_SUCCESS";
export const LINK_EMP_CLIENT_FAILURE = "LINK_EMP_CLIENT_FAILURE";

export const ADD_DOCUMENT_WITHNAME_SUCCESS = "ADD_DOCUMENT_WITHNAME_SUCCESS";
export const ADD_DOCUMENT_WITHNAME_FAILURE = "ADD_DOCUMENT_WITHNAME_FAILURE";

export const GET_CLIENT_JOB_HISTORY_SUCCESS = "GET_CLIENT_JOB_HISTORY_SUCCESS";
export const GET_CLIENT_JOB_HISTORY_FAILURE = "GET_CLIENT_JOB_HISTORY_FAILURE";

export const CLIENT_FAMILY_EMAIL_SUCCESS = "CLIENT_FAMILY_EMAIL_SUCCESS";
export const CLIENT_FAMILY_EMAIL_FAILURE = "CLIENT_FAMILY_EMAIL_FAILURE";

export const ADD_SINGLE_JOBHIS_SUCCESS = "ADD_SINGLE_JOBHIS_SUCCESS";
export const ADD_SINGLE_JOBHIS_FAILURE = "ADD_SINGLE_JOBHIS_FAILURE";

// Chat Display : Start
export const CHATS_DISPLAY_SUCCESS = "CHATS_DISPLAY_SUCCESS";
export const CHATS_DISPLAY_FAILURE = "CHATS_DISPLAY_FAILURE";

export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const ADD_CHAT_THREAD_SUCCESS = "ADD_CHAT_THREAD_SUCCESS";
export const ADD_CHAT_THREAD_FAILURE = "ADD_CHAT_THREAD_FAILURE";

export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_FAILURE = "GET_CHAT_MESSAGES_FAILURE";

export const ADD_THREAD_MESSAGE_SUCCESS = "ADD_THREAD_MESSAGE_SUCCESS";
export const ADD_THREAD_MESSAGE_FAILURE = "ADD_THREAD_MESSAGE_FAILURE";

export const ADD_NOTIFICATION_TOKEN_SUCCESS = "ADD_NOTIFICATION_TOKEN_SUCCESS";
export const ADD_NOTIFICATION_TOKEN_FAILURE = "ADD_NOTIFICATION_TOKEN_FAILURE";

export const REMOVE_NOTIFICATION_TOKEN_SUCCESS =
  "REMOVE_NOTIFICATION_TOKEN_SUCCESS";

export const REMOVE_NOTIFICATION_TOKEN_FAILURE =
  "REMOVE_NOTIFICATION_TOKEN_FAILURE";

export const ADD_CHAT_MEMBER_SUCCESS = "ADD_CHAT_MEMBER_SUCCESS";
export const ADD_CHAT_MEMBER_FAILURE = "ADD_CHAT_MEMBER_FAILURE";

export const ADD_CHAT_FILENOTES_SUCCESS = "ADD_CHAT_FILENOTES_SUCCESS";
export const ADD_CHAT_FILENOTES_FAILURE = "ADD_CHAT_FILENOTES_FAILURE";

//Chat Display : End
// Reports

export const SALE_HISTORY_REPORT_SUCCESS = "SALE_HISTORY_REPORT_SUCCESS";
export const SALE_HISTORY_REPORT_FAILURE = "SALE_HISTORY_REPORT_FAILURE";

export const PROCESSING_PERSON_REPORT_SUCCESS =
  "PROCESSING_PERSON_REPORT_SUCCESS";
export const PROCESSING_PERSON_REPORT_FAILURE =
  "PROCESSING_PERSON_REPORT_FAILURE";

export const CLIENT_SOURCE_REPORT_SUCCESS = "CLIENT_SOURCE_REPORT_SUCCESS";
export const CLIENT_SOURCE_REPORT_FAILURE = "CLIENT_SOURCE_REPORT_FAILURE";

export const TASK_REPORT_SUCCESS = "TASK_REPORT_SUCCESS";
export const TASK_REPORT_FAILURE = "TASK_REPORT_FAILURE";

export const BIRTHDAY_REPORT_SUCCESS = "BIRTHDAY_REPORT_SUCCESS";
export const BIRTHDAY_REPORT_FAILURE = "BIRTHDAY_REPORT_FAILURE";

export const FILE_NOTES_REPORT_SUCCESS = "FILE_NOTES_REPORT_SUCCESS";
export const FILE_NOTES_REPORT_FAILURE = "FILE_NOTES_REPORT_FAILURE";

export const ACTIVE_CASES_REPORT_SUCCESS = "ACTIVE_CASES_REPORT_SUCCESS";
export const ACTIVE_CASES_REPORT_FAILURE = "ACTIVE_CASES_REPORT_FAILURE";

export const VISA_EXPIRY_REPORT_SUCCESS = "VISA_EXPIRY_REPORT_SUCCESS";
export const VISA_EXPIRY_REPORT_FAILURE = "VISA_EXPIRY_REPORT_FAILURE";

export const CLIENT_EMPLOYER_REPORT_SUCCESS = "CLIENT_EMPLOYER_REPORT_SUCCESS";
export const CLIENT_EMPLOYER_REPORT_FAILURE = "CLIENT_EMPLOYER_REPORT_FAILURE";

export const DOC_CHECKLIST_REPORT_SUCCESS = "DOC_CHECKLIST_REPORT_SUCCESS";
export const DOC_CHECKLIST_REPORT_FAILURE = "DOC_CHECKLIST_REPORT_FAILURE";

export const SERVICE_MNGMNT_REPORT_SUCCESS = "SERVICE_MNGMNT_REPORT_SUCCESS";
export const SERVICE_MNGMNT_REPORT_FAILURE = "SERVICE_MNGMNT_REPORT_FAILURE";

export const EMPLOYER_MEDICAL_EXPIRY_SUCCESS =
  "EMPLOYER_MEDICAL_EXPIRY_SUCCESS";
export const EMPLOYER_MEDICAL_EXPIRY_FAILURE =
  "EMPLOYER_MEDICAL_EXPIRY_FAILURE";

export const POLICE_CERTIFICATE_EXPIRY_SUCCESS =
  "POLICE_CERTIFICATE_EXPIRY_SUCCESS";
export const POLICE_CERTIFICATE_EXPIRY_FAILURE =
  "POLICE_CERTIFICATE_EXPIRY_FAILURE";

export const EMPLOYER_PASSPORT_EXPIRY_SUCCESS =
  "EMPLOYER_PASSPORT_EXPIRY_SUCCESS";
export const EMPLOYER_PASSPORT_EXPIRY_FAILURE =
  "EMPLOYER_PASSPORT_EXPIRY_FAILURE";

export const TRAVEL_CONDITION_EXPIRY_SUCCESS =
  "TRAVEL_CONDITION_EXPIRY_SUCCESS";
export const TRAVEL_CONDITION_EXPIRY_FAILURE =
  "TRAVEL_CONDITION_EXPIRY_FAILURE";

export const UPDATE_POTENTIAL_EMPLOYER_SUCCESS =
  "UPDATE_POTENTIAL_EMPLOYER_SUCCESS";
export const UPDATE_POTENTIAL_EMPLOYER_FAILURE =
  "UPDATE_POTENTIAL_EMPLOYER_FAILURE";

export const GET_EMPLOYER_CASE_SUCCESS = "GET_EMPLOYER_CASE_SUCCESS";
export const GET_EMPLOYER_CASE_FAILURE = "GET_EMPLOYER_CASE_FAILURE";

export const P_SALE_HISTORY_REPORT_SUCCESS = "P_SALE_HISTORY_REPORT_SUCCESS";
export const P_SALE_HISTORY_REPORT_FAILURE = "P_SALE_HISTORY_REPORT_FAILURE";

export const P_CLIENT_SOURCE_REPORT_SUCCESS = "P_CLIENT_SOURCE_REPORT_SUCCESS";
export const P_CLIENT_SOURCE_REPORT_FAILURE = "P_CLIENT_SOURCE_REPORT_FAILURE";

export const P_PROCESSING_PERSON_REPORT_SUCCESS =
  "P_PROCESSING_PERSON_REPORT_SUCCESS";
export const P_PROCESSING_PERSON_REPORT_FAILURE =
  "P_PROCESSING_PERSON_REPORT_FAILURE";

export const P_FILE_NOTES_REPORT_SUCCESS = "P_FILE_NOTES_REPORT_SUCCESS";
export const P_FILE_NOTES_REPORT_FAILURE = "P_FILE_NOTES_REPORT_FAILURE";

export const POTENTIAL_CLIENT_STATUS_REPORT_SUCCESS =
  "POTENTIAL_CLIENT_STATUS_REPORT_SUCCESS";
export const POTENTIAL_CLIENT_STATUS_REPORT_FAILURE =
  "POTENTIAL_CLIENT_STATUS_REPORT_FAILURE";

export const POTENTIAL_CLIENT_FILE_NOTES_SUCCESS =
  "POTENTIAL_CLIENT_FILE_NOTES_SUCCESS";
export const POTENTIAL_CLIENT_FILE_NOTES_FAILURE =
  "POTENTIAL_CLIENT_FILE_NOTES_FAILURE";

export const DESTINATION_SUCCESS = "DESTINATION_SUCCESS";
export const DESTINATION_FAILURE = "DESTINATION_FAILURE";

export const VISA_REPORT_SUCCESS = "VISA_REPORT_SUCCESS";
export const VISA_REPORT_FAILURE = "VISA_REPORT_FAILURE";

export const TIME_TRACKING_REPORT_SUCCESS = "TIME_TRACKING_REPORT_SUCCESS";
export const TIME_TRACKING_REPORT_FAILURE = "TIME_TRACKING_REPORT_FAILURE";

export const GET_FILE_NOTES_REPORT_SUCCESS = "GET_FILE_NOTES_REPORT_SUCCESS";
export const GET_FILE_NOTES_REPORT_FAILURE = "GET_FILE_NOTES_REPORT_FAILURE";

/*////////////////
Dashboard Client 
////////////////*/

export const GET_CLIENT_SUMMARY_SUCCESS = "GET_CLIENT_SUMMARY_SUCCESS";
export const GET_CLIENT_SUMMARY_FAILURE = "GET_CLIENT_SUMMARY_FAILURE";

export const GET_CLIENT_SUMMARY_SETTINGS_SUCCESS =
  "GET_CLIENT_SUMMARY_SETTINGS_SUCCESS";
export const GET_CLIENT_SUMMARY_SETTINGS_FAILURE =
  "GET_CLIENT_SUMMARY_SETTINGS_FAILURE";

export const UPDATE_CLIENT_SUMMARY_SETTINGS_SUCCESS =
  "UPDATE_CLIENT_SUMMARY_SETTINGS_SUCCESS";
export const UPDATE_CLIENT_SUMMARY_SETTINGS_FAILURE =
  "UPDATE_CLIENT_SUMMARY_SETTINGS_FAILURE";

export const GET_PROCESSING_PERSONS_SUCCESS = "GET_PROCESSING_PERSONS_SUCCESS";
export const GET_PROCESSING_PERSONS_FAILURE = "GET_PROCESSING_PERSONS_FAILURE";

export const GET_PERMISSION_USER_SUCCESS = "GET_PERMISSION_USER_SUCCESS";
export const GET_PERMISSION_USER_FAILURE = "GET_PERMISSION_USER_FAILURE";

export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAILURE = "GET_COUNTRY_LIST_FAILURE";

export const UPDATE_CLIENT_PRIORITY_SUCCESS = "UPDATE_CLIENT_PRIORITY_SUCCESS";
export const UPDATE_CLIENT_PRIORITY_FAILURE = "UPDATE_CLIENT_PRIORITY_FAILURE";

export const UPDATE_CLIENT_CASE_PRIORITY_SUCCESS =
  "UPDATE_CLIENT_CASE_PRIORITY_SUCCESS";
export const UPDATE_CLIENT_CASE_PRIORITY_FAILURE =
  "UPDATE_CLIENT_CASE_PRIORITY_FAILURE";

export const GET_CLIENT_MAILS_SUCCESS = "GET_CLIENT_MAILS_SUCCESS";
export const GET_CLIENT_MAILS_FAILURE = "GET_CLIENT_MAILS_FAILURE";

export const ADD_CASE_TASKS_SUCCESS = "ADD_CASE_TASKS_SUCCESS";
export const ADD_CASE_TASKS_FAILURE = "ADD_CASE_TASKS_FAILURE";

export const GET_CASE_TASKS_SUCCESS = "GET_CASE_TASKS_SUCCESS";
export const GET_CASE_TASKS_FAILURE = "GET_CASE_TASKS_FAILURE";

export const GET_CLIENT_REMINDERS_SUCCESS = "GET_CLIENT_REMINDERS_SUCCESS";
export const GET_CLIENT_REMINDERS_FAILURE = "GET_CLIENT_REMINDERS_FAILURE";

export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS";
export const ADD_REMINDER_FAILURE = "ADD_REMINDER_FAILURE";

export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS";
export const UPDATE_REMINDER_FAILURE = "UPDATE_REMINDER_FAILURE";

export const UPDATE_REMINDER_DETAIL_SUCCESS = "UPDATE_REMINDER_DETAIL_SUCCESS";
export const UPDATE_REMINDER_DETAIL_FAILURE = "UPDATE_REMINDER_DETAIL_FAILURE";

export const GET_CLIENT_EMAIL_AND_PHONE_SUCCESS =
  "GET_CLIENT_EMAIL_AND_PHONE_SUCCESS";
export const GET_CLIENT_EMAIL_AND_PHONE_FAILURE =
  "GET_CLIENT_EMAIL_AND_PHONE_FAILURE";

export const GET_DASHBOARD_POTENTIALCLIENT_SUCCESS =
  "GET_DASHBOARD_POTENTIALCLIENT_SUCCESS";
export const GET_DASHBOARD_POTENTIALCLIENT_FAILURE =
  "GET_DASHBOARD_POTENTIALCLIENT_FAILURE";

export const GET_POTENTIALCLIENT_PENDING_TASKS_SUCCESS =
  "GET_POTENTIALCLIENT_PENDING_TASKS_SUCCESS";
export const GET_POTENTIALCLIENT_PENDING_TASKS_FAILURE =
  "GET_POTENTIALCLIENT_PENDING_TASKS_FAILURE";

export const GET_DASHBOARD_REMINDERS_SUCCESS =
  "GET_DASHBOARD_REMINDERS_SUCCESS";
export const GET_DASHBOARD_REMINDERS_FAILURE =
  "GET_DASHBOARD_REMINDERS_FAILURE";

export const UPDATE_DASHBOARD_REMINDER_STATUS_SUCCESS =
  "UPDATE_DASHBOARD_REMINDER_STATUS_SUCCESS";
export const UPDATE_DASHBOARD_REMINDER_STATUS_FAILURE =
  "UPDATE_DASHBOARD_REMINDER_STATUS_FAILURE";

export const GET_DASHBOARD_REMINDERS_TYPE_SUCCESS =
  "GET_DASHBOARD_REMINDERS_TYPE_SUCCESS";
export const GET_DASHBOARD_REMINDERS_TYPE_FAILURE =
  "GET_DASHBOARD_REMINDERS_TYPE_FAILURE";

export const GET_DASHBOARD_EMPLOYERS_SUCCESS =
  "GET_DASHBOARD_EMPLOYERS_SUCCESS";
export const GET_DASHBOARD_EMPLOYERS_FAILURE =
  "GET_DASHBOARD_EMPLOYERS_FAILURE";

export const GET_DASHBOARD_STUDENTS_SUCCESS = "GET_DASHBOARD_STUDENTS_SUCCESS";
export const GET_DASHBOARD_STUDENTS_FAILURE = "GET_DASHBOARD_STUDENTS_FAILURE";

/*////////////////
Dashboard Bi
////////////////*/
export const GET_CLIENT_ANALYTICS_SUCCESS = "GET_CLIENT_ANALYTICS_SUCCESS";
export const GET_CLIENT_ANALYTICS_FAILURE = "GET_CLIENT_ANALYTICS_FAILURE";

export const GET_TASK_ANALYTICS_SUCCESS = "GET_TASK_ANALYTICS_SUCCESS";
export const GET_TASK_ANALYTICS_FAILURE = "GET_TASK_ANALYTICS_FAILURE";

export const GET_ACCOUNTS_ANALYTICS_SUCCESS = "GET_ACCOUNTS_ANALYTICS_SUCCESS";
export const GET_ACCOUNTS_ANALYTICS_FAILURE = "GET_ACCOUNTS_ANALYTICS_FAILURE";

export const GET_VISA_ANALYTICS_SUCCESS = "GET_VISA_ANALYTICS_SUCCESS";
export const GET_VISA_ANALYTICS_FAILURE = "GET_VISA_ANALYTICS_FAILURE";

export const GET_IDLE_SINCE_ANALYTICS_SUCCESS =
  "GET_IDLE_SINCE_ANALYTICS_SUCCESS";
export const GET_IDLE_SINCE_ANALYTICS_FAILURE =
  "GET_IDLE_SINCE_ANALYTICS_FAILURE";

export const GET_ACTIVE_SINCE_ANALYTICS_SUCCESS =
  "GET_ACTIVE_SINCE_ANALYTICS_SUCCESS";
export const GET_ACTIVE_SINCE_ANALYTICS_FAILURE =
  "GET_ACTIVE_SINCE_ANALYTICS_FAILURE";

export const GET_VISA_STATUS_ANALYTICS_SUCCESS =
  "GET_VISA_STATUS_ANALYTICS_SUCCESS";
export const GET_VISA_STATUS_ANALYTICS_FAILURE =
  "GET_VISA_STATUS_ANALYTICS_FAILURE";

export const GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_SUCCESS =
  "GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_SUCCESS";
export const GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_FAILURE =
  "GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_FAILURE";
export const GET_CLIENT_ANALYTICS_DETAILS_SUCCESS =
  "GET_CLIENT_ANALYTICS_DETAILS_SUCCESS";
export const GET_CLIENT_ANALYTICS_DETAILS_FAILURE =
  "GET_CLIENT_ANALYTICS_DETAILS_FAILURE";

export const GET_VISA_ANALYTICS_CLIENT_DETAILS_SUCCESS =
  "GET_VISA_ANALYTICS_CLIENT_DETAILS_SUCCESS";
export const GET_VISA_ANALYTICS_CLIENT_DETAILS_FAILURE =
  "GET_VISA_ANALYTICS_CLIENT_DETAILS_FAILURE";

export const GET_INCOMING_ACCOUNTS_CLIENT_INFO_SUCCESS =
  "GET_INCOMING_ACCOUNTS_CLIENT_INFO_SUCCESS";
export const GET_INCOMING_ACCOUNTS_CLIENT_INFO_FAILURE =
  "GET_INCOMING_ACCOUNTS_CLIENT_INFO_FAILURE";

export const GET_OUTGOING_ACCOUNTS_CLIENT_INFO_SUCCESS =
  "GET_OUTGOING_ACCOUNTS_CLIENT_INFO_SUCCESS";
export const GET_OUTGOING_ACCOUNTS_CLIENT_INFO_FAILURE =
  "GET_OUTGOING_ACCOUNTS_CLIENT_INFO_FAILURE";

/*////////////////
Client Activity
////////////////*/

export const CLIENT_ACTIVITY_SUCCESS = "CLIENT_ACTIVITY_SUCCESS";
export const CLIENT_ACTIVITY_FAILURE = "CLIENT_ACTIVITY_FAILURE";

export const ADD_CLIENT_ACTIVITY_SUCCESS = "ADD_CLIENT_ACTIVITY_SUCCESS";
export const ADD_CLIENT_ACTIVITY_FAILURE = "ADD_CLIENT_ACTIVITY_FAILURE";

export const GET_USER_ACTIVITY_SUCCESS = "GET_USER_ACTIVITY_SUCCESS";
export const GET_USER_ACTIVITY_FAILURE = "GET_USER_ACTIVITY_FAILURE";

/*////////////////
Imap Mail
////////////////*/

export const SEARCH_IMAP_EMAIL_SUCCESS = "SEARCH_IMAP_EMAIL_SUCCESS";
export const SEARCH_IMAP_EMAIL_FAILURE = "SEARCH_IMAP_EMAIL_FAILURE";

export const EMPTY_IMAP_EMAIL_SUCCESS = "EMPTY_IMAP_EMAIL_SUCCESS";
export const EMPTY_IMAP_EMAIL_FAILURE = "EMPTY_IMAP_EMAIL_FAILURE";

export const GET_MAIL_BOX_SUCCESS = "GET_MAIL_BOX_SUCCESS";
export const GET_MAIL_BOX_FAILURE = "GET_MAIL_BOX_FAILURE";

export const MANUAL_IMPORT_EMAIL_SUCCESS = "MANUAL_IMPORT_EMAIL_SUCCESS";
export const MANUAL_IMPORT_EMAIL_FAILURE = "MANUAL_IMPORT_EMAIL_FAILURE";

export const MANUAL_IMPORT_POTENTIAL_EMAIL_SUCCESS =
  "MANUAL_IMPORT_POTENTIAL_EMAIL_SUCCESS";
export const MANUAL_IMPORT_POTENTIAL_EMAIL_FAILURE =
  "MANUAL_IMPORT_POTENTIAL_EMAIL_FAILURE";

export const MANUAL_IMPORT_EMPLOYER_EMAIL_SUCCESS =
  "MANUAL_IMPORT_EMPLOYER_EMAIL_SUCCESS";
export const MANUAL_IMPORT_EMPLOYER_EMAIL_FAILURE =
  "MANUAL_IMPORT_EMPLOYER_EMAIL_FAILURE";

export const AUTO_IMPORT_CLIENT_EMAIL_SUCCESS =
  "AUTO_IMPORT_CLIENT_EMAIL_SUCCESS";
export const AUTO_IMPORT_CLIENT_EMAIL_FAILURE =
  "AUTO_IMPORT_CLIENT_EMAIL_FAILURE";

export const GET_EMAIL_CLIENT_IMPORT_SUCCESS =
  "GET_EMAIL_CLIENT_IMPORT_SUCCESS";
export const GET_EMAIL_CLIENT_IMPORT_FAILURE =
  "GET_EMAIL_CLIENT_IMPORT_FAILURE";

export const GET_PDF_SUCCESS = "GET_PDF_SUCCESS";
export const GET_PDF_FAILURE = "GET_PDF_FAILURE";

export const LINK_EMAIL_SUCCESS = "LINK_EMAIL_SUCCESS";
export const LINK_EMAIL_FAILURE = "LINK_EMAIL_FAILURE";

export const GET_VISA_EMAIL_SUCCESS = "GET_VISA_EMAIL_SUCCESS";
export const GET_VISA_EMAIL_FAILURE = "GET_VISA_EMAIL_FAILURE";

export const GET_IMAP_FOR_ALL_SUCCESS = "GET_IMAP_FOR_ALL_SUCCESS";
export const GET_IMAP_FOR_ALL_FAILURE = "GET_IMAP_FOR_ALL_FAILURE";

export const UPDATE_IMAP_FOR_ALL_SUCCESS = "UPDATE_IMAP_FOR_ALL_SUCCESS";
export const UPDATE_IMAP_FOR_ALL_FAILURE = "UPDATE_IMAP_FOR_ALL_FAILURE";

export const SAVE_LINK_SUCCESS = "SAVE_LINK_SUCCESS";
export const SAVE_LINK_FAILURE = "SAVE_LINK_FAILURE";

//gmail

export const GET_GMAIL_SUCCESS = "GET_GMAIL_SUCCESS";
export const GET_GMAIL_FAILURE = "GET_GMAIL_FAILURE";

export const GET_GMAIL_LOGIN_SUCCESS = "GET_GMAIL_LOGIN_SUCCESS";
export const GET_GMAIL_LOGIN_FAILURE = "GET_GMAIL_LOGIN_FAILURE";

export const ADD_GMAIL_ACCOUNT_SUCCESS = "ADD_GMAIL_ACCOUNT_SUCCESS";
export const ADD_GMAIL_ACCOUNT_FAILURE = "ADD_GMAIL_ACCOUNT_FAILURE";

export const DELETE_GMAIL_ACCOUNT_SUCCESS = "DELETE_GMAIL_ACCOUNT_SUCCESS";
export const DELETE_GMAIL_ACCOUNT_FAILURE = "DELETE_GMAIL_ACCOUNT_FAILURE";

// Hubspot Integration

export const GET_HUBSPOT_TOKEN_SUCCESS = "GET_HUBSPOT_TOKEN_SUCCESS";
export const GET_HUBSPOT_TOKEN_FAILURE = "GET_HUBSPOT_TOKEN_FAILURE";

export const HUBSPOT_CONNECT_SUCCESS = "HUBSPOT_CONNECT_SUCCESS";
export const HUBSPOT_CONNECT_FAILURE = "HUBSPOT_CONNECT_FAILURE";

export const ADD_HUBSPOT_ACCOUNT_SUCCESS = "ADD_HUBSPOT_ACCOUNT_SUCCESS";
export const ADD_HUBSPOT_ACCOUNT_FAILURE = "ADD_HUBSPOT_ACCOUNT_FAILURE";

export const DELETE_HUBSPOT_ACCOUNT_SUCCESS = "DELETE_HUBSPOT_ACCOUNT_SUCCESS";
export const DELETE_HUBSPOT_ACCOUNT_FAILURE = "DELETE_HUBSPOT_ACCOUNT_FAILURE";

//outlook

export const GET_OUTLOOK_MAIL_SUCCESS = "GET_OUTLOOK_MAIL_SUCCESS";
export const GET_OUTLOOK_MAIL_FAILURE = "GET_OUTLOOK_MAIL_FAILURE";

export const GET_OUTLOOK_LOGIN_SUCCESS = "GET_OUTLOOK_LOGIN_SUCCESS";
export const GET_OUTLOOK_LOGIN_FAILURE = "GET_OUTLOOK_LOGIN_FAILURE";

export const ADD_OUTLOOK_ACCOUNT_SUCCESS = "ADD_OUTLOOK_ACCOUNT_SUCCESS";
export const ADD_OUTLOOK_ACCOUNT_FAILURE = "ADD_OUTLOOK_ACCOUNT_FAILURE";

export const DELETE_OUTLOOK_ACCOUNT_SUCCESS = "DELETE_OUTLOOK_ACCOUNT_SUCCESS";
export const DELETE_OUTLOOK_ACCOUNT_FAILURE = "DELETE_OUTLOOK_ACCOUNT_FAILURE";

export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILURE = "PASSWORD_RECOVERY_FAILURE";

export const SEND_FORGOT_SUCCESS = "SEND_FORGOT_SUCCESS";
export const SEND_FORGOT_FAILURE = "SEND_FORGOT_FAILURE";

export const SUPERVISOR_LOGIN_SUCCESS = "SUPERVISOR_LOGIN_SUCCESS";
export const SUPERVISOR_LOGIN_FAILURE = "SUPERVISOR_LOGIN_FAILURE";

export const GET_BATCHES_SUCCESS = "GET_BATCHES_SUCCESS";
export const GET_BATCHES_FAILURE = "GET_BATCHES_FAILURE";

export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAILURE = "CREATE_BATCH_FAILURE";

export const UPDATE_BATCH_SUCCESS = "UPDATE_BATCH_SUCCESS";
export const UPDATE_BATCH_FAILURE = "UPDATE_BATCH_FAILURE";

export const REMOVE_BATCH_SUCCESS = "REMOVE_BATCH_SUCCESS";
export const REMOVE_BATCH_FAILURE = "REMOVE_BATCH_FAILURE";

export const GET_BATCH_ACCOUNTS_SUCCESS = "GET_BATCH_ACCOUNTS_SUCCESS";
export const GET_BATCH_ACCOUNTS_FAILURE = "GET_BATCH_ACCOUNTS_FAILURE";

export const UPLOAD_BATCH_ACCOUNTS_SUCCESS = "UPLOAD_BATCH_ACCOUNTS_SUCCESS";
export const UPLOAD_BATCH_ACCOUNTS_FAILURE = "UPLOAD_BATCH_ACCOUNTS_FAILURE";

export const ADD_BATCH_ACCOUNT_SUCCESS = "ADD_BATCH_ACCOUNT_SUCCESS";
export const ADD_BATCH_ACCOUNT_FAILURE = "ADD_BATCH_ACCOUNT_FAILURE";

export const GET_IMPERSON_INFO_SUCCESS = "GET_IMPERSON_INFO_SUCCESS";
export const GET_IMPERSON_INFO_FAILURE = "GET_IMPERSON_INFO_FAILURE";

export const REMOVE_STUDENT_ACCOUNT_SUCCESS = "REMOVE_STUDENT_ACCOUNT_SUCCESS";
export const REMOVE_STUDENT_ACCOUNT_FAILURE = "REMOVE_STUDENT_ACCOUNT_FAILURE";

// DEALS
export const GET_BRANCH_PIPELINE_SUCCESS = "GET_BRANCH_PIPELINE_SUCCESS";
export const GET_BRANCH_PIPELINE_FAILURE = "GET_BRANCH_PIPELINE_FAILURE";

export const ADD_SALES_PIPELINE_SUCCESS = "ADD_SALES_PIPELINE_SUCCESS";
export const ADD_SALES_PIPELINE_FAILURE = "ADD_SALES_PIPELINE_FAILURE";

export const GET_SALES_PIPELINE_SUCCESS = "GET_SALES_PIPELINE_SUCCESS";
export const GET_SALES_PIPELINE_FAILURE = "GET_SALES_PIPELINE_FAILURE";

export const GET_BRANCH_VISA_COUNTRIES_SUCCESS =
  "GET_BRANCH_VISA_COUNTRIES_SUCCESS";
export const GET_BRANCH_VISA_COUNTRIES_FAILURE =
  "GET_BRANCH_VISA_COUNTRIES_FAILURE";

export const GET_VISA_WORK_TYPE_SUCCESS = "GET_VISA_WORK_TYPE_SUCCESS";
export const GET_VISA_WORK_TYPE_FAILURE = "GET_VISA_WORK_TYPE_FAILURE";

export const GET_VISA_TYPE_PRICE_SUCCESS = "GET_VISA_TYPE_PRICE_SUCCESS";
export const GET_VISA_TYPE_PRICE_FAILURE = "GET_VISA_TYPE_PRICE_FAILURE";

export const GET_SERVICE_TYPE_SUCCESS = "GET_SERVICE_TYPE_SUCCESS";
export const GET_SERVICE_TYPE_FAILURE = "GET_SERVICE_TYPE_FAILURE";

export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
export const GET_AGENTS_FAILURE = "GET_AGENTS_FAILURE";
