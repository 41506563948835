import React, { useEffect } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
// import PinIcon from './../../statics/images/pin.svg';
// import PhoneIcon from './../../statics/images/phone.svg';
// import EmailIcon from './../../statics/images/email.svg';
import { adressIcon, phoneIcon, contactMailIcon } from "../../../Themes/icons";
import { Images } from "../../../Themes";

const ContactUs = ({ branchData, stateData }) => {
  return (
    <div className="container" style={{ margin: "auto", paddingBottom: 0 }}>
      <div className="contact-info-sec">
        <div className="footer-logo">
          <img
            src={branchData.branchData.logo}
            alt="EZY Migration"
            style={{ maxWidth: "80%", height: 100 }}
          />
          <p>{branchData.branchData.name}</p>
          {/* <p>EZYMIGRATE</p> */}
        </div>
        <div className="footer-info-text">
          <div className="footer-info-box">
            <div className="contact-us-icon">{adressIcon}</div>
            <h4>
              <span>Address</span>
            </h4>
            <p>{`${branchData.branchData.address}${
              branchData.branchData.address && branchData.branchData.city
                ? ","
                : ""
            } `}</p>
            <p>{`${branchData.branchData.city}${
              branchData.branchData.city || branchData.branchData.country
                ? ","
                : ""
            } ${branchData.branchData.country}`}</p>
          </div>
          <div className="footer-info-box">
            <div className="contact-us-icon">{phoneIcon}</div>

            <h4>
              <span>Phone</span>
            </h4>
            <p>{branchData.branchData.phone1}</p>
          </div>
          <div className="footer-info-box">
            <div className="contact-us-icon">{contactMailIcon}</div>
            <h4>
              <span>Email</span>
            </h4>
            <p>
              {branchData.stateData.clientIdState ==
                "00000000-0000-0000-0000-000000000000" &&
              branchData.stateData.quesEmail
                ? branchData.stateData.quesEmail
                : branchData.branchData.email}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
