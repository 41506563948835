import {
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Tooltip, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import Moment from "moment";
import TimeCounter from "../TimeTracking/TimeCounter";
import { Images } from "../../Themes";
import add from "../../images/add.png";

function ClientTasks({
  item,
  index,
  completeTask,
  revertCompletedTask,
  setSelectedTask,
  setCommentModal,
  getComments,
  getFollower,
  onPlay,
  onPause,
  onStop,
  setTitleModal,
  setFileNoteModal,
  setTitle,
  setFileNoteValue,
}) {
  const [seconds, setSeconds] = useState();

  return (
    <div key={index}>
      <div className="taskListUI">
        <div
          style={{
            // display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <div title="Complete/Revert">
                <Checkbox
                  checked={item.isCompleted}
                  disabled={
                    item.status === 0 || item.status === 3 ? false : true
                  }
                  onChange={() => {
                    if (item.isCompleted) revertCompletedTask(item);
                    else completeTask(item);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedTask(item);
                  setCommentModal(true);
                  getComments(item);
                  getFollower(item);
                }}
              >
                <span
                  style={{
                    color: "#1281BC",
                    fontSize: "14px",
                    fontWeight: "500",
                    wordBreak: "break-all",
                  }}
                >
                  {item && item.taskTitle}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <TimeCounter
              startTime={item.totalTime}
              status={item.status}
              index={index}
            />
            <div style={{ display: "flex" }}>
              {item.isRead && item.status !== 3 && (
                <div>
                  {(item.status == 0 || item.status == 2) && (
                    <Tooltip
                      title="Time Start"
                      color="#eae9d4"
                      overlayInnerStyle={{ color: "black" }}
                    >
                      <PlayCircleOutlined
                        style={{
                          color: "#4FDE56",
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => onPlay(item.id)}
                      />
                    </Tooltip>
                  )}
                  {item.status == 1 && (
                    <Tooltip
                      title="Time Pause"
                      color="#eae9d4"
                      overlayInnerStyle={{ color: "black" }}
                    >
                      <PauseCircleOutlined
                        style={{
                          color: "#F9A32E",
                          fontSize: 20,
                          marginLeft: 3,
                          cursor: "pointer",
                        }}
                        onClick={() => onPause(item.id)}
                      />
                    </Tooltip>
                  )}
                  {item.status == 2 && (
                    <Tooltip
                      title="Time Stop"
                      color="#eae9d4"
                      overlayInnerStyle={{ color: "black" }}
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "#E7543A",
                          fontSize: 20,
                          marginLeft: 3,
                          cursor: "pointer",
                          pointerEvents: item.status !== 3 ? "auto" : "none",
                          opacity: item.status !== 3 ? 1.0 : 0.7,
                        }}
                        onClick={() => onStop(item)}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <span>({Moment(item && item.taskDate).format("DD/MM/YYYY")})</span>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <div
                className="mdtr-user-cont"
                style={{
                  backgroundColor: "#FFFFFF",
                  marginLeft: 5,
                }}
                title="Update Task Title"
              >
                <img
                  onClick={() => {
                    setTitle(item.taskTitle);
                    setSelectedTask(item);
                    setTitleModal(true);
                  }}
                  src={Images.fileNotes}
                  style={{ width: 15, height: 15 }}
                />
              </div>
              <div
                className="mdtr-user-cont"
                style={{
                  backgroundColor: "#FFFFFF",
                  marginLeft: 5,
                }}
                title="Add To File Note"
              >
                <img
                  onClick={() => {
                    // addTaskFileNote(item);
                    setSelectedTask(item);
                    setFileNoteModal(true);
                    setFileNoteValue(item);
                  }}
                  src={add}
                  style={{ width: 15, height: 15 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientTasks;
