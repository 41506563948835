import { api } from "../../services/api";
import * as types from "../Constants";

export const getTeamMember = () => {
  const options = {
    url: `v1/users/ddl/All`,
  };

  options.types = [types.TEAM_MEMBER_SUCCESS, types.TEAM_MEMBER_FAILURE];

  return api.get(options);
};

export const getTeamMemberObj = () => {
  const options = {
    url: "v1/users/All",
  };

  options.types = [
    types.TEAM_MEMBER_SUCCESS_OBJ,
    types.TEAM_MEMBER_FAILURE_OBJ,
  ];

  return api.get(options);
};

export const addTeamMember = (data) => {
  const options = {
    url: "v1/users",
  };

  options.types = [
    types.ADD_TEAM_MEMBER_SUCCESS,
    types.ADD_TEAM_MEMBER_FAILURE,
  ];

  return api.post(options, data);
};

export const addUserPassword = (data) => {
  const options = {
    url: "v1/user/identity/CreatePassword",
  };

  options.types = [types.ADD_PASSWORD_SUCCESS, types.ADD_PASSWORD_FAILURE];

  return api.post(options, data);
};

export const assignBranch = (data) => {
  const options = {
    url: "v1/user/Branch",
  };

  options.types = [types.ASSIGN_BRANCH_SUCCESS, types.ASSIGN_BRANCH_SUCCESS];

  return api.post(options, data);
};

export const updAssignedBranch = (data) => {
  const options = {
    url: "v1/user/Branch",
  };

  options.types = [
    types.UPDATE_ASSIGN_BRANCH_SUCCESS,
    types.UPDATE_ASSIGN_BRANCH_SUCCESS,
  ];

  return api.put(options, data);
};

export const unassignBranch = (data) => {
  const options = {
    url: "v1/user/Branch",
  };

  options.types = [
    types.UNASSIGN_BRANCH_SUCCESS,
    types.UNASSIGN_BRANCH_SUCCESS,
  ];

  return api.put(options, data);
};

export const addAgent = (data) => {
  const options = {
    url: "v1/agent",
  };

  options.types = [types.ADD_AGENT_SUCCESS, types.ADD_AGENT_FAILURE];

  return api.post(options, data);
};
