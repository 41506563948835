import React from "react";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import { getIncomingOutgoingPayments, setActiveKey } from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Images } from "../Themes";
import { apiRefresh } from "../services/api";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class AgentCommission extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    super(props);
    this.state = {
      showForm: "listing",
      accountsFlowListing: [],
      date: null,
      loading: true,
      clientprofileid: clientprofileid,
      totalIncoming: 0,
      totalOutgoing: 0,
      title: "",
      description: "",
      commission: 0,
      schPaymentList: [],
      visible: false,
      commissionId: 0,
      amount: 0,
      pmtDate: null,
      pmtDescription: "",
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: "100px",
          render: (text, record) => {
            return (
              <div>
                <span>{moment(text).format("DD/MM/YYYY")}</span>
              </div>
            );
          },
        },
        {
          title: "Due",
          dataIndex: "commission",
          key: "commission",
          width: "100px",
          render: (text, record) => {
            return (
              <div>
                <span>{text - record.paid}</span>
              </div>
            );
          },
        },
        {
          title: "Paid",
          dataIndex: "paid",
          key: "paid",
          width: "100px",
        },
        {
          title: "#",
          dataIndex: "",
          key: "",
          width: "30px",
          render: (text, record) => {
            return (
              <>
                {record.commission > record.paid ? (
                  <div
                    className="ca-show-add-cont"
                    style={{
                      paddingTop: 6,
                      paddingBottom: 6,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        commissionId: record.id,
                        amount: record.commission - record.paid,
                      });
                    }}
                  >
                    <span className="ca-show-add-text" style={{ fontSize: 8 }}>
                      PAY NOW
                    </span>
                  </div>
                ) : (
                  <div className="ca-green-cont">
                    <span className="ca-colored-box-text">Paid</span>
                  </div>
                )}
              </>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    // this.setState({ loading: true });
    // this.props.getIncomingOutgoingPayments(this.state).then(() => {
    //   this.setState({ loading: false });
    // });
    this.getScheduleCommissions();
  }

  getScheduleCommissions = () => {
    let clientId = sessionStorage.getItem("clientprofileid");
    let agentId = sessionStorage.getItem("agentId");
    const getSchComOpt = {
      url: `v1/agent/commission/GetAllCommissions/${agentId}/${clientId}`,
    };
    getSchComOpt.types = [
      "GET_AGENT_CLIENTS_SUCCESS",
      "GET_AGENT_CLIENTS_FAILURE",
    ];

    if (agentId && agentId != "00000000-0000-0000-0000-000000000000") {
      this.setState({ loading: true });
      apiRefresh
        .get(getSchComOpt)
        .then((res) => {
          this.setState({ schPaymentList: res.items, loading: false });
        })
        .catch((err) => {
          this.setState({ schPaymentList: [], loading: false });
        });
    } else {
      this.setState({ loading: false });
      message.warning("Please select Agent from profile!");
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    let _accountsFlowListing = nextProps && nextProps.accountsFlowListing;
    let _totalIncoming = 0;
    let _totalOutgoing = 0;
    if (_accountsFlowListing && _accountsFlowListing.length > 0) {
      for (let index = 0; index < _accountsFlowListing.length; index++) {
        if (_accountsFlowListing[index].type === "incoming") {
          _totalIncoming = _totalIncoming + _accountsFlowListing[index].amount;
        } else if (_accountsFlowListing[index].type === "outgoing") {
          _totalOutgoing = _totalOutgoing + _accountsFlowListing[index].amount;
        } else {
          //do-nothing
        }
      }
    }

    this.setState({
      accountsFlowListing: _accountsFlowListing,
      totalIncoming: _totalIncoming,
      totalOutgoing: _totalOutgoing,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  show = () => {
    if (
      this.state.dateFrom === "1900-01-01T00:00:00+00" ||
      this.state.dateFrom === "Invalid date"
    ) {
      message.error("Please select date from!");
    } else if (
      this.state.dateTo === "1900-01-01T00:00:00+00" ||
      this.state.dateTo === "Invalid date"
    ) {
      message.error("Please select date to!");
    } else {
      this.props.getIncomingOutgoingPayments(this.state).then(() => {});
    }
  };

  showAll = () => {
    this.setState(
      {
        loading: true,
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
      },
      () => {
        this.props.getIncomingOutgoingPayments(this.state).then(() => {
          this.setState({
            loading: false,
            dateFrom: "1900-01-01T00:00:00+00",
            dateTo: "1900-01-01T00:00:00+00",
          });
        });
      }
    );
  };

  onSavePaymentSchedule = () => {
    let data = {
      agentId: sessionStorage.getItem("agentId"),
      clientId: sessionStorage.getItem("clientprofileid"),
      date: this.state.date || "1900-01-01T00:00:00+00",
      description: this.state.description || "",
      commission: parseInt(this.state.commission) || 0,
    };

    const addSchComOpt = {
      url: `v1/agent/commission`,
    };
    addSchComOpt.types = [
      "ADD_PAYMENT_SCHEDULE_SUCCESS",
      "ADD_PAYMENT_SCHEDULE_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .post(addSchComOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ description: "", commission: 0, date: null });
        this.getScheduleCommissions();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onSavePayments = () => {
    let data = {
      commissionId: this.state.commissionId,
      date: this.state.pmtDate || "1900-01-01T00:00:00Z",
      description: this.state.pmtDescription,
      amount: this.state.amount ? parseInt(this.state.amount) : 0,
    };

    const addSchComOpt = {
      url: `v1/agent/payment`,
    };
    this.setState({ loading: true });
    addSchComOpt.types = ["ADD_PAYMENT_SUCCESS", "ADD_PAYMENT_FAILURE"];
    apiRefresh
      .post(addSchComOpt, data)
      .then((res) => {
        this.setState({
          pmtDate: null,
          pmtDescription: "",
          amount: 0,
          visible: false,
          loading: false,
        });
        this.getScheduleCommissions();
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getListingView = () => {
    return (
      <div>
        <div>
          <div style={{ marginTop: 5, marginLeft: 30 }}>
            <span className="ca-form-a-label">SCHEDULED PAYMENTS</span>
          </div>
          <div className="ca-gray-cont" style={{ padding: 20 }}>
            <div style={{ display: "flex", marginTop: 10 }}>
              <p class="ca-form-label">DATE</p>
              <div
                class="profile-input-border"
                style={{
                  display: "flex",
                  border: "none",
                  width: "47%",
                }}
              >
                <DatePicker
                  onChange={(date) => this.setState({ date: date })}
                  value={this.state.date}
                  format="DD/MM/YYYY"
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <p class="ca-form-label">AMOUNT</p>
              <div class="profile-input-border" style={{ width: "47%" }}>
                <Input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  value={this.state.commission}
                  onChange={(e) =>
                    this.setState({ commission: e.target.value })
                  }
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <p class="ca-form-label">DESCRIPTION</p>
              <div class="profile-input-border" style={{ width: "47%" }}>
                <Input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="ca-add-btn-row">
              <div></div>
              {/* <div
                className="ca-add-btn-cont"
                style={{ cursor: "pointer" }}
                onClick={this.onSavePaymentSchedule}
              >
                <span className="ca-show-add-text">ADD</span>
              </div> */}
              <Button
                className="sus-modal-button button-blue"
                onClick={this.onSavePaymentSchedule}
                disabled={
                  sessionStorage.getItem("agentId") &&
                  sessionStorage.getItem("agentId") !=
                    "00000000-0000-0000-0000-000000000000"
                    ? false
                    : true
                }
              >
                ADD
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 5, marginLeft: 30 }}>
            <span className="ca-form-a-label">PAYMENT PLAN</span>
          </div>
          <div className="ca-agent-table-cont">
            <Table
              dataSource={this.state.schPaymentList}
              rowClassName={(record, index) => "invoices-children-table-row"}
              columns={this.state.columns}
              bordered={false}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  };

  closeModal = () => {
    this.setState({
      visible: false,
      pmtDescription: "",
      pmtDate: null,
      amount: 0,
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <Spin spinning={loading} size="large">
          <div>{this.getListingView()}</div>
        </Spin>
        <Modal
          visible={this.state.visible}
          // width="500"
          // height="550"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
          title="Add Payment"
          footer={false}
        >
          <div>
            <div className="ca-gray-cont" style={{ padding: 20 }}>
              <div style={{ display: "flex", marginTop: 10 }}>
                <p class="ca-form-label">AMOUNT</p>
                <div class="profile-input-border" style={{ width: "47%" }}>
                  <Input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={this.state.amount}
                    onChange={(e) => this.setState({ amount: e.target.value })}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <p class="ca-form-label">DATE</p>
                <div
                  class="profile-input-border"
                  style={{
                    display: "flex",
                    border: "none",
                    width: "47%",
                  }}
                >
                  <DatePicker
                    onChange={(date) => this.setState({ pmtDate: date })}
                    value={this.state.pmtDate}
                    format="DD/MM/YYYY"
                  />
                </div>
              </div>

              <div style={{ display: "flex", marginTop: 10 }}>
                <p class="ca-form-label">DESCRIPTION</p>
                <div class="profile-input-border" style={{ width: "47%" }}>
                  <Input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={this.state.pmtDescription}
                    onChange={(e) =>
                      this.setState({ pmtDescription: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <div></div>
              <div style={{ display: "flex" }}>
                <Button
                  className="sus-modal-button button-blue"
                  onClick={this.onSavePayments}
                  disabled={sessionStorage.getItem("agentId") ? false : true}
                >
                  ADD
                </Button>
                <div
                  onClick={this.closeModal}
                  className="sus-modal-button"
                  style={{ marginLeft: 10 }}
                >
                  <span className="sus-modal-button-text">CLOSE</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.accountReducer &&
    state.accountReducer.accountsFlowListing &&
    state.accountReducer.accountsFlowListing;

  return {
    accountsFlowListing:
      state &&
      state.accountReducer &&
      state.accountReducer.accountsFlowListing &&
      state.accountReducer.accountsFlowListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getIncomingOutgoingPayments: bindActionCreators(
    getIncomingOutgoingPayments,
    dispatch
  ),
});
AgentCommission = connect(mapStateToProps, mapDispatchToProps)(AgentCommission);

export default withRouter(AgentCommission);
