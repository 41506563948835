import React, { Fragment, useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { yyyymmddFormate } from "../../../../Common/reUseFunction";
import { Link } from "react-router-dom";

const ClientVisaReport = ({
  onGetSingleClientHis,
  singleJobHisRes,
  isjobId,
}) => {
  const [loading, setLoading] = useState(false);
  const [singleCall, setSingleCall] = useState(false);
  const [visaList, setVisaList] = useState([]);

  useEffect(() => {
    letterTempaltesFilter(singleJobHisRes);
  }, [singleJobHisRes]);

  const letterTempaltesFilter = (singleJobHisRes) => {
    let filterList = [];
    if (
      singleJobHisRes &&
      singleJobHisRes.items &&
      singleJobHisRes.items.length > 0
    ) {
      singleJobHisRes.items.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
    }
    setVisaList(filterList);
  };
  // useEffect(() => {
  //   if (!singleCall) {
  //     setLoading(true);
  //
  //     const data = {
  //       jobId: isjobId && isjobId
  //     };
  //
  //     setSingleCall(true);
  //     onGetSingleClientHis(data)
  //       .then(() => {
  //         setLoading(false);
  //       })
  //       .catch(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [isjobId, onGetSingleClientHis, singleCall, singleJobHisRes]);

  // const columns = [
  //   {
  //     title: "Status",
  //     key: "Status",
  //     dataIndex: "Status",
  //     render: (text, record) => {
  //       return (
  //         <div className="table-action">
  //           <div>
  //             <span className="acc-actions-button">
  //               {record &&
  //               record.clientPermission &&
  //               record.clientPermission.active === true ? (
  //                 <span className="sent-email">Active</span>
  //               ) : (
  //                 <span className="draft">Inactive</span>
  //               )}
  //             </span>
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "firstName",
  //     key: "firstName",
  //     render: (text, record) => {
  //       return (
  //         <Link
  //           to={{
  //             pathname:
  //               record.memberType.toLowerCase() === "client"
  //                 ? "/profile"
  //                 : record.memberType.toLowerCase() === "spouse"
  //                 ? "/partner-profile"
  //                 : "/client-family-member",
  //             state: { id: record.clientId, familyId: record.familyId },
  //           }}
  //           className="hover-link"
  //           style={{
  //             fontSize: "12px",
  //             fontWeight: "initial",
  //             fontFamily: "Poppins, sans-serif",
  //           }}
  //         >
  //           {`${record.firstName} ${record.lastName}`}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     key: "email",
  //     render: (text, record) => (
  //       <p
  //         style={{
  //           color: "black",
  //           fontSize: "12px",
  //           fontWeight: "initial",
  //           fontFamily: "Poppins, sans-serif",
  //           textDecoration: "none !important",
  //           cursor: "pointer !important",
  //         }}
  //       >
  //         {record.email}
  //       </p>
  //     ),
  //   },
  //   {
  //     title: "DOB",
  //     key: "DOB",
  //     render: (text, record) => {
  //       return (
  //         <div className="table-action">
  //           <span
  //             style={{
  //               color: "black",
  //               fontSize: "12px",
  //               fontWeight: "initial",
  //               fontFamily: "Poppins, sans-serif",
  //               textDecoration: "none !important",
  //               cursor: "pointer !important",
  //             }}
  //           >
  //             {record && yyyymmddFormate(record.dateOfBirth)}
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Country",
  //     dataIndex: "visaCountryName",
  //     key: "visaCountryName",
  //     render: (text, record) => {
  //       const arr = record.visaCountryName.split(" ");
  //       for (let i = 0; i < arr.length; i++) {
  //         arr[i] =
  //           arr[i].charAt(0).toUpperCase() +
  //           arr[i].slice(1).toLowerCase();
  //       }
  //       const strVisaCountryName = arr.join(" ");
  //       return (
  //         <Link
  //           style={{
  //             color: "black",
  //             fontSize: "12px",
  //             fontWeight: "initial",
  //             fontFamily: "Poppins, sans-serif",
  //             textDecoration: "none !important",
  //             cursor: "pointer !important",
  //           }}
  //         >
  //           {strVisaCountryName}
  //         </Link>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      title: "Sn",
      dataIndex: "",
      key: "",
      width: "9.0909%",
      render: (text, record, index) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {index + 1}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "branchVisaTypeName",
      key: "branchVisaTypeName",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.branchVisaTypeName}
        </p>
      ),
    },
    // {
    //   title: "INZ Client Number",
    //   dataIndex: "clientNumber",
    //   key: "clientNumber",
    //   width: "9.0909%",
    //   render: (text, record) => (
    //     <p
    //       style={{
    //         color: "black",
    //         fontSize: "12px",
    //         fontWeight: "initial",
    //         fontFamily: "Poppins, sans-serif",
    //       }}
    //     >
    //       {record.clientNumber}
    //     </p>
    //   ),
    // },
    {
      title: "Visa Type",
      dataIndex: "visaTypeName",
      key: "visaTypeName",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.visaTypeName}
        </p>
      ),
    },
    {
      title: "Visa Status",
      dataIndex: "visaStatusName",
      key: "visaStatusName",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.visaStatusName}
        </p>
      ),
    },
    {
      title: "Visa Modified Date",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      width: "9.0909%",
      render: (text, record) => {
        var date =
          record.caseHistory.length > 0
            ? record.caseHistory[0].modifiedDate
            : "1900-01-01T00:00:00+00:00";
        return (
          <p
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {date === "1900-01-01T00:00:00+00:00" ||
            date === "0001-01-01T00:00:00+00:00"
              ? ""
              : record && yyyymmddFormate(date)}
          </p>
        );
      },
    },
    {
      title: "Visa Expiring",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.expiryDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : yyyymmddFormate(record.expiryDate)}
        </p>
      ),
    },
    {
      title: "Medical Certificate Issue Date",
      dataIndex: "medicalIssueDate",
      key: "medicalIssueDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record && record.medicalIssueDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.medicalIssueDate)}
        </p>
      ),
    },
    {
      title: "Police Certificate Date",
      dataIndex: "policeCertificateDate",
      key: "policeCertificateDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record &&
          record.policeCertificateDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.policeCertificateDate)}
        </p>
      ),
    },
    {
      title: "Xray Issue Date",
      dataIndex: "xRayDate",
      key: "xRayDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record && record.xRayDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.xRayDate)}
        </p>
      ),
    },
  ];

  return (
    <Fragment>
      <Spin spinning={loading} size="default">
        <div className="single-job">
          <Table
            className="ant-table-visa-white-view job-table table-head employer-tables border-3 school-table"
            bordered
            dataSource={visaList}
            columns={columns}
          />
        </div>
      </Spin>
    </Fragment>
  );
};
export default ClientVisaReport;
