import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, message, Spin } from "antd";
import { Images } from "./../../Themes";
import FroalaEditor from "react-froala-wysiwyg";
import FileNotesList from "./FileNotesList";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { apiRefresh } from "../../services/api";

const { Option } = Select;

const FileNotes = ({
  onGetEmployerFile,
  employerFileRes,
  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  potentialClientInfo,

  onRemoveEmployerFile,

  onUpdateEmployerFile,

  onGetClientFile,
  employerDocumentRes,

  onAddClientFile,

  onUpdateClientFile,

  onDeleteClientFile,

  clientFileRes,
  onGetAllUsers,
  onAddDailyTasks,
  users,
}) => {
  let userId = localStorage.getItem("userId");

  const [editorData, setEditorData] = useState("");
  const [dataSource, setDataSource] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileNoteRes, setFileNoteRes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [fileNotesCount, setFileNotesCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    setLoading(true);
    letterTempaltesFilter(LetterTemplatesRes);
    setLoading(false);
  }, [LetterTemplatesRes]);

  useEffect(() => {
    onGetLetterTemplates();
  }, [onGetLetterTemplates]);

  // useEffect(() => {
  //     const usersInfo = users && users.map((item) => {
  //       return {
  //         label: item.fullName,
  //         value: item.id,
  //       };
  //     });
  //     setUsersList(usersInfo);
  // }, [users]);

  useEffect(() => {
    setLoading(true);

    getFileNotes();
    setLoading(false);
  }, [onGetClientFile, potentialClientInfo]);

  const getFileNotes = (payloadData) => {
    setLoading(true);
    let pageNumb =
      payloadData && payloadData.pageNumber
        ? payloadData.pageNumber
        : pageNumber;
    let pageSizee =
      payloadData && payloadData.pageSize ? payloadData.pageSize : pageSize;
    const fileNotesOpt = {
      url: `v1/client/filenote/All/${potentialClientInfo.id}/${pageNumb}/${pageSizee}`,
    };
    fileNotesOpt.types = ["GET_FILE_NOTE_SUCCESS", "GET_FILE_NOTE_FAILURE"];

    apiRefresh.get(fileNotesOpt).then((res) => {
      if (res && res.items) {
        res.items = JSON.parse(res.items);
        if (res && res.items && res.items.length > 0) {
          setFileNoteRes(
            payloadData && payloadData.pageNumber
              ? res.items.concat(fileNoteRes)
              : fileNoteRes.concat(res.items)
          );
          setFileNotesCount(res.count);
          setPageNumber(
            payloadData && payloadData.pageNumber ? pageNumber : pageNumber + 1
          );
          setLoading(false);
        } else {
          setFileNoteRes([]);
          setFileNotesCount(0);
          setLoading(false);
        }
      }
      setLoading(false);
    });
  };

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "FILENOTE") {
          filterList.push(data);
        }
      });
      setDataSource(filterList);
    }
  };

  const handleData = (model) => {
    setEditorData(model);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (editorData && editorData.length > 0) {
      setLoading(true);
      console.log("Received values of form:", values);

      const data = {
        clientId: potentialClientInfo && potentialClientInfo.id,
        fileNote: editorData && editorData,
      };

      onAddClientFile(data)
        .then(() => {
          getFileNotes({ pageNumber: 1, pageSize: 1 });
          onGetClientFile(potentialClientInfo && potentialClientInfo.id);
          handleCancel();
          setLoading(false);
          message.success("Successfully Added!");
          setEditorData("");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      message.warning("Add some text first");
    }
  };

  const handeFileDropdown = (id) => {
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = ["GET_CONTRACT_SUCCESS", "GET_CONTRACT_FAILURE"];
    apiRefresh.get(getContractOpt).then((resp) => {
      setEditorData(resp.content);
    });
  };

  const onUdateFileNoteState = (isDelete, index, fileNoteData) => {
    let fileNotes = [...fileNoteRes];
    if (isDelete) {
      fileNotes.splice(index, 1);
    } else {
      fileNotes[index].FileNote = fileNoteData;
    }
    setFileNoteRes(fileNotes);
  };
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <div style={{ width: "98%", margin: "auto" }}>
            <div className="d-flex align-center  space-between">
              <Row>
                <Col>
                  <Form.Item>
                    <Select
                      style={{ width: "246px" }}
                      mode="single"
                      showArrow
                      checked={false}
                      onChange={(value) => handeFileDropdown(value)}
                      placeholder="Templates"
                    >
                      {dataSource &&
                        dataSource.length > 0 &&
                        dataSource.map((item, index) => (
                          <Option key={item.id}> {item.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col className="profile-print-box">
                <img src={Images.printWhite} className="profile-print-icon" />
              </Col>
            </div>
            <Row>
              <Col span={24}>
                <div className=" margin-top  letter-froala">
                  <FroalaEditorCom
                    setLoading={(value) => setLoading(value)}
                    model={editorData}
                    onModelChange={handleData}
                  />
                </div>
              </Col>
            </Row>

            <Form.Item className="d-flex">
              <Button
                style={{ marginTop: "12px" }}
                className="employer-btn"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
            {fileNoteRes &&
              fileNoteRes.map((item, index) => (
                <FileNotesList
                  item={item}
                  index={index}
                  onDeleteClientFile={onDeleteClientFile}
                  onGetEmployerFile={onGetEmployerFile}
                  potentialClientInfo={potentialClientInfo}
                  onUpdateClientFile={onUpdateClientFile}
                  setLoading={setLoading}
                  loading={loading}
                  onGetClientFile={onGetClientFile}
                  employerDocumentRes={employerDocumentRes}
                  users={users}
                  onAddDailyTasks={onAddDailyTasks}
                  updateFileNoteState={onUdateFileNoteState}
                />
              ))}
            {fileNotesCount > 5 && fileNoteRes.length !== fileNotesCount && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span
                  style={{
                    color: "#56B1F9",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={getFileNotes}
                >
                  Show More
                </span>
              </div>
            )}
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default FileNotes;
