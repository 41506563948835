import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Select, Spin, message } from "antd";
import "./comment.css";
import activityData from "../../Components/ClientActivity/ActivityData";
import moment from "moment";

const AddFollowerModal = ({
  dailyTaskRes,

  onUpdetaTasks,

  updateTitle,
  updateData,
  getAllUsers,
  addTaskFollower,
  users,
  handleCancel,
  reloadFlag,
  setReload,
}) => {
  const [loading, setLoading] = useState(false);
  // const [users, setUsers] = useState([]);
  const formRef = useRef();

  // const UserLinks = () => {
  //   getAllUsers();
  // };

  // useEffect(() => {
  //   getAllUsers().then((res) => {
  //     const usersInfo = res.payload.users.map((item) => {
  //       return {
  //         label: item.fullName,
  //         value: item.id,
  //       };
  //     });
  //     setUsers(usersInfo);
  //   });
  // });
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    let payload = {
      taskId: updateTitle,
      userId: values.add_follower,
    };
    let followerName = users.find((obj) => obj.value == values.add_follower);
    addTaskFollower(payload)
      .then((res) => {
        setLoading(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        handleCancel();
        if (updateData) {
          let userName = localStorage.getItem("userName");
          let myData = {
            clientName: updateData.clientName,
            logMessage:
              "\nFollower " +
              followerName.label +
              " added for task " +
              updateData.taskTitle +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Daily Tasks/Reminders",
            invoiceId: "0",
            notClientPart: true,
            linkedClient: updateData.subjectId,
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Error in Adding Followers!");
      });
  };

  return (
    <Spin size="large" spinning={loading}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        ref={formRef}
      >
        <div className="mdtr-modal-gray-cont">
          <div className="modal-parts">
            <Form.Item
              className="add-reminder-form"
              label="Add Follower"
              name="add_follower"
              required={true}
              rules={[{ required: true, message: "Required!" }]}
            >
              <Select
                // onSearch={() => UserLinks()}
                showSearch
                options={users}
                style={{ width: 200 }}
                placeholder="Search to Select Follower"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" className="task-blue" htmlType="submit">
            ADD FOLLOWER
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddFollowerModal;
