import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReminderSetting from "../AccountSetting/EmailContent/EmailContent";

import {
  getEmailContent,
  addEmailContent,
  updateEmailContent,
  setActiveInnerTab, removeEmailContent
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    emailContentRes: state && state.accountSetReducer && state.accountSetReducer.emailContentRes && state.accountSetReducer.emailContentRes,
    addEmailContentRes: state.accountSetReducer.addEmailContentRes,
    updateEmailContentRes: state.accountSetReducer.updateEmailContentRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
    onAddEmailContent: bindActionCreators(addEmailContent, dispatch),
    onUpdateEmailContent: bindActionCreators(updateEmailContent, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onDelEmailContent: bindActionCreators(removeEmailContent, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReminderSetting);
