import { api } from "../../services/api";
import * as types from "../Constants";
import { clientProfileReducer } from "../Reducers/ClientProfile";

export const startNewApplication = (data) => {
  const options = {
    url: "v1/subject/case",
  };

  options.types = [
    types.START_NEW_APPLICATION_SUCCESS,
    types.START_NEW_APPLICATION_FAILURE,
  ];

  return api.post(options, data);
};

export const getVisaApplication = (id) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  let clientId = id ? id : clientprofileid;
  const options = {
    url: `v1/subject/case/All/${clientId}`,
  };

  options.types = [
    types.GET_VISA_APPLICATION_SUCCESS,
    types.GET_VISA_APPLICATION_FAILURE,
  ];

  return api.get(options);
};

export const getFamilyMembers = () => {
  const options = {
    url: "",
  };

  options.types = [
    types.GET_FAMILY_MEMBERS_SUCCESS,
    types.GET_FAMILY_MEMBERS_FAILURE,
  ];

  return api.get(options);
};

export const getClientDocuments = () => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  const options = {
    url: `v1/document/All/${clientprofileid}`,
  };

  options.types = [
    types.GET_CLIENT_DOCUMENTS_SUCCESS,
    types.GET_CLIENT_DOCUMENTS_FAILURE,
  ];

  return api.get(options);
};

export const getClientEmail = () => {
  const options = {
    url: "",
  };

  options.types = [
    types.GET_CLIENT_EMAIL_SUCCESS,
    types.GET_CLIENT_EMAIL_FAILURE,
  ];

  return api.get(options);
};

export const getClientFileNotes = () => {
  const options = {
    url: "",
  };

  options.types = [
    types.GET_CLIENT_FILE_NOTES_SUCCESS,
    types.GET_CLIENT_FILE_NOTES_FAILURE,
  ];

  return api.get(options);
};

export const addFamilyLink = (data) => {
  const options = {
    url: "v1/subject/caselink",
  };

  options.types = [
    types.ADD_FAMILY_LINK_SUCCESS,
    types.ADD_FAMILY_LINK_FAILURE,
  ];

  return api.post(options, data);
};

export const removeFamilyLink = (data) => {
  const options = {
    url: "v1/subject/caselink",
  };

  options.types = [
    types.REMOVE_FAMILY_LINK_SUCCESS,
    types.REMOVE_FAMILY_LINK_FAILURE,
  ];

  return api.delete(options, data);
};

export const updateSubjectCase = (data) => {
  const options = {
    url: "v1/subject/case",
  };

  options.types = [
    types.UPDATE_SUBJECT_CASE_SUCCESS,
    types.UPDATE_SUBJECT_CASE_FAILURE,
  ];

  return api.put(options, data);
};

export const updateCaseStatus = (data) => {
  const options = {
    url: "v1/subject/case/UpdateSubjectCaseStatus",
  };

  options.types = [
    types.UPDATE_CASE_STATUS_SUCCESS,
    types.UPDATE_CASE_STATUS_FAILURE,
  ];

  return api.put(options, data);
};

export const removeCaseStatus = (data) => {
  const options = {
    url: "v1/case/history",
  };

  options.types = [
    types.DELETE_CASE_STATUS_SUCCESS,
    types.DELETE_CASE_STATUS_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeSubjectCase = (data) => {
  const options = {
    url: "v1/subject/case",
  };

  options.types = [
    types.DELETE_SUBJECT_CASE_SUCCESS,
    types.DELETE_SUBJECT_CASE_FAILURE,
  ];

  return api.delete(options, data);
};

export const getCaseDocuments = (data) => {
  const options = {
    url: "v1/visa/document/AllWithDetail/" + data,
  };

  options.types = [
    types.GET_CASE_DOCUMENTS_SUCCESS,
    types.GET_CASE_DOCUMENTS_FAILURE,
  ];

  return api.get(options);
};

export const getVisaTypeByCountry = (data) => {
  const options = {
    url:
      "v1/company/BranchVisaType/GetAllBranchVisaTypeByCountry/All/" +
      data,
  };

  options.types = [
    types.GET_VISA_TYPE_BY_COUNTRY_SUCCESS,
    types.GET_VISA_TYPE_BY_COUNTRY_FAILURE,
  ];

  return api.get(options);
};

export const getCategory = (data) => {
  const options = {
    url: "v1/config/GetAllVisaSubCategory/" + data,
  };

  options.types = [
    types.GET_VISA_CATEGORY_SUCCESS,
    types.GET_VISA_CATEGORY_FAILURE,
  ];

  return api.get(options);
};

export const updatePriority = (data) => {
  const options = {
    url: "v1/subject/case/UpdateSubjectCasePriority",
  };

  options.types = [
    types.UPDATE_CASE_PRIORITY_SUCCESS,
    types.UPDATE_CASE_PRIORITY_FAILURE,
  ];

  return api.put(options, data);
};

export const signedVisa = (data) => {
  const options = {
    url: "v1/subject/case/AgreementSignedVisa",
  };

  options.types = [
    types.UPDATE_SIGNED_VISA_SUCCESS,
    types.UPDATE_SIGNED_VISA_FAILURE,
  ];

  return api.put(options, data);
};

export const paidVisa = (data) => {
  const options = {
    url: "v1/subject/case/PaidVisa",
  };

  options.types = [
    types.UPDATE_PAID_VISA_SUCCESS,
    types.UPDATE_PAID_VISA_FAILURE,
  ];

  return api.put(options, data);
};

export const updateCaseHistory = (data) => {
  const options = {
    url: "v1/case/history",
  };

  options.types = [
    types.UPDATE_CASE_HISTORY_SUCCESS,
    types.UPDATE_CASE_HISTORY_FAILURE,
  ];

  return api.put(options, data);
};

export const getVisaEmails = (id) => {
  const options = {
    url: `v1/email/visaemail/${id}`,
  };

  options.types = [types.GET_VISA_EMAIL_SUCCESS, types.GET_VISA_EMAIL_FAILURE];

  return api.get(options);
};
