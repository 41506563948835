import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal, Spin, message } from "antd";
import MapEmail from "./MapEmail";
import BulkEmail from "../BulkEmail/BulkEmail";
import PurchaseSubscription from "../../Common/PurchaseMessage";
// import AddNewEmployerForm from "./AddNewEmployerForm";
// import EmployerManagementTabs from "./EmployerManagementTabs";
// import Invoice from "./Invoice/Invoice";

var userId = localStorage.getItem("userId");

const { TabPane } = Tabs;

const MailHead = ({
  onGetMailBox,
  mailBoxData,
  onSearchImapEmail,
  searchMailData,
  onSearchClient,
  mainClientsData,
  onImportManualEmail,
  onSearchPotentialClient,
  potentialClientsData,
  onSearchEmployer,
  searchEmployerRes,
  onImportManualPotentialEmail,
  onImportManualEmployerEmail,
  onAutoImportEmailClient,
  onGetLetterTemplates,
  LetterTemplatesRes,
  onAddDraftClient,
  onAddEmailDocument,
  emailDocumentRes,
  onGetSignature,
  signatureRes,
  onGetPdf,
  onGetDocuments,
  documentRes,
  onGetCountries,
  countriesData,
  visaTypeData,
  visaStatusData,
  onGetVisaType,
  onGetVisaStatus,
  onGetClientTag,
  clientTagRes,
  onGetAllUsers,
  userData,
  onGetPermProcessingPerson,
}) => {
  const [addNew, setAddNew] = useState("");
  const [addEmployerModel, setAddEmployerModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employerTabble, setEmployerTabble] = useState(true);
  const [userDataEmp, setUserDataEmp] = useState({});
  const [handleKey, setHandleKey] = useState("1");
  const [isGetEmployers, SetIsGetEmployers] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [labels, setLabels] = useState([]);
  const [accountType, setAccountType] = useState(null);

  const showModal = (modalTypeName) => {
    setAddNew(modalTypeName);
    setAddEmployerModel(true);
  };

  const handleCancel = () => {
    setAddEmployerModel(false);
  };

  const singleEmployeFuc = (userData) => {
    setEmployerTabble(!employerTabble);
    setUserDataEmp(userData);
  };

  const singleEmployeIcons = (userData) => {
    setUserDataEmp(userData);
  };

  const handleSetDefault = () => {
    setIsSearch(false);
  };

  useEffect(() => {
    setLoading(true);
    onGetMailBox()
      .then((res) => {
        setLabels(res.payload.mailBoxes);
        setLoading(false);
        setAccountType(res.payload.accountType);
      })
      .catch((err) => {
        setLoading(false);
        if (err.payload.response && err.payload.response.status === 404) {
          message.warning("Imap not integrated!");
        }
        if (err.payload.response.status === 400) {
          message.warning(err.payload.response.data.Detail);
        }
      });
  }, []);

  useEffect(() => {
    // if (!isGetEmployers) {
    //   setLoading(true);
    //
    //   SetIsGetEmployers(true);
    //   //   onGetEmployerManag().then(() => setLoading(false));
    // }
  }, [isGetEmployers]);

  const callback = (key) => {
    setEmployerTabble(true);
    // onGetEmployerManag();
    // window.location.reload();
  };

  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div className="reminder-tabs-header employer-Header">
          <Tabs onChange={(key) => callback(key)} defaultActiveKey={"1"}>
            <TabPane tab="EMAILS" key="1">
              {labels && labels ? (
                <MapEmail
                  labels={labels}
                  onSearchImapEmail={onSearchImapEmail}
                  searchMailData={searchMailData}
                  onSearchClient={onSearchClient}
                  mainClientsData={mainClientsData}
                  onImportManualEmail={onImportManualEmail}
                  onSearchPotentialClient={onSearchPotentialClient}
                  potentialClientsData={potentialClientsData}
                  onSearchEmployer={onSearchEmployer}
                  searchEmployerRes={searchEmployerRes}
                  onImportManualPotentialEmail={onImportManualPotentialEmail}
                  onImportManualEmployerEmail={onImportManualEmployerEmail}
                  onAutoImportEmailClient={onAutoImportEmailClient}
                  accountType={accountType}
                />
              ) : null}
            </TabPane>
            {/* <TabPane tab="EMAIL" key="2">
            {/*  <Invoice showModal={showModal} />
          </TabPane>
          <TabPane tab="MAIL CHIMP" key="3">
            {/*  <Invoice showModal={showModal} />
          </TabPane> */}
            <TabPane tab="BULK EMAIL" key="2">
              {localStorage.getItem("userBranchPermissions") &&
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userBranchPermissions"))
                  )
                )
              ).branchPermission.find(
                (x) => x.name.toLowerCase() == "bulkemail"
              ).status ? (
                <BulkEmail
                  onGetLetterTemplates={onGetLetterTemplates}
                  LetterTemplatesRes={LetterTemplatesRes}
                  onAddDraftClient={onAddDraftClient}
                  onAddEmailDocument={onAddEmailDocument}
                  emailDocumentRes={emailDocumentRes}
                  onGetSignature={onGetSignature}
                  signatureRes={signatureRes}
                  onGetPdf={onGetPdf}
                  onGetDocuments={onGetDocuments}
                  documentRes={documentRes}
                  onGetCountries={onGetCountries}
                  countriesData={countriesData}
                  visaTypeData={visaTypeData}
                  visaStatusData={visaStatusData}
                  onGetVisaType={onGetVisaType}
                  onGetVisaStatus={onGetVisaStatus}
                  onGetClientTag={onGetClientTag}
                  clientTagRes={clientTagRes}
                  onGetAllUsers={onGetAllUsers}
                  userData={userData}
                  onGetPermProcessingPerson={onGetPermProcessingPerson}
                />
              ) : (
                <PurchaseSubscription />
              )}
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </Fragment>
  );
};
export default MailHead;
