import React, { Component } from "react";
import PropTypes from "prop-types";
import settings from "../../svgs/settings.svg";
import exportImg from "../../svgs/export.svg";
import DashboardClientTopBars from "../../Components/Shared/DashboardClientTopBar";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Select,
  Table,
  Spin,
  Modal,
  DatePicker,
  Popconfirm,
  Input,
  Checkbox,
  Tooltip,
} from "antd";
import moment from "moment";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import DashboardPotentialReport from "../../Reports/PotentialClientDashboardReport/PotentialClientDashboardReport";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

const topBar = [
  { tabName: "Client Summary", linkName: "/dashboard" },
  { tabName: "Potential Clients", linkName: "/dashboard/potentialclient" },
  { tabName: "Students", linkName: "/dashboard/student" },
  { tabName: "Employers", linkName: "/dashboard/employers" },
  { tabName: "Reminders", linkName: "/coming-soon" },
];
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
var config = {
  key: "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
  height: "auto",
  toolbarSticky: false,
  events: {
    "charCounter.update": () => {
      // Do something here.
      // this is the editor instance.
      console.log("char");
    },
    "image.beforeUpload": (files) => {
      let editor = config.editor;
      if (files.length) {
        // Create a File Reader.
        let reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = (e) => {
          let result = e.target.result;
          console.log("result", result);

          editor.image.insert(result, null, null, editor.image.get());
        };
        // Read image as base64.
        let pic = reader.readAsDataURL(files[0]);
        console.log("pic", pic);
      }
      editor.popups.hideAll();
      // Stop default upload chain.
      return false;
    },
  },
};

class Reminders extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      Reminders: [],
      PotentialClients: [],
      ProcessingPersons: [],
      ReminderTypes: [],
      selectedBranch: null,
      processingPerson: "00000000-0000-0000-0000-000000000000",
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      isTaskModalOpen: false,
      pageLoading: false,
      newTaskObj: {},
      fileNoteObj: {
        fileNote: "",
      },
      completed: false,
      isDue: true,
      reminderTypeId: 0,
      startDate: "",
      endDate: "",
    };
  }
  columns = [
    {
      title: "",
      dataIndex: "",
      render: (data, record) => (
        <Checkbox onChange={(e) => this.onChangeReminderStatus(record)} />
      ),
    },
    { title: "Reminders Title", dataIndex: "reminderDetail" },
    { title: "Reminder Description", dataIndex: "reminderDescription" },
    {
      title: "Reminder Date",
      dataIndex: "reminderDate",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    { title: "Client Name", dataIndex: "clientName" },
  ];

  componentWillMount = () => {
    console.log("ClientSummary will mount");
  };

  componentDidMount = () => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.setState({ pageLoading: true, selectedBranch: selectedBranchId });
    const {
      onGetDashboardReminders,
      onGetDashboardRemindersTypes,
    } = this.props;
    onGetDashboardReminders({
      pageSize: 10,
      pageNumber: 1,
      reminderTypeId: 0,
      completed: false,
      isDue: true,
      startDate: "",
      endDate: "",
    });
    onGetDashboardRemindersTypes();
  };

  componentWillReceiveProps = (nextProps) => {
    let remindersList = nextProps && nextProps.dashboardRemindersList;
    let potentialClientError = nextProps && nextProps.potentialClientError;
    let remindersTypes = nextProps && nextProps.remindersTypes;
    let remindersTypesError = nextProps && nextProps.remindersTypesError;
    if (remindersList)
      this.setState({
        Reminders: remindersList.reminders,
        pageLoading: false,
        totalPages: remindersList.count,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
        pageLoading: false,
      });
    if (potentialClientError) this.setState({ pageLoading: false });
    if (remindersTypes) this.setState({ ReminderTypes: remindersTypes.items });
    if (remindersTypesError) this.setState({ pageLoading: false });
  };

  paginate = (value) => {
    let { current, pageSize } = value;
    let {
      selectedBranch,
      reminderTypeId,
      completed,
      isDue,
      startDate,
      endDate,
    } = this.state;
    let { onGetDashboardReminders } = this.props;
    this.setState({ pageLoading: true });
    this.setState(
      { pageSize: value.pageSize, currentPage: value.current },
      () => {
        onGetDashboardReminders({
          pageSize: pageSize,
          pageNumber: current,
          reminderTypeId: reminderTypeId,
          completed: completed,
          isDue: isDue,
          startDate: startDate || "",
          endDate: endDate || "",
        })
          .then(() => this.setState({ pageLoading: false }))
          .catch(() => this.setState({ pageLoading: false }));
      }
    );
  };
  getDashboardReminders = () => {
    let {
      selectedBranch,
      reminderTypeId,
      completed,
      isDue,
      currentPage,
      pageSize,
      startDate,
      endDate,
    } = this.state;
    let { onGetDashboardReminders } = this.props;
    onGetDashboardReminders({
      pageSize: pageSize,
      pageNumber: currentPage,
      reminderTypeId: reminderTypeId,
      completed: completed,
      isDue: isDue,
      startDate: startDate || "",
      endDate: endDate || "",
    });
  };
  onChangeProcessingPerson = (value) => {
    this.setState({ processingPerson: value }, () =>
      this.paginate({ pageSize: 5, current: 1 })
    );
  };

  addNewTask = () => {
    let userId = localStorage.getItem("userId");

    this.setState({ pageLoading: true });
    let {
      subjectId,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers,
    } = this.state.newTaskObj;
    let taskObj = {
      subjectId,
      isPontential: false,
      isCompleted: false,
      caseCheckListId: 0,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers: taskUsers.map((el) => ({ userId: el.userId })),
    };
    this.props
      .onAddTask(taskObj)
      .then((res) => {
        this.getTasks({ id: subjectId });
      })
      .catch((err) => {
        this.setState({ pageLoading: false });
      });
  };
  viewTasks = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: res.payload.items,
          },
          pageLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: [],
          },
          pageLoading: false,
        })
      );
    this.props
      .onGetClientFile(record.id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };

  onChangeFollower = (val, val2) => {
    let { newTaskObj } = this.state;
    this.setState(
      {
        newTaskObj: {
          ...newTaskObj,
          taskUsers: [
            ...newTaskObj.taskUsers,
            { userId: val, name: val2.children },
          ],
        },
      },
      () => this.formRef.current.setFieldsValue({ followers: "a" })
    );
  };
  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };
  getTasks = (record) => {
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        openNotificationWithIcon("success", "Task Added");
        this.setState(
          {
            newTaskObj: {
              ...this.state.newTaskObj,
              currentTasks: res.payload.items,
              taskUsers: [],
            },
            pageLoading: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch((er) => {
        this.setState({
          pageLoading: false,
        });
      });
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };
  onAddClientFile = () => {
    this.setState({ pageLoading: true });
    let { fileNoteObj } = this.state;
    let { onAddClientFile } = this.props;
    onAddClientFile(fileNoteObj)
      .then((res) => {
        this.getAllFileNotes(fileNoteObj.clientId);
        openNotificationWithIcon("success", "File Note Added!");
        this.setState(
          {
            pageLoading: false,
            fileNoteObj: { ...fileNoteObj, fileNote: "" },
          },
          () => config.editor.html.set("")
        );
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  getAllFileNotes = (id) => {
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({ fileNotes: notes, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  handleNoteModelChange = (val) => {
    let { fileNoteObj } = this.state;
    this.setState({ fileNoteObj: { ...fileNoteObj, fileNote: val } });
  };
  onChangeReminderStatus = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateDashboardReminderStatus({ id: record.id, isCompleted: true })
      .then((res) => {
        this.getDashboardReminders();
        openNotificationWithIcon("success", "Reminder Status Completed!");
      })
      .catch((er) => {
        openNotificationWithIcon("error", "Error:", "Something went wrong");
      });
  };
  onChangeReminderType = (value) => {
    this.setState(
      { reminderTypeId: value, selectedType: value, pageLoading: true },
      () => {
        this.getDashboardReminders();
      }
    );
  };
  onChangeReminderDue = (value) => {
    this.setState(
      { isDue: value === 1 ? true : false, due: value, pageLoading: true },
      () => {
        this.getDashboardReminders();
      }
    );
  };
  onChangeReminderPending = (value) => {
    this.setState(
      {
        completed: value === 1 ? false : true,
        isPending: value,
        pageLoading: true,
      },
      () => {
        this.getDashboardReminders();
      }
    );
  };

  onChangeStartDate = (value, string) => {
    this.setState(
      {
        startDate: value,
      },
      () => {
        this.getDashboardReminders();
      }
    );
  };

  onChangeEndDate = (value, string) => {
    this.setState(
      {
        endDate: value,
      },
      () => {
        this.getDashboardReminders();
      }
    );
  };

  onExport = () => {
    let { processingPerson, selectedBranch } = this.state;

    this.setState({ pageLoading: true });
    const clientExportOpt = {
      url: `v1/reminder/ReminderRport`,
    };
    clientExportOpt.types = [
      "REMINDER_EXPORT_SUCCESS",
      "REMINDER_EXPORT_FAILURE",
    ];

    let data = {
      pageSize: 0,
      pageNumber: 0,
      reminderTypeId: 0,
      completed: this.state.isPending === 1 ? false : true,
      isDue: this.state.due === 1 ? true : false,
      startDate: "",
      endDate: "",
    };

    apiRefresh
      .postWithFileRes(clientExportOpt, data)
      .then((res) => {
        this.setState({ pageLoading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "EzyMigrate-Reminders_Summary.xlsx");
      })
      .catch((err) => this.setState({ pageLoading: false }));
  };

  render() {
    const {
      pageLoading,
      pageSize,
      currentPage,
      totalPages,
      Reminders,
      ReminderTypes,
    } = this.state;
    return (
      <Spin size="large" spinning={pageLoading}>
        <div>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <Row>
                <Col xs={24}>
                  <DashboardClientTopBars data={topBar} activeTab="Reminders" />
                </Col>
              </Row>
              <div class="container-fluid">
                <div
                  class="top-row"
                  style={{ paddingRight: 0, marginBottom: 0 }}
                >
                  <div
                    class="top-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div class="select-optionss" style={{ paddingRight: 1 }}>
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Processing Persons"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeReminderType.bind(this)}
                          value={
                            this.state.selectedType
                              ? this.state.selectedType
                              : 0
                          }
                        >
                          <Option value={0}>All</Option>
                          {ReminderTypes.map((type) => (
                            <Option value={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ paddingRight: 1, marginLeft: 3 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeReminderDue.bind(this)}
                          value={this.state.due ? this.state.due : 1}
                        >
                          <Option value={1}>Due</Option>
                          <Option value={2}>Over Due</Option>
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ paddingRight: 1, marginLeft: 3 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={this.onChangeReminderPending.bind(this)}
                          value={
                            this.state.isPending ? this.state.isPending : 1
                          }
                        >
                          <Option value={2}>Completed</Option>
                          <Option value={1}>Pending</Option>
                        </Select>
                      </div>
                      <div
                        class="select-optionss"
                        style={{ paddingRight: 1, marginLeft: 3 }}
                      >
                        <DatePicker
                          placeholder={"Start Date"}
                          format={"DD/MM/YYYY"}
                          onChange={this.onChangeStartDate}
                        />
                      </div>
                      <div class="select-optionss" style={{ paddingRight: 1 }}>
                        <DatePicker
                          placeholder={"End Date"}
                          format={"DD/MM/YYYY"}
                          onChange={this.onChangeEndDate}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div class="setting-export-cont" style={{ height: 24 }}>
                        <Tooltip placement="topLeft" title={`Reminder Export`}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={exportImg}
                            class="setting-export-img"
                            onClick={this.onExport}
                          />
                        </Tooltip>
                        {/* <ReactToPrint
                          trigger={() => {
                            return (
                              <img
                                style={{ cursor: "pointer" }}
                                src={exportImg}
                                class="setting-export-img"
                              />
                            );
                          }}
                          content={() => this.remindersRef}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-card">
                <div className="container-fluid">
                  <DashboardPotentialReport
                    ref={(el) => (this.remindersRef = el)}
                    PotentialClients={Reminders}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    columns={this.columns}
                    paginate={this.paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default Reminders;
