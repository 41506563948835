import React, { Fragment } from "react";
import HeaderBar from "../Components/Header/HeaderBar";
import "./ClientsStyles.css";
import Sidebar from "../Components/SideBar";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import AllClientsTable from "./AllClientsTable";
import { Form, Input, Select, Button, DatePicker, Checkbox, Spin } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Images } from "../Themes";
import moment from "moment";
import { apiRefresh } from "../services/api";
import { saveAs } from "file-saver";

const { Option } = Select;

const options2 = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const headOption = [{ tabName: "All Clients" }];

const dateFormat = "DD/MM/YYYY";

const JobSectors = [
  {
    label: "Administrative",
    id: 1,
  },
  {
    label: "Agriculture / Farming",
    id: 2,
  },
  {
    label: "Automotive",
    id: 3,
  },
  {
    label: "Construction",
    id: 4,
  },
  {
    label: "Electrical",
    id: 5,
  },
  {
    label: "Engineer",
    id: 6,
  },
  {
    label: "Finance",
    id: 7,
  },
  {
    label: "FMCG",
    id: 8,
  },
  {
    label: "Hospitality",
    id: 9,
  },
  {
    label: "Human Resources",
    id: 10,
  },
  {
    label: "Insurance",
    id: 11,
  },
  {
    label: "Legal",
    id: 12,
  },
  {
    label: "Marketing",
    id: 13,
  },
  {
    label: "Medical",
    id: 14,
  },
  {
    label: "Real estate",
    id: 15,
  },
  {
    label: "Retail",
    id: 16,
  },
  {
    label: "Sales",
    id: 17,
  },
  {
    label: "Supply chain",
    id: 18,
  },
  {
    label: "Teachers",
    id: 19,
  },
  {
    label: "Trades",
    id: 20,
  },
  {
    label: "Not Employed",
    id: 21,
  },
  {
    label: "Transportation",
    id: 22,
  },
  {
    label: "Manufacturing",
    id: 23,
  },
];

class AllClients extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      newClientTags: [],
      GetSearchResponse: [],
      visaTypesArray: [],
      checkedInclude: true,
      loadClients: false,
      clientsCount: 0,
      searchClientRequest: null,
      showAll: false,
      pageSize: 20,
    };
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    this.props.onGetVisaStatus();
    this.props.onGetTeamMember();
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      includeAll: true,
      activeInactive: true,
    });
  }
  GetSearchResponse = () => {
    this.setState("");
  };

  componentDidMount() {
    console.log("this.props", this.props);
    this.props.onGetClientTag().then((res) => {});
    this.setState({ loadClients: true });
    let data = {
      firstName: "",
      lastName: "",
      email: "",
      occupation: "",
      pageSize: 20,
      pageNumber: 1,
      mobile: "",
      company: "",
      activeInactive: false,
      clientSort: "desc",
      passportNo: "",
      surnameStart: "",
      status: 0,
      dateOfBirth: "1900-01-01T00:00:00+00",
      jobSector: "",
      includeDeleted: false,
      internalId: "",
      familyMember: "",
      visaType: 0,
      salePerson: "",
      clientType: "",
      inzClient: "",
      clientTags: "",
      clientNumber: "",
      active: "All",
      includeAll: true,
      export: false,
      processingPerson: "",
    };
    this.setState({ searchClientRequest: data });
    this.props
      .onSearchConnection(data)
      .then((data) => {
        this.setState({
          GetSearchResponse: data.payload,
          loadClients: false,
          clientsCount: data.payload.count,
        });
      })
      .catch((err) => {
        this.setState({ loadClients: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientTagRes !== this.props.clientTagRes) {
      this.gettingClientTags(this.props.clientTagRes);
    }
    if (prevProps.onSearchConnection !== this.props.onSearchConnection) {
      this.gettingClientTags(this.props.onSearchConnection);
    }
  }

  gettingClientTags = (arr) => {
    let newArr = [];
    if (arr && arr.items && arr.items.length > 0) {
      arr.items.map((data) =>
        newArr.push({
          value: data.id,
          label: data.name,
        })
      );
    }

    this.setState({ newClientTags: newArr });
  };

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onFinish = (values) => {
    this.setState({ loadClients: true });
    var date = null;
    if (values.dateOfBirth) {
      date = moment(values.dateOfBirth).utcOffset(0);
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      date.toISOString();
      date.format();
    }

    let data = null;
    if (this.state.showAll) {
      this.setState({ pageSize: 20 });
      this.formRef.current.resetFields();
      data = {
        firstName: "",
        lastName: "",
        email: "",
        occupation: "",
        pageSize: 20,
        pageNumber: 1,
        mobile: "",
        company: "",
        activeInactive: false,
        clientSort: "desc",
        passportNo: "",
        surnameStart: "",
        status: 0,
        dateOfBirth: "1900-01-01T00:00:00+00",
        jobSector: "",
        includeDeleted: false,
        internalId: "",
        familyMember: "",
        visaType: 0,
        salePerson: "",
        clientType: "",
        inzClient: "",
        clientTags: "",
        clientNumber: "",
        active: "All",
        includeAll: true,
        processingPerson: "",
      };
    } else {
      data = {
        firstName: values && values.first_name ? values.first_name.trim() : "",
        lastName: values && values.last_name ? values.last_name.trim() : "",
        email: values.email ? values.email.trim() : "",
        occupation: values.occupation ? values.occupation.trim() : "",
        pageSize: this.state.pageSize,
        pageNumber: 1,
        mobile: values && values.mobile ? values.mobile.trim() : "",
        company: values && values.company ? values.company.trim() : "",
        activeInactive: values.activeInactive === "Inactive" ? false : true,
        clientSort: values.clientSort || "",
        passportNo: values.passportNo ? values.passportNo.trim() : "",
        surnameStart: values.surnameStart ? values.surnameStart.trim() : "",
        status: parseInt(values.status) || 0,
        dateOfBirth: values.dateOfBirth ? date : "1900-01-01T00:00:00+00",
        jobSector: values.jobSector ? values.jobSector.toString() : "",
        includeDeleted: values.includeDeleted || false,
        internalId: values.internalId ? values.internalId.trim() : "",
        familyMember: values.familyMember ? values.familyMember.trim() : "",
        visaType: parseInt(values.visaType) || 0,
        salePerson: values.salePerson || "",
        clientType: values.clientType || "",
        inzClient: values.inzClient ? values.inzClient : "",
        clientTags:
          values && values.client_tag ? values.client_tag.toString() : "",
        clientNumber: values.clientNumber ? values.clientNumber.trim() : "",
        active:
          values.activeInactive === "All" || values.activeInactive == undefined
            ? "All"
            : values.activeInactive === "Inactive"
            ? "false"
            : "true",
        includeAll: this.state.checkedInclude,
        processingPerson: values.processingPerson
          ? values.processingPerson
          : "",
      };
    }

    if (this.state.export) {
      const clientList = {
        url: `v1/client/SearchClientPrintAll`,
      };

      clientList.types = [
        "EXPORT_SEARCH_CLIENT_SUCCESS",
        "EXPORT_SEARCH_CLIENT_FAILURE",
      ];
      apiRefresh
        .postWithFileRes(clientList, data)
        .then((res) => {
          this.setState({ loadClients: false });
          var blob = new Blob([res], {
            type: "application/octet-stream",
          });
          saveAs(blob, "ClientsList.xlsx");
        })
        .catch((err) => {
          this.setState({ loadClients: false });
        });
    } else {
      this.setState({ searchClientRequest: data });
      this.props
        .onSearchConnection(data)
        .then((data) => {
          this.setState({
            GetSearchResponse: data.payload.items,
            loadClients: false,
          });
        })
        .catch((err) => {
          this.setState({ loadClients: false });
        });
    }
  };

  render() {
    const {
      GetSearchResponse,
      checkedInclude,
      loadClients,
      searchClientRequest,
      clientsCount,
    } = this.state;
    const {
      clientTagRes,
      visaTypeData,
      visaStatusData,
      teamMembers,
      onSearchConnection,
      searchConnectionData,
    } = this.props;
    return (
      <Fragment>
        <Spin spinning={loadClients}>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <HeaderBarTabs data={headOption} activeTab="All Clients" />
              <div className="container-ui w-100">
                <div className="header-text-style">
                  {/* <span>Clients Detail</span> */}
                </div>
                <div className="form-container">
                  <div
                    style={{
                      width: "100",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <div className="all-client-form ">
                      <Form
                        onFinish={this.onFinish}
                        ref={this.formRef}
                        style={{
                          justifyContent: "space-around",
                        }}
                        layout="vertical"
                      >
                        <div
                          style={{ display: "flex" }}
                          className="contant-around"
                        >
                          <div className="width-25">
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="clientNumber"
                                label="EZM ID"
                                colon={false}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="first_name"
                                label="First Name"
                                colon={false}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="last_name"
                                label="Last Name"
                                colon={false}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="mobile"
                                label="Mobile"
                                colon={false}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="company"
                                label="Company"
                                colon={false}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="activeInactive"
                                label="Active / Inactive"
                                colon={false}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  defaultValue={"All"}
                                >
                                  <Select.Option value="All">All</Select.Option>
                                  <Select.Option value={"active"}>
                                    Active
                                  </Select.Option>
                                  <Select.Option value={"Inactive"}>
                                    Inactive
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="width-25">
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="clientSort"
                                label="Client Sort"
                                colon={false}
                              >
                                <Select showSearch optionFilterProp="children">
                                  <Option key="acs">Oldest to Newest</Option>
                                  <Option key="desc">Newest to Oldest</Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="email"
                                label="Email"
                                colon={false}
                                rules={[
                                  {
                                    type: "email",
                                    message: "Required!",
                                  },
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="passportNo"
                                label="Passport"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="surnameStart"
                                label="Surname Start's With"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="status"
                                label="Status"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  {visaStatusData &&
                                    visaStatusData.items.map((visaStatus) => (
                                      <Option key={visaStatus.id}>
                                        {visaStatus.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="dateOfBirth"
                                label="Date of Birth"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <DatePicker format={dateFormat} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="width-25">
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="jobSector"
                                label="Job Sector"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  {JobSectors.map((data) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    return (
                                      <Option value={data.id}>
                                        {data.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="occupation"
                                label="Occupation"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="includeDeleted"
                                label="Include Deleted"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message:
                                      "Please input your Include Deleted!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  <Option value={true}>Yes</Option>
                                  <Option value={false}>No</Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="internalId"
                                label="Internal ID"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="familyMember"
                                label="Family Member"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="visaType"
                                label="Visa Type"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  {visaTypeData &&
                                    visaTypeData.items.map(
                                      (visaType, index) => (
                                        <Option key={visaType.id}>
                                          {visaType.visaTypeName}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="width-25">
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="salePerson"
                                label="Sales Person"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  <Option value="">Select</Option>
                                  {teamMembers &&
                                    teamMembers.users.map((user) => (
                                      <Option key={user.id}>
                                        {user.fullName}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="clientType"
                                label="Client Type"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  <Option value="">Select</Option>
                                  <Option value="1">Student</Option>
                                  <Option value="2">Visa </Option>
                                  <Option value="3">Unsuccessful</Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="inzClient"
                                label="INZ Client"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="client_tag"
                                label="Client Tag"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  <Option key="">Select</Option>
                                  {clientTagRes &&
                                    clientTagRes.items.map((tag) => (
                                      <Option key={tag.id}>{tag.name}</Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                className="margin-top-0 "
                                name="processingPerson"
                                label="Processing Person"
                                colon={false}
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select showSearch optionFilterProp="children">
                                  {teamMembers &&
                                    teamMembers.users.map((user) => (
                                      <Option key={user.id}>
                                        {user.fullName}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className="client-form"
                              style={{ marginTop: 26 }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                name="includeAll"
                                label=""
                                colon={false}
                              >
                                <Checkbox
                                  checked={checkedInclude}
                                  style={{ color: "#55626c" }}
                                  onChange={(e) => {
                                    this.setState({
                                      checkedInclude: e.target.checked,
                                    });
                                  }}
                                >
                                  Include All Family
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="all-client-btn d-flex">
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              type="primary"
                              className="button-blue"
                              onClick={() =>
                                this.setState({ showAll: false, export: false })
                              }
                            >
                              Search
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              style={{ marginLeft: 10 }}
                              htmlType="submit"
                              type="primary"
                              className="button-blue"
                              onClick={() =>
                                this.setState({ showAll: true, export: false })
                              }
                            >
                              Show All
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              style={{ marginLeft: 10 }}
                              htmlType="submit"
                              type="primary"
                              onClick={() =>
                                this.setState({ showAll: false, export: true })
                              }
                              className="button-blue"
                            >
                              Export
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="button-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div className="rep-print-icon-cont">
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return (
                          <img
                            style={{ cursor: "pointer" }}
                            src={Images.printNBlue}
                            className="rep-print-icon"
                          />
                        );
                      }}
                      content={() => this.fileNotetRef}
                    />
                  </div>
                </div> */}

                <AllClientsTable
                  GetSearchResponse={
                    searchConnectionData && searchConnectionData.items
                  }
                  onRemoveClient={this.props.onRemoveClient}
                  history={this.props.history}
                  ref={(el) => (this.fileNotetRef = el)}
                  clientsCount={
                    searchConnectionData && searchConnectionData.count
                  }
                  requestData={searchClientRequest}
                  searchClient={onSearchConnection}
                  pageSize={this.state.pageSize}
                  onPaginate={(flag, value) =>
                    this.setState({
                      loadClients: flag,
                      pageSize: value.pageSize,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    );
  }
}

export default AllClients;
