import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmployerManagement from "./../Clients/EmployerDocuments/index";

import {
  getEmployerManag,
  addEmployerManag,
  deleteEmployerManag,
  getEmployerContact,
  addEmployerContact,
  getEmployerJob,
  addEmployerJob,
  getEmployerFile,
  addEmployerFile,
  searchEmployer,
  getEmployerData,
  removeEmployerContact,
  removeEmployerFile,
  updateEmployerManag,
  updateEmployerFile,
  updateEmployerContact,
  getEmployerDocument,
  addEmployerDocument,
  removeEmployerDocument,
  updateEmployerDocument,
  uploadAvatar,
  getDocumentChecklist,
  getEmployerCheckList,
  addEmployerCheckList,
  sendDynamicLinkEmail,
  getLetterTemplates,
  addEmployerEmail,
  getEmployerHistory,
  getAutoEmailImport,
  addAutoEmailImport,
  getClientProfile,
  getDocumentDownload,
  getDocumentPdf,
  getFilteredType,
  addFilteredType,
  getVisaType,
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  createClientLink,
  getClientLink,
  setCmvLinkTemplate,
  removeClient,
  getClientJobHistoryCurrent,
  setActiveKey,
  updateSignedDate,
  getClientSource,
  searchMainClient,
  checkUniqueEmail,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  updateReminder,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  getAdmissionProgram,
  getAdmissionStatuses,
  getVisaTypeByCountry,
  setProfileData,
  getPartnerData,
} from "../store/Actions";
import { setClientTab } from "../store/Actions/EmployerManagement";

const mapStateToProps = (state) => {
  return {
    employerManagRes: state.employerManagReducer.employerManagRes,
    addEmployerManagRes: state.employerManagReducer.addEmployerManagRes,
    deleteEmployerManagRes: state.employerManagReducer.deleteEmployerManagRes,
    EmployerContactRes: state.employerManagReducer.EmployerContactRes,
    addEmployerContactRes: state.employerManagReducer.addEmployerContactRes,
    employerJobRes: state.employerManagReducer.employerJobRes,
    addEmployerJobRes: state.employerManagReducer.addEmployerJobRes,
    employerFileRes: state.employerManagReducer.employerFileRes,
    addEmployerFileRes: state.employerManagReducer.addEmployerFileRes,
    searchEmployerRes: state.employerManagReducer.searchEmployerRes,
    singleEmployerRes: state.employerManagReducer.singleEmployerRes,
    delEmployerConRes: state.employerManagReducer.delEmployerConRes,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    delEmployerFilaRes: state.LetterTemplatesReducer.delEmployerFilaRes,
    updateEmployerRes: state.employerManagReducer.updateEmployerRes,
    updateEmployerfileRes: state.employerManagReducer.updateEmployerfileRes,
    updateEmployerContactRes:
      state.employerManagReducer.updateEmployerContactRes,
    employerDocumentRes: state.employerManagReducer.employerDocumentRes,
    addemployerDocumentRes: state.employerManagReducer.addemployerDocumentRes,
    remEmployerDocumentRes: state.employerManagReducer.remEmployerDocumentRes,
    updateEmployerDocumentRes:
      state.employerManagReducer.updateEmployerDocumentRes,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    employerCheckListRes: state.employerManagReducer.employerCheckListRes,
    addEmployerCheckListRes: state.docChecklistReducer.addEmployerCheckListRes,
    addEmployerEmailRes: state.employerManagReducer.addEmployerEmailRes,
    employerHistoryRes: state.employerManagReducer.employerHistoryRes,
    emailImportRes: state.employerManagReducer.emailImportRes,
    addEmailImportRes: state.employerManagReducer.addEmailImportRes,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    downloadDocRes: state.clientProfileReducer.downloadDocRes,
    documentFilterRes: state.clientProfileReducer.documentFilterRes,
    addDocumentFilterRes: state.clientProfileReducer.addDocumentFilterRes,
    clientTab: state.employerManagReducer.clientTab,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    userBranchData: state.branchReducer.userBranchData,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    cmvLinkTemplateData: state.clientProfileReducer.cmvLinkTemplateData,
    removeClientRes: state.clientProfileReducer.removeClientRes,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    searchClientData: state.clientProfileReducer.clientsData,
    uniqueEmailRes: state.clientProfileReducer.uniqueEmailRes,
    clientTab: state.employerManagReducer.clientTab,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    updReminderRes: state.reminderReducer.updReminderRes,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onAddEmployerManag: bindActionCreators(addEmployerManag, dispatch),
    onDeleteEmployer: bindActionCreators(deleteEmployerManag, dispatch),
    ongetEmployerContact: bindActionCreators(getEmployerContact, dispatch),
    onAddEmployerContact: bindActionCreators(addEmployerContact, dispatch),
    onGetEmployerJob: bindActionCreators(getEmployerJob, dispatch),
    onAddEmployerJob: bindActionCreators(addEmployerJob, dispatch),
    onGetEmployerFile: bindActionCreators(getEmployerFile, dispatch),
    onAddEmployerFile: bindActionCreators(addEmployerFile, dispatch),
    onSearchEmployer: bindActionCreators(searchEmployer, dispatch),
    onGetEmployerData: bindActionCreators(getEmployerData, dispatch),
    onRemoveEmployerContact: bindActionCreators(
      removeEmployerContact,
      dispatch
    ),
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onRemoveEmployerFile: bindActionCreators(removeEmployerFile, dispatch),
    onUpdateEmployerManag: bindActionCreators(updateEmployerManag, dispatch),
    onUpdateEmployerFile: bindActionCreators(updateEmployerFile, dispatch),
    onUpdateEmployerContact: bindActionCreators(
      updateEmployerContact,
      dispatch
    ),
    onGetEmployerDocument: bindActionCreators(getEmployerDocument, dispatch),
    onAddEmployerDocument: bindActionCreators(addEmployerDocument, dispatch),
    onRemoveEmployerDocument: bindActionCreators(
      removeEmployerDocument,
      dispatch
    ),
    onUpdateEmployerDocument: bindActionCreators(
      updateEmployerDocument,
      dispatch
    ),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
    onGetEmployerCheckList: bindActionCreators(getEmployerCheckList, dispatch),
    onAddEmployerCheckList: bindActionCreators(addEmployerCheckList, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onAddEmployerEmail: bindActionCreators(addEmployerEmail, dispatch),
    onGetEmployerHistory: bindActionCreators(getEmployerHistory, dispatch),
    onGetAutoEmailImport: bindActionCreators(getAutoEmailImport, dispatch),
    onAddAutoEmailImport: bindActionCreators(addAutoEmailImport, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onGetDocumentPdf: bindActionCreators(getDocumentPdf, dispatch),
    onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
    onAddFilteredType: bindActionCreators(addFilteredType, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onCreateClientLink: bindActionCreators(createClientLink, dispatch),
    onGetClientLink: bindActionCreators(getClientLink, dispatch),
    onSetCmvLinkTemplate: bindActionCreators(setCmvLinkTemplate, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdateSignedDate: bindActionCreators(updateSignedDate, dispatch),
    getClientSource: bindActionCreators(getClientSource, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onCheckUniqueEmail: bindActionCreators(checkUniqueEmail, dispatch),
    onSetClientTab: bindActionCreators(setClientTab, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdateReminder: bindActionCreators(updateReminder, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerManagement);
