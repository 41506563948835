import React, { Fragment, useEffect, useState } from "react";
import { Checkbox, message, Table } from "antd";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";

const SideArea = ({
  showModal,
  reminderTaskRes,
  onGetReminderTasks,
  onRemoveReminderTask,
  closeReminderPanel,
  onUpdateDashboardReminderStatus,
}) => {
  // const [arrow, setArrow] = useState(false);

  const clientColumns = [
    {
      title: "Title",
      dataIndex: "reminderDetail",
    },
    {
      title: "Description",
      dataIndex: "reminderDescription",
    },
    {
      title: "Client Name",
      dataIndex: "subjectName",
    },
    {
      title: "Reminder Date",
      dataIndex: "reminderDate",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => {
        return (
          <div
            style={{
              width: "50%",
              justifyContent: "flex-end",
            }}
            className="table-action"
          >
            <EditOutlined
              onClick={() => showModal("update-reminder", record.id, record)}
            />
            <DeleteOutlined onClick={() => removeTag(record)} />
          </div>
        );
      },
    },
  ];
  console.log(
    "reminderTaskResreminderTaskResreminderTaskRes2323",
    reminderTaskRes
  );
  useEffect(() => {
    onGetReminderTasks();
  }, [onGetReminderTasks]);

  function onRcheckChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const removeTag = (data) => {
    // setLoading(true);

    const remove = {
      id: data.id,
      delete: true,
    };

    onRemoveReminderTask(remove).then(() => {
      onGetReminderTasks().then(() => {
        // setLoading(false);
        message.success("Successfully Deleted!");
      });
    });
  };
  const onChangeReminderStatus = (record) => {
    onUpdateDashboardReminderStatus({ id: record.id, isCompleted: true })
      .then((res) => {
        onGetReminderTasks();
        message.success("Reminder Status Completed!");
      })
      .catch((er) => {
        message.error("Something went wrong");
      });
  };

  return (
    <Fragment>
      <div className="task-to-do">
        <div class="heading-style" style={{ marginBottom: 15 }}>
          <span style={{ marginLeft: 0 }}>REMINDERS</span>
          <a
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              float: "right",
              marginRight: "-17px",
              marginTop: "-9px",
            }}
          >
            <CloseCircleOutlined
              onClick={() => closeReminderPanel()}
              style={{ fontSize: "20px" }}
            />
          </a>
        </div>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={clientColumns}
          dataSource={reminderTaskRes ? reminderTaskRes.items : []}
          pagination={false}
        />
        {/* {reminderTaskRes &&
          reminderTaskRes.items &&
          reminderTaskRes.items.length > 0 &&
          reminderTaskRes.items
            .filter((el) => !el.isCompleted)
            .map((data, index) => {
              return (
                <div
                  className="reminder-border "
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ width: "50%" }} className="d-flex ">
                    <span className="color-check">
                      <Checkbox onChange={() => onChangeReminderStatus(data)} />
                    </span>
                    <div>
                      <div>
                        <span
                          className="cv-bold-text"
                          style={{
                            color: "#1281BC",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {data.reminderDetail}
                        </span>
                      </div>
                      <div>
                        <div>
                          <span className="mdtr-rem-normal-text">
                            {data.reminderDescription}
                          </span>
                        </div>
                        {data.subjectName && data.subjectName.length && (
                          <div>
                            <span
                              className="cv-normal-text"
                              style={{
                                fontSize: 10,
                                fontWeight: "600",
                                marginLeft: 0,
                              }}
                            >
                              {data.subjectName}
                            </span>
                          </div>
                        )}
                        <div>
                          <span
                            className="cv-normal-text"
                            style={{
                              fontSize: 10,
                              fontWeight: "600",
                              marginLeft: 0,
                            }}
                          >
                            {yyyymmddFormate(data.reminderDate)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      justifyContent: "flex-end",
                    }}
                    className="table-action"
                  >
                    <EditOutlined
                      onClick={() =>
                        showModal("update-reminder", data.id, data)
                      }
                    />
                    <DeleteOutlined onClick={() => removeTag(data)} />
                  </div>
                </div>
              );
            })} */}
      </div>
    </Fragment>
  );
};

export default SideArea;
