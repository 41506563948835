import { api } from "../../services/api";
import * as types from "../Constants";

export const getDashboardStudents = (data) => {
  const options = {
    url: `v1/dashboard/Student`,
  };
  options.types = [
    types.GET_DASHBOARD_STUDENTS_SUCCESS,
    types.GET_DASHBOARD_STUDENTS_FAILURE,
  ];

  return api.post(options, data);
};
