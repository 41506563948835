import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Progress,
  Modal,
} from "antd";
import moment from "moment";

import { Images } from "../../Themes";
import Form from "antd/lib/form/Form";
import activityData from "../ClientActivity/ActivityData";
import AddAutoReminder from "../Reminder/AddAutoReminder";
import { apiRefresh } from "../../services/api";
import VisaUpdateEmail from "../../Clients/VisaUpdateEmail";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const typeArray = [
  { id: 1, name: "Standard" },
  { id: 2, name: "High-Volume" },
  { id: 3, name: "Franchise" },
  { id: 4, name: "Third-Party" },
];

const UpdateStatusCard = ({
  visaStatusData,
  visa,
  onUpdateCaseStatus,
  onGetVisaApplication,
  onSetActiveKey,
  onUpdatePriority,
  visaPriorityRes,
  onSignedVisa,
  onPaidVisa,
  clientId,
  isEmployer,
  onGetEmployerCase,
  onGetEmployerData,
  profileDataEmp,
  profileDataRdx,
  visaStatusNotification,
  setEmailTemplate,
  sendDynamicLinkEmail,
}) => {
  const [caseStatusId, setCaseStatusId] = useState(
    visa.caseStatusId === 5 ? "47" : visa.caseStatusId.toString()
  );
  const [caseStatusName, setCaseStatusName] = useState("");
  const [showFields, setShowField] = useState("0");
  const [caseStatusDate, setCaseStatusDate] = useState("");
  const [caseExpiryDate, setCaseExpiryDate] = useState("");
  const [caseApprovedDate, setCaseApprovedDate] = useState("");
  const [caseRefusedDate, setCaseRefusedDate] = useState("");
  const [refusedDescription, setRefusedDescription] = useState("");
  const [
    caseAssessmentCompletedDate,
    setCaseAssessmentCompletedDate,
  ] = useState("");
  const [caseAIPDate, setCaseAIPDate] = useState("");
  const [description, setDesctiption] = useState("");
  const [declinedDate, setDeclinedDate] = useState("");
  const [caseReceivedDate, setCaseReceivedDate] = useState("");
  const [caseDueDate, setCaseDueDate] = useState("");
  const [caseExtendedDate, setCaseExtendedDate] = useState("");
  const [casePPIReceivedDate, setCasePPIReceivedDate] = useState("");
  const [casePPIDueDate, setCasePPIDueDate] = useState("");
  const [casePPIExtendedDate, setCasePPIExtendedDate] = useState("");
  const [casePPISubmitedDate, setCasePPISubmitedDate] = useState("");
  const [caseAppliedDate, setCaseAppliedDates] = useState("");
  const [caseEoiSelectedDate, setCaseEoiSelectedDate] = useState("");
  const [caseEoiItaDate, setCaseEoiItaDate] = useState("");
  const [declinedDescription, setDeclinedDescriptionDate] = useState("");
  const [paid, setPaid] = useState(visa.isPaid);
  const [signed, setSigned] = useState(visa.contractSigned);
  const [visaStatuses, setVisaStatuses] = useState([]);
  const [lowPriority, setLowPriority] = useState(
    visa.casePriority === "Low" ? true : false
  );
  const [mediumPriority, setMediumPriority] = useState(
    visa.casePriority === "Medium" ? true : false
  );
  const [hightPriority, setHighPriority] = useState(
    visa.casePriority === "High" ? true : false
  );
  const [loadUpdCase, setLoadUpdCase] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [emailTemplateRes, setEmailTemplateRes] = useState(null);
  const [followUpDate, setFollowUpDate] = useState(
    visa.followUpDate === "1900-01-01T00:00:00+00:00"
      ? ""
      : moment(visa.followUpDate)
  );
  const [aggrementDate, setAggrementDate] = useState(
    visa.contractSignedDate === "1900-01-01T00:00:00+00:00"
      ? ""
      : moment(visa.contractSignedDate)
  );
  const [contractSignedDate, setContractSignedDate] = useState(
    visa.contractSignedDate === "1900-01-01T00:00:00+00:00"
      ? ""
      : moment(visa.contractSignedDate)
  );
  const [accType, setAccType] = useState(null);

  useEffect(() => {
    if (visa.typeId && visa.typeId !== 0) {
      var type = typeArray.find((obj) => obj.id == visa.typeId);
      if (type) {
        setAccType(type);
      }
    }
  }, []);

  useEffect(() => {
    var findVisaStatus =
      visaStatusData &&
      visaStatusData.items.find((obj) => obj.id === caseStatusId);

    if (showFields == 0 && visa) {
      setShowField(caseStatusId);
      setCaseExpiryDate(
        moment(visa.expiryDate).format("DD/MM/YYYY") == "01/01/1900"
          ? null
          : moment(visa.expiryDate)
      );
      setCaseApprovedDate(
        moment(visa.approveDate).format("DD/MM/YYYY") == "01/01/1900"
          ? null
          : moment(visa.approveDate)
      );
    }
    var caseData = [];
    var visaStatusArray = [];
    if (!findVisaStatus) {
      const branchUserOpt = {
        url: `v1/company/visastatus/${caseStatusId}`,
      };
      branchUserOpt.types = ["GET_VISA_TYPE_SUCCESS", "GET_VISA_TYPE_FAILURE"];

      apiRefresh.get(branchUserOpt).then((res) => {
        visaStatusArray = [];
        caseData = [...visaStatusData.items];
        visaStatusArray.push(<Option key={res.id}>{res.name}</Option>);
        var findInd = caseData.findIndex((obj) => obj.id == res.id);
        if (findInd !== -1) caseData.splice(findInd, 1);
        let _visaStatuses = caseData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        for (var i = 0; i < _visaStatuses.length; i++) {
          visaStatusArray.push(
            <Option key={_visaStatuses[i].id}>{_visaStatuses[i].name}</Option>
          );
        }
        setVisaStatuses(visaStatusArray);
      });
    } else if (visaStatusData && visaStatusData.items) {
      let _visaStatuses = visaStatusData.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      visaStatusArray = [];
      for (var i = 0; i < _visaStatuses.length; i++) {
        visaStatusArray.push(
          <Option key={_visaStatuses[i].id}>{_visaStatuses[i].name}</Option>
        );
      }
      setVisaStatuses(visaStatusArray);
    }
  }, [visaStatusData]);

  const onChange = (value) => {
    console.log(`selected ${value}`);

    setCaseStatusId(value);
    setShowField(value);
  };

  const onChangeDate = (value, dateString) => {
    setCaseStatusDate(value);
  };

  const onChangeExpiryDate = (value, dateString) => {
    setCaseExpiryDate(value);
  };

  const onChangeAggrementDate = (value, dateString) => {
    setAggrementDate(value);
  };

  const onChangeFollowupDate = (value, dateString) => {
    setFollowUpDate(value);
  };

  const onChangeApprovedDate = (value, dateString) => {
    setCaseApprovedDate(value);
  };

  const onChangeRefusedDate = (value, dateString) => {
    setCaseRefusedDate(value);
  };

  const onChangeRefusedDescription = (value) => {
    setRefusedDescription(value);
  };

  const onChangeAssessmentDate = (value, dateString) => {
    setCaseAssessmentCompletedDate(value);
  };

  const onChangePPiSubmitedDate = (value, dateString) => {
    setCasePPISubmitedDate(value);
  };

  const onChangeContractDate = (value, dateString) => {
    setContractSignedDate(value);
    setSigned(true);
    // onSignedVisa();
  };

  const onChangeDeclinedDate = (value, dateString) => {
    setDeclinedDate(value);
  };

  const onChangeDeclinedDescription = (value) => {
    setDeclinedDescriptionDate(value.target.value);
  };

  const onChangeAipDate = (value, dateString) => {
    setCaseAIPDate(value);
  };

  const onChangeReceivedDate = (value, dateString) => {
    setCaseReceivedDate(value);
  };

  const onChangeDueDate = (value, dateString) => {
    setCaseDueDate(value);
  };

  const onChangeExtendedDate = (value, dateString) => {
    setCaseExtendedDate(value);
  };

  const onChangeAppliedDate = (value, dateString) => {
    setCaseAppliedDates(value);
  };

  const onChangeDescription = (value) => {
    setDesctiption(value.target.value);
  };

  const onChangeEoiItaDate = (value, dateString) => {
    setCaseEoiItaDate(value);
  };

  const onChangeEoiSelectedDate = (value, dateString) => {
    setCaseEoiSelectedDate(value);
  };

  const updateStatus = (visa) => {
    let data = {
      id: visa.id,
      caseStatusId: parseInt(caseStatusId) || 0,
      approveDate: caseApprovedDate
        ? moment(caseApprovedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      expiryDate: caseExpiryDate
        ? moment(caseExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      date: caseStatusDate
        ? moment(caseStatusDate).format("YYYY-MM-DDT00:00:00+00:00")
        : new Date(),
      branchVisaTypeId: visa.branchVisaTypeId || 0,
      description: description || "",
      receivedDate: caseReceivedDate
        ? moment(caseReceivedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      dueDate: caseDueDate
        ? moment(caseDueDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      extendDueDate: caseExtendedDate
        ? moment(caseExtendedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      submittedDate: casePPISubmitedDate
        ? moment(casePPISubmitedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      aipDate: caseAIPDate
        ? moment(caseAIPDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      eoiSubmitedDate: "1900-01-01T00:00:00+00:00",
      eoiSelectedDate: caseEoiSelectedDate
        ? moment(caseEoiSelectedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      eoiitaDate: caseEoiItaDate
        ? moment(caseEoiItaDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      declineDate: declinedDate
        ? moment(declinedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      declineDescription: declinedDescription || "",
      assessmentCompletedDate: caseAssessmentCompletedDate
        ? moment(caseAssessmentCompletedDate).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      contractSigned: signed,
      contractSignedDate: contractSignedDate
        ? moment(contractSignedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      refusedDate: caseRefusedDate
        ? moment(caseRefusedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      refusedDescription: refusedDescription || "",
      followUpDate: followUpDate
        ? moment(followUpDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
    };
    var caseStatus = visaStatusData.items.find((obj) => obj.id == caseStatusId);
    var caseStatusName = "";
    if (caseStatus) {
      caseStatusName = caseStatus.name;
      setCaseStatusName(caseStatusName);
    }
    setLoadUpdCase(true);
    onUpdateCaseStatus(data)
      .then((res) => {
        message.success("Visa status updated successfully");

        if (isEmployer) {
          if (caseStatusId == 8) {
            if (visa.branchVisaTypeName == "Employer accreditation") {
              let data = {
                id: clientId,
                accredationStartDate: caseApprovedDate
                  ? moment(caseApprovedDate).format("YYYY-MM-DDT00:00:00+00:00")
                  : "1900-01-01T00:00:00+00:00",
                accredationExpiryDate: caseExpiryDate
                  ? moment(caseExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
                  : "1900-01-01T00:00:00+00:00",
              };
              const branchUserOpt = {
                url: `v1/employer/AccredationExpiryUpdate`,
              };
              branchUserOpt.types = [
                "ACCREDITATION_DATE_SUCCESS",
                "ACCREDITATION_DATE_FAILURE",
              ];
              apiRefresh.put(branchUserOpt, data).then(() => {
                onGetEmployerCase(clientId);
                onGetEmployerData(clientId);
              });
              if (caseExpiryDate) {
                let myData = {
                  title: "Accreditation Expiry",
                  reminderDate: caseExpiryDate || "1900-01-01T00:00:00+00:00",
                  valueA: visa.branchVisaTypeName,
                  isEmployer: isEmployer,
                  profileName: profileDataEmp.name,
                  profileId: profileDataEmp.id,
                  typeId: 2,
                };
                AddAutoReminder(myData);
              }
            } else if (visa.branchVisaTypeName == "Job Check") {
              if (caseExpiryDate) {
                let myData = {
                  title: "Job Check Expiry",
                  reminderDate: caseExpiryDate || "1900-01-01T00:00:00+00:00",
                  valueA: visa.branchVisaTypeName,
                  isEmployer: isEmployer,
                  profileName: profileDataEmp.name,
                  profileId: profileDataEmp.id,
                  typeId: 2,
                };
                AddAutoReminder(myData);
              }
            }
          } else {
            onGetEmployerCase(clientId);
          }
        }
        let userName = localStorage.getItem("userName");
        var profileData;
        if (!isEmployer) {
          profileData = profileDataRdx;
          var clientprofileid = sessionStorage.getItem("clientprofileid");

          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                visa.branchVisaTypeName +
                " visa application status updated to " +
                caseStatusName +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Visa",
              invoiceId: "0",
            };
            activityData(myData);
            if (visaStatusNotification == 1) {
              setLoadUpdCase(true);
              let _emailTemplatePayload = {
                templateName: "Update Visa Status Notification",
                parameters: [
                  {
                    key: "ClientId",
                    value: sessionStorage.getItem("clientprofileid"),
                  },
                  {
                    key: "EZMID",
                    value: profileData ? profileData.ezmid : "",
                  },
                  {
                    key: "ClientName",
                    value: profileData ? profileData.fullName : "",
                  },
                  {
                    key: "Content",
                    value: "UpdateVisa",
                  },
                  {
                    key: "VisaStatus",
                    value: caseStatusName,
                  },
                  {
                    key: "DigitalSignature",
                    value: "@DigitalSignature",
                  },
                ],
              };
              setEmailTemplate(_emailTemplatePayload).then((res) => {
                setLoadUpdCase(false);
                setEmailModal(true);
                setEmailTemplateRes(res.payload);
              });
            }
          }
        } else {
          profileData = profileDataEmp;
        }
        if (caseStatusId === "12" && caseDueDate) {
          let myData = {
            title: "PPI Expiry",
            reminderDate: caseDueDate || "1900-01-01T00:00:00+00:00",
            valueA: visa.branchVisaTypeName,
            isEmployer: isEmployer,
            profileName: profileData.fullName || profileData.name,
            profileId: isEmployer ? profileData.id : clientprofileid,
            typeId: 11,
          };
          AddAutoReminder(myData);
        }

        if (caseStatusId === "8" && caseExpiryDate) {
          let secondData = {
            title: "Approved Visa Expiry",
            reminderDate: caseExpiryDate || "1900-01-01T00:00:00+00:00",
            valueA: visa.branchVisaTypeName,
            isEmployer: isEmployer,
            profileName: profileData.fullName || profileData.name,
            profileId: isEmployer ? profileData.id : clientprofileid,
            typeId: 2,
          };
          AddAutoReminder(secondData);
        }

        if (followUpDate !== visa.followUpDate && followUpDate) {
          let thirdData = {
            title: "Follow Up",
            reminderDate: followUpDate || "1900-01-01T00:00:00+00:00",
            isEmployer: isEmployer,
            profileName: profileData.fullName || profileData.name,
            profileId: isEmployer ? profileData.id : clientprofileid,
            typeId: 9,
          };
          AddAutoReminder(thirdData);
        }
        if (isEmployer) {
          onGetEmployerCase(profileData.id);
        } else {
          onGetVisaApplication(clientprofileid);
        }
        setLoadUpdCase(false);
        onSetActiveKey("1");
        setShowField("0");
      })
      .catch((error) => {
        message.error("Failed to update visa status!");
        setLoadUpdCase(false);
      });
  };

  const updateVisaPriority = (priority) => {
    let data = {
      id: visa.id,
      priority: priority,
    };
    onUpdatePriority(data).then((res) => {
      message.success("Visa priority updated!");
      onGetVisaApplication();
      let userName = localStorage.getItem("userName");
      if (!isEmployer) {
        var profileData = profileDataRdx;
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              visa.branchVisaTypeName +
              " visa application priority changed to " +
              priority +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Visa",
            invoiceId: "0",
          };
          activityData(myData);
        }
      }
    });
  };

  const callPaidVisa = () => {
    paidVisa(!paid);
    setPaid(!paid);
  };

  const singedVisa = () => {
    let data = {
      id: visa.id,
      contractSigned: signed,
    };

    onSignedVisa(data)
      .then(() => {
        message.success("Updated!");
        onGetVisaApplication();
      })
      .catch(() => {
        message.error("Failed on sign toggle!");
      });
  };

  const paidVisa = (value) => {
    let data = {
      id: visa.id,
      status: value,
    };

    onPaidVisa(data)
      .then(() => {
        message.success("Updated!");
        onGetVisaApplication();
        let userName = localStorage.getItem("userName");
        if (!isEmployer) {
          var profileData = profileDataRdx;
          var paid_unpaid = value ? "paid" : "unpaid";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                visa.branchVisaTypeName +
                " visa application case status " +
                paid_unpaid +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Visa",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
      })
      .catch(() => {
        message.error("Failed on paid toggle!");
      });
  };

  const signedVisa = () => {
    let data = {
      id: visa.id,
      contractSigned: signed,
    };
    onSignedVisa(data).then((res) => {
      let userName = localStorage.getItem("userName");
      if (!isEmployer) {
        var profileData = profileDataRdx;
        var signed_unSigned = !signed ? "signed" : "unsigned";
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Application updated with " + signed_unSigned + " by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Visa",
            invoiceId: "0",
          };
          activityData(myData);
        }
      }
    });
    setSigned(!signed);
  };

  return (
    <Fragment>
      <div className="form-container" style={{ marginLeft: 0, marginRight: 0 }}>
        <Form>
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
              display: "inline-block",
            }}
          >
            <div
              style={{
                display: "inline-block",
              }}
            >
              <span className="visa-type-text">{visa.branchVisaTypeName}</span>
              {visa.caseLinks &&
                visa.caseLinks.items &&
                visa.caseLinks.items.length > 0 && (
                  <span style={{ marginLeft: 40 }}>
                    {"(" + visa.caseLinks.items[0].firstName + ")"}
                  </span>
                )}
            </div>
            {visa.caseTitle && visa.caseTitle !== "" && (
              <div style={{ display: "inline-block" }}>
                <div className="cv-green-box">
                  <span className="cv-green-text">
                    {visa.caseTitle.toUpperCase()}
                  </span>
                </div>
              </div>
            )}
          </div>

          {accType && (
            <div style={{ marginLeft: 10 }}>
              <span className="case-priority">{accType.name}</span>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div>
              <span className="case-priority">Case Priority</span>
              <div style={{ display: "flex", marginTop: 5 }}>
                <div
                  className={
                    hightPriority
                      ? "priority-high-filled"
                      : "priority-high-border"
                  }
                  onClick={() => {
                    setHighPriority(!hightPriority);
                    setLowPriority(false);
                    setMediumPriority(false);
                    updateVisaPriority("High");
                  }}
                  style={{ cursor: "pointer" }}
                ></div>
                <div
                  className={
                    mediumPriority
                      ? "priority-medium-filled"
                      : "priority-medium-border"
                  }
                  onClick={() => {
                    setMediumPriority(!mediumPriority);
                    setHighPriority(false);
                    setLowPriority(false);
                    updateVisaPriority("Medium");
                  }}
                  style={{ marginLeft: 2, cursor: "pointer" }}
                ></div>
                <div
                  style={{ marginLeft: 2, cursor: "pointer" }}
                  className={
                    lowPriority ? "priority-low-filled" : "priority-low-border"
                  }
                  onClick={() => {
                    setLowPriority(!lowPriority);
                    setMediumPriority(false);
                    setHighPriority(false);
                    updateVisaPriority("Low");
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <div className="case-priority-btn">
                    <span className="case-priority-text">Paid</span>
                  </div>
                  <div
                    className={
                      paid ? "cp-green-btn-cont" : "case-priority-cross"
                    }
                    style={{
                      justifyContent: "space-around",
                      borderRadius: 0,
                      cursor: "pointer",
                    }}
                    onClick={callPaidVisa}
                  >
                    {paid && (
                      <img
                        src={Images.tickWhite}
                        style={{ width: 8, height: 8 }}
                      />
                    )}
                    <div className="white-box"></div>
                    {!paid && (
                      <img
                        src={Images.crossWhite}
                        style={{ width: 8, height: 8 }}
                      />
                    )}
                  </div>
                  <div></div>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <div className="case-priority-btn">
                    <span className="case-priority-text">Signed</span>
                  </div>
                  <div
                    className={
                      signed ? "cp-green-btn-cont" : "case-priority-cross"
                    }
                    style={{
                      borderRadius: 0,
                      cursor: "pointer",
                      justifyContent: "space-around",
                    }}
                    onClick={signedVisa}
                  >
                    {signed && (
                      <img
                        src={Images.tickWhite}
                        style={{ width: 8, height: 8 }}
                      />
                    )}
                    <div className="white-box"></div>
                    {!signed && (
                      <img
                        src={Images.crossWhite}
                        style={{ width: 8, height: 8 }}
                      />
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          {visa.caseHistory && visa.caseHistory.length > 0 && (
            <div
              style={{
                paddingTop: 5,
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              {moment(visa.date).format("DD/MM/YYYY") !== "01/01/1900" && (
                <span className="visa-date-text" style={{ color: "#b3b9bc" }}>
                  {"Visa Status Date: " +
                    moment(visa.caseHistory[0].startDate).format("DD/MM/YYYY")}
                </span>
              )}
            </div>
          )}
          {/* <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            {moment(visa.expiryDate).format("DD/MM/YYYY") !== "01/01/1900" && (
              <span className="visa-date-text" style={{ color: "#b3b9bc" }}>
                {"Visa Expiry Date: " +
                  moment(visa.expiryDate).format("DD/MM/YYYY")}
              </span>
            )}
          </div> */}

          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <span className="visa-date-text">Agreement Signed Date:</span>
            <div>
              <DatePicker
                placeholder="Aggrement Signed Date"
                onChange={onChangeContractDate}
                format={dateFormat}
                value={contractSignedDate}
              />
            </div>
          </div>

          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <span className="visa-date-text">Follow up Date:</span>
            <div>
              {/* <Form.Item name="expiryDate"> */}
              <DatePicker
                placeholder="Follow Up Date"
                onChange={onChangeFollowupDate}
                format={dateFormat}
                value={followUpDate}
              />
              {/* </Form.Item> */}
            </div>
          </div>

          {/* <div className="profile-progressbar-cont">
             <Progress percent={30} />
            <ProgressBar bgcolor="#CCCCCC" completed={30} color="#9D0C0E" />
          </div> */}

          <div style={{ padding: 10 }}>
            {/* <Form.Item name="caseStatusId"> */}
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Select Visa Status"
              onChange={onChange}
              value={caseStatusId}
              dropdownClassName="dropdown-options-width"
            >
              {visaStatuses}
            </Select>
            {/* </Form.Item> */}
          </div>
          {/* Approved */}
          {showFields === "8" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <span className="visa-date-text">Expiry Date:</span>
                {/* <Form.Item name="expiryDate"> */}
                <DatePicker
                  placeholder="Expiry Date"
                  onChange={onChangeExpiryDate}
                  format={dateFormat}
                  value={caseExpiryDate}
                />
                {/* </Form.Item> */}
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <span className="visa-date-text">Approved Date:</span>
                {/* <Form.Item name="approveDate"> */}
                <DatePicker
                  placeholder="Approved Date"
                  onChange={onChangeApprovedDate}
                  format={dateFormat}
                  value={caseApprovedDate}
                />
                {/* </Form.Item> */}
              </div>
            </>
          )}
          {/* Refused */}
          {showFields === "10" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                {/* <Form.Item name="refusedDate"> */}
                <DatePicker
                  value={caseRefusedDate}
                  onChange={onChangeRefusedDate}
                  format={dateFormat}
                  placeholder="Refused Date"
                />
                {/* </Form.Item> */}
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    vlaue={refusedDescription}
                    onChange={onChangeRefusedDescription}
                    className="profile-input"
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
            </>
          )}
          {/* Assessment */}
          {showFields == "32" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAssessmentCompletedDate}
                onChange={onChangeAssessmentDate}
                format={dateFormat}
                placeholder="Assessment Completed Date"
              />
            </div>
          )}
          {/* Contract signed date */}
          {showFields == "33" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={contractSignedDate}
                onChange={onChangeContractDate}
                format={dateFormat}
                placeholder="Contract Signed Date"
              />
            </div>
          )}

          {/* Declined date */}
          {showFields == "9" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={declinedDate}
                  onChange={onChangeDeclinedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 10 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    placeholder="Decription"
                    vlaue={declinedDescription}
                    className="profile-input"
                    onChange={onChangeDeclinedDescription}
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
            </>
          )}

          {/* AIP date */}
          {showFields == "18" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAIPDate}
                onChange={onChangeAipDate}
                format={dateFormat}
                placeholder="AIP Date"
              />
            </div>
          )}

          {/* Further info date */}
          {showFields == "29" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    placeholder="description"
                    vlaue={description}
                    className="profile-input"
                    onChange={onChangeDescription}
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseReceivedDate}
                  placeholder="Receive Date"
                  onChange={onChangeReceivedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseDueDate}
                  placeholder="Due Date"
                  onChange={onChangeDueDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseExtendedDate}
                  placeholder="Extended Date"
                  onChange={onChangeExtendedDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {/* Immigration matter ppi date */}
          {showFields == "12" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    vlaue={description}
                    placeholder="Description"
                    onChange={onChangeDescription}
                    className="profile-input"
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseReceivedDate}
                  placeholder="PPI Receive Date"
                  onChange={onChangeReceivedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseDueDate}
                  placeholder="PPI Due Date"
                  onChange={onChangeDueDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseExtendedDate}
                  placeholder="PPI Extended Date"
                  onChange={onChangeExtendedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={casePPISubmitedDate}
                  placeholder="PPI Submited Date"
                  onChange={onChangePPiSubmitedDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {/* applied date */}
          {showFields == "58" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAppliedDate}
                onChange={onChangeAppliedDate}
                format={dateFormat}
              />
            </div>
          )}

          {/* selected date */}
          {showFields == "14" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseEoiSelectedDate}
                  placeholder="EOI Selected Date"
                  onChange={onChangeEoiSelectedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseEoiItaDate}
                  placeholder="EOI ITA Date"
                  onChange={onChangeEoiItaDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {showFields !== "0" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker onChange={onChangeDate} format={dateFormat} />
            </div>
          )}

          <div className="button-blue-cont">
            <Button
              onClick={() => updateStatus(visa)}
              className="button-blue"
              loading={loadUpdCase}
              style={{ color: "#FFFFFF" }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>

      {emailModal && (
        <VisaUpdateEmail
          fromClient={true}
          clientId={sessionStorage.getItem("clientProfileId")}
          // attDataArray={this.state.attDataArray}
          // getSentStatus={this.getSentStatus}
          // state={this.state}
          // showSecondaryEmail={this.state.showSecondaryEmail}
          // isPDFRelatedToInvoice={this.state.isPDFRelatedToInvoice}
          // setEmailData={setEmailData}
          // userEmail={this.state.userEmail}
          // paymentId={this.state.currentPaymentId}
          // invoiceNo={this.state.invoiceNo}
          // invoiceId={this.state.invoiceId}
          // branchName={this.state.branchName}
          // clientName={this.props && this.props.clientName}
          // clientEmail={this.props && this.props.clientEmail}
          clientProfileData={profileDataRdx}
          handleCancelSendingReceiptModal={() => setEmailModal(false)}
          // invoiceInfo={_invoiceInformation}
          visibleSendingReceiptModal={emailModal}
          // showInvoiceTitle={this.state.showInvoiceTitle}
          emailTemplateRes={emailTemplateRes}
          visaTypeName={visa.branchVisaTypeName}
          caseStatusName={caseStatusName}
        />
      )}
    </Fragment>
  );
};

export default UpdateStatusCard;
