import { api } from "../../services/api";
import * as types from "../Constants";

export const getCompany = () => {
  const options = {
    url: "v1/company",
  };

  options.types = [types.COMPANY_SUCCESS, types.COMPANY_FAILURE];

  return api.get(options);
};

export const updCompany = (data) => {
  var userId = localStorage.getItem("userId");
  const options = {
    url: "v1/company",
  };

  options.types = [types.UPDATE_COMPANY_SUCCESS, types.UPDATE_COMPANY_FAILURE];

  return api.put(options, data);
};
