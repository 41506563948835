import React, { Fragment, useState } from "react";
import { Table, message, Tag, Modal } from "antd";
import ClientEmployerDetail from "./ClientEmployerDetail";
import ClientEmployerJobTitle from "./ClientEmployerJobTitle";
import { yyyymmddFormate } from "./../../../../../Common/reUseFunction";
import history from "./../../../../../services/history";
import { Link } from "react-router-dom";

const ClientEmployer = ({
  showModal,
  empClientEmpRes,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  setLoading,
  loading,

  onGetClientJobHis,
  clientJobHisRes,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal1Visible, setIsModal1Visible] = useState(false);
  const [isClientTagId, setIsClientTagId] = useState();

  const confirmation = (value) => {
    setIsModalVisible(!isModalVisible);
    setIsClientTagId(value && value.id);
  };

  const handleJobTitle = (value) => {
    setIsModal1Visible(!isModalVisible);
    setIsClientTagId(value && value.id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModal1Visible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setIsModal1Visible(false);
  };

  const columns = [
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      width: "100px",
      render: (text, record) => {
        return (
          <div className="table-action">
            <div>
              <span className="acc-actions-button">
                {record &&
                record.clientPermission &&
                record.clientPermission.active === true ? (
                  <span className="sent-email">Active</span>
                ) : (
                  <span className="draft">Inactive</span>
                )}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "firstName",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname:
                record.memberType.toLowerCase() === "client"
                  ? "/profile"
                  : record.memberType.toLowerCase() === "spouse"
                  ? "/partner-profile"
                  : "/client-family-member",
              state: { id: record.clientId, familyId: record.familyId },
            }}
            className="hover-link"
            style={{
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {record.firstName + " " + record.lastName}
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: "200px",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
            textDecoration: "none !important",
            cursor: "pointer !important",
          }}
        >
          {record.email}
        </p>
      ),
    },
    {
      title: "DOB",
      key: "dateOfBirth",
      render: (text, record) => {
        return (
          <div
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
              textDecoration: "none !important",
              cursor: "pointer !important",
            }}
          >
            <span>
              {record && record.dateOfBirth === "1900-01-01T00:00:00+00:00"
                ? ""
                : record && yyyymmddFormate(record.dateOfBirth)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Job Title",
      dataIndex: "clientTags",
      key: "clientTags",
      ellipsis: true,
      render: (text, record) => (
        <span
          onClick={() => {
            handleJobTitle(record);
          }}
        >
          <a href="javascript:">
            <p
              className="hover-link"
              style={{
                fontSize: "12px",
                fontWeight: "initial",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {"View Detail"}
            </p>
          </a>
        </span>
      ),
    },
    {
      title: "Client Tags",
      dataIndex: "clientTags",
      key: "clientTags",
      ellipsis: true,
      render: (text, record) => (
        <span onClick={() => confirmation(record)}>
          <a href="javascript:">
            <p
              className="hover-link"
              style={{
                fontSize: "12px",
                fontWeight: "initial",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {"View Detail"}
            </p>
          </a>
        </span>
      ),
    },
    {
      title: "Country",
      dataIndex: "visaCountryName",
      key: "visaCountryName",
      render: (text, record) => {
        const arr = record.visaCountryName.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const strVisaCountryName = arr.join(" ");
        return (
          <Link
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
              textDecoration: "none !important",
              cursor: "pointer !important",
            }}
          >
            {strVisaCountryName}
          </Link>
        );
      },
    },
  ];

  console.log("empClientEmpResempClientEmpResempClientEmpRes", empClientEmpRes);
  return (
    <Fragment>
      <Table
        className="job-table table-head employer-tables border-3 school-table "
        dataSource={empClientEmpRes && empClientEmpRes.items}
        columns={columns}
        scroll={{ x: "auto" }}
      />
      <Modal
        className="client-detail-modal"
        title="CLIENT TAGS"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        header={false}
        // footer={false}
      >
        <ClientEmployerDetail
          getClientRes={getClientRes}
          onGetProfileClientTag={onGetProfileClientTag}
          isClientTagId={isClientTagId}
          setLoading={setLoading}
          loading={loading}
        />
      </Modal>
      <Modal
        className="client-detail-modal"
        title="DETAILS"
        visible={isModal1Visible}
        onOk={handleOk}
        onCancel={handleCancel}
        header={false}
        // footer={false}
      >
        <ClientEmployerJobTitle
          onGetClientJobHis={onGetClientJobHis}
          clientJobHisRes={clientJobHisRes}
          isClientTagId={isClientTagId}
          setLoading={setLoading}
          loading={loading}
        />
      </Modal>
    </Fragment>
  );
};
export default ClientEmployer;
