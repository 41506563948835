import React from "react";
import { Row, Col } from "antd";
import "./SchoolManagementStyles.css";
import { Redirect } from "react-router-dom";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import StudentList from "./StudentList";
import Schools from "./Schools";
import NewInvoice from "./NewInvoice";
import CommissionReminder from "./CommissionReminder";
import Invoices from "./Invoices";
import AddInvoice from "./AddInvoice";
import jwt_decode from "jwt-decode";

var headOption = [];

class SchoolManagementLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: "",
      addInvoice: "",
      parentInvoiceId: 0,
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      activeTab: "students",
      newInvoice: "",
      activePath: "/school-management/student-list",
      selectedSchoolId: 0,
    };

    if (
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userSystemPermissions")))
        )
      ).find((x) => x.role.toLowerCase() == "school management account")
        .status == 1
    ) {
      headOption = [
        {
          tabName: "Student List",
          linkName: "/school-management/student-list",
        },
        { tabName: "Schools", linkName: "/school-management/schools" },
        { tabName: "New Invoice", linkName: "/school-management/invoice" },
        { tabName: "Accounts", linkName: "/school-management/accounts" },
        {
          tabName: "Commission Reminder",
          linkName: "/school-management/commission-reminder",
        },
      ];
    } else {
      headOption = [
        {
          tabName: "Student List",
          linkName: "/school-management/student-list",
        },
        { tabName: "Schools", linkName: "/school-management/schools" },
      ];
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {}

  setActiveTab = (data) => {
    this.setState({ activeTab: data });
  };

  getChildInfo = (
    parentInvoiceId,
    childrenTotalAmount,
    parentTotalAmount,
    addInvoice
  ) => {
    this.setState({
      parentInvoiceId: parentInvoiceId,
      childrenTotalAmount: childrenTotalAmount,
      parentTotalAmount: parentTotalAmount,
      addInvoice: addInvoice,
    });
  };

  getSelectedSchoolName = (school) => {
    this.setState({ schoolName: school });
  };

  setAddInvoiceValueForInvoice = (data) => {
    this.setState({ addInvoice: data });
  };

  setNewInvoiceTab = (newInvoice) => {
    this.setState({ newInvoice: newInvoice });
  };

  setActivePath = (data) => {
    this.setState({ activePath: data });
  };

  getSelectedSchoolId = (id) => {
    this.setState({ selectedSchoolId: id });
  };

  render() {
    return (
      <Row>
        <Col xs={24} style={{ display: "flex" }}>
          <div className="page-container">
            <Row>
              <Col xs={24}>
                <PotentialHeaderTabs
                  setActivePath={this.setActivePath}
                  setInvoicesDataNull={this.props.setInvoicesDataNull}
                  setNewInvoiceTab={this.setNewInvoiceTab}
                  setActiveTab={this.setActiveTab}
                  setAddInvoiceValueForInvoice={
                    this.setAddInvoiceValueForInvoice
                  }
                  data={headOption}
                  activePath={this.state.activePath}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                {this.state.activeTab === "students" ? (
                  <StudentList />
                ) : this.state.activeTab === "schools" ? (
                  <Schools />
                ) : (this.state.activeTab === "invoice" &&
                    this.state.addInvoice === "add") ||
                  this.state.addInvoice === "refresh" ? (
                  // <div style={{height: "100vh"}}>
                  //   <h3 style={{display: "flex", justifyContent: "center"}}>
                  //     Coming Soon!
                  //   </h3>
                  // </div>
                  <NewInvoice
                    schoolName={this.state.schoolName}
                    selectedSchoolId={this.state.selectedSchoolId}
                    parentInvoiceId={this.state.parentInvoiceId}
                    childrenTotalAmount={this.state.childrenTotalAmount}
                    parentTotalAmount={this.state.parentTotalAmount}
                    newInvoice={this.state.newInvoice}
                    getChildInfo={this.getChildInfo}
                    addInvoice={this.state.addInvoice}
                  />
                ) : this.state.activeTab === "invoice" &&
                  this.state.addInvoice === "invoices" ? (
                  // <div style={{height: "100vh"}}>
                  //   <h3 style={{display: "flex", justifyContent: "center"}}>
                  //     Coming Soon!
                  //   </h3>
                  // </div>
                  <Invoices
                    getSelectedSchoolName={this.getSelectedSchoolName}
                    getChildInfo={this.getChildInfo}
                    getSelectedSchoolId={this.getSelectedSchoolId}
                    selectedSchoolId={this.state.selectedSchoolId}
                  />
                ) : this.state.activeTab === "reminders" ? (
                  <CommissionReminder />
                ) : null}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default SchoolManagementLayout;
