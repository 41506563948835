import React from "react";
import Select from "react-select";
import "./MeetingsStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import DatePicker from "react-date-picker";
import Modal from "react-awesome-modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LoginActions from "../Redux/LoginRedux/LoginRedux";

const headOption = [{ tabName: "MEETINGS", linkName: "/meetings" }];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

class Meetings extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    fetching: PropTypes.bool,
    attemptToLogin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };

    const data = {
      email: "Arslan",
      password: "arslan123"
    };

    this.props.attemptToLogin(data);
  }

  openModal = () => {
    this.setState({
      visible: true
    });
  };

  closeModal = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <PotentialHeaderTabs data={headOption} activeTab="MEETINGS" />

            <div className="m-container">
              <div className="m-gray-cont">
                <div className="m-row-cont">
                  <div className="m-mehroon-circle"></div>
                  <div>
                    <span className="cv-normal-text">OVERLAPED</span>
                  </div>
                </div>

                <div className="m-calendar-input-row" style={{ marginTop: 30 }}>
                  <div
                    className="profile-input-border"
                    style={{
                      overflow: "inherit",
                      width: "30%",
                      paddingTop: 6,
                      paddingBottom: 6,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: 5
                    }}
                  >
                    <DatePicker
                      calendarIcon={null}
                      clearIcon={null}
                      onChange={this.onChangeDate}
                      value={this.state.date}
                    />
                  </div>
                  <div className="m-icon-border">
                    <img
                      src={Images.calendarBlue}
                      className="profile-calendar-icon"
                      style={{ marginLeft: 0 }}
                    />
                  </div>
                  <div className="m-icon-border">
                    <img
                      src={Images.arrowDarkBlue}
                      className="profile-calendar-icon"
                      style={{ transform: `rotate(270deg)`, marginLeft: 0 }}
                    />
                  </div>
                </div>

                <div className="m-calendar-input-row" style={{ marginTop: 40 }}>
                  <div
                    className="select-options"
                    style={{ width: `calc(30% + 5px)` }}
                  >
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                  </div>
                  <div
                    className="emp-blue-btn"
                    style={{ marginLeft: 10, width: 50 }}
                  >
                    <span style={{ color: "#FFFFFF", fontSize: 12 }}>Show</span>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="m-white-cont">
                    <div>
                      <span>21/06/2020</span>
                    </div>
                    <div onClick={this.openModal}>
                      <img
                        src={Images.plusIcon}
                        style={{ width: 20, height: 20 }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="m-card-cont">
                    <div
                      className="m-row-cont"
                      style={{
                        justifyContent: "space-between",
                        borderBottom: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "#E9E9E9",
                        paddingBottom: 10
                      }}
                    >
                      <div className="m-row-cont">
                        <img
                          src={Images.clockBlue}
                          style={{ width: 10, height: 10 }}
                        />
                        <div className="m-org-cont">
                          <span className="m-white-font">Start 1:00 PM</span>
                        </div>
                        <div className="m-org-cont">
                          <span className="m-white-font">End 2:00 PM</span>
                        </div>
                      </div>
                      <div className="m-row-cont">
                        <img
                          src={Images.userCircleGray}
                          style={{ width: 15, height: 15 }}
                        />
                        <div className="m-icon-border" style={{ padding: 3 }}>
                          <img
                            src={Images.deleteIcon}
                            style={{ width: 10, height: 10 }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="m-title-cont">
                      <span
                        className="cv-normal-text"
                        style={{ marginLeft: 0 }}
                      >
                        MEETING TITLE
                      </span>
                    </div>

                    <div className="m-row-cont">
                      <div className="m-org-border-cont">
                        <span
                          className="cv-normal-text"
                          style={{ marginLeft: 0 }}
                        >
                          Anna
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="m-card-cont" style={{ marginLeft: 20 }}>
                    <div
                      className="m-row-cont"
                      style={{
                        justifyContent: "space-between",
                        borderBottom: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: "#E9E9E9",
                        paddingBottom: 10
                      }}
                    >
                      <div className="m-row-cont">
                        <img
                          src={Images.clockBlue}
                          style={{ width: 10, height: 10 }}
                        />
                        <div className="m-org-cont">
                          <span className="m-white-font">Start 1:00 PM</span>
                        </div>
                        <div className="m-org-cont">
                          <span className="m-white-font">End 2:00 PM</span>
                        </div>
                      </div>
                      <div className="m-row-cont">
                        <img
                          src={Images.userCircleGray}
                          style={{ width: 15, height: 15 }}
                        />
                        <div className="m-icon-border" style={{ padding: 3 }}>
                          <img
                            src={Images.deleteIcon}
                            style={{ width: 10, height: 10 }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="m-title-cont">
                      <span
                        className="cv-normal-text"
                        style={{ marginLeft: 0 }}
                      >
                        MEETING TITLE
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* ======== Update popup desing ====== */}

              {/* <Modal
                visible={this.state.visible}
                width="500"
                height="550"
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}
              >
                <div style={{ padding: 40 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="sus-modal-label">
                      <span className="sus-modal-label-text">UPDATE</span>
                    </div>
                    <div onClick={this.closeModal}>
                      <img src={Images.crossRed} style={{ width: 20 }} />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="ca-gray-cont">
                      <div className="m-followers-row">
                        <div className="m-row-cont">
                          <div>
                            <span>Followers</span>
                          </div>
                          <div
                            className="m-row-cont"
                            style={{ marginLeft: 40 }}
                          >
                            <div className="m-popup-user-cont">
                              <img
                                src={Images.userCircleGray}
                                style={{ width: 20, height: 20 }}
                              />
                            </div>
                            <div
                              className="m-popup-user-cont"
                              style={{ marginLeft: 15 }}
                            >
                              <img
                                src={Images.userCircleGray}
                                style={{ width: 20, height: 20 }}
                              />
                            </div>
                            <div
                              className="m-popup-user-cont"
                              style={{ marginLeft: 15 }}
                            >
                              <img
                                src={Images.userCircleGray}
                                style={{ width: 20, height: 20 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="m-client-btn-row-cont">
                        <div className="m-client-btn-cont">
                          <img
                            src={Images.crossWhite}
                            style={{ width: 8, height: 8 }}
                          />
                          <span className="m-client-btn-text">
                            Client: Aneela Zulfiquar
                          </span>
                        </div>
                      </div>

                      <div
                        style={{ width: `calc(100% - 100px)`, marginLeft: 20 }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between"
                          }}
                        >
                          <p className="medical-label">Medical Grading</p>
                          <div style={{ width: "50%", paddingLeft: 1 }}>
                            <div style={{ paddingLeft: 7 }}>
                              <div
                                className="select-options"
                                style={{ paddingTop: 1, width: "83%" }}
                              >
                                <Select
                                  value={selectedOption}
                                  onChange={this.handleChange}
                                  options={options}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Email"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Password"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Country"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="City"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="0"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Address"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          className="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Description"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 30
                    }}
                  >
                    <div></div>
                    <div style={{ display: "flex" }}>
                      <div className="sus-modal-button">
                        <span className="sus-modal-button-text">ADD NEW</span>
                      </div>
                      <div
                        onClick={this.closeModal}
                        className="sus-modal-button"
                        style={{ marginLeft: 10 }}
                      >
                        <span className="sus-modal-button-text">CLOSE</span>
                      </div>
                    </div>
                  </div>
                </div>
                  </Modal>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  attemptToLogin: data => dispatch(LoginActions.loginRequest(data))
});

Meetings = connect(mapStateToProps, mapDispatchToProps)(Meetings);

export default Meetings;
