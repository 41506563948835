import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SupervisorLogin from "../SupervisorLogin/SupervisorLogin";

import { supervisorLogin } from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSupervisorLogin: bindActionCreators(supervisorLogin, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupervisorLogin);
