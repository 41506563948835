import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal, Spin, message } from "antd";
import WorkType from "./WorkType";
import VisaTypePrice from "./VisaTypePrice";
import ServiceType from "./ServiceType";

const { TabPane } = Tabs;

const TimeTracking = ({
  onGetVisaWorkType,
  visaWorkTypeRes,
  onGetVisaTypePrice,
  visaTypePriceRes,
  onGetServiceType,
  serviceTypeRes,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const callback = (key) => {
    // setEmployerTabble(true);
    // onGetEmployerManag();
    // window.location.reload();
  };

  return (
    <Fragment>
      <div className="reminder-tabs-header employer-Header">
        <Tabs onChange={(key) => callback(key)} defaultActiveKey="1">
          <TabPane tab="WORK TYPE" key="1">
            <WorkType
              visaWorkTypeRes={visaWorkTypeRes}
              onGetVisaWorkType={onGetVisaWorkType}
            />
          </TabPane>

          <TabPane tab="VISA TYPE PRICE" key="2">
            <VisaTypePrice
              onGetVisaTypePrice={onGetVisaTypePrice}
              visaTypePriceRes={visaTypePriceRes}
            />
          </TabPane>
          <TabPane tab="SERVICE TYPE" key="3">
            <ServiceType
              onGetServiceType={onGetServiceType}
              serviceTypeRes={serviceTypeRes}
            />
          </TabPane>
        </Tabs>
      </div>
    </Fragment>
  );
};
export default TimeTracking;
