import React from "react";
import { Checkbox, Col, Collapse, InputNumber, Row, Select } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getAllTaxesListing } from "../../store/Actions";

const { Option } = Select;
const { Panel } = Collapse;

class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      gst: 0,
      taxInclusive: false,
      received: 0,
      taxName: "",
      taxValueDropdown: 0,
      id: 0,
    };
  }

  componentDidMount() {
    let _data = this.props && this.props.data;
    let _receivedAmount = 0;
    if (_data && _data.invoice && _data.invoice.length > 0) {
      for (
        let _indicatorTemp = 0;
        _indicatorTemp < _data.invoice.length;
        _indicatorTemp++
      ) {
        _receivedAmount =
          _receivedAmount + _data.invoice[_indicatorTemp].paidAmount;
      }
    }
    this.setState({
      amount: _data.amount,
      gst: _data.gst,
      taxInclusive: _data.taxInclusive,
      received: _receivedAmount,
      taxName: _data.taxName,
      id: _data.id,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _data = this.props && this.props.data;
    let _receivedAmount = 0;
    if (_data && _data.invoice && _data.invoice.length > 0) {
      for (
        let _indicatorTemp = 0;
        _indicatorTemp < _data.invoice.length;
        _indicatorTemp++
      ) {
        _receivedAmount =
          _receivedAmount + _data.invoice[_indicatorTemp].paidAmount;
      }
    }
    this.setState({
      amount: _data.amount,
      gst: _data.gst,
      taxInclusive: _data.taxInclusive,
      received: _receivedAmount,
      taxName: _data.taxName,
      id: _data.id,
    });
  }

  onChangeTaxDropdownValue = (data) => {
    let _taxDropdownValue = data === "" ? null : JSON.parse(data);
    this.setState(
      {
        taxValueDropdown: data === "" ? 0 : _taxDropdownValue.percent,
      },
      () => {
        this.setState(
          {
            gst: this.state.taxInclusive
              ? 0
              : data === ""
              ? 0
              : _taxDropdownValue.percent,
            taxName: data === "" ? " " : _taxDropdownValue.name,
          },
          () => {
            this.props &&
              this.props.getStageTaxNameUpdatedValue(
                this.state.id,
                this.state.taxName,
                this.state.gst
              );
          }
        );
      }
    );
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.setState({ gst: 0 }, () => {
        this.props &&
          this.props.getStageGSTUpdatedValue(this.state.id, this.state.gst);
      });
    } else {
      this.setState({ gst: value }, () => {
        this.props &&
          this.props.getStageGSTUpdatedValue(this.state.id, this.state.gst);
      });
    }
  };

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState(
      { taxInclusive: e.target.checked, taxValueDropdown: 0 },
      () => {
        this.setState(
          {
            gst:
              !this.state.taxInclusive && this.state.taxValueDropdown !== 0
                ? this.state.taxValueDropdown
                : 0,
            taxInclusive: e.target.checked,
            taxName: this.state.taxInclusive ? "" : "",
          },
          () => {
            this.props &&
              this.props.getStageTaxInclusiveUpdatedValue(
                this.state.id,
                this.state.taxInclusive,
                this.state.taxName,
                this.state.gst
              );
          }
        );
      }
    );
  };

  handleChangeAmount = (value) => {
    if (value === null) {
      this.setState({ amount: 0 }, () => {
        this.props &&
          this.props.getStageAmountUpdatedValue(
            this.state.id,
            this.state.amount
          );
      });
    } else {
      this.setState({ amount: value }, () => {
        this.props &&
          this.props.getStageAmountUpdatedValue(
            this.state.id,
            this.state.amount
          );
      });
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={8} className="color-background-template-stage">
            <div
              style={{
                display: "block",
                textAlign: "center",
                height: "24px",
                borderLeft: "1px solid rgb(54, 177, 228)",
              }}
            >
              <p style={{ fontWeight: "500", color: "white" }}>{`Stage ${this
                .props && this.props.index + 1}`}</p>
            </div>
          </Col>
          <Col
            xs={8}
            style={{
              borderTop: "1px solid #889093",
              borderBottom: "1px solid #889093",
            }}
          >
            <div>
              <InputNumber
                className="balance-input"
                onChange={this.handleChangeAmount}
                value={this.state.amount}
                style={{ fontWeight: "500", color: "#556370", border: "none" }}
              ></InputNumber>
            </div>
          </Col>
          <Col xs={8} className="color-background-template-stage">
            <div
              style={{
                display: "block",
                textAlign: "center",
                height: "24px",
                borderRight: "1px solid rgb(54, 177, 228)",
              }}
            >
              <p style={{ fontWeight: "500", color: "white" }}>
                {this.state.received}
              </p>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#f6f7f9",
            paddingTop: "7px",
            paddingBottom: "7px",
          }}
        >
          <Col xs={24}>
            <Row>
              <Col xs={16}>
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                    height: "35px",
                    marginLeft: "-28px",
                  }}
                >
                  <Checkbox
                    onChange={this.handleChangeTaxInclusiveCheck}
                    checked={this.state.taxInclusive}
                  >
                    {
                      <span
                        style={{
                          fontWeight: "500",
                          color: "#889093",
                          fontSize: "10px",
                        }}
                      >
                        Tax inclusive
                      </span>
                    }
                  </Checkbox>
                </div>
              </Col>
              <Col xs={8}>
                <div
                  style={{
                    display: "block",
                    textAlign: "center",
                    height: "35px",
                  }}
                >
                  <i
                    style={{
                      fontWeight: "500",
                      color: "#889093",
                      fontSize: "11px",
                    }}
                  >{`GST ${this.state.gst}%`}</i>
                </div>
              </Col>
            </Row>
            {!this.state.taxInclusive ? (
              <div>
                <Row>
                  <Col xs={21} offset={1}>
                    <Select
                      value={this.state.taxName}
                      virtual={false}
                      placeholder="Please Select Tax!"
                      size="small"
                      onChange={(e) => this.onChangeTaxDropdownValue(e)}
                    >
                      <Option value={""}>{"Please select tax!"}</Option>
                      {this.props &&
                        this.props.taxListing &&
                        this.props.taxListing.map((data) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          return (
                            <Option value={JSON.stringify(data)} key={data.id}>
                              {data.name}-{data.percent}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: "7px" }}>
                  <Col xs={23} offset={1}>
                    <InputNumber
                      value={this.state.gst}
                      size="small"
                      onChange={(e) => this.handleChangeTaxPercentage(e)}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ backgroundColor: "white", height: "15px" }}></div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({});

Stage = connect(mapStateToProps, mapDispatchToProps)(Stage);
export default withRouter(Stage);
