import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PotentialEmployer from "./../PotentialClient/Inquiry";

import {
  getEmployerManag,
  addEmployerManag,
  deleteEmployerManag,
  getEmployerContact,
  addEmployerContact,
  getEmployerJob,
  addEmployerJob,
  getEmployerFile,
  addEmployerFile,
  searchEmployer,
  getEmployerData,
  removeEmployerContact,
  removeEmployerFile,
  updateEmployerManag,
  updateEmployerFile,
  updateEmployerContact,
  getEmployerDocument,
  addEmployerDocument,
  removeEmployerDocument,
  updateEmployerDocument,
  uploadAvatar,
  getDocumentChecklist,
  getEmployerCheckList,
  addEmployerCheckList,
  sendDynamicLinkEmail,
  getLetterTemplates,
  addEmployerEmail,
  getEmployerHistory,
  getAutoEmailImport,
  addAutoEmailImport,
  addDocumentTittle,
  addAnyTamplate,
  uploadFile,
  getTeamMember,
  deleteCreateEmail,
  getDocumentPdf,
  getDocumentDownload,
  getFilteredType,
  addFilteredType,
  updateEmployerJob,
  removeEmployerJob,
  getClientSearch,
  getClientEmp,
  searchMainClient,
  getClientByEmpId,
  linkEmpWithClient,
  addEmployerJobHistory,
  getClientTag,
  getProfileClientTag,
  addDocWithName,
  getClientJobHis,
  getSingleClientHis
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    employerManagRes: state.employerManagReducer.employerManagRes,
    addEmployerManagRes: state.employerManagReducer.addEmployerManagRes,
    deleteEmployerManagRes: state.employerManagReducer.deleteEmployerManagRes,

    ///////////////////////////////////////////////////////////
    EmployerContactRes: state.employerManagReducer.EmployerContactRes,
    addEmployerContactRes: state.employerManagReducer.addEmployerContactRes,
    employerJobRes: state.employerManagReducer.employerJobRes,
    addEmployerJobRes: state.employerManagReducer.addEmployerJobRes,
    employerFileRes: state.employerManagReducer.employerFileRes,
    addEmployerFileRes: state.employerManagReducer.addEmployerFileRes,
    searchEmployerRes: state.employerManagReducer.searchEmployerRes,
    singleEmployerRes: state.employerManagReducer.singleEmployerRes,
    delEmployerConRes: state.employerManagReducer.delEmployerConRes,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    delEmployerFilaRes: state.LetterTemplatesReducer.delEmployerFilaRes,
    updateEmployerRes: state.employerManagReducer.updateEmployerRes,
    updateEmployerfileRes: state.employerManagReducer.updateEmployerfileRes,
    updateEmployerContactRes:
      state.employerManagReducer.updateEmployerContactRes,
    employerDocumentRes: state.employerManagReducer.employerDocumentRes,
    addemployerDocumentRes: state.employerManagReducer.addemployerDocumentRes,
    remEmployerDocumentRes: state.employerManagReducer.remEmployerDocumentRes,
    updateEmployerDocumentRes:
      state.employerManagReducer.updateEmployerDocumentRes,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    employerCheckListRes: state.employerManagReducer.employerCheckListRes,
    addEmployerCheckListRes: state.docChecklistReducer.addEmployerCheckListRes,
    addEmployerEmailRes: state.employerManagReducer.addEmployerEmailRes,
    employerHistoryRes: state.employerManagReducer.employerHistoryRes,
    emailImportRes: state.employerManagReducer.emailImportRes,
    addEmailImportRes: state.employerManagReducer.addEmailImportRes,
    addDoumentTittleRes: state.employerManagReducer.addDoumentTittleRes,
    addAnyTemRes: state.employerManagReducer.addAnyTemRes,
    addUploadFileRes: state.employerManagReducer.addUploadFileRes,
    teamMembers: state.teamMemberReducer.teamMembers,
    removeCreateERes: state.employerManagReducer.removeCreateERes,

    documentFilterRes: state.clientProfileReducer.documentFilterRes,
    addDocumentFilterRes: state.clientProfileReducer.addDocumentFilterRes,
    updateJobRes: state.employerManagReducer.updateJobRes,
    removeJobRes: state.employerManagReducer.removeJobRes,
    clientSearchRes: state.employerManagReducer.clientSearchRes,
    clientEmpRes: state.employerManagReducer.clientEmpRes,
    searchClientData: state.clientProfileReducer.searchConnectionData,
    empClientEmpRes: state.employerManagReducer.empClientEmpRes,
    linkEmpClientRes: state.employerManagReducer.linkEmpClientRes,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    docWithNameRes: state.employerManagReducer.docWithNameRes,
    clientJobHisRes: state.employerManagReducer.clientJobHisRes,
    singleJobHisRes: state.employerManagReducer.singleJobHisRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onAddEmployerManag: bindActionCreators(addEmployerManag, dispatch),
    onDeleteEmployer: bindActionCreators(deleteEmployerManag, dispatch),

    /////////////////////////////////////////////////////////////
    ongetEmployerContact: bindActionCreators(getEmployerContact, dispatch),
    onAddEmployerContact: bindActionCreators(addEmployerContact, dispatch),
    onGetEmployerJob: bindActionCreators(getEmployerJob, dispatch),
    onAddEmployerJob: bindActionCreators(addEmployerJob, dispatch),
    onGetEmployerFile: bindActionCreators(getEmployerFile, dispatch),
    onAddEmployerFile: bindActionCreators(addEmployerFile, dispatch),
    onSearchEmployer: bindActionCreators(searchEmployer, dispatch),
    onGetEmployerData: bindActionCreators(getEmployerData, dispatch),
    onRemoveEmployerContact: bindActionCreators(
      removeEmployerContact,
      dispatch
    ),
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onRemoveEmployerFile: bindActionCreators(removeEmployerFile, dispatch),
    onUpdateEmployerManag: bindActionCreators(updateEmployerManag, dispatch),
    onUpdateEmployerFile: bindActionCreators(updateEmployerFile, dispatch),
    onUpdateEmployerContact: bindActionCreators(
      updateEmployerContact,
      dispatch
    ),
    onGetEmployerDocument: bindActionCreators(getEmployerDocument, dispatch),
    onAddEmployerDocument: bindActionCreators(addEmployerDocument, dispatch),
    onRemoveEmployerDocument: bindActionCreators(
      removeEmployerDocument,
      dispatch
    ),
    onUpdateEmployerDocument: bindActionCreators(
      updateEmployerDocument,
      dispatch
    ),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
    onGetEmployerCheckList: bindActionCreators(getEmployerCheckList, dispatch),
    onAddEmployerCheckList: bindActionCreators(addEmployerCheckList, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onAddEmployerEmail: bindActionCreators(addEmployerEmail, dispatch),
    onGetEmployerHistory: bindActionCreators(getEmployerHistory, dispatch),
    onGetAutoEmailImport: bindActionCreators(getAutoEmailImport, dispatch),
    onAddAutoEmailImport: bindActionCreators(addAutoEmailImport, dispatch),
    onAddDocumentTittle: bindActionCreators(addDocumentTittle, dispatch),
    onAddAnyTamplate: bindActionCreators(addAnyTamplate, dispatch),
    onUploadFile: bindActionCreators(uploadFile, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onDeleteCreateEmail: bindActionCreators(deleteCreateEmail, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onGetDocumentPdf: bindActionCreators(getDocumentPdf, dispatch),
    onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
    onAddFilteredType: bindActionCreators(addFilteredType, dispatch),
    onUpdateEmployerJob: bindActionCreators(updateEmployerJob, dispatch),
    onRemoveEmployerJob: bindActionCreators(removeEmployerJob, dispatch),
    onGetClientSearch: bindActionCreators(getClientSearch, dispatch),
    onGetClientEmp: bindActionCreators(getClientEmp, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onGetClientByEmpId: bindActionCreators(getClientByEmpId, dispatch),
    onLinkEmpWithClient: bindActionCreators(linkEmpWithClient, dispatch),
    onAddEmployerJobHistory: bindActionCreators(
      addEmployerJobHistory,
      dispatch
    ),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddDocWithName: bindActionCreators(addDocWithName, dispatch),
    onGetClientJobHis: bindActionCreators(getClientJobHis, dispatch),
    onGetSingleClientHis: bindActionCreators(getSingleClientHis, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PotentialEmployer);
