// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Tooltip,
  Input,
  Row,
  Col,
  DatePicker,
  Table,
} from "antd";
import {
  CloseOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";
import { apiRefresh } from "../../services/api";
var notSearch = true;
function AddCustomFields(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [connection, setConnection] = useState("");
  const [searchedConnections, setSearchedConnections] = useState("");
  const [connectionsData, setConnectionsData] = useState("");
  const [relation, setRelation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [answerTypeOptions, setAnswerTypeOptions] = useState([]);
  const [typesArray, setTypesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldType, setFieldType] = useState("1");
  const [fieldsList, setFieldsList] = useState([]);
  const [updateRecord, setUdpateRecord] = useState(null);

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Field For",
      dataIndex: "fieldFor",
      key: "fieldFor",
    },
    {
      title: "Field Name",
      dataIndex: "fieldName",
      key: "fieldName",
    },
    {
      title: "Field Data",
      dataIndex: "fieldData",
      key: "fieldData",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record, ind) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Edit" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              // onClick={() => {
              //   this.setState({ loading: true, templateObj: record });
              //   this.getLines(record.id);
              // }}
            >
              <EditOutlined
                onClick={() => onEdit(record)}
                style={{ fontSize: 14 }}
              />
              {/* <img src={Images.editBorderBlue} className="cv-action-icon" /> */}
            </a>
          </Tooltip>
          <Tooltip title="Delete" style={{ display: "inline-block" }}>
            {/* <a
                href="javascript:"
                style={{ marginRight: "10px" }}
                // onClick={() => {
                //   // this.setState({ loading: true });
                //   const invoiceTempOpt = {
                //     url: `v1/invoice/DeleteTemplate/${record.id}`,
                //   };
                //   invoiceTempOpt.types = [
                //     "TEMPLATE_LINES_SUCCESS",
                //     "TEMPLATE_LINES_FAILURE",
                //   ];
                //   apiRefresh
                //     .delete(invoiceTempOpt)
                //     .then((res) => {
                //       this.getTemplates();
                //     })
                //     .catch((err) => {
                //       this.setState({ loading: false });
                //       message.error("Failed");
                //     });
                // }}
              > */}
            {/* <DeleteOutlined /> */}
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                const delCustomFieldOpt = {
                  url: `v1/branch/CustomFeild`,
                };
                delCustomFieldOpt.types = [
                  "DEL_CUSTOM_FIELD_SUCCESS",
                  "DEL_CUSTOM_FIELD_FAILURE",
                ];
                setLoading(true);
                apiRefresh
                  .delete(delCustomFieldOpt, { id: record.id })
                  .then((res) => {
                    setLoading(false);
                    message.success("Custom Field deleted successfully!");
                    getCustomFields();
                  })
                  .catch((err) => {
                    setLoading(false);
                    message.error("Failed!");
                  });
              }}
              src={Images.deleteBlue}
              className="cv-action-icon"
            />
            {/* </a> */}
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    var answerTypes = [
      { value: "1", label: "Textbox" },
      // { value: "2", label: "Date" },
      { value: "3", label: "Textarea" },
    ];
    var types = [];
    for (var i = 0; i < answerTypes.length; i++) {
      types.push(
        <Option value={answerTypes[i].value}>{answerTypes[i].label}</Option>
      );
    }
    setTypesArray(answerTypes);
    setAnswerTypeOptions(types);

    getCustomFields();
  }, []);

  const userOwner = localStorage.getItem("userOwner");

  const { Option } = Select;
  const connectionsList = [];

  //   if (
  //     searchedConnections &&
  //     searchedConnections.clients &&
  //     searchedConnections.clients.length > 0
  //   ) {
  //     for (let i = 0; i < searchedConnections.clients.length; i++) {
  //       connectionsList.push(
  //         <Option key={searchedConnections.clients[i].id}>
  //           {searchedConnections.clients[i].firstName +
  //             " " +
  //             searchedConnections.clients[i].lastName}
  //         </Option>
  //       );
  //     }
  //   }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCustomFields = () => {
    const getCustomFieldOpt = {
      url: `v1/branch/CustomFeild`,
    };
    getCustomFieldOpt.types = [
      "GET_CUSTOM_FIELD_SUCCESS",
      "GET_CUSTOM_FIELD_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .get(getCustomFieldOpt)
      .then((res) => {
        setLoading(false);
        setFieldsList(res);
      })
      .catch((err) => {
        setLoading(false);
        setFieldsList([]);
      });
  };

  const onEdit = (record) => {
    setUdpateRecord(record);
    form.setFieldsValue({
      fieldFor: record.fieldFor,
      fieldType: record.fieldType,
      fieldName: record.fieldName,
      fieldData: record.fieldData,
    });
  };

  const onFinish = (values) => {
    let data = {
      id: updateRecord ? updateRecord.id : 0,
      fieldType: values.fieldType || "",
      fieldName: values.fieldName || "",
      fieldData: values.fieldData || "",
      fieldFor: values.fieldFor || "",
    };
    const addCustomFieldOpt = {
      url: `v1/branch/CustomFeild`,
    };
    addCustomFieldOpt.types = [
      "ADD_CUSTOM_FIELD_SUCCESS",
      "ADD_CUSTOM_FIELD_FAILURE",
    ];
    setLoading(true);
    if (updateRecord) {
      apiRefresh
        .put(addCustomFieldOpt, data)
        .then((res) => {
          setLoading(false);
          // Props.onGetClientProfile(sessionStorage.getItem("clientprofileid"));
          form.resetFields();
          getCustomFields();
          setUdpateRecord(null);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      apiRefresh
        .post(addCustomFieldOpt, data)
        .then((res) => {
          setLoading(false);
          // Props.onGetClientProfile(sessionStorage.getItem("clientprofileid"));
          form.resetFields();
          getCustomFields();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Spin spinning={loading} size="large">
        <div
          className="d-flex align-item Reminder-top"
          style={{ justifyContent: "space-between", paddingBottom: 20 }}
        >
          <div className="d-flex align-item">
            <div className="client-tag-top">
              <img src={Images.documentSync} className="sus-bottom-icon" />
            </div>
            <span className="top-text">Client Profile Custom Fields</span>
          </div>

          <LeftCircleFilled
            onClick={() => Props.onSetActiveInnerTab("")}
            className="ac-back-icon"
          />
        </div>
        {/* <Modal
        title="Add Custom Field"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="SAVE"
        footer={false}
      > */}
        <Form
          // {...layoutSendingReceipt}
          onFinish={onFinish}
          form={form}
          style={{ padding: 40 }}
        >
          {/* {this.getAddBankForm(this.props)} */}

          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div
                  className="label-form"
                  style={{
                    marginRight: "6px",
                    width: "30%",
                  }}
                >
                  Field For<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  name={"fieldFor"}
                  style={{ width: "70%" }}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select showSearch optionFilterProp="children">
                    <Option value="personal_information">
                      Personal Information
                    </Option>
                    <Option value="current_visa_detail">
                      Current Visa Detail
                    </Option>
                    <Option value="Medical_detail">Medical Detail</Option>
                    <Option value="passport_detail">Passport Detail</Option>
                    <Option value="second_passport_detail">
                      Secondary Passport Detail
                    </Option>
                    <Option value="login_detail">Login Detail</Option>
                    <Option value="nzqa_detail">NZQA Detail</Option>
                    <Option value="billing_address">Billing Address</Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div
                  className="label-form"
                  style={{
                    marginRight: "6px",
                    width: "30%",
                  }}
                >
                  Field Type<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  name={"fieldType"}
                  style={{ width: "70%" }}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => {
                      setFieldType(e);
                    }}
                  >
                    {answerTypeOptions}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div
                  className="label-form"
                  style={{
                    marginRight: "6px",
                    width: "30%",
                  }}
                >
                  Field Name<span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  name={"fieldName"}
                  style={{ width: "70%" }}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div
                  className="label-form"
                  style={{
                    marginRight: "6px",
                    width: "30%",
                  }}
                >
                  Field Data
                </div>

                {fieldType == "1" ? (
                  <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                    <Input />
                  </Form.Item>
                ) : fieldType == "2" ? (
                  <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                ) : (
                  fieldType == "3" && (
                    <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  )
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col
              xs={4}
              style={{
                display: "flex",
                marginTop: 20,
                justifyContent: "flex-end",
              }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  className={"button-blue"}
                  // htmlType="submit"
                  style={{ marginRight: "10px" }}
                  loading={loading}
                  // disabled={this.state.isButtonDisabled}
                  onClick={() => {
                    form.resetFields();
                    setUdpateRecord(null);
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className={"button-blue"}
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                  loading={loading}
                  // disabled={this.state.isButtonDisabled}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div style={{ marginBottom: 20 }}>
          <span
            className="top-text"
            style={{ fontSize: 19, fontWeight: "400" }}
          >
            Custom Fields List
          </span>
        </div>
        <Table
          dataSource={fieldsList}
          columns={columns}
          // pagination={{
          //   defaultPageSize: 20,
          // }}
        />
        {/* </Modal> */}
      </Spin>
    </div>
  );
}

export default AddCustomFields;
