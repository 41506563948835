import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Col,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AddNewMultipleContact from "./AddNewMultipleContact";

var userId = localStorage.getItem("userId");
const { Option } = Select;

const AddNewEmployerForm = ({
  onAddEmployerManag,
  onGetEmployerManag,

  employerManagRes,

  handleCancel,

  onAddEmployerContact,

  ongetEmployerContact,

  userDataEmp,

  setLoading,
  loading,

  onUploadAvatar,

  onUploadFile,
  addUploadFileRes,

  handleSetDefault,

  onAddDocWithName,
  docWithNameRes,
  countriesData,
  onGetCountries,
  onAddProcessingPerson,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isSelector, setIsSelector] = useState();
  const [agreementURL, setAgreement] = useState("");
  const [agreementName, setAgreementName] = useState("");
  useEffect(() => {
    setAgreement("");
    onGetCountries();
  }, []);
  useEffect(() => {
    setAgreementName("");
  }, []);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    var userId = localStorage.getItem("userId");
    console.log("Received values of form:", values);

    setLoading(true);

    const data = {
      clientId: "00000000-0000-0000-0000-000000000000",
      name: values && values.name && values.name.length > 0 ? values.name : "",
      email:
        values && values.email && values.email.length > 0 ? values.email : "",
      secondaryEmail:
        values && values.secondaryEmail && values.secondaryEmail.length > 0
          ? values.secondaryEmail
          : "",
      contactNo:
        values && values.contact_no && values.contact_no.length > 0
          ? values.contact_no
          : "",
      business:
        values && values.business && values.business.length > 0
          ? values.business
          : "",
      city: values && values.city && values.city.length > 0 ? values.city : "",
      contactPerson:
        values && values.contact_person && values.contact_person.length > 0
          ? values.contact_person
          : "",
      mobile:
        values && values.mobile && values.mobile.length > 0
          ? values.mobile
          : "",
      website:
        values && values.website && values.website.length > 0
          ? values.website
          : "",
      jobSector:
        values && values.job_sector && values.job_sector.length > 0
          ? values.job_sector
          : "",
      nzbn: values && values.nzbn ? values.nzbn : "",
      irdNumber: values && values.irdNumber ? values.irdNumber : "",
      occupation:
        values && values.occupation && values.occupation.length > 0
          ? values.occupation
          : "",
      numberOfEmployees:
        values && values.company_size && values.company_size.length > 0
          ? values.company_size
          : "",
      yearsOfBusiness:
        values && values.how_many_years && values.how_many_years.length > 0
          ? values.how_many_years
          : "",
      isPotential: false,
      employerType:
        values && values.employer_Type ? values.employer_Type : "Employer",
      address:
        values && values.address && values.address.length > 0
          ? values.address
          : "",
      agreementUrl: agreementURL,
      agreementFileName: agreementName,
      xeroID: "00000000-0000-0000-0000-000000000000",
      createdBy: userId,
      jobStatus: 0,
      accredationStartDate: "1900-01-01T00:00:00+00",
      accredationExpiryDate: "1900-01-01T00:00:00+00",
      countryCodeId: values.countryCodeId ? parseInt(values.countryCodeId) : 64,
    };

    onAddEmployerManag(data)
      .then((res) => {
        let dataPP = {
          clientId: res.payload,
          userId: localStorage.getItem("userId"),
        };
        onAddProcessingPerson(dataPP);
        onGetEmployerManag();
        setLoading(false);
        handleSetDefault();
        message.success("Successfully Added!");
        handleCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onHandleChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].size > 50 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 50MB");
    }
    if (fileList && fileList.length > 1) {
      message.warning("You can upload 1 files only");
    } else setFileList(fileList);

    let formData = new FormData();
    formData.append("BranchId", localStorage.getItem("selectedBranchId"));
    if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile =
          fileList[i] && fileList[i].originFileObj && fileList[i].originFileObj;

        formData.append("file", selectedFile);

        onAddDocWithName(formData).then((res) => {
          setAgreement(res.payload.fileURL);
          setAgreementName(res.payload.fileName);
        });
      }
    }
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  console.log(
    "docWithNameResdocWithNameResdocWithNameResdocWithNameResdocWithNameRes===>>>123",
    docWithNameRes
  );
  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          onFinish={onFinish}
          form={form}
          className="width-100  "
          name="main"
        >
          <div className="border-box-add-emp add-employer-para">
            <Form.Item style={{ padding: "0px" }}>
              <Row gutter={8}>
                <Col
                  style={{ display: "flex", alignItems: "center" }}
                  span={12}
                >
                  <p className="emp-section-font">Type</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="employer_Type">
                    <Select defaultValue="Employer">
                      <Select.Option key="Employers">Employers</Select.Option>
                      <Select.Option key="Supplier">Supplier</Select.Option>
                      <Select.Option key="Recruiters">Recruiters</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <p
                  className="emp-section-font"
                  style={{
                    paddingTop: "18px",
                  }}
                >
                  Name<span className="required-field">*</span>
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={false}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <p
                  className="emp-section-font"
                  style={{
                    paddingTop: "18px",
                  }}
                >
                  Business/Company Name
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={false}
                  name="business"
                  rules={[
                    { required: false },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <p
                  className="emp-section-font"
                  style={{
                    paddingTop: "18px",
                  }}
                >
                  Email
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={false}
                  name="email"
                  rules={[
                    { required: false },
                    { type: "email" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <p
                  className="emp-section-font"
                  style={{
                    paddingTop: "18px",
                  }}
                >
                  Secondary Email
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={false}
                  name="secondaryEmail"
                  rules={[
                    { required: false },
                    { type: "email" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 100) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Secondary Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <p
                  className="emp-section-font"
                  style={{
                    paddingTop: "18px",
                  }}
                >
                  Contact No
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={false}
                  name="contact_no"
                  rules={[
                    { required: false },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 50) {
                          return Promise.reject("Character limit exceeded");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Contact No" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="city" required={true}>
              <Row gutter={8}>
                <Col span={12}>
                  <p>City</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="City" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="address" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">Address</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Address" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="contact_person" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">Contact Person</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Contact Person" />
                </Col>
              </Row>
            </Form.Item>
            <Row>
              <Col span={12}>
                <p className="emp-section-font">Mobile</p>
              </Col>
              <Col span={12}>
                <div className="form-mobile-number">
                  <Form.Item
                    className={"add-client-form-inputs"}
                    name="countryCodeId"
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={"Select"}
                      style={{
                        width: "150px",
                        minWidth: "150px",
                      }}
                      dropdownClassName="dropdown-options-width"
                    >
                      {countriesData &&
                        countriesData.items
                          .filter(
                            (obj) =>
                              obj.name.toLowerCase() === "new zealand" ||
                              obj.name.toLowerCase() === "australia" ||
                              obj.name.toLowerCase() === "canada"
                          )
                          .map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option
                                value={data.callingCode}
                                style={{ fontSize: "14px" }}
                              >
                                +{data.callingCode} - {data.name}
                              </Option>
                            );
                          })}

                      {countriesData &&
                        countriesData.items.map((data, index) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          if (
                            data.name.toLowerCase() === "new zealand" ||
                            data.name.toLowerCase() === "australia" ||
                            data.name.toLowerCase() === "canada"
                          ) {
                          } else {
                            return (
                              <Option
                                value={data.callingCode}
                                style={{ fontSize: "14px" }}
                              >
                                +{data.callingCode} - {data.name}
                              </Option>
                            );
                          }
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="mobile"
                    required={false}
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Mobile" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Form.Item name="website" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">Website</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Website" />
                </Col>
              </Row>
            </Form.Item>

            <Row gutter={8}>
              <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                <p className="emp-section-font">Job Sector</p>
              </Col>
              <Col span={12}>
                <Form.Item name="job_sector" required={false}>
                  <Select
                    defaultValue="Select job sector"
                    onChange={handleSectorChange}
                  >
                    <Select.Option value="Select job sector">
                      Select job sector
                    </Select.Option>
                    <Select.Option value="Administrative">
                      Administrative
                    </Select.Option>
                    <Select.Option value="AgricultureFarming">
                      Agriculture / Farming
                    </Select.Option>
                    <Select.Option value="Automotive">Automotive</Select.Option>
                    <Select.Option value="Construction">
                      Construction
                    </Select.Option>
                    <Select.Option value="Electrical">Electrical</Select.Option>
                    <Select.Option value="Engineer">Engineer</Select.Option>
                    <Select.Option value="Finance">Finance</Select.Option>
                    <Select.Option value="FMCG">FMCG</Select.Option>
                    <Select.Option value="Hospitality">
                      Hospitality
                    </Select.Option>
                    <Select.Option value="Human Resources">
                      Human Resources
                    </Select.Option>
                    <Select.Option value="Insurance">Insurance</Select.Option>
                    <Select.Option value="ICT">ICT</Select.Option>
                    <Select.Option value="Legal">Legal</Select.Option>
                    <Select.Option value="Marketing">Marketing</Select.Option>
                    <Select.Option value="Medical">Medical</Select.Option>
                    <Select.Option value="Real estate">
                      Realestate
                    </Select.Option>
                    <Select.Option value="Retail">Retail</Select.Option>
                    <Select.Option value="Sales">Sales</Select.Option>
                    <Select.Option value="Supply chain">
                      Supply chain
                    </Select.Option>
                    <Select.Option value="Teachers">Teachers</Select.Option>
                    <Select.Option value="Trades">Trades</Select.Option>
                    <Select.Option value="Not Employed">
                      Not Employed
                    </Select.Option>
                    <Select.Option value="Transportation">
                      Transportation
                    </Select.Option>
                    <Select.Option value="Manufacturing">
                      Manufacturing
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                <p className="emp-section-font">NZBN</p>
              </Col>
              <Col span={12}>
                <Form.Item name="nzbn" required={false}>
                  <Input placeholder="NZBN" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col style={{ display: "flex", alignItems: "center" }} span={12}>
                <p className="emp-section-font">IRD Number</p>
              </Col>
              <Col span={12}>
                <Form.Item name="irdNumber" required={false}>
                  <Input placeholder="IRD Numberav" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="occupation" required={false}>
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">Occupation</p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Occupation" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="company_size"
              required={false}
              rules={[
                {
                  max: 18,
                  message: "Entered characters should be less than 18",
                },
              ]}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">
                    Company Size (Number of Employees)
                  </p>
                </Col>
                <Col span={12}>
                  <Input placeholder="Company Size (Number of Employees)" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="how_many_years"
              required={false}
              rules={[
                {
                  max: 18,
                  message: "Entered characters should be less than 18",
                },
              ]}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">
                    How Many Years Has This Business In Operation
                  </p>
                </Col>
                <Col span={12}>
                  <Input placeholder="How Many Years Has This Business In Operation" />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <p className="emp-section-font">Agreement</p>
                </Col>
                <Col span={12} className="upload-button">
                  <Upload
                    single
                    onChange={onHandleChange}
                    beforeUpload={() => false}
                    fileList={fileList}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Col>
              </Row>
            </Form.Item>
            {/* <AddNewMultipleContact
            onAddEmployerContact={onAddEmployerContact}
            ongetEmployerContact={ongetEmployerContact}
            handleCancel={handleCancel}
            userDataEmp={userDataEmp}
          /> */}
          </div>
          <div className="add-emp-btn d-flex flex-end">
            <Form.Item>
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                className="login-form-button save-btn button-blue"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="login-form-button save-btn button-blue"
                onClick={handleCancel}
              >
                Close
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default AddNewEmployerForm;
