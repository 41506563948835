// @flow
import * as React from "react";
import styled from "styled-components";
import "./HeaderBarStyles.css";
import logo from "../../images/logo.png";
import Select from "react-select";
import navMenu from "../../images/nav-collaps.png";
import { Images } from "../../Themes";
import { Link } from "react-router-dom";

import { useHistory } from "react-router";

const PotentialHeaderBarTabs = (Props) => {
  const history = useHistory();
  let onClick = (linkName) => {
    var linkSplit = linkName.split("?");
    if (
      linkName === "/employer-management" ||
      linkName === "/technical-support"
    ) {
      Props && Props.callback();
    } else {
    }
    if (linkName === "/school-management/accounts") {
      Props.setAddInvoiceValueForInvoice("invoices");
      Props.setActiveTab("invoice");
      Props.setNewInvoiceTab("");
      Props.setActivePath("/school-management/accounts");
    } else if (linkName === "/school-management/invoice") {
      Props.setAddInvoiceValueForInvoice("add");
      Props.setActiveTab("invoice");
      Props.setNewInvoiceTab("newInvoice");
      Props.setActivePath("/school-management/invoice");
    } else if (linkName === "/school-management/schools") {
      Props.setActiveTab("schools");
      Props.setActivePath("/school-management/schools");
    } else if (linkName === "/school-management/student-list") {
      Props.setActiveTab("students");
      Props.setActivePath("/school-management/student-list");
    } else if (linkName === "/school-management/commission-reminder") {
      Props.setActiveTab("reminders");
      Props.setActivePath("/school-management/commission-reminder");
    } else if (linkSplit[0] == "/super-user-setting") {
      history.push({
        pathname: linkSplit[0],
        search: "?" + linkSplit[1],
        state: { detail: linkSplit[1] },
      });
    } else {
      history.push(linkName);
    }
    //     history.push(linkName{pathname: '/template',
    // search: '?query=abc',
    // state: { detail: response.data }});
  };
  return (
    <div>
      <div className="pc-header-bar">
        {Props.data &&
          Props.data.map((item, index) => {
            return (
              <>
                {/* eslint-disable-next-line no-undef */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() =>
                      onClick(
                        item.tabName == "Time Tracking" &&
                          localStorage.getItem("userBranchPermissions") &&
                          !JSON.parse(
                            decodeURIComponent(
                              escape(
                                window.atob(
                                  localStorage.getItem("userBranchPermissions")
                                )
                              )
                            )
                          ).branchPermission.find(
                            (x) => x.name.toLowerCase() == "timetracking"
                          ).status
                          ? "/not-allowed"
                          : item.linkName
                      )
                    }
                    className="header-bar-text-div"
                    style={{
                      backgroundColor:
                        Props.activeTab == item.tabName
                          ? "#33aada"
                          : "transparent",
                      paddingLeft: index == 0 ? 15 : 5,
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="header-text"
                      style={{
                        fontWeight:
                          Props.activeTab == item.tabName ? "500" : "300",
                        fontSize: 13,
                      }}
                    >
                      {item.tabName}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ fontSize: 20, fontWeight: "100", color: "#fff" }}
                    >
                      |
                    </span>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default PotentialHeaderBarTabs;
