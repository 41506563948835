import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Tabs,
  Layout,
  Row,
  Col,
  Image,
  Modal,
  DatePicker,
  message,
  Input,
  Comment,
  Select,
  Spin,
  Button,
  Form,
  Checkbox,
  Tag,
  Avatar,
  Tooltip,
} from "antd";
import {
  UnorderedListOutlined,
  PlusCircleOutlined,
  FileSyncOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import ClientSideBar from "../Profile/ProfileRightSide/ClientSideBar";
import UpdataVisaStatus from "../Profile/ProfileRightSide/UpdataVisaStatus";
import StartNewVisa from "../Components/ClientVisa/StartNewVisa";
import UpdateClientAdmission from "../Components/ClientAdmission/UpdateAdmission";
import ClientEmployerJobs from "../Components/ClientJobs/ClientEmployerJobs";

import rightNav from "../images/log-right-nav-1.png";
import rightNavTwo from "../images/log-right-nav-2.png";
import rightNavThr from "../images/log-right-nav-3.png";
import rightNavFour from "../images/log-right-nav-4.png";
import rightNavFiv from "../images/log-right-nav-5.png";
import rightNavSix from "../images/log-right-nav-6.png";
import rightNavSev from "../images/log-right-nav-7.png";
import rightNavEight from "../images/log-right-nav-8.png";
import rightNavNine from "../images/log-right-nav-9.png";
import AddNewAdmission from "./ClientAdmission/AddNewAdmission";
import Balance from "../Components/Balance/Balance";
import navMenu from "../images/nav-collaps.png";
import add from "../images/add.png";
import Moment from "moment";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  getClientJobHistoryCurrent,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  updateReminder,
  getClientTasks,
  updetaTasks,
  setActiveKey,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  removeFollower,
  getVisaTypeByCountry,
  getAdmissionProgram,
  getAdmissionStatuses,
  updAdmissionStatus,
  startNewApplication,
  getCategory,
  getSchools,
  getSchoolLevel,
  addClientAdmission,
  getSchoolType,
  getReminders,
  getEmployerCase,
  getEmployerData,
  getBranchPipeline,
  getTeamMember,
  getPermissonUser,
} from "../store/Actions";

import {
  AppleOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { Images } from "../Themes";
import RemindersTab from "./Reminder/RemindersTab";
import "./sideBar.css";
import activityData from "./ClientActivity/ActivityData";
import moment from "moment";
import ClientMessages from "./ClientMessages/ClientMessages";
import DealCard from "./Deals";
import PurchaseSubscription from "../Common/PurchaseMessage";

const { TabPane } = Tabs;
const { TextArea } = Input;

const { Header, Sider, Content } = Layout;

function EmployerSidebar(
  props
  // {

  // newApplicationWith,

  // triggerTask,
  // updateTrigger,
  // memberType,
  // triggerCmsForTask,
  // onLoadClient,
  // }
) {
  //   const [activeKeyState, setActiveKeyState] = useState(props.activeKey);

  const [handleValue, setHandleValue] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [allTasks, setAllTasks] = useState([]);
  const [isCompleted, setCompleted] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [followerModal, setFollowerModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [fileNoteModal, setFileNoteModal] = useState(false);
  const [fileNoteLoader, setFileNoteLoader] = useState(false);
  const [filenote, setFileNote] = useState(null);
  const [addTaskLoader, setAddTaskLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [followerLoader, setFollowerLoader] = useState(false);
  const [titleLoading, setTitleLoading] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [comment, setComment] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [followerId, setFollowerId] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [family, setFamily] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [comments, setTaskComments] = useState([]);
  const [title, setTitle] = useState(null);
  const [dateLoading, setDateLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const formRef = useRef();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onHandleValue = useCallback((key) => {
    console.log("abc", key);
    props.onSetActiveKey(key);
    setHandleValue(key);
  });
  useEffect(() => {
    if (props.activeKey) {
      onHandleValue(props.activeKey);
    }
  }, [props.activeKey]);

  useEffect(() => {
    if (handleValue && !props.activeKey) {
      setHandleValue(null);
    }
  }, [props.activeKey]);

  useEffect(() => {
    let clientInfo = props.clientId;
    setClientId(clientInfo);

    if (clientInfo && props.activeKey === "3") {
      getTasks();
      getUsers();
    }
  }, [props.activeKey]);
  useEffect(() => {
    if (props.triggerTask && props.activeKey === "3") {
      triggerCurrentTasks();
    }
  }, [props.triggerTask, props.activeKey]);

  const triggerCurrentTasks = () => {
    getTasks();
    // props.updateTrigger();
  };

  const UserLinks = () => {
    getUsers();
  };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const getTasks = async () => {
    setTaskLoader(true);

    let id = null;
    if (selectedMember) id = selectedMember;
    else id = props.clientId;
    //
    props
      .onGetClientTask(id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
        setCompleted(false);
      })
      .catch((error) => {
        // message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
        setCompleted(false);
      });
  };

  const getMemberTask = (id) => {
    setTaskLoader(true);
    props
      .onGetClientTask(id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
      })
      .catch((error) => {
        message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
      });
  };

  const getComments = (data) => {
    setCommentLoader(true);
    let comments = [];
    props
      .onGetTaskComments(data.id)
      .then((res) => {
        res.payload.items.forEach((item) => {
          comments.push(item);
        });
        setTaskComments(comments);
        setCommentLoader(false);
        console.log(res);
      })
      .catch((error) => {
        setTaskComments(comments);
        setCommentLoader(false);
      });
  };

  const setFileNoteValue = (data) => {
    const taskDescription = data.taskDescription;
    let fileNoteValue =
      `${data.taskTitle}` + "\n" + `task description: ` + `${taskDescription}`;
    props
      .onGetTaskComments(data.id)
      .then((res) => {
        const comments = res.payload.items;
        comments.forEach((item) => {
          fileNoteValue =
            fileNoteValue + "\n" + item.userName + ": " + item.description;
        });
        setFileNote(fileNoteValue);
      })
      .catch((error) => {
        setFileNote(fileNoteValue);
      });
  };

  const getFamilyTask = (familyId) => {
    setTaskLoader(true);
    props
      .onGetClientTask("00000000-0000-0000-0000-000000000000", familyId)
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
      })
      .catch((error) => {
        message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
      });
  };

  const getUsers = () => {
    props.onGetAllUsers().then((res) => {
      const usersInfo = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      console.log("=======users==,", usersInfo);
      setUsers(usersInfo);
    });
    props.onGetPermProcessingPerson(true).then((res) => {
      const usersInfoList = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      setUsersList(usersInfoList);
    });
  };
  const updateTaskDate = (data) => {
    setDateLoading(true);
    const userId = localStorage.getItem("userId");
    let updatedate = {
      id: selectedTask.id,
      taskDate: data.format(),
      taskTitle: selectedTask.taskTitle,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    props
      .onUpdateTask(updatedate)
      .then((res) => {
        setDateLoading(false);
        message.success("Successfully Rescheduled!");
        setDateModal(false);
        getTasks();
      })
      .catch((error) => {
        setDateLoading(false);
        message.error("Unable to Reschedule");
      });
  };

  const updateTaskTitle = () => {
    setTitleLoading(true);
    let updatedate = {
      id: selectedTask.id,
      taskDate: selectedTask.taskDate,
      taskTitle: title,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    props
      .onUpdateTask(updatedate)
      .then((res) => {
        setTitleLoading(false);
        message.success("Successfully Updated!");
        setTitleModal(false);
        setTitle(null);
        if (props.triggerCmsForTask) props.props.triggerCmsForTask();
        getTasks();
      })
      .catch((error) => {
        setTitleLoading(false);
        setTitle(null);
        message.error("Unable to Update");
      });
  };

  const addFollower = (data) => {
    setModalLoader(true);
    let payload = {
      taskId: selectedTask.id,
      userId: data,
    };
    let userAttached = users && users.find((obj) => obj.value == data);

    props
      .onAddTaskFollower(payload)
      .then((res) => {
        getFollower(selectedTask);
        setModalLoader(false);
        message.success("Successfully Added!");
      })
      .catch((error) => {
        setModalLoader(false);
        message.error("Unable to add Follower!");
      });
  };

  const getFollower = (data) => {
    props
      .onGetTaskFollowers(data.id)
      .then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
      })
      .catch((error) => {
        setFollowers([]);
      });
  };

  const deleteTask = () => {
    setDeleteLoader(true);
    const remove = {
      id: selectedTask.id,
      delete: true,
    };
    props
      .onRemoveTasks(remove)
      .then(() => {
        setDeleteLoader(false);
        message.success("Successfully Deleted!");
        setDeleteModal(false);
        if (props.triggerCmsForTask) props.triggerCmsForTask();

        getTasks();
      })
      .catch((error) => {
        setDeleteLoader(false);
        message.error("Unable to Delete!");
      });
  };

  const revertCompletedTask = (data) => {
    const revert = {
      id: data.id,
      taskDate: data.taskDate,
      taskTitle: data.taskTitle,
      taskDescription: data.taskDescription,
      subjectId: data.subjectId,
      isPontential: data.isPontential,
      isCompleted: false,
    };
    props
      .onUpdateTask(revert)
      .then(() => {
        message.success("Successfully Converted!");
        getTasks();
        if (props.triggerCmsForTask) props.triggerCmsForTask();
      })
      .catch((error) => {
        message.error("Unable to Revert!");
      });
  };

  const completeTask = (data) => {
    const completed = {
      id: data.id,
    };
    props
      .onUpdateCompletedTask(completed)
      .then(() => {
        message.success("Successfully Completed!");
        getTasks();
        // let userName = localStorage.getItem("userName");
        // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        // let myData = {
        //   clientName: profileData.fullName,
        //   logMessage:
        //     "\n Task titled " + data.taskTitle + " completed by " + userName,
        //   date: moment(new Date()).format("DD/MM/YYYY"),
        //   logType: "Client Tasks",
        //   invoiceId: "0",
        // };
        // activityData(myData);
        if (props.triggerCmsForTask) props.triggerCmsForTask();
      })
      .catch((error) => {
        message.error("Unable to Complete!");
      });
  };

  // const addTaskFileNote = (data) => {
  //   const userId = localStorage.getItem("userId");
  //   const clientId = sessionStorage.getItem("clientProfileIdMain");
  //   const payload = {
  //     clientId: clientId,
  //     fileNote: `${data.taskTitle} ${data.taskDescription}`,
  //     createdBy: userId,
  //   };
  //   onAddTaskFileNote(payload)
  //     .then(() => {
  //       message.success("File Note Added!");
  //     })
  //     .catch((error) => {
  //       message.error("Unable to add File Note!");
  //     });
  // };

  const showComplete = (data) => {
    if (data) {
      let taskItems = [...tasks];
      taskItems = taskItems.filter((item) => item.isCompleted);
      setTasks(taskItems);
    } else setTasks(allTasks);
  };

  const onChangeThreadHandler = (type, val) => {
    setTitle(val);
  };

  const renderTitleModal = () => (
    <Modal
      title="Update Task Title"
      visible={titleModal}
      confirmLoading={titleLoading}
      onOk={() => updateTaskTitle()}
      onCancel={() => setTitleModal(false)}
      okText="Update"
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
        <Input
          value={title}
          onChange={(e) => onChangeThreadHandler("title", e.target.value)}
          style={{ width: 283 }}
          placeholder="Enter Task Title"
        />
      </div>
    </Modal>
  );

  const onFinish = (values) => {
    setAddTaskLoader(true);
    let followers = [];
    let taskMemberId = null;
    if (selectedMember) taskMemberId = selectedMember;
    else taskMemberId = props.clientId;

    const userId = localStorage.getItem("userId");
    const branchId = localStorage.getItem("selectedBranchId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: taskMemberId
        ? taskMemberId
        : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: false,
      isCompleted: false,
    };

    props
      .onAddDailyTasks(data)
      .then((res) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        setTaskModal(false);
        if (props.triggerCmsForTask) props.triggerCmsForTask();
        getTasks();
      })
      .catch((error) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Unable to Add Task!");
      });
  };

  const renderAddTaskModal = () => (
    <Modal
      title="Add Task"
      visible={taskModal}
      onCancel={() => setTaskModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={addTaskLoader}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          {...layout}
        >
          <div className="add-tasks">
            <div>
              <div className="mdtr-modal-gray-cont">
                <div className="modal-parts">
                  <Form.Item
                    className="form-parts"
                    label="Task Title"
                    name="task_title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="form-parts"
                    label="Task Description"
                    name="task_description"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} showCount maxLength={250} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts"
                    label="Select Date"
                    name="select_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts multi-select-option"
                    label="Add Followers"
                    name="add_followers"
                    // rules={[{ message: "Please Add Followers!" }]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      options={usersList}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Form.Item>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      className="task-blue"
                      htmlType="submit"
                    >
                      ADD TASK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    setSubmit(true);
    const userId = localStorage.getItem("userId");
    let payload = {
      taskId: selectedTask.id,
      description: comment,
    };
    props
      .onAddTaskComment(payload)
      .then((res) => {
        setSubmit(false);
        setComment(null);
        getComments(selectedTask);
      })
      .catch((error) => {
        setSubmit(false);
        setComment(null);
      });
  };

  const renderDeleteModal = () => (
    <Modal
      title="Delete Task"
      visible={deleteModal}
      confirmLoading={deleteLoader}
      onOk={() => deleteTask()}
      onCancel={() => setDeleteModal(false)}
      okText="Delete"
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <div style={{ marginTop: 15, width: "70%", marginLeft: 80 }}>
        <p style={{ fontSize: 14 }}>
          Are you sure you want to delete this Task ?
        </p>
      </div>
    </Modal>
  );

  const renderAddComment = () => (
    <Modal
      title="Task Details"
      visible={commentModal}
      onCancel={() => setCommentModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={modalLoader}>
        <div className="mdtr-modal-gray-cont" style={{ marginBottom: 10 }}>
          <div class="d-flex">
            <Select
              onSearch={() => UserLinks()}
              showSearch
              allowClear
              options={usersList}
              style={{ width: "40%" }}
              placeholder="Search to Followers"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={(option) => {
                if (option) addFollower(option);
              }}
            />
            <div style={{ width: "40%", marginLeft: 94 }}>
              <DatePicker
                allowClear
                placeholder="Reschedule Task"
                onChange={(date) => {
                  if (date) updateTaskDate(date);
                }}
                format={"DD/MM/YYYY"}
              />
            </div>
          </div>
        </div>
        <Form name="basic" initialValues={{ remember: true }}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="d-flex space-between ">
                <div className="follower-row d-flex">
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    <h4>Followers</h4>
                  </span>
                  {followers.length
                    ? followers.map((item) => (
                        <span>
                          {item.imgURL &&
                          item.imgURL != null &&
                          item.imgURL != "" ? (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item)}
                                />
                                <Avatar
                                  src={
                                    <Image
                                      src={item.imgURL}
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item)}
                                />
                                <Avatar
                                  className="mar-right"
                                  size="small"
                                  style={{ height: 32, width: 32 }}
                                  icon={
                                    <UserOutlined
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      ))
                    : null}
                </div>
              </div>
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleChange(e)}
                        value={comment}
                        showCount
                        maxLength={250}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        Comment
                      </Button>
                    </view>
                  }
                />
                <div>
                  <div
                    className="d-flex comment-width"
                    style={{ alignItems: "center" }}
                  >
                    <Spin spinning={commentLoader} size="large">
                      <div>
                        {selectedTask ? (
                          <div
                            style={{
                              borderBottom: "1px solid #ccc",
                              width: "100%",
                              marginBottom: 10,
                            }}
                          >
                            <div>
                              <h5>Task Description: </h5>
                            </div>
                            <div>
                              <p>{selectedTask.taskDescription}</p>
                            </div>
                          </div>
                        ) : null}
                        {comments.length
                          ? comments.map((item, index) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  width: 451,
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  class="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  {item.imgURL &&
                                  item.imgURL != null &&
                                  item.imgURL != "" ? (
                                    <Avatar
                                      src={
                                        <Image
                                          src={item.imgURL}
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  ) : (
                                    <Avatar
                                      className="mar-right"
                                      size="small"
                                      style={{ height: 32, width: 32 }}
                                      icon={
                                        <UserOutlined
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  )}
                                  <div style={{ marginLeft: 3 }}>
                                    <h4>{item.userName}</h4>
                                    <span style={{ fontSize: 12 }}>
                                      {moment(item.createdDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  return (
    <Fragment>
      {/* <h3 onClick={() => setHandleValue(null)}>Hide</h3> */}
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: props.activeKey ? "100%" : "80%",
          }}
        >
          <Col xs={2} offset={1}>
            <Image
              style={{ cursor: "pointer" }}
              width={17}
              src={navMenu}
              preview={false}
              onClick={(key) => {
                if (props.activeKey) {
                  props.onSetActiveKey(null);
                } else {
                  props.onSetActiveKey("1");
                }
                setHandleValue(!handleValue);
              }}
            />
          </Col>
        </div>
      </Row>
      <div style={{ marginTop: 50 }}>
        <div className={handleValue ? "client-tabs" : "short-tabs"}>
          <Tabs
            // activeKey={activeTabNewVisa ? "3" : "1"}
            tabPosition="left"
            style={{
              height: "auto",
              backgroundColor: "#F3F4F6",
            }}
            activeKey={props.activeKey}
            onChange={(value) => onHandleValue(value)}
          >
            <TabPane
              style={{ width: "100%", paddingLeft: 15 }}
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNav} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Update Case Status
                  </span>
                </div>
              }
              key="1"
            >
              <UpdataVisaStatus
                visaStatusData={props.visaStatusData}
                onUpdateCaseStatus={props.onUpdateCaseStatus}
                visaAppData={props.visaAppData}
                onGetVisaApplication={props.onGetVisaApplication}
                onSetActiveKey={props.onSetActiveKey}
                onUpdatePriority={props.onUpdatePriority}
                visaPriorityRes={props.visaPriorityRes}
                onSignedVisa={props.onSignedVisa}
                onPaidVisa={props.onPaidVisa}
                activeKey={props.activeKey}
                onGetVisaStatus={props.onGetVisaStatus}
                clientId={props.clientId}
                isEmployer={true}
                onGetEmployerCase={props.onGetEmployerCase}
                employerCaseRes={props.employerCaseRes}
                onGetEmployerData={props.onGetEmployerData}
                profileData={props.profileData}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavThr} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Send SMS
                  </span>
                </div>
              }
              key="2"
            >
              <ClientMessages
                clientId={props.clientId}
                mobNumber={props.profileData && props.profileData.mobile}
                callingCode={
                  props.profileData && props.profileData.countryCodeId
                }
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavFour} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Tasks
                  </span>
                </div>
              }
              key="3"
            >
              <div style={{ padding: 10, height: 54 }}>
                {/* <Select
                  showSearch
                  labelInValue
                  options={family}
                  style={{ width: "60%" }}
                  placeholder="Search Family"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                  onChange={(option) => {
                    getMemberTask(option.value);
                    setSelectedMember(option.value);
                  }}
                /> */}
                <Checkbox
                  checked={isCompleted}
                  disabled={false}
                  onChange={(e) => {
                    showComplete(e.target.checked);
                    setCompleted(!isCompleted);
                  }}
                />
                <span style={{ marginLeft: 10 }}>Show Completed</span>

                <Button
                  style={{ float: "right" }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="medium"
                  onClick={() => {
                    setTaskModal(true);
                  }}
                />
              </div>

              <Spin
                style={{ marginLeft: "37%" }}
                size="large"
                spinning={taskLoader}
              >
                {tasks.length ? (
                  tasks.map((item, index) => (
                    <div key={index}>
                      <div className="taskListUI">
                        <div
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div title="Complete/Revert">
                              <Checkbox
                                checked={item.isCompleted}
                                disabled={false}
                                onChange={() => {
                                  if (item.isCompleted)
                                    revertCompletedTask(item);
                                  else completeTask(item);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedTask(item);
                                setCommentModal(true);
                                getComments(item);
                                getFollower(item);
                              }}
                            >
                              <span
                                style={{
                                  color: "#1281BC",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  wordBreak: "break-all",
                                }}
                              >
                                {item && item.taskTitle}
                              </span>
                            </div>
                          </div>
                          <div style={{ display: "flex", marginTop: 10 }}>
                            <span>
                              (
                              {Moment(item && item.taskDate).format(
                                "DD/MM/YYYY"
                              )}
                              )
                            </span>
                            <div
                              style={{ marginLeft: "auto", display: "flex" }}
                            >
                              <div
                                className="mdtr-user-cont"
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  marginLeft: 5,
                                }}
                                title="Update Task Title"
                              >
                                <img
                                  onClick={() => {
                                    setTitle(item.taskTitle);
                                    setSelectedTask(item);
                                    setTitleModal(true);
                                  }}
                                  src={Images.fileNotes}
                                  style={{ width: 15, height: 15 }}
                                />
                              </div>
                              {/* <div
                                className="mdtr-user-cont"
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  marginLeft: 5,
                                }}
                                title="Add To File Note"
                              >
                                <img
                                  onClick={() => {
                                    // addTaskFileNote(item);
                                    setSelectedTask(item);
                                    setFileNoteModal(true);
                                    setFileNoteValue(item);
                                  }}
                                  src={add}
                                  style={{ width: 15, height: 15 }}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <span>No Task Found</span>
                  </div>
                )}
              </Spin>
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavSix} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Reminders
                  </span>
                </div>
              }
              key="4"
            >
              <RemindersTab
                remindersRes={props.clientRemindersRes}
                onUpdateReminder={props.onUpdateReminder}
                onGetReminder={props.onGetClientReminders}
                activeKey={props.activeKey}
                clientId={props.clientId}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavSev} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Client Tags
                  </span>
                </div>
              }
              key="5"
            >
              <ClientSideBar
                onGetClientTag={props.onGetClientTag}
                clientTagRes={props.clientTagRes}
                onGetProfileClientTag={props.onGetProfileClientTag}
                getClientRes={props.getClientRes}
                onAddProfileClientTag={props.onAddProfileClientTag}
                addProfielTagRes={props.addProfielTagRes}
                onRemoveProfileClientTag={props.onRemoveProfileClientTag}
                removeProfileTagRes={props.removeProfileTagRes}
                activeKey={props.activeKey}
                clientId={props.clientId}
                isEmployer={true}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavSev} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Deals
                  </span>
                </div>
              }
              key="6"
            >
              {localStorage.getItem("userBranchPermissions") &&
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userBranchPermissions"))
                  )
                )
              ).branchPermission.find((x) => x.name.toLowerCase() == "deals")
                .status ? (
                <DealCard
                  clientId={props.clientId}
                  onGetTeamMember={props.onGetTeamMember}
                  teamMembers={props.teamMembers}
                  onGetBranchPipeline={props.onGetBranchPipeline}
                  brnchPipelineData={props.brnchPipelineData}
                  clientType="employer"
                />
              ) : (
                <PurchaseSubscription />
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
      {renderTitleModal()}
      {renderDeleteModal()}
      {renderAddTaskModal()}
      {renderAddComment()}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    countryVisaTypeData: state.clientVisaReducer.countryVisaTypeData,
    admissionProgramData: state.clientAdmissionReducer.admissionProgramData,
    admissionStatusData: state.clientAdmissionReducer.admissionStatusData,
    schoolTypesData: state.clientAdmissionReducer.schoolTypesData,
    schoolsData: state.clientAdmissionReducer.schoolsData,
    schoolLevelData: state.clientAdmissionReducer.schoolLevelData,
    visaCategoryData: state.clientVisaReducer.visaCategoryData,
    clientRemindersRes: state.clientSummaryReducer.clientRemindersRes,
    employerCaseRes: state.employerManagReducer.employerCaseRes,
    brnchPipelineData: state.dealsReducer.brnchPipelineData,
    teamMembers: state.teamMemberReducer.teamMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdateReminder: bindActionCreators(updateReminder, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onUpdAdmissionStatus: bindActionCreators(updAdmissionStatus, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetVisaCategory: bindActionCreators(getCategory, dispatch),
    onGetSchools: bindActionCreators(getSchools, dispatch),
    onGetSchoolLevel: bindActionCreators(getSchoolLevel, dispatch),
    onAddClientAdmission: bindActionCreators(addClientAdmission, dispatch),
    onGetSchoolType: bindActionCreators(getSchoolType, dispatch),
    onGetClientReminders: bindActionCreators(getReminders, dispatch),
    onGetEmployerCase: bindActionCreators(getEmployerCase, dispatch),
    onGetEmployerData: bindActionCreators(getEmployerData, dispatch),
    onGetBranchPipeline: bindActionCreators(getBranchPipeline, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerSidebar);
