import React from "react";
import Select from "react-select";
import { message, Button, Form, Checkbox, Input, Table } from "antd";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import Sidebar from "../Components/SideBar";

import Section from "../Components/Questionnaire/Section";

var optionsArray = [];
var optionsSectionArray = [];

const columns = [
  {
    title: "Key",
    key: "key",
    dataIndex: "key",
  },
  {
    title: "Value",
    key: "keyDescription",
    dataIndex: "keyDescription",
  },
];

const keys = [
  {
    key: "Country",
    keyDescription: "To get countries list",
  },
  {
    key: "VisaType",
    keyDescription: "To get visa type list",
  },
];
class CustomQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
      clickedButton: "",
      multiSections: [],

      mappedId: "",

      savedQuestionnarieId: 0,
      loading: false,
      previewLoading: false,
      optionsArray: [],
      mappPropertiesArray: [],
    };

    this.props.onGetMappingParent();
  }

  formRef = React.createRef();

  componentDidUpdate(prevProps) {}

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  makeOptionsArray = (options) => {
    // optionsArray.push(options);
    this.setState({ optionsArray: [...this.state.optionsArray, options] });
  };

  makeCheckboxArray = (options) => {
    // optionsArray.push(options);
    this.setState((prevState) => [...prevState, options]);
  };

  makeDropdownArray = (options) => {
    // optionsArray.push(options);
    this.setState((prevState) => [...prevState, options]);
  };

  removeOptionSection = (index, number) => {
    this.setState({
      multiSections: this.state.multiSections.splice(index, number),
    });
  };

  addedOptionSection = (sectionOptions) => {
    if (this.state.multiSections.length === 0) {
      this.setState({
        multiSections: this.state.multiSections.concat(sectionOptions),
      });
    } else {
      for (var i = 0; i < sectionOptions.length; i++) {
        var findSameIndex = this.state.multiSections.filter(
          (obj) =>
            obj.mainQuestionIndex === sectionOptions[i].mainQuestionIndex &&
            obj.mainSectionIndex === sectionOptions[i].mainSectionIndex &&
            obj.optSectionIndex === sectionOptions[i].optSectionIndex &&
            obj.sectionInd === sectionOptions[i].sectionInd
        );
        if (findSameIndex.length === 0) {
          this.setState({
            multiSections: this.state.multiSections.concat(sectionOptions[i]),
          });
        }
        console.log(this.state.multiSections);
      }
    }
    optionsSectionArray = this.state.multiSections;
  };

  onGetMappingProperty = (value) => {
    this.props.onGetMappingChild(value);
  };

  readOptionalSections = (options) => {
    let userId = localStorage.getItem("userId");
    var optionalQuestionsList = [];
    var subOptionalSection = [];
    var subOptionalQuestions = [];
    var subOptionValues = [];
    var mappParent = "";
    var property = "";

    var optionValuesList = [];

    var optionSectionList = [];
    for (var k = 0; k < options.length; k++) {
      if (options[k].innerSection) {
        for (var l = 0; l < options[k].innerSection.length; l++) {
          if (options[k].innerSection[l].questions) {
            for (
              var m = 0;
              m < options[k].innerSection[l].questions.length;
              m++
            ) {
              var findOptionalOptions = [];
              var optionalOptionArray = [];
              console.log(
                "======optional options",
                options[k].innerSection[l].questions[m]
              );

              if (options[k].innerSection[l].questions[m].questionOptions) {
                optionalOptionArray = this.readOptionalSections(
                  options[k].innerSection[l].questions[m].questionOptions
                );
              }

              var optRequired = false;
              var optCascading = false;
              var optMultiAnswers = false;
              if (
                options[k].innerSection[l].questions[m].checkedOptions &&
                options[k].innerSection[l].questions[m].checkedOptions.length >
                  0
              ) {
                //
                for (
                  var a = 0;
                  a <
                  options[k].innerSection[l].questions[m].checkedOptions.length;
                  a++
                ) {
                  if (
                    options[k].innerSection[l].questions[m].checkedOptions[
                      a
                    ] === "required"
                  ) {
                    optRequired = true;
                  }
                  if (
                    options[k].innerSection[l].questions[m].checkedOptions[
                      a
                    ] === "multiAnswers"
                  ) {
                    optMultiAnswers = true;
                  }
                  if (
                    options[k].innerSection[l].questions[m].checkedOptions[
                      a
                    ] === "cascading"
                  ) {
                    optCascading = true;
                  }
                }
              }
              if (options[k].innerSection[l].questions[m].textArea) {
                optionalQuestionsList.push({
                  question: options[k].innerSection[l].questions[
                    m
                  ].textArea.toHTML(),
                  answerType: 0,
                  position: 0,
                  isRequired: optRequired,
                  isCascading: optCascading,
                  isMultiple: optMultiAnswers,
                  mapped: "",
                  mappedProperty: "",
                  mappedText: "",
                  createdBy: userId,
                  questionOptions: [],
                });
              } else {
                // property = options[k].innerSection[l].questions[m]
                //   .mappingProperty
                //   ? JSON.parse(
                //       options[k].innerSection[l].questions[m].mappingProperty
                //     )
                //   : "";

                if (options[k].innerSection[l].questions[m].mappingParent) {
                  if (
                    options[k].innerSection[l].questions[
                      m
                    ].mappingParent.includes('"id":')
                  ) {
                    mappParent = options[k].innerSection[l].questions[m]
                      .mappingParent
                      ? JSON.parse(
                          options[k].innerSection[l].questions[m].mappingParent
                        )
                      : "";
                  } else {
                    mappParent =
                      options[k].innerSection[l].questions[m].mappingParent;
                  }
                } else {
                  mappParent = "";
                }
                if (options[k].innerSection[l].questions[m].mappingProperty) {
                  if (
                    options[k].innerSection[l].questions[
                      m
                    ].mappingProperty.includes('"id":')
                  ) {
                    property = options[k].innerSection[l].questions[m]
                      .mappingProperty
                      ? JSON.parse(
                          options[k].innerSection[l].questions[m]
                            .mappingProperty
                        )
                      : "";
                  } else {
                    property =
                      options[k].innerSection[l].questions[m].mappingProperty;
                  }
                } else {
                  property = "";
                }
                optionalQuestionsList.push({
                  questionnaireId: 0,
                  question: options[k].innerSection[l].questions[m].question,
                  answerType: options[k].innerSection[l].questions[m].answerType
                    ? parseInt(
                        options[k].innerSection[l].questions[m].answerType
                      )
                    : 1,
                  position: 0,
                  isRequired: optRequired,
                  isCascading: optCascading,
                  isMultiple: optMultiAnswers,
                  sectionId: 0,
                  mapped:
                    mappParent && mappParent.name
                      ? mappParent.name
                      : mappParent,
                  mappedProperty:
                    property && property.name
                      ? property.name
                      : property && property.friendlyName
                      ? property.friendlyName
                      : property,
                  mappedText: "",
                  createdBy: userId,
                  questionOptions: optionalOptionArray,
                });
              }

              optionalOptionArray = [];
            }
          }

          optionSectionList.push({
            name: options[k].innerSection[l].name
              ? options[k].innerSection[l].name.trim()
              : "",
            allowMultiple: options[k].innerSection[l].allowMultiple,
            position: 0,
            questions: optionalQuestionsList,
          });
          optionalQuestionsList = [];
        }
      }
      optionValuesList.push({
        option: options[k].optionName,
        optionalQuestions: optionSectionList,
      });
      optionSectionList = [];
    }

    return optionValuesList;
  };

  onFinish = (values) => {
    var userId = localStorage.getItem("userId");
    var sectionList = [];
    var optionSectionList = [];
    var questionsList = [];
    var optionValuesList = [];
    var optionalQuestionsList = [];
    var subOptionalSection = [];
    var subOptionalQuestions = [];
    var subOptionValues = [];
    let duplicateValues = [];
    var mappParent = "";
    var property = "";
    if (this.state.mappPropertiesArray.length > 0) {
      for (var i = 0; i < this.state.mappPropertiesArray.length - 1; i++) {
        for (var j = i + 1; j < this.state.mappPropertiesArray.length; j++) {
          if (
            this.state.mappPropertiesArray[j].id ==
            this.state.mappPropertiesArray[i].id
          ) {
            duplicateValues.push(this.state.mappPropertiesArray[i]);
          }
        }
      }
    }

    console.log(duplicateValues);
    if (duplicateValues.length > 0) {
      var names = "";
      for (var i = 0; i < duplicateValues.length; i++) {
        if (i == 0) {
          names = duplicateValues[0].name;
        } else {
          names += `, ${duplicateValues[i].name}`;
        }
      }
      message.error(`${names} are duplicate Mapping Properties in Questions!`);
      return;
    }
    if (this.state.clickedButton === "save") {
      this.setState({ loading: true });
    } else {
      this.setState({ previewLoading: true });
    }
    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            // ======== Move itrative to recursive method
            if (values.sections[i].questions[j].questionOptions) {
              optionValuesList = this.readOptionalSections(
                values.sections[i].questions[j].questionOptions
              );
            }
            var required = false;
            var cascading = false;
            var multiAnswers = false;
            if (
              values.sections[i].questions[j].checkedOptions &&
              values.sections[i].questions[j].checkedOptions.length > 0
            ) {
              for (
                var a = 0;
                a < values.sections[i].questions[j].checkedOptions.length;
                a++
              ) {
                if (
                  values.sections[i].questions[j].checkedOptions[a] ===
                  "required"
                ) {
                  required = true;
                }
                if (
                  values.sections[i].questions[j].checkedOptions[a] ===
                  "multiAnswers"
                ) {
                  multiAnswers = true;
                }
                if (
                  values.sections[i].questions[j].checkedOptions[a] ===
                  "cascading"
                ) {
                  cascading = true;
                }
              }
            }
            if (values.sections[i].questions[j].textArea) {
              questionsList.push({
                question: values.sections[i].questions[j].textArea.toHTML(),
                answerType: 0,
                position: 0,
                isRequired: required,
                isCascading: cascading,
                isMultiple: multiAnswers,
                mapped: "",
                mappedProperty: "",
                mappedText: "",
                createdBy: userId,
                questionOptions: [],
              });
            } else {
              // property = values.sections[i].questions[j].mappingProperty
              //   ? JSON.parse(values.sections[i].questions[j].mappingProperty)
              //   : "";
              if (values.sections[i].questions[j].mappingParent) {
                if (
                  values.sections[i].questions[j].mappingParent.includes(
                    '"id":'
                  )
                ) {
                  mappParent = values.sections[i].questions[j].mappingParent
                    ? JSON.parse(values.sections[i].questions[j].mappingParent)
                    : "";
                } else {
                  mappParent = values.sections[i].questions[j].mappingParent;
                }
              } else {
                mappParent = "";
              }
              if (values.sections[i].questions[j].mappingProperty) {
                if (
                  values.sections[i].questions[j].mappingProperty.includes(
                    '"id":'
                  )
                ) {
                  property = values.sections[i].questions[j].mappingProperty
                    ? JSON.parse(
                        values.sections[i].questions[j].mappingProperty
                      )
                    : "";
                } else {
                  property = values.sections[i].questions[j].mappingProperty;
                }
              } else {
                property = "";
              }
              questionsList.push({
                question: values.sections[i].questions[j].question,
                answerType: values.sections[i].questions[j].answerType
                  ? parseInt(values.sections[i].questions[j].answerType)
                  : 1,
                position: 0,
                isRequired: required,
                isCascading: cascading,
                isMultiple: multiAnswers,
                mapped:
                  mappParent && mappParent.name ? mappParent.name : mappParent,
                mappedProperty:
                  property && property.name
                    ? property.name
                    : property && property.friendlyName
                    ? property.friendlyName
                    : property,
                mappedText: "",
                createdBy: userId,
                questionOptions: optionValuesList,
              });
            }

            optionValuesList = [];
          }
        }
        sectionList.push({
          name: values.sections[i].name,
          allowMultiple: values.sections[i].allowMultiple,
          position: 0,
          questions: questionsList,
        });
        questionsList = [];
      }
    }

    var allowAttachments = false;
    var deceleration = false;

    if (values.questionnaireOptions && values.questionnaireOptions.length > 0) {
      for (var i = 0; i < values.questionnaireOptions.length; i++) {
        if (values.questionnaireOptions[i] === "allowAttachments") {
          allowAttachments = true;
        } else {
          deceleration = true;
        }
      }
    }

    let data = {
      questionnaireId: this.state.savedQuestionnarieId,
      name: values.name,
      allowAttachments: allowAttachments,
      deceleration: deceleration,
      showInPotential: false,
      showInClient: false,
      showInPublic: false,
      questionnaireEmail: "",
      declarationText: "",
      clientId: "00000000-0000-0000-0000-000000000000",
      parentQuestionnaireId: 0,
      isPotential: false,
      sections: sectionList,
    };
    console.log(JSON.stringify(data));

    if (this.state.savedQuestionnarieId === 0) {
      if (this.state.clickedButton === "save") {
        this.props
          .onCreateQuestionnaire(data)
          .then((res) => {
            message.success("Questionnaire added successfully");
            this.setState({
              savedQuestionnarieId: res.payload.id,
              loading: false,
            });
            this.props.onGetQuestionnaire(res.payload.id);
          })
          .catch((err) => {
            message.error("Create Questionnaire failed");
            this.setState({ loading: false, previewLoading: false });
          });
      } else {
        this.props
          .onCreateQuestionnaire(data)
          .then((res) => {
            this.setState({
              previewLoading: false,
              savedQuestionnarieId: res.payload.id,
            });
            window.open("questionnaire-form?id=" + res.payload.id, "_blank");
          })
          .catch((err) => {
            message.error("Create Questionnaire failed");
            this.setState({ loading: false, previewLoading: false });
          });
      }
    } else {
      this.props
        .onUpdateQuestionnaire(data)
        .then((res) => {
          if (this.state.clickedButton === "save") {
            this.setState({ loading: false });
          } else {
            this.setState({ previewLoading: false });
            window.open(
              "questionnaire-form?id=" + this.state.savedQuestionnarieId,
              "_blank"
            );
          }
        })
        .catch((err) => {
          message.error("Create Questionnaire failed");
          this.setState({ loading: false, previewLoading: false });
        });
    }

    //
  };

  onMappArrayChange = (toDo, property) => {
    var propertyArray = [...this.state.mappPropertiesArray];

    if (toDo == "remove") {
      var findIndex = propertyArray.findIndex((obj) => obj.id == property.id);
      if (findIndex > -1) {
        propertyArray.splice(findIndex, 1);
        this.setState({ mappPropertiesArray: propertyArray });
      }
    } else {
      if (property) {
        propertyArray.push(property);
        this.setState({ mappPropertiesArray: propertyArray });
      }
    }
  };

  onChangeMappProperty = (value, key) => {
    const fields = this.formRef.current.getFieldValue(0);
    // const { projects } = fields;
    // Object.assign(projects[key], { type: value });
    this.formRef.current.setFieldsValue({
      [key]: { ...fields, ["type"]: value },
    });
    // console.log(projects);
  };

  render() {
    const { optionsArray, mappPropertiesArray } = this.state;
    const { onGetMappingChild, mappParentData, mappChildData } = this.props;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="sus-container" style={{ paddingBottom: 80 }}>
              <div style={{ marginTop: 20 }}>
                <div className="heading-container">
                  <span className="heading-text">CUSTOM QUESTIONNAIRE</span>
                </div>
                {/* <div className="title-container">
                  <span className="heading-text">QUESTIONNAIRE NAME</span>
                </div> */}
                <div style={{ padding: 30 }}>
                  <h3>QUESTIONNAIRE KEYS</h3>
                  <div className="dynamic-keys" style={{ marginTop: 10 }}>
                    <p>
                      You can use the following keys in the Questionnaire
                      dropdown option to get Coutries and Visa Types list.
                    </p>
                  </div>
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    columns={columns}
                    dataSource={keys}
                    pagination={false}
                  />
                </div>
                <Form
                  onFinish={this.onFinish}
                  ref={this.formRef}
                  onValuesChange={(changed, all) => {
                    //
                  }}
                >
                  <div className="cq-container">
                    <div
                      className="cq-form-cont"
                      style={{
                        width: "100%",
                        marginLeft: 0,
                        marginTop: 0,
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 30,
                      }}
                    >
                      <div
                        className="form-cont"
                        style={{
                          justifyContent: "space-between",
                          paddingTop: 20,
                          paddingBottom: 0,
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            marginTop: 15,
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="cq-label-text">Name*</p>
                              <div
                                className="profile-input-border"
                                style={{ width: "60%", marginRight: "15%" }}
                              >
                                <Form.Item
                                  name="name"
                                  rules={[
                                    { required: true, message: "Required!" },
                                  ]}
                                >
                                  <Input
                                    className="profile-input"
                                    placeholder="QUESTIONNAIRE NAME"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div style={{ marginTop: 15 }}>
                              <Form.Item name="questionnaireOptions">
                                <Checkbox.Group
                                  style={{ width: "100%" }}
                                  onChange={this.onChange}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: 20,
                                      width: "68%",
                                    }}
                                  >
                                    <div className="sus-checkbox-cont">
                                      <Checkbox
                                        value="allowAttachments"
                                        className="sus-checkbox-label"
                                      >
                                        Allow Attachments
                                      </Checkbox>
                                    </div>
                                    <div className="sus-checkbox-cont">
                                      <Checkbox
                                        value="allowDecelration"
                                        className="sus-checkbox-label"
                                      >
                                        Add Declaration
                                      </Checkbox>
                                    </div>
                                  </div>
                                </Checkbox.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Form.List
                      name="sections"
                      // rules={[
                      //   {
                      //     validator: async (_, names) => {
                      //       if (!names || names.length < 2) {
                      //         return Promise.reject(new Error('At least 2 passengers'));
                      //       }
                      //     },
                      //   },
                      // ]}
                    >
                      {(sections, { add, remove, move }, { errors }) => (
                        <>
                          <div
                            className="cq-form-cont"
                            style={{ marginTop: -30, paddingLeft: 80 }}
                          >
                            <Form.Item>
                              <Button
                                onClick={() => add()}
                                loading={this.state.loading}
                                type="dashed"
                                className="cq-primary-btn"
                                style={{ marginTop: 20 }}
                              >
                                <span className="cq-btn-text">Add Section</span>
                              </Button>
                            </Form.Item>
                          </div>
                          {sections.map((section, index) => {
                            return (
                              <Form.Item required={false} key={index}>
                                <Section
                                  section={section}
                                  sectionIndex={index}
                                  sectionMove={move}
                                  addOptionValues={this.makeOptionsArray}
                                  addCheckboxValues={this.makeOptionsArray}
                                  addDropdownValues={this.makeOptionsArray}
                                  sectionAdd={() => add()}
                                  sectionRemove={remove}
                                  addSectionOptions={this.addedOptionSection}
                                  mappParentData={mappParentData}
                                  mappChildData={mappChildData}
                                  getMappChild={onGetMappingChild}
                                  optionsArray={optionsArray}
                                  optionsSectionArray={this.state.multiSections}
                                  removeOptionSection={this.removeOptionSection}
                                  formRef={this.formRef}
                                  mappPropertiesArray={mappPropertiesArray}
                                  onMappArrayChange={this.onMappArrayChange}
                                  onChangeMappProperty={
                                    this.onChangeMappProperty
                                  }
                                />
                              </Form.Item>
                            );
                          })}
                        </>
                      )}
                    </Form.List>

                    <Form.Item>
                      <div style={{ display: "flex" }}>
                        <Button
                          loading={this.state.loading}
                          onClick={() =>
                            this.setState({ clickedButton: "save" })
                          }
                          htmlType="submit"
                          name="save"
                          className="cq-save-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          SUBMIT
                        </Button>
                        <Button
                          loading={this.state.previewLoading}
                          onClick={() =>
                            this.setState({ clickedButton: "preview" })
                          }
                          htmlType="submit"
                          name="preview"
                          className="cq-save-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          PREVIEW
                        </Button>
                        <Button
                          type="primary"
                          className="cq-close-btn"
                          style={{ marginTop: 20 }}
                          onClick={() => this.props.history.goBack()}
                        >
                          CLOSE
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomQuestionnaire;
