import React, { useState, Fragment, useEffect } from "react";
import { Table, Spin } from "antd";

const DynamicFileNotes = ({ onGetDynamicKeys, dynamicKeysRes }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});

  useEffect(() => {
    setLoading(true);

    onGetDynamicKeys().then(() => {
      setLoading(false);
    });
  }, [onGetDynamicKeys]);

  // const removeTag = (id) => {
  //   setLoading(true);
  //
  //   const remove = {
  //     id: id,
  //     delete: true,
  //   };
  //
  //   onRemoveClientTag(remove).then(() => {
  //     onGetClientTag().then(() => {
  //       setLoading(false);
  //       message.success("Successfully Deleted!");
  //     });
  //   });
  // };
  const dataSource = [
    {
      key: "1",
      key: "Mike",
    },
    {
      key: "2",
      value: "John",
    },
  ];

  const columns = [
    {
      title: "Key",
      key: dynamicKeysRes && dynamicKeysRes.key,
      dataIndex: "key",
    },
    {
      title: "Value",
      key: dynamicKeysRes && dynamicKeysRes.keyDsecription,
      dataIndex: "keyDsecription",
    },
  ];
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="dynamicForm">
            <div className="">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={dynamicKeysRes && dynamicKeysRes.items}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DynamicFileNotes;
