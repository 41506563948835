import { api } from "../../services/api";
import * as types from "../Constants";

export const getDocumentView = () => {
  const options = {
    url: "v1/users/DocumentView",
  };
  options.types = [
    types.GET_DOCUMENT_VIEW_SUCCESS,
    types.GET_DOCUMENT_VIEW_FAILURE,
  ];

  return api.get(options);
};

export const addDocumentView = (data) => {
  const options = {
    url: "v1/users/DocumentView",
  };
  options.types = [
    types.ADD_DOCUMENT_VIEW_SUCCESS,
    types.ADD_DOCUMENT_VIEW_FAILURE,
  ];

  return api.put(options, data);
};
