import React, { Component } from "react";
import exportImg from "../../svgs/export.svg";
import DashboardClientTopBars from "../../Components/Shared/DashboardClientTopBar";
import { Row, Col, Button, Form, Select, Spin, Modal, Tooltip } from "antd";
import moment from "moment";
import { FileTwoTone } from "@ant-design/icons";
import renderHTML from "react-render-html";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import DashboardPotentialReport from "../../Reports/PotentialClientDashboardReport/PotentialClientDashboardReport";
import "./Employers.css";
import ContactEmployer from "../../EmployerManagement/EmployerManagement/EmployerManagementTabs/ProfileEmployer/ContactEmployer/ContactEmployer";
import JobEmployer from "../../EmployerManagement/EmployerManagement/EmployerManagementTabs/ProfileEmployer/JobEmployer/JobEmployer";
import ClientEmployer from "../../EmployerManagement/EmployerManagement/EmployerManagementTabs/ProfileEmployer/ClientEmployer/ClientEmployer";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import { getDashboardEmployers } from "../../store/Actions";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

let config = {
  key: "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
  // height: "auto",
  // toolbarSticky: false,
  charCounterCount: false,
  // placeholderText: "Edit Your Content Here!",
  // imageUpload: true,
  // imageDefaultAlign: "left",
  // imageDefaultDisplay: "inline-block",
  // Set max image size to 5MB.
  // imageMaxSize: 5  1024  1024,
  // Allow to upload PNG and JPG.
  imageAllowedTypes: ["jpeg", "jpg", "png"],
  events: {
    "charCounter.update": () => {
      // Do something here.
      // this is the editor instance.
      console.log("char");
    },
    "image.uploaded": function(response) {
      // Do something here.
      // this is the editor instance.
      console.log(this);
    },
    "image.beforeUpload": function(files) {
      let editor = config.editor;
      if (files.length) {
        // Create a File Reader.
        let reader = new FileReader();

        // Set the reader to insert images when they are loaded.
        reader.onload = (e) => {
          let result = e.target.result;
          console.log("result", result);

          editor.image.insert(result, null, null, editor.image.get());
        };
        // Read image as base64.
        let pic = reader.readAsDataURL(files[0]);
        console.log("pic", pic);
      }
      editor.popups.hideAll();
      // Stop default upload chain.
      return false;
    },
  },
};

const topBar = [
  { tabName: "Client Summary", linkName: "/dashboard" },
  { tabName: "Potential Clients", linkName: "/dashboard/potentialclient" },
  { tabName: "Students", linkName: "/dashboard/student" },
  { tabName: "Employers", linkName: "/dashboard/employers" },
  { tabName: "Reminders", linkName: "/dashboard/reminders" },
];
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

class Reminders extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      Employers: [],
      ProcessingPersons: [],
      selectedBranch: null,
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userManager") === "true"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userId"),
      currentPage: 1,
      pageSize: 20,
      totalPages: 0,
      isFileNoteModalOpen: false,
      isJobModalOpen: false,
      pageLoading: false,
      newTaskObj: {},
      fileNoteObj: {
        fileNote: "",
      },
      counts: {
        count: 0,
      },
      selectedEmp: {},
      selectedStatus: "",
      caseCount: 0,
    };
  }

  columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => {
        return (
          <div className="priority-buttons">
            <span
              class="priority-button"
              style={{
                borderColor: "#d9534f",
                backgroundColor: text === "High" ? "#d9534f" : "#0000",
              }}
              onClick={() => {
                this.updateClientPriority(record, "High");
              }}
            ></span>
            <span
              class="priority-button"
              onClick={() => {
                this.updateClientPriority(record, "Medium");
              }}
              style={{
                borderColor: "#f0ad4e",
                backgroundColor: text === "Medium" ? "#f0ad4e" : "#0000",
              }}
            ></span>
            <span
              class="priority-button High-priority"
              onClick={() => {
                this.updateClientPriority(record, "Low");
              }}
              style={{
                borderColor: "#5cb85c",
                backgroundColor: text === "Low" ? "#5cb85c" : "#0000",
              }}
            ></span>
          </div>
        );
      },
      filters: [
        {
          text: "High",
          value: "High",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Low",
          value: "Low",
        },
      ],
      onFilter: (value, record) => record.priority.indexOf(value) === 0,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      align: "center",
    },
    {
      title: "Business/Company Name",
      dataIndex: "business",
      sorter: (a, b) => a.business.length - b.business.length,
      align: "center",
    },
    {
      title: "Contact No.",
      dataIndex: "contactNo",
      sorter: (a, b) => a.contactNo.length - b.contactNo.length,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
      align: "center",
    },
    {
      title: "Case Type",
      dataIndex: "visaTypeName",
      sorter: (a, b) => a.visaTypeName.length - b.visaTypeName.length,
      align: "center",
    },
    {
      title: "Case Status",
      dataIndex: "visaStatus",
      sorter: (a, b) => a.visaStatus.length - b.visaStatus.length,
      align: "center",
    },
    {
      title: "Jobs",
      dataIndex: "jobs",
      render: (data, record) => ({
        children: data,
        props: {
          className: "addCursor",
          onClick: () =>
            this.setState({ isJobModalOpen: true, selectedEmp: record }),
        },
      }),
      sorter: (a, b) => a.jobs - b.jobs,
      align: "center",
    },
    {
      title: "File Note",
      dataIndex: "fileNote",
      render: (text, record) => (
        <FileTwoTone onClick={() => this.viewClientFileNote(record)} />
      ),
      align: "center",
      sorter: (a, b) => a.jobs - b.jobs,
    },
  ];
  updateClientPriority = (data, priority) => {
    this.setState({ pageLoading: true });
    this.props
      .onUpdateClientPriority({
        subjectId: data.id,
        priority: priority,
      })
      .then(() => this.getEmployerData())
      .catch((er) => this.setState({ pageLoading: false }));
  };
  updateClientCasePriority = (data, priority) => {
    // this.setState({ pageLoading: true });
    // this.props
    //   .onUpdateClientCasePriority({
    //     id: data.id,
    //     visaPriority: priority,
    //   })
    //   .then(() => this.getEmployerData())
    //   .catch((er) => this.setState({ pageLoading: false }));
  };
  getEmployerData = () => {
    let {
      currentPage,
      pageSize,
      selectedBranch,
      processingPerson,
    } = this.state;
    this.setState({ pageLoading: true });
    this.props.onGetDashboardEmployers({
      pageSize: pageSize,
      pageNumber: currentPage,
      processingPerson: processingPerson,
      caseStatus: this.state.selectedStatus,
    });
  };

  componentWillMount = () => {
    console.log("ClientSummary will mount");
  };

  componentDidMount = () => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.setState({ pageLoading: true, selectedBranch: selectedBranchId });
    let { currentPage, pageSize } = this.state;
    const { onGetDashboardEmployers, onGetVisaStatus } = this.props;
    onGetDashboardEmployers({
      pageSize: pageSize,
      pageNumber: currentPage,
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userManager") === "true"
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? "00000000-0000-0000-0000-000000000000"
          : localStorage.getItem("userId"),
      caseStatus: "",
    });
    onGetVisaStatus();
  };

  componentWillReceiveProps = (nextProps) => {
    let { currentPage, pageSize, counts } = this.state;
    let dashboardEmployersList = nextProps && nextProps.dashboardEmployersList;
    let dashboardEmployersError =
      nextProps && nextProps.dashboardEmployersError;
    if (dashboardEmployersList)
      this.setState({
        Employers: dashboardEmployersList.pClients,
        pageLoading: false,
        totalPages: dashboardEmployersList.count,
        caseCount: dashboardEmployersList.caseCount,
        currentPage: currentPage,
        pageSize: pageSize,
        counts: { ...counts, count: dashboardEmployersList.count },
      });
    if (dashboardEmployersError) this.setState({ pageLoading: false });
  };

  paginate = (value) => {
    let { current, pageSize } = value;
    let { processingPerson, selectedBranch } = this.state;
    let { onGetDashboardEmployers } = this.props;
    this.setState({ pageLoading: true });
    this.setState(
      { pageSize: value.pageSize, currentPage: value.current },
      () => {
        onGetDashboardEmployers({
          pageSize: pageSize,
          pageNumber: current,
          processingPerson: processingPerson,
          caseStatus: this.state.selectedStatus,
        })
          .then(() => this.setState({ pageLoading: false }))
          .catch(() => this.setState({ pageLoading: false }));
      }
    );
  };
  viewClientFileNote = ({ id }) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isFileNoteModalOpen: true,
          fileNoteObj: { clientId: id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isFileNoteModalOpen: true,
          fileNoteObj: { clientId: id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };

  getNoteModal = ({
    isFileNoteModalOpen,
    pageLoading,
    fileNoteObj,
    fileNotes,
  }) => {
    return (
      <Modal
        title={"Details"}
        visible={isFileNoteModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isFileNoteModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isFileNoteModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 600, overflow: "scroll" }}
        width={1010}
        height={1500}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}>
            <div
              style={{
                overflow: "inherit",
                paddingBottom: 6,
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 5,
              }}
              className="file-note-modal"
            >
              <Form
                ref={this.formRef2}
                {...layout}
                name="basic"
                onFinish={() => this.onAddClientFile()}
              >
                <Form.Item name="fileNote" style={{ width: "100%" }}>
                  <div className="letter-froala">
                    <FroalaEditor
                      ref={(el) => {
                        config = el;
                      }}
                      config={config}
                      name="model_data"
                      model={fileNoteObj.fileNote}
                      onModelChange={this.handleNoteModelChange}
                    />
                  </div>
                </Form.Item>
                <Form.Item style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="button-blue"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {fileNotes &&
              fileNotes.map((el) => (
                <>
                  <p>{moment(el.createdDate).format("DD/MM/YYYY")}</p>
                  <p>{moment(el.createdDate).format("HH:MM A")}</p>
                  <div>{renderHTML(el.fileNote)}</div>
                </>
              ))}
          </div>
        </Spin>
      </Modal>
    );
  };
  addNewTask = () => {
    let userId = localStorage.getItem("userId");

    this.setState({ pageLoading: true });
    let {
      subjectId,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers,
    } = this.state.newTaskObj;
    let taskObj = {
      subjectId,
      isPontential: false,
      isCompleted: false,
      caseCheckListId: 0,
      taskTitle,
      taskDescription,
      taskDate,
      taskUsers: taskUsers.map((el) => ({ userId: el.userId })),
    };
    this.props
      .onAddTask(taskObj)
      .then((res) => {
        this.getTasks({ id: subjectId });
      })
      .catch((err) => {
        this.setState({ pageLoading: false });
      });
  };
  viewTasks = (record) => {
    this.setState({ pageLoading: true });
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: res.payload.items,
          },
          pageLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isTaskModalOpen: true,
          newTaskObj: {
            subjectId: record.id,
            taskUsers: [],
            currentTasks: [],
          },
          pageLoading: false,
        })
      );
    this.props
      .onGetClientFile(record.id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: notes,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isTaskModalOpen: true,
          fileNoteObj: { clientId: record.id },
          fileNotes: [],
          pageLoading: false,
        });
      });
  };

  onChangeFollower = (val, val2) => {
    let { newTaskObj } = this.state;
    this.setState(
      {
        newTaskObj: {
          ...newTaskObj,
          taskUsers: [
            ...newTaskObj.taskUsers,
            { userId: val, name: val2.children },
          ],
        },
      },
      () => this.formRef.current.setFieldsValue({ followers: "a" })
    );
  };
  onChangeTaskHandler = (type, val) => {
    this.setState({ newTaskObj: { ...this.state.newTaskObj, [type]: val } });
  };
  getTasks = (record) => {
    this.props
      .onGetCaseTask(record.id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        openNotificationWithIcon("success", "Task Added");
        this.setState(
          {
            newTaskObj: {
              ...this.state.newTaskObj,
              currentTasks: res.payload.items,
              taskUsers: [],
            },
            pageLoading: false,
          },
          () => this.resetTaskForm()
        );
      })
      .catch((er) => {
        this.setState({
          pageLoading: false,
        });
      });
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };
  onAddClientFile = () => {
    this.setState({ pageLoading: true });
    let { fileNoteObj } = this.state;
    let { onAddClientFile } = this.props;
    onAddClientFile(fileNoteObj)
      .then((res) => {
        this.getAllFileNotes(fileNoteObj.clientId);
        openNotificationWithIcon("success", "File Note Added!");
        this.setState(
          {
            pageLoading: false,
            fileNoteObj: { ...fileNoteObj, fileNote: "" },
          },
          () => config.editor.html.set("")
        );
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  getAllFileNotes = (id) => {
    this.props
      .onGetClientFile(id)
      .then((res) => {
        let notes = res.payload && res.payload.items;
        this.setState({ fileNotes: notes, pageLoading: false });
      })
      .catch(() => this.setState({ pageLoading: false }));
  };
  handleNoteModelChange = (val) => {
    let { fileNoteObj } = this.state;
    this.setState({ fileNoteObj: { ...fileNoteObj, fileNote: val } });
  };
  getJobModal = ({
    isJobModalOpen,
    pageLoading,
    selectedEmp,
    // fileNoteObj,
    // fileNotes,
  }) => {
    return (
      <Modal
        title={"Details"}
        visible={isJobModalOpen}
        onOk={() => {
          this.resetTaskForm();
          this.setState({ isJobModalOpen: false });
        }}
        onCancel={() => {
          this.resetTaskForm();
          this.setState({ isJobModalOpen: false });
        }}
        header={true}
        footer={false}
        bodyStyle={{ maxHeight: 600, overflowY: "scroll" }}
        width={1010}
      >
        <Spin spinning={pageLoading} size="small">
          <div style={{ width: "100%" }}></div>
          <div
            style={{
              border: "1px solid #e3e3e3",
              backgroundColor: "#f5f5f5",
              borderRadius: 5,
            }}
            className="emp-contact-form"
          >
            <ContactEmployer
              ongetEmployerContact={this.props.ongetEmployerContact}
              EmployerContactRes={this.props.EmployerContactRes}
              onAddEmployerContact={this.props.onAddEmployerContact}
              userDataEmp={selectedEmp}
              onRemoveEmployerContact={this.props.onRemoveEmployerContact}
              onUpdateEmployerContact={this.props.onUpdateEmployerContact}
            />
          </div>
          <div
            style={{
              border: "1px solid #e3e3e3",
              backgroundColor: "#f5f5f5",
              borderRadius: 5,
              marginTop: 8,
            }}
            className="emp-contact-form"
          >
            <JobEmployer
              onGetEmployerJob={this.props.onGetEmployerJob}
              employerJobRes={this.props.employerJobRes}
              onAddEmployerJob={this.props.onAddEmployerJob}
              userDataEmp={selectedEmp}
              onGetTeamMember={this.props.onGetTeamMember}
              teamMembers={this.props.teamMembers}
              onUpdateEmployerJob={this.props.onUpdateEmployerJob}
              onRemoveEmployerJob={this.props.onRemoveEmployerJob}
              onGetClientSearch={this.props.onGetClientSearch}
              clientSearchRes={this.props.clientSearchRes}
              onSearchClient={this.props.onSearchClient}
              searchClientData={this.props.searchClientData}
              onGetClientByEmpId={this.props.onGetClientByEmpId}
              empClientEmpRes={this.props.empClientEmpRes}
              onLinkEmpWithClient={this.props.onLinkEmpWithClient}
              onAddEmployerJobHistory={this.props.onAddEmployerJobHistory}
              onGetSingleClientHis={this.props.onGetSingleClientHis}
              singleJobHisRes={this.props.singleJobHisRes}
            />
          </div>
          <div
            style={{
              border: "1px solid #e3e3e3",
              backgroundColor: "#f5f5f5",
              borderRadius: 5,
              marginTop: 8,
            }}
            className="emp-contact-form"
          >
            <ClientEmployer
              onGetClientEmp={this.props.onGetClientEmp}
              clientEmpRes={this.props.clientEmpRes}
              empClientEmpRes={this.props.empClientEmpRes}
              onLinkEmpWithClient={this.props.onLinkEmpWithClient}
              userDataEmp={selectedEmp}
              onGetClientByEmpId={this.props.onGetClientByEmpId}
              onGetClientTag={this.props.onGetClientTag}
              clientTagRes={this.props.clientTagRes}
              onGetProfileClientTag={this.props.onGetProfileClientTag}
              getClientRes={this.props.getClientRes}
              onGetClientJobHis={this.props.onGetClientJobHis}
              clientJobHisRes={this.props.clientJobHisRes}
            />
          </div>
        </Spin>
      </Modal>
    );
  };

  onExport = () => {
    let { processingPerson, selectedBranch } = this.state;

    this.setState({ pageLoading: true });
    const clientExportOpt = {
      url: `v1/dashboard/EmployerExport`,
    };
    clientExportOpt.types = ["CLIENT_EXPORT_SUCCESS", "CLIENT_EXPORT_FAILURE"];

    let data = {
      pageSize: 0,
      pageNumber: 0,
      processingPerson: processingPerson,
      caseStatus: this.state.selectedStatus,
    };

    apiRefresh
      .postWithFileRes(clientExportOpt, data)
      .then((res) => {
        this.setState({ pageLoading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Employer_Summary.xlsx");
      })
      .catch((err) => this.setState({ pageLoading: false }));
  };

  render() {
    const {
      pageLoading,
      pageSize,
      currentPage,
      totalPages,
      Employers,
      counts,
      ProcessingPersons,
    } = this.state;
    return (
      <Spin size="large" spinning={pageLoading}>
        <div>
          <div style={{ display: "flex" }}>
            <div class="page-container">
              <Row>
                <Col xs={24}>
                  <DashboardClientTopBars data={topBar} activeTab="Employers" />
                </Col>
              </Row>
              <div class="container-fluid">
                <div
                  class="top-row"
                  style={{ paddingRight: 0, marginBottom: 0 }}
                >
                  <div
                    class="top-container"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        class="select-optionss"
                        style={{ marginLeft: 10, paddingRight: 1 }}
                      >
                        <Select
                          showSearch
                          style={{ width: 120, borderRadius: 0 }}
                          placeholder="Case Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(e) => {
                            var findCase =
                              this.props.visaStatusData &&
                              this.props.visaStatusData.items.find(
                                (obj) => obj.id === e
                              );
                            this.setState({
                              selectedStatus: findCase ? findCase.name : "",
                              pageLoading: true,
                            });
                            this.props
                              .onGetDashboardEmployers({
                                pageSize: this.state.pageSize,
                                pageNumber: this.state.currentPage,
                                processingPerson: this.state.processingPerson,
                                caseStatus: findCase ? findCase.name : "",
                              })
                              .then(() => this.setState({ pageLoading: false }))
                              .catch(() =>
                                this.setState({ pageLoading: false })
                              );
                          }}
                          defaultValue={""}
                        >
                          <Option value={""}>All</Option>
                          {this.props &&
                            this.props.visaStatusData &&
                            this.props.visaStatusData.items.map((data) => {
                              // eslint-disable-next-line react/jsx-no-undef
                              return (
                                <Option value={data.id}>{data.name}</Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-high-filled"></div>
                          <span class="priority-text">High</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">14</span>
                        </div>
                      </div>
                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-medium-filled"></div>
                          <span class="priority-text">Medium</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">10</span>
                        </div>
                      </div>
                      <div class="priority-cont">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div class="priority-low-filled"></div>
                          <span class="priority-text">Low</span>
                        </div>

                        <div class="count-text-cont">
                          <span class="priority-count-text">12</span>
                        </div>
                      </div>
                      <div
                        class="priority-cont"
                        style={{ fontSize: 11, color: "#687278" }}
                      >
                        Employers :{" "}
                        <span style={{ fontWeight: "700" }}>
                          {counts.count}
                        </span>
                      </div>
                      <div
                        class="priority-cont"
                        style={{ fontSize: 11, color: "#687278" }}
                      >
                        Cases :{" "}
                        <span style={{ fontWeight: "700" }}>
                          {this.state.caseCount}
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div class="setting-export-cont" style={{ height: 24 }}>
                        <Tooltip placement="topLeft" title={`Employer Report`}>
                          <img
                            style={{ cursor: "pointer" }}
                            src={exportImg}
                            class="setting-export-img"
                            onClick={this.onExport}
                          />
                        </Tooltip>
                        {/* <ReactToPrint
                          trigger={() => {
                            return (
                              <img
                                style={{ cursor: "pointer" }}
                                src={exportImg}
                                class="setting-export-img"
                              />
                            );
                          }}
                          content={() => this.employersRef}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-card table-filter">
                <div className="container-fluid">
                  <DashboardPotentialReport
                    ref={(el) => (this.employersRef = el)}
                    PotentialClients={Employers}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    columns={this.columns}
                    paginate={this.paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.getNoteModal(this.state)}
        {this.getJobModal(this.state)}
      </Spin>
    );
  }
}

export default Reminders;
