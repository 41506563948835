import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeTrackingReport from "../Reports/TimeTracking";

import {
  getVisaType,
  getVisaStatus,
  getServiceType,
  timeTrackingReport,
  getTeamMember,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    serviceTypeRes: state.timeTrackingReducer.serviceTypeRes,
    timeTrackingReportRes: state.reportReducer.timeTrackingReportRes,
    teamMembers: state.teamMemberReducer.teamMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetServiceType: bindActionCreators(getServiceType, dispatch),
    onGetTimeTrackingReport: bindActionCreators(timeTrackingReport, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingReport);
