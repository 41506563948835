import React from "react";
import { Images } from "../Themes";
import HeaderBarTabs from "../Components/Header/HeaderTabs";

import activityData from "../Components/ClientActivity/ActivityData";
import ProfileSideBar from "../Components/ProfileSideBar";
import { apiRefresh } from "../services/api";
import * as types from "../store/Constants";
import {
  Select,
  message,
  Modal,
  Table,
  Spin,
  Form,
  DatePicker,
  Input,
  Button,
  Tag,
  Tooltip,
  Row,
  Col,
  Checkbox,
} from "antd";
import moment from "moment";
import headOption from "../Components/Header/HeaderTabOptions";
import axios from "axios";
import renderHTML from "react-render-html";
import { saveAs } from "file-saver";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "/print-case" }
// ];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const visaColumns = [
  {
    title: "Visa",
    dataIndex: "branchVisaTypeName",
    key: "branchVisaTypeName",
  },
];

const { Option } = Select;

var userName = "";
class PrintCase extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      fileNoteString: "",
      selectedOption: null,
      headerOptions: [],
      showOnEdit: false,
      showIndex: null,
      template: "",
      editFileNotesString: "",
      profileData: null,
      LetterTemplatesRes: [],
      onCaseData: null,
      modalVisible: false,
      modal1Visible: false,
      selectedRowKeys: [],
      visaType: null,
      taskModal: false,
      addTaskLoader: false,
      usersData: [],
      selectedFileNote: null,
      loading: false,
      content: "",
      fileNotesTemplate: [],
      fileNotesDataState: [],
      isTask: false,
      isFileNote: false,
      isEmail: false,
      configCopy: {
        key:
          "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
        height: "auto",
        // pluginsEnabled: ['image'],
        toolbarSticky: false,
        events: {
          "charCounter.update": function() {
            console.log("oops");
          },
          "image.beforeUpload": function(files) {
            let editor = this;
            if (files.length) {
              // Create a File Reader.
              let reader = new FileReader();

              // Set the reader to insert images when they are loaded.
              reader.onload = function(e) {
                let result = e.target.result;

                editor.image.insert(result, null, null, editor.image.get());
              };
              // Read image as base64.
              reader.readAsDataURL(files[0]);
            }
            editor.popups.hideAll();
            // Stop default upload chain.
            return false;
          },
        },
      },
    };
    this.props.onSetActiveKey(null);
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    let selectedTab = {
      headName: "Client Profile",
      headPath: "/profile",
    };
    // this.props.onSetClientTab(selectedTab);
  }

  getPrintCase = (payloadData) => {
    this.setState({ loading: true });
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let isFileNote =
      payloadData.isFileNote != undefined
        ? payloadData.isFileNote
        : this.state.isFileNote;
    let isEmail =
      payloadData.isEmail != undefined
        ? payloadData.isEmail
        : this.state.isEmail;
    let isTask =
      payloadData.isTask != undefined ? payloadData.isTask : this.state.isTask;
    const printCaseOpt = {
      url: `v1/case/PrintCase/${clientprofileid}/${familyId}/0/0/${isEmail}/${isFileNote}/${isTask}`,
    };
    printCaseOpt.types = ["GET_PRINT_CASE_SUCCESS", "GET_PRINT_CASE_FAILURE"];

    apiRefresh.get(printCaseOpt).then((res) => {
      this.setState({
        fileNotesDataState: res,
        loading: false,
      });
    });
    // axios.get("/api/updatecart", {
    //   params: {
    //     product: this.product,
    //   },
    // });
  };

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    userName = localStorage.getItem("userName");
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          {
            tabName: "Print Case",
            imageName: Images.print,
            linkName: "/print-case",
          },
        ],
      });
    }
    // var data = JSON.parse(sessionStorage.getItem("profileData"));
    // this.setState({ profileData: data });
  }

  rowSelection = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRowKeys: selectedRows });
    },
  };
  handleCancel = () => {
    this.setState({ showOnEdit: false });
  };

  tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  onChangeFileNote = (val) => {
    this.setState({ isFileNote: val.target.checked });
    this.getPrintCase({
      isFileNote: val.target.checked,
    });
  };

  onChangeEmail = (val) => {
    this.setState({ isEmail: val.target.checked });
    this.getPrintCase({
      isEmail: val.target.checked,
    });
  };

  onChangeTasks = (val) => {
    this.setState({ isTask: val.target.checked });
    this.getPrintCase({
      isTask: val.target.checked,
    });
  };

  isHTML = (str) => {
    const fragment = document.createRange().createContextualFragment(str);

    // remove all non text nodes from fragment
    fragment
      .querySelectorAll("*")
      .forEach((el) => el.parentNode.removeChild(el));

    // if there is textContent, then not a pure HTML
    return !(fragment.textContent || "").trim();
  };

  printCase = () => {
    const printCaseOpt = {
      url: `v1/case/PrintCase`,
    };
    printCaseOpt.types = [
      "PRINT_PRINT_CASE_SUCCESS",
      "PRINT_PRINT_CASE_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh
      .postWithFileRes(printCaseOpt, { items: this.state.fileNotesDataState })
      .then((res) => {
        this.setState({ loading: false });
        let blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, `EzmClient.pdf`);
      });
  };

  onRemoveItem = (index) => {
    let items = [...this.state.fileNotesDataState];
    items.splice(index, 1);

    this.setState({ fileNotesDataState: items });
  };

  render() {
    const {
      selectedOption,
      fileNoteString,
      headerOptions,
      editFileNotesString,
      showOnEdit,
      showIndex,
      profileData,
      content,
      config,
      fileNotesTemplate,
      fileNotesDataState,
    } = this.state;
    const {
      LetterTemplatesRes,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onGetClientJobHistoryCurrent,
      onSetActiveKey,
      activeKey,
      onGetReminder,
      onGetVisaStatus,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaType,
      onGetVisaTypeByCountry,
    } = this.props;
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="Print Case" />
              )}
              <div style={{ display: "flex", margin: 10 }}>
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                  style={{ marginTop: 13 }}
                >
                  {profileData ? (
                    <div style={{ width: "100%", marginTop: -10 }}>
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  <div
                    className={"file-notes-container"}
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Checkbox onChange={this.onChangeFileNote}>
                        File Notes
                      </Checkbox>
                      <Checkbox onChange={this.onChangeEmail}>Emails</Checkbox>
                      <Checkbox onChange={this.onChangeTasks}>Tasks</Checkbox>
                    </div>
                    <div
                      className="profile-print-box"
                      style={{
                        width: 26,
                        height: 26,
                        marginTop: 10,
                        cursor: "pointer",
                      }}
                      onClick={this.printCase}
                    >
                      <img
                        src={Images.printWhite}
                        className="profile-print-icon"
                      />
                    </div>
                  </div>

                  <div className="file-notes-content" style={{ marginTop: 20 }}>
                    {fileNotesDataState.map((FileNote, index) => {
                      let string =
                        FileNote.caseType === "FileNotes"
                          ? FileNote.fileNote
                          : FileNote.caseType === "Email"
                          ? FileNote.emailMessage
                          : FileNote.taskDescription;
                      let title =
                        FileNote.caseType === "FileNotes"
                          ? "File Note"
                          : FileNote.caseType === "Email"
                          ? "Email"
                          : "Task";
                      let html = string;
                      var checkIsHtml = this.isHTML(string);
                      if (!checkIsHtml || string[0] != "<") {
                        html = "<p>" + string + "</p>";
                      }

                      return (
                        <div>
                          <div
                            key={index}
                            className="file-content-index-cont"
                            style={{
                              paddingTop: 8,
                              paddingBottom: 8,
                              marginBottom: 20,
                            }}
                          >
                            <div className="file-content-index-row">
                              <div style={{ width: `calc(100% - 140px)` }}>
                                <div>
                                  <span
                                    className="title"
                                    style={{ fontSize: 14 }}
                                  >
                                    {title}
                                  </span>
                                </div>
                                {FileNote.caseType == "Email" && (
                                  <>
                                    <div style={{ marginTop: 20 }}>
                                      <span className="client-email-address">
                                        {"From: " + FileNote.emailFrom}
                                      </span>
                                    </div>
                                    <div className="client-email-to-row">
                                      <div>
                                        <span
                                          className="client-email-address"
                                          style={{ marginBottom: 5 }}
                                        >
                                          {"To: " + FileNote.emailTo}
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        className="client-email-address"
                                        style={{ marginBottom: 5 }}
                                      >
                                        {"Subject: " + FileNote.emailSubject}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        className="client-email-address"
                                        style={{ marginBottom: 5 }}
                                      >
                                        {"CC: " + FileNote.cc}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="client-email-address">
                                        {"Bcc: " + FileNote.bcc}
                                      </span>
                                    </div>
                                  </>
                                )}
                                <div>{renderHTML(html)}</div>
                                <div>
                                  <span className="date-time">
                                    {moment(
                                      FileNote.caseType == "Task"
                                        ? FileNote.taskDate
                                        : FileNote.createdDate
                                    ).format("DD MMM YYYY hh:mm A") + " "}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div className="delete-cont">
                                  <Tooltip placement="topLeft" title={`Delete`}>
                                    <div
                                      className="delete-icon"
                                      style={{
                                        cursor: "pointer",
                                        marginTop: 5,
                                      }}
                                      onClick={() => this.onRemoveItem(index)}
                                    >
                                      <img
                                        src={Images.deleteIcon}
                                        style={{ width: 15, height: 15 }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 15,
                  }}
                >
                  <ProfileSideBar
                    onGetClientTag={this.props.onGetClientTag}
                    clientTagRes={this.props.clientTagRes}
                    getClientRes={this.props.getClientRes}
                    onGetProfileClientTag={this.props.onGetProfileClientTag}
                    onAddProfileClientTag={this.props.onAddProfileClientTag}
                    addProfielTagRes={this.props.addProfielTagRes}
                    onRemoveProfileClientTag={
                      this.props.onRemoveProfileClientTag
                    }
                    removeProfileTagRes={this.props.removeProfileTagRes}
                    visaStatusData={visaStatusData}
                    onUpdateCaseStatus={onUpdateCaseStatus}
                    visaAppData={visaAppData}
                    onGetVisaApplication={onGetVisaApplication}
                    onGetClientJobHistoryCurrent={onGetClientJobHistoryCurrent}
                    employerJobHistoryCurrentRes={employerJobHistoryCurrentRes}
                    onSetActiveKey={onSetActiveKey}
                    activeKey={activeKey}
                    onGetClientTask={this.props.onGetClientTask}
                    onUpdateTask={this.props.onUpdateTask}
                    onGetAllUsers={this.props.onGetAllUsers}
                    onAddTaskFollower={this.props.onAddTaskFollower}
                    onRemoveTasks={this.props.onRemoveTasks}
                    onAddDailyTasks={this.props.onAddDailyTasks}
                    onGetClientFamily={this.props.onGetClientFamily}
                    onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                    onAddTaskFileNote={this.props.onAddTaskFileNote}
                    onAddTaskComment={this.props.onAddTaskComment}
                    onGetTaskComments={this.props.onGetTaskComments}
                    onGetTaskFollowers={this.props.onGetTaskFollowers}
                    onGetReminder={onGetReminder}
                    onGetVisaStatus={onGetVisaStatus}
                    onGetAdmissionProgram={onGetAdmissionProgram}
                    onGetAdmissionStatuses={onGetAdmissionStatuses}
                    onGetVisaType={onGetVisaType}
                    onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default PrintCase;
