import React, { useState, Fragment, useEffect } from "react";
import { Spin, message, Tooltip, Modal } from "antd";
import {
  FileTextOutlined,
  FileTextFilled,
  UserOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { Table, Tag, Space } from "antd";
import { Link } from "react-router-dom";
var permissions = JSON.parse(localStorage.getItem("adminPermissions"));
const columns = [
  {
    title: "Branch",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Link
          to={{
            pathname:
              permissions.length > 0 &&
              permissions.find((obj) => obj.Module === "BranchDetailView") &&
              permissions.find((obj) => obj.Module === "BranchDetailView")
                .Permisions
                ? "/Branch-Detail"
                : "/",
            state: {
              BranchId: record.id,
            },
          }}
          className={
            permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "BranchDetailView") &&
            permissions.find((obj) => obj.Module === "BranchDetailView")
              .Permisions
              ? ""
              : "disabled-link"
          }
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Phone",
    dataIndex: "phone1",
    key: "phone1",
  },
];

const data = [];

const Branches = ({ onGetBranches, branchesRes, companyId, record }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetBranches(record && record.id).then(() => {
      setLoading(false);
    });
  }, [onGetBranches, record]);
  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={branchesRes && branchesRes.items}
        pagination={false}
      />
    </Spin>
  );
};
export default Branches;
