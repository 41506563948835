import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


class ComingSoon extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    fetching: PropTypes.bool,
    attemptToLogin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <h3 style={{textAlign : "center"}}>Coming Soon</h3>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({

});

ComingSoon = connect(mapStateToProps, mapDispatchToProps)(ComingSoon);

export default ComingSoon;
