import React, { useState, Fragment, useEffect } from "react";
import { Spin, message, Modal } from "antd";
import ClientEmployerTable from "./ClientEmployerTable";

const ContactEmployer = ({
  onGetClientEmp,
  clientEmpRes,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  userDataEmp,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onGetClientJobHis,
  clientJobHisRes,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [updatedata, setUpdatedata] = useState();

  useEffect(() => {
    if (userDataEmp) {
      const clientData = {
        employerId: userDataEmp && userDataEmp.id,
        searchParam: "",
      };

      onGetClientByEmpId(clientData).then(() => {
        setLoading(false);
      });
    }
  }, [onGetClientByEmpId, userDataEmp]);

  const showModal = (value) => {
    setIsModalVisible(!isModalVisible);
    // setCreateMode(modelType);
    // setUpdatedata(value);
  };

  console.log("clientTagResclientTagResclientTagRes", clientTagRes);

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="contact-emp">
            <div className="client-tag-form"></div>
            <div>
              <div>
                <div className="d-end space-between">
                  <div className="contact-Head">
                    <div className="d-flex align-item ">
                      <h4
                        className="top-text heading-forms"
                        style={{
                          marginLeft: "1px",
                          fontWeight: 600,
                          fontSize: "14px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Clients
                      </h4>
                    </div>
                  </div>
                </div>

                <Spin size="large" spinning={loading}>
                  <ClientEmployerTable
                    showModal={showModal}
                    empClientEmpRes={empClientEmpRes}
                    clientTagRes={clientTagRes}
                    onGetProfileClientTag={onGetProfileClientTag}
                    getClientRes={getClientRes}
                    setLoading={setLoading}
                    loading={loading}
                    onGetClientJobHis={onGetClientJobHis}
                    clientJobHisRes={clientJobHisRes}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContactEmployer;
