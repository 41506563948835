import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal, Spin } from "antd";
import PotentialEmployer from "./PotentialEmployer";
import EmployerManagementTabs from "./EmployerManagementTabs";
import AddNewPotentialForm from "./AddNewPotentialForm";

const { TabPane } = Tabs;

const EmployerManagementHead = ({
  onGetEmployerManag,
  employerManagRes,

  onAddEmployerManag,

  onDeleteEmployer,

  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  onSearchEmployer,
  searchEmployerRes,

  onGetEmployerData,
  singleEmployerRes,

  onUploadAvatar,
  imageUploadSuccess,

  location,
  history,

  onUploadFile,
  addUploadFileRes,

  onGetTeamMember,
  teamMembers,
  onGetDocumentPdf,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,

  onGetClientEmp,
  clientEmpRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onAddDocWithName,
  docWithNameRes,

  onGetClientJobHis,
  clientJobHisRes,

  onGetSingleClientHis,
  singleJobHisRes,

  ////////////////////////////
  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  onRemoveEmployerContact,

  onUpdateEmployerManag,

  onUpdateEmployerContact,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onRemoveEmployerFile,

  onUpdateEmployerFile,

  onAddAnyTamplate,
  addAnyTemRes,

  onGetEmployerDocument,
  employerDocumentRes,

  onRemoveEmployerDocument,

  onAddDocumentTittle,

  onGetFilteredType,
  documentFilterRes,

  onAddFilteredType,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerDocument,
  onGetDocumentDownload,
  onUpdateEmployerDocument,

  onAddEmployerCheckList,

  onSendEmailLink,

  onGetEmployerHistory,
  employerHistoryRes,

  onAddEmployerEmail,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onDeleteCreateEmail,

  onMovePotentialEmployer,

  onGetSignature,
  onGetDocuments,
  onAddPotentialEmail,
  potentialClientInfo,
  onGetPClientEmailHistory,
  potentialClientHistory,
  onGetPClientHistory,
  onGetPdf,
  onGetImapForAll,
  imapForAllRes,
  signatureRes,
  onAddEmailDocument,
  documentRes,

  onGetEmailContent,
}) => {
  const [addNew, setAddNew] = useState("");
  const [addEmployerModel, setAddEmployerModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employerTabble, setEmployerTabble] = useState(true);
  const [userDataEmp, setUserDataEmp] = useState({});
  const [handleKey, setHandleKey] = useState("1");
  const [isGetEmployers, SetIsGetEmployers] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const showModal = (modalTypeName) => {
    setAddNew(modalTypeName);
    setAddEmployerModel(true);
  };

  const handleCancel = () => {
    setAddEmployerModel(false);
  };

  const singleEmployeFuc = (userData) => {
    setEmployerTabble(!employerTabble);
    setUserDataEmp(userData);
  };

  const singleEmployeIcons = (userData) => {
    setUserDataEmp(userData);
  };

  const handleSetDefault = () => {
    setIsSearch(false);
  };

  useEffect(() => {
    if (!isGetEmployers) {
      setLoading(true);

      SetIsGetEmployers(true);
      onGetEmployerManag()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, []);

  const callback = (key) => {
    setEmployerTabble(true);
  };

  return (
    <Fragment>
      <div className="reminder-tabs-header employer-Header">
        {employerTabble === true ? (
          <PotentialEmployer
            onGetEmployerManag={onGetEmployerManag}
            employerManagRes={employerManagRes}
            onAddEmployerManag={onAddEmployerManag}
            // addEmployerManagRes={addEmployerManagRes}
            onAddDocWithName={onAddDocWithName}
            docWithNameRes={docWithNameRes}
            onDeleteEmployer={onDeleteEmployer}
            singleEmployeFuc={singleEmployeFuc}
            showModal={showModal}
            setLoading={setLoading}
            loading={loading}
            onMovePotentialEmployer={onMovePotentialEmployer}
            onGetPClientEmailHistory={onGetPClientEmailHistory}
            potentialClientInfo={potentialClientInfo}
            potentialClientHistory={potentialClientHistory}
          />
        ) : (
          <EmployerManagementTabs
            onGetEmailContent={onGetEmailContent}
            userDataEmp={userDataEmp}
            ongetEmployerContact={ongetEmployerContact}
            EmployerContactRes={EmployerContactRes}
            onAddEmployerContact={onAddEmployerContact}
            onRemoveEmployerContact={onRemoveEmployerContact}
            onUpdateEmployerManag={onUpdateEmployerManag}
            onUpdateEmployerContact={onUpdateEmployerContact}
            onGetEmployerManag={onGetEmployerManag}
            employerManagRes={employerManagRes}
            onAddDocWithName={onAddDocWithName}
            docWithNameRes={docWithNameRes}
            onGetEmployerFile={onGetEmployerFile}
            employerFileRes={employerFileRes}
            onAddEmployerFile={onAddEmployerFile}
            onGetLetterTemplates={onGetLetterTemplates}
            LetterTemplatesRes={LetterTemplatesRes}
            onRemoveEmployerFile={onRemoveEmployerFile}
            onUpdateEmployerFile={onUpdateEmployerFile}
            onAddAnyTamplate={onAddAnyTamplate}
            addAnyTemRes={addAnyTemRes}
            onGetEmployerDocument={onGetEmployerDocument}
            employerDocumentRes={employerDocumentRes}
            onRemoveEmployerDocument={onRemoveEmployerDocument}
            onAddDocumentTittle={onAddDocumentTittle}
            onGetFilteredType={onGetFilteredType}
            documentFilterRes={documentFilterRes}
            onAddFilteredType={onAddFilteredType}
            onGetDocumentChecklist={onGetDocumentChecklist}
            docChecklistRes={docChecklistRes}
            onGetEmployerCheckList={onGetEmployerCheckList}
            employerCheckListRes={employerCheckListRes}
            onAddEmployerDocument={onAddEmployerDocument}
            onGetDocumentDownload={onGetDocumentDownload}
            onUpdateEmployerDocument={onUpdateEmployerDocument}
            onAddEmployerCheckList={onAddEmployerCheckList}
            onSendEmailLink={onSendEmailLink}
            onGetEmployerHistory={onGetEmployerHistory}
            employerHistoryRes={employerHistoryRes}
            onAddEmployerEmail={onAddEmployerEmail}
            onGetAutoEmailImport={onGetAutoEmailImport}
            emailImportRes={emailImportRes}
            onAddAutoEmailImport={onAddAutoEmailImport}
            onDeleteCreateEmail={onDeleteCreateEmail}
            onGetSignature={onGetSignature}
            onGetDocuments={onGetDocuments}
            onAddPotentialEmail={onAddPotentialEmail}
            onGetPClientEmailHistory={onGetPClientEmailHistory}
            potentialClientInfo={potentialClientInfo}
            potentialClientHistory={potentialClientHistory}
            onGetPClientHistory={onGetPClientHistory}
            onGetPdf={onGetPdf}
            onGetImapForAll={onGetImapForAll}
            imapForAllRes={imapForAllRes}
            signatureRes={signatureRes}
            onAddEmailDocument={onAddEmailDocument}
            documentRes={documentRes}
          />
        )}
      </div>
      {addEmployerModel === true && (
        <Modal
          className="reminder-model-main"
          title={addNew === "add-potential" && "Add New"}
          visible={addEmployerModel}
          onCancel={handleCancel}
          footer={false}
          width={addNew === "add-potential" && 1000}
        >
          <AddNewPotentialForm
            onAddEmployerManag={onAddEmployerManag}
            handleCancel={handleCancel}
            onAddDocWithName={onAddDocWithName}
            docWithNameRes={docWithNameRes}
            setLoading={setLoading}
            loading={loading}
            onGetEmployerManag={onGetEmployerManag}
          />
        </Modal>
      )}
    </Fragment>
  );
};
export default EmployerManagementHead;
