import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
  InputNumber,
} from "antd";
import { bindActionCreators } from "redux";
import { addBank, getAllBanksListing, editBank } from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const layoutSendingReceipt = {
  labelCol: { span: 7, offset: 2 },
  wrapperCol: { span: 14, offset: 1 },
};

class AddBank extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      title: "",
      bank: "",
      number: "",
      isButtonDisabled: false,
    };
  }

  componentWillMount() {
    let _bankInfo = this.props && this.props.bankInfo;

    if (_bankInfo && _bankInfo) {
      this.setState(
        {
          id: _bankInfo.id,
          name: _bankInfo.name,
          title: _bankInfo.title,
          bank: _bankInfo.bank,
          number: _bankInfo.number,
        },
        () => {
          this.formRef.current.setFieldsValue(
            {
              name: _bankInfo.name,
              title: _bankInfo.title,
              bank: _bankInfo.bank,
              number: _bankInfo.number,
            },
            () => {}
          );
        }
      );
    }
  }

  componentDidMount() {}

  onFinish = (values) => {
    let _name = values.name;
    let _title = values.title;
    let _bank = values.bank;
    let _number = values.number.toString();

    this.setState(
      {
        name: _name,
        title: _title,
        bank: _bank,
        number: _number,
      },
      () => {
        if (this.props && this.props.bankInfo && this.props.bankInfo.id) {
          this.setState({
            isButtonDisabled: true,
          });
          this.props
            .editBank(this.state)
            .then(() => {
              message.success("Bank is updated successfully!");
              this.props.getAllBanksListing();
              this.props.handleCancelAddBankModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        } else {
          this.setState({
            isButtonDisabled: true,
          });
          this.props
            .addBank(this.state)
            .then(() => {
              message.success("Bank is added successfully!");
              this.props.getAllBanksListing();
              this.props.handleCancelAddBankModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        }
      }
    );
  };

  getAddBankForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Required!" },
            // ({ getFieldValue }) => ({
            //   validator(rule, value) {
            //     if (value.length > 40) {
            //
            //       return Promise.reject("Character limit exceeded");
            //     } else {
            //       return Promise.resolve();
            //     }
            //   }
            // })
          ]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bank"
          name="bank"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bank Account Title"
          name="title"
          rules={[
            { required: true, message: "Required!" },
            // ({ getFieldValue }) => ({
            //   validator(rule, value) {
            //     if (value.length > 20) {
            //
            //       return Promise.reject("Character limit exceeded");
            //     } else {
            //       return Promise.resolve();
            //     }
            //   }
            // })
          ]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bank Account Number"
          name="number"
          rules={[
            { required: true, message: "Required!" },
            // ({ getFieldValue }) => ({
            //     validator(rule, value) {
            //         if (value.length > 30) {
            //
            //             return Promise.reject("Character limit exceeded");
            //         } else {
            //             return Promise.resolve();
            //         }
            //     }
            // })
          ]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
      </div>
    );
  };

  render() {
    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-outgoing-payments"
          title="Bank Account"
          visible={this.props.visibleAddBankModal}
          onCancel={this.props.handleCancelAddBankModal}
          footer={null}
          maskClosable={false}
        >
          <Form
            {...layoutSendingReceipt}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            {this.getAddBankForm(this.props)}
            <Row>
              <Col xs={4} offset={18} style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    className={"button-blue"}
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className={"button-blue"}
                    onClick={this.props.handleCancelAddBankModal}
                  >
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.invoicesReducer &&
    state.invoicesReducer.bankInfo &&
    state.invoicesReducer.bankInfo;

  return {
    bankInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankInfo &&
      state.invoicesReducer.bankInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addBank: bindActionCreators(addBank, dispatch),
  editBank: bindActionCreators(editBank, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
});

AddBank = connect(mapStateToProps, mapDispatchToProps)(AddBank);

export default withRouter(AddBank);
