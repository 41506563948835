import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientActivity = (id) => {
  const options = {
    url: "v1/client/ClientLog/" + id,
  };

  options.types = [
    types.CLIENT_ACTIVITY_SUCCESS,
    types.CLIENT_ACTIVITY_FAILURE,
  ];

  return api.get(options);
};

export const addClientActivity = (data) => {
  const options = {
    url: "v1/client/ClientLog",
  };

  options.types = [
    types.ADD_CLIENT_ACTIVITY_SUCCESS,
    types.ADD_CLIENT_ACTIVITY_FAILURE,
  ];

  return api.post(options, data);
};
