import React, { useState, Fragment, useEffect } from "react";
import { Table, Spin } from "antd";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";

const LetterTemplatesDynamic = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    setLoading(true);
    const VisaOpt = {
      url: `v1/config/DynamicKeys/All`,
    };
    VisaOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh
      .get(VisaOpt)
      .then((res) => {
        setLoading(false);
        setKeys(res.items);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Key",
      key: "key",
      dataIndex: "key",
    },
    {
      title: "Value",
      key: "keyDsecription",
      dataIndex: "keyDsecription",
    },
  ];
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="dynamicForm">
            <div className="">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={keys}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LetterTemplatesDynamic;
