import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Partner from "../Profile/Partner";

import {
  getPartnerData,
  getCountries,
  getAccessingAuth,
  getVisaType,
  updPartnerProfile,
  addPartnerProfile,
  updClientAddress,
  updClientEmail,
  updClientMedicals,
  updClientPhones,
  updClientPassport,
  addPoliceCertificate,
  addAccessingAuthorities,
  removePoliceCertificate,
  removeAccAuthority,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  getClientJobHistoryCurrent,
  setActiveKey,
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  uploadAvatar,
  getTeamMember,
  getClientSource,
  setClientTab,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  updateSignedDate,
  addTaskComment,
  getTasksFollowers,
  getTasksComments,
  addReminderTask,
  getBranchVisaCountries,
  setProfileData,
  getPermissonUser,
  getAgents,
  getAutoEmailClientImport,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientProfileData: state.partnerReducer.clientProfileData,
    countriesData: state.visaTypeReducer.countriesData,
    accessingAuthData: state.clientProfileReducer.accessingAuthData,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    clientTagRes: state.accountSetReducer.clientTagRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    teamMembers: state.teamMemberReducer.teamMembers,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    clientTab: state.employerManagReducer.clientTab,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    agentsRes: state.agentsReducer.agentsRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
    onUpdatePartner: bindActionCreators(updPartnerProfile, dispatch),
    onUpdClientAddress: bindActionCreators(updClientAddress, dispatch),
    onUpdClientEmail: bindActionCreators(updClientEmail, dispatch),
    onUpdClientMedical: bindActionCreators(updClientMedicals, dispatch),
    onUpdClientPhone: bindActionCreators(updClientPhones, dispatch),
    onUpdClientPassport: bindActionCreators(updClientPassport, dispatch),
    onAddPartner: bindActionCreators(addPartnerProfile, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetAccessingAuth: bindActionCreators(getAccessingAuth, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onAddPoliceCertificate: bindActionCreators(addPoliceCertificate, dispatch),
    onAddAccAuthorities: bindActionCreators(addAccessingAuthorities, dispatch),
    onRemovePoliceCertificate: bindActionCreators(
      removePoliceCertificate,
      dispatch
    ),
    onRemoveAccesingAuthority: bindActionCreators(removeAccAuthority, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    getClientSource: bindActionCreators(getClientSource, dispatch),
    onSetClientTab: bindActionCreators(setClientTab, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onUpdateSignedDate: bindActionCreators(updateSignedDate, dispatch),
    onAddReminderTask: bindActionCreators(addReminderTask, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetAgents: bindActionCreators(getAgents, dispatch),
    onGetAutoImportEmailClient: bindActionCreators(
      getAutoEmailClientImport,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
