import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmployerReports from "../Reports/EmployerReports";

import {
  getTeamMember,
  saleHistoryReport,
  processingPersonReport,
  clientSourceReport,
  tasksReport,
  birthdayReport,
  fileNotesReport,
  activeCasesReport,
  visaExpiringReport,
  clientEmployersReport,
  employerMedicalExpiry,
  getEmployerManag,
  getClientTag,
  policeCertificateExpiry,
  employerPassportExpiry,
  travelConditionExpiry,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    teamMembers: state.teamMemberReducer.teamMembers,
    employerMedicalExpRes: state.reportReducer.employerMedicalExpRes,
    clientSourceReportRes: state.reportReducer.clientSourceReportRes,
    processingPersonReportRes: state.reportReducer.processingPersonReportRes,
    taskReportRes: state.reportReducer.taskReportRes,
    birthdayReportRes: state.reportReducer.birthdayReportRes,
    fileNotesReportRes: state.reportReducer.fileNotesReportRes,
    activeCasesReportRes: state.reportReducer.activeCasesReportRes,
    visaExpiryReportRes: state.reportReducer.visaExpiryReportRes,
    clientEmployerReportRes: state.reportReducer.clientEmployerReportRes,
    employerManagRes: state.employerManagReducer.employerManagRes,
    clientTagRes: state.accountSetReducer.clientTagRes,
    policeCertificateExpRes: state.reportReducer.policeCertificateExpRes,
    employerPassportExpRes: state.reportReducer.employerPassportExpRes,
    travelConditionExpRes: state.reportReducer.travelConditionExpRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onEmployerMedicalExpiry: bindActionCreators(
      employerMedicalExpiry,
      dispatch
    ),
    onPoliceCertificateExpiry: bindActionCreators(
      policeCertificateExpiry,
      dispatch
    ),
    onEmployerPassportExpiry: bindActionCreators(
      employerPassportExpiry,
      dispatch
    ),
    onTravelConditionExpiry: bindActionCreators(
      travelConditionExpiry,
      dispatch
    ),
    onBirthdayReport: bindActionCreators(birthdayReport, dispatch),
    onFileNotesReport: bindActionCreators(fileNotesReport, dispatch),
    onActiveCasesReport: bindActionCreators(activeCasesReport, dispatch),
    onVisaExpiryReport: bindActionCreators(visaExpiringReport, dispatch),
    onClientEmployerReport: bindActionCreators(clientEmployersReport, dispatch),
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployerReports);
