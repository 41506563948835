export const onHover = (e, task) => {
  if (!task.isCompleted) e.target.style["background-color"] = "green";
};

export const onMouseOuts = (e, task) => {
  if (!task.isCompleted) e.target.style["background-color"] = "white";
};

export const onCompleteTask = (scope, e, task) => {
  e.target.style["background-color"] = "green";
  task.isCompleted = true;
};
