import React, { Fragment, useState, useEffect } from "react";
import { Images } from "./../../Themes";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import CommentModal from "../SideArea/CommentModal";
import CompleteComment from "../SideArea/CompleteComment";
import { Link, useHistory } from "react-router-dom";
import { Spin, message, Pagination, Select, Tooltip } from "antd";
import { UpSquareOutlined, DownSquareOutlined } from "@ant-design/icons";
import Moment from "moment";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const CompletedTasks = ({
  onGetDailyTasks,
  dailyTaskRes,

  onUpdetaTasks,

  updateTitle,

  onRemoveTasks,
  showModal,
  getTasksComments,
  addTaskComment,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  getTaskFollowers,
  onRemoveFollower,
  onRemoveTaskClient,
  onSearchClient,
  onSearchPotentialClient,
  reloadFlag,
  setReload,
  onAddFileNote,
  onGetCompletedTasks,
  users,
  onGetEmployerData,
}) => {
  const [loading, setLoading] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [comments, setTaskComments] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [commentLoader, setCommentLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userId, setUserId] = useState(null);
  const [count, setCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    getCompletedTasks();
    // if (dailyTaskRes && dailyTaskRes.items && dailyTaskRes.items.length > 0) {
    //   let tasksCompleted = dailyTaskRes.items.filter(
    //     (item) => item.isCompleted === true
    //   );
    //   tasksCompleted.forEach((item) => {
    //     item.showComment = false;
    //   });
    //   setCompletedTasks(tasksCompleted);
    // }
  }, [reloadFlag, pageNumber, pageSize, userId]);

  const CommentSection = (value) => {
    setShowComment(value);
  };
  const CommentClose = () => {
    setShowComment(false);
  };

  const getCompletedTasks = () => {
    setLoading(true);
    onGetCompletedTasks(userId, pageNumber, pageSize)
      .then((res) => {
        let completedTasks = res.payload.items;
        completedTasks.forEach((item) => {
          item.showComment = false;
        });
        setCompletedTasks(completedTasks);
        setCount(res.payload.count);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Unable to fetch Task Data!");
        setLoading(false);
        setCompletedTasks([]);
      });
  };

  const sortCompletedTaskDesc = () => {
    const taskArray = [...completedTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
      else return 0;
    });
    setCompletedTasks(taskArray);
  };

  const sortCompletedTaskAsc = () => {
    const taskArray = [...completedTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return 1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return -1;
      else return 0;
    });
    setCompletedTasks(taskArray);
  };

  function onChange(pageNumber, pageSize) {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    // getCompletedTasks();
    console.log("Page: ", pageNumber);
  }

  function onShowSizeChange(current, pageSize) {
    console.log(current, pageSize);
  }

  const RevertCompletedTask = (data) => {
    const userId = localStorage.getItem("userId");
    setLoading(true);
    const revert = {
      id: data.id,
      taskDate: data.taskDate,
      taskTitle: data.taskTitle,
      taskDescription: data.taskDescription,
      subjectId: data.subjectId,
      isPontential: data.isPontential,
      isCompleted: false,
    };
    onUpdetaTasks(revert).then(() => {
      onGetDailyTasks().then(() => {
        setLoading(false);
        message.success("Successfully Converted!");
        setReload(!reloadFlag);
      });
    });
  };

  const getComments = (data) => {
    setCommentLoader(true);
    let comments = [];
    getTasksComments(data.id)
      .then((res) => {
        res.payload.items.forEach((item) => {
          comments.push(item);
        });
        setTaskComments(comments);
        setCommentLoader(false);
        console.log(res);
      })
      .catch((error) => {
        setTaskComments(comments);
        setCommentLoader(false);
      });
  };

  const getFollower = (data) => {
    getTaskFollowers(data.id)
      .then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
      })
      .catch((error) => {
        setFollowers([]);
      });
  };

  const removeTask = (id) => {
    setLoading(true);
    const remove = {
      id: id,
      delete: true,
    };

    onRemoveTasks(remove).then(() => {
      onGetDailyTasks().then(() => {
        setLoading(false);
        message.success("Successfully Deleted!");
        setReload(!reloadFlag);
      });
    });
  };

  const openProfile = (data) => {
    if (data.clientName && data.clientName != "") {
      onGetClientProfile(data.subjectId).then((res) => {
        let clientData = res.payload;
        openClientFile(
          clientData.id,
          clientData.clientId,
          clientData.memberType,
          clientData.familyId
        );
      });
    } else if (data.potentialClientName && data.potentialClientName != "") {
      onGetPotentialClient(data.subjectId).then((res) => {
        onGetUpdateBol(false);
        history.push("/update-potential-client");
      });
    } else if (data.employerName && data.employerName != "") {
      onGetEmployerData(data.subjectId).then((res) => {
        onGetUpdateBol(false);

        history.push({
          pathname: "/employer-management",
          state: { userData: res.payload },
        });
      });
    } else return;
  };

  const openClientFile = (id, clientId, memberType, familyId) => {
    localStorage.setItem("clientProfileIdMain", id);
    localStorage.setItem("familyId", familyId);
    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div style={{ marginRight: "auto" }}>
          <Select
            showSearch
            options={users}
            style={{ width: 200 }}
            placeholder="Search to Select Users"
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) => {
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={(item) => {
              setUserId(item);
              setPageNumber(1);
            }}
          />
        </div>
        <div className="completed-tasks">
          <div className="task-to-do">
            <div className="heading-style" style={{ display: "flex" }}>
              <span>COMPLETED TASKS</span>
              <div style={{ marginLeft: "auto" }}>
                <UpSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => sortCompletedTaskAsc()}
                />
                <DownSquareOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => sortCompletedTaskDesc()}
                />
              </div>
            </div>

            <div className="cv-gray-cont">
              {completedTasks.length > 0 &&
                completedTasks.map((data, index) => {
                  return (
                    <div>
                      <div
                        key={index}
                        data={data}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: 29,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {/* <div>
                            <input
                              type="checkbox"
                              className="sus-checkbox"
                              // onChange={this.handleCheck}
                              // defaultChecked={this.state.checked}
                            />
                          </div> */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "75%",
                            }}
                          >
                            <div
                              onClick={() => {
                                getComments(data);
                                getFollower(data);
                                let tasks = [...completedTasks];
                                tasks.forEach((item) => {
                                  if (item.id === data.id)
                                    item.showComment = !item.showComment;
                                });
                                setCompletedTasks(tasks);
                              }}
                              className="cv-normal-text"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="cv-normal-text">
                                {data && data.taskTitle} (
                                {yyyymmddFormate(data && data.taskDate)})
                              </span>
                            </div>
                            {data.potentialClientName != "" ? (
                              data.potentialClientName ? (
                                <div
                                  className="marked-name"
                                  style={{
                                    cursor: "pointer",
                                    width: "auto",
                                  }}
                                  onClick={() => openProfile(data)}
                                >
                                  <span className="marked-name-font">
                                    {data.potentialClientName}
                                  </span>
                                </div>
                              ) : null
                            ) : data.clientName ? (
                              <div
                                className="marked-name"
                                style={{
                                  cursor: "pointer",
                                  width: "auto",
                                }}
                                onClick={() => openProfile(data)}
                              >
                                <span className="marked-name-font">
                                  {data.clientName}
                                </span>
                              </div>
                            ) : data.employerName ? (
                              <div
                                className="marked-name"
                                style={{
                                  cursor: "pointer",
                                  width: "auto",
                                }}
                                onClick={() => openProfile(data)}
                              >
                                <span className="marked-name-font">
                                  {data.employerName}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            right: 36,
                          }}
                        >
                          <div className="mdtr-user-cont">
                            <img
                              src={Images.userCircleGray}
                              style={{ width: 15, height: 15 }}
                              onClick={() =>
                                showModal("add-follower", data.id, data)
                              }
                            />
                            <img
                              src={Images.nextGray}
                              style={{
                                marginLeft: 5,
                                transform: `rotate(90deg)`,
                                width: 12,
                                height: 12,
                              }}
                              onClick={() =>
                                showModal("add-follower", data.id, data)
                              }
                            />
                          </div>

                          <div
                            className="mdtr-user-cont"
                            style={{
                              backgroundColor: "#FFFFFF",
                              marginLeft: 5,
                            }}
                          >
                            <img
                              onClick={() =>
                                showModal("confirm-delete", data.id)
                              }
                              src={Images.deleteIcon}
                              style={{ width: 15, height: 15 }}
                            />
                          </div>
                          <div
                            className="mdtr-user-cont"
                            style={{
                              backgroundColor: "#FFFFFF",
                              marginLeft: 5,
                            }}
                          >
                            <img
                              onClick={() => RevertCompletedTask(data)}
                              src={Images.arrowCurveBlue}
                              style={{
                                width: 15,
                                height: 15,
                                transform: `rotate(270deg)`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {data.showComment ? (
                          <CompleteComment
                            CommentClose={CommentClose}
                            comments={comments}
                            getComments={getComments}
                            getFollower={getFollower}
                            getTasksComments={getTasksComments}
                            dailyTaskRes={dailyTaskRes}
                            data={data}
                            addTaskComment={addTaskComment}
                            followers={followers}
                            getTaskFollowers={getTaskFollowers}
                            onRemoveFollower={onRemoveFollower}
                            onRemoveTaskClient={onRemoveTaskClient}
                            onSearchClient={onSearchClient}
                            onSearchPotentialClient={onSearchPotentialClient}
                            showComment={data.showComment}
                            reloadFlag={reloadFlag}
                            setReload={setReload}
                            onAddFileNote={onAddFileNote}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div style={{ textAlign: "center" }}>
              <Pagination
                showQuickJumper
                showSizeChanger
                // showTotal
                pageSizeOptions={[5, 10, 15]}
                current={pageNumber}
                total={count}
                onChange={onChange}
                // onShowSizeChange={onShowSizeChange}
              />
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default CompletedTasks;
