import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OrganizationSetting from "./../AccountSetting/OrganizationSetting/OrganizatinSetting";

import { setActiveInnerTab } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationSetting);
