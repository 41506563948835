import React, { Fragment } from "react";
import "./ClientsStyles.css";
import { Images } from "../Themes";
import { Table, Tag, message, Select, Modal, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { apiRefresh } from "../services/api";
import LetterTemplatesForm from "../AccountSetting/LetterTemplates/LetterTemplatesForm";

const { Option } = Select;
// const history = useHistory();

const options2 = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const headOption = [{ tabName: "All Clients" }];

// const AllClientsTable = ({ GetSearchResponse, onRemoveClient, history }) => {

const columns = [
  {
    width: "50px",
    title: "EZM ID",
    dataIndex: "clientNumberIZM",
    key: "clientNumberIZM",
    ellipsis: true,
  },

  {
    width: "50px",
    title: "Name",
    dataIndex: "firstName",
    key: "firstName",
    render: (text, record) => {
      return (
        <Link
          to={{
            pathname:
              record.memberType.toLowerCase() === "client"
                ? `/profile?para=${record.id}`
                : record.memberType.toLowerCase() === "spouse"
                ? `/partner-profile?para=${record.clientId}&&fId=${record.familyId}`
                : `/client-family-member?para=${record.clientId}&&fId=${record.familyId}`,
            state: {
              id: record.clientId,
              familyId: record.familyId,
              clickBy: "clientArea",
              memberId: record.id,
            },
            // params: {
            //   id: record.clientId,
            //   familyId: record.familyId,
            //   clickBy: "clientArea",
            //   memberId: record.id,
            // },
          }}
          params={{
            id: record.clientId,
            familyId: record.familyId,
            clickBy: "clientArea",
            memberId: record.id,
          }}
          style={{ textDecoration: "none" }}
        >
          <div
            onClick={() => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }}
          >
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {record.firstName + " " + record.lastName}
            </span>
          </div>
        </Link>
      );
    },
  },
  {
    width: "50px",
    title: "Internal ID",
    dataIndex: "clientSerial",
    key: "clientSerial",
    ellipsis: true,
  },
  {
    width: "50px",
    title: "Mobile #",
    dataIndex: "contact",
    key: "contact",
  },
  {
    width: "50px",
    title: "Email",
    dataIndex: "address",
    key: "address",
  },
  {
    width: "50px",
    title: "Processing Person",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    width: "50px",
    title: "Tags",
    key: "clientTags",
    dataIndex: "clientTags",
    // render: (data) => (
    //   <>
    //     {data &&
    //       data.payload &&
    //       data.payload.items.map((data, index) => {
    //         return <Tag key={index}>{data.clientTags}</Tag>;
    //       })}
    //   </>
    // ),
  },
  {
    width: "50px",
    title: "Action",
    dataIndex: "action",
    render: (text, record) => {
      return (
        <div className="table-action">
          <DeleteOutlined
            onClick={() => {
              let data = {
                url: "v1/client",
              };
              let remove = {
                id: record.id,
                delete: true,
              };

              AllClientsTable.removeTag(remove, data);
            }}
          />
        </div>
      );
    },
  },
];
class AllClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: props.clientsCount,
      defaultCurrent: 1,
      pageSize: 20,
      pageNumber: 1,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientsCount !== this.props.clientsCount) {
      this.setState({ totalPages: this.props.clientsCount });
    }
    if (
      prevProps.pageSize != this.props.pageSize &&
      this.props.pageSize == 20
    ) {
      this.setState({ pageSize: 20 });
    }
  }

  paginate = (value) => {
    this.props.onPaginate(true, value);
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: this.state.pageSize != value.pageSize ? 1 : value.current,
        loading: true,
      },
      () => {
        var { requestData } = this.props;
        let data = {
          firstName: requestData.firstName,
          lastName: requestData.lastName,
          email: requestData.email,
          occupation: requestData.occupation,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          branchId: requestData.branchId,
          mobile: requestData.mobile,
          company: requestData.company,
          activeInactive: requestData.activeInactive,
          clientSort: requestData.clientSort,
          passportNo: requestData.passportNo,
          surnameStart: requestData.surnameStart,
          status: requestData.status,
          dateOfBirth: requestData.dateOfBirth,
          jobSector: requestData.jobSector,
          includeDeleted: requestData.includeDeleted,
          internalId: requestData.internalId,
          familyMember: requestData.familyMember,
          visaType: requestData.visaType,
          salePerson: requestData.salePerson,
          clientType: requestData.clientType,
          inzClient: requestData.inzClient,
          clientTags: requestData.clientTags,
          clientNumber: requestData.clientNumber,
          active: requestData.active,
          includeAll: requestData.includeAll,
          processingPerson:
            localStorage.getItem("userOwner") === "True"
              ? "00000000-0000-0000-0000-000000000000"
              : localStorage.getItem("userManager") === "true"
              ? "00000000-0000-0000-0000-000000000000"
              : localStorage.getItem("userOwner") !== "True" &&
                localStorage.getItem("userManager") !== "true" &&
                JSON.parse(
                  decodeURIComponent(
                    escape(
                      window.atob(localStorage.getItem("userSystemPermissions"))
                    )
                  )
                ).find((x) => x.role.toLowerCase() === "client database")
                  .status === 1
              ? "00000000-0000-0000-0000-000000000000"
              : localStorage.getItem("userId"),
        };
        this.props
          .searchClient(data)
          .then(() => {
            this.props.onPaginate(false, value);
            this.setState(
              {
                defaultCurrent: 1,
                loading: false,
              },
              () => {
                console.log("state", this.state);
              }
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  static removeTag = (remove, data) => {
    const create = window.confirm(
      "By deleting applicant client all family members will be deleted!"
    );
    if (create) {
      apiRefresh.delete(data, remove).then((res) => {
        window.location.reload();
      });
    }
    // setLoading(true);
    // const userId = localStorage.getItem("userId");
    // const remove = {
    //   id: id,
    //   delete: true,
    //
    // };
    // this.props
    //   .onRemoveClient(remove)
    //   // .then(() => {
    //   //   onGetClientTag()
    //   .then(() => {
    //     // setLoading(false);
    //     message.success("Successfully Deleted!");
    //     // });
    //   });
  };

  render() {
    const { GetSearchResponse } = this.props;
    const { loading } = this.state;
    return (
      <Fragment>
        <Spin spinning={loading}>
          <div className="all-clients-tables">
            <div class="main-table-cont">
              <Table
                columns={columns}
                dataSource={GetSearchResponse && GetSearchResponse}
                onChange={this.paginate}
                pagination={{
                  defaultCurrent: this.state.defaultCurrent,
                  current: this.state.pageNumber,
                  total: this.state.totalPages,
                  showSizeChanger: true,
                  defaultPageSize: this.state.pageSize,
                  pageSizeOptions: ["20", "50", "100", "150", "200"],
                }}
                footer={(footer) => (
                  <div>
                    <span style={{ color: "#185b94", fontSize: 14 }}>
                      Showing{" "}
                      {this.state.pageNumber === 1
                        ? ""
                        : this.state.pageNumber - 1}
                      {GetSearchResponse && GetSearchResponse.length > 0
                        ? 1
                        : 0}{" "}
                      to{" "}
                      {this.state.totalPages < this.state.pageSize
                        ? this.state.totalPages
                        : this.state.pageNumber * this.state.pageSize}{" "}
                      out of {this.state.totalPages}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        </Spin>
      </Fragment>
    );
  }
}

export default AllClientsTable;
