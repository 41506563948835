import React from "react";
import {
  message,
  Spin,
  Button,
  Upload,
  Form,
  Checkbox,
  Input,
  Space,
  DatePicker,
  Select,
  Radio,
} from "antd";
import queryString from "query-string";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";

import Section from "../Components/Questionnaire/Section";
import FillQuestionRow from "../Components/Questionnaire/FillQuestionRow";
import Header from "../Components/Questionnaire/Header";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

var stopLoader = false;
const { Option } = Select;

var questionnaireDuplicate = null;

class SubmitQuestionnaire extends React.Component {
  constructor(props) {
    const initialQueryValue = queryString.parse(props.location.search);
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
      sectionData: null,
      questionnaire: null,
    };
    var questionnaireId = initialQueryValue ? initialQueryValue.id : 0;

    this.props.onGetQuestionnaire(questionnaireId).then((res) => {
      this.setState({ questionnaire: res.payload });
    });
    this.props.onGetCountries();

    // this.props
    //   .onGetTeamMember()
    //   .then(() => {})
    //   .catch(err => {
    //     console.log(this.props.teamMemberError);
    //   });
    // this.props.onGetBranch();
  }
  formRef = React.createRef();

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.questionnaireData !== this.props.questionnaireData) {
      questionnaireDuplicate = this.props.questionnaireData;
      for (var i = 0; i < questionnaireDuplicate.sections.length; i++) {
        for (
          var j = 0;
          j < questionnaireDuplicate.sections[i].questions.length;
          j++
        ) {
          for (
            var k = 0;
            k <
            questionnaireDuplicate.sections[i].questions[j].questionOptions
              .length;
            k++
          ) {
            if (
              questionnaireDuplicate.sections[i].questions[j].questionOptions[k]
                .optionalQuestions.length > 0
            ) {
              for (
                var l = 0;
                l <
                questionnaireDuplicate.sections[i].questions[j].questionOptions[
                  k
                ].optionalQuestions.length;
                l++
              ) {
                if (
                  questionnaireDuplicate.sections[i].questions[j]
                    .questionOptions[k].optionalQuestions[l].questions.length >
                  0
                ) {
                  for (
                    var m = 0;
                    m <
                    questionnaireDuplicate.sections[i].questions[j]
                      .questionOptions[k].optionalQuestions[l].questions.length;
                    m++
                  ) {
                    this.findQuestion(
                      questionnaireDuplicate.sections[i].questions[j]
                        .questionOptions[k].optionalQuestions[l].questions[m],
                      questionnaireDuplicate.sections[i].questions[j]
                        .questionOptions
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  findQuestion = (question, optionsArray) => {
    for (var i = 0; i < optionsArray.length; i++) {
      if (
        optionsArray[i].optionalQuestions &&
        optionsArray[i].optionalQuestions.length > 0
      ) {
        for (var j = 0; j < optionsArray[i].optionalQuestions.length; j++) {
          console.log(
            "optional questions",
            j,
            optionsArray[i].optionalQuestions[j]
          );
          if (
            optionsArray[i].optionalQuestions[j].questions &&
            optionsArray[i].optionalQuestions[j].questions.length > 0
          ) {
            for (
              var k = 0;
              k < optionsArray[i].optionalQuestions[j].questions.length;
              k++
            ) {
              if (
                question.id !==
                  optionsArray[i].optionalQuestions[j].questions[k].id &&
                question ===
                  optionsArray[i].optionalQuestions[j].questions[k].question
              ) {
              }
            }
          }
        }
      }
    }
    const data = optionsArray;

    // const r = data.filter(d => d.optionalQuestions.filter(c => console.log("in filter",  c)));
    // console.log(r);
    //
  };

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  // onChange = value => {
  //   console.log(`selected ${value}`);
  // };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  onFinish = (values) => {
    console.log(values);
    var filledAnswers = [];
    var answers = [];
    var userId = localStorage.getItem("userId");
    if (values.sections && values.sections.length > 0) {
      for (var i = 0; i < values.sections.length; i++) {
        if (values.sections[i].questions) {
          for (var j = 0; j < values.sections[i].questions.length; j++) {
            if (values.sections[i].questions[j].questionOptions) {
              for (
                var k = 0;
                k < values.sections[i].questions[j].questionOptions.length;
                k++
              ) {
                if (
                  values.sections[i].questions[j].questionOptions[k]
                    .optionalQuestions
                ) {
                  for (
                    var l = 0;
                    l <
                    values.sections[i].questions[j].questionOptions[k]
                      .optionalQuestions.length;
                    l++
                  ) {
                    for (
                      var m = 0;
                      m <
                      values.sections[i].questions[j].questionOptions[k]
                        .optionalQuestions[l].questions.length;
                      m++
                    ) {
                      if (
                        values.sections[i].questions[j].questionOptions[k]
                          .optionalQuestions[l].questions[m].filledAnswers &&
                        values.sections[i].questions[j].questionOptions[k]
                          .optionalQuestions[l].questions[m].filledAnswers
                          .length > 0
                      ) {
                        for (
                          var n = 0;
                          n <
                          values.sections[i].questions[j].questionOptions[k]
                            .optionalQuestions[l].questions[m].filledAnswers
                            .length;
                          n++
                        ) {
                          filledAnswers.push({
                            questionnaireId: this.props.questionnaireData
                              .questionnaireId,
                            questionId:
                              values.sections[i].questions[j].questionOptions[k]
                                .optionalQuestions[l].questions[m].id,
                            answer: values.sections[i].questions[j]
                              .questionOptions[k].optionalQuestions[l]
                              .questions[m].filledAnswers[n].answer
                              ? values.sections[i].questions[j].questionOptions[
                                  k
                                ].optionalQuestions[l].questions[
                                  m
                                ].filledAnswers[n].answer.toString()
                              : "",
                          });
                        }

                        answers.push({ filledAnswers: filledAnswers });
                        filledAnswers = [];
                      }
                    }
                  }
                }
              }
            }
            if (
              values.sections[i].questions[j].filledAnswers &&
              values.sections[i].questions[j].filledAnswers.length > 0
            ) {
              for (
                var k = 0;
                k < values.sections[i].questions[j].filledAnswers.length;
                k++
              ) {
                filledAnswers.push({
                  questionnaireId: this.props.questionnaireData.questionnaireId,
                  questionId: values.sections[i].questions[j].id,
                  answer: values.sections[i].questions[j].filledAnswers[k]
                    .answer
                    ? values.sections[i].questions[j].filledAnswers[
                        k
                      ].answer.toString()
                    : "",
                });
              }

              answers.push({ filledAnswers: filledAnswers });
              filledAnswers = [];
            }
          }
        }
      }
    }
    // let data = {
    //   name: values.questionnaireName,
    //   branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   allowAttachments: values.allowAttachments,
    //   deceleration: values.allowDecelration,
    //   showInPotential: true,
    //   showInClient: true,
    //   showInPublic: true,
    //   questionnaireEmail: "string",
    //   declarationText: "string",
    //   clientId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   parentQuestionnaireId: 0,
    //   isPotential: true,
    //   createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   sections: { ...values.sections }
    // };

    var userId = localStorage.getItem("userId");

    let data = {
      id: 0,
      filledQuestionnaireId: 0,
      questionnaireId: this.props.questionnaireData.questionnaireId,
      clientId: userId,
      isPotential: true,
      isPublic: true,
      clientName: values.fullName,
      createdBy: this.props.questionnaireData.createdBy,
      answers: answers,
    };
    this.props.onSubmitQuestionnaire(data).then(() => {
      message.success("Questionnaire answers submited successfully");
    });
  };

  addSection = (sectionIndex, sectionData) => {
    // add();
    // this.props.questionnaireData.sections.push(sectionData);

    this.props.questionnaireData.sections.splice(
      sectionIndex + 1,
      0,
      sectionData
    );
    setTimeout(() => {
      this.setState({
        questionnaire: this.props.questionnaireData,
      });
    }, 3000);

    this.setState({ sectionData: sectionData });
  };

  onChange = (value, index, add, questionnaire) => {
    // const fields = this.formRef.current.getFieldsValue();
    // fields.sections.push(value);
    // let questionnaire = this.state.questionnaire;
    add(value);
    if (questionnaire.sections) {
      questionnaire.sections.push(value);
      this.setState({ questionnaire: questionnaire });
    } else {
      questionnaire.optionalQuestions.push(value);
    }

    //this.formRef.current.setFieldsValue({ fields });
  };

  render() {
    const {
      selectedOption,
      fullName,
      email,
      password,
      imageUrl,
      addUserImageUrl,
      exportLoading,
      questionnaire,
    } = this.state;
    const { questionnaireData, countriesData } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="sus-container" style={{ paddingBottom: 80 }}>
              {false && <Header />}
              <div style={{ marginTop: 20 }}>
                <div className="title-container">
                  <span className="heading-text">
                    {this.state.questionnaire && this.state.questionnaire.name}
                  </span>
                </div>
                {questionnaire && (
                  <Form
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    initialValues={questionnaire}
                  >
                    <div className="cq-container">
                      <div
                        className="cq-form-cont"
                        style={{
                          width: "100%",
                          marginLeft: 0,
                          marginTop: 0,
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingBottom: 30,
                        }}
                      >
                        <div
                          className="form-cont"
                          style={{
                            justifyContent: "space-between",
                            paddingTop: 20,
                            paddingBottom: 0,
                            display: "block",
                          }}
                        >
                          <div
                            style={{
                              marginTop: 15,
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p className="cq-label-text">FULL NAME</p>
                                <div
                                  className="profile-input-border"
                                  style={{
                                    width: "60%",
                                    border: 0,
                                  }}
                                >
                                  <Form.Item name="fullName">
                                    <Input
                                      className="profile-input"
                                      placeholder="FULL NAME"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Form.List name="sections">
                        {(fields, { add, remove }) => {
                          return (
                            <div>
                              {fields.map((field, index) => {
                                return (
                                  <div key={index}>
                                    <div
                                      className="title-container"
                                      style={{
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span className="heading-text">
                                        {this.state.sectionData
                                          ? this.state.sectionData.name
                                          : this.state.questionnaire.sections[
                                              index
                                            ].name}
                                      </span>
                                      {false &&
                                        this.state.questionnaire.sections[
                                          index - 1
                                        ].id !==
                                          this.state.questionnaire.sections[
                                            index
                                          ].id &&
                                        this.state.questionnaire.sections[index]
                                          .allowMultiple && (
                                          <div
                                            className="cq-add-button"
                                            style={{ marginLeft: 20 }}
                                            onClick={() =>
                                              this.onChange(
                                                this.state.questionnaire
                                                  .sections[index],
                                                field.key
                                              )
                                            }
                                          >
                                            <img
                                              src={Images.plusIcon}
                                              style={{ width: 20 }}
                                            />
                                          </div>
                                        )}

                                      {index === 0 &&
                                      this.state.questionnaire.sections[index]
                                        .allowMultiple ? (
                                        <div
                                          className="cq-add-button"
                                          style={{ marginLeft: 20 }}
                                          onClick={() =>
                                            this.onChange(
                                              this.state.questionnaire.sections[
                                                index
                                              ],
                                              index + 1,
                                              add,
                                              this.state.questionnaire
                                            )
                                          }
                                        >
                                          <img
                                            src={Images.plusIcon}
                                            style={{ width: 20 }}
                                          />
                                        </div>
                                      ) : (
                                        index != 0 &&
                                        this.state.questionnaire.sections[
                                          index - 1
                                        ].id !==
                                          this.state.questionnaire.sections[
                                            index
                                          ].id &&
                                        this.state.questionnaire.sections[index]
                                          .allowMultiple && (
                                          <div
                                            className="cq-add-button"
                                            style={{ marginLeft: 20 }}
                                            onClick={() =>
                                              this.onChange(
                                                this.state.questionnaire
                                                  .sections[index],
                                                field.key,
                                                add,
                                                this.state.questionnaire
                                              )
                                            }
                                          >
                                            <img
                                              src={Images.plusIcon}
                                              style={{ width: 20 }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className="cq-form-cont"
                                      style={{
                                        width: "100%",
                                        marginLeft: 0,
                                        marginTop: 0,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        paddingBottom: 30,
                                      }}
                                    >
                                      <div
                                        className="form-cont"
                                        style={{
                                          justifyContent: "space-between",
                                          paddingTop: 20,
                                          paddingBottom: 0,
                                          display: "block",
                                        }}
                                      >
                                        <Form.List
                                          name={[field.name, "questions"]}
                                          key={[field.key, "questions"]}
                                        >
                                          {(questions, { add, remove }) => {
                                            return (
                                              <div>
                                                {questions.map(
                                                  (question, quesInd) => (
                                                    <div
                                                      key={quesInd}
                                                      style={{
                                                        marginTop: 15,
                                                      }}
                                                    >
                                                      <Form.Item
                                                        required={false}
                                                        key={question.key}
                                                      >
                                                        <FillQuestionRow
                                                          question={question}
                                                          countriesData={
                                                            countriesData
                                                              ? countriesData.items
                                                              : []
                                                          }
                                                          questionData={
                                                            this.state
                                                              .questionnaire
                                                              .sections[index]
                                                              .questions[
                                                              quesInd
                                                            ]
                                                          }
                                                          index={quesInd}
                                                          onChange={
                                                            this.onChange
                                                          }
                                                          questionsLength={
                                                            this.state
                                                              .questionnaire
                                                              .sections[index]
                                                              .questions.length
                                                          }
                                                          sectionsLength={
                                                            this.state
                                                              .questionnaire
                                                              .sections.length
                                                          }
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            );
                                          }}
                                        </Form.List>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </Form.List>

                      {this.state.questionnaire &&
                        this.state.questionnaire.deceleration && (
                          <div style={{ marginTop: 10 }}>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 0,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                style={{
                                  justifyContent: "space-between",
                                  paddingTop: 20,
                                  paddingBottom: 0,
                                  display: "block",
                                }}
                              >
                                <Checkbox />
                                <span style={{ marginLeft: 5, fontSize: 12 }}>
                                  I hereby declare that the information given by
                                  me in this application is true and correct to
                                  the best of my knowledge.I understand and
                                  agree that any false information,
                                  misrepresentation, or omission of facts in
                                  this application and the application process
                                  may be justification for refusal/ decline.I
                                  further understand and agree that all
                                  information furnished in this application and
                                  the application process may be verified as may
                                  be necessary.
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {this.state.questionnaire &&
                        this.state.questionnaire.allowAttachments && (
                          <div>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                style={{
                                  justifyContent: "center",
                                  paddingTop: 20,
                                  paddingBottom: 0,
                                  display: "flex",
                                }}
                              >
                                <span style={{ marginLeft: 5, fontSize: 12 }}>
                                  Drop or click here to attach CV and other
                                  documents. Max 10 files
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {/* <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="cq-primary-btn"
                            style={{ marginTop: 20, marginRight: 10 }}
                          >
                            SAVE
                          </Button>
                        </Form.Item>
                      </div> */}
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitQuestionnaire;
