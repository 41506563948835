import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Profile from "../Profile/Profile";

import {
  getClientProfile,
  getAllClients,
  getProcessingGroups,
  addProcessingGroup,
  removeProcessingGroup,
  getGroups,
  getEmailContent,
  getProcessingPerson,
  addPoliceCertificate,
  getAllPoliceCertificate,
  getCountries,
  addAccessingAuthorities,
  removePoliceCertificate,
  removeAccAuthority,
  getTeamMember,
  addProcessingPerson,
  removeProcessingPerson,
  searchConnection,
  addConnection,
  removeConnection,
  getAccessingAuth,
  updClientProfile,
  updClientAddress,
  updClientEmail,
  updClientMedicals,
  updClientPhones,
  updClientPassport,
  getGroupMembers,
  getVisaType,
  uploadAvatar,
  getUserBranch,
  userLogout,
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  sendDynamicLinkEmail,
  createClientLink,
  getClientLink,
  setCmvLinkTemplate,
  removeClient,
  getClientJobHistoryCurrent,
  setActiveKey,
  updateSignedDate,
  getClientSource,
  searchMainClient,
  checkUniqueEmail,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  updateReminder,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  getVisaTypeByCountry,
  removeFollower,
  getAdmissionProgram,
  getAdmissionStatuses,
  updAdmissionStatus,
  startNewApplication,
  getAllFilledQuestionnaire,
  addDraftClient,
  addReminderTask,
  getSignature,
  getBranchVisaCountries,
  setProfileData,
  getPermissonUser,
  getAgents,
  getAutoEmailClientImport,
  getMailBox,
  autoImportEmailClient,
} from "../store/Actions";
import { setClientTab } from "../store/Actions/EmployerManagement";

const mapStateToProps = (state) => {
  return {
    clientProfileData: state.clientProfileReducer.clientProfileData,
    policeCertificateData: state.clientProfileReducer.policeCertificateData,
    searchConnectionData: state.clientProfileReducer.searchConnectionData,
    accessingAuthData: state.clientProfileReducer.accessingAuthData,
    groupMembersData: state.clientProfileReducer.groupMembersData,
    removeProcGroupSuccess: state.clientProfileReducer.removeProcGroupSuccess,
    countriesData: state.visaTypeReducer.countriesData,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
    groupsData: state.groupReducer.groupsData,
    teamMembers: state.teamMemberReducer.teamMembers,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    userBranchData: state.branchReducer.userBranchData,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    cmvLinkTemplateData: state.clientProfileReducer.cmvLinkTemplateData,
    removeClientRes: state.clientProfileReducer.removeClientRes,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    searchClientData: state.clientProfileReducer.clientsData,
    uniqueEmailRes: state.clientProfileReducer.uniqueEmailRes,
    clientTab: state.employerManagReducer.clientTab,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    updReminderRes: state.reminderReducer.updReminderRes,
    countryVisaTypeData: state.clientVisaReducer.countryVisaTypeData,
    admissionProgramData: state.clientAdmissionReducer.admissionProgramData,
    admissionStatusData: state.clientAdmissionReducer.admissionStatusData,
    allFilledQuestionnaireRes:
      state.questionnaireReducer.allFilledQuestionnaireRes,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    agentsRes: state.agentsReducer.agentsRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onUpdClientProfile: bindActionCreators(updClientProfile, dispatch),
    onUpdClientAddress: bindActionCreators(updClientAddress, dispatch),
    onUpdClientEmail: bindActionCreators(updClientEmail, dispatch),
    onUpdClientMedical: bindActionCreators(updClientMedicals, dispatch),
    onUpdClientPhone: bindActionCreators(updClientPhones, dispatch),
    onUpdClientPassport: bindActionCreators(updClientPassport, dispatch),
    onGetAllClient: bindActionCreators(getAllClients, dispatch),
    onGetProcessingGroups: bindActionCreators(getProcessingGroups, dispatch),
    onAddProcessingGroups: bindActionCreators(addProcessingGroup, dispatch),
    onAddProcessingPerson: bindActionCreators(addProcessingPerson, dispatch),
    onRemoveProcessingGroup: bindActionCreators(
      removeProcessingGroup,
      dispatch
    ),
    onRemoveProcessingPerson: bindActionCreators(
      removeProcessingPerson,
      dispatch
    ),
    onGetGroupMembers: bindActionCreators(getGroupMembers, dispatch),
    onAddPoliceCertificate: bindActionCreators(addPoliceCertificate, dispatch),
    onAddAccAuthorities: bindActionCreators(addAccessingAuthorities, dispatch),
    onRemovePoliceCertificate: bindActionCreators(
      removePoliceCertificate,
      dispatch
    ),
    onRemoveAccesingAuthority: bindActionCreators(removeAccAuthority, dispatch),
    onGetAllPoliceCertificate: bindActionCreators(
      getAllPoliceCertificate,
      dispatch
    ),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetGroups: bindActionCreators(getGroups, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onSearchConnection: bindActionCreators(searchConnection, dispatch),
    onAddConnection: bindActionCreators(addConnection, dispatch),
    onRemoveConnection: bindActionCreators(removeConnection, dispatch),
    onGetAccessingAuth: bindActionCreators(getAccessingAuth, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    onUserLogout: bindActionCreators(userLogout, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onCreateClientLink: bindActionCreators(createClientLink, dispatch),
    onGetClientLink: bindActionCreators(getClientLink, dispatch),
    onSetCmvLinkTemplate: bindActionCreators(setCmvLinkTemplate, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdateSignedDate: bindActionCreators(updateSignedDate, dispatch),
    getClientSource: bindActionCreators(getClientSource, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onCheckUniqueEmail: bindActionCreators(checkUniqueEmail, dispatch),
    onSetClientTab: bindActionCreators(setClientTab, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdateReminder: bindActionCreators(updateReminder, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdAdmissionStatus: bindActionCreators(updAdmissionStatus, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetAllFilledQuestionnaire: bindActionCreators(
      getAllFilledQuestionnaire,
      dispatch
    ),
    onAddDraftClient: bindActionCreators(addDraftClient, dispatch),
    onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
    onAddReminderTask: bindActionCreators(addReminderTask, dispatch),
    onGetSignature: bindActionCreators(getSignature, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetAgents: bindActionCreators(getAgents, dispatch),
    onGetAutoImportEmailClient: bindActionCreators(
      getAutoEmailClientImport,
      dispatch
    ),
    onGetMailBox: bindActionCreators(getMailBox, dispatch),
    onAutoImportEmailClient: bindActionCreators(
      autoImportEmailClient,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
