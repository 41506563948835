import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import PubSub from "pubsub-js";
var firebaseConfig = {
  apiKey: "AIzaSyBYZz5b7-X9f4IYyy4w8Hrp5GhPSuWbtOo",
  authDomain: "ezymigrate.firebaseapp.com",
  projectId: "ezymigrate",
  storageBucket: "ezymigrate.appspot.com",
  messagingSenderId: "702966818628",
  appId: "1:702966818628:web:0371e8394919f1d6cd9911",
  measurementId: "G-EXJ1WXCGM2",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

if (messaging) {
  messaging
    .getToken({
      vapidKey:
        "BAXTgrtujITpPz-0cTFMTEhd8RClYe_dCWsEGvGg2aTOnBgErevEPSJ1ehIimWc5u8X7p4IB40i4NtNj8txh_o8",
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("notificationToken", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });

  messaging.onMessage((message) => {
    console.log(message);
    let data = {
      payload: JSON.parse(message.data.payload),
    };
    PubSub.publish("MESSAGE", data);
  });
}
export default firebase;
