import * as types from "../Constants";
import {message} from "antd";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const userDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case types.USER_DETAIL_SUCCESS:
      return { ...state, userDetail: action.payload };
    case types.USER_DETAIL_FAILURE:
      return {
        ...state,
        userDetailError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.ADD_USER_DETAIL_SUCCESS:
      return { ...state, addDetailSuccess: action.payload };
    case types.ADD_USER_DETAIL_FAILURE:
      console.log("on error console", action.payload);

    case types.UPDATE_USER_DETAIL_SUCCESS:
      if( action && action.payload === "This email is already taken")
      {
       message.error(action.payload)
      }
      else
      {
        return { ...state, updDetailSuccess: action.payload };
      }

    case types.UPDATE_USER_DETAIL_FAILURE:
      return {
        ...state,
        updUserDetailError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.ADD_USER_ADDRESS_SUCCESS:
      return { ...state, userAddressSuccess: action.payload };
    case types.ADD_USER_ADDRESS_FAILURE:
      return {
        ...state,
        addUserAddressError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.UPD_USER_ADDRESS_SUCCESS:
      return { ...state, updUserAddressSuccess: action.payload };
    case types.UPD_USER_ADDRESS_FAILURE:
      return {
        ...state,
        updUserAddressError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.ADD_USER_CONTACT_SUCCESS:
      return { ...state, userContactSuccess: action.payload };
    case types.ADD_USER_CONTACT_FAILURE:
      return {
        ...state,
        addUserContactEror: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordSuccess: action.payload };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.CHANGE_USERS_PASSWORD_SUCCESS:
      return { ...state, changeUsersPasswordSuccess: action.payload };
    case types.CHANGE_USERS_PASSWORD_FAILURE:
      return {
        ...state,
        changeUserPasswordError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };

    case types.DISABLE_USER_SUCCESS:
      return { ...state, disableUserSuccess: action.payload };
    case types.DISABLE_USER_FAILURE:
      console.log("on error console", action.payload);

    case types.UPLOAD_USER_AVATAR_SUCCESS:
      return { ...state, imageUploadSuccess: action.payload };
    case types.UPLOAD_USER_AVATAR_FAILURE:
      console.log("on error console", action.payload);
      return { ...state, imageUploadSuccess: "" };

    case types.GET_STORAGE_SUCCESS:
      return { ...state, storageData: action.payload };
    case types.GET_STORAGE_FAILURE:
      console.log("on error console", action.payload);

    case types.EXPORT_DATA_SUCCESS:
      return { ...state, exportSuccess: action.payload };
    case types.EXPORT_DATA_FAILURE:
      console.log("on error console", action.payload);
    default:
      return state;
  }
};
