import React, { Fragment, useState, useEffect } from "react";
import { Select, message, Button, Checkbox, Radio } from "antd";
import moment from "moment";

import { Images } from "../../Themes";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const LinkVisaCard = ({
  member,
  linked,
  onAddFamilyLink,
  onGetVisaApplication,
  visaApp,
  onRemoveFamilyLink,
}) => {
  const [linkMember, setLinkMember] = useState(linked);
  var visaTypeOptions = [];
  var categoryOptions = [];

  const addFamilyLink = (subjectCaseId, memberId) => {
    let userId = localStorage.getItem("userId");
    let data = {
      subjectCaseId: subjectCaseId,
      clientId: memberId,
    };
    onAddFamilyLink(data).then((res) => {
      message.success("Member linked successfully");
      onGetVisaApplication();
    });
  };

  const removeFamilyLink = (memberId) => {
    let userId = localStorage.getItem("userId");
    let data = {
      id: memberId,
      delete: true,
    };
    onRemoveFamilyLink(data);
  };

  const onChangeFamilyCheck = (e, subjectCaseId, memberId) => {
    setLinkMember(e.target.checked);
    if (e.target.checked) {
      addFamilyLink(subjectCaseId, memberId);
    } else {
      message.error("This member is already linked");
      //   removeFamilyLink(memberId);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div class="cm-profile-fill-cont" style={{ marginLeft: 20 }}>
          <img src={Images.personFill} class="cm-profile-fill" />
        </div>
        <div className="cv-lf-checkbox-label-cont">
          <span className="sus-checkbox-label">{member.firstName}</span>
        </div>
        <div>
          <Radio.Group>
            <Radio value={1}>A</Radio>
            <Radio value={2}>B</Radio>
            <Radio value={3}>C</Radio>
            <Radio value={4}>D</Radio>
          </Radio.Group>
        </div>
      </div>
    </Fragment>
  );
};

export default LinkVisaCard;
