import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Table,
  Spin,
  Modal,
  Tabs,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getAllInvoiceTypes,
  getAllBanksListing,
  getAllCurrencies,
  addNewInvoice,
  getLastInvoiceNumber,
  addInvoicePayment,
  getInvoicePayments,
  deleteInvoicePayment,
  editInvoice,
  setEmailTemplate,
  getBranch,
  getInvoiceStatuses,
  getCompany,
  checkDuplicateClientInvoice,
  postInvoiceToXero,
  postInvoicePaymentToXero,
  getInvoiceInfo,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import FroalaEditor from "react-froala-wysiwyg";
import activityData from "../Components/ClientActivity/ActivityData";
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class AddPaymentsToXero extends React.Component {
  formRef = React.createRef();
  formRefTemp = React.createRef();
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    this.state = {
      dateString: "",
      currencyName: "",
      loading: false,
      isTaxFieldVisible: true,
      clientProfileId: clientprofileid,
      branchId: selectedBranchId,
      dateStr: "",
      dueDate: "",
      accountCode: "",
      invoiceNo: Math.random()
        .toString(36)
        .substr(2, 5),
      invoiceTypeId: 1,
      taxInclusive: false,
      notes: "",
      lastInvoiceNumber: "",
      clientTagId: 0,
      _amount: 100,
      qTotal: 0,
      total: 0,
      subTotal: 0,
      discountAmount: 0,
      discount: 0,
      addedAmount: 0,
      gst: 0,
      dueAmount: 0,
      invoiceItems: [],
      invoiceItemsDefined: [],
      invoiceId: 0,
      invoicePayments: [],
      visibleDeleteModal: false,
      paymentDescription: "",
      visibleSendingReceiptModal: false,
      sendingReceiptEmail: "",
      subjectTypeId: 2,
      invoicePaymentsAmountTotal: 0,
      branchName: "",
      setEmailData: "",
      invoiceTypesListing: [],
      invoiceStatuses: [],
      statusId: 0,
      userEmail: userEmail,
      deletedPayment: 0,
      isDisabled: false,
      isDuplicateInvoiceExists: false,
      paymentId: 0,
      code: "",
      codePayment: "",
      paymentAmount: 0,
      paymentDate: "",
    };
  }

  componentDidMount() {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.getCompany();
    this.props.getClientTag();
    this.props.getAllInvoiceTypes();
    this.props.getAllCurrencies();
    this.props.getBranch();
    this.props.getInvoiceStatuses(this.state.invoiceTypeId);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let _invoiceInfo = nextProps.invoiceInfo;
    let _invoicePayments = nextProps && nextProps.invoicePayments;
    this.setState(
      {
        dueAmount: _invoiceInfo && _invoiceInfo.dueAmount,
        invoiceId: _invoiceInfo && _invoiceInfo.id,
      },
      () => {}
    );
    if (_invoicePayments && _invoicePayments) {
      this.setState({ invoicePayments: _invoicePayments }, () => {});
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  handleDateChange = (date, dateString) => {
    this.setState({ dateStr: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  handleDueDateChange = (date, dateString) => {
    this.setState({ dueDate: moment(date).format("DD/MM/YYYY") }, () => {
      console.log("state", this.state);
    });
  };

  getDatePickers = () => {
    return (
      <Row>
        <Col xs={11} offset={1}>
          <p style={{ fontSize: "11px" }}>{"DATE"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dateStr === "" || this.state.dateStr === "Invalid date"
                ? undefined
                : moment(this.state.dateStr, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDateChange}
          />
        </Col>
        <Col xs={11} style={{ marginLeft: "8px" }}>
          <p style={{ fontSize: "11px" }}>{"DUE DATE"}</p>
          <DatePicker
            disabled={true}
            value={
              this.state.dueDate === "" || this.state.dueDate === "Invalid date"
                ? undefined
                : moment(this.state.dueDate, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDueDateChange}
          />
        </Col>
      </Row>
    );
  };

  handleChangeInvoiceNo = (e) => {
    if (e.target.value.length < 6) {
      this.setState({ invoiceNo: e.target.value });
    } else {
      message.error("Max character limit for invoice no reached!");
    }
  };

  handleChangeInvoiceType = (value) => {
    this.setState({ invoiceTypeId: value }, () => {
      this.props.getInvoiceStatuses(this.state.invoiceTypeId);
    });
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  onBlur = () => {
    let _data = {
      invoiceNo: this.state.invoiceNo,
    };

    if (_data.invoiceNo !== "") {
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      this.props.checkDuplicateClientInvoice(_data).then(() => {
        let _duplicate = this.props && this.props.duplicate;
        if (_duplicate === true) {
          if (_invoiceInfo && _invoiceInfo.id) {
            if (this.state.invoiceNo !== _invoiceInfo.invoiceNo) {
              this.setState({ isDuplicateInvoiceExists: true }, () => {
                message.error("Invoice no must be unique!");
              });
            }
          } else {
            this.setState({ isDuplicateInvoiceExists: true }, () => {
              message.error("Invoice no must be unique!");
            });
          }
        } else {
          this.setState({ isDuplicateInvoiceExists: false }, () => {});
        }
      });
    }
  };

  getFilters = () => {
    return (
      <Row>
        <Col xs={6}></Col>
        <Col xs={6}>
          <p style={{ fontSize: "11px", marginLeft: "8px" }}>{"INVOICE #"}</p>
          <Input
            disabled={true}
            value={this.state.invoiceNo}
            onChange={this.handleChangeInvoiceNo}
            onBlur={this.onBlur}
            style={{ marginLeft: "8px" }}
          />
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "11px", marginLeft: "16px" }}>{"TYPE"}</p>
          <Select
            disabled={true}
            style={{ marginLeft: "16px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeInvoiceType}
            defaultValue={
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing[0] &&
              this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypesListing[0].id
                : undefined
            }
            value={
              this.state.invoiceTypeId === 0
                ? undefined
                : this.state.invoiceTypesListing &&
                  this.state.invoiceTypesListing[0] &&
                  this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypeId
                : undefined
            }
          >
            {this.state &&
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.invoiceTypeName}</Option>;
              })}
          </Select>
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "10px", marginLeft: "8px" }}>{"CLIENT TAGS"}</p>
          <Select
            disabled={true}
            style={{ marginLeft: "8px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeClientTags}
            value={
              this.state.clientTagId === 0 ? undefined : this.state.clientTagId
            }
          >
            <Option value={0}>{"Select"}</Option>
            {this.props &&
              this.props.clientTagRes &&
              this.props.clientTagRes.items &&
              this.props.clientTagRes.items.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </Col>
      </Row>
    );
  };

  handleChangeAmount = (value) => {};

  onFinishAddNewLines = (values) => {
    let _invoiceInfoId =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo.id;

    let invoiceItemsTemp = [];
    let _invoiceItems =
      values && values.invoiceItems === undefined ? [] : values.invoiceItems;
    if (_invoiceItems && _invoiceItems.length > 0) {
      for (
        let invoiceItemIndex = 0;
        invoiceItemIndex < _invoiceItems.length;
        invoiceItemIndex++
      ) {
        invoiceItemsTemp.push({
          id: 0,
          invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
          description:
            _invoiceItems[invoiceItemIndex].description === undefined
              ? ""
              : _invoiceItems[invoiceItemIndex].description,
          amount:
            _invoiceItems[invoiceItemIndex].amount === undefined
              ? 0
              : _invoiceItems[invoiceItemIndex].amount,
          xeroLineItemID: "00000000-0000-0000-0000-000000000000",
          taxInclusive: true,
        });
      }
    }
    if (this.state && this.state.invoiceItemsDefined.length > 0) {
      for (
        let invoiceItemDefinedIndex = 0;
        invoiceItemDefinedIndex < this.state.invoiceItemsDefined.length;
        invoiceItemDefinedIndex++
      ) {
        invoiceItemsTemp.push({
          id: _invoiceInfoId
            ? this.state.invoiceItemsDefined[invoiceItemDefinedIndex].id
            : 0,
          invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
          description: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
            .description,
          amount: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
            .amount,
          xeroLineItemID: "00000000-0000-0000-0000-000000000000",
          taxInclusive: true,
        });
      }
    }

    this.setState({ invoiceItems: invoiceItemsTemp });
    let amountTotal = 0;
    let amountTotalDefinedInvoiceItems = 0;
    if (values && values.invoiceItems && values.invoiceItems.length > 0) {
      for (let index = 0; index < values.invoiceItems.length; index++) {
        if (values.invoiceItems[index].amount !== undefined) {
          amountTotal = amountTotal + values.invoiceItems[index].amount;
        }
      }
    }

    let g = this.state.invoiceItemsDefined;

    if (
      this.state.invoiceItemsDefined &&
      this.state.invoiceItemsDefined.length > 0
    ) {
      for (
        let indexDefinedItems = 0;
        indexDefinedItems < this.state.invoiceItemsDefined.length;
        indexDefinedItems++
      ) {
        amountTotalDefinedInvoiceItems =
          amountTotalDefinedInvoiceItems +
          this.state.invoiceItemsDefined[indexDefinedItems].amount;
      }
    }

    amountTotal = amountTotal + amountTotalDefinedInvoiceItems;

    this.setState(
      {
        subTotal: amountTotal,
      },
      () => {
        let _discountedAmountDiscountPercentage;
        let _discountedAmountDiscountFinal;
        let _discountedAmountTaxPercentage;
        let _tempTotalAmount;
        if (this.state.discount !== 0) {
          _discountedAmountDiscountPercentage =
            (this.state.discount * this.state.subTotal) / 100;
          _discountedAmountDiscountFinal =
            this.state.subTotal - _discountedAmountDiscountPercentage;
        } else {
          _discountedAmountDiscountFinal =
            this.state.subTotal - this.state.discountAmount;
        }
        _discountedAmountTaxPercentage =
          (_discountedAmountDiscountFinal * this.state.gst) / 100;

        _tempTotalAmount =
          _discountedAmountTaxPercentage + _discountedAmountDiscountFinal;

        this.setState(
          {
            total: Math.round((_tempTotalAmount + Number.EPSILON) * 100) / 100,
            dueAmount: _tempTotalAmount - this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              subTotal: amountTotal,
              total: this.state.total,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    );
  };

  handleChangeInvoiceItemsDefinedDescription = (e, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].description = e.target.value;
        }
      }
    }
    this.setState({ invoiceItemsDefined: __invoiceItemsDefined }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeInvoiceItemsDefinedAmount = (value, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].amount = value;
        }
      }
    }
    this.setState({ invoiceItemsDefined: __invoiceItemsDefined }, () => {
      console.log("state", this.state);
    });
  };

  getAddNewLine = () => {
    return (
      <div>
        <Form onFinish={this.onFinishAddNewLines}>
          <Form.List name="invoiceItems">
            {(fields, { add, remove }) => (
              <>
                <Row>
                  <Col xs={23} className="add-new-line-container">
                    <Row style={{ padding: "4px" }}>
                      <Col xs={24} className="add-new-line-header">
                        <Row>
                          <Col xs={2}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"SR#"}
                            </h5>
                          </Col>
                          <Col xs={16}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"DESCRIPTION"}
                            </h5>
                          </Col>
                          <Col xs={3}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"AMOUNT"}
                            </h5>
                          </Col>
                          <Col xs={3}>
                            <h5
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              {"ACTION"}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {this.state &&
                      this.state.invoiceItemsDefined &&
                      this.state.invoiceItemsDefined.map((data, index) => {
                        return (
                          <Row>
                            <Col xs={2}>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {index + 1}
                              </p>
                            </Col>
                            <Col xs={16}>
                              <div className="add-new-line-inputs">
                                <Input
                                  disabled={true}
                                  defaultValue={data.description}
                                  onChange={(e) =>
                                    this.handleChangeInvoiceItemsDefinedDescription(
                                      e,
                                      data
                                    )
                                  }
                                />
                              </div>
                            </Col>
                            <Col xs={3} className="add-new-line-inputs">
                              <InputNumber
                                disabled={true}
                                defaultValue={data.amount}
                                onChange={(value) =>
                                  this.handleChangeInvoiceItemsDefinedAmount(
                                    value,
                                    data
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col xs={2}>
                          <p style={{ textAlign: "center", fontSize: "16px" }}>
                            {this.state &&
                              this.state.invoiceItemsDefined &&
                              this.state.invoiceItemsDefined.length + 1 + index}
                          </p>
                        </Col>
                        <Col xs={16}>
                          <div className="add-new-line-inputs">
                            <Form.Item
                              {...field}
                              name={[field.name, "description"]}
                              fieldKey={[field.fieldKey, "description"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter description!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div
                            className="add-new-line-inputs"
                            onClick={() => {
                              this.setState({ fieldKey: field }, () => {
                                console.log("field key", this.state.fieldKey);
                              });
                            }}
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "amount"]}
                              fieldKey={[field.fieldKey, "amount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter amount!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (value < 0) {
                                      return Promise.reject(
                                        "Type only positive numbers"
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={3}>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          >
                            <DeleteOutlined />
                          </div>
                        </Col>
                      </Row>
                    ))}
                    {/*<Row>*/}
                    {/*    <Col*/}
                    {/*        xs={6}*/}
                    {/*        offset={18}*/}
                    {/*        style={{ paddingLeft: "10px", marginTop: "6px" }}*/}
                    {/*    >*/}
                    {/*        <Button*/}
                    {/*            size="small"*/}
                    {/*            type="primary"*/}
                    {/*            htmlType="submit"*/}
                    {/*            // style={{*/}
                    {/*            //   display: fields.length > 0 ? "flex" : "none"*/}
                    {/*            // }}*/}
                    {/*        >*/}
                    {/*            Calculate Sub Total*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </Col>
                </Row>
                {/*<Row>*/}
                {/*    <Col xs={24}>*/}
                {/*        <Form.Item>*/}
                {/*            <div*/}
                {/*                className="add-new-line-button"*/}
                {/*                onClick={() => add()}*/}
                {/*            >*/}
                {/*                <p>{"ADD NEW LINE"}</p>*/}
                {/*            </div>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    );
  };

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState({ taxInclusive: e.target.checked }, () => {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
      this.setState({ gst: 0 });
      if (this.state.discount === 0) {
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - this.state.discountAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
      if (this.state.discountAmount === 0) {
        let _discountedAmount =
          (this.state.discount * this.state.subTotal) / 100;
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - _discountedAmount;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total:
              Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
              100,
            dueAmount:
              finalTotalAfterTaxInclusive -
              this.state.invoicePaymentsAmountTotal,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total:
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
              dueAmount: this.state.dueAmount,
            });
          }
        );
      }
    });
  };

  handleChangeDiscountPercentage = (value) => {
    let _discountedAmount;
    let __discountedAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discount: 0,
      });
      let _tempAmount;
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
        },
        () => {
          let b = _tempAmount - this.state.invoicePaymentsAmountTotal;

          this.setState({
            dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
          });
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    } else {
      let _tempAmount;
      _discountedAmount = (value * this.state.subTotal) / 100;
      __discountedAmount = this.state.subTotal - _discountedAmount;
      let discountedAmountPercentage =
        (__discountedAmount * this.state.gst) / 100;
      _tempAmount = __discountedAmount + discountedAmountPercentage;

      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: Math.round((_tempAmount + Number.EPSILON) * 100) / 100,
          dueAmount: _tempAmount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          this.formRef.current.setFieldsValue({
            total:
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
            dueAmount: this.state.dueAmount,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discountAmount: 0,
    });
    this.setState({ discountAmount: 0, qTotal: 0 });
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
    }
    let addedAmount;

    this.setState({ gst: value }, () => {});
    let totalAmount;
    if (this.state.discount === 0 && this.state.discountAmount === 0) {
      addedAmount = (value * this.state.subTotal) / 100;
      totalAmount = addedAmount + this.state.subTotal;
    } else {
      let cal;
      if (this.state.discount === 0) {
        cal = this.state.subTotal - this.state.discountAmount;
      } else {
        cal =
          this.state.subTotal -
          (this.state.discount * this.state.subTotal) / 100;
      }
      addedAmount = (value * cal) / 100;
      totalAmount = addedAmount + cal;
    }

    this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
      this.setState(
        {
          dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
        },
        () => {
          console.log(
            "state",
            this.state._amount - this.state.invoicePaymentsAmountTotal
          );
        }
      );
      // this.setState({ total: this.state._amount})
      this.formRef.current.setFieldsValue({
        total: Math.round((this.state._amount + Number.EPSILON) * 100) / 100,
        dueAmount: this.state._amount - this.state.invoicePaymentsAmountTotal,
      });
    });
  };

  getForm = () => {
    return (
      <div>
        <Row style={{ marginTop: "17px", marginLeft: "17px" }}>
          <Col xs={7}>
            <TextArea
              disabled={true}
              value={this.state.notes}
              rows={14}
              onChange={this.handleChangeNotes}
            />
          </Col>
          <Col xs={8} offset={1}>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="subTotal"
                  colon={false}
                  labelAlign="left"
                  label="SUB TOTAL"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  {...this.layout}
                  name="currencyId"
                  colon={false}
                  labelAlign="left"
                  label="CURRENCY"
                >
                  <Select
                    disabled={true}
                    placeholder="PLEASE SELECT CURRENCY"
                    size="small"
                  >
                    {this.props &&
                      this.props.currencyListing &&
                      this.props.currencyListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={data.id}>{data.currencyName}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.isTaxFieldVisible ? (
              <div>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Checkbox
                      disabled={true}
                      onChange={this.handleChangeTaxInclusiveCheck}
                      checked={this.state.taxInclusive}
                    >
                      {<span className={"filter-text"}>Tax inclusive</span>}
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item
                      name="gst"
                      colon={false}
                      labelAlign="left"
                      label="TAX %"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        disabled={true}
                        size="small"
                        // disabled={!!this.state.taxInclusive}
                        onChange={this.handleChangeTaxPercentage}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discount"
                  colon={false}
                  labelAlign="left"
                  label="DISCOUNT %"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    disabled={true}
                    size="small"
                    onChange={this.handleChangeDiscountPercentage}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="total"
                  colon={false}
                  labelAlign="left"
                  label="TOTAL"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="dueAmount"
                  colon={false}
                  labelAlign="left"
                  label="DUE"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="accountCode"
                  colon={false}
                  labelAlign="left"
                  label="Account Code"
                >
                  <InputNumber size="small" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  onClickPostToXero = (record) => {
    this.setState({ paymentId: record.id }, () => {
      let _data = {
        invoiceId: this.state.invoiceId,
        invoicePaymentId: this.state.paymentId,
        code: this.state.code,
      };
      if (this.state.code === "") {
        message.error("Please enter account code!");
      } else {
        this.setState({ loading: true });
        this.props
          .postInvoicePaymentToXero(_data)
          .then(() => {
            this.setState({ loading: false, code: "" });
            message.success("Payment is posted to Xero successfully!");
            this.props.history.push("/xero-sync-invoices");
          })
          .catch(() => {
            this.setState({ loading: false, code: "" });
            message.error("An error occurred due to Xero restrictions!");
          });
      }
    });
  };

  getButtons = (invoiceInformation) => {
    return (
      <div>
        <Row style={{ marginLeft: "17px", marginTop: "44px" }}>
          <Col xs={24} style={{ display: "flex" }}>
            <Button
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={this.onClickPostToXero}
              disabled={this.state.isDisabled}
            >
              {"POST TO XERO"}
            </Button>
            <Button
              type="primary"
              style={{ marginRight: "14px" }}
              onClick={this.backToInvoices}
            >
              BACK TO INVOICES
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  backToInvoices = () => {
    this.props.history.push("/xero-sync-invoices");
  };

  getPaymentHistoryTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="ca-gray-cont ca-gray-padding-payment-history">
              <Table
                dataSource={
                  this.state.invoicePayments
                    ? this.state.invoicePayments.sort((a, b) =>
                        a.receivedDate.localeCompare(b.receivedDate)
                      )
                    : null
                }
                columns={this.paymentColumns}
                pagination={false}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  onClickAddPayment = (values) => {
    this.setState({ showSendingReceiptModalForm: false });
  };

  handleChangePaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  handlePaymentDateChange = (date, dateString) => {
    this.setState({ paymentDate: moment(date).format() }, () => {
      console.log("state", this.state);
    });
  };

  onClickAddPaymentSendReceipt = (values) => {
    this.setState({ showSendingReceiptModalForm: true });
  };

  onFinishAddPaymentForm = (values) => {
    this.setState({ loading: true });
    this.props
      .addInvoicePayment(this.state)
      .then((res) => {
        message.success("Payment is added successfully!");

        let _data = {
          invoiceId: this.state.invoiceId,
          invoicePaymentId: res.payload,
          code: this.state.codePayment,
        };
        this.props
          .getInvoiceInfo(this.state.invoiceId)
          .then(() => {
            this.props
              .postInvoicePaymentToXero(_data)
              .then(() => {
                message.success("Payment is posted successfully to Xero");
                this.props
                  .getInvoicePayments(this.state.invoiceId)
                  .then(() => {
                    this.setState(
                      {
                        paymentAmount: 0,
                        paymentDate: "",
                        codePayment: "",
                      },
                      () => {
                        this.setState({ loading: false });
                      }
                    );
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                  });
              })
              .catch(() => {
                message.error("An error occurred due to Xero restrictions!");
                this.setState({ loading: false });
              });
          })
          .catch(() => {
            this.setState({ loading: false });
          });

        // this.setState(
        //   {
        //       paymentAmount: 0,
        //       paymentDate: "",
        //       codePayment: ""
        //   }, () => {
        //
        //       this.setState({loading: false})
        //   })
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  getMakePaymentForm = () => {
    return (
      <Row>
        <Col xs={24}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <Form onFinish={this.onFinishAddPaymentForm} ref={this.formRefTemp}>
              <div className="ca-gray-cont ca-gray-padding">
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Amount"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={this.handleChangePaymentAmount}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Date"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={this.handlePaymentDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Xero Account Code"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="xeroAccountCodePayment"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        size="medium"
                        onChange={this.onChangeXeroAccountCodePayment}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div className="payment-buttons-container">
                      <Button
                        style={{ marginRight: "13px" }}
                        type="primary"
                        htmlType="submit"
                        onClick={this.onClickAddPayment}
                        className="button-blue"
                      >
                        {"ADD PAYMENT (XERO)"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    );
  };

  onChangeXeroAccountCode = (e) => {
    if (e.target.value === null) {
      this.setState({ code: "" });
    } else {
      this.setState({ code: e.target.value });
    }
  };

  onChangeXeroAccountCodePayment = (e) => {
    if (e.target.value === null) {
      this.setState({ codePayment: "" });
    } else {
      this.setState({ codePayment: e.target.value });
    }
  };

  paymentColumns = [
    {
      title: "Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.receivedDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.amount}
        </p>
      ),
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      width: "150px",
      title: "Xero Account Code",
      render: (text, record) => (
        <Input
          type="number"
          size="medium"
          disabled={record.xeroID !== "00000000-0000-0000-0000-000000000000"}
          onChange={this.onChangeXeroAccountCode}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            className="button-blue"
            type="primary"
            htmlType="submit"
            onClick={() =>
              record.xeroID === "00000000-0000-0000-0000-000000000000"
                ? this.onClickPostToXero(record)
                : null
            }
          >
            {record.xeroID === "00000000-0000-0000-0000-000000000000"
              ? "Post to Xero"
              : "Already posted to Xero"}
          </Button>
        </span>
      ),
    },
  ];

  onChangeAccountCode = (value) => {
    this.setState({ accountCode: value === null ? "" : value.toString() });
  };

  render() {
    let _invoiceInformation =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo;
    return (
      <Fragment style={{ height: "100vh" }}>
        <div className="reminder-tabs-header employer-Header">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Xero Invoices" key="1"></TabPane>
          </Tabs>
        </div>
        <div className="ts-container-add-client">
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "6px" }}>
                {`Invoice No: ${_invoiceInformation &&
                  _invoiceInformation.invoiceNo}`}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "6px" }}>
                {`To: ${_invoiceInformation && _invoiceInformation.to}`}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "6px" }}>
                {`Due: ${this.state.dueAmount}`}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "6px" }}>
                {`Due Date: ${moment(
                  _invoiceInformation && _invoiceInformation.dueDate
                ).format("DD/MM/YYYY")}`}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "6px" }}>
                {`Total: ${_invoiceInformation && _invoiceInformation.total}`}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ marginTop: "32px" }}>{"Payment History"}</h4>
            </Col>
          </Row>
          {this.getPaymentHistoryTable()}
          {this.state.dueAmount > 0 ? (
            <Row>
              <Col xs={23} offset={1}>
                <h4 style={{ marginTop: "32px" }}>{"Make a Payment"}</h4>
              </Col>
            </Row>
          ) : null}
          {this.state.dueAmount > 0 ? this.getMakePaymentForm() : null}
          <Row>
            <Col xs={23} offset={1}>
              <Button
                className="button-blue"
                type="primary"
                style={{ marginRight: "14px" }}
                onClick={this.backToInvoices}
              >
                Back to Invoices
              </Button>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let b =
    state &&
    state.invoicesReducer &&
    state.invoicesReducer.duplicate &&
    state.invoicesReducer.duplicate.duplicate;

  return {
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    invoiceTypesListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceTypesListing &&
      state.invoicesReducer.invoiceTypesListing,
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items &&
      state.invoicesReducer.currencyListing.items,
    lastInvoiceNumber:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.lastInvoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    invoiceInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceInfo &&
      state.invoicesReducer.invoiceInfo,
    duplicate:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.duplicate &&
      state.invoicesReducer.duplicate.duplicate &&
      state.invoicesReducer.duplicate.duplicate,
    _invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments,
    invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments.items &&
      state.invoicesReducer.invoicePayments.items,
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    companyData:
      state &&
      state.companyReducer &&
      state.companyReducer.companyData &&
      state.companyReducer.companyData,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getAllInvoiceTypes: bindActionCreators(getAllInvoiceTypes, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  addNewInvoice: bindActionCreators(addNewInvoice, dispatch),
  getLastInvoiceNumber: bindActionCreators(getLastInvoiceNumber, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  getInvoicePayments: bindActionCreators(getInvoicePayments, dispatch),
  deleteInvoicePayment: bindActionCreators(deleteInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  getBranch: bindActionCreators(getBranch, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  checkDuplicateClientInvoice: bindActionCreators(
    checkDuplicateClientInvoice,
    dispatch
  ),
  postInvoiceToXero: bindActionCreators(postInvoiceToXero, dispatch),
  postInvoicePaymentToXero: bindActionCreators(
    postInvoicePaymentToXero,
    dispatch
  ),
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
});
AddPaymentsToXero = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentsToXero);

export default withRouter(AddPaymentsToXero);
