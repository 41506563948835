import { api } from "../../services/api";
import * as types from "../Constants";
import {
  ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS,
  DELETE_TEMPLATE_FOR_CLIENT_SUCCESS,
  EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS,
  GET_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS,
  GET_CLIENT_TEMPLATE_INFO_SUCCESS,
  GET_CLIENT_TEMPLATE_LISTING_SUCCESS,
} from "../Constants";

export const getClientProfile = (id) => {
  const options = {
    url: `v1/client/AllData/${id}`,
  };

  options.types = [
    types.GET_CLIENT_PROFILE_SUCCESS,
    types.GET_CLIENT_PROFILE_FAILURE,
  ];

  return api.get(options);
};

export const getAllClients = () => {
  const options = {
    url: "v1/client/GetAllClientsByBranchId",
  };

  options.types = [types.GET_ALL_CLIENT_SUCCESS, types.GET_ALL_CLIENT_FAILURE];

  return api.get(options);
};

export const getAllPoliceCertificate = () => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");

  const options = {
    url: `v1/client/policecertificate/All/${clientprofileid}`,
  };

  options.types = [
    types.GET_ALL_POLICE_CERTIFICATE_SUCCESS,
    types.GET_ALL_POLICE_CERTIFICATE_FAILURE,
  ];

  return api.get(options);
};

export const addPoliceCertificate = (data) => {
  const options = {
    url: "v1/client/policecertificate",
  };

  options.types = [
    types.ADD_POLICE_CERTIFICATE_SUCCESS,
    types.ADD_POLICE_CERTIFICATE_FAILURE,
  ];

  return api.post(options, data);
};

export const removePoliceCertificate = (data) => {
  const options = {
    url: "v1/client/policecertificate",
  };

  options.types = [
    types.REMOVE_POLICE_CERTIFICATE_SUCCESS,
    types.REMOVE_POLICE_CERTIFICATE_FAILURE,
  ];

  return api.delete(options, data);
};

export const setClientProfileLoader = (bol) => {
  const data = {
    type: types.SET_CLIENT_PROFILE_LOADER_SUCCESS,
    payload: bol,
  };
  return data;
};

export const addAccessingAuthorities = (data) => {
  const options = {
    url: "v1/client/SaveAssessingAuthority",
  };

  options.types = [
    types.ADD_ACCESSING_AUTHORITIES_SUCCESS,
    types.ADD_ACCESSING_AUTHORITIES_FAILURE,
  ];

  return api.post(options, data);
};

export const removeAccAuthority = (data) => {
  const options = {
    url: "v1/client/AssessingAuthority",
  };

  options.types = [
    types.REMOVE_ACCESSING_AUTHORITIES_SUCCESS,
    types.REMOVE_ACCESSING_AUTHORITIES_FAILURE,
  ];

  return api.delete(options, data);
};

export const getProcessingGroups = (data) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");

  const options = {
    url: `v1/client/processinggroup/All/${clientprofileid}`,
  };

  options.types = [
    types.GET_PROCESSING_GROUPS_SUCCESS,
    types.GET_PROCESSING_GROUPS_FAILURE,
  ];

  return api.get(options);
};

export const getGroupMembers = (data) => {
  const options = {
    url: "v1/group/member/" + data,
  };

  options.types = [
    types.GET_GROUP_MEMBER_SUCCESS,
    types.GET_GROUP_MEMBER_FAILURE,
  ];

  return api.get(options);
};

export const addProcessingGroup = (data) => {
  const options = {
    url: "v1/client/processinggroup",
  };

  options.types = [
    types.ADD_PROCESSING_GROUP_SUCCESS,
    types.ADD_PROCESSING_GROUP_FAILURE,
  ];

  return api.post(options, data);
};

export const removeProcessingGroup = (data) => {
  const options = {
    url: "v1/client/processinggroup",
  };

  options.types = [
    types.REMOVE_PROCESSING_GROUP_SUCCESS,
    types.REMOVE_PROCESSING_GROUP_FAILURE,
  ];

  return api.delete(options, data);
};

export const addProcessingPerson = (data) => {
  const options = {
    url: "v1/client/processingperson",
  };

  options.types = [
    types.ADD_PROCESSING_PERSON_SUCCESS,
    types.ADD_PROCESSING_PERSON_FAILURE,
  ];

  return api.post(options, data);
};

export const removeProcessingPerson = (data) => {
  const options = {
    url: "v1/client/processingperson",
  };

  options.types = [
    types.REMOVE_PROCESSING_PERSON_SUCCESS,
    types.REMOVE_PROCESSING_PERSON_FAILURE,
  ];

  return api.delete(options, data);
};

export const searchConnection = (data) => {
  const options = {
    url: "v1/client/SearchClient",
  };

  options.types = [
    types.SEARCH_CONNECTION_SUCCESS,
    types.SEARCH_CONNECTION_FAILURE,
  ];

  return api.post(options, data);
};

export const removeClient = (data) => {
  const options = {
    url: "v1/client",
  };

  options.types = [types.REMOVE_CLIENT_SUCCESS, types.REMOVE_CLIENT_FAILURE];

  return api.delete(options, data);
};

export const addConnection = (data) => {
  const options = {
    url: "v1/client/connection",
  };

  options.types = [types.ADD_CONNECTION_SUCCESS, types.ADD_CONNECTION_FAILURE];

  return api.post(options, data);
};

export const removeConnection = (data) => {
  const options = {
    url: "v1/client/connection",
  };

  options.types = [
    types.REMOVE_CONNECTION_SUCCESS,
    types.REMOVE_CONNECTION_FAILURE,
  ];

  return api.delete(options, data);
};

export const getAccessingAuth = () => {
  const options = {
    url: "v1/client/GetAssessingAuth",
  };

  options.types = [
    types.GET_ACCESSING_AUTH_SUCCESS,
    types.GET_ACCESSING_AUTH_FAILURE,
  ];

  return api.get(options);
};

export const updClientProfile = (data) => {
  const options = {
    url: "v1/client/UpdateClientSimple",
  };

  options.types = [
    types.UPD_CLIENT_PROFILE_SUCCESS,
    types.UPD_CLIENT_PROFILE_FAILURE,
  ];

  return api.put(options, data);
};

export const updClientAddress = (data) => {
  const options = {
    url: "v1/client/address/SaveAddress",
  };

  options.types = [
    types.UPD_CLIENT_ADDRESS_SUCCESS,
    types.UPD_CLIENT_ADDRESS_FAILURE,
  ];

  return api.post(options, data);
};

export const updClientEmail = (data) => {
  const options = {
    url: "v1/client/email/SaveEmail",
  };

  options.types = [
    types.UPD_CLIENT_EMAIL_SUCCESS,
    types.UPD_CLIENT_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const updClientMedicals = (data) => {
  const options = {
    url: "v1/client/medical/SaveMedical",
  };

  options.types = [
    types.UPD_CLIENT_MEDICAL_SUCCESS,
    types.UPD_CLIENT_MEDICAL_FAILURE,
  ];

  return api.post(options, data);
};

export const updClientPhones = (data) => {
  const options = {
    url: "v1/client/phone/SavePhone",
  };

  options.types = [
    types.UPD_CLIENT_PHONE_SUCCESS,
    types.UPD_CLIENT_PHONE_FAILURE,
  ];

  return api.post(options, data);
};

export const updClientPassport = (data) => {
  const options = {
    url: "v1/client/passport/SavePassport",
  };

  options.types = [
    types.UPD_CLIENT_PASSPORT_SUCCESS,
    types.UPD_CLIENT_PASSPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const getClientFamily = (data) => {
  let clientprofileid = "";
  let familyId = "";
  clientprofileid = sessionStorage.getItem("clientProfileIdMain");
  familyId = sessionStorage.getItem("familyId");
  if (data && data.clientId && data.familyId) {
    clientprofileid = data.clientId;
    familyId = data.familyId;
  }
  const options = {
    url: `v1/client/GetClientFamilyMembers/${clientprofileid}/${familyId}`,
  };

  options.types = [
    types.GET_CLIENT_FAMILY_SUCCESS,
    types.GET_CLIENT_FAMILY_FAILURE,
  ];

  return api.get(options);
};

export const addNewClient = (data) => {
  let _data = {
    clientNumber: data.clientNumber || "",
    contactPerson: data.contactPerson || "",
    familyId: "00000000-0000-0000-0000-000000000000",
    processingGroupId: 0,
    agentId: data.agentId || "00000000-0000-0000-0000-000000000000",
    clientTag: 0,
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    middleName: data.middleName || "",
    title: data.title || "",
    gender: data.gender || 0,
    dateOfBirth: data.dateOfBirth || "1900-01-01T00:00:00.000Z",
    maritalStatus: data.maritalStatus || "",
    dependentChildren: data.dependentChildren || 0,
    notes: data.notes || "",
    occupation: data.occupation || "",
    occupationOrganization: "",
    inzUserName: data.inzUserName || "",
    inzPassword: data.inzPassword || "",
    imageBlobUrl: data.imageBlobUrl || "",
    nationalityId: data.nationalityId || "",
    nationalityCountry: "",
    skypeID: "",
    preferredName: data.preferredName || "",
    isSubscribed: false,
    arbitaryJson: "",
    dependentClientIds: "",
    currentVisaTypeId: data.currentVisaTypeId || 0,
    currentNewZealandVisaExpiry:
      data.currentNewZealandVisaExpiry || "1900-01-01T00:00:00.000Z",
    travelConditionsValidTo:
      data.travelConditionsValidTo || "1900-01-01T00:00:00.000Z",
    visaText: "",
    visaDenied: false,
    deniedText: "",
    clientNumberIZM: "",
    inzFeeDate: "1900-01-01T00:00:00.000Z",
    memberType: "Client",
    clientId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    nzqaOnlineSubDate: data.nzqaOnlineSubDate || "1900-01-01T00:00:00.000Z",
    nzqaDocumentSubDate: data.nzqaDocumentSubDate || "1900-01-01T00:00:00.000Z",
    nzqaDocumentRetDate: data.nzqaDocumentRetDate || "1900-01-01T00:00:00.000Z",
    visaCountryId: data.visaCountryId || 168,
    visaCountyType: data.visaCountyType || 0,
    age: 0,
    jobSectorId: data.jobSectorId || 0,
    sourceId: data.sourceId || 0,
    sourceDescription: data.sourceDescription || "",
    clientSerial: data.clientSerial || "",
    companyOptional: data.companyOptional || "",
    dealWorth: data.dealWorth || "",
    saleDate: data.saleDate || "1900-01-01T00:00:00.000Z",
    clientPermission: {
      signed: false,
      onshore: true,
      active: true,
      allowUpdate: false,
      areaAccess: false,
    },
    clientMedical: data.clientMedical || {
      er: "",
      medicalIssueDate: "1900-01-01T00:00:00.000Z",
      medicalExpiryDate: "1900-01-01T00:00:00.000Z",
      xrayIssueDate: "1900-01-01T00:00:00.000Z",
      xrayExpiryDate: "1900-01-01T00:00:00.000Z",
      medicalGrading: "",
      xrayGrading: "",
      medicalNotes: "",
      medicalNotesDetail: "",
    },
    passports: data.passports || [
      {
        passportNo: "",
        passportCountry: 0,
        passportType: 1,
        passportIssueDate: "1900-01-01T00:00:00.000Z",
        passportExpiryDate: "1900-01-01T00:00:00.000Z",
      },
      {
        passportNo: "",
        passportCountry: 0,
        passportType: 2,
        passportIssueDate: "1900-01-01T00:00:00.000Z",
        passportExpiryDate: "1900-01-01T00:00:00.000Z",
      },
    ],
    clientEmails: data.clientEmails || [
      {
        address: "",
        emailTypeId: 1,
      },
      {
        address: "",
        emailTypeId: 2,
      },
      {
        address: "",
        emailTypeId: 3,
      },
    ],
    addresses: data.addresses || [
      {
        contactPerson: "",
        flat: "",
        building: "",
        streetName: "",
        suburb: "",
        streetNumber: "",
        city: "",
        state: "",
        zip: "",
        country: 0,
        addressTypeId: 1,
      },
      {
        contactPerson: "",
        flat: "",
        building: "",
        streetName: "",
        suburb: "",
        streetNumber: "",
        city: "",
        state: "",
        zip: "",
        country: 0,
        addressTypeId: 5,
      },
    ],
    clientPhones: data.clientPhones || [
      {
        countryCodeId: 168,
        phoneTypeId: 1,
        contact: "",
      },
      {
        countryCodeId: 168,
        phoneTypeId: 2,
        contact: "",
      },
      {
        countryCodeId: 168,
        phoneTypeId: 3,
        contact: "",
      },
      {
        countryCodeId: 168,
        phoneTypeId: 4,
        contact: "",
      },
      {
        countryCodeId: 168,
        phoneTypeId: 5,
        contact: "",
      },
    ],
    policeCertificates: data.policeCertificates || [],
  };

  const options = {
    url: "v1/client",
  };
  options.types = [
    types.CREATE_NEW_CLIENT_SUCCESS,
    types.CREATE_NEW_CLIENT_FAILURE,
  ];

  return api.post(options, _data);
};

export const createClientLink = (data) => {
  const options = {
    url: "v1/client/GetLink",
  };

  options.types = [
    types.CREATE_CLIENT_LINK_SUCCESS,
    types.CREATE_CLIENT_LINK_FAILURE,
  ];

  return api.post(options, data);
};

export const getClientLink = (data) => {
  const options = {
    url:
      "v1/client/GetLinkDecode?para=ZWJlYzRjMTM2MzAxNGFiN2I2MzE1OWE1ZDIwMGNjOTY=",
  };

  options.types = [
    types.GET_CLIENT_LINK_SUCCESS,
    types.GET_CLIENT_LINK_FAILURE,
  ];

  return api.get(options);
};

export const setCmvLinkTemplate = (data) => {
  const options = {
    url: "v1/HtmlTemplate/SetHtmlTemplate",
  };

  options.types = [
    types.SET_CMV_LINK_TEMPLATE_SUCCESS,
    types.SET_CMV_LINK_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const searchMainClient = (data) => {
  const options = {
    url: `v1/client/SearchClientMain/${data}`,
  };

  options.types = [
    types.GET_MAIN_CLIENT_SUCCESS,
    types.GET_MAIN_CLIENT_FAILURE,
  ];

  return api.get(options);
};

export const searchPotentialClient = (data) => {
  const options = {
    url: "v1/potentialclient/SearchPotentialClientMain/" + data,
  };
  options.types = [
    types.GET_POTENTIAL_CLIENT_SUCCESS,
    types.GET_POTENTIAL_CLIENT_FAILURE,
  ];

  return api.get(options);
};

export const searchEmployerMain = (data) => {
  const options = {
    url: "v1/employer/SearchEmployersMain/" + data,
  };
  options.types = [
    types.GET_EMPLOYER_SEARCH_SUCCESS,
    types.GET_EMPLOYER_SEARCH_FAILURE,
  ];

  return api.post(options);
};

export const updateSignedDate = (data) => {
  const options = {
    url: "v1/client/LastAgreementSigned",
  };

  options.types = [
    types.UPDATE_SIGNED_DATE_SUCCESS,
    types.UPDATE_SIGNED_DATE_FAILURE,
  ];

  return api.post(options, data);
};

export const checkUniqueEmail = (data) => {
  const options = {
    url: "v1/client/CheckClientEmail/" + data.email + "/" + data.emailTypeId,
  };

  options.types = [
    types.CHECK_UNIQUE_EMAIL_SUCCESS,
    types.CHECK_UNIQUE_EMAIL_FAILURE,
  ];

  return api.get(options);
};

export const getClientTemplateListing = (data) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  const options = {
    url: `v1/client/balance/All/${clientprofileid}`,
  };
  options.types = [
    types.GET_CLIENT_TEMPLATE_LISTING_SUCCESS,
    types.GET_CLIENT_TEMPLATE_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getClientTemplateInfo = (id) => {
  const options = {
    url: `v1/client/balance/${id}`,
  };

  options.types = [
    types.GET_CLIENT_TEMPLATE_INFO_SUCCESS,
    types.GET_CLIENT_TEMPLATE_INFO_FAILURE,
  ];

  return api.get(options);
};

export const addTemplateToClientAppliedTemplateListing = (data) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  let _data = {
    name: data.name,
    description: data.description,
    clientId: clientprofileid,
    total: data.total,
    stages: data.stages,
  };
  const options = {
    url: `v1/client/balance`,
  };

  options.types = [
    types.ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS,
    types.ADD_TEMPLATE_TO_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const editTemplateForClientAppliedTemplateListing = (data) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  let _data = {
    id: data.id,
    name: data.name,
    description: data.description,
    clientId: clientprofileid,
    total: data.total,
    stages: data.stages,
  };
  const options = {
    url: `v1/client/balance`,
  };
  options.types = [
    types.EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_SUCCESS,
    types.EDIT_TEMPLATE_FOR_CLIENT_APPLIED_TEMPLATE_LISTING_FAILURE,
  ];

  return api.put(options, _data);
};

export const deleteTemplateForClient = (data) => {
  let _data = {
    id: data,
    delete: true,
  };
  const options = {
    url: `v1/client/balance`,
  };
  options.types = [
    types.DELETE_TEMPLATE_FOR_CLIENT_SUCCESS,
    types.DELETE_TEMPLATE_FOR_CLIENT_FAILURE,
  ];

  return api.delete(options, _data);
};

export const setProfileData = (value) => {
  const data = {
    type: types.SET_PROFILE_DATA_SUCCESS,
    payload: value,
  };

  return data;
};
