import { api } from "../../services/api";
import * as types from "../Constants";
import moment from "moment";
import {
  GET_SCHOOL_INVOICE_PDF_FAILURE,
  GET_SCHOOL_INVOICE_PDF_SUCCESS,
  GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS,
  GET_SCHOOL_INVOICE_RECEIPT_PDF_SUCCESS,
} from "../Constants";
import { message } from "antd";

export const getSchoolInvoicePDFHtmlBlob = (data) => {
  const options = {
    url: `v1/invoice/SchoolInvoicePDFBlob/${data.invoiceId}/${data.paymentId}`,
  };
  options.types = [
    types.GET_SCHOOL_INVOICE_PDF_HTML_BLOB_SUCCESS,
    types.GET_SCHOOL_INVOICE_PDF_HTML_BLOB_FAILURE,
  ];

  return api.get(options);
};

export const getSchoolTypeListing = (data) => {
  const options = {
    url: `v1/school/type`,
  };
  options.types = [
    types.GET_SCHOOL_TYPE_LISTING_SUCCESS,
    types.GET_SCHOOL_TYPE_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getSchoolListing = () => {
  const options = {
    url: `v1/school/All/0`,
  };

  options.types = [
    types.GET_SCHOOL_LISTING_SUCCESS,
    types.GET_SCHOOL_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getSchoolListingForType = (id) => {
  const options = {
    url: `v1/school/All/${id}`,
  };

  options.types = [
    types.GET_SCHOOL_LISTING_FOR_TYPE_SUCCESS,
    types.GET_SCHOOL_LISTING_FOR_TYPE_FAILURE,
  ];

  return api.get(options);
};

export const addNewSchoolInvoice = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes:
      data.notesImageData === "" ||
      data.notesImageData === undefined ||
      data.notesImageData === null
        ? data.notes
        : data.notes.concat(`src=${data.notesImageData[1]}`),
    subTotal: data.subTotal,
    gst: data.gst,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: false,
    xeroInvoiceId: "00000000-0000-0000-0000-000000000000",
    currencyId: data.currencyId,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: data.depositInvoice,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    parentInvoiceId: data.parentInvoiceId,
    dateStr: _dateStr,
    invoiceItems: data.students,
    taxName: data.taxName,
  };
  const options = {
    url: "v1/invoice/school",
  };

  options.types = [
    types.ADD_SCHOOL_INVOICE_SUCCESS,
    types.ADD_SCHOOL_INVOICE_FAILURE,
  ];
  return api.post(options, _data);
};

export const editSchoolInvoice = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    id: data.invoiceId,
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes:
      data.notesImageData === "" ||
      data.notesImageData === undefined ||
      data.notesImageData === null
        ? data.notes
        : data.notes.concat(`src=${data.notesImageData[1]}`),
    subTotal: data.subTotal,
    gst: data.gst,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: data.postedXero,
    xeroInvoiceId: data.xeroInvoiceId,
    currencyId: data.currencyId,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: data.depositInvoice,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    parentInvoiceId: data.parentInvoiceId,
    dateStr: _dateStr,
    invoiceItems: data.students,
    taxName: data.taxName,
  };
  const options = {
    url: "v1/invoice/school",
  };

  options.types = [
    types.EDIT_SCHOOL_INVOICE_SUCCESS,
    types.EDIT_SCHOOL_INVOICE_FAILURE,
  ];
  return api.put(options, _data);
};

export const setSchoolInvoicesDataNull = () => {
  return {
    type: types.SET_SCHOOL_INVOICES_DATA_NULL_SUCCESS,
  };
};

export const getSchoolInvoiceInfo = (id) => {
  const options = {
    url: `v1/invoice/School/${id}`,
  };

  options.types = [
    types.GET_SCHOOL_INVOICE_INFO_SUCCESS,
    types.GET_SCHOOL_INVOICE_INFO_FAILURE,
  ];

  return api.get(options);
};

export const getStatuses = () => {
  const options = {
    url: `v1/client/programdetail/Status`,
  };

  options.types = [types.GET_STATUSES_SUCCESS, types.GET_STATUSES_FAILURE];

  return api.get(options);
};

export const getSearchSchoolListing = (searchText) => {
  const options = {
    url: `v1/school/Search/All/${searchText}`,
  };

  options.types = [types.SEARCH_SCHOOL_SUCCESS, types.SEARCH_SCHOOL_FAILURE];

  return api.get(options);
};

export const getParentSchoolInvoiceListing = (data) => {
  const options = {
    url: `v1/invoice/School/AllWithPaging/${data.pageSize}/${data.pageNumber}`,
  };

  options.types = [
    types.GET_SCHOOL_INVOICES_SUCCESS,
    types.GET_SCHOOL_INVOICES_FAILURE,
  ];

  return api.get(options);
};

export const getSearchStudentListing = (data) => {
  const options = {
    url: `v1/client/Students`,
  };

  options.types = [types.SEARCH_STUDENT_SUCCESS, types.SEARCH_STUDENT_FAILURE];

  return api.post(options, data);
};

export const getSchoolInfo = (id) => {
  const options = {
    url: `v1/school/${id}`,
  };

  options.types = [
    types.GET_SCHOOL_INFO_SUCCESS,
    types.GET_SCHOOL_INFO_FAILURE,
  ];

  return api.get(options);
};

export const deleteSchool = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/school",
  };

  options.types = [types.DELETE_SCHOOL_SUCCESS, types.DELETE_SCHOOL_FAILURE];

  return api.delete(options, deleteData);
};

export const deleteContact = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };
  const options = {
    url: "v1/school/contact",
  };

  options.types = [types.DELETE_CONTACT_SUCCESS, types.DELETE_CONTACT_FAILURE];

  return api.delete(options, deleteData);
};

export const deleteLevel = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };
  const options = {
    url: "v1/school/commission",
  };

  options.types = [types.DELETE_LEVEL_SUCCESS, types.DELETE_LEVEL_FAILURE];

  return api.delete(options, deleteData);
};

export const addSchool = (data) => {
  let _data = {
    schoolTypeId: data.schoolType,
    name: data.schoolName,
    city: data.schoolCity,
    address: data.schoolAddress,
    website: data.schoolWebsite,
    email: data.schoolEmail,
    notes: data.schoolNotes,
    agreementUrl: data.schoolAggrementURL && data.schoolAggrementURL,
    agreementFileName: data.schoolAggrementName,
    contacts: data.contacts,
    levels: data.levels,
    xeroID: "",
    contactPerson: "",
    contactDetails: "",
  };

  const options = {
    url: "v1/school",
  };

  options.types = [types.ADD_SCHOOL_SUCCESS, types.ADD_SCHOOL_FAILURE];
  return api.post(options, _data);
};

export const editSchool = (data) => {
  let _data = {
    schoolTypeId: data.schoolType,
    name: data.schoolName,
    city: data.schoolCity,
    address: data.schoolAddress,
    website: data.schoolWebsite,
    email: data.schoolEmail,
    notes: data.schoolNotes,
    agreementUrl: data.schoolAggrementURL && data.schoolAggrementURL,
    agreementFileName: data.schoolAggrementName,
    contacts: data.contacts,
    levels: data.levels,
    xeroID: "",
    contactPerson: "",
    contactDetails: "",
    id: data.schoolId,
  };

  const options = {
    url: "v1/school",
  };

  options.types = [types.EDIT_SCHOOL_SUCCESS, types.EDIT_SCHOOL_FAILURE];
  return api.put(options, _data);
};

export const getPDFSchoolInvoice = (data) => {
  const options = {
    url: `v1/invoice/SchoolInvoicePDF/${data.id}/${data.paymentId}`,
  };

  options.types = [
    types.GET_SCHOOL_INVOICE_PDF_SUCCESS,
    types.GET_SCHOOL_INVOICE_PDF_FAILURE,
  ];

  return api.getFile(options);
};

export const getPDFSchoolReceipt = (data) => {
  const options = {
    url: `v1/invoice/InvoiceReciptPDF/${data.id}/${data.paymentId}`,
  };

  options.types = [
    types.GET_SCHOOL_INVOICE_RECEIPT_PDF_SUCCESS,
    types.GET_SCHOOL_INVOICE_RECEIPT_PDF_FAILURE,
  ];

  return api.getFile(options);
};

export const getSchoolInvoicePDF = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes: data.notes,
    subTotal: data.subTotal,
    gst: data.gst,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: false,
    xeroInvoiceId: "00000000-0000-0000-0000-000000000000",
    currencyId: data.currencyId,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: true,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    parentInvoiceId: data.parentInvoiceId,
    dateStr: _dateStr,
    invoiceItems: data.students,
    taxName: data.taxName,
  };
  const options = {
    url: "v1/invoice/SchoolInvoicePDFPreView",
  };

  options.types = [
    types.GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS,
    types.GET_SCHOOL_INVOICE_PDF_WITHOUT_UPDATE_FAILURE,
  ];

  return api.postWithFileRes(options, _data);
};
