import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

import {
  Collapse,
  Spin,
  Modal,
  Form,
  Button,
  Input,
  Popconfirm,
  Select,
  DatePicker,
  message,
  Table,
  Upload,
} from "antd";
import jwt_decode from "jwt-decode";
import moment from "moment";
import getUserBranchPermissions from "../../Components/getUserBranchPermissions";
import { apiRefresh } from "../../services/api";
import CreateEmailForm from "../../Clients/EmployerEmail/CreateEmail/CreateEmailForm";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
class Students extends React.Component {
  formRef = React.createRef();

  state = {
    initLoading: true,
    loading: false,
    data: [],
    students: [],
    batchModal: false,
    isUpdate: false,
    currentBatch: null,
    studentUploadModal: false,
    batchId: null,
    studentModal: false,
    dataUploadModal: false,
    emailSendModal: false,
  };
  componentDidMount = () => {
    this.setState({ loading: true });

    let batchId = null;
    let batchName = null;
    if (this.props.location && this.props.location.state) {
      batchId = this.props.location.state.batchId;
      batchName = this.props.location.state.batchName;
    }
    if (batchId) {
      localStorage.setItem("batchId", batchId);
      localStorage.setItem("batchName", batchName);
    }
    this.setState(
      { batchId: batchId || localStorage.getItem("batchId") },
      () => {
        this.getBatchStudents(this.state.batchId);
      }
    );
  };
  getBatchStudents = (id) => {
    this.props
      .onGetAllBatchStudents(id)
      .then(() => this.setState({ loading: false }))

      .catch(() => this.setState({ loading: false }));
  };
  componentWillReceiveProps = (props, newprop) => {
    props.students?.users &&
      this.setState({
        students: props.students?.users,
        pages: props.students?.count,
        loading: false,
      });
  };
  createBatch = (values) => {
    const { isUpdate, currentBatch } = this.state;
    this.setState({ loading: true });

    let batchFunc = isUpdate
      ? this.props.onUpdateBatch
      : this.props.onCreateBatch;
    batchFunc({ ...values, id: currentBatch.id ? currentBatch.id : 0 })
      .then(() => {
        this.setState({ loading: false, batchModal: false }, () => {
          message.success(
            `Batch ${isUpdate ? "Updated" : "Created"} Successfully!`
          );
          this.getBatches();
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Some error occured!");
      });
  };
  columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "alternateEmail",
      key: "alternateEmail",
      align: "center",
    },

    {
      title: "Actions",
      align: "center",
      render: (item) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="ts-send-btn"
            size="medium"
            style={{ marginLeft: 4, marginRight: 4 }}
            type="primary"
            key="list-loadmore-more"
            onClick={() => this.getImpersonInfo(item.id)}
          >
            Impersonate
          </Button>
          {/* <Button
            className="ts-send-btn"
            size="medium"
            style={{ marginLeft: 4, marginRight: 4 }}
            type="primary"
            key="list-loadmore-more"
            onClick={() => {
              this.setState({ loading: true });
              this.props
                .onRemoveStudentAccount({
                  id: item.id,
                  delete: true,
                })
                .then(() => {
                  let { batchId } = this.state;
                  this.setState({ loading: false });
                  this.getBatchStudents(batchId);
                  message.success("Account removed!");
                })
                .catch(() => {
                  message.error("Could't removed account!");

                  this.setState({ loading: false });
                });
            }}
          >
            Delete
          </Button> */}
        </div>
      ),
    },
  ];
  enableUpdate = (item) => {
    this.setState(
      {
        isUpdate: true,
        batchModal: true,
        currentBatch: item,
      },
      () =>
        this.formRef.current.setFieldsValue({
          batchName: item.batchName,
          batchUniqueNumber: item.batchUniqueNumber,
          startDate: moment(item.startDate),
          endDate: moment(item.endDate),
        })
    );
  };
  uploadStudentModal = () => {
    const { studentUploadModal, loading } = this.state;
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="50%"
          visible={studentUploadModal}
          onCancel={() =>
            this.setState({ studentUploadModal: false }, () =>
              this.formRef?.current?.resetFields()
            )
          }
          footer={null}
          maskClosable={false}
          header={true}
          title={`Upload Accounts`}
        >
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={this.uploadBatchAccounts}
              >
                <Form.Item
                  name="file"
                  rules={[
                    {
                      required: false,
                      message: "Please select your file!",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={() => false}
                    onChange={this.onHandleChange}
                  >
                    <Button icon={<UploadOutlined />}></Button>
                  </Upload>
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Upload
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  sendStudentEmailModal = () => {
    const { emailSendModal, loading } = this.state;
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="50%"
          visible={emailSendModal}
          onCancel={() =>
            this.setState({ emailSendModal: false }, () =>
              this.formRef?.current?.resetFields()
            )
          }
          footer={null}
          maskClosable={false}
          header={true}
          title={`Create Email`}
        >
          <div style={{ width: "100%" }}>
            <CreateEmailForm
              batchId={this.state.batchId}
              studentEmail={true}
              // resultList={[]}
              emailContentData=""
              // onGetLetterTemplates={onGetLetterTemplates}
              // LetterTemplatesRes={LetterTemplatesRes}
              // onSendEmailLink={onSendEmailLink}
              // onAddEmployerEmail={onAddEmployerEmail}
              // onAddDraftClient={onAddDraftClient}
              // onGetClientProfile={onGetClientProfile}
              // clientProfileData={clientProfileData}
              setLoading={(e) => {
                this.setState({ loading: e });
              }}
              loading={loading}
              // onGetClientHistory={onGetClientHistory}
              // onUploadAvatar={onUploadAvatar}
              // onAddEmailDocument={onAddEmailDocument}
              // emailDocumentRes={emailDocumentRes}
              // setHandleKey={setHandleKey}
              // onGetClientFamilyEmails={onGetClientFamilyEmails}
              // clientEmailRes={clientEmailRes}
              // onGetEmployerDocument={onGetEmployerDocument}
              // employerDocumentRes={employerDocumentRes}
              // onGetImapSettingss={onGetImapSettingss}
              // imapSettingRes={imapSettingRes}
              onGetSignature={this.props.onGetSignature}
              signatureRes={this.props.signatureRes}
              cancelPopup={() => this.setState({ emailSendModal: false })}
              // onGetPdf={onGetPdf}
              // onGetLink={onGetLink}
              // onGetDocuments={onGetDocuments}
              // documentRes={documentRes}
              // inHistoryPopup={true}
              // emailContent={emailContent}
              // forward={isForward}
              // reply={isReply}
              // replyAll={isReplyAll}
              // isDraft={isDraft}
              // handleCancelPopup={handleCancelDetailPopup}
              // onDeleteClientEmail={onDeleteClientEmail}
              // onGetPermProcessingPerson={onGetPermProcessingPerson}
              // onGetGroups={onGetGroups}
            />
          </div>
        </Modal>
      </div>
    );
  };

  uploadClientData = () => {
    let formdata = new FormData();
    let { file, batchId } = this.state;
    formdata.append("file", file);
    const JsonData = {
      SubjectId: "00000000-0000-0000-0000-000000000000",
      FamilyId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: "0",
      Title: file && file.name,
      SizeInKB: file && file.size,
      DocuementExtension: file && file.type,
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      BranchId: localStorage.getItem("selectedBranchId"),
    };
    formdata.append("Data", JSON.stringify(JsonData));
    formdata.append("BatchId", batchId);
    this.setState({ loading: true });
    const uploadClientOpt = {
      url: `v1/supervisor/batch/Student/ImportData/Client`,
    };

    uploadClientOpt.types = ["UPLOAD_CLIENT_SUCCESS", "UPLOAD_CLIENT_FAILURE"];
    apiRefresh
      .post(uploadClientOpt, formdata)
      .then((res) => {
        message.success("Client Data uploaded Successfully!");
        this.setState({ dataUploadModal: false, loading: false }, () =>
          this.formRef?.current?.resetFields()
        );
        this.getBatchStudents(batchId);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  uploadFileNoteData = () => {
    let formdata = new FormData();
    let { file, batchId } = this.state;
    const JsonData = {
      SubjectId: "00000000-0000-0000-0000-000000000000",
      FamilyId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: "0",
      Title: file && file.name,
      SizeInKB: file && file.size,
      DocuementExtension: file && file.type,
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      BranchId: localStorage.getItem("selectedBranchId"),
    };
    formdata.append("Data", JSON.stringify(JsonData));
    formdata.append("file", file);
    formdata.append("BatchId", batchId);
    this.setState({ loading: true });
    const uploadClientOpt = {
      url: `v1/supervisor/batch/Student/ImportData/FileNote`,
    };

    uploadClientOpt.types = [
      "UPLOAD_FILE_NOTE_SUCCESS",
      "UPLOAD_FILE_NOTE_FAILURE",
    ];
    apiRefresh
      .post(uploadClientOpt, formdata)
      .then((res) => {
        message.success("Client Data uploaded Successfully!");
        this.setState({ dataUploadModal: false, loading: false }, () =>
          this.formRef?.current?.resetFields()
        );
        this.getBatchStudents(batchId);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  uploadDocumentsData = () => {
    let formdata = new FormData();
    let { file, batchId } = this.state;
    const JsonData = {
      SubjectId: "00000000-0000-0000-0000-000000000000",
      FamilyId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: "0",
      Title: file && file.name,
      SizeInKB: file && file.size,
      DocuementExtension: file && file.type,
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      BranchId: localStorage.getItem("selectedBranchId"),
    };
    formdata.append("Data", JSON.stringify(JsonData));
    formdata.append("file", file);
    formdata.append("BatchId", batchId);
    this.setState({ loading: true });
    const uploadClientOpt = {
      url: `v1/supervisor/batch/Student/ImportData/Document`,
    };

    uploadClientOpt.types = [
      "UPLOAD_DOCUMENTS_SUCCESS",
      "UPLOAD_DOCUMENTS_FAILURE",
    ];
    apiRefresh
      .post(uploadClientOpt, formdata)
      .then((res) => {
        message.success("Client Data uploaded Successfully!");
        this.setState({ dataUploadModal: false, loading: false }, () =>
          this.formRef?.current?.resetFields()
        );
        this.getBatchStudents(batchId);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  uploadEmailData = () => {
    this.setState({ emailSendModal: true });
    return;
    let formdata = new FormData();
    let { file, batchId } = this.state;
    const JsonData = {
      SubjectId: "00000000-0000-0000-0000-000000000000",
      FamilyId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: "0",
      Title: file && file.name,
      SizeInKB: file && file.size,
      DocuementExtension: file && file.type,
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      BranchId: localStorage.getItem("selectedBranchId"),
    };
    formdata.append("Data", JSON.stringify(JsonData));
    formdata.append("file", file);
    formdata.append("BatchId", batchId);
    this.setState({ loading: true });
    const uploadClientOpt = {
      url: `v1/supervisor/batch/Student/ImportData/Email`,
    };

    uploadClientOpt.types = ["UPLOAD_EMAIL_SUCCESS", "UPLOAD_EMAIL_FAILURE"];
    apiRefresh
      .post(uploadClientOpt, formdata)
      .then((res) => {
        message.success("Client Data uploaded Successfully!");
        this.setState({ dataUploadModal: false, loading: false }, () =>
          this.formRef?.current?.resetFields()
        );
        this.getBatchStudents(batchId);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  dwnldClientTemp = () => {
    const dwnldClientTempOpt = {
      url: `v1/supervisor/batch/Student/ImportData/ClientFileFormate`,
    };

    dwnldClientTempOpt.types = [
      "DWNLD_CLIENT_TEMP_SUCCESS",
      "DWNLD_CLIENT_TEMP_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .getFile(dwnldClientTempOpt)
      .then((res) => {
        this.setState({ loading: false });
        let blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "client_template.xlsx");
      })
      .catch((err) => this.setState({ loading: false }));
  };

  dwnldFileNotesTemp = () => {
    const dwnldClientTempOpt = {
      url: `v1/supervisor/batch/Student/ImportData/FileNoteFileFormate`,
    };

    dwnldClientTempOpt.types = [
      "DWNLD_FILE_NOTE_TEMP_SUCCESS",
      "DWNLD_FILE_NOTE_TEMP_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .getFile(dwnldClientTempOpt)
      .then((res) => {
        this.setState({ loading: false });
        let blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "file_note_template.xlsx");
      })
      .catch((err) => this.setState({ loading: false }));
  };

  dwnldEmailTemp = () => {
    const dwnldClientTempOpt = {
      url: `v1/supervisor/batch/Student/ImportData/EmailFileFormate`,
    };

    dwnldClientTempOpt.types = [
      "DWNLD_EMAIL_TEMP_SUCCESS",
      "DWNLD_EMAIL_TEMP_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .getFile(dwnldClientTempOpt)
      .then((res) => {
        this.setState({ loading: false });
        let blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "email_template.xlsx");
      })
      .catch((err) => this.setState({ loading: false }));
  };
  uploadDataModal = () => {
    const { dataUploadModal, loading } = this.state;
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="50%"
          visible={dataUploadModal}
          onCancel={() =>
            this.setState({ dataUploadModal: false }, () =>
              this.formRef?.current?.resetFields()
            )
          }
          footer={null}
          maskClosable={false}
          header={true}
          title={`Upload Accounts`}
        >
          <Spin spinning={loading}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="label">Upload Client</span>
                <Button className="button-blue" onClick={this.dwnldClientTemp}>
                  Client Template
                </Button>
              </div>
              <div
                className="file-content-index-cont"
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  marginBottom: 20,
                  marginLeft: 5,
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  // onFinish={this.uploadBatchAccounts}
                >
                  <Form.Item
                    name="file"
                    rules={[
                      {
                        required: false,
                        message: "Please select your file!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={(file) => {
                        this.onHandleChange(file);
                      }}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      // loading={loading}
                      disabled={loading}
                      type="primary"
                      // htmlType="submit"
                      onClick={() => this.uploadClientData()}
                    >
                      Upload
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="label">Upload File Note</span>
                <Button
                  className="button-blue"
                  onClick={this.dwnldFileNotesTemp}
                >
                  File Note Template
                </Button>
              </div>
              <div
                className="file-content-index-cont"
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  marginBottom: 20,
                  marginLeft: 5,
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={this.uploadFileNoteData}
                >
                  <Form.Item
                    name="file"
                    rules={[
                      {
                        required: false,
                        message: "Please select your file!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={this.onHandleChange}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      // loading={loading}
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                    >
                      Upload
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="label">Upload Document</span>
              </div>
              <div
                className="file-content-index-cont"
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  marginBottom: 20,
                  marginLeft: 5,
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={this.uploadDocumentsData}
                >
                  <Form.Item
                    name="file"
                    rules={[
                      {
                        required: false,
                        message: "Please select your file!",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={this.onHandleChange}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      // loading={loading}
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                    >
                      Upload
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className="label">Send Email</span>
                <Button className="button-blue" onClick={this.dwnldEmailTemp}>
                  Email Template
                </Button>
              </div>
              <div
                className="file-content-index-cont"
                style={{
                  paddingTop: 8,
                  paddingBottom: 8,
                  marginBottom: 20,
                  marginLeft: 5,
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={this.uploadEmailData}
                >
                  {/* <Form.Item
                    name="file"
                    rules={[
                      {
                        required: false,
                        message: "Please select your file!",
                      },
                    ]}
                  >
                   <Upload
                      beforeUpload={() => false}
                      onChange={this.onHandleChange}
                    >
                      <Button icon={<UploadOutlined />}></Button>
                    </Upload>
                  </Form.Item> */}
                  <Form.Item>
                    <Button
                      // loading={loading}
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: 5 }}
                    >
                      Send Email
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  };
  onHandleChange = (e) => {
    this.setState({ file: e.fileList[0].originFileObj });
  };
  uploadBatchAccounts = () => {
    let formdata = new FormData();
    let { file, batchId } = this.state;
    formdata.append("file", file);
    formdata.append("BatchId", batchId);
    this.setState({ loading: true });
    this.props
      .onUploadBatchAccounts(formdata)
      .then((response) => {
        message.success("Accounts Imported Successfully!");
        this.setState({ studentUploadModal: false, loading: false }, () =>
          this.formRef?.current?.resetFields()
        );
        this.getBatchStudents(batchId);
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Could't import accounts!");
      });
  };

  studentAddModal = () => {
    const { studentModal, loading } = this.state;
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="50%"
          visible={studentModal}
          onCancel={() => this.setState({ studentModal: false })}
          footer={null}
          maskClosable={false}
          header={true}
          title={`Add Account`}
        >
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={this.addStudentAccount}
              >
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Full Name"
                  name="fullName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input />
                </Form.Item>
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Email"
                  name="email"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input />
                </Form.Item>
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Password"
                  name="password"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                  >
                    ADD
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  addStudentAccount = (values) => {
    let { batchId } = this.state;
    this.setState({ loading: true });
    this.props
      .onAddBatchAccount([{ ...values, batchId: parseInt(batchId) }])
      .then(() => {
        message.success("Account added successfully!");
        this.setState({ loading: false, studentModal: false }, () =>
          this.getBatchStudents(batchId)
        );
      })
      .catch(() => {
        message.error("Account already exist!");
        this.setState({ loading: false });
      });
  };

  getImpersonInfo = (id) => {
    this.setState({ loading: true });
    this.props
      .onGetImpersonInfo(id)
      .then(async (res) => {
        let supervisorKeys = JSON.stringify(Object.entries(localStorage));
        this.setState({ loading: false });
        localStorage.clear();
        sessionStorage.clear();

        localStorage.removeItem("admintoken");
        localStorage.setItem("token", res.payload.accessToken);
        localStorage.setItem("refreshToken", res.payload.refreshToken);
        let token = localStorage.getItem("token");
        var jwtDecoded = jwt_decode(res.payload.accessToken);
        localStorage.setItem("userId", jwtDecoded.sub);
        localStorage.setItem("userEmail", jwtDecoded.email);
        localStorage.setItem("userOwner", jwtDecoded.IsOwner);
        localStorage.setItem("selectedBranchId", jwtDecoded.BranchId);
        localStorage.setItem("companyId", jwtDecoded.Com);
        localStorage.setItem("userName", jwtDecoded.FullName);
        localStorage.setItem("supervisorData", supervisorKeys);
        // this.props.getToken(token);
        let tokenData = localStorage.getItem("notificationToken");
        getUserBranchPermissions();
        this.props.onNotificationAdd(jwtDecoded.sub, tokenData).then((res) => {
          console.log("Notification token submitted");
        });
        this.props.history.push("/dashboardBI");
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  render() {
    const { loading, students, pages } = this.state;
    let { batchName } = this.props.match.params;

    return (
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="list-heading">
                <span className="header-text" style={{ fontWeight: "500" }}>
                  Student Accounts ({batchName})
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div className="file-notes-container supervisor-batches">
                  <div
                    style={{
                      display: "flex",
                      marginRight: 4,
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>
                      <Button
                        className="ts-send-btn"
                        size="medium"
                        type="primary"
                        key="list-loadmore-more"
                        onClick={() =>
                          this.setState({ studentModal: true }, () =>
                            this.formRef?.current?.resetFields()
                          )
                        }
                        style={{ marginLeft: 4, marginRight: 4 }}
                      >
                        Add Account
                      </Button>
                    </span>
                    <span>
                      <Button
                        className="ts-send-btn"
                        size="medium"
                        type="primary"
                        key="list-loadmore-more"
                        style={{ marginLeft: 4, marginRight: 4 }}
                        onClick={() =>
                          this.setState({ studentUploadModal: true })
                        }
                      >
                        Import From Excel
                      </Button>
                    </span>
                    <span>
                      <Button
                        className="ts-send-btn"
                        size="medium"
                        type="primary"
                        key="list-loadmore-more"
                        style={{ marginLeft: 4, marginRight: 4 }}
                        onClick={() =>
                          // this.setState({ studentUploadModal: true })
                          {
                            const downloadTempOpt = {
                              url: `v1/supervisor/batch/Student/ImportData/UserFileFormate`,
                            };

                            downloadTempOpt.types = [
                              "UPLOAD_CLIENT_SUCCESS",
                              "UPLOAD_CLIENT_FAILURE",
                            ];
                            this.setState({ loading: true });
                            apiRefresh
                              .getFile(downloadTempOpt)
                              .then((res) => {
                                this.setState({ loading: false });
                                let blob = new Blob([res], {
                                  type: "application/octet-stream",
                                });
                                saveAs(blob, `User-template.xlsx`);
                              })
                              .catch((err) => {
                                this.setState({ loading: false });
                              });
                          }
                        }
                      >
                        Download Template
                      </Button>
                    </span>
                    <span>
                      {/* <Link to="/supervisor/batches/upload-data"> */}
                      <Button
                        className="ts-send-btn"
                        size="medium"
                        type="primary"
                        key="list-loadmore-more"
                        style={{ marginLeft: 4, marginRight: 4 }}
                        onClick={() => this.setState({ dataUploadModal: true })}
                      >
                        Import Data
                      </Button>
                      {/* </Link> */}
                    </span>
                  </div>
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    columns={this.columns}
                    dataSource={students}
                    pagination={{
                      defaultCurrent: 1,
                      total: pages,
                      showSizeChanger: true,
                      defaultPageSize: 20,
                      pageSizeOptions: ["5", "10", "15", "20", "50"],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.uploadStudentModal()}
        {this.studentAddModal()}
        {this.uploadDataModal()}
        {this.sendStudentEmailModal()}
      </Spin>
    );
  }
}

export default Students;
