import * as types from "../Constants";

export const mailReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MAIL_BOX_SUCCESS:
      return { ...state, mailBoxData: action.payload };
    case types.GET_MAIL_BOX_FAILURE:
      return { ...state, mailBoxError: action.payload };

    case types.SEARCH_IMAP_EMAIL_SUCCESS:
      return { ...state, searchMailData: action.payload };
    case types.SEARCH_IMAP_EMAIL_FAILURE:
      return { ...state, searchMailError: action.payload };
    case types.EMPTY_IMAP_EMAIL_SUCCESS:
      return {...state, searchMailData: action.payload}

    case types.MANUAL_IMPORT_EMPLOYER_EMAIL_SUCCESS:
      return { ...state, searchEmployerData: action.payload };
    case types.MANUAL_IMPORT_EMPLOYER_EMAIL_FAILURE:
      return { ...state, searchEmployerError: action.payload };

    case types.AUTO_IMPORT_CLIENT_EMAIL_SUCCESS:
      return { ...state, autoImportEmailClientData: action.payload };
    case types.AUTO_IMPORT_CLIENT_EMAIL_FAILURE:
      return { ...state, autoImportEmailClientError: action.payload };

    default:
      return state;
  }
};
