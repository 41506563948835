import { api } from "../../services/api";
import * as types from "../Constants";
import {
  DELETE_EMAIL_CONTENT_FAILURE,
  DELETE_EMAIL_CONTENT_SUCCESS,
  GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE,
  GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS,
  UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS,
} from "../Constants";

export const getClientTag = (id) => {
  let selectedBranchId = id || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/potentialclient/markedtags/All/${selectedBranchId}`,
  };
  options.types = [types.GET_CLIENT_TAG_SUCCESS, types.GET_CLIENT_TAG_FAILURE];

  return api.get(options);
};

export const addClientTag = (data) => {
  const options = {
    url: "v1/potentialclient/markedtags",
  };
  options.types = [types.ADD_CLIENT_TAG_SUCCESS, types.ADD_CLIENT_TAG_FAILURE];

  return api.post(options, data);
};

export const removeClientTag = (data) => {
  const options = {
    url: "v1/potentialclient/markedtags",
  };
  options.types = [
    types.DELETE_CLIENT_TAG_SUCCESS,
    types.DELETE_CLIENT_TAG_FAILURE,
  ];

  return api.delete(options, data);
};

export const updetaClientTag = (data) => {
  const options = {
    url: "v1/potentialclient/markedtags",
  };
  options.types = [types.PUT_CLIENT_TAG_SUCCESS, types.PUT_CLIENT_TAG_FAILURE];

  return api.put(options, data);
};

// REMINDER SETTINGS //

export const getReminderSettings = (branchId) => {
  const options = {
    url: `v1/reminder/setting`,
  };
  options.types = [
    types.GET_REMINDER_SETTINGS_SUCCESS,
    types.GET_REMINDER_SETTINGS_FAILURE,
  ];

  return api.get(options);
};

export const addReminderSettings = (data) => {
  const options = {
    url: "v1/reminder/setting",
  };
  options.types = [
    types.ADD_REMINDER_SETTINGS_SUCCESS,
    types.ADD_REMINDER_SETTINGS_FAILURE,
  ];

  return api.post(options, data);
};

// EMAIL CONTENT //

export const getEmailContent = () => {
  const options = {
    url: `v1/emailtemplate`,
  };
  options.types = [
    types.GET_EMAIL_CONTENT_SUCCESS,
    types.GET_EMAIL_CONTENT_FAILURE,
  ];

  return api.get(options);
};

export const removeEmailContent = (data) => {
  const options = {
    url: `v1/emailtemplate`,
  };
  options.types = [
    types.DELETE_EMAIL_CONTENT_SUCCESS,
    types.DELETE_EMAIL_CONTENT_FAILURE,
  ];

  return api.delete(options, data);
};

export const getEmailContentDocumentCheckList = () => {
  const options = {
    url: `v1/template/documentCheckList`,
  };
  options.types = [
    types.GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS,
    types.GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.get(options);
};

export const addEmailContent = (data) => {
  const options = {
    url: "v1/emailtemplate",
  };
  options.types = [
    types.ADD_EMAIL_CONTENT_SUCCESS,
    types.ADD_EMAIL_CONTENT_FAILURE,
  ];

  return api.post(options, data);
};

export const updateEmailContent = (data) => {
  const options = {
    url: "v1/emailtemplate",
  };
  options.types = [
    types.PUT_EMAIL_CONTENT_SUCCESS,
    types.PUT_EMAIL_CONTENT_FAILURE,
  ];

  return api.put(options, data);
};

export const updateEmailContentDocumentChecklist = (data) => {
  const options = {
    url: "v1/template",
  };
  options.types = [
    types.UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS,
    types.UPDATE_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.put(options, data);
};

export const setActiveInnerTab = (value) => {
  const data = {
    type: types.SET_ACTIVE_INNER_TAB_SUCCESS,
    payload: value,
  };

  return data;
};
