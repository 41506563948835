import { api } from "../../services/api";
import * as types from "../Constants";

export const submitQuestionnaire = (data) => {
  const options = {
    url: "v1/questionnairefilledanswer/InsertFilledAnswers",
    // url: "v1/questionnairefilledanswer",
  };

  options.types = [
    types.SUBMIT_QUESTIONNAIRE_SUCCESS,
    types.SUBMIT_QUESTIONNAIRE_FAILURE,
  ];

  return api.post(options, data);
};

export const updateSubmitQuestionnaire = (data) => {
  const options = {
    // url: "v1/questionnairefilledanswer/InsertFilledAnswers"
    url: "v1/questionnairefilledanswer",
  };

  options.types = [
    types.UPDATE_SUBMIT_QUESTIONNAIRE_SUCCESS,
    types.UPDATE_SUBMIT_QUESTIONNAIRE_FAILURE,
  ];

  return api.put(options, data);
};

export const updateQuestionnaire = (data) => {
  const options = {
    url: "v1/questionnaire/RUpdate",
  };

  options.types = [
    types.UPDATE_QUESTIONNAIRE_SUCCESS,
    types.UPDATE_QUESTIONNAIRE_FAILURE,
  ];

  return api.put(options, data);
};
