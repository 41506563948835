import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientTags from "./../AccountSetting/ClientTags/ClientTags";

import {
  getClientTag,
  addClientTag,
  removeClientTag,
  updetaClientTag,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    clientTagRes: state.accountSetReducer.clientTagRes,
    addClientTagRes: state.accountSetReducer.addClientTagRes,
    removeClientTagRes: state.accountSetReducer.removeClientTagRes,
    updateClientTagRes: state.accountSetReducer.updateClientTagRes,
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onAddClientTag: bindActionCreators(addClientTag, dispatch),
    onRemoveClientTag: bindActionCreators(removeClientTag, dispatch),
    onUpdetaClientTag: bindActionCreators(updetaClientTag, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTags);
