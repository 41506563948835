import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var profSetting = [
  {
    type: "Personal Information",
    status: false,
  },
  {
    type: "Current Visa Detail",
    status: false,
  },
  {
    type: "Medical Detail",
    status: false,
  },
  {
    type: "Police Certificate",
    status: false,
  },
  {
    type: "Passport Detail",
    status: false,
  },
  {
    type: "Accessing Authorities",
    status: false,
  },
  {
    type: "Login Detail",
    status: false,
  },
  {
    type: "NZQA Detail",
    status: false,
  },
  {
    type: "Billing Address",
    status: false,
  },
];

const ClientProfileSetting = ({ onSetActiveInnerTab }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileSet, setProfileSet] = useState([]);
  const [checkedValues, setCheckedValues] = useState(
    profSetting.filter((option) => option.status).map((option) => option.type)
  );

  const state = useLocation().state;

  const { Option } = Select;

  useEffect(() => {
    getProfileSettingFucn();
  }, []);

  const getProfileSettingFucn = () => {
    setLoading(true);
    const getProfileSetOpt = {
      url: `v1/branch/ShowHideClientProfileSetting`,
    };
    getProfileSetOpt.types = [
      "GET_CLIENT_PROFILE_SETTING_SUCCESS",
      "GET_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getProfileSetOpt)
      .then((res) => {
        setLoading(false);
        setProfileSet((res && res.clientProfileSetting) || profSetting);
        setCheckedValues(
          res.clientProfileSetting
            .filter((option) => option.status)
            .map((option) => option.type)
        );
      })
      .catch((err) => {
        setLoading(false);
        setProfileSet(profSetting);
      });
  };

  const onTargetChecked = (e) => {
    let profileSetCopy = [...profileSet];
    // console.log(`checked = ${e.target.checked}`);
    for (var i = 0; i < profileSetCopy.length; i++) {
      let findObj = e.find((obj) => obj === profileSetCopy[i].type);

      if (findObj) {
        profileSetCopy[i].status = true;
      } else {
        profileSetCopy[i].status = false;
      }
    }
    const updProfileSetOpt = {
      url: `v1/branch/ShowHideClientProfileSetting`,
    };
    updProfileSetOpt.types = [
      "UPD_CLIENT_PROFILE_SETTING_SUCCESS",
      "UPD_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .put(updProfileSetOpt, profileSetCopy)
      .then((res) => {
        setLoading(false);
        getProfileSettingFucn();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
    for (var i = 0; i < values.length; i++) {}
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img
                      src={Images.documentSync}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="top-text">Client Profile Setting</span>
                </div>

                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div
                className="default-font-box"
                style={{ paddingLeft: 25, paddingRight: 25 }}
              >
                <div>
                  <div className="client-tag-table">
                    <Form
                      onChange={onDocumentShow}
                      form={form}
                      className="d-flex space-between"
                    >
                      <div className="reminder-set-form margin-top-34">
                        <Checkbox.Group
                          value={checkedValues}
                          onChange={onTargetChecked}
                        >
                          {profileSet.length > 0 &&
                            profileSet.map((option, index) => {
                              return (
                                <Checkbox
                                  value={option.type}
                                  key={option.type}
                                  style={{
                                    padding: 10,
                                    marginLeft: 0,
                                    width: 185,
                                  }}
                                >
                                  Hide {option.type}
                                </Checkbox>
                              );
                            })}
                        </Checkbox.Group>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default ClientProfileSetting;
