import { api } from "../../services/api";
import * as types from "../Constants";

export const getDashboardPotentialClient = (data) => {
  const options = {
    url: `v1/dashboard/PotentialClient`,
  };
  options.types = [
    types.GET_DASHBOARD_POTENTIALCLIENT_SUCCESS,
    types.GET_DASHBOARD_POTENTIALCLIENT_FAILURE,
  ];

  return api.post(options, data);
};

export const getPotentialClientPendingTask = (clientId) => {
  const options = {
    url: `v1/task/All/Pending/${clientId}`,
  };
  options.types = [
    types.GET_POTENTIALCLIENT_PENDING_TASKS_SUCCESS,
    types.GET_POTENTIALCLIENT_PENDING_TASKS_FAILURE,
  ];

  return api.get(options);
};