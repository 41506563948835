import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const emailIntegrationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_OUTLOOK_MAIL_SUCCESS:
      return { ...state, outlookMailRes: action.payload };
    case types.GET_OUTLOOK_MAIL_FAILURE:
    case types.GET_OUTLOOK_LOGIN_SUCCESS:
      return { ...state, outlookLoginRes: action.payload };
    case types.GET_GMAIL_SUCCESS:
      return { ...state, gmailRes: action.payload };
    case types.GET_GMAIL_LOGIN_SUCCESS:
      return { ...state, gmailLoginRes: action.payload };
    // case types.ADD_DROPBOX_DOC_SUCCESS:
    //   return { ...state, addDropboxDocRes: action.payload };
    // case types.ADD_DROPBOX_DOC_FAILURE:

    // case types.GET_DROPBOX_DOC_SUCCESS:
    //   return { ...state, dropboxDocRes: action.payload };
    // case types.GET_DROPBOX_DOC_FAILURE:

    default:
      return state;
  }
};
