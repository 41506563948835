import React, { Fragment } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <a>{text}</a>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "PROCESSING PERSON",
    dataIndex: "fullName",
    key: "fullName",
    render: (text, row, index) => {
      return (
        <Link
          to={{
            pathname:
              row.memberType.toLowerCase() === "client"
                ? "/profile"
                : row.memberType.toLowerCase() === "spouse"
                ? "/partner-profile"
                : "/client-family-member",
            state: {
              id: row.clientId,
              familyId: "00000000-0000-0000-0000-000000000000",
            },
          }}
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "FIRST NAME",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "LAST NAME",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "MOBILE",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "VISA",
    dataIndex: "visaTypeName",
    key: "visaTypeName",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (text) => (
      <span>
        {moment(text).format("DD/MM/YYYY") ==
        moment("1900-01-01T00:00:00.000Z").format("DD/MM/YYYY")
          ? ""
          : moment(text).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    title: "MEDICAL CERTIFICATE ISSUE DATE",
    dataIndex: "medicalIssueDate",
    key: "medicalIssueDate",
    render: (text) => (
      <span>
        {moment(text).format("DD/MM/YYYY") ==
        moment("1900-01-01T00:00:00.000Z").format("DD/MM/YYYY")
          ? ""
          : moment(text).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    title: "XRAY EXPIRY",
    dataIndex: "xrayExpiryDate",
    key: "xrayExpiryDate",
    render: (text) => (
      <span>
        {moment(text).format("DD/MM/YYYY") ==
        moment("1900-01-01T00:00:00.000Z").format("DD/MM/YYYY")
          ? ""
          : moment(text).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    title: "POLICE CERTIFICATE DATE",
    dataIndex: "policeCertificateExpiryDate",
    key: "policeCertificateExpiryDate",
    render: (text) => (
      <span>
        {moment(text).format("DD/MM/YYYY") ==
        moment("1900-01-01T00:00:00.000Z").format("DD/MM/YYYY")
          ? ""
          : moment(text).format("DD/MM/YYYY")}
      </span>
    ),
  },
  {
    title: "STATUS",
    dataIndex: "arbitaryJson",
    key: "arbitaryJson",
    render: (text) => (
      <span>
        {text ? (JSON.parse(text).Active ? "Active" : "InActive") : ""}
      </span>
    ),
  },
  {
    title: "PARTNER VISA EXPIRY DATE",
    dataIndex: "partnerExpiray",
    key: "partnerExpiray",
    render: (text) => {
      return (
        <span>
          {moment(text).format("DD/MM/YYYY") ==
          moment("1900-01-01T00:00:00.000Z").format("DD/MM/YYYY")
            ? ""
            : moment(text).format("DD/MM/YYYY")}
        </span>
      );
    },
  },
  {
    title: "PARTNER VISA",
    dataIndex: "partnerVisa",
    key: "partnerVisa",
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class VisaExpiryTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
      loading: false,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson:
            this.props.processingIds || "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
        };
        this.props.onFileNotesReport(data).then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };

  visaExpiryReport = () => {
    let data = {
      processingPerson:
        this.props.processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: 0,
      pageNumber: 0,
    };
    this.setState({ loading: true });
    const visaExpiryOpt = {
      url: `v1/report/CurrentVisaExpiryExport`,
    };
    visaExpiryOpt.types = [
      "VISA_EXPIRY_REPORT_SUCCESS",
      "VISA_EXPIRY_REPORT_FAILURE",
    ];

    apiRefresh
      .postWithFileRes(visaExpiryOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "VisaReport.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { fileNotesReportRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
            <Button
              className="button-blue"
              style={{ marginTop: 10 }}
              onClick={this.visaExpiryReport}
              loading={this.state.loading}
            >
              Export
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={fileNotesReportRes && fileNotesReportRes}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
            scroll={{ x: "auto" }}
          />
        </div>
      </Fragment>
    );
  }
}
export default VisaExpiryTable;
