import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  message,
  Col,
  InputNumber,
  Spin,
  Collapse,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addNewTemplate,
  addTemplateToClientAppliedTemplateListing,
  editTemplateForClientAppliedTemplateListing,
  getAllTaxesListing,
  getClientTemplateListing,
  getTemplateListing,
  setTemplateStatus,
} from "../../store/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Stage from "./Stage";
import moment from "moment";
import activityData from "../ClientActivity/ActivityData";
const { Option } = Select;
const { Panel } = Collapse;

class AccordianTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      stages: [],
      description: "",
      total: 0,
      id: 0,
      deposit: undefined,
      received: 0,
      totalStagedInvoices: 0,
    };
  }

  componentDidMount() {
    this.props.getAllTaxesListing();
    let _data = this.props && this.props.data;
    let _received = 0;
    let _totalStagedInvoices = 0;
    if (_data && _data.stages && _data.stages.length > 0) {
      for (let _indicator = 0; _indicator < _data.stages.length; _indicator++) {
        if (
          _data &&
          _data.stages &&
          _data.stages[_indicator].invoice &&
          _data.stages[_indicator].invoice.length > 0
        ) {
          _totalStagedInvoices =
            _totalStagedInvoices + _data.stages[_indicator].invoice.length;
          for (
            let _indicatorTemp = 0;
            _indicatorTemp < _data.stages[_indicator].invoice.length;
            _indicatorTemp++
          ) {
            _received =
              _received +
              _data.stages[_indicator].invoice[_indicatorTemp].paidAmount;
          }
        }
      }
    }
    this.setState(
      {
        name: _data.name,
        id: _data.id,
        description: _data.description,
        stages: _data.stages,
        total: _data.total,
        deposit:
          _data.depositeInvoice.length === 0
            ? undefined
            : _data.depositeInvoice[0] && _data.depositeInvoice[0].total,
        received: _received,
        totalStagedInvoices: _totalStagedInvoices,
      },
      () => {}
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _data = nextProps && nextProps.data;
    let _received = 0;
    let _totalStagedInvoices = 0;
    if (_data && _data.stages && _data.stages.length > 0) {
      for (let _indicator = 0; _indicator < _data.stages.length; _indicator++) {
        if (
          _data &&
          _data.stages &&
          _data.stages[_indicator].invoice &&
          _data.stages[_indicator].invoice.length > 0
        ) {
          _totalStagedInvoices =
            _totalStagedInvoices + _data.stages[_indicator].invoice.length;
          for (
            let _indicatorTemp = 0;
            _indicatorTemp < _data.stages[_indicator].invoice.length;
            _indicatorTemp++
          ) {
            _received =
              _received +
              _data.stages[_indicator].invoice[_indicatorTemp].paidAmount;
          }
        }
      }
    }
    this.setState(
      {
        name: _data.name,
        id: _data.id,
        description: _data.description,
        stages: _data.stages,
        total: _data.total,
        deposit:
          _data.depositeInvoice.length === 0
            ? undefined
            : _data.depositeInvoice[0] && _data.depositeInvoice[0].total,
        received: _received,
        totalStagedInvoices: _totalStagedInvoices,
      },
      () => {}
    );
  }

  getStatsPortion = () => {
    return (
      <div>
        <Row style={{ borderTop: "1px solid rgb(214, 208, 208)" }}>
          <Col xs={10} offset={14} style={{ display: "block" }}>
            <p style={{ wordWrap: "break-word" }}>
              {`Deposit: ${
                this.state.deposit === undefined ? 0 : this.state.deposit
              }`}
            </p>
            <p style={{ wordWrap: "break-word" }}>
              {`Total: ${this.state.total}`}
            </p>
            <p style={{ wordWrap: "break-word" }}>
              {`Received: ${this.state.received}`}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  onClickUpdate = () => {
    let profileData = JSON.parse(window.localStorage.getItem("profileData"));
    let userName = window.localStorage.getItem("userName");
    this.props && this.props.setLoadingTrue();
    this.props
      .editTemplateForClientAppliedTemplateListing(this.state)
      .then(() => {
        message.success("Template is updated successfully!");
        this.props
          .getClientTemplateListing()
          .then(() => {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Balance template" +
                " " +
                this.state.name +
                " " +
                "updated by" +
                " " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Balance",
              invoiceId: "0",
            };
            activityData(myData);
            this.props && this.props.setLoadingFalse();
          })
          .catch(() => {
            this.props && this.props.setLoadingFalse();
          });
      })
      .catch(() => {
        this.props && this.props.setLoadingFalse();
      });
  };

  getButton = () => {
    return (
      <Row style={{ marginTop: "10px" }}>
        {this.state.deposit !== undefined ||
        this.state.totalStagedInvoices > 0 ? (
          <Col xs={24}>
            <p style={{ marginLeft: "3px", fontSize: "10px", color: "black" }}>
              {"* To update please undo associated invoices"}
            </p>
          </Col>
        ) : (
          <Col xs={8} offset={15} style={{ marginBottom: "10px" }}>
            <Button
              type="primary"
              size="small"
              onClick={this.onClickUpdate}
              className="button-blue"
            >
              Update
            </Button>
          </Col>
        )}
      </Row>
    );
  };

  getHeadings = () => {
    return (
      <Row>
        <Col xs={8}>
          <div style={{ display: "block", textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>{"Stage"}</p>
          </div>
        </Col>
        <Col xs={8}>
          <div style={{ display: "block", textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>{"Amount"}</p>
          </div>
        </Col>
        <Col xs={8}>
          <div style={{ display: "block", textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>{"Received"}</p>
          </div>
        </Col>
      </Row>
    );
  };

  getStageAmountUpdatedValue = (id, amount) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].amount = amount;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageTaxInclusiveUpdatedValue = (id, taxInclusive, taxName, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].taxInclusive = taxInclusive;
          copyStateStages[_pointer].taxName = taxName;
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageTaxNameUpdatedValue = (id, taxName, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].taxName = taxName;
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  setTemplateStatus = (id, status) => {
    this.props && this.props.setLoadingTrue();
    if (status === "Inactive") {
      let _data = {
        id: id,
        status: "Active",
      };
      this.props
        .setTemplateStatus(_data)
        .then(() => {
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
    } else {
      let _data = {
        id: id,
        status: "Inactive",
      };
      this.props
        .setTemplateStatus(_data)
        .then(() => {
          this.props && this.props.setLoadingTrue();
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
    }
  };

  getStageGSTUpdatedValue = (id, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  handleDeleteTemplate = () => {
    let countInvoices = 0;
    let _data = this.props && this.props.data;
    if (_data && _data.stages && _data.stages.length > 0) {
      for (let _dataIndex = 0; _dataIndex < _data.stages.length; _dataIndex++) {
        if (
          _data.stages[_dataIndex].invoice &&
          _data.stages[_dataIndex].invoice.length > 0
        ) {
          countInvoices++;
        } else {
          //do nothing
        }
      }
    }
    if (countInvoices > 0) {
      message.error(
        "Template can't be deleted as invoices are linked with its stages!"
      );
    } else {
      this.props && this.props.showDeleteModal(this.state.id, this.state.name);
    }
  };

  render() {
    return (
      <Fragment>
        <Collapse
          defaultActiveKey={this.props && this.props.activeKeysList}
          key={this.props.index}
          expandIconPosition={"right"}
          className="balance-accordian template"
        >
          <Panel
            header={
              <Row style={{ display: "block", marginLeft: "9px" }}>
                <Col xs={24}>
                  <Row>
                    <Col xs={8}>
                      <h5
                        style={{
                          color: "#3fb5cf",
                          margin: "-4px",
                          wordBreak: "break-word",
                        }}
                      >
                        {this.state.name}
                      </h5>
                    </Col>
                    <Col xs={9} offset={1}>
                      <div
                        onClick={() =>
                          this.setTemplateStatus(
                            this.props && this.props.data && this.props.data.id,
                            this.props &&
                              this.props.data &&
                              this.props.data.status
                          )
                        }
                        className="invoice-status-btn"
                        style={{
                          backgroundColor:
                            this.props &&
                            this.props.data &&
                            this.props.data.status === "Active"
                              ? "#5cb85c"
                              : this.props &&
                                this.props.data &&
                                this.props.data.status === "Inactive"
                              ? "#d9534f"
                              : "transparent",
                          marginLeft: "13px",
                          cursor: "pointer",
                        }}
                      >
                        <span className="invoice-status-text">
                          {this.props &&
                            this.props.data &&
                            this.props.data.status}
                        </span>
                      </div>
                    </Col>
                    <Col xs={2} offset={1}>
                      <a href="javascript:" onClick={this.handleDeleteTemplate}>
                        <DeleteOutlined color="rgb(51, 170, 218)" />
                      </a>
                    </Col>
                  </Row>
                  <p
                    className="balance-client-des"
                    style={{ wordBreak: "break-word" }}
                  >
                    {this.state.description === ""
                      ? `-NA`
                      : `-${this.state.description}`}
                  </p>
                </Col>
              </Row>
            }
            key={this.state.id}
          >
            {this.getHeadings()}
            {this.state &&
              this.state.stages &&
              this.state.stages.map((data, index) => {
                return (
                  <Stage
                    index={index}
                    data={data}
                    getStageAmountUpdatedValue={this.getStageAmountUpdatedValue}
                    getStageTaxInclusiveUpdatedValue={
                      this.getStageTaxInclusiveUpdatedValue
                    }
                    getStageTaxNameUpdatedValue={
                      this.getStageTaxNameUpdatedValue
                    }
                    getStageGSTUpdatedValue={this.getStageGSTUpdatedValue}
                  />
                );
              })}
            {this.getStatsPortion()}
            {this.getButton()}
          </Panel>
        </Collapse>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateStatus: bindActionCreators(setTemplateStatus, dispatch),
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  editTemplateForClientAppliedTemplateListing: bindActionCreators(
    editTemplateForClientAppliedTemplateListing,
    dispatch
  ),
});

AccordianTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccordianTemplate);
export default withRouter(AccordianTemplate);
