import React, { Fragment, useState, useEffect } from "react";
import {
  UserOutlined,
  LinkOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { yyyymmddFormate } from "./../../../../../Common/reUseFunction";
import {
  Table,
  message,
  Modal,
  Tooltip,
  Form,
  Row,
  Col,
  Input,
  AutoComplete,
  Spin,
} from "antd";
import { Images } from "./../../../../../Themes";
import moment from "moment";
import ClientSingleEmpTable from "./ClientSingleEmpTable";

const JobEmployerTable = ({
  employerJobRes,
  showModal,
  isModalVisible,
  onRemoveEmployerJob,

  onGetEmployerJob,

  userDataEmp,

  setLoading,

  onGetClientSearch,
  clientSearchRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetSingleClientHis,
  singleJobHisRes,
}) => {
  const [modal1Visible, setModal1Visible] = useState(false);
  const [islinkClient, setIslinkClient] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [options, setOptions] = useState([]);
  const [isSearchPotential, setSearchPotential] = useState(false);
  const [searchTitle, setSearchTitle] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState([]);
  const [jobValue, setJobValue] = useState();
  const [arrOfClients, setArrOfClients] = useState([]);
  const [arrRemoveTag, setArrRemoveTag] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isjobId, setIsjobId] = useState();
  const [loadingTags, setLoadingTags] = useState(false);
  const [expandedRowsEntriesKeys, setExpandedRowsEntriesKeys] = useState("");

  // useEffect(() => {
  //   const clientData = {
  //     branchId: selectedBranchId && selectedBranchId,
  //     employerId: userDataEmp && userDataEmp.id,
  //     searchParam: "",
  //   };
  //
  //   onAddEmployerJobHistory(clientData).then(() => {
  //     setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    letterTempaltesFilter(employerJobRes);
  }, [employerJobRes]);

  const letterTempaltesFilter = (employerJobRes) => {
    let filterList = [];
    if (
      employerJobRes &&
      employerJobRes.items &&
      employerJobRes.items.length > 0
    ) {
      employerJobRes.items.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
    }
  };

  const removeJob = () => {
    setLoading(true);
    const remove = {
      id: onDelete,
      delete: true,
    };
    onRemoveEmployerJob(remove).then(() => {
      onGetEmployerJob(userDataEmp && userDataEmp.id);
      setLoading(false);
      message.success("Successfully Deleted!");
      setModal1Visible(false);
    });
  };

  const linkJob = () => {
    setLoading(true);
    let _selectedData = [];
    let tempSelectedData = [];

    if (selectedData && selectedData.length > 0) {
      for (let index = 0; index < selectedData.length; index++) {
        _selectedData.push(selectedData[index].id);
        tempSelectedData.push(selectedData[index].id);
      }
    }

    const update = {
      employerId: userDataEmp && userDataEmp.id,
      clientIds: _selectedData && _selectedData,
      jobStatus: jobValue && jobValue.id,
    };

    onLinkEmpWithClient(update).then(() => {
      message.success("Successfully Added!");
      tempSelectedData &&
        tempSelectedData.length > 0 &&
        tempSelectedData.map((data) => {
          const linkJob = {
            clientId: data && data,
            employerId: jobValue && jobValue.employerId,
            jobId: jobValue && jobValue.id,
            jobTitle: jobValue && jobValue.jobTitle,
            startDate: jobValue && jobValue.openDate,
            endDate: "1900-01-01T00:00:00+00",
            employerName: userDataEmp && userDataEmp.name,
            employerAddress: userDataEmp && userDataEmp.address,
            payDetails: "",
            phone: userDataEmp && userDataEmp.mobile,
            managerName: "",
            managerEmail: "",
            managerMobile: "",
            positionOffered: jobValue && jobValue.position,
            jobOffer: "",
            companyRole: "",
            mentionHoursInWeek: "",
            anzscoCode: jobValue && jobValue.anzscoCode,
            skillLevel: jobValue && jobValue.skillLevel,
            businessNumber: "",
            isCurrent: true,
            tradingName: "",
            workStay: "",
            businessType: "",
            employementType: userDataEmp && userDataEmp.employerType,
            streetNumber: "",
            streetName: "",
            suburb: "",
            city: userDataEmp && userDataEmp.city,
            state: "",
            zip: "",
            country: 0,
            website: userDataEmp && userDataEmp.website,
            annualsalary: "",
            ratePerHour: "",
            activityBasedRemuneration: "",
            workExperince: "",
            domicile: "",
            accountDuties: "",
            contactPersonJobTitle: "",
            arrivalDate: "1900-01-01T00:00:00+00",
            jobStatusId: jobValue && jobValue.jobStatusId,
          };
          onAddEmployerJobHistory(linkJob).then(() => {});
          onGetEmployerJob(userDataEmp && userDataEmp.id).then(() => {});
          setIslinkClient(false);
          setLoading(false);
        });

      const clientData = {
        employerId: userDataEmp && userDataEmp.id,
        searchParam: "",
      };
      onGetClientByEmpId(clientData);
    });
  };

  const confirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value);
  };

  const singleCient = (record, onExpand, expanded) => {
    onExpand(record);
    let keys = [];
    keys.push(record.key);
    setExpandedRowsEntriesKeys(keys);
    if (expanded) {
      setExpandedRowsEntriesKeys([]);
    }

    const data = {
      jobId: record && record.id,
    };
    onGetSingleClientHis(data)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const linkConfirmation = (value) => {
    setSelectedData([]);
    setSearchText("");
    setJobValue(value);
    setIslinkClient(!islinkClient);
    setExpandedRowsEntriesKeys([]);
  };

  const searchResult = (searchedResult) =>
    searchedResult.map((_, idx) => {
      let category = `${_.firstName} ${_.lastName}`;

      return {
        value: category,
        label: (
          <div
            className="search-client-card-cont"
            onClick={() => handleSearchData(_)}
          >
            <div>
              <div class="image-search-result">
                <img
                  src={_.imageBlobUrl || Images.dummyUserImage}
                  class="profile-img"
                  style={{ width: 60, height: 60 }}
                />
              </div>
            </div>
            <div>
              <div>
                <span class="search-name">{`${_.firstName} ${_.lastName}`}</span>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="search-text">Client No :</span>
                </div>
                <div>
                  <span class="search-text">{_.clientNumber}</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="search-text">DOB :</span>
                </div>
                <div>
                  <span class="search-text">
                    &nbsp;{moment(_.dateOfBirth).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="search-text">Email :</span>
                </div>
                <div>
                  <span class="search-text">&nbsp;{_.email}</span>
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <div>
                  <span class="search-text">Mobile :</span>
                </div>
                <div>
                  <span class="search-text">&nbsp;{_.mobile}</span>
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });

  const handleSearch = (value) => {
    let _searchText = value;
    setSearchText(_searchText);
    if (_searchText.length >= 2) {
      if (_searchText.trim().length === 0) {
        _searchText = "%20";
      }
      setLoadingTags(true);
      onGetClientSearch(_searchText)
        .then((res) => {
          if (res.payload.clients.length === 0) {
            setLoadingTags(false);
            message.warning("Searched record not found");
            setOptions([]);
          } else {
            let _options = res.payload.clients;
            let searchedResult = searchResult(_options);
            // setOptions(searchResult(res.payload.clients));
            if (_searchText.length < 2) {
              searchedResult = [];
              setOptions(searchedResult);
            }
            setOptions(searchedResult);
            setLoadingTags(false);
          }
        })
        .catch(() => {
          setLoadingTags(false);
        });
    } else {
      setOptions([]);
    }
  };
  const handleSearchData = (value) => {
    setLoading(true);
    setSearchText("");
    setOptions([]);
    setSelectedData((prevState) => [...prevState, value]);
    setSelectedClientId((prevState) => [...prevState, value.id]);
    setArrOfClients((prevState) => [...prevState, value]);
    setLoading(false);
  };

  const removeTag = (item, e) => {
    let tempContacts = [...selectedData];
    let _con = tempContacts.filter((data) => data.id !== item.id);
    // setSelectedData((prevState) => prevState.splice(index, 1));
    setSelectedData(_con);
    // const removeArr = selectedData.splice(index, 1);

    // setSelectedData(removeArr);

    // var array = [item]; // make a separate copy of the array
    // var index = array.indexOf(e);
    // if (index != -1) {
    //   array.splice(index, 1);
    //   setSelectedData({ array });
    // }
  };

  const columns = [
    {
      title: "Job Order No",
      dataIndex: "jobOrderNo",
      key: "jobOrderNo",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.jobOrderNo}
        </p>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.jobTitle}
        </p>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.position}
        </p>
      ),
    },
    {
      title: "Open Date",
      dataIndex: "openDate",
      key: "openDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {yyyymmddFormate(record.openDate)}
        </p>
      ),
    },
    {
      title: "Close Date",
      dataIndex: "closeDate",
      key: "closeDate",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record && record.closeDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.closeDate)}
        </p>
      ),
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.required}
        </p>
      ),
    },
    {
      title: "Contract",
      dataIndex: "contract",
      key: "contract",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.contract}
        </p>
      ),
    },
    {
      title: "Remuneration",
      dataIndex: "remuneration",
      key: "remuneration",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.remuneration}
        </p>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.location}
        </p>
      ),
    },
    {
      title: "ANZSCO Code",
      dataIndex: "anzscoCode",
      key: "anzscoCode",
      width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.anzscoCode}
        </p>
      ),
    },
  ];

  const onSelectClient = (value) => {
    console.log("onSelect", value);
    // setSearchTitle(value);
    // let searchTag = [];
    // console.log(
    //   "selectedDataselectedDataselectedDataselectedData",
    //   selectedData
    // );
    // if (selectedData && selectedData && selectedData.length > 0) {
    //   selectedData.map((data, index) => {
    //     //     if (data) {
    //       data.index = index;
    //       data.key = `${index + 1}`;
    //       searchTag.push(data);
    //     }
    //   });
    //
    //   // setSearchTitle(searchTag);
    // }
  };
  console.log("jobValuejobValuejobValue", jobValue);
  console.log("arrOfClientsarrOfClientsarrOfClients", arrOfClients);

  return (
    <Fragment>
      <div>
        <Table
          expandedRowKeys={expandedRowsEntriesKeys}
          pagination={false}
          className="job-table table-head employer-tables border-3 school-table
          "
          dataSource={employerJobRes && employerJobRes.items}
          columns={columns}
          scroll={{ x: "auto" }}
          expandIconColumnIndex={11}
          expandable={{
            expandedRowRender: (record) => (
              console.log("recordrecord00000000099999999999999==>", record),
              (
                <ClientSingleEmpTable
                  onGetSingleClientHis={onGetSingleClientHis}
                  singleJobHisRes={singleJobHisRes}
                  isjobId={isjobId}
                />
              )
            ),

            expandIcon: ({ expanded, onExpand, record }) => (
              <Fragment>
                <div className="table-action">
                  <div style={{ margin: "2px" }}>
                    <Tooltip placement="topLeft" title={`Link Client`}>
                      <LinkOutlined
                        style={{ fontSize: "15px" }}
                        onClick={() => linkConfirmation(record)}
                      />
                    </Tooltip>
                  </div>
                  <div
                    onClick={() => showModal(record, "update-job")}
                    style={{ margin: "2px" }}
                  >
                    <Tooltip placement="topLeft" title={`Update`}>
                      <EditOutlined style={{ fontSize: "15px" }} />
                    </Tooltip>
                  </div>

                  <div style={{ margin: "2px" }}>
                    <Tooltip placement="topLeft" title={`Client`}>
                      <UserOutlined
                        style={{ fontSize: "15px" }}
                        onClick={(e) => singleCient(record, onExpand, expanded)}
                      />
                    </Tooltip>
                  </div>
                  <div style={{ margin: "2px" }}>
                    <Tooltip placement="topLeft" title={`Delete`}>
                      <DeleteOutlined
                        style={{ fontSize: "15px" }}
                        onClick={() => confirmation(record && record.id)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Fragment>
            ),
          }}
        />
      </div>
      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={() => removeJob()}
        onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
      {islinkClient === true ? (
        <Modal
          title={
            <p style={{ fontFamily: "Poppins", fontSize: "14px" }}>
              LINK CLIENT
            </p>
          }
          style={{ top: "25%" }}
          visible={islinkClient}
          width={904}
          onOk={() => linkJob()}
          onCancel={() => setIslinkClient(false)}
          Header={false}
        >
          <Spin size="large" spinning={loadingTags}>
            <Form
              // onFinish={onFinish}
              // form={form}
              className="emp-content-box p-box add-job-form width-100"
              name="main"
            >
              <Row gutter={10} className="margin-top width-100">
                <Col span={10}>
                  <p style={{ fontSize: 13 }}>Search</p>
                </Col>
                <Col span={14}>
                  <AutoComplete
                    virtual={false}
                    dropdownMatchSelectWidth={252}
                    style={{ height: 32 }}
                    options={options}
                    onSelect={onSelectClient}
                    onSearch={handleSearch}
                    value={searchText}
                  >
                    <Input placeholder="Client" enterButton />
                  </AutoComplete>
                </Col>
              </Row>
              <Row>
                <Col>
                  {selectedData &&
                    selectedData.length > 0 &&
                    selectedData.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={
                            index % 1 === 0 ? "client-tag-btn" : "button-second"
                          }
                          style={{ marginTop: "10px" }}
                          onClick={() => removeTag(item, index)}
                        >
                          <div className="button-first-second-row">
                            <img
                              style={{ cursor: "pointer" }}
                              src={Images.crossWhite}
                              className="btn-cross-icon"
                            />
                            <span className="btn-fst-scnd-text">
                              {item.firstName}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default JobEmployerTable;
