import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Images } from "../Themes";
import jwt_decode from "jwt-decode";
import {
  Spin,
  message,
  Tooltip,
  Modal,
  Switch,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  FileTextOutlined,
  FileTextFilled,
  UserOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

import {
  Table,
  Tag,
  Space,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
} from "antd";
import getUserBranchPermissions from "../Components/getUserBranchPermissions";
import { apiRefresh } from "../services/api";

let IsInsertPermission = true;
const data = [];

const searchIndex = (nameKey, myArray) => {
  let findIndex = myArray.findIndex((obj) => obj.name == nameKey);
  if (findIndex >= 0) {
    return findIndex;
  } else {
    myArray.push({
      name: nameKey,
      status: false,
    });
    return myArray.length - 1;
    // searchIndex(nameKey, myArray);
  }
  // for (var i = 0; i < myArray.length; i++) {
  //   if (myArray[i].name == nameKey) {
  //     return i;
  //   }
  // }
};

const BranchDetail = ({
  props,
  onGetBranchDetail,
  branchDetailRes,
  updateBranchDetail,
  impersonateRes,
  onUserLockUnlock,
  onImpersonate,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [userData, setUserData] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLock, setIsLock] = useState(false);
  const [branchUser, setBranchUser] = useState("");
  const [isLockButtonPressed, setLockButtonPressed] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleModalUserLockUnlock, setVisibleModalUserLockUnlock] = useState(
    false
  );
  const [
    visibleModalUserActiveDeactive,
    setVisibleModalUserActiveDeactive,
  ] = useState(false);
  const [visibleModalUserDelete, setVisibleModalUserDelete] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  const { Option } = Select;
  const [form] = Form.useForm();
  const [userForm] = Form.useForm();

  var permissions = JSON.parse(localStorage.getItem("adminPermissions"));
  let [branchPermissions, setBranchPermission] = useState([
    {
      name: "FreeTrial",
      status: false,
    },
    {
      name: "BulkEmail",
      status: false,
    },
    {
      name: "Deals",
      status: false,
    },
    {
      name: "CaseManagement",
      status: false,
    },
    {
      name: "School",
      status: false,
    },
    {
      name: "Employer",
      status: false,
    },
    {
      name: "Agent",
      status: false,
    },
    {
      name: "CustomQuestionnaire",
      status: false,
    },
    {
      name: "TimeTracking",
      status: false,
    },
    {
      name: "CoverGenius",
      status: false,
    },
  ]);
  let BranchUsers = [];
  if (branchDetailRes && branchDetailRes.branchUser) {
    branchDetailRes.branchUser.forEach((element) => {
      var user = new Object();
      user.id = element.user.id;
      user.fullName = element.user.fullName;
      user.title = element.user.title;
      user.alternateEmail = element.user.alternateEmail;
      user.user = element.user;

      BranchUsers.push(user);
    });
  }

  useEffect(() => {
    if (branchDetailRes) {
      var findPermission =
        branchDetailRes.permissions &&
        branchDetailRes.permissions.branchPermission &&
        branchDetailRes.permissions.branchPermission.find(
          (obj) => obj.name == "CoverGenius"
        );
      // if (findPermission.length > 0) {
      //   for (var i = 0; i < findPermission.length; i++) {
      //     branchDetailRes.permissions.branchPermission.splice(i, 1);
      //   }
      // }
      if (!findPermission && branchDetailRes.permissions.branchPermission) {
        branchDetailRes.permissions.branchPermission.push({
          id: null,
          name: "CoverGenius",
          status: false,
        });
        var data = {
          id: branchDetailRes.id,
          companyId: branchDetailRes && branchDetailRes.companyId,
          name: branchDetailRes.name,
          description: branchDetailRes && branchDetailRes.description,
          address: branchDetailRes && branchDetailRes.address,
          addressLine1: branchDetailRes && branchDetailRes.addressLine1,
          addressLine2: branchDetailRes && branchDetailRes.addressLine2,
          bankAccountTitle: branchDetailRes && branchDetailRes.bankAccountTitle,
          bankAccountNumber:
            branchDetailRes && branchDetailRes.bankAccountNumber,
          gstNumber: branchDetailRes && branchDetailRes.gstNumber,
          gstPercent: branchDetailRes && branchDetailRes.gstPercent,
          city: branchDetailRes && branchDetailRes.city,
          country: branchDetailRes && branchDetailRes.country,
          zipcode: branchDetailRes && branchDetailRes.zipcode,
          ownerId: branchDetailRes && branchDetailRes.ownerId,
          phone1: branchDetailRes && branchDetailRes.phone1,
          phone2: branchDetailRes && branchDetailRes.phone2,
          mobile: branchDetailRes && branchDetailRes.mobile,
          fax: branchDetailRes && branchDetailRes.fax,
          email: branchDetailRes && branchDetailRes.email,
          logo: branchDetailRes && branchDetailRes.logo,
          status: branchDetailRes && branchDetailRes.status,
          currency: branchDetailRes && branchDetailRes.currency,
          allowedUsers: branchDetailRes && branchDetailRes.allowedUsers,
          allowSupervisor: branchDetailRes && branchDetailRes.allowSupervisor,
          contractSignedDate:
            branchDetailRes.contractSignedDate || "1900-01-01T00:00:00+00:00",
          contractExpiryDate:
            branchDetailRes.contractExpiryDate || "1900-01-01T00:00:00+00:00",
          contractUrl: branchDetailRes && branchDetailRes.contractUrl,
          storageLimit: branchDetailRes.storageLimit || 0,
          maxToken: branchDetailRes.maxToken || 0,
          notes: branchDetailRes && branchDetailRes.notes,
          permission: {
            branchId: branchDetailRes && branchDetailRes.id,
            branchPermission: branchDetailRes.permissions.branchPermission,
            isInsert: IsInsertPermission,
          },
        };
        updateBranchDetail(data)
          .then((res) => {
            // form.setFieldsValue({
            //   allowedUsers: value && value.allowedUsers,
            // });
            onGetBranchDetail(location.state && location.state.BranchId)
              .then((data) => {
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  }, [branchDetailRes]);

  const UpdatePermission = (value, index) => {
    let newArr = [...branchPermissions];
    newArr[index].status = value;
    if (newArr[index].name == "FreeTrial") {
      for (var i = 0; i < newArr.length; i++) {
        newArr[i].status = value;
      }
    }
    setBranchPermission(newArr);
  };

  const updateBranch = (value) => {
    setLoading(true);
    var data = {
      id: branchDetailRes.id,
      companyId: branchDetailRes && branchDetailRes.companyId,
      name: value.name,
      description: branchDetailRes && branchDetailRes.description,
      address: branchDetailRes && branchDetailRes.address,
      addressLine1: branchDetailRes && branchDetailRes.addressLine1,
      addressLine2: branchDetailRes && branchDetailRes.addressLine2,
      bankAccountTitle: branchDetailRes && branchDetailRes.bankAccountTitle,
      bankAccountNumber: branchDetailRes && branchDetailRes.bankAccountNumber,
      gstNumber: branchDetailRes && branchDetailRes.gstNumber,
      gstPercent: branchDetailRes && branchDetailRes.gstPercent,
      city: branchDetailRes && branchDetailRes.city,
      country: branchDetailRes && branchDetailRes.country,
      zipcode: branchDetailRes && branchDetailRes.zipcode,
      ownerId: branchDetailRes && branchDetailRes.ownerId,
      phone1: branchDetailRes && branchDetailRes.phone1,
      phone2: branchDetailRes && branchDetailRes.phone2,
      mobile: branchDetailRes && branchDetailRes.mobile,
      fax: branchDetailRes && branchDetailRes.fax,
      email: branchDetailRes && branchDetailRes.email,
      logo: branchDetailRes && branchDetailRes.logo,
      status: branchDetailRes && branchDetailRes.status,
      currency: branchDetailRes && branchDetailRes.currency,
      allowedUsers: value && value.allowedUsers,
      allowSupervisor: branchDetailRes && branchDetailRes.allowSupervisor,
      contractSignedDate:
        value.contractSignedDate || "1900-01-01T00:00:00+00:00",
      contractExpiryDate:
        value.contractExpiryDate || "1900-01-01T00:00:00+00:00",
      contractUrl: branchDetailRes && branchDetailRes.contractUrl,
      storageLimit: value.storageLimit || 0,
      maxToken: value.maxToken || 0,
      notes: branchDetailRes && branchDetailRes.notes,
      permission: {
        branchId: branchDetailRes && branchDetailRes.id,
        branchPermission: branchPermissions,
        isInsert: IsInsertPermission,
      },
    };

    updateBranchDetail(data)
      .then((res) => {
        form.setFieldsValue({
          allowedUsers: value && value.allowedUsers,
        });
        onGetBranchDetail(location.state && location.state.BranchId)
          .then((data) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onlockBranchUser = () => {
    setLoading(true);
    let _data = {
      userId: branchUser,
      isLock: isLockButtonPressed,
    };

    onUserLockUnlock(_data)
      .then(() => {
        setLoading(false);
        onGetBranchDetail(location.state && location.state.BranchId)
          .then((data) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        isLockButtonPressed
          ? message.success("User is locked successfully!")
          : message.success("User is unlocked successfully!");
        setVisibleModalUserLockUnlock(false);
      })
      .catch(() => {
        setLoading(false);
        setVisibleModalUserLockUnlock(false);
      });
  };

  const onActiveBranchUser = () => {
    setLoading(true);
    let _data = {
      userId: branchUser,
      isActive: !!isLockButtonPressed,
    };
    const histOpt = {
      url: `v1/admin/branch/User/ActiveDeActiveUser`,
    };
    histOpt.types = [
      "USER_ACTIVE_DEACTIVE_SUCCESS",
      "USER_ACTIVE_DEACTIVE_FAILURE",
    ];

    apiRefresh
      .post(histOpt, _data)
      .then(() => {
        setLoading(false);
        isLockButtonPressed
          ? message.success("User is activated successfully!")
          : message.success("User is deactivated successfully!");
        setVisibleModalUserActiveDeactive(false);
      })
      .catch(() => {
        setLoading(false);
        setVisibleModalUserActiveDeactive(false);
      });
  };

  const onDeleteBranchUser = () => {
    setLoading(true);
    let _data = {
      userId: branchUser,
      isDelete: isDelete,
    };
    const histOpt = {
      url: `v1/admin/branch/User/DeleteUser`,
    };
    histOpt.types = ["USER_DELETE_SUCCESS", "USER_DELETE_FAILURE"];

    apiRefresh
      .delete(histOpt, _data)
      .then(() => {
        // setLoading(false);
        message.success("User is deleted successfully!");
        onGetBranchDetail(location.state && location.state.BranchId)
          .then((data) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        setVisibleModalUserDelete(false);
      })
      .catch(() => {
        setLoading(false);
        setVisibleModalUserDelete(false);
      });
  };

  const handleCancelVisibleModalUserLockUnlock = () => {
    setVisibleModalUserLockUnlock(false);
    setVisibleModalUserActiveDeactive(false);
    setVisibleModalUserDelete(false);
  };

  const getModalForUserLockUnlock = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={visibleModalUserLockUnlock}
          onCancel={handleCancelVisibleModalUserLockUnlock}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              {isLockButtonPressed ? (
                <Row> Are you sure, you want to lock user?</Row>
              ) : (
                <Row> Are you sure, you want to unlock user?</Row>
              )}
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  handleCancelVisibleModalUserLockUnlock();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                style={{ marginLeft: "12px" }}
                className={"button"}
                onClick={() => {
                  onlockBranchUser();
                }}
              >
                Ok
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  const getModalForUserDelete = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={visibleModalUserDelete}
          onCancel={handleCancelVisibleModalUserLockUnlock}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                {!isDelete
                  ? "Are you sure, you want to undo deleted user?"
                  : "Are you sure, you want to delete user?"}
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  handleCancelVisibleModalUserLockUnlock();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                style={{ marginLeft: "12px" }}
                className={"button"}
                onClick={() => {
                  onDeleteBranchUser();
                }}
              >
                Ok
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  const ImpersonateUser = (id) => {
    onImpersonate(id).then((res) => {
      let admintoken = localStorage.getItem("admintoken");
      let userEmail = localStorage.getItem("userEmail");
      let userName = localStorage.getItem("userName");
      let userId = localStorage.getItem("userId");
      let refreshToken = localStorage.getItem("refreshToken");

      localStorage.setItem("_admintoken", admintoken);
      localStorage.setItem("_userEmail", userEmail);
      localStorage.setItem("_userName", userName);
      localStorage.setItem("_userId", userId);
      localStorage.setItem("_refreshToken", refreshToken);

      localStorage.removeItem("admintoken");
      localStorage.setItem("token", res.payload.accessToken);
      localStorage.setItem("refreshToken", res.payload.refreshToken);

      let token = localStorage.getItem("token");
      var jwtDecoded = jwt_decode(res.payload.accessToken);
      localStorage.setItem("userId", jwtDecoded.sub);
      localStorage.setItem("userEmail", jwtDecoded.email);
      localStorage.setItem("userOwner", jwtDecoded.IsOwner);
      localStorage.setItem("selectedBranchId", jwtDecoded.BranchId);
      localStorage.setItem("companyId", jwtDecoded.Com);
      localStorage.setItem("userName", jwtDecoded.FullName);
      // localStorage.setItem("UserSystemPermissions", JSON.parse(jwtDecoded.UserSystemPermissions));
      getUserBranchPermissions();
      // window.location = "/dashboardBI";
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            showUserModal(record.user);
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "alternateEmail",
      key: "alternateEmail",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Last Active",
      dataIndex: "user.lastSignedIn",
      key: "user.lastSignedIn",
      render: (text, record) => {
        return (
          <div>
            <span>
              {moment(record && record.user && record.user.lastSignedIn).format(
                "DD/MM/YYYY"
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Role",
      render: (text, record) => {
        return (
          <div>
            <span>
              {record && record.user && record.user.isOwner ? "Owner" : "User"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "user.userContact.mobileNumber",
      key: "user.userContact.mobileNumber",
      render: (text, record) => {
        return (
          <div>
            <span>{record.user.userContact.mobileNumber}</span>
          </div>
        );
      },
    },
    {
      title: "Impersonate",
      dataIndex: "Impersonate",
      key: "Impersonate",
      render: (text, record) => (
        <div style={{ display: "inline-block" }}>
          {permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "ImpersonateUser") &&
            permissions.find((obj) => obj.Module === "ImpersonateUser")
              .Permisions && (
              <a
                style={{ marginRight: "5px", display: "inline-block" }}
                onClick={() => {
                  ImpersonateUser(record.id);
                }}
              >
                Impersonate
              </a>
            )}
          {!record.user.isLock ? (
            permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "LockUser") &&
            permissions.find((obj) => obj.Module === "LockUser").Permisions ? (
              <a
                style={{ marginRight: "5px", display: "inline-block" }}
                onClick={() => {
                  setLockButtonPressed(true);
                  setVisibleModalUserLockUnlock(true);
                  setBranchUser(record.id);
                  // setLockButtonPressed(record.isLock);
                }}
              >
                Lock
              </a>
            ) : null
          ) : (
            permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "UnLockUser") &&
            permissions.find((obj) => obj.Module === "UnLockUser")
              .Permisions && (
              <a
                style={{ marginRight: "5px", display: "inline-block" }}
                onClick={() => {
                  setLockButtonPressed(false);
                  setVisibleModalUserLockUnlock(true);
                  setBranchUser(record.id);
                  // setLockButtonPressed(record.isLock);
                }}
              >
                Unlock
              </a>
            )
          )}{" "}
          {/* <a
            style={{ marginRight: "5px", display: "inline-block" }}
            onClick={() => {
              setActiveButtonPressed(true);
              setVisibleModalUserActiveDeactive(true);
              setBranchUser(record.id);
            }}
          >
            Active
          </a>
          <a
            style={{ marginRight: "5px", display: "inline-block" }}
            onClick={() => {
              setActiveButtonPressed(false);
              setVisibleModalUserActiveDeactive(true);
              setBranchUser(record.id);
            }}
          >
            Deactive
          </a>{" "} */}
          {permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "EditUser") &&
            permissions.find((obj) => obj.Module === "EditUser").Permisions && (
              <a
                style={{ marginRight: 5, display: "inline-block" }}
                onClick={() => {
                  showUserModal(record.user);
                }}
              >
                Edit
              </a>
            )}
          {record.user.isDeleted ? (
            permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "UndoUser") &&
            permissions.find((obj) => obj.Module === "UndoUser").Permisions ? (
              <a
                style={{ marginRight: 5, display: "inline-block" }}
                onClick={() => {
                  setVisibleModalUserDelete(true);
                  setBranchUser(record.id);
                  setIsDelete(!record.user.isDeleted);
                }}
              >
                Undo
              </a>
            ) : null
          ) : (
            permissions.length > 0 &&
            permissions.find((obj) => obj.Module === "DeleteUser") &&
            permissions.find((obj) => obj.Module === "DeleteUser")
              .Permisions && (
              <a
                style={{ marginRight: 5, display: "inline-block" }}
                onClick={() => {
                  setVisibleModalUserDelete(true);
                  setBranchUser(record.id);
                  setIsDelete(!record.user.isDeleted);
                }}
              >
                Delete
              </a>
            )
          )}
          {/* <a
            style={{ display: "inline-block" }}
            // onClick={() => {
            //   setVisibleModalUserDelete(true);
            //   setBranchUser(record.id);
            // }}
          >
            Undo
          </a> */}
        </div>
      ),
    },
  ];

  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    onGetBranch();
  }, [location.state, onGetBranchDetail]);

  const onGetBranch = () => {
    onGetBranchDetail(location.state && location.state.BranchId)
      .then((data) => {
        setLoading(false);

        if (
          data.payload &&
          data.payload.permissions &&
          data.payload.permissions.branchPermission
        ) {
          IsInsertPermission = false;
          setBranchPermission(data.payload.permissions.branchPermission);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  form.setFieldsValue({
    name: branchDetailRes && branchDetailRes.name,
    allowedUsers: branchDetailRes && branchDetailRes.allowedUsers,
    actualUsers: branchDetailRes && branchDetailRes.branchUser.length,
    storageLimit: branchDetailRes && branchDetailRes.storageLimit,
    maxToken: branchDetailRes && branchDetailRes.maxToken,
    storageConsumed: branchDetailRes && branchDetailRes.storageConsumed,
    consumedToken: branchDetailRes && branchDetailRes.consumedToken,
    remainingToken: branchDetailRes && branchDetailRes.remainingToken,
    contractSignedDate:
      branchDetailRes &&
      branchDetailRes.contractSignedDate !== "1900-01-01T00:00:00+00:00"
        ? moment(branchDetailRes.contractSignedDate)
        : "",
    contractExpiryDate:
      branchDetailRes &&
      branchDetailRes.contractExpiryDate !== "1900-01-01T00:00:00+00:00"
        ? moment(branchDetailRes.contractExpiryDate)
        : "",
  });

  const handleChange = (selectedOption) => {
    setSelectedOption(parseInt(selectedOption));
  };

  const showUserModal = (data) => {
    setIsModalVisible(true);
    setUserData(data);
    setIsLock(data.isLock);
    userForm.setFieldsValue({
      fullName: data.fullName || "",
      alternateEmail: data.alternateEmail || "",
      // address1: data.userAddress.address1 || "",
      // mobileNumber: data.userContact.mobileNumber || "",
      roleId: data.roleId == 0 ? data.roleId : "",
      title: data.title || "",
      isOwner: data.isOwner || false,
      isManager: data.isManager || false,
      isLock: data.isLock || false,
    });
  };

  const onFinish = (values) => {
    let data = {
      id: userData.id,
      roleId: userData.roleId,
      title: values.title,
      image: userData.image,
      forgotPasswordBit: userData.forgotPasswordBit,
      lastSignedIn: userData.lastSignedIn,
      signature: userData.signature,
      signatureImage: userData.signatureImage,
      timeZone: userData.timeZone,
      comission: userData.comission,
      agreementUrl: userData.agreementUrl,
      description: userData.description,
      fullName: values.fullName,
      alternateEmail: values.alternateEmail,
      status: userData.status,
      isManager: userData.isManager,
      isOwner: values.isOwner,
    };
    setLoading(true);

    const branchUserOpt = {
      url: `v1/admin/branch/User`,
    };
    branchUserOpt.types = [
      "UPDATE_BRANCH_USER_SUCCESS",
      "UPDATE_BRANCH_USER_FAILURE",
    ];

    apiRefresh
      .put(branchUserOpt, data)
      .then((res) => {
        message.success("User data updated successfully!");
        onGetBranch();
        setLoading(false);
        userForm.resetFields();
        setIsModalVisible(false);
      })
      .catch((err) => {});
  };

  const changePassword = () => {
    if (newPassword == confirmPassword && newPassword == "") {
      message.error("New password and confirm password must be same");
      return;
    }
    let data = {
      userId: userData.id,
      email: userData.alternateEmail,
      password: newPassword,
    };
    const branchUserOpt = {
      url: `v1/admin/branch/User/ChangePassword`,
    };
    branchUserOpt.types = [
      "CHANGE_PASSWORD_SUCCESS",
      "CHANGE_PASSWORD_FAILURE",
    ];

    setLoading(true);
    apiRefresh
      .post(branchUserOpt, data)
      .then((res) => {
        message.success("Password changed successfuly!");
        setLoading(false);
      })
      .catch((err) => {
        message.error("Failed!");
        setLoading(false);
      });
  };

  const onLockUser = (e) => {
    let data = {
      userId: userData.id,
      isLock: e,
    };
    setLoading(true);

    const branchUserOpt = {
      url: `v1/admin/branch/User/LockUnLockUser`,
    };
    branchUserOpt.types = [
      "UPDATE_BRANCH_USER_SUCCESS",
      "UPDATE_BRANCH_USER_FAILURE",
    ];

    apiRefresh.post(branchUserOpt, data).then((res) => {
      setIsLock(e);
      setLoading(false);
      onGetBranch();
      message.success("Updated!");
    });
  };
  return (
    <Spin spinning={loading}>
      {getModalForUserLockUnlock()}
      {getModalForUserDelete()}
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <Form form={form} onFinish={updateBranch}>
              <div
                className="denied-cont"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="denied-cont"
                  style={{ padding: 0, alignItems: "center" }}
                >
                  <div className="profile-down-arrow-cont">
                    <img
                      src={Images.whiteArrow}
                      className="profile-down-arrow-icon"
                    />
                  </div>
                  <span className="denied-text">Branch Detail</span>
                </div>
                <Form.Item>
                  <Button
                    className="button-blue"
                    htmlType="submit"
                    disabled={
                      permissions.length > 0 &&
                      permissions.find(
                        (obj) => obj.Module === "BranchDetailEdit"
                      ) &&
                      !permissions.find(
                        (obj) => obj.Module === "BranchDetailEdit"
                      ).Permisions
                    }
                  >
                    <span style={{ color: "#FFFFFF" }}>Update</span>
                  </Button>
                </Form.Item>
              </div>

              <div className="form-container">
                <div
                  className="form-cont"
                  style={{
                    justifyContent: "space-between",
                    paddingTop: 20,
                    paddingBottom: 0,
                    display: "block",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="medical-label">Name</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="name" style={{ width: "100%" }}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Package</p>
                    <div style={{ width: "48%", paddingLeft: 1 }}>
                      <div>
                        <Form.Item name="Package">
                          <Select showSearch optionFilterProp="children">
                            <Option value="1">Free</Option>
                            <Option value="2">Lite</Option>
                            <Option value="3">Pro</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Referal Share</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="Referal" style={{ width: "100%" }}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">User Limit</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item
                        name="allowedUsers"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (value === 0 || value < 0) {
                                return Promise.reject(
                                  "There should be atleast one user limit"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                          // {
                          //   type: 'number',
                          //   max: 100,
                          //   message: "user limit should be less than equal to 100",
                          // },
                        ]}
                      >
                        <InputNumber defaultValue={1} />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Actual Users</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="actualUsers" style={{ width: "100%" }}>
                        <Input disabled="true" />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Storage Limit (GB)</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="storageLimit" style={{ width: "100%" }}>
                        <InputNumber />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Storage Consumed (MB)</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item
                        name="storageConsumed"
                        style={{ width: "100%" }}
                      >
                        <Input disabled="true" />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Token Limit</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="maxToken" style={{ width: "100%" }}>
                        <InputNumber />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Token Consumed</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item name="consumedToken" style={{ width: "100%" }}>
                        <Input disabled="true" />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Pending Token</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item
                        name="remainingToken"
                        style={{ width: "100%" }}
                      >
                        <Input disabled="true" />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Agreement Signed Date</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item
                        name="contractSignedDate"
                        style={{ width: "100%" }}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label">Agreement Expiry Date</p>
                    <div
                      style={{
                        display: "flex",
                        border: "none",
                        width: "48%",
                      }}
                    >
                      <Form.Item
                        name="contractExpiryDate"
                        style={{ width: "100%" }}
                      >
                        <DatePicker format={dateFormat} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div style={{ width: "50%" }}>
            <div className="form-container" style={{ display: "inline-block" }}>
              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Free Trial</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("FreeTrial", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("FreeTrial", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Bulk Email</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("BulkEmail", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("BulkEmail", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Deals</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("Deals", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("Deals", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Supervisor</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("Supervisor", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("Supervisor", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div> */}

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Case Management</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("CaseManagement", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("CaseManagement", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">School</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("School", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("School", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Employer</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("Employer", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("Employer", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Agent</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("Agent", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("Agent", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Custom Questionnaire</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("CustomQuestionnaire", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("CustomQuestionnaire", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Time Tracking</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("TimeTracking", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("TimeTracking", branchPermissions)
                        ].status
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 0,
                  display: "inline-block",
                }}
              >
                <div clssName="">
                  <p className="medical-label">Cover Genius</p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "48%",
                    }}
                  >
                    <Switch
                      onChange={(value) => {
                        UpdatePermission(
                          value,
                          searchIndex("CoverGenius", branchPermissions)
                        );
                      }}
                      checked={
                        branchPermissions[
                          searchIndex("CoverGenius", branchPermissions)
                        ]
                          ? branchPermissions[
                              searchIndex("CoverGenius", branchPermissions)
                            ].status
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table columns={columns} dataSource={BranchUsers} pagination={false} />
      </div>
      <Modal
        title="User Detail"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        header={false}
        footer={false}
      >
        <Spin spinning={loading}>
          <Form onFinish={onFinish} form={userForm}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">User Name</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="fullName">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">User Email</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="alternateEmail">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">Title</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="title">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 20,
                // marginRight: -50,
              }}
            >
              {/* <div className="sus-checkbox-cont">
                <Form.Item name="isManager" valuePropName="checked">
                  <Checkbox>Branch Manager</Checkbox>
                </Form.Item>
              </div> */}

              <div className="sus-checkbox-cont">
                <Form.Item name="isOwner" valuePropName="checked">
                  <Checkbox>Owner</Checkbox>
                </Form.Item>
              </div>
            </div>

            {/*<div*/}
            {/*  style={{*/}
            {/*    display: "flex",*/}
            {/*    border: "none",*/}
            {/*    width: "48%",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  /!* <Form.Item> *!/*/}
            {/*  <Switch checked={isLock} onChange={(e) => onLockUser(e)} />*/}
            {/*  /!* </Form.Item> *!/*/}
            {/*</div>*/}

            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">Address</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="address1">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">Phone Number</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="mobileNumber">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div> */}

            <div>
              <div className="profile-input-border" style={{ marginTop: 10 }}>
                <input
                  className="profile-input"
                  placeholder="Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div className="profile-input-border" style={{ marginTop: 10 }}>
                <input
                  className="profile-input"
                  placeholder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Button
                  onClick={changePassword}
                  type="primary"
                  className="sus-save-btn"
                >
                  CHANGE PASSWORD
                </Button>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item>
                <Button
                  loading={loading}
                  //   onClick={() => this.setState({ clickedButton: "save" })}
                  htmlType="submit"
                  name="save"
                  className="button-blue"
                  style={{ marginTop: 20, marginRight: 10 }}
                >
                  SAVE
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => setIsModalVisible(false)}
                  // htmlType="submit"
                  name="save"
                  className="button-blue"
                  style={{ marginTop: 20, marginRight: 10 }}
                >
                  Close
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </Spin>
  );
};
export default BranchDetail;
