import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal } from "antd";
import ClientQuestionnaire from "../../Components/Questionnaire/ClientsQuestionnaire";

const { TabPane } = Tabs;

const PotentialClientQuestionnaire = ({
  userDataEmp,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  // APIS START FROM

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  potentialClientInfo,

  onGetSignature,
  onGetDocuments,

  onAddPotentialEmail,
  onGetPClientHistory,
  potentialClientHistory,
  onGetPdf,
  onGetImapForAll,
  imapForAllRes,
  signatureRes,
  onAddEmailDocument,
  documentRes,
  onGetEmployerDocument,
  employerDocumentRes,
  onGetDocumentDownload,
  onGetAllQuestionnaire,
  onCreateDynamicLink,
  onGetDynamicLink,
  onGetEmailTemplate,
  onSetEmailTemplate,
  onGetQuestionnaire,
  onUpdateQuestionnaire,
  onGetMappingParent,
  onGetMappingChild,
  onGetQuestionnaireGroup,
  onGetGroupedQuestionnaire,
  onGetFilledQuestionnaire,
  onGetAllFilledQuestionnaire,
  onGetCountries,
  questionnaireList,
  dynamicLinkSuccess,
  dynamicLinkData,
  emailTemplateData,
  setEmailData,
  clientTab,
  countriesData,
  questionnaireData,
  mappParentData,
  mappChildData,
  questionnaireGroupRes,
  groupedQuestionnaireRes,
  filledQuestionnaireRes,
  allFilledQuestionnaireRes,
  mergeFromClientRes,
  updMergeFromClientRes,
  updMergeToClientRes,
  onMergeFromClient,
  onUpdMergeFromClient,
  onUpdMergeToClient,
  onGetVisaType,
  visaTypeData,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [handleKey, setHandleKey] = useState("1");

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callback = (key) => {
    // console.log(key);

    setHandleKey(key);
  };

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div className="ts-container-Pclient width-100">
          <p style={{ marginBottom: "8px" }}>
            {potentialClientInfo && potentialClientInfo.firstName
              ? `Name: ${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`
              : `Add a New Potential Client`}
          </p>
          <ClientQuestionnaire
            clientId={potentialClientInfo.id}
            clientName={
              potentialClientInfo.firstName + " " + potentialClientInfo.lastName
            }
            onGetAllQuestionnaire={onGetAllQuestionnaire}
            onCreateDynamicLink={onCreateDynamicLink}
            onGetDynamicLink={onGetDynamicLink}
            onGetEmailTemplate={onGetEmailTemplate}
            onSetEmailTemplate={onSetEmailTemplate}
            onGetQuestionnaire={onGetQuestionnaire}
            onUpdateQuestionnaire={onUpdateQuestionnaire}
            onGetMappingParent={onGetMappingParent}
            onGetMappingChild={onGetMappingChild}
            onGetQuestionnaireGroup={onGetQuestionnaireGroup}
            onGetGroupedQuestionnaire={onGetGroupedQuestionnaire}
            onGetFilledQuestionnaire={onGetFilledQuestionnaire}
            onGetAllFilledQuestionnaire={onGetAllFilledQuestionnaire}
            questionnaireList={questionnaireList}
            dynamicLinkSuccess={dynamicLinkSuccess}
            dynamicLinkData={dynamicLinkData}
            emailTemplateData={emailTemplateData}
            setEmailData={setEmailData}
            clientTab={clientTab}
            countriesData={countriesData}
            questionnaireData={questionnaireData}
            mappParentData={mappParentData}
            mappChildData={mappChildData}
            questionnaireGroupRes={questionnaireGroupRes}
            groupedQuestionnaireRes={groupedQuestionnaireRes}
            filledQuestionnaireRes={filledQuestionnaireRes}
            allFilledQuestionnaireRes={allFilledQuestionnaireRes}
            mergeFromClientRes={mergeFromClientRes}
            updMergeFromClientRes={updMergeFromClientRes}
            updMergeToClientRes={updMergeToClientRes}
            onGetCountries={onGetCountries}
            onMergeFromClient={onMergeFromClient}
            onUpdMergeFromClient={onUpdMergeFromClient}
            onUpdMergeToClient={onUpdMergeToClient}
            isPotential={true}
            onSendEmailLink={onSendEmailLink}
            onGetSignature={onGetSignature}
            signatureRes={signatureRes}
            onGetVisaType={onGetVisaType}
            visaTypeData={visaTypeData}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PotentialClientQuestionnaire;
