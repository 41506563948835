import React from "react";
import { message, Spin, Button, Upload, Select, Input } from "antd";
import FileDownload from "js-file-download";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";

import TeamMemberCard from "../Components/SuperUserSetting/TeamMemberCard";
import { apiRefresh } from "../services/api";

import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const headOption = [
  {
    tabName: "Organization Settings",
    linkName: "/super-user-setting?activeTab=company-information",
  },
  // { tabName: "ADD TEAM MEMBER", linkName: "/team-member" },
  { tabName: "Branch Settings", linkName: "/branch-settings" },
];
var userOptions = [];

var stopLoader = false;
var findIndex = null;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class AddBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      password: "",
      name: "",
      email: "",
      country: "",
      city: "",
      address: "",
      phone: "",
      bnkAccTitle: "",
      bnkAccNumber: "",
      gstNumber: "",
      gstPercent: "",
      DefaultCurrency: "",
      managerId: null,
      selectedOption: null,
      saveLoad: false,
      currencies: [],
      userOptionsArray: [],
      countriesArray: [],
      defaultCountry: "",
      logoUrl: "",
    };
    this.props.onGetTeamMember();
    this.props.onGetCountries();
    const currenciesOpt = {
      url: `v1/currency/GetAllCurrencies`,
    };
    currenciesOpt.types = [
      "GET_ALL_CURRENCIES_SUCCESS",
      "GET_ALL_CURRENCIES_FAILURE",
    ];

    apiRefresh
      .get(currenciesOpt)
      .then((res) => {
        let currencyArray = [];
        if (res && res.items.length > 0) {
          for (var i = 0; i < res.items.length; i++) {
            currencyArray.push(
              <Option value={res.items[i].id}>
                {res.items[i].currencyName}
              </Option>
            );
          }
          this.setState({ currencies: currencyArray });
        }
      })
      .catch((err) => {});
  }

  componentDidUpdate(prevProps) {
    console.log("show add success value", this.props.addTeamMemberSuccess);

    // if (prevProps.countriesData != this.props.countriesData) {
    //   let country = [];
    //   if (this.props.countriesData.items.length > 0) {
    //     for (let i = 0; i < Props.countriesData.items.length; i++) {
    //       country.push(
    //         <Option key={Props.countriesData.items[i].id}>
    //           {Props.countriesData.items[i].name}
    //         </Option>
    //       );
    //     }
    //     this.setState({ countriesArray: country });
    //   }
    // }

    if (prevProps.updManagerSuccess !== this.props.updManagerSuccess) {
      //
      // stopLoader = true;
    }
    if (prevProps.teamMembers !== this.props.teamMembers) {
      if (
        this.props.teamMembers.users &&
        this.props.teamMembers.users.length > 0
      )
        userOptions = [];
      for (var i = 0; i < this.props.teamMembers.users.length; i++) {
        // if (this.props.teamMembers.users[i].isManager) {
        userOptions.push(
          <Option value={this.props.teamMembers.users[i].id}>
            {this.props.teamMembers.users[i].fullName}
          </Option>
        );
        // }
      }
      this.setState({ userOptionsArray: userOptions });
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  getReminderSettings = () => {
    this.props
      .onGetReminderSettings()
      .then((res) => {})
      .catch((err) => {
        if (err.payload.response.status == 404) {
          let data = {
            AdvertisingExpiry: "30",
            Followup: "30",
            ReminderSetting: "1",
            SkillMateExpiry: "30",
            XrayExpiry: "30",
            medicalcertexpiry: "30",
            passportExpiry: "30",
            policecertexpiry: "30",
            ppireminder: "21",
            tcreminder: "30",
            visareminder: "30",
            ReminderEmail: "",
          };
          var myJson = JSON.stringify(data);
          let req = {
            id: 0,
            settingsJson: myJson,
          };
          this.props
            .onAddReminderSettings(req)
            .then((res) => {
              // message.success("Updated!");
              this.getReminderSettings();
              this.setState({ saveLoad: false });
            })
            .catch((err) => {
              this.setState({ saveLoad: false });
              message("Failed!");
            });
        }
      });
  };

  onSubmit = () => {
    var userId = localStorage.getItem("userId");
    var companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.name.length < 3) {
      message.warning(
        "Please enter branch name with atleast three characters!"
      );
    } else if (!emailRegex.test(this.state.email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!this.state.managerId) {
      message.warning("Please select manager first!");
    } else {
      this.setState({
        saveLoad: true,
      });
      let data = {
        name: this.state.name,
        description: "",
        address: this.state.address,
        addressLine1: "",
        addressLine2: "",
        city: this.state.city,
        country: this.state.country,
        zipcode: "",
        ownerId: 0,
        phone1: this.state.phone,
        phone2: "",
        mobile: "",
        fax: "",
        email: this.state.email,
        logo: this.state.logoUrl,
        status: "",
        currency:
          (this.state.selectedOption && this.state.selectedOption.toString()) ||
          "106",
        allowedUsers: 0,
        allowSupervisor: true,
        contractSignedDate: "1900-01-01T00:00:00+00",
        contractExpiryDate: "1900-01-01T00:00:00+00",
        contractUrl: "",
        storageLimit: 0,
        notes: "",
        managerId: this.state.managerId ? this.state.managerId : null,
        bankAccountTitle: this.state.bnkAccTitle,
        bankAccountNumber: this.state.bnkAccNumber,
        gstNumber: this.state.gstNumber,
        gstPercent: this.state.gstPercent || "0",
        defaultCountry: this.state.defaultCountry
          ? parseInt(this.state.defaultCountry)
          : 0,
      };

      this.props
        .onAddBranch(data)
        .then((res) => {
          message.success("Branch added successfully!");
          let data = {
            branchId: res.payload.id,
            status: 1,
            userId: this.state.managerId,
            branchDesignation: findIndex[0].title,
            isManager: true,
          };

          this.props.onAssignBranch(data);

          if (userId != this.state.managerId) {
            let ownerData = {
              branchId: res.payload.id,
              status: 1,
              userId: userId,
              branchDesignation: "",
              isManager: true,
            };

            this.props.onAssignBranch(ownerData);
          }
          this.props.onGetUserBranch();
          //   this.closeModal();
          this.setState({
            name: "",
            email: "",
            country: "",
            city: "",
            address: "",
            phone: "",
            bnkAccTitle: "",
            bnkAccNumber: "",
            gstNumber: "",
            gstPercent: "",
            DefaultCurrency: "",
            managerId: null,
            selectedOption: null,
            saveLoad: false,
            defaultCountry: "",
          });
        })
        .catch((err) => {
          if (err.payload.response.status == 404) {
            let data = {
              AdvertisingExpiry: "30",
              Followup: "30",
              ReminderSetting: "1",
              SkillMateExpiry: "30",
              XrayExpiry: "30",
              medicalcertexpiry: "30",
              passportExpiry: "30",
              policecertexpiry: "30",
              ppireminder: "21",
              tcreminder: "30",
              visareminder: "30",
              ReminderEmail: "",
            };
            var myJson = JSON.stringify(data);
            let req = {
              id: 0,
              settingsJson: myJson,
            };
            this.props
              .onAddReminderSettings(req)
              .then((res) => {
                // message.success("Updated!");
                this.getReminderSettings();
                this.setState({ saveLoad: false });
              })
              .catch((err) => {
                this.setState({ saveLoad: false });
                message("Failed!");
              });
          }
          message.error("Failed to add branch. Please try again.");
        });
    }
  };

  updateUsersPassword = (passwordData) => {
    console.log("show password data ====== ", passwordData);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!strongRegex.test(passwordData.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (passwordData.password !== passwordData.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      this.setState({ passwordLoading: true });
      let data = {
        userId: passwordData.userId,
        email: passwordData.email,
        password: passwordData.password,
      };
      this.props.onChangeUsersPassword(data).then(() => {
        message.success("User's password updated successfully");
      });
    }
  };

  disableTeamMember = (disableUserData) => {
    this.props.onDisableUser(disableUserData).then(() => {
      if (disableUserData.isLock) {
        message.success("User disabled successfully!");
      } else {
        message.success("User enabled successfully!");
      }
      this.props.onGetTeamMember();
    });
  };

  assignBranch = (data) => {
    this.props.onAssignBranch(data);
  };

  unassignBranch = (data) => {
    this.props.onUnassignBranch(data);
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    this.props
      .onExportData()
      .then(function(response) {
        this.setState({ exportLoading: false });
        var blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Ezymigrate.zip");
      })
      .catch((e) => message.error("Unable to export data, please try again!"));
  };

  handleManagerChange = (managerId) => {
    this.setState({ managerId: managerId });
    findIndex = this.props.teamMembers.users.filter(
      (obj) => obj.id === managerId
    );
  };

  uploadCompanyLogo = (info) => {
    var size = info.size / 1024 / 1024 < 2;

    // if (id) {
    //   this.setState({ imageUpdateId: id });
    // }
    let formData = new FormData();
    formData.append("File", info);
    if (size) {
      this.props.onUploadAvatar(formData).then(() => {
        if (this.props.imageUploadSuccess) {
          this.setState({ logoUrl: this.props.imageUploadSuccess });
        }
      });
    } else {
      this.setState({
        logoUrl: "",
        loading: false,
      });
      message.error("Image size should be less than 2 MB!");
    }
  };

  onHandleChange = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          // logoUrl: imageUrl,
          loading: false,
        })
      );
    }
  };

  render() {
    const {
      selectedOption,
      name,
      email,
      country,
      city,
      address,
      phone,
      bnkAccTitle,
      bnkAccNumber,
      gstNumber,
      gstPercent,
      DefaultCurrency,
      managerId,
      saveLoad,
      currencies,
      userOptionsArray,
      logoUrl,
    } = this.state;
    const {} = this.props;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div
            className="sus-content-card-container"
            style={{ paddingRight: 0, width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              {/* <Modal
                visible={this.state.visible}
                width="700"
                height="600"
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}
              > */}
              <div style={{ paddingTop: 57 }}>
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="sus-modal-label">
                    <span className="sus-modal-label-text">
                      ADD BRANCH INFORMATION
                    </span>
                  </div>
                </div> */}
                <div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="ca-gray-cont"
                      style={{
                        display: "flex",
                        width: "100%",
                        border: 0,
                        borderRadius: 5,
                        paddingRight: 20,
                      }}
                    >
                      <div style={{ width: "100%", marginLeft: 20 }}>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={this.onHandleChange}
                          action={this.uploadCompanyLogo}
                        >
                          {logoUrl ? (
                            <img
                              src={logoUrl}
                              alt="avatar"
                              style={{
                                width: 105,
                                height: 105,
                              }}
                            />
                          ) : (
                            <PlusOutlined />
                          )}
                        </Upload>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Branch Name*
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Branch Name"
                              type="text"
                              value={name}
                              onChange={(e) => this.myChangeHandler("name", e)}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Branch Email*
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Branch Email"
                              type="text"
                              value={email}
                              onChange={(e) => this.myChangeHandler("email", e)}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Country
                          </p>
                          <div
                            // className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Country"
                              onChange={(val) =>
                                this.setState({ country: val })
                              }
                            >
                              {this.props.countriesData &&
                                this.props.countriesData.items
                                  .filter(
                                    (obj) =>
                                      obj.name.toLowerCase() ===
                                        "new zealand" ||
                                      obj.name.toLowerCase() === "australia" ||
                                      obj.name.toLowerCase() === "canada"
                                  )
                                  .map((data) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    return (
                                      <Option value={data.name}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}

                              {this.props.countriesData &&
                                this.props.countriesData.items.map(
                                  (data, index) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    if (
                                      data.name.toLowerCase() ===
                                        "new zealand" ||
                                      data.name.toLowerCase() === "australia" ||
                                      data.name.toLowerCase() === "canada"
                                    ) {
                                    } else {
                                      return (
                                        <Option value={data.name}>
                                          {data.name}
                                        </Option>
                                      );
                                    }
                                  }
                                )}
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            City
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="City"
                              type="text"
                              value={city}
                              onChange={(e) => this.myChangeHandler("city", e)}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Address
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Address"
                              type="text"
                              value={address}
                              onChange={(e) =>
                                this.myChangeHandler("address", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Phone
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Phone"
                              type="text"
                              value={phone}
                              onChange={(e) => this.myChangeHandler("phone", e)}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Title
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Bank Account Title"
                              type="text"
                              value={bnkAccTitle}
                              onChange={(e) =>
                                this.myChangeHandler("bnkAccTitle", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Number
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="Bank Account Number"
                              type="text"
                              value={bnkAccNumber}
                              onChange={(e) =>
                                this.myChangeHandler("bnkAccNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Number
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="GST Number"
                              type="text"
                              value={gstNumber}
                              onChange={(e) =>
                                this.myChangeHandler("gstNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Percentage
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Input
                              className="profile-input"
                              placeholder="GST Percentage"
                              type="number"
                              step="any"
                              value={gstPercent}
                              onChange={(e) =>
                                this.myChangeHandler("gstPercent", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Default Country
                          </p>
                          <div
                            // className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Country"
                              onChange={(val) =>
                                this.setState({ defaultCountry: val })
                              }
                            >
                              {this.props.countriesData &&
                                this.props.countriesData.items
                                  .filter(
                                    (obj) =>
                                      obj.name.toLowerCase() ===
                                        "new zealand" ||
                                      obj.name.toLowerCase() === "australia" ||
                                      obj.name.toLowerCase() === "canada"
                                  )
                                  .map((data) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    return (
                                      <Option value={data.id}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}

                              {this.props.countriesData &&
                                this.props.countriesData.items.map(
                                  (data, index) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    if (
                                      data.name.toLowerCase() ===
                                        "new zealand" ||
                                      data.name.toLowerCase() === "australia" ||
                                      data.name.toLowerCase() === "canada"
                                    ) {
                                    } else {
                                      return (
                                        <Option value={data.id}>
                                          {data.name}
                                        </Option>
                                      );
                                    }
                                  }
                                )}
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Default Currency
                          </p>
                          <div style={{ width: "47%" }}>
                            <Select
                              value={selectedOption}
                              onChange={this.handleChange}
                            >
                              {currencies}
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Add Branch Manager*
                          </p>
                          <div style={{ width: "47%" }}>
                            <Select
                              value={managerId}
                              onChange={this.handleManagerChange}
                            >
                              {userOptionsArray}
                            </Select>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 30,
                          }}
                        >
                          <div></div>
                          <div style={{ display: "flex" }}>
                            <Button
                              onClick={this.onSubmit}
                              loading={saveLoad}
                              type="primary"
                              className="sus-add-btn"
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </Modal> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBranch;
