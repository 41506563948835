import React, { Fragment, useState, useEffect } from "react";
import { Select, message, Button, DatePicker } from "antd";
import moment from "moment";
import activityData from "../ClientActivity/ActivityData";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const StartNewVisa = ({
  visaTypeData,
  onStartNewApplication,
  onGetVisaApplication,
  newApplicationWith,
  onGetVisaCategory,
  visaCategoryData,
  onSetActiveKey,
  onGetVisaTypeByCountry,
  activeKey,
  visaCountryId,
  profileDataRdx,
  brnchVisaCountriesData,
  onGetBVisaCountries,
}) => {
  const [visaTypeId, setVisaTypeId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [category, setCategory] = useState(0);
  const [destination, setDestination] = useState(0);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState(
    visaCountryId || localStorage.getItem("defaultCountryId")
  );
  const [prevNewApplicationWith, setPrevNewApplicatonWith] = useState(
    newApplicationWith || ""
  );
  const [countryName, setCountryName] = useState(newApplicationWith || "");

  useEffect(() => {
    onGetBVisaCountries();
  }, []);

  useEffect(() => {
    if (visaCountryId != countryId) {
      setCountryId(visaCountryId);
      countryNameFind(visaCountryId);
    }
  }, [visaCountryId]);

  const countryNameFind = (id) => {
    if (brnchVisaCountriesData) {
      let findCountry =
        brnchVisaCountriesData &&
        brnchVisaCountriesData.find((obj) => obj.countryId == id);
      if (findCountry) {
        setCountryName(findCountry.countryName);
      }
    }
  };

  useEffect(() => {
    if (brnchVisaCountriesData) {
      countryNameFind(countryId);
    }
  }, [brnchVisaCountriesData, newApplicationWith]);

  useEffect(() => {
    if (activeKey === "3") {
      if (countryId) {
        onGetVisaTypeByCountry(countryId);
      } else {
        let countryIdDef = localStorage.getItem("defaultCountryId") || 0;
        onGetVisaTypeByCountry(countryIdDef);
      }
    }
  }, [activeKey, countryId]);
  var visaTypeOptions = [];
  var categoryOptions = [];
  if (visaTypeData) {
    let _visaStatuses = visaTypeData.items.sort((a, b) =>
      a.visaTypeName.localeCompare(b.visaTypeName)
    );
    for (var i = 0; i < _visaStatuses.length > 0; i++) {
      visaTypeOptions.push(
        <Option value={_visaStatuses[i].id}>
          {_visaStatuses[i].visaTypeName}
        </Option>
      );
    }
  }

  if (visaCategoryData) {
    for (var i = 0; i < visaCategoryData.items.length > 0; i++) {
      categoryOptions.push(
        <Option value={visaCategoryData.items[i].id}>
          {visaCategoryData.items[i].name}
        </Option>
      );
    }
  }

  useEffect(() => {
    if (newApplicationWith !== prevNewApplicationWith) {
      setPrevNewApplicatonWith(newApplicationWith);
      setVisaTypeId("");
      setStartDate("");
      setCategory(0);
      setDestination(0);
    }
  }, [newApplicationWith]);

  const onChangeVisaType = (value) => {
    console.log(`selected ${value}`);
    setVisaTypeId(value);
    if (visaCountryId == 28) {
      onGetVisaCategory(value);
    }
  };

  const onChangeCategory = (value) => {
    console.log(`selected ${value}`);
    setCategory(value);
  };

  const onChangeDestination = (value) => {
    console.log(`selected ${value}`);
    setDestination(value);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(date);
  };

  const onSubmit = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (!countryName) {
      message.error(
        "Please select default country or add default country as branch visa country!"
      );
      return;
    }

    setLoading(true);
    var userId = localStorage.getItem("userId");
    var visaType =
      visaTypeData && visaTypeData.items.find((obj) => obj.id === visaTypeId);
    if (visaTypeId === "") {
      message.error("Please select visa type!");
      setLoading(false);
    }
    let data = {
      subjectId: clientprofileid,
      branchVisaTypeId: visaTypeId,
      caseStatusId: 1,
      startDate: startDate || new Date(),
      isPaid: false,
      isSigned: false,
      casePriority: "Medium",
      country: countryName || "",
      // === "au"
      //   ? "AUTRAILIA"
      //   : newApplicationWith === "nz"
      //   ? "NEW ZELAND"
      //   : "CANADA",
      expiryDate: "1900-01-01T00:00:00+00:00",
      approveDate: "1900-01-01T00:00:00+00:00",
      visaTypeName: visaType.visaTypeName,
      isCompleted: false,
      subCategory: parseInt(category),
      destination: parseInt(destination),
      applicationCountry: countryId ? parseInt(countryId) : 0,
    };
    onStartNewApplication(data)
      .then((res) => {
        message.success("Visa application created successfully.");
        onGetVisaApplication();
        onSetActiveKey(null);
        setVisaTypeId("");
        setStartDate("");
        let userName = localStorage.getItem("userName");
        if (profileDataRdx) {
          let myData = {
            clientName: profileDataRdx.fullName,
            logMessage:
              visaType.visaTypeName +
              " application status Preparing Added by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Visa",
            invoiceId: "0",
          };
          activityData(myData);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="form-container" style={{ marginLeft: 0, marginRight: 0 }}>
        {false && (
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <Button className="cv-btn-blue">START APPLICATION</Button>
            <Button
              className="cv-btn-blue"
              style={{ backgroundColor: "#3FCDAD" }}
            >
              NEW APPLICATION (AUS)
            </Button>
          </div>
        )}
        <div
          style={{
            paddingTop: 5,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <span className="visa-date-text" style={{ color: "#36B1E4" }}>
            Start Application
            {`(${countryName || ""})`}
          </span>
        </div>
        <div
          style={{
            paddingTop: 5,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          {/* <span className="visa-date-text">
            {"Visa Status Date: " + moment(new Date()).format("DD/MM/YYYY")}
          </span> */}
        </div>
        <div style={{ padding: 10 }}>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: "100%" }}
            onChange={onChangeVisaType}
            placeholder="Select Visa Types"
            value={visaTypeId}
          >
            <Option value="">Please Select Visa</Option>
            {visaTypeOptions}
          </Select>
        </div>
        {visaCountryId == 28 && visaTypeId && (
          <div style={{ padding: 10 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={onChangeCategory}
              // value={selectedOption}
              // onChange={this.handleChange}
            >
              {categoryOptions}
            </Select>
          </div>
        )}
        {visaCountryId == 28 && (
          <div style={{ padding: 10 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={onChangeDestination}
              // value={selectedOption}
              // onChange={this.handleChange}
            >
              <Option value="1">New South Wales</Option>
              <Option value="2">Queensland</Option>
              <Option value="6">South Australia</Option>
              <Option value="4">Tasmania</Option>
              <Option value="3">Victoria</Option>
              <Option value="5">Western Australia</Option>
            </Select>
          </div>
        )}
        <div style={{ padding: 10, paddingTop: 0 }}>
          <DatePicker
            value={startDate}
            onChange={onChange}
            format={dateFormat}
          />
        </div>

        <div className="button-blue-cont">
          {/* <div
            className="button-blue"
            style={{ cursor: "pointer" }}
            onClick={onSubmit}
          > */}
          <Button
            loading={loading}
            style={{ color: "#FFFFFF" }}
            onClick={onSubmit}
            className="button-blue"
          >
            Save
          </Button>
          {/* <span style={{ color: "#FFFFFF" }}>Save</span> */}
          {/* </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default StartNewVisa;
