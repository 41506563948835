import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomQuestionnaire from "../CustomQuestionnaire/Questionnaire";

import {
  createQuestionnaire,
  getQuestionnaire,
  getMappingParent,
  getMappingChild,
  updateQuestionnaire
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    questionnaireData: state.questionnaireReducer.questionnaireData,
    mappParentData: state.questionnaireReducer.mappParentData,
    mappChildData: state.questionnaireReducer.mappChildData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateQuestionnaire: bindActionCreators(createQuestionnaire, dispatch),
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onGetMappingParent: bindActionCreators(getMappingParent, dispatch),
    onGetMappingChild: bindActionCreators(getMappingChild, dispatch),
    onUpdateQuestionnaire: bindActionCreators(updateQuestionnaire, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomQuestionnaire);
