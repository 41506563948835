import React, { useState, Fragment, useEffect } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { sortableHandle } from "react-sortable-hoc";

import ContactEmployerForm from "./ContactEmployerForm";
import { Images } from "./../../../../../Themes";
import { Spin, message, Modal } from "antd";
import ContactEmployerTable from "./ContactEmployerTable";

const ContactEmployer = ({
  ongetEmployerContact,
  EmployerContactRes,
  onAddEmployerContact,

  userDataEmp,

  onRemoveEmployerContact,

  onUpdateEmployerContact
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState({});
  const [loading, setLoading] = useState(false);
  const [contactRow, setContactRow] = useState();
  const [updatedata, setUpdatedata] = useState();

  const showModal = record => {
     
    setIsModalVisible(!isModalVisible);
    setCreateMode(record);
    setContactRow(record);
  };

  const showEditContact = record => {
     
    setIsModalVisible(!isModalVisible);
    // setContactRow(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="contact-emp">
            <div className="client-tag-form"></div>
            <div>
              <div>
                <div className="d-end space-between">
                  <div style={{ marginBottom: "7px" }} className="contact-Head">
                    <div className="d-flex align-item ">
                      <h4
                        // style={{ color: "#5b6875", marginTop: "10px" }}
                        className="top-text heading-forms"
                        style={{marginLeft: "1px",fontWeight: 600 , fontSize: "14px",
                          fontFamily: "Poppins"}}
                      >
                        Contacts
                      </h4>
                    </div>
                    <>
                      <img
                        style={{ width: "29px", height: "29px" }}
                        src={Images.addIcon}
                        className="icons-client"
                        type="primary"
                        onClick={() => showModal("add-contact")}
                      />
                    </>
                  </div>
                </div>

                {isModalVisible && (
                  <Modal
                    title={
                      createMode === "add-contact"
                        ? "Add Contacts"
                        : "Update Contacts"
                    }
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    header={false}
                    footer={false}
                  >
                    <ContactEmployerForm
                      onAddEmployerContact={onAddEmployerContact}
                      ongetEmployerContact={ongetEmployerContact}
                      EmployerContactRes={EmployerContactRes}
                      userDataEmp={userDataEmp}
                      handleCancel={handleCancel}
                      createMode={createMode}
                      onUpdateEmployerContact={onUpdateEmployerContact}
                      contactRow={contactRow}
                    />
                  </Modal>
                )}
                <Spin size="large" spinning={loading}>
                  <ContactEmployerTable
                    ongetEmployerContact={ongetEmployerContact}
                    EmployerContactRes={EmployerContactRes}
                    onAddEmployerContact={onAddEmployerContact}
                    userDataEmp={userDataEmp}
                    onRemoveEmployerContact={onRemoveEmployerContact}
                    isModalVisible={isModalVisible}
                    showModal={showModal}
                    showEditContact={showEditContact}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContactEmployer;
