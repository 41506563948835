import { api } from "../../services/api";
import * as types from "../Constants";

export const getVisaNotification = (data) => {
  const options = {
    url: "v1/branch/GetBranchVisaNotificationSettings",
  };
  options.types = [
    types.GET_UPDATE_NOTIFICATION_SUCCESS,
    types.GET_UPDATE_NOTIFICATION_FAILURE,
  ];

  return api.get(options, data);
};

export const updateVisaNotification = (data) => {
  const options = {
    url: "v1/branch/UpdateBranchVisaNotification",
  };
  options.types = [
    types.PUT_UPDATE_NOTIFICATION_SUCCESS,
    types.PUT_UPDATE_NOTIFICATION_FAILURE,
  ];

  return api.put(options, data);
};
