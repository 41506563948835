import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Form,
  Table,
  Modal,
  Spin,
  Tabs,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getInvoiceInfo,
  getInvoiceListingBySubjectId,
  getInvoicePayments,
  getParentInvoiceListing,
  getPotentialClientInfo,
  getPotentialClientUpdateBol,
  getSchoolInvoiceInfo,
  getXeroToken,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import AddInvoiceToXero from "./AddInvoiceToXero";
import { apiRefresh } from "../services/api";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class Invoices extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    super(props);
    this.state = {
      subjectTypeId: 2,
      pageNumber: 1,
      pageSize: 5,
      dateFrom: "1900-01-01T00:00:00+00:00",
      dateTo: "1900-01-01T00:00:00+00:00",
      invoicesList: [],
      loading: false,
      totalPages: 0,
      defaultCurrent: 1,
      loadingTable: false,
      name: "",
      email: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getInvoiceListingBySubjectId(this.state)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let invoiceListingBySubjectId =
      nextProps &&
      nextProps.invoiceListingBySubjectId &&
      nextProps.invoiceListingBySubjectId;
    let _invoiceListingBySubjectId = [];
    if (invoiceListingBySubjectId && invoiceListingBySubjectId.length > 0) {
      for (let index = 0; index < invoiceListingBySubjectId.length; index++) {
        if (
          invoiceListingBySubjectId[index].invoiceTypeId === 1 ||
          invoiceListingBySubjectId[index].invoiceTypeId === 3
        ) {
          _invoiceListingBySubjectId.push(invoiceListingBySubjectId[index]);
        }
      }
    }
    let _xeroInvoicesCount =
      nextProps && nextProps.xeroInvoicesCount && nextProps.xeroInvoicesCount;
    this.setState({
      invoicesList: _invoiceListingBySubjectId,
      totalPages: _xeroInvoicesCount,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  show = () => {
    this.setState(
      { pageNumber: 1, pageSize: this.state.pageSize, loadingTable: true },
      () => {
        this.props
          .getInvoiceListingBySubjectId(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.xeroInvoicesCount &&
              this.props.xeroInvoicesCount;
            this.setState({
              defaultCurrent: 1,
              loadingTable: false,
              totalPages: _length,
            });
          })
          .catch(() => {
            this.setState({ loadingTable: false });
          });
      }
    );
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: this.state.pageSize != value.pageSize ? 1 : value.current,
        loadingTable: true,
      },
      () => {
        this.props
          .getInvoiceListingBySubjectId(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.xeroInvoicesCount &&
              this.props.xeroInvoicesCount;
            this.setState({
              loadingTable: false,
              defaultCurrent:
                this.state.pageSize != value.pageSize ? 1 : value.current,
              totalPages: _length,
            });
          })
          .catch(() => {
            this.setState({ loadingTable: false });
          });
      }
    );
  };

  showAll = () => {
    this.setState({ loading: true });
    this.setState(
      {
        subjectTypeId: 2,
        pageNumber: 1,
        pageSize: 5,
        dateFrom: "1900-01-01T00:00:00+00:00",
        dateTo: "1900-01-01T00:00:00+00:00",
        name: "",
        email: "",
      },
      () => {
        this.props
          .getInvoiceListingBySubjectId(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.xeroInvoicesCount &&
              this.props.xeroInvoicesCount;
            this.setState({
              loading: false,
              defaultCurrent: 1,
              totalPages: _length,
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    );
  };
  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  getFiltersSection = () => {
    return (
      <div>
        <Form>
          <Row style={{ marginBottom: "19px" }}>
            <Col xs={11} offset={1}>
              <div style={{ width: "100%", display: "flex" }}>
                <div className="xero-filters-label">
                  <p className={"font-school-list-filter-text-sub"}>Name</p>
                </div>
                <div style={{ width: "80%" }}>
                  <Form.Item>
                    <Input size="medium" onChange={this.handleChangeName} />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={11} offset={1}>
              <div style={{ width: "100%", display: "flex" }}>
                <div className="xero-filters-label">
                  <p className={"font-school-list-filter-text-sub"}>Email</p>
                </div>
                <div style={{ width: "80%" }}>
                  <Form.Item>
                    <Input size="medium" onChange={this.handleChangeEmail} />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "19px" }}>
            <Col xs={11} offset={1}>
              <div style={{ width: "100%", display: "flex" }}>
                <div className="xero-filters-label">
                  <p className={"font-school-list-filter-text-sub"}>
                    Date From
                  </p>
                </div>
                <div style={{ width: "80%" }}>
                  <Form.Item style={{ width: "100%" }}>
                    <DatePicker
                      placeholder="Date From"
                      size="small"
                      format={dateFormat}
                      onChange={this.handleChangeDateFrom}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={11} offset={1}>
              <div style={{ width: "100%", display: "flex" }}>
                <div className="xero-filters-label">
                  <p className={"font-school-list-filter-text-sub"}>Date To</p>
                </div>
                <div style={{ width: "80%" }}>
                  <Form.Item style={{ width: "100%" }}>
                    <DatePicker
                      placeholder="Date To"
                      size="small"
                      format={dateFormat}
                      onChange={this.handleChangeDateTo}
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "19px" }}>
            <Col xs={4} offset={20}>
              <div style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    className="button-blue"
                    size="medium"
                    type="primary"
                    style={{ marginRight: "4px" }}
                    onClick={this.showAll}
                  >
                    Show All
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="button-blue"
                    size="medium"
                    type="primary"
                    onClick={this.show}
                    htmlType="submit"
                  >
                    Search
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text, record) =>
        this.state.subjectTypeId === 2 ? (
          <Link
            to={{
              pathname:
                record.memberType.toLowerCase() === "client"
                  ? "/profile"
                  : record.memberType.toLowerCase() === "spouse"
                  ? "/partner-profile"
                  : "/client-family-member",
              state: { id: record.clientId, familyId: record.familyId },
            }}
          >
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {record.to}
            </p>
          </Link>
        ) : this.state.subjectTypeId === 3 ? (
          <a
            onClick={() => {
              this.props.getPotentialClientInfo(record.subjectId).then(() => {
                this.props.getPotentialClientUpdateBol(false);
                this.props.history.push("/update-potential-client");
              });
            }}
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.to}
          </a>
        ) : this.state.subjectTypeId === 4 ? (
          <a
            onClick={() => {
              this.props.history.push("/employer-management");
            }}
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.to}
          </a>
        ) : (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.to}
          </p>
        ),
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dueDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Due",
      dataIndex: "dueAmount",
      key: "dueAmount",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            size="small"
            type="primary"
            className="post-to-xero"
            onClick={() => {
              record.postedXero === false
                ? this.onClickPostToXero(record.id)
                : this.onClickAddPayments(record.id);
            }}
          >
            {record.postedXero === false ? "Post To Xero" : "Add Payments"}
          </Button>
          {record.postedXero === true ? (
            <Button
              size="small"
              type="primary"
              className="post-to-xero"
              style={{ marginLeft: "4px" }}
              onClick={() => {
                this.onClickPostToXero(record.id);
              }}
            >
              {"View Invoice"}
            </Button>
          ) : null}
        </span>
      ),
    },
  ];

  onClickPostToXero = (id) => {
    this.setState({ loading: true });
    if (this.state.subjectTypeId === 5) {
      this.props
        .getSchoolInvoiceInfo(id)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push("/xero-sync-school-invoice");
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .getInvoiceInfo(id)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push("/xero-sync-invoice");
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onClickAddPayments = (id) => {
    this.setState({ loading: true });
    this.props
      .getInvoiceInfo(id)
      .then(() => {
        this.props
          .getInvoicePayments(id)
          .then(() => {
            this.setState({ loading: false });
            this.props.history.push("/xero-sync-invoice-payments");
            // this.setState({invoicePayments: _invoicePayments})
          })
          .catch(() => {
            this.setState({ loading: false });
            this.props.history.push("/xero-sync-invoice-payments");
          });
        // this.props.history.push("/xero-sync-invoice");
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  onChange = (value) => {
    this.setState({ subjectTypeId: value, pageNumber: 1 }, () => {
      this.setState({ loadingTable: true });
      this.props
        .getInvoiceListingBySubjectId(this.state)
        .then(() => {
          this.setState({ loadingTable: false });
        })
        .catch(() => {
          this.setState({ loadingTable: false });
        });
    });
  };

  getTable = () => {
    return (
      <div>
        <Spin spinning={this.state.loading} size="large">
          <Row>
            <Col xs={6} style={{ marginBottom: "37px" }}>
              <Select
                placeholder="Select Invoice Department"
                value={this.state.subjectTypeId}
                onChange={this.onChange}
              >
                <Option value={2}>Client Invoices</Option>
                <Option value={8}>Template Invoices</Option>
                <Option value={3}>Potential Client Invoices</Option>
                <Option value={4}>Employer Invoices</Option>
                <Option value={5}>School Invoices</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Table
                dataSource={
                  this.state.invoicesList ? this.state.invoicesList : null
                }
                columns={this.columns}
                onChange={this.paginate}
                pagination={{
                  defaultCurrent: this.state.defaultCurrent,
                  current: this.state.pageNumber,
                  total: this.state.totalPages,
                  defaultPageSize: this.state.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ["5", "10", "15", "20", "50"],
                }}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Spin spinning={this.state.loadingTable} size="large">
          <Fragment>
            <div className="reminder-tabs-header employer-Header">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Xero Invoices" key="1"></TabPane>
              </Tabs>
            </div>
            <Row>
              <Col offset={18}>
                <Button
                  className="remove-xero-account"
                  onClick={() => {
                    const remXeroAcc = {
                      url: `v1/xero/RemoveXeroAccount`,
                    };
                    remXeroAcc.types = [
                      "REMOVE_XERO_ACCOUNT_SUCCESS",
                      "REMOVE_XERO_ACCOUNT_FAILURE",
                    ];
                    this.setState({ loading: true });
                    apiRefresh
                      .delete(remXeroAcc)
                      .then((res) => {
                        this.setState({ loading: false });
                        getXeroToken();
                        this.props.history.push("/xero-sync-add-account");
                      })
                      .catch((err) => {
                        this.setState({ loading: false });
                      });
                  }}
                >
                  Remove Account
                </Button>
              </Col>
            </Row>
            <div className="ts-container-import-clients">
              {this.getFiltersSection()}
            </div>
            <div className="ts-container-import-clients">{this.getTable()}</div>
          </Fragment>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoiceListingBySubjectId:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceListingBySubjectId &&
      state.invoicesReducer.invoiceListingBySubjectId.items &&
      state.invoicesReducer.invoiceListingBySubjectId.items,
    xeroInvoicesCount:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.xeroInvoicesCount &&
      state.invoicesReducer.xeroInvoicesCount,
    isExists: state && state.xeroReducer && state.xeroReducer.isExists,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  getSchoolInvoiceInfo: bindActionCreators(getSchoolInvoiceInfo, dispatch),
  getInvoiceListingBySubjectId: bindActionCreators(
    getInvoiceListingBySubjectId,
    dispatch
  ),
  getInvoicePayments: bindActionCreators(getInvoicePayments, dispatch),
  getPotentialClientInfo: bindActionCreators(getPotentialClientInfo, dispatch),
  getPotentialClientUpdateBol: bindActionCreators(
    getPotentialClientUpdateBol,
    dispatch
  ),
  getXeroToken: bindActionCreators(getXeroToken, dispatch),
});
Invoices = connect(mapStateToProps, mapDispatchToProps)(Invoices);

export default withRouter(Invoices);
