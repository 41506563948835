import { api } from "../../services/api";
import * as types from "../Constants";

export const getUserDetail = () => {
  const options = {
    url: "v1/users",
  };

  options.types = [types.USER_DETAIL_SUCCESS, types.USER_DETAIL_FAILURE];

  return api.get(options);
};

export const addUserDetail = (data) => {
  const options = {
    url: "v1/users",
  };

  options.types = [
    types.ADD_USER_DETAIL_SUCCESS,
    types.ADD_USER_DETAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const updUserDetail = (data) => {
  const options = {
    url: "v1/users",
  };

  options.types = [
    types.UPDATE_USER_DETAIL_SUCCESS,
    types.UPDATE_USER_DETAIL_FAILURE,
  ];

  return api.put(options, data);
};

export const addUserAddress = (data) => {
  const options = {
    url: "v1/user/address",
  };

  options.types = [
    types.ADD_USER_ADDRESS_SUCCESS,
    types.ADD_USER_ADDRESS_FAILURE,
  ];

  return api.post(options, data);
};

export const updUserAddress = (data) => {
  const options = {
    url: "v1/user/address",
  };

  options.types = [
    types.UPD_USER_ADDRESS_SUCCESS,
    types.UPD_USER_ADDRESS_FAILURE,
  ];

  return api.put(options, data);
};

export const addUserContact = (data) => {
  const options = {
    url: "v1/user/contact",
  };

  options.types = [
    types.ADD_USER_CONTACT_SUCCESS,
    types.ADD_USER_CONTACT_FAILURE,
  ];

  return api.post(options, data);
};

export const updUserContact = (data) => {
  const options = {
    url: "v1/user/contact",
  };

  options.types = [
    types.UPD_USER_CONTACT_SUCCESS,
    types.UPD_USER_CONTACT_FAILURE,
  ];

  return api.put(options, data);
};

export const changePassword = (data) => {
  const options = {
    url: "v1/user/identity/ChangeOwnPassword",
  };

  options.types = [
    types.CHANGE_PASSWORD_SUCCESS,
    types.CHANGE_PASSWORD_FAILURE,
  ];

  return api.post(options, data);
};

export const changeUsersPassword = (data) => {
  const options = {
    url: "v1/user/identity/ChangePassword",
  };

  options.types = [
    types.CHANGE_USERS_PASSWORD_SUCCESS,
    types.CHANGE_USERS_PASSWORD_FAILURE,
  ];

  return api.post(options, data);
};

export const disableUser = (data) => {
  const options = {
    url: "v1/user/identity/LockUnLockUser",
  };

  options.types = [types.DISABLE_USER_SUCCESS, types.DISABLE_USER_FAILURE];

  return api.post(options, data);
};

export const uploadAvatar = (data) => {
  const options = {
    url: "v1/document/Upload",
  };

  options.types = [
    types.UPLOAD_USER_AVATAR_SUCCESS,
    types.UPLOAD_USER_AVATAR_FAILURE,
  ];

  return api.post(options, data);
};

export const uploadAvatarWithFile = (data) => {
  const options = {
    url: "v1/document/Upload",
  };

  options.types = [
    types.UPLOAD_USER_AVATAR_SUCCESS,
    types.UPLOAD_USER_AVATAR_FAILURE,
  ];

  return api.postWithFileRes(options, data);
};

export const getStorage = () => {
  const options = {
    url: "v1/users/storage",
  };

  options.types = [types.GET_STORAGE_SUCCESS, types.GET_STORAGE_FAILURE];

  return api.get(options);
};

export const exportStorage = () => {
  var selectedBranchId = localStorage.getItem("selectedBranchId");
  const options = {
    url: "v1/users/export",
  };

  options.types = [types.EXPORT_DATA_SUCCESS, types.EXPORT_DATA_FAILURE];

  return api.getFile(options);
};
