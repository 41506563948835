import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUserCompanyRequest: [],
  getUserCompanySuccess: ["success"],
  getUserCompanyFailure: ["error"],

  addUserCompanyRequest: ["data"],
  addUserCompanySuccess: ["success"],
  addUserCompanyFailure: ["error"],

  updUserCompanyRequest: ["data"],
  updUserCompanySuccess: ["success"],
  updUserCompanyFailure: ["error"]
});

export const SusUserContactTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userCompanyData: null,
  fetching: false,
  error: null,
  addFetching: false,
  addError: null,
  addSuccess: null,
  updFetching: false,
  updError: null,
  updSuccess: null
});

/* ------------- Reducers ------------- */

// we're attempting to get sus user company
export const request = state => {
  console.log("on redux call");
  return state.merge({ fetching: true });
};

// we've successfully get sus user company
export const success = (state, { success }) =>
  state.merge({ fetching: false, userCompanyData: success });

// we've had a problem in geting sus user company
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// we're attempting to add sus user company
export const addrequest = state => state.merge({ addFetching: true });

// we've successfully added sus user company
export const addsuccess = (state, { addSuccess }) =>
  state.merge({ addFetching: false, addSuccess: addSuccess });

// we've had a problem in adding user company
export const addfailure = (state, { addError }) =>
  state.merge({ addFetching: false, addError });

// we're attempting to add sus user company
export const updrequest = state => state.merge({ updFetching: true });

// we've successfully added sus user company
export const updsuccess = (state, { updSuccess }) =>
  state.merge({ updFetching: false, updSuccess: updSuccess });

// we've had a problem in adding user company
export const updfailure = (state, { updError }) =>
  state.merge({ updFetching: false, updError });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_COMPANY_REQUEST]: request,
  [Types.GET_USER_COMPANY_SUCCESS]: success,
  [Types.GET_USER_COMPANY_FAILURE]: failure,

  [Types.ADD_USER_COMPANY_REQUEST]: addrequest,
  [Types.ADD_USER_COMPANY_SUCCESS]: addsuccess,
  [Types.ADD_USER_COMPANY_FAILURE]: addfailure,

  [Types.ADD_USER_COMPANY_REQUEST]: updrequest,
  [Types.ADD_USER_COMPANY_SUCCESS]: updsuccess,
  [Types.ADD_USER_COMPANY_FAILURE]: updfailure
});
