import { SystemGeneratedEmails } from "./SystemGeneratedEmails";

export const getFilterList = (res) => {
  let filteredList = [];
  let sysGeneratedEmail = SystemGeneratedEmails;
  let tempList = [];
  let _SystemGeneratedEmails = [...SystemGeneratedEmails];

  if (_SystemGeneratedEmails && _SystemGeneratedEmails.length > 0) {
    for (let index = 0; index < _SystemGeneratedEmails.length; index++) {
      if (
        res &&
        res.payload &&
        res.payload.emailTemplates &&
        res.payload.emailTemplates.length > 0
      ) {
        for (
          let _index = 0;
          _index < res.payload.emailTemplates.length;
          _index++
        ) {
          if (
            _SystemGeneratedEmails[index].emailType ===
            res.payload.emailTemplates[_index].emailType
          ) {
            tempList =
              sysGeneratedEmail &&
              sysGeneratedEmail.filter(
                (obj) =>
                  obj.emailType !== _SystemGeneratedEmails[index].emailType
              );
            sysGeneratedEmail = tempList;
          }
        }
      }
    }
  }
  let _sysGeneratedEmail = sysGeneratedEmail;

  if (res && res.payload && res.payload.emailTemplates) {
    filteredList = [..._sysGeneratedEmail, ...res.payload.emailTemplates];
  } else {
  }
  return filteredList;
};

export const getFilterListForModulesOtherThanInvoices = (res) => {
  let filteredList = [];
  let sysGeneratedEmail = SystemGeneratedEmails;
  let tempList = [];
  let _SystemGeneratedEmails = [...SystemGeneratedEmails];

  if (_SystemGeneratedEmails && _SystemGeneratedEmails.length > 0) {
    for (let index = 0; index < _SystemGeneratedEmails.length; index++) {
      if (
        res &&
        res.payload &&
        res.emailTemplates &&
        res.emailTemplates.length > 0
      ) {
        for (let _index = 0; _index < res.emailTemplates.length; _index++) {
          if (
            _SystemGeneratedEmails[index].emailType ===
            res.emailTemplates[_index].emailType
          ) {
            tempList = sysGeneratedEmail.filter(
              (obj) => obj.emailType !== _SystemGeneratedEmails[index].emailType
            );
            sysGeneratedEmail = tempList;
          }
        }
      }
    }
  }
  let _sysGeneratedEmail = sysGeneratedEmail;

  if (res && res && res.emailTemplates) {
    filteredList = [..._sysGeneratedEmail, ...res.emailTemplates];
  } else {
  }
  return filteredList;
};
