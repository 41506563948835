import { api } from "../../services/api";
import * as types from "../Constants";

export const getDocumentChecklist = (id) => {
  let idd = id || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/temp/document/checklist/All/${idd}`,
  };
  options.types = [
    types.GET_DOCUMENT_CHECKLIST_SUCCESS,
    types.GET_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.get(options);
};

export const addDocumentChecklist = (data) => {
  const options = {
    url: "v1/temp/document/checklist",
  };
  options.types = [
    types.ADD_DOCUMENT_CHECKLIST_SUCCESS,
    types.ADD_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.post(options, data);
};

export const updateDocumentChecklist = (data) => {
  const options = {
    url: "v1/temp/document/checklist",
  };
  options.types = [
    types.UPDATE_DOCUMENT_CHECKLIST_SUCCESS,
    types.UPDATE_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.put(options, data);
};

export const removeDocumentChecklist = (data) => {
  const options = {
    url: "v1/temp/document/checklist",
  };
  options.types = [
    types.DELETE_DOCUMENT_CHECKLIST_SUCCESS,
    types.DELETE_DOCUMENT_CHECKLIST_FAILURE,
  ];

  return api.delete(options, data);
};

export const getChecklistItems = () => {
  const options = {
    url: `v1/temp/document/checklistItem/All`,
  };
  options.types = [
    types.GET_CHECKLIST_ITEMS_SUCCESS,
    types.GET_CHECKLIST_ITEMS_FAILURE,
  ];

  return api.get(options);
};
