import { redirectUrl } from "../../config";
import { api } from "../../services/api";
import * as types from "../Constants";

export const DropboxAccountLogin = (data) => {
  const options = {
    url: `v1/dropbox/LoginLink`,
  };
  options.types = [types.DROPBOX_LOGIN_SUCCESS, types.DROPBOX_LOGIN_FAILURE];

  return api.post(options, data);
};

export const getDropboxDoc = (userId) => {
  //   let userId = await localStorage.getItem("userId");

  const options = {
    url: `v1/dropbox/${userId}`,
  };
  options.types = [
    types.GET_DROPBOX_DOC_SUCCESS,
    types.GET_DROPBOX_DOC_FAILURE,
  ];

  return api.get(options);
};

export const addDropboxDoc = (data) => {
  const options = {
    url: "v1/dropbox/CreateToken",
  };
  options.types = [
    types.ADD_DROPBOX_DOC_SUCCESS,
    types.ADD_DROPBOX_DOC_FAILURE,
  ];

  return api.post(options, data);
};

export const OneDriveAccountLogin = (redirectUrl) => {
  const options = {
    url: `v1/onedrive/Connect?RedirectUri=${redirectUrl}`,
  };
  options.types = [types.ONEDRIVE_LOGIN_SUCCESS, types.ONEDRIVE_LOGIN_FAILURE];
  return api.get(options);
};

export const SaveOneDriveToken = ({ code, redirectUrl }) => {
  const options = {
    url: `v1/onedrive/Code?code=${code}&redirect_uri=${redirectUrl}`,
  };
  options.types = [types.ONEDRIVE_TOKEN_SUCCESS, types.ONEDRIVE_TOKEN_FAILURE];
  return api.get(options);
};

export const GetOneDriveInfo = () => {
  const options = {
    url: `v1/onedrive`,
  };
  options.types = [
    types.ONEDRIVE_ACCOUNT_SUCCESS,
    types.ONEDRIVE_ACCOUNT_FAILURE,
  ];
  return api.get(options);
};

export const GoogleDriveAccountLogin = (data) => {
  const options = {
    url: `v1/googledrive/CreateToken`,
  };
  options.types = [
    types.GOOGLEDRIVE_LOGIN_SUCCESS,
    types.GOOGLEDRIVE_LOGIN_FAILURE,
  ];
  return api.post(options, data);
};

export const SaveGoogleDriveToken = ({ code, redirectUrl, userId }) => {
  const options = {
    url: `v1/googledrive/TokenCode?code=${code}&RedirectUri=${redirectUrl}&UserId=${userId}`,
  };
  options.types = [
    types.GOOGLEDRIVE_TOKEN_SUCCESS,
    types.GOOGLEDRIVE_TOKEN_FAILURE,
  ];
  return api.get(options);
};

export const GetGoogleDriveInfo = (userId) => {
  const options = {
    url: `v1/googledrive?UserId=${userId}`,
  };
  options.types = [
    types.GOOGLEDRIVE_ACCOUNT_SUCCESS,
    types.GOOGLEDRIVE_ACCOUNT_FAILURE,
  ];
  return api.get(options);
};

export const Office365AccountLogin = (redirectUrl) => {
  const options = {
    url: `v1/onedrive/Office365/Connect?RedirectUri=${redirectUrl}`,
  };
  options.types = [
    types.OFFICE365_LOGIN_SUCCESS,
    types.OFFICE365_LOGIN_FAILURE,
  ];
  return api.get(options);
};

export const SaveOffice365Token = ({ code, redirectUrl }) => {
  const options = {
    url: `v1/onedrive/Office365/Code?code=${code}&redirect_uri=${redirectUrl}`,
  };
  options.types = [
    types.OFFICE365_TOKEN_SUCCESS,
    types.OFFICE365_TOKEN_FAILURE,
  ];
  return api.get(options);
};

export const GetOffice365Info = () => {
  const options = {
    url: "v1/onedrive/Office365",
  };
  options.types = [
    types.OFFICE365_ACCOUNT_SUCCESS,
    types.OFFICE365_ACCOUNT_FAILURE,
  ];
  return api.get(options);
};

export const removeDropboxAccount = (data) => {
  const options = {
    url: "v1/dropbox",
  };
  options.types = [
    types.DELETE_DROPBOX_ACCOUNT_SUCCESS,
    types.DELETE_DROPBOX_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeOnedriveAccount = (data) => {
  const options = {
    url: "v1/onedrive",
  };
  options.types = [
    types.DELETE_ONEDRIVE_ACCOUNT_SUCCESS,
    types.DELETE_ONEDRIVE_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeGoogledriveAccount = (data) => {
  const options = {
    url: "v1/googledrive",
  };
  options.types = [
    types.DELETE_GOOGLEDRIVE_ACCOUNT_SUCCESS,
    types.DELETE_GOOGLEDRIVE_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeOffice365Account = (data) => {
  const options = {
    url: "v1/onedrive/Office365",
  };
  options.types = [
    types.DELETE_OFFICE365_ACCOUNT_SUCCESS,
    types.DELETE_OFFICE365_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};
