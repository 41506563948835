import { api } from "../../services/api";
import * as types from "../Constants";

export const addFileNotes = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [types.ADD_FILE_NOTES_SUCCESS, types.ADD_FILE_NOTES_FAILURE];

  return api.post(options, data);
};

export const getFileNotes = (data) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  let options = null;
  if (data && data.visaType !== null) {
    options = {
      url: "v1/client/filenote/All/Visa/" + clientprofileid + "/" + data,
    };
  } else {
    {
      options = {
        url: `v1/client/filenote/All/${clientprofileid}/${data.pageNumber}/${data.pageSize}`,
      };
    }
  }

  options.types = [types.GET_FILE_NOTES_SUCCESS, types.GET_FILE_NOTES_FAILURE];

  return api.get(options);
};

export const updateFileNotes = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [
    types.UPDATE_FILE_NOTES_SUCCESS,
    types.UPDATE_FILE_NOTES_FAILURE,
  ];

  return api.put(options, data);
};

export const deleteFileNotes = (data) => {
  const options = {
    url: "v1/client/filenote",
  };

  options.types = [
    types.DELETE_FILE_NOTES_SUCCESS,
    types.DELETE_FILE_NOTES_FAILURE,
  ];

  return api.delete(options, data);
};

export const getTemplates = () => {
  const options = {
    url: "v1/template/All",
  };

  options.types = [types.GET_TEMPLATE_SUCCESS, types.GET_TEMPLATE_FAILURE];

  return api.get(options);
};

export const getDynamicKeys = () => {
  const options = {
    url: "v1/config/DynamicKeys/All",
  };

  options.types = [
    types.GET_DYNAMIC_KEYS_SUCCESS,
    types.GET_DYNAMIC_KEYS_FAILURE,
  ];

  return api.get(options);
};

export const getClientVisaFileNotes = (data) => {
  const options = {
    url: "v1/client/filenote/All/Visa/" + data.clientId + "/" + data.caseId,
  };

  options.types = [
    types.GET_VISA_FILE_NOTES_SUCCESS,
    types.GET_VISA_FILE_NOTES_FAILURE,
  ];

  return api.get(options);
};

export const getClientFileNotesPdf = (data) => {
  const options = {
    url: "v1/client/filenote/FileNotespdf/" + data.clientId,
  };

  options.types = [
    types.GET_FILE_NOTES_PDF_SUCCESS,
    types.GET_FILE_NOTES_PDF_FAILURE,
  ];

  return api.getFile(options);
};
