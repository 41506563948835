import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Form, Modal } from "antd";
import { message, Spin, Button, Row, Col, Checkbox } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./SupervisorLogin.css";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { getXeroToken, setSelectedBranchId } from "./../store/Actions";
import { apiRefresh } from "../services/api";
import getUserBranchPermissions from "../Components/getUserBranchPermissions";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
class SupervisorLogin extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      loading: false,
      visibleDisclaimerModal: false,
      visibleReleasePlanModal: false,
      externalLogin: false,
    };
  }

  getForm = () => {
    return (
      <div>
        <Row>
          <Col style={{ marginLeft: "48px" }}>
            <img src={Images.logo} className="logo-img" />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "31px", marginLeft: "55px" }} xs={22}>
            <div>
              <p
                className="poppins-font-family"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                Username
              </p>
              <Form.Item
                colon={false}
                name="userName"
                rules={[{ required: true, message: "Required!" }]}
              >
                <div className="profile-input-border">
                  <input
                    className="profile-input-login"
                    placeholder="Enter user name"
                    type="text"
                    style={{ width: "100%" }}
                  />
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: "55px", marginTop: "20px" }} xs={22}>
            <div>
              <p
                className="poppins-font-family"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                Password
              </p>
              <Form.Item
                colon={false}
                name="password"
                rules={[{ required: true, message: "Required!" }]}
              >
                <div className="profile-input-border">
                  <input
                    className="profile-input-login"
                    placeholder="Enter password"
                    type="password"
                    style={{ width: "100%" }}
                  />
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={22} style={{ marginLeft: "55px", marginTop: "41px" }}>
            <Form.Item>
              <div className="ts-send-btn-cont">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ts-send-btn"
                  size="large"
                  style={{ width: "100%" }}
                  loading={this.state.loading}
                >
                  <span
                    className="sus-modal-button-text"
                    style={{ fontSize: "20px" }}
                  >
                    Log In
                  </span>
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  showModalDisclaimer = () => {
    // this.setState({
    //   visibleDisclaimerModal: true,
    // });
  };

  handleCancelDisclaimerModal = (e) => {
    this.setState({
      visibleDisclaimerModal: false,
    });
  };

  showModalReleasePlan = () => {
    this.setState({
      visibleReleasePlanModal: true,
    });
  };

  handleCancelReleasePlanModal = (e) => {
    this.setState({
      visibleReleasePlanModal: false,
    });
  };

  login = ({ userName, password }) => {
    this.setState({ loading: true });
    this.props
      .onSupervisorLogin({
        email: userName,
        password: password,
      })
      .then((res) => {
        var jwtDecoded = jwt_decode(res.payload.accessToken);
        localStorage.removeItem("admintoken");
        localStorage.setItem("token", res.payload.accessToken);
        localStorage.setItem("refreshToken", res.payload.refreshToken);
        localStorage.setItem("userId", jwtDecoded.sub);
        localStorage.setItem("userEmail", jwtDecoded.email);
        localStorage.setItem("userOwner", jwtDecoded.IsOwner);
        localStorage.setItem("selectedBranchId", jwtDecoded.BranchId);
        localStorage.setItem("companyId", jwtDecoded.Com);
        localStorage.setItem("userName", jwtDecoded.FullName);
        localStorage.setItem("isSupervisor", jwtDecoded.IsSupervisor);
        this.setState({ loading: false }, () => {
          // window.location.reload()
          window.location.assign("/supervisor/batches");
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <Row>
          <Col xs={24}>
            <div
              style={{
                width: "100%",
                height: "400px",
                backgroundSize: "cover",
                textAlign: "center",
                backgroundPosition: "top center",
                backgroundImage: `url(${Images.SupervisorLogin})`,
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  height: "70px",
                  top: 0,
                  marginLeft: 150,
                  marginRight: 150,
                }}
              >
                <img src={Images.logo} className="logo-img" />
              </div>
              <p
                style={{ fontSize: "32px", paddingTop: "150px", color: "#fff" }}
              >
                Fast, Easy and Every Featured <br />
                You Need!
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              style={{
                width: "750px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Form onFinish={this.login} ref={this.formRef}>
                {this.getForm()}
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              className="lg-item-center"
              style={{ padding: 15, position: "relative" }}
            >
              <span
                className="lg-copyright-text"
                style={{
                  fontSize: "14px",
                  position: "fixed",
                  bottom: 0,
                  backgroundColor: "#1281b9",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2024 EzyMigrate All right reserved.
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(SupervisorLogin);
