import React, { useState, Fragment, useEffect } from "react";
import { LeftCircleFilled, MenuOutlined } from "@ant-design/icons";
import { sortableHandle } from "react-sortable-hoc";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import ContractSettingsForm from "./ContractSettingsForm";
import { Images } from "../../Themes";
import { Spin, message, Button, Tooltip } from "antd";
import ContractDynamic from "./ContractDynamic";
import ContractTable from "./ContractTable";
import { useLocation } from "react-router-dom";
import history from "../../services/history";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const ContractSettings = ({
  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddLetterTemplates,

  onUpdetaLetterTemplates,

  onRemoveLetterTemplates,

  onSortLetterTemplates,
  onGetDocumentDownload,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [updatedata, setUpdatedata] = useState();
  const state = useLocation().state;

  useEffect(() => {
    setLoading(true);
    onGetLetterTemplates()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetLetterTemplates]);

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
    };

    onRemoveLetterTemplates(remove).then(() => {
      onGetLetterTemplates().then(() => {
        setLoading(false);
        message.success("Successfully Deleted!");
      });
    });
  };

  const showModal = (value, modelType) => {
    setIsModalVisible(value);
    setCreateMode(modelType);
    setUpdatedata(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.letterTemplate}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">Contracts</span>
                <Tooltip
                  placement="topLeft"
                  title={`This is where you can create and load your contracts and agreements into Ezymigrate, use the dynamic keys below in your agreements to allow Ezymigrate to automatically pull the relative information into the agreement.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="w-80">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="d-end space-between">
                    <div className="add-tag-btn">
                      <>
                        <img
                          src={Images.addIcon}
                          className="icons-client"
                          type="primary"
                          onClick={() =>
                            showModal(!isModalVisible, "add-contracts")
                          }
                        />
                      </>
                    </div>
                  </div>
                  {isModalVisible && (
                    <ContractSettingsForm
                      onAddLetterTemplates={onAddLetterTemplates}
                      handleCancel={handleCancel}
                      onGetLetterTemplates={onGetLetterTemplates}
                      setLoading={setLoading}
                      onUpdetaLetterTemplates={onUpdetaLetterTemplates}
                      createMode={createMode}
                      updatedata={updatedata}
                      onGetDocumentDownload={onGetDocumentDownload}
                    />
                  )}
                  <Spin size="large" spinning={loading}>
                    <ContractTable
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      storeData={storeData}
                      setIsModalVisible={setIsModalVisible}
                      setCreateMode={setCreateMode}
                      setUpdatedata={setUpdatedata}
                      onSortLetterTemplates={onSortLetterTemplates}
                      removeTag={removeTag}
                      setLoading={setLoading}
                    />
                  </Spin>
                </div>
              </div>
              <div className="top-60">
                <h3>DYNAMIC KEYS</h3>
                <div className="dynamic-keys">
                  <p>
                    You can use the following keys in the contracts and letters
                    to get the respective value for each client
                  </p>
                </div>
                <div>
                  <ContractDynamic />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContractSettings;
