import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal } from "antd";
import ProfileEmployer from "./ProfileEmployer";
import ContactEmployer from "./ContactEmployer/ContactEmployer";
// import JobEmployer from "./JobEmployer/JobEmployer";
// import ClientEmployer from "./ClientEmployer/ClientEmployer";

const EmployerManagementHead = ({
  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  userDataEmp,

  onRemoveEmployerContact,

  onUpdateEmployerManag,

  onUpdateEmployerContact,

  onGetEmployerManag,
  employerManagRes,

  docWithNameRes,
  onAddDocWithName
}) => {
  return (
    <Fragment>
      <div className="border-box emp-profile-box">
        <h2 className="top-text">Profile</h2>
        <ProfileEmployer
          userDataEmp={userDataEmp}
          onUpdateEmployerManag={onUpdateEmployerManag}
          onGetEmployerManag={onGetEmployerManag}
          employerManagRes={employerManagRes}
          docWithNameRes={docWithNameRes}
          onAddDocWithName={onAddDocWithName}
        />

        <ContactEmployer
          ongetEmployerContact={ongetEmployerContact}
          EmployerContactRes={EmployerContactRes}
          onAddEmployerContact={onAddEmployerContact}
          userDataEmp={userDataEmp}
          onRemoveEmployerContact={onRemoveEmployerContact}
          onUpdateEmployerContact={onUpdateEmployerContact}
        />
      </div>
    </Fragment>
  );
};
export default EmployerManagementHead;
