import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FroalaEditorCom from "../Common/FroalaEditorCom"

import { uploadAvatar
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FroalaEditorCom);
