import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const accountSetReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_TAG_SUCCESS:
      return { ...state, clientTagRes: action.payload };
    case types.GET_CLIENT_TAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_CLIENT_TAG_SUCCESS:
      return { ...state, addClientTagRes: action.payload };
    case types.ADD_CLIENT_TAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_CLIENT_TAG_SUCCESS:
      return { ...state, removeClientTagRes: action.payload };
    case types.DELETE_CLIENT_TAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_CLIENT_TAG_SUCCESS:
      return { ...state, updateClientTagRes: action.payload };
    case types.PUT_CLIENT_TAG_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_REMINDER_SETTINGS_SUCCESS:
      return { ...state, addReminderSettingsRes: action.payload };
    case types.ADD_REMINDER_SETTINGS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_REMINDER_SETTINGS_SUCCESS:
      return { ...state, reminderSettingRes: action.payload };
    case types.GET_REMINDER_SETTINGS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_SUCCESS:
      return { ...state, emailContentDocumentChecklistRes: action.payload };
    case types.GET_EMAIL_CONTENT_DOCUMENT_CHECKLIST_FAILURE:
      return { ...state, emailContentDocumentChecklistRes: [] };
    case types.GET_EMAIL_CONTENT_SUCCESS:
      return {
        ...state,
        emailContentRes: action.payload && action.payload.emailTemplates,
      };
    case types.GET_EMAIL_CONTENT_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, emailContentRes: [] };
      } else {
      }
      console.log("on error console", action.payload);
    case types.ADD_EMAIL_CONTENT_SUCCESS:
      return { ...state, addEmailContentRes: action.payload };
    case types.ADD_EMAIL_CONTENT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_EMAIL_CONTENT_SUCCESS:
      return { ...state, updateEmailContentRes: action.payload };
    case types.PUT_EMAIL_CONTENT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.SET_ACTIVE_INNER_TAB_SUCCESS:
      return { ...state, activeInnerTabValue: action.payload };

    default:
      return state;
  }
};
