import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import { apiRefresh } from "../../../services/api";

const HubspotSettings = ({ onSetActiveInnerTab }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const state = useLocation().state;

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
  }, []);

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
  };

  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img
                      src={Images.documentSync}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="top-text">Hubspot Setting</span>
                  {/* <Tooltip
                  placement="topLeft"
                  title={`When enabling folder view, will give you the ability to have specific folders with more of a classic folder view.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip> */}
                </div>

                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="default-font-box">
                <div>
                  <div className="client-tag-table">
                    <Form
                      onChange={onDocumentShow}
                      form={form}
                      className="d-flex space-between"
                    ></Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default HubspotSettings;
