import { api } from "../../services/api";
import * as types from "../Constants";

export const getDashboardReminders = (data) => {
  const options = {
    url: `v1/dashboard/Reminder`,
  };
  options.types = [
    types.GET_DASHBOARD_REMINDERS_SUCCESS,
    types.GET_DASHBOARD_REMINDERS_FAILURE,
  ];

  return api.post(options, data);
};

export const updateDashboardReminderStatus = (data) => {
  const options = {
    url: `v1/reminder/ReminderStatus`,
  };
  options.types = [
    types.UPDATE_DASHBOARD_REMINDER_STATUS_SUCCESS,
    types.UPDATE_DASHBOARD_REMINDER_STATUS_FAILURE,
  ];

  return api.put(options,data);
};
export const getDashboardRemindersTypes = (data) => {
  const options = {
    url: `v1/reminder/ReminderType`,
  };
  options.types = [
    types.GET_DASHBOARD_REMINDERS_TYPE_SUCCESS,
    types.GET_DASHBOARD_REMINDERS_TYPE_FAILURE,
  ];

  return api.get(options);
};
