import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const dashboardPotentialClientReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_POTENTIALCLIENT_SUCCESS:
      return { ...state, potentialClientList: action.payload };
    case types.GET_DASHBOARD_POTENTIALCLIENT_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, potentialClientError: action.payload };
    case types.GET_PROCESSING_PERSONS_SUCCESS:
      return { ...state, processingPersons: action.payload };
    case types.GET_PROCESSING_PERSONS_FAILURE:
      return { ...state, processingPersonsError: action.payload };
    default:
      return state;
  }
};
