import { api } from "../../services/api";
import * as types from "../Constants";
import {
  SEND_DYNAMIC_LINK_BLOB_FAILURE,
  SEND_DYNAMIC_LINK_BLOB_SUCCESS,
} from "../Constants";

export const createQuestionnaire = (data) => {
  const options = {
    url: "v1/questionnaire/RAddQuestionnaire",
  };

  options.types = [
    types.CREATE_QUESTIONNAIRE_SUCCESS,
    types.CREATE_QUESTIONNAIRE_FAILURE,
  ];

  return api.post(options, data);
};

export const getAllQuestionnaire = () => {
  var selectedBranchId = localStorage.getItem("selectedBranchId");
  const options = {
    url: "v1/questionnaire/GetAllQuestionnairs",
  };

  options.types = [
    types.GET_ALL_QUESTIONNAIRE_SUCCESS,
    types.GET_ALL_QUESTIONNAIRE_FAILURE,
  ];

  return api.get(options);
};

export const getQuestionnaire = (data) => {
  let clientId = "";
  let id = "";

  var options = null;
  if (data.clientId) {
    clientId = data.clientId;
    id = data.id;
    options = {
      url: `v1/questionnaire/RecursiveByClient/${id}/${clientId}`,
    };
  } else {
    options = {
      url: "v1/questionnaire/Recursive/" + data,
    };
  }

  options.types = [
    types.GET_QUESTIONNAIRE_SUCCESS,
    types.GET_QUESTIONNAIRE_FAILURE,
  ];

  return api.get(options);
};

export const getMappingParent = () => {
  const options = {
    url: "v1/parentbinding/All",
  };

  options.types = [
    types.GET_MAPPING_PARENT_SUCCESS,
    types.GET_MAPPING_PARENT_FAILURE,
  ];

  return api.get(options);
};

export const getMappingChild = (data) => {
  const options = {
    url: "v1/childbinding/GetAllChildBindingByParentId/" + data,
  };

  options.types = [
    types.GET_MAPPING_CHILD_SUCCESS,
    types.GET_MAPPING_CHILD_FAILURE,
  ];

  return api.get(options);
};

export const duplicateQuestionnaire = (data) => {
  const options = {
    url: "v1/questionnaire/DuplicateQuestionnaire",
  };

  options.types = [
    types.DUPLICATE_QUESTIONNAIRE_SUCCESS,
    types.DUPLICATE_QUESTIONNAIRE_FAILURE,
  ];

  return api.post(options, data);
};

export const createDynamicLink = (data) => {
  const options = {
    url: "v1/questionnaire/ShortLink",
  };

  options.types = [types.DYNAMIC_LINK_SUCCESS, types.DYNAMIC_LINK_FAILURE];

  return api.post(options, data);
};

export const getDynamicLink = (data) => {
  const options = {
    url: "v1/questionnaire/ShortLink/" + data,
  };

  options.types = [
    types.GET_DYNAMIC_LINK_SUCCESS,
    types.GET_DYNAMIC_LINK_FAILURE,
  ];

  return api.get(options);
};

export const sendDynamicLinkEmail = (data) => {
  const options = {
    url: "v1/emailqueue",
  };

  options.types = [
    types.SEND_DYNAMIC_LINK_SUCCESS,
    types.SEND_DYNAMIC_LINK_FAILURE,
  ];

  return api.post(options, data);
};

export const sendDynamicLinkEmailBlob = (data) => {
  const options = {
    url: "v1/emailqueue/EmailQueueWithBlobUrl",
  };

  options.types = [
    types.SEND_DYNAMIC_LINK_BLOB_SUCCESS,
    types.SEND_DYNAMIC_LINK_BLOB_FAILURE,
  ];

  return api.post(options, data);
};

export const getEmailTemplate = (data) => {
  const options = {
    url: "v1/HtmlTemplate/" + data,
  };

  options.types = [
    types.GET_EMAIL_TEMPLATE_SUCCESS,
    types.GET_EMAIL_TEMPLATE_FAILURE,
  ];

  return api.get(options, data);
};

export const setEmailTemplate = (data) => {
  const options = {
    url: "v1/HtmlTemplate/SetHtmlTemplate",
  };

  options.types = [
    types.SET_EMAIL_TEMPLATE_SUCCESS,
    types.SET_EMAIL_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const getQuestionnaireGroup = () => {
  const options = {
    url: "v1/questionnaire/QuestionnaireGroup",
  };

  options.types = [
    types.GET_QUESTIONNAIRE_GROUP_SUCCESS,
    types.GET_QUESTIONNAIRE_GROUP_FAILURE,
  ];

  return api.get(options);
};

export const groupedQuestionnaire = (id) => {
  const options = {
    url: `v1/questionnaire/GroupedQuestionnaire/${id}`,
  };

  options.types = [
    types.GET_GROUPED_QUESTIONNAIRE_SUCCESS,
    types.GET_GROUPED_QUESTIONNAIRE_FAILURE,
  ];

  return api.get(options);
};

export const getFilledQuestionnaire = (data) => {
  const options = {
    url: `v1/questionnaire/GetFilledQuestionnaire?Id=${data.id}&ClientId=${data.clientId}`,
  };

  options.types = [
    types.GET_FILLED_QUESTIONNAIRE_SUCCESS,
    types.GET_FILLED_QUESTIONNAIRE_FAILURE,
  ];

  return api.get(options);
};

export const getAllFilledQuestionnaire = (clientId) => {
  const options = {
    url: `v1/filledQuestionnaire/AllByClientId/${clientId}`,
  };

  options.types = [
    types.GET_ALL_FILLED_QUESTIONNAIRE_SUCCESS,
    types.GET_ALL_FILLED_QUESTIONNAIRE_FAILURE,
  ];

  return api.get(options);
};

export const mergeFromClient = (data) => {
  const options = {
    url: `v1/questionnaire/QuestionnaireToClient`,
  };

  options.types = [
    types.MERGE_FROM_CLIENT_SUCCESS,
    types.MERGE_FROM_CLIENT_FAILURE,
  ];

  return api.post(options, data);
};

export const updMergeFromClient = (data) => {
  const options = {
    url: `v1/questionnaire/QuestionnaireToClient`,
  };

  options.types = [
    types.UPD_MERGE_FROM_CLIENT_SUCCESS,
    types.UPD_MERGE_FROM_CLIENT_FAILURE,
  ];

  return api.put(options, data);
};

export const updMergeToClient = (data) => {
  const options = {
    url: `v1/questionnaire/QuestionnaireMapping`,
  };

  options.types = [
    types.UPD_MERGE_TO_CLIENT_SUCCESS,
    types.UPD_MERGE_TO_CLIENT_FAILURE,
  ];

  return api.put(options, data);
};

export const saveLink = (data) => {
  const options = {
    url: `v1/client/Link`,
  };

  options.types = [types.SAVE_LINK_SUCCESS, types.SAVE_LINK_FAILURE];

  return api.post(options, data);
};
