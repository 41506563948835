import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmployerManagement from "./../EmployerManagement/EmployerManagement/index";

import {
  getEmployerManag,
  addEmployerManag,
  deleteEmployerManag,
  getEmployerContact,
  addEmployerContact,
  getEmployerJob,
  addEmployerJob,
  getEmployerFile,
  addEmployerFile,
  searchEmployer,
  getEmployerData,
  removeEmployerContact,
  removeEmployerFile,
  updateEmployerManag,
  updateEmployerFile,
  updateEmployerContact,
  getEmployerDocument,
  addEmployerDocument,
  removeEmployerDocument,
  updateEmployerDocument,
  uploadAvatar,
  getDocumentChecklist,
  getEmployerCheckList,
  addEmployerCheckList,
  sendDynamicLinkEmail,
  getLetterTemplates,
  addEmployerEmail,
  getEmployerHistory,
  getAutoEmailImport,
  addAutoEmailImport,
  addDocumentTittle,
  addAnyTamplate,
  uploadFile,
  getTeamMember,
  deleteCreateEmail,
  getDocumentPdf,
  getDocumentDownload,
  getFilteredType,
  addFilteredType,
  updateEmployerJob,
  removeEmployerJob,
  getClientSearch,
  getClientEmp,
  searchMainClient,
  getClientByEmpId,
  linkEmpWithClient,
  addEmployerJobHistory,
  getClientTag,
  getProfileClientTag,
  addDocWithName,
  getClientJobHis,
  getSingleClientHis,
  movePotentialEmployer,
  getVisaApplication,
  getClientDocuments,
  getCaseDocuments,
  updateCaseHistory,
  getClientVisaFileNotes,
  removeCaseStatus,
  removeSubjectCase,
  getEmployerCase,
  getVisaTypeByCountry,
  startNewApplication,
  getVisaType,
  updateCaseStatus,
  getVisaStatus,
  getSignature,
  getDocuments,
  getImapForAll,
  getPdf,
  addEmailDocument,
  getAllQuestionnaire,
  createDynamicLink,
  getDynamicLink,
  getEmailTemplate,
  setEmailTemplate,
  getCountries,
  getQuestionnaire,
  updateQuestionnaire,
  getMappingParent,
  getMappingChild,
  getQuestionnaireGroup,
  groupedQuestionnaire,
  getFilledQuestionnaire,
  getAllFilledQuestionnaire,
  mergeFromClient,
  updMergeFromClient,
  updMergeToClient,
  submitQuestionnaire,
  addDraftClient,
  getEmailContent,
  setActiveKey,
  addProcessingPerson,
  getPermissonUser,
  getMailBox,
  autoImportEmailClient,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    employerManagRes: state.employerManagReducer.employerManagRes,
    addEmployerManagRes: state.employerManagReducer.addEmployerManagRes,
    deleteEmployerManagRes: state.employerManagReducer.deleteEmployerManagRes,
    EmployerContactRes: state.employerManagReducer.EmployerContactRes,
    addEmployerContactRes: state.employerManagReducer.addEmployerContactRes,
    employerJobRes: state.employerManagReducer.employerJobRes,
    addEmployerJobRes: state.employerManagReducer.addEmployerJobRes,
    employerFileRes: state.employerManagReducer.employerFileRes,
    addEmployerFileRes: state.employerManagReducer.addEmployerFileRes,
    searchEmployerRes: state.employerManagReducer.searchEmployerRes,
    singleEmployerRes: state.employerManagReducer.singleEmployerRes,
    delEmployerConRes: state.employerManagReducer.delEmployerConRes,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    delEmployerFilaRes: state.LetterTemplatesReducer.delEmployerFilaRes,
    updateEmployerRes: state.employerManagReducer.updateEmployerRes,
    updateEmployerfileRes: state.employerManagReducer.updateEmployerfileRes,
    updateEmployerContactRes:
      state.employerManagReducer.updateEmployerContactRes,
    employerDocumentRes: state.employerManagReducer.employerDocumentRes,
    addemployerDocumentRes: state.employerManagReducer.addemployerDocumentRes,
    remEmployerDocumentRes: state.employerManagReducer.remEmployerDocumentRes,
    updateEmployerDocumentRes:
      state.employerManagReducer.updateEmployerDocumentRes,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    employerCheckListRes: state.employerManagReducer.employerCheckListRes,
    addEmployerCheckListRes: state.docChecklistReducer.addEmployerCheckListRes,
    addEmployerEmailRes: state.employerManagReducer.addEmployerEmailRes,
    employerHistoryRes: state.employerManagReducer.employerHistoryRes,
    emailImportRes: state.employerManagReducer.emailImportRes,
    addEmailImportRes: state.employerManagReducer.addEmailImportRes,
    addDoumentTittleRes: state.employerManagReducer.addDoumentTittleRes,
    addAnyTemRes: state.employerManagReducer.addAnyTemRes,
    addUploadFileRes: state.employerManagReducer.addUploadFileRes,
    teamMembers: state.teamMemberReducer.teamMembers,
    removeCreateERes: state.employerManagReducer.removeCreateERes,

    documentFilterRes: state.clientProfileReducer.documentFilterRes,
    addDocumentFilterRes: state.clientProfileReducer.addDocumentFilterRes,
    updateJobRes: state.employerManagReducer.updateJobRes,
    removeJobRes: state.employerManagReducer.removeJobRes,
    clientSearchRes: state.employerManagReducer.clientSearchRes,
    clientEmpRes: state.employerManagReducer.clientEmpRes,
    searchClientData: state.clientProfileReducer.searchConnectionData,
    empClientEmpRes: state.employerManagReducer.empClientEmpRes,
    linkEmpClientRes: state.employerManagReducer.linkEmpClientRes,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    docWithNameRes: state.employerManagReducer.docWithNameRes,
    clientJobHisRes: state.employerManagReducer.clientJobHisRes,
    singleJobHisRes: state.employerManagReducer.singleJobHisRes,
    movePotentialEmpRes: state.employerManagReducer.movePotentialEmpRes,
    visaAppData: state.clientVisaReducer.visaAppData,
    caseDocumentData: state.clientVisaReducer.caseDocumentData,
    visaFileNotesRes: state.fileNotesReducer.visaFileNotesRes,
    employerCaseRes: state.employerManagReducer.employerCaseRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    documentRes: state.accountDocumentReducer.documentRes,
    imapForAllRes: state.imapSettingReducer.imapForAllRes,

    questionnaireList: state.questionnaireReducer.questionnaireList,
    dynamicLinkSuccess: state.questionnaireReducer.dynamicLinkSuccess,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    emailTemplateData: state.questionnaireReducer.emailTemplateData,
    setEmailData: state.questionnaireReducer.setEmailData,
    clientTab: state.employerManagReducer.clientTab,
    countriesData: state.visaTypeReducer.countriesData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    mappParentData: state.questionnaireReducer.mappParentData,
    mappChildData: state.questionnaireReducer.mappChildData,
    questionnaireGroupRes: state.questionnaireReducer.questionnaireGroupRes,
    groupedQuestionnaireRes: state.questionnaireReducer.groupedQuestionnaireRes,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    allFilledQuestionnaireRes:
      state.questionnaireReducer.allFilledQuestionnaireRes,
    mergeFromClientRes: state.questionnaireReducer.mergeFromClientRes,
    updMergeFromClientRes: state.questionnaireReducer.updMergeFromClientRes,
    updMergeToClientRes: state.questionnaireReducer.updMergeToClientRes,
    activeKey: state.employerManagReducer.activeKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onAddEmployerManag: bindActionCreators(addEmployerManag, dispatch),
    onDeleteEmployer: bindActionCreators(deleteEmployerManag, dispatch),
    ongetEmployerContact: bindActionCreators(getEmployerContact, dispatch),
    onAddEmployerContact: bindActionCreators(addEmployerContact, dispatch),
    onGetEmployerJob: bindActionCreators(getEmployerJob, dispatch),
    onAddEmployerJob: bindActionCreators(addEmployerJob, dispatch),
    onGetEmployerFile: bindActionCreators(getEmployerFile, dispatch),
    onAddEmployerFile: bindActionCreators(addEmployerFile, dispatch),
    onSearchEmployer: bindActionCreators(searchEmployer, dispatch),
    onGetEmployerData: bindActionCreators(getEmployerData, dispatch),
    onRemoveEmployerContact: bindActionCreators(
      removeEmployerContact,
      dispatch
    ),
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onRemoveEmployerFile: bindActionCreators(removeEmployerFile, dispatch),
    onUpdateEmployerManag: bindActionCreators(updateEmployerManag, dispatch),
    onUpdateEmployerFile: bindActionCreators(updateEmployerFile, dispatch),
    onUpdateEmployerContact: bindActionCreators(
      updateEmployerContact,
      dispatch
    ),
    onGetEmployerDocument: bindActionCreators(getEmployerDocument, dispatch),
    onAddEmployerDocument: bindActionCreators(addEmployerDocument, dispatch),
    onRemoveEmployerDocument: bindActionCreators(
      removeEmployerDocument,
      dispatch
    ),
    onUpdateEmployerDocument: bindActionCreators(
      updateEmployerDocument,
      dispatch
    ),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
    onGetEmployerCheckList: bindActionCreators(getEmployerCheckList, dispatch),
    onAddEmployerCheckList: bindActionCreators(addEmployerCheckList, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onAddEmployerEmail: bindActionCreators(addEmployerEmail, dispatch),
    onGetEmployerHistory: bindActionCreators(getEmployerHistory, dispatch),
    onGetAutoEmailImport: bindActionCreators(getAutoEmailImport, dispatch),
    onAddAutoEmailImport: bindActionCreators(addAutoEmailImport, dispatch),
    onAddDocumentTittle: bindActionCreators(addDocumentTittle, dispatch),
    onAddAnyTamplate: bindActionCreators(addAnyTamplate, dispatch),
    onUploadFile: bindActionCreators(uploadFile, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onDeleteCreateEmail: bindActionCreators(deleteCreateEmail, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onGetDocumentPdf: bindActionCreators(getDocumentPdf, dispatch),
    onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
    onAddFilteredType: bindActionCreators(addFilteredType, dispatch),
    onUpdateEmployerJob: bindActionCreators(updateEmployerJob, dispatch),
    onRemoveEmployerJob: bindActionCreators(removeEmployerJob, dispatch),
    onGetClientSearch: bindActionCreators(getClientSearch, dispatch),
    onGetClientEmp: bindActionCreators(getClientEmp, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onGetClientByEmpId: bindActionCreators(getClientByEmpId, dispatch),
    onLinkEmpWithClient: bindActionCreators(linkEmpWithClient, dispatch),
    onAddEmployerJobHistory: bindActionCreators(
      addEmployerJobHistory,
      dispatch
    ),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddDocWithName: bindActionCreators(addDocWithName, dispatch),
    onGetClientJobHis: bindActionCreators(getClientJobHis, dispatch),
    onGetSingleClientHis: bindActionCreators(getSingleClientHis, dispatch),
    onMovePotentialEmployer: bindActionCreators(
      movePotentialEmployer,
      dispatch
    ),
    onGetEmployerCase: bindActionCreators(getEmployerCase, dispatch),
    onGetClientDocument: bindActionCreators(getClientDocuments, dispatch),
    onGetCaseDocument: bindActionCreators(getCaseDocuments, dispatch),
    onUpdateCaseHistory: bindActionCreators(updateCaseHistory, dispatch),
    onGetVisaFileNotes: bindActionCreators(getClientVisaFileNotes, dispatch),
    onRemoveCaseStatus: bindActionCreators(removeCaseStatus, dispatch),
    onRemoveSubjectCase: bindActionCreators(removeSubjectCase, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetSignature: bindActionCreators(getSignature, dispatch),
    onGetDocuments: bindActionCreators(getDocuments, dispatch),
    onGetImapForAll: bindActionCreators(getImapForAll, dispatch),
    onGetPdf: bindActionCreators(getPdf, dispatch),
    onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),

    onGetAllQuestionnaire: bindActionCreators(getAllQuestionnaire, dispatch),
    onCreateDynamicLink: bindActionCreators(createDynamicLink, dispatch),
    onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
    onGetEmailTemplate: bindActionCreators(getEmailTemplate, dispatch),
    // onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onSetEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onUpdateQuestionnaire: bindActionCreators(updateQuestionnaire, dispatch),
    onGetMappingParent: bindActionCreators(getMappingParent, dispatch),
    onGetMappingChild: bindActionCreators(getMappingChild, dispatch),
    onGetQuestionnaireGroup: bindActionCreators(
      getQuestionnaireGroup,
      dispatch
    ),
    onGetGroupedQuestionnaire: bindActionCreators(
      groupedQuestionnaire,
      dispatch
    ),
    onGetFilledQuestionnaire: bindActionCreators(
      getFilledQuestionnaire,
      dispatch
    ),
    onGetAllFilledQuestionnaire: bindActionCreators(
      getAllFilledQuestionnaire,
      dispatch
    ),
    onMergeFromClient: bindActionCreators(mergeFromClient, dispatch),
    onUpdMergeFromClient: bindActionCreators(updMergeFromClient, dispatch),
    onUpdMergeToClient: bindActionCreators(updMergeToClient, dispatch),
    onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
    // onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onAddDraftClient: bindActionCreators(addDraftClient, dispatch),
    onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onAddProcessingPerson: bindActionCreators(addProcessingPerson, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetMailBox: bindActionCreators(getMailBox, dispatch),
    onAutoImportEmailClient: bindActionCreators(
      autoImportEmailClient,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerManagement);
