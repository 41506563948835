import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PreviewQuestionnaire from "../CustomQuestionnaire/Preview";

// import { userLogin, getUserDetail } from "../store/Actions";

const mapStateToProps = state => {
  return {
    // userData: state.userReducer.userData,
    // loginError: state.userReducer.loginError,
    // userDetail: state.userDetailReducer.userDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // onUserLogin: bindActionCreators(userLogin, dispatch),
    // onGetUserDetail: bindActionCreators(getUserDetail, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewQuestionnaire);
