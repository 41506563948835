import React from "react";
import {
  FormOutlined,
  DeleteOutlined,
  CopyOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";

import {
  Collapse,
  List,
  Spin,
  Modal,
  Form,
  Button,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Avatar,
  DatePicker,
  message,
  Table,
  Switch,
} from "antd";
import { openNotificationWithIcon } from "../../Common/reUseFunction";
import moment from "moment";
const { Panel } = Collapse;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { TextArea } = Input;

class Students extends React.Component {
  formRef = React.createRef();

  state = {
    initLoading: true,
    loading: false,
    data: [],
    batches: [],
    batchModal: false,
    isUpdate: false,
    currentBatch: null,
  };
  componentDidMount = () => {
    this.setState({ loading: true });
    this.getBatches();
  };
  getBatches = () => {
    this.props.onGetAllBatches().catch(() => this.setState({ loading: false }));
  };
  componentWillReceiveProps = (props, newprop) => {
    props.batches?.batches &&
      this.setState({
        batches: props.batches?.batches,
        pages: props.batches?.count,
        loading: false,
      });
  };
  createBatchModal = () => {
    const { batchModal, loading, isUpdate } = this.state;
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="50%"
          visible={batchModal}
          onCancel={() => this.setState({ batchModal: false })}
          footer={null}
          maskClosable={false}
          header={true}
          title={`${isUpdate ? "Update" : "Create"} Batch`}
        >
          <div style={{ width: "100%" }}>
            <div>
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={this.createBatch}
              >
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Batch Name"
                  name="batchName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input />
                </Form.Item>
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Batch Unique Number"
                  name="batchUniqueNumber"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input />
                </Form.Item>
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="Start Date"
                  name="startDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                  colon={true}
                  labelAlign="left"
                  label="End Date"
                  name="endDate"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button disabled={loading} type="primary" htmlType="submit">
                    {isUpdate ? "UPDATE" : "CREATE"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
  createBatch = (values) => {
    const { isUpdate, currentBatch } = this.state;
    this.setState({ loading: true });

    let batchFunc = isUpdate
      ? this.props.onUpdateBatch
      : this.props.onCreateBatch;
    batchFunc({ ...values, id: currentBatch?.id ? currentBatch.id : 0 })
      .then(() => {
        this.setState({ loading: false, batchModal: false }, () => {
          message.success(
            `Batch ${isUpdate ? "Updated" : "Created"} Successfully!`
          );
          this.getBatches();
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Some error occured!");
      });
  };
  columns = [
    {
      title: "Batch Name",
      dataIndex: "batchName",
      key: "batchName",
      align: "center",
    },
    {
      title: "Batch Unique Number",
      dataIndex: "batchUniqueNumber",
      key: "batchUniqueNumber",
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      render: (item) => moment(item).format("DD/MM/YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (item) => moment(item).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "isFreezed",
      key: "isFreezed",
      align: "center",

      render: (item, record) => (
        <Popconfirm
          title={`Are you sure to ${
            record.isFreezed ? "enable" : "block"
          } this batch?`}
          onConfirm={() => {
            record.statusloading = true;
            this.setState({ statusloading: true });
            this.props
              .onUpdateBatch({
                ...record,
                isFreezed: !record.isFreezed,
              })
              .then((res) => {
                message.success("Batch Updated!");
                record.statusloading = false;
                this.setState({ statusloading: false });
                this.getBatches();
              })
              .catch((er) => {
                message.error("Some error occured!");
                record.statusloading = false;
                this.setState({ statusloading: false });
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <Switch loading={record.statusloading} checked={!record.isFreezed} />
        </Popconfirm>
      ),
    },
    {
      title: "Actions",
      align: "center",
      render: (item) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="ts-send-btn"
            size="medium"
            style={{ marginLeft: 4, marginRight: 4 }}
            type="primary"
            key="list-loadmore-more"
            onClick={() =>
              this.props.history.push({
                pathname: `/supervisor/batches/students`,
                state: { batchName: item.batchName, batchId: item.id },
              })
            }
          >
            View Accounts
          </Button>{" "}
          <Button
            className="ts-send-btn"
            size="medium"
            style={{ marginLeft: 4, marginRight: 4 }}
            type="primary"
            key="list-loadmore-edit"
            onClick={() => this.enableUpdate(item)}
          >
            Update
          </Button>{" "}
          <Popconfirm
            title="Are you sure to delete this batch?"
            onConfirm={() => {
              this.setState({ loading: true });
              this.props
                .onRemoveBatch({
                  id: item.id,
                  delete: true,
                  modifedBy: localStorage.getItem("userId"),
                })
                .then(() => {
                  message.success("Batch Deleted!");
                  this.getBatches();
                })
                .catch(() => {
                  message.error("Some error occured!");
                  this.setState({ loading: false });
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="ts-send-btn"
              size="medium"
              style={{ marginLeft: 4, marginRight: 4 }}
              type="primary"
              key="list-loadmore-more"
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  enableUpdate = (item) => {
    this.setState(
      {
        isUpdate: true,
        batchModal: true,
        currentBatch: item,
      },
      () =>
        this.formRef.current.setFieldsValue({
          batchName: item.batchName,
          batchUniqueNumber: item.batchUniqueNumber,
          startDate: moment(item.startDate),
          endDate: moment(item.endDate),
        })
    );
  };

  render() {
    const { loading, batches, pages } = this.state;
    return (
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="list-heading">
                <span className="header-text" style={{ fontWeight: "500" }}>
                  Batches
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div className="file-notes-container supervisor-batches">
                  <div
                    style={{ position: "relative", justifyContent: "flex-end" }}
                  >
                    <span>
                      {" "}
                      <Button
                        className="ts-send-btn"
                        size="medium"
                        type="primary"
                        key="list-loadmore-more"
                        onClick={() =>
                          this.setState({ batchModal: true }, () =>
                            this.formRef?.current?.resetFields()
                          )
                        }
                      >
                        Create Batch
                      </Button>
                    </span>
                  </div>
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    columns={this.columns}
                    dataSource={batches}
                    pagination={{
                      defaultCurrent: 1,
                      total: pages,
                      showSizeChanger: true,
                      defaultPageSize: 20,
                      pageSizeOptions: ["5", "10", "15", "20", "50"],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.createBatchModal()}
      </Spin>
    );
  }
}

export default Students;
