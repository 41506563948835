import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import { Modal, Spin, Form, Select, Button, Tooltip, message } from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { redirectOutlookUrl } from "../../../config";
import { openNotificationWithIcon } from "../../../Common/reUseFunction";
import "./OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

var location = window.location.href;
var searchLocation = window.location.search;
const OutlookIntegration = ({
  onSetActiveInnerTab,
  onGetOutlookMail,
  onOutlookLogin,
  onAddOutlookAccount,
  onRemoveOutlookAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [outlook, setOutlookObj] = useState(null);
  const state = useLocation().state;

  const redirection = () => {
    setLoading(true);
    onGettingOutlookRedirectParams();
  };
  const getAllData = () => {
    if (!outlook) {
      setLoading(true);
      onGetOutlookMail()
        .then((res) => {
          setLoading(false);
          setOutlookObj(res.payload);
        })
        .catch((er) => {
          setLoading(false);
        });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirection(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllData(), []);
  const loginOnOutlook = () => {
    setLoading(true);
    onOutlookLogin(redirectOutlookUrl)
      .then((response) => {
        setLoading(false);
        // if (response.payload) window.location = response.payload;
        if (response.payload) window.open(response.payload, "_blank");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onGettingOutlookRedirectParams = () => {
    return new Promise(async (resolve) => {
      let params = new URLSearchParams(searchLocation);
      let code = params.get("code");
      if (code) {
        onAddOutlookAccount({
          code: code,
          redirectUrl: redirectOutlookUrl,
        })
          .then(() => {
            searchLocation = "";
            getAllData();
            setLoading(false);
            resolve(true);
          })
          .catch(() => {
            searchLocation = "";
            setLoading(false);
            resolve(false);
          });
      }
      setLoading(false);
      resolve(false);
    });
  };

  const removeOutlookAccount = () => {
    setLoading(true);
    onRemoveOutlookAccount()
      .then(() => {
        setOutlookObj(null);
        setLoading(false);
      })
      .catch(() => {
        message.error("Could't remove outlook account!");
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img src={Images.outlookInt} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">Outlook Integration</span>
                  <Tooltip
                    placement="topLeft"
                    title={`Add outlook for sync your mails in ezymigrate.`}
                  >
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <Form>
                  <div className="w-80">
                    <h3>OUTLOOK</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {outlook ? (
                              <div className="doc-office">
                                <div className="userDetail">
                                  {outlook.displayName && (
                                    <p>Name: {outlook.displayName}</p>
                                  )}
                                  {outlook.userPrincipalName && (
                                    <p>Email: {outlook.userPrincipalName}</p>
                                  )}
                                  <a
                                    className="remove-account"
                                    onClick={() => removeOutlookAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button onClick={loginOnOutlook} type="primary">
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
                  <div className="w-80">
                    <h3>ONE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {oneDrive ? (
                              <div className="doc-office ">
                                <div className="userDetail">
                                  <p>Name: {oneDrive.displayName}</p>
                                  <p>Email: {oneDrive.userPrincipalName}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeOneDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button
                                  onClick={loginOnOneDrive}
                                  type="primary"
                                >
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>GOOGLE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {googleDrive ? (
                              <div className="doc-office">
                                <div className="d-flex userDetail">
                                  <p>Name: {googleDrive.name}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeGoogleDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item
                                onClick={loginOnGoogleDrive}
                                className="d-block"
                              >
                                <Button type="primary">ADD ACCOUNT</Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>OFFICE 365</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            <div>
                              {office365 ? (
                                <div className="doc-office">
                                  <div className="d-flex userDetail">
                                    <p>Name:{office365.displayName}</p>
                                    <a
                                      className="remove-account"
                                      onClick={() => removeOffice365Account()}
                                    >
                                      X
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <Form.Item>
                                  <Button
                                    onClick={loginOnOffice365}
                                    className="margin-top"
                                    type="primary"
                                  >
                                    ADD ACCOUNT
                                  </Button>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default OutlookIntegration;
