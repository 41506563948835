import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Input,
} from "antd";
// import "./OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const EzyformKey = ({
  onSetActiveInnerTab,
  //   onGetOutlookMail,
  //   onOutlookLogin,
  //   onAddOutlookAccount,
  //   onRemoveOutlookAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [editEnable, setEditEnable] = useState(false);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const state = useLocation().state;

  const [form] = Form.useForm();

  useEffect(() => {
    getApiKey();
  }, []);

  const getApiKey = () => {
    const getApiKeyOpt = {
      url: `v1/ThirdPartyKey/GetByBranchId/EzyForm`,
    };
    getApiKeyOpt.types = [
      "GET_EZYFORM_API_KEY_SUCCESS",
      "GET_EZYFORM_API_KEY_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .get(getApiKeyOpt)
      .then((res) => {
        setLoading(false);
        setApiKey(res && res.length > 0 ? res[0] : null);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (editEnable) {
      let data = {
        id: apiKey.id,
        apiKey: values.ipAddress || "",
      };
      const getApiKeyOpt = {
        url: `v1/ThirdPartyKey`,
      };
      getApiKeyOpt.types = [
        "RE_GENERATE_API_KEY_SUCCESS",
        "RE_GENERATE_API_KEY_FAILURE",
      ];

      setLoading(true);

      apiRefresh
        .put(getApiKeyOpt, data)
        .then((res) => {
          getApiKey();
          setLoading(false);
          form.resetFields();
          setEditEnable(false);
          setShowCopyButton(true);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed!");
        });
    } else {
      let data = {
        apiKey: values.ipAddress || "",
        keyType: "EzyForm",
      };
      const getApiKeyOpt = {
        url: `v1/ThirdPartyKey`,
      };
      getApiKeyOpt.types = [
        "GENERATE_API_KEY_SUCCESS",
        "GENERATE_API_KEY_FAILURE",
      ];

      setLoading(true);

      apiRefresh
        .post(getApiKeyOpt, data)
        .then((res) => {
          getApiKey();
          setLoading(false);
          form.resetFields();
          setEditEnable(false);
          setShowCopyButton(true);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed!");
        });
    }
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img src={Images.apiKeyIcon} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">Ezyforms Api Key</span>
                  <Tooltip placement="topLeft" title={``}>
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <Form onFinish={onFinish} form={form}>
                  <div className="w-80">
                    <h3>EZYFORMS API KEY</h3>
                    <div
                      className="document-sync-box"
                      style={{
                        paddingLeft: apiKey && !editEnable ? 20 : 65,
                        paddingRight: apiKey && !editEnable ? 20 : 65,
                      }}
                    >
                      <div>
                        <div className="client-tag-table">
                          <div
                            className="reminder-set-form"
                            style={{ marginTop: 20 }}
                          >
                            {apiKey && !editEnable ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 15,
                                  }}
                                >
                                  <Button
                                    style={{
                                      fontSize: 10,
                                      fontWeight: "500",
                                      padding: 5,
                                      borderRadius: 5,
                                    }}
                                    // disabled={!showCopyButton}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        apiKey.apiKey
                                      );
                                      message.info("Copied!");
                                    }}
                                  >
                                    Copy
                                  </Button>
                                  <Button
                                    style={{
                                      fontSize: 10,
                                      fontWeight: "500",
                                      padding: 5,
                                      borderRadius: 5,
                                      marginLeft: 5,
                                    }}
                                    className="button-blue"
                                    onClick={() => {
                                      setEditEnable(true);
                                    }}
                                  >
                                    Re-Create
                                  </Button>
                                </div>
                                <div>
                                  <div className="userDetail">
                                    <p
                                      style={{
                                        overflowWrap: "break-word",
                                        color: "#555",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {apiKey.apiKey}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Form.Item
                                  className="d-block"
                                  name="ipAddress"
                                  // rules={[{ required: true }]}
                                >
                                  <Input placeholder="Ip Address" />
                                </Form.Item>
                                <Form.Item className="d-block">
                                  <Button type="primary" htmlType="submit">
                                    Save Key
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default EzyformKey;
