import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Form,
  Table,
  Modal,
  Spin,
  Tabs,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getDueOverDueInvoices,
  getInvoiceInfo,
  getParentInvoiceListing,
  getProfileClientTag,
  getSchoolInvoiceInfo,
} from "../../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class AllInvoices extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      branchId: selectedBranchId,
      subjectTypeId: 2,
      pageNumber: 1,
      pageSize: 20,
      dateFrom: "1900-01-01T00:00:00+00:00",
      dateTo: "1900-01-01T00:00:00+00:00",
      dueOverInvoices: [],
      loading: false,
      totalPages: 0,
      defaultCurrent: 1,
      loadingTable: false,
      name: "",
      email: "",
      invoiceNo: "",
      clientTagId: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getDueOverDueInvoices(this.state)
      .then(() => {
        this.props.getClientTag();
        this.setState({ loading: false });
      })
      .catch(() => {
        this.props.getClientTag();
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getClientTag().then(() => {});
      });
    }
    let _dueOverInvoices =
      nextProps && nextProps.dueOverInvoices && nextProps.dueOverInvoices;
    let _dueOverInvoicesCount =
      nextProps &&
      nextProps.dueOverInvoicesCount &&
      nextProps.dueOverInvoicesCount;
    this.setState({
      dueOverInvoices: _dueOverInvoices,
      totalPages: _dueOverInvoicesCount,
    });
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  show = () => {
    this.setState({ pageNumber: 1, pageSize: 20, loadingTable: true }, () => {
      this.props
        .getDueOverDueInvoices(this.state)
        .then(() => {
          let _length;
          _length =
            this.props &&
            this.props.dueOverInvoicesCount &&
            this.props.dueOverInvoicesCount;
          this.setState({
            defaultCurrent: 1,
            loadingTable: false,
            totalPages: _length,
          });
        })
        .catch(() => {
          this.setState({ loadingTable: false });
        });
    });
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        loadingTable: true,
      },
      () => {
        this.props
          .getDueOverDueInvoices(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.dueOverInvoicesCount &&
              this.props.dueOverInvoicesCount;
            this.setState({
              loadingTable: false,
              defaultCurrent: value.current,
              totalPages: _length,
            });
          })
          .catch(() => {
            this.setState({ loadingTable: false });
          });
      }
    );
  };

  showAll = () => {
    this.setState({ loading: true });
    this.setState(
      {
        subjectTypeId: 2,
        pageNumber: 1,
        pageSize: 20,
        dateFrom: "1900-01-01T00:00:00+00:00",
        dateTo: "1900-01-01T00:00:00+00:00",
        name: "",
        email: "",
        clientTagId: 0,
        invoiceNo: "",
      },
      () => {
        this.props
          .getDueOverDueInvoices(this.state)
          .then(() => {
            let _length;
            _length =
              this.props &&
              this.props.dueOverInvoicesCount &&
              this.props.dueOverInvoicesCount;
            this.setState({
              loading: false,
              defaultCurrent: 1,
              totalPages: _length,
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    );
  };
  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeInvoiceNumber = (e) => {
    this.setState({ invoiceNo: e.target.value });
  };

  getFiltersSection = () => {
    return (
      <div>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Name</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input size="medium" onChange={this.handleChangeName} />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Email</p>
              </div>
              <div style={{ width: "80%" }}>
                <Input size="medium" onChange={this.handleChangeEmail} />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "19px" }}>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Date From</p>
              </div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  placeholder="Date From"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateFrom}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>Date To</p>
              </div>
              <div style={{ width: "80%" }}>
                <DatePicker
                  placeholder="Date To"
                  size="small"
                  format={dateFormat}
                  onChange={this.handleChangeDateTo}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>
                  Invoice Number
                </p>
              </div>
              <div style={{ width: "80%" }}>
                <Input
                  size="medium"
                  onChange={this.handleChangeInvoiceNumber}
                />
              </div>
            </div>
          </Col>
          <Col xs={11} offset={1}>
            <div style={{ width: "100%", display: "flex" }}>
              <div className="xero-filters-label">
                <p className={"font-school-list-filter-text-sub"}>
                  Client Tags
                </p>
              </div>
              <div style={{ width: "80%" }}>
                <Select
                  style={{ marginLeft: "8px" }}
                  className={"width-web-kit"}
                  placeholder={"Select"}
                  onChange={this.handleChangeClientTags}
                  value={
                    this.state.clientTagId === 0
                      ? undefined
                      : this.state.clientTagId
                  }
                >
                  <Option value={0}>{"Select"}</Option>
                  {this.props &&
                    this.props.clientTagRes &&
                    this.props.clientTagRes.items &&
                    this.props.clientTagRes.items.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "19px", marginTop: "19px" }}>
          <Col xs={4} offset={20}>
            <div style={{ display: "flex" }}>
              <Button
                className="button-blue"
                size="medium"
                type="primary"
                style={{ marginRight: "4px" }}
                onClick={this.showAll}
              >
                Show All
              </Button>
              <Button
                className="button-blue"
                size="medium"
                type="primary"
                onClick={this.show}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  handleClickViewDetail = () => {
    this.setState({ parentInvoiceId: 0 }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
      console.log("parentinvoiceid", this.state.idInvoice);
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete invoice?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleDeleteInvoice(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoice = (id) => {
    this.props.deleteInvoice(id).then(() => {
      message.success("Invoice is deleted successfully!");
      this.setState({ visibleDeleteModal: false });
      setTimeout(() => {
        this.setState({ expandedRowKeys: [], loading: true });
        this.props
          .getParentInvoiceListing(this.state)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }, 2000);
    });
  };

  columns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Date",
      dataIndex: "dateStr",
      key: "dateStr",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dateStr).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.dueDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text, record) => {
        let _items =
          record.payments && record.payments.items && record.payments.items;
        let _amount = 0;
        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.invoiceTypeName === "ProForma Invoice"
              ? _amount + record.paidAmount
              : _amount}
          </p>
        );
      },
    },
    {
      title: "Due/Over Due Amount",
      dataIndex: "dueAmount",
      key: "dueAmount",
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <a
          onClick={() => {
            this.setState({ loading: true });
            this.props
              .getInvoiceInfo(record.id)
              .then(() => {
                this.setState({ loading: false });
                this.handleClickViewDetail();
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }}
        >
          {"View Details"}
        </a>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  onClickPostToXero = (id) => {
    this.setState({ loading: true });
    if (this.state.subjectTypeId === 5) {
      this.props
        .getSchoolInvoiceInfo(id)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push("/xero-sync-school-invoice");
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .getInvoiceInfo(id)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push("/xero-sync-invoice");
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onChange = (value) => {
    this.setState({ subjectTypeId: value }, () => {
      this.setState({ loadingTable: true });
      this.props
        .getDueOverDueInvoices(this.state)
        .then(() => {
          this.setState({ loadingTable: false });
        })
        .catch(() => {
          this.setState({ loadingTable: false });
        });
    });
  };

  getTable = () => {
    return (
      <div>
        <Row>
          <Col xs={6} style={{ marginBottom: "37px" }}>
            <Select
              placeholder="Select Invoice Department"
              value={this.state.subjectTypeId}
              onChange={this.onChange}
            >
              <Option value={2}>Client Invoices</Option>
              <Option value={3}>Potential Client Invoices</Option>
              <Option value={4}>Employer Invoices</Option>
              <Option value={5}>School Invoices</Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {this.state.loadingTable ? (
              <div className={"spinner-xero"}>
                <Spin size="large" />
              </div>
            ) : (
              <Table
                dataSource={
                  this.state.dueOverInvoices ? this.state.dueOverInvoices : null
                }
                columns={this.columns}
                onChange={this.paginate}
                pagination={{
                  defaultCurrent: this.state.defaultCurrent,
                  total: this.state.totalPages,
                  defaultPageSize: this.state.pageSize,
                  pageSizeOptions: ["20", "50", "100"],
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <Fragment>
            <div className="ts-container-import-clients">
              {this.getFiltersSection()}
            </div>
            <div className="ts-container-import-clients">{this.getTable()}</div>
            {this.getModalDelete()}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    dueOverInvoices:
      state.accountReducer &&
      state.accountReducer.dueOverInvoices &&
      state.accountReducer.dueOverInvoices.items &&
      state.accountReducer.dueOverInvoices.items,
    dueOverInvoicesCount:
      state.accountReducer &&
      state.accountReducer.dueOverInvoicesCount &&
      state.accountReducer.dueOverInvoicesCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  getSchoolInvoiceInfo: bindActionCreators(getSchoolInvoiceInfo, dispatch),
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getDueOverDueInvoices: bindActionCreators(getDueOverDueInvoices, dispatch),
});
AllInvoices = connect(mapStateToProps, mapDispatchToProps)(AllInvoices);

export default withRouter(AllInvoices);
