import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Progress,
} from "antd";
import moment from "moment";

import Form from "antd/lib/form/Form";
import { PriorityHigh } from "@material-ui/icons";
// import activityData from "../ClientActivity/ActivityData";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const UpdateCaseStatus = ({
  visaStatusData,
  visa,
  onUpdateCaseStatus,
  onGetEmployerCase,
  userDataEmp,
  index,
  onCloseModal,
}) => {
  const [caseStatusId, setCaseStatusId] = useState(
    visa.caseStatusId === 5 ? "47" : visa.caseStatusId.toString()
  );
  const [showFields, setShowField] = useState("0");
  const [caseStatusDate, setCaseStatusDate] = useState("");
  const [caseExpiryDate, setCaseExpiryDate] = useState("");
  const [caseApprovedDate, setCaseApprovedDate] = useState("");
  const [caseRefusedDate, setCaseRefusedDate] = useState("");
  const [refusedDescription, setRefusedDescription] = useState("");
  const [
    caseAssessmentCompletedDate,
    setCaseAssessmentCompletedDate,
  ] = useState("");
  const [caseAIPDate, setCaseAIPDate] = useState("");
  const [description, setDesctiption] = useState("");
  const [contractSignedDate, setContractSignedDate] = useState("");
  const [declinedDate, setDeclinedDate] = useState("");
  const [caseReceivedDate, setCaseReceivedDate] = useState("");
  const [caseDueDate, setCaseDueDate] = useState("");
  const [caseExtendedDate, setCaseExtendedDate] = useState("");
  const [casePPIReceivedDate, setCasePPIReceivedDate] = useState("");
  const [casePPIDueDate, setCasePPIDueDate] = useState("");
  const [casePPIExtendedDate, setCasePPIExtendedDate] = useState("");
  const [casePPISubmitedDate, setCasePPISubmitedDate] = useState("");
  const [caseAppliedDate, setCaseAppliedDates] = useState("");
  const [caseEoiSelectedDate, setCaseEoiSelectedDate] = useState("");
  const [caseEoiItaDate, setCaseEoiItaDate] = useState("");
  const [declinedDescription, setDeclinedDescriptionDate] = useState("");
  const [paid, setPaid] = useState(visa.paid);
  const [signed, setSigned] = useState(visa.contractSigned);
  const [lowPriority, setLowPriority] = useState(false);
  const [mediumPriority, setMediumPriority] = useState(false);
  const [hightPriority, setHighPriority] = useState(false);
  const [loadUpdCase, setLoadUpdCase] = useState(false);

  if (visaStatusData && visaStatusData.items) {
    var visaStatuses = [];
    for (var i = 0; i < visaStatusData.items.length; i++) {
      visaStatuses.push(
        <Option key={visaStatusData.items[i].id}>
          {visaStatusData.items[i].name}
        </Option>
      );
    }
  }

  const onChange = (value) => {
    console.log(`selected ${value}`);

    setCaseStatusId(value);
    setShowField(value);
  };

  const onChangeDate = (value, dateString) => {
    setCaseStatusDate(value);
  };

  const onChangeExpiryDate = (value, dateString) => {
    setCaseExpiryDate(value);
  };

  const onChangeApprovedDate = (value, dateString) => {
    setCaseApprovedDate(value);
  };

  const onChangeRefusedDate = (value, dateString) => {
    setCaseRefusedDate(value);
  };

  const onChangeRefusedDescription = (value) => {
    setRefusedDescription(value);
  };

  const onChangeAssessmentDate = (value, dateString) => {
    setCaseAssessmentCompletedDate(value);
  };

  const onChangePPiSubmitedDate = (value, dateString) => {
    setCasePPISubmitedDate(value);
  };

  const onChangeContractDate = (value, dateString) => {
    setContractSignedDate(value);
  };

  const onChangeDeclinedDate = (value, dateString) => {
    setDeclinedDate(value);
  };

  const onChangeDeclinedDescription = (value) => {
    setDeclinedDescriptionDate(value.target.value);
  };

  const onChangeAipDate = (value, dateString) => {
    setCaseAIPDate(value);
  };

  const onChangeReceivedDate = (value, dateString) => {
    setCaseReceivedDate(value);
  };

  const onChangeDueDate = (value, dateString) => {
    setCaseDueDate(value);
  };

  const onChangeExtendedDate = (value, dateString) => {
    setCaseExtendedDate(value);
  };

  const onChangeAppliedDate = (value, dateString) => {
    setCaseAppliedDates(value);
  };

  const onChangeDescription = (value) => {
    setDesctiption(value.target.value);
  };

  const onChangeEoiItaDate = (value, dateString) => {
    setCaseEoiItaDate(value);
  };

  const onChangeEoiSelectedDate = (value, dateString) => {
    setCaseEoiSelectedDate(value);
  };

  const updateStatus = (visa) => {
    let data = {
      id: visa.id,
      caseStatusId: parseInt(caseStatusId) || 0,
      approveDate: caseApprovedDate || "1900-01-01T00:00:00+00:00",
      expiryDate: caseExpiryDate || "1900-01-01T00:00:00+00:00",
      date: caseStatusDate || new Date(),
      branchVisaTypeId: visa.branchVisaTypeId || 0,
      description: description || "",
      receivedDate: caseReceivedDate || "1900-01-01T00:00:00+00:00",
      dueDate: caseDueDate || "1900-01-01T00:00:00+00:00",
      extendDueDate: caseExtendedDate || "1900-01-01T00:00:00+00:00",
      submittedDate: casePPISubmitedDate || "1900-01-01T00:00:00+00:00",
      aipDate: caseAIPDate || "1900-01-01T00:00:00+00:00",
      eoiSubmitedDate: "1900-01-01T00:00:00+00:00",
      eoiSelectedDate: caseEoiSelectedDate || "1900-01-01T00:00:00+00:00",
      eoiitaDate: caseEoiItaDate || "1900-01-01T00:00:00+00:00",
      declineDate: declinedDate || "1900-01-01T00:00:00+00:00",
      declineDescription: declinedDescription || "",
      assessmentCompletedDate:
        caseAssessmentCompletedDate || "1900-01-01T00:00:00+00:00",
      contractSigned: signed,
      contractSignedDate: contractSignedDate || "1900-01-01T00:00:00+00:00",
      refusedDate: caseRefusedDate || "1900-01-01T00:00:00+00:00",
      refusedDescription: refusedDescription || "",
    };
    var caseStatus = visaStatusData.items.find((obj) => obj.id == caseStatusId);
    var caseStatusName = "";
    if (caseStatus) {
      caseStatusName = caseStatus.name;
    }

    setLoadUpdCase(true);
    onUpdateCaseStatus(data)
      .then((res) => {
        message.success("Visa status updated successfully");
        onGetEmployerCase(userDataEmp.id);
        onCloseModal();
        // onSetActiveKey("1");
        // setShowField("0");
        // let userName = localStorage.getItem("userName");
        // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        // let myData = {
        //   clientName: profileData.fullName,
        //   logMessage:
        //     "visa status updated to " +
        //     caseStatusName +
        //     " for " +
        //     visa.branchVisaTypeName +
        //     " by " +
        //     userName,
        //   date: moment(new Date()).format("DD/MM/YYYY"),
        //   logType: "Client Visa",
        //   invoiceId: "0",
        // };
        // activityData(myData);
        setLoadUpdCase(false);
      })
      .catch(() => {
        message.error("Failed to update visa status!");
        setLoadUpdCase(false);
      });
  };

  return (
    <Fragment>
      <div
        className="form-container"
        style={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: index == 0 ? 0 : 20,
        }}
      >
        <Form>
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <span className="visa-type-text">{visa.branchVisaTypeName}</span>
            {visa.caseLinks &&
              visa.caseLinks.items &&
              visa.caseLinks.items.length > 0 && (
                <span style={{ marginLeft: 40 }}>
                  {"(" + visa.caseLinks.items[0].firstName + ")"}
                </span>
              )}
          </div>

          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <span className="visa-date-text">
              {"Visa Status Date: " +
                moment(visa.startDate).format("DD/MM/YYYY")}
            </span>
          </div>

          {/* <div className="profile-progressbar-cont">
             <Progress percent={30} />
            <ProgressBar bgcolor="#CCCCCC" completed={30} color="#9D0C0E" />
          </div> */}

          <div style={{ padding: 10 }}>
            {/* <Form.Item name="caseStatusId"> */}
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              placeholder="Select Visa Status"
              onChange={onChange}
              value={caseStatusId}
              dropdownClassName="dropdown-options-width"
            >
              {visaStatuses}
            </Select>
            {/* </Form.Item> */}
          </div>
          {/* Approved */}
          {showFields === "10" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                {/* <Form.Item name="expiryDate"> */}
                <DatePicker
                  placeholder="Expiry Date"
                  onChange={onChangeExpiryDate}
                  format={dateFormat}
                  value={caseExpiryDate}
                />
                {/* </Form.Item> */}
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                {/* <Form.Item name="approveDate"> */}
                <DatePicker
                  placeholder="Approved Date"
                  onChange={onChangeApprovedDate}
                  format={dateFormat}
                  value={caseApprovedDate}
                />
                {/* </Form.Item> */}
              </div>
            </>
          )}
          {/* Refused */}
          {showFields === "50" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                {/* <Form.Item name="refusedDate"> */}
                <DatePicker
                  value={caseRefusedDate}
                  onChange={onChangeRefusedDate}
                  format={dateFormat}
                  placeholder="Refused Date"
                />
                {/* </Form.Item> */}
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    vlaue={refusedDescription}
                    onChange={onChangeRefusedDescription}
                    className="profile-input"
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
            </>
          )}
          {/* Assessment */}
          {showFields == "13" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAssessmentCompletedDate}
                onChange={onChangeAssessmentDate}
                format={dateFormat}
                placeholder="Assessment Completed Date"
              />
            </div>
          )}
          {/* Contract signed date */}
          {showFields == "22" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={contractSignedDate}
                onChange={onChangeContractDate}
                format={dateFormat}
                placeholder="Contract Signed Date"
              />
            </div>
          )}

          {/* Declined date */}
          {showFields == "24" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={declinedDate}
                  onChange={onChangeDeclinedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 10 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    placeholder="Decription"
                    vlaue={declinedDescription}
                    className="profile-input"
                    onChange={onChangeDeclinedDescription}
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
            </>
          )}

          {/* AIP date */}
          {showFields == "9" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAIPDate}
                onChange={onChangeAipDate}
                format={dateFormat}
                placeholder="AIP Date"
              />
            </div>
          )}

          {/* Further info date */}
          {showFields == "29" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    placeholder="description"
                    vlaue={description}
                    className="profile-input"
                    onChange={onChangeDescription}
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseReceivedDate}
                  placeholder="Receive Date"
                  onChange={onChangeReceivedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseDueDate}
                  placeholder="Due Date"
                  onChange={onChangeDueDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseExtendedDate}
                  placeholder="Extended Date"
                  onChange={onChangeExtendedDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {/* Immigration matter ppi date */}
          {showFields == "32" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <div className="profile-input-border">
                  {/* <Form.Item name="description"> */}
                  <Input
                    vlaue={description}
                    placeholder="Description"
                    onChange={onChangeDescription}
                    className="profile-input"
                  />
                  {/* </Form.Item> */}
                </div>
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseReceivedDate}
                  placeholder="PPI Receive Date"
                  onChange={onChangeReceivedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseDueDate}
                  placeholder="PPI Due Date"
                  onChange={onChangeDueDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseExtendedDate}
                  placeholder="PPI Extended Date"
                  onChange={onChangeExtendedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={casePPISubmitedDate}
                  placeholder="PPI Submited Date"
                  onChange={onChangePPiSubmitedDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {/* applied date */}
          {showFields == "38" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker
                value={caseAppliedDate}
                onChange={onChangeAppliedDate}
                format={dateFormat}
              />
            </div>
          )}

          {/* selected date */}
          {showFields == "52" && (
            <>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseEoiSelectedDate}
                  placeholder="EOI Selected Date"
                  onChange={onChangeEoiSelectedDate}
                  format={dateFormat}
                />
              </div>
              <div style={{ padding: 10, paddingTop: 0 }}>
                <DatePicker
                  value={caseEoiItaDate}
                  placeholder="EOI ITA Date"
                  onChange={onChangeEoiItaDate}
                  format={dateFormat}
                />
              </div>
            </>
          )}

          {showFields !== "0" && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <DatePicker onChange={onChangeDate} format={dateFormat} />
            </div>
          )}

          <div className="button-blue-cont">
            <Button
              onClick={() => updateStatus(visa)}
              className="button-blue"
              loading={loadUpdCase}
              style={{ color: "#FFFFFF", justifyContent: "flex-end" }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default UpdateCaseStatus;
