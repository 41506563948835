import React, { useState } from "react";
import { Form, Button } from "antd";
import { Spin, message } from "antd";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const ComfirmToDelete = ({ removeTask, updateTitle, handleCancel }) => {
  return (
    // <Spin size="large" spinning={loading}>
    <Form name="basic" initialValues={{ remember: true }}>
      <div className="delete-model">
        <p>Are you sure you want to delete this Task</p>
      </div>
      <div className="d-flex flex-end">
        <Form.Item>
          <Button
            onClick={() => removeTask(updateTitle)}
            type="primary"
            htmlType="submit"
            className="margin-right"
          >
            OK
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => {
              handleCancel();
            }}
            type="primary"
            htmlType="submit"
          >
            Cancel
          </Button>
        </Form.Item>
      </div>
    </Form>
    // </Spin>
  );
};

export default ComfirmToDelete;
