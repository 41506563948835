// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
} from "antd";

import { UpOutlined, DownOutlined } from "@ant-design/icons";

import QuestionCard from "./QuestionCard";
import TextArea from "./TextArea";

var positionedQuestions = [];

function Section(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);
  const [sectionName, setSectionName] = useState("");
  const [disableAddFunc, setDisableAddFunc] = useState(false);
  const [sectionOption, setSectionOption] = useState(null);
  const [addTextArray, setAddTextArray] = useState([]);
  const [froalaValuesArray, setFroalaValueArray] = useState([]);
  const [multiSections, setMultiSections] = useState([]);
  const [questionAdded, setQuestionAdded] = useState(false);
  const [hideSection, setHideSection] = useState(false);
  // const [mainQuestionIndex, setMainQuestionIndex] = useState(null)

  useEffect(() => {
    if (Props.addQuestion) {
      setDisableAddFunc(true);
    }
    // if (Props.sectionOptions) {
    //   var findsecInd = Props.sectionOptions.find(
    //     (obj) =>
    //       obj.sectionInd ===
    //       (Props.subOptionalSection
    //         ? Props.sectionIndex
    //         : Props.optionalSection
    //         ? Props.sectionIndex
    //         : Props.mainSectionIndex)
    //   );
    //   if (findsecInd) {
    //     setSectionOption(findsecInd);
    //     // if(multiSections.length === 0) {
    //     //   setMultiSections(multiSections.concat(Props.sectionOptions))
    //     //    
    //     // } else {
    //     //   var findSameIndex = multiSections.filter(obj => obj.mainQuestionIndex === findsecInd.mainQuestionIndex && obj.mainSectionIndex === findsecInd.mainSectionIndex)
    //     //
    //     // }
    //     Props.addSectionOptions(Props.sectionOptions);
    //   }
    // }
    // if(Props.mainQuestionIndex) {
    //   setMainQuestionIndex(Props.mainQuestionIndex)
    // }
    console.log(multiSections);
    //  
  }, [Props, multiSections]);

  const { Option } = Select;

  // const addSectionOptions = optionalSections => {

  //   Props.addSectionOptions(multiSections)
  //
  // }

  //  

  const addQuestion = (add) => {
    setAddTextArray(
      addTextArray.concat({
        addText: false,
      })
    );
    add();
  };

  const onDeleteSection = () => {
    if (!questionAdded) {
      // if (Props.optionsSectionArray.length > 0) {
      //   var findOptSectionIndex = Props.optionsSectionArray.findIndex(
      //     obj =>
      //       obj.mainSectionIndex === Props.mainSectionIndex &&
      //       obj.mainQuestionIndex === Props.mainQuestionIndex &&
      //       obj.sectionInd === Props.optSectionIndex
      //   );
      //   var findOptSection = Props.optionsSectionArray.filter(
      //     obj =>
      //       obj.mainSectionIndex === Props.mainSectionIndex &&
      //       obj.mainQuestionIndex === Props.mainQuestionIndex &&
      //       obj.sectionInd === Props.optSectionIndex
      //   );
      //   if (findOptSectionIndex > -1) {
      //     Props.removeOptionSection(findOptSectionIndex, findOptSection.length);
      //     // Props.optionsSectionArray.splice(findOptSectionIndex, findOptSection.length)
      //   }
      // }
      // // var findSectionOption = Props.sectionOptions;
      // if (Props.sectionOptions && Props.sectionOptions.length > 0) {
      //   Props.sectionOptions.splice(Props.sectionIndex, 1);
      // }

      Props.sectionRemove(Props.sectionIndex);
    } else {
      message.warning("Please delete dependent questions first!");
    }
  };

  return (
    <div
      className="cq-section-cont"
      style={{ backgroundColor: Props.optionalSection ? "#FFF7EC" : "#FFFFFF" }}
    >
      <div className="section-heading-row">
        <div style={{ display: "flex" }}>
          <div style={{ cursor: "pointer" }} onClick={onDeleteSection}>
            <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
          </div>

          <div
            onClick={() => {
              Props.sectionMove(Props.sectionIndex, Props.sectionIndex - 1);
            }}
            className="section-arrow-icons"
          >
            <UpOutlined />
          </div>

          <div
            onClick={() => {
              Props.sectionMove(Props.sectionIndex, Props.sectionIndex + 1);
            }}
            className="section-arrow-icons"
          >
            <DownOutlined />
          </div>

          <span className="heading-text" style={{ marginLeft: 20 }}>
            {(Props.sectionOptions ? Props.sectionOptions + " " : "") +
              "SECTION"}
          </span>
        </div>
        {hideSection ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setHideSection(false)}
          >
            <span className="heading-text" style={{ fontSize: 11 }}>
              SHOW
            </span>
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setHideSection(true)}
          >
            <span className="heading-text" style={{ fontSize: 11 }}>
              HIDE
            </span>
          </div>
        )}
      </div>
      {!hideSection && (
        <div>
          {!Props.optionalSection && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                marginLeft: "6%",
              }}
            >
              <p className="cq-label-text">Section Name</p>
              <div style={{ width: "60%" }}>
                <Form.Item
                  {...Props.section}
                  name={[Props.section.name, "name"]}
                  fieldKey={[Props.section.fieldKey, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input
                    className="profile-input profile-input-border"
                    onChange={(e) => setSectionName(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <div style={{ marginLeft: 55, marginTop: 20 }}>
            <Form.Item
              {...Props.section}
              name={[Props.section.name, "allowMultiple"]}
              fieldKey={[Props.section.fieldKey, "allowMultiple"]}
              valuePropName="checked"
            >
              <Checkbox>Is Multiple</Checkbox>
            </Form.Item>
          </div>
          <Form.List name={[Props.section.name, "questions"]}>
            {(fields, { add, remove, move }, { errors }) => {
              if (fields.length > 0) {
                setQuestionAdded(true);
              } else {
                setQuestionAdded(false);
              }
              const moveAddTextArray = (arr, old_index, new_index) => {
                while (old_index < 0) {
                  old_index += arr.length;
                }
                while (new_index < 0) {
                  new_index += arr.length;
                }
                if (new_index >= arr.length) {
                  let k = new_index - arr.length;
                  while (k-- + 1) {
                    arr.push(undefined);
                  }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

                setAddTextArray(arr);
              };
              // if (positionedQuestions.length > 0) {
              //   fields = positionedQuestions;
              // }
              return (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Form.Item required={false} key={field.key}>
                        {addTextArray.length > 0 &&
                        addTextArray[index].addText ? (
                          <TextArea
                            field={field}
                            form={Props.form}
                            froalaValuesArray={froalaValuesArray}
                            questionRemove={remove}
                            questionsArray={addTextArray}
                            questionIndex={index}
                            index={index}
                            move={move}
                            questionArrayMove={moveAddTextArray}
                          />
                        ) : (
                          <QuestionCard
                            field={field}
                            optQuestionIndex={
                              Props.subOptionalSection
                                ? Props.optQuestionIndex
                                : index
                            }
                            index={index}
                            allFields={fields}
                            move={move}
                            questionArrayMove={moveAddTextArray}
                            subOptQuestionIndex={
                              Props.subOptionalSection ? index : null
                            }
                            subOptionalSection={Props.subOptionalSection}
                            addOptionValues={Props.addOptionValues}
                            addCheckboxValues={Props.addCheckboxValues}
                            addDropdownValues={Props.addDropdownValues}
                            addSectionOptions={Props.addSectionOptions}
                            mappParentData={Props.mappParentData}
                            mappChildData={Props.mappChildData}
                            questionRemove={remove}
                            mainSectionIndex={
                              Props.optionalSection
                                ? Props.mainSectionIndex
                                : Props.sectionIndex
                            }
                            optSectionIndex={Props.optSectionIndex}
                            subOptSectionIndex={Props.subOptSectionIndex}
                            mainQuestionIndex={
                              Props.optionalSection
                                ? Props.mainQuestionIndex
                                : index
                            }
                            optionalSection={Props.optionalSection}
                            onGetMappingProperty={Props.getMappChild}
                            questionsArray={addTextArray}
                            optionsArray={Props.optionsArray}
                            optionsSectionArray={Props.optionsSectionArray}
                            removeOptionSection={Props.removeOptionSection}
                            formRef={Props.formRef}
                            section={Props.section}
                            mappPropertiesArray={Props.mappPropertiesArray}
                            onMappArrayChange={Props.onMappArrayChange}
                            onChangeMappProperty={Props.onChangeMappProperty}
                          />
                        )}
                      </Form.Item>
                    );
                  })}
                  {disableAddFunc && fields.length < 1 && addQuestion(add)}
                  {disableAddFunc && setDisableAddFunc(false)}
                  <Form.Item>
                    <div style={{ display: "flex" }}>
                      {!Props.optionalSection && (
                        <Button
                          type="dashed"
                          className="cq-primary-btn"
                          onClick={Props.sectionAdd}
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Section</span>
                        </Button>
                      )}
                      <Button
                        type="dashed"
                        className={
                          Props.optionalSection
                            ? "cq-orng-btn"
                            : "cq-primary-btn"
                        }
                        onClick={() => {
                          setAddTextArray(
                            addTextArray.concat({
                              addText: true,
                            })
                          );
                          add();
                        }}
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        <span className="cq-btn-text">Add Text</span>
                      </Button>
                      <Button
                        onClick={() => {
                          setAddTextArray(
                            addTextArray.concat({
                              addText: false,
                            })
                          );
                          add();
                        }}
                        type="dashed"
                        className={
                          Props.optionalSection
                            ? "cq-orng-btn"
                            : "cq-primary-btn"
                        }
                        style={{ marginTop: 20 }}
                      >
                        <span className="cq-btn-text">Add Quesstion(s)</span>
                      </Button>
                      <Form.ErrorList errors={errors} />
                      <div className="cq-section-name-cont">
                        <span className="heading-text" style={{ fontSize: 8 }}>
                          {sectionName ? "(" + sectionName + ")" : ""}
                        </span>
                      </div>
                    </div>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
      )}
    </div>
  );
}

export default Section;
