import { api } from "../../services/api";
import * as types from "../Constants";
import {
  UPDATE_INVOICE_TO_XERO_FAILURE,
  UPDATE_INVOICE_TO_XERO_SUCCESS,
  UPDATE_SCHOOL_INVOICE_TO_XERO_FAILURE,
  UPDATE_SCHOOL_INVOICE_TO_XERO_SUCCESS,
} from "../Constants";

export const getXeroToken = () => {
  const options = {
    url: `v1/xero/XeroTokenStatus`,
  };
  options.types = [types.GET_XERO_TOKEN_SUCCESS, types.GET_XERO_TOKEN_FAILURE];

  return api.get(options);
};

export const createCode = (data) => {
  const options = {
    url: `v1/xero/CreateCode`,
  };
  options.types = [
    types.CREATE_XERO_CODE_SUCCESS,
    types.CREATE_XERO_CODE_FAILURE,
  ];
  return api.post(options, data);
};

export const createToken = (data) => {
  let _data = {
    code: data,
  };

  const options = {
    url: `v1/xero/CreateToken`,
  };
  options.types = [
    types.CREATE_XERO_TOKEN_SUCCESS,
    types.CREATE_XERO_TOKEN_FAILURE,
  ];
  return api.post(options, _data);
};

export const postInvoicePaymentToXero = (data) => {
  let _data = {
    invoiceId: data.invoiceId,
    invoicePaymentId: data.invoicePaymentId,
    code: data.code,
  };
  const options = {
    url: `v1/xero/PostInvoicePaymentToXero`,
  };
  options.types = [
    types.POST_INVOICE_PAYMENT_TO_XERO_SUCCESS,
    types.POST_INVOICE_PAYMENT_TO_XERO_FAILURE,
  ];
  return api.post(options, _data);
};

export const postInvoiceToXero = (data) => {
  let _data = {
    id: data.invoiceId,
    accountCode: data.accountCode,
    date: data.dateString,
    currency: data.currencyId && data.currencyId.toString(),
    invoiceNo: data.invoiceNo,
    currencyRate: 1,
  };

  const options = {
    url: `v1/xero/PostInvoiceToXero`,
  };
  options.types = [
    types.POST_INVOICE_TO_XERO_SUCCESS,
    types.POST_INVOICE_TO_XERO_FAILURE,
  ];
  return api.post(options, _data);
};

export const updateInvoiceToXero = (data) => {
  let _data = {
    id: data.invoiceId,
    accountCode: data.accountCode,
    date: data.dateString,
    currency: data.currencyId && data.currencyId.toString(),
    invoiceNo: data.invoiceNo,
    currencyRate: 1,
  };

  const options = {
    url: `v1/xero/PostInvoiceToXero`,
  };
  options.types = [
    types.UPDATE_INVOICE_TO_XERO_SUCCESS,
    types.UPDATE_INVOICE_TO_XERO_FAILURE,
  ];

  return api.put(options, _data);
};

export const postSchoolInvoiceToXero = (data) => {
  let _data = {
    id: data.invoiceId,
    accountCode: data.accountCode,
    date: data.dateString,
    currency: data.currencyId && data.currencyId.toString(),
    currencyRate: 1,
    invoiceNo: data.invoiceNo,
  };

  const options = {
    url: `v1/xero/PostSchoolInvoiceToXero`,
  };
  options.types = [
    types.POST_SCHOOL_INVOICE_TO_XERO_SUCCESS,
    types.POST_SCHOOL_INVOICE_TO_XERO_FAILURE,
  ];
  return api.post(options, _data);
};

export const updateSchoolInvoiceToXero = (data) => {
  let _data = {
    id: data.invoiceId,
    accountCode: data.accountCode,
    date: data.dateString,
    currency: data.currencyId && data.currencyId.toString(),
    currencyRate: 1,
    invoiceNo: data.invoiceNo,
  };

  const options = {
    url: `v1/xero/PostSchoolInvoiceToXero`,
  };
  options.types = [
    types.UPDATE_SCHOOL_INVOICE_TO_XERO_SUCCESS,
    types.UPDATE_SCHOOL_INVOICE_TO_XERO_FAILURE,
  ];
  return api.put(options, _data);
};
