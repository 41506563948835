import { api } from "../../services/api";
import * as types from "../Constants";

export const getEmailImport = () => {
  const options = {
    url: "v1/branch/BranchCCAndBCCImportSetting",
  };
  options.types = [
    types.GET_EMAIL_IMPORT_CC_SUCCESS,
    types.GET_EMAIL_IMPORT_CC_FAILURE,
  ];

  return api.get(options);
};

export const updEmailImport = (data) => {
  const options = {
    url: "v1/branch/BranchCCAndBCCImportSetting",
  };
  options.types = [
    types.ADD_EMAIL_IMPORT_CC_SUCCESS,
    types.ADD_EMAIL_IMPORT_CC_FAILURE,
  ];

  return api.put(options, data);
};
