// @flow
import React, { useState, useEffect, useRef } from "react";
import { Images } from "../../Themes";
import { message, Spin, Button, Upload, Select, Form } from "antd";

import { CloseOutlined } from "@ant-design/icons";

function ProcessingPerson(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [selectedOption, setSelectedOption] = useState("");
  const [processingGroup, setProcessingGroup] = useState("");
  const [groupsData, setGroupsData] = useState("");
  const [processingPerson, setProcessingPerson] = useState("");
  const [personsData, setPersonsData] = useState("");
  const [groupMembers, setGroupMembers] = useState("");
  const [allMembers, setAllMembers] = useState([]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");
  const prevMembers = useRef();
  const prevGroups = useRef();
  useEffect(() => {
    if (Props.clientInfo) {
      setGroupsData(Props.clientInfo.groups);
      setPersonsData(Props.clientInfo.persons);

      if (!prevGroups.current) {
        prevGroups.current = Props.clientInfo.groups;
      }
      if (prevGroups.current !== Props.clientInfo.groups) {
        if (prevGroups.current.length > Props.clientInfo.groups.length) {
          for (var i = 0; i < prevGroups.current.length; i++) {
            var removedGroup = Props.clientInfo.groups.find(
              (obj) => obj.id === prevGroups.current[i].id
            );
            if (removedGroup) {
            } else {
              var removePerson = allMembers.filter(
                (obj) => obj.groupId === prevGroups.current[i].groupId
              );
              if (removePerson) {
                for (var j = 0; j < allMembers.length; j++) {
                  for (var k = 0; k < removePerson.length; k++) {
                    if (allMembers[j].groupId === removePerson[k].groupId) {
                      allMembers.splice(j, 1);
                    }
                  }
                }
              }
            }
          }
        }
        prevGroups.current = Props.clientInfo.groups;
        setProcessingGroup("");
      }
    }
    if (Props.groupMembers && Props.groupMembers.items.length > 0) {
      console.log(prevMembers, Props.groupMembers);

      if (prevMembers.current != Props.groupMembers.items) {
        let _allMembers = [...allMembers];
        if (prevMembers && prevMembers.current && allMembers.length > 0) {
          let findMember = [];
          for (var i = 0; i < Props.groupMembers.items.length; i++) {
            findMember = allMembers.find(
              (obj) => obj.id == Props.groupMembers.items[i].id
            );
            if (!findMember) _allMembers.push(findMember);
            // setAllMembers(allMembers.concat(Props.groupMembers.items));
          }
          setAllMembers(_allMembers);
          prevMembers.current = Props.groupMembers.items;
        } else {
          setAllMembers(allMembers.concat(Props.groupMembers.items));
          prevMembers.current = Props.groupMembers.items;
        }
      }
    } else if (prevMembers.current) {
      prevMembers.current = null;
    }
  }, [Props, allMembers]);

  const { Option } = Select;
  const groups = [];
  const persons = [];
  if (Props.groupsData && Props.groupsData.items.length > 0) {
    for (let i = 0; i < Props.groupsData.items.length; i++) {
      groups.push(
        <Option key={Props.groupsData.items[i].id}>
          {Props.groupsData.items[i].name}
        </Option>
      );
    }
  }

  // if (Props.personsData && groupMembers && groupMembers.length > 0) {
  //   for (let i = 0; i < groupMembers.length; i++) {
  //       var findMember = Props.personsData.users.find(obj => obj.id === groupMembers[i].userId)
  //       if(findMember)
  //         groupMembers[i].fullName = findMember.fullName
  //   }
  // }

  if (Props.users && Props.users.length > 0) {
    for (let i = 0; i < Props.users.length; i++) {
      persons.push(
        <Option key={Props.users[i].id}>{Props.users[i].fullName}</Option>
      );
    }
  }

  const handleChange = (selectedOption) => {
    setSelectedOption(parseInt(selectedOption));
  };

  const handleChangeProcessingGroup = (selectedOption) => {
    const create = window.confirm(
      "Are you sure you want to add this processing team?"
    );
    let selectedGroup =
      Props.groupsData &&
      Props.groupsData.items.find((obj) => obj.id == selectedOption);

    if (create) {
      setProcessingGroup(selectedOption);
      let data = {
        clientId: Props.clientInfo.id,
        groupId: parseInt(selectedOption),
      };
      Props.onAddProcessingGroup(data, selectedGroup.name);
      Props.onGetGroupMembers(parseInt(selectedOption)).then((res) => {
        let findUsers = [];
        if (personsData.length > 0) {
          for (var i = 0; i < personsData.length; i++)
            findUsers = res.payload.items.filter(
              (obj) => obj.userId !== personsData[i].userId
            );
          if (findUsers.length > 0) {
            let data = null;
            for (var i = 0; i < findUsers.length; i++) {
              data = {
                clientId: sessionStorage.getItem("clientprofileid"),
                userId: findUsers[i].userId,
              };
              Props.onAddProcessingPerson(data, findUsers[i].fullName);
            }
          }
        } else {
          let data = null;
          for (var i = 0; i < res.payload.items.length; i++) {
            data = {
              clientId: sessionStorage.getItem("clientprofileid"),
              userId: res.payload.items[i].userId,
            };
            Props.onAddProcessingPerson(data, res.payload.items[i].fullName);
          }
        }
      });
      setTimeout(() => {
        setProcessingGroup("");
      }, 3000);
    }
  };

  const handleChangeProcessingPerson = (selectedOption) => {
    const create = window.confirm(
      "Are you sure you want to add this processing person?"
    );
    let selectedPerson =
      Props.users && Props.users.find((obj) => obj.id == selectedOption);
    if (create) {
      setProcessingPerson(selectedOption);
      let data = {
        clientId: Props.clientInfo.id,
        userId: selectedOption,
      };
      Props.onAddProcessingPerson(data, selectedPerson.fullName);
      setTimeout(() => {
        setProcessingPerson("");
      }, 3000);
    }
  };

  const removeProcessingGroup = (group) => {
    let data = {
      id: group.id,
      delete: true,
    };

    Props.onRemoveProcessingGroup(data, group.groupName);
  };

  const removeProcessingPerson = (person) => {
    let data = {
      id: person.id,
      delete: true,
    };

    Props.onRemoveProcessingPerson(data, person.fullName);
  };

  return (
    <div>
      <div className="profile-additional-box">
        <div className="form-cont">
          <div className="profile-down-arrow-cont" style={{ marginTop: 10 }}>
            <img src={Images.whiteArrow} className="profile-down-arrow-icon" />
          </div>
          <div className="input-cont-width">
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <p className="label">Processing Teams</p>
                <Form.Item>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={processingGroup}
                    onChange={handleChangeProcessingGroup}
                  >
                    {groups}
                  </Select>
                </Form.Item>
                <div className="form-btn-cont">
                  {groupsData &&
                    groupsData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index % 2 === 0 ? "button-first" : "button-second"
                          }
                        >
                          <div className="button-first-second-row">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => removeProcessingGroup(item)}
                            >
                              <img
                                src={Images.crossWhite}
                                className="btn-cross-icon"
                              />
                            </div>
                            <span className="btn-fst-scnd-text">
                              {item.groupName}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  {/*<div className="button-second">
                    <img
                      src={Images.crossWhite}
                      className="btn-cross-icon"
                    />
                    <span className="btn-fst-scnd-text">Inderjeet</span>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="input-cont-width" style={{ marginLeft: 25 }}>
            <p className="label">Processing Person</p>
            <Form.Item>
              <Select
                showSearch
                optionFilterProp="children"
                value={processingPerson}
                onChange={handleChangeProcessingPerson}
              >
                {persons}
              </Select>
            </Form.Item>
            <div>
              {/* {allMembers &&
                allMembers.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index % 2 === 0 ? "button-first" : "button-second"
                      }
                      style={{
                        cursor: "not-allowed",
                        marginLeft: index > 0 ? 5 : 0,
                      }}
                    >
                      <div className="button-first-second-row">
                        <img
                          src={Images.crossWhite}
                          className="btn-cross-icon"
                        />
                        <span className="btn-fst-scnd-text">
                          {item.fullName}
                        </span>
                      </div>
                    </div>
                  );
                })} */}
              {personsData &&
                personsData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="profile-tags"
                      style={{
                        marginLeft: allMembers.length || index > 0 ? 5 : 0,
                      }}
                    >
                      <div className="button-first-second-row">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => removeProcessingPerson(item)}
                        >
                          <CloseOutlined style={{ fontSize: "10px" }} />
                        </div>
                        <div className="tags-text">
                          <span>{item.fullName}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/*<div className="button-second">
                <img src={Images.crossWhite} className="btn-cross-icon" />
                <span className="btn-fst-scnd-text">Inderjeet</span>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessingPerson;
