import React from "react";
import "./TimeTrackingStyles.css";
// import Modal from "react-awesome-modal";
import {
  Input,
  Table,
  Button,
  InputNumber,
  Modal,
  Form,
  Row,
  Col,
  message,
} from "antd";
import { apiRefresh } from "../services/api";
import { Images } from "../Themes";
// import Form from "antd/lib/form/Form";

const headOption = [
  { tabName: "WORK TYPE", linkName: "/work-type" },
  { tabName: "VISA TYPE PRICE", linkName: "/visa-type-price" },
  { tabName: "SERVICE TYPE", linkName: "/service-type" },
];

class VisaTypePrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updateData: null,
      visaTypePriceVisible: false,
      columns: [
        {
          width: "100px",
          title: "Name",
          dataIndex: "visaName",
          key: "visaName",
        },
        {
          width: "100px",
          title: "Base Price",
          dataIndex: "basePrice",
          key: "basePrice",
        },
        {
          width: "100px",
          title: "Time In Hours",
          dataIndex: "timeInHours",
          key: "timeInHours",
        },
        {
          width: "20px",
          title: "Action",
          dataIndex: "",
          key: "",
          render: (text, row, index) => {
            return (
              <div>
                <img
                  src={Images.editBorderBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        updateData: row,
                      },
                      () => {
                        this.setState({ visaTypePriceVisible: true });
                      }
                    );
                  }}
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getTypePrice();
  }

  getTypePrice = () => {
    this.setState({ loading: true });
    this.props
      .onGetVisaTypePrice()
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  openWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: true,
    });
  };

  closeVisaTypePriceModal = () => {
    this.setState(
      {
        updateData: null,
      },
      () => {
        this.setState({ visaTypePriceVisible: false });
      }
    );
  };

  onFinish = (values) => {
    let data = {
      id: this.state.updateData.id,
      visaName: values.visaName,
      timeInHours: values.timeInHours || 0,
      basePrice: values.basePrice || 0,
    };
    const addVisaPriceOpt = {
      url: `v1/visatype/price`,
    };
    addVisaPriceOpt.types = [
      "ADD_VISA_TYPE_PRICE_SUCCESS",
      "ADD_VISA_TYPE_PRICE_FAILURE",
    ];
    this.setState({ loading: true });
    let payload = { priceList: [data] };
    apiRefresh
      .post(addVisaPriceOpt, payload)
      .then((res) => {
        this.setState({ loading: false });
        this.getTypePrice();
        this.closeVisaTypePriceModal();
        message.success("Visa Price updated successfully!");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { columns } = this.state;
    const { visaTypePriceRes } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="report-container">
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginRight: 20,
                  }}
                >
                  <div className="pciq-top-div" style={{ marginTop: 0 }}>
                    <span
                      className="pc-top-div-text"
                      style={{ color: "#0A3C5D" }}
                    >
                      VISA TYPE PRICE
                    </span>
                  </div>
                </div>

                <div className="ca-gray-cont" style={{ paddingLeft: 20 }}>
                  <div
                    className="ca-invoice-table-cont"
                    style={{
                      width: `calc(100% - 20px)`,
                      marginTop: 20,
                    }}
                  >
                    <Table
                      columns={columns}
                      dataSource={visaTypePriceRes && visaTypePriceRes.items}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ======== Add work visa design ======== */}

            <Modal
              visible={this.state.visaTypePriceVisible}
              effect="fadeInUp"
              onClickAway={() => this.closeVisaTypePriceModal()}
              footer={null}
              onCancel={this.closeVisaTypePriceModal}
            >
              <div style={{ padding: 20 }}>
                {this.state.updateData && (
                  <Form
                    initialValues={this.state.updateData}
                    onFinish={this.onFinish}
                  >
                    <Row gutter={8} className="margin-top">
                      <Col span={8}>
                        <p style={{ fontSize: 13 }}>Visa Name</p>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="visaName">
                          <Input placeholder="" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8} className="margin-top">
                      <Col span={8}>
                        <p style={{ fontSize: 13 }}>Base Price</p>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="basePrice">
                          <InputNumber type="number" placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8} className="margin-top">
                      <Col span={8}>
                        <p style={{ fontSize: 13 }}>Time In Hours</p>
                      </Col>
                      <Col span={16}>
                        <Form.Item name="timeInHours">
                          <InputNumber type="number" placeholder="" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20,
                      }}
                    >
                      <Button
                        htmlType="submit"
                        className="button-blue"
                        loading={this.state.loading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default VisaTypePrice;
