import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentView from "../AccountSetting/UserLevelSettings/DocumentView/DocumentView";

import {
  getDocumentView,
  addDocumentView,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    docViewRes: state.docViewReducer.docViewRes,
    addDocViewRes: state.docViewReducer.addDocViewRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocumentView: bindActionCreators(getDocumentView, dispatch),
    onAddDocumentView: bindActionCreators(addDocumentView, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentView);
