import { api } from "../../services/api";
import * as types from "../Constants";

export const getTimeZone = () => {
  const options = {
    url: `v1/users/UserTimeZone`,
  };
  options.types = [types.GET_TIME_ZONE_SUCCESS, types.GET_TIME_ZONE_FAILURE];

  return api.get(options);
};

export const updateTimeZone = (data) => {
  const options = {
    url: "v1/users/UserTimeZone",
  };
  options.types = [types.PUT_TIME_ZONE_SUCCESS, types.PUT_TIME_ZONE_FAILURE];

  return api.put(options, data);
};
