import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DailyEmailSettings from "../AccountSetting/UserLevelSettings/DailyEmailSettings/DailyEmailSettings";

import {
  getEmailSetting,
  setActiveInnerTab,
  updateEmailSetting,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    emailSetRes: state.emailSetReducer.emailSetRes,
    emailEmailSetRes: state.emailSetReducer.emailEmailSetRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmailSetting: bindActionCreators(getEmailSetting, dispatch),
    onUpdateEmailSetting: bindActionCreators(updateEmailSetting, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyEmailSettings);
