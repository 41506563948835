import React, { useState, Fragment, useEffect } from "react";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import { message, Spin, Form, Input, Button, Tooltip } from "antd";
import { LeftCircleFilled, RightCircleTwoTone } from "@ant-design/icons";
import { apiRefresh } from "../../services/api";

const MailChamp = ({
  onGetMailChimp,
  mailChimpRes,
  onAddMailChimp,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [updateRes, setUpdateRes] = useState({});
  const [sendGridIntegrated, setsendGridIntegrated] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    const checkMailchimpOpt = {
      url: `v1/mailchimp/GetIsMailChimpOrSendGrid`,
    };
    checkMailchimpOpt.types = [
      "CHECK_MAILCHIMP_SUCCESS",
      "CHECK_MAILCHIMP_FAILURE",
    ];
    setLoading(true);
    apiRefresh.get(checkMailchimpOpt).then((res) => {
      if (res == "SendGrid") {
        setsendGridIntegrated(true);
        message.error(
          "SendGrid Key added, You can't add MailChimp Key right now!",
          5
        );
      } else {
        setsendGridIntegrated(false);
        onGetMailChimp()
          .then((res) => {
            setUpdateRes(res && res.payload);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
      setLoading(false);
    });
  }, [onGetMailChimp]);

  useEffect(() => {
    form.setFieldsValue({
      host_name: updateRes && updateRes.mailChimpId,
    });
  }, [form, updateRes]);

  const removeMailChimp = () => {
    const deleteMailchimpOpt = {
      url: `v1/mailchimp`,
    };
    deleteMailchimpOpt.types = [
      "DELETE_MAILCHIMP_SUCCESS",
      "DELETE_MAILCHIMP_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(deleteMailchimpOpt)
      .then((res) => {
        setUpdateRes("");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    let companyId = localStorage.getItem("companyId");
    const data = {
      // companyId: companyId,
      // createdBy: userId,
      apiKey: values.host_name,
    };
    onAddMailChimp(data)
      .then(() => {
        onGetMailChimp(companyId);
        setLoading(false);
        message.success("Successfully Saved!");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.mailChimp} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Mail Chimp Integration</span>
                <Tooltip
                  placement="topLeft"
                  title={`Here you can integrate your Mailchimp with Ezymigrate`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="mail-chimp-box">
              <div className="">
                <div className="client-tag-table">
                  <Spin size="large" spinning={loading}>
                    <Form onFinish={onFinish} form={form}>
                      <div className="reminder-set-form margin-top-34 d-block">
                        {!sendGridIntegrated &&
                          updateRes &&
                          updateRes.mailChimpId && (
                            <div>
                              <a
                                className="remove-account"
                                onClick={() => removeMailChimp()}
                              >
                                X
                              </a>
                            </div>
                          )}
                        <Form.Item
                          name="host_name"
                          label="Host Name"
                          className=""
                        >
                          <Input style={{ width: 490 }} />
                        </Form.Item>
                      </div>
                      <div className="mail-Chimp-text">
                        <p>Log Into Your Mail Chimp Account</p>
                        <div className="mail-text d-flex align-center">
                          <RightCircleTwoTone />
                          <p>
                            Click your profile name to expand the Account Panel,
                            and choose Account.
                          </p>
                        </div>
                        <div className="mail-text d-flex align-center">
                          <RightCircleTwoTone />
                          <p>
                            Click the Extras drop-down menu and choose API keys.
                          </p>
                        </div>
                        <div className="mail-text d-flex align-center">
                          <RightCircleTwoTone />
                          <p>
                            Copy an existing API key or click the Create A Key
                            button.
                          </p>
                        </div>
                        <div className="mail-text d-flex align-center">
                          <RightCircleTwoTone />
                          <p>
                            Name your key descriptively, so you know what
                            application uses that key.
                          </p>
                        </div>
                      </div>
                      <div className="mail-chimp-btn reminder-set-form margin-top-34">
                        <Form.Item className="d-block">
                          <Button
                            className="button-blue"
                            htmlType="submit"
                            type="primary"
                            disabled={sendGridIntegrated}
                          >
                            SAVE
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MailChamp;
