import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const LetterTemplatesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_LETTER_TEMPLATE_SUCCESS:
      return { ...state, LetterTemplatesRes: action.payload };
    case types.GET_LETTER_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_LETTER_TEMPLATE_SUCCESS:
      return { ...state, addLetterTemplatesRes: action.payload };
    case types.ADD_LETTER_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_LETTER_TEMPLATE_SUCCESS:
      return { ...state, removeLetterTemplatesRes: action.payload };
    case types.DELETE_LETTER_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_LETTER_TEMPLATE_SUCCESS:
      return { ...state, updateLetterTemplatesRes: action.payload };
    case types.PUT_LETTER_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.SORT_LETTER_TEMPLATE_SUCCESS:
      return { ...state, updateSortLetterTemplatesRes: action.payload };
    case types.SORT_LETTER_TEMPLATE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_CONTRACT_EMAIL_SUCCESS:
      return { ...state, contractEmailSuccess: action.payload };
    case types.ADD_CONTRACT_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CONTRACT_EMAIL_SUCCESS:
      return { ...state, contractEmailRes: action.payload };
    case types.GET_CONTRACT_EMAIL_FAILURE:
      return { ...state, contractEmailRes: [] };

    default:
      return state;
  }
};
