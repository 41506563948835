import { api } from "../../services/api";
import * as types from "../Constants";

export const getStudentListing = (data) => {
  let _data = {
    schoolType: data.schoolType,
    schoolId: data.schoolId,
    status: data.status,
    clientTags: data.tags,
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    dateFrom: data.dateFrom || "1900-01-01T00:00:00+00",
    dateTo: data.dateTo || "1900-01-01T00:00:00+00",
  };
  const options = {
    url: "v1/school/studentList",
  };

  options.types = [
    types.GET_STUDENT_LISTING_SUCCESS,
    types.GET_STUDENT_LISTING_FAILURE,
  ];
  return api.post(options, _data);
};
