import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AgentPortalDocuments from "../AccountSetting/AgentPortalDocuments/AgentPortalDocuments";

import {
  getDocuments,
  addDocument,
  removeDocument,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    documentRes: state.accountDocumentReducer.documentRes,
    addDocumentRes: state.accountDocumentReducer.addDocumentRes,
    removeDocumentRes: state.accountDocumentReducer.removeDocumentRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocuments: bindActionCreators(getDocuments, dispatch),
    onAddDocument: bindActionCreators(addDocument, dispatch),
    onRemoveDocument: bindActionCreators(removeDocument, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentPortalDocuments);
