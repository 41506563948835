import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BranchDetail from "../Admin/BranchDetail";

import {
  onGetBranchDetail,
  updateBranchDetail,
  onImpersonate,
  onUserLockUnlock
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    branchDetailRes: state.AdminReducer.branchDetailRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetBranchDetail: bindActionCreators(onGetBranchDetail, dispatch),
    updateBranchDetail: bindActionCreators(updateBranchDetail, dispatch),
    onImpersonate: bindActionCreators(onImpersonate, dispatch),
    onUserLockUnlock: bindActionCreators(onUserLockUnlock, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetail);
