import React, { Fragment, useState, useEffect } from "react";
import { Table, Spin, Tooltip } from "antd";
import { yyyymmddFormate } from "../../../../Common/reUseFunction";
import { Link } from "react-router-dom";
import ClientVisaReport from "./ClientVisaReport";
import {
  UserOutlined,
  LinkOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

const JobLinkedClientTable = ({
  onGetSingleClientHis,
  singleJobHisRes,
  isjobId,
  onGetVisaApplication,
  visaAppData,
}) => {
  const [loading, setLoading] = useState(false);
  const [singleCall, setSingleCall] = useState(false);
  const [expandedRowsEntriesKeys, setExpandedRowsEntriesKeys] = useState("");
  const [linkClientList, setLinkClientList] = useState([]);

  useEffect(() => {
    letterTempaltesFilter(singleJobHisRes);
  }, [singleJobHisRes]);

  const letterTempaltesFilter = (singleJobHisRes) => {
    let filterList = [];
    if (
      singleJobHisRes &&
      singleJobHisRes.items &&
      singleJobHisRes.items.length > 0
    ) {
      singleJobHisRes.items.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
    }
    setLinkClientList(filterList);
  };
  // useEffect(() => {
  //   if (!singleCall) {
  //     setLoading(true);
  //
  //     const data = {
  //       branchId: selectedBranchId && selectedBranchId,
  //       jobId: isjobId && isjobId
  //     };
  //
  //     setSingleCall(true);
  //     onGetSingleClientHis(data)
  //       .then(() => {
  //         setLoading(false);
  //       })
  //       .catch(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [isjobId, onGetSingleClientHis, singleCall, singleJobHisRes]);

  const columns = [
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      render: (text, record) => {
        return (
          <div className="table-action">
            <div>
              <span className="acc-actions-button">
                {record &&
                record.clientPermission &&
                record.clientPermission.active === true ? (
                  <span className="sent-email">Active</span>
                ) : (
                  <span className="draft">Inactive</span>
                )}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname:
                record.memberType.toLowerCase() === "client"
                  ? "/profile"
                  : record.memberType.toLowerCase() === "spouse"
                  ? "/partner-profile"
                  : "/client-family-member",
              state: { id: record.clientId, familyId: record.familyId },
            }}
            className="hover-link"
            style={{
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {`${record.firstName} ${record.lastName}`}
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
            textDecoration: "none !important",
            cursor: "pointer !important",
          }}
        >
          {record.email}
        </p>
      ),
    },
    {
      title: "DOB",
      key: "DOB",
      render: (text, record) => {
        return (
          <div className="table-action">
            <span
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "initial",
                fontFamily: "Poppins, sans-serif",
                textDecoration: "none !important",
                cursor: "pointer !important",
              }}
            >
              {record && yyyymmddFormate(record.dateOfBirth)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "visaCountryName",
      key: "visaCountryName",
      render: (text, record) => {
        const arr = record.visaCountryName.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const strVisaCountryName = arr.join(" ");
        return (
          <Link
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
              textDecoration: "none !important",
              cursor: "pointer !important",
            }}
          >
            {strVisaCountryName}
          </Link>
        );
      },
    },
  ];

  const singleCient = (record, onExpand, expanded) => {
    onExpand(record);
    let keys = [];
    keys.push(record.key);
    setExpandedRowsEntriesKeys(keys);
    if (expanded) {
      setExpandedRowsEntriesKeys([]);
    }

    onGetVisaApplication(record.id)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin spinning={loading} size="default">
        <div className="single-job">
          <Table
            className="ant-table-client-yellow-view job-table table-head employer-tables border-3 school-table"
            bordered
            pagination={false}
            dataSource={linkClientList}
            columns={columns}
            expandIconColumnIndex={11}
            expandable={{
              expandedRowRender: (record) => (
                console.log("recordrecord00000000099999999999999==>", record),
                (
                  <ClientVisaReport
                    onGetSingleClientHis={onGetVisaApplication}
                    singleJobHisRes={visaAppData}
                    isjobId={isjobId}
                  />
                )
              ),

              expandIcon: ({ expanded, onExpand, record }) => (
                <Fragment>
                  <div className="table-action">
                    <div style={{ margin: "2px" }}>
                      <Tooltip placement="topLeft" title={`Visa`}>
                        <UserOutlined
                          style={{ fontSize: "15px" }}
                          onClick={(e) =>
                            singleCient(record, onExpand, expanded)
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </Fragment>
              ),
            }}
          />
        </div>
      </Spin>
    </Fragment>
  );
};
export default JobLinkedClientTable;
