import React, { useEffect, useState, Fragment } from "react";
import {
  Select,
  Spin,
  Modal,
  Form,
  message,
  Row,
  Col,
  Input,
  Button,
  Tooltip,
  Checkbox,
} from "antd";
import moment from "moment";
import renderHTML from "react-render-html";

import debounce from "lodash/debounce";
import { Images } from "./../../Themes";
import activityData from "../../Components/ClientActivity/ActivityData";
import { apiRefresh } from "../../services/api";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const { TextArea } = Input;

const ClientFileNotes = ({
  index,
  FileNote,
  onRemoveFileNote,
  gap,
  onAddTask,
  onUpdateFileNote,
  linkVisa,
  onChangeCheckbox,
}) => {
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // getSms();
  }, []);

  return (
    <Fragment>
      {/* <Spin
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        size="small"
        spinning={getLoading}
      >
        
      </Spin> */}
      <div style={{ display: "flex" }}>
        <Checkbox
          value={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            onChangeCheckbox(e.target.checked, FileNote);
          }}
        />
        <div
          key={index}
          className="file-content-index-cont"
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            marginBottom: 20,
            marginLeft: 5,
            width: "100%",
          }}
        >
          <div className="file-content-index-row">
            <div style={{ width: `calc(100% - 140px)` }}>
              <div>
                <span className="date-time">
                  {moment(FileNote.CreatedDate).format("DD MMM YYYY hh:mm A") +
                    " "}
                </span>
                <span className="title">{FileNote.FullName}</span>
              </div>
              <div>{renderHTML(gap)}</div>
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: "flex",*/}
              {/*    alignItems: "center",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div*/}
              {/*    dangerouslySetInnerHTML={{*/}
              {/*      __html: FileNote.FileNote,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div>
              <div className="delete-cont">
                <Tooltip placement="topLeft" title={`Update`}>
                  <div
                    className="delete-icon"
                    style={{
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      onUpdateFileNote(index, FileNote);
                    }}
                  >
                    <img
                      src={Images.notesBlue}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="topLeft" title={`Link Visa`}>
                  <div
                    className="delete-icon"
                    style={{
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      linkVisa(index, FileNote);
                    }}
                  >
                    <img
                      src={Images.linkvisa}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="topLeft" title={`Add Tasks`}>
                  <div
                    className="delete-icon"
                    style={{
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      onAddTask(index, FileNote);
                    }}
                  >
                    <img
                      src={Images.blueAdd}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="topLeft" title={`Delete`}>
                  <div
                    className="delete-icon"
                    style={{
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                    onClick={() => onRemoveFileNote(FileNote, index)}
                  >
                    <img
                      src={Images.deleteIcon}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientFileNotes;
