import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, Input, Button, Form, Spin } from "antd";
import "./PotentialClientStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { apiRefresh } from "../services/api";
import {
  addNewClient,
  addPotentialClient,
  getCountries,
  getDynamicLink,
  getFilledQuestionnaire,
  getQuestionnaire,
  getVisaType,
  submitQuestionnaire,
  updMergeToClient,
  updMergeToPotentialClient,
} from "../store/Actions";
import { LinkQuestionnaire } from "../Containers";
// import Form from "antd/lib/form/Form";

const headOption = [
  { tabName: "Web Assessment", linkName: "/web-assessment" },
  { tabName: "Web Inquiry", linkName: "/web-inquiry-detailed" },
  {
    tabName: "Web Inquiry Link (Detailed)",
    linkName: "/web-inquiry-link-detailed",
  },
  { tabName: "Web Inquiry Link (Basic)", linkName: "/web-inquiry-link-basic" },
  { tabName: "Web Assessment Link", linkName: "/web-assessment-link" },
  { tabName: "Basic Assessment Link", linkName: "/basic-assessment-link" },
];

const { Option } = Select;

class WebAssessment extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      countriesState: [],
      filledQuestionnaires: [],
      loading: true,
      questionnaireId: 1838,
      showTable: true,
      showDetail: false,
      activeAccTab: "basic",
      filledQuestionnaireId: null,
    };
    let data = {
      questionnaireId: 1838,
      cor: "",
      coi: "",
      lastName: "",
      email: "",
    };
    this.props.getCountries();
    const staicQuestionnaireOpt = {
      url: `v1/filledquestionnaire/GetStatic`,
    };
    staicQuestionnaireOpt.types = [
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_SUCCES",
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .post(staicQuestionnaireOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.filledQuestionnaires.length > 0) {
          this.setState({ filledQuestionnaires: res.filledQuestionnaires });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  onGetQuestionnaires = () => {
    let data = {
      questionnaireId: this.state.questionnaireId,
      cor: "",
      coi: "",
      lastName: "",
      email: "",
    };
    this.props.getCountries();
    const staicQuestionnaireOpt = {
      url: `v1/filledquestionnaire/GetStatic`,
    };
    staicQuestionnaireOpt.types = [
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_SUCCES",
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .post(staicQuestionnaireOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.filledQuestionnaires.length > 0) {
          this.setState({ filledQuestionnaires: res.filledQuestionnaires });
        } else {
          this.setState({ filledQuestionnaires: [] });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  componentDidUpdate(prevState) {
    if (prevState.countriesData != this.props.countriesData) {
      var countries = [];
      if (this.props.countriesData.length > 0) {
        for (var i = 0; i < this.props.countriesData.length; i++) {
          countries.push(
            <Option value={this.props.countriesData[i].name}>
              {this.props.countriesData[i].name}
            </Option>
          );
        }
        this.setState({ countriesState: countries });
      }
    }
  }

  onRemoveFilledQuestionnarie = (id) => {
    this.setState({ loading: true });
    const filledQuestionnaireOpt = {
      url: `v1/filledquestionnaire`,
    };
    filledQuestionnaireOpt.types = [
      "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
      "UPD_FILLED_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .delete(filledQuestionnaireOpt, { id: id })
      .then((res) => {
        let data = {
          questionnaireId: this.state.questionnaireId,
          cor: "",
          coi: "",
          lastName: "",
          email: "",
        };
        const staicQuestionnaireOpt = {
          url: `v1/filledquestionnaire/GetStatic`,
        };
        staicQuestionnaireOpt.types = [
          "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_SUCCES",
          "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh
          .post(staicQuestionnaireOpt, data)
          .then((res) => {
            this.setState({ loading: false });
            if (res && res.filledQuestionnaires.length > 0) {
              this.setState({ filledQuestionnaires: res.filledQuestionnaires });
            } else {
              this.setState({ filledQuestionnaires: [] });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onFinish = (values) => {
    let data = {
      questionnaireId: this.state.questionnaireId,
      cor: (values && values.countryOfResidence) || "",
      coi: (values && values.countryOfInterest) || "",
      lastName: (values && values.lastName) || "",
      email: (values && values.email) || "",
    };
    this.setState({ loading: true });
    const staicQuestionnaireOpt = {
      url: `v1/filledquestionnaire/GetStatic`,
    };
    staicQuestionnaireOpt.types = [
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_SUCCES",
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .post(staicQuestionnaireOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.filledQuestionnaires.length > 0) {
          this.setState({ filledQuestionnaires: res.filledQuestionnaires });
        } else {
          this.setState({ filledQuestionnaires: [] });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onChangeTab = (id, tabName) => {
    this.setState({
      questionnaireId: id,
      activeAccTab: tabName,
      showTable: true,
      showDetail: false,
      loading: true,
      filledQuestionnaires: [],
    });
    let data = {
      questionnaireId: id,
      cor: "",
      coi: "",
      lastName: "",
      email: "",
    };
    const staicQuestionnaireOpt = {
      url: `v1/filledquestionnaire/GetStatic`,
    };
    staicQuestionnaireOpt.types = [
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_SUCCES",
      "GET_STATIC_ASSESSMENT_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .post(staicQuestionnaireOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res && res.filledQuestionnaires.length > 0) {
          this.setState({ filledQuestionnaires: res.filledQuestionnaires });
        } else {
          this.setState({ filledQuestionnaires: [] });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onMoveToClient = (questionnaire) => {
    this.setState({ loading: true });
    let clientData = questionnaire.clientName.split(" ");
    var firstName = "";
    var lastName = "";
    if (clientData && clientData.length > 0) {
      firstName = clientData[0];
    }
    if (clientData && clientData.length > 1) {
      lastName = clientData[1];
    }
    let client = {
      firstName: firstName,
      lastName: lastName,
    };
    this.props
      .addNewClient(client)
      .then((res) => {
        let mergeData = [
          {
            id: questionnaire.id,
            questionnaireId: questionnaire.questionnaireId,
            clientId: res.payload,
            isPotential: questionnaire.isPotential,
            isPublic: questionnaire.isPublic,
            clientName: questionnaire.clientName,
          },
        ];

        const filledQuestionnaireOpt = {
          url: `v1/filledquestionnaire`,
        };
        filledQuestionnaireOpt.types = [
          "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
          "UPD_FILLED_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh.put(filledQuestionnaireOpt, mergeData).then((resp) => {
          let branchId = localStorage.getItem("selectedBranchId");
          let data = {
            clientId: res.payload,
            branchId: branchId,
            questionnaireId: questionnaire.questionnaireId,
            excluded: [0],
          };
          this.props
            .onUpdMergeToClient(data)
            .then((resp) => {
              this.onGetQuestionnaires();
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  onMoveToPotentialClient = (questionnaire) => {
    this.setState({ loading: true });
    let clientData = questionnaire.clientName.split(" ");
    var firstName = "";
    var lastName = "";
    if (clientData && clientData.length > 0) {
      firstName = clientData[0];
    }
    if (clientData && clientData.length > 1) {
      lastName = clientData[1];
    }
    let client = {
      firstName: firstName,
      lastName: lastName,
    };
    this.props
      .addPotentialClient(client)
      .then((res) => {
        let mergeData = [
          {
            id: questionnaire.id,
            questionnaireId: questionnaire.questionnaireId,
            clientId: res.payload,
            isPotential: questionnaire.isPotential,
            isPublic: questionnaire.isPublic,
            clientName: questionnaire.clientName,
          },
        ];

        const filledQuestionnaireOpt = {
          url: `v1/filledquestionnaire`,
        };
        filledQuestionnaireOpt.types = [
          "UPD_FILLED_QUESTIONNAIRE_SUCCESS",
          "UPD_FILLED_QUESTIONNAIRE_FAILURE",
        ];

        apiRefresh.put(filledQuestionnaireOpt, mergeData).then((resp) => {
          let branchId = localStorage.getItem("selectedBranchId");
          let data = {
            clientId: res.payload,
            branchId: branchId,
            questionnaireId: questionnaire.questionnaireId,
            excluded: [0],
          };
          this.props
            .onUpdMergeToPClient(data)
            .then((resp) => {
              this.onGetQuestionnaires();
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  render() {
    const {
      countriesState,
      filledQuestionnaires,
      loading,
      showTable,
      showDetail,
      questionnaireId,
      filledQuestionnaireId,
    } = this.state;
    const {
      onGetDynamicLink,
      getCountries,
      onSubmitQuestionnaire,
      onGetQuestionnaire,
      onGetFilledQuestionnaire,
      dynamicLinkData,
      allCountriesData,
      questionnaireData,
      filledQuestionnaireRes,
      onGetVisaType,
      visaTypeData,
    } = this.props;
    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs
                data={headOption}
                activeTab="Web Assessment"
              />

              <div className="sus-tab-container" style={{ marginLeft: 20 }}>
                <div
                  onClick={() => this.onChangeTab(1838, "basic")}
                  className={
                    this.state.activeAccTab == "basic"
                      ? "sus-active-tab"
                      : "sus-inactive-tab"
                  }
                >
                  <span
                    className={
                      this.state.activeAccTab == "basic"
                        ? "sus-active-tab-text"
                        : "sus-inactive-tab-text"
                    }
                  >
                    Basic
                  </span>
                </div>
                <div
                  onClick={() => {
                    this.onChangeTab(1821, "web");
                  }}
                  className={
                    this.state.activeAccTab == "web"
                      ? "sus-active-tab"
                      : "sus-inactive-tab"
                  }
                >
                  <span
                    className={
                      this.state.activeAccTab == "web"
                        ? "sus-active-tab-text"
                        : "sus-inactive-tab-text"
                    }
                  >
                    Web
                  </span>
                </div>
              </div>

              {showTable && (
                <div className="report-container" style={{ marginTop: 0 }}>
                  <Form onFinish={this.onFinish} ref={this.formRef}>
                    <div style={{ display: "flex" }}>
                      <Form.Item
                        name="countryOfResidence"
                        style={{ width: 170 }}
                      >
                        <Select placeholder="Country of Residence">
                          <Option value="">Select</Option>
                          {countriesState}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="countryOfInterest"
                        style={{ width: 170, marginLeft: 15 }}
                      >
                        <Select placeholder="Country Of Interest">
                          <Option value="">Select</Option>
                          {countriesState}
                        </Select>
                      </Form.Item>

                      <Form.Item name="email" style={{ marginLeft: 15 }}>
                        <Input placeholder="Email" />
                      </Form.Item>

                      <Form.Item name="lastName" style={{ marginLeft: 15 }}>
                        <Input placeholder="LastName" />
                      </Form.Item>

                      <Form.Item style={{ marginLeft: 15 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-blue"
                        >
                          Search
                        </Button>
                      </Form.Item>
                      <Form.Item style={{ marginLeft: 15 }}>
                        <Button
                          type="primary"
                          className="button-blue"
                          onClick={() => {
                            this.formRef.current.resetFields();
                            this.onFinish();
                          }}
                        >
                          Clear
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>

                  {!showDetail && (
                    <div>
                      <table
                        className="ca-invoice-table-cont"
                        style={{ borderSpacing: 1, marginTop: 20 }}
                      >
                        <tbody>
                          <tr style={{ backgroundColor: "#F8F9FB" }}>
                            {/* <th className="ca-table-heading">Date</th> */}
                            <th
                              className="ca-table-heading"
                              style={{ paddingLeft: 10 }}
                            >
                              First Name
                            </th>
                            <th className="ca-table-heading">Last Name</th>
                            <th className="ca-table-heading">Email</th>
                            <th className="ca-table-heading">Phone</th>
                            {/* {questionnaireId == 1838 && ( */}
                            <th className="ca-table-heading">
                              Country Of Residence
                            </th>
                            {/* )} */}
                            <th className="ca-table-heading">
                              Country Of Interest
                            </th>
                            <th className="ca-table-heading">Visa Type</th>
                            <th
                              className="ca-table-heading"
                              colSpan={2}
                              style={{ textAlign: "left" }}
                            >
                              Action
                            </th>
                          </tr>
                          {filledQuestionnaires.length > 0 &&
                            filledQuestionnaires.map(
                              (questionnaireAnswers, index) => {
                                return (
                                  <>
                                    {questionnaireId == 1838 ? (
                                      <tr
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        {/* <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          12/10/2020
                                        </td> */}
                                        <td
                                          className="report-table-content-text"
                                          style={{
                                            paddingLeft: 10,
                                            textAlign: "left",
                                          }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[1]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[3]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[28]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[27]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[14]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[8]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[12]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.setState({
                                                  filledQuestionnaireId:
                                                    questionnaireAnswers.id,
                                                  showDetail: true,
                                                })
                                              }
                                            >
                                              <img
                                                src={Images.paper}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: 5,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.onRemoveFilledQuestionnarie(
                                                  questionnaireAnswers.id
                                                )
                                              }
                                            >
                                              <img
                                                src={Images.crossRed}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{
                                            textAlign: "left",
                                            padding: 5,
                                            width: 132,
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div
                                              className="pc-add-btn"
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                backgroundColor: "#36B4E6",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.onMoveToPotentialClient(
                                                  questionnaireAnswers
                                                )
                                              }
                                            >
                                              <span
                                                className="sus-modal-button-text"
                                                style={{ fontSize: 10 }}
                                              >
                                                Move to potential clients
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: 3,
                                            }}
                                          >
                                            <div
                                              className="pc-add-btn"
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                backgroundColor: "#36B4E6",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.onMoveToClient(
                                                  questionnaireAnswers
                                                )
                                              }
                                            >
                                              <span
                                                className="sus-modal-button-text"
                                                style={{ fontSize: 10 }}
                                              >
                                                Move to clients
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        {/* <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          12/10/2020
                                        </td> */}
                                        <td
                                          className="report-table-content-text"
                                          style={{
                                            paddingLeft: 10,
                                            textAlign: "left",
                                          }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[0]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[1]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[2]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[3]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[9]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[10]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          {questionnaireAnswers.answers &&
                                          questionnaireAnswers.answers.length >
                                            0
                                            ? questionnaireAnswers.answers[21]
                                                .answer
                                            : ""}
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{ textAlign: "left" }}
                                        >
                                          <div
                                            style={{ display: "flex" }}
                                            onClick={() =>
                                              this.setState({
                                                filledQuestionnaireId:
                                                  questionnaireAnswers.id,
                                                showDetail: true,
                                              })
                                            }
                                          >
                                            <div>
                                              <img
                                                src={Images.paper}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: 5,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.onRemoveFilledQuestionnarie(
                                                  questionnaireAnswers.id
                                                )
                                              }
                                            >
                                              <img
                                                src={Images.crossRed}
                                                style={{
                                                  width: 15,
                                                  height: 15,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="report-table-content-text"
                                          style={{
                                            textAlign: "left",
                                            padding: 5,
                                            width: 132,
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <div
                                              className="pc-add-btn"
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                backgroundColor: "#36B4E6",
                                              }}
                                              onClick={() =>
                                                this.onMoveToPotentialClient(
                                                  questionnaireAnswers
                                                )
                                              }
                                            >
                                              <span
                                                className="sus-modal-button-text"
                                                style={{ fontSize: 10 }}
                                              >
                                                Move to potential clients
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: 3,
                                            }}
                                          >
                                            <div
                                              className="pc-add-btn"
                                              style={{
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                backgroundColor: "#36B4E6",
                                              }}
                                              onClick={() =>
                                                this.onMoveToClient(
                                                  questionnaireAnswers
                                                )
                                              }
                                            >
                                              <span
                                                className="sus-modal-button-text"
                                                style={{ fontSize: 10 }}
                                              >
                                                Move to clients
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {showDetail && (
                <>
                  <LinkQuestionnaire
                    questionnaireId={questionnaireId}
                    onGetDynamicLink={onGetDynamicLink}
                    onGetCountries={getCountries}
                    onSubmitQuestionnaire={onSubmitQuestionnaire}
                    onGetQuestionnaire={onGetQuestionnaire}
                    onGetFilledQuestionnaire={onGetFilledQuestionnaire}
                    dynamicLinkData={dynamicLinkData}
                    countriesData={allCountriesData}
                    questionnaireData={questionnaireData}
                    filledQuestionnaireRes={filledQuestionnaireRes}
                    notLink={true}
                    isPotential={true}
                    updateButton={true}
                    isStaticQuestionnaire={true}
                    filledQuestionnaireId={filledQuestionnaireId}
                    onGetVisaType={onGetVisaType}
                    visaTypeData={visaTypeData}
                  />
                </>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesData:
      state &&
      state.visaTypeReducer &&
      state.visaTypeReducer.countriesData &&
      state.visaTypeReducer.countriesData.items &&
      state.visaTypeReducer.countriesData.items,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    allCountriesData: state.visaTypeReducer.countriesData,
    visaTypeData: state.visaTypeReducer.visaTypeData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCountries: bindActionCreators(getCountries, dispatch),
  onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
  onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
  onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
  onGetFilledQuestionnaire: bindActionCreators(
    getFilledQuestionnaire,
    dispatch
  ),
  addNewClient: bindActionCreators(addNewClient, dispatch),
  onUpdMergeToClient: bindActionCreators(updMergeToClient, dispatch),
  onUpdMergeToPClient: bindActionCreators(updMergeToPotentialClient, dispatch),
  addPotentialClient: bindActionCreators(addPotentialClient, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
});

WebAssessment = connect(mapStateToProps, mapDispatchToProps)(WebAssessment);

export default WebAssessment;
