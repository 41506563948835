import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, message, Spin } from "antd";
import { Images } from "./../../../../Themes";
import FileNotesList from "./FileNotesList";
import FroalaEditorCom from "../../../../Containers/FroalaEditorCom";
import { apiRefresh } from "../../../../services/api";

const { Option } = Select;

var userId = localStorage.getItem("userId");

const FileNotes = ({
  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  userDataEmp,

  onRemoveEmployerFile,

  onUpdateEmployerFile,

  onAddAnyTamplate,
  addAnyTemRes,
}) => {
  const [editorData, setEditorData] = useState("");
  const [dataSource, setDataSource] = useState({});
  const [fileNoteRes, setFileNoteRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFileNote, SetIsFileNote] = useState(false);

  useEffect(() => {
    // setLoading(true);
    letterTempaltesFilter(LetterTemplatesRes);
    // setLoading(false);
  }, [LetterTemplatesRes]);

  useEffect(() => {
    onGetLetterTemplates();
  }, [onGetLetterTemplates]);

  useEffect(() => {
    if (!isFileNote) {
      setLoading(true);
      SetIsFileNote(true);

      onGetEmployerFile(userDataEmp && userDataEmp.id)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [isFileNote, onGetEmployerFile, userDataEmp]);

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "FILENOTE") {
          filterList.push(data);
        }
      });
      setDataSource(filterList);
    }
  };

  const handleData = (model) => {
    setEditorData(model);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editorData && editorData.length > 0) {
      setLoading(true);
      console.log("Received values of form:", values);

      const data = {
        note: editorData,
        employerId: userDataEmp && userDataEmp.id,
      };

      onAddEmployerFile(data)
        .then(() => {
          onGetEmployerFile(userDataEmp && userDataEmp.id);
          setLoading(false);
          message.success("Successfully Added!");
          setEditorData("");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      message.warning("Add some text first");
    }

    // handleCancel();
  };

  const handeFileDropdown = (id) => {
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = ["GET_CONTRACT_SUCCESS", "GET_CONTRACT_FAILURE"];
    apiRefresh.get(getContractOpt).then((resp) => {
      const data = {
        templateName: resp.content,
        parameters: [
          {
            key: "EmployerId",
            value: userDataEmp && userDataEmp.id,
          },
        ],
      };

      onAddAnyTamplate(data).then((res) => {
        setEditorData(res.payload);
      });
    });
  };

  console.log("employerFileResemployerFileResemployerFileRes", employerFileRes);

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <div className="border-box filenote-box">
            <div className="d-flex align-center  space-between">
              <Row>
                <Col>
                  <Form.Item>
                    <Select
                      style={{ width: "246px" }}
                      mode="single"
                      showArrow
                      checked={false}
                      onChange={(value) => handeFileDropdown(value)}
                      placeholder="Templates"
                    >
                      {dataSource &&
                        dataSource.length > 0 &&
                        dataSource.map((item, index) => (
                          <Option key={item.id}> {item.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col className="profile-print-box">
                <img src={Images.printWhite} className="profile-print-icon" />
              </Col>
            </div>
            <Row>
              <Col span={24}>
                <div className="margin-top  letter-froala">
                  <FroalaEditorCom
                    setLoading={(value) => setLoading(value)}
                    model={editorData}
                    onModelChange={handleData}
                  />
                </div>
              </Col>
            </Row>
            <Form.Item className="d-flex filenote-btn">
              <Button type="primary" htmlType="submit" className="button-blue">
                Save
              </Button>
            </Form.Item>

            {employerFileRes &&
              employerFileRes.items &&
              employerFileRes.items.map((item, index) => (
                <FileNotesList
                  item={item}
                  index={index}
                  onRemoveEmployerFile={onRemoveEmployerFile}
                  onGetEmployerFile={onGetEmployerFile}
                  userDataEmp={userDataEmp}
                  onUpdateEmployerFile={onUpdateEmployerFile}
                  setLoading={setLoading}
                  loading={loading}
                />
              ))}
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default FileNotes;
