import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GmailIntegration from "../AccountSetting/UserLevelSettings/ImapSettings/GmailIntegration";

import {
  addGmailAccount,
  getGmailMail,
  gmailLogin,
  removeGmailAccount,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGmailAccount: bindActionCreators(addGmailAccount, dispatch),
    getGmailMail: bindActionCreators(getGmailMail, dispatch),
    removeGmailAccount: bindActionCreators(removeGmailAccount, dispatch),
    gmailLogin: bindActionCreators(gmailLogin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GmailIntegration);
