import React, { Fragment } from "react";
import { Table, Checkbox, Spin, Modal, Row, Col, Button } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import moment from "moment";
import { Link } from "react-router-dom";
import { Images } from "../../Themes";
import { PaperClipOutlined } from "@ant-design/icons";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
// import { CheckBox } from "@material-ui/icons";
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class ImapEmailTable extends React.PureComponent {
  state = {
    filteredList: [],
    emailChecked: [],
    selectedRowKeys: [],
    pageNumber: 1,
    pageSize: this.props.pageSize,
    LoopList: [],
    totalPages: this.props.totalEmails,
    defaultCurrent: this.props.defaultCurrent,
    importCheckBox: false,
    loading: false,
    searchMailData: [],
    emailContent: null,
    showDetailPopup: false,
    suggestedMails: [],
    loadingSugg: false,
    columns: [
      {
        width: "30px",
        title: "",
        dataIndex: "",
        key: "",
        render: (text, row, index) => {
          //   if (index == 0) {
          return <div>{row.hasAttachments ? <PaperClipOutlined /> : null}</div>;
          //   }
          //   return {
          //     children: <a>{text}</a>,
          //     props: {
          //       colSpan: 6,
          //     },
          //   };
        },
      },
      {
        width: "30px",
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (text, row, index) => {
          //   if (index == 0) {
          return <span>{moment(text).format("DD/MM/YYYY")}</span>;
          //   }
          //   return {
          //     children: <a>{text}</a>,
          //     props: {
          //       colSpan: 6,
          //     },
          //   };
        },
      },
      {
        width: "100px",
        title: "From/To",
        dataIndex: "from",
        // ellipsis: true,
        key: "from",
        render: (text, row, index) => {
          return <span>{text + " / " + row.to}</span>;
        },
      },
      {
        width: "40px",
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
      },
      {
        width: "40px",
        dataIndex: "suggestions",
        key: "suggestions",
        // onHeaderCell: (column) => {
        //
        //   return {};
        // },
        render: (text, row, index) => {
          var names = [];
          return (
            <>
              <>
                {(row.imported.length > 0 ||
                  row.importedP.length > 0 ||
                  row.importedEmployer.length > 0) && (
                  <div
                    style={{
                      backgroundColor: "#00C3E0",
                      padding: 3,
                      marginRight: 5,
                      marginBottom: 5,
                      borderRadius: 2,
                    }}
                  >
                    <span style={{ color: "#FFFFFF" }}>Imported For</span>
                  </div>
                )}
                <div style={{ display: "inline-block" }}>
                  {row.imported.map((suggestion, sugInd) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#FFAB48",
                          padding: 3,
                          marginRight: 5,
                          marginBottom: 5,
                          display: "inline-block",
                          borderRadius: 2,
                        }}
                      >
                        <span
                          style={{
                            color: "#FFFFFF",
                          }}
                        >
                          {suggestion.name}
                        </span>
                      </div>
                    );
                  })}
                  {row.importedP.map((suggestion, sugInd) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#FFAB48",
                          padding: 3,
                          marginRight: 5,
                          marginBottom: 5,
                          display: "inline-block",
                          borderRadius: 2,
                        }}
                      >
                        <span
                          style={{
                            color: "#FFFFFF",
                          }}
                        >
                          {suggestion.name}
                        </span>
                      </div>
                    );
                  })}
                  {row.importedEmployer.map((suggestion, sugInd) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#FFAB48",
                          padding: 3,
                          marginRight: 5,
                          marginBottom: 5,
                          display: "inline-block",
                          borderRadius: 2,
                        }}
                      >
                        <span
                          style={{
                            color: "#FFFFFF",
                          }}
                        >
                          {suggestion.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>

              <>
                {" "}
                {row.suggestions.map((suggestion, index) => {
                  return (
                    <div
                      style={{ paddingBottom: "10px" }}
                      // style={{
                      //   backgroundColor: "#FFAB48",
                      //   padding: 3,
                      //   marginRight: 5,
                      //   marginBottom: 5,
                      //   display: "inline-block",
                      //   borderRadius: 2,
                      // }}
                    >
                      <Checkbox
                        key={suggestion.id}
                        checked={suggestion.isChecked}
                        onChange={(e) =>
                          this.onChange(e, suggestion, row, index)
                        }
                      />
                      <span
                        style={{
                          // color: "#FFFFFF",
                          marginLeft: 5,
                        }}
                      >
                        {suggestion.name}
                      </span>
                    </div>
                  );
                })}
              </>
            </>
          );
        },
      },
      Table.SELECTION_COLUMN,
      {
        title: "Action",
        width: "20px",
        render: (text, record) => {
          return (
            <div style={{ display: "block" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="cv-action-icons-border"
                  style={{ marginLeft: 5, cursor: "pointer" }}
                  onClick={() => this.onClickView(record)}
                >
                  <img src={Images.detailEmail} className="cv-action-icon" />
                </div>
              </div>
            </div>
          );
        },
      },
    ],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchMailData && nextProps.searchMailData) {
      if (prevState.searchMailData.length > 0) {
        if (nextProps.searchMailData[0].id !== prevState.searchMailData[0].id) {
          return {
            LoopList: nextProps.searchMailData,
            searchMailData: nextProps.searchMailData,
          };
        } // <- this is setState equivalent
      }
      if (prevState.searchMailData.length === 0) {
        return {
          LoopList: nextProps.searchMailData,
          searchMailData: nextProps.searchMailData,
        };
      } else {
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalEmails != this.props.totalEmails) {
      this.setState({ totalPages: this.props.totalEmails, defaultCurrent: 1 });
    }
  }

  onClickView = (record) => {
    this.setState({ loading: true }, () => {
      let data = {
        uids: [record.id],
        clientName: this.props.payloadData && this.props.payloadData.clientName,
        clientNumber:
          this.props.payloadData && this.props.payloadData.clientNumber,
        dateFrom:
          (this.props.payloadData && this.props.payloadData.dateFrom) ||
          "1900-01-01T00:00:00+00",
        email: this.props.payloadData && this.props.payloadData.email,
        mailBox: this.props.payloadData && this.props.payloadData.mailBox,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        // userId: this.props.payloadData && this.props.payloadData.userId,
      };
      const opt = {
        url: "v1/emailimport/SpecificEmailImportSettings",
      };
      opt.types = [
        "SPECIFIC_EMAIL_IMPORT_SUCCESS",
        "SPECIFIC_EMAIL_IMPORT_FAILURE",
      ];

      apiRefresh
        .post(opt, data)
        .then((res) => {
          record.emailMessage = res.mails[0].emailMessage;
          record.emailAttachment = res.mails[0].attachments;
          this.setState({ loading: false }, () => {
            this.setState(
              { showDetailPopup: true, emailContent: record },
              () => {
                let g = this.state.showDetailPopup;
              }
            );
          });
        })
        .catch((res) => {
          this.setState({ loading: false });
        });
    });
  };

  componentWillMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.totalEmails != this.props.totalEmails) {
      this.setState({ totalPages: this.state.totalPages });
    }
    if (prevProps.searchMailData != this.props.searchMailData) {
      var suggestions = [];
      var mails = [];
      if (this.props.searchMailData && this.props.searchMailData.length > 0) {
        this.props.searchMailData.map((data, index) => {
          data.suggestions.map((suggestion, ind) => {
            suggestion.isChecked = false;
            suggestions.push(suggestion);
          });
          data.suggestions = suggestions;
          suggestions = [];
          mails.push(data);
        });
      }

      if (this.props.uncheckCheckbox) {
        if (this.props.uncheckCheckbox) {
          this.setState({ emailChecked: [] }, () => {
            this.props.onCheckEmail(
              [],
              this.state.pageNumber,
              this.state.pageSize
            );
            this.props.setUncheckCheckbox(
              this.state.pageSize,
              this.state.pageNumber
            );
          });
        }
      }

      this.setState({ searchMailData: mails });
    }
  }

  importSuggestions = () => {
    var ids = [];
    if (this.state.suggestedMails && this.state.suggestedMails.length > 0) {
      for (let i = 0; i < this.state.suggestedMails.length; i++) {
        ids.push(this.state.suggestedMails[i].id);
      }
    }

    this.setState({ loading: true }, () => {
      let emailData = {
        uids: ids,
        clientName: this.props.payloadData && this.props.payloadData.clientName,
        clientNumber:
          this.props.payloadData && this.props.payloadData.clientNumber,
        dateFrom:
          (this.props.payloadData && this.props.payloadData.dateFrom) ||
          "1900-01-01T00:00:00+00",
        email: this.props.payloadData && this.props.payloadData.email,
        mailBox: this.props.payloadData && this.props.payloadData.mailBox,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        // userId: localStorage.getItem("userId"),
      };
      const opt = {
        url: "v1/emailimport/SpecificEmailImportSettings",
      };
      opt.types = [
        "SPECIFIC_EMAIL_IMPORT_SUCCESS",
        "SPECIFIC_EMAIL_IMPORT_FAILURE",
      ];

      apiRefresh.post(opt, emailData).then((res) => {
        const VisaOpt = {
          url: `v1/imap/BulkImportEmail`,
        };
        VisaOpt.types = [
          "IMPORT_SUGGESTIONS_SUCCESS",
          "IMPORT_SUGGESTIONS_FAILURE",
        ];
        let clientList = [];

        if (this.state.suggestedMails && this.state.suggestedMails.length > 0) {
          for (
            let point = 0;
            point < this.state.suggestedMails.length;
            point++
          ) {
            if (
              this.state.suggestedMails[point] &&
              this.state.suggestedMails[point].suggestions &&
              this.state.suggestedMails[point].suggestions.length > 0
            ) {
              for (
                let pointer = 0;
                pointer < this.state.suggestedMails[point].suggestions.length;
                pointer++
              ) {
                if (
                  this.state.suggestedMails[point].suggestions[pointer]
                    .isChecked === true
                ) {
                  clientList.push(
                    this.state.suggestedMails[point].suggestions[pointer]
                  );
                }
              }
            }
          }
        }

        if (res && res.mails) {
          var semiColon = "";
          for (var i = 0; i < res.mails.length; i++) {
            res.mails[i].attachmentUrl = "";
            res.mails[i].attachmentName = "";
            for (var j = 0; j < res.mails[i].attachments.length; j++) {
              if (j > 0) {
                semiColon = ";";
              } else {
                semiColon = "";
              }
              res.mails[i].attachmentUrl +=
                semiColon + res.mails[i].attachments[j].fileURL;
              res.mails[i].attachmentName +=
                semiColon + res.mails[i].attachments[j].fileName;
            }
          }
        }

        let data = {
          emails: res && res.mails,
          clients: clientList,
        };
        apiRefresh
          .post(VisaOpt, data)
          .then((res) => {
            this.props.setUncheckCheckbox(
              this.state.pageSize,
              this.state.pageNumber
            );
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
    });
  };

  onChange = (e, suggestion, mail, index) => {
    if (this.state.allImportChecked) {
      if (e.target.checked) {
        suggestion.isChecked = e.target.checked;
        suggestion.emailId = mail.id;
        mail.suggestions.map((data, ind) => {
          if (ind === index) {
            mail.suggestions[ind] = suggestion;
          }
        });

        this.setState({ loadingSugg: true }, () => {
          let copyState = [...this.state.searchMailData];
          let _findSuggestion = this.state.searchMailData.findIndex(
            (obj) => obj.id == mail.id
          );

          copyState[_findSuggestion] = mail;
          this.setState(
            { searchMailData: copyState, loadingSugg: false },
            () => {
              let _filList = [...this.state.suggestedMails];
              _filList.push(mail);
              let uniqueArray = [...new Set(_filList)];

              this.setState({
                suggestedMails: uniqueArray,
                LoopList: uniqueArray,
              });
            }
          );
        });
      } else {
        this.setState({}, () => {
          suggestion.isChecked = e.target.checked;
          suggestion.emailId = mail.id;
          mail.suggestions.map((data, ind) => {
            if (ind === index) {
              mail.suggestions[ind] = suggestion;
            }
          });

          this.setState({ loadingSugg: true }, () => {
            let copyState = [...this.state.searchMailData];
            let _findSuggestion = this.state.searchMailData.findIndex(
              (obj) => obj.id == mail.id
            );

            copyState[_findSuggestion] = mail;
            this.setState(
              { searchMailData: copyState, loadingSugg: false },
              () => {
                let filteredList = [];
                if (this.state.LoopList && this.state.LoopList.length > 0) {
                  for (let k = 0; k < this.state.LoopList.length; k++) {
                    if (this.state.LoopList[k].id !== mail.id) {
                      if (
                        this.state.LoopList[k] &&
                        this.state.LoopList[k].suggestions &&
                        this.state.LoopList[k].suggestions.length > 0
                      ) {
                        filteredList.push(this.state.LoopList[k]);
                      }
                    } else {
                      let bol = false;
                      if (mail.suggestions && mail.suggestions.length > 0) {
                        for (
                          let ind = 0;
                          ind < mail.suggestions.length;
                          ind++
                        ) {
                          if (
                            mail.suggestions[ind] &&
                            mail.suggestions[ind].isChecked &&
                            mail.suggestions[ind].isChecked === true
                          ) {
                            bol = true;
                            break;
                          }
                        }
                      }
                      if (mail.suggestions.length > 1 && bol) {
                        filteredList.push(this.state.LoopList[k]);
                      } else {
                        //donothing
                      }
                    }
                  }
                }
                let uniqueArray = [...new Set(filteredList)];
                this.setState({
                  suggestedMails: uniqueArray,
                  LoopList: uniqueArray,
                });
              }
            );
          });
        });
      }
    } else {
      if (e.target.checked) {
        this.setState({}, () => {
          suggestion.isChecked = e.target.checked;
          suggestion.emailId = mail.id;
          mail.suggestions.map((data, ind) => {
            if (ind === index) {
              mail.suggestions[ind] = suggestion;
            }
          });

          this.setState({ loadingSugg: true }, () => {
            let copyState = [...this.state.searchMailData];
            let _findSuggestion = this.state.searchMailData.findIndex(
              (obj) => obj.id == mail.id
            );

            copyState[_findSuggestion] = mail;
            this.setState(
              { searchMailData: copyState, loadingSugg: false },
              () => {
                let filteredList = [...this.state.filteredList];
                if (this.state.LoopList && this.state.LoopList.length > 0) {
                  for (let k = 0; k < this.state.LoopList.length; k++) {
                    if (this.state.LoopList[k].id !== mail.id) {
                      if (mail.suggestions.length > 1) {
                        // filteredList.push(this.state.LoopList[k]);
                        //
                      } else {
                        //donothing
                      }
                    } else {
                      if (
                        this.state.LoopList[k] &&
                        this.state.LoopList[k].suggestions &&
                        this.state.LoopList[k].suggestions.length > 0
                      ) {
                        filteredList.push(this.state.LoopList[k]);
                      } else {
                      }
                    }
                  }
                }
                let uniqueArray = [...new Set(filteredList)];
                this.setState({
                  suggestedMails: uniqueArray,
                  filteredList: uniqueArray,
                });
              }
            );
          });
        }); //donothing
      } else {
        suggestion.isChecked = e.target.checked;
        suggestion.emailId = mail.id;
        mail.suggestions.map((data, ind) => {
          if (ind === index) {
            mail.suggestions[ind] = suggestion;
          }
        });

        this.setState({ loadingSugg: true }, () => {
          let copyState = [...this.state.searchMailData];
          let _findSuggestion = this.state.searchMailData.findIndex(
            (obj) => obj.id == mail.id
          );

          copyState[_findSuggestion] = mail;
          this.setState(
            { searchMailData: copyState, loadingSugg: false },
            () => {
              let _filList = [...this.state.filteredList];
              let _bol = true;
              if (mail.suggestions.length > 1) {
                for (let inj = 0; inj < mail.suggestions.length; inj++) {
                  if (mail.suggestions[inj].isChecked === true) {
                    _bol = false;
                  }
                }
              }
              let finalList;
              if (_bol) {
                finalList = _filList.filter((data) => data.id !== mail.id);
              } else {
                finalList = _filList;
              }
              let uniqueArray = [...new Set(finalList)];
              this.setState({
                filteredList: uniqueArray,
                suggestedMails: uniqueArray,
              });
            }
          );
        });
      }
    }
  };

  onChangeImportChecked = (val) => {
    if (val.target.checked === true) {
      this.setState(
        {
          allImportChecked: val.target.checked,
          LoopList: this.props.searchMailData,
          loadingSugg: true,
        },
        () => {
          var suggestions = [];
          var mails = [];
          var suggestionMail = [];
          var suggestedClient = [];
          var suggestMail = null;
          if (
            this.state.searchMailData &&
            this.state.searchMailData.length > 0
          ) {
            this.state.searchMailData.map((data, index) => {
              data.suggestions.map((suggestion, ind) => {
                suggestion.isChecked = val.target.checked;
                suggestion.emailId = data.id;
                suggestions.push(suggestion);
                if (val.target.checked) {
                  suggestedClient.push(suggestion);
                }
              });

              suggestMail = data;

              data.suggestions = suggestions;
              if (val.target.checked && suggestions.length > 0) {
                suggestMail.suggestions = suggestedClient;
                suggestedClient = [];
                suggestionMail.push(suggestMail);
              }
              suggestions = [];
              mails.push(data);
            });
          }
          this.setState(
            {
              searchMailData: mails,
              suggestedMails: suggestionMail,
              loadingSugg: false,
            },
            () => {
              let g = this.state.searchMailData;
            }
          );
        }
      );
    } else {
      let g = this.props.searchMailData;

      this.setState(
        {
          allImportChecked: val.target.checked,
          LoopList: this.props.searchMailData,
          loadingSugg: true,
        },
        () => {
          var suggestions = [];
          var mails = [];
          var suggestionMail = [];
          var suggestedClient = [];
          var suggestMail = null;
          if (
            this.state.searchMailData &&
            this.state.searchMailData.length > 0
          ) {
            this.state.searchMailData.map((data, index) => {
              data.suggestions.map((suggestion, ind) => {
                suggestion.isChecked = val.target.checked;
                suggestion.emailId = data.id;
                suggestions.push(suggestion);
                if (val.target.checked) {
                  suggestedClient.push(suggestion);
                }
              });

              suggestMail = data;

              data.suggestions = suggestions;
              if (val.target.checked && suggestions.length > 0) {
                suggestMail.suggestions = suggestedClient;
                suggestedClient = [];
                suggestionMail.push(suggestMail);
              }
              suggestions = [];
              mails.push(data);
            });
          }
          this.setState(
            { searchMailData: mails, suggestedMails: [], loadingSugg: false },
            () => {
              let g = this.state.searchMailData;
            }
          );
        }
      );
    }
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        allImportChecked: false,
        LoopList: this.state.searchMailData,
        filteredList: [],
        selectedRowKeys: [],
        emailChecked: [],
      },
      () => {
        this.props.onCheckEmail(
          this.state.emailChecked,
          this.state.pageNumber,
          this.state.pageSize
        );
        this.props.setPagination(this.state.pageSize, this.state.pageNumber);
        this.setState({ loading: true });
        let data = {
          clientName:
            (this.props.payloadData && this.props.payloadData.clientName) || "",
          clientNumber:
            (this.props.payloadData && this.props.payloadData.clientNumber) ||
            "",
          dateFrom:
            (this.props.payloadData && this.props.payloadData.dateFrom) || "",
          email: (this.props.payloadData && this.props.payloadData.email) || "",
          mailBox:
            (this.props.payloadData && this.props.payloadData.mailBox) || "",
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          // userId:
          //   (this.props.payloadData && this.props.payloadData.userId) ||
          //   localStorage.getItem("userId"),
        };

        this.props
          .onSearchImapEmail(data)
          .then(() => {
            this.setState(
              {
                loading: false,
                defaultCurrent: this.state.pageNumber,
              },
              () => {
                console.log("state", this.state);
              }
            );
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  handleCancelDetailPopup = () => {
    this.setState({ showDetailPopup: false });
  };

  getFileEmail = () => {
    // let selectedBranchId = localStorage.getItem("selectedBranchId");
    // const docViewOpt = {
    //   url: `v1/document/GetDocumentBytesforAttachment/${res.fileName}/${selectedBranchId}`,
    // };
    //
    // docViewOpt.types = [
    //   types.GET_DOCUMENT_PREVIEW_SUCCESS,
    //   types.GET_DOCUMENT_PREVIEW_FAILURE,
    // ];
    // apiRefresh.getFile(docViewOpt).then((response) => {
    //   const file = new Blob([response], { type: "application/pdf" });
    //   const fileURL = URL.createObjectURL(file);
    //   const pdfWindow = window.open();
    //   pdfWindow.location.href = fileURL;
    // })
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({ selectedRowKeys, emailChecked: selectedRows }, () => {
      this.props.onCheckEmail(
        this.state.emailChecked,
        this.state.pageNumber,
        this.state.pageSize
      );
    });
  };

  render() {
    const {
      loadingSugg,
      columns,
      totalPages,
      defaultCurrent,
      loading,
      searchMailData,
      showDetailPopup,
      emailContent,
      selectedRowKeys,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    columns[4].title = (
      <div>
        <div>
          <span>Suggestions</span>
        </div>

        <div style={{ display: "flex" }}>
          <div>
            <Checkbox
              checked={this.state.allImportChecked}
              onChange={this.onChangeImportChecked}
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <Button
              className="button-blue"
              style={{ fontSize: 12 }}
              type="primary"
              onClick={this.importSuggestions}
            >
              Import
            </Button>
          </div>
        </div>
      </div>
    );
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {/* {displayText} */}
              </span>
            </div>
          </div>
          {showDetailPopup ? (
            <Modal
              visible={showDetailPopup}
              title={"Email Detail"}
              onCancel={this.handleCancelDetailPopup}
              // onOk={onOkLinkButton}
              width="900px"
              footer={false}
            >
              <>
                {emailContent && !emailContent.draft && (
                  <div
                    style={{
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "100vh",
                      maxWidth: "100vw",
                    }}
                    className="client-email-popup-body"
                  >
                    {emailContent && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <div className="client-email-label">
                              <span>
                                {"Created Date: " +
                                  moment(emailContent.createdDate).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )}
                              </span>
                            </div>
                            {/*<div*/}
                            {/*  className="client-email-label"*/}
                            {/*  style={{ marginLeft: 10 }}*/}
                            {/*>*/}

                            {/*  <span>*/}
                            {/*    {"Created By: " + emailContent.userName}*/}
                            {/*  </span>*/}
                            {/*</div>*/}
                          </div>
                          <div>
                            {emailContent.importText &&
                            emailContent.importText.toLowerCase() == "inbox" ? (
                              <span
                                className="sent-email"
                                style={{ backgroundColor: "#2EC7EA" }}
                              >
                                Inbox Imported
                              </span>
                            ) : emailContent.autoImport === true ? (
                              <span
                                className="draft"
                                style={{ backgroundColor: "#FCBB3A" }}
                              >
                                Sent Imported
                              </span>
                            ) : emailContent.isSent === true ? (
                              <span className="sent-email">Sent Email</span>
                            ) : emailContent.draft === true ? (
                              <span className="draft">Draft</span>
                            ) : (
                              emailContent.isImported && (
                                <span
                                  className="draft"
                                  style={{ backgroundColor: "#FCBB3A" }}
                                >
                                  Imported
                                </span>
                              )
                            )}
                          </div>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"From: " + emailContent.from}
                          </span>
                        </div>
                        <div className="client-email-to-row">
                          <div>
                            <span className="client-email-address">
                              {"To: " + emailContent.to}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"Subject: " + emailContent.subject}
                          </span>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"CC: " + emailContent.cc}
                          </span>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"Bcc: " + emailContent.bcc}
                          </span>
                        </div>
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: emailContent.emailMessage,
                            }}
                          />
                        </div>
                        {emailContent.emailAttachment &&
                          emailContent.emailAttachment.length > 0 && (
                            <div
                              className="body-attachment-row"
                              style={{ display: "inline-block" }}
                            >
                              {emailContent.emailAttachment.map(
                                (attachment, index) => {
                                  return (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "inline-block",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <PaperClipOutlined />

                                        <span
                                          className="body-attachment-text"
                                          style={{
                                            fontSize: 12,
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            window.open(attachment.fileURL)
                                          }
                                        >
                                          {attachment.fileName}
                                        </span>
                                        {/*<img*/}
                                        {/*  src={Images.forward}*/}
                                        {/*  style={{*/}
                                        {/*    marginLeft: 10,*/}
                                        {/*    width: 25,*/}
                                        {/*    cursor: "pointer",*/}
                                        {/*  }}*/}
                                        {/*  // onClick={() => moveToDocs(attachment.url)}*/}
                                        {/*/>*/}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </>
                    )}
                  </div>
                )}
                {/* {(showForm || isDraft) && (
                <CreateEmailForm
                  onGetLetterTemplates={onGetLetterTemplates}
                  LetterTemplatesRes={LetterTemplatesRes}
                  onSendEmailLink={onSendEmailLink}
                  onAddEmployerEmail={onAddEmployerEmail}
                  onAddDraftClient={onAddDraftClient}
                  onGetClientProfile={onGetClientProfile}
                  clientProfileData={clientProfileData}
                  setLoading={setLoading}
                  loading={loading}
                  onGetClientHistory={onGetClientHistory}
                  onUploadAvatar={onUploadAvatar}
                  onAddEmailDocument={onAddEmailDocument}
                  emailDocumentRes={emailDocumentRes}
                  setHandleKey={setHandleKey}
                  onGetClientFamilyEmails={onGetClientFamilyEmails}
                  clientEmailRes={clientEmailRes}
                  onGetEmployerDocument={onGetEmployerDocument}
                  employerDocumentRes={employerDocumentRes}
                  onGetImapSettingss={onGetImapSettingss}
                  imapSettingRes={imapSettingRes}
                  onGetSignature={onGetSignature}
                  signatureRes={signatureRes}
                  onGetPdf={onGetPdf}
                  onGetLink={onGetLink}
                  onGetDocuments={onGetDocuments}
                  documentRes={documentRes}
                  inHistoryPopup={true}
                  emailContent={emailContent}
                  forward={isForward}
                  reply={isReply}
                  replyAll={isReplyAll}
                  isDraft={isDraft}
                  handleCancelPopup={handleCancelDetailPopup}
                />
              )} */}
                <Row
                  className="margin-top-12"
                  gutter={10}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Col>
                    <Button
                      // onClick={() => DraftData()}
                      type="primary"
                      className="login-form-button save-btn button-blue"
                      onClick={() => {
                        this.setState({
                          showDetailPopup: false,
                          emailContent: null,
                        });
                      }}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </>
            </Modal>
          ) : null}
          {loadingSugg ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <Spin size="large" spinning={loading}>
              <Table
                columns={columns}
                dataSource={searchMailData}
                className="border-3 table-head"
                bordered
                rowSelection={{
                  ...rowSelection,
                }}
                onChange={this.paginate}
                pagination={{
                  defaultCurrent: defaultCurrent,
                  total: this.state.totalPages,
                  defaultPageSize: this.state.pageSize,
                  showSizeChanger: false,
                }}
                scroll={{ x: "auto" }}
                onHeaderRow={(columns, index) => {
                  if (index === 3) {
                  }
                  return {
                    onClick: () => {}, // click header row
                  };
                }}
              />
            </Spin>
          )}
        </div>
      </Fragment>
    );
  }
}
export default ImapEmailTable;
