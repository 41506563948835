import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientList from "./../Accounts/ClientList";

import { searchClient, createAccountInvoice } from "../store/Actions";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    // onCreateAccInvoice: bindActionCreators(createAccountInvoice, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
