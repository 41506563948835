import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Checkbox,
  Button,
  Tabs,
  Spin,
  Row,
  Col,
} from "antd";
import Settings from "./Settings";
import Listing from "./Templates/Listing";
import DailyTransactions from "./DailyTransactions";
import InvoiceListing from "./Invoices/DueInvoiceListing";
import AddClientInvoice from "../Clients/AddInvoice";
import AddPotentialClientInvoice from "../PotentialClient/Invoices/AddInvoice";
import AddSchoolInvoice from "../SchoolManagement/AddInvoice";
import AddTemplateInvoice from "./Invoices/AddTemplateInvoice";
import AddEmployerInvoice from "../EmployerManagement/EmployerManagement/EmployerManagementTabs/EmployerInvoices/AddInvoice";
import AllInvoices from "./Invoices/AllInvoices";
import Reports from "./Reports";
import Graph from "./Graph";
import InvoiceTemplates from "./InvoiceTemplates";

const { Option } = Select;
const { TabPane } = Tabs;

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      loading: false,
      addInvoice: "",
      parentInvoiceId: 0,
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      subjectTypeId: 0,
    };
  }

  onChange = (key) => {
    this.setState({ key: key }, () => {
      if (key === "2") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "invoices" }, () => {
            this.setState({ loading: false });
          });
        });
      } else if (key === "1") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "" }, () => {
            this.setState({ loading: false });
          });
        });
      } else if (key === "3") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "" }, () => {
            this.setState({ loading: false });
          });
        });
      } else if (key === "4") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "" }, () => {
            this.setState({ loading: false });
          });
        });
      } else if (key === "5") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "" }, () => {
            this.setState({ loading: false });
          });
        });
      } else if (key === "6") {
        this.setState({ loading: true }, () => {
          this.setState({ addInvoice: "invoices" }, () => {
            this.setState({ loading: false });
          });
        });
      } else {
        this.setState({ addInvoice: "" });
      }
    });
  };

  getChildInfo = (
    parentInvoiceId,
    childrenTotalAmount,
    parentTotalAmount,
    addInvoice,
    clientName,
    clientId,
    clientEmail
  ) => {
    this.setState({
      parentInvoiceId: parentInvoiceId,
      childrenTotalAmount: childrenTotalAmount,
      parentTotalAmount: parentTotalAmount,
      addInvoice: addInvoice,
      clientName: clientName,
      clientId: clientId,
      clientEmail: clientEmail,
    });
  };

  getInvoiceDepartment = (id) => {
    this.setState({ subjectTypeId: id });
  };

  getTemplateInfoForInvoice = (data) => {
    let _data = data && data.payload && data.payload;
    this.setState({ templateData: _data });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <Fragment>
        <div className="reminder-tabs-header-temp">
          <Row>
            <Col xs={24}>
              <Tabs defaultActiveKey="1" onChange={this.onChange}>
                <TabPane tab="Templates" key="1">
                  <Row>
                    <Col xs={16}>
                      {this.state.key === "1" || this.state.key === "" ? (
                        <Listing key={this.state.key} />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Due/Over Due" key="2">
                  <Row>
                    <Col xs={16}>
                      {this.state.loading ? (
                        <div className={"spinner"}>
                          <Spin size="small" />
                        </div>
                      ) : this.state.addInvoice === "invoices" &&
                        this.state.key === "2" ? (
                        <InvoiceListing
                          getInvoiceDepartment={this.getInvoiceDepartment}
                          getChildInfo={this.getChildInfo}
                          getTemplateInfoForInvoice={
                            this.getTemplateInfoForInvoice
                          }
                        />
                      ) : null}
                      {this.state.addInvoice === "add" &&
                      this.state.subjectTypeId === 2 &&
                      this.state.key === "2" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddClientInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 3 &&
                        this.state.key === "2" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddPotentialClientInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 4 &&
                        this.state.key === "2" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddEmployerInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 8 &&
                        this.state.key === "2" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddTemplateInvoice
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            templateData={this.state.templateData}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 5 &&
                        this.state.key === "2" ? (
                        <AddSchoolInvoice
                          clientName={this.state.clientName}
                          clientEmail={this.state.clientEmail}
                          clientId={this.state.clientId}
                          parentInvoiceId={this.state.parentInvoiceId}
                          childrenTotalAmount={this.state.childrenTotalAmount}
                          parentTotalAmount={this.state.parentTotalAmount}
                          getChildInfo={this.getChildInfo}
                        />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Reports" key="3">
                  <Row>
                    <Col xs={16}>
                      {this.state.loading ? (
                        <div className={"spinner"}>
                          <Spin size="small" />
                        </div>
                      ) : this.state.key === "3" ? (
                        <Reports />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Settings" key="4">
                  <Row>
                    <Col xs={16}>
                      {this.state.key === "4" ? <Settings /> : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Daily Transactions" key="5">
                  <Row>
                    <Col xs={16}>
                      {this.state.loading ? (
                        <div className={"spinner"}>
                          <Spin size="small" />
                        </div>
                      ) : this.state.key === "5" ? (
                        <DailyTransactions />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Invoices" key="6">
                  <Row>
                    <Col xs={16}>
                      {this.state.loading ? (
                        <div className={"spinner"}>
                          <Spin size="small" />
                        </div>
                      ) : this.state.addInvoice === "invoices" &&
                        this.state.key === "6" ? (
                        <InvoiceListing
                          getInvoiceDepartment={this.getInvoiceDepartment}
                          getChildInfo={this.getChildInfo}
                          getTemplateInfoForInvoice={
                            this.getTemplateInfoForInvoice
                          }
                          keyd={this.state.key}
                        />
                      ) : null}
                      {this.state.addInvoice === "add" &&
                      this.state.subjectTypeId === 2 &&
                      this.state.key === "6" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddClientInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 3 &&
                        this.state.key === "6" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddPotentialClientInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 4 &&
                        this.state.key === "6" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddEmployerInvoice
                            parentInvoiceId={this.state.parentInvoiceId}
                            childrenTotalAmount={this.state.childrenTotalAmount}
                            parentTotalAmount={this.state.parentTotalAmount}
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            getChildInfo={this.getChildInfo}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 8 &&
                        this.state.key === "6" ? (
                        <div className="border-box-emp-manag emp-profile-box-employer">
                          <AddTemplateInvoice
                            clientName={this.state.clientName}
                            clientEmail={this.state.clientEmail}
                            clientId={this.state.clientId}
                            templateData={this.state.templateData}
                          />
                        </div>
                      ) : this.state.addInvoice === "add" &&
                        this.state.subjectTypeId === 5 &&
                        this.state.key === "6" ? (
                        <AddSchoolInvoice
                          clientName={this.state.clientName}
                          clientEmail={this.state.clientEmail}
                          clientId={this.state.clientId}
                          parentInvoiceId={this.state.parentInvoiceId}
                          childrenTotalAmount={this.state.childrenTotalAmount}
                          parentTotalAmount={this.state.parentTotalAmount}
                          getChildInfo={this.getChildInfo}
                        />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Invoice Templates" key="7">
                  <Row>
                    <Col xs={16}>
                      {this.state.loading ? (
                        <div className={"spinner"}>
                          <Spin size="small" />
                        </div>
                      ) : this.state.key === "7" ? (
                        <InvoiceTemplates />
                      ) : null}
                    </Col>
                    <Col xs={8}>
                      <Graph />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Accounts;
