import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Select,
  Table,
  DatePicker,
  Modal,
  message,
  Tooltip,
  Spin,
  Dropdown,
  Menu,
  Form,
} from "antd";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getPotentialClientListing,
  getVisaType,
  deletePotentialClient,
  getPotentialClientInfo,
  getTeamMember,
  getPotentialClientUpdateBol,
  addNewClientNull,
  getPotentialClientStatus,
  movePClientToClient,
  getPClientEmailHistory,
  getAllFilledQuestionnaire,
  searchMainClient,
  getPermissonUser,
  getMailBox,
  autoImportEmailClient,
  getPClientEmailImport,
} from "../store/Actions";
import { withRouter } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import moment from "moment";
import AddNewPotentialClient from "./AddNewPotentialClient";
import { Images } from "../Themes";
import { LinkQuestionnaire } from "../Containers";
import { apiRefresh } from "../services/api";
import { saveAs } from "file-saver";
import activityData from "../Components/ClientActivity/ActivityData";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

var menu = (
  <Menu>
    {/* <Menu.Item key="0">CLIENT PROFILE</Menu.Item> */}
    {/* <Menu.Item key="1">
      <Link to="/partner-profile">PARTNER DETAILS</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="2">
      <Link to="/client-family-member">FAMILY DETAILS</Link>
    </Menu.Item> */}
  </Menu>
);
class PotentialClient extends React.Component {
  columnsClientTagsTable = [
    {
      title: "Tag Name",
      dataIndex: "tag",
      key: "tag",
      ellipsis: true,
      // width: "5%",
    },
  ];

  columnsProcessingPersonsTable = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
      // width: "5%",
    },
  ];

  columns = [
    {
      title: "Contact Date",
      dataIndex: "contactDate",
      key: "contactDate",
      ellipsis: true,
      width: "50px",
      render: (text, record) => (
        <span style={{ fontSize: "12px", color: "black" }}>
          {record.contactDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(record.contactDate).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: "EZM ID",
      dataIndex: "ezmId",
      key: "ezmId",
      width: "50px",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "50px",
      // ellipsis: true,
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            onClick={() => {
              this.props.onGetPClientImportSetting(record.id).then((res) => {
                if (
                  res.payload.length > 0 &&
                  res.payload[0].isAutoImportEmail
                ) {
                  var emailQueueOpt = {
                    url: `v1/potentialclientemail/ImportEmailQueue`,
                  };

                  emailQueueOpt.types = [
                    "EMAIL_QUEUE_SUCCESS",
                    "EMAIL_QUEUE_FAILURE",
                  ];
                  apiRefresh.post(emailQueueOpt, {
                    clientId: record.id,
                  });
                }
              });
              this.props.getPotentialClientInfo(record.id).then(() => {
                this.props.getPotentialClientUpdateBol(true);
                this.props.history.push("/update-potential-client");
              });
            }}
          >
            <span style={{ fontSize: "12px", color: "black" }}>
              {record.fullName}
            </span>
          </a>
        </span>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Status",
      dataIndex: "clientStatus",
      key: "clientStatus",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // ellipsis: true,
      width: "50px",
    },
    {
      title: "Interested Visa",
      dataIndex: "interestedVisaName",
      key: "interestedVisaName",
      // ellipsis: true,
      width: "50px",
    },
    // {
    //   title: "Worth",
    //   dataIndex: "worth",
    //   key: "worth",
    //   ellipsis: true,
    //   width: "50px",
    // },
    {
      title: "Client Tag",
      dataIndex: "clientTags",
      key: "clientTags",
      width: "50px",
      render: (text, record) => (
        <span>
          {record.clientTags.map((tag, i) => (
            <span style={{ fontSize: "12px", color: "black" }}>
              {i > 0 ? ", " + tag : "" + tag}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "50px",
      render: (text, record, index) => (
        <div className="display-inline-block">
          <Select
            value="Q"
            style={{ display: "inline-block" }}
            dropdownClassName="dropdown-options-width-potential"
            onSelect={(e) => {
              this.setState({
                selectedQuestionniareId: e,
                showDetail: true,
                potentialClientId: record.id,
              });
            }}
            onFocus={() => {
              if (this.state.clickedIndex !== index) {
                this.setState({
                  questionnaireLoading: true,
                  clickedIndex: index,
                });
                this.props
                  .onGetAllFilledQuestionnaire(record.id)
                  .then((res) => {
                    let opt = this.props.allFilledQuestionnaireRes.map(
                      (questionnaire, index) => (
                        <Option value={questionnaire.questionnaireId}>
                          {questionnaire.questionnaireName}
                        </Option>
                      )
                    );
                    var clientList = this.state.potentialClientList.map(
                      (client, ind) =>
                        ind == index ? { ...record, menuOption: opt } : client
                    );
                    this.setState({
                      questionnaireLoading: false,
                      potentialClientList: clientList,
                    });
                  })
                  .catch(() => {
                    this.setState({ questionnaireLoading: false });
                  });
              }
            }}
          >
            {record.menuOption || []}
          </Select>
          {/* <Dropdown
            style={{ display: "inline-block" }}
            placement="bottomRight"
            overlay={menu}
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link"
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 20,
                height: 20,
              }}
              onClick={(e) => {
                this.setState({ loading: true });
                this.props
                  .onGetAllFilledQuestionnaire(record.id)
                  .then((res) => {
                    this.setState({ loading: false });
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                  });
                // e.preventDefault();
              }}
            >
              <span
                style={{ marginRight: 5 }}
                // onClick={() => {

                // }}
              >
                Q
              </span>
            </a>
          </Dropdown> */}
          {/* <span style={{ display: "inline-block" }}> */}
          {JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "client database").status ==
          1 ? (
            <Tooltip title="Add to Client" style={{ display: "inline-block" }}>
              <a
                href="javascript:"
                style={{ marginRight: "10px", marginLeft: 2 }}
                onClick={() => {
                  this.showModalMovePClient(record);
                }}
              >
                <PlusCircleOutlined />
              </a>
            </Tooltip>
          ) : null}
          <Tooltip title="Edit" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.setState({ loading: true });
                this.props.getPotentialClientInfo(record.id).then(() => {
                  this.setState({ loading: false });
                  this.props.getPotentialClientUpdateBol(true);
                  this.props.history.push("/update-potential-client");
                });
              }}
            >
              <EditOutlined />
            </a>
          </Tooltip>
          {/* <Tooltip title="Client Tags" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.showModalClientTags(record);
              }}
            >
              <TagsOutlined />
            </a>
          </Tooltip> */}
          <Tooltip
            title="Processing Persons"
            style={{ display: "inline-block" }}
          >
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                this.showModalProcessingPersons(record);
              }}
            >
              <InfoCircleOutlined />
            </a>
          </Tooltip>

          <Tooltip title="Delete" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              onClick={(e) => {
                e.stopPropagation();
                this.showModalDeleteClient(record.id);
              }}
            >
              <DeleteOutlined />
            </a>
          </Tooltip>
          {/* </span> */}
        </div>
      ),
    },
  ];

  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      isVisibleAddNew: false,
      visibleDeleteModalClient: false,
      visibleModalClientTags: false,
      visibleModalProcessingPersons: false,
      toDate: "1753-01-01T12:00:00.000Z",
      fromDate: "1753-01-01T12:00:00.000Z",
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      occupation: "",
      clientStatus: "",
      interestedVisa: "",
      salePerson: "",
      exclude: false,
      clientTag: "",
      priority: 0,
      pageNumber: 1,
      pageSize: 20,
      totalPages: 0,
      defaultCurrent: 1,
      loading: true,
      pagLoading: false,
      branchId: selectedBranchId,
      potentialClientList: [],
      potentialClientDataStatuses: [],
      visibleModalMovePClient: false,
      isGetPotential: true,
      showDetail: false,
      selectedQuestionniareId: null,
      menuData: [],
      potentialClientId: "00000000-0000-0000-0000-000000000000",
      movePClientName: "",
      clickedIndex: null,
      questionnaireLoading: false,
      selectedRowKeys: [],
      processingPersonList: [],
      addProcessingPerson: false,
      remProcessingPerson: false,
      selectedRows: [],
      remId: null,
      newId: null,
      assignTag: false,
      remTag: false,
      selectedTagsList: [],
      selectedRemTagsList: [],
      users: [],
      processingPerson:
        localStorage.getItem("userOwner") === "True"
          ? []
          : localStorage.getItem("userManager") === "true"
          ? []
          : localStorage.getItem("userOwner") !== "True" &&
            localStorage.getItem("userManager") !== "true" &&
            JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() === "client database").status ===
              1
          ? []
          : [localStorage.getItem("userId")],
    };
    this.getPotentialClients();

    // this.props
    //   .onGetAllFilledQuestionnaire(clientId)
    //   .then((res) => {
    //     // this.setState({ loading: false });
    //   })
    //   .catch((err) => {
    //     // this.setState({ loading: false });
    //   });
    this.props.getClientTag();
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    this.props.onGetPermProcessingPerson(true).then((res) => {
      let usersData = res.payload.users.map((item, index) => (
        <Option value={item.id}>{item.fullName}</Option>
      ));
      this.setState({ users: usersData });
    });
    this.props.getPotentialClientStatus();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      prevProps.allFilledQuestionnaireRes !==
      this.props.allFilledQuestionnaireRes
    ) {
      let m = this.props.allFilledQuestionnaireRes.map(
        (questionnaire, index) => (
          <Option value={questionnaire.questionnaireId}>
            {questionnaire.questionnaireName}
          </Option>
        )
      );

      let _state = {
        isVisibleAddNew: false,
        visibleDeleteModalClient: false,
        visibleModalClientTags: false,
        visibleModalProcessingPersons: false,
        toDate: "1753-01-01T12:00:00.000Z",
        fromDate: "1753-01-01T12:00:00.000Z",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        occupation: "",
        clientStatus: "",
        interestedVisa: "",
        salePerson: "",
        exclude: false,
        clientTag: "",
        priority: 0,
        pageNumber: 1,
        pageSize: 20,
        totalPages: 0,
        defaultCurrent: 1,
        potentialClientList: [],
        potentialClientDataStatuses: [],
        processingPerson: this.state.salePerson || [],
        // localStorage.getItem("userOwner") === "True"
        //   ? "00000000-0000-0000-0000-000000000000"
        //   : localStorage.getItem("userManager") === "true"
        //   ? "00000000-0000-0000-0000-000000000000"
        //   : localStorage.getItem("userOwner") !== "True" &&
        //     localStorage.getItem("userManager") !== "true" &&
        //     JSON.parse(
        //       decodeURIComponent(
        //         escape(
        //           window.atob(localStorage.getItem("userSystemPermissions"))
        //         )
        //       )
        //     ).find((x) => x.role.toLowerCase() === "client database")
        //       .status === 1
        //   ? "00000000-0000-0000-0000-000000000000"
        //   : localStorage.getItem("userId"),
      };

      // if (!this.state.isGetPotential) {
      //   this.setState({ loading: true }, () => {
      //     this.props.getPotentialClientListing(_state).then(() => {
      //       let _potentialClientListingCount;
      //       // if (this.state.exclude === true) {
      //       //   _potentialClientListingCount =
      //       //   this.props &&
      //       //   this.props.potentialClientListingCountTemp &&
      //       //   this.props.potentialClientListingCountTemp;
      //       //
      //       // } else {
      //       _potentialClientListingCount =
      //         this.props &&
      //         this.props.potentialClientListingCount &&
      //         this.props.potentialClientListingCount;

      //       // }

      //       this.setState({
      //         totalPages: _potentialClientListingCount,
      //         loading: false,
      //       });
      //     });
      //   });

      //   this.props.getPotentialClientStatus().then(() => {
      //     let _potentialClientDataStatuses = this.props.potentialClientData;

      //     this.setState({
      //       potentialClientDataStatuses: _potentialClientDataStatuses,
      //     });
      //   });
      // }

      this.setState({ menuData: m });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _potentialClientList =
      nextProps &&
      nextProps.potentialClientListing &&
      nextProps.potentialClientListing;
    let filterList = [];
    if (_potentialClientList && _potentialClientList.length > 0) {
      _potentialClientList.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        filterList.push(data);
      });
    }

    // _potentialClientList = filterList;

    // if (_potentialClientList && _potentialClientList) {
    //   for (let index = 0; index < _potentialClientList.length; index++) {
    //     _potentialClientList[index].contactDate = moment(
    //       _potentialClientList[index].contactDate
    //     ).format("DD-MM-YYYY");
    //   }
    // }
    let _potentialClientDataStatuses =
      nextProps && nextProps.potentialClientData;
    this.setState(
      {
        potentialClientList: filterList,
        potentialClientDataStatuses: _potentialClientDataStatuses,
      },
      () => {
        console.log("state", this.state);
      }
    );
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {});
    }
  }

  getPotentialClients = () => {
    this.props
      .getPotentialClientListing(this.state)
      .then(() => {
        let _potentialClientListingCount =
          this.props &&
          this.props.potentialClientListingCount &&
          this.props.potentialClientListingCount;

        this.setState({
          totalPages: _potentialClientListingCount,
          isGetPotential: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ isGetPotential: false, loading: false });
      });
  };

  getHeaderButtons = () => {
    return (
      <Row>
        <Col>
          <Space size={8} className={"buttons-container-potential-clients"}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                this.props.addNewClientNull();
                this.props.getPotentialClientUpdateBol(true);

                this.props.history.push("/add-potential-client");
              }}
              className="button-blue"
            >
              <span className={"buttons-potential-clients"}>
                ADD POTENTIAL CLIENT
              </span>
            </Button>
            <Button
              className={"buttons-potential-clients button-blue"}
              size="small"
              type="primary"
              onClick={() => {
                this.props.history.push("/import-potential-client");
              }}
            >
              <span className={"buttons-potential-clients"}>
                IMPORT POTENTIAL CLIENT
              </span>
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  handleChangeFromDate = (date) => {
    let _fromDate =
      date === null
        ? ""
        : moment(moment(date).format()).format("YYYY-MM-DDT00:00:00+00:00");

    this.setState({ fromDate: _fromDate });
  };

  handleChangeToDate = (date) => {
    let _toDate =
      date === null
        ? ""
        : moment(moment(date).format()).format("YYYY-MM-DDT00:00:00+00:00");

    this.setState({ toDate: _toDate });
  };

  getDateFilters = () => {
    return (
      <Row>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"date-from-container"}>
            <p className={"date-text"}>Date From:</p>
            <Form.Item style={{ width: "100%" }}>
              <DatePicker
                format={dateFormat}
                size="large"
                onChange={this.handleChangeFromDate}
              />
            </Form.Item>
          </div>
          <div className={"date-to-container"}>
            <p className={"date-text"}>Date To:</p>
            <Form.Item style={{ width: "100%" }}>
              <DatePicker
                format={dateFormat}
                size="large"
                onChange={this.handleChangeToDate}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeFirstName = (e) => {
    this.setState({ firstName: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeLastName = (e) => {
    this.setState({ lastName: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeAddress = (e) => {
    this.setState({ address: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  handleChangeOccupation = (e) => {
    this.setState({ occupation: e.target.value }, () => {
      console.log("state", this.state);
    });
  };

  getInputFilters = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"inputs-filters-container"}>
            <Form.Item>
              <Input
                placeholder="Email"
                size="large"
                onChange={this.handleChangeEmail}
              />
            </Form.Item>
          </div>
          <div className={"inputs-filters-container"}>
            <Form.Item>
              <Input
                placeholder="First Name"
                size="large"
                onChange={this.handleChangeFirstName}
              />
            </Form.Item>
          </div>
          <div className={"inputs-filters-container"}>
            <Form.Item>
              <Input
                placeholder="Last Name"
                size="large"
                onChange={this.handleChangeLastName}
              />
            </Form.Item>
          </div>
          <div className={"inputs-filters-container"}>
            <Form.Item>
              <Input
                placeholder="Occupation"
                size="large"
                onChange={this.handleChangeOccupation}
              />
            </Form.Item>
          </div>
          <div className={"inputs-filters-container"}>
            <Form.Item>
              <Input
                placeholder="Address"
                size="large"
                onChange={this.handleChangeAddress}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  handleChangeClientStatus = (value) => {
    this.setState({ clientStatus: value });
  };

  handleChangeVisaTypes = (value) => {
    this.setState({ interestedVisa: value });
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTag: value.toString() });
  };

  handleChangePriority = (value) => {
    this.setState({ priority: value });
  };

  handleChangeSalesPerson = (value) => {
    this.setState({ salePerson: value });
  };

  handleChangeAddProcessingPerson = (value) => {
    let uniqueId = null;
    let ppId = this.state.processingPersonList;
    let clientIds = [];

    this.state.selectedRows.map((row) => {
      clientIds.push(row.id);
    });
    if (value.length > this.state.processingPersonList.length) {
      uniqueId = value.filter((f) => !ppId.includes(f));
      let data = {
        clientIds: clientIds,
        userId: uniqueId[0],
      };
      const processingPersonAssign = {
        url: `v1/client/processingperson/InsertMultiple`,
      };

      processingPersonAssign.types = [
        "PROCESSING_PERSON_ASSIGN_SUCCESS",
        "PROCESSING_PERSON_ASSIGN_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .post(processingPersonAssign, data)
        .then((res) => {
          this.props.onGetMailBox(data.userId).then((res) => {
            for (var i = 0; i < clientIds.length; i++) {
              let addEmailImp = {
                clientId: clientIds[0],
                isRead: true,
                isAutoImportEmail: true,
                isImportFromPrimaryEmail: true,
                isImportFromSecondaryEmail: true,
                isImportFromOtherEmail: true,
                importStartDate: new Date(),
                istTimeImport: true,
                userId: uniqueId[0],
              };
              this.props.onAutoImportEmailClient(addEmailImp);
            }
          });
          message.success("Processing person added successfully!");
          this.setState({ processingPersonList: value, loading: false });
          this.getPotentialClients();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      uniqueId = ppId.filter((f) => !value.includes(f));
      let data = {
        userId: "00000000-0000-0000-0000-000000000000",
        oldUserId: uniqueId && uniqueId.length > 0 ? uniqueId[0] : "",
        clientIds: clientIds,
      };
      this.setState({ loading: true });
      const processingPersonRemove = {
        url: `v1/client/processingperson/ChangeProcessingPerson`,
      };

      processingPersonRemove.types = [
        "PROCESSING_PERSON_ASSIGN_SUCCESS",
        "PROCESSING_PERSON_ASSIGN_FAILURE",
      ];
      apiRefresh
        .post(processingPersonRemove, data)
        .then((res) => {
          this.setState({ loading: false });
          message.success("Removed successfully!");
          this.getPotentialClients();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    }
  };

  handleChangeAddClientTag = (value) => {
    let uniqueId = null;
    let ppId = this.state.selectedTagsList;
    let clientIds = [];

    this.state.selectedRows.map((row) => {
      clientIds.push(row.id);
    });
    if (value.length > this.state.selectedTagsList.length) {
      uniqueId = value.filter((f) => !ppId.includes(f));
      let data = {
        subjectid: clientIds,
        tagIds: uniqueId,
      };
      const tagAssign = {
        url: `v1/ClientTag/InsertMultiple`,
      };

      tagAssign.types = [
        "CLIENT_TAG_ASSIGN_SUCCESS",
        "CLIENT_TAG_ASSIGN_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .post(tagAssign, data)
        .then((res) => {
          message.success("Client tag added successfully!");
          this.setState({ selectedTagsList: value, loading: false });
          this.getPotentialClients();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      uniqueId = ppId.filter((f) => !value.includes(f));
      let data = {
        tagIds: uniqueId && uniqueId.length > 0 ? uniqueId[0] : 0,
        subjectid: clientIds,
      };
      this.setState({ loading: true });
      const clientTagRem = {
        url: `v1/ClientTag/UpdateMultiple`,
      };

      clientTagRem.types = [
        "CLIENT_TAG_REMOVE_SUCCESS",
        "CLIENT_TAG_REMOVE_FAILURE",
      ];
      apiRefresh
        .put(clientTagRem, data)
        .then((res) => {
          this.setState({ loading: false });
          message.success("Removed successfully!");
          this.getPotentialClients();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    }
  };

  handleChangeRemClientTag = (value) => {
    let uniqueId = null;
    let ppId = this.state.selectedTagsList;
    let clientIds = [];

    this.state.selectedRows.map((row) => {
      clientIds.push(row.id);
    });
    if (value.length == 1) {
      uniqueId = value;
    } else {
      uniqueId = value.filter((f) => !ppId.includes(f));
    }
    let data = {
      tagIds: uniqueId && uniqueId.length > 0 ? uniqueId[0] : "",
      subjectid: clientIds,
    };
    this.setState({ loading: true });
    const processingPersonRemove = {
      url: `v1/ClientTag/UpdateMultiple`,
    };

    processingPersonRemove.types = [
      "CLIENT_TAG_REMOVE_SUCCESS",
      "CLIENT_TAG_REMOVE_FAILURE",
    ];
    apiRefresh
      .put(processingPersonRemove, data)
      .then((res) => {
        this.setState({ loading: false, selectedTagsList: value });
        message.success("Removed successfully!");
        this.getPotentialClients();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed!");
      });
  };

  onSaveChanges = () => {
    let clientIds = [];

    this.state.selectedRows.map((row) => {
      clientIds.push(row.id);
    });
    let data = {
      userId: this.state.newId || "00000000-0000-0000-0000-000000000000",
      oldUserId: this.state.remId || "00000000-0000-0000-0000-000000000000",
      clientIds: clientIds,
    };

    const processingPersonRemove = {
      url: `v1/client/processingperson/ChangeProcessingPerson`,
    };

    processingPersonRemove.types = [
      "PROCESSING_PERSON_ASSIGN_SUCCESS",
      "PROCESSING_PERSON_ASSIGN_FAILURE",
    ];
    if (clientIds.length > 0) {
      this.setState({ loading: true });
      apiRefresh
        .post(processingPersonRemove, data)
        .then((res) => {
          this.setState({
            loading: false,
            remId: null,
            newId: null,
          });
          message.success("Processing Person changed successfully!");
          this.getPotentialClients();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    } else {
      message.warning("Please select atleast one potential client!");
    }
  };

  getSelectFilters = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                className={"width-selects-filters"}
                placeholder="Please select client status"
                virtual={false}
                size="middle"
                onChange={this.handleChangeClientStatus}
              >
                <Option value={""}>{"Select"}</Option>
                {this.state &&
                  this.state.potentialClientDataStatuses &&
                  this.state.potentialClientDataStatuses.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.name}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className={"filters-container-sub"}>
            <Form.Item>
              <Select
                virtual={false}
                className={"width-selects-filters"}
                placeholder="Please select interested visa"
                size="middle"
                onChange={this.handleChangeVisaTypes}
              >
                <Option value={""}>{"Select"}</Option>
                {this.props &&
                  this.props.visaTypeData &&
                  this.props.visaTypeData.items &&
                  this.props.visaTypeData.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return (
                      <Option value={data.id.toString()}>
                        {data.visaTypeName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                virtual={false}
                className={"width-selects-filters"}
                placeholder="Please select client tags"
                size="middle"
                onChange={this.handleChangeClientTags}
              >
                <Option value={""}>{"Select"}</Option>
                {this.props &&
                  this.props.clientTagRes &&
                  this.props.clientTagRes.items &&
                  this.props.clientTagRes.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className={"filters-container-sub"}>
            <Form.Item>
              <Select
                virtual={false}
                className={"width-selects-filters"}
                size="middle"
                placeholder={"Select priority"}
                onChange={this.handleChangePriority}
              >
                <Option value={1}>{"Oldest to Newest"}</Option>
                <Option value={0}>{"Newest to Oldest"}</Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  getAddProcessingPersonDropdown = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                mode="multiple"
                className={"width-selects-filters"}
                placeholder="Please select Processing Person"
                virtual={false}
                size="middle"
                onChange={this.handleChangeAddProcessingPerson}
              >
                <Option value={""}>{"Select"}</Option>
                {/* {this.props &&
                  this.props.teamMembers &&
                  this.props.teamMembers.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.fullName}</Option>;
                  })} */}
                {this.state.users}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  getRemProcessingPersonDropdown = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                className={"width-selects-filters"}
                placeholder="Remove"
                virtual={false}
                size="middle"
                onChange={(e) => {
                  this.setState({ remId: e });
                }}
                value={this.state.remId}
              >
                <Option value={""}>{"Select"}</Option>
                {/* {this.props &&
                  this.props.teamMembers &&
                  this.props.teamMembers.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.fullName}</Option>;
                  })} */}
                {this.state.users}
              </Select>
            </Form.Item>
          </div>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                className={"width-selects-filters"}
                placeholder="Add New"
                virtual={false}
                size="middle"
                onChange={(e) => this.setState({ newId: e })}
                value={this.state.newId}
              >
                <Option value={""}>{"Select"}</Option>
                {/* {this.props &&
                  this.props.teamMembers &&
                  this.props.teamMembers.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.fullName}</Option>;
                  })} */}
                {this.state.users}
              </Select>
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              className="employer-btn button-blue"
              onClick={() => this.onSaveChanges()}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getAddClientTagDropdown = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                mode="multiple"
                className={"width-selects-filters"}
                placeholder="Please select Client Tag"
                virtual={false}
                size="middle"
                onChange={this.handleChangeAddClientTag}
              >
                <Option value={""}>{"Select"}</Option>
                {this.props &&
                  this.props.clientTagRes &&
                  this.props.clientTagRes.items &&
                  this.props.clientTagRes.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  getRemClientTagDropdown = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-main"}>
            <Form.Item>
              <Select
                mode="multiple"
                className={"width-selects-filters"}
                placeholder="Please select Client Tag"
                virtual={false}
                size="middle"
                onChange={this.handleChangeRemClientTag}
              >
                <Option value={""}>{"Select"}</Option>
                {this.props &&
                  this.props.clientTagRes &&
                  this.props.clientTagRes.items &&
                  this.props.clientTagRes.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
    );
  };

  onClickSearch = (opt) => {
    this.setState({ loading: true });
    let _data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      address: this.state.address,
      occupation: this.state.occupation,
      clientStatus: this.state.clientStatus,
      interestedVisa: this.state.interestedVisa,
      clientTag: this.state.clientTag,
      priority: this.state.priority,
      salePerson: "",
      exclude: this.state.exclude,
      pageNumber: 1,
      pageSize: 20,
      processingPerson: this.state.salePerson || [],
    };

    if (opt == "export") {
      const clientList = {
        url: `v1/potentialclient/AllPrint`,
      };

      clientList.types = [
        "EXPORT_SEARCH_CLIENT_SUCCESS",
        "EXPORT_SEARCH_CLIENT_FAILURE",
      ];
      apiRefresh
        .postWithFileRes(clientList, _data)
        .then((res) => {
          this.setState({ loading: false });
          var blob = new Blob([res], {
            type: "application/octet-stream",
          });
          // saveAs(blob, "PotentialClientsList.xlsx");
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "PotentialClientsList.xlsx";
          link.click();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .getPotentialClientListing(_data)
        .then(() => {
          this.setState({ loading: false });
          let _length;
          // if (this.state.exclude === true) {
          //   _length =
          //     this.props &&
          //     this.props.potentialClientListingCountTemp &&
          //     this.props.potentialClientListingCountTemp;
          //
          // } else {
          _length =
            this.props &&
            this.props.potentialClientListingCount &&
            this.props.potentialClientListingCount;

          // }

          this.setState(
            {
              defaultCurrent: 1,
              totalPages: _length,
              branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        })
        .then(() => {
          this.setState({ loading: false });
        });
    }
  };

  onClickShowAll = () => {
    window.location.reload();
  };

  getFilterButtons = () => {
    return (
      <Row style={{ marginTop: "35px" }}>
        <Col xs={18}>
          <Row>
            <Form.Item>
              <Button
                type="primary"
                style={{
                  marginRight: "4px",
                  backgroundColor: this.state.addProcessingPerson
                    ? "rgb(51, 170, 218)"
                    : "#ffffff",
                  border: "1px solid #c9c9ca",
                  padding: "5px 10px",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#1281b9",
                }}
                onClick={() =>
                  this.setState({
                    remProcessingPerson: false,
                    addProcessingPerson: !this.state.addProcessingPerson,
                    assignTag: false,
                    remTag: false,
                  })
                }
              >
                <PlusCircleOutlined />
                Processing Person
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{
                  marginRight: "4px",
                  backgroundColor: this.state.remProcessingPerson
                    ? "rgb(51, 170, 218)"
                    : "#ffffff",
                  border: "1px solid #c9c9ca",
                  padding: "5px 10px",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#1281b9",
                }}
                onClick={() =>
                  this.setState({
                    remProcessingPerson: !this.state.remProcessingPerson,
                    addProcessingPerson: false,
                    assignTag: false,
                    remTag: false,
                  })
                }
              >
                <MinusCircleOutlined /> Processing Person
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{
                  marginRight: "4px",
                  backgroundColor: this.state.assignTag
                    ? "rgb(51, 170, 218)"
                    : "#ffffff",
                  border: "1px solid #c9c9ca",
                  padding: "5px 10px",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#1281b9",
                }}
                onClick={() =>
                  this.setState({
                    remProcessingPerson: false,
                    addProcessingPerson: false,
                    remTag: false,
                    assignTag: !this.state.assignTag,
                  })
                }
              >
                <PlusCircleOutlined /> Tag
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{
                  marginRight: "4px",
                  backgroundColor: this.state.remTag
                    ? "rgb(51, 170, 218)"
                    : "#ffffff",
                  border: "1px solid #c9c9ca",
                  padding: "5px 10px",
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#1281b9",
                }}
                onClick={() =>
                  this.setState({
                    remProcessingPerson: false,
                    addProcessingPerson: false,
                    assignTag: false,
                    remTag: !this.state.remTag,
                  })
                }
              >
                <MinusCircleOutlined />
                Tag
              </Button>
            </Form.Item>
          </Row>
        </Col>
        <Col xs={6} style={{ display: "flex" }}>
          <Form.Item>
            <Button
              type="primary"
              style={{ marginRight: "4px" }}
              className="employer-btn button-blue"
              onClick={() => this.onClickSearch("export")}
            >
              Export
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              style={{ marginRight: "4px" }}
              className="employer-btn button-blue"
              onClick={() => this.onClickShowAll()}
            >
              Show All
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="employer-btn button-blue"
              onClick={() => {
                this.onClickSearch("search");
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  showModalDeleteClient = (id) => {
    this.setState({
      visibleDeleteModalClient: true,
      index: id,
    });
  };

  handleCancelDeleteModalClient = (e) => {
    this.setState({
      visibleDeleteModalClient: false,
    });
  };

  showModalMovePClient = (client) => {
    this.setState({
      visibleModalMovePClient: true,
      movePClientIndex: client.id,
      movePClientName: client.fullName,
    });
  };

  handleCancelModalMovePClient = (e) => {
    this.setState({
      visibleModalMovePClient: false,
    });
  };

  showModalClientTags = (data) => {
    let _clientTagsList = [];
    if (data.clientTags.length > 0) {
      for (let _ind = 0; _ind < data.clientTags.length; _ind++) {
        _clientTagsList.push({ tag: data.clientTags[_ind] });
      }
    }
    this.setState({
      visibleModalClientTags: true,
      dataClientTags: _clientTagsList,
    });
  };

  handleCancelShowModalClientTags = (e) => {
    this.setState({
      visibleModalClientTags: false,
    });
  };

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current, pagLoading: true },
      () => {
        this.props
          .getPotentialClientListing(this.state)
          .then(() => {
            this.setState(
              {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                address: this.state.address,
                occupation: this.state.occupation,
                defaultCurrent: 1,
                branchId: this.state.branchId,
                pagLoading: false,
              },
              () => {
                console.log("state", this.state);
              }
            );
          })
          .catch(() => {
            this.setState({ pagLoading: false });
          });
      }
    );
  };

  showModalProcessingPersons = (data) => {
    let _pPersonsList = [];
    if (data.processingPersons.length > 0) {
      for (let _ind = 0; _ind < data.processingPersons.length; _ind++) {
        _pPersonsList.push({ userName: data.processingPersons[_ind] });
      }
    }
    this.setState({
      visibleModalProcessingPersons: true,
      dataProcessingPersons: _pPersonsList,
    });
  };

  handleCancelShowModalProcessingPersons = (e) => {
    this.setState({
      visibleModalProcessingPersons: false,
    });
  };

  getModalClientTags = () => {
    return (
      <div>
        <Modal
          title="Client Tags"
          visible={this.state.visibleModalClientTags}
          onCancel={this.handleCancelShowModalClientTags}
          footer={null}
          maskClosable={false}
        >
          <div className={"school-table"}>
            <Table
              dataSource={
                this.state.dataClientTags ? this.state.dataClientTags : null
              }
              columns={this.columnsClientTagsTable}
              pagination={{
                defaultPageSize: 20,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  };

  onChangeExcludeCheckBox = (e) => {
    this.setState({ exclude: e.target.checked });
  };

  getExcludeFilter = () => {
    return (
      <Row style={{ marginTop: "27px" }}>
        <Col xs={24}>
          <div
            className={"processing-persons-container"}
            style={{ display: "flex" }}
          >
            <div className={"filters-container-main"}>
              <Form.Item>
                <Select
                  mode="multiple"
                  virtual={false}
                  placeholder="Select processing person"
                  className={"width-selects-filters"}
                  size="middle"
                  onChange={this.handleChangeSalesPerson}
                >
                  <Option value={""}>{"Select"}</Option>
                  {this.state.users}
                </Select>
              </Form.Item>
            </div>
            <div className={"checkbox"}>
              <Form.Item>
                <Checkbox onChange={(e) => this.onChangeExcludeCheckBox(e)}>
                  {<span className={"filter-text"}>Exclude</span>}
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  getAddProcessingPerson = () => {
    return (
      <Row style={{ marginTop: "27px" }}>
        <Col xs={24}>
          <div
            className={"processing-persons-container"}
            style={{ display: "flex" }}
          >
            <div className={"filters-container-main"}>
              <Form.Item>
                <Select
                  mode="multiple"
                  virtual={false}
                  placeholder="Select sales person"
                  className={"width-selects-filters"}
                  size="middle"
                  onChange={this.handleChangeSalesPerson}
                >
                  <Option value={""}>{"Select"}</Option>
                  {this.props &&
                    this.props.teamMembers &&
                    this.props.teamMembers.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.fullName}</Option>;
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className={"checkbox"}>
              <Form.Item>
                <Checkbox onChange={(e) => this.onChangeExcludeCheckBox(e)}>
                  {<span className={"filter-text"}>Exclude</span>}
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  getModalProcessingPersons = () => {
    return (
      <div>
        <Modal
          title="Processing Persons"
          visible={this.state.visibleModalProcessingPersons}
          onCancel={this.handleCancelShowModalProcessingPersons}
          footer={null}
          maskClosable={false}
        >
          <div className={"school-table"}>
            <Table
              dataSource={
                this.state.dataProcessingPersons
                  ? this.state.dataProcessingPersons
                  : null
              }
              columns={this.columnsProcessingPersonsTable}
              pagination={{
                defaultPageSize: 20,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  };

  getModalDeleteClient = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalClient}
          onCancel={this.handleCancelDeleteModalClient}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete potential client?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModalClient();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props
                    .deletePotentialClient(this.state.index)
                    .then(() => {
                      message.success("Client deleted successfully!");
                      this.setState({
                        visibleDeleteModalClient: false,
                        loading: false,
                      });
                      window.location.assign(
                        "/potential-client/potential-clients"
                      );
                      // this.props
                      //   .getPotentialClientListing(this.state)
                      //   .then(() => {
                      //
                      //     let _potentialClientListingCount
                      //     if(this.state.exclude === true)
                      //     {
                      //       _potentialClientListingCount =
                      //           this.props &&
                      //           this.props.potentialClientListingCountTemp &&
                      //           this.props.potentialClientListingCountTemp;
                      //
                      //     }
                      //     else
                      //     {
                      //       _potentialClientListingCount =
                      //           this.props &&
                      //           this.props.potentialClientListingCount &&
                      //           this.props.potentialClientListingCount;
                      //
                      //     }
                      //
                      //     this.setState({
                      //       totalPages: _potentialClientListingCount
                      //     });
                      //   });
                    })
                    .catch((err) => {
                      this.setState({ loading: false });
                    });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getModalMovePClient = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={this.state.visibleModalMovePClient}
          onCancel={this.handleCancelModalMovePClient}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                <span style={{ fontSize: 12 }}>
                  Are you sure, you want to move potential client to client?
                </span>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={15} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelModalMovePClient();
                }}
                style={{ borderRadius: 5 }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                loading={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props
                    .movePClientToClient(this.state.movePClientIndex)
                    .then(() => {
                      message.success(
                        "Potential Client is successfully added to client"
                      );

                      let userName = localStorage.getItem("userName");
                      let myData = {
                        clientName: this.state.movePClientName,
                        logMessage:
                          "Move to Client " +
                          this.state.movePClientName +
                          " moved by " +
                          userName,
                        date: moment(new Date()).format("DD/MM/YYYY"),
                        logType: "Client Information",
                        invoiceId: "0",
                      };
                      activityData(myData);
                      setTimeout(() => {
                        window.location.assign(
                          "/potential-client/potential-clients"
                        );
                      }, 1000);
                      this.setState({ loading: false });
                    })
                    .catch(() => {
                      message.error("An error occurred!");
                      this.setState({ loading: false });
                    });
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows, info) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  };

  rowSelection = {
    // selectedRowKeys,
    onChange: this.onSelectChange,
    // selections: [
    //   Table.SELECTION_ALL,
    //   Table.SELECTION_INVERT,
    //   Table.SELECTION_NONE,
    //   {
    //     // key: "odd",
    //     // text: "Select Odd Row",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];
    //       newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //         if (index % 2 !== 0) {
    //           return false;
    //         }
    //         return true;
    //       });
    //       // this.setState({ selectedRowKeys: newSelectedRowKeys });
    //       this.setState({ selectedRowKeys: newSelectedRowKeys });
    //     },
    //   },
    //   {
    //     // key: "even",
    //     // text: "Select Even Row",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];
    //       newSelectedRowKeys = changableRowKeys.filter((key, index) => {
    //         if (index % 2 !== 0) {
    //           return true;
    //         }
    //         return false;
    //       });
    //       this.setState({ selectedRowKeys: newSelectedRowKeys });
    //     },
    //   },
    // ],
  };

  getTable = () => {
    return (
      <Row style={{ marginTop: "8px" }}>
        <Col className={"p-client"} style={{ width: "100%" }}>
          <Spin spinning={this.state.pagLoading}>
            <Table
              dataSource={
                this.state.potentialClientList
                  ? this.state.potentialClientList
                  : null
              }
              rowSelection={this.rowSelection}
              columns={this.columns}
              onChange={this.paginate}
              rowClassName={(record, index) =>
                record.isduplicate ? "table-row-red" : "table-row-transparent"
              }
              pagination={{
                defaultCurrent: this.state.defaultCurrent,
                total: this.state.totalPages,
                showSizeChanger: true,
                defaultPageSize: 20,
                pageSizeOptions: ["20", "30", "50", "100", "150"],
              }}
              scroll={{ x: "auto" }}
            />
          </Spin>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      showDetail,
      selectedQuestionniareId,
      questionnaireLoading,
    } = this.state;
    return (
      <div>
        <Spin
          size="large"
          spinning={this.state.loading || questionnaireLoading}
        >
          <div>
            {this.getHeaderButtons()}
            <div style={{ display: "flex" }}>
              <div className="page-container">
                <div className="ts-container-inquiry">
                  <Form>
                    {this.getDateFilters()}
                    {this.getInputFilters()}
                    {this.getSelectFilters()}
                    {this.getExcludeFilter()}
                    {this.getFilterButtons()}
                    {this.state.addProcessingPerson &&
                      this.getAddProcessingPersonDropdown()}
                    {this.state.assignTag && this.getAddClientTagDropdown()}
                    {this.state.remTag && this.getRemClientTagDropdown()}
                    {this.state.remProcessingPerson &&
                      this.getRemProcessingPersonDropdown()}
                  </Form>
                  {this.getTable()}
                  {this.getModalMovePClient()}
                  {this.getModalDeleteClient()}
                  {this.getModalClientTags()}
                  {this.getModalProcessingPersons()}

                  <Modal
                    visible={showDetail}
                    onCancel={() => this.setState({ showDetail: false })}
                    footer={null}
                    width="80%"
                  >
                    <>
                      <LinkQuestionnaire
                        questionnaireId={selectedQuestionniareId}
                        // onGetDynamicLink={onGetDynamicLink}
                        // onGetCountries={getCountries}
                        // onSubmitQuestionnaire={onSubmitQuestionnaire}
                        // onGetQuestionnaire={onGetQuestionnaire}
                        // onGetFilledQuestionnaire={
                        //   onGetFilledQuestionnaire
                        // }
                        // dynamicLinkData={dynamicLinkData}
                        // countriesData={allCountriesData}
                        // questionnaireData={questionnaireData}
                        // filledQuestionnaireRes={filledQuestionnaireRes}
                        notLink={true}
                        clientprofileid={this.state.potentialClientId}
                        isPotential={true}
                        potentialMerge={true}
                      />
                    </>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let selectedBranch =
    state &&
    state.potentialClientReducer &&
    state.potentialClientReducer.potentialClientData;

  return {
    potentialClientListing:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientListing &&
      state.potentialClientReducer.potentialClientListing.taskIds &&
      state.potentialClientReducer.potentialClientListing.taskIds,
    potentialClientListingCount:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientListing &&
      state.potentialClientReducer.potentialClientListing &&
      state.potentialClientReducer.potentialClientListing.count,
    potentialClientListingCountTemp:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientListing &&
      state.potentialClientReducer.potentialClientListing &&
      state.potentialClientReducer.potentialClientListing.searchCount,
    teamMembers:
      state &&
      state.teamMemberReducer &&
      state.teamMemberReducer.teamMembers &&
      state.teamMemberReducer.teamMembers.users &&
      state.teamMemberReducer.teamMembers.users,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    visaTypeData:
      state && state.visaTypeReducer && state.visaTypeReducer.visaTypeData,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    potentialClientData:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientData &&
      state.potentialClientReducer.potentialClientData.items &&
      state.potentialClientReducer.potentialClientData.items,
    allFilledQuestionnaireRes:
      state.questionnaireReducer.allFilledQuestionnaireRes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPotentialClientListing: bindActionCreators(
    getPotentialClientListing,
    dispatch
  ),
  getClientTag: bindActionCreators(getClientTag, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
  deletePotentialClient: bindActionCreators(deletePotentialClient, dispatch),
  getPotentialClientInfo: bindActionCreators(getPotentialClientInfo, dispatch),
  getTeamMember: bindActionCreators(getTeamMember, dispatch),
  getPotentialClientUpdateBol: bindActionCreators(
    getPotentialClientUpdateBol,
    dispatch
  ),
  addNewClientNull: bindActionCreators(addNewClientNull, dispatch),
  getPotentialClientStatus: bindActionCreators(
    getPotentialClientStatus,
    dispatch
  ),
  movePClientToClient: bindActionCreators(movePClientToClient, dispatch),
  onGetPClientHistory: bindActionCreators(getPClientEmailHistory, dispatch),
  onGetAllFilledQuestionnaire: bindActionCreators(
    getAllFilledQuestionnaire,
    dispatch
  ),
  onSearchClient: bindActionCreators(searchMainClient, dispatch),
  onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  onGetMailBox: bindActionCreators(getMailBox, dispatch),
  onAutoImportEmailClient: bindActionCreators(autoImportEmailClient, dispatch),
  onGetPClientImportSetting: bindActionCreators(
    getPClientEmailImport,
    dispatch
  ),
});
PotentialClient = connect(mapStateToProps, mapDispatchToProps)(PotentialClient);

export default withRouter(PotentialClient);
