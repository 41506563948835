import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HubspotIntegration from "../AccountSetting/UserLevelSettings/HubspotIntegration";

import {
  getHubspotToken,
  hubspotLogin,
  addHubspotAccount,
  removeHubspotAccount,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHubspotAccount: bindActionCreators(addHubspotAccount, dispatch),
    getHubspotToken: bindActionCreators(getHubspotToken, dispatch),
    removeHubspotAccount: bindActionCreators(removeHubspotAccount, dispatch),
    hubspotLogin: bindActionCreators(hubspotLogin, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubspotIntegration);
