import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReminderSetting from "./../AccountSetting/ReminderSetting/ReminderSetting";

import {
  addReminderSettings,
  getReminderSettings,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    addReminderSettingsRes: state.accountSetReducer.addReminderSettingsRes,
    reminderSettingRes: state.accountSetReducer.reminderSettingRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddReminderSettings: bindActionCreators(addReminderSettings, dispatch),
    onGetReminderSettings: bindActionCreators(getReminderSettings, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReminderSetting);
