import React from "react";
import { connect } from "react-redux";
import { createCode, createToken, getXeroToken } from "../store/Actions";
import { Spin } from "antd";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import XeroAddAccount from "./XeroAddAccount";

class XeroAddAccountLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isExists: false,
    };
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.isExists !== nextProps.isExists.isExists) {
      this.setState({ isExists: nextProps.isExists.isExists });
    }
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <XeroAddAccount
          createToken={this.props.createToken}
          createCode={this.props.createCode}
          getXeroToken={this.props.getXeroToken}
          isExists={this.state.isExists}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isExists: state && state.xeroReducer && state.xeroReducer.isExists,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCode: bindActionCreators(createCode, dispatch),
  createToken: bindActionCreators(createToken, dispatch),
  getXeroToken: bindActionCreators(getXeroToken, dispatch),
});

XeroAddAccountLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(XeroAddAccountLayout);

export default withRouter(XeroAddAccountLayout);
