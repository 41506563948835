// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
} from "antd";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

import { UpOutlined, DownOutlined } from "@ant-design/icons";

function TextArea(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [fieldName, setFieldName] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [connectionsData, setConnectionsData] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [relation, setRelation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optionText, setOptionText] = useState("");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [froalaText, setFroalaText] = useState("");

  useEffect(() => {
    setOptions(Props.option);
    // setFieldKey(Props.field.fieldKey)
  }, [Props]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");
  //

  // const { getFieldDecorator } = Props.form;

  const submitContent = async () => {
    // Pressing ctrl + s when the editor has focus will execute this method
    // Before the editor content is submitted to the server, you can directly call editorState.toHTML () to get the HTML content
    // const htmlContent = this.state.editorState.toHTML()
    // const result = await saveEditorContent(htmlContent)
  };

  const handleEditorChange = (editorState) => {
    setValue(editorState);
  };

  return (
    <div
      class="cq-form-cont"
      style={{
        width: "100%",
        marginLeft: 0,
        marginTop: 30,
        padding: 30,
      }}
    >
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        <div
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            display: "flex",
          }}
          onClick={() => {
            Props.questionRemove(Props.questionIndex);

            Props.questionsArray.splice(Props.questionIndex, 1);
          }}
        >
          <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
        </div>
      </div>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <div
          onClick={() => {
            Props.questionArrayMove(
              Props.questionsArray,
              Props.index,
              Props.index - 1
            );
            Props.move(Props.index, Props.index - 1);
          }}
          className="section-arrow-icons"
        >
          <UpOutlined />
        </div>

        <div
          onClick={() => {
            Props.questionArrayMove(
              Props.questionsArray,
              Props.index,
              Props.index + 1
            );
            Props.move(Props.index, Props.index + 1);
          }}
          className="section-arrow-icons"
        >
          <DownOutlined />
        </div>
      </div>
      <div className="rich-text-area" style={{ width: "100%" }}>
        <Form.Item
          name={[Props.field.name, "textArea"]}
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <BraftEditor
            value={value}
            onChange={handleEditorChange}
            onSave={submitContent}
            language="en"
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default TextArea;
