import { api } from "../../services/api";
import * as types from "../Constants";

export const getAgents = () => {
  const options = {
    url: "v1/agent/All",
  };

  options.types = [types.GET_AGENTS_SUCCESS, types.GET_AGENTS_FAILURE];

  return api.get(options);
};
