import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Modal,
  message,
  Form,
  Upload,
  Card,
  Table,
  Spin,
} from "antd";
import { bindActionCreators } from "redux";
import { importPotentialClient, getPClientFormatFile } from "../store/Actions";
import { withRouter } from "react-router-dom";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { apiRefresh } from "../services/api";

const headOption = [
  {
    tabName: "Potential Clients",
    linkName: "/potential-client/potential-clients",
  },
  // {
  //   tabName: "Potential Clients Status",
  //   linkName: "/potential-client/potential-clients-status",
  // },
  {
    tabName: "Potential Employers",
    linkName: "/potential-client/potential-employer",
  },
];

class ImportPotentialClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPotentialClients: null,
      importedPotentialClients: [],
      duplicateRecords: [],
      potentialClientStatus: [],
      processingPersons: [],
      loading: false,
    };
  }

  columnsDublicateRecordsTable = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      // width: "5%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // width: "5%",
    },
  ];

  static getDerivedStateFromProps(props, state) {}

  componentDidMount() {}

  handleChange = ({ fileList }) => {
    this.setState({ fileList }, () => {});
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await this.getBase64(file.originFileObj);
    // }
    //
    // this.setState({
    //   previewImage: file.url || file.preview,
    //   previewVisible: true,
    //   previewTitle:
    //     file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    // });
  };

  getUploadFeature = (
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    uploadButton
  ) => {
    return (
      <div style={{ marginLeft: "7px" }}>
        <Form.Item colon={false} name="file">
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Upload
            showUploadList={{ showPreviewIcon: false }}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={() => false}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
          >
            {fileList && fileList.length > 0 ? null : uploadButton}
          </Upload>
          {/*<Modal*/}
          {/*  visible={previewVisible}*/}
          {/*  title={previewTitle}*/}
          {/*  footer={null}*/}
          {/*  onCancel={this.handleCancel}*/}
          {/*>*/}
          {/*  <img alt="example" style={{ width: "100%" }} src={previewImage} />*/}
          {/*</Modal>*/}
        </Form.Item>
      </div>
    );
  };

  handleSubmitClick = () => {
    this.setState({
      totalPotentialClients: null,
      importedPotentialClients: [],
      duplicateRecords: [],
      potentialClientStatus: [],
      processingPersons: [],
    });
    let formData = new FormData();
    let selectedFile =
      this.state.fileList &&
      this.state.fileList[0] &&
      this.state.fileList[0].originFileObj;
    formData.append("File", selectedFile);
    if (selectedFile) {
      this.setState({ loading: true });
      this.props
        .importPotentialClient(formData)
        .then(() => {
          let _totalPotentialClients =
            this.props && this.props.totalPotentialClients;
          let _importedPotentialClients =
            this.props && this.props.importedPotentialClients;
          let _duplicateRecords = this.props && this.props.duplicateRecords;
          let _potentialClientStatus =
            this.props && this.props.potentialClientStatus;
          let _processingPersons = this.props && this.props.processingPersons;

          this.setState(
            {
              totalPotentialClients: _totalPotentialClients,
              importedPotentialClients: _importedPotentialClients,
              duplicateRecords: _duplicateRecords,
              potentialClientStatus: _potentialClientStatus,
              processingPersons: _processingPersons,
              loading: false,
            },
            () => {
              message.success("Potential Clients imported successfully");
            }
          );
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  getSubmitButton = () => {
    return (
      <div>
        <Row>
          <Col xs={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={"save-button button-blue"}
                size={"small"}
                onClick={this.handleSubmitClick}
                disabled={!(this.state.fileList && this.state.fileList[0])}
              >
                CREATE POTENTIAL CLIENTS
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getReportView = () => {
    return (
      <div>
        <Row style={{ marginTop: "60px", marginLeft: "7px" }}>
          <Col xs={20}>
            <Card title="SUMMARY OF IMPORTED CLIENTS">
              <Row style={{ marginBottom: "6px" }}>
                <Col xs={6} style={{ borderBottom: "1px solid #f3f3f3" }}>
                  <h5>{"Total Potential Clients"}</h5>
                </Col>
                <Col xs={2} offset={1}>
                  <h5>{this.state.totalPotentialClients}</h5>
                </Col>
              </Row>
              <Row style={{ marginBottom: "6px" }}>
                <Col xs={6} style={{ borderBottom: "1px solid #f3f3f3" }}>
                  <h5>{"Imported Potential Clients"}</h5>
                </Col>
                <Col xs={2} offset={1}>
                  <h5>
                    {this.state.importedPotentialClients &&
                      this.state.importedPotentialClients.length}
                  </h5>
                </Col>
              </Row>
              <Row style={{ marginBottom: "6px" }}>
                <Col xs={6} style={{ borderBottom: "1px solid #f3f3f3" }}>
                  <h5>{"Duplicate Records"}</h5>
                </Col>
                <Col xs={2} offset={1}>
                  <h5>
                    {this.state.duplicateRecords &&
                      this.state.duplicateRecords.length}
                  </h5>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className={"duplicate-client-table-container"}>
          <Col xs={24}>
            <h3>{"DUPLICATE CLIENTS"}</h3>
          </Col>
        </Row>
        <Row style={{ marginLeft: "7px" }}>
          <Col xs={20}>
            <Table
              dataSource={
                this.state.duplicateRecords ? this.state.duplicateRecords : null
              }
              columns={this.columnsDublicateRecordsTable}
              onChange={this.paginate}
              pagination={{
                defaultPageSize: 20,
              }}
            />
          </Col>
        </Row>
        <Row className={"duplicate-client-table-container"}>
          <Col xs={20}>
            {this.state.potentialClientStatus &&
              this.state.potentialClientStatus.map((data, index) => {
                return (
                  <Row key={index}>
                    <Col xs={24}>
                      <p
                        style={{ fontSize: "13px" }}
                      >{`Potential Client with email ${data.potentialClient} loaded successfully but client status is not correct.`}</p>
                    </Col>
                  </Row>
                );
              })}
            {this.state.processingPersons &&
              this.state.processingPersons.map((data, index) => {
                return (
                  <Row key={index}>
                    <Col xs={24}>
                      <p
                        style={{ fontSize: "13px" }}
                      >{`Potential Client with email ${data.potentialClient} loaded successfully but Processing person ${data.email} client status is not correct.`}</p>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </div>
    );
  };

  onClickDownloadButton = () => {
    // window.open(
    //   "https://cs110032000b9dd1aa8.blob.core.windows.net/documentmanagement/5fe19fc9-2432-4005-aa05-05e384dde482potentialclientimport.xlsx",
    //   "_blank"
    // );
    // this.props
    //   .getPClientFormatFile()
    //   .then((response) => {
    //     let blob = new Blob([response.payload], {
    //       type: "application/octet-stream",
    //     });

    //     saveAs(blob, "PotentialClientFormat.xlsx");
    //   })
    //   .catch((e) => message.error("Unable to download, please try again!"));
    const importPotentialClient = {
      url: `v1/potentialclient/Template`,
    };
    importPotentialClient.types = [
      "IMPORT_PC_FORMAT_SUCCESS",
      "IMPORT_PC_FORMAT_FAILURE",
    ];
    apiRefresh.postWithFileRes(importPotentialClient).then((res) => {
      console.log(res);
      let blob = new Blob([res], {
        type: "application/octet-stream",
      });
      this.setState({ loading: false });
      saveAs(blob, `ImportPClientFormat.xlsx`);
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={24}>
                <PotentialHeaderTabs
                  data={headOption}
                  activePath={window.location.pathname}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "50px" }}>
              <Col xs={11} offset={1}>
                <h4>{"IMPORT POTENTIAL CLIENTS"}</h4>
              </Col>
              <Col xs={11}>
                <div style={{ float: "right" }}>
                  <Button
                    type="primary"
                    size={"small"}
                    onClick={this.onClickDownloadButton}
                    className="button-blue"
                  >
                    DOWNLOAD FORMAT
                  </Button>
                </div>
              </Col>
            </Row>
            <div style={{ display: "flex" }}>
              <div className="page-container">
                <div className="ts-container-import-clients">
                  <Form ref={this.formRef}>
                    {this.getUploadFeature(
                      previewVisible,
                      previewImage,
                      fileList,
                      previewTitle,
                      uploadButton
                    )}
                    {this.getSubmitButton()}
                    {this.state && this.state.totalPotentialClients
                      ? this.getReportView()
                      : null}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let selectedBranch =
    state && state.branchReducer && state.branchReducer.selectedBranch;

  return {
    totalPotentialClients:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.totalPotentialClients &&
      state.potentialClientReducer.totalPotentialClients,
    importedPotentialClients:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.importedPotentialClients &&
      state.potentialClientReducer.importedPotentialClients,
    duplicateRecords:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.duplicateRecords &&
      state.potentialClientReducer.duplicateRecords,
    potentialClientStatus:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientStatus &&
      state.potentialClientReducer.potentialClientStatus,
    processingPersons:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.processingPersons &&
      state.potentialClientReducer.processingPersons,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  importPotentialClient: bindActionCreators(importPotentialClient, dispatch),
  getPClientFormatFile: bindActionCreators(getPClientFormatFile, dispatch),
});

ImportPotentialClient = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPotentialClient);

export default withRouter(ImportPotentialClient);
