import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Select, DatePicker, Tag } from "antd";
import { Spin, message, Checkbox } from "antd";
import moment from "moment";
import activityData from "../../Components/ClientActivity/ActivityData";
import AddAutoReminder from "../../Components/Reminder/AddAutoReminder";

const { Option } = Select;

const options = [
  { value: "Usama", label: "Usama" },
  { value: "Mateen", label: "Mateen" },
  { value: "Chohan", label: "Chohan" },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddTasksModal = ({
  onAddDailyTasks,
  onGetDailyTasks,
  onGetAllClients,
  allClientData,
  getAllUsers,
  onSearchClient,
  onSearchPotentialClient,
  reloadFlag,
  setReload,
  handleCancel,
  onGetPermProcessingPerson,
}) => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [isPotential, setIsPotential] = useState(false);
  const [linkClient, setLinkClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [linkClientName, setLinkClientName] = useState("");
  const [connectionsList, setConnectionsList] = useState([]);

  const formRef = useRef();

  const ClientLinks = () => {
    onGetAllClients();
  };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  useEffect(() => {
    // onGetAllClients().then((res) => {
    //   let clientsData = res.payload.items.map((item, index) => {
    //     return {
    //       label: `${item.firstName} ${item.lastName}`,
    //       value: item.id,
    //     };
    //   });
    //   setClients(clientsData);
    // });
    // getAllUsers().then((res) => {
    //   let usersData = res.payload.users.map((item, index) => {
    //     return {
    //       label: item.fullName,
    //       value: item.id,
    //     };
    //   });
    //   setUsers(usersData);
    // });
    onGetPermProcessingPerson(true).then((res) => {
      let usersData = res.payload.users.map((item, index) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      setUsers(usersData);
    });
  }, []);

  const onFinish = (values) => {
    setLoading(true);

    let followers = [];
    console.log("Received values of form:", values);
    const userId = localStorage.getItem("userId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    else followers = [{ userId: userId }];
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: values.link_client
        ? values.link_client
        : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: isPotential,
      isCompleted: false,
    };
    onAddDailyTasks(data)
      .then((res) => {
        setLoading(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        // window.location.reload();
        onGetDailyTasks();
        handleCancel();
        setReload(!reloadFlag);
        let userName = localStorage.getItem("userName");
        let myData = {
          clientName: linkClientName || "",
          logMessage: "\nTask " + values.task_title + " added by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Daily Tasks/Reminders",
          invoiceId: "0",
          notClientPart: true,
          linkedClient: data.subjectId,
        };
        activityData(myData);
        if (values.select_date) {
          let tcData = {
            title: "Task Date Valid To",
            reminderDate: values.select_date
              ? moment(values.select_date).format("YYYY-MM-DDT00:00:00+00:00")
              : "1900-01-01T00:00:00+00:00",
            fullName: linkClientName || "",
            profileId: values.link_client
              ? values.link_client
              : "00000000-0000-0000-0000-000000000000",
          };
          AddAutoReminder(tcData);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Error in Adding Task!");
      });
  };

  const onChangeHandler = (value) => {
    setLinkClient(value);
    var findClient = clientList.find((obj) => obj.id === value);

    if (findClient) {
      setLinkClientName(findClient.firstName + " " + findClient.lastName);
    }
  };

  const onSearch = (val) => {
    console.log("search:", val);
    if (val.length > 2) {
      setConnectionsList([]);
      if (isPotential) {
        onSearchPotentialClient(val).then((res) => {
          setConnectionsList([]);
          if (
            res.payload.potentialClients &&
            res.payload.potentialClients.length > 0
          ) {
            setClientList(res.payload.potentialClients);
            for (let i = 0; i < res.payload.potentialClients.length; i++) {
              setConnectionsList((prevState) => [
                ...prevState,
                <Option key={res.payload.potentialClients[i].id}>
                  {res.payload.potentialClients[i].label}
                </Option>,
              ]);
            }
          }
        });
      } else {
        onSearchClient(val).then((res) => {
          setConnectionsList([]);
          if (res.payload.clients && res.payload.clients.length > 0) {
            setClientList(res.payload.clients);
            for (let i = 0; i < res.payload.clients.length; i++) {
              setConnectionsList((prevState) => [
                ...prevState,
                <Option key={res.payload.clients[i].id}>
                  {res.payload.clients[i].firstName +
                    " " +
                    res.payload.clients[i].lastName}
                </Option>,
              ]);
            }
          }
        });
      }
    } else {
      setConnectionsList([]);
    }
  };

  const onChangePotential = (e) => {
    setIsPotential(e.target.checked);
  };

  console.log("allClientDataallClientDataallClientData42", allClientData);
  return (
    <Spin size="large" spinning={loading}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        ref={formRef}
        {...layout}
      >
        <div className="add-tasks">
          <div>
            <span className="cv-bold-text">Select Client</span>

            <div className="mdtr-modal-gray-cont">
              <div className="modal-parts">
                <div className="mdtr-checkbox-cont">
                  <Checkbox
                    checked={isPotential}
                    onChange={onChangePotential}
                    className="sus-checkbox"
                  />
                  <span className="cv-normal-text">Potential Client</span>
                </div>
                <Form.Item
                  className="add-reminder-form form-parts"
                  label="Link Client"
                  name="link_client"
                  required={false}
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={onChangeHandler}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={linkClient}
                  >
                    {connectionsList}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div>
              <span style={{ marginTop: 39 }} className="cv-bold-text">
                Add Task
              </span>
            </div>
            <div className="mdtr-modal-gray-cont">
              <div className="modal-parts">
                <Form.Item
                  className="form-parts"
                  label="Task Title"
                  name="task_title"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className="form-parts"
                  label="Task Description"
                  name="task_description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea rows={6} maxLength={300} showCount />
                </Form.Item>
                <Form.Item
                  className="form-parts"
                  label="Select Date"
                  name="select_date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                  className="form-parts multi-select-option"
                  label="Add Followers"
                  name="add_followers"
                  // rules={[{ required: true, message: "Please Add Followers!" }]}
                >
                  <Select
                    mode="multiple"
                    showArrow
                    tagRender={tagRender}
                    style={{ width: "100%" }}
                    options={users}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="button-blue">
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default AddTasksModal;
