import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Redirect } from "react-router";
// import Login from "../Login/Login";
import {
  User,
  UserDetail,
  TeamMember,
  BranchSetting,
  VisaStatus,
  VisaType,
  PotentialClientStatus,
  CustomQuestionnaire,
  QuestionnaireList,
  PreviewQuestionnaire,
  Profile,
  Partner,
  Qualification,
  FileNotes,
  SubmitQuestionnaire,
  UpdateQuestionnaire,
  ClientQuestionnaire,
  LinkQuestionnaire,
  VisaFlow,
  Admission,
  Accounts,
  ClientList,
  Employer,
  JobHistory,
  FamilyMember,
  Activities,
  Reports,
  EmployerReports,
  AdminLogin,
  Companies,
  BranchDetail,
  PotentialClientReport,
  VisaReport,
  Mail,
  PCleintCustomQuestionnaire,
  CalendarSync,
  OtherInfo,
  UpdateMappProperty,
  AddAgent,
  Deals,
  VisaBranchCountry,
  TimeTrackingMain,
  LoginImage,
  CompanyUsersList,
  TimeTrackingReport,
  Agents,
} from "../Containers";
import AddNewClient from "../Clients/AddNewClient";
import AllClients from "./../Containers/AllClients";
import ClientDocument from "./../Containers/ClientDocuments";
import ClientAccount from "../Clients/ClientAccount";
import Inquiry from "../PotentialClient/Inquiry";
import PotentialEmployer from "./../PotentialClient/PotentialEmployer/PotentialEmployer";
import PotenialClientSummary from "../Dashboard/PotenialClient";
import AccountSetting from "../AccountSetting/AccountSetting.js";
// import VisaReport from "../Reports/VisaReport";
import TimeTracking from "../Reports/TimeTracking";
// import EmployerReport from "../Reports/EmployerReport";
import EmployerManagement from "../Containers/EmployerManagement";
import WebAssessment from "../PotentialClient/WebAssessment";
import WebInquiryDetailed from "../PotentialClient/WebInquiryDetailed";
import WebInquiryLinkDetailed from "../PotentialClient/WebInquiryLinkDetailed";
import WebInquiryLinkBasic from "../PotentialClient/WebInquiryLinkBasic";
import WebAssessmentLink from "../PotentialClient/WebAssessmentLink";
import BasicAssessmentLink from "../PotentialClient/BasicAssessmentLink";
import DailyTaskReminders from "./../Containers/MyDailyTasksReminder";
import CompletedTasks from "../MyDailyTasksReminders/CompletedTasks";
import CommissionReminder from "../MyDailyTasksReminders/CommissionReminders";
import WorkType from "../TimeTracking/WorkType";
import VisaTypePrice from "../TimeTracking/VisaTypePrice";
import ServiceType from "../TimeTracking/ServiceType";
import NzFormList from "../VisaForm/NZFormList";
import AusFormList from "../VisaForm/AusFormList";
import TechnicalSupport from "../TechnicalSupport/TechnicalSupport";
import Meetings from "../Meetings/Meetings";
import ClientEmail from "./../Containers/ClientEmail";
import StudentList from "../SchoolManagement/StudentList";
import Schools from "../SchoolManagement/Schools";
import SchoolManagementLayout from "../SchoolManagement/SchoolManagementLayout";
import NewInvoice from "../SchoolManagement/NewInvoice";
import SchoolAccounts from "../SchoolManagement/Invoices";
import ClientTags from "./../Containers/AccountSettings";
import DocumentTypes from "./../Containers/DocumentTypes";
import EzmCommonDocs from "./../Containers/EzmCommonDocs";
import ReminderSetting from "./../Containers/ReminderSetting";
import SettingTimeTracking from "./../Containers/TimeTracking";
import VisaUpdateNotification from "./../Containers/VisaUpdateNotification";
import DocumentChecklist from "./../Containers/DocumentChecklist";
import SettingFileNotes from "./../Containers/SettingFileNotes";
import ContractSettings from "./../Containers/ContractSettings";
import LetterTemplates from "./../Containers/LetterTemplates";
import MailChamp from "./../Containers/MailChimp";
import AgentPortalDocuments from "./../Containers/DocumentsSettings";
import CheckMyVisaDocs from "./../Containers/CheckMyVisaDocs";
import EmailContent from "./../Containers/EmailContent";
import QuestionnaireSettings from "./../Containers/QuestionnaireSettings";
import DefaultFontSettings from "./../Containers/DefaultFontSettings";
import TimeZoneSettings from "./../Containers/TimeZoneSettings";
import SignatureSettings from "./../Containers/SignatureSettings";
import DailyEmailSettings from "./../Containers/DailyEmailSettings";
import DocumentView from "./../Containers/DocumentView";
import ImapSettings from "./../Containers/ImapSettings";
import ContactUs from "./../Containers/EmployerCheckList";
import AddNewPotentialClient from "../PotentialClient/AddNewPotentialClient";
import PotentialClient from "../PotentialClient/PotentialClient";
import ImportPotentialClient from "../PotentialClient/ImportPotentialClient";
import PotentialClientsStatus from "../PotentialClient/PotentialClientsStatus";
import SignUpForm from "../Components/SignUpForm";
import ChatLists from "../Chats/ChatList";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import {
  DocumentSync,
  CaseManagement,
  ChecklistCategory,
  AddChecklist,
  Chats,
  ClientSummary,
  DashboardPotentialClientReport,
  DashboardReminders,
  DashboardEmployers,
  DashboardStudent,
  DashboardBi,
  OutlookIntegration,
  Supervisor,
  SupervisorBatchesStudents,
  PrintCase,
  GmailIntegration,
} from "./../Containers";

import Xero from "../Xero/Xero";
import XeroModule from "../Xero/XeroAddAccount";
import XeroAddAccountLayout from "../Xero/XeroAddAccountLayout";
import Invoices from "../Xero/Invoices";
import AddInvoiceToXero from "../Xero/AddInvoiceToXero";
import AddSchoolInvoiceToXero from "../Xero/AddSchoolInvoiceToXero";
import AddPaymentsToXero from "../Xero/AddPaymentsToXero";
import Listing from "../Accounts/Templates/Listing";
import AddTemplateInvoice from "../Clients/AddTemplateInvoice";
import ComingSoon from "../Components/ComingSoon";
import Videos from "../Videos";
import PurchaseSubscription from "../Common/PurchaseMessage.js";
import UploadStudentData from "../Supervisor/Students/UploadStudentsData.js";
import HubspotIntegration from "../Containers/HubspotIntegration.js";
// import GmailIntegration from "../AccountSetting/UserLevelSettings/ImapSettings/GmailIntegration.js";
export default function Routes() {
  const location = useLocation();

  return (
    <Switch>
      {/*<Route path="/login" component={User} />*/}
      <Route key={location.key} path="/dashboardBI" component={DashboardBi} />
      <Route key={location.key} path="/all-clients" component={AllClients} />
      <Route key={location.key} path="/visa-flow" component={VisaFlow} />
      <Route key={location.key} path="/documents" component={ClientDocument} />
      <Route key={location.key} path="/employer" component={Employer} />
      <Route key={location.key} path="/job-history" component={JobHistory} />
      <Route
        key={location.key}
        path="/qualification"
        component={Qualification}
      />
      <Route
        key={location.key}
        path="/client-account"
        component={ClientAccount}
      />
      <Route key={location.key} path="/potential-client" component={Inquiry} />
      <Route
        key={location.key}
        path="/import-potential-client"
        component={ImportPotentialClient}
      />
      <Route
        key={location.key}
        path="/add-potential-client"
        component={AddNewPotentialClient}
      />
      <Route
        key={location.key}
        path="/update-potential-client"
        component={AddNewPotentialClient}
      />
      {/*<Route path="/permanent-client" component={AddNewClient} />*/}
      <Route
        exact
        key={location.key}
        path="/potential-client/potential-clients"
        component={PotentialClient}
      />
      <Route
        exact
        key={location.key}
        path="/potential-client/potential-clients-status"
        component={PotentialClientsStatus}
      />
      <Route
        exact
        key={location.key}
        path="/potential-client/potential-employer"
        component={PotentialEmployer}
      />
      <Route
        key={location.key}
        exact
        path="/add-new-client"
        component={AddNewClient}
      />
      <Route key={location.key} path="/file-notes" component={FileNotes} />
      <Route key={location.key} path="/activities" component={Activities} />
      <Route key={location.key} path="/profile" component={Profile} />
      <Route key={location.key} path="/partner-profile" component={Partner} />
      <Route
        key={location.key}
        exact
        path="/dashboard"
        component={ClientSummary}
      />
      <Route
        key={location.key}
        exact
        path="/dashboard/potentialclient"
        component={DashboardPotentialClientReport}
      />
      <Route
        key={location.key}
        exact
        path="/dashboard/reminders"
        component={DashboardReminders}
      />
      <Route
        key={location.key}
        exact
        path="/dashboard/employers"
        component={DashboardEmployers}
      />
      <Route
        key={location.key}
        exact
        path="/dashboard/student"
        component={DashboardStudent}
      />
      <Route
        key={location.key}
        path="/Case-Management-All"
        component={CaseManagement}
      />
      <Route
        key={location.key}
        path="/Case-management"
        component={CaseManagement}
      />
      <Route key={location.key} path="/AddChecklist" component={AddChecklist} />
      <Route
        key={location.key}
        path="/Checklists"
        component={ChecklistCategory}
      />
      <Route
        // key={location.key}
        path="/super-user-setting"
        component={UserDetail}
      />
      <Route key={location.key} path="/team-member" component={TeamMember} />
      <Route key={location.key} path="/add-agent" component={AddAgent} />
      <Route
        key={location.key}
        path="/branch-settings"
        component={BranchSetting}
      />
      <Route key={location.key} path="/visa-type" component={VisaType} />
      <Route
        key={location.key}
        path="/visa-branch-country"
        component={VisaBranchCountry}
      />
      <Route key={location.key} path="/visa-statuses" component={VisaStatus} />
      <Route
        key={location.key}
        path="/potential-client-status"
        component={PotentialClientStatus}
      />
      <Route
        key={location.key}
        exact
        path="/account-setting"
        component={ImapSettings}
      />
      <Route
        key={location.key}
        exact
        path="/account-setting-gmail"
        component={GmailIntegration}
      />
      <Route
        key={location.key}
        exact
        path="/account-setting-hubspot"
        component={HubspotIntegration}
      />
      <Route
        key={location.key}
        path="/account-settings"
        component={AccountSetting}
      />
      <Route key={location.key} path="/reports" component={Reports} />
      <Route
        key={location.key}
        path="/potential-clients-report"
        component={PotentialClientReport}
      />
      <Route key={location.key} path="/visa-report" component={VisaReport} />
      <Route
        key={location.key}
        path="/time-tracking"
        component={TimeTrackingMain}
      />
      <Route
        key={location.key}
        path="/time-tracking-report"
        component={TimeTrackingReport}
      />
      <Route
        key={location.key}
        path="/employer-reports"
        component={EmployerReports}
      />
      <Route key={location.key} path="/agents" component={Agents} />
      <Route key={location.key} path="/agent-clients" component={ComingSoon} />
      <Route key={location.key} path="/video-tutorials" component={Videos} />
      <Route key={location.key} path="/useful-links" component={ComingSoon} />
      <Route
        key={location.key}
        path="/employer-management"
        component={EmployerManagement}
      />
      <Route
        key={location.key}
        path="/web-assessment"
        component={WebAssessment}
      />
      <Route
        key={location.key}
        path="/web-inquiry-detailed"
        component={WebInquiryDetailed}
      />
      <Route
        key={location.key}
        path="/web-inquiry-link-detailed"
        component={WebInquiryLinkDetailed}
      />
      <Route
        key={location.key}
        path="/web-inquiry-link-basic"
        component={WebInquiryLinkBasic}
      />
      <Route
        key={location.key}
        path="/web-assessment-link"
        component={WebAssessmentLink}
      />
      <Route
        key={location.key}
        path="/basic-assessment-link"
        component={BasicAssessmentLink}
      />
      <Route
        key={location.key}
        path="/tasks-and-reminders"
        component={DailyTaskReminders}
      />
      <Route
        key={location.key}
        path="/tasks-and-reminders/tasks-to-do"
        component={DailyTaskReminders}
      />
      <Route
        key={location.key}
        path="/tasks-and-reminders/completed-tasks"
        component={CompletedTasks}
      />
      <Route
        key={location.key}
        path="/tasks-and-reminders/commission-reminders"
        component={CommissionReminder}
      />
      <Route key={location.key} path="/work-type" component={WorkType} />
      <Route
        key={location.key}
        path="/visa-type-price"
        component={VisaTypePrice}
      />
      <Route key={location.key} path="/service-type" component={ServiceType} />
      <Route key={location.key} path="/nz-form-list" component={NzFormList} />
      <Route key={location.key} path="/aus-form-list" component={AusFormList} />
      <Route
        key={location.key}
        path="/technical-support"
        component={TechnicalSupport}
      />
      <Route key={location.key} path="/meetings" component={ComingSoon} />
      <Route key={location.key} path="/admission" component={Admission} />
      <Route key={location.key} path="/client-email" component={ClientEmail} />
      <Route
        key={location.key}
        path="/custom-questionnaire"
        component={CustomQuestionnaire}
      />
      <Route
        key={location.key}
        path="/questionnaire"
        component={QuestionnaireList}
      />
      <Route
        key={location.key}
        path="/preview-questionnaire"
        component={PreviewQuestionnaire}
      />
      <Route
        key={location.key}
        path="/school-management"
        component={SchoolManagementLayout}
      />
      <Route
        key={location.key}
        path="/school-management/student-list"
        component={StudentList}
      />
      <Route
        key={location.key}
        path="/school-management/schools"
        component={Schools}
      />
      <Route
        key={location.key}
        path="/school-management/new-invoice"
        component={NewInvoice}
      />
      <Route
        key={location.key}
        path="/school-management/accounts"
        component={SchoolAccounts}
      />
      <Route key={location.key} path="/client-tags" component={ClientTags} />
      <Route
        key={location.key}
        path="/document-types"
        component={DocumentTypes}
      />
      <Route
        key={location.key}
        path="/ezm-common-docs"
        component={EzmCommonDocs}
      />
      <Route
        key={location.key}
        path="/reminder-setting"
        component={ReminderSetting}
      />
      <Route
        key={location.key}
        path="/time-tracking-setting"
        component={SettingTimeTracking}
      />
      <Route
        key={location.key}
        path="/visa-update-notificaton"
        component={VisaUpdateNotification}
      />
      <Route
        key={location.key}
        path="/document-checklist"
        component={DocumentChecklist}
      />
      <Route
        key={location.key}
        path="/file-notes-settings"
        component={SettingFileNotes}
      />
      <Route
        key={location.key}
        path="/contract-settings"
        component={ContractSettings}
      />
      <Route
        key={location.key}
        path="/letter-templates-settings"
        component={LetterTemplates}
      />
      <Route
        key={location.key}
        path="/mail-chimp-settings"
        component={MailChamp}
      />
      <Route
        key={location.key}
        path="/agent-portal-document-settings"
        component={AgentPortalDocuments}
      />
      <Route
        key={location.key}
        path="/check-my-visa-settings"
        component={CheckMyVisaDocs}
      />
      <Route
        key={location.key}
        path="/email-content"
        component={EmailContent}
      />
      <Route
        key={location.key}
        path="/questionnaire-settings"
        component={QuestionnaireSettings}
      />
      <Route
        key={location.key}
        path="/default-font-settings"
        component={DefaultFontSettings}
      />
      <Route
        key={location.key}
        path="/time-zone-settings"
        component={TimeZoneSettings}
      />
      <Route
        key={location.key}
        path="/signature-settings"
        component={SignatureSettings}
      />
      <Route
        key={location.key}
        path="/daily-email-settings"
        component={DailyEmailSettings}
      />
      <Route key={location.key} path="/deals" component={Deals} />
      {/* <Route path="/profile-client-tags" component={ProfileSidebar} /> */}
      <Route
        // key={location.key}
        path="/questionnaire-form"
        component={SubmitQuestionnaire}
      />
      <Route
        // key={location.key}
        path="/update-questionnaire"
        component={UpdateQuestionnaire}
      />
      <Route
        // key={location.key}
        path="/update-mapp-property"
        component={UpdateMappProperty}
      />
      <Route
        key={location.key}
        path="/document-view-settings"
        component={DocumentView}
      />
      <Route
        key={location.key}
        path="/imap-settings"
        component={ImapSettings}
      />
      <Route
        key={location.key}
        path="/document-sync"
        component={DocumentSync}
      />
      <Route
        key={location.key}
        path="/outlook-integration"
        component={OutlookIntegration}
      />
      <Route
        key={location.key}
        path="/calender-settings"
        component={CalendarSync}
      />
      <Route
        key={location.key}
        path="/client-questionnaire"
        component={(props) => <ClientQuestionnaire {...props} />}
      />
      {/* <Route path="/c_q" component={LinkQuestionnaire} /> */}
      <Route key={location.key} path="/accounts" component={Accounts} />
      <Route
        key={location.key}
        path="/accounts/templates"
        component={Listing}
      />
      <Route
        key={location.key}
        path="/accounts/client-list"
        component={ClientList}
      />
      <Route
        key={location.key}
        path="/document-link-client/:id"
        component={ContactUs}
      />
      <Route
        key={location.key}
        path="/client-family-member"
        component={FamilyMember}
      />
      <Route key={location.key} path="/chats" component={ComingSoon} />
      <Route key={location.key} path="/companies" component={Companies} />
      <Route
        key={location.key}
        path="/company-user"
        component={CompanyUsersList}
      />
      <Route key={location.key} path="/login-image" component={LoginImage} />
      <Route
        key={location.key}
        path="/Branch-Detail"
        component={BranchDetail}
      />
      <Route key={location.key} exact path="/xero-sync" component={Xero} />
      <Route
        key={location.key}
        exact
        path="/xero-sync-add-account"
        component={XeroAddAccountLayout}
      />
      <Route
        key={location.key}
        exact
        path="/xero-sync-invoices"
        component={Invoices}
      />
      <Route
        key={location.key}
        exact
        path="/xero-sync-invoice"
        component={AddInvoiceToXero}
      />
      <Route
        key={location.key}
        exact
        path="/xero-sync-invoice-payments"
        component={AddPaymentsToXero}
      />
      <Route
        exact
        key={location.key}
        path="/xero-sync-school-invoice"
        component={AddSchoolInvoiceToXero}
      />
      <Route path="/email" component={Mail} />
      <Route
        key={location.key}
        exact
        path="/agreement"
        render={() =>
          (window.location.href = "../../AgreementBuilder/index.html")
        }
      />
      <Route
        exact
        key={location.key}
        path="/potential-client-questionnaire"
        component={PCleintCustomQuestionnaire}
      />
      <Route
        key={location.key}
        exact
        path="/client-other-info"
        component={OtherInfo}
      />
      /* Supervisor routes */
      <Route
        key={location.key}
        exact
        path="/supervisor/batches"
        component={Supervisor}
      />
      <Route
        key={location.key}
        exact
        path="/supervisor/batches/students"
        component={SupervisorBatchesStudents}
      />
      <Route
        key={location.key}
        exact
        path="/supervisor/batches/upload-data"
        component={UploadStudentData}
      />
      <Route exact path="/print-case" component={PrintCase} />
      <Route
        key={location.key}
        exact
        path="/not-allowed"
        component={PurchaseSubscription}
      />
      {/* <Route exact path="/Home/PrivacyPolicy" component={PrivacyPolicy} /> */}
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      {/* <Route key={location.key} component={Profile} /> */}
    </Switch>
  );
}
