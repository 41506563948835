import React, { Fragment } from "react";
import { Table } from "antd";
import { Images } from "../../Themes";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ClientSourceTable from "./ClientSourceTable";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text, record) => {
      return <span style={{ cursor: "pointer" }}>{text}</span>;
    },

    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value, record) => record.name.indexOf(value) === 0,
    // sorter: (a, b) => a.name.length - b.name.length,
    // sortDirections: ["descend"],
  },
  {
    title: "Clients",
    dataIndex: "totalClient",
    key: "totalClient",
    align: "center",
  },
];
// const ComponentToPrint = ({ clientSourceReportRes, displayText }) => {
export class ComponentToPrint extends React.PureComponent {
  render() {
    const {
      clientSourceReportRes,
      displayText,
      onClientSourceReport,
      requestData,
      reportsCount,
    } = this.props;
    return (
      <Fragment>
        <div className="rep-print-row-cont">
          <div />
          <div>
            <div className="rep-print-icon-cont">
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <img
                      style={{ cursor: "pointer" }}
                      src={Images.printNBlue}
                      className="rep-print-icon"
                    />
                  );
                }}
                content={() => this.clientSourceRef}
              />
            </div>
          </div>
        </div>
        <div className="report-table margin-top-34 table-head-clr">
          {/* <Spin size="large" spinning={loading}> */}
          <ClientSourceTable
            clientSourceRes={clientSourceReportRes}
            ref={(el) => (this.clientSourceRef = el)}
            displayText={displayText}
            onClientSourceReport={onClientSourceReport}
            requestData={requestData}
            reportsCount={reportsCount}
          />

          {/* </Spin> */}
        </div>
      </Fragment>
    );
  }
}
export default ComponentToPrint;
