import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PCleintCustomQuestionnaire from "../PotentialClient/CustomQuestionnaire";

// import { userLogin, getUserDetail } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    // userData: state.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onUserLogin: bindActionCreators(userLogin, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PCleintCustomQuestionnaire);
