// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
} from "antd";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

import { UpOutlined, DownOutlined } from "@ant-design/icons";
import Editor from "./Editor";

function UpdateTextArea(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [fieldName, setFieldName] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [connectionsData, setConnectionsData] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [relation, setRelation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optionText, setOptionText] = useState();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [froalaText, setFroalaText] = useState("");
  const [content, setContent] = useState(
    Props.questionData ? Props.questionData.question : ""
  );

  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(
      Props.questionData ? Props.questionData.question : ""
    )
  );
  const [outputHTML, setOutputHTML] = useState("<p></p>");
  const [isLivinig, setIsLivinig] = useState(true);

  useEffect(() => {
    setOptions(Props.option);

    // setFieldKey(Props.field.fieldKey)
  }, [Props]);
  // ;

  const setEditorContentAsync = () => {
    isLivinig && setEditorState();
    BraftEditor.createEditorState(Props.questionData.question);
  };

  useEffect(() => {
    setIsLivinig(isLivinig);
    Props.questionData && setTimeout(setEditorContentAsync, 3000);

    return () => {
      setIsLivinig(false);
    };
  }, [Props.questionData, isLivinig, setEditorContentAsync]);

  const handleChange = (editorState) => {
    setEditorState(editorState);
    setOutputHTML(editorState.toHTML());
  };

  // ;

  // const { getFieldDecorator } = Props.form;

  const submitContent = async () => {
    // Pressing ctrl + s when the editor has focus will execute this method
    // Before the editor content is submitted to the server, you can directly call editorState.toHTML () to get the HTML content
    // const htmlContent = this.state.editorState.toHTML()
    // const result = await saveEditorContent(htmlContent)
  };

  const changeContent = (val) => {
    setContent(val);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFields((error, values) => {
      if (!error) {
        const submitData = {
          title: values.title,
          content: values.content.toRAW(), // or values.content.toHTML()
        };
        console.log(submitData);
      }
    });
  };

  const controls = [
    "bold",
    "italic",
    "underline",
    "text-color",
    "separator",
    "link",
    "separator",
    "media",
  ];

  return (
    <div
      class="cq-form-cont"
      style={{
        width: "100%",
        marginLeft: 0,
        marginTop: 30,
        padding: 30,
      }}
    >
      <div
        style={{
          cursor: "pointer",
          justifyContent: "flex-end",
          display: "flex",
        }}
        onClick={() => {
          Props.questionRemove(Props.questionIndex);
          Props.questionsArray.splice(Props.questionIndex, 1);
        }}
      >
        <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
      </div>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <div
          onClick={() => {
            Props.questionArrayMove(
              Props.questionsArray,
              Props.index,
              Props.index - 1
            );
            Props.move(Props.index, Props.index - 1);
          }}
          className="section-arrow-icons"
        >
          <UpOutlined />
        </div>

        <div
          onClick={() => {
            Props.questionArrayMove(
              Props.questionsArray,
              Props.index,
              Props.index + 1
            );

            Props.move(Props.index, Props.index + 1);
          }}
          className="section-arrow-icons"
        >
          <DownOutlined />
        </div>
      </div>
      {/* <div className="rich-text-area">
        <Form.Item name={[Props.field.name, "value"]}>
          <BraftEditor
            value={value}
            onChange={handleEditorChange}
            onSave={submitContent}
          />
        </Form.Item>
      </div> */}
      {Props.questionData ? (
        <Form.Item
          name={[Props.field.name, "question"]}
          fieldKey={[Props.field.fieldKey, "question"]}
          rules={[
            {
              required: false,
              message: "Please enter text here!",
            },
          ]}
        >
          <Editor width={"100%"} onChange={changeContent} value={content} />
        </Form.Item>
      ) : (
        <Form.Item
          name={[Props.field.name, "question"]}
          fieldKey={[Props.field.fieldKey, "question"]}
          rules={[
            {
              required: true,
              message: "Please enter text here!",
            },
          ]}
        >
          <Editor width={"100%"} onChange={changeContent} value={content} />
          {/* <BraftEditor
            value={editorState}
            onChange={handleChange}
            language="en"
          /> */}
        </Form.Item>
      )}
    </div>
  );
}

export default UpdateTextArea;
