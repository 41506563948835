import React, { Component } from "react";
import {
  Router,
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import history from "./services/history";
import Routes from "./routes";
import { connect, Provider } from "react-redux";
import { initStore } from "./store";
import jwt_decode from "jwt-decode";
import {
  User,
  Theme,
  AdminLogin,
  LinkQuestionnaire,
  ForgotPassword,
  SupervisorLogin,
} from "./Containers";
import queryString from "query-string";
import SignUp from "./Containers/SignUp";
import SignUpForm from "./Containers/SignUpForm";
import "./firebase";
// import AdminLogin from "./Containers/AdminLogin";
import { PowerInputSharp } from "@material-ui/icons";
import { Spin } from "antd";
import { bindActionCreators } from "redux";
import { getXeroToken } from "./store/Actions";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import { message } from "antd";
import { messageConfig } from "./config";
import getUserBranchPermissions from "./Components/getUserBranchPermissions";
import moment from "moment";
import TermsAndConditions from "./PrivacyPolicy/TermsAndConditions";
const { top, duration } = messageConfig;
var jwtDecoded = null;

let token = localStorage.getItem("token");
let admintoken = localStorage.getItem("admintoken");

if (token && token != "undefined") {
  jwtDecoded = jwt_decode(token);
}

class App extends Component {
  constructor(props) {
    message.config({
      top: top,
      duration: duration,
    });
    super(props);
    this.state = {
      auth: token,
      adminauth: admintoken,
    };
  }

  componentWillMount() {
    // this.props.getXeroToken();
    if (localStorage.getItem("selectedBranchId")) {
      if (localStorage.getItem("userBranchPermissions")) {
        if (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userBranchPermissions")))
            )
          ).branchPermission.find((x) => x.name.toLowerCase() == "freetrial")
            .status
        ) {
          if (
            moment(new Date()) >
            moment(
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userBranchPermissions"))
                  )
                )
              ).freeTrileEndDate
            )
          ) {
            localStorage.clear();
            window.location.reload();
          }
        }
      } else if (localStorage.getItem("isSupervisor") === "True") {
      } else {
        localStorage.clear();
        window.location.reload();
      }
      if (localStorage.getItem("userSystemPermissions")) {
      } else if (localStorage.getItem("userId")) {
        getUserBranchPermissions();
      }
    } else if (localStorage.getItem("token")) {
      var jwtDecoded = jwt_decode(localStorage.getItem("token"));
      // localStorage.setItem("userId", jwtDecoded.sub);
      // localStorage.setItem("userEmail", jwtDecoded.email);
      // localStorage.setItem("userOwner", jwtDecoded.IsOwner);
      localStorage.setItem("selectedBranchId", jwtDecoded.BranchId);
      // localStorage.setItem("companyId", jwtDecoded.Com);
      // localStorage.setItem("userName", jwtDecoded.FullName);
      if (!jwtDecoded.BranchId) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  getThemeLayout = () => {
    var isSupervisor = localStorage.getItem("isSupervisor") === "True";

    let path = "";

    if (history.location.pathname === "/user-signup") {
      path = "/user-signup";
      window.history.pushState({ urlPath: "/user-signup" }, "", "/user-signup");
      if (localStorage.getItem("token") || localStorage.getItem("admintoken")) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    } else if (
      isSupervisor ||
      history.location.pathname === "/login/supervisor"
    ) {
      if (history.location.pathname === "/supervisor/batches/students") {
        path = "/supervisor/batches/students";
        window.history.pushState(
          { urlPath: "/supervisor/batches/students" },
          "",
          "/supervisor/batches/students"
        );
      } else {
        path = "/supervisor/batches";
        window.history.pushState(
          { urlPath: "/supervisor/batches" },
          "",
          "/supervisor/batches"
        );
      }
    } else if (
      history.location.pathname === "/" ||
      history.location.pathname === "/login" ||
      history.location.pathname === "/login/"
    ) {
      if (localStorage.getItem("isAgent") === "true") {
        path = "/potential-client/potential-clients";
        window.history.pushState(
          { urlPath: "/potential-client/potential-clients" },
          "",
          "/potential-client/potential-clients"
        );
      } else {
        path = "/dashboardBI";
        window.history.pushState(
          { urlPath: "/dashboardBI" },
          "",
          "/dashboardBI"
        );
      }
    } else if (
      history.location.pathname === "/admin-login" &&
      this.state.adminauth
    ) {
      window.history.pushState({ urlPath: "/companies" }, "", "/companies");
      path = "/companies";
      window.location.reload();
    } else {
      if (this.state.adminauth) {
        path = "/companies";
      } else {
        path = history.location.pathname;
      }
    }
    return this.state.auth || this.state.adminauth ? (
      <>
        {localStorage.getItem("userSystemPermissions") ||
        localStorage.getItem("isSupervisor") === "True"
          ? // <Redirect to={path} />
            null
          : // setTimeout(() => {
            //   this.getThemeLayout();
            // }, 500)
            null}
      </>
    ) : (
      <Redirect
        to={
          history.location.pathname == "/admin-login"
            ? "/admin-login"
            : history.location.pathname == "/c_q"
            ? "/c_q"
            : history.location.pathname == "/Home/PrivacyPolicy"
            ? "/Home/PrivacyPolicy"
            : history.location.pathname == "/user-signup"
            ? "/user-signup"
            : history.location.pathname.indexOf("forgot-password") > -1
            ? history.location.pathname
            : history.location.pathname == "/login/supervisor"
            ? "/login/supervisor"
            : "/login"
        }
      />
    );
  };
  getHomePage = () => {
    let jsx = null;
    if (
      this.state.auth ||
      this.state.adminauth
      // history.location.pathname !== "/admin-login"
    ) {
      jsx = <Route key={0} path="/" component={Theme} />;
    } else {
      // jsx = (
      //   <Route
      //     path={
      //       history.location.pathname == "/admin-login"
      //         ? "/admin-login"
      //         : "/login"
      //     }
      //     component={
      //       history.location.pathname === "/admin-login"
      //         ? AdminLogin
      //         : User
      //     }
      //   />
      // );
    }
    return jsx;
  };

  getToken = (token) => {
    this.setState({ auth: token });
  };

  getAdminToken = (admintoken) => {
    this.setState({ adminauth: admintoken });
  };

  authLink = () => {
    let token = localStorage.getItem("token");

    const { exp } = jwtDecoded(token);
    const expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      // set LocalStorage here based on response;
    }
    return this.state.auth ? <Redirect to={"/login"} /> : "";
  };

  checkXeroValidations = () => {
    if (
      this.state.auth &&
      history.location.pathname === "/xero-sync-add-account"
    ) {
      // this.props.history.push("/xero-sync")
      return <Redirect to={"/xero-sync"} />;
    } else if (this.state.auth && history.location.pathname === "/xero-sync") {
      return <Redirect to={"/xero-sync"} />;
    } else if (
      this.state.auth &&
      history.location.pathname === "/xero-sync-invoices"
    ) {
      return <Redirect to={"/xero-sync"} />;
    } else {
    }
  };

  render() {
    return (
      <Provider store={initStore()}>
        <BrowserRouter>
          <Switch>
            {history.location && (
              <>
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <User {...props} getToken={this.getToken} />
                  )}
                />
                <Route
                  exact
                  path="/forgot-password/:token"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/admin-login"
                  render={(props) => (
                    <AdminLogin {...props} getAdminToken={this.getAdminToken} />
                  )}
                />
                <Route
                  exact
                  path="/login/supervisor"
                  component={SupervisorLogin}
                />
                <Route
                  exact
                  path="/c_q"
                  render={(props) => <LinkQuestionnaire {...props} />}
                />
                <Route
                  exact
                  path="/Home/PrivacyPolicy"
                  render={(props) => <PrivacyPolicy {...props} />}
                />
                <Route
                  exact
                  path="/terms-and-conditions"
                  render={(props) => <TermsAndConditions {...props} />}
                />
                <Route path="/user-signup" component={SignUpForm} />
                {/* <Redirect to={"/user-signup"} /> */}

                {history.location.pathname == "/c_q"
                  ? null
                  : history.location.pathname == "/Home/PrivacyPolicy"
                  ? null
                  : this.getHomePage()}

                {history.location.pathname == "/c_q"
                  ? null
                  : history.location.pathname == "/Home/PrivacyPolicy"
                  ? null
                  : this.getThemeLayout()}
                {history.location.pathname != "/c_q"
                  ? null
                  : history.location.pathname == "/Home/PrivacyPolicy"
                  ? null
                  : this.checkXeroValidations()}
              </>
            )}
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userData: state.userReducer && state.userReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getXeroToken: bindActionCreators(getXeroToken, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
