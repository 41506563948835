import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const potentialClientReducer = (state = {}, action) => {
  switch (action.type) {
    case types.POTENTIAL_CLIENT_STATUS_SUCCESS:
      return { ...state, potentialClientData: action.payload };
    case types.POTENTIAL_CLIENT_STATUS_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_POTENTIAL_CLIENT_STATUS_SUCCESS:
      return { ...state, addPotentialClientSuccess: action.payload };
    case types.ADD_POTENTIAL_CLIENT_STATUS_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_POTENTIAL_CLIENT_STATUS_SUCCESS:
      return { ...state, updPotentialClientSuccess: action.payload };
    case types.UPDATE_POTENTIAL_CLIENT_STATUS_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_POTENTIAL_CLIENT_LISTING_SUCCESS:
      return { ...state, potentialClientListing: action.payload };
    case types.GET_POTENTIAL_CLIENT_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Client list is empty!");
        return { ...state, potentialClientListing: [] };
      } else {
      }
      console.log("on error console", action.payload);
    case types.GET_POTENTIAL_CLIENT_INFO_SUCCESS:
      return { ...state, potentialClientInfo: action.payload };
    case types.GET_POTENTIAL_CLIENT_INFO_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_NEW_CLIENT_NULL:
      return { ...state, potentialClientInfo: {} };
    case types.GET_POTENTIAL_CLIENT_UPDATE_BOL:
      console.log("bol console", action.payload);
      return { ...state, bol: action.payload };

    case types.CREATE_IMPORT_POTENTIAL_CLIENT_SUCCESS:
      return {
        ...state,
        totalPotentialClients: action.payload.count,
        importedPotentialClients: action.payload.created,
        duplicateRecords: action.payload.existing,
        potentialClientStatus: action.payload.potentialClientStatus,
        processingPersons: action.payload.processingPersons,
      };
    case types.CREATE_IMPORT_POTENTIAL_CLIENT_FAILURE:
      openNotificationWithIcon(
        "error",
        "Error",
        action.payload.response.data.Detail
      );
      console.log("on error console", action.payload.response.data.Detail);

    case types.POTENTIAL_EMAIL_IMPORT_SUCCESS:
      return {
        ...state,
        pclientImportsettings: action.payload.count,
        importedPotentialClients: action.payload.created,
        duplicateRecords: action.payload.existing,
        potentialClientStatus: action.payload.potentialClientStatus,
        processingPersons: action.payload.processingPersons,
      };
    case types.POTENTIAL_EMAIL_IMPORT_FAILURE:
      openNotificationWithIcon(
        "error",
        "Error",
        action.payload.response.data.Detail
      );
      console.log("on error console", action.payload.response);

    case types.GET_POTENTIAL_EMAIL_HISTORY_SUCCESS:
      return {
        ...state,
        potentialClientHistory: action.payload.emailHistories,
      };
    case types.GET_POTENTIAL_EMAIL_HISTORY_FAILURE:
      return {
        ...state,
        potentialClientHistory: [],
      };
    default:
      return state;
  }
};
