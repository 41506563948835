import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { Images } from "../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Input,
} from "antd";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const SendGridKey = ({ onSetActiveInnerTab }) => {
  const [loading, setLoading] = useState(false);
  const [gridKey, setGridKey] = useState(null);
  const [mailChimpIntegrated, setMailChimpIntegrated] = useState(false);
  const state = useLocation().state;
  const [form] = Form.useForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getGridKey(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getGridKey = () => {
    setLoading(true);
    const checkMailchimpOpt = {
      url: `v1/mailchimp/GetIsMailChimpOrSendGrid`,
    };
    checkMailchimpOpt.types = [
      "CHECK_MAILCHIMP_SUCCESS",
      "CHECK_MAILCHIMP_FAILURE",
    ];
    setLoading(true);
    apiRefresh.get(checkMailchimpOpt).then((res) => {
      if (res == "MailChimp") {
        setMailChimpIntegrated(true);
        message.error(
          "MailChimp Key added, You can't add SendGrind Key right now!",
          5
        );
      } else {
        setMailChimpIntegrated(false);
        const getGridKeyOpt = {
          url: `v1/company/sendgridkey`,
        };
        getGridKeyOpt.types = ["GET_GRID_KEY_SUCCESS", "GET_GRID_KEY_FAILURE"];

        apiRefresh
          .get(getGridKeyOpt)
          .then((res) => {
            setGridKey(res);
            setLoading(false);
            form.setFieldsValue({
              gridKey: res.key || "",
            });
          })
          .catch((err) => {
            setLoading(false);
          });
      }
      setLoading(false);
    });
  };

  const removeSendGrid = () => {
    const deleteGridKeyOpt = {
      url: `v1/company/sendgridkey`,
    };
    deleteGridKeyOpt.types = [
      "DELETE_GRID_KEY_SUCCESS",
      "DELETE_GRID_KEY_FAILURE",
    ];

    apiRefresh
      .delete(deleteGridKeyOpt)
      .then((res) => {
        setGridKey("");
        setLoading(false);
        form.setFieldsValue({
          gridKey: "",
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const onFinish = (values) => {
    setLoading(true);
    let data = {
      key: values.gridKey,
    };
    const sendGridKeyOpt = {
      url: `v1/company/sendgridkey`,
    };
    sendGridKeyOpt.types = ["SEND_GRID_KEY_SUCCESS", "SEND_GRID_KEY_FAILURE"];

    apiRefresh
      .put(sendGridKeyOpt, data)
      .then((res) => {
        setLoading(false);
        getGridKey();
        if (values.gridKey) {
          message.success("Grid Key added successfuly!");
        } else {
          message.success("Grid Key removed successfuly!");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img src={Images.SendGrid} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">SENDGRID</span>
                  <Tooltip placement="topLeft" title={``}>
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <Form form={form} onFinish={onFinish}>
                  <div>
                    <h3>SENDGRID KEY</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {/* {gridKey ? (
                              <div className="doc-office">
                                <div className="userDetail">
                                  <p>{gridKey.key}</p>
                                </div>
                              </div>
                            ) : ( */}

                            <>
                              {!mailChimpIntegrated && gridKey && (
                                <div className="remove-icon-cross">
                                  <a onClick={() => removeSendGrid()}>X</a>
                                </div>
                              )}
                              <Form.Item className="d-block" name="gridKey">
                                <Input />
                              </Form.Item>
                              <Form.Item className="d-block">
                                <Button
                                  className="button-blue"
                                  //   onClick={sendGridKey}
                                  type="primary"
                                  htmlType="submit"
                                  disabled={mailChimpIntegrated}
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div>
                  <div className="w-80">
                    <h3>ONE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {oneDrive ? (
                              <div className="doc-office ">
                                <div className="userDetail">
                                  <p>Name: {oneDrive.displayName}</p>
                                  <p>Email: {oneDrive.userPrincipalName}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeOneDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button
                                  onClick={loginOnOneDrive}
                                  type="primary"
                                >
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>GOOGLE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {googleDrive ? (
                              <div className="doc-office">
                                <div className="d-flex userDetail">
                                  <p>Name: {googleDrive.name}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeGoogleDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item
                                onClick={loginOnGoogleDrive}
                                className="d-block"
                              >
                                <Button type="primary">ADD ACCOUNT</Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>OFFICE 365</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            <div>
                              {office365 ? (
                                <div className="doc-office">
                                  <div className="d-flex userDetail">
                                    <p>Name:{office365.displayName}</p>
                                    <a
                                      className="remove-account"
                                      onClick={() => removeOffice365Account()}
                                    >
                                      X
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <Form.Item>
                                  <Button
                                    onClick={loginOnOffice365}
                                    className="margin-top"
                                    type="primary"
                                  >
                                    ADD ACCOUNT
                                  </Button>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default SendGridKey;
