import { api } from "../../services/api";
import * as types from "../Constants";

export const getPartnerData = data => {
   
  const options = {
    url: `v1/client/GetClientPartner/${data.clientId}/${data.familyId}`
  };

  options.types = [
    types.GET_PARTNER_PROFILE_SUCCESS,
    types.GET_PARTNER_PROFILE_FAILURE
  ];

  return api.get(options);
};

export const updPartnerProfile = data => {
  const options = {
    url: "v1/client/UpdateClientMember"
  };

  options.types = [
    types.UPD_PARTNER_PROFILE_SUCCESS,
    types.UPD_PARTNER_PROFILE_FAILURE
  ];

  return api.put(options, data);
};

export const addPartnerProfile = data => {
  const options = {
    url: "v1/client/InsertClientMember"
  };

  options.types = [
    types.ADD_PARTNER_PROFILE_SUCCESS,
    types.ADD_PARTNER_PROFILE_FAILURE
  ];

  return api.post(options, data);
};
