import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import SaleHistoryTable from "./SaleHistoryTable";
import { Images } from "../../Themes";
import { processingPersonReport } from "../../store/Actions";
import TaskPerformanceTable from "./TaskPerformanceTable";

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      if (index == 0) {
        return <a>{text}</a>;
      }
      return {
        children: <a>{text}</a>,
        props: {
          colSpan: 6,
        },
      };
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Invoiced Amount",
    dataIndex: "total",
    key: "invoicedAmount",
  },

  {
    title: "Received Amount",
    dataIndex: "received",
    key: "receivedAmount",
  },
];
// const SaleHistoryReport = ({ saleHistoryRes, displayText }) => {
export class TaskPerformanceReport extends React.PureComponent {
  render() {
    const {
      processingPersonReportRes,
      displayText,
      reportsCount,
      onTaskReport,
      requestData,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div />
            <div>
              <div className="rep-print-icon-cont">
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <img
                        style={{ cursor: "pointer" }}
                        src={Images.printNBlue}
                        className="rep-print-icon"
                      />
                    );
                  }}
                  content={() => this.taksPerformanceRef}
                />
              </div>
            </div>
          </div>
          <TaskPerformanceTable
            saleHistoryRes={processingPersonReportRes}
            ref={(el) => (this.taksPerformanceRef = el)}
            displayText={displayText}
            reportsCount={reportsCount}
            onTaskReport={onTaskReport}
            requestData
          />
        </div>
      </Fragment>
    );
  }
}
export default TaskPerformanceReport;
