import React, { useState, Fragment, useEffect } from "react";
import { Spin, message, Tooltip, Modal, Select } from "antd";
import {
  FileTextOutlined,
  FileTextFilled,
  UserOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import FileNotesMain from "./../EmployerManagement/EmployerManagementTabs/FileNotes";
import ClientEmployer from "./EmployerManagementTabs/ProfileEmployer/ClientEmployer/ClientEmployerTable";
import EmployerClient from "./EmployerClient";
import { Table } from "antd";
import { Images } from "../../Themes";
import moment from "moment";
import { LinkQuestionnaire } from "../../Containers";

let userId = localStorage.getItem("userId");
const { Option } = Select;

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const EmployerManagementTable = ({
  onGetEmployerManag,
  employerManagRes,
  _expandedRowsEntriesKeys,
  employerTabble,
  setEmployerTabble,

  onDeleteEmployer,

  singleEmployeFuc,

  userDataEmp,

  onGetEmployerData,
  singleEmployerRes,

  onGetLetterTemplates,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetEmployerJob,
  employerJobRes,
  LetterTemplatesRes,
  handleCancel,

  singleEmployeIcons,

  setLoading,
  loading,

  onUpdateEmployerFile,
  onRemoveEmployerFile,

  searchEmployerRes,

  isSearch,

  onAddAnyTamplate,

  onGetClientByEmpId,
  empClientEmpRes,
  onUpdateEmployerManag,
  onGetAllFilledQuestionnaire,
  allFilledQuestionnaireRes,
}) => {
  const [empTable, setEmpTable] = useState([]);
  const [modal1Visible, setModal1Visible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [isFileNoteVisible, setFileNoteVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [expandedRowsEntriesKeys, setExpandedRowsEntriesKeys] = useState("");
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);
  const [clickedIndex, setClickedIndex] = useState([]);
  const [selectedQuestionniareId, setSelectedQuestionnaireId] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [employerId, setEmployerId] = useState(false);
  const [employerList, setEmployerList] = useState([]);

  useEffect(() => {
    console.log("keys", expandedRowsEntriesKeys);
    if (_expandedRowsEntriesKeys.length === 0) {
      setExpandedRowsEntriesKeys(_expandedRowsEntriesKeys);
    }
  }, [_expandedRowsEntriesKeys]);

  useEffect(() => {
    letterTempaltesFilterSearch(searchEmployerRes);
  }, [searchEmployerRes]);

  useEffect(() => {
    letterTempaltesFilter(employerManagRes);
  }, [employerManagRes]);

  const letterTempaltesFilterSearch = (searchEmployerRes) => {
    let filterList = [];
    if (
      searchEmployerRes &&
      searchEmployerRes.items &&
      searchEmployerRes.items.length >= 0
    ) {
      searchEmployerRes.items.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        filterList.push(data);
      });

      let _finaLSortedList = filterList.sort((a, b) =>
        b.createdDate.localeCompare(a.createdDate)
      );

      setDataSourceSearch(_finaLSortedList);
    }
  };

  const letterTempaltesFilter = (employerManagRes) => {
    let filterList = [];
    if (
      employerManagRes &&
      employerManagRes.items &&
      employerManagRes.items.length >= 0
    ) {
      employerManagRes.items.map((data, index) => {
        if (data.isPotential === false) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        } else {
          // do nothing
        }
      });

      let _finaLSortedList = filterList.sort((a, b) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setDataSource(_finaLSortedList);
    }
  };

  const confirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value);
  };

  const removeEmploye = () => {
    setLoading(true);
    const remove = {
      id: onDelete,
      delete: true,
    };

    onDeleteEmployer(remove).then(() => {
      onGetEmployerManag();
      setLoading(false);
      setModal1Visible(false);

      message.success("Successfully Deleted!");
    });
  };

  const onExpandRowFuc = (record, onExpand, expanded) => {
    // setExpandedRowKeys(true);
    let keys = [];
    keys.push(record.key);
    setExpandedRowsEntriesKeys(keys);
    // if (expanded) {
    //   setExpandedRowsEntriesKeys([])
    // }
    setFileNoteVisible(true);
    singleEmployeIcons(record);
  };

  const onExpandClient = (record, onExpand, expanded) => {
    let keys = [];
    keys.push(record.key);
    setExpandedRowsEntriesKeys(keys);

    // if (expanded) {
    //   setExpandedRowsEntriesKeys([])
    // }
    setFileNoteVisible(false);
    singleEmployeIcons(record);
  };

  // const onExpandRowFuc = (article, expanded) => {
  //
  //   var keys = [];
  //   if (expanded) {
  //     keys.push(article.id);
  //   }

  //   setExpandedRowKeys({ keys });
  //   // if (expanded) {
  //   //   // expanded();
  //   // }
  // };

  const columns = [
    {
      title: "Status",
      dataIndex: "isActive",
      width: "70px",
      render: (text, record) => {
        return (
          <div
            className={text ? "right-green-btn-cont" : "cross-cont"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              // this.onProfileTopUpdate("ontext");
              record.isActive = !text;
              setLoading(true);
              onUpdateEmployerManag(record).then((res) => {
                onGetEmployerManag();
                setLoading(false);
              });
            }}
          >
            {text && (
              <img
                src={Images.tickWhite}
                className="svg-btn-img"
                style={{ marginRight: 10 }}
              />
            )}
            <img src={Images.btnImage} className="profile-btn-img" />
            {!text && (
              <img
                src={Images.crossWhite}
                className="svg-btn-img"
                style={{ marginLeft: 10 }}
              />
            )}
          </div>
        );
      },

      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.localeCompare(b.name),
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "EZM ID",
      dataIndex: "ezmId",
      width: "100px",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "100px",
      render: (text, record) => {
        return (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              singleEmployeFuc(record);
            }}
          >
            {text}
          </span>
        );
      },

      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.localeCompare(b.name),
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Business / Company Name",
      width: "110px",
      dataIndex: "business",
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.business.localeCompare(b.business),
    },
    {
      width: "100px",
      title: "Contact No",
      dataIndex: "contactNo",
      // ellipsis: true,
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      onFilter: (value, record) => record.address.indexOf(value) === 0,
      sorter: (a, b) => a.contactNo.localeCompare(b.contactNo),
    },
    {
      width: "150px",
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      width: "100px",
      title: "Tags",
      dataIndex: "tags",
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.tags.localeCompare(b.tags),
    },
    {
      width: "100px",
      title: "Accreditation",
      dataIndex: "accredationExpiryDate",
      // defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.accredationExpiryDate.localeCompare(b.accredationExpiryDate),
      render: (text, record) => {
        return (
          <>
            {record &&
            moment(record.accredationExpiryDate).format("DD/MM/YYYY") !==
              "01/01/0001" &&
            moment(record.accredationExpiryDate).format("DD/MM/YYYY") !==
              "01/01/1900" ? (
              moment(record.accredationExpiryDate) < moment(new Date()) ? (
                <span>Unaccredited</span>
              ) : (
                <span>Accredited</span>
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="school-table">
        <Spin size="large" spinning={questionnaireLoading}>
          <div className="col-emp">
            <Table
              expandedRowKeys={expandedRowsEntriesKeys}
              expandable={{
                expandIconColumnIndex: 8,
                expandedRowRender: (record) =>
                  isFileNoteVisible === true ? (
                    <FileNotesMain
                      onGetEmployerFile={onGetEmployerFile}
                      employerFileRes={employerFileRes}
                      employerJobRes={employerJobRes}
                      onAddEmployerFile={onAddEmployerFile}
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      userDataEmp={userDataEmp}
                      onRemoveEmployerFile={onRemoveEmployerFile}
                      handleCancel={handleCancel}
                      onUpdateEmployerFile={onUpdateEmployerFile}
                      onAddAnyTamplate={onAddAnyTamplate}
                    />
                  ) : (
                    <EmployerClient
                      onGetClientByEmpId={onGetClientByEmpId}
                      empClientEmpRes={empClientEmpRes}
                      userDataEmp={userDataEmp}
                    />
                  ),

                expandIcon: ({ expanded, onExpand, record }) => {
                  var index =
                    isSearch && dataSourceSearch.length > 0
                      ? dataSourceSearch.findIndex((obj) => obj.id == record.id)
                      : dataSource.findIndex((obj) => obj.id == record.id);
                  return (
                    <div className="table-action" type="right">
                      <Select
                        value="Q"
                        style={{ display: "inline-block" }}
                        dropdownClassName="dropdown-options-width-potential"
                        onSelect={(e) => {
                          setSelectedQuestionnaireId(e);
                          setShowDetail(true);
                          setEmployerId(record.id);
                        }}
                        onFocus={() => {
                          if (clickedIndex !== index) {
                            setQuestionnaireLoading(true);
                            setClickedIndex(index);
                            onGetAllFilledQuestionnaire(record.id)
                              .then((res) => {
                                let opt =
                                  res.payload &&
                                  res.payload.map((questionnaire, index) => (
                                    <Option
                                      value={questionnaire.questionnaireId}
                                    >
                                      {questionnaire.questionnaireName}
                                    </Option>
                                  ));
                                var clientList = isSearch
                                  ? dataSourceSearch.map((client, ind) =>
                                      ind == index
                                        ? { ...record, menuOption: opt }
                                        : client
                                    )
                                  : dataSource.map((client, ind) =>
                                      ind == index
                                        ? { ...record, menuOption: opt }
                                        : client
                                    );
                                isSearch
                                  ? setDataSourceSearch(clientList)
                                  : setDataSource(clientList);

                                setQuestionnaireLoading(false);
                                // setEmployerList(clientList);
                              })
                              .catch(() => {
                                setQuestionnaireLoading(false);
                              });
                          }
                        }}
                      >
                        {record.menuOption || []}
                      </Select>
                      <Tooltip placement="topLeft" title={`File Notes`}>
                        <FileTextFilled
                          type="fileNote"
                          style={{ fontSize: "15px" }}
                          onClick={(e) => {
                            onExpand(record, e);
                            onExpandRowFuc(record, onExpand, expanded);
                          }}
                        />
                      </Tooltip>
                      <Tooltip placement="topLeft" title={`Clients`}>
                        <UserOutlined
                          style={{ marginLeft: "3px", fontSize: "15px" }}
                          onClick={(e) => {
                            onExpand(record, e);
                            onExpandClient(record, onExpand, expanded);
                          }}
                        />
                      </Tooltip>
                      <Tooltip placement="topLeft" title={`Details`}>
                        <EditOutlined
                          style={{ marginLeft: "3px", fontSize: "15px" }}
                          onClick={() => singleEmployeFuc(record)}
                        />
                      </Tooltip>
                      <Tooltip placement="topLeft" title={`Delete`}>
                        <DeleteOutlined
                          style={{ marginLeft: "3px", fontSize: "15px" }}
                          onClick={() => confirmation(record && record.id)}
                        />
                      </Tooltip>
                    </div>
                  );
                },
              }}
              columns={columns}
              dataSource={isSearch === true ? dataSourceSearch : dataSource}
              scroll={{ x: "auto" }}
            />
          </div>
        </Spin>

        <Modal
          visible={showDetail}
          onCancel={() => setShowDetail(false)}
          footer={null}
          width="80%"
        >
          <>
            <LinkQuestionnaire
              questionnaireId={selectedQuestionniareId}
              notLink={true}
              clientprofileid={employerId}
              isPotential={false}
              isEmployer={true}
            />
          </>
        </Modal>

        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal1Visible}
          onOk={() => removeEmploye()}
          onCancel={() => setModal1Visible(false)}
          Header={false}
        >
          <h4>Are you sure you want to delete this employer?</h4>
        </Modal>
      </div>
    </Fragment>
  );
};
export default EmployerManagementTable;
