import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const visaTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case types.VISA_TYPE_SUCCESS:
      return { ...state, visaTypeData: action.payload };
    case types.VISA_TYPE_FAILURE:
      console.log("on error console", action.payload);
    case types.VISA_TYPE_WITH_HIDDEN_SUCCESS:
      return { ...state, visaTypeWithHiddenData: action.payload };
    case types.VISA_TYPE_WITH_HIDDEN_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "list is empty!");
        return { ...state, visaTypeWithHiddenData: [] };
      } else {
        console.log("on error console", action.payload);
      }
    case types.ADD_VISA_TYPE_SUCCESS:
      return { ...state, addVisaTypeSuccess: action.payload };
    case types.ADD_VISA_TYPE_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_VISA_TYPE_SUCCESS:
      return { ...state, updVisaTypeSuccess: action.payload };
    case types.UPDATE_VISA_TYPE_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_COUNTRIES_SUCCESS:
      return { ...state, countriesData: action.payload };
    case types.GET_COUNTRIES_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_BRANCH_VISA_COUNTRIES_SUCCESS:
      return { ...state, brnchVisaCountriesData: action.payload };
    case types.GET_BRANCH_VISA_COUNTRIES_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
