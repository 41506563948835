import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QuestionnaireSettings from "../AccountSetting/QuestionnaireSettings/QuestionnaireSettings";

import {
  getQuestionnaireSettings,
  setActiveInnerTab,
  updateQuestionnaireSet, uploadAvatar
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    questionnaireSetRes: state.documentTypeReducer.questionnaireSetRes,
    addQuestionnaireSetRes: state.documentTypeReducer.addQuestionnaireSetRes,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetQuestionnaireSet: bindActionCreators(
      getQuestionnaireSettings,
      dispatch
    ),
    onUpdateQuestionnaireSet: bindActionCreators(
      updateQuestionnaireSet,
      dispatch
    ),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireSettings);
