import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Spin, message } from "antd";
import "./comment.css";
import activityData from "../../Components/ClientActivity/ActivityData";
import moment from "moment";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const UpdateModal = ({
  onUpdetaTasks,
  dailyTaskRes,
  updateTitle,
  updateData,
  reloadFlag,
  setReload,
  handleCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    if (formRef && formRef.current)
      formRef.current.setFieldsValue({
        title: updateData.taskTitle,
      });
  }, [updateData]);
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    const update = {
      id: updateTitle,
      taskDate: updateData.taskDate,
      taskTitle: values.title,
      taskDescription: updateData.taskDescription,
      subjectId: updateData.subjectId,
      isPontential: updateData.isPontential,
      isCompleted: updateData.isCompleted,
      completedOn: updateData.completedOn,
    };
    onUpdetaTasks(update)
      .then(() => {
        setLoading(false);
        // if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Updated!");
        handleCancel();
        setReload(!reloadFlag);
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            "\nlOG FOR " +
            profileData.fullName +
            " Task updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Tasks",
          invoiceId: "0",
        };
        activityData(myData);
        // window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        // if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Error in Update!");
      });
  };

  return (
    <Spin size="large" spinning={loading}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        ref={formRef}
      >
        <div className="mdtr-modal-gray-cont">
          <div className="modal-parts">
            <Form.Item
              className="add-reminder-form form-parts"
              label="Title"
              name="title"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" className="task-blue" htmlType="submit">
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UpdateModal;
