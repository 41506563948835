import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col
} from "antd";
import { Images } from "../Themes";
import Banks from "./Banks";
import Taxes from "./Taxes";
import Notes from "./Notes";
import OutgoingPaymentSources from "./OutgoingPaymentSources";
const { Option } = Select;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTaxesSection = () => {
    return (
      <Row xs={24}>
        <Col xs={21} offset={1} style={{ marginTop: "13px" }}>
          <Table />
        </Col>
        <Col xs={1} offset={1} style={{ marginTop: "-14px" }}>
          <img
            src={Images.addIcon}
            className="icons-client"
            type="primary"
            onClick={() => this.onAddingNewSchool()}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <div className="page-container" style={{ marginTop: "-30px" }}>
          <div className="ts-container-settings">
            <Banks />
            <Taxes />
            <Notes />
            <OutgoingPaymentSources />
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
