import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultFontSettings from "../AccountSetting/UserLevelSettings/DefaultFontSettings/DefaultFontSettings";

import { setActiveInnerTab } from "../store/Actions";
import CustomMessage from "../AccountSetting/CustomMessage";

const mapStateToProps = (state) => {
  return {
    defaultFontRes: state.documentTypeReducer.defaultFontRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomMessage);
