import React, { Fragment } from "react";
import { Table, Button } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";

const columns = [
  {
    title: "EZM ID",
    dataIndex: "clientNumber",
    key: "clientNumber",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <a>{text}</a>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "EMPLOYER NAME",
    dataIndex: "employerName",
    key: "employerName",
  },
  {
    title: "LEGAL COMPANY Name",
    dataIndex: "business",
    key: "business",
  },
  {
    title: "CONTACT PERSON",
    dataIndex: "contactPerson",
    key: "contactPerson",
  },
  {
    title: "MOBILE",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "PHONE",
    dataIndex: "dob",
    key: "dob",
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "CLIENT",
    dataIndex: "clientName",
    key: "clientName",
  },
  {
    title: "JOB SECTOR",
    dataIndex: "jobSector",
    key: "jobSector",
  },
  {
    title: "OCCUPATION",
    dataIndex: "occupation",
    key: "occupation",
  },
  {
    title: "PROCESSING PERSONS",
    dataIndex: "fullName",
    key: "fullName",
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class ClientEmployerTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson:
            this.props.requestData.processingIds ||
            "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
        };
        this.props.onBirthdayReport(data).then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };

  clientEmployerExport = () => {
    let data = {
      processingPerson:
        this.props.processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: 0,
      pageNumber: 0,
    };
    this.setState({ loading: true });
    const visaExpiryOpt = {
      url: `v1/report/ClientEmployerExport`,
    };
    visaExpiryOpt.types = [
      "VISA_EXPIRY_REPORT_SUCCESS",
      "VISA_EXPIRY_REPORT_FAILURE",
    ];

    apiRefresh
      .postWithFileRes(visaExpiryOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Client_Employer_Report.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { clientEmployerReportRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
            <Button
              className="button-blue"
              style={{ marginTop: 10 }}
              onClick={this.clientEmployerExport}
              loading={this.state.loading}
            >
              Export
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={clientEmployerReportRes && clientEmployerReportRes}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
            scroll={{ x: "auto" }}
          />
        </div>
      </Fragment>
    );
  }
}
export default ClientEmployerTable;
