import { api } from "../../services/api";
import * as types from "../Constants";

export const getReminderTasks = (id) => {
  let cId = id || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/reminder/All`,
  };
  options.types = [types.GET_REMINDER_SUCCESS, types.GET_REMINDER_FAILURE];

  return api.get(options);
};

export const addReminderTask = (data) => {
  const options = {
    url: "v1/reminder",
  };
  options.types = [
    types.ADD_REMINDER_TASK_SUCCESS,
    types.ADD_REMINDER_TASK_FAILURE,
  ];

  return api.post(options, data);
};

export const updateReminder = (data) => {
  const options = {
    url: "v1/reminder/reminderStatus",
  };
  options.types = [
    types.UPDATE_REMINDER_SUCCESS,
    types.UPDATE_REMINDER_FAILURE,
  ];

  return api.put(options, data);
};

export const updateReminderDetail = (data) => {
  const options = {
    url: "v1/reminder/reminder",
  };
  options.types = [
    types.UPDATE_REMINDER_DETAIL_SUCCESS,
    types.UPDATE_REMINDER_DETAIL_FAILURE,
  ];

  return api.put(options, data);
};

export const removeReminderTask = (data) => {
  const options = {
    url: "v1/reminder",
  };
  options.types = [
    types.DELETE_REMINDER_TASK_SUCCESS,
    types.DELETE_REMINDER_TASK_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeProfileClientTag = (data) => {
  const options = {
    url: "v1/client/AssignTag",
  };
  options.types = [
    types.DELETE_PROFILE_CLIENT_TAG_SUCCESS,
    types.DELETE_PROFILE_CLIENT_TAG_FAILURE,
  ];

  return api.delete(options, data);
};

export const getDailyTasks = () => {
  const options = {
    url: `v1/task/AllByUserId`,
  };
  options.types = [
    types.GET_DAILY_TASKS_SUCCESS,
    types.GET_DAILY_TASKS_FAILURE,
  ];

  return api.get(options);
};

export const getTasksComments = (id) => {
  const options = {
    url: `v1/task/comment/All/${id}`,
  };
  options.types = [
    types.GET_TASK_COMMENTS_SUCCESS,
    types.GET_TASK_COMMENTS_FAILURE,
  ];

  return api.get(options);
};

export const getTasksFollowers = (id) => {
  const options = {
    url: `v1/task/users/All/${id}`,
  };
  options.types = [
    types.GET_TASK_FOLLOWERS_SUCCESS,
    types.GET_TASK_FOLLOWERS_FAILURE,
  ];

  return api.get(options);
};

export const getClientTasks = (id, familyId) => {
  const options = {
    url: `v1/task/AllBySubjectId/${id}/${familyId}`,
  };
  options.types = [
    types.GET_CLIENT_TASK_SUCCESS,
    types.GET_CLIENT_TASK_FAILURE,
  ];

  return api.get(options);
};

export const getPotentialClientTasks = (id) => {
  const options = {
    url: `v1/task/All/SubjectId/${id}`,
  };
  options.types = [
    types.GET_POTENTIAL_TASK_SUCCESS,
    types.GET_POTENTIAL_TASK_FAILURE,
  ];

  return api.get(options);
};

export const addTaskComments = (data) => {
  const options = {
    url: "v1/task/comment",
  };
  options.types = [
    types.ADD_TASK_COMMENTS_SUCCESS,
    types.ADD_TASK_COMMENTS_FAILURE,
  ];

  return api.post(options, data);
};

export const addTaskFollower = (data) => {
  const options = {
    url: "v1/task/users",
  };
  options.types = [
    types.ADD_TASK_FOLLOWER_SUCCESS,
    types.ADD_TASK_FOLLOWER_FAILURE,
  ];

  return api.post(options, data);
};

export const addDailyTasks = (data) => {
  const options = {
    url: "v1/task/TaskWithUsers",
  };
  options.types = [
    types.ADD_DAILY_TASKS_SUCCESS,
    types.ADD_DAILY_TASKS_FAILURE,
  ];

  return api.post(options, data);
};

export const removeTasks = (data) => {
  const options = {
    url: "v1/task",
  };
  options.types = [types.DELETE_TASKS_SUCCESS, types.DELETE_TASKS_FAILURE];

  return api.delete(options, data);
};

export const removeFollower = (data) => {
  const options = {
    url: "v1/task/users",
  };
  options.types = [
    types.DELETE_FOLLOWER_SUCCESS,
    types.DELETE_FOLLOWER_FAILURE,
  ];

  return api.delete(options, data);
};

export const removeTaskClient = (data) => {
  const options = {
    url: "v1/task/TaskSubject",
  };
  options.types = [types.DELETE_CLIENT_SUCCESS, types.DELETE_CLIENT_FAILURE];

  return api.put(options, data);
};

export const updetaTasks = (data) => {
  const options = {
    url: "v1/task",
  };
  options.types = [types.PUT_TASK_SUCCESS, types.PUT_TASK_FAILURE];

  return api.put(options, data);
};

export const updateCompletedTask = (data) => {
  const options = {
    url: "v1/task/CompleteTask",
  };
  options.types = [
    types.PUT_COMPLETED_TASK_SUCCESS,
    types.PUT_COMPLETED_TASK_FAILURE,
  ];

  return api.put(options, data);
};

export const addTaskFileNote = (data) => {
  const options = {
    url: "v1/client/filenote",
  };
  options.types = [
    types.ADD_TASK_FILE_NOTE_SUCCESS,
    types.ADD_TASK_FILE_NOTE_FAILURE,
  ];

  return api.post(options, data);
};

export const getCompletedTasks = (id, pageNumber, pageSize) => {
  let userId = null;
  if (id) userId = id;
  else userId = "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/task/AllByUserIdPagination/${userId}/${pageSize}/${pageNumber}`,
  };
  options.types = [
    types.GET_TASK_COMPLETED_SUCCESS,
    types.GET_TASK_COMPLETED_FAILURE,
  ];

  return api.get(options);
};
