import React, { Fragment, useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import AddAutoReminder from "../../../../../Components/Reminder/AddAutoReminder";
import FroalaEditorCom from "../../../../../Containers/FroalaEditorCom";
const dateFormat = "DD/MM/YYYY";
function onChange(date, dateString) {
  console.log(date, dateString);
}

const JobEmployerForm = ({
  handleCancel,

  // setLoading,

  onUpdetaClientTag,
  updateClientTagRes,

  createMode,

  updatedata,

  onGetEmployerJob,

  onAddEmployerJob,

  userDataEmp,

  teamMembers,

  setLoading,
  loading,

  onUpdateEmployerJob,

  onGetClientSearch,
  clientSearchRes,
  onJobUpdate,
}) => {
  const [openDateChange, setOpenDateChange] = useState("");
  const [closeDateChange, setCloseDateChange] = useState("");
  const [remunerationChange, setRemunerationChange] = useState("");
  const [requiredChange, setRequiredChange] = useState("");
  const [advertisingExpiry, setAdvertisingExpiry] = useState("");
  const [skillMatesReportExpiry, setSkillMatesReportExpiry] = useState("");
  const [letterString, setLetterString] = useState("");

  const [form] = Form.useForm();

  const onOpenDateChange = (value, dateString) => {
    console.log(value, dateString);
    setOpenDateChange(value);
  };

  const { Option } = Select;

  const onCloseDateChange = (value, dateString) => {
    console.log(value, dateString);
    setCloseDateChange(value);
  };

  const onRemunerationChange = (value) => {
    console.log("changed", value);
    setRemunerationChange(value);
  };
  const onRequiredChange = (value) => {
    console.log("changed", value);
    setRequiredChange(parseFloat(value));
  };

  const onAdvertisingExpiry = (value) => {
    console.log("changed", value);
    setAdvertisingExpiry(value);
  };

  const onSkillReportExpiry = (value, dateString) => {
    console.log(value, dateString);
    setSkillMatesReportExpiry(value);
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     name: updatedata.name
  //   });
  // }, [form, updatedata.name]);

  useEffect(() => {
    setLoading(true);
    if (updatedata && updatedata.id) {
      let _openDate = moment(updatedata.openDate).format("DD-MM-YYYY");
      let _closeDate;
      if (updatedata.closeDate === "1900-01-01T00:00:00+00:00") {
        _closeDate = undefined;
      } else {
        _closeDate = moment(updatedata.closeDate).format("DD-MM-YYYY");
      }

      let _advertisingExpiry;
      if (updatedata.advertisingExpiry === "1900-01-01T00:00:00+00:00") {
        _advertisingExpiry = undefined;
      } else {
        _advertisingExpiry = moment(updatedata.advertisingExpiry).format(
          "DD-MM-YYYY"
        );
      }

      let _skillMatesReportExpiry;
      if (updatedata.skillMatesReportExpiry === "1900-01-01T00:00:00+00:00") {
        _skillMatesReportExpiry = undefined;
      } else {
        _skillMatesReportExpiry = moment(
          updatedata.skillMatesReportExpiry
        ).format("DD-MM-YYYY");
      }

      setLetterString(updatedata && updatedata.comments);
      form.setFieldsValue({
        job_no: updatedata && updatedata.jobOrderNo,
        job_tittle: updatedata && updatedata.jobTitle,
        openDate: updatedata
          ? moment(_openDate, dateFormat)
          : moment(_openDate, dateFormat),
        closeDate: updatedata
          ? _closeDate === undefined
            ? undefined
            : moment(_closeDate, dateFormat)
          : _closeDate === undefined
          ? undefined
          : moment(_closeDate, dateFormat),
        position: updatedata && updatedata.position,
        remuneration: updatedata && updatedata.remuneration,
        experience_required: updatedata && updatedata.experinceRequired,
        required:
          updatedata && updatedata.required === 0
            ? undefined
            : updatedata.required,
        other_requirements: updatedata && updatedata.otherRequiremts,
        anzsco_code: updatedata && updatedata.anzscoCode,
        policy: updatedata && updatedata.policy,
        contract: updatedata && updatedata.contract,
        visa_length: updatedata && updatedata.visaLength,
        location: updatedata && updatedata.location,
        advertisingExpiry: updatedata
          ? _advertisingExpiry === undefined
            ? undefined
            : moment(_advertisingExpiry, dateFormat)
          : _advertisingExpiry === undefined
          ? undefined
          : moment(_advertisingExpiry, dateFormat),
        address: updatedata && updatedata.siteAddress,
        liaId:
          updatedata &&
          updatedata.liaId === "00000000-0000-0000-0000-000000000000"
            ? undefined
            : updatedata.liaId,
        skillMatesReportExpiry: updatedata
          ? _skillMatesReportExpiry === undefined
            ? undefined
            : moment(_skillMatesReportExpiry, dateFormat)
          : _skillMatesReportExpiry === undefined
          ? undefined
          : moment(_skillMatesReportExpiry, dateFormat),
        skill_level: updatedata && updatedata.skillLevel,
        salesPersonId:
          updatedata &&
          updatedata.salePersonId === "00000000-0000-0000-0000-000000000000"
            ? undefined
            : updatedata.salePersonId,
      });
    }

    setLoading(false);
  }, [updatedata, form, setLoading]);

  const onFinish = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
    let _closeDate;
    if (values.closeDate === undefined || values.closeDate === null) {
      _closeDate = "1900-01-01T00:00:00.000Z";
    } else {
      _closeDate = values.closeDate;
    }

    let _skillMatesReportExpiry;
    if (
      values.skillMatesReportExpiry === undefined ||
      values.skillMatesReportExpiry === null
    ) {
      _skillMatesReportExpiry = "1900-01-01T00:00:00.000Z";
    } else {
      _skillMatesReportExpiry = values.skillMatesReportExpiry;
    }

    let _advertisingExpiry;
    if (
      values.advertisingExpiry === undefined ||
      values.advertisingExpiry === null
    ) {
      _advertisingExpiry = "1900-01-01T00:00:00.000Z";
    } else {
      _advertisingExpiry = values.advertisingExpiry;
    }
    const userId = localStorage.getItem("userId");
    if (createMode === "add-job") {
      const data = {
        employerId: userDataEmp && userDataEmp.id,
        jobStatusId: 1,
        jobTitle: values && values.job_tittle ? values.job_tittle : "",
        liaId:
          values && values.liaId
            ? values.liaId
            : "00000000-0000-0000-0000-000000000000",
        salePersonId:
          values && values.salesPersonId
            ? values.salesPersonId
            : "00000000-0000-0000-0000-000000000000",
        jobOrderNo: values && values.job_no ? values.job_no : "",
        position: values && values.position ? values.position : "",
        openDate: values.openDate || new Date(),
        closeDate: _closeDate,
        required: values && values.required ? values.required : 0,
        contract: values && values.contract ? values.contract : "",
        location: values && values.location ? values.location : "",
        remuneration: values && values.remuneration ? values.remuneration : "",
        agency: "",
        conLocationtract: "",
        siteAddress: values && values.address ? values.address : "",
        anzscoCode: values && values.anzsco_code ? values.anzsco_code : "",
        skillLevel: values && values.skill_level ? values.skill_level : "",
        experinceRequired:
          values && values.experience_required
            ? values.experience_required
            : "",
        otherRequiremts:
          values && values.other_requirements ? values.other_requirements : "",
        policy: values && values.policy ? values.policy : "",
        visaLength: values && values.visa_length ? values.visa_length : "",
        comments: letterString ? letterString : "",
        laborMarketTestExpiry: new Date(),
        laborMarketTestExpiry1: new Date(),
        advertisingExpiry: _advertisingExpiry,
        skillMatesReportExpiry: _skillMatesReportExpiry,
      };

      onAddEmployerJob(data)
        .then(() => {
          onGetEmployerJob(userDataEmp && userDataEmp.id);
          setLoading(false);
          handleCancel();
          if (
            _advertisingExpiry &&
            _advertisingExpiry != updatedata.advertisingExpiry &&
            moment(_advertisingExpiry).format("DD/MM/YYYY") != "01/01/1900"
          ) {
            let myData = {
              title: "Advertising Expiry",
              description:
                "EZYM Notification - Advertising Expiry is set to expire on ",
              reminderDate: _advertisingExpiry
                ? moment(_advertisingExpiry).format("YYYY-MM-DDT00:00:00+00:00")
                : "1900-01-01T00:00:00+00:00",
              profileId: userDataEmp && userDataEmp.id,
              profileName: userDataEmp && userDataEmp.name,
              typeId: 3,
            };
            AddAutoReminder(myData);
          }

          if (
            _skillMatesReportExpiry &&
            _skillMatesReportExpiry != updatedata.skillMatesReportExpiry &&
            moment(_skillMatesReportExpiry).format("DD/MM/YYYY") != "01/01/1900"
          ) {
            let secondData = {
              title: "Skill Match Expiry",
              reminderDate: _skillMatesReportExpiry
                ? moment(_skillMatesReportExpiry).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00",
              profileId: userDataEmp && userDataEmp.id,
              profileName: userDataEmp && userDataEmp.name,
              typeId: 12,
            };
            AddAutoReminder(secondData);
          }
          message.success("Successfully Added!");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      const update = {
        id: updatedata && updatedata.id,
        employerId: userDataEmp && userDataEmp.id,
        jobStatusId: 1,
        jobTitle: values && values.job_tittle ? values.job_tittle : "",
        liaId:
          values && values.liaId
            ? values.liaId
            : "00000000-0000-0000-0000-000000000000",
        salePersonId:
          values && values.salesPersonId
            ? values.salesPersonId
            : "00000000-0000-0000-0000-000000000000",
        jobOrderNo: values && values.job_no ? values.job_no : "",
        position: values && values.position ? values.position : "",
        openDate: values.openDate || new Date(),
        closeDate: _closeDate,
        required: values && values.required ? values.required : 0,
        contract: values && values.contract ? values.contract : "",
        location: values && values.location ? values.location : "",
        remuneration: values && values.remuneration ? values.remuneration : "",
        agency: "",
        conLocationtract: "",
        siteAddress: values && values.address ? values.address : "",
        anzscoCode: values && values.anzsco_code ? values.anzsco_code : "",
        skillLevel: values && values.skill_level ? values.skill_level : "",
        experinceRequired:
          values && values.experience_required
            ? values.experience_required
            : "",
        otherRequiremts:
          values && values.other_requirements ? values.other_requirements : "",
        policy: values && values.policy ? values.policy : "",
        visaLength: values && values.visa_length ? values.visa_length : "",
        comments: letterString ? letterString : "",
        laborMarketTestExpiry: new Date(),
        laborMarketTestExpiry1: new Date(),
        advertisingExpiry: _advertisingExpiry
          ? moment(_advertisingExpiry).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        skillMatesReportExpiry: _skillMatesReportExpiry
          ? moment(_skillMatesReportExpiry).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
      };

      onUpdateEmployerJob(update)
        .then(() => {
          onGetEmployerJob(userDataEmp && userDataEmp.id);
          onJobUpdate();
          handleCancel();
          setLoading(false);
          message.success("Successfully Updated!");
          if (
            _advertisingExpiry &&
            _advertisingExpiry != updatedata.advertisingExpiry &&
            moment(_advertisingExpiry).format("DD/MM/YYYY") != "01/01/1900"
          ) {
            let myData = {
              title: "Advertising Expiry",
              description:
                "EZYM Notification - Advertising Expiry is set to expire on ",
              reminderDate: _advertisingExpiry
                ? moment(_advertisingExpiry).format("YYYY-MM-DDT00:00:00+00:00")
                : "1900-01-01T00:00:00+00:00",
              profileId: userDataEmp && userDataEmp.id,
              profileName: userDataEmp && userDataEmp.name,
              typeId: 3,
            };
            AddAutoReminder(myData);
          }

          if (
            _skillMatesReportExpiry &&
            _skillMatesReportExpiry != updatedata.skillMatesReportExpiry &&
            moment(_skillMatesReportExpiry).format("DD/MM/YYYY") != "01/01/1900"
          ) {
            let secondData = {
              title: "Skill Match Expiry",
              reminderDate: _skillMatesReportExpiry
                ? moment(_skillMatesReportExpiry).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00",
              profileId: userDataEmp && userDataEmp.id,
              profileName: userDataEmp && userDataEmp.name,
              typeId: 12,
            };
            AddAutoReminder(secondData);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <Fragment>
        <Form
          onFinish={onFinish}
          form={form}
          className="emp-content-box p-box add-job-form width-100"
          name="main"
        >
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>
                Job Order No<span className="required-field">*</span>
              </p>
            </Col>
            <Col span={8}>
              <Form.Item
                name="job_no"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="Job Order No" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>
                Job Tittle<span className="required-field">*</span>
              </p>
            </Col>
            <Col span={8}>
              <Form.Item
                name="job_tittle"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="Job Tittle" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>
                Open Date<span className="required-field">*</span>
              </p>
            </Col>
            <Col span={8}>
              <Form.Item
                name="openDate"
                rules={[{ required: true, message: "Required!" }]}
              >
                <DatePicker onChange={onOpenDateChange} format={dateFormat} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Close Date</p>
            </Col>
            <Col span={8}>
              <Form.Item name="closeDate" required={false}>
                <DatePicker onChange={onCloseDateChange} format={dateFormat} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>
                Position<span className="required-field">*</span>
              </p>
            </Col>
            <Col span={8}>
              <Form.Item
                name="position"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="Position" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>
                Remuneration<span className="required-field">*</span>
              </p>
            </Col>
            <Col span={8}>
              <Form.Item
                name="remuneration"
                rules={[{ required: true, message: "Required!" }]}
              >
                <InputNumber onChange={onRemunerationChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Experience Required</p>
            </Col>
            <Col span={8}>
              <Form.Item name="experience_required" required={false}>
                <Input placeholder="Experience Required" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Required</p>
            </Col>
            <Col span={8}>
              <Form.Item name="required">
                <InputNumber onChange={onRequiredChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Other Requirements</p>
            </Col>
            <Col span={8}>
              <Form.Item name="other_requirements" required={false}>
                <Input placeholder="Other Requirements" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>ANZSCO Code</p>
            </Col>
            <Col span={8}>
              <Form.Item name="anzsco_code">
                <Input placeholder="ANZSCO Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Policy</p>
            </Col>
            <Col span={8}>
              <Form.Item name="policy" required={false}>
                <Input placeholder="Policy" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Contract</p>
            </Col>
            <Col span={8}>
              <Form.Item name="contract">
                <Input placeholder="Contract" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Visa Length</p>
            </Col>
            <Col span={8}>
              <Form.Item name="visa_length" required={false}>
                <Input placeholder="Visa Length" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Location</p>
            </Col>
            <Col span={8}>
              <Form.Item name="location">
                <Input placeholder="Location" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Advertising Expiry</p>
            </Col>
            <Col span={8}>
              <Form.Item name="advertisingExpiry" required={false}>
                <DatePicker
                  onChange={onAdvertisingExpiry}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Address</p>
            </Col>
            <Col span={8}>
              <Form.Item name="address" required={false}>
                <Input placeholder="Address" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>LIA</p>
            </Col>
            <Col span={8}>
              <Form.Item name="liaId">
                <Select>
                  {teamMembers &&
                    teamMembers.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.fullName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Skill Match Report Expiry</p>
            </Col>
            <Col span={8}>
              <Form.Item name="skillMatesReportExpiry" required={false}>
                <DatePicker
                  onChange={onSkillReportExpiry}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Skill Level</p>
            </Col>
            <Col span={8}>
              <Form.Item name="skill_level" required={false}>
                <Input placeholder="Skill Level" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <p style={{ fontSize: 13 }}>Sales Person</p>
            </Col>
            <Col span={8}>
              <Form.Item name="salesPersonId">
                <Select>
                  {teamMembers &&
                    teamMembers.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.fullName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="margin-top-20">
            <div className="letter-froala emp-froala">
              <h4
                style={{
                  marginTop: "22px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Immigration Strategies
              </h4>
              <div className="letter-froala">
                <FroalaEditorCom
                  setLoading={(value) => setLoading(value)}
                  model={letterString}
                  onModelChange={(value) => setLetterString(value)}
                />
              </div>

              <div className="document-checklist--btn d-flex">
                <Button
                  style={{ marginRight: "10px" }}
                  // onClick={() => emailTypeData(data.emailType)}
                  htmlType="submit"
                  type="primary"
                  className="button-blue"
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="button-blue"
                >
                  Close
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    </Spin>
  );
};
export default JobEmployerForm;
