import React, { Fragment, useState, useEffect } from "react";
import CreateEmailForm from "./CreateEmailForm";

const createEmail = ({
  onGetLetterTemplates,
  LetterTemplatesRes,
  emailContentData,
  onSendEmailLink,
  handleKey,
  onAddEmployerEmail,
  onAddDraftClient,

  onGetClientProfile,
  clientProfileData,

  setLoading,
  loading,
  onGetClientHistory,

  onUploadAvatar,

  onAddEmailDocument,
  emailDocumentRes,
  resultList,
  setHandleKey,

  onGetClientFamilyEmails,
  clientEmailRes,

  onGetEmployerDocument,
  employerDocumentRes,

  onGetImapSettingss,
  imapSettingRes,

  onGetSignature,
  signatureRes,

  onGetPdf,

  onGetLink,
  onGetDocuments,

  documentRes,
  onGetDocumentDownload,
  onDeleteClientEmail,
  onSetActiveKey,
  profileData,
  onGetPermProcessingPerson,
  onGetGroups,
}) => {
  // useEffect(() => {
  //   setLoading(true);
  //
  //   onGetEmployerCheckList().then((res) => {
  //     setChecklistItem(res.payload.items);
  //     setLoading(false);
  //   });
  // }, [onGetEmployerCheckList]);

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  return (
    <Fragment>
      <CreateEmailForm
        onGetLetterTemplates={onGetLetterTemplates}
        LetterTemplatesRes={LetterTemplatesRes}
        onSendEmailLink={onSendEmailLink}
        onAddEmployerEmail={onAddEmployerEmail}
        onAddDraftClient={onAddDraftClient}
        onGetClientProfile={onGetClientProfile}
        clientProfileData={clientProfileData}
        setLoading={setLoading}
        loading={loading}
        onGetClientHistory={onGetClientHistory}
        onUploadAvatar={onUploadAvatar}
        onAddEmailDocument={onAddEmailDocument}
        emailDocumentRes={emailDocumentRes}
        setHandleKey={setHandleKey}
        onGetClientFamilyEmails={onGetClientFamilyEmails}
        clientEmailRes={clientEmailRes}
        onGetEmployerDocument={onGetEmployerDocument}
        employerDocumentRes={employerDocumentRes}
        onGetImapSettingss={onGetImapSettingss}
        imapSettingRes={imapSettingRes}
        onGetSignature={onGetSignature}
        signatureRes={signatureRes}
        onGetPdf={onGetPdf}
        onGetLink={onGetLink}
        onGetDocuments={onGetDocuments}
        documentRes={documentRes}
        onGetDocumentDownload={onGetDocumentDownload}
        onDeleteClientEmail={onDeleteClientEmail}
        handleKey={handleKey}
        emailContentData={emailContentData}
        resultList={resultList}
        onSetActiveKey={onSetActiveKey}
        profileData={profileData}
        onGetPermProcessingPerson={onGetPermProcessingPerson}
        onGetGroups={onGetGroups}
      />
    </Fragment>
  );
};

export default createEmail;
