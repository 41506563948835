import React, { Fragment, useEffect, useState } from "react";
import {
  Tabs,
  Modal,
  Form,
  Input,
  Select,
  Table,
  Button,
  Spin,
  DatePicker,
} from "antd";
import { apiRefresh } from "../../services/api";

const { TabPane } = Tabs;

const { Option } = Select;

const JobSectors = [
  {
    label: "Administrative",
    id: 1,
  },
  {
    label: "Agriculture / Farming",
    id: 2,
  },
  {
    label: "Automotive",
    id: 3,
  },
  {
    label: "Construction",
    id: 4,
  },
  {
    label: "Electrical",
    id: 5,
  },
  {
    label: "Engineer",
    id: 6,
  },
  {
    label: "Finance",
    id: 7,
  },
  {
    label: "FMCG",
    id: 8,
  },
  {
    label: "Hospitality",
    id: 9,
  },
  {
    label: "Human Resources",
    id: 10,
  },
  {
    label: "Insurance",
    id: 11,
  },
  {
    label: "Legal",
    id: 12,
  },
  {
    label: "Marketing",
    id: 13,
  },
  {
    label: "Medical",
    id: 14,
  },
  {
    label: "Real estate",
    id: 15,
  },
  {
    label: "Retail",
    id: 16,
  },
  {
    label: "Sales",
    id: 17,
  },
  {
    label: "Supply chain",
    id: 18,
  },
  {
    label: "Teachers",
    id: 19,
  },
  {
    label: "Trades",
    id: 20,
  },
  {
    label: "Not Employed",
    id: 21,
  },
  {
    label: "Transportation",
    id: 22,
  },
  {
    label: "Manufacturing",
    id: 23,
  },
];

const FilterPopup = ({
  countriesData,
  allUsers,
  setClientRows,
  setPotClientRows,
  setEmployerRows,
  visaTypeData,
  visaStatusData,
  clientTagRes,
  userData,
  usersList,
}) => {
  const [handleKey, setHandleKey] = useState("1");
  const [clients, setClients] = useState([]);
  const [potentialClients, setPotentialClients] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [processingPersons, setProcessingPersons] = useState([]);
  const [clientTags, setClientTags] = useState([]);
  const [visaStatuses, setVisaStatuses] = useState([]);
  const [potentialClientStatus, setPotentialClientStatus] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [visaCountry, setVisaCountry] = useState(0);
  const [clientStatus, setClientStatus] = useState("");
  const [visaType, setVisaType] = useState(0);
  const [pcVisaType, setPcVisaType] = useState("");
  const [pcClientTag, setPcClientTag] = useState("");
  const [visaStatus, setVisaStatus] = useState("");
  const [clientTag, setClientTag] = useState(0);
  const [processingPerValue, setProcessingPerValue] = useState("");
  const [passportCountryVal, setPassportCountryVal] = useState("");
  const [pcStatusValue, setPcStatusValue] = useState("");
  const [clientEmails, setClientEmails] = useState(null);
  const [pcEmails, setPcEmails] = useState(null);
  const [employerEmails, setEmployerEmails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [clientPages, setClientPages] = useState(0);
  const [pcPages, setPcPages] = useState(0);
  const [employerPages, setEmployerPages] = useState(0);
  const [jobSector, setJobSector] = useState("");
  const [aggregatedDate, setAggregatedDate] = useState();

  const callback = (key) => {
    // console.log(key);
    if (potentialClients.length == 0 && key == "2") {
      getPcEmail();
    } else if (employers.length == 0 && key == "3") {
      getEmpEmail();
    }
    setHandleKey(key);
  };

  useEffect(() => {
    getClientEmail();
  }, []);

  const getClientEmail = (pageData) => {
    let data = {
      pageSize: pageData && pageData.pageSize ? pageData.pageSize : pageSize,
      pageNumber:
        pageData && pageData.pageNumber ? pageData.pageNumber : pageNumber,
      visaCountryId:
        pageData && (pageData.visaCountryId || pageData.visaCountryId === 0)
          ? pageData.visaCountryId
          : visaCountry || 0,
      tagId:
        pageData && (pageData.tagId || pageData.tagId === 0)
          ? pageData.tagId
          : clientTag
          ? clientTag
          : 0,
      processingPersonId:
        pageData && pageData.processingPersonId
          ? pageData.processingPersonId
          : processingPerValue
          ? processingPerValue
          : "00000000-0000-0000-0000-000000000000",
      passportCountryId:
        pageData && (pageData.passportCountry || pageData.passportCountry === 0)
          ? pageData.passportCountry
          : passportCountryVal
          ? passportCountryVal
          : 0,
      clientStatus:
        pageData && (pageData.clientStatus || pageData.clientStatus === "")
          ? pageData.clientStatus
          : clientStatus,
      visaTypeStatus:
        pageData && (pageData.visaTypeStatus || pageData.visaTypeStatus === "")
          ? pageData.visaTypeStatus
          : visaStatus,
      visaType:
        pageData && (pageData.visaType || pageData.visaType === 0)
          ? pageData.visaType
          : visaType
          ? visaType
          : 0,
    };
    setLoading(true);
    const getDataOpt = {
      url: `v1/email/ClientBulkEmail`,
    };

    getDataOpt.types = [
      "GET_CLIENT_BULK_EMAIL_SUCCESS",
      "GET_CLIENT_BULK_EMAIL_FAILURE",
    ];
    apiRefresh
      .post(getDataOpt, data)
      .then((res) => {
        setLoading(false);

        res.clientEmail.map((data, index) => {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        });
        setClients(res.clientEmail);
        setClientPages(res.count);
      })
      .catch((err) => {
        setLoading(false);
        setClientPages(0);
        setClients([]);
      });
  };

  const getPcEmail = (pageData) => {
    let data = {
      pageSize: pageData && pageData.pageSize ? pageData.pageSize : pageSize,
      pageNumber:
        pageData && pageData.pageNumber ? pageData.pageNumber : pageNumber,
      tagId:
        pageData && (pageData.pcTags || pageData.pcTags === 0)
          ? pageData.pcTags
          : pcClientTag
          ? pcClientTag
          : 0,
      clientStatus:
        pageData && (pageData.clientStatus || pageData.clientStatus === "")
          ? pageData.clientStatus
          : pcStatusValue,
      intrestedVisa:
        pageData && (pageData.intrestedVisa || pageData.intrestedVisa === 0)
          ? pageData.intrestedVisa
          : pcVisaType,
    };
    setLoading(true);
    const getPcEmailOpt = {
      url: `v1/email/PotentialClientBulkEmail`,
    };

    getPcEmailOpt.types = [
      "GET_PC_BULK_EMAIL_SUCCESS",
      "GET_PC_BULK_EMAIL_FAILURE",
    ];
    apiRefresh
      .post(getPcEmailOpt, data)
      .then((res) => {
        setLoading(false);
        res.potentialClientEmail.map((data, index) => {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        });
        setPotentialClients(res.potentialClientEmail);
        setPcPages(res.potentialClientCount);
        setPotentialClientStatus(
          (res.getPotentialClientStatus &&
            res.getPotentialClientStatus.items) ||
            []
        );
      })
      .catch((err) => {
        setLoading(false);
        setPotentialClients([]);
        setPcPages(0);
      });
  };

  const getEmpEmail = (pageData) => {
    let data = {
      pageSize: pageData && pageData.pageSize ? pageData.pageSize : pageSize,
      pageNumber:
        pageData && pageData.pageNumber ? pageData.pageNumber : pageNumber,
      tagId:
        pageData && (pageData.tagId || pageData.tagId === 0)
          ? pageData.tagId
          : clientTag
          ? clientTag
          : 0,
      jobSector:
        pageData && (pageData.jobSector || pageData.jobSector === 0)
          ? pageData.jobSector
            ? pageData.jobSector.toString()
            : ""
          : jobSector.toString(),
      processingPersonId:
        pageData && pageData.processingPersonId
          ? pageData.processingPersonId
          : processingPerValue
          ? processingPerValue
          : "00000000-0000-0000-0000-000000000000",
      aggregateddate:
        pageData && pageData.aggregatedDate
          ? pageData.aggregatedDate
          : aggregatedDate
          ? aggregatedDate
          : "1900-01-01T00:00:00+00",
    };
    setLoading(true);
    const getEmpEmailOpt = {
      url: `v1/email/EmployerBulkEmail`,
    };

    getEmpEmailOpt.types = [
      "GET_EMPLOYER_BULK_EMAIL_SUCCESS",
      "GET_EMPLOYER_BULK_EMAIL_FAILURE",
    ];
    apiRefresh
      .post(getEmpEmailOpt, data)
      .then((res) => {
        setLoading(false);
        res.employerEmail.map((data, index) => {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        });
        setEmployers(res.employerEmail);
        setEmployerPages(res.count);
      })
      .catch((err) => {
        setLoading(false);
        setEmployers([]);
        setEmployerPages(0);
      });
  };

  useEffect(() => {
    setProcessingPersons([] || []);
  }, [userData]);

  useEffect(() => {
    setClientTags((clientTagRes && clientTagRes.items) || []);
  }, [clientTagRes]);

  useEffect(() => {
    setVisaStatuses((visaStatusData && visaStatusData.items) || []);
  }, [visaStatusData]);

  useEffect(() => {
    setVisaTypes((visaTypeData && visaTypeData.items) || []);
  }, [visaTypeData]);

  const clientColumns = [
    {
      title: "Name",
      dataIndex: "fullName",
      // defaultSortOrder: "descend",
      ellipsis: true,
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.fullName}
            </span>
          </div>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "index",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.email}</span>
          </div>
        );
      },
    },
    {
      title: "Passport Country",
      dataIndex: "passportCountry",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.passportCountry}
            </span>
          </div>
        );
      },
    },
    {
      title: "Visa Type, Visa Status",
      dataIndex: "visaTypeStatus",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.visaTypeStatus}
            </span>
          </div>
        );
      },
    },
    {
      title: "Client Tags",
      dataIndex: "tags",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.tags}</span>
          </div>
        );
      },
    },
    {
      title: "Processing Person",
      dataIndex: "processingPerson",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.processingPerson}
            </span>
          </div>
        );
      },
    },
  ];
  const potentialClientColumns = [
    {
      title: "Name",
      dataIndex: "fullName",
      // defaultSortOrder: "descend",
      ellipsis: true,
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.fullName}
            </span>
          </div>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "index",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.email}</span>
          </div>
        );
      },
    },
    {
      title: "Client Status",
      dataIndex: "clientStatus",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.clientStatus}
            </span>
          </div>
        );
      },
    },
  ];
  const employerColumns = [
    {
      title: "Name",
      dataIndex: "fullName",
      // defaultSortOrder: "descend",
      ellipsis: true,
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.fullName}
            </span>
          </div>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "index",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.email}</span>
          </div>
        );
      },
    },
    {
      title: "Client Status",
      dataIndex: "contactPerson",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.contactPerson}
            </span>
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRowKeys(selectedRows);
      setClientRows(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection1 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRowKeys(selectedRows);
      setPotClientRows(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection2 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      // setSelectedRowKeys(selectedRows);
      setEmployerRows(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const onChangeVisaCountry = (value) => {
    setVisaCountry(value);
  };

  const onChangeClientStatus = (value) => {
    setClientStatus(value);
  };

  const onChangeVisaType = (value) => {
    var findValue = visaTypes.find((obj) => obj.id == value);

    setVisaType(value);
  };

  const onChangePcVisaType = (value) => {
    var findValue = visaTypes.find((obj) => obj.id == value);

    setPcVisaType(findValue.visaTypeName);
  };

  const onChangeVisaStatus = (value) => {
    var findValue = visaStatuses.find((obj) => obj.id == value);

    setVisaStatus(findValue.name);
  };

  const onChangeClientTag = (value) => {
    var findValue = clientTags.find((obj) => obj.id == value);

    setClientTag(value);
  };

  const onChangeJobSector = (value) => {
    var findValue = JobSectors.find((obj) => obj.id == value);

    setJobSector(findValue.label);
  };

  const onChangePcClientTag = (value) => {
    var findValue = clientTags.find((obj) => obj.id == value);

    setPcClientTag(value);
  };

  const onChangeProcessingPerson = (value) => {
    var findValue = processingPersons.find((obj) => obj.id == value);

    setProcessingPerValue(value);
  };

  const onChangeAggregatedDate = (value) => {
    setAggregatedDate(value);
  };

  const onChangePassportCountry = (value) => {
    var findValue =
      countriesData && countriesData.items.find((obj) => obj.id == value);

    setPassportCountryVal(value);
  };

  const onChangePCStatus = (value) => {
    var findValue = potentialClientStatus.find((obj) => obj.id == value);

    setPcStatusValue(findValue.name);
    // setPotentialClients(filter);
    getPcEmail();
  };

  const clearState = () => {
    setVisaCountry("");
    setClientStatus("");
    setVisaType("");
    setVisaStatus("");
    setClientTag("");
    setProcessingPerValue("");
    setPassportCountryVal("");
    setPcStatusValue("");
    setPcVisaType("");
    setPcClientTag("");
    setAggregatedDate();
    setJobSector("");

    let data = {
      pageNumber: 1,
      pageSize: 100,
      visaCountryId: 0,
      tagId: 0,
      processingPersonId: "00000000-0000-0000-0000-000000000000",
      passportCountry: 0,
      clientStatus: "",
      visaTypeStatus: "",
      visaType: 0,
      intrestedVisa: "",
      jobSector: "",
      aggregatedDate: "1900-01-01T00:00:00+00",
      pcTags: 0,
    };
    getClientEmail(data);
    getPcEmail(data);
    getEmpEmail(data);
    allUsers &&
      allUsers.clientEmail.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        data.chosen = false;
      });
    setClients(allUsers.clientEmail || []);
  };

  const paginate = (value) => {
    setPageSize(value.pageSize);
    setPageNumber(value.current);
    let data = {
      pageSize: value.pageSize,
      pageNumber: value.current,
      tagId: clientTag,
      jobSector: jobSector,
      processingPersonId: processingPerValue,
      aggregateddate: aggregatedDate,
      visaCountryId: 0,
      passportCountry: 0,
      clientStatus: pcStatusValue,
      visaTypeStatus: visaStatus,
      visaType: visaType,
      pcVisaType: pcVisaType,
      intrestedVisa: "",
      pcTags: pcClientTag,
    };
    if (handleKey == "1") {
      getClientEmail(data);
    } else if (handleKey == "2") {
      getPcEmail(data);
    } else if (handleKey == "3") {
      getEmpEmail(data);
    }
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div style={{ display: "flex", margin: 10 }}>
          <div style={{ width: "100%" }}>
            <div
              className={"employerin-box employer-manag-tabs"}
              style={{
                margin: 10,
                marginTop: 0,
                marginLeft: 0,
              }}
            >
              <div className="bg-white ">
                <Tabs
                  activeKey={handleKey}
                  type="card"
                  size={"small"}
                  className="mar-r employer-doc-tab"
                  onChange={(key) => callback(key)}
                >
                  <TabPane tab="Client" key="1">
                    <div
                      style={{
                        border: "2px solid #c9c9ca",
                        padding: "20px",
                        backgroundColor: "#f0f2f5b8",
                      }}
                    >
                      <Form
                        // onFinish={this.onFinish}
                        // ref={this.formRef}
                        style={{
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              name="clientNumber"
                              colon={false}
                            >
                              <Select
                                placeholder="All"
                                showSearch
                                optionFilterProp="children"
                                value={visaCountry}
                                onChange={(e) => onChangeVisaCountry(e)}
                              >
                                <Option value="">Select Visa Country</Option>
                                {countriesData &&
                                  countriesData.items
                                    .filter(
                                      (obj) =>
                                        obj.name.toLowerCase() ===
                                          "new zealand" ||
                                        obj.name.toLowerCase() ===
                                          "australia" ||
                                        obj.name.toLowerCase() === "canada"
                                    )
                                    .map((data) => {
                                      // eslint-disable-next-line react/jsx-no-undef
                                      return (
                                        <Option value={data.id}>
                                          {data.name}
                                        </Option>
                                      );
                                    })}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              colon={false}
                            >
                              <Select
                                placeholder="Select Client Status"
                                showSearch
                                optionFilterProp="children"
                                value={clientStatus}
                                onChange={(e) => onChangeClientStatus(e)}
                              >
                                <Option value="">Select Client Status</Option>
                                <Option value="True">Active</Option>
                                <Option value="False">InActive</Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              colon={false}
                            >
                              <Select
                                placeholder="Select Visa Type"
                                showSearch
                                optionFilterProp="children"
                                value={visaType}
                                onChange={(e) => onChangeVisaType(e)}
                              >
                                <Option value={0}>Select Visa Type</Option>
                                {visaTypes &&
                                  visaTypes.map((visaType, ind) => (
                                    <Option value={visaType.id}>
                                      {visaType.visaTypeName}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              colon={false}
                            >
                              <Select
                                placeholder="Select Visa Status"
                                showSearch
                                optionFilterProp="children"
                                value={visaStatus}
                                onChange={(e) => onChangeVisaStatus(e)}
                              >
                                <Option value="">Select Visa Status</Option>
                                {visaStatuses &&
                                  visaStatuses.map((visaStatus, ind) => (
                                    <Option value={visaStatus.id}>
                                      {visaStatus.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              colon={false}
                            >
                              <Select
                                placeholder="Select Client Tag"
                                showSearch
                                optionFilterProp="children"
                                value={clientTag}
                                onChange={(e) => onChangeClientTag(e)}
                              >
                                <Option value={0}>Select Client Tags</Option>
                                {clientTags &&
                                  clientTags.map((tag, ind) => (
                                    <Option value={tag.id}>{tag.name}</Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item
                              className="margin-top-0"
                              style={{ paddingBottom: 0 }}
                              colon={false}
                            >
                              <Select
                                placeholder="Processing Persons"
                                showSearch
                                optionFilterProp="children"
                                value={processingPerValue}
                                onChange={(e) => onChangeProcessingPerson(e)}
                              >
                                <Option value="">
                                  Select Processing Person
                                </Option>
                                {usersList &&
                                  usersList.map((user, ind) => (
                                    <Option value={user.id}>
                                      {user.fullName}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="client-form" style={{ width: "30%" }}>
                            <Form.Item className="margin-top-0" colon={false}>
                              <Select
                                placeholder="Passport Country"
                                showSearch
                                optionFilterProp="children"
                                value={passportCountryVal}
                                onChange={(e) => onChangePassportCountry(e)}
                              >
                                <Option value="">
                                  Select Passport Country
                                </Option>
                                {countriesData &&
                                  countriesData.items &&
                                  countriesData.items.map((country, ind) => (
                                    <Option value={country.id}>
                                      {country.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div style={{ width: "30%" }}></div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingTop: 18,
                            }}
                          >
                            <Button
                              style={{ marginRight: 10 }}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={getClientEmail}
                            >
                              Search
                            </Button>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={clearState}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </Form>
                      <Table
                        className="border-3"
                        rowSelection={rowSelection}
                        showCount={true}
                        columns={clientColumns}
                        dataSource={clients}
                        onChange={paginate}
                        pagination={{
                          defaultPageSize: 200,
                          total: clientPages,
                          showSizeChanger: true,
                          pageSizeOptions: ["100", "200", "300", "500", "1000"],
                        }}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Potential Client" key="2">
                    {handleKey === "1" || handleKey === "3" ? null : (
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <Form
                          // onFinish={this.onFinish}
                          // ref={this.formRef}
                          style={{
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item className="margin-top-0" colon={false}>
                                <Select
                                  placeholder="Potential Client Status"
                                  onChange={(e) => onChangePCStatus(e)}
                                >
                                  <Option value="">
                                    Potential Client Status
                                  </Option>
                                  {potentialClientStatus &&
                                    potentialClientStatus.map((status, ind) => (
                                      <Option value={status.id}>
                                        {status.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                style={{ paddingBottom: 0 }}
                                colon={false}
                              >
                                <Select
                                  placeholder="Select Visa Type"
                                  showSearch
                                  optionFilterProp="children"
                                  value={pcVisaType}
                                  onChange={(e) => onChangePcVisaType(e)}
                                >
                                  <Option value="">Select Visa Type</Option>
                                  {visaTypes &&
                                    visaTypes.map((visaType, ind) => (
                                      <Option value={visaType.id}>
                                        {visaType.visaTypeName}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>{" "}
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                style={{ paddingBottom: 0 }}
                                colon={false}
                              >
                                <Select
                                  placeholder="Select Client Tag"
                                  showSearch
                                  optionFilterProp="children"
                                  value={pcClientTag}
                                  onChange={(e) => onChangePcClientTag(e)}
                                >
                                  <Option value="">Select Client Tags</Option>
                                  {clientTags &&
                                    clientTags.map((tag, ind) => (
                                      <Option value={tag.id}>{tag.name}</Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div
                            style={{
                              // width: "30%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{ marginRight: 10 }}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={getPcEmail}
                            >
                              Search
                            </Button>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={clearState}
                            >
                              Clear
                            </Button>
                          </div>
                        </Form>
                        <Table
                          className="border-3"
                          rowSelection={rowSelection1}
                          showCount={true}
                          columns={potentialClientColumns}
                          dataSource={potentialClients}
                          onChange={paginate}
                          pagination={{
                            defaultPageSize: 200,
                            total: pcPages,
                            showSizeChanger: true,
                            pageSizeOptions: [
                              "100",
                              "200",
                              "300",
                              "500",
                              "1000",
                            ],
                          }}
                        />
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab="Employer" key="3">
                    {handleKey === "1" || handleKey === "2" ? null : (
                      <div
                        style={{
                          border: "2px solid #c9c9ca",
                          padding: "20px",
                          backgroundColor: "#f0f2f5b8",
                        }}
                      >
                        <Form
                          // onFinish={this.onFinish}
                          // ref={this.formRef}
                          style={{
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                style={{ paddingBottom: 0 }}
                                colon={false}
                              >
                                <Select
                                  placeholder="Select Client Tags"
                                  showSearch
                                  optionFilterProp="children"
                                  value={clientTag}
                                  onChange={(e) => onChangeClientTag(e)}
                                >
                                  <Option value={0}>Select Client Tags</Option>
                                  {clientTags &&
                                    clientTags.map((tag, ind) => (
                                      <Option value={tag.id}>{tag.name}</Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                style={{ paddingBottom: 0 }}
                                colon={false}
                              >
                                <Select
                                  placeholder="Select Job Sector"
                                  showSearch
                                  optionFilterProp="children"
                                  value={jobSector}
                                  onChange={(e) => onChangeJobSector(e)}
                                >
                                  <Option value={""}>Select Job Sector</Option>
                                  {JobSectors.map((data, ind) => (
                                    <Option value={data.id}>
                                      {data.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className="client-form"
                              style={{ width: "30%" }}
                            >
                              <Form.Item
                                className="margin-top-0"
                                style={{ paddingBottom: 0 }}
                                colon={false}
                              >
                                <Select
                                  placeholder="Processing Persons"
                                  showSearch
                                  optionFilterProp="children"
                                  value={processingPerValue}
                                  onChange={(e) => onChangeProcessingPerson(e)}
                                >
                                  <Option value="">
                                    Select Processing Person
                                  </Option>
                                  {usersList &&
                                    usersList.map((user, ind) => (
                                      <Option value={user.id}>
                                        {user.fullName}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "30%" }}>
                              <DatePicker
                                placeholder="Select Aggregated Date"
                                format={"DD/MM/YYYY"}
                                value={aggregatedDate}
                                onChange={onChangeAggregatedDate}
                              />
                            </div>
                            <div
                              style={{
                                width: "30%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Form.Item>
                                <Button
                                  style={{ marginRight: 10 }}
                                  type="primary"
                                  className="login-form-button save-btn button-blue"
                                  onClick={getEmpEmail}
                                >
                                  Search
                                </Button>
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="login-form-button save-btn button-blue"
                                  onClick={clearState}
                                >
                                  Clear
                                </Button>
                              </Form.Item>
                            </div>
                          </div>
                        </Form>
                        <Table
                          className="border-3"
                          rowSelection={rowSelection2}
                          showCount={true}
                          columns={employerColumns}
                          dataSource={employers}
                          onChange={paginate}
                          pagination={{
                            defaultPageSize: 200,
                            total: employerPages,
                            showSizeChanger: true,
                            pageSizeOptions: [
                              "100",
                              "200",
                              "300",
                              "500",
                              "1000",
                            ],
                          }}
                        />
                      </div>
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default FilterPopup;
