import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentSettings from "../AccountSetting/UserLevelSettings/DocumentSettings";

import { getUserDetail, setActiveInnerTab } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailReducer.userDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserDetail: bindActionCreators(getUserDetail, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSettings);
