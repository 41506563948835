import React, { useState, Fragment, useEffect } from "react";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import EmailContentForm from "./EmailContentForm";
import { Spin, Button, Tooltip, Row, Col, Collapse } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { LeftCircleFilled } from "@ant-design/icons";
import EmailContentAddForm from "./EmailContentAddForm";
import { SystemGeneratedEmails } from "../../Common/SystemGeneratedEmails";
import { getFilterList } from "../../Common/getEmailContentList";

const { Panel } = Collapse;

const EmailContent = ({
  onGetEmailContent,
  emailContentRes,
  onAddEmailContent,
  addEmailContentRes,
  onDelEmailContent,
  onUpdateEmailContent,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [editorType, setEditorType] = useState("");
  const [modelData, setModelData] = useState([]);
  const [
    isVisibleAddEmailTemplatePopUp,
    setIsVisibleAddEmailTemplatePopUp,
  ] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetEmailContent()
      .then((res) => {
        let resultList = getFilterList(res);

        setModelData(resultList);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetEmailContent]);

  const emailTypeData = (type, id) => {
    setEditorType(type, id);
  };

  const getListingEmailContent = () => {
    onGetEmailContent()
      .then((res) => {
        let _resultList = getFilterList(res);
        setModelData(_resultList);
        setIsVisibleAddEmailTemplatePopUp(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancelAddEmailTempPopUp = () => {
    setIsVisibleAddEmailTemplatePopUp(false);
  };

  return (
    <div>
      <Spin size="large" spinning={loading}>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item client-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img
                      src={Images.emailContent}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span to="/client-tags" className="top-text">
                    Email Content
                  </span>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <Row>
                <Col xs={2} offset={21}>
                  <>
                    <img
                      src={Images.addIcon}
                      className="icons-client"
                      type="primary"
                      onClick={() => {
                        setIsVisibleAddEmailTemplatePopUp(true);
                      }}
                    />
                  </>
                </Col>
              </Row>
              {modelData &&
                modelData.map((data, index) => {
                  return (
                    <Row key={index}>
                      <Col xs={22} offset={1} style={{ marginTop: "4vh" }}>
                        <Collapse
                          className={"template"}
                          expandIconPosition={"right"}
                          defaultActiveKey={[index]}
                        >
                          <Panel
                            header={
                              <Row>
                                <Col xs={24}>
                                  <Row>
                                    <Col xs={23}>
                                      <h5
                                        style={{
                                          color: "black",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {data.emailType}
                                      </h5>
                                    </Col>
                                    <Col xs={1}>
                                      {data.emailType &&
                                      data.emailType ===
                                        "Invitation to www.checkmyvisa.io" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template automatically each time
                                              user gives access to their client
                                              to Client Portal. System will
                                              automatically pick client’s name,
                                              content, registration link and
                                              user’s signature. The below editor
                                              contains the CONTENT of this
                                              template, feel free to customize
                                              it and update it for future use.
                                              This email template will run using
                                              following flow:<br></br>
                                              <br></br>Hi (Client Name)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>
                                                CUSTOMISABLE CONTENT Auto
                                                generated Link for your client
                                                (Automatic)
                                              </b>
                                              <br></br>
                                              <br></br>Your signature
                                              (Automatic)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : data.emailType &&
                                        data.emailType === "Invoice Email" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template automatically each time
                                              user emails Invoice and receipt to
                                              its client. System will
                                              automatically pick client’s name,
                                              content and user’s signature. The
                                              below editor contains the CONTENT
                                              of this template. It contains
                                              dynamic variables so please do not
                                              delete it. However feel free to
                                              add further content and update it
                                              for future use. This email
                                              template will run using following
                                              flow:<br></br>
                                              <br></br> Hi (Client Name)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>CUSTOMISEABLE CONTENT </b>
                                              <br></br>
                                              <br></br>Your signature
                                              (Automatic)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : data.emailType &&
                                        data.emailType === "Notifications" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template automatically each time
                                              user sends an email using
                                              Ezymigrate email feature. System
                                              will automatically pick client’s
                                              name, content and user’s
                                              signature. The below editor
                                              contains the CONTENT of this
                                              template. Feel free to customize
                                              or delete content and update it
                                              for future use. This email
                                              template will run using following
                                              flow:<br></br>
                                              <br></br>Hi (Client Name)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>CUSTOMISEABLE CONTENT </b>
                                              <br></br>
                                              <br></br>Your signature
                                              (Automatic)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : data.emailType &&
                                        data.emailType ===
                                          "Update Visa Status Notification" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template automatically each time
                                              user UPDATES VISA status under
                                              client’s file VISA info. System
                                              will automatically pick client’s
                                              name, content, visa status and
                                              user’s signature. The below editor
                                              contains the CONTENT of this
                                              template. Feel free to customize
                                              the content and update it for
                                              future use. This email template
                                              will run using following flow:
                                              <br></br>
                                              <br></br>Hi (@ClientName)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>CUSTOMISEABLE CONTENT</b>
                                              <br></br>
                                              <br></br>Visa Status key
                                              (@VisaStatus) <br></br>
                                              <br></br>Your signature key
                                              (@DigitalSignature)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : data.emailType &&
                                        data.emailType ===
                                          "Questionnaire Link" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template each time user sends
                                              questionnaire link. System will
                                              automatically pick client’s name,
                                              content and user’s signature. The
                                              below editor contains the CONTENT
                                              of this template. Feel free to
                                              customize the content and update
                                              it for future use. This email
                                              template will run using following
                                              flow:<br></br>
                                              <br></br>Hi (Client Name)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>CUSTOMISEABLE CONTENT</b>
                                              <br></br>
                                              <br></br>Questionnaire Link
                                              (Automatic) <br></br>
                                              <br></br> Your signature
                                              (Automatic)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : data.emailType &&
                                        data.emailType ===
                                          "Invoice Follow-up" ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={
                                            <p style={{ color: "white" }}>
                                              Ezymigrate system will run this
                                              template each time user sends
                                              invoice follow-up. System will
                                              automatically pick client’s name,
                                              content and user’s signature. The
                                              below editor contains the CONTENT
                                              of this template. Feel free to
                                              customize the content and update
                                              it for future use. This email
                                              template will run using following
                                              flow:<br></br>
                                              <br></br>Hi (Client Name)
                                              (Automatic)<br></br>
                                              <br></br>
                                              <b>
                                                CUSTOMISEABLE CONTENT use key
                                                words like @InoviceNo ,
                                                @DueDate, @Amount, please make
                                                sure they are part of the
                                                invoice when you customize it
                                              </b>
                                              <br></br>
                                              <br></br>Your signature
                                              (Automatic)
                                            </p>
                                          }
                                        >
                                          <img
                                            className="ac-info-icon-email-temp"
                                            src={Images.info}
                                          />
                                        </Tooltip>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            }
                            key={index}
                          >
                            <EmailContentForm
                              setLoading={setLoading}
                              getListingEmailContent={getListingEmailContent}
                              onDelEmailContent={onDelEmailContent}
                              emailTypeData={emailTypeData}
                              data={data}
                              index={index}
                              editorType={editorType}
                              onAddEmailContent={onAddEmailContent}
                              onGetEmailContent={onGetEmailContent}
                              emailContentRes={emailContentRes}
                              onUpdateEmailContent={onUpdateEmailContent}
                            />
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </div>
          {isVisibleAddEmailTemplatePopUp ? (
            <EmailContentAddForm
              setIsVisibleAddEmailTemplatePopUp={
                setIsVisibleAddEmailTemplatePopUp
              }
              getListingEmailContent={getListingEmailContent}
              setLoading={setLoading}
              isVisibleAddEmailTemplatePopUp={isVisibleAddEmailTemplatePopUp}
              handleCancelAddEmailTempPopUp={handleCancelAddEmailTempPopUp}
            />
          ) : null}
        </div>
      </Spin>
    </div>
  );
};
export default EmailContent;
