// @flow
import React, { useState, useEffect } from "react";
import { Button, Select, Spin, message, Upload } from "antd";
import BranchUserCard from "./BranchUserCard";
import PermissionCard from "./PermissionCard";
import { apiRefresh } from "../../services/api";
import { PlusOutlined } from "@ant-design/icons";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function BranchCard(Props) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(Props.branch.branchName);
  const [managerName, setManagerName] = useState("");
  const [teamCount, setTeamCount] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [copySelectedUsers, setCopySelectedUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState([]);
  const [pushValues, setPushValues] = useState(true);
  const [imageUserId, setImageUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [branchEmail, setBranchEmail] = useState("");
  const [branchCountry, setBranchCountry] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const [branchAccountTitle, setBranchAccountTitle] = useState("");
  const [branchAccountNumber, setBranchAccountNumber] = useState("");
  const [branchGSTNumber, setBranchGSTNumber] = useState("");
  const [branchGSTPercent, setBranchGSTPercent] = useState("");
  const [branchCurrency, setBranchCurrency] = useState("");
  const [branchUsers, setBranchUsers] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [countriesArray, setCountriesArray] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");

  var selectedBranchId = localStorage.getItem("selectedBranchId");

  useEffect(() => {
    Props.onGetCountries().then((res) => {});
    Props.getAllCurrencies();
  }, []);

  useEffect(() => {
    setName(Props.branch.branchDetail.name);
    // setSelectedUsers(Props.branch.branchUser.length > 0 ? )
    setBranchEmail(Props.branch.branchDetail.email);
    setBranchCountry(Props.branch.branchDetail.country);
    setBranchAddress(Props.branch.branchDetail.address);
    setBranchPhone(Props.branch.branchDetail.phone1);
    setBranchAccountTitle(Props.branch.branchDetail.bankAccountTitle);
    setBranchAccountNumber(Props.branch.branchDetail.bankAccountNumber);
    setBranchGSTNumber(Props.branch.branchDetail.gstNumber);
    setBranchGSTPercent(Props.branch.branchDetail.gstPercent);
    setBranchCurrency(
      Props.branch.branchDetail.currency
        ? parseInt(Props.branch.branchDetail.currency)
        : null
    );
    setLogoUrl(Props.branch.branchDetail.logo);
    setDefaultCountry(
      Props.branch.branchDetail.defaultCountry != 0
        ? Props.branch.branchDetail.defaultCountry.toString()
        : ""
    );
  }, [Props.branch]);

  useEffect(() => {
    var country = [];
    if (Props.countriesData && Props.countriesData.items.length > 0) {
      for (let i = 0; i < Props.countriesData.items.length; i++) {
        country.push(
          <Option key={Props.countriesData.items[i].id}>
            {Props.countriesData.items[i].name}
          </Option>
        );
      }
      setCountriesArray(country);
    }
  }, [Props.countriesData]);

  const { Option } = Select;
  if (
    pushValues &&
    Props.branch.branchUser &&
    Props.branch.branchUser.length > 0
  ) {
    // for (var i = 0; i < Props.branch.branchUser.length; i++) {
    //   if (Props.branch.branchUser[i].status == 1) {
    //     var findIndex = selectedUsers.find(
    //       (obj) => obj === Props.branch.branchUser[i].userId
    //     );
    //     if (!findIndex) {
    //       selectedUsers.push(Props.branch.branchUser[i].userId);
    //     }
    //   }
    // }
    setCopySelectedUsers(selectedUsers);
    setTeamCount(selectedUsers.length);
    setDeleteUser(selectedUsers.concat(deleteUser));
    setPushValues(false);
  }

  const branch = [];
  let IsUserManager;
  if (localStorage.getItem("userManager") === "true") {
    IsUserManager = true;
  } else {
    IsUserManager = false;
  }
  if (Props.users && Props.users.users.length > 0) {
    for (let i = 0; i < Props.users.users.length; i++) {
      if (
        localStorage.getItem("userId") !== Props.users.users[i].id &&
        !(IsUserManager && Props.users.users[i].isOwner)
      ) {
        branch.push(
          <Option key={Props.users.users[i].id}>
            {Props.users.users[i].fullName}
          </Option>
        );
      } else if (IsUserManager && Props.users.users[i].isOwner) {
        //donothing
      } else {
      }
    }
  }

  const uploadCompanyLogo = (info) => {
    var size = info.size / 1024 / 1024 < 2;
    // if (id) {
    //   this.setState({ imageUpdateId: id });
    // }
    let formData = new FormData();
    formData.append("File", info);
    setLoading(true);
    if (size) {
      Props.onUploadAvatar(formData).then((res) => {
        setLoading(false);
        // if (Props.imageUploadSuccess) {
        setLogoUrl(res.payload);
        // }
      });
    } else {
      setLoading(false);
      setLogoUrl("");
      message.error("Image size should be less than 2 MB!");
    }
  };

  const onHandleChange = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {});
    }
  };

  const handleChangeUser = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
    setDeleteUser(copySelectedUsers);

    if (selectedUsers.length < copySelectedUsers.length) {
      for (var i = 0; i < selectedUsers.length; i++) {
        for (var j = 0; j < copySelectedUsers.length; j++) {
          if (selectedUsers[i] === copySelectedUsers[j]) {
            copySelectedUsers.splice(j, 1);
            break;
          }
        }
      }
      setLoading(true);
      var user = Props.users
        ? Props.users.users.filter((obj) => obj.id === copySelectedUsers[0])
        : null;

      let data = {
        branchId: Props.branch.branchId,
        status: 0,
        userId: copySelectedUsers[0],
        branchDesignation: user[0].title,
      };
      Props.onUnassignUser(data)
        .then((res) => {
          setLoading(false);
          setCopySelectedUsers(selectedUsers);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      var user = Props.users
        ? Props.users.users.filter(
            (obj) => obj.id === selectedUsers[selectedUsers.length - 1]
          )
        : null;

      let data = {
        branchId: Props.branch.branchId,
        status: 1,
        userId: selectedUsers[selectedUsers.length - 1],
        branchDesignation: user[0].title,
      };
      setLoading(true);
      Props.onAssignUser(data)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleChangeCountry = (val) => {
    setDefaultCountry(val);
  };

  const onUploadImage = (info, id) => {
    setImageUserId(id);
    Props.uploadImage(info, id);
  };

  function updateBranch() {
    var userId = localStorage.getItem("userId");
    var companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (name.length < 3) {
      message.warning(
        "Please enter branch name with atleast three characters!"
      );
      return;
    } else if (!emailRegex.test(branchEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
      return;
    }
    if (!branchCountry || branchCountry.length < 3) {
      message.error(
        "Country should not be empty or not less than three characters!"
      );
      return;
    }
    let data = {
      id: Props.branch.branchId,
      companyId: companyId,
      name: name,
      description: "",
      address: branchAddress,
      addressLine1: "",
      addressLine2: "",
      bankAccountTitle: branchAccountTitle,
      bankAccountNumber: branchAccountNumber,
      city: Props.branch.city,
      country: branchCountry,
      zipcode: "",
      ownerId: 0,
      phone1: branchPhone,
      phone2: "",
      mobile: "",
      fax: "",
      email: branchEmail,
      gstNumber: branchGSTNumber,
      gstPercent: branchGSTPercent,
      logo: logoUrl,
      status: "",
      currency: branchCurrency && branchCurrency.toString(),
      allowedUsers: 0,
      allowSupervisor: true,
      contractSignedDate: "2020-09-30T07:44:07.134Z",
      contractExpiryDate: "2020-09-30T07:44:07.134Z",
      contractUrl: "",
      storageLimit: 0,
      notes: "",
      defaultCountry: parseInt(defaultCountry),
      // managerId: Props.branch.managerId,
    };
    if (name.length > 1) Props.parentMethod(data);
    else message.error("Enter branch name!");
  }

  const options = [{ value: "branch", label: "Branch Name" }];

  const getUpdatedScreenOnSave = (value) => {
    setShow(true);
    setLoading(true);
    const branchUserOpt = {
      url: `v1/user/Branch/users/${Props.branch.branchId}`,
    };
    branchUserOpt.types = [
      "GET_BRANCH_USER_SUCCESS",
      "GET_BRANCH_USER_FAILURE",
    ];
    let IsUserManager;
    if (localStorage.getItem("userManager") === "true") {
      IsUserManager = true;
    } else {
      IsUserManager = false;
    }
    apiRefresh
      .get(branchUserOpt)
      .then((res) => {
        let users = [];
        let branchUser = [];
        if (res.length > 0) {
          for (var i = 0; i < res.length; i++) {
            if (
              localStorage.getItem("userId") !== res[i].userId &&
              !(IsUserManager && res[i].user.isOwner)
            ) {
              users.push(res[i].userId);
            } else if (IsUserManager && res[i].user.isOwner) {
              //donothing
            } else {
            }

            branchUser.push(res[i]);
          }
          setSelectedUsers(users);
          setTeamCount(users.length);
          setCopySelectedUsers(users);
          setBranchUsers(branchUser);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <div className={"spinner"}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div
            onClick={getUpdatedScreenOnSave}
            className="sus-bottom-btn-cont"
            style={{ marginLeft: 0, cursor: "pointer" }}
          >
            <div
              className="sus-bottom-icon-cont"
              style={{
                backgroundColor: "#FFFFFF",
                padding: 12,
                borderRadius: 5,
              }}
            >
              <span className="sus-bn-logo">BN</span>
            </div>
            <span className="sus-bottom-text">{Props.branch.branchName}</span>
            {Props.branch.branchId == selectedBranchId && (
              <span
                className="sus-bottom-text"
                style={{ color: "#1885bb", fontSize: 12 }}
              >
                (Default Branch)
              </span>
            )}
          </div>
          <div style={{ marginTop: 20, width: "100%" }}>
            {show && (
              <div
                className="sus-member-card-cont"
                style={
                  {
                    // paddingBottom: Props.branch.branchUser.length > 0 ? 20 : 0,
                  }
                }
              >
                <div
                  className="form-cont"
                  style={{
                    justifyContent: "space-between",
                    paddingTop: 20,
                    paddingTop: 5,
                    paddingBottom: 0,
                    display: "block",
                    // width: "97%",
                    // marginLeft: -30,
                    // marginRight: -30,
                  }}
                >
                  <div
                    onClick={() => setShow(false)}
                    className="sus-branch-hide-cont"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="sus-branch-hide-text">HIDE</span>
                  </div>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={onHandleChange}
                    action={uploadCompanyLogo}
                  >
                    {logoUrl ? (
                      <img
                        src={logoUrl}
                        alt="avatar"
                        style={{
                          width: 105,
                          height: 105,
                        }}
                      />
                    ) : (
                      <PlusOutlined />
                    )}
                  </Upload>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Branch Name*
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder="Branch Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Branch Manager
                  </p>
                  <div
                    className="profile-input-border"
                    style={{ width: "55%" }}
                  >
                    <input
                      className="profile-input"
                      placeholder="Branch Manager"
                      type="text"
                      value={managerName}
                      onChange={(e) => setManagerName(e.target.value)}
                    />
                  </div>
                </div> */}

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Branch Email*:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchEmail}
                        onChange={(e) => setBranchEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Country*:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchCountry}
                        onChange={(e) => setBranchCountry(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Address:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchAddress}
                        onChange={(e) => setBranchAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Phone:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchPhone}
                        onChange={(e) => setBranchPhone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Bank Account Title:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchAccountTitle}
                        onChange={(e) => setBranchAccountTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Bank Account Number:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchAccountNumber}
                        onChange={(e) => setBranchAccountNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      GST Number:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchGSTNumber}
                        onChange={(e) => setBranchGSTNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      GST Percentage:
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="number"
                        step="any"
                        value={branchGSTPercent}
                        onChange={(e) => setBranchGSTPercent(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Default Currency:
                    </p>
                    <div
                      // className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <Select
                        // disabled={true}
                        placeholder="Please Select Currency!"
                        size="small"
                        value={branchCurrency}
                        onChange={(e) => {
                          setBranchCurrency(e);
                        }}
                      >
                        {Props &&
                          Props.currencyListing &&
                          Props.currencyListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option value={data.id}>
                                {data.currencyName}
                              </Option>
                            );
                          })}
                      </Select>
                      {/* <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={branchCurrency}
                        onChange={(e) => setBranchCurrency(e.target.value)}
                      /> */}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Team Member Count
                    </p>
                    <div
                      className="profile-input-border"
                      style={{ width: "55%" }}
                    >
                      <input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={teamCount}
                        onChange={(e) => console.log(e)}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Default Country
                    </p>
                    <div
                      className="select-border multi-select-option"
                      style={{ marginTop: 0, width: "55%" }}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        bordered={false}
                        placeholder="Country"
                        value={defaultCountry}
                        onChange={handleChangeCountry}
                        style={{ width: "100%" }}
                      >
                        {countriesArray}
                      </Select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="medical-label" style={{ fontSize: 11 }}>
                      Assign Team Member
                    </p>
                    <div
                      className="select-border multi-select-option"
                      style={{ marginTop: 0, width: "55%" }}
                    >
                      <Select
                        mode="multiple"
                        bordered={false}
                        placeholder="Assign User"
                        value={selectedUsers}
                        onChange={handleChangeUser}
                        style={{ width: "100%" }}
                      >
                        {branch}
                      </Select>
                    </div>
                  </div>

                  <div style={{ display: "flex", paddingBottom: 30 }}>
                    <Button
                      onClick={() => updateBranch()}
                      type="primary"
                      className="sus-add-btn"
                      style={{ marginTop: 20 }}
                    >
                      SAVE
                    </Button>
                  </div>

                  {branchUsers &&
                    branchUsers.map((item, index) => {
                      var imageUrl = "";
                      // if (item.userId === imageUserId) {
                      //   imageUrl = Props.imageUrl;
                      // }
                      return (
                        // <BranchUserCard
                        //   user={item}
                        //   onDisable={Props.onDisable}
                        //   onUpdate={(userData, isManager) =>
                        //     Props.onUpdate(userData, isManager, item)
                        //   }
                        //   uploadImage={onUploadImage}
                        //   imageUrl={imageUrl}
                        //   onGetBranch={Props.onGetBranch}
                        // />
                        <PermissionCard
                          getUpdatedScreenOnSave={getUpdatedScreenOnSave}
                          user={item}
                          onUpdateUserPermission={Props.onUpdateUserPermission}
                          onUpdateManager={Props.onUpdateManager}
                          onGetTeamMember={Props.onGetTeamMember}
                          onUploadAvatar={Props.onUploadAvatar}
                          // parentMethod={addUserPermissons}
                          // updateUser={this.updateCompanyUserData}
                          // uploadImage={this.uploadImage}
                          imageUrl={imageUrl}
                          branchData={Props.branchData}
                          onAssignBranch={Props.onAssignBranch}
                          onUnassignBranch={Props.onUnassignBranch}
                          onDisable={Props.onDisable}
                          onChangeUsersPassword={Props.onChangeUsersPassword}
                          // passwordUpdateMethod={this.updateUsersPassword}
                        />
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default BranchCard;
