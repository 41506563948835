import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeTrackingMain from "../TimeTracking";

import {
  getServiceType,
  getVisaTypePrice,
  getVisaWorkType,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaWorkTypeRes: state.timeTrackingReducer.visaWorkTypeRes,
    visaTypePriceRes: state.timeTrackingReducer.visaTypePriceRes,
    serviceTypeRes: state.timeTrackingReducer.serviceTypeRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetVisaWorkType: bindActionCreators(getVisaWorkType, dispatch),
    onGetVisaTypePrice: bindActionCreators(getVisaTypePrice, dispatch),
    onGetServiceType: bindActionCreators(getServiceType, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrackingMain);
