import * as types from "../Constants";

export const clientAdmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CLIENT_ADMISSION_SUCCESS:
      return { ...state, clientAdmissionSuccess: action.payload };
    case types.CLIENT_ADMISSION_FAILURE:
      return { ...state, clientAdmissionError: action.payload };

    case types.GET_ADMISSION_PROGRAM_SUCCESS:
      return { ...state, admissionProgramData: action.payload };
    case types.GET_ADMISSION_PROGRAM_FAILURE:
      return { ...state, admissionProgramError: action.payload };

    case types.DELETE_ADMISSION_PROGRAM_SUCCESS:
      return { ...state, removeProgramSuccess: action.payload };
    case types.DELETE_ADMISSION_PROGRAM_FAILURE:
      return { ...state, removeProgramError: action.payload };

    case types.GET_SCHOOL_TYPES_SUCCESS:
      return { ...state, schoolTypesData: action.payload };
    case types.GET_SCHOOL_TYPES_FAILURE:
      return { ...state, schoolTypesError: action.payload };

    case types.GET_SCHOOLS_SUCCESS:
      return { ...state, schoolsData: action.payload };
    case types.GET_SCHOOLS_FAILURE:
      return { ...state, schoolsError: action.payload };

    case types.GET_SCHOOL_LEVEL_SUCCESS:
      return { ...state, schoolLevelData: action.payload };
    case types.GET_SCHOOL_LEVEL_FAILURE:
      return { ...state, schoolLevelError: action.payload };

    case types.ADD_COMISSION_FORM_SUCCESS:
      return { ...state, comissionFormSuccess: action.payload };
    case types.ADD_COMISSION_FORM_FAILURE:
      return { ...state, comissionFormFailure: action.payload };

    case types.GET_COMISSION_PAYMENT_SUCCESS:
      return { ...state, comissionPaymentData: action.payload };
    case types.GET_COMISSION_PAYMENT_FAILURE:
      return { ...state, comissionPaymentError: action.payload };

    case types.GET_ADMISSION_STATUS_SUCCESS:
      return { ...state, admissionStatusData: action.payload };
    case types.GET_ADMISSION_STATUS_FAILURE:
      return { ...state, admissionStatusError: action.payload };

    case types.UPD_ADMISSION_FORM_SUCCESS:
      return { ...state, admissionUpdSuccess: action.payload };
    case types.UPD_ADMISSION_FORM_FAILURE:
      return { ...state, admissionUpdError: action.payload };

    case types.UPD_ADMISSION_STATUS_SUCCESS:
      return { ...state, updAdmissionStatusSuccess: action.payload };
    case types.UPD_ADMISSION_STATUS_FAILURE:
      return { ...state, updAdmissionStatusError: action.payload };

    case types.ADD_ADMISSION_REMINDER_SUCCESS:
      return { ...state, addAdmissionReminderSuccess: action.payload };
    case types.ADD_ADMISSION_REMINDER_FAILURE:
      return { ...state, addAdmissionReminderError: action.payload };

    default:
      return state;
  }
};
