import * as types from "../Constants";

export const branchReducer = (state = {}, action) => {
  switch (action.type) {
    case types.BRANCH_SUCCESS:
      return { ...state, branchData: action.payload };
    case types.BRANCH_FAILURE:
      return {
        ...state,
        branchError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error"
      };
    case types.ADD_BRANCH_SUCCESS:
      return { ...state, addBranchSuccess: action.payload };
    case types.ADD_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_BRANCH_SUCCESS:
      return { ...state, updBranchSuccess: action.payload };
    case types.UPDATE_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.USER_BRANCH_SUCCESS:
      return { ...state, userBranchData: action.payload };
    case types.USER_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_BRANCH_SELECTED:
      return { ...state, selectedBranch: action.payload };
    default:
      return state;
  }
};
