import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Employer from "../Clients/Employer";

import {
  getEmployerManag,
  getClientEmployer,
  addClientEmployer,
  updClientEmployer,
  removeClientEmployer,
  getEmployerJob,
  linkEmployer,
  addEmployerJobHistory,
  onGetClientJobStatuses,
  getClientJobHistory,
  getClientJobHistoryCurrent,
  setActiveKey,
  updatePriority,
  signedVisa,
  paidVisa,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  getVisaType,
  getVisaTypeByCountry,
  getAdmissionStatuses,
  getAdmissionProgram,
  getReminderTasks,
  getClientProfile,
  setProfileData,
  getPartnerData,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    employerManagRes: state.employerManagReducer.employerManagRes,
    clientEmployerRes: state.clientEmployerReducer.clientEmployerRes,
    employerJobHistoryRes:
      state.EmployerJobHistoryReducer.employerJobHistoryRes,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    clientTab: state.employerManagReducer.clientTab,
    activeKey: state.employerManagReducer.activeKey,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
    onGetClientEmployer: bindActionCreators(getClientEmployer, dispatch),
    onAddClientEmployer: bindActionCreators(addClientEmployer, dispatch),
    onUpdClientEmployer: bindActionCreators(updClientEmployer, dispatch),
    onRemoveClientEmployer: bindActionCreators(removeClientEmployer, dispatch),
    onGetEmployerJob: bindActionCreators(getEmployerJob, dispatch),
    onLinkEmployer: bindActionCreators(linkEmployer, dispatch),
    onAddEmployerJobHistory: bindActionCreators(
      addEmployerJobHistory,
      dispatch
    ),
    onGetClientJobStatuses: bindActionCreators(
      onGetClientJobStatuses,
      dispatch
    ),
    onGetClientJobHistory: bindActionCreators(getClientJobHistory, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employer);
