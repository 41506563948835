import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignatureSettings from "../AccountSetting/UserLevelSettings/SignatureSettings/SignatureSettings";

import {
  getSignature,
  setActiveInnerTab,
  updateSignature,
  uploadAvatar,
  uploadAvatarWithFile,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    updateSignatureRes: state.userLevelSettingsReducer.updateSignatureRes,
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSignature: bindActionCreators(getSignature, dispatch),
    onUpdateSignature: bindActionCreators(updateSignature, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onUploadAvatarWithFile: bindActionCreators(uploadAvatarWithFile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatureSettings);
