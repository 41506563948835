import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignUp from "./../Components/SignUpForm";

import {
  addSignUpForm,
  getCountries,
  getTextEncoding,
  uploadFile,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    singUpFormRes: state.signUpReducer.singUpFormRes,
    textEncodingRes: state.signUpReducer.textEncodingRes,
    addUploadFileRes: state.employerManagReducer.addUploadFileRes,
    countriesData: state.visaTypeReducer.countriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddSignUpForm: bindActionCreators(addSignUpForm, dispatch),
    onGetTextEncoding: bindActionCreators(getTextEncoding, dispatch),
    onUploadFile: bindActionCreators(uploadFile, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
