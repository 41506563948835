import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const signUpReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_SIGN_UP_SUCCESS:
      return { ...state, addSignUpRes: action.payload };
    case types.ADD_SIGN_UP_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_TEXT_ENCODING_SUCCESS:
      return { ...state, textEncodingRes: action.payload };
    case types.GET_TEXT_ENCODING_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_SIGN_FORM_SUCCESS:
      return { ...state, singUpFormRes: action.payload };
    case types.ADD_SIGN_FORM_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
