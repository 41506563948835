import React, { Fragment, useEffect, useState } from "react";
import { Images } from "../../Themes";
import CommentModal from "../SideArea/CommentModal";
import { Link, useHistory } from "react-router-dom";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import { Tooltip } from "antd";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const UpcommingTaskToDo = ({
  data,
  index,

  showModal,
  onGetReminderTasks,
  reminderTaskRes,

  onGetDailyTasks,
  dailyTaskRes,

  upcommingTasks,

  removeTask,

  id,

  ComletedTasks,
  getTasksComments,
  addTaskComment,
  getTaskFollowers,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  onRemoveFollower,
  onRemoveTaskClient,
  onSearchClient,
  onSearchPotentialClient,
  reloadFlag,
  setReload,
  onAddFileNote,
  onGetEmployerData,
}) => {
  const [showComment, setShowComment] = useState(false);
  const history = useHistory();
  const CommentSection = (value) => {
    setShowComment(value);
  };
  const CommentClose = () => {
    setShowComment(false);
  };

  const openProfile = () => {
    if (data.clientName && data.clientName != "") {
      onGetClientProfile(data.subjectId).then((res) => {
        let clientData = res.payload;
        openClientFile(
          clientData.id,
          clientData.clientId,
          clientData.memberType,
          clientData.familyId
        );
      });
    } else if (data.potentialClientName && data.potentialClientName != "") {
      onGetPotentialClient(data.subjectId).then((res) => {
        onGetUpdateBol(false);
        history.push("/update-potential-client");
      });
    } else if (data.employerName && data.employerName != "") {
      onGetEmployerData(data.subjectId).then((res) => {
        onGetUpdateBol(false);

        history.push({
          pathname: "/employer-management",
          state: { userData: res.payload },
        });
      });
    } else return;
  };

  const openClientFile = (id, clientId, memberType, familyId) => {
    localStorage.setItem("clientProfileIdMain", id);
    localStorage.setItem("familyId", familyId);
    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };

  return (
    <Fragment>
      <div>
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 29,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div>
              <input
                onChange={() => ComletedTasks(data)}
                type="checkbox"
                className="sus-checkbox"
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "75%" }}
            >
              <div
                onClick={() => {
                  CommentSection(!showComment);
                }}
                className="cv-normal-text"
                style={{ cursor: "pointer" }}
              >
                {data && data.taskTitle} (
                {yyyymmddFormate(data && data.taskDate)})
              </div>
              {data.potentialClientName != "" ? (
                data.potentialClientName ? (
                  <div
                    className="marked-name"
                    style={{
                      cursor: "pointer",
                      width: "auto",
                    }}
                    onClick={() => openProfile()}
                  >
                    <span className="marked-name-font">
                      {data.potentialClientName}
                    </span>
                  </div>
                ) : null
              ) : data.clientName ? (
                <div
                  className="marked-name"
                  style={{
                    cursor: "pointer",
                    width: "auto",
                  }}
                  onClick={() => openProfile()}
                >
                  <span className="marked-name-font">{data.clientName}</span>
                </div>
              ) : data.employerName ? (
                <div
                  className="marked-name"
                  style={{
                    cursor: "pointer",
                    width: "auto",
                  }}
                  onClick={() => openProfile()}
                >
                  <span className="marked-name-font">{data.employerName}</span>
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ display: "flex", position: "absolute", right: 36 }}>
            <div className="mdtr-user-cont">
              <img
                src={Images.userCircleGray}
                style={{ width: 15, height: 15 }}
                onClick={() => showModal("add-follower", data.id, data)}
              />
              <img
                src={Images.nextGray}
                style={{
                  marginLeft: 5,
                  transform: `rotate(90deg)`,
                  width: 12,
                  height: 12,
                }}
                onClick={() => showModal("add-follower", data.id, data)}
              />
            </div>
            <Tooltip placement="topLeft" title={`Calendar`}>
              <div
                className="mdtr-user-cont"
                style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
              >
                <img
                  onClick={() => showModal("for-update-date", data.id, data)}
                  src={Images.calendarBlue}
                  style={{ width: 15, height: 15 }}
                />
              </div>
            </Tooltip>
            <Tooltip placement="topLeft" title={`Update`}>
              <div
                className="mdtr-user-cont"
                style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
              >
                <img
                  onClick={() => showModal("for-update", data.id, data)}
                  src={Images.fileNotes}
                  style={{ width: 15, height: 15 }}
                />
              </div>
            </Tooltip>
            <Tooltip placement="topLeft" title={`Delete`}>
              <div
                className="mdtr-user-cont"
                style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
              >
                <img
                  onClick={() => showModal("confirm-delete", data.id)}
                  src={Images.deleteIcon}
                  style={{ width: 15, height: 15 }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {showComment === true && (
        <CommentModal
          CommentClose={CommentClose}
          getTasksComments={getTasksComments}
          dailyTaskRes={dailyTaskRes}
          data={data}
          addTaskComment={addTaskComment}
          getTaskFollowers={getTaskFollowers}
          onRemoveFollower={onRemoveFollower}
          onRemoveTaskClient={onRemoveTaskClient}
          onGetDailyTasks={onGetDailyTasks}
          onSearchClient={onSearchClient}
          onSearchPotentialClient={onSearchPotentialClient}
          reloadFlag={reloadFlag}
          setReload={setReload}
          onAddFileNote={onAddFileNote}
        />
      )}
    </Fragment>
  );
};

export default UpcommingTaskToDo;
