import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";

const Container = styled.div`
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 18px;
  background-color: ${props => (props.isDragging ? "#e9ecee" : "white")};
`;

export default class Task extends React.Component {
  render() {
    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <Row>
              <Col xs={6}>
                <UserOutlined />
              </Col>
              <Col xs={18}>
                <h5 style={{ textAlign: "left", color: "#1179af" }}>
                  {"Sushana Smith"}
                </h5>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={11} offset={1}>
                <span style={{ fontWeight: "500" }}>{"Contact Date"}</span>
              </Col>
              <Col xs={11} offset={1}>
                <span style={{ fontSize: "10px" }}>{"02/02/2021"}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={11} offset={1}>
                <span style={{ fontWeight: "500" }}>{"Mobile No"}</span>
              </Col>
              <Col xs={11} offset={1}>
                <span style={{ fontSize: "10px" }}>{"0327635788"}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={11} offset={1}>
                <span style={{ fontWeight: "500" }}>{"Email"}</span>
              </Col>
              <Col xs={11} offset={1}>
                <span style={{ fontSize: "10px", wordWrap: "break-word" }}>
                  {"lorem@gmail.com"}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={11} offset={1}>
                <span style={{ fontWeight: "500" }}>{"Worth"}</span>
              </Col>
              <Col xs={11} offset={1}>
                <span style={{ fontSize: "10px", wordWrap: "break-word" }}>
                  {"50 $"}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={11} offset={1}>
                <span style={{ fontWeight: "500" }}>{"Worth"}</span>
              </Col>
              <Col xs={11} offset={1}>
                <span style={{ fontSize: "10px", wordWrap: "break-word" }}>
                  {"50 $"}
                </span>
              </Col>
            </Row>
          </Container>
        )}
      </Draggable>
    );
  }
}
