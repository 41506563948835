// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
} from "antd";

import OptionalQuestion from "./OptionalQuestion";
import Section from "./Section";

import {
  MinusCircleOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { apiRefresh } from "../../services/api";

function QuestionCard(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [fieldName, setFieldName] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [answerType, setAnswerType] = useState("1");
  const [optionText, setOptionText] = useState("");
  const [options, setOptions] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [multiCheckboxOption, setMultiCheckbox] = useState("");
  const [radioOption, setRadioOption] = useState("");
  const [isCascading, setCascading] = useState(false);
  const [isMultiple, SetMultiple] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [sectionOptions, setSectionOptions] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [mappedChild, setMappedChild] = useState([]);
  const [isOptionSectionAdd, setIsOptionSectionAdd] = useState(false);
  const [addOptionalSection, setAddOptionalSection] = useState(false);
  const [propertyDisable, setPropertyDisable] = useState(false);
  const [questionString, setQuestionString] = useState("");
  const [searchString, setSearchString] = useState("");
  const [parentId, setParentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [mappChildValue, setMappChildValue] = useState("");
  const [childType, setChildType] = useState("");
  const [answerTypeOptions, setAnswerTypeOptions] = useState([]);
  const [typesArray, setTypesArray] = useState([]);

  useEffect(() => {
    setFieldName(Props.field.name);
    setFieldKey(Props.field.fieldKey);
    // if(Props.mainQuestionIndex) {
    //   setMainQuestionIndex(Props.mainQuestionIndex)
    // }
  }, [Props]);

  useEffect(() => {
    var answerTypes = [
      { value: "1", label: "Textbox" },
      { value: "2", label: "Date" },
      { value: "3", label: "Drop down list" },
      { value: "4", label: "Radio button" },
      { value: "5", label: "Checkbox" },
      { value: "6", label: "Month" },
      { value: "7", label: "Textarea" },
      { value: "8", label: "File" },
    ];
    var types = [];
    for (var i = 0; i < answerTypes.length; i++) {
      types.push(
        <Option value={answerTypes[i].value}>{answerTypes[i].label}</Option>
      );
    }
    setTypesArray(answerTypes);
    setAnswerTypeOptions(types);
  }, []);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  // if(radioOption === "") {
  //
  // const sectionOptions = [];
  // }
  //

  const onChangeAnswerType = (value) => {
    setAnswerType(value);
  };

  const onChangeRadio = (e) => {
    console.log("radio1 checked", e.target.value);
    setRadioOption(e.target.value);
    setAddOptionalSection(false);
  };

  const onChangeOptionCheckbox = (value) => {
    console.log("radio1 checked", value);
    setMultiCheckbox(value);
  };

  const onChangeCheckbox = (value) => {
    console.log("checkbox checked", value);
    setCheckboxOptions(value);
    setAddOptionalSection(false);
    var checkCascading = value.find((obj) => obj === "cascading");
    if (checkCascading) {
      setCascading(true);
    } else {
      setCascading(false);
    }
    var checkMultiple = value.find((obj) => obj === "multiAnswers");
    if (checkMultiple) {
      SetMultiple(true);
    } else {
      SetMultiple(false);
    }
  };

  const onChangeCheckboxOption = (value) => {
    setCheckboxValue(value);
    setAddOptionalSection(false);
  };

  const plainOptions = ["Apple", "Pear", "Orange"];

  if (options.length <= 0) {
    var optionsArray = [];
  }

  const addRadioOptions = () => {
    if (optionText == "") {
      return false;
    } else {
      console.log(Props.field, Props.section);
      setOptions(options.concat(optionText));
      Props.addOptionValues({
        optionName: optionText,
        isOptional: Props.optionalSection,
        mainSection: Props.mainSectionIndex,
        mainQuestion: Props.mainQuestionIndex,
        questionIndex: Props.optQuestionIndex,
        sectionIndex: Props.optSectionIndex,
        isSubOptional: Props.subOptionalSection,
        subOptSectionIndex: Props.subOptSectionIndex,
        subOptQuestionIndex: Props.subOptQuestionIndex,
      });
      // setOptionText("");
      // const questionOptionValue = Props.formRef.current.getFieldsValue();
      // const { sections } = questionOptionValue;
      //
      // console.log(
      //   "=============",
      //   questionOptionValue,
      //   sections[Props.section.key]
      // );
    }
  };

  const addCheckboxOptions = () => {
    if (optionText == "") {
      return false;
    } else {
      setOptions(options.concat(optionText));
      Props.addCheckboxValues({
        optionName: optionText,
        isOptional: Props.optionalSection,
        mainSection: Props.mainSectionIndex,
        mainQuestion: Props.mainQuestionIndex,
        questionIndex: Props.optQuestionIndex,
        sectionIndex: Props.optSectionIndex,
        isSubOptional: Props.subOptionalSection,
        subOptSectionIndex: Props.subOptSectionIndex,
        subOptQuestionIndex: Props.subOptQuestionIndex,
      });
      setOptionText("");
    }
  };

  const addDropdownOptions = () => {
    if (optionText == "") {
      return false;
    } else {
      setOptions(options.concat(optionText));
      Props.addDropdownValues({
        optionName: optionText,
        isOptional: Props.optionalSection,
        mainSection: Props.mainSectionIndex,
        mainQuestion: Props.mainQuestionIndex,
        questionIndex: Props.optQuestionIndex,
        sectionIndex: Props.optSectionIndex,
        isSubOptional: Props.subOptionalSection,
        subOptSectionIndex: Props.subOptSectionIndex,
        subOptQuestionIndex: Props.subOptQuestionIndex,
      });
      setOptionText("");
    }
  };
  console.log("test opt log", options);

  const dropdownOptions = [];

  for (var i = 0; i < options.length; i++) {
    dropdownOptions.push(<Option key={options[i]}>{options[i]}</Option>);
  }

  var mappParentData = [];
  var mappChildData = [];
  if (Props.mappParentData && Props.mappParentData.length > 0) {
    for (var i = 0; i < Props.mappParentData.length; i++) {
      mappParentData.push(
        <Option key={JSON.stringify(Props.mappParentData[i])}>
          {Props.mappParentData[i].name}
        </Option>
      );
    }
  }

  // if (Props.mappChildData && Props.mappChildData.length > 0) {
  //   for (var i = 0; i < Props.mappChildData.length; i++) {
  //     mappChildData.push(
  //       <Option key={Props.mappChildData[i].id}>
  //         {Props.mappChildData[i].name}
  //       </Option>
  //     );
  //
  //   }
  // }

  const onChangeMappingParent = (value) => {
    setPropertyDisable(true);
    setMappedChild([]);
    console.log("on mapping parent", value);
    var parentObj = JSON.parse(value);
    var parent = Props.mappParentData.find(
      (obj) => obj.name === parentObj.name
    );
    setParentId(parent.id);
    if (parent.id == "37" || parent.id == "38" || parent.id == "39") {
      onGetBranchOtherInfo(parent.id);
    } else {
      Props.onGetMappingProperty(parent.id).then((res) => {
        console.log(res);
        setMappedChild(res.payload);
        setPropertyDisable(false);
      });
    }
  };

  if (mappedChild.length > 0) {
    if (parentId == "37" || parentId == "38" || parentId == "39") {
      for (var i = 0; i < mappedChild.length; i++) {
        mappChildData.push(
          <Option key={JSON.stringify(mappedChild[i])}>
            {mappedChild[i].friendlyName}
          </Option>
        );
      }
    } else {
      for (var i = 0; i < mappedChild.length; i++) {
        mappChildData.push(
          <Option key={JSON.stringify(mappedChild[i])}>
            {mappedChild[i].name}
          </Option>
        );
      }
    }
  }

  const onGetBranchOtherInfo = (parentid) => {
    const branchOtherInfoOpt = {
      url: `v1/branch/BranchOtherInfo`,
    };
    branchOtherInfoOpt.types = [
      "GET_BRANCH_OTHER_INFO_SUCCESS",
      "GET_BRANCH_OTHER_INFO_FAILURE",
    ];

    apiRefresh
      .get(branchOtherInfoOpt)
      .then((res) => {
        var findFamily = res.items.filter((obj) => obj.family == true);

        var findPartner = res.items.filter((obj) => obj.partner == true);
        var findClient = res.items.filter(
          (obj) => obj.partner == false && obj.family == false
        );
        if (parentid == "38" && findFamily && findFamily.length > 0) {
          // setFamilyChild(findFamily)

          setMappedChild(findFamily);
          setPropertyDisable(false);
        } else if (parentid == "39" && findPartner && findPartner.length > 0) {
          // setClientPartnerChild(findPartner);

          setMappedChild(findPartner);
          setPropertyDisable(false);
        } else if (parentid == "37" && findClient && findClient.length > 0) {
          // setClientChild(findClient)

          setMappedChild(findClient);
          setPropertyDisable(false);
        } else {
          setPropertyDisable(false);
        }
      })
      .catch((err) => {});
  };

  const selectDropdown = (value) => {
    setOptionSelected(value);
    setAddOptionalSection(false);
  };

  const onAddBranchOtherInfo = () => {
    let data = {
      type: answerType,
      child: false,
      columnName: questionString,
      family: parentId == "38" ? true : false,
      friendlyName: searchString,
      partner: parentId == "39" ? true : false,
    };

    if (
      (questionString != "" || questionString) &&
      (searchString || searchString != "")
    ) {
      setLoading(true);
      const branchOtherInfoOpt = {
        url: `v1/branch/BranchOtherInfo`,
      };
      branchOtherInfoOpt.types = [
        "ADD_BRANCH_OTHER_INFO_SUCCESS",
        "ADD_BRANCH_OTHER_INFO_FAILURE",
      ];

      apiRefresh
        .post(branchOtherInfoOpt, data)
        .then((res) => {
          message.success("Added!");
          mappParentData = [];
          setPropertyDisable(true);
          onGetBranchOtherInfo(parentId);
          setLoading(false);
          setSearchString("");
        })
        .catch((err) => {
          message.error("Failed!");
          setLoading(false);
        });
    } else {
      message.warning("Please enter question name and other info option!");
    }
  };

  const onChangeMappedChildValue = (val) => {
    var findChild = mappedChild.find((obj) => obj.name == val);
    var findChildOpt = mappedChild.find((obj) => obj.name == mappChildValue);

    if (parentId != "37" && parentId != "38" && parentId != "39") {
      let propertyData = {
        id: findChild.id,
        name: findChild.name,
      };
      var findValue = null;
      if (Props.mappPropertiesArray.length > 0) {
        if (findChildOpt) {
          findValue = Props.mappPropertiesArray.find(
            (obj) => obj.id == findChildOpt.id
          );
        }

        if (findValue) {
          if (mappChildValue == findValue.name) {
            setTimeout(() => {
              Props.onMappArrayChange("remove", findValue);
            }, 500);

            Props.onMappArrayChange("add", propertyData);
          } else {
            Props.onMappArrayChange("add", propertyData);
          }
        } else {
          Props.onMappArrayChange("add", propertyData);
        }
      } else {
        Props.onMappArrayChange("add", propertyData);
      }

      if (findChild) {
        setChildType(findChild.type);
        var types = [];
        for (var i = 0; i < typesArray.length; i++) {
          if (findChild.type.toLowerCase() == "text") {
            if (
              typesArray[i].value == "1" ||
              typesArray[i].value == "3" ||
              typesArray[i].value == "7"
            ) {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
          } else if (findChild.type.toLowerCase() == "radio") {
            if (typesArray[i].value == "4" || typesArray[i].value == "5") {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
            setAnswerType("4");
          } else if (findChild.type.toLowerCase() == "date") {
            if (typesArray[i].value == "2" || typesArray[i].value == "6") {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
            setAnswerType("2");
          } else if (typesArray[i].value.toLowerCase() != "file") {
            if (typesArray[i].value == "3") {
              types.push(
                <Option value={typesArray[i].value}>
                  {typesArray[i].label}
                </Option>
              );
            }
            setAnswerType("3");
          }
        }
        setAnswerTypeOptions(types);
      }
    }
    setMappChildValue(val);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div
          className="cq-form-cont"
          style={{
            width: "100%",
            marginLeft: 0,
            marginTop: 20,
            padding: 30,
            backgroundColor: "#EEF9FF",
          }}
        >
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <div
              style={{
                cursor: "pointer",
                justifyContent: "flex-end",
                display: "flex",
              }}
              onClick={() => {
                // if (!isOptionSectionAdd) {
                // var findQuestionOptionsIndex = Props.optionsArray.findIndex(
                //   (obj) =>
                //     obj.mainSection === Props.mainSectionIndex &&
                //     obj.mainQuestion === Props.mainQuestionIndex &&
                //     obj.sectionIndex === Props.optSectionIndex &&
                //     obj.questionIndex === Props.optQuestionIndex
                // );
                // var findQuestionOptions = Props.optionsArray.filter(
                //   (obj) =>
                //     obj.mainSection === Props.mainSectionIndex &&
                //     obj.mainQuestion === Props.mainQuestionIndex &&
                //     obj.sectionIndex === Props.optSectionIndex &&
                //     obj.questionIndex === Props.optQuestionIndex
                // );
                // if (findQuestionOptionsIndex > -1) {
                //   // Delete options selected questions from main option's array
                //   Props.optionsArray.splice(
                //     findQuestionOptionsIndex,
                //     findQuestionOptions.length
                //   );
                // }

                // Remove index from questions array
                Props.questionRemove(Props.optQuestionIndex);
                // console.log(findQuestionOptions);

                // Delete index from array with check question is text or not

                Props.questionsArray.splice(Props.optQuestionIndex, 1);
                // } else {
                //   message.warning(
                //     "Please delete dependent sections and questions first!"
                //   );
                // }
              }}
            >
              <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
            </div>
          </div>

          <div className="question-field-row">
            <div style={{ display: "flex" }}>
              <div
                onClick={() => {
                  Props.questionArrayMove(
                    Props.questionsArray,
                    Props.index,
                    Props.index - 1
                  );
                  Props.move(Props.index, Props.index - 1);
                }}
                className="section-arrow-icons"
              >
                <UpOutlined />
              </div>

              <div
                onClick={() => {
                  Props.questionArrayMove(
                    Props.questionsArray,
                    Props.index,
                    Props.index + 1
                  );
                  Props.move(Props.index, Props.index + 1);
                }}
                className="section-arrow-icons"
              >
                <DownOutlined />
              </div>
              <p className="cq-label-text">Question</p>
            </div>
            <div style={{ width: "60%" }}>
              <Form.Item
                {...Props.field}
                name={[fieldName, "question"]}
                fieldKey={[fieldKey, "question"]}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setQuestionString(e.target.value)}
                  className="profile-input profile-input-border"
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <Form.Item
              {...Props.field}
              name={[fieldName, "checkedOptions"]}
              fieldKey={[fieldKey, "checkedOptions"]}
              valuePropName="checked"
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={onChangeCheckbox}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    width: "68%",
                    marginLeft: 50,
                  }}
                >
                  <div className="cq-checkbox-width">
                    <Checkbox value="required" className="sus-checkbox-label">
                      Required
                    </Checkbox>
                  </div>
                  {!isMultiple ? (
                    <div className="cq-checkbox-width">
                      <Checkbox
                        value="cascading"
                        className="sus-checkbox-label"
                      >
                        cascading
                      </Checkbox>
                    </div>
                  ) : (
                    <div className="cq-checkbox-width">
                      <Checkbox
                        disabled
                        value="cascading"
                        className="sus-checkbox-label"
                      >
                        cascading
                      </Checkbox>
                    </div>
                  )}
                  {!isCascading ? (
                    <div className="cq-checkbox-width">
                      <Checkbox
                        value="multiAnswers"
                        className="sus-checkbox-label"
                      >
                        Multiple Answers
                      </Checkbox>
                    </div>
                  ) : (
                    <div className="cq-checkbox-width">
                      <Checkbox
                        disabled
                        value="multiAnswers"
                        className="sus-checkbox-label"
                      >
                        Multiple Answers
                      </Checkbox>
                    </div>
                  )}
                </div>
              </Checkbox.Group>
            </Form.Item>
          </div>

          {answerType != "8" && (
            <div className="question-field-row" style={{ marginTop: 30 }}>
              <p className="cq-label-text">Mapping Parent</p>
              <div style={{ width: "60%", paddingLeft: 1 }}>
                <div>
                  <Form.Item
                    {...Props.field}
                    name={[fieldName, "mappingParent"]}
                    fieldKey={[fieldKey, "mappingParent"]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={onChangeMappingParent}
                    >
                      <Option value="">Select Mapping Parent</Option>
                      {mappParentData}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {answerType != "8" && (
            <div className="question-field-row" style={{ marginTop: 30 }}>
              <p className="cq-label-text">Mapping Property</p>
              <div style={{ width: "60%", paddingLeft: 1, display: "flex" }}>
                <div
                  style={{
                    width:
                      parentId == "37" || parentId == "38" || parentId == "39"
                        ? `calc(100% - 134px)`
                        : "100%",
                  }}
                >
                  <Form.Item
                    {...Props.field}
                    name={[fieldName, "mappingProperty"]}
                    fieldKey={[fieldKey, "mappingProperty"]}
                    shouldUpdate={(prevValues, curValues) => {
                      // prevValues.additional !== curValues.additional;
                      // return false;
                    }}
                  >
                    {/* {({ getFieldValue }) => {
                      var value = getFieldValue("mappingParent");
                       
                      return (
                        <>
                          {getFieldValue("mappingParent") === "Client" ? (
                            <Form.Item
                              name="customizeGender"
                              label="Customize Gender"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          ) : (
                            <span>no filed</span>
                          )}
                        </>
                      );
                    }} */}
                    <Select
                      showSearch
                      disabled={propertyDisable}
                      optionFilterProp="children"
                      onChange={onChangeMappedChildValue}
                      onSearch={(e) => {
                        if (e != "") setSearchString(e);
                      }}
                      autoClearSearchValue={false}
                      // dropdownRender={(e) => console.log("render", e)}
                    >
                      <Option value="">Select Mapping Child</Option>
                      {mappChildData}
                    </Select>
                  </Form.Item>
                </div>
                {(parentId == "37" || parentId == "38" || parentId == "39") && (
                  <Button onClick={() => onAddBranchOtherInfo()}>
                    <span className="cq-add-btn">Add Other Info</span>
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="question-field-row" style={{ marginTop: 30 }}>
            <p className="cq-label-text">Answer Type</p>
            <div style={{ width: "60%", paddingLeft: 1 }}>
              <div>
                <Form.Item
                  {...Props.field}
                  name={[fieldName, "answerType"]}
                  fieldKey={[fieldKey, "answerType"]}
                  className="answer-type-ques"
                >
                  <Select
                    onChange={onChangeAnswerType}
                    defaultValue="1"
                    value={answerType}
                  >
                    {answerTypeOptions}
                  </Select>
                </Form.Item>
              </div>

              {answerType === "1" && (
                <div
                  className="profile-input-border"
                  style={{
                    width: "100%",
                    marginTop: 10,
                    paddingLeft: 7,
                  }}
                >
                  <Form.Item>
                    <Input className="profile-input" />
                  </Form.Item>
                </div>
              )}
              {answerType === "2" && (
                <div
                  className="profile-input-border"
                  style={{
                    border: 0,
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <Form.Item>
                    <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
                  </Form.Item>
                </div>
              )}
              {false && answerType === "3" && (
                <div>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <div
                      className="profile-input-border"
                      style={{
                        width: "100%",
                        paddingLeft: 7,
                      }}
                    >
                      <div>
                        <Input
                          value={optionText}
                          onChange={(e) => setOptionText(e.target.value)}
                          className="profile-input"
                        />
                      </div>
                    </div>
                    <div>
                      <Button onClick={() => addDropdownOptions()}>
                        <span className="cq-add-btn">ADD</span>
                      </Button>
                    </div>
                  </div>
                  <Select
                    style={{ marginTop: 10, marginLeft: 7 }}
                    onChange={selectDropdown}
                  >
                    {dropdownOptions}
                  </Select>
                </div>
              )}
              {answerType === "6" && (
                <div
                  className="profile-input-border"
                  style={{
                    border: 0,
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <Form.Item>
                    <DatePicker style={{ width: "100%" }} format="MM-YYYY" />
                  </Form.Item>
                </div>
              )}
              {answerType === "7" && (
                <div
                  style={{
                    border: 0,
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <Form.Item>
                    <textarea rows={5} className="cv-textarea" />
                  </Form.Item>
                </div>
              )}
              {answerType === "8" && (
                <div
                  className="profile-input-border"
                  style={{
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <Form.Item>
                    <Input type="file" className="profile-input" />
                  </Form.Item>
                </div>
              )}
              {false && answerType === "4" && (
                <div>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <div
                      className="profile-input-border"
                      style={{
                        width: "100%",
                        paddingLeft: 7,
                      }}
                    >
                      <div>
                        <Input
                          value={optionText}
                          onChange={(e) => setOptionText(e.target.value)}
                          className="profile-input"
                        />
                      </div>
                    </div>
                    <div>
                      <Button onClick={addRadioOptions}>
                        <span className="cq-add-btn">ADD</span>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {false && answerType === "5" && (
                <div>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <div
                      className="profile-input-border"
                      style={{
                        width: "100%",
                        marginLeft: 7,
                      }}
                    >
                      <div>
                        <Input
                          value={optionText}
                          onChange={(e) => setOptionText(e.target.value)}
                          className="profile-input"
                        />
                      </div>
                    </div>
                    <div>
                      <Button onClick={addCheckboxOptions}>
                        <span className="cq-add-btn">ADD</span>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Form.List name={[Props.field.name, "questionOptions"]}>
            {(questionOptions, { add, remove }, { errors }) => (
              <>
                {answerType === "3" && (
                  <div>
                    <div
                      className="question-field-row"
                      style={{ justifyContent: "flex-end", marginTop: 10 }}
                    >
                      <div
                        className="profile-input-border"
                        style={{
                          width: "50%",
                          marginRight: 5,
                        }}
                      >
                        <div>
                          <Input
                            value={optionText}
                            onChange={(e) => setOptionText(e.target.value)}
                            className="profile-input"
                          />
                        </div>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            addDropdownOptions();
                            if (optionText != "") {
                              add({ optionName: optionText });
                            }
                            setOptionText("");
                          }}
                        >
                          <span className="cq-add-btn">ADD</span>
                        </Button>
                      </div>
                    </div>
                    <div
                      className="question-field-row"
                      style={{ justifyContent: "flex-end", marginTop: 10 }}
                    >
                      <Select
                        style={{
                          marginTop: 10,
                          marginLeft: 7,
                          width: "60%",
                          paddingLeft: 2,
                        }}
                        onChange={selectDropdown}
                      >
                        {dropdownOptions}
                      </Select>
                    </div>
                  </div>
                )}
                {answerType === "4" && (
                  <>
                    <div>
                      <div
                        className="question-field-row"
                        style={{ justifyContent: "flex-end", marginTop: 10 }}
                      >
                        <div
                          className="profile-input-border"
                          style={{
                            width: "50%",
                            marginRight: 5,
                          }}
                        >
                          <div>
                            <Input
                              value={optionText}
                              onChange={(e) => setOptionText(e.target.value)}
                              className="profile-input"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              addRadioOptions();
                              if (optionText != "") {
                                add({ optionName: optionText });
                              }
                              setOptionText("");
                            }}
                          >
                            <span className="cq-add-btn">ADD</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="options-cont"
                      style={{ display: "flex", marginTop: 20 }}
                    >
                      <Radio.Group onChange={onChangeRadio} value={radioOption}>
                        {options.length > 0 &&
                          options.map((option, index) => {
                            return <Radio value={option}>{option}</Radio>;
                          })}
                      </Radio.Group>
                    </div>
                  </>
                )}
                {answerType === "5" && (
                  <>
                    <div>
                      <div
                        className="question-field-row"
                        style={{ justifyContent: "flex-end", marginTop: 10 }}
                      >
                        <div
                          className="profile-input-border"
                          style={{
                            width: "50%",
                            marginRight: 5,
                          }}
                        >
                          <div>
                            <Input
                              value={optionText}
                              onChange={(e) => setOptionText(e.target.value)}
                              className="profile-input"
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              addCheckboxOptions();
                              if (optionText != "") {
                                add({ optionName: optionText });
                              }
                              setOptionText("");
                            }}
                          >
                            <span className="cq-add-btn">ADD</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                      <Checkbox.Group onChange={onChangeCheckboxOption}>
                        {options.length > 0 &&
                          options.map((option, index) => {
                            return <Checkbox value={option}>{option}</Checkbox>;
                          })}
                      </Checkbox.Group>
                    </div>
                  </>
                )}
                {false && options.length > questionOptions.length
                  ? add({ optionName: optionText })
                  : null}

                {questionOptions.map((option, index) => {
                  return (
                    <>
                      <Form.List name={[option.name, "innerSection"]}>
                        {(innerSections, { add, remove, move }, { errors }) => {
                          if (
                            radioOption === options[index] ||
                            (checkboxValue.length > 0 &&
                              checkboxValue[checkboxValue.length - 1] ===
                                options[index]) ||
                            optionSelected === options[index]
                          ) {
                            if (
                              isOptionSectionAdd &&
                              innerSections.length < 1
                            ) {
                              setIsOptionSectionAdd(false);
                            }
                            if (isCascading) {
                              if (checkboxValue.length > 0) {
                                var findOptions = checkboxValue.find(
                                  (obj) => obj === options[index]
                                );
                              }
                              if (
                                !addOptionalSection &&
                                (radioOption ||
                                  (checkboxValue.length > 0 &&
                                    findOptions === options[index]) ||
                                  optionSelected === options[index])
                              ) {
                                add();
                                setAddOptionalSection(true);
                              }
                              // else if (
                              //   innerSections.length > 0 &&
                              //   !addOptionalSection
                              //   // (radioOption === options[index] ||
                              //   //   (checkboxValue.length > 0 &&
                              //   //     checkboxValue[checkboxValue.length - 1] ===
                              //   //       options[index]))
                              // ) {
                              //   // var findOption = sectionOptions.find(
                              //   //   (obj) =>
                              //   //     obj.optionName === radioOption.toUpperCase()
                              //   // );
                              //
                              //   // if (!findOption) {
                              //   setAddOptionalSection(true);
                              //   add();
                              //   // }
                              // }
                            }
                          }

                          return (
                            <>
                              {innerSections.map((section, secIndex) => {
                                // if (!isOptionSectionAdd) {
                                //
                                //   setIsOptionSectionAdd(true);
                                // }

                                console.log(innerSections.length, options);
                                var optionName = options[index].toUpperCase();
                                // if (
                                //   sectionOptions.length < innerSections.length
                                // ) {
                                //   if (secIndex === innerSections.length - 1) {
                                //     setSectionOptions(
                                //       {
                                //         optionName:
                                //           options[index],
                                //         // sectionInd: secIndex,
                                //         // mainQuestionIndex:
                                //         //   Props.mainQuestionIndex,
                                //         // mainSectionIndex: Props.mainSectionIndex,
                                //         // optSectionIndex: Props.optSectionIndex,
                                //         // optQuestionIndex: Props.optQuestionIndex,
                                //       }
                                //     );
                                //   }
                                // }

                                return (
                                  <Form.Item required={false} key={section.key}>
                                    <Section
                                      section={section}
                                      optSectionIndex={
                                        Props.optSectionIndex || secIndex
                                      }
                                      subOptsectionIndex={
                                        Props.optionalSection ? secIndex : null
                                      }
                                      optQuestionIndex={
                                        Props.optionalSection
                                          ? Props.optQuestionIndex
                                          : null
                                      }
                                      sectionIndex={secIndex}
                                      sectionMove={move}
                                      sectionOptions={optionName}
                                      options={options}
                                      addQuestion={addQuestion}
                                      addSectionOptions={
                                        Props.addSectionOptions
                                      }
                                      sectionRemove={remove}
                                      optionalSection={true}
                                      subOptionalSection={
                                        Props.optionalSection ? true : false
                                      }
                                      subOptSectionIndex={
                                        Props.optionalSection ? secIndex : null
                                      }
                                      addOptionValues={Props.addOptionValues}
                                      addCheckboxValues={
                                        Props.addCheckboxValues
                                      }
                                      addDropdownValues={
                                        Props.addCheckboxValues
                                      }
                                      mainSectionIndex={Props.mainSectionIndex}
                                      mainQuestionIndex={
                                        Props.mainQuestionIndex
                                      }
                                      optionsArray={Props.optionsArray}
                                      optionsSectionArray={
                                        Props.optionsSectionArray
                                      }
                                      removeOptionSection={
                                        Props.removeOptionSection
                                      }
                                      mappParentData={Props.mappParentData}
                                    />
                                  </Form.Item>
                                );
                              })}
                              {addQuestion && setAddQuestion(false)}
                              {false &&
                                isCascading &&
                                (radioOption ||
                                  checkboxValue.length > 0 ||
                                  optionSelected) && (
                                  <Form.Item>
                                    <div style={{ display: "flex" }}>
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        className="cq-orng-btn"
                                        style={{
                                          marginTop: 20,
                                          marginRight: 10,
                                        }}
                                      >
                                        <span className="cq-btn-text">
                                          Add Section
                                        </span>
                                      </Button>

                                      {/*======= Need to finalize======*/}

                                      <Button
                                        type="dashed"
                                        className="cq-orng-btn"
                                        style={{
                                          marginTop: 20,
                                          marginRight: 10,
                                        }}
                                      >
                                        <span className="cq-btn-text">
                                          Add Text
                                        </span>
                                      </Button>
                                      <Button
                                        type="dashed"
                                        className="cq-orng-btn"
                                        onClick={() => {
                                          setAddQuestion(true);
                                          add();
                                        }}
                                        style={{ marginTop: 20 }}
                                      >
                                        <span className="cq-btn-text">
                                          Add Quesstion(s)
                                        </span>
                                      </Button>
                                      <Form.ErrorList errors={errors} />
                                    </div>
                                  </Form.Item>
                                )}
                            </>
                          );
                        }}
                      </Form.List>
                    </>
                  );
                })}

                {/*{optionalQuestions.map((optionalQuestion, index) => (
            <Form.Item required={false} key={optionalQuestion.key}>
              <OptionalQuestion field={optionalQuestion} />
            </Form.Item>
          ))}*/}

                {/*<Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                type="dashed"
                className="cq-primary-btn"
                style={{ marginTop: 20, marginRight: 10 }}
              >
                Add Section
              </Button>
              <Button
                type="dashed"
                className="cq-primary-btn"
                style={{ marginTop: 20, marginRight: 10 }}
              >
                Add Text
              </Button>
              <Button
                onClick={() => add()}
                type="dashed"
                className="cq-primary-btn"
                style={{ marginTop: 20 }}
              >
                Add Quesstion(s)
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          </Form.Item>*/}
              </>
            )}
          </Form.List>

          {false && (
            <Form.List name={[Props.field.name, "innerSection"]}>
              {(innerSections, { add, remove }, { errors }) => {
                if (isOptionSectionAdd && innerSections.length < 1) {
                  setIsOptionSectionAdd(false);
                }
                if (isCascading) {
                  if (
                    innerSections.length === 0 &&
                    !addOptionalSection &&
                    (radioOption ||
                      checkboxValue.length === 1 ||
                      optionSelected)
                  ) {
                    add();
                    setAddOptionalSection(true);
                  } else if (innerSections.length > 0 && !addOptionalSection) {
                    var findOption = sectionOptions.find(
                      (obj) => obj.optionName === radioOption.toUpperCase()
                    );
                    if (!findOption) {
                      setAddOptionalSection(true);
                      add();
                    }
                  }
                }

                return (
                  <>
                    {innerSections.map((section, index) => {
                      setIsOptionSectionAdd(true);
                      console.log(innerSections.length, sectionOptions);
                      if (sectionOptions.length < innerSections.length) {
                        if (index === innerSections.length - 1) {
                          setSectionOptions(
                            sectionOptions.concat({
                              optionName:
                                checkboxValue.length > 0
                                  ? checkboxValue[
                                      checkboxValue.length - 1
                                    ].toUpperCase()
                                  : optionSelected
                                  ? optionSelected.toUpperCase()
                                  : radioOption.toUpperCase(),
                              sectionInd: index,
                              mainQuestionIndex: Props.mainQuestionIndex,
                              mainSectionIndex: Props.mainSectionIndex,
                              optSectionIndex: Props.optSectionIndex,
                              optQuestionIndex: Props.optQuestionIndex,
                            })
                          );
                        }
                      }

                      return (
                        <Form.Item required={false} key={section.key}>
                          <Section
                            section={section}
                            optSectionIndex={Props.optSectionIndex || index}
                            subOptsectionIndex={
                              Props.optionalSection ? index : null
                            }
                            optQuestionIndex={
                              Props.optionalSection
                                ? Props.optQuestionIndex
                                : null
                            }
                            sectionIndex={index}
                            sectionOptions={sectionOptions}
                            options={options}
                            addQuestion={addQuestion}
                            addSectionOptions={Props.addSectionOptions}
                            sectionRemove={remove}
                            optionalSection={true}
                            subOptionalSection={
                              Props.optionalSection ? true : false
                            }
                            subOptSectionIndex={
                              Props.optionalSection ? index : null
                            }
                            addOptionValues={Props.addOptionValues}
                            addCheckboxValues={Props.addCheckboxValues}
                            addDropdownValues={Props.addCheckboxValues}
                            mainSectionIndex={Props.mainSectionIndex}
                            mainQuestionIndex={Props.mainQuestionIndex}
                            optionsArray={Props.optionsArray}
                            optionsSectionArray={Props.optionsSectionArray}
                            removeOptionSection={Props.removeOptionSection}
                            mappPropertiesArray={Props.mappPropertiesArray}
                            onMappArrayChange={Props.onMappArrayChange}
                          />
                        </Form.Item>
                      );
                    })}
                    {addQuestion && setAddQuestion(false)}
                    {false &&
                      isCascading &&
                      (radioOption ||
                        checkboxValue.length > 0 ||
                        optionSelected) && (
                        <Form.Item>
                          <div style={{ display: "flex" }}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              className="cq-orng-btn"
                              style={{ marginTop: 20, marginRight: 10 }}
                            >
                              <span className="cq-btn-text">Add Section</span>
                            </Button>

                            {/*======= Need to finalize======*/}

                            <Button
                              type="dashed"
                              className="cq-orng-btn"
                              style={{ marginTop: 20, marginRight: 10 }}
                            >
                              <span className="cq-btn-text">Add Text</span>
                            </Button>
                            <Button
                              type="dashed"
                              className="cq-orng-btn"
                              onClick={() => {
                                setAddQuestion(true);
                                add();
                              }}
                              style={{ marginTop: 20 }}
                            >
                              <span className="cq-btn-text">
                                Add Quesstion(s)
                              </span>
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </div>
                        </Form.Item>
                      )}
                  </>
                );
              }}
            </Form.List>
          )}

          {/*<Form.List name={[Props.field.name, "optionalQuestions"]}>
          {(optionalQuestions, { add, remove }, { errors }) => (
            <>
              {optionalQuestions.map((optionalQuestion, index) => (
                <Form.Item required={false} key={optionalQuestion.key}>
                  <OptionalQuestion
                    field={optionalQuestion}
                    options={options}
                  />
                </Form.Item>
              ))}

              {isCascading && radioOption !== "" && (
                <div>
                  <Form.Item>
                    <Button
                      onClick={() => add()}
                      type="dashed"
                      className="cq-primary-btn"
                      style={{ marginTop: 20 }}
                    >
                      {"Add Quesstion(s) in " + radioOption}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex" }}>
                      <Button
                        type="dashed"
                        className="cq-primary-btn"
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        Add Section
                      </Button>
                      <Button
                        type="dashed"
                        className="cq-primary-btn"
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        Add Text
                      </Button>
                      <Button
                        onClick={() => add()}
                        type="dashed"
                        className="cq-primary-btn"
                        style={{ marginTop: 20 }}
                      >
                        Add Quesstion(s)
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </div>
                  </Form.Item>
                </div>
              )}
            </>
          )}
        </Form.List>*/}
        </div>
      </Spin>
    </div>
  );
}

export default QuestionCard;
