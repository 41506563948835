import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Tabs,
  Layout,
  Row,
  Col,
  Image,
  Modal,
  DatePicker,
  message,
  Input,
  Comment,
  Select,
  Spin,
  Button,
  Form,
  Checkbox,
  Tag,
  Avatar,
  Tooltip,
} from "antd";
import {
  UnorderedListOutlined,
  PlusCircleOutlined,
  FileSyncOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
// import AssignmentIcon from "@mui/icons-material/Assignment";
import ClientSideBar from "../Profile/ProfileRightSide/ClientSideBar";
import UpdataVisaStatus from "../Profile/ProfileRightSide/UpdataVisaStatus";
import StartNewVisa from "../Components/ClientVisa/StartNewVisa";
import UpdateClientAdmission from "../Components/ClientAdmission/UpdateAdmission";
import ClientEmployerJobs from "../Components/ClientJobs/ClientEmployerJobs";

import rightNav from "../images/log-right-nav-1.png";
import rightNavTwo from "../images/log-right-nav-2.png";
import rightNavThr from "../images/log-right-nav-3.png";
import rightNavFour from "../images/log-right-nav-4.png";
import rightNavFiv from "../images/log-right-nav-5.png";
import rightNavSix from "../images/log-right-nav-6.png";
import rightNavSev from "../images/log-right-nav-7.png";
import rightNavEight from "../images/log-right-nav-8.png";
import rightNavNine from "../images/log-right-nav-9.png";
import AddNewAdmission from "./ClientAdmission/AddNewAdmission";
import Balance from "../Components/Balance/Balance";
import navMenu from "../images/nav-collaps.png";
import add from "../images/add.png";
import Moment from "moment";
import TimeTracking from "./TimeTracking";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  getClientJobHistoryCurrent,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  updateReminder,
  getClientTasks,
  updetaTasks,
  setActiveKey,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  removeFollower,
  getVisaTypeByCountry,
  getAdmissionProgram,
  getAdmissionStatuses,
  updAdmissionStatus,
  startNewApplication,
  getCategory,
  getSchools,
  getSchoolLevel,
  addClientAdmission,
  getSchoolType,
  getReminders,
  getBranchPipeline,
  getTeamMember,
  getTimeTracking,
  getVisaType,
  getVisaWorkType,
  getVisaTypePrice,
  getServiceType,
  getTimeTrackingPopup,
  getPermissonUser,
  getBranchVisaCountries,
  getVisaNotification,
  setEmailTemplate,
  sendDynamicLinkEmailBlob,
} from "../store/Actions";

import {
  AppleOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { Images } from "../Themes";
import RemindersTab from "./Reminder/RemindersTab";
import "./sideBar.css";
import activityData from "./ClientActivity/ActivityData";
import moment from "moment";
import ClientMessages from "./ClientMessages/ClientMessages";
import DealCard from "./Deals";
import images from "../Themes/Images";
import TimeTrackingCard from "./TimeTracking";
import AddTimeTrackingForm from "./TimeTracking/AddTimeTrackingForm";
import PurchaseSubscription from "../Common/PurchaseMessage";
import { apiRefresh } from "../services/api";
import TimeCounter from "./TimeTracking/TimeCounter";
import ClientTasks from "./ClientTasks";

const { TabPane } = Tabs;
const { TextArea } = Input;

const { Header, Sider, Content } = Layout;

function ProfileSidebar(
  props
  // {

  // newApplicationWith,

  // triggerTask,
  // updateTrigger,
  // memberType,
  // triggerCmsForTask,
  // onLoadClient,
  // }
) {
  const [activeKeyState, setActiveKeyState] = useState(props.activeKey);

  const [handleValue, setHandleValue] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [allTasks, setAllTasks] = useState([]);
  const [isCompleted, setCompleted] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [followerModal, setFollowerModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [fileNoteModal, setFileNoteModal] = useState(false);
  const [fileNoteLoader, setFileNoteLoader] = useState(false);
  const [filenote, setFileNote] = useState(null);
  const [addTaskLoader, setAddTaskLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [followerLoader, setFollowerLoader] = useState(false);
  const [titleLoading, setTitleLoading] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [comment, setComment] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [followerId, setFollowerId] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [family, setFamily] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [comments, setTaskComments] = useState([]);
  const [title, setTitle] = useState(null);
  const [dateLoading, setDateLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showListIcon, setShowListIcon] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [timeTrackingVisible, setTimeTrackingVisible] = useState(false);
  const [completedTask, setCompletedTask] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [timerStatus, setTimerStatus] = useState(0);

  const formRef = useRef();

  // var profileData = JSON.parse(localStorage.getItem("profileData") || "");
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    props.onGetBranchPermission();
  }, []);

  useEffect(() => {
    if (props.profileDataRdx) {
      setProfileData(JSON.parse(props.profileDataRdx));
    }
  }, [props.profileDataRdx]);

  // useEffect(() => {
  //   if (branchOptions && branchOptions.length)
  //     setSelectedBranch(localStorage.getItem("selectedBranchId"));
  // }, [branchOptions]);

  useEffect(() => {
    // let findPermission = props.branchPermissionRes.branchPermission.find(
    //   (obj) => obj.name == "CoverGenius"
    // );
    if (
      localStorage.getItem("userBranchPermissions") &&
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userBranchPermissions")))
        )
      ).branchPermission.find((x) => x.name.toLowerCase() == "covergenius")
        .status
    ) {
      setShowListIcon(true);
    }
  }, []);

  const onHandleValue = useCallback((key) => {
    console.log("abc", key);
    props.onSetActiveKey(key);
    setHandleValue(key);
  });
  useEffect(() => {
    if (props.activeKey) {
      onHandleValue(props.activeKey);
    }
  }, [props.activeKey, onHandleValue]);

  useEffect(() => {
    if (handleValue && !props.activeKey) {
      setHandleValue(null);
    }
    if (props.activeKey == "6") {
      props.onGetTimeTrackingPopup().then((res) => {
        setShowPopup(res.payload && res.payload.isTimeTrackingPopUp);
      });
    }
  }, [props.activeKey]);

  useEffect(() => {
    let clientInfo = sessionStorage.getItem("clientprofileid");
    setClientId(clientInfo);
    if (clientInfo && !props.onLoadClient && props.activeKey === "6") {
      getTasks();
      getUsers();
      getClientFamily();
    }
  }, [props.onLoadClient, props.activeKey]);

  useEffect(() => {
    if (props.triggerTask && props.activeKey === "6") {
      triggerCurrentTasks();
    }
  }, [props.triggerTask, props.activeKey]);

  const triggerCurrentTasks = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    if (props.memberType.toLowerCase() === "client") {
      getTasks();
    } else if (props.memberType.toLowerCase() === "spouse") {
      getFamilyTask(familyId);
    } else if (props.memberType.toLowerCase() === "child") {
      getMemberTask(clientprofileid);
    }
    props.updateTrigger();
  };

  const UserLinks = () => {
    getUsers();
  };

  const FamilyLinks = () => {
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let data = {
      clientId: clientProfileIdMain,
      familyId: familyId,
    };
    getClientFamily(data);
  };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const getTasks = async () => {
    setTaskLoader(true);
    let id = null;
    if (selectedMember) id = selectedMember;
    else id = sessionStorage.getItem("clientprofileid");
    //
    props
      .onGetClientTask(id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
        setCompleted(false);
      })
      .catch((error) => {
        // message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
        setCompleted(false);
      });
  };

  const getMemberTask = (id) => {
    setTaskLoader(true);
    props
      .onGetClientTask(id, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
      })
      .catch((error) => {
        message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
      });
  };

  const getComments = (data) => {
    setCommentLoader(true);
    let comments = [];
    props
      .onGetTaskComments(data.id)
      .then((res) => {
        res.payload.items.forEach((item) => {
          comments.push(item);
        });
        setTaskComments(comments);
        setCommentLoader(false);
        console.log(res);
      })
      .catch((error) => {
        setTaskComments(comments);
        setCommentLoader(false);
      });
  };

  const setFileNoteValue = (data) => {
    const taskDescription = data.taskDescription;
    let fileNoteValue =
      `${data.taskTitle}` + "\n" + `task description: ` + `${taskDescription}`;
    props
      .onGetTaskComments(data.id)
      .then((res) => {
        const comments = res.payload.items;
        comments.forEach((item) => {
          fileNoteValue =
            fileNoteValue + "\n" + item.userName + ": " + item.description;
        });
        setFileNote(fileNoteValue);
      })
      .catch((error) => {
        setFileNote(fileNoteValue);
      });
  };

  const getFamilyTask = (familyId) => {
    setTaskLoader(true);
    props
      .onGetClientTask("00000000-0000-0000-0000-000000000000", familyId)
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
      })
      .catch((error) => {
        message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
      });
  };

  const getUsers = () => {
    props.onGetAllUsers().then((res) => {
      const usersInfo = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      setUsers(usersInfo);
    });
    props.onGetPermProcessingPerson(true).then((res) => {
      const usersInfoList = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      setUsersList(usersInfoList);
    });
  };

  const getClientFamily = (data) => {
    const clientId = sessionStorage.getItem("clientProfileIdMain");
    const familyId = sessionStorage.getItem("familyId");

    props.onGetClientFamily(data).then((res) => {
      let familyInfo = [];
      familyInfo.push({ label: "All", value: familyId });
      res.payload.items.forEach((item) => {
        familyInfo.push({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        });
      });
      setFamily(familyInfo);
    });
  };

  const updateTaskDate = (data) => {
    setDateLoading(true);
    let updatedate = {
      id: selectedTask.id,
      branchId: selectedTask.branchId,
      taskDate: data.format(),
      taskTitle: selectedTask.taskTitle,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    props
      .onUpdateTask(updatedate)
      .then((res) => {
        setDateLoading(false);
        message.success("Successfully Rescheduled!");
        setDateModal(false);
        getTasks();
        let userName = localStorage.getItem("userName");
        // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\n Task titled " +
              selectedTask.taskTitle +
              " rescheduled by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        setDateLoading(false);
        message.error("Unable to Reschedule");
      });
  };

  const updateTaskTitle = () => {
    setTitleLoading(true);
    const userId = localStorage.getItem("userId");
    let updatedate = {
      id: selectedTask.id,
      branchId: selectedTask.branchId,
      taskDate: selectedTask.taskDate,
      taskTitle: title,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    props
      .onUpdateTask(updatedate)
      .then((res) => {
        setTitleLoading(false);
        message.success("Successfully Updated!");
        setTitleModal(false);
        setTitle(null);
        if (props.triggerCmsForTask) props.props.triggerCmsForTask();
        getTasks();
        let userName = localStorage.getItem("userName");
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage: "\n Task titled " + title + " updated by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        setTitleLoading(false);
        setTitle(null);
        message.error("Unable to Update");
      });
  };

  const onPlay = (id) => {
    const onPlayOpt = {
      url: `v1/TaskTracking/Start`,
    };
    onPlayOpt.types = [
      "TIME_TRACKING_START_SUCCESS",
      "TIME_TRACKING_START_FAILURE",
    ];
    setTaskLoader(true);
    apiRefresh
      .post(onPlayOpt, { taskId: id })
      .then((res) => {
        setTaskLoader(false);
        getTasks();
      })
      .catch((err) => {
        setTaskLoader(false);
      });
  };

  const onPause = (id) => {
    const onPlayOpt = {
      url: `v1/TaskTracking/Pause`,
    };
    onPlayOpt.types = [
      "TIME_TRACKING_PAUSE_SUCCESS",
      "TIME_TRACKING_PAUSE_FAILURE",
    ];
    setTaskLoader(true);
    apiRefresh
      .post(onPlayOpt, { taskId: id })
      .then((res) => {
        setTaskLoader(false);
        getTasks();
      })
      .catch((err) => {
        setTaskLoader(false);
      });
  };

  const onStop = (item) => {
    const onPlayOpt = {
      url: `v1/TaskTracking/Stop`,
    };
    onPlayOpt.types = [
      "TIME_TRACKING_STOP_SUCCESS",
      "TIME_TRACKING_STOP_FAILURE",
    ];
    setTaskLoader(true);
    apiRefresh
      .post(onPlayOpt, { taskId: item.id })
      .then((res) => {
        setTaskLoader(false);
        completeTask(item);
        getTasks();
      })
      .catch((err) => {
        setTaskLoader(false);
      });
  };

  const addFollower = (data) => {
    setModalLoader(true);
    let payload = {
      taskId: selectedTask.id,
      userId: data,
    };
    let userAttached = users && users.find((obj) => obj.value == data);

    props
      .onAddTaskFollower(payload)
      .then((res) => {
        getFollower(selectedTask);
        setModalLoader(false);
        message.success("Successfully Added!");
        let userName = localStorage.getItem("userName");
        let followerName = userAttached && userAttached.label;
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\n Task titled " +
              selectedTask.taskTitle +
              " assigned to " +
              followerName +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        setModalLoader(false);
        message.error("Unable to add Follower!");
      });
  };

  const getFollower = (data) => {
    props
      .onGetTaskFollowers(data.id)
      .then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
      })
      .catch((error) => {
        setFollowers([]);
      });
  };

  const deleteTask = () => {
    setDeleteLoader(true);
    const userId = localStorage.getItem("userId");
    const remove = {
      id: selectedTask.id,
      delete: true,
    };
    props
      .onRemoveTasks(remove)
      .then(() => {
        setDeleteLoader(false);
        message.success("Successfully Deleted!");
        setDeleteModal(false);
        if (props.triggerCmsForTask) props.triggerCmsForTask();

        getTasks();
      })
      .catch((error) => {
        setDeleteLoader(false);
        message.error("Unable to Delete!");
      });
  };

  const revertCompletedTask = (data) => {
    const userId = localStorage.getItem("userId");
    const revert = {
      id: data.id,
      branchId: data.branchId,
      taskDate: data.taskDate,
      taskTitle: data.taskTitle,
      taskDescription: data.taskDescription,
      subjectId: data.subjectId,
      isPontential: data.isPontential,
      isCompleted: false,
    };
    props
      .onUpdateTask(revert)
      .then(() => {
        message.success("Successfully Converted!");
        getTasks();
        if (props.triggerCmsForTask) props.triggerCmsForTask();
      })
      .catch((error) => {
        message.error("Unable to Revert!");
      });
  };

  const closeVisibleTimeTracking = () => {
    setTimeTrackingVisible(false);
  };

  const completeTask = (data) => {
    const completed = {
      id: data.id,
    };
    props
      .onUpdateCompletedTask(completed)
      .then(() => {
        message.success("Successfully Completed!");
        getTasks();

        if (showPopup) {
          setCompletedTask([data]);
          setTimeTrackingVisible(true);
        }
        let userName = localStorage.getItem("userName");
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\n Task titled " + data.taskTitle + " completed by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
        if (props.triggerCmsForTask) props.triggerCmsForTask();
      })
      .catch((error) => {
        message.error("Unable to Complete!");
      });
  };

  // const addTaskFileNote = (data) => {
  //   const userId = localStorage.getItem("userId");
  //   const clientId = sessionStorage.getItem("clientProfileIdMain");
  //   const payload = {
  //     clientId: clientId,
  //     fileNote: `${data.taskTitle} ${data.taskDescription}`,
  //     createdBy: userId,
  //   };
  //   onAddTaskFileNote(payload)
  //     .then(() => {
  //       message.success("File Note Added!");
  //     })
  //     .catch((error) => {
  //       message.error("Unable to add File Note!");
  //     });
  // };

  const showComplete = (data) => {
    if (data) {
      let taskItems = [...tasks];
      taskItems = taskItems.filter((item) => item.isCompleted);
      setTasks(taskItems);
    } else setTasks(allTasks);
  };

  const onFinish = (values) => {
    setAddTaskLoader(true);
    let followers = [];
    let taskMemberId = null;
    if (selectedMember) taskMemberId = selectedMember;
    else taskMemberId = sessionStorage.getItem("clientprofileid");

    const userId = localStorage.getItem("userId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: taskMemberId
        ? taskMemberId
        : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: false,
      isCompleted: false,
    };

    props
      .onAddDailyTasks(data)
      .then((res) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        setTaskModal(false);
        if (props.triggerCmsForTask) props.triggerCmsForTask();
        getTasks();
        let userName = localStorage.getItem("userName");
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\nTask titled " + values.task_title + " added by " + userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Unable to Add Task!");
      });
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    setSubmit(true);
    const userId = localStorage.getItem("userId");
    let payload = {
      taskId: selectedTask.id,
      description: comment,
    };
    props
      .onAddTaskComment(payload)
      .then((res) => {
        setSubmit(false);
        setComment(null);
        getComments(selectedTask);
      })
      .catch((error) => {
        setSubmit(false);
        setComment(null);
      });
  };

  const handleFileNoteChange = (e) => {
    // let fileValue = filenote;
    // fileValue = e.target.value;
    //
    setFileNote(e.target.value);
  };

  const removeFollower = (data) => {
    let payload = {
      id: data.id,
      delete: true,
    };

    props.onRemoveFollower(payload).then((res) => {
      message.success("Follower Removed!");
      let userName = localStorage.getItem("userName");
      if (profileData) {
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            "\n " +
            data.fullName +
            " removed from task " +
            selectedTask.taskTitle +
            " by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Tasks",
          invoiceId: "0",
        };
        activityData(myData);
      }
      props
        .onGetTaskFollowers(selectedTask.id)
        .then((res) => {
          let followers = res.payload.items;
          setFollowers(followers);
        })
        .catch((error) => {
          setFollowers([]);
        });
    });
  };

  const handleFileNoteSubmit = (e) => {
    const userId = localStorage.getItem("userId");
    const clientId = sessionStorage.getItem("clientprofileid");
    const payload = {
      clientId: clientId,
      fileNote: filenote,
    };
    props
      .onAddTaskFileNote(payload)
      .then(() => {
        message.success("File Note Added!");
        setFileNoteModal(false);
      })
      .catch((error) => {
        message.error("Unable to add File Note!");
      });
  };

  const onChangeThreadHandler = (type, val) => {
    setTitle(val);
  };

  const renderTitleModal = () => (
    <Modal
      title="Update Task Title"
      visible={titleModal}
      confirmLoading={titleLoading}
      onOk={() => updateTaskTitle()}
      onCancel={() => setTitleModal(false)}
      okText="Update"
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
        <Input
          value={title}
          onChange={(e) => onChangeThreadHandler("title", e.target.value)}
          style={{ width: 283 }}
          placeholder="Enter Task Title"
        />
      </div>
    </Modal>
  );

  const renderDeleteModal = () => (
    <Modal
      title="Delete Task"
      visible={deleteModal}
      confirmLoading={deleteLoader}
      onOk={() => deleteTask()}
      onCancel={() => setDeleteModal(false)}
      okText="Delete"
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <div style={{ marginTop: 15, width: "70%", marginLeft: 80 }}>
        <p style={{ fontSize: 14 }}>
          Are you sure you want to delete this Task ?
        </p>
      </div>
    </Modal>
  );

  const renderAddTaskModal = () => (
    <Modal
      title="Add Task"
      visible={taskModal}
      onCancel={() => setTaskModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={addTaskLoader}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          {...layout}
        >
          <div className="add-tasks">
            <div>
              <div className="mdtr-modal-gray-cont">
                <div className="modal-parts">
                  <Form.Item
                    className="form-parts"
                    label="Task Title"
                    name="task_title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="form-parts"
                    label="Task Description"
                    name="task_description"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} showCount maxLength={250} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts"
                    label="Select Date"
                    name="select_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts multi-select-option"
                    label="Add Followers"
                    name="add_followers"
                    // rules={[{ message: "Please Add Followers!" }]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      options={usersList}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Form.Item>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      className="task-blue"
                      htmlType="submit"
                    >
                      ADD TASK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const renderAddFileNote = () => (
    <Modal
      title="Add To File Note"
      visible={fileNoteModal}
      onCancel={() => setFileNoteModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={false}>
        <Form name="basic" initialValues={{ remember: true }}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleFileNoteChange(e)}
                        value={filenote}
                        showCount
                        maxLength={300}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleFileNoteSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        SUBMIT
                      </Button>
                    </view>
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const renderAddComment = () => (
    <Modal
      title="Task Details"
      visible={commentModal}
      onCancel={() => setCommentModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={modalLoader}>
        <div className="mdtr-modal-gray-cont" style={{ marginBottom: 10 }}>
          <div class="d-flex">
            <Select
              onSearch={() => UserLinks()}
              showSearch
              allowClear
              options={usersList}
              style={{ width: "40%" }}
              placeholder="Search to Followers"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={(option) => {
                if (option) addFollower(option);
              }}
            />
            <div style={{ width: "40%", marginLeft: 94 }}>
              <DatePicker
                allowClear
                placeholder="Reschedule Task"
                onChange={(date) => {
                  if (date) updateTaskDate(date);
                }}
                format={"DD/MM/YYYY"}
              />
            </div>
          </div>
        </div>
        <Form name="basic" initialValues={{ remember: true }}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="d-flex space-between ">
                <div className="follower-row d-flex">
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    <h4>Followers</h4>
                  </span>
                  {followers.length
                    ? followers.map((item) => (
                        <span>
                          {item.imgURL &&
                          item.imgURL != null &&
                          item.imgURL != "" ? (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item)}
                                />
                                <Avatar
                                  src={
                                    <Image
                                      src={item.imgURL}
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item)}
                                />
                                <Avatar
                                  className="mar-right"
                                  size="small"
                                  style={{ height: 32, width: 32 }}
                                  icon={
                                    <UserOutlined
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      ))
                    : null}
                </div>
              </div>
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleChange(e)}
                        value={comment}
                        showCount
                        maxLength={250}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        Comment
                      </Button>
                    </view>
                  }
                />
                <div>
                  <div
                    className="d-flex comment-width"
                    style={{ alignItems: "center" }}
                  >
                    <Spin spinning={commentLoader} size="large">
                      <div>
                        {selectedTask ? (
                          <div
                            style={{
                              borderBottom: "1px solid #ccc",
                              width: "100%",
                              marginBottom: 10,
                            }}
                          >
                            <div>
                              <h5>Task Description: </h5>
                            </div>
                            <div>
                              <p>{selectedTask.taskDescription}</p>
                            </div>
                          </div>
                        ) : null}
                        {comments.length
                          ? comments.map((item, index) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  width: 451,
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  class="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  {item.imgURL &&
                                  item.imgURL != null &&
                                  item.imgURL != "" ? (
                                    <Avatar
                                      src={
                                        <Image
                                          src={item.imgURL}
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  ) : (
                                    <Avatar
                                      className="mar-right"
                                      size="small"
                                      style={{ height: 32, width: 32 }}
                                      icon={
                                        <UserOutlined
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  )}
                                  <div style={{ marginLeft: 3 }}>
                                    <h4>{item.userName}</h4>
                                    <span style={{ fontSize: 12 }}>
                                      {moment(item.createdDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  return (
    <Fragment>
      {/* <h3 onClick={() => setHandleValue(null)}>Hide</h3> */}
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent:
              showListIcon && props.showListView ? "space-between" : "flex-end",
            width: props.activeKey ? "100%" : "80%",
          }}
        >
          {showListIcon && props.showListView && (
            <div style={{ cursor: "pointer" }} onClick={props.onClickListView}>
              {/* <span className="cv-bold-text">List</span> */}
              <Image
                style={{ cursor: "pointer", width: 28, height: 16 }}
                width={28}
                src={Images.coverLetterView}
                preview={false}
              />
              {/* <AssignmentIcon sx={{ color: "#158EC5", fontSize: 20 }} /> */}
            </div>
          )}
          <Col xs={2} offset={1}>
            <Image
              style={{ cursor: "pointer" }}
              width={17}
              src={navMenu}
              preview={false}
              onClick={(key) => {
                if (props.activeKey) {
                  props.onSetActiveKey(null);
                } else {
                  props.onSetActiveKey("1");
                }
                setHandleValue(!handleValue);
              }}
            />
          </Col>
        </div>
      </Row>
      <div>
        <div className={handleValue ? "client-tabs" : "short-tabs"}>
          <Tabs
            // activeKey={activeTabNewVisa ? "3" : "1"}
            tabPosition="left"
            style={{
              height: "auto",
              backgroundColor: "#F3F4F6",
            }}
            activeKey={props.activeKey}
            onChange={(value) => onHandleValue(value)}
          >
            <TabPane
              style={{ width: "100%" }}
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNav} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                      paddingLeft: 15,
                    }}
                  >
                    Update Visa Status
                  </span>
                </div>
              }
              key="1"
            >
              <UpdataVisaStatus
                visaStatusData={props.visaStatusData}
                onUpdateCaseStatus={props.onUpdateCaseStatus}
                visaAppData={props.visaAppData}
                onGetVisaApplication={props.onGetVisaApplication}
                onSetActiveKey={props.onSetActiveKey}
                onUpdatePriority={props.onUpdatePriority}
                visaPriorityRes={props.visaPriorityRes}
                onSignedVisa={props.onSignedVisa}
                onPaidVisa={props.onPaidVisa}
                activeKey={props.activeKey}
                onGetVisaStatus={props.onGetVisaStatus}
                clientId={sessionStorage.getItem("clientprofileid")}
                profileDataRdx={
                  props.profileDataRdx ? JSON.parse(props.profileDataRdx) : null
                }
                onGetVisaNotification={props.onGetVisaNotification}
                setEmailTemplate={props.setEmailTemplate}
                sendDynamicLinkEmail={props.sendDynamicLinkEmail}
              />
            </TabPane>
            <TabPane
              style={{ width: "100%" }}
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavTwo} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Update Admission
                  </span>
                </div>
              }
              key="2"
            >
              {localStorage.getItem("userBranchPermissions") &&
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userBranchPermissions"))
                  )
                )
              ).branchPermission.find((x) => x.name.toLowerCase() == "school")
                .status ? (
                <UpdateClientAdmission
                  onUpdAdmissionStatus={props.onUpdAdmissionStatus}
                  admissionStatusData={props.admissionStatusData}
                  admissionProgramData={props.admissionProgramData}
                  onGetAdmissionProgram={props.onGetAdmissionProgram}
                  activeKey={props.activeKey}
                  onGetAdmissionStatuses={props.onGetAdmissionStatuses}
                  profileDataRdx={
                    props.profileDataRdx
                      ? JSON.parse(props.profileDataRdx)
                      : null
                  }
                />
              ) : (
                <PurchaseSubscription />
              )}
            </TabPane>
            <TabPane
              style={{ width: "100%" }}
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img
                      src={Images.newApplicaton}
                      className="right-bar-icon"
                    />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Start Application
                  </span>
                </div>
              }
              key="3"
            >
              <StartNewVisa
                visaTypeData={props.countryVisaTypeData}
                onStartNewApplication={props.onStartNewApplication}
                onGetVisaApplication={props.onGetVisaApplication}
                newApplicationWith={props.newApplicationWith}
                visaCountryId={props.visaCountryId}
                onGetVisaCategory={props.onGetVisaCategory}
                visaCategoryData={props.visaCategoryData}
                onSetActiveKey={props.onSetActiveKey}
                onGetVisaTypeByCountry={props.onGetVisaTypeByCountry}
                activeKey={props.activeKey}
                profileDataRdx={
                  props.profileDataRdx ? JSON.parse(props.profileDataRdx) : null
                }
                brnchVisaCountriesData={props.brnchVisaCountriesData}
                onGetBVisaCountries={props.onGetBVisaCountries}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={Images.admissionTab} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    New Admissions
                  </span>
                </div>
              }
              key="4"
            >
              {localStorage.getItem("userBranchPermissions") &&
              JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userBranchPermissions"))
                  )
                )
              ).branchPermission.find((x) => x.name.toLowerCase() == "school")
                .status ? (
                <AddNewAdmission
                  schoolTypesData={props.schoolTypesData}
                  onGetSchools={props.onGetSchools}
                  schoolsData={props.schoolsData}
                  onGetSchoolLevel={props.onGetSchoolLevel}
                  schoolLevelData={props.schoolLevelData}
                  onAddClientAdmission={props.onAddClientAdmission}
                  onGetAdmissionProgram={props.onGetAdmissionProgram}
                  activeKey={props.activeKey}
                  onGetSchoolType={props.onGetSchoolType}
                  onSetActiveKey={props.onSetActiveKey}
                  profileDataRdx={
                    props.profileDataRdx
                      ? JSON.parse(props.profileDataRdx)
                      : null
                  }
                />
              ) : (
                <PurchaseSubscription />
              )}
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavThr} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Send SMS
                  </span>
                </div>
              }
              key="5"
            >
              <ClientMessages
                clientId={sessionStorage.getItem("clientprofileid")}
                mobNumber={profileData && profileData.mobileNumber}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavFour} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Tasks
                  </span>
                </div>
              }
              key="6"
            >
              <div style={{ padding: 10, height: 54 }}>
                <Select
                  showSearch
                  labelInValue
                  options={family}
                  style={{ width: "60%" }}
                  placeholder="Search Family"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                  onChange={(option) => {
                    if (option.label === "All") {
                      getFamilyTask(option.value);
                      setSelectedMember(null);
                    } else {
                      getMemberTask(option.value);
                      setSelectedMember(option.value);
                    }
                  }}
                />
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="medium"
                  onClick={() => {
                    setTaskModal(true);
                  }}
                />
              </div>
              <div style={{ display: "flex", marginBottom: 20 }}>
                <Checkbox
                  checked={isCompleted}
                  disabled={false}
                  onChange={(e) => {
                    showComplete(e.target.checked);
                    setCompleted(!isCompleted);
                  }}
                />
                <span style={{ marginLeft: 10 }}>Show Completed</span>
              </div>
              <Spin
                style={{ marginLeft: "37%" }}
                size="large"
                spinning={taskLoader}
              >
                {tasks.length ? (
                  tasks.map((item, index) => (
                    <ClientTasks
                      item={item}
                      index={index}
                      completeTask={completeTask}
                      revertCompletedTask={revertCompletedTask}
                      setSelectedTask={(val) => setSelectedTask(val)}
                      setCommentModal={(val) => setCommentModal(val)}
                      getComments={(e) => getComments(e)}
                      getFollower={(e) => getFollower(e)}
                      onPlay={(val) => onPlay(val)}
                      onPause={(val) => onPause(val)}
                      onStop={(e) => onStop(e)}
                      setTitle={(e) => setTitle(e)}
                      setTitleModal={(val) => setTitleModal(val)}
                      setFileNoteModal={(val) => setFileNoteModal(val)}
                      setFileNoteValue={(e) => setFileNoteValue(e)}
                    />
                  ))
                ) : (
                  <div>
                    <span>No Task Found</span>
                  </div>
                )}
              </Spin>
            </TabPane>
            {/* {JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() == "meetings").status == 1 ? (
              <TabPane
                tab={
                  <div style={{ display: props.activeKey ? "flex" : "block" }}>
                    <div>
                      <img src={rightNavFiv} className="right-bar-icon" />
                    </div>
                    <span
                      className="rightbar-icons"
                      style={{
                        fontSize: props.activeKey && 14,
                        marginLeft: props.activeKey ? 10 : 0,
                      }}
                    >
                      Meetings
                    </span>
                  </div>
                }
                key="7"
              >
                Coming Soon
              </TabPane>
            ) : null} */}
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavSix} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Reminders
                  </span>
                </div>
              }
              key="8"
            >
              <RemindersTab
                remindersRes={props.clientRemindersRes}
                onUpdateReminder={props.onUpdateReminder}
                onGetReminder={props.onGetClientReminders}
                activeKey={props.activeKey}
                clientId={sessionStorage.getItem("clientprofileid")}
              />
            </TabPane>
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavSev} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Client Tags
                  </span>
                </div>
              }
              key="9"
            >
              <ClientSideBar
                onGetClientTag={props.onGetClientTag}
                clientTagRes={props.clientTagRes}
                onGetProfileClientTag={props.onGetProfileClientTag}
                getClientRes={props.getClientRes}
                onAddProfileClientTag={props.onAddProfileClientTag}
                addProfielTagRes={props.addProfielTagRes}
                onRemoveProfileClientTag={props.onRemoveProfileClientTag}
                removeProfileTagRes={props.removeProfileTagRes}
                activeKey={props.activeKey}
                clientId={sessionStorage.getItem("clientprofileid")}
              />
            </TabPane>
            {JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() == "accounts").status == 1 ? (
              <TabPane
                tab={
                  <div style={{ display: props.activeKey ? "flex" : "block" }}>
                    <div>
                      <img src={rightNavEight} className="right-bar-icon" />
                    </div>
                    <span
                      className="rightbar-icons"
                      style={{
                        fontSize: props.activeKey && 14,
                        marginLeft: props.activeKey ? 10 : 0,
                      }}
                    >
                      Balance
                    </span>
                  </div>
                }
                key="10"
              >
                <Balance />
              </TabPane>
            ) : null}
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={rightNavNine} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Time Tracking
                  </span>
                </div>
              }
              key="11"
            >
              <TimeTracking
                clientId={sessionStorage.getItem("clientprofileid")}
                onGetVisaType={props.onGetVisaType}
                onGetVisaWorkType={props.onGetVisaWorkType}
                onGetVisaTypePrice={props.onGetVisaTypePrice}
                onGetServiceType={props.onGetServiceType}
                visaTypeData={props.visaTypeData}
                visaWorkTypeRes={props.visaWorkTypeRes}
                visaTypePriceRes={props.visaTypePriceRes}
                serviceTypeRes={props.serviceTypeRes}
                getTasks={getTasks}
                tasks={tasks}
                activeKey={props.activeKey}
                onGetVisaApplication={props.onGetVisaApplication}
                visaAppData={props.visaAppData}
              />
            </TabPane>
            {JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() == "deals").status == 1 ? (
              <TabPane
                tab={
                  <div style={{ display: props.activeKey ? "flex" : "block" }}>
                    <div>
                      <img src={Images.deals} className="right-bar-icon" />
                    </div>
                    <span
                      className="rightbar-icons"
                      style={{
                        fontSize: props.activeKey && 14,
                        marginLeft: props.activeKey ? 10 : 0,
                      }}
                    >
                      Deals
                    </span>
                  </div>
                }
                key="12"
              >
                {localStorage.getItem("userBranchPermissions") &&
                JSON.parse(
                  decodeURIComponent(
                    escape(
                      window.atob(localStorage.getItem("userBranchPermissions"))
                    )
                  )
                ).branchPermission.find((x) => x.name.toLowerCase() == "deals")
                  .status ? (
                  <DealCard
                    clientId={sessionStorage.getItem("clientprofileid")}
                    onGetTeamMember={props.onGetTeamMember}
                    teamMembers={props.teamMembers}
                    onGetBranchPipeline={props.onGetBranchPipeline}
                    brnchPipelineData={props.brnchPipelineData}
                    clientType={"client"}
                  />
                ) : (
                  <PurchaseSubscription />
                )}
              </TabPane>
            ) : null}
            <TabPane
              tab={
                <div style={{ display: props.activeKey ? "flex" : "block" }}>
                  <div>
                    <img src={Images.jobs} className="right-bar-icon" />
                  </div>
                  <span
                    className="rightbar-icons"
                    style={{
                      fontSize: props.activeKey && 14,
                      marginLeft: props.activeKey ? 10 : 0,
                    }}
                  >
                    Jobs
                  </span>
                </div>
              }
              key="13"
            >
              <ClientEmployerJobs
                onGetClientJobHistoryCurrent={
                  props.onGetClientJobHistoryCurrent
                }
                employerJobHistoryCurrentRes={
                  props.employerJobHistoryCurrentRes
                }
                activeKey={props.activeKey}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Modal
        title="Time Tracking"
        visible={timeTrackingVisible}
        // onOk={handleOk}
        onCancel={closeVisibleTimeTracking}
        header={false}
        footer={false}
        width={550}
      >
        <AddTimeTrackingForm
          onGetVisaType={props.onGetVisaType}
          onGetVisaWorkType={props.onGetVisaWorkType}
          onGetVisaTypePrice={props.onGetVisaTypePrice}
          onGetServiceType={props.onGetServiceType}
          visaTypeData={props.visaTypeData}
          visaWorkTypeRes={props.visaWorkTypeRes}
          visaTypePriceRes={props.visaTypePriceRes}
          serviceTypeRes={props.serviceTypeRes}
          // getTasks={getTasks}
          tasks={completedTask}
          addSuccess={closeVisibleTimeTracking}
          handleCancel={closeVisibleTimeTracking}
          completed={true}
          clientId={clientId}
          addByClient={true}
          onGetVisaApplication={props.onGetVisaApplication}
          visaAppData={props.visaAppData}
        />
      </Modal>
      {renderTitleModal()}
      {renderDeleteModal()}
      {renderAddTaskModal()}
      {renderAddComment()}
      {renderAddFileNote()}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    countryVisaTypeData: state.clientVisaReducer.countryVisaTypeData,
    admissionProgramData: state.clientAdmissionReducer.admissionProgramData,
    admissionStatusData: state.clientAdmissionReducer.admissionStatusData,
    schoolTypesData: state.clientAdmissionReducer.schoolTypesData,
    schoolsData: state.clientAdmissionReducer.schoolsData,
    schoolLevelData: state.clientAdmissionReducer.schoolLevelData,
    visaCategoryData: state.clientVisaReducer.visaCategoryData,
    clientRemindersRes: state.clientSummaryReducer.clientRemindersRes,
    brnchPipelineData: state.dealsReducer.brnchPipelineData,
    teamMembers: state.teamMemberReducer.teamMembers,
    branchPermissionRes: state.timeTrackingReducer.timeTrackingRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaWorkTypeRes: state.timeTrackingReducer.visaWorkTypeRes,
    visaTypePriceRes: state.timeTrackingReducer.visaTypePriceRes,
    serviceTypeRes: state.timeTrackingReducer.serviceTypeRes,
    timeTrackingPopupRes: state.timeTrackingReducer.timeTrackingPopupRes,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdateReminder: bindActionCreators(updateReminder, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onUpdAdmissionStatus: bindActionCreators(updAdmissionStatus, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetVisaCategory: bindActionCreators(getCategory, dispatch),
    onGetSchools: bindActionCreators(getSchools, dispatch),
    onGetSchoolLevel: bindActionCreators(getSchoolLevel, dispatch),
    onAddClientAdmission: bindActionCreators(addClientAdmission, dispatch),
    onGetSchoolType: bindActionCreators(getSchoolType, dispatch),
    onGetClientReminders: bindActionCreators(getReminders, dispatch),
    onGetBranchPipeline: bindActionCreators(getBranchPipeline, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetBranchPermission: bindActionCreators(getTimeTracking, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaWorkType: bindActionCreators(getVisaWorkType, dispatch),
    onGetVisaTypePrice: bindActionCreators(getVisaTypePrice, dispatch),
    onGetServiceType: bindActionCreators(getServiceType, dispatch),
    onGetTimeTrackingPopup: bindActionCreators(getTimeTrackingPopup, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
    onGetVisaNotification: bindActionCreators(getVisaNotification, dispatch),
    setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
    sendDynamicLinkEmail: bindActionCreators(
      sendDynamicLinkEmailBlob,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar);
