export const JobSectors = [
  {
    label: "Administrative",
    id: 1,
  },
  {
    label: "Agriculture / Farming",
    id: 2,
  },
  {
    label: "Automotive",
    id: 3,
  },
  {
    label: "Construction",
    id: 4,
  },
  {
    label: "Electrical",
    id: 5,
  },
  {
    label: "Engineer",
    id: 6,
  },
  {
    label: "Finance",
    id: 7,
  },
  {
    label: "FMCG",
    id: 8,
  },
  {
    label: "Hospitality",
    id: 9,
  },
  {
    label: "Human Resources",
    id: 10,
  },
  {
    label: "Insurance",
    id: 11,
  },
  {
    label: "Legal",
    id: 12,
  },
  {
    label: "Marketing",
    id: 13,
  },
  {
    label: "Medical",
    id: 14,
  },
  {
    label: "Real estate",
    id: 15,
  },
  {
    label: "Retail",
    id: 16,
  },
  {
    label: "Sales",
    id: 17,
  },
  {
    label: "Supply chain",
    id: 18,
  },
  {
    label: "Teachers",
    id: 19,
  },
  {
    label: "Trades",
    id: 20,
  },
  {
    label: "Not Employed",
    id: 21,
  },
  {
    label: "Transportation",
    id: 22,
  },
  {
    label: "Manufacturing",
    id: 23,
  },
];
