import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContractEmail from "../AccountSetting/ContractEmail/ContractEmail";

import {
  addContractEmail,
  getContractEmail,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    contractEmailRes: state.LetterTemplatesReducer.contractEmailRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddContractEmail: bindActionCreators(addContractEmail, dispatch),
    onGetContractEmail: bindActionCreators(getContractEmail, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractEmail);
