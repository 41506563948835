import React, { Fragment } from "react";
import { Table } from "antd";
import moment from "moment";

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <a>{text}</a>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Client Tags",
    dataIndex: "clienttags",
    key: "clienttags",
  },
  {
    title: "Visa Type, Visa Status",
    dataIndex: "visa",
    key: "visa",
  },
  {
    title: "Employer Name",
    dataIndex: "employerName",
    key: "employerName",
  },
  {
    title: "Issue Date",
    dataIndex: "issueDate",
    key: "issueDate",
    render: (text) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
  },

  {
    title: "Expiry Date",
    dataIndex: "expiryDate",
    key: "expiryDate",
    render: (text) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class EMedicalExpiryTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        this.props.onSaleHistoryReport().then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };

  render() {
    const {
      saleHistoryRes,
      displayText,
      totalInvoiced,
      totalReceived,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={saleHistoryRes && saleHistoryRes}
            pagination={false}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
          />
        </div>
      </Fragment>
    );
  }
}
export default EMedicalExpiryTable;
