import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LetterTemplates from "../AccountSetting/SettingFileNotes/SettingFileNotes";

import {
  getLetterTemplates,
  addLetterTemplates,
  removeLetterTemplates,
  updetaLetterTemplates,
  getDynamicKeys,
  setActiveInnerTab,
  sortLetterTemplates,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    addLetterTemplatesRes: state.LetterTemplatesReducer.addLetterTemplatesRes,
    removeLetterTemplatesRes:
      state.LetterTemplatesReducer.removeLetterTemplatesRes,
    updateLetterTemplatesRes:
      state.LetterTemplatesReducer.updateLetterTemplatesRes,
    dynamicKeysRes: state.fileNotesReducer.dynamicKeysRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onAddLetterTemplates: bindActionCreators(addLetterTemplates, dispatch),
    onRemoveLetterTemplates: bindActionCreators(
      removeLetterTemplates,
      dispatch
    ),
    onUpdetaLetterTemplates: bindActionCreators(
      updetaLetterTemplates,
      dispatch
    ),
    onGetDynamicKeys: bindActionCreators(getDynamicKeys, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onSortLetterTemplates: bindActionCreators(sortLetterTemplates, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LetterTemplates);
