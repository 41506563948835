import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const clientEmployerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_EMPLOYER_SUCCESS:
      return { ...state, clientEmployerRes: action.payload };
    case types.GET_CLIENT_EMPLOYER_FAILURE:
      if (action.payload.response.status === 404) {
        return { ...state, clientEmployerRes: null };
      } else {
         
      }
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_CLIENT_EMPLOYER_SUCCESS:
      return { ...state, addClientEmployerRes: action.payload };
    case types.ADD_CLIENT_EMPLOYER_FAILURE:
      if (action.payload.status === 400) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Request not completed due to missing data!"
        );
      } else {
         
      }

      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_CLIENT_EMPLOYER_SUCCESS:
      return { ...state, updClientEmployerRes: action.payload };
    case types.UPDATE_CLIENT_EMPLOYER_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_CLIENT_EMPLOYER_SUCCESS:
      return { ...state, removeClientEmployerRes: action.payload };
    case types.DELETE_CLIENT_EMPLOYER_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.LINK_EMPLOYER_SUCCESS:
      return { ...state, linkEmployerRes: action.payload };
    case types.LINK_EMPLOYER_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
