import React, { useState, Fragment, useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

// import ClientTagForm from "./ClientTagForm";
import { Images } from "./../Themes";
import { Table, Modal, Spin, message, Input } from "antd";

const ClientList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});

  useEffect(() => {
    // setLoading(true);
    // onGetClientTag().then(() => {
    //   setLoading(false);
    // });
  }, []);

  const headOption = [
    { tabName: "Create Invoice", linkName: "/accounts/create-invoice" },
    { tabName: "Due/Over Due", linkName: "/account/due-over-due" },
    { tabName: "Reports", linkName: "" },
    { tabName: "Templates", linkName: "" },
    {
      tabName: "Settings",
      linkName: "",
    },
    {
      tabName: "Daily Transactions",
      linkName: "",
    },
    {
      tabName: "Invoices",
      linkName: "",
    },
  ];

  const removeTag = (id) => {
    // setLoading(true);
    //
    // const remove = {
    //   id: id,
    //   delete: true,
    // };
    //
    // onRemoveClientTag(remove).then(() => {
    //   onGetClientTag().then(() => {
    //     setLoading(false);
    //     message.success("Successfully Deleted!");
    //   });
    // });
  };

  const showModal = (value) => {
    setIsModalVisible(true);
    setCreateMode(value);
    setUpdatedata(value);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dataSource = [
    {
      clientName: "Test name",
      invoiceNo: "123",
      payment: "0",
      dueOverDueAmount: "333",
    },
  ];

  const columns = [
    {
      title: "CLIENT NAME",
      dataIndex: "clientName",
    },
    {
      title: "PAYMENT",
      dataIndex: "payment",
    },
    {
      title: "DUE",
      dataIndex: "dueAmount",
    },
    {
      title: "VIEW",
      dataIndex: "view",
      render: (text, record) => {
        return (
          <div className="table-action">
            <div>
              <span className="acc-actions-button">VIEW INVOICE</span>
            </div>
            {/* <EditOutlined onClick={() => showModal(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} /> */}
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      <div>
        <PotentialHeaderTabs data={headOption} activeTab="Create Invoice" />
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <div className="acc-search-cont">
              <div className="acc-search-head-cont">
                <span className="acc-search-text">Search Client</span>
              </div>
              <div className="acc-search-input-cont" style={{ paddingTop: 30 }}>
                <div class="profile-input-border">
                  <Input className="profile-input" placeholder="" type="text" />
                </div>
              </div>
            </div>
          </div>
          {false && (
            <div className="client-tag">
              <div className="d-flex align-item client-top">
                <span to="/client-tags" className="top-text">
                  OUTSTANDING INVOICES
                </span>
              </div>
              <div className="acc-due-over-due-table-cont">
                <div className="client-tag-form"></div>
                <div>
                  <div className="client-tag-table">
                    <Spin size="large" spinning={loading}>
                      <Table
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "table-row-light" : "table-row-dark"
                        }
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {isModalVisible && (
        <Modal
          title={createMode === "add-tag" ? "Add Tag" : "Update"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          header={false}
          footer={false}
        >
          <ClientTagForm
            onAddClientTag={onAddClientTag}
            handleCancel={handleCancel}
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            setLoading={setLoading}
            onUpdetaClientTag={onUpdetaClientTag}
            updateClientTagRes={updateClientTagRes}
            createMode={createMode}
            updatedata={updatedata}
          />
        </Modal>
      )} */}
    </Fragment>
  );
};
export default ClientList;
