import React from "react";
import {
  message,
  Spin,
  Button,
  Upload,
  Form,
  Checkbox,
  Input,
  Space,
  DatePicker,
  Select,
  Radio,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FileDownload from "js-file-download";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";

import Section from "../Components/Questionnaire/Section";
import RadioOption from "../Components/Questionnaire/RadioOption";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

var stopLoader = false;
const { Option } = Select;

class QuestionnairePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: false,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
    };

    // this.props
    //   .onGetTeamMember()
    //   .then(() => {})
    //   .catch(err => {
    //     console.log(this.props.teamMemberError);
    //   });
    // this.props.onGetBranch();
  }

  componentDidMount() {
    console.log("show props: ", this.props);
  }

  componentDidUpdate(prevProps) {}

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  onSubmit = () => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.email)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else if (!strongRegex.test(this.state.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else {
      this.setState({ loading: true });

      let data = {
        roleId: 0,
        title: "",
        image: this.state.addUserImageUrl,
        forgotPasswordBit: false,
        lastSignedIn: new Date(),
        signature: "",
        signatureImage: "",
        timeZone: "",
        comission: 0,
        agreementUrl: "",
        description: "",
        fullName: this.state.fullName,
        alternateEmail: this.state.email,
        status: 0,
        isManager: false,
        isOwner: false,
      };

      console.log(data);

      // this.props
      //   .onAddTeamMember(data)
      //   .then(() => {
      //     this.setState({ loading: false });
      //     if (this.props.addTeamMemberSuccess.id === "Email exists") {
      //       message.error("User email already exist!");
      //     } else {
      //       // this.props.onGetTeamMember();
      //       let createPassword = {
      //         userId: this.props.addTeamMemberSuccess.id,
      //         email: this.state.email,
      //         password: this.state.password
      //       };
      //       this.props.onAddPassword(createPassword);
      //       message.success("User added successfully");
      //       this.setState({
      //         fullName: "",
      //         email: "",
      //         password: "",
      //         addUserImageUrl: ""
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.setState({ loading: false });
      //   });
    }
  };

  onFinish = (values) => {
    let data = {
      name: values.questionnaireName,
      branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      allowAttachments: values.allowAttachments,
      deceleration: values.allowDecelration,
      showInPotential: true,
      showInClient: true,
      showInPublic: true,
      questionnaireEmail: "string",
      declarationText: "string",
      clientId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      parentQuestionnaireId: 0,
      isPotential: true,
      sections: { ...values.sections },
    };

    console.log(data);
  };

  render() {
    const {
      selectedOption,
      fullName,
      email,
      password,
      imageUrl,
      addUserImageUrl,
      exportLoading,
    } = this.state;
    const { location } = this.props;
    console.log("show sections", location.state);
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="sus-container" style={{ paddingBottom: 80 }}>
              <div style={{ marginTop: 20 }}>
                <div className="title-container">
                  <span className="heading-text">
                    {location.state.questionnaire.name}
                  </span>
                </div>
                <Form onFinish={this.onFinish}>
                  <div className="cq-container">
                    {location.state &&
                      location.state.sections.map((section, secInd) => {
                        return (
                          <div key={secInd}>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 0,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                style={{
                                  justifyContent: "space-between",
                                  paddingTop: 20,
                                  paddingBottom: 0,
                                  display: "block",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 15,
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="cq-label-text">FULL NAME</p>
                                      <div
                                        className="profile-input-border"
                                        style={{ width: "60%", border: 0 }}
                                      >
                                        <Form.Item name="questionnaireName">
                                          <Input
                                            className="profile-input"
                                            placeholder="FULL NAME"
                                            disabled={true}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="title-container"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <span className="heading-text">
                                {section.mySection.name}
                              </span>
                            </div>
                            <div
                              className="cq-form-cont"
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginTop: 0,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <div
                                className="form-cont"
                                style={{
                                  justifyContent: "space-between",
                                  paddingTop: 20,
                                  paddingBottom: 0,
                                  display: "block",
                                }}
                              >
                                {section.questions.map((question, quesInd) => {
                                  return (
                                    <div
                                      style={{
                                        marginTop: 15,
                                      }}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p className="cq-label-text">
                                            {question.question.question}
                                          </p>
                                          {question.question.answerType ==
                                            1 && (
                                            <div
                                              className="profile-input-border"
                                              style={{
                                                width: "60%",
                                                border: 0,
                                              }}
                                            >
                                              <Form.Item name="questionnaireName">
                                                <Input
                                                  className="profile-input"
                                                  placeholder="FULL NAME"
                                                  disabled={true}
                                                />
                                              </Form.Item>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            2 && (
                                            <div
                                              style={{
                                                border: 0,
                                                width: "60%",
                                                marginTop: 10,
                                                marginLeft: 7,
                                              }}
                                            >
                                              <Form.Item>
                                                <DatePicker
                                                  disabled
                                                  style={{ width: "100%" }}
                                                  format="DD-MM-YYYY"
                                                />
                                              </Form.Item>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            3 && (
                                            <div style={{ width: "60%" }}>
                                              <Select
                                                style={{
                                                  marginTop: 10,
                                                  marginLeft: 7,
                                                  width: "100%",
                                                }}
                                              >
                                                {question.questionOptions
                                                  .length > 0 &&
                                                  question.questionOptions.map(
                                                    (option, index) => {
                                                      return (
                                                        <Option
                                                          value={
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        >
                                                          {
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            4 && (
                                            <div
                                              style={{
                                                width: "60%",
                                                display: "flex",
                                              }}
                                            >
                                              <Radio.Group>
                                                {question.questionOptions
                                                  .length > 0 &&
                                                  question.questionOptions.map(
                                                    (option, index) => {
                                                      return (
                                                        <Radio
                                                          value={
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        >
                                                          {
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        </Radio>
                                                      );
                                                    }
                                                  )}
                                              </Radio.Group>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            5 && (
                                            <div
                                              style={{
                                                width: "60%",
                                                display: "flex",
                                              }}
                                            >
                                              <Checkbox.Group>
                                                {question.questionOptions
                                                  .length > 0 &&
                                                  question.questionOptions.map(
                                                    (option, index) => {
                                                      return (
                                                        <Checkbox
                                                          value={
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        >
                                                          {
                                                            option
                                                              .questionOption
                                                              .option
                                                          }
                                                        </Checkbox>
                                                      );
                                                    }
                                                  )}
                                              </Checkbox.Group>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            6 && (
                                            <div
                                              className="profile-input-border"
                                              style={{
                                                border: 0,
                                                width: "60%",
                                                marginTop: 10,
                                                marginLeft: 7,
                                              }}
                                            >
                                              <Form.Item>
                                                <DatePicker
                                                  disabled
                                                  style={{ width: "100%" }}
                                                  format="MM-YYYY"
                                                />
                                              </Form.Item>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            7 && (
                                            <div
                                              style={{
                                                border: 0,
                                                width: "60%",
                                                marginTop: 10,
                                                marginLeft: 7,
                                              }}
                                            >
                                              <Form.Item>
                                                <textarea
                                                  rows={5}
                                                  className="cv-textarea"
                                                />
                                              </Form.Item>
                                            </div>
                                          )}
                                          {question.question.answerType ===
                                            8 && (
                                            <div
                                              style={{
                                                border: 0,
                                                width: "60%",
                                                marginTop: 10,
                                                marginLeft: 7,
                                              }}
                                            >
                                              <Form.Item>
                                                <Input
                                                  type="file"
                                                  className="profile-input"
                                                />
                                              </Form.Item>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="cq-optional-cont">
                                        {question.questionOptions &&
                                          question.questionOptions.map(
                                            (questionOption, index) => {
                                              return (
                                                <div>
                                                  {questionOption.questionOption.optionalQuestions.map(
                                                    (optQuestion, ind) => {
                                                      return (
                                                        <div
                                                          key={index}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <p className="cq-label-text">
                                                              {
                                                                optQuestion
                                                                  .question
                                                                  .question
                                                              }
                                                            </p>
                                                            {optQuestion
                                                              .question
                                                              .answerType ==
                                                              1 && (
                                                              <div
                                                                className="profile-input-border"
                                                                style={{
                                                                  width: "60%",
                                                                  border: 0,
                                                                }}
                                                              >
                                                                <Form.Item name="questionnaireName">
                                                                  <Input
                                                                    className="profile-input"
                                                                    placeholder="FULL NAME"
                                                                    disabled={
                                                                      true
                                                                    }
                                                                  />
                                                                </Form.Item>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              2 && (
                                                              <div
                                                                style={{
                                                                  border: 0,
                                                                  width: "60%",
                                                                  marginTop: 10,
                                                                  marginLeft: 7,
                                                                }}
                                                              >
                                                                <Form.Item>
                                                                  <DatePicker
                                                                    disabled
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    format="DD-MM-YYYY"
                                                                  />
                                                                </Form.Item>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              3 && (
                                                              <div
                                                                style={{
                                                                  width: "60%",
                                                                }}
                                                              >
                                                                <Select
                                                                  style={{
                                                                    marginTop: 10,
                                                                    marginLeft: 7,
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  {optQuestion
                                                                    .questionOptions
                                                                    .length >
                                                                    0 &&
                                                                    optQuestion.questionOptions.map(
                                                                      (
                                                                        option,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <Option
                                                                            value={
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          >
                                                                            {
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          </Option>
                                                                        );
                                                                      }
                                                                    )}
                                                                </Select>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              4 && (
                                                              <div
                                                                style={{
                                                                  width: "60%",
                                                                  display:
                                                                    "flex",
                                                                }}
                                                              >
                                                                <Radio.Group>
                                                                  {optQuestion
                                                                    .optionValues
                                                                    .length >
                                                                    0 &&
                                                                    question.optionValues.map(
                                                                      (
                                                                        option,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <Radio
                                                                            value={
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          >
                                                                            {
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          </Radio>
                                                                        );
                                                                      }
                                                                    )}
                                                                </Radio.Group>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              5 && (
                                                              <div
                                                                style={{
                                                                  width: "60%",
                                                                  display:
                                                                    "flex",
                                                                }}
                                                              >
                                                                <Checkbox.Group>
                                                                  {question
                                                                    .optionValues
                                                                    .length >
                                                                    0 &&
                                                                    question.optionValues.map(
                                                                      (
                                                                        option,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <Checkbox
                                                                            value={
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          >
                                                                            {
                                                                              option
                                                                                .questionOption
                                                                                .option
                                                                            }
                                                                          </Checkbox>
                                                                        );
                                                                      }
                                                                    )}
                                                                </Checkbox.Group>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              6 && (
                                                              <div
                                                                className="profile-input-border"
                                                                style={{
                                                                  border: 0,
                                                                  width: "60%",
                                                                  marginTop: 10,
                                                                  marginLeft: 7,
                                                                }}
                                                              >
                                                                <Form.Item>
                                                                  <DatePicker
                                                                    disabled
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                    format="MM-YYYY"
                                                                  />
                                                                </Form.Item>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              7 && (
                                                              <div
                                                                style={{
                                                                  border: 0,
                                                                  width: "60%",
                                                                  marginTop: 10,
                                                                  marginLeft: 7,
                                                                }}
                                                              >
                                                                <Form.Item>
                                                                  <textarea
                                                                    rows={5}
                                                                    className="cv-textarea"
                                                                  />
                                                                </Form.Item>
                                                              </div>
                                                            )}
                                                            {optQuestion
                                                              .question
                                                              .answerType ===
                                                              8 && (
                                                              <div
                                                                style={{
                                                                  border: 0,
                                                                  width: "60%",
                                                                  marginTop: 10,
                                                                  marginLeft: 7,
                                                                }}
                                                              >
                                                                <Form.Item>
                                                                  <Input
                                                                    type="file"
                                                                    className="profile-input"
                                                                  />
                                                                </Form.Item>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {location.state.questionnaire.deceleration && (
                      <div>
                        <div
                          className="cq-form-cont"
                          style={{
                            width: "100%",
                            marginLeft: 0,
                            marginTop: 0,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingBottom: 30,
                          }}
                        >
                          <div
                            className="form-cont"
                            style={{
                              justifyContent: "space-between",
                              paddingTop: 20,
                              paddingBottom: 0,
                              display: "block",
                            }}
                          >
                            <Checkbox />
                            <span style={{ marginLeft: 5, fontSize: 12 }}>
                              I hereby declare that the information given by me
                              in this application is true and correct to the
                              best of my knowledge.I understand and agree that
                              any false information, misrepresentation, or
                              omission of facts in this application and the
                              application process may be justification for
                              refusal/ decline.I further understand and agree
                              that all information furnished in this application
                              and the application process may be verified as may
                              be necessary.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {location.state.questionnaire.allowAttachments && (
                      <div>
                        <div
                          className="cq-form-cont"
                          style={{
                            width: "100%",
                            marginLeft: 0,
                            marginTop: 10,
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingBottom: 30,
                          }}
                        >
                          <div
                            className="form-cont"
                            style={{
                              justifyContent: "center",
                              paddingTop: 20,
                              paddingBottom: 0,
                              display: "flex",
                            }}
                          >
                            <span style={{ marginLeft: 5, fontSize: 12 }}>
                              Drop or click here to attach CV and other
                              documents. Max 10 files
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {/*<div style={{ display: "flex" }}>
                      <Button
                        loading={this.state.loading}
                        type="primary"
                        className="cq-primary-btn"
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        SAVE
                      </Button>
                      <Button
                        loading={this.state.loading}
                        type="primary"
                        htmlType="submit"
                        className="cq-primary-btn"
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        PREVIEW
                      </Button>
                      <Form.Item>
                        <Button
                          loading={this.state.loading}
                          type="primary"
                          className="cq-primary-btn"
                          style={{ marginTop: 20 }}
                        >
                          CLOSE
                        </Button>
                      </Form.Item>
                    </div>*/}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionnairePreview;
