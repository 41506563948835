import { api } from "../../services/api";
import * as types from "../Constants";

export const addClientAdmission = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };

  options.types = [
    types.CLIENT_ADMISSION_SUCCESS,
    types.CLIENT_ADMISSION_FAILURE,
  ];

  return api.post(options, data);
};

export const getAdmissionProgram = () => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");

  const options = {
    url: `v1/client/programdetail/All/${clientprofileid}`,
  };

  options.types = [
    types.GET_ADMISSION_PROGRAM_SUCCESS,
    types.GET_ADMISSION_PROGRAM_FAILURE,
  ];

  return api.get(options);
};

export const removeAdmissionProgram = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };

  options.types = [
    types.DELETE_ADMISSION_PROGRAM_SUCCESS,
    types.DELETE_ADMISSION_PROGRAM_FAILURE,
  ];

  return api.delete(options, data);
};

export const getSchoolType = () => {
  const options = {
    url: "v1/school/type",
  };

  options.types = [
    types.GET_SCHOOL_TYPES_SUCCESS,
    types.GET_SCHOOL_TYPES_FAILURE,
  ];

  return api.get(options);
};

export const getSchools = (data) => {
  const options = {
    url: "v1/school/All/" + data,
  };

  options.types = [types.GET_SCHOOLS_SUCCESS, types.GET_SCHOOLS_FAILURE];

  return api.get(options);
};

export const getSchoolLevel = (data) => {
  const options = {
    url: "v1/school/commission/All/" + data,
  };

  options.types = [
    types.GET_SCHOOL_LEVEL_SUCCESS,
    types.GET_SCHOOL_LEVEL_FAILURE,
  ];

  return api.get(options);
};

export const addCommissionPayment = (data) => {
  const options = {
    url: "",
  };

  options.types = [
    types.ADD_COMISSION_FORM_SUCCESS,
    types.ADD_COMISSION_FORM_FAILURE,
  ];

  return api.post(options);
};

export const getCommissionPayment = (data) => {
  const options = {
    url: "",
  };

  options.types = [
    types.GET_COMISSION_PAYMENT_SUCCESS,
    types.GET_COMISSION_PAYMENT_FAILURE,
  ];

  return api.get(options);
};

export const addAdmissionForm = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };

  options.types = [
    types.UPD_ADMISSION_FORM_SUCCESS,
    types.UPD_ADMISSION_FORM_FAILURE,
  ];

  return api.put(options, data);
};

export const getAdmissionStatuses = () => {
  const options = {
    url: "v1/client/programdetail/Status",
  };

  options.types = [
    types.GET_ADMISSION_STATUS_SUCCESS,
    types.GET_ADMISSION_STATUS_FAILURE,
  ];

  return api.get(options);
};

export const updAdmissionStatus = (data) => {
  const options = {
    url: "v1/client/programdetail",
  };

  options.types = [
    types.UPD_ADMISSION_STATUS_SUCCESS,
    types.UPD_ADMISSION_STATUS_FAILURE,
  ];

  return api.put(options, data);
};

export const addCommissionReminder = (data) => {
  const options = {
    url: "v1/commission/reminder",
  };

  options.types = [
    types.ADD_ADMISSION_REMINDER_SUCCESS,
    types.ADD_ADMISSION_REMINDER_FAILURE,
  ];

  return api.post(options, data);
};
