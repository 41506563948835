import React, { useState } from "react";
import { Upload, Button, message, Row, Col, Modal, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DeviceHub } from "@material-ui/icons";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const CreateEmailDocuments = ({
  onAddEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,
}) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, SetPreviewVisible] = useState(false);
  const [previewImage, SetPreviewImage] = useState("");
  const [previewTitle, SetPreviewTitle] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const [imageValues, setImageValues] = useState({});

  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };

  const handleCancel = () => SetPreviewVisible(false);

  /////////////////

  const onHandleChange = ({ fileList }) => {
    setFileList(fileList);

    setLoading(true);
    let formData = new FormData();

    if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile =
          fileList[i] && fileList[i].originFileObj && fileList[i].originFileObj;

        formData.append("file", selectedFile);

        onUploadAvatar(formData)
          // onGetEmployerDocument(clientprofileid)
          .then(() => {
            setLoading(false);
            handleCancel();
            message.success("Successfully Added!");
          });
        const JsonData = {
          SubjectId: clientprofileid && clientprofileid,
          FamilyId: "00000000-0000-0000-0000-000000000000",
          DocumentTypeId: 2,
          Title: selectedFile && selectedFile.name,
          SizeInKB: selectedFile && selectedFile.size,
          DocuementExtension: selectedFile && selectedFile.type,
          IsAgent: true,
          IsClient: true,
          ShowAgent: true,
          ShowClient: true,
          QuestionnaireId: 0,
        };

        formData.append("Data", JSON.stringify(JsonData));
      }
    }
  };

  // const handleSubmit = () => {

  // };

  /////////////////

  const onChange = ({ fileList }) => {
    if (
      fileList &&
      fileList[0] &&
      fileList[0][fileList[0].length - 1] &&
      fileList[0][fileList[0].length - 1].size > 20 * 1024 * 1024
    ) {
      message.warning("File size should not be greater than 20MB");
    } else {
      setFileList(fileList);
    }
  };

  // const onChange = (info) => {
  //   if (info.file.status === "uploading") {
  //     // setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Get this url from response in real world.
  //
  //     setImageValues(info.file.originFileObj);
  //     getBase64(
  //       info.file.originFileObj
  //       // setLoading(false)
  //     );
  //   }
  // };

  const getUploadedFileData = (formData, selectedFile) => {
    return onUploadAvatar(formData).then((res) => {
      if (res) {
        let fileURLS = [];

        fileURLS.push({
          attachmentURL: res && res.payload,
          attachmentName: selectedFile.name,
        });

        setAttachments(fileURLS[0]);
      }
    });
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("valuesvaluesvalues ", values);
    // const JsonData = {
    //   id: 0,
    //   subjectId: "86209A03-F000-42BE-B890-9A2EBE637B5B",
    //   familyId: "00000000-0000-0000-0000-000000000000",
    //   documentTypeId: 0,
    //   title: "string",
    //   sizeInKB: 0,
    //   docuementExtension: "string",
    //   docuementBlobUrl: "string",
    //   blobFileName: "string",
    //   isAgent: true,
    //   isClient: true,
    //   showAgent: true,
    //   showClient: true,
    //   questionnaireId: 0,
    //   createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   createdDate: "2021-04-15T07:25:59.887Z",
    //   modifiedDate: "2021-04-15T07:25:59.887Z",
    //   subjectTypeId: 0,
    // };
    // let formData = new FormData();
    //
    // if (fileList) {
    //   formData.append("File", fileList && fileList);
    //   formData.append("Data", JsonData);
    //
    //   onAddEmployerDocument(formData);
    // await getUploadedFileData(formData, fileList);

    // for (let index = 0; index < fileList.length; index++) {
    //   let selectedFile =
    //     fileList && fileList[index] && fileList[index].originFileObj;
    //   formData.append("File", selectedFile);
    //   if (selectedFile) {
    //
    //     await getUploadedFileData(formData, selectedFile);
    //   }
    // }
    // }
    // let data = {
    //   name: fileList.name,
    //   size: fileList.size,
    //   lastModifiedDate: fileList.lastModifiedDate,
    //   type: fileList.type,
    // };
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    SetPreviewVisible(true);
    SetPreviewImage(file.url || file.preview);

    SetPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  console.log(
    "fileListfileListfileselectedFileselectedFileselectedFileList====>",
    fileList
  );

  return (
    <Row>
      <Col span={24}>
        <div className="ts-upload-file-cont">
          <Form onFinish={onFinish} form={form}>
            <Form.Item name="username">
              <Upload
                multiple
                style={{ backgroundColor: "#fff" }}
                listType="picture-card"
                fileList={fileList}
                beforeUpload={() => false}
                onPreview={handlePreview}
                onChange={onHandleChange}
                className={"upload-technical-support"}
              >
                {fileList.length < 10 && (
                  <div className="import-file-button-sec">
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            {/* <Button
              // onClick={handleSubmit}
              className="float-right"
              htmlType="submit"
              type="primary"
            >
              Upload
            </Button> */}
          </Form>

          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

export default CreateEmailDocuments;
