import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "./../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "./../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

const DefaultFontSettings = ({
  onGetImapForAll,
  onUpdImapForAll,
  imapForAllRes,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [FontChange, setFontChange] = useState("");
  const state = useLocation().state;

  const { Option } = Select;
  useEffect(() => {
    setLoading(true);

    onGetImapForAll()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (imapForAllRes) {
      form.setFieldsValue({
        imapForAll: imapForAllRes.imapForAll,
      });
    }
  }, [imapForAllRes]);

  const onFontChange = (value) => {
    setFontChange(value);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);
    const data = {
      imapForAll: values.imapForAll,
    };

    onUpdImapForAll(data).then(() => {
      onGetImapForAll().then(() => {
        setLoading(false);
        message.success("Successfully Updated!");
      });
    });
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.important} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Auto Import Settings</span>
                <Tooltip
                  placement="topLeft"
                  title={`When you turn Auto Email import on, Ezymigrate will automatically import all email correspondence between you and your clients.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>

              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    onFinish={onFinish}
                    form={form}
                    className="d-flex space-between"
                  >
                    <div className="reminder-set-form margin-top-34">
                      <Form.Item
                        className="d-block"
                        name="imapForAll"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Default settings for all email addresses
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <div className="mail-chimp-btn reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="button-blue"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DefaultFontSettings;
