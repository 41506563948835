import * as types from "../Constants";
import {message} from "antd";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const ownerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.OWNER_SUCCESS:
      return { ...state, ownerData: action.payload };
    case types.OWNER_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_OWNER_SUCCESS:
      if( action && action.payload === "This email is already taken")
      {
           message.error(action.payload)
      }
      else
      {
        return { ...state, updOwnerSuccess: action.payload };
      }

    case types.UPDATE_OWNER_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
