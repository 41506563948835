import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentType from "../AccountSetting/DocumentTypes/DocumentTypes";

import {
  getDocumentType,
  addDocumentType,
  updetaDocumentType,
  removeDocumentType,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    documentTypeRes: state.documentTypeReducer.documentTypeRes,
    addDocumentTypeRes: state.documentTypeReducer.addDocumentTypeRes,
    updateDocumentTypeRes: state.documentTypeReducer.updateDocumentTypeRes,
    removeDocumentTypeRes: state.documentTypeReducer.removeDocumentTypeRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocumentType: bindActionCreators(getDocumentType, dispatch),
    onAddDocumentType: bindActionCreators(addDocumentType, dispatch),
    onUpdetaDocumentType: bindActionCreators(updetaDocumentType, dispatch),
    onRemoveDocumentType: bindActionCreators(removeDocumentType, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentType);
