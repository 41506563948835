import React from "react";
import { Row, Col } from "antd";

import "./PrivacyPolicy.css";

import { Images } from "../Themes";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const {} = this.state;
    const {} = this.props;
    return (
      <div>
        <div
          className="pp-container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <img src={Images.logo} />
          </div>

          <a href="https://app.ezymigrate.com/login" target="_blank">
            <button class="sign-in-btn btn-default">
              <i class="fa fa-sign-in" aria-hidden="true"></i> sign in
            </button>
          </a>
        </div>

        <div
          style={{ padding: 80, paddingTop: 100, backgroundColor: "#FFFFFF" }}
        >
          <div style={{ marginTop: 20 }}>
            <span className="pp-heading-text">
              EZY SYSTEM(S) ( EZYMIGRATE & EZYFORMS) PRIVACY POLICY
            </span>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>
          <div style={{ marginTop: 20 }}>
            <span className="pp-heading-text">Last updated November 2021</span>
          </div>
          <div style={{ marginTop: 30 }}>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">1.&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">EZYMIGRATE PRIVACY POLICY</span>
            </p>
          </div>

          <div style={{ marginTop: 30 }}>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">1.1</span>
              <span className="pp-heading-text">&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate &nbsp;is committed to ensuring compliance with the
                New Zealand Privacy Act 1993 and the European Union’s General
                Data Protection Regulation (GPDR).&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">1.2</span>
              <span className="pp-heading-text">&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate’s &nbsp;Privacy Policy applies to anyone who visits
                the Ezymigrate website &nbsp;or uses Ezymigrate platform. From
                time to time, &nbsp;Ezymigrate may change this Privacy Policy
                and will post the changes on the Ezymigrate website. If You
                don’t agree with the changes to the Privacy Policy then you may
                not be able to fully use Ezymigrate Website and Web
                Application.&nbsp;
              </span>
            </p>
          </div>

          <div style={{ marginTop: 30 }}>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">2.&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">WHAT EZYMIGRATE COLLECTS</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">2.1</span>
              <span>&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate may collect from You:
              </span>
            </p>
          </div>

          <div style={{ marginTop: 30 }}>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;(a)</span>
              <span>&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Your Personal Information, including:
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (i) Your Sensitive Information;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (ii) Your name;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (iii) Your contact information, including email address;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (iv) Information that you disclose to us in relation to your
                business processes that help us advise you on suitable features
                and services .&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (iv) Other information relevant to surveys or interviews You
                participate in and/or offers You accept; and&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (v) Information about Your preferences and activities on
                Ezymigrate Application; and&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(b)</span>
              <span>&nbsp;&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Personal Information (including Sensitive Information) about
                anyone else that You give to Ezymigrate.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">2.2</span>
              <span>&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                When You disclose Your Personal Information to Ezymigrate
                &nbsp;it must be accurate, complete, up to date and not
                misleading. If You give Ezymigrate Personal information about
                someone else, then You must be authorised to do so, and You must
                tell that person about this Privacy Policy.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;3.&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                HOW EZYMIGRATE COLLECTS YOUR PERSONAL INFORMATION
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;3.1</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;
              </span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate collects Your Personal Information only when needed
                and for lawful purposes connected with performing its functions
                or activities. You authorise the collection of Your Personal
                Information from Ezymigrate when You:&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(a)</span>
              <span>&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                Use the Ezymigrate on Your computer, mobile, tablet or any other
                electronic device;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(b)&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Create a user account with Ezymigrate;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(c)</span>
              <span>&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Communicate with our team members by phone, email, app, via the
                Ezymigrate website or otherwise;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(d)&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Enter into any Ezymigrate educational program, competitions
                and/or promotions.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;3.2</span>
              <span>&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate may also collect Your Personal Information from
                publicly available sources or from Third Parties from time to
                time.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;4.&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                HOW EZYMIGRATE WILL USE YOUR PERSONAL INFORMATION
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">&nbsp;4.1&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                You authorise Ezymigrate &nbsp;to use Your Personal Information
                (and where necessary), to disclose Your Personal Information to
                Third Parties for the following Purposes:&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(a)&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                for the Purposes of providing You with the Ezymigrate Services,
                including to:
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (i) communicate with You, including to send promotional emails
                about new services, special offers, promotions or other
                information;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (ii) provide client services;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (iii) respond to Your queries regarding Ezymigrate features and
                services;&nbsp;
              </span>
            </p>
          </div>
          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (iv) verify Your identity when You create a user account with
                Ezymigrate;&nbsp;
              </span>
            </p>
          </div>
          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (v) administer Your account; (vi) provide You with a good user
                experience;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (vii) develop new Services, features and/or Content; and&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                (viii) keep You updated on things that are happening at
                Ezymigrate;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(b)&nbsp;</span>
              <span>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                for internal record keeping purposes;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(c)</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;to the extent necessary to perform the purposes specified
                in this Privacy Policy and to allow for the operation of
                Ezymigrate’s business activities and functions;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(d)</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; for any other purposes You authorise;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(e)</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; for the purpose of Ezymigrate selling all or most of
                Ezymigrate’s assets; and
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">(f)</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; to comply with Ezymigrate’s Legal Obligations
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">4.2&nbsp;</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                You agree that EZYMigrate may share Your Personal Information
                with Third Parties to fulfil business transactions for the
                purposes specified in this Privacy Policy (for example providing
                customer services, sending marketing communications, and
                providing maintenance services).&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                5. HOW LONG EZYMIGRATE HOLDS YOUR PERSONAL INFORMATION
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;Ezymigrate will hold Your Personal Information for as long
                as Your Personal Information is required for the Purposes and
                for as long as is necessary to comply with its Legal
                Obligations.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                6. HOW EZYMigrate STORES AND PROTECTS YOUR PERSONAL
                INFORMATION&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate will use reasonable care to protect Your Personal
                Information from unauthorised access, use, modification,
                disclosure, and loss.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                7. EZYMigrate MAY TRANSFER YOUR PERSONAL INFORMATION TO OTHER
                COUNTRIES
              </span>
              <span>&nbsp;</span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                In order to provide You with Ezymigrate Services, Ezymigrate may
                need to disclose and transfer Your Personal Information to Third
                Parties located in a different country from where You live. This
                may include countries located outside the European Economic Area
                (EEA). If those countries do not have data protection laws
                comparable to the data protection laws of Your country of
                residence, then Ezymigrate will take reasonable steps to ensure
                that Your Personal Information (including any Sensitive
                Information) is protected in those countries.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;
              </span>
              <span className="pp-heading-text">
                8. FOR THE PURPOSE OF THE GDPR
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">8.1</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Ezymigrate is the data controller for personal data
                collected by it in the provision of its Services, support and
                assistance to You as the user. If &nbsp;Ezymigrate uses personal
                data that has been collected by a Third Party, it will process
                the information as a data processor on behalf of the Third Party
                that collected Your Personal Information.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">8.2</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Request Access to Your Personal Information: You have the
                right to request access to any Personal Information that
                Ezymigrate holds about You or your clients, the right to know
                the source of Your Personal Information and the processing
                methods used by Ezymigrate. If EZYMigrate cannot give You access
                to the Personal Information that You have requested, then
                EZYMigrate will let You know the legal reasons for not
                disclosing Your Personal Information. If You are not happy with
                the legal reasons provided, then You have the right to complain
                to the New Zealand Privacy Commissioner:
                www.privacy.org.nz/your-rights/how-to-complain/.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">8.3</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Request correction of Your Personal Information: You have
                the right to request correction of the Personal Information that
                EZYMigrate holds about You. EZYMigrate will correct Your
                Personal Information if it is necessary to ensure that Your
                Personal Information is accurate, up to date, complete and not
                misleading. If EZYMigrate decides that it is unnecessary to
                correct Your Personal Information, EZYMigrate will let You know
                the legal reasons for its decision. If You are not happy with
                those reasons, then You have the right to complain to the New
                Zealand Privacy Commissioner:
                www.privacy.org.nz/your-rights/how-to-complain/.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">8.4</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Request to restrict the use and processing of or delete
                Your Personal Information: You have the right to request that
                EZYMigrate delete Your Personal Information when it is no longer
                needed for the Purposes, or withdraw your authorisation for
                EZYMigrate to use Your Personal Information. You also have the
                right to restrict Your Personal Information from being processed
                in the event You consider Your rights and freedoms outweigh our
                legitimate interests in using Your Personal Information.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">8.5</span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Data portability: You have the right to request that
                EZYMigrate transfer Your Personal Information from one
                electronic processing system to another electronic processing
                system.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;9. RIGHTS OF ACCESS AND CORRECTION OF YOUR PERSONAL
                INFORMATION
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Under the Privacy Act 1993, You have the right to request access
                to any Personal Information that EZYMigrate holds about You and
                the right to request correction of the Personal Information that
                EZYMigrate holds about You. If EZYMigrate cannot give You access
                to the Personal Information that You have requested, then
                EZYMigrate will let You know the legal reasons for not
                disclosing Your Personal Information. EZYMigrate will correct
                Your Personal Information if it is necessary to ensure that Your
                Personal Information is accurate, up to date, complete and not
                misleading. If EZYMigrate decides that it is unnecessary to
                correct Your Personal Information, You have the right to request
                a statement of the correction sought but not made be attached to
                the information. If You are not happy with the response to your
                request, then You have the right to complain to the New Zealand
                Privacy Commissioner:
                www.privacy.org.nz/your-rights/how-to-complain/.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                10. INFORMATION THAT EZYMIGRATE PROCESSES ON YOUR BEHALF
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.1&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                Information entrusted to Ezymigrate and purpose
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Information provided in connection with services : You may
                entrust information that you or your organization (“you”)
                control, to Ezymigrate in connection with use of our services or
                for requesting technical support for our products. This includes
                information regarding your customers and your employees (if you
                are a controller) or data that you hold and use on behalf of
                another person for a specific purpose, such as a customer to
                whom you provide services (if you are a processor). The data may
                either be stored on &nbsp;Microsoft Azure SQL Server or
                transferred or shared to us as part of a request for technical
                support or other services.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p d className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We recognize that you own your service data. We provide you
                complete control of your service data by providing you the
                ability to (i) access your service data, (ii) share your service
                data through supported third-party integrations, and (iii)
                request export or deletion of your service data.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.2&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">How we use service data</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We process your service data when you provide us instructions
                through the various modules of our services. For example, when
                you generate an invoice, information such as the name and
                address of your customer will be used to generate the invoice;
                and when you use our campaign management service for email
                marketing, the email addresses of the persons on your mailing
                list will be used for sending the emails.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.3 Push notifications</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                If you have enabled notification on our desktop and mobile
                applications, we will push notifications through a push
                notification provider such as Apple Push Notification Service,
                Google Cloud Messaging or Windows Push Notification Services.
                You can manage your push notification preferences or deactivate
                these notifications by turning off notifications in the
                application or device settings.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.4&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                Who we share service data with
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Employees and independent contractors : We may provide access to
                your service data to our employees and individuals who are
                independent contractors of Ezymigrate entities involved in
                providing the services (collectively our “employees”) so that
                they can
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;(i) identify, analyze and resolve errors,
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;(ii) manually verify emails reported as spam to improve
                spam detection, or
              </span>
            </p>
          </div>

          <div>
            <div>
              <p>
                <br />
              </p>
            </div>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We ensure that access by our employees to your service data is
                restricted to specific individuals, and is logged and audited.
                Our employees will also have access to data that you knowingly
                share with us for technical support or to import data into our
                products or services. We communicate our privacy and security
                guidelines to our employees and strictly enforce privacy
                safeguards within the Ezymigrate.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.5</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">
                Third-party integrations you have enabled
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;:
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;Some of our products and services support integrations
                with third-party products and services. If you choose to enable
                any third-party integrations, you may be allowing the third
                party to access your service information and personal
                information about you. We encourage you to review the privacy
                practices of the third-party services and products before you
                enable integrations with them.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.6</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">Retention of information</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We hold the data in your account as long as you choose to use
                Ezymigrate Services. Once you terminate your Ezymigrate user
                account, your data will eventually get deleted from the active
                database during the next clean-up that occurs once in 6
                months.&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">10.7</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">Data subject requests</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                If you are from the European Economic Area and you believe that
                we store, use or process your information on behalf of one of
                our customers, please contact the customer if you would like to
                access, rectify, erase, restrict or object to processing, or
                export your personal data. We will extend our support to our
                customer in responding to your request within a reasonable
                timeframe.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">11. MINORS</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;Ezymigrate does not knowingly collect Personal Information
                from children (as defined by local laws) and does not target its
                Website and/or Services towards children.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">12.&nbsp;</span>
              <span className="pp-heading-text">
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span>
              <span className="pp-heading-text">PRIVACY COMPLAINTS</span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;If You think that Your privacy rights have been breached,
                You can make a written complaint to EZYMigrate at&nbsp;
                <span
                  onClick={() =>
                    (window.location = "mailto:info@ezymigrate.co.nz")
                  }
                  style={{
                    color: "#064eae",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  info@ezymigrate.co.nz
                </span>
                , or otherwise contact the NZ Privacy Commissioner at
                <a href="https://www.privacy.org.nz/your-rights/making-a-complaint/complaintform/">
                  https://www.privacy.org.nz/your-rights/making-a-complaint/complaintform/
                </a>
                .&nbsp;
                <br />
                If you are a resident in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your local data protection supervisory
                authority. You can find their contact details here:
                <br />
                <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                  http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                </a>
                <br />
                If you are a resident in Switzerland, the contact details for
                the data protection authorities are available here:
                <br />
                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                  https://www.edoeb.admin.ch/edoeb/en/home.html
                </a>
                .
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">13. COOKIES</span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              {/* <span className="pp-heading-text">13.1&nbsp;</span> */}
              {/* <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;
              </span>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                <span>&nbsp; &nbsp;&nbsp;</span>
              </span> */}
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezymigrate may use cookies in order to make it easier to use the
                Ezymigrate application and to help Ezymigrate to analyse the
                profile of visitors and users in order to improve usability and
                features of the Ezymigrate Website and Services.
                <br />{" "}
                <p
                  className="pp-heading-text"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  (a) Session cookies: These are used to store information about
                  Your activities so You can move from one webpage to another.
                  These cookies track Your movement from webpage to webpage of a
                  website so that You do not have to provide the same
                  information provided on earlier webpages. Session cookies only
                  remain in the cookie file of Your browser until You close the
                  browser.{" "}
                </p>
                (b) To enable some of the features and functionality of the
                Ezymigrate application, You will need to permit Ezymigrate to
                place cookies on Your device. If You choose not to, then those
                features and functionality will not be available to You. You can
                remove or block cookies by using the settings in Your browser
                but it may affect Your ability to use the EZYMigrate .
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;14. Google API Limited Use Disclosure
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Rightly’s use and transfer to any other app of information
                received from Google APIs will adhere to{" "}
                <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                  Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;15. EZYFORMS CHROME EXTENSION PRIVACY NOTICE
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezysystem has a chrome extension called Ezyforms. Ezyforms
                extension fetches your information from the Ezysystem and
                populates it to another website to fill a webform with your
                consent. We are committed to protecting your information and
                your right to privacy. If you have any questions or concerns
                about this privacy notice, or our practices with regards to your
                information, please contact us at&nbsp;
                <span
                  onClick={() =>
                    (window.location = "mailto:support@ezymigrate.co.nz")
                  }
                  style={{
                    color: "#064eae",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  support@ezymigrate.co.nz
                </span>{" "}
                This section of privacy notice applies to all information
                collected through our Ezyforms (which, as described above works
                with Ezysystem), as well as, any related services, sales,
                marketing or events.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;16. WHAT INFORMATION EZYFORMS COLLECT?
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                16.1 Personal information you disclose to us
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "600" }}>
                In Short: We only collect information to authenticate a user .
                We do not collect any other personal information
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezyforms only collects users' email and password to authenticate
                its validation as an Ezymigrate active user.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                16.2 Information automatically collected
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "600" }}>
                In Short: Some information — such as your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected
                automatically when you visit our Website.
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We automatically collect certain information when you visit, use
                or navigate the Website. This information does not reveal your
                specific identity (like your name or contact information) but
                may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Website and
                other technical information.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;17. WHAT INFORMATION EZYFORMS COLLECT?
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "600" }}>
                In Short: We only share information with your consent, to comply
                with laws, to provide you with services, to protect your rights,
                or to fulfill business obligations.
              </span>
            </p>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We may process and transfer your information that we hold (on
                Ezy-System) based on the following legal basis:
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.1 Consent:{" "}
                <span style={{ fontWeight: "400" }}>
                  We may process/transfer your data if you have given us
                  specific consent to use your information for the purpose of
                  filling a webform on your selected website.
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.2 Legitimate Interests:{" "}
                <span style={{ fontWeight: "400" }}>
                  We may process/transfer your data when it is reasonably
                  necessary to achieve our legitimate business interests.
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.3 Performance of a Contract:{" "}
                <span style={{ fontWeight: "400" }}>
                  Where we have entered into a contract with you, we may process
                  your information to fulfill the terms of our contract.
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.4 Legal Obligations:{" "}
                <span style={{ fontWeight: "400" }}>
                  We may disclose your information where we are legally required
                  to do so in order to comply with applicable law, governmental
                  requests, a judicial proceeding, court order, or legal
                  process, such as in response to a court order or a subpoena
                  (including in response to public authorities to meet national
                  security or law enforcement requirements).
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.5 Vital Interests:{" "}
                <span style={{ fontWeight: "400" }}>
                  We may disclose your information where we believe it is
                  necessary to investigate, prevent, or take action regarding
                  potential violations of our policies, suspected fraud,
                  situations involving potential threats to the safety of any
                  person and illegal activities, or as evidence in litigation in
                  which we are involved.
                </span>
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                17.6 Business Transfers:{" "}
                <span style={{ fontWeight: "400" }}>
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                &nbsp;18. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text">
                In Short: We may use cookies and other tracking technologies to
                collect and store your information
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                19. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text">
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than .
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <p
                className="pp-heading-text"
                style={{ fontWeight: "400", marginLeft: 36 }}
              >
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                20. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text">
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our Website is at your own risk. You should only access the
                Website within a secure environment.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                21. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                22. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text">
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </span>
            </p>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                23. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                If you have questions or comments about this notice, you may
                contact our Data Protection Officer (DPO), Anna Hyatt at&nbsp;
                <span
                  onClick={() =>
                    (window.location = "mailto:anna@ezymigrate.co.nz")
                  }
                  style={{
                    color: "#064eae",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  anna@ezymigrate.co.nz
                </span>{" "}
                , or by post to: <br />
                12/112 Bush Road , Rosedale, Auckland 0632 New Zealand
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">
                24. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please email your request at&nbsp;
                <span
                  onClick={() =>
                    (window.location = "mailto:support@ezymigrate.co.nz")
                  }
                  style={{
                    color: "#064eae",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  support@ezymigrate.co.nz
                </span>
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div">
              <span className="pp-heading-text">25. DEFINITIONS</span>
            </p>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                “Ezymigrate” means a product of Smitten Online Ltd T/A
                Ezymigrate
              </span>
            </p>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                “Ezyforms” means a product of Smitten Online Ltd T/A Ezyforms
              </span>
            </p>
          </div>
          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                Ezysystem(s) means Ezymigrate or any product or service provided
                by Smitten Online Ltd T/A Ezymigrate
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                “Ezymigrate Services”: means any Ezymigrate application,
                products, services, software, Content, features, functionalities
                and any other services provided to You in person, by phone,
                email, app, or via the Ezymigrate Website;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp;“Ezymigrate Website” means the website (or websites)
                located at&nbsp;
              </span>
              <a
                href="http://www.ezymigrate.com"
                style={{ textDecoration: "none" }}
              >
                <span className="pp-link-text">www.ezymigrate.com</span>
              </a>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                ;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                “Legal Obligations” means any legal or regulatory obligation
                that Ezymigrate has under the laws of any relevant any country
                in relation to the collection, use, disclosure, storage or
                processing of Your Personal Information;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; “Personal Information” means any information that can be
                used to directly or indirectly identify an individual, and
                includes Sensitive Information, and references to “Your Personal
                Information” also includes any Personal Information that You
                provide/process/control through Ezymigrate (Ezysystem) about
                someone else;&nbsp;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                “Purposes” means the purposes for which Your Personal
                Information is used and disclosed as specified in this
                Ezymigrate Privacy Policy;
              </span>
            </p>
          </div>

          <div>
            <p>
              <br />
            </p>
          </div>

          <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; “You” means you as an individual and Your has a
                corresponding meaning;
              </span>
            </p>
          </div>

          {/* <div>
            <p className="pp-heading1-div" style={{ marginLeft: 36 }}>
              <span className="pp-heading-text" style={{ fontWeight: "400" }}>
                &nbsp; Ezyforms means an Ezysystem’s chrome extension, that is
                built to work in conjunction with Ezysystem(s)
              </span>
            </p>
          </div> */}
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
