import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal, Spin } from "antd";
import ProfileEmployer from "./ProfileEmployer";
import ContactEmployer from "./ContactEmployer/ContactEmployer";
import JobEmployer from "./JobEmployer/JobEmployer";
import ClientEmployer from "./ClientEmployer/ClientEmployer";
import { Images } from "../../../../Themes";
import moment from "moment";
import JobCheckReport from "./JobCheckReport";

const EmployerManagementHead = ({
  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  userDataEmp,

  onRemoveEmployerContact,

  onUpdateEmployerManag,

  onUpdateEmployerContact,

  onGetTeamMember,
  teamMembers,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,
  singleEmployeFuc,
  onGetClientEmp,
  clientEmpRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onGetEmployerManag,
  employerManagRes,

  docWithNameRes,
  onAddDocWithName,

  onGetClientJobHis,
  clientJobHisRes,

  onGetSingleClientHis,
  singleJobHisRes,
  onSetActiveKey,
  countriesData,
  onGetCountries,
  onAddProcessingPerson,
  onGetPermProcessingPerson,
  tabKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [jobUpdate, setJobUpdate] = useState(false);

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div className="border-box-emp-manag emp-profile-box-employer">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <h4
                className="top-text heading-forms"
                style={{
                  marginLeft: "40px !important",
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>

              {userDataEmp &&
              moment(userDataEmp.accredationExpiryDate).format("DD/MM/YYYY") !==
                "01/01/0001" &&
              moment(userDataEmp.accredationExpiryDate).format("DD/MM/YYYY") !==
                "01/01/1900" ? (
                moment(userDataEmp.accredationExpiryDate) <
                moment(new Date()) ? (
                  <span style={{ color: "#dc2f36", marginLeft: 5 }}>
                    (Unaccredited)
                  </span>
                ) : (
                  <span style={{ color: "#98d14b", marginLeft: 5 }}>
                    (Accredited)
                  </span>
                )
              ) : (
                ""
              )}
            </div>
            {userDataEmp && (
              <div>
                <div
                  className={
                    userDataEmp && userDataEmp.isActive
                      ? "right-green-btn-cont"
                      : "cross-cont"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    userDataEmp.isActive = !userDataEmp.isActive;
                    setLoading(true);
                    onUpdateEmployerManag(userDataEmp).then((res) => {
                      onGetEmployerManag();
                      setLoading(false);
                    });
                  }}
                >
                  {userDataEmp && userDataEmp.isActive && (
                    <img
                      src={Images.tickWhite}
                      className="svg-btn-img"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <img src={Images.btnImage} className="profile-btn-img" />
                  {userDataEmp && !userDataEmp.isActive && (
                    <img
                      src={Images.crossWhite}
                      className="svg-btn-img"
                      style={{ marginLeft: 10 }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <ProfileEmployer
            singleEmployeFuc={singleEmployeFuc}
            userDataEmp={userDataEmp}
            onUpdateEmployerManag={onUpdateEmployerManag}
            onGetEmployerManag={onGetEmployerManag}
            employerManagRes={employerManagRes}
            docWithNameRes={docWithNameRes}
            onAddDocWithName={onAddDocWithName}
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            onSetActiveKey={onSetActiveKey}
            onGetTeamMember={onGetTeamMember}
            teamMembers={teamMembers}
            countriesData={countriesData}
            onGetCountries={onGetCountries}
            onAddProcessingPerson={onAddProcessingPerson}
            onGetPermProcessingPerson={onGetPermProcessingPerson}
            tabKey={tabKey}
          />

          <ContactEmployer
            ongetEmployerContact={ongetEmployerContact}
            EmployerContactRes={EmployerContactRes}
            onAddEmployerContact={onAddEmployerContact}
            userDataEmp={userDataEmp}
            onRemoveEmployerContact={onRemoveEmployerContact}
            onUpdateEmployerContact={onUpdateEmployerContact}
            tabKey={tabKey}
          />
          <JobCheckReport
            userDataEmp={userDataEmp}
            tabKey={tabKey}
            jobUpdate={jobUpdate}
          />
          <JobEmployer
            onGetEmployerJob={onGetEmployerJob}
            employerJobRes={employerJobRes}
            onAddEmployerJob={onAddEmployerJob}
            userDataEmp={userDataEmp}
            onGetTeamMember={onGetTeamMember}
            teamMembers={teamMembers}
            onUpdateEmployerJob={onUpdateEmployerJob}
            onRemoveEmployerJob={onRemoveEmployerJob}
            onGetClientSearch={onGetClientSearch}
            clientSearchRes={clientSearchRes}
            onSearchClient={onSearchClient}
            searchClientData={searchClientData}
            onGetClientByEmpId={onGetClientByEmpId}
            empClientEmpRes={empClientEmpRes}
            onLinkEmpWithClient={onLinkEmpWithClient}
            onAddEmployerJobHistory={onAddEmployerJobHistory}
            onGetSingleClientHis={onGetSingleClientHis}
            singleJobHisRes={singleJobHisRes}
            tabKey={tabKey}
            onJobUpdate={() => setJobUpdate(!jobUpdate)}
          />
          <ClientEmployer
            onGetClientEmp={onGetClientEmp}
            clientEmpRes={clientEmpRes}
            empClientEmpRes={empClientEmpRes}
            onLinkEmpWithClient={onLinkEmpWithClient}
            userDataEmp={userDataEmp}
            onGetClientByEmpId={onGetClientByEmpId}
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            onGetProfileClientTag={onGetProfileClientTag}
            getClientRes={getClientRes}
            onGetClientJobHis={onGetClientJobHis}
            clientJobHisRes={clientJobHisRes}
            tabKey={tabKey}
          />
        </div>
      </Spin>
    </Fragment>
  );
};
export default EmployerManagementHead;
