import React from "react";
import {
  FormOutlined,
  DeleteOutlined,
  CopyOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";

import {
  Collapse,
  List,
  Spin,
  Modal,
  Form,
  Button,
  Input,
  Popconfirm,
  Select,
} from "antd";
import { openNotificationWithIcon } from "../Common/reUseFunction";
const { Panel } = Collapse;
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { TextArea } = Input;

class CheckLists extends React.Component {
  formRef = React.createRef();

  state = {
    expandIconPosition: "right",
    Categories: [],
    pageLoading: false,
    newCategory: {},
    newChecklist: {},
    newTask: {},
    selectedChecklist: {},
    isCategoryModalOpen: false,
    isChecklistModalOpen: false,
    isTaskEdit: false,
    isTaskModalOpen: false,
    isCopyModalOpen: false,
  };
  componentDidMount = () => {
    this.setState({
      pageLoading: true,
    });
    this.props
      .onGetVisaCategoryMain()
      .then((res) => {
        let types = res.payload && res.payload.items;
        if (types) this.setState({ Categories: types });
        this.setState({
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  };
  removeCategory = (id, index) => {
    let { Categories } = this.state;
    this.setState({ pageLoading: true });
    this.props
      .onRemoveChecklistCategory({
        id: id,
        delete: true,
      })
      .then((res) => {
        this.state.Categories.splice(index, 1);
        this.setState(
          {
            pageLoading: false,
            Categories: Categories,
          },
          () => openNotificationWithIcon("success", "Category Removed!")
        );
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  };
  getCategoryIcons = (category, index) => (
    <div>
      <FormOutlined
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          this.setState(
            { isCategoryModalOpen: true, newCategory: category },
            () =>
              this.formRef.current.setFieldsValue({
                name: category.name,
              })
          );
        }}
        style={{ fontSize: 16, color: "#293A81", marginRight: 10 }}
      />
      <Popconfirm
        placement="topRight"
        title="Are you sure to delete this category?"
        onConfirm={(event) => {
          event.stopPropagation();
          this.removeCategory(category.id, index);
        }}
        onCancel={(event) => {
          event.stopPropagation();
        }}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
          style={{ fontSize: 16, color: "#293A81" }}
        />
      </Popconfirm>
    </div>
  );
  getChecklistIcons = (lists, checklist, categoryIndex, checklistIndex) => (
    <div>
      <CopyOutlined
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          this.setState({
            isCopyModalOpen: true,
            selectedChecklist: checklist,
          });
        }}
        style={{ fontSize: 16, color: "#293A81", marginRight: 10 }}
      />
      <FormOutlined
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          this.setState(
            {
              isChecklistModalOpen: true,
              newChecklist: {
                ...checklist,
                tasks: checklist.tasks.items,
                categoryIndex,
                checklistIndex,
              },
            },
            () =>
              this.formRef.current.setFieldsValue({
                name: checklist.name,
              })
          );
        }}
        style={{ fontSize: 16, color: "#293A81", marginRight: 10 }}
      />
      <Popconfirm
        placement="topRight"
        title="Are you sure to delete this checklist?"
        onConfirm={(event) => {
          event.stopPropagation();
          this.setState({ pageLoading: true });
          let userId = localStorage.getItem("userId");
          this.props
            .onRemoveChecklist({
              id: checklist.id,
              delete: true,
              
              itemIds: [],
            })
            .then(() => {
              lists.splice(checklistIndex, 1);
              this.setState(
                { pageLoading: false, Categories: this.state.Categories },
                () => openNotificationWithIcon("success", "Checklist Removed")
              );
            })
            .catch((er) => {
              this.setState({ pageLoading: false });
            });
        }}
        onCancel={(event) => {
          event.stopPropagation();
        }}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
          style={{ fontSize: 16, color: "#293A81" }}
        />
      </Popconfirm>
    </div>
  );

  getTaskIcons = (
    allTasks,
    task,
    taskIndex,
    checklist,
    categoryIndex,
    checklistIndex
  ) => (
    <div>
      <FormOutlined
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          this.setState(
            {
              isTaskModalOpen: true,
              newChecklist: {
                ...checklist,
                tasks: checklist.tasks.items,
                categoryIndex,
                checklistIndex,
              },
              newTask: {
                id: task.id,
                categoryIndex: categoryIndex,
                checklistIndex: checklistIndex,
                name: task.name,
                description: task.description,
                checklistId: checklist.id,
              },
              isTaskEdit: true,
            },
            () =>
              this.formRef.current.setFieldsValue({
                name: task.name,
                description: task.description,
              })
          );
        }}
        style={{ fontSize: 16, color: "#293A81", marginRight: 10 }}
      />
      <Popconfirm
        placement="topRight"
        title="Are you sure to delete this task?"
        onConfirm={(event) => {
          event.stopPropagation();
          this.removeTask(allTasks, task, taskIndex);
        }}
        onCancel={(event) => {
          event.stopPropagation();
        }}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
          style={{ fontSize: 16, color: "#293A81" }}
        />
      </Popconfirm>
    </div>
  );
  onExpandCategory = (key, item) => {
    let { Categories } = this.state;
    let { name } = item;
    if (key.length) {
      this.setState({
        pageLoading: true,
      });
      this.props
        .onGetVisaCategoryChecklist(name)
        .then((res) => {
          let data = res.payload && res.payload.items;
          if (data) {
            item.CheckLists = data;
            this.setState({
              Categories: Categories,
              pageLoading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            pageLoading: false,
          });
        });
    }
  };
  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };

  onChangeCategoryHandler = (categoryName) => {
    let { newCategory } = this.state;
    this.setState({
      newCategory: { ...newCategory, name: categoryName },
    });
  };
  onChangeChecklistHandler = (checklistName) => {
    let { newChecklist } = this.state;
    this.setState({ newChecklist: { ...newChecklist, name: checklistName } });
  };
  onUpdateCategory = () => {
    let { newCategory, Categories } = this.state;
    newCategory.name = newCategory.name;
    this.setState({ pageLoading: true });
    this.props
      .onUpdateChecklistCategory(newCategory)
      .then((res) => {
        let data = Categories.map((el) => {
          if (el.id === newCategory.id) el.name = newCategory.name;
          return el;
        });
        this.setState(
          {
            Categories: data,
            pageLoading: false,
            isCategoryModalOpen: false,
          },
          () => {
            this.resetTaskForm();
            openNotificationWithIcon("success", "Category Updated!");
          }
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };
  updateChecklist = () => {
    this.setState({ pageLoading: true });
    let { newChecklist } = this.state;
    let { categoryIndex, checklistIndex } = newChecklist;
    this.props
      .onUpdateVisaChecklist(newChecklist)
      .then((res) => {
        let tasks = newChecklist.tasks;
        delete newChecklist.tasks;
        newChecklist["tasks"] = { items: tasks };
        this.state.Categories[categoryIndex].CheckLists[
          checklistIndex
        ] = newChecklist;
        this.setState(
          {
            pageLoading: false,
            Categories: this.state.Categories,
            isChecklistModalOpen: false,
          },
          () => {
            this.resetTaskForm();
            openNotificationWithIcon("success", "Checklist Updated");
          }
        );
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };
  addOrUpdateTask = () => {
    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");
    let { newTask, newChecklist, isTaskEdit } = this.state;
    let { name, description, categoryIndex, checklistIndex } = newTask;
    let data;

    if (!isTaskEdit) {
      data = {
        ...newChecklist,
        tasks: [...newChecklist.tasks, newTask],
      };
    } else {
      data = {
        ...newChecklist,
        tasks: newChecklist.tasks.map((el) => {
          if (el.id === newTask.id) {
            el.name = name;
            el.description = description;
          }
          return el;
        }),
      };
    }

    this.props
      .onUpdateVisaChecklist(data)
      .then((res) => {
        this.props
          .onGetChecklistTasks(data.id)
          .then((res) => {
            if (res.payload && res.payload.items) {
              this.state.Categories[categoryIndex].CheckLists[
                checklistIndex
              ].tasks.items = res.payload.items;
              this.setState(
                {
                  pageLoading: false,
                  Categories: this.state.Categories,
                  isTaskModalOpen: false,
                  isTaskEdit: false,
                  newTask: {},
                  newChecklist: {},
                },
                () => {
                  this.resetTaskForm();
                  openNotificationWithIcon("success", "Task Added");
                }
              );
            }
          })
          .catch((er) => {
            this.setState({ pageLoading: false });
          });
      })
      .catch((er) => {
        this.setState({ pageLoading: false });
      });
  };

  onChangeTaskHandler = (type, val) => {
    this.setState({ newTask: { ...this.state.newTask, [type]: val } });
  };

  removeTask = (allTasks, task, taskIndex) => {
    this.setState({ pageLoading: true });
    let userId = localStorage.getItem("userId");

    this.props
      .onRemoveChecklistTask({
        id: task.id,
        delete: true,
        
        itemIds: [],
      })
      .then(() => {
        allTasks.splice(taskIndex, 1);
        this.setState({ pageLoading: false });
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };

  onSelectCategory = (data) => {
    let value = JSON.parse(data);
    let { selectedChecklist } = this.state;

    this.setState(
      {
        selectedChecklist: {
          ...selectedChecklist,
          description: value.name,
        },
      },
      () => {
        let data = this.state.selectedChecklist;
      }
    );
  };
  copyChecklist = () => {
    this.setState({ pageLoading: true });
    let { selectedChecklist } = this.state;

    let data = {
      ...selectedChecklist,
      tasks: selectedChecklist.tasks.items,
    };

    this.props
      .onAddVisaChecklist(data)
      .then(() => {
        this.setState({ pageLoading: false, isCopyModalOpen: false }, () =>
          this.resetTaskForm()
        );
        openNotificationWithIcon(
          "success",
          `Checklist Copied to ${selectedChecklist.description}`
        );
      })
      .catch(() => {
        this.setState({ pageLoading: false });
      });
  };

  getCopyCategoryModal = ({ Categories, isCopyModalOpen, pageLoading }) => (
    <Modal
      title={"Move To Category"}
      visible={isCopyModalOpen}
      onOk={() => {
        this.resetTaskForm();
        this.setState({ isCopyModalOpen: false });
      }}
      onCancel={() => {
        this.resetTaskForm();
        this.setState({ isCopyModalOpen: false });
      }}
      header={true}
      footer={false}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            overflow: "inherit",
            paddingBottom: 6,
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 5,
          }}
        >
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            onFinish={() => {
              this.copyChecklist();
            }}
            // onFinishFailed={() => alert("fail")}
          >
            <Form.Item
              name="category"
              labelAlign="left"
              label="Category"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                style={{ width: 300, borderRadius: 0 }}
                placeholder="Please Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={this.onSelectCategory.bind(this)}
              >
                {Categories.map((cat) => (
                  <Option value={JSON.stringify(cat)}>{cat.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
              <Button
                disabled={pageLoading}
                type="primary"
                htmlType="submit"
                className="cm-blue-btn"
              >
                {pageLoading && <Spin size="small" />}
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );

  render() {
    const { onExpandCategory, state } = this;
    const {
      expandIconPosition,
      Categories,
      pageLoading,
      isCategoryModalOpen,
      isChecklistModalOpen,
      isTaskEdit,
      isTaskModalOpen,
    } = state;
    return (
      <Spin spinning={pageLoading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="list-heading">
                <span className="header-text" style={{ fontWeight: "500" }}>
                  Categories
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div className="file-notes-container" style={{ margin: 0 }}>
                  {Categories.map((item, categoryIndex) => (
                    <Collapse
                      onChange={(e) => onExpandCategory(e, item)}
                      expandIconPosition={expandIconPosition}
                    >
                      <Panel
                        header={item.name}
                        key="1"
                        extra={this.getCategoryIcons(item, categoryIndex)}
                      >
                        {item.CheckLists
                          ? item.CheckLists.map((list, checklistIndex) => (
                              <Collapse expandIconPosition={expandIconPosition}>
                                <Panel
                                  header={list.name}
                                  key="1"
                                  extra={this.getChecklistIcons(
                                    item.CheckLists,
                                    list,
                                    categoryIndex,
                                    checklistIndex
                                  )}
                                >
                                  <div style={{ textAlign: "end" }}>
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          isTaskModalOpen: true,
                                          newChecklist: {
                                            ...list,
                                            tasks: list.tasks.items,
                                            categoryIndex,
                                            checklistIndex,
                                          },
                                          newTask: {
                                            id: 0,
                                            categoryIndex: categoryIndex,
                                            checklistIndex: checklistIndex,
                                            checklistId: list.id,
                                          },
                                          isTaskEdit: false,
                                        })
                                      }
                                      href="#"
                                    >
                                      <PlusSquareFilled
                                        style={{
                                          fontSize: 24,
                                          color: "#0F7EB7",
                                          marginBottom: 15,
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <List
                                    itemLayout="horizontal"
                                    dataSource={
                                      list.tasks && list.tasks.items
                                        ? list.tasks.items
                                        : []
                                    }
                                    renderItem={(item, index) => (
                                      <List.Item
                                        extra={this.getTaskIcons(
                                          list.tasks.items,
                                          item,
                                          index,
                                          list,
                                          categoryIndex,
                                          checklistIndex
                                        )}
                                      >
                                        <List.Item.Meta
                                          avatar={
                                            <div
                                              style={{
                                                width: 15,
                                                height: 15,
                                                backgroundColor: "#0F7EB7",
                                                borderRadius: 2,
                                              }}
                                            ></div>
                                          }
                                          title={
                                            <a
                                              style={{ color: "#0F7EB7" }}
                                              href="#"
                                            >
                                              {item.name}
                                            </a>
                                          }
                                        />
                                      </List.Item>
                                    )}
                                  />
                                </Panel>
                              </Collapse>
                            ))
                          : null}
                      </Panel>
                    </Collapse>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={"Update Category"}
          visible={isCategoryModalOpen}
          onOk={() => {
            this.resetTaskForm();
            this.setState({ isCategoryModalOpen: false });
          }}
          onCancel={() => {
            this.resetTaskForm();
            this.setState({ isCategoryModalOpen: false });
          }}
          header={true}
          footer={false}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                overflow: "inherit",
                paddingBottom: 6,
                // display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 5,
              }}
            >
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                // initialValues={{ remember: true }}
                onFinish={() => {
                  this.onUpdateCategory();
                }}
                onFinishFailed={() => alert("fail")}
              >
                <Form.Item
                  name="name"
                  labelAlign="left"
                  label="Category Name"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeCategoryHandler(e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="cm-blue-btn"
                  >
                    {pageLoading && <Spin size="small" />}
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
        <Modal
          title={"Update Checklist"}
          visible={isChecklistModalOpen}
          onOk={() => {
            this.resetTaskForm();
            this.setState({ isChecklistModalOpen: false });
          }}
          onCancel={() => {
            this.resetTaskForm();
            this.setState({ isChecklistModalOpen: false });
          }}
          header={true}
          footer={false}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                overflow: "inherit",
                paddingBottom: 6,
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 5,
              }}
            >
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => {
                  this.updateChecklist();
                }}
                onFinishFailed={() => alert("fail")}
              >
                <Form.Item
                  name="name"
                  labelAlign="left"
                  label="Name"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeChecklistHandler(e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="cm-blue-btn"
                  >
                    {pageLoading && <Spin size="small" />}
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
        <Modal
          title={isTaskEdit ? "Update Task" : "Add Task"}
          visible={isTaskModalOpen}
          onOk={() => {
            this.resetTaskForm();
            this.setState({ isTaskModalOpen: false });
          }}
          onCancel={() => {
            this.resetTaskForm();
            this.setState({ isTaskModalOpen: false });
          }}
          header={true}
          footer={false}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                overflow: "inherit",
                paddingBottom: 6,
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 5,
              }}
            >
              <Form
                ref={this.formRef}
                {...layout}
                name="basic"
                onFinish={() => this.addOrUpdateTask()}
                onFinishFailed={() => alert("fail")}
              >
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Task Title"
                  name="name"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Required!" }]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Input
                    onChange={(e) =>
                      this.onChangeTaskHandler("name", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  labelAlign="left"
                  label="Task Description"
                  name="description"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <TextArea
                    aria-multiline={true}
                    onChange={(e) =>
                      this.onChangeTaskHandler("description", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                  <Button
                    disabled={pageLoading}
                    type="primary"
                    htmlType="submit"
                    className="cm-blue-btn"
                  >
                    {pageLoading && <Spin size="small" />}
                    {isTaskEdit ? "Update" : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
        {this.getCopyCategoryModal(this.state)}
      </Spin>
    );
  }
}

export default CheckLists;
