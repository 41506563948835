import { api } from "../../services/api";
import * as types from "../Constants";

export const getPotentialClientStatus = (data) => {
  const options = {
    url: "v1/company/clientstatus/potentialclient/All",
  };

  options.types = [
    types.POTENTIAL_CLIENT_STATUS_SUCCESS,
    types.POTENTIAL_CLIENT_STATUS_FAILURE,
  ];

  return api.get(options);
};

export const addPotentialClientStatus = (data) => {
  const options = {
    url: "v1/company/clientstatus/potentialclient",
  };

  options.types = [
    types.ADD_POTENTIAL_CLIENT_STATUS_SUCCESS,
    types.ADD_POTENTIAL_CLIENT_STATUS_FAILURE,
  ];

  return api.post(options, data);
};

export const updPotentialClientStatus = (data) => {
  const options = {
    url: "v1/company/clientstatus",
  };

  options.types = [
    types.UPDATE_POTENTIAL_CLIENT_STATUS_SUCCESS,
    types.UPDATE_POTENTIAL_CLIENT_STATUS_FAILURE,
  ];

  return api.put(options, data);
};
