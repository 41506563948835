import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientJobHistory = (id) => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");

  const options = {
    url: `v1/client/jobhistory/All/${clientprofileid}`,
  };
  options.types = [
    types.GET_EMPLOYER_JOB_HISTORY_SUCCESS,
    types.GET_EMPLOYER_JOB_HISTORY_FAILURE,
  ];

  return api.get(options);
};

export const addEmployerJobHistory = (data) => {
  const options = {
    url: "v1/client/jobhistory",
  };
  options.types = [
    types.ADD_EMPLOYER_JOB_HISTORY_SUCCESS,
    types.ADD_EMPLOYER_JOB_HISTORY_FAILURE,
  ];

  return api.post(options, data);
};

export const updEmployerJobHistory = (data) => {
  const options = {
    url: "v1/client/jobhistory",
  };
  options.types = [
    types.UPDATE_EMPLOYER_JOB_HISTORY_SUCCESS,
    types.UPDATE_EMPLOYER_JOB_HISTORY_FAILURE,
  ];

  return api.put(options, data);
};

export const removeEmployerJobHistory = (data) => {
  const options = {
    url: "v1/client/jobhistory",
  };
  options.types = [
    types.DELETE_EMPLOYER_JOB_HISTORY_SUCCESS,
    types.DELETE_EMPLOYER_JOB_HISTORY_FAILURE,
  ];

  return api.delete(options, data);
};

export const onGetClientJobStatuses = () => {
  const options = {
    url: `v1/client/jobhistory/All/JobStatus`,
  };
  options.types = [
    types.GET_EMPLOYER_JOB_STATUS_SUCCESS,
    types.GET_EMPLOYER_JOB_STATUS_FAILURE,
  ];

  return api.get(options);
};

export const getClientJobHistoryCurrent = (id) => {
  const options = {
    url: `v1/client/jobhistory/All/Current/${id}`,
  };
  options.types = [
    types.GET_EMPLOYER_JOB_HISTORY_CURRENT_SUCCESS,
    types.GET_EMPLOYER_JOB_HISTORY_CURRENT_FAILURE,
  ];

  return api.get(options);
};
