import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const adminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_ADMIN_LOGIN_SUCCESS:
      return { ...state, addSignUpRes: action.payload };
    case types.ADD_ADMIN_LOGIN_FAILURE:
      openNotificationWithIcon(
        "error",
        "Invalid Credentails",
        action.payload.message
      );
      if (action.payload.response === "undefined") {
        window.location.assign("/adminLogin");
      }

    default:
      return state;
  }
};
