import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  DatePicker,
  Spin,
  Modal,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import { Images } from "../../Themes";
import AddDealForm from "../../Deals/AddDealForm";
import AddTimeTrackingForm from "./AddTimeTrackingForm";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const TimeTrackingCard = ({
  clientId,
  onGetVisaType,
  onGetVisaWorkType,
  onGetVisaTypePrice,
  onGetServiceType,
  visaTypeData,
  visaWorkTypeRes,
  visaTypePriceRes,
  serviceTypeRes,
  getTasks,
  tasks,
  activeKey,
  onGetVisaApplication,
  visaAppData,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [dealsList, setDealsList] = useState([]);
  const [summaryClicked, setSummaryClicked] = useState(true);
  const [spendTime, setSpendTime] = useState(0);

  useEffect(() => {
    if (activeKey == 11) {
      getTimeTrackingSummary();
      onGetVisaType();
      onGetVisaWorkType();
      onGetVisaTypePrice();
      onGetServiceType();
      getTasks();
    }
  }, [activeKey]);

  const getTimeTracking = () => {
    const getDealOpt = {
      url: `v1/timetracking/All/${clientId}`,
    };
    getDealOpt.types = [
      "GET_TIME_TRACKING_SUCCESS",
      "GET_TIME_TRACKING_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .get(getDealOpt)
      .then((res) => {
        setLoading(false);
        let spentTm = 0;
        for (var i = 0; i < res.items.length; i++) {
          spentTm += res.items[i].timeSpent;
        }
        setSpendTime(spentTm);
        setDealsList(res.items);
      })
      .catch((errr) => {
        setLoading(false);
        setDealsList([]);
      });
  };

  const getTimeTrackingSummary = () => {
    const getDealOpt = {
      url: `v1/timetracking/Summary/${clientId}`,
    };
    getDealOpt.types = [
      "GET_TIME_TRACKING_SUMMARY_SUCCESS",
      "GET_TIME_TRACKING_SUMMARY_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .get(getDealOpt)
      .then((res) => {
        setLoading(false);
        let spentTm = 0;
        for (var i = 0; i < res.summary.length; i++) {
          spentTm += res.summary[i].timeSpent;
        }
        setSpendTime(spentTm);
        setDealsList(res.summary);
      })
      .catch((errr) => {
        setLoading(false);
        setDealsList([]);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addSuccess = () => {
    setSummaryClicked(true);
    getTimeTrackingSummary();
    handleCancel();
  };

  const onFinish = (values) => {
    let data = {
      visaId: values.visaTypeId ? parseInt(values.visaTypeId) : 0,
      serviceId: values.serviceType ? parseInt(values.serviceType) : 0,
      workTypeId: values.visaWorkType ? parseInt(values.visaWorkType) : 0,
      timeSpent: values.timeSpent ? parseInt(values.timeSpent) : 0,
      description: values.description || "",
      taskId: values.clientTask ? parseInt(values.clientTask) : 0,
      clientId: clientId,
    };
    const addTimeTrackingOpt = {
      url: `v1/timetracking`,
    };
    addTimeTrackingOpt.types = [
      "ADD_TIME_TRACKING_SUCCESS",
      "ADD_TIME_TRACKING_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .post(addTimeTrackingOpt, data)
      .then((res) => {
        setLoading(false);
        message.success("Time Tracking added successfuly!");
        setSummaryClicked(true);
        getTimeTrackingSummary();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  const removeDeal = (id) => {
    let data = {
      id: id,
      delete: true,
    };
    const getDealsOpt = {
      url: `v1/timetracking`,
    };
    getDealsOpt.types = [
      "REMOVE_TIME_TRACKING_SUCCESS",
      "REMOVE_TIME_TRACKING_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(getDealsOpt, data)
      .then((res) => {
        getTimeTracking();
        setLoading(false);
        message.success("Deleted!");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 8,
          }}
        >
          <img
            onClick={() => setIsModalVisible(true)}
            src={Images.plusIcon}
            style={{ width: 20, cursor: "pointer" }}
          />
        </div> */}

        <div
          style={{
            padding: 0,
            margin: 0,
            marginTop: 9,
            display: "block",
          }}
        >
          <div style={{ display: "flex", marginBottom: 20 }}>
            <Button
              onClick={() => {
                setSummaryClicked(true);
                getTimeTrackingSummary();
              }}
              style={{
                backgroundColor: summaryClicked
                  ? "rgb(51, 170, 218)"
                  : "rgb(83, 168, 204)",
                color: "#fff",
              }}
            >
              Summary
            </Button>
            <Button
              onClick={() => {
                setSummaryClicked(false);
                getTimeTracking();
              }}
              style={{
                marginLeft: 20,
                backgroundColor: summaryClicked
                  ? "rgb(83, 168, 204)"
                  : "rgb(51, 170, 218)",
                color: "#fff",
              }}
            >
              Detail
            </Button>
          </div>
          <div style={{ marginBottom: 20 }}>
            <span>{`Total Time Spent (Min): ${spendTime}`}</span>
          </div>
          {dealsList.map((item, ind) => {
            let timeHrs = item.timeSpent / 60;
            let timeMins = item.timeSpent % 60;
            return (
              <div
                style={{
                  padding: 10,
                  marginBottom: 10,
                  margin: 0,
                  display: "block",
                }}
                className="box-shadow"
              >
                <div className="deal-icon-position">
                  <img
                    src={Images.deleteGray}
                    style={{
                      width: 12,
                      height: 12,
                      marginRight: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      removeDeal(item.id);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <span className="deal-title">Test</span> */}
                  <span className="deal-title">
                    {item.visaName || item.serviceName}
                  </span>
                </div>

                {!summaryClicked && (
                  <div>
                    <span className="visa-row">
                      {`Visa Work Type :  ${item.workName}`}
                      {/* {`Remaining Time (hr) : ${timeHrs} hour(s) and ${timeMins} minute(s)`} */}
                    </span>
                  </div>
                )}

                <div

                //   onClick={() => moveToClient(item)}
                >
                  {summaryClicked && (
                    <div>
                      <span
                        className="visa-row"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {`Allowed Time (Hours): ${item.allowedTimeInHours}`}
                      </span>
                    </div>
                  )}
                  <div>
                    <span
                      className="visa-row"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {`Spent Time (min) :  ${item.timeSpent}`}
                      {/* {`Spent Time (hr) : ${timeHrs} hour(s) and ${timeMins} minute(s)`} */}
                    </span>
                  </div>
                </div>

                {summaryClicked ? (
                  <div>
                    <span className="visa-row">
                      {`Remaining Time (min) :  ${item.remainingTimeInMinutes}`}
                      {/* {`Remaining Time (hr) : ${timeHrs} hour(s) and ${timeMins} minute(s)`} */}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="visa-row">
                      {`Client Task :  ${item.taskTitle || ""}`}
                      {/* {`Remaining Time (hr) : ${timeHrs} hour(s) and ${timeMins} minute(s)`} */}
                    </span>
                  </div>
                )}
                {!summaryClicked && (
                  <>
                    <div>
                      <span
                        className="visa-row"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {`Description : ${item.description}`}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <div>
                        <p style={{ color: "#555", fontSize: 10 }}>
                          {moment(item.createdDate).format(
                            "DD/MM/YYYY hh:mm:ss"
                          )}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "#555", fontSize: 10 }}>
                          {item.userName}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {/* <div>
                  <img
                    src={Images.calendar}
                    className="svg-btn-img"
                    style={{
                      width: 12,
                      height: 12,
                    }}
                  />
                  <span className="deal-card-text" style={{ marginLeft: 5 }}>
                    {moment(item.createdDate).format("DD/MM/YYYY") !==
                      "01/01/1900" ||
                    moment(item.createdDate).format("DD/MM/YYYY") !==
                      "01/01/0001"
                      ? moment(item.createdDate).format("DD/MM/YYYY")
                      : ""}
                  </span>
                </div> */}
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Images.grayDollar}
                    className="svg-btn-img"
                    style={{
                      width: 12,
                      height: 12,
                      marginRight: 5,
                    }}
                  />
                  <span className="deal-card-text">
                    {item.amount ? item.amount : ""}
                  </span>
                </div> */}
              </div>
            );
          })}
        </div>
        {isModalVisible && (
          <Modal
            title="Time Tracking"
            visible={isModalVisible}
            // onOk={handleOk}
            onCancel={handleCancel}
            header={false}
            footer={false}
            width={550}
          >
            <AddTimeTrackingForm
              onGetVisaType={onGetVisaType}
              onGetVisaWorkType={onGetVisaWorkType}
              onGetVisaTypePrice={onGetVisaTypePrice}
              onGetServiceType={onGetServiceType}
              visaTypeData={visaTypeData}
              visaWorkTypeRes={visaWorkTypeRes}
              visaTypePriceRes={visaTypePriceRes}
              serviceTypeRes={serviceTypeRes}
              getTasks={getTasks}
              tasks={tasks}
              addSuccess={addSuccess}
              handleCancel={handleCancel}
              clientId={clientId}
              onGetVisaApplication={onGetVisaApplication}
              visaAppData={visaAppData}
            />
          </Modal>
        )}
      </Spin>
    </Fragment>
  );
};

export default TimeTrackingCard;
