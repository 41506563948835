import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CalendarSync from "../AccountSetting/UserLevelSettings/CalenderSync/CalenderSync";

import { setActiveInnerTab } from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSync);
