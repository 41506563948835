import { api } from "../../services/api";
import * as types from "../Constants";

export const getDefaultFont = () => {
  const options = {
    url: `v1/users/UserDefaultFont`,
  };
  options.types = [
    types.GET_DETAULT_FONT_SUCCESS,
    types.GET_DETAULT_FONT_FAILURE,
  ];

  return api.get(options);
};

export const addDefaultFont = (data) => {
  const options = {
    url: "v1/users/UserDefaultFont",
  };
  options.types = [
    types.PUT_DETAULT_FONT_SUCCESS,
    types.PUT_DETAULT_FONT_FAILURE,
  ];

  return api.put(options, data);
};
