import { api } from "../../services/api";
import * as types from "../Constants";

export const addTechnicalComplaint = (data) => {
  const options = {
    url: "v1/support",
  };

  options.types = [
    types.CREATE_TECHNICAL_COMPLAINT_SUCCESS,
    types.CREATE_TECHNICAL_COMPLAINTE_FAILURE,
  ];

  return api.post(options, data);
};
