import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUserContactRequest: [],
  getUserContactSuccess: ["success"],
  getUserContactFailure: ["error"],

  addUserContactRequest: ["data"],
  addUserContactSuccess: ["success"],
  addUserContactFailure: ["error"],

  updUserContactRequest: ["data"],
  updUserContactSuccess: ["success"],
  updUserContactFailure: ["error"],

  getManagerContactRequest: [],
  getManagerContactSuccess: ["success"],
  getManagerContactFailure: ["error"]
});

export const SusUserContactTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userContactData: null,
  fetching: false,
  error: null,
  addFetching: false,
  addError: null,
  addSuccess: null,
  updFetching: false,
  updError: null,
  updSuccess: null,
  mngrFetching: false,
  mngrContacts: null,
  mngrDataError: null
});

/* ------------- Reducers ------------- */

// we're attempting to get sus user contact
export const request = state => {
  console.log("on redux call");
  return state.merge({ fetching: true });
};

// we've successfully get sus user contact
export const success = (state, { success }) =>
  state.merge({ fetching: false, userContactData: success });

// we've had a problem in geting sus user contact
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// we're attempting to add sus user contact
export const addrequest = state => state.merge({ addFetching: true });

// we've successfully added sus user contact
export const addsuccess = (state, { addSuccess }) =>
  state.merge({ addFetching: false, addSuccess: addSuccess });

// we've had a problem in adding user contact
export const addfailure = (state, { addError }) =>
  state.merge({ addFetching: false, addError });

// we're attempting to add sus user contact
export const updrequest = state => state.merge({ updFetching: true });

// we've successfully added sus user contact
export const updsuccess = (state, { updSuccess }) =>
  state.merge({ updFetching: false, updSuccess: updSuccess });

// we've had a problem in adding user contact
export const updfailure = (state, { updError }) =>
  state.merge({ updFetching: false, updError });

// we're attempting to get sus manager contact
export const mngrrequest = state => {
  console.log("on redux call");
  return state.merge({ mngrFetching: true });
};

// we've successfully get sus manager contact
export const mngrsuccess = (state, { success }) =>
  state.merge({ mngrFetching: false, mngrContacts: success });

// we've had a problem in geting sus maanager contact
export const mngrfailure = (state, { mngrDataError }) =>
  state.merge({ mngrFetching: false, mngrDataError });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_CONTACT_REQUEST]: request,
  [Types.GET_USER_CONTACT_SUCCESS]: success,
  [Types.GET_USER_CONTACT_FAILURE]: failure,

  [Types.ADD_USER_CONTACT_REQUEST]: addrequest,
  [Types.ADD_USER_CONTACT_SUCCESS]: addsuccess,
  [Types.ADD_USER_CONTACT_FAILURE]: addfailure,

  [Types.UPD_USER_CONTACT_REQUEST]: updrequest,
  [Types.UPD_USER_CONTACT_SUCCESS]: updsuccess,
  [Types.UPD_USER_CONTACT_FAILURE]: updfailure,

  [Types.GET_MANAGER_CONTACT_REQUEST]: mngrrequest,
  [Types.GET_MANAGER_CONTACT_SUCCESS]: mngrsuccess,
  [Types.GET_MANAGER_CONTACT_FAILURE]: mngrfailure
});
