import React, { Fragment } from "react";
import CreateEmailForm from "./CreateEmailForm";

const createEmail = ({
  onGetLetterTemplates,
  LetterTemplatesRes,

  onSendEmailLink,

  onAddEmployerEmail,

  userDataEmp,
  onGetSignature,
  onGetDocuments,
  onAddPotentialEmail,
  setHandleKey,

  onGetPClientHistory,
  onGetPdf,
  onAddEmailDocument,
  documentRes,
  onGetEmployerDocument,
  employerDocumentRes,
  onGetDocumentDownload,
  onGetEmployerHistory,
  emailContentData,
}) => {
  // useEffect(() => {
  //   setLoading(true);
  //
  //   onGetEmployerCheckList().then((res) => {
  //     setChecklistItem(res.payload.items);
  //     setLoading(false);
  //   });
  // }, [onGetEmployerCheckList]);

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  return (
    <Fragment>
      <CreateEmailForm
        emailContentData={emailContentData}
        onGetLetterTemplates={onGetLetterTemplates}
        LetterTemplatesRes={LetterTemplatesRes}
        onSendEmailLink={onSendEmailLink}
        onAddEmployerEmail={onAddEmployerEmail}
        userDataEmp={userDataEmp}
        onGetSignature={onGetSignature}
        onGetDocuments={onGetDocuments}
        onAddPotentialEmail={onAddPotentialEmail}
        setHandleKey={setHandleKey}
        onGetPClientHistory={onGetPClientHistory}
        onGetPdf={onGetPdf}
        onAddEmailDocument={onAddEmailDocument}
        documentRes={documentRes}
        onGetEmployerDocument={onGetEmployerDocument}
        employerDocumentRes={employerDocumentRes}
        onGetDocumentDownload={onGetDocumentDownload}
        onGetEmployerHistory={onGetEmployerHistory}
      />
    </Fragment>
  );
};

export default createEmail;
