// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker,
  Space,
} from "antd";

import OptFillQuestion from "./OptFillQuestion";
import AnswerField from "./AnswerField";
import FillQuestionRow from "./FillQuestionRow";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function CascadedQuestionRow(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [value, setValue] = useState("");
  const [optionName, setOptionName] = useState("");
  const [question, setQuestion] = useState(Props.question);
  const [checkboxOptions, setCheckboxOptions] = useState([]);

  useEffect(() => {
    // setQuestion(Props.question);
    // setFieldKey(Props.field.fieldKey)
  }, [Props]);

  const { Option } = Select;

  var quesitonsDuplicate = [];

  var sectionId = "";

  //  

  return (
    <div>
      <FillQuestionRow
        question={Props.question}
        countriesData={Props.countriesData}
        questionData={Props.questionData}
        questionsLength={Props.questionsLength}
        sectionsLength={Props.sectionsLength}
        isSaveClicked={Props.isSaveClicked}
      />
    </div>
  );
}

export default CascadedQuestionRow;
