import React, { Component } from "react";
import BraftEditor from "braft-editor"; //  Import and stock in
import "braft-editor/dist/index.css"; //  Introducing styles
// import { apiPostUpload } from "@/api/load"; //  Introduce your own upload interface
// import { isDev } from "@/utils"; //  A function that determines whether it is a development pattern or not
import { message } from "antd";

//  Local debugging service address
const HOST = process.env.REACT_APP_DEFAULT_PROXY;

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propValue: "", //  A variable used to determine whether the editor needs to be initialized
      editorState: BraftEditor.createEditorState(null), //  Set the initial content of the editor
      outputHTML: null, //  The content of rich text
    };
    this.editorRef = React.createRef(); //  Because it uses custom Uploads , So add a new one to the editor ref
  }
  componentDidMount() {}
  //  Used to determine whether the editor needs to be initialized
  componentDidUpdate() {
    if (this.props.value && this.props.value !== this.state.propValue) {
      if (this.state.propValue === "") {
        //  The upper layer passes in string structure data , So we need to use BraftEditor.createEditorState To wrap it
        this.setState({
          editorState: BraftEditor.createEditorState(this.props.value),
          propValue: this.props.value,
        });
      }
    }
  }

  //  Editor changes
  handleChange = async (editorState) => {
    await this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML(),
    });
    this.props.onChange(editorState.toHTML()); //  When the editor content changes, throw up the result of the change
  };

  //  Editor lost focus
  onBlurEditor = () => {
    const { outputHTML } = this.state;
    // this.props.onChange(outputHTML)
  };

  //  Upload function
  uploadImage = async (param) => {};
  //  The function changes the picture of
  changeImage = async (file) => {
    console.log(file);
  };

  render() {
    const { editorState } = this.state;
    const { width = "100%", id = "braft_editor", height = 600 } = this.props; //  Define some default formats
    //  This is the part of configuring your own menu , Delete is to display the full menu
    // const controls = [
    //   {
    //     key: "bold",
    //     text: <b> In bold </b>,
    //   },
    //   "italic",
    //   "underline",
    //   "separator",
    //   "link",
    //   "separator",
    //   "media",
    // ];
    return (
      <div className="editor-wrapper" style={{ width: width }} id={id}>
        <BraftEditor
          ref={(e) => (this.editorRef = e)} //  Callback refs, Used to bind ref
          value={editorState}
          onChange={this.handleChange}
          language="en"
          //   controls={controls} //  To configure your own menu
          //   contentStyle={{
          //     height: 300,
          //     boxShadow: "inset 0 1px 3px rgba(0,0,0,.1)",
          //   }} //  Used to set some styles
          //   media={{
          //     uploadFn: this.uploadImage,
          //     pasteImage: true,
          //     onChange: this.changeImage,
          //   }} //  Used to define your own upload function
        />
      </div>
    );
  }
}

export default Editor;
