import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import DocumentTypesForm from "./DocumentTypesForm";
import { Images } from "../../Themes";
import { Table, Modal, Spin, message, Checkbox, Button, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";

const DocumentTypes = ({
  onGetDocumentType,
  documentTypeRes,

  onAddDocumentType,
  addDocumentTypeRes,

  onUpdetaDocumentType,

  onRemoveDocumentType,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderViewVisible, setFolderViewVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [folderView, setFolderView] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedataRes, setUpdatedataRes] = useState({});
  const [checkbox, setCheckbox] = useState();
  const state = useLocation().state;

  useEffect(() => {
    setLoading(true);

    onGetDocumentType()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [onGetDocumentType]);

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveDocumentType(remove).then(() => {
      onGetDocumentType().then(() => {
        setLoading(false);
        message.success("Successfully Deleted!");
      });
    });
  };

  const showModal = (value) => {
    setIsModalVisible(true);
    setCreateMode(value);
    setUpdatedataRes(value);
    setFolderView(value);
  };
  const showFolderView = (type) => {
    setFolderViewVisible(true);
    setFolderView(type);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setFolderViewVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFolderViewVisible(false);
  };
  function onCheckChange(e) {
    console.log(`checked = ${e.target.checked}`);
    setCheckbox(e.target.checked);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined onClick={() => showModal(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.docType} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  Document Types
                </span>
                <Tooltip
                  placement="topLeft"
                  title={`Here you can create specific document types to help you manage your documents better. For example you can create a “Contracts” document type, and assign all contract related documents that type.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="client-section">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="d-flex space-between">
                    <div className="add-tag-btn">
                      <>
                        <img
                          src={Images.addIcon}
                          className="icons-client"
                          type="primary"
                          onClick={() => showModal("add-document")}
                        />
                      </>
                    </div>
                  </div>
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={
                        documentTypeRes &&
                        documentTypeRes.items &&
                        documentTypeRes.items
                      }
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <Modal
          title={createMode === "add-document" ? "Add Document Type" : "Update"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          header={false}
          footer={false}
        >
          <DocumentTypesForm
            handleCancel={handleCancel}
            setLoading={setLoading}
            createMode={createMode}
            updatedataRes={updatedataRes}
            onGetDocumentType={onGetDocumentType}
            onAddDocumentType={onAddDocumentType}
            onUpdetaDocumentType={onUpdetaDocumentType}
            checkbox={checkbox}
          />
        </Modal>
      )}
      {folderViewVisible && (
        <Modal
          title={folderView === "folder-view" && "Confirmation"}
          visible={folderViewVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure you want to update ?</p>
        </Modal>
      )}
    </Fragment>
  );
};
export default DocumentTypes;
