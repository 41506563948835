import React from "react";
import {
  Select,
  Input,
  Divider,
  Row,
  Col,
  Button,
  Modal,
  Form,
  List,
  Spin,
  Tooltip,
} from "antd";
import { FormOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../Common/reUseFunction";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
const { TextArea } = Input;
function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}
const data = [
  {
    title: "Test",
  },
  {
    title: "Test",
  },
];
class AddChecklist extends React.Component {
  formRef = React.createRef();
  state = {
    Categories: [],
    selectedCategory: {},
    checklist: {
      name: "",
      description: "",
      tasks: [],
    },
    tempTaskObj: {},
    isTaskModalOpen: false,
    pageLoading: false,
  };
  onChange = (value) => {
    let newObj = JSON.parse(value);
    if (!newObj.id) {
      newObj = {
        name: newObj.name,
      };
    }
    this.setState({
      selectedCategory: newObj,
    });
  };
  componentDidMount() {
    this.props.onGetVisaCategory().then((res) => {
      let types = res.payload && res.payload.items;
      if (types) this.setState({ Categories: types });
    });
  }
  checklistNameHandler = (val) => {
    this.setState({
      checklist: { ...this.state.checklist, name: val },
    });
  };

  resetTaskForm = () => {
    if (this.formRef && this.formRef.current)
      this.formRef.current.resetFields();
  };
  genList = (item, index) => (
    <div>
      {/* <Tooltip placement="topLeft" title={`Edit`}> */}
      <FormOutlined
        onClick={() => {
          item.index = index;
          this.setState(
            {
              isTaskModalOpen: true,
              tempTaskObj: item,
              isTaskEdit: true,
            },
            () =>
              this.formRef.current.setFieldsValue({
                name: item.name,
                description: item.description,
              })
          );
        }}
        style={{ fontSize: 15, color: "#4b90cd", marginRight: 10 }}
      />
      {/* </Tooltip> */}
      {/* <Tooltip placement="topLeft" title={`Delete`}> */}
      <DeleteOutlined
        onClick={() => this.removeTask(index)}
        style={{ fontSize: 15, color: "#4b90cd", marginRight: 10 }}
      />
      {/* </Tooltip> */}
    </div>
  );
  onChangeTaskHandler = (type, val) => {
    this.setState({ tempTaskObj: { ...this.state.tempTaskObj, [type]: val } });
  };
  addNewTask = () => {
    let { checklist, tempTaskObj, isTaskEdit } = this.state;
    if (
      !tempTaskObj.name.trim().length ||
      !tempTaskObj.description.trim().length
    )
      return openNotificationWithIcon(
        "error",
        "Validation Failed:",
        "Please enter data in given field!"
      );
    tempTaskObj.name = tempTaskObj.name.trim();
    tempTaskObj.description = tempTaskObj.description.trim();
    if (isTaskEdit) return this.updateTask(tempTaskObj);
    let tc = checklist.tasks.filter(
      (el) => el.name.trim() === tempTaskObj.name.trim()
    );
    if (tc.length)
      return openNotificationWithIcon(
        "error",
        "Validation Failed:",
        "Task already exist!"
      );

    this.setState(
      {
        checklist: {
          ...checklist,
          tasks: [...checklist.tasks, tempTaskObj],
        },
        isTaskModalOpen: false,
        tempTaskObj: {},
      },
      () => this.resetTaskForm()
    );
  };
  updateTask = (tempTaskObj) => {
    let { checklist } = this.state;
    checklist.tasks[tempTaskObj.index].name = tempTaskObj.name;
    checklist.tasks[tempTaskObj.index].description = tempTaskObj.description;
    this.setState(
      {
        checklist: {
          ...checklist,
          tasks: checklist.tasks,
        },
        isTaskModalOpen: false,
        tempTaskObj: {},
        isTaskEdit: false,
      },
      () => this.resetTaskForm()
    );
  };
  removeTask = (index) => {
    let { checklist } = this.state;
    checklist.tasks.splice(index, 1);
    this.setState({ checklist: checklist });
  };

  saveDetail = () => {
    let { checklist, selectedCategory } = this.state;
    this.setState({ pageLoading: true });
    if (this.validationCheck()) {
      let userId = localStorage.getItem("userId");
      checklist.description = selectedCategory.name;
      this.addVisaType();
      this.props
        .onAddVisaChecklist(checklist)
        .then((res) => {
          openNotificationWithIcon("success", "Checklist Added!");
          this.setState({
            selectedCategory: {},
            checklist: {
              name: "",
              description: "",
              tasks: [],
            },
            tempTaskObj: {},
            isTaskModalOpen: false,
            pageLoading: false,
          });
        })
        .catch(() => {
          this.setState({ pageLoading: false });
        });
    } else {
      this.setState({ pageLoading: false });
    }
  };
  addVisaType = () => {
    let { selectedCategory } = this.state;
    if (!selectedCategory.id) this.props.onAddVisaCategory(selectedCategory);
  };
  validationCheck = () => {
    let { selectedCategory, checklist, Categories } = this.state;
    if (!selectedCategory.id && selectedCategory.name) {
      let duplicateCheck = Categories.filter(
        (el) => el.name.toLowerCase() === selectedCategory.name.toLowerCase()
      );
      if (duplicateCheck.length) {
        this.notifyError("This category is already exist!");
        return false;
      }
    }
    if (!selectedCategory.name) {
      this.notifyError("Please select an existing category or add a new one!");
      return false;
    }
    if (!checklist.name.length) {
      this.notifyError("Please enter checklist name!");
      return false;
    }
    if (!checklist.tasks.length) {
      this.notifyError("Please add checklist tasks!");
      return false;
    }
    return true;
  };

  notifyError(message) {
    openNotificationWithIcon("error", "Validation Failed:", message);
  }

  render() {
    const {
      Categories,
      isTaskModalOpen,
      checklist,
      selectedCategory,
      pageLoading,
      isTaskEdit,
    } = this.state;
    return (
      <Spin spinning={pageLoading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="header-bar" style={{ padding: 10 }}>
                <span
                  style={{ color: "#fff", fontSize: 14, fontWeight: "500" }}
                >
                  Create CheckList
                </span>
              </div>

              <div style={{ display: "flex" }}>
                <div className="file-notes-container">
                  <div style={{ textAlign: "center" }}>
                    <div style={{ marginBottom: 10 }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Choose Main Category
                      </span>
                    </div>
                    <Select
                      showSearch
                      style={{
                        width: 340,
                        borderRadius: 0,
                        textAlign: "start",
                      }}
                      allowClear
                      placeholder="Please Select"
                      optionFilterProp="children"
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={this.onChange.bind(this)}
                      value={selectedCategory.id ? selectedCategory.name : null}
                      onClear={() => this.setState({ selectedCategory: {} })}
                    >
                      {Categories.map((cat) => (
                        <Option value={JSON.stringify(cat)}>{cat.name}</Option>
                      ))}
                    </Select>
                    {/* {selectedCategory.id ? (
                      <Button
                        onClick={() => this.setState({ selectedCategory: {} })}
                      >
                        <CloseOutlined />
                      </Button>
                    ) : null} */}
                    <div style={{ marginTop: 12, marginBottom: 6 }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        OR
                      </span>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                        }}
                      >
                        Add Main Category
                      </span>
                    </div>

                    <Input
                      value={
                        !selectedCategory.id ? selectedCategory.name : null
                      }
                      disabled={selectedCategory.id ? true : false}
                      onChange={(e) =>
                        this.onChange(JSON.stringify({ name: e.target.value }))
                      }
                      style={{ width: 340, height: 32, borderRadius: 5 }}
                      placeholder="Enter Category"
                    />
                    <Divider />
                  </div>
                  <div style={{ backgroundColor: "#eee", padding: 15 }}>
                    <Row>
                      <Col span={6}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: "500",
                          }}
                        >
                          Checklist Name:
                        </span>
                      </Col>
                      <Col span={13}>
                        <Input
                          value={checklist.name}
                          onChange={(e) =>
                            this.checklistNameHandler(e.target.value)
                          }
                          style={{ width: "100%", height: 40 }}
                          placeholder="Enter Checklist Name"
                        />
                      </Col>
                      <Col span={2} offset={2}>
                        <Button
                          style={{
                            backgroundColor: "#1179af",
                            color: "#fff",
                            height: 32,
                            borderRadius: 5,
                            border: "none",
                          }}
                          disabled={checklist.name.length ? false : true}
                          onClick={() =>
                            this.setState({ isTaskModalOpen: true })
                          }
                        >
                          Add Task
                        </Button>
                      </Col>
                    </Row>
                    <div
                      style={{
                        textAlign: "start",
                        backgroundColor: "#fff",
                        paddingTop: 15,
                        paddingBottom: 15,
                        marginTop: 10,
                      }}
                    >
                      {checklist.tasks.length ? (
                        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                          <List
                            itemLayout="horizontal"
                            dataSource={checklist.tasks}
                            renderItem={(item, index) => (
                              <List.Item extra={this.genList(item, index)}>
                                <List.Item.Meta
                                  avatar={
                                    <div
                                      style={{
                                        width: 15,
                                        height: 15,
                                        backgroundColor: "#0F7EB7",
                                        borderRadius: 2,
                                      }}
                                    ></div>
                                  }
                                  title={
                                    <a
                                      style={{
                                        color: "#0F7EB7",
                                        textAlign: "left",
                                      }}
                                      href="#"
                                    >
                                      {item.name}
                                    </a>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                      ) : null}
                      <Row>
                        <Col span={19}></Col>
                        <Col span={2} offset={2}>
                          <div
                            style={{
                              borderTopWidth: 1,
                              borderColor: "#eee",
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#1179af",
                                color: "#fff",
                                height: 32,
                                borderRadius: 5,
                                border: "none",
                                alignSelf: "end",
                                width: 90,
                              }}
                              onClick={() => this.saveDetail()}
                              // disabled={true}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            title={isTaskEdit ? "Update Task" : "Add Task"}
            visible={isTaskModalOpen}
            onOk={() => {
              this.resetTaskForm();
              this.setState({ isTaskModalOpen: false });
            }}
            onCancel={() => {
              this.resetTaskForm();
              this.setState({ isTaskModalOpen: false });
            }}
            header={true}
            footer={false}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  overflow: "inherit",
                  paddingBottom: 6,
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <Form
                  ref={this.formRef}
                  {...layout}
                  name="basic"
                  onFinish={() => this.addNewTask()}
                >
                  <Form.Item
                    colon={false}
                    labelAlign="left"
                    label="Task Title"
                    name="name"
                    style={{ width: "100%" }}
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <Input
                      onChange={(e) =>
                        this.onChangeTaskHandler("name", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    // className={"add-client-form-inputs"}
                    colon={false}
                    labelAlign="left"
                    label="Task Description"
                    name="description"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <TextArea
                      rows={12}
                      onChange={(e) =>
                        this.onChangeTaskHandler("description", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item {...tailLayout} style={{ textAlign: "end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: "#1179af",
                        color: "#fff",
                        height: 32,
                        borderRadius: 5,
                        border: "none",
                      }}
                    >
                      {isTaskEdit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    );
  }
}

export default AddChecklist;
