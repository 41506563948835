import { api } from "../../services/api";
import * as types from "../Constants";

export const getProfileClientTag = (id) => {
  const options = {
    url: `v1/client/AssignTag/All/${id}`,
  };
  options.types = [
    types.GET_PROFILE_CLIENT_TAG_SUCCESS,
    types.GET_PROFILE_CLIENT_TAG_FAILURE,
  ];

  return api.get(options);
};

export const addProfileClientTag = (data) => {
  const options = {
    url: "v1/client/AssignTag",
  };
  options.types = [
    types.ADD_PROFILE_CLIENT_TAG_SUCCESS,
    types.ADD_PROFILE_CLIENT_TAG_FAILURE,
  ];
  return api.post(options, data);
};

export const removeProfileClientTag = (data) => {
  const options = {
    url: "v1/client/AssignTag",
  };
  options.types = [
    types.DELETE_PROFILE_CLIENT_TAG_SUCCESS,
    types.DELETE_PROFILE_CLIENT_TAG_FAILURE,
  ];

  return api.delete(options, data);
};

export const getClientSource = () => {
  const options = {
    url: "v1/config/GetAllClientSource",
  };
  options.types = [
    types.GET_CLIENT_SOURCE_LISTING_SUCCESS,
    types.GET_CLIENT_SOURCE_LISTING_FAILURE,
  ];

  return api.get(options);
};
