import { api } from "../../services/api";
import * as types from "../Constants";
import {
  HIDE_UNHIDE_VISA_TYPE_SUCCESS,
  VISA_TYPE_WITH_HIDDEN_SUCCESS,
} from "../Constants";

export const getVisaType = (branchId) => {
  var selectedBranchId = localStorage.getItem("selectedBranchId");
  let id = selectedBranchId || branchId;
  const options = {
    url: "v1/company/BranchVisaType/All/" + id,
  };

  options.types = [types.VISA_TYPE_SUCCESS, types.VISA_TYPE_FAILURE];

  return api.get(options);
};

export const getVisaTypeWithHidden = () => {
  const options = {
    url: `v1/company/BranchVisaType/WithHidden/All`,
  };

  options.types = [
    types.VISA_TYPE_WITH_HIDDEN_SUCCESS,
    types.VISA_TYPE_WITH_HIDDEN_FAILURE,
  ];

  return api.get(options);
};

export const addVisaType = (data) => {
  const options = {
    url: "v1/company/BranchVisaType",
  };

  options.types = [types.ADD_VISA_TYPE_SUCCESS, types.ADD_VISA_TYPE_FAILURE];

  return api.post(options, data);
};

export const hideUnhideVisaType = (data) => {
  const options = {
    url: "v1/company/BranchVisaType/Hide",
  };

  options.types = [
    types.HIDE_UNHIDE_VISA_TYPE_SUCCESS,
    types.HIDE_UNHIDE_VISA_TYPE_FAILURE,
  ];

  return api.put(options, data);
};

export const hideUnhideVisaStatus = (data) => {
  const options = {
    url: "v1/company/visastatus/Hide",
  };

  options.types = [
    types.HIDE_UNHIDE_VISA_STATUS_SUCCESS,
    types.HIDE_UNHIDE_VISA_STATUS_FAILURE,
  ];

  return api.put(options, data);
};

export const updVisaType = (data) => {
  const options = {
    url: "v1/company/BranchVisaType",
  };

  options.types = [
    types.UPDATE_VISA_TYPE_SUCCESS,
    types.UPDATE_VISA_TYPE_FAILURE,
  ];

  return api.put(options, data);
};

export const getCountries = () => {
  const options = {
    url: "v1/config/GetAllCountries",
  };

  options.types = [types.GET_COUNTRIES_SUCCESS, types.GET_COUNTRIES_FAILURE];

  return api.get(options);
};

export const getBranchVisaCountries = () => {
  const options = {
    url: `v1/BranchCountryLinking/ByBranchId`,
  };

  options.types = [
    types.GET_BRANCH_VISA_COUNTRIES_SUCCESS,
    types.GET_BRANCH_VISA_COUNTRIES_FAILURE,
  ];

  return api.get(options);
};
