import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getSusPiInfoRequest: [],
  getSusPiInfoSuccess: ["success"],
  getSusPiInfoFailure: ["error"],

  susPiChngPassRequest: ["data"],
  susPiChngPassSuccess: ["success"],
  susPiChngPassFailure: ["error"],

  addSusPiRequest: ["data"],
  addSusPiSuccess: ["success"],
  addSusPiFailure: ["error"],

  updSusPiRequest: ["data"],
  updSusPiSuccess: ["success"],
  updSusPiFailure: ["error"]
});

export const SusAccSettingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  prsnlInfoData: null,
  fetching: false,
  error: null,
  chngPassFetching: false,
  chngPassSuccess: null,
  chngPassFailure: null,
  addFetching: false,
  addError: null,
  addSuccess: null,
  updFetching: false,
  updError: null,
  updSuccess: null
});

/* ------------- Reducers ------------- */

// we're attempting to get sus personal info data
export const request = state => {
  console.log("on redux call");
  return state.merge({ fetching: true });
};

// we've successfully get sus personal info data
export const success = (state, { success }) =>
  state.merge({ fetching: false, prsnlInfoData: success });

// we've had a problem in geting sus personal info data
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// we're attempting to sus personal info change pass
export const cprequest = state => state.merge({ chngPassFetching: true });

// we've successfully sus personal info change password
export const cpsuccess = (state, { success }) =>
  state.merge({ chngPassFetching: false, chngPassSuccess: success });

// we've had a problem in personal info change pass
export const cpfailure = (state, { chngPassFailure }) =>
  state.merge({ chngPassFetching: false, chngPassFailure });

// we're attempting to add sus personal info
export const addpirequest = state => state.merge({ addFetching: true });

// we've successfully added sus personal info
export const addpisuccess = (state, { addSuccess }) =>
  state.merge({ addFetching: false, addSuccess: addSuccess });

// we've had a problem in adding personal info
export const addpifailure = (state, { addError }) =>
  state.merge({ addFetching: false, addError });

// we're attempting to add sus personal info
export const updpirequest = state => state.merge({ updFetching: true });

// we've successfully added sus personal info
export const updpisuccess = (state, { updSuccess }) =>
  state.merge({ updFetching: false, updSuccess: updSuccess });

// we've had a problem in adding personal info
export const updpifailure = (state, { updError }) =>
  state.merge({ updFetching: false, updError });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SUS_PI_INFO_REQUEST]: request,
  [Types.GET_SUS_PI_INFO_SUCCESS]: success,
  [Types.GET_SUS_PI_INFO_FAILURE]: failure,

  [Types.SUS_PI_CHNG_PASS_REQUEST]: cprequest,
  [Types.SUS_PI_CHNG_PASS_SUCCESS]: cpsuccess,
  [Types.SUS_PI_CHNG_PASS_FAILURE]: cpfailure,

  [Types.ADD_SUS_PI_REQUEST]: addpirequest,
  [Types.ADD_SUS_PI_SUCCESS]: addpisuccess,
  [Types.ADD_SUS_PI_FAILURE]: addpifailure,

  [Types.ADD_SUS_PI_REQUEST]: updpirequest,
  [Types.ADD_SUS_PI_SUCCESS]: updpisuccess,
  [Types.ADD_SUS_PI_FAILURE]: updpifailure
});
