import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Input,
  Table,
} from "antd";
// import "./OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const ApiKey = ({
  onSetActiveInnerTab,
  //   onGetOutlookMail,
  //   onOutlookLogin,
  //   onAddOutlookAccount,
  //   onRemoveOutlookAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [editEnable, setEditEnable] = useState(false);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [visible, setModalVisible] = useState(false);
  const state = useLocation().state;

  const [form] = Form.useForm();

  const columns = [
    // {
    //   title: "Name",
    //   dataIndex: "",
    //   key: "",
    //   width: "100px",
    //   render: (text, record) => (
    //     <p
    //       style={{
    //         color: "black",
    //         fontSize: "12px",
    //         fontWeight: "initial",
    //         fontFamily: "Poppins, sans-serif",
    //       }}
    //     >
    //       {record.countryName}
    //     </p>
    //   ),
    // },
    {
      title: "API Key",
      dataIndex: "",
      key: "",
      width: "100px",
      textWrap: "word-break",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
            width: "400px",
          }}
        >
          {record.apiKey}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div className="table-action" style={{ alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 15,
                }}
              >
                <Button
                  style={{
                    fontSize: 10,
                    fontWeight: "500",
                    padding: 5,
                    borderRadius: 5,
                  }}
                  // disabled={!showCopyButton}
                  onClick={() => {
                    navigator.clipboard.writeText(record.apiKey);
                    message.info("Copied!");
                  }}
                >
                  Copy
                </Button>
                <Button
                  style={{
                    fontSize: 10,
                    fontWeight: "500",
                    padding: 5,
                    borderRadius: 5,
                    marginLeft: 5,
                  }}
                  className="button-blue"
                  onClick={() => {
                    let data = {
                      id: record.id,
                    };
                    const delApiKeyOpt = {
                      url: `v1/EzmApiKey`,
                    };
                    delApiKeyOpt.types = [
                      "DELETE_API_KEY_SUCCESS",
                      "DELETE_API_KEY_FAILURE",
                    ];
                    setLoading(true);
                    apiRefresh
                      .delete(delApiKeyOpt, data)
                      .then((res) => {
                        message.success("API key revoded successfully!");
                        getApiKey();
                        setLoading(false);
                      })
                      .catch((err) => {
                        message.error("Failed!");
                        setLoading(false);
                      });
                  }}
                >
                  Revoke
                </Button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getApiKey();
  }, []);

  const closeModal = () => {
    setModalVisible(false);
  };

  const getApiKey = () => {
    const getApiKeyOpt = {
      url: `v1/EzmApiKey/GetByBranchId`,
    };
    getApiKeyOpt.types = ["GET_API_KEY_SUCCESS", "GET_API_KEY_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getApiKeyOpt)
      .then((res) => {
        setLoading(false);
        setApiKey(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (editEnable) {
      let data = {
        id: apiKey.id,
        clientIP: values.ipAddress || "",
      };
      const getApiKeyOpt = {
        url: `v1/EzmApiKey`,
      };
      getApiKeyOpt.types = [
        "RE_GENERATE_API_KEY_SUCCESS",
        "RE_GENERATE_API_KEY_FAILURE",
      ];

      setLoading(true);

      apiRefresh
        .put(getApiKeyOpt, data)
        .then((res) => {
          getApiKey();
          setLoading(false);
          form.resetFields();
          setEditEnable(false);
          setShowCopyButton(true);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed!");
        });
    } else {
      let data = {
        clientIP: values.ipAddress || "",
        keyType: "API",
      };
      const getApiKeyOpt = {
        url: `v1/EzmApiKey`,
      };
      getApiKeyOpt.types = [
        "GENERATE_API_KEY_SUCCESS",
        "GENERATE_API_KEY_FAILURE",
      ];

      setLoading(true);

      apiRefresh
        .post(getApiKeyOpt, data)
        .then((res) => {
          getApiKey();
          setLoading(false);
          form.resetFields();
          setEditEnable(false);
          setShowCopyButton(true);
          closeModal();
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed!");
        });
    }
  };

  const getAddModal = () => {
    return (
      <div>
        <Modal
          visible={visible}
          width="200"
          effect="fadeInUp"
          onClickAway={() => closeModal()}
          title="Genereate API Key"
          footer={false}
          onCancel={closeModal}
        >
          <div style={{ padding: 20 }}>
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                className="d-block"
                name="ipAddress"
                // rules={[{ required: true }]}
              >
                <Input placeholder="Ip Address" />
              </Form.Item>
              <Form.Item className="d-block">
                <Button type="primary" htmlType="submit">
                  Generate Key
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img src={Images.apiKeyIcon} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">Api Key</span>
                  <Tooltip
                    placement="topLeft"
                    title={`Add outlook for sync your mails in ezymigrate.`}
                  >
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <div className="w-80" style={{ width: "90%" }}>
                  <h3>API KEY</h3>
                  <div
                    className="document-sync-box"
                    style={{
                      paddingLeft: apiKey && !editEnable ? 20 : 65,
                      paddingRight: apiKey && !editEnable ? 20 : 65,
                    }}
                  >
                    <div>
                      <div className="client-tag-table">
                        <div
                          className="reminder-set-form"
                          style={{ marginTop: 20 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{
                                fontSize: 10,
                                fontWeight: "500",
                                padding: 5,
                                borderRadius: 5,
                                marginLeft: 5,
                              }}
                              className="button-blue"
                              onClick={() => {
                                setModalVisible(true);
                              }}
                            >
                              Genereate API Key
                            </Button>
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              marginLeft: 20,
                              marginRight: 20,
                            }}
                          >
                            <Table
                              // expandedRowKeys={expandedRowsEntriesKeys}
                              pagination={false}
                              className="job-table table-head employer-tables border-3 school-table"
                              dataSource={apiKey}
                              columns={columns}
                            />
                          </div>
                          <>
                            <div>
                              {/* <div className="userDetail">
                                <p
                                  style={{
                                    overflowWrap: "break-word",
                                    color: "#555",
                                    fontWeight: "400",
                                  }}
                                >
                                  {apiKey.apiKey}
                                </p>
                              </div> */}
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getAddModal()}
        </Spin>
      </div>
    </Fragment>
  );
};
export default ApiKey;
