import React, { Fragment, useState } from "react";
import { Row, message, Col, Form, Button, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { yyyymmddFormate } from "../../../../Common/reUseFunction";
import moment from "moment";
import FroalaEditorCom from "../../../../Containers/FroalaEditorCom";

const regex = /(<([^>]+)>)/gi;

let userName = localStorage.getItem("userName");

const FileNotesList = ({
  onGetEmployerFile,
  employerFileRes,
  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  userDataEmp,

  onRemoveEmployerFile,

  item,
  index,

  onUpdateEmployerFile,

  setLoading,
  loading,
}) => {
  const [editFileNote, setEditFileNote] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [fileNoteData, setFileNoteData] = useState();

  const removeFileNotes = () => {
    setLoading(true);
    const remove = {
      id: item && item.id,
      delete: true,
    };
    onRemoveEmployerFile(remove)
      .then(() => {
        onGetEmployerFile(userDataEmp && userDataEmp.id);
        setLoading(false);
        message.success("Successfully Deleted!");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editNotes = (value) => {
    console.log("Thisisdemodata---==========", item);
    setEditFileNote(!editFileNote);
    setEditMode(item.note);
  };

  const handleEditNote = (value) => {
    setFileNoteData(value);
    setEditMode(value);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form:", values);

    setLoading(true);
    const update = {
      id: item && item.id,
      note: fileNoteData,
      employerId: userDataEmp && userDataEmp.id,
    };

    onUpdateEmployerFile(update)
      .then(() => {
        onGetEmployerFile(userDataEmp && userDataEmp.id);
        setLoading(false);
        message.success("Successfully Updated!");
        setEditFileNote(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <div
            className={
              index % 2 === 0
                ? "file-note-dark margin-top space-between d-flex"
                : "file-note-light margin-top space-between d-flex"
            }
            key={item.id}
          >
            <div style={{ width: "92%" }}>
              <div className="float-left d-flex width-100">
                <p style={{ fontSize: 12, marginRight: "10px" }}>
                  {moment(item.createdDate).format("DD MMM YYYY hh:mm A") + " "}
                  {/* {yyyymmddFormate(item.createdDate)} */}
                </p>
                <p style={{ fontSize: 12, color: "#36b1e4" }}>{userName}</p>
              </div>
              <div
                className="float-left width-100"
                style={{ textAlign: "left" }}
              >
                <p style={{ color: "#1081b9", fontSize: 12 }}>
                  {item.note.replace(regex, "")}
                </p>
              </div>
            </div>

            <div>
              <div className="table-action">
                <EditOutlined onClick={() => editNotes()} />

                <DeleteOutlined onClick={() => removeFileNotes()} />
              </div>
            </div>
          </div>
          {editFileNote === true && (
            <div>
              <Row>
                <Col span={24}>
                  <div className=" margin-top  letter-froala">
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={editMode && editMode}
                      onModelChange={(value) => handleEditNote(value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ justifyContent: "flex-end" }}>
                <Form.Item className="d-flex">
                  <Button danger type="primary" htmlType="submit">
                    UPDATE
                  </Button>
                </Form.Item>
              </Row>
            </div>
          )}
        </Form>
      </Spin>
    </Fragment>
  );
};

export default FileNotesList;
