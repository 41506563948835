import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getVisaTypeRequest: [],
  getVisaTypeSuccess: ["success"],
  getVisaTypeFailure: ["error"],

  addVisaTypeRequest: ["data"],
  addVisaTypeSuccess: ["success"],
  addVisaTypeFailure: ["error"],

  updUserContactRequest: ["data"],
  updUserContactSuccess: ["success"],
  updUserContactFailure: ["error"]
});

export const VisaTypeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  visaTypeData: null,
  fetching: false,
  error: null,
  addFetching: false,
  addError: null,
  addSuccess: null,
  updFetching: false,
  updError: null,
  updSuccess: null
});

/* ------------- Reducers ------------- */

// we're attempting to get sus user contact
export const request = state => {
  console.log("on redux call");
  return state.merge({ fetching: true });
};

// we've successfully get sus user contact
export const success = (state, { success }) =>
  state.merge({ fetching: false, visaTypeData: success });

// we've had a problem in geting sus user contact
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// we're attempting to add sus user contact
export const addrequest = state => state.merge({ addFetching: true });

// we've successfully added sus user contact
export const addsuccess = (state, { addSuccess }) =>
  state.merge({ addFetching: false, addSuccess: addSuccess });

// we've had a problem in adding user contact
export const addfailure = (state, { addError }) =>
  state.merge({ addFetching: false, addError });

// we're attempting to add sus user contact
export const updrequest = state => state.merge({ updFetching: true });

// we've successfully added sus user contact
export const updsuccess = (state, { updSuccess }) =>
  state.merge({ updFetching: false, updSuccess: updSuccess });

// we've had a problem in adding user contact
export const updfailure = (state, { updError }) =>
  state.merge({ updFetching: false, updError });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_VISA_TYPE_REQUEST]: request,
  [Types.GET_VISA_TYPE_SUCCESS]: success,
  [Types.GET_VISA_TYPE_FAILURE]: failure,

  [Types.ADD_VISA_TYPE_REQUEST]: addrequest,
  [Types.ADD_VISA_TYPE_SUCCESS]: addsuccess,
  [Types.ADD_VISA_TYPE_FAILURE]: addfailure,

  [Types.UPD_USER_CONTACT_REQUEST]: updrequest,
  [Types.UPD_USER_CONTACT_SUCCESS]: updsuccess,
  [Types.UPD_USER_CONTACT_FAILURE]: updfailure
});
