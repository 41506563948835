import React, { Fragment, useState, useEffect } from "react";
import { Select, message, DatePicker, Spin } from "antd";
import moment from "moment";

import UpdateAdmissionCard from "./UpdateAdmissionCard";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const UpdateClientAdmission = ({
  onUpdAdmissionStatus,
  admissionStatusData,
  admissionProgramData,
  onGetAdmissionProgram,
  activeKey,
  onGetAdmissionStatuses,
  profileDataRdx,
}) => {
  const [admissionData, setAdmissionData] = useState(null);
  const [apiCall, setApiCall] = useState(false);
  //   console.log("show visa  status data ", visaStatusData);

  useEffect(() => {
    if ((activeKey === "2") & !apiCall) {
      onGetAdmissionStatuses();
      getAdmission();
    }
  }, [activeKey]);

  const getAdmission = () => {
    onGetAdmissionProgram().then((res) => {
      setAdmissionData(res.payload);
    });
  };

  if (admissionStatusData && admissionStatusData.items) {
    var admissionStatuses = [];
    for (var i = 0; i < admissionStatusData.items.length; i++) {
      admissionStatuses.push(
        <Option key={admissionStatusData.items[i].id}>
          {admissionStatusData.items[i].name}
        </Option>
      );
    }
  }

  return (
    <Fragment>
      {admissionData &&
        admissionData.items.map((program, index) => {
          return (
            <div key={index}>
              <UpdateAdmissionCard
                program={program}
                onUpdAdmissionStatus={onUpdAdmissionStatus}
                admissionStatusData={admissionStatusData}
                onGetAdmissionProgram={getAdmission}
                profileDataRdx={profileDataRdx}
              />
            </div>
          );
        })}
    </Fragment>
  );
};

export default UpdateClientAdmission;
