// @flow
import * as React from "react";
import styled from "styled-components";
import logo from "../../images/logo.png";
import Select from "react-select";
import navMenu from "../../images/nav-collaps.png";
import { Images } from "../../Themes";
import { Link } from "react-router-dom";

const DashboardClientTopBar = (Props) => {
  return (
    <div>
      <div className="cp-top-bar">
        {Props.data &&
          Props.data.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  to={item.linkName}
                  style={{ textDecoration: "none", color: "#FFFFFF" }}
                >
                  <div
                    className="cp-top-bar-div"
                    style={{
                      backgroundColor:
                        Props.activeTab == item.tabName ? "#55ABDA" : "#3C79B0",
                      paddingLeft: Props.activeTab == item.tabName ? 15 : 10,
                      paddingRight: Props.activeTab == item.tabName ? 15 : 10,
                    }}
                  >
                    <span
                      className="cp-top-bar-text"
                      style={{
                        color: "#fff",
                        fontWeight: "500"
                      }}
                    >
                      {item.tabName}
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DashboardClientTopBar;
