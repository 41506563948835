import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const categoryChecklistReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_VISA_CATEGORY_TYPE_SUCCESS:
      return { ...state, visaCategoryRes: action.payload };
    case types.GET_VISA_CATEGORY_TYPE_FAILURE:
       
    case types.GET_VISA_CATEGORY_CHECKLIST_SUCCESS:
      return { ...state, visaCategoryChecklistRes: action.payload };
    case types.GET_VISA_CATEGORY_CHECKLIST_FAILURE:
       
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon(
          "error",
          "Empty",
          "This category has't any checklist"
        );
        return { ...state, visaCategoryChecklistRes: [] };
      }
       
    case types.DELETE_CHECKLIST_CATEGORY_SUCCESS:
      return { ...state, removeChecklistCategorySuccess: action.payload };
    case types.DELETE_CHECKLIST_CATEGORY_FAILURE:
       
      return { ...state, removeChecklistCategoryError: action.payload };
    case types.UPDATE_CHECKLIST_CATEGORY_SUCCESS:
      return { ...state, updateChecklistCategorySuccess: action.payload };
    case types.UPDATE_CHECKLIST_CATEGORY_FAILURE:
       
      return { ...state, updateChecklistCategoryError: action.payload };
    case types.UPDATE_VISA_CHECKLIST_SUCCESS:
      return { ...state, checklistUpdateRes: action.payload };
    case types.UPDATE_VISA_CHECKLIST_FAILURE:
       
    case types.DELETE_CHECKLIST_SUCCESS:
      return { ...state, removeChecklistSuccess: action.payload };
    case types.DELETE_CHECKLIST_FAILURE:
       
      return { ...state, removeChecklistError: action.payload };
    case types.GET_CHECKLIST_TASK_SUCCESS:
      return { ...state, checklisttaskRes: action.payload };
    case types.GET_CHECKLIST_TASK_FAILURE:
      return { ...state, checklisttaskError: action.payload };
    case types.DELETE_CHECKLIST_TASK_SUCCESS:
      return { ...state, removeChecklistTaskSuccess: action.payload };
    case types.DELETE_CHECKLIST_TASK_FAILURE:
       
      return { ...state, removeChecklistTaskError: action.payload };
    default:
      return state;
  }
};
