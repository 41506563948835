import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const dashboardBiReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_ANALYTICS_SUCCESS:
      return { ...state, clientAnalytics: action.payload };
    case types.GET_CLIENT_ANALYTICS_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, clientAnalyticsError: action.payload };
    case types.GET_TASK_ANALYTICS_SUCCESS:
      return { ...state, taskAnalytics: action.payload };
    case types.GET_TASK_ANALYTICS_FAILURE:
      return { ...state, taskAnalyticsError: action.payload };
    case types.GET_ACCOUNTS_ANALYTICS_SUCCESS:
      return { ...state, accountAnalytics: action.payload };
    case types.GET_ACCOUNTS_ANALYTICS_FAILURE:
      return { ...state, accountAnalyticsError: action.payload };
    case types.GET_VISA_ANALYTICS_SUCCESS:
      return { ...state, visaAnalytics: action.payload };
    case types.GET_VISA_ANALYTICS_FAILURE:
      return { ...state, visaAnalyticsError: action.payload };
    case types.GET_IDLE_SINCE_ANALYTICS_SUCCESS:
      return { ...state, idleSinceAnalytics: action.payload };
    case types.GET_IDLE_SINCE_ANALYTICS_FAILURE:
      return { ...state, idleSinceAnalyticsError: action.payload };
    case types.GET_ACTIVE_SINCE_ANALYTICS_SUCCESS:
      return { ...state, activeSinceAnalytics: action.payload };
    case types.GET_ACTIVE_SINCE_ANALYTICS_FAILURE:
      return { ...state, activeSinceAnalyticsError: action.payload };
    case types.GET_VISA_STATUS_ANALYTICS_SUCCESS:
      return { ...state, visaStatusAnalytics: action.payload };
    case types.GET_VISA_STATUS_ANALYTICS_FAILURE:
      return { ...state, visaStatusAnalyticsError: action.payload };
    case types.GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_SUCCESS:
      return { ...state, activeBalanceAnalytics: action.payload };
    case types.GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_FAILURE:
      return { ...state, activeBalanceAnalyticsError: action.payload };
    case types.GET_CLIENT_ANALYTICS_DETAILS_SUCCESS:
      return { ...state, clientDetails: action.payload };
    case types.GET_CLIENT_ANALYTICS_DETAILS_FAILURE:
      return { ...state, clientDetailsError: action.payload };
    case types.GET_VISA_ANALYTICS_CLIENT_DETAILS_SUCCESS:
      return { ...state, visaClientDetails: action.payload };
    case types.GET_VISA_ANALYTICS_CLIENT_DETAILS_FAILURE:
      return { ...state, visaClientDetailsError: action.payload };
    case types.GET_INCOMING_ACCOUNTS_CLIENT_INFO_SUCCESS:
      return { ...state, incomingClientDetails: action.payload };
    case types.GET_INCOMING_ACCOUNTS_CLIENT_INFO_FAILURE:
      return { ...state, incomingClientDetailsError: action.payload };
    case types.GET_OUTGOING_ACCOUNTS_CLIENT_INFO_SUCCESS:
      return { ...state, outgoingClientDetails: action.payload };
    case types.GET_OUTGOING_ACCOUNTS_CLIENT_INFO_FAILURE:
      return { ...state, outgoingClientDetailsError: action.payload };
    default:
      return state;
  }
};
