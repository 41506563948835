import React, { useState, Fragment, useEffect } from "react";
import { Modal, Spin, Form, Select, Button, message } from "antd";
import { redirectUrlXero } from "../config.js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getXeroToken } from "../store/Actions";
let location = window.location.search;
let _location = window.location.href;

const XeroAddAccount = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const redirection = () => {
    onGettingXeroRedirectParams();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    redirection();
  }, []);
  // useEffect(() => {dispatch(getXeroToken()) } , []);
  const onGettingXeroRedirectParams = async () => {
    // setLoading(true);
    let params = new URLSearchParams(location);
    let account_code = params.get("code");

    if (account_code && account_code) {
      setLoading(true);
      props
        .createToken(_location)
        .then(() => {
          setLoading(false);
          history.push("/xero-sync-invoices");
        })
        .catch(() => {
          setLoading(false);
        });
    }
    //  props.getXeroToken().then((res) =>  {
    //
    //      setLoading(false);
    //  }).catch(() => {
    //      setLoading(false);
    //  })
  };

  const loginXeroAccount = async () => {
    setLoading(true);
    props
      .createCode({ url: redirectUrlXero })
      .then((response) => {
        setLoading(false);
        if (response.payload)
          if (response.payload.uri) window.location = response.payload.uri;
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div className="w-80 ">
            <div className="document-sync-box">
              <div>
                <div className="client-tag-table">
                  <div className="reminder-set-form margin-top-34">
                    <Form.Item onClick={loginXeroAccount} className="d-block">
                      <Button
                        className="button-blue"
                        type="primary"
                        disabled={props.isExists}
                      >
                        ADD ACCOUNT
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default XeroAddAccount;
