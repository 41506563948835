import { combineReducers } from "redux";
import { userReducer } from "./User";
import { companyReducer } from "./Company";
import { userDetailReducer } from "./UserDetail";
import { groupReducer } from "./Group";
import { managerReducer } from "./Manager";
import { ownerReducer } from "./Owner";
import { teamMemberReducer } from "./TeamMember";
import { branchReducer } from "./Branch";
import { userPermissionReducer } from "./Permission";
import { visaStatusReducer } from "./VisaStatus";
import { visaTypeReducer } from "./VisaType";
import { potentialClientReducer } from "./PotentialClientStatus";
import { clientProfileReducer } from "./ClientProfile";
import { partnerReducer } from "./Partner";
import { questionnaireReducer } from "./Questionnaire";
import { accountSetReducer } from "./AccountSettings";
import { qualificationReducer } from "./ClientQualification";
import { fileNotesReducer } from "./FileNotes";
import { allClientReducer } from "./AllClients";
import { reminderReducer } from "./MyDailyTasksReminder";
import { documentTypeReducer } from "./DocumentTypes";
import { LetterTemplatesReducer } from "./LetterTemplates";
import { accountDocumentReducer } from "./DocumentsSettings";
import { timeTrackingReducer } from "./TimeTracking";
import { visaNotificationReducer } from "./VisaUpdateNotification";
import { userLevelSettingsReducer } from "./UserLevelSettings";
import { defaultFontReducer } from "./DefaultFontSettings";
import { timeZoneReducer } from "./TimeZoneSettings";
import { emailSetReducer } from "./DailyEmailSettings";
import { questionnaireSetReducer } from "./QuestionnaireSettings";
import { docViewReducer } from "./DocumentView";
import { employerManagReducer } from "./EmployerManagement";
import { accountReducer } from "./Accounts";
import { studentListingReducer } from "./StudentListing";
import { schoolReducer } from "./School";
import { commissionRemindersReducer } from "./CommissionReminders";
import { clientVisaReducer } from "./ClientVisa";
import { clientAdmissionReducer } from "./ClientAdmission";
import { docChecklistReducer } from "./DocumentChecklist";
import { imapSettingReducer } from "./ImapSettings";
import { docSyncReducer } from "./DocumentSync";
import { signUpReducer } from "./SignUp";
import { clientEmployerReducer } from "./ClientEmployer";
import { EmployerJobHistoryReducer } from "./ClientJobHistory";
import { invoicesReducer } from "./Invoices";
import { outgoingPaymentsReducer } from "./OutgoingPayments";
import { caseManagementReducer } from "./CaseManagement";
import { addchecklistReducer } from "./AddChecklist";
import { categoryChecklistReducer } from "./CategoryChecklist";
import { adminLoginReducer } from "./AdminLogin";
import { AdminReducer } from "./Admin";
import { reportReducer } from "./Reports";
import { clientSummaryReducer } from "./ClientSummary";
import { clientActivityReducer } from "./Activity";

import { xeroReducer } from "./Xero";
import { mailReducer } from "./Mail";
import { dashboardRemindersReducer } from "./DashboardReminders";
import { dashboardBiReducer } from "./DashboardBi";
import { dashboardPotentialClientReducer } from "./DashboardPotentialClient";
import { dashboardEmployersReducer } from "./DashboardEmployers";
import { dashboardStudentReducer } from "./DashboardStudent";
import { clientEmailReducer } from "./ClientEmail";
import { templatesReducer } from "./Template";
import { emailIntegrationReducer } from "./EmailIntegration";
import { supervisorReducer } from "./Supervisor";
import { dealsReducer } from "./Deals";
import { agentsReducer } from "./Agents";

const reducersObj = {
  userReducer,
  companyReducer,
  userDetailReducer,
  groupReducer,
  managerReducer,
  ownerReducer,
  teamMemberReducer,
  branchReducer,
  userPermissionReducer,
  visaStatusReducer,
  visaTypeReducer,
  potentialClientReducer,
  clientProfileReducer,
  partnerReducer,
  questionnaireReducer,
  accountSetReducer,
  qualificationReducer,
  fileNotesReducer,
  allClientReducer,
  reminderReducer,
  documentTypeReducer,
  LetterTemplatesReducer,
  accountDocumentReducer,
  timeTrackingReducer,
  visaNotificationReducer,
  userLevelSettingsReducer,
  defaultFontReducer,
  timeZoneReducer,
  emailSetReducer,
  questionnaireSetReducer,
  docViewReducer,
  employerManagReducer,
  clientVisaReducer,
  clientAdmissionReducer,
  accountReducer,
  studentListingReducer,
  schoolReducer,
  commissionRemindersReducer,
  docChecklistReducer,
  imapSettingReducer,
  docSyncReducer,
  signUpReducer,
  clientEmployerReducer,
  EmployerJobHistoryReducer,
  invoicesReducer,
  outgoingPaymentsReducer,
  caseManagementReducer,
  addchecklistReducer,
  categoryChecklistReducer,
  adminLoginReducer,
  AdminReducer,
  reportReducer,
  clientActivityReducer,
  clientSummaryReducer,
  xeroReducer,
  mailReducer,
  dashboardPotentialClientReducer,
  dashboardRemindersReducer,
  dashboardBiReducer,
  dashboardEmployersReducer,
  dashboardStudentReducer,
  clientEmailReducer,
  templatesReducer,
  emailIntegrationReducer,
  supervisorReducer,
  dealsReducer,
  agentsReducer,
};

export default combineReducers(reducersObj);
