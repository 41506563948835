// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
  DatePicker
} from "antd";

import OptionalQuestion from "./OptionalQuestion";
import UpdateSectionCard from "./UpdateSectionCard";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function UpdateCreatedQuestionCard(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [fieldName, setFieldName] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [answerType, setAnswerType] = useState(
    Props.questionData ? Props.questionData.answerType.toString() : ""
  );
  const [optionText, setOptionText] = useState("");
  const [options, setOptions] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [multiCheckboxOption, setMultiCheckbox] = useState("");
  const [radioOption, setRadioOption] = useState("");
  const [isCascading, setCascading] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [dataByUpdate, setDataByUpdate] = useState(true);
  const [isOptionSectionAdd, setIsOptionSectionAdd] = useState(false);
  const [addOptionalSection, setAddOptionalSection] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");

  useEffect(() => {
    setFieldName(Props.field.name);
    setFieldKey(Props.field.fieldKey);
  }, [Props]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;
  const sectionOptionsArray = [];
  // if(radioOption === "") {
  //  
  // const sectionOptions = [];
  // }
  //  

  if (
    !Props.isNewQuestion &&
    options.length === 0 &&
    Props.questionData &&
    Props.questionData.questionOptions.length > 0
  ) {
    var getOptions = [];
    if (Props.questionOptions) {
      for (var i = 0; i < Props.questionData.questionOptions.length; i++) {
        console.log(Props.questionData.questionOptions[i].option);
        getOptions.push(Props.questionData.questionOptions[i].option);
        Props.addOptionValues({
          optionName: Props.questionData.questionOptions[i].option,
          mainSection: Props.mainSectionIndex,
          mainQuestion: Props.mainQuestionIndex,
          questionIndex: Props.optQuestionIndex,
          sectionIndex: Props.optSectionIndex
        });
      }
    }

    setOptions(getOptions);
  }
  if (!Props.isNewQuestion && Props.questionData) {
    Props.questionData.answerType = Props.questionData.answerType.toString();
  }

  const onChangeAnswerType = value => {
    setAnswerType(value);
  };

  const onChangeRadio = e => {
    console.log("radio1 checked", e.target.value);
    setRadioOption(e.target.value);
    setAddOptionalSection(false);
  };

  const onChangeOptionCheckbox = value => {
    setMultiCheckbox(value);
  };

  const onChangeCheckbox = value => {
    console.log("checkbox checked", value);
    setCheckboxOptions(value);
    var checkCascading = value.find(obj => obj === "cascading");
    if (checkCascading) {
      setCascading(true);
    } else {
      setCascading(false);
    }
  };

  if (options.length <= 0) {
    var optionsArray = [];
  }

  const addDropdownOptions = () => {
    setOptions(options.concat(optionText));
    Props.addDropdownValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex
    });
    setOptionText("");
  };
  const addRadioOptions = () => {
    console.log(Props.field, Props.section);
    setOptions(options.concat(optionText));
    Props.addOptionValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex
    });
    setOptionText("");
  };

  const addCheckboxOptions = () => {
    setOptions(options.concat(optionText));
    Props.addCheckboxValues({
      optionName: optionText,
      isOptional: Props.optionalSection,
      mainSection: Props.mainSectionIndex,
      mainQuestion: Props.mainQuestionIndex,
      questionIndex: Props.optQuestionIndex,
      sectionIndex: Props.optSectionIndex
    });
    setOptionText("");
  };

  const dropdownOptions = [];

  for (var i = 0; i < options.length; i++) {
    dropdownOptions.push(<Option key={options[i]}>{options[i]}</Option>);
  }

  const mappParentData = [];
  const mappChildData = [];
  if (Props.mappParentData && Props.mappParentData.length > 0) {
    for (var i = 0; i < Props.mappParentData.length; i++) {
      mappParentData.push(
        <Option key={Props.mappParentData[i].id}>
          {Props.mappParentData[i].name}
        </Option>
      );
    }
  }

  if (Props.mappChildData && Props.mappChildData.length > 0) {
    for (var i = 0; i < Props.mappChildData.length; i++) {
      mappChildData.push(
        <Option key={Props.mappChildData[i].id}>
          {Props.mappChildData[i].name}
        </Option>
      );
    }
  }

  const onChangeMappingParent = value => {
    console.log(value);
    Props.getMappChild({ id: value });
  };

  const onChangeCheckboxOption = value => {
    setCheckboxValue(value);
    setAddOptionalSection(false);
  };

  const selectDropdown = value => {
    setOptionSelected(value);
    setAddOptionalSection(false);
  };

  return (
    <div>
      <>
        <div>
          <Form.List name={[Props.option.name, "optionalQuestions"]}>
            {(optionalQuestions, { add, remove }, { errors }) => {
              // if (isOptionSectionAdd && optionalQuestions.length < 1) {
              //   setIsOptionSectionAdd(false);
              // }
              // if (Props.isCascading || Props.questionData.isCascading) {
              //   if (
              //     optionalQuestions.length === 0 &&
              //     !addOptionalSection &&
              //     (Props.radioOption ||
              //       (Props.option.option && Props.questionData.isCascading) ||
              //       Props.checkboxValue.length === 1 ||
              //       Props.optionSelected)
              //   ) {
              //     add();
              //     setAddOptionalSection(true);
              //   } else if (
              //     optionalQuestions.length > 0 &&
              //     !addOptionalSection
              //   ) {
              //     var findOption = sectionOptions.find(
              //       (obj) => obj.optionName === Props.radioOption.toUpperCase()
              //     );
              //     if (!findOption) {
              //       setAddOptionalSection(true);
              //       add();
              //     }
              //   }
              // }
              return (
                <>
                  {optionalQuestions.map((section, index) => {
                    console.log(optionalQuestions.length, sectionOptions);
                    // if (sectionOptions.length < optionalQuestions.length) {
                    //   if (index === optionalQuestions.length - 1) {
                    //     setSectionOptions(
                    //       sectionOptions.concat({
                    //         optionName:
                    //           Props.checkboxValue.length > 0
                    //             ? Props.checkboxValue[
                    //                 Props.checkboxValue.length - 1
                    //               ].toUpperCase()
                    //             : Props.optionSelected
                    //             ? Props.optionSelected.toUpperCase()
                    //             : Props.radioOption.toUpperCase(),
                    //         sectionInd: index,
                    //         mainQuestionIndex: Props.mainQuestionIndex,
                    //         mainSectionIndex: Props.mainSectionIndex,
                    //       })
                    //     );
                    //   }
                    // }

                    return (
                      <Form.Item required={false} key={section.key}>
                        <UpdateSectionCard
                          section={section}
                          optSectionIndex={index}
                          sectionIndex={index}
                          sectionData={
                            Props.isCascading
                              ? Props.questionData.questionOptions[
                                  Props.optIndex
                                ].optionalQuestions[index]
                              : null
                          }
                          sectionOptions={sectionOptions}
                          options={options}
                          addQuestion={addQuestion}
                          addSectionOptions={Props.addSectionOptions}
                          dataByUpdate={dataByUpdate}
                          optionIndex={Props.optionIndex}
                          mainSectionIndex={Props.mainSectionIndex}
                          mainQuestionIndex={Props.mainQuestionIndex}
                          addOptionValues={Props.addOptionValues}
                          optionalSection={true}
                        />
                      </Form.Item>
                    );
                  })}
                  {addQuestion && setAddQuestion(false)}
                  {isCascading && radioOption && (
                    <Form.Item>
                      <div style={{ display: "flex" }}>
                        <Button
                          type="dashed"
                          onClick={() => {
                            setDataByUpdate(false);
                            add();
                          }}
                          className="cq-orng-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Section</span>
                        </Button>

                        {/*======= Need to finalize======*/}

                        <Button
                          type="dashed"
                          className="cq-orng-btn"
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Text</span>
                        </Button>
                        <Button
                          type="dashed"
                          className="cq-orng-btn"
                          onClick={() => {
                            setAddQuestion(true);
                            setDataByUpdate(false);
                            add();
                          }}
                          style={{ marginTop: 20 }}
                        >
                          <span className="cq-btn-text">Add Quesstion(s)</span>
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.List>
        </div>
      </>
    </div>
  );
}

export default UpdateCreatedQuestionCard;
