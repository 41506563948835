import { api } from "../../services/api";
import * as types from "../Constants";

export const getDocuments = () => {
  let selectedBranchId = localStorage.getItem("selectedBranchId");
  const options = {
    url: `v1/company/document/All`,
  };
  options.types = [types.GET_DOCUMENTS_SUCCESS, types.GET_DOCUMENTS_FAILURE];

  return api.get(options);
};

export const addDocument = (data) => {
  const options = {
    url: "v1/company/document",
  };
  options.types = [types.ADD_DOCUMENTS_SUCCESS, types.ADD_DOCUMENTS_FAILURE];

  return api.post(options, data);
};

export const removeDocument = (data) => {
  const options = {
    url: "v1/company/document",
  };
  options.types = [
    types.DELETE_DOCUMENTS_SUCCESS,
    types.DELETE_DOCUMENTS_FAILURE,
  ];

  return api.delete(options, data);
};

// export const updetaClientTag = (data) => {
//   const options = {
//     url: "v1/potentialclient/markedtags",
//   };
//   options.types = [types.PUT_CLIENT_TAG_SUCCESS, types.PUT_CLIENT_TAG_FAILURE];

//   return api.put(options, data);
// };

// // REMINDER SETTINGS //

// export const getReminderSettings = () => {
//   const options = {
//     url: `v1/reminder/setting/3fa85f64-5717-4562-b3fc-2c963f66afa6  `,
//   };
//   options.types = [
//     types.GET_REMINDER_SETTINGS_SUCCESS,
//     types.GET_REMINDER_SETTINGS_FAILURE,
//   ];

//   return api.get(options);
// };

// export const addReminderSettings = (data) => {
//   const options = {
//     url: "v1/reminder/setting",
//   };
//   options.types = [
//     types.ADD_REMINDER_SETTINGS_SUCCESS,
//     types.ADD_REMINDER_SETTINGS_FAILURE,
//   ];

//   return api.post(options, data);
// };

// // EMAIL CONTENT //

// export const getEmailContent = () => {
//   const options = {
//     url: `v1/emailtemplate/3fa85f64-5717-4562-b3fc-2c963f66afa6`,
//   };
//   options.types = [
//     types.GET_EMAIL_CONTENT_SUCCESS,
//     types.GET_EMAIL_CONTENT_FAILURE,
//   ];

//   return api.get(options);
// };

// export const addEmailContent = (data) => {
//   const options = {
//     url: "v1/emailtemplate",
//   };
//   options.types = [
//     types.ADD_EMAIL_CONTENT_SUCCESS,
//     types.ADD_EMAIL_CONTENT_FAILURE,
//   ];

//   return api.post(options, data);
// };
