import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const docSyncReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DROPBOX_LOGIN_SUCCESS:
      return { ...state, dropboxLoginRes: action.payload };
    case types.DROPBOX_LOGIN_FAILURE:
       
    case types.ADD_DROPBOX_DOC_SUCCESS:
      return { ...state, addDropboxDocRes: action.payload };
    case types.ADD_DROPBOX_DOC_FAILURE:
       
    case types.GET_DROPBOX_DOC_SUCCESS:
      return { ...state, dropboxDocRes: action.payload };
    case types.GET_DROPBOX_DOC_FAILURE:
    //  
    case types.ONEDRIVE_LOGIN_SUCCESS:
      return { ...state, oneDriveLoginRes: action.payload };
    case types.ONEDRIVE_LOGIN_FAILURE:
       
    case types.ONEDRIVE_TOKEN_SUCCESS:
      return { ...state, oneDriveTokenRes: action.payload };
    case types.ONEDRIVE_TOKEN_FAILURE:
       
    case types.ONEDRIVE_ACCOUNT_SUCCESS:
      return { ...state, oneDriveAccountRes: action.payload };
    case types.ONEDRIVE_ACCOUNT_FAILURE:
    //  
    case types.GOOGLEDRIVE_TOKEN_SUCCESS:
      return { ...state, googleDriveTokenRes: action.payload };
    case types.GOOGLEDRIVE_TOKEN_FAILURE:
       
    case types.GOOGLEDRIVE_ACCOUNT_SUCCESS:
      return { ...state, googleDriveAccountRes: action.payload };
    case types.GOOGLEDRIVE_ACCOUNT_FAILURE:
    //  
    case types.OFFICE365_TOKEN_SUCCESS:
      return { ...state, office365TokenRes: action.payload };
    case types.OFFICE365_TOKEN_FAILURE:
       
    case types.OFFICE365_ACCOUNT_SUCCESS:
      return { ...state, office365AccountRes: action.payload };
    case types.OFFICE365_ACCOUNT_FAILURE:
      return { ...state, office365FailRes: action.payload };
    case types.DELETE_DROPBOX_ACCOUNT_SUCCESS:
      return { ...state, deleteDropboxRes: action.payload };
    case types.DELETE_DROPBOX_ACCOUNT_FAILURE:
       
    case types.DELETE_ONEDRIVE_ACCOUNT_SUCCESS:
      return { ...state, deleteOnedriveRes: action.payload };
    case types.DELETE_ONEDRIVE_ACCOUNT_FAILURE:
       
    default:
      return state;
  }
};
