import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
  InputNumber,
  Upload,
} from "antd";
import { bindActionCreators } from "redux";
import {
  addNote,
  editNote,
  getAllNotesListing,
  uploadAvatar,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const layoutSendingReceipt = {
  labelCol: { span: 7, offset: 2 },
  wrapperCol: { span: 14, offset: 1 },
};

class AddNote extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      note: "",
      isButtonDisabled: false,
      imageBlobUrl: "",
      results: [],
    };
  }

  componentWillMount() {
    let _noteInfo = this.props && this.props.noteInfo;
    if (_noteInfo && _noteInfo) {
      let html = _noteInfo.note;
      let re = /src=([^">]+)/g;
      let results = re.exec(html);
      let _finalResult = results ? results[1] : "";
      let _text = html.split("src")[0];

      this.setState(
        {
          id: _noteInfo.id,
          name: _noteInfo.name,
          note: _text,
          imageBlobUrl: _finalResult,
        },
        () => {
          this.formRef.current.setFieldsValue(
            {
              name: _noteInfo.name,
              note: this.state.note,
            },
            () => {}
          );
        }
      );
    }
  }

  componentDidMount() {}

  onFinish = (values) => {
    let _name = values.name;
    let _note = values.note;

    this.setState(
      {
        name: _name,
        note: _note.concat(`src=${this.state.imageBlobUrl}`),
      },
      () => {
        if (this.props && this.props.noteInfo && this.props.noteInfo.id) {
          this.setState({
            isButtonDisabled: false,
          });

          this.props
            .editNote(this.state)
            .then(() => {
              message.success("Note is updated successfully!");
              this.props.getAllNotesListing();
              this.props.handleCancelAddNoteModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        } else {
          this.setState({
            isButtonDisabled: true,
          });
          this.props
            .addNote(this.state)
            .then(() => {
              message.success("Note is added successfully!");
              this.props.getAllNotesListing();
              this.props.handleCancelAddNoteModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        }
      }
    );
  };

  handleChange = ({ fileList }) => {
    let _type =
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].type;

    if (
      _type === "image/jpeg" ||
      _type === "image/jpg" ||
      _type === "image/png" ||
      _type === undefined
    ) {
      this.setState({ fileList }, () => {
        let formData = new FormData();
        let selectedFile = fileList && fileList[0] && fileList[0].originFileObj;
        formData.append("File", selectedFile);
        if (_type !== undefined) {
          // this.setState({ loading: true });
        }

        if (selectedFile) {
          this.props.onUploadAvatar(formData).then(() => {
            if (this.props.imageUploadSuccess) {
              this.setState({
                imageBlobUrl: this.props.imageUploadSuccess,
              });
            }
          });
        } else if (selectedFile === undefined) {
          this.setState({ imageBlobUrl: "" });
        } else {
          //do nothing
        }
      });
    } else {
      message.warning("Only 'png','jpg' & 'jpeg' type of images are allowed");
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  getUploadFeature = (
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    uploadButton
  ) => {
    return (
      <div style={{ marginLeft: "7px" }}>
        <Form.Item colon={false} name="file">
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Upload
            listType="picture-card"
            fileList={fileList}
            beforeUpload={() => false}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
          >
            {fileList && fileList.length > 0 ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Form.Item>
      </div>
    );
  };

  getAddNoteForm = (props) => {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Required!" },
            // ({ getFieldValue }) => ({
            //   validator(rule, value) {
            //     if (value.length > 20) {
            //
            //       return Promise.reject("Character limit exceeded");
            //     } else {
            //       return Promise.resolve();
            //     }
            //   }
            // })
          ]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Notes"
          name="note"
          rules={[
            { required: true, message: "Required!" },
            ({ getFieldValue }) => ({}),
          ]}
        >
          <TextArea size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
        <Row
          style={{
            display: this.state && this.state.imageBlobUrl ? "flex" : "none",
          }}
        >
          <Col xs={14} offset={10}>
            <img
              src={this.state.imageBlobUrl}
              style={{ width: "20%", objectFit: "contain" }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={15} offset={9}>
            {this.getUploadFeature(
              previewVisible,
              previewImage,
              fileList,
              previewTitle,
              uploadButton
            )}
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-outgoing-payments"
          title="Note"
          visible={this.props.visibleAddNoteModal}
          onCancel={this.props.handleCancelAddNoteModal}
          footer={null}
          maskClosable={false}
        >
          <Form
            {...layoutSendingReceipt}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            {this.getAddNoteForm(this.props)}
            <Row>
              <Col xs={4} offset={18} style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={"button-blue"}
                    style={{ marginRight: "10px" }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    className={"button-blue"}
                    type="primary"
                    onClick={this.props.handleCancelAddNoteModal}
                  >
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.invoicesReducer &&
    state.invoicesReducer.noteInfo &&
    state.invoicesReducer.noteInfo;

  return {
    noteInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.noteInfo &&
      state.invoicesReducer.noteInfo,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNote: bindActionCreators(addNote, dispatch),
  editNote: bindActionCreators(editNote, dispatch),
  getAllNotesListing: bindActionCreators(getAllNotesListing, dispatch),
  onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
});

AddNote = connect(mapStateToProps, mapDispatchToProps)(AddNote);

export default withRouter(AddNote);
