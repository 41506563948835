import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
//import { Test } from './PotentialClientDashboardReport.styles';

class ClientSummaryReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentWillMount = () => {
    console.log("PotentialClientDashboardReport will mount");
  };

  componentDidMount = () => {
    console.log("PotentialClientDashboardReport mounted");
  };

  componentWillReceiveProps = (nextProps) => {
    console.log("PotentialClientDashboardReport will receive props", nextProps);
  };

  componentWillUpdate = (nextProps, nextState) => {
    console.log(
      "PotentialClientDashboardReport will update",
      nextProps,
      nextState
    );
  };

  componentDidUpdate = () => {
    console.log("PotentialClientDashboardReport did update");
  };

  componentWillUnmount = () => {
    console.log("PotentialClientDashboardReport will unmount");
  };

  render() {
    const {
      Clients,
      pageSize,
      currentPage,
      totalPages,
      columns,
      paginate,
    } = this.props;

    return (
      <div className="cell-margin">
        <Table
          className="dashboard-summary-table line-height"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "line-height" : "line-height"
          }
          columns={columns}
          dataSource={Clients}
          onChange={paginate}
          showSorterTooltip={{ title: "" }}
          pagination={{
            defaultCurrent: currentPage,
            total: totalPages,
            showSizeChanger: true,
            defaultPageSize: pageSize,
            pageSizeOptions: ["20", "50", "100", "150", "200"],
          }}
          // scroll={{ x: "auto" }}
          scroll={{ x: "auto", y: `calc(100vh - 314px)` }}
        />
      </div>
    );
  }
}

export default ClientSummaryReport;
