import React, { useState, Fragment, useEffect } from "react";
import { LeftCircleFilled, MenuOutlined } from "@ant-design/icons";
import { sortableHandle } from "react-sortable-hoc";

import { Images } from "../../Themes";
import { Spin, message, Button, Tooltip, Input, Form } from "antd";
import ContractEmailTable from "./ContractEmailTable";
import { apiRefresh } from "../../services/api";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const ContractEmail = ({
  onGetContractEmail,
  contractEmailRes,
  onAddContractEmail,

  onSortLetterTemplates,
  onGetDocumentDownload,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [updatedata, setUpdatedata] = useState();
  const [email, setEmail] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    onGetContractEmail()
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const removeTag = (id) => {
    let data = {
      id: id,
    };
    const histOpt = {
      url: `v1/client/contract/ContractEmail`,
    };
    histOpt.types = [
      "DELETE_CONTRACT_EMAIL_SUCCESS",
      "DELETE_CONTRACT_EMAIL_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(histOpt, data)
      .then((res) => {
        onGetContractEmail()
          .then((res) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  const addContractEmail = () => {
    let data = {
      contractEmail: email,
    };
    onAddContractEmail(data).then((res) => {
      form.setFieldsValue({
        email: "",
      });
      onGetContractEmail();
    });
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.letterTemplate}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">Contract Email</span>
                <Tooltip
                  placement="topLeft"
                  title={`This is where you can create and load your contract email and you can send the signed agreement contract on these added emails.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="w-80">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <Form onFinish={addContractEmail} form={form}>
                    <div
                      className="d-flex space-between align-items-center"
                      style={{ marginTop: 60 }}
                    >
                      <div>
                        <span>Email</span>
                      </div>
                      <div style={{ width: "60%" }}>
                        <Form.Item
                          name="email"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please enter valid email address!",
                            },
                          ]}
                        >
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "flex-end", marginTop: 10 }}
                    >
                      <Form.Item>
                        <Button
                          type="primary"
                          className={"button-blue"}
                          htmlType="submit"
                          // onClick={addContractEmail}
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                  <Spin size="large" spinning={loading}>
                    <ContractEmailTable
                      onGetContractEmail={onGetContractEmail}
                      contractEmailRes={contractEmailRes}
                      storeData={storeData}
                      setIsModalVisible={setIsModalVisible}
                      setCreateMode={setCreateMode}
                      setUpdatedata={setUpdatedata}
                      onSortLetterTemplates={onSortLetterTemplates}
                      removeTag={removeTag}
                      setLoading={setLoading}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ContractEmail;
