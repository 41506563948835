// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";

// import OptFillQuestion from "./OptFillQuestion";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function headOption(Props) {
  //   const [show, setShow] = useState(false);

  //   useEffect(() => {
  //     // setQuestion(Props.question);
  //     // setFieldKey(Props.field.fieldKey)
  //   }, [Props]);
  var headOption = [
    {
      tabName: Props.headName,
      imageName: Images.clientProfile,
      linkName: Props.headPath,
    },
    { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
    {
      tabName: "Admission",
      imageName: Images.admission,
      linkName: "/admission",
    },
    {
      tabName: "Documents",
      imageName: Images.documents,
      linkName: "/documents",
    },
    { tabName: "Email", imageName: Images.email, linkName: "/client-email" },
    {
      tabName: "Activities",
      imageName: Images.activities,
      linkName: "/activities",
    },
    {
      tabName: "File Notes",
      imageName: Images.documents,
      linkName: "/file-notes",
    },
    {
      tabName: "Accounts",
      imageName: Images.accounts,
      linkName: "/client-account",
    },
    {
      tabName: "Open Case Management",
      imageName: Images.caseManagement,
      linkName: "/Case-management",
    },
    {
      tabName: "Questionnaire",
      imageName: Images.questionnare,
      linkName: "/client-questionnaire",
    },
    // { tabName: "Chat", imageName: Images.supplier, linkName: "/coming-soon" },
    {
      tabName: "Print Case",
      imageName: Images.print,
      linkName: "/print-case",
    },
  ];

  if (headOption.length > 0) {
    for (let ind = 0; ind < headOption.length; ind++) {
      if (headOption[ind].tabName === "Accounts") {
        let g = JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "accounts").status;
        if (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "accounts").status == 0
        ) {
          headOption.splice(ind, 1);
        }
      }
    }
  }

  if (headOption.length > 0) {
    for (let ind = 0; ind < headOption.length; ind++) {
      if (headOption[ind].tabName === "Open Case Management") {
        if (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "case management").status == 0
        ) {
          headOption.splice(ind, 1);
        }
      }
    }
  }

  if (headOption.length > 0) {
    for (let ind = 0; ind < headOption.length; ind++) {
      if (headOption[ind].tabName === "Admission") {
        if (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find((x) => x.role.toLowerCase() == "school management").status == 0
        ) {
          headOption.splice(ind, 1);
        }
      }
    }
  }

  if (headOption.length > 0) {
    for (let ind = 0; ind < headOption.length; ind++) {
      if (headOption[ind].tabName === "Questionnaire") {
        if (
          JSON.parse(
            decodeURIComponent(
              escape(window.atob(localStorage.getItem("userSystemPermissions")))
            )
          ).find(
            (x) =>
              x.role.toLowerCase() == "custom questionnaire" ||
              x.role.toLowerCase() == "custom questionnaires"
          ).status == 0
        ) {
          headOption.splice(ind, 1);
        }
      }
    }
  }

  return headOption;
}

export default headOption;
