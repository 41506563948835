import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MailChimp from "../AccountSetting/MailChamp/MailChamp";

import {
  getMailChimp,
  addMailChimp,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    mailChimpRes: state.documentTypeReducer.mailChimpRes,
    addMailChimpRes: state.documentTypeReducer.addMailChimpRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMailChimp: bindActionCreators(getMailChimp, dispatch),
    onAddMailChimp: bindActionCreators(addMailChimp, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailChimp);
