import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const accountReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ACCOUNT_CREATE_INVOICE_SUCCESS:
      return { ...state, accountInvoiceSuccess: action.payload };
    case types.ACCOUNT_CREATE_INVOICE_FAILURE:
      return { ...state, accountInvoiceError: action.payload };
    case types.ACCOUNT_DAILY_TRANSACTIONS_SUCCESS:
      return { ...state, accountsDailyTransactions: action.payload };
    case types.ACCOUNT_DAILY_TRANSACTIONS_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Daily transactions list is empty!");
        return { ...state, accountsDailyTransactions: [] };
      } else {

      }
      console.log("on error console", action.payload);
    case types.GET_INVOICES_GRAPH_LISTING_SUCCESS:
      return { ...state, accountsInvoicesGraphData: action.payload && action.payload.items && action.payload.items};
    case types.GET_INVOICES_GRAPH_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "No invoices data found for analytics!");
        return { ...state, accountsInvoicesGraphData: [] };
      } else {

      }
      console.log("on error console", action.payload);
    case types.GET_DUE_OVER_INVOICES_LISTING_SUCCESS:
      return { ...state, dueOverInvoices: action.payload, dueOverInvoicesCount: action.payload.totalCount };
    case types.GET_DUE_OVER_INVOICES_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Due invoices list is empty!");
        return { ...state, dueOverInvoices: [], dueOverInvoicesCount: 0 };
      } else {

      }
      console.log("on error console", action.payload);
    case types.GET_ALL_TYPES_INVOICES_SUCCESS:
      return { ...state, allTypesInvoices: action.payload, allTypesInvoicesCount: action.payload.totalCount };
    case types.GET_ALL_TYPES_INVOICES_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Invoices list is empty!");
        return { ...state, allTypesInvoices: [], allTypesInvoicesCount: 0 };
      } else {

      }
      console.log("on error console", action.payload);
    case types.GET_WORK_IN_PROGRESS_INVOICES_LISTING_SUCCESS:
      return { ...state, workInProgressInvoices: action.payload, workInProgressInvoicesCount: action.payload.totalCount };
    case types.GET_WORK_IN_PROGRESS_INVOICES_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Work in progress invoices list is empty!");
        return { ...state, workInProgressInvoices: [], workInProgressInvoicesCount: 0 };
      } else {

      }
      console.log("on error console", action.payload);
    case types.GET_WORK_COMPLETED_INVOICES_LISTING_SUCCESS:
      return { ...state, workCompletedInvoices: action.payload, workCompletedInvoicesCount: action.payload.totalCount };
    case types.GET_WORK_COMPLETED_INVOICES_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Work completed invoices list is empty!");
        return { ...state, workCompletedInvoices: [], workCompletedInvoicesCount: 0 };
      } else {

      }
      console.log("on error console", action.payload);
    case types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_SUCCESS:
      return { ...state, accountsFlowListing: action.payload };
    case types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "list is empty!");
        return { ...state, accountsFlowListing: [] };
      } else {
      }
      console.log("on error console", action.payload);
    default:
      return state;
  }
};
