// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import { apiRefresh } from "../../services/api";

const dateFormate = "DD/MM/YYYY";

function OtherInfoRow(Props) {
  const [fieldValue, setFieldValue] = useState(
    Props.clientInfo.type == 2 || Props.clientInfo.type == 6
      ? moment(Props.clientInfo.value)
      : Props.clientInfo.value
  );
  const [isEdit, setIsEdit] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    // setFieldValue(Props.clientInfo.value);
  }, [Props.clientInfo.value]);

  const updateClientInfo = (values) => {
    let data = {
      columnId: Props.clientInfo.columnId,
      clientId: Props.clientInfo.clientId,
      type: Props.clientInfo.type,
      value: fieldValue,
      id: Props.clientInfo.id,
    };
    setLoading(true);
    const clientInfoOpt = {
      url: `v1/client/ClientOtherInfo`,
    };
    clientInfoOpt.types = [
      "UPDATE_CLIENT_INFO_SUCCES",
      "UPDATE_CLIENT_INFO_FAILURE",
    ];

    apiRefresh
      .put(clientInfoOpt, data)
      .then((res) => {
        setLoading(false);
        setIsEdit(false);
        setShowSaveButton(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: 20, display: "flex", alignItems: "center" }}>
      {Props.clientInfo.type == 2 ? (
        <div
          style={{
            border: 0,
            marginTop: 10,
            width: `calc(100% - 20px)`,
          }}
        >
          <DatePicker
            disabled={!isEdit}
            value={fieldValue}
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            onChange={(date, dateString) => setFieldValue(date)}
          />
        </div>
      ) : Props.clientInfo.type == 6 ? (
        <div
          className="profile-input-border"
          style={{
            border: 0,
            marginTop: 10,
            width: `calc(100% - 20px)`,
          }}
        >
          <DatePicker
            disabled={!isEdit}
            value={fieldValue}
            style={{ width: "100%" }}
            format="MM/YYYY"
            onChange={(date, dateString) => setFieldValue(date)}
          />
        </div>
      ) : Props.clientInfo.type == 7 ? (
        <div
          style={{
            border: 0,
            marginTop: 10,
            width: `calc(100% - 20px)`,
          }}
        >
          <textarea
            disabled={!isEdit}
            value={fieldValue}
            rows={5}
            className="cv-textarea"
            onChange={(e) => setFieldValue(e.target.value)}
          />
        </div>
      ) : Props.clientInfo.type == 8 ? (
        <div
          style={{
            border: 0,
            marginTop: 10,
            width: `calc(100% - 20px)`,
          }}
        >
          <Input
            disabled={!isEdit}
            value={fieldValue}
            type="file"
            className="profile-input"
            onChange={(e) => setFieldValue(e.target.value)}
          />
        </div>
      ) : Props.clientInfo.type != 8 ? (
        <div
          className="profile-input-border"
          style={{
            border: 0,
            width: `calc(100% - 20px)`,
          }}
        >
          <Input
            disabled={!isEdit}
            value={fieldValue}
            className="profile-input"
            onChange={(e) => setFieldValue(e.target.value)}
          />
        </div>
      ) : null}
      {showSaveButton ? (
        <>
          <Button
            type="primary"
            className="cq-primary-btn"
            loading={loading}
            style={{
              marginTop:
                Props.clientInfo.type == 2 || Props.clientInfo.type == 6
                  ? 0
                  : 10,
              marginLeft: 5,
            }}
            onClick={updateClientInfo}
          >
            Save
          </Button>
          <Button
            type="primary"
            className="cq-primary-btn"
            // loading={loading}
            style={{ marginTop: 10, marginLeft: 5 }}
            onClick={() => {
              setIsEdit(false);
              setShowSaveButton(false);
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <div
          style={{
            cursor: "pointer",
            marginLeft: 5,
          }}
          onClick={() => {
            setIsEdit(true);
            setShowSaveButton(true);
          }}
        >
          <img src={Images.editBorderBlue} style={{ width: 15 }} />
        </div>
      )}
    </div>
  );
}

export default OtherInfoRow;
