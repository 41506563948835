import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal } from "antd";
import EmployerEmailHistory from "./PotentialClientHistory/PotentialClientHistory";
// import AddEmployerDocuments from "./EmployerEmailHistory/AddEmployerDocuments";
// import UpdateEmployerDocuments from "./EmployerEmailHistory/UpdateEmployerDocuments";
import CreateEmail from "./CreateEmail/CreateEmail";
import { apiRefresh } from "../../services/api";

const { TabPane } = Tabs;

const PotentialClientEmail = ({
  userDataEmp,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  // APIS START FROM

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  potentialClientInfo,

  onGetSignature,
  onGetDocuments,

  onAddPotentialEmail,
  onGetPClientHistory,
  potentialClientHistory,
  onGetPdf,
  onGetImapForAll,
  imapForAllRes,
  signatureRes,
  onAddEmailDocument,
  documentRes,
  onGetEmployerDocument,
  employerDocumentRes,
  onGetDocumentDownload,
  onGetEmailContent,
  onGetPClientImportSetting,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [handleKey, setHandleKey] = useState("1");

  const [emailContentData, setEmailContentData] = useState("");
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    initialCallApis();
  }, []);

  const initialCallApis = () => {
    onGetPClientImportSetting(potentialClientInfo.id).then((res) => {
      if (res.payload.length > 0 && res.payload[0].isAutoImportEmail) {
        var emailQueueOpt = {
          url: `v1/potentialclientemail/ImportEmailQueue`,
        };

        emailQueueOpt.types = ["EMAIL_QUEUE_SUCCESS", "EMAIL_QUEUE_FAILURE"];
        apiRefresh.post(emailQueueOpt, {
          clientId: potentialClientInfo.id,
        });
      }
    });
    onGetEmailContent &&
      onGetEmailContent().then((res) => {
        let _emailTemp = res && res.payload && res.payload.emailTemplates;
        setResultList(_emailTemp);
        for (let ind = 0; ind < _emailTemp.length; ind++) {
          if (_emailTemp[ind].emailType === "Notifications") {
            setEmailContentData(
              '<p><p style="color: #000">Dear ' +
                potentialClientInfo.fullName +
                "</p>" +
                _emailTemp[ind].emailContent
            );
          } else {
            //do nothing
          }
        }
      });
  };

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callback = (key) => {
    // console.log(key);

    setHandleKey(key);
  };

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div className="ts-container-Pclient width-100">
          <div className="bg-white employer-manag-tabs">
            <p style={{ marginBottom: "8px" }}>
              {potentialClientInfo && potentialClientInfo.firstName
                ? `Name: ${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`
                : `Add a New Potential Client`}
            </p>
            <Tabs
              activeKey={handleKey}
              type="card"
              size={"small"}
              className="mar-r employer-doc-tab"
              onChange={(key) => callback(key)}
            >
              <TabPane tab="HISTORY" key="1">
                <div
                  style={{
                    border: "2px solid #c9c9ca",
                    padding: "20px",
                    backgroundColor: "#f0f2f5b8",
                  }}
                >
                  <EmployerEmailHistory
                    resultList={resultList}
                    emailContentData={emailContentData}
                    potentialClientInfo={potentialClientInfo}
                    showModal={showModal}
                    onGetEmployerHistory={onGetEmployerHistory}
                    employerHistoryRes={employerHistoryRes}
                    onGetAutoEmailImport={onGetAutoEmailImport}
                    emailImportRes={emailImportRes}
                    onAddAutoEmailImport={onAddAutoEmailImport}
                    onGetPClientHistory={onGetPClientHistory}
                    potentialClientHistory={potentialClientHistory}
                    onGetLetterTemplates={onGetLetterTemplates}
                    LetterTemplatesRes={LetterTemplatesRes}
                    onSendEmailLink={onSendEmailLink}
                    onGetImapForAll={onGetImapForAll}
                    imapForAllRes={imapForAllRes}
                    onGetSignature={onGetSignature}
                    signatureRes={signatureRes}
                    onGetDocuments={onGetDocuments}
                    documentRes={documentRes}
                    // onGetEmployerDocument={onGetEmployerDocument}
                    onGetPdf={onGetPdf}
                    onAddEmailDocument={onAddEmailDocument}
                    onGetEmployerDocument={onGetEmployerDocument}
                    employerDocumentRes={employerDocumentRes}
                    onGetDocumentDownload={onGetDocumentDownload}
                    onAddPotentialEmail={onAddPotentialEmail}
                    setHandleKey={setHandleKey}
                    initialCallApis={initialCallApis}
                  />
                </div>
              </TabPane>
              <TabPane tab="CREATE" key="2">
                {handleKey === "1" ? null : (
                  <div
                    style={{
                      border: "2px solid #c9c9ca",
                      padding: "20px",
                      backgroundColor: "#f0f2f5b8",
                    }}
                  >
                    <CreateEmail
                      resultList={resultList}
                      emailContentData={emailContentData}
                      onSendEmailLink={onSendEmailLink}
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      onAddEmployerEmail={onAddEmployerEmail}
                      potentialClientInfo={potentialClientInfo}
                      onGetSignature={onGetSignature}
                      onGetDocuments={onGetDocuments}
                      onAddPotentialEmail={onAddPotentialEmail}
                      setHandleKey={setHandleKey}
                      onGetPClientHistory={onGetPClientHistory}
                      onGetPdf={onGetPdf}
                      signatureRes={signatureRes}
                      onAddEmailDocument={onAddEmailDocument}
                      documentRes={documentRes}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      onGetDocumentDownload={onGetDocumentDownload}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      {/* {isModalVisible && (
        <div className="reminder-model">
          <Modal
            className="reminder-model-main"
            title={
              (modalType === "add-documents" && "ADD DOCUMENTS") ||
              (modalType === "update-documents" && "UPDATE DOCUMENTS")
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            // Header={true}
            footer={false}
          >
            {modalType === "add-documents" && (
              <AddEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
              />
            )}

            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
              />
            )}
          </Modal>
        </div>
      )} */}
    </Fragment>
  );
};

export default PotentialClientEmail;
