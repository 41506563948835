import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PotentialClientReport from "../Reports/PotentialClientsReport";

import {
  getTeamMember,
  pSaleHistoryReport,
  pProcessingPersonReport,
  pClientSourceReport,
  pFileNotesReport,
  activeCasesReport,
  visaExpiringReport,
  clientEmployersReport,
  potentialClientStatusReport,
  pcFileNotes,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    teamMembers: state.teamMemberReducer.teamMembers,
    pSaleHistoryRes: state.reportReducer.pSaleHistoryRes,
    pClientSourceReportRes: state.reportReducer.pClientSourceReportRes,
    pcProcessingPersonReportRes:
      state.reportReducer.pcProcessingPersonReportRes,
    taskReportRes: state.reportReducer.taskReportRes,
    birthdayReportRes: state.reportReducer.birthdayReportRes,
    pcFileNotesReportRes: state.reportReducer.pcFileNotesReportRes,
    activeCasesReportRes: state.reportReducer.activeCasesReportRes,
    visaExpiryReportRes: state.reportReducer.visaExpiryReportRes,
    clientEmployerReportRes: state.reportReducer.clientEmployerReportRes,
    pcStatusReportRes: state.reportReducer.pcStatusReportRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onPSaleHistoryReport: bindActionCreators(pSaleHistoryReport, dispatch),
    onPcProcessingPersonReport: bindActionCreators(
      pProcessingPersonReport,
      dispatch
    ),
    onPClientSourceReport: bindActionCreators(pClientSourceReport, dispatch),
    onPcFileNotesReport: bindActionCreators(pFileNotesReport, dispatch),
    onActiveCasesReport: bindActionCreators(activeCasesReport, dispatch),
    onVisaExpiryReport: bindActionCreators(visaExpiringReport, dispatch),
    onClientEmployerReport: bindActionCreators(clientEmployersReport, dispatch),
    onPotentialClientStatusReport: bindActionCreators(
      potentialClientStatusReport,
      dispatch
    ),
    pcFileNotes: bindActionCreators(pcFileNotes, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PotentialClientReport);
