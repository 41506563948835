import { redirectUrl } from "../../config";
import { api } from "../../services/api";
import * as types from "../Constants";
import {
  ADD_GMAIL_ACCOUNT_FAILURE,
  ADD_GMAIL_ACCOUNT_SUCCESS,
  GET_GMAIL_FAILURE,
  GET_GMAIL_LOGIN_FAILURE,
  GET_GMAIL_LOGIN_SUCCESS,
  GET_GMAIL_SUCCESS,
} from "../Constants";

// export const DropboxAccountLogin = data => {
//   const options = {
//     url: `v1/dropbox/LoginLink`
//   };
//   options.types = [types.DROPBOX_LOGIN_SUCCESS, types.DROPBOX_LOGIN_FAILURE];

//   return api.post(options, data);
// };

export const getOutlookMail = () => {
  const options = {
    url: `v1/OutlookMail`,
  };
  options.types = [
    types.GET_OUTLOOK_MAIL_SUCCESS,
    types.GET_OUTLOOK_MAIL_FAILURE,
  ];

  return api.get(options);
};
export const OutlookLogin = (uri) => {
  const options = {
    url: `v1/OutlookMail/Connect?RedirectUri=${uri}`,
  };
  options.types = [
    types.GET_OUTLOOK_LOGIN_SUCCESS,
    types.GET_OUTLOOK_LOGIN_FAILURE,
  ];

  return api.get(options);
};

export const addOutlookAccount = ({ code, redirectUrl }) => {
  const options = {
    url: `v1/OutlookMail/Code?code=${code}&redirect_uri=${redirectUrl}`,
  };
  options.types = [
    types.ADD_OUTLOOK_ACCOUNT_SUCCESS,
    types.ADD_OUTLOOK_ACCOUNT_FAILURE,
  ];

  return api.get(options);
};

export const removeOutlookAccount = (data) => {
  const options = {
    url: "v1/OutlookMail",
  };
  options.types = [
    types.DELETE_OUTLOOK_ACCOUNT_SUCCESS,
    types.DELETE_OUTLOOK_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};

//GmailIntegration

export const getGmailMail = () => {
  const options = {
    url: `v1/gmail?UserId=${localStorage.getItem("userId")}`,
  };
  options.types = [types.GET_GMAIL_SUCCESS, types.GET_GMAIL_FAILURE];

  return api.get(options);
};
export const gmailLogin = (data) => {
  const options = {
    url: "v1/gmail/TokenUrl",
  };
  options.types = [
    types.GET_GMAIL_LOGIN_SUCCESS,
    types.GET_GMAIL_LOGIN_FAILURE,
  ];

  return api.post(options, data);
};

export const addGmailAccount = (data) => {
  const options = {
    url: "v1/gmail/CreateToken",
  };
  options.types = [
    types.ADD_GMAIL_ACCOUNT_SUCCESS,
    types.ADD_GMAIL_ACCOUNT_FAILURE,
  ];

  return api.post(options, data);
};

export const removeGmailAccount = (data) => {
  const options = {
    url: "v1/gmail",
  };
  options.types = [
    types.DELETE_OUTLOOK_ACCOUNT_SUCCESS,
    types.DELETE_OUTLOOK_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};

// Hubspot Integration

export const getHubspotToken = () => {
  const options = {
    url: `v1/hubspot/Token`,
  };
  options.types = [
    types.GET_HUBSPOT_TOKEN_SUCCESS,
    types.GET_HUBSPOT_TOKEN_FAILURE,
  ];

  return api.get(options);
};

export const hubspotLogin = (data) => {
  const options = {
    url: `v1/hubspot/Connect?RedirectUri=${data.uri}`,
  };
  options.types = [
    types.HUBSPOT_CONNECT_SUCCESS,
    types.HUBSPOT_CONNECT_FAILURE,
  ];

  return api.get(options, data);
};

export const addHubspotAccount = ({ code, redirectUrl }) => {
  const options = {
    url: `v1/hubspot/Code?code=${code}&redirect_uri=${redirectUrl}`,
  };
  options.types = [
    types.ADD_HUBSPOT_ACCOUNT_SUCCESS,
    types.ADD_HUBSPOT_ACCOUNT_FAILURE,
  ];

  return api.get(options);
};

export const removeHubspotAccount = (data) => {
  const options = {
    url: "v1/hubspot/Token",
  };
  options.types = [
    types.DELETE_HUBSPOT_ACCOUNT_SUCCESS,
    types.DELETE_HUBSPOT_ACCOUNT_FAILURE,
  ];

  return api.delete(options, data);
};
