import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  message,
  Col,
  InputNumber,
  Spin,
  Collapse,
  Modal,
} from "antd";
import { saveAs } from "file-saver";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addInvoicePayment,
  addNewTemplate,
  addTemplateToClientAppliedTemplateListing,
  deleteInvoice,
  editTemplateForClientAppliedTemplateListing,
  getAllBanksListing,
  getAllTaxesListing,
  getClientProfile,
  getClientTemplateListing,
  getCompany,
  getInvoiceInfo,
  getInvoicePDFHtmlBlob,
  getInvoiceReceiptPDFHtmlBlob,
  getInvoiceStatuses,
  addEmailDocument,
  getPDF,
  getPDFInvoicePayment,
  getTemplateListing,
  setEmailTemplatePayment,
  setInvoicesDataNull,
  setTemplateStatus,
  getBranch,
} from "../store/Actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import StageClientTemplate from "./StageClientTemplate";
import SendEmailTemplate from "./SendEmailTemplate";
import moment from "moment";
import SendEmailTemplatePayment from "./SendEmailTemplatePayment";
const { Option } = Select;
const { Panel } = Collapse;

class AccordianClientProfileTemplate extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userId = localStorage.getItem("userId");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    this.state = {
      generateDepositInvoiceCount: 0,
      userId: userId,
      userEmail: userEmail,
      branchId: selectedBranchId,
      clientProfileId: clientprofileid,
      name: "",
      stages: [],
      description: "",
      total: 0,
      id: 0,
      deposit: undefined,
      received: 0,
      depositInvoices: [],
      visibleDeleteModalInvoice: false,
      templateStatus: "Inactive",
      visibleSendingReceiptModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllTaxesListing();
    this.props.getClientProfile(this.state.clientProfileId);
    this.props.getAllBanksListing();
    this.props.getBranch();
    let _branchData = this.props.branchData;
    let _branchName;
    if (_branchData && _branchData.length > 0) {
      for (
        let branchIndex = 0;
        branchIndex < _branchData.length;
        branchIndex++
      ) {
        if (_branchData[branchIndex].id === this.state.branchId) {
          _branchName = _branchData[branchIndex].name;
        }
        this.setState({ branchName: _branchName });
      }
    }

    let _data = this.props && this.props.data;

    let _generateDepositInvoiceCount = 0;
    let _stageList = [];
    if (_data && _data.stages && _data.stages.length > 0) {
      for (let _indicator = 0; _indicator < _data.stages.length; _indicator++) {
        if (
          _data &&
          _data.stages &&
          _data.stages[_indicator].invoice &&
          _data.stages[_indicator].invoice.length > 0
        ) {
          _generateDepositInvoiceCount = _generateDepositInvoiceCount + 1;

          for (
            let _indicatorTemp = 0;
            _indicatorTemp < _data.stages[_indicator].invoice.length;
            _indicatorTemp++
          ) {
            let _received = 0;
            if (
              _data &&
              _data.stages &&
              _data.stages[_indicator].invoice &&
              _data.stages[_indicator].invoice[_indicatorTemp].payment &&
              _data.stages[_indicator].invoice[_indicatorTemp].payment.length >
                0
            ) {
              for (
                let _indicatorTempCal = 0;
                _indicatorTempCal <
                _data.stages[_indicator].invoice[_indicatorTemp].payment.length;
                _indicatorTempCal++
              ) {
                _received =
                  _received +
                  _data.stages[_indicator].invoice[_indicatorTemp].payment[
                    _indicatorTempCal
                  ].amount;
              }
            }
            _stageList.push({
              stageMoney: Math.round((_received + Number.EPSILON) * 100) / 100,
            });
          }
        } else {
          _stageList.push({ stageMoney: 0 });
        }
      }
    }

    this.setState(
      {
        generateDepositInvoiceCount: _generateDepositInvoiceCount,
        name: _data.name,
        id: _data.id,
        description: _data.description,
        stageList: _stageList,
        stages: _data.stages,
        total: _data.total,
        depositInvoices: _data.depositeInvoice,
      },
      () => {}
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _data = nextProps && nextProps.data;

    let _generateDepositInvoiceCount = 0;
    let _stageList = [];
    if (_data && _data.stages && _data.stages.length > 0) {
      for (let _indicator = 0; _indicator < _data.stages.length; _indicator++) {
        if (
          _data &&
          _data.stages &&
          _data.stages[_indicator].invoice &&
          _data.stages[_indicator].invoice.length > 0
        ) {
          _generateDepositInvoiceCount = _generateDepositInvoiceCount + 1;

          for (
            let _indicatorTemp = 0;
            _indicatorTemp < _data.stages[_indicator].invoice.length;
            _indicatorTemp++
          ) {
            let _received = 0;
            if (
              _data &&
              _data.stages &&
              _data.stages[_indicator].invoice &&
              _data.stages[_indicator].invoice[_indicatorTemp].payment &&
              _data.stages[_indicator].invoice[_indicatorTemp].payment.length >
                0
            ) {
              for (
                let _indicatorTempCal = 0;
                _indicatorTempCal <
                _data.stages[_indicator].invoice[_indicatorTemp].payment.length;
                _indicatorTempCal++
              ) {
                _received =
                  _received +
                  _data.stages[_indicator].invoice[_indicatorTemp].payment[
                    _indicatorTempCal
                  ].amount;
              }
            }
            _stageList.push({
              stageMoney: Math.round((_received + Number.EPSILON) * 100) / 100,
            });
          }
        } else {
          _stageList.push({ stageMoney: 0 });
        }
      }
    }
    this.setState(
      {
        generateDepositInvoiceCount: _generateDepositInvoiceCount,
        name: _data.name,
        id: _data.id,
        description: _data.description,
        stageList: _stageList,
        stages: _data.stages,
        total: _data.total,
        depositInvoices: _data.depositeInvoice,
      },
      () => {}
    );
  }

  getDepositInvoiceTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          <div className="ca-gray-cont ca-gray-padding-payment-history">
            <Table
              style={{ overflowX: "auto" }}
              dataSource={
                this.state.depositInvoices
                  ? this.state.depositInvoices.sort((a, b) =>
                      a.modifiedDate.localeCompare(b.modifiedDate)
                    )
                  : null
              }
              columns={this.depositInvoicesColumns}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    );
  };

  downloadDepositInvoice = (id, invoiceNo) => {
    let _data = {
      id: id,
      paymentId: 0,
    };
    this.props && this.props.setLoadingTrue();
    this.props
      .getPDFInvoicePayment(_data)
      .then((response) => {
        let blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        this.props && this.props.setLoadingFalse();
        saveAs(blob, `Invoice-${invoiceNo}.pdf`);
      })
      .catch((e) => {
        this.props && this.props.setLoadingFalse();
        message.error("Unable to download, please try again!");
      });
  };

  depositInvoicesColumns = [
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.total}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.downloadDepositInvoice(record.id, record.invoiceNo);
            }}
          >
            {"DOWNLOAD"}{" "}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ showAddTemplateInvoice: true }, () => {
                    this.props &&
                      this.props.getShowInvoice(
                        this.state.showAddTemplateInvoice,
                        this.props && this.props.data,
                        record.id
                      );
                  });
                })
                .catch(() => {});
            }}
          >
            {"DETAILS"}{" "}
          </Button>
          <Button
            size="small"
            type="primary"
            className="button-blue"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            {"UNDO"}
          </Button>
        </span>
      ),
    },
  ];

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModalInvoice: true,
      index: id,
    });
  };

  handleCancelDeleteModalInvoice = () => {
    this.setState({ visibleDeleteModalInvoice: false });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalInvoice}
          onCancel={this.handleCancelDeleteModalInvoice}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete deposit invoice?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModalInvoice();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleDeleteInvoice(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoice = (id) => {
    this.props
      .deleteInvoice(id)
      .then(() => {
        message.success("Deposit Invoice is deleted successfully!");
        this.setState({ visibleDeleteModalInvoice: false }, () => {
          this.props && this.props.setLoadingTrue();
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        });
      })
      .catch(() => {});
  };

  onClickGenerateNewDepositInvoice = () => {
    this.props && this.props.setInvoicesDataNull();
    this.setState({ showAddTemplateInvoice: true }, () => {
      this.props &&
        this.props.getShowInvoice(
          this.state.showAddTemplateInvoice,
          this.props && this.props.data,
          0
        );
    });
  };

  onClickSendReceiptDepositInvoice = () => {
    this.props
      .getInvoiceInfo(
        this.state.depositInvoices && this.state.depositInvoices[0].id
      )
      .then((_res) => {
        let _invoiceInfo = _res && _res.payload;

        this.setState({ invoiceInfo: _invoiceInfo }, () => {
          this.props
            .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
            .then((res) => {
              let _statuses =
                res && res.payload && res.payload.items && res.payload.items;
              this.setState({ statuses: _statuses }, () => {
                let _tempInvoiceInfo = this.state.invoiceInfo;
                _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
                _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
                _tempInvoiceInfo.dateStr = moment(
                  _tempInvoiceInfo.dateStr
                ).format("DD/MM/YYYY");
                _tempInvoiceInfo.dueDate = moment(
                  _tempInvoiceInfo.dueDate
                ).format("DD/MM/YYYY");
                this.setState(
                  { invoiceInfo: _tempInvoiceInfo, selectedPaymentId: 0 },
                  () => {
                    let _emailTemplatePayload = {
                      templateName: "recipt",
                      paymentId: 0,
                      invoiceId:
                        this.state.depositInvoices &&
                        this.state.depositInvoices[0].id,
                      parameters: [
                        {
                          key: "ClientId",
                          value: this.state.clientProfileId,
                        },
                        {
                          key: "UserId",
                          value: this.state.userId,
                        },
                        {
                          key: "CurrencyId",
                          value: this.state.invoiceInfo.currencyId
                            ? this.state.invoiceInfo.currencyId.toString()
                            : "106",
                        },
                        {
                          key: "Amount",
                          value: this.state.invoiceInfo.total.toString(),
                        },
                        {
                          key: "InvoiceNumber",
                          value: this.state.invoiceInfo.invoiceNo,
                        },
                      ],
                    };
                    this.props
                      .setEmailTemplatePayment(_emailTemplatePayload)
                      .then(() => {
                        let _setEmailData = this.props.setEmailDataPayment;

                        this.setState({ setEmailData: _setEmailData }, () => {
                          let _data = {
                            invoiceId:
                              this.state &&
                              this.state.invoiceInfo &&
                              this.state.invoiceInfo.id &&
                              this.state.invoiceInfo.id,
                            paymentId: this.state.selectedPaymentId,
                          };
                          this.props
                            .getInvoiceReceiptPDFHtmlBlob(_data)
                            .then((res) => {
                              let formData = new FormData();

                              let binaryString = window.atob(res.payload.url);
                              let binaryLen = binaryString.length;
                              let bytes = new Uint8Array(binaryLen);
                              for (let i = 0; i < binaryLen; i++) {
                                let ascii = binaryString.charCodeAt(i);
                                bytes[i] = ascii;
                              }
                              let _blob = new Blob([bytes], {
                                type: "application/pdf",
                              });
                              let metadata = {
                                type: "application/pdf",
                              };
                              let file = new File(
                                [_blob],
                                res.payload.name,
                                metadata
                              );

                              formData.append(
                                "BranchId",
                                localStorage.getItem("selectedBranchId")
                              );

                              formData.append("files", file);
                              this.props
                                .onAddEmailDocument(formData)
                                .then((res) => {
                                  let _attData = res && res.payload;
                                  let attDataArray = [
                                    {
                                      name: _attData[0].fileName,
                                      type: _attData[0].contentType,
                                      url: _attData[0].fileUrl,
                                    },
                                  ];
                                  this.props && this.props.setLoadingFalse();

                                  this.setState({
                                    attDataArray: attDataArray,

                                    visibleSendingReceiptModal: true,
                                  });
                                })
                                .catch(() => {
                                  this.props && this.props.setLoadingFalse();
                                });
                            })
                            .catch(() => {
                              this.props && this.props.setLoadingFalse();
                            });
                        });
                      })
                      .catch(() => {
                        this.props && this.props.setLoadingFalse();
                      });
                  }
                );
              });
            });
        });
      });
  };

  getDepositInvoiceSection = () => {
    return (
      <Row
        style={{
          borderTop: "1px solid rgb(214, 208, 208)",
          paddingBottom: "15px",
        }}
      >
        <Col xs={24}>
          <Row>
            <Col xs={23} offset={1}>
              <h4 style={{ paddingTop: "18px" }}>
                {this.state.depositInvoices.length > 0
                  ? "Deposit Invoices"
                  : null}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              {this.state.depositInvoices.length > 0
                ? this.getDepositInvoiceTable()
                : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: "15px", paddingLeft: "13px" }}>
              {this.state.depositInvoices &&
              this.state.depositInvoices.length === 0 ? (
                this.state.generateDepositInvoiceCount > 0 ? null : (
                  <Button
                    style={{ marginTop: "6px" }}
                    type="primary"
                    size={"small"}
                    className="button-blue"
                    onClick={() => {
                      this.onClickGenerateNewDepositInvoice();
                    }}
                  >
                    GENERATE DEPOSIT INVOICE
                  </Button>
                )
              ) : (
                <div style={{ display: "block" }}>
                  {this.state.depositInvoices &&
                  this.state.depositInvoices[0] &&
                  this.state.depositInvoices[0].payment &&
                  this.state.depositInvoices[0].payment.length === 0 ? (
                    <div style={{ display: "block" }}>
                      <p>
                        {`Deposit ${this.state.depositInvoices &&
                          this.state.depositInvoices[0] &&
                          this.state.depositInvoices[0].total}`}
                      </p>
                      <Button
                        style={{ marginRight: "3px" }}
                        type="primary"
                        size={"small"}
                        className="button-blue"
                        onClick={() => {
                          this.onClickDeposit();
                        }}
                      >
                        DEPOSIT
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: "flex", marginTop: "4px" }}>
                      <Button
                        style={{ marginRight: "3px" }}
                        type="primary"
                        size={"small"}
                        className="button-blue"
                        onClick={() => {
                          this.onClickSendReceiptDepositInvoice();
                        }}
                      >
                        SEND RECEIPT
                      </Button>
                      <Button
                        type="primary"
                        size={"small"}
                        className="button-blue"
                        onClick={() =>
                          this.downloadDepositInvoice(
                            this.state.depositInvoices &&
                              this.state.depositInvoices[0].id,
                            this.state.depositInvoices &&
                              this.state.depositInvoices[0].invoiceNo
                          )
                        }
                      >
                        DOWNLOAD
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Col>
            <Col
              xs={7}
              offset={5}
              style={{ display: "block", marginTop: "15px" }}
            >
              <p style={{ wordWrap: "break-word", fontWeight: "bold" }}>
                {this.state.depositInvoices &&
                this.state.depositInvoices[0] &&
                this.state.depositInvoices[0].payment &&
                this.state.depositInvoices[0].payment.length > 0
                  ? `Total Deposited Amount: ${this.state.depositInvoices &&
                      this.state.depositInvoices[0] &&
                      this.state.depositInvoices[0].total}`
                  : `Total: ${this.state.total}`}
              </p>
              <div style={{ display: "block" }}>
                {this.state &&
                  this.state.stageList &&
                  this.state.stageList.map((data, index) => {
                    return (
                      <p style={{ wordWrap: "break-word", fontWeight: "bold" }}>
                        {`Stage ${index + 1} Received: ${data.stageMoney}`}
                      </p>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  onClickDeposit = () => {
    const now = new Date();
    let _data = {
      invoiceId:
        this.state.depositInvoices &&
        this.state.depositInvoices[0] &&
        this.state.depositInvoices[0].id,
      amount:
        this.state.depositInvoices &&
        this.state.depositInvoices[0] &&
        this.state.depositInvoices[0].total,
      receivedDate: now.toISOString(),
    };
    this.props.addInvoicePayment(_data).then(() => {
      message.success("Payment is added successfully!");
      this.props && this.props.setLoadingTrue();
      this.props
        .getClientTemplateListing()
        .then(() => {
          this.props && this.props.setLoadingFalse();
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
    });
  };

  handleCancelSendingReceiptModal = (e) => {
    this.setState({
      visibleSendingReceiptModal: false,
    });
  };

  setTemplateStatus = (id, status) => {
    this.props && this.props.setLoadingTrue();
    if (status === "Inactive") {
      let _data = {
        id: id,
        status: "Active",
      };
      this.props
        .setTemplateStatus(_data)
        .then(() => {
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
    } else {
      let _data = {
        id: id,
        status: "Inactive",
      };
      this.props
        .setTemplateStatus(_data)
        .then(() => {
          this.props && this.props.setLoadingTrue();
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
    }
  };

  render() {
    return (
      <Fragment>
        <Collapse
          defaultActiveKey={this.props && this.props.activeKeysList}
          key={this.props.index}
          expandIconPosition={"right"}
          className="balance-accordian template"
        >
          <Panel
            header={
              <div style={{ display: "block" }}>
                <Row style={{ display: "flex", marginLeft: "9px" }}>
                  <Col xs={24}>
                    <Row>
                      <Col xs={20}>
                        <h5
                          style={{
                            color: "black",
                            margin: "-4px",
                            wordBreak: "break-word",
                          }}
                        >
                          {this.state.name}
                        </h5>
                      </Col>
                      <Col xs={3} className="tab-col-css">
                        <div
                          onClick={() =>
                            this.setTemplateStatus(
                              this.props &&
                                this.props.data &&
                                this.props.data.id,
                              this.props &&
                                this.props.data &&
                                this.props.data.status
                            )
                          }
                          className="invoice-status-btn"
                          style={{
                            backgroundColor:
                              this.props &&
                              this.props.data &&
                              this.props.data.status === "Active"
                                ? "#5cb85c"
                                : this.props &&
                                  this.props.data &&
                                  this.props.data.status === "Inactive"
                                ? "#d9534f"
                                : "transparent",
                            marginTop: "-5px",
                            marginLeft: "13px",
                            cursor: "pointer",
                          }}
                        >
                          <span className="invoice-status-text">
                            {this.props &&
                              this.props.data &&
                              this.props.data.status}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <p
                      className="balance-client-des"
                      style={{ wordBreak: "break-word" }}
                    >
                      {this.state.description === ""
                        ? `-NA`
                        : `-${this.state.description}`}
                    </p>
                  </Col>
                </Row>
              </div>
            }
            key={this.state.id}
          >
            {this.state &&
              this.state.stages &&
              this.state.stages.map((data, index) => {
                return (
                  <div key={data.id}>
                    <StageClientTemplate
                      data={data}
                      index={index}
                      setLoadingTrue={this.props && this.props.setLoadingTrue}
                      setLoadingFalse={this.props && this.props.setLoadingFalse}
                      getShowInvoice={this.props && this.props.getShowInvoice}
                    />
                  </div>
                );
              })}
            {this.getDepositInvoiceSection()}
            {this.getModalDelete()}
            {this.state.visibleSendingReceiptModal ? (
              <SendEmailTemplatePayment
                clientId={this.state.clientProfileId}
                attDataArray={this.state.attDataArray}
                selectedPaymentId={this.state.selectedPaymentId}
                state={this.state}
                setEmailData={this.state.setEmailData}
                userEmail={this.state.userEmail}
                branchName={this.state.branchName}
                clientProfileData={this.props && this.props.clientProfileData}
                handleCancelSendingReceiptModal={
                  this.handleCancelSendingReceiptModal
                }
                setLoadingTrue={this.props.setLoadingTrue}
                setLoadingFalse={this.props.setLoadingFalse}
                invoiceInfo={this.state.invoiceInfo}
                statuses={this.state.statuses}
                visibleSendingReceiptModal={
                  this.state.visibleSendingReceiptModal
                }
              />
            ) : null}
          </Panel>
        </Collapse>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
    setEmailDataPayment:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.setEmailData &&
      state.invoicesReducer.setEmailData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  getBranch: bindActionCreators(getBranch, dispatch),
  getClientProfile: bindActionCreators(getClientProfile, dispatch),
  setEmailTemplatePayment: bindActionCreators(
    setEmailTemplatePayment,
    dispatch
  ),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  deleteInvoice: bindActionCreators(deleteInvoice, dispatch),
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  setInvoicesDataNull: bindActionCreators(setInvoicesDataNull, dispatch),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  setTemplateStatus: bindActionCreators(setTemplateStatus, dispatch),
  getInvoicePDFHtmlBlob: bindActionCreators(getInvoicePDFHtmlBlob, dispatch),
  getInvoiceReceiptPDFHtmlBlob: bindActionCreators(
    getInvoiceReceiptPDFHtmlBlob,
    dispatch
  ),
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  editTemplateForClientAppliedTemplateListing: bindActionCreators(
    editTemplateForClientAppliedTemplateListing,
    dispatch
  ),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
});

AccordianClientProfileTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccordianClientProfileTemplate);
export default withRouter(AccordianClientProfileTemplate);
