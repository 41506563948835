import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, message, Radio, Spin } from "antd";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { apiRefresh } from "../../services/api";

const CustomMessage = ({}) => {
  const [letterString, setLetterString] = useState("");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    let data = {
      message: letterString,
    };

    var updCustomMessageOpt = {
      url: `v1/branch/QuestionnaireMessageSetting`,
    };

    updCustomMessageOpt.types = [
      "PUT_CUSTOM_MESSAGE_SUCCESS",
      "PUT_CUSTOM_MESSAGE_FAILURE",
    ];
    setLoading(true);

    apiRefresh
      .put(updCustomMessageOpt, data)
      .then((res) => {
        setLoading(false);
        message.success("Updated!");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  useEffect(() => {
    var getCustomMessageOpt = {
      url: `v1/branch/QuestionnaireMessageSetting`,
    };

    getCustomMessageOpt.types = [
      "GET_CUSTOM_MESSAGE_SUCCESS",
      "GET_CUSTOM_MESSAGE_FAILURE",
    ];
    setLoading(true);

    apiRefresh
      .get(getCustomMessageOpt)
      .then((res) => {
        setLoading(false);
        setLetterString(res.message);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <Form
          className="width-100 border-box padding-20"
          name="main"
          onFinish={onFinish}
          form={form}
        >
          {/* <div className="width-100 d-flex align-center letter-font letter-spacing">
          <div className="w-20 ">
            <p>Show Header</p>
          </div>
          <div className="w-80">
            <Form.Item name="header">
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div> */}
          <Form.Item className="">
            <div className="width-100 d-flex align-center letter-font letter-spacing">
              <div className="w-20">
                <p>Message</p>
              </div>
              <div className="w-80 letter-froala">
                <FroalaEditorCom
                  setLoading={(value) => setLoading(value)}
                  model={letterString}
                  onModelChange={(value) => setLetterString(value)}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="form-btn button-blue"
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
            {/* <div className="margin-btns">
            <Button className="form-btn button-blue" type="primary">
              PREVIEW
            </Button>
          </div> */}
          </Form.Item>
          <Form.Item></Form.Item>
        </Form>
      </Spin>
    </Fragment>
  );
};
export default CustomMessage;
