import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Students from "../Supervisor/Students/Students";

import {
  getBatchesAccounts,
  uploadBatchAccounts,
  addBatchAccount,
  getImpersonInfo,
  addNotificationToken,
  removeStudentAccount,
  getSignature,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    students: state.supervisorReducer.batchStudents,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllBatchStudents: bindActionCreators(getBatchesAccounts, dispatch),
    onUploadBatchAccounts: bindActionCreators(uploadBatchAccounts, dispatch),
    onAddBatchAccount: bindActionCreators(addBatchAccount, dispatch),
    onGetImpersonInfo: bindActionCreators(getImpersonInfo, dispatch),
    onNotificationAdd: bindActionCreators(addNotificationToken, dispatch),
    onRemoveStudentAccount: bindActionCreators(removeStudentAccount, dispatch),
    onGetSignature: bindActionCreators(getSignature, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Students);
