import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getTeamMemberRequest: [],
  getTeamMemberSuccess: ["success"],
  getTeamMemberFailure: ["error"],

  addTeamMemberRequest: ["data"],
  addTeamMemberSuccess: ["success"],
  addTeamMemberFailure: ["error"]
});

export const SusTeamMemberTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  teamMemberData: null,
  fetching: false,
  error: null,
  addFetching: false,
  addError: null,
  addSuccess: null
});

/* ------------- Reducers ------------- */

// we're attempting to get sus team member
export const request = state => state.merge({ fetching: true });

// we've successfully get sus team member
export const success = (state, { success }) =>
  state.merge({ fetching: false, teamMemberData: success });

// we've had a problem in geting sus team member
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

// we're attempting to add sus team member
export const addrequest = state => state.merge({ addFetching: true });

// we've successfully added sus team member
export const addsuccess = (state, { addSuccess }) =>
  state.merge({ addFetching: false, addSuccess: addSuccess });

// we've had a problem in adding team member
export const addfailure = (state, { addError }) =>
  state.merge({ addFetching: false, addError });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TEAM_MEMBER_REQUEST]: request,
  [Types.GET_TEAM_MEMBER_SUCCESS]: success,
  [Types.GET_TEAM_MEMBER_FAILURE]: failure,

  [Types.ADD_TEAM_MEMBER_REQUEST]: addrequest,
  [Types.ADD_TEAM_MEMBER_SUCCESS]: addsuccess,
  [Types.ADD_TEAM_MEMBER_FAILURE]: addfailure
});
