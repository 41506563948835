import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "./../../Components/Header/HeaderBar";
import Sidebar from "./../../Components/SideBar";
import ClientTagForm from "./ClientTagForm";
import { Images } from "./../../Themes";
import { Table, Modal, Spin, message, Button, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";

const ClientTags = ({
  onGetClientTag,
  clientTagRes,

  onAddClientTag,
  addClientTagRes,

  onRemoveClientTag,
  removeClientTagRes,

  onUpdetaClientTag,
  updateClientTagRes,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});
  const state = useLocation().state;
  console.log("params========", state, history);

  useEffect(() => {
    setLoading(true);
    onGetClientTag()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetClientTag]);

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveClientTag(remove)
      .then(() => {
        onGetClientTag().then(() => {
          setLoading(false);
          message.success("Successfully Deleted!");
        });
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.payload.response &&
          err.payload.response.data.detail == "please remove assigned tag first"
        ) {
          message.error("Please remove assigned tag first!");
        } else {
          message.error("Failed!");
        }
      });
  };

  const showModal = (value) => {
    setIsModalVisible(true);
    setCreateMode(value);
    setUpdatedata(value);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined onClick={() => showModal(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.clientTags} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  Client Tags
                </span>
                <Tooltip
                  placement="topLeft"
                  title={`Create specific client tags to assign to clients. To help you and your team at first glance get a quick idea of what type of client it is.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="client-section">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="add-tag-btn">
                    <>
                      <img
                        src={Images.addIcon}
                        className="icons-client"
                        type="primary"
                        onClick={() => showModal("add-tag")}
                      />
                    </>
                  </div>
                  <Spin size="large" spinning={loading}>
                    <Table
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                      }
                      columns={columns}
                      dataSource={
                        clientTagRes && clientTagRes.items && clientTagRes.items
                      }
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <Modal
          title={createMode === "add-tag" ? "Add Tag" : "Update"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          header={false}
          footer={false}
        >
          <ClientTagForm
            onAddClientTag={onAddClientTag}
            handleCancel={handleCancel}
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            setLoading={setLoading}
            onUpdetaClientTag={onUpdetaClientTag}
            updateClientTagRes={updateClientTagRes}
            createMode={createMode}
            updatedata={updatedata}
          />
        </Modal>
      )}
    </Fragment>
  );
};
export default ClientTags;
