import React, { useEffect, useState, Fragment } from "react";
import { Select, Spin, Modal, Form, message, Input, DatePicker } from "antd";
import debounce from "lodash/debounce";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const ClientActivity = ({ clientActivity }) => {
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [showMail, setToShowMail] = useState("");
  const [htmlText, setHtmlText] = useState(false);

  const confirmationTag = (id, value) => {
    if (value) {
      setModal1Visible(value);
    }
    if (id) {
      setSelectedTag(id);
    }
  };

  const removeProfileTag = (id) => {};

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {}, []);
  console.log(showMail);

  const displayDiv = (ref) => {
    alert(ref);
  };

  return (
    <Fragment>
      <div className="file-notes-content">
        <div className="activity-content-index">
          <div style={{ width: `calc(100% - 130px)` }}>
            <div
              dangerouslySetInnerHTML={{
                __html: clientActivity.clientLogNote,
              }}
            />
          </div>
          <div className="cl-act-org-btn-cont">
            <div className="client-doc">
              <span className="cl-act-org-btn-text">
                {clientActivity.logType}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientActivity;
