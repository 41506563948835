import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";

const DocumentTypesForm = ({
  onAddClientTag,
  addClientTagRes,

  handleCancel,

  onGetClientTag,
  clientTagRes,

  setLoading,

  onUpdetaClientTag,
  updateClientTagRes,

  createMode,

  updatedataRes,

  onGetDocumentType,

  onAddDocumentType,

  onUpdetaDocumentType,

  checkbox,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: updatedataRes.name,
    });
  }, [form, updatedataRes]);
  const onFinish = (values) => {
    if (createMode === "add-document") {
      const data = {
        name: values.name,
      };

      onAddDocumentType(data)
        .then(() => handleCancel())
        .then(() => onGetDocumentType())
        .then(() => {
          form.resetFields();
          message.success("Successfully Added!");
        });
    } else {
      setLoading(true);
      const update = {
        id: updatedataRes.id,
        name: values.name,
      };

      onUpdetaDocumentType(update)
        .then(() => handleCancel())
        .then(() => onGetDocumentType())
        .then(() => {
          setLoading(false);
          form.resetFields();
          message.success("Successfully Updated!");
        });
    }
  };

  return (
    <Fragment>
      <Form name="main" onFinish={onFinish} form={form}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input placeholder="Add New" />
        </Form.Item>
        <Form.Item>
          <Button className="form-btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
export default DocumentTypesForm;
