import React, { useEffect, useState, Fragment } from "react";
import {
  Select,
  Spin,
  Modal,
  Form,
  message,
  Row,
  Col,
  Input,
  Button,
} from "antd";
import moment from "moment";

import debounce from "lodash/debounce";
import { Images } from "./../../Themes";
import activityData from "../../Components/ClientActivity/ActivityData";
import { apiRefresh } from "../../services/api";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const { TextArea } = Input;

const ClientMessages = ({
  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onAddProfileClientTag,
  addProfielTagRes,

  onRemoveProfileClientTag,
  removeProfileTagRes,
  clientId,
  mobNumber,
  callingCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getSms();
  }, []);

  const getSms = () => {
    setGetLoading(true);
    const getMessageOpt = {
      url: `v1/sms/getSMS/${clientId}`,
    };
    getMessageOpt.types = ["SEND_MESSAGE_SUCCES", "SEND_MESSAGE_FAILURE"];

    apiRefresh
      .get(getMessageOpt)
      .then((res) => {
        setGetLoading(false);
        var messageArray = [];
        if (res && res.length > 0) {
          for (var i = 0; i < res.length; i++) messageArray.push(res[i]);
        }
        setMessages(messageArray);
      })
      .catch((err) => {
        setGetLoading(false);
      });
  };

  const onSubmit = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let profileData = JSON.parse(window.localStorage.getItem("profileData"));
    let userName = window.localStorage.getItem("userName");

    if (mobNumber) {
      let num = "";
      if (mobNumber.callingCode) {
        if (mobNumber.callingCode != 0) {
          setLoading(true);
          num = "+" + mobNumber.callingCode + mobNumber.contact;
        } else {
          message.error(
            "Please select country for mobile number form profile!"
          );
          return;
        }
      } else {
        num = `+${callingCode}${mobNumber}`;
      }
      let data = {
        content: messageBody,
        number: num,
        clientId: clientId,
      };
      const sendMessageOpt = {
        url: `v1/sms/sendSMS`,
      };
      sendMessageOpt.types = ["SEND_MESSAGE_SUCCES", "SEND_MESSAGE_FAILURE"];

      apiRefresh
        .post(sendMessageOpt, data)
        .then((res) => {
          if (profileData && mobNumber && mobNumber.callingCode) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "SMS" +
                " " +
                messageBody +
                " " +
                "sent to client by" +
                " " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Chat",
              invoiceId: "0",
            };
            activityData(myData);
          }
          setLoading(false);
          setMessageBody("");
          getSms();
          message.success("Sent!");
        })
        .catch((err) => {
          setLoading(false);
          message.success("Failed!");
        });
    } else {
      message.error("Please enter client's mobile number");
    }
  };
  return (
    <Fragment>
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        size="small"
        spinning={getLoading}
      >
        <Row className="message-textArea">
          <Row style={{ display: "block" }}>
            <Col>
              <TextArea
                rows={12}
                value={messageBody}
                onChange={(e) => {
                  setMessageBody(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ justifyContent: "flex-end", marginTop: 10 }}>
            <Col>
              <Button
                loading={loading}
                style={{ color: "#FFFFFF" }}
                onClick={onSubmit}
                className="button-blue"
              >
                Send
              </Button>
            </Col>
          </Row>
        </Row>

        {messages.map((message, index) => (
          <>
            <Row style={{ marginTop: 10 }}>
              <Col span={8}>
                <span style={{ fontSize: 10, color: "#707070" }}>
                  {message.toPhone}
                </span>
              </Col>
              <Col span={8} offset={8}>
                <span style={{ fontSize: 10, color: "#707070" }}>
                  {moment(message.sendTime).format("DD/MM/YYYY")}
                </span>
              </Col>
            </Row>
            <Row
              className="message-textArea message-content-box-radius"
              style={{ marginTop: 0, marginBottom: 10 }}
            >
              <Col>
                <span style={{ fontSize: 12, color: "#707070" }}>
                  {message.content}
                </span>
              </Col>
            </Row>
          </>
        ))}
      </Spin>
    </Fragment>
  );
};

export default ClientMessages;
