import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

const columns = [
  {
    title: "Client",
    dataIndex: "client",
    key: "client",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <a>{text}</a>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Mobile",
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: "Contact Date",
    dataIndex: "",
    key: "",
  },
  {
    title: "Status",
    dataIndex: "clientStatus",
    key: "clientStatus",
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "Interested Visa Type",
    dataIndex: "interestedVisa",
    key: "interestedVisa",
  },
  {
    title: "Last Activity",
    dataIndex: "modifiedDate",
    key: "modifiedDate",
  },
  {
    title: "Processing Person",
    dataIndex: "fullName",
    key: "fullName",
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class PotentialClientStatusTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson: this.props.requestData.processingIds,
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
        };
        this.props.onFileNotesReport(data).then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };

  render() {
    const { fileNotesReportRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={fileNotesReportRes && fileNotesReportRes}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
          />
        </div>
      </Fragment>
    );
  }
}
export default PotentialClientStatusTable;
