import React, { useState } from "react";
import "./SidebarStyles.css";
import dashboardBI from "../svgs/menu-icons/dashboardBI.svg";
import dashboardClient from "../svgs/menu-icons/dashboard.svg";
import caseManagement from "../svgs/menu-icons/case-management.svg";
import meeting from "../svgs/menu-icons/meeting.svg";
import taskComplete from "../svgs/menu-icons/task-complete.svg";
import playButton from "../svgs/menu-icons/play-button.svg";
import userGroup from "../svgs/menu-icons/users-group.svg";
import addContact from "../svgs/menu-icons/add-contact.svg";
import group from "../svgs/menu-icons/group.svg";
import report from "../svgs/menu-icons/report.svg";
import account from "../svgs/menu-icons/calculator.svg";
import xero from "../svgs/menu-icons/xero.svg";
import email from "../svgs/menu-icons/black-back-closed-envelope-shape.svg";
import agent from "../svgs/menu-icons/insurance-agent.svg";
import schoolManagement from "../svgs/menu-icons/college-graduation.svg";
import supplierManagement from "../svgs/menu-icons/manager.svg";
import questionnaires from "../svgs/menu-icons/question-speech-bubble.svg";
import deals from "../svgs/menu-icons/handshake.svg";
import timeTracking from "../svgs/menu-icons/clock.svg";
import visaForms from "../svgs/menu-icons/forms.svg";
import techSupport from "../svgs/menu-icons/admin-with-cogwheels.svg";
import settings from "../svgs/menu-icons/settings.svg";
import superUserSettings from "../svgs/menu-icons/super_user_settings.svg";
import cpdPlan from "../svgs/menu-icons/CPD-plan.svg";
import usefulLink from "../svgs/menu-icons/usefull-link.svg";
import { Tabs, Layout, Menu, Row, Col, AutoComplete } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getPotentialClientUpdateBol,
  addNewClientNull,
} from "../store/Actions";
import { Fragment } from "react";
const { SubMenu } = Menu;

const { Sider } = Layout;

const AdminSidebar = (onGetCompanies, companiesRes) => {
  // const userOwner = localStorage.getItem("userOwner");
  // const userManager = localStorage.getItem("userManager");
  const dispatch = useDispatch();
  var permissions = JSON.parse(localStorage.getItem("adminPermissions"));

  return (
    <Menu
      className="dashboardSideBar"
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["1"]}
      style={{ width: 286 }}
    >
      {/*<Row>*/}
      {/*  <Col xs={24} style={{ marginBottom: "20px", marginTop: "10px" }}>*/}
      {/*    <AutoComplete*/}
      {/*      // onSelect={this.onSelect}*/}
      {/*      // value={searchText}*/}
      {/*      virtual={false}*/}
      {/*      // onSearch={onSearchCompany}*/}
      {/*      placeholder="Search branch..."*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {permissions.length > 0 &&
        permissions.find((obj) => obj.Module === "Companies") &&
        permissions.find((obj) => obj.Module === "Companies").Permisions && (
          <Menu.Item key="1" icon={<img src={agent} />}>
            <Link to="/companies">Companies</Link>
          </Menu.Item>
        )}
      {permissions.length > 0 &&
        permissions.find((obj) => obj.Module === "LoginImage") &&
        permissions.find((obj) => obj.Module === "LoginImage").Permisions && (
          <Menu.Item key="2" icon={<img src={agent} />}>
            <Link to="/login-image">Login Image</Link>
          </Menu.Item>
        )}
      {permissions.length > 0 &&
        permissions.find((obj) => obj.Module === "Users") &&
        permissions.find((obj) => obj.Module === "Users").Permisions && (
          <Menu.Item key="3" icon={<img src={agent} />}>
            <Link to="/company-user">Company Users</Link>
          </Menu.Item>
        )}
    </Menu>
  );
};

export default AdminSidebar;
