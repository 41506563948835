import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LetterTemplates from "../AccountSetting/ContractSettings/ContractSettings";

import {
  getLetterTemplates,
  addLetterTemplates,
  removeLetterTemplates,
  updetaLetterTemplates,
  sortLetterTemplates,
  getDynamicKeys,
  getDocumentDownload,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    addLetterTemplatesRes: state.LetterTemplatesReducer.addLetterTemplatesRes,
    removeLetterTemplatesRes:
      state.LetterTemplatesReducer.removeLetterTemplatesRes,
    updateLetterTemplatesRes:
      state.LetterTemplatesReducer.updateLetterTemplatesRes,
    updateSortLetterTemplatesRes:
      state.LetterTemplatesReducer.updateSortLetterTemplatesRes,
    dynamicKeysRes: state.fileNotesReducer.dynamicKeysRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
    onAddLetterTemplates: bindActionCreators(addLetterTemplates, dispatch),
    onRemoveLetterTemplates: bindActionCreators(
      removeLetterTemplates,
      dispatch
    ),
    onUpdetaLetterTemplates: bindActionCreators(
      updetaLetterTemplates,
      dispatch
    ),
    onSortLetterTemplates: bindActionCreators(sortLetterTemplates, dispatch),
    onGetDynamicKeys: bindActionCreators(getDynamicKeys, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LetterTemplates);
