import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import SaleHistoryTable from "./SaleHistoryTable";
import { Images } from "../../Themes";
import BirthdayReportTable from "./BirthdayReportTable";
import ClientEmployerTable from "./ClientEmployerTable";
import DocChecklistTable from "./DocCheckListTable";

// const SaleHistoryReport = ({ saleHistoryRes, displayText }) => {
export class DocChecklistReport extends React.PureComponent {
  render() {
    const {
      clientEmployerReportRes,
      displayText,
      reportsCount,
      onDocChecklistReport,
      requestData,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          {/* <div className="rep-print-row-cont">
            <div />
            <div>
              <div className="rep-print-icon-cont">
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <img
                        style={{ cursor: "pointer" }}
                        src={Images.printNBlue}
                        className="rep-print-icon"
                      />
                    );
                  }}
                  content={() => this.birthdayReportRef}
                />
              </div>
            </div>
          </div> */}
          <DocChecklistTable
            clientEmployerReportRes={clientEmployerReportRes}
            ref={(el) => (this.birthdayReportRef = el)}
            displayText={displayText}
            reportsCount={reportsCount}
            onDocChecklistReport={onDocChecklistReport}
            requestData={requestData}
          />
        </div>
      </Fragment>
    );
  }
}
export default DocChecklistReport;
