import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaStatus from "../SuperUserSetting/VisaStatus";

import {
  getVisaStatus,
  addVisaStatus,
  updVisaStatus,
  getCountries,
  setActiveInnerTab,
  VisaStatusReOrder,
  hideUnhideVisaStatus,
  getVisaStatusWithHide,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaStatusHideData: state.visaStatusReducer.visaStatusWithHideData,
    addVisaStatusSuccess: state.visaStatusReducer.addVisaStatusSuccess,
    updVisaStatusSuccess: state.visaStatusReducer.updVisaStatusSuccess,
    countriesData: state.visaTypeReducer.countriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onAddVisaStatus: bindActionCreators(addVisaStatus, dispatch),
    onUpdVisaStatus: bindActionCreators(updVisaStatus, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    VisaStatusReOrder: bindActionCreators(VisaStatusReOrder, dispatch),
    onHideUnhideVisaStatus: bindActionCreators(hideUnhideVisaStatus, dispatch),
    onGetVisaStatusWithHide: bindActionCreators(
      getVisaStatusWithHide,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaStatus);
