import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignUp from "../Components/SignUp";

import {
  addSignUpEmail,
  getTextEncoding,
  sendDynamicLinkEmail
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    addSignUpRes: state.signUpReducer.addSignUpRes,
    textEncodingRes: state.signUpReducer.textEncodingRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddSignUpEmail: bindActionCreators(addSignUpEmail, dispatch),
    onGetTextEncoding: bindActionCreators(getTextEncoding, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
