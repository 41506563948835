import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardPotentialClient from "./../Dashboard/PotenialClient";

import {
  getDashboardPotentialClient,
  getProcessingPersons,
  addCaseTask,
  getClientFile,
  addClientFile,
  getPotentialClientPendingTask,
  getPotentialClientInfo,
  getPotentialClientUpdateBol,
  getPotentialClientStatus,
  getClientTag,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    processingPersons: state.dashboardPotentialClientReducer.processingPersons,
    potentialClientList:
      state.dashboardPotentialClientReducer.potentialClientList,
    potentialClientError:
      state.dashboardPotentialClientReducer.potentialClientError,
    potentialClientData:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientData &&
      state.potentialClientReducer.potentialClientData.items &&
      state.potentialClientReducer.potentialClientData.items,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDashboardPotentialClients: bindActionCreators(
      getDashboardPotentialClient,
      dispatch
    ),
    onGetProcessingPersons: bindActionCreators(getProcessingPersons, dispatch),
    onGetCaseTask: bindActionCreators(getPotentialClientPendingTask, dispatch),
    onAddTask: bindActionCreators(addCaseTask, dispatch),
    onGetClientFile: bindActionCreators(getClientFile, dispatch),
    onAddClientFile: bindActionCreators(addClientFile, dispatch),
    onGetPotentialClient: bindActionCreators(getPotentialClientInfo, dispatch),
    onGetUpdateBol: bindActionCreators(getPotentialClientUpdateBol, dispatch),
    getPotentialClientStatus: bindActionCreators(
      getPotentialClientStatus,
      dispatch
    ),
    getClientTag: bindActionCreators(getClientTag, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPotentialClient);
