import React, { Fragment } from "react";
import { Images } from "./../../Themes";
import { Tooltip } from "antd";

const CommissionReminders = () => {
  return (
    <Fragment>
      <div className="completed-tasks">
        <div className="task-to-do">
          <div className="heading-style">
            <span>COMMISSION REMINDERS</span>
          </div>

          <div className="cv-gray-cont">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 29,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <input
                    type="checkbox"
                    className="sus-checkbox"
                    // onChange={this.handleCheck}
                    // defaultChecked={this.state.checked}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="cv-normal-text">Ghbbjnm (03/06/2020)</span>
                </div>
                <div className="marked-name">
                  <span className="marked-name-font">Julie Smith</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="mdtr-user-cont">
                  <img
                    src={Images.userCircleGray}
                    style={{ width: 15, height: 15 }}
                  />
                  <img
                    src={Images.nextGray}
                    style={{
                      marginLeft: 5,
                      transform: `rotate(90deg)`,
                      width: 12,
                      height: 12,
                    }}
                  />
                </div>
                <Tooltip placement="topLeft" title={`Calendar`}>
                  <div
                    className="mdtr-user-cont"
                    style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                  >
                    <img
                      src={Images.calendarBlue}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="topLeft" title={`Update`}>
                  <div
                    className="mdtr-user-cont"
                    style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                  >
                    <img
                      src={Images.fileNotes}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="topLeft" title={`Delete`}>
                  <div
                    className="mdtr-user-cont"
                    style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                  >
                    <img
                      src={Images.deleteIcon}
                      style={{ width: 15, height: 15 }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 29,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <input
                    type="checkbox"
                    className="sus-checkbox"
                    // onChange={this.handleCheck}
                    // defaultChecked={this.state.checked}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="cv-normal-text">Ghbbjnm (03/06/2020)</span>
                </div>
                <div className="marked-name">
                  <span className="marked-name-font">Julie Smith</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="mdtr-user-cont">
                  <img
                    src={Images.userCircleGray}
                    style={{ width: 15, height: 15 }}
                  />
                  <img
                    src={Images.nextGray}
                    style={{
                      marginLeft: 5,
                      transform: `rotate(90deg)`,
                      width: 12,
                      height: 12,
                    }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.calendarBlue}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.fileNotes}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.deleteIcon}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 29,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <input
                    type="checkbox"
                    className="sus-checkbox"
                    // onChange={this.handleCheck}
                    // defaultChecked={this.state.checked}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="cv-normal-text">Ghbbjnm (03/06/2020)</span>
                </div>
                <div className="marked-name">
                  <span className="marked-name-font">Julie Smith</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="mdtr-user-cont">
                  <img
                    src={Images.userCircleGray}
                    style={{ width: 15, height: 15 }}
                  />
                  <img
                    src={Images.nextGray}
                    style={{
                      marginLeft: 5,
                      transform: `rotate(90deg)`,
                      width: 12,
                      height: 12,
                    }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.calendarBlue}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.fileNotes}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.deleteIcon}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 20,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <input
                    type="checkbox"
                    className="sus-checkbox"
                    // onChange={this.handleCheck}
                    // defaultChecked={this.state.checked}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="cv-normal-text">Ghbbjnm (03/06/2020)</span>
                </div>
                <div className="marked-name">
                  <span className="marked-name-font">Julie Smith</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="mdtr-user-cont">
                  <img
                    src={Images.userCircleGray}
                    style={{ width: 15, height: 15 }}
                  />
                  <img
                    src={Images.nextGray}
                    style={{
                      marginLeft: 5,
                      transform: `rotate(90deg)`,
                      width: 12,
                      height: 12,
                    }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.calendarBlue}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.fileNotes}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    src={Images.deleteIcon}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CommissionReminders;
