import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import { Modal, Spin, Form, Select, Button, Tooltip } from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import { setActiveInnerTab } from "../../../store/Actions";
import { bindActionCreators } from "redux";

const CalenderSync = ({ onSetActiveInnerTab }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});
  const state = useLocation().state;

  const { Option } = Select;

  // useEffect(() => {
  //   setLoading(true);
  //   onGetClientTag().then(() => {
  //     setLoading(false);
  //   });
  // }, [onGetClientTag]);

  // const removeTag = (id) => {
  //   setLoading(true);
  //
  //   const remove = {
  //     id: id,
  //     delete: true,
  //   };
  //
  //   onRemoveClientTag(remove).then(() => {
  //     onGetClientTag().then(() => {
  //       setLoading(false);
  //       message.success("Successfully Deleted!");
  //     });
  //   });
  // };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.calendarBlue} className="sus-bottom-icon" />
                </div>

                <span className="top-text">Calendar</span>
                <Tooltip
                  placement="topLeft"
                  title={`Use this area to sync your online calendar with Ezymigrate.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="mar-top-75">
              <Form>
                <div className="w-80">
                  <div className="d-flex">
                    <Form.Item>
                      <div className="margin-btns">
                        <Button
                          className="margin-top button-blue"
                          href="https://myaccount.google.com/?utm_source=sign_in_no_continue"
                          type="primary"
                        >
                          ACCOUNT
                        </Button>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="margin-top button-blue"
                        href="https://myaccount.google.com/?utm_source=sign_in_no_continue"
                        type="primary"
                      >
                        DEFAULT SETTINGS
                      </Button>
                    </Form.Item>
                  </div>
                  <div className="margin-top-40">
                    <h3>CALENDAR</h3>
                  </div>
                  <div className="document-sync-box">
                    <div>
                      <div className="client-tag-table">
                        <div className="reminder-set-form margin-top-34">
                          <div>
                            <div className="doc-office">
                              <div className="d-flex">
                                <p>Name:</p>
                                <p>Usama Chohan</p>
                              </div>
                              <div className="d-flex">
                                <p>Email:</p>
                                <p>Usama@123</p>
                              </div>
                            </div>
                            <Form.Item>
                              <Button
                                className="margin-top"
                                href="https://myaccount.google.com/?utm_source=sign_in_no_continue"
                                type="primary"
                              >
                                START SYNC
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-80">
                  <h3>GMAIL</h3>
                  <div className="document-sync-box">
                    <div>
                      <div className="client-tag-table">
                        <div className="reminder-set-form margin-top-34">
                          <Form.Item className="d-block">
                            <Button type="primary">ADD ACCOUNT</Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CalenderSync;
