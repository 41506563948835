import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Form, Modal } from "antd";
import { message, Spin, Button, Row, Col, Checkbox } from "antd";
import "./ForgotPassword.css";
import { Images } from "../Themes";
import getUserBranchPermissions from "../Components/getUserBranchPermissions";
class ForgotPassword extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      loading: false,
      visibleDisclaimerModal: false,
      visibleReleasePlanModal: false,
      externalLogin: false,
    };
  }

  getForm = () => {
    return (
      <div>
        <Row>
          <Col style={{ marginLeft: "8px" }}>
            <img src={Images.logo} className="logo-img" />
          </Col>
        </Row>
        <Row style={{ marginTop: "71px" }}>
          <Col style={{ marginLeft: "8px" }}>
            <p
              className="lgin-text-main poppins-font-family"
              style={{ fontSize: "24px" }}
            >
              Reset Password
            </p>
          </Col>
        </Row>

        <Row>
          <Col style={{ marginTop: "31px", marginLeft: "26px" }} xs={22}>
            <div>
              <p
                className="poppins-font-family"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                Password
              </p>

              <Form.Item
                colon={false}
                name="password"
                rules={[{ required: true, message: "Required!" }]}
              >
                <div className="profile-input-border">
                  <input
                    className="profile-input-login"
                    placeholder="Password"
                    type="password"
                    style={{ width: "100%" }}
                  />
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: "26px", marginTop: "20px" }} xs={22}>
            <div>
              <p
                className="poppins-font-family"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                Confirm Password
              </p>
              <Form.Item
                colon={false}
                name="confpassword"
                rules={[{ required: true, message: "Required!" }]}
              >
                <div className="profile-input-border">
                  <input
                    className="profile-input-login"
                    placeholder="Confirm Password"
                    type="password"
                    style={{ width: "100%" }}
                    // onChange={(e) => this.passwordChangeHandler(e)}
                  />
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={22} style={{ marginLeft: "26px", marginTop: "41px" }}>
            <Form.Item>
              <div className="ts-send-btn-cont">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ts-send-btn"
                  size="large"
                  style={{ width: "100%" }}
                  loading={this.state.loading}
                >
                  <span
                    className="sus-modal-button-text"
                    style={{ fontSize: "20px" }}
                  >
                    Reset & LogIn
                  </span>
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginTop: "23px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="lg-item-center" style={{ marginTop: 19 }}>
                <span>
                  <Link
                    onClick={this.showModalReleasePlan}
                    className="poppins-font-family"
                    style={{ fontSize: "14px" }}
                  >
                    View Release Plan
                  </Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  showModalDisclaimer = () => {
    // this.setState({
    //   visibleDisclaimerModal: true,
    // });
  };

  handleCancelDisclaimerModal = (e) => {
    this.setState({
      visibleDisclaimerModal: false,
    });
  };

  getDisclaimerModal = () => {
    return (
      <div>
        <Modal
          title="Disclaimer"
          visible={this.state.visibleDisclaimerModal}
          onCancel={this.handleCancelDisclaimerModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                This is a demo of the latest Ezymigrate release. You are free to
                add and change any of the sample data. However, all data will be
                deleted prior to migrating all your current client data to the
                new system. Ezymigrate accepts no responsibility for the content
                created by its users or any loss of data on this beta version.
                There will be limited access to Ezymigrate and its latest
                features as they are still under release. By continuing you
                acknowledge and agree to the disclaimer.
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={15} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelDisclaimerModal();
                }}
                style={{ borderRadius: 5 }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                loading={this.state.loading}
                className="button button-blue"
                onClick={() => {
                  this.setState({ loading: true });
                  let data = {
                    email: this.state.userName,
                    password: this.state.password,
                  };
                  if (this.state.externalLogin) {
                    this.props
                      .onExternalUserLogin(data)
                      .then((res) => {
                        this.setState({ loading: false }, () => {
                          localStorage.removeItem("admintoken");
                          localStorage.setItem(
                            "token",
                            res.payload.accessToken
                          );
                          localStorage.setItem(
                            "refreshToken",
                            res.payload.refreshToken
                          );
                          let token = localStorage.getItem("token");
                          var jwtDecoded = jwt_decode(res.payload.accessToken);
                          localStorage.setItem("userId", jwtDecoded.sub);
                          localStorage.setItem("userEmail", jwtDecoded.email);
                          localStorage.setItem("userOwner", jwtDecoded.IsOwner);
                          localStorage.setItem(
                            "selectedBranchId",
                            jwtDecoded.BranchId
                          );
                          localStorage.setItem("companyId", jwtDecoded.Com);
                          localStorage.setItem("userName", jwtDecoded.FullName);

                          // localStorage.setItem(
                          //   "UserSystemPermissions",
                          //   jwtDecoded.UserSystemPermissions
                          // );
                          this.props.getToken(token);

                          let tokenData = localStorage.getItem(
                            "notificationToken"
                          );
                          getUserBranchPermissions();
                          this.props
                            .onNotificationAdd(jwtDecoded.sub, tokenData)
                            .then((res) => {
                              console.log("Notification token submitted");
                            });
                          this.props.history.push("/dashboardBI");
                        });
                      })
                      .catch((err) => {
                        let _err =
                          err &&
                          err.payload &&
                          err.payload.response &&
                          err.payload.response.data &&
                          err.payload.response.data.Status;

                        this.setState({ loading: false }, () => {
                          if (_err) {
                            message.error("Invalid credentials");
                          } else {
                            message.error(
                              "An error occurred! Please try again"
                            );
                          }
                        });
                      });
                  } else {
                    this.props
                      .onUserLogin(data)
                      .then((res) => {
                        this.setState({ loading: false }, () => {
                          localStorage.removeItem("admintoken");
                          localStorage.setItem(
                            "token",
                            res.payload.accessToken
                          );
                          localStorage.setItem(
                            "refreshToken",
                            res.payload.refreshToken
                          );
                          let token = localStorage.getItem("token");
                          var jwtDecoded = jwt_decode(res.payload.accessToken);
                          localStorage.setItem("userId", jwtDecoded.sub);
                          localStorage.setItem("userEmail", jwtDecoded.email);
                          localStorage.setItem("userOwner", jwtDecoded.IsOwner);

                          // localStorage.setItem(
                          //   "UserSystemPermissions",
                          //   jwtDecoded.UserSystemPermissions
                          // );
                          localStorage.setItem(
                            "selectedBranchId",
                            jwtDecoded.BranchId
                          );
                          localStorage.setItem("companyId", jwtDecoded.Com);
                          localStorage.setItem("userName", jwtDecoded.FullName);
                          this.props.getToken(token);

                          let tokenData = localStorage.getItem(
                            "notificationToken"
                          );
                          getUserBranchPermissions();
                          this.props
                            .onNotificationAdd(jwtDecoded.sub, tokenData)
                            .then((res) => {
                              console.log("Notification token submitted");
                            });
                          // this.props.history.push("/dashboardBI");
                        });
                      })
                      .catch((err) => {
                        let errMessage =
                          err &&
                          err.payload &&
                          err.payload.response &&
                          err.payload.response.data &&
                          err.payload.response.data.reponseMessage;

                        this.setState({ loading: false }, () => {
                          if (errMessage) {
                            message.error(errMessage);
                          } else {
                            message.error(
                              "An error occurred! Please try again"
                            );
                          }
                        });
                      });
                  }
                }}
              >
                I Agree
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  showModalReleasePlan = () => {
    this.setState({
      visibleReleasePlanModal: true,
    });
  };

  handleCancelReleasePlanModal = (e) => {
    this.setState({
      visibleReleasePlanModal: false,
    });
  };

  getReleasePlanModal = () => {
    return (
      <div>
        <Modal
          style={{ marginTop: "-75px" }}
          width="100%"
          visible={this.state.visibleReleasePlanModal}
          onCancel={this.handleCancelReleasePlanModal}
          footer={null}
          maskClosable={false}
        >
          <div
            style={{
              height: "600px",
              maxHeight: "600px",
              overflowY: "scroll",
              marginTop: "30px",
            }}
          >
            <Row>
              <Col xs={24}>
                <h1 className="font-family" style={{ fontSize: "48px" }}>
                  It&rsquo;s Finally Here!
                </h1>
                <h1 className="font-family" style={{ fontSize: "48px" }}>
                  We are so Happy to Announce the Feature Release Plan for the
                  Brand New Ezymigrate Platform!
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xs={10} style={{ marginTop: "20px", paddingRight: "6px" }}>
                <h2 className="font-family" style={{ fontWeight: "700" }}>
                  Ezymigrate system Release Plan
                </h2>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  Ezymigrate is excited to announce the launch of their new
                  system today. Experience a complete upgrade with this
                  incredible historic day that opens up so many doors for you!
                  The ezymigrate team has been working hard on this exciting
                  update and we just can't wait to share it with all our
                  customers soon!
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  We decided to release the brand new Ezymigrate in X stages,
                  this way we can take our current users through each stage of
                  the new system to ensure everyone is comfortable before we
                  migrate all the data from the current Ezymigrate to the new
                  platform.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  This will allow us to slowly and effectively communicate all
                  of the new features, changes, and updates that we are
                  implementing over time instead of overloading our users with
                  everything at once.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  You can expect a smoother ride when we launch our new
                  extensive Ezymigrate system in stages. Our developers are
                  already working on any bugs that may occur, and they'll be
                  able to manage them more easily with the system released step
                  by step so your business is never interrupted.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  For now you will keep using the current system, once we have
                  completed the Release Plan and all features and
                  functionalities are fully deployed and you are comfortable
                  with the new system, then we will help you move you and your
                  data over to the brand new Ezymigrate.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  Finally we want to say this version of Ezymigrate is a beta.
                  The purpose is to ensure our customers are happy and confident
                  in using it before we migrate all our clients to the new
                  system. Our goal is to make the transfer easy and stress free.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  Please be patient. It's a new system in beta. It is completely
                  normal for there to be bugs when people start using it. We are
                  aware and ready to start fixing them if they occur.
                </p>
                <p
                  className="font-family"
                  style={{ fontSize: "14px", color: "black" }}
                >
                  Keep an eye on this page we will post updates and changes as
                  we progress through the Release Plan.
                </p>
                <h4
                  className="font-family"
                  style={{ fontWeight: "bolder", marginTop: "23px" }}
                >
                  Questions?
                </h4>
                <h4 className="font-family" style={{ marginTop: "13px" }}>
                  Can I start working on the New Ezymigrate?
                </h4>
                <p className="font-family" style={{ color: "black" }}>
                  We are so excited for you to start using the new Ezymigrate,
                  however for now this is a beta for you to try out and learn
                  how to use the new features. All data on the beta will be
                  deleted before we move all data from the current ezymigrate
                  over to the new platform.
                </p>
                <h4 className="font-family" style={{ marginTop: "13px" }}>
                  What happens to all my client files and data?
                </h4>
                <p className="font-family" style={{ color: "black" }}>
                  Nothing, everything stays the same for now, we will make sure
                  we keep you well informed before we start migrating your data
                  to the new platform.
                </p>
                <h4 className="font-family" style={{ marginTop: "13px" }}>
                  How do we access the new Ezymigrate?
                </h4>
                <p className="font-family" style={{ color: "black" }}>
                  You can find the login page here just use your current
                  Ezymigrate login credentials to try out the new beta!
                </p>
                <h4 className="font-family" style={{ marginTop: "13px" }}>
                  When is the Mobile App and Ezyform going to be available?
                </h4>
                <p className="font-family" style={{ color: "black" }}>
                  We are so excited about these game changing features! We want
                  to ensure they are perfect for you! Our goal is for them to be
                  ready. Our goal is for them to be completed during the final
                  stage 4 of the Release Plan.{" "}
                </p>
              </Col>
              <Col xs={14} style={{ marginTop: "66px" }}>
                <img
                  src={Images.releasePlanImageSecond}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  };

  recoverPassword = ({ password, confpassword }) => {
    if (password != confpassword)
      return message.error("Password and confirm password are different!");
    this.setState({ loading: true });
    let location = window.location.href;
    var last = location.substring(
      location.lastIndexOf("/") + 1,
      location.length
    );
    this.props
      .onPasswordRecovery({
        password: password,
        link: last,
      })
      .then(() => {
        this.setState({ loading: false });
        message.success("Password reset successfully!");
        setTimeout(() => {
          this.props.history.push("/login");
        }, 1200);
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Some error occured!");
      });
  };

  render() {
    return (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <Row style={{ height: "100vh" }}>
          <Col xs={8}>
            <Form onFinish={this.recoverPassword} ref={this.formRef}>
              {this.getForm()}
            </Form>
          </Col>
          <Col xs={16}>
            <img src={Images.loginImage} className={"img-login-bg"} />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              className="lg-item-center"
              style={{ padding: 15, position: "relative" }}
            >
              <span
                className="lg-copyright-text"
                style={{
                  fontSize: "14px",
                  position: "fixed",
                  bottom: 0,
                  backgroundColor: "#1281b9",
                  width: "100%",
                  color: "white",
                }}
              >
                © 2021 EzyMigrate All right reserved.
              </span>
            </div>
          </Col>
        </Row>
        {this.getDisclaimerModal()}
        {this.getReleasePlanModal()}
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     selectedBranch: state.branchReducer.selectedBranch,
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   onSetSelectedBranchId: bindActionCreators(setSelectedBranchId, dispatch),
//   getXeroToken: bindActionCreators(getXeroToken, dispatch),
// });

// LoginNew = connect(mapStateToProps, mapDispatchToProps)(LoginNew);

export default withRouter(ForgotPassword);
