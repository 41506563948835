import AUS1446 from "../VisaForms/1446.pdf";
import AUS80 from "../VisaForms/80.pdf";
import AUS40CH from "../VisaForms/40ch.pdf";
import AUS47CH from "../VisaForms/47ch.pdf";
import AUS956 from "../VisaForms/956.pdf";
import AUS1000 from "../VisaForms/1000.pdf";
import AUS1022 from "../VisaForms/1022.pdf";
import AUS1300 from "../VisaForms/1300t.pdf";
import AUS1424 from "../VisaForms/1424.pdf";
import AUS1436 from "../VisaForms/1436.pdf";

export const ausVisaForms = [
  {
    title: "PERSONAL PARTICULARS FOR ASSESSMENT INCLUDING CHARACTER ASSESSMENT",
    link: AUS80,
  },
  {
    title: "SPONSORSHIP FOR A CHILD TO MIGRATE TO AUSTRALIA",
    link: AUS40CH,
  },
  {
    title: "APPLICATION FOR MIGRATION TO AUSTRALIA BY A CHILD",
    link: AUS47CH,
  },
  {
    title:
      "APPOINTMENT OF A REGISTERED MIGRATION AGENT, LEGAL PRACTITIONER OR EXEMPT PERSON",
    link: AUS956,
  },
  {
    title: "NOMINATION FOR GLOBAL TALENT",
    link: AUS1000,
  },
  {
    title: "NOTIFICATION OF CHANGES IN CIRCUMSTANCES",
    link: AUS1022,
  },
  {
    title: "APPLICATION FOR AUSTRALIAN CITIZENSHIP",
    link: AUS1300,
  },
  {
    title: "REFUND REQUEST",
    link: AUS1424,
  },
  {
    title: "ADDING AN ADDITIONAL APPLICANT AFTER LODGEMENT",
    link: AUS1436,
  },
  {
    title: "WITHDRAWAL OF A VISA APPLICATION",
    link: AUS1446,
  },
];
