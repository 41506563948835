export const onChangeVisaCaseStatus = (
  scope,
  visa,
  visaItem,
  clientIndex,
  visaCaseInd
) => {
   
  scope.setState({
    isModalVisible: true,
    caseStatusData: {
      id: visaItem.id,
      caseStatusId: visa.id,
      branchVisaTypeId: visaItem.branchVisaTypeId,
      clientIndex: clientIndex,
      visaCaseInd: visaCaseInd,
      selectedVisa: visa
    }
  });
};

export const onChangeAdmissionCaseStatus = (
  scope,
  admission,
  programItem,
  clientIndex,
  programCaseInd
) => {
   
  scope.setState({
    isAdmissionCaseModalVisible: true,
    admissionStatusData: {
      ...programItem,
      programStatus: admission.id,
      programStatusName: admission.name.trim(),
      color: admission.color,
      clientIndex: clientIndex,
      programCaseInd: programCaseInd
    }
  });
};

export const onChangeChecklistVisaStatus = (scope, visaItem, status) => {
   
  if (!visaItem["newVisaCaseChecklistObj"])
    visaItem["newVisaCaseChecklistObj"] = { statusName: status };
  else
    visaItem["newVisaCaseChecklistObj"] = {
      ...visaItem["newVisaCaseChecklistObj"],
      statusName: status
    };
   
  scope.setState({
    clients: scope.state.clients
  });
};

export const onSelectVisaChecklist = (scope, checklist, clientId, visaItem) => {
  let cObj = {
    id: checklist.id,
    checkListName: checklist.name,
    caseId: visaItem.id,
    caseType: "V",
    clientId: clientId,
    branchId: checklist.branchId,
    createdBy: checklist.branchId,
    tasks: checklist.tasks.items,
    description: checklist.description
  };
  if (!visaItem["newVisaCaseChecklistObj"])
    visaItem["newVisaCaseChecklistObj"] = cObj;
  else
    visaItem["newVisaCaseChecklistObj"] = {
      ...visaItem["newVisaCaseChecklistObj"],
      ...cObj
    };
  scope.setState({
    clients: scope.state.clients
  });
};

export const onChangeChecklistAdmissionStatus = (
  scope,
  programItem,
  status
) => {
  if (!programItem["newAdmissionCaseChecklistObj"])
    programItem["newAdmissionCaseChecklistObj"] = { statusName: status };
  else
    programItem["newAdmissionCaseChecklistObj"] = {
      ...programItem["newAdmissionCaseChecklistObj"],
      statusName: status
    };
  scope.setState({
    clients: scope.state.clients
  });
};

export const onSelectAdmissionChecklist = (
  scope,
  programItem,
  checklist,
  clientId
) => {
  let cObj = {
    id: checklist.id,
    checkListName: checklist.name,
    caseId: programItem.id,
    caseType: "A",
    clientId: clientId,
    branchId: checklist.branchId,
    createdBy: checklist.branchId,
    tasks: checklist.tasks.items,
    description: checklist.description
  };
  if (!programItem["newAdmissionCaseChecklistObj"])
    programItem["newAdmissionCaseChecklistObj"] = cObj;
  else
    programItem["newAdmissionCaseChecklistObj"] = {
      ...programItem["newAdmissionCaseChecklistObj"],
      ...cObj
    };
  scope.setState({
    clients: scope.state.clients
  });
};
