import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const accountDocumentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DOCUMENTS_SUCCESS:
      return { ...state, documentRes: action.payload };
    case types.GET_DOCUMENTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }
      return { ...state, documentRes: [] };

    case types.ADD_DOCUMENTS_SUCCESS:
      return { ...state, addDocumentRes: action.payload };
    case types.ADD_DOCUMENTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_DOCUMENTS_SUCCESS:
      return { ...state, removeDocumentRes: action.payload };
    case types.DELETE_DOCUMENTS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
