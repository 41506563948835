import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <span>{index + 1}</span>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
    render: (text, row, index) => {
      return (
        <Link
          to={{
            pathname:
              row.memberType.toLowerCase() === "client"
                ? "/profile"
                : row.memberType.toLowerCase() === "spouse"
                ? "/partner-profile"
                : "/client-family-member",
            state: {
              id: row.clientId,
              familyId: "00000000-0000-0000-0000-000000000000",
            },
          }}
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Invoiced Amount",
    dataIndex: "total",
    key: "invoicedAmount",
  },

  {
    title: "Received Amount",
    dataIndex: "received",
    key: "receivedAmount",
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class SaleHistoryTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson:
            this.props.requestData.processingIds ||
            "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
        };
        this.props.onSaleHistoryReport(data).then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };

  render() {
    const {
      saleHistoryRes,
      displayText,
      totalInvoiced,
      totalReceived,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={saleHistoryRes && saleHistoryRes}
            pagination={false}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
          />
          <div className="report-total-cont">
            <div className="report-total-text-div">
              <span
                className="cv-bold-text"
                style={{ marginRight: 20, fontSize: 10 }}
              >
                Total
              </span>
            </div>
            <div className="rep-rec-am-total-cont" style={{ width: "17%" }}>
              <span className="cv-bold-text" style={{ fontSize: 10 }}>
                {totalInvoiced}
              </span>
            </div>
            <div className="rep-rec-am-total-cont" style={{ width: "18%" }}>
              <span className="cv-bold-text" style={{ fontSize: 10 }}>
                {totalReceived}
              </span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default SaleHistoryTable;
