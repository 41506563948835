import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardStudent from "./../Dashboard/Student";

import {
  getDashboardStudents,
  getAdmissionCaseStatuses,
  getPotentialClientPendingTask,
  addCaseTask,
  getClientFile,
  addClientFile,
  removeFollower,
  getProcessingPersons,
  getTasksFollowers,
  getTaskComment,
  addTaskComment,
  addTaskFollower,
  updateAdmissionCaseStatus,
  updateClientPriority,
  getTeamMember,
  getClientSummarySettings,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    studentsList: state.dashboardStudentReducer.dashboardStudentsList,
    studentsListError: state.dashboardStudentReducer.dashboardStudentsError,
    admissionCaseStatus: state.caseManagementReducer.admissionCaseStatus,
    processingPersons: state.clientSummaryReducer.processingPersons,
    teamMembers: state.teamMemberReducer.teamMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDashboardStudents: bindActionCreators(getDashboardStudents, dispatch),
    onGetProcessingPersons: bindActionCreators(getProcessingPersons, dispatch),
    onGetCaseTask: bindActionCreators(getPotentialClientPendingTask, dispatch),
    onAddTask: bindActionCreators(addCaseTask, dispatch),
    onGetClientFile: bindActionCreators(getClientFile, dispatch),
    onAddClientFile: bindActionCreators(addClientFile, dispatch),
    onGetAdmissionCaseStatus: bindActionCreators(
      getAdmissionCaseStatuses,
      dispatch
    ),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onGetTaskComment: bindActionCreators(getTaskComment, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onUpdateAdmissionCaseStatus: bindActionCreators(
      updateAdmissionCaseStatus,
      dispatch
    ),
    onUpdateClientPriority: bindActionCreators(updateClientPriority, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetClientSummarySettings: bindActionCreators(
      getClientSummarySettings,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStudent);
