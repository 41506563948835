import React, { Fragment, useEffect, useState } from "react";
import {
  Tabs,
  Modal,
  Form,
  Row,
  Col,
  Select,
  Table,
  Tooltip,
  DatePicker,
  Spin,
  Button,
} from "antd";
import EmployerCaseJobTable from "./EmployerCaseJobTable";
import { apiRefresh } from "../../../../services/api";
import { yyyymmddFormate } from "../../../../Common/reUseFunction";
import { UserOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

const EmployerCaseReport = ({
  userDataEmp,
  onGetEmployerJob,
  employerJobRes,
  onAddEmployerJob,
  onGetTeamMember,
  teamMembers,
  onUpdateEmployerJob,
  onRemoveEmployerJob,
  onGetClientSearch,
  clientSearchRes,
  onSearchClient,
  searchClientData,
  onGetClientByEmpId,
  empClientEmpRes,
  onLinkEmpWithClient,
  onAddEmployerJobHistory,
  onGetSingleClientHis,
  singleJobHisRes,
  visaStatusData,
  onGetVisaStatus,
  onGetVisaApplication,
  visaAppData,
  tabKey,
}) => {
  const [loading, setLoading] = useState([false]);
  const [jcDropdwn, setJcDropdwn] = useState([]);
  const [caseReportList, setCaseReportList] = useState([]);
  const [empJobList, setEmpJobList] = useState([]);
  const [activeTab, setActiveTab] = useState("Jobs");
  const [expandedRowsEntriesKeys, setExpandedRowsEntriesKeys] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    getCaseReport();
  }, []);
  useEffect(() => {
    if (tabKey == "6") getCaseReport();
  }, [tabKey]);

  useEffect(() => {
    // onGetVisaStatus(localStorage.getItem("selectedBranchId"));
    if (userDataEmp) {
      const getJobCaseOpt = {
        url: `v1/employerCase/EmployerJobCheckDropdown/${userDataEmp.id}`,
      };

      getJobCaseOpt.types = [
        "GET_EMPLOYER_CASE_REPORT_SUCCESS",
        "GET_EMPLOYER_CASE_REPORT_FAILURE",
      ];
      apiRefresh.get(getJobCaseOpt).then((res) => {
        setJcDropdwn(res);
      });
    }
  }, [userDataEmp]);

  const getCaseReport = (data) => {
    let dataFilter = {
      subjectId: userDataEmp && userDataEmp.id,
      caseStatusId: 0,
      visaTypeId: 10100,
      jobCheckId: (data && data.jobs) || 0,
    };
    setLoading(true);
    const getEmpCaseOpt = {
      url: `v1/employerCase/GetReport`,
    };

    getEmpCaseOpt.types = [
      "GET_EMPLOYER_CASE_REPORT_SUCCESS",
      "GET_EMPLOYER_CASE_REPORT_FAILURE",
    ];
    apiRefresh
      .post(getEmpCaseOpt, dataFilter)
      .then((res) => {
        setLoading(false);
        let filterList = [];
        res.map((data, index) => {
          if (data) {
            data.index = index;
            data.key = `${index + 1}`;
            filterList.push(data);
          }
        });
        setCaseReportList(filterList);
      })
      .catch((err) => {
        setLoading(false);
        setCaseReportList([]);
      });
  };

  const columns = [
    {
      title: "Job check case Title ",
      dataIndex: "caseTitle",
      key: "caseTitle",
      render: (text, record, index) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {text || "Job Check"}
        </p>
      ),
    },
    {
      title: "Prepa Date",
      dataIndex: "prepadate",
      key: "prepadate",
      // width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record && record.prepadate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.prepadate)}
        </p>
      ),
    },
    {
      title: "Approved  Date ",
      dataIndex: "approvedDate",
      key: "approvedDate",
      // width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.approvedDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : yyyymmddFormate(record.approvedDate)}
        </p>
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      // width: "9.0909%",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record && record.expiryDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : record && yyyymmddFormate(record.expiryDate)}
        </p>
      ),
    },
    {
      title: "Job title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      // width: "9.0909%",
      render: (text, record, index) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {text}
        </p>
      ),
    },
  ];

  const onFinish = (values) => {
    getCaseReport(values);
  };

  const singleCient = (record, onExpand, expanded) => {
    onExpand(record);
    let keys = [];
    keys.push(record.key);
    setExpandedRowsEntriesKeys(keys);
    if (expanded) {
      setExpandedRowsEntriesKeys([]);
    }
    const getEmpJobOpt = {
      url: `v1/employer/job/${record.jobId}`,
    };

    getEmpJobOpt.types = [
      "GET_EMPLOYER_CASE_REPORT_SUCCESS",
      "GET_EMPLOYER_CASE_REPORT_FAILURE",
    ];
    apiRefresh
      .get(getEmpJobOpt)
      .then((res) => {
        let jList = [];
        jList.push(res);
        setEmpJobList(jList);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickJobs = () => {
    setActiveTab("Jobs");
  };

  const onClickJobCheck = () => {
    setActiveTab("Job Check");
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div className="border-box-emp-manag">
          <h4
            className="top-text heading-forms"
            style={{
              marginLeft: "40px !important",
              fontWeight: 600,
              fontSize: "14px",
              marginTop: "19px",
              fontFamily: "Poppins",
            }}
          >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
          {/* <div style={{ display: "flex", marginTop: 20 }}>
            <div
              className={
                activeTab === "Jobs"
                  ? "report-active-btn-tab"
                  : "report-inactive-btn-tab"
              }
              onClick={() => {
                onClickJobs();
              }}
            >
              <span className="pc-btn-text">Jobs</span>
            </div>
            <div
              className={
                activeTab === "Job Check"
                  ? "report-active-btn-tab"
                  : "report-inactive-btn-tab"
              }
              onClick={() => {
                onClickJobCheck();
              }}
            >
              <span className="pc-btn-text">Job Check</span>
            </div>
          </div> */}
          {/* {activeTab == "Jobs" && ( */}
          <>
            <div>
              <Form onFinish={onFinish} form={form}>
                <div
                  className="reminder-set-form text-style"
                  style={{ marginTop: 10 }}
                >
                  {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Form.Item
                      label="Visa Modified Date From"
                      name="dateFrom"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Visa Modified Date To"
                      className="d-block"
                      name="dateTo"
                    >
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row> */}
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Form.Item
                        label="Jobs"
                        name="jobs"
                        rules={[
                          {
                            required: false,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value="">
                            Select Job Check
                          </Select.Option>
                          {jcDropdwn &&
                            jcDropdwn.map((status, index) => (
                              <Select.Option value={status.id}>
                                {status.caseTitle || "Job Check"}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                    <Form.Item label="Clients" className="d-block" name="type">
                      <Select>
                        <Select.Option value="">Select</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col> */}
                  </Row>
                </div>
                <div
                  style={{
                    marginBottom: 45,
                    zIndex: 2,
                    position: "relative",
                    marginTop: 22,
                  }}
                  className="reminder-set-form  d-flex float-right"
                >
                  <Row gutter={12}>
                    <Col>
                      <Form.Item>
                        <Button
                          onClick={() => {
                            // handleSetDefault();
                            form.resetFields();
                            getCaseReport();
                          }}
                          className="employer-btn button-blue"
                          type="primary"
                        >
                          Set Default
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          className="employer-btn button-blue"
                          htmlType="submit"
                          type="primary"
                          // onClick={() => {
                          //   setShowTable(true);
                          //   setExportEmp(false);
                          // }}
                        >
                          Search
                        </Button>
                      </Form.Item>
                    </Col>
                    {/* <Col>
                    <Form.Item>
                      <Button
                        style={{ marginLeft: 10 }}
                        htmlType="submit"
                        type="primary"
                        onClick={() => {
                          setShowTable(false);
                          setExportEmp(true);
                        }}
                        className="button-blue"
                      >
                        Export
                      </Button>
                    </Form.Item>
                  </Col> */}
                  </Row>
                </div>
              </Form>
            </div>
            <Table
              expandedRowKeys={expandedRowsEntriesKeys}
              pagination={false}
              className="job-table table-head employer-tables border-3 school-table"
              dataSource={caseReportList}
              columns={columns}
              expandIconColumnIndex={5}
              expandable={{
                expandedRowRender: (record) => (
                  console.log("recordrecord00000000099999999999999==>", record),
                  (
                    <EmployerCaseJobTable
                      onGetEmployerJob={onGetEmployerJob}
                      employerJobRes={empJobList}
                      onAddEmployerJob={onAddEmployerJob}
                      userDataEmp={userDataEmp}
                      onGetTeamMember={onGetTeamMember}
                      teamMembers={teamMembers}
                      onUpdateEmployerJob={onUpdateEmployerJob}
                      onRemoveEmployerJob={onRemoveEmployerJob}
                      onGetClientSearch={onGetClientSearch}
                      clientSearchRes={clientSearchRes}
                      onSearchClient={onSearchClient}
                      searchClientData={searchClientData}
                      onGetClientByEmpId={onGetClientByEmpId}
                      empClientEmpRes={empClientEmpRes}
                      onLinkEmpWithClient={onLinkEmpWithClient}
                      onAddEmployerJobHistory={onAddEmployerJobHistory}
                      onGetSingleClientHis={onGetSingleClientHis}
                      singleJobHisRes={singleJobHisRes}
                      onGetVisaApplication={onGetVisaApplication}
                      visaAppData={visaAppData}
                    />
                  )
                ),

                expandIcon: ({ expanded, onExpand, record }) => (
                  <Fragment>
                    <div className="table-action">
                      <div style={{ margin: "2px" }}>
                        <Tooltip placement="topLeft" title={`Jobs`}>
                          <UserOutlined
                            style={{ fontSize: "15px" }}
                            onClick={(e) =>
                              singleCient(record, onExpand, expanded)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </Fragment>
                ),
              }}
            />
          </>
          {/* )} */}
        </div>
      </Spin>
    </Fragment>
  );
};

export default EmployerCaseReport;
