import introCQ from "../VideoThumbnail/YoutubeThumbnails/Introduction to Custom Questionnaires.png";
import wq from "../VideoThumbnail/YoutubeThumbnails/What is Required.png";
import wc from "../VideoThumbnail/YoutubeThumbnails/What is Cascading.png";
import wma from "../VideoThumbnail/YoutubeThumbnails/What is Multiple Answers.png";
import ismul from "../VideoThumbnail/YoutubeThumbnails/Is Multiple.png";
import odt from "../VideoThumbnail/YoutubeThumbnails/Other Data Types.png";
import ecqe from "../VideoThumbnail/YoutubeThumbnails/Email Content - Questionnaire Links.png";
import wsml from "../VideoThumbnail/YoutubeThumbnails/Website and Social Media Links.png";
import desm from "../VideoThumbnail/YoutubeThumbnails/How to change Display Email address for Social MediaWebsite.png";
import logSignin from "../VideoThumbnail/YoutubeThumbnails/HOW TO  LOGIN OR SIGN IN IN EZYMIGRATE SYSTEM.png";
import creAcc from "../VideoThumbnail/YoutubeThumbnails/How to create account.png";
import chngPassForgot from "../VideoThumbnail/YoutubeThumbnails/Change Pasword - Password Link.png";
import addTm from "../VideoThumbnail/YoutubeThumbnails/How to add a team member.png";
import gaPerm from "../VideoThumbnail/YoutubeThumbnails/How to give or add permissions to team members.png";
import crBrnch from "../VideoThumbnail/YoutubeThumbnails/How to create a Branch.png";
import passOU from "../VideoThumbnail/YoutubeThumbnails/How to change password for the other users.png";
import oPermPass from "../VideoThumbnail/YoutubeThumbnails/How users with owner permissions can change their own password.png";
import chngBrnchCompInfo from "../VideoThumbnail/YoutubeThumbnails/How to change branch or company information.png";
import chngCurBnkInfo from "../VideoThumbnail/YoutubeThumbnails/How to change currency and bank info.png";
import chngEmlSgntr from "../VideoThumbnail/YoutubeThumbnails/How to change my email signature.png";
import emlIntg365 from "../VideoThumbnail/YoutubeThumbnails/Office 365 Email Integration.png";
import dMlSet from "../VideoThumbnail/YoutubeThumbnails/Daily Mail Settings.png";
import dMlAssTsk from "../VideoThumbnail/YoutubeThumbnails/Daily Emails for Assigned Task.png";
import dMlPClnt from "../VideoThumbnail/YoutubeThumbnails/Daily Emails for Potential Client.png";
import dMlImprt from "../VideoThumbnail/YoutubeThumbnails/Daily Emails for EMAIL IMPORT.png";
import dMlClntFile from "../VideoThumbnail/YoutubeThumbnails/Daily Emails for CLIENT FILE.png";
import addClient from "../VideoThumbnail/YoutubeThumbnails/Adding a Client File.png";
import updClntFile from "../VideoThumbnail/YoutubeThumbnails/HOW TO UPDATE A CLIENT FILE.png";
import updClntPrtnr from "../VideoThumbnail/YoutubeThumbnails/HOW TO ADD A CLIENT PARTNER.png";
import updFmlyMbr from "../VideoThumbnail/YoutubeThumbnails/HOW TO ADD A FAMILY MEMBERS.png";
import updClntVsa from "../VideoThumbnail/YoutubeThumbnails/How to update client visa information.png";
import cusVsaTypSts from "../VideoThumbnail/YoutubeThumbnails/How can I customized Visa types or status.png";
import lnkDocVsa from "../VideoThumbnail/YoutubeThumbnails/How can I link documents with Visa types.png";
import lnkEmlVsa from "../VideoThumbnail/YoutubeThumbnails/How can I link emails with Visa types.png";
import lnkFnVsa from "../VideoThumbnail/YoutubeThumbnails/How can I link file notes with the Visa types.png";
import lnkMTOneApp from "../VideoThumbnail/YoutubeThumbnails/How can I link more than one applicant in a Visa case.png";
import svUpdVsaInfo from "../VideoThumbnail/YoutubeThumbnails/How to saveupdate visa related information into the system.png";
import addDocClnt from "../VideoThumbnail/YoutubeThumbnails/How to add a document under client file.png";
import assDocPertDocTyp from "../VideoThumbnail/YoutubeThumbnails/How can you assign a document to a particular document type.png";
import cusDocTyp from "../VideoThumbnail/YoutubeThumbnails/How can you customized document type in the system.png";
import folListView from "../VideoThumbnail/YoutubeThumbnails/Folder view and list view of the Document section.png";
import chDocUndrFol from "../VideoThumbnail/YoutubeThumbnails/How to characterize a document under a folder or with document type.png";
import updDocName from "../VideoThumbnail/YoutubeThumbnails/How to update a document name.png";
import conImgFile from "../VideoThumbnail/YoutubeThumbnails/How to convert an image file into PDF.png";
import mrgMulPdf from "../VideoThumbnail/YoutubeThumbnails/How to merge multiple PDF document.png";
import spltPdf from "../VideoThumbnail/YoutubeThumbnails/How to split PDF document.png";
import clntDocUpldChklst from "../VideoThumbnail/YoutubeThumbnails/How can your client upload documents in document checklist.png";
import crtDocChklst from "../VideoThumbnail/YoutubeThumbnails/How to create document checklist.png";
import sendEml from "../VideoThumbnail/YoutubeThumbnails/How to send email.png";
import useLtrCntrctKeys from "../VideoThumbnail/YoutubeThumbnails/HOW TO USE LETTER TEMPLATES, CONTRACTS AND USE OF DYNAMIC KEYS.png";
import attchDocEml from "../VideoThumbnail/YoutubeThumbnails/HOW CAN I ATTACH DOCUMENTS WITH THE EMAILS.png";
import attchLtrTemp from "../VideoThumbnail/YoutubeThumbnails/How can I automatically link attachments to a letter template.png";
import crtLtrTmpKeys from "../VideoThumbnail/YoutubeThumbnails/How to create letter templates with use of dynamic keys.png";
import crtCntrctDgtlSgntr from "../VideoThumbnail/YoutubeThumbnails/How to create contracts with dig. signature and get my client sign it digitally.png";
import mnlImprtEml from "../VideoThumbnail/YoutubeThumbnails/How can I manually import emails.png";
import autEmlImprt from "../VideoThumbnail/YoutubeThumbnails/HOW TO TURN ON AUTOMATIC EMAIL IMPORT.png";
import activityRelClnt from "../VideoThumbnail/YoutubeThumbnails/Activities relevant to client file.png";
import sysWdActicts from "../VideoThumbnail/YoutubeThumbnails/System wide activites.png";
import addUpdFn from "../VideoThumbnail/YoutubeThumbnails/How to add and update File notes.png";
import crtFnTmp from "../VideoThumbnail/YoutubeThumbnails/How to create File note Template.png";
import cnvrtFnTsk from "../VideoThumbnail/YoutubeThumbnails/How to convert File notes into Task.png";
import sndSms from "../VideoThumbnail/YoutubeThumbnails/How to send SMS.png";
import useClntTgs from "../VideoThumbnail/YoutubeThumbnails/How to use client tags.png";
import crtClntTgs from "../VideoThumbnail/YoutubeThumbnails/How to create client tags.png";
import useMngTsk from "../VideoThumbnail/YoutubeThumbnails/How to use or manage task.png";
import crtTsk from "../VideoThumbnail/YoutubeThumbnails/How to create Task.png";
import mngTskClntFile from "../VideoThumbnail/YoutubeThumbnails/How you can manage task under a client file.png";
import allAbtAccSet from "../VideoThumbnail/YoutubeThumbnails/All about account settings.png";
import cerSmplTxInv from "../VideoThumbnail/YoutubeThumbnails/How to create a simple tax invoice.png";
import crtSndRcpt from "../VideoThumbnail/YoutubeThumbnails/How to create and send a receipt.png";
import advPymtInv from "../VideoThumbnail/YoutubeThumbnails/How can I create an Advance Payment Invoice.png";
import crtInvWOTx from "../VideoThumbnail/YoutubeThumbnails/How to create Invoice without tax.png";
import benImprtncTmpInv from "../VideoThumbnail/YoutubeThumbnails/What are the benefits and importance of Stage-wise Templated Invoices.png";
import crtDrpInv from "../VideoThumbnail/YoutubeThumbnails/How to create and drop stage-wise Templated Invoices.png";
import crtDpstInv from "../VideoThumbnail/YoutubeThumbnails/How to create deposit invoices and a deposit receipt using the templated invoice.png";
import crtGnrtNwInv from "../VideoThumbnail/YoutubeThumbnails/How you can create or generate new invoices stagewise.png";
import crtFuInv from "../VideoThumbnail/YoutubeThumbnails/How you can create follow-up invoice for Stagewise Templated invoices.png";
import dltStgwsInv from "../VideoThumbnail/YoutubeThumbnails/How to delete stagewise templated invoice.png";
import incorClntAccBlnc from "../VideoThumbnail/YoutubeThumbnails/How can you incorporate client_s account balance into your agreement.png";
import tipFrmtEmlLnk from "../VideoThumbnail/YoutubeThumbnails/Tips on styling and formatting email link templates.png";
import cstEmlLnkTmp from "../VideoThumbnail/YoutubeThumbnails/How to customize email link template for Invoice Email.png";
import cstFolowupEml from "../VideoThumbnail/YoutubeThumbnails/How to customize email link template for Invoice Follow-up Email.png";
import cstsmplEmlNot from "../VideoThumbnail/YoutubeThumbnails/How to customize email link template for simple Email Notification.png";
import cstsmplQuesLnk from "../VideoThumbnail/YoutubeThumbnails/How to customize email link template for Questionnaire Link.png";
import cstsmplVSNot from "../VideoThumbnail/YoutubeThumbnails/How to customize email link template for Update Visa Status Notification.png";
import prntCsWrk from "../VideoThumbnail/YoutubeThumbnails/How does Print Case work.png";
import introSMSys from "../VideoThumbnail/YoutubeThumbnails/Introduction to School Management system.png";
import addUniSMSys from "../VideoThumbnail/YoutubeThumbnails/How to add universities in school records.png";
import introDboard from "../VideoThumbnail/YoutubeThumbnails/INTRODUCTION TO DASHBOARD BI (BUSINESS INSIGHTS).png";
import aboutCDboard from "../VideoThumbnail/YoutubeThumbnails/ABOUT CLIENT  DASHBOARD.png";
import addCrtChklstase from "../VideoThumbnail/YoutubeThumbnails/HOW YOU CAN ADD OR CREATE CHECKLIST TO BE ASSIGNED TO A CASE.png";
import addCase from "../VideoThumbnail/YoutubeThumbnails/HOW DO YOU ADD CASES IN CASE MANAGEMENT.png";
import addMntnSCom from "../VideoThumbnail/YoutubeThumbnails/HOW DO YOU ADD OR MAINTAIN SCHOOL COMMISSION.png";
import mngAddStuf from "../VideoThumbnail/YoutubeThumbnails/HOW TO MANAGE ADMISSION AND ADMISSION RELATED  RECORDS.png";
import invSchCom from "../VideoThumbnail/YoutubeThumbnails/HOW YOU CAN INVOICE SCHOOL BASED ON THE COMMISSION.png";
import stdDshWrks from "../VideoThumbnail/YoutubeThumbnails/HOW DOES STUDENT DASHBOARD WORKS.png";
import intriEmpMng from "../VideoThumbnail/YoutubeThumbnails/INTRODUCTION TO EMPLOYER MANAGEMENT.png";
import crtEmp from "../VideoThumbnail/YoutubeThumbnails/HOW TO CREATE OR ADD AN EMPLOYER FILE.png";
import mngMultiEmp from "../VideoThumbnail/YoutubeThumbnails/HOW TO MANAGE MULTIPLE EMPLOYERS IN THE DASHBOARD.png";
import invTempBrnch from "../VideoThumbnail/YoutubeThumbnails/Making Invoice Templates Available to a Specific Branch.png";
import addPcStatus from "../VideoThumbnail/YoutubeThumbnails/How to add or create potential client statuses.png";
import updEmpAccAndRep from "../VideoThumbnail/YoutubeThumbnails/How to update employer client file accreditation status and generate reports.png";
import jobChckAndLink from "../VideoThumbnail/YoutubeThumbnails/How to manage Job check case and link it with Job information.png";
import multiVisaCountries from "../VideoThumbnail/YoutubeThumbnails/HOW TO ADD MULTIPLE VISA COUNTRIES IN EZYMIGRATE SYSTEM.png";
import sendBulkEmail from "../VideoThumbnail/YoutubeThumbnails/HOW TO SEND BULK EMAIL IN EZYMIGRATE.png";
import coverGenius from "../VideoThumbnail/YoutubeThumbnails/HOW TO USE COVER GENIUS IN EZYMIGRATE SYSTEM.png";
import dealsAndPipeline from "../VideoThumbnail/YoutubeThumbnails/HOW TO CREATE PIPELINES AND DEALS IN EZYMIGRATE SYSTEM.png";
import mngReminder from "../VideoThumbnail/YoutubeThumbnails/HOW TO MANAGE REMINDER SETTINGS IN EZYMIGRATE SYSTEM.png";
import imapIntegration from "../VideoThumbnail/YoutubeThumbnails/INTEGRATION THROUGH IMAP - GMAIL.png";
import introCaseMngmnt from "../VideoThumbnail/YoutubeThumbnails/INTRODUCTION TO CASE MANAGEMENT SYSTEM.png";
import intrgrtMailChimp from "../VideoThumbnail/YoutubeThumbnails/HOW TO  INTEGRATE MAILCHIMP IN EZYMIGRATE AND USE IT IN DEALS.png";
import intrgrtSendGrid from "../VideoThumbnail/YoutubeThumbnails/HOW TO  INTEGRATE SENDGRID IN EZYMIGRATE AND USE IT IN DEALS.png";

const ModuleLinks = [
  {
    name: "Login",
    links: [
      {
        topic: "How to Login/Signin",
        thumbNail: logSignin,
        url: "https://youtu.be/E6zuEHAKstg",
      },
      {
        topic: "How to create an account",
        thumbNail: creAcc,
        url: "https://youtu.be/mpObt_TeoUg",
      },
      {
        topic: "How to change password  through forgot password link ",
        thumbNail: chngPassForgot,
        url: "https://youtu.be/5FIic6N6ab4",
      },
    ],
  },
  {
    name: "Super User Settings",
    links: [
      {
        topic: "How to Add a team member",
        thumbNail: addTm,
        url: "https://youtu.be/uEN9rGdBEx8",
      },
      {
        topic: "How to give/add  permissions to team memebers",
        thumbNail: gaPerm,
        url: "https://youtu.be/4Q2xOEcfkrk",
      },
      {
        topic: "How to create a branch ",
        thumbNail: crBrnch,
        url: "https://youtu.be/L_OEtLtb1eo",
      },
      {
        topic: "How to change password for the other users ",
        thumbNail: passOU,
        url: "https://youtu.be/1JT05GUort4",
      },
      {
        topic:
          "How  users with owner permissions can chnage their own password ",
        thumbNail: oPermPass,
        url: "https://youtu.be/843q5QireqY",
      },
      {
        topic:
          "How to change branch/company information  and its important on external links like questioniare, document",
        thumbNail: chngBrnchCompInfo,
        url: "https://youtu.be/ZoX9XEp3WWA",
      },
      {
        topic: "How to chnage default currency and bank information",
        thumbNail: chngCurBnkInfo,
        url: "https://youtu.be/16aDs4nb3W0",
      },
      {
        topic: "How to change or update my email signature",
        thumbNail: chngEmlSgntr,
        url: "https://youtu.be/R1NrMeqhstw",
      },
      {
        topic: "How to do email integration with the system",
        thumbNail: emlIntg365,
        url: "https://youtu.be/YL0lATToevY",
      },
      {
        topic: "Integration through IMAP - e.g GMAIL account",
        thumbNail: imapIntegration,
        url: "https://youtu.be/Kg2jgoAfMTU",
      },
      {
        topic: "office365 email integration ( Outh2 )",
        thumbNail: emlIntg365,
        url: "https://youtu.be/ChUZnoSQLV8",
      },
      {
        topic: " How to manage daily automatic emails from teh system",
        thumbNail: dMlSet,
        url: "https://youtu.be/7QDD_QzBUH0",
      },
      {
        topic: "Send daily email for assigned task(s)",
        thumbNail: dMlAssTsk,
        url: "https://youtu.be/NtqH9P8XezM",
      },
      {
        topic:
          "Send notification when a potential client file is assigned to a processing person",
        thumbNail: dMlPClnt,
        url: "https://youtu.be/yLo_Oe8hmAQ",
      },
      {
        topic:
          "Send notification on email import if you are a processing person",
        thumbNail: dMlImprt,
        url: "https://youtu.be/0t79s_xMLgs",
      },
      {
        topic:
          "Send notification when a client file is assigned to a processing person",
        thumbNail: dMlClntFile,
        url: "https://youtu.be/vlf-f0zkk5M",
      },
    ],
  },

  {
    name: "Account Settings",
    links: [
      {
        topic: "All about account settings",
        thumbNail: allAbtAccSet,
        url: "https://youtu.be/8V1p64RwDnc",
      },
      {
        topic: "How to create a simple tax invoice",
        thumbNail: cerSmplTxInv,
        url: "https://youtu.be/Y3xE5OA4P1g",
      },
      {
        topic: "How to create and send a receipt",
        thumbNail: crtSndRcpt,
        url: "https://youtu.be/kTp3y3Ul3_E",
      },
      {
        topic: "How can I create an Advance Payment Invoice",
        thumbNail: advPymtInv,
        url: "https://youtu.be/ZQ2LLt_Znf8",
      },
      {
        topic: "How to create Invoice without tax",
        thumbNail: crtInvWOTx,
        url: "https://youtu.be/RLFh-OLlmzc",
      },
      {
        topic: "How to create template for Tax Invoice",
        thumbNail: crtInvWOTx, // Pending
        url: "https://youtu.be/TGo0HHo5yC0",
      },
      {
        topic: "How to create a follow-up for your Invoice",
        thumbNail: advPymtInv, // Pending
        url: "https://youtu.be/zBePHMesvsY",
      },
      {
        topic: "How to manage stages/milestones using simple invoices",
        thumbNail: crtSndRcpt, // Pending
        url: "https://youtu.be/5B0V8oph0-Q",
      },
      {
        topic: "What are Outgoing Payments and Account Flows",
        thumbNail: crtSndRcpt, // Pending
        url: "https://youtu.be/Jy5SstXniFA",
      },
      {
        topic: "How to manage due or overdue invoices",
        thumbNail: logSignin,
        url: "https://youtu.be/dmIJTY5X_KY",
      },
      {
        topic: "Making Invoice Templates Available to a Specific Branch",
        thumbNail: invTempBrnch,
        url: "https://youtu.be/gLU5YTzlUCM",
      },
      {
        topic: "How to add multiple visa countries in system",
        thumbNail: multiVisaCountries,
        url: "https://youtu.be/cYlssctf0Sw",
      },
    ],
  },
  {
    name: "Client File/Profile",
    links: [
      {
        topic: "Adding a client",
        thumbNail: addClient,
        url: "https://youtu.be/cRQpUyVR4oQ",
      },
      {
        topic: "How to update a client file",
        thumbNail: updClntFile,
        url: "https://youtu.be/iGlWSSRh98c",
      },
      {
        topic: "How to add client partner",
        thumbNail: updClntPrtnr,
        url: "https://youtu.be/Wz3YMsjrXxg",
      },
      {
        topic: "How to add family members",
        thumbNail: updFmlyMbr,
        url: "https://youtu.be/Bccw0mjtmQg",
      },
    ],
  },
  {
    name: "Visa Related",
    links: [
      {
        topic: "How to update client visa information",
        thumbNail: updClntVsa,
        url: "https://youtu.be/lLiOba3iEU4",
      },
      {
        topic: "How can I customized Visa types or status",
        thumbNail: cusVsaTypSts,
        url: "https://youtu.be/DzTaWo3weeg",
      },
      {
        topic: "How can I link documents with Visa types",
        thumbNail: lnkDocVsa,
        url: "https://youtu.be/pb-LCvIuNEs",
      },
      {
        topic: "How can I link emails with Visa types",
        thumbNail: lnkEmlVsa,
        url: "https://youtu.be/XnwRexal5mM",
      },
      {
        topic: "How can I link file notes with the Visa types",
        thumbNail: lnkFnVsa,
        url: "https://youtu.be/IeIUqwwLjCk",
      },
      {
        topic: "How can I link more than one applicant in a Visa case",
        thumbNail: lnkMTOneApp,
        url: "https://youtu.be/hCNkMMmdCmI",
      },
      {
        topic: "How to save/update visa related information into the system",
        thumbNail: svUpdVsaInfo,
        url: "https://youtu.be/--XMMG6iYzU",
      },
      {
        topic: "How to add or create potential client statuses",
        thumbNail: addPcStatus,
        url: "https://youtu.be/4otjJqgkRpU",
      },
    ],
  },
  {
    name: "Documents",
    links: [
      {
        topic: "How to add a document under client file",
        thumbNail: addDocClnt,
        url: "https://youtu.be/7yIpugKJDP0",
      },
      {
        topic: "How can you assign a document to a particular document type",
        thumbNail: assDocPertDocTyp,
        url: "https://youtu.be/SJWx_jJQ0MU",
      },
      {
        topic: "How can you customized document type in the system",
        thumbNail: cusDocTyp,
        url: "https://youtu.be/KnmDUCt9hi8",
      },
      {
        topic: "Folder view and list view of the Document section",
        thumbNail: folListView,
        url: "https://youtu.be/fYsT1-bFqNw",
      },
      {
        topic:
          "How to charactized a document under a folder or with document type",
        thumbNail: chDocUndrFol,
        url: "https://youtu.be/1Qi3mJ9BcgE",
      },
      {
        topic: "How to update a document name",
        thumbNail: updDocName,
        url: "https://youtu.be/x4HgDgkFXbo",
      },
      {
        topic: "How to convert an image file into PDF",
        thumbNail: conImgFile,
        url: "https://youtu.be/68279ZtQ_0k",
      },
      {
        topic: "How to merge multiple PDF document",
        thumbNail: mrgMulPdf,
        url: "https://youtu.be/3riE-UHG8yI",
      },
      {
        topic: "How to split PDF document",
        thumbNail: spltPdf,
        url: "https://youtu.be/oXMVGOecbi8",
      },
      {
        topic: "How can your client upload documents in document checklist",
        thumbNail: clntDocUpldChklst,
        url: "https://youtu.be/QaZ9_J34hfo",
      },
      {
        topic: "How to create document checklist",
        thumbNail: crtDocChklst,
        url: "https://youtu.be/cTZGZxgt48s",
      },
    ],
  },
  {
    name: "Email Sending",
    links: [
      {
        topic: "How to send email",
        thumbNail: sendEml,
        url: "https://youtu.be/waTYsN-NF1o",
      },
      {
        topic: "How to use letter templates, contracts and use of dynamic keys",
        thumbNail: useLtrCntrctKeys,
        url: "https://youtu.be/S082Fpy_R5Y",
      },
      {
        topic: "How can I attached documents with the emails",
        thumbNail: attchDocEml,
        url: "https://youtu.be/AeegtsPzlDs",
      },
      {
        topic: "How can I automatically link attachments to a letter template",
        thumbNail: attchLtrTemp,
        url: "https://youtu.be/6IQvi5bQ-Co",
      },
      {
        topic: "How to create letter templates with use of dynamic keys",
        thumbNail: crtLtrTmpKeys,
        url: "https://youtu.be/9x4MZVSdi40",
      },
      {
        topic:
          "How to create contracts with digital signature and get my client sign it digitally",
        thumbNail: crtCntrctDgtlSgntr,
        url: "https://youtu.be/txdTGJmo-Sg",
      },
    ],
  },
  {
    name: "Email Import",
    links: [
      {
        topic: "How can I manually import emails",
        thumbNail: mnlImprtEml,
        url: "https://youtu.be/2PXEe5rhjNU",
      },
      {
        topic: "How to turn on automatic email import",
        thumbNail: autEmlImprt,
        url: "https://youtu.be/OTqDLLgBaHc",
      },
    ],
  },
  {
    name: "User Activity",
    links: [
      {
        topic: "Activities relevant to client file",
        thumbNail: activityRelClnt,
        url: "https://youtu.be/NZOW6aOMUmc",
      },
      {
        topic: "System wide activities relevant to all system users",
        thumbNail: sysWdActicts,
        url: "https://youtu.be/g4kXwYqttBc",
      },
    ],
  },
  {
    name: "File Notes",
    links: [
      {
        topic: "How to add and update File notes",
        thumbNail: addUpdFn,
        url: "https://youtu.be/gxR4zKnHrlM",
      },
      {
        topic: "How to create File note Template",
        thumbNail: crtFnTmp,
        url: "https://youtu.be/rwT_jzIxzwg",
      },
      {
        topic: "How to convert File notes into Task",
        thumbNail: cnvrtFnTsk,
        url: "https://youtu.be/hBiPKOAZwJE",
      },
    ],
  },
  {
    name: "Stage-wise Templated Invoices",
    links: [
      {
        topic:
          "What are the benefits and importance of Stage-wise Templated Invoices?",
        thumbNail: benImprtncTmpInv,
        url: "https://youtu.be/DlZi4Ezh6kg",
      },
      {
        topic: "How to create and drop stage-wise Templated Invoices",
        thumbNail: crtDrpInv,
        url: "https://youtu.be/u6EDs2hy-g0",
      },
      {
        topic:
          "How to create deposit invoices and a deposit receipt using the templated invoice",
        thumbNail: crtDpstInv,
        url: "https://youtu.be/U6T6WzcoXwg",
      },
      {
        topic: "How you can create or generate new invoices stagewise",
        thumbNail: crtGnrtNwInv,
        url: "https://youtu.be/zmVCxz7oA-Y",
      },
      {
        topic:
          "How you can create follow-up invoice for Stagewise Templated invoices",
        thumbNail: crtFuInv,
        url: "https://youtu.be/Q3FRaqS8l28",
      },
      {
        topic: "How to delete stagewise templated invoice",
        thumbNail: dltStgwsInv,
        url: "https://youtu.be/G42gORwE1xs",
      },
      {
        topic:
          "How can you incorporate client's account balance into your agreement",
        thumbNail: incorClntAccBlnc,
        url: "https://youtu.be/gv6aY95BrFM",
      },
    ],
  },
  {
    name: "Custom Questionnaire",
    links: [
      {
        topic: "Introduction to Custom Questionnaires",
        thumbNail: introCQ,
        url: "https://youtu.be/Gmkf0-lo6-k",
      },
      {
        topic:
          'What is "Required" and how does it work in Custom Questionnaires?',
        thumbNail: wq,
        url: "https://youtu.be/VuzHAFPJCuQ",
      },
      {
        topic:
          "What is “Cascading” and how does it work in Custom Questionnaires?",
        thumbNail: wc,
        url: "https://youtu.be/kEiL1wSElZk",
      },
      {
        topic:
          "What is “Multiple Answers” and how does it work in Custom Questionnaires?",
        thumbNail: wma,
        url: "https://youtu.be/rIIftNOknNg",
      },
      {
        topic: 'What is "Is Multiple" and how does it work?',
        thumbNail: ismul,
        url: "https://youtu.be/483gDTFz8Wg",
      },
      {
        topic: "What are the other data types in custom questionnaires?",
        thumbNail: odt,
        url: "https://youtu.be/ty6T6Sidlg8",
      },
      {
        topic:
          "How to edit email content that goes with the Questionnaire Links",
        thumbNail: ecqe,
        url: "https://youtu.be/iraJ6txeGWw",
      },
      {
        topic: "Website and/or Social Media Links",
        thumbNail: wsml,
        url: "https://youtu.be/TPrEGV3pO3w",
      },
      //   {
      //     topic: "Website and/or Social Media Links Pt. 2",
      //     thumbNail: "",
      //     url: "",
      //   },
      //   {
      //     topic: "How to Add Declaration",
      //     thumbNail: "",
      //     url: "",
      //   },
      {
        topic:
          "How to change display email for website/social media questionnaire links",
        thumbNail: desm,
        url: "https://www.youtube.com/watch?v=aYSZ4WLJEoM",
      },
    ],
  },
  {
    name: "SMS",
    links: [
      {
        topic: "How to send SMS",
        thumbNail: sndSms,
        url: "https://youtu.be/rr1sN_Xrb7Y",
      },
    ],
  },
  {
    name: "Tasks",
    links: [
      {
        topic: "How to use or manage task",
        thumbNail: useMngTsk,
        url: "https://youtu.be/lH9DTEBLK3U",
      },
      {
        topic: "How to create Task",
        thumbNail: crtTsk,
        url: "https://youtu.be/nzBcRscFUFI",
      },
      {
        topic: "How you can manage task under a client file",
        thumbNail: mngTskClntFile,
        url: "https://youtu.be/bNaFC-QpoHE",
      },
    ],
  },
  {
    name: "Tags",
    links: [
      {
        topic: "How to use client tags",
        thumbNail: useClntTgs,
        url: "https://youtu.be/lrmtRdPyMjA",
      },
      {
        topic: "How to create client tags",
        thumbNail: crtClntTgs,
        url: "https://youtu.be/0Pjm4OAXRmA",
      },
    ],
  },

  {
    name: "Email Content",
    links: [
      {
        topic: "Tips on styling and formatting email link templates",
        thumbNail: tipFrmtEmlLnk,
        url: "https://youtu.be/0bzCbpNGpvc",
      },
      {
        topic: "How to customize email link template for Invoice Email",
        thumbNail: cstEmlLnkTmp,
        url: "https://youtu.be/QfIFpcfyYJA",
      },
      {
        topic:
          "How to customize email link template for Invoice Follow-up Email",
        thumbNail: cstFolowupEml,
        url: "https://youtu.be/KHJQf6fu2jc",
      },
      {
        topic:
          "How to customize email link template for simple Email Notification",
        thumbNail: cstsmplEmlNot,
        url: "https://youtu.be/Sa424bPOlmg",
      },
      {
        topic: "How to customize email link template for Questionnaire Link",
        thumbNail: cstsmplQuesLnk,
        url: "https://youtu.be/Jq6DJAyrrS0",
      },
      {
        topic:
          "How to customize email link template for Update Visa Status Notification",
        thumbNail: cstsmplVSNot,
        url: "https://youtu.be/bFiLoFAx8CE",
      },
      {
        topic: "How does Print Case work",
        thumbNail: prntCsWrk,
        url: "https://youtu.be/S5ahW8N5h6A",
      },
    ],
  },
  {
    name: "Dashboard",
    links: [
      {
        topic: "Introduction to Dashboard BI",
        thumbNail: introDboard,
        url: "https://youtu.be/UuJ5NL2Cmt4",
      },
      {
        topic: "About Client Dashboard",
        thumbNail: aboutCDboard,
        url: "https://youtu.be/qQwAH-1hBts",
      },
    ],
  },
  {
    name: "Case Management",
    links: [
      {
        topic: "Introduction to Case Management System",
        thumbNail: introCaseMngmnt,
        url: "https://youtu.be/UZES2QHeSJY",
      },
      {
        topic: "How you can add/create checklist to be assigned to a case",
        thumbNail: addCrtChklstase,
        url: "https://youtu.be/vl7YubsNBpg",
      },
      {
        topic: "How do you add cases in Case Management",
        thumbNail: addCase,
        url: "https://youtu.be/7NYGPZgE6l8",
      },
    ],
  },
  {
    name: "School Management",
    links: [
      {
        topic: "Introduction to School Management system",
        thumbNail: introSMSys,
        url: "https://youtu.be/vJq_f3iiUlk",
      },
      {
        topic: "How to add universities in school records",
        thumbNail: addUniSMSys,
        url: "https://youtu.be/UQ_8ubA0Q1E",
      },
      {
        topic: "How do you add/mantain school comission",
        thumbNail: addMntnSCom,
        url: "https://youtu.be/LXNi9oIo5y8",
      },
      {
        topic: "How to manage admission and admission related records",
        thumbNail: mngAddStuf,
        url: "https://youtu.be/4V_z15Tzdh4",
      },
      {
        topic: "How you can invoice school based on the commission",
        thumbNail: invSchCom,
        url: "https://youtu.be/ns6zD_rGX9k",
      },
      {
        topic: "How does the student dashboard works",
        thumbNail: stdDshWrks,
        url: "https://youtu.be/Vwri55KoGhk",
      },

      // Pending
      // {
      //   topic: "How to use school commission reminders",
      //   thumbNail:
      //     logSignin,
      //   url: "",
      // },
    ],
  },
  {
    name: "Employer Management",
    links: [
      {
        topic: "Introduction to Employer Management",
        thumbNail: intriEmpMng,
        url: "https://youtu.be/JyBbfm68EKQ",
      },
      {
        topic: "How to create or add an employer file",
        thumbNail: crtEmp,
        url: "https://youtu.be/9dX1o4Ol-Gs",
      },
      {
        topic: "How to manage multiple employers in the dashboard",
        thumbNail: mngMultiEmp,
        url: "https://youtu.be/FG_hvVvIk_c",
      },
      {
        topic: "Learn more about Employer Management System",
        thumbNail: mngMultiEmp,
        url: "https://youtu.be/inufjCfeUSk",
      },
      {
        topic:
          "How to update Employer client file Accreditation status and generate report",
        thumbNail: updEmpAccAndRep,
        url: "https://youtu.be/TV-MTTUCFDM",
      },
      {
        topic: "How to manage Job Check Case and link it with Job Information",
        thumbNail: jobChckAndLink,
        url: "https://youtu.be/jWtbQkjxOXw",
      },
    ],
  },
  {
    name: "Bulk Email",
    links: [
      {
        topic: "How to Send Bulk Email",
        thumbNail: sendBulkEmail,
        url: "https://youtu.be/pJfoYLB5gGA",
      },
    ],
  },
  {
    name: "Cover Genius",
    links: [
      {
        topic: "How to use Cover Genius in Ezymigrate System",
        thumbNail: coverGenius,
        url: "https://youtu.be/5Xv19Yenwx8",
      },
    ],
  },
  {
    name: "Pipelines and Deals",
    links: [
      {
        topic: "How to create Pipelines and Deals in Ezymigrate system",
        thumbNail: dealsAndPipeline,
        url: "https://youtu.be/ZmtV7v0ywCY",
      },
      {
        topic:
          "How to integrate Mailchimp in Ezymigrate and use it in Deals and Pipelines",
        thumbNail: intrgrtMailChimp,
        url: "https://youtu.be/M70H5MaWym4",
      },
      {
        topic:
          "How to integrate SendGrid in Ezymigrate and use it in Deals and Pipelines",
        thumbNail: intrgrtSendGrid,
        url: "https://youtu.be/chLffSaz95s",
      },
    ],
  },
  {
    name: "Reminder Settings",
    links: [
      {
        topic: "How to manage Reminder Settings in Ezymigrate",
        thumbNail: mngReminder,
        url: "https://youtu.be/7A75__wZvhE",
      },
    ],
  },
];

export default ModuleLinks;
