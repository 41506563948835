import React from "react";
import { saveAs } from "file-saver";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import {
  sendDynamicLinkEmail,
  getPDF,
  getPDFInvoicePayment,
  checkDuplicateClientInvoice,
  editInvoice,
  sendDynamicLinkEmailBlob,
  getEmailContent,
  setEmailTemplate,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import activityData from "../Components/ClientActivity/ActivityData";
import { apiRefresh } from "../services/api";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import { getFilterList } from "../Common/getEmailContentList";

const { Option } = Select;

const layoutSendingReceipt = {
  labelCol: { span: 8, offset: 0 },
  wrapperCol: { span: 20, offset: 2 },
};

class VisaUpdateEmail extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sendingReceiptEmail: "",
      loading: false,
      resultList: [],
    };
  }

  componentDidMount() {
    let _emails =
      this.props.clientProfileData &&
      this.props.clientProfileData.emails &&
      this.props.clientProfileData.emails;
    let setEmailData = this.props.setEmailData;
    this.setState({
      sendingReceiptEmail: setEmailData && setEmailData.htmlTemplate,
    });
    let toEmail;
    if (_emails && _emails.length > 0) {
      for (let index = 0; index < _emails.length; index++) {
        if (this.props && this.props.showSecondaryEmail === true) {
          if (_emails[index].emailTypeId === 2) {
            toEmail = _emails[index].address;
          }
        } else {
          if (_emails[index].emailTypeId === 1) {
            toEmail = _emails[index].address;
          }
        }
      }
    }
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.emailTemplateRes != this.props.emailTemplateRes) {
      let profileData = JSON.parse(sessionStorage.getItem("profileData"));
      this.setState({
        sendingReceiptEmail:
          (this.props.emailTemplateRes &&
            this.props.emailTemplateRes.htmlTemplate) ||
          "",
      });
      this.formRef.current.setFieldsValue({
        from: localStorage.getItem("selectedBranchName"),
        to: profileData ? profileData.primaryEmail : "",
        subject: `Status for your application ${this.props.visaTypeName} is changed to ${this.props.caseStatusName}`,
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  onFinishSendingReceipt = async (values) => {
    let _from = values.from === undefined ? "" : values.from;
    let _to = values.to === undefined ? "" : values.to;
    let _cc = values.cc === undefined ? "" : values.cc;
    let _bcc = values.bcc === undefined ? "" : values.bcc;
    let _subject = values.subject === undefined ? "" : values.subject;
    const formData = new FormData();
    formData.append("Message", this.state.sendingReceiptEmail);
    formData.append("Subject", _subject);
    formData.append("From", _from);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "client");
    formData.append("ClientId", this.props.clientId);
    formData.append("ModuleId", "10");
    formData.append(`Recipients[0].name`, _to);
    formData.append(`Recipients[0].type`, "To");
    if (_cc !== "") {
      formData.append(`Recipients[1].name`, _cc);
      formData.append(`Recipients[1].type`, "CC");
    }
    if (_bcc !== "") {
      formData.append(`Recipients[2].name`, _bcc);
      formData.append(`Recipients[2].type`, "Bcc");
    }
    formData.append(`Attachments[0].type`, "");
    formData.append(`Attachments[0].blobURL`, "");
    formData.append(`Attachments[0].name`, "");
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    const draftData = {
      id: 0,
      emailMessage: this.state.sendingReceiptEmail || "",
      isRead: true,
      attachmentName: "",
      attachmentUrl: "",
      sizeInKB: 0,
      subject: _subject,
      from: localStorage.getItem("selectedBranchName"),

      import: false,
      to: _to,
      cc: _cc,
      bcc: _bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      clientId: clientprofileid && clientprofileid,
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    const histOpt = {
      url: `v1/imap/ClientEmail`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh.post(histOpt, draftData).then((res) => {
      formData.append(`emailId`, res);

      this.props
        .sendDynamicLinkEmail(formData)
        .then(async () => {
          message.success("Email sent successfully!");
          this.setState({ loading: false });
          this.props.handleCancelSendingReceiptModal();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  getSendingReceiptForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="From"
          name="from"
          rules={[
            { required: true, message: "Required!" },
            // { type: "email", message: "Invalid!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="To"
          name="to"
          rules={[
            { required: true, message: "Required!" },
            { type: "email", message: "Invalid!" },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="CC"
          name="cc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Bcc"
          name="bcc"
          rules={[{ type: "email", message: "Invalid!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Required!" }]}
        >
          <Input />
        </Form.Item>

        <Row>
          <Col xs={24}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FroalaEditorCom
              model={this.state.sendingReceiptEmail}
              onModelChange={this.getEditorValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2} offset={20} style={{ display: "flex" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px" }}
                className="button-blue"
              >
                Send
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={props.handleCancelSendingReceiptModal}
                className="button-blue"
              >
                Close
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getEditorValue = (value) => {
    this.setState({ sendingReceiptEmail: value });
  };

  render() {
    let a = this.props;

    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-sending-receipt"
          title="SEND EMAIL NOTIFICATION"
          visible={this.props.visibleSendingReceiptModal}
          onCancel={this.props.handleCancelSendingReceiptModal}
          footer={null}
          maskClosable={false}
        >
          <Spin size="large" spinning={this.state.loading}>
            <Form
              {...layoutSendingReceipt}
              onFinish={this.onFinishSendingReceipt}
              ref={this.formRef}
            >
              {this.getSendingReceiptForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendDynamicLinkEmail: bindActionCreators(sendDynamicLinkEmailBlob, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
});
VisaUpdateEmail = connect(mapStateToProps, mapDispatchToProps)(VisaUpdateEmail);

export default withRouter(VisaUpdateEmail);
