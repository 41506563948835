import React, { Fragment, useState, useEffect } from "react";
import { Select, message, Button, DatePicker, Spin, Modal } from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import { Images } from "../../Themes";
import AddDealForm from "../../Deals/AddDealForm";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const DealCard = ({
  clientId,
  onGetTeamMember,
  teamMembers,
  onGetBranchPipeline,
  brnchPipelineData,
  hideButton,
  clientType,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [dealsList, setDealsList] = useState([]);

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = () => {
    const getDealOpt = {
      url: `v1/deal/GetDealBySubjectId/${clientId}`,
    };
    getDealOpt.types = ["GET_DEAL_SUCCESS", "GET_DEAL_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getDealOpt)
      .then((res) => {
        setLoading(false);

        setDealsList(res);
      })
      .catch((errr) => {
        setLoading(false);
        setDealsList([]);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const removeDeal = (id) => {
    let data = {
      id: id,
    };
    const getDealsOpt = {
      url: `v1/deal`,
    };
    getDealsOpt.types = ["REMOVE_DEALS_SUCCESS", "REMOVE_DEALS_FAILURE"];
    setLoading(true);
    apiRefresh
      .delete(getDealsOpt, data)
      .then((res) => {
        getDeals();
        setLoading(false);
        message.success("Deleted!");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div
          style={{
            display: clientType == "pc" ? "block" : "flex",
            justifyContent: clientType == "pc" ? "flex-start" : "flex-end",
            marginTop: clientType == "pc" ? 25 : 8,
          }}
        >
          {clientType == "pc" ? (
            <Button
              className="button-blue"
              style={{ marginLeft: 30 }}
              onClick={() => setIsModalVisible(true)}
            >
              Add Deal
            </Button>
          ) : (
            <img
              onClick={() => setIsModalVisible(true)}
              src={Images.plusIcon}
              style={{ width: 20, cursor: "pointer" }}
            />
          )}
        </div>

        <div
          className="form-container"
          style={{
            padding: 0,
            margin: clientType == "pc" ? 25 : 0,
            marginTop: clientType == "pc" ? 25 : 9,
            display: clientType == "pc" ? "inline-block" : "block",
          }}
        >
          {dealsList.map((item, ind) => (
            <div
              style={{
                padding: 10,
                marginBottom: 10,
                margin: clientType == "pc" ? 5 : 0,
                display: clientType == "pc" ? "inline-block" : "block",
              }}
              className="box-shadow"
            >
              <div className="deal-icon-position">
                <img
                  src={Images.editBorderIcon}
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsModalVisible(true);
                    setUpdateData(item);
                    setIsUpdate(true);
                  }}
                />
                <img
                  src={Images.deleteGray}
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeDeal(item.id);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.isActive ? (
                  <img
                    src={Images.activeGreen}
                    className="svg-btn-img"
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                ) : (
                  <img
                    src={Images.crossIcon}
                    className="svg-btn-img"
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                )}
                <span
                  className="deal-title"
                  style={{
                    color:
                      item.priority === 1
                        ? "#5ab859"
                        : item.priority === 2
                        ? "#efae4d"
                        : item.priority === 3
                        ? "#d8544f"
                        : "#555",
                    marginLeft: 5,
                  }}
                >
                  {item.name}
                </span>
              </div>
              {clientId && item.stageName && (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  //   onClick={() => moveToClient(item)}
                >
                  <div>
                    {/* <img
                      src={Images.personFill}
                      className="svg-btn-img"
                      style={{
                        width: 12,
                        height: 12,
                      }}
                    /> */}
                    <span
                      className="deal-card-text"
                      style={{
                        marginLeft: 5,
                        fontSize: 12,
                      }}
                    >
                      {item.stageName ? item.stageName : ""}
                    </span>
                  </div>
                </div>
              )}
              {item.clientName && (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  //   onClick={() => moveToClient(item)}
                >
                  <div>
                    <img
                      src={Images.personFill}
                      className="svg-btn-img"
                      style={{
                        width: 12,
                        height: 12,
                      }}
                    />
                    <span
                      className="deal-card-text"
                      style={{
                        marginLeft: 5,
                        fontSize: 12,
                      }}
                    >
                      {item.clientName ? item.clientName : ""}
                    </span>
                  </div>
                </div>
              )}
              {item.ownerName && (
                <div>
                  <img
                    src={Images.personIcon}
                    className="svg-btn-img"
                    style={{
                      width: 26,
                      height: 18,
                      marginLeft: -6,
                    }}
                  />
                  <span className="deal-card-text">
                    {item.ownerName ? item.ownerName : ""}
                  </span>
                </div>
              )}
              <div>
                <img
                  src={Images.calendar}
                  className="svg-btn-img"
                  style={{
                    width: 12,
                    height: 12,
                  }}
                />
                <span className="deal-card-text" style={{ marginLeft: 5 }}>
                  {moment(item.createdDate).format("DD/MM/YYYY") !==
                    "01/01/1900" ||
                  moment(item.createdDate).format("DD/MM/YYYY") !== "01/01/0001"
                    ? moment(item.createdDate).format("DD/MM/YYYY")
                    : ""}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Images.grayDollar}
                  className="svg-btn-img"
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 5,
                  }}
                />
                <span className="deal-card-text">
                  {item.amount ? item.amount : ""}
                </span>
              </div>
            </div>
          ))}
        </div>
        {isModalVisible && (
          <Modal
            title="Update Deal"
            visible={isModalVisible}
            // onOk={handleOk}
            onCancel={handleCancel}
            header={false}
            footer={false}
            width={550}
          >
            <AddDealForm
              onGetBranchPipeline={onGetBranchPipeline}
              brnchPipelineData={brnchPipelineData}
              handleCancel={handleCancel}
              setLoading={setLoading}
              loading={loading}
              //   state={state}
              //   pipelineId={selectedPipeline}
              onGetTeamMember={onGetTeamMember}
              teamMembers={teamMembers}
              getSinglePiplineData={getDeals}
              //   onSearchClient={onSearchClient}
              //   onSearchPotentialClient={onSearchPotentialClient}
              updateData={updateData}
              isUpdate={isUpdate}
              setIsUpdate={(val) => setIsUpdate(val)}
              setUpdateData={(val) => setUpdateData(val)}
              updateByProfile={true}
              clientId={clientId}
              clientType={clientType}
              //   onSearchEmployerMain={onSearchEmployerMain}
            />
          </Modal>
        )}
      </Spin>
    </Fragment>
  );
};

export default DealCard;
