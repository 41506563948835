import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const clientEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_EMAIL_HISTORY_SUCCESS:
      return { ...state, clientHistoryRes: action.payload };
    case types.GET_EMAIL_HISTORY_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DRAFT_CLIENT_SUCCESS:
      return { ...state, draftclientRes: action.payload };
    case types.ADD_DRAFT_CLIENT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_CLIENT_EMAIL_SUCCESS:
      return { ...state, deleteEmailRes: action.payload };
    case types.DELETE_CLIENT_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_EMAIL_DOCUMENT_SUCCESS:
      return { ...state, emailDocumentRes: action.payload };
    case types.ADD_EMAIL_DOCUMENT_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_DOWNLOAD_SUCCESS:
      return { ...state, downloadDocRes: action.payload };
    case types.GET_DOCUMENT_DOWNLOAD_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_PDF_SUCCESS:
      return { ...state, documentPdfRes: action.payload };
    case types.GET_DOCUMENT_PDF_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DOCUMENT_FILTERTYPE_SUCCESS:
      return { ...state, documentFilterRes: action.payload };
    case types.GET_DOCUMENT_FILTERTYPE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_FILTERTYPE_SUCCESS:
      return { ...state, addDocumentFilterRes: action.payload };
    case types.ADD_DOCUMENT_FILTERTYPE_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.CLIENT_FAMILY_EMAIL_SUCCESS:
      return { ...state, clientEmailRes: action.payload };
    case types.CLIENT_FAMILY_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_PDF_SUCCESS:
      return { ...state, getPdfRes: action.payload };
    case types.GET_PDF_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.LINK_EMAIL_SUCCESS:
      return { ...state, linkEmailRes: action.payload };
    case types.LINK_EMAIL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
