import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  message,
  Col,
  InputNumber,
  Spin,
  Collapse,
  Modal,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addNewTemplate,
  addTemplateToClientAppliedTemplateListing,
  getAllTaxesListing,
  getClientTemplateListing,
  getTemplateListing,
  deleteTemplateForClient,
  getClientTemplateInfo,
  getTemplateInfo,
} from "../store/Actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccordianClientProfileTemplate from "./AccordianClientProfileTemplate";
const { Option } = Select;
const { Panel } = Collapse;

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientTemplateListing: [],
      templateId: undefined,
      visibleDeleteModal: false,
      index: 0,
      loading: false,
      total: 0,
      received: 0,
      dueAmount: 0,
      activeKeysList: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getClientTemplateListing()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let clientTemplateListingTemp =
      nextProps && nextProps.clientTemplateListing;
    let _clientTemplateListing =
      clientTemplateListingTemp &&
      clientTemplateListingTemp.sort((a, b) =>
        a.status.localeCompare(b.status)
      );
    let _received = 0;
    let _dueAmount = 0;
    let _total = 0;
    this.setState({ clientTemplateListing: _clientTemplateListing }, () => {
      let _tempClientTemplateListing = this.state.clientTemplateListing;
      let _activeKeysList = this.state.activeKeysList;
      if (_tempClientTemplateListing && _tempClientTemplateListing.length > 0) {
        for (
          let _indicatorPoint = 0;
          _indicatorPoint < _tempClientTemplateListing.length;
          _indicatorPoint++
        ) {
          _total = _total + _tempClientTemplateListing[_indicatorPoint].total;
          if (_tempClientTemplateListing[_indicatorPoint].status === "Active") {
            _activeKeysList.push(
              _tempClientTemplateListing[_indicatorPoint].id
            );
          } else {
          }

          if (
            _tempClientTemplateListing &&
            _tempClientTemplateListing[_indicatorPoint].stages &&
            _tempClientTemplateListing[_indicatorPoint].stages.length > 0
          ) {
            for (
              let _indicator = 0;
              _indicator <
              _tempClientTemplateListing[_indicatorPoint].stages.length;
              _indicator++
            ) {
              if (
                _tempClientTemplateListing[_indicatorPoint].stages[_indicator]
                  .invoice &&
                _tempClientTemplateListing[_indicatorPoint].stages[_indicator]
                  .invoice.length > 0
              ) {
                for (
                  let _indicatorTemp = 0;
                  _indicatorTemp <
                  _tempClientTemplateListing[_indicatorPoint].stages[_indicator]
                    .invoice.length;
                  _indicatorTemp++
                ) {
                  if (
                    _tempClientTemplateListing[_indicatorPoint].stages[
                      _indicator
                    ].invoice &&
                    _tempClientTemplateListing[_indicatorPoint].stages[
                      _indicator
                    ].invoice[_indicatorTemp].payment &&
                    _tempClientTemplateListing[_indicatorPoint].stages[
                      _indicator
                    ].invoice[_indicatorTemp].payment.length > 0
                  ) {
                    for (
                      let _indicatorTempCal = 0;
                      _indicatorTempCal <
                      _tempClientTemplateListing[_indicatorPoint].stages[
                        _indicator
                      ].invoice[_indicatorTemp].payment.length;
                      _indicatorTempCal++
                    ) {
                      _received =
                        _received +
                        _tempClientTemplateListing[_indicatorPoint].stages[
                          _indicator
                        ].invoice[_indicatorTemp].payment[_indicatorTempCal]
                          .amount;
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.setState({
        total: Math.round((_total + Number.EPSILON) * 100) / 100,
        dueAmount:
          Math.round((_total - _received + Number.EPSILON) * 100) / 100,
        received: Math.round((_received + Number.EPSILON) * 100) / 100,
      });
    });
  }

  setLoadingTrue = () => {
    this.setState({ loading: true });
  };

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={24} style={{ display: "flex", paddingLeft: "22px" }}>
                <div style={{ display: "flex", marginRight: "16px" }}>
                  <p>Total :&nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>{this.state.total}</p>
                </div>
                <div style={{ display: "flex", marginRight: "16px" }}>
                  <p>Received :&nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>{this.state.received}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p>Due : &nbsp;</p>
                  <p style={{ fontWeight: "bold" }}>
                    {" " + this.state.dueAmount}
                  </p>
                </div>
              </Col>
            </Row>
            <Row
              style={{ height: "100vh", maxHeight: "100vh", overflowY: "auto" }}
            >
              <Col xs={23} style={{ marginLeft: "auto", marginRight: "auto" }}>
                {this.state &&
                  this.state.clientTemplateListing &&
                  this.state.clientTemplateListing.map((data, index) => {
                    return (
                      <div key={data.id}>
                        <AccordianClientProfileTemplate
                          activeKeysList={this.state.activeKeysList}
                          getShowInvoice={
                            this.props && this.props.getShowInvoice
                          }
                          setLoadingTrue={this.setLoadingTrue}
                          setLoadingFalse={this.setLoadingFalse}
                          data={data}
                        />
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    templateListing:
      state &&
      state.templatesReducer &&
      state.templatesReducer.templateListing &&
      state.templatesReducer.templateListing.items &&
      state.templatesReducer.templateListing.items,
    clientTemplateListing:
      state &&
      state.clientProfileReducer &&
      state.clientProfileReducer.clientTemplateListing &&
      state.clientProfileReducer.clientTemplateListing.items &&
      state.clientProfileReducer.clientTemplateListing.items,
    clientAppliedTemplateListing:
      state &&
      state.clientProfileReducer &&
      state.clientProfileReducer.clientAppliedTemplateListing &&
      state.clientProfileReducer.clientAppliedTemplateListing.items &&
      state.clientProfileReducer.clientAppliedTemplateListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  getTemplateListing: bindActionCreators(getTemplateListing, dispatch),
  addTemplateToClientAppliedTemplateListing: bindActionCreators(
    addTemplateToClientAppliedTemplateListing,
    dispatch
  ),
  deleteTemplateForClient: bindActionCreators(
    deleteTemplateForClient,
    dispatch
  ),
  getClientTemplateInfo: bindActionCreators(getClientTemplateInfo, dispatch),
  getTemplateInfo: bindActionCreators(getTemplateInfo, dispatch),
});

Templates = connect(mapStateToProps, mapDispatchToProps)(Templates);
export default withRouter(Templates);
