import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Task from "./task";
import { Col, Row } from "antd";

const Container = styled.div`
  margin: 14px;
  border-radius: 5px;
  box-shadow: 0px 0px 22px -13px rgb(0 0 0 / 75%);
  display: block;
  flex-direction: column;
  max-height: 660px;
  height: 660px;
  overflow-y: auto;
  width: 286px;
  max-width: 286px;
`;
const Title = styled.h3`
  padding: 8px;
`;
const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "#f3f3f3" : "#f3f3f3")};
  flex-grow: 1;
  min-height: 100%;
  height: 100%;
`;

export default class Column extends React.Component {
  render() {
    return (
      <div style={{ display: "block", width: "314px", maxWidth: "314px" }}>
        <div className="ts-potential-client-statuses">
          <Row>
            <Col xs={12}>
              <div className={"container-center"}>
                <h5>{"ACCESSED"}</h5>
              </div>
            </Col>
            <Col xs={2} offset={10}>
              <div className={"container-center"}>
                <h5>{"04"}</h5>
              </div>
            </Col>
          </Row>
        </div>
        <Container id="style-3">
          <Droppable droppableId={this.props.column.id}>
            {(provided, snapshot) => (
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {this.props.tasks.map((task, index) => (
                  <Task key={task.id} task={task} index={index} />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </Container>
      </div>
    );
  }
}
