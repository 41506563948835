import React, { Fragment, useState, useEffect } from "react";
import { Select, message, DatePicker } from "antd";
import moment from "moment";

import activityData from "../ClientActivity/ActivityData";
import { Images } from "../../Themes";
import { apiRefresh } from "../../services/api";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const UpdateAdmissionCard = ({
  onUpdAdmissionStatus,
  admissionStatusData,
  onGetAdmissionProgram,
  program,
  profileDataRdx,
}) => {
  const [programStatus, setProgramStatus] = useState(
    program.programStatus.toString()
  );
  const [initialDeposit, setInitialDeposit] = useState(program.initialDeposit);
  const [selection, setSelection] = useState(program.isFinal);
  const [loading, setLoading] = useState(false);
  const [admissionStatusDate, setAdmissionStatusDate] = useState(
    program.date === "1900-01-01T00:00:00+00:00" ? "" : moment(program.date)
  );
  //   console.log("show visa  status data ", visaStatusData);
  if (admissionStatusData && admissionStatusData.items) {
    var admissionStatuses = [];
    for (var i = 0; i < admissionStatusData.items.length; i++) {
      admissionStatuses.push(
        <Option key={admissionStatusData.items[i].id}>
          {admissionStatusData.items[i].name}
        </Option>
      );
    }
  }

  useEffect(() => {
    setSelection(program.isFinal);
  }, [program]);

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setProgramStatus(value);
  };

  const onChangeDate = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
    setAdmissionStatusDate(value);
  };

  const updateStatus = (visa) => {
    // let data = {
    //   id: visa.id,
    //   caseStatusId: parseInt(caseStatusId),
    //   approveDate: caseStatusDate,
    // };
    // onUpdateCaseStatus(data).then((res) => {
    //   message.success("Visa status updated successfully");
    //   onGetVisaApplication();
    // });
  };

  const onSubmit = (programData) => {
    var userId = localStorage.getItem("userId");

    let admStatus =
      admissionStatusData &&
      admissionStatusData.items.find((obj) => obj.id == programStatus);

    let data = {
      id: programData.id,
      clientId: programData.clientId,
      schoolId: programData.schoolId,
      programId: programData.programId,
      programStatus: parseInt(programStatus),
      description: programData.description,
      appliedDate:
        programStatus === "1"
          ? moment(admissionStatusDate || new Date()).format(
              "YYYY-MM-DDT00:00:00+00:00"
            )
          : programData.appliedDate,
      addmissionDate: programData.addmissionDate,
      enrolledProgram: programData.enrolledProgram,
      studentNo: programData.studentNo,
      fee: programData.fee,
      hideCMS: programData.hideCMS,
      date: admissionStatusDate || new Date(),
      caseStart: programData.caseStart,
      caseEnd: programData.caseEnd,
      courseEffectiveDate: programData.courseEffectiveDate,

      aipDate: programData.aipDate,
      aipSubmitted: programData.aipSubmitted,
      visaApprovedDate: programData.visaApprovedDate,
      ftsSubmitted: programData.ftsSubmitted,
      ftsDate: programData.ftsDate,
      visaId: programData.visaId,
      conditionalOfferDate:
        programStatus === "3"
          ? admissionStatusDate || new Date()
          : programData.conditionalOfferDate,
      formalOfferDate:
        programStatus === "4"
          ? admissionStatusDate || new Date()
          : programData.formalOfferDate,
      paidDate:
        programStatus === "5"
          ? admissionStatusDate || new Date()
          : programData.paidDate,
      declineDate:
        programStatus === "6"
          ? admissionStatusDate || new Date()
          : programData.declineDate,
      startDate: programData.startDate,
      deletedDate: programData.deletedDate || new Date(),
      commissionId: programData.commissionId,
      schoolTypeId: programData.schoolTypeId,
    };

    console.log(data);
    onUpdAdmissionStatus(data)
      .then((res) => {
        message.success("Admission status updated successfuly");
        onGetAdmissionProgram();

        let userName = localStorage.getItem("userName");
        var profileData = profileDataRdx;
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              programData.schoolName +
              " admission status updated to " +
              admStatus.name +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Admission",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((err) => {
        message.error("Failed to update admission status");
      });
  };

  const onInitialDeposit = () => {
    const initialDepositOpt = {
      url: `v1/client/programdetail/InitialDeposit`,
    };
    initialDepositOpt.types = [
      "INITIAL_DEPOSIT_SUCCESS",
      "INITIAL_DEPOSIT_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .put(initialDepositOpt, { id: program.id, status: !initialDeposit })
      .then((res) => {
        setLoading(false);
        setInitialDeposit(!initialDeposit);
        onGetAdmissionProgram();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  const onFinalSelection = () => {
    const initialDepositOpt = {
      url: `v1/client/programdetail/IsFinalSelection`,
    };
    initialDepositOpt.types = [
      "FINAL_SELECTION_SUCCESS",
      "FINAL_SELECTION_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .put(initialDepositOpt, { id: program.id, status: !selection })
      .then((res) => {
        setLoading(false);
        setSelection(!selection);
        onGetAdmissionProgram();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  return (
    <Fragment>
      <div className="form-container" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          className="cv-assist-cont"
          style={{ paddingTop: 10, marginLeft: 5 }}
        >
          <span className="cv-bold-text">{program.schoolName}</span>
        </div>
        <div
          style={{
            paddingTop: 5,
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span className="visa-type-text">{program.enrolledProgram}</span>
          <div style={{ display: "flex" }}>
            <div>
              <div
                className="case-priority-btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #3fb6cd",
                }}
              >
                <span
                  className="case-priority-text"
                  style={{ textAlign: "center", color: "#40B5CE" }}
                >
                  Initial Deposit
                </span>
              </div>
              <div
                className={
                  initialDeposit ? "cp-green-btn-cont" : "case-priority-cross"
                }
                style={{
                  justifyContent: "space-around",
                  borderRadius: 0,
                  cursor: "pointer",
                }}
                onClick={onInitialDeposit}
              >
                {initialDeposit && (
                  <img src={Images.tickWhite} style={{ width: 8, height: 8 }} />
                )}
                <div className="white-box"></div>
                {!initialDeposit && (
                  <img
                    src={Images.crossWhite}
                    style={{ width: 8, height: 8 }}
                  />
                )}
              </div>
              <div></div>
            </div>
            <div style={{ marginLeft: 10 }}>
              <div
                className="case-priority-btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #3fb6cd",
                }}
              >
                <span
                  className="case-priority-text"
                  style={{ textAlign: "center", color: "#40B5CE" }}
                >
                  Final Selection
                </span>
              </div>
              <div
                className={
                  selection ? "cp-green-btn-cont" : "case-priority-cross"
                }
                style={{
                  borderRadius: 0,
                  cursor: "pointer",
                  justifyContent: "space-around",
                }}
                onClick={onFinalSelection}
              >
                {selection && (
                  <img src={Images.tickWhite} style={{ width: 8, height: 8 }} />
                )}
                <div className="white-box"></div>
                {!selection && (
                  <img
                    src={Images.crossWhite}
                    style={{ width: 8, height: 8 }}
                  />
                )}
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {program.appliedDate != "1900-01-01T00:00:00+00:00" && (
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <span className="visa-date-text">
              Applied Date: {moment(program.appliedDate).format("DD/MM/YYYY")}
            </span>
          </div>
        )}

        {/* <div className="profile-progressbar-cont">
                <ProgressBar bgcolor="#CCCCCC" completed={30} color="#9D0C0E" />
              </div> */}

        <div style={{ padding: 10 }}>
          <Select
            placeholder="Select Status"
            style={{ width: "100%" }}
            onChange={onChange}
            value={programStatus}
          >
            {admissionStatuses}
          </Select>
        </div>
        <div style={{ padding: 10, paddingTop: 0, display: "flex" }}>
          <DatePicker
            // defaultValue={admissionStatusDate}
            onChange={onChangeDate}
            format={dateFormat}
          />
          <div className="button-blue-cont" style={{ paddingRight: 0 }}>
            <div
              className="ad-upd-button"
              style={{ cursor: "pointer" }}
              onClick={() => onSubmit(program)}
            >
              <span style={{ color: "#40B5CE" }}>Save</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateAdmissionCard;
