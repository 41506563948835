import React from "react";
import "./ClientsStyles.css";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getOutgoingPaymentsListing,
  deleteOutgoingPayment,
  getPaymentSourcesList,
  setOutgoingPaymentInfo,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Images } from "../Themes";
import AddOutgoingPaymentForm from "./AddOutgoingPaymentForm";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class OutgoingPayments extends React.Component {
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    super(props);
    this.state = {
      showForm: "listing",
      outgoingPaymentsListing: [],
      dateFrom: "1900-01-01T00:00:00+00",
      dateTo: "1900-01-01T00:00:00+00",
      loading: false,
      clientprofileid: clientprofileid,
      total: 0,
    };
  }

  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.date).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agentId",
      key: "agentId",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.agentId === 0 ? "" : record.agentId}
        </p>
      ),
    },
    {
      title: "Source",
      dataIndex: "sourceId",
      key: "sourceId",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.sourceId === 0 ? "" : record.sourceId}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.props.setOutgoingPaymentInfo(record);
              this.setState({ visibleAddOutgoingPaymentModal: true });
            }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getOutgoingPaymentsListing(this.state).then(() => {
      this.setState({ loading: false });
    });
    this.props.getPaymentSourcesList().then(() => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _outgoingPaymentsListing =
      nextProps && nextProps.outgoingPaymentsListing;
    let _paymentSourcesListing = nextProps && nextProps.paymentSourcesListing;
    let _total = 0;
    if (_outgoingPaymentsListing && _outgoingPaymentsListing.length > 0) {
      for (let index = 0; index < _outgoingPaymentsListing.length; index++) {
        if (_paymentSourcesListing && _paymentSourcesListing.length > 0) {
          for (
            let _index = 0;
            _index < _paymentSourcesListing.length;
            _index++
          ) {
            if (
              _outgoingPaymentsListing[index].sourceId ===
              _paymentSourcesListing[_index].id
            ) {
              _outgoingPaymentsListing[index].sourceId =
                _paymentSourcesListing[_index].name;
            }
          }
        }
      }
      for (
        let _outgoingPaymentsIndex = 0;
        _outgoingPaymentsIndex < _outgoingPaymentsListing.length;
        _outgoingPaymentsIndex++
      ) {
        _total =
          _total + _outgoingPaymentsListing[_outgoingPaymentsIndex].amount;
      }
    }

    this.setState({
      outgoingPaymentsListing: _outgoingPaymentsListing,
      total: _total,
      paymentSourcesListing: _paymentSourcesListing,
    });
  }

  addOutgoingPayment = () => {
    this.props.setOutgoingPaymentInfo(null);
    this.setState({ visibleAddOutgoingPaymentModal: true });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this payment?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.handleDeleteOutgoingPayment(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteOutgoingPayment = (id) => {
    this.props.deleteOutgoingPayment(id).then(() => {
      message.success("Payment is deleted successfully!");
      this.setState({ loading: true });
      this.props.getOutgoingPaymentsListing(this.state).then(() => {
        this.setState({ loading: false, visibleDeleteModal: false });
      });
    });
  };

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null ? "1900-01-01T00:00:00+00" : moment(date).format(),
      },
      () => {}
    );
  };

  show = () => {
    if (
      this.state.dateFrom === "1900-01-01T00:00:00+00" ||
      this.state.dateFrom === "Invalid date"
    ) {
      message.error("Please select date from!");
    } else if (
      this.state.dateTo === "1900-01-01T00:00:00+00" ||
      this.state.dateTo === "Invalid date"
    ) {
      message.error("Please select date to!");
    } else {
      // this.setState({ loading: true });
      this.props.getOutgoingPaymentsListing(this.state).then(() => {
        this.setState({});
      });
    }
  };

  showAll = () => {
    this.setState(
      {
        loading: true,
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
      },
      () => {
        this.props.getOutgoingPaymentsListing(this.state).then(() => {
          this.setState({
            loading: false,
            dateFrom: "1900-01-01T00:00:00+00",
            dateTo: "1900-01-01T00:00:00+00",
          });
        });
      }
    );
  };

  getListingView = () => {
    return (
      <div>
        <div className="ca-gray-cont">
          <Row>
            <Col xs={11}>
              <Row>
                <Col xs={11} offset={1}>
                  <DatePicker
                    format={dateFormat}
                    placeholder={"SELECT DATE FROM"}
                    onChange={this.handleChangeDateFrom}
                  />
                </Col>
                <Col xs={11} offset={1}>
                  <DatePicker
                    placeholder={"SELECT DATE TO"}
                    format={dateFormat}
                    onChange={this.handleChangeDateTo}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={13}>
              <Row>
                <Col xs={11} offset={10} style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    onClick={this.show}
                    style={{ marginRight: "5px" }}
                  >
                    SEARCH
                  </Button>
                  <Button type="primary" onClick={this.showAll}>
                    SHOW ALL
                  </Button>
                </Col>
                <Col xs={2}>
                  <>
                    <img
                      src={Images.addIcon}
                      className="icons-client"
                      type="primary"
                      onClick={this.addOutgoingPayment}
                    />
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="ca-gray-cont"
          style={{ padding: 20, paddingBottom: 20 }}
        >
          <Row>
            <Col xs={24} className={"school-table"}>
              <Table
                dataSource={
                  this.state.outgoingPaymentsListing
                    ? this.state.outgoingPaymentsListing
                    : null
                }
                columns={this.columns}
                pagination={{
                  defaultPageSize: 20,
                }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24}>
            <div style={{ display: "block", marginLeft: "25px" }}>
              <p>{`TOTAL : ${this.state.total}`}</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  handleCancelAddOutgoingPaymentModal = (e) => {
    this.setState({
      visibleAddOutgoingPaymentModal: false,
    });
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className={"spinner"}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {this.state.showForm === "listing" ? this.getListingView() : null}
            {this.state.visibleAddOutgoingPaymentModal ? (
              <AddOutgoingPaymentForm
                handleCancelAddOutgoingPaymentModal={
                  this.handleCancelAddOutgoingPaymentModal
                }
                visibleAddOutgoingPaymentModal={
                  this.state.visibleAddOutgoingPaymentModal
                }
                dateFrom={this.state.dateFrom}
                dateTo={this.state.dateTo}
                clientprofileid={this.state.clientprofileid}
              />
            ) : null}
            {this.getModalDelete()}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outgoingPaymentsListing:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.outgoingPaymentsListing &&
      state.outgoingPaymentsReducer.outgoingPaymentsListing,
    paymentSourcesListing:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.paymentSourcesListing &&
      state.outgoingPaymentsReducer.paymentSourcesListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOutgoingPaymentsListing: bindActionCreators(
    getOutgoingPaymentsListing,
    dispatch
  ),
  deleteOutgoingPayment: bindActionCreators(deleteOutgoingPayment, dispatch),
  getPaymentSourcesList: bindActionCreators(getPaymentSourcesList, dispatch),
  setOutgoingPaymentInfo: bindActionCreators(setOutgoingPaymentInfo, dispatch),
});
OutgoingPayments = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutgoingPayments);

export default withRouter(OutgoingPayments);
