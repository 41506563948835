import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaFlow from "../Clients/VisaFlow";

import {
  getVisaType,
  getCountries,
  startNewApplication,
  getVisaApplication,
  addFamilyLink,
  getClientDocuments,
  getClientEmail,
  getPartnerData,
  getClientFamily,
  updateSubjectCase,
  getVisaStatus,
  updateCaseStatus,
  getTeamMember,
  removeCaseStatus,
  removeSubjectCase,
  getCaseDocuments,
  getVisaTypeByCountry,
  getCategory,
  removeFamilyLink,
  setActiveKey,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  updateCompletedTask,
  addTaskFileNote,
  updateCaseHistory,
  getClientVisaFileNotes,
  getDocumentDownload,
  getAdmissionProgram,
  getAdmissionStatuses,
  getFilteredType,
  getVisaEmails,
  getBranchVisaCountries,
  setProfileData,
  getClientProfile,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaTypeData: state.visaTypeReducer.visaTypeData,
    countriesData: state.visaTypeReducer.countriesData,
    visaAppData: state.clientVisaReducer.visaAppData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    teamMembers: state.teamMemberReducer.teamMembers,
    caseDocumentData: state.clientVisaReducer.caseDocumentData,
    countryVisaTypeData: state.clientVisaReducer.countryVisaTypeData,
    visaCategoryData: state.clientVisaReducer.visaCategoryData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    activeKey: state.employerManagReducer.activeKey,
    clientTab: state.employerManagReducer.clientTab,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    visaFileNotesRes: state.fileNotesReducer.visaFileNotesRes,
    visaEmailRes: state.clientVisaReducer.visaEmailRes,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onAddFamilyLink: bindActionCreators(addFamilyLink, dispatch),
    onGetClientDocument: bindActionCreators(getClientDocuments, dispatch),
    onGetClientEmailt: bindActionCreators(getClientEmail, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateSubjectCase: bindActionCreators(updateSubjectCase, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onRemoveCaseStatus: bindActionCreators(removeCaseStatus, dispatch),
    onRemoveSubjectCase: bindActionCreators(removeSubjectCase, dispatch),
    onGetCaseDocument: bindActionCreators(getCaseDocuments, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetVisaCategory: bindActionCreators(getCategory, dispatch),
    onRemoveFamilyLink: bindActionCreators(removeFamilyLink, dispatch),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onUpdateCaseHistory: bindActionCreators(updateCaseHistory, dispatch),
    onGetVisaFileNotes: bindActionCreators(getClientVisaFileNotes, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
    onGetVisaEmails: bindActionCreators(getVisaEmails, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaFlow);
