import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const emailSetReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_EMAIL_SETTINGS_SUCCESS:
      return { ...state, emailSetRes: action.payload };
    case types.GET_EMAIL_SETTINGS_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_EMAIL_SETTINGS_SUCCESS:
      return { ...state, emailEmailSetRes: action.payload };
    case types.PUT_EMAIL_SETTINGS_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
