import React, { Fragment, useState, useEffect } from "react";
import { Images } from "../../Themes";
import CommentModal from "../../MyDailyTasksReminders/SideArea/CommentModal";
import { Link, useHistory } from "react-router-dom";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
let clientprofileid = sessionStorage.getItem("clientprofileid");

const DailyTaskToDo = ({
  title,
  data,
  index,
  showModal,
  dailyTaskRes,
  ComletedTasks,
  getTasksComments,
  addTaskComment,
  getTaskFollowers,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  updateTaskState,
  onRemoveFollower,
  closeTaskPanel,
  onRemoveTaskClient,
  onSearchClient,
}) => {
  const history = useHistory();
  const CommentClose = () => {
    let key = title === "TODAY'S TASKS" ? "todaysTask" : "pendingTasks";
    updateTaskState(key, data);
  };

  const openProfile = (data) => {
    if (data.clientName && data.clientName != "") {
      onGetClientProfile(data.subjectId).then((res) => {
        let clientData = res.payload;
        openClientFile(
          clientData.id,
          clientData.clientId,
          clientData.memberType,
          clientData.familyId
        );
      });
    } else if (data.potentialClientName && data.potentialClientName != "") {
      onGetPotentialClient(data.subjectId).then((res) => {
        onGetUpdateBol(false);
        history.push("/update-potential-client");
      });
    } else return;
  };

  const openClientFile = (id, clientId, memberType, familyId) => {
    localStorage.setItem("clientProfileIdMain", id);
    localStorage.setItem("familyId", familyId);
    if (memberType === "Client") {
      localStorage.setItem("clientProfileIdMain", clientId);
      localStorage.setItem("familyId", familyId);
      history.push({
        pathname: "/profile",
        state: { id: id },
      });
    } else if (memberType === "Spouse") {
      history.push({
        pathname: "/partner-profile",
        state: { id: clientId, familyId: familyId },
      });
    } else if (memberType === "Child") {
      history.push({
        pathname: "/client-family-member",
        state: { id: clientId, familyId: familyId },
      });
    }
  };

  return (
    <Fragment>
      <div className="task-to-do">
        <div class="heading-style" style={{ marginBottom: 15 }}>
          <span style={{ marginLeft: 0 }}>{title}</span>
          <a
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              float: "right",
              marginRight: "-17px",
              marginTop: "-9px",
            }}
          >
            <CloseCircleOutlined
              onClick={() => closeTaskPanel()}
              style={{ fontSize: "20px" }}
            />
          </a>
        </div>
        {data.map((el) => (
          <div>
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: 29,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <input
                    onChange={(e) => ComletedTasks(el, e.target.checked)}
                    type="checkbox"
                    className="sus-checkbox"
                    checked={el.isCompleted}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    onClick={() => {
                      el.showComment = !el.showComment;
                    }}
                    className="cv-normal-text"
                  >
                    {el && el.taskTitle} ({yyyymmddFormate(el && el.taskDate)})
                  </Link>
                </div>
                {el.potentialClientName != "" ? (
                  el.potentialClientName ? (
                    <div
                      className="marked-name"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => openProfile(el)}
                    >
                      <span className="marked-name-font">
                        {el.potentialClientName}
                      </span>
                    </div>
                  ) : null
                ) : el.clientName ? (
                  <div
                    className="marked-name"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => openProfile(el)}
                  >
                    <span className="marked-name-font">{el.clientName}</span>
                  </div>
                ) : null}
              </div>
              <div style={{ display: "flex" }}>
                <div className="mdtr-user-cont">
                  <img
                    src={Images.userCircleGray}
                    style={{ width: 15, height: 15 }}
                    onClick={() => showModal("add-follower", el.id, el)}
                  />
                  <img
                    src={Images.nextGray}
                    style={{
                      marginLeft: 5,
                      transform: `rotate(90deg)`,
                      width: 12,
                      height: 12,
                    }}
                    onClick={() => showModal("add-follower", el.id, el)}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    onClick={() => showModal("for-update-date", el.id, el)}
                    src={Images.calendarBlue}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    onClick={() => showModal("for-update", el.id, el)}
                    src={Images.fileNotes}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
                <div
                  className="mdtr-user-cont"
                  style={{ backgroundColor: "#FFFFFF", marginLeft: 5 }}
                >
                  <img
                    onClick={() => showModal("confirm-delete", el.id)}
                    src={Images.deleteIcon}
                    style={{ width: 15, height: 15 }}
                  />
                </div>
              </div>
            </div>
            <div>
              {el.showComment === true && (
                <CommentModal
                  CommentClose={() => {
                    el.showComment = !el.showComment;
                    CommentClose();
                  }}
                  getTasksComments={getTasksComments}
                  dailyTaskRes={dailyTaskRes}
                  data={el}
                  addTaskComment={addTaskComment}
                  getTaskFollowers={getTaskFollowers}
                  onRemoveFollower={onRemoveFollower}
                  onRemoveTaskClient={onRemoveTaskClient}
                  onSearchClient={onSearchClient}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default DailyTaskToDo;
