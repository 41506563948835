import React, { Fragment, useState, useEffect, useRef } from "react";
// import EmployerManagementTable from "./EmployerManagementTable";
import {
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  DatePicker,
  Spin,
  message,
  AutoComplete,
} from "antd";
import ImapEmailTable from "./ImapEmailTable";
import { apiRefresh } from "../../services/api";
import moment from "moment";
import activityData from "../../Components/ClientActivity/ActivityData";

const dateFormat = "DD/MM/YYYY";

var notSearch = true;
const MapEmail = ({
  labels,
  onSearchImapEmail,
  searchMailData,
  onSearchClient,
  mainClientsData,
  onImportManualEmail,
  onSearchPotentialClient,
  potentialClientsData,
  onSearchEmployer,
  searchEmployerRes,
  onImportManualPotentialEmail,
  onImportManualEmployerEmail,
  onAutoImportEmailClient,
  accountType,
}) => {
  const [isSelectType, setIsSelectType] = useState();
  const [isSelector, setIsSelector] = useState();
  const [mailList, setMailList] = useState();
  const [buttonClick, setButtonClick] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [searchedClients, setSearchedClients] = useState(null);
  const [checkedEmails, setCheckedEmails] = useState([]);
  const [label, setLabel] = useState("[Gmail]/All Mail");
  const [id, setId] = useState([]);
  const [pageNo, setPageNo] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [selectedPotentialClient, setSelectedPotentialClient] = useState("");
  const [
    selectedPotentialClientName,
    setSelectedPotentialClientName,
  ] = useState("");
  const [searchedPotentialClients, setSearchedPotentialClients] = useState(
    null
  );
  const [selectedEmployer, setSelectedEmployer] = useState("");
  const [selectedEmployerName, setSelectedEmployerName] = useState("");
  const [searchedEmployer, setSearchedEmployer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [payloadData, setPayloadData] = useState(null);
  const [uncheckCheckbox, setUncheckCheckbox] = useState(false);
  const [mailBoxOpt, setMailBoxOpt] = useState([]);
  const [imapTablePSize, setImapTablePageSize] = useState(1);
  const [imapTablePNumber, setImapTablePageNumber] = useState(10);
  const [options, setOptions] = useState([]);
  const [pcOptions, setPcOptions] = useState([]);
  const [employerOptions, setEmployerOptions] = useState([]);
  // const usePrevious = (value) => {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // };
  //
  // const prevBranchId = usePrevious(selectedBranchId);
  // useEffect(() => {
  //   let data = null;
  //   let userId = localStorage.getItem("userId");
  //   let selectedBranchId = localStorage.getItem("selectedBranchId");
  //   if (prevBranchId !== selectedBranchId) {
  //     data = {
  //       branchId: selectedBranchId,
  //       clientName: "",
  //       clientNumber: "",
  //       dateFrom: "1900-01-01T00:00:00+00",
  //       email: "",
  //       mailBox: "INBOX",
  //       pageSize: 10,
  //       pageNumber: 1,
  //       userId: userId,
  //     };
  //
  //     setPayloadData(data);
  //     setLoading(true);
  //     onSearchImapEmail(data)
  //       .then((res) => {
  //         // form.resetFields();
  //         setDefaultCurrent(1);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // });

  useEffect(() => {
    let options = [
      { value: "INBOX", text: "INBOX" },
      { value: "[Gmail]", text: "[Gmail]" },
      { value: "[Gmail]/All Mail", text: "All Mail" },
      { value: "[Gmail]/Drafts", text: "Drafts" },
      { value: "[Gmail]/Important", text: "Important" },
      { value: "[Gmail]/Sent Mail", text: "Sent Mail" },
      { value: "[Gmail]/Spam", text: "Spam" },
      { value: "[Gmail]/Starred", text: "Starred" },
      { value: "[Gmail]/Trash", text: "Trash" },
    ];
    var mailBoxOp = [];
    if (accountType === "Outlook") {
      labels.map((mailBox) => {
        mailBoxOp.push(<Option value={mailBox.value}>{mailBox.text}</Option>);
      });
      setMailBoxOpt(mailBoxOp);
    } else {
      options.map((opt) => {
        mailBoxOp.push(<Option value={opt.value}>{opt.text}</Option>);
      });
      setMailBoxOpt(mailBoxOp);
    }
  }, [accountType]);

  useEffect(() => {
    if (
      searchMailData &&
      searchMailData.mails &&
      searchMailData.mails.length > 0
    ) {
      searchMailData &&
        searchMailData.mails &&
        searchMailData.mails.map((data, index) => {
          if (data) {
            data.index = index;
            data.key = `${index + 1}`;
          }
        });
      setMailList(searchMailData.mails);
    }
  }, [searchMailData]);
  useEffect(() => {
    if (mainClientsData) {
      setSearchedClients(mainClientsData);
    }
    if (potentialClientsData) {
      setSearchedPotentialClients(potentialClientsData);
    }
    if (searchEmployerRes) {
      setSearchedEmployer(searchEmployerRes);
    }
  }, [mainClientsData, potentialClientsData, searchEmployerRes]);
  const [form] = Form.useForm();
  const { Option } = Select;
  const clientsList = [];
  const potentialClientsList = [];
  const employersList = [];

  if (
    searchedClients &&
    searchedClients.clients &&
    searchedClients.clients.length > 0
  ) {
    for (let i = 0; i < searchedClients.clients.length; i++) {
      clientsList.push(
        <Option key={searchedClients.clients[i].id}>
          {searchedClients.clients[i].firstName +
            " " +
            searchedClients.clients[i].lastName}
        </Option>
      );
    }
  }
  if (
    searchedPotentialClients &&
    searchedPotentialClients.potentialClients &&
    searchedPotentialClients.potentialClients.length > 0
  ) {
    for (let i = 0; i < searchedPotentialClients.potentialClients.length; i++) {
      potentialClientsList.push(
        <Option key={searchedPotentialClients.potentialClients[i].id}>
          {searchedPotentialClients.potentialClients[i].label}
        </Option>
      );
    }
  }

  if (
    searchEmployerRes &&
    searchEmployerRes.items &&
    searchEmployerRes.items.length > 0
  ) {
    for (let i = 0; i < searchEmployerRes.items.length; i++) {
      employersList.push(
        <Option key={searchEmployerRes.items[i].id}>
          {searchEmployerRes.items[i].name}
        </Option>
      );
    }
  }

  var d = new Date();
  var byDate = d.setMonth(d.getMonth() - 1);

  const onSearch = (values) => {
    var data = null;
    let userId = localStorage.getItem("userId");
    // setIsSearch(true);
    setLoading(true);
    //
    // console.log("valuesvaluesvalues", values);
    if (buttonClick === "showAll") {
      data = {
        clientName: "",
        clientNumber: "",
        dateFrom: values.sentSince || "1900-01-01T00:00:00+00",
        email: "",
        mailBox: values.mailBox || "INBOX",
        pageSize: 10,
        pageNumber: 1,
        userId: userId,
      };
    } else {
      data = {
        clientNumber: values.clientNumber || "",
        clientName: values.clientName || "",
        dateFrom: values.sentSince || "1900-01-01T00:00:00+00",
        email: values.email || "",
        mailBox: values.mailBox || "INBOX",
        pageSize: pageSize,
        pageNumber: 1,
        // userId: userId,
      };
    }
    setMailList([]);

    setPayloadData(data);

    onSearchImapEmail(data)
      .then(() => {
        // form.resetFields();
        setDefaultCurrent(1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Imap not integrated!");
      });
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setPageSize(value);
  };

  const handleChangeLabel = (value) => {
    console.log(`selected ${value}`);
    setLabel(value);
  };

  const handleSectorChange = (value) => {
    console.log(`selected ${value}`);
    setIsSelector(value);
  };

  const onChangeHandler = (value) => {
    setSelectedClient(value);
  };

  const onChangePotentialHandler = (value) => {
    setSelectedPotentialClient(value);
  };

  const onChangeEmployer = (value) => {
    setSelectedEmployer(value);
  };

  const onCheckEmail = (data, pageNumber, pageSize) => {
    let tempData;
    let _data = [];
    if (data && data.length > 0) {
      tempData = data.filter((item, i, ar) => ar.indexOf(item) === i);
    }
    if (tempData && tempData.length > 0) {
      for (let ind = 0; ind < tempData.length; ind++) {
        _data.push(tempData[ind].id);
      }
    }

    setPageNo(pageNumber);
    setPageSize(pageSize);
    setId(_data);
    setCheckedEmails(tempData);
  };

  const handleSearch = (val) => {
    console.log("search:", val);
    if (val.length > 2 && notSearch) {
      notSearch = false;

      setTimeout(() => {
        setSearchLoading(true);
        setOptions(searchResult([]));
        onSearchClient(val)
          .then((res) => {
            setSearchLoading(false);
            notSearch = true;
            setOptions(
              res && res.payload && res.payload.clients
                ? searchResult(res.payload.clients)
                : []
            );
          })
          .catch((err) => {
            notSearch = true;
            setSearchLoading(false);
          });
      }, 1500);
    }
  };

  const onSelect = (value, opt) => {
    setSelectedClientName(value);
    setSelectedClient(opt.label.key);
  };

  const onSelectPotential = (value, opt) => {
    setSelectedPotentialClientName(value);
    setSelectedPotentialClient(opt.label.key);
  };

  const onSelectEmployer = (value, opt) => {
    setSelectedEmployerName(value);
    setSelectedEmployer(opt.label.key);
  };

  const searchPotentialClient = (val) => {
    console.log("search:", val);
    if (val.length > 2 && notSearch) {
      notSearch = false;
      setTimeout(() => {
        setSearchLoading(true);
        setPcOptions(searchResult([]));
        onSearchPotentialClient(val)
          .then((res) => {
            notSearch = true;
            setSearchLoading(false);
            setPcOptions(
              res && res.payload && res.payload.potentialClients
                ? searchResult(res.payload.potentialClients)
                : []
            );
          })
          .catch((err) => {
            notSearch = true;
            setSearchLoading(false);
          });
      }, 1500);
    }
  };

  const searchEmployer = (val) => {
    console.log("search:", val);
    if (val.length > 2 && notSearch) {
      notSearch = false;
      setTimeout(() => {
        setSearchLoading(true);
        setEmployerOptions(searchResult([]));
        let data = {
          name: val,
          business: "",
          city: "",
          jobSector: "",
          occupation: "",
          yearsOfBusiness: "",
          isPotential: false,
          employerType: "",
          pageSize: 10,
          pageNumber: 1,
        };
        onSearchEmployer(data)
          .then((res) => {
            notSearch = true;
            setSearchLoading(false);
            setEmployerOptions(
              res && res.payload && res.payload.items
                ? searchResult(res.payload.items)
                : []
            );
          })
          .catch((err) => {
            notSearch = true;
            setSearchLoading(false);
          });
      });
    }
  };
  const setPagination = (pSize, pNumber) => {
    setImapTablePageSize(pSize);
    setImapTablePageNumber(pNumber);
  };
  const onImportClientEmail = () => {
    var userId = localStorage.getItem("userId");
    let count = 0;
    if (checkedEmails && checkedEmails.length > 0) {
      setSearchLoading(true);
      for (let i = 0; i < checkedEmails.length; i++) {
        let data = {
          uids: [checkedEmails[i].id],
          clientName: payloadData.clientName,
          clientNumber: payloadData.clientNumber,
          dateFrom: payloadData.dateFrom || "1900-01-01T00:00:00+00",
          email: payloadData.email,
          mailBox: payloadData.mailBox,
          pageSize: imapTablePSize,
          pageNumber: imapTablePNumber,
          // userId: payloadData.userId,
        };
        const opt = {
          url: "v1/emailimport/SpecificEmailImportSettings",
        };
        opt.types = [
          "SPECIFIC_EMAIL_IMPORT_SUCCESS",
          "SPECIFIC_EMAIL_IMPORT_FAILURE",
        ];

        apiRefresh.post(opt, data).then((res) => {
          let userName = localStorage.getItem("userName");

          let myData = {
            clientName: selectedClient,
            logMessage:
              " Email with subject : " +
              checkedEmails[i].subject +
              "\n" +
              '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
              '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
              '<div style="display: none">' +
              res.mails[0].emailMessage +
              "</div>" +
              "<br />" +
              " created by " +
              userName,
            notClientPart: true,
            linkedClient: selectedClient,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client import",
            invoiceId: "0",
          };

          activityData(myData);
          if (res && res.mails) {
            var semiColon = "";
            for (var j = 0; j < res.mails.length; j++) {
              res.mails[j].attachmentUrl = "";
              res.mails[j].attachmentName = "";
              for (var k = 0; k < res.mails[j].attachments.length; k++) {
                if (k > 0) {
                  semiColon = ";";
                } else {
                  semiColon = "";
                }
                res.mails[j].attachmentUrl +=
                  semiColon + res.mails[j].attachments[k].fileURL;
                res.mails[j].attachmentName +=
                  semiColon + res.mails[j].attachments[k].fileName;
              }
            }
          }

          let importData = {
            id: 0,
            emailMessage:
              res &&
              res.mails[0] &&
              res.mails[0].emailMessage &&
              res.mails[0].emailMessage,
            isRead: false,
            attachmentName: res.mails[0] && res.mails[0].attachmentName,
            attachmentUrl: res.mails[0] && res.mails[0].attachmentUrl,
            sizeInKB: checkedEmails[i].sizeInKB,
            subject: checkedEmails[i].subject,
            from: checkedEmails[i].from,
            import: checkedEmails[i].import,
            to: checkedEmails[i].to,
            cc: checkedEmails[i].cc,
            bcc: checkedEmails[i].bcc,
            notClients: false,
            clientReply: false,
            clientReplyMail: 0,
            importMessageId: checkedEmails[i].importMessageId,
            draft: false,
            emailType: 0,
            importText: "",
            clientId: selectedClient,
            importedDate: checkedEmails[i].date || new Date(),
            autoImport: false,
            isSent: false,
            clientEmailType: 0,
            timeStamp: moment(checkedEmails[i].date).format(
              "YYYY-MM-DDT00:00:00+00:00"
            ),
          };

          onImportManualEmail(importData)
            .then((res) => {
              message.success("Imported");

              setSelectedClient("");
              setUncheckCheckbox(true);
              count++;
              if (count === checkedEmails.length) {
                let data = {
                  uids: id,
                  clientName: "",
                  clientNumber: "",
                  dateFrom: "1900-01-01T00:00:00+00",
                  email: "",
                  mailBox: "INBOX",
                  pageSize: pageSize,
                  pageNumber: pageNo,
                  // userId: userId,
                };
                const opt = {
                  url: "v1/emailimport/SpecificEmailImportSettings",
                };
                opt.types = [
                  "SPECIFIC_EMAIL_IMPORT_SUCCESS",
                  "SPECIFIC_EMAIL_IMPORT_FAILURE",
                ];

                apiRefresh
                  .post(opt, data)
                  .then((res) => {
                    let _data = {
                      clientNumber: payloadData.clientNumber || "",
                      clientName: payloadData.clientName || "",
                      dateFrom:
                        payloadData.sentSince || "1900-01-01T00:00:00+00",
                      email: payloadData.email || "",
                      mailBox: payloadData.mailBox || "INBOX",
                      pageSize: pageSize,
                      pageNumber: pageNo,
                    };
                    onSearchImapEmail(_data)
                      .then(() => {
                        // form.resetFields();
                        setDefaultCurrent(pageNo);
                        setSearchLoading(false);
                      })
                      .catch((err) => {
                        setSearchLoading(false);
                      });
                  })
                  .catch((res) => {
                    setSearchLoading(false);
                  });
              }
            })
            .catch((err) => {
              message.error("Failed!");
              setSearchLoading(false);
            });
        });
      }
    }
  };

  const onUncheckCheckbox = (pSize, pNumber) => {
    setSearchLoading(true);
    let userId = localStorage.getItem("userId");
    setUncheckCheckbox(false);
    let data = {
      clientNumber: payloadData.clientNumber || "",
      clientName: payloadData.clientName || "",
      dateFrom: payloadData.sentSince || "1900-01-01T00:00:00+00",
      email: payloadData.email || "",
      mailBox: payloadData.mailBox || "INBOX",
      pageSize: pSize,
      pageNumber: pNumber,
    };

    onSearchImapEmail(data)
      .then(() => {
        setSearchLoading(false);
      })
      .then(() => {
        setSearchLoading(false);
      });
  };

  const onImportPotentialClientEmail = () => {
    var userId = localStorage.getItem("userId");
    let count = 0;
    if (checkedEmails && checkedEmails.length > 0) {
      setSearchLoading(true);
      for (let i = 0; i < checkedEmails.length; i++) {
        let data = {
          uids: [checkedEmails[i].id],
          clientName: payloadData.clientName,
          clientNumber: payloadData.clientNumber,
          dateFrom: payloadData.dateFrom || "1900-01-01T00:00:00+00",
          email: payloadData.email,
          mailBox: payloadData.mailBox,
          pageSize: imapTablePSize,
          pageNumber: imapTablePNumber,
          // userId: payloadData.userId,
        };
        const opt = {
          url: "v1/emailimport/SpecificEmailImportSettings",
        };
        opt.types = [
          "SPECIFIC_EMAIL_IMPORT_SUCCESS",
          "SPECIFIC_EMAIL_IMPORT_FAILURE",
        ];

        apiRefresh.post(opt, data).then((res) => {
          if (res && res.mails) {
            var semiColon = "";
            for (var j = 0; j < res.mails.length; j++) {
              res.mails[j].attachmentUrl = "";
              res.mails[j].attachmentName = "";
              for (var k = 0; k < res.mails[j].attachments.length; k++) {
                if (k > 0) {
                  semiColon = ";";
                } else {
                  semiColon = "";
                }
                res.mails[j].attachmentUrl +=
                  semiColon + res.mails[j].attachments[k].fileURL;
                res.mails[j].attachmentName +=
                  semiColon + res.mails[j].attachments[k].fileName;
              }
            }
          }
          let importData = {
            id: 0,
            emailMessage: res && res.mails[0].emailMessage,
            isRead: false,
            attachmentName: res.mails[0] && res.mails[0].attachmentName,
            attachmentUrl: res.mails[0] && res.mails[0].attachmentUrl,
            sizeInKB: checkedEmails[i].sizeInKB,
            subject: checkedEmails[i].subject,
            from: checkedEmails[i].from,
            import: checkedEmails[i].import,
            to: checkedEmails[i].to,
            cc: checkedEmails[i].cc,
            bcc: checkedEmails[i].bcc,
            notClients: false,
            clientReply: false,
            clientReplyMail: 0,
            cUserId: userId,
            importMessageId: checkedEmails[i].importMessageId,
            draft: false,
            emailType: 0,
            importText: "",
            potentialClientId: selectedPotentialClient,
            importedDate: checkedEmails[i].date,
            autoImport: false,
            isSent: false,
            clientEmailType: 0,
            timeStamp: moment(checkedEmails[i].date).format(
              "YYYY-MM-DDT00:00:00+00:00"
            ),
          };

          onImportManualPotentialEmail(importData)
            .then((res) => {
              message.success("Imported");
              setSelectedPotentialClient("");
              setUncheckCheckbox(true);
              count++;
              if (count === checkedEmails.length) {
                let data = {
                  uids: id,
                  clientName: "",
                  clientNumber: "",
                  dateFrom: "1900-01-01T00:00:00+00",
                  email: "",
                  mailBox: "INBOX",
                  pageSize: pageSize,
                  pageNumber: pageNo,
                  // userId: userId,
                };
                const opt = {
                  url: "v1/emailimport/SpecificEmailImportSettings",
                };
                opt.types = [
                  "SPECIFIC_EMAIL_IMPORT_SUCCESS",
                  "SPECIFIC_EMAIL_IMPORT_FAILURE",
                ];

                apiRefresh
                  .post(opt, data)
                  .then((res) => {
                    let _data = {
                      clientNumber: payloadData.clientNumber || "",
                      clientName: payloadData.clientName || "",
                      dateFrom:
                        payloadData.sentSince || "1900-01-01T00:00:00+00",
                      email: payloadData.email || "",
                      mailBox: payloadData.mailBox || "INBOX",
                      pageSize: pageSize,
                      pageNumber: pageNo,
                      // userId: userId,
                    };

                    onSearchImapEmail(_data)
                      .then(() => {
                        // form.resetFields();
                        setDefaultCurrent(pageNo);
                        setSearchLoading(false);
                      })
                      .catch((err) => {
                        setSearchLoading(false);
                      });
                  })
                  .catch((res) => {
                    setSearchLoading(false);
                  });
              }
            })
            .catch((error) => {
              message.error("Failed!");
              setSearchLoading(false);
            });
        });
      }
    }
  };

  const onImportEmployerEmail = () => {
    var userId = localStorage.getItem("userId");
    let count = 0;
    if (checkedEmails && checkedEmails.length > 0) {
      setSearchLoading(true);
      for (let i = 0; i < checkedEmails.length; i++) {
        let data = {
          uids: [checkedEmails[i].id],
          clientName: payloadData.clientName,
          clientNumber: payloadData.clientNumber,
          dateFrom: payloadData.dateFrom || "1900-01-01T00:00:00+00",
          email: payloadData.email,
          mailBox: payloadData.mailBox,
          pageSize: imapTablePSize,
          pageNumber: imapTablePNumber,
          // userId: payloadData.userId,
        };
        const opt = {
          url: "v1/emailimport/SpecificEmailImportSettings",
        };
        opt.types = [
          "SPECIFIC_EMAIL_IMPORT_SUCCESS",
          "SPECIFIC_EMAIL_IMPORT_FAILURE",
        ];

        apiRefresh.post(opt, data).then((res) => {
          if (res && res.mails) {
            var semiColon = "";
            for (var j = 0; j < res.mails.length; j++) {
              res.mails[j].attachmentUrl = "";
              res.mails[j].attachmentName = "";
              for (var k = 0; k < res.mails[j].attachments.length; k++) {
                if (k > 0) {
                  semiColon = ";";
                } else {
                  semiColon = "";
                }
                res.mails[j].attachmentUrl +=
                  semiColon + res.mails[j].attachments[k].fileURL;
                res.mails[j].attachmentName +=
                  semiColon + res.mails[j].attachments[k].fileName;
              }
            }
          }
          let importData = {
            id: 0,
            emailMessage: res && res.mails[0].emailMessage,
            isRead: false,
            attachmentName: res.mails[0] && res.mails[0].attachmentName,
            attachmentUrl: res.mails[0] && res.mails[0].attachmentUrl,
            sizeInKB: checkedEmails[i].sizeInKB,
            subject: checkedEmails[i].subject,
            from: checkedEmails[i].from,
            import: checkedEmails[i].import,
            to: checkedEmails[i].to,
            cc: checkedEmails[i].cc,
            bcc: checkedEmails[i].bcc,
            notClients: false,
            clientReply: false,
            clientReplyMail: 0,
            cUserId: userId,
            importMessageId: checkedEmails[i].importMessageId,
            draft: false,
            emailType: 0,
            importText: "",
            employerId: selectedEmployer,
            importedDate: checkedEmails[i].date,
            autoImport: false,
            isSent: false,
            clientEmailType: 0,
            timeStamp: moment(checkedEmails[i].date).format(
              "YYYY-MM-DDT00:00:00+00:00"
            ),
          };

          onImportManualEmployerEmail(importData)
            .then((res) => {
              message.success("Imported");
              setSelectedEmployer("");
              setUncheckCheckbox(true);
              count++;

              if (count === checkedEmails.length) {
                let data = {
                  uids: id,
                  clientName: "",
                  clientNumber: "",
                  dateFrom: "1900-01-01T00:00:00+00",
                  email: "",
                  mailBox: "INBOX",
                  pageSize: pageSize,
                  pageNumber: pageNo,
                  // userId: userId,
                };
                const opt = {
                  url: "v1/emailimport/SpecificEmailImportSettings",
                };
                opt.types = [
                  "SPECIFIC_EMAIL_IMPORT_SUCCESS",
                  "SPECIFIC_EMAIL_IMPORT_FAILURE",
                ];

                apiRefresh
                  .post(opt, data)
                  .then((res) => {
                    let _data = {
                      clientNumber: payloadData.clientNumber || "",
                      clientName: payloadData.clientName || "",
                      dateFrom:
                        payloadData.sentSince || "1900-01-01T00:00:00+00",
                      email: payloadData.email || "",
                      mailBox: payloadData.mailBox || "INBOX",
                      pageSize: pageSize,
                      pageNumber: pageNo,
                      // userId: userId,
                    };

                    onSearchImapEmail(_data)
                      .then(() => {
                        // form.resetFields();
                        setDefaultCurrent(pageNo);
                        setSearchLoading(false);
                      })
                      .catch((err) => {
                        setSearchLoading(false);
                      });
                  })
                  .catch((res) => {
                    setSearchLoading(false);
                  });
              }
            })
            .catch((err) => {
              message.error("Failed!");
              setSearchLoading(false);
            });
        });
      }
    }
  };

  const searchResult = (query) =>
    query.map((_, idx) => {
      let category;
      if (_.name) {
        category = _.name;
      } else if (_.label) {
        category = _.label;
      } else {
        category = `${_.firstName} ${_.lastName}`;
      }
      return {
        value: category,
        label: (
          <div
            className="search-client-card-cont"
            key={_.id}
            style={{ display: "block" }}
          >
            <span>{category}</span>
            <div style={{ display: "flex" }}>
              <div>
                <span class="date-text">DOB :</span>
              </div>
              <div>
                <span class="date-text">
                  &nbsp;
                  {_.dateOfBirth
                    ? _.dateOfBirth !== "1900-01-01T00:00:00+00:00"
                      ? moment(_.dateOfBirth).format("DD/MM/YYYY")
                      : ""
                    : _.dob && _.dob !== "1900-01-01T00:00:00+00:00"
                    ? moment(_.dob).format("DD/MM/YYYY")
                    : ""}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <span class="date-text">Email :</span>
              </div>
              <div>
                <span class="date-text">&nbsp;{_.email}</span>
              </div>
            </div>
          </div>
        ),
      };
    });

  return (
    <Fragment>
      <div>
        <Spin spinning={searchLoading || loading}>
          <div style={{ display: "flex" }}>
            <div className="client-tag">
              <div className="employer-manag">
                <div className="client-tag-form"></div>
                <div className="">
                  <div>
                    <div>
                      <Form
                        form={form}
                        onFinish={onSearch}
                        // {...layout}
                        name="employer-form"
                        initialValues={{ remember: true }}
                        colon={false}
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                      >
                        {" "}
                        <div className="imap-set-form text-style margin-top-34">
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={12}>
                              <Form.Item
                                label="MAIL BOX"
                                name="mailBox"

                                // rules={[
                                //   {
                                //     required: false,
                                //     message: "Please input your username!",
                                //   },
                                // ]}
                              >
                                {/*<Select  onChange={handleChangeLabel} defaultValue={"All Mail"}>*/}
                                {/*  {labels.map((data) => {*/}
                                {/*    return (*/}
                                {/*      <Option value={data.value}>{data.text}</Option>*/}
                                {/*    )*/}
                                {/*  })}*/}
                                {/*</Select>*/}
                                <Select defaultValue="INBOX">
                                  {mailBoxOpt}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="DATE FILTER"
                                name="dateFilter"
                                // className="d-flex"
                              >
                                <Select
                                //   onChange={handleChange}
                                >
                                  <option value="">Please select</option>
                                  <option value="1">1 Day</option>
                                  <option value="2">2 Days</option>
                                  <option value="4">4 Days</option>
                                  <option value="7">1 Week</option>
                                  <option value="14">2 Weeks</option>
                                  <option value="21">3 Weeks</option>
                                  <option value="30">1 Month</option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={12}>
                              <Form.Item
                                label="SENT SINCE"
                                name="sentSince"
                                // className="d-flex"
                              >
                                <DatePicker format={dateFormat} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="CLIENT NUMBER"
                                name="clientNumber"
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={12}>
                              <Form.Item label="EMAIL" name="email">
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="CLIENT NAME"
                                name="clientName"
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={12}>
                              <Form.Item
                                label="PAGE SIZE"
                                name="pageSize"
                                rules={[
                                  {
                                    required: false,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={pageSize}
                                  onChange={handleChange}
                                >
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                  <option value={30}>30</option>
                                  <option value={40}>40</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                  <option value={150}>150</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={6}></Col>
                            {/* <Col style={{ paddingLeft: 0, paddingRight: 5 }}>
                                <Form.Item>
                                  <Button
                                    onClick={() => setButtonClick("showAll")}
                                    className="employer-btn button-blue"
                                    type="primary"
                                    // htmlType="submit"
                                  >
                                    Show All
                                  </Button>
                                </Form.Item>
                              </Col> */}
                            <Col style={{ paddingLeft: 5, paddingRight: 0 }}>
                              <Form.Item>
                                <Button
                                  onClick={() => setButtonClick("search")}
                                  className="employer-btn button-blue"
                                  htmlType="submit"
                                  type="primary"
                                >
                                  Search
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </div>
                    <div className="reminder-set-form text-style margin-top-12">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <Form.Item label="CLIENTS">
                            {/* <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Search a client"
                              optionFilterProp="children"
                              onChange={onChangeHandler}
                              onSearch={searchClient}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              value={selectedClient}
                            >
                              <Option key="">Select</Option>
                              {clientsList}
                            </Select> */}
                            <AutoComplete
                              dropdownMatchSelectWidth={252}
                              // style={{ width: 300 }}
                              options={options}
                              onSelect={onSelect}
                              onSearch={handleSearch}
                              notFoundContent="Not Found"
                              placeholder="Search Client"
                              value={selectedClientName}
                              onChange={(val) => setSelectedClientName(val)}
                            ></AutoComplete>
                          </Form.Item>
                          <Button
                            onClick={onImportClientEmail}
                            className="employer-btn button-blue"
                            type="primary"
                          >
                            Import
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="POTENTIAL CLIENTS"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <AutoComplete
                              dropdownMatchSelectWidth={252}
                              // style={{ width: 300 }}
                              options={pcOptions}
                              onSelect={onSelectPotential}
                              onSearch={searchPotentialClient}
                              notFoundContent="Not Found"
                              placeholder="Search Potential Client"
                              value={selectedPotentialClientName}
                              onChange={(val) =>
                                setSelectedPotentialClientName(val)
                              }
                            ></AutoComplete>
                          </Form.Item>
                          <Button
                            onClick={onImportPotentialClientEmail}
                            className="employer-btn button-blue"
                            type="primary"
                          >
                            Import
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label="EMPLOYER"
                            rules={[
                              {
                                required: false,
                                message: "Required!",
                              },
                            ]}
                          >
                            <AutoComplete
                              dropdownMatchSelectWidth={252}
                              // style={{ width: 300 }}
                              options={employerOptions}
                              onSelect={onSelectEmployer}
                              onSearch={searchEmployer}
                              notFoundContent="Not Found"
                              placeholder="Search Employer"
                              value={selectedEmployerName}
                              onChange={(val) => setSelectedEmployerName(val)}
                            ></AutoComplete>
                          </Form.Item>
                          <Button
                            onClick={onImportEmployerEmail}
                            className="employer-btn button-blue"
                            type="primary"
                          >
                            Import
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    {mailList && mailList.length > 0 && (
                      <ImapEmailTable
                        pageSize={pageSize}
                        defaultCurrent={defaultCurrent}
                        searchMailData={mailList}
                        onCheckEmail={onCheckEmail}
                        totalEmails={
                          searchMailData &&
                          searchMailData.total &&
                          searchMailData.total
                        }
                        payloadData={payloadData}
                        onSearchImapEmail={onSearchImapEmail}
                        uncheckCheckbox={uncheckCheckbox}
                        setUncheckCheckbox={onUncheckCheckbox}
                        setPagination={setPagination}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default MapEmail;
