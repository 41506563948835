import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Image,
  Row,
  Col,
  Select,
  Upload,
  Modal,
  message,
  Spin,
  Checkbox,
  Radio,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Images } from "../../Themes";
import { PlusOutlined } from "@ant-design/icons";
import history from "./../../services/history";
import queryString from "query-string";

// const formData = new FormData();
// const history = useHistory();
const { Option } = Select;

const SignUpForm = ({
  onAddSignUpForm,
  onGetTextEncoding,
  textEncodingRes,

  onUploadFile,
  addUploadFileRes,
  onGetCountries,
  countriesData,
}) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewVisible, SetPreviewVisible] = useState(false);
  const [previewImage, SetPreviewImage] = useState("");
  const [previewTitle, SetPreviewTitle] = useState("");
  const [isSignReginter, SetIsSignRegister] = useState(false);
  const [visibleDisclaimerModal, SetVisibleDisclaimerModal] = useState(false);
  const [signupFormData, SetSignupFormData] = useState(null);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [countriesArray, setCountriesArray] = useState([]);

  const [form] = Form.useForm();
  const location = useLocation();
  const initialQueryValue = queryString.parse(location.search);

  useEffect(() => {
    // setLoading(true);
    if (!isSignReginter) {
      if (initialQueryValue) {
        SetIsSignRegister(true);
        // onGetTextEncoding(initialQueryValue && initialQueryValue.params).then(
        //   (res) => {
        //     form.setFieldsValue({
        //       email: res && res.payload,
        //     });
        //   }
        // );
      }
    }
  }, [form, initialQueryValue, isSignReginter, onGetTextEncoding]);

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    onGetCountries();
  }, []);

  useEffect(() => {
    var country = [];
    if (countriesData && countriesData.items.length > 0) {
      for (let i = 0; i < countriesData.items.length; i++) {
        country.push(
          <Option value={countriesData.items[i].name}>
            {countriesData.items[i].name}
          </Option>
        );
      }
      setCountriesArray(country);
    }
  }, [countriesData]);

  const onHandleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleSubmit = () => {};

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    SetPreviewVisible(true);
    SetPreviewImage(file.url || file.preview);

    SetPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => SetPreviewVisible(false);

  const onFinish = (values) => {
    // setLoading(true);
    SetVisibleDisclaimerModal(true);
    SetSignupFormData(values);
  };

  const handleCancelDisclaimerModal = () => {
    SetVisibleDisclaimerModal(false);
    SetSignupFormData(null);
  };

  const HandleSignupSubmittion = () => {
    let data = {
      roleId: 0,
      title: signupFormData.designation || "",
      userImage: "",
      forgotPasswordBit: false,
      lastSignedIn: new Date(), // currnent data
      signature: "",
      signatureImage: "",
      timeZone: "",
      comission: 0,
      agreementUrl: "",
      password: signupFormData.password,
      description: "",
      fullName: signupFormData.full_name,
      alternateEmail: signupFormData.email || "",
      userStatus: 1,
      isManager: true,
      isOwner: true,
      image: "",
      designation: signupFormData.designation || "",
      name: signupFormData.company_name || "",
      country: signupFormData.country || "",
      address: "",
      gender: signupFormData.gender || "",
      phone: signupFormData.mobile || "",
      bankAccountTitle: "",
      bankAccountNumber: "",
      gstNumber: "",
      gstPercent: "",
      currency: "",
      createdBy: "00000000-0000-0000-0000-000000000000",
      // email: textEncodingRes && textEncodingRes,
      email: signupFormData.email || "",
      branchName: signupFormData.company_name || "",
      userDescription: "",
      addressLine1: "",
      addressLine2: "",
      zipcode: "",
      city: signupFormData.city || "",
      ownerId: 0,
      phone1: signupFormData.telephone_number || "",
      phone2: signupFormData.telephone_number || "",
      mobile: signupFormData.mobile || "",
      fax: "",
      logo: "", // logo
      status: "",
      allowedUsers: 0,
      allowSupervisor: true,
      contractSignedDate: "1900-01-01T00:00:00+00:00",
      contractExpiryDate: "1900-01-01T00:00:00+00:00",
      contractUrl: "",
      storageLimit: 10,
      notes: "",
    };

    let selectedFile = null;
    selectedFile = fileList.length > 0 ? fileList[0].originFileObj : null;
    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("Data", JSON.stringify(data));

    if (agreeChecked) {
      setLoading(true);
      onAddSignUpForm(formData)
        .then((res) => {
          setLoading(false);
          if (res.payload.id == "Email exists") {
            message.error(
              "Email already exist, Please try another email for Signup!"
            );
            return;
          }
          message.success("Successfully Created!");
          handleCancelDisclaimerModal();
          history.push("/login");
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          message.error("Failed!");
        });
    } else {
      message.error("Please check agreement checkbox!");
    }
  };

  const onChange = (e) => {
    setAgreeChecked(e.target.checked);
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div className="signup-container">
          <Row style={{ justifyContent: "center" }} gutter={10}>
            <Col>
              <Image src={Images.logo} preview={false} />
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }} gutter={10}>
            <Col>
              <div className="watch-video">
                <Button type="primary" htmlType="submit" size="large">
                  WATCH VIDEO
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onFinish={onFinish}
            form={form}
            className="width-100 "
            name="main"
          >
            <div className="  add-employer-para signup-fields">
              <Row className="signup-text">
                <span>TELL US ABOUT YOUR SELF</span>
              </Row>
              {/* <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Upload Your Company Logo</p>
                </Col>
                <Col span={12} className="upload-button">
                  <Form.Item required={false}>
                    <Upload
                      listType="picture-card"
                      // fileList={fileList}
                      beforeUpload={() => false}
                      onPreview={handlePreview}
                      onChange={onHandleChange}
                      single
                    >
                      {fileList && fileList.length < 1 ? (
                        <div className="import-file-button-sec">
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        </div>
                      ) : null}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Email*</p>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                      {
                        tyoe: "email",
                        message: "Invalid!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Full Name*</p>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="full_name"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                className="d-aligin-center"
                gutter={8}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Col span={12}>
                  <p>Password*</p>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="  add-employer-para signup-fields">
              <Row className="signup-text">
                <span>
                  IN ORDER FOR EZYMIGRATE TO WORK BEST FOR YOUR COMPANY, PLEASE
                  FILL A FEW MORE DETAILS BELOW ?
                </span>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Telephone Number</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="telephone_number" required={false}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Company Name*</p>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company_name"
                    required={false}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Required!",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Select Gender</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="gender" required={false}>
                    <Radio.Group>
                      <Radio value={"Male"}>Male</Radio>
                      <Radio value={"Female"}>Female</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Mobile Number</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" required={false}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Designation In Company</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="designation" required={false}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>City</p>
                </Col>
                <Col span={12}>
                  <Form.Item name="city" required={false}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="d-aligin-center" gutter={8}>
                <Col span={12}>
                  <p>Country</p>
                </Col>
                <Col span={12}>
                  <div className="select-border" style={{ marginTop: 0 }}>
                    <Form.Item name="country" required={false}>
                      {/* <div
                      className="select-border multi-select-option"
                      style={{ marginTop: 0, width: "55%" }}
                    > */}
                      <Select
                        className="add-background-color"
                        showSearch
                        optionFilterProp="children"
                        bordered={false}
                        placeholder="Country"
                        // value={defaultCountry}
                        // onChange={handleChangeCountry}
                        style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                      >
                        {countriesArray}
                      </Select>
                      {/* </div> */}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <Form.Item className="save-signup">
              <Button
                className="form-btn employer-btn button-blue"
                type="primary"
                htmlType="submit"
              >
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div>
          <Modal
            title="Disclaimer"
            visible={visibleDisclaimerModal}
            onOk={HandleSignupSubmittion}
            onCancel={handleCancelDisclaimerModal}
            // footer={null}
            maskClosable={false}
            width="80%"
          >
            <Spin spinning={loading}>
              <Row>
                <Col span={24}>
                  <div
                    className="term-condition-container"
                    style={{ padding: 20 }}
                  >
                    <h4>
                      1&nbsp;&nbsp;&nbsp;<strong>APPLICATION OF TERMS</strong>
                    </h4>
                    <div>
                      <span>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        Application and agreement:
                      </span>
                      <span>
                        &nbsp;&nbsp;These Terms apply to your use of the
                        Ezymigrate Service (as that term is defined below). By
                        setting up an account with Ezymigrate:-
                      </span>
                    </div>
                    <div style={{ paddingLeft: 30 }}>
                      <span>
                        a.&nbsp;&nbsp;&nbsp;&nbsp;You agree to these Terms; and
                      </span>
                    </div>
                    <div style={{ paddingLeft: 30 }}>
                      <span>
                        b.&nbsp;&nbsp;&nbsp;&nbsp;Where your access and use is
                        on behalf of another person (e.g. a company), you
                        confirm that you are authorised to, and do in fact,
                        agree to these Terms on that person’s behalf and that,
                        by agreeing to these Terms on that person’s behalf, that
                        person is bound by these Terms.
                      </span>
                    </div>
                    <br />
                    <div>
                      <span>1.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        If you do not agree:
                      </span>
                      <span>
                        &nbsp;&nbsp; If you do not agree to these Terms, you are
                        not authorised to access and use the Services, and you
                        must immediately stop doing so.
                      </span>
                    </div>
                    <h4>
                      2&nbsp;&nbsp;&nbsp;<strong> INTERPRETATION</strong>
                    </h4>
                    <div>
                      <span>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>Definitions:</span>
                      <span>
                        &nbsp;&nbsp;In these Terms, the following terms have the
                        stated meaning:
                      </span>
                    </div>
                    <table>
                      <thead>
                        <tr style={{ backgroundColor: "grey", height: 35 }}>
                          <td style={{ width: "50%", paddingLeft: 10 }}>
                            <strong>Term</strong>
                          </td>
                          <td style={{ width: "50%" }}>
                            <strong>Meaning</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Client Portal</td>
                          <td>
                            Has the meaning given in the definition of
                            Ezymigrate Service
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Confidential
                            <br /> Information
                          </td>
                          <td>
                            The terms of the Agreement and any information that
                            is not public knowledge and that is obtained from
                            the other party in the course of, or in connection
                            with, the provision and use of the Ezymigrate
                            Service. Our Confidential Information includes
                            Intellectual Property owned by us (or our
                            licensors), including the Ezymigrate Software. Your
                            Confidential Information includes the Data.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>Data</td>
                          <td>
                            All data, content, and information (including
                            Personal Information) owned, held, used or created
                            by you or on your behalf that is stored using, or
                            inputted into, the Ezymigrate Service.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Ezymigrate
                            <br />
                            Service
                          </td>
                          <td>
                            The following services:
                            <br />
                            ▲&nbsp;&nbsp;&nbsp;The Ezymigrate CRM facility,
                            accessed via the Website, and having the
                            functionality described at{" "}
                            <a href="www.ezymigrate.co.nz/how-it-works.html">
                              www.ezymigrate.co.nz/how-it-works.html
                            </a>{" "}
                            and
                            <br />
                            ▲&nbsp;&nbsp;&nbsp;The Ezymigrate client portal,
                            accessed via{" "}
                            <a href="http://www.checkmyvisa.io/">
                              www.checkmyvisa.io
                            </a>{" "}
                            (<strong>Client Portal</strong>).
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Ezymigrate
                            <br />
                            Software
                          </td>
                          <td>
                            The software owned by Ezymigrate (and its licensors)
                            that is used to provide the Ezymigrate Service.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>Fees</td>
                          <td>
                            The applicable fees set out in our pricing page on
                            the Website at
                            https://www.ezymigrate.co.nz/faqs.html or as
                            otherwise agreed in writing between you and us, as
                            may be updated from time to time in accordance with
                            clause 7.3.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Force Majeure
                          </td>
                          <td>
                            An event that is beyond the reasonable control of a
                            party, excluding:
                            <br />
                            ▲&nbsp;&nbsp;&nbsp;An event to the extent that it
                            could have been avoided by a party taking reasonable
                            steps or reasonable care; or
                            <br />
                            ▲&nbsp;&nbsp;&nbsp;A lack of funds for any reason.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Good Industry
                            <br />
                            Practice
                          </td>
                          <td>
                            In relation to an undertaking, the exercise of that
                            degree of skill and care which would reasonably be
                            expected from an experienced operator engaging in
                            the same or a similar undertaking.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Intellectual
                            <br /> Property Rights
                          </td>
                          <td>
                            Includes copyright and all rights existing anywhere
                            in the world conferred under statute, common law or
                            equity relating to inventions (including patents),
                            registered and unregistered trade marks and designs,
                            circuit layouts, data and databases, confidential
                            information, know how, and all other rights
                            resulting from intellectual activity.
                            <strong>Intellectual Property</strong> has a
                            consistent meaning, and includes any enhancement,
                            modification or derivative work of the Intellectual
                            Property.
                          </td>
                        </tr>
                        <tr>
                          <td>Initial Term</td>
                          <td>One Year</td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Objectionable
                          </td>
                          <td>
                            Includes being objectionable, defamatory, obscene,
                            harassing, threatening, harmful, or unlawful in any
                            way.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>Partner</td>
                          <td>
                            The government authorities and/or trusted businesses
                            (including Microsoft Azure for cloud storage and
                            other related services, and Xero for invoicing
                            services) that enable us to provide certain features
                            of the Ezymigrate Service. A full list of our
                            current partners is available on request by email to{" "}
                            <a href="mailto:info@ezymigrate.co.nz">
                              info@ezymigrate.co.nz
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Payment Terms
                          </td>
                          <td>
                            The payment terms set out in the Key Details (if
                            any)
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Permitted Users
                          </td>
                          <td>
                            Your personnel who are authorised to access and use
                            the Services on your behalf in accordance with
                            clause 5.3.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Personal
                            <br />
                            Information
                          </td>
                          <td>Has the meaning given in the Privacy Act 1993</td>
                        </tr>
                        <tr>
                          <td>Related Services</td>
                          <td>Has the meaning given in clause 3.5a.</td>
                        </tr>
                        <tr>
                          <td>Services</td>
                          <td>
                            The Ezymigrate Service and the Support Services.
                          </td>
                        </tr>
                        <tr>
                          <td>Start Date</td>
                          <td>
                            The date that you accept these Terms under clause
                            1.1.
                          </td>
                        </tr>
                        <tr>
                          <td>Support Services</td>
                          <td>Has the meaning given in clause 4.</td>
                        </tr>
                        <tr>
                          <td>Terms</td>
                          <td>
                            These terms titled Ezymigrate Terms of Service.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            Underlying <br />
                            Systems
                          </td>
                          <td>
                            The Ezymigrate Software, IT solutions, systems and
                            networks (including other software and hardware)
                            used to provide the Services, including any third
                            party solutions, systems and networks.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>User ID</td>
                          <td>
                            A unique name and/or password allocated to a
                            Permitted User to allow that Permitted User to
                            access the Ezymigrate Service or certain parts of
                            it.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>Website</td>
                          <td>
                            The internet site at ezymigrate.co.nz, or such other
                            site notified to you by us.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            We, us or our
                          </td>
                          <td>
                            Small Business Online Solutions Limited (trading as
                            Ezymigrate), a New Zealand company, company number
                            3400053.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>Year</td>
                          <td>
                            A 12-month period starting on the Start Date or the
                            anniversary of that date.
                          </td>
                        </tr>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>You or your</td>
                          <td>
                            You or, if clause 1.1b applies, both you and the
                            other person on whose behalf you are acting.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <span>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>Interpretation:</span>
                      <span>&nbsp;&nbsp;In these Terms:</span>
                    </div>
                    <ol type="a" style={{ marginLeft: 50 }}>
                      <li>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Clause and other headings are for ease
                        of reference only and do not affect the interpretation
                        of these Terms;
                      </li>

                      <li>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Words in the singular include the
                        plural and vice versa;
                      </li>

                      <li>
                        &nbsp;&nbsp;&nbsp;A reference to:
                        <ol type="i" style={{ marginLeft: 20 }}>
                          <li>
                            {" "}
                            &nbsp;&nbsp;&nbsp;A <strong>party</strong> includes
                            that party’s permitted assigns;
                          </li>

                          <li>
                            {" "}
                            &nbsp;&nbsp;&nbsp;<strong>Personnel</strong>{" "}
                            includes officers, employees, contractors and
                            agents, but a reference to your personnel does not
                            include us;
                          </li>

                          <li>
                            {" "}
                            &nbsp;&nbsp;&nbsp;A<strong> person</strong> includes
                            an individual, a body corporate, an association of
                            persons (whether corporate or not), a trust, a
                            government department, or any other entity;
                          </li>

                          <li>
                            {" "}
                            &nbsp;&nbsp;&nbsp;<strong>Including </strong> and
                            similar words do not imply any limit; and
                          </li>

                          <li>
                            {" "}
                            &nbsp;&nbsp;&nbsp;A statute includes references to
                            regulations, orders or notices made under or in
                            connection with the statute or regulations and all
                            amendments, replacements or other changes to any of
                            them.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <h4>
                      3&nbsp;&nbsp;&nbsp;
                      <strong> SERVICES</strong>
                    </h4>
                    <div>
                      <span>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>General:</span>
                      <span>
                        &nbsp;&nbsp;We will use best efforts to provide the
                        Services:
                      </span>
                    </div>
                    <ol type="a" style={{ marginLeft: 50 }}>
                      <li>
                        &nbsp;&nbsp;&nbsp;In accordance with these Terms and New
                        Zealand law;
                      </li>

                      <li>
                        &nbsp;&nbsp;&nbsp;Exercising reasonable care, skill and
                        diligence; and
                      </li>

                      <li>
                        &nbsp;&nbsp;&nbsp;Using suitably skilled and experienced
                        personnel.
                      </li>
                    </ol>
                    <div>
                      <span>3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>Non-exclusive:</span>
                      <span>
                        &nbsp;&nbsp;Our provision of the Services to you is
                        non-exclusive. Nothing in the Agreement prevents us from
                        providing the Services to any other person.
                      </span>
                    </div>
                    <div>
                      <span>3.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>Availability:</span>
                    </div>
                    <ol type="a" style={{ marginLeft: 50 }}>
                      <li>
                        &nbsp;&nbsp;&nbsp;Subject to clause 3.3b, we will use
                        reasonable efforts to ensure the Ezymigrate Service is
                        available [<em>on a 24/7 basis</em>] in New Zealand.
                        However, it is possible that on occasion the Ezymigrate
                        Service may be unavailable to permit maintenance or
                        other development activity to take place, or in the
                        event of Force Majeure. We will use reasonable efforts
                        to publish on the Website advance details of any
                        unavailability.
                      </li>

                      <li>
                        &nbsp;&nbsp;&nbsp;Through the use of web services and
                        APIs, the Ezymigrate Service interoperates with a range
                        of third party service features, including those
                        provided by the Partners. We do not make any warranty or
                        representation:
                        <ol type="i" style={{ marginLeft: 20 }}>
                          <li>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the availability of
                            those features. Without limiting the previous
                            sentence, if a third party feature provider ceases
                            to provide that feature or ceases to make that
                            feature available on reasonable terms, we may cease
                            to make available that feature to you. To avoid
                            doubt, if we exercise our right to cease the
                            availability of a third party feature, you are not
                            entitled to any refund, discount or other
                            compensation; or
                          </li>

                          <li>
                            &nbsp;&nbsp;&nbsp;On the performance of those third
                            parties, their services or their systems, and we
                            will have no liability to you for any delay or
                            failure to provide the Services as a result of any
                            delay or failure by any such third party (including
                            a Partner) or its services or systems.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <div>
                      <span>3.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        Underlying Systems:
                      </span>
                      <span>
                        &nbsp;&nbsp;We are responsible for procuring all
                        Underlying Systems reasonably required for us to provide
                        the Ezymigrate Service in accordance with these Terms
                      </span>
                    </div>
                    <div>
                      <span>3.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        Related Services:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          &nbsp;&nbsp;&nbsp;We may, from time to time, make
                          available additional services and/or features to
                          supplement the Ezymigrate Service (
                          <strong>Related Services</strong>).
                        </li>

                        <li>
                          &nbsp;&nbsp;&nbsp;Subject to you paying the applicable
                          Fees, and agreeing to any different or further terms,
                          we may agree to provide to you a Related Service.
                        </li>
                      </ol>
                    </div>
                    <h4>
                      4&nbsp;&nbsp;&nbsp;
                      <strong> SUPPORT SERVICES</strong>
                    </h4>
                    <div>
                      <span>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Support Services:
                      </span>
                      <span>
                        {" "}
                        For so long as you continue to comply with these Terms
                        (including paying the Fees) we will provide the
                        following support services in accordance with the
                        further provisions of this clause 4 (
                        <strong>Support Services</strong>):
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          telephone and email support in the form of
                          consultation, assistance and advice; and
                        </li>
                        <li>
                          reasonable efforts to assist in the resolution of
                          issues (taking into account the nature and severity of
                          the issue):
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              24/7 for priority 1 issues (determined in our
                              reasonable discretion); and/or
                            </li>
                            <li>
                              during our business hours (Monday to Friday from
                              8.30am to 5.00pm, excluding public holidays in
                              Auckland) (<strong>Business Hours</strong>) for
                              non-priority 1 issues.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> Conditions:</span>
                      <span>
                        {" "}
                        The provision of support is conditional on you
                        contacting us:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>by phone during our Business Hours; or</li>
                        <li>
                          by email to{" "}
                          <a href="mailto:support@ezymigrate.co.nz">
                            support@ezymigrate.co.nz
                          </a>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> Exclusions:</span>
                      <span>
                        {" "}
                        Nothing in these Terms requires us to provide Support
                        Services where the support is required as a result of:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          modification or alteration of the Ezymigrate Service
                          by any person other than us; or
                        </li>
                        <li>
                          a breach of these Terms by you or your personnel,
                          including use of the Ezymigrate Service by you or your
                          personnel in a manner or for a purpose not reasonably
                          contemplated by these Terms and not authorised in
                          writing by us.{" "}
                        </li>
                      </ol>
                    </div>
                    <h4>
                      5&nbsp;&nbsp;&nbsp;
                      <strong> CLIENT OBLIGATIONS</strong>
                    </h4>
                    <div>
                      <span>5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> General use:</span>
                      <span> You and your personnel must:</span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Use the services in accordance with these Terms and
                          Good Industry Practice and solely for:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Your ordinary business purposes of providing
                              immigration services to your own clients; and
                            </li>

                            <li>
                              Lawful purposes (including complying with the
                              Unsolicited Electronic Messages Act 2007);
                            </li>
                          </ol>
                        </li>
                        <li>
                          {" "}
                          Comply with all applicable immigration, employment,
                          and privacy, laws and codes of practice;
                        </li>
                        <li>
                          Not resell or make available the Services to any third
                          party, or otherwise commercially exploit the Services
                          except as expressly permitted under these Terms; and
                        </li>

                        <li>
                          provide true, current and complete information in your
                          dealings with us (including when setting up an
                          account), and must promptly update that information as
                          required so that the information remains true, current
                          and complete.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Access conditions:
                      </span>
                      <span>
                        {" "}
                        When accessing the Ezymigrate Service, you and your
                        personnel must:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Not impersonate another person or misrepresent
                          authorisation to act on behalf of others or us;
                        </li>

                        <li>
                          Correctly identify the sender of all electronic
                          transmissions;
                        </li>

                        <li>
                          Not attempt to undermine the security or integrity of
                          the Underlying Systems;
                        </li>

                        <li>
                          Not use, or misuse, the Ezymigrate Service in any way
                          which may impair the functionality of the Underlying
                          Systems or impair the ability of any other user to use
                          the Ezymigrate Service;
                        </li>

                        <li>
                          Not attempt to view, access or copy any material or
                          data other than that to which you are authorised to
                          access;
                        </li>

                        <li>
                          Neither use the Ezymigrate Service in a manner, nor
                          transmit, input or store any Data, that breaches any
                          third party right (including Intellectual Property
                          Rights and privacy rights) or is Objectionable,
                          incorrect or misleading; and
                        </li>

                        <li>
                          Comply with any terms of use on the Website, as
                          updated from time to time by us<em>.</em>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> Personnel:</span>

                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Without limiting clause 5.2, no individual other than
                          a Permitted User may access or use the Ezymigrate
                          Service.
                        </li>

                        <li>
                          You may authorise any member of your personnel to be a
                          Permitted User, provided that:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              The number of Permitted Users does not exceed any
                              maximum number of users notified to you or
                              selected by you when setting up your account. To
                              avoid doubt, this restriction does not limit the
                              number of end clients you may permit to use the
                              Client Portal; and
                            </li>

                            <li>
                              You provide us with the Permitted User’s name and
                              other information that we reasonably require in
                              relation to the Permitted User.
                            </li>
                          </ol>
                        </li>
                        <li>
                          You and each Permitted User must keep any User ID
                          allocated to you secure, and:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              not permit any other person to use that User ID,
                              including not disclosing or providing it to any
                              other person; and
                            </li>

                            <li>
                              immediately notify us on becoming aware of any
                              unauthorised use or disclosure of that User ID, by
                              sending an email to{" "}
                              <a href="mailto:support@ezymigrate.co.nz">
                                support@ezymigrate.co.nz
                              </a>
                            </li>
                          </ol>
                        </li>
                        <li>
                          You must:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Procure each Permitted User’s compliance with
                              these Terms; and
                            </li>

                            <li>
                              Require your end clients to agree to any terms of
                              use applicable to the use of the Client Portal.
                            </li>
                          </ol>
                        </li>
                        <li>
                          An act or omission by any of the following is deemed
                          to be an act or omission by you:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              your personnel (including, to avoid doubt, a
                              Permitted User); and/or
                            </li>

                            <li>
                              Any person using a User ID allocated to you or a
                              Permitted User.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Authorisations:
                      </span>
                      <span>
                        {" "}
                        You are responsible for procuring all licences,
                        authorisations and consents required for you and your
                        personnel to use the Services, including to use, store
                        and input Data into, and process and distribute Data
                        through, the Services.
                      </span>
                    </div>
                    <h4>
                      5&nbsp;&nbsp;&nbsp;
                      <strong> DATA</strong>
                    </h4>
                    <div>
                      <span>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Ezymigrate access to Data:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          You acknowledge that:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              We may require access to the Data to exercise our
                              rights and perform our obligations under these
                              Terms (including to monitor, maintain and/or
                              improve the Services); and
                            </li>

                            <li>
                              To the extent that this is necessary but subject
                              to clause 9, we may authorise a member or members
                              of its personnel or our Partners to access the
                              Data for this purpose.
                            </li>
                          </ol>
                        </li>
                        <li>
                          You must arrange all consents and approvals that are
                          necessary for us, our personnel and Partners to access
                          the Data as described in clause 6.1a.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> Agent:</span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          You acknowledge and agree that to the extent data
                          contains personal information, in collecting, holding
                          and processing that information through the Services,
                          we are acting as your agent for the purposes of the
                          Privacy Act 1993 and any other applicable privacy law.
                        </li>

                        <li>
                          You must obtain all necessary consents from the
                          relevant individual to enable us and our personnel to
                          collect, use, hold and process that information in
                          accordance with these Terms.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Backups of Data:
                      </span>
                      <span>
                        {" "}
                        While we will take standard industry measures to back up
                        all data stored using the Ezymigrate Service, you must
                        keep, in accordance with Good Industry Practice,
                        separate back-up copies of all Data uploaded by you onto
                        the Ezymigrate Service.
                      </span>
                    </div>
                    <div>
                      <span>6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Storage of Data:
                      </span>
                      <span>
                        {" "}
                        The Ezymigrate Service does not include a maximum data
                        storage capacity under the Fees; provided that you must
                        request from us additional data storage capacity once
                        your Data storage reaches limit. On receipt of your
                        request, we will provide additional data storage
                        capacity at no extra cost.
                      </span>
                    </div>
                    <div>
                      <span>6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        International storage of Data:
                      </span>
                      <span>
                        {" "}
                        You agree that we may store, and may engage third party
                        service providers to store on our behalf, Data
                        (including any Personal Information) in secure servers
                        in Australia and we, and those third party service
                        providers, may access that Data (including any Personal
                        Information) in Australia and New Zealand from time to
                        time. Ezymigrate currently uses Microsoft Azure for data
                        storage and related services.
                      </span>
                    </div>
                    <div>
                      <span>6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <span style={{ fontWeight: "500" }}> Indemnities:</span>
                      <span>
                        {" "}
                        You indemnify us and our Partners against any liability,
                        claim, proceeding, cost, expense (including the actual
                        legal fees charged by our solicitors) and loss of any
                        kind arising from:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Any actual or alleged claim by a third party that any
                          Data infringes the rights of any third party
                          (including Intellectual Property Rights and privacy
                          rights) or that the Data is Objectionable, incorrect,
                          incomplete, misleading, or has not been provided to
                          any person in a timely manner;
                        </li>

                        <li>
                          Your use of the Services in any unlawful or negligent
                          manner; or
                        </li>

                        <li>A breach by you of clause 6.3.</li>
                      </ol>
                    </div>
                    <h4>
                      7&nbsp;&nbsp;&nbsp;
                      <strong> FEES</strong>
                    </h4>
                    <div>
                      <span>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Fees:</span>
                      <span> &nbsp;&nbsp;&nbsp;You must pay us the Fees.</span>
                    </div>
                    <div>
                      <span>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Invoicing and payment:
                      </span>
                      <ol type="a" style={{ marginLeft: 20 }}>
                        <li>
                          By giving at least [<em>2 months’</em>] notice,
                          Ezymigrate may increase the Fees (but not in the
                          Initial Term). Fees updated under this clause are
                          deemed to be the Fees.
                        </li>

                        <li>
                          If you do not wish to pay the increased Fees, you may
                          terminate your right to access and use the Services on
                          no less than [<em>1 month’s</em>] notice, provided the
                          notice is received by us before the effective date of
                          the Fee increase. If you do not terminate in
                          accordance with this clause, you are deemed to have
                          accepted the increased Fees.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Increases:</span>
                      <ol type="a" style={{ marginLeft: 20 }}>
                        <li>
                          By giving at least [<em>2 months’</em>] notice,
                          Ezymigrate may increase the Fees (but not in the
                          Initial Term). Fees updated under this clause are
                          deemed to be the Fees.
                        </li>

                        <li>
                          If you do not wish to pay the increased Fees, you may
                          terminate your right to access and use the Services on
                          no less than [<em>1 month’s</em>] notice, provided the
                          notice is received by us before the effective date of
                          the Fee increase. If you do not terminate in
                          accordance with this clause, you are deemed to have
                          accepted the increased Fees.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Refunds:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; We will not refund (on
                        termination or otherwise) any Fees paid in advance,
                        unless required to do so by law.
                      </span>
                    </div>
                    <h4>
                      8&nbsp;&nbsp;&nbsp;
                      <strong> INTELLECTUAL PROPERTY</strong>
                    </h4>
                    <div>
                      <span>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Ownership:</span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Subject to clause 8.1b, title to, and all Intellectual
                          Property Rights in, the Services, the Website, and all
                          Underlying Systems is and remains the property of us
                          (and our licensors). You must not dispute that
                          ownership.
                        </li>

                        <li>
                          Title to, and all Intellectual Property Rights in, the
                          Data (as between the parties) remains your property.
                          You grant us and our personnel a worldwide,
                          non-exclusive, fully paid up, transferable,
                          irrevocable licence to use, store, copy, modify, make
                          available and communicate the Data for any purpose in
                          connection with the exercise of our rights and
                          performance of our obligations in accordance with
                          these Terms.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Know-how:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; To the extent not owned by us,
                        you grant us a royalty-free, transferable, irrevocable
                        and perpetual licence to use any know-how, techniques,
                        ideas, methodologies, and similar Intellectual Property
                        used by us in the provision of the Services.
                      </span>
                    </div>
                    <div>
                      <span>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Feedback:</span>
                      <span>
                        &nbsp;&nbsp;&nbsp;If you provide us with ideas, comments
                        or suggestions relating to the Services or Underlying
                        Systems (together<strong> feedback</strong>):
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          all Intellectual Property Rights in that feedback, and
                          anything created as a result of that feedback
                          (including new material, enhancements, modifications
                          or derivative works), are owned solely by us; and
                        </li>

                        <li>
                          we may use or disclose the feedback for any purpose.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>8.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Third party sites and material:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;You acknowledge that the Ezymigrate
                        Service may link to third party websites or feeds that
                        are connected or relevant to the Ezymigrate Service. Any
                        link from the Ezymigrate Service does not imply any
                        endorsement, approval or recommendation of, or
                        responsibility for, those websites or feeds or their
                        content or operators by us. To the maximum extent
                        permitted by law, we exclude all responsibility or
                        liability for those websites or feeds.
                      </span>
                    </div>
                    <h4>
                      9&nbsp;&nbsp;&nbsp;
                      <strong> CONFIDENTIALITY</strong>
                    </h4>
                    <div>
                      <span>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Security:</span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Each party must, unless it has the
                        prior written consent of the other party:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Keep confidential at all times the Confidential
                          Information of the other party;
                        </li>

                        <li>
                          Take reasonable steps to effect and maintain adequate
                          security measures to safeguard the other party’s
                          Confidential Information from unauthorised access or
                          use; and
                        </li>

                        <li>
                          Disclose the other party’s Confidential Information to
                          its personnel or professional advisors on a{" "}
                          <em>need to know </em>basis only and, in that case,
                          ensure that any personnel or professional advisor to
                          whom it discloses the other party’s Confidential
                          Information is aware of, and complies with, the
                          provisions of clauses 9.1a and 9.1b.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Permitted disclosure:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;The obligation of confidentiality in
                        clause 9.1a does not apply to any disclosure or use of
                        Confidential Information:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          For the purpose of performing a party’s obligations or
                          exercising a party’s rights under these Terms;
                        </li>

                        <li>
                          (Subject to clause 9.3) required by law (including
                          under the rules of any stock exchange);
                        </li>

                        <li>
                          {" "}
                          Which is publicly available through no fault of the
                          recipient of the Confidential Information or its
                          personnel;
                        </li>

                        <li>
                          Which was rightfully received by a party from a third
                          party without restriction and without breach of any
                          obligation of confidentiality; or
                        </li>

                        <li>
                          By us if required as part of a <em>bona fide</em> sale
                          of our business (assets or shares, whether in whole or
                          in part) to a third party, provided that we enter into
                          a confidentiality agreement with the third party on
                          terms no less restrictive than this clause 9.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>9.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Notification:</span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Where a party is required by law to
                        disclose or use any Confidential Information of the
                        other party, it must:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          promptly, and prior to disclosure, give written notice
                          of the requirement to the other party to enable the
                          other party to seek a protective order or other
                          appropriate remedy; and
                        </li>

                        <li>
                          where the other party’s Confidential Information is
                          disclosed as a result of the requirement, use all
                          reasonable endeavours to obtain, from the recipient,
                          assurances that the Confidential Information will be
                          treated as confidential.
                        </li>
                      </ol>
                    </div>
                    <h4>
                      10&nbsp;&nbsp;&nbsp;
                      <strong> WARRANTIES</strong>
                    </h4>
                    <div>
                      <span>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Mutual warranties:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Each party warrants that it has full
                        power and authority to enter into and perform its
                        obligations under these Terms.
                      </span>
                    </div>
                    <div>
                      <span>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        No implied warranties:
                      </span>
                      <span>To the maximum extent permitted by law:</span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Our warranties are limited to those set out in these
                          Terms, and all other conditions, guarantees or
                          warranties whether expressed or implied by statute or
                          otherwise (including any warranty under the Sale of
                          Goods Act 1908) are expressly excluded and, to the
                          extent that they cannot be excluded, our liability for
                          them is limited to $500.00; and
                        </li>

                        <li>
                          We make no representation concerning the quality of
                          the Services, and without limiting the foregoing, do
                          not promise that the Ezymigrate Service will:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Meet your (or any other person’s) requirements or
                              be suitable for a particular purpose, including
                              that the use of the Ezymigrate Service will:
                              <br />
                              ▲ Fulfil or meet any statutory role or
                              responsibility you have; or
                              <br />▲ Ensure that any person meets or complies
                              with any deadline, criteria for assessment, or
                              legal requirement; or
                            </li>

                            <li>
                              Be secure, free of viruses or other harmful code,
                              uninterrupted or error free.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>10.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Consumer Guarantees Act:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;You agree and represent that you are
                        acquiring the Services, and accepting these Terms, for
                        the purpose of a business and that the Consumer
                        Guarantees Act 1993 does not apply to the supply of the
                        Services or these Terms.
                      </span>
                    </div>
                    <div>
                      <span>10.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Limitation of remedies:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Where legislation or rule of law
                        implies into these Terms a condition or warranty that
                        cannot be excluded or modified by contract, the
                        condition or warranty is deemed to be included in these
                        Terms. However, our liability for any breach of that
                        condition or warranty is limited, at our option, to:
                      </span>

                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>Supplying the Services again; and/or</li>

                        <li>
                          Paying the costs of having the Services supplied
                          again.
                        </li>
                      </ol>
                    </div>
                    <h4>
                      11&nbsp;&nbsp;&nbsp;<strong>LIABILITY</strong>{" "}
                    </h4>
                    <div>
                      <span>11.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Maximum liability:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Our maximum aggregate liability under
                        or in connection with these Terms or relating to the
                        Services, whether in contract, tort (including
                        negligence), breach of statutory duty or otherwise, must
                        not in any Year exceed an amount equal to the Fees paid
                        by you under these Terms in the previous Year (which in
                        the first Year is deemed to be the total Fees paid by
                        you from the Start Date to the date of the first event
                        giving rise to liability). The cap in this clause 11.1
                        includes the cap set out in clause 10.2a.
                      </span>
                    </div>
                    <div>
                      <span>11.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Unrecoverable loss:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Neither party is liable to the other under or in
                          connection with the Agreement or the Services for any:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Loss of profit, revenue, savings, business, use,
                              and/or goodwill; or
                            </li>

                            <li>
                              Consequential, indirect, incidental or special
                              damage or loss of any kind.
                            </li>
                          </ol>
                        </li>

                        <li>
                          We will not be liable to you or any other person under
                          or in connection with these Terms or the Services for
                          any loss of data (including Data).
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>11.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Ezymigrate unlimited liability:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Clauses 11.1 and 11.2 do not apply to
                        limit our liability under or in connection with these
                        Terms for:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>Personal injury or death;</li>

                        <li>Fraud or wilful misconduct; or</li>

                        <li>A breach of clause 9.</li>
                      </ol>
                    </div>
                    <div>
                      <span>11.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Client unlimited liability:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Clause 11.2a does not apply to limit
                        your liability:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>To pay the Fees;</li>

                        <li>For breach of clause 5;</li>

                        <li>Under the indemnities in clause 6.6; or</li>

                        <li>
                          Under or in connection with these Terms for those
                          matters stated in clauses 11.3a to 11.3c.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>11.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        No liability for other’s failure:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Neither party will be responsible,
                        liable, or held to be in breach of these Terms for any
                        failure to perform its obligations under these Terms or
                        otherwise, to the extent that the failure is caused by
                        the other party failing to comply with its obligations
                        under these Terms, or by the negligence or misconduct of
                        the other party or its personnel.
                      </span>
                    </div>
                    <div>
                      <span>11.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Mitigation:</span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Each party must take reasonable steps
                        to mitigate any loss or damage, cost or expense it may
                        suffer or incur arising out of anything done or not done
                        by the other party under or in connection with these
                        Terms.
                      </span>
                    </div>
                    <h4>
                      12&nbsp;&nbsp;&nbsp;
                      <strong>TERM, TERMINATION AND SUSPENSION</strong>{" "}
                    </h4>
                    <div>
                      <span>12.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Duration:</span>
                      <span>
                        &nbsp;&nbsp;&nbsp;Unless terminated under clause 7.3b or
                        this clause 12, your right to access and use the
                        Services:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Starts on the Start Date and continues for the Initial
                          Term; and
                        </li>

                        <li>
                          On expiry of the Initial Term, continues for further
                          terms of 2 years each unless:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              You give 1 month’s notice that your right to
                              access and use the Services will terminate on the
                              expiry of the Initial Term or the notice (as
                              applicable); or
                            </li>

                            <li>
                              We give 1 months’ notice that your right to access
                              and use the Services will terminate on the expiry
                              of the Initial Term or the notice (as applicable).{" "}
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>12.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Termination during Initial Term:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;You may terminate your right to access
                        and use the Services during the Initial Term by giving
                        us 1 month’s notice, provided that:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          We will not refund or credit any Fees paid by you in
                          advance; and
                        </li>

                        <li>
                          You must pay us 50% of the fees that would otherwise
                          have applied during the remainder of the Initial Term.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>12.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Other termination rights:
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;You may terminate your right to access
                        and use the Services during the Initial Term by giving
                        us 1 month’s notice, provided that:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Breaches any material provision of these Terms and the
                          breach is not:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Remedied within 10 days of the receipt of a notice
                              from the first party requiring it to remedy the
                              breach; or
                            </li>

                            <li>Capable of being remedied;</li>
                          </ol>
                        </li>

                        <li>
                          Becomes insolvent, liquidated or bankrupt, has an
                          administrator, receiver, liquidator, statutory
                          manager, mortgagee’s or charge’s agent appointed,
                          becomes subject to any form of insolvency action or
                          external administration, or ceases to continue
                          business for any reason; or
                        </li>

                        <li>
                          is unable to perform a material obligation under these
                          Terms for 30 days or more due to Force Majeure.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>12.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Consequences of termination or expiry:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Termination or expiry of your right to access and use
                          the Services does not affect either party’s rights and
                          obligations that accrued before that termination or
                          expiry.
                        </li>

                        <li>
                          On termination under clause 12.2 of your right to
                          access and use the Services, you must pay the amount
                          under clause 12.2b.
                        </li>

                        <li>
                          Except to the extent that a party has ongoing rights
                          to use Confidential Information, at the other party’s
                          request following termination or expiry of your right
                          to access and use the Services, a party must promptly
                          return to the other party or destroy all Confidential
                          Information of the other party that is in the first
                          party’s possession or control.
                        </li>

                        <li>
                          At any time prior to one month after the date of
                          termination or expiry, you may:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>
                              Download a copy of any readily available Data
                              stored using the Ezymigrate Service.
                            </li>
                            <li>
                              Request in writing a copy of any readily available
                              Data stored using the Ezymigrate Service, provided
                              that you pay our reasonable costs of providing
                              that copy; and/or
                            </li>
                            <li>
                              Request deletion of the Data stored using the
                              Ezymigrate Service, in which case we must use
                              reasonable efforts to promptly delete that Data.
                              Deletion of Data within 20 working days is deemed
                              to fulfil our obligation under this subparagraph.
                            </li>
                            To avoid doubt, we are not required to comply with
                            clause 12.4di or clause 12.4dii to the extent that
                            you previously requested deletion of the Data or
                            that any request under clause 12.4dii is not in
                            writing.
                          </ol>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>12.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Obligations continuing:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Clauses which, by their nature, are
                        intended to survive termination or expiry of the
                        Agreement, including clauses 6.6, 8, 9, 11, 12.4, 12.5
                        and 13, continue in force.
                      </span>
                    </div>
                    <div>
                      <span>12.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Suspending access:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Without limiting any other right or
                        remedy available to us, we may restrict or suspend your
                        access to the Ezymigrate Service where you (including
                        any of your personnel):
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Undermine, or attempt to undermine, the security or
                          integrity of the Ezymigrate Service or any Underlying
                          Systems;
                        </li>

                        <li>
                          Use, or attempt to use, the Ezymigrate Service:
                          <ol type="i" style={{ marginLeft: 20 }}>
                            <li>For improper purposes; or</li>

                            <li>
                              In a manner, other than for normal operational
                              purposes, that materially reduces the operational
                              performance of the Ezymigrate Service; or
                            </li>
                          </ol>
                        </li>

                        <li>
                          Have otherwise materially breached these Terms (in our
                          reasonable opinion).
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>12.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Notice:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;We must notify you where we restrict
                        or suspend your access under clause 12.6.
                      </span>
                    </div>
                    <h4>
                      13&nbsp;&nbsp;&nbsp;<strong>DISPUTES</strong>{" "}
                    </h4>
                    <div>
                      <span>13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Good faith negotiations:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Before taking any court action, a
                        party must use best efforts to resolve any dispute
                        under, or in connection with, these Terms or the
                        Services through good faith negotiations.
                      </span>
                    </div>
                    <div>
                      <span>13.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Obligations continue:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Each party must, to the extent
                        possible, continue to perform its obligations under
                        these Terms even if there is a dispute.
                      </span>
                    </div>
                    <div>
                      <span>13.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Right to seek relief:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;This clause 13 does not affect either
                        party’s right to seek urgent interlocutory and/or
                        injunctive relief.
                      </span>
                    </div>
                    <h4>
                      14&nbsp;&nbsp;&nbsp;<strong>GENERAL</strong>{" "}
                    </h4>
                    <div>
                      <span>14.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Force Majeure:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Neither party is liable to the other
                        for any failure to perform its obligations under the
                        Agreement to the extent caused by Force Majeure,
                        provided that the affected party:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          Immediately notifies the other party and provides full
                          information about the Force Majeure;
                        </li>

                        <li>
                          Uses best efforts to overcome the Force Majeure; and
                        </li>

                        <li>
                          Continues to perform its obligations to the extent
                          practicable.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>14.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Rights of third parties:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;No person other than you and us has
                        any right to a benefit under, or to enforce, these
                        Terms.
                      </span>
                    </div>
                    <div>
                      <span>14.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Waiver:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;To waive a right under these Terms,
                        that waiver must be in writing and signed by the waiving
                        party.
                      </span>
                    </div>
                    <div>
                      <span>14.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Independent contractor:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Subject to clause 6.2a, we are an
                        independent contractor of yours, and no other
                        relationship (e.g. joint venture, agency, trust or
                        partnership) exists under these Terms.
                      </span>
                    </div>
                    <div>
                      <span>14.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Notices:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;If we need to contact you, we may do
                        so by email or by posting a notice on the Website. You
                        agree that this satisfies all legal requirements for
                        written communications. A notice given by you to us
                        under these Terms must be sent by email to{" "}
                        <a href="mailto:info@ezymigrate.co.nz">
                          info@ezymigrate.co.nz
                        </a>{" "}
                        or otherwise notified by us to you for this purpose.
                      </span>
                    </div>
                    <div>
                      <span>14.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Severability:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Any illegality, unenforceability or
                        invalidity of a provision of these Terms does not affect
                        the legality, enforceability or validity of the
                        remaining provisions.
                      </span>
                    </div>
                    <div>
                      <span>14.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Variation:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;Except as otherwise permitted under
                        these Terms, any variation to these Terms must be in
                        writing and signed by both parties.
                      </span>
                    </div>
                    <div>
                      <span>14.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Entire agreement:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;These Terms set out everything agreed
                        by the parties relating to the Services, and supersede
                        and cancel anything discussed, exchanged or agreed prior
                        to the Start Date. The parties have not relied on any
                        representation, warranty or agreement relating to the
                        subject matter of these Terms that is not expressly set
                        out in these Terms, and no such representation, warranty
                        or agreement has any effect from the Start Date. Without
                        limiting the previous sentence, the parties agree that
                        sections 9, 12A and 13 of the Fair Trading Act 1986 do
                        not apply to these Terms or the supply of the Services.
                      </span>
                    </div>
                    <div>
                      <span>14.9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}>
                        {" "}
                        Subcontracting and assignment:
                      </span>
                      <ol type="a" style={{ marginLeft: 50 }}>
                        <li>
                          You may not assign, novate, subcontract or transfer
                          any right or obligation under these Terms without our
                          prior written consent, that consent not to be
                          unreasonably withheld. You remain liable for your
                          obligations under these Terms despite any approved
                          assignment, subcontracting or transfer. Any
                          assignment, novation, subcontracting or transfer must
                          be in writing.
                        </li>

                        <li>
                          A change of control affecting you is deemed to be an
                          assignment for which our prior written consent is
                          required under clause 14.9a. In this clause
                          <strong>change of control</strong> means any transfer
                          of shares or other arrangement affecting you or any
                          member of its group, which results in a change in your
                          effective control.
                        </li>
                      </ol>
                    </div>
                    <div>
                      <span>14.10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontWeight: "500" }}> Law:</span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp;These Terms are governed by, and must
                        be interpreted in accordance with, the laws of New
                        Zealand. Each party submits to the non-exclusive
                        jurisdiction of the courts of New Zealand in relation to
                        any dispute connected with the Agreement.
                      </span>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <span style={{ fontWeight: "500" }}>
                        *Ezyform Disclaimer:
                      </span>
                      <span>
                        {" "}
                        &nbsp;&nbsp;&nbsp; You must personally review the
                        auto-filled data to make sure it has been uploaded
                        correctly. The user submits the web form to the
                        concerned authority with their own responsibility. We
                        cannot take any responsibility for incorrect data that
                        might be submitted to any authority.
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ marginLeft: 20 }}>
                <Checkbox checked={agreeChecked} onChange={onChange}>
                  I Agree
                </Checkbox>
              </div>
            </Spin>
          </Modal>
        </div>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default SignUpForm;
