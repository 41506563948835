import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const imapSettingReducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_IMAP_SETTING_SUCCESS:
      return { ...state, updateImapSettingRes: action.payload };
    case types.UPDATE_IMAP_SETTING_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_IMAP_SETTING_SUCCESS:
      return { ...state, imapSettingRes: action.payload };
    case types.GET_IMAP_SETTING_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_IMAP_FOR_ALL_SUCCESS:
      return { ...state, imapForAllRes: action.payload };
    case types.GET_IMAP_FOR_ALL_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
