import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CheckListCategory from "./../CaseManagement/CheckLists";

import {
  getVisaCategoryMain,
  getVisaCategoryChecklist,
  removeChecklistCategory,
  updateChecklistCategory,
  updateVisaChecklist,
  removeChecklist,
  getChecklistTasks,
  removeChecklistTask,
  addVisaChecklist
} from "../store/Actions";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onGetVisaCategoryMain: bindActionCreators(getVisaCategoryMain, dispatch),
    onGetVisaCategoryChecklist: bindActionCreators(
      getVisaCategoryChecklist,
      dispatch
    ),
    onRemoveChecklistCategory: bindActionCreators(
      removeChecklistCategory,
      dispatch
    ),
    onUpdateChecklistCategory: bindActionCreators(
      updateChecklistCategory,
      dispatch
    ),
    onUpdateVisaChecklist: bindActionCreators(updateVisaChecklist, dispatch),
    onRemoveChecklist: bindActionCreators(removeChecklist, dispatch),
    onGetChecklistTasks: bindActionCreators(getChecklistTasks, dispatch),
    onRemoveChecklistTask: bindActionCreators(removeChecklistTask, dispatch),
    onAddVisaChecklist: bindActionCreators(addVisaChecklist, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckListCategory);
