import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
} from "antd";
import { bindActionCreators } from "redux";
import { getSearchStudentListing } from "../store/Actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { openNotificationWithIcon } from "../Common/reUseFunction";
const dateFormat = "DD/MM/YYYY";

class AddStudentsModal extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      searchedStudents: [],
      cDateFrom: "1900-01-01T00:00:00.000Z",
      cDateTo: "1900-01-01T00:00:00.000Z",
      aDateFrom: "1900-01-01T00:00:00.000Z",
      aDateTo: "1900-01-01T00:00:00.000Z",
      loadingStudentsList: false,
      selectedSchoolId: 0,
      selectedStudents: [],
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.setState({
      selectedSchoolId: this.props.selectedSchoolId,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  rowSelection = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedStudents: selectedRows });
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  studentListingColumns = [
    {
      title: "Sn",
      dataIndex: "sn",
      key: "sn",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.index + 1}
        </p>
      ),
    },
    {
      title: "Student Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.firstName + record.lastName}
        </p>
      ),
    },
    {
      title: "Programme",
      dataIndex: "enrolledProgram",
      key: "enrolledProgram",
    },
    {
      title: "Last Invoiced",
      dataIndex: "lastInvoice",
      key: "lastInvoice",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record && record.lastInvoice === "1990-01-01T00:00:00+00:00"
            ? ""
            : moment(record.lastInvoice).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  onClickShow = () => {
    let cDateFrom;
    let cDateTo;
    let aDateFrom;
    let aDateTo;
    if (
      this.state.cDateFrom === undefined ||
      this.state.cDateFrom === "1900-01-01T00:00:00.000Z"
    ) {
      cDateFrom = "1900-01-01T00:00:00.000Z";
    } else {
      cDateFrom = moment(this.state.cDateFrom, "DD/MM/YYYY").format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    if (
      this.state.cDateTo === undefined ||
      this.state.cDateTo === "1900-01-01T00:00:00.000Z"
    ) {
      cDateTo = "1900-01-01T00:00:00.000Z";
    } else {
      cDateTo = moment(this.state.cDateTo, "DD/MM/YYYY").format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    if (
      this.state.aDateFrom === undefined ||
      this.state.aDateFrom === "1900-01-01T00:00:00.000Z"
    ) {
      aDateFrom = "1900-01-01T00:00:00.000Z";
    } else {
      aDateFrom = moment(this.state.aDateFrom, "DD/MM/YYYY").format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    if (
      this.state.aDateTo === undefined ||
      this.state.aDateTo === "1900-01-01T00:00:00.000Z"
    ) {
      aDateTo = "1900-01-01T00:00:00.000Z";
    } else {
      aDateTo = moment(this.state.aDateTo, "DD/MM/YYYY").format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    let _data = {
      cDateFrom: cDateFrom,
      cDateTo: cDateTo,
      aDateFrom: aDateFrom,
      aDateTo: aDateTo,
      schoolId: this.state.selectedSchoolId,
    };

    if (
      _data.cDateFrom === "1900-01-01T00:00:00.000Z" &&
      _data.cDateTo === "1900-01-01T00:00:00.000Z" &&
      _data.aDateFrom === "1900-01-01T00:00:00.000Z" &&
      _data.aDateTo === "1900-01-01T00:00:00.000Z"
    ) {
      message.error("Please select at least one date!");
    } else if (
      _data.cDateFrom !== "1900-01-01T00:00:00.000Z" &&
      _data.cDateTo === "1900-01-01T00:00:00.000Z"
    ) {
      message.error("Please select course effective date to!");
    } else if (
      _data.cDateFrom === "1900-01-01T00:00:00.000Z" &&
      _data.cDateTo !== "1900-01-01T00:00:00.000Z"
    ) {
      message.error("Please select course effective date from!");
    } else if (
      _data.aDateFrom !== "1900-01-01T00:00:00.000Z" &&
      _data.aDateTo === "1900-01-01T00:00:00.000Z"
    ) {
      message.error("Please select applied date to!");
    } else if (
      _data.aDateFrom === "1900-01-01T00:00:00.000Z" &&
      _data.aDateTo !== "1900-01-01T00:00:00.000Z"
    ) {
      message.error("Please select applied date from!");
    } else {
      this.setState({ loadingStudentsList: true });
      this.props
        .getSearchStudentListing(_data)
        .then((res) => {
          if (res && res.payload && res.payload.length > 0) {
            res &&
              res.payload &&
              res.payload.map((data, index) => {
                if (data) {
                  data.index = index;
                  data.key = `${index + 1}`;
                }
              });
          }
          this.setState({
            searchedStudents: res.payload,
            loadingStudentsList: false,
          });
        })
        .catch(() => {
          this.setState({ loadingStudentsList: false });
          openNotificationWithIcon("error", "An error occurred! Try again!");
        });
    }
  };

  handleCourseEffectiveDateFromChange = (date, dateString) => {
    this.setState(
      {
        cDateFrom:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ aDateFrom: undefined, aDateTo: undefined });
      }
    );
  };

  handleCourseEffectiveDateToChange = (date, dateString) => {
    this.setState(
      {
        cDateTo:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ aDateFrom: undefined, aDateTo: undefined });
      }
    );
  };

  handleAppliedDateFromChange = (date, dateString) => {
    this.setState(
      {
        aDateFrom:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ cDateFrom: undefined, cDateTo: undefined });
      }
    );
  };

  handleAppliedDateToChange = (date, dateString) => {
    this.setState(
      {
        aDateTo:
          date === null
            ? "1900-01-01T00:00:00.000Z"
            : moment(date).format("DD/MM/YYYY"),
      },
      () => {
        this.setState({ cDateFrom: undefined, cDateTo: undefined });
      }
    );
  };

  onClickSelect = () => {
    if (this.state.selectedStudents.length === 0) {
      message.error("Select atleast one student!");
    } else {
      this.props && this.props.getSelectedStudents(this.state.selectedStudents);
    }
  };

  render() {
    let a = this.props;

    return (
      <div>
        <Modal
          className="reminder-model-main width-modal"
          title="ADD STUDENT"
          visible={this.props.showModalAddStudent}
          onCancel={this.props.handleCancelAddStudentModal}
          footer={null}
          maskClosable={false}
        >
          <Row style={{ marginBottom: "6px" }}>
            <Col xs={10} offset={2}>
              <p style={{ fontSize: "12px" }}>{"Course Effective Date From"}</p>
            </Col>
            <Col xs={10}>
              <DatePicker
                value={
                  this.state.cDateFrom === "1900-01-01T00:00:00.000Z" ||
                  this.state.cDateFrom === undefined
                    ? undefined
                    : moment(this.state.cDateFrom, dateFormat)
                }
                size="small"
                format={dateFormat}
                onChange={this.handleCourseEffectiveDateFromChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={10} offset={2}>
              <p style={{ fontSize: "12px" }}>{"Course Effective Date To"}</p>
            </Col>
            <Col xs={10}>
              <DatePicker
                value={
                  this.state.cDateTo === "1900-01-01T00:00:00.000Z" ||
                  this.state.cDateTo === undefined
                    ? undefined
                    : moment(this.state.cDateTo, dateFormat)
                }
                size="small"
                format={dateFormat}
                onChange={this.handleCourseEffectiveDateToChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "6px" }}>
            <Col xs={24}>
              <p style={{ fontSize: "14px", textAlign: "center" }}>{"OR"}</p>
            </Col>
          </Row>
          <Row style={{ marginBottom: "6px" }}>
            <Col xs={10} offset={2}>
              <p style={{ fontSize: "12px" }}>{"Applied Date From"}</p>
            </Col>
            <Col xs={10}>
              <DatePicker
                value={
                  this.state.aDateFrom === "1900-01-01T00:00:00.000Z" ||
                  this.state.aDateFrom === undefined
                    ? undefined
                    : moment(this.state.aDateFrom, dateFormat)
                }
                size="small"
                format={dateFormat}
                onChange={this.handleAppliedDateFromChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={10} offset={2}>
              <p style={{ fontSize: "12px" }}>{"Applied Date To"}</p>
            </Col>
            <Col xs={10}>
              <DatePicker
                value={
                  this.state.aDateTo === "1900-01-01T00:00:00.000Z" ||
                  this.state.aDateTo === undefined
                    ? undefined
                    : moment(this.state.aDateTo, dateFormat)
                }
                size="small"
                format={dateFormat}
                onChange={this.handleAppliedDateToChange}
              />
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: "12px" }}>
            <Col xs={24}>
              <Button type="primary" onClick={this.onClickShow}>
                {"Show"}
              </Button>
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: "12px" }}>
            <Col xs={22} offset={2}>
              <p style={{ fontSize: "12px", textAlign: "left" }}>
                {"Student Admissions"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Spin spinning={this.state.loadingStudentsList}>
                <Table
                  columns={this.studentListingColumns}
                  pagination={false}
                  rowSelection={{
                    ...this.rowSelection,
                  }}
                  dataSource={
                    this.state.searchedStudents
                      ? this.state.searchedStudents
                      : []
                  }
                ></Table>
              </Spin>
            </Col>
          </Row>
          <Row>
            <Col
              xs={5}
              offset={19}
              style={{ display: "flex", marginTop: "30px" }}
            >
              <Button
                type="primary"
                style={{ marginRight: "6px" }}
                onClick={this.onClickSelect}
              >
                Select
              </Button>
              <Button
                type="primary"
                onClick={this.props.handleCancelAddStudentModal}
              >
                Close
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  getSearchStudentListing: bindActionCreators(
    getSearchStudentListing,
    dispatch
  ),
});
AddStudentsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStudentsModal);

export default withRouter(AddStudentsModal);
