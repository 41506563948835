import React, { Fragment } from "react";
import "./SchoolManagementStyles.css";
import { Images } from "../Themes";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Upload,
  Space,
  message,
  InputNumber,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getSchoolListing,
  getSchoolTypeListing,
  getSchoolListingForType,
  deleteSchool,
  uploadAvatar,
  addSchool,
  getSchoolInfo,
} from "../store/Actions";
import { connect } from "react-redux";
import SchoolForm from "./SchoolForm";
import { Link } from "react-router-dom";
const { Option } = Select;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 11, offset: 4 },
};
let selectedBranchId = localStorage.getItem("selectedBranchId");
class Schools extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      visibleDeleteModal: false,
      visibleSchoolModal: false,
      schoolName: "",
      schoolType: 0,
      schoolCity: "",
      schoolAddress: "",
      schoolWebsite: "",
      schoolEmail: "",
      schoolNotes: "",
      schoolAggrementURL: "",
      agreementFileName: "",
      contacts: [],
      levels: [],
      schoolListForType: [],
      branchId: selectedBranchId,
      readOnly: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.props.getSchoolTypeListing().then(() => {
      let _schoolTypeList =
        this.props &&
        this.props.schoolTypeListing &&
        this.props.schoolTypeListing;
      this.setState({ activeTabId: _schoolTypeList && _schoolTypeList[0].id });
      this.props
        .getSchoolListingForType(_schoolTypeList && _schoolTypeList[0].id)
        .then(() => {
          let _schoolListForType =
            this.props &&
            this.props.schoolListingForType &&
            this.props.schoolListingForType;
          this.setState({
            schoolListForType: _schoolListForType,
            schoolTypeList: _schoolTypeList,
            activeTab: _schoolTypeList && _schoolTypeList[0].name,
            loading: false,
          });
        });
    });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let __schoolListingForType =
      nextProps &&
      nextProps.schoolListingForType &&
      nextProps.schoolListingForType;
    this.setState(
      {
        schoolListForType: __schoolListingForType,
      },
      () => {}
    );
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getSchoolTypeListing().then(() => {
        //   let _schoolTypeList =
        //     this.props &&
        //     this.props.schoolTypeListing &&
        //     this.props.schoolTypeListing;
        //   this.setState({
        //     activeTabId: _schoolTypeList && _schoolTypeList[0].id,
        //   });
        //   this.props
        //     .getSchoolListingForType(_schoolTypeList && _schoolTypeList[0].id)
        //     .then(() => {
        //       let _schoolListForType =
        //         this.props &&
        //         this.props.schoolListingForType &&
        //         this.props.schoolListingForType;
        //       this.setState({
        //         schoolListForType: _schoolListForType,
        //         schoolTypeList: _schoolTypeList,
        //         activeTab: _schoolTypeList && _schoolTypeList[0].name,
        //       });
        //     });
        // });
      });
    }
  }

  onClickEditIcon = (id) => {
    this.props.getSchoolInfo(id).then(() => {
      let _schoolInfo = this.props && this.props.schoolInfo;
      let _name = _schoolInfo && _schoolInfo.name;
      let _city = _schoolInfo && _schoolInfo.city;
      let _address = _schoolInfo && _schoolInfo.address;
      let _website = _schoolInfo && _schoolInfo.website;
      let _email = _schoolInfo && _schoolInfo.email;
      let _notes = _schoolInfo && _schoolInfo.notes;
      let _type = _schoolInfo && _schoolInfo.schoolTypeId;
      let _contacts = _schoolInfo && _schoolInfo.contacts;
      let _levels = _schoolInfo && _schoolInfo.levels;
      let _aggrementUrl = this.props && this.props.aggrementUrl;
      let _agreementFileName = _schoolInfo.agreementFileName;
      let _readOnly = _schoolInfo.readOnly;
      let schoolId = _schoolInfo.id;
      this.setState({
        readOnly: _readOnly,
        visibleSchoolModal: true,
        schoolName: _name,
        schoolType: _type,
        schoolCity: _city,
        schoolAddress: _address,
        schoolWebsite: _website,
        schoolEmail: _email,
        schoolNotes: _notes,
        contacts: _contacts,
        levels: _levels,
        aggrementUrl: _aggrementUrl,
        agreementFileName: _agreementFileName,
        schoolId: schoolId,
      });
    });
  };

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text, record) => (
        <a
          style={{ fontSize: "12px !important" }}
          onClick={() => this.onClickEditIcon(record.id)}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.name}
          </p>
        </a>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.city}
          </p>
        );
      },
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.website}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      ellipsis: true,

      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            onClick={() => this.onClickEditIcon(record.id)}
            style={{ marginRight: "10px" }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  onChangeTab = (name, id) => {
    this.setState({ activeTab: name, activeTabId: id }, () => {
      this.props.getSchoolListingForType(id);
    });
  };

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  handleCancelAddSchoolModal = () => {
    this.setState({
      visibleSchoolModal: false,
    });
  };

  onAddingNewSchool = () => {
    this.setState({
      readOnly: false,
      visibleSchoolModal: true,
      schoolName: "",
      schoolType: null,
      schoolCity: "",
      schoolAddress: "",
      schoolWebsite: "",
      schoolEmail: "",
      schoolNotes: "",
      contacts: [],
      levels: [],
      aggrementUrl: "",
      agreementFileName: "",
    });
  };

  getTabs = () => {
    return (
      <Row>
        <Col>
          <div className="sus-tab-container-schools">
            {this.state.schoolTypeList &&
              this.state.schoolTypeList.map((data, index) => {
                return (
                  <div
                    onClick={() => this.onChangeTab(data.name, data.id)}
                    style={{
                      borderLeft: index === 0 ? "none" : "2px solid white",
                    }}
                    className={
                      this.state.activeTab == data.name
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                    key={index}
                  >
                    <span
                      className={
                        this.state.activeTab == data.name
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                    >
                      {data.name.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            <div className="d-end space-between cont-positioning">
              <div className="add-tag-btn">
                <>
                  <img
                    src={Images.addIcon}
                    className="icons-client"
                    type="primary"
                    onClick={() => this.onAddingNewSchool()}
                  />
                </>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  getSchoolListingTable = () => {
    return (
      <Row className="ts-container-schools">
        <Col xs={24} className={"school-table"}>
          <Table
            dataSource={
              this.state.schoolListForType ? this.state.schoolListForType : null
            }
            rowKey={(record, index) => index}
            columns={this.columns}
            onChange={this.paginate}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ["20", "50", "100"],
            }}
            onRow={(record) => ({
              onClick: () => {},
            })}
          />
        </Col>
      </Row>
    );
  };

  handleDeleteSchool = (id) => {
    this.props.deleteSchool(id).then((res) => {
      if (res.payload.response === false) {
        message.error(
          "School can't be deleted as students are linked with it!"
        );
      } else {
        message.success("School is deleted successfully!");
      }
      this.props.getSchoolListingForType(this.state.activeTabId);
      this.setState({ visibleDeleteModal: false });
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete school?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.handleDeleteSchool(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row style={{ display: "flex" }}>
            <Col xs={24}>
              <div className="page-container">
                {this.getTabs()}
                {this.getSchoolListingTable()}
                {this.getModalDelete()}
                {this.state.visibleSchoolModal ? (
                  <SchoolForm
                    readOnly={this.state.readOnly}
                    schoolName={this.state.schoolName}
                    schoolType={this.state.schoolType}
                    schoolCity={this.state.schoolCity}
                    schoolAddress={this.state.schoolAddress}
                    schoolWebsite={this.state.schoolWebsite}
                    schoolEmail={this.state.schoolEmail}
                    schoolNotes={this.state.schoolNotes}
                    contacts={this.state.contacts}
                    levels={this.state.levels}
                    visibleSchoolModal={this.state.visibleSchoolModal}
                    handleCancelAddSchoolModal={this.handleCancelAddSchoolModal}
                    schoolInfo={this.props && this.props.schoolInfo}
                    aggrementUrl={this.state && this.state.aggrementUrl}
                    agreementFileName={
                      this.state && this.state.agreementFileName
                    }
                    schoolTypeList={this.state.schoolTypeList}
                    activeTabId={this.state.activeTabId}
                    schoolId={this.state.schoolId}
                  />
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schoolTypeListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing.items,
    schoolListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolListing &&
      state.schoolReducer.schoolListing &&
      state.schoolReducer.schoolListing.items,
    schoolListingForType:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolListingForType &&
      state.schoolReducer.schoolListingForType &&
      state.schoolReducer.schoolListingForType.items,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    schoolInfo:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolInfo &&
      state.schoolReducer.schoolInfo,
    aggrementUrl:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolInfo &&
      state.schoolReducer.schoolInfo.agreementUrl,
    // bol: state && state.schoolReducer && state.schoolReducer.bol,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSchoolTypeListing: bindActionCreators(getSchoolTypeListing, dispatch),
  getSchoolListing: bindActionCreators(getSchoolListing, dispatch),
  getSchoolListingForType: bindActionCreators(
    getSchoolListingForType,
    dispatch
  ),
  deleteSchool: bindActionCreators(deleteSchool, dispatch),
  onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
  addSchool: bindActionCreators(addSchool, dispatch),
  getSchoolInfo: bindActionCreators(getSchoolInfo, dispatch),
});

Schools = connect(mapStateToProps, mapDispatchToProps)(Schools);

export default Schools;
