import React, { Fragment } from "react";
import HeaderBar from "../../src/Components/Header/HeaderBar";
import Sidebar from "../../src/Components/SideBar";
import InnerHeader from "./SideArea/InnerHeader";

const DailyTaskReminders = ({
  onGetReminderTasks,
  reminderTaskRes,
  onAddReminderTask,

  onGetDailyTasks,
  dailyTaskRes,

  onAddDailyTasks,

  onRemoveTasks,
  removeTaskRes,

  onUpdetaTasks,

  onUpdateCompletedTask,
  updateComletedTaskRes,

  onGetAllClients,
  allClientData,

  onRemoveReminderTask,
  onGetTaskComments,
  onAddTaskComments,
  onGetAllUsers,
  onGetTaskFollowers,
  onAddTaskFollower,
  onSearchClient,
  onSearchPotentialClient,
  searchClientData,
  potentialClientsData,

  onUpdateReminderDetail,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  onRemoveFollower,
  onRemoveTaskClient,
  onAddFileNote,
  onGetCompletedTasks,
  onGetEmployerData,
  onGetTimeTrackingPopup,
  timeTrackingPopupRes,
  onGetVisaWorkType,
  onGetVisaTypePrice,
  onGetServiceType,
  onGetVisaType,
  visaWorkTypeRes,
  visaTypePriceRes,
  serviceTypeRes,
  visaTypeData,
  onGetPermProcessingPerson,
  visaAppData,
  onGetVisaApplication,
}) => {
  return (
    <div>
      <div style={{ width: "100%" }}>
        <InnerHeader
          onGetReminderTasks={onGetReminderTasks}
          reminderTaskRes={reminderTaskRes}
          onAddReminderTask={onAddReminderTask}
          onGetDailyTasks={onGetDailyTasks}
          dailyTaskRes={dailyTaskRes}
          onAddDailyTasks={onAddDailyTasks}
          onRemoveTasks={onRemoveTasks}
          removeTaskRes={removeTaskRes}
          onUpdetaTasks={onUpdetaTasks}
          onUpdateCompletedTask={onUpdateCompletedTask}
          updateComletedTaskRes={updateComletedTaskRes}
          onGetAllClients={onGetAllClients}
          allClientData={allClientData}
          onRemoveReminderTask={onRemoveReminderTask}
          getTasksComments={onGetTaskComments}
          addTaskComment={onAddTaskComments}
          getAllUsers={onGetAllUsers}
          getTaskFollowers={onGetTaskFollowers}
          addTaskFollower={onAddTaskFollower}
          searchClientData={searchClientData}
          potentialClientsData={potentialClientsData}
          onSearchClient={onSearchClient}
          onSearchPotentialClient={onSearchPotentialClient}
          onUpdateReminderDetail={onUpdateReminderDetail}
          onGetClientProfile={onGetClientProfile}
          onGetPotentialClient={onGetPotentialClient}
          onGetUpdateBol={onGetUpdateBol}
          onRemoveFollower={onRemoveFollower}
          onRemoveTaskClient={onRemoveTaskClient}
          onAddFileNote={onAddFileNote}
          onGetCompletedTasks={onGetCompletedTasks}
          onGetEmployerData={onGetEmployerData}
          onGetTimeTrackingPopup={onGetTimeTrackingPopup}
          timeTrackingPopupRes={timeTrackingPopupRes}
          onGetVisaWorkType={onGetVisaWorkType}
          onGetVisaTypePrice={onGetVisaTypePrice}
          onGetServiceType={onGetServiceType}
          onGetVisaType={onGetVisaType}
          visaWorkTypeRes={visaWorkTypeRes}
          visaTypePriceRes={visaTypePriceRes}
          serviceTypeRes={serviceTypeRes}
          visaTypeData={visaTypeData}
          onGetPermProcessingPerson={onGetPermProcessingPerson}
          onGetVisaApplication={onGetVisaApplication}
          visaAppData={visaAppData}
        />
        ;
      </div>
    </div>
  );
};

export default DailyTaskReminders;
