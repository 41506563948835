import React, { useState, Fragment, useEffect } from "react";
// import JobEmployerForm from "./JobEmployerForm";
import { Images } from "../Themes";
import { Spin, message, Modal } from "antd";
import AddPipelineForm from "./AddPipelineForm";
import SalesPipelineTable from "./SalesPipelineTable";
// import JobEmployerTable from "./JobEmployerTable";
// import UpdateEmployerJob from "./UpdateEmployerJob";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const SalesPipeline = ({
  onAddSalePipeline,
  onGetSalePipeline,
  getSalePipelineRes,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState();

  useEffect(() => {}, []);

  const showModal = (value, modelType) => {
    setIsModalVisible(true);
    setCreateMode(modelType);
    setUpdatedata(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div style={{ margin: 25, marginTop: 0 }}>
          <div style={{ display: "flex" }}>
            <div className="contact-emp" style={{ padding: 20 }}>
              <div className="client-tag-form"></div>
              <div>
                <div>
                  <div className="d-end space-between">
                    <div
                      style={{ marginBottom: "7px" }}
                      className="contact-Head"
                    >
                      <div className="d-flex align-item ">
                        <h4
                          className="top-text heading-forms"
                          style={{
                            marginLeft: "1px",
                            fontWeight: 600,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Sales Pipeline
                        </h4>
                      </div>
                      <>
                        <img
                          style={{ width: "29px", height: "29px" }}
                          src={Images.addIcon}
                          className="icons-client"
                          type="primary"
                          onClick={() =>
                            showModal(!isModalVisible, "add-pipeline")
                          }
                        />
                      </>
                    </div>
                  </div>

                  {isModalVisible && (
                    <Modal
                      title={
                        createMode === "add-pipeline"
                          ? "Add Pipeline"
                          : "Update Pipeline"
                      }
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      header={false}
                      footer={false}
                      width={550}
                    >
                      <AddPipelineForm
                        onAddSalePipeline={onAddSalePipeline}
                        handleCancel={handleCancel}
                        createMode={createMode}
                        setLoading={setLoading}
                        loading={loading}
                        onGetSalePipeline={onGetSalePipeline}
                        updatedata={updatedata}
                      />
                    </Modal>
                  )}

                  <SalesPipelineTable
                    getSalePipelineRes={getSalePipelineRes}
                    showModal={showModal}
                    isModalVisible={isModalVisible}
                    onGetSalePipeline={onGetSalePipeline}
                    setLoading={setLoading}
                    // onGetClientSearch={onGetClientSearch}
                    // clientSearchRes={clientSearchRes}
                    // onSearchClient={onSearchClient}
                    // searchClientData={searchClientData}
                    // onGetClientByEmpId={onGetClientByEmpId}
                    // empClientEmpRes={empClientEmpRes}
                    // onLinkEmpWithClient={onLinkEmpWithClient}
                    // onAddEmployerJobHistory={onAddEmployerJobHistory}
                    // onGetSingleClientHis={onGetSingleClientHis}
                    // singleJobHisRes={singleJobHisRes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default SalesPipeline;
