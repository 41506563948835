import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DashboardEmployers from "./../Dashboard/Employers";

import {
  getDashboardEmployers,
  updateClientPriority,
  getClientFile,
  addClientFile,
  getEmployerContact,
  addEmployerContact,
  removeEmployerContact,
  updateEmployerContact,
  getEmployerJob,
  addEmployerJob,
  updateEmployerJob,
  removeEmployerJob,
  getClientSearch,
  searchMainClient,
  getClientByEmpId,
  linkEmpWithClient,
  addEmployerJobHistory,
  getSingleClientHis,
  getTeamMember,
  getClientEmp,
  getClientTag,
  getProfileClientTag,
  getClientJobHis,
  updateClientCasePriority,
  getVisaStatus,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dashboardEmployersList:
      state.dashboardEmployersReducer.dashboardEmployersList,
    EmployerContactRes: state.employerManagReducer.EmployerContactRes,
    employerJobRes: state.employerManagReducer.employerJobRes,
    teamMembers: state.teamMemberReducer.teamMembers,
    clientSearchRes: state.employerManagReducer.clientSearchRes,
    searchClientData: state.clientProfileReducer.searchConnectionData,
    empClientEmpRes: state.employerManagReducer.empClientEmpRes,
    singleJobHisRes: state.employerManagReducer.singleJobHisRes,
    clientEmpRes: state.employerManagReducer.clientEmpRes,
    empClientEmpRes: state.employerManagReducer.empClientEmpRes,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    clientJobHisRes: state.employerManagReducer.clientJobHisRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDashboardEmployers: bindActionCreators(
      getDashboardEmployers,
      dispatch
    ),
    onUpdateClientPriority: bindActionCreators(updateClientPriority, dispatch),
    onUpdateClientCasePriority: bindActionCreators(
      updateClientCasePriority,
      dispatch
    ),
    onGetClientFile: bindActionCreators(getClientFile, dispatch),
    onAddClientFile: bindActionCreators(addClientFile, dispatch),
    ongetEmployerContact: bindActionCreators(getEmployerContact, dispatch),
    onAddEmployerContact: bindActionCreators(addEmployerContact, dispatch),
    onRemoveEmployerContact: bindActionCreators(
      removeEmployerContact,
      dispatch
    ),
    onUpdateEmployerContact: bindActionCreators(
      updateEmployerContact,
      dispatch
    ),
    onGetEmployerJob: bindActionCreators(getEmployerJob, dispatch),
    onAddEmployerJob: bindActionCreators(addEmployerJob, dispatch),
    onUpdateEmployerJob: bindActionCreators(updateEmployerJob, dispatch),
    onRemoveEmployerJob: bindActionCreators(removeEmployerJob, dispatch),
    onGetClientSearch: bindActionCreators(getClientSearch, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onGetClientByEmpId: bindActionCreators(getClientByEmpId, dispatch),
    onLinkEmpWithClient: bindActionCreators(linkEmpWithClient, dispatch),
    onAddEmployerJobHistory: bindActionCreators(
      addEmployerJobHistory,
      dispatch
    ),
    onGetSingleClientHis: bindActionCreators(getSingleClientHis, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetClientEmp: bindActionCreators(getClientEmp, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onGetClientJobHis: bindActionCreators(getClientJobHis, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEmployers);
