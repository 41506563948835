import { api } from "../../services/api";
import * as types from "../Constants";

export const updateImapSettings = (data) => {
  const options = {
    url: "v1/users/UpdateUserIMAP",
  };
  options.types = [
    types.UPDATE_IMAP_SETTING_SUCCESS,
    types.UPDATE_IMAP_SETTING_FAILURE,
  ];

  return api.put(options, data);
};

export const getImapSettings = () => {
  const options = {
    url: `v1/users/GetUserIMAP`,
  };

  options.types = [
    types.GET_IMAP_SETTING_SUCCESS,
    types.GET_IMAP_SETTING_FAILURE,
  ];

  return api.get(options);
};

export const getImapForAll = () => {
  const options = {
    url: `v1/users/UserIMAPForAll`,
  };

  options.types = [
    types.GET_IMAP_FOR_ALL_SUCCESS,
    types.GET_IMAP_FOR_ALL_FAILURE,
  ];

  return api.get(options);
};

export const updImapForAll = (data) => {
  const options = {
    url: `v1/users/UserIMAPForAll`,
  };

  options.types = [
    types.UPDATE_IMAP_FOR_ALL_SUCCESS,
    types.UPDATE_IMAP_FOR_ALL_FAILURE,
  ];

  return api.put(options, data);
};
