import React, { Fragment } from "react";
import { Form, Input, Button, Space, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FroalaEditorCom from "../../../../../Containers/FroalaEditorCom";

const CreateEmailEditors = ({ data, handleEditNote }) => {
  return (
    <FroalaEditorCom
      model={data.content}
      onModelChange={(value) => handleEditNote(value, data.id)}
    />
  );
};
export default CreateEmailEditors;
