import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const clientProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_PROFILE_SUCCESS:
      if (action.payload) {
        localStorage.setItem(
          "clientprofileid",
          JSON.stringify(action.payload.id)
        );
        sessionStorage.setItem("clientprofileid", action.payload.id);
        sessionStorage.setItem("memberType", "client");

        localStorage.setItem("clientProfileIdMain", action.payload.clientId);
        localStorage.setItem("familyId", action.payload.familyId);
        sessionStorage.setItem("clientProfileIdMain", action.payload.clientId);
        sessionStorage.setItem("familyId", action.payload.familyId);
      }
      return { ...state, clientProfileData: action.payload };
    case types.SET_CLIENT_PROFILE_LOADER_SUCCESS:
      return { ...state, loader: action.payload };
    case types.GET_CLIENT_PROFILE_FAILURE:
      return { ...state, clientProfileError: action.payload };

    case types.UPD_CLIENT_PROFILE_SUCCESS:
      return { ...state, updProfileSuccess: action.payload };
    case types.UPD_CLIENT_PROFILE_FAILURE:
      return { ...state, updProfileFailure: action.payload };

    case types.UPD_CLIENT_ADDRESS_SUCCESS:
      return { ...state, updAddressSuccess: action.payload };
    case types.UPD_CLIENT_ADDRESS_FAILURE:
      return { ...state, updAddressFailure: action.payload };

    case types.UPD_CLIENT_EMAIL_SUCCESS:
      return { ...state, updEmailSuccess: action.payload };
    case types.UPD_CLIENT_EMAIL_FAILURE:
      return { ...state, updEmailFailure: action.payload };

    case types.UPD_CLIENT_MEDICAL_SUCCESS:
      return { ...state, updMedicalSuccess: action.payload };
    case types.UPD_CLIENT_MEDICAL_FAILURE:
      return { ...state, updMedicalFailure: action.payload };

    case types.UPD_CLIENT_PHONE_SUCCESS:
      return { ...state, updPhoneSuccess: action.payload };
    case types.UPD_CLIENT_PHONE_FAILURE:
      return { ...state, updPhoneFailure: action.payload };

    case types.UPD_CLIENT_PASSPORT_SUCCESS:
      return { ...state, updPassportSuccess: action.payload };
    case types.UPD_CLIENT_PASSPORT_FAILURE:
      return { ...state, updPassportFailure: action.payload };

    case types.GET_ALL_CLIENT_SUCCESS:
      return { ...state, allClientData: action.payload };
    case types.GET_ALL_CLIENT_FAILURE:
      return { ...state, allClientError: action.payload };

    case types.GET_PROCESSING_GROUPS_SUCCESS:
      return { ...state, processingGroupData: action.payload };
    case types.GET_PROCESSING_GROUPS_FAILURE:
      return { ...state, processingGroupError: action.payload };

    case types.ADD_PROCESSING_GROUP_SUCCESS:
      return { ...state, addProcGroupSuccess: action.payload };
    case types.ADD_PROCESSING_GROUP_FAILURE:
      return { ...state, addProcGroupError: action.payload };

    case types.REMOVE_PROCESSING_GROUP_SUCCESS:
      return { ...state, removeProcGroupSuccess: action.payload };
    case types.REMOVE_PROCESSING_GROUP_FAILURE:
      return { ...state, removeProcGroupError: action.payload };

    case types.GET_GROUP_MEMBER_SUCCESS:
      return { ...state, groupMembersData: action.payload };
    case types.GET_GROUP_MEMBER_FAILURE:
      return { ...state, groupMembersError: action.payload };

    case types.ADD_PROCESSING_PERSON_SUCCESS:
      return { ...state, addProcPersonSuccess: action.payload };
    case types.ADD_PROCESSING_PERSON_FAILURE:
      return { ...state, addProcPersonError: action.payload };

    case types.REMOVE_PROCESSING_PERSON_SUCCESS:
      return { ...state, removeProcPersonSuccess: action.payload };
    case types.REMOVE_PROCESSING_PERSON_FAILURE:
      return { ...state, removeProcPersonError: action.payload };

    case types.GET_ALL_POLICE_CERTIFICATE_SUCCESS:
      return { ...state, policeCertificateData: action.payload };
    case types.GET_ALL_POLICE_CERTIFICATE_FAILURE:
      return { ...state, policeCertificateDataError: action.payload };

    case types.ADD_POLICE_CERTIFICATE_SUCCESS:
      return { ...state, policeCertificateSuccess: action.payload };
    case types.ADD_POLICE_CERTIFICATE_FAILURE:
      return { ...state, policeCertificateError: action.payload };

    case types.ADD_ACCESSING_AUTHORITIES_SUCCESS:
      return { ...state, accAuthoritiesSuccess: action.payload };
    case types.ADD_ACCESSING_AUTHORITIES_FAILURE:
      return { ...state, accAuthoritiesError: action.payload };

    case types.REMOVE_POLICE_CERTIFICATE_SUCCESS:
      return { ...state, removeCertificateSuccess: action.payload };
    case types.REMOVE_POLICE_CERTIFICATE_FAILURE:
      return { ...state, removeCertificateError: action.payload };

    case types.REMOVE_ACCESSING_AUTHORITIES_SUCCESS:
      return { ...state, removeAuthoritySuccess: action.payload };
    case types.REMOVE_ACCESSING_AUTHORITIES_FAILURE:
      return { ...state, removeAuthorityError: action.payload };

    case types.SEARCH_CONNECTION_SUCCESS:
      return { ...state, searchConnectionData: action.payload };
    case types.SEARCH_CONNECTION_FAILURE:
      return { ...state, searchConnectionError: action.payload };

    case types.ADD_CONNECTION_SUCCESS:
      return { ...state, addConnectionData: action.payload };
    case types.ADD_CONNECTION_FAILURE:
      return { ...state, addConnectionError: action.payload };

    case types.REMOVE_CONNECTION_SUCCESS:
      return { ...state, removeConnectionSuccess: action.payload };
    case types.REMOVE_CONNECTION_FAILURE:
      return { ...state, removeConnectionError: action.payload };

    case types.GET_CLIENT_TEMPLATE_LISTING_SUCCESS:
      return { ...state, clientTemplateListing: action.payload };
    case types.GET_CLIENT_TEMPLATE_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Client Template list is empty!");
        return { ...state, clientTemplateListing: [] };
      } else {
      }
      console.log("on error console", action.payload);

    case types.GET_ACCESSING_AUTH_SUCCESS:
      return { ...state, accessingAuthData: action.payload };
    case types.GET_ACCESSING_AUTH_FAILURE:
      return { ...state, accessingAuthError: action.payload };

    case types.GET_CLIENT_FAMILY_SUCCESS:
      return { ...state, clientFamilyData: action.payload };
    case types.GET_CLIENT_FAMILY_FAILURE:
      return { ...state, clientFamilyError: action.payload };

    case types.CREATE_CLIENT_LINK_SUCCESS:
      return { ...state, clientLinkSuccess: action.payload };
    case types.CREATE_CLIENT_LINK_FAILURE:
      return { ...state, clientLinkFailure: action.payload };

    case types.GET_CLIENT_LINK_SUCCESS:
      return { ...state, clientLinkData: action.payload };
    case types.GET_CLIENT_LINK_FAILURE:
      return { ...state, clientLinkError: action.payload };

    case types.SET_CMV_LINK_TEMPLATE_SUCCESS:
      return { ...state, cmvLinkTemplateData: action.payload };
    case types.SET_CMV_LINK_TEMPLATE_FAILURE:
      return { ...state, cmvLinkTemplateError: action.payload };

    case types.GET_MAIN_CLIENT_SUCCESS:
      return {
        ...state,
        potentialClientsData: [],
        clientsData: action.payload,
      };
    case types.GET_MAIN_CLIENT_FAILURE:
      return { ...state, clientsError: action.payload, clientsData: [] };

    case types.GET_POTENTIAL_CLIENT_SUCCESS:
      return {
        ...state,
        clientsData: [],
        potentialClientsData: action.payload,
      };
    case types.GET_POTENTIAL_CLIENT_FAILURE:
      return {
        ...state,
        potentialClientsError: action.payload,
        potentialClientsData: [],
      };

    case types.REMOVE_CLIENT_SUCCESS:
      return { ...state, removeClientRes: action.payload };
    case types.REMOVE_CLIENT_FAILURE:
      return { ...state, cmvLinkTemplateError: action.payload };

    case types.UPDATE_SIGNED_DATE_SUCCESS:
      return { ...state, updateSignedRes: action.payload };
    case types.UPDATE_SIGNED_DATE_FAILURE:
      return { ...state, updateSignedError: action.payload };

    case types.CHECK_UNIQUE_EMAIL_SUCCESS:
      return { ...state, uniqueEmailRes: action.payload };
    case types.CHECK_UNIQUE_EMAIL_FAILURE:
      return { ...state, uniqueEmailError: action.payload };

    case types.GET_EMPLOYER_SEARCH_SUCCESS:
      return { ...state, mainEmpSearchRes: action.payload };
    case types.GET_EMPLOYER_SEARCH_FAILURE:
      return { ...state, mainEmpSearchError: action.payload };

    case types.SET_PROFILE_DATA_SUCCESS:
      return { ...state, profileDataRdx: action.payload };

    default:
      return state;
  }
};
