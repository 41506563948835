import { api } from "../../services/api";
import * as types from "../Constants";

export const userLogin = (data) => {
  const options = {
    url: "v1/user/identity/AuthenticateUser",
  };

  options.types = [types.LOGIN_SUCCESS, types.LOGIN_FAILURE];

  return api.authLogin(options, data);
};

export const userExternalLogin = (data) => {
  const options = {
    url: "v1/user/identity/AuthenticateExternalUser",
  };

  options.types = [types.LOGIN_SUCCESS, types.LOGIN_FAILURE];

  return api.authLogin(options, data);
};

export const userLogout = () => {
  const options = {
    url: "v1/user/identity/Logout",
  };

  options.types = [types.LOGOUT_SUCCESS, types.LOGOUT_FAILURE];

  return api.getLogout(options);
};

export const refreshToken = () => {
  const options = {
    url: "v1/user/identity/RefreshToken",
  };

  options.types = [types.TOKEN_REFRESH_SUCCESS, types.TOKEN_REFRESH_FAILURE];

  return api.post(options);
};

export const addNotificationToken = (subjectId, token) => {
  const options = {
    url: `v1/device/Add/${subjectId}/${token}`,
  };

  options.types = [
    types.ADD_NOTIFICATION_TOKEN_SUCCESS,
    types.ADD_NOTIFICATION_TOKEN_FAILURE,
  ];

  return api.get(options);
};

export const removeNotificationToken = (subjectId, token) => {
  const options = {
    url: `v1/device/Remove/${subjectId}/${token}`,
  };

  options.types = [
    types.REMOVE_NOTIFICATION_TOKEN_SUCCESS,
    types.REMOVE_NOTIFICATION_TOKEN_FAILURE,
  ];

  return api.get(options);
};

export const userActivityLog = (data) => {
  const options = {
    url: `v1/client/GetUserLog`,
  };

  options.types = [
    types.GET_USER_ACTIVITY_SUCCESS,
    types.GET_USER_ACTIVITY_FAILURE,
  ];

  return api.post(options, data);
};

export const sendForgotPasswordEmail = (data) => {
  const options = {
    url: "v1/user/identity/ForgotPassword",
  };

  options.types = [types.SEND_FORGOT_SUCCESS, types.SEND_FORGOT_FAILURE];

  return api.post(options, data);
};
export const passwordRecovery = (data) => {
  const options = {
    url: "v1/user/identity/PasswordRecovery",
  };
  options.types = [
    types.PASSWORD_RECOVERY_SUCCESS,
    types.PASSWORD_RECOVERY_FAILURE,
  ];
  return api.post(options, data);
};

export const supervisorLogin = (data) => {
  const options = {
    url: "v1/supervisor/identity/AuthenticateSupervisor",
  };
  options.types = [
    types.SUPERVISOR_LOGIN_SUCCESS,
    types.SUPERVISOR_LOGIN_FAILURE,
  ];
  return api.authLogin(options, data);
};
