import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeZoneSettings from "../AccountSetting/UserLevelSettings/TimeZoneSettings/TimeZoneSettings";

import {
  getTimeZone,
  setActiveInnerTab,
  updateTimeZone,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    timeZoneRes: state.documentTypeReducer.timeZoneRes,
    updateTimeZoneRes: state.documentTypeReducer.updateTimeZoneRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTimeZone: bindActionCreators(getTimeZone, dispatch),
    onUpdateTimeZone: bindActionCreators(updateTimeZone, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeZoneSettings);
