import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const addchecklistReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_VISA_CATEGORY_TYPE_SUCCESS:
      return { ...state, addVisaCategoryRes: action.payload };
    case types.ADD_VISA_CATEGORY_TYPE_FAILURE:
       
    case types.GET_VISA_CATEGORY_TYPE_SUCCESS:
      return { ...state, visaCategoryRes: action.payload };
    case types.GET_VISA_CATEGORY_TYPE_FAILURE:
       
    case types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_SUCCESS:
      return { ...state, accountsFlowListing: action.payload };
    case types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "list is empty!");
        return { ...state, accountsFlowListing: [] };
      } else {
         
      }
    case types.ADD_VISA_CHECKLIST_SUCCESS:
      return { ...state, addVisaChecklistRes: action.payload };
    case types.ADD_VISA_CHECKLIST_FAILURE:
       
    default:
      return state;
  }
};
