import React, { useState, Fragment, useEffect } from "react";
import JobEmployerForm from "./JobEmployerForm";
import { Images } from "../../../../../Themes";
import { Spin, message, Modal } from "antd";
import JobEmployerTable from "./JobEmployerTable";
import UpdateEmployerJob from "./UpdateEmployerJob";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const JobEmployer = ({
  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  userDataEmp,

  onGetTeamMember,
  teamMembers,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetSingleClientHis,
  singleJobHisRes,
  onJobUpdate,
  tabKey,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState();

  useEffect(() => {
    setLoading(true);
    if (userDataEmp) {
      onGetEmployerJob(userDataEmp && userDataEmp.id).then(() => {
        setLoading(false);
      });
      onGetTeamMember();
    }
  }, [onGetEmployerJob, onGetTeamMember, userDataEmp]);

  useEffect(() => {
    if (tabKey == "1") {
      onGetEmployerJob(userDataEmp && userDataEmp.id).then(() => {
        setLoading(false);
      });
      onGetTeamMember();
    }
  }, [tabKey]);

  const showModal = (value, modelType) => {
    setIsModalVisible(true);
    setCreateMode(modelType);
    setUpdatedata(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div>
          <div style={{ display: "flex" }}>
            <div className="contact-emp">
              <div className="client-tag-form"></div>
              <div>
                <div>
                  <div className="d-end space-between">
                    <div
                      style={{ marginBottom: "7px" }}
                      className="contact-Head"
                    >
                      <div className="d-flex align-item ">
                        <h4
                          className="top-text heading-forms"
                          style={{
                            marginLeft: "1px",
                            fontWeight: 600,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Jobs
                        </h4>
                      </div>
                      <>
                        <img
                          style={{ width: "29px", height: "29px" }}
                          src={Images.addIcon}
                          className="icons-client"
                          type="primary"
                          onClick={() => showModal(!isModalVisible, "add-job")}
                        />
                      </>
                    </div>
                  </div>

                  {isModalVisible && (
                    <Modal
                      title={
                        createMode === "add-job" ? "Add Job" : "Update Job"
                      }
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      header={false}
                      footer={false}
                      width={1100}
                    >
                      <JobEmployerForm
                        onGetEmployerJob={onGetEmployerJob}
                        onAddEmployerJob={onAddEmployerJob}
                        userDataEmp={userDataEmp}
                        handleCancel={handleCancel}
                        teamMembers={teamMembers && teamMembers.users}
                        setLoading={setLoading}
                        loading={loading}
                        createMode={createMode}
                        onUpdateEmployerJob={onUpdateEmployerJob}
                        updatedata={updatedata}
                        onGetClientSearch={onGetClientSearch}
                        clientSearchRes={clientSearchRes}
                        onJobUpdate={onJobUpdate}
                      />
                    </Modal>
                  )}

                  <JobEmployerTable
                    employerJobRes={employerJobRes}
                    showModal={showModal}
                    isModalVisible={isModalVisible}
                    onRemoveEmployerJob={onRemoveEmployerJob}
                    onGetEmployerJob={onGetEmployerJob}
                    userDataEmp={userDataEmp}
                    setLoading={setLoading}
                    onGetClientSearch={onGetClientSearch}
                    clientSearchRes={clientSearchRes}
                    onSearchClient={onSearchClient}
                    searchClientData={searchClientData}
                    onGetClientByEmpId={onGetClientByEmpId}
                    empClientEmpRes={empClientEmpRes}
                    onLinkEmpWithClient={onLinkEmpWithClient}
                    onAddEmployerJobHistory={onAddEmployerJobHistory}
                    onGetSingleClientHis={onGetSingleClientHis}
                    singleJobHisRes={singleJobHisRes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default JobEmployer;
