import { api } from "../../services/api";
import * as types from "../Constants";

export const getOwner = data => {
  const options = {
    url: "v1/users/Owners"
  };

  options.types = [types.OWNER_SUCCESS, types.OWNER_FAILURE];

  return api.get(options);
};

export const updateOwner = data => {
  const options = {
    url: "v1/users"
  };

  options.types = [types.UPDATE_OWNER_SUCCESS, types.UPDATE_OWNER_FAILURE];

  return api.put(options, data);
};
