import React, { Fragment } from "react";
import { Table } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Images } from "../../Themes";
import TimeTrackingReportTable from "./TimeTrackingTable";

// const SaleHistoryReport = ({ saleHistoryRes, displayText }) => {
export class TimeTrackingReportChild extends React.PureComponent {
  render() {
    const {
      fileNotesReportRes,
      displayText,
      reportsCount,
      onTimeTrackingReport,
      requestData,
      totalTime,
    } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          {/* <div className="rep-print-row-cont">
            <div />
             <div>
              <div className="rep-print-icon-cont">
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <img
                        style={{ cursor: "pointer" }}
                        src={Images.printNBlue}
                        className="rep-print-icon"
                      />
                    );
                  }}
                  content={() => this.fileNotetRef}
                />
              </div>
            </div> 
          </div> */}
          <TimeTrackingReportTable
            fileNotesReportRes={fileNotesReportRes}
            ref={(el) => (this.fileNotetRef = el)}
            displayText={displayText}
            reportsCount={reportsCount}
            onTimeTrackingReport={onTimeTrackingReport}
            requestData={requestData}
            totalTime={totalTime}
          />
        </div>
      </Fragment>
    );
  }
}
export default TimeTrackingReportChild;
