import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaBranchCountry from "../SuperUserSetting/VisaBranchCountry";

import { getCountries, setActiveInnerTab } from "../store/Actions";
const mapStateToProps = (state) => {
  return {
    countriesData: state.visaTypeReducer.countriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaBranchCountry);
