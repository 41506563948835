import React, { Fragment, useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditorCom from "../../../Containers/FroalaEditorCom";

const CreateEmailEditors = ({ data, handleEditNote }) => {
  const [modelContent, setModelContent] = useState(data.content);

  useEffect(() => {
    setModelContent(data.content);
  }, [data]);
  let config = {
    key:
      "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
    height: "auto",
    toolbarSticky: false,
    events: {
      "charCounter.update": () => {
        // Do something here.
        // this is the editor instance.
        console.log("char");
      },
    },
  };

  const modelChange = (value) => {
    setModelContent(value);
    handleEditNote(value, data.id);
  };

  return (
    <Fragment>
      <FroalaEditorCom
        // ref={(el) => {
        //   config = el;
        // }}
        // config={config}
        model={modelContent}
        onModelChange={modelChange}
      />
    </Fragment>
  );
};
export default CreateEmailEditors;
