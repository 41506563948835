import React from "react";
import "./SchoolManagementStyles.css";
import { Select, Table, Spin, DatePicker, Button } from "antd";
import { bindActionCreators } from "redux";
import {
  getStudentListing,
  getClientTag,
  getSchoolTypeListing,
  getSchoolListing,
  getStatuses,
} from "../store/Actions";
import { connect } from "react-redux";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { apiRefresh } from "../services/api";
import { saveAs } from "file-saver";
const { Option } = Select;

const statuses = [
  { value: 1, label: "Paid" },
  { value: 2, label: "Unpaid" },
];

let selectedBranchId = localStorage.getItem("selectedBranchId");
class StudentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0,
      schoolType: 0,
      schoolId: "00000000-0000-0000-0000-000000000000",
      status: 0,
      pageSize: 20,
      pageNumber: 1,
      defaultCurrent: 1,
      branchId: selectedBranchId,
      tags: "",
      selectedOption: null,
      loading: true,
      dateFrom: "",
      dateTo: "",
    };
  }
  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        this.props.getStudentListing(this.state).then(() => {
          this.setState({ defaultCurrent: 1 });
        });
      }
    );
  };

  columns = [
    {
      title: "Client Name",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname:
                record.memberType.toLowerCase() === "client"
                  ? "/profile"
                  : record.memberType.toLowerCase() === "spouse"
                  ? "/partner-profile"
                  : "/client-family-member",
              state: { id: record.clientId, familyId: record.familyId },
            }}
          >
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {record.fullName}
            </p>
          </Link>
        );
      },
    },
    {
      title: "School Type",
      dataIndex: "schoolType",
      key: "schoolType",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.schoolType}
          </p>
        );
      },
    },
    {
      title: "School Name",
      dataIndex: "schoolName",
      key: "schoolName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.schoolName}
          </p>
        );
      },
    },
    {
      title: "Program Name",
      dataIndex: "programName",
      key: "programName",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.programName}
          </p>
        );
      },
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      width: "68px",
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.fee}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "programStatusName",
      key: "programStatusName",
      width: "150px",
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.programStatusName}
          </p>
        );
      },
    },
    {
      title: "Applied Date",
      dataIndex: "appliedDate",
      key: "appliedDate",
      ellipsis: true,
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.appliedDate}
        </p>
      ),
    },
    {
      title: "Client Tags",
      dataIndex: "clienttags",
      key: "clienttags",
      ellipsis: true,
      render: (text, record) => {
        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {record.clienttags}
          </p>
        );
      },
    },
  ];

  componentDidMount() {
    this.props.getClientTag();
    this.props.getStatuses();
    this.props
      .getStudentListing(this.state)
      .then(() => {
        let _studentListingCount =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _studentListingCount, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
    this.props.getSchoolTypeListing();
    this.props.getSchoolListing();
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _studentList =
      nextProps &&
      nextProps.studentListing &&
      nextProps.studentListing.studentList;

    if (
      _studentList &&
      _studentList &&
      this.state.studentList !== _studentList
    ) {
      for (let index = 0; index < _studentList.length; index++) {
        _studentList[index].appliedDate =
          _studentList[index].appliedDate === "1900-01-01T00:00:00+00:00"
            ? ""
            : moment(_studentList[index].appliedDate).format("DD/MM/YYYY");
      }
    }

    this.setState({ studentList: _studentList });
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getStudentListing(this.state).then(() => {
        //   let _studentListingCount =
        //     this.props &&
        //     this.props.studentListingCount &&
        //     this.props.studentListingCount;
        //   this.setState({ totalPages: _studentListingCount });
        // });
        // this.props.getSchoolTypeListing();
        // this.props.getSchoolListing();
      });
    }
  }

  handleChangeSchoolType = (value) => {
    this.setState({ schoolType: value, pageNumber: 1, pageSize: 20 }, () => {
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  handleChangeSchoolName = (value) => {
    this.setState({ schoolId: value.toString(), pageNumber: 1 }, () => {
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  handleChangeStatus = (value) => {
    this.setState({ status: value, pageNumber: 1 }, () => {
      console.log("selected status", this.state);
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  handleChangeClientTags = (value) => {
    this.setState({ tags: value.toString(), pageNumber: 1 }, () => {
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  handleChangeDateTo = (value) => {
    this.setState({ dateTo: value, pageNumber: 1 }, () => {
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  handleChangeDateFrom = (value) => {
    this.setState({ dateFrom: value, pageNumber: 1 }, () => {
      this.props.getStudentListing(this.state).then(() => {
        let _length =
          this.props &&
          this.props.studentListingCount &&
          this.props.studentListingCount;

        this.setState({ totalPages: _length });
      });
    });
  };

  getSchoolTypes = (schoolType) => {
    return (
      <div style={{ width: "100%" }}>
        <p className={"font-school-list-filter-text"}>School Type</p>
        <div>
          <Select
            defaultValue={0}
            onChange={this.handleChangeSchoolType}
            className={"width-web-kit"}
          >
            <Option value={0}>{"All"}</Option>
            {this.props.schoolTypeListing &&
              this.props.schoolTypeListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };

  getSchoolNames = (schoolName) => {
    return (
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <p className={"font-school-list-filter-text"}>School</p>
        <div>
          <Select
            defaultValue={"00000000-0000-0000-0000-000000000000"}
            onChange={this.handleChangeSchoolName}
            className={"width-web-kit"}
          >
            <Option value={"00000000-0000-0000-0000-000000000000"}>
              {"All"}
            </Option>
            {this.props.schoolListing &&
              this.props.schoolListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };

  getStatuses = (status) => {
    return (
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <p className={"font-school-list-filter-text"}>Status</p>
        <div>
          <Select
            value={status}
            defaultValue={0}
            onChange={this.handleChangeStatus}
            className={"width-web-kit"}
          >
            <Option value={0}>{"All"}</Option>
            {this.props.statusesListing &&
              this.props.statusesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };

  getClientTags = (tags) => {
    return (
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <p className={"font-school-list-filter-text"}>Client Tag</p>
        <div>
          <Select
            defaultValue={""}
            onChange={this.handleChangeClientTags}
            className={"width-web-kit"}
          >
            <Option value={""}>{"All"}</Option>
            {this.props &&
              this.props.clientTagRes &&
              this.props.clientTagRes.items &&
              this.props.clientTagRes.items.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };

  getDateFrom = (dateFrom) => {
    return (
      <div style={{ width: "100%" }}>
        <p className={"font-school-list-filter-text"}>Date From</p>
        <div>
          <DatePicker
            value={dateFrom}
            format="DD/MM/YYYY"
            onChange={this.handleChangeDateFrom}
          />
        </div>
      </div>
    );
  };

  getDateTo = (dateTo) => {
    return (
      <div style={{ width: "100%", marginLeft: "15px" }}>
        <p className={"font-school-list-filter-text"}>Date To</p>
        <div>
          <DatePicker
            value={dateTo}
            format="DD/MM/YYYY"
            onChange={this.handleChangeDateTo}
          />
        </div>
      </div>
    );
  };

  exportStudentList = () => {
    let data = {
      schoolType: this.state.schoolType,
      schoolId: this.state.schoolId,
      status: this.state.status,
      clientTags: this.state.tags,
      pageSize: 1000,
      pageNumber: 1,
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00+00",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00+00",
    };
    const studentExpOpt = {
      url: "v1/school/studentListExport",
    };

    studentExpOpt.types = [
      "EXP_STUDENT_LISTING_SUCCESS",
      "EXP_STUDENT_LISTING_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .postWithFileRes(studentExpOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Student_List.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      schoolType,
      schoolName,
      status,
      tags,
      loading,
      dateFrom,
      dateTo,
    } = this.state;

    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="ts-container">
                <div style={{ display: "flex" }}>
                  {this.getSchoolTypes(schoolType)}
                  {this.getSchoolNames(schoolName)}
                  {this.getStatuses(status)}
                  {this.getClientTags(tags)}
                </div>
                <div style={{ display: "flex" }}>
                  {this.getDateFrom(dateFrom)}
                  {this.getDateTo(dateTo)}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Button
                    className="button-blue"
                    htmlType="submit"
                    onClick={this.exportStudentList}
                  >
                    Export
                  </Button>
                </div>
                <div style={{ marginTop: "65px" }}>
                  <div>
                    {/*<p className={"font-school-list-filter-text"}>*/}
                    {/*  School Students*/}
                    {/*</p>*/}
                  </div>

                  <div className={"school-table"}>
                    <Table
                      dataSource={
                        this.state.studentList ? this.state.studentList : null
                      }
                      columns={this.columns}
                      onChange={this.paginate}
                      pagination={{
                        defaultCurrent: this.state.defaultCurrent,
                        total: this.state.totalPages,
                        showSizeChanger: true,
                        defaultPageSize: 20,
                        pageSizeOptions: ["20", "50", "100"],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    studentListingCount:
      state &&
      state.studentListingReducer &&
      state.studentListingReducer.studentListing &&
      state.studentListingReducer.studentListing.totalRecords,
    schoolTypeListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing &&
      state.schoolReducer.schoolTypeListing.items,
    schoolListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.schoolListing &&
      state.schoolReducer.schoolListing &&
      state.schoolReducer.schoolListing.items,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    studentListing:
      state &&
      state.studentListingReducer &&
      state.studentListingReducer.studentListing,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    statusesListing:
      state &&
      state.schoolReducer &&
      state.schoolReducer.statusesListing &&
      state.schoolReducer.statusesListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getStudentListing: bindActionCreators(getStudentListing, dispatch),
  getSchoolTypeListing: bindActionCreators(getSchoolTypeListing, dispatch),
  getSchoolListing: bindActionCreators(getSchoolListing, dispatch),
  getStatuses: bindActionCreators(getStatuses, dispatch),
});

StudentList = connect(mapStateToProps, mapDispatchToProps)(StudentList);

export default withRouter(StudentList);
