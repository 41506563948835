import { api } from "../../services/api";
import * as types from "../Constants";

export const getBranchPipeline = () => {
  const options = {
    url: `v1/deal/pipeline/GetByBranchId`,
  };

  options.types = [
    types.GET_BRANCH_PIPELINE_SUCCESS,
    types.GET_BRANCH_PIPELINE_FAILURE,
  ];

  return api.get(options);
};

export const addSalesPipeline = (data) => {
  const options = {
    url: `v1/deal/pipeline`,
  };

  options.types = [
    types.ADD_SALES_PIPELINE_SUCCESS,
    types.ADD_SALES_PIPELINE_FAILURE,
  ];

  return api.post(options, data);
};

export const getSalesPipeline = (id) => {
  const options = {
    url: `v1/deal/pipeline/GetByBranchId`,
  };

  options.types = [
    types.GET_SALES_PIPELINE_SUCCESS,
    types.GET_SALES_PIPELINE_FAILURE,
  ];

  return api.get(options);
};

// export const addGroup = (data) => {
//   const options = {
//     url: "v1/group",
//   };

//   options.types = [types.ADD_GROUP_SUCCESS, types.ADD_GROUP_FAILURE];

//   return api.post(options, data);
// };

// export const updGroup = (data) => {
//   const options = {
//     url: "v1/group",
//   };

//   options.types = [types.UPDATE_GROUP_SUCCESS, types.UPDATE_GROUP_FAILURE];

//   return api.put(options, data);
// };

// export const deleteGroup = (data) => {
//   const options = {
//     url: "v1/group",
//   };

//   options.types = [types.DELETE_GROUP_SUCCESS, types.DELETE_GROUP_FAILURE];

//   return api.delete(options, data);
// };
