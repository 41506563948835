import React, { Fragment } from "react";
import CreateEmailForm from "./CreateEmailForm";

const createEmail = ({
  onGetLetterTemplates,
  LetterTemplatesRes,

  onSendEmailLink,

  onAddEmployerEmail,

  userDataEmp,

  onAddAnyTamplate,
  addAnyTemRes,

  onUploadAvatar,
  imageUploadSuccess,

  onGetEmployerHistory,

  setHandleKey,
  onGetSignature,
  signatureRes,

  onGetDocuments,
  documentRes,
  onGetEmployerDocument,
  onGetPdf,
  onAddEmailDocument,
  employerDocumentRes,
  onGetDocumentDownload,
  emailContentData,
}) => {
  // useEffect(() => {
  //   setLoading(true);
  //
  //   onGetEmployerCheckList().then((res) => {
  //     setChecklistItem(res.payload.items);
  //     setLoading(false);
  //   });
  // }, [onGetEmployerCheckList]);

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  return (
    <Fragment>
      <CreateEmailForm
        onGetLetterTemplates={onGetLetterTemplates}
        LetterTemplatesRes={LetterTemplatesRes}
        onSendEmailLink={onSendEmailLink}
        onAddEmployerEmail={onAddEmployerEmail}
        userDataEmp={userDataEmp}
        onAddAnyTamplate={onAddAnyTamplate}
        addAnyTemRes={addAnyTemRes}
        onUploadAvatar={onUploadAvatar}
        imageUploadSuccess={imageUploadSuccess}
        onGetEmployerHistory={onGetEmployerHistory}
        setHandleKey={setHandleKey}
        onGetSignature={onGetSignature}
        signatureRes={signatureRes}
        onGetDocuments={onGetDocuments}
        documentRes={documentRes}
        onGetEmployerDocument={onGetEmployerDocument}
        onGetPdf={onGetPdf}
        onAddEmailDocument={onAddEmailDocument}
        employerDocumentRes={employerDocumentRes}
        onGetDocumentDownload={onGetDocumentDownload}
        emailContentData={emailContentData}
      />
    </Fragment>
  );
};

export default createEmail;
