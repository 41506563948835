import { api } from "../../services/api";
import * as types from "../Constants";

export const saleHistoryReport = (data) => {
  const options = {
    url: "v1/report/SaleHistory",
  };

  options.types = [
    types.SALE_HISTORY_REPORT_SUCCESS,
    types.SALE_HISTORY_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const processingPersonReport = (data) => {
  const options = {
    url: "v1/report/ProcessingPerson",
  };

  options.types = [
    types.PROCESSING_PERSON_REPORT_SUCCESS,
    types.PROCESSING_PERSON_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const clientSourceReport = (data) => {
  const options = {
    url: "v1/report/ClientSource",
  };

  options.types = [
    types.CLIENT_SOURCE_REPORT_SUCCESS,
    types.CLIENT_SOURCE_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const tasksReport = (data) => {
  const options = {
    url: "v1/report/Task",
  };

  options.types = [types.TASK_REPORT_SUCCESS, types.TASK_REPORT_FAILURE];

  return api.post(options, data);
};

export const birthdayReport = (data) => {
  const options = {
    url: "v1/report/BirthDay",
  };

  options.types = [
    types.BIRTHDAY_REPORT_SUCCESS,
    types.BIRTHDAY_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const fileNotesReport = (data) => {
  const options = {
    url: "v1/report/FileNote",
  };

  options.types = [
    types.FILE_NOTES_REPORT_SUCCESS,
    types.FILE_NOTES_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const activeCasesReport = (data) => {
  const options = {
    url: "v1/report/ActiveCases",
  };

  options.types = [
    types.ACTIVE_CASES_REPORT_SUCCESS,
    types.ACTIVE_CASES_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const visaExpiringReport = (data) => {
  const options = {
    url: "v1/report/CurrentVisaExpiry",
  };

  options.types = [
    types.VISA_EXPIRY_REPORT_SUCCESS,
    types.VISA_EXPIRY_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const clientEmployersReport = (data) => {
  const options = {
    url: "v1/report/ClientEmployer",
  };

  options.types = [
    types.CLIENT_EMPLOYER_REPORT_SUCCESS,
    types.CLIENT_EMPLOYER_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const docChecklistReport = (data) => {
  const options = {
    url: "v1/report/DocumentCheckList",
  };

  options.types = [
    types.DOC_CHECKLIST_REPORT_SUCCESS,
    types.DOC_CHECKLIST_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const serviceMngmntReport = (data) => {
  const options = {
    url: "v1/report/ClientContractAll",
  };

  options.types = [
    types.SERVICE_MNGMNT_REPORT_SUCCESS,
    types.SERVICE_MNGMNT_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const employerMedicalExpiry = (data) => {
  const options = {
    url: "v1/report/EMedicalExpiry",
  };

  options.types = [
    types.EMPLOYER_MEDICAL_EXPIRY_SUCCESS,
    types.EMPLOYER_MEDICAL_EXPIRY_FAILURE,
  ];

  return api.post(options, data);
};

export const policeCertificateExpiry = (data) => {
  const options = {
    url: "v1/report/EPoliceCertificateExpiry",
  };

  options.types = [
    types.POLICE_CERTIFICATE_EXPIRY_SUCCESS,
    types.POLICE_CERTIFICATE_EXPIRY_FAILURE,
  ];

  return api.post(options, data);
};

export const employerPassportExpiry = (data) => {
  const options = {
    url: "v1/report/EPassportExpiry",
  };

  options.types = [
    types.EMPLOYER_PASSPORT_EXPIRY_SUCCESS,
    types.EMPLOYER_PASSPORT_EXPIRY_FAILURE,
  ];

  return api.post(options, data);
};

export const travelConditionExpiry = (data) => {
  const options = {
    url: "v1/report/ETravelConditions",
  };

  options.types = [
    types.TRAVEL_CONDITION_EXPIRY_SUCCESS,
    types.TRAVEL_CONDITION_EXPIRY_FAILURE,
  ];

  return api.post(options, data);
};

export const pSaleHistoryReport = (data) => {
  const options = {
    url: "v1/report/PSaleHistory",
  };

  options.types = [
    types.P_SALE_HISTORY_REPORT_SUCCESS,
    types.P_SALE_HISTORY_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const pClientSourceReport = (data) => {
  const options = {
    url: "v1/report/PClientSource",
  };

  options.types = [
    types.P_CLIENT_SOURCE_REPORT_SUCCESS,
    types.P_CLIENT_SOURCE_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const pProcessingPersonReport = (data) => {
  const options = {
    url: "v1/report/PClientProcessingPerson",
  };

  options.types = [
    types.P_PROCESSING_PERSON_REPORT_SUCCESS,
    types.P_PROCESSING_PERSON_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const pFileNotesReport = (data) => {
  const options = {
    url: "v1/report/PClientProcessingPerson",
  };

  options.types = [
    types.P_FILE_NOTES_REPORT_SUCCESS,
    types.P_FILE_NOTES_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const potentialClientStatusReport = (data) => {
  const options = {
    url: "v1/report/PClientStatus",
  };

  options.types = [
    types.POTENTIAL_CLIENT_STATUS_REPORT_SUCCESS,
    types.POTENTIAL_CLIENT_STATUS_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const pcFileNotes = (data) => {
  const options = {
    url: "v1/report/PFileNote",
  };

  options.types = [
    types.POTENTIAL_CLIENT_FILE_NOTES_SUCCESS,
    types.POTENTIAL_CLIENT_FILE_NOTES_FAILURE,
  ];

  return api.post(options, data);
};

export const getDestination = (data) => {
  const options = {
    url: "v1/config/GetAllVisaDestination",
  };

  options.types = [types.DESTINATION_SUCCESS, types.DESTINATION_FAILURE];

  return api.get(options);
};

export const visaReport = (data) => {
  const options = {
    url: "v1/report/Visa",
  };

  options.types = [types.VISA_REPORT_SUCCESS, types.VISA_REPORT_FAILURE];

  return api.post(options, data);
};

export const timeTrackingReport = (data) => {
  const options = {
    url: "v1/report/TimeTracking",
  };

  options.types = [
    types.TIME_TRACKING_REPORT_SUCCESS,
    types.TIME_TRACKING_REPORT_FAILURE,
  ];

  return api.post(options, data);
};

export const getFileNotesReport = (data) => {
  const options = {
    url: "v1/client/filenote/FileNotespdfAll",
  };

  options.types = [
    types.GET_FILE_NOTES_REPORT_SUCCESS,
    types.GET_FILE_NOTES_REPORT_FAILURE,
  ];

  return api.post(options, data);
};
