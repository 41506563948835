import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const userLevelSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_SIGNATURE_SUCCESS:
      return { ...state, signatureRes: action.payload };
    case types.GET_SIGNATURE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_SIGNATURE_SUCCESS:
      return { ...state, updateSignatureRes: action.payload };
    case types.PUT_SIGNATURE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
