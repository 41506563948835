import React from "react";
import Select from "react-select";
import "./SchoolManagementStyles.css";
import { Row, Col, AutoComplete, Spin } from "antd";
import AddInvoice from "./AddInvoice";
import { bindActionCreators } from "redux";
import {
  getSearchSchoolListing,
  setSchoolInvoicesDataNull,
  setSelectedSchoolEmail,
} from "../store/Actions";
import { connect } from "react-redux";
import { Images } from "../Themes";
import moment from "moment";

class NewInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addInvoice: "",
      parentInvoiceId: 0,
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      searchSchoolListing: [],
      selectedSchoolId: 0,
      selectedSchoolName: 0,
      showNewInvoiceSection: false,
      searchText: "",
    };
  }

  componentDidMount() {
    this.setState({ selectedSchoolId: this.props.selectedSchoolId });

    if (this.props && this.props.addInvoice === "add") {
      this.setState({
        showNewInvoiceSection: true,
      });
    }
    if (this.props && this.props.newInvoice === "newInvoice") {
      this.setState({
        showNewInvoiceSection: false,
      });
    } else {
      this.setState({
        showNewInvoiceSection: true,
      });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    this.setState({ selectedSchoolId: nextProps.selectedSchoolId });

    if (nextProps && nextProps.addInvoice === "add") {
      this.setState({
        showNewInvoiceSection: true,
      });
    }
    if (nextProps && nextProps.newInvoice === "newInvoice") {
      this.setState({
        showNewInvoiceSection: false,
      });
    } else {
      this.setState({
        showNewInvoiceSection: true,
      });
    }
    if (nextProps.addInvoice === "refresh") {
      this.setState({ searchText: "", searchSchoolListing: [] }, () => {
        this.props.getChildInfo(
          this.state.parentInvoiceId,
          this.state.childrenTotalAmount,
          this.state.parentTotalAmount,
          "add"
        );
      });
    }
  }

  onSearch = (searchText) => {
    let _searchText = searchText;
    this.setState({ searchText: _searchText });

    if (_searchText.length > 2) {
      if (_searchText.trim().length === 0) {
        _searchText = "%20";
      }
      this.setState({ loading: true });
      this.props.getSearchSchoolListing(_searchText).then(() => {
        let _searchSchoolListing = this.props.searchSchoolListing;
        let searchedResult = this.searchResult(_searchSchoolListing);
        if (_searchText.length < 2) {
          searchedResult = [];
        }

        this.setState({ searchSchoolListing: searchedResult }, () => {
          this.setState({ loading: false });
        });
      });
    } else {
      this.setState({ searchSchoolListing: [] });
    }
  };

  setSchoolInfo = (id, name) => {
    this.setState({ selectedSchoolId: id, selectedSchoolName: name }, () => {
      this.addInvoice();
      this.setState({ showNewInvoiceSection: true });
    });
  };

  searchResult = (searchedResult) => {
    return searchedResult.map((_, idx) => {
      let category;
      category = `${_.name}`;
      return {
        value: category,
        label: (
          <div
            className="search-client-card-cont"
            onClick={() => {
              this.props.setSelectedSchoolEmail(_.email, _.id);
              this.setSchoolInfo(_.id, _.name);
              this.setState({ searchText: _.name });
            }}
          >
            <div>
              <span className="label-search-schools">{category}</span>
              <div style={{ display: "flex" }}>
                <div>
                  <span class="date-text">School Type:</span>
                </div>
                <div>
                  <span class="date-text">&nbsp;{_.schoolTypeName}</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span className="date-text">City:</span>
                </div>
                <div>
                  <span className="date-text">&nbsp;{_.city}</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <span className="date-text">Address:</span>
                </div>
                <div>
                  <span className="date-text">&nbsp;{_.address}</span>
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });
  };
  componentDidUpdate() {}

  getSearchSchoolSection = () => {
    return (
      <div className="page-container">
        <h4
          style={{
            marginLeft: "33px",
            marginTop: "26px",
            marginBottom: "20px",
          }}
        >
          {"CREATE INVOICE"}
        </h4>
        <div
          className="sm-newinvoice-container"
          style={{
            marginLeft: "21px",
            marginRight: "21px",
            paddingTop: "19px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <h5 style={{ marginLeft: "11px" }}>{"Search Schools"}</h5>
          <div className="ca-gray-cont">
            <Row>
              <Col xs={23} style={{ marginLeft: "21px" }}>
                <AutoComplete
                  // onSelect={this.onSelect}
                  value={this.state.searchText}
                  virtual={false}
                  options={this.state.searchSchoolListing}
                  onSearch={this.onSearch}
                  placeholder="Type here..."
                />
              </Col>
            </Row>
          </div>
        </div>
        {this.state.showNewInvoiceSection === true ? (
          <h4
            style={{
              marginLeft: "33px",
              marginTop: "17px",
              marginBottom: "20px",
            }}
          >
            {"NEW INVOICE"}
          </h4>
        ) : null}
      </div>
    );
  };

  // getChildInfo = (
  //     parentInvoiceId,
  //     childrenTotalAmount,
  //     parentTotalAmount,
  //     addInvoice
  // ) => {
  //
  //     this.setState({
  //         parentInvoiceId: parentInvoiceId,
  //         childrenTotalAmount: childrenTotalAmount,
  //         parentTotalAmount: parentTotalAmount,
  //         addInvoice: addInvoice
  //     });
  // };

  addInvoice = () => {
    this.props.setInvoicesDataNull();
    this.setState({ parentInvoiceId: 0 }, () => {
      this.props.getChildInfo(
        this.state.parentInvoiceId,
        this.state.childrenTotalAmount,
        this.state.parentTotalAmount,
        "add"
      );
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={24}>
            <Spin size="large" spinning={this.state.loading}>
              <div style={{ display: "block", height: "100vh" }}>
                {this.props.newInvoice === "newInvoice"
                  ? this.getSearchSchoolSection()
                  : null}
                {this.state.showNewInvoiceSection === true &&
                this.props.addInvoice === "add" ? (
                  <div className="ts-container-school-invoices">
                    <AddInvoice
                      schoolName={this.props.schoolName}
                      parentInvoiceId={this.props.parentInvoiceId}
                      childrenTotalAmount={this.props.childrenTotalAmount}
                      parentTotalAmount={this.props.parentTotalAmount}
                      getChildInfo={this.props.getChildInfo}
                      selectedSchoolId={this.state.selectedSchoolId}
                      selectedSchoolName={this.state.selectedSchoolName}
                    />
                  </div>
                ) : null}
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchSchoolListing:
    state &&
    state.schoolReducer &&
    state.schoolReducer.searchSchoolListing &&
    state.schoolReducer.searchSchoolListing.items,
});

const mapDispatchToProps = (dispatch) => ({
  getSearchSchoolListing: bindActionCreators(getSearchSchoolListing, dispatch),
  setInvoicesDataNull: bindActionCreators(setSchoolInvoicesDataNull, dispatch),
  setSelectedSchoolEmail: bindActionCreators(setSelectedSchoolEmail, dispatch),
});

NewInvoice = connect(mapStateToProps, mapDispatchToProps)(NewInvoice);

export default NewInvoice;
