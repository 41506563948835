import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Deals from "../Deals";

import {
  addSalesPipeline,
  getBranchPipeline,
  getMailChimp,
  getPotentialClientInfo,
  getSalesPipeline,
  getTeamMember,
  searchEmployerMain,
  searchMainClient,
  searchPotentialClient,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    brnchPipelineData: state.dealsReducer.brnchPipelineData,
    getSalePipelineRes: state.dealsReducer.getSalePipelineRes,
    teamMembers: state.teamMemberReducer.teamMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBranchPipeline: bindActionCreators(getBranchPipeline, dispatch),
    onAddSalePipeline: bindActionCreators(addSalesPipeline, dispatch),
    onGetSalePipeline: bindActionCreators(getSalesPipeline, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onSearchPotentialClient: bindActionCreators(
      searchPotentialClient,
      dispatch
    ),
    getPotentialClientInfo: bindActionCreators(
      getPotentialClientInfo,
      dispatch
    ),
    onSearchEmployerMain: bindActionCreators(searchEmployerMain, dispatch),
    onGetMailChimp: bindActionCreators(getMailChimp, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
