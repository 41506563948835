import React, { Fragment } from "react";
import { Table, Button, Spin } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { apiRefresh } from "../../services/api";
import { saveAs } from "file-saver";
import moment from "moment";
import { Images } from "../../Themes";
var http = window.location.host == "localhost:3000" ? "http://" : "https://";
var URL = http + window.location.host + "/CheckList/index.htm?param=";
const columns = [
  {
    title: "CLIENT NAME",
    dataIndex: "clientName",
    key: "clientName",
  },
  {
    title: "MEMBER TYPE",
    dataIndex: "memberType",
    key: "memberType",
  },
  {
    title: "CONTRACT SIGNED DATE",
    dataIndex: "contractSignedDate",
    key: "contractSignedDate",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <span>{moment(text).format("DD/MM/YYYY")}</span>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    title: "",
    dataIndex: "",
    key: "",
    render: (text, row, index) => {
      //   if (index == 0) {
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            const aggPdfOpt = {
              url: `v1/client/contract/Contractpdf/${row.id}`,
            };
            aggPdfOpt.types = [
              "AGREEMENT_PDF_SUCCESS",
              "AGREEMENT_PDF_FAILURE",
            ];
            apiRefresh.getFile(aggPdfOpt).then((response) => {
              let blob = new Blob([response], {
                type: "application/octet-stream",
              });
              saveAs(blob, `Service_Aggrement.pdf`);
            });
          }}
        >
          <img style={{ width: 20, height: 20 }} src={Images.download} />
        </div>
      );
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class ServiceMngmntTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
      loading: false,
    };
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          pageSize: value.pageSize,
          pageNumber: value.current,
          processingPerson:
            this.props.requestData.processingIds ||
            "00000000-0000-0000-0000-000000000000",
          signedStartDate:
            this.props.requestData.signedStartDate ||
            "1900-01-01T00:00:00.000Z",
          signedEndDate:
            this.props.requestData.signedEndDate || "1900-01-01T00:00:00.000Z",
        };
        this.setState({ loading: true });
        this.props
          .onBirthdayReport(data)
          .then(() => {
            this.setState(
              {
                defaultCurrent: 1,
                loading: false,
                //   branchId: this.state.branchId,
              },
              () => {
                console.log("state", this.state);
              }
            );
          })
          .catch((er) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  clientEmployerExport = () => {
    let data = {
      processingPerson:
        this.props.requestData.processingIds ||
        "00000000-0000-0000-0000-000000000000",
      signedStartDate:
        this.props.requestData.signedStartDate || "1900-01-01T00:00:00.000Z",
      signedEndDate:
        this.props.requestData.signedEndDate || "1900-01-01T00:00:00.000Z",
    };
    this.setState({ loading: true });
    const visaExpiryOpt = {
      url: `v1/report/ClientContractAllpdf`,
    };
    visaExpiryOpt.types = [
      "SERVICE_AGREEMENT_REPORT_EXPORT_SUCCESS",
      "SERVICE_AGREEMENT_REPORT_EXPORT_FAILURE",
    ];

    apiRefresh
      .postWithFileRes(visaExpiryOpt, data)
      .then((res) => {
        this.setState({ loading: false });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Service_Agreement_Report.xlsx");
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { clientEmployerReportRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
            <Button
              className="button-blue"
              style={{ marginTop: 10 }}
              onClick={this.clientEmployerExport}
              loading={this.state.loading}
            >
              Export
            </Button>
          </div>
          <Spin spinning={this.state.loading}>
            <Table
              columns={columns}
              dataSource={clientEmployerReportRes && clientEmployerReportRes}
              pagination={false}
              className="border-3 table-head"
              bordered
              onChange={this.paginate}
              pagination={{
                defaultCurrent: this.state.defaultCurrent,
                total: this.props.reportsCount,
                defaultPageSize: 20,
              }}
              scroll={{ x: "auto" }}
            />
          </Spin>
        </div>
      </Fragment>
    );
  }
}
export default ServiceMngmntTable;
