import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Theme from "../Components/Theme";

import {
  searchMainClient,
  userLogout,
  searchPotentialClient,
  closeAndOpenSidebar,
  getPotentialClientInfo,
  getPotentialClientUpdateBol,
  getClientProfile,
  getUserDetail,
  removeNotificationToken,
  userActivityLog,
  getTeamMember,
  getUserBranch,
  getPermissonUser,
  searchEmployerMain,
  getBranch,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    searchClientData: state.clientProfileReducer.searchConnectionData,
    branchData: state.branchReducer.branchData,
    mainClientsData: state.clientProfileReducer.clientsData,
    potentialClientsData: state.clientProfileReducer.potentialClientsData,
    mainEmpSearchRes: state.clientProfileReducer.mainEmpSearchRes,
    sideBarRes: state.employerManagReducer.sideBarRes,
    userDetail: state.userDetailReducer.userDetail,
    loader: state.clientProfileReducer && state.clientProfileReducer.loader,
    userActivityRes: state.userReducer.userActivityRes,
    teamMembers: state.teamMemberReducer.teamMembers,
    userBranchData: state.branchReducer.userBranchData,
    permProcessingPerson: state.clientSummaryReducer.permProcessingPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPotentialClientInfo: bindActionCreators(
      getPotentialClientInfo,
      dispatch
    ),
    getPotentialClientUpdateBol: bindActionCreators(
      getPotentialClientUpdateBol,
      dispatch
    ),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onSearchPotentialClient: bindActionCreators(
      searchPotentialClient,
      dispatch
    ),
    onUserLogout: bindActionCreators(userLogout, dispatch),
    onGetBranch: bindActionCreators(getBranch, dispatch),
    onCloseAndOpenSidebar: bindActionCreators(closeAndOpenSidebar, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetUserDetail: bindActionCreators(getUserDetail, dispatch),
    onNotificationRemove: bindActionCreators(removeNotificationToken, dispatch),
    onGetUserActivity: bindActionCreators(userActivityLog, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
    onSearchEmployerMain: bindActionCreators(searchEmployerMain, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
