import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  InputNumber,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Option } = Select;
const { TextArea } = Input;

class ListingStage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      amount: 0,
      taxInclusive: false,
      taxValueDropdown: 0,
      taxName: "",
      gst: 0,
      id: 0,
    };
  }

  componentDidMount() {
    let _data = this.props && this.props.data;

    this.setState({
      description: _data.description,
      amount: _data.amount,
      taxInclusive: _data.taxInclusive,
      taxName: _data.taxName,
      gst: _data.gst,
      id: _data.id,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState(
      { taxInclusive: e.target.checked, taxValueDropdown: 0 },
      () => {
        this.setState(
          {
            gst:
              !this.state.taxInclusive && this.state.taxValueDropdown !== 0
                ? this.state.taxValueDropdown
                : 0,
            taxInclusive: e.target.checked,
            taxName: this.state.taxInclusive ? "" : "",
          },
          () => {
            this.props &&
              this.props.getStageTaxInclusiveUpdatedValue(
                this.state.id,
                this.state.taxInclusive,
                this.state.taxName,
                this.state.gst
              );
          }
        );
      }
    );
  };

  onChangeTaxDropdownValue = (data) => {
    let _taxDropdownValue = data === "" ? null : JSON.parse(data);
    this.setState(
      {
        taxValueDropdown: data === "" ? 0 : _taxDropdownValue.percent,
      },
      () => {
        this.setState(
          {
            gst: this.state.taxInclusive
              ? 0
              : data === ""
              ? 0
              : _taxDropdownValue.percent,
            taxName: data === "" ? " " : _taxDropdownValue.name,
          },
          () => {
            this.props &&
              this.props.getStageTaxNameUpdatedValue(
                this.state.id,
                this.state.taxName,
                this.state.gst
              );
          }
        );
      }
    );
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.setState({ gst: 0 }, () => {
        this.props &&
          this.props.getStageGSTUpdatedValue(this.state.id, this.state.gst);
      });
    } else {
      this.setState({ gst: value }, () => {
        this.props &&
          this.props.getStageGSTUpdatedValue(this.state.id, this.state.gst);
      });
    }
  };

  handleChangeAmount = (value) => {
    if (value === null) {
      this.setState({ amount: 0 }, () => {
        this.props &&
          this.props.getStageAmountUpdatedValue(
            this.state.id,
            this.state.amount
          );
      });
    } else {
      this.setState({ amount: value }, () => {
        this.props &&
          this.props.getStageAmountUpdatedValue(
            this.state.id,
            this.state.amount
          );
      });
    }
  };

  handleEditStageBasic = () => {
    this.props && this.props.onEditTemplate();
  };

  handleEditStageTax = () => {
    this.props && this.props.onEditTemplate();
  };

  handleChangeStageDescription = (e) => {
    this.setState({ description: e.target.value }, () => {
      this.props &&
        this.props.getStageDescriptionUpdatedValue(
          this.state.id,
          this.state.description
        );
    });
  };

  onBlurDescription = (e) => {
    if (e.target.value === "") {
      this.setState({ description: this.props.data.description }, () => {
        this.props &&
          this.props.getStageDescriptionUpdatedValue(
            this.state.id,
            this.state.description
          );
      });
    }
  };

  render() {
    return (
      <div>
        <Row style={{ marginTop: "19px" }}>
          <Col xs={3} offset={1}>
            <p>{`Stage ${this.props.index + 1}`}</p>
          </Col>
          <Col xs={9}>
            <TextArea
              value={this.state.description}
              placeholder={"Description!"}
              maxLength={2000}
              onBlur={this.onBlurDescription}
              rows={4}
              onChange={this.handleChangeStageDescription}
            />
          </Col>
          <Col xs={4} style={{ marginLeft: "8px" }}>
            <InputNumber
              value={this.state.amount}
              placeholder={"Amount!"}
              onChange={this.handleChangeAmount}
            />
          </Col>
          <Col xs={2} style={{ marginTop: "-12px" }}>
            <a
              className="marginTemplate"
              href="javascript:"
              onClick={this.handleEditStageBasic}
            >
              <EditOutlined />
            </a>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col xs={6} offset={1}>
            <Checkbox
              onChange={this.handleChangeTaxInclusiveCheck}
              checked={this.state.taxInclusive}
            >
              <span
                style={{
                  fontSize: "12px",
                  color: "#556370",
                  fontFamily: "Poppins",
                }}
              >
                Tax inclusive
              </span>
            </Checkbox>
          </Col>
          <Col xs={2}>
            <a
              href="javascript:"
              style={{ marginTop: "-2px", marginLeft: "-45px" }}
              onClick={this.handleEditStageTax}
            >
              <EditOutlined />
            </a>
          </Col>
        </Row>
        {!this.state.taxInclusive ? (
          <div style={{ marginTop: "28px" }}>
            <Row>
              <Col xs={21} offset={1}>
                <Select
                  value={this.state.taxName}
                  virtual={false}
                  placeholder="Please Select Tax!"
                  size="medium"
                  onChange={(e) => this.onChangeTaxDropdownValue(e)}
                >
                  <Option value={""}>{"Please select tax!"}</Option>
                  {this.props &&
                    this.props.taxListing &&
                    this.props.taxListing.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return (
                        <Option value={JSON.stringify(data)} key={data.id}>
                          {data.name}-{data.percent}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: "7px" }}>
              <Col xs={23} offset={1}>
                <InputNumber
                  value={this.state.gst}
                  size="medium"
                  onChange={(e) => this.handleChangeTaxPercentage(e)}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ display: "none" }}>
            <Row>
              <Col xs={21} offset={1}>
                <Select
                  value={this.state.taxName}
                  virtual={false}
                  placeholder="Please Select Tax!"
                  size="medium"
                  onChange={(e) => this.onChangeTaxDropdownValue(e)}
                >
                  <Option value={""}>{"Please select tax!"}</Option>
                  {this.props &&
                    this.props.taxListing &&
                    this.props.taxListing.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return (
                        <Option value={JSON.stringify(data)} key={data.id}>
                          {data.name}-{data.percent}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col xs={23} offset={1}>
                <InputNumber
                  value={this.state.gst}
                  size="medium"
                  onChange={(e) => this.handleChangeTaxPercentage(e)}
                />
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col xs={22} className={"border-add-template-stages"}></Col>
        </Row>
      </div>
    );
  }
}

export default ListingStage;
