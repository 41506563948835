import React, { Fragment, useState, useEffect, useForm } from "react";
import { Select, message, DatePicker, Form, Input, Button } from "antd";
import moment from "moment";
import { Images } from "../../Themes";
import AddAutoReminder from "../Reminder/AddAutoReminder";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const ScheduleCommission = ({
  onAddCommissionReminder,
  programData,
  onGetAdmissionProgram,
  commissionRemindersListing,
  getCommissionRemindersListing,
  toFee,
  onRemoveCommissionReminder,
}) => {
  const [programStatus, setProgramStatus] = useState("");
  const [admissionStatusDate, setAdmissionStatusDate] = useState("");
  const [loadRemoveCommission, setLoadCommission] = useState(false);
  //   console.log("show visa  status data ", visaStatusData);

  const [form] = Form.useForm();

  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    let commission = "";
    if (programData.fee > 0 && programData.percentage > 0) {
      commission = (programData.fee * programData.percentage) / 100;
    }
    form.setFieldsValue({
      totalFee: programData.fee > 0 ? programData.fee : "",
      commission: programData.percentage !== 0 ? programData.percentage : "",
      tatalCommission: commission,
    });
  }, [form, programData]);

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setProgramStatus(value);
  };

  const onChangeDate = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
    setAdmissionStatusDate(value);
  };

  const onRemove = (id) => {
    setLoadCommission(true);
    let userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      id: id,
      delete: true,
    };
    onRemoveCommissionReminder(data)
      .then((res) => {
        message.success("Updated!");
        setLoadCommission(false);
        getCommissionRemindersListing(false, clientprofileid);
      })
      .catch((err) => {
        message.error("Failed!");
        setLoadCommission(false);
      });
  };

  const onFinish = (values) => {
    let userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let profileData = JSON.parse(sessionStorage.getItem("profileData"));
    let data = {
      reminderTypeId: 1,
      subjectId: programData.schoolId,
      relatedSubjectId: clientprofileid,
      amount: values.reminderAmount ? parseInt(values.reminderAmount) : 0,
      isCompleted: false,
      reminderDate: values.reminderDate || "1900-01-01T00:00:00+00:00",
      commissionPercentage:
        values.commission !== "" ? parseInt(values.commission) : 0,
      totalFee: values.totalFee !== "" ? parseInt(values.totalFee) : 0,
      totalCommission:
        values.tatalCommission !== "" ? parseInt(values.tatalCommission) : 0,
      description: values.description || "",
      relatedSubjectName: profileData ? profileData.fullName : "",
    };

    console.log(data);
    onAddCommissionReminder(data)
      .then((res) => {
        message.success("Commission scheduled successfuly");
        if (values.reminderDate) {
          let tcData = {
            title: "School Commission Reminder",
            reminderDate: values.reminderDate
              ? moment(values.reminderDate).format("YYYY-MM-DDT00:00:00+00:00")
              : "1900-01-01T00:00:00+00:00",
            profileId: clientprofileid,
            valueA: values.reminderAmount ? values.reminderAmount : "",
            typeId: 15,
          };
          AddAutoReminder(tcData);
        }

        getCommissionRemindersListing(false, clientprofileid);
      })
      .catch((err) => {
        message.error("Failed to set commission reminder");
      });
  };

  return (
    <Fragment>
      <div className="cv-gray-cont" style={{ paddingBottom: 0 }}>
        <Form form={form} onFinish={onFinish}>
          <div
            className="ad-row-cont"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <div style={{ width: "49%" }}>
              <p>Total Fee</p>
              <div class="profile-input-border">
                <Form.Item name="totalFee">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "49%" }}>
              <p>Commission%</p>
              <div class="profile-input-border">
                <Form.Item name="commission">
                  <input className="profile-input" placeholder="" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div
            className="ad-row-cont"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <div style={{ width: "49%" }}>
              <p>Total Commission</p>
              <div class="profile-input-border">
                <Form.Item name="tatalCommission">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "49%" }}>
              <p>Reminder Date</p>
              <div>
                <Form.Item name="reminderDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div
            className="ad-row-cont"
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <div style={{ width: "49%" }}>
              <p>Reminder Amount</p>
              <div class="profile-input-border">
                <Form.Item name="reminderAmount">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
            <div style={{ width: "49%" }}>
              <p>Description</p>
              <div class="profile-input-border">
                <Form.Item name="description">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="ad-btn-row">
            <Form.Item>
              <Button
                className="button-blue"
                style={{ marginLeft: 10 }}
                htmlType="submit"
              >
                <span style={{ color: "#FFFFFF" }}>SAVE</span>
              </Button>
            </Form.Item>
          </div>
        </Form>
        <div style={{ paddingTop: 10 }}>
          <span className="ad-pp-heading-text">Payment Plan</span>
        </div>

        <div style={{ padding: 15, paddingTop: 0 }}>
          <table
            className="ca-invoice-table-cont"
            style={{ borderSpacing: 0, marginTop: 10 }}
          >
            <tbody>
              <tr style={{ backgroundColor: "#F8F9FB" }}>
                <th
                  className="ca-table-heading"
                  style={{
                    borderTopLeftRadius: 5,
                    textAlign: "left",
                    width: "65%",
                    paddingLeft: 10,
                  }}
                >
                  Reminder Date
                </th>
                <th className="ca-table-heading">Amount</th>
                <th
                  className="ca-table-heading"
                  style={{
                    borderTopRightRadius: 5,
                    textAlign: "left",
                    paddingLeft: 15,
                  }}
                >
                  Action
                </th>
              </tr>
              {commissionRemindersListing &&
                commissionRemindersListing.items &&
                commissionRemindersListing.items.map(
                  (commissionReminder, index) => {
                    return (
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <td
                          className="ad-table-content"
                          style={{ paddingLeft: 10 }}
                        >
                          {moment(commissionReminder.reminderDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td
                          className="ad-table-content"
                          style={{
                            color: "#54636D",
                            fontWeight: "600",
                          }}
                        >
                          {commissionReminder.amount}
                        </td>
                        <td className="ad-table-content">
                          <div style={{ display: "flex" }}>
                            <div
                              class="remove-cont"
                              style={{ borderRadius: 3, cursor: "pointer" }}
                              onClick={() => onRemove(commissionReminder.id)}
                            >
                              <span
                                class="remove-text"
                                style={{ marginLeft: 5 }}
                              >
                                REMOVE
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default ScheduleCommission;
