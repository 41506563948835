import React from "react";
import "./TimeTrackingStyles.css";
import { Images } from "../Themes";
import { Table, Modal, Input, Spin, message } from "antd";
import { apiRefresh } from "../services/api";

const headOption = [
  { tabName: "WORK TYPE", linkName: "/work-type" },
  { tabName: "VISA TYPE PRICE", linkName: "/visa-type-price" },
  { tabName: "SERVICE TYPE", linkName: "/service-type" },
];

class WorkType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workTypePopupVisible: false,
      workTypeName: "",
      loading: true,
      updateData: null,
      columns: [
        {
          width: "150px",
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          width: "50px",
          title: "Action",
          dataIndex: "",
          key: "",
          render: (text, row, index) => {
            return (
              <div style={{ display: "flex" }}>
                <img
                  src={Images.editBorderBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      updateData: row,
                      workTypeName: row.name,
                      workTypePopupVisible: true,
                    })
                  }
                />
                <img
                  src={Images.deleteBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ updateData: row, remWorkTypeVisible: true })
                  }
                />
              </div>
            );
          },
        },
      ],
    };
  }
  k;
  componentDidMount() {
    this.getWorkType();
  }

  getWorkType = () => {
    this.props
      .onGetVisaWorkType()
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  openWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: true,
    });
  };

  closeWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: false,
    });
  };

  onAddWorkType = () => {
    if(this.state.workTypeName == "")
     {
      message.error("Work Type Name Can't be Empty!");
       return;
     }
    let data = {
      name: this.state.workTypeName,
    };
    if (this.state.updateData) {
      data.id = this.state.updateData.id;
      const addWorkTypeOpt = {
        url: `v1/worktype`,
      };
      addWorkTypeOpt.types = [
        "UPD_VISA_WORK_TYPE_SUCCESS",
        "UPD_VISA_WORK_TYPE_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .put(addWorkTypeOpt, data)
        .then((res) => {
          message.success("Work Type updated successfuly!");
          this.setState({
            workTypePopupVisible: false,
            loading: false,
            updateData: null,
            name: "",
          });
          this.getWorkType();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    } else {
      const addWorkTypeOpt = {
        url: `v1/worktype`,
      };
      addWorkTypeOpt.types = [
        "ADD_VISA_WORK_TYPE_SUCCESS",
        "ADD_VISA_WORK_TYPE_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .post(addWorkTypeOpt, data)
        .then((res) => {
          message.success("Work Type added successfuly!");
          this.setState({
            workTypePopupVisible: false,
            loading: false,
            updateData: null,
            name: "",
          });
          this.getWorkType();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    }
  };

  onRemoveWorkType = () => {
    let data = {
      id: this.state.updateData && this.state.updateData.id,
      delete: true,
    };
    const remWorkTypeOpt = {
      url: `v1/worktype`,
    };
    remWorkTypeOpt.types = [
      "REM_VISA_WORK_TYPE_SUCCESS",
      "REM_VISA_WORK_TYPE_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .delete(remWorkTypeOpt, data)
      .then((res) => {
        this.setState({
          remWorkTypeVisible: false,
          updateData: null,
          loading: false,
        });
        this.getWorkType();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed!");
      });
  };

  render() {
    const { visaWorkTypeRes } = this.props;
    const { workTypeName, loading, columns } = this.state;
    return (
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {/* <PotentialHeaderTabs data={headOption} activeTab="WORK TYPE" /> */}

              <div className="report-container">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 20,
                      marginRight: 20,
                    }}
                  >
                    <div className="pciq-top-div" style={{ marginTop: 0 }}>
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D" }}
                      >
                        VISA WORK TYPE
                      </span>
                    </div>
                    <div onClick={this.openWorkTypeModal}>
                      <img
                        src={Images.plusIcon}
                        style={{ width: 20, height: 20 }}
                      />
                    </div>
                  </div>

                  <div className="ca-gray-cont" style={{ paddingLeft: 20 }}>
                    <div
                      className="ca-invoice-table-cont"
                      style={{
                        width: `calc(100% - 20px)`,
                        marginTop: 20,
                      }}
                    >
                      <Table
                        columns={columns}
                        dataSource={visaWorkTypeRes && visaWorkTypeRes.items}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* ======== Edit popup ======== */}

              <Modal
                title="Work Type"
                visible={this.state.workTypePopupVisible}
                effect="fadeInUp"
                onCancel={() => this.closeWorkTypeModal()}
                onOk={this.onAddWorkType}
                okText="Save"
              >
                <Spin spinning={loading}>
                  <div style={{ padding: 20 }}>
                    <div style={{ width: "100%", marginLeft: 20 }}>
                      <div style={{ display: "flex" }}>
                        <p style={{ width: "30%" }}>Name</p>
                        <div
                          class="profile-input-border"
                          style={{ width: "65%" }}
                        >
                          <Input
                            className="profile-input"
                            placeholder="Name"
                            type="text"
                            onChange={(e) =>
                              this.setState({ workTypeName: e.target.value })
                            }
                            value={workTypeName}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </Modal>
              <Modal
                visible={this.state.remWorkTypeVisible}
                effect="fadeInUp"
                onCancel={() => this.setState({ remWorkTypeVisible: false })}
                onOk={this.onRemoveWorkType}
                header={false}
              >
                <Spin spinning={loading}>
                  <h4>Are you sure you want to delete this ?</h4>
                </Spin>
              </Modal>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default WorkType;
