import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Spin,
  Col,
  Select,
  Row,
} from "antd";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { getFilterListForModulesOtherThanInvoices } from "../../Common/getEmailContentList";
import * as types from "../../store/Constants";
import { apiRefresh } from "../../services/api";
const { Option } = Select;
const ClientAreaEmail = ({
  handleCancel,
  visible,
  profileData,
  onSendEmail,
  emailContent,
  onAddDraftClient,
  onGetEmailContent,
  signatureText,
}) => {
  const [letterString, setLetterString] = useState("");
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState("");
  const [_emailContent, setEmailContent] = useState("");
  const [resultList, setResultList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    var primaryEmail =
      profileData && profileData.emails.find((obj) => obj.emailTypeId === 1);

    var profile = JSON.parse(sessionStorage.getItem("profileData"));

    setEmailContent(
      `Dear ${profile.fullName} <br /> ${emailContent} <br /> ${signatureText}`
    );
    const Opt = {
      url: `v1/emailtemplate`,
    };
    Opt.types = [
      types.GET_EMAIL_CONTENT_SUCCESS,
      types.GET_EMAIL_CONTENT_FAILURE,
    ];
    apiRefresh.get(Opt).then((res) => {
      let resultList = getFilterListForModulesOtherThanInvoices(res);
      setResultList(resultList);
    });
    if (primaryEmail) {
      setToSend(primaryEmail.address);
    }

    form.setFieldsValue({
      name: "",
      to: primaryEmail.address,
      subject: "Welcome to CHECKMYVISA.IO",
    });
  }, [form, profileData]);

  const onFinish = (values) => {
    if (_emailContent !== "" && _emailContent !== undefined) {
      console.log("Received values of form:", values);
      let selectedBranchName = localStorage.getItem("selectedBranchName");
      // const fromEmail = getSpecificUserEmail();
      console.log("Success:", values);
      const formData = new FormData();
      formData.append("Message", _emailContent);
      formData.append("Subject", values.subject);
      formData.append("From", selectedBranchName);
      formData.append("Priority", "1");
      formData.append("FrequencyCode", "EZM");
      formData.append("Status", "Queued");
      formData.append("ModuleId", "2");
      formData.append(`Recipients[0].name`, values.to);
      formData.append(`Recipients[0].type`, "To");
      formData.append("Attachments", []);

      let userId = localStorage.getItem("userId");
      let familyId = sessionStorage.getItem("familyId");
      let clientprofileid = sessionStorage.getItem("clientprofileid");

      const draftData = {
        id: 0,
        emailMessage: _emailContent || "",
        isRead: false,
        attachmentName: "",
        attachmentUrl: "",
        sizeInKB: 0,
        subject: values.subject || "",
        from: selectedBranchName,
        import: false,
        to: values && values.to,
        cc: "",
        bcc: "",
        notClients: false,
        clientReply: false,
        clientReplyMail: 0,
        importMessageId: "",
        draft: false,
        emailType: 0,
        importText: "",
        clientId: clientprofileid,
        importedDate: new Date(),
        autoImport: false,
        isSent: true,
        clientEmailType: 0,
        timeStamp: new Date(),
      };

      onAddDraftClient(draftData).then((res) => {
        formData.append("emailId", res.payload);
        onSendEmail(formData).then(() => {
          message.success(`Successfully Email Send`);
          handleCancel();
        });
      });
    } else {
      message.error("Content is empty!");
    }
  };

  const onChange = (value) => {
    setEmailContent(value);
  };

  return (
    <Fragment>
      <Modal
        title="Title"
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        width="70%"
      >
        <div className="border-box">
          <Form
            onFinish={onFinish}
            form={form}
            className="width-100"
            name="main"
          >
            <Form.Item name="to">
              <div className="width-100 d-flex align-center letter-font">
                <div className="w-91">
                  <Input
                    placeholder="To"
                    value={toSend}
                    onChange={(e) => setToSend(e.target.value)}
                  />
                </div>
              </div>
            </Form.Item>
            <div className="width-100 d-flex align-center letter-font">
              <div className="w-91" style={{ marginTop: 10 }}>
                <Form.Item name="subject">
                  <Input placeholder="Subject" />
                </Form.Item>
              </div>
            </div>
            <Row>
              <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Select
                  placeholder={"Select content type"}
                  virtual={false}
                  onChange={(value) => onChange(value)}
                >
                  <Option value={""}>{"Select"}</Option>
                  {resultList.map((item, index) => (
                    <Option value={item.emailContent}>{item.emailType}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Form.Item className="" name="content">
              <div className="width-100 d-flex align-center letter-font letter-spacing">
                <div className="letter-froala" style={{ marginTop: 10 }}>
                  <Spin size={"large"} spinning={loading}>
                    <FroalaEditorCom
                      setLoading={(value) => setLoading(value)}
                      model={_emailContent}
                      onModelChange={(value) => setEmailContent(value)}
                    />
                  </Spin>
                </div>
              </div>
            </Form.Item>
            <Form.Item className="d-flex" style={{ marginTop: 20 }}>
              <Button
                className="form-btn button-blue"
                type="primary"
                htmlType="submit"
              >
                SEND
              </Button>
            </Form.Item>

            {false && (
              <Form.Item className="d-flex">
                <Button className="form-btn" type="primary" htmlType="submit">
                  Submit
                </Button>
                <div className="margin-btns">
                  <Button className="form-btn" type="primary">
                    PREVIEW
                  </Button>
                </div>
                <div className="margin-btns">
                  <Button
                    onClick={() => handleCancel()}
                    className="form-btn"
                    type="primary"
                  >
                    CANCEL
                  </Button>
                </div>
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};
export default ClientAreaEmail;
