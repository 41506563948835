import React, { Fragment } from "react";
import { Table, Select } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

const { Option } = Select;
const columns = [
  {
    width: "100px",
    title: "Work Name",
    dataIndex: "workName",
    key: "workName",
    // render: (text, row, index) => {
    //   //   if (index == 0) {
    //   return <a>{text}</a>;
    //   //   }
    //   //   return {
    //   //     children: <a>{text}</a>,
    //   //     props: {
    //   //       colSpan: 6,
    //   //     },
    //   //   };
    // },
  },
  {
    width: "100px",
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName",
  },
  {
    width: "100px",
    title: "Visa Type",
    dataIndex: "visaName",
    key: "visaName",
  },
  {
    width: "100px",
    title: "Service Type",
    dataIndex: "serviceName",
    key: "serviceName",
  },
  {
    width: "100px",
    title: "Visa Status",
    dataIndex: "visaStatusName",
    key: "visaStatusName",
  },
  {
    width: "100px",
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    width: "100px",
    title: "Time Spent (Min)",
    dataIndex: "timeSpent",
    key: "timeSpent",
  },
];

// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class TimeTrackingReportTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 20,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
      visaStatuses: [],
      totalHours:
        this.props.totalTime > 0 ? Math.floor(this.props.totalTime / 60) : 0,
      remainingMinutes:
        this.props.totalTime > 0 ? this.props.totalTime % 60 : 0,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.reportsCount != this.props.reportsCount) {
      this.setState({ totalPages: this.props.reportsCount });
    }
    if (prevProps.totalTime != this.props.totalTime) {
      let th = Math.floor(this.props.totalTime / 60);
      let rm = this.props.totalTime % 60;
      this.setState({ totalHours: th, remainingMinutes: rm });
    }
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          userId:
            this.props.requestData.userId ||
            "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
          visaTypeId: this.props.requestData.visaTypeId || 0,
          visaStatusName: this.props.requestData.visaStatusName,
          serviceTypeId: this.props.requestData.serviceTypeId || 0,
        };
        this.props.onTimeTrackingReport(data);
      }
    );
  };

  handleChange = (value) => {
    this.setState({ visaStatuses: value });
    this.props.onChangeStatus(value);
  };

  render() {
    const {
      fileNotesReportRes,
      displayText,
      visaStatusHideData,
      totalTime,
    } = this.props;
    const { totalHours, remainingMinutes } = this.state;
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={fileNotesReportRes && fileNotesReportRes}
            // className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.state.totalPages,
              defaultPageSize: 20,
            }}
            scroll={{ x: "auto" }}
            summary={() => (
              <Table.Summary fixed={"bottom"}>
                <Table.Summary.Row>
                  {/* <Table.Summary.Cell index={0} colSpan={2}>
                    {/* <Switch
                      checkedChildren="Fixed Top"
                      unCheckedChildren="Fixed Top"
                      checked={fixedTop}
                      onChange={() => {
                        setFixedTop(!fixedTop);
                      }}
                    /> 
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={2} colSpan={6}>
                    <span style={{ fontWeight: "500" }}>Total Time (Min)</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <span style={{ fontWeight: "500" }}>{`${totalTime}`}</span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  {/* <Table.Summary.Cell index={0} colSpan={2}>
                    {/* <Switch
                      checkedChildren="Fixed Top"
                      unCheckedChildren="Fixed Top"
                      checked={fixedTop}
                      onChange={() => {
                        setFixedTop(!fixedTop);
                      }}
                    /> 
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={2} colSpan={6}>
                    <span style={{ fontWeight: "500" }}>Total Time</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <span
                      style={{ fontWeight: "500" }}
                    >{`${totalHours} Hours and ${remainingMinutes} Minutes`}</span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </Fragment>
    );
  }
}
export default TimeTrackingReportTable;
