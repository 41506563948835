import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientAnalytics = (data) => {
  const options = {
    url: `v1/dashboardbi/ClientAnalytics`,
  };
  options.types = [
    types.GET_CLIENT_ANALYTICS_SUCCESS,
    types.GET_CLIENT_ANALYTICS_FAILURE,
  ];

  return api.post(options, data);
};
export const getTaskAnalytics = () => {
  const options = {
    url: `v1/dashboardbi/TaskNReminder`,
  };
  options.types = [
    types.GET_TASK_ANALYTICS_SUCCESS,
    types.GET_TASK_ANALYTICS_FAILURE,
  ];

  return api.post(options);
};

export const getAccountsAnalytics = (data) => {
  const options = {
    url: `v1/dashboardbi/AccountAnalytics`,
  };
  options.types = [
    types.GET_ACCOUNTS_ANALYTICS_SUCCESS,
    types.GET_ACCOUNTS_ANALYTICS_FAILURE,
  ];

  return api.post(options, data);
};

export const getVisaAnalytics = (data) => {
  const options = {
    url: `v1/dashboardbi/VisaAnalytic`,
  };
  options.types = [
    types.GET_VISA_ANALYTICS_SUCCESS,
    types.GET_VISA_ANALYTICS_FAILURE,
  ];

  return api.post(options, data);
};

export const getIdleSinceAnalytics = () => {
  const options = {
    url: `v1/dashboardbi/IdleSince`,
  };
  options.types = [
    types.GET_IDLE_SINCE_ANALYTICS_SUCCESS,
    types.GET_IDLE_SINCE_ANALYTICS_FAILURE,
  ];

  return api.post(options);
};

export const getActiveSinceAnalytics = () => {
  const options = {
    url: `v1/dashboardbi/ActiveSince`,
  };
  options.types = [
    types.GET_ACTIVE_SINCE_ANALYTICS_SUCCESS,
    types.GET_ACTIVE_SINCE_ANALYTICS_FAILURE,
  ];

  return api.post(options);
};

export const getVisaStatusAnalytics = (data) => {
  const options = {
    url: `v1/dashboardbi/VisaStatusAnalytic`,
  };
  options.types = [
    types.GET_VISA_STATUS_ANALYTICS_SUCCESS,
    types.GET_VISA_STATUS_ANALYTICS_FAILURE,
  ];

  return api.post(options, data);
};

export const getActiveBalanceAnalytics = () => {
  const options = {
    url: `v1/dashboardbi/ActiveClientBalance`,
  };
  options.types = [
    types.GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_SUCCESS,
    types.GET_ACTIVE_CLIENT_BALANCE_ANALYTICS_FAILURE,
  ];

  return api.post(options);
};

export const getClientsByClientDate = (data) => {
  const options = {
    url: `v1/dashboardbi/ClientAnalyticsDetail`,
  };
  options.types = [
    types.GET_CLIENT_ANALYTICS_DETAILS_SUCCESS,
    types.GET_CLIENT_ANALYTICS_DETAILS_FAILURE,
  ];

  return api.post(options, data);
};

export const getVisaClientsByVisaTypeId = (data) => {
  const options = {
    url: `v1/dashboardbi/VisaAnalyticDetail`,
  };
  options.types = [
    types.GET_VISA_ANALYTICS_CLIENT_DETAILS_SUCCESS,
    types.GET_VISA_ANALYTICS_CLIENT_DETAILS_FAILURE,
  ];

  return api.post(options, data);
};

export const getIncomingAccountsClientInfo = (data) => {
  const options = {
    url: `v1/payment/dashboardbi/incomingoutgoing/All`,
  };
  options.types = [
    types.GET_INCOMING_ACCOUNTS_CLIENT_INFO_SUCCESS,
    types.GET_INCOMING_ACCOUNTS_CLIENT_INFO_FAILURE,
  ];

  return api.post(options, data);
};
export const getOutgoingAccountsClientInfo = (data) => {
  const options = {
    url: `v1/payment/dashboardbi/outgoing/All`,
  };
  options.types = [
    types.GET_OUTGOING_ACCOUNTS_CLIENT_INFO_SUCCESS,
    types.GET_OUTGOING_ACCOUNTS_CLIENT_INFO_FAILURE,
  ];

  return api.post(options, data);
};
