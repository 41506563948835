import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SuperUserSetting from "../SuperUserSetting/SuperUserSetting";

import {
  getUserDetail,
  getCompany,
  addUserDetail,
  updUserDetail,
  getGroups,
  addGroup,
  updGroup,
  deleteGroup,
  getUserPermission,
  addUserPermission,
  updUserPermission,
  getManager,
  updateManager,
  getOwner,
  addUserAddress,
  updUserAddress,
  addUserContact,
  updUserContact,
  changePassword,
  changeUsersPassword,
  disableUser,
  uploadAvatar,
  getBranch,
  assignBranch,
  unassignBranch,
  getCountries,
  getStorage,
  exportStorage,
  getUserBranch,
  updCompany,
  getTeamMember,
  getAllCurrencies,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    userDetail: state.userDetailReducer.userDetail,
    updDetailSuccess: state.userDetailReducer.updDetailSuccess,
    userDetailError: state.userDetailReducer.userDetailError,
    changePasswordSuccess: state.userDetailReducer.changePasswordSuccess,
    storageData: state.userDetailReducer.storageData,
    exportSuccess: state.userDetailReducer.exportSuccess,
    changeUsersPasswordSuccess:
      state.userDetailReducer.changeUsersPasswordSuccess,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    groupsData: state.groupReducer.groupsData,
    userPermissionData: state.userPermissionReducer.userPermissionData,
    managerData: state.managerReducer.managerData,
    ownerData: state.ownerReducer.ownerData,
    teamMembers: state.teamMemberReducer.teamMembersObj,
    updPermissionSuccess: state.userPermissionReducer.updPermissionSuccess,
    companyData: state.companyReducer.companyData,
    updCompanySuccess: state.companyReducer.updCompanySuccess,
    branchData: state.branchReducer.branchData,
    userBranchData: state.branchReducer.userBranchData,
    countriesData: state.visaTypeReducer.countriesData,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserDetail: bindActionCreators(getUserDetail, dispatch),
    onGetCompanyDetail: bindActionCreators(getCompany, dispatch),
    onUpdateCompany: bindActionCreators(updCompany, dispatch),
    onAddUserDetail: bindActionCreators(addUserDetail, dispatch),
    onUpdUserDetail: bindActionCreators(updUserDetail, dispatch),
    onGetGroups: bindActionCreators(getGroups, dispatch),
    onAddGroup: bindActionCreators(addGroup, dispatch),
    onUpdateGroup: bindActionCreators(updGroup, dispatch),
    onDeleteGroup: bindActionCreators(deleteGroup, dispatch),
    onGetUserPermission: bindActionCreators(getUserPermission, dispatch),
    onAddUserPermission: bindActionCreators(addUserPermission, dispatch),
    onUpdateUserPermission: bindActionCreators(updUserPermission, dispatch),
    onGetManager: bindActionCreators(getManager, dispatch),
    onUpdateManager: bindActionCreators(updateManager, dispatch),
    onGetOwner: bindActionCreators(getOwner, dispatch),
    onAddUserAddress: bindActionCreators(addUserAddress, dispatch),
    onUpdUserAddress: bindActionCreators(updUserAddress, dispatch),
    onAddUserContact: bindActionCreators(addUserContact, dispatch),
    onUpdUserContact: bindActionCreators(updUserContact, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onChangePassword: bindActionCreators(changePassword, dispatch),
    onChangeUsersPassword: bindActionCreators(changeUsersPassword, dispatch),
    onDisableUser: bindActionCreators(disableUser, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetBranch: bindActionCreators(getBranch, dispatch),
    onAssignBranch: bindActionCreators(assignBranch, dispatch),
    onUnassignBranch: bindActionCreators(unassignBranch, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onGetStorage: bindActionCreators(getStorage, dispatch),
    onExportData: bindActionCreators(exportStorage, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperUserSetting);
