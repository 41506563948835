import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const clientSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_SUMMARY_SUCCESS:
      return { ...state, clientSummaryList: action.payload };
    case types.GET_CLIENT_SUMMARY_FAILURE:
      return { ...state, clientSummaryError: action.payload };
    case types.GET_CLIENT_SUMMARY_SETTINGS_SUCCESS:
      return { ...state, clientSummarySettings: action.payload };
    case types.GET_CLIENT_SUMMARY_SETTINGS_FAILURE:
      return { ...state, clientSummarySettingsError: action.payload };
    case types.UPDATE_CLIENT_SUMMARY_SETTINGS_SUCCESS:
      return { ...state, clientSummarySettings: action.payload };
    case types.UPDATE_CLIENT_SUMMARY_SETTINGS_FAILURE:
      return { ...state, clientSummarySettingsFailure: action.payload };
    case types.GET_PROCESSING_PERSONS_SUCCESS:
      return { ...state, processingPersons: action.payload };
    case types.GET_PROCESSING_PERSONS_FAILURE:
      return { ...state, processingPersonsError: action.payload };
    case types.GET_PERMISSION_USER_SUCCESS: {
      return { ...state, permProcessingPerson: action.payload };
    }
    case types.GET_PERMISSION_USER_FAILURE:
      return { ...state, permProcessingPersonsError: action.payload };
    case types.GET_COUNTRY_LIST_SUCCESS:
      return { ...state, countryList: action.payload };
    case types.GET_COUNTRY_LIST_FAILURE:
      return { ...state, countryListError: action.payload };
    case types.UPDATE_CLIENT_PRIORITY_SUCCESS:
      return { ...state, updatePriority: action.payload };
    case types.UPDATE_CLIENT_PRIORITY_FAILURE:
      return { ...state, updatePriorityError: action.payload };
    case types.GET_CLIENT_REMINDERS_SUCCESS:
      return { ...state, clientRemindersRes: action.payload };
    case types.GET_CLIENT_REMINDERS_FAILURE:
      return { ...state, clientRemindersError: action.payload };
    default:
      return state;
  }
};
