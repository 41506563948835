import React, { Fragment, useState, useEffect, useForm } from "react";
import {
  Select,
  message,
  Button,
  DatePicker,
  Input,
  Form,
  Checkbox,
} from "antd";
import moment from "moment";

import activityData from "../ClientActivity/ActivityData";
import { PropertySafetyFilled } from "@ant-design/icons";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const AdmissionForm = ({
  schoolTypesData,
  onGetSchools,
  schoolsData,
  onGetSchoolLevel,
  schoolLevelData,
  onAddClientAdmission,
  onGetAdmissionProgram,
  programData,
  visaAppData,
  onUpdAdmissionForm,
}) => {
  const [schoolType, setSchoolType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [school, setSchool] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("");
  const [loadProgram, setLoadProgram] = useState(false);
  var visaOptions = [];
  var schools = [];
  var schoolLevelOpt = [];

  const [form] = Form.useForm();
  if (visaAppData) {
    for (var i = 0; i < visaAppData.items.length > 0; i++) {
      visaOptions.push(
        <Option value={visaAppData.items[i].id}>
          {visaAppData.items[i].branchVisaTypeName}
        </Option>
      );
    }
  }

  //   if (schoolsData) {
  //     for (var i = 0; i < schoolsData.items.length > 0; i++) {
  //       schools.push(
  //         <Option value={schoolsData.items[i].id}>
  //           {schoolsData.items[i].name}
  //         </Option>
  //       );
  //     }
  //   }

  //   if (schoolLevelData) {
  //     for (var i = 0; i < schoolLevelData.items.length > 0; i++) {
  //       schoolLevelOpt.push(
  //         <Option value={schoolLevelData.items[i].id}>
  //           {schoolLevelData.items[i].name}
  //         </Option>
  //       );
  //     }
  //   }

  useEffect(() => {
    form.setFieldsValue({
      program: programData.enrolledProgram,
      fee: programData.fee,
      studentNo: programData.studentNo,
      description: programData.description,
      appliedDate:
        programData.appliedDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.appliedDate),
      ftsDate:
        programData.ftsDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.ftsDate),
      aipDate:
        programData.aipDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.aipDate),
      visaApproveDate:
        programData.visaApprovedDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.visaApprovedDate),
      conditionalOfferDate:
        programData.conditionalOfferDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.conditionalOfferDate),
      declineDate:
        programData.declineDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.declineDate),
      paidDate:
        programData.paidDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.paidDate),
      formalOfferDate:
        programData.formalOfferDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.formalOfferDate),
      courseEffectiveDate:
        programData.courseEffectiveDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.courseEffectiveDate),
      aipSubmitted: programData.aipSubmitted,
      ftsSubmitted: programData.ftsSubmitted,
      startDate:
        programData.startDate === "1900-01-01T00:00:00+00:00"
          ? ""
          : moment(programData.startDate),
      visaId: programData.visaId !== 0 ? programData.visaId : "",
    });
  }, [form, programData]);
  console.log("show program data ", programData);

  const onChangeSchoolType = (value) => {
    console.log(`selected ${value}`);
    setSchoolType(value);
    // onGetSchools(value);
  };

  const onChangeSchool = (value) => {
    console.log(`selected ${value}`);
    setSchool(value);
    // onGetSchoolLevel(value);
  };

  const onChangeLevel = (value) => {
    console.log(`selected ${value}`);
    setSchoolLevel(value);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(date);
  };

  const deleteFromObject = (keyPart, obj) => {
    for (var k in obj) {
      // Loop through the object
      if (~k.indexOf(keyPart)) {
        // If the current key contains the string we're looking for
        delete obj[k]; // Delete obj[key];
      }
    }
    return obj;
  };

  const isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      var result = {};
      var keys = Object.keys(bProps);

      for (var key in aProps) {
        if (!keys.includes(key)) {
          result[key] = aProps[key];
        }
      }
      var keyNames = Object.keys(result);
      // for (var i = 0; i < keyNames.length; i++) {
      //   var name = keyNames[i];
      //   delete aProps.keyNames[i];
      // }

      var myObject = [];

      for (var i = 0; i < keyNames.length; i++) {
        myObject = deleteFromObject(keyNames[i], aProps);
      }

      console.log(aProps, myObject);
      // return false;
    }
    var propNames = "";

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (
        propName !== "createdDate" &&
        propName !== "modifiedDate" &&
        propName !== "createdBy" &&
        propName !== "modifiedBy"
      ) {
        if (a[propName] !== b[propName]) {
          if (propNames === "") {
            propNames += propName;
          } else {
            propNames += ", " + propName;
          }
        }
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return propNames;
  };

  const onFinish = (values) => {
    var userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    setLoadProgram(true);
    let data = {
      id: programData.id,
      clientId: clientprofileid,
      schoolId: programData.schoolId,
      programId: programData.programId,
      programStatus: programData.programStatus,
      description: values.description || "",
      appliedDate: values.appliedDate
        ? moment(values.appliedDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      addmissionDate: values.addmissionDate || "1900-01-01T00:00:00+00:00",
      enrolledProgram: values.program || "",
      studentNo: values.studentNo || "",
      fee: parseInt(values.fee) || 0,
      hideCMS: programData.hideCMS,
      date: programData.date,
      caseStart: programData.caseStart,
      caseEnd: programData.caseEnd,
      courseEffectiveDate: values.courseEffectiveDate
        ? moment(values.courseEffectiveDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      aipDate: values.aipDate || "1900-01-01T00:00:00+00:00",
      aipSubmitted: values.aipSubmitted || false,
      visaApprovedDate: values.visaApproveDate || "1900-01-01T00:00:00+00:00",
      ftsSubmitted: values.ftsSubmitted || false,
      ftsDate: values.ftsDate || "1900-01-01T00:00:00+00:00",
      visaId: parseInt(values.visaId) || 0,
      conditionalOfferDate:
        values.conditionalOfferDate || "1900-01-01T00:00:00+00:00",
      formalOfferDate: values.formalOfferDate || "1900-01-01T00:00:00+00:00",
      paidDate: values.paidDate || "1900-01-01T00:00:00+00:00",
      declineDate: values.declineDate || "1900-01-01T00:00:00+00:00",
      startDate: values.startDate || "1900-01-01T00:00:00+00:00",
      deletedDate: programData.deletedDate || "1900-01-01T00:00:00+00:00",
      commissionId: programData.commissionId,
      schoolTypeId: programData.schoolTypeId,
      programStatusName: programData.programStatusName,
      color: programData.color,
      deletedDate: programData.deletedDate,
    };
    var changedFields = isEquivalent(programData, data);

    onUpdAdmissionForm(data)
      .then((res) => {
        message.success("Admission updated successfuly");
        onGetAdmissionProgram();
        setLoadProgram(false);
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            programData.schoolName +
            " Admission information  " +
            changedFields +
            " updated by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Admission",
          invoiceId: "0",
        };
        activityData(myData);
      })
      .catch((err) => {
        message.error("Failed to update admission");
        setLoadProgram(false);
      });
  };

  return (
    <Fragment>
      <div className="ad-form-container">
        <Form onFinish={onFinish} form={form}>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Program</p>
              <div className="profile-input-border">
                <Form.Item name="program">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Fee</p>
              <div className="profile-input-border">
                <Form.Item name="fee">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Student No</p>
              <div className="profile-input-border">
                <Form.Item name="studentNo">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Description</p>
              <div className="profile-input-border">
                <Form.Item name="description">
                  <Input className="profile-input" placeholder="" type="text" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Applied Date</p>
              <div>
                <Form.Item name="appliedDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Conditional Offer Date</p>
              <div>
                <Form.Item name="conditionalOfferDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Decline Date</p>
              <div>
                <Form.Item name="declineDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Paid Date</p>
              <div>
                <Form.Item name="paidDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Formal Offer Date</p>
              <div>
                <Form.Item name="formalOfferDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Course Effective Date</p>
              <div>
                <Form.Item name="courseEffectiveDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>Visa Linked</p>
              <div>
                <Form.Item name="visaId">
                  <Select showSearch optionFilterProp="children">
                    {visaOptions}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>FTS Date</p>
              <div>
                <Form.Item name="ftsDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="ad-input-row">
            <div className="input-cont-width">
              <p>AIP Date</p>
              <div>
                <Form.Item name="aipDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <div className="input-cont-width">
              <p>Visa Approved Date</p>
              <div>
                <Form.Item name="visaApproveDate">
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="input-cont-width">
            <p>Start Date</p>
            <div>
              <Form.Item name="startDate">
                <DatePicker format={dateFormat} />
              </Form.Item>
            </div>
          </div>

          <div
            className="ca-checkbox-row"
            style={{ marginLeft: 0, marginBottom: 0 }}
          >
            <Form.Item name="aipSubmitted" valuePropName="checked">
              <Checkbox>AIP Submitted</Checkbox>
            </Form.Item>
          </div>

          <div
            className="ca-checkbox-row"
            style={{ marginLeft: 0, marginTop: 0 }}
          >
            <Form.Item name="ftsSubmitted" valuePropName="checked">
              <Checkbox>FTS Submitted</Checkbox>
            </Form.Item>
          </div>

          <div
            className="ad-btn-row"
            style={{ marginBottom: 0, marginRight: 0 }}
          >
            <Form.Item>
              <Button
                className="button-blue"
                style={{ marginLeft: 10 }}
                htmlType="submit"
              >
                <span style={{ color: "#FFFFFF" }}>UPDATE</span>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default AdmissionForm;
