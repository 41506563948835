import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const DocumentChecklistForm = ({
  onAddClientTag,
  addClientTagRes,

  handleCancel,

  onGetClientTag,
  clientTagRes,

  setLoading,

  onUpdetaClientTag,
  updateClientTagRes,

  createMode,

  updatedata,
}) => {
  // useEffect(() => {
  //   form.setFieldsValue({
  //     name: updatedata.name
  //   });
  // }, [form, updatedata.name]);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    console.log("Received values of form:", values);

    if (createMode === "add-tag") {
      const data = {
        subjectId: clientprofileid && clientprofileid,
        name: values.name,
      };
      onAddClientTag(data)
        .then(() => handleCancel())
        .then(() => onGetClientTag())
        .then(() => {
          setLoading(false);
          message.success("Successfully Added!");
        });
    } else {
      const updata = {
        id: updatedata.id,
        subjectId: updatedata && updatedata.subjectId,
        name: values.name,
      };

      onUpdetaClientTag(updata)
        .then(() => handleCancel())
        .then(() => onGetClientTag())
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        });
    }
  };

  return (
    <Fragment>
      <Form name="main" onFinish={onFinish} form={form}>
        <Form.Item name="name">
          <Input placeholder="Add New" />
        </Form.Item>
        <Form.Item>
          <Button className="form-btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
export default DocumentChecklistForm;
