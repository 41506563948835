import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginNew from "../Login/Login";

import {
  userLogin,
  userLogout,
  getUserDetail,
  refreshToken,
  addNotificationToken,
  removeNotificationToken,
  userExternalLogin,
  sendForgotPasswordEmail,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    // userData: state.userReducer.userData,
    loginError: state.userReducer.loginError,
    userDetail: state.userDetailReducer.userDetail,
    refreshTokenRes: state.userDetailReducer.refreshTokenRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserLogin: bindActionCreators(userLogin, dispatch),
    onExternalUserLogin: bindActionCreators(userExternalLogin, dispatch),
    onGetUserDetail: bindActionCreators(getUserDetail, dispatch),
    onRefreshToken: bindActionCreators(refreshToken, dispatch),
    onNotificationAdd: bindActionCreators(addNotificationToken, dispatch),
    onSendPasswordRequest: bindActionCreators(
      sendForgotPasswordEmail,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginNew);
