import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import DocumentTypesForm from "./SettingTimeTrackingForm";
import { Images } from "../../Themes";
import { Modal, Spin, Form, Input, Button, Select, message } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { apiRefresh } from "../../services/api";

const SettingTimeTracking = ({
  onGetTimeTrackingPopup,
  timeTrackingPopupRes,
  onAddTimeTracking,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    onGetTimeTrackingPopup()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetTimeTrackingPopup]);

  useEffect(() => {
    form.setFieldsValue({
      isTimeTrackingPopUp:
        timeTrackingPopupRes && timeTrackingPopupRes.isTimeTrackingPopUp,
    });
  }, [timeTrackingPopupRes]);

  // const removeTag = (id) => {
  //   setLoading(true);
  //
  //   const remove = {
  //     id: id,
  //     delete: true,
  //   };
  //
  //   onRemoveClientTag(remove).then(() => {
  //     onGetClientTag().then(() => {
  //       setLoading(false);
  //       message.success("Successfully Deleted!");
  //     });
  //   });
  // };

  const showModal = (value) => {
    setIsModalVisible(true);
    setCreateMode(value);
    setUpdatedata(value);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    let data = {
      isTimeTrackingPopUp: values.isTimeTrackingPopUp,
    };

    const timeTrackingPopupOpt = {
      url: `v1/users/TimeTrackingPopUp`,
    };
    timeTrackingPopupOpt.types = [
      "UPD_TIME_TRACKING_POPUP_SUCCESS",
      "UPD_TIME_TRACKING_POPUP_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .put(timeTrackingPopupOpt, data)
      .then((res) => {
        setLoading(false);
        message.success("Time tracking setting updated successfuly!");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Update failed!");
      });
  };
  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img
                      src={Images.timeTracking}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="top-text">TIME TRACKING</span>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="time-tracking">
                <div className="client-tag-form"></div>
                <div className="width-52">
                  <div className="client-tag-table">
                    <Form form={form} onFinish={onFinish}>
                      <div className="reminder-set-form margin-top-34">
                        <h3>TIME TRACKING</h3>
                        <Form.Item
                          className="d-block"
                          name="isTimeTrackingPopUp"
                        >
                          <Select>
                            <Select.Option value={true}>
                              Show Time Tracking Popup On Task Completed
                            </Select.Option>
                            <Select.Option value={false}>
                              Don't Show Time Tracking Popup On Task Completed
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {/* <span>left in client's passport expiry</span> */}
                      </div>

                      <div className="reminder-set-form margin-top-34">
                        <Form.Item className="d-block">
                          <Button
                            className="button-blue"
                            htmlType="submit"
                            type="primary"
                          >
                            UPDATE
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default SettingTimeTracking;
