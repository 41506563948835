import * as types from "../Constants";

export const questionnaireReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_QUESTIONNAIRE_SUCCESS:
      return { ...state, questionnaireSuccess: action.payload };
    case types.CREATE_QUESTIONNAIRE_FAILURE:
      return { ...state, questionnaireError: action.payload };

    case types.GET_ALL_QUESTIONNAIRE_SUCCESS:
      return { ...state, questionnaireList: action.payload };
    case types.GET_ALL_QUESTIONNAIRE_FAILURE:
      return { ...state, questionnaireListError: action.payload };

    case types.GET_QUESTIONNAIRE_SUCCESS:
      return { ...state, questionnaireData: action.payload };
    case types.GET_QUESTIONNAIRE_FAILURE:
      return { ...state, questionnaireDataError: action.payload };

    case types.SUBMIT_QUESTIONNAIRE_SUCCESS:
      return { ...state, subQuesSuccess: action.payload };
    case types.SUBMIT_QUESTIONNAIRE_FAILURE:
      return { ...state, subQuesFailure: action.payload };

    case types.UPDATE_QUESTIONNAIRE_SUCCESS:
      return { ...state, updQuesSuccess: action.payload };
    case types.UPDATE_QUESTIONNAIRE_FAILURE:
      return { ...state, updQuesFailure: action.payload };

    case types.GET_MAPPING_PARENT_SUCCESS:
      return { ...state, mappParentData: action.payload };
    case types.GET_MAPPING_PARENT_FAILURE:
      return { ...state, mappParentError: action.payload };

    case types.GET_MAPPING_CHILD_SUCCESS:
      return { ...state, mappChildData: action.payload };
    case types.GET_MAPPING_CHILD_FAILURE:
      return { ...state, mappChildError: action.payload };

    case types.DUPLICATE_QUESTIONNAIRE_SUCCESS:
      return { ...state, duplicateQuestionnaireSuccess: action.payload };
    case types.DUPLICATE_QUESTIONNAIRE_FAILURE:
      return { ...state, duplicateQuestionnaireError: action.payload };

    case types.DYNAMIC_LINK_SUCCESS:
      return { ...state, dynamicLinkSuccess: action.payload };
    case types.DYNAMIC_LINK_FAILURE:
      return { ...state, dynamicLinkError: action.payload };

    case types.GET_DYNAMIC_LINK_SUCCESS:
      return { ...state, dynamicLinkData: action.payload };
    case types.GET_DYNAMIC_LINK_FAILURE:
      return { ...state, dynamicLinkFailure: action.payload };

    case types.SEND_DYNAMIC_LINK_SUCCESS:
      return { ...state, sendLinkSuccess: action.payload };
    case types.SEND_DYNAMIC_LINK_FAILURE:
      return { ...state, sendLinkFailure: action.payload };

    case types.GET_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, emailTemplateData: action.payload };
    case types.GET_EMAIL_TEMPLATE_FAILURE:
      return { ...state, emailTemplateFailure: action.payload };

    case types.SET_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, setEmailData: action.payload };
    case types.SET_EMAIL_TEMPLATE_FAILURE:
      return { ...state, setEmailError: action.payload };

    case types.GET_QUESTIONNAIRE_GROUP_SUCCESS:
      return { ...state, questionnaireGroupRes: action.payload };
    case types.GET_QUESTIONNAIRE_GROUP_FAILURE:
      return { ...state, questionnaireGroupErr: action.payload };

    case types.GET_GROUPED_QUESTIONNAIRE_SUCCESS:
      return { ...state, groupedQuestionnaireRes: action.payload };
    case types.GET_GROUPED_QUESTIONNAIRE_FAILURE:
      return { ...state, groupedQuestionnaireErr: action.payload };

    case types.GET_FILLED_QUESTIONNAIRE_SUCCESS:
      return { ...state, filledQuestionnaireRes: action.payload };
    case types.GET_FILLED_QUESTIONNAIRE_FAILURE:
      return { ...state, filledQuestionnaireErr: action.payload };

    case types.GET_ALL_FILLED_QUESTIONNAIRE_SUCCESS:
      return { ...state, allFilledQuestionnaireRes: action.payload };
    case types.GET_ALL_FILLED_QUESTIONNAIRE_FAILURE:
      return { ...state, allFilledQuestionnaireErr: action.payload };

    case types.MERGE_FROM_CLIENT_SUCCESS:
      return { ...state, mergeFromClientRes: action.payload };
    case types.MERGE_FROM_CLIENT_FAILURE:
      return { ...state, mergeFromClientErr: action.payload };

    case types.UPD_MERGE_FROM_CLIENT_SUCCESS:
      return { ...state, updMergeFromClientRes: action.payload };
    case types.UPD_MERGE_FROM_CLIENT_FAILURE:
      return { ...state, updMergeFromClientErr: action.payload };

    case types.UPD_MERGE_TO_CLIENT_SUCCESS:
      return { ...state, updMergeToClientRes: action.payload };
    case types.UPD_MERGE_TO_CLIENT_FAILURE:
      return { ...state, updMergeToClientErr: action.payload };

    default:
      return state;
  }
};
