import { api } from "../../services/api";
import * as types from "../Constants";

export const getUserPermission = (data) => {
  const options = {
    url: "v1/user/permission",
  };

  options.types = [types.PERMISSION_SUCCESS, types.PERMISSION_FAILURE];

  return api.get(options);
};

export const addUserPermission = (data) => {
  const options = {
    url: "v1/user/permission/InsertUserPermission",
  };

  options.types = [types.ADD_PERMISSION_SUCCESS, types.ADD_PERMISSION_FAILURE];

  return api.post(options, data);
};

export const updUserPermission = (data) => {
  const options = {
    url: "v1/user/permission",
  };

  options.types = [
    types.UPDATE_PERMISSION_SUCCESS,
    types.UPDATE_PERMISSION_FAILURE,
  ];

  return api.put(options, data);
};
