import React, { useState } from "react";
import FileNotes from "./FileNotes";

const FileNotesMain = ({
  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  userDataEmp,

  onRemoveEmployerFile,

  handleCancel,

  onUpdateEmployerFile,

  onAddAnyTamplate,
  addAnyTemRes,
  onGetPermProcessingPerson,
  tabKey,
}) => {
  return (
    <FileNotes
      onGetEmployerFile={onGetEmployerFile}
      employerFileRes={employerFileRes}
      onAddEmployerFile={onAddEmployerFile}
      onGetLetterTemplates={onGetLetterTemplates}
      LetterTemplatesRes={LetterTemplatesRes}
      userDataEmp={userDataEmp}
      onRemoveEmployerFile={onRemoveEmployerFile}
      handleCancel={handleCancel}
      onUpdateEmployerFile={onUpdateEmployerFile}
      onAddAnyTamplate={onAddAnyTamplate}
      addAnyTemRes={addAnyTemRes}
      onGetPermProcessingPerson={onGetPermProcessingPerson}
      tabKey={tabKey}
    />
  );
};

export default FileNotesMain;
