import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Modal, Spin, message, Select, Button } from "antd";
import SalesPipeline from "./SalesPipeline";
// import EmployerManagementTabs from "./EmployerManagementTabs";
// import Invoice from "./Invoice/Invoice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { apiRefresh } from "../services/api";
import DealStages from "./DealStages";
import AddDealForm from "./AddDealForm";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Images } from "../Themes";

var userId = localStorage.getItem("userId");
const selectedBranchId = localStorage.getItem("selectedBranchId");

const { TabPane } = Tabs;
const { Option } = Select;

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,
  border: 2,
  borderStyle: "solid",
  borderColor: "aliceblue",

  // change background colour if dragging
  background: isDragging ? "#EDEDED" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#DCEBF3",
  padding: grid,
  width: 250,
  border: 0.5,
  borderStyle: "solid",
  borderColor: "#D4D4D4",
  marginTop: 5,
  margin: 2,
});

const Deals = ({
  onGetBranchPipeline,
  brnchPipelineData,
  onAddSalePipeline,
  onGetSalePipeline,
  getSalePipelineRes,
  onGetTeamMember,
  teamMembers,
  onSearchPotentialClient,
  onSearchClient,
  getPotentialClientInfo,
  onSearchEmployerMain,
  onGetMailChimp,
}) => {
  const history = useHistory();
  const [employerTabble, setEmployerTabble] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [state, setState] = useState([getItems(10), getItems(5)]);
  const [state, setState] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedKey, setSelectedKey] = useState(false);

  useEffect(() => {
    onGetBranchPipeline().then((res) => {});
  }, []);

  const callback = (key) => {
    setSelectedKey(key);
    if (key == "2") {
      onGetSalePipeline();
    } else if (key == "3") {
    }
    // onGetEmployerManag(selectedBranchId);
    // window.location.reload();
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    let stgInd = parseInt(destination.droppableId);
    let findDeal = state[source.droppableId].dealList.find(
      (obj) => obj.id === result.draggableId
    );
    if (findDeal) {
      findDeal.stageId = state[stgInd].id;
      findDeal.amount = parseInt(findDeal.amount);
    }

    const addDealOpt = {
      url: `v1/deal`,
    };
    addDealOpt.types = ["UPDATE_DEAL_SUCCESS", "UPDATE_DEAL_FAILURE"];
    setLoading(true);
    apiRefresh.put(addDealOpt, findDeal).then((res) => {
      getSinglePiplineData(selectedPipeline);
      setLoading(false);
      // dropped outside the list
      // if (!destination) {
      //   return;
      // }
      // const sInd = +source.droppableId;
      // const dInd = +destination.droppableId;

      // if (sInd === dInd) {
      //   const items = reorder(state[sInd], source.index, destination.index);
      //   const newState = [...state];
      //   newState[sInd] = items;
      //   setState(newState);
      // } else {
      //   const result = move(state[sInd], state[dInd], source, destination);
      //   const newState = [...state];
      //   newState[sInd] = result[sInd];
      //   newState[dInd] = result[dInd];

      //   setState(newState.filter((group) => group.length));
      // }
    });
  };

  const onChangePipeline = (id) => {
    setSelectedPipeline(id);
    getSinglePiplineData(id);
  };

  const getSinglePiplineData = (id) => {
    const getDealsOpt = {
      url: `v1/deal/GetDealByPipelineId/${id}`,
    };
    getDealsOpt.types = ["GET_DEALS_SUCCESS", "GET_DEALS_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getDealsOpt)
      .then((res) => {
        setLoading(false);
        setState(res && res.stageList);
      })
      .catch((err) => {
        setLoading(false);
        setState([]);
        message.error("Failed!");
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const removeDeal = (id) => {
    let data = {
      id: id,
    };
    const getDealsOpt = {
      url: `v1/deal`,
    };
    getDealsOpt.types = ["REMOVE_DEALS_SUCCESS", "REMOVE_DEALS_FAILURE"];
    setLoading(true);
    apiRefresh
      .delete(getDealsOpt, data)
      .then((res) => {
        getSinglePiplineData(selectedPipeline);
        setLoading(false);
        message.success("Deleted!");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const moveToClient = (data) => {
    if (data.memberType) {
      history.push({
        pathname:
          data.memberType.toLowerCase() === "client"
            ? "/profile"
            : data.memberType.toLowerCase() === "spouse"
            ? "/partner-profile"
            : "/client-family-member",
        state: {
          id: data.clientId,
          familyId: data.familyId,
          clickBy: "clientArea",
          memberId: data.subjectId,
        },
      });
    } else {
      getPotentialClientInfo(data.subjectId).then(() => {
        // getPotentialClientUpdateBol(false);
        history.push("/update-potential-client");
      });
    }
  };

  return (
    <Fragment>
      <Spin spinning={loading}>
        <div className="reminder-tabs-header employer-Header">
          <Tabs onChange={(key) => callback(key)} defaultActiveKey="1">
            <TabPane tab="DEALS" key="1">
              <div style={{ margin: 20, marginTop: 0 }}>
                {/* <button
                  type="button"
                  onClick={() => {
                    setState([...state, []]);
                  }}
                >
                  Add new group
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setState([...state, getItems(1)]);
                  }}
                >
                  Add new item
                </button> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Select
                    style={{ width: 300 }}
                    onChange={onChangePipeline}
                    placeholder="Select Pipeline"
                  >
                    {brnchPipelineData &&
                      brnchPipelineData.pipeline &&
                      brnchPipelineData.pipeline.map((pipeline, index) => {
                        return (
                          <Option value={pipeline.id}>{pipeline.name}</Option>
                        );
                      })}
                  </Select>
                  {selectedPipeline && (
                    <Button
                      className="button-blue"
                      onClick={() => setIsModalVisible(true)}
                    >
                      Create Deal
                    </Button>
                  )}
                </div>
                {isModalVisible && (
                  <Modal
                    title="Add Deal"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    header={false}
                    footer={false}
                    width={550}
                  >
                    <AddDealForm
                      onAddSalePipeline={onAddSalePipeline}
                      handleCancel={handleCancel}
                      setLoading={setLoading}
                      loading={loading}
                      state={state}
                      pipelineId={selectedPipeline}
                      onGetTeamMember={onGetTeamMember}
                      teamMembers={teamMembers}
                      getSinglePiplineData={getSinglePiplineData}
                      onSearchClient={onSearchClient}
                      onSearchPotentialClient={onSearchPotentialClient}
                      updateData={updateData}
                      isUpdate={isUpdate}
                      setIsUpdate={(val) => setIsUpdate(val)}
                      setUpdateData={(val) => setUpdateData(val)}
                      onSearchEmployerMain={onSearchEmployerMain}
                    />
                  </Modal>
                )}
                <div style={{ display: "flex", marginTop: 20 }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    {state.map((el, ind) => (
                      <Droppable key={ind} droppableId={`${ind}`}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <>
                              <div
                                style={{
                                  marginBottom: 20,
                                  borderBottom: 1,
                                  borderBottomStyle: "solid",
                                  borderBottomColor: "#D4D4D4",
                                  height: 50,
                                  marginLeft: -8,
                                  marginRight: -8,
                                  padding: 8,
                                  paddingTop: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    // alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#555",
                                    }}
                                  >
                                    {el.name}
                                  </span>
                                  <div style={{ width: 20 }}>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#555",
                                      }}
                                    >
                                      {el.dealList.length}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {el.dealList.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                  draggableObj={item}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div>
                                        <div className="deal-icon-position">
                                          <img
                                            src={Images.editBorderIcon}
                                            style={{
                                              width: 12,
                                              height: 12,
                                              marginRight: 10,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setIsModalVisible(true);
                                              setUpdateData(item);
                                              setIsUpdate(true);
                                            }}
                                          />
                                          <img
                                            src={Images.deleteGray}
                                            style={{
                                              width: 12,
                                              height: 12,
                                              marginRight: 5,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              removeDeal(item.id);
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {item.isActive ? (
                                            <img
                                              src={Images.activeGreen}
                                              className="svg-btn-img"
                                              style={{
                                                width: 14,
                                                height: 14,
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={Images.crossIcon}
                                              className="svg-btn-img"
                                              style={{
                                                width: 14,
                                                height: 14,
                                              }}
                                            />
                                          )}
                                          <span
                                            className="deal-title"
                                            style={{
                                              color:
                                                item.priority === 1
                                                  ? "#5ab859"
                                                  : item.priority === 2
                                                  ? "#efae4d"
                                                  : item.priority === 3
                                                  ? "#d8544f"
                                                  : "#555",
                                              marginLeft: 5,
                                            }}
                                          >
                                            {item.name}
                                          </span>
                                        </div>
                                        {item.clientName && (
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            onClick={() => moveToClient(item)}
                                          >
                                            <div>
                                              <img
                                                src={Images.personFill}
                                                className="svg-btn-img"
                                                style={{
                                                  width: 12,
                                                  height: 12,
                                                }}
                                              />
                                              <span
                                                className="deal-card-text"
                                                style={{
                                                  marginLeft: 5,
                                                  fontSize: 12,
                                                }}
                                              >
                                                {item.clientName
                                                  ? item.clientName
                                                  : ""}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {item.ownerName && (
                                          <div>
                                            <img
                                              src={Images.personIcon}
                                              className="svg-btn-img"
                                              style={{
                                                width: 26,
                                                height: 18,
                                                marginLeft: -6,
                                              }}
                                            />
                                            <span className="deal-card-text">
                                              {item.ownerName
                                                ? item.ownerName
                                                : ""}
                                            </span>
                                          </div>
                                        )}
                                        <div>
                                          <img
                                            src={Images.calendar}
                                            className="svg-btn-img"
                                            style={{
                                              width: 12,
                                              height: 12,
                                            }}
                                          />
                                          <span
                                            className="deal-card-text"
                                            style={{ marginLeft: 5 }}
                                          >
                                            {moment(item.createdDate).format(
                                              "DD/MM/YYYY"
                                            ) !== "01/01/1900" ||
                                            moment(item.createdDate).format(
                                              "DD/MM/YYYY"
                                            ) !== "01/01/0001"
                                              ? moment(item.createdDate).format(
                                                  "DD/MM/YYYY"
                                                )
                                              : ""}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={Images.grayDollar}
                                            className="svg-btn-img"
                                            style={{
                                              width: 12,
                                              height: 12,
                                              marginRight: 5,
                                            }}
                                          />
                                          <span className="deal-card-text">
                                            {item.amount ? item.amount : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </DragDropContext>
                </div>
              </div>
            </TabPane>
            <TabPane tab="PIPELINE" key="2">
              <SalesPipeline
                onAddSalePipeline={onAddSalePipeline}
                setLoading={setLoading}
                loading={loading}
                onGetSalePipeline={onGetSalePipeline}
                getSalePipelineRes={getSalePipelineRes}
              />
            </TabPane>
            <TabPane tab="DEAL STAGES" key="3">
              <DealStages
                selectedKey={selectedKey}
                onGetMailChimp={onGetMailChimp}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </Fragment>
  );
};
export default Deals;
