import React, { Fragment } from "react";
import { Form, Input, Button, Col, Row, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Images } from "./../../Themes";

let clientprofileid = sessionStorage.getItem("clientprofileid");

// const DynamicFieldSet = () => {
//   const onFinish = values => {
//     console.log('Received values of form:', values);
//   };
const AddNewMultipleContact = ({
  ongetEmployerContact,

  onAddEmployerContact,

  handleCancel,

  userDataEmp,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    var clientprofileid = sessionStorage.getItem("clientprofileid");
    // setLoading(true);
    console.log("Received values of form:", values);

    const data = {
      name: "values.contact_name",
      number: "values.contact_number",
      email: "values.contact_email",
      employerId: clientprofileid && clientprofileid,
    };

    onAddEmployerContact(data)
      .then(() => handleCancel())
      .then(() => ongetEmployerContact(userDataEmp && userDataEmp.id))
      .then(() => {
        // setLoading(false);
        message.success("Successfully Added!");
      });
  };

  return (
    <Fragment>
      <div className="employer-head">
        <Row gutter={8}>
          <Col span={12}>
            <p style={{ fontSize: "24px" }}>CONTACTS</p>
          </Col>
        </Row>
      </div>
      <div className=" add-employer-para">
        <Form
          onFinish={onFinish}
          form={form}
          className="width-100"
          name="main_contacts"
        >
          <Form.List name="contacts">
            {(fields, { add, remove }) => (
              <div className="">
                <Row className=" margin-contact-container">
                  <Col xs={24}>
                    <div className=" add-tag-btn">
                      <Form.Item className="form-add-btn ">
                        <img
                          style={{ marginBottom: "52px" }}
                          src={Images.addIcon}
                          className="icons-client add-icon"
                          type="primary"
                          onClick={() => add()}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    {fields.map((field, index) => (
                      <Row
                        className="file-contacts border-color forms-styling-tabs border-box-modal-add"
                        key={index}
                      >
                        <Col xs={24}>
                          <Row className="file-delete-head">
                            <Col xs={1} offset={23}>
                              <a
                                href="javascript:"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              >
                                <DeleteOutlined />
                              </a>
                            </Col>
                          </Row>
                          <Form.Item key={field.key}>
                            <Row gutter={8}>
                              <Col span={12}>
                                <p>Name</p>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="contact_name" required={false}>
                                  <Input placeholder="Name" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={8}>
                              <Col span={12}>
                                <p>Email</p>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="contact_email"
                                  required={false}
                                >
                                  <Input placeholder="Email" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={8}>
                              <Col span={12}>
                                <p>Number</p>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="contact_number"
                                  required={false}
                                >
                                  <Input placeholder="Number" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item>
                              <Button
                                type="primary"
                                className="login-form-button save-btn button-blue"
                                htmlType="submit"
                              >
                                Save Contacts
                              </Button>
                            </Form.Item>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddNewMultipleContact;
