import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import { Images } from "../Themes";
import { bindActionCreators } from "redux";
import { getAllNotesListing, deleteNote, setNoteInfo } from "../store/Actions";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddNote from "./AddNote";
const { Option } = Select;

class Notes extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      noteListing: [],
      visibleAddNoteModal: false,
      branchId: selectedBranchId,
      visibleDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllNotesListing();
  }

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this note?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.props.deleteNote(this.state.index).then(() => {
                    message.success("Note is deleted successfully!");
                    this.props.getAllNotesListing();
                    this.setState({ visibleDeleteModal: false });
                  });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getNotesSection = () => {
    return (
      <Row xs={24}>
        <Col xs={21} offset={1} style={{ marginTop: "13px" }}>
          <Table
            dataSource={this.state.noteListing ? this.state.noteListing : null}
            columns={this.columns}
            pagination={{
              defaultPageSize: 20,
            }}
          />
        </Col>
        <Col xs={1} offset={1} style={{ marginTop: "-14px" }}>
          <img
            src={Images.addIcon}
            className="icons-client"
            type="primary"
            onClick={() => {
              this.props.setNoteInfo(null);
              this.setState({ visibleAddNoteModal: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    let _noteListing = nextProps && nextProps.noteListing;
    this.setState({
      noteListing: _noteListing,
    });
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getAllNotesListing().then(() => {});
      });
    }
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      width: "60%",
      title: "Notes",
      dataIndex: "note",
      key: "note",
      render: (text, record) => {
        let html = record.note;

        let re = /src=([^">]+)/g;
        let results = re.exec(html);
        let _text = html.split("src")[0];
        return (
          <div style={{ display: "block" }}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontStyle: "normal",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              {_text}
            </p>
            {results && results[1] ? (
              <img
                src={results && results[1]}
                alt={""}
                style={{ width: "10%", objectFit: "contain" }}
              />
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      ellipsis: true,
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.props.setNoteInfo(record);
              this.setState({ visibleAddNoteModal: true });
            }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  handleCancelAddNoteModal = (e) => {
    this.setState({
      visibleAddNoteModal: false,
    });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <Row>
          <Col xs={24}>
            <h4 className={"settings-heading"}>{"Invoice Notes"}</h4>
          </Col>
        </Row>
        <div className="ca-gray-cont">{this.getNotesSection()}</div>
        {this.state.visibleAddNoteModal ? (
          <AddNote
            handleCancelAddNoteModal={this.handleCancelAddNoteModal}
            visibleAddNoteModal={this.state.visibleAddNoteModal}
          />
        ) : null}
        {this.getModalDelete()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    noteListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.noteListing &&
      state.invoicesReducer.noteListing.items &&
      state.invoicesReducer.noteListing.items,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllNotesListing: bindActionCreators(getAllNotesListing, dispatch),
  deleteNote: bindActionCreators(deleteNote, dispatch),
  setNoteInfo: bindActionCreators(setNoteInfo, dispatch),
});

Notes = connect(mapStateToProps, mapDispatchToProps)(Notes);

export default Notes;
