import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import ClientTagForm from "./DocumentChecklistForm";
import { Images } from "../../Themes";
import { Input, Button, Form, message, Row, Col, Radio, Spin } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const QuestionnaireSettings = ({
  onUploadAvatar,
  imageUploadSuccess,
  onGetQuestionnaireSet,
  questionnaireSetRes,

  onUpdateQuestionnaireSet,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    questionaireDeclarationWebValue,
    setQuestionaireDeclarationWebValue,
  ] = useState("");
  const [
    questionaireDeclarationDetail,
    setQuestionaireDeclarationDetail,
  ] = useState("");
  const [
    questionaireDeclarationBasic,
    setQuestionaireDeclarationBasic,
  ] = useState("");
  const [customDeclaration, setCustomDeclaration] = useState("");
  const [bankInfo, setBankInfo] = useState("");
  const [radioValue, setRadioValue] = useState(true);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      setLoading(true);
      onGetQuestionnaireSet().then((res) => {
        let data = res && res.payload && res.payload[0];
        // setQuestionaireDeclarationWebValue(data && data.qDeceleration);
        // setQuestionaireDeclarationDetail(data && data.qDecelerationDetail);
        // setQuestionaireDeclarationBasic(data && data.qDecelerationBasic);
        setCustomDeclaration(
          (data && data.customDeceleration) ||
            "I hereby declare that the information given by me in this application is true and correct to the best of my knowledge.I understand and agree thatany false information, misrepresentation, or omission of facts in this application and the application process may be justification for refusal/ decline.I further understand and agree that all information furnished in this application and the application process may be verified as may be necessary."
        );
        // setBankInfo(data && data.qBankInfo);
        form.setFieldsValue({
          display_email_address: data && data.qEmail,
          ShowBankInfo: data && data.qShowBankInfo,
        });
        setLoading(false);
      });
    } else {
      initialRender.current = false;
    }
  }, [onGetQuestionnaireSet]);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      qBankInfo: bankInfo,
      qDeceleration: questionaireDeclarationWebValue,
      qDecelerationBasic: questionaireDeclarationBasic,
      qDecelerationDetail: questionaireDeclarationDetail,
      qEmail:
        values && values.display_email_address === undefined
          ? ""
          : values.display_email_address,
      customDeceleration: customDeclaration,
      qShowBankInfo: radioValue,
    };
    onUpdateQuestionnaireSet(data).then(() => {
      setLoading(false);
      message.success("Successfully Updated!");
    });
  };

  const getQuestionaireDeclarationWebValue = (value) => {
    setQuestionaireDeclarationWebValue(value);
  };

  const getQuestionaireDeclarationDetail = (value) => {
    setQuestionaireDeclarationDetail(value);
  };

  const getQuestionaireDeclarationBasic = (value) => {
    setQuestionaireDeclarationBasic(value);
  };

  const getCustomDeclaration = (value) => {
    setCustomDeclaration(value);
  };

  const getbankInfo = (value) => {
    setBankInfo(value);
  };

  const onChangeRadioButton = (e) => {
    setRadioValue(e.target.value);
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item questionnaire-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.questionnaireIcon}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">Questionnaire Settings</span>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="questionnaire-content w-80">
              <Spin size="large" spinning={loading}>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  form={form}
                  // onFinishFailed={onFinishFailed}
                >
                  <div className="border-5px">
                    <Form.Item
                      label="Questionnaire Display Email Address"
                      name="display_email_address"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  {/* <div className="border-5px">
                    <Form.Item
                      className="mar-top"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <h4 style={{ marginBottom: "10px" }}>
                        Questionnaire Declaration Web Assessment
                      </h4>
                      <FroalaEditorCom
                        setLoading={(value) => setLoading(value)}
                        model={questionaireDeclarationWebValue}
                        onModelChange={getQuestionaireDeclarationWebValue}
                      />
                    </Form.Item>
                  </div> */}
                  {/* <div className="border-5px">
                    <Form.Item
                      className="mar-top"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <h4 style={{ marginBottom: "10px" }}>
                        Questionnaire Declaration Detail
                      </h4>
                      <FroalaEditorCom
                        setLoading={(value) => setLoading(value)}
                        model={questionaireDeclarationDetail}
                        onModelChange={getQuestionaireDeclarationDetail}
                      />
                    </Form.Item>
                  </div> */}
                  {/* <div className="border-5px">
                    <Form.Item
                      className="mar-top"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <h4 style={{ marginBottom: "10px" }}>
                        Questionnaire Declaration Basic
                      </h4>
                      <FroalaEditorCom
                        setLoading={(value) => setLoading(value)}
                        model={questionaireDeclarationBasic}
                        onModelChange={getQuestionaireDeclarationBasic}
                      />
                    </Form.Item>
                  </div> */}
                  <div className="border-5px">
                    <Form.Item
                      className="mar-top"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <h4 style={{ marginBottom: "10px" }}>
                        Custom Declaration
                      </h4>
                      <FroalaEditorCom
                        setLoading={(value) => setLoading(value)}
                        model={customDeclaration}
                        onModelChange={getCustomDeclaration}
                      />
                    </Form.Item>
                  </div>
                  {/* <div className="border-5px">
                    <Form.Item
                      className="mar-top"
                      name="username"
                      rules={[
                        {
                          required: false,
                          message: "Required!",
                        },
                      ]}
                    >
                      <h4 style={{ marginBottom: "10px" }}>
                        Questionnaire Bank Info
                      </h4>
                      <FroalaEditorCom
                        setLoading={(value) => setLoading(value)}
                        model={bankInfo}
                        onModelChange={getbankInfo}
                      />
                    </Form.Item>
                  </div> */}
                  {/* <Row>
                    <Col>
                      <Form.Item
                        className="mar-top"
                        name="ShowBankInfo"
                        rules={[
                          {
                            required: false,
                            message: "Required!",
                          },
                        ]}
                      >
                        <div style={{ display: "flex" }}>
                          <p style={{ marginRight: "33px" }}>
                            {"Show Bank Info"}
                          </p>
                          <Radio.Group
                            onChange={onChangeRadioButton}
                            value={radioValue}
                            style={{ marginTop: "5px" }}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Form.Item className="mar-top">
                    <Button
                      className="button-blue"
                      htmlType="submit"
                      type="primary"
                    >
                      SAVE NOW
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default QuestionnaireSettings;
