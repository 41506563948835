import * as types from "../Constants";
import { api } from "../../services/api";
import moment from "moment";
import {
  GET_CHILD_INVOICE_LISTING_FAILURE,
  GET_CHILD_INVOICE_LISTING_SUCCESS,
  GET_INVOICE_PAYMENT_PDF_SUCCESS,
  GET_INVOICE_PDF_HTML_BLOB_SUCCESS,
  GET_INVOICE_PDF_WITHOUT_UPDATE_FAILURE,
  GET_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS,
  GET_INVOICE_RECEIPT_PDF_HTML_BLOB_FAILURE,
  GET_INVOICE_RECEIPT_PDF_HTML_BLOB_SUCCESS,
} from "../Constants";
import { getBranchId } from "../../Components/getBranchId";

export const setEmailTemplatePayment = (data) => {
  const options = {
    url: "v1/invoice/SendRecipt",
  };

  options.types = [
    types.SET_EMAIL_TEMPLATE_PAYMENT_SUCCESS,
    types.SET_EMAIL_TEMPLATE_PAYMENT_FAILURE,
  ];

  return api.post(options, data);
};

export const getParentInvoiceListing = (data) => {
  let _data = {
    subjectId: data.clientProfileId,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    clientTagId: data.clientTagId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
  };

  const options = {
    url: "v1/invoice/AllBySubjectIdWithPaging",
  };

  options.types = [
    types.GET_INVOICE_LISTING_SUCCESS,
    types.GET_INVOICE_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const getChildInvoiceListing = (data) => {
  const options = {
    url: data,
  };

  options.types = [
    types.GET_CHILD_INVOICE_LISTING_SUCCESS,
    types.GET_CHILD_INVOICE_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getPDF = (data) => {
  const options = {
    url: `v1/invoice/InvoicePDFHtml/${data.id}/${data.paymentId}`,
  };
  options.types = [
    types.GET_INVOICE_PDF_SUCCESS,
    types.GET_INVOICE_PDF_FAILURE,
  ];

  return api.getFile(options);
};

export const getInvoicePDFHtmlBlob = (data) => {
  const options = {
    url: `v1/invoice/InvoicePDFHtmlBlob/${data.invoiceId}/${data.paymentId}`,
  };
  options.types = [
    types.GET_INVOICE_PDF_HTML_BLOB_SUCCESS,
    types.GET_INVOICE_PDF_HTML_BLOB_SUCCESS,
  ];

  return api.get(options);
};

export const getInvoiceReceiptPDFHtmlBlob = (data) => {
  const options = {
    url: `v1/invoice/InvoiceReciptPDFHtmlBlob/${data.invoiceId}/${data.paymentId}`,
  };
  options.types = [
    types.GET_INVOICE_RECEIPT_PDF_HTML_BLOB_SUCCESS,
    types.GET_INVOICE_RECEIPT_PDF_HTML_BLOB_FAILURE,
  ];

  return api.get(options);
};

export const getPDFInvoicePayment = (data) => {
  const options = {
    url: `v1/invoice/InvoiceReciptPDFHtml/${data.id}/${data.paymentId}`,
  };

  options.types = [
    types.GET_INVOICE_PAYMENT_PDF_SUCCESS,
    types.GET_INVOICE_PAYMENT_PDF_FAILURE,
  ];

  return api.getFile(options);
};

export const getInvoiceListingBySubjectId = (data) => {
  let _data = {
    subjectTypeId: data.subjectTypeId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    name: data.name,
    email: data.email,
  };

  const options = {
    url: "v1/invoice/InvoicesBySubjectTypeId",
  };

  options.types = [
    types.GET_INVOICE_LISTING_BY_SUBJECT_ID_SUCCESS,
    types.GET_INVOICE_LISTING_BY_SUBJECT_ID_FAILURE,
  ];

  return api.post(options, _data);
};

export const getAllInvoiceTypes = () => {
  const options = {
    url: "v1/invoice/type/GetAllInvoiceTypes",
  };

  options.types = [
    types.GET_ALL_INVOICE_TYPES_SUCCESS,
    types.GET_ALL_INVOICE_TYPES_FAILURE,
  ];

  return api.get(options);
};

export const getInvoiceInfo = (id) => {
  const options = {
    url: `v1/invoice/${id}`,
  };

  options.types = [
    types.GET_INVOICE_INFO_SUCCESS,
    types.GET_INVOICE_INFO_FAILURE,
  ];

  return api.get(options);
};

export const getInvoicePDF = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes:
      data.notesImageData === "" ||
      data.notesImageData === undefined ||
      data.notesImageData === null
        ? data.notes
        : data.notes.concat(`src=${data.notesImageData}`),
    subTotal: data.subTotal,
    gst: data.gst,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: false,
    xeroInvoiceId: "00000000-0000-0000-0000-000000000000",
    currencyId: data.currencyId,
    taxName: data.taxName,
    taxId: data.taxId || 0,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: true,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    parentInvoiceId: data.parentInvoiceId,
    dateStr: _dateStr,
    invoiceItems: data.invoiceItems,
  };
  const options = {
    url: "v1/invoice/InvoicePDFHtmlPreview",
  };

  options.types = [
    types.GET_INVOICE_PDF_WITHOUT_UPDATE_SUCCESS,
    types.GET_INVOICE_PDF_WITHOUT_UPDATE_FAILURE,
  ];

  return api.postWithFileRes(options, _data);
};

export const getAllTaxesListing = (data) => {
  const options = {
    url: `v1/branch/tax/All`,
  };

  options.types = [
    types.GET_ALL_TAXES_LISTING_SUCCESS,
    types.GET_ALL_TAXES_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const deleteTax = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/branch/tax",
  };

  options.types = [types.DELETE_TAX_SUCCESS, types.DELETE_TAX_FAILURE];

  return api.delete(options, deleteData);
};

export const setTaxInfo = (info) => {
  const taxInfo = {
    type: types.GET_TAX_INFO_SUCCESS,
    payload: info,
  };
  return taxInfo;
};

export const addTax = (data) => {
  let _data = {
    name: data.name,
    number: data.number,
    percent: data.percent,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/tax",
  };

  options.types = [types.ADD_TAX_SUCCESS, types.ADD_TAX_FAILURE];

  return api.post(options, _data);
};

export const editTax = (data) => {
  let _data = {
    id: data.id,
    name: data.name,
    number: data.number,
    percent: data.percent,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/tax",
  };

  options.types = [types.EDIT_TAX_SUCCESS, types.EDIT_TAX_FAILURE];

  return api.put(options, _data);
};

export const getAllNotesListing = () => {
  const options = {
    url: `v1/branch/note/All`,
  };

  options.types = [
    types.GET_ALL_NOTES_LISTING_SUCCESS,
    types.GET_ALL_NOTES_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const deleteNote = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/branch/note",
  };

  options.types = [types.DELETE_NOTE_SUCCESS, types.DELETE_NOTE_FAILURE];

  return api.delete(options, deleteData);
};

export const setNoteInfo = (info) => {
  const noteInfo = {
    type: types.GET_NOTE_INFO_SUCCESS,
    payload: info,
  };
  return noteInfo;
};

export const checkDuplicateClientInvoice = (data) => {
  // Promise.all([getBranchId()]).then((res) => {
  //   if (res && res[0].length > 0 && res[0]) {
  // selectedBranchId = res[0];

  let _data = {
    invoiceNo: data.invoiceNo,
  };

  const options = {
    url: `v1/invoice/CheckDuplicate/${_data.invoiceNo}`,
  };
  options.types = [
    types.CHECK_DUPLICATE_INVOICE_SUCCESS,
    types.CHECK_DUPLICATE_INVOICE_FAILURE,
  ];
  return api.get(options);
  // }
  // });
};

export const addNote = (data) => {
  let _data = {
    name: data.name,
    note: data.note,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/note",
  };

  options.types = [types.ADD_NOTE_SUCCESS, types.ADD_NOTE_FAILURE];

  return api.post(options, _data);
};

export const editNote = (data) => {
  let _data = {
    id: data.id,
    name: data.name,
    note: data.note,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/note",
  };

  options.types = [types.EDIT_NOTE_SUCCESS, types.EDIT_NOTE_FAILURE];

  return api.put(options, _data);
};

export const addBank = (data) => {
  let _data = {
    name: data.name,
    title: data.title,
    bank: data.bank,
    number: data.number,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/bank",
  };

  options.types = [types.ADD_BANK_SUCCESS, types.ADD_BANK_FAILURE];

  return api.post(options, _data);
};

export const editBank = (data) => {
  let _data = {
    id: data.id,
    name: data.name,
    title: data.title,
    bank: data.bank,
    number: data.number,
    // branchId: data.branchId,
  };

  const options = {
    url: "v1/branch/bank",
  };

  options.types = [types.EDIT_BANK_SUCCESS, types.EDIT_BANK_FAILURE];

  return api.put(options, _data);
};

export const getAllBanksListing = () => {
  // let selectedBranchId = localStorage.getItem("selectedBranchId");
  // let branchId = data || selectedBranchId;

  const options = {
    url: `v1/branch/bank`,
  };

  options.types = [
    types.GET_ALL_BANKS_LISTING_SUCCESS,
    types.GET_ALL_BANKS_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const deleteBank = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/branch/bank",
  };

  options.types = [types.DELETE_BANK_SUCCESS, types.DELETE_BANK_FAILURE];

  return api.delete(options, deleteData);
};

export const setBankInfo = (info) => {
  const bankInfo = {
    type: types.GET_BANK_INFO_SUCCESS,
    payload: info,
  };
  return bankInfo;
};

export const getAllCurrencies = () => {
  const options = {
    url: "v1/currency/GetAllCurrencies",
  };

  options.types = [
    types.GET_ALL_CURRENCY_LISTING_SUCCESS,
    types.GET_ALL_CURRENCY_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const getLastInvoiceNumber = (data) => {
  const options = {
    url: `v1/invoice/LastInvoiceNumber`,
  };

  options.types = [
    types.GET_LAST_INVOICE_NO_SUCCESS,
    types.GET_LAST_INVOICE_NO_FAILURE,
  ];

  return api.get(options);
};

export const getInvoiceStatuses = (id) => {
  const options = {
    url: `v1/invoice/status/GetAllInvoiceStatuses/${id}`,
  };

  options.types = [
    types.GET_INVOICE_STATUSES_LISTING_SUCCESS,
    types.GET_INVOICE_STATUSES_LISTING_FAILURE,
  ];
  return api.get(options);
};

export const setInvoicesDataNull = () => {
  return {
    type: types.SET_INVOICES_DATA_NULL_SUCCESS,
  };
};

export const setSelectedSchoolEmail = (email, id) => {
  const _data = {
    type: types.GET_SCHOOL_EMAIL_SUCCESS,
    payload: email,
    id,
  };
  return _data;
};

export const deleteInvoice = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/invoice",
  };

  options.types = [types.DELETE_INVOICE_SUCCESS, types.DELETE_INVOICE_FAILURE];

  return api.delete(options, deleteData);
};

export const addNewInvoice = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes:
      data.notesImageData === "" ||
      data.notesImageData === undefined ||
      data.notesImageData === null
        ? data.notes
        : data.notes.concat(`src=${data.notesImageData[1]}`),
    subTotal: data.subTotal,
    gst: data.gst || 0,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: false,
    xeroInvoiceId: "00000000-0000-0000-0000-000000000000",
    currencyId: data.currencyId,
    taxName: data.taxName,
    taxId: data.taxId || 0,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: data.depositInvoice,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    parentInvoiceId: data.parentInvoiceId,
    dateStr: _dateStr,
    invoiceItems: data.invoiceItems,
    templateRefId: data.templateRefId,
  };
  const options = {
    url: "v1/invoice",
  };

  options.types = [types.ADD_INVOICE_SUCCESS, types.ADD_INVOICE_FAILURE];
  return api.post(options, _data);
};

export const editInvoice = (data) => {
  let _dueDate;
  if (data.dueDate === "") {
    _dueDate = "1900-01-01T00:00:00+00:00";
  } else {
    let dueDate = moment(data.dueDate, "DD/MM/YYYY").format();
    _dueDate = moment(dueDate).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _dateStr;
  if (data.dateStr === "") {
    _dateStr = "1900-01-01T00:00:00+00:00";
  } else {
    let dateStr = moment(data.dateStr, "DD/MM/YYYY").format();
    _dateStr = moment(dateStr).format("YYYY-MM-DDT00:00:00+00:00");
  }
  let _data = {
    id: data.invoiceId,
    subjectId: data.clientProfileId || "",
    subjectTypeId: data.subjectTypeId,
    dueDate: _dueDate,
    invoiceNo: data.invoiceNo,
    invoiceTypeId: data.invoiceTypeId,
    notes:
      data.notesImageData === "" ||
      data.notesImageData === undefined ||
      data.notesImageData === null
        ? data.notes
        : data.notes.concat(`src=${data.notesImageData[1]}`),
    subTotal: data.subTotal,
    gst: data.gst || 0,
    discount: data.discount,
    total: data.total,
    dueAmount: data.dueAmount,
    to: data.to,
    paidAmount: 0,
    postedXero: data.postedXero,
    xeroInvoiceId: data.xeroInvoiceId,
    currencyId: data.currencyId || "106",
    taxName: data.taxName,
    taxId: data.taxId || 0,
    taxInclusive: data.taxInclusive,
    bankAccount: data.bankAccount,
    depositInvoice: data.depositInvoice,
    statusId: data.statusId,
    discountAmount: data.discountAmount,
    clientTagId: data.clientTagId,
    newClaculations: true,
    dateStr: _dateStr,
    templateRefId: data.templateRefId,
    invoiceItems: data.invoiceItems,
  };
  const options = {
    url: "v1/invoice",
  };

  options.types = [types.EDIT_INVOICE_SUCCESS, types.EDIT_INVOICE_FAILURE];
  return api.put(options, _data);
};

export const addInvoicePayment = (data) => {
  let _data = {
    invoiceId: data.invoiceId,
    description: data.paymentDescription || "",
    amount: data.paymentAmount,
    receivedDate: moment(data.paymentDate).format("YYYY-MM-DDT00:00:00+00:00"),
    bankAccountId: data.paymentBank,
  };
  const options = {
    url: "v1/invoice/payment",
  };

  options.types = [
    types.ADD_INVOICE_PAYMENT_SUCCESS,
    types.ADD_INVOICE_PAYMENT_FAILURE,
  ];
  return api.post(options, _data);
};

export const sendFollowUp = (data) => {
  let _data = {
    id: data.id,
    sendDate: data.sendDate,
  };
  const options = {
    url: "v1/invoice/FollowUpInvoice",
  };

  options.types = [types.SEND_FOLLOW_UP_SUCCESS, types.SEND_FOLLOW_UP_FAILURE];
  return api.post(options, _data);
};

export const getInvoicePayments = (id) => {
  const options = {
    url: `v1/invoice/payment/All/${id}`,
  };

  options.types = [
    types.GET_ALL_INVOICE_PAYMENTS_SUCCESS,
    types.GET_ALL_INVOICE_PAYMENTS_FAILURE,
  ];

  return api.get(options);
};

export const deleteInvoicePayment = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/invoice/payment",
  };

  options.types = [
    types.DELETE_INVOICE_PAYMENT_SUCCESS,
    types.DELETE_INVOICE_PAYMENT_FAILURE,
  ];

  return api.delete(options, deleteData);
};

export const addInvoiceTemplate = (data) => {
  const options = {
    url: "v1/invoice/TemplateAddNewLine",
  };

  options.types = [
    types.ADD_INVOICE_TEMPLATE_SUCCESS,
    types.ADD_INVOICE_TEMPLATE_FAILURE,
  ];

  return api.post(options, data);
};

export const getInvoiceTemplate = (id, user) => {
  let userId = "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/invoice/TemplateAddNewLine/00000000-0000-0000-0000-000000000000`,
  };

  options.types = [
    types.GET_INVOICE_TEMPLATE_SUCCESS,
    types.GET_INVOICE_TEMPLATE_FAILURE,
  ];

  return api.get(options);
};
