import React, { Fragment } from "react";
import { Table, Select } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

const { Option } = Select;
const columns = [
  {
    width: "100px",
    title: "Sn",
    dataIndex: "sn",
    key: "sn",
    render: (text, row, index) => {
      //   if (index == 0) {
      return <a>{text}</a>;
      //   }
      //   return {
      //     children: <a>{text}</a>,
      //     props: {
      //       colSpan: 6,
      //     },
      //   };
    },
  },
  {
    width: "100px",
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    width: "100px",
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    width: "100px",
    title: "Visa Type",
    dataIndex: "visaTypeName",
    key: "visaTypeName",
  },
  {
    width: "100px",
    title: "Visa Status",
    dataIndex: "currentStatus",
    key: "currentStatus",
  },
  // {
  //   width: "100px",
  //   title: "Visa Status",
  //   dataIndex: "otherVisas",
  //   key: "otherVisas",
  //   render: (otherVisas) => (
  //     <span>
  //       {otherVisas.map((c) => (
  //         <div key={c.visaStatus}>{c.visaStatus}</div>
  //       ))}
  //     </span>
  //   ),
  // },
  {
    width: "100px",
    dataIndex: "",
    key: "",
    // onHeaderCell: (column) => {
    //
    //   return {};
    // },
    render: (text, row, index) => {
      var names = [];
      return <></>;
    },
  },
];
// const SaleHistoryTable = ({ SaleHistoryReport, displayText }) => {
export class VisaReportTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
      visaStatuses: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps != this.props.reportsCount) {
      this.setState({ totalPages: this.props.reportsCount });
    }
  }

  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson:
            this.props.requestData.processingPerson ||
            "00000000-0000-0000-0000-000000000000",
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: value.pageSize,
          pageNumber: value.current,
          visaTypeId: this.props.requestData.visaTypeId || 0,
          caseStatusId: this.props.requestData.caseStatusId.toString(),
          subId: this.props.requestData.subId || 0,
          destination: this.props.requestData.destination || "",
          liaId:
            this.props.requestData.liaId ||
            "00000000-0000-0000-0000-000000000000",
          countryId: 0,
          statusList: this.props.requestData.statusesArray,
          active: this.props.requestData.activeInactive || "",
          isFamily: this.props.requestData.checkedInclude || false,
          jobSector: this.props.requestData.selectedJobSector,
        };
        this.props.onFileNotesReport(data);
      }
    );
  };

  handleChange = (value) => {
    this.setState({ visaStatuses: value });
    this.props.onChangeStatus(value);
  };

  render() {
    const { fileNotesReportRes, displayText, visaStatusHideData } = this.props;
    columns[5].title = (
      <div>
        <Select
          mode="multiple"
          placeholder="Visa Status"
          value={this.state.visaStatuses}
          onChange={this.handleChange}
          style={{ width: "200px" }}
        >
          {visaStatusHideData &&
            visaStatusHideData.items.map((user, userInd) => (
              <Option key={userInd} value={user.name}>
                {user.name}
              </Option>
            ))}
        </Select>
      </div>
    );
    return (
      <Fragment>
        <div className="report-table">
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={fileNotesReportRes && fileNotesReportRes}
            className="border-3 table-head"
            bordered
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
            scroll={{ x: "auto" }}
          />
        </div>
      </Fragment>
    );
  }
}
export default VisaReportTable;
