import React, { Fragment, useState, useEffect } from "react";
import { Select, message, Button, Checkbox } from "antd";
import moment from "moment";

import { Images } from "../../Themes";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const LinkMemberCard = ({
  member,
  linked,
  onAddFamilyLink,
  onGetVisaApplication,
  visaApp,
  onRemoveFamilyLink,
  linkedMember,
}) => {
  const [linkMember, setLinkMember] = useState(linked);
  var visaTypeOptions = [];
  var categoryOptions = [];

  const addFamilyLink = (e, subjectCaseId, memberId) => {
    let userId = localStorage.getItem("userId");
    let data = {
      subjectCaseId: subjectCaseId,
      clientId: memberId,
    };
    onAddFamilyLink(data).then((res) => {
      setLinkMember(e.target.checked);
      message.success("Member linked successfully");
      onGetVisaApplication();
    });
  };

  const removeFamilyLink = (e) => {
    let userId = localStorage.getItem("userId");
    let data = {
      id: linkedMember.id,
      delete: true,
    };
    onRemoveFamilyLink(data)
      .then((res) => {
        setLinkMember(e.target.checked);
        message.success("Member unlinked successfully");
        onGetVisaApplication();
      })
      .catch((err) => {
        message.error("Failed to unlinked member");
      });
  };

  const onChangeFamilyCheck = (e, subjectCaseId, memberId) => {
    if (e.target.checked) {
      addFamilyLink(e, subjectCaseId, memberId);
    } else {
      removeFamilyLink(e);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div class="cm-profile-fill-cont" style={{ marginLeft: 20 }}>
          <img src={Images.personFill} class="cm-profile-fill" />
        </div>
        <div className="cv-lf-checkbox-label-cont">
          <span className="sus-checkbox-label">
            {member.firstName + " " + member.lastName}
          </span>
        </div>
        <div>
          <Checkbox
            checked={linkMember}
            onChange={(e) => onChangeFamilyCheck(e, visaApp.id, member.id)}
          ></Checkbox>
        </div>
      </div>
    </Fragment>
  );
};

export default LinkMemberCard;
