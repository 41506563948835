import { api } from "../../services/api";
import * as types from "./../Constants";

export const getDocumentType = () => {
  const options = {
    url: `v1/document/type/All`,
  };
  options.types = [
    types.GET_DOCUMENT_TYPE_SUCCESS,
    types.GET_DOCUMENT_TYPE_FAILURE,
  ];

  return api.get(options);
};

export const addDocumentType = (data) => {
  const options = {
    url: "v1/document/type",
  };
  options.types = [
    types.ADD_DOCUMENT_TYPE_SUCCESS,
    types.ADD_DOCUMENT_TYPE_FAILURE,
  ];

  return api.post(options, data);
};

export const removeDocumentType = (data) => {
  const options = {
    url: "v1/document/type",
  };
  options.types = [
    types.DELETE_DOCUMENT_TYPE_SUCCESS,
    types.DELETE_DOCUMENT_TYPE_FAILURE,
  ];

  return api.delete(options, data);
};

export const updetaDocumentType = (data) => {
  const options = {
    url: "v1/document/type",
  };
  options.types = [
    types.PUT_DOCUMENT_TYPE_SUCCESS,
    types.PUT_DOCUMENT_TYPE_FAILURE,
  ];

  return api.put(options, data);
};
