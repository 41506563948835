// @flow
import * as React from "react";
import styled from "styled-components";
import "./HeaderBarStyles.css";
import logo from "../../images/logo.png";
import Select from "react-select";
import navMenu from "../../images/nav-collaps.png";
import { Images } from "../../Themes";
import { Link } from "react-router-dom";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const HeaderBarTabs = (Props) => {
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/profile">CLIENT PROFILE</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/partner-profile">PARTNER DETAILS</Link>
      </Menu.Item>
      {/* <Menu.Divider /> */}
      <Menu.Item key="2">
        <Link to="/client-family-member">FAMILY DETAILS</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="header-bar">
        {Props.data &&
          Props.data.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  opacity:
                    (item.tabName == "Questionnaire" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "customquestionnaire"
                      ).status) ||
                    (item.tabName == "Open Case Management" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "casemanagement"
                      ).status) ||
                    (item.tabName == "Admission" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "school"
                      ).status)
                      ? 0.7
                      : 1,
                }}
              >
                <Link
                  to={
                    (item.tabName == "Questionnaire" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "customquestionnaire"
                      ).status) ||
                    (item.tabName == "Open Case Management" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "casemanagement"
                      ).status) ||
                    (item.tabName == "Admission" &&
                      localStorage.getItem("userBranchPermissions") &&
                      !JSON.parse(
                        decodeURIComponent(
                          escape(
                            window.atob(
                              localStorage.getItem("userBranchPermissions")
                            )
                          )
                        )
                      ).branchPermission.find(
                        (x) => x.name.toLowerCase() == "school"
                      ).status)
                      ? "/not-allowed"
                      : item.linkName
                  }
                  style={{ textDecoration: "none", color: "#FFFFFF" }}
                >
                  <div
                    className="header-bar-text-div"
                    style={{
                      backgroundColor:
                        Props.activeTab == item.tabName
                          ? "#33aada"
                          : "transparent",
                      paddingTop:
                        item.tabName == "Open Case Management" ? 15 : 12,
                      paddingBottom:
                        item.tabName == "Open Case Management" ? 15 : 12,
                      paddingLeft: Props.activeTab == item.tabName ? 8 : 5,
                      paddingRight: Props.activeTab == item.tabName ? 8 : 5,
                    }}
                  >
                    {item.imageName && (
                      <img
                        src={item.imageName}
                        className="header-bar-image"
                        style={{
                          width:
                            item.tabName == "Open Case Management" ? 12 : 18,
                          height:
                            item.tabName == "Open Case Management" ? 12 : 18,
                        }}
                      />
                    )}
                    <span
                      className="header-text"
                      style={{
                        fontWeight:
                          Props.activeTab == item.tabName ? "500" : "300",
                      }}
                    >
                      {item.tabName}
                    </span>

                    {(item.tabName == "Client Profile" ||
                      item.tabName == "Partner Detail" ||
                      item.tabName == "Family Details") && (
                      <div className="header-downarrow-cont">
                        <Dropdown
                          placement="bottomRight"
                          overlay={menu}
                          trigger={["click"]}
                        >
                          <a
                            className="ant-dropdown-link"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: 16,
                              height: 16,
                            }}
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              src={Images.rightArrow}
                              style={{
                                width: 8,
                                height: 8,
                                transform: `rotate(90deg)`,
                              }}
                            />
                          </a>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default HeaderBarTabs;
