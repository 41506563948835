import React, { Fragment } from "react";
import { connect } from "react-redux";
import HeaderBar from "../Components/Header/HeaderBar";
import "./AccountSettingStyles.css";

// import Modal from "react-awesome-modal";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import DefaultFontSettings from "../Containers/DefaultFontSettings";
import {
  setActiveInnerTab,
  getGroups,
  addGroup,
  updGroup,
  deleteGroup,
  getUserDetail,
  getGmailMail,
  gmailLogin,
  addGmailAccount,
  removeGmailAccount,
  getPermissonUser,
  getGroupMembers,
  getHubspotToken,
  hubspotLogin,
  addHubspotAccount,
  removeHubspotAccount,
} from "../store/Actions";
import { bindActionCreators } from "redux";
import SignatureSettings from "../Containers/SignatureSettings";
import ImapSettings from "../Containers/ImapSettings";
import DocumentView from "../Containers/DocumentView";
import DailyEmailSettings from "../Containers/DailyEmailSettings";
import CalenderSync from "../Containers/CalendarSync";
import {
  CmvFAQs,
  DocumentSync,
  PotentialClientStatus,
  VisaStatus,
  VisaType,
  OutlookIntegration,
  IntegrateGmail,
  ApiKey,
  EzyformKey,
} from "../Containers";
import TimeZoneSettings from "../Containers/TimeZoneSettings";
import EmailContent from "../Containers/EmailContent";
import LetterTemplates from "../Containers/LetterTemplates";
import ContractSettings from "../Containers/ContractSettings";
import SettingFileNotes from "../Containers/SettingFileNotes";
import EzmCommonDocs from "../Containers/EzmCommonDocs";
import DocumentChecklist from "../Containers/DocumentChecklist";
import VisaUpdateNotification from "../Containers/VisaUpdateNotification";
import ReminderSetting from "../Containers/ReminderSetting";
import DocumentTypes from "../Containers/DocumentTypes";
import AccountSettings from "../Containers/AccountSettings";
import CheckMyVisaDocs from "../Containers/CheckMyVisaDocs";
import AgentPortalDocuments from "../Containers/DocumentsSettings";
import QuestionnaireSettings from "../Containers/QuestionnaireSettings";
import TimeTracking from "../Containers/TimeTracking";
import MailChimp from "../Containers/MailChimp";
import OrganizationSetting from "./OrganizationSetting/OrganizatinSetting";

import { message, Button, Spin, Form, Select, Modal, Input } from "antd";
import {
  ArrowUpOutlined,
  LeftCircleFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import ContractEmail from "../Containers/ContractEmail";
import GmailIntegration from "./UserLevelSettings/ImapSettings/GmailIntegration";
import VisaBranchCountry from "../Containers/VisaBranchCountry";
import SendGridKey from "../SuperUserSetting/SendGridKey";
import CoverGeniusToken from "../Containers/CoverGeniusToken";
import AllMails from "../Containers/AllMails";
import DocumentSettings from "../Containers/DocumentSettings";
import EmailImportSettings from "../Containers/EmailImportSettings";
import CustomMessage from "../Containers/CustomMessage";
import PurchaseSubscription from "../Common/PurchaseMessage";
import { apiRefresh } from "../services/api";
import AutoEmailImport from "./UserLevelSettings/AutoEmailImportSetting/AutoEmailImportSetting";
import HubspotIntegration from "./UserLevelSettings/HubspotIntegration";
import HubspotSettings from "./UserLevelSettings/HubspotSettings/HubspotSettings";
import ClientProfileSetting from "./ClientProfileSetting/ClientProfileSetting";
import AddCustomFields from "../Components/Client/AddCustomfields";

const { Option } = Select;

class AccountSetting extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      activeAccTab:
        localStorage.getItem("userOwner") == "True" ||
        !JSON.parse(localStorage.getItem("userManager"))
          ? "userLevelSetting"
          : localStorage.getItem("userOwner") == "True" ||
            JSON.parse(localStorage.getItem("userManager"))
          ? "companyLevelSetting"
          : "organizationLevelSetting",
      activeInnerTab: "",
      groupsState: [],
      visible: false,
      groupName: "",
      groupEmail: "",
      isUpdateGroup: false,
      addGroupLoading: false,
      deleteGroupLoadng: false,
      loading: false,
      personsOpt: [],
      isAddGroup: false,
      groupMembers: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeInnerTabValue != this.props.activeInnerTabValue) {
      this.setState({ activeInnerTab: this.props.activeInnerTabValue });
    }
    if (prevProps.groupsData != this.props.groupsData) {
      this.setState({
        groupsState: this.props.groupsData.items,
      });
    }
  }

  onChangeTab = (value) => {
    this.setState({ activeAccTab: value });
    this.props.onSetActiveInnerTab("");
  };

  onChangeInner = (value) => {
    this.setState({ activeInnerTab: value });
    this.props.onSetActiveInnerTab(value);
    if (value === "processingPersonGroup") {
      this.props.onGetGroups().then((res) => {});
      const branchUserOpt = {
        url: `v1/user/Branch/users/${localStorage.getItem("selectedBranchId")}`,
      };
      branchUserOpt.types = [
        "GET_BRANCH_USER_SUCCESS",
        "GET_BRANCH_USER_FAILURE",
      ];
      apiRefresh.get(branchUserOpt).then((res) => {
        //  this.setState({ users: res.payload.users });
        let persons = [];
        if (res && res.length > 0) {
          // <Option key=>Select Users</Option>;
          for (let i = 0; i < res.length; i++) {
            persons.push(
              <Option key={res[i].user.id}>{res[i].user.fullName}</Option>
            );
          }
          this.setState({ personsOpt: persons });
        }
      });
    }
    if (value === "imapSetting") {
      this.props.history.push("/account-setting");
    }
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  openUpdatePopup = (group) => {
    this.setState({
      groupId: group.id,
      groupName: group.name,
      groupEmail: group.email,
      isUpdateGroup: true,
      loading: true,
    });
    this.getGrpMembers(group.id);
    this.openModal();
  };

  getGrpMembers = (groupId) => {
    this.props.onGetGroupMembers(groupId).then((res) => {
      this.setState({
        loading: false,
        groupMembers: res.payload && res.payload.items,
      });
      let members = [];
      if (res.payload && res.payload.items.length > 0) {
        for (var i = 0; i < res.payload.items.length; i++) {
          members.push(res.payload.items[i].userId);
        }
        this.formRef.current.setFieldsValue({
          groupMembers: members,
        });
      }
    });
  };

  updateGroup = (values) => {
    var companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.groupName.length < 3) {
      message.warning("Group name should be more than three characters!");
    } else if (!emailRegex.test(this.state.groupEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else {
      this.setState({ addGroupLoading: true });
      let data = {
        id: this.state.groupId,
        name: this.state.groupName,
        email: this.state.groupEmail,
      };
      this.setState({ loading: true });

      this.props
        .onUpdateGroup(data)
        .then(() => {
          if (values.groupMembers) {
            for (var i = 0; i < values.groupMembers.length; i++) {
              if (this.state.groupMembers[i] && this.state.groupMembers[i].id) {
                let dataPP = {
                  id: this.state.groupMembers[i].id,
                  userId: values.groupMembers[i],
                  groupId: this.state.groupId,
                  sendEmail: false,
                };
                var addProcessingPersonOpt = {
                  url: `v1/group/member`,
                };

                addProcessingPersonOpt.types = [
                  "UPD_PROCESSING_PERSON_SUCCESS",
                  "UPD_PROCESSING_PERSON_FAILURE",
                ];
                apiRefresh.put(addProcessingPersonOpt, dataPP).then((res) => {
                  this.formRef.current.resetFields();
                });
              } else {
                let dataPP = {
                  userId: values.groupMembers[i],
                  groupId: this.state.groupId,
                  sendEmail: false,
                };
                var addProcessingPersonOpt = {
                  url: `v1/group/member`,
                };

                addProcessingPersonOpt.types = [
                  "ADD_PROCESSING_PERSON_SUCCESS",
                  "ADD_PROCESSING_PERSON_FAILURE",
                ];
                apiRefresh.post(addProcessingPersonOpt, dataPP).then((res) => {
                  this.formRef.current.resetFields();
                });
              }
            }
          }
          this.closeModal();
          this.setState({
            groupName: "",
            groupEmail: "",
            isUpdateGroup: false,
            addGroupLoading: false,
          });
          message.success("Processing person updated successfully!");
          this.props
            .onGetGroups()
            .then((res) => {
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.setState({
                loading: false,
              });
            });
        })
        .catch((err) => {
          this.setState({ addGroupLoading: false, loading: false });
          message.error("Update processing person failed!");
        });
    }
  };

  addGroup = (values) => {
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.groupName.length < 3) {
      message.warning("Group name should be more than three characters!");
    } else if (!emailRegex.test(this.state.groupEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else {
      this.setState({ addGroupLoading: true });
      var companyId = localStorage.getItem("companyId");
      let data = {
        name: this.state.groupName,
        email: this.state.groupEmail,
      };

      this.setState({ loading: true });

      this.props
        .onAddGroup(data)
        .then((res) => {
          this.closeModal();
          this.setState({
            groupName: "",
            groupEmail: "",
            addGroupLoading: false,
          });
          message.success("Processing person added successfully");
          if (values.groupMembers) {
            for (var i = 0; i < values.groupMembers.length; i++) {
              let dataPP = {
                userId: values.groupMembers[i],
                groupId: res.payload.id,
                sendEmail: false,
              };
              var addProcessingPersonOpt = {
                url: `v1/group/member`,
              };

              addProcessingPersonOpt.types = [
                "ADD_PROCESSING_PERSON_SUCCESS",
                "ADD_PROCESSING_PERSON_FAILURE",
              ];
              apiRefresh.post(addProcessingPersonOpt, dataPP).then((res) => {
                this.formRef.current.resetFields();
              });
            }
          }
          this.props
            .onGetGroups()
            .then((res) => {
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
            });
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Add processing person failed!");
        });
    }
  };

  deleteGroup = (group) => {
    this.setState({ deleteGroupLoadng: true });
    let data = {
      id: group.id,
      delete: true,
    };

    this.setState({ loading: true });

    this.props
      .onDeleteGroup(data)
      .then(() => {
        this.setState({ deleteGroupLoadng: false });
        message.success("Processing person deleted successfully");
        this.props.onGetGroups().then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Processing person failed on delete!");
      });
  };

  render() {
    const {
      activeInnerTab,
      activeAccTab,
      groupsState,
      groupName,
      groupEmail,
      isUpdateGroup,
      addGroupLoading,
      loading,
    } = this.state;
    const { onSetActiveInnerTab, activeInnerTabValue, groupsData } = this.props;
    var userOwner = localStorage.getItem("userOwner");
    var userManager = JSON.parse(localStorage.getItem("userManager"));

    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="sus-tab-container">
                <div
                  onClick={() => this.onChangeTab("userLevelSetting")}
                  className={
                    activeAccTab == "userLevelSetting"
                      ? "sus-active-tab"
                      : "sus-inactive-tab"
                  }
                >
                  <span
                    className={
                      activeAccTab == "userLevelSetting"
                        ? "sus-active-tab-text"
                        : "sus-inactive-tab-text"
                    }
                  >
                    User Level Setting
                  </span>
                </div>

                {userOwner == "True" || userManager === "true" ? (
                  <div
                    onClick={() => this.onChangeTab("companyLevelSetting")}
                    className={
                      activeAccTab == "companyLevelSetting"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        activeAccTab == "companyLevelSetting"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Company/Branch Level Setting
                    </span>
                  </div>
                ) : ((JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userSystemPermissions")
                        )
                      )
                    )
                  ).find(
                    (x) => x.role.toLowerCase() === "company level settings"
                  ) &&
                    JSON.parse(
                      decodeURIComponent(
                        escape(
                          window.atob(
                            localStorage.getItem("userSystemPermissions")
                          )
                        )
                      )
                    ).find(
                      (x) => x.role.toLowerCase() === "company level settings"
                    ).status === 1) ||
                    (!JSON.parse(
                      decodeURIComponent(
                        escape(
                          window.atob(
                            localStorage.getItem("userSystemPermissions")
                          )
                        )
                      )
                    ).find(
                      (x) => x.role.toLowerCase() === "company level settings"
                    ) &&
                      true)) &&
                  (userOwner !== "True" || userManager !== "true") ? (
                  <div
                    onClick={() => this.onChangeTab("companyLevelSetting")}
                    className={
                      activeAccTab == "companyLevelSetting"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        activeAccTab == "companyLevelSetting"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Company/Branch Level Setting
                    </span>
                  </div>
                ) : null}
                {userOwner == "True" && (
                  <div
                    onClick={() => this.onChangeTab("organizationLevelSetting")}
                    className={
                      activeAccTab == "organizationLevelSetting"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        activeAccTab == "organizationLevelSetting"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Organization Level Setting
                    </span>
                  </div>
                )}
              </div>
              <div className="as-container">
                {activeAccTab == "userLevelSetting" && activeInnerTab == "" && (
                  <div style={{ display: "inline-block" }}>
                    {/* <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("autoImport")}
                      >
                        <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.important}
                            className="sus-bottom-icon"
                          />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Auto Import Settings
                        </span>
                      </div> */}

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("signature")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Signature</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("documentView")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.documentView}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Document View</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("imapSetting")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img src={Images.imap} className="sus-bottom-icon" />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>IMAP</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("dailyEmail")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.dailyMailSetting}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Daily Mail Setting
                        </span>
                      </div>
                    </div>

                    {/*<div*/}
                    {/*  className="acc-set-btn-cont"*/}
                    {/*  onClick={() => this.onChangeInner("timeZone")}*/}
                    {/*>*/}
                    {/*  <div className="sus-bottom-icon-cont">*/}
                    {/*    <img*/}
                    {/*      src={Images.timeZone}*/}
                    {/*      className="sus-bottom-icon"*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <span style={{ marginLeft: 20 }}>Time Zone</span>*/}
                    {/*</div>*/}
                    {localStorage.getItem("userBranchPermissions") &&
                    JSON.parse(
                      decodeURIComponent(
                        escape(
                          window.atob(
                            localStorage.getItem("userBranchPermissions")
                          )
                        )
                      )
                    ).branchPermission.find(
                      (x) => x.name.toLowerCase() == "timetracking"
                    ).status ? (
                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("timeTracking")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.timeTracking}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span style={{ marginLeft: 20 }}>Time Tracking</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("notAllowed")}
                        style={{ opacity: 0.7 }}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.timeTracking}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span style={{ marginLeft: 20 }}>Time Tracking</span>
                        </div>
                      </div>
                    )}

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("documentSettings")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.dailyMailSetting}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Document Settings
                        </span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("autoImportOn")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.documentSync}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Auto Email Import Setting
                        </span>
                      </div>
                    </div>
                    {/* <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("hubspotOn")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Hubspot Setting</span>
                      </div>
                    </div> */}
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("outlookIntegration")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.outlookInt}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Outlook Integration
                        </span>
                      </div>
                    </div>

                    {/* <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("allMailsImport")}
                      >
                        <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.apiKeyIcon}
                            className="sus-bottom-icon"
                          />
                        </div>
                        <span style={{ marginLeft: 20 }}>All Mails Import</span>
                      </div> */}
                    {/* {(localStorage.getItem("userId") ===
                      "f0341b6d-71ac-4fd4-b0d4-59ccfb12a759" ||
                      localStorage.getItem("userId") ===
                        "a0f31bc7-2066-413b-8f27-8b15a9a82eff") && ( */}
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("gmailIntegration")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Gmail Integration
                        </span>
                      </div>
                    </div>
                    {/* )} */}

                    {/* <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("documentSync")}
                      >
                        <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.documentSync}
                            className="sus-bottom-icon"
                          />
                        </div>
                        <span style={{ marginLeft: 20 }}>Document Sync</span>
                      </div> */}

                    {/* <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("calendar")}
                      >
                        <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.calendarBlue}
                            className="sus-bottom-icon"
                          />
                        </div>
                        <span style={{ marginLeft: 20 }}>Calendar</span>
                      </div> */}
                  </div>
                )}
                {activeAccTab == "companyLevelSetting" && activeInnerTab == "" && (
                  <div style={{ display: "inline-block" }}>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("emailContent")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.emailContent}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Email Content</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("letterTemplate")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.letterTemplate}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Letter Templates</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("contract")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.contract}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Contracts</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("fileNotes")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.fileNotes}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>File Notes</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("cmvFAQs")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.fileNotes}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Check MyVisa FAQs
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("ezmDocs")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.creativeCommons}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>EZM Common Docs</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("documentChecklist")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.docChecklist}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Document Checklist
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("visaUpdNotification")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.visaUpdateNotification}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Visa Update Notification
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("reminderSettings")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.reminders}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Reminder Settings
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("ezyformApiKey")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.apiKeyIcon}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Ezyforms API Key</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("documentType")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.docType}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Document Types</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("contractEmail")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.clientTags}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Contract Email</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("clientTags")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.clientTags}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Client Tags</span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("checkMyVisaDocs")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.myVisaDoc}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Check MyVisa Docs
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("agentPortal")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.agentDoc}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Agent Portal Documents
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() =>
                        this.onChangeInner(
                          localStorage.getItem("userBranchPermissions") &&
                            JSON.parse(
                              decodeURIComponent(
                                escape(
                                  window.atob(
                                    localStorage.getItem(
                                      "userBranchPermissions"
                                    )
                                  )
                                )
                              )
                            ).branchPermission.find(
                              (x) =>
                                x.name.toLowerCase() == "customquestionnaire"
                            ).status
                            ? "questionnaireSettings"
                            : "notAllowed"
                        )
                      }
                      style={{
                        opacity:
                          localStorage.getItem("userBranchPermissions") &&
                          JSON.parse(
                            decodeURIComponent(
                              escape(
                                window.atob(
                                  localStorage.getItem("userBranchPermissions")
                                )
                              )
                            )
                          ).branchPermission.find(
                            (x) => x.name.toLowerCase() == "customquestionnaire"
                          ).status
                            ? 1
                            : 0.7,
                      }}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.questionnaireIcon}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Questionnaire Settings
                        </span>
                      </div>
                    </div>

                    <div
                      className="acc-set-btn-cont"
                      onClick={() =>
                        this.onChangeInner("processingPersonGroup")
                      }
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.organizationSetting}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Processing Person Group
                        </span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() =>
                        this.onChangeInner(
                          localStorage.getItem("userBranchPermissions") &&
                            JSON.parse(
                              decodeURIComponent(
                                escape(
                                  window.atob(
                                    localStorage.getItem(
                                      "userBranchPermissions"
                                    )
                                  )
                                )
                              )
                            ).branchPermission.find(
                              (x) =>
                                x.name.toLowerCase() == "customquestionnaire"
                            ).status
                            ? "apiKey"
                            : "notAllowed"
                        )
                      }
                      style={{
                        opacity:
                          localStorage.getItem("userBranchPermissions") &&
                          JSON.parse(
                            decodeURIComponent(
                              escape(
                                window.atob(
                                  localStorage.getItem("userBranchPermissions")
                                )
                              )
                            )
                          ).branchPermission.find(
                            (x) => x.name.toLowerCase() == "customquestionnaire"
                          ).status
                            ? 1
                            : 0.7,
                      }}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.apiKeyIcon}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>API Key</span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("emailImportSetting")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.apiKeyIcon}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Email Import Setting
                        </span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("customMessage")}
                    >
                      {/* <div className="sus-bottom-icon-cont">
                          <img
                            src={Images.apiKeyIcon}
                            className="sus-bottom-icon"
                          />
                        </div> */}
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>Custom Message</span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("hubspotIntegration")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Hubspot Integration
                        </span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("clientProfileSetting")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Client Profile Setting
                        </span>
                      </div>
                    </div>
                    <div
                      className="acc-set-btn-cont"
                      onClick={() => this.onChangeInner("addCustomFields")}
                    >
                      <div className="acc-tab-flex">
                        <div className="arrow-round-cont">
                          <ArrowUpOutlined className="arrow-color-size" />
                        </div>
                        <span style={{ marginLeft: 20 }}>
                          Client Profile Custom Fields
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {activeAccTab == "organizationLevelSetting" &&
                  activeInnerTab == "" && (
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("visaCountry")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.visaCountry}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            Visa Country
                          </span>
                        </div>
                      </div>
                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("visaTypes")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.visaType}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            Visa Types
                          </span>
                        </div>
                      </div>

                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("visaStatus")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.visaStatus}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            Visa Statuses
                          </span>
                        </div>
                      </div>
                      <div
                        className="acc-set-btn-cont"
                        onClick={() =>
                          this.onChangeInner("potentialClientStatus")
                        }
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.potentialClients}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            Potential Client Statuses
                          </span>
                        </div>
                      </div>

                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("mailChimp")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.mailChimp}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span style={{ marginLeft: 20 }}>Mail Chimp</span>
                        </div>
                      </div>

                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("sendGridKey")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.SendGrid}
                              className="sus-bottom-icon"
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            SendGrid
                          </span>
                        </div>
                      </div>
                      <div
                        className="acc-set-btn-cont"
                        onClick={() => this.onChangeInner("coverGenius")}
                      >
                        {/* <div className="sus-bottom-icon-cont">
                            <img
                              src={Images.coverLetterView}
                              className="sus-bottom-icon"
                              style={{ width: 50 }}
                            />
                          </div> */}
                        <div className="acc-tab-flex">
                          <div className="arrow-round-cont">
                            <ArrowUpOutlined className="arrow-color-size" />
                          </div>
                          <span
                            className="sus-bottom-text"
                            style={{ fontSize: 14, marginLeft: 20 }}
                          >
                            Cover Genius Token
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                {activeInnerTab == "autoImport" && <DefaultFontSettings />}
                {activeInnerTab == "signature" && <SignatureSettings />}
                {activeInnerTab == "documentView" && <DocumentView />}
                {activeInnerTab == "dailyEmail" && <DailyEmailSettings />}
                {activeInnerTab == "documentSettings" && <DocumentSettings />}
                {activeInnerTab == "calendar" && <CalenderSync />}
                {activeInnerTab == "autoImportOn" && (
                  <AutoEmailImport onSetActiveInnerTab={onSetActiveInnerTab} />
                )}
                {/* {activeInnerTab == "hubspotOn" && (
                  <HubspotSettings onSetActiveInnerTab={onSetActiveInnerTab} />
                )} */}
                {activeInnerTab == "outlookIntegration" && (
                  <OutlookIntegration />
                )}
                {activeInnerTab == "gmailIntegration" && (
                  <GmailIntegration
                    getGmailMail={this.props.getGmailMail}
                    addGmailAccount={this.props.addGmailAccount}
                    gmailLogin={this.props.gmailLogin}
                    removeGmailAccount={this.props.removeGmailAccount}
                  />
                )}
                {activeInnerTab == "hubspotIntegration" && (
                  <HubspotIntegration
                    getHubspotToken={this.props.getHubspotToken}
                    addHubspotAccount={this.props.addHubspotAccount}
                    hubspotLogin={this.props.hubspotLogin}
                    removeHubspotAccount={this.props.removeHubspotAccount}
                  />
                )}
                {activeInnerTab == "clientProfileSetting" && (
                  <ClientProfileSetting
                    onSetActiveInnerTab={onSetActiveInnerTab}
                  />
                )}
                {activeInnerTab == "addCustomFields" && (
                  <AddCustomFields onSetActiveInnerTab={onSetActiveInnerTab} />
                )}

                {activeInnerTab == "timeZone" && <TimeZoneSettings />}
                {activeInnerTab == "emailContent" && <EmailContent />}
                {activeInnerTab == "letterTemplate" && <LetterTemplates />}
                {activeInnerTab == "contract" && <ContractSettings />}
                {activeInnerTab == "contractEmail" && <ContractEmail />}
                {activeInnerTab == "fileNotes" && <SettingFileNotes />}
                {activeInnerTab == "cmvFAQs" && <CmvFAQs />}
                {activeInnerTab == "ezmDocs" && <EzmCommonDocs />}
                {activeInnerTab == "documentChecklist" && <DocumentChecklist />}
                {activeInnerTab == "visaUpdNotification" && (
                  <VisaUpdateNotification />
                )}
                {activeInnerTab == "reminderSettings" && <ReminderSetting />}
                {activeInnerTab == "apiKey" && <ApiKey />}
                {activeInnerTab == "emailImportSetting" && (
                  <EmailImportSettings />
                )}
                {activeInnerTab == "customMessage" && <CustomMessage />}
                {activeInnerTab == "ezyformApiKey" && <EzyformKey />}
                {activeInnerTab == "allMailsImport" && <AllMails />}
                {activeInnerTab == "documentType" && <DocumentTypes />}
                {activeInnerTab == "clientTags" && <AccountSettings />}
                {activeInnerTab == "checkMyVisaDocs" && <CheckMyVisaDocs />}
                {activeInnerTab == "agentPortal" && <AgentPortalDocuments />}
                {activeInnerTab == "questionnaireSettings" && (
                  <QuestionnaireSettings />
                )}
                {activeInnerTab == "timeTracking" && <TimeTracking />}
                {activeInnerTab == "notAllowed" && <PurchaseSubscription />}
                {activeInnerTab == "mailChimp" && <MailChimp />}
                {activeInnerTab == "visaTypes" && <VisaType />}
                {activeInnerTab == "visaStatus" && <VisaStatus />}
                {activeInnerTab == "potentialClientStatus" && (
                  <PotentialClientStatus />
                )}
                {activeInnerTab == "visaCountry" && <VisaBranchCountry />}
                {activeInnerTab == "sendGridKey" && (
                  <SendGridKey onSetActiveInnerTab={onSetActiveInnerTab} />
                )}
                {activeInnerTab == "coverGenius" && <CoverGeniusToken />}
                {activeInnerTab == "processingPersonGroup" && (
                  <div style={{ paddingLeft: 20, paddingLeft: 20 }}>
                    <div
                      className="d-flex align-item Reminder-top"
                      style={{
                        justifyContent: "space-between",
                        paddingBottom: 30,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <div className="d-flex align-item">
                        <div className="client-tag-top">
                          <img
                            src={Images.reminders}
                            className="sus-bottom-icon"
                          />
                        </div>
                        <span className="top-text">
                          PROCESSING PERSON GROUPS
                        </span>
                        {/* <Tooltip
                        placement="topLeft"
                        title={`Select when you want Ezymigrate to send you a reminder about the specific documents expiry dates below.`}
                      >
                        <img className="ac-info-icon" src={Images.info} />
                      </Tooltip> */}
                      </div>
                      <LeftCircleFilled
                        onClick={() => onSetActiveInnerTab("")}
                        className="ac-back-icon"
                      />
                    </div>
                    <div
                      className="sus-form-container"
                      style={{
                        marginLeft: 0,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#D9D9D9",
                        width: "100%",
                      }}
                    >
                      <div className="sus-add-cont-row">
                        <div></div>
                        <div
                          onClick={() => {
                            this.openModal();
                            this.setState({ isAddGroup: true });
                          }}
                        >
                          <img src={Images.plusIcon} style={{ width: 20 }} />
                        </div>
                      </div>

                      <div style={{ marginTop: 35 }}>
                        <div className="sus-table">
                          <div className="sus-table-header">
                            <div className="sus-head-text-cont">
                              <span className="sus-head-text">Name</span>
                            </div>
                            <div
                              className="sus-head-text-cont"
                              style={{ width: 150 }}
                            >
                              <span className="sus-head-text">Email</span>
                            </div>
                            <div className="sus-head-text-cont">
                              <span className="sus-head-text">Action</span>
                            </div>
                          </div>
                          {groupsState.length > 0 &&
                            groupsState.map((item, index) => {
                              return (
                                <div className="sus-table-content">
                                  <div className="sus-head-text-cont">
                                    <span className="sus-content-text">
                                      {item.name}
                                    </span>
                                  </div>
                                  <div
                                    className="sus-head-text-cont"
                                    style={{ width: 150 }}
                                  >
                                    <span className="sus-content-text">
                                      {item.email}
                                    </span>
                                  </div>
                                  <div
                                    className="sus-head-text-cont"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => {
                                        this.setState({ isAddGroup: false });
                                        this.openUpdatePopup(item);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={Images.editBorderBlue}
                                        style={{ width: 15 }}
                                      />
                                    </div>
                                    <div
                                      onClick={() => this.deleteGroup(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={Images.deleteIcon}
                                        style={{
                                          marginLeft: 5,
                                          width: 17,
                                          height: 17,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <Modal
                  visible={this.state.visible}
                  // width="500"
                  // height="300"
                  style={{ width: 500 }}
                  effect="fadeInUp"
                  onCancel={() => this.closeModal()}
                  footer={null}
                >
                  <Form
                    ref={this.formRef}
                    style={{ padding: 40 }}
                    onFinish={(val) => {
                      this.state.isAddGroup
                        ? this.addGroup(val)
                        : this.updateGroup(val);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="sus-modal-label">
                        <span className="sus-modal-label-text">
                          PROCESSING PERSON GROUPS
                        </span>
                      </div>
                      {/* <div onClick={this.closeModal}>
                        <img src={Images.crossRed} style={{ width: 20 }} />
                      </div> */}
                    </div>
                    <div className="sus-add-processing-person-form">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          class="medical-label"
                          style={{ fontSize: 11, marginLeft: 15 }}
                        >
                          Group Name*:
                        </p>
                        <div
                          class="profile-input-border"
                          style={{ width: "60%" }}
                        >
                          <Form.Item
                            rules={[{ required: true, message: "Required!" }]}
                          >
                            <Input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={groupName}
                              onChange={(e) =>
                                this.setState({ groupName: e.target.value })
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 15,
                        }}
                      >
                        <p
                          class="medical-label"
                          style={{ fontSize: 11, marginLeft: 15 }}
                        >
                          Group Email*:
                        </p>
                        <div
                          class="profile-input-border"
                          style={{ width: "60%" }}
                        >
                          <Form.Item>
                            <Input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={groupEmail}
                              onChange={(e) =>
                                this.setState({ groupEmail: e.target.value })
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Form.List name="groupMembers">
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <div
                              className="sus-add-cont-row"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <span>Add Group Members</span>
                              </div>
                              <div
                                onClick={() => {
                                  add();
                                }}
                              >
                                <img
                                  src={Images.plusIcon}
                                  style={{ width: 20 }}
                                />
                              </div>
                            </div>
                            {fields.map((field, index) => (
                              <Form.Item required={false} key={field.key}>
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please select member.",
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Select
                                    placeholder="Select Member"
                                    style={{
                                      width: "60%",
                                    }}
                                  >
                                    {this.state.personsOpt}
                                  </Select>
                                </Form.Item>
                                {fields.length > 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => {
                                      if (
                                        this.state.groupMembers.length > 0 &&
                                        this.state.groupMembers[index] &&
                                        this.state.groupMembers[index].id
                                      ) {
                                        let data = {
                                          id: this.state.groupMembers[index].id,
                                          delete: true,
                                        };
                                        var remProcessingPersonOpt = {
                                          url: `v1/group/member`,
                                        };

                                        remProcessingPersonOpt.types = [
                                          "DEL_PROCESSING_PERSON_SUCCESS",
                                          "DEL_PROCESSING_PERSON_FAILURE",
                                        ];
                                        apiRefresh
                                          .delete(remProcessingPersonOpt, data)
                                          .then((res) => {
                                            remove(field.name);
                                            this.getGrpMembers(
                                              this.state.groupId
                                            );
                                          });
                                      } else {
                                        remove(field.name);
                                      }
                                    }}
                                    style={{ marginLeft: 10 }}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 30,
                      }}
                    >
                      <div></div>
                      <div style={{ display: "flex" }}>
                        <Form.Item>
                          <Button
                            // onClick={
                            //   isUpdateGroup
                            //     ? () => this.updateGroup()
                            //     : this.addGroup
                            // }
                            loading={addGroupLoading}
                            type="primary"
                            htmlType="submit"
                            className="sus-add-btn"
                          >
                            SAVE
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            onClick={this.closeModal}
                            type="primary"
                            className="sus-add-btn"
                            style={{ marginLeft: 10 }}
                          >
                            CLOSE
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </Modal>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
    groupsData: state.groupReducer.groupsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  onGetGroups: bindActionCreators(getGroups, dispatch),
  onAddGroup: bindActionCreators(addGroup, dispatch),
  onUpdateGroup: bindActionCreators(updGroup, dispatch),
  onDeleteGroup: bindActionCreators(deleteGroup, dispatch),
  onGetUserDetail: bindActionCreators(getUserDetail, dispatch),
  getGmailMail: bindActionCreators(getGmailMail, dispatch),
  gmailLogin: bindActionCreators(gmailLogin, dispatch),
  addGmailAccount: bindActionCreators(addGmailAccount, dispatch),
  removeGmailAccount: bindActionCreators(removeGmailAccount, dispatch),
  onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  onGetGroupMembers: bindActionCreators(getGroupMembers, dispatch),
  getHubspotToken: bindActionCreators(getHubspotToken, dispatch),
  hubspotLogin: bindActionCreators(hubspotLogin, dispatch),
  addHubspotAccount: bindActionCreators(addHubspotAccount, dispatch),
  removeHubspotAccount: bindActionCreators(removeHubspotAccount, dispatch),
});
AccountSetting = connect(mapStateToProps, mapDispatchToProps)(AccountSetting);

export default withRouter(AccountSetting);
