import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const questionnaireSetReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_QUESTIONNAIRE_SETTING_SUCCESS:
      return { ...state, questionnaireSetRes: action.payload };
    case types.GET_QUESTIONNAIRE_SETTING_SUCCESS:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_QUESTIONNAIRE_SETTING_SUCCESS:
      return { ...state, addQuestionnaireSetRes: action.payload };
    case types.PUT_QUESTIONNAIRE_SETTING_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
