import React, { Fragment, useState, useEffect } from "react";
import { Select, message, Button, DatePicker, Input, Form } from "antd";
import moment from "moment";

import activityData from "../ClientActivity/ActivityData";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const AddNewAdmission = ({
  schoolTypesData,
  onGetSchools,
  schoolsData,
  onGetSchoolLevel,
  schoolLevelData,
  onAddClientAdmission,
  onGetAdmissionProgram,
  onGetSchoolType,
  activeKey,
  onSetActiveKey,
  profileDataRdx,
}) => {
  const [schoolType, setSchoolType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [school, setSchool] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("");
  const [loading, setLoading] = useState(false);
  var schoolTypes = [];
  var schools = [];
  var schoolLevelOpt = [];

  useEffect(() => {
    if (activeKey === "4") {
      onGetSchoolType();
    }
  }, [activeKey]);
  if (schoolTypesData) {
    for (var i = 0; i < schoolTypesData.items.length > 0; i++) {
      schoolTypes.push(
        <Option value={schoolTypesData.items[i].id}>
          {schoolTypesData.items[i].name}
        </Option>
      );
    }
  }

  const [form] = Form.useForm();

  if (schoolsData) {
    for (var i = 0; i < schoolsData.items.length > 0; i++) {
      schools.push(
        <Option value={schoolsData.items[i].id}>
          {schoolsData.items[i].name}
        </Option>
      );
    }
  }

  if (schoolLevelData) {
    for (var i = 0; i < schoolLevelData.items.length > 0; i++) {
      schoolLevelOpt.push(
        <Option value={schoolLevelData.items[i].id}>
          {schoolLevelData.items[i].name}
        </Option>
      );
    }
  }

  const onChangeSchoolType = (value) => {
    console.log(`selected ${value}`);
    setSchoolType(value);
    onGetSchools(value);
  };

  const onChangeSchool = (value) => {
    console.log(`selected ${value}`);
    setSchool(value);
    onGetSchoolLevel(value);
  };

  const onChangeLevel = (value) => {
    console.log(`selected ${value}`);
    setSchoolLevel(value);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(date);
  };

  const onFinish = (values) => {
    var userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    setLoading(true);
    let userName = localStorage.getItem("userName");
    var profileData = profileDataRdx;
    if (clientprofileid && profileData) {
      let data = {
        clientId: clientprofileid,
        schoolId: values.school,
        programId: 0,
        programStatus: 8,
        description: values.description || "",
        appliedDate: "1900-01-01T00:00:00+00",
        addmissionDate: "1900-01-01T00:00:00+00",
        enrolledProgram: values.program,
        studentNo: values.studentNo || "",
        fee: parseInt(values.fee) || 0,
        hideCMS: false,
        date: "1900-01-01T00:00:00+00",
        caseStart: values.startDate,
        caseEnd: "1900-01-01T00:00:00+00",
        courseEffectiveDate: "1900-01-01T00:00:00+00",
        aipDate: "1900-01-01T00:00:00+00",
        aipSubmitted: false,
        visaApprovedDate: "1900-01-01T00:00:00+00",
        ftsSubmitted: false,
        ftsDate: "1900-01-01T00:00:00+00",
        visaId: 0,
        conditionalOfferDate: "1900-01-01T00:00:00+00",
        formalOfferDate: "1900-01-01T00:00:00+00",
        paidDate: "1900-01-01T00:00:00+00",
        declineDate: "1900-01-01T00:00:00+00",
        startDate: values.startDate || new Date(),
        deletedDate: "1900-01-01T00:00:00+00",
        commissionId: parseInt(values.schoolLevel),
        schoolTypeId: parseInt(values.schoolType),
      };
      var schoolType = schoolTypesData.items.find(
        (obj) => obj.id == values.schoolType
      );
      var findSchool =
        schoolsData && schoolsData.items.find((obj) => obj.id == values.school);
      var schoolName = findSchool ? findSchool.name : "";
      var schoolTypeName = "";
      if (schoolType) {
        schoolTypeName = schoolType.name;
      }
      onAddClientAdmission(data)
        .then((res) => {
          message.success("Admission created successfuly");
          onGetAdmissionProgram();
          setLoading(false);
          onSetActiveKey(null);
          form.resetFields();
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\n" +
              schoolName +
              " Admission with status Start started for " +
              schoolTypeName +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Admission",
            invoiceId: "0",
          };
          activityData(myData);
        })
        .catch((err) => {
          message.error("Failed to create admission");
          setLoading(false);
        });
    } else {
      message.warning("Please search and select client first!");
    }
  };

  return (
    <Fragment>
      <div className="form-container" style={{ marginLeft: 0, marginRight: 0 }}>
        {false && (
          <div
            style={{
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <Button className="cv-btn-blue">START APPLICATION</Button>
            <Button
              className="cv-btn-blue"
              style={{ backgroundColor: "#3FCDAD" }}
            >
              NEW APPLICATION (AUS)
            </Button>
          </div>
        )}
        <Form onFinish={onFinish} form={form}>
          <div style={{ padding: 10 }}>
            <Form.Item
              name="schoolType"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Select Type"
                style={{ width: "100%" }}
                onChange={onChangeSchoolType}
              >
                {schoolTypes}
              </Select>
            </Form.Item>
          </div>
          {schoolType && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <Form.Item
                name="school"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select School"
                  style={{ width: "100%" }}
                  onChange={onChangeSchool}
                >
                  {schools}
                </Select>
              </Form.Item>
            </div>
          )}
          {school && (
            <div style={{ padding: 10, paddingTop: 0 }}>
              <Form.Item
                name="schoolLevel"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Level"
                  style={{ width: "100%" }}
                  onChange={onChangeLevel}
                >
                  {schoolLevelOpt}
                </Select>
              </Form.Item>
            </div>
          )}
          <div style={{ padding: 10, paddingTop: 0 }}>
            <p>Program</p>
            <div class="profile-input-border">
              <Form.Item
                name="program"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input className="profile-input" placeholder="" type="text" />
              </Form.Item>
            </div>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <p>Fee</p>
            <div class="profile-input-border">
              <Form.Item name="fee">
                <Input className="profile-input" placeholder="" type="text" />
              </Form.Item>
            </div>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <p>Student No</p>
            <div class="profile-input-border">
              <Form.Item name="studentNo">
                <Input className="profile-input" placeholder="" type="text" />
              </Form.Item>
            </div>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <p>Description</p>
            <div class="profile-input-border">
              <Form.Item name="description">
                <Input className="profile-input" placeholder="" type="text" />
              </Form.Item>
            </div>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <p>Start Date :</p>
            <Form.Item name="startDate">
              <DatePicker onChange={onChange} format={dateFormat} />
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              className="button-blue"
              style={{ marginLeft: 10 }}
              htmlType="submit"
              loading={loading}
            >
              <span style={{ color: "#FFFFFF" }}>Save</span>
            </Button>
          </Form.Item>
          {/* <div className="button-blue-cont">
            <div className="button-blue" style={{ cursor: "pointer" }}>
              <span style={{ color: "#FFFFFF" }}>Save</span>
            </div>
          </div> */}
        </Form>
      </div>
    </Fragment>
  );
};

export default AddNewAdmission;
