// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Checkbox,
  Input,
  Radio,
} from "antd";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import { UpOutlined, DownOutlined } from "@ant-design/icons";

import UpdateQuestionCard from "./UpdateQuestionCard";
import UpdateTextArea from "./UpdateTextArea";
import Editor from "./Editor";

function UpdateSectionCard(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [sectionName, setSectionName] = useState("");
  const [disableAddFunc, setDisableAddFunc] = useState(false);
  const [sectionOption, setSectionOption] = useState(null);
  const [isNewQuestion, setNewQuestion] = useState(false);
  const [questionAdded, setQuestionAdded] = useState(false);
  const [addTextArray, setAddTextArray] = useState([]);
  const [addedQuestionsArray, setQuestionsArray] = useState(
    Props.sectionData ? Props.sectionData.questions : null
  );
  const [hideSection, setHideSection] = useState(false);

  useEffect(() => {
    if (Props.addQuestion) {
      setDisableAddFunc(true);
    }
    // if (Props.sectionOptions) {
    //   var findsecInd = Props.sectionOptions.find(
    //     obj =>
    //       obj.sectionInd ===
    //       (Props.subOptionalSection
    //         ? Props.sectionIndex
    //         : Props.optionalSection
    //         ? Props.sectionIndex
    //         : Props.mainSectionIndex)
    //   );
    //   if (findsecInd) {
    //     setSectionOption(findsecInd);
    //     // if(multiSections.length === 0) {
    //     //   setMultiSections(multiSections.concat(Props.sectionOptions))
    //     //
    //     // } else {
    //     //   var findSameIndex = multiSections.filter(obj => obj.mainQuestionIndex === findsecInd.mainQuestionIndex && obj.mainSectionIndex === findsecInd.mainSectionIndex)
    //     //   ;
    //     // }
    //     //
    //     Props.addSectionOptions(Props.sectionOptions);
    //   }
    // }
  }, [Props]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;

  const onDeleteSection = () => {
    if (!questionAdded) {
      // if (Props.optionsSectionArray.length > 0) {
      //   // var findOptSectionIndex = Props.optionsSectionArray.findIndex(
      //   //   obj =>
      //   //     obj.mainSectionIndex === Props.mainSectionIndex &&
      //   //     obj.mainQuestionIndex === Props.mainQuestionIndex &&
      //   //     obj.sectionInd === Props.optSectionIndex
      //   // );
      //   // var findOptSection = Props.optionsSectionArray.filter(
      //   //   obj =>
      //   //     obj.mainSectionIndex === Props.mainSectionIndex &&
      //   //     obj.mainQuestionIndex === Props.mainQuestionIndex &&
      //   //     obj.sectionInd === Props.optSectionIndex
      //   // );
      //   if (findOptSectionIndex > -1) {
      //     Props.removeOptionSection(findOptSectionIndex, findOptSection.length);
      //     // Props.optionsSectionArray.splice(findOptSectionIndex, findOptSection.length)
      //   }
      // }
      // var findSectionOption = Props.sectionOptions;
      // if (Props.sectionOptions && Props.sectionOptions.length > 0) {
      //   Props.sectionOptions.splice(Props.sectionIndex, 1);
      // }

      Props.sectionRemove(Props.sectionIndex);
    } else {
      message.warning("Please delete dependent questions first!");
    }
  };
  if (Props.sectionData && !isNewQuestion && addTextArray.length === 0) {
    // let textArray = []

    for (var i = 0; i < Props.sectionData.questions.length; i++) {
      if (Props.sectionData.questions[i].answerType === 0) {
        setAddTextArray((prevState) => [...prevState, { addText: true }]);
      } else {
        setAddTextArray((prevState) => [...prevState, { addText: false }]);
      }
    }
  }

  const addQuestion = (add) => {
    setAddTextArray(
      addTextArray.concat({
        addText: false,
      })
    );
    add();
  };

  const isHTML = (str) => {
    const fragment = document.createRange().createContextualFragment(str);

    // remove all non text nodes from fragment
    fragment
      .querySelectorAll("*")
      .forEach((el) => el.parentNode.removeChild(el));

    // if there is textContent, then not a pure HTML
    return !(fragment.textContent || "").trim();
  };

  return (
    <div
      className="cq-section-cont"
      style={{ backgroundColor: Props.optionalSection ? "#FFF7EC" : "#FFFFFF" }}
    >
      <div className="section-heading-row">
        <div style={{ display: "flex" }}>
          <div style={{ cursor: "pointer" }} onClick={onDeleteSection}>
            <img src={Images.deleteGray} style={{ width: 20, height: 20 }} />
          </div>

          <div
            onClick={() => {
              Props.sectionMove(Props.sectionIndex, Props.sectionIndex - 1);
            }}
            className="section-arrow-icons"
          >
            <UpOutlined />
          </div>

          <div
            onClick={() => {
              Props.sectionMove(Props.sectionIndex, Props.sectionIndex + 1);
            }}
            className="section-arrow-icons"
          >
            <DownOutlined />
          </div>

          <span className="heading-text" style={{ marginLeft: 20 }}>
            {Props.sectionOptions
              ? Props.sectionOptions + " "
              : Props.sectionData
              ? Props.sectionData.name
              : "Section"}
          </span>
        </div>
        {hideSection ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setHideSection(false)}
          >
            <span className="heading-text" style={{ fontSize: 11 }}>
              SHOW
            </span>
          </div>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setHideSection(true)}
          >
            <span className="heading-text" style={{ fontSize: 11 }}>
              HIDE
            </span>
          </div>
        )}
      </div>
      {!hideSection && (
        <div>
          {!Props.optionalSection && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                marginLeft: "10%",
              }}
            >
              <p className="cq-label-text">Section Name</p>
              <div style={{ width: "60%" }}>
                <Form.Item
                  {...Props.section}
                  name={[Props.section.name, "name"]}
                  fieldKey={[Props.section.fieldKey, "name"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter section name here!",
                    },
                  ]}
                >
                  <Input
                    className="profile-input profile-input-border"
                    onChange={(e) => setSectionName(e.target.value)}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <div style={{ marginLeft: "10%", marginTop: 20 }}>
            <Form.Item
              {...Props.section}
              name={[Props.section.name, "allowMultiple"]}
              fieldKey={[Props.section.fieldKey, "allowMultiple"]}
              valuePropName="checked"
            >
              <Checkbox>Is Multiple</Checkbox>
            </Form.Item>
          </div>

          <Form.List
            name={[Props.section.name, "questions"]}
            // rules={[
            //   {
            //     validator: async (_, names) => {
            //       if (!names || names.length < 2) {
            //         return Promise.reject(new Error('At least 2 passengers'));
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, { add, remove, move }, { errors }) => {
              if (fields.length > 0) {
                setQuestionAdded(true);
              } else {
                setQuestionAdded(false);
              }

              const moveAddTextArray = (arr, old_index, new_index) => {
                // ;
                while (old_index < 0) {
                  old_index += arr.length;
                  // old_index += Props.sectionData.questions.length;
                }
                while (new_index < 0) {
                  new_index += arr.length;
                }
                if (new_index >= arr.length) {
                  let k = new_index - arr.length;
                  while (k-- + 1) {
                    arr.push(undefined);
                  }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
                // ;
                setAddTextArray(arr);
                // ;
              };

              return (
                <>
                  {fields.map((field, index) => {
                    // ;
                    // if (Props.sectionData && Props.sectionData.questions.length <= index) {
                    //   var checkIsHTML = isHTML(Props.sectionData.questions[index].question);
                    // } else {
                    //   var checkIsHTML =
                    // }

                    return (
                      <Form.Item required={false} key={field.key}>
                        {addTextArray.length > 0 &&
                        addTextArray[index].addText ? (
                          // <Form.Item>
                          //   <Editor
                          //     width={"85%"}
                          //     onChange={changeContent}
                          //     value={content}
                          //   />
                          // </Form.Item>
                          <UpdateTextArea
                            field={field}
                            form={Props.form}
                            questionRemove={remove}
                            questionsArray={addTextArray}
                            questionIndex={index}
                            questionData={
                              addedQuestionsArray &&
                              addedQuestionsArray.length > field.key
                                ? addedQuestionsArray[field.key]
                                : null
                            }
                            isNewQuestion={isNewQuestion}
                            formRef={Props.formRef}
                            index={index}
                            move={move}
                            questionArrayMove={moveAddTextArray}
                            questionsAdded={
                              addedQuestionsArray &&
                              addedQuestionsArray.length > 0
                                ? addedQuestionsArray
                                : null
                            }
                          />
                        ) : (
                          <UpdateQuestionCard
                            field={field}
                            section={Props.section}
                            optQuestionIndex={
                              Props.subOptionalSection
                                ? Props.optQuestionIndex
                                : index
                            }
                            index={index}
                            move={move}
                            questionArrayMove={moveAddTextArray}
                            subOptQuestionIndex={
                              Props.subOptionalSection ? index : null
                            }
                            subOptionalSection={Props.subOptionalSection}
                            questionsDataArray={
                              Props.sectionData &&
                              Props.sectionData.questions.length > 0 &&
                              Props.sectionData.questions.length - 1 >=
                                field.key
                                ? Props.sectionData.questions
                                : []
                            }
                            questionData={
                              addedQuestionsArray &&
                              addedQuestionsArray.length > field.key
                                ? addedQuestionsArray[index]
                                : null
                            }
                            questionsAdded={
                              addedQuestionsArray &&
                              addedQuestionsArray.length > field.key
                                ? addedQuestionsArray
                                : null
                            }
                            addOptionValues={Props.addOptionValues}
                            addCheckboxValues={Props.addCheckboxValues}
                            addDropdownValues={Props.addDropdownValues}
                            addSectionOptions={Props.addSectionOptions}
                            mappParentData={Props.mappParentData}
                            mappChildData={Props.mappChildData}
                            getMappChild={Props.getMappChild}
                            mainSectionIndex={
                              Props.optionalSection
                                ? Props.mainSectionIndex
                                : Props.sectionIndex
                            }
                            isNewQuestion={
                              !Props.isNewQuestion &&
                              Props.sectionData &&
                              Props.sectionData.questions.length > 0
                                ? false
                                : true
                            }
                            mainQuestionIndex={
                              Props.optionalSection
                                ? Props.mainQuestionIndex
                                : index
                            }
                            optionalSection={Props.optionalSection}
                            subOptSectionIndex={Props.subOptSectionIndex}
                            optSectionIndex={Props.optSectionIndex}
                            optionsArray={Props.optionsArray}
                            questionRemove={remove}
                            questionsArray={addTextArray}
                          />
                        )}
                      </Form.Item>
                    );
                  })}
                  {false && disableAddFunc && fields.length < 1 && add()}
                  {disableAddFunc && setDisableAddFunc(false)}
                  <Form.Item>
                    <div style={{ display: "flex" }}>
                      {!Props.optionalSection && (
                        <Button
                          type="dashed"
                          className="cq-primary-btn"
                          onClick={Props.sectionAdd}
                          style={{ marginTop: 20, marginRight: 10 }}
                        >
                          <span className="cq-btn-text">Add Section</span>
                        </Button>
                      )}
                      <Button
                        type="dashed"
                        className={
                          Props.optionalSection
                            ? "cq-orng-btn"
                            : "cq-primary-btn"
                        }
                        onClick={() => {
                          setAddTextArray((prevState) => [
                            ...prevState,
                            { addText: true },
                          ]);
                          add();
                          setNewQuestion(true);
                        }}
                        style={{ marginTop: 20, marginRight: 10 }}
                      >
                        <span className="cq-btn-text">Add Text</span>
                      </Button>
                      <Button
                        onClick={() => {
                          add();
                          setAddTextArray((prevState) => [
                            ...prevState,
                            { addText: false },
                          ]);
                          setNewQuestion(true);
                        }}
                        type="dashed"
                        className={
                          Props.optionalSection
                            ? "cq-orng-btn"
                            : "cq-primary-btn"
                        }
                        style={{ marginTop: 20 }}
                      >
                        <span className="cq-btn-text">Add Quesstion(s)</span>
                      </Button>
                      <Form.ErrorList errors={errors} />
                      <div className="cq-section-name-cont">
                        <span className="heading-text" style={{ fontSize: 8 }}>
                          {sectionName ? "(" + sectionName + ")" : ""}
                        </span>
                      </div>
                    </div>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
      )}
    </div>
  );
}

export default UpdateSectionCard;
