import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Accounts from "../Accounts/Accounts";

import { searchClient, createAccountInvoice } from "../store/Actions";

const mapStateToProps = state => {
  return {
    accountInvoiceSuccess: state.accountReducer.accountInvoiceSuccess,
    clientTab: state.employerManagReducer.clientTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateAccInvoice: bindActionCreators(createAccountInvoice, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
