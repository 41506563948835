import * as types from "../Constants";
import { api } from "../../services/api";

export const getOutgoingPaymentsListing = (data) => {
  let _data = {
    clientId: data.clientprofileid,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
  };
  const options = {
    url: "v1/payment/outgoing/All",
  };

  options.types = [
    types.GET_OUTGOING_PAYMENTS_LISTING_SUCCESS,
    types.GET_OUTGOING_PAYMENTS_LISTING_FAILURE,
  ];
  return api.post(options, _data);
};

export const addOutgoingPayment = (data) => {
  let _data = {
    clientId: data.clientId,
    sourceId: data.sourceId,
    amount: data.amount,
    description: data.description,
    date: data.date,
    agentId: data.agentId,
  };
  const options = {
    url: "v1/payment/outgoing",
  };

  options.types = [
    types.ADD_OUTGOING_PAYMENTS_SUCCESS,
    types.ADD_OUTGOING_PAYMENTS_FAILURE,
  ];
  return api.post(options, _data);
};

export const editOutgoingPayment = (data) => {
  let _data = {
    id: data.id,
    clientId: data.clientId,
    sourceId: data.sourceId,
    amount: data.amount,
    description: data.description,
    date: data.date,
    agentId: data.agentId,
  };
  const options = {
    url: "v1/payment/outgoing",
  };

  options.types = [
    types.EDIT_OUTGOING_PAYMENTS_SUCCESS,
    types.EDIT_OUTGOING_PAYMENTS_FAILURE,
  ];
  return api.put(options, _data);
};

export const deleteOutgoingPayment = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/payment/outgoing",
  };

  options.types = [
    types.DELETE_OUTGOING_PAYMENT_SUCCESS,
    types.DELETE_OUTGOING_PAYMENT_FAILURE,
  ];

  return api.delete(options, deleteData);
};

export const getPaymentSourcesList = () => {
  const options = {
    url: `v1/payment/source/All`,
  };

  options.types = [
    types.GET_PAYMENT_SOURCES_LISTING_SUCCESS,
    types.GET_PAYMENT_SOURCES_LISTING_FAILURE,
  ];

  return api.get(options);
};

export const addOutgoingPaymentSource = (data) => {
  let _data = {
    name: data.name,
  };
  const options = {
    url: "v1/payment/source",
  };

  options.types = [
    types.ADD_OUTGOING_PAYMENTS_SOURCE_SUCCESS,
    types.ADD_OUTGOING_PAYMENTS_SOURCE_FAILURE,
  ];
  return api.post(options, _data);
};

export const editOutgoingPaymentSource = (data) => {
  let _data = {
    id: data.id,
    name: data.name,
  };
  const options = {
    url: "v1/payment/source",
  };

  options.types = [
    types.EDIT_OUTGOING_PAYMENTS_SOURCE_SUCCESS,
    types.EDIT_OUTGOING_PAYMENTS_SOURCE_FAILURE,
  ];
  return api.put(options, _data);
};

export const setOutgoingPaymentSourceInfo = (info) => {
  const dataInfo = {
    type: types.GET_OUTGOING_PAYMENT_SOURCE_INFO_SUCCESS,
    payload: info,
  };
  return dataInfo;
};

export const deleteOutgoingPaymentSource = (id) => {
  let deleteData = {
    id: id,
    delete: true,
  };

  const options = {
    url: "v1/payment/source",
  };

  options.types = [
    types.DELETE_OUTGOING_PAYMENT_SOURCE_SUCCESS,
    types.DELETE_OUTGOING_PAYMENT_SOURCE_FAILURE,
  ];

  return api.delete(options, deleteData);
};

export const setOutgoingPaymentInfo = (info) => {
  const dataInfo = {
    type: types.GET_OUTGOING_PAYMENT_INFO_SUCCESS,
    payload: info,
  };
  return dataInfo;
};
