import React, { useState, Fragment, useEffect } from "react";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import { Form, Button, Select, Tooltip, message } from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { LeftCircleFilled } from "@ant-design/icons";

const { Option } = Select;

const VisaUpdateNotification = ({
  onUpdateVisaNotification,
  onSetActiveInnerTab,
  onGetVisaNotification,
}) => {
  const [visaNotification, setVisaNotification] = useState(1);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    onGetVisaNotification()
      .then((res) => {
        setLoading(false);
        setVisaNotification((res.payload && res.payload.settings) || 1);
        form.setFieldsValue({
          name: (res.payload && res.payload.settings) || 1,
        });
      })
      .catch((err) => {
        setLoading(false);
        setVisaNotification(1);
        form.setFieldsValue({
          name: 1,
        });
      });
  }, []);

  const onFinish = (values) => {
    console.log("valuesvaluesvalues", values);

    const data = {
      value: visaNotification,
    };

    onUpdateVisaNotification(data).then(() => {
      message.success("Updated successfully!");
    });
  };

  const onVisaNotificationChange = (value) => {
    setVisaNotification(value);
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.visaUpdateNotification}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">VISA UPDATE NOTIFICATION</span>
                <Tooltip
                  placement="topLeft"
                  title={`Decide how Ezymigrate sends notifications to a client when you change their Visa status.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="time-tracking">
              <div className="client-tag-form"></div>
              <div className="width-52">
                <div className="client-tag-table">
                  <Form onFinish={onFinish} form={form}>
                    <div className="reminder-set-form margin-top-34">
                      <h3>VISA UPDATE NOTIFICATION</h3>
                      <Form.Item name={"name"} className="d-block">
                        <Select
                          onChange={onVisaNotificationChange}
                          placeholder={"Select"}
                        >
                          <Option value={1}>
                            Show Email Popup For Notification
                          </Option>
                          {/* <Option value={2}>
                            Send Visa Notification Without Opening Popup
                          </Option> */}
                          <Option value={2}>Don't Show Any Notification</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Button htmlType="submit" type="primary">
                          UPDATE
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default VisaUpdateNotification;
