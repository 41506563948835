import React, { Fragment, useEffect } from "react";
import { Table, Spin } from "antd";

const ClientEmployerDetail = ({
  onGetProfileClientTag,
  getClientRes,

  isClientTagId,

  setLoading,
  loading
}) => {
  useEffect(() => {
    setLoading(true);
     
    onGetProfileClientTag(isClientTagId && isClientTagId).then(() => {
      setLoading(false);
    });
  }, [isClientTagId, onGetProfileClientTag, setLoading]);
  const columns2 = [
    {
      title: "Name",
      dataIndex: "tag",
      key: "tag"
    }
  ];

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          style={{ marginTop: "10px" }}
          className="job-table table-head employer-tables border-3"
          dataSource={getClientRes && getClientRes.items}
          columns={columns2}
          pagination={false}
        />
      </Spin>
    </Fragment>
  );
};
export default ClientEmployerDetail;
