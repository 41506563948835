import React from "react";
import { Link } from "react-router-dom";
import { Select, DatePicker, Table, Tag, Space, Spin } from "antd";

import "./ReportsStyles.css";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";
import SaleHistoryReport from "../Components/Reports/SaleHistoryReport";
import ComponentToPrint from "../Components/Reports/ClientSourceReportPrint";
import ProcessingPersonReport from "../Components/Reports/ProcessingPersonReport";
import FileNotesReport from "../Components/Reports/FileNotesReport";
import PotentialClientStatusReport from "../Components/Reports/PotentialClientStatusReport";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const headOption = [
  { tabName: "Reports", linkName: "/reports" },
  // { tabName: "Visa Reports", linkName: "/visa-report" },
  // // { tabName: "Case Management", linkName: "/" },
  // { tabName: "Potential Clients", linkName: "/potential-clients-report" },
  // { tabName: "Time Tracking", linkName: "/time-tracking" },
  // { tabName: "Employer Report", linkName: "/employer-reports" },
];

const { Option } = Select;

var totalInvoiced = 0;
var totalReceived = 0;

const dateFormat = "DD/MM/YYYY";

class PotentialClientReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMainTab: "",
      activeTab: "",
      extend: false,
      dateFrom: "",
      dateTo: "",
      processingPerson: [],
      loadReports: false,
      pageNumber: 1,
      pageSize: 10,
      processingIds: "",
    };
    this.props.onGetTeamMember();
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.pSaleHistoryRes !== this.props.pSaleHistoryRes) {
      totalInvoiced = 0;
      totalReceived = 0;
      if (this.props.pSaleHistoryRes)
        for (var i = 0; i < this.props.pSaleHistoryRes.list.length; i++) {
          totalInvoiced += parseInt(this.props.pSaleHistoryRes.list[i].total);
          totalReceived += parseInt(
            this.props.pSaleHistoryRes.list[i].received
          );
        }
    }
  }

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeMainTab = (value) => {
    this.setState({ activeMainTab: value });
  };

  onChangeTab = (value) => {
    this.setState({ activeTab: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  onChangeDateFrom = (date, dateString) => {
    this.setState({ dateFrom: date });
  };

  onChangeDateTo = (date, dateString) => {
    this.setState({ dateTo: date });
  };

  handleChange = (value) => {
    this.setState({ processingPerson: value });
  };

  onSaleHistoryReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onPSaleHistoryReport(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("SALES HISTORY");
    });
  };

  onClientSourceReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onPClientSourceReport(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("CLIENT SOURCE");
    });
  };

  onClientProcessingPerson = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onPcProcessingPersonReport(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("PROCESSING PERSONS");
    });
  };

  onFileNotesReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.pcFileNotes(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("FILE NOTES");
    });
  };

  onPotentialClientStatusReport = () => {
    var processingIds = "";
    this.setState({ loadReports: true });
    if (this.state.processingPerson.length > 0) {
      for (var i = 0; i < this.state.processingPerson.length; i++) {
        if (i === 0) {
          processingIds = this.state.processingPerson[i];
        } else {
          processingIds += "," + this.state.processingPerson[i];
        }
      }
    }
    this.setState({ processingIds: processingIds });
    let data = {
      processingPerson: processingIds || "00000000-0000-0000-0000-000000000000",
      dateFrom: this.state.dateFrom || "1900-01-01T00:00:00.000Z",
      dateTo: this.state.dateTo || "1900-01-01T00:00:00.000Z",
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
    };
    this.props.onPotentialClientStatusReport(data).then(() => {
      this.setState({ loadReports: false });
      this.onChangeTab("POTENTIAL CLIENT STATUS");
    });
  };

  render() {
    const {
      selectedOption,
      activeTab,
      dateFrom,
      dateTo,
      processingPerson,
      loadReports,
    } = this.state;
    const {
      teamMembers,
      pSaleHistoryRes,
      onPSaleHistoryReport,
      pClientSourceReportRes,
      onPClientSourceReport,
      pcProcessingPersonReportRes,
      onPcProcessingPersonReport,
      pcFileNotesReportRes,
      pcFileNotes,
      onPotentialClientStatusReport,
      pcStatusReportRes,
    } = this.props;
    return (
      <div>
        <Spin spinning={loadReports}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs
                data={headOption}
                activeTab="Potential Clients"
              />

              <div className="report-container">
                <div>
                  <div>
                    <div className="pciq-top-div">
                      <span
                        className="pc-top-div-text"
                        style={{ color: "#0A3C5D" }}
                      >
                        Report Filter
                      </span>
                    </div>

                    <div className="ca-gray-cont" style={{ border: 0 }}>
                      <div style={{ display: "flex", marginLeft: 20 }}>
                        <div style={{ display: "flex", width: "31%" }}>
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateFrom}
                              value={dateFrom}
                              format={dateFormat}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            width: "31%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <DatePicker
                              onChange={this.onChangeDateTo}
                              value={dateTo}
                              format={dateFormat}
                            />
                          </div>
                        </div>

                        <div
                          className="pc-select-width multi-select-option"
                          style={{ marginLeft: 20, width: "31%" }}
                        >
                          <div>
                            <Select
                              mode="multiple"
                              placeholder="Please select"
                              value={processingPerson}
                              onChange={this.handleChange}
                              style={{ width: "100%" }}
                            >
                              {teamMembers &&
                                teamMembers.users.map((user, userInd) => (
                                  <Option value={user.id}>
                                    {user.fullName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="report-btn-tab-row">
                      <div
                        className={
                          activeTab === "SALES HISTORY"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        onClick={this.onSaleHistoryReport}
                      >
                        <span className="pc-btn-text">SALES HISTORY</span>
                      </div>

                      <div
                        className={
                          activeTab === "CLIENT SOURCE"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={this.onClientSourceReport}
                      >
                        <span className="pc-btn-text">CLIENT SOURCE</span>
                      </div>

                      <div
                        className={
                          activeTab === "PROCESSING PERSONS"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={this.onClientProcessingPerson}
                      >
                        <span className="pc-btn-text">PROCESSING PERSONS</span>
                      </div>

                      <div
                        className={
                          activeTab === "FILE NOTES"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={this.onFileNotesReport}
                      >
                        <span className="pc-btn-text">FILE NOTES</span>
                      </div>

                      <div
                        className={
                          activeTab === "POTENTIAL CLIENT STATUS"
                            ? "report-active-btn-tab"
                            : "report-inactive-btn-tab"
                        }
                        style={{ marginLeft: 10 }}
                        onClick={this.onPotentialClientStatusReport}
                      >
                        <span className="pc-btn-text">
                          POTENTIAL CLIENT STATUS
                        </span>
                      </div>
                    </div>

                    {activeTab === "SALES HISTORY" && (
                      <SaleHistoryReport
                        saleHistoryRes={pSaleHistoryRes && pSaleHistoryRes.list}
                        reportsCount={pSaleHistoryRes && pSaleHistoryRes.count}
                        displayText={"Sale History Report"}
                        totalInvoiced={totalInvoiced}
                        totalReceived={totalReceived}
                        onSaleHistoryReport={onPSaleHistoryReport}
                        requestData={this.state}
                      />
                    )}

                    {activeTab === "CLIENT SOURCE" && (
                      <div>
                        <ComponentToPrint
                          clientSourceReportRes={
                            pClientSourceReportRes &&
                            pClientSourceReportRes.list
                          }
                          displayText={"Client Source Report"}
                          reportsCount={
                            pClientSourceReportRes &&
                            pClientSourceReportRes.count
                          }
                          onClientSourceReport={onPClientSourceReport}
                          requestData={this.state}
                        />
                      </div>
                    )}

                    {activeTab === "PROCESSING PERSONS" && (
                      <ProcessingPersonReport
                        processingPersonReportRes={
                          pcProcessingPersonReportRes &&
                          pcProcessingPersonReportRes.list
                        }
                        displayText={"Processing Person Report"}
                        totalInvoiced={totalInvoiced}
                        totalReceived={totalReceived}
                        reportsCount={
                          pcProcessingPersonReportRes &&
                          pcProcessingPersonReportRes.count
                        }
                        onProcessingPersonReport={onPcProcessingPersonReport}
                        requestData={this.state}
                      />
                    )}

                    {activeTab === "FILE NOTES" && (
                      <FileNotesReport
                        fileNotesReportRes={
                          pcFileNotesReportRes && pcFileNotesReportRes.list
                        }
                        displayText={"File Notes"}
                        reportsCount={
                          pcFileNotesReportRes && pcFileNotesReportRes.count
                        }
                        onFileNotesReport={pcFileNotes}
                        requestData={this.state}
                      />
                    )}

                    {activeTab === "POTENTIAL CLIENT STATUS" && (
                      <PotentialClientStatusReport
                        fileNotesReportRes={
                          pcStatusReportRes && pcStatusReportRes.list
                        }
                        displayText={"File Notes"}
                        reportsCount={
                          pcStatusReportRes && pcStatusReportRes.count
                        }
                        onFileNotesReport={onPotentialClientStatusReport}
                        requestData={this.state}
                      />
                    )}

                    {/* ===== Design from Potential clients Processing Persons report Screen ===== */}

                    {false && (
                      <div>
                        <div className="rep-print-row-cont">
                          <div
                            className="pciq-top-div"
                            style={{ marginBottom: 5 }}
                          >
                            <span
                              className="pc-top-div-text"
                              style={{ color: "#0A3C5D", fontSize: 12 }}
                            >
                              PROCESSING PERSON
                            </span>
                          </div>
                          <div>
                            <div className="rep-print-icon-cont">
                              <img
                                src={Images.printNBlue}
                                className="rep-print-icon"
                              />
                            </div>
                          </div>
                        </div>

                        <table
                          className="ca-invoice-table-cont"
                          style={{ borderSpacing: 1, border: 0 }}
                        >
                          <tbody>
                            <tr style={{ backgroundColor: "#F8F9FB" }}>
                              <th
                                className="ca-table-heading"
                                style={{ width: 40 }}
                              >
                                Sn
                              </th>
                              <th
                                className="ca-table-heading"
                                style={{ textAlign: "left" }}
                              >
                                First Name
                              </th>
                              <th
                                className="ca-table-heading"
                                style={{
                                  width: 180,
                                  textAlign: "left",
                                  paddingLeft: 30,
                                }}
                              >
                                Last Name
                              </th>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td
                                className="report-table-content-text"
                                style={{
                                  textAlign: "left",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                }}
                                colSpan={6}
                              >
                                <div className="report-table-first-row">
                                  <div>
                                    <span>PROCESSING PERSON :</span>
                                  </div>
                                  <div className="report-column-btn">
                                    <span style={{ color: "#FFFFFF" }}>
                                      Sam Bam
                                    </span>
                                  </div>
                                  <div className="report-column-btn">
                                    <span style={{ color: "#FFFFFF" }}>
                                      advisor
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">1</td>
                              <td
                                className="report-table-content-text"
                                style={{ textAlign: "left" }}
                              >
                                jaspreet
                              </td>
                              <td
                                className="report-table-content-text"
                                style={{ textAlign: "left", paddingLeft: 30 }}
                              >
                                kaur
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">2</td>
                              <td
                                className="report-table-content-text"
                                style={{ textAlign: "left" }}
                              >
                                potential client
                              </td>
                              <td
                                className="report-table-content-text"
                                style={{ textAlign: "left", paddingLeft: 30 }}
                              >
                                kaur
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    {/* ===== Design from File Notes report Screen ===== */}

                    {false && (
                      <div>
                        <div className="rep-print-row-cont">
                          <div
                            className="pciq-top-div"
                            style={{ marginBottom: 5 }}
                          >
                            <span
                              className="pc-top-div-text"
                              style={{ color: "#0A3C5D", fontSize: 12 }}
                            >
                              FILE NOTES
                            </span>
                          </div>
                          <div>
                            <div className="rep-print-icon-cont">
                              <img
                                src={Images.printNBlue}
                                className="rep-print-icon"
                              />
                            </div>
                          </div>
                        </div>

                        <table
                          className="ca-invoice-table-cont"
                          style={{ borderSpacing: 1, border: 0 }}
                        >
                          <tbody>
                            <tr style={{ backgroundColor: "#F8F9FB" }}>
                              <th className="ca-table-heading">EZM ID</th>
                              <th className="ca-table-heading">CLIENT</th>
                              <th className="ca-table-heading">Email</th>
                              <th className="ca-table-heading">FILE NOTES</th>
                              <th className="ca-table-heading">USER</th>
                              <th className="ca-table-heading">CREATED DATE</th>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">
                                11355
                              </td>
                              <td className="report-table-content-text">
                                Helen
                              </td>
                              <td className="report-table-content-text">
                                vshgh@gmail.com
                              </td>
                              <td className="report-table-content-text">
                                sdkjksd
                              </td>
                              <td className="report-table-content-text">
                                Sam Bam
                              </td>
                              <td className="report-table-content-text">
                                13/01/2020
                              </td>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">
                                11355
                              </td>
                              <td className="report-table-content-text">
                                Helen
                              </td>
                              <td className="report-table-content-text">
                                vshgh@gmail.com
                              </td>
                              <td className="report-table-content-text">
                                sdkjksd
                              </td>
                              <td className="report-table-content-text">
                                Sam Bam
                              </td>
                              <td className="report-table-content-text">
                                13/01/2020
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="report-total-main-cont">
                          <div />

                          <div className="report-total-cont">
                            <div className="report-total-text-div">
                              <span
                                className="cv-bold-text"
                                style={{ marginRight: 20, fontSize: 10 }}
                              >
                                Total
                              </span>
                            </div>
                            <div
                              className="rep-rec-am-total-cont"
                              style={{ width: "33%" }}
                            >
                              <span
                                className="cv-bold-text"
                                style={{ fontSize: 10 }}
                              >
                                300.00
                              </span>
                            </div>
                            <div className="rep-rec-am-total-cont">
                              <span
                                className="cv-bold-text"
                                style={{ fontSize: 10 }}
                              >
                                300.00
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* ===== Design from potential client status report Screen ===== */}

                    {false && (
                      <div>
                        <div className="rep-print-row-cont">
                          <div
                            className="pciq-top-div"
                            style={{ marginBottom: 5 }}
                          >
                            <span
                              className="pc-top-div-text"
                              style={{ color: "#0A3C5D", fontSize: 12 }}
                            >
                              POTENTIAL CLIENT
                            </span>
                          </div>
                          <div>
                            <div className="rep-print-icon-cont">
                              <img
                                src={Images.printNBlue}
                                className="rep-print-icon"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 5,
                          }}
                        >
                          <div />
                          <div
                            className="pc-btn-cont"
                            style={{
                              paddingTop: 5,
                              paddingBottom: 5,
                              borderRadius: 3,
                            }}
                          >
                            <span className="pc-btn-text">EXPORT</span>
                          </div>
                        </div>

                        <table
                          className="ca-invoice-table-cont"
                          style={{ borderSpacing: 1, border: 0 }}
                        >
                          <tbody>
                            <tr style={{ backgroundColor: "#F8F9FB" }}>
                              <th className="ca-table-heading">EZM ID</th>
                              <th className="ca-table-heading">CLIENT</th>
                              <th className="ca-table-heading">EMAIL</th>
                              <th className="ca-table-heading">
                                VISA TYPE NAME
                              </th>
                              <th className="ca-table-heading">STATUS</th>
                              <th className="ca-table-heading">
                                LAST ACTIVITY
                              </th>
                              <th className="ca-table-heading">
                                PROCESSING PERSON
                              </th>
                              <th className="ca-table-heading">INTERNAL ID</th>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">
                                196794
                              </td>
                              <td className="report-table-content-text">
                                jason firmclient
                              </td>
                              <td className="report-table-content-text">
                                vshgh@gmail.com
                              </td>
                              <td className="report-table-content-text">
                                visitor visa - General
                              </td>
                              <td className="report-table-content-text">
                                Selected
                              </td>
                              <td className="report-table-content-text">
                                13/01/2020
                              </td>
                              <td className="report-table-content-text"></td>
                              <td className="report-table-content-text"></td>
                            </tr>
                            <tr style={{ backgroundColor: "#FFFFFF" }}>
                              <td className="report-table-content-text">
                                196794
                              </td>
                              <td className="report-table-content-text">
                                jason firmclient
                              </td>
                              <td className="report-table-content-text">
                                vshgh@gmail.com
                              </td>
                              <td className="report-table-content-text">
                                visitor visa - General
                              </td>
                              <td className="report-table-content-text">
                                Selected
                              </td>
                              <td className="report-table-content-text">
                                13/01/2020
                              </td>
                              <td className="report-table-content-text"></td>
                              <td className="report-table-content-text"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ===== Design from potential client's client source report Screen ===== */}

              {false && (
                <div
                  className="report-container"
                  style={{ paddingTop: 30, paddingBottom: 30 }}
                >
                  <table
                    className="ca-invoice-table-cont"
                    style={{ borderSpacing: 1, border: 0 }}
                  >
                    <tbody>
                      <tr style={{ backgroundColor: "#F8F9FB" }}>
                        <th
                          className="ca-table-heading"
                          style={{ textAlign: "left", paddingLeft: 25 }}
                        >
                          Source
                        </th>
                        <th className="ca-table-heading" style={{ width: 180 }}>
                          Clients
                        </th>
                      </tr>
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <td
                          className="rep-cs-table-content-text"
                          style={{ textAlign: "left" }}
                        >
                          Advertisement
                        </td>
                        <td className="rep-cs-table-content-text">11</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <td
                          className="rep-cs-table-content-text"
                          style={{ textAlign: "left" }}
                        >
                          Artical or blog post
                        </td>
                        <td className="rep-cs-table-content-text">5</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <td
                          className="rep-cs-table-content-text"
                          style={{ textAlign: "left" }}
                        >
                          Direct phone call to imigration Advisor
                        </td>
                        <td className="rep-cs-table-content-text">1</td>
                      </tr>
                      <tr style={{ backgroundColor: "#FAFAFA" }}>
                        <td
                          className="rep-cs-table-content-text"
                          style={{ textAlign: "left" }}
                        >
                          Google or other search
                        </td>
                        <td className="rep-cs-table-content-text">5</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default PotentialClientReport;
