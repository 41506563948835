import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const commissionRemindersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_COMMISSION_REMINDER_LISTING_SUCCESS:
      return { ...state, commissionRemindersListing: action.payload };
    case types.GET_COMMISSION_REMINDER_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, commissionRemindersListing: [] };
      } else {
      }

      console.log("on error console", action.payload);
    default:
      return state;
  }
};
