import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import { message, Table } from "antd";
import { getEmailContent } from "../../store/Actions";
import { apiRefresh } from "../../services/api";
import moment from "moment";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    index: 0,
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    index: 1,
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    index: 2,
  },
];

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const ContractSettings = ({
  onGetLetterTemplates,
  LetterTemplatesRes,
  setIsModalVisible,
  setCreateMode,
  setUpdatedata,

  onSortLetterTemplates,

  removeTag,

  setStoreData,

  isModalVisible,
}) => {
  const [dataSource, setDataSource] = useState(null);
  const [newIndexdata, setNewIndexdata] = useState();
  const [oldIndexdata, setOldIndexdata] = useState();

  useEffect(() => {
    letterTempaltesFilter(LetterTemplatesRes);
  }, [LetterTemplatesRes]);

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];

    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "FILENOTE") {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });
      setDataSource(filterList);
    }
  };

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined onClick={() => showModal(record, "edit-value")} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log("Sorted items: ", newData);

      let idArray = [];
      for (var i = 0; i < newData.length; i++) {
        idArray.push({ id: newData[i].id, newOrder: i });
      }
      setDataSource(newData);
      setOldIndexdata(oldIndex);
      setNewIndexdata(newIndex);

      const data = {
        id: dataSource && dataSource.length > 0 && dataSource[oldIndex].id,
        oldOrder: oldIndex,
        newOrder: newIndex,
      };

      onSortLetterTemplates({ items: idArray }).then(() => {
        onGetLetterTemplates();
        message.success("Successfully Updated!");
      });
    }
  };

  const onTableChange = () => {
    const data = {
      id: dataSource && dataSource.id,
      oldOrder: oldIndexdata,
      newOrder: newIndexdata,
    };

    onSortLetterTemplates(data).then(() => onGetLetterTemplates());
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      // disableAutoscroll
      rowSelection
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      dataSource &&
      dataSource.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const showModal = (value, modelType) => {
    getcontent(value);
  };

  const getcontent = (value) => {
    const getContractOpt = {
      url: `v1/template/${value.id}`,
    };

    getContractOpt.types = [
      "GET_SINGLE_FILE_NOTE_SUCCESS",
      "GET_SINGLE_FILE_NOTE_FAILURE",
    ];
    apiRefresh.get(getContractOpt).then((resp) => {
      if (resp) {
        setUpdatedata(resp);
        setIsModalVisible(resp);
        setCreateMode(!isModalVisible);
        setStoreData(resp);
      }
    });
  };
  return (
    <Table
      pagination={false}
      dataSource={dataSource}
      columns={columns && columns}
      // onFinish={onTableChange}
      // onChange={}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
export default ContractSettings;
