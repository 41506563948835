// @flow
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./HeaderBarStyles.css";
import { useHistory } from "react-router";
import { Layout, message } from "antd";
import {
  Button,
  Select,
  Upload,
  Row,
  Col,
  Input,
  Image,
  Typography,
  Checkbox,
  AutoComplete,
} from "antd";
import navMenu from "../../images/nav-collaps.png";
import search from "../../svgs/search.svg";
import { Images } from "../../Themes";
import {
  Router,
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import { User } from "../../Containers";
import { bindActionCreators } from "redux";
import { closeAndOpenSidebar } from "./../../store/Actions";
import history from "../../services/history";
import { useDispatch } from "react-redux";
import { setSelectedBranchId } from "../../store/Actions";
import moment from "moment";
const { Header } = Layout;
const { Search } = Input;
const Option = Select.Option;
const { Paragraph } = Typography;

const languageOptions = [
  { value: "Eng", label: "Eng" },
  { value: "Arabic", label: "Arabic" },
  { value: "Swedish", label: "Swedish" },
];

const getRandomInt = (max, min = 0) => {
  return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
};

var userName = "";

function SupervisorHeaderBar(Props) {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [storeUserName, setUsername] = useState(
    localStorage.getItem("userName")
  );

  if (storeUserName) {
    if (userName.length > 15) {
      var name = storeUserName.split(" ");
      userName = name[0];
    } else {
      userName = storeUserName;
    }
  }
  const handleBar = () => {
    setIsModalVisible(!isModalVisible);
    Props.onCloseAndOpenSidebar(isModalVisible);
  };

  const onLogout = () => {
    Props.onUserLogout().then((res) => {
      localStorage.clear();
      sessionStorage.clear();
      // localStorage.removeItem("_admintoken");
      // localStorage.removeItem("admintoken");
      // localStorage.removeItem("_userEmail");
      // localStorage.removeItem("_userName");
      // localStorage.removeItem("_userId");
      // localStorage.removeItem("userId");
      // localStorage.removeItem("userEmail");
      // localStorage.removeItem("userOwner");
      // localStorage.removeItem("selectedBranchId");
      // localStorage.removeItem("companyId");
      // localStorage.removeItem("userName");
      // localStorage.removeItem("token");
      // localStorage.removeItem("refreshToken");
      // localStorage.removeItem("_refreshToken");
      // localStorage.removeItem("isSupervisor");
      window.location.assign("/login/supervisor");
    });
  };

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {history.location && (
            <>
              <Route exact path="/login" component={User} />
            </>
          )}
        </Switch>
      </BrowserRouter>
      <Row>
        <Col xs={15}>
          <Row>
            <Col xs={24}>
              <Row>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Image src={Images.logo} preview={false} />
                </Col>
                <Col xs={2} offset={1}>
                  <Image
                    width={17}
                    src={navMenu}
                    preview={false}
                    onClick={() => {
                      handleBar();
                    }}
                  />
                </Col>
                <Col xs={6}></Col>
                <Col xs={6} className="header-search-col">
                  {/* <Search
                    style={{ width: "270px", marginLeft: "10px" }}
                    placeholder="Search Client..."
                    onSearch={onSearch}
                    onChange={onChangeValue}
                    enterButton
                  /> */}
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                ></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={9}>
          {/* <Row>
            <Col xs={24}> */}
          <Row gutter={12}>
            <Col span={8}>
              <Select
                defaultValue="Eng"
                style={{ width: "-webkit-fill-available" }}
              >
                {languageOptions.map((data) => {
                  return <Option>{data.label}</Option>;
                })}
              </Select>
            </Col>

            <Col span={8}>
              <div className="header-name-cont">
                {storeUserName && (
                  <>
                    <div>
                      <span className="header-name-text-style">Hello</span>
                    </div>
                    <div className="header-name-text">
                      <span className="header-name-text-style">{userName}</span>
                    </div>
                  </>
                )}
              </div>
            </Col>

            <Col
              span={8}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="primary"
                onClick={onLogout}
                style={{ marginLeft: "7px", marginTop: 15 }}
                className="button-blue"
              >
                Logout
              </Button>
            </Col>
          </Row>
          {/* </Col>
          </Row> */}
        </Col>
      </Row>
    </div>
  );
}

export default SupervisorHeaderBar;
