import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  DatePicker,
  Input,
  Form,
  InputNumber,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../../../services/api";
// import activityData from "../ClientActivity/ActivityData";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const StartEmployerVisa = ({
  visaTypeData,
  onStartNewApplication,
  newApplicationWith,
  onGetVisaCategory,
  visaCategoryData,
  onCloseModal,
  onGetEmployerCase,
  userDataEmp,
  onGetEmployerJob,
  employerJobRes,
  //   onSetActiveKey,
}) => {
  const [visaTypeId, setVisaTypeId] = useState("");
  const [accreditationType, setAccreditationType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [category, setCategory] = useState(0);
  const [destination, setDestination] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [prevNewApplicationWith, setPrevNewApplicatonWith] = useState("");
  const [showJobForm, setShowJobForm] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (userDataEmp) {
      setLoading(true);
      onGetEmployerJob(userDataEmp.id).then((res) => {
        setLoading(false);
      });
    }
  }, [userDataEmp]);

  var visaTypeOptions = [];
  var categoryOptions = [];
  if (visaTypeData) {
    for (var i = 0; i < visaTypeData.items.length > 0; i++) {
      visaTypeOptions.push(
        <Option value={visaTypeData.items[i].id}>
          {visaTypeData.items[i].visaTypeName}
        </Option>
      );
    }
  }

  if (visaCategoryData) {
    for (var i = 0; i < visaCategoryData.items.length > 0; i++) {
      categoryOptions.push(
        <Option value={visaCategoryData.items[i].id}>
          {visaCategoryData.items[i].name}
        </Option>
      );
    }
  }

  if (newApplicationWith !== prevNewApplicationWith) {
    setPrevNewApplicatonWith(newApplicationWith);
    setVisaTypeId("");
    setStartDate("");
    setCategory(0);
    setDestination(0);
  }

  const onChangeVisaType = (value) => {
    setVisaTypeId(value);
    setAccreditationType("");
    if (newApplicationWith === "au") {
      onGetVisaCategory(value);
    }
  };

  const onChangeCategory = (value) => {
    console.log(`selected ${value}`);
    setCategory(value);
  };

  const onChangeDestination = (value) => {
    console.log(`selected ${value}`);
    setDestination(value);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(date);
  };

  const onSubmit = (values) => {
    setLoading(true);
    var visaType =
      visaTypeData && visaTypeData.items.find((obj) => obj.id === visaTypeId);
    if (!visaType) {
      const branchUserOpt = {
        url: `v1/company/BranchVisaType/${visaTypeId}`,
      };
      branchUserOpt.types = ["GET_VISA_TYPE_SUCCESS", "GET_VISA_TYPE_FAILURE"];
      apiRefresh
        .get(branchUserOpt)
        .then((res) => {
          if (values && values.jobTitle) {
            onSaveJob(values, res);
          } else {
            saveCase(res);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      // saveCase(visaType);
      if (values && values.jobTitle) {
        onSaveJob(values, visaType);
      } else {
        saveCase(visaType);
      }
    }
  };

  const saveCase = (visaType, title, jobId) => {
    if (visaTypeId === "") {
      message.error("Please select visa type!");
      setLoading(false);
    }
    let data = {
      subjectId: userDataEmp.id,
      branchVisaTypeId: visaTypeId,
      caseStatusId: 1,
      startDate: startDate || new Date(),
      isPaid: false,
      isSigned: false,
      casePriority: "Medium",
      country: "",
      expiryDate: "1900-01-01T00:00:00+00:00",
      approveDate: "1900-01-01T00:00:00+00:00",
      visaTypeName: visaType.visaTypeName,
      isCompleted: false,
      subCategory: parseInt(category),
      destination: parseInt(destination),
      applicationCountry: newApplicationWith === "au" ? 1 : 0,
      typeId: accreditationType ? parseInt(accreditationType) : 0,
      caseTitle: jobTitle || title || "",
    };
    onStartNewApplication(data)
      .then((res) => {
        message.success("Visa application created successfully.");
        onGetEmployerCase(userDataEmp.id)
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        form.resetFields();
        setVisaTypeId("");
        setStartDate("");
        setJobTitle("");
        setSelectedJob("");
        onCloseModal();
        if (selectedJob || jobId) {
          let linkData = {
            caseId: res.payload,
            jobID: parseInt(selectedJob || jobId),
          };
          const jobLinkOpt = {
            url: `v1/subject/SubjectCaseJobLink`,
          };
          jobLinkOpt.types = ["JOB_LINK_SUCCESS", "JOB_LINK_FAILURE"];
          apiRefresh.post(jobLinkOpt, linkData);
        }
        // onSetActiveKey("1");
        // let userName = localStorage.getItem("userName");
        // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        // let myData = {
        //   clientName: profileData.fullName,
        //   logMessage:
        //     "Application with status Preparing " + " Added by " + userName,
        //   date: moment(new Date()).format("DD/MM/YYYY"),
        //   logType: "Client Visa",
        //   invoiceId: "0",
        // };
        // activityData(myData);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onSaveJob = (values, visaType) => {
    const data = {
      employerId: userDataEmp && userDataEmp.id,
      jobStatusId: 1,
      jobTitle: values && values.jobTitle ? values.jobTitle : "",
      liaId: "00000000-0000-0000-0000-000000000000",
      salePersonId: "00000000-0000-0000-0000-000000000000",
      jobOrderNo: values && values.jobOrderNo ? values.jobOrderNo : "",
      position: values && values.position ? values.position : "",
      openDate: values.openDate || new Date(),
      closeDate: "1900-01-01T00:00:00+00:00",
      required: 0,
      contract: "",
      location: "",
      remuneration: values && values.remuneration ? values.remuneration : "",
      agency: "",
      conLocationtract: "",
      siteAddress: "",
      anzscoCode: "",
      skillLevel: "",
      experinceRequired: "",
      otherRequiremts: "",
      policy: "",
      visaLength: "",
      comments: values.immigrationStrategies
        ? values.immigrationStrategies
        : "",
      laborMarketTestExpiry: new Date(),
      laborMarketTestExpiry1: new Date(),
      advertisingExpiry: "1900-01-01T00:00:00+00:00",
      skillMatesReportExpiry: "1900-01-01T00:00:00+00:00",
    };
    const addJob = {
      url: `v1/employer/job`,
    };
    addJob.types = ["ADD_JOB_SUCCESS", "ADD_JOB_FAILURE"];
    apiRefresh
      .post(addJob, data)
      .then((res) => {
        saveCase(visaType, values.jobTitle, res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="form-container" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          style={{
            paddingTop: 5,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          {/* <span className="visa-date-text">
            {"Visa Status Date: " + moment(new Date()).format("DD/MM/YYYY")}
          </span> */}
        </div>
        <Form onFinish={onSubmit} form={form}>
          <div style={{ padding: 10 }}>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "100%" }}
              onChange={onChangeVisaType}
              placeholder="Select Visa Types"
              value={visaTypeId}
            >
              <Option value="">Please Select Visa</Option>
              <Option value={10042}>Employer accreditation</Option>
              <Option value={10070}>Employer Approval in Principal</Option>
              <Option value={10100}>Job Check</Option>
            </Select>
          </div>
          {newApplicationWith === "au" && visaTypeId && (
            <div style={{ padding: 10 }}>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={onChangeCategory}
                // value={selectedOption}
                // onChange={this.handleChange}
              >
                {categoryOptions}
              </Select>
            </div>
          )}
          {newApplicationWith === "au" && (
            <div style={{ padding: 10 }}>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={onChangeDestination}
                // value={selectedOption}
                // onChange={this.handleChange}
              >
                <Option value="1">New South Wales</Option>
                <Option value="2">Queensland</Option>
                <Option value="6">South Australia</Option>
                <Option value="4">Tasmania</Option>
                <Option value="3">Victoria</Option>
                <Option value="5">Western Australia</Option>
              </Select>
            </div>
          )}
          <div style={{ padding: 10, paddingTop: 0 }}>
            <DatePicker
              value={startDate}
              onChange={onChange}
              format={dateFormat}
            />
          </div>
          {visaTypeId == 10042 && (
            <div style={{ padding: 10 }}>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                placeholder="Select Accreditation Type"
                value={accreditationType}
                onChange={(e) => setAccreditationType(e)}
              >
                <Option value="">Select Accreditation Type</Option>
                <Option value={1}>Standard</Option>
                <Option value={2}>High-Volume</Option>
                <Option value={3}>Franchise</Option>
                <Option value={4}>Third-Party</Option>
              </Select>
            </div>
          )}
          {visaTypeId == 10100 && (
            <>
              <div style={{ padding: 10 }}>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
              <div style={{ padding: 10 }}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="Select Job"
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e)}
                >
                  <Option value="">Select Job</Option>
                  {employerJobRes &&
                    employerJobRes.items.map((job) => (
                      <Option value={job.id}>{job.jobTitle}</Option>
                    ))}
                </Select>
              </div>
              <div style={{ padding: 10 }}>
                <Button
                  className="button-blue"
                  onClick={() => setShowJobForm(!showJobForm)}
                >
                  {showJobForm ? "Hide Job Form" : "Add New Job"}
                </Button>
              </div>
              {showJobForm && (
                <>
                  <div style={{ padding: 10 }}>
                    <Form.Item
                      name="jobOrderNo"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Input placeholder="Job Order No" />
                    </Form.Item>{" "}
                  </div>
                  <div style={{ padding: 10 }}>
                    <Form.Item
                      name="jobTitle"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Input placeholder="Job Title" />
                    </Form.Item>
                  </div>
                  <div style={{ padding: 10 }}>
                    <Form.Item
                      name="openDate"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <DatePicker placeholder="Open Date" format="DD/MM/YYYY" />
                    </Form.Item>
                  </div>
                  <div style={{ padding: 10 }}>
                    <Form.Item
                      name="position"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <Input placeholder="Position" />
                    </Form.Item>
                  </div>
                  <div style={{ padding: 10 }}>
                    <Form.Item
                      name="remuneration"
                      rules={[{ required: true, message: "Required!" }]}
                    >
                      <InputNumber
                        placeholder="Remuneration"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ padding: 10 }}>
                    <Form.Item name="immigrationStrategies">
                      <Input.TextArea placeholder="Immigration Strategies" />
                    </Form.Item>
                  </div>
                </>
              )}
            </>
          )}
          <div
            className="button-blue-cont"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <div
            className="button-blue"
            style={{ cursor: "pointer" }}
            onClick={onSubmit}
          > */}
            <Form.Item>
              <Button
                loading={loading}
                style={{ color: "#FFFFFF" }}
                // onClick={onSubmit}
                className="button-blue"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
            {/* <span style={{ color: "#FFFFFF" }}>Save</span> */}
            {/* </div> */}
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default StartEmployerVisa;
