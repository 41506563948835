import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  message,
  Button,
  DatePicker,
  Spin,
  Modal,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
import { Images } from "../../Themes";
import AddDealForm from "../../Deals/AddDealForm";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const AddTimeTrackingForm = ({
  clientId,
  onGetVisaType,
  onGetVisaWorkType,
  onGetVisaTypePrice,
  onGetServiceType,
  visaTypeData,
  visaWorkTypeRes,
  visaTypePriceRes,
  serviceTypeRes,
  getTasks,
  tasks,
  activeKey,
  addSuccess,
  handleCancel,
  completed,
  onGetVisaApplication,
  visaAppData,
  addByClient,
}) => {
  const [loading, setLoading] = useState(false);
  const [isVisaType, setIsVisaType] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [dealsList, setDealsList] = useState([]);
  const [summaryClicked, setSummaryClicked] = useState(true);
  const [visaTypeOpt, setVisaTypeOpt] = useState(true);

  const [form] = Form.useForm();
  useEffect(() => {
    if (tasks.isPotential) {
      onGetVisaType();
    } else {
      if (clientId != "00000000-0000-0000-0000-000000000000")
        onGetVisaApplication(clientId);
    }
    onGetVisaWorkType();
    onGetVisaTypePrice();
    onGetServiceType();
    if (getTasks) {
      getTasks();
    }
  }, [activeKey]);

  const calculateTotalMinutes = (timeString) => {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Calculate total minutes
    const totalMinutes = hours * 60 + minutes + seconds / 60;

    return Math.round(totalMinutes);
  };

  useEffect(() => {
    var totalMinutes;
    if (completed) {
      if (addByClient) {
        totalMinutes = calculateTotalMinutes(
          tasks && tasks.length > 0 ? tasks[0].totalTime : null
        );
      } else {
        totalMinutes = tasks && tasks.length > 0 ? tasks[0].totalTime : null;
      }
      form.setFieldsValue({
        clientTask: tasks && tasks.length > 0 ? tasks[0].id : undefined,
        timeSpent: tasks && tasks.length > 0 ? totalMinutes : null,
      });
    }
  }, [completed, tasks]);

  useEffect(() => {
    let visaTypeOptions = [];
    if (!tasks.isPotential) {
      visaTypeOptions =
        visaAppData &&
        visaAppData.items.length > 0 &&
        visaAppData.items.map((visaType) => (
          <Option value={visaType.branchVisaTypeId}>
            {visaType.branchVisaTypeName}
          </Option>
        ));
    } else {
      visaTypeOptions =
        visaTypeData &&
        visaTypeData.items &&
        visaTypeData.items.length > 0 &&
        visaTypeData.items.map((item, ind) => (
          <Option value={item.id}>{item.visaTypeName}</Option>
        ));
    }
    setVisaTypeOpt(visaTypeOptions);
  }, [visaAppData, visaTypeData]);

  const onFinish = (values) => {
    let data = {
      visaId: values.visaTypeId ? parseInt(values.visaTypeId) : 0,
      serviceId: values.serviceType ? parseInt(values.serviceType) : 0,
      workTypeId: values.visaWorkType ? parseInt(values.visaWorkType) : 0,
      timeSpent: values.timeSpent ? parseInt(values.timeSpent) : 0,
      description: values.description || "",
      taskId: values.clientTask ? parseInt(values.clientTask) : 0,
      clientId: clientId,
    };
    const addTimeTrackingOpt = {
      url: `v1/timetracking`,
    };
    addTimeTrackingOpt.types = [
      "ADD_TIME_TRACKING_SUCCESS",
      "ADD_TIME_TRACKING_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .post(addTimeTrackingOpt, data)
      .then((res) => {
        setLoading(false);
        message.success("Time Tracking added successfuly!");
        addSuccess();
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed!");
      });
  };

  const changeType = (value) => {
    setIsVisaType(value);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form
          onFinish={onFinish}
          className="emp-content-box p-box add-job-form width-100"
          name="main"
          form={form}
        >
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Select Type</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="selectType"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select
                  style={{ width: 300 }}
                  placeholder="Select Type"
                  value={isVisaType}
                  onChange={changeType}
                >
                  <Option value={0}>Select Type</Option>
                  <Option value={1}>Visa Type</Option>
                  <Option value={2}>Service Type</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {isVisaType != 0 && (
            <>
              {isVisaType == 1 ? (
                <Row gutter={8} className="margin-top">
                  <Col span={8}>
                    <p style={{ fontSize: 13 }}>Visa Type</p>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="visaTypeId"
                      // rules={[{ required: true, message: "Required!" }]}
                    >
                      <Select
                        style={{ width: 300 }}
                        placeholder=""
                        // onChange={changePipeline}
                      >
                        {visaTypeOpt}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={8} className="margin-top">
                  <Col span={8}>
                    <p style={{ fontSize: 13 }}>Service Type</p>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="serviceType"
                      // rules={[{ required: true, message: "Required!" }]}
                    >
                      <Select
                        style={{ width: 300 }}
                        placeholder=""
                        // onChange={changePipeline}
                      >
                        {serviceTypeRes &&
                          serviceTypeRes.items &&
                          serviceTypeRes.items.length > 0 &&
                          serviceTypeRes.items.map((item, ind) => (
                            <Option value={item.id}>{item.serviceName}</Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}

          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Visa Work Type</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="visaWorkType"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select style={{ width: 300 }} placeholder="">
                  {visaWorkTypeRes &&
                    visaWorkTypeRes.items &&
                    visaWorkTypeRes.items.length > 0 &&
                    visaWorkTypeRes.items.map((item, ind) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Client Task</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="clientTask"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select style={{ width: 300 }} placeholder="">
                  {tasks &&
                    tasks.length > 0 &&
                    tasks.map((item, ind) => (
                      <Option value={item.id}>{item.taskTitle}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Time Spent(Min)</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="timeSpent"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Description</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="description"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="margin-top-20">
            <div className="letter-froala emp-froala">
              <div className="document-checklist--btn d-flex">
                <Button
                  style={{ marginRight: "10px" }}
                  // onClick={() => emailTypeData(data.emailType)}
                  htmlType="submit"
                  type="primary"
                  className="button-blue"
                  loading={loading}
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="button-blue"
                >
                  Close
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default AddTimeTrackingForm;
