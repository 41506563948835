import { api } from "../../services/api";
import * as types from "../Constants";

export const getClientEmployer = () => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  const options = {
    url: `v1/clientemployer/All/${clientprofileid}`,
  };
  options.types = [
    types.GET_CLIENT_EMPLOYER_SUCCESS,
    types.GET_CLIENT_EMPLOYER_FAILURE,
  ];

  return api.get(options);
};

export const addClientEmployer = (data) => {
  const options = {
    url: "v1/clientemployer",
  };
  options.types = [
    types.ADD_CLIENT_EMPLOYER_SUCCESS,
    types.ADD_CLIENT_EMPLOYER_FAILURE,
  ];

  return api.post(options, data);
};

export const updClientEmployer = (data) => {
  const options = {
    url: "v1/clientemployer",
  };
  options.types = [
    types.UPDATE_CLIENT_EMPLOYER_SUCCESS,
    types.UPDATE_CLIENT_EMPLOYER_FAILURE,
  ];

  return api.put(options, data);
};

export const removeClientEmployer = (data) => {
  const options = {
    url: "v1/clientemployer",
  };
  options.types = [
    types.DELETE_CLIENT_EMPLOYER_SUCCESS,
    types.DELETE_CLIENT_EMPLOYER_FAILURE,
  ];

  return api.delete(options, data);
};

export const linkEmployer = (data) => {
  const options = {
    url: "v1/clientemployer/LinkEmployer",
  };
  options.types = [types.LINK_EMPLOYER_SUCCESS, types.LINK_EMPLOYER_FAILURE];

  return api.post(options, data);
};
