// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  Row,
  Tooltip,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import { DeleteOutlined } from "@ant-design/icons";
import { apiRefresh } from "../../services/api";

function Medicals(Props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const dateFormat = "DD/MM/YYYY";

  const [form] = Form.useForm();

  useEffect(() => {
    if (Props.clientInfo) {
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);

      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 2
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
          er: Props.clientInfo.medicals[0].er,
        };
        setContent(medicalData.medicalNotesDetail);
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }

      form.setFieldsValue({
        visaCountryId: Props.clientInfo.visaCountryId.toString(),
        visaCountryType: Props.clientInfo.visaCountyType.toString(),
        title: Props.clientInfo.title,
        middleName: Props.clientInfo.middleName,
        gender: Props.clientInfo.gender,
        dateOfBirth: moment(Props.clientInfo.dateOfBirth),
        address: clientAddressData ? clientAddressData.address : "",
        nationalityId: Props.clientInfo.nationalityId,
        saleDate: moment(Props.clientInfo.saleDate),
        sourceId: Props.clientInfo.sourceId.toString(),
        jobSectorId: Props.clientInfo.jobSectorId.toString(),
        companyOptional: Props.clientInfo.companyOptional,
        clientSerial: Props.clientInfo.clientSerial,
        nationalityCountry: Props.clientInfo.nationalityCountry,
        firstName: Props.clientInfo.firstName,
        lastName: Props.clientInfo.lastName,
        age: Props.clientInfo.age,
        dealWorth: Props.clientInfo.dealWorth,
        maritalStatus: Props.clientInfo.maritalStatus,
        dependentChildren: Props.clientInfo.dependentChildren,
        sourceDescription: Props.clientInfo.sourceDescription,
        occupation: Props.clientInfo.occupation,
        visaDenied: Props.clientInfo.visaDenied,
        deniedText: Props.clientInfo.deniedText,
        visaText: Props.clientInfo.visaText,
        currentVisaTypeId: Props.clientInfo.currentVisaTypeId.toString(),
        currentNewZealandVisaExpiry: moment(
          Props.clientInfo.currentNewZealandVisaExpiry
        ),
        travelConditionsValidTo: moment(
          Props.clientInfo.travelConditionsValidTo
        ),
        clientNumber: Props.clientInfo.clientNumber,
        inzUserName: Props.clientInfo.inzUserName,
        inzPassword: Props.clientInfo.inzPassword,
        inzFeeDate: moment(Props.clientInfo.inzFeeDate),
        nzqaOnlineSubDate: moment(Props.clientInfo.nzqaOnlineSubDate),
        nzqaDocumentSubDate: moment(Props.clientInfo.nzqaDocumentSubDate),
        nzqaDocumentRetDate: moment(Props.clientInfo.nzqaDocumentRetDate),
        email: email,
        secondaryEmail: secondaryEmail,
        otherEmail: otherEmail,
        contactPerson: billAddressData ? billAddressData.contactPerson : "",
        flat: billAddressData ? billAddressData.flat : "",
        streetNumber: billAddressData ? billAddressData.streetNumber : "",
        suburb: billAddressData ? billAddressData.suburb : "",
        city: billAddressData ? billAddressData.city : "",
        billCountry: billAddressData ? billAddressData.country : "",
        zipcode: billAddressData ? billAddressData.zipcode : "",
        clientAddress: clientAddressData ? clientAddressData.address : "",
        mobilePhone: phoneData ? phoneData.mobile : "",
        secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
        overseasMobile: phoneData ? phoneData.overseasMobile : "",
        landLine: phoneData ? phoneData.landLine : "",
        otherMobile: phoneData ? phoneData.otherMobile : "",
        er: medicalData && medicalData.er ? medicalData.er : "",
        medicalIssueDate:
          medicalData &&
          medicalData.medicalIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalIssueDate)
            : "",
        medicalExpiryDate:
          medicalData &&
          medicalData.medicalExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.medicalExpiryDate)
            : "",
        medicalGrading: medicalData ? medicalData.medicalGrading : "",
        xrayIssueDate:
          medicalData &&
          medicalData.xrayIssueDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayIssueDate)
            : "",
        xrayExpiryDate:
          medicalData &&
          medicalData.xrayExpiryDate !== "1900-01-01T00:00:00+00:00"
            ? moment(medicalData.xrayExpiryDate)
            : "",
        xrayGrading: medicalData ? medicalData.xrayGrading : "",
        medicalNotes: medicalData ? medicalData.medicalNotes : "",
        medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
        passportNo:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportNo
            : "",
        passportCountry:
          Props.clientInfo.passports.length > 0
            ? Props.clientInfo.passports[0].passportCountry.toString()
            : "",
        passportIssueDate:
          Props.clientInfo.passports.length > 0
            ? moment(Props.clientInfo.passports[0].passportIssueDate)
            : "",
        passportExpiryDate:
          Props.clientInfo.passports.length > 0
            ? moment(Props.clientInfo.passports[0].passportExpiryDate)
            : "",
        secondPassportNo:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportNo
            : "",
        secondPassportCountry:
          Props.clientInfo.passports.length > 1
            ? Props.clientInfo.passports[1].passportCountry.toString()
            : "",
        secondPassportIssueDate:
          Props.clientInfo.passports.length > 1
            ? moment(Props.clientInfo.passports[1].passportIssueDate)
            : "",
        secondPassportExpiryDate:
          Props.clientInfo.passports.length > 1
            ? moment(Props.clientInfo.passports[1].passportExpiryDate)
            : "",
        customFields:
          Props.clientInfo.customFields || Props.clientInfo.customFields || [],
      });
    }
  }, [Props, form]);

  const { Option } = Select;
  const country = [];
  const authorityOption = [];
  const visaTypeOption = [];
  if (Props.countriesData && Props.countriesData.items.length > 0) {
    for (let i = 0; i < Props.countriesData.items.length; i++) {
      country.push(
        <Option key={Props.countriesData.items[i].id}>
          {Props.countriesData.items[i].name}
        </Option>
      );
    }
  }

  if (Props.accessingAuthData && Props.accessingAuthData.length > 0) {
    for (let i = 0; i < Props.accessingAuthData.length; i++) {
      authorityOption.push(
        <Option key={Props.accessingAuthData[i].id}>
          {Props.accessingAuthData[i].name}
        </Option>
      );
    }
  }

  if (Props.visaTypeData && Props.visaTypeData.items.length > 0) {
    for (let i = 0; i < Props.visaTypeData.items.length; i++) {
      visaTypeOption.push(
        <Option key={Props.visaTypeData.items[i].id}>
          {Props.visaTypeData.items[i].visaTypeName}
        </Option>
      );
    }
  }

  const onContentChange = (model) => {
    setContent(model);
  };

  const updateMedical = (values) => {
    Props.updateProfileMedicals(values, content, Props.clientInfo);
  };

  return (
    <div>
      <Form form={form} onFinish={updateMedical}>
        <div
          className="denied-cont"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div
            className="denied-cont"
            style={{ padding: 0, alignItems: "center" }}
          >
            <Tooltip title="Hide Container Fields">
              <div
                className="profile-down-arrow-cont"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  Props.showHideContainers({
                    type: "medical_detail",
                    status: true,
                  })
                }
              >
                <img
                  src={Images.whiteArrow}
                  className="profile-down-arrow-icon"
                />
              </div>
            </Tooltip>
            <span className="denied-text">Medical Detail</span>
          </div>
          <Form.Item>
            <Button className="button-blue" htmlType="submit">
              <span style={{ color: "#FFFFFF" }}>Update</span>
            </Button>
          </Form.Item>
        </div>

        <div className="form-container">
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingTop: 20,
              paddingBottom: 0,
              display: "block",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 15,
              }}
            >
              <p className="medical-label">NZER#</p>
              <div className="profile-input-border" style={{ width: "48%" }}>
                <Form.Item name="er">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Medical Certificate Issue Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "48%",
                }}
              >
                <Form.Item name="medicalIssueDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Medical Certificate Expiry</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "48%",
                }}
              >
                <Form.Item name="medicalExpiryDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Medical Grading</p>
              <div style={{ width: "48%", paddingLeft: 1 }}>
                <div>
                  <Form.Item name="medicalGrading">
                    <Select showSearch optionFilterProp="children">
                      <Option value="A">A</Option>
                      <Option value="AB">AB</Option>
                      <Option value="B">B</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Xray Issue Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "48%",
                }}
              >
                <Form.Item name="xrayIssueDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Xray Expiry Date</p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "48%",
                }}
              >
                <Form.Item name="xrayExpiryDate" style={{ width: "100%" }}>
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Xray Grading</p>
              <div style={{ width: "48%", paddingLeft: 1 }}>
                <div>
                  <Form.Item name="xrayGrading">
                    <Select showSearch optionFilterProp="children">
                      <Option value="A">A</Option>
                      <Option value="AB">AB</Option>
                      <Option value="B">B</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label">Medical Notes</p>
              <div style={{ width: "48%", paddingLeft: 1 }}>
                <div>
                  <Form.Item name="medicalNotes">
                    <Select showSearch optionFilterProp="children">
                      <Option value="ASH">ASH</Option>
                      <Option value="ASH with Conditions">
                        ASH with Conditions
                      </Option>
                      <Option value="Not ASH">Not ASH</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <Form.List name="customFields">
              {(customFields, { add, remove }) => (
                <div>
                  <div>
                    {customFields.map((field, index) => {
                      return (
                        <>
                          {Props.clientInfo &&
                            Props.clientInfo.customFields.length > 0 &&
                            Props.clientInfo.customFields[index] &&
                            Props.clientInfo.customFields[index].fieldFor ===
                              "Medical_detail" && (
                              <div
                                key={field.key}
                                style={{
                                  display: "flex",
                                  marginTop: 10,
                                  justifyContent: "space-between",
                                }}
                              >
                                <p className="medical-label">
                                  {Props.clientInfo &&
                                    Props.clientInfo.customFields &&
                                    Props.clientInfo.customFields[index]
                                      .fieldName}
                                </p>
                                <div style={{ display: "flex", width: "48%" }}>
                                  <div
                                    className="profile-input-border"
                                    style={{ width: "100%" }}
                                  >
                                    <Form.Item
                                      {...field}
                                      fieldKey={[field.fieldKey, "first"]}
                                      name={[field.name, "fieldData"]}
                                    >
                                      {Props.clientInfo &&
                                      Props.clientInfo.customFields &&
                                      Props.clientInfo.customFields[index]
                                        .fieldType === "1" ? (
                                        <Input className="profile-input" />
                                      ) : Props.clientInfo &&
                                        Props.clientInfo.customFields &&
                                        Props.clientInfo.customFields[index]
                                          .fieldType === "2" ? (
                                        <DatePicker format="DD/MM/YYYY" />
                                      ) : (
                                        <Input.TextArea rows={4} />
                                      )}
                                    </Form.Item>
                                  </div>
                                  {/* <div style={{ marginTop: 3, marginLeft: 5 }}>
                                    <a
                                      href="javascript:"
                                      onClick={() => {
                                        let data = {
                                          id:
                                            Props.clientInfo.customFields[index]
                                              .id,
                                        };
                                        var delCustomFieldOpt = null;

                                        delCustomFieldOpt = {
                                          url: `v1/client/customField`,
                                        };

                                        delCustomFieldOpt.types = [
                                          "DELETE_CUSTOM_FIELD_SUCCESS",
                                          "DELETE_CUSTOM_FIELD_FAILURE",
                                        ];
                                        setLoading(true);
                                        apiRefresh
                                          .delete(delCustomFieldOpt, data)
                                          .then((res) => {
                                            setLoading(false);
                                            // Props.clientInfo.customFields.splice(
                                            //   index,
                                            //   1
                                            // );
                                            // remove(field.name);
                                            Props.onGetClientProfile(
                                              sessionStorage.getItem(
                                                "clientprofileid"
                                              )
                                            );
                                          })
                                          .catch((err) => {
                                            setLoading(false);
                                          });
                                      }}
                                    >
                                      <DeleteOutlined color="rgb(51, 170, 218)" />
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            )}
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </Form.List>
            <div style={{ marginTop: 20 }}>
              <Spin size={"large"} spinning={loading}>
                <FroalaEditorCom
                  setLoading={(value) => setLoading(value)}
                  model={content}
                  onModelChange={onContentChange}
                />
              </Spin>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Medicals;
