import React from "react";
import "./SchoolManagementStyles.css";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Checkbox, Modal, Button, message } from "antd";
import { CheckCircleTwoTone, RedoOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getCommissionRemindersListing,
  editCommissionReminder,
} from "../store/Actions";

let remindersTabs = [
  {
    label: "Pending Reminders",
  },
  {
    label: "Completed Reminders",
  },
];

class CommissionReminder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
    };
  }

  componentDidMount() {
    this.props.getCommissionRemindersListing(false, "");
    this.setState({
      activeTab: remindersTabs && remindersTabs[0].label,
    });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _commissionReminders =
      nextProps &&
      nextProps.commissionRemindersListing &&
      nextProps.commissionRemindersListing &&
      nextProps.commissionRemindersListing.items;
    this.setState({
      commissionReminders: _commissionReminders,
    });
  }

  handleCancelModalCheckedForCompletedReminder = () => {
    this.setState({
      visibleModalCheckedForCompletedReminder: false,
    });
  };

  handleCancelModalCheckedForPendingReminder = () => {
    this.setState({
      visibleModalCheckedForPendingReminder: false,
    });
  };

  onMarkReminderAsCompleted = () => {
    let data = {
      id: this.state.reminderId,
      isCompleted: this.state.isCompleted,
    };
    this.props
      .editCommissionReminder(data)
      .then(() => {
        message.success("Reminder marked as completed successfully!");
        this.props.getCommissionRemindersListing(false);
        this.setState({
          visibleModalCheckedForCompletedReminder: false,
          reminderId: null,
        });
      })
      .catch(() => {
        message.warning("An error occurred!");
      });
  };

  onMarkReminderAsPending = () => {
    let data = {
      id: this.state.reminderId,
      isCompleted: this.state.isCompleted,
    };
    this.props
      .editCommissionReminder(data)
      .then(() => {
        message.success("Reminder marked as pending successfully!");
        this.props.getCommissionRemindersListing(true);
        this.setState({
          visibleModalCheckedForPendingReminder: false,
          reminderId: null,
        });
      })
      .catch(() => {
        message.warning("An error occurred!");
      });
  };

  getModalCheckedForCompletedReminder = () => {
    return (
      <div>
        <Modal
          title="Update"
          visible={this.state.visibleModalCheckedForCompletedReminder}
          onCancel={this.handleCancelModalCheckedForCompletedReminder}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to mark it as completed?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelModalCheckedForCompletedReminder();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.onMarkReminderAsCompleted();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getModalCheckedForPendingReminder = () => {
    return (
      <div>
        <Modal
          title="Update"
          visible={this.state.visibleModalCheckedForPendingReminder}
          onCancel={this.handleCancelModalCheckedForPendingReminder}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to mark it as pending?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelModalCheckedForPendingReminder();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.onMarkReminderAsPending();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  onChangeCheckedBox = (e, data) => {
    this.setState({
      visibleModalCheckedForCompletedReminder: true,
      reminderId: data.id,
      isCompleted: true,
    });
  };

  getCompletedCommissionReminders = () => {
    return (
      <div>
        {this.state.commissionReminders &&
          this.state.commissionReminders.map((data, index) => {
            return (
              <div>
                <div key={index} style={{ marginBottom: "40px" }}>
                  <Row>
                    <Col xs={24} style={{ display: "flex" }}>
                      <div style={{ marginRight: "17px" }}>
                        {this.state.activeTab === "Completed Reminders" ? (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        ) : (
                          <Checkbox
                            checked={this.state.reminderId === data.id}
                            onChange={(e) => this.onChangeCheckedBox(e, data)}
                          />
                        )}
                      </div>
                      <h4>{"School Commission Reminder"}</h4>
                      {this.state.activeTab === "Completed Reminders" ? (
                        <RedoOutlined
                          className={"redo-icon"}
                          onClick={() => {
                            this.setState({
                              visibleModalCheckedForPendingReminder: true,
                              reminderId: data.id,
                              isCompleted: false,
                            });
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{moment(data.reminderDate).format("DD/MM/YYYY")}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{data.description}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ display: "flex" }}>
                        <p
                          style={{ marginRight: "7px" }}
                        >{`Amount: ${parseFloat(data.amount)}`}</p>
                        <p>{`Client: ${
                          data.relatedSubjectName
                            ? data.relatedSubjectName
                            : "NA"
                        }`}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{`School: ${
                        data.subjectName ? data.subjectName : "NA"
                      }`}</p>
                    </Col>
                  </Row>
                </div>
                {this.getModalCheckedForCompletedReminder()}
              </div>
            );
          })}
      </div>
    );
  };

  onChangeTab = (value) => {
    this.setState({ activeTab: value });
    if (value === "Pending Reminders") {
      this.props.getCommissionRemindersListing(false);
    } else {
      this.props.getCommissionRemindersListing(true);
    }
  };

  getTabs = () => {
    return (
      <Row>
        <Col>
          <div className="sus-tab-container-schools">
            {remindersTabs.map((data, index) => {
              return (
                <div
                  onClick={() => this.onChangeTab(data.label)}
                  className={
                    this.state.activeTab == data.label
                      ? "sus-active-tab-schools"
                      : "sus-inactive-tab-schools"
                  }
                  key={index}
                >
                  <span
                    className={
                      this.state.activeTab == data.label
                        ? "sus-active-tab-text-school"
                        : "sus-inactive-tab-text-school"
                    }
                  >
                    {data.label}
                  </span>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <div style={{ display: "flex", height: "100vh" }}>
          <div className="page-container">
            {this.getTabs()}
            <div className="ts-container-commission-reminders">
              <div style={{ display: "block" }}>
                {this.getCompletedCommissionReminders()}
                {this.getModalCheckedForCompletedReminder()}
                {this.getModalCheckedForPendingReminder()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commissionRemindersListing:
      state &&
      state.commissionRemindersReducer &&
      state.commissionRemindersReducer.commissionRemindersListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCommissionRemindersListing: bindActionCreators(
    getCommissionRemindersListing,
    dispatch
  ),
  editCommissionReminder: bindActionCreators(editCommissionReminder, dispatch),
});

CommissionReminder = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionReminder);

export default CommissionReminder;
