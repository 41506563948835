import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaUpdateNotification from "../AccountSetting/VisaUpdateNotification/VisaUpdateNotification";

import {
  setActiveInnerTab,
  updateVisaNotification,
  getVisaNotification,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaNotificationRes: state.visaNotificationReducer.visaNotificationRes,
    getVisaNotificationRes:
      state.visaNotificationReducer.getVisaNotificationRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateVisaNotification: bindActionCreators(
      updateVisaNotification,
      dispatch
    ),
    onGetVisaNotification: bindActionCreators(getVisaNotification, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisaUpdateNotification);
