import { api } from "../../services/api";
import * as types from "../Constants";

export const getBranch = (data) => {
  const options = {
    url: "v1/branch/All",
  };

  options.types = [types.BRANCH_SUCCESS, types.BRANCH_FAILURE];

  return api.get(options);
};

export const getUpdatedBranch = (data) => {
  var companyId = localStorage.getItem("companyId");
  const options = {
    url: "v1/branch/AllWithLinks",
  };

  options.types = [types.BRANCH_SUCCESS, types.BRANCH_FAILURE];

  return api.get(options);
};

export const getUserBranch = () => {
  const options = {
    url: "v1/user/Branch",
  };

  options.types = [types.USER_BRANCH_SUCCESS, types.USER_BRANCH_FAILURE];

  return api.get(options);
};

export const addBranch = (data) => {
  const options = {
    url: "v1/branch",
  };

  options.types = [types.ADD_BRANCH_SUCCESS, types.ADD_BRANCH_FAILURE];

  return api.post(options, data);
};

export const updBranch = (data) => {
  const options = {
    url: "v1/branch",
  };

  options.types = [types.UPDATE_BRANCH_SUCCESS, types.UPDATE_BRANCH_FAILURE];

  return api.put(options, data);
};

export const setSelectedBranchId = (branch) => {
  // return {
  //   type: types.GET_BRANCH_SELECTED,
  //   payload: branch
  // };
  const data = {
    type: types.GET_BRANCH_SELECTED,
    payload: branch,
  };
  return data;
};
