import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, message, Spin } from "antd";
import { Images } from "./../../../../Themes";
import FileNotesList from "./FileNotesList";
import FroalaEditorCom from "../../../../Containers/FroalaEditorCom";
import { apiRefresh } from "../../../../services/api";

const { Option } = Select;

const FileNotes = ({
  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetLetterTemplates,
  LetterTemplatesRes,

  userDataEmp,

  onRemoveEmployerFile,

  handleCancel,

  onUpdateEmployerFile,

  onAddAnyTamplate,
  addAnyTemRes,
  onGetPermProcessingPerson,
  tabKey,
}) => {
  var userId = localStorage.getItem("userId");
  const [editorData, setEditorData] = useState("");
  const [dataSource, setDataSource] = useState({});
  const [fileNoteRes, setFileNoteRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFileNote, SetIsFileNote] = useState(false);
  const [fileNotesCount, setFileNotesCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    // setLoading(true);
    letterTempaltesFilter(LetterTemplatesRes);
    // setLoading(false);
  }, [LetterTemplatesRes]);

  useEffect(() => {
    onGetLetterTemplates();
  }, [onGetLetterTemplates]);

  useEffect(() => {
    if (tabKey == "2") {
      setLoading(true);
      SetIsFileNote(true);

      getFileNotes();
    }
  }, [isFileNote, onGetEmployerFile, userDataEmp, tabKey]);

  const getFileNotes = (payloadData) => {
    setLoading(true);
    let pageNumb =
      payloadData && payloadData.pageNumber
        ? payloadData.pageNumber
        : pageNumber;
    let pageSizee =
      payloadData && payloadData.pageSize ? payloadData.pageSize : pageSize;
    const fileNotesOpt = {
      url: `v1/employer/filenote/All/${userDataEmp.id}/${pageNumb}/${pageSizee}`,
    };
    fileNotesOpt.types = ["GET_FILE_NOTE_SUCCESS", "GET_FILE_NOTE_FAILURE"];

    apiRefresh.get(fileNotesOpt).then((res) => {
      if (res && res.items && res.items.length > 0) {
        setFileNoteRes(
          payloadData && payloadData.pageNumber
            ? res.items.concat(fileNoteRes)
            : fileNoteRes.concat(res.items)
        );
        setFileNotesCount(res.count);
        setPageNumber(
          payloadData && payloadData.pageNumber ? pageNumber : pageNumber + 1
        );
        setLoading(false);
      } else {
        setFileNotesCount([]);
        setFileNotesCount(0);
        setLoading(false);
      }
    });
  };

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "FILENOTE") {
          filterList.push(data);
        }
      });
      setDataSource(filterList);
    }
  };

  const handleData = (model) => {
    setEditorData(model);
  };

  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editorData && editorData.length > 0) {
      setLoading(true);
      console.log("Received values of form:", values);

      const data = {
        note: editorData,
        employerId: userDataEmp && userDataEmp.id,
      };

      onAddEmployerFile(data)
        .then(() => {
          // onGetEmployerFile(userDataEmp && userDataEmp.id);
          getFileNotes({ pageNumber: 1, pageSize: 1 });
          setLoading(false);
          message.success("Successfully Added!");
          setEditorData("");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      message.warning("Add some text first");
    }

    // handleCancel();
  };

  const handeFileDropdown = (id) => {
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = ["GET_CONTRACT_SUCCESS", "GET_CONTRACT_FAILURE"];
    apiRefresh.get(getContractOpt).then((resp) => {
      const data = {
        templateName: resp.content,
        parameters: [
          {
            key: "ClientId",
            value: userDataEmp && userDataEmp.id,
          },
          {
            key: "UserId",
            value: localStorage.getItem("userId"),
          },
        ],
      };

      onAddAnyTamplate(data).then((res) => {
        setEditorData(res.payload);
      });
    });
  };

  const onUdateFileNoteState = (isDelete, index, fileNoteData) => {
    let fileNotes = [...fileNoteRes];
    if (isDelete) {
      fileNotes.splice(index, 1);
    } else {
      fileNotes[index].note = fileNoteData;
    }
    setFileNoteRes(fileNotes);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <div className="border-box filenote-box">
            <h4
              className="top-text heading-forms"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                marginLeft: "2px",
                fontFamily: "Poppins",
              }}
            >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
            <div className="d-flex align-center  space-between">
              <Row>
                <Col>
                  <Form.Item>
                    <Select
                      style={{ width: "246px" }}
                      mode="single"
                      showArrow
                      checked={false}
                      onChange={(value) => handeFileDropdown(value)}
                      placeholder="Templates"
                    >
                      {dataSource &&
                        dataSource.length > 0 &&
                        dataSource.map((item, index) => (
                          <Option key={item.id}> {item.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Col
              // className="profile-print-box"
              >
                {/*<img src={Images.printWhite} className="profile-print-icon" />*/}
              </Col>
            </div>
            <Row>
              <Col span={24}>
                <div className="margin-top  letter-froala">
                  <FroalaEditorCom
                    setLoading={(value) => setLoading(value)}
                    model={editorData}
                    onModelChange={handleData}
                  />
                </div>
              </Col>
            </Row>
            <Form.Item className="d-flex filenote-btn">
              <Button className="button-blue" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>

            {fileNoteRes
              .sort((a, b) => b.createdDate.localeCompare(a.createdDate))
              .map((item, index) => (
                <FileNotesList
                  item={item}
                  index={index}
                  onRemoveEmployerFile={onRemoveEmployerFile}
                  onGetEmployerFile={onGetEmployerFile}
                  userDataEmp={userDataEmp}
                  onUpdateEmployerFile={onUpdateEmployerFile}
                  setLoading={setLoading}
                  loading={loading}
                  updateFileNoteState={onUdateFileNoteState}
                />
              ))}
            {fileNotesCount > 5 && fileNoteRes.length !== fileNotesCount && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span
                  style={{
                    color: "#56B1F9",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={getFileNotes}
                >
                  Show More
                </span>
              </div>
            )}
          </div>
        </Form>
      </Spin>
    </Fragment>
  );
};

export default FileNotes;
