import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  message,
  Col,
  InputNumber,
  Spin,
  Collapse,
  Modal,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import {
  addEmailDocument,
  addInvoicePayment,
  addNewTemplate,
  addTemplateToClientAppliedTemplateListing,
  deleteInvoice,
  deleteInvoicePayment,
  editInvoice,
  editTemplateForClientAppliedTemplateListing,
  getAllBanksListing,
  getAllTaxesListing,
  getBranch,
  getClientProfile,
  getClientTemplateListing,
  getCompany,
  getInvoiceInfo,
  getInvoicePDFHtmlBlob,
  getInvoiceReceiptPDFHtmlBlob,
  getInvoiceStatuses,
  getPDF,
  getPDFInvoicePayment,
  getTemplateListing,
  setEmailTemplate,
  setEmailTemplatePayment,
  setInvoicesDataNull,
} from "../store/Actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AddTemplateInvoice from "./AddTemplateInvoice";
import moment from "moment";
import SendEmailTemplate from "./SendEmailTemplate";
import SendEmailTemplatePayment from "./SendEmailTemplatePayment";
import SendEmailFromInvoiceListing from "./SendEmailFromInvoiceListing";
const { Option } = Select;
const { Panel } = Collapse;
const dateFormat = "DD/MM/YYYY";

class StageClientTemplate extends React.Component {
  formRefTemp = React.createRef();
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userId = localStorage.getItem("userId");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    this.state = {
      userId: userId,
      userEmail: userEmail,
      name: "",
      stages: [],
      description: "",
      total: 0,
      id: 0,
      deposit: undefined,
      totalStagedInvoices: 0,
      amount: 0,
      receivedAmount: 0,
      taxInclusive: false,
      gst: 0,
      invoices: [],
      showAddTemplateInvoice: false,
      payments: [],
      followUps: [],
      stageAmount: 0,
      visibleDeleteModalInvoice: false,
      branchId: selectedBranchId,
      loadingPaymentModal: false,
      clientProfileId: clientprofileid,
      visibleDeleteModalInvoicePayment: false,
      loadingPaymentModalDelete: false,
      addedPaymentAmount: 0,
      visibleSendingReceiptModalForInvoiceListing: false,
    };
  }

  componentDidMount() {
    this.props.getClientProfile(this.state.clientProfileId);
    this.props.getAllBanksListing();
    this.props.getBranch();
    let _data = this.props && this.props.data;
    let _branchData = this.props.branchData;
    let _branchName;
    if (_branchData && _branchData.length > 0) {
      for (
        let branchIndex = 0;
        branchIndex < _branchData.length;
        branchIndex++
      ) {
        if (_branchData[branchIndex].id === this.state.branchId) {
          _branchName = _branchData[branchIndex].name;
        }
        this.setState({ branchName: _branchName });
      }
    }
    let _receivedAmount = 0;
    let _invoicedAmount = 0;
    let _stageAmount = _data.amount;
    let _stageGstAmount = (_stageAmount * (_data && _data.gst)) / 100;
    let _followUps = [];
    let _payments = [];
    let _amount = 0;
    if (_data && _data.invoice && _data.invoice.length > 0) {
      for (
        let _indicatorTemp = 0;
        _indicatorTemp < _data.invoice.length;
        _indicatorTemp++
      ) {
        let _items =
          _data.invoice[_indicatorTemp].payment &&
          _data.invoice[_indicatorTemp].payment;

        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        _receivedAmount = _amount;
        _invoicedAmount = _invoicedAmount + _data.invoice[_indicatorTemp].total;
        _followUps = _followUps.concat(_data.invoice[_indicatorTemp].followUps);
        _payments = _payments.concat(_data.invoice[_indicatorTemp].payment);
      }
    }
    this.setState(
      {
        stageAmount: _stageAmount,
        stageGstAmount: _stageGstAmount,
        name: _data.name,
        id: _data.id,
        description: _data.description,
        amount: _data.amount,
        receivedAmount:
          Math.round((_receivedAmount + Number.EPSILON) * 100) / 100,
        invoicedAmount:
          Math.round((_invoicedAmount + Number.EPSILON) * 100) / 100,
        taxInclusive: _data.taxInclusive,
        gst: _data.gst,
        invoices: _data.invoice,
        payments: _payments,
        followUps: _followUps,
      },
      () => {}
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _data = this.props && this.props.data;
    let _receivedAmount = 0;
    let _invoicedAmount = 0;
    let _stageAmount = _data.amount;
    let _stageGstAmount = (_stageAmount * (_data && _data.gst)) / 100;
    let _followUps = [];
    let _payments = [];
    let _amount = 0;
    if (_data && _data.invoice && _data.invoice.length > 0) {
      for (
        let _indicatorTemp = 0;
        _indicatorTemp < _data.invoice.length;
        _indicatorTemp++
      ) {
        let _items =
          _data.invoice[_indicatorTemp].payment &&
          _data.invoice[_indicatorTemp].payment;

        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        _receivedAmount = _amount;
        _invoicedAmount = _invoicedAmount + _data.invoice[_indicatorTemp].total;
        _followUps = _followUps.concat(_data.invoice[_indicatorTemp].followUps);
        _payments = _payments.concat(_data.invoice[_indicatorTemp].payment);
      }
    }
    this.setState(
      {
        stageAmount: _stageAmount,
        stageGstAmount: _stageGstAmount,
        name: _data.name,
        id: _data.id,
        description: _data.description,
        amount: _data.amount,
        receivedAmount:
          Math.round((_receivedAmount + Number.EPSILON) * 100) / 100,
        invoicedAmount:
          Math.round((_invoicedAmount + Number.EPSILON) * 100) / 100,
        taxInclusive: _data.taxInclusive,
        gst: _data.gst,
        invoices: _data.invoice,
        payments: _payments,
        followUps: _followUps,
      },
      () => {}
    );
  }

  onClickGenerateNewInvoice = () => {
    this.props && this.props.setInvoicesDataNull();
    this.setState({ showAddTemplateInvoice: true }, () => {
      this.props &&
        this.props.getShowInvoice(
          this.state.showAddTemplateInvoice,
          this.props && this.props.data,
          0
        );
    });
  };

  getStageInfoStaticTable = () => {
    return (
      <div>
        <div className="ca-table-cont">
          <table style={{ width: "100%" }}>
            <tr className="ca-table-head-row">
              <th className="ca-head-text" style={{ paddingLeft: "9px" }}>
                Stage
              </th>
              <th className="ca-head-text">Amount</th>
              <th className="ca-head-text">Received</th>
              <th className="ca-head-text">Invoiced Amount</th>
            </tr>
            <tr className="ca-table-head-row" style={{ height: 32 }}>
              <td
                className="ca-td-row-text color-background-template-stage"
                style={{ paddingLeft: "9px" }}
              >
                {`Stage${this.props && this.props.index + 1}`}
              </td>
              <td
                className="ca-td-row-text"
                style={{ backgroundColor: "#889093" }}
              >
                {this.state.amount}
              </td>
              <td className="ca-td-row-text color-background-template-stage">
                {this.state.receivedAmount}
              </td>
              <td
                className="ca-td-row-text"
                style={{ backgroundColor: "#889093" }}
              >
                {this.state.invoicedAmount}
              </td>
            </tr>
          </table>
        </div>
        <Row>
          <Col xs={24} style={{ height: "auto" }}>
            <p style={{ marginLeft: "8px", wordBreak: "break-word" }}>
              {this.state.description}
            </p>
          </Col>
        </Row>
        <Row className="ca-table-cont">
          <Col xs={12} style={{ height: "36px" }}>
            {this.state.taxInclusive ? (
              <Checkbox
                style={{ marginLeft: "8px" }}
                className="template-des-position"
                checked={true}
              >
                {
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#889093",
                      fontSize: "10px",
                    }}
                  >
                    Tax inclusive
                  </span>
                }
              </Checkbox>
            ) : (
              <h6
                style={{ marginLeft: "8px", display: "initial" }}
                className="template-des-position"
              >
                {`gst ${this.state.gst} %`}
              </h6>
            )}
          </Col>
          <Col xs={8} offset={4}>
            {this.state.invoicedAmount <
            this.state.stageAmount + this.state.stageGstAmount ? (
              <Button
                style={{ marginTop: "6px" }}
                type="primary"
                size={"small"}
                onClick={this.onClickGenerateNewInvoice}
                className="button-blue"
              >
                GENERATE NEW INVOICE
              </Button>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  showModalDelete = (id, record) => {
    if (record && record.payment && record.payment.length === 0) {
      this.setState({
        visibleDeleteModalInvoice: true,
        index: id,
      });
    } else {
      message.error(
        "Please first delete all payments associated with the invoice!"
      );
    }
  };

  onClickEmail = (record) => {
    // this.props && this.props.setLoadingTrue();
    this.setState({ loading: true }, () => {
      this.props
        .getInvoiceInfo(record.id)
        .then((_res) => {
          let _invoiceInfo = _res && _res.payload;

          this.setState({ invoiceInfo: _invoiceInfo }, () => {
            this.props
              .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
              .then((res) => {
                let _statuses =
                  res && res.payload && res.payload.items && res.payload.items;
                this.setState({ statuses: _statuses }, () => {
                  let _tempInvoiceInfo = this.state.invoiceInfo;
                  let _statusId;
                  if (
                    _tempInvoiceInfo &&
                    _tempInvoiceInfo.invoiceTypeId === 2 &&
                    _tempInvoiceInfo.statusId === 27
                  ) {
                    _statusId = _tempInvoiceInfo.statusId;
                  } else {
                    if (
                      _tempInvoiceInfo.dueAmount !== 0 &&
                      _tempInvoiceInfo.dueAmount > 0
                    ) {
                      if (_statuses && _statuses.length > 0) {
                        _statusId = _statuses[0].id;
                      }
                    } else {
                      _statusId = _statuses[4].id;
                    }
                  }
                  _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
                  _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
                  _tempInvoiceInfo.statusId = _statusId;
                  _tempInvoiceInfo.dateStr = moment(
                    _tempInvoiceInfo.dateStr
                  ).format("DD/MM/YYYY");
                  _tempInvoiceInfo.dueDate = moment(
                    _tempInvoiceInfo.dueDate
                  ).format("DD/MM/YYYY");

                  this.setState(
                    {
                      invoiceInfo: _tempInvoiceInfo,
                    },
                    () => {
                      let _emailTemplatePayload = {
                        templateName: "invoice",
                        parameters: [
                          {
                            key: "ClientId",
                            value: this.state.clientProfileId,
                          },
                          {
                            key: "CurrencyId",
                            value: this.state.invoiceInfo.currencyId
                              ? this.state.invoiceInfo.currencyId.toString()
                              : "106",
                          },
                          {
                            key: "UserId",
                            value: this.state.userId,
                          },
                          {
                            key: "Content",
                            value: "Invoice",
                          },
                          {
                            key: "Amount",
                            value: this.state.invoiceInfo.total.toString(),
                          },
                          {
                            key: "InvoiceNumber",
                            value: this.state.invoiceInfo.invoiceNo,
                          },
                          {
                            key: "DueDate",
                            value: this.state.invoiceInfo.dueDate,
                          },
                        ],
                      };
                      this.props
                        .setEmailTemplate(_emailTemplatePayload)
                        .then(() => {
                          let _setEmailData = this.props.setEmailData;

                          this.setState({ setEmailData: _setEmailData }, () => {
                            this.setState({}, () => {
                              this.setState(
                                {
                                  currentPaymentId: 0,
                                },
                                () => {
                                  let _data = {
                                    invoiceId:
                                      this.state &&
                                      this.state.invoiceInfo &&
                                      this.state.invoiceInfo.id,
                                    paymentId: this.state.currentPaymentId,
                                  };
                                  this.props
                                    .getInvoicePDFHtmlBlob(_data)
                                    .then((res) => {
                                      let formData = new FormData();

                                      let binaryString = window.atob(
                                        res.payload.url
                                      );
                                      let binaryLen = binaryString.length;
                                      let bytes = new Uint8Array(binaryLen);
                                      for (let i = 0; i < binaryLen; i++) {
                                        let ascii = binaryString.charCodeAt(i);
                                        bytes[i] = ascii;
                                      }
                                      let _blob = new Blob([bytes], {
                                        type: "application/pdf",
                                      });
                                      let metadata = {
                                        type: "application/pdf",
                                      };
                                      let file = new File(
                                        [_blob],
                                        res.payload.name,
                                        metadata
                                      );

                                      formData.append(
                                        "BranchId",
                                        localStorage.getItem("selectedBranchId")
                                      );

                                      formData.append("files", file);
                                      this.props
                                        .onAddEmailDocument(formData)
                                        .then((res) => {
                                          let _attData = res && res.payload;
                                          let attDataArray = [
                                            {
                                              name: _attData[0].fileName,
                                              type: _attData[0].contentType,
                                              url: _attData[0].fileUrl,
                                            },
                                          ];
                                          this.props &&
                                            this.props.setLoadingFalse();

                                          this.setState({
                                            attDataArray: attDataArray,
                                            loading: false,
                                            visibleSendingReceiptModalForInvoiceListing: true,
                                          });
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                    })
                                    .catch(() => {
                                      this.setState({ loading: false });
                                    });
                                }
                              );
                            });
                          });
                        })
                        .catch(() => {
                          this.setState({ loading: false });
                        });
                    }
                  );
                });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  };

  showModalDeletePayment = (id, invoiceId, amount) => {
    this.setState({
      visibleDeleteModalInvoicePayment: true,
      indexPayment: id,
      invoiceId: invoiceId,
      addedPaymentAmount: amount,
    });
  };

  invoicesColumns = [
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          className="invoice-status-btn"
          style={{
            backgroundColor:
              record.statusName === "Saved"
                ? "#f0ad4e"
                : record.statusName === "Sent"
                ? "#5bc0de"
                : record.statusName === "Completed" ||
                  record.statusName === "Completed/Sent"
                ? "#5cb85c"
                : record.statusName === "Posted"
                ? "#9932CC"
                : record.statusName === "Reconciled"
                ? "#2E8B57"
                : record.statusName === "Paid"
                ? "#B87333"
                : record.statusName === "Settled"
                ? "#f64a8a"
                : "#ffff",
            width: "70px",
          }}
        >
          <span className="invoice-status-text">{record.statusName}</span>
        </div>
      ),
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Due",
      dataIndex: "dueAmount",
      key: "dueAmount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.dueAmount}
        </p>
      ),
    },
    {
      title: "Received",
      dataIndex: "received",
      key: "received",
      render: (text, record) => {
        let _items = record.payment && record.payment;
        let _amount = 0;
        if (_items && _items.length > 0) {
          for (let index = 0; index < _items.length; index++) {
            _amount = _amount + _items[index].amount;
          }
        }

        return (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {_amount}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.showModalAddPayment(record.id);
            }}
          >
            {"ADD PAYMENT"}{" "}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.props
                .getInvoiceInfo(record.id)
                .then(() => {
                  this.setState({ showAddTemplateInvoice: true }, () => {
                    this.props &&
                      this.props.getShowInvoice(
                        this.state.showAddTemplateInvoice,
                        this.props && this.props.data,
                        record.id
                      );
                  });
                })
                .catch(() => {});
            }}
          >
            {"DETAILS"}{" "}
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "4px" }}
            onClick={() => this.onClickEmail(record)}
            className="button-blue"
          >
            {"EMAIL"}
          </Button>
          <Button
            size="small"
            type="primary"
            className="button-blue"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id, record);
            }}
          >
            {"UNDO"}
          </Button>
        </span>
      ),
    },
  ];

  onClickSendReceiptInvoicePayment = (id, invoiceId) => {
    this.props.getInvoiceInfo(invoiceId).then((_res) => {
      let _invoiceInfo = _res && _res.payload;

      this.setState({ invoiceInfo: _invoiceInfo }, () => {
        this.props
          .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
          .then((res) => {
            let _statuses =
              res && res.payload && res.payload.items && res.payload.items;
            this.setState({ statuses: _statuses }, () => {
              let _tempInvoiceInfo = this.state.invoiceInfo;
              _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
              _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
              _tempInvoiceInfo.dateStr = moment(
                _tempInvoiceInfo.dateStr
              ).format("DD/MM/YYYY");
              _tempInvoiceInfo.dueDate = moment(
                _tempInvoiceInfo.dueDate
              ).format("DD/MM/YYYY");
              this.setState(
                { invoiceInfo: _tempInvoiceInfo, selectedPaymentId: id },
                () => {
                  let _emailTemplatePayload = {
                    templateName: "recipt",
                    paymentId: id,
                    invoiceId: invoiceId,
                    parameters: [
                      {
                        key: "ClientId",
                        value: this.state.clientProfileId,
                      },
                      {
                        key: "UserId",
                        value: this.state.userId,
                      },
                      {
                        key: "CurrencyId",
                        value: this.state.invoiceInfo.currencyId
                          ? this.state.invoiceInfo.currencyId.toString()
                          : "106",
                      },
                      {
                        key: "Amount",
                        value: this.state.invoiceInfo.total.toString(),
                      },
                      {
                        key: "InvoiceNumber",
                        value: this.state.invoiceInfo.invoiceNo,
                      },
                    ],
                  };
                  this.props
                    .setEmailTemplatePayment(_emailTemplatePayload)
                    .then(() => {
                      let _setEmailData = this.props.setEmailDataPayment;

                      this.setState({ setEmailData: _setEmailData }, () => {
                        let _data = {
                          invoiceId:
                            this.state &&
                            this.state.invoiceInfo &&
                            this.state.invoiceInfo.id &&
                            this.state.invoiceInfo.id,
                          paymentId: this.state.selectedPaymentId,
                        };
                        this.props
                          .getInvoiceReceiptPDFHtmlBlob(_data)
                          .then((res) => {
                            let formData = new FormData();

                            let binaryString = window.atob(res.payload.url);
                            let binaryLen = binaryString.length;
                            let bytes = new Uint8Array(binaryLen);
                            for (let i = 0; i < binaryLen; i++) {
                              let ascii = binaryString.charCodeAt(i);
                              bytes[i] = ascii;
                            }
                            let _blob = new Blob([bytes], {
                              type: "application/pdf",
                            });
                            let metadata = {
                              type: "application/pdf",
                            };
                            let file = new File(
                              [_blob],
                              res.payload.name,
                              metadata
                            );

                            formData.append(
                              "BranchId",
                              localStorage.getItem("selectedBranchId")
                            );

                            formData.append("files", file);
                            this.props
                              .onAddEmailDocument(formData)
                              .then((res) => {
                                let _attData = res && res.payload;
                                let attDataArray = [
                                  {
                                    name: _attData[0].fileName,
                                    type: _attData[0].contentType,
                                    url: _attData[0].fileUrl,
                                  },
                                ];
                                this.props && this.props.setLoadingFalse();

                                this.setState({
                                  attDataArray: attDataArray,
                                  visibleSendingReceiptModal: true,
                                });
                              })
                              .catch(() => {
                                this.props && this.props.setLoadingFalse();
                              });
                          })
                          .catch(() => {
                            this.props && this.props.setLoadingFalse();
                          });
                      });
                    })
                    .catch(() => {
                      this.props && this.props.setLoadingFalse();
                    });
                }
              );
            });
          });
      });
    });
  };

  onClickDownloadPayment = (paymentId, invoiceId, invoiceNo) => {
    let _data = {
      id: invoiceId,
      paymentId: paymentId,
    };
    this.props && this.props.setLoadingTrue();
    this.props
      .getPDFInvoicePayment(_data)
      .then((response) => {
        let blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        this.props && this.props.setLoadingFalse();
        saveAs(blob, `Invoice-${invoiceNo}.pdf`);
      })
      .catch((e) => {
        this.props && this.props.setLoadingFalse();
        message.error("Unable to download, please try again!");
      });
  };

  paymentsColumns = [
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.receivedDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.onClickSendReceiptInvoicePayment(
                record.id,
                record.invoiceId
              );
            }}
          >
            {"SEND RECEIPT"}{" "}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => {
              this.onClickDownloadPayment(
                record.id,
                record.invoiceId,
                record.invoiceNo
              );
            }}
          >
            {"DOWNLOAD"}{" "}
          </Button>
          <Button
            size="small"
            type="primary"
            className="button-blue"
            onClick={() => {
              this.showModalDeletePayment(
                record.id,
                record.invoiceId,
                record.amount
              );
            }}
          >
            {"UNDO"}
          </Button>
        </span>
      ),
    },
  ];

  showModalAddPayment = (id) => {
    this.setState({
      visibleModalInvoicePayment: true,
      invoiceId: id,
    });
  };

  followUpHistoryColumns = [
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.invoiceNo}
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "sentDate",
      key: "sentDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.sentDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Sent By",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.name}
        </p>
      ),
    },
  ];

  getInvoicesTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          <div className="ca-gray-cont ca-gray-padding-payment-history">
            <Table
              style={{ overflowX: "auto" }}
              dataSource={
                this.state.invoices
                  ? this.state.invoices.sort((a, b) =>
                      b.dateStr.localeCompare(a.dateStr)
                    )
                  : null
              }
              columns={this.invoicesColumns}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    );
  };

  getPaymentsTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          <div className="ca-gray-cont ca-gray-padding-payment-history">
            <Table
              style={{ overflowX: "auto" }}
              dataSource={
                this.state.payments
                  ? this.state.payments.sort((a, b) =>
                      b.receivedDate.localeCompare(a.receivedDate)
                    )
                  : null
              }
              columns={this.paymentsColumns}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    );
  };

  getFollowUpHistoryTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          <div className="ca-gray-cont ca-gray-padding-payment-history">
            <Table
              style={{ overflowX: "auto" }}
              dataSource={
                this.state.followUps
                  ? this.state.followUps.sort((a, b) =>
                      b.sentDate.localeCompare(a.sentDate)
                    )
                  : null
              }
              columns={this.followUpHistoryColumns}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    );
  };

  handleCancelDeleteModalInvoice = () => {
    this.setState({ visibleDeleteModalInvoice: false });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalInvoice}
          onCancel={this.handleCancelDeleteModalInvoice}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete invoice?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModalInvoice();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleDeleteInvoice(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoice = (id) => {
    this.props
      .deleteInvoice(id)
      .then(() => {
        message.success("Invoice is deleted successfully!");
        this.setState({ visibleDeleteModalInvoice: false }, () => {
          this.props && this.props.setLoadingTrue();
          this.props
            .getClientTemplateListing()
            .then(() => {
              this.props && this.props.setLoadingFalse();
            })
            .catch(() => {
              this.props && this.props.setLoadingFalse();
            });
        });
      })
      .catch(() => {});
  };

  handleCancelDeleteModalInvoicePayment = () => {
    this.setState({ visibleDeleteModalInvoicePayment: false });
  };

  getModalDeleteInvoicePayment = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModalInvoicePayment}
          onCancel={this.handleCancelDeleteModalInvoicePayment}
          footer={null}
          maskClosable={false}
        >
          <Spin size={"large"} spinning={this.state.loadingPaymentModalDelete}>
            <Row>
              <Col span={24}>
                <Row> Are you sure, you want to delete payment?</Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", marginTop: "40px" }}>
              <Col span={5} offset={15}>
                <Button
                  onClick={() => {
                    this.handleCancelDeleteModalInvoicePayment();
                  }}
                  style={{ borderRadius: "5px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  className={"button button-blue"}
                  onClick={() => {
                    this.handleDeleteInvoicePayment(this.state.indexPayment);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  };

  handleDeleteInvoicePayment = (id) => {
    this.setState({ loadingPaymentModalDelete: true }, () => {
      this.props
        .getInvoiceInfo(this.state.invoiceId)
        .then((_res) => {
          let _invoiceInfo = _res && _res.payload;

          this.setState({ invoiceInfo: _invoiceInfo }, () => {
            this.props
              .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
              .then((res) => {
                let _statuses =
                  res && res.payload && res.payload.items && res.payload.items;

                this.props
                  .deleteInvoicePayment(id)
                  .then(() => {
                    let _addedAmount = this.state.addedPaymentAmount;
                    message.success("Payment is deleted successfully!");
                    let _statusId;
                    let calAmount =
                      Math.round(
                        (this.state.invoiceInfo.dueAmount +
                          _addedAmount +
                          Number.EPSILON) *
                          100
                      ) / 100;
                    let _tempInvoiceInfo = this.state.invoiceInfo;

                    _tempInvoiceInfo.dueAmount = calAmount;
                    if (
                      _tempInvoiceInfo.dueAmount !== 0 &&
                      _tempInvoiceInfo.dueAmount > 0
                    ) {
                      if (_statuses && _statuses.length > 0) {
                        _statusId = _statuses[0].id;
                      }
                    } else {
                      _statusId = _statuses[4].id;
                    }
                    _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
                    _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
                    _tempInvoiceInfo.statusId = _statusId;
                    _tempInvoiceInfo.dateStr = moment(
                      _tempInvoiceInfo.dateStr
                    ).format("DD/MM/YYYY");
                    _tempInvoiceInfo.dueDate = moment(
                      _tempInvoiceInfo.dueDate
                    ).format("DD/MM/YYYY");

                    this.setState(
                      {
                        invoiceInfo: _tempInvoiceInfo,
                      },
                      () => {
                        this.props
                          .editInvoice(this.state.invoiceInfo)
                          .then(() => {
                            this.setState(
                              {
                                loadingPaymentModalDelete: false,
                                visibleModalInvoicePaymentDelete: false,
                              },
                              () => {
                                this.props && this.props.setLoadingTrue();
                                this.props
                                  .getClientTemplateListing()
                                  .then(() => {
                                    this.props && this.props.setLoadingFalse();
                                  })
                                  .catch(() => {
                                    this.props && this.props.setLoadingFalse();
                                  });
                              }
                            );
                          })
                          .catch(() => {
                            this.setState({ loadingPaymentModalDelete: false });
                          });
                      }
                    );
                  })
                  .catch(() => {
                    this.setState({ loadingPaymentModalDelete: false });
                  });
              })
              .catch(() => {
                this.setState({ loadingPaymentModalDelete: false });
              });
          });
        })
        .catch(() => {
          this.setState({ loadingPaymentModalDelete: false });
        });
    });
  };

  handleCancelModalInvoicePayment = () => {
    this.setState({ visibleModalInvoicePayment: false });
  };

  onFinishAddPaymentForm = (values) => {
    if (this.state.paymentDate === "") {
      message.error("Please select date!");
    } else {
      this.setState({ loadingPaymentModal: true }, () => {
        this.props
          .getInvoiceInfo(this.state.invoiceId)
          .then((_res) => {
            let _invoiceInfo = _res && _res.payload;

            this.setState({ invoiceInfo: _invoiceInfo }, () => {
              this.props
                .getInvoiceStatuses(this.state.invoiceInfo.invoiceTypeId)
                .then((res) => {
                  let _statuses =
                    res &&
                    res.payload &&
                    res.payload.items &&
                    res.payload.items;

                  this.props
                    .addInvoicePayment(this.state)
                    .then(() => {
                      let _addedAmount = this.state.paymentAmount;
                      message.success("Payment is added successfully!");
                      let _statusId;
                      let calAmount =
                        Math.round(
                          (this.state.invoiceInfo.dueAmount -
                            _addedAmount +
                            Number.EPSILON) *
                            100
                        ) / 100;
                      let _tempInvoiceInfo = this.state.invoiceInfo;

                      _tempInvoiceInfo.dueAmount = calAmount;
                      if (
                        _tempInvoiceInfo &&
                        _tempInvoiceInfo.invoiceTypeId === 2 &&
                        _tempInvoiceInfo.statusId === 27
                      ) {
                        _statusId = _tempInvoiceInfo.statusId;
                      } else {
                        if (
                          _tempInvoiceInfo.dueAmount !== 0 &&
                          _tempInvoiceInfo.dueAmount > 0
                        ) {
                          if (_statuses && _statuses.length > 0) {
                            _statusId = _statuses[0].id;
                          }
                        } else {
                          _statusId = _statuses[4].id;
                        }
                      }
                      _tempInvoiceInfo.invoiceId = _tempInvoiceInfo.id;
                      _tempInvoiceInfo.clientProfileId = this.state.clientProfileId;
                      _tempInvoiceInfo.statusId = _statusId;
                      _tempInvoiceInfo.dateStr = moment(
                        _tempInvoiceInfo.dateStr
                      ).format("DD/MM/YYYY");
                      _tempInvoiceInfo.dueDate = moment(
                        _tempInvoiceInfo.dueDate
                      ).format("DD/MM/YYYY");

                      this.setState(
                        {
                          invoiceInfo: _tempInvoiceInfo,
                        },
                        () => {
                          this.props
                            .editInvoice(this.state.invoiceInfo)
                            .then(() => {
                              this.setState(
                                {
                                  loadingPaymentModal: false,
                                  visibleModalInvoicePayment: false,
                                },
                                () => {
                                  this.props && this.props.setLoadingTrue();
                                  this.props
                                    .getClientTemplateListing()
                                    .then(() => {
                                      this.props &&
                                        this.props.setLoadingFalse();
                                    })
                                    .catch(() => {
                                      this.props &&
                                        this.props.setLoadingFalse();
                                    });
                                }
                              );
                            })
                            .catch(() => {
                              this.setState({ loadingPaymentModal: false });
                            });
                        }
                      );
                    })
                    .catch(() => {
                      this.setState({ loadingPaymentModal: false });
                    });
                })
                .catch(() => {
                  this.setState({ loadingPaymentModal: false });
                });
            });
          })
          .catch(() => {
            this.setState({ loadingPaymentModal: false });
          });
      });
    }
  };

  handleChangePaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  handlePaymentDateChange = (date, dateString) => {
    this.setState(
      { paymentDate: date === null ? "" : moment(date).format() },
      () => {
        console.log("state", this.state);
      }
    );
  };

  handleChangePaymentBank = (value) => {
    this.setState({ paymentBank: value });
  };

  handleChangePaymentDescription = (e) => {
    this.setState({ paymentDescription: e.target.value });
  };

  getModalPayment = () => {
    return (
      <div>
        <Modal
          title="Add a Payment"
          visible={this.state.visibleModalInvoicePayment}
          onCancel={this.handleCancelModalInvoicePayment}
          footer={null}
          maskClosable={false}
        >
          <Spin size={"large"} spinning={this.state.loadingPaymentModal}>
            <Form onFinish={this.onFinishAddPaymentForm} ref={this.formRefTemp}>
              <div>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Amount"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={this.handleChangePaymentAmount}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Date"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={this.handlePaymentDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Bank Account"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item name="paymentBank">
                      <Select
                        placeholder="Please Select Bank Account!"
                        onChange={this.handleChangePaymentBank}
                      >
                        {this.props &&
                          this.props.bankListing &&
                          this.props.bankListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return <Option value={data.id}>{data.name}</Option>;
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Description"}</p>
                  </Col>
                  <Col xs={14}>
                    <Input
                      value={this.state.paymentDescription}
                      onChange={this.handleChangePaymentDescription}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={14} offset={12}>
                    <div className="payment-buttons-container">
                      <Button
                        onClick={() => {
                          this.handleCancelModalInvoicePayment();
                        }}
                        style={{ borderRadius: "5px", marginRight: "13px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="button-blue"
                      >
                        {"Add Payment"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  };

  handleCancelSendingReceiptModal = (e) => {
    this.setState({
      visibleSendingReceiptModal: false,
    });
  };

  handleCancelSendingReceiptModalForInvoiceListing = (e) => {
    this.setState({
      visibleSendingReceiptModalForInvoiceListing: false,
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={24}>
            {this.getStageInfoStaticTable()}
            <Row>
              <Col xs={23} offset={1}>
                <h4 style={{ paddingTop: "18px" }}>
                  {this.state.invoices.length > 0 ? "Invoices" : null}
                </h4>
              </Col>
            </Row>
            {this.state.invoices.length > 0 ? this.getInvoicesTable() : null}
            <Row>
              <Col xs={23} offset={1}>
                <h4 style={{ paddingTop: "18px" }}>
                  {this.state.payments.length > 0 ? "Payments" : null}
                </h4>
              </Col>
            </Row>
            {this.state.payments.length > 0 ? this.getPaymentsTable() : null}
            <Row>
              <Col xs={23} offset={1}>
                <h4 style={{ paddingTop: "18px" }}>
                  {this.state.followUps.length > 0 ? "Follow Up History" : null}
                </h4>
              </Col>
            </Row>
            {this.state.followUps.length > 0
              ? this.getFollowUpHistoryTable()
              : null}
          </Col>
        </Row>
        {this.getModalDelete()}
        {this.getModalDeleteInvoicePayment()}
        {this.state.visibleModalInvoicePayment ? this.getModalPayment() : null}
        {this.state.visibleSendingReceiptModal ? (
          <SendEmailTemplatePayment
            clientId={this.state.clientProfileId}
            attDataArray={this.state.attDataArray}
            selectedPaymentId={this.state.selectedPaymentId}
            state={this.state}
            setEmailData={this.state.setEmailData}
            userEmail={this.state.userEmail}
            branchName={this.state.branchName}
            clientProfileData={this.props && this.props.clientProfileData}
            handleCancelSendingReceiptModal={
              this.handleCancelSendingReceiptModal
            }
            setLoadingTrue={this.props.setLoadingTrue}
            setLoadingFalse={this.props.setLoadingFalse}
            invoiceInfo={this.state.invoiceInfo}
            statuses={this.state.statuses}
            visibleSendingReceiptModal={this.state.visibleSendingReceiptModal}
          />
        ) : null}
        {this.state.visibleSendingReceiptModalForInvoiceListing ? (
          <SendEmailFromInvoiceListing
            fromClient={true}
            clientId={this.state.clientProfileId}
            invoiceType={"template"}
            setLoadingTrue={this.props.setLoadingTrue}
            setLoadingFalse={this.props.setLoadingFalse}
            statuses={this.state.statuses}
            attDataArray={this.state.attDataArray}
            setEmailData={this.state.setEmailData}
            userEmail={this.state.userEmail}
            paymentId={this.state.currentPaymentId}
            invoiceNo={
              this.state.invoiceInfo && this.state.invoiceInfo.invoiceNo
            }
            invoiceId={this.state.invoiceInfo && this.state.invoiceInfo.id}
            branchName={this.state.branchName}
            clientProfileData={this.props && this.props.clientProfileData}
            handleCancelSendingReceiptModal={
              this.handleCancelSendingReceiptModalForInvoiceListing
            }
            invoiceInfo={this.state.invoiceInfo}
            visibleSendingReceiptModal={
              this.state.visibleSendingReceiptModalForInvoiceListing
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
    setEmailDataPayment:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.setEmailData &&
      state.invoicesReducer.setEmailData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoicePDFHtmlBlob: bindActionCreators(getInvoicePDFHtmlBlob, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getClientTemplateListing: bindActionCreators(
    getClientTemplateListing,
    dispatch
  ),
  deleteInvoice: bindActionCreators(deleteInvoice, dispatch),
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  setInvoicesDataNull: bindActionCreators(setInvoicesDataNull, dispatch),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  deleteInvoicePayment: bindActionCreators(deleteInvoicePayment, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  getBranch: bindActionCreators(getBranch, dispatch),
  getClientProfile: bindActionCreators(getClientProfile, dispatch),
  setEmailTemplatePayment: bindActionCreators(
    setEmailTemplatePayment,
    dispatch
  ),
  getPDFInvoicePayment: bindActionCreators(getPDFInvoicePayment, dispatch),
  getInvoiceReceiptPDFHtmlBlob: bindActionCreators(
    getInvoiceReceiptPDFHtmlBlob,
    dispatch
  ),
  editTemplateForClientAppliedTemplateListing: bindActionCreators(
    editTemplateForClientAppliedTemplateListing,
    dispatch
  ),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
});

StageClientTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(StageClientTemplate);
export default withRouter(StageClientTemplate);
