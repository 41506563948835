import { api } from "../../services/api";
import * as types from "../Constants";

export const getSignature = (UserId) => {
  const options = {
    url: `v1/users/UserSignature`,
  };
  options.types = [types.GET_SIGNATURE_SUCCESS, types.GET_SIGNATURE_FAILURE];

  return api.get(options);
};

export const updateSignature = (data) => {
  const options = {
    url: "v1/users/UserSignature",
  };
  options.types = [types.PUT_SIGNATURE_SUCCESS, types.PUT_SIGNATURE_FAILURE];

  return api.put(options, data);
};
