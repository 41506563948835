import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const visaNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PUT_UPDATE_NOTIFICATION_SUCCESS:
      return { ...state, visaNotificationRes: action.payload };
    case types.PUT_UPDATE_NOTIFICATION_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_UPDATE_NOTIFICATION_SUCCESS:
      return { ...state, getVisaNotificationRes: action.payload };
    case types.GET_UPDATE_NOTIFICATION_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
