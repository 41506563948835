import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const dashboardEmployersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_EMPLOYERS_SUCCESS:
      return { ...state, dashboardEmployersList: action.payload };
    case types.GET_DASHBOARD_EMPLOYERS_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, dashboardEmployersError: action.payload };
    // case types.GET_PROCESSING_PERSONS_SUCCESS:
    //   return { ...state, processingPersons: action.payload };
    // case types.GET_PROCESSING_PERSONS_FAILURE:
    //   return { ...state, processingPersonsError: action.payload };
    default:
      return state;
  }
};
