import React from "react";
import "./TimeTrackingStyles.css";
import { Images } from "../Themes";
import { Table, Modal, message, Spin, Input } from "antd";
import { apiRefresh } from "../services/api";

const headOption = [
  { tabName: "WORK TYPE", linkName: "/work-type" },
  { tabName: "VISA TYPE PRICE", linkName: "/visa-type-price" },
  { tabName: "SERVICE TYPE", linkName: "/service-type" },
];

class ServiceType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addServiceTypeVisible: false,
      loading: true,
      updateData: null,
      serviceTypeName: "",
      basePrice: "",
      totalHours: "",
      remServiceTypeVisible: false,
      columns: [
        {
          width: "150px",
          title: "Name",
          dataIndex: "serviceName",
          key: "serviceName",
        },
        {
          width: "150px",
          title: "Base Price",
          dataIndex: "basePrice",
          key: "basePrice",
        },
        {
          width: "150px",
          title: "Time In Hours",
          dataIndex: "timeInHours",
          key: "timeInHours",
        },
        {
          width: "50px",
          title: "Action",
          dataIndex: "",
          key: "",
          render: (text, row, index) => {
            return (
              <div style={{ display: "flex" }}>
                <img
                  src={Images.editBorderBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState(
                      {
                        updateData: row,
                        workTypeName: row.name,
                        serviceTypeName: row.serviceName,
                        basePrice: row.basePrice,
                        totalHours: row.timeInHours,
                      },
                      () => {
                        this.setState({
                          addServiceTypeVisible: true,
                        });
                      }
                    )
                  }
                />
                <img
                  src={Images.deleteBlue}
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      updateData: row,
                      remServiceTypeVisible: true,
                    })
                  }
                />
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getServiceType();
  }

  getServiceType = () => {
    this.props
      .onGetServiceType()
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  openServiceTypeModal = () => {
    this.setState({
      addServiceTypeVisible: true,
    });
  };

  closeServiceTypeModal = () => {
    this.setState({
      addServiceTypeVisible: false,
    });
  };

  onAddServiceType = () => {
    let data = {
      serviceName: this.state.serviceTypeName,
      timeInHours: this.state.totalHours ? parseInt(this.state.totalHours) : 0,
      basePrice: this.state.basePrice ? parseInt(this.state.basePrice) : 0,
      name: this.state.workTypeName,
    };
    if (this.state.updateData) {
      data.id = this.state.updateData.id;
      const addWorkTypeOpt = {
        url: `v1/servicetype`,
      };
      addWorkTypeOpt.types = [
        "UPD_SERVICE_TYPE_SUCCESS",
        "UPD_SERVICE_TYPE_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .put(addWorkTypeOpt, data)
        .then((res) => {
          message.success("Work Type updated successfuly!");
          this.setState({
            addServiceTypeVisible: false,
            loading: false,
            updateData: null,
            serviceTypeName: "",
            basePrice: "",
            totalHours: "",
          });
          this.getServiceType();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    } else {
      const addWorkTypeOpt = {
        url: `v1/servicetype`,
      };
      addWorkTypeOpt.types = [
        "ADD_SERVICE_TYPE_SUCCESS",
        "ADD_SERVICE_TYPE_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh
        .post(addWorkTypeOpt, data)
        .then((res) => {
          message.success("Service Type added successfuly!");
          this.setState({
            addServiceTypeVisible: false,
            loading: false,
            updateData: null,
            serviceTypeName: "",
            basePrice: "",
            totalHours: "",
          });
          this.getServiceType();
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error("Failed!");
        });
    }
  };

  onRemoveWorkType = () => {
    let data = {
      id: this.state.updateData && this.state.updateData.id,
      delete: true,
    };
    const remWorkTypeOpt = {
      url: `v1/servicetype`,
    };
    remWorkTypeOpt.types = [
      "REM_VISA_WORK_TYPE_SUCCESS",
      "REM_VISA_WORK_TYPE_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .delete(remWorkTypeOpt, data)
      .then((res) => {
        this.setState(
          {
            updateData: null,
            loading: false,
          },
          () => {
            this.setState({
              remServiceTypeVisible: false,
            });
          }
        );
        this.getServiceType();
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed!");
      });
  };

  render() {
    const {
      loading,
      columns,
      serviceTypeName,
      basePrice,
      totalHours,
    } = this.state;
    const { serviceTypeRes } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="report-container">
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginRight: 20,
                  }}
                >
                  <div className="pciq-top-div" style={{ marginTop: 0 }}>
                    <span
                      className="pc-top-div-text"
                      style={{ color: "#0A3C5D" }}
                    >
                      SERVICE TYPE
                    </span>
                  </div>
                </div>

                <div className="ca-gray-cont" style={{ paddingLeft: 20 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: 20,
                    }}
                  >
                    <div onClick={this.openServiceTypeModal}>
                      <img
                        src={Images.plusIcon}
                        style={{ width: 20, height: 20 }}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      className="ca-invoice-table-cont"
                      style={{
                        width: `calc(100% - 20px)`,
                        marginTop: 20,
                      }}
                    >
                      <Table
                        columns={columns}
                        dataSource={serviceTypeRes && serviceTypeRes.items}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ======== Add service type popup design ======== */}

            <Modal
              visible={this.state.addServiceTypeVisible}
              height="270"
              effect="fadeInUp"
              onOk={() => this.onAddServiceType()}
              onCancel={() => this.closeServiceTypeModal()}
            >
              <div style={{ padding: 20 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="sus-modal-label" style={{ border: 0 }}>
                    <span className="sus-modal-label-text">SERVICE TYPE</span>
                  </div>
                </div>
                <div style={{ width: "100%", marginLeft: 20 }}>
                  <div class="emp-ae-input-width" style={{ display: "flex" }}>
                    <p style={{ width: "30%" }}>Name</p>
                    <div class="profile-input-border" style={{ width: "65%" }}>
                      <Input
                        className="profile-input"
                        placeholder=""
                        type="text"
                        value={serviceTypeName}
                        onChange={(e) =>
                          this.setState({ serviceTypeName: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div
                    class="emp-ae-input-width"
                    style={{ marginTop: 15, display: "flex" }}
                  >
                    <p style={{ width: "30%" }}>Base Price</p>
                    <div class="profile-input-border" style={{ width: "65%" }}>
                      <input
                        className="profile-input"
                        placeholder=""
                        type="number"
                        value={basePrice}
                        onChange={(e) =>
                          this.setState({ basePrice: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div
                    class="emp-ae-input-width"
                    style={{ marginTop: 15, display: "flex" }}
                  >
                    <p style={{ width: "30%" }}>Time In Hours</p>
                    <div class="profile-input-border" style={{ width: "65%" }}>
                      <input
                        className="profile-input"
                        placeholder=""
                        type="number"
                        value={totalHours}
                        onChange={(e) =>
                          this.setState({ totalHours: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div></div>
                  {/* <div style={{ display: "flex" }}>
                    <div
                      onClick={this.closeServiceTypeModal}
                      className="sus-modal-button"
                      style={{ marginLeft: 10 }}
                    >
                      <span className="sus-modal-button-text">SAVE</span>
                    </div>
                    <div
                      onClick={this.closeServiceTypeModal}
                      className="sus-modal-button"
                      style={{ marginLeft: 10 }}
                    >
                      <span className="sus-modal-button-text">CLOSE</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </Modal>
            <Modal
              visible={this.state.remServiceTypeVisible}
              effect="fadeInUp"
              onCancel={() => this.setState({ remServiceTypeVisible: false })}
              onOk={this.onRemoveWorkType}
              header={false}
            >
              <Spin spinning={loading}>
                <h4>Are you sure you want to delete this ?</h4>
              </Spin>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceType;
