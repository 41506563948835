import React, { useEffect, useState } from "react";
import { Spin, Tabs } from "antd";
import ProfileEmployer from "./ProfileEmployer/index";
import FileNotesMain from "./FileNotes";
import EmployerDocuments from "./EmployerDocuments/index";
import EmployerEmailTabs from "./EmployerEmail";
import EmployerCase from "./EmployerCase/EmployerCase";
import EmployerInvoices from "./EmployerInvoices/EmployerInvoices";
import EmployerCreditNotes from "./EmployerCreditNotes/EmployerCreditNotes";
import AddInvoice from "./EmployerInvoices/AddInvoice";
import { getCompany } from "../../../store/Actions";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import ClientQuestionnaire from "../../../Components/Questionnaire/ClientsQuestionnaire";
import EmployerSideBar from "../../../Components/EmployerSideBar";
import EmployerCaseReport from "./EmployerCaseReport";
import PurchaseSubscription from "../../../Common/PurchaseMessage";
import { apiRefresh } from "../../../services/api";

const { TabPane } = Tabs;

const EmployerManagementTabs = ({
  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onGetEmployerData,
  singleEmployerRes,
  singleEmployeFuc,
  userDataEmp,

  onRemoveEmployerContact,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onRemoveEmployerFile,

  handleCancel,

  onUpdateEmployerManag,

  onUpdateEmployerFile,

  onUpdateEmployerContact,

  onGetEmployerDocument,
  employerDocumentRes,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onAddEmployerEmail,

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onAddDocumentTittle,

  onAddAnyTamplate,
  addAnyTemRes,

  onGetTeamMember,
  teamMembers,

  onDeleteCreateEmail,

  onGetDocumentDownload,
  onGetDocumentPdf,

  onGetFilteredType,
  documentFilterRes,

  onAddFilteredType,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,

  onGetClientEmp,
  clientEmpRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onGetEmployerManag,
  employerManagRes,

  docWithNameRes,
  onAddDocWithName,

  onGetClientJobHis,
  clientJobHisRes,

  onGetSingleClientHis,
  singleJobHisRes,

  onGetEmployerCase,
  onGetCaseDocument,
  onUpdateCaseHistory,
  onGetVisaFileNotes,
  visaFileNotesRes,
  onRemoveSubjectCase,
  onRemoveCaseStatus,
  employerCaseRes,
  onGetVisaTypeByCountry,
  onStartNewApplication,
  onGetVisaType,
  visaTypeData,

  visaStatusData,
  onGetVisaStatus,

  onUpdateCaseStatus,

  onGetSignature,
  signatureRes,

  onGetDocuments,
  documentRes,
  onGetImapForAll,
  imapForAllRes,
  onGetPdf,
  onAddEmailDocument,

  onGetAllQuestionnaire,
  onCreateDynamicLink,
  onGetDynamicLink,
  onGetEmailTemplate,
  onSetEmailTemplate,
  onGetQuestionnaire,
  onUpdateQuestionnaire,
  onGetMappingParent,
  onGetMappingChild,
  onGetQuestionnaireGroup,
  onGetGroupedQuestionnaire,
  onGetFilledQuestionnaire,
  onGetAllFilledQuestionnaire,
  onGetCountries,
  questionnaireList,
  dynamicLinkSuccess,
  dynamicLinkData,
  emailTemplateData,
  setEmailData,
  clientTab,
  countriesData,
  questionnaireData,
  mappParentData,
  mappChildData,
  questionnaireGroupRes,
  groupedQuestionnaireRes,
  filledQuestionnaireRes,
  allFilledQuestionnaireRes,
  mergeFromClientRes,
  updMergeFromClientRes,
  updMergeToClientRes,
  onMergeFromClient,
  onUpdMergeFromClient,
  onUpdMergeToClient,
  onGetEmailContent,
  activeKey,
  onSetActiveKey,
  onAddProcessingPerson,
  onGetVisaApplication,
  visaAppData,
  onGetPermProcessingPerson,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [addInvoice, setAddInvoice] = useState("");
  const [parentInvoiceId, setParentInvoiceId] = useState(0);
  const [childrenTotalAmount, setChildrenTotalAmount] = useState(0);
  const [parentTotalAmount, setParentTotalAmount] = useState(0);
  const [tabKey, setTabKey] = useState(null);
  const [userData, setUserData] = useState();
  useEffect(() => {
    onGetAutoEmailImport(userDataEmp && userDataEmp.id).then((res) => {
      if (
        res.payload.employerImportSetting.length > 0 &&
        res.payload.employerImportSetting[0].isAutoImportEmail
      ) {
        var emailQueueOpt = {
          url: `v1/employer/ImportEmailQueue`,
        };

        emailQueueOpt.types = ["EMAIL_QUEUE_SUCCESS", "EMAIL_QUEUE_FAILURE"];
        apiRefresh.post(emailQueueOpt, {
          clientId: userDataEmp && userDataEmp.id,
        });
      }
    });
    onGetEmployerData(userDataEmp && userDataEmp.id).then((res) => {
      setUserData(res.payload);
      // setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    dispatch(getCompany());
  }, [dispatch]);

  console.log(
    "userDataEmpuserDataEmpuserDataEmpuserDataEmp2313123123123123",
    userDataEmp
  );

  const onClickInvoicesTab = (key) => {
    setTabKey(key);
    onSetActiveKey(null);
    if (key === "7") {
      setLoading(true);
      setAddInvoice("invoices");
    } else {
      setAddInvoice("");
    }
  };

  const getChildInfo = (
    parentInvoiceId,
    childrenTotalAmount,
    parentTotalAmount,
    addInvoice
  ) => {
    setParentInvoiceId(parentInvoiceId);
    setChildrenTotalAmount(childrenTotalAmount);
    setParentTotalAmount(parentTotalAmount);
    setAddInvoice(addInvoice);
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        className={
          activeKey
            ? "content-width-open-sidebar"
            : "content-width-close-sidebar"
        }
      >
        <div className="box-emp" style={{ width: "98%" }}>
          <div className="employer-manag-tabs">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size={"small"}
              className="mar-r employer-tabs-content"
              onChange={onClickInvoicesTab}
            >
              <TabPane tab="PROFILE" key="1" className="employer-tabs-content">
                <ProfileEmployer
                  singleEmployeFuc={singleEmployeFuc}
                  ongetEmployerContact={ongetEmployerContact}
                  EmployerContactRes={EmployerContactRes}
                  onAddEmployerContact={onAddEmployerContact}
                  onGetEmployerJob={onGetEmployerJob}
                  employerJobRes={employerJobRes}
                  onAddEmployerJob={onAddEmployerJob}
                  userDataEmp={singleEmployerRes || null}
                  onRemoveEmployerContact={onRemoveEmployerContact}
                  onUpdateEmployerManag={onUpdateEmployerManag}
                  onUpdateEmployerContact={onUpdateEmployerContact}
                  onGetTeamMember={onGetTeamMember}
                  teamMembers={teamMembers}
                  onUpdateEmployerJob={onUpdateEmployerJob}
                  onRemoveEmployerJob={onRemoveEmployerJob}
                  onGetClientSearch={onGetClientSearch}
                  clientSearchRes={clientSearchRes}
                  onGetClientEmp={onGetClientEmp}
                  clientEmpRes={clientEmpRes}
                  onSearchClient={onSearchClient}
                  searchClientData={searchClientData}
                  onGetClientByEmpId={onGetClientByEmpId}
                  empClientEmpRes={empClientEmpRes}
                  onLinkEmpWithClient={onLinkEmpWithClient}
                  onAddEmployerJobHistory={onAddEmployerJobHistory}
                  onGetClientTag={onGetClientTag}
                  clientTagRes={clientTagRes}
                  onGetProfileClientTag={onGetProfileClientTag}
                  getClientRes={getClientRes}
                  onGetEmployerManag={onGetEmployerManag}
                  employerManagRes={employerManagRes}
                  docWithNameRes={docWithNameRes}
                  onAddDocWithName={onAddDocWithName}
                  onGetClientJobHis={onGetClientJobHis}
                  clientJobHisRes={clientJobHisRes}
                  onGetSingleClientHis={onGetSingleClientHis}
                  singleJobHisRes={singleJobHisRes}
                  activeKey={activeKey}
                  onSetActiveKey={onSetActiveKey}
                  countriesData={countriesData}
                  onGetCountries={onGetCountries}
                  onAddProcessingPerson={onAddProcessingPerson}
                  onGetPermProcessingPerson={onGetPermProcessingPerson}
                  tabKey={tabKey}
                />
              </TabPane>
              <TabPane tab="FILE NOTES" key="2">
                <FileNotesMain
                  onGetEmployerFile={onGetEmployerFile}
                  employerFileRes={employerFileRes}
                  onAddEmployerFile={onAddEmployerFile}
                  onGetEmployerJob={onGetEmployerJob}
                  employerJobRes={employerJobRes}
                  onGetLetterTemplates={onGetLetterTemplates}
                  LetterTemplatesRes={LetterTemplatesRes}
                  userDataEmp={userData}
                  onRemoveEmployerFile={onRemoveEmployerFile}
                  handleCancel={handleCancel}
                  onUpdateEmployerFile={onUpdateEmployerFile}
                  onAddAnyTamplate={onAddAnyTamplate}
                  addAnyTemRes={addAnyTemRes}
                  activeKey={activeKey}
                  onSetActiveKey={onSetActiveKey}
                  tabKey={tabKey}
                />
              </TabPane>
              <TabPane tab="DOCUMENTS" key="3">
                <EmployerDocuments
                  onGetEmployerDocument={onGetEmployerDocument}
                  employerDocumentRes={employerDocumentRes}
                  userDataEmp={userData}
                  onAddEmployerDocument={onAddEmployerDocument}
                  onRemoveEmployerDocument={onRemoveEmployerDocument}
                  onUpdateEmployerDocument={onUpdateEmployerDocument}
                  onUploadAvatar={onUploadAvatar}
                  imageUploadSuccess={imageUploadSuccess}
                  onGetDocumentChecklist={onGetDocumentChecklist}
                  docChecklistRes={docChecklistRes}
                  onGetEmployerCheckList={onGetEmployerCheckList}
                  employerCheckListRes={employerCheckListRes}
                  onAddEmployerCheckList={onAddEmployerCheckList}
                  location={location}
                  history={history}
                  onSendEmailLink={onSendEmailLink}
                  onAddDocumentTittle={onAddDocumentTittle}
                  onGetDocumentDownload={onGetDocumentDownload}
                  onGetDocumentPdf={onGetDocumentPdf}
                  onGetFilteredType={onGetFilteredType}
                  documentFilterRes={documentFilterRes}
                  onAddFilteredType={onAddFilteredType}
                  activeKey={activeKey}
                  onSetActiveKey={onSetActiveKey}
                  tabKey={tabKey}
                />
              </TabPane>
              <TabPane tab="EMAIL" key="4">
                {/* <div style={{height: "100vh"}}>
                <h3 style={{display: "flex", justifyContent: "center"}}>
                  Coming Soon!
                </h3>
              </div> */}
                <EmployerEmailTabs
                  onGetEmailContent={onGetEmailContent}
                  onGetEmployerDocument={onGetEmployerDocument}
                  employerDocumentRes={employerDocumentRes}
                  userDataEmp={userData}
                  onAddEmployerDocument={onAddEmployerDocument}
                  onRemoveEmployerDocument={onRemoveEmployerDocument}
                  onUpdateEmployerDocument={onUpdateEmployerDocument}
                  onUploadAvatar={onUploadAvatar}
                  imageUploadSuccess={imageUploadSuccess}
                  onGetDocumentChecklist={onGetDocumentChecklist}
                  docChecklistRes={docChecklistRes}
                  onGetEmployerCheckList={onGetEmployerCheckList}
                  employerCheckListRes={employerCheckListRes}
                  onAddEmployerCheckList={onAddEmployerCheckList}
                  location={location}
                  history={history}
                  onSendEmailLink={onSendEmailLink}
                  onGetLetterTemplates={onGetLetterTemplates}
                  LetterTemplatesRes={LetterTemplatesRes}
                  onAddEmployerEmail={onAddEmployerEmail}
                  onGetEmployerHistory={onGetEmployerHistory}
                  employerHistoryRes={employerHistoryRes}
                  onGetAutoEmailImport={onGetAutoEmailImport}
                  emailImportRes={emailImportRes}
                  onAddAutoEmailImport={onAddAutoEmailImport}
                  onAddAnyTamplate={onAddAnyTamplate}
                  addAnyTemRes={addAnyTemRes}
                  onDeleteCreateEmail={onDeleteCreateEmail}
                  onGetSignature={onGetSignature}
                  signatureRes={signatureRes}
                  onGetDocuments={onGetDocuments}
                  documentRes={documentRes}
                  onGetImapForAll={onGetImapForAll}
                  imapForAllRes={imapForAllRes}
                  onGetPdf={onGetPdf}
                  onGetDocumentDownload={onGetDocumentDownload}
                  onAddEmailDocument={onAddEmailDocument}
                  activeKey={activeKey}
                  onSetActiveKey={onSetActiveKey}
                  tabKey={tabKey}
                />
              </TabPane>
              <TabPane tab="CASE" key="5">
                <EmployerCase
                  onGetEmployerCase={onGetEmployerCase}
                  onGetCaseDocument={onGetCaseDocument}
                  onUpdateCaseHistory={onUpdateCaseHistory}
                  onGetVisaFileNotes={onGetVisaFileNotes}
                  onGetDocumentDownload={onGetDocumentDownload}
                  visaFileNotesRes={visaFileNotesRes}
                  onRemoveSubjectCase={onRemoveSubjectCase}
                  onRemoveCaseStatus={onRemoveCaseStatus}
                  userDataEmp={userData}
                  employerCaseRes={employerCaseRes}
                  onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                  onStartNewApplication={onStartNewApplication}
                  onGetVisaType={onGetVisaType}
                  visaTypeData={visaTypeData}
                  visaStatusData={visaStatusData}
                  onGetVisaStatus={onGetVisaStatus}
                  onUpdateCaseStatus={onUpdateCaseStatus}
                  activeKey={activeKey}
                  onSetActiveKey={onSetActiveKey}
                  onGetEmployerData={onGetEmployerData}
                  onGetEmployerJob={onGetEmployerJob}
                  employerJobRes={employerJobRes}
                  tabKey={tabKey}
                />
              </TabPane>
              <TabPane tab="REPORTS" key="6">
                <EmployerCaseReport
                  userDataEmp={userDataEmp}
                  onGetEmployerJob={onGetEmployerJob}
                  employerJobRes={employerJobRes}
                  onAddEmployerJob={onAddEmployerJob}
                  onGetTeamMember={onGetTeamMember}
                  teamMembers={teamMembers}
                  onUpdateEmployerJob={onUpdateEmployerJob}
                  onRemoveEmployerJob={onRemoveEmployerJob}
                  onGetClientSearch={onGetClientSearch}
                  clientSearchRes={clientSearchRes}
                  onSearchClient={onSearchClient}
                  searchClientData={searchClientData}
                  onGetClientByEmpId={onGetClientByEmpId}
                  empClientEmpRes={empClientEmpRes}
                  onLinkEmpWithClient={onLinkEmpWithClient}
                  onAddEmployerJobHistory={onAddEmployerJobHistory}
                  onGetSingleClientHis={onGetSingleClientHis}
                  singleJobHisRes={singleJobHisRes}
                  visaStatusData={visaStatusData}
                  onGetVisaStatus={onGetVisaStatus}
                  onGetVisaApplication={onGetVisaApplication}
                  visaAppData={visaAppData}
                  tabKey={tabKey}
                />
              </TabPane>
              {JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userSystemPermissions"))
                  )
                )
              ).find(
                (x) =>
                  x.role.toLowerCase() == "employer management account" ||
                  x.role.toLowerCase() == "supplier management account"
              ).status == 1 ? (
                <TabPane tab="INVOICES" key="7">
                  {/*<div style={{height: "100vh"}}>*/}
                  {/*  <h3 style={{display: "flex", justifyContent: "center"}}>*/}
                  {/*    Coming Soon!*/}
                  {/*  </h3>*/}
                  {/*</div>*/}
                  {loading ? (
                    <div className={"spinner"}>
                      <Spin size="large" />
                    </div>
                  ) : addInvoice === "invoices" ? (
                    <EmployerInvoices
                      userDataEmp={userData}
                      getChildInfo={getChildInfo}
                      tabKey={tabKey}
                    />
                  ) : null}
                  {addInvoice === "add" ? (
                    <div className="border-box-emp-manag emp-profile-box-employer">
                      <h4
                        className="top-text heading-forms"
                        style={{
                          marginLeft: "40px !important",
                          fontWeight: 600,
                          fontSize: "14px",
                          fontFamily: "Poppins",
                        }}
                      >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
                      <AddInvoice
                        userDataEmp={userData}
                        parentInvoiceId={parentInvoiceId}
                        childrenTotalAmount={childrenTotalAmount}
                        parentTotalAmount={parentTotalAmount}
                        getChildInfo={getChildInfo}
                        tabKey={tabKey}
                      />
                    </div>
                  ) : null}
                </TabPane>
              ) : null}
              {/* {JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(localStorage.getItem("userSystemPermissions"))
                  )
                )
              ).find(
                (x) =>
                  x.role.toLowerCase() == "employer management account" ||
                  x.role.toLowerCase() == "supplier management account"
              ).status == 1 ? (
                <TabPane tab="CREDIT NOTES" key="8">
                  <div style={{ height: "100vh" }}>
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      Coming Soon
                    </h3>
                  </div>
                  <EmployerCreditNotes />
                </TabPane>
              ) : (
                ""
              )} */}
              <TabPane tab="QUESTIONNAIRE" key="9">
                <div style={{ height: "100vh" }}>
                  {localStorage.getItem("userBranchPermissions") &&
                  JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userBranchPermissions")
                        )
                      )
                    )
                  ).branchPermission.find(
                    (x) => x.name.toLowerCase() == "customquestionnaire"
                  ).status ? (
                    <ClientQuestionnaire
                      userDataEmp={userData}
                      clientId={userData && userData.id}
                      clientName={userData && userData.name}
                      onGetAllQuestionnaire={onGetAllQuestionnaire}
                      onCreateDynamicLink={onCreateDynamicLink}
                      onGetDynamicLink={onGetDynamicLink}
                      onGetEmailTemplate={onGetEmailTemplate}
                      onSetEmailTemplate={onSetEmailTemplate}
                      onGetQuestionnaire={onGetQuestionnaire}
                      onUpdateQuestionnaire={onUpdateQuestionnaire}
                      onGetMappingParent={onGetMappingParent}
                      onGetMappingChild={onGetMappingChild}
                      onGetQuestionnaireGroup={onGetQuestionnaireGroup}
                      onGetGroupedQuestionnaire={onGetGroupedQuestionnaire}
                      onGetFilledQuestionnaire={onGetFilledQuestionnaire}
                      onGetAllFilledQuestionnaire={onGetAllFilledQuestionnaire}
                      questionnaireList={questionnaireList}
                      dynamicLinkSuccess={dynamicLinkSuccess}
                      dynamicLinkData={dynamicLinkData}
                      emailTemplateData={emailTemplateData}
                      setEmailData={setEmailData}
                      clientTab={clientTab}
                      countriesData={countriesData}
                      questionnaireData={questionnaireData}
                      mappParentData={mappParentData}
                      mappChildData={mappChildData}
                      questionnaireGroupRes={questionnaireGroupRes}
                      groupedQuestionnaireRes={groupedQuestionnaireRes}
                      filledQuestionnaireRes={filledQuestionnaireRes}
                      allFilledQuestionnaireRes={allFilledQuestionnaireRes}
                      mergeFromClientRes={mergeFromClientRes}
                      updMergeFromClientRes={updMergeFromClientRes}
                      updMergeToClientRes={updMergeToClientRes}
                      onGetCountries={onGetCountries}
                      onMergeFromClient={onMergeFromClient}
                      onUpdMergeFromClient={onUpdMergeFromClient}
                      onUpdMergeToClient={onUpdMergeToClient}
                      onSendEmailLink={onSendEmailLink}
                      isEmployer={true}
                      onGetSignature={onGetSignature}
                      signatureRes={signatureRes}
                      onGetVisaType={onGetVisaType}
                      visaTypeData={visaTypeData}
                      tabKey={tabKey}
                    />
                  ) : (
                    <PurchaseSubscription />
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <div
        style={{
          width: activeKey ? "438px" : "115px",
          marginLeft: 20,
          marginTop: 40,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <EmployerSideBar
              clientId={userDataEmp && userDataEmp.id}
              activeKey={activeKey}
              profileData={singleEmployerRes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerManagementTabs;
