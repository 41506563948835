import { api } from "../../services/api";
import * as types from "../Constants";

export const addSignUpEmail = data => {
  const options = {
    url: "v1/config/RegisterSendEmail"
  };

  options.types = [types.ADD_SIGN_UP_SUCCESS, types.ADD_SIGN_UP_FAILURE];

  return api.post(options, data);
};

export const getTextEncoding = para => {
   
  const options = {
    url: `v1/config/TextDecoding/${para}`
  };
  options.types = [
    types.GET_TEXT_ENCODING_SUCCESS,
    types.GET_TEXT_ENCODING_FAILURE
  ];

  return api.get(options);
};

export const addSignUpForm = data => {
  const options = {
    url: "v1/user/identity/Signup"
  };

  options.types = [types.ADD_SIGN_FORM_SUCCESS, types.ADD_SIGN_FORM_FAILURE];

  return api.post(options, data);
};
