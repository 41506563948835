import * as types from "../Constants";

export const fileNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_FILE_NOTES_SUCCESS:
      return { ...state, addFileNotesSuccess: action.payload };
    case types.ADD_FILE_NOTES_FAILURE:
      return { ...state, addfileNotesError: action.payload };

    case types.GET_FILE_NOTES_SUCCESS:
      return { ...state, fileNotesData: action.payload };
    case types.GET_FILE_NOTES_FAILURE:
      return { ...state, fileNotesError: action.payload };

    case types.UPDATE_FILE_NOTES_SUCCESS:
      return { ...state, updateFileNotesSuccess: action.payload };
    case types.UPDATE_FILE_NOTES_FAILURE:
      return { ...state, updateFileNotesError: action.payload };

    case types.DELETE_FILE_NOTES_SUCCESS:
      return { ...state, deleteFileNotesSuccess: action.payload };
    case types.DELETE_FILE_NOTES_FAILURE:
      return { ...state, deleteFileNotesError: action.payload };

    case types.GET_TEMPLATE_SUCCESS:
      return { ...state, templateData: action.payload };
    case types.GET_TEMPLATE_FAILURE:
      return { ...state, templateError: action.payload };

    case types.GET_DYNAMIC_KEYS_SUCCESS:
      return { ...state, dynamicKeysRes: action.payload };
    case types.GET_DYNAMIC_KEYS_FAILURE:
      return { ...state, templateError: action.payload };

    case types.GET_VISA_FILE_NOTES_SUCCESS:
      return { ...state, visaFileNotesRes: action.payload };
    case types.GET_VISA_FILE_NOTES_FAILURE:
      return { ...state, visaFileNotesError: action.payload };

    case types.GET_FILE_NOTES_PDF_SUCCESS:
      return { ...state, fileNotesPdfRes: action.payload };
    case types.GET_FILE_NOTES_PDF_FAILURE:
      return { ...state, fileNotesPdfError: action.payload };

    default:
      return state;
  }
};
