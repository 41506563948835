import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtherInfo from "../Clients/OtherInfo";

import {
  addQualification,
  getQualification,
  updateQualification,
  getClientProfile,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  getVisaType,
  uploadAvatar,
  getUserBranch,
  userLogout,
  getClientTag,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  sendDynamicLinkEmail,
  createClientLink,
  getClientLink,
  setCmvLinkTemplate,
  removeClient,
  getClientJobHistoryCurrent,
  setActiveKey,
  updateSignedDate,
  getClientSource,
  searchMainClient,
  checkUniqueEmail,
  updatePriority,
  signedVisa,
  paidVisa,
  getReminderTasks,
  updateReminder,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  getVisaTypeByCountry,
  getAdmissionProgram,
  getAdmissionStatuses,
  setProfileData,
  getPartnerData,
} from "../store/Actions";
import { setClientTab } from "../store/Actions/EmployerManagement";

const mapStateToProps = (state) => {
  return {
    qualificationData: state.qualificationReducer.qualificationData,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    clientTab: state.employerManagReducer.clientTab,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    userBranchData: state.branchReducer.userBranchData,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    cmvLinkTemplateData: state.clientProfileReducer.cmvLinkTemplateData,
    removeClientRes: state.clientProfileReducer.removeClientRes,
    employerJobHistoryCurrentRes:
      state.EmployerJobHistoryReducer.employerJobHistoryCurrentRes,
    activeKey: state.employerManagReducer.activeKey,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    searchClientData: state.clientProfileReducer.clientsData,
    uniqueEmailRes: state.clientProfileReducer.uniqueEmailRes,
    clientTab: state.employerManagReducer.clientTab,
    visaPriorityRes: state.visaTypeReducer.visaPriorityRes,
    remindersRes: state.reminderReducer.reminderTaskRes,
    updReminderRes: state.reminderReducer.updReminderRes,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddQualification: bindActionCreators(addQualification, dispatch),
    onGetQualification: bindActionCreators(getQualification, dispatch),
    onUpdateQualification: bindActionCreators(updateQualification, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    onUserLogout: bindActionCreators(userLogout, dispatch),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
    onCreateClientLink: bindActionCreators(createClientLink, dispatch),
    onGetClientLink: bindActionCreators(getClientLink, dispatch),
    onSetCmvLinkTemplate: bindActionCreators(setCmvLinkTemplate, dispatch),
    onGetClientJobHistoryCurrent: bindActionCreators(
      getClientJobHistoryCurrent,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onUpdateSignedDate: bindActionCreators(updateSignedDate, dispatch),
    getClientSource: bindActionCreators(getClientSource, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onCheckUniqueEmail: bindActionCreators(checkUniqueEmail, dispatch),
    onSetClientTab: bindActionCreators(setClientTab, dispatch),
    onUpdatePriority: bindActionCreators(updatePriority, dispatch),
    onSignedVisa: bindActionCreators(signedVisa, dispatch),
    onPaidVisa: bindActionCreators(paidVisa, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onUpdateReminder: bindActionCreators(updateReminder, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherInfo);
