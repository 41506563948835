import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import { Modal, Spin, Form, Select, Button, Tooltip } from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { redirectUrl } from "../../../config";
import { openNotificationWithIcon } from "../../../Common/reUseFunction";
import "./DocumentSync.css";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

var location = window.location.href;
var searchLocation = window.location.search;
const DocumentSync = ({
  onDropboxLogin,
  onAddDropbox,
  onGetDropboxDocuments,
  onOneDriveLogin,
  onSaveOneDriveToken,
  onGetOneDriveInfo,
  onGoogleDriveLogin,
  onSaveGoogleDriveToken,
  onGetGoogleDriveInfo,
  onOffice365Login,
  onSaveOffice365Token,
  onGetOffice365Info,
  onRemoveDropboxAccount,
  onRemoveOnedriveAccount,
  onRemoveGoogledriveAccount,
  onRemoveOffice365Account,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [dropbox, setDropbox] = useState(null);
  const [oneDrive, setOneDrive] = useState(null);
  const [googleDrive, setGoogleDrive] = useState(null);
  const [office365, setOffice365] = useState(null);
  const state = useLocation().state;

  const redirection = () => {
    setLoading(true);
    onGettingDropboxRedirectParams();
    onGettingOnedriveRedirectParams();
    onGettingGoogledriveRedirectParams();
    onGettingOffice365RedirectParams();
  };
  const getAllData = () => {
    if (!dropbox) getDropboxDocuments();
    if (!oneDrive) getOneDriveDocuments();
    if (!googleDrive) getGoogleDriveDocs();
    if (!office365) getOffice365Docs();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirection(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllData(), []);

  const getDropboxDocuments = async () => {
    setLoading(true);
    let userId = await localStorage.getItem("userId");
    onGetDropboxDocuments(userId)
      .then((res) => {
        setLoading(false);
        setDropbox(res.payload);
      })
      .catch(() => {
        setLoading(false);
        setDropbox(null);
      });
  };

  const getOneDriveDocuments = () => {
    setLoading(true);
    onGetOneDriveInfo()
      .then((res) => {
        setLoading(false);
        setOneDrive(res.payload);
      })
      .catch(() => {
        setLoading(false);
        setOneDrive(null);
      });
  };

  const getGoogleDriveDocs = async () => {
    setLoading(true);
    let userId = await localStorage.getItem("userId");
    onGetGoogleDriveInfo(userId)
      .then((res) => {
        setLoading(false);
        setGoogleDrive(res.payload);
      })
      .catch(() => {
        setLoading(false);
        setGoogleDrive(null);
      });
  };

  const getOffice365Docs = () => {
    setLoading(true);
    onGetOffice365Info()
      .then((res) => {
        setLoading(false);
        setOffice365(res.payload);
      })
      .catch(() => {
        setLoading(false);
        setOffice365(null);
      });
  };

  const onGettingDropboxRedirectParams = async () => {
    let userId = await localStorage.getItem("userId");
    let params = new URLSearchParams(location);
    let access_token = params.get("access_token");
    let token_type = params.get("token_type");
    let state = params.get("state");
    let account_id = params.get("account_id");
    let uid = undefined;
    params.forEach((el, i) => {
      if (i === `${redirectUrl}#uid`.trim()) uid = el;
    });
    if (access_token && token_type && state && account_id && uid) {
      // setLoading(true);
      onAddDropbox({
        userId: userId,
        accessToken: access_token,
        tokenType: token_type,
        accountId: account_id,
        state: state,
        uid: uid,
      })
        .then((res) => {
          getDropboxDocuments();
          setLoading(false);
          location = "";
        })
        .catch(() => {
          location = "";
          setLoading(false);
        });
    }
  };

  const onGettingOnedriveRedirectParams = () => {
    return new Promise(async (resolve) => {
      let params = new URLSearchParams(searchLocation);
      let code = params.get("code");
      if (code && (await localStorage.getItem("isOneDrive"))) {
        setLoading(true);
        onSaveOneDriveToken({
          code: code,
          redirectUrl: redirectUrl,
        })
          .then(() => {
            searchLocation = "";
            localStorage.removeItem("isOneDrive");
            getOneDriveDocuments();
            setLoading(false);
            resolve(true);
          })
          .catch(() => {
            searchLocation = "";
            localStorage.removeItem("isOneDrive");
            setLoading(false);
            resolve(false);
          });
      }
      resolve(false);
    });
  };

  const onGettingGoogledriveRedirectParams = () => {
    return new Promise(async (resolve) => {
      let userId = await localStorage.getItem("userId");
      let params = new URLSearchParams(searchLocation);
      let code = params.get("code");
      let scope = params.get("scope");
      let state = params.get("state");
      if (code && scope && state) {
        setLoading(true);
        onSaveGoogleDriveToken({
          code: code,
          redirectUrl: redirectUrl,
          userId: userId,
        })
          .then(() => {
            searchLocation = "";
            getGoogleDriveDocs();
            setLoading(false);
            resolve(true);
          })
          .catch((er) => {
            searchLocation = "";
            setLoading(false);
            resolve(false);
          });
      }
      resolve(false);
    });
  };

  const onGettingOffice365RedirectParams = () => {
    return new Promise(async (resolve) => {
      let params = new URLSearchParams(searchLocation);
      let code = params.get("code");
      if (code && (await localStorage.getItem("isOffice365"))) {
        setLoading(true);
        onSaveOffice365Token({
          code: code,
          redirectUrl: redirectUrl,
        })
          .then(() => {
            searchLocation = "";
            localStorage.removeItem("isOffice365");
            getOffice365Docs();
            setLoading(false);
            resolve(true);
          })
          .catch(() => {
            searchLocation = "";
            localStorage.removeItem("isOffice365");
            setLoading(false);
            resolve(false);
          });
      }
      setLoading(false);
      resolve(false);
    });
  };

  const loginOnDropbox = () => {
    setLoading(true);
    onDropboxLogin({ uri: redirectUrl })
      .then((response) => {
        setLoading(false);
        if (response.payload) window.location = response.payload.authorizeUri;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loginOnOneDrive = () => {
    setLoading(true);
    onOneDriveLogin(redirectUrl)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("isOneDrive", true);
        if (response.payload) window.location = response.payload;
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  const loginOnGoogleDrive = async () => {
    let userId = await localStorage.getItem("userId");
    setLoading(true);
    onGoogleDriveLogin({ userId, redirectUri: redirectUrl })
      .then((response) => {
        setLoading(false);
        if (response.payload)
          if (response.payload.uri) window.location = response.payload.uri;
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  const loginOnOffice365 = () => {
    setLoading(true);
    onOffice365Login(redirectUrl)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("isOffice365", true);
        if (response.payload) window.location = response.payload;
      })
      .catch((er) => {
        setLoading(false);
      });
  };

  const removeDropboxAccount = () => {
    setLoading(true);
    onRemoveDropboxAccount()
      .then(() => {
        openNotificationWithIcon("success", "Dropbox Account Deleted!");
        setDropbox(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const removeOneDriveAccount = () => {
    setLoading(true);
    onRemoveOnedriveAccount()
      .then(() => {
        openNotificationWithIcon("success", "Onedrive Account Deleted!");
        setOneDrive(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const removeGoogleDriveAccount = () => {
    setLoading(true);
    onRemoveGoogledriveAccount()
      .then(() => {
        openNotificationWithIcon("success", "Googledrive Account Deleted!");
        setGoogleDrive(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const removeOffice365Account = () => {
    setLoading(true);
    onRemoveOffice365Account()
      .then(() => {
        openNotificationWithIcon("success", "Office365 Account Deleted!");
        setOffice365(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className=""></div>
                  <div className="client-tag-top">
                    <img
                      src={Images.documentSync}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="top-text">Document Sync</span>
                  <Tooltip
                    placement="topLeft"
                    title={`Sync your Ezymigrate documents and files with your online cloud platform.`}
                  >
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mar-top-75">
                <Form>
                  <div className="w-80">
                    <h3>DROP BOX</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {dropbox ? (
                              <div className="doc-office">
                                <div className="d-flex userDetail">
                                  <p>Name: {dropbox.displayName}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeDropboxAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button onClick={loginOnDropbox} type="primary">
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>ONE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {oneDrive ? (
                              <div className="doc-office ">
                                <div className="userDetail">
                                  <p>Name: {oneDrive.displayName}</p>
                                  <p>Email: {oneDrive.userPrincipalName}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeOneDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item className="d-block">
                                <Button
                                  onClick={loginOnOneDrive}
                                  type="primary"
                                >
                                  ADD ACCOUNT
                                </Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>GOOGLE DRIVE</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            {googleDrive ? (
                              <div className="doc-office">
                                <div className="d-flex userDetail">
                                  <p>Name: {googleDrive.name}</p>
                                  <a
                                    className="remove-account"
                                    onClick={() => removeGoogleDriveAccount()}
                                  >
                                    X
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <Form.Item
                                onClick={loginOnGoogleDrive}
                                className="d-block"
                              >
                                <Button type="primary">ADD ACCOUNT</Button>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-80">
                    <h3>OFFICE 365</h3>
                    <div className="document-sync-box">
                      <div>
                        <div className="client-tag-table">
                          <div className="reminder-set-form margin-top-34">
                            <div>
                              {office365 ? (
                                <div className="doc-office">
                                  <div className="d-flex userDetail">
                                    <p>Name:{office365.displayName}</p>
                                    <a
                                      className="remove-account"
                                      onClick={() => removeOffice365Account()}
                                    >
                                      X
                                    </a>
                                  </div>
                                </div>
                              ) : (
                                <Form.Item>
                                  <Button
                                    onClick={loginOnOffice365}
                                    className="margin-top"
                                    type="primary"
                                  >
                                    ADD ACCOUNT
                                  </Button>
                                </Form.Item>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default DocumentSync;
