import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import { Table, Spin, message, Button, Upload, Form, Avatar } from "antd";
import { yyyymmddFormate } from "./../../Common/reUseFunction";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { saveAs } from "file-saver";

import { Select } from "antd";
import { apiRefresh } from "../../services/api";

const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
}

const { Dragger } = Upload;
const CheckMyVisaDocs = ({
  onGetDocuments,
  documentRes,

  onAddDocument,

  onRemoveDocument,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadDoc, setUploadDoc] = useState(false);
  const [visaDocument, setVisaDocument] = useState([]);
  const [imageUrl, setImageUrl] = useState({});
  const [imageValues, setImageValues] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setLoading(true);
    onGetDocuments()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetDocuments]);

  // const result =
  //   documentRes &&
  //   documentRes.items &&
  //   documentRes.items.filter((word) => word.companyDocumentTypeId === 3);
  //
  // setVisaDocument(result);
  if (documentRes && documentRes.items) {
    var findDocument =
      documentRes &&
      documentRes.items.filter((obj) => obj.companyDocumentTypeId === 3);
  }

  const downloadDoc = (data) => {
    let blob = new Blob([data], {
      type: "application/octet-stream",
    });
    let fileName = data.substring(data.lastIndexOf("/") + 1);
    saveAs(blob, `${fileName}`);
  };
  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveDocument(remove)
      .then(() => {
        onGetDocuments()
          .then(() => {
            setLoading(false);
            message.success("Successfully Deleted!");
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      setImageValues(info.file.originFileObj);
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [form] = Form.useForm();
  const onFinish = (values) => {
    var count = 0;
    if (fileList.length > 10) {
      message.error("10 files at a time can be uploaded.");
    } else if (fileList && fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let selectedFile = fileList[i].originFileObj;
        let extensionArr = selectedFile.name.split(".");
        let fileExt = extensionArr[extensionArr.length - 1];
        if (
          fileExt == "mp3" ||
          fileExt == "mp4" ||
          fileExt == "avi" ||
          fileExt == "mov" ||
          fileExt == "wmv"
        ) {
          message.error(fileExt + " type is not allowed");
        } else {
          setLoading(true);

          const JsonData = {
            CompanyDocumentTypeId: 3,
            SizeInKB: selectedFile && selectedFile.size,
            Name: selectedFile && selectedFile.name,
            BlobUrl: "",
            BlobFileName: "",
            Extension: selectedFile && selectedFile.type,
            forCMV: true,
            forAgent: false,
          };

          var formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("Data", JSON.stringify(JsonData));

          onAddDocument(formData)
            .then(() => {
              count++;
              if (count == fileList.length) {
                onGetDocuments();
                message.success("Uploaded Successfully!");
                setLoading(false);
                setImageValues(null);
                setIsImage(false);
                setFileList([]);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      }
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => {
        return (
          <div className="table-action">
            <span>{record && yyyymmddFormate(record.createdDate)}</span>
          </div>
        );
      },
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   render: (text, record) => {
    //     return (
    //       <div className="table-action">
    //         <Select
    //           style={{ width: 100 }}
    //           placeholder="Select a person"
    //           optionFilterProp="children"
    //           small
    //           // onChange={onChange}
    //           // onFocus={onFocus}
    //           // onBlur={onBlur}
    //           // onSearch={onSearch}
    //         >
    //           <Option value="jack">Jack</Option>
    //           <Option value="lucy">Lucy</Option>
    //           <Option value="tom">Tom</Option>
    //         </Select>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <DownloadOutlined onClick={() => onDownloadDoc(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onDownloadDoc = (value) => {
    const docTypeOpt = {
      url: `v1/company/document/GetDocumentBytes/${value.id}/${value.blobFileName}`,
    };

    docTypeOpt.types = [
      "DOCUMENT_DOWNLOAD_SUCCESS",
      "DOCUMENT_DOWNLOAD_FAILURE",
    ];

    setLoading(true);
    apiRefresh
      .getFile(docTypeOpt)
      .then((res) => {
        downlodDocument(res, value);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${value.name}`);
  };

  const onHandleChange = (info) => {
    // if (
    //   fileList &&
    //   fileList[fileList.length - 1] &&
    //   fileList[fileList.length - 1].size > 50 * 1024 * 1024
    // ) {
    //   message.warning("File size should not be greater than 50MB");
    // } else {
    setIsImage(true);
    setImageValues(info.file.originFileObj || info.file);
    setFileList(info.fileList);
    setLoading(false);
    // getBase64(
    //   info.file.originFileObj,
    //   (imageUrl) => setImageUrl(imageUrl),
    //   setLoading(false)
    // );
    // }
  };

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.myVisaDoc} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  Check MyVisa Docs
                </span>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="client-section">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="d-flex space-between">
                    <div>
                      {!uploadDoc && uploadDoc === false ? (
                        <Button
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                          className="button-blue"
                        >
                          ADD NEW DOCUMENT
                        </Button>
                      ) : (
                        <Button
                          className="button-blue"
                          onClick={() => {
                            setUploadDoc(!uploadDoc);
                          }}
                          type="primary"
                        >
                          HIDE
                        </Button>
                      )}
                    </div>
                  </div>
                  {uploadDoc && uploadDoc === true && (
                    <div className="file-uploader">
                      <Form onFinish={onFinish} form={form}>
                        <Form.Item name="username">
                          <Upload
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={onHandleChange}
                            multiple
                          >
                            {fileList && fileList.length < 10 ? (
                              <div className="import-file-button-sec">
                                <div>
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                              </div>
                            ) : null}
                          </Upload>
                          <Button
                            htmlType="submit"
                            type="primary"
                            className={imageValues && "button-blue"}
                            disabled={!isImage}
                            style={{ marginTop: 20 }}
                          >
                            Upload
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                  <Spin size="large" spinning={loading}>
                    <div className="cmv-table">
                      <Table
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "table-row-light" : "table-row-dark"
                        }
                        columns={columns}
                        dataSource={findDocument && findDocument}
                        pagination={false}
                      />
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CheckMyVisaDocs;
