import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "@atlaskit/css-reset";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import initialData from "./initial-data";
import Column from "./column";
import { Button, Col, DatePicker, Input, Row, Select, Pagination } from "antd";
import { bindActionCreators } from "redux";
import { getClientTag, getTeamMember, getVisaType } from "../store/Actions";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class PotentialClientsStatus extends React.Component {
  state = initialData;

  componentDidMount() {
    this.props.getClientTag();
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    this.props.getTeamMember();
    console.log("data", this.state);
  }

  static getDerivedStateFromProps(props, state) {}
  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState(newState);
  };

  getDateFilters = () => {
    return (
      <Row>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"date-from-container"}>
            <p className={"date-text"}>DATE FROM:</p>
            <DatePicker
              format={dateFormat}
              size="small"
              onChange={this.handleChangeFromDate}
            />
          </div>
          <div className={"date-to-container"}>
            <p className={"date-text"}>DATE TO:</p>
            <DatePicker
              format={dateFormat}
              size="small"
              onChange={this.handleChangeToDate}
            />
          </div>
        </Col>
      </Row>
    );
  };

  getInputFilters = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"inputs-filters-container"}>
            <Input
              placeholder="Email"
              size="small"
              onChange={this.handleChangeEmail}
            />
          </div>
          <div className={"inputs-filters-container"}>
            <Input
              placeholder="First Name"
              size="small"
              onChange={this.handleChangeFirstName}
            />
          </div>
          <div className={"inputs-filters-container"}>
            <Input
              placeholder="Last Name"
              size="small"
              onChange={this.handleChangeLastName}
            />
          </div>
          <div className={"inputs-filters-container"}>
            <Input
              placeholder="Occupation"
              size="small"
              onChange={this.handleChangeAddress}
            />
          </div>
          <div className={"inputs-filters-container"}>
            <Input
              placeholder="Address"
              size="small"
              onChange={this.handleChangeOccupation}
            />
          </div>
        </Col>
      </Row>
    );
  };

  handleChangeVisaTypes = (value) => {};

  handleChangeClientTags = (value) => {};

  handleChangePriority = (value) => {};

  getSelectFilters = () => {
    return (
      <Row style={{ marginTop: "18px" }}>
        <Col xs={24} style={{ display: "flex" }}>
          <div className={"filters-container-sub-status"}>
            <Select
              className={"width-selects-filters"}
              placeholder="Please select interested visa"
              size="small"
              onChange={this.handleChangeVisaTypes}
            >
              {this.props &&
                this.props.visaTypeData &&
                this.props.visaTypeData.items &&
                this.props.visaTypeData.items.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.id}>{data.visaTypeName}</Option>;
                })}
            </Select>
          </div>
          <div className={"filters-container-sub-status"}>
            <Select
              className={"width-selects-filters"}
              placeholder="Please select client tags"
              size="small"
              onChange={this.handleChangeClientTags}
            >
              {this.props &&
                this.props.clientTagRes &&
                this.props.clientTagRes.items &&
                this.props.clientTagRes.items.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.id}>{data.name}</Option>;
                })}
            </Select>
          </div>
          <div className={"filters-container-sub-status"}>
            <Select
              className={"width-selects-filters"}
              size="small"
              placeholder={"Select priority"}
              onChange={this.handleChangePriority}
            >
              <Option value={0}>{"Oldest to Newest"}</Option>
              <Option value={1}>{"Newest to Oldest"}</Option>
            </Select>
          </div>
        </Col>
      </Row>
    );
  };

  onClickShowAll = () => {};

  onClickSearch = () => {};

  handleChangeFromDate = (date) => {};

  handleChangeToDate = (date) => {};

  handleChangeEmail = (e) => {};

  handleChangeFirstName = (e) => {};

  handleChangeLastName = (e) => {};

  handleChangeAddress = (e) => {};

  handleChangeOccupation = (e) => {};

  getFilterButtons = () => {
    return (
      <Row style={{ marginTop: "35px" }}>
        <Col xs={2} offset={18}>
          <Button
            size="small"
            type="primary"
            style={{ width: "75px" }}
            onClick={() => this.onClickShowAll()}
            className="button-blue"
          >
            <span className={"buttons-potential-clients"}>Show All</span>
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            size="small"
            type="primary"
            style={{ width: "75px" }}
            onClick={() => this.onClickSearch()}
            className="button-blue"
          >
            <span className={"buttons-potential-clients"}>Search</span>
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div style={{ overflowY: "hidden" }}>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div className="ts-container-inquiry">
              {this.getDateFilters()}
              {this.getInputFilters()}
              {this.getSelectFilters()}
            </div>
            {this.getFilterButtons()}
          </div>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div
            id="style-3"
            style={{
              marginLeft: "69px",
              marginRight: "69px",
              maxWidth: "940px",
              width: "940px",
              overflowX: "auto",
              overflowY: "auto",
              display: "flex",
            }}
          >
            {this.state.columnOrder.map((columnId) => {
              const column = this.state.columns[columnId];
              const tasks = column.taskIds.map(
                (taskId) => this.state.tasks[taskId]
              );

              return <Column key={column.id} column={column} tasks={tasks} />;
            })}
          </div>
        </DragDropContext>
        <div
          style={{
            float: "right",
            marginTop: "21px",
            marginRight: "74px",
            marginBottom: "21px",
          }}
        >
          <Pagination defaultCurrent={1} total={50} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamMembers:
      state &&
      state.teamMemberReducer &&
      state.teamMemberReducer.teamMembers &&
      state.teamMemberReducer.teamMembers.users &&
      state.teamMemberReducer.teamMembers.users,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    visaTypeData:
      state && state.visaTypeReducer && state.visaTypeReducer.visaTypeData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
  getTeamMember: bindActionCreators(getTeamMember, dispatch),
});

PotentialClientsStatus = connect(
  mapStateToProps,
  mapDispatchToProps
)(PotentialClientsStatus);

export default withRouter(PotentialClientsStatus);
