import React from "react";
import Select from "react-select";
import "./VisaFormStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import Modal from "react-awesome-modal";
import { ausVisaForms } from "./AusFormsList";

const headOption = [
  { tabName: "NZ FOMRS LIST", linkName: "/nz-form-list" },
  { tabName: "AUS FORMS LIST", linkName: "/aus-form-list" },
];

class AusFormList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workTypePopupVisible: false,
    };
  }

  openWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: true,
    });
  };

  closeWorkTypeModal = () => {
    this.setState({
      workTypePopupVisible: false,
    });
  };

  render() {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <PotentialHeaderTabs data={headOption} activeTab="AUS FORMS LIST" />

            <div className="report-container">
              <div>
                <div className="ca-gray-cont" style={{ paddingLeft: 20 }}>
                {ausVisaForms.map((data, index) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Images.pointer}
                          style={{ width: 20, height: 20 }}
                        />
                        <a
                          className="cv-normal-text"
                          style={{ marginLeft: 30, fontSize: 12, fontFamily: 'Poppins !important' }}
                          href={data.link}
                          target="_blank"
                        >
                          {data.title}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AusFormList;
