import React, { useEffect, useState, Fragment } from "react";
import {
  Select,
  Spin,
  Modal,
  Form,
  message,
  Row,
  Col,
  Input,
  Button,
  Tooltip,
  Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const { TextArea } = Input;

const FieldsRow = ({
  index,
  FileNote,
  onRemoveFileNote,
  gap,
  onAddTask,
  onUpdateFileNote,
  linkVisa,
  onChangeCheckbox,
  selectedFileNotesCont,
  add,
  remove,
  field,
  fields,
}) => {
  const [loading, setLoading] = useState(false);
  const [textMach, setTextMach] = useState(false);
  const [findWord, setFindWord] = useState("");
  const [replaceWord, setReplaceWord] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // getSms();
  }, []);

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="profile-input-border"
          style={{ marginTop: 20, width: "46%" }}
        >
          <Form.Item
            {...field}
            name={[field.name, "findWord"]}
            key={[field.fieldKey, "findWord"]}
          >
            <Input
              className="profile-input"
              placeholder="Find"
              type="text"
              //   value={findWord}
              style={{ width: "100%" }}
              onChange={(e) => {
                setFindWord(e.target.value);
                var html = selectedFileNotesCont;
                var div = document.createElement("div");
                div.innerHTML = html;
                var text = div.textContent || div.innerText || "";
                let result = text.match(e.target.value);
                if (result && result.length > 0) {
                  setTextMach(true);
                } else {
                  setTextMach(false);
                }
              }}
            />
          </Form.Item>
        </div>
        {/* {textMach && (
        <span
          style={{
            color: textMach ? "green" : "red",
            fontSize: 12,
            marginLeft: 5,
          }}
        >
          {textMach ? "Entered text found!" : "Entered text not found!"}
        </span>
      )} */}
        <div
          className="profile-input-border"
          style={{ marginTop: 20, marginLeft: 10, width: "46%" }}
        >
          <Form.Item
            {...field}
            name={[field.name, "replaceWord"]}
            key={[field.fieldKey, "replaceWord"]}
          >
            <Input
              className="profile-input"
              placeholder="Replace"
              type="text"
              //   value={replaceWord}
              style={{ width: "100%" }}
              onChange={(e) => setReplaceWord(e.target.value)}
            />
          </Form.Item>
        </div>
        <div style={{ marginLeft: 10, marginTop: 20 }}>
          {index == fields.length - 1 ? (
            <PlusCircleOutlined onClick={() => add()} />
          ) : (
            <MinusCircleOutlined onClick={() => remove()} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FieldsRow;
