import { api } from "../../services/api";
import * as types from "../Constants";

export const getAllBatches = () => {
  const options = {
    url: "v1/supervisor/batch/All",
  };
  options.types = [types.GET_BATCHES_SUCCESS, types.GET_BATCHES_FAILURE];
  return api.get(options);
};

export const createBatch = (data) => {
  const options = {
    url: "v1/supervisor/batch",
  };
  options.types = [types.CREATE_BATCH_SUCCESS, types.CREATE_BATCH_FAILURE];
  return api.post(options, data);
};

export const updateBatch = (data) => {
  const options = {
    url: "v1/supervisor/batch",
  };
  options.types = [types.CREATE_BATCH_SUCCESS, types.CREATE_BATCH_FAILURE];
  return api.put(options, data);
};

export const removeBatch = (data) => {
  const options = {
    url: "v1/supervisor/batch",
  };
  options.types = [types.REMOVE_BATCH_SUCCESS, types.REMOVE_BATCH_FAILURE];
  return api.delete(options, data);
};

export const getBatchesAccounts = (id) => {
  const options = {
    url: `v1/supervisor/batch/Student/All/${id}`,
  };
  options.types = [
    types.GET_BATCH_ACCOUNTS_SUCCESS,
    types.GET_BATCH_ACCOUNTS_FAILURE,
  ];
  return api.get(options);
};

export const uploadBatchAccounts = (data) => {
  const options = {
    url: `v1/supervisor/batch/Student/BulkImport`,
  };
  options.types = [
    types.UPLOAD_BATCH_ACCOUNTS_SUCCESS,
    types.UPLOAD_BATCH_ACCOUNTS_FAILURE,
  ];
  return api.post(options, data);
};

export const addBatchAccount = (data) => {
  const options = {
    url: `v1/supervisor/batch/Student`,
  };
  options.types = [
    types.ADD_BATCH_ACCOUNT_SUCCESS,
    types.ADD_BATCH_ACCOUNT_FAILURE,
  ];
  return api.post(options, data);
};

export const getImpersonInfo = (id) => {
  const options = {
    url: `v1/supervisor/batch/Student/Impersonate/${id}`,
  };
  options.types = [
    types.GET_IMPERSON_INFO_SUCCESS,
    types.GET_IMPERSON_INFO_FAILURE,
  ];
  return api.get(options);
};

export const removeStudentAccount = (data) => {
  const options = {
    url: `v1/supervisor/batch/Student`,
  };
  options.types = [
    types.REMOVE_STUDENT_ACCOUNT_SUCCESS,
    types.REMOVE_STUDENT_ACCOUNT_FAILURE,
  ];
  return api.delete(options, data);
};
