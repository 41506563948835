import React, { useState, Fragment, useEffect } from "react";
import {
  Spin,
  message,
  Tooltip,
  Modal,
  Row,
  Col,
  DatePicker,
  Button,
  AutoComplete,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import { Table, Tag, Space } from "antd";
import Branches from "./Branches";
import { Images } from "../Themes";

let notSearch = true;
const data = [];
const dateFormat = "YYYY-MM-DD";
const CompanyUsersList = ({
  onGetCompanyUser,
  companyUsersRes,
  onGetCompanies,
  companiesRes,
  onGetCompanyUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(false);
  const [singleData, setSingleData] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedCompanyListing, setSearchedCompanyListing] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [companiesList, setCompaniesList] = useState([]);

  useEffect(() => {
    // setLoading(true);
    // let data = {
    //   searchParam: "",
    //   pageSize: 20,
    //   pageNumber: 1,
    //   sortColumn: "CreatedDate",
    //   sortDirection: "Desc",
    //   startDate: "1900-01-01T00:00:00Z",
    //   endDate: new Date(),
    // };
    // onGetCompanyUser(data)
    //   .then(() => {
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  }, [onGetCompanyUser]);

  useEffect(() => {
    letterTempaltesFilter(companyUsersRes);
  }, [companyUsersRes]);

  const letterTempaltesFilter = (companyUsersRes) => {
    let filterList = [];

    if (companyUsersRes && companyUsersRes.users.length > 0) {
      setTotalPages(companyUsersRes.count);
      companyUsersRes.users.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });

      setSingleData(filterList);
    }
  };

  const fetchBranches = (record, onExpand) => {
    onExpand(record);
    setCompanyId(record && record.id);
  };

  const handleChangeDateFrom = (date, dateString) => {
    let _dateFrom = date === null ? "" : moment(date).format("YYYY-MM-DD");
    setDateFrom(_dateFrom);
  };

  const handleChangeDateTo = (date, dateString) => {
    let _dateTo = date === null ? "" : moment(date).format("YYYY-MM-DD");
    setDateTo(_dateTo);
  };

  const showAll = () => {
    setLoading(true);
    let data = {
      searchParam: "",
      pageSize: 20,
      pageNumber: 1,
      sortColumn: "CreatedDate",
      sortDirection: "Desc",
      startDate: "1900-01-01T00:00:00Z",
      endDate: new Date(),
    };
    onGetCompanyUser(data).then(() => {
      setDateTo("");
      setDateFrom("");
      setLoading(false);
    });
  };

  const handleClick = (_) => {
    let data = {
      is_locked: "ALL",
      is_deleted: "ALL",
      is_active: "ALL",
      company_id: _.id,
      startDate: "1900-01-01T00:00:00Z",
      endDate: new Date(),
      pageNumber: 1,
      pageSize: 20,
    };
    onGetCompanyUsers(data).then((res) => {});
  };

  const searchResult = (searchedResult) =>
    searchedResult.map((_, idx) => {
      let category = _.name;

      return {
        value: `${category} - ${idx + 1}`,
        label: (
          <div
            className="search-client-card-cont"
            style={{ border: "none" }}
            // ref={ref}
            tabIndex={idx}
            onClick={() => handleClick(_)}
            // onKeyDown={(e) => {
            //   handleKeyDown(e);
            // }}
          >
            {category}
          </div>
        ),
      };
    });

  const onSearchCompany = (searchtext) => {
    let _filterSearchList = [];
    setSearchText(searchtext);
    if (searchtext.length > 2 && notSearch) {
      notSearch = false;

      setTimeout(() => {
        setLoading(true);
        let data = {
          searchParam: searchtext,
          pageSize: 20,
          pageNumber: 1,
          sortColumn: "CreatedDate",
          sortDirection: "Desc",
          startDate: dateFrom || "1900-01-01T00:00:00Z",
          endDate: dateTo || new Date(),
        };
        onGetCompanies(data)
          .then((res) => {
            setLoading(false);
            notSearch = true;
            setCompaniesList(
              searchResult((res.payload && res.payload.list) || [])
            );
          })
          .catch((err) => {
            setLoading(false);
            notSearch = true;
          });
      }, 1500);

      setLoading(false);
    } else if (searchtext.length < 3) {
      setLoading(true);
      let data = {
        searchParam: "",
        pageSize: 20,
        pageNumber: 1,
        sortColumn: "CreatedDate",
        sortDirection: "Desc",
        startDate: dateFrom || "1900-01-01T00:00:00Z",
        endDate: dateTo || new Date(),
      };
      onGetCompanies(data).then(() => {
        setLoading(false);
      });
    } else {
      //
    }
  };

  const onSearch = () => {
    if (
      dateFrom === "" ||
      dateTo === "" ||
      (dateFrom === "" && dateTo === "")
    ) {
      message.error("Please select both dates, date from and date to!");
    } else {
      setLoading(true);
      let _filterList = [];
      let data = {
        searchParam: searchText,
        pageSize: 20,
        pageNumber: 1,
        sortColumn: "CreatedDate",
        sortDirection: "Desc",
        startDate: dateFrom || "1900-01-01T00:00:00Z",
        endDate: dateTo || new Date(),
      };
      onGetCompanyUser(data).then(() => {
        setLoading(false);
      });
      // if (companyUsersRes && companyUsersRes.list.length > 0) {
      //   companyUsersRes &&
      //     companyUsersRes.list.forEach((data) => {
      //       let modifiedDate = moment(data.createdDate).format(
      //         "YYYY-MM-DDT00:00:00+00:00"
      //       );
      //       let _comparedDate = moment(modifiedDate).format("YYYY-MM-DD");

      //       let isBetween = moment(_comparedDate).isBetween(dateFrom, dateTo);

      //       if (isBetween) {
      //         _filterList.push(data);
      //       } else {
      //         //do nothing
      //       }
      //     });
      // }
      // setSingleData(_filterList);
      // setLoading(false);
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        // <a
        // // onClick={()=> {fetchBranches(record.id)}}
        // >
        <span>{text}</span>
        // </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Branch",
      dataIndex: "branchNames",
      key: "branchNames",
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
  ];

  const paginate = (value) => {
    // this.props.onPaginate(true);
    setPageNumber(value.current);
    setPageSize(value.pageSize);
    setLoading(true);
    let data = {
      searchParam: searchText,
      pageSize: value.pageSize,
      pageNumber: value.current,
      sortColumn: "CreatedDate",
      sortDirection: "Desc",
      startDate: dateFrom || "1900-01-01T00:00:00Z",
      endDate: dateTo || new Date(),
    };
    onGetCompanyUser(data)
      .then(() => {
        setLoading(false);
        setDefaultCurrent(1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div style={{ display: "block" }}>
          <div className="ca-gray-cont">
            <Row>
              <Col xs={5} style={{ marginLeft: "26px", marginBottom: "20px" }}>
                <AutoComplete
                  // onSelect={this.onSelect}
                  virtual={false}
                  onSearch={onSearchCompany}
                  placeholder="Search company..."
                  options={companiesList}
                />
              </Col>
            </Row>
          </div>
          <Table
            columns={columns}
            dataSource={singleData && singleData}
            pagination={{
              defaultCurrent: defaultCurrent,
              total: totalPages,
              defaultPageSize: pageSize,
              pageSizeOptions: ["20", "50", "100"],
            }}
            onChange={paginate}
          />
        </div>
      </Spin>
    </>
  );
};
export default CompanyUsersList;
