import * as types from "../Constants";

export const teamMemberReducer = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_MEMBER_SUCCESS:
      return { ...state, teamMembers: action.payload };
    case types.TEAM_MEMBER_FAILURE:
      return {
        ...state,
        teamMemberError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };
    case types.TEAM_MEMBER_SUCCESS_OBJ:
      return { ...state, teamMembersObj: action.payload };
    case types.TEAM_MEMBER_FAILURE_OBJ:
      return {
        ...state,
        teamMembersObjError: action.payload.response
          ? action.payload.response.data
          : "Netwrork error",
      };
    case types.ADD_TEAM_MEMBER_SUCCESS:
      return { ...state, addTeamMemberSuccess: action.payload };
    case types.ADD_TEAM_MEMBER_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_PASSWORD_SUCCESS:
      return { ...state, addPasswordSuccess: action.payload };
    case types.ADD_PASSWORD_FAILURE:
      console.log("on error console", action.payload);
    case types.ASSIGN_BRANCH_SUCCESS:
      return { ...state, assignBranchSuccess: action.payload };
    case types.ASSIGN_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_ASSIGN_BRANCH_SUCCESS:
      return { ...state, updAssignBranchSuccess: action.payload };
    case types.UPDATE_ASSIGN_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.UNASSIGN_BRANCH_SUCCESS:
      return { ...state, unAssignBranchSuccess: action.payload };
    case types.UNASSIGN_BRANCH_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_AGENT_SUCCESS:
      return { ...state, addAgentSuccess: action.payload };
    case types.ADD_AGENT_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
