import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactUs from "../EmployerManagement/EmployerManagement/EmployerManagementTabs/EmployerDocuments/DocumentsCheckList/ContactUs";

import { getEmployerList } from "../store/Actions";

const mapStateToProps = state => {
  return {
    employerListRes: state.employerManagReducer.employerListRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetEmployerList: bindActionCreators(getEmployerList, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
