import React, { useEffect, Fragment, useState } from "react";
import {
  DownOutlined,
  PlusCircleOutlined,
  RedoOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Images } from "../../../Themes";
import {
  Table,
  Spin,
  Button,
  Menu,
  Dropdown,
  Input,
  Form,
  Checkbox,
  DatePicker,
  Select,
  message,
  Modal,
  Row,
  Col,
  Tooltip,
} from "antd";
import { yyyymmddFormate } from "../../../Common/reUseFunction";
import { apiRefresh } from "../../../services/api";
import moment from "moment";
import { PaperClipOutlined } from "@ant-design/icons";
import CreateEmailForm from "../CreateEmail/CreateEmailForm";
import saveAs from "file-saver";

const { Option } = Select;

const data = [
  {
    key: "1",
    name: "#",
    age: 32,
    address: "New York No. 1 Lake Park",
  },
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const PotentialClientHistory = ({
  resultList,
  emailContentData,
  onGetEmployerDocument,
  employerDocumentRes,
  potentialClientInfo,

  showModal,

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,
  onGetPClientHistory,
  potentialClientHistory,

  onSendEmailLink,
  onGetLetterTemplates,
  LetterTemplatesRes,
  onAddEmployerEmail,
  onGetSignature,
  onGetDocuments,
  onAddPotentialEmail,
  onGetImapForAll,
  imapForAllRes,
  signatureRes,
  onAddEmailDocument,
  documentRes,
  onGetDocumentDownload,
  setHandleKey,
  onGetPdf,
  initialCallApis,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [autoEmail, setAutoEmail] = useState(false);
  const [onImport, setOnImport] = useState(false);
  const [onCheck, setOnCheck] = useState(false);
  const [emailHistories, setEmailHistories] = useState([]);
  const [importSettingData, setImportSettingData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [isReplyAll, setIsReplyAll] = useState(false);
  const [emailContent, setEmailContent] = useState(null);
  const [isDraft, setDraft] = useState(false);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [selectedType, setSelectedType] = useState("0");
  const [oldId, setOldId] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [loadingPaginate, setLoadingPaginate] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    getPCEmailHistory();
  }, [potentialClientInfo]);

  const getPCEmailHistory = () => {
    setLoading(true);
    // onGetEmployerHistory(
    //   potentialClientInfo && potentialClientInfo.id,
    //   selectedBranchId
    // );
    // .then(() => setLoading(false));
    setSelectedType("0");
    setPageNumber(1);
    onGetPClientHistory(
      potentialClientInfo.id,
      "00000000-0000-0000-0000-000000000000",
      "0",
      pageSize,
      1,
      "0"
    )
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setEmailHistories([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setEmailHistories(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setLoading(false);
      })
      .catch((err) => {
        setEmailHistories([]);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (potentialClientHistory && potentialClientHistory.length > 0) {
  //     setEmailHistories(potentialClientHistory);
  //   } else {
  //     setEmailHistories([]);
  //   }
  // }, [potentialClientHistory]);

  useEffect(() => {
    let filterList = [];
    if (
      potentialClientHistory &&
      potentialClientHistory &&
      potentialClientHistory.length > 0
    ) {
      potentialClientHistory.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        filterList.push(data);
      });
      setEmailHistories(filterList);
    } else setEmailHistories([]);
  }, [potentialClientHistory]);

  useEffect(() => {
    // setLoading(true);

    // onGetImapForAll()
    //   .then((resp) => {
    //     if (!resp.payload.imapForAll) {
    const VisaOpt = {
      url: `v1/emailimport/PotentialClientImportSettings/${potentialClientInfo.id}`,
    };
    VisaOpt.types = [
      "POTENTIAL_EMAIL_IMPORT_SETTING_SUCCESS",
      "POTENTIAL_EMAIL_IMPORT_SETTING_FAILURE",
    ];

    apiRefresh.get(VisaOpt).then((res) => {
      if (res && res.length > 0) {
        res[0].importStartDate = moment(res[0].importStartDate);
        setImportSettingData(res[0]);
        setOnCheck(res[0].isAutoImportEmail);
        // setPrimaryEmail(res[0].isImportFromPrimaryEmail);
        // setSecondaryEmail(res[0].isImportFromSecondaryEmail);
        // setOtherEmail(res[0].isImportFromOtherEmail);
        // setImportSettingData(new Date());

        formEmail.setFieldsValue({
          isAutoImportEmail: res[0].isAutoImportEmail,
          isImportFromPrimaryEmail: res[0].isImportFromPrimaryEmail,
          isImportFromSecondaryEmail: res[0].isImportFromSecondaryEmail,
          isImportFromOtherEmail: res[0].isImportFromOtherEmail,
          importStartDate: res[0].importStartDate,
        });
      }
    });
    //   }
    // })
    // .catch((err) => {});

    // .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (imapForAllRes) {
      setOnCheck(imapForAllRes.imapForAll);
      formEmail.setFieldsValue({
        isAutoImportEmail: imapForAllRes.imapForAll,
      });
      if (imapForAllRes.imapForAll) {
        // setPrimaryEmail(true);
        // setSecondaryEmail(true);
        // setOtherEmail(true);
        // setImportSettingData(new Date());
        formEmail.setFieldsValue({
          isAutoImportEmail: true,
          isImportFromPrimaryEmail: true,
          isImportFromSecondaryEmail: true,
          isImportFromOtherEmail: true,
          importStartDate: moment(new Date()),
        });
      }
    }
  }, [imapForAllRes]);

  function handleMenuClick(e) {
    console.log("click", e);
  }

  function onDateChange(date, dateString) {
    console.log(date, dateString);
    formEmail.setFieldsValue({
      importStartDate: date,
    });
    setOnImport(date, dateString);
  }

  function onCheckChange(e) {
    setOnCheck(e.target.checked);
    formEmail.setFieldsValue({
      importStartDate: "",
      isAutoImportEmail: e.target.checked,
      isImportFromPrimaryEmail: false,
      isImportFromSecondaryEmail: false,
      isImportFromOtherEmail: false,
    });
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Delete Selected File</Menu.Item>
      <Menu.Item key="2">Download Selected</Menu.Item>
    </Menu>
  );

  const [formEmail] = Form.useForm();
  const onEmailFinish = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);

    const JsonData = {
      potentialClientId: potentialClientInfo && potentialClientInfo.id,
      importStartDate: values.importStartDate
        ? moment(values.importStartDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      isRead: true,
      isAutoImportEmail: onCheck,
      istTimeImport: true,
      clientImportSettingsId: importSettingData
        ? importSettingData.clientImportSettingsId
        : 0,
    };

    const importOption = {
      url: `v1/emailimport/PotentialClientImportSettings`,
    };
    importOption.types = [
      "POTENTIAL_EMAIL_IMPORT_SETTING_SUCCESS",
      "POTENTIAL_EMAIL_IMPORT_SETTING_FAILURE",
    ];

    if (JsonData.clientImportSettingsId === 0) {
      apiRefresh.post(importOption, JsonData).then(() => {
        const VisaOpt = {
          url: `v1/emailimport/PotentialClientImportSettings/${potentialClientInfo.id}`,
        };
        VisaOpt.types = [
          "POTENTIAL_EMAIL_IMPORT_SETTING_SUCCESS",
          "POTENTIAL_EMAIL_IMPORT_SETTING_FAILURE",
        ];

        apiRefresh.get(VisaOpt).then((res) => {
          setLoading(false);
          message.success("Uploaded Successfully!");
          if (res && res.length > 0) {
            res[0].importStartDate = moment(res[0].importStartDate);
            setImportSettingData(res[0]);
            setOnCheck(res[0].isAutoImportEmail);
          }
        });
      });
    } else {
      apiRefresh.put(importOption, JsonData).then(() => {
        const VisaOpt = {
          url: `v1/emailimport/PotentialClientImportSettings/${potentialClientInfo.id}`,
        };
        VisaOpt.types = [
          "POTENTIAL_EMAIL_IMPORT_SETTING_SUCCESS",
          "POTENTIAL_EMAIL_IMPORT_SETTING_FAILURE",
        ];

        apiRefresh.get(VisaOpt).then((res) => {
          setLoading(false);
          message.success("Uploaded Successfully!");

          if (res && res.length > 0) {
            res[0].importStartDate = moment(res[0].importStartDate);
            setImportSettingData(res[0]);
            setOnCheck(res[0].isAutoImportEmail);
          }
        });
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const columns = [
    {
      title: "",
      key: "index",
      render: (text, record) => {
        return (
          <div>
            {record.importText.toLowerCase() == "inbox" ? (
              <span
                className="sent-email"
                style={{ backgroundColor: "#2EC7EA" }}
              >
                Inbox Imported
              </span>
            ) : record.autoImport === true ? (
              <span className="draft" style={{ backgroundColor: "#FCBB3A" }}>
                Sent Imported
              </span>
            ) : record.draft === true ? (
              <span className="draft">Draft</span>
            ) : record.import ? (
              <span className="draft" style={{ backgroundColor: "#FCBB3A" }}>
                Imported
              </span>
            ) : record.isSent === true ? (
              <span className="sent-email">Sent Email</span>
            ) : record.status == "Queued" ? (
              <span>Sending</span>
            ) : (
              <span>{record.status}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "createdDate",
      width: "50px",
      dataIndex: "createdDate",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      render: (text, record) => {
        return <span>{moment(text).format("DD/MM/YYYY hh:mm:ss")}</span>;
      },
    },
    {
      title: "From",
      dataIndex: "from",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.from.localeCompare(b.from),
      render: (text, record) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowDetailPopup(true);
              setOldId(record.oldId);
              if (record.emailMessage) {
                record.emailMessage = record.emailMessage.replace(
                  "float:left;",
                  ""
                );
                record.emailMessage = record.emailMessage.replace(
                  "float: left;",
                  ""
                );
                record.emailMessage = record.emailMessage.replace(
                  "<img",
                  '<img style="max-width: 822px"'
                );
              }
              setEmailContent(record);
              if (record.draft) {
                setDraft(true);
              }
            }}
          >
            <span style={{ fontSize: "12px" }}>{record && record.from}</span>
          </div>
        );
      },
    },

    {
      title: "To",
      dataIndex: "to",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.to.localeCompare(b.to),
      render: (text, record) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowDetailPopup(true);
              setOldId(record.oldId);
              if (record.emailMessage) {
                record.emailMessage = record.emailMessage.replace(
                  "float:left;",
                  ""
                );
                record.emailMessage = record.emailMessage.replace(
                  "float: left;",
                  ""
                );
                record.emailMessage = record.emailMessage.replace(
                  "<img",
                  '<img style="max-width: 822px"'
                );
              }
              setEmailContent(record);
              if (record.draft) {
                setDraft(true);
              }
            }}
          >
            <span style={{ fontSize: "12px" }}> {record && record.to.length > 20 ? `${record.to.slice(0, 20)} ...` : record.to}</span>
          </div>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, record) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowDetailPopup(true);
              setOldId(record.oldId);
              // if (record.emailMessage) {
              //   record.emailMessage = record.emailMessage.replace(
              //     "float:left;",
              //     ""
              //   );
              //   record.emailMessage = record.emailMessage.replace(
              //     "float: left;",
              //     ""
              //   );
              // }
              setEmailContent(record);
              if (record.draft) {
                setDraft(true);
              }
            }}
          >
            <span style={{ fontSize: "12px" }}>{record && record.subject}</span>
          </div>
        );
      },
    },
    {
      title: "",
      // dataIndex: "address",
      title: "Action",
      render: (text, record) => {
        return (
          <div style={{ display: "block" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => historyConfirmation(record)}
                className="cv-action-icons-border"
                style={{ marginLeft: 5 }}
              >
                <img src={Images.deleteIcon} className="cv-action-icon" />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys({ selectedRowKeys });
  };

  const handleCancelDetailPopup = () => {
    setIsReplyAll(false);
    setIsReply(false);
    setIsForward(false);
    setShowForm(false);
    setDraft(false);
    setShowDetailPopup(false);
  };

  const moveToDocs = (attachment) => {
    var clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let formData = new FormData();
    // formData.append("file", selectedFile);
    setLoading(true);
    const JsonData = {
      SubjectId: potentialClientInfo.id,
      FamilyId: "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: 0,
      Title: "",
      SizeInKB: 0,
      DocuementExtension: "",
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      DocuementBlobUrl: attachment.blobURL,
      BlobFileName: attachment.name,
      oldId: oldId,
    };
    formData.append("Data", JSON.stringify(JsonData));
    var docTypeOpt = {
      url: `v1/document/FromUrl`,
    };

    docTypeOpt.types = ["MOVE_DOCUMENTS_SUCCESS", "MOVE_DOCUMENTS_FAILURE"];
    apiRefresh
      .post(docTypeOpt, formData)
      .then((res) => {
        message.success("Success!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed");
      });
  };

  const onDownloadAttachment = (attachment) => {
    let formData = new FormData();
    if (oldId) {
      var getAttachmentOpt = {
        url: `v1/document/GetOldDocumentBytesforAttachment/${attachment.name}/${attachment.blobURL}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        downlodDocument(res, attachment);
      });
    } else {
      let branchId = localStorage.getItem("selectedBranchId");
      var getAttachmentOpt = {
        url: `v1/document/GetDocumentBytesforAttachment/${attachment.blobURL}/${branchId}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        downlodDocument(res, attachment);
      });
    }
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(byte, value.name);
  };

  const historyConfirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value && value.id);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          // this.setState({ selectedRowKeys: newSelectedRowKeys });
          setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
        },
      },
    ],
  };

  const removeEmail = () => {
    setLoading(true);

    const remove = {
      ids: [onDelete],
    };

    const importOption = {
      url: `v1/imap/PotentialClientEmail`,
    };
    importOption.types = [
      "POTENTIAL_EMAIL_IMPORT_SETTING_SUCCESS",
      "POTENTIAL_EMAIL_IMPORT_SETTING_FAILURE",
    ];

    apiRefresh.delete(importOption, remove).then(() => {
      onGetPClientHistory(
        potentialClientInfo.id,
        "00000000-0000-0000-0000-000000000000",
        selectedType,
        "20",
        "1",
        "0"
      )
        .then((res) => {
          if (
            res &&
            res.payload &&
            res.payload.emailHistories &&
            res.payload.emailHistories.length === 0
          ) {
            setEmailHistories([]);
            setTotalPages(0);
          } else {
            let filterList = [];
            res &&
              res.payload &&
              res.payload.emailHistories.map((data, index) => {
                data.index = index;
                data.key = `${index + 1}`;
                filterList.push(data);
              });
            setEmailHistories(filterList);
            setTotalPages(res && res.payload && res.payload.count);
          }
          setPageNumber(1);
          setDefaultCurrent(1);
          setLoading(false);
          setModal1Visible(false);
          message.success("Successfully Deleted!");
        })
        .catch(() => {
          setEmailHistories([]);
          setLoading(false);
        });
    });
  };

  const paginate = (value) => {
    let pgNum = value.current;
    if (value.pageSize !== pageSize) {
      setPageSize(value.pageSize);
      setPageNumber(1);
      pgNum = 1;
    } else {
      setPageSize(value.pageSize);
      setPageNumber(value.current);
    }
    setLoadingPaginate(true);
    onGetPClientHistory(
      potentialClientInfo.id,
      "00000000-0000-0000-0000-000000000000",
      selectedType,
      value.pageSize,
      pgNum,
      "0"
    )
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setEmailHistories([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setEmailHistories(filterList);
        }
        setLoadingPaginate(false);
      })
      .catch(() => {
        setEmailHistories([]);
        setLoadingPaginate(false);
      });
  };

  const onChangeType = (val) => {
    setLoading(true);
    setSelectedType(val);
    onGetPClientHistory(
      potentialClientInfo.id,
      "00000000-0000-0000-0000-000000000000",
      val,
      "20",
      "1",
      "0"
    )
      .then((res) => {
        // setSelectedData(res.payload);
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setEmailHistories([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setEmailHistories(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setPageNumber(1);
        setDefaultCurrent(1);
        setLoading(false);
      })
      .catch(() => {
        setEmailHistories([]);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div className="employer-table border-color-blue">
        <div>
          <div className=" margin-btm">
            {autoEmail === false ? (
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  display: "flex",
                  backgroundColor: "#1281b9",
                  padding: 10,
                  borderRadius: 5,
                }}
                onClick={() => setAutoEmail(!autoEmail)}
              >
                <div>
                  <span style={{ color: "#FFFFFF" }}>Auto Email Import</span>
                </div>
                <DownOutlined style={{ color: "#FFFFFF" }} />
              </div>
            ) : (
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  display: "flex",
                  backgroundColor: "#1281b9",
                  padding: 10,
                  borderRadius: 5,
                }}
                onClick={() => setAutoEmail(!autoEmail)}
              >
                <div>
                  <span style={{ color: "#FFFFFF" }}>Auto Email Import</span>
                </div>
                <UpOutlined style={{ color: "#FFFFFF" }} />
              </div>
            )}
          </div>
          <div className="margin-btm">
            {autoEmail === true && (
              <Form
                onFinish={onEmailFinish}
                form={formEmail}
                className="auto-email margin-btm"
                initialValues={importSettingData}
              >
                <Form.Item name="isAutoImportEmail" valuePropName="checked">
                  <Checkbox onChange={onCheckChange} checked={onCheck}>
                    Auto Import
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  style={{ width: "400px" }}
                  name="importStartDate"
                  rules={[
                    {
                      required: onCheck,
                      message: "Required!",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onDateChange}
                    // picker="Start Date"
                    format="DD/MM/YYYY"
                    disabled={!onCheck}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="employer-btn"
                    style={{
                      backgroundColor: "#1081b9 ",
                      border: 0,
                      zIndex: "2",
                    }}
                    // onClick={() => showModal("add-documents")}
                    type="primary"
                    htmlType="submit"
                  >
                    UPDATE
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
        <div className="d-flex float-right dropdown-btn-reload-btn-row-pc-emp">
          <div style={{ marginRight: "7px", marginBottom: "26px", zIndex: 1 }}>
            <Select
              className="doc-filter"
              showSearch
              style={{ width: 120 }}
              placeholder="ALL"
              optionFilterProp="children"
              onChange={onChangeType}
              value={selectedType}
            >
              <Option value="0">All</Option>
              <Option value="1">Imported</Option>
              <Option value="3">Sent</Option>
              <Option value="4">Email Not Sent</Option>
              <Option value="2">Draft</Option>
            </Select>
          </div>
          <Tooltip title="Refresh" style={{ display: "inline-block" }}>
            <div
              className="reload-email-icon-cont"
              style={{ zIndex: 1, marginRight: 7 }}
              onClick={() => {
                initialCallApis();
                getPCEmailHistory();
              }}
            >
              <RedoOutlined className="reload-email-icon" />
            </div>
          </Tooltip>
          <div style={{ zIndex: 1 }}>
            <Dropdown.Button overlay={menu}></Dropdown.Button>
          </div>
        </div>
        <Spin size="large" spinning={loadingPaginate || loading}>
          <Table
            className="border-3 "
            rowSelection={rowSelection}
            showCount={true}
            columns={columns}
            dataSource={emailHistories}
            onChange={paginate}
            pagination={{
              defaultCurrent: defaultCurrent,
              current: pageNumber,
              total: totalPages,
              showSizeChanger: true,
              defaultPageSize: 20,
              pageSizeOptions: ["20", "50", "100"],
            }}
            scroll={{ x: "auto" }}
          />
        </Spin>
        {/* </Spin> */}
      </div>
      <Modal
        visible={showDetailPopup}
        title={"Email Detail"}
        onCancel={handleCancelDetailPopup}
        // onOk={onOkLinkButton}
        width="900px"
        footer={false}
      >
        <>
          {emailContent && !emailContent.draft && (
            <div className="client-email-popup-body">
              {emailContent && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="client-email-label">
                        <span>
                          {"Created Date: " +
                            moment(emailContent.createdDate).format(
                              "DD/MM/YYYY hh:mm:ss"
                            )}
                        </span>
                      </div>
                      <div
                        className="client-email-label"
                        style={{ marginLeft: 10 }}
                      >
                        <span>{"Created By: " + emailContent.userName}</span>
                      </div>
                    </div>
                    <div>
                      {emailContent.importText.toLowerCase() == "inbox" ? (
                        <span
                          className="sent-email"
                          style={{ backgroundColor: "#2EC7EA" }}
                        >
                          Inbox Imported
                        </span>
                      ) : emailContent.autoImport === true ? (
                        <span
                          className="draft"
                          style={{ backgroundColor: "#FCBB3A" }}
                        >
                          Sent Imported
                        </span>
                      ) : emailContent.draft === true ? (
                        <span className="draft">Draft</span>
                      ) : emailContent.import ? (
                        <span
                          className="draft"
                          style={{ backgroundColor: "#FCBB3A" }}
                        >
                          Imported
                        </span>
                      ) : emailContent.isSent === true ? (
                        <span className="sent-email">Sent Email</span>
                      ) : emailContent.status == "Queued" ? (
                        <span>Sending</span>
                      ) : (
                        <span>{emailContent.status}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <span className="client-email-address">
                      {"From: " + emailContent.from}
                    </span>
                  </div>
                  <div className="client-email-to-row">
                    <div>
                      <span className="client-email-address">
                        {"To: " + emailContent.to}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="client-email-address">
                      {"Subject: " + emailContent.subject}
                    </span>
                  </div>
                  <div>
                    <span className="client-email-address">
                      {"CC: " + emailContent.cc}
                    </span>
                  </div>
                  <div>
                    <span className="client-email-address">
                      {"Bcc: " + emailContent.bcc}
                    </span>
                  </div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: emailContent.emailMessage,
                      }}
                    />
                  </div>
                  {emailContent.emailAttachment.length > 0 && (
                    <div
                      className="body-attachment-row"
                      style={{ display: "inline-block" }}
                    >
                      {emailContent.emailAttachment.map((attachment, index) => {
                        return (
                          <div
                            style={{
                              margin: 10,
                              display: "inline-block",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <PaperClipOutlined />

                              <span
                                className="body-attachment-text"
                                style={{
                                  fontSize: 12,
                                  cursor: "pointer",
                                }}
                                onClick={() => onDownloadAttachment(attachment)}
                              >
                                {attachment.name}
                              </span>
                              <img
                                src={Images.forward}
                                style={{
                                  marginLeft: 10,
                                  width: 25,
                                  cursor: "pointer",
                                }}
                                onClick={() => moveToDocs(attachment)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <Row>
            <Col>
              {(showForm || isDraft) && (
                <CreateEmailForm
                  emailContentData={emailContentData}
                  resultList={resultList}
                  onSendEmailLink={onSendEmailLink}
                  onGetLetterTemplates={onGetLetterTemplates}
                  LetterTemplatesRes={LetterTemplatesRes}
                  onAddEmployerEmail={onAddEmployerEmail}
                  potentialClientInfo={potentialClientInfo}
                  onGetSignature={onGetSignature}
                  onGetDocuments={onGetDocuments}
                  onAddPotentialEmail={onAddPotentialEmail}
                  setLoading={setLoading}
                  loading={loading}
                  inHistoryPopup={true}
                  emailContent={emailContent}
                  forward={isForward}
                  reply={isReply}
                  replyAll={isReplyAll}
                  isDraft={isDraft}
                  handleCancelPopup={handleCancelDetailPopup}
                  signatureRes={signatureRes}
                  onAddEmailDocument={onAddEmailDocument}
                  documentRes={documentRes}
                  onGetEmployerDocument={onGetEmployerDocument}
                  onGetDocumentDownload={onGetDocumentDownload}
                  setHandleKey={setHandleKey}
                  onGetPdf={onGetPdf}
                  onGetPClientHistory={onGetPClientHistory}
                />
              )}
            </Col>
          </Row>

          {/* <Row
            className="margin-top-12"
            gutter={10}
          >
            <Col> */}
          <div></div>
          <div
            style={{
              display: "inline-block",
              marginLeft:
                showForm || isDraft
                  ? `calc(100% - 70px)`
                  : `calc(100% - 340px)`,
              marginTop: 20,
            }}
          >
            {!showForm && !isDraft && (
              <div style={{ display: "inline-block" }}>
                <div style={{ display: "inline-block", margin: 5 }}>
                  <Button
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => {
                      setIsForward(true);
                      setShowForm(true);
                    }}
                  >
                    Forward
                  </Button>
                </div>
                <div style={{ display: "inline-block", margin: 5 }}>
                  <Button
                    // onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => {
                      setIsReply(true);
                      setShowForm(true);
                    }}
                  >
                    Reply
                  </Button>
                </div>
                <div style={{ display: "inline-block", margin: 5 }}>
                  <Button
                    // onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                    onClick={() => {
                      setIsReplyAll(true);
                      setShowForm(true);
                    }}
                  >
                    Reply All
                  </Button>
                </div>
              </div>
            )}
            {/* </Col> */}
            <div style={{ display: "inline-block", margin: 5 }}>
              <Button
                // onClick={() => DraftData()}
                type="primary"
                className="login-form-button save-btn button-blue"
                onClick={() => {
                  setIsReplyAll(false);
                  setIsReply(false);
                  setIsForward(false);
                  setShowForm(false);
                  setDraft(false);
                  setShowDetailPopup(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
          {/* </Row> */}
        </>
      </Modal>

      <Modal
        title={false}
        style={{ top: 20 }}
        visible={modal1Visible}
        onOk={() => removeEmail(value)}
        onCancel={() => setModal1Visible(false)}
        Header={false}
      >
        <h4>Are you sure you want to delete this ?</h4>
      </Modal>
    </Fragment>
  );
};

export default PotentialClientHistory;
