import { api } from "../../services/api";
import * as types from "../Constants";

export const getTimeTracking = () => {
  const options = {
    url: `v1/branch/permissions`,
  };
  options.types = [
    types.GET_TIME_TRACKINGS_SUCCESS,
    types.GET_TIME_TRACKINGS_FAILURE,
  ];

  return api.get(options);
};

export const getTimeTrackingPopup = () => {
  const options = {
    url: `v1/users/TimeTrackingPopUp`,
  };
  options.types = [
    types.GET_TIME_TRACKINGS_POPUP_SUCCESS,
    types.GET_TIME_TRACKINGS_POPUP_FAILURE,
  ];

  return api.get(options);
};

export const addTimeTracking = (data) => {
  const options = {
    url: "v1/timetracking",
  };
  options.types = [
    types.ADD_TIME_TRACKINGS_SUCCESS,
    types.ADD_TIME_TRACKINGS_FAILURE,
  ];

  return api.post(options, data);
};

export const getVisaWorkType = () => {
  const options = {
    url: `v1/worktype/All`,
  };
  options.types = [
    types.GET_VISA_WORK_TYPE_SUCCESS,
    types.GET_VISA_WORK_TYPE_FAILURE,
  ];

  return api.get(options);
};

export const getVisaTypePrice = () => {
  const options = {
    url: `v1/visatype/price/All`,
  };
  options.types = [
    types.GET_VISA_TYPE_PRICE_SUCCESS,
    types.GET_VISA_TYPE_PRICE_FAILURE,
  ];

  return api.get(options);
};

export const getServiceType = () => {
  const options = {
    url: `v1/servicetype/All`,
  };
  options.types = [
    types.GET_SERVICE_TYPE_SUCCESS,
    types.GET_SERVICE_TYPE_FAILURE,
  ];

  return api.get(options);
};
