import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllClients from "../Clients/AllClients";

import {
  getClientTag,
  addClientTag,
  removeClientTag,
  updetaClientTag,
  getProfileClientTag,
  searchConnection,
  removeClient,
  getVisaType,
  getVisaStatus,
  getTeamMember
} from "../store/Actions";

const mapStateToProps = state => {
  return {
    clientTagRes: state.accountSetReducer.clientTagRes,
    addClientTagRes: state.accountSetReducer.addClientTagRes,
    removeClientTagRes: state.accountSetReducer.removeClientTagRes,
    updateClientTagRes: state.accountSetReducer.updateClientTagRes,
    searchConnectionData: state.clientProfileReducer.searchConnectionData,
    removeClientRes: state.clientProfileReducer.removeClientRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    teamMembers: state.teamMemberReducer.teamMembers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onAddClientTag: bindActionCreators(addClientTag, dispatch),
    onRemoveClientTag: bindActionCreators(removeClientTag, dispatch),
    onUpdetaClientTag: bindActionCreators(updetaClientTag, dispatch),
    onSearchConnection: bindActionCreators(searchConnection, dispatch),
    onRemoveClient: bindActionCreators(removeClient, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllClients);
