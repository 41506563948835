import * as types from "../Constants";

export const groupReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GROUP_SUCCESS:
      return { ...state, groupsData: action.payload };
    case types.GROUP_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_GROUP_SUCCESS:
      return { ...state, addGroupSuccess: action.payload };
    case types.ADD_GROUP_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_GROUP_SUCCESS:
      return { ...state, updGroupSuccess: action.payload };
    case types.UPDATE_GROUP_FAILURE:
      console.log("on error console", action.payload);
    case types.DELETE_GROUP_SUCCESS:
      return { ...state, deleteGroupSuccess: action.payload };
    case types.DELETE_GROUP_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
