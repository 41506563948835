import { api } from "../../services/api";
import * as types from "../Constants";
import {
  BRANCH_USER_LOCK_UNLOCK_FAILURE,
  BRANCH_USER_LOCK_UNLOCK_SUCCESS,
} from "../Constants";

export const onGetCompanies = (data) => {
  const options = {
    url: "v1/admin/company/All",
  };

  options.types = [
    types.GET_ADMIN_COMPANIES_SUCCESS,
    types.GET_ADMIN_COMPANIES_FAILURE,
  ];

  return api.post(options, data);
};

export const onGetBranches = (id) => {
  const options = {
    url: "v1/admin/branch/all/" + id,
  };

  options.types = [
    types.GET_ADMIN_BRANCHES_SUCCESS,
    types.GET_ADMIN_BRANCHES_FAILURE,
  ];

  return api.get(options);
};

export const onGetBranchDetail = (id) => {
  const options = {
    url: "v1/admin/branch/" + id,
  };

  options.types = [
    types.GET_BRANCH_DETAIL_SUCCESS,
    types.GET_BRANCH_DETAIL_FAILURE,
  ];

  return api.get(options);
};

export const onUserLockUnlock = (data) => {
  const options = {
    url: "v1/admin/branch/User/LockUnLockUser",
  };

  options.types = [
    types.BRANCH_USER_LOCK_UNLOCK_SUCCESS,
    types.BRANCH_USER_LOCK_UNLOCK_FAILURE,
  ];

  return api.post(options, data);
};

export const updateBranchDetail = (data) => {
  const options = {
    url: "v1/admin/branch",
  };

  options.types = [
    types.UPDATE_BRANCH_DETAIL_SUCCESS,
    types.UPDATE_BRANCH_DETAIL_FAILURE,
  ];

  return api.put(options, data);
};

export const onImpersonate = (id) => {
  const options = {
    url: "v1/admin/branch/Impersonate/" + id,
  };

  options.types = [types.IMPERSONATE_SUCCESS, types.IMPERSONATE_FAILURE];

  return api.get(options);
};

export const onGetCompanyUsers = (data) => {
  const options = {
    url: `v1/admin/Users/AllCompanyUser`,
  };

  options.types = [
    types.GET_COMPANY_USERS_SUCCESS,
    types.GET_COMPANY_USERS_FAILURE,
  ];

  return api.post(options, data);
};
