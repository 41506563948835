import React, { useState, Fragment, useEffect } from "react";
// import JobEmployerForm from "./JobEmployerForm";
import { Images } from "../../Themes";
import { Spin, message, Modal } from "antd";
// import SalesPipelineTable from "./SalesPipelineTable";
import AddStageForm from "./AddStageForm";
import { apiRefresh } from "../../services/api";
import StageTable from "./StageTable";

let clientprofileid = sessionStorage.getItem("clientprofileid");

const DealStages = ({
  onAddSalePipeline,
  onGetSalePipeline,
  selectedKey,
  onGetMailChimp,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState({});
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState();
  const [pipelineStages, setPiplineStages] = useState(null);
  const [pipelineObj, setPipelineObj] = useState(null);
  const [gridList, setGridList] = useState([]);
  const [mailChimpList, setMailChimpList] = useState([]);

  useEffect(() => {
    if (selectedKey === "3") {
      getPipeline();
      const getSendGridListOpt = {
        url: `v1/deal/sendgridmap/GetSendGridList`,
      };
      getSendGridListOpt.types = [
        "GET_SEND_GRID_LIST_SUCCESS",
        "GET_SEND_GRID_LIST_FAILURE",
      ];
      setLoading(true);
      apiRefresh
        .get(getSendGridListOpt)
        .then((res) => {
          setLoading(false);
          setGridList(res.list || []);
        })
        .catch((err) => {
          setLoading(false);
        });
      const getMailChmipListOpt = {
        url: `v1/mailchimp/GetAllList`,
      };
      getMailChmipListOpt.types = [
        "GET_MAILCHIMP_LIST_SUCCESS",
        "GET_MAILCHIMP_LIST_FAILURE",
      ];
      setLoading(true);
      apiRefresh
        .get(getMailChmipListOpt)
        .then((res) => {
          setLoading(false);
          setMailChimpList(res.lists || []);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedKey]);

  const getPipeline = () => {
    const getPipelineOpt = {
      url: `v1/deal/pipeline/GetByBranchId`,
    };
    getPipelineOpt.types = [
      "REMOVE_PIPELINE_SUCCESS",
      "REMOVE_PIPELINE_FAILURE",
    ];
    setLoading(true);
    apiRefresh.get(getPipelineOpt).then((res) => {
      setPiplineStages(res);
      setLoading(false);
    });
  };

  const showModal = (value, modelType, pipeline) => {
    setIsModalVisible(true);
    setCreateMode(modelType);
    setUpdatedata(value);
    setPipelineObj(pipeline);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div style={{ margin: 25, marginTop: 0 }}>
          <div style={{ display: "flex" }}>
            <div className="contact-emp" style={{ padding: 20 }}>
              <div className="client-tag-form"></div>
              <div>
                {pipelineStages &&
                  pipelineStages.pipeline.map((pipeline, ind) => (
                    <div style={{ margin: 10 }}>
                      <div className="d-end space-between">
                        <div
                          style={{ marginBottom: "7px" }}
                          className="contact-Head"
                        >
                          <div className="d-flex align-item ">
                            <h4
                              className="top-text heading-forms"
                              style={{
                                marginLeft: "1px",
                                fontWeight: 600,
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {pipeline.name}
                            </h4>
                          </div>
                          <>
                            <img
                              style={{ width: "29px", height: "29px" }}
                              src={Images.addIcon}
                              className="icons-client"
                              type="primary"
                              onClick={() =>
                                showModal(
                                  !isModalVisible,
                                  "add-stage",
                                  pipeline
                                )
                              }
                            />
                          </>
                        </div>
                      </div>

                      <StageTable
                        getSalePipelineRes={pipeline.stagesList}
                        showModal={showModal}
                        isModalVisible={isModalVisible}
                        onGetSalePipeline={getPipeline}
                        setLoading={setLoading}
                        getPipeline={getPipeline}
                        gridList={gridList}
                        onGetMailChimp={onGetMailChimp}
                        mailChimpList={mailChimpList}
                      />
                    </div>
                  ))}
              </div>
              {isModalVisible && (
                <Modal
                  title={
                    createMode === "add-stage" ? "Add Stage" : "Update Stage"
                  }
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  header={false}
                  footer={false}
                  width={550}
                >
                  <AddStageForm
                    // onAddSalePipeline={onAddSalePipeline}
                    handleCancel={handleCancel}
                    createMode={createMode}
                    setLoading={setLoading}
                    loading={loading}
                    onGetSalePipeline={getPipeline}
                    updatedata={updatedata}
                    pipelineObj={pipelineObj}
                  />
                </Modal>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default DealStages;
