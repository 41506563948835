import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Comment,
  Avatar,
  Image,
  Spin,
  Tooltip,
  message,
  Checkbox,
  Select,
  Modal,
} from "antd";
import {
  UserOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CommentList from "./CommentFunction";
import moment from "moment";
import "./comment.css";
const { Option } = Select;

const { TextArea } = Input;

const CompleteComment = ({
  CommentClose,
  getTasksComments,
  dailyTaskRes,
  data,
  addTaskComment,
  getTaskFollowers,
  onRemoveFollower,
  followers,
  comments,
  getComments,
  getFollower,
  onRemoveTaskClient,
  onSearchClient,
  onSearchPotentialClient,
  showComment,
  reloadFlag,
  setReload,
  onAddFileNote,
}) => {
  const [submitting, setSubmitting] = useState();
  const [value, setValue] = useState();
  const [modalLoading, setLoading] = useState(false);
  const [taskComments, setTaskComments] = useState([]);
  const [taskFollowers, setFollowers] = useState([]);
  const [isPotential, setIsPotential] = useState(false);
  const [linkClient, setLinkClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [linkClientName, setLinkClientName] = useState("");
  const [connectionsList, setConnectionsList] = useState([]);
  const [checkComments, setCheckComments] = useState(false);
  const [fileNoteModal, setFileNoteModal] = useState(false);
  const [filenote, setFileNote] = useState(null);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    // setTaskComments(comments);
    // setFollowers(followers);
    if (showComment) {
      getTaskFollowers(data.id).then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
        setTaskComments(comments);
      });
    }
  }, [showComment]);

  const handleSubmit = () => {
    setSubmitting(true);
    const userId = localStorage.getItem("userId");
    let payload = {
      taskId: data.id,
      description: value,
    };
    addTaskComment(payload)
      .then((res) => {
        setSubmitting(false);
        setValue(null);
        getTasksComments(data.id)
          .then((res) => {
            let comments = [];
            res.payload.items.forEach((item) => {
              comments.push(item);
            });
            setTaskComments(comments);
            setLoading(false);
          })
          .catch((error) => {
            setTaskComments([]);
            setLoading(false);
          });
      })
      .catch((error) => {
        setSubmitting(false);
        setValue(null);
      });
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleFileNoteChange = (e) => {
    setFileNote(e.target.value);
  };

  const removeFollower = (id) => {
    let userId = localStorage.getItem("userId");
    let payload = {
      id: id,
      delete: true,
    };
    onRemoveFollower(payload).then((res) => {
      message.success("Follower Removed!");
      getTaskFollowers(data.id).then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
      });
    });
  };

  const removeClient = () => {
    let payload = {
      id: data.id,
      subjectId: "00000000-0000-0000-0000-000000000000",
    };
    onRemoveTaskClient(payload)
      .then((res) => {
        message.success("Successfully Removed Client!");
        // window.location.reload();
        setReload(!reloadFlag);
      })
      .catch((error) => {
        message.error("Unable to remove client!");
      });
  };

  const onSearch = (val) => {
    console.log("search:", val);
    if (val.length > 2) {
      setConnectionsList([]);
      if (isPotential) {
        onSearchPotentialClient(val).then((res) => {
          setConnectionsList([]);
          if (
            res.payload.potentialClients &&
            res.payload.potentialClients.length > 0
          ) {
            setClientList(res.payload.potentialClients);
            for (let i = 0; i < res.payload.potentialClients.length; i++) {
              setConnectionsList((prevState) => [
                ...prevState,
                <Option key={res.payload.potentialClients[i].id}>
                  {res.payload.potentialClients[i].label}
                </Option>,
              ]);
            }
          }
        });
      } else {
        onSearchClient(val).then((res) => {
          setConnectionsList([]);
          if (res.payload.clients && res.payload.clients.length > 0) {
            setClientList(res.payload.clients);
            for (let i = 0; i < res.payload.clients.length; i++) {
              setConnectionsList((prevState) => [
                ...prevState,
                <Option key={res.payload.clients[i].id}>
                  {res.payload.clients[i].firstName +
                    " " +
                    res.payload.clients[i].lastName}
                </Option>,
              ]);
            }
          }
        });
      }
    }
  };

  const onChangePotential = (e) => {
    setIsPotential(e.target.checked);
  };

  const onChangeHandler = (value) => {
    setLinkClient(value);
    var findClient = clientList.find((obj) => obj.id === value);

    if (findClient) {
      setLinkClientName(findClient.firstName + " " + findClient.lastName);
      let payload = {
        id: data.id,
        subjectId: findClient.id,
      };
      onRemoveTaskClient(payload)
        .then((res) => {
          message.success("Successfully Added Client!");
          // window.location.reload();
          setReload(!reloadFlag);
        })
        .catch((error) => {
          message.error("Unable to Add client!");
        });
    }
  };

  const handleFileNoteSubmit = (e) => {
    const userId = localStorage.getItem("userId");
    const clientId = sessionStorage.getItem("clientprofileid");
    const payload = {
      clientId: clientId,
      fileNote: filenote,
    };
    onAddFileNote(payload)
      .then(() => {
        message.success("File Note Added!");
        setFileNoteModal(false);
      })
      .catch((error) => {
        message.error("Unable to add File Note!");
      });
  };

  const setFileNoteValue = (data) => {
    const taskDescription = data.taskDescription;
    let fileNoteValue =
      `${data.taskTitle}` + "\n" + `task description: ` + `${taskDescription}`;
    getTasksComments(data.id)
      .then((res) => {
        const comments = res.payload.items;
        comments.forEach((item) => {
          fileNoteValue =
            fileNoteValue + "\n" + item.userName + ": " + item.description;
        });
        setFileNote(fileNoteValue);
      })
      .catch((error) => {
        setFileNote(fileNoteValue);
      });
  };

  const renderAddFileNote = () => (
    <Modal
      title="Add To File Note"
      visible={fileNoteModal}
      onCancel={() => setFileNoteModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={false}>
        <Form name="basic" initialValues={{ remember: true }}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleFileNoteChange(e)}
                        value={filenote}
                        showCount
                        maxLength={300}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleFileNoteSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        SUBMIT
                      </Button>
                    </view>
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item className="comment-btn">
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
        >
          COMMENT
        </Button>
      </Form.Item>
    </>
  );

  // const comments = [
  //   {
  //     actions: [<span key="comment-list-reply-to-0">Reply to</span>],
  //     author: 'Han Solo',
  //     avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
  //     content: (
  //       <p>
  //         We supply a series of design principles, practical patterns and high quality design
  //         resources (Sketch and Axure), to help people create their product prototypes beautifully and
  //         efficiently.
  //       </p>
  //     ),]

  return (
    <Form name="basic" initialValues={{ remember: true }}>
      <div className="bg-box-white mar-b">
        <div className="w-96">
          <div className="d-flex space-between ">
            <div className="follower-row d-flex">
              <span>
                <h4>Followers</h4>
              </span>
              {followers.length
                ? followers.map((item) => (
                    <span
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      {item.imgURL &&
                      item.imgURL != null &&
                      item.imgURL != "" ? (
                        <Tooltip
                          title={item.fullName}
                          color="#eae9d4"
                          overlayInnerStyle={{ color: "black" }}
                        >
                          <div style={{ position: "relative" }}>
                            <CloseCircleOutlined
                              style={{
                                position: "absolute",
                                right: 0,
                                top: -8,
                                cursor: "pointer",
                              }}
                              onClick={() => removeFollower(item.id)}
                            />
                            <Avatar
                              src={
                                <Image
                                  src={item.imgURL}
                                  style={{ height: 32, width: 32 }}
                                />
                              }
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={item.fullName}
                          color="#eae9d4"
                          overlayInnerStyle={{ color: "black" }}
                        >
                          <div style={{ position: "relative" }}>
                            <CloseCircleOutlined
                              style={{
                                position: "absolute",
                                right: 0,
                                top: -8,
                                cursor: "pointer",
                              }}
                              onClick={() => removeFollower(item.id)}
                            />
                            <Avatar
                              className="mar-right"
                              size="small"
                              style={{ height: 32, width: 32 }}
                              icon={
                                <UserOutlined
                                  style={{ height: 32, width: 32 }}
                                />
                              }
                            />
                          </div>
                        </Tooltip>
                      )}
                    </span>
                  ))
                : null}
            </div>
            {/* <div>
              <CloseOutlined onClick={CommentClose} />
            </div> */}
          </div>
          <div className="tasks-comment">
            {data.potentialClientName === "" && data.clientName === "" ? (
              <div className="mdtr-checkbox-cont" style={{ marginTop: 10 }}>
                <Checkbox
                  checked={isPotential}
                  onChange={onChangePotential}
                  className="sus-checkbox"
                />
                <span className="cv-normal-text">Potential Client</span>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={onChangeHandler}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={linkClient}
                >
                  {connectionsList}
                </Select>
              </div>
            ) : null}
            <div class="dashboard_container">
              {data.potentialClientName != "" ? (
                data.potentialClientName ? (
                  <div class="hide_btn">
                    <CloseOutlined
                      style={{ marginRight: 10 }}
                      onClick={() => removeClient()}
                    />
                    <span>Client : {data.potentialClientName}</span>
                    <PlusCircleOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        setFileNoteValue(data);
                        setFileNoteModal(true);
                      }}
                    />
                  </div>
                ) : null
              ) : data.clientName ? (
                <div class="hide_btn">
                  <CloseOutlined
                    style={{ marginRight: 10 }}
                    onClick={() => removeClient()}
                  />
                  <span>Client : {data.clientName}</span>
                  <PlusCircleOutlined
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setFileNoteValue(data);
                      setFileNoteModal(true);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <Comment
              // avatar={
              //   <Avatar
              //     src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              //     alt="Han Solo"
              //   />
              // }
              content={
                <view>
                  <TextArea
                    rows={4}
                    onChange={(e) => handleChange(e)}
                    value={value}
                  />
                  <Button
                    style={{ marginTop: 10 }}
                    htmlType="submit"
                    loading={submitting}
                    onClick={(e) => handleSubmit(e)}
                    type="primary"
                    className="task-blue"
                  >
                    Comment
                  </Button>
                </view>

                // <Editor
                //   // onChange={handleChange}
                //   onSubmit={(value) => {
                //
                //     handleSubmit();
                //   }}
                //   submitting={submitting}
                //   value={value}
                // />
              }
            />
            <div>
              <div
                className="d-flex comment-width"
                style={{ alignItems: "center" }}
              >
                <Spin spinning={modalLoading} size="large">
                  <div>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        width: "100%",
                        marginBottom: 10,
                      }}
                    >
                      <div>
                        <h5>Task Description: </h5>
                      </div>
                      <div>
                        <p>{data.taskDescription}</p>
                      </div>
                    </div>
                    {taskComments.length
                      ? taskComments.map((item, index) => (
                          <div
                            style={{
                              borderBottom: "1px solid #ccc",
                              width: 642,
                              marginBottom: 10,
                            }}
                          >
                            <div
                              class="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              {item.imgURL &&
                              item.imgURL != null &&
                              item.imgURL != "" ? (
                                <Avatar
                                  src={
                                    <Image
                                      src={item.imgURL}
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              ) : (
                                <Avatar
                                  className="mar-right"
                                  size="small"
                                  style={{ height: 32, width: 32 }}
                                  icon={
                                    <UserOutlined
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              )}
                              <div style={{ marginLeft: 3 }}>
                                <h4>{item.userName}</h4>
                                <span style={{ fontSize: 12 }}>
                                  {moment(item.createdDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderAddFileNote()}
    </Form>
  );
};

export default CompleteComment;
