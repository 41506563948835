import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import { Select } from "antd";
import { PotentialClientStatus, VisaStatus, VisaType } from "../../Containers";

const { Option } = Select;

const OrganizationSetting = ({ onSetActiveInnerTab }) => {
  const [activeTab, setActiveTab] = useState(false);

  const onChangeInner = (value) => {
     
    setActiveTab(value);
    onSetActiveInnerTab(value);
  };
  return (
    <Fragment>
      <div></div>
    </Fragment>
  );
};
export default OrganizationSetting;
