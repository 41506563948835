import React, { Fragment } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Form,
  Table,
  Modal,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextFilled,
  FileTextOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getClientTag,
  getDueOverDueInvoices,
  getInvoiceInfo,
  getInvoiceTemplate,
  getParentInvoiceListing,
  getProfileClientTag,
  getSchoolInvoiceInfo,
  getUserBranch,
  getWorkCompletedInvoices,
  getWorkInProgressInvoices,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Listing from "./Templates/Listing";
import { Images } from "../Themes";
import { apiRefresh } from "../services/api";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;
class InvoiceTemplates extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      branchId: selectedBranchId,
      subjectTypeId: 0,
      pageNumber: 1,
      pageSize: 5,
      dateFrom: "1900-01-01T00:00:00+00:00",
      dateTo: "1900-01-01T00:00:00+00:00",
      loading: false,
      workInProgressInvoices: [],
      workCompletedInvoices: [],
      totalPages: 0,
      defaultCurrent: 1,
      key: "1",
      name: "",
      email: "",
      invoiceNo: "",
      clientTagId: 0,
      loadingTable: false,
      totalAmount: 0,
      templates: [],
      modalVisible: false,
      modal1Visible: false,
      templateObj: null,
      tempLines: [],
      templateLine: null,
      ids: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getTemplates();
    this.props.onGetUserBranch();
  }

  getTemplates = () => {
    this.props
      .getInvoiceTemplate(this.state, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        // this.props.getClientTag();
        this.setState({ loading: false, templates: res.payload });
      })
      .catch(() => {
        // this.props.getClientTag();
        this.setState({ loading: false });
      });
  };

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00:00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00:00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {}
    );
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  paginate = (value) => {
    this.setState(
      {
        pageSize: value.pageSize,
        pageNumber: value.current,
        loadingTable: true,
      },
      () => {
        if (this.state.key === "1") {
          this.props
            .getWorkInProgressInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.workInProgressInvoicesCount &&
                this.props.workInProgressInvoicesCount;
              this.setState({
                loadingTable: false,
                defaultCurrent: value.current,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
        } else {
          this.props
            .getWorkCompletedInvoices(this.state)
            .then(() => {
              let _length;
              _length =
                this.props &&
                this.props.workCompletedInvoicesCount &&
                this.props.workCompletedInvoicesCount;
              this.setState({
                loadingTable: false,
                defaultCurrent: value.current,
                totalPages: _length,
              });
            })
            .catch(() => {
              this.setState({ loadingTable: false });
            });
        }
      }
    );
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeInvoiceNumber = (e) => {
    this.setState({ invoiceNo: e.target.value });
  };

  getLines = (id) => {
    const invoiceTempOpt = {
      url: `v1/invoice/AddNewLine/${id}`,
    };
    invoiceTempOpt.types = ["TEMPLATE_LINES_SUCCESS", "TEMPLATE_LINES_FAILURE"];
    apiRefresh.get(invoiceTempOpt).then((res) => {
      var bIds = [];
      for (var i = 0; i < res.branches.length; i++) {
        bIds.push(res.branches[i].branchId);
      }
      this.setState({
        loading: false,
        tempLines: res.items,
        modalVisible: true,
        ids: bIds,
      });
    });
  };

  columns = [
    {
      title: "Template Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record, ind) => (
        <>
          <Tooltip title="Detail" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.setState({ loading: true, templateObj: record });
                this.getLines(record.id);
              }}
            >
              {/* <EditOutlined /> */}
              <img src={Images.detailEmail} className="cv-action-icon" />
            </a>
          </Tooltip>
          <Tooltip title="Delete" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.setState({ loading: true });
                const invoiceTempOpt = {
                  url: `v1/invoice/DeleteTemplate/${record.id}`,
                };
                invoiceTempOpt.types = [
                  "TEMPLATE_LINES_SUCCESS",
                  "TEMPLATE_LINES_FAILURE",
                ];
                apiRefresh
                  .delete(invoiceTempOpt)
                  .then((res) => {
                    this.getTemplates();
                  })
                  .catch((err) => {
                    this.setState({ loading: false });
                    message.error("Failed");
                  });
              }}
            >
              {/* <DeleteOutlined /> */}
              <img src={Images.deleteIcon} className="cv-action-icon" />
            </a>
          </Tooltip>
        </>
      ),
    },
  ];

  linesColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record, ind) => (
        <>
          <Tooltip title="Detail" style={{ display: "inline-block" }}>
            <a
              href="javascript:"
              style={{ marginRight: "10px" }}
              onClick={() => {
                this.setState({ templateLine: record, modal1Visible: true });
              }}
            >
              <EditOutlined />
            </a>
          </Tooltip>
        </>
      ),
    },
  ];

  onChange = (value) => {
    this.setState({ subjectTypeId: value }, () => {
      if (this.state.key === "1") {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkInProgressInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      } else {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkCompletedInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      }
    });
  };

  onChangeTab = (key) => {
    this.setState({ key: key }, () => {
      if (this.state.key === "1") {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkInProgressInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workInProgressInvoicesCount &&
                  this.props.workInProgressInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      } else {
        this.setState({ loadingTable: true });
        this.setState(
          {
            pageNumber: 1,
            pageSize: 5,
          },
          () => {
            this.props
              .getWorkCompletedInvoices(this.state)
              .then(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  defaultCurrent: 1,
                  totalPages: _length,
                });
              })
              .catch(() => {
                let _length;
                _length =
                  this.props &&
                  this.props.workCompletedInvoicesCount &&
                  this.props.workCompletedInvoicesCount;
                this.setState({
                  loadingTable: false,
                  totalPages: _length,
                  totalAmount: _length,
                });
              });
          }
        );
      }
    });
  };

  getTable = () => {
    return (
      <div>
        <Row>
          <Col xs={24}>
            {this.state.loadingTable ? (
              <div className={"spinner-xero"}>
                <Spin size="large" />
              </div>
            ) : (
              <Row>
                <Col xs={24} className={"school-table overflow"}>
                  <Table
                    dataSource={this.state.templates}
                    columns={this.columns}
                    // onChange={this.paginate}
                    // pagination={{
                    //   defaultCurrent: this.state.defaultCurrent,
                    //   total: this.state.totalPages,
                    //   defaultPageSize: 20,
                    //   showSizeChanger: false,
                    // }}
                    pagination={false}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  onChangeBranch = (value) => {
    if (value.length < this.state.ids.length) {
      var val = this.state.ids.filter((f) => !value.includes(f));
      const invoiceTempOpt = {
        url: `v1/invoice/BranchTemplate/${val[0]}/${this.state.templateObj.id}`,
      };
      invoiceTempOpt.types = [
        "TEMPLATE_LINES_SUCCESS",
        "TEMPLATE_LINES_FAILURE",
      ];
      this.setState({ loading: true });
      apiRefresh.delete(invoiceTempOpt).then((res) => {
        this.setState({ loading: false, ids: value });
      });
    } else {
      var val = value.filter((f) => !this.state.ids.includes(f));
      let data = {
        items: [
          {
            tempId: this.state.templateObj.id,
            branchId: val[0],
          },
        ],
      };
      this.setState({ loading: true });
      const invoiceTempOpt = {
        url: `v1/invoice/BranchTemplate`,
      };
      invoiceTempOpt.types = [
        "TEMPLATE_LINES_SUCCESS",
        "TEMPLATE_LINES_FAILURE",
      ];
      apiRefresh.post(invoiceTempOpt, data).then(() => {
        this.setState({ loading: false, ids: value });
      });
    }
  };

  onFinish = (values) => {
    let data = {
      items: [
        {
          id: 0,
          description: values.description || "",
          amount: values.amount || 0,
        },
      ],
    };
    this.setState({ loading: true });
    const invoiceTempOpt = {
      url: `v1/invoice/AddNewLine`,
    };
    invoiceTempOpt.types = [
      "UPD_TEMPLATE_LINES_SUCCESS",
      "UPD_TEMPLATE_LINES_FAILURE",
    ];
    apiRefresh.post(invoiceTempOpt, data).then((res) => {
      this.getLines(this.state.templateObj.id);
      this.setState({ loading: false, modal1Visible: false });
    });
  };

  render() {
    const { userBranchData } = this.props;
    return (
      <div>
        <Spin spinning={this.state.loading} size="large">
          <Fragment style={{ height: "100vh" }}>
            {/* <div
              className="ts-container-import-clients"
              style={{ marginTop: "22px" }}
            >
              {this.getFiltersSection()}
            </div> */}

            <div className="ts-container-import-clients">
              {/* <div className="add-tag-btn">
                <>
                  <img
                    src={Images.addIcon}
                    className="icons-client"
                    type="primary"
                    onClick={() => this.setState({ modal1Visible: true })}
                  />
                </>
              </div> */}
              {this.getTable()}
            </div>
            <Modal
              className="reminder-model-main width-modal-outgoing-payments"
              title={this.state.templateObj && this.state.templateObj.title}
              visible={this.state.modalVisible}
              onCancel={() => this.setState({ modalVisible: false })}
              footer={null}
              maskClosable={false}
              // style={{ paddingBottom: 20 }}
            >
              <Spin spinning={this.state.loading}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={8}>
                    <span>Branches</span>
                  </Col>
                  <Col xs={12}>
                    <Select
                      mode="multiple"
                      onChange={this.onChangeBranch}
                      value={this.state.ids}
                    >
                      {userBranchData &&
                        userBranchData.map((branch, ind) => (
                          <Option value={branch.branchId}>
                            {branch.branchName}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={24}
                    className={"school-table overflow"}
                    style={{ paddingBottom: 20 }}
                  >
                    <Table
                      dataSource={this.state.tempLines}
                      columns={this.linesColumns}
                      // onChange={this.paginate}
                      pagination={false}
                      // pagination={{
                      //   defaultCurrent: this.state.defaultCurrent,
                      //   total: this.state.totalPages,
                      //   defaultPageSize: 20,
                      //   showSizeChanger: false,
                      // }}
                    />
                  </Col>
                </Row>
              </Spin>
            </Modal>
            <Modal
              className="reminder-model-main width-modal-outgoing-payments"
              visible={this.state.modal1Visible}
              onCancel={() => this.setState({ modal1Visible: false })}
              footer={null}
              maskClosable={false}
            >
              <Form
                initialValues={this.state.templateLine}
                onFinish={this.onFinish}
              >
                <Row className="ca-gray-cont">
                  <Col xs={23}>
                    <Form.Item
                      colon={false}
                      labelAlign="left"
                      label="Description"
                      name="description"
                      // rules={[{ required: true, message: "Required!" }]}
                      style={{ marginLeft: 20 }}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      labelAlign="left"
                      label="Amount"
                      name="amount"
                      style={{ marginLeft: 20 }}
                    >
                      <Input placeholder="Enter Amount" />
                    </Form.Item>
                  </Col>
                  <Col xs={23}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="button-blue"
                        loading={this.state.loading}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Fragment>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    workInProgressInvoices:
      state.accountReducer &&
      state.accountReducer.workInProgressInvoices &&
      state.accountReducer.workInProgressInvoices.items &&
      state.accountReducer.workInProgressInvoices.items,
    workInProgressInvoicesCount:
      state.accountReducer &&
      state.accountReducer.workInProgressInvoicesCount &&
      state.accountReducer.workInProgressInvoicesCount,
    workCompletedInvoices:
      state.accountReducer &&
      state.accountReducer.workCompletedInvoices &&
      state.accountReducer.workCompletedInvoices.items &&
      state.accountReducer.workCompletedInvoices.items,
    workCompletedInvoicesCount:
      state.accountReducer &&
      state.accountReducer.workCompletedInvoicesCount &&
      state.accountReducer.workCompletedInvoicesCount,
    clientTagRes:
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    userBranchData: state.branchReducer.userBranchData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  getInvoiceTemplate: bindActionCreators(getInvoiceTemplate, dispatch),
  getWorkInProgressInvoices: bindActionCreators(
    getWorkInProgressInvoices,
    dispatch
  ),
  onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
});
InvoiceTemplates = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceTemplates);

export default withRouter(InvoiceTemplates);
