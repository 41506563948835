import React, { useEffect, useState } from "react";
import { Form, Button, message, Spin, Modal, Row, Col, Input } from "antd";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const layout = {
  wrapperCol: { span: 22, offset: 1 },
};

const EmailContentForm = ({
  index,
  data,
  emailTypeData,
  editorType,
  setLoading,
  onAddEmailContent,
  onGetEmailContent,
  emailContentRes,
  onUpdateEmailContent,
  onDelEmailContent,
  getListingEmailContent,
  modelData,
}) => {
  const userId = localStorage.getItem("userId");
  const [letterString, setLetterString] = useState(data.emailContent);
  const [loading, setLoadingFroala] = useState(false);
  const [isDelModalOpened, setDelModalOpened] = useState(false);
  const [objData, setSelectedObjData] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      emailType: data.emailType,
    });
  }, [data]);

  const handleCancelDeleteModal = () => {
    setDelModalOpened(false);
  };

  const getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={isDelModalOpened}
          onCancel={handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col xs={3} offset={17}>
              <Button
                className={"button-blue"}
                onClick={() => {
                  handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                style={{ marginLeft: "1.3vw" }}
                className={"button-blue"}
                onClick={() => {
                  let delData = {
                    id: objData && objData.id,
                  };

                  setLoading(true);
                  onDelEmailContent(delData).then(() => {
                    message.success("Successfully deleted!");

                    getListingEmailContent();
                    setDelModalOpened(false);
                  });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  const getEmailTypeStatus = (updateEmail) => {
    if (
      updateEmail.emailType === "Invitation to www.checkmyvisa.io" ||
      updateEmail.emailType === "Invoice Email" ||
      updateEmail.emailType === "Notifications" ||
      updateEmail.emailType === "Update Visa Status Notification" ||
      updateEmail.emailType === "Questionnaire Link" ||
      updateEmail.emailType === "Invoice Follow-up" ||
      updateEmail.emailType === "Client Birthday"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onFinish = (values) => {
    const updateEmail = {
      id: data && data.id,
      emailContent: letterString,
      emailType: values && values.emailType,
    };
    setLoading(true);
    let emailStatusBol = getEmailTypeStatus(updateEmail);
    if (emailStatusBol) {
      onUpdateEmailContent(updateEmail).then(() => {
        getListingEmailContent();
        message.success("Successfully Updated!");
      });
    } else {
      const addEmailData = {
        emailType: updateEmail.emailType,
        emailContent: letterString,
      };
      onAddEmailContent(addEmailData).then((res) => {
        const _updateEmail = {
          id: parseInt(res && res.payload && res.payload.id),
          emailContent: letterString,
          emailType: values && values.emailType,
        };

        onUpdateEmailContent(_updateEmail).then(() => {
          getListingEmailContent();
          message.success("Successfully Updated!");
        });
      });
    }
  };

  const handleModelChange = (model) => {
    setLetterString(model);
  };

  return (
    <div>
      <Form key={index} onFinish={onFinish} form={form} {...layout}>
        <Row style={{ marginBottom: "5vh" }}>
          <Col xs={24}>
            <Form.Item
              colon={false}
              name="emailType"
              rules={[
                { required: true, message: "Please enter name!" },
                {
                  max: 100,
                  message: "Name should be less than 100 character",
                },
              ]}
            >
              <Input disabled={!getEmailTypeStatus(data)} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item colon={false}>
              <Spin size={"large"} spinning={loading}>
                <FroalaEditorCom
                  setLoading={(value) => setLoadingFroala(value)}
                  model={letterString}
                  onModelChange={handleModelChange}
                />
              </Spin>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: "flex", marginTop: "40px" }}>
          <Col xs={2} offset={19}>
            {getEmailTypeStatus(data) ? (
              <Button
                className={"button-blue"}
                onClick={() => {
                  setDelModalOpened(true);
                  setSelectedObjData(data);
                }}
                type="primary"
              >
                Delete
              </Button>
            ) : null}
          </Col>
          <Col xs={1}>
            <Form.Item>
              <Button
                className={"button-blue"}
                onClick={() => emailTypeData(data.emailType)}
                htmlType="submit"
                type="primary"
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {getModalDelete()}
    </div>
  );
};

export default EmailContentForm;
