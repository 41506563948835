import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal } from "antd";
import EmployerEmailHistory from "./EmployerEmailHistory/EmployerEmailHistory";
// import AddEmployerDocuments from "./EmployerEmailHistory/AddEmployerDocuments";
// import UpdateEmployerDocuments from "./EmployerEmailHistory/UpdateEmployerDocuments";
import CreateEmail from "./CreateEmail/CreateEmail";
import { apiRefresh } from "../../../../services/api";

const { TabPane } = Tabs;

const EmployerEmailTabs = ({
  userDataEmp,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onAddAnyTamplate,
  addAnyTemRes,

  onDeleteCreateEmail,

  onGetSignature,
  signatureRes,

  onGetDocuments,
  documentRes,
  onGetEmployerDocument,

  onGetImapForAll,
  imapForAllRes,
  onGetPdf,
  onGetDocumentDownload,
  onAddEmailDocument,
  employerDocumentRes,
  onGetEmailContent,
  tabKey,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [handleKey, setHandleKey] = useState("1");
  const [profileData, setProfileData] = useState(null);
  const [emailContentData, setEmailContentData] = useState("");

  //   useEffect(() => {
  //     onGetEmployerData(userDataEmp && userDataEmp.id);
  //   }, [onGetEmployerData, userDataEmp]);

  useEffect(() => {
    if (tabKey == "4") {
      initialCallApis();
    }
  }, [tabKey]);

  const initialCallApis = () => {
    var data = JSON.parse(sessionStorage.getItem("profileData"));
    setProfileData(data);
    onGetAutoEmailImport(userDataEmp && userDataEmp.id).then((res) => {
      if (
        res.payload.employerImportSetting.length > 0 &&
        res.payload.employerImportSetting[0].isAutoImportEmail
      ) {
        var emailQueueOpt = {
          url: `v1/employer/ImportEmailQueue`,
        };

        emailQueueOpt.types = ["EMAIL_QUEUE_SUCCESS", "EMAIL_QUEUE_FAILURE"];
        apiRefresh.post(emailQueueOpt, {
          clientId: userDataEmp && userDataEmp.id,
        });
      }
    });
    var profileData = JSON.parse(sessionStorage.getItem("profileData"));
    onGetEmailContent &&
      onGetEmailContent()
        .then((res) => {
          let _emailTemp = res && res.payload && res.payload.emailTemplates;
          for (let ind = 0; ind < _emailTemp.length; ind++) {
            if (_emailTemp[ind].emailType === "Notifications") {
              setEmailContentData(
                '<p><p style="color: #000">Dear ' +
                  userDataEmp.name +
                  "</p>" +
                  _emailTemp[ind].emailContent
              );
            } else {
              //do nothing
            }
          }
        })
        .catch((err) => {});
  };

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
    // setAddReminders(true);
    // setUpdateTitle(id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callback = (key) => {
    setHandleKey(key);
  };

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div
          className="employerin-box"
          style={{
            marginLeft: 10,
            marginRight: 10,
            padding: 20,
            paddingTop: 10,
          }}
        >
          <h4
            className="top-text heading-forms"
            style={{
              margin: "12px",
              fontWeight: 600,
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          >{`Name: ${userDataEmp && userDataEmp.name}`}</h4>
          <div className="bg-white ">
            <Tabs
              activeKey={handleKey}
              type="card"
              size={"small"}
              className="mar-r employer-doc-tab"
              onChange={(key) => callback(key)}
            >
              <TabPane tab="HISTORY" key="1">
                <div
                  style={{
                    border: "2px solid #c9c9ca",
                    padding: "20px",
                    backgroundColor: "#f0f2f5b8",
                  }}
                >
                  <EmployerEmailHistory
                    emailContentData={emailContentData}
                    userDataEmp={userDataEmp}
                    showModal={showModal}
                    onGetEmployerHistory={onGetEmployerHistory}
                    employerHistoryRes={employerHistoryRes}
                    onGetAutoEmailImport={onGetAutoEmailImport}
                    emailImportRes={emailImportRes}
                    onAddAutoEmailImport={onAddAutoEmailImport}
                    onDeleteCreateEmail={onDeleteCreateEmail}
                    profileData={profileData}
                    onSendEmailLink={onSendEmailLink}
                    onGetLetterTemplates={onGetLetterTemplates}
                    LetterTemplatesRes={LetterTemplatesRes}
                    onAddEmployerEmail={onAddEmployerEmail}
                    onAddAnyTamplate={onAddAnyTamplate}
                    addAnyTemRes={addAnyTemRes}
                    onUploadAvatar={onUploadAvatar}
                    imageUploadSuccess={imageUploadSuccess}
                    setHandleKey={setHandleKey}
                    onGetSignature={onGetSignature}
                    signatureRes={signatureRes}
                    onGetDocuments={onGetDocuments}
                    documentRes={documentRes}
                    onGetEmployerDocument={onGetEmployerDocument}
                    onGetImapForAll={onGetImapForAll}
                    imapForAllRes={imapForAllRes}
                    onGetPdf={onGetPdf}
                    onAddEmailDocument={onAddEmailDocument}
                    employerDocumentRes={employerDocumentRes}
                    onGetDocumentDownload={onGetDocumentDownload}
                    tabKey={tabKey}
                    initialCallApis={initialCallApis}
                  />
                </div>
              </TabPane>
              <TabPane tab="CREATE" key="2">
                {handleKey === "1" ? null : (
                  <div
                    style={{
                      border: "2px solid #c9c9ca",
                      padding: "20px",
                      backgroundColor: "#f0f2f5b8",
                    }}
                  >
                    <CreateEmail
                      emailContentData={emailContentData}
                      onSendEmailLink={onSendEmailLink}
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      onAddEmployerEmail={onAddEmployerEmail}
                      userDataEmp={userDataEmp}
                      onAddAnyTamplate={onAddAnyTamplate}
                      addAnyTemRes={addAnyTemRes}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      onGetEmployerHistory={onGetEmployerHistory}
                      setHandleKey={setHandleKey}
                      onGetSignature={onGetSignature}
                      signatureRes={signatureRes}
                      onGetDocuments={onGetDocuments}
                      documentRes={documentRes}
                      onGetEmployerDocument={onGetEmployerDocument}
                      onGetPdf={onGetPdf}
                      onGetDocumentDownload={onGetDocumentDownload}
                      onDeleteCreateEmail={onDeleteCreateEmail}
                      onAddEmailDocument={onAddEmailDocument}
                      employerDocumentRes={employerDocumentRes}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      {/* {isModalVisible && (
        <div className="reminder-model">
          <Modal
            className="reminder-model-main"
            title={
              (modalType === "add-documents" && "ADD DOCUMENTS") ||
              (modalType === "update-documents" && "UPDATE DOCUMENTS")
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            // Header={true}
            footer={false}
          >
            {modalType === "add-documents" && (
              <AddEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
              />
            )}

            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
              />
            )}
          </Modal>
        </div>
      )} */}
    </Fragment>
  );
};

export default EmployerEmailTabs;
