import { api } from "../../services/api";
import * as types from "../Constants";
import { EMPTY_IMAP_EMAIL_SUCCESS } from "../Constants";

export const searchImapEmail = (data) => {
  const options = {
    url: "v1/emailimport/AllEmailImportSettings",
  };

  options.types = [
    types.SEARCH_IMAP_EMAIL_SUCCESS,
    types.SEARCH_IMAP_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const setMailDataEmpty = () => {
  const payloadData = {
    type: types.EMPTY_IMAP_EMAIL_SUCCESS,
    payload: [],
  };
  return payloadData;
};

export const getMailBox = (id) => {
  let userId = id || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: "v1/emailimport/IMAPImportSettings/" + userId,
  };

  options.types = [types.GET_MAIL_BOX_SUCCESS, types.GET_MAIL_BOX_FAILURE];

  return api.get(options);
};

export const manualImportEmail = (data) => {
  const options = {
    url: "v1/imap/ClientEmail",
  };

  options.types = [
    types.MANUAL_IMPORT_EMAIL_SUCCESS,
    types.MANUAL_IMPORT_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const manualImportPotentialEmail = (data) => {
  const options = {
    url: "v1/imap/PotentialClientEmail",
  };

  options.types = [
    types.MANUAL_IMPORT_POTENTIAL_EMAIL_SUCCESS,
    types.MANUAL_IMPORT_POTENTIAL_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const manualImportEmployerEmail = (data) => {
  const options = {
    url: "v1/imap/EmployerEmail",
  };

  options.types = [
    types.MANUAL_IMPORT_EMPLOYER_EMAIL_SUCCESS,
    types.MANUAL_IMPORT_EMPLOYER_EMAIL_FAILURE,
  ];

  return api.post(options, data);
};

export const autoImportEmailClient = (data) => {
  const options = {
    url: "v1/emailimport/ClientImportSettings",
  };

  options.types = [
    types.AUTO_IMPORT_CLIENT_EMAIL_SUCCESS,
    types.AUTO_IMPORT_CLIENT_EMAIL_FAILURE,
  ];

  // if (data.clientImportSettingsId == 0)
  return api.post(options, data);
  // else return api.put(options, data);
};

export const getAutoEmailClientImport = (clientId) => {
  const options = {
    url: `v1/emailimport/ClientImportSettings/${clientId}`,
  };

  options.types = [
    types.GET_EMAIL_CLIENT_IMPORT_SUCCESS,
    types.GET_EMAIL_CLIENT_IMPORT_FAILURE,
  ];

  return api.get(options);
};
