import React from "react";
import { Row, Col, Button, Form, Space } from "antd";
import Select from "react-select";
import "./PotentialClientStyles.css";
import { Redirect } from "react-router-dom";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import PotentialEmployerfrom from "./PotentialEmployer";
import PotentialClient from "./PotentialClient";
import WebAssessment from "./WebAssessment";
import CommissionReminder from "../SchoolManagement/CommissionReminder";
import AddNewPotentialClient from "./AddNewPotentialClient";
import PotentialClientsStatus from "./PotentialClientsStatus";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEmployerManag,
  addEmployerManag,
  addDocWithName,
  deleteEmployerManag,
  getEmployerContact,
  addEmployerContact,
  removeEmployerContact,
  updateEmployerManag,
  updateEmployerContact,
  getEmployerFile,
  addEmployerFile,
  getLetterTemplates,
  removeEmployerFile,
  updateEmployerFile,
  addAnyTamplate,
  getEmployerDocument,
  removeEmployerDocument,
  addDocumentTittle,
  getFilteredType,
  addFilteredType,
  getDocumentChecklist,
  getEmployerCheckList,
  addEmployerDocument,
  updateEmployerDocument,
  addEmployerCheckList,
  getDocumentDownload,
  sendDynamicLinkEmail,
  getEmployerHistory,
  addEmployerEmail,
  getAutoEmailImport,
  addAutoEmailImport,
  deleteCreateEmail,
  movePotentialEmployer,
  getSignature,
  getDocuments,
  getPClientEmailHistory,
  manualImportPotentialEmail,
  getPdf,
  getImapForAll,
  addEmailDocument,
  getEmailContent,
  getPermissonUser,
} from "../store/Actions";

const headOption = [
  {
    tabName: "Potential Clients",
    linkName: "/potential-client/potential-clients",
  },
  // {
  //   tabName: "Potential Clients Status",
  //   linkName: "/potential-client/potential-clients-status",
  // },
  {
    tabName: "Potential Employers",
    linkName: "/potential-client/potential-employer",
  },
];
class Inquiry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let data = this.props;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <Row>
              <Col xs={24}>
                <PotentialHeaderTabs
                  data={headOption}
                  activePath={window.location.pathname}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {/*<Redirect to={"/potential-client/potential-clients"} />*/}
                {window.location.pathname ===
                "/potential-client/potential-clients" ? (
                  <PotentialClient />
                ) : window.location.pathname ===
                  "/potential-client/potential-clients-status" ? (
                  // eslint-disable-next-line react/jsx-no-undef
                  <div style={{ height: "100vh" }}>
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      Coming Soon
                    </h3>
                  </div>
                ) : // <PotentialClientsStatus />
                window.location.pathname ===
                  "/potential-client/potential-employer" ? (
                  <PotentialEmployerfrom
                    onGetEmailContent={this.props.onGetEmailContent}
                    onGetEmployerManag={this.props.onGetEmployerManag}
                    employerManagRes={this.props.employerManagRes}
                    onAddEmployerManag={this.props.onAddEmployerManag}
                    addEmployerManagRes={this.props.addEmployerManagRes}
                    onAddDocWithName={this.props.onAddDocWithName}
                    docWithNameRes={this.props.docWithNameRes}
                    onDeleteEmployer={this.props.onDeleteEmployer}
                    ongetEmployerContact={this.props.ongetEmployerContact}
                    EmployerContactRes={this.props.EmployerContactRes}
                    onAddEmployerContact={this.props.onAddEmployerContact}
                    onRemoveEmployerContact={this.props.onRemoveEmployerContact}
                    onUpdateEmployerManag={this.props.onUpdateEmployerManag}
                    onUpdateEmployerContact={this.props.onUpdateEmployerContact}
                    onGetEmployerFile={this.props.onGetEmployerFile}
                    employerFileRes={this.props.employerFileRes}
                    onAddEmployerFile={this.props.onAddEmployerFile}
                    onGetLetterTemplates={this.props.onGetLetterTemplates}
                    LetterTemplatesRes={this.props.LetterTemplatesRes}
                    onRemoveEmployerFile={this.props.onRemoveEmployerFile}
                    onUpdateEmployerFile={this.props.onUpdateEmployerFile}
                    onAddAnyTamplate={this.props.onAddAnyTamplate}
                    addAnyTemRes={this.props.addAnyTemRes}
                    onGetEmployerDocument={this.props.onGetEmployerDocument}
                    employerDocumentRes={this.props.employerDocumentRes}
                    onRemoveEmployerDocument={
                      this.props.onRemoveEmployerDocument
                    }
                    onAddDocumentTittle={this.props.onAddDocumentTittle}
                    onGetFilteredType={this.props.onGetFilteredType}
                    documentFilterRes={this.props.documentFilterRes}
                    onAddFilteredType={this.props.onAddFilteredType}
                    onGetDocumentChecklist={this.props.onGetDocumentChecklist}
                    docChecklistRes={this.props.docChecklistRes}
                    onGetEmployerCheckList={this.props.onGetEmployerCheckList}
                    employerCheckListRes={this.props.employerCheckListRes}
                    onAddEmployerDocument={this.props.onAddEmployerDocument}
                    onGetDocumentDownload={this.props.onGetDocumentDownload}
                    onUpdateEmployerDocument={
                      this.props.onUpdateEmployerDocument
                    }
                    onAddEmployerCheckList={this.props.onAddEmployerCheckList}
                    onSendEmailLink={this.props.onSendEmailLink}
                    onGetEmployerHistory={this.props.onGetEmployerHistory}
                    employerHistoryRes={this.props.employerHistoryRes}
                    onAddEmployerEmail={this.props.onAddEmployerEmail}
                    onGetAutoEmailImport={this.props.onGetAutoEmailImport}
                    emailImportRes={this.props.emailImportRes}
                    onAddAutoEmailImport={this.props.onAddAutoEmailImport}
                    onDeleteCreateEmail={this.props.onDeleteCreateEmail}
                    onMovePotentialEmployer={this.props.onMovePotentialEmployer}
                    onGetSignature={this.props.onGetSignature}
                    onGetDocuments={this.props.onGetDocuments}
                    onAddPotentialEmail={this.props.onAddPotentialEmail}
                    potentialClientInfo={this.props.potentialClientInfo}
                    potentialClientHistory={this.props.potentialClientHistory}
                    onGetPClientHistory={this.props.onGetPClientHistory}
                    onGetPdf={this.props.onGetPdf}
                    onGetImapForAll={this.props.onGetImapForAll}
                    imapForAllRes={this.props.imapForAllRes}
                    signatureRes={this.props.signatureRes}
                    onAddEmailDocument={this.props.onAddEmailDocument}
                    documentRes={this.props.documentRes}
                  />
                ) : window.location.pathname === "/add-potential-client" ? (
                  <AddNewPotentialClient />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employerManagRes: state.employerManagReducer.employerManagRes,
    addEmployerManagRes: state.employerManagReducer.addEmployerManagRes,
    docWithNameRes: state.employerManagReducer.docWithNameRes,
    EmployerContactRes: state.employerManagReducer.EmployerContactRes,
    employerFileRes: state.employerManagReducer.employerFileRes,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    addAnyTemRes: state.employerManagReducer.addAnyTemRes,
    employerDocumentRes: state.employerManagReducer.employerDocumentRes,
    documentFilterRes: state.clientProfileReducer.documentFilterRes,
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    employerCheckListRes: state.employerManagReducer.employerCheckListRes,
    employerHistoryRes: state.employerManagReducer.employerHistoryRes,
    emailImportRes: state.employerManagReducer.emailImportRes,
    movePotentialEmpRes: state.employerManagReducer.movePotentialEmpRes,
    potentialClientInfo:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientInfo &&
      state.potentialClientReducer.potentialClientInfo,
    potentialClientHistory: state.potentialClientReducer.potentialClientHistory,
    imapForAllRes: state.imapSettingReducer.imapForAllRes,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    documentRes: state.accountDocumentReducer.documentRes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetEmployerManag: bindActionCreators(getEmployerManag, dispatch),
  onAddEmployerManag: bindActionCreators(addEmployerManag, dispatch),
  onAddDocWithName: bindActionCreators(addDocWithName, dispatch),
  onDeleteEmployer: bindActionCreators(deleteEmployerManag, dispatch),
  ongetEmployerContact: bindActionCreators(getEmployerContact, dispatch),
  onAddEmployerContact: bindActionCreators(addEmployerContact, dispatch),
  onRemoveEmployerContact: bindActionCreators(removeEmployerContact, dispatch),

  onUpdateEmployerManag: bindActionCreators(updateEmployerManag, dispatch),
  onUpdateEmployerContact: bindActionCreators(updateEmployerContact, dispatch),
  onGetEmployerFile: bindActionCreators(getEmployerFile, dispatch),
  onAddEmployerFile: bindActionCreators(addEmployerFile, dispatch),
  onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
  onRemoveEmployerFile: bindActionCreators(removeEmployerFile, dispatch),
  onUpdateEmployerFile: bindActionCreators(updateEmployerFile, dispatch),
  onAddAnyTamplate: bindActionCreators(addAnyTamplate, dispatch),
  onGetEmployerDocument: bindActionCreators(getEmployerDocument, dispatch),
  onRemoveEmployerDocument: bindActionCreators(
    removeEmployerDocument,
    dispatch
  ),
  onAddDocumentTittle: bindActionCreators(addDocumentTittle, dispatch),

  onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
  onAddFilteredType: bindActionCreators(addFilteredType, dispatch),
  onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
  onGetEmployerCheckList: bindActionCreators(getEmployerCheckList, dispatch),
  onAddEmployerDocument: bindActionCreators(addEmployerDocument, dispatch),
  onUpdateEmployerDocument: bindActionCreators(
    updateEmployerDocument,
    dispatch
  ),
  onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
  onAddEmployerCheckList: bindActionCreators(addEmployerCheckList, dispatch),
  onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
  onGetEmployerHistory: bindActionCreators(getEmployerHistory, dispatch),
  onAddEmployerEmail: bindActionCreators(addEmployerEmail, dispatch),
  onGetAutoEmailImport: bindActionCreators(getAutoEmailImport, dispatch),
  onAddAutoEmailImport: bindActionCreators(addAutoEmailImport, dispatch),
  onDeleteCreateEmail: bindActionCreators(deleteCreateEmail, dispatch),
  onMovePotentialEmployer: bindActionCreators(movePotentialEmployer, dispatch),
  onGetSignature: bindActionCreators(getSignature, dispatch),
  onGetDocuments: bindActionCreators(getDocuments, dispatch),
  onGetPClientHistory: bindActionCreators(getPClientEmailHistory, dispatch),
  onGetSignature: bindActionCreators(getSignature, dispatch),
  onAddPotentialEmail: bindActionCreators(manualImportPotentialEmail, dispatch),
  onGetPdf: bindActionCreators(getPdf, dispatch),
  onGetImapForAll: bindActionCreators(getImapForAll, dispatch),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
});

Inquiry = connect(mapStateToProps, mapDispatchToProps)(Inquiry);

export default Inquiry;
