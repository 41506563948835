import React from "react";
// import Select from "react-select";
import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { message, Button, Radio, Select, Row, Col } from "antd";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import ProgressBar from "../Components/Shared/Progressbar";

import VisaTypeActions from "../Redux/SuperUserSettingRedux/VisaTypeRedux";
import { LeftCircleFilled } from "@ant-design/icons";

const { Option } = Select;
class VisaType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      chineseName: "",
      loading: false,
      countryName: "NEW ZEALAND",
      isUpdate: false,
      id: "",
      selectedCountry: "",
      visaTypesArray: [],
    };
    this.props.onGetCountries();
    this.props.onGetVisaTypeWithHidden();
    this.props.onGetBVisaCountries();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.visaTypeWithHiddenData !== this.props.visaTypeWithHiddenData
    ) {
      this.setState({
        visaTypesArray:
          this.props &&
          this.props.visaTypeWithHiddenData &&
          this.props.visaTypeWithHiddenData.items,
      });
    } else {
    }
  }

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      name: "",
      chineseName: "",
      countryId: "",
    });
  };

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (val) => {
    let findCountry =
      this.props.brnchVisaCountriesData &&
      this.props.brnchVisaCountriesData.find((obj) => obj.countryId === val);
    this.setState({
      countryName: (findCountry && findCountry.countryName) || "",
    });
  };

  openEditModal = (item) => {
    this.setState({
      id: item.id,
      name: item.visaTypeName,
      chineseName: item.chName,
      countryName: item.countryName,
      isUpdate: true,
    });
    this.openModal();
  };

  updateVisaType = () => {
    var selectedBranchId = localStorage.getItem("selectedBranchId");
    var countryId = "";
    if (this.state.name.length < 3) {
      message.warning("Visa type Name should be more than three characters!");
    }
    this.setState({ loading: true });
    var findCountryId =
      this.props.countriesData &&
      this.props.countriesData.items.find(
        (obj) => obj.name === this.state.countryName
      );
    if (findCountryId) {
      countryId = findCountryId.id;
    }

    let visaTypeData = {
      id: this.state.id,
      branchVisaTypeName: this.state.name,
      chName: this.state.chineseName,
      isAusi: this.state.countryName === "AUSTRALIA" ? true : false,
      isDataShare: false,
      isEmployers: false,
      countryId: countryId,
    };
    this.props
      .onUpdVisaType(visaTypeData)
      .then(() => {
        this.setState({
          loading: false,
          name: "",
          chineseName: "",
          isUpdate: false,
        });
        this.closeModal();
        message.success("Visa Type updated successfully");
        this.props.onGetVisaTypeWithHidden();
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Update visa type failed!");
      });
  };

  onChangeCountry = (value) => {
    this.setState({ selectedCountry: value }, () => {
      let findCountry;
      if (this.state.selectedCountry === "") {
        findCountry =
          this.props &&
          this.props.visaTypeWithHiddenData &&
          this.props.visaTypeWithHiddenData.items &&
          this.props.visaTypeWithHiddenData.items;
      } else {
        findCountry =
          this.props &&
          this.props.visaTypeWithHiddenData &&
          this.props.visaTypeWithHiddenData.items &&
          this.props.visaTypeWithHiddenData.items.filter(
            (obj) => obj.countryId == value
          );
      }
      if (findCountry) {
        this.setState({ visaTypesArray: findCountry });
      }
    });
  };

  handleCancelVisibleModal = () => {
    this.setState({ visibleModal: false });
  };

  getModalAlert = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={this.state.visibleModal}
          onCancel={this.handleCancelVisibleModal}
          height="150"
          width="360"
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                <span
                  style={{ fontSize: 12, marginTop: "4vh", marginLeft: "2vw" }}
                >
                  {`Are you sure, you want to ${
                    this.state.isHide ? "unhide" : "hide"
                  }?`}
                </span>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "7vh" }}>
            <Col span={6} offset={13} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelVisibleModal();
                }}
                style={{ borderRadius: 5 }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  let data = {
                    id: this.state.TypeId,
                    isHide: !this.state.isHide,
                  };
                  this.props.hideUnhideVisaType(data).then(() => {
                    message.success("Changes are updated successfully!");
                    this.props.onGetVisaTypeWithHidden().then(() => {
                      this.setState({ visibleModal: false });
                    });
                  });
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getAddModal = (name, chineseName, isUpdate, loading) => {
    return (
      <div>
        <Modal
          visible={this.state.visible}
          width="500"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: 40 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="sus-modal-label">
                <span className="sus-modal-label-text">VISA TYPE</span>
              </div>
              <div onClick={this.closeModal}>
              <button type="button" aria-label="Close" class="ant-modal-close"><span class="ant-modal-close-x"><span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon"><svg fill-rule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path></svg></span></span></button>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="medical-label"
                style={{ fontSize: 11, marginLeft: 15 }}
              >
                Name:
              </p>
              <div className="profile-input-border" style={{ width: "60%" }}>
                <input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  value={name}
                  onChange={(e) => this.myChangeHandler("name", e)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p
                className="medical-label"
                style={{ fontSize: 11, marginLeft: 15 }}
              >
                Chinese Name:
              </p>
              <div className="profile-input-border" style={{ width: "60%" }}>
                <input
                  className="profile-input"
                  placeholder=""
                  type="text"
                  value={chineseName}
                  onChange={(e) => this.myChangeHandler("chineseName", e)}
                />
              </div>
            </div>
            {/* <Radio.Group
              style={{ marginTop: 20 }}
              onChange={this.onChange}
              value={this.state.countryName}
            >
              <Radio value={"AUSTRALIA"}>Australia</Radio>
              <Radio value={"CANADA"} style={{ marginLeft: 50 }}>
                Canada
              </Radio>
              <Radio value={"NEW ZEALAND"} style={{ marginLeft: 50 }}>
                New Zealand
              </Radio>
            </Radio.Group> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <p
                className="medical-label"
                style={{ fontSize: 11, marginLeft: 15 }}
              >
                Country:
              </p>
              <div
                className="profile-input-border"
                style={{ width: "60%", border: 0 }}
              >
                <Select
                  showSearch
                  style={{ width: "100%", borderRadius: 0 }}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={this.onChange}
                >
                  {this.props.brnchVisaCountriesData &&
                    this.props.brnchVisaCountriesData.map((country, ind) => (
                      <Option value={country.countryId}>
                        {country.countryName}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <div></div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={this.closeModal}
                  type="primary"
                  className="sus-add-btn"
                >
                  CLOSE
                </Button>
                <Button
                  onClick={
                    isUpdate ? () => this.updateVisaType() : this.onSubmit
                  }
                  loading={loading}
                  type="primary"
                  className="sus-add-btn"
                  style={{ marginLeft: 10 }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  onSubmit = () => {
    var selectedBranchId = localStorage.getItem("selectedBranchId");
    if (this.state.name.length < 3) {
      message.warning("Visa type name should be more than three characters!");
      return;
    }
    this.setState({ loading: true });
    var countryId = "";
    var findCountryId =
      this.props.countriesData &&
      this.props.countriesData.items.find(
        (obj) => obj.name === this.state.countryName
      );
    if (findCountryId) {
      countryId = findCountryId.id;
    }

    let visaTypeData = {
      visaTypeName: this.state.name,
      chName: this.state.chineseName,
      isAusi: this.state.countryName === "AUSTRALIA" ? true : false,
      isDataShare: false,
      isEmployers: false,
      countryId: countryId,
    };
    this.props
      .onAddVisaType(visaTypeData)
      .then(() => {
        this.setState({ loading: false });
        this.closeModal();
        this.setState({
          name: "",
          chineseName: "",
        });
        message.success("Visa Type added successfully");
        this.props.onGetVisaTypeWithHidden();
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Adding visa type failed!");
      });
  };

  render() {
    const {
      visaTypeWithHiddenData,
      countriesData,
      onSetActiveInnerTab,
    } = this.props;
    const {
      name,
      chineseName,
      isUpdate,
      loading,
      selectedCountry,
      visaTypesArray,
    } = this.state;

    console.log("show visa type data ", visaTypeWithHiddenData, countriesData);
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <div>
              <div style={{ paddingRight: 45 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="sus-bottom-btn-cont"
                    style={{ paddingTop: 0, marginLeft: 20 }}
                  >
                    <div className="sus-bottom-icon-cont">
                      <img src={Images.visaType} className="sus-bottom-icon" />
                    </div>
                    <span className="sus-bottom-text">VISA TYPES</span>
                  </div>
                  <LeftCircleFilled
                    onClick={() => onSetActiveInnerTab("")}
                    className="ac-back-icon"
                  />
                </div>
                <div
                  className="sus-form-container"
                  style={{
                    width: "100%",
                    minHeight: 130,
                    paddingLeft: 55,
                    paddingRight: 55,
                    border: 1,
                    borderStyle: "solid",
                    borderColor: "#D9D9D9",
                  }}
                >
                  <div
                    className="sus-add-cont-row"
                    style={{ marginTop: 10, marginRight: -40 }}
                  >
                    <div></div>
                    <div onClick={this.openModal}>
                      <img src={Images.plusIcon} style={{ width: 20 }} />
                    </div>
                  </div>
                  <div
                    style={{ marginTop: 10, marginLeft: -40, marginRight: -40 }}
                  >
                    <div className="sus-table">
                      <div style={{ margin: 20 }}>
                        <div
                          className="sus-table-header"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div style={{ width: "380px", maxWidth: "380px" }}>
                            <span className="sus-head-text">Name</span>
                          </div>
                          <div
                            className="sus-head-text-cont"
                            style={{ overflow: "inherit" }}
                          >
                            <span className="sus-head-text">Chinese Name</span>
                          </div>
                          <div className="sus-head-text-cont">
                            {/* <span className="sus-head-text">Country</span> */}
                            <Select
                              showSearch
                              optionFilterProp="children"
                              placeholder="Country"
                              value={selectedCountry}
                              onChange={this.onChangeCountry}
                            >
                              <Option key="">ALL</Option>
                              {this.props.brnchVisaCountriesData &&
                                this.props.brnchVisaCountriesData.map(
                                  (data) => {
                                    // eslint-disable-next-line react/jsx-no-undef
                                    return (
                                      <Option value={data.countryId}>
                                        {data.countryName}
                                      </Option>
                                    );
                                  }
                                )}
                            </Select>
                          </div>
                          <div className="sus-head-text-cont">
                            <span className="sus-head-text">Action</span>
                          </div>
                        </div>
                        {visaTypesArray &&
                          visaTypesArray.map((item, index) => {
                            // var countryName = "";
                            // var findCountry = countriesData && countriesData.items.find(obj => obj.id === item.countryId);
                            // if(findCountry) {
                            //   countryName = findCountry.name
                            // }
                            return (
                              <div
                                key={index}
                                className="sus-table-content"
                                style={{
                                  borderBottomWidth: 1,
                                  borderBottomStyle: "solid",
                                  borderColor: "#D3D3D3",
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                              >
                                <div
                                  style={{ width: "380px", maxWidth: "380px" }}
                                >
                                  <p
                                    style={{ wordBreak: "break-all" }}
                                    className="sus-content-text"
                                  >
                                    {item.visaTypeName}
                                  </p>
                                </div>
                                <div
                                  style={{ width: "120px", maxWidth: "120px" }}
                                >
                                  <p
                                    className="sus-content-text"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {item.chName}
                                  </p>
                                </div>
                                <div className="sus-head-text-cont">
                                  <span className="sus-content-text">
                                    {item.countryName}
                                  </span>
                                </div>
                                <div
                                  className="sus-head-text-cont"
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {!item.isReadOnly ? (
                                    <img
                                      onClick={() => this.openEditModal(item)}
                                      src={Images.editBorderBlue}
                                      style={{
                                        width: 15,
                                        marginRight: "1vw",
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    onClick={() => {
                                      this.setState({
                                        visibleModal: true,
                                        TypeId: item.id,
                                        isHide: item.isHide,
                                      });
                                    }}
                                  >
                                    <span style={{ color: "#0E65FC" }}>
                                      {item.isHide ? "Unhide" : "Hide"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.getAddModal(name, chineseName, isUpdate, loading)}
        {this.getModalAlert()}
      </div>
    );
  }
}

export default VisaType;
