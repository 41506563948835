import React, { useState, Fragment, useEffect, useRef } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import { Spin, message } from "antd";
import moment from "moment";
import $ from "jquery";

const FroalaEditorCom = ({
  onUploadAvatar,
  model,
  onModelChange,
  setLoading,
}) => {
  var editorRef = useRef(null);
  const [loading, srtLoading] = useState(false);
  var apiCallsInProgress = 0;

  // Function to upload image to server and insert into editor after response
  const uploadImageToServer = (file) => {
    // Create formData object to send file
    let result = file;
    let formData = new FormData();
    formData.append("File", result);
    setLoading && setLoading(true);
    if (!loading) srtLoading(true);
    onUploadAvatar(formData)
      .then((res) => {
        // .then((res) => {
        // editor.image.insert(res.payload, null, null, editor.image.get());
        $(config.current).froalaEditor("image.insert", res.payload);
        setLoading && setLoading(false);
        //  editor.popups.hideAll();
        // Stop default upload chain.
        // return false;
        // })
      })
      .catch(() => {
        setLoading && setLoading(false);
        message.error(
          "Image couldn't upload. Please try another Image or try again later!"
        );
      })
      .finally(() => {
        apiCallsInProgress--;
        if (apiCallsInProgress == 0) {
          srtLoading(false);
        }
      });
    // const formData = new FormData();
    // formData.append("file", file);

    // // Make your API call to upload the image to the server
    // fetch("your-upload-api-url", {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // Insert the image into the editor
    //     $(config.current).froalaEditor("image.insert", data.imageUrl);
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading image: ", error);
    //   });
  };

  // Event handler for when a file is selected
  // const handleFileBeforeUpload = (e, editor, files) => {
  //   // Upload each file to the server
  //
  //   files.forEach((file) => {
  //     uploadImageToServer(file);
  //   });
  // };
  // let config = {
  //   key:
  //     "VCC6kE4B3C3J4B10C9A6cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5A3F3E3A3F2C6A4==",
  //   toolbarSticky: false,
  //   zIndex: 999999,
  //   // fontFamilySelection: true,
  //   fontFamily: {
  //     "Arial,Helvetica,sans-serif": "Arial",
  //     "Georgia,serif": "Georgia",
  //     "Impact,Charcoal,sans-serif": "Impact",
  //     "Tahoma,Geneva,sans-serif": "Tahoma",
  //     "Times New Roman,Times,serif,-webkit-standard": "Times New Roman",
  //     "Verdana,Geneva,sans-serif": "Verdana",
  //     "Poppins, sans-serif": "Poppins",
  //   },
  //   // useClasses: false,
  //   htmlRemoveTags: ["script"],
  //   imageInsertButtons: ["imageBack", "|", "imageUpload"],
  //   toolbarButtons: {
  //     moreText: {
  //       buttons: [
  //         "bold",
  //         "italic",
  //         "subscript",
  //         "superscript",
  //         "fontFamily",
  //         "fontSize",
  //         "textColor",
  //         "backgroundColor",
  //         "undo",
  //         "textColor",
  //         "redo",
  //         "fullscreen",
  //         "print",
  //         "spellChecker",
  //         "selectAll",
  //         "html",
  //         "help",
  //       ],
  //     },
  //     moreParagraph: {
  //       buttons: [
  //         "alignLeft",
  //         "alignCenter",
  //         "formatOLSimple",
  //         "alignRight",
  //         "alignJustify",
  //         "formatOL",
  //         "formatUL",
  //         "paragraphFormat",
  //         "paragraphStyle",
  //         "lineHeight",
  //         "outdent",
  //         "indent",
  //         "quote",
  //       ],
  //     },
  //     moreRich: {
  //       buttons: [
  //         "insertLink",
  //         "insertImage",
  //         "insertTable",
  //         "emoticons",
  //         "fontAwesome",
  //         "specialCharacters",
  //         "embedly",
  //         "insertHR",
  //       ],
  //     },
  //     moreMisc: {
  //       buttons: [
  //         "undo",
  //         "redo",
  //         "fullscreen",
  //         "spellChecker",
  //         "selectAll",
  //         "html",
  //         "help",
  //       ],
  //     },
  //   },
  //   imageEditButtons: [
  //     "imageDisplay",
  //     "imageAlign",
  //     "imageInfo",
  //     "imageRemove",
  //   ],
  //   imageMultipleStyles: false,
  //   events: {
  //     keydown: function(e) {
  //       if (e.keyCode === 13) {
  //         e.preventDefault();
  //         e.stopPropagation();
  //         e.stopImmediatePropagation();
  //       }
  //     },
  //     "image.beforeUpload": async function(files) {
  //       //   // setTimeout(() => {
  //
  //       let editor = this;
  //       if (files.length) {
  //         // Create a File Reader.
  //         apiCallsInProgress++;
  //         let file = null;
  //         if (files) {
  //           if (files[0] && files[0] instanceof Blob) {
  //             var fileType = files[0].type.split("/");
  //             file = new File(
  //               [files[0]],
  //               `FileNotes-${moment(new Date()).format(
  //                 "yyyy-MM-ddThh:mm:ssZ"
  //               )}.${fileType[1]}`,
  //               { type: files[0].type }
  //             );
  //           } else {
  //             file = files[0];
  //           }
  //         } else {
  //           message.error("File not found!");
  //           return;
  //         }
  //         let reader = new FileReader();
  //         let result = file;
  //         let formData = new FormData();
  //         formData.append("File", result);
  //         setLoading && setLoading(true);
  //         try {
  //           if (!loading) srtLoading(true);
  //           var res = await onUploadAvatar(formData);
  //           // .then((res) => {
  //           editor.image.insert(res.payload, null, null, editor.image.get());
  //           setLoading && setLoading(false);
  //           // editor.popups.hideAll();
  //           // Stop default upload chain.
  //           return false;
  //           // })
  //         } catch {
  //           setLoading && setLoading(false);
  //           message.error(
  //             "Image couldn't upload. Please try another Image or try again later!"
  //           );
  //         } finally {
  //           apiCallsInProgress--;
  //           if (apiCallsInProgress == 0) {
  //             srtLoading(false);
  //           }
  //         }
  //         // Read image as base64.

  //         // reader.readAsDataURL(files[0]);
  //       }
  //       // editor.popups.hideAll();
  //       // // Stop default upload chain.
  //       return false;
  //       //   // }, 1000);
  //     },
  //     // "file.beforeUpload": handleFileBeforeUpload,
  //     "image.inserted": function($img, response) {
  //
  //       console.log("Inserted trigered");
  //       // Do something here.
  //       // this is the editor instance.
  //       // console.log(this);
  //       // return false
  //     },
  //   },
  // };

  // useEffect(() => {
  //
  //   if (editorRef.current) {
  //     FroalaEditor(editorRef.current, {
  //       // Configure Froala Editor options here
  //       imageUploadURL: "/upload-image", // Example URL where image will be uploaded
  //       // Other Froala Editor options...
  //     });

  //     // Add event listener for paste events
  //     editorRef.current.addEventListener("paste", handlePaste);

  //     return () => {
  //       // Cleanup function to remove event listener
  //       editorRef.current.removeEventListener("paste", handlePaste);
  //     };
  //   }
  // }, []);
  let config = {
    key:
      "BWC6D-16D3E3E3G2D1A8B4wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B4C6E2G3F4F1G1==",
    toolbarSticky: false,
    zIndex: 999999,
    // fontFamilySelection: true,
    fontFamily: {
      "Arial,Helvetica,sans-serif": "Arial",
      "Georgia,serif": "Georgia",
      "Impact,Charcoal,sans-serif": "Impact",
      "Tahoma,Geneva,sans-serif": "Tahoma",
      "Times New Roman,Times,serif,-webkit-standard": "Times New Roman",
      "Verdana,Geneva,sans-serif": "Verdana",
      "Poppins, sans-serif": "Poppins",
    },
    // useClasses: false,
    htmlRemoveTags: ["script"],
    imageInsertButtons: ["imageBack", "|", "imageUpload"],
    toolbarButtons: {
      moreText: {
        buttons: [
          "bold",
          "italic",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "undo",
          "textColor",
          "redo",
          "fullscreen",
          "print",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
      },
      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertTable",
          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertHR",
          "imageLink",
        ],
      },
      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
      },
    },
    imageEditButtons: [
      "imageDisplay",
      "imageAlign",
      "imageInfo",
      "imageLink",
      "imageRemove",
    ],
    imageMultipleStyles: false,
    imagePaste: false,
    events: {
      keydown: function(e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      },
      "image.beforeUpload": function(files) {
        // setTimeout(() => {
        let editor = this;
        if (files.length) {
          // Create a File Reader.

          let file = null;
          if (files) {
            if (files[0] && files[0] instanceof Blob) {
              var fileType = files[0].type.split("/");
              file = new File(
                [files[0]],
                `FileNotes-${moment(new Date()).format(
                  "yyyy-MM-ddThh:mm:ssZ"
                )}.${fileType[1]}`,
                { type: files[0].type }
              );
            } else {
              file = files[0];
            }
          } else {
            message.error("File not found!");
            return;
          }
          let reader = new FileReader();
          let result = file;
          let formData = new FormData();
          formData.append("File", result);
          setLoading && setLoading(true);
          onUploadAvatar(formData)
            .then((res) => {
              editor.image.insert(res.payload, null, null, editor.image.get());
              setLoading && setLoading(false);
            })
            .catch(() => {
              setLoading && setLoading(false);
            });
          // Read image as base64.

          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
        // }, 1000);
      },
    },
  };

  // Function to handle paste events
  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === "file" && item.type.indexOf("image/") !== -1) {
        // Found an image in clipboard, prevent it from being pasted
        event.preventDefault();
        alert(
          "Pasting images directly into the editor is not allowed. Please use the image upload button."
        );
        return false;
      }
    }
  };

  return (
    <div className="froala-font-arial-use">
      <Spin spinning={loading}>
        <FroalaEditor
          ref={(el) => {
            editorRef = el;
          }}
          // onFileBeforeUpload={handleFileSelect}
          model={model}
          config={config}
          onModelChange={onModelChange}
          // onImageUpload={handleFileBeforeUpload}
        />
      </Spin>
    </div>
  );
};

export default FroalaEditorCom;
