import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { truncate } from "fs";

const EmailImportSettings = ({
  onGetEmailImport,
  docViewRes,
  onUpdEmailImport,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [importCc, setImportCc] = useState(false);
  const [importBCc, setImportBCc] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    getEmailImportSettings();
  }, [onGetEmailImport]);

  const getEmailImportSettings = () => {
    setLoading(true);
    onGetEmailImport()
      .then((res) => {
        setLoading(false);
        setImportCc(res.payload && res.payload.isCC);
        setImportBCc(res.payload && res.payload.isBCC);
        // setFolderCheck(res.payload.isDocumentView);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onTargetCheckedCC = (e) => {
    const data = {
      isCC: !e.target.checked,
      isBCC: importBCc,
    };
    setLoading(true);
    onUpdEmailImport(data)
      .then(() => {
        message.success("Successfully Updated!");
        setLoading(false);
        getEmailImportSettings();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onTargetCheckedBCC = (e) => {
    const data = {
      isCC: importCc,
      isBCC: !e.target.checked,
    };
    setLoading(truncate);
    onUpdEmailImport(data)
      .then(() => {
        message.success("Successfully Updated!");
        setLoading(false);
        getEmailImportSettings();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.apiKeyIcon} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Email Import Settings</span>
                <Tooltip placement="topLeft" title={``}>
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>

              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    onChange={onDocumentShow}
                    form={form}
                    className="d-flex"
                  >
                    {/* ! is use for show checkbox checked if value is false, If checkbox checked email will be import */}
                    <div className="reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Checkbox
                          checked={!importCc}
                          onChange={onTargetCheckedCC}
                        >
                          CC
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div
                      className="reminder-set-form margin-top-34"
                      style={{ marginLeft: 20 }}
                    >
                      <Form.Item className="d-block">
                        <Checkbox
                          checked={!importBCc}
                          onChange={onTargetCheckedBCC}
                        >
                          BCC
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default EmailImportSettings;
