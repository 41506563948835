import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CoverGeniusToken from "../AccountSetting/CoverGeniusToken";

import { setActiveInnerTab } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dropboxDocRes: state.emailIntegrationReducer.outlookMailRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverGeniusToken);
