import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const docChecklistReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_CHECKLIST_SUCCESS:
      if(action) {
         
      }
      return { ...state, docChecklistRes: action.payload };
    case types.GET_DOCUMENT_CHECKLIST_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "List is empty!");
        return { ...state, docChecklistRes: [] };
      } else {

      }
      console.log("on error console", action.payload);


    case types.ADD_DOCUMENT_CHECKLIST_SUCCESS:
      return { ...state, addDocChecklistRes: action.payload };
    case types.ADD_DOCUMENT_CHECKLIST_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_DOCUMENT_CHECKLIST_SUCCESS:
      return { ...state, updateDocChecklistRes: action.payload };
    case types.UPDATE_DOCUMENT_CHECKLIST_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_DOCUMENT_EMPLOYER_SUCCESS:
      return { ...state, remDocChecklistRes: action.payload };
    case types.DELETE_DOCUMENT_EMPLOYER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_CHECKLIST_ITEMS_SUCCESS:
      return { ...state, docChecklistItemRes: action.payload };
    case types.GET_CHECKLIST_ITEMS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
