import React from "react";
import Modal from "react-awesome-modal";
import { message, Spin, Button, Upload, Select } from "antd";
// const FileDownload = require('js-file-download');
import { saveAs } from "file-saver";
import queryString from "query-string";

import "./SuperUserSettingStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import { Link, useLocation } from "react-router-dom";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";

import ProgressBar from "../Components/Shared/Progressbar";
import ManagerCard from "../Components/SuperUserSetting/ManagerCard";
import OwnerCard from "../Components/SuperUserSetting/OwnerCard";

import PermissionCard from "../Components/SuperUserSetting/PermissionCard";
import {
  AddAgent,
  AddBranch,
  TeamMember,
  TeamMemberSettings,
} from "../Containers";

import { PlusOutlined } from "@ant-design/icons";
import { apiRefresh } from "../services/api";
import PurchaseSubscription from "../Common/PurchaseMessage";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { Option } = Select;

const headOption = [
  {
    tabName: "Organization Settings",
    linkName: "/super-user-setting?activeTab=company-information",
  },
  // { tabName: "ADD TEAM MEMBER", linkName: "/team-member" },
  { tabName: "Branch Settings", linkName: "/branch-settings" },
];

const headManagerOption = [
  {
    tabName: "BRANCH SETTINGS",
    linkName: "/super-user-setting?activeTab=company-information",
  },
];

var showUserPermissions = [];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  console.log("show before upload ===== ", file);
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

// const initialQueryValue = queryString.parse(this.props.location.search);

var branch = [];

var branchOptions = [];
var branchUsers = [];

var selectedBranchId = localStorage.getItem("selectedBranchId");

class SuperUserSetting extends React.Component {
  constructor(props) {
    const initialQueryValue = queryString.parse(props.location.search);

    super(props);
    this.state = {
      activeInfoTab:
        initialQueryValue.accountTabs == "personal-info"
          ? "personalInfo"
          : initialQueryValue.accountTabs == "company-info"
          ? "companySetting"
          : "procPersonGroup",
      selectedOption: null,
      activeTopTab:
        // initialQueryValue.activeTab == "account-settings"
        //   ? "accountSettings"
        initialQueryValue.activeTab == "owner-manager-settings"
          ? "managerSettings"
          : initialQueryValue.activeTab == "company-information"
          ? "companyInformation"
          : initialQueryValue.activeTab == "addTeamMember"
          ? "addTeamMember"
          : initialQueryValue.activeTab == "addAgent"
          ? "addAgent"
          : "companyInformation",
      checked: false,
      show: false,
      visible: false,
      loading: false,
      passwordLoading: false,
      userDropdown: true,
      exportLoading: false,
      queryString: this.props.location.search,

      groupName: "",
      groupEmail: "",
      isUpdateGroup: false,
      addGroupLoading: false,
      deleteGroupLoadng: false,
      groupId: null,

      // State variables for Personal information tab
      imageUrl: "",
      name: "",
      email: "",
      telephoneNumber: "",
      mobileNumber: "",
      companyDesignation: "",
      country: "",
      city: "",

      currentPassword: "",
      newPassword: "",
      confirmPassword: "",

      // State variables for company information
      companyName: "",
      companyEmail: "",
      companyCountry: "",
      companyCity: "",
      companyAddress: "",
      companyPhone: "",
      companyAccountTitle: "",
      companyAccountNumber: "",
      companyGSTNumber: "",
      companyGSTPercent: "",
      defaultCurrency: "",
      imageUpdateId: "",
      selectedUsers: "",

      userUpdateSuccess: false,
      userContactAUSuccess: false,
      userAddressAUSuccess: false,
      branchId: "",

      selectedBranch: "",
      branchName: "",
      branchEmail: "",
      branchCountry: "",
      branchCity: "",
      branchAddress: "",
      branchPhone: "",
      branchAccountTitle: "",
      branchAccountNumber: "",
      branchGSTNumber: "",
      branchGSTPercent: "",
      branchCurrency: "",
      teamMemberOpt: [],
      fileList: [],
      logoUrl: "",
    };

    this.props
      .onGetUserDetail()
      .then(() => {})
      .catch((error) => {
        // message.error(this.props.userDetailError);
      });
    this.props.onGetGroups();
    this.props.onGetTeamMember().then((res) => {});
    this.props.onGetCompanyDetail();
    this.props.getAllCurrencies();
    // this.props.onGetManager();
    this.props.onGetOwner();
    // this.props.onGetBranch();
    this.props.onGetStorage();
    this.props.onGetUserBranch();
  }

  componentDidUpdate(PrevProps) {
    if (PrevProps.updDetailSuccess !== this.props.updDetailSuccess) {
      // message.success(this.props.updDetailSuccess)
    }

    if (PrevProps.userDetail !== this.props.userDetail) {
      this.setState({
        name: this.props.userDetail.fullName,
        email: this.props.userDetail.alternateEmail,
        companyDesignation: this.props.userDetail.title,
        imageUrl: this.props.userDetail.image,
      });

      if (this.props.userDetail.userContact) {
        this.setState({
          telephoneNumber: this.props.userDetail.userContact.telephoneNumber,
          mobileNumber: this.props.userDetail.userContact.mobileNumber,
        });
      }

      if (this.props.userDetail.userAddress) {
        this.setState({
          country: this.props.userDetail.userAddress.country,
          city: this.props.userDetail.userAddress.city,
        });
      }
    }
    if (
      this.state.userUpdateSuccess &&
      this.state.userContactAUSuccess &&
      this.state.userAddressAUSuccess
    ) {
      this.props.onGetUserDetail();
      this.props.onGetOwner();
      this.props.onGetManager();
      this.props.onGetTeamMember().then((res) => {});
      this.setState({
        userUpdateSuccess: false,
        userContactAUSuccess: false,
        userAddressAUSuccess: false,
        loading: false,
      });
      message.success("User updated successfully!");
    }

    if (PrevProps.imageUploadSuccess !== this.props.imageUploadSuccess) {
      this.setState({ imageUrl: this.props.imageUploadSuccess });
    }
    if (PrevProps.teamMembers !== this.props.teamMembers) {
      // branch = [];
      // if(this.props.teamMembers.users.length > 0) {
      //   for (let i = 0; i < this.props.teamMembers.users.length; i++) {
      //     branch.push(
      //       <Option key={this.props.teamMembers.users[i].id}>{this.props.teamMembers.users[i].fullName}</Option>
      //     );
      //   }
      // }
    }

    if (PrevProps.userBranchData !== this.props.userBranchData) {
      branchOptions = [];
      if (this.props.userBranchData.length > 0) {
        for (let i = 0; i < this.props.userBranchData.length; i++) {
          if (this.props.userBranchData[i].isManager) {
            branchOptions.push(
              <Option key={this.props.userBranchData[i].branchId}>
                {this.props.userBranchData[i].branchName}
              </Option>
            );
          }
        }
      }
    }

    if (PrevProps.updCompanySuccess !== this.props.updCompanySuccess) {
      this.props.onGetCompanyDetail();
    }
  }

  componentWillReceiveProps(Props) {
    console.log("on receive props", Props);

    if (Props.userDetail) {
    }

    if (Props.companyData) {
      localStorage.setItem("companyId", Props.companyData.id);
      this.setState({
        logoUrl: Props.companyData.image,
        companyName: Props.companyData.name,
        companyEmail: Props.companyData.email,
        companyCountry: Props.companyData.country,
        companyAddress: Props.companyData.address,
        companyPhone: Props.companyData.phone,
        companyAccountTitle: Props.companyData.bankAccountTitle,
        companyAccountNumber: Props.companyData.bankAccountNumber,
        companyGSTNumber: Props.companyData.gstNumber,
        companyGSTPercent: Props.companyData.gstPercent,
        defaultCurrency: Props.companyData.currency
          ? parseInt(Props.companyData.currency)
          : null,
      });
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  currencyHandler = (e) => {
    this.setState({ defaultCurrency: e });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeTab = (value) => {
    this.setState({ activeInfoTab: value });
  };

  onChangeTopTab = (value, queryString) => {
    this.setState({ activeTopTab: value, queryString: queryString });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleCheck = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = () => {
    var userId = localStorage.getItem("userId");
    this.setState({ loading: true });
    let data = {
      Id: userId,
      fullName: this.state.name,
      alternateEmail: this.state.email,
      companyDesignation: this.state.companyDesignation,
      RoleId: 0,
      Title: this.state.companyDesignation,
      Image: this.state.imageUrl,
      ForgotPasswordBit: false,
      LastSignedIn: new Date(),
      Signature: "",
      TimeZone: "",
      comission: 0,
      agreementUrl: "",
      description: "",
      SignatureImage: "",
    };

    let userContact = {
      fax1: "",
      fax2: "",
      mobileNumber: this.state.mobileNumber,
      mobileNumber2: "",
      telephoneNumber: this.state.telephoneNumber,
      telephoneNumber2: "",
    };

    let userAddress = {
      country: this.state.country,
      city: this.state.city,
      address3: "",
      address2: "",
      address1: "",
    };

    this.props.onUpdUserDetail(data).then(() => {
      this.setState({ userUpdateSuccess: true });
      localStorage.setItem("userEmail", data.alternateEmail);
      if (this.props.updDetailSuccess == "This email is already taken") {
        message.error("This email is already taken!");
        this.setState({ loading: false });
      } else {
        if (this.props.userDetail.userAddress) {
          this.props.onUpdUserAddress(userAddress).then(() => {
            this.setState({ userAddressAUSuccess: true });
          });
        } else {
          this.props.onAddUserAddress(userAddress).then(() => {
            this.setState({ userAddressAUSuccess: true });
          });
        }
        if (this.props.userDetail.userContact) {
          this.props.onUpdUserContact(userContact).then(() => {
            this.setState({ userContactAUSuccess: true });
          });
        } else {
          this.props.onAddUserContact(userContact).then(() => {
            this.setState({ userContactAUSuccess: true });
          });
        }
      }
    });
  };

  updatePassword = () => {
    var userId = localStorage.getItem("userId");
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!this.state.currentPassword) {
      message.warning("Enter current password!");
    } else if (!strongRegex.test(this.state.newPassword)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      this.setState({ passwordLoading: true });
      let data = {
        oldPassword: this.state.currentPassword,
        password: this.state.newPassword,
      };

      this.props.onChangePassword(data).then(() => {
        this.setState({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          passwordLoading: false,
        });
        if (
          this.props.changePasswordSuccess.responseMessage ===
          "Password changed"
        ) {
          message.success("Password changed successfully");
        } else {
          message.error("Current password is invalid");
        }
      });
    }
  };

  updateUsersPassword = (passwordData) => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
    );
    if (!strongRegex.test(passwordData.password)) {
      message.warning(
        "Password must be atleast one capital letter, one small letter, one special character and one numeric character!"
      );
    } else if (passwordData.password !== passwordData.confirmPassword) {
      message.warning("New password and confirm password must be same.");
    } else {
      this.setState({ passwordLoading: true });
      let data = {
        userId: passwordData.userId,
        email: passwordData.email,
        password: passwordData.password,
      };
      this.props.onChangeUsersPassword(data).then(() => {
        message.success("User's password updated successfully");
      });
    }
  };

  addUserPermissons = (
    loginPermissions,
    systemPermissions,
    userId,
    permissionId,
    startTime,
    endTime
  ) => {
    const daysArray = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const systemPermissionsArray = [
      "Client Database",
      "Client Database (Update)",
      "Potential Clients (Update)",
      "Add A Client",
      "Potential Clients",
      "Accounts",
      "Visa Forms",
      "Reports",
      "Mail",
      "Case Management",
      "Agent Portal",
      "Agent Portal Accounts",
      "School Management",
      "School Management Account",
      "Employer Management",
      "Employer Management Account",
      "Custom Questionnaire",
      "Deals",
      "Meetings",
      "Time Tracking",
      "Company Level Settings",
      // "Potential Client Account"
    ];

    var userloginPermissions = [];
    var userSystemPermissions = [];

    for (var i = 0; i < daysArray.length; i++) {
      var findIndex = loginPermissions.find((obj) => obj === daysArray[i]);
      if (findIndex) {
        userloginPermissions.push({ day: findIndex, status: 1 });
      } else {
        userloginPermissions.push({ day: daysArray[i], status: 0 });
      }
    }

    for (var i = 0; i < systemPermissionsArray.length; i++) {
      var findIndex = systemPermissions.find(
        (obj) => obj === systemPermissionsArray[i]
      );
      if (findIndex) {
        userSystemPermissions.push({
          role: systemPermissionsArray[i],
          status: 1,
        });
      } else {
        if (systemPermissionsArray[i] === "Client Database (Update)") {
          userSystemPermissions.push({
            role: systemPermissionsArray[i],
            status: 1,
          });
        } else if (systemPermissionsArray[i] === "Potential Clients (Update)") {
          userSystemPermissions.push({
            role: systemPermissionsArray[i],
            status: 1,
          });
        } else {
          userSystemPermissions.push({
            role: systemPermissionsArray[i],
            status: 0,
          });
        }
      }
    }

    // const selectedBranchId = localStorage.getItem("selectedBranchId");

    let newdata = {
      id: permissionId,
      userId: userId,
      branchId: selectedBranchId,
      userSystemPermissions: userSystemPermissions,
      userloginPermissions: {
        timeStart: startTime,
        timeEnd: endTime,
        timeZone: "(UTC+12:00)",
        days: userloginPermissions,
      },
    };

    console.log("show permission data", newdata);

    this.props
      .onUpdateUserPermission(newdata)
      .then(() => {
        message.success("User permissions updated successfully");
      })
      .catch(() => {
        message.error("Give permission access failed!");
      });
  };

  updateUserData = (
    userData,
    userAddress,
    userContact,
    postUserAddress,
    postUserContact
  ) => {
    this.setState({ imageUploadSuccess: null });
    const ownerCountOpt = {
      url: `v1/users/OwnerCount`,
    };
    ownerCountOpt.types = ["GET_OWNER_COUNT_SUCCES", "GET_OWNER_COUNT_FAILURE"];

    apiRefresh.get(ownerCountOpt).then((res) => {
      if (res > 1 || userData.isOwner) {
        this.props.onUpdateManager(userData).then((res) => {
          if (res && res.payload === "This email is already taken") {
            //donothing
          } else {
            message.success("Updated!");
          }

          this.setState({ userUpdateSuccess: true });
        });
        if (postUserAddress) {
          this.props.onAddUserAddress(userAddress).then(() => {
            this.setState({ userAddressAUSuccess: true });
          });
        } else {
          this.props.onUpdUserAddress(userAddress).then(() => {
            this.setState({ userAddressAUSuccess: true });
          });
        }
        if (postUserContact) {
          this.props.onAddUserContact(userContact).then(() => {
            this.setState({ userContactAUSuccess: true });
          });
        } else {
          this.props.onUpdUserContact(userContact).then(() => {
            this.setState({ userContactAUSuccess: true });
          });
        }
      } else {
        message.error("Alleast one owner is mendatory!");
      }
    });
  };

  updateCompanyUserData = (userData) => {
    this.setState({ imageUploadSuccess: null });
    this.props.onUpdateManager(userData).then((res) => {
      if (res && res.payload === "This email is already taken") {
        //donothing
      } else {
        message.success("Updated!");
      }

      this.props.onGetTeamMember();
    });
  };

  addGroup = () => {
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.groupEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else {
      this.setState({ addGroupLoading: true });
      var companyId = localStorage.getItem("companyId");
      let data = {
        name: this.state.groupName,
        email: this.state.groupEmail,
      };

      this.props
        .onAddGroup(data)
        .then(() => {
          this.closeModal();
          this.setState({
            groupName: "",
            groupEmail: "",
            addGroupLoading: false,
          });
          message.success("Processing person added successfully");
          this.props.onGetGroups();
        })
        .catch(() => {
          message.error("Add processing person failed!");
        });
    }
  };

  openUpdatePopup = (group) => {
    this.setState({
      groupId: group.id,
      groupName: group.name,
      groupEmail: group.email,
      isUpdateGroup: true,
    });
    this.openModal();
  };

  updateGroup = (group) => {
    var companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(this.state.groupEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
    } else {
      this.setState({ addGroupLoading: true });
      let data = {
        id: this.state.groupId,
        name: this.state.groupName,
        email: this.state.groupEmail,
      };

      this.props
        .onUpdateGroup(data)
        .then(() => {
          this.closeModal();
          this.setState({
            groupName: "",
            groupEmail: "",
            isUpdateGroup: false,
            addGroupLoading: false,
          });
          message.success("Processing person updated successfully!");
          this.props.onGetGroups();
        })
        .catch(() => {
          this.setState({ addGroupLoading: false });
          message.error("Update processing person failed!");
        });
    }
  };

  deleteGroup = (group) => {
    this.setState({ deleteGroupLoadng: true });
    let data = {
      id: group.id,
      delete: true,
    };

    this.props
      .onDeleteGroup(data)
      .then(() => {
        this.setState({ deleteGroupLoadng: false });
        message.success("Processing person deleted successfully");
        this.props.onGetGroups();
      })
      .catch(() => {
        message.error("Processing person failed on delete!");
      });
  };

  uploadImage = (info, id) => {
    if (id) {
      this.setState({ imageUpdateId: id });
    }
    let formData = new FormData();
    formData.append("File", info);

    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({ imageUrl: this.props.imageUploadSuccess });
      }
    });
  };

  uploadCompanyLogo = (info) => {
    // if (id) {
    //   this.setState({ imageUpdateId: id });
    // }
    let formData = new FormData();
    formData.append("File", info);

    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({ logoUrl: this.props.imageUploadSuccess });
      }
    });
  };

  disableTeamMember = (disableUserData) => {
    this.props.onDisableUser(disableUserData).then(() => {
      if (disableUserData.isLock) {
        message.success("User disabled successfully!");
      } else {
        message.success("User enabled successfully!");
      }
      this.props.onGetTeamMember();
    });
  };

  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  assignBranch = (data) => {
    this.props.onAssignBranch(data);
  };

  unassignBranch = (data) => {
    this.props.onUnassignBranch(data);
  };

  handleChangeUser = (selectedUsers) => {
    setTimeout(() => {
      showUserPermissions = [];
    }, 1000);
    this.setState({ selectedUsers });
    var findUser = this.props.teamMembers
      ? this.props.teamMembers.users.find((obj) => obj.id === selectedUsers)
      : null;
    if (findUser) showUserPermissions.push(findUser);
  };

  handleChangeBranch = (selectedBranch) => {
    if (this.props.branchData) {
      this.setState({ selectedBranch });
      var findBranch = this.props.branchData.items.find(
        (obj) => obj.id === selectedBranch
      );

      this.setState({
        branchName: findBranch.name,
        branchEmail: findBranch.email,
        branchCountry: findBranch.country,
        branchCity: findBranch.city,
        branchAddress: findBranch.address,
        branchPhone: findBranch.phone1,
        branchAccountTitle: findBranch.bankAccountTitle,
        branchAccountNumber: findBranch.bankAccountNumber,
        branchCurrency: findBranch.currency,
        branchGSTNumber: findBranch.gstNumber,
        branchGSTPercent: findBranch.gstPercent,
      });

      branchUsers = findBranch.branchUser;

      branch = [];
      if (branchUsers.length > 0) {
        for (let i = 0; i < branchUsers.length; i++) {
          branch.push(
            <Option key={branchUsers[i].user.id}>
              {branchUsers[i].user.fullName}
            </Option>
          );
        }
        this.setState({ teamMemberOpt: branch });
      }
    } else {
      // this.props.onGetBranch();
      message.warn(
        "Selected branch data not found, please select branch again!"
      );
    }
  };

  exportData = () => {
    this.setState({ exportLoading: true });
    this.props
      .onExportData()
      .then((response) => {
        this.setState({ exportLoading: false });
        var blob = new Blob([response.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "Ezymigrate.zip");
      })
      .catch((e) => message.error("Unable to export data, please try again!"));
  };

  updateCompany = () => {
    const companyId = localStorage.getItem("companyId");
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      this.state.companyName.length < 3 ||
      !this.state.companyEmail ||
      !this.state.companyCountry
    ) {
      message.error(
        "Name or Email or Country should not be empty or less then three characters!"
      );
      return;
    } else if (!emailRegex.test(this.state.companyEmail)) {
      message.warning("Invalid email address. Please enter correct email!");
      return;
    }

    let data = {
      id: companyId,
      image: this.state.logoUrl,
      name: this.state.companyName,
      country: this.state.companyCountry,
      address: this.state.companyAddress,
      phone: this.state.companyPhone,
      bankAccountTitle: this.state.companyAccountTitle,
      bankAccountNumber: this.state.companyAccountNumber,
      gstNumber: this.state.companyGSTNumber,
      gstPercent: this.state.companyGSTPercent
        ? this.state.companyGSTPercent.toString()
        : "",
      currency: this.state.defaultCurrency
        ? this.state.defaultCurrency.toString()
        : "",
      email: this.state.companyEmail,
    };

    this.props
      .onUpdateCompany(data)
      .then((res) => {
        message.success("Updated!");
      })
      .catch((err) => {
        message.error("Error!");
      });
  };

  onHandleChange = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          logoUrl: imageUrl,
          loading: false,
        })
      );
    }
  };

  render() {
    const {
      selectedOption,
      groupName,
      groupEmail,
      imageUrl,
      name,
      email,
      city,
      companyDesignation,
      country,
      mobileNumber,
      telephoneNumber,
      companyName,
      companyEmail,
      companyCountry,
      companyCity,
      companyAddress,
      companyPhone,
      companyAccountTitle,
      companyAccountNumber,
      companyGSTNumber,
      companyGSTPercent,
      defaultCurrency,
      currentPassword,
      newPassword,
      confirmPassword,
      selectedUsers,
      isUpdateGroup,
      addGroupLoading,
      exportLoading,
      branchId,
      selectedBranch,
      branchName,
      branchEmail,
      branchCountry,
      branchCity,
      branchAddress,
      branchPhone,
      branchAccountTitle,
      branchAccountNumber,
      branchGSTNumber,
      branchGSTPercent,
      branchCurrency,
      teamMemberOpt,
      fileList,
      logoUrl,
    } = this.state;
    const {
      userDetail,
      managerData,
      ownerData,
      groupsData,
      teamMembers,
      companyData,
      branchData,
      imageUploadSuccess,
      storageData,
      location,
      userBranchData,
      onDisableUser,
      onUnassignBranch,
      onAssignBranch,
    } = this.props;

    const queryValue = queryString.parse(location.search);

    var activeQueryTab = queryValue.activeTab;

    const userOwner = localStorage.getItem("userOwner");
    const userManager = localStorage.getItem("userManager");

    return (
      <div>
        {/* <h3 style={{display: "flex", justifyContent: "center"}}>
          Coming Soon!
        </h3>   */}
        <div style={{ display: "flex" }}>
          <div className="page-container">
            <PotentialHeaderTabs
              data={userOwner === "True" ? headOption : headManagerOption}
              activeTab={
                userOwner === "True"
                  ? "Organization Settings"
                  : "BRANCH SETTINGS"
              }
            />
            <div className="sus-tab-container">
              {/* <Link to="/super-user-setting?activeTab=company-information">
                <div
                  onClick={() => this.onChangeTopTab("accountSettings")}
                  className={
                    this.state.activeTopTab == "accountSettings"
                      ? "sus-active-tab"
                      : "sus-inactive-tab"
                  }
                >
                  <span
                    className={
                      this.state.activeTopTab == "accountSettings"
                        ? "sus-active-tab-text"
                        : "sus-inactive-tab-text"
                    }
                  >
                    ACCOUNT SETTINGS
                  </span>
                </div>
              </Link> */}

              {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=company-information">
                  <div
                    onClick={() => this.onChangeTopTab("companyInformation")}
                    className={
                      this.state.activeTopTab == "companyInformation"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "companyInformation"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Organization Detail
                    </span>
                  </div>
                </Link>
              )}

              <Link to="/super-user-setting?activeTab=owner-manager-settings">
                <div
                  onClick={() => this.onChangeTopTab("managerSettings")}
                  className={
                    this.state.activeTopTab == "managerSettings"
                      ? "sus-active-tab"
                      : "sus-inactive-tab"
                  }
                >
                  <span
                    className={
                      this.state.activeTopTab == "managerSettings"
                        ? "sus-active-tab-text"
                        : "sus-inactive-tab-text"
                    }
                  >
                    {userOwner === "True"
                      ? "Owner Settings"
                      : "Branch Manager Settings"}
                  </span>
                </div>
              </Link>

              {/* {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=branch-information">
                  <div
                    onClick={() => this.onChangeTopTab("companySetting")}
                    className={
                      this.state.activeTopTab == "companySetting"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "companySetting"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Branch Information
                    </span>
                  </div>
                </Link>
              )} */}

              {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=add-branch">
                  <div
                    onClick={() => this.onChangeTopTab("addBranch")}
                    className={
                      this.state.activeTopTab == "addBranch"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "addBranch"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Add Branch
                    </span>
                  </div>
                </Link>
              )}

              {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=add-team-member">
                  <div
                    onClick={() => this.onChangeTopTab("addTeamMember")}
                    className={
                      this.state.activeTopTab == "addTeamMember"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "addTeamMember"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Add Team Member
                    </span>
                  </div>
                </Link>
              )}
              {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=team-member-settings">
                  <div
                    onClick={() => this.onChangeTopTab("teamMemberSettings")}
                    className={
                      this.state.activeTopTab == "teamMemberSettings"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "teamMemberSettings"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Team Member Settings
                    </span>
                  </div>
                </Link>
              )}
              {userOwner === "True" && (
                <Link to="/super-user-setting?activeTab=add-agent">
                  <div
                    onClick={() =>
                      this.onChangeTopTab(
                        localStorage.getItem("userBranchPermissions") &&
                          JSON.parse(
                            decodeURIComponent(
                              escape(
                                window.atob(
                                  localStorage.getItem("userBranchPermissions")
                                )
                              )
                            )
                          ).branchPermission.find(
                            (x) => x.name.toLowerCase() == "agent"
                          ).status
                          ? "addAgent"
                          : "notAllowed"
                      )
                    }
                    className={
                      this.state.activeTopTab == "addAgent"
                        ? "sus-active-tab"
                        : "sus-inactive-tab"
                    }
                  >
                    <span
                      className={
                        this.state.activeTopTab == "addAgent"
                          ? "sus-active-tab-text"
                          : "sus-inactive-tab-text"
                      }
                    >
                      Add Agent
                    </span>
                  </div>
                </Link>
              )}
            </div>
            <div className="sus-container" style={{ paddingBottom: 80 }}>
              {this.state.activeTopTab == "accountSettings" && (
                <div
                  className="sus-tab-container"
                  style={{ marginLeft: 48, paddingTop: 40, marginTop: 0 }}
                >
                  <Link to="/super-user-setting?activeTab=company-information">
                    <div
                      className={
                        this.state.activeInfoTab == "personalInfo"
                          ? "sus-active-tab"
                          : "sus-inactive-tab"
                      }
                      onClick={() => this.onChangeTab("personalInfo")}
                      style={{
                        width: 145,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <span
                        className={
                          this.state.activeInfoTab == "personalInfo"
                            ? "sus-active-tab-text"
                            : "sus-inactive-tab-text"
                        }
                        style={{ fontSize: 9 }}
                      >
                        PERSONAL INFORMATION
                      </span>
                    </div>
                  </Link>
                  <Link to="/super-user-setting?activeTab=branch-information">
                    <div
                      className={
                        this.state.activeInfoTab == "companySetting"
                          ? "sus-active-tab"
                          : "sus-inactive-tab"
                      }
                      onClick={() => this.onChangeTab("companySetting")}
                      style={{
                        width: 145,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <span
                        className={
                          this.state.activeInfoTab == "companySetting"
                            ? "sus-active-tab-text"
                            : "sus-inactive-tab-text"
                        }
                        style={{ fontSize: 9 }}
                      >
                        Branch Information
                      </span>
                    </div>
                  </Link>
                  <Link to="/super-user-setting?activeTab=company-information">
                    <div
                      className={
                        this.state.activeInfoTab == "procPersonGroup"
                          ? "sus-active-tab"
                          : "sus-inactive-tab"
                      }
                      onClick={() => this.onChangeTab("procPersonGroup")}
                      style={{
                        width: 145,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <span
                        className={
                          this.state.activeInfoTab == "procPersonGroup"
                            ? "sus-active-tab-text"
                            : "sus-inactive-tab-text"
                        }
                        style={{ fontSize: 9 }}
                      >
                        PROCESSING PERSON GROUP
                      </span>
                    </div>
                  </Link>
                </div>
              )}

              <div className="sus-content-card-container">
                {this.state.activeInfoTab == "personalInfo" &&
                  this.state.activeTopTab == "accountSettings" && (
                    <div
                      className="sus-pi-form-container"
                      style={{
                        marginLeft: 45,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#D9D9D9",
                      }}
                    >
                      <div
                        className="form-cont"
                        style={{
                          justifyContent: "space-between",
                          paddingTop: 20,
                          paddingBottom: 0,
                          display: "block",
                        }}
                      >
                        <div className="sus-form-row-cont">
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={this.uploadImage}
                            onChange={this.handleChangeImage}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: 105, height: 105 }}
                              />
                            ) : imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: 105, height: 105 }}
                              />
                            ) : (
                              <img
                                src={Images.dummyUserImage}
                                className="sus-profile-img"
                              />
                            )}
                          </Upload>
                          <div style={{ width: "60%" }}>
                            <div className="profile-input-border">
                              <input
                                className="profile-input"
                                placeholder="Full Name"
                                type="text"
                                value={name}
                                onChange={(e) =>
                                  this.myChangeHandler("name", e)
                                }
                              />
                            </div>
                            <div
                              className="profile-input-border"
                              style={{ marginTop: 10 }}
                            >
                              <input
                                className="profile-input"
                                placeholder="Email"
                                type="text"
                                value={email}
                                onChange={(e) =>
                                  this.myChangeHandler("email", e)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div style={{ marginTop: 20, marginBottom: 10 }}>
                          <span className="sus-cp-text">CHANGE PASSWORD</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder="Current Password"
                              type="password"
                              value={currentPassword}
                              onChange={(e) =>
                                this.myChangeHandler("currentPassword", e)
                              }
                            />
                          </div>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder="New Password"
                              type="password"
                              value={newPassword}
                              onChange={(e) =>
                                this.myChangeHandler("newPassword", e)
                              }
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder="Confirm New Password"
                              type="password"
                              value={confirmPassword}
                              onChange={(e) =>
                                this.myChangeHandler("confirmPassword", e)
                              }
                            />
                          </div>
                          <div style={{ width: "47%", display: "flex" }}>
                            <Button
                              className="sus-cp-btn"
                              loading={this.state.passwordLoading}
                              onClick={this.updatePassword}
                            >
                              <span className="sus-cp-btn-text">
                                CHANGE PASSWORD
                              </span>
                            </Button>
                          </div>
                        </div>

                        <div style={{ marginTop: 40, marginBottom: 20 }}>
                          <span className="sus-cp-text">
                            OTHER INFORMATION SETTINGS
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Telephone No:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={telephoneNumber}
                              onChange={(e) =>
                                this.myChangeHandler("telephoneNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Mobile No:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={mobileNumber}
                              onChange={(e) =>
                                this.myChangeHandler("mobileNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Company Designation
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyDesignation}
                              onChange={(e) =>
                                this.myChangeHandler("companyDesignation", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Country
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={country}
                              onChange={(e) =>
                                this.myChangeHandler("country", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            City
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "47%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={city}
                              onChange={(e) => this.myChangeHandler("city", e)}
                            />
                          </div>
                        </div>

                        <Button
                          onClick={this.onSubmit}
                          loading={this.state.loading}
                          type="primary"
                          className="sus-save-btn"
                          style={{ marginTop: 40 }}
                        >
                          SAVE NOW
                        </Button>
                      </div>
                    </div>
                  )}

                {this.state.activeTopTab == "companySetting" && (
                  <div style={{ width: "68%", marginTop: 67 }}>
                    <div
                      className="sus-cs-form-container"
                      style={{
                        marginLeft: 45,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#B7B7B7",
                        backgroundColor: "#FBFBFB",
                        borderRadius: 0,
                      }}
                    >
                      <div
                        className="form-cont"
                        style={{
                          justifyContent: "space-between",
                          paddingTop: 20,
                          paddingBottom: 0,
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="sus-logo-img">
                            <img src={Images.logoWithIcon} />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Select Branch:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <Select
                              bordered={false}
                              placeholder="Select Branch"
                              defaultValue={selectedBranch}
                              onChange={this.handleChangeBranch}
                              style={{ width: "100%" }}
                            >
                              {branchOptions}
                            </Select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Branch Name:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchName}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Branch Email:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchEmail}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Country:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchCountry}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            City:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchCity}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Address:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchAddress}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Phone:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchPhone}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Title:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchAccountTitle}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Number:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchAccountNumber}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Number:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchGSTNumber}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Percentage:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="number"
                              step="any"
                              value={branchGSTPercent}
                              onChange={this.myChangeHandler}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Default Currency:
                          </p>
                          <div
                            // className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <Select
                              // disabled={true}
                              placeholder="Please Select Currency!"
                              size="small"
                              value={defaultCurrency}
                              onChange={this.myChangeHandler}
                            >
                              {this.props &&
                                this.props.currencyListing &&
                                this.props.currencyListing.map((data) => {
                                  // eslint-disable-next-line react/jsx-no-undef
                                  return (
                                    <Option value={data.id}>
                                      {data.currencyName}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {/* <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={branchCurrency}
                              onChange={this.myChangeHandler}
                            /> */}
                          </div>
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            marginTop: 40,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Show Team Member/s Info:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <Select
                              bordered={false}
                              placeholder="Select User"
                              defaultValue={selectedUsers}
                              onChange={this.handleChangeUser}
                              style={{ width: "100%" }}
                            >
                              {teamMemberOpt}
                            </Select>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {showUserPermissions.length > 0 &&
                      showUserPermissions.map((item, index) => {
                        let imageUrl = "";
                        if (this.state.imageUpdateId == item.id) {
                          imageUrl = imageUploadSuccess;
                        }

                        return (
                          <PermissionCard
                            user={item}
                            parentMethod={this.addUserPermissons}
                            updateUser={this.updateCompanyUserData}
                            uploadImage={this.uploadImage}
                            imageUrl={imageUrl}
                            branchData={branchData}
                            onAssignBranch={onAssignBranch}
                            onUnassignBranch={onUnassignBranch}
                            onDisable={this.disableTeamMember}
                            passwordUpdateMethod={this.updateUsersPassword}
                          />
                        );
                      })}
                  </div>
                )}

                {this.state.activeInfoTab == "procPersonGroup" &&
                  this.state.activeTopTab == "accountSettings" && (
                    <div
                      className="sus-form-container"
                      style={{
                        marginLeft: 45,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#D9D9D9",
                      }}
                    >
                      <div className="sus-add-cont-row">
                        <div></div>
                        <div onClick={this.openModal}>
                          <img src={Images.plusIcon} style={{ width: 20 }} />
                        </div>
                      </div>
                      <div>
                        <span className="sus-ppg-text">
                          PROCESSING PERSON GROUPS
                        </span>
                      </div>
                      <div style={{ marginTop: 35 }}>
                        <div className="sus-table">
                          <div className="sus-table-header">
                            <div className="sus-head-text-cont">
                              <span className="sus-head-text">Name</span>
                            </div>
                            <div
                              className="sus-head-text-cont"
                              style={{ width: 150 }}
                            >
                              <span className="sus-head-text">Email</span>
                            </div>
                            <div className="sus-head-text-cont">
                              <span className="sus-head-text">Action</span>
                            </div>
                          </div>
                          {groupsData &&
                            groupsData.items.map((item, index) => {
                              return (
                                <div className="sus-table-content">
                                  <div className="sus-head-text-cont">
                                    <span className="sus-content-text">
                                      {item.name}
                                    </span>
                                  </div>
                                  <div
                                    className="sus-head-text-cont"
                                    style={{ width: 150 }}
                                  >
                                    <span className="sus-content-text">
                                      {item.email}
                                    </span>
                                  </div>
                                  <div
                                    className="sus-head-text-cont"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => this.openUpdatePopup(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={Images.editBorderBlue}
                                        style={{ width: 15 }}
                                      />
                                    </div>
                                    <div
                                      onClick={() => this.deleteGroup(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={Images.deleteIcon}
                                        style={{
                                          marginLeft: 5,
                                          width: 17,
                                          height: 17,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}

                {this.state.activeTopTab == "managerSettings" && (
                  <div style={{ width: `calc(65% - 45px)`, marginTop: 40 }}>
                    {ownerData &&
                      userOwner === "True" &&
                      ownerData.users.map((item, index) => {
                        let imageUrl = "";
                        if (
                          this.state.imageUpdateId == item.id &&
                          imageUploadSuccess
                        ) {
                          imageUrl = imageUploadSuccess;
                        }
                        return (
                          <OwnerCard
                            owner={item}
                            parentMethod={this.updateUserData}
                            passwordUpdateMethod={this.updateUsersPassword}
                            imageUrl={imageUrl}
                            onDisable={this.disableTeamMember}
                            uploadImage={this.uploadImage}
                            onGetCountries={this.props.onGetCountries}
                            countriesData={this.props.countriesData}
                          />
                        );
                      })}
                    {false &&
                      managerData &&
                      managerData.users.map((item, index) => {
                        let imageUrl = "";
                        if (
                          this.state.imageUpdateId == item.id &&
                          imageUploadSuccess
                        ) {
                          imageUrl = imageUploadSuccess;
                        }
                        return (
                          <div>
                            {!item.isOwner && (
                              <ManagerCard
                                manager={item}
                                parentMethod={this.updateUserData}
                                imageUrl={imageUrl}
                                onDisable={this.disableTeamMember}
                                uploadImage={this.uploadImage}
                                onDisableUser={onDisableUser}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}

                {this.state.activeTopTab == "companyInformation" && (
                  <div style={{ width: "68%", marginTop: 67 }}>
                    <div
                      className="sus-cs-form-container"
                      style={{
                        marginLeft: 45,
                        paddingLeft: 55,
                        paddingRight: 55,
                        border: 1,
                        borderStyle: "solid",
                        borderColor: "#B7B7B7",
                        backgroundColor: "#FBFBFB",
                        borderRadius: 0,
                      }}
                    >
                      <div
                        className="form-cont"
                        style={{
                          justifyContent: "space-between",
                          paddingTop: 20,
                          paddingBottom: 0,
                          display: "block",
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="sus-logo-img">
                            <img src={Images.logoWithIcon} />
                          </div>
                        </div> */}

                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={this.onHandleChange}
                          action={this.uploadCompanyLogo}
                        >
                          {logoUrl ? (
                            <img
                              src={logoUrl}
                              alt="avatar"
                              style={{
                                width: 105,
                                height: 105,
                              }}
                            />
                          ) : logoUrl ? (
                            <img
                              src={logoUrl}
                              alt="avatar"
                              style={{
                                width: 105,
                                height: 105,
                              }}
                            />
                          ) : (
                            <PlusOutlined />
                          )}
                        </Upload>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Company Name*:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyName}
                              onChange={(e) =>
                                this.myChangeHandler("companyName", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Company Email*:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyEmail}
                              onChange={(e) =>
                                this.myChangeHandler("companyEmail", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Country*:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyCountry}
                              onChange={(e) =>
                                this.myChangeHandler("companyCountry", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Address:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyAddress}
                              onChange={(e) =>
                                this.myChangeHandler("companyAddress", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Phone:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyPhone}
                              onChange={(e) =>
                                this.myChangeHandler("companyPhone", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Title:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyAccountTitle}
                              onChange={(e) =>
                                this.myChangeHandler("companyAccountTitle", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Bank Account Number:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyAccountNumber}
                              onChange={(e) =>
                                this.myChangeHandler("companyAccountNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Number:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={companyGSTNumber}
                              onChange={(e) =>
                                this.myChangeHandler("companyGSTNumber", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            GST Percentage:
                          </p>
                          <div
                            className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <input
                              className="profile-input"
                              placeholder=""
                              type="number"
                              step="any"
                              value={companyGSTPercent}
                              onChange={(e) =>
                                this.myChangeHandler("companyGSTPercent", e)
                              }
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="medical-label" style={{ fontSize: 11 }}>
                            Default Currency:
                          </p>
                          <div
                            // className="profile-input-border"
                            style={{ width: "55%" }}
                          >
                            <Select
                              // disabled={true}
                              placeholder="Please Select Currency!"
                              size="small"
                              value={defaultCurrency}
                              onChange={(e) => this.currencyHandler(e)}
                            >
                              {this.props &&
                                this.props.currencyListing &&
                                this.props.currencyListing.map((data) => {
                                  // eslint-disable-next-line react/jsx-no-undef
                                  return (
                                    <Option value={data.id}>
                                      {data.currencyName}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {/* <input
                              className="profile-input"
                              placeholder=""
                              type="text"
                              value={defaultCurrency}
                              onChange={(e) =>
                                this.myChangeHandler("defaultCurrency", e)
                              }
                            /> */}
                          </div>
                        </div>
                        <Button
                          onClick={this.updateCompany}
                          loading={this.state.loading}
                          type="primary"
                          className="button-blue"
                          style={{ marginTop: 40 }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeTopTab == "addTeamMember" && (
                  <div className="sus-team-memb-setting">
                    {false && (
                      <div
                        className="sus-bottom-btn-cont"
                        style={{ marginLeft: 0 }}
                      >
                        <div
                          className="sus-bottom-icon-cont"
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 12,
                            borderRadius: 5,
                          }}
                        >
                          <span className="sus-bn-logo">BN</span>
                        </div>
                        <span className="sus-bottom-text">OWNER SETTINGS</span>
                      </div>
                    )}
                    <TeamMember />
                  </div>
                )}
                {this.state.activeTopTab == "teamMemberSettings" && (
                  <div className="sus-team-memb-setting">
                    <TeamMemberSettings />
                  </div>
                )}
                {this.state.activeTopTab == "addAgent" && (
                  <div className="sus-team-memb-setting">
                    <AddAgent />
                  </div>
                )}
                {this.state.activeTopTab == "notAllowed" && (
                  <div className="sus-team-memb-setting">
                    <PurchaseSubscription />
                  </div>
                )}
                {this.state.activeTopTab == "addBranch" && (
                  <div className="sus-team-memb-setting">
                    <AddBranch />
                  </div>
                )}

                <div
                  className="sus-right-cont"
                  style={{
                    paddingTop:
                      this.state.activeTopTab == "managerSettings" ||
                      this.state.activeTopTab == "companyInformation" ||
                      this.state.activeTopTab == "addTeamMember" ||
                      this.state.activeTopTab == "addAgent" ||
                      this.state.activeTopTab == "teamMemberSettings" ||
                      this.state.activeTopTab == "companySetting" ||
                      this.state.activeTopTab == "addBranch"
                        ? 67
                        : 0,
                  }}
                >
                  <div className="sus-card-cont">
                    <div className="sus-card-header">
                      <div>
                        <span className="sus-card-header-text">STORAGE</span>
                      </div>
                      <div>
                        <span className="sus-card-header-small-text">HIDE</span>
                      </div>
                    </div>
                    <div className="sus-bar-cont">
                      <ProgressBar
                        bgcolor="#05B8E6"
                        completed={storageData ? storageData.used : 0}
                      />
                    </div>
                    <div className="sus-card-content-cont">
                      <div style={{ width: 100 }}>
                        <span className="sus-card-content-text">Used:</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span className="sus-card-content-text">
                          {storageData ? storageData.used : 0.0} MB
                        </span>
                      </div>
                    </div>
                    <div
                      className="sus-card-content-cont"
                      style={{ paddingTop: 0 }}
                    >
                      <div style={{ width: 100 }}>
                        <span className="sus-card-content-text">
                          Remaining:
                        </span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span className="sus-card-content-text">
                          {storageData ? storageData.available : 0.0} MB
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="sus-card-cont" style={{ marginTop: 40 }}>
                    <div className="sus-card-header">
                      <div>
                        <span className="sus-card-header-text">
                          DATA EXPORTS
                        </span>
                      </div>
                      <div>
                        <span className="sus-card-header-small-text">HIDE</span>
                      </div>
                    </div>
                    <div
                      className="sus-card-content-cont"
                      style={{ paddingTop: 25 }}
                    >
                      <span
                        className="sus-card-content-text"
                        style={{ color: "#6C6C6C" }}
                      >
                        EXPORT DATA
                      </span>
                    </div>

                    <div
                      className="sus-card-content-cont"
                      style={{ paddingTop: 0 }}
                    >
                      <span
                        className="sus-card-content-text"
                        style={{ color: "#41AAD2" }}
                      >
                        Export All Data to CSV
                      </span>
                    </div>

                    <div
                      style={{
                        width: "47%",
                        display: "flex",
                        marginLeft: 35,
                        marginTop: 5,
                      }}
                    >
                      <Button
                        className="sus-cp-btn"
                        loading={exportLoading}
                        onClick={this.exportData}
                      >
                        <span className="sus-cp-btn-text">EXPORT</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visible}
          width="500"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: 40 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="sus-modal-label">
                <span className="sus-modal-label-text">
                  PROCESSING PERSON GROUPS
                </span>
              </div>
              <div onClick={this.closeModal}>
              <button type="button" aria-label="Close" class="ant-modal-close"><span class="ant-modal-close-x"><span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon"><svg fill-rule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path></svg></span></span></button>
              </div>
            </div>
            <div className="sus-add-processing-person-form">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  class="medical-label"
                  style={{ fontSize: 11, marginLeft: 15 }}
                >
                  Group Name*:
                </p>
                <div class="profile-input-border" style={{ width: "60%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={groupName}
                    onChange={(e) => this.myChangeHandler("groupName", e)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <p
                  class="medical-label"
                  style={{ fontSize: 11, marginLeft: 15 }}
                >
                  Group Email*:
                </p>
                <div class="profile-input-border" style={{ width: "60%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={groupEmail}
                    onChange={(e) => this.myChangeHandler("groupEmail", e)}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <div></div>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={
                    isUpdateGroup ? () => this.updateGroup() : this.addGroup
                  }
                  loading={addGroupLoading}
                  type="primary"
                  className="sus-add-btn"
                >
                  SAVE
                </Button>
                <Button
                  onClick={this.closeModal}
                  type="primary"
                  className="sus-add-btn"
                  style={{ marginLeft: 10 }}
                >
                  CLOSE
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SuperUserSetting;
