import { api } from "../../services/api";
import * as types from "../Constants";

export const getMailChimp = (selectedBranchId) => {
  const options = {
    url: `v1/mailchimp`,
  };
  options.types = [types.GET_MAIL_CHIMP_SUCCESS, types.GET_MAIL_CHIMP_FAILURE];

  return api.get(options);
};

export const addMailChimp = (data) => {
  const options = {
    url: "v1/mailchimp",
  };
  options.types = [types.ADD_MAIL_CHIMP_SUCCESS, types.ADD_MAIL_CHIMP_FAILURE];

  return api.post(options, data);
};
