import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TeamMemberSettings from "../SuperUserSetting/TeamMemberSettings";

import {
  addTeamMember,
  addUserPassword,
  getBranch,
  updateManager,
  changeUsersPassword,
  disableUser,
  uploadAvatar,
  assignBranch,
  unassignBranch,
  getStorage,
  exportStorage,
  getUserBranch,
  getTeamMember,
  getTeamMemberObj, updUserPermission
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    teamMembers: state.teamMemberReducer.teamMembersObj,
    teamMemberError: state.teamMemberReducer.teamMemberError,
    addTeamMemberSuccess: state.teamMemberReducer.addTeamMemberSuccess,
    branchData: state.branchReducer.branchData,
    updManagerSuccess: state.managerReducer.updManagerSuccess,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    branchData: state.branchReducer.branchData,
    storageData: state.userDetailReducer.storageData,
    exportSuccess: state.userDetailReducer.exportSuccess,
    userBranchData: state.branchReducer.userBranchData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeamMember: bindActionCreators(getTeamMemberObj, dispatch),
    onAddTeamMember: bindActionCreators(addTeamMember, dispatch),
    onAddPassword: bindActionCreators(addUserPassword, dispatch),
    onGetBranch: bindActionCreators(getBranch, dispatch),
    onUpdateManager: bindActionCreators(updateManager, dispatch),
    onChangeUsersPassword: bindActionCreators(changeUsersPassword, dispatch),
    onDisableUser: bindActionCreators(disableUser, dispatch),
    onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
    onAssignBranch: bindActionCreators(assignBranch, dispatch),
    onUnassignBranch: bindActionCreators(unassignBranch, dispatch),
    onGetStorage: bindActionCreators(getStorage, dispatch),
    onExportData: bindActionCreators(exportStorage, dispatch),
    onGetUserBranch: bindActionCreators(getUserBranch, dispatch),
    onUpdateUserPermission: bindActionCreators(updUserPermission, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberSettings);
