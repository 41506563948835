import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Reports from "../Reports/Reports";

import {
  getTeamMember,
  saleHistoryReport,
  processingPersonReport,
  clientSourceReport,
  tasksReport,
  birthdayReport,
  fileNotesReport,
  activeCasesReport,
  visaExpiringReport,
  clientEmployersReport,
  searchMainClient,
  docChecklistReport,
  serviceMngmntReport,
  getFileNotesReport,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    teamMembers: state.teamMemberReducer.teamMembers,
    saleHistoryRes: state.reportReducer.saleHistoryRes,
    clientSourceReportRes: state.reportReducer.clientSourceReportRes,
    processingPersonReportRes: state.reportReducer.processingPersonReportRes,
    taskReportRes: state.reportReducer.taskReportRes,
    birthdayReportRes: state.reportReducer.birthdayReportRes,
    fileNotesReportRes: state.reportReducer.fileNotesReportRes,
    activeCasesReportRes: state.reportReducer.activeCasesReportRes,
    visaExpiryReportRes: state.reportReducer.visaExpiryReportRes,
    clientEmployerReportRes: state.reportReducer.clientEmployerReportRes,
    docChecklistReportRes: state.reportReducer.docChecklistReportRes,
    servMngmntReportRes: state.reportReducer.servMngmntReportRes,
    getFileNotesReportRes: state.reportReducer.getFileNotesReportRes,
    clientsData: state.clientProfileReducer.clientsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onSaleHistoryReport: bindActionCreators(saleHistoryReport, dispatch),
    onProcessingPersonReport: bindActionCreators(
      processingPersonReport,
      dispatch
    ),
    onClientSourceReport: bindActionCreators(clientSourceReport, dispatch),
    onTaskReport: bindActionCreators(tasksReport, dispatch),
    onBirthdayReport: bindActionCreators(birthdayReport, dispatch),
    onFileNotesReport: bindActionCreators(fileNotesReport, dispatch),
    onActiveCasesReport: bindActionCreators(activeCasesReport, dispatch),
    onVisaExpiryReport: bindActionCreators(visaExpiringReport, dispatch),
    onClientEmployerReport: bindActionCreators(clientEmployersReport, dispatch),
    onSearchClient: bindActionCreators(searchMainClient, dispatch),
    onDocChecklistReport: bindActionCreators(docChecklistReport, dispatch),
    onServMngmntReport: bindActionCreators(serviceMngmntReport, dispatch),
    onGetFileNotesReport: bindActionCreators(getFileNotesReport, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
