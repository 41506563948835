import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  Spin,
  Upload,
  InputNumber,
  message,
  AutoComplete,
  TimePicker,
} from "antd";
import { Images } from "../../Themes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";
import {
  addEmailContent,
  getEmailContent,
  updateEmailContent,
} from "../../store/Actions";

const layout = {
  labelCol: { span: 3, offset: 2 },
  wrapperCol: { span: 18, offset: 1 },
};

class EmailContentAddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: "",
    };
  }

  getEmailTypeStatus = (email) => {
    if (
      email.emailType === "Invitation to www.checkmyvisa.io" ||
      email.emailType === "Invoice Email" ||
      email.emailType === "Notifications" ||
      email.emailType === "Update Visa Status Notification" ||
      email.emailType === "Questionnaire Link" ||
      email.emailType === "Invoice Follow-up" ||
      email.emailType === "Client Birthday"
    ) {
      return false;
    } else {
      return true;
    }
  };

  onAddEmailContent = (values) => {
    const addEmailData = {
      emailType: values && values.emailType,
      emailContent: this.state.content,
    };

    let statusBol = this.getEmailTypeStatus(addEmailData);
    if (statusBol) {
      this.props &&
        this.props
          .onAddingEmailContent(addEmailData)
          .then(() => {
            this.props.setLoading(true);
            this.props.getListingEmailContent();
          })
          .catch((res) => {
            message.error("An error occurred, please try again!");
          });
    } else {
      message.error(
        "You cannot add because this name is already assigned to one of the system generated email contents! Try with another name"
      );
    }
  };

  getFormFields = () => {
    return (
      <Row className="ca-gray-cont">
        <Col xs={23}>
          <Form.Item
            colon={false}
            labelAlign="left"
            label="Name"
            name="emailType"
            rules={[
              { required: true, message: "Please enter name!" },
              {
                max: 100,
                message: "Name should be less than 100 character",
              },
            ]}
          >
            <Input placeholder={"Enter name"} />
          </Form.Item>
          <Form.Item
            colon={false}
            labelAlign="left"
            label="Content"
            name="emailContent"
          >
            <FroalaEditorCom
              setLoading={(value) => this.setState({ loading: value })}
              model={this.state.content}
              onModelChange={(data) => {
                this.setState({ content: data });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getButtons = () => {
    return (
      <Row>
        <Col xs={1} offset={20}>
          <Form.Item>
            <Button
              className="button-blue"
              type="primary"
              onClick={this.props.handleCancelAddEmailTempPopUp}
            >
              Close
            </Button>
          </Form.Item>
        </Col>
        <Col xs={2} offset={1}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button-blue">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getAddEmailTempForm = () => {
    return (
      <div>
        {this.getFormFields()}
        {this.getButtons()}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="reminder-model-main width-modal-add-email-template"
          title="Add Email Template"
          visible={this.props.isVisibleAddEmailTemplatePopUp}
          onCancel={this.props.handleCancelAddEmailTempPopUp}
          footer={null}
          maskClosable={false}
        >
          <Spin spinning={this.state.loading}>
            <Form {...layout} onFinish={this.onAddEmailContent}>
              {this.getAddEmailTempForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
  onAddingEmailContent: bindActionCreators(addEmailContent, dispatch),
});

EmailContentAddForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailContentAddForm);
export default withRouter(EmailContentAddForm);
