import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentSync from "../AccountSetting/UserLevelSettings/DocumentSync/DocumentSync";

import {
  getDropboxDoc,
  DropboxAccountLogin,
  addDropboxDoc,
  OneDriveAccountLogin,
  SaveOneDriveToken,
  GetOneDriveInfo,
  GoogleDriveAccountLogin,
  SaveGoogleDriveToken,
  GetGoogleDriveInfo,
  Office365AccountLogin,
  SaveOffice365Token,
  GetOffice365Info,
  removeDropboxAccount,
  removeOnedriveAccount,
  removeGoogledriveAccount,
  removeOffice365Account,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dropboxDocRes: state.docSyncReducer.dropboxDocRes,
    addDropboxRes: state.docSyncReducer.addDropboxDocRes,
    dropboxLoginRes: state.docSyncReducer.dropboxLoginRes,
    oneDriveLoginRes: state.docSyncReducer.oneDriveLoginRes,
    oneDriveTokenRes: state.docSyncReducer.oneDriveTokenRes,
    oneDriveAccountRes: state.docSyncReducer.oneDriveAccountRes,
    googleDriveAccountRes: state.docSyncReducer.googleDriveAccountRes,
    office365TokenRes: state.docSyncReducer.office365TokenRes,
    office365AccountRes: state.docSyncReducer.office365AccountRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDropboxDocuments: bindActionCreators(getDropboxDoc, dispatch),
    onAddDropbox: bindActionCreators(addDropboxDoc, dispatch),
    onDropboxLogin: bindActionCreators(DropboxAccountLogin, dispatch),
    onOneDriveLogin: bindActionCreators(OneDriveAccountLogin, dispatch),
    onSaveOneDriveToken: bindActionCreators(SaveOneDriveToken, dispatch),
    onGetOneDriveInfo: bindActionCreators(GetOneDriveInfo, dispatch),
    onGoogleDriveLogin: bindActionCreators(GoogleDriveAccountLogin, dispatch),
    onSaveGoogleDriveToken: bindActionCreators(SaveGoogleDriveToken, dispatch),
    onGetGoogleDriveInfo: bindActionCreators(GetGoogleDriveInfo, dispatch),
    onOffice365Login: bindActionCreators(Office365AccountLogin, dispatch),
    onSaveOffice365Token: bindActionCreators(SaveOffice365Token, dispatch),
    onGetOffice365Info: bindActionCreators(GetOffice365Info, dispatch),
    onRemoveDropboxAccount: bindActionCreators(removeDropboxAccount, dispatch),
    onRemoveOnedriveAccount: bindActionCreators(
      removeOnedriveAccount,
      dispatch
    ),
    onRemoveGoogledriveAccount: bindActionCreators(
      removeGoogledriveAccount,
      dispatch
    ),
    onRemoveOffice365Account: bindActionCreators(
      removeOffice365Account,
      dispatch
    ),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSync);
