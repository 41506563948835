import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
// import DocumentTypesForm from "./DocumentTypesForm";
import { Images } from "../../Themes";
import {
  Table,
  Modal,
  Spin,
  message,
  Checkbox,
  Button,
  Tooltip,
  Form,
  Input,
} from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import { apiRefresh } from "../../services/api";

const CmvFAQs = ({
  onGetDocumentType,
  documentTypeRes,

  onAddDocumentType,
  addDocumentTypeRes,

  onUpdetaDocumentType,

  onRemoveDocumentType,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderViewVisible, setFolderViewVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [folderView, setFolderView] = useState("");
  const [loading, setLoading] = useState(false);
  const [faqsList, setFaqsList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const [form] = Form.useForm();
  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = () => {
    setLoading(true);
    const cmvFaqOpt = {
      url: `v1/faq/All`,
    };
    cmvFaqOpt.types = ["GET_CMV_FAQS_SUCCESS", "GET_CMV_FAQS_FAILURE"];

    apiRefresh
      .get(cmvFaqOpt)
      .then((res) => {
        setLoading(false);
        setFaqsList(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    // onRemoveDocumentType(remove).then(() => {
    //   onGetDocumentType().then(() => {
    //     setLoading(false);
    //     message.success("Successfully Deleted!");
    //   });
    // });
  };

  const showModal = (value) => {
    setIsModalVisible(true);
    setCreateMode(value);
    // setUpdatedataRes(value);
    setFolderView(value);
  };
  const showFolderView = (type) => {
    setFolderViewVisible(true);
    setFolderView(type);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setFolderViewVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFolderViewVisible(false);
    setIsUpdate(false);
    form.resetFields();
  };
  // function onCheckChange(e) {
  //   console.log(`checked = ${e.target.checked}`);
  //   setCheckbox(e.target.checked);
  // }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined onClick={() => showModal(record)} />
            <DeleteOutlined onClick={() => removeTag(record && record.id)} />
          </div>
        );
      },
    },
  ];

  const onRemoveFaq = () => {
    let data = {
      id: selectedId,
    };
    setLoading(true);
    const cmvFaqOpt = {
      url: `v1/faq`,
    };
    cmvFaqOpt.types = ["DELETE_CMV_FAQS_SUCCESS", "DELETE_CMV_FAQS_FAILURE"];

    apiRefresh
      .delete(cmvFaqOpt, data)
      .then((res) => {
        getFaqs();
        setLoading(false);
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (isUpdate) {
      let updData = {
        id: selectedId,
        question: values.question,
        answer: values.answer,
        hideInCMV: false,
        priority: 0,
      };
      setLoading(true);
      const cmvFaqOpt = {
        url: `v1/faq`,
      };
      cmvFaqOpt.types = ["ADD_CMV_FAQS_SUCCESS", "ADD_CMV_FAQS_FAILURE"];

      apiRefresh
        .put(cmvFaqOpt, updData)
        .then((res) => {
          getFaqs();
          setLoading(false);
          // setFaqsList(res.payload);
          form.resetFields();
          handleCancel();
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      let data = {
        question: values.question,
        answer: values.answer,
        hideInCMV: false,
        priority: 0,
      };
      setLoading(true);
      const cmvFaqOpt = {
        url: `v1/faq`,
      };
      cmvFaqOpt.types = ["ADD_CMV_FAQS_SUCCESS", "ADD_CMV_FAQS_FAILURE"];

      apiRefresh
        .post(cmvFaqOpt, data)
        .then((res) => {
          getFaqs();
          setLoading(false);
          // setFaqsList(res.payload);
          form.resetFields();
          handleCancel();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  return (
    <Fragment>
      <Spin spinning={loading}>
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h2>FAQS</h2>
          <div>
            <img
              onClick={() => setIsModalVisible(true)}
              src={Images.plusIcon}
              style={{ width: 20, cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="faq-main-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <div>
              <span className="faq-head-text">Questions</span>
            </div>
            <div style={{ width: 100 }}>
              <span className="faq-head-text">Actions</span>
            </div>
          </div>
          {faqsList &&
            faqsList.map((faq, ind) => (
              <div
                class="faq-item-main"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ width: "100%" }}>
                  <div class="faq-item-first">
                    <div class="faq-item-first-child first-width">
                      <b class="sn-faq">{++ind + "."}</b>{" "}
                      <span>{faq.question + "?"}</span>
                    </div>
                  </div>
                  <div class="faq-item-sec">
                    <div class="faq-item-sec-child">
                      <b>Answer</b> <span>{": " + faq.answer}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="sus-head-text-cont"
                  style={{
                    display: "flex",
                    // justifyContent: "space-around",
                  }}
                >
                  {/* <div
                    style={{ cursor: "pointer" }}
                    // onClick={() =>
                    //   this.createDuplicateQuestionnaire(
                    //     questionnaire.questionnaireId
                    //   )
                    // }
                  >
                    <img src={Images.duplicate} style={{ width: 15 }} />
                  </div> */}
                  <div
                    style={{
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    onClick={() => {
                      setIsUpdate(true);
                      form.setFieldsValue({
                        question: faq.question,
                        answer: faq.answer,
                      });
                      setIsModalVisible(true);
                      setSelectedId(faq.id);
                    }}
                  >
                    <img src={Images.editBorderBlue} style={{ width: 15 }} />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    onClick={() => {
                      setSelectedId(faq.id);
                      setFolderViewVisible(true);
                    }}
                  >
                    <img src={Images.deleteBlue} style={{ width: 15 }} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* <div className="faq-cont">
        <div className="faq-item-first">
          <span className="faq-item-first-child">Queston</span>
        </div>
      </div> */}

        {isModalVisible && (
          <Modal
            title={!isUpdate ? "Add CMV FAQ" : "Update"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            header={false}
            footer={false}
          >
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                name="question"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="answer"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input.TextArea rows={6} />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button
                    loading={loading}
                    //   onClick={() => this.setState({ clickedButton: "save" })}
                    htmlType="submit"
                    name="save"
                    className="button-blue"
                    style={{ marginTop: 20, marginRight: 10 }}
                  >
                    SAVE
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => setIsModalVisible(false)}
                    htmlType="submit"
                    name="save"
                    className="button-blue"
                    style={{ marginTop: 20, marginRight: 10 }}
                  >
                    Close
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        )}
        {folderViewVisible && (
          <Modal
            title={folderView === "folder-view" && "Confirmation"}
            visible={folderViewVisible}
            onOk={onRemoveFaq}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to delete ?</p>
          </Modal>
        )}
      </Spin>
    </Fragment>
  );
};
export default CmvFAQs;
