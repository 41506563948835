import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PotentialClientStatus from "../SuperUserSetting/PotentialClientStatus";

import {
  getPotentialClientStatus,
  addPotentialClientStatus,
  updPotentialClientStatus,
  getPClientEmailImport,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    potentialClientData: state.potentialClientReducer.potentialClientData,
    addPotentialClientStatus:
      state.potentialClientReducer.addPotentialClientStatus,
    updPotentialClientStatus:
      state.potentialClientReducer.updPotentialClientStatus,
    pclientImportsettings: state.potentialClientReducer.pclientImportsettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPotentialClientStatus: bindActionCreators(
      getPotentialClientStatus,
      dispatch
    ),
    onAddPotentialClientStatus: bindActionCreators(
      addPotentialClientStatus,
      dispatch
    ),
    onUpdPotentialClientStatus: bindActionCreators(
      updPotentialClientStatus,
      dispatch
    ),
    onGetPClientImportSetting: bindActionCreators(
      getPClientEmailImport,
      dispatch
    ),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PotentialClientStatus);
