import React, { useState, Fragment, useEffect } from "react";
import { Spin, message, Tooltip, Modal, Table } from "antd";
import {
  FileTextOutlined,
  FileTextFilled,
  UserOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

let userId = localStorage.getItem("userId");

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

// const ClientSourceTable = ({ clientSourceReportRes }) => {
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text, record) => {
      return <span style={{ cursor: "pointer" }}>{text}</span>;
    },

    // specify the condition of filtering result
    // here is that finding the name started with `value`
    // onFilter: (value, record) => record.name.indexOf(value) === 0,
    // sorter: (a, b) => a.name.length - b.name.length,
    // sortDirections: ["descend"],
  },
  {
    title: "Clients",
    dataIndex: "totalClient",
    key: "totalClient",
    align: "center",
  },
];
export class ClientSourceTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 10,
      totalPages: props.reportsCount,
      defaultCurrent: 1,
    };
  }
  paginate = (value) => {
    this.setState(
      { pageSize: value.pageSize, pageNumber: value.current },
      () => {
        let data = {
          processingPerson: this.props.requestData.processingIds,
          dateFrom:
            this.props.requestData.dateFrom || "1900-01-01T00:00:00.000Z",
          dateTo: this.props.requestData.dateTo || "1900-01-01T00:00:00.000Z",
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
        };
        this.props.onClientSourceReport(data).then(() => {
          this.setState(
            {
              defaultCurrent: 1,
              //   branchId: this.state.branchId,
            },
            () => {
              console.log("state", this.state);
            }
          );
        });
      }
    );
  };
  render() {
    const { clientSourceRes, displayText } = this.props;
    return (
      <Fragment>
        <div className="table-head-clr">
          {/* <Spin size="large" spinning={loading}> */}
          <div className="rep-print-row-cont">
            <div
              className="pciq-top-div"
              style={{ marginBottom: 5, marginLeft: 10 }}
            >
              <span
                className="pc-top-div-text"
                style={{ color: "#0A3C5D", fontSize: 12 }}
              >
                {displayText}
              </span>
            </div>
          </div>
          <Table
            className="border-3 table-head"
            bordered
            columns={columns}
            dataSource={clientSourceRes}
            onChange={this.paginate}
            pagination={{
              defaultCurrent: this.state.defaultCurrent,
              total: this.props.reportsCount,
              defaultPageSize: 20,
            }}
          />

          {/* </Spin> */}
        </div>
      </Fragment>
    );
  }
}
export default ClientSourceTable;
