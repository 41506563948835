import React from "react";
import HeaderBar from "../Components/Header/HeaderBar";
import rightNav from "../images/log-right-nav-1.png";
import rightNavTwo from "../images/log-right-nav-2.png";
import rightNavThr from "../images/log-right-nav-3.png";
import rightNavFour from "../images/log-right-nav-4.png";
import rightNavFiv from "../images/log-right-nav-5.png";
import rightNavSix from "../images/log-right-nav-6.png";
import rightNavSev from "../images/log-right-nav-7.png";
import rightNavEight from "../images/log-right-nav-8.png";
import rightNavNine from "../images/log-right-nav-9.png";
import "./FileNotesStyles.css";
import { Link } from "react-router-dom";
import { Images } from "../Themes";
import Sidebar from "../Components/SideBar";
import HeaderBarTabs from "../Components/Header/HeaderTabs";

import activityData from "../Components/ClientActivity/ActivityData";
import ProfileSideBar from "../Components/ProfileSideBar";
import { apiRefresh } from "../services/api";
import * as types from "../store/Constants";
import {
  Select,
  message,
  Modal,
  Table,
  Spin,
  Form,
  DatePicker,
  Input,
  Button,
  Tag,
  Tooltip,
  Row,
  Col,
} from "antd";
import moment from "moment";
import renderHTML from "react-render-html";
import headOption from "../Components/Header/HeaderTabOptions";
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import ClientFileNotes from "../Components/FileNotes";
import { saveAs } from "file-saver";
import FieldsRow from "../Components/FileNotes/FieldsRow";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const headOption = [
//   {
//     tabName: "Client Profile",
//     imageName: Images.clientProfile,
//     linkName: "/profile"
//   },
//   { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
//   { tabName: "Admission", imageName: Images.admission, linkName: "/admission" },
//   { tabName: "Documents", imageName: Images.documents, linkName: "/documents" },
//   { tabName: "Email", imageName: Images.email, linkName: "" },
//   {
//     tabName: "Activities",
//     imageName: Images.activities,
//     linkName: "/activities"
//   },
//   {
//     tabName: "File Notes",
//     imageName: Images.documents,
//     linkName: "/file-notes"
//   },
//   {
//     tabName: "Accounts",
//     imageName: Images.accounts,
//     linkName: "/client-account"
//   },
//   { tabName: "Questionnaire", imageName: Images.questionnare, linkName: "" },
//   {  tabName: "Chat", imageName: Images.supplier, linkName: "" },
//   { tabName: "Print Case", imageName: Images.print, linkName: "" }
// ];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const visaColumns = [
  {
    title: "Visa",
    dataIndex: "branchVisaTypeName",
    key: "branchVisaTypeName",
  },
];

const { Option } = Select;

var userName = "";
class FileNotes extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      fileNoteString: "",
      selectedOption: null,
      headerOptions: [],
      showOnEdit: false,
      showIndex: null,
      template: "",
      editFileNotesString: "",
      profileData: null,
      LetterTemplatesRes: [],
      onCaseData: null,
      modalVisible: false,
      modal1Visible: false,
      selectedRowKeys: [],
      visaType: null,
      taskModal: false,
      addTaskLoader: false,
      usersData: [],
      selectedFileNote: null,
      loading: true,
      content: "",
      fileNotesTemplate: [],
      fileNotesDataState: [],
      pageNumber: 1,
      pageSize: 5,
      fileNotesCount: 0,
      selectedFileNotes: [],
      selectedFileNotesCont: "",
      showListView: false,
      modal2Visible: false,
      savedHistory: [],
      isModalVisible: false,
      coverLetterName: "",
      coverLetterList: [],
      findAndReplaceVisible: false,
      findWord: "",
      replaceWord: "",
      maxToken: 50,
      textMach: "",
      applyReplacement: false,
      templateValue: "",
      lettersData: [],
      fnrObj: {
        fnrRow: [
          {
            findWord: "",
            replaceWord: "",
          },
        ],
      },
      configCopy: {
        key:
          "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
        height: "auto",
        // pluginsEnabled: ['image'],
        toolbarSticky: false,
        events: {
          "charCounter.update": function() {
            console.log("oops");
          },
          "image.beforeUpload": function(files) {
            let editor = this;
            if (files.length) {
              // Create a File Reader.
              let reader = new FileReader();

              // Set the reader to insert images when they are loaded.
              reader.onload = function(e) {
                let result = e.target.result;

                editor.image.insert(result, null, null, editor.image.get());
              };
              // Read image as base64.
              reader.readAsDataURL(files[0]);
            }
            editor.popups.hideAll();
            // Stop default upload chain.
            return false;
          },
        },
      },
    };
    this.props.onSetActiveKey(null);

    let clientprofileid = sessionStorage.getItem("clientprofileid");

    let data = {
      visaType: this.state.visaType,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
    };
    this.getFileNotes();
    // let clientId = sessionStorage.getItem("clientprofileid");
    // this.props.onGetClientProfile(clientId);
    this.getCoverLetter();
    if (
      localStorage.getItem("userBranchPermissions") &&
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userBranchPermissions")))
        )
      ).branchPermission.find((x) => x.name.toLowerCase() == "covergenius")
        .status
    ) {
      this.setState({ showListView: true });
    }
    this.props.onGetLetterTemplates().then((res) => {
      this.setState({
        LetterTemplatesRes: res.payload,
      });
    });

    this.props.onGetLetterTemplates().then((res) => {
      this.setState({
        LetterTemplatesRes: res.payload,
      });
    });

    const VisaOpt = {
      url: `v1/subject/case/All/dropdown/` + clientprofileid,
    };
    VisaOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    apiRefresh.get(VisaOpt).then((res) => {
      res.items.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
        }
        this.setState({ onCaseData: res.items });
      });
    });

    let selectedTab = {
      headName: "Client Profile",
      headPath: "/profile",
    };
    this.props.onSetClientTab(selectedTab);
  }

  getCoverLetter = () => {
    let ClientId = sessionStorage.getItem("clientprofileid");
    const getCoverLetterOpt = {
      url: `v1/openAI/CoverLetter/${ClientId}`,
    };
    getCoverLetterOpt.types = [
      "GET_COVER_LETTER_SUCCESS",
      "GET_COVER_LETTER_FAILURE",
    ];

    apiRefresh
      .get(getCoverLetterOpt)
      .then((res) => {
        this.setState({ coverLetterList: res });
      })
      .catch((err) => {
        this.setState({ coverLetterList: [] });
      });
  };

  getFileNotes = (payloadData) => {
    this.setState({ loading: true });
    let pageNumber =
      payloadData && payloadData.pageNumber
        ? payloadData.pageNumber
        : this.state.pageNumber;
    let pageSize =
      payloadData && payloadData.pageSize
        ? payloadData.pageSize
        : this.state.pageSize;
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const fileNotesOpt = {
      url: `v1/client/filenote/All/${clientprofileid}/${pageNumber}/${pageSize}`,
    };
    fileNotesOpt.types = ["GET_FILE_NOTE_SUCCESS", "GET_FILE_NOTE_FAILURE"];

    apiRefresh.get(fileNotesOpt).then((res) => {
      if (res && res.items) {
        res.items = JSON.parse(res.items);
        if (res && res.items && res.items.length > 0) {
          this.setState({
            fileNotesDataState:
              payloadData && payloadData.pageNumber
                ? res.items.concat(this.state.fileNotesDataState)
                : this.state.fileNotesDataState.concat(res.items),
            fileNotesCount: res.count,
            pageNumber:
              payloadData && payloadData.pageNumber
                ? this.state.pageNumber
                : this.state.pageNumber + 1,
            loading: false,
          });
        } else {
          this.setState({
            fileNotesDataState: [],
            fileNotesCount: res.count,
            loading: false,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fileNotesData !== this.props.fileNotesData) {
      if (this.props.fileNotesData && this.props.fileNotesData.items) {
        this.setState({
          fileNotesDataState: this.state.fileNotesDataState.concat(
            this.props.fileNotesData.items
          ),
        });
      }
    }
    let usertype = sessionStorage.getItem("memberType");

    if (
      prevProps.clientProfileData != this.props.clientProfileData &&
      usertype == "client"
    ) {
      this.setProfileData(this.props.clientProfileData);
    }
    if (
      prevProps.partnerProfileData != this.props.partnerProfileData &&
      usertype == "spouse"
    ) {
      this.setProfileData(this.props.partnerProfileData);
    }
    if (
      prevProps.clientFamilyData != this.props.clientFamilyData &&
      usertype == "child"
    ) {
      let clientprofileid = sessionStorage.getItem("clientprofileid");
      let findClient = this.props.clientFamilyData.items.find(
        (obj) => obj.id == clientprofileid
      );
      if (findClient) {
        this.setProfileData(findClient);
      }
    }
    if (prevProps.profileDataRdx != this.props.profileDataRdx) {
      this.setState({ profileData: JSON.parse(this.props.profileDataRdx) });
    }
    if (prevProps.LetterTemplatesRes != this.props.LetterTemplatesRes) {
      let filterListLetters = [];
      this.props.LetterTemplatesRes.items.map((data, index) => {
        if (data.type === "LETTER") {
          data.index = index;
          data.key = `${index + 1}`;
          filterListLetters.push(data);
        }
      });
      this.setState({ lettersData: filterListLetters });
    }
    if (prevProps.LetterTemplatesRes != this.props.LetterTemplatesRes) {
      var filterList = [];
      if (
        this.props.LetterTemplatesRes &&
        this.props.LetterTemplatesRes.items &&
        this.props.LetterTemplatesRes.items.length > 0
      ) {
        this.props.LetterTemplatesRes.items.map((data, index) => {
          if (data.type === "FILENOTE") {
            data.index = index;
            data.key = `${index + 1}`;
            filterList.push(data);
          }
        });
        this.setState({ fileNotesTemplate: filterList });
      }
    }
  }

  setProfileData = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));
    this.setState({ profileData: profileData });

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
  };

  componentDidMount() {
    userName = localStorage.getItem("userName");
    this.getUsers();
    if (this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    } else {
      this.setState({
        headerOptions: [
          {
            tabName: "Client Profile",
            imageName: Images.clientProfile,
            linkName: "/profile",
          },
          { tabName: "Visas", imageName: Images.visas, linkName: "/visa-flow" },
          {
            tabName: "Admission",
            imageName: Images.admission,
            linkName: "/admission",
          },
          {
            tabName: "Documents",
            imageName: Images.documents,
            linkName: "/documents",
          },
          {
            tabName: "Email",
            imageName: Images.email,
            linkName: "/client-email",
          },
          {
            tabName: "Activities",
            imageName: Images.activities,
            linkName: "/activities",
          },
          {
            tabName: "File Notes",
            imageName: Images.documents,
            linkName: "/file-notes",
          },
          {
            tabName: "Accounts",
            imageName: Images.accounts,
            linkName: "/client-account",
          },
          {
            tabName: "Open Case Management",
            imageName: Images.caseManagement,
            linkName: "/Case-management",
          },
          {
            tabName: "Questionnaire",
            imageName: Images.questionnare,
            linkName: "/client-questionnaire",
          },
          { tabName: "Chat", imageName: Images.supplier, linkName: "" },
          { tabName: "Print Case", imageName: Images.print, linkName: "" },
        ],
      });
    }
    // var data = JSON.parse(this.props.profileDataRdx);
    let clientId = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let memberType = sessionStorage.getItem("memberType");
    if (memberType == "client") {
      this.props.onGetClientProfile(clientId);
    } else if (memberType == "spouse") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetPartner(data);
    } else if (memberType == "child") {
      let data = {
        clientId: clientProfileIdMain,
        familyId: familyId,
      };
      this.props.onGetClientFamily(data);
    }
  }

  rowSelection = {
    fixed: "right",
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRowKeys: selectedRows });
    },
  };
  handleCancel = () => {
    this.setState({ showOnEdit: false });
  };

  tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  getUsers = () => {
    // this.props.onGetAllUsers().then((res) => {
    //   const usersInfo = res.payload.users.map((item) => {
    //     return {
    //       label: item.fullName,
    //       value: item.id,
    //     };
    //   });
    //   this.setState({ usersData: usersInfo });
    // });
    this.props.onGetPermProcessingPerson(true).then((res) => {
      const usersInfoList = res.payload.users.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
        };
      });
      this.setState({ usersData: usersInfoList });
    });
  };

  onFinish = (values) => {
    this.setState({ addTaskLoader: true });
    let followers = [];
    const clientId = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: clientId ? clientId : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: false,
      isCompleted: false,
    };

    this.props
      .onAddDailyTasks(data)
      .then((res) => {
        this.setState({ addTaskLoader: false });
        message.success("Successfully Added!");
        if (this.formRef && this.formRef.current)
          this.formRef.current.resetFields();
        this.setState({ taskModal: false });
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "\n client file note : " +
              values.task_description +
              "Moved to Task titled " +
              data.taskTitle +
              "  by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Tasks",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch((error) => {
        if (this.formRef && this.formRef.current)
          this.formRef.current.resetFields();
        this.setState({ taskModal: false });
        message.error("Unable to Add Task!");
      });
  };

  addFileNote = () => {
    var userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (this.state.fileNoteString) {
      if (clientprofileid) {
        let data = {
          clientId: clientprofileid,
          FileNote: this.state.fileNoteString,
          createdBy: userId,
        };
        this.props
          .onAddFileNotes(data)
          .then(() => {
            this.getFileNotes({ pageNumber: 1, pageSize: 1 });
            message.success("File note create successfully");
            let payload = {
              visaType: this.state.visaType,
              pageNumber: 1,
              pageSize: this.state.pageSize,
            };
            // this.props.onGetFileNotes(payload).then((res) => {
            //   this.setState({ fileNotesCount: res.payload.count });
            // });
            let userName = localStorage.getItem("userName");
            var profileData = JSON.parse(this.props.profileDataRdx);
            if (profileData) {
              let myData = {
                clientName: profileData.fullName,
                logMessage:
                  "client file note: " +
                  this.state.fileNoteString +
                  " Added by " +
                  userName,
                date: moment(new Date()).format("DD/MM/YYYY"),
                logType: "Client FileNote",
                invoiceId: "0",
              };
              activityData(myData);
            }
            this.setState({ fileNoteString: "" });
          })
          .catch((err) => {
            message.error("Failed to create file notes");
          });
      } else {
        message.error("Please search and select client first!");
      }
    } else {
      message.warning("Add some text first");
    }
  };

  onRemoveFileNote = (FileNote, index) => {
    this.setState({ loading: true });
    var userId = localStorage.getItem("userId");
    let data = {
      id: FileNote.Id,
      delete: true,
    };
    this.props.onDeleteFileNotes(data).then(() => {
      message.success("File Note deleted successfully");
      let payload = {
        visaType: this.state.visaType,
        pageNumber: 1,
        pageSize: this.state.pageSize,
      };
      var fileNotes = [...this.state.fileNotesDataState];
      fileNotes.splice(index, 1);
      this.setState({
        fileNotesDataState: fileNotes,
      });
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      if (profileData) {
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            "client file note: " +
            FileNote.FileNote +
            " Deleted by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client FileNote",
          invoiceId: "0",
        };
        activityData(myData);
      }
      this.setState({ loading: false });
    });
  };
  LinkFileNote = () => {
    var id = this.state.fileNotesDataState[this.state.showIndex].Id;
    const docTypeOpt = {
      url: `v1/client/filenote/linkvisa`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];

    this.state.selectedRowKeys.map((item, index) => {
      var data = new Object();
      data.fileNotes = [];
      data.fileNotes.push(id);
      data.subjectCaseId = item.id;

      apiRefresh.post(docTypeOpt, data).then((res) => {
        data.FileNote = [];
      });
    });
    this.setState({ modal1Visible: false });
  };
  updFileNote = () => {
    var id = this.state.selectedFileNote.Id;

    let clientprofileid = sessionStorage.getItem("clientprofileid");

    let userId = localStorage.getItem("userId");

    let data = {
      id: id,
      clientId: clientprofileid,
      FileNote: this.state.editFileNotesString,
    };

    this.props
      .onUpdateFileNotes(data)
      .then(() => {
        message.success("File notes updated successfully");
        var fileNotes = [...this.state.fileNotesDataState];
        fileNotes[
          this.state.showIndex
        ].FileNote = this.state.editFileNotesString;
        this.setState({ fileNotesDataState: fileNotes });

        // this.props.onGetFileNotes(payload).then((res) => {
        //   this.setState({ fileNotesCount: res.payload.count });
        // });
        this.handleCancel();
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        if (profileData) {
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "CLIENT FILE NOTE: " +
              this.state.fileNoteString +
              " updated by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client FileNote",
            invoiceId: "0",
          };
          this.setState({ modalVisible: false });
          activityData(myData);
        }
      })
      .catch(() => {
        this.setState({ modalVisible: false });
        message.error("Failed to update file notes");
      });
  };

  onContentChange = (model) => {
    this.setState({ content: model });
  };

  onChangeTemplate = (value) => {
    this.setState({ template: value });

    const getContractOpt = {
      url: `v1/template/${value}`,
    };

    getContractOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.get(getContractOpt).then((resp) => {
      if (resp) {
        let clientprofileid = sessionStorage.getItem("clientprofileid");
        var paramArray = [];
        var param1 = new Object();
        param1.key = "ClientId";
        param1.value = clientprofileid;
        paramArray.push(param1);
        var param2 = new Object();
        param2.key = "UserId";
        param2.value = localStorage.getItem("userId");
        paramArray.push(param2);
        var param3 = new Object();
        param3.key = "CurrentDate";
        param3.value = moment(new Date()).format("DD/MM/YYYY");
        paramArray.push(param3);
        var data = new Object();
        data.templateName = resp.content
          ? resp.content.replace(
              "@CurrentDate",
              moment(new Date()).format("DD/MM/YYYY")
            )
          : "";
        data.parameters = paramArray;
        const docTypeOpt = {
          url: `v1/HtmlTemplate/SetAnyTemplate`,
        };

        docTypeOpt.types = [
          types.GET_DOCUMENT_TYPE_SUCCESS,
          types.GET_DOCUMENT_TYPE_FAILURE,
        ];
        apiRefresh.post(docTypeOpt, data).then((res) => {
          this.setState({ fileNoteString: res });
        });
      }
    });
  };

  onVisaTypeTemplate = (value) => {
    this.setState({ visaType: value });
    if (value == 0) {
      value = null;
      this.setState({ visaType: null });
    }
    let payload = {
      visaType: value,
      pageNumber: 1,
      pageSize: this.state.pageSize,
    };
    this.props.onGetFileNotes(payload).then((res) => {
      this.setState({ fileNotesCount: res.payload.count });
    });
  };

  onShowMoreButton = () => {
    let payload = {
      visaType: null,
      pageNumber: this.state.pageNumber + 1,
      pageSize: this.state.pageSize,
    };
    this.setState({ loading: true });
    this.props
      .onGetFileNotes(payload)
      .then((res) => {
        this.setState({
          fileNotesCount: res.payload.count,
          pageNumber: this.state.pageNumber + 1,
          loading: false,
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  renderAddTaskModal = () => (
    <Modal
      title="Add Task"
      visible={this.state.taskModal}
      onCancel={() => this.setState({ taskModal: false })}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={this.state.addTaskLoader}>
        <Form
          name="basic"
          // initialValues={{
          //   task_description:
          //     this.state.selectedFileNote != null
          //       ? this.state.selectedFileNote.FileNote
          //       : "",
          // }}
          ref={this.formRef}
          {...layout}
          onFinish={this.onFinish}
        >
          <div className="add-tasks">
            <div>
              <div className="mdtr-modal-gray-cont">
                <div className="modal-parts">
                  <Form.Item
                    className="form-parts"
                    label="Task Title"
                    name="task_title"
                    rules={[{ required: true, message: "Required!" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="form-parts"
                    label="Task Description"
                    name="task_description"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} showCount maxLength={250} />
                    {/* <FroalaEditor
                      ref={(el) => {
                        this.state.configCopy = el;
                      }}
                      config={this.state.configCopy}
                      tag="textarea"
                      model={this.state.content}
                      style={{ width: 300 }}
                      onModelChange={(e) => this.setState({ content: e })}
                    /> */}
                  </Form.Item>
                  <Form.Item
                    className="form-parts"
                    label="Select Date"
                    name="select_date"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts multi-select-option"
                    label="Add Followers"
                    name="add_followers"
                    // rules={[{ message: "Please Add Followers!" }]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={this.tagRender}
                      style={{ width: "100%" }}
                      options={this.state.usersData}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-blue"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  isHTML = (str) => {
    const fragment = document.createRange().createContextualFragment(str);

    // remove all non text nodes from fragment
    fragment
      .querySelectorAll("*")
      .forEach((el) => el.parentNode.removeChild(el));

    // if there is textContent, then not a pure HTML
    return !(fragment.textContent || "").trim();
  };

  onAddTask = (index, FileNote) => {
    this.setState(
      {
        showIndex: index,
        content: FileNote.FileNote,
        selectedFileNote: FileNote,
        taskModal: true,
      },
      () => {
        let html = FileNote.FileNote;
        let div = document.createElement("div");
        div.innerHTML = html;
        let text = div.textContent || div.innerText || "";
        this.formRef.current.setFieldsValue({
          task_description: text,
        });
      }
    );
  };

  onUpdateFileNote = (index, FileNote) => {
    this.setState({
      modalVisible: true,
      showIndex: index,
      editFileNotesString: FileNote.FileNote,
      selectedFileNote: FileNote,
    });
  };

  linkVisa = (index, FileNote) => {
    this.setState({
      modal1Visible: true,
      showIndex: index,
      editFileNotesString: FileNote.FileNote,
      selectedFileNote: FileNote,
    });
  };

  onChangeCheckbox = (isCheck, FileNote) => {
    if (isCheck) {
      this.setState({
        selectedFileNotes: [...this.state.selectedFileNotes, FileNote],
      });
    } else {
      let fileNotes = [...this.state.selectedFileNotes];
      let remFileNote = fileNotes.findIndex((obj) => obj.id === FileNote.id);
      fileNotes.splice(remFileNote, 1);
      this.setState({ selectedFileNotes: fileNotes });
    }
  };

  onClickListView = () => {
    this.setState({ showListView: true });
  };

  savedHistoryStr = () => {
    let historyList = [...this.state.savedHistory];
    if (this.state.selectedFileNotesCont != "") {
      historyList.push({
        description: this.state.selectedFileNotesCont,
        notHtml: false,
      });
      var html = this.state.selectedFileNotesCont;
      var div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      this.setState({ savedHistory: historyList, selectedFileNotesCont: "" });
      let data = {
        prompt: text,
      };
      const chatGptLetterOpt = {
        url: `v1/openAI/ChatGPT`,
      };
      chatGptLetterOpt.types = [
        "ADD_COVER_LETTER_SUCCESS",
        "ADD_COVER_LETTER_FAILURE",
      ];
      this.setState({ loading: true });

      apiRefresh
        .post(chatGptLetterOpt, data)
        .then((res) => {
          this.setState({ loading: false });

          historyList.push({
            description: `${res.document}`,
            notHtml: true,
          });
          this.setState({ savedHistory: historyList });
        })
        .catch((err) => {
          console.log(err.response);
          if (err && err.response && err.response.status === 404) {
            message.error(
              "Token limit exceeded please request for more tokens!"
            );
          } else {
            message.error("Failed!");
          }
          this.setState({ loading: false });
        });
    } else {
      message.error("Failed!");
    }
  };

  handleCancelNameModal = () => {
    this.setState({ isModalVisible: false });
  };

  saveCoverLetter = () => {
    let data = {
      coverLetter: {
        clientId: sessionStorage.getItem("clientprofileid"),
        title: this.state.coverLetterName,
      },
      chatHistory: this.state.savedHistory,
    };
    const addCoverLetterOpt = {
      url: `v1/openAI/CoverLetter`,
    };
    addCoverLetterOpt.types = [
      "ADD_COVER_LETTER_SUCCESS",
      "ADD_COVER_LETTER_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh
      .post(addCoverLetterOpt, data)
      .then((res) => {
        message.success("Cover letter created successfuly!");
        this.handleCancelNameModal();
        this.setState({
          modal2Visible: false,
          selectedFileNotesCont: "",
          coverLetterName: "",
          loading: false,
        });
      })
      .catch((err) => {
        message.error("Failed!");
        this.setState({ loading: false });
      });
  };

  onSavePdf = () => {
    let data = {
      content: this.state.selectedFileNotesCont,
      fileName: "Cover_letter",
      subjectId: sessionStorage.getItem("clientprofileid"),
      familyId: sessionStorage.getItem("familyId"),
    };
    const addCoverLetterOpt = {
      url: `v1/openAI/SaveCoverLetter`,
    };
    addCoverLetterOpt.types = [
      "ADD_COVER_LETTER_SUCCESS",
      "ADD_COVER_LETTER_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh
      .postWithFileRes(addCoverLetterOpt, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        var blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "CoverLetter.pdf");
      })
      .catch((err) => {
        message.error("Failed!");
        this.setState({ loading: false });
      });
  };

  onChangeCoverLetter = (value) => {
    const getCoverLetterHisOpt = {
      url: `v1/openAI/ChatHistory/${value}`,
    };
    getCoverLetterHisOpt.types = [
      "GET_COVER_LETTER_HIS_SUCCESS",
      "GET_COVER_LETTER_HIS_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .get(getCoverLetterHisOpt)
      .then((res) => {
        this.setState({
          modal2Visible: true,
          savedHistory: res,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ savedHistory: [], loading: false });
      });
  };

  onFindAndReplace = (values) => {
    let fileNotecontState = this.state.selectedFileNotesCont;
    let findWords = "";

    if (values && values.fnrRow && values.fnrRow.length > 0) {
      for (var i = 0; i < values.fnrRow.length; i++) {
        if (values.fnrRow[i].findWord && values.fnrRow[i].replaceWord) {
          if (this.state.applyReplacement) {
            fileNotecontState = fileNotecontState.replace(
              new RegExp(values.fnrRow[i].replaceWord, "g"),
              values.fnrRow[i].findWord
            );
          } else {
            fileNotecontState = fileNotecontState.replace(
              new RegExp(values.fnrRow[i].findWord, "g"),
              values.fnrRow[i].replaceWord
            );
          }
        }
      }
    }
    this.setState({
      selectedFileNotesCont: fileNotecontState,
      findAndReplaceVisible: false,
      applyReplacement: !this.state.applyReplacement,
      fnrObj: values,
    });
    // if (this.state.findWord) {

    // } else {
    //   message.error("Please find text first!");
    // }
  };

  onContractChange = (id) => {
    this.setState({ templateValue: id, loading: true });
    var fileNoteCont = this.state.selectedFileNotesCont;
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh
      .get(getContractOpt)
      .then((res) => {
        this.setState({ templateValue: id });

        if (res) {
          let clientprofileid = sessionStorage.getItem("clientprofileid");
          var paramArray = [];
          var param1 = new Object();
          param1.key = "ClientId";
          param1.value = clientprofileid;
          paramArray.push(param1);
          var param2 = new Object();
          param2.key = "UserId";
          param2.value = localStorage.getItem("userId");
          paramArray.push(param2);
          var param3 = new Object();
          param3.key = "CurrentDate";
          param3.value = moment(new Date()).format("DD/MM/YYYY");
          paramArray.push(param3);
          var data = new Object();
          data.templateName = res.content
            ? res.content.replace(
                "@CurrentDate",
                moment(new Date()).format("DD/MM/YYYY")
              )
            : "";
          data.parameters = paramArray;

          const docTypeOpt = {
            url: `v1/HtmlTemplate/SetAnyTemplate`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
          apiRefresh
            .post(docTypeOpt, data)
            .then((response) => {
              fileNoteCont = response + "<br/>" + fileNoteCont;
              this.setState({
                selectedFileNotesCont: fileNoteCont,
                loading: false,
              });
            })
            .catch((err) => {
              this.setState({ loading: false });
              message.error("Failed!");
            });
          const docTypeOpt1 = {
            url: `v1/template/Attachments/All/` + id,
          };
          docTypeOpt1.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
        }

        // let template = lettersData.find((obj) => obj.id == id);
        //
        // if (template) {
        //   let data = {
        //     id: id,
        //     content: template.content,
        //     isAttachment: true,
        //     header: template.header,
        //   };
        //
        //   setTimeout(() => {
        //     setContractsData((prevState) => [data, ...prevState]);
        //   }, 500);
        //
        //   onAddAttachment(true, data);
        // }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onGetFileNotePdf = () => {
    let data = {
      clientId: sessionStorage.getItem("clientprofileid"),
    };
    this.setState({
      loading: false,
    });
    this.props
      .onGetClientFileNotesPdf(data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        var blob = new Blob([res.payload], {
          type: "application/octet-stream",
        });
        saveAs(blob, "File_Notes.pdf");
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Failed!");
      });
  };

  render() {
    const {
      selectedOption,
      fileNoteString,
      headerOptions,
      editFileNotesString,
      showOnEdit,
      showIndex,
      profileData,
      content,
      config,
      fileNotesTemplate,
      fileNotesDataState,
      showListView,
      selectedFileNotes,
      selectedFileNotesCont,
      savedHistory,
      templateValue,
      lettersData,
    } = this.state;
    const {
      LetterTemplatesRes,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      employerJobHistoryCurrentRes,
      onGetClientJobHistoryCurrent,
      onSetActiveKey,
      activeKey,
      onGetReminder,
      onGetVisaStatus,
      onGetAdmissionProgram,
      onGetAdmissionStatuses,
      onGetVisaType,
      onGetVisaTypeByCountry,
      branchPermissionRes,
    } = this.props;

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs data={headerOptions} activeTab="File Notes" />
              )}
              <div style={{ display: "flex", margin: 10 }}>
                <div
                  // className={
                  //   activeKey
                  //     ? "content-width-open-sidebar"
                  //     : "content-width-close-sidebar"
                  // }
                  style={{
                    marginTop: 13,
                    width:
                      !showListView && !activeKey
                        ? `calc(100% - 140px)`
                        : showListView
                        ? "63%"
                        : `calc(100% - 458px)`,
                  }}
                >
                  {profileData ? (
                    <div style={{ width: "100%", marginTop: -10 }}>
                      <p>Client Name: {profileData && profileData.fullName}</p>
                    </div>
                  ) : (
                    <div style={{ height: 30 }} />
                  )}
                  <div
                    className={"file-notes-container"}
                    style={{ marginLeft: 0, marginRight: 0 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        class="select-options"
                        style={{ width: "64%", border: 0, display: "flex" }}
                      >
                        <div style={{ width: "50%" }}>
                          <Select
                            style={{ width: "100%" }}
                            onChange={this.onChangeTemplate}
                            placeholder="Templates"
                          >
                            {fileNotesTemplate &&
                              fileNotesTemplate.map((template, index) => {
                                return (
                                  <Option value={template.id}>
                                    {template.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div style={{ width: "50%", marginLeft: "10px" }}>
                          <Select
                            style={{ width: "100%" }}
                            onChange={this.onVisaTypeTemplate}
                            placeholder="Visa Types"
                          >
                            <Option value={0}>All</Option>
                            {this.state.onCaseData &&
                              this.state.onCaseData.map((template, index) => (
                                <Option value={template.id}>
                                  {template.branchVisaTypeName}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div
                          className="profile-print-box"
                          onClick={this.onGetFileNotePdf}
                        >
                          <img
                            src={Images.printWhite}
                            className="profile-print-icon"
                          />
                        </div>
                      </div>
                    </div>
                    <Row style={{ marginTop: "3vh", marginBottom: "3vh" }}>
                      <Col>
                        <FroalaEditorCom
                          model={fileNoteString}
                          onModelChange={(e) =>
                            this.setState({ fileNoteString: e })
                          }
                        />
                      </Col>
                    </Row>

                    <div
                      className="button-container"
                      style={{ paddingLeft: 0, paddingTop: 0 }}
                    >
                      <Button
                        onClick={this.addFileNote}
                        className="add-file-note-btn margin-top-12 button-blue"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "#FFFFFF", fontSize: 12 }}>
                          Save
                        </span>
                      </Button>
                    </div>

                    <div className="file-notes-content">
                      {fileNotesDataState.map((FileNote, index) => {
                        // FileNote.FileNote = JSON.parse(FileNote.FileNote);
                        let html = FileNote.FileNote;
                        var checkIsHtml = this.isHTML(FileNote.FileNote);
                        if (!checkIsHtml || FileNote.FileNote[0] != "<") {
                          html = "<p>" + FileNote.FileNote + "</p>";
                        }
                        // let re = /</g
                        // let _text = html.split('<');
                        let regex = /\S+[a-z0-9]@[a-z0-9\.]+/gim;
                        let resultArr = html.match(regex);

                        if (resultArr && resultArr.length > 0) {
                          for (let ind = 0; ind < resultArr.length; ind++) {
                            if (resultArr[ind][0] === "<") {
                              let filteredValue = resultArr[ind].replace(
                                /</g,
                                "< "
                              );
                              html = html.replace(
                                resultArr[ind],
                                filteredValue
                              );
                            } else if (
                              resultArr[ind][0] === "<" &&
                              resultArr[ind][1] === "/"
                            ) {
                              let filteredValue = resultArr[ind].replace(
                                "/",
                                " "
                              );
                              html = html.replace(
                                resultArr[ind],
                                filteredValue
                              );
                            } else {
                            }
                          }
                        }
                        let gap = html;
                        return (
                          <div>
                            <ClientFileNotes
                              index={index}
                              FileNote={FileNote}
                              onRemoveFileNote={this.onRemoveFileNote}
                              setState={this.setState}
                              gap={gap}
                              onAddTask={this.onAddTask}
                              onUpdateFileNote={this.onUpdateFileNote}
                              linkVisa={this.linkVisa}
                              onChangeCheckbox={this.onChangeCheckbox}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {this.state.fileNotesCount > 5 &&
                      this.state.fileNotesDataState.length !==
                        this.state.fileNotesCount && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#56B1F9",
                              fontWeight: "500",
                              cursor: "pointer",
                            }}
                            onClick={this.getFileNotes}
                          >
                            Show More
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                {!showListView && (
                  <div
                    className=""
                    style={{
                      width: activeKey ? "438px" : "115px",
                      marginLeft: 15,
                    }}
                  >
                    <ProfileSideBar
                      onGetClientTag={this.props.onGetClientTag}
                      clientTagRes={this.props.clientTagRes}
                      getClientRes={this.props.getClientRes}
                      onGetProfileClientTag={this.props.onGetProfileClientTag}
                      onAddProfileClientTag={this.props.onAddProfileClientTag}
                      addProfielTagRes={this.props.addProfielTagRes}
                      onRemoveProfileClientTag={
                        this.props.onRemoveProfileClientTag
                      }
                      removeProfileTagRes={this.props.removeProfileTagRes}
                      visaStatusData={visaStatusData}
                      onUpdateCaseStatus={onUpdateCaseStatus}
                      visaAppData={visaAppData}
                      onGetVisaApplication={onGetVisaApplication}
                      onGetClientJobHistoryCurrent={
                        onGetClientJobHistoryCurrent
                      }
                      employerJobHistoryCurrentRes={
                        employerJobHistoryCurrentRes
                      }
                      onSetActiveKey={onSetActiveKey}
                      activeKey={activeKey}
                      onGetClientTask={this.props.onGetClientTask}
                      onUpdateTask={this.props.onUpdateTask}
                      onGetAllUsers={this.props.onGetAllUsers}
                      onAddTaskFollower={this.props.onAddTaskFollower}
                      onRemoveTasks={this.props.onRemoveTasks}
                      onAddDailyTasks={this.props.onAddDailyTasks}
                      onGetClientFamily={this.props.onGetClientFamily}
                      onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                      onAddTaskFileNote={this.props.onAddTaskFileNote}
                      onAddTaskComment={this.props.onAddTaskComment}
                      onGetTaskComments={this.props.onGetTaskComments}
                      onGetTaskFollowers={this.props.onGetTaskFollowers}
                      onGetReminder={onGetReminder}
                      onGetVisaStatus={onGetVisaStatus}
                      onGetAdmissionProgram={onGetAdmissionProgram}
                      onGetAdmissionStatuses={onGetAdmissionStatuses}
                      onGetVisaType={onGetVisaType}
                      onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                      showListView={true}
                      onClickListView={this.onClickListView}
                    />
                  </div>
                )}
                {showListView && (
                  <div style={{ marginLeft: 20, width: "35%" }}>
                    <div className="cv-top-heading">
                      <div>
                        <span className="cv-bold-text">Cover Genius</span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            showListView: false,
                          })
                        }
                      >
                        <span className="cv-normal-text">Show Menu</span>
                      </div>
                    </div>
                    <div
                      className="cv-gray-cont"
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        paddingTop: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                        minHeight: 500,
                        backgroundColor: "#FFF",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          disabled={selectedFileNotes.length === 0}
                          className="button-blue"
                          onClick={() => {
                            this.setState({ modal2Visible: true });
                            let text = "";
                            selectedFileNotes.map((note, ind) => {
                              text += note.FileNote + "<br/>";
                            });
                            this.setState({ selectedFileNotesCont: text });
                          }}
                        >
                          Create Cover Letter
                        </Button>
                      </div>

                      <div>
                        <Select
                          style={{ width: "100%", marginTop: 20 }}
                          onChange={this.onChangeCoverLetter}
                          placeholder="Cover Letters List"
                        >
                          {this.state.coverLetterList &&
                            this.state.coverLetterList.map(
                              (coverLetter, index) => {
                                return (
                                  <Option value={coverLetter.id}>
                                    {coverLetter.title}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </div>
                      <div>
                        {selectedFileNotes.length > 0 &&
                          selectedFileNotes.map((fileNote, ind) => {
                            let html = fileNote.FileNote;
                            var checkIsHtml = this.isHTML(fileNote.FileNote);
                            if (!checkIsHtml || fileNote.FileNote[0] != "<") {
                              html = "<p>" + fileNote.FileNote + "</p>";
                            }
                            // let re = /</g
                            // let _text = html.split('<');
                            let regex = /\S+[a-z0-9]@[a-z0-9\.]+/gim;
                            let resultArr = html.match(regex);

                            if (resultArr && resultArr.length > 0) {
                              for (let ind = 0; ind < resultArr.length; ind++) {
                                if (resultArr[ind][0] === "<") {
                                  let filteredValue = resultArr[ind].replace(
                                    /</g,
                                    "< "
                                  );
                                  html = html.replace(
                                    resultArr[ind],
                                    filteredValue
                                  );
                                } else if (
                                  resultArr[ind][0] === "<" &&
                                  resultArr[ind][1] === "/"
                                ) {
                                  let filteredValue = resultArr[ind].replace(
                                    "/",
                                    " "
                                  );
                                  html = html.replace(
                                    resultArr[ind],
                                    filteredValue
                                  );
                                } else {
                                }
                              }
                            }
                            let gap = html;
                            return (
                              <div
                                className="form-container"
                                style={{
                                  marginLeft: 0,
                                  marginRight: 0,
                                  padding: 8,
                                }}
                              >
                                <div className="content-text-file-note no-copy-selection">
                                  {renderHTML(gap)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            title={false}
            style={{ top: 20 }}
            visible={this.state.modalVisible}
            onOk={() => this.updFileNote()}
            onCancel={() => this.setState({ modalVisible: false })}
            Header={false}
          >
            <Row style={{ marginTop: "3vh", marginBottom: "3vh" }}>
              <Col>
                <FroalaEditorCom
                  model={this.state.editFileNotesString}
                  onModelChange={(e) =>
                    this.setState({ editFileNotesString: e })
                  }
                />
              </Col>
            </Row>
          </Modal>
          <Modal
            title={false}
            style={{ top: 20 }}
            visible={this.state.modal2Visible}
            // onOk={() => this.updFileNote()}
            onCancel={() =>
              this.setState({
                modal2Visible: false,
                selectedFileNotesCont: "",
              })
            }
            footer={false}
            Header={false}
            width="90%"
          >
            <Spin spinning={this.state.loading}>
              {savedHistory &&
                savedHistory.map((history, index) => {
                  let gap;

                  // if (history.notHtml) {
                  //   gap = history.description;
                  // } else {
                  let html = history.description;
                  var checkIsHtml = this.isHTML(history.description);
                  if (!checkIsHtml || history.description[0] != "<") {
                    html = history.description;
                  }

                  // let re = /</g
                  // let _text = html.split('<');
                  let regex = /\S+[a-z0-9]@[a-z0-9\.]+/gim;
                  let resultArr = html.match(regex);

                  if (resultArr && resultArr.length > 0) {
                    for (let ind = 0; ind < resultArr.length; ind++) {
                      if (resultArr[ind][0] === "<") {
                        let filteredValue = resultArr[ind].replace(/</g, "< ");
                        html = html.replace(resultArr[ind], filteredValue);
                      } else if (
                        resultArr[ind][0] === "<" &&
                        resultArr[ind][1] === "/"
                      ) {
                        let filteredValue = resultArr[ind].replace("/", " ");
                        html = html.replace(resultArr[ind], filteredValue);
                      } else {
                      }
                    }
                  }
                  gap = html;
                  // }
                  return (
                    <div
                      className="form-container"
                      style={{
                        padding: 15,
                        width: "100%",
                        margin: 0,
                        marginTop: 20,
                        backgroundColor: history.notHtml
                          ? "#f5f7f8"
                          : "#DDDFDF",
                      }}
                    >
                      <Row
                        style={{
                          justifyContent: "flex-end",
                          marginBottom: 10,
                        }}
                      >
                        <Col>
                          <img
                            src={Images.nextGray}
                            onClick={() => {
                              this.setState({
                                selectedFileNotesCont: `<div>${gap}</div>`,
                              });
                            }}
                            style={{
                              transform: `rotate(90deg)`,
                              width: 20,
                              height: 20,
                              cursor: "pointer",
                            }}
                          />
                        </Col>
                        <Col style={{ marginLeft: 10 }}>
                          <img
                            src={Images.deleteGray}
                            style={{
                              width: 20,
                              height: 20,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let historyArray = [...savedHistory];
                              historyArray.splice(index, 1);
                              this.setState({
                                savedHistory: historyArray,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {history.notHtml ? (
                          <Col className="no-copy-selection">
                            {renderHTML(gap)}
                          </Col>
                        ) : (
                          <Col className="no-copy-selection">
                            {renderHTML(gap)}
                          </Col>
                        )}
                      </Row>
                    </div>
                  );
                })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "8vh",
                  alignItems: "center",
                }}
              >
                <Select
                  showSearch
                  style={{ width: 250 }}
                  placeholder="LETTERS"
                  optionFilterProp="children"
                  value={templateValue}
                  onChange={(val) => this.onContractChange(val)}
                  dropdownMatchSelectWidth={false}
                >
                  <Option key="">Letters</Option>
                  {lettersData &&
                    lettersData.map((item) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                </Select>
                <Button
                  onClick={() => this.setState({ findAndReplaceVisible: true })}
                  className="add-file-note-btn button-blue"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  disabled={this.state.selectedFileNotesCont ? false : true}
                >
                  Find And Replace
                </Button>
              </div>
              <Row style={{ marginTop: "2vh", marginBottom: "3vh" }}>
                <Col>
                  <FroalaEditorCom
                    model={selectedFileNotesCont}
                    onModelChange={(e) => {
                      this.setState({ selectedFileNotesCont: e });
                    }}
                  />
                </Col>
              </Row>
              <div
                className="button-container"
                style={{
                  paddingLeft: 0,
                  paddingTop: 0,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={this.savedHistoryStr}
                  className="add-file-note-btn margin-top-12 button-blue"
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ color: "#FFFFFF", fontSize: 12 }}>Submit</span>
                </Button>
                <Button
                  onClick={() => this.setState({ isModalVisible: true })}
                  className="add-file-note-btn margin-top-12 button-blue"
                  style={{ marginLeft: 5 }}
                  disabled={selectedFileNotesCont == "" ? false : true}
                >
                  <span style={{ color: "#FFFFFF", fontSize: 12 }}>Save</span>
                </Button>
                <Button
                  onClick={this.onSavePdf}
                  className="add-file-note-btn margin-top-12 button-blue"
                  style={{ cursor: "pointer", marginLeft: 5 }}
                >
                  <span style={{ color: "#FFFFFF", fontSize: 12 }}>
                    Save PDF
                  </span>
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      modal2Visible: false,
                      selectedFileNotesCont: "",
                    })
                  }
                  className="add-file-note-btn margin-top-12 button-blue"
                  style={{ cursor: "pointer", marginLeft: 5 }}
                >
                  <span style={{ color: "#FFFFFF", fontSize: 12 }}>Cancel</span>
                </Button>
              </div>
            </Spin>
          </Modal>
          {this.state.modal1Visible ? (
            <Modal
              title={false}
              style={{ top: 20 }}
              visible={this.state.modal1Visible}
              onOk={this.LinkFileNote}
              onCancel={() => this.setState({ modal1Visible: false })}
              Header={false}
            >
              <Table
                className="border-3 "
                rowSelection={this.rowSelection}
                showCount={true}
                columns={visaColumns}
                dataSource={this.state.onCaseData}
                pagination={false}
              />
            </Modal>
          ) : null}
          {this.state.findAndReplaceVisible ? (
            <Modal
              title={false}
              style={{ top: 20 }}
              visible={this.state.findAndReplaceVisible}
              // onOk={this.LinkFileNote}
              onCancel={() => this.setState({ findAndReplaceVisible: false })}
              Header={false}
              footer={false}
            >
              <Form
                onFinish={this.onFindAndReplace}
                initialValues={this.state.fnrObj}
              >
                <Form.List name="fnrRow">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <>
                            <FieldsRow
                              field={field}
                              fields={fields}
                              selectedFileNotesCont={
                                this.state.selectedFileNotesCont
                              }
                              add={() => add()}
                              remove={() => remove()}
                              index={index}
                            />
                          </>
                        ))}
                      </div>
                    );
                  }}
                </Form.List>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Form.Item>
                    <Button
                      // onClick={this.onFindAndReplace}
                      className="add-file-note-btn margin-top-12 button-blue"
                      style={{ cursor: "pointer", marginTop: "8vh" }}
                      htmlType="submit"
                    >
                      {this.state.applyReplacement ? "Undo" : "Apply"}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      onClick={() =>
                        this.setState({ findAndReplaceVisible: false })
                      }
                      className="add-file-note-btn margin-top-12 button-blue"
                      style={{
                        cursor: "pointer",
                        marginTop: "8vh",
                        marginLeft: 5,
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          ) : null}
          <Modal
            title="Cover Letter Name"
            visible={this.state.isModalVisible}
            onOk={this.saveCoverLetter}
            onCancel={this.handleCancelNameModal}
            okText="SAVE"
          >
            <Spin spinning={this.state.loading}>
              <div className="profile-input-border" style={{ marginTop: 5 }}>
                <input
                  className="profile-input"
                  placeholder="Name"
                  type="text"
                  value={this.state.coverLetterName}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    this.setState({ coverLetterName: e.target.value })
                  }
                />
              </div>
            </Spin>
          </Modal>
        </Spin>
        {this.renderAddTaskModal()}
      </div>
    );
  }
}

export default FileNotes;
