import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const dashboardStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_STUDENTS_SUCCESS:
      return { ...state, dashboardStudentsList: action.payload };
    case types.GET_DASHBOARD_STUDENTS_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, dashboardStudentsError: action.payload };
    default:
      return state;
  }
};
