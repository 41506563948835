import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../Components/Header/HeaderBar";
import Sidebar from "../../Components/SideBar";
import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Button,
  Select,
  message,
  Input,
  Tooltip,
  Checkbox,
} from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";

const { Option } = Select;

const ReminderSetting = ({
  onAddReminderSettings,
  addReminderSettingsRes,

  onGetReminderSettings,
  reminderSettingRes,
  activeTabName,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(true);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [reminderexpiry, setReminderexpiry] = useState("");
  const [visaExpairing, setVisaExpairing] = useState("");
  const [ppiSettings, setPpiSettings] = useState("");
  const [travelCondition, setTravelCondition] = useState("");
  const [policeCer, setPoliceCer] = useState("");
  const [medicalCer, setMedicalCer] = useState("");
  const [xrayDate, setXrayDate] = useState("");
  const [advertisingEx, setAdvertisingEx] = useState("");
  const [skillMates, setSkillMates] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");
  const [birthdayReminder, setBirthdayReminder] = useState("");
  const state = useLocation().state;

  const [form] = Form.useForm();
  useEffect(() => {
    // setLoading(true);
    getReminderSettings();
  }, []);

  const getReminderSettings = () => {
    onGetReminderSettings()
      .then((res) => {
        let reminderData = JSON.parse(res.payload.settingsJson);

        form.setFieldsValue({
          AdvertisingExpiry: reminderData.AdvertisingExpiry,
          Followup: reminderData.Followup,
          ReminderSetting: reminderData.ReminderSetting,
          SkillMateExpiry: reminderData.SkillMateExpiry,
          XrayExpiry: reminderData.XrayExpiry,
          medicalcertexpiry: reminderData.medicalcertexpiry,
          passportExpiry: reminderData.passportExpiry,
          policecertexpiry: reminderData.policecertexpiry,
          ppireminder: reminderData.ppireminder,
          tcreminder: reminderData.tcreminder,
          visareminder: reminderData.visareminder,
          ReminderEmail: reminderData.ReminderEmail || "",
          isBirthdayEmail: res.payload.isBirthdayEmail || false,
          accreditationreminder: reminderData.accreditationreminder || "",
          jobcheckreminder: reminderData.jobcheckreminder || "",
          isBirthdayuserEmail: res.payload.isBirthdayuserEmail || false,
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.payload.response.status == 404) {
          let data = {
            AdvertisingExpiry: "30",
            Followup: "30",
            ReminderSetting: "1",
            SkillMateExpiry: "30",
            XrayExpiry: "30",
            medicalcertexpiry: "30",
            passportExpiry: "30",
            policecertexpiry: "30",
            ppireminder: "21",
            tcreminder: "30",
            visareminder: "30",
            ReminderEmail: "",
          };
          var myJson = JSON.stringify(data);
          let req = {
            id: 0,
            settingsJson: myJson,
          };
          onAddReminderSettings(req)
            .then((res) => {
              // message.success("Updated!");
              getReminderSettings();
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              message("Failed!");
            });
        }
      });
  };

  const onReminderChange = (value) => {
    setReminderexpiry(value);
  };
  const onVisaeChange = (value) => {
    setVisaExpairing(value);
  };
  const onPiChange = (value) => {
    setPpiSettings(value);
  };
  const onTravelChange = (value) => {
    setTravelCondition(value);
  };
  const onPoliceExpiryChange = (value) => {
    setPoliceCer(value);
  };
  const onMedicalChange = (value) => {
    setMedicalCer(value);
  };
  const oXrayChange = (value) => {
    setXrayDate(value);
  };
  const onAdvertisingChange = (value) => {
    setAdvertisingEx(value);
  };
  const onSkillChange = (value) => {
    setSkillMates(value);
  };
  const onFollowUpChange = (value) => {
    setFollowUpDate(value);
  };

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    setLoadUpdate(true);
    const data = {
      AdvertisingExpiry: values.AdvertisingExpiry || "",
      Followup: values.Followup || "",
      ReminderSetting: values.ReminderSetting || "",
      SkillMateExpiry: values.SkillMateExpiry,
      XrayExpiry: values.XrayExpiry || "",
      medicalcertexpiry: values.medicalcertexpiry || "",
      passportExpiry: values.passportExpiry || "",
      policecertexpiry: values.policecertexpiry || "",
      ppireminder: values.ppireminder || "",
      tcreminder: values.tcreminder || "",
      visareminder: values.visareminder || "",
      ReminderEmail: values.ReminderEmail || "",
      // ClientBirthday: values.ClientBirthday || false,
      accreditationreminder: values.accreditationreminder || false,
      jobcheckreminder: values.jobcheckreminder || false,
      // isBirthdayuserEmail: values.isBirthdayuserEmail || false,
    };
    var myJson = JSON.stringify(data);

    const result = {
      settingsJson: myJson,
      isBirthdayuserEmail: values.isBirthdayuserEmail || false,
      isBirthdayEmail: values.isBirthdayEmail || false,
    };

    onAddReminderSettings(result)
      .then((res) => {
        message.success("Updated!");
        setLoadUpdate(false);
      })
      .catch((err) => {
        setLoadUpdate(false);
        message("Failed!");
      });
  };

  const onChange = (e) => {
    setBirthdayReminder(e.target.checked);
  };

  return (
    <Fragment>
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img src={Images.reminders} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">Reminder Settings</span>
                  <Tooltip
                    placement="topLeft"
                    title={`Select when you want Ezymigrate to send you a reminder about the specific documents expiry dates below.`}
                  >
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>
                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="reminder-settings" style={{ paddingTop: 20 }}>
                <div className="client-tag-form"></div>
                <div>
                  <div className="client-tag-table">
                    <div style={{ marginBottom: 20 }}>
                      <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                        Select when you want Ezymigrate to send you a reminder
                        about then specific documents expiry dates below
                      </span>
                    </div>
                    <Form
                      name="reminder-settings-form"
                      onFinish={onFinish}
                      form={form}
                    >
                      <div className="reminder-setting-form">
                        <h4>REMINDER SETTINGS</h4>
                        <Form.Item
                          name="ReminderSetting"
                          label="Please Select who receives the Reminder"
                        >
                          <Select>
                            <Option value="1">
                              branch email + company owner email +processing
                              person
                            </Option>
                            <Option value="2">
                              branch manager+processing person
                            </Option>
                            <Option value="3">
                              reminder email + processing email
                            </Option>
                            <Option value="4">processing person</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4>REMINDER EMAIL</h4>
                        <Form.Item
                          name="ReminderEmail"
                          label="Send Email To"
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4>PASSPORT EXPIRING</h4>
                        <Form.Item name="passportExpiry" label="Remind me when">
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="45">45 Days</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="180">6 Months</Option>
                            <Option value="365">12 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in client's passport expiry</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>VISA EXPIRING</h4>
                        <Form.Item name="visareminder" label="Remind me when">
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="45">45 Days</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="180">6 Months</Option>
                            <Option value="365">12 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in client's visa expiry</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>PPI</h4>
                        <Form.Item name="ppireminder" label="Remind me when">
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="6">6 Days</Option>
                            <Option value="14">2 Weeks</Option>
                            <Option value="21">3 Weeks</Option>
                          </Select>
                        </Form.Item>
                        <span>left in client's PPI letter date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          TRAVEL CONDITIONS VALID TO
                        </h4>
                        <Form.Item name="tcreminder" label="Remind me when">
                          <Select onChange={onTravelChange}>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="6">6 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                          </Select>
                        </Form.Item>
                        <span> left in travel conditions valid to</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          POLICE CERTIFICATE EXPIRY DATE
                        </h4>
                        <Form.Item
                          name="policecertexpiry"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="6">6 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">3 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in police certificate expiry</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          MEDICAL CERTIFICATE EXPIRY DATE
                        </h4>
                        <Form.Item
                          name="medicalcertexpiry"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in medical certificate expiry</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>XRAY EXPIRY DATE</h4>
                        <Form.Item name="XrayExpiry" label="Remind me when">
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in x-ray expiry date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>ADVERTISING EXPIRY</h4>
                        <Form.Item
                          name="AdvertisingExpiry"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in advertising expiry date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          SKILL MATCH REPORT EXPIRY
                        </h4>
                        <Form.Item
                          name="SkillMateExpiry"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in skill mate report expiry date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34 d-flex">
                        <h4 style={{ paddingBottom: 0 }}></h4>
                        <Form.Item
                          name="isBirthdayEmail"
                          valuePropName="checked"
                        >
                          <Checkbox
                          // checked={birthdayReminder}
                          // onChange={onChange}
                          >
                            Auto Birthday Wish to Client
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="isBirthdayuserEmail"
                          valuePropName="checked"
                          style={{ marginLeft: 20 }}
                        >
                          <Checkbox
                          // checked={birthdayReminder}
                          // onChange={onChange}
                          >
                            Client Birthday Reminder ( User)
                          </Checkbox>
                        </Form.Item>
                        {/* <span>left in skill mate report expiry date</span> */}
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>FOLLOW UP DATE</h4>
                        <Form.Item name="Followup" label="Remind me when">
                          <Select>
                            <Option value="0">Same Day</Option>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in follow up date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          ACCREDITATION EXPIRY DATE
                        </h4>
                        <Form.Item
                          name="accreditationreminder"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="0">Same Day</Option>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in accreditation expiry date</span>
                      </div>
                      <div className="reminder-setting-form margin-top-34">
                        <h4 style={{ paddingBottom: 0 }}>
                          JOB CHECK EXPIRY DATE
                        </h4>
                        <Form.Item
                          name="jobcheckreminder"
                          label="Remind me when"
                        >
                          <Select>
                            <Option value="0">Same Day</Option>
                            <Option value="1">1 Day</Option>
                            <Option value="2">2 Days</Option>
                            <Option value="3">3 Days</Option>
                            <Option value="4">4 Days</Option>
                            <Option value="5">5 Days</Option>
                            <Option value="10">10 Days</Option>
                            <Option value="20">20 Days</Option>
                            <Option value="30">1 Month</Option>
                            <Option value="60">2 Months</Option>
                            <Option value="90">3 Months</Option>
                            <Option value="120">4 Months</Option>
                            <Option value="150">5 Months</Option>
                            <Option value="180">6 Months</Option>
                          </Select>
                        </Form.Item>
                        <span>left in job check expiry date</span>
                      </div>
                      <div
                        className="margin-top-34"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            type="primary"
                            loading={loadUpdate}
                            className="button-blue"
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default ReminderSetting;
