import React, { Fragment, useEffect, useState } from "react";
import { Tabs, Modal } from "antd";
import EmployerEmailHistory from "./PotentialEmployerHistory/PotentialClientHistory";
// import AddEmployerDocuments from "./EmployerEmailHistory/AddEmployerDocuments";
// import UpdateEmployerDocuments from "./EmployerEmailHistory/UpdateEmployerDocuments";
import CreateEmail from "./CreateEmail/CreateEmail";

const { TabPane } = Tabs;

const EmployerEmailTabs = ({
  userDataEmp,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onGetEmployerHistory,
  employerHistoryRes,

  onSendEmailLink,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onAddAnyTamplate,
  addAnyTemRes,

  onDeleteCreateEmail,
  onGetPClientEmailHistory,
  potentialClientHistory,
  onGetPClientHistory,
  onGetSignature,
  onGetDocuments,
  onAddPotentialEmail,
  onGetPdf,
  onGetImapForAll,
  imapForAllRes,
  signatureRes,
  onAddEmailDocument,
  documentRes,
  onGetEmployerDocument,
  employerDocumentRes,
  onGetDocumentDownload,
  onGetEmailContent,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [handleKey, setHandleKey] = useState("1");
  const [emailContentData, setEmailContentData] = useState("");

  useEffect(() => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    onGetEmailContent &&
      onGetEmailContent().then((res) => {
        let _emailTemp = res && res.payload && res.payload.emailTemplates;
        for (let ind = 0; ind < _emailTemp.length; ind++) {
          if (_emailTemp[ind].emailType === "Notifications") {
            setEmailContentData(
              "<p>Dear " + userDataEmp.name + _emailTemp[ind].emailContent
            );
          } else {
            //do nothing
          }
        }
      });
  }, []);

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
    // setAddReminders(true);
    // setUpdateTitle(id);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const callback = (key) => {
    setHandleKey(key);
  };

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div className="employerin-box">
          <div className="bg-white ">
            <Tabs
              activeKey={handleKey}
              type="card"
              size={"small"}
              className="mar-r employer-doc-tab"
              onChange={(key) => callback(key)}
            >
              <TabPane tab="HISTORY" key="1">
                <div
                  style={{
                    border: "2px solid #c9c9ca",
                    padding: "20px",
                    backgroundColor: "#f0f2f5b8",
                  }}
                >
                  <EmployerEmailHistory
                    emailContentData={emailContentData}
                    emailImportRes={emailImportRes}
                    onDeleteCreateEmail={onDeleteCreateEmail}
                    userDataEmp={userDataEmp}
                    onGetPClientEmailHistory={onGetPClientEmailHistory}
                    showModal={showModal}
                    onGetEmployerHistory={onGetEmployerHistory}
                    employerHistoryRes={employerHistoryRes}
                    onGetAutoEmailImport={onGetAutoEmailImport}
                    onAddAutoEmailImport={onAddAutoEmailImport}
                    onGetPClientHistory={onGetPClientHistory}
                    potentialClientHistory={potentialClientHistory}
                    onGetLetterTemplates={onGetLetterTemplates}
                    LetterTemplatesRes={LetterTemplatesRes}
                    onSendEmailLink={onSendEmailLink}
                    onGetImapForAll={onGetImapForAll}
                    imapForAllRes={imapForAllRes}
                    onGetSignature={onGetSignature}
                    signatureRes={signatureRes}
                    onAddEmailDocument={onAddEmailDocument}
                    documentRes={documentRes}
                    onGetEmployerDocument={onGetEmployerDocument}
                    employerDocumentRes={employerDocumentRes}
                    onGetDocuments={onGetDocuments}
                    onGetDocumentDownload={onGetDocumentDownload}
                    setHandleKey={setHandleKey}
                    onGetPdf={onGetPdf}
                  />
                </div>
              </TabPane>
              <TabPane tab="CREATE" key="2">
                {handleKey === "1" ? null : (
                  <div
                    style={{
                      border: "2px solid #c9c9ca",
                      padding: "20px",
                      backgroundColor: "#f0f2f5b8",
                    }}
                  >
                    <CreateEmail
                      emailContentData={emailContentData}
                      onAddAnyTamplate={onAddAnyTamplate}
                      addAnyTemRes={addAnyTemRes}
                      onUploadAvatar={onUploadAvatar}
                      imageUploadSuccess={imageUploadSuccess}
                      onGetEmployerHistory={onGetEmployerHistory}
                      onSendEmailLink={onSendEmailLink}
                      onGetLetterTemplates={onGetLetterTemplates}
                      LetterTemplatesRes={LetterTemplatesRes}
                      onAddEmployerEmail={onAddEmployerEmail}
                      userDataEmp={userDataEmp}
                      onGetSignature={onGetSignature}
                      onGetDocuments={onGetDocuments}
                      onAddPotentialEmail={onAddPotentialEmail}
                      setHandleKey={setHandleKey}
                      onAddPotentialEmail={onAddPotentialEmail}
                      onGetPClientHistory={onGetPClientHistory}
                      onGetPdf={onGetPdf}
                      signatureRes={signatureRes}
                      onAddEmailDocument={onAddEmailDocument}
                      documentRes={documentRes}
                      onGetEmployerDocument={onGetEmployerDocument}
                      employerDocumentRes={employerDocumentRes}
                      onGetDocumentDownload={onGetDocumentDownload}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      {/* {isModalVisible && (
        <div className="reminder-model">
          <Modal
            className="reminder-model-main"
            title={
              (modalType === "add-documents" && "ADD DOCUMENTS") ||
              (modalType === "update-documents" && "UPDATE DOCUMENTS")
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            // Header={true}
            footer={false}
          >
            {modalType === "add-documents" && (
              <AddEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
                onUploadAvatar={onUploadAvatar}
                imageUploadSuccess={imageUploadSuccess}
              />
            )}

            {modalType === "update-documents" && (
              <UpdateEmployerDocuments
                onAddEmployerDocument={onAddEmployerDocument}
              />
            )}
          </Modal>
        </div>
      )} */}
    </Fragment>
  );
};

export default EmployerEmailTabs;
