import * as types from "../Constants";
import {message} from "antd";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const managerReducer = (state = {}, action) => {
  switch (action.type) {
    case types.MANAGER_SUCCESS:
      return { ...state, managerData: action.payload };
    case types.MANAGER_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_MANAGER_SUCCESS:
      if( action && action.payload === "This email is already taken")
      {
       message.error(action.payload)
      }
      else
      {
        return { ...state, updManagerSuccess: "User updated successfully" };
      }
    case types.UPDATE_MANAGER_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
