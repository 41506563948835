import React, { Fragment, useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { yyyymmddFormate } from "../../../../../Common/reUseFunction";
import { Link } from "react-router-dom";

const ClientSingleEmpTable = ({
  onGetSingleClientHis,
  singleJobHisRes,
  isjobId,
}) => {
  const [loading, setLoading] = useState(false);
  const [singleCall, setSingleCall] = useState(false);

  // useEffect(() => {
  //   if (!singleCall) {
  //     setLoading(true);
  //
  //     const data = {
  //       branchId: selectedBranchId && selectedBranchId,
  //       jobId: isjobId && isjobId
  //     };
  //
  //     setSingleCall(true);
  //     onGetSingleClientHis(data)
  //       .then(() => {
  //         setLoading(false);
  //       })
  //       .catch(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [isjobId, onGetSingleClientHis, singleCall, singleJobHisRes]);

  const columns = [
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      render: (text, record) => {
        return (
          <div className="table-action">
            <div>
              <span className="acc-actions-button">
                {record &&
                record.clientPermission &&
                record.clientPermission.active === true ? (
                  <span className="sent-email">Active</span>
                ) : (
                  <span className="draft">Inactive</span>
                )}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => {
        return (
          <Link
            to={{
              pathname:
                record.memberType.toLowerCase() === "client"
                  ? "/profile"
                  : record.memberType.toLowerCase() === "spouse"
                  ? "/partner-profile"
                  : "/client-family-member",
              state: { id: record.clientId, familyId: record.familyId },
            }}
            className="hover-link"
            style={{
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {`${record.firstName} ${record.lastName}`}
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
            textDecoration: "none !important",
            cursor: "pointer !important",
          }}
        >
          {record.email}
        </p>
      ),
    },
    {
      title: "DOB",
      key: "DOB",
      render: (text, record) => {
        return (
          <div className="table-action">
            <span
              style={{
                color: "black",
                fontSize: "12px",
                fontWeight: "initial",
                fontFamily: "Poppins, sans-serif",
                textDecoration: "none !important",
                cursor: "pointer !important",
              }}
            >
              {record && yyyymmddFormate(record.dateOfBirth)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "visaCountryName",
      key: "visaCountryName",
      render: (text, record) => {
        const arr = record.visaCountryName.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const strVisaCountryName = arr.join(" ");
        return (
          <Link
            style={{
              color: "black",
              fontSize: "12px",
              fontWeight: "initial",
              fontFamily: "Poppins, sans-serif",
              textDecoration: "none !important",
              cursor: "pointer !important",
            }}
          >
            {strVisaCountryName}
          </Link>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Spin spinning={loading} size="default">
        <div className="single-job">
          <Table
            className="job-table table-head employer-tables border-3 school-table"
            bordered
            dataSource={singleJobHisRes && singleJobHisRes.items}
            columns={columns}
            scroll={{ x: "auto" }}
          />
        </div>
      </Spin>
    </Fragment>
  );
};
export default ClientSingleEmpTable;
