import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import { apiRefresh } from "../../../services/api";

const AutoEmailImport = ({
  onGetDocumentView,
  docViewRes,
  onAddDocumentView,
  onSetActiveInnerTab,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [autoImport, setAutoImport] = useState(false);
  const state = useLocation().state;

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    const getAutoImportOpt = {
      url: `v1/users/AutoEmailON/00000000-0000-0000-0000-000000000000`,
    };
    getAutoImportOpt.types = [
      "GET_AUTO-IMPORT_SUCCESS",
      "GET_AUTO-IMPORT_FAILURE",
    ];
    apiRefresh
      .get(getAutoImportOpt)
      .then((res) => {
        setLoading(false);
        setAutoImport(res.isAutoEmailON);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const onTargetChecked = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setAutoImport(e.target.checked);
    setLoading(true);
    const data = {
      isUserAutoEmailON: e.target.checked,
    };
    const putAutoImportOpt = {
      url: `v1/users/AutoEmailON`,
    };
    putAutoImportOpt.types = [
      "PUT_AUTO-IMPORT_SUCCESS",
      "PUT_AUTO-IMPORT_FAILURE",
    ];
    apiRefresh
      .put(putAutoImportOpt, data)
      .then(() => {
        setLoading(false);
        message.success("Successfully Updated!");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img
                      src={Images.documentSync}
                      className="sus-bottom-icon"
                    />
                  </div>
                  <span className="top-text">Auto Email Import Setting</span>
                  {/* <Tooltip
                  placement="topLeft"
                  title={`When enabling folder view, will give you the ability to have specific folders with more of a classic folder view.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip> */}
                </div>

                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="default-font-box">
                <div>
                  <div className="client-tag-table">
                    <Form
                      onChange={onDocumentShow}
                      form={form}
                      className="d-flex space-between"
                    >
                      <div className="reminder-set-form margin-top-34">
                        <Form.Item className="d-block">
                          <Checkbox
                            checked={autoImport}
                            onChange={onTargetChecked}
                          >
                            Auto Email Import
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default AutoEmailImport;
