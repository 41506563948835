import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Tabs,
  Modal,
  List,
  Avatar,
  Spin,
  message,
  Input,
  Checkbox,
  Select,
  Comment,
  DatePicker,
  Image,
  Form,
  Button,
  Tag,
  Tooltip,
} from "antd";
import {
  FileAddOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UserOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "./Task.css";

const { TextArea } = Input;

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
  {
    title: "Ant Design Title 4",
  },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TabPane } = Tabs;

const TaskList = ({
  potentialClientInfo,
  onGetClientTask,
  onUpdateTask,
  onGetAllUsers,
  onAddTaskFollower,
  onRemoveTasks,
  onAddDailyTasks,
  onGetClientFamily,
  onUpdateCompletedTask,
  onAddTaskFileNote,
  onAddTaskComment,
  onGetTaskComments,
  onGetTaskFollowers,
  clientInfo,
  onGetPotentialTasks,
  onRemoveFollowers,
  users,
}) => {
  const [modalType, setModalType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [isCompleted, setCompleted] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  // const [users, setUsers] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [titleLoading, setTitleLoading] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const [addTaskLoader, setAddTaskLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [comment, setComment] = useState(null);
  const [filenote, setFileNote] = useState(null);
  const [comments, setTaskComments] = useState([]);
  const [title, setTitle] = useState(null);
  const [titleModal, setTitleModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [fileNoteModal, setFileNoteModal] = useState(false);

  useEffect(() => {
    if (clientInfo) {
      getTasks();
      getUsers();
      //   getClientFamily();
    }
  }, []);

  const formRef = useRef();

  const showModal = (modalTypeName) => {
    setModalType(modalTypeName);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const UserLinks = () => {
    getUsers();
  };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"cyan"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  const getTasks = () => {
    setTaskLoader(true);
    const clientId = clientInfo.id;
    onGetPotentialTasks(clientId, "00000000-0000-0000-0000-000000000000")
      .then((res) => {
        let taskData = res.payload.items;
        setTasks(taskData);
        setAllTasks(taskData);
        setTaskLoader(false);
        setCompleted(false);
      })
      .catch((error) => {
        // message.error("Unable to find Task!");
        setTasks([]);
        setAllTasks([]);
        setTaskLoader(false);
        setCompleted(false);
      });
  };

  const getComments = (data) => {
    setCommentLoader(true);
    const userName = localStorage.getItem("userName");
    const taskDescription = data.taskDescription;
    let comments = [];
    onGetTaskComments(data.id)
      .then((res) => {
        res.payload.items.forEach((item) => {
          comments.push(item);
        });
        setTaskComments(comments);
        setCommentLoader(false);
        console.log(res);
      })
      .catch((error) => {
        setTaskComments(comments);
        setCommentLoader(false);
      });
  };

  const getUsers = () => {
    // onGetAllUsers().then((res) => {
    //   const usersInfo = res.payload.users.map((item) => {
    //     return {
    //       label: item.fullName,
    //       value: item.id,
    //     };
    //   });
    //   setUsers(usersInfo);
    // });
  };

  const updateTaskDate = (data) => {
    setModalLoader(true);
    let updatedate = {
      id: selectedTask.id,
      taskDate: data.format(),
      taskTitle: selectedTask.taskTitle,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    onUpdateTask(updatedate)
      .then((res) => {
        setModalLoader(false);
        message.success("Successfully Rescheduled!");
        getTasks();
      })
      .catch((error) => {
        setModalLoader(false);
        message.error("Unable to Reschedule");
      });
  };

  const updateTaskTitle = () => {
    setTitleLoading(true);
    const userId = localStorage.getItem("userId");
    let updatetitle = {
      id: selectedTask.id,
      taskDate: selectedTask.taskDate,
      taskTitle: title,
      taskDescription: selectedTask.taskDescription,
      subjectId: selectedTask.subjectId,
      isPontential: selectedTask.isPontential,
      isCompleted: selectedTask.isCompleted,
      completedOn: selectedTask.completedOn,
    };
    onUpdateTask(updatetitle)
      .then((res) => {
        setTitleLoading(false);
        message.success("Successfully Updated!");
        setTitleModal(false);
        setTitle(null);
        getTasks();
      })
      .catch((error) => {
        setTitleLoading(false);
        setTitle(null);
        message.error("Unable to Update");
      });
  };

  const onChangeThreadHandler = (type, val) => {
    setTitle(val);
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const setFileNoteValue = (data) => {
    const taskDescription = data.taskDescription;
    let fileNoteValue =
      `${data.taskTitle}` + "\n" + `task description: ` + `${taskDescription}`;
    onGetTaskComments(data.id)
      .then((res) => {
        const comments = res.payload.items;
        comments.forEach((item) => {
          fileNoteValue =
            fileNoteValue + "\n" + item.userName + ": " + item.description;
        });
        setFileNote(fileNoteValue);
      })
      .catch((error) => {
        setFileNote(fileNoteValue);
      });
  };

  const handleSubmit = () => {
    setSubmit(true);
    const userId = localStorage.getItem("userId");
    let payload = {
      taskId: selectedTask.id,
      description: comment,
    };
    onAddTaskComment(payload)
      .then((res) => {
        setSubmit(false);
        setComment(null);
        getComments(selectedTask);
      })
      .catch((error) => {
        setSubmit(false);
        setComment(null);
      });
  };

  const completeTask = (data) => {
    const completed = {
      id: data.id,
    };
    onUpdateCompletedTask(completed)
      .then(() => {
        message.success("Successfully Completed!");
        getTasks();
      })
      .catch((error) => {
        message.error("Unable to Complete!");
      });
  };

  const revertCompletedTask = (data) => {
    const userId = localStorage.getItem("userId");
    const revert = {
      id: data.id,
      taskDate: data.taskDate,
      taskTitle: data.taskTitle,
      taskDescription: data.taskDescription,
      subjectId: data.subjectId,
      isPontential: data.isPontential,
      isCompleted: false,
    };
    onUpdateTask(revert)
      .then(() => {
        message.success("Successfully Converted!");
        getTasks();
      })
      .catch((error) => {
        message.error("Unable to Revert!");
      });
  };

  const addFollower = (data) => {
    setModalLoader(true);
    let payload = {
      taskId: selectedTask.id,
      userId: data,
    };
    onAddTaskFollower(payload)
      .then((res) => {
        getFollower(selectedTask);
        setModalLoader(false);
        message.success("Successfully Added!");
      })
      .catch((error) => {
        setModalLoader(false);
        message.error("Unable to add Follower!");
      });
  };

  const showComplete = (data) => {
    if (data) {
      let taskItems = [...tasks];
      taskItems = taskItems.filter((item) => item.isCompleted);
      setTasks(taskItems);
    } else setTasks(allTasks);
  };

  const getFollower = (data) => {
    onGetTaskFollowers(data.id)
      .then((res) => {
        let followers = res.payload.items;
        setFollowers(followers);
      })
      .catch((error) => {
        setFollowers([]);
      });
  };

  const handleFileNoteChange = (e) => {
    setFileNote(e.target.value);
  };

  const removeFollower = (id) => {
    let userId = localStorage.getItem("userId");
    let payload = {
      id: id,
      delete: true,
    };
    onRemoveFollowers(payload).then((res) => {
      message.success("Follower Removed!");
      onGetTaskFollowers(selectedTask.id)
        .then((res) => {
          let followers = res.payload.items;
          setFollowers(followers);
        })
        .catch((error) => {
          setFollowers([]);
        });
    });
  };

  const handleFileNoteSubmit = (e) => {
    const userId = localStorage.getItem("userId");
    const payload = {
      clientId: clientInfo.id,
      fileNote: filenote,
    };
    onAddTaskFileNote(payload)
      .then(() => {
        message.success("File Note Added!");
        setFileNoteModal(false);
      })
      .catch((error) => {
        message.error("Unable to add File Note!");
      });
  };

  const onFinish = (values) => {
    setAddTaskLoader(true);
    let followers = [];
    const userId = localStorage.getItem("userId");
    const branchId = localStorage.getItem("selectedBranchId");
    if (values.add_followers && values.add_followers.length)
      followers = values.add_followers.map((Item) => {
        return { userId: Item };
      });
    const data = {
      taskDate: values.select_date.format(),
      taskTitle: values && values.task_title,
      taskDescription: values && values.task_description,
      subjectId: clientInfo
        ? clientInfo.id
        : "00000000-0000-0000-0000-000000000000",
      taskUsers: followers.length
        ? [...followers, { userId: userId }]
        : [{ userId: userId }],
      isPontential: false,
      isCompleted: false,
    };
    onAddDailyTasks(data)
      .then((res) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.success("Successfully Added!");
        setTaskModal(false);
        getTasks();
      })
      .catch((error) => {
        setAddTaskLoader(false);
        if (formRef && formRef.current) formRef.current.resetFields();
        message.error("Unable to Add Task!");
      });
  };

  const renderTitleModal = () => (
    <Modal
      title="Update Task Title"
      visible={titleModal}
      confirmLoading={titleLoading}
      onOk={() => updateTaskTitle()}
      onCancel={() => setTitleModal(false)}
      okText="Update"
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
        <Input
          value={title}
          onChange={(e) => onChangeThreadHandler("title", e.target.value)}
          style={{ width: 283 }}
          placeholder="Enter Task Title"
        />
      </div>
    </Modal>
  );

  const renderAddComment = () => (
    <Modal
      title="Task Details"
      visible={commentModal}
      onCancel={() => setCommentModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={modalLoader}>
        <div className="mdtr-modal-gray-cont" style={{ marginBottom: 10 }}>
          <div class="d-flex">
            <Select
              onSearch={() => UserLinks()}
              showSearch
              allowClear
              // options={users}
              // options={users}
              style={{ width: "40%" }}
              placeholder="Search to Followers"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              onChange={(option) => {
                if (option) addFollower(option);
              }}
            >
              {users}
            </Select>
            <div style={{ width: "40%", marginLeft: 94 }}>
              <DatePicker
                allowClear
                placeholder="Reschedule Task"
                onChange={(date) => {
                  if (date) updateTaskDate(date);
                }}
                format={"DD/MM/YYYY"}
              />
            </div>
          </div>
        </div>
        <Form name="basic" initialValues={{ remember: true }}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="d-flex space-between ">
                <div className="follower-row d-flex">
                  <span>
                    <h4>Followers</h4>
                  </span>
                  {followers.length
                    ? followers.map((item) => (
                        <span>
                          {item.imgURL &&
                          item.imgURL != null &&
                          item.imgURL != "" ? (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item.id)}
                                />
                                <Avatar
                                  src={
                                    <Image
                                      src={item.imgURL}
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={item.fullName}
                              color="#eae9d4"
                              overlayInnerStyle={{ color: "black" }}
                            >
                              <div style={{ position: "relative" }}>
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: -8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeFollower(item.id)}
                                />
                                <Avatar
                                  className="mar-right"
                                  size="small"
                                  style={{ height: 32, width: 32 }}
                                  icon={
                                    <UserOutlined
                                      style={{ height: 32, width: 32 }}
                                    />
                                  }
                                />
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      ))
                    : null}
                </div>
              </div>
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleChange(e)}
                        value={comment}
                        showCount
                        maxLength={250}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        Comment
                      </Button>
                    </view>
                  }
                />
                <div>
                  <div
                    className="d-flex comment-width"
                    style={{ alignItems: "center" }}
                  >
                    <Spin spinning={commentLoader} size="large">
                      <div>
                        {selectedTask ? (
                          <div
                            style={{
                              borderBottom: "1px solid #ccc",
                              width: "100%",
                              marginBottom: 10,
                            }}
                          >
                            <div>
                              <h5>Task Description: </h5>
                            </div>
                            <div>
                              <p>{selectedTask.taskDescription}</p>
                            </div>
                          </div>
                        ) : null}
                        {comments.length
                          ? comments.map((item, index) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  width: 451,
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  class="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  {item.imgURL &&
                                  item.imgURL != null &&
                                  item.imgURL != "" ? (
                                    <Avatar
                                      src={
                                        <Image
                                          src={item.imgURL}
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  ) : (
                                    <Avatar
                                      className="mar-right"
                                      size="small"
                                      style={{ height: 32, width: 32 }}
                                      icon={
                                        <UserOutlined
                                          style={{ height: 32, width: 32 }}
                                        />
                                      }
                                    />
                                  )}
                                  <div style={{ marginLeft: 3 }}>
                                    <h4>{item.userName}</h4>
                                    <span style={{ fontSize: 12 }}>
                                      {moment(item.createdDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const renderAddFileNote = () => (
    <Modal
      title="Add To File Note"
      visible={fileNoteModal}
      onCancel={() => setFileNoteModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={false}>
        <Form name="basic" initialValues={{ remember: true }} ref={formRef}>
          <div className="bg-box-white mar-b">
            <div className="w-96">
              <div className="tasks-comment">
                <Comment
                  content={
                    <view>
                      <TextArea
                        rows={6}
                        onChange={(e) => handleFileNoteChange(e)}
                        value={filenote}
                        showCount
                        maxLength={300}
                      />
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                        loading={submit}
                        onClick={(e) => handleFileNoteSubmit(e)}
                        type="primary"
                        className="task-blue"
                      >
                        SUBMIT
                      </Button>
                    </view>
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  const renderAddTaskModal = () => (
    <Modal
      title="Add Task"
      visible={taskModal}
      onCancel={() => setTaskModal(false)}
      footer={false}
      bodyStyle={{ backgroundColor: "#f6f7f9" }}
    >
      <Spin size="large" spinning={addTaskLoader}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          {...layout}
        >
          <div className="add-tasks">
            <div>
              <div className="mdtr-modal-gray-cont">
                <div className="modal-parts">
                  <Form.Item
                    className="form-parts"
                    label="Task Title"
                    name="task_title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="form-parts"
                    label="Task Description"
                    name="task_description"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} showCount maxLength={250} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts"
                    label="Select Date"
                    name="select_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                  <Form.Item
                    className="form-parts multi-select-option"
                    label="Add Followers"
                    name="add_followers"
                    // rules={[{ message: "Please Add Followers!" }]}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      style={{ width: "100%" }}
                      // options={users}
                      filterOption={(input, option) => {
                        return (
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {users}
                    </Select>
                  </Form.Item>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      className="task-blue"
                      htmlType="submit"
                    >
                      ADD TASK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );

  return (
    <Spin spinning={taskLoader} size="large" style={{ marginTop: 92 }}>
      <Fragment>
        <div style={{ display: "flex" }}>
          <div className="ts-container-Pclient width-100">
            <div className="bg-white employer-manag-tabs">
              <p style={{ marginBottom: "8px" }}>
                {potentialClientInfo && potentialClientInfo.firstName
                  ? `Name: ${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`
                  : `Add a New Potential Client`}
              </p>
              <Tabs
                defaultActiveKey="1"
                type="card"
                size={"small"}
                className="mar-r employer-doc-tab"
              >
                <TabPane tab="TASKS" key="1">
                  <div
                    style={{
                      border: "2px solid #c9c9ca",
                      padding: "20px",
                      backgroundColor: "#f0f2f5b8",
                    }}
                  >
                    <div style={{ marginBottom: 15 }}>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        size="medium"
                        onClick={() => {
                          setTaskModal(true);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 20,
                        borderBottom: "1px solid #ccc",
                        paddingBottom: 10,
                      }}
                    >
                      <Checkbox
                        checked={isCompleted}
                        disabled={false}
                        onChange={(e) => {
                          showComplete(e.target.checked);
                          setCompleted(!isCompleted);
                        }}
                      />
                      <span style={{ marginLeft: 10 }}>Show Completed</span>
                    </div>
                    <List
                      itemLayout="horizontal"
                      dataSource={tasks}
                      renderItem={(item) => (
                        <List.Item
                          actions={[
                            <FileAddOutlined
                              onClick={() => {
                                setSelectedTask(item);
                                setTitleModal(true);
                                setTitle(item.taskTitle);
                              }}
                            />,
                            <PlusCircleOutlined
                              onClick={() => {
                                setSelectedTask(item);
                                setFileNoteModal(true);
                                setFileNoteValue(item);
                              }}
                            />,
                          ]}
                        >
                          <div title="Complete/Revert">
                            <Checkbox
                              checked={item.isCompleted}
                              disabled={false}
                              onChange={() => {
                                if (item.isCompleted) revertCompletedTask(item);
                                else completeTask(item);
                              }}
                            />
                          </div>
                          <List.Item.Meta
                            title={
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedTask(item);
                                  setCommentModal(true);
                                  getComments(item);
                                  getFollower(item);
                                }}
                              >
                                {item && item.taskTitle}
                              </span>
                            }
                            style={{ marginLeft: 10 }}
                          />
                          <div>
                            <span>
                              (
                              {moment(item && item.taskDate).format(
                                "DD/MM/YYYY"
                              )}
                              )
                            </span>
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        {renderTitleModal()}
        {renderAddComment()}
        {renderAddFileNote()}
        {renderAddTaskModal()}
      </Fragment>
    </Spin>
  );
};

export default TaskList;
