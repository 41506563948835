import * as types from "../Constants";

export const userPermissionReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PERMISSION_SUCCESS:
      return { ...state, userPermissionData: action.payload };
    case types.PERMISSION_FAILURE:
      console.log("on error console", action.payload);
    case types.ADD_PERMISSION_SUCCESS:
      return { ...state, addPermissionSuccess: action.payload };
    case types.ADD_PERMISSION_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_PERMISSION_SUCCESS:
      return { ...state, updPermissionSuccess: action.payload };
    case types.UPDATE_PERMISSION_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
