import React, { Component } from "react";

import {
  Collapse,
  List,
  Avatar,
  Row,
  Col,
  Spin,
  Card,
  Modal,
  Menu,
  message,
  Form,
  Button,
  Divider,
  Dropdown,
  Input,
  DatePicker,
  Typography,
} from "antd";

import {
  PlusOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UserOutlined,
  FileAddOutlined,
  SendOutlined,
} from "@ant-design/icons";
import Select from "react-select";
import "./DropDownStyle.css";
import Moment from "moment";
import { openNotificationWithIcon } from "../Common/reUseFunction";
import PubSub from "pubsub-js";
import { DriveEtaOutlined } from "@material-ui/icons";
import "./ChatList.css";
const { Panel } = Collapse;

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

function handleMenuClick(e) {
  message.info("Click on menu item.");
  console.log("click", e);
}

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class SendButton extends Component {
  render() {
    return (
      <div className="send_message" onClick={this.props.handleClick}>
        <div className="text">
          <SendOutlined />
        </div>
      </div>
    );
  }
}

class MessageTextBoxContainer extends Component {
  render() {
    return (
      <div className="message_input_wrapper">
        <input
          id="msg_input"
          className="message_input"
          placeholder="Type your messages here..."
          value={this.props.message}
          onChange={this.props.onChange}
          onKeyPress={this.props._handleKeyPress}
        />
      </div>
    );
  }
}

class Avartar extends Component {
  render() {
    return <div className="avatar" />;
  }
}

class BotMessageBox extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <li className="message left appeared">
        <Avartar></Avartar>
        <div className="text_wrapper">
          <div className="text">{this.props.message}</div>
        </div>
      </li>
    );
  }
}

class UserMessageBox extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <li className={`message ${this.props.appearance} appeared`}>
        <div className="text_wrapper" style={{ width: "50%" }}>
          <div className="text">{this.props.message}</div>
        </div>
        {this.props.appearance === "left" ? (
          <div className="text" style={{ marginLeft: "3%" }}>
            <h6>{Moment(this.props.date).format("l")}</h6>
          </div>
        ) : (
          <div
            className="text"
            style={{
              float: "right",
              width: "100%",
              textAlign: "end",
              marginRight: 20,
            }}
          >
            <h6>{Moment(this.props.date).format("l")}</h6>
          </div>
        )}
      </li>
    );
  }
}

class MessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.createBotMessages = this.createBotMessages.bind(this);
  }

  scrollToBottom = () => {
    var el = this.refs.scroll;
    el.scrollTop = el.scrollHeight;
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  createBotMessages() {
    console.log(this.props.messages);
    return this.props.displayMessages.map((item, index) => (
      <UserMessageBox
        key={index}
        message={item.message}
        appearance={item.messageType === 1 ? "right" : "left"}
        date={item.createdDate}
      />
    ));
  }

  render() {
    return (
      <ul className="messages" ref="scroll">
        {this.createBotMessages()}
      </ul>
    );
  }
}

class ChatLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      displayMessages: [],
      current_message: "",
      clientFlag: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addMessageBox = this.addMessageBox.bind(this);
  }

  addMessageBox(enter = true) {
    let messages = this.state.messages;
    let displayMessages = this.state.displayMessages;
    let current_message = this.state.current_message;
    console.log(this.state);
    if (current_message && enter) {
      let data = {
        chatId: this.state.chatThreadDetail.chatId,
        subjectId: this.state.chatThreadDetail.subjectId,
        name: "string",
        message: current_message,
        messageType: 1,
        documentName: "string",
        createdDate: Moment(new Date()).format(),
        chatName: this.state.chatThreadDetail.chatName,
      };
      messages = [
        ...messages,
        {
          id: this.state.chatThreadDetail.chatId,
          message: current_message,
          messageType: 1,
          createdDate: Moment(new Date()).format(),
        },
      ];
      current_message = "";
      this.setState({
        current_message: current_message,
        messages,
        displayMessages: messages,
      });
      this.props
        .onAddThreadMessage(data)
        .then((res) => {
          console.log("Message Sent");
        })
        .catch(() => {
          openNotificationWithIcon("error", "Message failed!");
        });
    }
  }

  handleClick() {
    this.addMessageBox();
  }

  onChange(e) {
    this.setState({
      current_message: e.target.value,
    });
  }

  _handleKeyPress(e) {
    let enter_pressed = false;
    if (e.key === "Enter") {
      enter_pressed = true;
    }
    this.addMessageBox(enter_pressed);
  }

  formRef = React.createRef();

  state = {
    ChatList: [],
    DisplayList: [],
    pageLoading: false,
    modal: false,
    memberModal: false,
    filenoteModal: false,
    modalLoading: false,
    ClientData: [],
    UserData: [],
    listData: [],
    userListData: [],
    clientSelected: null,
    chatThreadName: "",
    showMessages: false,
    chatThreadDetail: null,
    membersName: null,
    membersId: null,
    selectedChatId: null,
    fileNoteStart: null,
    fileNoteEnd: null,
    fileNoteClients: null,
    messageCount: 0,
  };
  componentDidMount = () => {
    const propData = this.props;
    this.getChatList();
    this.getClientList();
    this.getUserData();

    PubSub.subscribe(
      "MESSAGE",
      function(msg, data) {
        const messages = [
          ...this.state.messages,
          {
            createdAt: data.payload.CreatedDate,
            id: data.payload.id,
            message: data.payload.Message,
            messageType: 0,
          },
        ];

        if (this.state.selectedChatId === data.payload.ChatId)
          this.setState({
            messages,
            displayMessages: messages,
          });
      }.bind(this)
    );
  };

  menu = () => {
    return (
      <Menu onClick={handleMenuClick}>
        {this.state.ClientData.map((item, index) => {
          return (
            <Menu.Item
              key={item.id}
            >{`${item.firstName} ${item.lastName}`}</Menu.Item>
          );
        })}
      </Menu>
    );
  };

  onViewMessages = (id, name) => {
    try {
      let userId = localStorage.getItem("userId");

      this.setState({
        chatThreadDetail: {
          chatId: id,
          chatName: name,
          subjectId: userId,
        },
      });
      this.props
        .onGetChatMessages(id)
        .then((res) => {
          let chatMessages = res.payload && res.payload.items;

          if (chatMessages)
            this.setState({
              messages: chatMessages,
              displayMessages: chatMessages,
            });
        })
        .catch(() => {
          this.setState({
            messages: [],
            displayMessages: [],
          });
        });
    } catch (error) {
      this.setState({
        messages: [],
        displayMessages: [],
      });
    }
  };

  getChatList = () => {
    try {
      this.setState({
        pageLoading: true,
      });

      this.props
        .onGetAllChats()
        .then((res) => {
          let messages = 0;
          let chats = res.payload && res.payload.items;

          chats.forEach((item) => {
            messages = messages + item.unreadMessages;
          });

          if (chats)
            this.setState({
              ChatList: chats,
              DisplayList: chats,
              pageLoading: false,
              messageCount: messages,
            });
        })
        .catch(() => {
          this.setState({
            ChatList: [],
            DisplayList: [],
            pageLoading: false,
          });
        });
    } catch (error) {
      this.setState({
        ChatList: [],
        DisplayList: [],
        pageLoading: false,
      });
    }
  };

  getClientList = () => {
    try {
      this.props
        .onGetClientsData()
        .then((res) => {
          let clientsInfo = res.payload && res.payload.items;

          if (clientsInfo) this.setState({ ClientData: clientsInfo });
          let listDetails = clientsInfo.map((item, index) => {
            return {
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            };
          });

          this.setState({ listData: listDetails });
        })
        .catch(() => {
          this.setState({
            ClientData: [],
          });
        });
    } catch (error) {
      this.setState({
        ClientData: [],
      });
    }
  };

  getUserData = () => {
    try {
      this.props
        .onGetUsersData()
        .then((res) => {
          let userInfo = res.payload && res.payload.users;

          if (userInfo) this.setState({ UserData: userInfo });
          let listDetails = userInfo.map((item, index) => {
            return {
              value: item.id,
              label: item.fullName,
            };
          });

          this.setState({ userListData: listDetails });
        })
        .catch(() => {
          this.setState({
            UserData: [],
          });
        });
    } catch (error) {
      this.setState({
        UserData: [],
      });
    }
  };

  addChatThread = () => {
    try {
      this.setState({ modalLoading: true });
      let userId = localStorage.getItem("userId");

      let data = {
        name: this.state.chatThreadName,
        img: "string",
        members: [
          {
            chatId: 0,
            subjectId: userId,
            isClient: false,
          },
          {
            chatId: 0,
            subjectId: this.state.clientSelected.value,
            isClient: this.state.clientSelected.isClient,
          },
        ],
      };

      this.props
        .onAddChatThread(data)
        .then((res) => {
          openNotificationWithIcon("success", "Chat Thread Added!");

          this.setState({ modal: false, modalLoading: false });
          this.getChatList();
        })
        .catch(() => {
          openNotificationWithIcon("error", "Chat create failed!");
          this.setState({ modalLoading: false });
        });
    } catch (error) {
      this.setState({ modalLoading: false });
    }
  };

  addChatMember = (e) => {
    try {
      this.setState({ modalLoading: true });
      const { clientFlag } = this.state;
      let userNames = this.state.membersName;
      let data = {
        chatId: this.state.selectedChatId,
        subjectId: this.state.clientSelected.value,
        isClient: this.state.clientSelected.isClient,
      };

      this.props
        .onAddChatMember(data)
        .then((res) => {
          openNotificationWithIcon("success", "Chat Member Added!");
          if (this.state.clientSelected.isClient) {
            this.state.ClientData.forEach((item, index) => {
              if (item.clientId === this.state.clientSelected.value)
                userNames = userNames + `,${item.firstName}`;
            });
          } else {
            this.state.UserData.forEach((item, index) => {
              if (item.id === this.state.clientSelected.value)
                userNames = userNames + `,${item.fullName}`;
            });
          }
          this.setState({
            memberModal: false,
            membersName: userNames,
            modalLoading: false,
          });
          this.getChatList();
        })
        .catch(() => {
          openNotificationWithIcon("error", "Chat member add failed!");
          this.setState({
            memberModal: false,
            modalLoading: false,
          });
        });
    } catch (error) {
      openNotificationWithIcon("error", "Chat member add failed!");
      this.setState({
        memberModal: false,
        modalLoading: false,
      });
    }
  };

  addChatFileNotes = () => {
    try {
      this.setState({ modalLoading: true });
      let data = {
        clientIds: this.state.fileNoteClients,
        chatId: this.state.selectedChatId,
        dateFrom: this.state.fileNoteStart,
        dateTo: this.state.fileNoteEnd,
      };
      this.props
        .onAddChatFileNotes(data)
        .then((res) => {
          openNotificationWithIcon("success", "Chat File Note Added!");
          this.setState({
            filenoteModal: false,
            modalLoading: false,
          });
        })
        .catch(() => {
          openNotificationWithIcon("error", "Chat File Note failed!");
          this.setState({
            filenoteModal: false,
            modalLoading: false,
          });
        });
    } catch (error) {
      openNotificationWithIcon("error", "Chat File Note failed!");
      this.setState({
        filenoteModal: false,
        modalLoading: false,
      });
    }
  };

  onChangeThreadHandler = (type, val) => {
    this.setState({
      chatThreadName: val,
    });
  };

  onSearch = (value) => {
    let searchData = this.state.ChatList.filter((item, index) => {
      return item.name === value;
    });

    this.setState({
      DisplayList: searchData,
    });
  };

  onChangeSearchHandler = (name, value) => {
    let searchData = this.state.ChatList.filter((item, index) => {
      return item.name.includes(value);
    });

    this.setState({
      DisplayList: searchData,
    });
  };

  showModal = () => {
    this.setState({
      modal: true,
    });
  };

  onDateChange = (value) => {
    if (value === null) {
      this.setState({
        displayMessages: this.state.messages,
      });
    } else {
      let startDate = value[0].format();
      let endDate = value[1].format();

      let messageData = this.state.messages.filter((item) => {
        return item.createdDate >= startDate && item.createdDate <= endDate;
      });
      this.setState({
        displayMessages: messageData,
      });
    }
  };

  renderCard = () => {
    const userName = localStorage.getItem("userName");
    return (
      <div style={{ padding: 15 }}>
        <Row gutter={24}>
          <Col span={24}>
            <Card title={`Welcome, ${userName}`} bordered={true}>
              {`Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.\n`}
              <div style={{ marginTop: 15 }}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => this.showModal()}
                >
                  Start New Chat
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  renderChat = () => {
    return (
      <div>
        <div
          style={{
            width: "100%",
            paddingTop: 15,
            paddingBottom: 15,
            backgroundColor: "#ebebeb",
          }}
        >
          <Row>
            <Col span={8}>
              <div style={{ marginLeft: 20 }}>
                <Title level={5}>{this.state.membersName}</Title>
              </div>
            </Col>
            <Col span={16}>
              <div style={{ display: "flex" }}>
                <div style={{ marginLeft: 10 }}>
                  <RangePicker
                    onChange={(value) => {
                      this.onDateChange(value);
                    }}
                  />
                </div>
                <div
                  className="chat-header"
                  style={{ marginLeft: 10, textAlign: "center" }}
                >
                  <Row>
                    <Col flex={1} style={{ marginRight: 10 }}>
                      <Button
                        style={{ width: "20" }}
                        type="primary"
                        icon={<UserAddOutlined />}
                        size="large"
                        onClick={() => this.setState({ memberModal: true })}
                      />
                      <h6 style={{ fontSize: 11 }}>Add Member</h6>
                    </Col>
                    <Col flex={1}>
                      <Button
                        style={{ width: "20" }}
                        type="primary"
                        icon={<FileAddOutlined />}
                        size="large"
                        onClick={() => this.setState({ filenoteModal: true })}
                      />
                      <h6 style={{ fontSize: 11 }}>Add To File Note</h6>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <Divider orientation="left">Messages</Divider> */}
        <div className="chat_window" style={{ height: "100%", width: "100%" }}>
          <MessagesContainer
            messages={this.state.messages}
            displayMessages={this.state.displayMessages}
          ></MessagesContainer>
          <div className="bottom_wrapper clearfix">
            <MessageTextBoxContainer
              _handleKeyPress={this._handleKeyPress}
              onChange={this.onChange}
              message={this.state.current_message}
            ></MessageTextBoxContainer>
            <SendButton handleClick={this.handleClick}></SendButton>
          </div>
        </div>
      </div>
    );
  };

  renderModal = () => {
    return (
      <Modal
        title="Add New Chat"
        visible={this.state.modal}
        confirmLoading={this.state.modalLoading}
        onOk={() => this.addChatThread()}
        onCancel={() =>
          this.setState({
            modal: false,
            modalLoading: false,
            clientSelected: null,
            chatThreadName: "",
          })
        }
        okText="Add Chat"
        // bodyStyle={{ backgroundColor: "#b5bdb7" }}
      >
        <div style={{ marginLeft: 80 }}>
          <Input
            value={this.state.chatThreadName}
            onChange={(e) =>
              this.onChangeThreadHandler("chatThreadName", e.target.value)
            }
            style={{ width: 283 }}
            placeholder="Enter Chat Name"
          />
        </div>
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.userListData}
            placeholder="Select User"
            isClearable={true}
            onChange={(item) => {
              this.setState({
                clientSelected: { ...item, isClient: false },
              });
            }}
          />
        </div>
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.listData}
            placeholder="Select Client"
            isClearable={true}
            onChange={(item) => {
              this.setState({
                clientSelected: { ...item, isClient: true },
              });
            }}
          />
        </div>
      </Modal>
    );
  };

  renderMemberModal = () => {
    return (
      <Modal
        title="Add Chat Member"
        visible={this.state.memberModal}
        confirmLoading={this.state.modalLoading}
        onOk={this.addChatMember.bind(this)}
        onCancel={() =>
          this.setState({
            memberModal: false,
            clientSelected: null,
          })
        }
        okText="Add Member"
        bodyStyle={{ backgroundColor: "#b5bdb7" }}
      >
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.userListData}
            placeholder="Select User"
            isClearable={true}
            onChange={(item) => {
              this.setState({
                clientSelected: { ...item, isClient: false },
              });
            }}
          />
        </div>
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.listData}
            placeholder="Select Client"
            isClearable={true}
            onChange={(item) => {
              this.setState({
                clientSelected: { ...item, isClient: true },
              });
            }}
          />
        </div>
      </Modal>
    );
  };

  renderFileNoteModal = () => {
    return (
      <Modal
        title="Add Chat Messages To File Notes"
        visible={this.state.filenoteModal}
        confirmLoading={this.state.modalLoading}
        onOk={() => this.addChatFileNotes()}
        onCancel={() =>
          this.setState({
            filenoteModal: false,
          })
        }
        okText="Add File Note"
        bodyStyle={{ backgroundColor: "#b5bdb7" }}
      >
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <RangePicker
            onChange={(value) => {
              this.setState({
                fileNoteStart: value[0].format(),
                fileNoteEnd: value[1].format(),
              });
            }}
          />
        </div>
        <div style={{ marginTop: 15, width: "60%", marginLeft: 80 }}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            options={this.state.listData}
            placeholder="Select Client"
            isClearable={true}
            isMulti={true}
            onChange={(item) => {
              if (item != null) {
                let clientIds = item.map((obj) => {
                  return obj.value;
                });
                this.setState({
                  fileNoteClients: clientIds,
                });
              } else {
                this.setState({
                  fileNoteClients: null,
                });
              }
            }}
          />
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <Spin spinning={false} size="large">
        <div>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div style={{ display: "flex" }}>
                <div style={{ marginBottom: 5, padding: 0, width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        backgroundColor: "#f7f7f7",
                        padding: 10,
                        paddingRight: 2,
                        position: "relative",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h2>
                          <b>Messages</b>
                        </h2>
                        <div className="unread-msg">
                          {this.state.messageCount}
                        </div>
                      </div>
                      <div className="chat-search-box">
                        <Search
                          placeholder="search chat"
                          onSearch={this.onSearch}
                          onChange={(e) =>
                            this.onChangeSearchHandler("search", e.target.value)
                          }
                          style={{ width: "100%" }}
                        />
                      </div>
                      <Divider orientation="left">Chats</Divider>
                      {this.state.pageLoading ? (
                        <div style={{ margin: "50%" }}>
                          <Spin
                            spinning={this.state.pageLoading}
                            size="large"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "50%",
                            overflowY: "scroll",
                            marginTop: 20,
                            position: "relative",
                          }}
                        >
                          <List
                            itemLayout="horizontal"
                            dataSource={this.state.DisplayList}
                            renderItem={(item) => {
                              let nameString = "";
                              if (item.members.length === 2)
                                nameString = item.members[1].name;
                              else
                                item.members.forEach((element, index) => {
                                  if (index === 0)
                                    nameString = nameString + `${element.name}`;
                                  else
                                    nameString =
                                      nameString + `, ${element.name}`;
                                });

                              return (
                                <List.Item
                                  onClick={() => {
                                    this.setState({
                                      showMessages: true,
                                      membersName: nameString,
                                      selectedChatId: item.id,
                                    });
                                    this.onViewMessages(item.id, item.name);
                                  }}
                                >
                                  <List.Item.Meta
                                    avatar={
                                      item.members.length > 2 ? (
                                        <Avatar
                                          size="large"
                                          icon={<UsergroupAddOutlined />}
                                        />
                                      ) : (
                                        <Avatar
                                          size="large"
                                          icon={<UserOutlined />}
                                        />
                                      )
                                    }
                                    title={nameString}
                                    description={item.name}
                                  />
                                  <div className="unread-msgs">
                                    {item.unreadMessages}
                                  </div>
                                </List.Item>
                              );
                            }}
                          />
                        </div>
                      )}
                      <Button
                        style={{
                          position: "absolute",
                          bottom: "45%",
                          right: 30,
                        }}
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => this.showModal()}
                      />
                    </div>
                    <Divider
                      type="vertical"
                      style={{ height: "100vh", color: "black" }}
                    />
                    <div style={{ width: "70%", backgroundColor: "#fbfbfb" }}>
                      {this.state.showMessages
                        ? this.renderChat()
                        : this.renderCard()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderModal()}
          {this.renderMemberModal()}
          {this.renderFileNoteModal()}
        </div>
      </Spin>
    );
  }
}

export default ChatLists;
