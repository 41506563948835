import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import { Images } from "../Themes";
import { bindActionCreators } from "redux";
import { getAllTaxesListing, deleteTax, setTaxInfo } from "../store/Actions";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddTax from "./AddTax";

class Taxes extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      visibleAddTaxModal: false,
      taxListing: [],
      branchId: selectedBranchId,
      visibleDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllTaxesListing();
  }

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this tax?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.props.deleteTax(this.state.index).then(() => {
                    message.success("Tax is deleted successfully!");
                    this.props.getAllTaxesListing();
                    this.setState({ visibleDeleteModal: false });
                  });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getTaxesSection = () => {
    return (
      <Row xs={24}>
        <Col xs={21} offset={1} style={{ marginTop: "13px" }}>
          <Table
            dataSource={this.state.taxListing ? this.state.taxListing : null}
            columns={this.columns}
            pagination={{
              defaultPageSize: 20,
            }}
          />
        </Col>
        <Col xs={1} offset={1} style={{ marginTop: "-14px" }}>
          <img
            src={Images.addIcon}
            className="icons-client"
            type="primary"
            onClick={() => {
              this.props.setTaxInfo(null);
              this.setState({ visibleAddTaxModal: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    let _taxListing = nextProps && nextProps.taxListing;
    this.setState({
      taxListing: _taxListing,
    });
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getAllTaxesListing().then(() => {});
      });
    }
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      ellipsis: true,
    },
    {
      title: "Percentage",
      dataIndex: "percent",
      key: "percent",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.props.setTaxInfo(record);
              this.setState({ visibleAddTaxModal: true });
            }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  handleCancelAddTaxModal = (e) => {
    this.setState({
      visibleAddTaxModal: false,
    });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <Row>
          <Col xs={24}>
            <h4 className={"settings-heading"}>{"Taxes"}</h4>
          </Col>
        </Row>
        <div className="ca-gray-cont">
          {this.getTaxesSection()}
          {this.state.visibleAddTaxModal ? (
            <AddTax
              handleCancelAddTaxModal={this.handleCancelAddTaxModal}
              visibleAddTaxModal={this.state.visibleAddTaxModal}
            />
          ) : null}
          {this.getModalDelete()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  deleteTax: bindActionCreators(deleteTax, dispatch),
  setTaxInfo: bindActionCreators(setTaxInfo, dispatch),
});

Taxes = connect(mapStateToProps, mapDispatchToProps)(Taxes);

export default Taxes;
