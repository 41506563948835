import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";

const DailyEmailSettings = ({
  onGetEmailSetting,
  emailSetRes,
  onUpdateEmailSetting,
  onSetActiveInnerTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [dailyTaskEmail, setDailyTaskEmail] = useState();
  const [sendNotification, setSendNotification] = useState();
  const [toClient, setToClient] = useState();
  const [emailImport, setEmailImport] = useState();
  const [dailyMeeting, setDailyMeeting] = useState();
  const state = useLocation().state;

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    onGetEmailSetting()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetEmailSetting]);

  useEffect(() => {
    if (emailSetRes) {
      form.setFieldsValue({
        dailyTaskEmail: emailSetRes.dailyTaskEmail,
        emailImportNotification:
          emailSetRes && emailSetRes.emailImportNotification,
        processingPersonNotification: emailSetRes.processingPersonNotification,
        processingPersonNotificationPotential:
          emailSetRes.processingPersonNotificationPotential,
        dailyMeetingEmail: emailSetRes.dailyMeetingEmail,
      });
    }
  }, [emailSetRes]);

  const onCheckMailChange = (values) => {
    setLoading(true);

    console.log("Received values of form:", values);
    const data = {
      dailyTaskEmail:
        values.target.id == "dailyTaskEmail"
          ? values.target.checked
          : emailSetRes.dailyTaskEmail,
      emailImportNotification:
        values.target.id == "emailImportNotification"
          ? values.target.checked
          : emailSetRes && emailSetRes.emailImportNotification,
      processingPersonNotification:
        values.target.id == "processingPersonNotification"
          ? values.target.checked
          : emailSetRes.processingPersonNotification,
      processingPersonNotificationPotential:
        values.target.id == "processingPersonNotificationPotential"
          ? values.target.checked
          : emailSetRes.processingPersonNotificationPotential,
      dailyMeetingEmail:
        values.target.id == "dailyMeetingEmail"
          ? values.target.checked
          : emailSetRes.dailyMeetingEmail,
    };
    onUpdateEmailSetting(data).then(() => {
      onGetEmailSetting()
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        })
        .catch((err) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    });
  };

  const onDailyTaskEmailChange = (e) => {
    console.log("checked = ", e.target.checked);
    setDailyTaskEmail(e.target.checked);
  };
  const ondailyMeetingEmailChange = (e) => {
    console.log("checked = ", e.target.checked);
    setDailyMeeting(e.target.checked);
  };
  const onSendNotificationChange = (e) => {
    console.log("checked = ", e.target.checked);
    setSendNotification(e.target.checked);
  };
  const onToClientChange = (e) => {
    console.log("checked = ", e.target.checked);
    setToClient(e.target.checked);
  };
  const onEmailImportNotificationChange = (e) => {
    console.log("checked = ", e.target.checked);
    setEmailImport(e.target.checked);
  };
  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img
                    src={Images.dailyMailSetting}
                    className="sus-bottom-icon"
                  />
                </div>
                <span className="top-text">Daily Mail Setting</span>
                <Tooltip
                  placement="topLeft"
                  title={`This is where you can update your daily email notifications that Ezymigrate sends you.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    form={form}
                    onChange={onCheckMailChange}
                    className="d-flex space-between"
                  >
                    <div className="reminder-set-form margin-top-34">
                      <div className="margin-top">
                        <Form.Item
                          name="dailyTaskEmail"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // checked={this.state.checked}
                            // disabled={this.state.disabled}
                            onChange={onDailyTaskEmailChange}
                          >
                            Send email for Assigned Tasks
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="margin-top">
                        <Form.Item
                          name="dailyMeetingEmail"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // checked={this.state.checked}
                            // disabled={this.state.disabled}
                            onChange={ondailyMeetingEmailChange}
                          >
                            Send Daily Reminder Email for Assigned Tasks
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="margin-top">
                        <Form.Item
                          name="processingPersonNotificationPotential"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // checked={this.state.checked}/
                            // disabled={this.state.disabled}
                            onChange={onSendNotificationChange}
                          >
                            Send notification when a potential client file is
                            assigned to a processing person
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="margin-top">
                        <Form.Item
                          name="processingPersonNotification"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // checked={this.state.checked}
                            // disabled={this.state.disabled}
                            onChange={onToClientChange}
                          >
                            Send notification when a client file is assigned to
                            a processing person
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="margin-top">
                        <Form.Item
                          name="emailImportNotification"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // checked={this.state.checked}
                            // disabled={this.state.disabled}
                            onChange={onEmailImportNotificationChange}
                          >
                            Send notification on email import if you are a
                            processing person
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DailyEmailSettings;
