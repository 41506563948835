import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  InputNumber,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Images } from "../../Themes";
const { Option } = Select;
const { TextArea } = Input;
class Stage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxInclusive: true,
      taxValueDropdown: 0,
    };
  }

  handleChangeTaxInclusiveCheck = (e, field) => {
    this.setState(
      { taxInclusive: e.target.checked, taxValueDropdown: 0 },
      () => {
        const fields = this.props.formRefTempAddStages.current.getFieldValue();
        const { stages } = fields;
        Object.assign(stages[field.key], {
          gst:
            !this.state.taxInclusive && this.state.taxValueDropdown !== 0
              ? this.state.taxValueDropdown
              : 0,
          taxInclusive: e.target.checked,
          taxName: this.state.taxInclusive ? "" : "",
        });
        this.props.formRefTempAddStages.current.setFieldsValue({ stages });
      }
    );
  };

  onChangeTaxDropdownValue = (data, field) => {
    let _taxDropdownValue = data === "" ? null : JSON.parse(data);
    this.setState(
      {
        taxValueDropdown: data === "" ? 0 : _taxDropdownValue.percent,
      },
      () => {
        const fields = this.props.formRefTempAddStages.current.getFieldValue();
        const { stages } = fields;
        Object.assign(stages[field.key], {
          gst: this.state.taxInclusive
            ? 0
            : data === ""
            ? 0
            : _taxDropdownValue.percent,
          taxName: data === "" ? " " : _taxDropdownValue.name,
        });
        this.props.formRefTempAddStages.current.setFieldsValue({ stages });
      }
    );
  };

  handleChangeTaxPercentage = (value, field) => {
    if (value === null) {
      const fields = this.props.formRefTempAddStages.current.getFieldValue();
      const { stages } = fields;
      Object.assign(stages[field.key], { gst: 0 });
      this.props.formRefTempAddStages.current.setFieldsValue({ stages });
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={4} offset={1}>
            <Form.Item
              initialValue=""
              {...this.props.field}
              name={[this.props.field.name, "stageName"]}
              fieldKey={[this.props.field.fieldKey, "stageName"]}
            >
              <p>{`Stage ${this.props.index + 1}`}</p>
            </Form.Item>
          </Col>
          <Col xs={11}>
            <Form.Item
              {...this.props.field}
              name={[this.props.field.name, "description"]}
              fieldKey={[this.props.field.fieldKey, "description"]}
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <TextArea placeholder={"Description!"} maxLength={2000} rows={4} />
            </Form.Item>
          </Col>
          <Col xs={5} style={{ marginLeft: "8px" }}>
            <Form.Item
              {...this.props.field}
              name={[this.props.field.name, "amount"]}
              fieldKey={[this.props.field.fieldKey, "amount"]}
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value < 0) {
                      return Promise.reject("Type only positive numbers");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <InputNumber placeholder={"Amount!"} />
            </Form.Item>
          </Col>
          <Col xs={2} style={{ marginTop: "13px" }}>
            <a
              href="javascript:"
              onClick={(e) => {
                e.stopPropagation();
                this.props.remove(this.props.field.name);
              }}
            >
              <DeleteOutlined className={"positioning-delete-icon"} />
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs={23} offset={1}>
            <Form.Item
              initialValue={true}
              {...this.props.field}
              name={[this.props.field.name, "taxInclusive"]}
              fieldKey={[this.props.field.fieldKey, "taxInclusive"]}
            >
              <Checkbox
                onChange={(e) =>
                  this.handleChangeTaxInclusiveCheck(e, this.props.field)
                }
                checked={this.state.taxInclusive}
              >
                {<span className={"filter-text"}>Tax inclusive</span>}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {!this.state.taxInclusive ? (
          <div>
            <Row style={{ marginTop: "-20px" }}>
              <Col xs={21} offset={1}>
                <Form.Item
                  {...this.props.field}
                  initialValue=""
                  fieldKey={[this.props.field.fieldKey, "taxName"]}
                  name={[this.props.field.name, "taxName"]}
                >
                  <Select
                    virtual={false}
                    placeholder="Please Select Tax!"
                    size="medium"
                    onChange={(e) =>
                      this.onChangeTaxDropdownValue(e, this.props.field)
                    }
                  >
                    <Option value={""}>{"Please select tax!"}</Option>
                    {this.props &&
                      this.props.taxListing &&
                      this.props.taxListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={JSON.stringify(data)} key={data.id}>
                            {data.name}-{data.percent}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "-31px" }}>
              <Col xs={23} offset={1}>
                <Form.Item
                  {...this.props.field}
                  initialValue={0}
                  fieldKey={[this.props.field.fieldKey, "gst"]}
                  name={[this.props.field.name, "gst"]}
                  colon={false}
                  labelAlign="left"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="medium"
                    onChange={(e) =>
                      this.handleChangeTaxPercentage(e, this.props.field)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ display: "none" }}>
            <Row style={{ marginTop: "-20px" }}>
              <Col xs={21} offset={1}>
                <Form.Item
                  {...this.props.field}
                  initialValue=""
                  fieldKey={[this.props.field.fieldKey, "taxName"]}
                  name={[this.props.field.name, "taxName"]}
                >
                  <Select
                    virtual={false}
                    placeholder="Please Select Tax!"
                    size="medium"
                    onChange={(e) =>
                      this.onChangeTaxDropdownValue(e, this.props.field)
                    }
                  >
                    <Option value={""}>{"Please select tax!"}</Option>
                    {this.props &&
                      this.props.taxListing &&
                      this.props.taxListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={JSON.stringify(data)} key={data.id}>
                            {data.name}-{data.percent}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "-31px" }}>
              <Col xs={23} offset={1}>
                <Form.Item
                  {...this.props.field}
                  initialValue={0}
                  fieldKey={[this.props.field.fieldKey, "gst"]}
                  name={[this.props.field.name, "gst"]}
                  colon={false}
                  labelAlign="left"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="medium"
                    onChange={(e) =>
                      this.handleChangeTaxPercentage(e, this.props.field)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col xs={22} className={"border-add-template-stages"}></Col>
        </Row>
      </div>
    );
  }
}

export default Stage;
