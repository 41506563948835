import React, { useEffect, useState } from "react";
import {
  FileTextOutlined,
  MenuOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Table, message, Tooltip } from "antd";
import moment from "moment";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

let clientprofileid = sessionStorage.getItem("clientprofileid");

const ContactEmployerTable = ({
  onGetLetterTemplates,
  LetterTemplatesRes,
  storeData,
  setIsModalVisible,
  setCreateMode,
  setUpdatedata,
  updatedata,

  onSortLetterTemplates,

  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  userDataEmp,

  onRemoveEmployerContact,

  isModalVisible,

  showModal,

  showEditContact,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    if (userDataEmp) {
      ongetEmployerContact(userDataEmp && userDataEmp.id).then(() => {
        // setLoading(false);
      });
    }
  }, [ongetEmployerContact, userDataEmp]);

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveEmployerContact(remove).then(() => {
      ongetEmployerContact(userDataEmp && userDataEmp.id);
      setLoading(false);
      message.success("Successfully Deleted!");
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.name}
        </p>
      ),
      // width: "10%"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.email}
        </p>
      ),
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (text, record) => (
        <p
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "initial",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {record.number}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <Tooltip placement="topLeft" title={`Update`}>
              <EditOutlined
                style={{ fontSize: "15px" }}
                onClick={() => showModal(record)}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title={`Delete`}>
              <DeleteOutlined
                style={{ fontSize: "15px" }}
                onClick={() => removeTag(record && record.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className="contact-tables table-head employer-tables border-3 school-table "
      dataSource={EmployerContactRes && EmployerContactRes.items}
      columns={columns}
      pagination={false}
      scroll={{ x: "auto" }}
    />
  );
};
export default ContactEmployerTable;
