import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
} from "antd";
import { bindActionCreators } from "redux";
import { getInvoicesGraph } from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Column } from "@ant-design/charts";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsInvoicesGraphData: [],
      config: {
        xField: "date",
        yField: "total",
        // label: {
        //   position: 'none',
        //   // style: {
        //   //   display: "none",
        //   //   fill: '#FFFFFF',
        //   //   opacity: 0.6,
        //   // },
        // },
        meta: {
          date: { alias: "Date" },
          total: { alias: "Invoice Taxable Amount" },
        },
      },
      dateFrom: "1900-01-01T00:00:00+00",
      dateTo: "1900-01-01T00:00:00+00",
      loading: false,
      isGraphVisible: true,
    };
  }

  componentWillMount() {
    this.state.config.data = this.state.accountsInvoicesGraphData;
    this.setState(this.state);
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props
        .getInvoicesGraph(this.state)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _accountsInvoicesGraphData =
      nextProps &&
      nextProps.accountsInvoicesGraphData &&
      nextProps.accountsInvoicesGraphData;

    if (
      _accountsInvoicesGraphData !== undefined &&
      this.state.accountsInvoicesGraphData !== _accountsInvoicesGraphData
    ) {
      this.setState(
        {
          accountsInvoicesGraphData: _accountsInvoicesGraphData,
        },
        () => {
          this.state.config.data = this.state.accountsInvoicesGraphData;
          this.setState(this.state);
        }
      );
    }
  }

  handleChangeDateFrom = (date, dateString) => {
    this.setState(
      {
        dateFrom:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {
        this.setState({ loading: true }, () => {
          this.props
            .getInvoicesGraph(this.state)
            .then(() => {
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        });
      }
    );
  };

  handleChangeDateTo = (date, dateString) => {
    this.setState(
      {
        dateTo:
          date === null
            ? "1900-01-01T00:00:00+00"
            : moment(date).format("YYYY-MM-DDT00:00:00+00:00"),
      },
      () => {
        this.setState({ loading: true }, () => {
          this.props
            .getInvoicesGraph(this.state)
            .then(() => {
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        });
      }
    );
  };

  onClickHide = () => {
    this.setState(
      {
        isGraphVisible: !this.state.isGraphVisible,
        dateFrom: "1900-01-01T00:00:00+00",
        dateTo: "1900-01-01T00:00:00+00",
      },
      () => {
        this.setState({ loading: true }, () => {
          this.props
            .getInvoicesGraph(this.state)
            .then(() => {
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        });
      }
    );
  };

  render() {
    return (
      <div>
        <div className="page-container" style={{ marginTop: "-30px" }}>
          <div
            className="ts-container-settings-graph-accounts "
            style={{ height: "95vh", maxHeight: "95vh", overflowY: "auto" }}
          >
            <Row style={{ backgroundColor: "#1081b9" }}>
              <Col xs={19} style={{ height: "30px" }}>
                <h5
                  style={{
                    color: "white",
                    display: "inline-block",
                    marginLeft: "21px",
                    verticalAlign: "sub",
                  }}
                >
                  <i>GRAPHS</i>
                </h5>
              </Col>
              <Col xs={3} offset={2}>
                <p
                  style={{
                    color: "white",
                    verticalAlign: "sub",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  onClick={this.onClickHide}
                >
                  {this.state.isGraphVisible ? "HIDE" : "SHOW"}
                </p>
              </Col>
            </Row>
            {this.state.isGraphVisible ? (
              <Row style={{ marginBottom: "28px", marginTop: "19px" }}>
                <Col xs={11} offset={1}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "80%" }}>
                      <DatePicker
                        placeholder="Date From"
                        size="small"
                        format={dateFormat}
                        onChange={this.handleChangeDateFrom}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={11} offset={1}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "80%" }}>
                      <DatePicker
                        placeholder="Date To"
                        size="small"
                        format={dateFormat}
                        onChange={this.handleChangeDateTo}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
            {this.state.loading ? (
              <div className={"spinner"}>
                <Spin size="large" />
              </div>
            ) : this.state.isGraphVisible ? (
              <Row>
                <Col xs={24}>
                  <div style={{ margin: "20px 20px 20px 20px" }}>
                    <Column
                      {...this.state.config}
                      onReady={(plot) => {
                        plot.on("plot:click", (evt) => {
                          const { x, y } = evt;
                          const { xField } = plot.options;
                          const tooltipData = plot.chart.getTooltipItems({
                            x,
                            y,
                          });
                          console.log(tooltipData);
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountsInvoicesGraphData:
      state &&
      state.accountReducer &&
      state.accountReducer.accountsInvoicesGraphData &&
      state.accountReducer.accountsInvoicesGraphData,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoicesGraph: bindActionCreators(getInvoicesGraph, dispatch),
});
Graph = connect(mapStateToProps, mapDispatchToProps)(Graph);

export default withRouter(Graph);
