import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Switch,
  Space,
  Select,
  Spin,
} from "antd";

import { Images } from "./../../Themes";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { apiRefresh } from "../../services/api";
import { mode } from "@antv/l7-utils/es/statistics";

const layoutSendingReceipt = {
  labelCol: { span: 3, offset: 0 },
  wrapperCol: { span: 13, offset: 10 },
};

const layoutInner = {
  labelCol: { span: 2, offset: 0 },
  wrapperCol: { span: 13, offset: 11 },
};

const { Option } = Select;
const DocumentChecklistForm = ({
  loading,
  handleCancel,
  setLoading,
  checklistName,
  createMode,
  getUrl,
  updatedata,
  Mode,
  showModal,
  onAddDocumentChecklist,

  onGetDocumentChecklist,
  docChecklistRes,

  onUpdateDocumentChecklist,
  view,
  onGetChecklistItems,
  docChecklistItemRes,

  docuementResponse,
  clientprofileid,
  onGetEmployerCheckList,
}) => {
  const [form] = Form.useForm();

  console.log("Mode=========", Mode);

  // useEffect(() => {
  //   setLoading(true);
  //
  //   onGetChecklistItems().then(() => {
  //     setLoading(false);
  //   });
  // }, []);

  // useEffect(() => {
  //
  //   // Fields Set Form Start //
  //   form.setFieldsValue({
  //     name:
  //       docChecklistRes &&
  //       docChecklistRes.items[0] &&
  //       docChecklistRes.items[0].name,
  //     Discription: docChecklistRes && docChecklistRes.name,
  //   });
  // }, [docChecklistRes]);

  // const docChecklistitems = (docChecklistRes) => {

  // };

  const onFinish = (values) => {
    if (
      (values && values.checkListItems === undefined) ||
      (values && values.checkListItems && values.checkListItems.length === 0)
    ) {
      message.error("Please add atleast one item!");
    } else {
      setLoading(true);

      var checkListItemsData = [];
      var checkListItemsUpdate = [];

      if (values.checkListItems && values.checkListItems.length > 0) {
        for (var i = 0; i < values.checkListItems.length; i++) {
          checkListItemsData.push({
            id: 0,
            checkListId: view === "settings" ? 0 : Mode && Mode.id,
            name: values && values.checkListItems[i].name,
            required:
              values && values.checkListItems[i].required === undefined
                ? true
                : values && values.checkListItems[i].required,
            priority:
              values &&
              (values.checkListItems[i].priority === undefined ||
                values.checkListItems[i].priority === null)
                ? "0"
                : values && values.checkListItems[i].priority,
          });
        }
      }
      if (values.checkListItems && values.checkListItems.length > 0) {
        for (var j = 0; j < values.checkListItems.length; j++) {
          checkListItemsUpdate.push({
            id:
              values && values.checkListItems[j].id
                ? values.checkListItems[j].id
                : 0,
            checkListId:
              values && values.checkListItems[j].checkListId
                ? values.checkListItems[j].checkListId
                : Mode
                ? Mode.id
                : 0,
            name: values && values.checkListItems[j].name,
            required:
              values && values.checkListItems[j].required === undefined
                ? true
                : values && values.checkListItems[j].required,
            priority:
              values &&
              (values.checkListItems[j].priority === undefined ||
                values.checkListItems[j].priority === null)
                ? "0"
                : values && values.checkListItems[j].priority,
          });
        }
      }

      // console.log("Received values of form:", values);

      if (createMode === "add-document") {
        let data;
        view === "settings"
          ? (data = {
              name: values.name,
              description:
                values.description === undefined ? "" : values.description,
              checkListItems: checkListItemsData,
            })
          : (data = {
              subjectId: clientprofileid,
              name: values.name,
              description:
                values.description === undefined ? "" : values.description,
              checkListItems: checkListItemsData,
            });

        onAddDocumentChecklist(data)
          .then((res) => {
            handleCancel();
            getUrl(res.payload);
          })
          .catch(() => {
            setLoading(false);
          })
          .then(() =>
            onGetDocumentChecklist(
              view === "settings"
                ? "00000000-0000-0000-0000-000000000000"
                : clientprofileid
            )
          )
          .catch(() => {
            setLoading(false);
          })
          .then((res) => {
            setLoading(false);
            message.success("Successfully Added!");

            if (view === "settings") {
            } else {
              var link = res.payload.items[res.payload.items.length - 1].link;
              showModal("send-email", link, values.name);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      } else if (createMode === "update-document") {
        const updata = {
          id: Mode && Mode.id,
          name: values.name,
          subjectId: clientprofileid,
          description:
            values.description === undefined ? "" : values.description,
          checkListItems: checkListItemsUpdate,
        };

        const updDocChecklistOpt = {
          url: `v1/document/checklist`,
        };
        updDocChecklistOpt.types = [
          "UPDATE_DOCUMENT_CHECKLIST_SUCCESS",
          "UPDATE_DOCUMENT_CHECKLIST_FAILURE",
        ];

        apiRefresh
          .put(updDocChecklistOpt, updata)
          .then(() => {
            handleCancel();
          })
          .catch(() => {
            setLoading(false);
          })
          .then(() => onGetDocumentChecklist(clientprofileid))
          .catch(() => {
            setLoading(false);
          })
          .then(() => {
            setLoading(false);
            message.success("Successfully Updated!");
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        const updata = {
          id: updatedata && updatedata.id,
          name: values.name,
          description:
            values.description === undefined ? "" : values.description,
          checkListItems: checkListItemsUpdate,
        };

        onUpdateDocumentChecklist(updata)
          .then(() => handleCancel())
          .catch(() => {
            setLoading(false);
          })
          .then(() => onGetEmployerCheckList(clientprofileid))
          .catch(() => {
            setLoading(false);
          })
          .then(() => {
            setLoading(false);
            onGetDocumentChecklist(
              view === "settings"
                ? "00000000-0000-0000-0000-000000000000"
                : clientprofileid
            );
            message.success("Successfully Updated!");
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  // console.log(
  //   "docChecklistitemsdocChecklistitemsdocChecklistitems",
  //   docChecklistitems
  // );

  return (
    <Spin size="large" spinning={loading}>
      <Fragment>
        {Mode && (
          <Form
            onFinish={onFinish}
            form={form}
            initialValues={Mode}
            className="width-100"
            name="main"
          >
            <div className="border-box-checklist">
              <Form.Item
                name="name"
                label="Name"
                colon={false}
                {...layoutInner}
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    max: 100,
                    message: "Name should be less than 100 character",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                {...layoutSendingReceipt}
                style={{ marginTop: "12px" }}
                name="description"
                label="Description"
                colon={false}
              >
                <Input placeholder="Description" />
              </Form.Item>
            </div>

            {/* Hey this is testing Data */}

            <div style={{ marginTop: "12px" }} className="border-box-checklist">
              {/* THIS IS WORKING ORIGINAL Data */}

              <Form.List name="checkListItems">
                {(checkListItems, { add, remove }) => (
                  <div className="">
                    <Row className=" margin-contact-container">
                      <Col>
                        <span style={{ fontSize: "12px", color: "gray" }}>
                          Create a list of documents that you require from your
                          client, for example you can create a work visa
                          document list. Please note if you make a document
                          “Required” this means the client will not be able to
                          update any documents until the required document has
                          been added.
                        </span>
                      </Col>
                      <Col>
                        <Form.Item className="form-add-btn">
                          <img
                            style={{ marginBottom: "6px" }}
                            src={Images.addIcon}
                            className="icons-client "
                            type="primary"
                            onClick={() => add()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        {checkListItems.map((field, index) => (
                          <Row className="file-delete-head" key={field.key}>
                            <Col xs={2} style={{ paddingTop: "15px" }}>
                              <p>{index + 1}</p>
                            </Col>
                            <Col xs={13}>
                              <Form.Item
                                {...field}
                                fieldKey={[field.fieldKey, "first"]}
                                name={[field.name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <Input.TextArea rows={6} />
                              </Form.Item>
                            </Col>
                            <Col xs={3} offset={1}>
                              <Row>
                                <Col xs={24}>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      className="label-form"
                                      style={{
                                        marginTop: "19px",
                                        marginRight: "6px",
                                      }}
                                    >
                                      Required?
                                    </div>
                                    <Form.Item
                                      initialValue={true}
                                      {...field}
                                      key={[field.fieldKey, "required"]}
                                      name={[field.name, "required"]}
                                    >
                                      <Select>
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24}>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      className="label-form"
                                      style={{
                                        marginTop: "19px",
                                        marginRight: "23px",
                                      }}
                                    >
                                      Priority
                                    </div>
                                    <Form.Item
                                      initialValue="0"
                                      {...field}
                                      key={[field.fieldKey, "priority"]}
                                      name={[field.name, "priority"]}
                                    >
                                      <Select>
                                        <Option value="0">Low</Option>
                                        <Option value="1">High</Option>
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={2}
                              offset={3}
                              style={{ paddingTop: "15px" }}
                            >
                              <a
                                href="javascript:"
                                onClick={() => {
                                  if (
                                    Mode &&
                                    Mode.checkListItems[index] &&
                                    Mode.checkListItems[index].id &&
                                    createMode != "add-document"
                                  ) {
                                    let data = {
                                      id: Mode.checkListItems[index].id,
                                      delete: true,
                                    };
                                    var delDocChecklistOpt = null;
                                    Mode.checkListItems.splice(index, 1);
                                    if (createMode == "Update Doc Account") {
                                      delDocChecklistOpt = {
                                        url: `v1/temp/document/checklistItem`,
                                      };
                                    } else {
                                      delDocChecklistOpt = {
                                        url: `v1/document/checklistItem`,
                                      };
                                    }
                                    delDocChecklistOpt.types = [
                                      "DELETE_DOCUMENT_CHECKLIST_SUCCESS",
                                      "DELETE_DOCUMENT_CHECKLIST_FAILURE",
                                    ];
                                    setLoading(true);
                                    apiRefresh
                                      .delete(delDocChecklistOpt, data)
                                      .then((res) => {
                                        setLoading(false);
                                        remove(field.name);
                                      })
                                      .catch((err) => {
                                        setLoading(false);
                                      });
                                  } else {
                                    if (
                                      Mode &&
                                      Mode.checkListItems &&
                                      Mode.checkListItems.length > 0
                                    )
                                      Mode.checkListItems.splice(index, 1);
                                    remove(field.name);
                                  }
                                }}
                              >
                                <DeleteOutlined color="rgb(51, 170, 218)" />
                              </a>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </div>
                )}
              </Form.List>
            </div>

            <Row className="flex-end margin-top-12" gutter={10}>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    className="login-form-button save-btn"
                    htmlType="submit"
                    // onClick={() => showModal("send-email")}
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    onClick={() => handleCancel()}
                    type="primary"
                    className="login-form-button save-btn"
                  >
                    CLOSE
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Fragment>
    </Spin>
  );
};
export default DocumentChecklistForm;
