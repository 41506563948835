import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";
import DocumentChecklistForm from "../../Components/DocumentChecklist/DocumentChecklistForm";
import { Images } from "../../Themes";
import {
  Table,
  Modal,
  Spin,
  Button,
  message,
  Form,
  Row,
  Col,
  Tooltip,
} from "antd";
import { useLocation } from "react-router-dom";
import history from "../../services/history";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const DocumentChecklist = ({
  onGetDocumentChecklist,
  docChecklistRes,
  onAddDocumentChecklist,
  onUpdateDocumentChecklist,
  onRemoveDocumentChecklist,
  updateEmailContentDocumentChecklist,
  onGetEmailContent,
  onAddEmailContent,
  emailContentDocumentChecklistRes,
  onGetChecklistItems,
  docChecklistItemRes,
  getEmailContentDocumentCheckList,
  onSetActiveInnerTab,
  onAddLetterTemplateContent,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});
  const [letterString, setLetterString] = useState("");
  const [
    emailDocumentChecklistContent,
    setEmailDocumentChecklistContent,
  ] = useState("");
  const [docuementResponse, setDocuementResponse] = useState();
  const [itemId, setItemId] = useState();

  const handleCancelDeleteModal = () => {
    setVisibleDeleteModal(false);
  };

  const getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={visibleDeleteModal}
          onCancel={handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Spin size="large" spinning={loading}>
            <Row>
              <Col span={24}>
                <Row> Are you sure, you want to delete checklist?</Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", marginTop: "40px" }}>
              <Col span={5} offset={15}>
                <Button
                  onClick={() => handleCancelDeleteModal()}
                  style={{ borderRadius: "5px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  className={"button button-blue"}
                  onClick={() => removeTag(itemId)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);

    onGetDocumentChecklist()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [onGetDocumentChecklist]);

  useEffect(() => {
    setLoading(true);
    if (
      emailContentDocumentChecklistRes &&
      emailContentDocumentChecklistRes[0] &&
      emailContentDocumentChecklistRes[0].content
    )
      setEmailDocumentChecklistContent(
        emailContentDocumentChecklistRes &&
          emailContentDocumentChecklistRes[0] &&
          emailContentDocumentChecklistRes[0].content
      );
    setLoading(false);
  }, [emailContentDocumentChecklistRes]);

  useEffect(() => {
    setLoading(true);
    getEmailContentDocumentCheckList()
      .then((res) => {
        if (!res.payload?.items.length) {
          let content = `<p>Hi @ClientName</p><p>Attached below is your document checklist ,</p><p>Please provide all required documents</p><p>Please <a href="@ClientLink" style="background-color: rgb(255, 255, 255);">click here</a> to upload your documents.</p><p><br></p>`;
          setEmailDocumentChecklistContent(content);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [getEmailContentDocumentCheckList]);

  const removeTag = (id) => {
    setLoading(true);

    const remove = {
      id: id,
      delete: true,
    };

    onRemoveDocumentChecklist(remove)
      .then(() => {
        message.success("Successfully Deleted!");
        onGetDocumentChecklist()
          .then((res) => {
            setDocuementResponse(res);
            setLoading(false);
            setVisibleDeleteModal(false);
          })
          .catch(() => {
            setLoading(false);
            setVisibleDeleteModal(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const showModal = (value, updMode) => {
    setIsModalVisible(true);
    setCreateMode(updMode || value);
    setUpdatedata(value);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleModelChange = (model) => {
    setEmailDocumentChecklistContent(model);
  };

  const [form] = Form.useForm();
  const onFinish = (id) => {
    setLoading(true);
    const postEmail = {
      id:
        emailContentDocumentChecklistRes &&
        emailContentDocumentChecklistRes[0] &&
        emailContentDocumentChecklistRes[0].id
          ? emailContentDocumentChecklistRes[0].id
          : 0,
      key:
        emailContentDocumentChecklistRes &&
        emailContentDocumentChecklistRes[0] &&
        emailContentDocumentChecklistRes[0].id
          ? emailContentDocumentChecklistRes[0].id
          : 0,
      type: "DocumentCheckList",
      name: "DocumentCheckList",
      content: emailDocumentChecklistContent,
      header: false,
      defaultContract: false,
    };
    if (
      emailContentDocumentChecklistRes &&
      emailContentDocumentChecklistRes[0] &&
      emailContentDocumentChecklistRes[0].content
    ) {
      updateEmailContentDocumentChecklist(postEmail).then(() => {
        getEmailContentDocumentCheckList()
          .then(() => {
            setEmailDocumentChecklistContent(
              emailContentDocumentChecklistRes &&
                emailContentDocumentChecklistRes[0] &&
                emailContentDocumentChecklistRes[0].content
            );
            message.success("Template is updated successfully!");
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            message.error("An error occurred! Please try again!");
          });
      });
    } else {
      onAddLetterTemplateContent(postEmail).then(() => {
        getEmailContentDocumentCheckList()
          .then(() => {
            setEmailDocumentChecklistContent(
              emailContentDocumentChecklistRes &&
                emailContentDocumentChecklistRes[0] &&
                emailContentDocumentChecklistRes[0].content
            );
            message.success("Template is updated successfully!");
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            message.error("An error occurred! Please try again!");
          });
      });
    }
    // onAddLetterTemplateContent

    // }
    // else {
    //
    // const updateEmail = {
    //   id: 0,
    //   branchId: selectedBranchId,
    //   emailContent: letterString,
    //   emailType: "DocumentCheckList",
    //   userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    // };
    // onUpdateEmailContent(updateEmail).then(() => {
    //   onGetEmailContent();
    //   message.success("Successfully Updated!");
    // });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div className="table-action">
            <EditOutlined
              onClick={() => showModal(record, "Update Doc Account")}
            />
            <DeleteOutlined
              onClick={() => {
                setVisibleDeleteModal(true);
                setItemId(record && record.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item client-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.docChecklist} className="sus-bottom-icon" />
                </div>
                <span to="/client-tags" className="top-text">
                  DOCUMENT CHECKLIST
                </span>
                <Tooltip
                  placement="topLeft"
                  title={`Create specific document checklists so you can send an easy online form for your clients to easily upload their documents directly to their client files.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>
              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="document-checklist-box letter-froala">
              <h4>Document Checklist Email Template</h4>
              <Spin size="large" spinning={loading}>
                <Form
                  onFinish={onFinish}
                  form={form}
                  className="width-100"
                  name="main"
                >
                  <FroalaEditorCom
                    setLoading={(value) => setLoading(value)}
                    model={emailDocumentChecklistContent}
                    onModelChange={handleModelChange}
                  />

                  <div className="document-checklist--btn">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="button-blue"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              </Spin>
            </div>

            <div className="document-checklist-box">
              <div className="client-tag-form"></div>
              <div>
                <div className="client-tag-table">
                  <div className="add-tag-btn">
                    <>
                      <img
                        src={Images.addIcon}
                        className="icons-client"
                        type="primary"
                        onClick={() => showModal("add-document")}
                      />
                    </>
                  </div>
                  <Spin size="large" spinning={loading}>
                    <div className={"school-table"}>
                      <Table
                        columns={columns}
                        dataSource={docChecklistRes && docChecklistRes.items}
                        pagination={false}
                      />
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <Modal
          className="reminder-model-main"
          title={
            createMode === "add-document"
              ? "Add Document Checklist"
              : "Update Document Checklist"
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          header={false}
          footer={false}
          width={800}
        >
          <DocumentChecklistForm
            view={"settings"}
            loading={loading}
            onAddDocumentChecklist={onAddDocumentChecklist}
            onUpdateDocumentChecklist={onUpdateDocumentChecklist}
            handleCancel={handleCancel}
            onGetDocumentChecklist={onGetDocumentChecklist}
            docChecklistRes={docChecklistRes}
            setLoading={setLoading}
            updatedata={updatedata}
            createMode={createMode}
            Mode={updatedata}
            onAddEmailContent={onAddEmailContent}
            onGetChecklistItems={onGetChecklistItems}
            docChecklistItemRes={docChecklistItemRes}
            docuementResponse={docuementResponse}
          />
        </Modal>
      )}
      {getModalDelete()}
    </Fragment>
  );
};
export default DocumentChecklist;
