import * as types from "../Constants";

export const supervisorReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_BATCHES_SUCCESS:
      return { ...state, batches: action.payload };
    case types.GET_BATCHES_FAILURE:
      console.log("on error console", action.payload);
    case types.GET_BATCH_ACCOUNTS_SUCCESS:
      return { ...state, batchStudents: action.payload };
    case types.GET_BATCH_ACCOUNTS_FAILURE:
      console.log("on error console", action.payload);
    default:
      return state;
  }
};
