import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QuestionnaireList from "../CustomQuestionnaire/QuestionnaireList";

import {
  getAllQuestionnaire,
  duplicateQuestionnaire,
  getQuestionnaireGroup,
  groupedQuestionnaire,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    questionnaireList: state.questionnaireReducer.questionnaireList,
    questionnaireGroupRes: state.questionnaireReducer.questionnaireGroupRes,
    groupedQuestionnaireRes: state.questionnaireReducer.groupedQuestionnaireRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllQuestionnaire: bindActionCreators(getAllQuestionnaire, dispatch),
    onCreateDuplicateQuestionnaire: bindActionCreators(
      duplicateQuestionnaire,
      dispatch
    ),
    onGetQuestionnaireGroup: bindActionCreators(
      getQuestionnaireGroup,
      dispatch
    ),
    onGetGroupedQuestionnaire: bindActionCreators(
      groupedQuestionnaire,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireList);
