import { api } from "../../services/api";
import * as types from "../Constants";
import {
  ACCOUNT_DAILY_TRANSACTIONS_FAILURE,
  ACCOUNT_DAILY_TRANSACTIONS_SUCCESS,
  GET_ALL_TYPES_INVOICES_SUCCESS,
  GET_DUE_OVER_INVOICES_LISTING_FAILURE,
  GET_DUE_OVER_INVOICES_LISTING_SUCCESS,
  GET_INVOICES_GRAPH_LISTING_SUCCESS,
  GET_TEMPLATE_ITEM_INFO_FAILURE,
  GET_TEMPLATE_ITEM_INFO_SUCCESS,
  GET_WORK_COMPLETED_INVOICES_LISTING_SUCCESS,
  GET_WORK_IN_PROGRESS_INVOICES_LISTING_SUCCESS,
} from "../Constants";

export const createAccountInvoice = (data) => {
  const options = {
    url: "v1/invoice",
  };

  options.types = [
    types.ACCOUNT_CREATE_INVOICE_SUCCESS,
    types.ACCOUNT_CREATE_INVOICE_FAILURE,
  ];

  return api.post(options, data);
};

export const getIncomingOutgoingPayments = (data) => {
  let _data = {
    clientId: data.clientprofileid,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
  };
  const options = {
    url: "v1/payment/incomingoutgoing/All",
  };
  options.types = [
    types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_SUCCESS,
    types.ACCOUNT_INCOMING_OUTGOING_PAYMENTS_FAILURE,
  ];

  return api.post(options, _data);
};

export const getDailyTransactions = (data) => {
  let _data = {
    clientId: data.clientProfileId,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
  };
  const options = {
    url: "v1/payment/dailytransaction",
  };
  options.types = [
    types.ACCOUNT_DAILY_TRANSACTIONS_SUCCESS,
    types.ACCOUNT_DAILY_TRANSACTIONS_FAILURE,
  ];

  return api.post(options, _data);
};

export const getDueOverDueInvoices = (data) => {
  let _data = {
    subjectTypeId: data.subjectTypeId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    name: data.name,
    email: data.email,
    clientTagId: data.clientTagId,
    invoiceNo: data.invoiceNo,
    processingPerson:
      data.processingPerson ||
      (localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "accounts").status == 1 &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "potential clients").status ==
          0 &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "client database").status == 0 &&
        localStorage.getItem("userId")) ||
      "00000000-0000-0000-0000-000000000000",
  };
  const options = {
    url: "v1/invoice/overdue",
  };
  options.types = [
    types.GET_DUE_OVER_INVOICES_LISTING_SUCCESS,
    types.GET_DUE_OVER_INVOICES_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const getAllTypesInvoices = (data) => {
  let _data = {
    subjectTypeId: data.subjectTypeId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    name: data.name,
    email: data.email,
    clientTagId: data.clientTagId,
    invoiceNo: data.invoiceNo,
    processingPerson:
      data.processingPerson ||
      (localStorage.getItem("userSystemPermissions") &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "accounts").status == 1 &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "potential clients").status ==
          0 &&
        JSON.parse(
          decodeURIComponent(
            escape(window.atob(localStorage.getItem("userSystemPermissions")))
          )
        ).find((x) => x.role.toLowerCase() == "client database").status == 0 &&
        localStorage.getItem("userId")) ||
      "00000000-0000-0000-0000-000000000000",
  };
  const options = {
    url: "v1/invoice/AllInvoices",
  };
  options.types = [
    types.GET_ALL_TYPES_INVOICES_SUCCESS,
    types.GET_ALL_TYPES_INVOICES_FAILURE,
  ];

  return api.post(options, _data);
};

export const getWorkInProgressInvoices = (data) => {
  let _data = {
    subjectTypeId: data.subjectTypeId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    name: data.name,
    email: data.email,
    clientTagId: data.clientTagId,
    invoiceNo: data.invoiceNo,
  };
  const options = {
    url: "v1/invoice/workinprogress",
  };
  options.types = [
    types.GET_WORK_IN_PROGRESS_INVOICES_LISTING_SUCCESS,
    types.GET_WORK_IN_PROGRESS_INVOICES_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const getWorkCompletedInvoices = (data) => {
  let _data = {
    subjectTypeId: data.subjectTypeId,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    name: data.name,
    email: data.email,
    clientTagId: data.clientTagId,
    invoiceNo: data.invoiceNo,
  };
  const options = {
    url: "v1/invoice/workcompleted",
  };
  options.types = [
    types.GET_WORK_COMPLETED_INVOICES_LISTING_SUCCESS,
    types.GET_WORK_COMPLETED_INVOICES_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const getInvoicesGraph = (data) => {
  let _data = {
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
  };
  const options = {
    url: "v1/invoice/InvoiceGraph",
  };
  options.types = [
    types.GET_INVOICES_GRAPH_LISTING_SUCCESS,
    types.GET_INVOICES_GRAPH_LISTING_FAILURE,
  ];

  return api.post(options, _data);
};

export const getTemplateInvoiceInfo = (id) => {
  const options = {
    url: `v1/invoice/item/${id}`,
  };
  options.types = [
    types.GET_TEMPLATE_ITEM_INFO_SUCCESS,
    types.GET_TEMPLATE_ITEM_INFO_FAILURE,
  ];

  return api.get(options);
};
