import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EzmCommonDocs from "../AccountSetting/EzmCommonDocs/EzmCommonDocs";

import {
  getDocuments,
  addDocument,
  removeDocument,
  setActiveInnerTab,
  getDocumentDownload,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    documentRes: state.accountDocumentReducer.documentRes,
    addDocumentRes: state.accountDocumentReducer.addDocumentRes,
    removeDocumentRes: state.accountDocumentReducer.removeDocumentRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocuments: bindActionCreators(getDocuments, dispatch),
    onAddDocument: bindActionCreators(addDocument, dispatch),
    onRemoveDocument: bindActionCreators(removeDocument, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EzmCommonDocs);
