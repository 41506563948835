import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Select,
  Table,
  DatePicker,
  Modal,
  message,
  Form,
  Upload,
  InputNumber,
} from "antd";
import { bindActionCreators } from "redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getClientTag,
  getPotentialClientListing,
  getVisaType,
  getPotentialClientInfo,
  getTeamMember,
  getPotentialClientUpdateBol,
  getCountries,
  getClientSource,
  uploadAvatar,
  addNewClient,
  checkUniqueEmail,
  addProcessingPerson,
  getBranchVisaCountries,
  getMailBox,
  autoImportEmailClient,
} from "../store/Actions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import activityData from "../Components/ClientActivity/ActivityData";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import { apiRefresh } from "../services/api";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
let selectedBranchId = localStorage.getItem("selectedBranchId");
const visaCountries = [
  {
    label: "New Zealand",
    id: 1,
  },
  {
    label: "Australia",
    id: 2,
  },
  {
    label: "Both",
    id: 3,
  },
];

const medicalNotes = [
  {
    label: "ASH",
  },
  {
    label: "ASH with Conditions",
  },
  {
    label: "Not ASH",
  },
];

const gradings = [
  {
    label: "A",
  },
  {
    label: "AB",
  },
  {
    label: "B",
  },
];

const types = [
  {
    label: "Student",
    id: 1,
  },
  {
    label: "Visa",
    id: 2,
  },
  {
    label: "Unsuccessful",
    id: 3,
  },
];

const headOption = [
  { tabName: "Personal Detail", linkName: "/add-new-client" },
];

const genderTypes = [
  {
    label: "Male",
    id: 1,
  },
  {
    label: "Female",
    id: 2,
  },
];

const maritalStatuses = [
  {
    label: "Single",
  },
  {
    label: "Married",
  },
  {
    label: "Widowed",
  },
  {
    label: "Defacto",
  },
  {
    label: "Separated",
  },
  {
    label: "Divorced",
  },
];

const JobSectors = [
  {
    label: "Administrative",
    id: 1,
  },
  {
    label: "Agriculture / Farming",
    id: 2,
  },
  {
    label: "Automotive",
    id: 3,
  },
  {
    label: "Construction",
    id: 4,
  },
  {
    label: "Electrical",
    id: 5,
  },
  {
    label: "Engineer",
    id: 6,
  },
  {
    label: "Finance",
    id: 7,
  },
  {
    label: "FMCG",
    id: 8,
  },
  {
    label: "Hospitality",
    id: 9,
  },
  {
    label: "Human Resources",
    id: 10,
  },
  {
    label: "Insurance",
    id: 11,
  },
  {
    label: "Legal",
    id: 12,
  },
  {
    label: "Marketing",
    id: 13,
  },
  {
    label: "Medical",
    id: 14,
  },
  {
    label: "Real estate",
    id: 15,
  },
  {
    label: "Retail",
    id: 16,
  },
  {
    label: "Sales",
    id: 17,
  },
  {
    label: "Supply chain",
    id: 18,
  },
  {
    label: "Teachers",
    id: 19,
  },
  {
    label: "Trades",
    id: 20,
  },
  {
    label: "Not Employed",
    id: 21,
  },
  {
    label: "Transportation",
    id: 22,
  },
  {
    label: "Manufacturing",
    id: 23,
  },
];

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const layoutInnerForms = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

class AddNewClient extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      notes: "",
      medicalNotesDetail: "",
      loading: false,
      policeCertificateCountry1: 0,
      policeCertificateIssueDate1: "1900-01-01T00:00:00.000Z",
      policeCertificateCountry2: 0,
      policeCertificateIssueDate2: "1900-01-01T00:00:00.000Z",
      policeCertificateCountry3: 0,
      policeCertificateIssueDate3: "1900-01-01T00:00:00.000Z",
      branchId: selectedBranchId,
      imageBlobUrl: "",
      mobile: "",
      setValues: false,
      loadSaveClient: false,
    };
  }

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  componentDidMount() {
    this.props.getCountries();
    this.props.getTeamMember();
    this.props.getClientSource();
    this.props.onGetBVisaCountries();
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _bol = this.props && this.props.bol;
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     if (_bol === undefined) {
    //       this.props.history.push("/profile");
    //     }
    //   }
    // }
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {});
    }
    let potentialClientInfo =
      this.props &&
      this.props.potentialClientInfo &&
      this.props.potentialClientInfo;

    if (
      potentialClientInfo &&
      potentialClientInfo.id &&
      !this.state.setValues
    ) {
      this.setState(
        {
          firstName: potentialClientInfo.firstName,
          lastName: potentialClientInfo.lastName,
          dateOfBirth: potentialClientInfo.dob,
          email: potentialClientInfo.email,
          mobile:
            potentialClientInfo.mobile === undefined
              ? ""
              : potentialClientInfo.mobile,
          address: potentialClientInfo.address,
          notes:
            potentialClientInfo.notes === undefined
              ? ""
              : potentialClientInfo.notes,
          dealWorth: potentialClientInfo.worth,
          visaTypeId: potentialClientInfo.visaTypeId,
          currentVisaTypeId: potentialClientInfo.visaTypeId,
          phone: potentialClientInfo.phone,
          agentId: potentialClientInfo.salePerson,
          intrestedVisa: potentialClientInfo.intrestedVisa,
          sourceId: potentialClientInfo.sourceId,
          sourceDescription: potentialClientInfo.sourceDescription,
          clientStatus: potentialClientInfo.clientStatus,
          occupation: potentialClientInfo.occupation,
          potentialClientId: potentialClientInfo.id,
          countryCode: potentialClientInfo.countryCode,
          setValues: true,
        },
        () => {
          console.log("its state", this.state);
        }
      );

      let _dateOfBirth;
      if (
        potentialClientInfo.dob === undefined ||
        potentialClientInfo.dob === ""
      ) {
        _dateOfBirth = undefined;
      } else {
        _dateOfBirth = potentialClientInfo.dob;
      }
      let _sourceId;
      if (
        potentialClientInfo.sourceId === undefined ||
        potentialClientInfo.sourceId === 0
      ) {
        _sourceId = undefined;
      } else {
        _sourceId = potentialClientInfo.sourceId;
      }
      let _countryCode;
      if (
        potentialClientInfo.countryCode === undefined ||
        potentialClientInfo.countryCode === 0
      ) {
        _countryCode = undefined;
      } else {
        _countryCode =
          this.state._countryCode || potentialClientInfo.countryCode;
      }
      this.formRef.current.setFieldsValue({
        clientStatus: potentialClientInfo.clientStatus,
        firstName: potentialClientInfo.firstName,
        lastName: potentialClientInfo.lastName,
        email: potentialClientInfo.email,
        address: potentialClientInfo.address,
        phone: potentialClientInfo.phone,
        intrestedVisa: potentialClientInfo.intrestedVisa,
        dealWorth: potentialClientInfo.worth,
        agentId: potentialClientInfo.salePerson,
        sourceDescription: potentialClientInfo.sourceDescription,
        occupation: potentialClientInfo.occupation,
        sourceId: _sourceId,
        currentVisaTypeId:
          potentialClientInfo && potentialClientInfo.visaTypeId,
        dateOfBirth:
          _dateOfBirth === undefined
            ? undefined
            : moment(_dateOfBirth, dateFormat),
        mobile: potentialClientInfo.mobile,
        countryCode: _countryCode,
      });
    }
  }

  onHandleChangeInputNumber = (value) => {
    if (value && value) {
      this.setState({ mobile: value.toString() });
    }
  };

  handleChange = ({ fileList }) => {
    let _type =
      fileList &&
      fileList[fileList.length - 1] &&
      fileList[fileList.length - 1].type;

    if (
      _type === "image/jpeg" ||
      _type === "image/jpg" ||
      _type === "image/png" ||
      _type === undefined
    ) {
      this.setState({ fileList }, () => {
        let formData = new FormData();
        let selectedFile = fileList && fileList[0] && fileList[0].originFileObj;
        formData.append("File", selectedFile);
        if (_type !== undefined) {
          // this.setState({ loading: true });
        }
        if (selectedFile) {
          this.props.onUploadAvatar(formData).then(() => {
            if (this.props.imageUploadSuccess) {
              this.setState({
                imageBlobUrl: this.props.imageUploadSuccess,
              });
            }
          });
        }
      });
    } else {
      message.warning("Only 'png','jpg' & 'jpeg' type of images are allowed");
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  getUploadImage = (
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    uploadButton
  ) => {
    const { selectedOption } = this.state;
    return (
      <div className="ts-container-client">
        <Row>
          <Col xs={24} style={{ marginTop: 5, marginBottom: 15 }}>
            <h4 className={"upload-image-heading"} style={{ fontSize: 14 }}>
              {"UPLOAD IMAGE"}
            </h4>
          </Col>
        </Row>
        <Upload
          listType="picture-card"
          fileList={fileList}
          beforeUpload={() => false}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList && fileList.length > 0 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  };

  getPersonalInfoForm = () => {
    let config = {
      key:
        "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
      height: "auto",
      toolbarSticky: false,
      events: {
        "charCounter.update": () => {
          // Do something here.
          // this is the editor instance.
          console.log("char");
        },
      },
    };
    return (
      <div className="personal-info-add-client-container">
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Visa Country"
              name="visaCountryId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="PLEASE SELECT"
                onChange={(e) => {
                  this.setState({ _countryCode: e });
                  this.formRef.current.setFieldsValue({
                    countryCode: e,
                  });
                }}
              >
                {this.props.brnchVisaCountriesData &&
                  this.props.brnchVisaCountriesData.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return (
                      <Option value={data.countryId}>{data.countryName}</Option>
                    );
                  })}

                {/* {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Type"
              name="visaCountyType"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select placeholder="SELECT">
                {types.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.id}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Existing Client Serial Number / Internal ID"
              name="clientSerial"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Title"
              name="title"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Client's First Name"
              name="firstName"
              rules={[{ required: true, message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Middle Name"
              name="middleName"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Other Name"
              name="preferredName"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Gender"
              name="gender"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT"
              >
                {genderTypes.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.id}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Primary Email"
              name="email"
              rules={[{ type: "email", message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Secondary Email"
              name="secondaryEmail"
              rules={[{ type: "email", message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Other Email"
              name="otherEmail"
              rules={[{ type: "email", message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Date Of Birth"
              name="dateOfBirth"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Address"
              name="address"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Deal Worth"
              name="dealWorth"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Mobile Phone"
              style={{ display: "flex" }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "-31px",
                  marginTop: "-14px",
                }}
              >
                <Form.Item
                  className={"add-client-form-inputs"}
                  name="countryCode"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={"Select"}
                    style={{ width: "150px", minWidth: "150px", marginTop: 13 }}
                    dropdownClassName="dropdown-options-width"
                  >
                    {this.props.countriesData &&
                      this.props.countriesData
                        .filter(
                          (obj) =>
                            obj.name.toLowerCase() === "new zealand" ||
                            obj.name.toLowerCase() === "australia" ||
                            obj.name.toLowerCase() === "canada"
                        )
                        .map((data) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          return (
                            <Option
                              value={data.id}
                              style={{ fontSize: "14px" }}
                            >
                              +{data.callingCode} - {data.name}
                            </Option>
                          );
                        })}

                    {this.props.countriesData &&
                      this.props.countriesData.map((data, index) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        if (
                          data.name.toLowerCase() === "new zealand" ||
                          data.name.toLowerCase() === "australia" ||
                          data.name.toLowerCase() === "canada"
                        ) {
                        } else {
                          return (
                            <Option
                              value={data.id}
                              style={{ fontSize: "14px" }}
                            >
                              +{data.callingCode} - {data.name}
                            </Option>
                          );
                        }
                      })}
                  </Select>
                </Form.Item>
                <div style={{ width: "100%" }}>
                  <Form.Item
                    className={
                      ("add-client-form-inputs", "mobile-field-margin-left")
                    }
                    name="mobile"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (Math.ceil(Math.log10(value + 1)) > 15) {
                            return Promise.reject("Character limit exceeded");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      // style={{ width: "288px" }}
                      onChange={this.onHandleChangeInputNumber}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Secondary Mobile"
              name="secondaryMobile"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Overseas Mobile #"
              name="overseasMobile"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Land Line"
              name="phone"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Other Contact Information"
              name="otherContactInformation"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Marital Status"
              name="maritalStatus"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT"
              >
                {maritalStatuses.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.label}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Number Of Dependent Children"
              name="dependentChildren"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <InputNumber style={{ width: "-webkit-fill-available" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="National ID"
              name="nationalityId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Sale Date"
              name="saleDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Sales Person"
              name="agentId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT SALES PERSON"
              >
                {this.props &&
                  this.props.teamMembers &&
                  this.props.teamMembers.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.fullName}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Client Source"
              name="sourceId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT CLIENT'S SOURCE"
              >
                {this.props &&
                  this.props.clientSourceListing &&
                  this.props.clientSourceListing.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Source Description"
              name="sourceDescription"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Job Sector"
              name="jobSectorId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT JOB SECTOR"
              >
                {JobSectors.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.id}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Occupation"
              name="occupation"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Company (Optional)"
              name="companyOptional"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Notes"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "101%" }}>
          <Col xs={23} style={{ marginLeft: "32px" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <div className="letter-froala">
              <FroalaEditorCom
                model={this.state.notes}
                onModelChange={this.getEditorNotesValue}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  getEditorNotesValue = (value) => {
    this.setState({ notes: value });
  };

  getEditorMedicalNotesValue = (value) => {
    this.setState({ medicalNotesDetail: value });
  };

  getBillingAddressForm = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Contact Person"
              name="contactPersonBilling"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="House"
              name="flat"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Street"
              name="streetName"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Suburb"
              name="suburb"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="City"
              name="city"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Country"
              name="country"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"Select"}
              >
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Postal Code"
              name="zip"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getPassportDetail = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Passport Number"
              name="passportNo"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Country Of Issue"
              name="passportCountry"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"Select"}
              >
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Passport Issue Date"
              name="passportIssueDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Passport Expiry Date"
              name="passportExpiryDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getSecondPassportDetail = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Second Passport Number"
              name="secondPassportNumber"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Second Country Of Issue"
              name="secondIssueCountry"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"Select"}
              >
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Secondary Passport Issue Date"
              name="secondaryIssueDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Second Passport Expiry Date"
              name="secondExpiryDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getCurrentVisaDetail = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Current Visa Type"
              name="currentVisaTypeId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="PLEASE SELECT VISA"
              >
                {this.props &&
                  this.props.visaTypeData &&
                  this.props.visaTypeData.items &&
                  this.props.visaTypeData.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.visaTypeName}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Current Visa Expiry Date"
              name="currentNewZealandVisaExpiry"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Travel Conditions Valid To"
              name="travelConditionsValidTo"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getMedicalDetail = () => {
    let config = {
      key:
        "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
      height: "auto",
      toolbarSticky: false,
      events: {
        "charCounter.update": () => {
          // Do something here.
          // this is the editor instance.
          console.log("char");
        },
      },
    };
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="NZER#"
              name="er"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Medical Certificate Issue Date"
              name="medicalIssueDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Medical Certificate Expiry Date"
              name="medicalExpiryDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Medical Grading"
              name="medicalGrading"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT"
              >
                {gradings.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.label}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Xray Issue Date"
              name="xrayIssueDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Xray Expiry Date"
              name="xrayExpiryDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Xray Grading"
              name="xrayGrading"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT"
              >
                {gradings.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.label}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Medical Notes"
              name="medicalNotes"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT"
              >
                {medicalNotes.map((data) => {
                  // eslint-disable-next-line react/jsx-no-undef
                  return <Option value={data.label}>{data.label}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "101%", marginTop: "40px" }}>
          <Col xs={23} style={{ marginLeft: "32px" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <div className="letter-froala">
              <FroalaEditorCom
                model={this.state.medicalNotesDetail}
                onModelChange={this.getEditorMedicalNotesValue}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  getLoginDetails = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Client Number"
              name="clientNumber"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Username"
              name="inzUserName"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Password"
              name="inzPassword"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getNZQADetails = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="NZQA Online Submission Date"
              name="nzqaOnlineSubDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="NZQA Documents Submission Date"
              name="nzqaDocumentSubDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              {...layoutInnerForms}
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="NZQA Documents Returned Date"
              name="nzqaDocumentRetDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  getPoliceCertificateInfo = () => {
    return (
      <div className={"personal-info-add-client-container"}>
        <Row>
          <Col xs={3}>
            <h4 style={{ textAlign: "center" }}>{"Sr#"}</h4>
          </Col>
          <Col xs={13} style={{ textAlign: "left" }}>
            <span>Police Certificate Date</span>
          </Col>
          <Col xs={7} offset={1} style={{ textAlign: "left" }}>
            <span>Country</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col xs={3}>
            <h4 style={{ textAlign: "center", marginTop: "6px" }}>{"1"}</h4>
          </Col>
          <Col xs={13} style={{ textAlign: "left" }}>
            <DatePicker
              format={dateFormat}
              onChange={this.onChangePoliceCertificateIssueDate1}
            />
          </Col>
          <Col xs={7} offset={1}>
            <div style={{ width: "100%" }}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"PLEASE SELECT"}
                style={{ width: "96%", minWidth: "96%" }}
                onChange={this.onChangePoliceCertificateCountry1}
              >
                <Option value="">Please Select</Option>
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col xs={3}>
            <h4 style={{ textAlign: "center", marginTop: "6px" }}>{"2"}</h4>
          </Col>
          <Col xs={13} style={{ textAlign: "left" }}>
            <DatePicker
              format={dateFormat}
              onChange={this.onChangePoliceCertificateIssueDate2}
            />
          </Col>
          <Col xs={7} offset={1}>
            <div style={{ width: "100%" }}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"PLEASE SELECT"}
                style={{ width: "96%", minWidth: "96%" }}
                onChange={this.onChangePoliceCertificateCountry2}
              >
                <Option value="">Please Select</Option>
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <h4 style={{ textAlign: "center", marginTop: "6px" }}>{"3"}</h4>
          </Col>
          <Col xs={13} style={{ textAlign: "left" }}>
            <DatePicker
              format={dateFormat}
              onChange={this.onChangePoliceCertificateIssueDate3}
            />
          </Col>
          <Col xs={7} offset={1}>
            <div style={{ width: "100%" }}>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={"PLEASE SELECT"}
                style={{ width: "96%", minWidth: "96%" }}
                onChange={this.onChangePoliceCertificateCountry3}
              >
                <Option value="">Please Select</Option>
                {this.props.countriesData &&
                  this.props.countriesData
                    .filter(
                      (obj) =>
                        obj.name.toLowerCase() === "new zealand" ||
                        obj.name.toLowerCase() === "australia" ||
                        obj.name.toLowerCase() === "canada"
                    )
                    .map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}

                {this.props.countriesData &&
                  this.props.countriesData.map((data, index) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    if (
                      data.name.toLowerCase() === "new zealand" ||
                      data.name.toLowerCase() === "australia" ||
                      data.name.toLowerCase() === "canada"
                    ) {
                    } else {
                      return <Option value={data.id}>{data.name}</Option>;
                    }
                  })}
              </Select>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  onChangePoliceCertificateIssueDate1 = (date) => {
    this.setState({ policeCertificateIssueDate1: date });
  };

  onChangePoliceCertificateIssueDate2 = (date) => {
    this.setState({ policeCertificateIssueDate2: date });
  };

  onChangePoliceCertificateIssueDate3 = (date) => {
    this.setState({ policeCertificateIssueDate3: date });
  };

  onChangePoliceCertificateCountry1 = (value) => {
    this.setState({ policeCertificateCountry1: value });
  };

  onChangePoliceCertificateCountry2 = (value) => {
    this.setState({ policeCertificateCountry2: value });
  };

  onChangePoliceCertificateCountry3 = (value) => {
    this.setState({ policeCertificateCountry3: value });
  };

  getSaveButton = () => {
    return (
      <div>
        <Form.Item>
          <Button
            loading={this.state.loadSaveClient}
            type="primary"
            htmlType="submit"
            className="button-blue"
          >
            Save
          </Button>
        </Form.Item>
      </div>
    );
  };

  addClientConfirm = (values) => {
    let _visaCountryId =
      values.visaCountryId === undefined ? 0 : values.visaCountryId;
    let _visaCountyType =
      values.visaCountyType === undefined ? 0 : values.visaCountyType;
    let _clientSerial =
      values.clientSerial === undefined ? "" : values.clientSerial;
    let _title = values.title === undefined ? "" : values.title;
    let _firstName = values.firstName === undefined ? "" : values.firstName;
    let _middleName = values.middleName === undefined ? "" : values.middleName;
    let _lastName = values.lastName === undefined ? "" : values.lastName;
    let _preferredName =
      values.preferredName === undefined ? "" : values.preferredName;
    let _gender = values.gender === undefined ? 0 : values.gender;
    let _dateOfBirth;
    if (values.dateOfBirth === undefined || values.dateOfBirth === null) {
      _dateOfBirth = "1900-01-01T00:00:00.000Z";
    } else {
      _dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    let _dealWorth = values.dealWorth === undefined ? "" : values.dealWorth;
    let _maritalStatus =
      values.maritalStatus === undefined ? "" : values.maritalStatus;
    let _dependentChildren =
      values.dependentChildren === undefined ? 0 : values.dependentChildren;
    let _nationalityId =
      values.nationalityId === undefined ? "" : values.nationalityId;
    let _saleDate;
    if (values.saleDate === undefined || values.saleDate === null) {
      _saleDate = "1900-01-01T00:00:00.000Z";
    } else {
      _saleDate = moment(values.saleDate).format();
    }
    let _primaryEmail = values.email === undefined ? "" : values.email;
    let _secondaryEmail =
      values.secondaryEmail === undefined ? "" : values.secondaryEmail;
    let _otherEmail = values.otherEmail === undefined ? "" : values.otherEmail;
    let _emails = [
      {
        address: _primaryEmail,
        emailTypeId: 1,
      },
      {
        address: _secondaryEmail,
        emailTypeId: 2,
      },
      {
        address: _otherEmail,
        emailTypeId: 3,
      },
    ];
    let _countryCode;
    if (values.countryCode === undefined) {
      _countryCode = 168;
    } else {
      _countryCode = values.countryCode;
    }
    let _secondaryMobile =
      values.secondaryMobile === undefined ? "" : values.secondaryMobile;
    let _overseasMobile =
      values.overseasMobile === undefined ? "" : values.overseasMobile;
    let _phone = values.phone === undefined ? "" : values.phone;
    let _otherContactInformation =
      values.otherContactInformation === undefined
        ? ""
        : values.otherContactInformation;
    let _contactPerson =
      values.contactPerson === undefined ? "" : values.contactPerson;
    let _agentId;
    if (values.agentId === undefined || values.agentId === "") {
      _agentId = "00000000-0000-0000-0000-000000000000";
    } else {
      _agentId = values.agentId;
    }
    let _sourceId = values.sourceId === undefined ? 0 : values.sourceId;
    let _sourceDescription =
      values.sourceDescription === undefined ? "" : values.sourceDescription;
    let _jobSectorId =
      values.jobSectorId === undefined ? 0 : values.jobSectorId;
    let _occupation = values.occupation === undefined ? "" : values.occupation;
    let _companyOptional =
      values.companyOptional === undefined ? "" : values.companyOptional;
    let _phones = [
      {
        countryCodeId: _countryCode,
        phoneTypeId: 1,
        contact: this.state.mobile,
      },
      {
        countryCodeId: 168,
        phoneTypeId: 2,
        contact: _secondaryMobile,
      },
      {
        countryCodeId: 168,
        phoneTypeId: 3,
        contact: _overseasMobile,
      },
      {
        countryCodeId: 168,
        phoneTypeId: 4,
        contact: _phone,
      },
      {
        countryCodeId: 168,
        phoneTypeId: 5,
        contact: _otherContactInformation,
      },
    ];
    let _contactPersonBilling =
      values.contactPersonBilling === undefined
        ? ""
        : values.contactPersonBilling;
    let _flat = values.flat === undefined ? "" : values.flat;
    let _streetName = values.streetName === undefined ? "" : values.streetName;
    let _suburb = values.suburb === undefined ? "" : values.suburb;
    let _city = values.city === undefined ? "" : values.city;
    let _country = values.country === undefined ? 0 : values.country;
    let _zip = values.zip === undefined ? "" : values.zip;
    let _address = values.address === undefined ? "" : values.address;
    let addresses = [
      {
        contactPerson: "",
        flat: "",
        building: "",
        streetName: "",
        suburb: "",
        streetNumber: "",
        city: _address,
        state: "",
        zip: "",
        country: 0,
        addressTypeId: 1,
      },
      {
        contactPerson: _contactPersonBilling,
        flat: _flat,
        building: "",
        streetName: "",
        suburb: _suburb,
        streetNumber: _streetName,
        city: _city,
        state: "",
        zip: _zip,
        country: _country,
        addressTypeId: 5,
      },
    ];
    let _passportNo = values.passportNo === undefined ? "" : values.passportNo;
    let _passportCountry =
      values.passportCountry === undefined ? 0 : values.passportCountry;
    let _passportIssueDate;
    if (
      values.passportIssueDate === undefined ||
      values.passportIssueDate === null
    ) {
      _passportIssueDate = "1900-01-01T00:00:00.000Z";
    } else {
      _passportIssueDate = moment(values.passportIssueDate).format();
    }
    let _passportExpiryDate;
    if (
      values.passportExpiryDate === undefined ||
      values.passportExpiryDate === null
    ) {
      _passportExpiryDate = "1900-01-01T00:00:00.000Z";
    } else {
      _passportExpiryDate = moment(values.passportExpiryDate).format();
    }
    let _secondPassportNumber =
      values.secondPassportNumber === undefined
        ? ""
        : values.secondPassportNumber;
    let _secondIssueCountry =
      values.secondIssueCountry === undefined ? 0 : values.secondIssueCountry;
    let _secondaryIssueDate;
    if (
      values.secondaryIssueDate === undefined ||
      values.secondaryIssueDate === null
    ) {
      _secondaryIssueDate = "1900-01-01T00:00:00.000Z";
    } else {
      _secondaryIssueDate = moment(values.secondaryIssueDate).format();
    }
    let _secondExpiryDate;
    if (
      values.secondExpiryDate === undefined ||
      values.secondExpiryDate === null
    ) {
      _secondExpiryDate = "1900-01-01T00:00:00.000Z";
    } else {
      _secondExpiryDate = moment(values.secondExpiryDate).format();
    }
    let passports = [
      {
        passportNo: _passportNo,
        passportCountry: _passportCountry,
        passportType: 1,
        passportIssueDate: _passportIssueDate,
        passportExpiryDate: _passportExpiryDate,
      },
      {
        passportNo: _secondPassportNumber,
        passportCountry: _secondIssueCountry,
        passportType: 0,
        passportIssueDate: _secondaryIssueDate,
        passportExpiryDate: _secondExpiryDate,
      },
    ];
    let _currentVisaTypeId =
      values.currentVisaTypeId === undefined ? 0 : values.currentVisaTypeId;

    let _currentNewZealandVisaExpiry;
    if (
      values.currentNewZealandVisaExpiry === undefined ||
      values.currentNewZealandVisaExpiry === null
    ) {
      _currentNewZealandVisaExpiry = "1900-01-01T00:00:00.000Z";
    } else {
      _currentNewZealandVisaExpiry = moment(
        values.currentNewZealandVisaExpiry
      ).format();
    }
    let _travelConditionsValidTo;
    if (
      values.travelConditionsValidTo === undefined ||
      values.travelConditionsValidTo === null
    ) {
      _travelConditionsValidTo = "1900-01-01T00:00:00.000Z";
    } else {
      _travelConditionsValidTo = moment(
        values.travelConditionsValidTo
      ).format();
    }
    let _er = values.er === undefined ? "" : values.er;

    let _medicalIssueDate;
    if (
      values.medicalIssueDate === undefined ||
      values.medicalIssueDate === null
    ) {
      _medicalIssueDate = "1900-01-01T00:00:00.000Z";
    } else {
      _medicalIssueDate = moment(values.medicalIssueDate).format();
    }
    let _medicalExpiryDate;
    if (
      values.medicalExpiryDate === undefined ||
      values.medicalExpiryDate === null
    ) {
      _medicalExpiryDate = "1900-01-01T00:00:00.000Z";
    } else {
      _medicalExpiryDate = moment(values.medicalExpiryDate).format();
    }
    let _xrayIssueDate;
    if (values.xrayIssueDate === undefined || values.xrayIssueDate === null) {
      _xrayIssueDate = "1900-01-01T00:00:00.000Z";
    } else {
      _xrayIssueDate = moment(values.xrayIssueDate).format();
    }
    let _xrayExpiryDate;
    if (values.xrayExpiryDate === undefined || values.xrayExpiryDate === null) {
      _xrayExpiryDate = "1900-01-01T00:00:00.000Z";
    } else {
      _xrayExpiryDate = moment(values.xrayExpiryDate).format();
    }
    let _medicalGrading =
      values.medicalGrading === undefined ? "" : values.medicalGrading;
    let _xrayGrading =
      values.xrayGrading === undefined ? "" : values.xrayGrading;
    let _medicalNotes =
      values.medicalNotes === undefined ? "" : values.medicalNotes;
    let clientMedical = {
      er: _er,
      medicalIssueDate: _medicalIssueDate,
      medicalExpiryDate: _medicalExpiryDate,
      xrayIssueDate: _xrayIssueDate,
      xrayExpiryDate: _xrayExpiryDate,
      medicalGrading: _medicalGrading,
      xrayGrading: _xrayGrading,
      medicalNotes: _medicalNotes,
      medicalNotesDetail: this.state.medicalNotesDetail,
    };
    let policeCertificates = [];
    // {
    //   id: 0,
    //   issueDate: this.state.policeCertificateIssueDate1,
    //   certificateExpiryDate: "1900-01-01T00:00:00.000Z",
    //   country: this.state.policeCertificateCountry1
    // },
    // {
    //   id: 0,
    //   issueDate: this.state.policeCertificateIssueDate2,
    //   certificateExpiryDate: "1900-01-01T00:00:00.000Z",
    //   country: this.state.policeCertificateCountry2
    // },
    // {
    //   id: 0,
    //   issueDate: this.state.policeCertificateIssueDate3,
    //   certificateExpiryDate: "1900-01-01T00:00:00.000Z",
    //   country: this.state.policeCertificateCountry3
    // }
    // ];
    {
      this.state.policeCertificateCountry1 !== 0 &&
        policeCertificates.push({
          id: 0,
          issueDate:
            this.state.policeCertificateIssueDate1 ||
            "1900-01-01T00:00:00.000Z",
          certificateExpiryDate: "1900-01-01T00:00:00.000Z",
          country: this.state.policeCertificateCountry1,
        });
    }
    {
      this.state.policeCertificateCountry2 !== 0 &&
        policeCertificates.push({
          id: 0,
          issueDate:
            this.state.policeCertificateIssueDate2 ||
            "1900-01-01T00:00:00.000Z",
          certificateExpiryDate: "1900-01-01T00:00:00.000Z",
          country: this.state.policeCertificateCountry2,
        });
    }
    {
      this.state.policeCertificateCountry3 !== 0 &&
        policeCertificates.push({
          id: 0,
          issueDate:
            this.state.policeCertificateIssueDate3 ||
            "1900-01-01T00:00:00.000Z",
          certificateExpiryDate: "1900-01-01T00:00:00.000Z",
          country: this.state.policeCertificateCountry3,
        });
    }
    let _clientNumber =
      values.clientNumber === undefined ? "" : values.clientNumber;
    let _inzUserName =
      values.inzUserName === undefined ? "" : values.inzUserName;
    let _inzPassword =
      values.inzPassword === undefined ? "" : values.inzPassword;
    let _nzqaOnlineSubDate;
    if (
      values.nzqaOnlineSubDate === undefined ||
      values.nzqaOnlineSubDate === null
    ) {
      _nzqaOnlineSubDate = "1900-01-01T00:00:00.000Z";
    } else {
      _nzqaOnlineSubDate = moment(values.nzqaOnlineSubDate).format();
    }
    let _nzqaDocumentSubDate;
    if (
      values.nzqaDocumentSubDate === undefined ||
      values.nzqaDocumentSubDate === null
    ) {
      _nzqaDocumentSubDate = "1900-01-01T00:00:00.000Z";
    } else {
      _nzqaDocumentSubDate = moment(values.nzqaDocumentSubDate).format();
    }
    let _nzqaDocumentRetDate;
    if (
      values.nzqaDocumentRetDate === undefined ||
      values.nzqaDocumentRetDate === null
    ) {
      _nzqaDocumentRetDate = "1900-01-01T00:00:00.000Z";
    } else {
      _nzqaDocumentRetDate = moment(values.nzqaDocumentRetDate).format();
    }
    this.setState(
      {
        clientNumber: _clientNumber,
        contactPerson: _contactPerson,
        agentId: _agentId,
        firstName: _firstName,
        lastName: _lastName,
        middleName: _middleName,
        title: _title,
        gender: _gender,
        dateOfBirth: _dateOfBirth,
        maritalStatus: _maritalStatus,
        dependentChildren: _dependentChildren,
        occupation: _occupation,
        inzUserName: _inzUserName,
        inzPassword: _inzPassword,
        nationalityId: _nationalityId,
        preferredName: _preferredName,
        currentVisaTypeId: _currentVisaTypeId,
        currentNewZealandVisaExpiry: _currentNewZealandVisaExpiry,
        travelConditionsValidTo: _travelConditionsValidTo,
        nzqaOnlineSubDate: _nzqaOnlineSubDate,
        nzqaDocumentSubDate: _nzqaDocumentSubDate,
        nzqaDocumentRetDate: _nzqaDocumentRetDate,
        visaCountryId: _visaCountryId,
        visaCountyType: _visaCountyType,
        jobSectorId: _jobSectorId,
        sourceId: _sourceId,
        sourceDescription: _sourceDescription,
        clientSerial: _clientSerial,
        companyOptional: _companyOptional,
        dealWorth: _dealWorth,
        saleDate: _saleDate,
        clientMedical: clientMedical,
        passports: passports,
        clientEmails: _emails,
        addresses: addresses,
        clientPhones: _phones,
        policeCertificates: policeCertificates,
      },
      () => {
        this.props
          .addNewClient(this.state)
          .then((res) => {
            sessionStorage.setItem("clientprofileid", res.payload);
            let procPersonData = {
              clientId: res.payload,
              userId: localStorage.getItem("userId"),
            };
            this.props.onAddProcessingPerson(procPersonData).then(() => {
              this.props.onGetMailBox().then((resp) => {
                let addEmailImp = {
                  clientId: res.payload,
                  isRead: true,
                  isAutoImportEmail: true,
                  isImportFromPrimaryEmail: true,
                  isImportFromSecondaryEmail: true,
                  isImportFromOtherEmail: true,
                  importStartDate: new Date(),
                  istTimeImport: true,
                  userId: "00000000-0000-0000-0000-000000000000",
                };
                const getAutoImportOpt = {
                  url: `v1/users/AutoEmailON/00000000-0000-0000-0000-000000000000`,
                };
                getAutoImportOpt.types = [
                  "GET_AUTO-IMPORT_SUCCESS",
                  "GET_AUTO-IMPORT_FAILURE",
                ];
                apiRefresh.get(getAutoImportOpt).then((response) => {
                  if (response.isAutoEmailON)
                    this.props.onAutoImportEmailClient(addEmailImp);
                });
              });
            });
            message.success("Client is added successfully!");
            let userName = localStorage.getItem("userName");
            let data = {
              clientName: this.state.firstName + " " + this.state.lastName,
              logMessage:
                this.state.firstName +
                " " +
                this.state.lastName +
                " created by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
              notClientPart: true,
              linkedClient: res.payload,
            };
            activityData(data);
            this.setState({ loadSaveClient: false });
            setTimeout(() => {
              this.props.history.push({
                pathname: "/profile",
                state: { id: res.payload },
              });
            }, 1000);
          })
          .catch((err) => {
            this.setState({ loadSaveClient: false });
          });
      }
    );
  };

  onFinish = (values) => {
    this.setState({ loadSaveClient: true });
    if (values.email) {
      let data = {
        email: values.email,
        emailTypeId: 1,
      };
      this.props
        .onCheckUniqueEmail(data)
        .then((res) => {
          this.addClientConfirm(values);
        })
        .catch((err) => {
          if (err.payload && err.payload.response.status === 409) {
            const create = window.confirm(
              "Client with email: " +
                values.email +
                " already exist. Do you want to continue?"
            );
            if (create) {
              this.addClientConfirm(values);
            } else {
              this.setState({ loadSaveClient: false });
            }
          }
        });
    } else {
      this.addClientConfirm(values);
    }
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <PotentialHeaderTabs data={headOption} activeTab="Personal Detail" />
        <Form {...layout} ref={this.formRef} onFinish={this.onFinish}>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div className="page-container" style={{ marginTop: 30 }}>
                  <Row>
                    <Col xs={23} className="save-button-add-client">
                      {this.getSaveButton()}
                    </Col>
                  </Row>
                  {this.getUploadImage(
                    previewVisible,
                    previewImage,
                    fileList,
                    previewTitle,
                    uploadButton
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div className="page-container">
                  <div className="ts-container-add-client">
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"PERSONAL INFORMATION"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getPersonalInfoForm()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"BILLING ADDRESS"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getBillingAddressForm()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"PASSPORT DETAIL"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getPassportDetail()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {
                            "SECOND PASSPORT DETAILS (IF CLIENT IS DUAL NATIONAL)"
                          }
                        </h4>
                      </Col>
                    </Row>
                    {this.getSecondPassportDetail()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"CURRENT VISA DETAIL"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getCurrentVisaDetail()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"MEDICAL DETAIL"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getMedicalDetail()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"POLICE CERTIFICATE INFO"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getPoliceCertificateInfo()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"INZ LOGIN DETAILS"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getLoginDetails()}
                    <Row>
                      <Col xs={23} offset={1}>
                        <h4 className={"personal-info-heading"}>
                          {"NZQA DETAILS"}
                        </h4>
                      </Col>
                    </Row>
                    {this.getNZQADetails()}
                  </div>
                  <Row>
                    <Col xs={23} className="save-button-add-client">
                      {this.getSaveButton()}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    potentialClientInfo:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientInfo &&
      state.potentialClientReducer.potentialClientInfo,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    teamMembers:
      state &&
      state.teamMemberReducer &&
      state.teamMemberReducer.teamMembers &&
      state.teamMemberReducer.teamMembers.users &&
      state.teamMemberReducer.teamMembers.users,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    visaTypeData:
      state && state.visaTypeReducer && state.visaTypeReducer.visaTypeData,
    countriesData:
      state &&
      state.visaTypeReducer &&
      state.visaTypeReducer.countriesData &&
      state.visaTypeReducer.countriesData.items &&
      state.visaTypeReducer.countriesData.items,
    bol:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.bol &&
      state.potentialClientReducer.bol,
    imageUploadSuccess: state.userDetailReducer.imageUploadSuccess,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    uniqueEmailRes: state.clientProfileReducer.uniqueEmailRes,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientSource: bindActionCreators(getClientSource, dispatch),
  getPotentialClientListing: bindActionCreators(
    getPotentialClientListing,
    dispatch
  ),
  getClientTag: bindActionCreators(getClientTag, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
  getPotentialClientInfo: bindActionCreators(getPotentialClientInfo, dispatch),
  getTeamMember: bindActionCreators(getTeamMember, dispatch),
  getPotentialClientUpdateBol: bindActionCreators(
    getPotentialClientUpdateBol,
    dispatch
  ),
  getCountries: bindActionCreators(getCountries, dispatch),
  onUploadAvatar: bindActionCreators(uploadAvatar, dispatch),
  addNewClient: bindActionCreators(addNewClient, dispatch),
  onCheckUniqueEmail: bindActionCreators(checkUniqueEmail, dispatch),
  onAddProcessingPerson: bindActionCreators(addProcessingPerson, dispatch),
  onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
  onGetMailBox: bindActionCreators(getMailBox, dispatch),
  onAutoImportEmailClient: bindActionCreators(autoImportEmailClient, dispatch),
});
AddNewClient = connect(mapStateToProps, mapDispatchToProps)(AddNewClient);

export default withRouter(AddNewClient);
