import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinkQuestionnaire from "../CustomQuestionnaire/LinkQuestionnaire";

import {
  getDynamicLink,
  getCountries,
  submitQuestionnaire,
  getFilledQuestionnaire,
  getQuestionnaire,
  addDocWithName,
  getDocumentDownload,
  getQuestionnaireSettings,
  getVisaType,
  updMergeToClient,
  updMergeToPotentialClient,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    countriesData: state.visaTypeReducer.countriesData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    questionnaireSetRes: state.questionnaireSetReducer.questionnaireSetRes,
    visaTypeData: state.visaTypeReducer.visaTypeData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onSubmitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
    onGetFilledQuestionnaire: bindActionCreators(
      getFilledQuestionnaire,
      dispatch
    ),
    onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
    onAddDocWithName: bindActionCreators(addDocWithName, dispatch),
    onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
    onGetQuestionnaireSet: bindActionCreators(
      getQuestionnaireSettings,
      dispatch
    ),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onUpdMergeToClient: bindActionCreators(updMergeToClient, dispatch),
    onUpdMergeToPClient: bindActionCreators(
      updMergeToPotentialClient,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkQuestionnaire);
