import React from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Table,
  Modal,
  Spin,
  Input,
  InputNumber,
} from "antd";
import { bindActionCreators } from "redux";
import {
  getPaymentSourcesList,
  addOutgoingPaymentSource,
  editOutgoingPaymentSource,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const layoutSendingReceipt = {
  labelCol: { span: 7, offset: 2 },
  wrapperCol: { span: 14, offset: 1 },
};

class AddSource extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isButtonDisabled: false,
    };
  }

  componentWillMount() {
    let _outgoingPaymentSourceInfo =
      this.props && this.props.outgoingPaymentSourceInfo;

    if (_outgoingPaymentSourceInfo && _outgoingPaymentSourceInfo) {
      this.setState(
        {
          name: _outgoingPaymentSourceInfo.name,
          id: _outgoingPaymentSourceInfo.id,
        },
        () => {
          this.formRef.current.setFieldsValue(
            {
              name: _outgoingPaymentSourceInfo.name,
            },
            () => {}
          );
        }
      );
    }
  }

  componentDidMount() {}

  onFinish = (values) => {
    let _name = values.name;

    this.setState(
      {
        name: _name,
      },
      () => {
        if (
          this.props &&
          this.props.outgoingPaymentSourceInfo &&
          this.props.outgoingPaymentSourceInfo.id
        ) {
          this.setState({
            isButtonDisabled: true,
          });
          this.props
            .editOutgoingPaymentSource(this.state)
            .then(() => {
              message.success("Source is updated successfully!");
              this.props.getPaymentSourcesList();
              this.props.handleCancelAddSourceModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        } else {
          this.setState({
            isButtonDisabled: true,
          });
          this.props
            .addOutgoingPaymentSource(this.state)
            .then(() => {
              message.success("Source is added successfully!");
              this.props.getPaymentSourcesList();
              this.props.handleCancelAddSourceModal();
            })
            .catch(() => {
              this.setState({
                isButtonDisabled: false,
              });
            });
        }
      }
    );
  };

  getAddSourceForm = (props) => {
    return (
      <div className="border-box-modal-sending-receipt add-employer-para">
        <Form.Item
          colon={false}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Required!" },
            // ({ getFieldValue }) => ({
            //   validator(rule, value) {
            //     if (value.length > 20) {
            //
            //       return Promise.reject("Character limit exceeded");
            //     } else {
            //       return Promise.resolve();
            //     }
            //   }
            // })
          ]}
        >
          <Input size="medium" style={{ width: "-webkit-fill-available" }} />
        </Form.Item>
      </div>
    );
  };

  render() {
    return (
      <div className="reminder-model">
        <Modal
          className="reminder-model-main width-modal-outgoing-payments"
          title="Outgoing Source"
          visible={this.props.visibleAddSourceModal}
          onCancel={this.props.handleCancelAddSourceModal}
          footer={null}
          maskClosable={false}
        >
          <Form
            {...layoutSendingReceipt}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            {this.getAddSourceForm(this.props)}
            <Row>
              <Col xs={4} offset={18} style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    className={"button-blue"}
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    className={"button-blue"}
                    type="primary"
                    onClick={this.props.handleCancelAddSourceModal}
                  >
                    Close
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let a =
    state &&
    state.outgoingPaymentsReducer &&
    state.outgoingPaymentsReducer.outgoingPaymentSourceInfo &&
    state.outgoingPaymentsReducer.outgoingPaymentSourceInfo;

  return {
    outgoingPaymentSourceInfo:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.outgoingPaymentSourceInfo &&
      state.outgoingPaymentsReducer.outgoingPaymentSourceInfo,
    paymentSourcesListing:
      state &&
      state.outgoingPaymentsReducer &&
      state.outgoingPaymentsReducer.paymentSourcesListing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPaymentSourcesList: bindActionCreators(getPaymentSourcesList, dispatch),
  addOutgoingPaymentSource: bindActionCreators(
    addOutgoingPaymentSource,
    dispatch
  ),
  editOutgoingPaymentSource: bindActionCreators(
    editOutgoingPaymentSource,
    dispatch
  ),
});

AddSource = connect(mapStateToProps, mapDispatchToProps)(AddSource);

export default withRouter(AddSource);
