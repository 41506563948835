import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  Select,
  Checkbox,
  Upload,
  Modal,
  Spin,
  Radio,
  Table,
  Tabs,
  Collapse,
} from "antd";
import {
  CloseCircleOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import CreateEmailDocuments from "./CreateEmailDocuments";
import CreateEmailEditors from "./CreateEmailEditors";
import moment from "moment";
import { visaForms } from "../../../VisaForm/NZForms";
import { Images } from "../../../Themes";
import { ADD_FAMILY_LINK_SUCCESS } from "../../../store/Constants";
import * as types from "../../../store/Constants";
import { apiRefresh } from "../../../services/api";
import { saveAs } from "file-saver";
import EmailPopupcontent from "./EmailsPopupContent";
import FroalaEditorCom from "../../../Containers/FroalaEditorCom";
import activityData from "../../../Components/ClientActivity/ActivityData";
import { ausVisaForms } from "../../../VisaForm/AusFormsList";
import { set } from "core-js/core/dict";

let clientprofileid = sessionStorage.getItem("clientprofileid");
let tempArray = [];

var userId = localStorage.getItem("userId");

const { Option } = Select;

function onLetterChange(value) {
  console.log(`selected ${value}`);
}
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log("blur");
}

function onFocus() {
  console.log("focus");
}

function onSearch(val) {
  console.log("search:", val);
}

const DocumentChecklistForm = ({
  resultList,
  onSendEmailLink,
  handleKey,
  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddEmployerEmail,
  emailContentData,
  onAddDraftClient,

  onGetClientProfile,
  clientProfileData,

  setLoading,
  loading,

  onGetClientHistory,

  onUploadAvatar,

  onAddEmailDocument,
  emailDocumentRes,

  setHandleKey,
  onGetClientFamilyEmails,
  clientEmailRes,

  onGetEmployerDocument,
  employerDocumentRes,

  onGetImapSettingss,
  imapSettingRes,

  onGetSignature,
  signatureRes,

  onGetPdf,

  onGetLink,

  onGetDocuments,
  documentRes,

  inHistoryPopup,
  forward,
  reply,
  replyAll,
  isDraft,
  onGetDocumentDownload,
  handleCancelPopup,

  onDeleteClientEmail,
  emailContent,
  onSetActiveKey,
  profileData,
  onGetPermProcessingPerson,
  onGetGroups,

  studentEmail,
  batchId,
  cancelPopup,
}) => {
  const { Panel } = Collapse;
  const [to, setTo] = useState("");
  const [ccc, setCcc] = useState("");
  const [bol, setBol] = useState(false);
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [lettersData, setLettersData] = useState([]);
  const [contractsData, setContractsData] = useState([]);
  const [letterString, setLetterString] = useState(emailContentData);
  const [isSendCopy, setIsSendCopy] = useState(false);
  const [size, setSize] = useState([0]);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, SetPreviewVisible] = useState(false);
  const [previewImage, SetPreviewImage] = useState("");
  const [previewTitle, SetPreviewTitle] = useState("");
  const [emailFile, SetEmailFile] = useState([]);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState("");
  const [showDocPopup, setShowDocPopup] = useState(false);
  const [docArray, setDocArray] = useState([]);
  const [formData, setFormData] = useState();
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [filteredArr, setFilteredArr] = useState([]);
  const [showVisaFormPopup, setShowVisaFormPopup] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState({
    attachmentName: "",
    attachmentUrl: "",
  });
  const [contractAttachmentData, setContractAttachmentsData] = useState({
    attachmentName: "",
    attachmentUrl: "",
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [tempFileList, setTempFileList] = useState([]);
  const [hostName, setHostName] = useState("");
  const [showEditor, setShowEditor] = useState(true);
  const [showComDocPopup, setShowComDocPopup] = useState(false);
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
  const [commonDocArray, setCommonDocArray] = useState([]);
  const [visaFormArray, setVisaFormArray] = useState([]);
  const [selectedRowKeys3, setSelectedRowKeys3] = useState([]);
  const [selectedRowKeys4, setSelectedRowKeys4] = useState([]);
  const [showAttachmentsData, setShowAttachmentsData] = useState([]);
  const [contractValue, setContractValue] = useState("");
  const [templateValue, setTemplateValue] = useState("");
  const [emailsList, setEmailsList] = useState([]);
  const [nullRadioState, setNullRadioState] = useState(false);
  const [tempSize, setTempSize] = useState([0]);
  const [counter, setCounter] = useState(0);
  const [handleInnerKey, setInnerHandleKey] = useState("1");
  const [ausVisaFormArray, setAusVisaFormArray] = useState([]);
  const [usersEmails, setUsersEmails] = useState([]);
  const [usersEmailList, setUsersEmailsList] = useState([]);
  const [groupsEmails, setGroupsEmails] = useState([]);
  const [clientEmailsList, setClientEmailsList] = useState([]);
  const [processingPersonEmails, setProcessingPersonEmails] = useState([]);
  const [employerEmails, setEmployerEmails] = useState([]);
  const [agentEmails, setAgentEmails] = useState([]);
  const [visaOfficersEmails, setVisaOfficerEmails] = useState([]);
  const [groupEmailsList, setGroupEmailsList] = useState([]);

  const { TabPane } = Tabs;
  // redeploye
  useEffect(() => {
    if (counter === 2 && !studentEmail) {
      let attachmentUrl = attachmentsData.attachmentUrl;
      let attachmentName = attachmentsData.attachmentName;
      setFileList(tempFileList);
      onAddEmailDocument(formData)
        .then((res) => {
          for (var i = 0; i < res.payload.length; i++) {
            let pdfData = {
              url: res.payload[i].fileUrl,
              name: res.payload[i].fileName,
              type: res.payload[i].contentType,
            };
            setShowAttachmentsData((prevState) => [...prevState, pdfData]);
            let semiColon = "";
            if (attachmentName !== "") {
              semiColon = ";";
            }
            attachmentName += semiColon + res.payload[i].fileName;
            attachmentUrl += semiColon + res.payload[i].fileUrl;
          }

          setAttachmentsData({
            attachmentUrl: attachmentUrl,
            attachmentName: attachmentName,
          });
          SetEmailFile(res && res.payload);
          setLoading(false);
          handleCancel();
          message.success("Successfully Added!");
          setSize(tempSize);
        })
        .catch((err) => {
          if (err.payload && err.payload.response == undefined) {
            message.error("Max upload file size reached! Uploading is failed!");
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [bol]);

  // useEffect(() => {
  //   if (emailContent) {
  //     setContractsData([]);
  //     setAttachmentsData({
  //       attachmentName: "",
  //       attachmentUrl: "",
  //     });
  //   }
  // }, [emailContent]);

  useEffect(() => {
    if (tempFileList && tempFileList.length === 1) {
      let formData = new FormData();
      var fs = require("fs");
      setLoading(true);
      formData.append("BranchId", localStorage.getItem("selectedBranchId"));
      let _size = [...size];

      if (tempFileList && tempFileList.length > 0) {
        for (let i = 0; i < tempFileList.length; i++) {
          formData.append("files", tempFileList[i].originFileObj);
          _size[0] = _size[0] + tempFileList[i].originFileObj.size;
        }
      }
      if (_size <= 15000000) {
        setSize(_size);
        let attachmentUrl = attachmentsData.attachmentUrl;
        let attachmentName = attachmentsData.attachmentName;
        setFileList(tempFileList);
        onAddEmailDocument(formData)
          .then((res) => {
            for (var i = 0; i < res.payload.length; i++) {
              let pdfData = {
                url: res.payload[i].fileUrl,
                name: res.payload[i].fileName,
                type: res.payload[i].contentType,
              };
              setShowAttachmentsData((prevState) => [...prevState, pdfData]);
              let semiColon = "";
              if (attachmentName !== "") {
                semiColon = ";";
              }
              attachmentName += semiColon + res.payload[i].fileName;
              attachmentUrl += semiColon + res.payload[i].fileUrl;
            }
            setAttachmentsData({
              attachmentUrl: attachmentUrl,
              attachmentName: attachmentName,
            });
            SetEmailFile(res && res.payload);
            setLoading(false);
            handleCancel();
            message.success("Successfully Added!");
          })
          .catch((err) => {
            if (err.payload && err.payload.response == undefined) {
              message.error(
                "Max upload file size reached! Uploading is failed!"
              );
            }
            setLoading(false);
          });
      } else {
        message.error("Max upload file size reached! Uploading is failed!");
        setLoading(false);
      }
    } else {
      let formData = new FormData();
      var fs = require("fs");
      setLoading(true);
      formData.append("BranchId", localStorage.getItem("selectedBranchId"));
      let _size = [...size];

      let tempSize = 0;
      if (tempFileList && tempFileList.length > 0) {
        for (let i = 0; i < tempFileList.length; i++) {
          formData.append("files", tempFileList[i].originFileObj);
          tempSize = tempSize + tempFileList[i].originFileObj.size;
        }
      }
      _size[0] = _size[0] + tempSize;

      setFormData(formData);
      if (_size <= 15000000) {
        setCounter(counter + 1);
        setBol(!bol);
        setTempSize(_size);
      } else {
        message.error("Max upload file size reached! Uploading is failed!");
        setLoading(false);
      }
    }
  }, [tempFileList]);

  useEffect(() => {
    setLoading(true);
    let familyId = sessionStorage.getItem("familyId");
    let userId = localStorage.getItem("userId");
    if (!studentEmail) {
      onGetClientFamilyEmails(familyId)
        .then((res) => {
          tempArray = [];
          setFilteredArr([]);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // if (!emailContent) {
    onGetSignature()
      .then((res) => {
        setLoading(false);
        if (!isDraft) setLetterString(letterString + res.payload.signatureText);

        if (forward && emailContent) {
          var text =
            letterString +
            res.payload.signatureText +
            "<br/>" +
            "<p>---------- Forwarded message ---------</p><br/><p>From: " +
            emailContent.from +
            "</p><p>To: " +
            emailContent.to +
            "</p><p>Subject: " +
            emailContent.subject +
            emailContent.emailMessage;
          setLetterString(text);
        }

        if (replyAll || reply || !emailContent) {
          var emailReply = emailContent
            ? "<br/><p>From: " +
              emailContent.from +
              "</p><p>To: " +
              emailContent.to +
              "</p><p>Subject: " +
              emailContent.subject +
              emailContent.emailMessage
            : "";
          setLetterString(
            letterString + res.payload.signatureText + "<br/>" + emailReply
          );
        } else if (studentEmail) {
          setLetterString();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    if (!studentEmail) {
      onGetPermProcessingPerson(false).then((res) => {
        setUsersEmails(res.payload.users);
      });
      onGetGroups().then((res) => {
        setGroupsEmails(res.payload && res.payload.items);
      });
      onGetDocuments().then((res) => {});
      onGetLetterTemplates()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setLoading(false));
      onGetImapSettingss(userId).then((res) => {
        if (res.payload.useImap) {
          localStorage.setItem("imapEmail", res.payload.exportEmail);
        }
      });
      // imapSettingRes = { imapSettingRes };
      let clientprofileid = sessionStorage.getItem("clientprofileid");
      var data = new Object();
      data.id = clientprofileid;
      data.docType = -1;
      data.CaseId = 0;
      data.IsVisa = false;
      onGetEmployerDocument(data);
    }
  }, [onGetLetterTemplates]);

  useEffect(() => {
    // setNullRadioState(false);
  }, [nullRadioState]);

  useEffect(() => {
    if (emailContent) {
      if (
        emailContent &&
        emailContent.emailAttachment &&
        emailContent.emailAttachment.length > 0 &&
        (forward || isDraft)
      ) {
        setShowAttachmentsData([]);
        let attachmentUrl = "";
        let attachmentName = "";
        for (var i = 0; i < emailContent.emailAttachment.length; i++) {
          let pdfData = {
            url: emailContent.emailAttachment[i].blobURL,
            name: emailContent.emailAttachment[i].name,
            type: emailContent.emailAttachment[i].type,
          };
          setShowAttachmentsData((prevState) => [...prevState, pdfData]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }

          attachmentName += semiColon + emailContent.emailAttachment[i].name;
          attachmentUrl += semiColon + emailContent.emailAttachment[i].blobURL;
        }
        setAttachmentsData({
          attachmentUrl: attachmentUrl,
          attachmentName: attachmentName,
        });
      }
    }
    if (isDraft) {
      form.setFieldsValue({
        to: emailContent.to,
        ccc: emailContent.cc,
        bcc: emailContent.bcc,
        subject: emailContent.subject,
      });
      setTo(emailContent.to);
      setCcc(emailContent.cc);
      setBcc(emailContent.bcc);
      setSubject(emailContent.subject);
      setLetterString(emailContent.emailMessage);
      if (emailContent.draftContract) {
        var dContract = JSON.parse(emailContent.draftContract);
        setContractsData(dContract);
      }
    }
    if (forward) {
      var emailReply = emailContent
        ? "<p>---------- Forwarded message ---------</p><br/><p>From: " +
          emailContent.from +
          "</p><p>To: " +
          emailContent.to +
          "</p><p>Subject: " +
          emailContent.subject +
          emailContent.emailMessage
        : "";
      setLetterString(
        "<p>Dear " +
          (profileData && profileData.fullName) +
          "</p>" +
          "<br/>" +
          emailReply
      );
      setSubject(emailContent.subject);
      // setLetterString(emailContent.emailMessage);
      form.setFieldsValue({
        subject: "Fwd: " + emailContent.subject,
      });
    }

    if (reply) {
      form.setFieldsValue({
        to: emailContent.from,
        subject: "Re: " + emailContent.subject,
      });

      setTo(emailContent.from);
      setSubject(emailContent.subject);
    }
    if (replyAll) {
      setTo(emailContent.from);
      setCcc(emailContent.cc);
      setBcc(emailContent.bcc);
      setSubject(emailContent.subject);
      form.setFieldsValue({
        to: emailContent.from,
        ccc: emailContent.cc,
        bcc: emailContent.bcc,
        subject: "Re: " + emailContent.subject,
      });
    }
  }, [emailContent, forward, reply, replyAll, isDraft, profileData]);

  useEffect(() => {
    if (employerDocumentRes && employerDocumentRes.items.length > 0) {
      employerDocumentRes.items.map((data, index) => {
        if (employerDocumentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      setDocArray(employerDocumentRes.items);
    }
    if (documentRes && documentRes.items && documentRes.items.length > 0) {
      documentRes.items.map((data, index) => {
        if (documentRes) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      var findDocument = documentRes.items.filter(
        (obj) => obj.companyDocumentTypeId === 1
      );
      setCommonDocArray(findDocument);
    }
    if (visaForms.length > 0) {
      console.log("visa forms ty==========", visaForms);
      visaForms.map((data, index) => {
        if (visaForms) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      setVisaFormArray(visaForms);
    }

    if (ausVisaForms.length > 0) {
      console.log("visa forms ty==========", ausVisaForms);
      ausVisaForms.map((data, index) => {
        if (ausVisaForms) {
          data.index = index;
          data.key = `${index + 1}`;
          data.chosen = false;
        }
      });
      setAusVisaFormArray(ausVisaForms);
    }
  }, [employerDocumentRes, documentRes]);

  const onContractChange = (id, selection) => {
    var subStr = subject;
    var strSepComa = ",";
    const getContractOpt = {
      url: `v1/template/${id}`,
    };

    getContractOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh.get(getContractOpt).then((res) => {
      if (selection == "contract") {
        setContractValue(id);
      } else {
        setTemplateValue(id);
      }
      let checkId = null;
      if (selectedContracts.length > 0) {
        checkId = selectedContracts.find((obj) => obj.id == id);
      }
      if (!checkId && selection == "letter") {
        let dataId = {
          id,
        };
        setSelectedContracts((prevState) => [...prevState, dataId]);
        var findData = null;

        if (selection == "contract") {
          findData = dataSource.find((obj) => obj.id == id);
        } else if (selection == "letter") {
          findData = lettersData.find((obj) => obj.id == id);
        }
        if (res) {
          if (subStr) {
            subStr += strSepComa + " " + res.name;
          } else {
            subStr = res.name;
          }
          setSubject(subStr);
          form.setFieldsValue({
            subject: subStr,
          });
          setLoading(true);
          let clientprofileid = sessionStorage.getItem("clientprofileid");
          var paramArray = [];
          var param1 = new Object();
          param1.key = "ClientId";
          param1.value = clientprofileid;
          paramArray.push(param1);
          var param2 = new Object();
          param2.key = "UserId";
          param2.value = localStorage.getItem("userId");
          paramArray.push(param2);
          var param3 = new Object();
          param3.key = "CurrentDate";
          param3.value = moment(new Date()).format("DD/MM/YYYY");
          paramArray.push(param3);
          var data = new Object();
          data.templateName = res.content
            ? res.content.replace(
                "@CurrentDate",
                moment(new Date()).format("DD/MM/YYYY")
              )
            : "";
          data.parameters = paramArray;

          const docTypeOpt = {
            url: `v1/HtmlTemplate/SetAnyTemplate`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
          apiRefresh
            .post(docTypeOpt, data)
            .then((response) => {
              setLoading(false);

              let data = {
                id: id,
                content: response,
                isAttachment: false,
                header: res.header,
                name: res.name,
              };
              // setTimeout(() => {
              setContractsData((prevState) => [data, ...prevState]);
              // }, 500);
            })
            .catch((err) => {
              setLoading(false);
              message.error("Failed!");
            });
          const docTypeOpt1 = {
            url: `v1/template/Attachments/All/` + id,
          };
          docTypeOpt1.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];

          apiRefresh
            .get(docTypeOpt1)
            .then((resp) => {
              setLoading(false);
              let attachmentUrl = attachmentsData.attachmentUrl;
              let attachmentName = attachmentsData.attachmentName;

              if (resp.items && resp.items.length > 0) {
                for (var i = 0; i < resp.items.length; i++) {
                  let nameExtension = resp.items[i].name.split(".");
                  let pdfData = {
                    url: resp.items[i].url,
                    name: resp.items[i].name,
                    type: nameExtension[nameExtension.length - 1],
                  };
                  setShowAttachmentsData((prevState) => [
                    ...prevState,
                    pdfData,
                  ]);
                  let semiColon = "";
                  if (attachmentName !== "") {
                    semiColon = ";";
                  }
                  attachmentName += semiColon + resp.items[i].name;
                  attachmentUrl += semiColon + resp.items[i].url;
                }
                setAttachmentsData({
                  attachmentUrl: attachmentUrl,
                  attachmentName: attachmentName,
                });
              }
              // onAddAttachment(true, data, attachmentUrl, attachmentName);
            })
            .catch((err) => {
              setLoading(false);
            });
        }

        // let template = lettersData.find((obj) => obj.id == id);
        //
        // if (template) {
        //   let data = {
        //     id: id,
        //     content: template.content,
        //     isAttachment: true,
        //     header: template.header,
        //   };
        //
        //   setTimeout(() => {
        //     setContractsData((prevState) => [data, ...prevState]);
        //   }, 500);
        //
        //   onAddAttachment(true, data);
        // }
      } else if (!checkId && selection != "letter") {
        let dataId = {
          id,
        };
        setSelectedContracts((prevState) => [...prevState, dataId]);
        var findData = null;
        if (selection == "contract") {
          findData = dataSource.find((obj) => obj.id == id);
        } else if (selection == "letter") {
          findData = lettersData.find((obj) => obj.id == id);
        }
        if (res) {
          if (subStr) {
            subStr += strSepComa + " " + res.name;
          } else {
            subStr = res.name;
          }
          setSubject(subStr);
          form.setFieldsValue({
            subject: subStr,
          });
          setLoading(true);
          let clientprofileid = sessionStorage.getItem("clientprofileid");
          var contractData = null;
          var paramArray = [];
          var param1 = new Object();
          param1.key = "ClientId";
          param1.value = clientprofileid;
          paramArray.push(param1);
          var param2 = new Object();
          param2.key = "UserId";
          param2.value = localStorage.getItem("userId");
          paramArray.push(param2);
          var param3 = new Object();
          param3.key = "CurrentDate";
          param3.value = moment(new Date()).format("DD/MM/YYYY");
          paramArray.push(param3);
          var data = new Object();
          data.templateName = res.content
            ? res.content.replace(
                "@CurrentDate",
                moment(new Date()).format("DD/MM/YYYY")
              )
            : "";
          data.parameters = paramArray;
          const docTypeOpt = {
            url: `v1/HtmlTemplate/SetAnyTemplate`,
          };

          docTypeOpt.types = [
            types.GET_DOCUMENT_TYPE_SUCCESS,
            types.GET_DOCUMENT_TYPE_FAILURE,
          ];
          apiRefresh
            .post(docTypeOpt, data)
            .then((response) => {
              contractData = {
                id: id,
                content: response,
                isAttachment: true,
                header: res.header,
                name: res.name,
              };
              // setTimeout(() => {
              setContractsData((prevState) => [contractData, ...prevState]);
              // }, 500);
              const docTypeOpt1 = {
                url: `v1/template/Attachments/All/` + id,
              };
              docTypeOpt1.types = [
                types.GET_DOCUMENT_TYPE_SUCCESS,
                types.GET_DOCUMENT_TYPE_FAILURE,
              ];

              apiRefresh
                .get(docTypeOpt1)
                .then((resp) => {
                  setLoading(false);
                  let attachmentUrl = attachmentsData.attachmentUrl;
                  let attachmentName = attachmentsData.attachmentName;

                  if (resp.items && resp.items.length > 0) {
                    for (var i = 0; i < resp.items.length; i++) {
                      let nameExtension = resp.items[i].name.split(".");
                      let pdfData = {
                        url: resp.items[i].url,
                        name: resp.items[i].name,
                        type: nameExtension[nameExtension.length - 1],
                      };

                      setShowAttachmentsData((prevState) => [
                        ...prevState,
                        pdfData,
                      ]);
                      let semiColon = "";
                      if (attachmentName !== "") {
                        semiColon = ";";
                      }
                      attachmentName += semiColon + resp.items[i].name;
                      attachmentUrl += semiColon + resp.items[i].url;
                    }
                    setAttachmentsData({
                      attachmentUrl: attachmentUrl,
                      attachmentName: attachmentName,
                    });
                  }
                  contractDataAttachment(true, contractData);
                })
                .catch((err) => {
                  setLoading(false);
                  contractDataAttachment(true, contractData);
                });
            })
            .catch((err) => {
              setLoading(false);
              message.error("Failed!");
            });
        }
      } else {
        message.warning("Contract already selected!");
      }
    });
  };

  useEffect(() => {
    letterTempaltesFilter(LetterTemplatesRes);
  }, [LetterTemplatesRes]);

  useEffect(() => {
    let clientEmail = [];
    let processingPersonEmail = [];
    let employerEmail = [];
    let agentEmail = [];
    let groupEmail = [];
    let userEmail = [];
    let emails = [];
    let visaOfficerEmail = [];
    if (clientEmailRes) {
      if (clientEmailRes.items.length > 0) {
        for (var i = 0; i < clientEmailRes.items.length; i++) {
          let email = {
            name: clientEmailRes.items[i].emailType,
            address: clientEmailRes.items[i].address,
            memberType: clientEmailRes.items[i].memberType,
          };
          clientEmail.push(email);
        }
        setClientEmailsList(clientEmail);
      }
      if (
        clientEmailRes.processingPersonEmails &&
        clientEmailRes.processingPersonEmails.length > 0
      ) {
        for (var i = 0; i < clientEmailRes.processingPersonEmails.length; i++) {
          let email = {
            name: clientEmailRes.processingPersonEmails[i].name,
            address: clientEmailRes.processingPersonEmails[i].email,
            memberType: "Processing Person",
          };
          processingPersonEmail.push(email);
        }
        setProcessingPersonEmails(processingPersonEmail);
      }
      if (
        clientEmailRes.supplierEmails &&
        clientEmailRes.supplierEmails.length > 0
      ) {
        for (var i = 0; i < clientEmailRes.supplierEmails.length; i++) {
          let email = {
            name: clientEmailRes.supplierEmails[i].name,
            address: clientEmailRes.supplierEmails[i].email,
            memberType: "Employer",
          };
          employerEmail.push(email);
          setEmployerEmails(employerEmail);
        }
      }
      if (clientEmailRes.agentEmails && clientEmailRes.agentEmails.length > 0) {
        for (var i = 0; i < clientEmailRes.agentEmails.length; i++) {
          let email = {
            name: clientEmailRes.agentEmails[i].name,
            address: clientEmailRes.agentEmails[i].email,
            memberType: "Agent",
          };
          agentEmail.push(email);
          setAgentEmails(agentEmail);
        }
      }
      if (
        clientEmailRes.visaOfficerEmails &&
        clientEmailRes.visaOfficerEmails.length > 0
      ) {
        for (var i = 0; i < clientEmailRes.visaOfficerEmails.length; i++) {
          let email = {
            name: "",
            address: clientEmailRes.visaOfficerEmails[i].email,
            memberType: "Visa Officer",
          };
          visaOfficerEmail.push(email);
        }
        setVisaOfficerEmails(visaOfficerEmail);
      }
      if (usersEmails.length > 0) {
        for (var i = 0; i < usersEmails.length; i++) {
          let findUser = emails.find(
            (obj) => obj.address === usersEmails[i].alternateEmail
          );
          if (!findUser) {
            let email = {
              name: usersEmails[i].fullName,
              address: usersEmails[i].alternateEmail,
              memberType: "User",
            };
            userEmail.push(email);
          }
          setUsersEmailsList(userEmail);
        }
      }
      if (groupsEmails.length > 0) {
        for (var i = 0; i < groupsEmails.length; i++) {
          let email = {
            groupId: groupsEmails[i].id,
            name: groupsEmails[i].name,
            address: groupsEmails[i].email,
            memberType: "Processing Person Group",
          };
          groupEmail.push(email);
        }
        setGroupEmailsList(groupEmail);
      }
    }
    setEmailsList(emails);
  }, [clientEmailRes, usersEmails]);

  const letterTempaltesFilter = (letterTemplatesRes) => {
    let filterList = [];
    let filterListLetters = [];
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "CONTRACT") {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });

      setDataSource(filterList);
    }
    if (
      letterTemplatesRes &&
      letterTemplatesRes.items &&
      letterTemplatesRes.items.length > 0
    ) {
      letterTemplatesRes.items.map((data, index) => {
        if (data.type === "LETTER") {
          data.index = index;
          data.key = `${index + 1}`;
          filterListLetters.push(data);
        }
      });

      setLettersData(filterListLetters);
    }
  };
  // const clientEmail = () => {};

  const [form] = Form.useForm();

  const handleCancel = () => SetPreviewVisible(false);

  const onHandleChange = ({ fileList }) => {
    setTempFileList(fileList);
  };

  const onFinish = (values) => {
    var attachmentName = "";
    var attachmentUrl = "";
    var countContractIndex = 0;
    var contractAttachments = [];
    let attachmentString = {
      attachmentName: "",
      attachmentUrl: "",
    };
    var isContractAttachment = false;

    if (typeof values.to !== "undefined" && values.to.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (typeof values.ccc !== "undefined" && values.ccc.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if (typeof values.bcc !== "undefined" && values.bcc.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    setLoading(true);
    var contentData = showEditor && letterString ? letterString + "<br/>" : "";
    if (contractsData.length > 0) {
      for (var i = contractsData.length - 1; i > -1; i--) {
        if (!contractsData[i].isAttachment) {
          contentData += contractsData[i].content + "<br/>";
          countContractIndex++;
          if (
            !isContractAttachment &&
            countContractIndex == contractsData.length
          ) {
            callSendApi(
              values,
              contentData,
              contractAttachments,
              attachmentString
            );
          }
        } else {
          isContractAttachment = true;
          var content = contractsData[i].content;
          var formValue = new FormData();
          formValue.append(`Html`, content);
          formValue.append(
            `BranchId`,
            localStorage.getItem("selectedBranchId")
          );

          formValue.append(`FileTitle`, contractsData[i].name + ".pdf");
          onGetPdf(formValue)
            .then((res) => {
              countContractIndex++;
              setLoading(false);
              let pdfData = {
                url: res.payload.uri,
                name: res.payload.fileName,
                type: "application/pdf",
              };
              contractAttachments.push(pdfData);
              // setShowAttachmentsData((prevState) => [...prevState, pdfData]);
              let semiColon = "";
              if (attachmentName !== "") {
                semiColon = ";";
              }

              attachmentName += semiColon + res.payload.fileName;
              attachmentUrl += semiColon + res.payload.uri;

              attachmentString = {
                attachmentUrl: attachmentUrl,
                attachmentName: attachmentName,
              };

              if (countContractIndex == contractsData.length) {
                callSendApi(
                  values,
                  contentData,
                  contractAttachments,
                  attachmentString
                );
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      }
    } else {
      callSendApi(values, contentData, contractAttachments, attachmentString);
    }
  };

  const callSendApi = (
    values,
    contentData,
    contractAttachments,
    attachmentString
  ) => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    console.log("Received values of form:", values);
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let selectedBranchName = localStorage.getItem("selectedBranchName");
    const formData = new FormData();
    let userEmail = localStorage.getItem("userEmail");
    var count = 0;

    formData.append(`Recipients[0].name`, values.to);
    formData.append(`Recipients[0].type`, "To");
    if (ccc) {
      count++;
      formData.append(`Recipients[${count}].name`, ccc && ccc);
      formData.append(`Recipients[${count}].type`, "CC");
    }

    if (bcc) {
      count++;
      formData.append(`Recipients[${count}].name`, bcc);
      formData.append(`Recipients[${count}].type`, "Bcc");
    }

    let cc = ccc;
    if (isSendCopy === true) {
      count++;
      formData.append(`Recipients[${count}].name`, userEmail);
      formData.append(`Recipients[${count}].type`, "CC");
      let cccSplit = ccc.split(";");
      let findUserEmail = cccSplit.find((obj) => obj == userEmail);

      if (ccc != "") {
        if (!findUserEmail) cc = cc + ";" + userEmail;
      } else {
        cc = userEmail;
      }
      setCcc(cc);
    }

    formData.append("Subject", subject && subject ? subject : "");
    formData.append("Message", contentData || "");
    formData.append("From", selectedBranchName);
    formData.append("Priority", "10");
    formData.append("FrequencyCode", "client");
    formData.append("ClientId", clientprofileid);
    formData.append("ModuleId", "10");
    formData.append("BranchId", selectedBranchId);

    var mergeArray = showAttachmentsData.concat(contractAttachments);

    if (mergeArray.length > 0) {
      for (var i = 0; i < mergeArray.length; i++) {
        // formData.append(`Attachments[${i}].url`, null);
        formData.append(`Attachments[${i}].name`, mergeArray[i].name);
        formData.append(`Attachments[${i}].type`, mergeArray[i].type);
        formData.append(`Attachments[${i}].blobURL`, mergeArray[i].url);
      }
    } else {
      formData.append(`Attachments[0].name`, "");
      formData.append(`Attachments[0].type`, "");
      formData.append(`Attachments[0].blobURL`, "");
    }

    // for (
    //   var i = showAttachmentsData.length;
    //   i < contractAttachments.length + showAttachmentsData.length;
    //   i++
    // ) {
    //   formData.append(`Attachments[${i}].url`, contractAttachments[i].url);
    //   formData.append(`Attachments[${i}].name`, contractAttachments[i].name);
    //   formData.append(`Attachments[${i}].type`, contractAttachments[i].type);
    // }

    let userId = localStorage.getItem("userId");
    let familyId = sessionStorage.getItem("familyId");
    var semiColon = "";

    if (
      attachmentsData.attachmentName != "" &&
      attachmentString.attachmentName != ""
    ) {
      semiColon = ";";
    }

    const draftData = {
      id: 0,
      emailMessage: contentData || "",
      draftContract: "",
      draftLetter: "",
      isRead: true,
      attachmentName:
        attachmentsData.attachmentName +
          semiColon +
          attachmentString.attachmentName || "",
      attachmentUrl:
        attachmentsData.attachmentUrl +
          semiColon +
          attachmentString.attachmentUrl || "",
      sizeInKB: 0,
      subject: values && values ? values.subject : "",
      from: selectedBranchName,

      import: false,
      to: to && to,
      cc: isSendCopy ? cc : ccc,
      bcc: bcc && bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: false,
      emailType: 0,
      importText: "",
      clientId:
        (clientprofileid && clientprofileid) ||
        "00000000-0000-0000-0000-000000000000",
      importedDate: new Date(),
      autoImport: false,
      isSent: true,
      clientEmailType: 0,
      timeStamp: new Date(),
    };
    if (studentEmail) {
      draftData.batchId = batchId;
      const uploadClientOpt = {
        url: `v1/supervisor/batch/Student/ImportData/Email`,
      };

      uploadClientOpt.types = [
        "SEND_STUDENT_EMAIL_SUCCESS",
        "SEND_STUDENT_EMAIL_FAILURE",
      ];
      apiRefresh.post(uploadClientOpt, draftData).then(() => {
        setLoading(false);
        form.resetFields();
        cancelPopup();
        message.success("Email send successfully!");
      });
    } else {
      onAddDraftClient(draftData).then((res) => {
        formData.append("emailId", res.payload);
        const VisaOpt = {
          url: `v1/emailqueue/EmailQueueWithBlobUrl`,
        };
        VisaOpt.types = [
          "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
          "EMAIL_QUEUE_WITH_BLOB_FAILURE",
        ];

        apiRefresh
          .post(VisaOpt, formData)
          .then((resp) => {
            setLoading(false);
            message.success("Successfully Sent!");
            changeState();
            let userName = localStorage.getItem("userName");

            let myData = {
              clientName: profileData && profileData.fullName,
              logMessage:
                " Email with subject : " +
                values.subject +
                "\n" +
                '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer;" onclick={this.nextSibling.style.display="inline-block";this.nextSibling.nextSibling.style.display="block";this.style.display="none";this.nextSibling.nextSibling.childNodes[0].style.color="#1280b8";this.nextSibling.nextSibling.childNodes[0].style.fontSize="10px"}>Detail</Button>' +
                '<Button style="margin-left: 10px; border: 1px solid #1281b1;border-radius: 3px;background-color: #1281b1;color: #FFFFFF; cursor: pointer; display: none" onclick={this.nextSibling.style.display="none";this.style.display="none";this.previousSibling.style.display="inline-block"}>Hide</Button>' +
                '<div style="display: none">' +
                contentData +
                "</div>" +
                "<br />" +
                " created by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Email",
              invoiceId: "0",
            };

            activityData(myData);

            if (emailContent && emailContent.draft) {
              let emailId = {
                ids: [emailContent.id],
              };
              onDeleteClientEmail(emailId).then(() => {
                onGetClientHistory(
                  clientprofileid,
                  familyId,
                  "0",
                  "20",
                  "1",
                  0
                );
              });
            } else {
              onGetClientHistory(clientprofileid, familyId, "0", "20", "1", 0);
            }
            if (forward || reply || replyAll || isDraft) {
              handleCancelPopup();
            }
            setHandleKey("1");
            // setKeys(resp.items);
          })
          .catch((err) => {
            setLoading(false);
          });
        // if (emailContent && emailContent.draft) {
        //   let emailId = {
        //     ids: [emailContent.id],
        //   };
        //   onDeleteClientEmail(emailId).then(() => {
        //     onGetClientHistory(
        //       "00000000-0000-0000-0000-000000000000",
        //       familyId,
        //       "0",
        //       "10",
        //       "1",
        //       0
        //     );
        //   });
        // } else {
        //   onGetClientHistory(
        //     "00000000-0000-0000-0000-000000000000",
        //     familyId,
        //     "0",
        //     "10",
        //     "1",
        //     0
        //   );
        // }
      });
    }
  };

  const changeState = () => {
    let signature = signatureRes ? signatureRes.signatureText : "";

    form.resetFields();
    setTo("");
    setCcc("");
    setBcc("");
    setSubject("");
    setLetterString(letterString + signature);
    setShowAttachmentsData([]);
    setContractValue("");
    setTemplateValue("");
    setAttachmentsData({
      attachmentName: "",
      attachmentUrl: "",
    });
    SetEmailFile([]);
    setContractsData([]);
    setFileList([]);
    setSelectedContracts([]);
    setNullRadioState(true);
    setFilteredArr([]);
  };

  const DraftData = () => {
    var attachmentName = "";
    var attachmentUrl = "";
    var countContractIndex = 0;
    var contractAttachments = [];
    let attachmentString = {
      attachmentName: "",
      attachmentUrl: "",
    };
    var isContractAttachment = false;

    if (to && to.includes(",")) {
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if(ccc && ccc.includes(",")){
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    if( bcc && bcc.includes(",")){
      return message.info("Emails should be seprated by a semicolon (;)");
    }
    setLoading(true);

    var contentData = showEditor && letterString ? letterString + "<br/>" : "";
    // if (contractsData.length > 0) {
    //   for (var i = contractsData.length - 1; i > -1; i--) {
    //     if (!contractsData[i].isAttachment) {
    //       // contentData += contractsData[i].content + "<br/>";
    //       countContractIndex++;
    //       if (
    //         !isContractAttachment &&
    //         countContractIndex == contractsData.length
    //       ) {
    //         onSaveData(contentData, attachmentString);
    //       }
    //     } else {
    //       isContractAttachment = true;
    //       var content = contractsData[i].content;
    //       var formValue = new FormData();
    //       formValue.append(`Html`, content);
    //       formValue.append(
    //         `BranchId`,
    //         localStorage.getItem("selectedBranchId")
    //       );

    //       formValue.append(`FileTitle`, contractsData[i].name + ".pdf");
    //       onGetPdf(formValue)
    //         .then((res) => {
    //           countContractIndex++;
    //           setLoading(false);
    //           let pdfData = {
    //             url: res.payload.uri,
    //             name: res.payload.fileName,
    //             type: "application/pdf",
    //           };
    //           contractAttachments.push(pdfData);
    //           // setShowAttachmentsData((prevState) => [...prevState, pdfData]);
    //           let semiColon = "";
    //           if (attachmentName !== "") {
    //             semiColon = ";";
    //           }

    //           attachmentName += semiColon + res.payload.fileName;
    //           attachmentUrl += semiColon + res.payload.uri;

    //           attachmentString = {
    //             attachmentUrl: attachmentUrl,
    //             attachmentName: attachmentName,
    //           };

    //           if (countContractIndex == contractsData.length) {
    //             onSaveData(contentData, attachmentString);
    //           }
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //         });
    //     }
    //   }
    // } else {
    onSaveData(contentData, attachmentString);
    // }
  };

  const onSaveData = (contentData, attachmentString) => {
    let imapEmail = localStorage.getItem("imapEmail");
    let userName = localStorage.getItem("userName");
    // if (imapEmail) {
    //   useEmail = imapEmail;
    // } else {
    //   useEmail = "messaging.service@ezymigrate.net";
    // }

    let clientprofileid = sessionStorage.getItem("clientprofileid");

    let userEmail = localStorage.getItem("userEmail");
    let cc = ccc;
    if (isSendCopy) {
      let cccSplit = ccc.split(";");
      let findUserEmail = cccSplit.find((obj) => obj == userEmail);

      if (ccc != "") {
        if (!findUserEmail) cc += ";" + userEmail;
      } else {
        cc = userEmail;
      }
      setCcc(cc);
    }
    let selectedBranchName = localStorage.getItem("selectedBranchName");
    let userId = localStorage.getItem("userId");
    var semiColon = "";

    if (
      attachmentsData.attachmentName != "" &&
      attachmentString.attachmentName != ""
    ) {
      semiColon = ";";
    }
    var dContracts = "";
    if (contractsData.length > 0) {
      dContracts = JSON.stringify(contractsData);
    }
    const draftData = {
      id: 0,
      emailMessage: letterString || "",
      draftContract: dContracts,
      draftLetter: "",
      isRead: false,
      attachmentName:
        attachmentsData.attachmentName +
          semiColon +
          attachmentString.attachmentName || "",
      attachmentUrl:
        attachmentsData.attachmentUrl +
          semiColon +
          attachmentString.attachmentUrl || "",
      sizeInKB: 0,
      subject: subject ? subject : "",
      from: selectedBranchName,

      import: false,
      to: to && to,
      cc: isSendCopy ? cc : ccc,
      bcc: bcc && bcc,
      notClients: false,
      clientReply: false,
      clientReplyMail: 0,
      importMessageId: "",
      draft: true,
      emailType: 0,
      importText: "",
      clientId: clientprofileid && clientprofileid,
      importedDate: new Date(),
      autoImport: false,
      isSent: false,
      clientEmailType: 0,
      timeStamp: new Date(),
    };

    let familyId = sessionStorage.getItem("familyId");

    if (letterString) {
      onAddDraftClient(draftData).then((res) => {
        if (emailContent && emailContent.draft) {
          let emailId = {
            ids: [emailContent.id],
          };
          onDeleteClientEmail(emailId).then(() => {
            onGetClientHistory(clientprofileid, familyId, "0", "20", "1", 0);
          });
        } else {
          onGetClientHistory(clientprofileid, familyId, "0", "20", "1", 0);
        }
        changeState();

        if (forward || reply || replyAll || isDraft) {
          handleCancelPopup();
        }
        setLoading(false);
        message.success("Saved!");
        if (!emailContent) {
          setHandleKey("1");
        }
      });
    } else {
      setLoading(false);
      message.error("Email should not be empty. Please enter some text!");
    }
    // const draftOption = {
    //   url: `v1/emailqueue/DraftEmailQueueWithBlobUrl`,
    // };
    // draftOption.types = [
    //   "EMAIL_DRAFT_WITH_BLOB_SUCCESS",
    //   "EMAIL_DRAFT_WITH_BLOB_FAILURE",
    // ];

    // apiRefresh.post(draftOption, draftData).then((resp) => {
    //   onGetClientHistory(clientprofileid, familyId);
    //   setLoading(false);
    //   message.success("Saved!");
    //   setHandleKey("1");
    // });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return (
          <div>
            <span>{moment(text).format("DD MMM YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      //width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <Input
            className="docInput"
            placeholder={record && record.title}
            onChange={(e) => {}}
          />
        );
      },
    },

    {
      title: "Document",
      dataIndex: "title",
      className: "docNameTitle",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <p style={{ color: "#1890ff", fontSize: "11px" }}>
              {`${record && record.title}.${record &&
                record.docuementExtension} `}
            </p>
            {/* <p style={{ color: "#1890ff", fontSize: "10px" }}>
              {`${yyyymmddFormate(record && record.createdDate)} | ${record &&
                record.sizeInKB / 1000} KB `}
            </p> */}
          </div>
        );
      },
    },
  ];

  const comDocColumns = [
    {
      title: "#",
      key: "index",
      // width: "100px",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (text, record) => {
        return (
          <div>
            <span>{moment(text).format("DD MMM YYYY")}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      //width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return <p style={{ color: "#1890ff", fontSize: "11px" }}>{text}</p>;
      },
    },
  ];

  const visaFormColumns = [
    {
      title: "#",
      key: "index",
      width: "15px",
      textAlign: "center",
      render: (text, record, index) => {
        return (
          <div>
            <span>{index + 1}</span>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "100px",
      className: "docNameInput",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.title.localeCompare(b.title),
      ellipsis: true,
      render: (text, record) => {
        return <p style={{ color: "#1890ff", fontSize: "11px" }}>{text}</p>;
      },
    },
  ];

  const removeClientEmail = (index, id) => {
    // var removeArr = contractsData.splice(index, 1);
    let removeContract = [...contractsData];

    removeContract.splice(index, 1);

    setContractsData(removeContract);
    let removeContractId = selectedContracts.findIndex((obj) => obj.id == id);
    if (removeContractId != -1) {
      let removeId = [...selectedContracts];
      removeId.splice(removeContractId, 1);
      setSelectedContracts(removeId);
    }
  };

  const handleEditNote = (value, id) => {
    var contractIndex = contractsData.findIndex((obj) => obj.id == id);

    let contracts = [...contractsData];
    contracts[contractIndex].content = value;

    setContractsData(contracts);
    // setLetterString(value);
  };

  const onRequiredChange = (e) => {
    setIsSendCopy(e.target.checked);
    console.log(`checked = ${e.target.checked}`);
  };

  const contractDataAttachment = (checked, data) => {
    console.log(`checked = ${checked}`);
    var contractIndex = contractsData.findIndex((obj) => obj.id == data.id);

    let contracts = [...contractsData];
    if (contractIndex != -1) {
      contracts[contractIndex].isAttachment = checked;
      setContractsData(contracts);
    }
  };

  const onAddAttachment = (checked, data, url, name) => {
    let attachmentName = name ? name : attachmentsData.attachmentName;
    let attachmentUrl = url ? url : attachmentsData.attachmentUrl;
    console.log(`checked = ${checked}`);
    var contractIndex = contractsData.findIndex((obj) => obj.id == data.id);

    let contracts = [...contractsData];
    if (contractIndex != -1) {
      contracts[contractIndex].isAttachment = checked;
      setContractsData(contracts);
    }

    if (checked) {
      setLoading(true);
      var content =
        contractIndex > -1
          ? contractsData[contractIndex].content
          : data.content;
      var formData = new FormData();
      formData.append(`Html`, content);
      formData.append(`BranchId`, localStorage.getItem("selectedBranchId"));
      formData.append(`FileTitle`, "Agreement_Contract.pdf");
      onGetPdf(formData)
        .then((res) => {
          setLoading(false);
          let pdfData = {
            url: res.payload.uri,
            name: res.payload.fileName,
            type: res.payload.contentType,
          };
          setShowAttachmentsData((prevState) => [...prevState, pdfData]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName += semiColon + res.payload.fileName;
          attachmentUrl += semiColon + res.payload.uri;
          setAttachmentsData({
            attachmentUrl: attachmentUrl,
            attachmentName: attachmentName,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    SetPreviewVisible(true);
    SetPreviewImage(file.url || file.preview);

    SetPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  let config = {
    key:
      "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
    height: "auto",
    toolbarSticky: false,
    events: {
      "charCounter.update": () => {
        // Do something here.
        // this is the editor instance.
        console.log("char");
      },
    },
  };

  const onChangeMailOptions = (value, email, index) => {
    // if (value.target.value == "to") {
    //
    //   let toEmailString = to;
    //   let _index= index;
    //   if (to)
    //   {
    //     toEmailString = to + ";" + email.address;
    //   }
    //   else
    //   {
    //     toEmailString = email.address;
    //   }
    //
    //   setTo(toEmailString);
    //
    //   // if (toEmail) {
    //   //   emailArray = toEmail.splice(";");
    //   // }
    //   // if (emailArray.length > 0) {
    //   //   for (var i = 0; i < emailArray.length; i++) {
    //   //     if (i == 0) {
    //   //       emailString = emailArray[i];
    //   //     } else {
    //   //       emailString += ";" + emailArray[i];
    //   //     }
    //   //   }
    //   //   setToEmail(emailString);
    //   // }
    // } else if (value.target.value == "cc") {
    //
    //   let ccEmailString = ccc;
    //   if (ccc) {
    //     ccEmailString += ";" + email.address;
    //   } else {
    //     ccEmailString = email.address;
    //   }
    //   setCcc(ccEmailString);
    // } else {
    //
    //   let bccEmailString = bcc;
    //   if (bcc) {
    //     bccEmailString += ";" + email.address;
    //   } else {
    //     bccEmailString = email.address;
    //   }
    //   setBcc(bccEmailString);
    //
    // }

    if (tempArray && tempArray.length === 0) {
      tempArray.push({ index: index, value: value.target.value, email: email });
    } else {
      let _filteredArray = tempArray.filter(
        (data, ind) => data.index !== index
      );
      _filteredArray.push({
        index: index,
        value: value.target.value,
        email: email,
      });
      tempArray = _filteredArray;
    }
    setFilteredArr(tempArray);
  };

  const onChangeCheckbox = (value) => {
    setSelectedEmails(value);

    var checkboxValue = to;
    if (value.length < selectedEmails.length) {
      // for
    }
    if (value.length > 0) {
      if (checkboxValue !== "") {
        checkboxValue += ";" + value[value.length - 1];
      } else {
        checkboxValue = value[0];
      }
    }
    // form.setFieldsValue({
    //   to: checkboxValue,
    // });
    setTo(checkboxValue);
    // setTo((prevState) => ({
    //   ...prevState,
    //   to: value[0],
    // }));
  };

  const onOkButtn = () => {
    var uniqueToEmailsString = "";
    var uniqueBccEmailsString = "";
    var uniqueCcEmailsString = "";
    if (filteredArr.length > 0) {
      for (let ind = 0; ind < filteredArr.length; ind++) {
        if (filteredArr[ind].value === "to") {
          if (uniqueToEmailsString.length > 0) {
            uniqueToEmailsString += ";" + filteredArr[ind].email.address;
          } else {
            uniqueToEmailsString = filteredArr[ind].email.address;
          }
        }
        if (filteredArr[ind].value === "cc") {
          if (uniqueCcEmailsString.length > 0) {
            uniqueCcEmailsString += ";" + filteredArr[ind].email.address;
          } else {
            uniqueCcEmailsString = filteredArr[ind].email.address;
          }
        }
        if (filteredArr[ind].value === "bcc") {
          if (uniqueBccEmailsString.length > 0) {
            uniqueBccEmailsString += ";" + filteredArr[ind].email.address;
          } else {
            uniqueBccEmailsString = filteredArr[ind].email.address;
          }
        }
      }
    }

    setTo(uniqueToEmailsString);
    setBcc(uniqueBccEmailsString);
    setCcc(uniqueCcEmailsString);

    form.setFieldsValue({
      to: uniqueToEmailsString,
      ccc: uniqueCcEmailsString,
      bcc: uniqueBccEmailsString,
    });
    //   form.setFieldsValue({ to: uniqueEmailsString });
    setShowEmailPopup(false);
  };

  const handleCancelDocPopup = () => {
    setShowDocPopup(false);
  };

  const handleCancelEmailPopup = () => setShowEmailPopup(false);

  const rowSelection1 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys1(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection2 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys2(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection3 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys3(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const rowSelection4 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys4(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const getFileSizeSimple = async (filename) => {
    let sizeFile;
    const histOpt = {
      url: `v1/document/GetDocumentSize/${filename}`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    await apiRefresh.get(histOpt).then((resp) => {
      sizeFile = resp;
    });
    return sizeFile;
  };

  const getFileSize = async (fileName) => {
    let _size = [...size];
    const histOpt = {
      url: `v1/document/GetDocumentSize/${fileName}`,
    };
    histOpt.types = [
      "EMAIL_QUEUE_WITH_BLOB_SUCCESS",
      "EMAIL_QUEUE_WITH_BLOB_FAILURE",
    ];
    await apiRefresh.get(histOpt).then((resp) => {
      _size[0] = _size[0] + resp;
    });
    return _size[0];
  };

  const onOkClientDocuments = async () => {
    let data = null;
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;

    if (selectedRowKeys1.length > 0) {
      for (var i = 0; i < selectedRowKeys1.length; i++) {
        data = {
          url: selectedRowKeys1[i].blobFileName,
          name:
            selectedRowKeys1[i].title +
            "." +
            selectedRowKeys1[i].docuementExtension,
          type: selectedRowKeys1[i].docuementExtension,
        };

        let _resultSize = await getFileSize(data.url);

        if (_resultSize <= 15000000) {
          setSize([_resultSize]);
          setShowAttachmentsData((prevState) => [...prevState, data]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName +=
            semiColon +
            selectedRowKeys1[i].title +
            "." +
            selectedRowKeys1[i].docuementExtension;
          attachmentUrl += semiColon + selectedRowKeys1[i].blobFileName;
        } else {
          message.error("Max upload file size reached! Uploading is failed!");
          break;
        }
      }
      setAttachmentsData({
        attachmentUrl: attachmentUrl,
        attachmentName: attachmentName,
      });
    }
    setShowDocPopup(false);
  };

  const onOkCommonDocuments = async () => {
    let data = null;
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;
    if (selectedRowKeys2.length > 0) {
      for (var i = 0; i < selectedRowKeys2.length; i++) {
        data = {
          url: selectedRowKeys2[i].blobFileName,
          name: selectedRowKeys2[i].name,
          type: selectedRowKeys2[i].extension,
        };
        let _resultSize = await getFileSize(data.url);

        if (_resultSize <= 15000000) {
          setSize([_resultSize]);
          setShowAttachmentsData((prevState) => [...prevState, data]);
          let semiColon = "";
          if (attachmentName !== "") {
            semiColon = ";";
          }
          attachmentName += semiColon + selectedRowKeys2[i].name;
          attachmentUrl += semiColon + selectedRowKeys2[i].blobFileName;
        } else {
          message.error("Max upload file size reached! Uploading is failed!");
          break;
        }
      }
      setAttachmentsData({
        attachmentUrl: attachmentUrl,
        attachmentName: attachmentName,
      });
    }
    setShowComDocPopup(false);
  };

  async function createFile(link) {
    let fileArray = link.split("/");

    let fName = fileArray[fileArray.length - 1].split(".");

    console.log(fName);

    var hostName = window.location.origin;

    let response = await fetch(hostName + link);
    let data = await response.blob();
    let metadata = {
      type: "application/pdf",
    };
    let file = new File(
      [data],
      fName[0] + "." + fName[fName.length - 1],
      metadata
    );
    return file;
    // ... do something with the file or return it
  }

  const onOkVisaForm = () => {
    let data = null;
    let formData = new FormData();
    let ausFormData = new FormData();
    let visaFile = [];
    let ausVisaFile = [];
    let attachmentUrl = attachmentsData.attachmentUrl;
    let attachmentName = attachmentsData.attachmentName;
    if (selectedRowKeys3.length > 0) {
      for (var i = 0; i < selectedRowKeys3.length; i++) {
        createFile(selectedRowKeys3[i].link).then((res) => {
          visaFile.push(res);
          if (visaFile.length == selectedRowKeys3.length) {
            formData.append(
              "BranchId",
              localStorage.getItem("selectedBranchId")
            );
            var count = 0;
            let _size = [...size];

            for (var i = 0; i < visaFile.length; i++) {
              formData.append("files", visaFile[i]);
              _size[0] = _size[0] + visaFile[i].size;
            }

            if (_size[0] <= 15000000) {
              setSize(_size);
              setLoading(true);
              onAddEmailDocument(formData)
                .then((res) => {
                  for (var i = 0; i < res.payload.length; i++) {
                    if (res.payload[i].contentType) {
                      let pdfData = {
                        url: res.payload[i].fileUrl,
                        name: res.payload[i].fileName,
                        type: res.payload[i].contentType,
                      };
                      setShowAttachmentsData((prevState) => [
                        ...prevState,
                        pdfData,
                      ]);
                      let semiColon = "";
                      if (attachmentName !== "") {
                        semiColon = ";";
                      }
                      attachmentName += semiColon + res.payload[i].fileName;
                      attachmentUrl += semiColon + res.payload[i].fileUrl;
                    } else {
                      count++;
                    }
                  }
                  setAttachmentsData({
                    attachmentUrl: attachmentUrl,
                    attachmentName: attachmentName,
                  });
                  SetEmailFile(res && res.payload);
                  setLoading(false);
                  handleCancel();
                  if (count > 0) {
                    var file = count > 1 ? " Files" : " File";
                    message.info(count + file + " failed to attach");
                  }
                  setSelectedRowKeys3([]);
                  message.success("Successfully Added!");
                })
                .catch((err) => {
                  if (err.payload && err.payload.response == undefined) {
                    message.error(
                      "Max upload file size reached! Uploading is failed!"
                    );
                  }
                  setLoading(false);
                });
              setAttachmentsData({
                attachmentUrl: attachmentUrl,
                attachmentName: attachmentName,
              });
              setShowVisaFormPopup(false);
            } else {
              message.error(
                "Max upload file size reached! Uploading is failed!"
              );
            }
          }
        });
      }
    }
    if (selectedRowKeys4.length > 0) {
      for (var i = 0; i < selectedRowKeys4.length; i++) {
        createFile(selectedRowKeys4[i].link).then((res) => {
          ausVisaFile.push(res);
          if (ausVisaFile.length == selectedRowKeys4.length) {
            ausFormData.append(
              "BranchId",
              localStorage.getItem("selectedBranchId")
            );
            var count = 0;
            let _size = [...size];
            for (var i = 0; i < ausVisaFile.length; i++) {
              ausFormData.append("files", ausVisaFile[i]);
              _size[0] = _size[0] + ausVisaFile[i].size;
            }
            if (_size[0] <= 15000000) {
              setSize(_size);
              setLoading(true);
              onAddEmailDocument(ausFormData)
                .then((res) => {
                  for (var i = 0; i < res.payload.length; i++) {
                    if (res.payload[i].contentType) {
                      let pdfData = {
                        url: res.payload[i].fileUrl,
                        name: res.payload[i].fileName,
                        type: res.payload[i].contentType,
                      };
                      setShowAttachmentsData((prevState) => [
                        ...prevState,
                        pdfData,
                      ]);
                      let semiColon = "";
                      if (attachmentName !== "") {
                        semiColon = ";";
                      }
                      attachmentName += semiColon + res.payload[i].fileName;
                      attachmentUrl += semiColon + res.payload[i].fileUrl;
                    } else {
                      count++;
                    }
                  }
                  setAttachmentsData({
                    attachmentUrl: attachmentUrl,
                    attachmentName: attachmentName,
                  });
                  SetEmailFile(res && res.payload);
                  setLoading(false);
                  handleCancel();
                  if (count > 0) {
                    var file = count > 1 ? " Files" : " File";
                    message.info(count + file + " failed to attach");
                  }
                  setSelectedRowKeys4([]);
                  message.success("Successfully Added!");
                })
                .catch((err) => {
                  if (err.payload && err.payload.response == undefined) {
                    message.error(
                      "Max upload file size reached! Uploading is failed!"
                    );
                  }
                  setLoading(false);
                });
              setAttachmentsData({
                attachmentUrl: attachmentUrl,
                attachmentName: attachmentName,
              });
              setShowVisaFormPopup(false);
            } else {
              message.error(
                "Max upload file size reached! Uploading is failed!"
              );
            }
          }
        });
      }
    }
  };

  const handleCancelVisaFormPopup = () => {
    setShowVisaFormPopup(false);
  };

  const onGenerateLink = (item) => {
    // if(item.content) {

    // }
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let userId = localStorage.getItem("userId");
    var findContractIndex = contractsData.findIndex((obj) => obj.id == item.id);

    if (!item.content.includes("@ClientSignature")) {
      message.info("Client signature not found. Can not generate link!");
    } else {
      setLoading(true);
      let data = {
        id: 0,
        clientID: clientprofileid,
        contractHTML: item.content,
        contractSentDate: "2021-10-07T16:00:59.446Z",
        isSigned: false,
        contractSignedDate: "2021-10-07T16:00:59.446Z",
        contractPdfUrl: "",
        isSent: true,
        sentBy: 0,
        // branchID: selectedBranchId,
        header: item.header,
        city: "",
        countyCode: "",
        country: "",
        region: "",
        latitude: "",
        longitude: "",
        timezone: "",
        zip: "",
        ipAddress: "",
        oldId: "",
        countryOld: "",
        sentById: userId,
      };
      const contractOpt = {
        url: `v1/client/contract`,
      };
      contractOpt.types = ["GENAREATE_LINK_SUCCESS", "GENAREATE_LINK_FAILURE"];

      apiRefresh
        .post(contractOpt, data)
        .then((res) => {
          const VisaOpt = {
            url: `v1/client/contract/GetLink/${res}`,
          };
          VisaOpt.types = ["GET_LINK_SUCCESS", "GET_LINK_FAILURE"];

          let contracts = [...contractsData];
          apiRefresh
            .get(VisaOpt)
            .then((resp) => {
              setLoading(false);

              console.log(window.location);
              var http =
                window.location.host == "localhost:3000"
                  ? "http://"
                  : "https://";
              contracts[findContractIndex].hostString =
                "Copy the link in the email to send this contract, contract should have signature key (@ClientSignature) as the link purpose is to get the documents signed.";
              contracts[findContractIndex].host =
                http +
                window.location.host +
                `/AgreementBuilder/index.htm?param=${resp}`;

              setContractsData(contracts);

              // setKeys(resp.items);
            })
            .catch((err) => {
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const beforeUpload = (file, fileList) => {
    // Access file content here and do something with it
    // console.log(file)

    // Prevent upload
    return false;
  };

  const onClickPreview = (item) => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    var formData = new FormData();
    formData.append(`Html`, item.content);
    formData.append(`FileTitle`, "AgreementContract");
    formData.append(`BranchId`, selectedBranchId);
    setLoading(true);
    const docTypeOpt = {
      url: `v1/config/GetPdf`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh
      .post(docTypeOpt, formData)
      .then((res) => {
        const docViewOpt = {
          url: `v1/document/GetDocumentBytesforAttachment/${res.uri}/${selectedBranchId}`,
        };

        docViewOpt.types = [
          types.GET_DOCUMENT_PREVIEW_SUCCESS,
          types.GET_DOCUMENT_PREVIEW_FAILURE,
        ];
        apiRefresh
          .getFile(docViewOpt)
          .then((response) => {
            setLoading(false);
            const file = new Blob([response], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });

    // var findContractIndex = contractsData.findIndex((obj) => obj.id == item.id);
    // setLoading(true);
    // let data = {
    //   id: 0,
    //   clientID: clientprofileid,
    //   contractHTML: item.content,
    //   contractSentDate: "2021-10-07T16:00:59.446Z",
    //   isSigned: false,
    //   contractSignedDate: "2021-10-07T16:00:59.446Z",
    //   contractPdfUrl: "",
    //   isSent: true,
    //   sentBy: 0,
    //   branchID: selectedBranchId,
    //   header: item.header,
    //   city: "",
    //   countyCode: "",
    //   country: "",
    //   region: "",
    //   latitude: "",
    //   longitude: "",
    //   timezone: "",
    //   zip: "",
    //   ipAddress: "",
    //   oldId: "",
    //   countryOld: "",
    //   sentById: userId,
    // };
    // onGetLink(data).then((res) => {
    //   const VisaOpt = {
    //     url: `v1/client/contract/GetLink/${res.payload}`,
    //   };
    //   VisaOpt.types = ["GET_LINK_SUCCESS", "GET_LINK_FAILURE"];

    //   let contracts = [...contractsData];
    //   // apiRefresh.get(VisaOpt).then((resp) => {
    //   setLoading(false);

    //   console.log(window.location);
    //   // var http =
    //   //   window.location.host == "localhost:3000" ? "http://" : "https://";
    //   // var host =
    //   //   http +
    //   //   window.location.host +
    //   //   `/AgreementBuilder/index.htm?param=${res}`;

    //   // window.open(host + "&preview=1");

    //   // setContractsData(contracts);

    //   // setKeys(resp.items);
    //   // });
    // });
  };

  const handleCancelComDocPopup = () => {
    setShowComDocPopup(false);
  };

  const removeAttachment = async (attachment, index) => {
    let _resultSize = await getFileSizeSimple(attachment.url);

    let _size = [...size];

    setSize([_size[0] - _resultSize]);
    var attachmentsArray = [...showAttachmentsData];
    attachmentsArray.splice(index, 1);
    setShowAttachmentsData(attachmentsArray);
    let attachmentName = "";
    let attachmentUrl = attachment.url;
    let attachmentNameString = attachmentsData.attachmentName.split(";");
    let attachmentUrlString = attachmentsData.attachmentUrl.split(";");

    let findAttanchmentIndex = attachmentNameString.findIndex(
      (obj) => obj == attachment.name
    );
    if (findAttanchmentIndex > -1) {
      attachmentNameString.splice(findAttanchmentIndex, 1);
    }
    let findUrlIndex = attachmentUrlString.findIndex(
      (obj) => obj == attachment.url
    );

    if (findUrlIndex > -1) {
      attachmentUrlString.splice(findUrlIndex, 1);
    }
    if (attachmentNameString.length > 0) {
      let name = "";
      let url = "";
      for (var i = 0; i < attachmentNameString.length; i++) {
        if (i == 0) {
          name = attachmentNameString[i];
        } else {
          name += ";" + attachmentNameString[i];
        }
      }
      for (var i = 0; i < attachmentUrlString.length; i++) {
        if (i == 0) {
          url = attachmentUrlString[i];
        } else {
          url += ";" + attachmentUrlString[i];
        }
      }
      setAttachmentsData({
        attachmentName: name,
        attachmentUrl: url,
      });
    } else {
      setAttachmentsData({
        attachmentName: "",
        attachmentUrl: "",
      });
    }
  };

  const radioValueChange = (value) => {
    setNullRadioState(false);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Form onFinish={onFinish} form={form} className="width-100" name="main">
          <div className="border-box-checklist add-employer-para">
            {!studentEmail && (
              <Row gutter={8}>
                <Col>
                  <Form.Item required={false}>
                    <Select
                      showSearch
                      style={{ width: 250 }}
                      placeholder="CONTRACTS"
                      optionFilterProp="children"
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      value={contractValue}
                      onChange={(val) => onContractChange(val, "contract")}
                      dropdownMatchSelectWidth={false}
                    >
                      <Option key="">Contracts</Option>
                      {dataSource &&
                        dataSource.map((item) => (
                          <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item required={false}>
                    <Select
                      showSearch
                      style={{ width: 250 }}
                      placeholder="LETTERS"
                      optionFilterProp="children"
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      value={templateValue}
                      onChange={(val) => onContractChange(val, "letter")}
                      dropdownMatchSelectWidth={false}
                    >
                      <Option key="">Letters</Option>
                      {lettersData &&
                        lettersData.map((item) => (
                          <Option key={item.id}>{item.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Modal
              visible={showEmailPopup}
              title={"Important Emails"}
              onCancel={handleCancelEmailPopup}
              onOk={onOkButtn}
              width="800px"
            >
              <>
                {/* <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={onChangeCheckbox}
                > */}
                {/* {emailsList.length > 0 &&
                  emailsList.map((email, ind) => {
                    return ( */}
                <>
                  <Collapse
                    defaultActiveKey={["1"]}
                    // onChange={onChange}
                  >
                    <Panel header="Applicant" key="1">
                      {clientEmailsList.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={ind}
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Processing Persons" key="2">
                      {processingPersonEmails.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={clientEmailsList.length + ind}
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Users" key="3">
                      {usersEmailList.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={
                            clientEmailsList.length +
                            processingPersonEmails.length +
                            ind
                          }
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Agents" key="4">
                      {agentEmails.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={
                            clientEmailsList.length +
                            processingPersonEmails.length +
                            usersEmailList.length +
                            ind
                          }
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Visa Officers Emails" key="5">
                      {visaOfficersEmails.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={
                            clientEmailsList.length +
                            processingPersonEmails.length +
                            usersEmailList.length +
                            agentEmails.length +
                            ind
                          }
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Employer Emails" key="6">
                      {employerEmails.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={
                            clientEmailsList.length +
                            processingPersonEmails.length +
                            usersEmailList.length +
                            agentEmails.length +
                            visaOfficersEmails.length +
                            ind
                          }
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                    <Panel header="Groups" key="7">
                      {groupEmailsList.map((email, ind) => (
                        <EmailPopupcontent
                          email={email}
                          index={
                            clientEmailsList.length +
                            processingPersonEmails.length +
                            usersEmailList.length +
                            agentEmails.length +
                            visaOfficersEmails.length +
                            employerEmails.length +
                            ind
                          }
                          onChangeMailOptions={onChangeMailOptions}
                          nullRadioState={nullRadioState}
                          radioValueChange={radioValueChange}
                        />
                      ))}
                    </Panel>
                  </Collapse>
                </>
                {/* );
                  })} */}
                {/* </Checkbox.Group> */}
              </>
            </Modal>
            {!studentEmail && (
              <div>
                <div
                  className="email-address-btn"
                  onClick={() => {
                    // setFilteredArr([]);
                    // tempArray = [];
                    // setNullRadioState(true);
                    setShowEmailPopup(true);
                  }}
                >
                  <span>Email Addresses</span>
                </div>
              </div>
            )}
            <div className="create-email-form" style={{ width: "90%" }}>
              {!studentEmail && (
                <Form.Item required={false}>
                  <Checkbox onChange={onRequiredChange} checked={isSendCopy}>
                    Send me a copy
                  </Checkbox>
                </Form.Item>
              )}
              <Form.Item name="to" rules={[{ required: true }]}>
                <Input
                  placeholder="To"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="ccc" required={false}>
                <Input
                  placeholder="CC"
                  value={ccc}
                  onChange={(e) => setCcc(e.target.value)}
                />
              </Form.Item>
              {/* <Form.Item name="bcc" required={false}>
                <Select
                  showSearch
                  style={{ width: 70 }}
                  placeholder="CC"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="tom">Tom</Option>
                </Select>
              </Form.Item> */}
              <Form.Item name="bcc" required={false}>
                <Input
                  placeholder="Bcc"
                  value={bcc}
                  onChange={(e) => setBcc(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="subject" rules={[
                { required: true  },
                {
                  max: 280,
                  message: "Subject should be less than 280 character",
                }
                ]}>
                <Input
                  placeholder="Subject"
                   maxLength={280}
                  onChange={(e) => setSubject(e.target.value)}
                /> 
              </Form.Item>
              
            </div>
            {/*<Row>*/}
            {/*  <Col xs={8} style={{ marginTop: "3vh", marginBottom: "4vh" }}>*/}
            {/*    /!* eslint-disable-next-line react/jsx-no-undef *!/*/}
            {/*    <Select*/}
            {/*      placeholder={"Select content type"}*/}
            {/*      virtual={false}*/}
            {/*      onChange={(value) => {*/}
            {/*        let signature = signatureRes ? signatureRes.signatureText : "";*/}
            {/*        setLetterString("<p>Dear " +*/}
            {/*          profileData.fullName +*/}
            {/*          value + signature)*/}
            {/*      }*/}
            {/*    }*/}
            {/*    >*/}
            {/*      {resultList && resultList.map((item, index) => (*/}
            {/*          <Option value={item.emailContent}>{item.emailType}</Option>*/}
            {/*        ))}*/}
            {/*    </Select>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {contractsData &&
              contractsData.map((data, index) => {
                return (
                  <Fragment>
                    <div style={{ margin: 15 }}>
                      <div>
                        <span style={{ fontSize: 12 }}>{data.hostString}</span>
                      </div>
                      <div style={{ marginTop: 10, display: "flex" }}>
                        <a href={data.host} target="_blank">
                          <span style={{ fontSize: 12 }}>{data.host}</span>
                        </a>
                        {data.host && (
                          <div style={{ marginLeft: 20, cursor: "pointer" }}>
                            {/* <CopyOutlined
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            /> */}
                            <Button
                              style={{
                                fontSize: 10,
                                fontWeight: "500",
                                padding: 5,
                                borderRadius: 5,
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(data.host);
                                message.info("Copied!");
                              }}
                            >
                              Copy Link
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex width-100">
                      <div className="client-ehead d-flex space-between align-center">
                        <div>
                          <Form.Item required={false}>
                            <Checkbox
                              onChange={(e) =>
                                contractDataAttachment(e.target.checked, data)
                              }
                              checked={data.isAttachment}
                            >
                              Send as an attachment
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <div>
                          <Button
                            type="primary"
                            className="login-form-button save-btn button-blue"
                            onClick={() => onGenerateLink(data)}
                          >
                            Generate Contract Link
                          </Button>
                        </div>
                      </div>
                      <div className="client-ehead d-flex space-between align-center">
                        <div>
                          <Form.Item required={false}>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => onClickPreview(data)}
                            >
                              Preview (for attachment only)
                            </Button>
                          </Form.Item>
                        </div>
                        {(showEditor || contractsData.length > 1) && (
                          <div className="close-editor">
                            <CloseCircleOutlined
                              style={{
                                color: "white",
                                backgroundColor: "#6C6666",
                                borderRadius: "44px",
                                // width: "30px",
                                fontSize: "20px",
                                marginRight: "8px",
                              }}
                              onClick={() => removeClientEmail(index, data.id)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "11px" }}
                      className="  letter-froala"
                    >
                      <CreateEmailEditors
                        data={data}
                        handleEditNote={handleEditNote}
                      />
                    </div>
                  </Fragment>
                );
              })}
            {contractsData.length > 0 && showEditor && (
              <div className="close-editor">
                <CloseCircleOutlined
                  style={{
                    color: "white",
                    backgroundColor: "#6C6666",
                    borderRadius: "44px",
                    // width: "30px",
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                  onClick={() => setShowEditor(false)}
                />
              </div>
            )}
            {showEditor && (
              <div className="margin-top-20 letter-froala">
                <FroalaEditorCom
                  setLoading={(value) => setLoading(value)}
                  model={letterString}
                  onModelChange={(value) => setLetterString(value)}
                />
              </div>
            )}

            {!studentEmail && (
              <div className="margin-top-20">
                <Row>
                  <Col span={24}>
                    <div className="ts-upload-file-cont">
                      <Form.Item name="username">
                        {!studentEmail && (
                          <Upload
                            multiple
                            style={{ backgroundColor: "#fff" }}
                            listType="picture-card"
                            fileList={[]}
                            beforeUpload={beforeUpload}
                            onPreview={handlePreview}
                            onChange={onHandleChange}
                            className={"upload-technical-support"}
                          >
                            {fileList.length < 10 && (
                              <div className="import-file-button-sec">
                                <div
                                  onClick={() => {
                                    setCounter(1);
                                  }}
                                >
                                  <PlusOutlined />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                              </div>
                            )}
                          </Upload>
                        )}
                      </Form.Item>
                      {/* <Button
              // onClick={handleSubmit}
              className="float-right"
              htmlType="submit"
              type="primary"
            >
              Upload
            </Button> */}

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          {showDocPopup ? (
            <Modal
              visible={showDocPopup}
              title={"Client Documents"}
              onCancel={handleCancelDocPopup}
              onOk={onOkClientDocuments}
              width="600px"
            >
              <>
                <Table
                  className="border-3"
                  rowSelection={rowSelection1}
                  showCount={true}
                  columns={columns}
                  dataSource={docArray}
                  pagination={false}
                />
              </>
            </Modal>
          ) : null}
          {showComDocPopup ? (
            <Modal
              visible={showComDocPopup}
              title={"Common Documents"}
              onCancel={handleCancelComDocPopup}
              onOk={onOkCommonDocuments}
              width="600px"
            >
              <>
                <Table
                  className="border-3"
                  rowSelection={rowSelection2}
                  showCount={true}
                  columns={comDocColumns}
                  dataSource={commonDocArray}
                  pagination={false}
                />
              </>
            </Modal>
          ) : null}
          {showVisaFormPopup ? (
            <Modal
              visible={showVisaFormPopup}
              title={"Visa Form"}
              onCancel={handleCancelVisaFormPopup}
              onOk={onOkVisaForm}
              width="600px"
            >
              <>
                <Tabs
                  activeKey={handleInnerKey}
                  type="card"
                  size={"small"}
                  className="mar-r employer-doc-tab"
                  onChange={(key) => setInnerHandleKey(key)}
                >
                  <TabPane tab="NZ Visa Form" key="1">
                    <Table
                      className="border-3"
                      rowSelection={rowSelection3}
                      showCount={true}
                      columns={visaFormColumns}
                      dataSource={visaFormArray}
                      pagination={false}
                    />
                  </TabPane>
                  <TabPane tab="Aus Visa Form" key="2">
                    <Table
                      className="border-3"
                      rowSelection={rowSelection4}
                      showCount={true}
                      columns={visaFormColumns}
                      dataSource={ausVisaFormArray}
                      pagination={false}
                    />
                  </TabPane>
                </Tabs>
              </>
            </Modal>
          ) : null}
          {!studentEmail && (
            <div>
              <p className="label">Attachment(S)</p>
              <Row className="margin-top-12" gutter={10}>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="login-form-button save-btn button-blue"
                      onClick={() => setShowDocPopup(true)}
                    >
                      Client Documents
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      // onClick={() => DraftData()}
                      type="primary"
                      className="login-form-button save-btn button-blue"
                      onClick={() => setShowComDocPopup(true)}
                    >
                      Common Documents
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      // onClick={() => DraftData()}
                      type="primary"
                      className="login-form-button save-btn button-blue"
                      onClick={() => setShowVisaFormPopup(true)}
                    >
                      Visa Form
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <div className="attachments-container">
                {showAttachmentsData.length > 0 &&
                  showAttachmentsData.map((attachment, ind) => (
                    <div className="attachment-content-item">
                      <img
                        src={Images.crossWhite}
                        style={{
                          width: 10,
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                        onClick={() => removeAttachment(attachment, ind)}
                      />
                      <span className="attachment-content-text">
                        {attachment.name}
                      </span>
                    </div>
                  ))}
              </div>
              <Row>
                <div></div>
              </Row>
            </div>
          )}
          <Row
            className="margin-top-12"
            gutter={10}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  className="login-form-button save-btn button-blue"
                  htmlType="submit"
                >
                  Send Now
                </Button>
              </Form.Item>
            </Col>
            {!studentEmail && (
              <Col>
                <Form.Item>
                  <Button
                    onClick={() => DraftData()}
                    type="primary"
                    className="login-form-button save-btn button-blue"
                  >
                    Save As Draft
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
    </Fragment>
  );
};
export default DocumentChecklistForm;
