import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, message, Radio } from "antd";
import FroalaEditorCom from "../../Containers/FroalaEditorCom";

const SettingFileNotesForm = ({
  handleCancel,

  setLoading,

  createMode,

  updatedata,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onAddLetterTemplates,

  onUpdetaLetterTemplates,

  setUpdatedata,

  setStoreData,
  storeData,
}) => {
  const [letterString, setLetterString] = useState("");

  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    console.log("Received values of form:", values);
    var header = false;
    if (values.header == 1) {
      header = true;
    }

    if (createMode === "add-file-note") {
      const data = {
        id: 0,
        type: "FILENOTE",
        name: values.name,
        content: letterString,
        header: false,
        defaultContract: true,
      };

      onAddLetterTemplates(data)
        .then(() => handleCancel())
        .then(() => onGetLetterTemplates())
        .then(() => {
          setLoading(false);
          message.success("Successfully Added!");
        });
    } else {
      const updata = {
        id: storeData.id,
        type: "FILENOTE",
        name: values.name,
        content: letterString,
        header: false,
        defaultContract: true,
      };

      onUpdetaLetterTemplates(updata)
        .then(() => handleCancel())
        .then(() => onGetLetterTemplates())
        .then(() => {
          setLoading(false);
          message.success("Successfully Updated!");
        });
    }
  };

  useEffect(() => {
    if (storeData) {
      var Header = 2;
      if (storeData.header == true) {
        Header = 1;
      }

      form.setFieldsValue({
        name: storeData.name,
        header: Header,
      });
      setLetterString(storeData.content);
    }
  }, [storeData]);

  let config = {
    key:
      "YNB3fA3A7A8B6A4C3A-9UJHAEFZMUJOYGYQEa1c1ZJg1RAeF5C4C3G3E2C2A3D6B3E3==",
    height: "auto",
    toolbarSticky: false,
    events: {
      "charCounter.update": () => {
        // Do something here.
        // this is the editor instance.
        console.log("char");
      },
    },
  };

  return (
    <Fragment>
      <Form
        className="width-100 border-box padding-20"
        name="main"
        onFinish={onFinish}
        form={form}
      >
        <div className="width-100 d-flex align-center letter-font">
          <div className="w-20 ">
            <p>Name</p>
          </div>
          <div className="w-80">
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Required!" },
                {
                  max: 100,
                  message: "Name should be less than 100 character",
                },
              ]}
            >
              <Input placeholder="Add New" />
            </Form.Item>
          </div>
        </div>
        {/* <div className="width-100 d-flex align-center letter-font letter-spacing">
          <div className="w-20 ">
            <p>Show Header</p>
          </div>
          <div className="w-80">
            <Form.Item name="header">
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div> */}
        <Form.Item className="" name="content">
          <div className="width-100 d-flex align-center letter-font letter-spacing">
            <div className="w-20">
              <p>Contents</p>
            </div>
            <div className="w-80 letter-froala">
              <FroalaEditorCom
                setLoading={(value) => setLoading(value)}
                model={letterString}
                onModelChange={(value) => setLetterString(value)}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <Button
              className="form-btn button-blue"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            {/* <div className="margin-btns">
            <Button className="form-btn button-blue" type="primary">
              PREVIEW
            </Button>
          </div> */}
            <div className="margin-btns">
              <Button
                onClick={() => handleCancel()}
                className="form-btn button-blue"
                type="primary"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form.Item>
        <Form.Item></Form.Item>
      </Form>
    </Fragment>
  );
};
export default SettingFileNotesForm;
