import React, { Fragment, useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import {
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { apiRefresh } from "../../services/api";
const dateFormat = "DD/MM/YYYY";
function onChange(date, dateString) {
  console.log(date, dateString);
}

const { Option } = Select;

const AddStageForm = ({
  onAddSalePipeline,
  handleCancel,

  createMode,

  setLoading,
  loading,
  onGetSalePipeline,
  updatedata,
  pipelineObj,
}) => {
  const [openDateChange, setOpenDateChange] = useState("");
  const [closeDateChange, setCloseDateChange] = useState("");
  const [remunerationChange, setRemunerationChange] = useState("");
  const [requiredChange, setRequiredChange] = useState("");
  const [advertisingExpiry, setAdvertisingExpiry] = useState("");
  const [skillMatesReportExpiry, setSkillMatesReportExpiry] = useState("");
  const [letterString, setLetterString] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    if (updatedata && updatedata.id) {
      form.setFieldsValue({
        name: updatedata.name,
        winProbability: updatedata.winProbability,
      });
    }
    setLoading(false);
  }, [updatedata, form, setLoading]);

  const onFinish = (values) => {
    setLoading(true);
    let userId = localStorage.getItem("userId");
    if (createMode === "add-stage") {
      let data = {
        name: values.name || "",
        description: values.description || "",
        winProbability: values.winProbability || 0,
        orderInList: 0,
        pipelineId: pipelineObj.id,
      };
      const addPipelineStageOpt = {
        url: `v1/deal/stage`,
      };
      addPipelineStageOpt.types = [
        "REMOVE_PIPELINE_SUCCESS",
        "REMOVE_PIPELINE_FAILURE",
      ];

      apiRefresh
        .post(addPipelineStageOpt, data)
        .then(() => {
          setLoading(false);
          onGetSalePipeline();
          handleCancel();
          message.success("Successfully Added!");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      const update = {
        id: updatedata.id,
        name: values.name || "",
        description: values.description || "",
        winProbability: values.winProbability || 0,
        orderInList: 0,
        pipelineId: updatedata.pipelineId,
      };

      const remPipelineOpt = {
        url: `v1/deal/stage`,
      };
      remPipelineOpt.types = [
        "UPDATE_PIPELINE_SUCCESS",
        "UPDATE_PIPELINE_FAILURE",
      ];

      apiRefresh
        .put(remPipelineOpt, update)
        .then(() => {
          handleCancel();
          onGetSalePipeline();
          setLoading(false);
          message.success("Successfully Updated!");
        })
        .catch(() => {
          setLoading(false);
          message.error("Failed!");
        });
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <Fragment>
        <Form
          onFinish={onFinish}
          form={form}
          className="emp-content-box p-box add-job-form width-100"
          name="main"
        >
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Name</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Required!" }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="margin-top">
            <Col span={8}>
              <p style={{ fontSize: 13 }}>Win Probability %</p>
            </Col>
            <Col span={16}>
              <Form.Item
                name="winProbability"
                // rules={[{ required: true, message: "Required!" }]}
              >
                <Select placeholder="">
                  <Option value={10}>10</Option>
                  <Option value={20}>20</Option>
                  <Option value={30}>30</Option>
                  <Option value={40}>40</Option>
                  <Option value={50}>50</Option>
                  <Option value={60}>60</Option>
                  <Option value={70}>70</Option>
                  <Option value={80}>80</Option>
                  <Option value={90}>90</Option>
                  <Option value={100}>100</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="margin-top-20">
            <div className="letter-froala emp-froala">
              <div className="document-checklist--btn d-flex">
                <Button
                  style={{ marginRight: "10px" }}
                  // onClick={() => emailTypeData(data.emailType)}
                  htmlType="submit"
                  type="primary"
                  className="button-blue"
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel()}
                  type="primary"
                  className="button-blue"
                >
                  Close
                </Button>
              </div>
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    </Spin>
  );
};
export default AddStageForm;
