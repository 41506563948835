import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Select, Checkbox } from "antd";
import moment from "moment";
import activityData from "../../Components/ClientActivity/ActivityData";

const AddReminderModal = ({
  onAddReminderTask,
  onGetReminderTasks,
  onSearchClient,
  onSearchPotentialClient,
  searchClientData,
  potentialClientsData,
  handleCancel,
  reminderData,
  onUpdateReminderDetail,
}) => {
  const [linkClient, setLinkClient] = useState("");
  const [linkClientName, setLinkClientName] = useState("");
  const [isPotential, setIsPotential] = useState(false);
  const [connectionsList, setConnectionsList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  // const connectionsList = [];

  const [form] = Form.useForm();

  const dateFormate = "DD/MM/YYYY";

  useEffect(() => {
    if (reminderData) {
      if (connectionsList.length === 0) {
        setConnectionsList((prevState) => [
          ...prevState,
          <Option key={reminderData.subjectId}>
            {reminderData.subjectName}
          </Option>,
        ]);
      }

      if (linkClient === "") {
        setLinkClient(reminderData.subjectId);
      }
      if (linkClientName === "") {
        setLinkClientName(reminderData.subjectName);
      }

      form.setFieldsValue({
        subjectId: reminderData.subjectId,
        reminderDetail: reminderData.reminderDetail,
        reminderDescription: reminderData.reminderDescription,
        reminderDate: moment(reminderData.reminderDate),
        reminder: true,
      });
    } else {
      form.setFieldsValue({
        reminder: true,
      });
    }
  }, []);

  const onChangeHandler = (value) => {
    setLinkClient(value);
    var findClient = clientList.find((obj) => obj.id === value);

    if (findClient) {
      setLinkClientName(findClient.firstName + " " + findClient.lastName);
    }
  };

  const onSearch = (val) => {
    console.log("search:", val);
    var users = [];
    if (val.length > 2) {
      // setConnectionsList([]);
      if (isPotential) {
        onSearchPotentialClient(val).then((res) => {
          if (
            res.payload.potentialClients &&
            res.payload.potentialClients.length > 0
          ) {
            setClientList(res.payload.potentialClients);
            users = [];
            for (let i = 0; i < res.payload.potentialClients.length; i++) {
              users.push(
                <Option key={res.payload.potentialClients[i].id}>
                  {res.payload.potentialClients[i].label}
                </Option>
              );
            }
            setConnectionsList(users);
          }
        });
      } else {
        onSearchClient(val).then((res) => {
          if (res.payload.clients && res.payload.clients.length > 0) {
            setClientList(res.payload.clients);
            users = [];
            for (let i = 0; i < res.payload.clients.length; i++) {
              users.push(
                <Option key={res.payload.clients[i].id}>
                  {res.payload.clients[i].firstName +
                    " " +
                    res.payload.clients[i].lastName}
                </Option>
              );
            }
            setConnectionsList(users);
          }
        });
      }
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      subjectId: linkClient,
      subjectName: linkClientName,
      id: 0,
      reminderTypeId: 1,
      reminderDescription: values.reminderDescription,
      reminderDetail: values.reminderDetail,
      isCompleted: false,
      reminderDate: values.reminderDate || "1900-01-01T00:00:00+00",
      expiryDate: values.reminderDate || "1900-01-01T00:00:00+00",
      valueA: "",
    };
    onAddReminderTask(data)
      .then(() => {
        onGetReminderTasks();
        setLoading(false);
        form.resetFields();
        setLinkClient("");
        setLinkClientName("");
        handleCancel();
        let userName = localStorage.getItem("userName");
        let myData = {
          clientName: data.subjectName,
          logMessage:
            "\nReminder " + values.task_title + " added by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Daily Tasks/Reminders",
          invoiceId: "0",
          notClientPart: true,
          linkedClient: data.subjectId,
        };
        activityData(myData);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onChangePotential = (e) => {
    setIsPotential(e.target.checked);
  };
  return (
    <Form name="basic" form={form} onFinish={onFinish}>
      <div>
        <div>
          <span className="cv-bold-text">SELECT CLIENT (OPTIONAL)</span>
        </div>

        <div className="mdtr-modal-gray-cont">
          <div className="modal-parts">
            <div className="mdtr-checkbox-cont">
              <Checkbox
                checked={isPotential}
                onChange={onChangePotential}
                className="sus-checkbox"
              />
              <span className="cv-normal-text">Potential Client</span>
            </div>
            <Form.Item
              className="add-reminder-form form-parts"
              label="Link Client"
              name="subjectId"
              required={false}
              rules={[{ required: true, message: "Required!" }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={onChangeHandler}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={linkClient}
              >
                {connectionsList}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <span style={{ marginTop: 39 }} className="cv-bold-text">
            ADD REMINDER
          </span>
        </div>
        <div className="mdtr-modal-gray-cont">
          <div className="modal-parts">
            <Form.Item
              className="form-parts"
              label="Reminder Title"
              name="reminderDetail"
              rules={[{ required: true, message: "Required!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="form-parts"
              label="Reminder Description"
              name="reminderDescription"
              rules={[{ required: true, message: "Required!" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              className="form-parts"
              label="Select Date"
              name="reminderDate"
              rules={[{ required: true, message: "Required!" }]}
            >
              <DatePicker format={dateFormate} />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="button-blue"
          >
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddReminderModal;
