import { api } from "../../services/api";
import * as types from "../Constants";

export const addQualification = (data) => {
  const options = {
    url: "v1/client/educationalhistory",
  };

  options.types = [
    types.ADD_CLIENT_QUALIFICATION_SUCCESS,
    types.ADD_CLIENT_QUALIFICATION_FAILURE,
  ];

  return api.post(options, data);
};

export const getQualification = () => {
  let clientprofileid = sessionStorage.getItem("clientprofileid");
  const options = {
    url:
      "v1/client/educationalhistory/GetAllEducationalHistoryByClientId/" +
      clientprofileid,
  };

  options.types = [
    types.GET_CLIENT_QUALIFICATION_SUCCESS,
    types.GET_CLIENT_QUALIFICATION_FAILURE,
  ];

  return api.get(options);
};

export const updateQualification = (data) => {
  const options = {
    url: "v1/client/educationalhistory",
  };

  options.types = [
    types.UPDATE_CLIENT_QUALIFICATION_SUCCESS,
    types.UPDATE_CLIENT_QUALIFICATION_FAILURE,
  ];

  return api.put(options, data);
};
