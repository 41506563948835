import React from "react";
import "./AgentStyles.css";
import { Images } from "../Themes";
import { Table, Row, Col, Spin } from "antd";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { apiRefresh } from "../services/api";
import {
  UserOutlined,
  LinkOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";

const headOption = [{ tabName: "AGENTS", linkName: "/agent" }];

var agentId = "";
var clientId = "";

class Agents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      linkVisible: false,
      agentsList: [],
      agentClientsList: [],
      expandedRowKeys: [],
      expandedRowClientKeys: [],
      schPaymentList: [],
      loading: true,
      clientsList: [
        {
          title: "Status",
          dataIndex: "",
          key: "",
          // render: (text, record) => {
          //   return (
          //     <div onClick={() => this.getAgentClients}>
          //       <span>{text}</span>
          //     </div>
          //   );
          // },
        },
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          render: (text, record) => {
            return (
              <div onClick={() => this.getAgentClients}>
                <span>{record.firstName + " " + record.lastName}</span>
              </div>
            );
          },
        },
        {
          title: "Ezm Id",
          dataIndex: "ezmId",
          key: "ezmId",
          // render: (text, record) => {
          //   return (
          //     <div onClick={() => this.getAgentClients}>
          //       <span>{text}</span>
          //     </div>
          //   );
          // },
        },
        {
          title: "Type",
          dataIndex: "memberType",
          key: "memberType",
          // render: (text, record) => {
          //   return (
          //     <div onClick={() => this.getAgentClients}>
          //       <span>{text}</span>
          //     </div>
          //   );
          // },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          // render: (text, record) => {
          //   return (
          //     <div onClick={() => this.getAgentClients}>
          //       <span>{text}</span>
          //     </div>
          //   );
          // },
        },
        {
          title: "Commission Due",
          dataIndex: "comission",
          key: "comission",
          render: (text, record) => {
            return (
              <div>
                <span>{text - record.paid}</span>
              </div>
            );
          },
        },
        {
          title: "Commission Paid",
          dataIndex: "paid",
          key: "paid",
          // render: (text, record) => {
          //   return (
          //     <div onClick={() => this.getAgentClients}>
          //       <span>{text}</span>
          //     </div>
          //   );
          // },
        },
      ],
      columns: [
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          render: (text, record) => {
            return (
              <div>
                <span>{text}</span>
              </div>
            );
          },
        },
        {
          title: "Country",
          dataIndex: "userAddress",
          key: "userAddress",
          render: (text, record) => {
            return <span>{record.country}</span>;
          },
        },
        {
          title: "City",
          dataIndex: "userAddress",
          key: "userAddress",
          render: (text, record) => {
            return <span>{record.city}</span>;
          },
        },
        {
          title: "Email",
          dataIndex: "alternateEmail",
          key: "alternateEmail",
        },
        {
          title: "Commission Paid",
          dataIndex: "paid",
          key: "paid",
        },
        {
          title: "Commission Due",
          dataIndex: "comission",
          key: "comission",
          render: (text, record) => {
            return (
              <div>
                <span>{text - record.paid}</span>
              </div>
            );
          },
        },
        // {
        //   title: "Action",
        //   dataIndex: "",
        //   key: "",
        //   render: (text, record) => (
        //     <div style={{ display: "flex" }}>
        //       <div>
        //         <img
        //           src={Images.disable}
        //           style={{ width: 15, height: 15, cursor: "pointer" }}
        //         />
        //       </div>
        //       <div style={{ marginLeft: 5 }}>
        //         <img
        //           src={Images.docType}
        //           style={{ width: 15, height: 15, cursor: "pointer" }}
        //         />
        //       </div>
        //       <div style={{ marginLeft: 5 }}>
        //         <img
        //           src={Images.crossRed}
        //           style={{ width: 15, height: 15, cursor: "pointer" }}
        //         />
        //       </div>
        //     </div>
        //   ),
        // },
      ],
      expandedColumns: [],
      schPaymentList: [],
      paymentColumns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          width: "100px",
          render: (text, record) => {
            return (
              <div>
                <span>{moment(text).format("DD/MM/YYYY")}</span>
              </div>
            );
          },
        },
        {
          title: "Due",
          dataIndex: "commission",
          key: "commission",
          width: "100px",
          render: (text, record) => {
            return (
              <div>
                <span>{text - record.paid}</span>
              </div>
            );
          },
        },
        {
          title: "Paid",
          dataIndex: "paid",
          key: "paid",
          width: "100px",
        },
        // {
        //   title: "#",
        //   dataIndex: "",
        //   key: "",
        //   width: "30px",
        //   render: (text, record) => {
        //     return (
        //       <>
        //         {record.commission > record.paid ? (
        //           <div
        //             className="ca-show-add-cont"
        //             style={{
        //               paddingTop: 6,
        //               paddingBottom: 6,
        //               cursor: "pointer",
        //             }}
        //             onClick={() => {
        //               this.setState({
        //                 visible: true,
        //                 commissionId: record.id,
        //                 amount: record.commission - record.paid,
        //               });
        //             }}
        //           >
        //             <span className="ca-show-add-text" style={{ fontSize: 8 }}>
        //               PAY NOW
        //             </span>
        //           </div>
        //         ) : (
        //           <div className="ca-green-cont">
        //             <span className="ca-colored-box-text">Paid</span>
        //           </div>
        //         )}
        //       </>
        //     );
        //   },
        // },
      ],
    };
  }

  componentDidMount() {
    this.props
      .onGetAgents()
      .then((res) => {
        this.setState({ loading: false });
        this.props.agentsRes &&
          this.props.agentsRes.users.map((data, index) => {
            data.index = index;
            data.key = `${index + 1}`;
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  getAgentClientsData = (agentId) => {
    // let data = {
    //   firstName: "",
    //   lastName: "",
    //   address: "",
    //   occupation: "",
    //   pageNumber: 1,
    //   pageSize: 1000,
    //   priority: 0,
    //   interestedVisa: "",
    //   toDate: "1900-01-01T00:00:00Z",
    //   fromDate: "1900-01-01T00:00:00Z",
    //   clientTag: "",
    //   clientStatus: "",
    //   email: "",
    //   exclude: true,
    //   salePerson: "",
    //   processingPerson: [""],
    //   agentId: agentId,
    // };
    let data = {
      firstName: "",
      lastName: "",
      email: "",
      occupation: "",
      pageSize: 10,
      pageNumber: 1,
      mobile: "",
      company: "",
      activeInactive: false,
      clientSort: "desc",
      passportNo: "",
      surnameStart: "",
      status: 0,
      dateOfBirth: "1900-01-01T00:00:00+00",
      jobSector: "",
      includeDeleted: false,
      internalId: "",
      familyMember: "",
      visaType: 0,
      salePerson: "",
      clientType: "",
      inzClient: "",
      clientTags: "",
      clientNumber: "",
      active: "All",
      includeAll: true,
      agentUserId: agentId,
    };
    this.setState({ loading: true });
    // const agentClientsOpt = {
    //   url: `v1/potentialclient/AgentClients`,
    // };
    // agentClientsOpt.types = [
    //   "GET_AGENT_CLIENTS_SUCCESS",
    //   "GET_AGENT_CLIENTS_FAILURE",
    // ];
    // apiRefresh
    //   .post(agentClientsOpt, data)
    this.props
      .onSearchConnection(data)
      .then(() => {
        this.setState({ loading: false });
        this.props.searchConnectionData &&
          this.props.searchConnectionData.items.map((data, index) => {
            data.index = index;
            data.key = `${index + 1}`;
          });
        this.setState({
          agentClientsList: this.props.searchConnectionData.items,
        });
      })
      .catch((err) => {
        this.setState({ agentClientsList: [], loading: false });
      });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  openLinkModal = () => {
    this.setState({
      linkVisible: true,
    });
  };

  closeLinkModal = () => {
    this.setState({
      linkVisible: false,
    });
  };

  getAgentClients = (record, onExpand, expanded) => {
    onExpand(record);
    let keys = [];
    keys.push(record.key);
    this.setState({ expandedRowKeys: keys });
    if (expanded) {
      this.setState({ expandedRowKeys: [] });
    }

    this.getAgentClientsData(record.id);
  };

  getScheduleCommissions = (record, onExpand, expanded) => {
    let keys = [];
    keys.push(record.key);
    this.setState({ expandedRowClientKeys: keys });
    if (expanded) {
      this.setState({ expandedRowClientKeys: [] });
    }
    const getSchComOpt = {
      url: `v1/agent/commission/GetAllCommissions/${agentId}/${clientId}`,
    };
    getSchComOpt.types = [
      "GET_AGENT_CLIENTS_SUCCESS",
      "GET_AGENT_CLIENTS_FAILURE",
    ];
    this.setState({ loading: true });
    apiRefresh
      .get(getSchComOpt)
      .then((res) => {
        this.setState({ schPaymentList: res.items, loading: false });
      })
      .catch((err) => {
        this.setState({ schPaymentList: [], loading: false });
      });
  };

  render() {
    const {
      selectedOption,
      agentClientsList,
      clientsList,
      schPaymentList,
      loading,
    } = this.state;
    const { agentsRes } = this.props;
    return (
      <div>
        <Spin spinning={loading} size="large">
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <PotentialHeaderTabs data={headOption} activeTab="AGENTS" />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginRight: 20,
                }}
              >
                <div className="pciq-top-div" style={{ marginTop: 0 }}>
                  <span
                    className="pc-top-div-text"
                    style={{ color: "#0A3C5D" }}
                  >
                    AGENTS
                  </span>
                </div>
                {/* <div onClick={this.openModal}>
                <img src={Images.plusIcon} style={{ width: 20, height: 20 }} />
              </div> */}
              </div>

              <div className="report-container">
                <div>
                  <Table
                    expandedRowKeys={this.state.expandedRowKeys}
                    expandable={{
                      expandIconColumnIndex: 9,
                      // expandedRowKeys: [this.state.invoicesList[0].id],
                      expandedRowRender: (record) => (
                        <Row>
                          <Col xs={24}>
                            <Row>
                              <Col
                                xs={24}
                                className="accounts-flow-table school-table"
                              >
                                <Table
                                  dataSource={agentClientsList}
                                  rowClassName={(record, index) =>
                                    "invoices-children-table-row"
                                  }
                                  columns={clientsList}
                                  bordered={false}
                                  pagination={false}
                                  expandedRowKeys={
                                    this.state.expandedRowClientKeys
                                  }
                                  expandable={{
                                    expandIconColumnIndex: 7,
                                    expandedRowRender: (record) => (
                                      <Row>
                                        <Col xs={24}>
                                          <Row>
                                            <Col
                                              xs={24}
                                              className="accounts-flow-table school-table"
                                            >
                                              <Table
                                                dataSource={schPaymentList}
                                                rowClassName={(record, index) =>
                                                  "invoices-children-table-row"
                                                }
                                                columns={
                                                  this.state.paymentColumns
                                                }
                                                bordered={false}
                                                pagination={false}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    ),

                                    expandIcon: ({
                                      expanded,
                                      onExpand,
                                      record,
                                    }) => (
                                      <div
                                        className="table-action"
                                        type="right"
                                      >
                                        <div style={{ display: "flex" }}>
                                          {/* <div>
                            <img
                              src={Images.disable}
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                            />
                          </div> */}
                                          {/* <div style={{ marginLeft: 5 }}>
                                          <img
                                            src={Images.docType}
                                            style={{
                                              width: 15,
                                              height: 15,
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              onExpand(record, e);
                                              this.getAgentClients(
                                                record,
                                                onExpand,
                                                expanded
                                              );
                                            }}
                                          />
                                        </div> */}
                                          <div
                                            className="ca-show-add-cont"
                                            style={{
                                              paddingTop: 6,
                                              paddingBottom: 6,
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              console.log(record);
                                              clientId = record.id;
                                              this.getScheduleCommissions(
                                                record,
                                                onExpand,
                                                expanded
                                              );
                                              // this.setState({
                                              //   visible: true,
                                              //   commissionId: record.id,
                                              //   amount:
                                              //     record.commission - record.paid,
                                              // });
                                            }}
                                          >
                                            <span
                                              className="ca-show-add-text"
                                              style={{ fontSize: 8 }}
                                            >
                                              Payment Plan
                                            </span>
                                          </div>
                                          {/* <div style={{ marginLeft: 5 }}>
                            <img
                              src={Images.crossRed}
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                            />
                          </div> */}
                                        </div>
                                      </div>
                                    ),
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ),

                      expandIcon: ({ expanded, onExpand, record }) => (
                        <div className="table-action" type="right">
                          <div style={{ display: "flex" }}>
                            {/* <div>
                            <img
                              src={Images.disable}
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                            />
                          </div> */}
                            <div style={{ marginLeft: 5 }}>
                              {/* <img
                              src={Images.docType}
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                              // onClick={(e) => {
                              //   onExpand(record, e);
                              //   this.getAgentClients(
                              //     record,
                              //     onExpand,
                              //     expanded
                              //   );
                              // }}
                            /> */}
                            </div>
                            <div style={{ marginLeft: 5 }}>
                              <UserOutlined
                                style={{
                                  width: 15,
                                  height: 15,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  onExpand(record, e);

                                  agentId = record.id;
                                  this.getAgentClients(
                                    record,
                                    onExpand,
                                    expanded
                                  );
                                }}
                              />
                            </div>
                            {/* <div style={{ marginLeft: 5 }}>
                            <img
                              src={Images.crossRed}
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                            />
                          </div> */}
                          </div>
                        </div>
                      ),
                    }}
                    dataSource={agentsRes && agentsRes.users}
                    // onChange={this.paginate}
                    columns={this.state.columns}
                    pagination={false}
                    // pagination={{
                    //   showSizeChanger: false,
                    //   defaultCurrent: this.state.defaultCurrent,
                    //   total: this.state.totalPages,
                    //   defaultPageSize: 20,
                    // }}
                  />
                </div>

                {/* ======== Add agent desing ====== */}

                {/* <Modal
                visible={this.state.visible}
                width="500"
                height="550"
                effect="fadeInUp"
                onClickAway={() => this.closeModal()}
              >
                <div style={{ padding: 40 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="sus-modal-label">
                      <span className="sus-modal-label-text">ADD AGENT</span>
                    </div>
                    <div onClick={this.closeModal}>
                      <img src={Images.crossRed} style={{ width: 20 }} />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="ca-gray-cont"
                      style={{
                        display: "flex",
                        width: "100%",
                        border: 0,
                        borderRadius: 5,
                        paddingRight: 20
                      }}
                    >
                      <div style={{ marginTop: -20 }}>
                        <div className="ag-user-icon-cont">
                          <img
                            src={Images.userCircle}
                            style={{ width: 25, height: 25 }}
                          />
                        </div>
                      </div>

                      <div
                        style={{ width: `calc(100% - 100px)`, marginLeft: 20 }}
                      >
                        <div class="profile-input-border">
                          <input
                            className="profile-input"
                            placeholder="Full Name"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Email"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Password"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Country"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="City"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="0"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Address"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>

                        <div
                          class="profile-input-border"
                          style={{ marginTop: 10 }}
                        >
                          <input
                            className="profile-input"
                            placeholder="Description"
                            type="text"
                            onChange={this.myChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 30
                    }}
                  >
                    <div></div>
                    <div style={{ display: "flex" }}>
                      <div className="sus-modal-button">
                        <span className="sus-modal-button-text">ADD NEW</span>
                      </div>
                      <div
                        onClick={this.closeModal}
                        className="sus-modal-button"
                        style={{ marginLeft: 10 }}
                      >
                        <span className="sus-modal-button-text">CLOSE</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal> */}

                {/* ======== Show link ======== */}

                {/* <Modal
                visible={this.state.linkVisible}
                width="500"
                height="200"
                effect="fadeInUp"
                onClickAway={() => this.closeLinkModal()}
              >
                <div style={{ padding: 20 }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="sus-modal-label">
                      <span className="sus-modal-label-text">LOGIN LINK</span>
                    </div>
                    <div onClick={this.closeModal}>
                      <img src={Images.crossRed} style={{ width: 20 }} />
                    </div>
                  </div>
                  <div
                    className="ca-gray-cont"
                    style={{ border: 0, padding: 10, margin: 0 }}
                  >
                    <p
                      class="medical-label"
                      style={{ fontSize: 11, marginLeft: 15 }}
                    >
                      http://agent.ezymigrate.co.nz/Home/Agent?u=ZXp5bWlncmFOZT-BAZ21haWwuY29t&p=VGVZzdEAOMTE=
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 15
                    }}
                  >
                    <div></div>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={this.closeLinkModal}
                        className="sus-modal-button"
                        style={{ marginLeft: 10 }}
                      >
                        <span className="sus-modal-button-text">CLOSE</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
             */}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Agents;
