import React, { Fragment, useEffect, useState } from "react";
import { yyyymmddFormate } from "../../Common/reUseFunction";
import { apiRefresh } from "../../services/api";
import * as types from "../../store/Constants";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  DatePicker,
  Form,
  Button,
  Spin,
  Select,
  Card,
  Tabs,
  message,
  Modal,
  Table,
  Tooltip,
} from "antd";
import moment from "moment-timezone";

const Checklist = ({
  showModal,
  closeSignaturePanel,
  onGetUpdateBol,
  onGetPotentialClient,
}) => {
  const history = useHistory();
  const [signatures, setSignatures] = useState([]);
  var clientColumns = [
    {
      title: "Check List Name",
      dataIndex: "checkListName",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (text, data) => {
        return <a onClick={() => openClientFile(data)}>{text}</a>;
      },
    },
    {
      title: "Client Type",
      dataIndex: "type",
    },
    {
      title: "Submitted Date",
      dataIndex: "modifiedDate",
      render: (date) => {
        let timezone = moment.tz.guess();
        return moment(date)
          .tz(timezone)
          .format("DD/MM/YYYY");
      },
    },
  ];
  useEffect(() => {
    let isSupplier;
    if (
      JSON.parse(
        decodeURIComponent(
          escape(window.atob(localStorage.getItem("userSystemPermissions")))
        )
      ).find(
        (x) =>
          x.role.toLowerCase() == "employer management" ||
          x.role.toLowerCase() == "supplier management"
      ).status === 1
    ) {
      isSupplier = true;
    } else {
      isSupplier = false;
    }
    const docTypeOpt = {
      url: `v1/dashboardbi/GetChecklist/${isSupplier}`,
    };

    docTypeOpt.types = [
      types.GET_DOCUMENT_TYPE_SUCCESS,
      types.GET_DOCUMENT_TYPE_FAILURE,
    ];
    apiRefresh
      .get(docTypeOpt)
      .then((res) => {
        setSignatures(res);
      })
      .catch((err) => {});
  }, []);

  const openClientFile = (data) => {
    localStorage.setItem("clientprofileid", JSON.stringify(data.id));
    localStorage.setItem("familyId", data.familyId);
    let g = data.type.toLowerCase();

    if (data.type.toLowerCase() === "client") {
      if (data.memberType.toLowerCase() === "client") {
        localStorage.setItem("clientProfileIdMain", data.clientId);
        localStorage.setItem("familyId", data.familyId);
        history.push({
          pathname: "/profile",
          state: { id: data.clientId },
        });
      } else if (data.memberType.toLowerCase() === "spouse") {
        history.push({
          pathname: "/partner-profile",
          state: { id: data.clientId, familyId: data.familyId },
        });
      } else if (data.memberType.toLowerCase() === "child") {
        history.push({
          pathname: "/client-family-member",
          state: { id: data.clientId, familyId: data.familyId },
        });
      }
    } else if (data.type.toLowerCase() === "employer") {
      history.push("/employer-management");
    } else if (data.type.toLowerCase() === "potential client") {
      onGetPotentialClient(data.id).then(() => {
        onGetUpdateBol(false);
        history.push("/update-potential-client");
      });
    } else {
      //do nothing
    }
  };
  return (
    <Fragment>
      <div className="task-to-do">
        <div class="heading-style" style={{ marginBottom: 15 }}>
          <span style={{ marginLeft: 0 }}>Document Check List</span>
          <a
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              float: "right",
              marginRight: "-17px",
              marginTop: "-9px",
            }}
          >
            <CloseCircleOutlined
              onClick={() => closeSignaturePanel()}
              style={{ fontSize: "20px" }}
            />
          </a>
        </div>
        <div
          className="reminder-border "
          style={{
            display: "flex",
          }}
        >
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            columns={clientColumns}
            dataSource={signatures}
            pagination={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Checklist;
