import * as types from "../Constants";

export const clientActivityReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CLIENT_ACTIVITY_SUCCESS:
      return { ...state, clientActivityData: action.payload };
    case types.CLIENT_ACTIVITY_FAILURE:
      return { ...state, clientActivityError: action.payload };

    case types.ADD_CLIENT_ACTIVITY_SUCCESS:
      return { ...state, addClientActivitySuccess: action.payload };
    case types.ADD_CLIENT_ACTIVITY_FAILURE:
      return { ...state, addClientActivityError: action.payload };

    default:
      return state;
  }
};
