import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Admission from "../Admission/Admission";

import {
  getVisaStatus,
  updateCaseStatus,
  getVisaApplication,
  getSchoolType,
  getSchools,
  getSchoolLevel,
  addClientAdmission,
  getAdmissionProgram,
  removeAdmissionProgram,
  getAdmissionStatuses,
  addAdmissionForm,
  updAdmissionStatus,
  addCommissionReminder,
  getCommissionRemindersListing,
  setActiveKey,
  getReminderTasks,
  updetaTasks,
  getUsers,
  getClientTasks,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  getVisaTypeByCountry,
  getVisaType,
  removeCommissionReminder,
  setProfileData,
  getPartnerData,
  getClientProfile,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    schoolTypesData: state.clientAdmissionReducer.schoolTypesData,
    schoolsData: state.clientAdmissionReducer.schoolsData,
    schoolLevelData: state.clientAdmissionReducer.schoolLevelData,
    admissionProgramData: state.clientAdmissionReducer.admissionProgramData,
    admissionStatusData: state.clientAdmissionReducer.admissionStatusData,
    commissionRemindersListing:
      state &&
      state.commissionRemindersReducer &&
      state.commissionRemindersReducer.commissionRemindersListing,
    activeKey: state.employerManagReducer.activeKey,
    clientTab: state.employerManagReducer.clientTab,
    remindersRes: state.reminderReducer.reminderTaskRes,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateCaseStatus, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onGetSchoolType: bindActionCreators(getSchoolType, dispatch),
    onGetSchools: bindActionCreators(getSchools, dispatch),
    onGetSchoolLevel: bindActionCreators(getSchoolLevel, dispatch),
    onAddClientAdmission: bindActionCreators(addClientAdmission, dispatch),
    onGetAdmissionProgram: bindActionCreators(getAdmissionProgram, dispatch),
    onGetAdmissionStatuses: bindActionCreators(getAdmissionStatuses, dispatch),
    onRemoveAdmissionProgram: bindActionCreators(
      removeAdmissionProgram,
      dispatch
    ),
    onUpdAdmissionForm: bindActionCreators(addAdmissionForm, dispatch),
    onUpdAdmissionStatus: bindActionCreators(updAdmissionStatus, dispatch),
    onAddCommissionReminder: bindActionCreators(
      addCommissionReminder,
      dispatch
    ),
    getCommissionRemindersListing: bindActionCreators(
      getCommissionRemindersListing,
      dispatch
    ),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onGetReminder: bindActionCreators(getReminderTasks, dispatch),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onRemoveCommissionReminder: bindActionCreators(
      removeCommissionReminder,
      dispatch
    ),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admission);
