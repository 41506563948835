import React, { Fragment } from "react";
import HeaderContact from "./ContactUs";

const Header = (branchData, stateData) => {
  return (
    <Fragment>
      <HeaderContact branchData={branchData} stateData={stateData} />
    </Fragment>
  );
};

export default Header;
