// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import { message, Spin, Button, Upload, Select } from "antd";

function OwnerCard(Props) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(Props.owner.fullName);
  const [email, setEmail] = useState(Props.owner.alternateEmail);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isManager, setIsManager] = useState(Props.owner.isManager);
  const [isOwner, setIsOwner] = useState(Props.owner.isOwner);
  const [countriesArray, setCountriesArray] = useState([]);
  const [telephoneNumber, setTelephone] = useState(
    Props.owner.userContact ? Props.owner.userContact.telephoneNumber : ""
  );
  const [mobileNumber, setMobileNum] = useState(
    Props.owner.userContact ? Props.owner.userContact.mobileNumber : ""
  );
  const [companyDesignation, setCompanyDesignation] = useState(
    Props.owner.title
  );
  const [city, setCity] = useState(
    Props.owner.userAddress ? Props.owner.userAddress.city : ""
  );
  const [country, setCountry] = useState(
    Props.owner.userAddress ? Props.owner.userAddress.country : ""
  );
  const [imageUrl, setImageUrl] = useState(
    Props.imageUrl ? Props.imageUrl : Props.owner.image
  );
  const [isLock, setIsLock] = useState(Props.owner.isLock);
  const [selectedOption, setSelectedOption] = useState(
    Props.owner.roleId == 3
      ? "User"
      : Props.owner.roleId === 2
      ? "Manager"
      : Props.owner.roleId === 1
      ? "Super User"
      : null
  );

  useEffect(() => {
    Props.onGetCountries();
  }, []);

  useEffect(() => {
    var country = [];
    if (Props.countriesData && Props.countriesData.items.length > 0) {
      for (let i = 0; i < Props.countriesData.items.length; i++) {
        country.push(
          <Option value={Props.countriesData.items[i].name}>
            {Props.countriesData.items[i].name}
          </Option>
        );
      }
      setCountriesArray(country);
    }
  }, [Props.countriesData]);

  var userId = localStorage.getItem("userId");

  const { Option } = Select;

  const handleChange = (selectedOption) => {
    setSelectedOption(parseInt(selectedOption));
  };

  var postUserAddress = false;
  var postUserContact = false;

  if (Props.owner.userAddress) {
    postUserAddress = false;
  } else {
    postUserAddress = true;
  }

  if (Props.owner.userContact) {
    postUserContact = false;
  } else {
    postUserContact = true;
  }

  let userData = {
    Id: Props.owner.id,
    fullName: name,
    alternateEmail: email,
    companyDesignation: "",
    isManager: isManager,
    isOwner: isOwner,
    RoleId:
      typeof selectedOption === "string" || selectedOption === null
        ? Props.owner.roleId
        : selectedOption,
    Title: companyDesignation,
    Image: Props.imageUrl ? Props.imageUrl : imageUrl,
    ForgotPasswordBit: false,
    LastSignedIn: new Date(),
    Signature: "",
    TimeZone: "",
    comission: 0,
    agreementUrl: "",
    description: "",
    SignatureImage: "",
  };

  let userContact = {
    fax1: "",
    fax2: "",
    mobileNumber: mobileNumber,
    mobileNumber2: "",
    telephoneNumber: telephoneNumber,
    telephoneNumber2: "",
    userId: Props.owner.id,
  };

  let userAddress = {
    country: country,
    city: city,
    address3: "",
    address2: "",
    address1: "",
    userId: Props.owner.id,
  };

  let passwordData = {
    userId: Props.owner.id,
    email: Props.owner.alternateEmail,
    password: newPassword,
    confirmPassword: confirmPassword,
  };

  let disableData = {
    userId: Props.owner.id,
    isLock: !isLock,
  };

  let userOwner = localStorage.getItem("userOwner");

  return (
    <div>
      {show && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: -75,
            marginLeft: 45,
            marginTop: 20,
            width: "85%",
          }}
        >
          <div></div>
          <div className="sus-show-btn" onClick={() => setShow(false)}>
            <span className="sus-save-btn-text">HIDE</span>
          </div>
        </div>
      )}
      <div
        className="sus-member-card-cont"
        style={{
          height: "auto",
          marginTop: show ? 0 : 20,
          backgroundColor: "#F2F2F2",
          borderRadius: 0,
          marginLeft: 45,
        }}
      >
        <div
          className="form-cont"
          style={{
            justifyContent: "space-between",
            paddingTop: 20,
            paddingTop: 5,
            paddingBottom: 0,
            display: "block",
            width: `calc(100% + 15px)`,
            marginLeft: -15,
            marginRight: -30,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div className="sus-profile-cont">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={(info) => Props.uploadImage(info, Props.manager.id)}
                  onChange={Props.handleChangeImage}
                >
                  {Props.imageUrl ? (
                    <img
                      src={Props.imageUrl}
                      alt="avatar"
                      style={{ width: 105, height: 105 }}
                    />
                  ) : imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: 105, height: 105 }}
                    />
                  ) : (
                    <img
                      src={Images.dummyUserImage}
                      className="sus-profile-img"
                    />
                  )}
                </Upload>
              </div>
            </div>
            <div style={{ width: "56%" }}>
              <div className="profile-input-border">
                <input
                  className="profile-input"
                  placeholder="advisor"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="profile-input-border" style={{ marginTop: 10 }}>
                <input
                  className="profile-input"
                  placeholder="testadvisor@ezymigrate.com"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                className="select-options"
                style={{ marginTop: 10, backgroundColor: "#FFFFFF" }}
              >
                <Select
                  defaultValue={selectedOption}
                  bordered={false}
                  placeholder="Select Role"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <Option key={1}>Super User</Option>
                  <Option key={2}>Manager</Option>
                  <Option key={3}>User</Option>
                </Select>
              </div>

              {userOwner === "True" && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    marginRight: -50,
                  }}
                >
                  {/* <div className="sus-checkbox-cont">
                    <input
                      type="checkbox"
                      className="sus-checkbox"
                      onChange={() => setIsManager(!isManager)}
                      defaultChecked={isManager}
                    />
                    <span className="sus-disable-text">Branch Manager</span>
                  </div> */}

                  <div className="sus-checkbox-cont">
                    <input
                      disabled={userOwner == "True" ? false : true}
                      type="checkbox"
                      className="sus-checkbox"
                      onChange={() => setIsOwner(!isOwner)}
                      defaultChecked={isOwner}
                    />
                    <span className="sus-disable-text">Owner</span>
                  </div>
                </div>
              )}

              {Props.owner.id != userId && (
                <div
                  className="sus-disable-cont"
                  style={{ cursor: "pointer" }}
                  onClick={() => Props.onDisable(disableData)}
                >
                  <span className="sus-disable-text">
                    {Props.owner.isLock
                      ? "Enable Team Member"
                      : "Disable Team Member"}
                  </span>
                  <img src={Images.disable} className="sus-disable-icon" />
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="profile-input-border" style={{ marginTop: 10 }}>
              <input
                className="profile-input"
                placeholder="Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="profile-input-border" style={{ marginTop: 10 }}>
              <input
                className="profile-input"
                placeholder="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              <Button
                onClick={() => Props.passwordUpdateMethod(passwordData)}
                type="primary"
                className="sus-save-btn"
              >
                CHANGE PASSWORD
              </Button>
            </div>
          </div>

          {show && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="medical-label" style={{ fontSize: 11 }}>
                  Telephone No:
                </p>
                <div className="profile-input-border" style={{ width: "55%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={telephoneNumber}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p className="medical-label" style={{ fontSize: 11 }}>
                  Mobile No:
                </p>
                <div className="profile-input-border" style={{ width: "55%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNum(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p className="medical-label" style={{ fontSize: 11 }}>
                  Company Designation:
                </p>
                <div className="profile-input-border" style={{ width: "55%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={companyDesignation}
                    onChange={(e) => setCompanyDesignation(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p className="medical-label" style={{ fontSize: 11 }}>
                  Country:
                </p>
                <div
                  className="select-border multi-select-option"
                  style={{ marginTop: 0, width: "55%" }}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    bordered={false}
                    placeholder="Country"
                    value={country}
                    onChange={(val) => setCountry(val)}
                    style={{ width: "100%" }}
                  >
                    {countriesArray}
                  </Select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p className="medical-label" style={{ fontSize: 11 }}>
                  City:
                </p>
                <div className="profile-input-border" style={{ width: "55%" }}>
                  <input
                    className="profile-input"
                    placeholder=""
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Button
                  onClick={() => {
                    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    if (name.length < 3) {
                      message.warning(
                        "Name should be more than three characters!"
                      );
                      return;
                    }
                    if (!emailRegex.test(email)) {
                      message.warning("Please enter valid email address");
                      return;
                    }
                    Props.parentMethod(
                      userData,
                      userAddress,
                      userContact,
                      postUserAddress,
                      postUserContact,
                      Props.owner
                    );
                  }}
                  type="primary"
                  className="sus-add-btn"
                  style={{ marginTop: 15 }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          )}

          {!show && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div
                className="sus-show-btn"
                onClick={() => setShow({ show: true })}
              >
                <span className="sus-save-btn-text">SHOW</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OwnerCard;
