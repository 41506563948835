import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";

let userId = localStorage.getItem("userId");

const ContactEmployerForm = ({
  handleCancel,

  // setLoading,

  onAddEmployerContact,

  ongetEmployerContact,
  EmployerContactRes,

  userDataEmp,

  createMode,

  onUpdateEmployerContact,

  contactRow,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: contactRow && contactRow.name,
      number: contactRow && contactRow.number,
      email: contactRow && contactRow.email,
    });
  }, [contactRow, form]);

  const onFinish = (values) => {
    // setLoading(true);
    console.log("Received values of form:", values);
    if (createMode === "add-contact") {
      const data = {
        name: values && values.name ? values.name : "",
        number: values && values.number ? values.number : "",
        email: values && values.email ? values.email : "",
        employerId: userDataEmp && userDataEmp.id,
      };

      onAddEmployerContact(data)
        .then(() => handleCancel())
        .then(() => ongetEmployerContact(userDataEmp && userDataEmp.id))
        .then(() => {
          // setLoading(false);
          message.success("Successfully Added!");
        });
    } else {
      const update = {
        id: contactRow && contactRow.id,
        name: values.name,
        number: values.number,
        email: values.email,
        employerId: userDataEmp && userDataEmp.id,
      };

      onUpdateEmployerContact(update)
        .then(() => handleCancel())
        .then(() => ongetEmployerContact(userDataEmp && userDataEmp.id))
        .then(() => {
          // setLoading(false);
          message.success("Successfully Added!");
        });
    }
  };

  return (
    <Fragment>
      <Form onFinish={onFinish} form={form} className="width-100" name="main">
        <Row gutter={8}>
          <Col span={12}>
            <p style={{ fontSize: "13px" }}>
              Name<span className="required-field">*</span>
            </p>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
              className=""
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12} className="required">
            <p style={{ fontSize: "12px" }}>Email</p>
          </Col>
          <Col span={12}>
            <Form.Item
              className="margin-top alert-msg "
              name="email"
              rules={[{ type: "email" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <p style={{ fontSize: "12px" }}>Number</p>
          </Col>
          <Col span={12}>
            <Form.Item className="margin-top" name="number" required={false}>
              <Input placeholder="Number" />
            </Form.Item>
          </Col>
        </Row>

        <div className="margin-top d-flex flex-end">
          <Row gutter={8}>
            <Col>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="form-btn button-blue"
                  type="primary"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  onClick={() => handleCancel()}
                  className="form-btn button-blue"
                  type="primary"
                >
                  Close
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};
export default ContactEmployerForm;
