import { api } from "../../services/api";
import * as types from "../Constants";

export const getManager = () => {
  const options = {
    url: "v1/users/Managers",
  };

  options.types = [types.MANAGER_SUCCESS, types.MANAGER_FAILURE];

  return api.get(options);
};

export const updateManager = (data) => {
  const options = {
    url: "v1/users",
  };

  options.types = [types.UPDATE_MANAGER_SUCCESS, types.UPDATE_MANAGER_FAILURE];

  return api.put(options, data);
};
