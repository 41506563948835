import React, { Fragment } from "react";
import "./AccountStyles.css";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  message,
  Row,
  Col,
  Modal,
} from "antd";
import { Images } from "../Themes";
import { bindActionCreators } from "redux";
import { getAllBanksListing, deleteBank, setBankInfo } from "../store/Actions";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AddBank from "./AddBank";
import AddOutgoingPaymentForm from "../Clients/AddOutgoingPaymentForm";
import moment from "moment";
const { Option } = Select;

class Banks extends React.Component {
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      banks: [],
      branchId: selectedBranchId,
      visibleAddBankModal: false,
      visibleDeleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllBanksListing();
  }

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this bank?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={16}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.props.deleteBank(this.state.index).then(() => {
                    message.success("Bank is deleted successfully!");
                    this.props.getAllBanksListing();
                    this.setState({ visibleDeleteModal: false });
                  });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    let _bankListing = nextProps && nextProps.bankListing;
    this.setState({
      banks: _bankListing,
    });
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        // this.props.getAllBanksListing().then(() => {});
      });
    }
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      ellipsis: true,
    },
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <a
            href="javascript:"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.props.setBankInfo(record);
              this.setState({ visibleAddBankModal: true });
            }}
          >
            <EditOutlined />
          </a>
          <a
            href="javascript:"
            onClick={(e) => {
              e.stopPropagation();
              this.showModalDelete(record.id);
            }}
          >
            <DeleteOutlined />
          </a>
        </span>
      ),
    },
  ];

  handleCancelAddBankModal = (e) => {
    this.setState({
      visibleAddBankModal: false,
    });
  };

  getBanksSection = () => {
    return (
      <Row xs={24}>
        <Col xs={21} offset={1} style={{ marginTop: "13px" }}>
          <Table
            dataSource={this.state.banks ? this.state.banks : null}
            columns={this.columns}
            pagination={{
              defaultPageSize: 20,
            }}
          ></Table>
        </Col>
        <Col xs={1} offset={1} style={{ marginTop: "-14px" }}>
          <img
            src={Images.addIcon}
            className="icons-client"
            type="primary"
            onClick={() => {
              this.props.setBankInfo(null);
              this.setState({ visibleAddBankModal: true });
            }}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={24}>
            <h4 style={{ marginLeft: "28px" }}>{"Bank Accounts"}</h4>
          </Col>
        </Row>
        <div className="ca-gray-cont">
          {this.getBanksSection()}
          {this.state.visibleAddBankModal ? (
            <AddBank
              handleCancelAddBankModal={this.handleCancelAddBankModal}
              visibleAddBankModal={this.state.visibleAddBankModal}
            />
          ) : null}
          {this.getModalDelete()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  deleteBank: bindActionCreators(deleteBank, dispatch),
  setBankInfo: bindActionCreators(setBankInfo, dispatch),
});

Banks = connect(mapStateToProps, mapDispatchToProps)(Banks);

export default Banks;
