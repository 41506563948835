import React, { useState, Fragment, useEffect } from "react";
import { Images } from "../../../Themes";
import {
  Radio,
  Form,
  Input,
  Button,
  Spin,
  Checkbox,
  message,
  Tooltip,
  Row,
  Col,
} from "antd";
import { LeftCircleFilled, RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import GmailIntegration from "./GmailIntegration";
import AllMails from "../../../Containers/AllMails";

var userId = localStorage.getItem("userId");

const ImapSettings = ({
  onUpdateImapSettings,
  updateImapSettingRes,
  onGetImapSettingss,
  imapSettingRes,
  onSetActiveInnerTab,
  getGmailMail,
  gmailLogin,
  addGmailAccount,
  removeGmailAccount,
}) => {
  const [imapDefault, setImapDefault] = useState();
  const [updateRes, setUpdateRes] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imapForAll, setImportForAll] = useState(false);
  const state = useLocation().state;

  const [form] = Form.useForm();

  useEffect(() => {
    var userId = localStorage.getItem("userId");
    setLoading(true);

    onGetImapSettingss()
      .then((res) => {
        setUpdateRes(res && res.payload);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetImapSettingss]);

  function onChange(e) {
    console.log(`radio checked:${e.target.value}`);
    setImapDefault(e.target.value);
  }

  useEffect(() => {
    const hostNameD = updateRes && updateRes && updateRes.hostName;

    form.setFieldsValue({
      host_name: hostNameD,
      port: updateRes && updateRes.port,
      email: updateRes && updateRes.exportEmail,
      password: updateRes && updateRes.exportEmailPassword,
      host_outgoing: updateRes && updateRes.hostNameOut,
      port_outgoing: updateRes && updateRes.portOut,
      username: updateRes && updateRes.exportUserName,
      imapForAll: updateRes && updateRes.imapForAll,
      useImap: updateRes && updateRes.useImap,
    });
  }, [form, updateRes]);

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    var userId = localStorage.getItem("userId");
    const data = {
      hostName: values && values.host_name,
      port: values && values.port ? values.port.toString() : "",
      exportEmail: values && values.email,
      exportEmailPassword: values && values.password,
      hostNameOut: values && values.host_outgoing,
      portOut:
        values && values.port_outgoing ? values.port_outgoing.toString() : "",
      useImap: values.useImap || false,
      exportUserName: values && values.username,
    };
    setButtonLoading(true);

    onUpdateImapSettings(data)
      .then((res) => {
        message.success("Updated!");
        setButtonLoading(false);
      })
      .catch((err) => {
        message.error(
          "We are unable to connect using the above information. Please provide the correct information or contact support for help"
        );
        setButtonLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%", height: "100%" }}>
              <div
                className="d-flex align-item Reminder-top"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex align-item">
                  <div className="client-tag-top">
                    <img src={Images.imap} className="sus-bottom-icon" />
                  </div>
                  <span className="top-text">IMAP</span>
                  <Tooltip
                    placement="topLeft"
                    title={`Setting up Imap allows you to manage your emails all through Ezymigrate.`}
                  >
                    <img className="ac-info-icon" src={Images.info} />
                  </Tooltip>
                </div>

                <LeftCircleFilled
                  onClick={() => onSetActiveInnerTab("")}
                  className="ac-back-icon"
                />
              </div>
              <div className="mail-chimp-box">
                {/* <Row>
                  <Col xs={24}>
                    <GmailIntegration
                      getGmailMail={getGmailMail}
                      gmailLogin={gmailLogin}
                      addGmailAccount={addGmailAccount}
                      removeGmailAccount={removeGmailAccount}
                    />
                  </Col>
                </Row> */}
                <div className="">
                  <div className="client-tag-table">
                    <Form onFinish={onFinish} form={form}>
                      <div
                        className="reminder-set-form margin-top-34 d-block"
                        style={{
                          backgroundColor: "#FFFFFF",
                          padding: 30,
                          borderRadius: 5,
                        }}
                      >
                        <div className="width-100 d-flex align-center letter-font">
                          <div className="width-50 ">
                            <p className="input-name">Host Name</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="host_name"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  pattern: new RegExp(/^\S*$/),
                                  message: "Required or format is invalid!",
                                },
                              ]}
                            >
                              <Input placeholder="Host Name" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font">
                          <div className="width-50 ">
                            <p className="input-name">Port</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="port"
                              placeholder="(Usually 993)"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  message: "Required and allow only numbers",
                                  pattern: new RegExp(/^[0-9]+$/),
                                },
                              ]}
                            >
                              <Input placeholder="(Usually 993)" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font">
                          <div className="width-50 ">
                            <p className="input-name">Host Name Outgoing</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="host_outgoing"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  pattern: new RegExp(/^\S*$/),
                                  message: "Required or format is invalid!",
                                },
                              ]}
                            >
                              <Input placeholder="Host Name Outgoing" />
                            </Form.Item>
                          </div>
                        </div>{" "}
                        <div className="width-100 d-flex align-center letter-font">
                          <div className="width-50 ">
                            <p className="input-name">Port Outgoing</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="port_outgoing"
                              placeholder="(Usually 587)"
                              rules={[
                                {
                                  required: true,
                                },
                                {
                                  message: "Required and allow only numbers",
                                  pattern: new RegExp(/^[0-9]+$/),
                                },
                              ]}
                            >
                              <Input placeholder="(Usually 587)" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font">
                          <div className="width-50">
                            <p className="input-name">Username</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]}
                            >
                              <Input placeholder="Username" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font">
                          <div
                            style={{ marginTop: "14px" }}
                            className="width-50 "
                          >
                            <p className="input-name">Email</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                  message: "Required!",
                                },
                              ]}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font">
                          <div
                            style={{ marginTop: "1px" }}
                            className="width-50 "
                          >
                            <p className="input-name">Password</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]}
                            >
                              <Input.Password autoComplete="off" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="width-100 d-flex align-center letter-font letter-spacing">
                          <div className="width-50 ">
                            <p className="input-name">Use IMAP As Default</p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="useImap"
                              rules={[
                                {
                                  required: true,
                                  message: "Required!",
                                },
                              ]}
                            >
                              <Radio.Group onChange={onChange}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="mail-chimp-btn reminder-set-form margin-top-34">
                          <Form.Item className="d-block">
                            <Button
                              loading={buttonLoading}
                              htmlType="submit"
                              type="primary"
                              className="button-blue"
                            >
                              SAVE
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                      <Row>
                        <AllMails />
                      </Row>
                      <div className="imap-text">
                        <div
                          style={{ display: "flex", width: "100%" }}
                          className="margin-top-34"
                        >
                          <div style={{ width: "50%" }}>
                            <p style={{ color: "#556370" }}>
                              Some Common IMAP Host Names Are
                            </p>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>imap-mail.outlook.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>outlook.office365.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>imap.mail.yahoo.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>imap.gmail.com</p>
                            </div>
                          </div>
                          <div style={{ width: "50%" }}>
                            <div className="d-block">
                              <div>
                                <p>Some Common SMTP Host Names Are</p>
                              </div>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>smtp-mail.outlook.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>smtp.office365.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>smtp.mail.yahoo.com</p>
                            </div>
                            <div className="mail-text d-flex align-center">
                              <RightCircleTwoTone />
                              <p>smtp.gmail.com</p>
                            </div>
                          </div>
                        </div>
                        {/* <div style={{ width: "48%" }} className="margin-top-34">
                          <p>
                            If You Are Not Using One Of Them Please Check Your
                            Email Server Settings Or Contact EZYMigrate Support
                            For Help Port Usually IMAP Port Is 993. Please Check
                            Email Provider Settings. Host Name Outgoing Some
                            Common SMTP Host Names Are
                          </p>
                        </div>
                        <div className="reminder-set-form  d-block">
                          <div className="width-50 ">
                            <p>
                              Usually IMAP Port Is 993. Please Check Email
                              Provider Settings.
                            </p>
                          </div>
                        </div> */}

                        <div className="margin-top-34 ">
                          <p style={{ textAlign: "center", color: "#9FA0A0" }}>
                            Please check with your email provider to obtain your
                            Imap details.
                            <br />
                            Need help? - Please see this helpfull article, or
                            contact Ezymigrate
                            <br />
                            Support for further assistance.
                          </p>
                        </div>
                        <div className="reminder-set-form margin-top-34 d-block"></div>

                        {/* <div className="margin-top-34">
                          <p style={{ color: "#556370" }}>
                            Usually SMTP Port Is 587. Please Check Email
                            Provider Settings.
                          </p>
                        </div> */}
                      </div>
                      <div className="reminder-set-form margin-top-34 d-block"></div>
                      <div className="reminder-set-form  d-block"></div>
                      <div className="reminder-set-form  d-block"></div>
                      <div className="reminder-set-form  d-block"></div>
                      {/* <div className="reminder-set-form  d-block">
                        <div className="width-100 d-flex align-center letter-font letter-spacing">
                          <div className="width-50 ">
                            <p className="input-name"></p>
                          </div>
                          <div className="width-50">
                            <Form.Item
                              name="imapForAll"
                              valuePropName="checked"
                            >
                              <Checkbox>Imap For All</Checkbox>
                            </Form.Item>
                          </div>
                        </div>
                      </div> */}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <GmailIntegration
          onUpdateImapSettings={onUpdateImapSettings}
          updateImapSettingRes={onUpdateImapSettings}
          onGetImapSettingss={onUpdateImapSettings}
          imapSettingRes={onUpdateImapSettings}
          onSetActiveInnerTab={onUpdateImapSettings}
          getGmailMail={onUpdateImapSettings}
          gmailLogin={onUpdateImapSettings}
          addGmailAccount={onUpdateImapSettings}
          removeGmailAccount={onUpdateImapSettings}
        /> */}
      </Spin>
    </Fragment>
  );
};
export default ImapSettings;
