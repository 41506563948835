import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ImapSettings from "./../AccountSetting/UserLevelSettings/ImapSettings/ImapSettings";

import {
  updateImapSettings,
  getImapSettings,
  setActiveInnerTab, getGmailMail, gmailLogin, addGmailAccount, removeGmailAccount
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    updateImapSettingRes: state.imapSettingReducer.updateImapSettingRes,
    imapSettingRes: state.imapSettingReducer.imapSettingRes,
    activeInnerTabValue: state.accountSetReducer.activeInnerTabValue,
    gmailRes: state.emailIntegrationReducer.gmailRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateImapSettings: bindActionCreators(updateImapSettings, dispatch),
    onGetImapSettingss: bindActionCreators(getImapSettings, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    getGmailMail: bindActionCreators(getGmailMail, dispatch),
    gmailLogin: bindActionCreators(gmailLogin, dispatch),
    addGmailAccount: bindActionCreators(addGmailAccount, dispatch),
    removeGmailAccount: bindActionCreators(removeGmailAccount, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImapSettings);
