import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Agents from "../Agent/Agent";

import { getAgents, searchConnection } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    agentsRes: state.agentsReducer.agentsRes,
    searchConnectionData: state.clientProfileReducer.searchConnectionData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAgents: bindActionCreators(getAgents, dispatch),
    onSearchConnection: bindActionCreators(searchConnection, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
