// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
  InputNumber,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import activityData from "../ClientActivity/ActivityData";

function UpdateJobHistoryForm(Props) {
  const [loading, setLoading] = useState(false);
  const [disableDropdown, setDisableDropdown] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();

  const dateFormat = "DD/MM/YYYY";

  var employerOptions = [];

  let clientprofileid = sessionStorage.getItem("clientprofileid");

  useEffect(() => {
    if (
      Props.jobHistory.employerId !== "00000000-0000-0000-0000-000000000000"
    ) {
      setDisableDropdown(true);
    }
    form.setFieldsValue({
      jobTitle: Props.jobHistory.jobTitle,
      employerName: Props.jobHistory.employerName,
      employerAddress: Props.jobHistory.employerAddress,
      businessNumber: Props.jobHistory.businessNumber,
      // startDate:moment(Props.jobHistory.startDate),
      // endDate: moment(Props.jobHistory.endDate),
      payDetails: Props.jobHistory.payDetails,
      phone: Props.jobHistory.phone,
      managerName: Props.jobHistory.managerName,
      managerEmail: Props.jobHistory.managerEmail,
      managerMobile: Props.jobHistory.managerMobile,
      positionOffered: Props.jobHistory.positionOffered,
      jobOffer: Props.jobHistory.jobOffer,
      companyRole: Props.jobHistory.companyRole,
      mentionHoursInWeek: Props.jobHistory.mentionHoursInWeek,
      anzscoCode: Props.jobHistory.anzscoCode,
      skillLevel: Props.jobHistory.skillLevel,
      workStay: Props.jobHistory.workStay,
      jobStatusId: Props.jobHistory.jobStatusId,
      employerId:
        Props.jobHistory.employerId === "00000000-0000-0000-0000-000000000000"
          ? ""
          : Props.jobHistory.employerId,
    });
    if (Props.jobHistory.startDate != "1900-01-01T00:00:00+00:00") {
      form.setFieldsValue({
        startDate: moment(Props.jobHistory.startDate),
      });
    }
    if (Props.jobHistory.endDate != "1900-01-01T00:00:00+00:00") {
      form.setFieldsValue({
        endDate: moment(Props.jobHistory.endDate),
      });
    }
  }, [
    Props.jobHistory.anzscoCode,
    Props.jobHistory.businessNumber,
    Props.jobHistory.companyRole,
    Props.jobHistory.employerAddress,
    Props.jobHistory.employerId,
    Props.jobHistory.employerName,
    Props.jobHistory.endDate,
    Props.jobHistory.jobOffer,
    Props.jobHistory.jobStatusId,
    Props.jobHistory.jobTitle,
    Props.jobHistory.managerEmail,
    Props.jobHistory.managerMobile,
    Props.jobHistory.managerName,
    Props.jobHistory.mentionHoursInWeek,
    Props.jobHistory.payDetails,
    Props.jobHistory.phone,
    Props.jobHistory.positionOffered,
    Props.jobHistory.skillLevel,
    Props.jobHistory.startDate,
    Props.jobHistory.workStay,
    form,
  ]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const addQualification = (values) => {
    Props.addQualification(values);
  };

  const removeEmployerJob = () => {
    let data = {
      id: Props.jobHistory.id,
      delete: true,
    };
    Props.onRemoveEmployerJobHistory(data)
      .then((res) => {
        message.success("Job history removed successfully!");
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            "employer " + Props.jobHistory.jobTitle + " removed by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
        Props.onGetClientJobHistory();
      })
      .catch((err) => {
        message.error("Failed to remove job history!");
      });
  };

  const onFinish = (values) => {
    const userId = localStorage.getItem("userId");
    setLoading(true);
    let data = {
      id: Props.jobHistory.id,
      clientId: Props.jobHistory.clientId,
      employerId: values.employerId || "00000000-0000-0000-0000-000000000000",
      jobId: Props.jobHistory.jobId,
      jobTitle: values.jobTitle || "",
      startDate: values.startDate || "1900-01-01T00:00:00+00:00",
      endDate: values.endDate || "1900-01-01T00:00:00+00:00",
      employerName: values.employerName || "",
      employerAddress: values.employerAddress || "",
      payDetails: values.payDetails || "",
      phone: values.phone ? values.phone.toString() : "",
      managerName: values.managerName || "",
      managerEmail: values.managerEmail || "",
      managerMobile: values.managerMobile
        ? values.managerMobile.toString()
        : "",
      positionOffered: values.positionOffered || "",
      jobOffer: values.jobOffer || "",
      companyRole: values.companyRole || "",
      mentionHoursInWeek: values.mentionHoursInWeek || "",
      anzscoCode: values.anzscoCode || "",
      skillLevel: values.skillLevel || "",
      businessNumber: values.businessNumber || "",
      isCurrent: false,
      tradingName: Props.jobHistory.tradingName,
      workStay: values.workStay || "",
      businessType: Props.jobHistory.businessType,
      employementType: Props.jobHistory.employementType,
      streetNumber: Props.jobHistory.streetNumber,
      streetName: Props.jobHistory.streetName,
      suburb: Props.jobHistory.suburb,
      city: Props.jobHistory.city,
      state: Props.jobHistory.state,
      zip: Props.jobHistory.zip,
      country: Props.jobHistory.country,
      website: Props.jobHistory.website,
      annualsalary: Props.jobHistory.annualsalary,
      ratePerHour: Props.jobHistory.ratePerHour,
      activityBasedRemuneration: Props.jobHistory.activityBasedRemuneration,
      workExperince: Props.jobHistory.workExperince,
      domicile: Props.jobHistory.domicile,
      accountDuties: Props.jobHistory.accountDuties,
      contactPersonJobTitle: Props.jobHistory.contactPersonJobTitle,
      arrivalDate: Props.jobHistory.arrivalDate || "1900-01-01T00:00:00+00:00",
      jobStatusId: Props.jobHistory.jobStatusId || 0,
    };
    Props.onUpdEmployerJobHistory(data)
      .then((res) => {
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(sessionStorage.getItem("profileData"));
        let myData = {
          clientName: profileData.fullName,
          logMessage: "Job " + values.jobTitle + " updated by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
        if (Props.jobHistory.employerId !== values.employerId) {
          const data = {
            employerId: values.employerId || Props.jobHistory.employerId,
            jobStatusId: 1,
            jobTitle: values.jobTitle || Props.jobHistory.jobTitle,
            liaId:
              Props.jobHistory.liaId || "00000000-0000-0000-0000-000000000000",
            salePersonId:
              Props.jobHistory.salesPersonId ||
              "00000000-0000-0000-0000-000000000000",
            jobOrderNo: values.job_no || "",
            position: values.position || "",
            openDate: values.startDate || "1900-01-01T00:00:00+00:00",
            closeDate: values.endDate || "1900-01-01T00:00:00+00:00",
            required: 0,
            contract: "",
            location: "",
            remuneration: 0,
            agency: "",
            conLocationtract: "",
            siteAddress: "",
            anzscoCode: values.anzscoCode || Props.jobHistory.anzscoCode,
            skillLevel: values.skillLevel || Props.jobHistory.skillLevel,
            experinceRequired: "",
            otherRequiremts: "",
            policy: "",
            visaLength: "",
            comments: "",
            laborMarketTestExpiry: "1900-01-01T00:00:00+00:00",
            laborMarketTestExpiry1: "1900-01-01T00:00:00+00:00",
            advertisingExpiry: "1900-01-01T00:00:00+00:00",
            skillMatesReportExpiry: "1900-01-01T00:00:00+00:00",
          };
          Props.onAddEmployerJob(data).then(() => {});
        }

        message.success("Job history updated successfully!");
        setLoading(false);
        Props.onGetClientJobHistory();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed to update job history!");
      });
  };
  return (
    <div
      class="emp-middle-cont"
      style={{ marginTop: 20, marginLeft: 0 }}
      key={Props.index}
    >
      <div
        class="form-cont"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div className="cj-status-cross-row">
          <p class="emp-ace-bold-text">
            {Props.jobHistory.jobTitle.toUpperCase()}
          </p>
          {Props.jobHistory.statusName && (
            <div style={{ marginLeft: 20 }}>
              <span className="cj-job-status-text">
                ({Props.jobHistory.statusName})
              </span>
            </div>
          )}
        </div>
        <div>
          <CloseCircleFilled
            style={{ fontSize: 17, cursor: "pointer" }}
            onClick={removeEmployerJob}
          />
        </div>
      </div>
      <div className="emp-ace-cont">
        <Form onFinish={onFinish} form={form}>
          <div className="emp-ace-cont">
            <div
              class="form-cont"
              style={{
                justifyContent: "space-between",
                paddingLeft: 0,
                paddingRight: 0,
                display: "block",
                width: "100%",
              }}
            >
              <div className="ce-field-row">
                <p className="medical-label" style={{ fontSize: 11 }}>
                  Employers
                </p>
                {disableDropdown ? (
                  <div style={{ paddingLeft: 1, width: "65%" }}>
                    <Form.Item name="employerId">
                      <Select disabled>
                        {Props.clientEmployerRes &&
                          Props.clientEmployerRes.map((item, index) => (
                            <Option value={item.id}>{item.name}</Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                ) : (
                  <div style={{ paddingLeft: 1, width: "65%" }}>
                    <Form.Item name="employerId">
                      <Select>
                        {Props.clientEmployerRes &&
                          Props.clientEmployerRes.map((item, index) => (
                            <Option value={item.id}>{item.name}</Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Job Title*
                </p>
                <div class="emp-input-border">
                  <Form.Item
                    name="jobTitle"
                    rules={[
                      {
                        required: true,
                        message: "Please enter job title!",
                      },
                    ]}
                  >
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Employer Name
                </p>
                <div class="emp-input-border">
                  <Form.Item name="employerName">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Employer Address
                </p>
                <div class="emp-input-border">
                  <Form.Item name="employerAddress">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Business Number
                </p>
                <div class="emp-input-border">
                  <Form.Item name="businessNumber">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Start Date
                </p>
                <div
                  style={{
                    width: "65%",
                    display: "flex",
                    border: "none",
                    overflow: "inherit",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      overflow: "inherit",
                      display: "flex",
                      height: 30,
                      backgroundColor: "#FFFFFF",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Form.Item name="startDate" style={{ width: "100%" }}>
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </div>
                  {/* <div className="profile-refresh-cont">
                    <img
                      src={Images.calendar}
                      className="profile-calendar-icon"
                      style={{ marginLeft: 0 }}
                    />
                  </div> */}
                  {/* <div class="profile-refresh-cont">
                    <img
                      src={Images.refreshArrow}
                      style={{ width: 18, height: 18 }}
                    />
                  </div> */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  End Date
                </p>
                <div
                  style={{
                    width: "65%",
                    display: "flex",
                    border: "none",
                    overflow: "inherit",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 0,
                      overflow: "inherit",
                      display: "flex",
                      height: 30,
                      backgroundColor: "#FFFFFF",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Form.Item name="endDate" style={{ width: "100%" }}>
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </div>
                  {/* <div className="profile-refresh-cont">
                    <img
                      src={Images.calendar}
                      className="profile-calendar-icon"
                      style={{ marginLeft: 0 }}
                    />
                  </div> */}
                  {/* <div class="profile-refresh-cont">
                    <img
                      src={Images.refreshArrow}
                      style={{ width: 18, height: 18 }}
                    />
                  </div> */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Pay Details
                </p>
                <div class="emp-input-border">
                  <Form.Item name="payDetails">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Phone
                </p>
                <div class="emp-input-border">
                  <Form.Item name="phone">
                    <InputNumber
                      className="profile-input input-number-field"
                      placeholder=""
                    />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Manager's Name
                </p>
                <div class="emp-input-border">
                  <Form.Item name="managerName">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Manager's Email
                </p>
                <div class="emp-input-border">
                  <Form.Item
                    name="managerEmail"
                    rules={[
                      {
                        type: "email",
                        message: "Please input email!",
                      },
                    ]}
                  >
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Manager's Mobile
                </p>
                <div class="emp-input-border">
                  <Form.Item name="managerMobile">
                    <InputNumber
                      className="profile-input input-number-field"
                      placeholder=""
                    />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Position Offered
                </p>
                <div class="emp-input-border">
                  <Form.Item name="positionOffered">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Job Offer
                </p>
                <div class="emp-input-border">
                  <Form.Item name="jobOffer">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              {/* <div className="ce-field-row">
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Job Status
                </p>
                <div style={{ paddingLeft: 1, width: "65%" }}>
                  <Form.Item name="jobStatusId">
                    <Select>{Props.jobStatuses}</Select>
                  </Form.Item>
                </div>
              </div> */}

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Role In Company
                </p>
                <div class="emp-input-border">
                  <Form.Item name="companyRole">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Working Hours In Week
                </p>
                <div style={{ display: "flex", width: "65%" }}>
                  <div style={{ width: "100%" }}>
                    <Form.Item name="mentionHoursInWeek">
                      <Input
                        style={{ width: "100%" }}
                        className="profile-input emp-input-border"
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  ANZSCO Code
                </p>
                <div class="emp-input-border">
                  <Form.Item name="anzscoCode">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Skill Level
                </p>
                <div class="emp-input-border">
                  <Form.Item name="skillLevel">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <p class="medical-label" style={{ fontSize: 11 }}>
                  Work Stay
                </p>
                <div class="emp-input-border">
                  <Form.Item name="workStay">
                    <Input className="profile-input" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div />
                <div
                  className="button-blue-cont"
                  style={{
                    marginTop: 34,
                    paddingRight: 0,
                    width: 70,
                  }}
                >
                  {/* <div className="emp-blue-btn">
                              <span style={{ color: "#FFFFFF" }}>ADD</span>
                            </div> */}
                  <Button
                    loading={loading}
                    htmlType="submit"
                    name="update"
                    className="emp-blue-btn"
                    style={{ marginRight: 10, color: "#FFFFFF" }}
                  >
                    UPDATE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div>{/* <div className="emp-line"></div> */}</div>
      </div>
    </div>
  );
}

export default UpdateJobHistoryForm;
