import React, { Fragment } from "react";
import Template from "../Templates/Template";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Spin,
  Modal,
  message,
} from "antd";
import {
  addNewTemplate,
  deleteTemplate,
  editTemplate,
  getAllTaxesListing,
  getTemplateListing,
} from "../../store/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { templatesReducer } from "../../store/Reducers/Template";
import ListingSingleModule from "./ListingSingleModule";
const { Option } = Select;

class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleAddTemplatePopUp: false,
      loading: false,
      templateListing: [],
      visibleDeleteModal: false,
      index: 0,
    };
  }

  setLoadingTrue = () => {
    this.setState({ loading: true });
  };

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .getTemplateListing()
      .then(() => {
        this.props
          .getAllTaxesListing()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props && this.props.key === "1") {
      this.setState({ loading: true });
    } else if (
      this.props &&
      this.props.key === undefined &&
      nextProps &&
      nextProps.templateListing &&
      nextProps.templateListing.length !== 0
    ) {
      this.setState({ loading: true }, () => {
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
    let _templateListing =
      nextProps && nextProps.templateListing && nextProps.templateListing;
    if (_templateListing !== this.state.templateListing) {
      this.setState({ templateListing: _templateListing }, () => {
        this.setState({ loading: false });
      });
    }
  }

  showModalDelete = (id) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
    });
  };

  getAddTemplateButton = () => {
    return (
      <Row xs={24}>
        <Col xs={24} style={{ marginTop: "10px" }}>
          <Button
            type="primary"
            className="button-blue"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              this.setState({ isVisibleAddTemplatePopUp: true });
            }}
          >
            ADD TEMPLATE
          </Button>
        </Col>
      </Row>
    );
  };

  handleCancelAddTemplatePopUp = () => {
    this.setState({ isVisibleAddTemplatePopUp: false });
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete this template?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.setLoadingTrue();
                  this.props
                    .deleteTemplate(this.state.index)
                    .then(() => {
                      message.success("Template is deleted successfully!");
                      this.props
                        .getTemplateListing()
                        .then(() => {
                          this.setState({ visibleDeleteModal: false }, () => {
                            this.setLoadingFalse();
                          });
                        })
                        .catch(() => {
                          this.setState({ visibleDeleteModal: false }, () => {
                            this.setLoadingFalse();
                          });
                        });
                    })
                    .catch(() => {
                      this.setState({ visibleDeleteModal: false }, () => {
                        this.setLoadingFalse();
                      });
                    });
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getTemplateListing = () => {
    return (
      <div>
        {this.state &&
          this.state.templateListing &&
          this.state.templateListing.map((data, index) => {
            return (
              <div key={index}>
                <ListingSingleModule
                  data={data}
                  taxListing={this.props && this.props.taxListing}
                  editTemplate={this.props && this.props.editTemplate}
                  setLoadingTrue={this.setLoadingTrue}
                  setLoadingFalse={this.setLoadingFalse}
                  getTemplateListing={
                    this.props && this.props.getTemplateListing
                  }
                  showModalDelete={this.showModalDelete}
                />
              </div>
            );
          })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div
          className="page-container"
          style={{ marginTop: "-30px", height: "100vh" }}
        >
          <div
            className="ts-container-settings"
            style={{
              height: "95vh",
              maxHeight: "95vh",
              overflowY: "auto",
            }}
          >
            {this.state.loading ? (
              <div className={"spinner"}>
                <Spin size="large" />
              </div>
            ) : (
              <div>
                {this.getAddTemplateButton()}
                {this.state.isVisibleAddTemplatePopUp ? (
                  <Template
                    isVisibleAddTemplatePopUp={
                      this.state.isVisibleAddTemplatePopUp
                    }
                    handleCancelAddTemplatePopUp={
                      this.handleCancelAddTemplatePopUp
                    }
                  />
                ) : null}
                {this.getTemplateListing()}
              </div>
            )}
          </div>
        </div>
        {this.getModalDelete()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    templateListing:
      state &&
      state.templatesReducer &&
      state.templatesReducer.templateListing &&
      state.templatesReducer.templateListing.items &&
      state.templatesReducer.templateListing.items,
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateListing: bindActionCreators(getTemplateListing, dispatch),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  editTemplate: bindActionCreators(editTemplate, dispatch),
  deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
});

Listing = connect(mapStateToProps, mapDispatchToProps)(Listing);
export default withRouter(Listing);
