import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  Modal,
  Spin,
  InputNumber,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Images } from "../../Themes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addNewTemplate,
  getAllTaxesListing,
  getTemplateListing,
} from "../../store/Actions";
import Stage from "./Stage";
const { Option } = Select;

const layoutSendingReceipt = {
  labelCol: { span: 7, offset: 2 },
  wrapperCol: { span: 10, offset: 1 },
};

const { TextArea } = Input;
class Template extends React.Component {
  formRef = React.createRef();
  formRefTempAddStages = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      stages: [],
    };
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({ total: 0 });
  }

  getTotal = () => {
    return (
      <Row className="ca-gray-cont">
        <Col xs={24}>
          <Form.Item colon={false} labelAlign="left" label="Total" name="total">
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getNameForm = () => {
    return (
      <Row className="ca-gray-cont">
        <Col xs={23}>
          <Form.Item
            colon={false}
            labelAlign="left"
            label="Name"
            name="name"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            colon={false}
            labelAlign="left"
            label="Description"
            name="description"
          >
            <Input placeholder="Enter Description" />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  onFinishAddStages = (values) => {
    let _amount = 0;
    let amountWithTax = 0;
    if (values.stages && values.stages.length > 0) {
      let _stages = values.stages;
      for (let _index = 0; _index < _stages.length; _index++) {
        _stages[_index].id = 0;
        _stages[_index].invoiceId = 0;
        _stages[_index].xeroLineItemID = "00000000-0000-0000-0000-000000000000";
        if (_stages[_index].taxInclusive === false) {
          amountWithTax =
            _stages[_index].amount +
            (_stages[_index].gst * _stages[_index].amount) / 100;
        } else {
          amountWithTax = _stages[_index].amount;
        }
        _amount = _amount + amountWithTax;
      }
      this.setState({ stages: _stages });
    }

    this.formRef.current.setFieldsValue({
      total: Math.round((_amount + Number.EPSILON) * 100) / 100,
    });
  };

  addStagesForm = () => {
    return (
      <div>
        <Form onFinish={this.onFinishAddStages} ref={this.formRefTempAddStages}>
          <Form.List
            name="stages"
            initialValue={[
              { gst: 0, taxName: "", taxInclusive: true, stageName: "" },
            ]}
          >
            {(fields, { add, remove }) => (
              <div>
                <Row className={"margin-contact-container"}>
                  <Col xs={5}>
                    <div className={"display-contact-text-container"}>
                      <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                        {"Stages"}
                      </h3>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="add-tag-btn">
                      <Form.Item className="form-add-btn ">
                        <img
                          src={Images.addIcon}
                          className="icons-client add-icon"
                          type="primary"
                          onClick={() => add()}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <div className="ca-gray-cont">
                  <Row style={{ marginTop: "30px" }}>
                    <Col xs={23}>
                      {fields.map((field, index) => (
                        <div key={index}>
                          <Stage
                            taxListing={this.props && this.props.taxListing}
                            field={field}
                            index={index}
                            add={() => add()}
                            remove={remove}
                            formRefTempAddStages={this.formRefTempAddStages}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col
                      xs={6}
                      offset={15}
                      style={{ paddingLeft: "10px", marginTop: "6px" }}
                    >
                      <Button
                        size="small"
                        type="primary"
                        htmlType="submit"
                        className="button-blue"
                        // style={{
                        //   display: fields.length > 0 ? "flex" : "none"
                        // }}
                      >
                        Calculate Sub Total
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    );
  };

  getStagesForm = () => {
    return <div>{this.addStagesForm()}</div>;
  };

  getButtons = () => {
    return (
      <Row>
        <Col xs={3} offset={18}>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="button-blue">
              Save
            </Button>
          </Form.Item>
        </Col>
        <Col xs={3}>
          <Form.Item>
            <Button
              className="button-blue"
              type="primary"
              onClick={this.props.handleCancelAddTemplatePopUp}
            >
              Close
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getAddTemplateForm = () => {
    return (
      <div>
        {this.getNameForm()}
        {this.getStagesForm()}
        {this.getTotal()}
        {this.getButtons()}
      </div>
    );
  };

  onFinish = (values) => {
    let data = {
      name: values.name,
      description: values.description === undefined ? "" : values.description,
      stages: this.state.stages,
      total: values.total,
    };

    if (values.total === 0) {
      message.error("Total is 0!");
    } else {
      this.setState({ loading: true });
      this.props
        .addNewTemplate(data)
        .then(() => {
          message.success("Template is added successfully!");
          this.props
            .getTemplateListing()
            .then(() => {
              this.setState({ loading: false }, () => {
                this.props.handleCancelAddTemplatePopUp();
              });
            })
            .catch(() => {
              this.setState({ loading: false }, () => {
                this.props.handleCancelAddTemplatePopUp();
              });
            });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    return (
      <div>
        <Modal
          className="reminder-model-main width-modal-add-template"
          title="Add Template"
          visible={this.props.isVisibleAddTemplatePopUp}
          onCancel={this.props.handleCancelAddTemplatePopUp}
          footer={null}
          maskClosable={false}
        >
          <Spin spinning={this.state.loading}>
            <Form
              {...layoutSendingReceipt}
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              {this.getAddTemplateForm(this.props)}
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewTemplate: bindActionCreators(addNewTemplate, dispatch),
  getTemplateListing: bindActionCreators(getTemplateListing, dispatch),
});

Template = connect(mapStateToProps, mapDispatchToProps)(Template);
export default withRouter(Template);
