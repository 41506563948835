import React, { Fragment, useEffect } from "react";
import { Table, Spin } from "antd";

const ClientEmployerJobTitle = ({
  onGetClientJobHis,
  clientJobHisRes,
  isClientTagId,

  setLoading,
  loading
}) => {
  useEffect(() => {
     
    setLoading(true);
     
    onGetClientJobHis(isClientTagId && isClientTagId)
      .then((res) => {
        let clientJobHisRes = res
         
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [isClientTagId, onGetClientJobHis, setLoading]);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      ellipsis: true,
    },
    {
      title: "Pay Details",
      dataIndex: "payDetails",
      key: "payDetails",
      ellipsis: true,
    }
  ];

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          style={{ marginTop: "10px" }}
          className="job-table table-head employer-tables border-3"
          dataSource={clientJobHisRes && clientJobHisRes.items}
          columns={columns}
          pagination={false}
        />
      </Spin>
    </Fragment>
  );
};
export default ClientEmployerJobTitle;
