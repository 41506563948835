import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const reminderReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_REMINDER_SUCCESS:
      return { ...state, reminderTaskRes: action.payload };
    case types.GET_REMINDER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_REMINDER_TASK_SUCCESS:
      return { ...state, addReminderTaskRes: action.payload };
    case types.ADD_REMINDER_TASK_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.UPDATE_REMINDER_SUCCESS:
      return { ...state, updReminderRes: action.payload };
    case types.UPDATE_REMINDER_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_DAILY_TASKS_SUCCESS:
      return { ...state, dailyTaskRes: action.payload };
    case types.GET_DAILY_TASKS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DAILY_TASKS_SUCCESS:
      return { ...state, adddailyTaskRes: action.payload };
    case types.ADD_DAILY_TASKS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_TASKS_SUCCESS:
      return { ...state, removeTaskRes: action.payload };
    case types.DELETE_TASKS_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_TASK_SUCCESS:
      return { ...state, updateTaskRes: action.payload };
    case types.PUT_TASK_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_COMPLETED_TASK_SUCCESS:
      return { ...state, updateComletedTaskRes: action.payload };
    case types.PUT_COMPLETED_TASK_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_REMINDER_TASK_SUCCESS:
      return { ...state, removeReminderRes: action.payload };
    case types.DELETE_REMINDER_TASK_FAILURE:
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.GET_TASK_COMMENTS_SUCCESS:
      return { ...state, commentData: action.payload };
    case types.GET_TASK_COMMENTS_FAILURE:
      return { ...state, commentData: [] };

    case types.GET_TASK_FOLLOWERS_SUCCESS:
      return { ...state, followersData: action.payload };
    case types.GET_TASK_FOLLOWERS_FAILURE:
      return { ...state, followersData: [] };

    case types.GET_CLIENT_TASK_SUCCESS:
      return { ...state, clientTaskData: action.payload };
    case types.GET_CLIENT_TASK_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, clientTaskData: [] };

    case types.GET_POTENTIAL_TASK_SUCCESS:
      return { ...state, potentialTaskData: action.payload };
    case types.GET_POTENTIAL_TASK_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, potentialTaskData: [] };

    case types.ADD_TASK_COMMENTS_SUCCESS:
      return { ...state, addCommentRes: action.payload };
    case types.ADD_TASK_COMMENTS_FAILURE:

    case types.ADD_TASK_FOLLOWER_SUCCESS:
      return { ...state, addFollowerRes: action.payload };
    case types.ADD_TASK_FOLLOWER_FAILURE:

    case types.ADD_TASK_FILE_NOTE_SUCCESS:
      return { ...state, addFilenoteRes: action.payload };
    case types.ADD_TASK_FILE_NOTE_FAILURE:
      openNotificationWithIcon("error", "Error", action.payload.message);

    case types.DELETE_FOLLOWER_SUCCESS:
      return { ...state, removeFollowerRes: action.payload };
    case types.DELETE_FOLLOWER_FAILURE:
      if (action.payload.response === "undefined") {
        return;
      }
    case types.DELETE_CLIENT_SUCCESS:
      return { ...state, removeClientRes: action.payload };
    case types.DELETE_CLIENT_FAILURE:
      if (action.payload.response === "undefined") {
        return;
      }
    case types.GET_TASK_COMPLETED_SUCCESS:
      return { ...state, completedTaskData: action.payload };
    case types.GET_TASK_COMPLETED_FAILURE:
      // openNotificationWithIcon("error", "Error", action.payload.message);
      return { ...state, completedTaskData: [] };

    default:
      return state;
  }
};
