import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApiKey from "../AccountSetting/ApiKey";

import {
  getOutlookMail,
  OutlookLogin,
  addOutlookAccount,
  removeOutlookAccount,
  setActiveInnerTab,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    dropboxDocRes: state.emailIntegrationReducer.outlookMailRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onGetOutlookMail: bindActionCreators(getOutlookMail, dispatch),
    // onOutlookLogin: bindActionCreators(OutlookLogin, dispatch),
    // onAddOutlookAccount: bindActionCreators(addOutlookAccount, dispatch),
    // onRemoveOutlookAccount: bindActionCreators(removeOutlookAccount, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKey);
