import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, Modal, Spin, Row, Col } from "antd";
import EmployerManagement from "./EmployerManagement";
import AddNewEmployerForm from "./AddNewEmployerForm";
import EmployerManagementTabs from "./EmployerManagementTabs";
import Invoice from "./Invoice/Invoice";
import PotentialHeaderTabs from "../../Components/Header/PotentialHeaderTabs";

var userId = localStorage.getItem("userId");

const { TabPane } = Tabs;

const EmployerManagementHead = ({
  onGetEmployerManag,
  employerManagRes,

  onAddEmployerManag,

  onDeleteEmployer,

  ongetEmployerContact,
  EmployerContactRes,

  onAddEmployerContact,

  onGetEmployerJob,
  employerJobRes,

  onAddEmployerJob,

  onGetEmployerFile,
  employerFileRes,

  onAddEmployerFile,

  onSearchEmployer,
  searchEmployerRes,

  onGetEmployerData,
  singleEmployerRes,

  onRemoveEmployerContact,

  onGetLetterTemplates,
  LetterTemplatesRes,

  onRemoveEmployerFile,

  onUpdateEmployerManag,

  onUpdateEmployerFile,

  onUpdateEmployerContact,

  onGetEmployerDocument,
  employerDocumentRes,

  onAddEmployerDocument,

  onRemoveEmployerDocument,

  onUpdateEmployerDocument,

  onUploadAvatar,
  imageUploadSuccess,

  onGetDocumentChecklist,
  docChecklistRes,

  onGetEmployerCheckList,
  employerCheckListRes,

  onAddEmployerCheckList,

  location,
  history,

  onSendEmailLink,

  onAddEmployerEmail,

  onGetEmployerHistory,
  employerHistoryRes,

  onGetAutoEmailImport,
  emailImportRes,

  onAddAutoEmailImport,

  onAddDocumentTittle,

  onAddAnyTamplate,
  addAnyTemRes,

  onUploadFile,
  addUploadFileRes,

  onGetTeamMember,
  teamMembers,

  onDeleteCreateEmail,

  onGetDocumentDownload,
  onGetDocumentPdf,

  onGetFilteredType,
  documentFilterRes,

  onAddFilteredType,

  onUpdateEmployerJob,

  onRemoveEmployerJob,

  onGetClientSearch,
  clientSearchRes,

  onGetClientEmp,
  clientEmpRes,

  onSearchClient,
  searchClientData,

  onGetClientByEmpId,
  empClientEmpRes,

  onLinkEmpWithClient,

  onAddEmployerJobHistory,

  onGetClientTag,
  clientTagRes,

  onGetProfileClientTag,
  getClientRes,

  onAddDocWithName,
  docWithNameRes,

  onGetClientJobHis,
  clientJobHisRes,

  onGetSingleClientHis,
  singleJobHisRes,

  onGetEmployerCase,
  onGetCaseDocument,
  onUpdateCaseHistory,
  onGetVisaFileNotes,
  visaFileNotesRes,
  onRemoveCaseStatus,
  onRemoveSubjectCase,
  employerCaseRes,
  onStartNewApplication,

  onGetVisaType,
  visaTypeData,

  visaStatusData,
  onGetVisaStatus,

  onUpdateCaseStatus,

  onGetSignature,
  signatureRes,

  onGetDocuments,
  documentRes,

  onGetImapForAll,
  imapForAllRes,
  onGetPdf,
  onAddEmailDocument,

  onGetAllQuestionnaire,
  onCreateDynamicLink,
  onGetDynamicLink,
  onGetEmailTemplate,
  onSetEmailTemplate,
  onGetQuestionnaire,
  onUpdateQuestionnaire,
  onGetMappingParent,
  onGetMappingChild,
  onGetQuestionnaireGroup,
  onGetGroupedQuestionnaire,
  onGetFilledQuestionnaire,
  onGetAllFilledQuestionnaire,
  onGetCountries,
  questionnaireList,
  dynamicLinkSuccess,
  dynamicLinkData,
  emailTemplateData,
  setEmailData,
  clientTab,
  countriesData,
  questionnaireData,
  mappParentData,
  mappChildData,
  questionnaireGroupRes,
  groupedQuestionnaireRes,
  filledQuestionnaireRes,
  allFilledQuestionnaireRes,
  mergeFromClientRes,
  updMergeFromClientRes,
  updMergeToClientRes,
  onMergeFromClient,
  onUpdMergeFromClient,
  onUpdMergeToClient,
  onGetEmailContent,
  activeKey,
  onSetActiveKey,
  onAddProcessingPerson,
  onGetVisaApplication,
  visaAppData,
  onGetPermProcessingPerson,
}) => {
  const _selectedBranchId = localStorage.getItem("selectedBranchId");
  const [addNew, setAddNew] = useState("");
  const [addEmployerModel, setAddEmployerModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employerTabble, setEmployerTabble] = useState(true);
  const [userDataEmp, setUserDataEmp] = useState({});
  const [handleKey, setHandleKey] = useState("1");
  const [isGetEmployers, SetIsGetEmployers] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(_selectedBranchId);
  const [expandedRowsEntriesKeys, setExpandedRowsEntriesKeys] = useState(["1"]);

  useEffect(() => {
    if (
      window.history.state &&
      window.history.state.state &&
      window.history.state.state.userData
    ) {
      singleEmployeFuc(window.history.state.state.userData);
    }
  }, [window.history.state]);

  const showModal = (modalTypeName) => {
    setAddNew(modalTypeName);
    setAddEmployerModel(true);
  };

  const handleCancel = () => {
    setAddEmployerModel(false);
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const singleEmployeFuc = (userData) => {
    setEmployerTabble(!employerTabble);
    setUserDataEmp(userData);
  };

  const singleEmployeIcons = (userData) => {
    setUserDataEmp(userData);
  };

  const handleSetDefault = () => {
    setExpandedRowsEntriesKeys([]);
    setIsSearch(false);
  };

  const prevBranchId = usePrevious(selectedBranchId);

  useEffect(() => {
    setLoading(true);
    onGetEmployerManag().then(() => setLoading(false));
  }, []);

  // useEffect(() => {
  //
  //   onGetEmployerManag().then(() => setLoading(false));
  // }, [loading]);

  const callback = (key) => {
    setLoading(true);
    setEmployerTabble(true);
    setIsSearch(false);
    setLoading(false);
    // setLoading(false);
    // window.location.reload();
  };

  const headOption = [
    { tabName: "Employer Management", linkName: "/employer-management" },
  ];

  return (
    <Fragment>
      <div className="reminder-tabs-header employer-Header">
        <Row>
          <Col xs={24}>
            <PotentialHeaderTabs
              activePath={window.location.pathname}
              data={headOption}
              callback={callback}
            />
          </Col>
        </Row>
        {/*<Tabs onChange={key => callback(key)} defaultActiveKey="1">*/}
        {/*  <TabPane tab="EMPLOYER MANAGEMENT" key="1">*/}

        {employerTabble === true ? (
          window.location.pathname === "/employer-management" ? (
            <Spin size="large" spinning={loading}>
              <EmployerManagement
                expandedRowsEntriesKeys={expandedRowsEntriesKeys}
                onGetEmployerManag={onGetEmployerManag}
                employerManagRes={employerManagRes}
                onAddEmployerManag={onAddEmployerManag}
                showModal={showModal}
                setEmployerTabble={setEmployerTabble}
                employerTabble={employerTabble}
                onDeleteEmployer={onDeleteEmployer}
                onSearchEmployer={onSearchEmployer}
                searchEmployerRes={searchEmployerRes}
                onGetEmployerData={onGetEmployerData}
                singleEmployerRes={singleEmployerRes}
                singleEmployeFuc={singleEmployeFuc}
                userDataEmp={userDataEmp}
                setLoading={setLoading}
                loading={loading}
                userId={userId}
                onGetLetterTemplates={onGetLetterTemplates}
                onGetEmployerFile={onGetEmployerFile}
                employerFileRes={employerFileRes}
                onAddEmployerFile={onAddEmployerFile}
                onGetEmployerJob={onGetEmployerJob}
                employerJobRes={employerJobRes}
                LetterTemplatesRes={LetterTemplatesRes}
                handleCancel={handleCancel}
                singleEmployeIcons={singleEmployeIcons}
                onUpdateEmployerFile={onUpdateEmployerFile}
                onRemoveEmployerFile={onRemoveEmployerFile}
                onAddEmployerContact={onAddEmployerContact}
                onAddAnyTamplate={onAddAnyTamplate}
                handleSetDefault={handleSetDefault}
                setIsSearch={setIsSearch}
                isSearch={isSearch}
                onGetClientByEmpId={onGetClientByEmpId}
                empClientEmpRes={empClientEmpRes}
                onGetImapForAll={onGetImapForAll}
                imapForAllRes={imapForAllRes}
                onGetPdf={onGetPdf}
                onGetDocumentDownload={onGetDocumentDownload}
                onGetEmailContent={onGetEmailContent}
                onUpdateEmployerManag={onUpdateEmployerManag}
                activeKey={activeKey}
                onSetActiveKey={onSetActiveKey}
                onGetClientTag={onGetClientTag}
                clientTagRes={clientTagRes}
                onGetEmployerCase={onGetEmployerCase}
                countriesData={countriesData}
                onGetCountries={onGetCountries}
                onGetAllFilledQuestionnaire={onGetAllFilledQuestionnaire}
                allFilledQuestionnaireRes={allFilledQuestionnaireRes}
              />
            </Spin>
          ) : null
        ) : (
          <EmployerManagementTabs
            ongetEmployerContact={ongetEmployerContact}
            EmployerContactRes={EmployerContactRes}
            onAddEmployerContact={onAddEmployerContact}
            onGetEmployerJob={onGetEmployerJob}
            employerJobRes={employerJobRes}
            onAddEmployerJob={onAddEmployerJob}
            onGetEmployerFile={onGetEmployerFile}
            employerFileRes={employerFileRes}
            onAddEmployerFile={onAddEmployerFile}
            onGetEmployerData={onGetEmployerData}
            singleEmployerRes={singleEmployerRes}
            singleEmployeFuc={singleEmployeFuc}
            userDataEmp={userDataEmp}
            onRemoveEmployerContact={onRemoveEmployerContact}
            onGetLetterTemplates={onGetLetterTemplates}
            LetterTemplatesRes={LetterTemplatesRes}
            onRemoveEmployerFile={onRemoveEmployerFile}
            handleCancel={handleCancel}
            onUpdateEmployerManag={onUpdateEmployerManag}
            onUpdateEmployerFile={onUpdateEmployerFile}
            onUpdateEmployerContact={onUpdateEmployerContact}
            onGetEmployerDocument={onGetEmployerDocument}
            employerDocumentRes={employerDocumentRes}
            onAddEmployerDocument={onAddEmployerDocument}
            onRemoveEmployerDocument={onRemoveEmployerDocument}
            onUpdateEmployerDocument={onUpdateEmployerDocument}
            onUploadAvatar={onUploadAvatar}
            imageUploadSuccess={imageUploadSuccess}
            onGetDocumentChecklist={onGetDocumentChecklist}
            docChecklistRes={docChecklistRes}
            onGetEmployerCheckList={onGetEmployerCheckList}
            employerCheckListRes={employerCheckListRes}
            onAddEmployerCheckList={onAddEmployerCheckList}
            location={location}
            history={history}
            onSendEmailLink={onSendEmailLink}
            onAddEmployerEmail={onAddEmployerEmail}
            onGetEmployerHistory={onGetEmployerHistory}
            employerHistoryRes={employerHistoryRes}
            onGetAutoEmailImport={onGetAutoEmailImport}
            emailImportRes={emailImportRes}
            onAddAutoEmailImport={onAddAutoEmailImport}
            onAddDocumentTittle={onAddDocumentTittle}
            onAddAnyTamplate={onAddAnyTamplate}
            addAnyTemRes={addAnyTemRes}
            onGetTeamMember={onGetTeamMember}
            teamMembers={teamMembers}
            onDeleteCreateEmail={onDeleteCreateEmail}
            onGetDocumentDownload={onGetDocumentDownload}
            onGetDocumentPdf={onGetDocumentPdf}
            onGetFilteredType={onGetFilteredType}
            documentFilterRes={documentFilterRes}
            onAddFilteredType={onAddFilteredType}
            onUpdateEmployerJob={onUpdateEmployerJob}
            onRemoveEmployerJob={onRemoveEmployerJob}
            onGetClientSearch={onGetClientSearch}
            clientSearchRes={clientSearchRes}
            onGetClientEmp={onGetClientEmp}
            clientEmpRes={clientEmpRes}
            onSearchClient={onSearchClient}
            searchClientData={searchClientData}
            onGetClientByEmpId={onGetClientByEmpId}
            empClientEmpRes={empClientEmpRes}
            onLinkEmpWithClient={onLinkEmpWithClient}
            onAddEmployerJobHistory={onAddEmployerJobHistory}
            onGetClientTag={onGetClientTag}
            clientTagRes={clientTagRes}
            onGetProfileClientTag={onGetProfileClientTag}
            getClientRes={getClientRes}
            onGetEmployerManag={onGetEmployerManag}
            employerManagRes={employerManagRes}
            docWithNameRes={docWithNameRes}
            onAddDocWithName={onAddDocWithName}
            onGetClientJobHis={onGetClientJobHis}
            clientJobHisRes={clientJobHisRes}
            onGetSingleClientHis={onGetSingleClientHis}
            singleJobHisRes={singleJobHisRes}
            onGetEmployerCase={onGetEmployerCase}
            onGetCaseDocument={onGetCaseDocument}
            onUpdateCaseHistory={onUpdateCaseHistory}
            onGetVisaFileNotes={onGetVisaFileNotes}
            visaFileNotesRes={visaFileNotesRes}
            onRemoveCaseStatus={onRemoveCaseStatus}
            onRemoveSubjectCase={onRemoveSubjectCase}
            employerCaseRes={employerCaseRes}
            onStartNewApplication={onStartNewApplication}
            onGetVisaType={onGetVisaType}
            visaTypeData={visaTypeData}
            visaStatusData={visaStatusData}
            onGetVisaStatus={onGetVisaStatus}
            onUpdateCaseStatus={onUpdateCaseStatus}
            onGetSignature={onGetSignature}
            signatureRes={signatureRes}
            onGetDocuments={onGetDocuments}
            documentRes={documentRes}
            onGetImapForAll={onGetImapForAll}
            imapForAllRes={imapForAllRes}
            onGetPdf={onGetPdf}
            onAddEmailDocument={onAddEmailDocument}
            onGetAllQuestionnaire={onGetAllQuestionnaire}
            onCreateDynamicLink={onCreateDynamicLink}
            onGetDynamicLink={onGetDynamicLink}
            onGetEmailTemplate={onGetEmailTemplate}
            onSetEmailTemplate={onSetEmailTemplate}
            onGetQuestionnaire={onGetQuestionnaire}
            onUpdateQuestionnaire={onUpdateQuestionnaire}
            onGetMappingParent={onGetMappingParent}
            onGetMappingChild={onGetMappingChild}
            onGetQuestionnaireGroup={onGetQuestionnaireGroup}
            onGetGroupedQuestionnaire={onGetGroupedQuestionnaire}
            onGetFilledQuestionnaire={onGetFilledQuestionnaire}
            onGetAllFilledQuestionnaire={onGetAllFilledQuestionnaire}
            questionnaireList={questionnaireList}
            dynamicLinkSuccess={dynamicLinkSuccess}
            dynamicLinkData={dynamicLinkData}
            emailTemplateData={emailTemplateData}
            setEmailData={setEmailData}
            clientTab={clientTab}
            countriesData={countriesData}
            questionnaireData={questionnaireData}
            mappParentData={mappParentData}
            mappChildData={mappChildData}
            questionnaireGroupRes={questionnaireGroupRes}
            groupedQuestionnaireRes={groupedQuestionnaireRes}
            filledQuestionnaireRes={filledQuestionnaireRes}
            allFilledQuestionnaireRes={allFilledQuestionnaireRes}
            mergeFromClientRes={mergeFromClientRes}
            updMergeFromClientRes={updMergeFromClientRes}
            updMergeToClientRes={updMergeToClientRes}
            onGetCountries={onGetCountries}
            onMergeFromClient={onMergeFromClient}
            onUpdMergeFromClient={onUpdMergeFromClient}
            onUpdMergeToClient={onUpdMergeToClient}
            onGetEmailContent={onGetEmailContent}
            activeKey={activeKey}
            onSetActiveKey={onSetActiveKey}
            onAddProcessingPerson={onAddProcessingPerson}
            onGetVisaApplication={onGetVisaApplication}
            visaAppData={visaAppData}
            onGetPermProcessingPerson={onGetPermProcessingPerson}
          />
        )}
        {/*  </TabPane>*/}
        {/*  /!*<TabPane tab="INVOICE" key="2">*!/*/}
        {/*  /!*  <Invoice showModal={showModal} />*!/*/}
        {/*  /!*</TabPane>*!/*/}
        {/*</Tabs>*/}
      </div>
      {addEmployerModel === true && (
        <Modal
          className="reminder-model-main"
          title={addNew === "add-new" && "ADD NEW"}
          visible={addEmployerModel}
          onCancel={handleCancel}
          footer={false}
          width={addNew === "add-new" && 1000}
        >
          <AddNewEmployerForm
            onAddEmployerManag={onAddEmployerManag}
            onGetEmployerManag={onGetEmployerManag}
            employerManagRes={employerManagRes}
            onAddEmployerContact={onAddEmployerContact}
            handleCancel={handleCancel}
            ongetEmployerContact={ongetEmployerContact}
            userDataEmp={userDataEmp}
            setLoading={setLoading}
            loading={loading}
            onUploadAvatar={onUploadAvatar}
            onUploadFile={onUploadFile}
            addUploadFileRes={addUploadFileRes}
            handleSetDefault={handleSetDefault}
            onAddDocWithName={onAddDocWithName}
            docWithNameRes={docWithNameRes}
            countriesData={countriesData}
            onGetCountries={onGetCountries}
            onAddProcessingPerson={onAddProcessingPerson}
          />
        </Modal>
      )}
    </Fragment>
  );
};
export default EmployerManagementHead;
