import * as types from "../Constants";

export const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case types.COMPANY_SUCCESS:
      return { ...state, companyData: action.payload };
    case types.COMPANY_FAILURE:
      console.log("on error console", action.payload);
    case types.UPDATE_COMPANY_SUCCESS:
      return { ...state, updCompanySuccess: action.payload };
    case types.UPDATE_COMPANY_FAILURE:
      console.log("on error console", action.payload);

    default:
      return state;
  }
};
