import { api } from "../../services/api";
import * as types from "../Constants";

export const getGroups = (data) => {
  const options = {
    url: "v1/group",
  };

  options.types = [types.GROUP_SUCCESS, types.GROUP_FAILURE];

  return api.get(options);
};

export const addGroup = (data) => {
  const options = {
    url: "v1/group",
  };

  options.types = [types.ADD_GROUP_SUCCESS, types.ADD_GROUP_FAILURE];

  return api.post(options, data);
};

export const updGroup = (data) => {
  const options = {
    url: "v1/group",
  };

  options.types = [types.UPDATE_GROUP_SUCCESS, types.UPDATE_GROUP_FAILURE];

  return api.put(options, data);
};

export const deleteGroup = (data) => {
  const options = {
    url: "v1/group",
  };

  options.types = [types.DELETE_GROUP_SUCCESS, types.DELETE_GROUP_FAILURE];

  return api.delete(options, data);
};
