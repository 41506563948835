import { api } from "../../services/api";
import * as types from "../Constants";

export const getVisaCategoryMain = () => {
  const options = {
    url: `v1/case/CheckList/Category/All`,
  };
  options.types = [
    types.GET_VISA_CATEGORY_TYPE_SUCCESS,
    types.GET_VISA_CATEGORY_TYPE_FAILURE,
  ];

  return api.get(options);
};

export const getVisaCategoryChecklist = (visaTypeName) => {
  const options = {
    url: `v1/case/SearchCheckList`,
  };
  options.types = [
    types.GET_VISA_CATEGORY_CHECKLIST_SUCCESS,
    types.GET_VISA_CATEGORY_CHECKLIST_FAILURE,
  ];

  return api.post(options, { description: visaTypeName });
};

export const removeChecklistCategory = (data) => {
  const options = {
    url: "v1/case/CheckList/Category",
  };
  options.types = [
    types.DELETE_CHECKLIST_CATEGORY_SUCCESS,
    types.DELETE_CHECKLIST_CATEGORY_FAILURE,
  ];

  return api.delete(options, data);
};

export const updateChecklistCategory = (data) => {
  const options = {
    url: "v1/case/CheckList/Category",
  };
  options.types = [
    types.UPDATE_CHECKLIST_CATEGORY_SUCCESS,
    types.UPDATE_CHECKLIST_CATEGORY_FAILURE,
  ];

  return api.put(options, data);
};

export const updateVisaChecklist = (data) => {
  const options = {
    url: "v1/case/CheckList",
  };
  options.types = [
    types.UPDATE_VISA_CHECKLIST_SUCCESS,
    types.UPDATE_VISA_CHECKLIST_FAILURE,
  ];

  return api.put(options, data);
};

export const removeChecklist = (data) => {
  const options = {
    url: "v1/case/CheckList",
  };
  options.types = [
    types.DELETE_CHECKLIST_SUCCESS,
    types.DELETE_CHECKLIST_FAILURE,
  ];

  return api.delete(options, data);
};

export const getChecklistTasks = (checklistId) => {
  const options = {
    url: `v1/case/CheckListTask/${checklistId}`,
  };
  options.types = [
    types.GET_CHECKLIST_TASK_SUCCESS,
    types.GET_CHECKLIST_TASK_FAILURE,
  ];

  return api.get(options);
};

export const removeChecklistTask = (data) => {
  const options = {
    url: "v1/case/CheckListTask",
  };
  options.types = [
    types.DELETE_CHECKLIST_TASK_SUCCESS,
    types.DELETE_CHECKLIST_TASK_FAILURE,
  ];

  return api.delete(options, data);
};
