import * as types from "./../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const documentTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_TYPE_SUCCESS:
      return { ...state, documentTypeRes: action.payload };
    case types.GET_DOCUMENT_TYPE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.ADD_DOCUMENT_TYPE_SUCCESS:
      return { ...state, addDocumentTypeRes: action.payload };
    case types.ADD_DOCUMENT_TYPE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.PUT_DOCUMENT_TYPE_SUCCESS:
      return { ...state, updateDocumentTypeRes: action.payload };
    case types.PUT_DOCUMENT_TYPE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    case types.DELETE_DOCUMENT_TYPE_SUCCESS:
      return { ...state, removeDocumentTypeRes: action.payload };
    case types.DELETE_DOCUMENT_TYPE_FAILURE:
       
      if (action.payload.response === "undefined") {
        window.location.assign("/login");
      }

    default:
      return state;
  }
};
