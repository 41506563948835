import { api } from "../../services/api";
import * as types from "../Constants";

export const getEmailSetting = () => {
  const options = {
    url: `v1/users/UserEmailSetting`,
  };
  options.types = [
    types.GET_EMAIL_SETTINGS_SUCCESS,
    types.GET_EMAIL_SETTINGS_FAILURE,
  ];

  return api.get(options);
};

export const updateEmailSetting = (data) => {
  const options = {
    url: "v1/users/UserEmailSetting",
  };
  options.types = [
    types.PUT_EMAIL_SETTINGS_SUCCESS,
    types.PUT_EMAIL_SETTINGS_FAILURE,
  ];

  return api.put(options, data);
};
