import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminLogin from "../Admin/AdminLogin";

import { addAdminLogin } from "../store/Actions";

const mapStateToProps = state => {
  return {
    addSignUpRes: state.signUpReducer.addSignUpRes,
    textEncodingRes: state.signUpReducer.textEncodingRes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAdminLogin: bindActionCreators(addAdminLogin, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
