import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllMails from "../AccountSetting/Allmails";

import { setActiveInnerTab } from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onGetOutlookMail: bindActionCreators(getOutlookMail, dispatch),
    // onOutlookLogin: bindActionCreators(OutlookLogin, dispatch),
    // onAddOutlookAccount: bindActionCreators(addOutlookAccount, dispatch),
    // onRemoveOutlookAccount: bindActionCreators(removeOutlookAccount, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMails);
