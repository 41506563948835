import React, { useEffect, Fragment, useState } from "react";
import { Row, Col, Table, Button, Upload, message, Spin, Form } from "antd";
import { Link } from "react-router-dom";

import { Images } from "../../../../../Themes";
import { UploadOutlined, BankTwoTone, PhoneTwoTone } from "@ant-design/icons";
// import { PlayCircleOutlined } from "@ant-design/icons";
// import PinIcon from "./../../statics/images/pin.svg";
// import PhoneIcon from "./../../statics/images/phone.svg";
// import EmailIcon from "./../../statics/images/email.svg";
// import { adressIcon, phoneIcon, contactMailIcon } from "./../Common/icons";
import { getUrlLastId } from "./../../../../../Common/reUseFunction";

const style = { background: "#0092ff", padding: "8px 0" };

const props = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const data = [
  {
    key: "1",
    title: "Attachment 3.pdf",
    linkClient: "Click Here",
    link: "https://checkmyvisa.io/",
  },
  {
    key: "2",
    title: "Attachment 3.pdf",
    linkClient: "Click Here",
    link: "https://checkmyvisa.io/",
  },
];

console.log("12222333333333333333==", window.location);

const ContactUs = ({ onGetEmployerList, employerListRes }) => {
  const [employerItems, setEmployerItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const locationUrl = window.location.pathname;
  const getUrlPath = getUrlLastId(locationUrl);

  useEffect(() => {
    setLoading(true);
    onGetEmployerList(getUrlPath).then((res) => {
      setEmployerItems(res.payload);
      setLoading(false);
    });
  }, [getUrlPath, onGetEmployerList]);

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (text, record, index) => (
        <div>
          <span className="red-dot" />
        </div>
      ),
    },
    {
      title: "SN",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Upload",
      dataIndex: "Upload",
      key: "Upload",
      render: (text, record) => (
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Chose File</Button>
        </Upload>
      ),
    },
  ];

  console.log("employerListResemployerListRes===>", employerListRes);

  return (
    <Fragment>
      <div className="container-box">
        <Row style={{ width: "103.4%" }} className=" margin-top-40">
          <Col
            style={{ marginRight: "10px" }}
            span={6}
            className="employer-logo-box"
          >
            <div className="link-client-logo">
              <div>
                <img src={Images.logo} alt="EZY Migration" />
                <h1>QA COMPANY</h1>
              </div>
            </div>
          </Col>
          <Col span={17}>
            <Row className="employer-logo-box">
              <Col span={6} className="">
                <div className="link-client-logo">
                  <div>
                    <BankTwoTone style={{ height: "72px", fontSize: "43px" }} />
                    <h4>
                      Street # 07, Building 1, First Floor Auckland New Zealand
                    </h4>
                  </div>
                </div>
              </Col>

              <Col span={5} className="">
                <div className="link-client-logo">
                  <div>
                    <PhoneTwoTone
                      style={{ height: "72px", fontSize: "43px" }}
                    />
                    <h4>091 025 0130322</h4>
                  </div>
                </div>
              </Col>
              <Col span={6} className="">
                <div className="link-client-logo">
                  <div>
                    <BankTwoTone style={{ height: "72px", fontSize: "43px" }} />
                    <h4>Abcd@gmail.com</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div style={{ marginTop: "7rem" }} className="  d-flex">
          <span
            style={{
              color: "#772727",
              paddingRight: "10px",
              fontWeight: "500",
            }}
          >
            * Required fields
          </span>
          <span style={{ paddingRight: "10px", fontWeight: "400" }}>
            Priority
          </span>
          <span>
            High
            <span style={{ paddingRight: "10px" }} className="red-dot" />
          </span>
          <span>
            Low
            <span style={{ paddingRight: "10px" }} className="yellow-dot" />
          </span>
        </div>
        <Spin size="large" spinning={loading}>
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            className="margin-top-12 contact-us table-head employer-table "
            columns={columns}
            dataSource={
              employerItems && employerItems.items && employerItems.items
            }
            pagination={false}
          />
        </Spin>
        <Row className="flex-end margin-top-12" gutter={10}>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                className="login-form-button save-btn"
                htmlType="submit"
                // onClick={() => showModal("send-email")}
              >
                SAVE
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ContactUs;
