import { api } from "../../services/api";
import * as types from "../Constants";

export const getCommissionRemindersListing = (boolean, clientId) => {
  var id = clientId || "00000000-0000-0000-0000-000000000000";
  const options = {
    url: `v1/commission/reminder/All/${id}/${boolean}`,
  };

  options.types = [
    types.GET_COMMISSION_REMINDER_LISTING_SUCCESS,
    types.GET_COMMISSION_REMINDER_LISTING_FAILURE,
  ];
  return api.get(options);
};

export const editCommissionReminder = (data) => {
  const options = {
    url: "v1/commission/reminder",
  };

  options.types = [
    types.EDIT_COMMISSION_REMINDER_SUCCESS,
    types.EDIT_COMMISSION_REMINDER_FAILURE,
  ];
  return api.put(options, data);
};

export const removeCommissionReminder = (data) => {
  const options = {
    url: "v1/commission/reminder",
  };

  options.types = [
    types.REMOVE_COMMISSION_REMINDER_SUCCESS,
    types.REMOVE_COMMISSION_REMINDER_FAILURE,
  ];
  return api.delete(options, data);
};
