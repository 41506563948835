import React, { useState, Fragment, useEffect } from "react";
// import { Images } from "../../Themes";
import {
  Modal,
  Spin,
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  message
} from "antd";
import {
  SnippetsFilled,
  FileTextFilled,
  CloseCircleTwoTone,
  UserOutlined
} from "@ant-design/icons";

import { Table } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",

    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ["descend"]
  },
  {
    title: "Business/Company Name",
    dataIndex: "age",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.age - b.age
  },
  {
    title: "Contact No",
    dataIndex: "address",

    filterMultiple: false,
    onFilter: (value, record) => record.address.indexOf(value) === 0,
    sorter: (a, b) => a.address.length - b.address.length,
    sortDirections: ["descend", "ascend"]
  },
  {
    title: "Email",
    dataIndex: "address",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.age - b.age
  },
  {
    title: "",
    dataIndex: "address",
    render: (text, record) => {
      return (
        <div className="table-action">
          <FileTextFilled />
          <UserOutlined />
          <SnippetsFilled />
          <CloseCircleTwoTone />
        </div>
      );
    }
  }
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park"
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park"
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park"
  },
  {
    key: "4",
    name: "Jim Red",
    age: 32,
    address: "London No. 2 Lake Park"
  }
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const EmployerManagement = ({ onAddEmployerManag, showModal }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedata, setUpdatedata] = useState({});

  return (
    <Fragment>
      <div>
        <div style={{ display: "flex" }}>
          <div className="client-tag">
            <div className="employer-manag">
              <div className="">
                <div className="client-tag-table">
                  <div className="employer-tables margin-top-34">
                    <Table
                      columns={columns}
                      dataSource={data}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EmployerManagement;
