import React, { useEffect, useState, Fragment } from "react";
import { Select, Spin, Modal, Form, message, Input, DatePicker } from "antd";
import debounce from "lodash/debounce";
import { Images } from "./../../Themes";
import ClientEmployerJobsCard from "./ClientEmployerJobsCard";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const ClientEmployerJobs = ({
  employerJobHistoryCurrentRes,
  onGetClientJobHistoryCurrent,
  activeKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [visibleTag, setVisibleTag] = useState("");

  const confirmationTag = (id, value) => {
    if (value) {
      setModal1Visible(value);
    }
    if (id) {
      setSelectedTag(id);
    }
  };

  const removeProfileTag = (id) => {};

  useEffect(() => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    if (activeKey === "13") {
      onGetClientJobHistoryCurrent(clientprofileid);
    }
  }, [activeKey]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {}, []);

  return (
    <Fragment>
      {employerJobHistoryCurrentRes &&
        employerJobHistoryCurrentRes.items.map((jobHistory, index) => {
          return <ClientEmployerJobsCard jobHistory={jobHistory} />;
        })}
    </Fragment>
  );
};

export default ClientEmployerJobs;
