import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CaseManagement from "../CaseManagement/CaseManagement";

import {
  getClientProfiles,
  getCaseStatuses,
  updateVisaCaseStatus,
  getAdmissionCaseStatuses,
  updateAdmissionCaseStatus,
  getCheckList,
  getVisaCaseCheckList,
  attachCaseCheckListWithCase,
  addCaseCheckListTask,
  completeChecklistTask,
  updateCaseCheckListTask,
  removeCmsClientOrAdd,
  getCheckListTasks,
  removeCmsCase,
  removeCmsTasks,
  addTaskComment,
  getTaskComment,
  removeCmsChecklist,
  removeCmsAdmissionCase,
  searchClientProfiles,
  getVisaTypeByCountry,
  startNewApplication,
  getClientAllVisaCases,
  getCategory,
  getSchoolType,
  getSchools,
  getSchoolLevel,
  addClientAdmission,
  getClientAdmissionProgram,
  getProcessingPersons,
  updateClientActiveStatus,
  getVisaType,
  advanceSearchClientProfiles,
  getClientTag,
  getVisaApplication,
  setActiveKey,
  getProfileClientTag,
  addProfileClientTag,
  removeProfileClientTag,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  updateCompletedTask,
  removeTasks,
  addDailyTasks,
  getTasksComments,
  addTaskComments,
  getTasksFollowers,
  getClientFamily,
  addTaskFileNote,
  getAllClients,
  removeFollower,
  getClientProfilesWithProgress,
  getBranchVisaCountries,
  getClientProfile,
  setProfileData,
  getPartnerData,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    clientProfiles: state.caseManagementReducer.clientProfiles,
    visaStatus: state.caseManagementReducer.visaStatus,
    admissionCaseStatus: state.caseManagementReducer.admissionCaseStatus,
    checkList: state.caseManagementReducer.checkList,
    clientProfilesError: state.caseManagementReducer.clientProfilesError,
    clientTab: state.employerManagReducer.clientTab,
    schoolTypesData: state.caseManagementReducer.schoolTypesData,
    processingPersons: state.caseManagementReducer.processingPersons,
    visaTypeData: state.caseManagementReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    visaAppData: state.clientVisaReducer.visaAppData,
    schoolsData: state.clientAdmissionReducer.schoolsData,
    activeKey: state.employerManagReducer.activeKey,
    clientTagRes: state.accountSetReducer.clientTagRes,
    getClientRes: state.allClientReducer.getClientRes,
    addProfielTagRes: state.allClientReducer.addProfielTagRes,
    removeProfileTagRes: state.allClientReducer.removeProfileTagRes,
    clientsData: state.clientProfileReducer.allClientData,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
    clientProfileData: state.clientProfileReducer.clientProfileData,
    partnerProfileData: state.partnerReducer.partnerProfileData,
    clientFamilyData: state.clientProfileReducer.clientFamilyData,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClientProfiles: bindActionCreators(getClientProfiles, dispatch),
    onSearchClientProfiles: bindActionCreators(searchClientProfiles, dispatch),
    onGetCaseStatuses: bindActionCreators(getCaseStatuses, dispatch),
    onUpdateCaseStatus: bindActionCreators(updateVisaCaseStatus, dispatch),
    onGetAdmissionCaseStatus: bindActionCreators(
      getAdmissionCaseStatuses,
      dispatch
    ),
    onUpdateAdmissionCaseStatus: bindActionCreators(
      updateAdmissionCaseStatus,
      dispatch
    ),
    onGetCheckList: bindActionCreators(getCheckList, dispatch),
    onGetVisaCaseCheckList: bindActionCreators(getVisaCaseCheckList, dispatch),
    onAttachCaseCheckListWithCase: bindActionCreators(
      attachCaseCheckListWithCase,
      dispatch
    ),
    onAddCaseCheckListTask: bindActionCreators(addCaseCheckListTask, dispatch),
    onCompleteChecklistTask: bindActionCreators(
      completeChecklistTask,
      dispatch
    ),
    onUpdateCaseCheckListTask: bindActionCreators(
      updateCaseCheckListTask,
      dispatch
    ),
    onRemoveCmsClientOrAdd: bindActionCreators(removeCmsClientOrAdd, dispatch),
    onGetCheckListTasks: bindActionCreators(getCheckListTasks, dispatch),
    onRemoveCmsCase: bindActionCreators(removeCmsCase, dispatch),
    onRemoveCmsAdmissionCase: bindActionCreators(
      removeCmsAdmissionCase,
      dispatch
    ),
    onRemoveCmsTasks: bindActionCreators(removeCmsTasks, dispatch),
    onRemoveCmsChecklist: bindActionCreators(removeCmsChecklist, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskComment: bindActionCreators(getTaskComment, dispatch),
    dispatch: dispatch,
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onStartNewApplication: bindActionCreators(startNewApplication, dispatch),
    onGetClientAllVisaCases: bindActionCreators(
      getClientAllVisaCases,
      dispatch
    ),
    onGetCategory: bindActionCreators(getCategory, dispatch),
    onGetSchoolTypesData: bindActionCreators(getSchoolType, dispatch),
    onGetAllSchools: bindActionCreators(getSchools, dispatch),
    onGetSchoolLevel: bindActionCreators(getSchoolLevel, dispatch),
    onAddClientAdmission: bindActionCreators(addClientAdmission, dispatch),
    onGetClientAdmissionProgram: bindActionCreators(
      getClientAdmissionProgram,
      dispatch
    ),
    onGetProcessingPersons: bindActionCreators(getProcessingPersons, dispatch),
    onUpdateClientActiveStatus: bindActionCreators(
      updateClientActiveStatus,
      dispatch
    ),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onAdvanceSearchClientProfiles: bindActionCreators(
      advanceSearchClientProfiles,
      dispatch
    ),
    onGetClientTag: bindActionCreators(getClientTag, dispatch),
    onGetVisaApplication: bindActionCreators(getVisaApplication, dispatch),
    onSetActiveKey: bindActionCreators(setActiveKey, dispatch),
    onGetProfileClientTag: bindActionCreators(getProfileClientTag, dispatch),
    onAddProfileClientTag: bindActionCreators(addProfileClientTag, dispatch),
    onRemoveProfileClientTag: bindActionCreators(
      removeProfileClientTag,
      dispatch
    ),
    onGetClientTask: bindActionCreators(getClientTasks, dispatch),
    onUpdateTask: bindActionCreators(updetaTasks, dispatch),
    onGetAllUsers: bindActionCreators(getUsers, dispatch),
    onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
    onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
    onRemoveTasks: bindActionCreators(removeTasks, dispatch),
    onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
    onGetTaskComments: bindActionCreators(getTaskComment, dispatch),
    onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
    onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
    onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
    onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
    onGetAllClients: bindActionCreators(getAllClients, dispatch),
    onRemoveFollower: bindActionCreators(removeFollower, dispatch),
    onGetClientProfilesWithProgress: bindActionCreators(
      getClientProfilesWithProgress,
      dispatch
    ),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
    onGetClientProfile: bindActionCreators(getClientProfile, dispatch),
    onSetProfileData: bindActionCreators(setProfileData, dispatch),
    onGetPartner: bindActionCreators(getPartnerData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
