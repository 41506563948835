import React, { Fragment } from "react";
import {
  Select,
  DatePicker,
  Input,
  Form,
  Table,
  Checkbox,
  Button,
  Tabs,
  Row,
  Col,
  InputNumber,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Images } from "../../Themes";
import ListingStage from "./ListingStage";
import { editTemplate, getTemplateListing } from "../../store/Actions";
const { Option } = Select;
const { TextArea } = Input;

class ListingSingleModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxInclusive: true,
      taxValueDropdown: 0,
      name: "",
      description: "",
      stages: [],
      isStagesBlockVisible: false,
      total: 0,
      id: 0,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let _data = nextProps && nextProps.data;
    if (this.state.name !== _data.name) {
      this.setState({
        name: _data.name,
        description: _data.description,
        stages: _data.stages,
        total: _data.total,
        id: _data.id,
      });
    }
  }

  getStageAmountUpdatedValue = (id, amount) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].amount = amount;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageTaxInclusiveUpdatedValue = (id, taxInclusive, taxName, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].taxInclusive = taxInclusive;
          copyStateStages[_pointer].taxName = taxName;
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageTaxNameUpdatedValue = (id, taxName, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].taxName = taxName;
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageGSTUpdatedValue = (id, gst) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].gst = gst;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {
      let _amount = 0;
      let amountWithTax = 0;
      if (this.state.stages && this.state.stages.length > 0) {
        let _stages = this.state.stages;
        for (let _index = 0; _index < _stages.length; _index++) {
          if (_stages[_index].taxInclusive === false) {
            amountWithTax =
              _stages[_index].amount +
              (_stages[_index].gst * _stages[_index].amount) / 100;
          } else {
            amountWithTax = _stages[_index].amount;
          }
          _amount = _amount + amountWithTax;
        }
      }
      this.setState({
        total: Math.round((_amount + Number.EPSILON) * 100) / 100,
      });
    });
  };

  getStageDescriptionUpdatedValue = (id, description) => {
    let copyStateStages = [...this.state.stages];
    if (copyStateStages && copyStateStages.length > 0) {
      for (let _pointer = 0; _pointer < copyStateStages.length; _pointer++) {
        if (copyStateStages[_pointer].id === id) {
          copyStateStages[_pointer].description = description;
        }
      }
    }
    this.setState({ stages: copyStateStages }, () => {});
  };

  handleChangeTemplateName = (e) => {
    this.setState({ name: e.target.value });
  };

  handleChangeTemplateDescription = (e) => {
    this.setState({ description: e.target.value });
  };

  onEditTemplate = () => {
    let _data = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      total: this.state.total,
      stages: this.state.stages,
    };

    this.props && this.props.setLoadingTrue();
    this.props &&
      this.props
        .editTemplate(_data)
        .then(() => {
          message.success("Template is updated successfully!");
          this.props &&
            this.props
              .getTemplateListing()
              .then(() => {
                this.props && this.props.setLoadingFalse();
              })
              .catch(() => {
                this.props && this.props.setLoadingFalse();
              });
        })
        .catch(() => {
          this.props && this.props.setLoadingFalse();
        });
  };

  handleDeleteTemplate = () => {
    this.props && this.props.showModalDelete(this.state.id);
  };

  render() {
    return (
      <Row className="ca-gray-cont">
        <Col xs={23} offset={1}>
          <Row>
            <Col xs={2} offset={22}>
              <a href="javascript:" onClick={this.handleDeleteTemplate}>
                <DeleteOutlined color="rgb(51, 170, 218)" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              offset={1}
              style={{ display: "flex", marginBottom: "10px" }}
            >
              <Input
                value={this.state.name}
                placeholder="Enter Name"
                onChange={this.handleChangeTemplateName}
                style={{ marginRight: "10px" }}
              />
              <a
                href="javascript:"
                className="marginTemplate"
                onClick={this.onEditTemplate}
              >
                <EditOutlined />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={13} offset={1} style={{ display: "flex" }}>
              <Input
                maxLength={1000}
                value={this.state.description}
                onChange={this.handleChangeTemplateDescription}
                placeholder="Enter Description"
                style={{ marginRight: "10px" }}
              />
              <a
                className="marginTemplate"
                href="javascript:"
                onClick={this.onEditTemplate}
              >
                <EditOutlined />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <p
                style={{
                  marginLeft: "13px",
                  paddingTop: "18px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    isStagesBlockVisible: !this.state.isStagesBlockVisible,
                  });
                }}
              >
                {"DETAILS"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={23} className={"border-detail"}></Col>
          </Row>
          {this.state.isStagesBlockVisible
            ? this.state &&
              this.state.stages &&
              this.state.stages.map((data, index) => {
                return (
                  <div key={index}>
                    <ListingStage
                      getStageAmountUpdatedValue={
                        this.getStageAmountUpdatedValue
                      }
                      getStageTaxInclusiveUpdatedValue={
                        this.getStageTaxInclusiveUpdatedValue
                      }
                      getStageTaxNameUpdatedValue={
                        this.getStageTaxNameUpdatedValue
                      }
                      getStageGSTUpdatedValue={this.getStageGSTUpdatedValue}
                      getStageDescriptionUpdatedValue={
                        this.getStageDescriptionUpdatedValue
                      }
                      onEditTemplate={this.onEditTemplate}
                      data={data}
                      index={index}
                      taxListing={this.props && this.props.taxListing}
                    />
                  </div>
                );
              })
            : null}
          <Row>
            <Col xs={4} offset={20}>
              <p
                style={{ fontWeight: "bold" }}
              >{`Total : ${this.state.total}`}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default ListingSingleModule;
