import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddChecklist from "./../CaseManagement/AddChecklist";

import {
  addVisaCategory,
  getVisaCategory,
  addVisaChecklist
} from "../store/Actions";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onAddVisaCategory: bindActionCreators(addVisaCategory, dispatch),
    onGetVisaCategory: bindActionCreators(getVisaCategory, dispatch),
    onAddVisaChecklist: bindActionCreators(addVisaChecklist, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddChecklist);
