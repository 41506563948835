import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaReport from "../Reports/VisaReport";

import {
  getTeamMember,
  getVisaType,
  getVisaStatus,
  getVisaTypeByCountry,
  getCategory,
  saleHistoryReport,
  processingPersonReport,
  clientSourceReport,
  tasksReport,
  birthdayReport,
  fileNotesReport,
  activeCasesReport,
  visaExpiringReport,
  clientEmployersReport,
  getDestination,
  getAllClients,
  visaReport,
  getVisaStatusWithHide,
  getBranchVisaCountries,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    teamMembers: state.teamMemberReducer.teamMembers,
    visaTypeData: state.visaTypeReducer.visaTypeData,
    visaStatusData: state.visaStatusReducer.visaStatusData,
    countryVisaTypeData: state.clientVisaReducer.countryVisaTypeData,
    visaCategoryData: state.clientVisaReducer.visaCategoryData,
    destinationRes: state.reportReducer.destinationRes,
    allClientData: state.clientProfileReducer.allClientData,
    visaReportRes: state.reportReducer.visaReportRes,

    saleHistoryRes: state.reportReducer.saleHistoryRes,
    clientSourceReportRes: state.reportReducer.clientSourceReportRes,
    processingPersonReportRes: state.reportReducer.processingPersonReportRes,
    taskReportRes: state.reportReducer.taskReportRes,
    birthdayReportRes: state.reportReducer.birthdayReportRes,
    fileNotesReportRes: state.reportReducer.fileNotesReportRes,
    activeCasesReportRes: state.reportReducer.activeCasesReportRes,
    visaExpiryReportRes: state.reportReducer.visaExpiryReportRes,
    clientEmployerReportRes: state.reportReducer.clientEmployerReportRes,
    visaStatusHideData: state.visaStatusReducer.visaStatusWithHideData,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTeamMember: bindActionCreators(getTeamMember, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaStatus: bindActionCreators(getVisaStatus, dispatch),
    onGetVisaTypeByCountry: bindActionCreators(getVisaTypeByCountry, dispatch),
    onGetVisaCategory: bindActionCreators(getCategory, dispatch),
    onGetDestination: bindActionCreators(getDestination, dispatch),
    onGetAllClient: bindActionCreators(getAllClients, dispatch),
    onVisaReport: bindActionCreators(visaReport, dispatch),

    onSaleHistoryReport: bindActionCreators(saleHistoryReport, dispatch),
    onProcessingPersonReport: bindActionCreators(
      processingPersonReport,
      dispatch
    ),
    onClientSourceReport: bindActionCreators(clientSourceReport, dispatch),
    onTaskReport: bindActionCreators(tasksReport, dispatch),
    onBirthdayReport: bindActionCreators(birthdayReport, dispatch),
    onFileNotesReport: bindActionCreators(fileNotesReport, dispatch),
    onActiveCasesReport: bindActionCreators(activeCasesReport, dispatch),
    onVisaExpiryReport: bindActionCreators(visaExpiringReport, dispatch),
    onClientEmployerReport: bindActionCreators(clientEmployersReport, dispatch),
    onGetVisaStatusWithHide: bindActionCreators(
      getVisaStatusWithHide,
      dispatch
    ),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VisaReport);
