import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VisaTypes from "../SuperUserSetting/VisaTypes";

import {
  getVisaType,
  addVisaType,
  updVisaType,
  getCountries,
  setActiveInnerTab,
  getVisaTypeWithHidden,
  hideUnhideVisaType,
  getBranchVisaCountries,
} from "../store/Actions";
const mapStateToProps = (state) => {
  return {
    visaTypeData: state.visaTypeReducer.visaTypeData,
    countriesData: state.visaTypeReducer.countriesData,
    visaTypeWithHiddenData:
      state &&
      state.visaTypeReducer &&
      state.visaTypeReducer.visaTypeWithHiddenData &&
      state.visaTypeReducer.visaTypeWithHiddenData,
    brnchVisaCountriesData: state.visaTypeReducer.brnchVisaCountriesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideUnhideVisaType: bindActionCreators(hideUnhideVisaType, dispatch),
    onGetVisaType: bindActionCreators(getVisaType, dispatch),
    onGetVisaTypeWithHidden: bindActionCreators(
      getVisaTypeWithHidden,
      dispatch
    ),
    onAddVisaType: bindActionCreators(addVisaType, dispatch),
    onUpdVisaType: bindActionCreators(updVisaType, dispatch),
    onGetCountries: bindActionCreators(getCountries, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onGetBVisaCountries: bindActionCreators(getBranchVisaCountries, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisaTypes);
