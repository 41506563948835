import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const agentsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_AGENTS_SUCCESS:
      return { ...state, agentsRes: action.payload };
    case types.GET_AGENTS_FAILURE:
      return { ...state, agentsRes: [] };

    default:
      return state;
  }
};
