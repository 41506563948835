// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import activityData from "../ClientActivity/ActivityData";

function AddQualification(Props) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(false);
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();

  useEffect(() => {
    if (Props.clientInfo) {
      setCertificates(Props.clientInfo.certificates);
      setAuthorities(Props.clientInfo.authorities);

      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (Props.clientInfo.emails.length > 0) {
        for (var i = 0; i < Props.clientInfo.emails.length; i++) {
          if (Props.clientInfo.emails[i].emailTypeId === 1) {
            email = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 2) {
            secondaryEmail = Props.clientInfo.emails[i].address;
          }
          if (Props.clientInfo.emails[i].emailTypeId === 3) {
            otherEmail = Props.clientInfo.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (Props.clientInfo.addresses.length > 0) {
        const findBillAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 2
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }

        const findAddress = Props.clientInfo.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (Props.clientInfo.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: Props.clientInfo.medicals[0].medicalIssueDate,
          medicalExpiryDate: Props.clientInfo.medicals[0].medicalExpiryDate,
          medicalGrading: Props.clientInfo.medicals[0].medicalGrading,
          xrayIssueDate: Props.clientInfo.medicals[0].xrayIssueDate,
          xrayExpiryDate: Props.clientInfo.medicals[0].xrayExpiryDate,
          xrayGrading: Props.clientInfo.medicals[0].xrayGrading,
          medicalNotes: Props.clientInfo.medicals[0].medicalNotes,
          medicalNotesDetail: Props.clientInfo.medicals[0].medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
      };
      if (Props.clientInfo.phones.length > 0) {
        const findMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
        }
        const findSecondaryMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = Props.clientInfo.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }

      // form.setFieldsValue({
      //   visaCountryId: Props.clientInfo.visaCountryId.toString(),
      //   visaCountryType: Props.clientInfo.visaCountyType.toString(),
      //   title: Props.clientInfo.title,
      //   middleName: Props.clientInfo.middleName,
      //   gender: Props.clientInfo.gender,
      //   dateOfBirth: moment(Props.clientInfo.dateOfBirth),
      //   address: clientAddressData.address,
      //   nationalityId: Props.clientInfo.nationalityId,
      //   saleDate: moment(Props.clientInfo.saleDate),
      //   sourceId: Props.clientInfo.sourceId.toString(),
      //   jobSectorId: Props.clientInfo.jobSectorId.toString(),
      //   companyOptional: Props.clientInfo.companyOptional,
      //   clientSerial: Props.clientInfo.clientSerial,
      //   nationalityCountry: Props.clientInfo.nationalityCountry,
      //   firstName: Props.clientInfo.firstName,
      //   lastName: Props.clientInfo.lastName,
      //   age: Props.clientInfo.age,
      //   dealWorth: Props.clientInfo.dealWorth,
      //   maritalStatus: Props.clientInfo.maritalStatus,
      //   dependentChildren: Props.clientInfo.dependentChildren,
      //   sourceDescription: Props.clientInfo.sourceDescription,
      //   occupation: Props.clientInfo.occupation,
      //   visaDenied: Props.clientInfo.visaDenied,
      //   deniedText: Props.clientInfo.deniedText,
      //   visaText: Props.clientInfo.visaText,
      //   currentVisaTypeId: Props.clientInfo.currentVisaTypeId.toString(),
      //   currentNewZealandVisaExpiry: moment(
      //     Props.clientInfo.currentNewZealandVisaExpiry
      //   ),
      //   travelConditionsValidTo: moment(
      //     Props.clientInfo.travelConditionsValidTo
      //   ),
      //   clientNumber: Props.clientInfo.clientNumber,
      //   inzUserName: Props.clientInfo.inzUserName,
      //   inzPassword: Props.clientInfo.inzPassword,
      //   inzFeeDate: moment(Props.clientInfo.inzFeeDate),
      //   nzqaOnlineSubDate: moment(Props.clientInfo.nzqaOnlineSubDate),
      //   nzqaDocumentSubDate: moment(Props.clientInfo.nzqaDocumentSubDate),
      //   nzqaDocumentRetDate: moment(Props.clientInfo.nzqaDocumentRetDate),
      //   email: email,
      //   secondaryEmail: secondaryEmail,
      //   otherEmail: otherEmail,
      //   contactPerson: billAddressData ? billAddressData.contactPerson : "",
      //   flat: billAddressData ? billAddressData.flat : "",
      //   streetNumber: billAddressData ? billAddressData.streetNumber : "",
      //   suburb: billAddressData ? billAddressData.suburb : "",
      //   city: billAddressData ? billAddressData.city : "",
      //   billCountry: billAddressData ? billAddressData.country : "",
      //   zipcode: billAddressData ? billAddressData.zipcode : "",
      //   clientAddress: clientAddressData ? clientAddressData.address : "",
      //   mobilePhone: phoneData ? phoneData.mobile : "",
      //   secondaryMobile: phoneData ? phoneData.secondaryMobile : "",
      //   overseasMobile: phoneData ? phoneData.overseasMobile : "",
      //   landLine: phoneData ? phoneData.landLine : "",
      //   otherMobile: phoneData ? phoneData.otherMobile : "",
      //   medicalIssueDate: medicalData
      //     ? moment(medicalData.medicalIssueDate)
      //     : "",
      //   medicalExpiryDate: medicalData
      //     ? moment(medicalData.medicalExpiryDate)
      //     : "",
      //   medicalGrading: medicalData ? medicalData.medicalGrading : "",
      //   xrayIssueDate: medicalData ? moment(medicalData.xrayIssueDate) : "",
      //   xrayExpiryDate: medicalData ? moment(medicalData.xrayExpiryDate) : "",
      //   xrayGrading: medicalData ? medicalData.xrayGrading : "",
      //   medicalNotes: medicalData ? medicalData.medicalNotes : "",
      //   medicalNotesDetail: medicalData ? medicalData.medicalNotesDetail : "",
      //   passportNo:
      //     Props.clientInfo.passports.length > 0
      //       ? Props.clientInfo.passports[0].passportNo
      //       : "",
      //   passportCountry:
      //     Props.clientInfo.passports.length > 0
      //       ? Props.clientInfo.passports[0].passportCountry.toString()
      //       : "",
      //   passportIssueDate:
      //     Props.clientInfo.passports.length > 0
      //       ? moment(Props.clientInfo.passports[0].passportIssueDate)
      //       : "",
      //   passportExpiryDate:
      //     Props.clientInfo.passports.length > 0
      //       ? moment(Props.clientInfo.passports[0].passportExpiryDate)
      //       : "",
      //   secondPassportNo:
      //     Props.clientInfo.passports.length > 1
      //       ? Props.clientInfo.passports[1].passportNo
      //       : "",
      //   secondPassportCountry:
      //     Props.clientInfo.passports.length > 1
      //       ? Props.clientInfo.passports[1].passportCountry.toString()
      //       : "",
      //   secondPassportIssueDate:
      //     Props.clientInfo.passports.length > 1
      //       ? moment(Props.clientInfo.passports[1].passportIssueDate)
      //       : "",
      //   secondPassportExpiryDate:
      //     Props.clientInfo.passports.length > 1
      //       ? moment(Props.clientInfo.passports[1].passportExpiryDate)
      //       : ""
      // });
    }
  }, [Props, form]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const { Option } = Select;

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const addQualification = (values) => {
    // Props.addQualification(values);
    var userId = localStorage.getItem("userId");
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let data = {
      clientId: clientprofileid,
      schoolId: "00000000-0000-0000-0000-000000000000",
      title: values.title || "",
      levelId: 0,
      startDate: values.startDate || "1900-01-01T00:00:00+00",
      endDate: values.endDate || "1900-01-01T00:00:00+00",
      universityName: values.instituteName || "",
      universityAddress: values.instituteAddress || "",
      country: 0,
      streetNumber: "",
      streetName: "",
      suburb: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      studentID: "",
      website: "",
      qualificationAwarded: true,
      level: values.level || "",
    };
    Props.onAddQualification(data).then(() => {
      message.success("Qualification added successfully");
      Props.onGetQualification();
      form.resetFields();
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(sessionStorage.getItem("profileData"));
      let myData = {
        clientName: profileData.fullName,
        logMessage: "Qulaification " + data.title + " added by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  return (
    <div>
      <Form form={form} onFinish={addQualification}>
        <div className="emp-ace-cont">
          <div
            className="form-cont"
            style={{
              justifyContent: "space-between",
              paddingLeft: 0,
              paddingRight: 0,
              display: "block",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                Title
              </p>
              <div className="emp-input-border">
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                Level
              </p>
              <div
                className="emp-input-border"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Form.Item name="level">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                Start Date
              </p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "65%",
                }}
              >
                <Form.Item name="startDate" style={{ width: "100%" }}>
                  <DatePicker />
                </Form.Item>
                {/* <div className="profile-refresh-cont">
                  <img
                    src={Images.refreshArrow}
                    style={{ width: 18, height: 18 }}
                  />
                </div> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                End Date
              </p>
              <div
                style={{
                  display: "flex",
                  border: "none",
                  width: "65%",
                }}
              >
                <Form.Item name="endDate" style={{ width: "100%" }}>
                  <DatePicker />
                </Form.Item>
                {/* <div className="profile-refresh-cont">
                  <img
                    src={Images.refreshArrow}
                    style={{ width: 18, height: 18 }}
                  />
                </div> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                Institute Name
              </p>
              <div className="emp-input-border">
                <Form.Item name="instituteName">
                  <Input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <p className="medical-label" style={{ fontSize: 11 }}>
                Institute Address
              </p>
              <div className="emp-input-border">
                <Form.Item name="instituteAddress">
                  <input className="profile-input" />
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div />
              <div
                className="button-blue-cont"
                style={{ marginTop: 34, paddingRight: 0, width: 70 }}
              >
                <Form.Item>
                  <Button className="emp-blue-btn" htmlType="submit">
                    <span style={{ color: "#FFFFFF" }}>ADD</span>
                  </Button>
                </Form.Item>
                {/*<div className="emp-blue-btn">
                  <span style={{ color: "#FFFFFF" }}>ADD</span>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default AddQualification;
