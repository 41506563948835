import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DocumentChecklist from "../AccountSetting/DocumentChecklist/DocumentChecklist";

import {
  getDocumentChecklist,
  addDocumentChecklist,
  updateDocumentChecklist,
  removeDocumentChecklist,
  getEmailContent,
  addEmailContent,
  updateEmailContent,
  getChecklistItems,
  updateEmailContentDocumentChecklist,
  addLetterTemplates
} from "../store/Actions";
import {
  getEmailContentDocumentCheckList,
  setActiveInnerTab,
} from "../store/Actions/AccountSettings";
import { accountSetReducer } from "../store/Reducers/AccountSettings";

const mapStateToProps = (state) => {
  return {
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    addDocChecklistRes: state.docChecklistReducer.addDocChecklistRes,
    updateDocChecklistRes: state.docChecklistReducer.updateDocChecklistRes,
    remDocChecklistRes: state.docChecklistReducer.remDocChecklistRes,
    emailContentRes: state.accountSetReducer.emailContentRes,
    addEmailContentRes: state.accountSetReducer.addEmailContentRes,
    updateEmailContentRes: state.accountSetReducer.updateEmailContentRes,
    docChecklistItemRes: state.accountSetReducer.docChecklistItemRes,
    emailContentDocumentChecklistRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.emailContentDocumentChecklistRes &&
      state.accountSetReducer.emailContentDocumentChecklistRes.items &&
      state.accountSetReducer.emailContentDocumentChecklistRes.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
    onAddDocumentChecklist: bindActionCreators(addDocumentChecklist, dispatch),
    onUpdateDocumentChecklist: bindActionCreators(
      updateDocumentChecklist,
      dispatch
    ),
    onRemoveDocumentChecklist: bindActionCreators(
      removeDocumentChecklist,
      dispatch
    ),
    getEmailContentDocumentCheckList: bindActionCreators(
      getEmailContentDocumentCheckList,
      dispatch
    ),
    updateEmailContentDocumentChecklist: bindActionCreators(
      updateEmailContentDocumentChecklist,
      dispatch
    ),
    onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
    onAddEmailContent: bindActionCreators(addEmailContent, dispatch),
    onUpdateEmailContent: bindActionCreators(updateEmailContent, dispatch),
    onGetChecklistItems: bindActionCreators(getChecklistItems, dispatch),
    onSetActiveInnerTab: bindActionCreators(setActiveInnerTab, dispatch),
    onAddLetterTemplateContent: bindActionCreators(addLetterTemplates, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentChecklist);
