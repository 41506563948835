import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Common/reUseFunction";

export const caseManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CLIENT_PROFILES_LISTING_SUCCESS:
      return { ...state, clientProfiles: action.payload };
    case types.GET_CLIENT_PROFILES_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Client list is empty!");
        return { ...state, clientProfiles: [] };
      } else {
         
        return { ...state, clientProfilesError: action.payload };
      }
    case types.SEARCH_CLIENT_PROFILES_LISTING_SUCCESS:
      return { ...state, clientSearchProfiles: action.payload };
    case types.SEARCH_CLIENT_PROFILES_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, clientProfiles: [] };
      } else {
         
        return { ...state, clientSearchProfilesError: action.payload };
      }
    case types.GET_VISA_STATUS_LISTING_SUCCESS:
      return { ...state, visaStatus: action.payload };
    case types.GET_VISA_STATUS_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, visaStatus: [] };
      } else {
         
      }
    case types.UPDATE_VISA_CASE_STATUS_SUCCESS:
      return { ...state, visaUpdateStatus: action.payload };
    case types.UPDATE_VISA_CASE_STATUS_FAILURE:
       
    case types.GET_ADMISSION_CASE_STATUS_LISTING_SUCCESS:
      return { ...state, admissionCaseStatus: action.payload };
    case types.GET_ADMISSION_CASE_STATUS_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, admissionCaseStatus: [] };
      }
    case types.UPDATE_ADMISSION_CASE_STATUS_SUCCESS:
      return { ...state, admissionCaseUpdateStatus: action.payload };
    case types.UPDATE_ADMISSION_CASE_STATUS_FAILURE:
       
    case types.GET_CHECKLIST_LISTING_SUCCESS:
      return { ...state, checkList: action.payload };
    case types.GET_CHECKLIST_LISTING_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, checkList: [] };
      }
    case types.ADD_CHECKLIST_WITH_CASE_SUCCESS:
      return { ...state, checkListRes: action.payload };
    case types.ADD_CHECKLIST_WITH_CASE_FAILURE:
       
    case types.ADD_CHECKLIST_TASK_SUCCESS:
      return { ...state, taskRes: action.payload };
    case types.ADD_CHECKLIST_TASK_FAILURE:
       
    case types.COMPLETE_CHECKLIST_TASK_SUCCESS:
      openNotificationWithIcon("success", "Task completed!");
      return { ...state, taskRes: action.payload };
    case types.COMPLETE_CHECKLIST_TASK_FAILURE:
       
    case types.UPDATE_CHECKLIST_TASK_SUCCESS:
      openNotificationWithIcon("success", "Task Updated!");
      return { ...state, resetTaskRes: action.payload };
    case types.UPDATE_CHECKLIST_TASK_FAILURE:
       
    case types.GET_CHECKLIST_TASKS_SUCCESS:
      return { ...state, checkListTask: action.payload };
    case types.GET_CHECKLIST_TASKS_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, checkListTask: [] };
      }
    case types.DELETE_CMS_CLIENT_SUCCESS:
      return { ...state, removeClientSuccess: action.payload };
    case types.DELETE_CMS_CLIENT_FAILURE:
           
      return { ...state, removeClientError: action.payload };
    case types.DELETE_CMS_CASE_SUCCESS:
      return { ...state, removeVisaCaseSuccess: action.payload };
    case types.DELETE_CMS_CASE_FAILURE:
       
    case types.DELETE_CMS_CASE_CHECKLIST_SUCCESS:
      return { ...state, removeVisaCaseChecklistSuccess: action.payload };
    case types.DELETE_CMS_CASE_CHECKLIST_FAILURE:
       
      return { ...state, removeVisaCaseChecklistError: action.payload };
    case types.DELETE_CMS_CHECKLIST_TASK_SUCCESS:
      return { ...state, removeVisaCaseTasksSuccess: action.payload };
    case types.DELETE_CMS_CHECKLIST_TASK_FAILURE:
       
      return { ...state, removeVisaCaseTasksError: action.payload };
    case types.ADD_TASK_COMMENT_SUCCESS:
      return { ...state, addTaskCommentRes: action.payload };
    case types.ADD_TASK_COMMENT_FAILURE:
       
    case types.GET_TASK_COMMENT_SUCCESS:
      return { ...state, taskCommentRes: action.payload };
    case types.GET_TASK_COMMENT_FAILURE:
      return { ...state, taskCommentError: action.payload };
    case types.GET_VISA_TYPE_BY_COUNTRY_SUCCESS:
      return { ...state, countryVisaTypeData: action.payload };
    case types.GET_VISA_TYPE_BY_COUNTRY_FAILURE:
      return { ...state, countryVisaTypeError: action.payload };
    case types.GET_VISA_CASES_SUCCESS:
      return { ...state, visaCases: action.payload };
    case types.GET_VISA_CASES_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        return { ...state, visaCases: [] };
      }
    case types.GET_SCHOOL_TYPES_SUCCESS:
      return { ...state, schoolTypesData: action.payload };
    case types.GET_SCHOOL_TYPES_FAILURE:
      return { ...state, clientStatusRes: action.payload };
    case types.UPDATE_CLIENT_STATUS_SUCCESS:
      return { ...state, schoolTypesData: action.payload };
    case types.UPDATE_CLIENT_STATUS_FAILURE:
      return { ...state, clientStatusErr: action.payload };
    case types.GET_PROCESSING_PERSONS_SUCCESS:
      return { ...state, processingPersons: action.payload };
    case types.GET_PROCESSING_PERSONS_FAILURE:
      return { ...state, processingPersonsError: action.payload };
    case types.VISA_TYPE_SUCCESS:
      return { ...state, visaTypeData: action.payload };
    case types.VISA_TYPE_FAILURE:
      console.log("on error console", action.payload);
    case types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS:
      return { ...state, clientSearchProfiles: action.payload };
    case types.ADVANCE_SEARCH_CLIENT_PROFILES_LISTING_SUCCESS:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status &&
        action.payload.response.status === 404
      ) {
        openNotificationWithIcon("success", "Could't find any client!");
        return { ...state, clientSearchProfiles: [] };
      } else {
         
        return { ...state, clientSearchProfiles: action.payload };
      }
    default:
      return state;
  }
};
