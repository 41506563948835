// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  DatePicker,
  Radio,
  Input,
} from "antd";
import moment from "moment";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import { apiRefresh } from "../../services/api";

const dateFormate = "DD/MM/YYYY";

function QualificationCard(Props) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(false);
  const [interestedVisa, setInterestedVisa] = useState("");
  const [certificates, setCertificates] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [visaDenied, setVisaDenied] = useState([]);
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authority, setAuthority] = useState("");
  const [authSubDate, setAuthSubDate] = useState("");
  const [authRetDate, setAuthRetDate] = useState("");
  const [authRefNo, setAuthRefNo] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const [form] = Form.useForm();
  useEffect(() => {
    if (Props.qualificationData) {
      form.setFieldsValue({
        title: Props.qualificationData.title,
        level: Props.qualificationData.level,
        startDate:
          Props.qualificationData.startDate &&
          moment(Props.qualificationData.startDate).format("DD/MM/YYYY") !=
            "01/01/1900"
            ? moment(Props.qualificationData.startDate)
            : undefined,
        endDate:
          Props.qualificationData.endDate &&
          moment(Props.qualificationData.endDate).format("DD/MM/YYYY") !=
            "01/01/1900"
            ? moment(Props.qualificationData.endDate)
            : undefined,
        instituteName: Props.qualificationData.universityName,
        instituteAddress: Props.qualificationData.universityAddress,
      });
    }
  }, [Props, form]);

  const userOwner = localStorage.getItem("userOwner");
  const userManager = localStorage.getItem("userManager");
  const userId = localStorage.getItem("userId");

  const handleChange = (selectedOption) => {
    // setGender(selectedOption);
  };

  const handleChangeCertificateCountry = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const updateQualification = (values) => {
    Props.updateQualification(values, Props.qualificationData.id);
  };

  const remQualification = () => {
    let data = {
      id: Props.qualificationData.id,
      delete: true,
    };
    const remQualificationOpt = {
      url: `v1/client/educationalhistory`,
    };
    remQualificationOpt.types = [
      "REM_QUALIFICATION_SUCCESS",
      "REM_QUALIFICATION_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .delete(remQualificationOpt, data)
      .then((res) => {
        setLoading(false);
        Props.onGetQualification();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={updateQualification}>
          <div className="emp-middle-cont" style={{ paddingTop: 10 }}>
            <div
              onClick={() => remQualification()}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={Images.deleteGray}
                style={{
                  marginRight: 20,
                  marginBottom: 10,
                  width: 17,
                  height: 17,
                }}
              />
            </div>
            <div className="emp-ace-cont">
              <div
                className="form-cont"
                style={{
                  justifyContent: "space-between",
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "block",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Title
                  </p>
                  <div className="emp-input-border">
                    <Form.Item name="title">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Level
                  </p>
                  <div className="emp-input-border">
                    <Form.Item name="level">
                      <Input className="profile-input" />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Start Date
                  </p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "65%",
                    }}
                  >
                    <Form.Item name="startDate" style={{ width: "100%" }}>
                      <DatePicker format={dateFormate} />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    End Date
                  </p>
                  <div
                    style={{
                      display: "flex",
                      border: "none",
                      width: "65%",
                    }}
                  >
                    <Form.Item name="endDate" style={{ width: "100%" }}>
                      <DatePicker format={dateFormate} />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Institute Name
                  </p>
                  <div className="emp-input-border">
                    <Form.Item name="instituteName">
                      <Input
                        className="profile-input"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <p className="medical-label" style={{ fontSize: 11 }}>
                    Institute Address
                  </p>
                  <div className="emp-input-border">
                    <Form.Item name="instituteAddress">
                      <input
                        className="profile-input"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div />
                  <div style={{ marginTop: 34, paddingRight: 0, width: 70 }}>
                    <Form.Item>
                      <Button className="emp-blue-btn" htmlType="submit">
                        <span style={{ color: "#FFFFFF" }}>UPDATE</span>
                      </Button>
                    </Form.Item>
                    {/*<div className="emp-blue-btn">
                    <span style={{ color: "#FFFFFF" }}>ADD</span>
                  </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
}

export default QualificationCard;
