import React, { useEffect, useState, Fragment } from "react";
import { Select, Spin, Modal, Form, message, Input, DatePicker } from "antd";
import debounce from "lodash/debounce";
import { Images } from "./../../Themes";
import moment from "moment";

const { Option } = Select;
let clientprofileid = sessionStorage.getItem("clientprofileid");

const ClientEmployerJobsCard = ({ jobHistory }) => {
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [visibleTag, setVisibleTag] = useState("");
  const [form] = Form.useForm();
  const confirmationTag = (id, value) => {
    if (value) {
      setModal1Visible(value);
    }
    if (id) {
      setSelectedTag(id);
    }
  };

  const removeProfileTag = (id) => {};

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      jobTitle: jobHistory.jobTitle,
      employerName: jobHistory.employerName,
      employerAddress: jobHistory.employerAddress,
      businessNumber: jobHistory.businessNumber,
      startDate: moment(jobHistory.startDate),
      endDate: moment(jobHistory.endDate),
      payDetails: jobHistory.payDetails,
      anzscoCode: jobHistory.anzscoCode,
    });
  }, [
    form,
    jobHistory.anzscoCode,
    jobHistory.businessNumber,
    jobHistory.employerAddress,
    jobHistory.employerName,
    jobHistory.endDate,
    jobHistory.jobTitle,
    jobHistory.payDetails,
    jobHistory.startDate,
  ]);

  return (
    <Fragment>
      <div
        className="form-container"
        style={{ width: 200, marginLeft: 0, marginRight: 0 }}
      >
        <Form form={form}>
          <div style={{ padding: 10 }}>
            <div>
              <p>Job Title</p>
              <div className="profile-input-border">
                <Form.Item name="jobTitle">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p>Employer Name</p>
              <div className="profile-input-border">
                <Form.Item name="employerName">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p>Employer Address</p>
              <div className="profile-input-border">
                <Form.Item name="employerAddress">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p>Business Number</p>
              <div className="profile-input-border">
                <Form.Item name="businessNumber">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p>Start Date</p>
              <Form.Item name="startDate">
                <DatePicker
                  disabled
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </div>
            <div>
              <p>End Date</p>
              <Form.Item name="endDate">
                <DatePicker
                  disabled
                  style={{ width: "100%" }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </div>
            <div>
              <p>Pay Details</p>
              <div className="profile-input-border">
                <Form.Item name="payDetails">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
            <div>
              <p>ANZSCO Code</p>
              <div className="profile-input-border">
                <Form.Item name="anzscoCode">
                  <Input disabled className="profile-input" />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default ClientEmployerJobsCard;
