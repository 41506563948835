import React, { useState, Fragment, useEffect } from "react";
import {
  Modal,
  Spin,
  Form,
  Select,
  Button,
  Tooltip,
  message,
  Checkbox,
} from "antd";
import { redirectHubspotUrl } from "../../config";
// import "../OutlookIntegration/OutlookIntegration.css";
import { useLocation } from "react-router-dom";
import { apiRefresh } from "../../services/api";

var location = window.location.href;
var searchLocation = window.location.search;
const HubspotIntegration = ({
  getHubspotToken,
  hubspotLogin,
  removeHubspotAccount,
  addHubspotAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingToken, setLoadingToken] = useState(false);
  const [hubspot, setHubspotObj] = useState("");
  const [hubspotOn, setHubspotOn] = useState(false);
  const state = useLocation().state;

  const redirection = () => {
    setLoading(true);
    onGettingHubspotRedirectParams();
  };
  const getAllData = () => {
    setLoading(true);
    const getHubspotOpt = {
      url: `v1/hubspot/HubSpotON`,
    };
    getHubspotOpt.types = [
      "GET_HUBSPOT_SETTING_SUCCESS",
      "GET_HUBSPOT_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getHubspotOpt)
      .then((res) => {
        setLoading(false);
        setHubspotOn(res.isHubSpotON);
      })
      .catch((err) => {
        setLoading(false);
      });
    setLoadingToken(true);
    getHubspotToken()
      .then((res) => {
        setHubspotObj(res.payload.accessToken);
        // setName(res.payload.name);
        // setEmail(res.payload.email);
        setLoadingToken(false);
      })
      .catch((er) => {
        setLoadingToken(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirection(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllData(), []);
  const loginOnHubspot = () => {
    setLoading(true);
    let _data = {
      uri: redirectHubspotUrl,
    };

    hubspotLogin(_data)
      .then((response) => {
        setLoading(false);
        // if (response.payload) window.location = response.payload;
        if (response.payload) {
          window.open(response.payload, "_blank");
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onTargetChecked = (e) => {
    setLoading(true);
    const data = {
      isHubSpotON: (e && e.target.checked) || false,
    };
    const putHubspotOpt = {
      url: `v1/hubspot/HubSpotON`,
    };
    putHubspotOpt.types = [
      "PUT_HUBSPOT_SETTING_SUCCESS",
      "PUT_HUBSPOT_SETTING_FAILURE",
    ];
    apiRefresh
      .put(putHubspotOpt, data)
      .then(() => {
        setHubspotOn(e.target.checked);
        setLoading(false);
        message.success("Successfully Updated!");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onGettingHubspotRedirectParams = async () => {
    let params = new URLSearchParams(searchLocation);
    let code = params.get("code");

    if (code) {
      addHubspotAccount({
        code: code,
        redirectUrl: redirectHubspotUrl,
      })
        .then((res) => {
          searchLocation = "";
          message.error(res, "30");
          getAllData();
        })
        .catch((err) => {
          searchLocation = "";
          message.error(err.payload && err.payload.response.data.Detail, "30");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const deleteHubspotAccount = () => {
    setLoading(true);
    removeHubspotAccount()
      .then(() => {
        setHubspotObj("");
        onTargetChecked();
        setLoading(false);
      })
      .catch(() => {
        message.error("Couldn't remove hubspot account!");
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div>
        <Spin spinning={loading || loadingToken} size="large">
          <div style={{ display: "flex", padding: 40 }}>
            <div style={{ width: "100%", height: "100%" }}>
              <Form>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3>HUBSPOT</h3>
                    <div
                      className="reminder-set-form"
                      style={{ marginLeft: 20 }}
                    >
                      <Form.Item className="d-block">
                        <Checkbox
                          checked={hubspotOn}
                          disabled={hubspot ? false : true}
                          onChange={onTargetChecked}
                        >
                          Enable Forms
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="document-sync-box">
                    {/* {hubspot !== "" && (
                      <>
                        <div
                          style={{ marginTop: 15, overflowWrap: "break-word" }}
                        >
                          <span>{hubspot}</span>
                        </div>
                      </>
                    )} */}
                    <div>
                      <div className="client-tag-table">
                        <div className="reminder-set-form margin-top-34">
                          {hubspot !== "" ? (
                            <div className="doc-office">
                              <div className="userDetail">
                                {/*{hubspot.displayName && (*/}
                                {/*  <p>Name: {hubspot.displayName}</p>*/}
                                {/*)}*/}
                                {/*{hubspot.userPrincipalName && (*/}
                                {/*  <p>Email: {hubspot.userPrincipalName}</p>*/}
                                {/*)}*/}
                                <a
                                  className="remove-account"
                                  onClick={() => deleteHubspotAccount()}
                                >
                                  X
                                </a>
                              </div>
                              <div
                                style={{
                                  marginTop: 15,
                                  overflowWrap: "break-word",
                                  padding: 10,
                                }}
                              >
                                <span>{hubspot}</span>
                              </div>
                            </div>
                          ) : (
                            <Form.Item className="d-block">
                              <Button
                                onClick={loginOnHubspot}
                                type="primary"
                                className="button-blue"
                              >
                                ADD ACCOUNT
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </div>
    </Fragment>
  );
};
export default HubspotIntegration;
