import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChatLists from "../Chats/ChatList";

import {
  getAllChats,
  getClientsData,
  addChatThread,
  getChatMessages,
  addThreadMessage,
  addChatMember,
  getUsers,
  addChatFileNotes
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllChats: bindActionCreators(getAllChats, dispatch),
    onGetClientsData: bindActionCreators(getClientsData, dispatch),
    onAddChatThread: bindActionCreators(addChatThread, dispatch),
    onGetChatMessages: bindActionCreators(getChatMessages, dispatch),
    onAddThreadMessage: bindActionCreators(addThreadMessage, dispatch),
    onAddChatMember: bindActionCreators(addChatMember, dispatch),
    onGetUsersData: bindActionCreators(getUsers, dispatch),
    onAddChatFileNotes: bindActionCreators(addChatFileNotes, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatLists);
