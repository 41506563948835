import React, { useState, Fragment, useEffect } from "react";
import { Spin, message, Upload, Button } from "antd";
import {
  LeftCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Images } from "../Themes";
import { apiRefresh } from "../services/api";

const data = [];
const dateFormat = "YYYY-MM-DD";
const LoginImage = ({ onUploadAvatar }) => {
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(false);
  const [singleData, setSingleData] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [imageUrl, setImageUrl] = useState([]);

  useEffect(() => {
    // setLoading(true);
    // onGetCompanies().then(() => {
    //   setLoading(false);
    // });
    getImage();
  }, []);

  const getImage = () => {
    const getImageOpt = {
      url: `v1/admin/EzyMigrateSettings/GetLoginPageImage`,
    };
    getImageOpt.types = ["GET_IMAGE_SUCCESS", "GET_IMAGE_FAILURE"];
    setLoading(true);
    apiRefresh
      .get(getImageOpt)
      .then((res) => {
        let images = [];
        if (res) {
          images.push(res && res.paramValue);
        }
        setImageUrl(images);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.addEventListener("load", () => callback(reader.result));
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // if (!isJpgOrPng) {
    //   message.error("You can only upload JPG/PNG file!");
    // }
    const isLt2M = file.size / 1024 / 1024 < 2;

    // if (!isLt2M) {
    //   message.error("Image must smaller than 2MB!");
    // }
    return true;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadImage = (info, id) => {
    let formData = new FormData();
    formData.append("File", info);
    onUploadAvatar(formData).then((res) => {
      setImageUrl(res.payload);
    });
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);

      setImageUrl("");
      return;
    }
    if (info.file.status == "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (img) => {
          setImageUrl(img);
        },
        setLoading(false)
      );
    }
    if (info.file.status == "error") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (img) => {
          setImageUrl(img);
        },
        setLoading(false)
      );
    }
  };

  const onSaveImage = () => {
    let data = {
      path: imageUrl,
    };
    const saveImageOpt = {
      url: `v1/admin/EzyMigrateSettings/LoginPageImage`,
    };
    saveImageOpt.types = ["SAVE_IMAGE_SUCCESS", "SAVE_IMAGE_FAILURE"];
    setLoading(true);
    apiRefresh
      .put(saveImageOpt, data)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div style={{ display: "block" }}>
          <div className="ca-gray-cont">
            <div style={{ marginLeft: 20 }}>
              <div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={uploadImage}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {uploadButton}
                </Upload>
                {imageUrl && imageUrl.length > 0 && (
                  <div
                    style={{ width: "98%", marginBottom: 20, marginTop: 10 }}
                  >
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 20,
                }}
              >
                <Button onClick={onSaveImage} className="button-blue">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default LoginImage;
