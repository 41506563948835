import React, { Fragment, useEffect, useState } from "react";
import { Images } from "./../../Themes";
import DailyTaskToDo from "./DailyTaskToDo";
import PendingTaskToDo from "./PendingTaskToDo";
import Moment from "moment";
import UpcommingTaskToDo from "./UpcommingTaskToDo";
import { Spin, Select } from "antd";
import { UpSquareOutlined, DownSquareOutlined } from "@ant-design/icons";

const TastToDo = ({
  showModal,
  onGetReminderTasks,
  reminderTaskRes,

  onGetDailyTasks,
  dailyTaskRes,
  onGetAllClients,

  onRemoveTasks,
  removeTaskRes,

  onUpdetaTasks,

  onUpdateCompletedTask,
  updateComletedTaskRes,

  ComletedTasks,

  removeTask,
  getTasksComments,
  addTaskComment,
  getTaskFollowers,
  onGetClientProfile,
  onGetPotentialClient,
  onGetUpdateBol,
  onRemoveFollower,
  users,
  onRemoveTaskClient,
  onSearchClient,
  onSearchPotentialClient,
  reloadFlag,
  setReload,
  onAddFileNote,
  onGetEmployerData,
}) => {
  const [todaytasks, setTodaytasks] = useState([]);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [upcommingTasks, setUpcommingTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOwner, setOwner] = useState(false);

  useEffect(() => {
    dateFormating(dailyTaskRes);
  }, [dailyTaskRes]);

  const dateFormating = (dailyTaskRes) => {
    if (dailyTaskRes && dailyTaskRes.items && dailyTaskRes.items.length > 0) {
      const currentDate = Moment(new Date()).format("l");
      let todayTask = [];
      let pendingTask = [];
      let upcommingTask = [];

      dailyTaskRes.items.map((data, index) => {
        console.log("tasks dates", data.taskDate);
        console.log("current dates", currentDate);
        const taskDate = Moment(new Date(data.taskDate)).format("l");

        if (Moment(currentDate).isSame(Moment(taskDate))) {
          todayTask.push(data);
        }

        if (Moment(currentDate) < Moment(taskDate)) {
          upcommingTask.push(data, index);
        }

        if (Moment(currentDate) > Moment(taskDate)) {
          pendingTask.push(data);
        }
      });

      const taskPendingArray = [...pendingTask];
      taskPendingArray.sort((a, b) => {
        if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
        if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
        else return 0;
      });
      setPendingTasks(taskPendingArray);

      const taskTodayArray = [...todayTask];
      taskTodayArray.sort((a, b) => {
        if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
        if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
        else return 0;
      });
      setTodaytasks(taskTodayArray);

      const taskUpcomingArray = [...upcommingTask];
      taskUpcomingArray.sort((a, b) => {
        if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
        if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
        else return 0;
      });
      setUpcommingTasks(taskUpcomingArray);
    }
  };

  useEffect(() => {
    setLoading(true);
    const ownerInfo = localStorage.getItem("userOwner");
    if (ownerInfo === "True") setOwner(true);
    else setOwner(false);
    onGetDailyTasks()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [onGetDailyTasks, reloadFlag]);

  const showUserTasks = (id) => {
    setLoading(true);
    onGetDailyTasks(id)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const sortDailyTaskDesc = () => {
    const taskArray = [...todaytasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
      else return 0;
    });
    setTodaytasks(taskArray);
  };

  const sortDailyTaskAsc = () => {
    const taskArray = [...todaytasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return 1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return -1;
      else return 0;
    });
    setTodaytasks(taskArray);
  };

  const sortPendingTaskDesc = () => {
    const taskArray = [...pendingTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
      else return 0;
    });
    setPendingTasks(taskArray);
  };

  const sortPendingTaskAsc = () => {
    const taskArray = [...pendingTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return 1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return -1;
      else return 0;
    });
    setPendingTasks(taskArray);
  };

  const sortUpcomingTaskDesc = () => {
    const taskArray = [...upcommingTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return -1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return 1;
      else return 0;
    });
    setUpcommingTasks(taskArray);
  };

  const sortUpcomingTaskAsc = () => {
    const taskArray = [...upcommingTasks];
    taskArray.sort((a, b) => {
      if (Moment(a.taskDate) > Moment(b.taskDate)) return 1;
      if (Moment(a.taskDate) < Moment(b.taskDate)) return -1;
      else return 0;
    });
    setUpcommingTasks(taskArray);
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="d-flex">
          <div
            className="profile-print-box"
            style={{ marginLeft: 22, height: 31 }}
          >
            <img src={Images.printWhite} className="profile-print-icon" />
          </div>
          {isOwner ? (
            <div style={{ marginLeft: 54 }}>
              <Select
                showSearch
                options={users}
                style={{ width: 200 }}
                placeholder="Search to Select Users"
                optionFilterProp="children"
                allowClear
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(item) => {
                  showUserTasks(item);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="task-to-do">
          <div className="heading-style" style={{ display: "flex" }}>
            <span>TODAY's TASKS</span>
            <div style={{ marginLeft: "auto" }}>
              <UpSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortDailyTaskAsc()}
              />
              <DownSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortDailyTaskDesc()}
              />
            </div>
          </div>

          <div className="cv-gray-cont">
            {todaytasks &&
              todaytasks.length > 0 &&
              todaytasks.map((data, index) => {
                if (data && data.isCompleted === false) {
                  return (
                    <DailyTaskToDo
                      key={index}
                      index={index}
                      data={data}
                      id={data.id}
                      showModal={showModal}
                      onGetDailyTasks={onGetDailyTasks}
                      onGetAllClients={onGetAllClients}
                      dailyTaskRes={dailyTaskRes}
                      removeTask={removeTask}
                      onUpdetaTasks={onUpdetaTasks}
                      ComletedTasks={ComletedTasks}
                      getTasksComments={getTasksComments}
                      addTaskComment={addTaskComment}
                      getTaskFollowers={getTaskFollowers}
                      onGetClientProfile={onGetClientProfile}
                      onGetPotentialClient={onGetPotentialClient}
                      onGetUpdateBol={onGetUpdateBol}
                      onRemoveFollower={onRemoveFollower}
                      onRemoveTaskClient={onRemoveTaskClient}
                      onSearchClient={onSearchClient}
                      onSearchPotentialClient={onSearchPotentialClient}
                      reloadFlag={reloadFlag}
                      setReload={setReload}
                      onAddFileNote={onAddFileNote}
                      onGetEmployerData={onGetEmployerData}
                    />
                  );
                }
              })}
          </div>
        </div>

        <div className="task-to-do">
          <div className="heading-style" style={{ display: "flex" }}>
            <span>PENDING TASKS</span>
            <div style={{ marginLeft: "auto" }}>
              <UpSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortPendingTaskAsc()}
              />
              <DownSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortPendingTaskDesc()}
              />
            </div>
          </div>

          <div className="cv-gray-cont">
            {pendingTasks &&
              pendingTasks.length > 0 &&
              pendingTasks.map((data, index) => {
                if (data && data.isCompleted === false) {
                  return (
                    <PendingTaskToDo
                      key={index}
                      index={index}
                      data={data}
                      id={data.id}
                      showModal={showModal}
                      onGetDailyTasks={onGetDailyTasks}
                      onGetAllClients={onGetAllClients}
                      dailyTaskRes={dailyTaskRes}
                      removeTask={removeTask}
                      ComletedTasks={ComletedTasks}
                      getTasksComments={getTasksComments}
                      addTaskComment={addTaskComment}
                      getTaskFollowers={getTaskFollowers}
                      onGetClientProfile={onGetClientProfile}
                      onGetPotentialClient={onGetPotentialClient}
                      onGetUpdateBol={onGetUpdateBol}
                      onRemoveFollower={onRemoveFollower}
                      onRemoveTaskClient={onRemoveTaskClient}
                      onSearchClient={onSearchClient}
                      onSearchPotentialClient={onSearchPotentialClient}
                      reloadFlag={reloadFlag}
                      setReload={setReload}
                      onAddFileNote={onAddFileNote}
                      onGetEmployerData={onGetEmployerData}
                    />
                  );
                }
              })}
          </div>
        </div>

        <div className="task-to-do">
          <div className="heading-style" style={{ display: "flex" }}>
            <span>UPCOMING TASKS</span>
            <div style={{ marginLeft: "auto" }}>
              <UpSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortUpcomingTaskAsc()}
              />
              <DownSquareOutlined
                style={{ cursor: "pointer" }}
                onClick={() => sortUpcomingTaskDesc()}
              />
            </div>
          </div>

          <div>
            <div className="cv-gray-cont ">
              {upcommingTasks &&
                upcommingTasks.length > 0 &&
                upcommingTasks.map((data, index) => {
                  if (data && data.isCompleted === false) {
                    return (
                      <UpcommingTaskToDo
                        key={index}
                        index={index}
                        data={data}
                        id={data.id}
                        showModal={showModal}
                        onGetDailyTasks={onGetDailyTasks}
                        onGetAllClients={onGetAllClients}
                        dailyTaskRes={dailyTaskRes}
                        removeTask={removeTask}
                        ComletedTasks={ComletedTasks}
                        getTasksComments={getTasksComments}
                        addTaskComment={addTaskComment}
                        getTaskFollowers={getTaskFollowers}
                        onGetClientProfile={onGetClientProfile}
                        onGetPotentialClient={onGetPotentialClient}
                        onGetUpdateBol={onGetUpdateBol}
                        onRemoveFollower={onRemoveFollower}
                        onRemoveTaskClient={onRemoveTaskClient}
                        onSearchClient={onSearchClient}
                        onSearchPotentialClient={onSearchPotentialClient}
                        reloadFlag={reloadFlag}
                        setReload={setReload}
                        onAddFileNote={onAddFileNote}
                        onGetEmployerData={onGetEmployerData}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};

export default TastToDo;
