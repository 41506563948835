import React, { useState, Fragment, useEffect } from "react";
import {
  Spin,
  message,
  Tooltip,
  Modal,
  Row,
  Col,
  DatePicker,
  Button,
  AutoComplete,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import { Table, Tag, Space } from "antd";
import Branches from "./Branches";
import { Images } from "../Themes";

const data = [];
const dateFormat = "YYYY-MM-DD";
const Companies = ({
  onGetCompanies,
  companiesRes,
  onGetBranches,
  branchesRes,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(false);
  const [singleData, setSingleData] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedCompanyListing, setSearchedCompanyListing] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setLoading(true);
    let data = {
      searchParam: "",
      pageSize: 20,
      pageNumber: 1,
      sortColumn: "CreatedDate",
      sortDirection: "Desc",
      startDate: "1900-01-01T00:00:00Z",
      endDate: new Date(),
    };
    onGetCompanies(data).then(() => {
      setLoading(false);
    });
  }, [onGetCompanies]);

  useEffect(() => {
    letterTempaltesFilter(companiesRes);
  }, [companiesRes]);

  const letterTempaltesFilter = (companiesRes) => {
    let filterList = [];
    if (companiesRes && companiesRes.list.length > 0) {
      setTotalPages(companiesRes.count);
      companiesRes.list.map((data, index) => {
        if (data) {
          data.index = index;
          data.key = `${index + 1}`;
          filterList.push(data);
        }
      });

      setSingleData(filterList);
    }
  };

  const fetchBranches = (record, onExpand) => {
    onExpand(record);
    setCompanyId(record && record.id);
  };

  const handleChangeDateFrom = (date, dateString) => {
    let _dateFrom = date === null ? "" : moment(date).format("YYYY-MM-DD");
    setDateFrom(_dateFrom);
  };

  const handleChangeDateTo = (date, dateString) => {
    let _dateTo = date === null ? "" : moment(date).format("YYYY-MM-DD");
    setDateTo(_dateTo);
  };

  const showAll = () => {
    setLoading(true);
    let data = {
      searchParam: "",
      pageSize: 20,
      pageNumber: 1,
      sortColumn: "CreatedDate",
      sortDirection: "Desc",
      startDate: "1900-01-01T00:00:00Z",
      endDate: new Date(),
    };
    onGetCompanies(data).then(() => {
      setDateTo("");
      setDateFrom("");
      setLoading(false);
    });
  };

  const onSearchCompany = (searchtext) => {
    // let _filterSearchList = [];
    setSearchText(searchtext);
    if (searchtext.length > 2) {
      setLoading(true);
      let data = {
        searchParam: searchtext,
        pageSize: 20,
        pageNumber: 1,
        sortColumn: "CreatedDate",
        sortDirection: "Desc",
        startDate: dateFrom || "1900-01-01T00:00:00Z",
        endDate: dateTo || new Date(),
      };
      onGetCompanies(data).then(() => {
        setLoading(false);
      });
      // if (companiesRes && companiesRes.list.length > 0) {
      //   companiesRes &&
      //     companiesRes.list.forEach((data) => {
      //       let result = 0;
      //       result = data.name.match(searchtext);

      //       if (result && result.length > 0) {
      //         _filterSearchList.push(data);
      //       } else {
      //         //do nothing
      //       }
      //     });
      // }
      // setSingleData(_filterSearchList);
      // setLoading(false);
    } else if (searchtext.length < 3) {
      setLoading(true);
      let data = {
        searchParam: "",
        pageSize: 20,
        pageNumber: 1,
        sortColumn: "CreatedDate",
        sortDirection: "Desc",
        startDate: dateFrom || "1900-01-01T00:00:00Z",
        endDate: dateTo || new Date(),
      };
      onGetCompanies(data).then(() => {
        setLoading(false);
      });
    } else {
      //
    }
  };

  const onSearch = () => {
    if (
      dateFrom === "" ||
      dateTo === "" ||
      (dateFrom === "" && dateTo === "")
    ) {
      message.error("Please select both dates, date from and date to!");
    } else {
      setLoading(true);
      let _filterList = [];
      let data = {
        searchParam: searchText,
        pageSize: 20,
        pageNumber: 1,
        sortColumn: "CreatedDate",
        sortDirection: "Desc",
        startDate: dateFrom || "1900-01-01T00:00:00Z",
        endDate: dateTo || new Date(),
      };
      onGetCompanies(data).then(() => {
        setLoading(false);
      });
      // if (companiesRes && companiesRes.list.length > 0) {
      //   companiesRes &&
      //     companiesRes.list.forEach((data) => {
      //       let modifiedDate = moment(data.createdDate).format(
      //         "YYYY-MM-DDT00:00:00+00:00"
      //       );
      //       let _comparedDate = moment(modifiedDate).format("YYYY-MM-DD");

      //       let isBetween = moment(_comparedDate).isBetween(dateFrom, dateTo);

      //       if (isBetween) {
      //         _filterList.push(data);
      //       } else {
      //         //do nothing
      //       }
      //     });
      // }
      // setSingleData(_filterList);
      // setLoading(false);
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a
        // onClick={()=> {fetchBranches(record.id)}}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const paginate = (value) => {
    // this.props.onPaginate(true);
    setPageNumber(value.current);
    setPageSize(value.pageSize);
    setLoading(true);
    let data = {
      searchParam: searchText,
      pageSize: value.pageSize,
      pageNumber: value.current,
      sortColumn: "CreatedDate",
      sortDirection: "Desc",
      startDate: dateFrom || "1900-01-01T00:00:00Z",
      endDate: dateTo || new Date(),
    };
    onGetCompanies(data)
      .then(() => {
        setLoading(false);
        setDefaultCurrent(1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div style={{ display: "block" }}>
          <div className="ca-gray-cont">
            <Row>
              <Col xs={5} style={{ marginLeft: "26px", marginBottom: "20px" }}>
                <AutoComplete
                  // onSelect={this.onSelect}
                  virtual={false}
                  onSearch={onSearchCompany}
                  placeholder="Search company..."
                />
              </Col>
            </Row>
            <Row>
              <Col xs={11}>
                <Row>
                  <Col xs={11} offset={1}>
                    <DatePicker
                      format={dateFormat}
                      placeholder={"SELECT DATE FROM"}
                      onChange={handleChangeDateFrom}
                    />
                  </Col>
                  <Col xs={11} offset={1}>
                    <DatePicker
                      placeholder={"SELECT DATE TO"}
                      format={dateFormat}
                      onChange={handleChangeDateTo}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={13}>
                <Row>
                  <Col xs={11} offset={10} style={{ display: "flex" }}>
                    <Button
                      type="primary"
                      onClick={onSearch}
                      style={{ marginRight: "5px" }}
                    >
                      SEARCH
                    </Button>
                    <Button type="primary" onClick={showAll}>
                      SHOW ALL
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <Branches
                  companyId={companyId}
                  onGetBranches={onGetBranches}
                  branchesRes={branchesRes}
                  record={record}
                />
              ),

              expandIcon: ({ expanded, onExpand, record }) => (
                <div className="table-action" type="right">
                  <Tooltip placement="topLeft" title={`Branches`}>
                    <CaretDownOutlined
                      type="Branches"
                      onClick={() => fetchBranches(record, onExpand)}
                    />
                  </Tooltip>
                </div>
              ),
            }}
            dataSource={singleData && singleData}
            pagination={{
              defaultCurrent: defaultCurrent,
              total: totalPages,
              defaultPageSize: pageSize,
              pageSizeOptions: ["20", "50", "100"],
            }}
            onChange={paginate}
          />
        </div>
      </Spin>
    </>
  );
};
export default Companies;
