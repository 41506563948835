import { api } from "../../services/api";
import * as types from "../Constants";

export const addVisaCategory = (data) => {
  const options = {
    url: "v1/case/CheckList/Category",
  };
  options.types = [
    types.ADD_VISA_CATEGORY_TYPE_SUCCESS,
    types.ADD_VISA_CATEGORY_TYPE_FAILURE,
  ];

  return api.post(options, data);
};

export const getVisaCategory = () => {
  const options = {
    url: `v1/case/CheckList/Category/All`,
  };
  options.types = [
    types.GET_VISA_CATEGORY_TYPE_SUCCESS,
    types.GET_VISA_CATEGORY_TYPE_FAILURE,
  ];

  return api.get(options);
};
export const addVisaChecklist = (data) => {
  const options = {
    url: "v1/case/CheckList",
  };
  options.types = [
    types.ADD_VISA_CHECKLIST_SUCCESS,
    types.ADD_VISA_CHECKLIST_FAILURE,
  ];

  return api.post(options, data);
};
