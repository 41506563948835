import * as types from "../Constants";

export const qualificationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_CLIENT_QUALIFICATION_SUCCESS:
      return { ...state, addQualificationSuccess: action.payload };
    case types.ADD_CLIENT_QUALIFICATION_FAILURE:
      return { ...state, addQualificationError: action.payload };

    case types.GET_CLIENT_QUALIFICATION_SUCCESS:
      return { ...state, qualificationData: action.payload };
    case types.GET_CLIENT_QUALIFICATION_FAILURE:
      return {
        ...state,
        qualificationError: action.payload,
        qualificationData: null,
      };

    case types.UPDATE_CLIENT_QUALIFICATION_SUCCESS:
      return { ...state, updateQualificatonSuccess: action.payload };
    case types.UPDATE_CLIENT_QUALIFICATION_FAILURE:
      return { ...state, updateQualificationError: action.payload };

    default:
      return state;
  }
};
